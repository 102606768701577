import dayjs from 'dayjs';
import _ from 'lodash';
import { TableCell } from './Components/TableCell';

export const onAction = async ({
  data,
  actionType,
  resolveExternalUpdate,
  handleEditExternalUpdate,
  handleResyncAction,
}) => {
  switch (actionType) {
    case 'overwrite':
      if (data.externalUpdate.type === 'resync') handleResyncAction({ data, actionType });
      else await resolveExternalUpdate.mutate({ id: data.externalUpdate.id, data: { actionType: 'overwrite' } });
      break;
    case 'dismiss':
      await resolveExternalUpdate.mutate({ id: data.externalUpdate.id, data: { actionType: 'dismiss' } });
      break;
    case 'edit':
      handleEditExternalUpdate({ data, actionType: 'overwrite' });
      break;
    default:
      break;
  }
};

export const formatDate = (date) => {
  if (date === undefined || date === null) return date;
  return dayjs.utc(date).format('YYYY-MM-DD');
};

const normalizeDate = (val) => (dayjs(val).isValid() ? dayjs.utc(val).format('YYYY-MM-DD') : null);
const getMonth = (val) => (dayjs(val).isValid() ? dayjs.utc(val).format('YYYY-MM') : null);

export const normalizeNumber = (val) =>
  !_.isNil(val) && !isNaN(val) ? (typeof val === 'number' ? val : parseFloat(val)).toFixed(2) : null;

// These should match the functions used on the backend as the `MAPPING_FUNCTIONS` for external updates
export const MAPPING_FUNCTIONS = {
  start_date: normalizeDate,
  end_date: normalizeDate,
  date: getMonth,
  amount: normalizeNumber,
  recurring_amount: normalizeNumber,
  seats: _.parseInt,
};

// For external updates, when the product_id and customer_id are updated to null,
// then we don't get a product_name nor customer_name. They are `undefined` in the update_data
// Thus, we need to make an exception when the `fields` that we want to check include `product_name` or `customer_name`
const compareFieldToAlternateKey = ({ field, target }) => {
  if (field === 'product_name' && _.isNull(target.product_id)) {
    return true;
  } else if (field === 'customer_name' && _.isNull(target.customer_id)) {
    return true;
  } else {
    return false;
  }
};

// This should match backend function
export const generateChanges = ({ source, target, fields }) =>
  fields.reduce((acc, field) => {
    if (field in target || compareFieldToAlternateKey({ field, target })) {
      const mapFunc = MAPPING_FUNCTIONS[field] ?? _.identity;
      const mappedSourceValue = mapFunc(source[field]);
      const mappedTargetValue = mapFunc(target[field]);

      const compareFunc = field === 'metadata' ? _.isMatch : _.isEqual;

      if (!compareFunc(mappedSourceValue, mappedTargetValue)) {
        acc[field] = target[field];
      }
    }
    return acc;
  }, {});

export const sortingFunction = ({ rowA, rowB, fieldName, compareFunction = (a, b) => a > b }) => {
  let rowAValue = rowA.original.externalUpdate?.update_data?.[fieldName];
  if (_.isUndefined(rowAValue)) rowAValue = rowA.original?.targetObject?.[fieldName];

  let rowBValue = rowB.original.externalUpdate?.update_data?.[fieldName];
  if (_.isUndefined(rowBValue)) rowBValue = rowB.original?.targetObject?.[fieldName];

  return compareFunction(rowAValue, rowBValue) ? 1 : -1;
};

export const compareDates = (a, b) => {
  if (_.isNil(a)) return false;
  if (_.isNil(b)) return true;
  return dayjs(a).isAfter(b);
};

export const makeTableCell = ({ row, key, formatter = _.identity, onClick, isLink }) => {
  return (
    <TableCell
      isLink={isLink}
      onClick={onClick}
      isChanged={row.original.changedFields[key]}
      original={formatter(row.original.targetObject?.[key])}
      updated={formatter(row.original.externalUpdate?.update_data?.[key])}
      field={key}
    />
  );
};
