import { cssVar } from 'polished';
import { ChartDonut4Icon, GeometryIcon, ReportIcon, WaterfallIcon } from 'components/Icons';

export const DEFAULT_REPORTS_OPTION = 'all';
export const DEFAULT_NEW_REPORT_TYPE = 'waterfall';

export const REPORTS_OPTIONS = {
  all: 'All types',
  snapshot: 'Subscription Metrics',
  waterfall: 'Revenue Waterfall',
  cohort: 'Cohorts',
  revenue_details: 'Revenue Details',
  revenue_spread: 'Revenue Spread',
};

export const REPORT_OPTIONS_SELECTOR = Object.entries(REPORTS_OPTIONS)
  .filter(([key]) => key !== 'all' && key !== 'snapshot')
  .map(([key, value]) => ({
    label: value,
    value: key,
  }));

export const REPORT_OPTIONS_ICONS = {
  snapshot: ReportIcon,
  waterfall: WaterfallIcon,
  cohort: GeometryIcon,
  revenue_details: ReportIcon,
  revenue_spread: ChartDonut4Icon,
};

export const REPORT_OPTIONS_ROUTE = {
  snapshot: '/dashboard',
  waterfall: '/waterfall',
  cohort: '/cohorts',
  revenue_details: '/revenue',
  revenue_spread: '/spreads',
};

export const REPORT_OPTIONS_FROM_ROUTE = {
  '/dashboard': 'snapshot',
  '/waterfall': 'waterfall',
  '/cohorts': 'cohort',
  '/revenue': 'revenue_details',
  '/spreads': 'revenue_spread',
};

export const REPORTS_COLOR = {
  snapshot: cssVar('--primaryGreen'),
  waterfall: cssVar('--primaryBlue'),
  cohort: cssVar('--primaryPurple'),
  revenue_details: cssVar('--primaryRed'),
  revenue_spread: cssVar('--primaryOrange'),
};
