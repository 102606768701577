import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import styled from 'styled-components';
import { AppContext } from 'AppContext';
import { useInvoicesAPI } from 'api/billing';
import { usePagination, useStateWithStorage } from 'utils/hooks';
import { sortByToKnexOrderBy } from 'utils/tableUtils';
import { TableColumnSettings } from 'shared/Common';
import { BillingContext } from '../BillingContext';
import { TableContainerLayout } from '../Common/TableContainerLayout';
import { INVOICES_FILTER_GROUP_BY } from '../BillingProvider';
import {
  HIDDEN_DEFAULT_COLUMNS,
  INVOICES_DEFAULT_SORT_BY,
  INVOICES_SORT_BY_COLUMNS_NAME_MAPPING,
  INVOICES_TABLE_COLUMN_TITLES_BY_ID,
} from './consts';
import { InvoicesTable } from './InvoicesTable';
import { groupInvoicesByMonth, groupInvoicesBySchedule } from './utils';

const ColumnsSettingsWrapper = styled.div`
  position: relative;
`;

const DEFAULT_PAGE_SIZE = 50;

export const InvoicesTableContainer = () => {
  const {
    appSettings: { currencyISOCode: currency },
    orgId,
  } = useContext(AppContext);
  const {
    openInvoiceModal,
    billingDataFilter,
    invoiceStatuses,
    invoicesGroupBy,
    invoicesSearchQuery,
    metadataFilter,
    setInvoicesSearchQuery,
  } = useContext(BillingContext);

  const { endMonth, startMonth } = billingDataFilter;
  const startDate = dayjs(startMonth).format('YYYY-MM-DD');
  const endDate = dayjs(endMonth).format('YYYY-MM-DD');

  const [storageSortBy, setStorageSortBy] = useStateWithStorage('invoices-page-sort-by', []);

  const {
    currentPageIndex: invoicesCurrentPageIndex = 1,
    setCurrentPageIndex: invoicesSetCurrentPageIndex,
    pageSize: invoicesPageSize,
    setPageSize: invoicesSetPageSize,
  } = usePagination({
    cacheSuffix: 'invoices-page',
    defaultPageSize: DEFAULT_PAGE_SIZE,
  });

  useEffect(() => {
    invoicesSetCurrentPageIndex(1);
    // If invoice statuses or search query changes, we need to reset the page index
  }, [invoicesSetCurrentPageIndex, invoiceStatuses, invoicesSearchQuery]);

  const { data: invoicesData = {}, isFetching: isFetchingInvoices, params: listInvoicesParams } = useInvoicesAPI({
    orgId,
    // Just avoiding multiple calls when initializing the page
    autoFetch: !!invoiceStatuses,
    invoiceStatus: invoiceStatuses,
    startDate,
    endDate,
    includeReminders: true,
    orderBy: isEmpty(storageSortBy)
      ? INVOICES_DEFAULT_SORT_BY
      : sortByToKnexOrderBy(storageSortBy, INVOICES_SORT_BY_COLUMNS_NAME_MAPPING),
    includeCount: true,
    includeCountByStatus: true,
    searchQuery: invoicesSearchQuery,
    page: invoicesCurrentPageIndex,
    limit: invoicesPageSize,
    metadataFilter,
  });

  const [invoicesPageCount, setInvoicesPageCount] = useState();
  useEffect(() => {
    if (!isFetchingInvoices) {
      setInvoicesPageCount(invoicesData?.metadata?.maxPage);
    }
  }, [isFetchingInvoices, invoicesData]);

  const invoicesForTable = useMemo(() => {
    if (invoicesData?.data && invoicesData?.data?.length) return invoicesData?.data;
  }, [invoicesData?.data]);

  const dataForTable = useMemo(() => {
    if (!invoicesForTable) return [];
    switch (invoicesGroupBy) {
      case INVOICES_FILTER_GROUP_BY.MONTH:
        return groupInvoicesByMonth({ invoices: invoicesForTable });
      case INVOICES_FILTER_GROUP_BY.INVOICING_SCHEDULE:
        return groupInvoicesBySchedule({ invoices: invoicesForTable });
      default:
        return [];
    }
  }, [invoicesForTable, invoicesGroupBy]);

  const [tableRows, setTableRows] = useState([]);
  const [allColumnsState, setAllColumnsState] = useState();

  const [hiddenInvoicesColumns, setHiddenInvoicesColumns] = useStateWithStorage(
    'invoices-table-hidden-columns',
    HIDDEN_DEFAULT_COLUMNS,
  );

  const onInvoiceClick = useCallback(
    (invoice) => {
      openInvoiceModal({ invoice });
    },
    [openInvoiceModal],
  );

  return (
    <>
      <TableContainerLayout
        columnsSettingsComponent={
          hiddenInvoicesColumns && (
            <ColumnsSettingsWrapper>
              <TableColumnSettings
                tableName="invoices"
                columnsTitles={INVOICES_TABLE_COLUMN_TITLES_BY_ID}
                allColumns={allColumnsState}
                numberValue={invoicesPageSize}
                handleShowResultsChange={(number) => invoicesSetPageSize(number?.value ?? 10)}
                resultsLabel={'# of invoices on page'}
                options={[
                  { label: '10', value: 10 },
                  { label: '50', value: 50 },
                  { label: '100', value: 100 },
                ]}
              />
            </ColumnsSettingsWrapper>
          )
        }
        setSearchQuery={setInvoicesSearchQuery}
        isFetching={isFetchingInvoices}
        rowsCount={tableRows?.length}
        currentPageIndex={invoicesCurrentPageIndex}
        setCurrentPageIndex={invoicesSetCurrentPageIndex}
        pageCount={invoicesPageCount}
      >
        <InvoicesTable
          initialSortBy={storageSortBy}
          setSortBy={setStorageSortBy}
          currency={currency}
          isFetchingInvoices={isFetchingInvoices}
          hiddenInvoicesColumns={hiddenInvoicesColumns}
          setHiddenInvoicesColumns={setHiddenInvoicesColumns}
          setAllColumnsState={setAllColumnsState}
          setTableRows={setTableRows}
          dataForTable={dataForTable}
          invoicesCountData={invoicesData?.metadata?.countByStatus}
          onInvoiceClick={onInvoiceClick}
          listInvoicesParams={listInvoicesParams}
        />
      </TableContainerLayout>
    </>
  );
};
