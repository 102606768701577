import React from 'react';
import { REVENUE_METRIC } from 'consts/revenueMetrics';
import MetricsCard from './MetricsCard';
import { ExistingInFluxCard } from './ExistingInFluxCard';

const METRICS_ORDER = [
  REVENUE_METRIC.UPSELL,
  REVENUE_METRIC.NEW,
  REVENUE_METRIC.DOWNSELL,
  REVENUE_METRIC.CHURN,
  REVENUE_METRIC.NON_RECURRING,
];

export const MetricsCards = ({
  historicalData,
  transactionData,
  endMonth,
  activeMetric,
  setActiveMetric,
  influxMonths,
  hasTransactionsWithRenewalDeadline,
  countInfluxAsRenewed,
}) => {
  const metrics = METRICS_ORDER;

  const showInFlux = countInfluxAsRenewed && (influxMonths || hasTransactionsWithRenewalDeadline);

  return (
    <>
      {showInFlux ? (
        <ExistingInFluxCard
          historicalData={historicalData}
          endMonth={endMonth}
          activeMetric={activeMetric}
          setActiveMetric={setActiveMetric}
        />
      ) : (
        <MetricsCard
          historicalData={historicalData}
          endMonth={endMonth}
          metric={REVENUE_METRIC.EXISTING}
          activeMetric={activeMetric}
          setActiveMetric={setActiveMetric}
        />
      )}

      {metrics.map((metric) => (
        <MetricsCard
          key={metric.key}
          historicalData={historicalData}
          transactionData={transactionData}
          endMonth={endMonth}
          metric={metric}
          activeMetric={activeMetric}
          setActiveMetric={setActiveMetric}
        />
      ))}
    </>
  );
};
