import styled from 'styled-components';
import { InputLabel } from 'components/Controls/FormikCustomCheckbox/FormikCustomCheckbox';
import { Flexer, FlexerColumn } from 'components/Core';
import { FieldBody, FieldTitle } from 'views/Wizard/styles';

export const TermsSelectors = styled(FlexerColumn)`
  padding: 0 28px 8px 28px;
`;

export const CheckboxRow = styled.div`
  padding: 0 28px 8px 28px;

  ${InputLabel} {
    font-size: 14px;
    line-height: 20px;
  }
`;

export const ItemsRow = styled.div`
  display: flex;
  margin-bottom: 16px;

  > * {
    width: 100%;
    margin-right: 16px;
    white-space: nowrap;

    &:last-child {
      margin-right: 0;
    }
  }
`;

export const StyledFieldTitle = styled(FieldTitle)`
  padding: 28px 0 16px 28px;
  margin: 0;
`;

export const StyledFieldBody = styled(FieldBody)`
  padding: 0;

  > p {
    padding: 0 28px 0 28px;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: var(--primaryBlack50);

    b {
      font-weight: 900;
    }
  }
`;

export const InvoiceNumberLabel = styled.span`
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
`;

export const InvoiceNumberAdditionalSettings = styled(Flexer)`
  padding: 8px 28px 8px 40px;
  gap: 16px;

  > * {
    width: 100%;
  }
`;
