import { MultiselectRibbon } from 'components/Blocks';
import {
  EmptyTableContent,
  HeaderCellWrapper,
  ReactTableBody,
  ReactTableCell,
  ReactTableHeader,
  ReactTableHeaderColumn,
  ReactTableHeaderRow,
  ReactTableRow,
  SortIconWrapper,
} from 'components/Table';
import { ReactComponent as TotalIcon } from 'images/sort-descending.svg';

export const PricingPlansTable = ({
  getTableProps,
  getTableBodyProps,
  headerGroups,
  prepareRow,
  rows,
  toggleAllRowsSelected,
  selectedRowIds,
  bulkDeletePricingPlans,
}) => {
  return (
    <div {...getTableProps()} data-cy="billing__pricing-plans-table">
      <ReactTableHeader backgroundColor="var(--secondaryGray)">
        {headerGroups.map((headerGroup) => (
          <ReactTableHeaderRow {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <ReactTableHeaderColumn
                {...column.getHeaderProps(column.getSortByToggleProps({ title: undefined }))}
                customWidth={column.width}
              >
                <HeaderCellWrapper isSortable={column.canSort && !column.disableSortBy} isSorted={column.isSorted}>
                  {column.id === 'actions' ? <></> : column.render('Header')}

                  {column.isSorted && (
                    <SortIconWrapper isSortedDesc={column.isSortedDesc}>
                      <TotalIcon />
                    </SortIconWrapper>
                  )}
                </HeaderCellWrapper>
              </ReactTableHeaderColumn>
            ))}
          </ReactTableHeaderRow>
        ))}
      </ReactTableHeader>

      {rows.length ? (
        <ReactTableBody {...getTableBodyProps()}>
          {Object.keys(selectedRowIds).length > 0 && (
            <MultiselectRibbon
              label={`${Object.keys(selectedRowIds).length} selected rows:`}
              actions={[
                {
                  role: 'destructive',
                  label: 'Delete',
                  onClick: async () => {
                    const pricingPlanIds = rows.filter((row) => selectedRowIds[row.id]).map((row) => row.original.id);
                    await bulkDeletePricingPlans.mutateAsync({ ids: pricingPlanIds });
                  },
                },
              ]}
              onResetSelection={() => toggleAllRowsSelected(false)}
            />
          )}
          {rows.map((row) => {
            prepareRow(row);
            return (
              <ReactTableRow {...row.getRowProps()} noRowHover>
                {row.cells.map((cell) => (
                  <ReactTableCell {...cell.getCellProps()} customWidth={cell.column.width}>
                    {cell.render('Cell')}
                  </ReactTableCell>
                ))}
              </ReactTableRow>
            );
          })}
        </ReactTableBody>
      ) : (
        <EmptyTableContent>No Pricing Plans Found</EmptyTableContent>
      )}
    </div>
  );
};
