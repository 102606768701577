import React, { useMemo } from 'react';
import { INTEGRATION_TYPES } from 'consts/integrations';
import { GreyDot, SaveIcon } from 'components/Icons';
import { CentererVertical, Flexer } from 'components/Core';
import ConnectionStatus from './ConnectionStatus';
import { CONFIGURATION_TABS } from '../consts';
import { DisconnectIntegrationButton } from './DisconnectIntegrationButton';
import { ReconnectIntegrationButton } from './ReconnectIntegrationButton';
import { StyledIconButton } from 'shared/SyncedExports/ExportButton';
import { COLORS } from 'consts/colors';

const GLConnectionStatus = ({ activeTab, selectedIntegration }) => {
  const isGLTab = activeTab === CONFIGURATION_TABS.SOURCES && selectedIntegration?.type === INTEGRATION_TYPES.GL;

  const isConfigurationRequired = useMemo(() => {
    return (
      isGLTab &&
      !selectedIntegration?.metadata?.cogs &&
      !selectedIntegration?.metadata?.sales &&
      !selectedIntegration?.metadata?.marketing
    );
  }, [
    isGLTab,
    selectedIntegration?.metadata?.cogs,
    selectedIntegration?.metadata?.marketing,
    selectedIntegration?.metadata?.sales,
  ]);

  if (!isGLTab) return <></>;

  return (
    <>
      <ConnectionStatus isConfigurationRequired={isConfigurationRequired} hasIntegration={!!selectedIntegration} />
      <GreyDot spacing="20px" />
    </>
  );
};

const ActionsSection = ({ activeTab, selectedIntegration, onSave }) => {
  if (activeTab !== CONFIGURATION_TABS.SOURCES) return <></>;

  return (
    <CentererVertical>
      <GLConnectionStatus activeTab={activeTab} selectedIntegration={selectedIntegration} />

      <Flexer gap="8px">
        <DisconnectIntegrationButton selectedIntegration={selectedIntegration} />

        <ReconnectIntegrationButton integration={selectedIntegration} />

        {!!onSave && (
          <StyledIconButton
            data-cy="configuration-header__save-button"
            style={{ display: 'flex' }}
            filled
            border
            color={COLORS.GREEN}
            icon={<SaveIcon />}
            iconFillColor="#FFF"
            onClick={onSave}
          >
            Save
          </StyledIconButton>
        )}
      </Flexer>
    </CentererVertical>
  );
};

export { ActionsSection };
