import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from 'AppContext';
import { useAnalytics } from 'utils/hooks';
import { ReactComponent as RepeatIcon } from 'images/repeat.svg';
import { ReactComponent as WalletIcon } from 'images/wallet.svg';
import { ClockIcon, SendIcon, ErrorCircleIcon, FileInvoiceIcon, ArchiveIcon } from 'components/Icons';
import { Button } from 'components/Buttons';
import { BILLING_PAGES_ROUTES, INVOICE_STATUSES } from 'views/Billing/consts';
import { EVENTS } from 'consts/analytics';
import { POPOVER_LINK_ICON_COLORS, PopoverLinkContents, PopoverLinkContentsItem } from '../PopoverLinkContents';
import { PopoverLinkContentsWrapper, Wrapper } from '../SubLinkPopovers/BillingConfigSubLinksPopover';
import { InvoicesCounter, OverdueWarning } from './styles';

export const InvoicesItem = ({ title, invoicesByStatusCount, onClose }) => {
  const history = useHistory();
  const { trackEvent } = useAnalytics();
  const { orgConfigs } = useContext(AppContext);
  const { billingReminderDefaults } = orgConfigs;
  const [isOpen, setIsOpen] = useState(false);

  const processingInvoicesCount =
    (invoicesByStatusCount[INVOICE_STATUSES.PROCESSING] ?? 0) +
    (invoicesByStatusCount[INVOICE_STATUSES.TAXES_PROCESSING] ?? 0);

  return (
    <Wrapper onMouseEnter={() => setIsOpen(true)} onMouseLeave={() => setIsOpen(false)}>
      <PopoverLinkContentsItem
        title={title}
        item={{
          title: 'All Invoices',
          titleSuffix: invoicesByStatusCount[INVOICE_STATUSES.ALL] ? (
            <InvoicesCounter>{invoicesByStatusCount[INVOICE_STATUSES.ALL]}</InvoicesCounter>
          ) : null,
          description: 'See invoices with all statuses',
          to: {
            pathname: BILLING_PAGES_ROUTES.INVOICES,
            state: { status: INVOICE_STATUSES.ALL },
          },
          icon: <FileInvoiceIcon />,
        }}
        onClick={() => {
          trackEvent({ name: EVENTS.CLICK_BILLING_NEW_INVOICES });
          onClose();
        }}
      />

      {isOpen && (
        <PopoverLinkContentsWrapper width="290px" left="280px">
          <PopoverLinkContents title="Invoices status" minWidth="100%">
            {invoicesByStatusCount[INVOICE_STATUSES.ALL_FAILED] > 0 && (
              <PopoverLinkContentsItem
                iconColor={POPOVER_LINK_ICON_COLORS.RED}
                title={title}
                item={{
                  title: 'Failed Invoices',
                  titleSuffix: (
                    <InvoicesCounter data-cy="billing-popover__all-failed-invoices-counter" red>
                      {invoicesByStatusCount[INVOICE_STATUSES.ALL_FAILED]}
                    </InvoicesCounter>
                  ),
                  icon: <ErrorCircleIcon />,
                  to: {
                    pathname: BILLING_PAGES_ROUTES.INVOICES,
                    state: { status: INVOICE_STATUSES.ALL_FAILED },
                  },
                }}
                onClick={() => {
                  trackEvent({ name: EVENTS.CLICK_BILLING_ALL_FAILED_INVOICES });
                  onClose();
                }}
              />
            )}

            {invoicesByStatusCount[INVOICE_STATUSES.QUEUED_UP] > 0 && (
              <PopoverLinkContentsItem
                iconColor={POPOVER_LINK_ICON_COLORS.RED}
                title={title}
                item={{
                  title: 'Queued Up Invoices',
                  titleSuffix: (
                    <InvoicesCounter data-cy="billing-popover__queued_up-invoices-counter" red>
                      {invoicesByStatusCount[INVOICE_STATUSES.QUEUED_UP]}
                    </InvoicesCounter>
                  ),
                  icon: <ErrorCircleIcon />,
                  to: {
                    pathname: BILLING_PAGES_ROUTES.INVOICES,
                    state: { status: INVOICE_STATUSES.QUEUED_UP },
                  },
                }}
                onClick={() => {
                  trackEvent({ name: EVENTS.CLICK_BILLING_QUEUED_UP_INVOICES });
                  onClose();
                }}
              />
            )}

            {processingInvoicesCount > 0 && (
              <PopoverLinkContentsItem
                iconColor={POPOVER_LINK_ICON_COLORS.RED}
                title={title}
                item={{
                  title: 'In Processing Invoices',
                  titleSuffix: (
                    <InvoicesCounter data-cy="billing-popover__processing-invoices-counter" red>
                      {processingInvoicesCount}
                    </InvoicesCounter>
                  ),
                  icon: <ErrorCircleIcon />,
                  to: {
                    pathname: BILLING_PAGES_ROUTES.INVOICES,
                    state: { status: INVOICE_STATUSES.PROCESSING },
                  },
                }}
                onClick={() => {
                  trackEvent({ name: EVENTS.CLICK_BILLING_PROCESSING_INVOICES });
                  onClose();
                }}
              />
            )}

            <PopoverLinkContentsItem
              iconColor={POPOVER_LINK_ICON_COLORS.GRAY}
              title={title}
              item={{
                title: 'All Unsent Invoices',
                titleSuffix: invoicesByStatusCount[INVOICE_STATUSES.UNSENT] ? (
                  <InvoicesCounter data-cy="billing-popover__unsent-invoices-counter">
                    {invoicesByStatusCount[INVOICE_STATUSES.UNSENT]}
                  </InvoicesCounter>
                ) : null,
                icon: <SendIcon />,
                to: {
                  pathname: BILLING_PAGES_ROUTES.INVOICES,
                  state: { status: INVOICE_STATUSES.UNSENT },
                },
              }}
              onClick={() => {
                trackEvent({ name: EVENTS.CLICK_BILLING_NEW_INVOICES });
                onClose();
              }}
            />

            <PopoverLinkContentsItem
              iconColor={POPOVER_LINK_ICON_COLORS.GRAY}
              title={title}
              item={{
                title: 'Auto-charged invoices',
                titleSuffix: invoicesByStatusCount[INVOICE_STATUSES.AUTO_CHARGE] ? (
                  <InvoicesCounter>{invoicesByStatusCount[INVOICE_STATUSES.AUTO_CHARGE]}</InvoicesCounter>
                ) : null,
                icon: <RepeatIcon />,
                to: {
                  pathname: BILLING_PAGES_ROUTES.INVOICES,
                  state: { status: INVOICE_STATUSES.AUTO_CHARGE },
                },
              }}
              onClick={() => {
                trackEvent({ name: EVENTS.CLICK_BILLING_AUTO_CHARGE_INVOICES });
                onClose();
              }}
            />

            <PopoverLinkContentsItem
              iconColor={POPOVER_LINK_ICON_COLORS.GRAY}
              title={title}
              item={{
                icon: <ClockIcon />,
                title: 'Awaiting Payment',
                titleSuffix: invoicesByStatusCount[INVOICE_STATUSES.SENT] ? (
                  <InvoicesCounter>{invoicesByStatusCount[INVOICE_STATUSES.SENT]}</InvoicesCounter>
                ) : null,
                to: {
                  pathname: BILLING_PAGES_ROUTES.INVOICES,
                  state: { status: INVOICE_STATUSES.SENT },
                },
              }}
              onClick={() => {
                trackEvent({ name: EVENTS.CLICK_BILLING_AWAITING_PAYMENT_INVOICES });
                onClose();
              }}
            />

            {billingReminderDefaults?.enabled && invoicesByStatusCount[INVOICE_STATUSES.OVERDUE] > 0 && (
              <OverdueWarning
                to={{ pathname: BILLING_PAGES_ROUTES.INVOICES, state: { status: INVOICE_STATUSES.SENT } }}
                onClick={() => {
                  trackEvent({ name: EVENTS.CLICK_BILLING_OVERDUE_INVOICES });
                  onClose();
                }}
              >
                reminders for{' '}
                <span>{`${invoicesByStatusCount[INVOICE_STATUSES.OVERDUE]} overdue invoice${
                  invoicesByStatusCount[INVOICE_STATUSES.OVERDUE] > 1 ? 's' : ''
                }`}</span>
              </OverdueWarning>
            )}

            <PopoverLinkContentsItem
              iconColor={POPOVER_LINK_ICON_COLORS.GRAY}
              title={title}
              item={{
                icon: <WalletIcon />,
                title: 'Paid Invoices',
                titleSuffix: invoicesByStatusCount[INVOICE_STATUSES.PAID] ? (
                  <InvoicesCounter>{invoicesByStatusCount[INVOICE_STATUSES.PAID]}</InvoicesCounter>
                ) : null,
                to: {
                  pathname: BILLING_PAGES_ROUTES.INVOICES,
                  state: { status: INVOICE_STATUSES.PAID },
                },
              }}
              onClick={() => {
                trackEvent({ name: EVENTS.CLICK_BILLING_PAID_INVOICES });
                onClose();
              }}
            />

            <PopoverLinkContentsItem
              iconColor={POPOVER_LINK_ICON_COLORS.GRAY}
              title={title}
              item={{
                icon: <ArchiveIcon />,
                title: 'Voided Invoices',
                titleSuffix: invoicesByStatusCount[INVOICE_STATUSES.VOID] ? (
                  <InvoicesCounter>{invoicesByStatusCount[INVOICE_STATUSES.VOID]}</InvoicesCounter>
                ) : null,
                to: {
                  pathname: BILLING_PAGES_ROUTES.INVOICES,
                  state: { status: INVOICE_STATUSES.VOID },
                },
              }}
              onClick={() => {
                trackEvent({ name: EVENTS.CLICK_BILLING_VOID_INVOICES });
                onClose();
              }}
            />

            <Button
              width="100%"
              fontWeight="700"
              onClick={() => {
                history.push(BILLING_PAGES_ROUTES.INVOICES, { status: INVOICE_STATUSES.ALL });
                onClose();
              }}
            >
              View All Invoices
            </Button>
          </PopoverLinkContents>
        </PopoverLinkContentsWrapper>
      )}
    </Wrapper>
  );
};
