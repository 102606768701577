import React, { Fragment, useEffect, useRef, useState } from 'react';
import Select, { components } from 'react-select';
import { Checkbox } from 'antd';
import styled from 'styled-components';
import { Column, Row, Spacer } from 'components/Core';
import { INTEGRATION_SERVICES } from 'consts/integrations';
import { RECOGNITION_TYPES } from 'consts/global';
import { TruncateStringWithTooltip } from 'components/Tooltip';
import { ReactComponent as TagsIcon } from 'images/route.svg';
import { ReactComponent as SelectIcon } from 'images/wizard-search.svg';
import { ReactComponent as ClockIcon } from 'images/clock.svg';
import { ReactComponent as CoinIcon } from 'images/coin-wizard.svg';
import { ReactComponent as AlertIcon } from 'images/alert-triangle.svg';
import { ReactComponent as SumIcon } from 'images/sum.svg';
import { ReactComponent as TrashIcon } from 'images/trash-wizard-selector.svg';
import exampleDealImg from 'images/Saly-27.png';
import {
  FieldBody,
  FieldTitle,
  FieldCount,
  CheckboxLabel,
  FieldTag,
  fieldSelectorStyles,
  SelectedItems,
  SelectedItem,
  CircleCheckbox,
  ConfigPageDice,
} from '../styles';
import {
  calcDealFieldCount,
  getExampleDealTopPosition,
  getExampleFieldComponent,
  getExampleDealFieldValue,
  getExampleLineItemFieldValue,
  getSelectorOptions,
} from '../utils';

const DealsWrapper = styled.div`
  padding: 0 100px;
  position: relative;

  opacity: ${({ disabled }) => disabled && '0.7'};
  pointer-events: ${({ disabled }) => disabled && 'none'};
`;

const DealsSubtitle = styled.div`
  font-size: 14px;
  line-height: 20px;
  opacity: 0.5;
  text-align: center;
`;

const ExampleTitle = styled.div`
  font-size: 12px;
  line-height: 16px;
  margin-top: 16px;
  margin-bottom: 8px;
`;

const ExampleLink = styled.div`
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  text-decoration: none;
  display: flex;
  align-items: center;

  svg {
    margin-left: 4px;
  }
`;

const ExampleDeal = styled.div`
  padding: 20px;
  background: var(--primaryBlack2);
  border-radius: 20px;
  width: 221px;
  left: -190px;
  top: ${({ top }) => top};
  position: absolute;
`;

const TagsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const TagsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const TagsTitle = styled.div`
  display: flex;
  align-items: center;
  color: var(--primaryBlack50);

  svg {
    margin-right: 8px;
  }
`;

const Line = styled.div`
  position: relative;
  width: 770px;
  left: -28px;
  height: 1px;
  background-color: var(--primaryBlack3);
  margin: 24px 0;
`;

const DealsTitle = styled.div`
  font-size: 30px;
  line-height: 44px;
  text-align: center;
  margin-top: 16px;
  margin-bottom: 40px;

  span {
    font-weight: 900;
    color: var(--primaryGreen);
  }
`;

const CustomSelector = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const ExampleDealFields = styled.div`
  font-size: 12px;
  line-height: 16px;
  border-top: 1px solid var(--primaryBlack3);
  margin-top: 12px;
`;

const CustomOption = styled.div`
  display: flex;
  padding: 6px 10px;
  align-items: center;

  &:hover {
    cursor: pointer;
    background-color: rgba(0, 117, 255, 0.1);
  }
`;

const CustomOptionKey = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
`;

const SkipWarning = styled.div`
  padding-top: 24px;
  display: flex;
  align-items: center;
  border-top: 1px solid var(--primaryBlack3);

  span {
    display: flex;
    align-items: center;
    color: var(--secondaryYellow);
    margin-right: 8px;

    svg {
      margin-right: 8px;
    }
  }
`;

const NoExampleFields = styled.div`
  color: var(--primaryBlack30);
  padding-top: 12px;
  font-size: 12px;
  line-height: 16px;
  border-top: 1px solid var(--primaryBlack3);
  margin-top: 12px;
`;

const ExampleDealRow = styled(Row)`
  margin-top: 12px;

  span {
    font-weight: bold;
  }
`;

const CustomOptionValue = styled.div`
  opacity: 0.5;
  margin-left: 8px;
`;

const SelectorWithTitle = styled.div`
  width: 100%;
  margin-right: ${({ noMarginRight }) => !noMarginRight && '24px'};
`;

const SelectorTitle = styled.div`
  color: var(--primaryBlack50);
  margin-bottom: 12px;
  display: flex;
  align-items: center;

  svg {
    margin-right: 8px;
  }
`;

const SubText = styled.span`
  color: var(--primaryBlack50);
  margin-top: 8px;
  margin-left: 32px;
  margin-bottom: 20px;
`;

const { Control, Option, SingleValue } = components;

export const DEAL_OR_LINE_ITEM_CONSTS = {
  LINE_ITEMS: 'line-items',
  DEALS: 'deals',
  BOTH: 'both',
};

export const CustomSingleOption = (props) => (
  <Option {...props}>
    <CustomOption>
      <CustomOptionKey>{props.data.label}</CustomOptionKey>
      <CustomOptionValue>{props.data.exampleValue}</CustomOptionValue>
    </CustomOption>
  </Option>
);

export const IconControl = (props) => (
  <Control {...props}>
    <CustomSelector>
      <SelectIcon />
      <Spacer width="8px" />
      {props.children}
    </CustomSelector>
  </Control>
);

export const IconSingleValue = (props) => (
  <SingleValue {...props}>
    <Row>
      <CustomOptionKey>{props.data.label}</CustomOptionKey>
      <CustomOptionValue>{props.data.value_label}</CustomOptionValue>
    </Row>
  </SingleValue>
);

export const Deals = ({
  configPageView,
  configSelectedIntegration,
  user,
  defaultTransactionRecognition,
  setDefaultTransactionRecognition,
  closedWonStages,
  setClosedWonStages,
  transactionMetadataOptions,
  setTransactionMetadataOptions,
  internalId,
  setInternald,
  totalValueFieldDeal,
  setTotalValueFieldDeal,
  totalValueFieldLineItem,
  setTotalValueFieldLineItem,
  startDateFieldDeal,
  setStartDateFieldDeal,
  startDateFieldLineItem,
  setStartDateFieldLineItem,
  endDateFieldDeal,
  setEndDateFieldDeal,
  endDateFieldLineItem,
  setEndDateFieldLineItem,
  seatsFieldDeal,
  setSeatsFieldDeal,
  seatsFieldLineItem,
  setSeatsFieldLineItem,
  isTillCanceledMrr,
  setIsTillCanceledMrr,
  integrationObjectFields,
  pipelinesAndStages,
  exampleIntegrationObjects,
  setReachedPage,
  dealsOrLineItems,
  setDealsOrLineItems,
  skipDailySync,
  setSkipDailySync,
  currencyISOField,
  setCurrencyISOField,
}) => {
  const [exampleDealTopPosition, setExampleDealTopPosition] = useState();

  const crmFields = integrationObjectFields.CRM;

  const integrationCRM = configSelectedIntegration?.type === 'CRM';

  const hasHubspotIntegration = configSelectedIntegration?.service === INTEGRATION_SERVICES.HUBSPOT;

  const fieldsWrapperRef = useRef();

  const allFieldsUndefined = [
    totalValueFieldDeal,
    totalValueFieldLineItem,
    startDateFieldDeal,
    startDateFieldLineItem,
    endDateFieldDeal,
    endDateFieldLineItem,
    seatsFieldDeal,
    seatsFieldLineItem,
    internalId,
  ].every((field) => field === undefined);

  useEffect(() => {
    setExampleDealTopPosition(
      getExampleDealTopPosition({
        defaultTransactionRecognition,
        current: fieldsWrapperRef?.current,
        amount: totalValueFieldDeal,
        lineAmount: totalValueFieldLineItem,
        start: startDateFieldDeal,
        lineStart: startDateFieldLineItem,
        end: endDateFieldDeal,
        lineEnd: endDateFieldLineItem,
        seats: seatsFieldDeal,
        lineSeats: seatsFieldLineItem,
      }),
    );
  }, [
    fieldsWrapperRef,
    defaultTransactionRecognition,
    endDateFieldDeal,
    endDateFieldLineItem,
    seatsFieldDeal,
    seatsFieldLineItem,
    startDateFieldDeal,
    startDateFieldLineItem,
    totalValueFieldDeal,
    totalValueFieldLineItem,
  ]);

  useEffect(() => {
    setReachedPage(2);
  }, [setReachedPage]);

  const isItemsDealsOrBoth =
    dealsOrLineItems === DEAL_OR_LINE_ITEM_CONSTS.DEALS || dealsOrLineItems === DEAL_OR_LINE_ITEM_CONSTS.BOTH;

  const dealOrOpportunity = hasHubspotIntegration ? 'deal' : 'opportunity';

  let exampleNameField;
  let exampleIdField;
  if (hasHubspotIntegration) {
    // hubspot
    if (isItemsDealsOrBoth) {
      // deal
      exampleNameField = 'dealname';
      exampleIdField = 'dealId';
    } else {
      // line-item
      exampleNameField = 'name';
      exampleIdField = 'lineItemId';
    }
  } else {
    // salesforce
    exampleNameField = 'Name';
    exampleIdField = 'Id';
  }

  const dealOrLineItem = isItemsDealsOrBoth ? 'Deal' : 'Line Item';
  const exampleObjectFunction = isItemsDealsOrBoth ? getExampleDealFieldValue : getExampleLineItemFieldValue;

  return (
    <DealsWrapper data-cy="wizard__deals-config" disabled={configPageView} ref={fieldsWrapperRef}>
      {defaultTransactionRecognition !== undefined && !configPageView && (
        <ExampleDeal top={exampleDealTopPosition} data-cy="wizard__example-deal">
          <img src={exampleDealImg} alt="example" width="88px" height="32px" />

          <ExampleTitle>How Subscript will see your deal:</ExampleTitle>

          <ExampleLink>{user.company}</ExampleLink>

          {exampleIntegrationObjects && (
            <>
              <ExampleTitle>Example {dealOrLineItem} Name:</ExampleTitle>
              <ExampleLink>
                {exampleObjectFunction({ field: exampleNameField, exampleIntegrationObjects }) ?? 'No Name'}
              </ExampleLink>
              <ExampleTitle>Example {dealOrLineItem} Id:</ExampleTitle>
              <ExampleLink>
                {exampleObjectFunction({ field: exampleIdField, exampleIntegrationObjects }) ?? 'No Id'}
              </ExampleLink>
            </>
          )}

          {allFieldsUndefined && <NoExampleFields>Please answer questions...</NoExampleFields>}

          <ExampleDealFields>
            {(totalValueFieldDeal !== undefined || totalValueFieldLineItem !== undefined) && (
              <ExampleDealRow horizontal="space-between">
                Total
                <span>
                  <TruncateStringWithTooltip length={15} tooltipWidth={200}>
                    {getExampleFieldComponent({
                      dealField: totalValueFieldDeal,
                      lineItemField: totalValueFieldLineItem,
                      exampleIntegrationObjects,
                    })}
                  </TruncateStringWithTooltip>
                </span>
              </ExampleDealRow>
            )}

            {(startDateFieldDeal !== undefined || startDateFieldLineItem !== undefined) && (
              <ExampleDealRow horizontal="space-between">
                Start
                <span>
                  <TruncateStringWithTooltip length={15} tooltipWidth={200}>
                    {getExampleFieldComponent({
                      dealField: startDateFieldDeal,
                      lineItemField: startDateFieldLineItem,
                      exampleIntegrationObjects,
                    })}
                  </TruncateStringWithTooltip>
                </span>
              </ExampleDealRow>
            )}

            {(endDateFieldDeal !== undefined || endDateFieldLineItem !== undefined) && (
              <ExampleDealRow horizontal="space-between">
                End
                <span>
                  <TruncateStringWithTooltip length={10} tooltipWidth={200}>
                    {getExampleFieldComponent({
                      dealField: endDateFieldDeal,
                      lineItemField: endDateFieldLineItem,
                      exampleIntegrationObjects,
                    })}
                  </TruncateStringWithTooltip>
                </span>
              </ExampleDealRow>
            )}

            {(seatsFieldDeal !== undefined || seatsFieldLineItem !== undefined) && (
              <ExampleDealRow horizontal="space-between">
                Seats
                <span>
                  <TruncateStringWithTooltip length={15} tooltipWidth={200}>
                    {getExampleFieldComponent({
                      dealField: seatsFieldDeal,
                      lineItemField: seatsFieldLineItem,
                      exampleIntegrationObjects,
                    })}
                  </TruncateStringWithTooltip>
                </span>
              </ExampleDealRow>
            )}

            {internalId !== undefined && (
              <ExampleDealRow horizontal="space-between">
                ID
                <span>
                  <TruncateStringWithTooltip length={10} tooltipWidth={200}>
                    {getExampleFieldComponent({
                      dealField: internalId,
                      lineItemField: undefined,
                      exampleIntegrationObjects,
                    })}
                  </TruncateStringWithTooltip>
                </span>
              </ExampleDealRow>
            )}
          </ExampleDealFields>
        </ExampleDeal>
      )}

      {!configPageView ? (
        <>
          <DealsSubtitle>Pick the correct word for the CRM</DealsSubtitle>
          <DealsTitle>
            Tell us about your <span>deals</span>
          </DealsTitle>
        </>
      ) : (
        <>
          <ConfigPageDice>
            <span>{hasHubspotIntegration ? 'Hubspot' : 'Salesforce'} integration</span>
            {calcDealFieldCount({ integrationCRM, initalCount: 10, defaultTransactionRecognition })} questions
          </ConfigPageDice>
        </>
      )}

      {/* Default recognition */}
      <FieldBody>
        <FieldCount configPageView={configPageView}>1</FieldCount>
        <FieldTitle>Do you create a new {dealOrOpportunity} for each renewal that comes up?</FieldTitle>

        <Row horizontal="space-between">
          <Row onClick={() => setDefaultTransactionRecognition(RECOGNITION_TYPES.linear)}>
            <Checkbox
              data-cy="wizard__checkbox--select-linear"
              checked={defaultTransactionRecognition === RECOGNITION_TYPES.linear}
            />
            <CheckboxLabel>Yes</CheckboxLabel>
          </Row>
          <Row onClick={() => setDefaultTransactionRecognition(RECOGNITION_TYPES.tillCanceled)}>
            <Checkbox
              data-cy="wizard__checkbox--select-till-canceled"
              checked={defaultTransactionRecognition === RECOGNITION_TYPES.tillCanceled}
            />
            <CheckboxLabel>No, because they auto renew</CheckboxLabel>
          </Row>
          <Row onClick={() => setDefaultTransactionRecognition('linear:2')}>
            <Checkbox
              data-cy="wizard__checkbox--select-linear-2"
              checked={defaultTransactionRecognition === 'linear:2'}
            />
            <CheckboxLabel>No, but they don't auto renew</CheckboxLabel>
          </Row>
          <Row onClick={() => setDefaultTransactionRecognition(null)}>
            <Checkbox
              data-cy="wizard__checkbox--select-no-recognition"
              checked={defaultTransactionRecognition === null}
            />
            <CheckboxLabel>It depends</CheckboxLabel>
          </Row>
        </Row>
      </FieldBody>

      {/* Closed stages */}
      {hasHubspotIntegration && (
        <FieldBody>
          <FieldCount configPageView={configPageView}>2</FieldCount>
          <FieldTitle>
            <span>
              Which of these stages represent <b>deals that are won?</b>
            </span>
          </FieldTitle>
          {Object.entries(pipelinesAndStages).map(([pipelineName, pipelineStages], index, array) => (
            <Fragment key={pipelineName}>
              <TagsContainer>
                <TagsTitle>
                  <TagsIcon /> {pipelineName}:
                </TagsTitle>
                <TagsWrapper>
                  {pipelineStages.map((stage) => (
                    <FieldTag
                      key={stage.id}
                      data-cy={`wizard__stage-${stage.id}`}
                      selected={closedWonStages.includes(stage.id)}
                      onClick={() => {
                        if (closedWonStages.includes(stage.id)) {
                          setClosedWonStages(closedWonStages.filter((closedStage) => closedStage !== stage?.id));
                        } else {
                          setClosedWonStages([...closedWonStages, stage.id]);
                        }
                      }}
                    >
                      {stage.label}
                    </FieldTag>
                  ))}
                </TagsWrapper>
              </TagsContainer>

              {index < array.length - 1 && <Line />}
            </Fragment>
          ))}
        </FieldBody>
      )}

      {/* Deal or line item fields (or both) */}
      <FieldBody>
        <FieldCount configPageView={configPageView}>{integrationCRM?.service === 'hubspot' ? '3' : '2'}</FieldCount>
        <FieldTitle>
          <span>Do you use line items in your deals?</span>
        </FieldTitle>

        <Column horizontal="flex-start">
          <Row onClick={() => setDealsOrLineItems(DEAL_OR_LINE_ITEM_CONSTS.DEALS)} style={{ cursor: 'pointer' }}>
            <Column horizontal="flex-start">
              <Row>
                <CircleCheckbox
                  data-cy="wizard__checkbox--no-line-items"
                  checked={dealsOrLineItems === DEAL_OR_LINE_ITEM_CONSTS.DEALS}
                />
                <span>No, we don't use it</span>
              </Row>
              <SubText>In the next steps you will need to select the fields that represent only deal level</SubText>
            </Column>
          </Row>

          <Row onClick={() => setDealsOrLineItems(DEAL_OR_LINE_ITEM_CONSTS.LINE_ITEMS)} style={{ cursor: 'pointer' }}>
            <Column horizontal="flex-start">
              <Row>
                <CircleCheckbox
                  data-cy="wizard__checkbox--all-line-items"
                  checked={dealsOrLineItems === DEAL_OR_LINE_ITEM_CONSTS.LINE_ITEMS}
                />
                <span>
                  Yes, <b>in all deals</b> we use line items
                </span>
              </Row>
              <SubText>
                In the next steps you will need to select the fields that represent only line item level
              </SubText>
            </Column>
          </Row>

          <Row onClick={() => setDealsOrLineItems(DEAL_OR_LINE_ITEM_CONSTS.BOTH)} style={{ cursor: 'pointer' }}>
            <Column horizontal="flex-start">
              <Row>
                <CircleCheckbox
                  data-cy="wizard__checkbox--both-line-items"
                  checked={dealsOrLineItems === DEAL_OR_LINE_ITEM_CONSTS.BOTH}
                />
                <span>
                  Yes, but we use line items <b>only for some deals</b>
                </span>
              </Row>
              <SubText>
                In the next steps you will need to select the fields that represent both the deal level and the line
                item level. If the deal does not have line items, Subscript will use only mappings of deal level
              </SubText>
            </Column>
          </Row>
        </Column>
      </FieldBody>

      {/* Total Amount */}
      <div id="amount-field">
        {defaultTransactionRecognition !== RECOGNITION_TYPES.tillCanceled && (
          <FieldBody>
            <FieldCount configPageView={configPageView}>{hasHubspotIntegration ? '4' : '3'}</FieldCount>
            <FieldTitle>
              <span>
                Which field represents <b>the total value of each {isItemsDealsOrBoth ? 'deal' : 'line item'}?</b>
              </span>
              <Row
                onClick={() => {
                  if (totalValueFieldDeal === null && totalValueFieldLineItem === null) {
                    setTotalValueFieldDeal(undefined);
                    setTotalValueFieldLineItem(undefined);
                  } else {
                    setTotalValueFieldDeal(null);
                    setTotalValueFieldLineItem(null);
                  }
                }}
              >
                <Checkbox
                  data-cy="wizard__checkbox--no-total"
                  checked={totalValueFieldDeal === null && totalValueFieldLineItem === null}
                />
                <CheckboxLabel>I'm not sure</CheckboxLabel>
              </Row>
            </FieldTitle>
            {totalValueFieldDeal === null && totalValueFieldLineItem === null ? (
              <SkipWarning>
                <span>
                  <AlertIcon />
                  Warning:
                </span>
                <span>You will need to set them manually for each {dealOrOpportunity}</span>
              </SkipWarning>
            ) : (
              <Row>
                {dealsOrLineItems !== DEAL_OR_LINE_ITEM_CONSTS.LINE_ITEMS && (
                  <SelectorWithTitle>
                    <SelectorTitle>
                      <CoinIcon />
                      Deal level:
                    </SelectorTitle>
                    <Select
                      menuPlacement="auto"
                      isClearable={true}
                      onChange={(selectedField) => {
                        setTotalValueFieldDeal(selectedField?.value ?? undefined);
                      }}
                      value={crmFields?.deal?.allFields?.find((option) => option.value === totalValueFieldDeal) ?? null}
                      menuPortalTarget={document.querySelector('body')}
                      styles={fieldSelectorStyles}
                      components={{ Control: IconControl, Option: CustomSingleOption, SingleValue: IconSingleValue }}
                      placeholder="Search..."
                      options={getSelectorOptions({
                        type: 'number',
                        fields: crmFields?.deal?.allFields ?? [],
                        isDealLevel: true,
                        exampleIntegrationObjects,
                      })}
                    />
                  </SelectorWithTitle>
                )}

                {dealsOrLineItems !== DEAL_OR_LINE_ITEM_CONSTS.DEALS && (
                  <SelectorWithTitle noMarginRight>
                    <SelectorTitle>
                      <CoinIcon />
                      Line item level:
                    </SelectorTitle>
                    <Select
                      menuPlacement="auto"
                      isClearable={true}
                      onChange={(selectedField) => {
                        setTotalValueFieldLineItem(selectedField?.value ?? undefined);
                      }}
                      value={
                        crmFields?.lineItem?.allFields?.find((option) => option.value === totalValueFieldLineItem) ??
                        (totalValueFieldLineItem
                          ? { label: totalValueFieldLineItem, value: totalValueFieldLineItem }
                          : null)
                      }
                      menuPortalTarget={document.querySelector('body')}
                      styles={fieldSelectorStyles}
                      components={{ Control: IconControl, Option: CustomSingleOption, SingleValue: IconSingleValue }}
                      placeholder="Search... "
                      options={getSelectorOptions({
                        type: 'number',
                        fields: crmFields?.lineItem?.allFields ?? [],
                        isDealLevel: false,
                        exampleIntegrationObjects,
                      })}
                    />
                  </SelectorWithTitle>
                )}
              </Row>
            )}
          </FieldBody>
        )}

        {/* Currency field */}
        <FieldBody>
          <FieldCount configPageView={configPageView}>{hasHubspotIntegration ? '4' : '3'}</FieldCount>
          <FieldTitle>
            <span>
              Which field represents <b>the currency (ISO code) of each {isItemsDealsOrBoth ? 'deal' : 'line item'}?</b>
            </span>
            <Row
              onClick={() => {
                if (currencyISOField === null) setCurrencyISOField(undefined);
                else setCurrencyISOField(null);
              }}
            >
              <Checkbox data-cy="wizard__checkbox--no-currency" checked={currencyISOField === null} />
              <CheckboxLabel>I'm not sure</CheckboxLabel>
            </Row>
          </FieldTitle>
          {currencyISOField !== null && (
            <Row>
              {dealsOrLineItems !== DEAL_OR_LINE_ITEM_CONSTS.LINE_ITEMS && (
                <SelectorWithTitle>
                  <Select
                    menuPlacement="auto"
                    isClearable={true}
                    onChange={(selectedField) => {
                      setCurrencyISOField(selectedField?.value ?? undefined);
                    }}
                    value={crmFields?.deal?.allFields?.find((option) => option.value === currencyISOField) ?? null}
                    menuPortalTarget={document.querySelector('body')}
                    styles={fieldSelectorStyles}
                    components={{ Control: IconControl, Option: CustomSingleOption, SingleValue: IconSingleValue }}
                    placeholder="Search..."
                    options={getSelectorOptions({
                      type: 'string',
                      fields: crmFields?.deal?.allFields ?? [],
                      isDealLevel: true,
                      exampleIntegrationObjects,
                    })}
                  />
                </SelectorWithTitle>
              )}
            </Row>
          )}
        </FieldBody>

        {/* Total field for till-cancelled */}
        {defaultTransactionRecognition === RECOGNITION_TYPES.tillCanceled && (
          <FieldBody>
            <FieldCount configPageView={configPageView}>{integrationCRM?.service === 'hubspot' ? '4' : '3'}</FieldCount>
            <FieldTitle>
              <span>
                Which field represents <b>the recurring revenue of each {isItemsDealsOrBoth ? 'deal' : 'line item'}?</b>
              </span>
              <Row
                onClick={() => {
                  if (totalValueFieldDeal === null && totalValueFieldLineItem === null) {
                    setTotalValueFieldDeal(undefined);
                    setTotalValueFieldLineItem(undefined);
                  } else {
                    setTotalValueFieldDeal(null);
                    setTotalValueFieldLineItem(null);
                  }
                }}
              >
                <Checkbox
                  data-cy="wizard__checkbox--no-total-till-canceled"
                  checked={totalValueFieldDeal === null && totalValueFieldLineItem === null}
                />
                <CheckboxLabel>I'm not sure</CheckboxLabel>
              </Row>
            </FieldTitle>
            {totalValueFieldDeal === null && totalValueFieldLineItem === null ? (
              <SkipWarning>
                <span>
                  <AlertIcon />
                  Warning:
                </span>
                <span>You will need to set them manually for each {dealOrOpportunity}</span>
              </SkipWarning>
            ) : (
              <>
                <Row>
                  {dealsOrLineItems !== DEAL_OR_LINE_ITEM_CONSTS.LINE_ITEMS && (
                    <SelectorWithTitle data-cy="wizard__total-selector">
                      <SelectorTitle>
                        <CoinIcon />
                        Deal level:
                      </SelectorTitle>
                      <Select
                        inputId="wizard__total-selector--input"
                        menuPlacement="auto"
                        isClearable={true}
                        onChange={(selectedField) => {
                          setTotalValueFieldDeal(selectedField?.value ?? undefined);
                        }}
                        value={
                          crmFields?.deal?.allFields?.find((option) => option.value === totalValueFieldDeal) ?? null
                        }
                        menuPortalTarget={document.querySelector('body')}
                        styles={fieldSelectorStyles}
                        components={{ Control: IconControl, Option: CustomSingleOption, SingleValue: IconSingleValue }}
                        placeholder="Search..."
                        options={getSelectorOptions({
                          type: 'number',
                          fields: crmFields?.deal?.allFields ?? [],
                          isDealLevel: true,
                          exampleIntegrationObjects,
                        })}
                      />
                    </SelectorWithTitle>
                  )}

                  {dealsOrLineItems !== DEAL_OR_LINE_ITEM_CONSTS.DEALS && (
                    <SelectorWithTitle noMarginRight>
                      <SelectorTitle>
                        <CoinIcon />
                        Line item level:
                      </SelectorTitle>
                      <Select
                        menuPlacement="auto"
                        isClearable={true}
                        onChange={(selectedField) => {
                          setTotalValueFieldLineItem(selectedField?.value ?? undefined);
                        }}
                        value={
                          crmFields?.lineItem?.allFields?.find((option) => option.value === totalValueFieldLineItem) ??
                          (totalValueFieldLineItem
                            ? { label: totalValueFieldLineItem, value: totalValueFieldLineItem }
                            : null)
                        }
                        menuPortalTarget={document.querySelector('body')}
                        styles={fieldSelectorStyles}
                        components={{ Control: IconControl, Option: CustomSingleOption, SingleValue: IconSingleValue }}
                        placeholder="Search... "
                        options={getSelectorOptions({
                          type: 'number',
                          fields: crmFields?.lineItem?.allFields ?? [],
                          isDealLevel: false,
                          exampleIntegrationObjects,
                        })}
                      />
                    </SelectorWithTitle>
                  )}
                </Row>

                <Line />

                <Row horizontal="space-between">
                  <span style={{ fontSize: 16 }}>What does it indicate?</span>
                  <Row>
                    <Row onClick={() => setIsTillCanceledMrr(true)} style={{ cursor: 'pointer' }}>
                      <CircleCheckbox checked={isTillCanceledMrr} /> MRR
                    </Row>

                    <Row onClick={() => setIsTillCanceledMrr(false)} style={{ cursor: 'pointer', marginLeft: 10 }}>
                      <CircleCheckbox checked={!isTillCanceledMrr} /> ARR
                    </Row>
                  </Row>
                </Row>
              </>
            )}
          </FieldBody>
        )}
      </div>

      {/* Start date */}
      <FieldBody id="start-field">
        <FieldCount configPageView={configPageView}>{integrationCRM?.service === 'hubspot' ? '5' : '4'}</FieldCount>
        <FieldTitle>
          <span>
            Which field shows when service for <b>a {isItemsDealsOrBoth ? 'deal' : 'line item'} starts?</b>
          </span>
          <Row
            onClick={() => {
              if (startDateFieldDeal === null && startDateFieldLineItem === null) {
                setStartDateFieldDeal(undefined);
                setStartDateFieldLineItem(undefined);
              } else {
                setStartDateFieldDeal(null);
                setStartDateFieldLineItem(null);
              }
            }}
          >
            <Checkbox
              data-cy="wizard__checkbox--no-start-date"
              checked={startDateFieldDeal === null && startDateFieldLineItem === null}
            />
            <CheckboxLabel>Skip it</CheckboxLabel>
          </Row>
        </FieldTitle>

        {startDateFieldDeal === null && startDateFieldLineItem === null ? (
          <SkipWarning>
            <span>
              <AlertIcon />
              Warning:
            </span>
            <span>You will need to set them manually for each {dealOrOpportunity}</span>
          </SkipWarning>
        ) : (
          <Row>
            {dealsOrLineItems !== DEAL_OR_LINE_ITEM_CONSTS.LINE_ITEMS && (
              <SelectorWithTitle data-cy="wizard__start-date-selector">
                <SelectorTitle>
                  <ClockIcon />
                  Deal level:
                </SelectorTitle>
                <Select
                  menuPlacement="auto"
                  isClearable={true}
                  inputId="wizard__start-date-selector--input"
                  onChange={(selectedField) => {
                    setStartDateFieldDeal(selectedField?.value ?? undefined);
                  }}
                  value={crmFields?.deal?.allFields?.find((option) => option.value === startDateFieldDeal) ?? null}
                  menuPortalTarget={document.querySelector('body')}
                  styles={fieldSelectorStyles}
                  components={{ Control: IconControl, Option: CustomSingleOption, SingleValue: IconSingleValue }}
                  placeholder="Search..."
                  options={getSelectorOptions({
                    type: 'date',
                    fields: crmFields?.deal?.allFields ?? [],
                    isDealLevel: true,
                    exampleIntegrationObjects,
                  })}
                />
              </SelectorWithTitle>
            )}

            {dealsOrLineItems !== DEAL_OR_LINE_ITEM_CONSTS.DEALS && (
              <SelectorWithTitle noMarginRight>
                <SelectorTitle>
                  <ClockIcon />
                  Line item level:
                </SelectorTitle>
                <Select
                  menuPlacement="auto"
                  isClearable={true}
                  onChange={(selectedField) => {
                    setStartDateFieldLineItem(selectedField?.value ?? undefined);
                  }}
                  value={
                    crmFields?.lineItem?.allFields?.find((option) => option.value === startDateFieldLineItem) ??
                    (startDateFieldLineItem ? { label: startDateFieldLineItem, value: startDateFieldLineItem } : null)
                  }
                  menuPortalTarget={document.querySelector('body')}
                  styles={fieldSelectorStyles}
                  components={{ Control: IconControl, Option: CustomSingleOption, SingleValue: IconSingleValue }}
                  placeholder="Search... "
                  options={getSelectorOptions({
                    type: 'date',
                    fields: crmFields?.lineItem?.allFields ?? [],
                    isDealLevel: false,
                    exampleIntegrationObjects,
                  })}
                />
              </SelectorWithTitle>
            )}
          </Row>
        )}
      </FieldBody>

      {/* End date */}
      {[RECOGNITION_TYPES.linear, RECOGNITION_TYPES.tillCanceled, 'linear:2'].includes(
        defaultTransactionRecognition,
      ) && (
        <FieldBody id="end-field">
          <FieldCount configPageView={configPageView}>{integrationCRM?.service === 'hubspot' ? '6' : '5'}</FieldCount>
          <FieldTitle>
            <span>
              Which field shows when service for{' '}
              <b>
                a {isItemsDealsOrBoth ? 'deal' : 'line item'}{' '}
                {defaultTransactionRecognition === RECOGNITION_TYPES.tillCanceled ? 'ended, if it has?' : 'ends?'}
              </b>
            </span>
            <Row
              onClick={() => {
                if (endDateFieldDeal === null && endDateFieldLineItem === null) {
                  setEndDateFieldDeal(undefined);
                  setEndDateFieldLineItem(undefined);
                } else {
                  setEndDateFieldDeal(null);
                  setEndDateFieldLineItem(null);
                }
              }}
            >
              <Checkbox
                data-cy="wizard__checkbox--no-end-date"
                checked={endDateFieldDeal === null && endDateFieldLineItem === null}
              />
              <CheckboxLabel>Skip it</CheckboxLabel>
            </Row>
          </FieldTitle>

          {endDateFieldDeal === null && endDateFieldLineItem === null ? (
            <SkipWarning>
              <span>
                <AlertIcon />
                Warning:
              </span>
              <span>You will need to set them manually for each {dealOrOpportunity}</span>
            </SkipWarning>
          ) : (
            <Row>
              {dealsOrLineItems !== DEAL_OR_LINE_ITEM_CONSTS.LINE_ITEMS && (
                <SelectorWithTitle>
                  <SelectorTitle>
                    <ClockIcon />
                    Deal level:
                  </SelectorTitle>
                  <Select
                    menuPlacement="auto"
                    isClearable={true}
                    onChange={(selectedField) => {
                      setEndDateFieldDeal(selectedField?.value ?? undefined);
                    }}
                    value={crmFields?.deal?.allFields?.find((option) => option.value === endDateFieldDeal) ?? null}
                    menuPortalTarget={document.querySelector('body')}
                    styles={fieldSelectorStyles}
                    components={{ Control: IconControl, Option: CustomSingleOption, SingleValue: IconSingleValue }}
                    placeholder="Search..."
                    options={getSelectorOptions({
                      type: 'date',
                      fields: crmFields?.deal?.allFields ?? [],
                      isDealLevel: true,
                      exampleIntegrationObjects,
                    })}
                  />
                </SelectorWithTitle>
              )}

              {dealsOrLineItems !== DEAL_OR_LINE_ITEM_CONSTS.DEALS && (
                <SelectorWithTitle noMarginRight>
                  <SelectorTitle>
                    <ClockIcon />
                    Line item level:
                  </SelectorTitle>
                  <Select
                    menuPlacement="auto"
                    isClearable={true}
                    onChange={(selectedField) => {
                      setEndDateFieldLineItem(selectedField?.value ?? undefined);
                    }}
                    value={
                      crmFields?.lineItem?.allFields?.find((option) => option.value === endDateFieldLineItem) ??
                      (endDateFieldLineItem ? { label: endDateFieldLineItem, value: endDateFieldLineItem } : null)
                    }
                    menuPortalTarget={document.querySelector('body')}
                    styles={fieldSelectorStyles}
                    components={{ Control: IconControl, Option: CustomSingleOption, SingleValue: IconSingleValue }}
                    placeholder="Search... "
                    options={getSelectorOptions({
                      type: 'date',
                      fields: crmFields?.lineItem?.allFields ?? [],
                      isDealLevel: false,
                      exampleIntegrationObjects,
                    })}
                  />
                </SelectorWithTitle>
              )}
            </Row>
          )}
        </FieldBody>
      )}

      {/* Seats */}
      <FieldBody id="seats-field">
        <FieldCount configPageView={configPageView}>
          {calcDealFieldCount({ integrationCRM, initalCount: 7, defaultTransactionRecognition })}
        </FieldCount>
        <FieldTitle>
          <span>
            Which fields represent <b>quantity or seats that are sold?</b>
          </span>
          <Row
            onClick={() => {
              if (seatsFieldDeal === null && seatsFieldLineItem === null) {
                setSeatsFieldDeal(undefined);
                setSeatsFieldLineItem(undefined);
              } else {
                setSeatsFieldDeal(null);
                setSeatsFieldLineItem(null);
              }
            }}
          >
            <Checkbox
              data-cy="wizard__checkbox--no-seats"
              checked={seatsFieldDeal === null && seatsFieldLineItem === null}
            />
            <CheckboxLabel>None of them</CheckboxLabel>
          </Row>
        </FieldTitle>

        {seatsFieldDeal === null && seatsFieldLineItem === null ? (
          <SkipWarning>
            <span>
              <AlertIcon />
              Warning:
            </span>
            <span>You will need to set them manually for each {dealOrOpportunity}</span>
          </SkipWarning>
        ) : (
          <>
            {dealsOrLineItems !== DEAL_OR_LINE_ITEM_CONSTS.LINE_ITEMS && (
              <SelectorWithTitle data-cy="wizard__seats-selector">
                <SelectorTitle>
                  <SumIcon />
                  Deal level:
                </SelectorTitle>
                <Select
                  menuPlacement="auto"
                  isClearable={true}
                  inputId="wizard__seats-selector--input"
                  onChange={(selectedField) => {
                    setSeatsFieldDeal(selectedField?.value ?? undefined);
                  }}
                  value={crmFields?.deal?.allFields?.find((option) => option.value === seatsFieldDeal) ?? null}
                  menuPortalTarget={document.querySelector('body')}
                  styles={fieldSelectorStyles}
                  components={{ Control: IconControl, Option: CustomSingleOption, SingleValue: IconSingleValue }}
                  placeholder="Search... "
                  options={getSelectorOptions({
                    type: 'number',
                    fields: crmFields?.deal?.allFields ?? [],
                    isDealLevel: true,
                    exampleIntegrationObjects,
                  })}
                />
              </SelectorWithTitle>
            )}

            {dealsOrLineItems !== DEAL_OR_LINE_ITEM_CONSTS.DEALS && (
              <SelectorWithTitle style={{ marginTop: 24 }}>
                <SelectorTitle>
                  <SumIcon />
                  Line item level:
                </SelectorTitle>
                <Select
                  menuPlacement="auto"
                  isClearable={true}
                  onChange={(selectedField) => {
                    setSeatsFieldLineItem(selectedField?.value ?? undefined);
                  }}
                  value={
                    crmFields?.lineItem?.allFields?.find((option) => option.value === seatsFieldLineItem) ??
                    (seatsFieldLineItem ? { label: seatsFieldLineItem, value: seatsFieldLineItem } : null)
                  }
                  menuPortalTarget={document.querySelector('body')}
                  styles={fieldSelectorStyles}
                  components={{ Control: IconControl, Option: CustomSingleOption, SingleValue: IconSingleValue }}
                  placeholder="Search... "
                  options={getSelectorOptions({
                    type: 'number',
                    fields: crmFields?.lineItem?.allFields ?? [],
                    isDealLevel: false,
                    exampleIntegrationObjects,
                  })}
                />
              </SelectorWithTitle>
            )}
          </>
        )}
      </FieldBody>

      {/* Metadata fields */}
      <FieldBody>
        <FieldCount configPageView={configPageView}>
          {calcDealFieldCount({ integrationCRM, initalCount: 8, defaultTransactionRecognition })}
        </FieldCount>
        <FieldTitle>
          What are fields on the {isItemsDealsOrBoth ? 'deal' : 'line item'} that it would help to slice and dice
          analysis by in Subscript?
        </FieldTitle>

        <Select
          menuPlacement="auto"
          isClearable={true}
          isMulti
          inputId="wizard__metadata-selector--input"
          onChange={(selectedField) => {
            setTransactionMetadataOptions([...new Set([...transactionMetadataOptions, selectedField[0].value])]);
          }}
          value={null}
          menuPortalTarget={document.querySelector('body')}
          styles={fieldSelectorStyles}
          components={{ Control: IconControl, Option: CustomSingleOption, SingleValue: IconSingleValue }}
          placeholder="You can add multiple fields..."
          options={getSelectorOptions({
            fields: crmFields?.deal?.allFields ?? [],
            isDealLevel: true,
            exampleIntegrationObjects,
          })}
        />
        <SelectedItems>
          {transactionMetadataOptions.map((option) => (
            <SelectedItem key={option}>
              {crmFields?.deal?.allFields.find((field) => field.value === option)?.label ?? option}
              <TrashIcon
                onClick={() =>
                  setTransactionMetadataOptions(
                    transactionMetadataOptions.filter((metadataOption) => metadataOption !== option),
                  )
                }
              />
            </SelectedItem>
          ))}
        </SelectedItems>
      </FieldBody>

      {/* Internal id */}
      <FieldBody id="internal-id-field">
        <FieldCount configPageView={configPageView}>
          {calcDealFieldCount({ integrationCRM, initalCount: 9, defaultTransactionRecognition })}
        </FieldCount>
        <FieldTitle>
          <span>
            Is there a field that represents <b>an internal database id</b> on your deals?
          </span>

          <Row
            onClick={() => {
              if (internalId === null) {
                setInternald(undefined);
              } else {
                setInternald(null);
              }
            }}
          >
            <Checkbox data-cy="wizard__checkbox--no-internal-id" checked={internalId === null} />
            <CheckboxLabel>No</CheckboxLabel>
          </Row>
        </FieldTitle>

        <Select
          menuPlacement="auto"
          isClearable={true}
          onChange={(selectedField) => {
            setInternald(selectedField?.value ?? undefined);
          }}
          inputId="wizard__internal-id-selector--input"
          value={crmFields?.deal?.customFields?.find((option) => option.value === internalId) ?? null}
          menuPortalTarget={document.querySelector('body')}
          styles={fieldSelectorStyles}
          components={{ Control: IconControl, Option: CustomSingleOption, SingleValue: IconSingleValue }}
          placeholder="Search..."
          options={getSelectorOptions({
            fields: crmFields?.deal?.customFields ?? [],
            isDealLevel: true,
            exampleIntegrationObjects,
          })}
        />
      </FieldBody>

      {/* Nightly sync */}
      <FieldBody>
        <FieldCount configPageView={configPageView}>
          {calcDealFieldCount({ integrationCRM, initalCount: 10, defaultTransactionRecognition })}
        </FieldCount>
        <FieldTitle>Would you like to sync automatically every night, or only sync on demand?</FieldTitle>

        <Column horizontal="flex-start">
          <Row onClick={() => setSkipDailySync(false)} style={{ cursor: 'pointer' }}>
            <Column horizontal="flex-start">
              <Row>
                <CircleCheckbox data-cy="wizard__checkbox--sync-daily" checked={!skipDailySync} />
                <span>Yes, please sync nightly</span>
              </Row>
              <SubText>Every night, we'll sync everything, but you can also sync on demand</SubText>
            </Column>
          </Row>

          <Row onClick={() => setSkipDailySync(true)} style={{ cursor: 'pointer' }}>
            <Column horizontal="flex-start">
              <Row>
                <CircleCheckbox data-cy="wizard__checkbox--skip-daily-sync" checked={skipDailySync} />
                <span>No, please don't sync nightly</span>
              </Row>
              <SubText>We won't sync automatically every night, but you can sync on demand</SubText>
            </Column>
          </Row>
        </Column>
      </FieldBody>
    </DealsWrapper>
  );
};
