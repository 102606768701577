import { useContext, useMemo } from 'react';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { cssVar } from 'polished';

import { AppContext } from 'AppContext';
import { StackedBarChart } from 'components/Graph';
import { useInvoicingStatisticsAPI } from 'api/billing';
import { NUMBER_FORMATS } from 'consts/global';
import { numberFormatter } from 'utils/formatters';
import { useStateWithStorage } from 'utils/hooks';
import { Filters } from 'shared/Filters';
import { GlobalTogglesContextProvider } from 'shared/GlobalToggles';
import { CircleLoader } from 'components/Loaders';
import { Flexer, FlexerColumn, Row } from 'components/Core';
import { BillingDashboardContext } from './BillingDashboardContext';
import { getChartData } from './utils';

const LoadingSpinnerWrapper = styled(Flexer)`
  position: relative;
  margin-top: 20px;
  height: 24px;
`;

const MetricsCards = styled.div`
  display: flex;
  width: 100%;
  border: 1px solid var(--accentGraySecond);
  border-top: none;
  overflow: hidden;
  border-radius: 0px 0px 16px 16px;
  background-color: white;
  position: relative;
  margin-bottom: 40px;
`;

const MetricCard = styled.div`
  padding: 20px;
  width: 100%;
  position: relative;
  border-right: 1px solid var(--accentGraySecond);
  opacity: ${({ disabled }) => disabled && 0.2};
  cursor: pointer;

  &:last-child {
    border-right: none;
  }

  &:hover {
    background-color: var(--primaryBlack3);
  }
`;

const ColorDot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 100px;
  background-color: ${({ color }) => color};
  margin-right: 8px;
`;

const ChartContainer = styled(FlexerColumn)`
  background-color: var(--primaryBlack2);
  box-shadow: inset 0px 0px 20px var(--primaryBlack2);
  padding: 40px;
  margin-top: auto;
  position: relative;
`;

const CardAmount = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  line-height: 22px;
`;

const CardHeader = styled.div`
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 20px;
  align-items: center;
`;

const Tag = styled.div`
  font-weight: 900;
  font-size: 9px;
  line-height: 12px;
  text-transform: uppercase;
  opacity: 0.5;
  background: var(--primaryGray);
  border: 1px solid var(--neutralGray);
  border-radius: 4px;
  padding: 2px 4px;
  position: absolute;
  left: 40px;
  top: -8px;
`;

const CHART_METRICS = [
  {
    value: 'Collected',
    color: cssVar('--primaryGreen'),
  },
  {
    value: 'Sent',
    color: cssVar('--primaryYellow'),
  },
  {
    value: 'Overdue',
    color: cssVar('--newRed'),
  },
  {
    value: 'New to send',
    color: cssVar('--primaryBlue'),
  },
];

export const BillingDashboardChart = () => {
  const {
    orgId,
    appSettings: { currencyISOCode: currency },
  } = useContext(AppContext);

  const { dashboardChartDataFilter, setDashboardChartDataFilter } = useContext(BillingDashboardContext);

  const [disabledMetrics, setDisabledMetrics] = useStateWithStorage('billing-dashboard-disabled-metrics', {});

  const { data: rawStatistics, isLoading } = useInvoicingStatisticsAPI({
    orgId,
    endDate: dayjs(dashboardChartDataFilter?.endMonth).format('YYYY-MM-DD'),
    startDate: dayjs(dashboardChartDataFilter?.startMonth).format('YYYY-MM-DD'),
  });

  const chartData = useMemo(() => getChartData({ rawStatistics: rawStatistics ?? {} }), [rawStatistics]);

  const chartDataTotalValues = useMemo(
    () =>
      chartData
        ? Object.keys(chartData).reduce((acc, month) => {
            Object.entries(chartData[month]).forEach(([key, value]) => {
              acc[key] = (acc[key] || 0) + value;
            });
            return acc;
          }, {})
        : {},
    [chartData],
  );

  const filteredChartData = useMemo(
    () =>
      Object.entries(chartData || {}).reduce((acc, [month, metrics]) => {
        acc[month] = Object.entries(metrics).reduce((monthAcc, [metricKey, value]) => {
          monthAcc[metricKey] = disabledMetrics[metricKey] ? null : value;
          return monthAcc;
        }, {});
        return acc;
      }, {}),
    [chartData, disabledMetrics],
  );

  return (
    <ChartContainer data-cy="billing-dashboard__chart">
      <Tag data-cy="billing-dashboard__chart-tag">Invoicing overview</Tag>

      <GlobalTogglesContextProvider urlState={dashboardChartDataFilter}>
        <Filters
          hidePeriodSelection
          removeBottomBorderRadius
          sameMonthAllowed={true}
          showGlobalToggles={false}
          noShadow
          dataFilter={{
            ...dashboardChartDataFilter,
            startMonth: dayjs(dashboardChartDataFilter?.startMonth).toDate(),
            endMonth: dayjs(dashboardChartDataFilter?.endMonth).toDate(),
          }}
          setDataFilter={setDashboardChartDataFilter}
        />

        <MetricsCards>
          {CHART_METRICS.map(({ value, color }) => (
            <MetricCard
              data-cy={`billing-dashboard__chart-card-${value}`}
              onClick={() => setDisabledMetrics((prevState) => ({ ...prevState, [value]: !disabledMetrics[value] }))}
              key={value}
              color={color}
              disabled={!!disabledMetrics[value]}
            >
              <CardHeader>
                <ColorDot color={color} />
                {value}
              </CardHeader>

              <CardAmount>
                {numberFormatter({
                  type: NUMBER_FORMATS.CURRENCY,
                  rawValue: chartDataTotalValues[value] ?? 0,
                  currency,
                })}
              </CardAmount>
            </MetricCard>
          ))}
        </MetricsCards>
      </GlobalTogglesContextProvider>

      <Row style={{ width: '100%' }}>
        {isLoading ? (
          <LoadingSpinnerWrapper data-cy="loader">
            <CircleLoader width="35px" height="35px" isAbsolute isInline={true} />
          </LoadingSpinnerWrapper>
        ) : (
          <StackedBarChart data={filteredChartData} currency={currency} />
        )}
      </Row>
    </ChartContainer>
  );
};
