import React, { useState } from 'react';
import { Formik } from 'formik';
import styled from 'styled-components';

import { FormikCustomSelector } from 'components/Controls';
import { Flexer } from 'components/Core';
import { useConfirmModal } from 'shared/ConfirmModal';

const FlexGrow = styled.div`
  flex-grow: 1;
`;

const useUpsertFieldModal = ({ glCustomFields, billingCustomFieldOptions, onUpsert }) => {
  const [customField, setCustomField] = useState(null);
  const glCustomFieldsOptions = Object.entries(
    customField && customField.glField && !glCustomFields[customField.glField]
      ? // if it is a new custom field, we need to add it to the list of options
        { ...glCustomFields, [customField.glField]: customField.glFieldLabel }
      : glCustomFields ?? {},
  ).map(([value, label]) => ({
    value,
    label,
  }));

  const { ConfirmModal, openConfirmModal, isModalVisible } = useConfirmModal({
    title: 'Create or edit custom field',
    width: '568px',
    content: (
      <Formik enableReinitialize initialValues={customField}>
        <Flexer gap="20px">
          <FlexGrow>
            <FormikCustomSelector
              name="subscriptField"
              label="Name"
              placeholder="Select"
              options={billingCustomFieldOptions}
              isClearable
              minWidth="180px"
              handleChange={(option) => {
                setCustomField({
                  ...customField,
                  subscriptField: option?.value,
                  subscriptFieldLabel: option?.label,
                });
              }}
            />
          </FlexGrow>
          <FlexGrow>
            <FormikCustomSelector
              name="glField"
              label="Field in your GL"
              placeholder="Select or create"
              options={glCustomFieldsOptions}
              creatable
              isClearable
              minWidth="180px"
              handleChange={(option) => {
                setCustomField({
                  ...customField,
                  glField: option?.value,
                  glFieldLabel: option?.label,
                });
              }}
            />
          </FlexGrow>
        </Flexer>
      </Formik>
    ),
    confirmButtonText: 'Save',
    denyButtonText: 'Cancel',
    onConfirm: () => {
      onUpsert({ glField: customField.glField, subscriptField: customField.subscriptField });
      setCustomField(null);
    },
    onDeny: () => {
      setCustomField(null);
    },
  });

  return {
    Modal: ConfirmModal,
    openModal: ({ glField, glFieldLabel, subscriptField, subscriptFieldLabel }) => {
      setCustomField({
        glField,
        glFieldLabel,
        subscriptField,
        subscriptFieldLabel,
      });
      openConfirmModal();
    },
    isModalVisible,
  };
};

export default useUpsertFieldModal;
