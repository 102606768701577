import { useContext } from 'react';
import dayjs from 'dayjs';

import { ReactComponent as NoItemsImage } from 'images/related_transactions_no_items.svg';
import { ReactComponent as RRIcon } from 'images/RR_icon.svg';
import { RECOGNITION_TYPES, NUMBER_FORMATS } from 'consts/global';
import { useCurrencyNumberFormatter } from 'utils/hooks';
import { Centerer, CentererVertical } from 'components/Core';
import { Loader } from 'components/Loaders';
import { FillDot } from 'components/Icons';
import { TooltipContainer, TruncateStringWithTooltip } from 'components/Tooltip';
import { TOOLS_TIP_COLORS } from 'shared/TransactionTable';
import { TRANSACTION_VIEW_MODE } from 'shared/TransactionContent/consts';
import { TransactionContext } from 'shared/TransactionContent/TransactionContext';
import {
  RelatedTransactionsContent,
  RelatedTransactionsItem,
  RelatedTransactionsItemColumn,
  RelatedTransactionsItemDates,
  RelatedTransactionsItemId,
  RelatedTransactionsItemSeats,
  RelatedTransactionsNoItems,
  ToolTipContentContainer,
  ToolTipRow,
  ToolTipLabel,
  ToolTipValue,
  Divider,
  TillCanceledLabel,
} from './styles';
import { SidebarFooter } from '../styles';
import { ReplaceTransaction } from '../ReplaceTransaction';

export const RelatedTransactions = () => {
  const { view, relatedTransactions, relatedTransactionsLoading } = useContext(TransactionContext);
  const numberFormatter = useCurrencyNumberFormatter();

  const RRToolTip = (transaction) => (
    <ToolTipContentContainer>
      <ToolTipRow>
        <ToolTipLabel>MRR:</ToolTipLabel>
        <ToolTipValue>
          {numberFormatter({ type: NUMBER_FORMATS.CURRENCY, rawValue: transaction.recurring_amount })}
        </ToolTipValue>
      </ToolTipRow>
      <ToolTipRow>
        <ToolTipLabel>Total:</ToolTipLabel>
        <ToolTipValue>{numberFormatter({ type: NUMBER_FORMATS.CURRENCY, rawValue: transaction.amount })}</ToolTipValue>
      </ToolTipRow>
      <Divider />
      <Centerer>
        <TillCanceledLabel>TILL CANCELED DEAL</TillCanceledLabel>
      </Centerer>
    </ToolTipContentContainer>
  );

  return (
    <>
      {!relatedTransactionsLoading ? (
        <>
          <ReplaceTransaction />
          {Boolean(relatedTransactions.length) ? (
            <>
              <RelatedTransactionsContent data-cy="related-transactions__list">
                {relatedTransactions.map((transaction) => (
                  <RelatedTransactionsItem to={`/transactions/${transaction.id}`} target="_blank" key={transaction.id}>
                    <RelatedTransactionsItemColumn>
                      <RelatedTransactionsItemId>
                        <TooltipContainer
                          width={100}
                          toolTipContent={transaction.confirmed ? 'Confirmed' : 'Unconfirmed'}
                        >
                          <FillDot
                            width={6}
                            height={6}
                            fill={transaction.confirmed ? TOOLS_TIP_COLORS.Confirmed : TOOLS_TIP_COLORS.Unconfirmed}
                          />
                        </TooltipContainer>

                        <TruncateStringWithTooltip length={20} tooltipWidth={300}>
                          {transaction.name
                            ? transaction.name
                            : transaction?.end_date
                            ? dayjs(transaction.end_date).format('MMM YYYY')
                            : transaction.customer_name ?? 'Unnamed transaction'}
                        </TruncateStringWithTooltip>
                      </RelatedTransactionsItemId>
                      <RelatedTransactionsItemDates>
                        {dayjs.utc(transaction.start_date).format('MMM YYYY')} -{' '}
                        {transaction?.end_date
                          ? dayjs.utc(transaction?.end_date).format('MMM YYYY')
                          : transaction.recognition === RECOGNITION_TYPES.tillCanceled
                          ? 'Till Canceled'
                          : 'No end date'}
                      </RelatedTransactionsItemDates>
                    </RelatedTransactionsItemColumn>
                    <RelatedTransactionsItemColumn alignItems="flex-end">
                      <CentererVertical>
                        <span>
                          {numberFormatter({
                            type: NUMBER_FORMATS.CURRENCY,
                            rawValue:
                              transaction.recognition === RECOGNITION_TYPES.tillCanceled
                                ? transaction.recurring_amount
                                : transaction.amount,
                            overrideCurrency: transaction.currency,
                          })}
                        </span>
                        {transaction.recognition === RECOGNITION_TYPES.tillCanceled && (
                          <TooltipContainer width={120} toolTipContent={RRToolTip(transaction)}>
                            <RRIcon style={{ marginLeft: 4 }} />
                          </TooltipContainer>
                        )}
                      </CentererVertical>
                      <RelatedTransactionsItemSeats>
                        {transaction.seats}
                        <span>seats</span>
                      </RelatedTransactionsItemSeats>
                    </RelatedTransactionsItemColumn>
                  </RelatedTransactionsItem>
                ))}
              </RelatedTransactionsContent>
            </>
          ) : (
            <RelatedTransactionsNoItems>
              <NoItemsImage />
              <h5>0 results</h5>
              <p>
                We didn't find any related
                <br /> transactions
              </p>
            </RelatedTransactionsNoItems>
          )}
          {view !== TRANSACTION_VIEW_MODE.PAGE && (
            <SidebarFooter>{relatedTransactions.length} transactions</SidebarFooter>
          )}
        </>
      ) : (
        <div className="flexer w-100" style={{ height: 400 }}>
          <Loader loaderStyles={{ height: 40 }} />
        </div>
      )}
    </>
  );
};
