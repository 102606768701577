import React, { useCallback, useState } from 'react';
import { cloneDeep } from 'lodash';
import { DndContext, PointerSensor, useDraggable, useDroppable, useSensor, useSensors } from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';

import { DraggableItemStyled, DroppableSection, DroppableWrapper } from './styles';
import { BUCKET_SECTION_TO_FORECAST_DETAILS_FIELD } from './utils';

export const useDragAndDropContext = ({
  scenarioId,
  month,
  editForecastDetailsAsync,
  bucketedData,
  setBucketedData,
}) => {
  const [draggingItemData, setDraggingItemData] = useState({});

  const handleDragStart = useCallback(
    async (event) => {
      setDraggingItemData(event?.active?.data?.current);
    },
    [setDraggingItemData],
  );

  const handleDragEnd = useCallback(
    async (event) => {
      const { active, over } = event;

      const droppableData = over?.data?.current;
      const draggableData = active?.data?.current;

      if (over && droppableData.section === draggableData.section && droppableData.bucket !== draggableData.bucket) {
        const section = draggableData.section;
        const customerId = draggableData.id;
        const oldBucket = draggableData.bucket;
        const newBucket = droppableData.bucket;

        // Optimistic update
        const rollbackData = cloneDeep(bucketedData);
        const clonedData = cloneDeep(bucketedData);
        const oldData = clonedData[oldBucket][section][draggableData.index];
        clonedData[oldBucket][section]?.splice(draggableData.index, 1);
        clonedData[newBucket][section].push(oldData);
        setBucketedData(clonedData);

        // Actual Update
        try {
          await editForecastDetailsAsync({
            forecastId: scenarioId,
            forecastMonth: month,
            forecastType: BUCKET_SECTION_TO_FORECAST_DETAILS_FIELD[section],
            customerId,
            data: {
              bucket: newBucket,
            },
          });
        } catch (e) {
          setBucketedData(rollbackData);
        }
      }

      setDraggingItemData({});
    },
    [bucketedData, editForecastDetailsAsync, month, scenarioId, setBucketedData],
  );

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
  );

  const DragAndDropContext = useCallback(
    ({ children }) => (
      <DndContext sensors={sensors} onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
        {children}
      </DndContext>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleDragStart, handleDragEnd],
  );

  return {
    draggingItemData,
    DragAndDropContext,
  };
};

export const DragAndDropSection = ({ section, bucket, children, draggingItemData }) => {
  const { setNodeRef, isOver } = useDroppable({
    id: `droppable-${section}-${bucket}`,
    data: {
      section,
      bucket,
    },
  });

  return (
    <DroppableWrapper isOver={isOver} gap="4px">
      {children}
      {draggingItemData.section === section && draggingItemData.bucket !== bucket && (
        <DroppableSection ref={setNodeRef} />
      )}
    </DroppableWrapper>
  );
};

export const DraggableItem = ({ id, section, bucket, index, isDragDisabled, children }) => {
  const { setNodeRef, attributes, listeners, transform, transition, isDragging } = useDraggable({
    id: `draggable-${bucket}-${id}-${index}`,
    data: {
      id,
      section,
      bucket,
      index,
    },
    disabled: isDragDisabled,
  });

  return (
    <DraggableItemStyled
      ref={setNodeRef}
      style={{ transform: CSS.Transform.toString(transform), transition }}
      {...listeners}
      {...attributes}
      isDragging={isDragging}
      isDragDisabled={isDragDisabled}
    >
      {children &&
        React.cloneElement(children, {
          isDragging,
        })}
    </DraggableItemStyled>
  );
};
