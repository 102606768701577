import React, { useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as HeaderIcon } from 'images/info-circle-icon.svg';
import { useToasts } from 'components/Toasts';
import { Loader } from 'components/Loaders';
import { Modal, ModalBody, ModalButton, ModalContainer, ModalFooter, ModalHeader, ModalTitle } from 'components/Modal';
import { pluralize } from 'utils/stringUtils';
import { bulkDeleteTransactions } from 'api/transactions';

const StyledHeaderIcon = styled.div`
  background-color: #ffffff;
  border-radius: 50%;
  display: inline-block;
  left: 50%;
  padding: 11px;
  position: absolute;
  top: -20px;
  transform: translate(-50%, 0);
`;

export const TransactionBulkDeleteModal = ({ organization, transactionIds, onClose, onSuccess }) => {
  const { pushToast } = useToasts();
  const [loading, setLoading] = useState(false);

  const handleConfirmClick = async () => {
    try {
      setLoading(true);

      const response = await bulkDeleteTransactions({
        orgId: organization.id,
        transactionIds,
        params: { skipInvoicingResync: true },
      });

      if (response.success) {
        pushToast(`${pluralize(response.deletedIds.length, 'transaction')} were bulk deleted`, 'success');
        onSuccess(response.deletedIds);
      }
    } catch (err) {
      if (err?.response?.data?.errors?.message)
        pushToast(`Failed to bulk delete transactions!\n${err.response.data.errors.message}`, 'error');
      else pushToast('Failed to bulk delete transactions!', 'error');
      console.error({ message: err.message, component: 'TransactionBulkDeleteModal', stack: err });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <ModalContainer>
        <Modal maxWidth="350px" height="auto" overflow="visible" compact>
          <StyledHeaderIcon>
            <HeaderIcon width="20px" height="20px" />
          </StyledHeaderIcon>
          <ModalHeader>
            <ModalTitle compact>
              <b>Transactions Bulk Delete</b>
            </ModalTitle>
          </ModalHeader>
          <ModalBody compact centered>
            <div>
              <b>Do you want to delete {pluralize(transactionIds.length, 'transaction')}?</b>
              <br />
              This cannot be un-done.
            </div>
            {loading && <Loader loaderStyles={{ height: 40 }} />}
          </ModalBody>
          <ModalFooter centered transparent>
            <ModalButton onClick={onClose}>No, cancel</ModalButton>
            <ModalButton
              className="primary"
              onClick={loading ? undefined : handleConfirmClick}
              disabled={loading}
              data-cy="transaction-bulk-delete-modal-submit"
            >
              Confirm
            </ModalButton>
          </ModalFooter>
        </Modal>
      </ModalContainer>
    </>
  );
};
