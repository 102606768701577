import React, { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import styled from 'styled-components';
import { isNil } from 'lodash';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { NUMBER_FORMATS } from 'consts/global';
import { Centerer, Spacer } from 'components/Core';
import { AlertUnfilledIcon, CircleCheckBlackIcon, PencilIcon } from 'components/Icons';
import { TooltipContainer } from 'components/Tooltip';
import { CustomDatePicker, FormikCustomInput } from 'components/Controls';
import { useCurrencyNumberFormatter } from 'utils/hooks';
import { formatDateForDatepicker, updateDateFromDatePicker } from 'utils/dateUtils';

dayjs.extend(utc);

const MetricValue = styled.div`
  background: ${({ isSecondary }) => (isSecondary ? 'var(--primaryPurple10)' : 'var(--primaryYellow15)')};
  border-radius: 4px;
  color: ${({ isMissing }) => (isMissing ? 'var(--primaryBlack30)' : 'var(--primaryBlack70)')};
  padding: 4px;
  border: ${({ isSecondary }) =>
    isSecondary ? '1px solid var(--primaryPurple10)' : '1px solid var(--primaryYellow15)'};

  svg {
    margin-left: 5px;

    path {
      fill: ${({ isSecondary }) => (isSecondary ? 'var(--primaryPurple60)' : 'var(--secondaryYellow60)')};
    }
  }

  &:hover {
    cursor: pointer;
    color: var(--primaryBlack);
    border: ${({ isSecondary }) =>
      isSecondary ? '1px solid var(--primaryPurple)' : '1px solid var(--secondaryYellow)'};

    svg {
      path {
        fill: ${({ isSecondary }) => (isSecondary ? 'var(--primaryPurple)' : 'var(--secondaryYellow)')};
      }
    }
  }
`;

const TooltipContentWrapper = styled.div`
  b {
    font-weight: 900;
  }
`;

const EditMetricWrapper = styled(Centerer)`
  justify-content: end;

  svg {
    margin-left: 5px;

    &:hover {
      cursor: pointer;
    }
  }
`;

const EditMetric = ({ name, dataType, closeEditMode }) => {
  const { values, setFieldValue, submitForm } = useFormikContext();
  const handleSubmit = async () => {
    closeEditMode();
    await submitForm();
  };

  return (
    <EditMetricWrapper>
      {dataType === 'calendar' ? (
        <CustomDatePicker
          formik
          name={name}
          selected={values[name] ? formatDateForDatepicker(values[name]) : null}
          onChange={(name, date) => setFieldValue(name, updateDateFromDatePicker(date))}
        />
      ) : (
        <FormikCustomInput
          name={name}
          style={{ height: 26, width: dataType === NUMBER_FORMATS.PERCENT ? 70 : 90 }}
          placeholder="0"
          type="number"
          precision={0}
        />
      )}
      <CircleCheckBlackIcon size="20px" onClick={handleSubmit} />
    </EditMetricWrapper>
  );
};

export const CustomizableMetric = ({ dataType, value, name, explanation, missingExplanation, isSecondary }) => {
  const [editMode, setEditMode] = useState(false);
  const closeEditMode = () => setEditMode(false);
  const numberFormatter = useCurrencyNumberFormatter();

  useEffect(() => {
    closeEditMode();
  }, [value]);

  const metricWithTooltip = (
    <TooltipContainer
      width={280}
      fontSize="12px"
      toolTipContent={
        <TooltipContentWrapper>
          {isNil(value) && missingExplanation ? missingExplanation : explanation ?? 'Edit'}
        </TooltipContentWrapper>
      }
    >
      {editMode ? (
        <EditMetric name={name} dataType={dataType} closeEditMode={closeEditMode} />
      ) : (
        <MetricValue isSecondary={isSecondary} onClick={() => setEditMode(true)}>
          <Centerer>
            <span>
              {isNil(value)
                ? 'Null'
                : Object.values(NUMBER_FORMATS).includes(dataType)
                ? numberFormatter({ type: dataType, rawValue: value })
                : dataType === 'calendar'
                ? dayjs.utc(value).format('MMM  DD, YYYY')
                : value}
            </span>
            <PencilIcon size="12px" />
          </Centerer>
        </MetricValue>
      )}
    </TooltipContainer>
  );

  return isNil(value) ? (
    <Centerer>
      <AlertUnfilledIcon size="14px" />
      <Spacer width="8px" />
      {metricWithTooltip}
    </Centerer>
  ) : (
    metricWithTooltip
  );
};
