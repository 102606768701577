import { DEFAULT_REPORTS_OPTION, REPORTS_OPTIONS } from '../consts';

export const getReportsOptionsWithCount = (data) => {
  const optionsCount = (data ?? []).reduce(
    (accumulator, report) => {
      accumulator.all += 1;
      accumulator[report.type] += 1;
      return accumulator;
    },
    Object.assign(
      ...Object.keys(REPORTS_OPTIONS).map((key) => ({
        [key]: 0,
      })),
    ),
  );

  return Object.entries(REPORTS_OPTIONS).reduce((accumulator, [key, value]) => {
    accumulator[key] = `${value} (${optionsCount[key]})`;
    return accumulator;
  }, {});
};

export const getFilteredReports = ({ data, selectedReportType }) => {
  if (selectedReportType && selectedReportType !== DEFAULT_REPORTS_OPTION) {
    return data.filter((report) => report.type === selectedReportType);
  }
  return data;
};
