import { useState } from 'react';
import { Formik } from 'formik';
import { Spacer } from 'components/Core';
import { SizedLoader } from 'components/Loaders';
import { Modal, ModalContainer } from 'components/Modal';
import { useToasts } from 'components/Toasts';
import { FIELDS_FOR_TYPE, COMMPONENT_FOR_FIELD } from './SyncedExportFields';
import { SyncedExportModalHeader } from './SyncedExportModalHeader';
import { SyncedExportModalFooter } from './SyncedExportModalFooter';
import { SyncedExportModalInfo } from './SyncedExportModalInfo';
import { StyledModalBody, BodyWrapper, LoaderContainer } from './SyncedExportModal.styles';
import { formValuesToCustomization, customizationToFormValues } from './SyncedExportModal.utils';

export const SyncedExportModal = ({ type, initialCustomization = {}, onSubmit, onClose }) => {
  const { pushError } = useToasts();
  const [loading, setLoading] = useState(false);

  const existingFilters = Object.entries(initialCustomization).reduce((acc, [key, value]) => {
    if (!FIELDS_FOR_TYPE[type].includes(key)) {
      acc[key] = value;
    }
    return acc;
  }, {});

  const handleFormSubmit = async ({ values }) => {
    setLoading(true);

    const { useExistingFilters, ...formValues } = values;
    let customization = formValuesToCustomization(formValues);
    if (useExistingFilters) {
      customization = { ...initialCustomization, ...customization };
    }

    try {
      await onSubmit({ customization });
    } catch (err) {
      pushError(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <ModalContainer data-cy="synced-export-modal">
      <Modal height="auto" width="640px">
        <SyncedExportModalHeader type={type} onClose={onClose} />
        {loading ? (
          <LoaderContainer>
            <SizedLoader size={40} />
          </LoaderContainer>
        ) : (
          <Formik
            initialValues={{
              ...customizationToFormValues(FIELDS_FOR_TYPE[type], initialCustomization),
              useExistingFilters: false,
            }}
            onSubmit={(values) => handleFormSubmit({ values })}
          >
            <>
              <StyledModalBody>
                <BodyWrapper>
                  <SyncedExportModalInfo />
                  <Spacer height="25px" />
                  {FIELDS_FOR_TYPE[type].map((field, index) => {
                    let ComponentForField = COMMPONENT_FOR_FIELD[field];
                    let props = {};
                    if (Array.isArray(ComponentForField)) {
                      [ComponentForField, props] = ComponentForField;
                    }
                    return (
                      <div key={field}>
                        <ComponentForField {...props} />
                        {index < FIELDS_FOR_TYPE[type].length - 1 && <Spacer height="12px" />}
                      </div>
                    );
                  })}
                </BodyWrapper>
              </StyledModalBody>
              <SyncedExportModalFooter existingFilters={existingFilters} onClose={onClose} />
            </>
          </Formik>
        )}
      </Modal>
    </ModalContainer>
  );
};
