import styled from 'styled-components';

export const HeaderWrapper = styled.div`
  border-right: 1px solid var(--accentGraySecond);
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  position: relative;
  width: 100%;
`;

export const CircleArrowWrapper = styled.div`
  position: absolute;
  top: 26px;
  right: -12px;

  &:hover {
    cursor: pointer;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0;
  gap: 12px;
`;

export const DirectionIconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px;
  gap: 10px;

  background: var(--primaryBlack2);
  border-radius: 8px;
`;

export const ScenarioName = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;

  display: flex;
  align-items: center;
  height: 36px;
`;

export const ScenarioSubTitle = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;

  color: var(--primaryBlack50);
`;

export const ScenarioActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  gap: 8px;
`;

export const ScenarioAction = styled.div`
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: ${({ warn }) => (warn ? 'var(--primaryRed)' : 'var(--primaryBlue)')};

  cursor: pointer;
`;
