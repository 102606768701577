// The sortBy that comes from react-table is like:
// [{ id: 'customer_name', desc: false }, { id: 'name', desc: true }]
// So, before sending to the backend, we need to format this into the knexjs orderBy format, which would look like:
// [{ column: 'customer_name' }, { column: 'name', order: 'desc' }]
export const sortByToKnexOrderBy = (sortBy, columnNameMapping = {}) =>
  sortBy.map((field) => ({
    column: columnNameMapping[field.id] ?? field.id,
    order: field.desc ? 'desc' : 'asc',
  }));

export const fixSelectingWithGetSubRows = ({ row, selectedIds }) => {
  //Fix selecting subRows with getSubRows usage. Maybe will be fixed in new ver React-table
  //https://github.com/TanStack/react-table/issues/2609
  if (!row.canExpand) {
    selectedIds.includes(row.original.id) ? (row.isSelected = true) : (row.isSelected = false);
  }
};
