import React from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { Header, HeaderTitle } from 'shared/Layout';
import { FlexerRow } from 'components/Core';
import { ActionsPopover } from 'shared/Common';
import { ReactComponent as AddIcon } from 'images/actions-dropdown__add.svg';
import { ReactComponent as CsvIcon } from 'images/actions-dropdown__csv.svg';

export const ContractsListHeader = ({ setShowCreateContractModal }) => {
  const history = useHistory();
  return (
    <Header
      headerLeft={<HeaderTitle>Contracts</HeaderTitle>}
      headerRight={
        <FlexerRow justifyContent="end" gap="12px">
          <ActionsPopover
            width="200px"
            dropdownXOffset={-64}
            showHoverText={false}
            actions={{
              Contract: {
                icon: <AddIcon />,
                cb: () => setShowCreateContractModal(true),
              },
              'Upload Contract(s)': {
                icon: <CsvIcon />,
                cb: () => history.push('/contracts/pdfs'),
              },
            }}
          />
        </FlexerRow>
      }
    />
  );
};
