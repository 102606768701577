import React from 'react';
import styled from 'styled-components';
import { PlusIcon } from './';
import { Centerer } from '../Core';

export const StyledCirclePlusBlueIcon = styled(Centerer)`
  cursor: pointer;
  width: 28px;
  height: 28px;
  padding: 4px;
  border-radius: 50%;
  background-color: var(--accentBlue);
  transition: background-color 0.2s ease-in-out;
  transition: box-shadow 0.2s ease-in-out;

  svg {
    width: 16px;
    height: 16px;

    path {
      fill: var(--primaryBlue);
    }
  }

  &:hover {
    background-color: var(--primaryBlue);
    box-shadow: 0px 4px 10px var(--primaryBlue20);

    svg {
      path {
        fill: var(--lightGray);
      }
    }
  }
`;

export const CirclePlusBlueIcon = (props) => (
  <StyledCirclePlusBlueIcon {...props}>
    <PlusIcon />
  </StyledCirclePlusBlueIcon>
);
