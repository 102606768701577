export const INSIGHT_DIRECTIONS = {
  POSITIVE: 'positive',
  NEGATIVE: 'negative',
};

export const INSIGHT_METRIC_TYPES = {
  revenue_percent: 'Revenue %',
  customer_percent: 'Customer %',
  new: 'New',
  upsell: 'Upsell',
  downsell: 'Downsell',
  churn: 'Churn',
  influx: 'Influx',
};

export const INSIGHT_METRICS_BY_CHART = {
  cohort: [INSIGHT_METRIC_TYPES.revenue_percent, INSIGHT_METRIC_TYPES.customer_percent],
  waterfall: [
    INSIGHT_METRIC_TYPES.new,
    INSIGHT_METRIC_TYPES.upsell,
    INSIGHT_METRIC_TYPES.downsell,
    INSIGHT_METRIC_TYPES.churn,
    INSIGHT_METRIC_TYPES.influx,
  ],
};

export const INSIGHT_MIN_POPULATION_SIZE = 10;

export const CHART_COLORS = {
  insight: 'var(--primaryYellow)',
  default: 'var(--primaryBlack50)',
  others: ['var(--primaryPurple)', 'var(--primaryPink)'],
};
