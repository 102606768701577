import { useMutation, useQuery, useQueryClient } from 'react-query';
import { createReport, deleteReport, getReports, updateReport, getReport } from './requests';

const CACHE_KEY_GET_REPORTS = 'getReports';
const CACHE_KEY_GET_REPORT = 'getReport';
const INCLUDE_PARAM = ['creator'];

export const useReportsAPI = ({ orgId, filters, autoFetch = true }) => {
  const queryClient = useQueryClient();
  const dataKey = [CACHE_KEY_GET_REPORTS, orgId, filters];

  const { data, error, isLoading, isFetching } = useQuery(
    dataKey,
    () =>
      getReports({
        orgId,
        filters,
        include: INCLUDE_PARAM,
      }),
    {
      enabled: autoFetch && !!orgId,
    },
  );
  const mutationOptions = {
    onSuccess: async () => {
      await queryClient.invalidateQueries([CACHE_KEY_GET_REPORTS, orgId]);
      await queryClient.invalidateQueries([CACHE_KEY_GET_REPORT, orgId]);
    },
  };

  const createReportMutation = useMutation(
    ({ orgId, report }) =>
      createReport({
        orgId,
        report,
        include: INCLUDE_PARAM,
      }),
    mutationOptions,
  );

  const updateReportMutation = useMutation(
    ({ orgId, reportId, report }) =>
      updateReport({
        orgId,
        reportId,
        report,
        include: INCLUDE_PARAM,
      }),
    mutationOptions,
  );

  const removeReportMutation = useMutation(
    ({ orgId, reportId }) =>
      deleteReport({
        orgId,
        reportId,
      }),
    mutationOptions,
  );

  return {
    data,
    error,
    isLoading,
    isFetching,
    operations: {
      createReport: createReportMutation.mutateAsync,
      updateReport: updateReportMutation.mutateAsync,
      removeReport: removeReportMutation.mutateAsync,
    },
  };
};

export const useReportAPI = ({ orgId, reportId, autoFetch = true }) => {
  const dataKey = [CACHE_KEY_GET_REPORT, orgId, reportId];

  const { data, error, isLoading, isFetching } = useQuery(
    dataKey,
    () =>
      getReport({
        orgId,
        reportId,
        include: INCLUDE_PARAM,
      }),
    {
      enabled: autoFetch && !!orgId && !!reportId,
    },
  );

  return {
    data,
    error,
    isLoading,
    isFetching,
  };
};
