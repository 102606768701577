import React from 'react';
import styled from 'styled-components';
import { Flexer, FlexerColumn } from 'components/Core';
import { HelpCircleIcon, TriangleWarningIcon } from 'components/Icons';

export const WARN_BLOCK_VARIANT = {
  INFO: 'info',
  CRITICAL: 'critical',
};

const BACKGROUND_COLOR = {
  [WARN_BLOCK_VARIANT.INFO]: 'var(--primaryYellow10)',
  [WARN_BLOCK_VARIANT.CRITICAL]: 'var(--primaryRed10)',
};

const ICON_FILL_COLOR = {
  [WARN_BLOCK_VARIANT.INFO]: 'var(--secondaryYellow)',
  [WARN_BLOCK_VARIANT.CRITICAL]: 'var(--secondaryRed)',
};

const DEFAULT_ICON = {
  [WARN_BLOCK_VARIANT.INFO]: <HelpCircleIcon />,
  [WARN_BLOCK_VARIANT.CRITICAL]: <TriangleWarningIcon />,
};

const StyledWarnBlock = styled(Flexer)`
  align-items: flex-start;
  padding: 16px;
  gap: 16px;
  background: ${({ variant }) => BACKGROUND_COLOR[variant]};
  border-radius: 12px;

  svg {
    width: 16px;
    height: 16px;

    path {
      fill: ${({ variant }) => ICON_FILL_COLOR[variant]};
      opacity: 1;
    }
  }
`;

const StyledWarnBlockText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  white-space: normal;
  word-break: break-word;
  overflow-wrap: break-word;
`;

const StyledActionButton = styled.div`
  padding: 4px 8px;
  gap: 4px;
  border: 1px solid var(--primaryBlack5);
  border-radius: 4px;
  cursor: pointer;

  font-weight: 900;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  color: var(--primaryBlack75);
`;

export const WarnBlock = ({
  children,
  actionText,
  onActionClick,
  rightIcon,
  justifyContent,
  variant = WARN_BLOCK_VARIANT.INFO,
}) => (
  <StyledWarnBlock justifyContent={justifyContent} variant={variant}>
    <FlexerColumn gap="16px">
      <StyledWarnBlockText>{children}</StyledWarnBlockText>
      {onActionClick && (
        <Flexer>
          <StyledActionButton onClick={onActionClick}>{actionText}</StyledActionButton>
        </Flexer>
      )}
    </FlexerColumn>
    <Flexer>{rightIcon ?? DEFAULT_ICON[variant]}</Flexer>
  </StyledWarnBlock>
);
