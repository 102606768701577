import styled from 'styled-components';
import { useContext } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import { Centerer, FlexBetweenContainer, FlexEndContainer } from 'components/Core';
import { ModalFooter } from 'components/Modal';
import { DIRECTIONS, TooltipContainer } from 'components/Tooltip';
import { ArrowRightCircleIcon } from 'components/Icons';
import { COLORS } from 'consts/colors';
import { Button } from 'components/Buttons';
import { ReactComponent as ArrowIcon } from 'images/arrow-narrow-right.svg';

import { InvoicingScheduleContext } from '../InvoicingScheduleModal/InvoicingScheduleContext';
import { SUBMIT_TYPS } from './ReceiptModal';

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 8px;

  font-weight: 700;
  line-height: 20px;
  box-shadow: 2px 2px 12px var(--primaryBlack2);

  border: ${({ borderColor }) => borderColor && `1px solid ${borderColor}`};
  background: ${({ background }) => background};

  &:hover {
    border: ${({ hoverBorderColor }) => hoverBorderColor && `1px solid ${hoverBorderColor}`};
    background: ${({ hoverBackground }) => hoverBackground};
  }

  svg {
    width: ${({ iconSize }) => iconSize};
    height: ${({ iconSize }) => iconSize};
    path {
      fill: ${({ iconFillColor }) => iconFillColor};
    }
  }
`;

const StyledArrowRightCircleIcon = styled(ArrowRightCircleIcon)`
  width: 16px;
  height: 16px;
`;

const ResendButton = styled(FlexBetweenContainer)`
  align-items: center;
  padding: 16px;
  gap: 16px;
  color: var(--primaryBlue);
  border-radius: 12px;
  background-color: var(--primaryBlue10);

  &:hover {
    cursor: pointer;
  }
`;

const ButtonText = styled.span`
  color: var(--primaryBlack);
`;

const ButtonIconWrapper = styled(Centerer)`
  width: 35px;
  height: 35px;
  background-color: var(--primaryBlue10);
  border-radius: 50%;
`;

const StyledArrowIcon = styled(ArrowIcon)`
  g {
    opacity: 1;
  }

  path {
    fill: var(--primaryBlue);
  }
`;

export const ReceiptModalFooter = ({ isPaid, handleSubmit, receipt }) => {
  const { user = {} } = useAuth0();
  const { disableSubmitButton } = useContext(InvoicingScheduleContext);

  return receipt?.sent_at ? (
    <ModalFooter transparent padding="0px 40px">
      <ResendButton
        onClick={() => handleSubmit(SUBMIT_TYPS.SEND)}
        data-cy="billing__receipt-modal__resend-receipt-button"
      >
        <div>
          Send Receipt <ButtonText>Again</ButtonText>
        </div>
        <ButtonIconWrapper>
          <StyledArrowIcon />
        </ButtonIconWrapper>
      </ResendButton>
    </ModalFooter>
  ) : (
    <ModalFooter transparent noFixedHeight padding="12px 40px" topSeparator>
      <FlexEndContainer gap="8px">
        <TooltipContainer
          width={220}
          direction={DIRECTIONS.TOP}
          hideArrow
          yOffset={28}
          fontSize="12px"
          toolTipContent={`Send the email to yourself: ${user?.email}`}
        >
          <StyledButton
            hoverBackground="var(--primaryBlack10)"
            onClick={() => handleSubmit(SUBMIT_TYPS.TEST_SEND)}
            disabled={disableSubmitButton}
          >
            Test Send
          </StyledButton>
        </TooltipContainer>

        <StyledButton
          hoverBackground="var(--primaryBlack10)"
          onClick={() => handleSubmit(SUBMIT_TYPS.SAVE)}
          data-cy="billing__receipt-modal__save-receipt-button"
        >
          Save Receipt Email
        </StyledButton>

        {!receipt.auto_send && (
          <TooltipContainer
            width={220}
            direction={DIRECTIONS.TOP}
            hideArrow
            yOffset={28}
            fontSize="12px"
            toolTipContent={
              disableSubmitButton && isPaid
                ? 'Please check sender settings and configure mailer'
                : 'You can only send the receipt after the invocie is paid'
            }
            isVisible={!isPaid || disableSubmitButton}
          >
            <StyledButton
              filled
              active={isPaid}
              disabled={!isPaid || disableSubmitButton}
              color={COLORS.GREEN}
              iconFillColor="#FFF"
              onClick={() => handleSubmit(SUBMIT_TYPS.SEND)}
              data-cy="billing__receipt-modal__send-receipt-button"
            >
              Send Receipt Now
              <StyledArrowRightCircleIcon />
            </StyledButton>
          </TooltipContainer>
        )}
      </FlexEndContainer>
    </ModalFooter>
  );
};
