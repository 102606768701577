import dayjs from 'dayjs';

export const convertCsvToPeriods = ({ csvData, readString }) => {
  if (csvData === undefined) return [];
  const parsedPeriods = readString(csvData);

  return parsedPeriods.data.map((periodData) => ({
    type: periodData[0],
    start_month: dayjs(periodData[1], ['MM/YYYY']).format('YYYY-MM-DD'),
    value: periodData[2],
  }));
};
