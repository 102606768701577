import { reFormatDate } from 'utils/dateUtils';
import { dig } from 'utils/objectUtils';

// summaryDataKey can be multiple choices in which case we take the first choice that returns truthy
const extractSummaryKey = (object, summaryDataKey) => {
  for (const path of Array.isArray(summaryDataKey) ? summaryDataKey : [summaryDataKey]) {
    const summaryValue = dig(object, path);
    if (summaryValue) return summaryValue;
  }
  return null;
};

export const getColumnValue = (header, data, monthDataKey, summaryObject, summaryDataKey) => {
  if (summaryObject?.[header]) {
    return extractSummaryKey(summaryObject[header], summaryDataKey);
  } else {
    const monthDateKey = reFormatDate(header, 'MMM YYYY', 'YYYY-MM');
    if (data[monthDateKey]) {
      return data[monthDateKey][monthDataKey];
    }
  }
};
