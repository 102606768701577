import React, { useCallback, useContext, useMemo } from 'react';
import styled from 'styled-components';

import { AppContext } from 'AppContext';
import { useInvoiceMemoTemplatesAPI } from 'api/invoiceMemoTemplates/hooks';
import { SelectDropdownButton } from 'components/Buttons';
import { Flexer, FlexerColumn } from 'components/Core';
import { CirclePlusIcon } from 'components/Icons';

const SecondaryMenuWrapper = styled(FlexerColumn)`
  border-radius: 12px;
  border: 1px solid var(--neutralGray);
  background-color: white;
  width: 100%;
`;

const SecondaryMenuItem = styled(Flexer)`
  padding: 12px;
  font-weight: 700;
  gap: 8px;
  font-size: 12px;
  font-style: italic;
  align-items: center;

  &:hover {
    cursor: pointer;
  }

  &:not(:first-child) {
    border-top: 1px solid var(--neutralGray);
  }
`;

const ClearIcon = styled(CirclePlusIcon)`
  transform: rotate(45deg);
`;

const formatMemoTemplatesForDropdown = (templates) =>
  templates.reduce((total, template) => {
    total[template.id] = template.name;
    return total;
  }, {});

export const MemoTemplateDropdownButton = ({
  isLoading,
  templates,
  onSelect,
  buttonText,
  isDisabled,
  name,
  actions,
  isCreatable,
  isClearable,
  onCreate,
  ...props
}) => {
  if (isLoading) return null;

  return (
    <SelectDropdownButton
      name={name}
      showSelectedDirectly
      options={formatMemoTemplatesForDropdown(templates)}
      selected={buttonText}
      XOffset={-150}
      disabled={isDisabled}
      fontSize="12px"
      onSelect={onSelect}
      actions={actions}
      secondaryMenu={
        (isCreatable || isClearable) && (
          <SecondaryMenuWrapper>
            {isCreatable && (
              <SecondaryMenuItem onClick={onCreate} data-cy="create-template-button">
                <CirclePlusIcon color="var(--primaryBlue)" size="16px" />
                <div>Create a new template</div>
              </SecondaryMenuItem>
            )}
            {isClearable && (
              <SecondaryMenuItem onClick={() => onSelect(null)} data-cy="clear-template-button">
                <ClearIcon color="var(--primaryBlack50)" size="16px" />
                <div>Clear template</div>
              </SecondaryMenuItem>
            )}
          </SecondaryMenuWrapper>
        )
      }
      {...props}
    />
  );
};

export const useMemoTemplateDropdownButton = ({ templates: _templates, ...props }) => {
  const { orgId } = useContext(AppContext);

  const { data = [], isLoading } = useInvoiceMemoTemplatesAPI({ orgId, autoFetch: !_templates });
  const templates = useMemo(() => _templates ?? data, [_templates, data]);

  const DropdownButton = useCallback(
    () => <MemoTemplateDropdownButton isLoading={isLoading} templates={templates} {...props} />,
    [isLoading, props, templates],
  );

  return {
    MemoTemplateDropdownButton: DropdownButton,
    templates,
  };
};
