import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { DndContext, closestCenter, PointerSensor, useSensor, useSensors, DragOverlay } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { isEqual, omit } from 'lodash';

import { AppContext } from 'AppContext';
import { ISO_CODE_TO_SYMBOL, MAGIC_METADATA, NUMBER_FORMATS } from 'consts/global';
import { INTEGRATION_SERVICES } from 'consts/integrations';
import { numberFormatter } from 'utils/formatters';
import { InvoicingScheduleContext } from 'views/Billing/InvoicingScheduleModal/InvoicingScheduleContext';
import { calculateAmountFromPercent, calculatePercentFromAmount } from 'views/Billing/InvoiceModal/utils';
import { InvoiceImportedTag } from 'views/Billing/InvoicingScheduleModal/InvoicingScheduleTabsPanel/InvoiceLineItem';
import { InvoiceItemColumnTitle, InvoiceItemsHeader, InvoiceItemSubTitle } from 'views/Billing/Common/styles';
import { INVOICE_ITEM_TYPES, INVOICE_ITEM_TYPE_TO_KEY } from 'views/Billing/consts';
import { InlineButton } from 'components/Buttons';
import { TrashIcon } from 'components/Icons';
import { Flexer, FlexerColumn } from 'components/Core';
import { FormikCustomInput } from 'components/Controls';
import { TooltipContainer } from 'components/Tooltip';
import { ReactComponent as CirclePlusIcon } from 'images/circle-plus.svg';
import { groupBy } from 'utils/arrayUtils';
import { getServiceCategory } from 'models/integration';
import { roundToDecimalPlaces } from 'utils/mathUtils';

import {
  InvoiceItemsWrapper,
  EqualSign,
  InvoiceItemLink,
  TaxCodeDescription,
} from '../../../InvoiceScheduleWarnings/styles';
import { AmountBalanceWrapper, AutomaticTaxNotification, TaxesLoader } from '../../../styles';
import { MergedInvoiceItem, useMergeInvoiceItemsModal } from '../MergeInvoiceItems';
import { getFieldChangesProps, handleInvoiceItemsDragEnd } from '../utils';
import { InvoiceItem } from './InvoiceItem';
import { ImportedInvoiceItemsInfo } from './ImportedInvoiceItemsInfo';

const StyledCirclePlusIcon = styled(CirclePlusIcon)`
  path {
    fill: var(--primaryBlue);
  }
`;

const FooterSubText = styled.div`
  font-size: 12px;
  font-weight: 700;
  color: var(--primaryBlack50);
`;

const getInvoiceGroupingForDisplay = ({ invoiceGrouping, invoiceItems }) =>
  (
    invoiceGrouping ??
    (invoiceItems ?? []).map((item) => ({
      id: item.id,
      invoice_items_ids: [item.id],
    }))
  )
    .filter((group) => !!group.invoice_items_ids?.length || group.isMerged)
    .map((group) => ({
      // if we have not yet set an id, then we create one
      // this id is used only for drag and drop purposes, we don't store it in the database
      id: group.isMerged ? uuidv4() : group.invoice_items_ids[0].id,
      ...group,
    }));

export const InvoiceItemsAndTaxes = ({
  invoice,
  values,
  disableEditing,
  invoicingService,
  productData,
  setFieldValue,
}) => {
  const {
    appSettings: { currencyISOCode: defaultOrgCurrency },
    orgConfigs: {
      billingSenderDefaults: { tax_label: taxLabel },
    },
  } = useContext(AppContext);
  const { usesGLConfiguredTaxCodes, invoicingServiceDisplayName } = useContext(InvoicingScheduleContext);

  const [itemBeingDragged, setItemBeingDragged] = useState(null);
  const [itemBeingReplaced, setItemBeingReplaced] = useState(null);
  const [isFieldsInitialySynced, setIsFieldsInitialySynced] = useState(false);

  const isWaitingForTaxes = !!invoice?.polling_taxes_job_id;

  const showTaxesSection = useMemo(
    () => !!values[INVOICE_ITEM_TYPE_TO_KEY[INVOICE_ITEM_TYPES.TAXES]] && !isWaitingForTaxes,
    [values, isWaitingForTaxes],
  );
  const showFeesSection = useMemo(() => !!values[INVOICE_ITEM_TYPE_TO_KEY[INVOICE_ITEM_TYPES.FEE]], [values]);
  const showShippingSection = useMemo(() => !!values[INVOICE_ITEM_TYPE_TO_KEY[INVOICE_ITEM_TYPES.SHIPPING]], [values]);
  const showDiscountSection = useMemo(() => !!values[INVOICE_ITEM_TYPE_TO_KEY[INVOICE_ITEM_TYPES.DISCOUNT]], [values]);

  const invoiceItemTypesFields = useMemo(
    () => ({
      [INVOICE_ITEM_TYPES.FEE]: {
        showSection: showFeesSection,
        key: INVOICE_ITEM_TYPE_TO_KEY[INVOICE_ITEM_TYPES.FEE],
        title: 'Fees',
      },
      [INVOICE_ITEM_TYPES.TAXES]: {
        showSection: showTaxesSection,
        key: INVOICE_ITEM_TYPE_TO_KEY[INVOICE_ITEM_TYPES.TAXES],
        title: usesGLConfiguredTaxCodes ? `Taxes calculated by ${invoicingServiceDisplayName}` : 'Taxes',
      },
      [INVOICE_ITEM_TYPES.SHIPPING]: {
        showSection: showShippingSection,
        key: INVOICE_ITEM_TYPE_TO_KEY[INVOICE_ITEM_TYPES.SHIPPING],
        title: 'Shipping',
      },
      [INVOICE_ITEM_TYPES.DISCOUNT]: {
        showSection: showDiscountSection,
        key: INVOICE_ITEM_TYPE_TO_KEY[INVOICE_ITEM_TYPES.DISCOUNT],
        title: 'Discount',
      },
    }),
    [
      showFeesSection,
      showTaxesSection,
      usesGLConfiguredTaxCodes,
      invoicingServiceDisplayName,
      showShippingSection,
      showDiscountSection,
    ],
  );

  const currency = invoice?.currency ?? defaultOrgCurrency;
  const invoiceItemById = groupBy(values.invoice_items ?? [], 'id', { uniqueness: true });

  const onConfirm = useCallback((group) => setFieldValue('grouping', group), [setFieldValue]);
  const { Modal: MergeInvoiceItemsModal, openModal: openMergeInvoiceItemsModal } = useMergeInvoiceItemsModal({
    invoicingServiceDisplayName,
    invoiceItems: values.invoice_items,
    currency: invoice.currency ?? defaultOrgCurrency,
    invoiceGrouping: values.grouping,
    onConfirm,
  });

  // getting the invoiceGroupingForDisplay this way prevents undesired re-renders when editing inputs
  const [invoiceGroupingForDisplay, setInvoiceGroupingForDisplay] = useState(
    getInvoiceGroupingForDisplay({ invoiceItems: values.invoice_items ?? [], invoiceGrouping: values.grouping }),
  );
  const invoiceGroupingForDisplayRef = useRef(invoiceGroupingForDisplay);

  useEffect(() => {
    const updatedInvoiceGroupingForDisplay = getInvoiceGroupingForDisplay({
      invoiceItems: values.invoice_items ?? [],
      invoiceGrouping: values.grouping,
    });

    if (
      !isEqual(
        invoiceGroupingForDisplayRef.current.map(({ invoice_items_ids, isMerged }) => ({
          invoice_items_ids,
          isMerged,
        })),
        updatedInvoiceGroupingForDisplay.map(({ invoice_items_ids, isMerged }) => ({
          invoice_items_ids,
          isMerged,
        })),
      )
    ) {
      setInvoiceGroupingForDisplay(updatedInvoiceGroupingForDisplay);
      invoiceGroupingForDisplayRef.current = updatedInvoiceGroupingForDisplay;
    }
  }, [values.grouping, values.invoice_items]);

  const addInvoiceItem = useCallback(
    (event) => {
      event.preventDefault();

      const newInvoiceItem = {
        organization_id: values.organization_id,
        customer_id: values.customer_id,
        transaction_id: null,
        amount: 0,
        seats: 0,
        description: '',
        id: Math.random() * 100000,
      };

      setFieldValue(`invoice_items`, [...(values.invoice_items ?? []), newInvoiceItem]);
      values.grouping && setFieldValue('grouping', [...values.grouping, { invoice_items_ids: [newInvoiceItem.id] }]);
    },
    [setFieldValue, values],
  );

  const handleDragEnd = (event) => {
    const { active, over } = event;

    const { grouping: newGrouping, invoiceItems: newInvoiceItems } = handleInvoiceItemsDragEnd({
      active,
      over,
      hasGoruping: !!values.grouping,
      grouping: invoiceGroupingForDisplay.map((item, index) => ({
        ...item,
        ...values.grouping?.[index],
      })),
      invoiceItemById,
      invoiceItems: values.invoice_items,
    });

    newGrouping && setFieldValue('grouping', newGrouping);
    newInvoiceItems && setFieldValue('invoice_items', newInvoiceItems);

    setItemBeingDragged(null);
    setItemBeingReplaced(null);
  };

  const handleDragStart = (event) => {
    const { active } = event;
    setItemBeingDragged(
      values.invoice_items.find((item) => item.id === active.id) ??
        invoiceGroupingForDisplay.find((group) => group.id === active.id),
    );
  };

  const handleDragOver = (event) => {
    const { over } = event;
    setItemBeingReplaced(
      values.invoice_items.find((item) => item.id === over.id) ??
        invoiceGroupingForDisplay.find((group) => group.id === over.id),
    );
  };

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 20,
      },
    }),
  );

  const syncInvoiceFields = useCallback(
    (values) => {
      const invoiceItems = values.invoice_items ?? [];
      const creditNotes = values.credit_notes ?? [];

      const newBaseAmount = invoiceItems.reduce((acc, item) => acc + item.amount, 0);
      setFieldValue('base_amount', newBaseAmount);

      /** This logic allows the following actions:
       * Tax amount auto-adjusts instead of percentage when invoice item amounts are changed
       * When fees, taxes, or shipping amount is manually changed, the percentage is auto-adjusted
       * When fees, taxes, or shipping percentage is manually changed, the amount is auto-adjusted
       */
      let newAmount = newBaseAmount;
      for (const { key } of Object.values(invoiceItemTypesFields)) {
        if (values[key]) {
          const newPercentForType =
            values[key].newPercent ??
            (key === INVOICE_ITEM_TYPE_TO_KEY[INVOICE_ITEM_TYPES.TAXES]
              ? values[key].percent
              : calculatePercentFromAmount({
                  amount: values[key].amount,
                  total: newBaseAmount,
                }));
          const newAmountForType =
            values[key].newAmount ??
            (key === INVOICE_ITEM_TYPE_TO_KEY[INVOICE_ITEM_TYPES.TAXES]
              ? calculateAmountFromPercent({ percent: newPercentForType, total: newBaseAmount })
              : values[key].amount);

          values[key].percent !== newPercentForType && setFieldValue(`${key}.percent`, newPercentForType);
          roundToDecimalPlaces({ number: values[key].amount, decimalPlaces: 2 }) !==
            roundToDecimalPlaces({ number: newAmountForType, decimalPlaces: 2 }) &&
            setFieldValue(
              `${key}.amount`,
              key === INVOICE_ITEM_TYPE_TO_KEY[INVOICE_ITEM_TYPES.DISCOUNT]
                ? -Math.abs(newAmountForType ?? 0)
                : newAmountForType,
            );

          newAmount += newAmountForType;
        }
      }

      setFieldValue('amount', newAmount - creditNotes.reduce((acc, curr) => acc + curr.amount, 0));
    },
    [setFieldValue, invoiceItemTypesFields],
  );

  useEffect(() => {
    setIsFieldsInitialySynced(false);
  }, [values?.unsavedId]);

  //initial fields sync
  useEffect(() => {
    if (values && !isFieldsInitialySynced) {
      syncInvoiceFields(values);
      setIsFieldsInitialySynced(true);
    }
  }, [syncInvoiceFields, values, isFieldsInitialySynced]);

  return (
    <>
      <InvoiceItemsWrapper data-cy="invoice__invoice-items">
        {!!values?.is_imported && <ImportedInvoiceItemsInfo values={values} />}
        <InvoiceItemsHeader>
          {!!values?.is_imported && (
            <InvoiceImportedTag style={{ marginRight: '-12px' }}>in subscript</InvoiceImportedTag>
          )}
          <InvoiceItemColumnTitle>Invoice Items:</InvoiceItemColumnTitle>
          <InvoiceItemSubTitle flexGrow={0.7}>Transaction (internal)</InvoiceItemSubTitle>
          <InvoiceItemSubTitle flexGrow={1}>
            <span>Product line item</span>
            {invoicingServiceDisplayName && <span>{`${invoicingServiceDisplayName} Product`}</span>}
          </InvoiceItemSubTitle>
          <InvoiceItemSubTitle width="100px" flexEnd>
            Quantity
          </InvoiceItemSubTitle>
          <InvoiceItemSubTitle width="120px" flexEnd>
            Amount
          </InvoiceItemSubTitle>
        </InvoiceItemsHeader>

        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
          onDragOver={handleDragOver}
        >
          <SortableContext
            items={invoiceGroupingForDisplay}
            strategy={verticalListSortingStrategy}
            disabled={{ draggable: disableEditing }}
          >
            {invoiceGroupingForDisplay.map((group, index) => (
              <React.Fragment key={group.id}>
                {group.isMerged && (
                  <MergedInvoiceItem
                    index={index}
                    group={omit(group, 'id') ?? {}}
                    groupId={group.id}
                    disableEditing={disableEditing}
                    setFieldValue={setFieldValue}
                    values={values}
                    isDragging={itemBeingDragged?.id === group.id}
                    currency={currency}
                    invoiceItemById={invoiceItemById}
                    invoicingServiceDisplayName={invoicingServiceDisplayName}
                  />
                )}

                {(group.invoice_items_ids ?? []).map((itemId, subIndex) => (
                  <InvoiceItem
                    index={values.invoice_items.findIndex((item) => item.id?.toString() === itemId?.toString())}
                    item={invoiceItemById[itemId] ?? {}}
                    disableEditing={disableEditing}
                    setFieldValue={setFieldValue}
                    syncInvoiceFields={syncInvoiceFields}
                    values={values}
                    invoice={invoice}
                    key={itemId}
                    isDragging={
                      itemBeingDragged?.id === itemId || itemBeingDragged?.invoice_items_ids?.includes?.(itemId)
                    }
                    hideItem={itemBeingReplaced?.invoice_items_ids?.includes?.(itemId)}
                    currency={currency}
                    products={productData?.products ?? []}
                    usesGLConfiguredTaxCodes={usesGLConfiguredTaxCodes}
                    isGroupItem={!!group.isMerged}
                    groupIndex={index}
                    subIndex={subIndex}
                    isLastItem={subIndex === (group.invoice_items_ids ?? []).length - 1}
                  />
                ))}
              </React.Fragment>
            ))}
          </SortableContext>

          <DragOverlay>
            {itemBeingDragged ? (
              itemBeingDragged.isMerged ? (
                <MergedInvoiceItem
                  index={invoiceGroupingForDisplay.findIndex((g) => g.id === itemBeingDragged.id)}
                  group={itemBeingDragged}
                  groupId={itemBeingDragged.id}
                  disableEditing={disableEditing}
                  setFieldValue={setFieldValue}
                  values={values}
                  currency={currency}
                  invoiceItemById={invoiceItemById}
                  invoicingServiceDisplayName={invoicingServiceDisplayName}
                />
              ) : (
                <InvoiceItem
                  index={(values.invoice_items ?? []).findIndex((item) => item.id === itemBeingDragged.id)}
                  groupIndex={invoiceGroupingForDisplay.findIndex((group) => group.id === itemBeingDragged.id)}
                  item={itemBeingDragged}
                  disableEditing={disableEditing}
                  setFieldValue={setFieldValue}
                  syncInvoiceFields={syncInvoiceFields}
                  values={values}
                  invoice={invoice}
                  id={itemBeingDragged.id}
                  currency={currency}
                  usesGLConfiguredTaxCodes={usesGLConfiguredTaxCodes}
                  isGroupItem={
                    !!values.grouping?.find((group) => group.invoice_items_ids?.includes(itemBeingDragged?.id))
                      ?.isMerged
                  }
                />
              )
            ) : null}
          </DragOverlay>
        </DndContext>

        {Object.values(invoiceItemTypesFields).map(
          ({ key, showSection, title }) =>
            values[key] &&
            showSection && (
              <FlexerColumn key={key} style={{ marginTop: 12 }}>
                <InvoiceItemColumnTitle style={{ margin: '8px 0px -12px' }}>{title}</InvoiceItemColumnTitle>
                <Flexer gap="16px" width="100%" alignItems="center">
                  <Flexer width="100%" marginTop="22px">
                    <TooltipContainer
                      width={220}
                      fontSize="12px"
                      toolTipContent="Tax is calculated by your GL, so you can't edit it here. To change this, update your GL integration settings to disable 'Use mapped tax codes'"
                      hideArrow
                      isVisible={usesGLConfiguredTaxCodes && key === INVOICE_ITEM_TYPE_TO_KEY[INVOICE_ITEM_TYPES.TAXES]}
                    >
                      <FormikCustomInput
                        name={`${key}.description`}
                        width="100%"
                        data-cy={`billing__invoice_schedule-modal__invoice__${key}-description-input`}
                        disabled={
                          disableEditing ||
                          (usesGLConfiguredTaxCodes && key === INVOICE_ITEM_TYPE_TO_KEY[INVOICE_ITEM_TYPES.TAXES])
                        }
                        {...getFieldChangesProps({
                          values,
                          field: `${key}.description`,
                        })}
                      />
                    </TooltipContainer>
                  </Flexer>

                  <TooltipContainer
                    width={220}
                    fontSize="12px"
                    toolTipContent="Subscript calculates the % or the amount if you fill in one of the fields"
                    hideArrow
                  >
                    <FormikCustomInput
                      name={`${key}.percent`}
                      label="%"
                      precision={3}
                      data-cy={`billing__invoice_schedule-modal__invoice__${key}-percent-input`}
                      type="number"
                      labelFlex="end"
                      handleChange={async (value) => {
                        const newAmount = calculateAmountFromPercent({ percent: value, total: values.base_amount });

                        syncInvoiceFields({
                          ...values,
                          [key]: {
                            ...values[key],
                            newPercent: value,
                            newAmount:
                              key === INVOICE_ITEM_TYPE_TO_KEY[INVOICE_ITEM_TYPES.DISCOUNT]
                                ? -Math.abs(newAmount)
                                : newAmount,
                          },
                        });
                      }}
                      disabled={
                        disableEditing ||
                        (usesGLConfiguredTaxCodes && key === INVOICE_ITEM_TYPE_TO_KEY[INVOICE_ITEM_TYPES.TAXES])
                      }
                      value={Math.abs(values[key].percent ?? 0)}
                      {...getFieldChangesProps({
                        values,
                        field: `${key}.percent`,
                      })}
                    />
                  </TooltipContainer>

                  <EqualSign>=</EqualSign>

                  <TooltipContainer
                    width={220}
                    fontSize="12px"
                    toolTipContent="Subscript calculates the % or the amount if you fill in one of the fields"
                    hideArrow
                  >
                    <FormikCustomInput
                      inputWidth="120px"
                      name={`${key}.amount`}
                      data-cy={`billing__invoice_schedule-modal__invoice__${key}-amount-input`}
                      label="Amount"
                      suffix={ISO_CODE_TO_SYMBOL[values?.invoice_items?.[0]?.currency ?? currency] ?? '$'}
                      precision={2}
                      type="number"
                      labelFlex="end"
                      handleChange={(value) => {
                        syncInvoiceFields({
                          ...values,
                          [key]: {
                            ...values[key],
                            newAmount: value,
                            newPercent: calculatePercentFromAmount({ amount: value, total: values.base_amount }),
                          },
                        });
                      }}
                      disabled={
                        disableEditing ||
                        (usesGLConfiguredTaxCodes && key === INVOICE_ITEM_TYPE_TO_KEY[INVOICE_ITEM_TYPES.TAXES])
                      }
                      {...getFieldChangesProps({
                        values,
                        field: `${key}.amount`,
                      })}
                    />
                  </TooltipContainer>

                  {usesGLConfiguredTaxCodes && key === INVOICE_ITEM_TYPE_TO_KEY[INVOICE_ITEM_TYPES.TAXES] ? null : (
                    <InvoiceItemLink
                      style={{
                        marginTop: 28,
                      }}
                    >
                      <TrashIcon
                        data-cy={`billing__invoice_schedule-modal__invoice__${key}-remove`}
                        onClick={() => {
                          setFieldValue(key, null);

                          syncInvoiceFields({
                            ...values,
                            [key]: null,
                          });
                        }}
                      />
                    </InvoiceItemLink>
                  )}
                </Flexer>
                {key === INVOICE_ITEM_TYPE_TO_KEY[INVOICE_ITEM_TYPES.TAXES] &&
                  values?.[key]?.metadata?.[MAGIC_METADATA.EXTERNAL_TAX_CODE] && (
                    <TaxCodeDescription
                      style={{
                        marginTop: 8,
                      }}
                    >
                      {`${invoicingServiceDisplayName} Tax Code: ${
                        values[key].metadata[MAGIC_METADATA.EXTERNAL_TAX_CODE]
                      }`}
                    </TaxCodeDescription>
                  )}
              </FlexerColumn>
            ),
        )}

        <Flexer justifyContent="space-between" width="100%" style={{ marginTop: 16 }} gap="8px">
          <FlexerColumn gap="16px">
            {!disableEditing && (
              <>
                <Flexer gap="8px" justifyContent="flex-start">
                  <StyledCirclePlusIcon />

                  <InlineButton
                    lineHeight="15px"
                    fontSize="12px"
                    isSecondary
                    withBackground
                    onClick={addInvoiceItem}
                    type="button"
                    data-cy="billing__invoice_schedule-modal__invoice__add-item-button"
                  >
                    Add Item
                  </InlineButton>

                  <InlineButton
                    lineHeight="15px"
                    fontSize="12px"
                    isSecondary
                    withBackground
                    onClick={openMergeInvoiceItemsModal}
                    type="button"
                    data-cy="billing__invoice_schedule-modal__invoice__merge-item-button"
                  >
                    Merge Items
                  </InlineButton>
                </Flexer>

                <Flexer gap="8px" justifyContent="flex-start">
                  <FooterSubText>More:</FooterSubText>

                  {!showTaxesSection && !usesGLConfiguredTaxCodes && (
                    <InlineButton
                      lineHeight="15px"
                      fontSize="12px"
                      data-cy="billing__invoice_schedule-modal__invoice__add-taxes-button"
                      isSecondary
                      withBackground
                      type="button"
                      onClick={() => {
                        setFieldValue(INVOICE_ITEM_TYPE_TO_KEY[INVOICE_ITEM_TYPES.TAXES], {
                          description: taxLabel ?? 'Total Tax',
                          percent: 0,
                          amount: 0,
                        });
                      }}
                    >
                      Add Taxes
                    </InlineButton>
                  )}
                  {!showFeesSection && (
                    <InlineButton
                      data-cy="billing__invoice_schedule-modal__invoice__add-fee-button"
                      lineHeight="15px"
                      fontSize="12px"
                      isSecondary
                      withBackground
                      type="button"
                      onClick={() => {
                        setFieldValue(INVOICE_ITEM_TYPE_TO_KEY[INVOICE_ITEM_TYPES.FEE], {
                          description: 'Total Fee',
                          percent: 0,
                          amount: 0,
                        });
                      }}
                    >
                      Add Fees
                    </InlineButton>
                  )}
                  {!showShippingSection && (
                    <InlineButton
                      data-cy="billing__invoice_schedule-modal__invoice__add-shipping-button"
                      lineHeight="15px"
                      fontSize="12px"
                      isSecondary
                      withBackground
                      type="button"
                      onClick={() => {
                        setFieldValue(INVOICE_ITEM_TYPE_TO_KEY[INVOICE_ITEM_TYPES.SHIPPING], {
                          description: 'Shipping',
                          amount: 0,
                        });
                      }}
                    >
                      Add Shipping
                    </InlineButton>
                  )}

                  {!showDiscountSection && (
                    <InlineButton
                      data-cy="billing__invoice_schedule-modal__invoice__add-discount-button"
                      lineHeight="15px"
                      fontSize="12px"
                      isSecondary
                      withBackground
                      type="button"
                      onClick={() => {
                        setFieldValue(INVOICE_ITEM_TYPE_TO_KEY[INVOICE_ITEM_TYPES.DISCOUNT], {
                          description: 'Discount',
                          amount: 0,
                        });
                      }}
                    >
                      Add Discount
                    </InlineButton>
                  )}
                </Flexer>
              </>
            )}
          </FlexerColumn>

          <FlexerColumn alignItems="flex-end" gap="8px">
            {isWaitingForTaxes ? (
              <TaxesLoader invoicingServiceDisplayName={invoicingServiceDisplayName} loaderName="invoice-taxes" />
            ) : (
              !disableEditing &&
              !showTaxesSection &&
              usesGLConfiguredTaxCodes && (
                <AutomaticTaxNotification>
                  {`Save the invoice in ${invoicingServiceDisplayName} so that Subscript can use the `}
                  <span>{`tax calculations from ${invoicingServiceDisplayName}`}</span>
                </AutomaticTaxNotification>
              )
            )}
            <AmountBalanceWrapper>
              <div>
                Line items total:{' '}
                <span data-cy="billing__invoice_schedule-modal__invoice__items-total-excluding-credit">
                  {numberFormatter({
                    rawValue: values?.invoice_items?.reduce((acc, curr) => acc + curr.amount, 0),
                    type: NUMBER_FORMATS.CURRENCY,
                    decimalPlaces: 2,
                    currency: values?.invoice_items?.[0]?.currency ?? currency,
                  })}
                </span>
              </div>
              {!isWaitingForTaxes && (
                <div>
                  Final invoice balance:{' '}
                  <span data-cy="billing__invoice_schedule-modal__invoice__items-total">
                    {numberFormatter({
                      rawValue: values?.amount,
                      type: NUMBER_FORMATS.CURRENCY,
                      decimalPlaces: 2,
                      currency: values?.invoice_items?.[0]?.currency ?? currency,
                    })}
                  </span>
                </div>
              )}
              {defaultOrgCurrency !== currency &&
              getServiceCategory(invoicingService) === INTEGRATION_SERVICES.QUICKBOOKS ? (
                <p>
                  We will send the Subscript conversion rate to quickbooks <br />
                  from {currency} to {defaultOrgCurrency}
                </p>
              ) : null}
            </AmountBalanceWrapper>
          </FlexerColumn>
        </Flexer>
      </InvoiceItemsWrapper>

      <MergeInvoiceItemsModal />
    </>
  );
};
