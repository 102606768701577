import { useContext, useEffect, useState } from 'react';

import { useToasts } from 'components/Toasts';

import { SelectCustomerModal } from './SelectCustomerModal';
import { SettingsModal } from '../InvoicingScheduleTabsPanel/InvoiceSettings/useSettingsModal';
import { InvoicingScheduleContext } from '../InvoicingScheduleContext';
import { useInvoicingScheduleFrequencyDropdown } from '../InvoicingScheduleTabsPanel/useInvoicingScheduleFrequencyDropdown';
import { PreviewScheduleModal } from '../InvoicingScheduleSelectTransactionsPanel/PreviewScheduleModal';

export const CREATE_INVOICING_SCHEDULE_MODES = {
  PREVIEW: 'preview',
  SETTINGS: 'settings',
  SELECT_CUSTOMER: 'select_customer',
};
export const CreateInvoicingScheduleModal = ({ initialMode = CREATE_INVOICING_SCHEDULE_MODES.SETTINGS, onClose }) => {
  const {
    scheduleFormRef,
    invoicingScheduleFormValues,
    invoicesCreatedInGl,
    includedTransactions,
    defaultFrequency,
    setInvoiceUpdateLoading,
    initialOpenRef,
  } = useContext(InvoicingScheduleContext);

  const { pushError } = useToasts();

  const [mode, setMode] = useState(
    invoicingScheduleFormValues?.invoices?.length ? CREATE_INVOICING_SCHEDULE_MODES.PREVIEW : initialMode,
  );
  const [shouldAutoGenerateInvoices, setShouldAutoGenerateInvoices] = useState(!!defaultFrequency);

  const setFieldValue = scheduleFormRef?.current?.setFieldValue;

  const { regenerateInvoices } = useInvoicingScheduleFrequencyDropdown({
    setFieldValue: scheduleFormRef?.current?.setFieldValue,
    importedInvoices: invoicesCreatedInGl,
    invoices: invoicingScheduleFormValues?.invoices ?? [],
  });

  useEffect(() => {
    const onRegenerateInvoices = async () => {
      setInvoiceUpdateLoading(true);
      setMode(CREATE_INVOICING_SCHEDULE_MODES.PREVIEW);
      setFieldValue && setFieldValue('invoicing_frequency', defaultFrequency);
      let result;

      try {
        result = await regenerateInvoices({
          frequency: defaultFrequency,
          allowPastInvoices: true,
        });
      } finally {
        if (!result?.length) setMode(CREATE_INVOICING_SCHEDULE_MODES.SETTINGS);
        setInvoiceUpdateLoading(false);
      }
    };

    if (
      initialOpenRef?.current &&
      !!defaultFrequency &&
      !invoicingScheduleFormValues?.invoices?.length &&
      invoicingScheduleFormValues?.customer_id
    ) {
      initialOpenRef.current = false;
      onRegenerateInvoices();
      setShouldAutoGenerateInvoices(false);
    }
  }, [
    defaultFrequency,
    initialOpenRef,
    invoicingScheduleFormValues?.customer_id,
    invoicingScheduleFormValues?.invoices?.length,
    regenerateInvoices,
    setFieldValue,
    setInvoiceUpdateLoading,
    shouldAutoGenerateInvoices,
  ]);

  return (
    <>
      {mode === CREATE_INVOICING_SCHEDULE_MODES.SELECT_CUSTOMER && (
        <SelectCustomerModal onClose={onClose} onSave={() => setMode(CREATE_INVOICING_SCHEDULE_MODES.SETTINGS)} />
      )}
      {mode === CREATE_INVOICING_SCHEDULE_MODES.SETTINGS && (
        <SettingsModal
          onClose={onClose}
          onSave={async ({ ignoreImportInvoiceDates, regenerateAfterExistingInvoice } = {}) => {
            scheduleFormRef?.current?.setFieldTouched('invoicing_frequency', true);
            scheduleFormRef?.current?.validateField('invoicing_frequency');

            if (!includedTransactions?.length) {
              pushError(`Please select at least one transaction to create an invoice schedule.`, 'error');
              return;
            }

            if (invoicingScheduleFormValues?.invoicing_frequency) {
              const hasInvoicesToNotRegenerate = invoicingScheduleFormValues?.invoices?.some(
                (invoice) => invoice.invoice_external_id,
              );
              const result = await regenerateInvoices({
                frequency: invoicingScheduleFormValues.invoicing_frequency,
                hasInvoicesToNotRegenerate,
                ignoreImportInvoiceDates,
                regenerateAfterExistingInvoice,
              });

              if (result?.length) setMode(CREATE_INVOICING_SCHEDULE_MODES.PREVIEW);
            }
          }}
          onEditCustomer={() => setMode(CREATE_INVOICING_SCHEDULE_MODES.SELECT_CUSTOMER)}
        />
      )}
      {mode === CREATE_INVOICING_SCHEDULE_MODES.PREVIEW && (
        <PreviewScheduleModal
          onClose={onClose}
          onBack={() => setMode(CREATE_INVOICING_SCHEDULE_MODES.SETTINGS)}
          previewScheduleData={invoicingScheduleFormValues}
          confirmButtonLabel="Confirm Generating Invoices"
          showCheckBox
        />
      )}
    </>
  );
};
