import React from 'react';
import { components } from 'react-select';
import { FlexBetweenContainer } from 'components/Core';
import { CirclePlusIcon, TransactionIcon } from 'components/Icons';
import { useFormikContext } from 'formik';
import { isNil } from 'lodash';
import { FormikCustomSelector } from 'components/Controls';
import { TooltipContainer } from 'components/Tooltip';
import styled from 'styled-components';

export const TRANSACTION_TYPES = {
  NEW_LINEAR_RECURRING: 'NEW_LINEAR_RECURRING',
  NEW_LINEAR_NONRECURRING: 'NEW_LINEAR_NONRECURRING',
  NEW_IMMEDIATE: 'NEW_IMMEDIATE',
  USE_EXISTING: 'USE_EXISTING',
};

const StyledTransactionIcon = styled(TransactionIcon)`
  path {
    fill: var(--primaryBlack50);
  }
`;

const TRANSACTION_TYPE_OPTIONS = [
  {
    label: 'Create a new linear (recurring) transaction',
    subLabel: 'Reverses ARR, spreads out RevRec reversal',
    value: TRANSACTION_TYPES.NEW_LINEAR_RECURRING,
    icon: <CirclePlusIcon color="var(--primaryBlack50)" />,
  },
  {
    label: 'Create a new linear (not recurring) transaction',
    subLabel: 'Does not reverse ARR, spreads out RevRec reversal',
    value: TRANSACTION_TYPES.NEW_LINEAR_NONRECURRING,
    icon: <CirclePlusIcon color="var(--primaryBlack50)" />,
  },
  {
    label: 'Create a new immediate transaction',
    subLabel: 'Does not reverse ARR, RevRec reversal all at once',
    value: TRANSACTION_TYPES.NEW_IMMEDIATE,
    icon: <CirclePlusIcon color="var(--primaryBlack50)" />,
  },
  {
    label: 'Use existing transaction',
    subLabel: 'Does not reverse ARR, does not reverse RevRec',
    value: TRANSACTION_TYPES.USE_EXISTING,
    icon: <StyledTransactionIcon />,
  },
];

const SelectorWrapper = styled.div`
  width: 100%;
`;

export const TransactionTypeSelector = ({ index, readOnly }) => {
  const { values, setFieldValue } = useFormikContext();

  const Option = (props) => (
    <components.Option {...props}>
      <FlexBetweenContainer alignItems="flex-start" gap="12px">
        <div style={{ flexShrink: 0, width: '16px', height: '16px', marginTop: '2px' }}>{props.data.icon}</div>
        <div style={{ flex: 1 }}>
          <div
            style={{ fontWeight: 700, color: 'var(--primaryBlack)', marginBottom: '2px' }}
            data-cy={`credit-note-view__credit-note-item--${index}__transaction-type-selector__option-${props.data.label}`}
          >
            {props.data.label}
          </div>
          <div style={{ color: 'var(--primaryBlack70)', fontSize: '12px' }}>{props.data.subLabel}</div>
        </div>
      </FlexBetweenContainer>
    </components.Option>
  );

  const SingleValue = (props) => (
    <components.SingleValue {...props}>
      <FlexBetweenContainer alignItems="center" gap="8px">
        <div
          style={{
            flexShrink: 0,
            width: '16px',
            height: '16px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {props.data.icon}
        </div>
        <div style={{ flex: 1 }}>{props.data.label}</div>
      </FlexBetweenContainer>
    </components.SingleValue>
  );

  React.useEffect(() => {
    const currentItem = values.items[index];
    if (currentItem.transaction_id && isNil(currentItem.transaction_type)) {
      setFieldValue(`items[${index}].transaction_type`, TRANSACTION_TYPES.USE_EXISTING);
    }
  }, [values.items, index, setFieldValue]);

  const selectedOption = TRANSACTION_TYPE_OPTIONS.find(
    (option) => option.value === values.items[index].transaction_type,
  );

  return (
    <SelectorWrapper>
      <FormikCustomSelector
        label={
          index === 0 && (
            <TooltipContainer toolTipContent="Assign a transaction that matches the credit note item so Subscript can use for recurring revenue and accounting revenue calculations">
              Transaction type
            </TooltipContainer>
          )
        }
        placeholder="Select transaction"
        name={`items[${index}].transaction_type`}
        options={TRANSACTION_TYPE_OPTIONS}
        components={{ Option, SingleValue }}
        value={selectedOption}
        handleChange={({ value }) => {
          setFieldValue(`items[${index}].transaction_type`, value);
          setFieldValue(`items[${index}].transaction_id`, null);
        }}
        containerWidth="100%" // Changed from 80% to 100%
        noSort
        data-cy={`credit-note-view__credit-note-item--${index}__transaction-type-selector`}
        isDisabled={readOnly}
      />
    </SelectorWrapper>
  );
};
