import axios from 'axios';
import { API_URL } from 'api/consts';

export const getRevenueSpreads = async ({ orgId, params, body }) => {
  const url = `${API_URL}/organizations/${orgId}/revenue-spreads`;
  const { data } = await axios.post(url, body, { params });
  return data;
};

export const bulkCreateSpreads = async ({ orgId, data }) => {
  const url = `${API_URL}/organizations/${orgId}/spreads/bulk-create`;
  const { data: response } = await axios.post(url, data);
  return response;
};
