import styled from 'styled-components';
import { FlexerColumn } from 'components/Core/Flex';

export const LeftSide = styled(FlexerColumn)`
  border-right: 1px solid var(--accentGraySecond);
  align-items: center;
`;

export const SelectedCounter = styled.span`
  font-size: 10px;
  font-weight: 900;
  margin: 8px;
  padding: 2px 6px;
  border-radius: 4px;
  background-color: var(--primaryBlue10);
  color: var(--primaryBlue);
`;

export const Option = styled.div`
  cursor: pointer;
  font-size: 11px;
  font-weight: 700;
  width: fit-content;
  height: fit-content;
  margin: 8px;
  padding: 8px 12px;
  background: ${({ selected }) => (selected ? 'var(--primaryBlue10)' : 'var(--primaryBlack2)')};
  border: ${({ selected }) => (selected ? '1px solid var(--primaryBlue)' : 'none')};
  color: ${({ selected }) => (selected ? 'var(--primaryBlue)' : 'black')};
  border-radius: 100px;
  border: 1px solid ${({ selected }) => (selected ? 'var(--primaryBlue)' : 'var(--primaryBlack2)')};

  &:hover {
    color: var(--primaryBlue);
    border: 1px solid var(--primaryBlue);
  }
`;
