import { useContext } from 'react';
import styled from 'styled-components';
import { ReactComponent as CloseIcon } from 'images/close-icon.svg';
import { TransactionContext } from '../TransactionContext';
import { TransactionUnsavedWarning } from './TransactionUnsavedWarning';

const StyledCloseIcon = styled(CloseIcon)`
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  height: 38px;
  width: 38px;

  rect {
    fill: var(--accentGray);
  }

  path {
    fill: var(--primaryBlack);
  }
`;

export const TransactionCloseButton = () => {
  const { closeModal, safeCloseModal, setShowUnsavedWarning, showUnsavedWarning, transactionFormValues } = useContext(
    TransactionContext,
  );

  return showUnsavedWarning ? (
    <TransactionUnsavedWarning onConfirm={closeModal} onCancel={() => setShowUnsavedWarning(false)} />
  ) : (
    <StyledCloseIcon
      data-cy="transaction-modal__close-button"
      height={30}
      onClick={() => safeCloseModal(transactionFormValues)}
    />
  );
};
