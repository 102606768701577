export const EXTERNAL_UPDATES_TABLE_COLUMNS = {
  id: {
    key: 'id',
    name: 'Id',
    isFilterable: false,
    isHideable: false,
  },
  created_at: {
    key: 'created_at',
    name: 'Created At',
    isFilterable: false,
    isHideable: false,
  },
  date: {
    key: 'date',
    name: 'Date',
    isFilterable: true,
    isHideable: true,
  },
  name: {
    key: 'name',
    name: 'Name',
    isFilterable: true,
    isHideable: false,
  },
  amount: {
    key: 'amount',
    name: 'Total',
    isFilterable: true,
    isHideable: true,
  },
  recurring_amount: {
    key: 'recurring_amount',
    name: 'Recurring',
    isFilterable: true,
    isHideable: true,
  },
  seats: {
    key: 'seats',
    name: 'Seats',
    isFilterable: true,
    isHideable: true,
  },
  customer_name: {
    key: 'customer_name',
    name: 'Customer',
    isFilterable: true,
    isHideable: true,
  },
  product_name: {
    key: 'product_name',
    name: 'Product',
    isFilterable: true,
    isHideable: true,
  },
  recognition: {
    key: 'recognition',
    name: 'Type',
    isFilterable: true,
    isHideable: true,
  },
  start_date: {
    key: 'start_date',
    name: 'Start',
    isFilterable: true,
    isHideable: true,
  },
  end_date: {
    key: 'end_date',
    name: 'End',
    isFilterable: true,
    isHideable: true,
  },
  replaced_by: {
    key: 'replaced_by',
    name: 'Replaced By',
    isFilterable: true,
    isHideable: true,
  },
  metadata: {
    key: 'metadata',
    name: null,
    isFilterable: true,
    isHideable: null,
  },
  new_spreads: {
    key: 'new_spreads',
    name: 'New Spreads',
    isFilterable: true,
    isHideable: true,
  },
  changed_spreads: {
    key: 'changed_spreads',
    name: 'Changed Spreads',
    isFilterable: true,
    isHideable: true,
  },
  deleted_spreads: {
    key: 'deleted_spreads',
    name: 'Deleted Spreads',
    isFilterable: true,
    isHideable: true,
  },
  renewal_deadline: {
    key: 'renewal_deadline',
    name: 'Renewal Deadline',
    isFilterable: true,
    isHideable: true,
  },
  include_end_month: {
    key: 'include_end_month',
    name: 'Include End Month',
    isFilterable: true,
    isHideable: true,
  },
};

export const HIDEABLE_COLUMNS = {
  date: EXTERNAL_UPDATES_TABLE_COLUMNS.date.name,
  amount: EXTERNAL_UPDATES_TABLE_COLUMNS.amount.name,
  recurring_amount: EXTERNAL_UPDATES_TABLE_COLUMNS.recurring_amount.name,
  seats: EXTERNAL_UPDATES_TABLE_COLUMNS.seats.name,
  customer_name: EXTERNAL_UPDATES_TABLE_COLUMNS.customer_name.name,
  product_name: EXTERNAL_UPDATES_TABLE_COLUMNS.product_name.name,
  recognition: EXTERNAL_UPDATES_TABLE_COLUMNS.recognition.name,
  start_date: EXTERNAL_UPDATES_TABLE_COLUMNS.start_date.name,
  end_date: EXTERNAL_UPDATES_TABLE_COLUMNS.end_date.name,
  renewal_deadline: EXTERNAL_UPDATES_TABLE_COLUMNS.renewal_deadline.name,
  replaced_by: EXTERNAL_UPDATES_TABLE_COLUMNS.replaced_by.name,
  include_end_month: EXTERNAL_UPDATES_TABLE_COLUMNS.include_end_month.name,
  transactions: 'Transactions',
};

export const EXTERNAL_UPDATE_TYPES = {
  edit: 'edit',
  delete: 'delete',
  resync: 'resync',
};

export const EXTERNAL_UPDATES_SUPPORTED_RESOURCES = {
  TRANSACTION: 'transaction',
  INVOICING_SCHEDULE: 'invoicing_schedule',
};

export const EXTERNAL_UPDATE_ACTION_OPTIONS = {
  overwrite: 'overwrite',
  edit: 'edit',
  dismiss: 'dismiss',
};
