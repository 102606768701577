import React, { useCallback, useContext, useEffect, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { isEmpty } from 'lodash';
import { useCustomersAPI } from 'api/customers';
import { CUSTOMERS_TABLE_COLUMN_TITLES_BY_ID } from 'consts/global';
import { EVENTS } from 'consts/analytics';
import { AppContext } from 'AppContext';
import { Spacer } from 'components/Core';
import { TimeLoader, TimeLoaderContainer } from 'components/Loaders';
import { ReactComponent as AddIcon } from 'images/actions-dropdown__add.svg';
import { ReactComponent as CsvIcon } from 'images/actions-dropdown__csv.svg';
import { Header, HEADER_TITLES } from 'shared/Layout';
import { useSyncedExport } from 'shared/SyncedExports';
import { useLinkShare } from 'shared/LinkShare';
import { NoItems } from 'shared/ErrorBars';
import { ActionsPopover } from 'shared/Common';
import { METADATA_FILTER_TYPES } from 'shared/Filters/MetadataFilter';
import { GlobalTogglesContextProvider, GlobalTogglesFloatingContainer } from 'shared/GlobalToggles';
import { Filters, CustomerTypeFilter, CUSTOMER_TYPES_OPTIONS } from 'shared/Filters';
import { sortByToKnexOrderBy } from 'utils/tableUtils';
import { useAnalytics, useCachedMetrics, useStateWithStorage } from 'utils/hooks';
import { CustomersContext } from '../CustomersContext';
import { TABS as CUSTOMER_TABLE_TABS } from '../CustomerSingle/CustomerTablesTabs';
import { CustomersTable } from './CustomersTable';
import { CustomersActionsModal } from '../CustomersActionsModal';
import { buildCustomersTree, cleanCustomerMetadata, getRequiredScopes } from './utils';
import { generateCustomersListColumns } from './generateCustomersListColumns';
import { OrgNoCustomers } from './OrgNoCustomers';

dayjs.extend(utc);

export const CustomersListContent = () => {
  const {
    organizations,
    dateFormat,
    appSettings: { isARR, isCommitted, rollup, currencyISOCode: currency },
  } = useContext(AppContext);
  const pageState = useContext(CustomersContext);
  const { trackEvent } = useAnalytics();
  const {
    customersListSearchQuery,
    setCustomersListSearchQuery,
    customersListPageIndex,
    setCustomersListPageIndex,
    customersListPageSize,
    setCustomersListPageSize,
    sortBy,
    setSortBy,
    dataFilter,
    setDataFilter,
    metadataFilter,
    setMetadataFilter,
  } = pageState;
  const [customers, setCustomers] = useState(null);
  const [showCustomersActionModal, setShowCustomersActionModal] = useState(false);
  const [dataForModal, setDataForModal] = useState({});
  const [modalAction, setModalAction] = useState();
  const [pageCount, setPageCount] = useState();
  const [visibleColumns, setVisibleColumns] = useState(['name', 'actions']);
  // eslint-disable-next-line no-unused-vars
  const [activeCustomerSingleTab, setActiveCustomerSingleTab] = useStateWithStorage(
    'customer-page-tab',
    CUSTOMER_TABLE_TABS.REVENUE.title,
  );

  const cleanedCustomerMetadata = cleanCustomerMetadata(metadataFilter?.[METADATA_FILTER_TYPES.CUSTOMERS] ?? []);
  const { LinkShareButton, LinkShareModal } = useLinkShare({ headerTitle: HEADER_TITLES.customers });
  const { SyncedExportInfoBar, SyncedExportModal, ExportButton } = useSyncedExport({
    orgId: organizations[0].id,
    type: 'customers',
    buttonWhite: true,
    customization: ({ selectAllData }) => ({
      isARR,
      types: dataFilter.customerTypes,
      isCommitted,
      rollup,
      ...(!selectAllData && { customerMetadata: cleanedCustomerMetadata }),
    }),
    isFilterOn: !!metadataFilter?.[METADATA_FILTER_TYPES.CUSTOMERS],
  });

  const params = useMemo(() => {
    const toReturn = {
      page: customersListPageIndex,
      limit: customersListPageSize,
      types: dataFilter.customerTypes,
      scopes: getRequiredScopes({ visibleColumns }),
      asOf: dayjs.utc().startOf('day').toISOString(),
      isCommitted,
      rollup,
      searchParams: isEmpty(customersListSearchQuery) ? undefined : customersListSearchQuery,
      orderBy: isEmpty(sortBy) ? undefined : sortByToKnexOrderBy(sortBy),
      customerMetadata: cleanedCustomerMetadata,
      includeCount: true,
      includeLastTransactionUpdatedAt: true,
    };

    return toReturn;
  }, [
    customersListPageIndex,
    customersListSearchQuery,
    customersListPageSize,
    sortBy,
    isCommitted,
    rollup,
    dataFilter,
    cleanedCustomerMetadata,
    visibleColumns,
  ]);

  const {
    data,
    metadata,
    error,
    isLoading,
    isFetching,
    dataUpdatedAt,
    operations: { refetch },
  } = useCustomersAPI({
    orgId: organizations?.[0]?.id,
    filters: { params },
  });

  useEffect(() => {
    if (!isLoading && !isFetching) {
      setCustomers(data ? buildCustomersTree(data) : null);
      setPageCount(metadata?.maxPage);
    }
  }, [data, metadata, isLoading, isFetching, dataUpdatedAt]);

  useCachedMetrics({
    readyData:
      dataFilter.customerTypes.length === 1 &&
      dataFilter.customerTypes[0] === CUSTOMER_TYPES_OPTIONS.LIVE &&
      !isLoading &&
      !isFetching &&
      !isCommitted && // customersService.list on the backend is not yet fully supporting committed mode
      isEmpty(metadataFilter) &&
      isEmpty(customersListSearchQuery) &&
      metadata
        ? metadata
        : null,
    getValue: ({ readyData }) => readyData?.count,
    description: `All customers count from Customers List page`,
    storageKey: `allCustomerCount.${rollup ? 'withRollup' : 'withoutRollup'}.${dayjs().format('YYYY-MM')}`,
    context: 'Customers List',
  });

  const closeModalCallback = () => {
    setShowCustomersActionModal(false);
  };

  const columns = useMemo(
    () =>
      generateCustomersListColumns({
        isARR,
        setDataForModal,
        setModalAction,
        setShowCustomersActionModal,
        setActiveCustomerSingleTab,
        currency,
        dateFormat,
        rollup,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isARR, currency, rollup],
  );

  const handleVisibleColumnsChange = useCallback(
    (visibleColumns) => {
      setVisibleColumns(visibleColumns);

      trackEvent({
        name: EVENTS.SET_VISIBLE_COLUMNS_ON_CUSTOMERS_PAGE,
        properties: visibleColumns.reduce((acc, curr) => {
          acc[curr] = true;
          return acc;
        }, {}),
      });
    },
    [trackEvent, setVisibleColumns],
  );
  const history = useHistory();

  const TableComponent = useMemo(() => {
    let componentState;
    if (!error && !customers && (isLoading || isFetching)) {
      componentState = <TimeLoader pageName="customers" />;
    } else if (customers && (customers?.length || customersListSearchQuery)) {
      componentState = (
        <div style={{ marginTop: 32 }}>
          <CustomersTable
            columnsTitles={CUSTOMERS_TABLE_COLUMN_TITLES_BY_ID}
            columns={columns}
            dataForTable={customers}
            searchQuery={customersListSearchQuery}
            onSearchQueryChange={setCustomersListSearchQuery}
            pageCount={pageCount}
            currentPageIndex={customersListPageIndex}
            setCurrentPageIndex={setCustomersListPageIndex}
            sortBy={sortBy}
            setSortBy={setSortBy}
            pageSize={customersListPageSize}
            setPageSize={setCustomersListPageSize}
            isFetching={isFetching}
            onVisibleColumnsChange={handleVisibleColumnsChange}
          />
        </div>
      );
    } else if (
      metadataFilter === null &&
      dataFilter.customerTypes.length === Object.keys(CUSTOMER_TYPES_OPTIONS).length
    ) {
      componentState = (
        <OrgNoCustomers
          onCreateClick={() => {
            setModalAction('create');
            setShowCustomersActionModal(true);
          }}
        />
      );
    } else {
      componentState = <NoItems entityName="Customers" buttonCb={refetch} organization={organizations[0]?.id} />;
    }

    return componentState;
  }, [
    columns,
    customers,
    customersListPageIndex,
    customersListPageSize,
    customersListSearchQuery,
    dataFilter.customerTypes,
    error,
    isFetching,
    isLoading,
    metadataFilter,
    organizations,
    pageCount,
    refetch,
    setCustomersListPageIndex,
    setCustomersListPageSize,
    setCustomersListSearchQuery,
    setSortBy,
    sortBy,
    handleVisibleColumnsChange,
  ]);

  return (
    <div style={{ paddingLeft: 20 }}>
      <Header
        activePage="customers"
        headerTitle={HEADER_TITLES.customers}
        headerRight={
          <>
            <LinkShareButton />
            <Spacer width="10px" />
            <ExportButton />
            <Spacer width="10px" />

            <ActionsPopover
              actions={{
                'Create New': {
                  icon: <AddIcon />,
                  cb: () => {
                    setModalAction('create');
                    setShowCustomersActionModal(true);
                    setDataForModal({});
                  },
                },
                'Upload CSV': {
                  icon: <CsvIcon />,
                  cb: () => history.push('/csv/customers'),
                },
              }}
            />
          </>
        }
      />

      <Spacer width="100%">
        <GlobalTogglesContextProvider urlState={pageState}>
          <Filters
            hidePeriodSelection
            hideTimeSelection
            dataFilter={dataFilter}
            setDataFilter={setDataFilter}
            metadataFilter={metadataFilter}
            setMetadataFilter={setMetadataFilter}
            metadataFilterTypes={[METADATA_FILTER_TYPES.CUSTOMERS]}
            showConditions
            showMetadataFilterExplanation={false}
          >
            <CustomerTypeFilter />
          </Filters>
          <SyncedExportInfoBar />

          <TimeLoaderContainer>
            <GlobalTogglesFloatingContainer>
              {TableComponent}
              {rollup && !isEmpty(customersListSearchQuery) && (
                <>
                  <Spacer height="10px" />
                  <i>Showing only parent customers since Roll Up By Parent is on</i>
                </>
              )}
              <Spacer height="40px" />
            </GlobalTogglesFloatingContainer>
          </TimeLoaderContainer>
        </GlobalTogglesContextProvider>

        {showCustomersActionModal && (
          <CustomersActionsModal
            organizationId={organizations?.[0].id}
            closeModal={closeModalCallback}
            modalAction={modalAction}
            customer={dataForModal}
            filters={params}
          />
        )}
      </Spacer>

      <LinkShareModal />
      <SyncedExportModal />
    </div>
  );
};
