import { useCallback, useContext } from 'react';
import dayjs from 'dayjs';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import { AppContext } from 'AppContext';
import { ISO_CODE_TO_SYMBOL, NUMBER_FORMATS } from 'consts/global';
import { useGoalsAPI, useGoalAPI } from 'api/goals';
import { SNAPSHOT_METRIC_BY_KEY, SNAPSHOT_METRICS } from 'consts/snapshotMetrics';
import { formatDateForDatepicker, updateDateFromDatePicker } from 'utils/dateUtils';
import { CustomDatePicker, FormikCustomInput, FormikCustomSelector } from 'components/Controls';
import {
  Modal,
  ModalBody,
  ModalButton,
  ModalCloseIcon,
  ModalContainer,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTitleText,
} from 'components/Modal';
import { Container, FlexBetweenContainer, FlexEndContainer, Flexer, Spacer } from 'components/Core';
import { Loader } from 'components/Loaders';
import { RemoveButton } from 'components/Buttons';
import { PeriodsContainer } from './styles';
import { PeriodsForm } from './PeriodsForm';
import { convertFormToGoalData, convertGoalToFormData } from './utils';
import { useRemoveGoalModal } from './useRemoveGoalModal';

const GOAL_METRICS = Object.values(SNAPSHOT_METRIC_BY_KEY);

export const GoalModal = ({ goalId, initialMetricKey, onClose }) => {
  const {
    orgId,
    appSettings: { isARR, currencyISOCode: currency },
  } = useContext(AppContext);
  const {
    operations: { addGoalWithPeriods, editGoalWithPeriods, removeGoal },
  } = useGoalsAPI({ orgId, autoFetch: false });
  const { data, isLoading } = useGoalAPI({ orgId, goalId });

  const handleRemoveGoal = useCallback(() => {
    if (goalId) {
      onClose();
      removeGoal.mutate({ id: goalId });
    }
  }, [goalId, onClose, removeGoal]);
  const { Modal: RemoveGoalModal, openModal: openRemoveGoalModal } = useRemoveGoalModal({
    goal: data,
    onRemoveGoal: handleRemoveGoal,
  });

  const handleSubmit = (data) => {
    const convertedData = convertFormToGoalData({ data, isARR });
    goalId
      ? editGoalWithPeriods.mutate({ id: goalId, data: convertedData })
      : addGoalWithPeriods.mutate({
          data: convertedData,
        });
    onClose();
  };

  return (
    <>
      <ModalContainer>
        <Modal height="auto" maxHeight="80vh" maxWidth="740px" overflow="auto">
          <ModalHeader>
            <ModalCloseIcon onClose={onClose} />
            <ModalTitle>
              <Flexer>
                <ModalTitleText>
                  <b>Goal Management</b>
                </ModalTitleText>
              </Flexer>
            </ModalTitle>
          </ModalHeader>
          {isLoading ? (
            <div className="w-100 flexer">
              <Loader containerStyles={{ width: 40, margin: 20 }} />
            </div>
          ) : (
            <Formik
              initialValues={{
                ...(goalId
                  ? convertGoalToFormData({
                      data,
                      isARR,
                    })
                  : {
                      name: null,
                      metric: initialMetricKey ?? SNAPSHOT_METRICS.RECURRING_REVENUE.key,
                      startDate: updateDateFromDatePicker(dayjs().startOf('month').toDate()),
                      endDate: null,
                      goal: null,
                      periods: [],
                    }),
                // This allows us to prevent the user can hit save before we get the periods values
                isLoadingPeriods: false,
              }}
              validationSchema={Yup.object({
                name: Yup.string().nullable().required('Name is required'),
                metric: Yup.string().required('Metric is required'),
                startDate: Yup.date()
                  .nullable()
                  .transform((value, originalValue) => {
                    if (originalValue === null) {
                      return originalValue;
                    }
                    // Doing this because Yup min for dates does not fail if both dates are the same.
                    // This makes sure that startDate is at least one hour later than endDate for the same day
                    value.setHours(value.getHours() + 1);
                    return value;
                  })
                  .required('Start date is required'),
                endDate: Yup.date()
                  .nullable()
                  .transform((value, originalValue) => (originalValue !== null ? value : null))
                  .min(Yup.ref('startDate'), 'End date must be later than Start date')
                  .required('End date is required'),
                goal: Yup.number().nullable().required('Goal value is required'),
                periods: Yup.array().required().min(1, 'Please, select Start and End dates'),
              })}
              enableReinitialize={true}
              onSubmit={handleSubmit}
            >
              {({ handleSubmit, values, setFieldValue, getFieldMeta, initialValues }) => {
                const snapshotMetric = SNAPSHOT_METRIC_BY_KEY[values.metric] ?? {};
                const suffix =
                  snapshotMetric.dataType === NUMBER_FORMATS.CURRENCY
                    ? ISO_CODE_TO_SYMBOL[currency] ?? '$'
                    : snapshotMetric.dataType === NUMBER_FORMATS.PERCENT
                    ? '%'
                    : null;
                return (
                  <Form>
                    <ModalBody>
                      <Spacer height="20px" />
                      <Flexer>
                        <Container>
                          <FormikCustomSelector
                            name="metric"
                            placeholder="Select metric..."
                            label="Metric"
                            width="220px"
                            options={GOAL_METRICS.map((t) => ({
                              label: `${isARR && SNAPSHOT_METRIC_BY_KEY[t.key].isARRMetric ? 'Annual ' : ''}${t.label}`,
                              value: t.key,
                            }))}
                          />
                        </Container>
                        <Spacer width="20px" />
                        <Container>
                          <CustomDatePicker
                            formik
                            name="startDate"
                            meta={getFieldMeta('startDate')}
                            label="Start Date"
                            height="38px"
                            selected={values.startDate ? formatDateForDatepicker(values.startDate) : null}
                            onChange={(name, val) => setFieldValue(name, updateDateFromDatePicker(val))}
                            showMonthYearPicker
                            dateFormat="MMM yyyy"
                          />
                        </Container>
                        <Spacer width="20px" />
                        <Container>
                          <CustomDatePicker
                            formik
                            name="endDate"
                            meta={getFieldMeta('endDate')}
                            label="Goal Month"
                            placeholder="Month..."
                            height="38px"
                            selected={values.endDate ? formatDateForDatepicker(values.endDate) : null}
                            onChange={(name, val) => {
                              setFieldValue(name, updateDateFromDatePicker(val));
                              if (!values.name) {
                                // Default name
                                setFieldValue(
                                  'name',
                                  `${isARR && snapshotMetric.isARRMetric ? 'Annual ' : ''}${
                                    snapshotMetric.label
                                  } Goal ${dayjs(val).format('MM/YYYY')}`,
                                );
                              }
                            }}
                            showMonthYearPicker
                            dateFormat="MMM yyyy"
                          />
                        </Container>
                        <Spacer width="20px" />
                        <Container>
                          <FormikCustomInput
                            name="goal"
                            label="Goal"
                            placeholder="Enter goal..."
                            type="number"
                            suffix={suffix}
                          />
                        </Container>
                      </Flexer>

                      {values.name !== null && (
                        <Container>
                          <FormikCustomInput name="name" label="Goal name" placeholder="Enter goal name..." />
                        </Container>
                      )}
                    </ModalBody>
                    <Spacer height="20px" />

                    <PeriodsContainer>
                      <PeriodsForm
                        values={values}
                        setFieldValue={setFieldValue}
                        startDate={values.startDate}
                        endDate={values.endDate}
                        goal={values.goal}
                        suffix={suffix}
                        changed={['metric', 'startDate', 'endDate', 'goal'].some(
                          (field) => initialValues[field] !== values[field],
                        )}
                      />
                    </PeriodsContainer>

                    <ModalFooter>
                      <FlexBetweenContainer>
                        {goalId ? <RemoveButton onClick={openRemoveGoalModal}>Remove Goal</RemoveButton> : <div></div>}
                        <FlexEndContainer>
                          <ModalButton onClick={onClose}>Cancel</ModalButton>
                          <Spacer width="10px" />
                          <ModalButton primary onClick={handleSubmit} disabled={values.isLoadingPeriods}>
                            Save
                          </ModalButton>
                        </FlexEndContainer>
                      </FlexBetweenContainer>
                    </ModalFooter>
                  </Form>
                );
              }}
            </Formik>
          )}
        </Modal>
      </ModalContainer>
      <RemoveGoalModal />
    </>
  );
};
