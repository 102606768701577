import React, { useState } from 'react';
import { ContractsListHeader } from './ContractsListHeader';
import { ContractsListTable } from './ContractsListTable';
import { CreateContractModal } from './CreateContractModal';

export const ContractsListContainer = () => {
  const [showCreateContractModal, setShowCreateContractModal] = useState(false);
  return (
    <div style={{ width: '100%', position: 'relative' }}>
      <ContractsListHeader setShowCreateContractModal={setShowCreateContractModal} />
      <ContractsListTable />
      {showCreateContractModal && <CreateContractModal onClose={() => setShowCreateContractModal(false)} />}
    </div>
  );
};
