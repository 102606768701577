import React from 'react';
import styled from 'styled-components';
import { ReactComponent as ExplainImage } from 'images/ai-main-2.svg';
import { Modal, ModalBody, ModalCloseIcon, ModalContainer, ModalHeader } from 'components/Modal';
import { useClickOutside } from 'utils/hooks';
import { BlueText } from 'components/Core';

const BodyContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 32px;
`;

const ExplainTitle = styled.div`
  font-weight: 900;
  font-size: 20px;
  line-height: 26px;
  margin-bottom: 12px;
`;

const BlueCircle = styled.div`
  width: 8px;
  min-width: 8px;
  height: 8px;
  position: relative;
  top: 5px;
  border-radius: 100px;
  border: 2px solid var(--primaryBlue);
  margin-right: 10px;
`;

const ListTitle = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 900;
  line-height: 20px;
  margin-bottom: 12px;
`;

const ExplainParagraph = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 12px;
`;

const AIText = styled.span`
  background: linear-gradient(180deg, #ffc27a 0%, #ff62dd 48%, #6d48ff 100%);
  background-clip: text;
  padding-right: 1px;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const ExplainList = styled.div`
  display: flex;
  flex-direction: column;
`;

const GrayParagraph = styled.div`
  padding: 12px;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: 20px;
  color: var(--primaryBlack70);
  border-radius: 12px;
  border: 1px solid var(--neutralGray);
`;

const ListItem = styled.div`
  display: flex;
  align-items: flex-start;
  font-size: 14px;
  position: relative;
  font-style: italic;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 8px;
  padding-left: 6px;
`;

export const AiExplainModal = ({ hideModal }) => {
  const modalRef = useClickOutside(() => hideModal());

  return (
    <ModalContainer>
      <Modal maxHeight="646px" padding="4px 4px" ref={modalRef} maxWidth="540px" height="auto" overflow="hidden">
        <ModalHeader>
          <ModalCloseIcon
            data-cy="auto-confirmation-explain-modal__close-button"
            style={{ width: 32, height: 32 }}
            onClose={() => hideModal()}
          />
        </ModalHeader>
        <ModalBody paddingLeft="4px" paddingRight="4px">
          <ExplainImage width="100%" />

          <BodyContent>
            <ExplainTitle>Smart dunning flow</ExplainTitle>
            <ExplainParagraph>
              By using the{' '}
              <b>
                <i>
                  Subscript <AIText>AI</AIText>
                </i>
              </b>
              , our system reads and analyzes emails to find unpaid invoices and send customized reminder messages,
              making the dunning process more efficient and improving cash flow.
            </ExplainParagraph>

            <ExplainList>
              <ListTitle>What rights will the Subscript AI have regarding your emailbox?</ListTitle>

              <ListItem>
                <BlueCircle /> Reading rights: the system will analyze incoming emails only from the emails of the
                customers specified in Subscript
              </ListItem>

              <ListItem>
                <BlueCircle />
                <span>
                  <b>No rights for automatic sending:</b> users will need to confirm generated reminders by AI each time
                  in order to send them
                </span>
              </ListItem>

              <ListTitle style={{ marginTop: 16 }}>How will the Subscript AI generate reminders?</ListTitle>

              <ListItem>
                <BlueCircle />
                <span>
                  The 1st reminder will be generated based on incoming emails from the customers and your example email,
                  the language of which you <BlueText>can edit</BlueText>
                </span>
              </ListItem>

              <ListItem>
                <BlueCircle />
                <span>
                  All subsequent reminders will be generated with the same frequency: 2 weeks by default, but you can
                  also <BlueText>change this.</BlueText>
                </span>
              </ListItem>
            </ExplainList>

            <GrayParagraph>
              If it detects significant information from your communications, it can adjust the frequency of the
              reminders accordingly.
            </GrayParagraph>
          </BodyContent>
        </ModalBody>
      </Modal>
    </ModalContainer>
  );
};
