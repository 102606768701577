import RevenueIcon from 'images/icon_revenue.svg';
import SaaSIcon from 'images/icon_saas.svg';
import UnitEconomicsIcon from 'images/icon_unit_economics.svg';
import EfficiencyIcon from 'images/icon_efficiency.svg';
import { SNAPSHOT_METRICS } from 'consts/snapshotMetrics';
import { setColumnHeaders } from './setColumnHeaders';
import { generateSectionFunction } from './generateSectionFunction';
import { SNAPSHOT_TABLE_METRICS } from './consts';

export const reshapeSnapshotDataForTable = ({
  revenueStats,
  customerStats,
  seatStats,
  saasStats,
  summaryStats,
  selectedColumns,
  quarters,
  quartersYearOffset,
  isARR,
  growthType,
  hasCostAttribution,
  winbackMonths,
}) => {
  const metricsObject = {
    revenueStats,
    customerStats,
    seatStats,
    saasStats,
  };

  const columnHeaders = setColumnHeaders({
    revenueStats,
    selectedColumns,
    quarters,
    quartersYearOffset,
  });

  const createMetricSectionFn = generateSectionFunction({
    metricsObject,
    columnHeaders,
    growthType,
    isARR,
    summaryStats,
    hasCostAttribution,
    winbackMonths,
  });

  const revenueRows = createMetricSectionFn(SNAPSHOT_TABLE_METRICS.REVENUE_ROW_METRICS);

  const saasRows = createMetricSectionFn(SNAPSHOT_TABLE_METRICS.SAAS_ROW_METRICS);

  const unitEconomicsRows = createMetricSectionFn(SNAPSHOT_TABLE_METRICS.UNIT_ECONOMICS_METRICS);

  const efficiencyRows = createMetricSectionFn(SNAPSHOT_TABLE_METRICS.EFFICIENCY_METRICS);

  const returnVal = {
    columnHeaders,
    tooltips: {},
    rows: [
      {
        title: SNAPSHOT_METRICS.REVENUE.label,
        icon: RevenueIcon,
        subRows: revenueRows,
      },
      {
        title: SNAPSHOT_METRICS.SAAS.label,
        icon: SaaSIcon,
        subRows: saasRows,
      },
      {
        title: SNAPSHOT_METRICS.UNIT_ECONOMICS.label,
        icon: UnitEconomicsIcon,
        subRows: unitEconomicsRows,
      },
      {
        title: SNAPSHOT_METRICS.EFFICIENCY.label,
        icon: EfficiencyIcon,
        subRows: efficiencyRows,
      },
    ],
  };

  return returnVal;
};
