import React, { useCallback, useContext, useState } from 'react';
import styled from 'styled-components';

import { ReactComponent as HowItWorksIllustration } from 'images/forecasting-how-it-works-illustration.svg';
import { ReactComponent as InfoIcon } from 'images/info-icon-black.svg';
import { AppContext } from 'AppContext';
import { INTEGRATION_TYPES } from 'consts/integrations';
import { useClickOutside } from 'utils/hooks';
import { capitalize } from 'utils/stringUtils';
import { Modal, ModalBody, ModalContainer } from 'components/Modal';
import { Centerer, Flexer, FlexerColumn } from 'components/Core';

const ModalHeader = styled.div`
  padding: 4px;
  margin-bottom: 20px;
`;

const FloatingCloseButton = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  width: 32px;
  height: 32px;
  border-radius: 16px;

  cursor: pointer;
`;

const Title = styled.div`
  font-weight: 900;
  font-size: 20px;
  line-height: 26px;
`;

const DescriptionText = styled.div`
  margin: 12px 0;

  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`;

const DetailsRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0;
  gap: 8px;
  margin-bottom: 12px;
`;

const Dot = styled.div`
  flex: none;
  width: 8px;
  height: 8px;
  margin: 5px;
  border: 2px solid var(--primaryBlack30);
  border-radius: 50%;
`;

const DetailsText = styled.div`
  margin-bottom: 8px;

  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--primaryBlack);

  :last-child {
    margin-bottom: 0;
  }
`;

const NRRExplainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px;
  gap: 8px;
  margin-bottom: 8px;

  border: 1px solid var(--accentGraySecond);
  border-radius: 8px;
`;

const NRRBox = styled(Centerer)`
  width: 54px;
  height: 48px;
  background: var(--primaryPurple10);
  border-radius: 8px;

  font-weight: 900;
  font-size: 9px;
  line-height: 12px;
  text-align: center;
  text-transform: uppercase;
  color: var(--primaryPurple);
`;

const NRRExplainerColumn = styled(Flexer)`
  flex-direction: column;
  justify-content: space-between;
  width: 84px;
  height: 48px;

  div {
    padding: 4px 12px;
  }
`;

const NRRMonth = styled.div`
  font-weight: 900;
  font-size: 9px;
  line-height: 12px;
  text-align: right;
  text-transform: uppercase;
  color: var(--primaryBlack50);
`;

const NRRPercentage = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: right;
  background: var(--primaryBlack3);
  border-radius: 3px;
`;

const Callout = styled(Flexer)`
  padding: 12px;
  margin-bottom: 28px;

  font-weight: 400;
  font-size: 12px;
  line-height: 16px;

  background: var(--primaryBlack2);
  border-radius: 8px;
  gap: 10px;
`;

const Link = styled.a`
  color: var(--primaryGreen);

  :hover {
    color: var(--secondaryGreen);
  }
`;

const HowItWorksModal = ({ onClose }) => {
  const { integrations } = useContext(AppContext);
  const modal = useClickOutside(onClose);
  const CRMIntegrations = integrations.filter((e) => e.type === INTEGRATION_TYPES.CRM);

  return (
    <>
      <ModalContainer data-cy="forecasting-how-it-works-modal">
        <Modal width="540px" height="auto" overflow="visible" padding="0" ref={modal}>
          <ModalHeader>
            <FloatingCloseButton data-cy="forecasting-how-it-works-modal--close-button" onClick={onClose} />
            <HowItWorksIllustration />
          </ModalHeader>
          <ModalBody paddingLeft="36px" paddingRight="36px">
            <Title>Forecasting</Title>
            <DescriptionText>
              Subscript generates forecasts based on Cohorts Analysis and Net Revenue Retention. This method allows you
              to achieve good accuracy of forecasts if you have a large amount of data for the past months.
            </DescriptionText>

            <DetailsRow>
              <Dot />
              <FlexerColumn>
                <DetailsText>
                  <b>Forecasting revenue from new customers</b> is based on:
                </DetailsText>
                <DetailsText>
                  Sales pipeline, which you can import from {capitalize(CRMIntegrations[0].service)}. Subscript only
                  takes into account deals with recurring revenue that have the non-empty close date.
                </DetailsText>
              </FlexerColumn>
            </DetailsRow>

            <DetailsRow>
              <Dot />
              <FlexerColumn>
                <DetailsText>
                  <b>To forecast revenue from existing customers</b> Subscript calculates the average revenue retention
                  for each month (for example, % from the 1st to the 2nd month).
                </DetailsText>

                <NRRExplainer>
                  <NRRBox>NRR</NRRBox>

                  <NRRExplainerColumn>
                    <NRRMonth>Month 2</NRRMonth>
                    <NRRPercentage>98.75%</NRRPercentage>
                  </NRRExplainerColumn>

                  <NRRExplainerColumn>
                    <NRRMonth>M 3</NRRMonth>
                    <NRRPercentage>99.45%</NRRPercentage>
                  </NRRExplainerColumn>

                  <NRRExplainerColumn>
                    <NRRMonth>M 4</NRRMonth>
                    <NRRPercentage>95.24%</NRRPercentage>
                  </NRRExplainerColumn>

                  <NRRExplainerColumn>
                    <NRRMonth>M 5</NRRMonth>
                    <NRRPercentage>94.20%</NRRPercentage>
                  </NRRExplainerColumn>
                </NRRExplainer>

                <DetailsText>
                  Next, Subscript multiplies the previous revenue values by such retention percentages and shows the
                  forecasted revenue values in future months.
                </DetailsText>
              </FlexerColumn>
            </DetailsRow>

            <Callout>
              <InfoIcon />
              <div>
                More information about this forecasting method and other approaches you can find in our{' '}
                <Link
                  data-cy="forecasting-how-it-works-modal--the-dive-link"
                  href="https://www.subscript.com/the-dive/forecasting-5-major-approaches-used-by-b2b-saas-leaders"
                  target="_blank"
                >
                  “The Dive” blog
                </Link>
                .
              </div>
            </Callout>
          </ModalBody>
        </Modal>
      </ModalContainer>
    </>
  );
};

export const useHowItWorksModal = () => {
  const [showModal, setShowModal] = useState(false);

  const openModal = useCallback(() => {
    setShowModal(true);
  }, [setShowModal]);

  const closeModal = useCallback(() => {
    setShowModal(false);
  }, [setShowModal]);

  const Modal = useCallback(() => (showModal ? <HowItWorksModal onClose={closeModal} /> : null), [
    showModal,
    closeModal,
  ]);

  return {
    openModal,
    Modal,
  };
};
