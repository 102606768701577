import { useContext } from 'react';

import { AppContext } from 'AppContext';
import { useGetAvailableTags } from 'api/billing';
import { CREDIT_NOTE_DYNAMIC_TAGS, DEFAULT_AVAILABLE_TAGS, DESCRIPTION_TAGS } from 'consts/billing';
import { FieldBody, FieldTitle } from 'views/Wizard/styles';
import { FormikCustomInput, FormikEmailSelector } from 'components/Controls';
import { templateToHTMLString } from 'utils/htmlUtils';
import { CentererVertical } from 'components/Core';
import { BillingDynamicFieldsTooltip, DYNAMIC_FIELD_TOOLTIP_SECTIONS } from 'views/Billing/BillingDynamicFieldsTooltip';

import { FormRow, PaddingWrapper } from '../styles';

export const CreditNoteDefaultEmailSettings = ({ values, setFieldValue }) => {
  const { orgId } = useContext(AppContext);

  const {
    transactionMetadataTags = [],
    customerMetadataTags = [],
    transactionCustomerMetadataTags = [],
  } = useGetAvailableTags({ orgId });

  const availableTags = [
    ...DEFAULT_AVAILABLE_TAGS,
    ...CREDIT_NOTE_DYNAMIC_TAGS,
    ...transactionMetadataTags,
    ...customerMetadataTags,
    ...DESCRIPTION_TAGS,
    ...transactionCustomerMetadataTags,
  ];

  return (
    <FieldBody style={{ padding: 0, overflow: 'hidden' }}>
      <PaddingWrapper>
        <FieldTitle>Credit Note Email Defaults</FieldTitle>

        <FormRow marginTop="16px" childrenWidth="100%">
          <FormikEmailSelector
            name="billingCreditNoteDefaults.send_cc"
            label={`Email address to CC for credit note emails`}
            value={values?.billingCreditNoteDefaults?.send_cc?.filter((e) => typeof e === 'string')}
            onChange={(selectedValues) => {
              setFieldValue('billingCreditNoteDefaults.send_cc', selectedValues);
            }}
            smallVersion
            data-cy="billing-credit-notes-setting__email-cc--selector"
          />
        </FormRow>

        <FormRow marginTop="16px" childrenWidth="100%">
          <FormikCustomInput
            name="billingCreditNoteDefaults.email_subject"
            label="Credit Note Email Subject"
            HTMLString={templateToHTMLString({
              text: values?.billingCreditNoteDefaults?.email_subject,
              availableTags,
            })}
            availableTags={availableTags}
            TopRightHeader={
              <CentererVertical gap="8px">
                <BillingDynamicFieldsTooltip
                  tags={{
                    [DYNAMIC_FIELD_TOOLTIP_SECTIONS.GENERAL_TAGS]: [
                      ...DEFAULT_AVAILABLE_TAGS,
                      ...CREDIT_NOTE_DYNAMIC_TAGS,
                    ],
                    [DYNAMIC_FIELD_TOOLTIP_SECTIONS.DESCRIPTION_TAGS]: DESCRIPTION_TAGS,
                    [DYNAMIC_FIELD_TOOLTIP_SECTIONS.TRANSACTION_METADATA_TAGS]: transactionMetadataTags,
                    [DYNAMIC_FIELD_TOOLTIP_SECTIONS.CUSTOMER_METADATA_TAGS]: customerMetadataTags,
                    [DYNAMIC_FIELD_TOOLTIP_SECTIONS.TRANSACTION_CUSTOMER_METADATA_TAGS]: transactionCustomerMetadataTags,
                  }}
                  XOffset={100}
                />
              </CentererVertical>
            }
            type="HTMLEdit"
            placeholder="Enter Credit Note Email Subject..."
            data-cy="billing-credit-notes-setting__email-subject--input"
          />
        </FormRow>

        <FormRow marginTop="16px" childrenWidth="100%">
          <FormikCustomInput
            name="billingCreditNoteDefaults.email_body"
            minHeight="186px"
            label="Credit Note Email Body"
            HTMLString={templateToHTMLString({
              text: values?.billingCreditNoteDefaults?.email_body,
              availableTags,
            })}
            availableTags={availableTags}
            TopRightHeader={
              <CentererVertical gap="8px">
                <BillingDynamicFieldsTooltip
                  tags={{
                    [DYNAMIC_FIELD_TOOLTIP_SECTIONS.GENERAL_TAGS]: [
                      ...DEFAULT_AVAILABLE_TAGS,
                      ...CREDIT_NOTE_DYNAMIC_TAGS,
                    ],
                    [DYNAMIC_FIELD_TOOLTIP_SECTIONS.DESCRIPTION_TAGS]: DESCRIPTION_TAGS,
                    [DYNAMIC_FIELD_TOOLTIP_SECTIONS.TRANSACTION_METADATA_TAGS]: transactionMetadataTags,
                    [DYNAMIC_FIELD_TOOLTIP_SECTIONS.CUSTOMER_METADATA_TAGS]: customerMetadataTags,
                    [DYNAMIC_FIELD_TOOLTIP_SECTIONS.TRANSACTION_CUSTOMER_METADATA_TAGS]: transactionCustomerMetadataTags,
                  }}
                  XOffset={100}
                />
              </CentererVertical>
            }
            type="HTMLEdit"
            placeholder="Enter Credit Note Email Body..."
            data-cy="billing-credit-notes-setting__email-body--input"
          />
        </FormRow>
      </PaddingWrapper>
    </FieldBody>
  );
};
