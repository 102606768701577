import React, { useContext, useState } from 'react';
import { AppContext } from 'AppContext';
import { NUMBER_FORMATS } from 'consts/global';
import { numberFormatter } from 'utils/formatters';
import { reFormatDate } from 'utils/dateUtils';
import { roundToDecimalPlaces } from 'utils/mathUtils';
import { useAccountingSpreadsForContractAPI } from 'api/accountingRevenue/hooks';
import { Centerer, CentererVertical, FlexerColumn, FlexerRow } from 'components/Core';
import { Loader } from 'components/Loaders';
import { TruncateStringWithTooltip } from 'components/Tooltip';
import { AllocationsModal } from '../AllocationsModal';
import {
  Header,
  StyledTable,
  TableBody,
  TableContainer,
  TableHeaderRow,
  TableHeaderTile,
  TableBodyRow,
  TableBodyTile,
  TransactionName,
  TableSubheader,
  GroupingTotal,
  EntireContractLabel,
  TableSubheaderContainer,
  Allocation,
  TransactionAmount,
} from './styles';

export const ContractRevRecTable = ({ contractData, contractId }) => {
  const { orgId } = useContext(AppContext);

  const [showAllocationsModal, setShowAllocationsModal] = useState(false);

  const { data, isLoading, isFetching } = useAccountingSpreadsForContractAPI({
    orgId,
    contractId: contractId,
  });

  if (isLoading || isFetching || !data) {
    return (
      <Centerer width="100%" marginTop="32px">
        <Loader containerStyles={{ width: 40 }} />
      </Centerer>
    );
  }

  const { revrec_allocations: revRecAllocations } = contractData ?? {};
  const { monthKeys = [], total = {}, grouped = {}, separate = {}, currency } = data ?? {};

  const contractTotal = Object.values(total?.monthlyAmounts ?? {}).reduce(
    (acc, monthlyAmount) => acc + monthlyAmount,
    0,
  );

  const groupedTotal = Object.values(grouped).reduce((acc, transaction) => acc + transaction.amount, 0);

  const separateTotal = Object.values(separate).reduce((acc, transaction) => acc + transaction.amount, 0);

  const TransactionRow = ({ transaction, grouped = false, openAllocationsModal }) => (
    <TableBodyRow>
      <TableBodyTile>
        <FlexerRow gap="8px" alignItems="center" justifyContent="space-between" width="100%">
          <CentererVertical gap="8px">
            <TransactionName onClick={() => window.open(`/transactions/${transaction.id}`, '_blank')}>
              <TruncateStringWithTooltip length={25} tooltipWidth={200}>
                {transaction.name}
              </TruncateStringWithTooltip>
            </TransactionName>
            <TransactionAmount>
              {numberFormatter({
                rawValue: transaction.amount,
                type: NUMBER_FORMATS.CURRENCY,
                currency,
              })}
            </TransactionAmount>
          </CentererVertical>
          {grouped && (
            <Allocation onClick={openAllocationsModal}>
              {roundToDecimalPlaces({ number: (revRecAllocations?.[transaction.id] ?? 0) * 100, decimalPlaces: 2 })}%
            </Allocation>
          )}
        </FlexerRow>
      </TableBodyTile>
      {monthKeys.map((monthKey) => (
        <TableBodyTile key={monthKey}>
          {numberFormatter({
            rawValue: transaction?.accountingSpreads?.[monthKey] ?? 0,
            type: NUMBER_FORMATS.CURRENCY,
            currency,
          })}
        </TableBodyTile>
      ))}
    </TableBodyRow>
  );

  return (
    <>
      <FlexerColumn gap="20px">
        <Header>Revenue Recognition</Header>
        {monthKeys?.length ? (
          <TableContainer data-cy="accounting-spreads-table">
            <StyledTable>
              <TableHeaderRow>
                <TableHeaderTile>TRANSACTIONS</TableHeaderTile>
                {monthKeys.map((monthKey) => (
                  <TableHeaderTile data-cy={`accounting-spread-table__month-cell-${monthKey}`} key={monthKey}>
                    {reFormatDate(monthKey, 'YYYY-MM', 'MMM YYYY')}
                  </TableHeaderTile>
                ))}
              </TableHeaderRow>

              <TableBody>
                {/* CONTRACT TOTAL */}
                <FlexerRow marginTop="8px">
                  <TableBodyRow>
                    <TableBodyTile>
                      <CentererVertical gap="8px">
                        <EntireContractLabel>Entire Contract</EntireContractLabel>
                        <TransactionAmount>
                          {numberFormatter({
                            rawValue: contractTotal,
                            type: NUMBER_FORMATS.CURRENCY,
                            currency,
                          })}
                        </TransactionAmount>
                      </CentererVertical>
                    </TableBodyTile>
                    {monthKeys.map((monthKey) => (
                      <TableBodyTile key={monthKey}>
                        {numberFormatter({
                          rawValue: total?.monthlyAmounts?.[monthKey] ?? 0,
                          type: NUMBER_FORMATS.CURRENCY,
                          currency,
                        })}
                      </TableBodyTile>
                    ))}
                  </TableBodyRow>
                </FlexerRow>

                {/* CONTRACT GROUPING */}
                {Object.values(grouped).length > 0 && (
                  <>
                    <FlexerRow width="100%">
                      <TableSubheaderContainer>
                        <TableSubheader>RECOGNIZED BY CONTRACT GROUPING</TableSubheader>
                        <GroupingTotal>
                          Total: {numberFormatter({ rawValue: groupedTotal, type: NUMBER_FORMATS.CURRENCY, currency })}
                        </GroupingTotal>
                      </TableSubheaderContainer>
                    </FlexerRow>

                    {Object.values(grouped).map((transaction) => (
                      <TransactionRow
                        key={transaction.id}
                        transaction={transaction}
                        grouped
                        openAllocationsModal={() => setShowAllocationsModal(true)}
                      />
                    ))}
                  </>
                )}

                {/* CONTRACT SEPARATELY */}
                {Object.values(separate).length > 0 && (
                  <>
                    <FlexerRow width="100%">
                      <TableSubheaderContainer>
                        <TableSubheader>RECOGNIZED SEPARATELY</TableSubheader>
                        <GroupingTotal>
                          Total: {numberFormatter({ rawValue: separateTotal, type: NUMBER_FORMATS.CURRENCY, currency })}
                        </GroupingTotal>
                      </TableSubheaderContainer>
                    </FlexerRow>

                    {Object.values(separate).map((transaction) => (
                      <TransactionRow key={transaction.id} transaction={transaction} />
                    ))}
                  </>
                )}
              </TableBody>
            </StyledTable>
          </TableContainer>
        ) : (
          <span>No Accounting Spreads</span>
        )}
      </FlexerColumn>

      {showAllocationsModal && (
        <AllocationsModal
          orgId={orgId}
          contractData={contractData}
          transactions={Object.values(grouped)}
          onClose={() => setShowAllocationsModal(false)}
        />
      )}
    </>
  );
};
