import styled from 'styled-components';
import { Flexer } from 'components/Core';

export const CardWrapper = styled(Flexer)`
  flex-grow: 1;
  flex-direction: column;
  background: #fff;
  border: 1px solid var(--accentGraySecond);
  border-radius: 16px;
  min-height: 360px;
`;

export const MoreInfoSection = styled(Flexer)`
  @media (max-width: 1440px) {
    display: none;
  }
`;
