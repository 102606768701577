import Select from 'react-select';
import styled from 'styled-components';
import { Checkbox } from 'antd';
import { usePortal } from 'components/Portal';
import { TableSettingsTitle } from 'components/Table';
import { selectStyles } from 'components/Controls';

const StyledSettingsPopover = styled.div`
  width: 220px;
  max-height: 500px;
  overflow-y: scroll;
  right: 120px;
  padding: 18px 18px 10px;
  background-color: white;
  box-shadow: 8px 8px 40px var(--primaryBlack10);
  border-radius: 12px;
  z-index: 1;
`;

const SettingsResultNumber = styled.div`
  margin-bottom: 20px;
`;

const ColumnLabel = styled.h4`
  font-weight: 800;
  font-size: 10px;
  line-height: 14px;
  color: rgba(0, 21, 46, 0.5);
  text-transform: uppercase;
  margin-bottom: 8px;
`;

const ColumnItems = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 8px 0;
`;

const ColumnItemText = styled.p`
  font-size: 14px;
  line-height: 18px;
  margin: 0 0 0 10px;
`;

const DEFAULT_OPTIONS = [
  { label: '10', value: 10 },
  { label: '20', value: 20 },
  { label: '30', value: 30 },
  { label: '40', value: 40 },
  { label: '50', value: 50 },
];

export const TableColumnSettings = ({
  handleShowResultsChange,
  numberValue,
  resultsLabel,
  columnsTitles,
  allColumns,
  options = DEFAULT_OPTIONS,
  tableName,
}) => {
  const { triggerRef, togglePortal, isPortalVisible, Portal } = usePortal({ XOffset: -120, YOffset: -10 });

  return (
    <>
      <TableSettingsTitle
        ref={triggerRef}
        onClick={togglePortal}
        pointer
        data-cy={`${tableName}-table__table-column-settings__trigger`}
      >
        Table Settings
      </TableSettingsTitle>
      {isPortalVisible && (
        <Portal>
          <StyledSettingsPopover data-cy={`${tableName}-table__table-column-settings__portal`}>
            {handleShowResultsChange && (
              <SettingsResultNumber>
                <ColumnLabel>{resultsLabel ?? 'Results on Page:'}</ColumnLabel>
                <Select
                  styles={selectStyles({ fullWidth: true, menuWidth: '100%' })}
                  menuPlacement="auto"
                  isSearchable={false}
                  options={options}
                  onChange={handleShowResultsChange}
                  value={options.find((option) => option.value === numberValue) || ''}
                  // This prevents the dropdown from being cut-off by the portal
                  menuPosition="fixed"
                  inputId="page-size-select"
                />
              </SettingsResultNumber>
            )}
            {allColumns && (
              <div>
                <ColumnLabel>Display Columns:</ColumnLabel>
                {allColumns?.map((column) => {
                  return (
                    columnsTitles[column.id] && (
                      <ColumnItems key={column.id}>
                        <Checkbox
                          style={{ height: 20, width: 20 }}
                          {...column.getToggleHiddenProps()}
                          data-cy={`${tableName}-table__table-column-settings__checkbox--${columnsTitles[column.id]}`}
                        />
                        &nbsp;
                        <ColumnItemText>{columnsTitles[column.id]}</ColumnItemText>
                      </ColumnItems>
                    )
                  );
                })}
              </div>
            )}
          </StyledSettingsPopover>
        </Portal>
      )}
    </>
  );
};
