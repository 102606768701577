import styled from 'styled-components';
import { ReactComponent as Icon } from 'images/external-link-full.svg';

export const ExternalLinkFullIcon = styled(Icon)`
  width: ${({ size }) => size};
  height: ${({ size }) => size};

  stroke: ${({ stroke }) => stroke};
  path {
    fill: ${({ fill }) => fill};
  }
`;
