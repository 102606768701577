import * as Yup from 'yup';
import {
  DEFAULT_INVOICES_TRANSFORMATIONS,
  SOURCE_TYPES,
  NAME_MATCHING_SOURCE,
  INTERNAL_CUSTOMER_ID_SOURCE,
} from './consts';

export const getSchema = ({ customers, customerIdSourceAndType, existingInvoices, invoicingSchedules }) => {
  const customersSet = new Set(customers?.map((customer) => customer.id));
  const existingInvoicesSet = new Set(existingInvoices?.map((invoice) => invoice.id));
  const invoicingScheduleSet = new Set(invoicingSchedules?.map((schedule) => schedule.id));

  const baseSchema = {
    date: Yup.date().nullable(),
    invoicing_schedule_id: Yup.string()
      .nullable()
      .test('valid-schedule-id', 'Invalid invoicing schedule ID', function (value) {
        if (!value) return true;
        return invoicingScheduleSet.has(value);
      }),
    name: Yup.string().nullable(),
    customer_id: Yup.string()
      .nullable()
      .test('customer-id-validation', 'Invalid customer ID', function (value) {
        if (!value) return true;
        if (customerIdSourceAndType.type === SOURCE_TYPES.INTERNAL) {
          return customersSet.has(value);
        }
        if (customerIdSourceAndType.type === SOURCE_TYPES.EXTERNAL) {
          if (customerIdSourceAndType.source === NAME_MATCHING_SOURCE) {
            return customers.some((customer) => customer.name === value);
          }
          if (customerIdSourceAndType.source === INTERNAL_CUSTOMER_ID_SOURCE) {
            return customersSet.has(value);
          }
          return true;
        }
        return false;
      }),
    amount: Yup.number()
      .typeError('Total must be a number')
      .nullable()
      .transform(DEFAULT_INVOICES_TRANSFORMATIONS.amount),
    service_start: Yup.date().nullable().transform(DEFAULT_INVOICES_TRANSFORMATIONS.service_start),
    service_end: Yup.date().nullable().transform(DEFAULT_INVOICES_TRANSFORMATIONS.service_end),
    currency: Yup.string().nullable(),
    metadata: Yup.mixed().nullable(),
    invoice_number: Yup.string().nullable(),
    send_date: Yup.date().nullable().transform(DEFAULT_INVOICES_TRANSFORMATIONS.date),
    memo: Yup.string().nullable(),
    external_notes: Yup.string().nullable(),
    internal_notes: Yup.string().nullable(),
    po_number: Yup.string().nullable(),
    language: Yup.string().nullable(),
    invoice_items: Yup.string().nullable(),
  };

  const createSchema = Yup.array().of(Yup.object(baseSchema));

  const updateSchema = Yup.array().of(
    Yup.object({
      ...baseSchema,
      id: Yup.string()
        .required('Invoice ID is required for updates')
        .test('existing-invoice', 'Invalid invoice ID', (value) => existingInvoicesSet.has(value)),
    }),
  );

  return { createSchema, updateSchema };
};
