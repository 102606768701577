import dayjs from 'dayjs';
import { compareDate, isBetween } from 'utils/dateUtils';

export const getGoalsInsideFiltersPeriod = ({ goalsMetrics, rawGoals, metricKey, startDate }) =>
  (rawGoals ?? []).filter((goal) => {
    const firstPeriodDate = goal?.periods?.[0].start_month;
    const lastPeriodDate = goal?.periods?.[goal?.periods?.length - 1].start_month;
    return (
      // Is a business snapshot metric
      goalsMetrics.includes(goal?.for_metric) &&
      // Goal has periods
      goal?.periods?.length > 0 &&
      // If we have metricKey we want to filter just goals of that metric
      (metricKey ? goal.for_metric === metricKey : true) &&
      // Always include if goal starts after startDate
      (compareDate(firstPeriodDate, startDate) > 0 ||
        isBetween({
          date: startDate,
          startDate: dayjs(firstPeriodDate),
          endDate: dayjs(lastPeriodDate),
          granularity: 'month',
        }))
    );
  });
