import { useContext } from 'react';
import { useStateWithStorage } from 'utils/hooks';
import { TabButton } from 'components/Buttons';
import { TransactionContext } from '../TransactionContext';
import { TRANSACTION_VIEW_MODE } from '../consts';
import { SidebarWrapper, SidebarTabs } from './TransactionSidebar.styles';
import { RelatedTransactions } from './RelatedTransactions';
import { RevenueHistory } from './RevenueHistory';

const SIDEBAR_TABS = { REVENUE_HISTORY: 'revenue history', TRANSACTIONS: 'transactions' };

export const TransactionSidebar = ({ floating }) => {
  const { view } = useContext(TransactionContext);
  const [selectedTab, setSelectedTab] = useStateWithStorage(
    'transaction-modal-selected-tab',
    SIDEBAR_TABS.TRANSACTIONS,
  );

  return (
    <SidebarWrapper pageView={view === TRANSACTION_VIEW_MODE.PAGE} floating={floating}>
      <SidebarTabs noMinWidth>
        {Object.values(SIDEBAR_TABS).map((tab) => (
          <TabButton
            key={tab}
            active={selectedTab === tab}
            onClick={() => setSelectedTab(tab)}
            data-cy={`transaction-modal__sidebar__${tab}-tab`}
          >
            {tab}
          </TabButton>
        ))}
      </SidebarTabs>
      {selectedTab === SIDEBAR_TABS.REVENUE_HISTORY && <RevenueHistory />}
      {selectedTab === SIDEBAR_TABS.TRANSACTIONS && <RelatedTransactions />}
    </SidebarWrapper>
  );
};
