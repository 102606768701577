import { useMemo } from 'react';
import styled from 'styled-components';

import { FlexBetweenContainer, Flexer, FlexerColumn, Spacer } from 'components/Core';
import { groupBy } from 'utils/arrayUtils';
import { InvoicesSection } from './InvoicesSection';

const Wrapper = styled(FlexerColumn)`
  ::-webkit-scrollbar {
    display: none;
  }
`;

const TableBody = styled.div`
  background: var(--white8);
  border: 1px solid var(--white20);
  border-radius: 16px;
`;

export const PaymentFooter = styled(FlexBetweenContainer)`
  padding: 22px;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
  background: var(--white8);
  border: 1px solid var(--white20);
  border-radius: 16px;
  margin-top: 20px;
  font-style: italic;
`;

export const PaymentFooterLeft = styled(Flexer)`
  align-items: center;
  gap: 10px;
`;

export const PaymentFooterRight = styled(Flexer)`
  align-items: center;
  gap: 8px;
`;

export const ExtraBold = styled.b`
  font-weight: 900;
`;

export const SubFooterText = styled.span`
  display: inline-block;
  font-size: 12px;
  color: white;
  opacity: 50%;
  font-style: italic;
  margin-top: 30px;
`;

export const PaymentDetailsTable = ({
  orgConfigs,
  transactions,
  products,
  invoices,
  frequency,
  currentInvoiceId,
  currentInvoiceObject,
  translations,
  language,
}) => {
  const transactionById = useMemo(() => groupBy(transactions, 'id', { uniqueness: true }), [transactions]);
  const productsById = useMemo(() => groupBy(products, 'id', { uniqueness: true }), [products]);

  const invoicesSortedbyDate = invoices.sort((i1, i2) => (i1.date < i2.date ? -1 : 1));

  // If the current invoice is paid, it won't be in the list, so we should have gotten it passed to us
  const currentInvoice =
    currentInvoiceObject ?? invoicesSortedbyDate.find((invoice) => invoice.id === currentInvoiceId);
  const unpaidInvoicesAfterCurrent = invoicesSortedbyDate.filter(
    (invoice) => invoice.date > currentInvoice?.date && !invoice.paid_at,
  );
  return (
    <>
      <Wrapper data-cy="checkout__payment-details">
        <Spacer height="18px" />
        <TableBody>
          <InvoicesSection
            invoice={currentInvoice}
            currency={currentInvoice?.currency}
            frequency={frequency}
            transactionById={transactionById}
            productsById={productsById}
            futureInvoices={unpaidInvoicesAfterCurrent}
            orgConfigs={orgConfigs}
            translations={translations}
            language={language}
          />
        </TableBody>
      </Wrapper>

      <SubFooterText>{translations?.['Powered by'] ?? 'Powered by'} Subscript</SubFooterText>
    </>
  );
};
