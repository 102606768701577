import * as yup from 'yup';
import { convertCsvToPeriods } from './utils';

export const periodSchema = yup.object().shape({
  type: yup
    .string()
    .required('Select type of period')
    .oneOf(['month', 'quarter', 'year'], 'Valid periods types are "month", "quarter" or "year"'),
  start_month: yup.date().required('Add start month of the period'),
  value: yup.number().required('Add Goal value'),
});

export const goalSchema = yup.object().shape({
  name: yup.string().required('Add a name for your goal'),
  for_metric: yup.string().required('Select a metric'),
  periods: yup.array().of(periodSchema).nullable(),
});

export const csvGoalSchema = ({ readString }) =>
  yup.object().shape({
    name: yup.string().required('Add a name for your goal'),
    for_metric: yup.string().required('Select a metric'),
    csv_data: yup.string().test('valid-periods-from-csv', 'Periods are not valid!', async (value, testContext) => {
      try {
        const periods = convertCsvToPeriods({ csvData: value, readString });

        return await yup.array().of(periodSchema).validate(periods);
      } catch (err) {
        const rowIndex = err.path?.split('.')?.[0]?.[1];
        const column = err.path?.split('.')?.[1];

        let errorMessage;
        if (rowIndex !== undefined && column !== undefined) {
          errorMessage = `Error encountered on row with index ${rowIndex} and column ${column}! - ${err.message}`;
        } else {
          errorMessage = `Error encountered! - ${err.message}`;
        }

        return testContext.createError({
          message: errorMessage,
        });
      }
    }),
  });
