import React, { useContext, useMemo, useState } from 'react';
import { Formik } from 'formik';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { AppContext } from 'AppContext';
import { COLORS } from 'consts/colors';
import { Button } from 'components/Buttons';
import { FlexEndContainer, Spacer } from 'components/Core';
import { FormikCustomSelector } from 'components/Controls';
import {
  Modal,
  ModalBody,
  ModalCloseIcon,
  ModalContainer,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTitleText,
} from 'components/Modal';
import { useContractAPI } from 'api/contracts/hooks';
import { useTransactionsAPI } from 'api/transactions';
import { transactionDisplayTitle } from 'models/transaction';

dayjs.extend(utc);

export const AddTransactionsModal = ({ onClose, contractData }) => {
  const { orgId } = useContext(AppContext);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const contractId = contractData?.id;
  const customerId = contractData?.customer_id;

  const { data: transactions } = useTransactionsAPI({
    orgId,
    filters: {
      limit: 1000,
      params: {
        confirmed: true,
        includeReplaced: false,
        archived: false,
      },
      body: {
        customerIds: [customerId],
        notPartOfAContract: true,
      },
    },
  });

  const options = useMemo(
    () =>
      transactions?.map((transaction) => ({
        value: transaction.id,
        label: `${transactionDisplayTitle(transaction)} (${transaction.id})`,
      })),
    [transactions],
  );

  const {
    operations: { addTransactionsToContract },
  } = useContractAPI({ orgId, contractId, enabled: false });

  const handleFormSubmit = async ({ values }) => {
    setIsSubmitting(true);
    await addTransactionsToContract({
      transactionIds: values.transaction_ids,
    });
    setIsSubmitting(false);
    onClose();
  };

  return (
    <ModalContainer>
      <Formik initialValues={{}} onSubmit={(values) => handleFormSubmit({ values })}>
        {({ submitForm, setFieldValue }) => (
          <Modal data-cy="add-transactions-to-contract-modal" width="540px" height="auto">
            <ModalCloseIcon onClose={onClose} data-cy="add-transactions-to-contract-modal__close-button" />
            <ModalHeader>
              <ModalTitle padding="12px 36px">
                <ModalTitleText>Add Transaction(s) to Contract</ModalTitleText>
              </ModalTitle>
            </ModalHeader>
            <ModalBody paddingLeft="36px" paddingRight="36px">
              <FormikCustomSelector
                isMulti={true}
                label="Transaction(s)"
                placeholder="Select transaction(s)"
                name="transaction_ids"
                options={options}
                handleChange={(selectedValues) =>
                  setFieldValue('transaction_ids', selectedValues?.map((option) => option.value) ?? [])
                }
              />
              <Spacer height="30px" />
            </ModalBody>
            <ModalFooter padding="12px 36px" noFixedHeight>
              <FlexEndContainer>
                <Button border filled color={COLORS.GREY} onClick={onClose}>
                  Cancel
                </Button>
                <Spacer width="14px" />
                <Button
                  color={COLORS.GREEN}
                  border
                  filled
                  active
                  disabled={isSubmitting}
                  onClick={submitForm}
                  data-cy="add-transactions-to-contract-modal__submit-button"
                >
                  Add
                </Button>
              </FlexEndContainer>
            </ModalFooter>
          </Modal>
        )}
      </Formik>
    </ModalContainer>
  );
};
