import styled from 'styled-components';
import { mediaQuery } from 'components/Core';
import logo from 'images/logo.svg';

const MainLogoContainer = styled.div`
  width: 100%;
  top: 0;
  margin-bottom: 23px;

  a {
    display: flex;
  }

  img {
    margin-right: 18px;
    ${mediaQuery('max-width').xl`height: 44px`};
    ${mediaQuery('max-height').giantHeight`height: 44px`};
    ${mediaQuery('max-width').xl`margin-right: 16x`};
    ${mediaQuery('max-height').giantHeight`margin-right: 16px`};
  }

  div {
    display: flex;
    align-items: center;
    color: #fff;
    font-weight: 900;
    font-size: 20px;
    line-height: 26px;
    letter-spacing: 0.02em;
    padding: 12px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 10px;

    ${mediaQuery('max-width').xl`font-size: 16px;`}
    ${mediaQuery('max-height').giantHeight`font-size: 16px;`}
    ${mediaQuery('max-width').xl`line-height: 20px;`}
    ${mediaQuery('max-height').giantHeight`line-height: 20px;`}
  }
`;

export const MainLogo = () => (
  <MainLogoContainer>
    <a href="/">
      <img src={logo} alt="logo" width="64" height="50" />
      <div>Subscript</div>
    </a>
  </MainLogoContainer>
);
