import React from 'react';
import styled from 'styled-components';
import { ReactComponent as EmptyStateIllustration } from 'images/forecasting-empty-state-illustration.svg';
import { ReactComponent as ChartsIllustration } from 'images/forecasting-charts-illustration.svg';
import { Flexer } from 'components/Core';
import {
  EmptyStateContents,
  EmptyStateMainText,
  EmptyStateText,
  Step,
  StepsCircle,
  StepsWrapper,
} from 'shared/EmptyState';
import { AddScenarioButton } from './AddScenarioButton';

export const EmptyStateWrapper = styled(Flexer)`
  height: 596px;

  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  margin-top: 20px;
  padding-top: 40px;
  background: var(--primaryBlack1);
  border: 1px solid var(--accentGraySecond);
  border-radius: 20px;
`;

export const PageEmptyState = () => (
  <EmptyStateWrapper>
    <EmptyStateContents>
      <EmptyStateIllustration />

      <EmptyStateMainText>Create your first forecast!</EmptyStateMainText>

      <StepsWrapper>
        <Step textWidth="192px">
          <StepsCircle>
            <span>1</span>
          </StepsCircle>
          <EmptyStateText>For new business: set up sales pipeline or use trendlines</EmptyStateText>
        </Step>

        <Step textWidth="192px">
          <StepsCircle>
            <span>2</span>
          </StepsCircle>
          <EmptyStateText>Compare results with other scenarios and the real data</EmptyStateText>
        </Step>

        <Step textWidth="192px">
          <StepsCircle>
            <span>3</span>
          </StepsCircle>
          <EmptyStateText>Adjust any filters and save the report</EmptyStateText>
        </Step>
      </StepsWrapper>
      <AddScenarioButton />
    </EmptyStateContents>

    <ChartsIllustration />
  </EmptyStateWrapper>
);
