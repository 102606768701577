import { useQuery, useQueryClient } from 'react-query';
import { getRetentionBreakdown } from './requests';

export const CACHE_KEY = 'retentionBreakdown';

export const useRetentionBreakdownAPI = ({
  orgId,
  startMonth,
  endMonth,
  metadataFilter,
  rollup,
  isCommitted,
  optimisticAnalytics,
  countInfluxAsRenewed,
  retentionMetric,
  autoFetch = true,
}) => {
  const queryClient = useQueryClient();

  const params = autoFetch
    ? {
        startMonth,
        endMonth,
        rollup,
        isCommitted,
        optimisticAnalytics,
        countInfluxAsRenewed,
        retentionMetric,
        customerMetadata: JSON.stringify(metadataFilter?.customerMetadata),
        transactionMetadata: JSON.stringify(metadataFilter?.transactionMetadata),
      }
    : {};

  const { data, error, isLoading, isFetching } = useQuery(
    [CACHE_KEY, orgId, params],
    () => getRetentionBreakdown({ orgId, params }),
    {
      enabled: autoFetch && !!orgId,
    },
  );

  const refetch = () => queryClient.invalidateQueries(CACHE_KEY);

  return {
    data,
    error,
    isLoading,
    isFetching,
    refetch,
  };
};
