import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { AppContext } from 'AppContext';
import { useInvoicesAPI } from 'api/billing';
import { CSV_MODES } from 'shared/CsvUpload/CsvUpload';
import { CsvUpload } from 'shared/CsvUpload';
import { CUSTOMER_TYPES_OPTIONS } from 'shared/Filters';
import { useCustomersAPI } from 'api/customers';
import { useImportsAPI } from 'api/imports';
import { useInvoicingSchedulesAPI } from 'api/billing';
import {
  INTERNAL_INVOICE_ID_SOURCE,
  NAME_MATCHING_SOURCE,
  SOURCE_TYPES,
  UPLOADABLE_OBJECTS,
  INTERNAL_CUSTOMER_ID_SOURCE,
} from 'views/Transactions/TransactionsCsvUpload/consts';

import { getSchema } from './schema';
import { getColumns } from './columns';
import { DEFAULT_INVOICES_CREATE_MAPPER, DEFAULT_INVOICES_MAPPER, DEFAULT_INVOICES_TRANSFORMATIONS } from './consts';
import { getFormFinalInvoices, getInitialIdSource, getOrgIdSources } from './utils';

export const InvoicesCsvUpload = () => {
  const { organizations, integrations, orgConfigs } = useContext(AppContext);

  const [invoiceIdSourceAndType, setInvoiceIdSourceAndType] = useState({});
  const [customerIdSourceAndType, setCustomerIdSourceAndType] = useState({});
  const [submittingForm, setSubmittingForm] = useState(false);

  const invoicesIdSources = useMemo(() => {
    return getOrgIdSources({
      integrations,
      internalSource: INTERNAL_INVOICE_ID_SOURCE,
      suffix: 'invoice ID',
    });
  }, [integrations]);

  const customersIdSources = useMemo(() => {
    return {
      ...getOrgIdSources({
        integrations,
        internalSource: INTERNAL_CUSTOMER_ID_SOURCE,
        suffix: 'customer ID',
      }),
      [INTERNAL_CUSTOMER_ID_SOURCE]: INTERNAL_CUSTOMER_ID_SOURCE,
      [NAME_MATCHING_SOURCE]: 'Customer name',
    };
  }, [integrations]);

  useEffect(() => {
    if (organizations) {
      setInvoiceIdSourceAndType(getInitialIdSource({ idSources: invoicesIdSources }));
      setCustomerIdSourceAndType(getInitialIdSource({ idSources: customersIdSources }));
    }
  }, [organizations, invoicesIdSources, customersIdSources]);

  // Fetch Customers
  const customersListParams = useMemo(
    () => ({
      limit: 50000,
      types: [CUSTOMER_TYPES_OPTIONS.LIVE, CUSTOMER_TYPES_OPTIONS.PROSPECT],
      // If matching internally by a metadata, we only need to fetch customers with existing id in metadata
      notNullMetadataField: [NAME_MATCHING_SOURCE, INTERNAL_CUSTOMER_ID_SOURCE].includes(customerIdSourceAndType.source)
        ? undefined
        : customerIdSourceAndType.source,
    }),
    [customerIdSourceAndType],
  );

  // With INTERNAL source we get Customers from our customers table
  const {
    data: dataCustomers,
    isLoading: isCustomersLoading,
    operations: { refetch: refetchCustomers },
  } = useCustomersAPI({
    orgId: organizations?.[0]?.id,
    filters: { params: customersListParams },
    autoFetch: false,
  });

  // With EXTERNAL source we get Customers from our imports table
  const {
    data: dataCustomerImports,
    operations: { refetch: refetchCustomerImports },
  } = useImportsAPI({
    orgId: organizations?.[0]?.id,
    filters: {
      providerName: customerIdSourceAndType.source,
      chifferObjectName: 'customer',
      includeCustomerName: true,
    },
    autoFetch: false,
  });

  // Fetch Invoicing Schedules
  const { data: dataInvoicingSchedules, isLoading: isInvoicingSchedulesLoading } = useInvoicingSchedulesAPI({
    orgId: organizations?.[0]?.id,
    groupByCustomer: false,
    filters: {
      limit: 30000,
    },
  });

  const {
    data: dataInvoices,
    isLoading: isInvoicesLoading,
    operations: { refetch: refetchInvoices, bulkUpload: bulkUploadInvoices },
  } = useInvoicesAPI({
    orgId: organizations?.[0]?.id,
    filters: {
      limit: 30000,
    },
    autoFetch: false,
  });

  const customerNameByInvoiceId = useMemo(() => {
    return dataInvoices?.data?.reduce((acc, invoice) => {
      acc[invoice.id] = invoice.customer_name;
      return acc;
    }, {});
  }, [dataInvoices?.data]);

  useEffect(() => {
    refetchInvoices();
  }, [invoiceIdSourceAndType, refetchInvoices]);

  // Fetch Customers
  useEffect(() => {
    if (customerIdSourceAndType?.type === SOURCE_TYPES.INTERNAL) {
      refetchCustomers();
    } else {
      refetchCustomerImports();
    }
  }, [customerIdSourceAndType, refetchCustomers, refetchCustomerImports]);

  const invoices = useMemo(() => dataInvoices, [dataInvoices]);
  const customers = useMemo(
    () => (customerIdSourceAndType?.type === SOURCE_TYPES.INTERNAL ? dataCustomers : dataCustomerImports),
    [dataCustomers, dataCustomerImports, customerIdSourceAndType?.type],
  );

  const invoicingSchedules = useMemo(() => dataInvoicingSchedules, [dataInvoicingSchedules]);

  const invoicesSelectOptions = useMemo(
    () =>
      invoices?.data
        ?.map((invoice) => ({
          value: invoice.id,
          label: `${customerNameByInvoiceId[invoice.id] ?? 'Unknown customer name'} - ${invoice.date} - ${
            invoice.amount
          }${invoice.invoice_number ? ` / ${invoice.invoice_number}` : ''}`,
        }))
        .sort((a, b) => a?.label?.localeCompare(b?.label)),
    [invoices?.data, customerNameByInvoiceId],
  );

  const { uploadCsvCustomersIdSource } = orgConfigs;
  const customersSelectOptions = useMemo(
    () =>
      customers
        ?.map((customer) => ({
          value: [NAME_MATCHING_SOURCE, INTERNAL_CUSTOMER_ID_SOURCE].includes(customerIdSourceAndType?.source)
            ? customer.id
            : customerIdSourceAndType?.type === SOURCE_TYPES.INTERNAL
            ? customer?.metadata?.[uploadCsvCustomersIdSource]
            : customer.provider_object_id,
          label:
            customer.customer_name ??
            customer.name ??
            `No name / ${
              customerIdSourceAndType?.source === NAME_MATCHING_SOURCE
                ? customer.id
                : customerIdSourceAndType?.type === SOURCE_TYPES.INTERNAL
                ? customer.metadata?.[uploadCsvCustomersIdSource]
                : customer.provider_object_id
            }`,
        }))
        .sort((a, b) => a?.label?.localeCompare(b?.label)),
    [customers, customerIdSourceAndType, uploadCsvCustomersIdSource],
  );

  const customersById = useMemo(
    () =>
      customers?.reduce((acc, curr) => {
        acc[curr.id] = curr;
        return acc;
      }, {}),
    [customers],
  );

  const tableSchema = useMemo(() => {
    const { updateSchema, createSchema } = getSchema({
      invoices,
      invoiceIdSourceAndType,
      customerIdSourceAndType, // Add this line
      customers,
      invoicingSchedules,
      existingInvoices: invoices?.data,
    });

    return { updateSchema, createSchema };
  }, [invoices, invoiceIdSourceAndType, customerIdSourceAndType, customers, invoicingSchedules]);

  const defaultMapperWithCreateOrUpdateMode = useCallback(({ createOrUpdateMode }) => {
    return createOrUpdateMode === CSV_MODES.UPDATE
      ? DEFAULT_INVOICES_MAPPER
      : createOrUpdateMode === CSV_MODES.CREATE
      ? DEFAULT_INVOICES_CREATE_MAPPER
      : undefined;
  }, []);

  const getColumnsWithCsvUploadState = useCallback(
    ({
      fuzzyRowsMapper,
      defaultMapper,
      setFuzzyRowsMapper,
      mappedData,
      formRef,
      csvColumnsMapper,
      setCsvColumnsMapper,
      csvColumns,
    }) =>
      getColumns({
        objects: UPLOADABLE_OBJECTS.INVOICES,
        organization: organizations[0],
        mappedData,
        formRef,
        csvColumns,
        csvColumnsMapper,
        setCsvColumnsMapper,
        invoices,
        invoicesSelectOptions,
        invoiceIdSourceAndType,
        setInvoiceIdSourceAndType,
        customerIdSourceAndType,
        setCustomerIdSourceAndType,
        customersIdSources,
        customers,
        customersSelectOptions,
        customersById,
        fuzzyRowsMapper,
        setFuzzyRowsMapper,
        defaultMapper,
        invoicingSchedules,
      }),
    [
      invoices,
      invoicesSelectOptions,
      invoiceIdSourceAndType,
      customerIdSourceAndType,
      organizations,
      setInvoiceIdSourceAndType,
      setCustomerIdSourceAndType,
      customersIdSources,
      invoicingSchedules,
      customers,
      customersSelectOptions,
      customersById,
    ],
  );

  const handleSubmit = async ({ values }) => {
    setSubmittingForm(true);

    const formInvoices = getFormFinalInvoices({
      formInvoices: values,
      invoiceIdSourceAndType,
      subscriptInvoices: invoices,
      organizations,
      subscriptCustomers: customers,
      customerIdSourceAndType,
      uploadCsvCustomersIdSource,
    });

    try {
      await bulkUploadInvoices.mutateAsync({ data: formInvoices });
      setSubmittingForm(false);
    } catch {
      setSubmittingForm(false);
    }
  };

  const isLoading = submittingForm || isInvoicesLoading || isCustomersLoading || isInvoicingSchedulesLoading;

  return (
    <CsvUpload
      entityName={UPLOADABLE_OBJECTS.INVOICES}
      isLoading={isLoading}
      createSchema={tableSchema?.createSchema}
      updateSchema={tableSchema?.updateSchema}
      handleSubmit={handleSubmit}
      defaultMapperWithCreateOrUpdateMode={defaultMapperWithCreateOrUpdateMode}
      csvColumnsTransformations={DEFAULT_INVOICES_TRANSFORMATIONS}
      getColumnsWithCsvUploadState={getColumnsWithCsvUploadState}
      backLink="/billing/invoices"
    />
  );
};
