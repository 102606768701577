import { getColumnValue } from './getColumnValue';

export const generateSectionFunction = ({
  metricsObject,
  columnHeaders,
  growthType,
  isARR,
  summaryStats,
  hasCostAttribution,
  winbackMonths,
}) => (metrics) =>
  metrics.map((metric) => ({
    title: metric.label,
    tooltipText: metric.description({ growthType, isARR, hasCostAttribution, winbackMonths }),
    dataType: metric.dataType,
    decimalPlaces: metric.decimalPlaces,
    monthDataKey: metric.monthDataKey,
    data: columnHeaders.reduce((acc, header) => {
      acc[header] = getColumnValue(
        header,
        metricsObject[metric.metricCategoryKey],
        metric.monthDataKey,
        summaryStats,
        metric.summaryDataKey,
      );
      return acc;
    }, {}),
  }));
