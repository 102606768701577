import { useQueryPolling } from 'api/jobHooks.helper';
import { useQueryClient } from 'react-query';
import {
  getAccountingRevenueForContract,
  getAccountingRevenueForCustomer,
  getAccountingRevenueForDateRange,
  getAccountingRevenueForMonth,
  getAccountingRevenueForMonthCustomer,
  getAccountingSpreadsForContract,
} from './requests';

export const CACHE_KEY = 'accounting-revenue';

export const useAccountingRevenueForDateRangeAPI = ({ orgId, params, autoFetch = true }) => {
  const dataKey = [CACHE_KEY, orgId, 'date-range', params];

  const queryClient = useQueryClient();

  const { data, error, isLoading, isFetching } = useQueryPolling({
    key: dataKey,
    fetchFn: () => getAccountingRevenueForDateRange({ orgId, params }),
    fetchWithJobIdFn: (jobId) => getAccountingRevenueForDateRange({ orgId, params: { jobId } }),
    queryOptions: {
      enabled: autoFetch,
    },
    pollingErrorMessage: `Accounting Revenue for date range with params ${JSON.stringify(
      params,
    )} is taking too long to load for org ${orgId}`,
  });

  const refetch = () => queryClient.invalidateQueries(dataKey);

  return {
    data,
    error,
    isLoading,
    isFetching,
    operations: {
      refetch,
    },
  };
};

export const useAccountingRevenueForMonthAPI = ({ orgId, params, autoFetch = true }) => {
  const dataKey = [CACHE_KEY, orgId, 'month', params];

  const queryClient = useQueryClient();

  const { data, error, isLoading, isFetching } = useQueryPolling({
    key: dataKey,
    fetchFn: () => getAccountingRevenueForMonth({ orgId, params }),
    fetchWithJobIdFn: (jobId) => getAccountingRevenueForMonth({ orgId, params: { jobId } }),
    queryOptions: {
      enabled: autoFetch,
    },
    pollingErrorMessage: `Accounting Revenue for month with params ${JSON.stringify(
      params,
    )} is taking too long to load for org ${orgId}`,
  });

  const refetch = () => queryClient.invalidateQueries(dataKey);

  return {
    data,
    error,
    isLoading,
    isFetching,
    operations: {
      refetch,
    },
  };
};

export const useAccountingRevenueForMonthCustomerAPI = ({ orgId, params, autoFetch = true }) => {
  const dataKey = [CACHE_KEY, orgId, 'month-customer', params];

  const queryClient = useQueryClient();

  const { data, error, isLoading, isFetching } = useQueryPolling({
    key: dataKey,
    fetchFn: () => getAccountingRevenueForMonthCustomer({ orgId, params }),
    fetchWithJobIdFn: (jobId) => getAccountingRevenueForMonthCustomer({ orgId, params: { jobId } }),
    queryOptions: {
      enabled: autoFetch,
    },
    pollingErrorMessage: `Accounting Revenue for month with params ${JSON.stringify(
      params,
    )} is taking too long to load for org ${orgId}`,
  });

  const refetch = () => queryClient.invalidateQueries(dataKey);

  return {
    data,
    error,
    isLoading,
    isFetching,
    operations: {
      refetch,
    },
  };
};

export const useAccountingRevenueForCustomerAPI = ({ orgId, customerId, params, autoFetch = true }) => {
  const dataKey = [CACHE_KEY, orgId, 'customer', customerId, params];

  const queryClient = useQueryClient();

  const { data, error, isLoading, isFetching } = useQueryPolling({
    key: dataKey,
    fetchFn: () => getAccountingRevenueForCustomer({ orgId, customerId, params }),
    fetchWithJobIdFn: (jobId) => getAccountingRevenueForCustomer({ orgId, customerId, params: { jobId } }),
    queryOptions: {
      enabled: autoFetch,
    },
    pollingErrorMessage: `Accounting Revenue for customer with params ${JSON.stringify(
      params,
    )} is taking too long to load for org ${orgId}`,
  });

  const refetch = () => queryClient.invalidateQueries(dataKey);

  return {
    data,
    error,
    isLoading,
    isFetching,
    operations: {
      refetch,
    },
  };
};

export const useAccountingRevenueForContractAPI = ({ orgId, contractId, params, autoFetch = true }) => {
  const dataKey = [CACHE_KEY, orgId, 'contract', contractId, params];

  const queryClient = useQueryClient();

  const { data, error, isLoading, isFetching } = useQueryPolling({
    key: dataKey,
    fetchFn: () => getAccountingRevenueForContract({ orgId, contractId, params }),
    fetchWithJobIdFn: (jobId) => getAccountingRevenueForContract({ orgId, contractId, params: { jobId } }),
    queryOptions: {
      enabled: autoFetch,
    },
    pollingErrorMessage: `Accounting Revenue for contract with params ${JSON.stringify(
      params,
    )} is taking too long to load for org ${orgId}`,
  });

  const refetch = () => queryClient.invalidateQueries(dataKey);

  return {
    data,
    error,
    isLoading,
    isFetching,
    operations: {
      refetch,
    },
  };
};

export const useAccountingSpreadsForContractAPI = ({ orgId, contractId, params, autoFetch = true }) => {
  const dataKey = [CACHE_KEY, orgId, 'accounting_spreads', contractId, params];

  const queryClient = useQueryClient();

  const { data, error, isLoading, isFetching } = useQueryPolling({
    key: dataKey,
    fetchFn: () => getAccountingSpreadsForContract({ orgId, contractId, params }),
    fetchWithJobIdFn: (jobId) => getAccountingSpreadsForContract({ orgId, contractId, params: { jobId } }),
    queryOptions: {
      enabled: autoFetch,
    },
    pollingErrorMessage: `Accounting Spreads for contract with params ${JSON.stringify(
      params,
    )} is taking too long to load for org ${orgId}`,
  });

  const refetch = () => queryClient.invalidateQueries(dataKey);

  return {
    data,
    error,
    isLoading,
    isFetching,
    operations: {
      refetch,
    },
  };
};
