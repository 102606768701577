import { FlexerColumn } from 'components/Core';
import styled from 'styled-components';

export const MetricColumn = styled(FlexerColumn)`
  align-items: flex-end;
  width: 50%;
`;

export const LargeNumber = styled.span`
  font-weight: 300;
  font-size: 20px;
  margin-bottom: 5px;
  &:hover {
    cursor: pointer;
  }
`;

export const SubValueContainer = styled.span`
  font-size: 12px;
  padding: 2px 4px;
  border-radius: 4px;
  background-color: var(--primaryBlack2);
  color: var(--accentDarkGray);
  width: fit-content;
  gap: 4px;
`;

export const SubValue = styled.span`
  padding-left: 4px;
  padding-right: 6px;
  font-weight: 800;
  font-size: 10px;
  line-height: 14px;
`;

export const QuarterLabel = styled.span`
  font-size: 12px;
  color: var(--accentDarkGray);
  margin-top: 12px;
`;
