import styled from 'styled-components';
import { Flexer, FlexerColumn } from 'components/Core';

export const Body = styled.div`
  background: var(--secondaryGray);
  border-top: 1px solid var(--accentGraySecond);
  padding: 20px 0 0 0;
  position: relative;
  width: 100%;
  overflow-y: scroll;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
`;

export const Sidebar = styled.div`
  border-bottom: 1px solid var(--primaryBlack3);
  padding-bottom: 20px;
`;

export const SidebarTitle = styled.div`
  width: 90px;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  opacity: ${({ bottom }) => bottom && '0.5'};
`;

export const SidebarSection = styled.div`
  border-top: ${({ withTopBorder }) => withTopBorder && '1px solid var(--accentGraySecond)'};
  background: var(--secondaryGray);
  left: 0;
  margin: 0;
  position: sticky;
  min-width: 134px;
  width: 134px;
  padding-top: 12px;
  padding-left: 20px;
  padding-right: 20px;
`;

export const MetricValue = styled.span`
  color: ${({ darker }) => (darker ? 'var(--primaryBlack)' : 'var(--primaryBlack50)')};
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  padding: ${({ isSidebar }) => (isSidebar ? '12px 0 12px 0' : '0')};
`;

export const MonthCellsBottom = styled(Flexer)`
  gap: 10px;
  min-width: 116px;
  width: 116px;
`;

export const MonthCell = styled.div`
  border-bottom: ${({ forHeader }) => (forHeader ? '1px solid var(--primaryBlack3)' : undefined)};
  padding: 12px;
  min-width: 116px;
  width: 116px;
  border-top: ${({ withTopBorder }) => withTopBorder && '1px solid var(--accentGraySecond)'};
`;

export const MonthCellContent = styled(FlexerColumn)`
  align-items: flex-end;
`;

export const MonthTitle = styled.div`
  color: var(--primaryBlue);
  font-size: 10px;
  font-weight: 900;
  margin-bottom: 12px;
  line-height: 12px;
  text-transform: uppercase;
`;

export const MonthTotal = styled(Flexer)`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: var(--primaryDark);
`;

export const MainMetricsMonthsWrapper = styled(Flexer)`
  gap: 8px;
  padding-right: 8px;
`;

export const MonthCellsCard = styled(Flexer)`
  background: ${({ isQuarterEnd }) => (isQuarterEnd ? 'var(--primaryBlack4)' : '#FFF')};
  border: ${({ isQuarterEnd }) => (isQuarterEnd ? 'var(--primaryBlack4)' : '1px solid var(--accentGraySecond)')};
  box-shadow: 4px 4px 24px var(--primaryBlack4);
  border-radius: 12px;

  &:hover {
    ${MonthTitle} {
      color: var(--secondaryBlue);
    }

    ${MetricValue} {
      color: var(--primaryBlack);
    }

    box-shadow: 0 4px 20px var(--primaryBlack10);
  }
`;
