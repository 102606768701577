import styled from 'styled-components';
import { ReactComponent as RecurringIcon } from 'images/recurring-icon.svg';
import { ReactComponent as ExternalLink } from 'images/link-share.svg';

export const SyncIcon = styled(RecurringIcon)`
  width: 14px;

  path {
    fill: var(--secondaryBlue);
  }
`;

export const LinkIcon = styled(ExternalLink)`
  width: 14px;

  path {
    fill: var(--secondaryBlue);
  }
`;

export const ItemText = styled.span`
  font-weight: 900;
  font-size: 10px;
  line-height: 14px;
  text-transform: uppercase;
`;

export const ManualCreatedText = styled.span`
  padding: 10px;
  font-size: 12px;
  line-height: 18px;
  color: rgba(0, 21, 46, 0.5);
  text-align: center;
`;
