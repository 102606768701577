import React from 'react';
import { ReactComponent as ArrowIconLeft } from 'images/search-button-left.svg';
import { ReactComponent as ArrowIconRight } from 'images/search-button-right.svg';
import { Container, TablePage, ArrowButton } from './PaginationButton.styles';

export const PaginationButton = ({ pageIndex, canPreviousPage, canNextPage, pageCount, nextPage, previousPage }) => (
  <Container className="flexer-space-between">
    <ArrowButton
      data-cy="pagination-button__left-arrow"
      className="flexer"
      disabled={!canPreviousPage ? true : null}
      onClick={() => {
        previousPage();
      }}
    >
      <ArrowIconLeft />
    </ArrowButton>

    <TablePage data-cy="pagination-button__info-text">
      {pageCount ? `${pageIndex} of ${pageCount} pages` : '0 pages'}
    </TablePage>

    <ArrowButton
      data-cy="pagination-button__right-arrow"
      className="flexer"
      disabled={!canNextPage ? true : null}
      onClick={() => {
        nextPage();
      }}
    >
      <ArrowIconRight />
    </ArrowButton>
  </Container>
);
