import { useContext, useState } from 'react';
import styled from 'styled-components';

import { AppContext } from 'AppContext';
import { ReactComponent as BlueCross } from 'images/blue-cross.svg';
import { DropdownSearchBar } from 'components/Blocks/SearchBar';
import { Centerer } from 'components/Core';
import { CirclePlusIcon, CircleCheckBlackIcon } from 'components/Icons';
import { Popover, PopoverItemsContainer, PopoverListItem, PopoverWrapper, usePortal } from 'components/Portal';

import { UPLOADABLE_OBJECTS } from './consts';

const ItemsContainer = styled(PopoverItemsContainer)`
  max-height: 300px;
  overflow-y: auto;
`;

const ButtonWrapper = styled(Centerer)`
  background: var(--primaryBlue5);
  border-radius: 8px;
  cursor: pointer;
  padding: 6px;

  width: 36px;
  height: 36px;

  &:hover {
    background: var(--primaryBlue10);
  }
`;

const StyledDropdownSearchBar = styled(DropdownSearchBar)`
  padding: 8px 12px;
  border-bottom: 1px solid var(--accentGraySecond);

  svg {
    width: 20px;
    height: 20px;
    padding: 0px;
    margin-right: 0px;
  }
`;

const StyledPopoverListItem = styled(PopoverListItem)`
  font-weight: ${({ selected }) => (selected ? 700 : 400)};
  color: ${({ selected }) => (selected ? 'var(--primaryBlue)' : 'undefined')};
  background: ${({ selected }) => (selected ? 'var(--primaryBlue2)' : 'undefined')};
`;

const AddNewMetadataButton = styled(PopoverListItem)`
  svg {
    margin-right: 10px;
  }
`;

const InputWrapper = styled(Centerer)`
  padding: 12px;

  background: var(--dark7);
  box-shadow: 16px 16px 60px var(--primaryBlack20);
  border-radius: 12px;
`;

const StyledInput = styled.input`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;

  background-color: transparent;
  border: 1px solid transparent;

  color: #ffffff;
`;

const CheckmarkIcon = styled(CircleCheckBlackIcon)`
  cursor: pointer;
`;

const CreateNewMetadataButton = ({ toggleSelectedMetadataField }) => {
  const { triggerRef, togglePortal, isPortalVisible, Portal } = usePortal({ YOffset: 95, XOffset: 310 });

  const [inputValue, setInputValue] = useState('');

  const handleAddMetadata = (name) => {
    toggleSelectedMetadataField(name);
    togglePortal();
  };

  return (
    <PopoverWrapper onClick={togglePortal} ref={triggerRef}>
      <AddNewMetadataButton>
        <BlueCross />
        Create New Metadata
      </AddNewMetadataButton>

      {isPortalVisible && (
        <Portal>
          <Popover width="195px" onClick={(e) => e.stopPropagation()}>
            <InputWrapper>
              <StyledInput
                placeholder="Enter name..."
                name="newMetadata"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                onKeyPress={(event) => {
                  if (event.key === 'Enter') {
                    handleAddMetadata(inputValue);
                  }
                }}
              />
              <CheckmarkIcon
                fill="var(--primaryBlack)"
                onClick={() => {
                  handleAddMetadata(inputValue);
                }}
              />
            </InputWrapper>
          </Popover>
        </Portal>
      )}
    </PopoverWrapper>
  );
};

export const AddMetadataDropdown = ({ selectedMetadataFields, toggleSelectedMetadataField, objects }) => {
  const { orgConfigs } = useContext(AppContext);
  const { triggerRef, togglePortal, isPortalVisible, Portal } = usePortal({
    YOffset: 55,
    XOffset: 45,
    hideOnOutsideClick: false,
  });
  const [searchText, setSearchText] = useState('');

  const { customerCustomFields, transactionCustomFields } = orgConfigs;

  const fieldOptions = objects === UPLOADABLE_OBJECTS.CUSTOMERS ? customerCustomFields : transactionCustomFields;
  const fieldOptionsSet = new Set([...selectedMetadataFields, ...Object.keys(fieldOptions ?? {})]);
  const filteredKeys = [...fieldOptionsSet].filter((key) => key.toLowerCase().includes(searchText.toLowerCase()));

  return (
    <PopoverWrapper onClick={togglePortal} ref={triggerRef} data-cy="add-metadata-dropdown-csv-uploader">
      <ButtonWrapper>
        <CirclePlusIcon color="var(--primaryBlue)" />
      </ButtonWrapper>

      {isPortalVisible && (
        <Portal>
          <Popover width="210px">
            <ItemsContainer onClick={(e) => e.stopPropagation()}>
              <CreateNewMetadataButton
                toggleSelectedMetadataField={(name) => {
                  toggleSelectedMetadataField(name);
                  togglePortal();
                }}
              />
              <StyledDropdownSearchBar
                value={searchText}
                onChange={({ target }) => setSearchText(target.value)}
                placeholder={'Search...'}
              />
              {filteredKeys.map((key) => (
                <StyledPopoverListItem
                  key={key}
                  onClick={() => {
                    toggleSelectedMetadataField(key);
                  }}
                  selected={selectedMetadataFields.includes(key)}
                  data-cy={`metadata-dropdown-csv-uploader-option_${key}`}
                >
                  {key}
                </StyledPopoverListItem>
              ))}
            </ItemsContainer>
          </Popover>
        </Portal>
      )}
    </PopoverWrapper>
  );
};
