import dayjs from 'dayjs';
import React from 'react';
import { WarningBar } from 'components/Blocks';
import { hasInvoicePaymentFailed } from 'views/Billing/utils';

export const InvoicePaymentFailedWarning = ({ invoice }) => {
  const invoicePaymentFailed = hasInvoicePaymentFailed({ invoice }) && !invoice.paid_at;
  if (!invoicePaymentFailed) return null;

  return (
    <WarningBar
      fullWidth
      warnings={[
        <>
          <b>Auto-charge process failed</b> on {dayjs.utc(invoice.auto_charge_last_failed_at).format('MMM DD, YYYY')}.
          <br />
          <b>Reason:</b> <i>{invoice.auto_charge_last_payment_error_message}</i>
        </>,
      ]}
    />
  );
};
