import React, { useContext } from 'react';
import styled from 'styled-components';

import { ReactComponent as PlusIcon } from 'images/plus-2.svg';
import { METADATA_TYPES } from 'shared/Common';
import { TRANSACTION_MODAL_MODE } from 'shared/TransactionContent';
import { getOriginalField, SYMBOL_getOriginalField } from 'shared/TransactionContent/utils';
import { TransactionContext } from 'shared/TransactionContent/TransactionContext';
import { MetadataContent, MetadataItem } from 'shared/Common/MetadataSection/MetadataSection';
import { MetadataItemFormikInput, NoMetadata } from 'shared/Common/MetadataSection';
import { FlexBetweenContainer } from 'components/Core';
import { CircleButton } from 'components/Buttons';
import { TooltipContainer } from 'components/Tooltip';
import { ChargedIcon } from 'components/Icons';
import { MAGIC_METADATA } from 'consts/global';
import { formatAddress } from 'models/common';

import { METADATA_TABS } from './TransactionModalMetadata';
import { ItemsWrapper, Metadata, MetadataBody, NoMetadataCustomer } from './styles';

const Header = styled(FlexBetweenContainer)`
  color: var(--primaryBlue);
  font-weight: 700;
  font-size: 12px;
  margin-bottom: 5px;
  position: sticky;
  top: 0;
  background-color: var(--accentGrayFourth);
  z-index: 11;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 3px;
`;

const getMetadataDisplayValue = (item) => {
  const { key, value } = item;
  switch (key) {
    case MAGIC_METADATA.SHIPPING_ADDRESS:
      return formatAddress(value);
    default:
      return String(value);
  }
};

export const TransactionModalMetadataBody = ({
  metadata,
  selectedTab,
  handleFormChange,
  openCreateMetadataModal,
  disabled,
}) => {
  const { mode, changedDataFormatted, organization } = useContext(TransactionContext);

  const renderMetadataItem = (item, isChanged = false) => (
    <MetadataItem
      key={item.key}
      name={item.key}
      customerView={selectedTab === METADATA_TABS.CUSTOMER}
      data-cy="metadata-item"
    >
      {isChanged && (
        <span style={{ float: 'right', marginLeft: '2px' }}>
          <ChargedIcon size="16px" />
        </span>
      )}

      <MetadataContent customerView={selectedTab === METADATA_TABS.CUSTOMER} name={item.key}>
        <div>{item.key} :</div>
        <span style={{ marginLeft: 4 }}>{getMetadataDisplayValue(item)}</span>
      </MetadataContent>
    </MetadataItem>
  );

  const isChanged = (key) =>
    mode === TRANSACTION_MODAL_MODE.EXTERNAL_UPDATE
      ? getOriginalField({ key, data: changedDataFormatted.metadata }) !== SYMBOL_getOriginalField
      : undefined;

  const getMetadataTooltipLabel = (key) => {
    return mode === TRANSACTION_MODAL_MODE.EXTERNAL_UPDATE && isChanged(key) ? (
      <>
        <div>Old value:</div>
        <div>{getOriginalField({ key, data: changedDataFormatted.metadata }) || 'No previous value'}</div>
      </>
    ) : undefined;
  };

  return (
    <Metadata
      style={{
        ...(selectedTab === METADATA_TABS.TRANSACTION && {
          paddingTop: 0,
        }),
      }}
    >
      {metadata.length > 0 ? (
        <MetadataBody>
          {selectedTab === METADATA_TABS.TRANSACTION && (
            <Header centerer>
              <span>Add new metadata</span>
              <CircleButton
                data-cy="metadata-add-button"
                width="30px"
                height="30px"
                icon={<PlusIcon />}
                iconFillColor="var(--primaryBlue)"
                backgroundColor="var(--primaryBlue10)"
                border
                onClick={() => openCreateMetadataModal({ metadataType: METADATA_TYPES.TRANSACTIONS })}
                disabled={disabled}
              />
            </Header>
          )}

          {selectedTab === METADATA_TABS.TRANSACTION ? (
            metadata.map((item) => (
              <MetadataItemFormikInput
                key={item.key}
                name={`metadata.${item.key}`}
                metadataType={
                  selectedTab === METADATA_TABS.TRANSACTION ? METADATA_TYPES.TRANSACTIONS : METADATA_TYPES.CUSTOMERS
                }
                organizationId={organization.id}
                metadataDataType={item.dataType}
                onDataChange={(newValue) => {
                  handleFormChange({
                    ...metadata.reduce(
                      (obj, item) => ({
                        ...obj,
                        [item.key]: item.value,
                      }),
                      {},
                    ),
                    [item.key]: newValue,
                  });
                }}
                item={item}
                disabled={disabled}
              />
            ))
          ) : (
            <ItemsWrapper>
              {metadata.map((item) =>
                isChanged(item.key) ? (
                  <TooltipContainer key={item.key} width={150} toolTipContent={getMetadataTooltipLabel(item.key)}>
                    {renderMetadataItem(item, true)}
                  </TooltipContainer>
                ) : (
                  renderMetadataItem(item)
                ),
              )}
            </ItemsWrapper>
          )}
        </MetadataBody>
      ) : selectedTab === METADATA_TABS.TRANSACTION ? (
        <NoMetadata handleAddMetadata={() => openCreateMetadataModal({ metadataType: METADATA_TYPES.TRANSACTIONS })} />
      ) : (
        <NoMetadataCustomer>
          <span>You don't have any metadata item for this customer</span>
        </NoMetadataCustomer>
      )}
    </Metadata>
  );
};
