import React from 'react';
import dayjs from 'dayjs';

import { ReactComponent as UpIcon } from 'images/icon_up.svg';
import { ReactComponent as DownIcon } from 'images/icon_down.svg';
import { useCurrencyNumberFormatter } from 'utils/hooks';
import { TooltipContainer } from 'components/Tooltip';
import { FlexBetweenContainer, Flexer } from 'components/Core';
import { GreyDot } from 'components/Icons';
import { CARD_SIZES } from './consts';
import { getGrowthBasedOnType } from './utils';
import { StyledCardHeader, CardTitle, TTMTag, MainStats, Stats, Dashed, NoDataFound } from './CardHeader.styled';

export const CardHeader = ({
  isTTM,
  title,
  titleTooltip,
  currentValue,
  startingValue,
  valueType,
  graphLabels,
  hasConfirmedTransactions,
  size,
  ActionButton,
  onTTMClick,
  version,
}) => {
  const numberFormatter = useCurrencyNumberFormatter();
  const didGrow = currentValue - startingValue > 0;
  const columnDirection = [CARD_SIZES.SMALL, CARD_SIZES.MEDIUM].includes(size);

  return (
    <StyledCardHeader v1Compat={version !== 2 && size === CARD_SIZES.SMALL}>
      <Flexer direction="row" alignItems="center" justifyContent="flex-start" gap="8px">
        <TooltipContainer width={240} toolTipContent={titleTooltip} isVisible={titleTooltip}>
          <CardTitle>{title}</CardTitle>
        </TooltipContainer>
        {isTTM && (
          <TooltipContainer width={130} toolTipContent="Trailing Twelve Month">
            <TTMTag onClick={onTTMClick}>TTM</TTMTag>
          </TooltipContainer>
        )}
      </Flexer>

      {hasConfirmedTransactions ? (
        <FlexBetweenContainer>
          <Flexer direction={columnDirection ? 'column' : 'row'} gap={columnDirection ? '12px' : '24px'}>
            <MainStats>
              <TooltipContainer width={100} toolTipContent={graphLabels[graphLabels.length - 1]}>
                {numberFormatter({ type: valueType, rawValue: currentValue })}
              </TooltipContainer>
              {didGrow ? <UpIcon /> : <DownIcon />}
            </MainStats>

            <Flexer
              direction={columnDirection ? 'column' : 'row'}
              alignItems={!columnDirection && 'center'}
              gap={columnDirection ? '8px' : '12px'}
            >
              {(size !== CARD_SIZES.SMALL || version === 2) && (
                <Stats>
                  <TooltipContainer
                    width={100}
                    toolTipContent={dayjs
                      .utc(graphLabels[graphLabels.length - 1])
                      .subtract(11, 'month')
                      .format('MMM YYYY')}
                  >
                    <span>1 Year Prior: </span>
                  </TooltipContainer>
                  <strong>{numberFormatter({ type: valueType, rawValue: startingValue })}</strong>
                </Stats>
              )}

              {!columnDirection && <GreyDot width={6} height={6} />}

              <Stats>
                <span>Growth: </span>
                <strong className={!didGrow ? 'neg' : ''}>
                  {getGrowthBasedOnType(currentValue, startingValue, valueType)}
                </strong>
              </Stats>
            </Flexer>
          </Flexer>

          {size !== CARD_SIZES.SMALL && <div>{ActionButton}</div>}
        </FlexBetweenContainer>
      ) : (
        <Flexer alignItems="center">
          <Dashed />
          <NoDataFound>(data wasn't found)</NoDataFound>
        </Flexer>
      )}
    </StyledCardHeader>
  );
};
