import React from 'react';
import { defaults, Doughnut } from 'react-chartjs-2';
import styled from 'styled-components';
import { cssVar, transparentize } from 'polished';
import { NUMBER_FORMATS } from 'consts/global';
import { numberFormatter } from 'utils/formatters';
import { Flexer } from 'components/Core';

export const ChartWrapper = styled(Flexer)`
  align-items: end;
  width: ${({ width }) => `${width}px`};
`;

export const DoughnutChart = ({ data, width = 64, cutoutPercentage = 60, fontSize = '9', decimalPlaces, colorRed }) => {
  defaults.global.defaultFontFamily = 'Nunito Sans';

  const chartData = {
    datasets: [
      {
        data,
        backgroundColor: [
          colorRed ? cssVar('--secondaryRed') : cssVar('--secondaryBlue'),
          colorRed ? transparentize(0.9, cssVar('--secondaryRed')) : transparentize(0.9, cssVar('--secondaryBlue')),
        ],
        borderWidth: 0,
      },
    ],
  };

  const options = {
    responsive: true,
    legend: {
      display: false,
    },
    tooltips: {
      enable: false,
    },
    hover: false,
    events: [],
    cutoutPercentage: cutoutPercentage,
    plugins: {
      doughnutlabel: {
        labels: [
          {
            text: data[0] ? numberFormatter({ type: NUMBER_FORMATS.PERCENT, rawValue: data[0], decimalPlaces }) : '0%',
            font: {
              size: fontSize,
              family: 'Nunito Sans',
              weight: '900',
            },
            color: cssVar('--primaryBlack'),
          },
        ],
      },
    },
  };

  return (
    <ChartWrapper width={width}>
      <Doughnut height={width} width={width} data={chartData} options={options} />
    </ChartWrapper>
  );
};
