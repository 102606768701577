import styled from 'styled-components';
import { Column, Flexer } from 'components/Core';
import { ReactComponent as CirclePlus } from 'images/circle-plus.svg';

export const WrapperCard = styled(Flexer)`
  align-items: flex-start;
  justify-content: space-between;
  padding: 12px;
  gap: 16px;

  width: 100%;
  min-height: 148px;

  border: 1px solid var(--accentGraySecond);
  border-radius: 20px;

  &:hover {
    border: 1px solid var(--neutralGray);
    box-shadow: 8px 8px 60px var(--primaryBlack4);
  }
`;

export const StyledCirclePlus = styled(CirclePlus)`
  path {
    fill: var(--primaryBlue);
  }
`;
export const NewGoalCard = styled(Column)`
  gap: 9px;
  padding: 20px;
  background: var(--primaryBlue5);
  border-radius: 12px;
  max-width: 120px;
  min-height: 120px;
  cursor: pointer;

  div {
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: var(--primaryBlue);
  }

  &:hover {
    background: var(--primaryBlue10);

    div {
      color: var(--secondaryBlue);
    }

    path {
      fill: var(--secondaryBlue);
    }
  }
`;
