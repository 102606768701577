import axios from 'axios';
import { API_URL } from 'api/consts';

const buildUrl = ({ orgId, importId }) => {
  const baseUrl = `${API_URL}/organizations/${orgId}/imports`;
  if (!importId) return baseUrl;

  return `${baseUrl}/${importId}`;
};

export const getImports = async ({ orgId, params }) => {
  const { data } = await axios.get(buildUrl({ orgId }), { params });
  return data;
};

export const upsertImport = async ({ orgId, integrationId, data }) => {
  const response = await axios.post(`${API_URL}/organizations/${orgId}/integrations/${integrationId}/import`, data);
  return response.data;
};

export const deleteImport = async ({ orgId, importId }) => {
  const { data } = await axios.delete(buildUrl({ orgId, importId }));
  return data;
};

export const bulkUpsertImport = async ({ orgId, data }) => {
  const response = await axios.post(`${API_URL}/organizations/${orgId}/imports/bulk-upsert`, { data });
  return response.data;
};
