import { useContext } from 'react';
import { FieldArray } from 'formik';
import styled from 'styled-components';

import { AppContext } from 'AppContext';
import { GL_INTEGRATION_SERVICES, INTEGRATION_TYPES } from 'consts/integrations';
import { Centerer, FlexBetweenContainer, Flexer, FlexerColumn } from 'components/Core';
import { FormikCustomSelector } from 'components/Controls';
import { getIntegrationDisplayName, getServiceCategory } from 'models/integration';
import { IncomeAccountSelector } from 'shared/IncomeAccountSelector';
import { PRODUCT_IMPORT_METADATA_KEYS } from 'views/Billing/consts';
import { BinIcon, PlusSignIcon } from 'components/Icons';
import { IntegrationProductSelector } from 'shared/IntegrationProductSelector';

const AddContainer = styled(Flexer)`
  justify-content: flex-end;
  align-items: center;
  font-style: italic;
  color: var(--primaryBlue);
  font-size: 12px;
  gap: 6px;
  pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'auto')};
  opacity: ${({ isDisabled }) => (isDisabled ? 0.5 : 1)};

  &:hover {
    cursor: pointer;
  }
`;

const AddIconWrapper = styled(Centerer)`
  width: 28px;
  height: 28px;
  background-color: var(--primaryBlue10);
  border-radius: 50%;
`;

const RemoveButton = styled(Centerer)`
  width: 16px;
  height: 16px;
  padding-top: 18px;
  pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'auto')};

  &:hover {
    cursor: pointer;
  }
`;

const EMPTY_DATA = {
  integration_id: null,
  id: null,
  name: null,
  service: null,
  metadata: {},
};

export const GeneralLedgerOptions = ({ values, setFieldValue, existingGlImports }) => {
  const { integrations } = useContext(AppContext);
  const glIntegrationOptions = integrations.filter(
    (integration) =>
      integration.type === INTEGRATION_TYPES.GL &&
      GL_INTEGRATION_SERVICES.includes(getServiceCategory(integration.service)),
  );

  const initialValues = values.glImports?.length ? values.glImports : [EMPTY_DATA];

  const isAddButtonDisabled = glIntegrationOptions.length === initialValues.length;

  const handleImportChange = ({ providerId, name, code, index }) => {
    setFieldValue(`glImports.${index}.provider_object_id`, providerId);
    setFieldValue(`glImports.${index}.name`, name);
    setFieldValue(`glImports.${index}.metadata.code`, code);
  };

  return glIntegrationOptions.length ? (
    <FieldArray name="glImports">
      {(arrayHelpers) => (
        <FlexerColumn gap="8px">
          <FlexBetweenContainer>
            <label style={{ fontSize: 14, display: 'block' }}>
              <b>Links with your General Ledgers</b>
            </label>

            <AddContainer
              onClick={() => arrayHelpers.push(EMPTY_DATA)}
              isDisabled={isAddButtonDisabled}
              data-cy="product-actions-modal__add-link-button"
            >
              <span>Add</span>
              <AddIconWrapper>
                <PlusSignIcon size="16px" fill="var(--primaryBlue)" />
              </AddIconWrapper>
            </AddContainer>
          </FlexBetweenContainer>

          {initialValues.map((productImport, index) => (
            <Flexer
              gap="8px"
              key={`${productImport.provider_object_id}${productImport.integration_id}`}
              alignItems="center"
            >
              <FormikCustomSelector
                name={`glImports.${index}.integration_id`}
                options={glIntegrationOptions.map((integration) => ({
                  label: `${getIntegrationDisplayName(integration)} / ${integration.id}`,
                  value: integration.id,
                }))}
                label="General Ledger"
                placeholder="Select a GL..."
                containerWidth="100%"
                handleChange={({ value }) => {
                  const selectedImport = existingGlImports?.find(
                    (existingGlImport) => existingGlImport.integration_id === value,
                  );
                  setFieldValue(`glImports.${index}.integration_id`, value);
                  setFieldValue(`glImports.${index}.metadata.${PRODUCT_IMPORT_METADATA_KEYS.INCOME_ACCOUNT}`, null);
                  handleImportChange({
                    providerId: selectedImport?.provider_object_id,
                    name: selectedImport?.metadata?.product_name,
                    code: selectedImport?.metadata?.code,
                    index,
                  });
                }}
                dataCy={`product-actions-modal__general-ledger-selector--${index}`}
              />

              <IntegrationProductSelector
                name={`glImports.${index}.provider_object_id`}
                existingGlImports={existingGlImports}
                integrationId={productImport.integration_id}
                productImportName={productImport.name}
                productImportProviderId={productImport.provider_object_id}
                label={`${
                  getIntegrationDisplayName(
                    glIntegrationOptions.find((option) => option.id === productImport.integration_id),
                  ) || 'General ledger'
                } product ${!productImport?.integration_id ? '(select a GL first)' : ''}`}
                handleChange={({ providerId, name, code }) => handleImportChange({ providerId, name, code, index })}
                dataCy={`product-actions-modal__provider-object-id--${index}`}
              />

              <IncomeAccountSelector
                integrationId={productImport?.integration_id}
                name={`glImports.${index}.metadata.${PRODUCT_IMPORT_METADATA_KEYS.INCOME_ACCOUNT}`}
                isDisabled={!productImport?.provider_object_id}
                placeholder="select income account..."
                label={productImport?.provider_object_id ? 'Income Account' : 'Income Account (Must link to GL first)'}
                dataCy="product-actions-modal-income-account"
                containerWidth="100%"
              />
              {initialValues.length > 1 && (
                <RemoveButton
                  onClick={() => arrayHelpers.remove(index)}
                  isDisabled={initialValues.length === 0}
                  data-cy={`product-actions-modal__remove-link-button--${index}`}
                >
                  <BinIcon
                    size="16px"
                    fill={initialValues.length === 0 ? 'var(--primaryBlack5)' : 'var(--primaryBlack30)'}
                  />
                </RemoveButton>
              )}
            </Flexer>
          ))}
        </FlexerColumn>
      )}
    </FieldArray>
  ) : null;
};
