import { useContext } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { isNil } from 'lodash';

import { AppContext } from 'AppContext';
import { ReactComponent as ArrowUp } from 'images/arrow_up_bold.svg';
import { ReactComponent as GreyDot } from 'images/grey-dot.svg';
import { ReactComponent as FillDot } from 'images/fill-dot.svg';
import { getCustomerDisplayName } from 'models/customer';
import { Centerer, Spacer } from 'components/Core';
import { StyledTableNameTitle, TableSubRowsCount, TableSubRowsCountArrow } from 'components/Table';
import { TooltipContainer } from 'components/Tooltip';
import { useCurrencyNumberFormatter } from 'utils/hooks';
import { RECOGNITION_TYPES, NUMBER_FORMATS } from 'consts/global';
import { RelationLabel } from 'views/Customers/styles';

import { checkRowIsReviewed } from './utils';

dayjs.extend(utc); //without this, time comparisons get messed up

const StyledCustomerGroup = styled(Centerer)`
  position: absolute;
  justify-content: flex-start;
`;

const TransactionRowTitle = styled.div``;

const TransactionTableGroupHeadRow = ({ row, value }) => {
  const { dateFormat } = useContext(AppContext);
  const currencies = new Set(row.subRows.map((trx) => trx.original.currency).filter((c) => !isNil(c)));
  const currency = currencies.size ? Array.from(currencies)[0] : undefined;
  const numberFormatter = useCurrencyNumberFormatter({ currency });

  const customerStartingDate = row.original.earliest_start_date ?? new Date();
  const customerEndingDate = row.original.latest_end_date ?? new Date();

  const hasTillCanceledTransactionWithoutEndDate = row.subRows.some(
    (trx) => trx.original.recognition === RECOGNITION_TYPES.tillCanceled && !trx.original.end_date,
  );

  const dtFormat = dateFormat ?? 'L';

  const timePeriod = `${dayjs.utc(customerStartingDate).format(dtFormat)} ${
    hasTillCanceledTransactionWithoutEndDate
      ? ' - Till Canceled'
      : customerEndingDate
      ? '- ' + dayjs.utc(customerEndingDate).format(dtFormat)
      : ''
  }`;

  return (
    <>
      {row.canExpand ? (
        <StyledCustomerGroup>
          {row.original.childCustomer && <RelationLabel>Child</RelationLabel>}
          <StyledTableNameTitle
            isUnassigned={!(row.original.group_customer_id && row.original.group_customer_id !== 'null')}
          >
            {row.original.group_title && row.original.group_customer_id !== 'null'
              ? getCustomerDisplayName({
                  customerName: row.original.group_title,
                  customerId: row.original.group_customer_id,
                  customerEmail: row.original.group_customer_email,
                })
              : row.original.group_title ?? 'Unassigned Transactions'}
          </StyledTableNameTitle>
          <GreyDot style={{ marginRight: 12, marginLeft: 12 }} />

          {row.original.group_customer_id && row.original.group_customer_id !== 'null' ? (
            <>
              <span style={{ whiteSpace: 'nowrap', color: 'var(--primaryBlack)', fontWeight: 'normal', fontSize: 12 }}>
                {timePeriod}
              </span>
              <GreyDot style={{ marginRight: 12, marginLeft: 12 }} />
            </>
          ) : (
            <></>
          )}

          {currencies.size < 2 && ( //there's nothing we can show that'll make sense if more than 1 currency
            <span
              style={{
                color: 'var(--primaryGreen)',
                fontWeight: 900,
                fontSize: 12,
              }}
              data-cy="transactions-table__customer-total-amount"
            >
              {numberFormatter({ type: NUMBER_FORMATS.CURRENCY, rawValue: row.original.amount })}
            </span>
          )}

          <Spacer width="24px" />
          <TableSubRowsCount
            data-cy={'transactions-group-expander'}
            {...row.getToggleRowExpandedProps()}
            isHidden={!row.isExpanded}
          >
            {row.subRows.length}
            <TableSubRowsCountArrow isHidden={!row.isExpanded}>
              <ArrowUp />
            </TableSubRowsCountArrow>
          </TableSubRowsCount>
        </StyledCustomerGroup>
      ) : (
        <TransactionRowTitle data-cy={'transaction-table__row__date-cell'}>
          <TooltipContainer
            width={180}
            underline={false}
            isVisible={!checkRowIsReviewed(row)}
            toolTipContent={
              <p style={{ textAlign: 'center', margin: 0 }}>{"You haven't viewed this transaction yet"}</p>
            }
          >
            {!checkRowIsReviewed(row) && (
              <FillDot
                fill={'var(--primaryBlue)'}
                style={{ marginRight: 10 }}
                data-cy="transaction-table__row__unreviewed-dot"
              />
            )}
            <span style={{ fontWeight: 500, whiteSpace: 'nowrap' }}>{dayjs.utc(value).format(dtFormat)}</span>
          </TooltipContainer>
        </TransactionRowTitle>
      )}
    </>
  );
};

export default TransactionTableGroupHeadRow;
