import React, { useContext, useState } from 'react';
import { AppContext } from 'AppContext';
import { useIntegrationsAPI } from 'api/integrations';
import { SYNC_ENTITIES } from 'shared/Common';
import { useToasts } from 'components/Toasts';
import { CircleLoader } from 'components/Loaders';
import { AddButton } from '../styles';

export const SyncAccountsButton = ({ integrationId }) => {
  const { orgId } = useContext(AppContext);
  const {
    operations: { syncEntity },
  } = useIntegrationsAPI({ orgId, autoFetch: false });

  const { pushError } = useToasts();
  const [isLoading, setIsLoading] = useState(false);

  const handleSyncAccounts = async () => {
    setIsLoading(true);
    try {
      await syncEntity.mutateAsync({
        integrationId,
        entity: SYNC_ENTITIES.ACCOUNTS,
        initialSync: true,
      });
    } catch (error) {
      pushError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return !integrationId || isLoading ? (
    <CircleLoader width={30} height={30} />
  ) : (
    <AddButton onClick={handleSyncAccounts}>Sync Accounts from GL</AddButton>
  );
};
