import axios from 'axios';
import { API_URL } from 'api/consts';

const buildUrl = ({ orgId, invoiceMemoTemplateId }) => {
  const baseUrl = `${API_URL}/organizations/${orgId}/invoice_memo_templates`;
  if (!invoiceMemoTemplateId) return baseUrl;

  return `${baseUrl}/${invoiceMemoTemplateId}`;
};

export const getInvoiceMemoTemplates = async ({ orgId, params }) => {
  const response = await axios.get(buildUrl({ orgId }), { params });
  return response.data;
};

export const getInvoiceMemoTemplate = async ({ orgId, invoiceMemoTemplateId }) => {
  const response = await axios.get(buildUrl({ orgId, invoiceMemoTemplateId }));
  return response.data;
};

export const createInvoiceMemoTemplate = async ({ orgId, body }) => {
  const response = await axios.post(buildUrl({ orgId }), body);
  return response.data;
};

export const updateInvoiceMemoTemplate = async ({ orgId, invoiceMemoTemplateId, body }) => {
  const response = await axios.patch(buildUrl({ orgId, invoiceMemoTemplateId }), body);
  return response.data;
};
