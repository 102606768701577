import React, { useContext } from 'react';
import { Formik } from 'formik';
import styled from 'styled-components';
import * as Yup from 'yup';

import { AppContext } from 'AppContext';
import { createCustomer } from 'api';
import { ReactComponent as HeaderIcon } from 'images/attach_customer.svg';
import { ReactComponent as HeaderIconDetach } from 'images/detach_customer.svg';
import { ModalContainer, ModalBody, ModalHeader, ModalCloseIcon } from 'components/Modal';
import { getCustomersFromSearch } from 'shared/TransactionContent';
import { FormikCustomSelector } from 'components/Controls';
import { Row, Column, Spacer } from 'components/Core';
import { InfoIcon } from 'components/Icons';
import { LinkIcon } from 'shared/TransactionContent/TransactionHeader/TransactionDataActions/styles';
import { humanize, stringToBoolean } from 'utils/stringUtils';

import { CHILD_PARENT, CHILD_PARENT_MODAL_ACTIONS } from './CustomerHierarchyTable';
import { ExternalLink, IconWrapper, InformationText } from '../styles';
import { INTEGRATION_METADATA_FIELDS } from 'consts/integrations';

const InformationModal = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  background: #ffffff;
  box-shadow: 0px 30px 120px rgba(0, 21, 46, 0.4);
  border-radius: 20px;
  padding: 10px;
  width: 400px;
`;

const ChildParentModal = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  background: #ffffff;
  box-shadow: 0px 30px 120px rgba(0, 21, 46, 0.4);
  border-radius: 20px;
  padding: 24px;
  width: 280px;
`;

const InfoMessage = styled.div`
  font-size: 10px;
  line-height: 14px;
  opacity: 0.5;
  margin-top: 10px;
`;

const ButtonsRow = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const CancelButton = styled.div`
  padding: 11px 14px;
  font-size: 14px;
  line-height: 18px;
  background: var(--primaryBlack5);
  border: 1px solid var(--primaryBlack5);
  border-radius: 100px;
  margin-right: 10px;
  cursor: pointer;
`;

const SubmitButton = styled.div`
  padding: 11px 14px;
  font-size: 14px;
  color: #ffffff;
  line-height: 18px;
  background: ${(props) => (props.detach ? 'var(--primaryRed)' : 'var(--primaryGreen)')};
  box-shadow: 0px 4px 20px rgba(82, 181, 120, 0.2);
  border-radius: 100px;
  border: none;
  cursor: pointer;
`;

const ModalTitle = styled.div`
  font-size: 20px;
  line-height: 26px;
  font-weight: 900;
  margin-bottom: 20px;
  text-align: center;
  text-transform: capitalize;
`;

const StyledHeaderIcon = styled.div`
  position: absolute;
  transform: translate(-50%, 0);
  left: 50%;
  top: -20px;
`;

const ChildParentActionModal = ({ customer, childOrParent, childCustomer, closeModal, modalAction }) => {
  const { organizations, integrations } = useContext(AppContext);

  const initialValue =
    modalAction === CHILD_PARENT_MODAL_ACTIONS.DETACH ||
    (modalAction === CHILD_PARENT_MODAL_ACTIONS.ATTACH &&
      customer.parent_customer_id &&
      childOrParent === CHILD_PARENT.PARENT)
      ? {
          customer_id: childOrParent === CHILD_PARENT.PARENT ? customer.parent_customer_id : childCustomer?.id,
          customer_name: childOrParent === CHILD_PARENT.PARENT ? customer.parent_customer_name : childCustomer?.name,
        }
      : {
          customer_id: '',
          customer_name: '',
        };

  const nightlySyncedIntegrations = (integrations ?? [])?.filter(
    (i) => stringToBoolean(i?.metadata?.[INTEGRATION_METADATA_FIELDS.SKIP_DAILY_SYNC]) === false,
  );
  const isImported =
    (customer.imports ?? []).filter((customerImport) =>
      nightlySyncedIntegrations.map((i) => i.service).includes(customerImport.provider_name),
    ).length > 0;

  if (isImported)
    return (
      <ModalContainer>
        <InformationModal>
          <ModalHeader>
            <ModalCloseIcon onClose={() => closeModal({ modalAction: null })} />
            <ModalTitle>Imported customer</ModalTitle>
          </ModalHeader>
          <ModalBody>
            <Column horizontal="start">
              <Spacer height="6px" />
              <Row vertical="flex-start" horizontal="space-between">
                <IconWrapper>
                  <InfoIcon size="20px" />
                </IconWrapper>
                <Spacer width="10px" />
                <Column horizontal="start">
                  <InformationText>
                    This customer was imported from an external source. If you want to update the hierarchy, please
                    update them from their external sources, since Subscript will import new changes overnight.
                  </InformationText>
                  <Spacer height="6px" />
                  {customer.imports.map((customerImport) => (
                    <ExternalLink onClick={() => window.open(customerImport.external_url, '_blank')}>
                      <div>Go to {humanize(customerImport.provider_name)}</div>
                      <Spacer width="6px" />
                      <LinkIcon />
                    </ExternalLink>
                  ))}
                </Column>
              </Row>
            </Column>
          </ModalBody>
        </InformationModal>
      </ModalContainer>
    );

  return (
    <ModalContainer>
      <ChildParentModal>
        <StyledHeaderIcon>
          {modalAction === CHILD_PARENT_MODAL_ACTIONS.ATTACH ? <HeaderIcon /> : <HeaderIconDetach />}
        </StyledHeaderIcon>
        <ModalTitle>
          {modalAction === CHILD_PARENT_MODAL_ACTIONS.ATTACH
            ? `${
                customer?.parent_customer_id && childOrParent === CHILD_PARENT.PARENT ? 'Change' : 'Attach'
              } ${childOrParent}`
            : `Detach ${childOrParent}`}
        </ModalTitle>

        <Formik
          initialValues={initialValue}
          validationSchema={Yup.object({
            customer_id: Yup.string().nullable().not([customer.id], 'You cannot select the current customer'),
          })}
          enableReinitialize={true}
          onSubmit={(values) => {
            closeModal({
              values,
              modalAction,
              childOrParent,
            });
          }}
        >
          {({ values, setFieldValue, handleSubmit }) => (
            <div>
              {modalAction === CHILD_PARENT_MODAL_ACTIONS.DETACH && (
                <div style={{ marginBottom: 10 }}>
                  Do you really want to detach next {childOrParent === CHILD_PARENT.CHILD ? 'child' : 'parent'}{' '}
                  customer?
                </div>
              )}

              <FormikCustomSelector
                isDisabled={modalAction === CHILD_PARENT_MODAL_ACTIONS.DETACH}
                boldValue
                creatable
                label="Customer"
                placeholder="Select customer"
                value={
                  values.customer_id
                    ? {
                        label: values.customer_name,
                        value: values.customer_id,
                      }
                    : null
                }
                name="customer_id"
                handleChange={(option) => {
                  if (option) {
                    setFieldValue('customer_id', option.value);
                    setFieldValue('customer_name', option.label);
                  } else {
                    setFieldValue('customer_id', null);
                    setFieldValue('customer_name', null);
                  }
                }}
                onCreateOption={async (newCustomerName) => {
                  const newCustomer = await createCustomer({
                    orgId: organizations[0].id,
                    customerName: newCustomerName,
                  });
                  setFieldValue('customer_id', newCustomer.id);
                  setFieldValue('customer_name', newCustomer.name);
                }}
                loadOptions={(searchQuery, prevOptions, additional) =>
                  getCustomersFromSearch({ searchQuery, orgId: organizations[0]?.id, prevOptions, additional })
                }
                isPaginateable
                isClearable
              />

              {values.customer_id && (
                <InfoMessage>
                  {modalAction === CHILD_PARENT_MODAL_ACTIONS.ATTACH ? (
                    <>
                      {childOrParent === CHILD_PARENT.CHILD ? (
                        <>
                          Data for <b>“{values.customer_name}“</b> will be in included under <b>“{customer?.name}”</b>{' '}
                          when viewing data rolled up by parent
                        </>
                      ) : (
                        <>
                          Data for <b>“{customer?.name}”</b> will be in included under <b>“{values.customer_name}“</b>{' '}
                          when viewing data rolled up by parent
                        </>
                      )}
                    </>
                  ) : (
                    <>All data of that customer will be saved</>
                  )}
                </InfoMessage>
              )}
              <ButtonsRow>
                <CancelButton
                  data-cy="child-parent-modal__cancel"
                  onClick={() => closeModal({ values: null, modalAction: null, childOrParent: null })}
                >
                  No, cancel
                </CancelButton>
                <SubmitButton
                  data-cy="child-parent-modal__save"
                  detach={modalAction === CHILD_PARENT_MODAL_ACTIONS.DETACH}
                  onClick={() => handleSubmit()}
                >
                  {modalAction === 'attach' ? 'Yes, save' : 'Yes, detach'}
                </SubmitButton>
              </ButtonsRow>
            </div>
          )}
        </Formik>
      </ChildParentModal>
    </ModalContainer>
  );
};

export { ChildParentActionModal };
