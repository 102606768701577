import React, { useMemo } from 'react';
import { Subject } from 'utils/observable/Subject';
import { ObservableContext } from './ObservableContext';

export const ObserverProvider = ({ children }) => {
  /*
   * We could add as many as we want in the future
   * or we could have subject functions receive (key, callback)
   */
  const transactionsObservable = useMemo(() => {
    return new Subject();
  }, []);

  return <ObservableContext.Provider value={{ transactionsObservable }}>{children}</ObservableContext.Provider>;
};
