import { useContext } from 'react';
import dayjs from 'dayjs';
import { AppContext } from 'AppContext';
import { TruncateStringWithTooltip } from 'components/Tooltip';
import { NUMBER_FORMATS, RECOGNITION_TYPES } from 'consts/global';
import { useCurrencyNumberFormatter } from 'utils/hooks';
import {
  RelatedSelectorName,
  RelatedSelectorInfoWrapper,
  RelatedSelectorCustomOption,
  RelatedSelectorDates,
  RelatedSelectorAmount,
} from './styles';

const MenuOption = ({ name, end_date, start_date, recognition, amount, currency }) => {
  const { formatDateWithLocale } = useContext(AppContext);
  const numberFormatter = useCurrencyNumberFormatter();

  return (
    <RelatedSelectorCustomOption>
      <RelatedSelectorName className="relatedName">
        <TruncateStringWithTooltip length={20} tooltipWidth={200}>
          {name ? name : dayjs(end_date).format('MMM YYYY')}
        </TruncateStringWithTooltip>
      </RelatedSelectorName>

      <RelatedSelectorInfoWrapper>
        <RelatedSelectorDates>
          {formatDateWithLocale(start_date)} -{' '}
          {end_date
            ? formatDateWithLocale(end_date)
            : recognition === RECOGNITION_TYPES.tillCanceled
            ? 'Till Canceled'
            : 'No end date'}
        </RelatedSelectorDates>
        <RelatedSelectorAmount className="relatedAmount">
          {numberFormatter({ type: NUMBER_FORMATS.CURRENCY, rawValue: amount, overrideCurrency: currency })}
        </RelatedSelectorAmount>
      </RelatedSelectorInfoWrapper>
    </RelatedSelectorCustomOption>
  );
};

export const formatOptionLabel = ({ name, end_date, start_date, recognition, amount, currency }, { context }) => {
  if (context === 'value') {
    return name ? name : dayjs(end_date).format('MMM YYYY');
  } else if (context === 'menu') {
    return (
      <MenuOption
        name={name}
        end_date={end_date}
        start_date={start_date}
        recognition={recognition}
        amount={amount}
        currency={currency}
      />
    );
  }
};
