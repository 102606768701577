import styled from 'styled-components';
import { Flexer, FlexerColumn } from 'components/Core';

export const ContentWrapper = styled.div`
  background-color: var(--primaryBlack3);
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  width: 100%;
  z-index: 1;
`;

export const ModalHeader = styled(FlexerColumn)``;

export const ModalSubHeading = styled(Flexer)`
  align-items: center;
  font-size: 14px;
  text-align: left;
  padding: 24px 52px;
  border-bottom: 1px solid var(--accentGraySecond);

  > em {
    margin-left: 4px;
    font-style: italic;
    font-weight: 700;
  }
`;

export const ModalHeading = styled.div`
  background-color: white;
  font-size: 20px;
  font-weight: 900;
  line-height: 26px;
  padding: 24px 52px;

  > a {
    margin-left: 6px;
    color: var(--primaryBlack50);
    font-style: italic;
  }
`;
