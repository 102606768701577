import { useAuth0 } from '@auth0/auth0-react';
import { EVENTS } from 'consts/analytics';
import { clearAppSettings } from 'models/appSettings/base';
import { useAnalytics } from 'utils/hooks';
import { formatCustomerName } from 'utils/stringUtils';
import { clearCachedMetrics } from 'utils/cachedMetricsUtils';
import { clearLocalStorage } from 'utils/storageUtils';

export const useCurrentUser = () => {
  const { user = {} } = useAuth0();

  const getUserIdentity = () => {
    // People who sign up via email-password instead of socials
    if (user?.name === user?.email) return user?.nickname;

    // for people that sign up via socials like google
    return formatCustomerName(user?.name);
  };

  return {
    name: getUserIdentity(),
  };
};

export const useLogout = () => {
  const { logout } = useAuth0();
  const { trackEvent } = useAnalytics();

  return {
    logout: () => {
      trackEvent({ name: EVENTS.LOG_OUT });
      // [JB 2021-12-15] https://github.com/ChifferCo/chiffer/issues/2043
      // Let's start with appSettings, later we might want to remove more or all
      clearAppSettings();
      clearCachedMetrics();
      clearLocalStorage();
      logout({ returnTo: window.location.origin });
    },
  };
};
