import React from 'react';
import { Flexer, FlexerColumn } from 'components/Core';
import { ISO_CODE_TO_SYMBOL } from 'consts/global';
import { FormikCustomInput } from 'components/Controls';
import { ReactComponent as CornerDownRightIcon } from 'images/corner-down-right-icon.svg';
import { BundleItemName, BundleItemTitle, BundleItemsWrapper } from '../../../InvoiceScheduleWarnings/styles';
import { getFieldChangesProps } from '../utils';

export const InvoiceItemBundleItems = ({
  items,
  invoiceItemIndex,
  disableEditing,
  values,
  currency,
  setFieldValue,
  syncInvoiceItemAmount,
  invoicingServiceDisplayName,
}) => {
  const handleValueChange = ({ index, updateValues, updatedItems }) => {
    Object.entries(updateValues).forEach(([key, value]) => {
      setFieldValue(`invoice_items[${invoiceItemIndex}].metadata.bundleItems[${index}].${key}`, value);
    });

    syncInvoiceItemAmount(updatedItems);
  };

  const handleQuantityChange = ({ index, value, bundleItem }) => {
    const unitPrice = ((bundleItem.unit_price ?? 0) * (bundleItem.seats ?? 0)) / (value || 1);
    const updateValues = { unit_price: unitPrice, seats: value };
    const updatedItems = items.map((item, i) => (i === index ? { ...item, ...updateValues } : item));

    handleValueChange({ index, updateValues, updatedItems });
  };

  const handlePerUnitPriceChange = ({ index, value }) => {
    const updateValues = { unit_price: value ?? 0 };
    const updatedItems = items.map((item, i) => (i === index ? { ...item, ...updateValues } : item));

    handleValueChange({ index, updateValues, updatedItems });
  };

  const handleAmountChange = ({ index, value, bundleItem }) => {
    // the value passed in is the total amount of the bundle item
    const unitPrice = (value ?? 0) / (bundleItem.seats || 1);
    const updateValues = { unit_price: unitPrice };
    const updatedItems = items.map((item, i) => (i === index ? { ...item, ...updateValues } : item));

    handleValueChange({ index, updateValues, updatedItems });
  };

  return (
    <FlexerColumn padding="0 50px 0 36px">
      <BundleItemsWrapper
        data-cy={`schedule-modal__invoice__item${invoiceItemIndex}__bundle-items`}
        gap="4px"
        width="100%"
        alignItems="center"
      >
        {items.map((item, index) => (
          <FlexerColumn gap="8px" width="100%" key={item.id}>
            <Flexer gap="12px" alignItems="center">
              <BundleItemTitle
                gap="6px"
                alignItems="center"
                justifyContent="flex-end"
                width="80%"
                marginTop={index === 0 && '24px'}
              >
                <CornerDownRightIcon />
                {invoicingServiceDisplayName} bundle product:
              </BundleItemTitle>
              <BundleItemName marginTop={index === 0 && '24px'}>
                <FormikCustomInput
                  name={`invoice_items[${invoiceItemIndex}].metadata.bundleItems[${index}].name`}
                  data-cy={`schedule-modal__invoice__item${invoiceItemIndex}__bundle-item${index}-product`}
                  type="text"
                  width="100%"
                  tooltipInputDisplay={`This product is part of a bundle and cannot be modified here. Please adjust in ${invoicingServiceDisplayName} if desired.`}
                  isDisabled
                />
              </BundleItemName>
              <FormikCustomInput
                inputWidth="75px"
                name={`invoice_items[${invoiceItemIndex}].metadata.bundleItems[${index}].seats`}
                data-cy={`schedule-modal__invoice__item${invoiceItemIndex}__bundle-item${index}-seats`}
                type="number"
                precision={2}
                handleChange={(value) => handleQuantityChange({ index, value, bundleItem: item })}
                isDisabled={disableEditing}
                labelFlex="end"
                label={index === 0 ? 'Quantity' : ''}
                {...getFieldChangesProps({
                  values,
                  field: `invoice_items[${invoiceItemIndex}].metadata.bundleItems[${index}].seats`,
                })}
              />
              <FormikCustomInput
                inputWidth="120px"
                name={`invoice_items[${invoiceItemIndex}].metadata.bundleItems[${index}].unit_price`}
                data-cy={`schedule-modal__invoice__item${invoiceItemIndex}__bundle-item${index}-unit_price`}
                suffix={ISO_CODE_TO_SYMBOL[values?.invoice_items?.[0]?.currency ?? currency] ?? '$'}
                precision={2}
                type="number"
                handleChange={(value) => handlePerUnitPriceChange({ index, value })}
                value={item.unit_price}
                isDisabled={disableEditing}
                labelFlex="end"
                label={index === 0 ? 'Per unit' : ''}
                {...getFieldChangesProps({
                  values,
                  field: `invoice_items[${invoiceItemIndex}].metadata.bundleItems[${index}].unit_price`,
                })}
              />
              <FormikCustomInput
                inputWidth="120px"
                name={`invoice_items[${invoiceItemIndex}].metadata.bundleItems[${index}].amount`}
                data-cy={`schedule-modal__invoice__item${invoiceItemIndex}__bundle-item${index}-amount`}
                suffix={ISO_CODE_TO_SYMBOL[values?.invoice_items?.[0]?.currency ?? currency] ?? '$'}
                precision={2}
                type="number"
                handleChange={(value) => handleAmountChange({ index, value, bundleItem: item })}
                value={(item.unit_price ?? 0) * (item.seats ?? 0)}
                isDisabled={disableEditing}
                labelFlex="end"
                label={index === 0 ? 'Amount' : ''}
                {...getFieldChangesProps({
                  values,
                  field: `invoice_items[${invoiceItemIndex}].metadata.bundleItems[${index}].unit_price`,
                })}
              />
            </Flexer>
          </FlexerColumn>
        ))}
      </BundleItemsWrapper>
    </FlexerColumn>
  );
};
