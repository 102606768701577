import React, { useState } from 'react';
import styled from 'styled-components';

import { EVENTS } from 'consts/analytics';
import { useAnalytics, useWindowScrollPositions } from 'utils/hooks';
import { Centerer } from 'components/Core';
import { ChevronRight } from 'components/Icons';
import { GlobalTogglesModal } from './GlobalTogglesModal';

const Floater = styled(Centerer)`
  display: none;
  cursor: pointer;
  position: fixed;
  bottom: 40px;
  left: calc(50%);
  z-index: 49;
  padding: 8px 8px 8px 12px;
  gap: 12px;
  background: var(--primaryBlack30);
  backdrop-filter: blur(20px);
  border-radius: 100px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #ffffff;

  &:hover {
    background: var(--primaryBlack50);
  }
`;

export const HoverWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  &:hover {
    ${Floater} {
      ${({ showFloater }) => showFloater && 'display: flex'}
    }
  }
`;

const Circle = styled(Centerer)`
  width: 16px;
  height: 16px;
  padding: 2px;
  background: #ffffff;
  border-radius: 100px;
`;

export const GlobalTogglesFloatingContainer = ({ children, showAfterScrolling = 280 }) => {
  const { trackEvent } = useAnalytics();
  const { scrollPosition } = useWindowScrollPositions();
  const [showModal, setShowModal] = useState(false);
  const openModal = () => {
    trackEvent({
      name: EVENTS.OPEN_GLOBAL_TOGGLES_MODAL,
    });
    setShowModal(true);
  };
  const closeModal = () => setShowModal(false);

  return (
    <HoverWrapper showFloater={scrollPosition >= showAfterScrolling}>
      {children}
      {!showModal && (
        <Floater onClick={openModal}>
          See settings affecting these calculations
          <Circle>
            <ChevronRight />
          </Circle>
        </Floater>
      )}
      {showModal && <GlobalTogglesModal onCloseModal={closeModal} />}
    </HoverWrapper>
  );
};
