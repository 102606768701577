import styled, { css } from 'styled-components';
import { Centerer, Flexer, FlexerColumn } from 'components/Core';

export const SelectorWrapper = styled.div`
  position: relative;
`;

export const RecognitionToggler = styled(Centerer)`
  font-size: 16px;
  line-height: 22px;
  padding: 6px 11px;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid var(--primaryBlack10);
  background-color: white;

  svg {
    width: 10px;
    margin-left: 10px;
    transform: ${({ active }) => active && 'rotate(180deg)'};
    path {
      fill: #cccccc;
    }
  }

  ${({ empty, bannerVersion }) =>
    empty && !bannerVersion
      ? css`
          font-weight: 400;
          background: ${({ active }) => (active ? 'var(--primaryRed20)' : 'var(--primaryRed10)')};
          color: ${({ active }) => (active ? 'var(--secondaryRed)' : 'var(--primaryRed)')};

          &:hover {
            background: var(--primaryRed20);
            color: var(--secondaryRed);
          }
        `
      : bannerVersion
      ? css`
          font-weight: 700;
          padding: 2px 4px;
          border-radius: 4px;
          font-size: 12px;
          line-height: 16px;
          color: ${({ active }) =>
            empty ? 'var(--primaryBlue50)' : active ? 'var(--secondaryBlue)' : 'var(--primaryBlue)'};
          background: var(--primaryBlue10);

          svg {
            display: none;
          }

          &:hover {
            color: var(--secondaryBlue);
          }
        `
      : css`
          font-weight: 900;
          color: ${({ active }) => (active ? 'var(--secondaryBlue)' : 'var(--primaryBlue)')};

          &:hover {
            border: 1px solid var(--accentGraySecond);
            color: var(--secondaryBlue);
          }
        `}
`;

export const StyledSelectorMenuBase = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 14px;
  width: ${({ menuWidth }) => menuWidth ?? '398px'};
  background: #ffffff;
  box-shadow: 16px 16px 60px var(--primaryBlack10);
  border-radius: 8px;
`;

export const StyledSelectorPopover = styled(StyledSelectorMenuBase)`
  position: absolute;
  top: 33px;
  left: 0;
  border: 1px solid var(--accentGraySecond);
  border-radius: 12px;
`;

export const GroupWrapper = styled.div`
  &:first-child {
    border-bottom: 1px solid var(--primaryBlack5);
    margin-bottom: 16px;
    padding-bottom: 8px;
  }
`;

export const GroupTitle = styled.div`
  font-weight: 900;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  color: var(--primaryBlack);
  opacity: 0.3;
  margin-bottom: 16px;
`;

export const RecognitionStyledIcon = styled(Centerer)`
  width: 36px;
  height: 36px;
  background: var(--primaryBlack2);
  border-radius: 8px;
  margin-right: 12px;
`;

export const RecognitionText = styled(FlexerColumn)`
  max-width: 300px;
`;

export const RecognitionLabel = styled.div`
  font-weight: 900;
  font-size: 12px;
  line-height: 16px;
  color: var(--primaryBlack);
  margin-bottom: 4px;
`;

export const RecognitionDescription = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--primaryBlack);
  opacity: 0.5;
`;

export const RecognitionRow = styled(Flexer)`
  padding: 8px;
  cursor: pointer;
  margin-bottom: 8px;

  &:hover {
    background: var(--primaryBlack2);
    border-radius: 8px;

    ${RecognitionStyledIcon} {
      background: var(--primaryBlack2);
      border: 1px solid var(--accentGraySecond);
    }
  }

  ${({ selected }) =>
    selected &&
    css`
      ${RecognitionStyledIcon} {
        background: #fff;
        border: 1px solid var(--accentGraySecond);
      }

      ${RecognitionLabel} {
        opacity: 0.3;
      }

      ${RecognitionDescription} {
        opacity: 0.3;
      }
    `}
`;
