import { getYearAndQuarter } from 'utils/dateUtils';
import { BUSINESS_SNAPSHOT_COLUMNS, WATERFALL_GRAPH_PERIOD_OPTIONS } from 'consts/global';

export const setQuarterColumn = ({
  date,
  selectedColumns,
  waterfallGraphPeriod,
  columnHeaders,
  waterfallColumnHeaders,
  quarterEndMonths,
  quartersYearOffset,
}) => {
  let quarterHeader;
  let yearHeader;

  const year = date.year();
  const month = date.month() + 1;

  const monthIsQuarter = quarterEndMonths.includes(month);

  if (monthIsQuarter) {
    const { year: quarterYear, quarter } = getYearAndQuarter({
      quarterEndMonths,
      quartersYearOffset,
      year,
      month,
    });

    quarterHeader = `${quarterYear}-Q${quarter}`;
    if (selectedColumns && selectedColumns[BUSINESS_SNAPSHOT_COLUMNS.QUARTERS]) {
      columnHeaders.push(quarterHeader);
    }
    if (waterfallGraphPeriod === WATERFALL_GRAPH_PERIOD_OPTIONS.QUARTERS) waterfallColumnHeaders.push(quarterHeader);

    yearHeader = `${quarterYear}-Y`;
    if (quarter === 4 && selectedColumns && selectedColumns[BUSINESS_SNAPSHOT_COLUMNS.YEARS]) {
      columnHeaders.push(yearHeader);
    }
    if (quarter === 4 && waterfallGraphPeriod === WATERFALL_GRAPH_PERIOD_OPTIONS.YEARS)
      waterfallColumnHeaders.push(yearHeader);
  }

  return {
    quarterHeader,
    yearHeader,
  };
};
