import React from 'react';
import { CentererVertical, Spacer } from 'components/Core';
import { FormikCustomSelector } from 'components/Controls';
import { INSTALL_BY_OPTIONS } from 'views/Cohorts/consts';

export const COHORT_INSTALL_BY_OPTIONS = Object.entries(INSTALL_BY_OPTIONS).map(([value, label]) => ({
  label,
  value,
}));

export const CohortInstallByField = () => (
  <CentererVertical>
    <div>Cohort Data:</div>
    <Spacer width="8px" />
    <FormikCustomSelector width="150px" options={COHORT_INSTALL_BY_OPTIONS} name="cohortInstallBy" />
  </CentererVertical>
);
