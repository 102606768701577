import { useCallback, useEffect, useRef } from 'react';
import { isNil } from 'lodash';
import styled from 'styled-components';
import { Centerer } from 'components/Core';
import { CircleLoader } from 'components/Loaders';
import { TooltipContainer } from 'components/Tooltip';
import { useUsageSubscriptionAPI } from 'api/usageBasedEngine/hooks';

const Wrapper = styled(Centerer)`
  padding: 8px;
  border-radius: 8px;
  gap: 4px;
  font-size: 10px;
  font-style: normal;
  font-weight: 900;
  line-height: 12px; /* 120% */
  text-transform: uppercase;
  background: var(--primaryBlack10);
  color: var(--primaryBlack50);
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  cursor: ${({ disabled }) => (disabled ? undefined : 'pointer')};
`;

export const TransactionRecalculateUsageButton = ({
  orgId,
  transactionId,
  usageSubscriptionId,
  fetchTransactionRef,
}) => {
  const prevJobId = useRef(null);

  const {
    data,
    operations: { recalculate },
  } = useUsageSubscriptionAPI({
    orgId,
    id: usageSubscriptionId,
    queryOptions: {
      enabled: !!usageSubscriptionId,
      refetchInterval: 1000,
    },
  });

  useEffect(() => {
    if (!isNil(prevJobId.current) && isNil(data?.spreads_sync_job_id)) {
      prevJobId.current = null;
      if (typeof fetchTransactionRef.current === 'function') {
        fetchTransactionRef.current(orgId, transactionId);
      }
    } else {
      prevJobId.current = data?.spreads_sync_job_id;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.spreads_sync_job_id]);

  const isRecalculating = !!data?.spreads_sync_job_id;

  const handleClick = useCallback(() => {
    if (!isRecalculating) recalculate.mutateAsync();
  }, [recalculate, isRecalculating]);

  return (
    <TooltipContainer
      yOffset={10}
      toolTipContent={
        isRecalculating
          ? 'We are recalculating the usage spreads of this transaction. The page will auto-refresh upon completion.'
          : 'We auto recalculate the usage spreads but you can use this button to force a recalculation'
      }
    >
      <Wrapper disabled={isRecalculating} onClick={handleClick}>
        {isRecalculating && <CircleLoader width={12} height={12} />}
        Recalculate
      </Wrapper>
    </TooltipContainer>
  );
};
