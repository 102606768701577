import dayjs from 'dayjs';
import styled from 'styled-components';
import { isNil, omit } from 'lodash';
import { numberFormatter } from 'utils/formatters';
import { EVENT_RECOGNITION_TYPES, NUMBER_FORMATS, RECOGNITION_TYPES } from 'consts/global';
import { InvoiceChanges } from 'shared/InvoicingScheduleChanges';
import { checkIfGmailDisconnected } from '../../utils';
import { earliestAndLatestDatesForTransactions } from '../utils';
import { ExternalUpdateRemoveTransactionsWarning } from './ExternalUpdateRemoveTransactionsWarning';

const TransactionLink = styled.span`
  color: var(--primaryBlue);
  cursor: pointer;
`;

const parseCurrency = (currencyString) => {
  // Remove any characters that are not digits or dots
  const cleanedString = currencyString.replace(/[^0-9.]+/g, '');
  const amount = parseFloat(cleanedString);
  // Convert to a floating-point number
  return isNaN(amount) ? 0 : amount;
};

export const getInvoicingScheduleWarnings = ({
  billedAmount,
  transactionsAmount,
  includedTransactions,
  invoices,
  invoicingScheduleFormValues,
  dataArchivedTransactions,
  expectedNumberOfInvoices,
  displayNameByTransactionId,
  dateFormat,
  defaultOrgCurrency,
  replacedTransactions,
  dismissedWarnings,
  disauthenticatedIntegrations,
  externalUpdates,
  resolveExternalUpdate,
  closeModal,
}) => {
  const warnings = [];
  const transactionsById = includedTransactions.flat().reduce((acc, transaction) => {
    acc[transaction.id] = transaction;
    return acc;
  }, {});

  // Add warning for disconnected Google Integration
  const isGmailDisconnected = checkIfGmailDisconnected({ disauthenticatedIntegrations });
  if (isGmailDisconnected) {
    warnings.push({
      warning: (
        <span>
          Reintegrate&nbsp;
          <TransactionLink
            onClick={() => window.open('/configure?tab=subscript-billing&subTab=billing-invoice-settings', '_blank')}
          >
            Google apps API
          </TransactionLink>
          &nbsp; again to enable invoice sending.
        </span>
      ),
    });
  }

  const dates = earliestAndLatestDatesForTransactions(includedTransactions.flat());
  const frequency = invoicingScheduleFormValues?.invoicing_frequency;
  const startDate = dates.earliest;
  const endDate = dates.latest;

  const linearTransactions = includedTransactions.filter(
    (transaction) => transaction?.recognition === RECOGNITION_TYPES.linear,
  );
  if (frequency && startDate && endDate && linearTransactions.length > 0) {
    const nonVoidedInvoices = invoices.filter((invoice) => isNil(invoice.voided_at)); // ignore voided invoices

    if (!isNil(expectedNumberOfInvoices) && nonVoidedInvoices.length !== expectedNumberOfInvoices) {
      const numberOfMonths = Math.ceil(dayjs.utc(endDate).diff(startDate, 'months', true));
      warnings.push({
        warning: (
          <span>
            Your contract term is <b>{numberOfMonths}</b> months and the billing frequency is{' '}
            <b>{invoicingScheduleFormValues.invoicing_frequency}</b>, but you have <b>{nonVoidedInvoices.length}</b>{' '}
            {nonVoidedInvoices.length !== invoices.length ? 'non-voided ' : ''}
            invoices. You should have <b>{expectedNumberOfInvoices}</b> invoices.
          </span>
        ),
        dismissKey: `schedule-${invoicingScheduleFormValues?.id}-expected-${expectedNumberOfInvoices}-got-${invoices.length}-months-warning`,
      });
    }
  }

  if (
    !invoicingScheduleFormValues?.currency ||
    invoicingScheduleFormValues?.currency === defaultOrgCurrency // we're not supporting this check if currencies are different, yet
  ) {
    Object.keys(transactionsAmount)
      .filter((key) => key !== 'total')
      .forEach((transactionId) => {
        const transaction = transactionsById[transactionId];
        if (transaction?.recognition === RECOGNITION_TYPES.tillCanceled) return; // no warnings yet for those
        if (typeof billedAmount[transactionId] == 'string') return;

        const transactionCurrency = transaction.currency;
        const currency = transactionCurrency ?? defaultOrgCurrency;

        const amountBilledForTransaction = numberFormatter({
          type: NUMBER_FORMATS.CURRENCY,
          rawValue: billedAmount[transactionId] ?? 0,
          currency,
        });

        const AccountingSpreadAmountForTransaction = numberFormatter({
          type: NUMBER_FORMATS.CURRENCY,
          rawValue: transaction.accounting_spreads_total_amount ?? 0,
          currency,
        });

        if (EVENT_RECOGNITION_TYPES.includes(transaction?.recognition)) {
          if (
            parseCurrency(amountBilledForTransaction) > transaction.accounting_spreads_total_amount &&
            parseCurrency(amountBilledForTransaction) > parseCurrency(AccountingSpreadAmountForTransaction)
          ) {
            const displayName = displayNameByTransactionId[transactionId];

            warnings.push({
              warning: (
                <span>
                  You're invoicing <b>{amountBilledForTransaction}</b> for <b>{displayName}</b>, but you are recognizing{' '}
                  {numberFormatter({
                    type: NUMBER_FORMATS.CURRENCY,
                    rawValue: transaction.accounting_spreads_total_amount ?? 0,
                    currency,
                  })}{' '}
                  in Subscript.
                </span>
              ),
              dismissKey: `schedule-${invoicingScheduleFormValues?.id}-expected-${amountBilledForTransaction}-got-${AccountingSpreadAmountForTransaction}-amount-warning`,
            });
          }
        } else if (amountBilledForTransaction !== AccountingSpreadAmountForTransaction) {
          const displayName = displayNameByTransactionId[transactionId];

          warnings.push({
            warning: (
              <span>
                You're invoicing <b>{amountBilledForTransaction}</b> for <b>{displayName}</b> although{' '}
                <b>{AccountingSpreadAmountForTransaction}</b> was recognized in Subscript.
              </span>
            ),
            dismissKey: `schedule-${invoicingScheduleFormValues?.id}-expected-${amountBilledForTransaction}-got-${AccountingSpreadAmountForTransaction}-amount-warning`,
          });
        }
      });
  }

  if (replacedTransactions?.length > 0) {
    for (const replacedTransaction of replacedTransactions) {
      const {
        accounting_spreads_total_amount,
        invoice_item_sum,
        id,
        name,
        currency: transactionCurrency,
      } = replacedTransaction;
      if (invoice_item_sum !== accounting_spreads_total_amount) {
        const currency = transactionCurrency ?? defaultOrgCurrency;
        const invoicedAmount = numberFormatter({
          type: NUMBER_FORMATS.CURRENCY,
          rawValue: invoice_item_sum ?? 0,
          currency,
        });

        const accountingSpreadAmount = numberFormatter({
          type: NUMBER_FORMATS.CURRENCY,
          rawValue: accounting_spreads_total_amount ?? 0,
          currency,
        });

        warnings.push({
          warning: (
            <span>
              One of the transactions in this schedule replaces transaction{' '}
              <TransactionLink onClick={() => window.open(`/transactions/${id}`, '_blank')}>
                {name ? `"${name}"` : `#${id}`}
              </TransactionLink>
              . <br />
              That transaction is part of a schedule that is invoicing <b>{invoicedAmount}</b> but the recognized amount
              is <b>{accountingSpreadAmount}</b>. <br />
              Please revise that transaction's schedule to correct the amount being invoiced.
            </span>
          ),
          dismissKey: `schedule-${invoicingScheduleFormValues?.id}-expected-${invoicedAmount}-got-${accountingSpreadAmount}-replaced-amount`,
        });
      }
    }
  }

  if (externalUpdates?.length > 0) {
    const deleteTransactions =
      externalUpdates?.[0]?.externalUpdate?.update_data?.deletedTransactions ?? dataArchivedTransactions ?? [];

    if (!!deleteTransactions?.length) {
      warnings.push({
        warning: (
          <ExternalUpdateRemoveTransactionsWarning
            deleteTransactions={deleteTransactions}
            dateFormat={dateFormat}
            defaultOrgCurrency={defaultOrgCurrency}
            update={externalUpdates?.[0]}
            resolveExternalUpdate={resolveExternalUpdate}
            closeModal={closeModal}
          />
        ),
      });
    } else {
      warnings.push({
        warning: (
          <InvoiceChanges
            update={{
              updateData: externalUpdates?.[0]?.externalUpdate?.update_data,
              invoicingSchedule: externalUpdates?.[0]?.targetObject,
            }}
            externalUpdate={omit(externalUpdates?.[0]?.externalUpdate, 'update_data')}
            resolveExternalUpdate={resolveExternalUpdate}
            onClose={closeModal}
          />
        ),
      });
    }
  }

  return warnings?.filter((warning) => {
    if (warning?.dismissKey) {
      return !dismissedWarnings?.includes(warning?.dismissKey);
    } else return true;
  });
};
