export const METRIC_ROWS_DATA = [
  {
    title: 'ARR',
    key: 'recurring_revenue',
  },
  {
    title: 'NRR',
    key: 'net_revenue_retention_ttm',
  },
  {
    title: 'New',
    key: 'New',
  },
  {
    title: 'Upsell',
    key: 'Upsell',
  },
  {
    title: 'Downsell',
    key: 'Downsell',
  },
  {
    title: 'In Flux and/or Churn',
    key: 'Churn',
  },
  {
    title: 'Total # of Customers',
    key: 'customers',
  },
  {
    title: 'ARPA',
    key: 'arpa_ttm',
  },
];

//value format is a cron job date string format https://cloud.google.com/scheduler/docs/configuring/cron-job-schedules
// 0 and 7 in cron is a sunday
export const WEEK_PERIOD_DAY_OPTIONS = [
  { value: 1, label: 'Monday' },
  { value: 2, label: 'Tuesday' },
  { value: 3, label: 'Wednesday' },
  { value: 4, label: 'Thursday' },
  { value: 5, label: 'Friday' },
  { value: 6, label: 'Saturday' },
  { value: 7, label: 'Sunday' },
];

export const EMAIL_DEFAULT_FREQUENCY = {
  period: 'Week',
  day: 1, // 0 and 7 in cron is a sunday
  time: 7,
  timezone: 'America/Los_Angeles',
};

export const MONTH_PERIOD_DAY_OPTIONS = [
  { value: 'first_day', label: 'First day of the month' },
  { value: 'first_monday', label: 'First Monday of the month' },
  { value: 'last_day', label: 'Last day of the month' },
];

export const TIME_OPTIONS = [
  { value: 0, label: '12:00 AM' },
  { value: 1, label: '01:00 AM' },
  { value: 2, label: '02:00 AM' },
  { value: 3, label: '03:00 AM' },
  { value: 4, label: '04:00 AM' },
  { value: 5, label: '05:00 AM' },
  { value: 6, label: '06:00 AM' },
  { value: 7, label: '07:00 AM' },
  { value: 8, label: '08:00 AM' },
  { value: 9, label: '09:00 AM' },
  { value: 10, label: '10:00 AM' },
  { value: 11, label: '11:00 AM' },
  { value: 12, label: '12:00 PM' },
  { value: 13, label: '01:00 PM' },
  { value: 14, label: '02:00 PM' },
  { value: 15, label: '03:00 PM' },
  { value: 16, label: '04:00 PM' },
  { value: 17, label: '05:00 PM' },
  { value: 18, label: '06:00 PM' },
  { value: 19, label: '07:00 PM' },
  { value: 20, label: '08:00 PM' },
  { value: 21, label: '09:00 PM' },
  { value: 22, label: '10:00 PM' },
  { value: 23, label: '11:00 PM' },
];

export const PERIOD_OPTIONS = {
  WEEK: 'Week',
  DAY: 'Day',
  MONTH: 'Month',
};
