import React, { useCallback, useContext, useState } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { AppContext } from 'AppContext';
import { useForecastDefaultsAPI } from 'api/forecasts';
import { EVENTS } from 'consts/analytics';
import { useAnalytics } from 'utils/hooks';
import { Modal, ModalCloseIcon, ModalContainer, ModalHeader, ModalTitle, ModalTitleText } from 'components/Modal';
import { SizedLoader } from 'components/Loaders';
import { Centerer } from 'components/Core';
import { FORECAST_SCENARIO_TYPE } from './consts';
import { ForecastingContext } from '../ForecastingContext';
import { ForecastModalForm } from './ForecastModalForm';

dayjs.extend(utc);

const LoaderContainer = styled(Centerer)`
  padding: 40px;
`;

const CreateForecastModal = ({ onClose, onForecastCreated, scenarioType }) => {
  const { orgId } = useContext(AppContext);
  const { data, outdatedCount, addForecast } = useContext(ForecastingContext);
  const { data: defaultsData, isLoading: isLoadingDefaults } = useForecastDefaultsAPI({
    orgId,
    scenarioType,
  });

  const [isLoading, setIsLoading] = useState(false);

  const handleSaveForecast = async (data) => {
    if (!isLoading) {
      setIsLoading(true);
      await addForecast({ data });
      onClose();
      onForecastCreated?.(data);
    }
  };

  return (
    <ModalContainer data-cy="create-forecast-modal">
      <Modal width="740px" height="auto" overflow="visible" compact>
        <ModalCloseIcon onClose={onClose} />
        <ModalHeader>
          <ModalTitle>
            <ModalTitleText>
              {scenarioType === FORECAST_SCENARIO_TYPE.WEIGHTED ? 'Create new scenario' : 'Create Unweighted Scenario'}
            </ModalTitleText>
          </ModalTitle>
        </ModalHeader>
        {isLoading || isLoadingDefaults ? (
          <LoaderContainer>
            <SizedLoader size={40} />
          </LoaderContainer>
        ) : (
          <ForecastModalForm
            name="create-forecast-modal"
            scenarioType={scenarioType}
            initialValues={{
              name: `Scenario #${(data?.length ?? 0) + outdatedCount + 1}`,
              start_date: dayjs.utc().startOf('month'),
              end_date: dayjs.utc().add(11, 'month').startOf('month'),
              ...defaultsData,
            }}
            onSubmit={handleSaveForecast}
            onClose={onClose}
          />
        )}
      </Modal>
    </ModalContainer>
  );
};

export const useCreateForecastModal = (props) => {
  const { trackEvent } = useAnalytics();
  const [showModal, setShowModal] = useState(false);
  const [scenarioType, setScenarioType] = useState();

  const openModal = useCallback(
    (forecastScenarioType = FORECAST_SCENARIO_TYPE.WEIGHTED) => {
      trackEvent({
        name: EVENTS.OPEN_CREATE_FORECAST_SCENARIO,
        properties: {
          scenario_type: forecastScenarioType,
        },
      });
      setScenarioType(forecastScenarioType);
      setShowModal(true);
    },
    [trackEvent, setShowModal, setScenarioType],
  );

  const Modal = useCallback(
    () =>
      showModal ? (
        <CreateForecastModal {...props} onClose={() => setShowModal(false)} scenarioType={scenarioType} />
      ) : null,
    // otherwise onForecastCreated was making the modal rerender -> Open/Close flash
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [showModal, setShowModal, scenarioType],
  );

  return {
    openModal,
    Modal,
  };
};
