import { getBilledAmount } from '../utils';

export const getInvoicesWithRemovedTransactionLineItem = ({ update, deleteTransactions }) => {
  const targetObject = update?.targetObject;

  const deletedTransaction = deleteTransactions?.[0];

  return targetObject?.invoices?.reduce((result, invoice) => {
    if (invoice?.invoice_items?.some((item) => item?.transaction_id === deletedTransaction?.id)) {
      result.push(invoice);
    }
    return result;
  }, []);
};

export const getTotalAndSendPlusPaidTransactionInvoicesAmounts = ({
  invoicesWithRemovedTransactionLineItem,
  specificTransactionId,
}) => {
  const totalInvoicesAmount = getBilledAmount({
    invoices: invoicesWithRemovedTransactionLineItem,
    specificTransactionId,
  });

  const totalSentAndPaidInvoicesAmount = getBilledAmount({
    invoices: invoicesWithRemovedTransactionLineItem?.filter((invoice) => !!invoice?.paid_at || !!invoice?.sent_at),
    specificTransactionId,
  });

  return {
    totalInvoicesAmount,
    totalSentAndPaidInvoicesAmount,
  };
};

export const getScheduleTransactionsIds = ({ targetObject }) => [
  ...new Set(
    targetObject?.invoices?.flatMap(({ invoice_items }) =>
      invoice_items?.map(({ transaction_id }) => transaction_id).filter(Boolean),
    ),
  ),
];
