export function getFlags() {
  const featureFlags = {
    versionHistory: false,
    MVP: false,
  };

  const flag = (window.location.search || '').replace('?', '');
  const hasMultipleFlags = flag.includes('&');

  if (hasMultipleFlags) {
    flag.split('&').map((flag) => (featureFlags[flag] = true));
    return featureFlags;
  }

  featureFlags[flag] = true;
  return featureFlags;
}
