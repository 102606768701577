import React, { useContext, useMemo, useState } from 'react';
import { Checkbox } from 'antd';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { AppContext } from 'AppContext';
import { getURLWithoutParam, urlParamExists } from 'utils/urlUtils';
import { toSentence } from 'utils/arrayUtils';
import { ReactComponent as SecondaryInfoIcon } from 'images/info-circle-new.svg';
import { ReactComponent as MailIcon } from 'images/share-modal-fancy-icon.svg';
import { ReactComponent as IconCopy } from 'images/new-copy-icon.svg';
import { ReactComponent as FooterIcon } from 'images/link-share-footer-icon.svg';
import { Row, Column, CentererVertical, BlueText } from 'components/Core';
import {
  Modal,
  ModalBody,
  ModalCloseIcon,
  ModalContainer,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTitleText,
} from 'components/Modal';
import { useToasts } from 'components/Toasts';
import { MembersModal } from 'shared/MembersModal';
import {
  SubtitleText,
  CheckboxLabel,
  LinkBlock,
  SubtitleWrapper,
  LinkWithButton,
  ShareLinkButton,
  BlockTitle,
  FooterSubtitle,
} from './LinkShareModal.styles';
import { useClickOutside } from 'utils/hooks';
import { CONFIGURATION_TABS } from 'views/Configuration/consts';

const LINK_FILTERS_PARAM = 's';

const StyledFooterIcon = styled(FooterIcon)`
  cursor: pointer;
`;

export const LinkShareModal = ({ onClose, headerTitle, additionalQuery }) => {
  const { organizations } = useContext(AppContext);
  const { pushToast } = useToasts();
  const linkFiltersExist = urlParamExists({ param: LINK_FILTERS_PARAM });
  const [showMembersModal, setShowMembersModal] = useState(false);
  const [showLinkFilters, setShowLinkFilters] = useState(linkFiltersExist);
  const toggleShowLinkFilters = () => setShowLinkFilters(!showLinkFilters);
  const modalRef = useClickOutside(() => onClose());

  const history = useHistory();

  const url = useMemo(() => {
    const link = showLinkFilters
      ? window.location.href
      : getURLWithoutParam({
          param: LINK_FILTERS_PARAM,
        });
    const searchParams = new URLSearchParams({
      origin: 'link-share',
      ...additionalQuery,
    });

    return additionalQuery ? (showLinkFilters ? `${link}&${searchParams}` : `${link}?${searchParams}`) : link;
  }, [showLinkFilters, additionalQuery]);
  const handleCopy = () => {
    navigator.clipboard.writeText(url);
    pushToast('Copied to clipboard successfully', 'success');
    onClose();
  };

  return (
    <>
      <ModalContainer data-cy="link-share-modal">
        <Modal ref={modalRef} overflow="visible" height="auto" width="640px">
          <ModalHeader>
            <ModalCloseIcon onClose={onClose} />
            <ModalTitle compact padding="0 36px 0 36px">
              <ModalTitleText compact>
                <b>Share {headerTitle}</b>
                <SubtitleWrapper>
                  <SecondaryInfoIcon />
                  <SubtitleText>
                    You can share Subscript links to other{' '}
                    <BlueText
                      data-cy="link-share-modal__members-modal-opener"
                      onClick={() => setShowMembersModal(true)}
                    >
                      members of your organization
                    </BlueText>
                    . Users who sign up with the email domain {toSentence(organizations[0]?.email_domains ?? [])}{' '}
                    automatically become members of the organization.
                    <p>
                      <b>All your data will be safe,</b> because the user should authorize in Subscript.
                    </p>
                  </SubtitleText>
                </SubtitleWrapper>
              </ModalTitleText>
            </ModalTitle>
          </ModalHeader>

          <ModalBody paddingLeft="36px" paddingRight="36px">
            <LinkBlock>
              <BlockTitle>
                Link settings:
                <CentererVertical>
                  <Checkbox checked={showLinkFilters} onClick={toggleShowLinkFilters} disabled={!linkFiltersExist} />
                  <CheckboxLabel>Link with your filters and settings</CheckboxLabel>
                </CentererVertical>
              </BlockTitle>

              <LinkWithButton>
                <a href={url} rel="noreferrer" target="_blank">
                  {url?.length > 55 ? `${url.substring(0, 55)}...` : url}
                </a>

                <ShareLinkButton onClick={handleCopy} data-cy="link-share-modal__close-button">
                  <IconCopy />
                  Copy Link
                </ShareLinkButton>
              </LinkWithButton>
            </LinkBlock>
          </ModalBody>

          <ModalFooter noFixedHeight padding="24px 36px">
            <Row horizontal="flex-start">
              <StyledFooterIcon
                onClick={() => {
                  history.push(`configure?tab=${CONFIGURATION_TABS.GENERAL_SETTINGS}`);
                }}
              />

              <Column horizontal="start" style={{ marginLeft: 16 }}>
                Subscribe to weekly reports with your metrics!
                <FooterSubtitle>
                  We can send updates of your financial data every Monday via Email or Slack.
                </FooterSubtitle>
              </Column>
            </Row>
          </ModalFooter>
          <MailIcon style={{ position: 'absolute', bottom: -20, right: -16 }} />
        </Modal>
      </ModalContainer>

      {showMembersModal && <MembersModal onClose={() => setShowMembersModal(false)} />}
    </>
  );
};
