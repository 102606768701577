import * as Yup from 'yup';
import { RECOGNITION_TYPES } from 'consts/global';

export const formValidations = () => {
  return Yup.lazy((values) => {
    const isEndDateRequired =
      values.recognition === RECOGNITION_TYPES.linear || values.recognition === RECOGNITION_TYPES.linearNotRecurring;

    return Yup.object().shape({
      amount:
        values.recognition &&
        ![RECOGNITION_TYPES.tillCanceled, RECOGNITION_TYPES.eventRecurring].includes(values.recognition) &&
        Yup.number().required('This transaction needs total amount'),
      recurring_amount:
        values.recognition &&
        values.recognition === RECOGNITION_TYPES.tillCanceled &&
        Yup.number().required('This transaction needs recurring amount'),
      start_date: Yup.date().nullable().required('Subscriptions need a start date and an end date'),
      recognition: Yup.string().nullable().required('This transaction needs a recognition schedule'),
      customer_id: Yup.string().nullable().required('This transaction needs to be associated with a customer'),
      end_date: isEndDateRequired
        ? Yup.date().nullable().required('Subscriptions need a start date and an end date')
        : Yup.date().nullable(),
      include_end_month: Yup.boolean().nullable(),
      currency: Yup.string().nullable(),
    });
  });
};
