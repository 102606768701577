import * as Yup from 'yup';

export const USAGE_EVENTS_TABLE_COLUMN_TITLES_BY_ID = {
  customer_id: 'Customer',
  name: 'Event name',
  product_id: 'Product',
  units: 'Units',
  source: 'Source',
  notes: 'Notes',
  timestamp: 'Created at',
};

export const createUsageEventValidationSchema = Yup.object({
  name: Yup.string().required('Please select an event name').typeError('Please select an event name'),
  customer_id: Yup.number().required('Please select a customer').typeError('Please select a customer'),
  product_id: Yup.number().nullable(),
  source: Yup.string().nullable(),
  units: Yup.number().nullable(),
  notes: Yup.string().nullable(),
  timestamp: Yup.date().nullable(),
});
