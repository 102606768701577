import { useContext, useState } from 'react';

import { ReactComponent as QuestionIcon } from 'images/related-help.svg';
import { capitalize } from 'utils/stringUtils';
import { transactionDisplayTitle } from 'models/transaction';
import { Row, Spacer } from 'components/Core';
import { TooltipContainer, TruncateStringWithTooltip } from 'components/Tooltip';
import { RechargeIcon } from 'components/Icons';
import { TRANSACTION_MODAL_MODE, TRANSACTION_VIEW_MODE } from 'shared/TransactionContent/consts';
import { TransactionContext } from 'shared/TransactionContent/TransactionContext';

import {
  TransactionStatus,
  Heading,
  TransactionName,
  UpdatedPill,
  TransactionStatusContainer,
  StyledEditCircle,
  EditCircleWrapper,
} from './TransactionTitle.styles';
import { EditTransactionNameModal } from './EditTransactionNameModal';

export const TransactionTitle = () => {
  const { currentTransaction, mode, view, transactionFormValues } = useContext(TransactionContext);

  const [editName, setEditName] = useState(false);

  const displayName = transactionDisplayTitle(transactionFormValues?.name ? transactionFormValues : currentTransaction);

  const handleOpenModal = () => {
    setEditName(true);
  };

  const EditName = () => (
    <>
      <EditCircleWrapper onClick={handleOpenModal} data-cy="transaction-modal--edit-name-button">
        <StyledEditCircle />
      </EditCircleWrapper>

      {editName && <EditTransactionNameModal onClose={() => setEditName(false)} value={displayName} />}
    </>
  );

  const renderHeader = () => {
    switch (mode) {
      case TRANSACTION_MODAL_MODE.CREATE:
        return 'New Manual Transaction';
      case TRANSACTION_MODAL_MODE.EXTERNAL_UPDATE:
        return (
          <Row>
            <TooltipContainer
              underline={false}
              width={180}
              tooltipStyles={{
                fontSize: 12,
              }}
              toolTipContent={
                <>
                  The transaction has been updated in {capitalize(currentTransaction?.service ?? '')}. The changed
                  fields have been highlighted, and you can make your own edits before confirming.
                </>
              }
            >
              <UpdatedPill>
                <RechargeIcon width="20px" height="20px" />
                <Spacer width="10px" />
                <span>Updated: </span>
              </UpdatedPill>
            </TooltipContainer>
            <TransactionName data-cy="transaction-modal__transaction-title" onClick={handleOpenModal}>
              <TruncateStringWithTooltip length={30} tooltipWidth={300}>
                {displayName}
              </TruncateStringWithTooltip>
            </TransactionName>
            <EditName />
          </Row>
        );
      default:
        const showTooltip =
          !currentTransaction.confirmed && mode !== TRANSACTION_MODAL_MODE.CREATE && !currentTransaction.archived_at;
        return (
          <Row>
            <TransactionStatusContainer pageView={view === TRANSACTION_VIEW_MODE.PAGE}>
              <TooltipContainer
                isVisible={showTooltip}
                underline={false}
                width={180}
                tooltipStyles={{
                  fontSize: 12,
                }}
                toolTipContent={
                  <>
                    Transactions come into Subscript in <b>unconfirmed</b> status. As you confirm them here, they become
                    part of the analysis that's computed on dashboard, cohorts, and other screens.
                  </>
                }
              >
                <TransactionStatus
                  pageView={view === TRANSACTION_VIEW_MODE.PAGE}
                  isConfirmed={currentTransaction.confirmed}
                  isDeleted={currentTransaction.archived_at}
                >
                  {view === TRANSACTION_VIEW_MODE.PAGE ? (
                    <>
                      <span>Status:</span>
                      {currentTransaction.archived_at
                        ? 'Deleted'
                        : currentTransaction.confirmed
                        ? 'Confirmed'
                        : 'Unconfirmed'}
                      {showTooltip && <QuestionIcon width="14px" height="14px" />}
                    </>
                  ) : (
                    <>
                      {showTooltip && (
                        <>
                          <QuestionIcon width="20px" height="20px" />
                          <Spacer width="10px" />
                        </>
                      )}
                      {currentTransaction.archived_at
                        ? 'Deleted'
                        : currentTransaction.confirmed
                        ? 'Confirmed'
                        : 'Unconfirmed'}
                      :
                      <Spacer width="8px" />
                    </>
                  )}
                </TransactionStatus>
              </TooltipContainer>
            </TransactionStatusContainer>
            <TransactionName
              pageView={view === TRANSACTION_VIEW_MODE.PAGE}
              data-cy="transaction-modal__transaction-title"
              onClick={handleOpenModal}
            >
              <TruncateStringWithTooltip
                length={mode === TRANSACTION_MODAL_MODE.EDIT_BULK || view === TRANSACTION_VIEW_MODE.PAGE ? 20 : 30}
                tooltipWidth={300}
              >
                {displayName}
              </TruncateStringWithTooltip>
            </TransactionName>
            <EditName />
          </Row>
        );
    }
  };

  return <Heading>{renderHeader()}</Heading>;
};
