import React, { useContext, useState } from 'react';
import dayjs from 'dayjs';
import { AppContext } from 'AppContext';
import { useOrganizationMetadataAPI } from 'api/organizations';
import { CustomDatePicker } from 'components/Controls';
import { SelectDropdownButton } from 'components/Buttons';
import { onSelectPeriod, PERIOD_OPTIONS } from 'shared/Filters';
import { CardChartModalContext } from '../CardChartModalContext';
import { NonEnterableDates, SelectorCard } from './styled';

export const DateRangeSelector = () => {
  const { startMonth, setStartMonth, endMonth, setEndMonth } = useContext(CardChartModalContext);
  const { orgId } = useContext(AppContext);
  const [period, setPeriod] = useState(PERIOD_OPTIONS.custom);

  const { data: organizationMetadata } = useOrganizationMetadataAPI({ orgId });
  const { earliestAndLatestSpreadsDates } = organizationMetadata ?? {};

  const handleSelectPeriod = ({ period, startMonth, endMonth }) => {
    setPeriod(period);
    startMonth && setStartMonth(startMonth);
    endMonth && setEndMonth(endMonth);
  };

  return (
    <SelectorCard>
      <span>Period:</span>
      <SelectDropdownButton
        showSelectedDirectly
        name="filters__period"
        options={PERIOD_OPTIONS}
        selected={period}
        onSelect={(period) =>
          handleSelectPeriod(onSelectPeriod({ period: PERIOD_OPTIONS[period], earliestAndLatestSpreadsDates }))
        }
      />
      {period !== PERIOD_OPTIONS.custom ? (
        <NonEnterableDates>
          ({dayjs.utc(`${startMonth.getMonth() + 1}-${startMonth.getFullYear()}`, 'M-YYYY').format('MMM YYYY')} through{' '}
          {dayjs.utc(`${endMonth.getMonth() + 1}-${endMonth.getFullYear()}`, 'M-YYYY').format('MMM YYYY')})
        </NonEnterableDates>
      ) : (
        <>
          <span>from:</span>
          <CustomDatePicker
            name="card-chart-modal__start-date"
            showMonthYearPicker
            dateFormat="MM/yyyy"
            filtersView
            selected={startMonth}
            onChange={setStartMonth}
          />
          <span>to</span>
          <CustomDatePicker
            name="card-chart-modal__end-date"
            showMonthYearPicker
            dateFormat="MM/yyyy"
            filtersView
            selected={endMonth}
            onChange={setEndMonth}
          />
        </>
      )}
    </SelectorCard>
  );
};
