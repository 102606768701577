import React, { useContext, useMemo } from 'react';
import { useIncomeAccountsAPI } from 'api/integrations';
import { AppContext } from 'AppContext';
import { FormikCustomSelector } from 'components/Controls';

export const IncomeAccountSelector = ({ integrationId, name, label, isDisabled, ...props }) => {
  const { orgId } = useContext(AppContext);
  const { data, isLoading, error } = useIncomeAccountsAPI({ orgId, integrationId });

  const incomeAccountsOptions = useMemo(
    () =>
      Object.entries(data ?? {}).map(([id, label]) => ({
        label: `${label} / ${id}`,
        value: id,
      })) ?? [],
    [data],
  );

  return (
    <FormikCustomSelector
      name={name}
      options={incomeAccountsOptions}
      isDisabled={isLoading || error || isDisabled}
      label={label ?? 'Income Account'}
      {...props}
    />
  );
};
