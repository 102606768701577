import React from 'react';
import styled from 'styled-components';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  Modal,
  ModalBodyMargin,
  ModalButton,
  ModalCloseIcon,
  ModalContainer,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTitleText,
} from 'components/Modal';
import { Centerer, Spacer } from 'components/Core';
import { WIDGET_TYPES, WIDGET_TYPES_TITLES } from '../consts';
import { WidgetMetricsForm } from './WidgetMetricsForm';
import { WidgetChartVisualizerForm } from './WidgetChartVisualizerForm';

const ErrorMessage = styled.div`
  color: var(--primaryRed);
`;

export const WidgetModal = ({ widgetType, onClose, onSubmitted, metric, metricOptions, editMode }) => {
  return (
    <ModalContainer data-cy={`${widgetType}-widget-modal`}>
      <Modal height="auto" width="440px">
        <ModalHeader>
          <ModalCloseIcon onClose={onClose} />
          <ModalTitle>
            <ModalTitleText>
              <b>
                {editMode ? 'Edit' : 'Add'} {WIDGET_TYPES_TITLES[widgetType]}
              </b>
            </ModalTitleText>
          </ModalTitle>
        </ModalHeader>
        <Formik
          initialValues={{ ...metric }}
          validationSchema={Yup.object({
            id: Yup.string().required('Please, select a metric'),
            isTTM: Yup.bool(),
            size: Yup.string().required('Please, select a width'),
            selectedMetrics: Yup.array().when('id', {
              is: WIDGET_TYPES.CHART_VISUALIZER,
              then: Yup.array().required('Please, select a metric').min(1, 'Please, select a metric'),
              otherwise: Yup.array().nullable(),
            }),
          })}
          onSubmit={onSubmitted}
        >
          {({ values, setFieldValue, handleSubmit, errors, submitCount }) => (
            <>
              <ModalBodyMargin padding="0 32px">
                {widgetType === WIDGET_TYPES.METRIC ? (
                  <WidgetMetricsForm metricOptions={metricOptions} values={values} setFieldValue={setFieldValue} />
                ) : (
                  <WidgetChartVisualizerForm
                    metricOptions={metricOptions}
                    values={values}
                    setFieldValue={setFieldValue}
                  />
                )}

                {submitCount > 0 && errors && (
                  <Centerer direction="column">
                    {Object.entries(errors).map(([key, error]) => (
                      <ErrorMessage key={key}>{error}</ErrorMessage>
                    ))}
                  </Centerer>
                )}
              </ModalBodyMargin>

              <ModalFooter flexEnd>
                <ModalButton default onClick={onClose}>
                  Cancel
                </ModalButton>
                <Spacer width="14px" />
                <ModalButton primary onClick={handleSubmit} data-cy={`${widgetType}-widget-modal__save-button`}>
                  Save
                </ModalButton>
              </ModalFooter>
            </>
          )}
        </Formik>
      </Modal>
    </ModalContainer>
  );
};
