import { snakeCase } from 'lodash';
import { humanize } from 'utils/stringUtils';
import { FormikCustomInput, FormikCustomSelector } from 'components/Controls';
import { INTEGRATION_SERVICES, NETSUITE_SUPPORTED_COUNTRIES } from 'consts/integrations';
import { getServiceCategory } from 'models/integration';

export const CustomersEditCountryField = ({ value, setFieldValue, fieldName, service, isDisabled }) => {
  const invoicingService = getServiceCategory(service);
  return invoicingService === INTEGRATION_SERVICES.NETSUITE ? (
    <FormikCustomSelector
      name={fieldName}
      data-cy={`input-${fieldName}`}
      placeholder="Select country..."
      label="Country"
      options={NETSUITE_SUPPORTED_COUNTRIES.map((country) => ({
        value: humanize(snakeCase(country)),
        label: humanize(snakeCase(country)),
      }))}
      handleChange={(val) => {
        setFieldValue(fieldName, val.value);
      }}
      isDisabled={isDisabled}
    />
  ) : (
    <FormikCustomInput
      name={fieldName}
      data-cy={`input-${fieldName}`}
      placeholder="Select country..."
      label="Country"
      value={value}
      handleChange={(val) => {
        setFieldValue(fieldName, val);
      }}
      isDisabled={isDisabled}
    />
  );
};
