import React, { useCallback, useContext, useMemo, useState } from 'react';
import { AppContext } from 'AppContext';
import { SNAPSHOT_METRICS } from 'consts/snapshotMetrics';
import { getAppSetting } from 'models/appSettings';
import { DashboardContext } from 'views/Dashboard/DashboardContext';
import { SNAPSHOT_TABLE_METRICS } from '../consts';
import { BusinessSnapshotSettingsModal } from './BusinessSnapshotSettingsModal';

export const generateSubRow = ({ metrics, isARR, growthType, hasCostAttribution, winbackMonths }) => {
  const subRows = metrics.map((metric) => ({
    ...metric,
    title: metric.label,
    tooltipText: metric.description({ growthType, isARR, hasCostAttribution, winbackMonths }),
  }));

  return subRows;
};

export const useBusinessSnapshotSettingsModal = ({ selectedMetrics, selectedColumns, setSelected }) => {
  const { organizations, isARR } = useContext(AppContext);
  const { growthType } = useContext(DashboardContext);
  const winbackMonths = getAppSetting('winbackMonths');
  const hasCostAttribution = organizations[0]?.hasCostAttribution;
  const [showModal, setShowModal] = useState(false);
  const toggleModal = useCallback(
    ({ selectedColumns } = {}) => {
      if (selectedColumns) {
        setSelected({
          selectedMetrics,
          selectedColumns,
        });
      }
      setShowModal(!showModal);
    },
    [selectedMetrics, setSelected, showModal],
  );

  const rows = useMemo(
    () => [
      {
        title: SNAPSHOT_METRICS.REVENUE.label,
        subRows: generateSubRow({
          metrics: SNAPSHOT_TABLE_METRICS.REVENUE_ROW_METRICS,
          isARR,
          growthType,
          hasCostAttribution,
          winbackMonths,
        }),
      },
      {
        title: SNAPSHOT_METRICS.SAAS.label,
        subRows: generateSubRow({
          metrics: SNAPSHOT_TABLE_METRICS.SAAS_ROW_METRICS,
          isARR,
          growthType,
          hasCostAttribution,
          winbackMonths,
        }),
      },
      {
        title: SNAPSHOT_METRICS.UNIT_ECONOMICS.label,
        subRows: generateSubRow({
          metrics: SNAPSHOT_TABLE_METRICS.UNIT_ECONOMICS_METRICS,
          isARR,
          growthType,
          hasCostAttribution,
          winbackMonths,
        }),
      },
      {
        title: SNAPSHOT_METRICS.EFFICIENCY.label,
        subRows: generateSubRow({
          metrics: SNAPSHOT_TABLE_METRICS.EFFICIENCY_METRICS,
          isARR,
          growthType,
          hasCostAttribution,
          winbackMonths,
        }),
      },
    ],
    [growthType, hasCostAttribution, isARR, winbackMonths],
  );

  const Modal = useCallback(
    () =>
      showModal ? (
        <BusinessSnapshotSettingsModal
          toggleModal={toggleModal}
          rows={rows}
          selectedMetrics={selectedMetrics}
          selectedColumns={selectedColumns}
          setSelected={setSelected}
        />
      ) : (
        <></>
      ),
    [showModal, toggleModal, rows, selectedMetrics, selectedColumns, setSelected],
  );

  return {
    toggleModal,
    showModal,
    Modal,
  };
};
