import React from 'react';
import { ReactComponent as PlusIcon } from 'images/circle-plus.svg';
import { ReactComponent as DeleteSegment } from 'images/segments-trash.svg';
import { Row } from 'components/Core';
import { METADATA_SEGMENT_BUCKET_TYPES } from 'shared/Filters/MetadataFilter/consts';
import {
  AddSegmentButton,
  ItemsHeader,
  HeaderLine,
  HeaderCount,
  HeaderName,
  HeaderValue,
  ItemRow,
  ItemCount,
  ItemName,
  SegmentActions,
  DeleteIconContainer,
} from './styles';
import { FieldBody, FieldTitle } from '../../styles';

const getSegmentsLevelsNamesLabel = ({ levels, segmentType, useZeroBucket }) => {
  let finalLabel = '';
  if (segmentType === METADATA_SEGMENT_BUCKET_TYPES.CONTINUOUS && useZeroBucket) finalLabel += 'Zero, ';
  levels.forEach((level, index) => {
    if (index === levels?.length - 1) {
      finalLabel += String(level.name || level.bucketName);
    } else if (index > 5) {
      return;
    } else {
      finalLabel += `${level.name || level.bucketName}, `;
    }
  });

  return (
    <span>
      {finalLabel}
      {levels.length > 6 ? <b>{`  +${levels.length - 6} more`}</b> : ''}
    </span>
  );
};

export const CustomMetadataSegments = ({
  setShowMetadataSegmentModal,
  setSelectedMetadataSegmentIndex,
  setObjectType,
  crmFields,
  objectType,
  metadataSegments,
  setMetadataSegments,
}) => {
  return (
    <FieldBody data-cy={`wizard__${objectType}-metadata-segments`} style={{ marginBottom: '20px' }}>
      <FieldTitle style={{ marginBottom: 0 }}>
        <span>
          Create and customize your segments using <b>{`${objectType}`}</b> metadata
        </span>
        <AddSegmentButton
          data-cy={`wizard__add-${objectType}-metadata-segment`}
          onClick={() => {
            setShowMetadataSegmentModal(true);
            setSelectedMetadataSegmentIndex(undefined);
            setObjectType(objectType);
          }}
        >
          <PlusIcon /> Segment
        </AddSegmentButton>
      </FieldTitle>

      {/* Custom Metadata segments */}
      {metadataSegments && metadataSegments?.length ? (
        <>
          <ItemsHeader style={{ marginTop: 24 }}>
            <HeaderLine />
            <Row style={{ width: '100%' }} horizontal="space-between">
              <Row>
                <HeaderCount>#</HeaderCount>
                <HeaderName>Name</HeaderName>
              </Row>
              <HeaderValue>Levels</HeaderValue>
            </Row>
          </ItemsHeader>

          {metadataSegments.map((segment, index) => (
            <ItemRow key={`${segment.key}_${index}`}>
              <Row>
                <ItemCount smallPadding={index >= 9}>{index + 1}</ItemCount>
                <ItemName
                  onClick={() => {
                    setShowMetadataSegmentModal(true);
                    setSelectedMetadataSegmentIndex(index);
                    setObjectType(objectType);
                  }}
                >
                  {crmFields?.account?.customFields.find((field) => field.value === segment.key)?.label ?? segment.key}
                </ItemName>
              </Row>

              <SegmentActions>
                {getSegmentsLevelsNamesLabel({
                  levels: segment?.levels,
                  segmentType: segment?.type,
                  useZeroBucket: segment?.useZeroBucket,
                })}
                <DeleteIconContainer>
                  <DeleteSegment
                    data-cy={`wizard__${objectType}-metadata-segments__remove-segment-${segment.key}`}
                    onClick={() =>
                      setMetadataSegments((previousSegments) =>
                        previousSegments.filter((filterSegment) => filterSegment.key !== segment.key),
                      )
                    }
                  />
                </DeleteIconContainer>
              </SegmentActions>
            </ItemRow>
          ))}
        </>
      ) : null}
    </FieldBody>
  );
};
