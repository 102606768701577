import styled from 'styled-components';
import { Centerer, Flexer } from 'components/Core';

export const Wrapper = styled.div`
  padding: 20px 40px;
`;

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background: var(--accentGraySecond);
  margin-bottom: 20px;
`;

export const LinkCard = styled(Centerer)`
  flex-direction: column;
  gap: 8px;
  width: 100%;
  height: 100%;
  padding: 4px;

  text-align: center;
  font-size: 12px;
  font-style: italic;
  font-weight: 700;
  line-height: 16px;

  background: #fff;
  border: 1px solid var(--accentGraySecond);
  box-shadow: 8px 8px 60px 0px var(--primaryBlack4);
  border-radius: 8px;

  span {
    margin-left: 4px;
    color: var(--primaryBlue);
    cursor: pointer;
  }
`;

export const LinkSubtext = styled.div`
  color: var(--primaryBlack50);
  text-align: center;
  font-size: 12px;
  font-style: italic;
  font-weight: 700;
  line-height: 16px;
`;

export const StatusTag = styled(Centerer)`
  height: 16px;
  padding: 4px;
  border-radius: 4px;
  border: 1px solid var(--neutralGray);

  font-size: 9px;
  font-style: normal;
  font-weight: 900;
  line-height: 12px;
  text-transform: uppercase;
  color: var(--primaryBlack50);
`;

const InfoWrapper = styled(Centerer)`
  width: 100%;
  padding: 8px 12px;
  gap: 12px;
  border-radius: 8px;

  font-size: 12px;
  font-style: italic;
  font-weight: 700;
  line-height: 16px;
`;

export const SuccessWrapper = styled(InfoWrapper)`
  background: var(--primaryBlue5);
  span {
    color: var(--primaryBlue);
  }
`;

export const FailureWrapper = styled(InfoWrapper)`
  background: var(--primaryRed5);
  b {
    color: var(--primaryRed);
  }
`;

export const ActionButton = styled(Centerer)`
  padding: 4px 8px;
  gap: 4px;
  border-radius: 100px;
  border: 1px solid var(--primaryBlue10);
  background: var(--primaryBlue5);
  cursor: pointer;
  color: var(--primaryBlue);
  font-size: 12px;
  font-style: italic;
  font-weight: 700;
  line-height: 16px;
`;

export const PaymentLink = styled.a`
  color: var(--primaryBlue);
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  margin: 0 4px;
`;

export const AutoChargeInfo = styled(Flexer)`
  gap: 4px;
  color: var(--primaryBlue);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  cursor: pointer;

  svg {
    width: 16px;
    height: 16px;

    path {
      opacity: 0.5;
      fill: var(--primaryBlack);
    }
  }

  &:hover {
    svg {
      path {
        opacity: 1;
        fill: var(--primaryBlack);
      }
    }
  }
`;

export const InfoTooltipContentText = styled(Flexer)`
  gap: 8px;
  color: white;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  text-align: left;

  svg {
    width: 16px;
    height: 16px;
    fill: none;
    circle {
      stroke: white;
      opacity: 0.23;
    }
  }
`;
