import axios from 'axios';
import { API_URL } from 'api/consts';

export const getContracts = async ({ orgId, params }) => {
  const response = await axios.get(`${API_URL}/organizations/${orgId}/contracts`, { params });
  return response.data;
};

export const getContractById = async ({ orgId, contractId, params }) => {
  const response = await axios.get(`${API_URL}/organizations/${orgId}/contracts/${contractId}`, { params });
  return response.data;
};

export const createContract = async ({ orgId, body }) => {
  const response = await axios.post(`${API_URL}/organizations/${orgId}/contracts`, body);
  return response.data;
};

export const bulkCreateContracts = async ({ orgId, body }) => {
  const response = await axios.post(`${API_URL}/organizations/${orgId}/contracts/bulk`, body);
  return response.data;
};

export const updateContract = async ({ orgId, contractId, body }) => {
  const response = await axios.patch(`${API_URL}/organizations/${orgId}/contracts/${contractId}`, body);
  return response.data;
};

export const addContractAttachment = async ({ orgId, contractId, body }) => {
  const response = await axios.put(`${API_URL}/organizations/${orgId}/contracts/${contractId}/attachment`, body);
  return response.data;
};

export const removeContractAttachment = async ({ orgId, contractId, attachmentS3Key }) => {
  const response = await axios.delete(
    `${API_URL}/organizations/${orgId}/contracts/${contractId}/attachment/${attachmentS3Key}`,
  );
  return response.data;
};

export const deleteContract = async ({ orgId, contractId, archiveTransactions = false }) => {
  const response = await axios.delete(`${API_URL}/organizations/${orgId}/contracts/${contractId}`, {
    params: { archiveTransactions },
  });
  return response.data;
};

export const bulkDeleteContracts = async ({ orgId, data, archiveTransactions = false }) => {
  const url = `${API_URL}/organizations/${orgId}/contracts/bulk-delete`;
  const { data: response } = await axios.post(url, data, { params: { archiveTransactions } });
  return response;
};

export const interpretContract = async ({ orgId, contractId }) => {
  const response = await axios.post(`${API_URL}/organizations/${orgId}/contracts/${contractId}/interpret`);
  return response.data;
};

export const generateFromContract = async ({ orgId, contractId, body }) => {
  const response = await axios.post(`${API_URL}/organizations/${orgId}/contracts/${contractId}/generate`, body);
  return response.data;
};

export const addTransactionsToContract = async ({ orgId, contractId, transactionIds }) => {
  const response = await axios.post(`${API_URL}/organizations/${orgId}/contracts/${contractId}/add-transactions`, {
    transactionIds,
  });
  return response.data;
};

export const removeTransactionFromContract = async ({ orgId, contractId, transactionId }) => {
  const response = await axios.delete(
    `${API_URL}/organizations/${orgId}/contracts/${contractId}/remove-transaction/${transactionId}`,
    {
      transactionId,
    },
  );
  return response.data;
};

export const updateContractAllocations = async ({ orgId, contractId, body }) => {
  const response = await axios.post(
    `${API_URL}/organizations/${orgId}/contracts/${contractId}/update-allocations`,
    body,
  );
  return response.data;
};

export const getDefaultContractAllocations = async ({ orgId, contractId }) => {
  const response = await axios.get(`${API_URL}/organizations/${orgId}/contracts/${contractId}/default-allocations`);
  return response.data;
};
