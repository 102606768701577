import { MAX_POLLING_COUNT, POLLING_DURATION, PROGRESS_POLLING_COUNT } from 'consts/global';
import { delay } from 'utils';

export const pollForCompletion = async ({ pollFn, onSuccess, onProgress, onFailed, onMaxAttempts }) => {
  let attemptCount = 0;
  while (attemptCount < MAX_POLLING_COUNT) {
    const { state, data } = await pollFn();
    if (state === 'failed') {
      await onFailed?.();
      return;
    } else if (state === 'completed') {
      await onSuccess?.();
      return data;
    }

    if (attemptCount === PROGRESS_POLLING_COUNT) onProgress?.();
    await delay(POLLING_DURATION);
    attemptCount++;
  }
  await onMaxAttempts?.();
};
