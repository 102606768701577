import { useContext } from 'react';
import dayjs from 'dayjs';
import { Form, Formik } from 'formik';
import { AppContext } from 'AppContext';
import { Centerer, FlexBetweenContainer } from 'components/Core';
import { NUMBER_FORMATS } from 'consts/global';
import { numberFormatter } from 'utils/formatters';
import { CustomizableMetric } from '../Shared/CustomizableMetric';
import { SectionItemCard, SectionItemCardSecondaryText } from './styles';

export const NewPipelineToBeClosedCard = ({ editForecastDetails, forecastId, month, values }) => {
  const {
    appSettings: { isARR, currencyISOCode: currency },
  } = useContext(AppContext);
  const handleSubmit = (values) => {
    editForecastDetails({
      forecastId,
      forecastMonth: month,
      forecastType: 'customers_new',
      customerId: 'new_pipeline',
      data: {
        recurring_amount: values.recurring_amount / (isARR ? 12 : 1),
        probability: values.probability / 100,
      },
    });
  };

  return (
    <Formik
      initialValues={{
        recurring_amount: values.recurring_amount,
        probability: values.probability * 100,
        forecast_month: values.forecast_month,
      }}
      onSubmit={handleSubmit}
      enableReinitialize={true}
    >
      <Form>
        <SectionItemCard>
          <FlexBetweenContainer centerer>
            <div>In {dayjs(`${month}-15`).format('MMM YYYY')}</div>
            <Centerer gap="4px">
              <CustomizableMetric
                name="recurring_amount"
                dataType={NUMBER_FORMATS.CURRENCY}
                value={values.recurring_amount ?? 0}
              />
              x
              <CustomizableMetric
                name="probability"
                dataType={NUMBER_FORMATS.PERCENT}
                value={values.probability}
                explanation="The likelihood that this deal will close"
              />
              ={' '}
              <SectionItemCardSecondaryText>
                {numberFormatter({ type: NUMBER_FORMATS.CURRENCY, rawValue: values.forecasted_amount, currency })}
              </SectionItemCardSecondaryText>
            </Centerer>
          </FlexBetweenContainer>
        </SectionItemCard>
      </Form>
    </Formik>
  );
};
