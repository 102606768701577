import { joinNonEmptyStrings } from 'utils/stringUtils';

export const formatAddress = (object) => {
  if (!object || Object.keys(object).length === 0) return '';
  return joinNonEmptyStrings(
    [
      object.addressLine1,
      object.addressLine2,
      object.city,
      joinNonEmptyStrings([object.state, object.postalcode], ' '),
      object.country,
    ],
    ', ',
  );
};
