import { cloneDeep } from 'lodash';
import { REVENUE_METRIC } from 'consts/revenueMetrics';

const DONT_CONVERT_TO_ARR = [REVENUE_METRIC.NON_RECURRING.key];

export const convertToARR = (data) => {
  if (!data || Object.keys(data).length === 0) {
    return {};
  }

  const result = cloneDeep(data);

  // update historical data
  for (const historicalData of Object.values(result.historical_data)) {
    const specificMonthData = historicalData.revenue;
    for (const metric of Object.keys(specificMonthData)) {
      if (!DONT_CONVERT_TO_ARR.includes(metric)) {
        specificMonthData[metric] *= 12;
      }
    }
  }

  // update recuring totals
  result.last_recurring_total *= 12;
  result.total_recurring_revenue *= 12;

  // update transaction_data
  for (const metric of Object.keys(result.transaction_data)) {
    const customers = result.transaction_data[metric];
    for (const customer of Object.values(customers)) {
      if (!DONT_CONVERT_TO_ARR.includes(metric)) {
        customer.metricValue *= 12;
        customer.existingRR *= 12;
        customer.currentRR *= 12;
        customer.previousRR *= 12;
        for (const spread of customer.metricSpreads) {
          spread.spread_amount *= 12;
        }
        for (const spread of customer.previousSpreads) {
          spread.spread_amount *= 12;
        }
      }
    }
  }

  return result;
};
