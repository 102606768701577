import React, { useContext, useMemo, useState } from 'react';
import { isEmpty } from 'lodash';
import { AppContext } from 'AppContext';
import { TableContainerLayout } from 'views/Billing/Common/TableContainerLayout';
import { InvoicesTable, groupInvoicesByMonth } from 'views/Billing/InvoicingTables';
import { useInvoicingScheduleModal } from 'views/Billing/InvoicingScheduleModal';
import { useInvoicesAPI } from 'api/billing';
import { INVOICES_DEFAULT_SORT_BY, INVOICES_SORT_BY_COLUMNS_NAME_MAPPING } from 'views/Billing/InvoicingTables/consts';
import { useStateWithStorage } from 'utils/hooks';
import { sortByToKnexOrderBy } from 'utils/tableUtils';

const DEFAULT_PAGE_SIZE = 10;

export const CustomerInvoicesTable = ({ customer }) => {
  const {
    orgId,
    appSettings: { currencyISOCode: currency },
  } = useContext(AppContext);

  const [storageSortBy, setStorageSortBy] = useStateWithStorage('customer-invoices-page-sort-by');
  const [currentPageIndex, setCurrentPageIndex] = useState(1);

  const {
    data: { data: invoices, metadata },
    isFetching: isFetchingInvoices,
  } = useInvoicesAPI({
    orgId,
    includeReminders: true,
    orderBy: isEmpty(storageSortBy)
      ? INVOICES_DEFAULT_SORT_BY
      : sortByToKnexOrderBy(storageSortBy, INVOICES_SORT_BY_COLUMNS_NAME_MAPPING),
    customerId: customer.id,
    includeCount: true,
    includeChildren: true,
    page: currentPageIndex,
    limit: DEFAULT_PAGE_SIZE,
  });

  const { Modal: InvoicingScheduleModal, openModal: openInvoicingScheduleModal } = useInvoicingScheduleModal();
  const openInvoiceModal = (invoice) =>
    openInvoicingScheduleModal({ invoicingSchedule: { id: invoice?.invoicing_schedule_id }, invoice });

  const dataForTable = useMemo(() => (invoices ? groupInvoicesByMonth({ invoices }) : []), [invoices]);

  return (
    <>
      <TableContainerLayout
        isFetching={isFetchingInvoices}
        rowsCount={invoices?.length}
        currentPageIndex={currentPageIndex}
        setCurrentPageIndex={setCurrentPageIndex}
        pageCount={metadata?.maxPage ?? 1}
      >
        <InvoicesTable
          initialSortBy={storageSortBy}
          setSortBy={setStorageSortBy}
          currency={currency}
          isFetchingInvoices={isFetchingInvoices}
          dataForTable={dataForTable}
          onInvoiceClick={openInvoiceModal}
        />
      </TableContainerLayout>
      <InvoicingScheduleModal />
    </>
  );
};
