import React from 'react';
import styled from 'styled-components';
import { CentererVertical, Flexer, Spacer } from 'components/Core';
import { ExternalLinkFullIcon } from 'components/Icons';
import { ReactComponent as CornerArrowIcon } from 'images/corner-down-right-icon.svg';

const Row = styled(CentererVertical)`
  position: sticky;
  left: 0px;
  gap: 16px;
  margin-top: 16px;
  margin-bottom: 16px;
`;

const CustomerLink = styled(CentererVertical)`
  cursor: pointer;
  gap: 8px;
`;

const CustomerName = styled.span`
  color: var(--primaryBlue);
  font-size: 12px;
  font-weight: 700;
`;

const Subtext = styled.span`
  color: ${({ isChildCustomer }) => (isChildCustomer ? 'var(--primaryBlack)' : 'var(--primaryBlack50)')};
  font-size: 12px;
  font-weight: 700;
`;

export const CustomerRow = ({ customerName, customerId, isChildCustomer = false }) => (
  <Flexer>
    <Row>
      <Spacer width="8px" />
      {isChildCustomer && <CornerArrowIcon />}
      <CustomerLink onClick={() => window.open(`/customers/${customerId}`, '_blank')}>
        <CustomerName>{customerName}</CustomerName>
        <ExternalLinkFullIcon fill="var(--primaryBlue)" />
      </CustomerLink>
      <Subtext>{`${isChildCustomer ? 'Child' : 'This'} customer`}</Subtext>
    </Row>
  </Flexer>
);
