import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { usePortal } from '../Portal';
import { cssVar, transparentize } from 'polished';

const IconContainer = styled.div`
  width: ${(props) => props.width ?? '36px'};
  height: ${(props) => props.height ?? '36px'};
  border-radius: 100px;
  background: ${(props) => props.backgroundColor ?? (props.active ? 'var(--secondaryGreen)' : 'var(--primaryGreen)')};
  background: ${(props) => (props.disabled ? 'var(--primaryBlack10)' : undefined)};

  border: ${({ border }) => (border ? '1px solid var(--accentGraySecond)' : '')};
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    path {
      fill: ${(props) => props.iconFillColor ?? '#FFF'};
    }
  }

  &:hover {
    background: ${(props) => props.backgroundColor ?? 'var(--secondaryGreen)'};
    box-shadow: ${({ border }) => (border ? `4px 4px 24px ${transparentize(0.96, cssVar('--primaryBlack'))}` : '')};
  }
`;

const Container = styled.span`
  display: flex;
  align-items: center;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : undefined)};

  cursor: pointer;

  border-radius: 100px;
`;

const HoverContainer = styled.div`
  padding: 4px 6px;
  background: #ffffff;

  box-shadow: 0px 4px 20px var(--primaryBlack10);
  border-radius: 3px;
`;

export const CircleButton = ({
  onClick,
  icon,
  iconFillColor,
  backgroundColor,
  border,
  hoverText,
  XOffset,
  YOffset,
  disabled,
  ...rest
}) => {
  const { triggerRef, togglePortal, isPortalVisible, Portal } = usePortal({
    XOffset,
    YOffset,
  });

  return (
    <>
      <Container
        onClick={onClick}
        ref={triggerRef}
        onMouseEnter={togglePortal}
        onMouseLeave={togglePortal}
        disabled={disabled}
      >
        <IconContainer
          iconFillColor={iconFillColor}
          backgroundColor={backgroundColor}
          border={border}
          disabled={disabled}
          {...rest}
        >
          {icon}
        </IconContainer>
      </Container>
      {isPortalVisible && hoverText && (
        <Portal>
          <HoverContainer>{hoverText}</HoverContainer>
        </Portal>
      )}
    </>
  );
};

CircleButton.propTypes = {
  /**
   * Icon
   */
  icon: PropTypes.element,
  /**
   * Optional Icon color
   */
  iconFillColor: PropTypes.string,
  /**
   * Optional Background color
   */
  backgroundColor: PropTypes.string,
  /**
   * Optional with border
   */
  border: PropTypes.bool,
  /**
   * Optional Hover Text
   */
  hoverText: PropTypes.string,
  /**
   * Optional click handler
   */
  onClick: PropTypes.func,
};
