import React, { useContext } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { isNil } from 'lodash';
import { NUMBER_FORMATS } from 'consts/global';

import { ReactComponent as MoneyIcon } from 'images/money.svg';
import { ReactComponent as DateIcon } from 'images/customer-date.svg';
import { ReactComponent as BellIcon } from 'images/bell-ringing.svg';
import { ReactComponent as WalletIcon } from 'images/wallet-1.svg';

import { numberFormatter } from 'utils/formatters';
import { getPercentDifference } from 'views/Customers/CustomersList/utils';
import {
  Divider,
  ToolTipContentContainer,
  ToolTipLabel,
  ToolTipRow,
  ToolTipValue,
  PercentChange,
} from 'views/RevenueDetails/styled';
import { TooltipContainer } from 'components/Tooltip';
import { AppContext } from 'AppContext';

import { PercentDice } from '../styles';

const CardsContainer = styled.div`
  display: flex;
  margin-bottom: 44px;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 18px;
  border: 1px solid var(--accentGraySecond);

  &:first-child {
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
  }

  &:last-child {
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
  }
`;

const CardTitle = styled.div`
  font-size: 12px;
  line-height: 16px;
  opacity: 0.5;
  margin-top: 14px;
  margin-bottom: 8px;
`;

const CardData = styled.div`
  font-weight: 900;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 14px;
  display: flex;
  align-items: center;
`;

const CardSubtitle = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: rgba(0, 21, 46, 0.5);
`;

const RenewalDice = styled.div`
  font-size: 12px;
  line-height: 16px;
  padding: 2px 4px;
  background: ${(props) => (props.isPast ? 'var(--primaryYellow15)' : 'var(--primaryBlack5)')};
  font-weight: ${(props) => (props.isPast ? 'bold' : 'normal')};
  color: ${(props) => props.isPast && 'var(--secondaryYellow)'};
  margin-left: 8px;
  border-radius: 4px;
`;

const CustomerInfoCards = ({ customer }) => {
  const { currentMRR, previousMRR, start_date, leftToRecognize, recurringEndDate, transactionSum } = customer;

  const {
    appSettings: { isARR, currencyISOCode: currency },
  } = useContext(AppContext);

  const percentChange = getPercentDifference({ previousMRR, currentMRR });
  const customerEndDate = recurringEndDate ? dayjs.utc(recurringEndDate) : null;
  const renewalDate = customerEndDate ? customerEndDate : null;
  const NOW = dayjs();
  const endDateInThePast = customerEndDate?.isSameOrBefore(NOW, 'month');

  const currentChangeContent = () => {
    const previousARR = previousMRR * 12;
    const currentARR = currentMRR * 12;

    const MRRDifference = currentMRR - previousMRR;
    const ARRDifference = currentARR - previousARR;
    return (
      <ToolTipContentContainer>
        <ToolTipRow>
          <ToolTipLabel>{dayjs(start_date).format('YYYY')}:</ToolTipLabel>
          <ToolTipValue>
            {numberFormatter({
              type: NUMBER_FORMATS.CURRENCY,
              rawValue: Math.round(isARR ? previousARR : previousMRR),
              currency,
            })}
          </ToolTipValue>
        </ToolTipRow>
        <ToolTipRow>
          <ToolTipLabel>{NOW.format('YYYY')}:</ToolTipLabel>
          <ToolTipValue>
            {numberFormatter({
              type: NUMBER_FORMATS.CURRENCY,
              rawValue: Math.round(isARR ? currentARR : currentMRR),
              currency,
            })}
          </ToolTipValue>
        </ToolTipRow>
        <Divider backgroundColor="#262626" />
        <ToolTipRow>
          <ToolTipLabel>Current change:</ToolTipLabel>
          <ToolTipValue>
            <span style={{ paddingRight: 4 }}>
              {numberFormatter({
                type: NUMBER_FORMATS.CURRENCY,
                rawValue: Math.round(isARR ? ARRDifference : MRRDifference),
                currency,
              })}
            </span>
            {Boolean(percentChange) && (
              <PercentChange
                backgroundColor={percentChange >= 0 ? 'var(--secondaryGreen)' : 'var(--secondaryRed)'}
                color={'white'}
              >
                {percentChange > 0 ? `+${percentChange}` : percentChange}%
              </PercentChange>
            )}
          </ToolTipValue>
        </ToolTipRow>
      </ToolTipContentContainer>
    );
  };

  return (
    <CardsContainer>
      <Card>
        <MoneyIcon />
        <CardTitle>Current {isARR ? 'ARR' : 'MRR'}</CardTitle>
        <CardData data-cy="customers-page__info-cards__recurring-revenue-summary">
          {isNil(currentMRR)
            ? 'Loading...'
            : numberFormatter({
                type: NUMBER_FORMATS.CURRENCY,
                rawValue: isARR ? currentMRR * 12 : currentMRR,
                currency,
              })}
          {Boolean(percentChange) && (
            <PercentDice isPositive={percentChange > 0}>
              {percentChange > 0 ? `+${percentChange}` : percentChange}%
            </PercentDice>
          )}
        </CardData>

        {start_date && (
          <CardSubtitle>
            <TooltipContainer width={250} isVisible={true} underline={false} toolTipContent={currentChangeContent()}>
              {dayjs(start_date).format('YYYY')} {isARR ? 'ARR' : 'MRR'}:
              <span style={{ color: 'var(--primaryBlack)', marginLeft: 6 }}>
                {numberFormatter({
                  type: NUMBER_FORMATS.CURRENCY,
                  rawValue: isARR ? previousMRR * 12 : previousMRR,
                  currency,
                })}
              </span>
            </TooltipContainer>
          </CardSubtitle>
        )}
      </Card>

      <Card>
        <DateIcon />
        <CardTitle>Renewal</CardTitle>
        <CardData>
          {customerEndDate && endDateInThePast && <BellIcon style={{ marginRight: 8 }} />}

          {renewalDate ? renewalDate.format('MMM YYYY') : 'None'}

          {renewalDate && (
            <RenewalDice isPast={endDateInThePast}>
              {endDateInThePast ? 'up for renewal' : `in ${renewalDate.diff(NOW, 'month')} months`}
            </RenewalDice>
          )}
        </CardData>

        <CardSubtitle>
          Customer since:
          <span style={{ color: 'var(--primaryBlack)', marginLeft: 6 }}>
            {start_date ? dayjs.utc(start_date).format('MMM YYYY') : 'No start date'}
          </span>
        </CardSubtitle>
      </Card>

      <Card>
        <WalletIcon />
        <CardTitle>Total revenue</CardTitle>

        <CardData>
          {isNil(transactionSum)
            ? 'Loading...'
            : numberFormatter({ type: NUMBER_FORMATS.CURRENCY, rawValue: transactionSum, currency })}
        </CardData>

        <CardSubtitle>
          Left to recognize:
          <span style={{ color: 'var(--primaryBlack)', marginLeft: 6 }}>
            {isNil(leftToRecognize)
              ? 'Loading...'
              : numberFormatter({ type: NUMBER_FORMATS.CURRENCY, rawValue: leftToRecognize, currency })}
          </span>
        </CardSubtitle>
      </Card>
    </CardsContainer>
  );
};

export { CustomerInfoCards };
