import React, { useState } from 'react';
import styled from 'styled-components';
import { useToasts } from 'components/Toasts';
import { ReactComponent as QuestionIcon } from 'images/help-table-header.svg';
import { Flexer } from 'components/Core';
import { Popover, usePortal } from 'components/Portal';
import { ChevronRight, CircleXIcon } from 'components/Icons';

export const StyledDynamicFieldsTooltip = styled(Flexer)`
  width: 100%;
  align-items: center;
  justify-content: ${({ centerContent }) => (centerContent ? 'center' : 'flex-end')};
  gap: 4px;
  opacity: 1;
  color: var(--primaryBlack);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  white-space: nowrap;
  cursor: pointer;

  span {
    color: #fff;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    padding: 1px 4px;
    border-radius: 4px;
    background: var(--dark7);
  }

  svg {
    margin: 0px;
    width: 16px;
    height: 16px;

    g {
      opacity: ${({ active }) => (active ? 1 : 0.5)};
      path {
        fill: var(--primaryBlack);
      }
    }
  }

  &:hover {
    svg g path {
      fill: var(--primaryBlack);
    }
  }
`;

const PopoverContainer = styled(Flexer)`
  flex-direction: column;
  gap: 8px;
  padding: 12px;
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;

  svg {
    cursor: pointer;
  }
`;

const Tag = styled.div`
  padding: 1px 4px;
  border-radius: 4px;
  margin-bottom: 4px;
  background: var(--dark20);

  color: #fff;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  cursor: pointer;
`;

const TagsContainer = styled(Flexer)`
  gap: 4px;
  flex-wrap: wrap;
`;

const ArrowWrapper = styled.div`
  padding: 2px;
  display: flex;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  background-color: var(--primaryBlack50);
  margin-right: 8px;

  svg {
    transition: transform 0.3s ease;
    margin-right: 0;

    path {
      fill: white;
    }
  }
`;

const SectionToggle = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 6px;
  cursor: pointer;
`;

const Title = styled.div`
  font-size: 12px;
  color: white;
  font-weight: 700;
  line-height: 16px;

  span {
    color: rgba(255, 255, 255, 0.5);
    font-style: italic;
  }
`;

export const DYNAMIC_FIELD_TOOLTIP_SECTIONS = {
  GENERAL_TAGS: 'generalTags',
  DESCRIPTION_TAGS: 'descriptionTags',
  TRANSACTION_METADATA_TAGS: 'transactionMetadataTags',
  CUSTOMER_METADATA_TAGS: 'customerMetadataTags',
  TRANSACTION_CUSTOMER_METADATA_TAGS: 'transactionCustomerMetadataTags',
  GENERATE_NEW_PAYMENT_LINK_EMAIL_TAGS: 'generateNewPaymentLinkEmailTags',
};

const TITLE_BY_SECTION = {
  [DYNAMIC_FIELD_TOOLTIP_SECTIONS.GENERAL_TAGS]: (
    <>
      Dynamic fields for emails (you can use them in <span>Email Subject</span>, <span>Email Body</span>, and{' '}
      <span>Memo</span>)
    </>
  ),
  [DYNAMIC_FIELD_TOOLTIP_SECTIONS.DESCRIPTION_TAGS]: 'Dynamic fields for invoice item description',
  [DYNAMIC_FIELD_TOOLTIP_SECTIONS.TRANSACTION_METADATA_TAGS]: 'Transaction metadata tags',
  [DYNAMIC_FIELD_TOOLTIP_SECTIONS.CUSTOMER_METADATA_TAGS]: 'Customer metadata tags',
  [DYNAMIC_FIELD_TOOLTIP_SECTIONS.TRANSACTION_CUSTOMER_METADATA_TAGS]:
    'Transaction customer metadata tags (useful for parent-child)',
  [DYNAMIC_FIELD_TOOLTIP_SECTIONS.GENERATE_NEW_PAYMENT_LINK_EMAIL_TAGS]: 'Generate new payment link email tags',
};

export const BillingDynamicFieldsTooltip = ({ tags = {}, XOffset, ...props }) => {
  const { pushToast } = useToasts();

  const [isSectionExpanded, setIsSectionExpanded] = useState({
    [DYNAMIC_FIELD_TOOLTIP_SECTIONS.GENERAL_TAGS]: true,
    [DYNAMIC_FIELD_TOOLTIP_SECTIONS.DESCRIPTION_TAGS]: false,
    [DYNAMIC_FIELD_TOOLTIP_SECTIONS.TRANSACTION_METADATA_TAGS]: false,
    [DYNAMIC_FIELD_TOOLTIP_SECTIONS.CUSTOMER_METADATA_TAGS]: false,
    [DYNAMIC_FIELD_TOOLTIP_SECTIONS.GENERATE_NEW_PAYMENT_LINK_EMAIL_TAGS]: true,
  });

  const { triggerRef, togglePortal, isPortalVisible, hidePortal, Portal } = usePortal({
    XOffset: XOffset ?? 240,
    YOffset: 46,
  });

  const handleTagClick = (tag) => {
    navigator.clipboard.writeText(tag);
    hidePortal();
    pushToast('Copied to clipboard successfully', 'success');
  };

  return (
    <>
      <StyledDynamicFieldsTooltip ref={triggerRef} active={isPortalVisible} onClick={togglePortal} {...props}>
        Type <span>@</span> to use <span>dynamic fields</span>
        <QuestionIcon />
      </StyledDynamicFieldsTooltip>

      {isPortalVisible && (
        <Portal>
          <Popover width="600px" darkMode>
            <PopoverContainer>
              <CircleXIcon
                fill="var(--dark20)"
                style={{ position: 'absolute', right: 8, top: 8 }}
                onClick={hidePortal}
              />

              {Object.values(DYNAMIC_FIELD_TOOLTIP_SECTIONS)?.map((section) => (
                <>
                  {tags?.[section] && (
                    <>
                      <SectionToggle
                        onClick={() => setIsSectionExpanded((prev) => ({ ...prev, [section]: !prev[section] }))}
                      >
                        <ArrowWrapper>
                          <ChevronRight
                            style={{ transform: isSectionExpanded[section] && 'rotate(-90deg)' }}
                            width={16}
                            height={16}
                          />
                        </ArrowWrapper>
                        <Title>{TITLE_BY_SECTION[section]}</Title>
                      </SectionToggle>

                      {isSectionExpanded[section] && (
                        <TagsContainer>
                          {tags[section].map((tag) => (
                            <Tag key={tag} onClick={() => handleTagClick(tag)}>
                              {tag}
                            </Tag>
                          ))}
                        </TagsContainer>
                      )}
                    </>
                  )}
                </>
              ))}
            </PopoverContainer>
          </Popover>
        </Portal>
      )}
    </>
  );
};
