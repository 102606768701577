import React, { useContext } from 'react';

import { AppContext } from 'AppContext';
import { useIntegrationsAPI } from 'api/integrations/hooks';
import { Centerer, Spacer } from 'components/Core';
import {
  Modal,
  ModalBody,
  ModalButton,
  ModalCloseIcon,
  ModalContainer,
  ModalFooter,
  ModalHeader,
  ModalSubtitleText,
  ModalTitle,
  ModalTitleText,
} from 'components/Modal';
import { CircleLoader } from 'components/Loaders';
import { useClickOutside } from 'utils/hooks';
import { ReportSubscribe } from './ReportSubscribe';
import { StyledCheckButton } from './styles';
import { useAnalyticsIntegrations } from './useAnalyticsIntegrations';

export const ReportSubscribeModal = ({ onClose, immediatelyToggleEmailIntegration }) => {
  const { orgId } = useContext(AppContext);

  const { data: integrationTypes, isLoading, isFetching } = useIntegrationsAPI({ orgId });
  const {
    analyticsIntegrations,
    hasActiveAnalyticsIntegrations,
    deactivateAllIntegrations,
    updateAnalyticsIntegration,
    updateAnalyticsIntegrations,
  } = useAnalyticsIntegrations({
    integrationTypes,
  });

  const modalRef = useClickOutside(() => onClose());

  const handleSaveModal = () => {
    updateAnalyticsIntegrations();
    onClose();
  };

  return (
    <>
      <ModalContainer data-cy="report-subscribe-modal">
        <Modal ref={modalRef} overflow="visible" height="auto" width="740px">
          <ModalHeader>
            <ModalCloseIcon onClose={onClose} />
            <ModalTitle padding="0 32px">
              <ModalTitleText compact>
                <b>Subscribe to recurring updates</b>
              </ModalTitleText>
              <ModalSubtitleText>Subscript will send reports on how your business is moving</ModalSubtitleText>
            </ModalTitle>
          </ModalHeader>

          <ModalBody height="600px" paddingLeft="0" paddingRight="0">
            <Spacer height="2px" />
            {isLoading || isFetching ? (
              <Centerer>
                <CircleLoader width={24} height={24} />
              </Centerer>
            ) : (
              <ReportSubscribe
                integrationTypes={integrationTypes}
                immediatelyToggleEmailIntegration={immediatelyToggleEmailIntegration}
                analyticsIntegrations={analyticsIntegrations}
                hasActiveAnalyticsIntegrations={hasActiveAnalyticsIntegrations}
                updateAnalyticsIntegration={updateAnalyticsIntegration}
                deactivateAllIntegrations={deactivateAllIntegrations}
              />
            )}
          </ModalBody>

          <ModalFooter flexEnd noFixedHeight padding="12px 36px">
            <ModalButton onClick={onClose}>Cancel</ModalButton>
            <ModalButton className="primary" onClick={handleSaveModal} data-cy="report-subscribe-modal__submit-button">
              Save
              <StyledCheckButton />
            </ModalButton>
          </ModalFooter>
        </Modal>
      </ModalContainer>
    </>
  );
};
