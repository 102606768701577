import React from 'react';
import dayjs from 'dayjs';
import { WarningBar } from 'components/Blocks';
import { CREDIT_NOTE_METADATA } from 'views/Billing/consts';

export const CreditNoteFailedSendWarning = ({ creditNote }) => {
  const warnings = [
    <>
      <b>Failed to send</b> credit note on{' '}
      {dayjs.utc(creditNote.metadata?.[CREDIT_NOTE_METADATA.JOB_FAILED_AT]).format('MMM DD, YYYY')}.
      <br />
      <b>Reason:</b>{' '}
      <i>
        {creditNote.metadata?.[CREDIT_NOTE_METADATA.JOB_FAILED_MESSAGE]}
        <b />
      </i>
    </>,
  ];

  return <WarningBar fullWidth warnings={warnings} />;
};
