import React, { useContext, useMemo } from 'react';
import Avatar from 'boring-avatars';
import styled from 'styled-components';
import { useTable, usePagination, useGlobalFilter } from 'react-table';

import { useStateWithStorage } from 'utils/hooks';
import { AppContext } from 'AppContext';
import { useUsersAPI } from 'api/users/hooks';
import { SaveIcon } from 'components/Icons';
import { Spacer } from 'components/Core';
import { Loader } from 'components/Loaders';
import { RowActionPopover, StyledTableName, TableActionCell } from 'components/Table';
import { TableColumnSettings } from 'shared/Common';
import { TableContainerLayout } from 'views/Billing/Common/TableContainerLayout';
import { formatMemberDetails } from 'models/member';

import { MembersTable } from './MembersTable';
import { StyledHeaderIconButton } from '../styles';
import { useMemberInviteModal } from './MemberInviteModal';
import { useMemberDeleteModal } from './MemberDeleteModal';

const MembersTabContent = styled.div`
  margin-top: 28px;
  margin-bottom: 22px;
`;

const MemberTableNameTitle = styled.div`
  white-space: nowrap;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
`;

const HeaderCell = styled.div`
  font-weight: 900;
  font-size: 10px;
  line-height: 14px;
  text-transform: uppercase;

  &:last-child {
    width: 80px;
  }
`;

const StatusBadge = styled.div`
  display: inline-flex;
  padding: 4px 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 100px;
  border: 1px solid var(--primaryGreen10);
  background: var(--primaryGreen10);

  color: var(--secondaryGreen);

  font-size: 9px;
  font-weight: 900;
  line-height: 12px;
  text-transform: uppercase;

  opacity: 0.8;
`;

const MembersTab = () => {
  const { orgId } = useContext(AppContext);

  const { openModal, MemberInviteModal } = useMemberInviteModal();
  const { openModal: openDeleteModal, MemberDeleteModal } = useMemberDeleteModal();

  const { data, isLoading, isFetching } = useUsersAPI({ orgId });
  const dataForTable = useMemo(() => data?.map((member) => formatMemberDetails(member)) ?? [], [data]);

  const columns = useMemo(
    () => [
      {
        Header: () => <HeaderCell>Name</HeaderCell>,
        accessor: 'name',
        width: 180,
        Cell: ({ cell: { value } }) => (
          <StyledTableName>
            <Avatar
              size={24}
              name={value}
              variant="beam"
              colors={['#92A1C6', '#146A7C', '#F0AB3D', '#C271B4', '#C20D90']}
            />
            <Spacer width="8px" />
            <MemberTableNameTitle>{value}</MemberTableNameTitle>
          </StyledTableName>
        ),
      },
      {
        Header: () => <HeaderCell>Status</HeaderCell>,
        accessor: 'status',
        width: 150,
        Cell: () => <StatusBadge> Active </StatusBadge>,
      },
      {
        Header: () => <HeaderCell>Email</HeaderCell>,
        accessor: 'email',
        width: 250,
      },
      {
        Header: () => <HeaderCell>Role</HeaderCell>,
        accessor: 'permissions',
        width: 55,
      },
      {
        accessor: 'actions',
        width: 80,
        Cell: ({ row }) => (
          <TableActionCell>
            <RowActionPopover
              currentSelection={row.original}
              onDeleteClick={() => {
                openDeleteModal({ user: row.original });
              }}
              dataCyPrefix="members"
            />
          </TableActionCell>
        ),
      },
    ],
    [openDeleteModal],
  );

  const [cachedPageSize, setCachedPageSize] = useStateWithStorage('members-table-page-size', 10);

  const tableProps = useTable(
    {
      columns,
      data: dataForTable,
      initialState: {
        pageIndex: 0,
        pageSize: cachedPageSize,
      },
    },
    useGlobalFilter,
    usePagination,
  );

  return (
    <MembersTabContent>
      {isLoading ? (
        <div className="w-100 flexer">
          <Loader containerStyles={{ width: 40 }} />
        </div>
      ) : (
        <>
          <StyledHeaderIconButton icon={<SaveIcon />} iconFillColor="var(--primaryGreen)" onClick={openModal}>
            Add Member
          </StyledHeaderIconButton>
          <TableContainerLayout
            columnsSettingsComponent={
              <TableColumnSettings
                tableName="members"
                numberValue={tableProps.state.pageSize}
                handleShowResultsChange={(option) => {
                  tableProps.setPageSize(option.value);
                  setCachedPageSize(option.value);
                }}
              />
            }
            isFetching={isFetching}
            rowsCount={data?.length ?? 0}
            currentPageIndex={tableProps.state.pageIndex + 1}
            setCurrentPageIndex={(value) => tableProps.gotoPage(value - 1)}
            pageCount={tableProps.pageCount}
            setSearchQuery={tableProps.setGlobalFilter}
            placeholder="Search by name or email..."
          >
            <MembersTable tableProps={tableProps} />
          </TableContainerLayout>
        </>
      )}
      <MemberInviteModal />
      <MemberDeleteModal />
    </MembersTabContent>
  );
};

export { MembersTab };
