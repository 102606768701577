import { useContext, useMemo } from 'react';
import styled from 'styled-components';

import { TooltipContainer } from 'components/Tooltip';

import { InvoicingScheduleContext } from '../../InvoicingScheduleContext';
import { INVOICE_ITEM_TYPE_TO_KEY } from '../../../consts';
import { getInvoiceInitialValues, invoiceWasEdited } from 'views/Billing/InvoiceModal/utils';
import { WhiteButtonScheduleHeader } from '../styles';
import { useSettingsModal } from './useSettingsModal';

const Container = styled.div`
  pointer-events: ${({ disabled }) => disabled && 'none'};
`;

export const InvoiceSettingsButton = ({ disableEditing }) => {
  const { fetchedSelectedInvoice, invoiceFormValues, isScheduleDraft } = useContext(InvoicingScheduleContext);

  const { openSettingsModal, SettingsModal } = useSettingsModal();

  const invoiceHasPendingEdits = useMemo(() => {
    const source = fetchedSelectedInvoice ? getInvoiceInitialValues(fetchedSelectedInvoice) : {};
    const target = invoiceFormValues ? getInvoiceInitialValues(invoiceFormValues) : {};
    return (
      invoiceWasEdited({
        source,
        target,
        fields: Object.keys(source).concat(Object.values(INVOICE_ITEM_TYPE_TO_KEY)),
      }) && !source?.paid_at
    );
  }, [fetchedSelectedInvoice, invoiceFormValues]);

  return (
    <>
      <TooltipContainer
        isVisible={(invoiceHasPendingEdits && !isScheduleDraft) || disableEditing}
        toolTipContent={
          disableEditing
            ? 'Please wait for invoices to finish processing before updating settings'
            : 'Please save pending invoice edits first'
        }
        width={220}
        fontSize="12px"
      >
        <Container disabled={invoiceHasPendingEdits && !isScheduleDraft}>
          <WhiteButtonScheduleHeader
            style={{
              borderRadius: '0',
              borderRight: '1px solid var(--accentGraySecond)',
              borderLeft: '1px solid var(--accentGraySecond)',
            }}
            onClick={() => openSettingsModal()}
            data-cy="billing__invoice-schedule-modal__settings-button"
            disabled={(invoiceHasPendingEdits && !isScheduleDraft) || disableEditing}
          >
            <span>Settings</span>
          </WhiteButtonScheduleHeader>
        </Container>
      </TooltipContainer>

      <SettingsModal />
    </>
  );
};
