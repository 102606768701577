import styled from 'styled-components';
import Select from 'react-select';

import { LOCALES_LABEL, SUPPORTED_LOCALES_BY_COUNTRY } from 'consts/global';
import { selectStyles } from 'components/Controls';
import { Spacer } from 'components/Core';

const Card = styled.div`
  width: 770px;
  padding: 28px;
  background: #ffffff;
  margin: 20px 0;

  box-shadow: 8px 8px 60px var(--primaryBlack5);
  border-radius: 20px;
`;

const CardTitle = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: var(--primaryBlack);
`;

export const LocaleRegionCard = ({ localeRegion, setLocaleRegion }) => {
  const options = Object.values(SUPPORTED_LOCALES_BY_COUNTRY)
    .sort((a, b) => a.localeCompare(b))
    .map((country) => ({
      value: country,
      label: LOCALES_LABEL[country],
    }));

  return (
    <Card>
      <CardTitle>
        Your <b>locale region</b> for date formats
      </CardTitle>

      <Spacer height="10px" />

      <div data-cy="custom-select--locale_region">
        <Select
          menuPlacement="auto"
          name="locale_region"
          menuPortalTarget={document.querySelector('body')}
          styles={selectStyles({
            menuWidth: '100%',
          })}
          openMenuOnFocus
          value={localeRegion ? { label: LOCALES_LABEL[localeRegion], value: localeRegion } : null}
          placeholder="Select region..."
          onChange={(newValue) => {
            setLocaleRegion(newValue?.value);
          }}
          options={options}
          isClearable
          classNamePrefix="react-select"
        />
      </div>
    </Card>
  );
};
