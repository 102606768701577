/*
  Naming convention: <page_name>-page-<variable_name>
*/
export const WIZARD_LOCAL_STORAGE = {
  USER_INFO: 'wizard-page-user-info', //Object
};

export const getLocalStorage = () => {
  const storedWizardUserInfo = localStorage.getItem(WIZARD_LOCAL_STORAGE.USER_INFO)
    ? JSON.parse(localStorage.getItem(WIZARD_LOCAL_STORAGE.USER_INFO))
    : {};

  return {
    storedWizardUserInfo,
  };
};
