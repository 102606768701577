import styled from 'styled-components';
import { ReactComponent as DangerIcon } from 'images/alert-circle.svg';
import { ReactComponent as CogIcon } from 'images/bx-cog.svg';

export const StyledTable = styled.div`
  width: fit-content;

  .table-data {
    position: relative;

    svg {
      position: absolute;
      margin-left: 10px;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
`;

export const TilePercent = styled.div`
  opacity: 0.5;
  text-align: right;
  font-size: 11px;
  line-height: 14px;
`;

export const TableBody = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
`;

export const DateHeaderTile = styled.div`
  opacity: 0.5;
`;

export const StyledAverageRow = styled.div`
  height: 47px;
  ${({ marginBottom }) => (marginBottom ? 'margin-bottom: 8px;' : '')}
  display: flex;
  width: fit-content;

  & > div:first-child {
    background: white;
    z-index: 10;
    position: sticky;
    left: 0;
  }
`;

export const AverageTile = styled.div`
  width: 90px;
  display: flex;
  flex-direction: column;
  text-align: end;
  align-items: flex-end;
  justify-content: center;
  padding: 14px;
  border: 1px solid white;
  border-radius: 3px;
  background: var(--primaryBlack5);
  font-size: 12px;
  line-height: 16px;

  &:first-child {
    align-items: flex-start;
    font-weight: 700;
    border: 1px solid var(--primaryBlack5);
    border-radius: 0;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    border-right: none;
    ${({ noBorderTop }) => (noBorderTop ? 'border-top: none;' : 'border-top: 1px solid var(--primaryBlack5);')}
    ${({ noBorderTop }) => (noBorderTop ? 'border-top-left-radius: 0;' : 'border-top-left-radius: 8px;')}
    ${({ noBorderBottom }) =>
      noBorderBottom ? 'border-bottom: none;' : 'border-bottom: 1px solid var(--primaryBlack5);'}
    ${({ noBorderBottom }) => (noBorderBottom ? 'border-bottom-left-radius: 0;' : 'border-bottom-left-radius: 8px;')}
    background: white;
  }

  &:nth-child(2) {
    border-radius: 0;
    border: 1px solid var(--primaryBlack5);
    border-left: none;
    border-right: none;
    ${({ noBorderTop }) => (noBorderTop ? 'border-top: none;' : 'border-top: 1px solid var(--primaryBlack5);')}
    ${({ noBorderBottom }) =>
      noBorderBottom ? 'border-bottom: none;' : 'border-bottom: 1px solid var(--primaryBlack5);'}
    background: white;
  }

  &:nth-child(3) {
    border: 1px solid var(--primaryBlack5);
    border-radius: 0;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border-left: none;
    ${({ noBorderTop }) => (noBorderTop ? 'border-top: none;' : 'border-top: 1px solid var(--primaryBlack5);')}
    ${({ noBorderTop }) => (noBorderTop ? 'border-top-right-radius: 0;' : 'border-top-right-radius: 8px;')}
    ${({ noBorderBottom }) =>
      noBorderBottom ? 'border-bottom: none;' : 'border-bottom: 1px solid var(--primaryBlack5);'}
    ${({ noBorderBottom }) => (noBorderBottom ? 'border-bottom-right-radius: 0;' : 'border-bottom-right-radius: 8px;')}
    margin-right: 12px;
    background: white;
    width: 91px;
  }
`;

export const TableHeaderRow = styled.div`
  height: 42px;
  display: flex;
  width: fit-content;
  position: sticky;
  top: 0;
  z-index: 20;
`;

export const TableHeaderTile = styled.div`
  display: flex;
  align-items: center;
  width: 90px;
  justify-content: end;
  font-weight: 900;
  font-size: 10px;
  line-height: 14px;
  text-transform: uppercase;
  padding: 14px;
  white-space: nowrap;
  background: var(--primaryGray);

  &:first-child {
    justify-content: start;
    background: var(--primaryGray);
  }

  &:nth-child(2) {
    background: var(--primaryGray);
  }

  &:nth-child(3) {
    background: var(--primaryGray);
    margin-right: 12px;
    width: 91px;
  }

  &:last-child {
    border-top-right-radius: 8px;
  }
`;

export const TileValue = styled.div`
  cursor: ${({ active }) => (active ? 'pointer' : 'default')};
  ${({ segmentCell, isSecondaryValue }) => {
    const subtractBy = 1 * !!segmentCell + 1 * !!isSecondaryValue;

    return `
      font-size: ${12 - subtractBy}px;\n
      line-height: ${16 - subtractBy}px;
      font-weight: normal;
    `;
  }}

  &:hover {
    font-weight: 900;
    border-radius: 4px;
    transition: all 0.3s;
  }
`;

export const AverageTileSecondaryValue = styled.div`
  font-size: 11px;
  color: var(--primaryBlack50);
`;

export const StyledDangerIcon = styled(DangerIcon)`
  position: absolute;
  right: -2px !important;
  top: -1px !important;
  cursor: pointer;
  transform: none !important;
`;

export const TableDataCell = styled.div`
  position: relative;
  width: 90px;
  border-radius: 3px;
  padding: ${({ padding }) => padding ?? '8px 12px'};
  height: ${({ height }) => height ?? '47 px'};
  display: flex;
  align-items: center;
  color: ${({ whiteText }) => whiteText && 'white'};
  justify-content: ${({ left }) => (left ? 'start' : 'end')};
  font-weight: ${({ bold }) => (bold ? '700' : 'normal')};
  border-collapse: collapse;
  ${({ primary }) => primary && 'border: solid 1px white;'}
  ${({ backgroundColor }) => backgroundColor && `background-color: ${backgroundColor};`}
  white-space: nowrap;

  &:hover {
    ${StyledDangerIcon} {
      rect {
        fill: white;
      }

      path {
        fill: var(--primaryBlack);
      }
    }
  }
`;

export const SettingsTile = styled.div`
  width: 90px;
  color: var(--primaryBlue);
  cursor: pointer;

  svg {
    margin-top: 2px;
  }

  &:hover {
    color: var(--secondaryBlue);

    svg path {
      fill: var(--secondaryBlue);
    }
  }
`;

export const StyledCogIcon = styled(CogIcon)`
  height: 16px;
`;

export const CohortsTableTooltip = styled.div`
  margin-top: 16px;
  opacity: 0.3;

  svg {
    margin-left: 10px;
    margin-right: 10px;
  }
`;
