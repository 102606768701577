import React, { useContext, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useLocation } from 'react-router-dom';

import { AppContext } from 'AppContext';
import { useReportsAPI } from 'api/reports';
import { getAppSetting } from 'models/appSettings';
import { PAGE_CONTEXT_VALUES } from 'utils/hooks';
import { FlexBetweenContainer, Flexer, Spacer } from 'components/Core';
import {
  Modal,
  ModalBody,
  ModalButton,
  ModalCloseIcon,
  ModalContainer,
  ModalFooter,
  ModalFooterText,
  ModalHeader,
  ModalSubtitleText,
  ModalTitle,
  ModalTitleText,
} from 'components/Modal';
import { Loader } from 'components/Loaders';
import { useToasts } from 'components/Toasts';
import { DEFAULT_NEW_REPORT_TYPE, REPORT_OPTIONS_FROM_ROUTE } from '../consts';
import { ModalBodyWrapper } from '../styles';
import { ReportSelector } from '../ReportSelector';
import { pageStateToReportState } from '../stateUtils';

export const NewReportModal = ({ onClose, onSubmitted }) => {
  const { pathname } = useLocation();
  const { organizations, dataFilter, metadataFilter, appSettings } = useContext(AppContext);
  const {
    operations: { createReport },
  } = useReportsAPI({
    orgId: organizations[0].id,
    autoFetch: false,
  });
  const { pushError } = useToasts();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (data) => {
    setLoading(true);
    try {
      // Custom page app settings
      const customAppSettingsStates = Object.entries(
        PAGE_CONTEXT_VALUES[data.type]?.customAppSettingsStates ?? {},
      ).reduce((acc, [stateKey, settingKey]) => {
        acc[stateKey] = getAppSetting(settingKey);
        return acc;
      }, {});

      const report = await createReport({
        orgId: organizations[0].id,
        report: {
          ...data,
          // We just want to store the fields relevant for this page
          options: pageStateToReportState({
            page: data.type,
            pageState: {
              ...appSettings,
              dataFilter,
              metadataFilter,
              ...customAppSettingsStates,
            },
          }),
        },
      });
      onClose();
      if (onSubmitted) {
        onSubmitted({ report, isNewReport: true });
      }
    } catch (e) {
      pushError(e, 'Could not not create your report');
      console.error({ message: e.message, component: 'NewReportModal.js', stack: e });
      setLoading(false);
    }
  };

  return (
    <ModalContainer data-cy="new-report-modal">
      <Modal height="auto" width="640px">
        <ModalHeader>
          <ModalCloseIcon onClose={onClose} />
          <ModalTitle>
            <ModalTitleText>
              <b>Create Saved Report</b>
            </ModalTitleText>
            <ModalSubtitleText>New filters preset for specific page</ModalSubtitleText>
          </ModalTitle>
        </ModalHeader>
        {loading && (
          <ModalBody>
            <ModalBodyWrapper>
              <div className="w-100 flexer">
                <Loader containerStyles={{ width: 40 }} />
              </div>
            </ModalBodyWrapper>
          </ModalBody>
        )}
        {!loading && (
          <Formik
            initialValues={{
              type: REPORT_OPTIONS_FROM_ROUTE[pathname] ?? DEFAULT_NEW_REPORT_TYPE,
            }}
            validationSchema={Yup.object({
              title: Yup.string().required('Please, enter name'),
            })}
            onSubmit={handleSubmit}
          >
            {({ handleSubmit }) => (
              <>
                <ModalBody>
                  <ModalBodyWrapper>
                    <ReportSelector />
                  </ModalBodyWrapper>
                </ModalBody>

                <ModalFooter>
                  <FlexBetweenContainer centerer>
                    <ModalFooterText>You can change the filters on the report page after creating it</ModalFooterText>
                    <Flexer>
                      <ModalButton default onClick={onClose}>
                        Cancel
                      </ModalButton>
                      <Spacer width="14px" />
                      <ModalButton primary onClick={handleSubmit} data-cy="new-report-modal__save-button">
                        Save
                      </ModalButton>
                    </Flexer>
                  </FlexBetweenContainer>
                </ModalFooter>
              </>
            )}
          </Formik>
        )}
      </Modal>
    </ModalContainer>
  );
};
