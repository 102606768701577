import styled, { css } from 'styled-components';
import { Radio as AntdRadio } from 'antd';

export const RadioStyles = css`
  display: flex;

  &:not(:last-of-type) {
    margin-right: 10px;
  }

  & .ant-radio-inner {
    width: 20px;
    height: 20px;
  }

  & .ant-radio-checked {
    .ant-radio-inner {
      border-color: var(--primaryBlue);
      background-color: var(--primaryBlue);

      &::after {
        background-color: white;
        top: 5px;
        left: 5px;
      }
    }
  }
`;

const StyledRadio = styled(AntdRadio)`
  ${RadioStyles}
`;

export const Radio = (props) => <StyledRadio {...props} />;

export const RadioGroup = (props) => <StyledRadio.Group {...props} />;
