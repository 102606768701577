import axios from 'axios';
import { API_URL } from 'api/consts';

export const getReceipt = async ({ orgId, id }) => {
  const response = await axios.get(`${API_URL}/organizations/${orgId}/billing-receipts/${id}`);
  return response.data;
};

export const getReceipts = async ({ orgId, params }) => {
  const response = await axios.get(`${API_URL}/organizations/${orgId}/billing-receipts`, { params });
  return response.data;
};

export const createReceipt = async ({ orgId, body }) => {
  const response = await axios.post(`${API_URL}/organizations/${orgId}/billing-receipts`, body);
  return response.data;
};

export const updateReceipt = async ({ orgId, body, id }) => {
  const response = await axios.patch(`${API_URL}/organizations/${orgId}/billing-receipts/${id}`, body);
  return response.data;
};

export const sendReceipt = async ({ orgId, body, params }) => {
  const response = await axios.post(`${API_URL}/organizations/${orgId}/billing-receipts/send-receipt`, body, {
    params,
  });
  return response.data;
};

export const bulkUpdateReceipts = async ({ orgId, body, params }) => {
  const response = await axios.post(`${API_URL}/organizations/${orgId}/billing-receipts/bulk-update-receipts`, body, {
    params,
  });
  return response.data;
};
