import React, { useContext, useState } from 'react';
import { FlexerColumn } from 'components/Core';

import {
  Popover,
  PopoverListItemDescriptionFull,
  PopoverListItemFull,
  PopoverListItemIconFull,
  PopoverListItemTitleFull,
  PopoverWrapper,
} from 'components/Portal';
import { useClickOutside } from 'utils/hooks';
import { ReportBuilderContext } from './ReportBuilderContext';
import { POPOVER_OPTIONS } from './consts';

export const AddWidgetDropdown = ({ addWidgetButton, addInRowIndex, height, XOffset, YOffset }) => {
  const { onAddWidget } = useContext(ReportBuilderContext);
  const [showDropdown, setShowDropdown] = useState(false);
  const toggleDropdown = (event) => {
    event?.stopPropagation();
    setShowDropdown(!showDropdown);
  };
  const dropdownRef = useClickOutside(() => {
    setShowDropdown(false);
  });

  return (
    <PopoverWrapper ref={dropdownRef} height={height}>
      {addWidgetButton && addWidgetButton({ active: showDropdown, onClick: toggleDropdown })}
      {showDropdown && (
        <Popover
          data-cy="add-widget__popover"
          darkBorder
          width="200px"
          padding="0"
          YOffset={YOffset ?? -300}
          XOffset={XOffset}
          zIndex={1000}
        >
          {POPOVER_OPTIONS.map((option) => {
            const handleOnClick = (event) => {
              event?.stopPropagation();
              onAddWidget({
                key: option.key,
                addInRowIndex,
              });
              toggleDropdown();
            };
            return (
              <PopoverListItemFull
                key={option.key}
                data-cy={`add-widget__popover__item--${option.key}`}
                gap="8px"
                onClick={handleOnClick}
              >
                <PopoverListItemIconFull>{option.icon}</PopoverListItemIconFull>
                <FlexerColumn gap="4px">
                  <PopoverListItemTitleFull>{option.title}</PopoverListItemTitleFull>
                  <PopoverListItemDescriptionFull>{option.description}</PopoverListItemDescriptionFull>
                </FlexerColumn>
              </PopoverListItemFull>
            );
          })}
        </Popover>
      )}
    </PopoverWrapper>
  );
};
