import { filter } from 'lodash';

import { EMOJI, ISO_CODE_TO_SYMBOL, NUMBER_FORMATS } from 'consts/global';
import { EmojiTooltip } from 'views/Dashboard/Common/EmojiTooltip';
import { pluralize } from './stringUtils';

export const numberFormatter = ({
  type,
  rawValue,
  minimumFractionDigits,
  decimalPlaces = 0,
  addSign = false,
  currency = 'USD',
  language,
}) => {
  if (type === NUMBER_FORMATS.EMOJI && rawValue) {
    return <EmojiTooltip value={rawValue} />;
  }

  const value = Number(rawValue);
  language = language ?? 'en-US';

  if (typeof value !== NUMBER_FORMATS.NUMBER) return <EmojiTooltip value={EMOJI.NO_GOOD} />;

  if (!isFinite(value)) return <EmojiTooltip value={EMOJI.INFINITY} />;

  switch (type) {
    case NUMBER_FORMATS.CURRENCY:
      return !isNaN(value) ? (
        `${addSign && value > 0 ? '+' : ''}${value.toLocaleString(language, {
          style: NUMBER_FORMATS.CURRENCY,
          currency,
          minimumFractionDigits: minimumFractionDigits ?? decimalPlaces,
          maximumFractionDigits: decimalPlaces,
        })}`
      ) : (
        <EmojiTooltip value={EMOJI.NO_MOUTH} />
      );
    case NUMBER_FORMATS.PERCENT:
      if (value === 0) return '0%';
      //isNaN(null) is apparently false
      return value && !isNaN(value) ? (
        `${(value * 100).toFixed(decimalPlaces)}%`
      ) : (
        <EmojiTooltip value={EMOJI.SPEAK_NO_EVIL} />
      );
    case NUMBER_FORMATS.MONTHS:
      if (value === 0) return '0  mo';
      return value && !isNaN(value) ? (
        `${value.toFixed(decimalPlaces)} mo`
      ) : (
        <EmojiTooltip value={EMOJI.EXPRESSIONLESS} />
      );
    default:
      return value.toLocaleString(language, {
        minimumFractionDigits: minimumFractionDigits ?? decimalPlaces,
        maximumFractionDigits: decimalPlaces,
      });
  }
};

export const calcMonthlyCustomerCount = (customerCounts) => {
  const currentYear = new Date().getFullYear().toString();
  const currentYearlyCustomers = filter(customerCounts, (c) => c.month.includes(currentYear));

  const currentMonth = (new Date().getMonth() + 1).toString();
  const currentMonthlyCustomers = currentYearlyCustomers.find((c) => c.month.includes(currentMonth));

  const lastMonthlyCount = currentYearlyCustomers.find((c) => c.month.includes(currentMonth - 1));

  return {
    currentMonth: parseInt(currentMonthlyCustomers?.count),
    lastMonth: parseInt(lastMonthlyCount?.count),
    yearlySpread: currentYearlyCustomers,
  };
};

// Formats the given value based on its type, to be displayed
export const formatValueForDisplay = (value) => {
  switch (typeof value) {
    case NUMBER_FORMATS.NUMBER:
      return value.toLocaleString('en-US', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      });
    case 'undefined':
      return '-';
    default:
      return String(value);
  }
};

export const amountWithAbbreviation = ({ value, type, currency }) => {
  let suffix = '';
  let showValue = formatValueForDisplay(value);
  if (type === NUMBER_FORMATS.PERCENT) {
    showValue = formatValueForDisplay(value * 100);
    suffix = '%';
  } else {
    if (Math.floor(Math.abs(value) / 1000000) > 0) {
      suffix = 'M';
      showValue = Math.floor(value / 100000) / 10;
    } else if (Math.floor(Math.abs(value) / 1000) > 0) {
      suffix = 'K';
      showValue = Math.floor(value / 100) / 10;
    }
  }
  return `${type === NUMBER_FORMATS.CURRENCY ? ISO_CODE_TO_SYMBOL[currency] ?? '$' : ''}${showValue}${suffix}`;
};

/**
 * Converts number into readable period
 * Examples:
 *   1 -> 1 day
 *   5 -> 5 days
 *   7 -> 1 week
 *   14 -> 2 weeks
 */
export const daysToPeriodString = (num) => {
  if (num % 7 === 0) {
    const weeks = num / 7;
    return pluralize(weeks, 'week');
  } else if (num > 7) {
    const weeks = Math.floor(num / 7);
    const days = num % 7;
    return `${pluralize(weeks, 'week')} and ${pluralize(days, 'day')}`;
  } else {
    return pluralize(num, 'day');
  }
};
