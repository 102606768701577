import React, { useMemo, useContext } from 'react';
import styled from 'styled-components';
import { AppContext } from 'AppContext';
import { useTable } from 'react-table';
import {
  ReactTableBody,
  ReactTableHeader,
  ReactTableHeaderColumn,
  ReactTableHeaderRow,
  ReactTableCell,
  ReactTableRow,
} from 'components/Table';
import { getColumns, TableCell } from './columns';
import { getDataForTable } from './getDataForTable';

const StyledReactTableCell = styled(ReactTableCell)`
  position: ${({ sticky }) => sticky && 'sticky'};
  left: ${({ sticky }) => sticky && '0'};
`;

const TableContainer = styled.div`
  margin-top: 32px;
  width: 100%;
  overflow: scroll;
  &:hover ${TableCell}.has-drilldown {
    outline: 1px solid var(--primaryBlue10);
    border-radius: 4px;
  }
`;

export const AccountingRevenueTable = ({
  data,
  handleModalOpen,
  setShowCustomersWithDeferred,
  setShowCustomersWithAccrued,
  setShowCustomersWithInvoiced,
  setShowCustomersWithAccountingRevenue,
  setModalGLIntegrationId,
  glIntegrationsById,
}) => {
  const {
    orgConfigs: { accountingCurrencies },
  } = useContext(AppContext);

  const columns = useMemo(
    () =>
      getColumns({
        data,
        handleModalOpen,
        accountingCurrencies,
        setShowCustomersWithDeferred,
        setShowCustomersWithAccrued,
        setShowCustomersWithInvoiced,
        setShowCustomersWithAccountingRevenue,
        setModalGLIntegrationId,
      }),
    [
      data,
      handleModalOpen,
      accountingCurrencies,
      setShowCustomersWithDeferred,
      setShowCustomersWithAccrued,
      setShowCustomersWithInvoiced,
      setShowCustomersWithAccountingRevenue,
      setModalGLIntegrationId,
    ],
  );

  const dataForTable = useMemo(() => getDataForTable({ glIntegrationsById, data }), [glIntegrationsById, data]);

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable({
    columns,
    data: dataForTable,
  });

  return (
    <TableContainer {...getTableProps()}>
      <ReactTableHeader backgroundColor="var(--secondaryGray)">
        {headerGroups.map((headerGroup) => (
          <ReactTableHeaderRow {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <ReactTableHeaderColumn
                {...column.getHeaderProps()}
                customWidth={column.width}
                data-cy="accounting-table__month-cell"
                alignRight
              >
                {column.render('Header')}
              </ReactTableHeaderColumn>
            ))}
          </ReactTableHeaderRow>
        ))}
      </ReactTableHeader>
      {rows.length > 0 && (
        <ReactTableBody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            const subRow = row.original.subRow;
            const isHeader = row.original.isHeader;
            const isGLRow = row.original.isGLRow;
            return (
              <ReactTableRow
                {...row.getRowProps()}
                noRowHover
                noBorderBottom={true}
                borderTop={isHeader || isGLRow}
                subRowBackgroundColor="white"
                height={subRow ? '48px' : '58px'}
                firstChildBorder
              >
                {row.cells.map((cell, index) => (
                  <StyledReactTableCell
                    {...cell.getCellProps()}
                    customWidth={cell.column.width}
                    alignRight={index !== 0}
                    sticky={index === 0}
                  >
                    {cell.render('Cell')}
                  </StyledReactTableCell>
                ))}
              </ReactTableRow>
            );
          })}
        </ReactTableBody>
      )}
    </TableContainer>
  );
};
