import { useCallback, useState } from 'react';
import { EVENTS } from 'consts/analytics';
import { SNAPSHOT_METRIC_BY_KEY } from 'consts/snapshotMetrics';
import { useAnalytics } from 'utils/hooks';
import { CardChartModal } from './CardChartModal';
import { CardChartModalProvider } from './CardChartModalProvider';

export const useCardChartModal = () => {
  const { trackEvent } = useAnalytics();
  const [showModal, setShowModal] = useState(false);
  const [cardChartModalData, setCardChartModalData] = useState(false);

  const openModal = useCallback(
    (data) => {
      setShowModal(true);

      if (data) {
        const { label } = SNAPSHOT_METRIC_BY_KEY[data.metricKey];
        trackEvent({ name: EVENTS.OPEN_DASHBOARD_CARD_CHART_DETAILS, properties: { title: label } });

        if (data.selectedGoalId) {
          trackEvent({
            name: EVENTS.OPEN_GOAL_METRIC_MODAL,
            properties: {
              goalId: data.selectedGoalId,
              goalMetric: data.metricKey,
            },
          });
        }
      }
      setCardChartModalData(data);
    },
    [setShowModal, setCardChartModalData, trackEvent],
  );

  const closeModal = useCallback(() => {
    setShowModal(false);
  }, [setShowModal]);

  const Modal = useCallback(
    () =>
      showModal ? (
        <CardChartModalProvider cardChartModalData={cardChartModalData}>
          <CardChartModal closeModal={closeModal} />
        </CardChartModalProvider>
      ) : null,
    [showModal, closeModal, cardChartModalData],
  );

  return {
    openModal,
    Modal,
  };
};
