import querystring from 'querystring';

// Intentionally using the same signature as @slack/web-api in case we want to use that official package.
// That package isn't build-able for us as of now.
class Slack {
  constructor({ token }) {
    this.token = token;
    this.chat = {
      postMessage: async (data) => {
        // We would run into CORS issue if we use Content-type: application/json
        //  or pass the token in the Authorization HTTP header
        const response = await fetch('https://slack.com/api/chat.postMessage', {
          method: 'POST',
          headers: {
            'Content-type': 'application/x-www-form-urlencoded',
          },
          body: querystring.stringify({
            ...data,
            token,
          }),
        });
        return response.json();
      },
    };
  }
}

export { Slack };
