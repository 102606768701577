import styled from 'styled-components';

export const ReportDataSection = styled.div`
  box-shadow: inset 0 10px 40px rgba(0, 0, 0, 0.05);
  padding: 21px 50px;
`;

export const ExplainerText = styled.div`
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  margin: 0 8px;
`;

export const DateText = styled.div`
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #00152e;
  opacity: 0.4;
  margin: 0 8px;
`;
