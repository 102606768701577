export const DEFAULT_PRICING_PLAN_MAPPER = {
  name: 'Pricing Plan Name',
  product_id: 'Product',
  event_name: 'Event Name',
  tiers: 'Billing Scheme',
  invoicing_frequency: 'Interval',
  currency: 'Currency',
  interval: 'Interval',
  bill_in_advance: 'Bill in advance',
  group_usage_by_calendar_month: 'Group usage by calendar month',
  description: 'Description',
};
