import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { cssVar } from 'polished';

import { AppContext } from 'AppContext';
import { useReportsAPI } from 'api/reports';
import { useToasts } from 'utils/hooks';
import { useUnsavedChanges } from 'utils/hooks/useUnsavedChanges';
import { DEFAULT_DASHBOARD_TITLE } from 'views/Dashboard/ReportBuilder/consts';
import { CentererVertical, Spacer } from 'components/Core';
import { TooltipContainer } from 'components/Tooltip';
import { IconButton } from 'components/Buttons';
import { SaveIcon, RechargeIcon } from 'components/Icons';
import { CircleLoader } from 'components/Loaders';
import { isReportModified } from '../utils';
import { UnsavedText } from './styles';

export const SaveReportButton = ({ report, pageReportState, onSubmitted }) => {
  const { pathname } = useLocation();
  const { pushError } = useToasts();
  const { orgId } = useContext(AppContext);
  const {
    operations: { updateReport, createReport },
  } = useReportsAPI({
    orgId,
    autoFetch: false,
  });
  const [isUpdating, setIsUpdating] = useState(false);

  // TODO: https://github.com/ChifferCo/chiffer/issues/1823
  // const isNewReport = state?.isNewReport;

  const isDefaultDashboard = report.type === 'snapshot' && report.title === DEFAULT_DASHBOARD_TITLE;
  const isModified = useMemo(
    () =>
      report &&
      isReportModified({
        reportOptions: report.options,
        changedReportOptions: pageReportState,
      }),
    [pageReportState, report],
  );

  const { Prompt } = useUnsavedChanges({ isModified: isModified && !isDefaultDashboard, currentPathname: pathname });

  const handleUpdateReport = useCallback(async () => {
    setIsUpdating(true);
    try {
      if (report.id) {
        const updatedReport = await updateReport({
          orgId,
          reportId: report.id,
          report: {
            options: pageReportState,
          },
        });
        onSubmitted({ updatedReport });
      } else {
        const createdReport = await createReport({
          orgId,
          report: {
            ...report,
            title: report.title,
            options: pageReportState,
          },
        });
        onSubmitted({ updatedReport: createdReport });
      }
    } catch (e) {
      pushError(e, 'Could not update report! Please try again');
      console.error({ message: e.message, component: 'SaveReportButton.js', stack: e });
    } finally {
      setIsUpdating(false);
    }
  }, [pushError, orgId, pageReportState, report, updateReport, createReport, onSubmitted]);

  if (isUpdating) {
    return <CircleLoader width={24} height={24} />;
  }

  return (
    <CentererVertical>
      <Prompt />
      {isModified && (
        <>
          <RechargeIcon fill="var(--primaryOrange)" />
          <UnsavedText>You have several unsaved changes</UnsavedText>
          <Spacer width="16px" />
        </>
      )}
      <TooltipContainer
        width={220}
        yOffset={10}
        toolTipContent="After setting the necessary filters, please save your report"
      >
        <IconButton
          icon={<SaveIcon />}
          iconFillColor={cssVar('--primaryGreen')}
          onClick={handleUpdateReport}
          data-cy="save-report-button"
        >
          Save Report
        </IconButton>
      </TooltipContainer>
    </CentererVertical>
  );
};
