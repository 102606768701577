import React, { useContext } from 'react';
import styled from 'styled-components';

import { useAuth0 } from '@auth0/auth0-react';
import { COLORS } from 'consts/colors';
import { FlexBetweenContainer, Flexer } from 'components/Core';
import { DIRECTIONS, TooltipContainer } from 'components/Tooltip';
import { PopoverWrapper } from 'components/Portal';
import { ModalFooter } from 'components/Modal';
import { Button } from 'components/Buttons';
import { ArrowRightCircleIcon } from 'components/Icons';
import { useToasts } from 'components/Toasts';

import { InvoicingScheduleContext } from '../InvoicingScheduleModal/InvoicingScheduleContext';
import { validateFormWithToast } from 'utils/formUtils';
import { CreditNoteContext } from '../InvoicingScheduleModal/CreditNoteContext';

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 8px;

  font-weight: 700;
  line-height: 20px;
  box-shadow: 2px 2px 12px var(--primaryBlack2);

  border: ${({ borderColor }) => borderColor && `1px solid ${borderColor}`};
  background: ${({ background }) => background};

  &:hover {
    border: ${({ hoverBorderColor }) => hoverBorderColor && `1px solid ${hoverBorderColor}`};
    background: ${({ hoverBackground }) => hoverBackground};
  }

  svg {
    width: ${({ iconSize }) => iconSize};
    height: ${({ iconSize }) => iconSize};
    path {
      fill: ${({ iconFillColor }) => iconFillColor};
      stroke: ${({ iconStrokeColor }) => iconStrokeColor};
    }
  }
`;

const StyledArrowRightCircleIcon = styled(ArrowRightCircleIcon)`
  width: 16px;
  height: 16px;
`;

export const CreditNoteSendFooter = ({ creditNote, onClose, onSaveCreditNote, isResend }) => {
  const { user = {} } = useAuth0();

  const { disableSubmitButton } = useContext(InvoicingScheduleContext);

  const { creditNoteFormRef } = useContext(CreditNoteContext);

  const { pushToast } = useToasts();

  const handleSaveCreditNote = async () => {
    const isFormValid = await validateFormWithToast({
      formCurrent: creditNoteFormRef?.current,
      pushToast,
    });

    if (isFormValid) {
      await onSaveCreditNote();
      onClose();
    }
  };

  const handleSendCreditNote = async ({ testSend } = {}) => {
    const isFormValid = await validateFormWithToast({
      formCurrent: creditNoteFormRef?.current,
      pushToast,
    });

    if (isFormValid) {
      await onSaveCreditNote({ isSend: true, isTestSend: testSend });
      if (!testSend) onClose();
    }
  };

  return (
    <ModalFooter transparent noFixedHeight padding="12px 40px" topSeparator>
      <FlexBetweenContainer alignItems="center">
        <Flexer gap="8px" alignItems="center">
          <>
            <TooltipContainer
              width={220}
              direction={DIRECTIONS.TOP}
              hideArrow
              yOffset={28}
              fontSize="12px"
              toolTipContent={`Send the email to yourself: ${user?.email}`}
            >
              <StyledButton
                hoverBackground="var(--primaryBlack10)"
                onClick={() => handleSendCreditNote({ testSend: true })}
              >
                Test Send
              </StyledButton>
            </TooltipContainer>

            <StyledButton
              data-cy="credit-note-send-modal__save-button"
              hoverBackground="var(--primaryBlack10)"
              onClick={() => handleSaveCreditNote()}
            >
              Save Email
            </StyledButton>

            {!creditNote?.auto_send && (
              <PopoverWrapper>
                <TooltipContainer
                  width={210}
                  direction={DIRECTIONS.TOP}
                  hideArrow
                  yOffset={28}
                  fontSize="12px"
                  toolTipContent={'Please check sender settings and configure mailer'}
                  isVisible={disableSubmitButton}
                >
                  <StyledButton
                    data-cy="credit-note-send-modal__send-button"
                    filled
                    active
                    color={COLORS.GREEN}
                    iconFillColor="#FFF"
                    onClick={() => handleSendCreditNote()}
                    disabled={disableSubmitButton}
                  >
                    {isResend ? 'Resend' : 'Send Now'} <StyledArrowRightCircleIcon />
                  </StyledButton>
                </TooltipContainer>
              </PopoverWrapper>
            )}
          </>
        </Flexer>
      </FlexBetweenContainer>
    </ModalFooter>
  );
};
