import { useContext, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Formik } from 'formik';
import { isEmpty } from 'lodash';

import { AppContext } from 'AppContext';
import { Centerer, FlexerColumn, Row, Spacer } from 'components/Core';
import { CircleLoader } from 'components/Loaders';
import { ModalContainer, Modal, ModalCloseIcon } from 'components/Modal';
import { useDebouncedSearchBar } from 'components/Blocks';
import { includesSearchQuery, pluralize } from 'utils/stringUtils';
import { useClickOutside } from 'utils/hooks';
import { useImportsAPI } from 'api/imports';
import { useExternalInvoiceAPI } from 'api/billing/hooks';
import { INVOICE_ITEM_TYPES } from 'views/Billing/consts';

import { InvoicingScheduleContext } from '../InvoicingScheduleContext';
import {
  ConfirmButton,
  Footer,
  Header,
  TableHeader,
  TogglesDescription,
} from '../InvoicingScheduleSelectTransactionsPanel/styles';
import { ImportInvoiceLine } from './ImportInvoiceLine';
import { useMatchImportedInvoicesModal } from './useMatchImportedInvoicesModal';

const InvoicesList = styled(FlexerColumn)`
  height: 504px;
  overflow: auto;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
`;

const ExternalInvoice = styled(FlexerColumn)`
  border-top: ${({ hasBorder }) => (hasBorder ? '1px solid var(--accentGraySecond)' : 'none')};
  padding-top: 16px;
`;

const EmptyState = styled.div`
  color: var(--primaryBlack50);
  font-size: 12px;
  margin: 16px;
  line-height: 16px;
`;

export const SelectImportInvoicesModal = ({ customerId, onClose, onImportInvoicesSave, selectedInvoices = [] }) => {
  const { orgId } = useContext(AppContext);
  const { glIntegration, includedTransactions } = useContext(InvoicingScheduleContext);
  const [searchQuery, setSearchQuery] = useState();

  const { data: glCustomerImports } = useImportsAPI({
    orgId,
    filters: {
      integrationIds: glIntegration?.id ? [glIntegration.id] : [],
      chifferObjectName: 'customer',
      chifferObjectIds: [customerId],
    },
  });
  const existingGlCustomerImport = glCustomerImports?.[0] ?? null;

  const { data: externalInvoices, isLoading: isLoadingExternalInvoices } = useExternalInvoiceAPI({
    orgId,
    externalCustomerId: existingGlCustomerImport?.provider_object_id,
    excludeExistingImports: true,
    integrationId: glIntegration?.id,
  });

  const { DebouncedSearchBar } = useDebouncedSearchBar({
    onSearchQueryChange: setSearchQuery,
    initialValue: searchQuery,
    placeholder: 'Search by Number or External ID...',
    placeholderColor: 'var(--primaryBlack30)',
    backgroundColor: 'transparent',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
    width: '180px',
  });

  const {
    openModal: openMatchImportedInvoicesModal,
    Modal: MatchImportedInvoicesModal,
    isModalOpen: isMatchImportedInvoicesModalOpen,
  } = useMatchImportedInvoicesModal({
    name: 'selectedInvoicesToImport',
  });

  const handleFormSubmit = (values, { setFieldValue }) => {
    const invoicesToImport = values.selectedInvoicesToImport.map((invoice) => ({
      ...invoice,
      invoice_items: invoice.invoice_items.map((lineItem, index) => {
        const transaction =
          includedTransactions?.find((transaction) => transaction.product_name === lineItem.name) ??
          includedTransactions?.[index];
        const isTypedItem = Object.values(INVOICE_ITEM_TYPES).includes(lineItem.type);

        if (
          (!transaction?.productMetadata?.bundle_items?.length || isTypedItem) &&
          lineItem.metadata?.bundleItems?.length
        )
          delete lineItem.metadata?.bundleItems;

        return {
          ...lineItem,
          transaction_id: isTypedItem ? null : transaction?.id ?? null,
          product_id: isTypedItem ? null : transaction?.product_id ?? null,
          amount: lineItem?.amount ?? 0,
          seats: lineItem?.seats ?? 0,
          ...(transaction?.productMetadata?.bundle_items &&
            !lineItem.metadata?.bundleItems.length &&
            !isTypedItem && {
              metadata: {
                ...lineItem.metadata,
                bundleItems: transaction.productMetadata.bundle_items,
              },
            }),
        };
      }),
      // for Xero
      ...(invoice.SentToContact && {
        sent_at: new Date(invoice.date),
      }),
    }));
    setFieldValue('selectedInvoicesToImport', invoicesToImport);

    openMatchImportedInvoicesModal({
      onSave: (importedInvoices) => {
        onImportInvoicesSave(importedInvoices);
        onClose();
      },
    });
  };

  const modalRef = useClickOutside(() => !isMatchImportedInvoicesModalOpen && onClose());

  const searchedInvoices = useMemo(
    () =>
      Array.isArray(externalInvoices) &&
      (externalInvoices ?? [])?.filter((externalInvoice) => {
        return (
          includesSearchQuery(externalInvoice.invoice_number, searchQuery) ||
          includesSearchQuery(externalInvoice?.external_id ?? externalInvoice?.invoice_external_id, searchQuery)
        );
      }),
    [externalInvoices, searchQuery],
  );

  return (
    <ModalContainer>
      <Modal
        padding="0"
        width="860px"
        height="675px"
        ref={modalRef}
        data-cy="invoicing-schedule__select-invoices-import"
        overflow="visible"
        compact
      >
        <ModalCloseIcon onClick={onClose} style={{ right: '-40px', top: 0 }} />
        <Formik
          enableReinitialize={true}
          initialValues={{
            selectedInvoicesToImport: selectedInvoices,
          }}
          onSubmit={handleFormSubmit}
        >
          {({ values, setFieldValue, handleSubmit }) => (
            <>
              <FlexerColumn>
                <Header>
                  <DebouncedSearchBar />

                  <Row>
                    <TogglesDescription>Showing only inv. not imported</TogglesDescription>
                  </Row>
                </Header>

                <TableHeader>
                  <div>invoice / invoice item</div>
                  <div>date</div>
                  <div>status</div>
                  <div>amount</div>
                </TableHeader>

                <FlexerColumn>
                  <InvoicesList>
                    {isLoadingExternalInvoices ? (
                      <Centerer>
                        <Spacer height="200px" />
                        <CircleLoader width={24} height={24} />
                      </Centerer>
                    ) : isEmpty(searchedInvoices) ? (
                      <EmptyState>No invoices found that haven't already been imported</EmptyState>
                    ) : (
                      searchedInvoices?.map((externalInvoice, index) => (
                        <ExternalInvoice hasBorder={index > 0} key={externalInvoice.external_id}>
                          <ImportInvoiceLine
                            externalInvoice={externalInvoice}
                            showCheckbox
                            onInvoiceAdd={() => {
                              setFieldValue('selectedInvoicesToImport', [
                                externalInvoice,
                                ...values.selectedInvoicesToImport,
                              ]);
                            }}
                            onInvoiceRemove={() => {
                              setFieldValue(
                                'selectedInvoicesToImport',
                                values.selectedInvoicesToImport.filter(
                                  ({ external_id }) => external_id !== externalInvoice.external_id,
                                ),
                              );
                            }}
                            isSelected={
                              !!values.selectedInvoicesToImport.find(
                                (i) => i.external_id === externalInvoice.external_id,
                              )
                            }
                          />
                        </ExternalInvoice>
                      ))
                    )}
                  </InvoicesList>
                </FlexerColumn>
                <Footer>
                  <ConfirmButton onClick={() => setFieldValue('selectedInvoicesToImport', searchedInvoices)}>
                    Import all invoices
                  </ConfirmButton>

                  {values?.selectedInvoicesToImport.length ? (
                    <ConfirmButton filled onClick={handleSubmit} data-cy="select-import-invoices-modal__confirm-button">
                      <span>Save importing ({pluralize(values.selectedInvoicesToImport.length, 'invoice')})</span>
                    </ConfirmButton>
                  ) : null}
                </Footer>
              </FlexerColumn>

              <MatchImportedInvoicesModal />
            </>
          )}
        </Formik>
      </Modal>
    </ModalContainer>
  );
};
