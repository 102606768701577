import styled from 'styled-components';
import dayjs from 'dayjs';
import Checkbox from 'antd/lib/checkbox/Checkbox';

import { RowActionPopover, TableActionCell } from 'components/Table';
import { TextBox } from 'components/Core/Text';
import { NumberCircle } from 'views/Contracts/styles';

import { CONTRACT_STATUS } from '../consts';

const STATUS_TAG_TO_FIELDS = {
  [CONTRACT_STATUS.PARSED]: {
    displayedText: 'Parsed',
    backgroundColor: 'var(--primaryGreen10)',
    borderColor: 'var(--primaryGreen10)',
    tagColor: 'var(--secondaryGreen80)',
  },
  [CONTRACT_STATUS.NEEDS_REVIEW]: {
    displayedText: 'Needs review',
    backgroundColor: 'var(--primaryYellow10)',
    borderColor: 'var(--accentGraySecond)',
    tagColor: 'var(--secondaryYellow80)',
  },
  [CONTRACT_STATUS.PROCESSING]: {
    displayedText: 'In processing',
    backgroundColor: 'white',
    borderColor: 'white',
    tagColor: 'var(--primaryBlack50)',
  },
};

export const StatusTag = styled.div`
  font-weight: 900;
  font-size: 9px;
  text-transform: uppercase;
  line-height: 12px;
  padding: 4px 8px;
  position: relative;
  left: -5px;
  border-radius: 100px;
  background-color: ${({ status }) => STATUS_TAG_TO_FIELDS[status].backgroundColor};
  color: ${({ status }) => STATUS_TAG_TO_FIELDS[status].tagColor};
  border: ${({ status }) => `1px solid ${STATUS_TAG_TO_FIELDS[status].borderColor}`};
`;

const DealNameColumn = styled.div`
  cursor: pointer;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: var(--primaryBlue);

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const generateColumns = ({ onContractClick, onContractDelete }) => {
  const columns = [
    {
      Header: ({ getToggleAllRowsSelectedProps }) => (
        <Checkbox data-cy="contract-row-checkbox--select-all" {...getToggleAllRowsSelectedProps()} />
      ),
      accessor: 'checkbox',
      width: 32,
      Cell: ({ row }) => (
        <Checkbox
          checked={row.isSelected}
          onClick={() => {
            row.toggleRowSelected();
          }}
          data-cy={`contract-row-checkbox-${row.original.name}-${row.original.customer_name}`}
        />
      ),
    },
    {
      Header: 'ID',
      width: 100,
      accessor: 'id',
      Cell: ({ row }) => <div>{row.original.id}</div>,
    },
    {
      Header: 'Files',
      accessor: 'file_name',
      width: 250,
      Cell: ({ row }) => (
        <DealNameColumn onClick={() => onContractClick(row)} data-cy="contract-table__name-column">
          {row.original.attachments?.map((att) => att.name).join(',')}
        </DealNameColumn>
      ),
    },
    {
      Header: 'Status',
      accessor: 'status',
      width: 120,
      Cell: ({ row }) => {
        return (
          <StatusTag status={row.original.status}>{STATUS_TAG_TO_FIELDS[row.original.status].displayedText}</StatusTag>
        );
      },
    },
    {
      Header: 'Customer',
      accessor: 'customer',
      width: 250,
      Cell: ({ row }) => {
        if (row.original.status === CONTRACT_STATUS.PROCESSING) {
          return (
            <TextBox italic opacity="0.5">
              Will be parsed
            </TextBox>
          );
        }
        if (!row.original.customer_name) {
          return (
            <TextBox italic opacity="0.5">
              Customer not parsed
            </TextBox>
          );
        }

        return <div> {row.original.customer_name} </div>;
      },
    },
    {
      Header: 'Transactions',
      accessor: 'transactions',
      width: 250,
      Cell: ({ row }) => {
        if (row.original.status === CONTRACT_STATUS.PROCESSING) {
          return (
            <TextBox italic opacity="0.5">
              Will be created
            </TextBox>
          );
        }

        return <NumberCircle> {row.original.attachments.length} </NumberCircle>;
      },
    },
    {
      Header: 'Uploaded at',
      accessor: 'created_at',
      width: 100,
      Cell: ({ row }) => <div>{dayjs.utc(row.original.created_at).format('MMM D, YYYY')}</div>,
    },
    {
      accessor: 'actions',
      disableSortBy: true,
      width: 65,
      Cell: ({ row }) => (
        <TableActionCell>
          <RowActionPopover
            editText="review"
            onEditClick={() => onContractClick(row)}
            onDeleteClick={() => onContractDelete(row)}
          />
        </TableActionCell>
      ),
    },
  ];

  return columns;
};
