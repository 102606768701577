import React from 'react';
import styled from 'styled-components';
import { numberFormatter } from 'utils/formatters';
import { reFormatDate, getPreviousMonth, generateMonths } from 'utils/dateUtils';
import { ReactComponent as SubcategoryArrowIcon } from 'images/icon_subcategory_arrow.svg';
import DataDecreasingIcon from 'images/icon_stat_dec.svg';
import DataIncreasingIcon from 'images/icon_stat_inc.svg';
import DataStableIcon from 'images/icon_stat_stable.svg';
import { StyledTableName } from 'components/Table';
import { FlexEndContainer } from 'components/Core';

const TitleColumn = styled(StyledTableName)`
  background: #fff;
  width: 100%;
`;

const CreditOrDebitLabel = styled.span`
  background: ${({ value }) => (value === 'CR' ? 'var(--primaryBlue10)' : 'var(--primaryRed10)')};
  padding: 4px;
  margin-left: 24px;
  border-radius: 5px;
`;

const TableRowTag = styled.span`
  display: flex;
  padding: 8px;
  ${({ subTag, isGLRow }) => (subTag || isGLRow ? '' : 'background-color: var(--primaryBlack5);')}
  border-radius: 100px;
  align-items: center;
  font-weight: ${({ subTag, isGLRow }) => (subTag ? '400' : isGLRow ? '800' : '900')};
  font-size: ${({ subTag }) => (subTag ? '12px' : '10px')};
  line-height: ${({ subTag }) => (subTag ? '16px' : '12px')};
  color: ${({ subTag }) => (subTag ? 'var(--primaryBlack70)' : 'var(--primaryBlack)')};
  margin-left: ${({ subTag }) => (subTag ? '4px' : '8px')};
`;

export const TableCell = styled(FlexEndContainer)`
  padding: 4px;

  ${({ debitOrCredit }) =>
    !debitOrCredit &&
    `
    cursor: pointer;
    
    &:hover {
      background: var(--primaryBlue10);
      transition: all 0.3s;
    }
  `}
`;

const CellValue = styled.span`
  color: ${({ subRow }) => (subRow ? 'var(--primaryBlack70)' : 'var(--primaryBlack)')};
`;

const Indicator = ({ lastValue, currentValue }) => {
  let icon;
  if (lastValue < currentValue) {
    icon = DataIncreasingIcon;
  } else if (lastValue > currentValue) {
    icon = DataDecreasingIcon;
  } else {
    icon = DataStableIcon;
  }
  return <img src={icon} alt="icon" />;
};

export const getColumns = ({
  data = {},
  handleModalOpen,
  accountingCurrencies,
  setShowCustomersWithDeferred,
  setShowCustomersWithAccrued,
  setShowCustomersWithInvoiced,
  setShowCustomersWithAccountingRevenue,
  setModalGLIntegrationId,
}) => {
  const result = [];

  result.push({
    Header: '',
    accessor: 'title',
    width: 220,
    Cell: ({ row, cell: { value } }) => {
      const subRow = row.original.subRow;
      const isGLRow = row.original.isGLRow;
      return (
        <TitleColumn style={{ paddingTop: 4 }}>
          {subRow && !row.original.debitOrCredit && <SubcategoryArrowIcon style={{ marginLeft: 12 }} />}
          {row.original.debitOrCredit && (
            <CreditOrDebitLabel value={row.original.debitOrCredit}>{row.original.debitOrCredit}</CreditOrDebitLabel>
          )}
          <TableRowTag subTag={subRow} isGLRow={isGLRow}>
            {subRow ? value : value.toUpperCase()}
          </TableRowTag>
        </TitleColumn>
      );
    },
  });

  const monthKeys = generateMonths({
    startDate: new Date(`${data.startMonth}-15`),
    endDate: new Date(`${data.endMonth}-15`),
    inclusive: true,
  });
  for (const [index, monthKey] of Object.entries(monthKeys)) {
    result.push({
      Header: reFormatDate(monthKey, 'YYYY-MM', 'MMM YY'),
      accessor: monthKey,
      id: monthKey,
      Cell: ({ row }) => {
        const isHeader = row.original.isHeader;
        if (isHeader) return null;

        const rowTitle = row.original.title;
        const rowKey = row.original.rowKey;
        const isGLRow = row.original.isGLRow;
        const debitOrCredit = row.original.debitOrCredit;
        const glId = row.original.glId;

        const subRow = row.original.subRow;
        const lastValue = row.original.data?.[getPreviousMonth(monthKey)] ?? 0;
        const currentValue =
          row.original.data?.[monthKey]?.[rowTitle] ??
          (typeof row.original.data?.[monthKey] === 'number' ? row.original.data?.[monthKey] : 0) ??
          0;
        return (
          <TableCell
            data-cy={`accounting-table__value-cell__${rowTitle}__${glId}__${monthKey}`}
            gap="4px"
            debitOrCredit={debitOrCredit}
            className={isGLRow ? 'has-drilldown' : ''}
            onClick={
              !debitOrCredit
                ? () => {
                    setModalGLIntegrationId(glId);

                    setShowCustomersWithDeferred(false);
                    setShowCustomersWithAccrued(false);
                    setShowCustomersWithInvoiced(false);
                    setShowCustomersWithAccountingRevenue(false);
                    if (rowKey.includes('Deferred Revenue')) {
                      setShowCustomersWithDeferred(true);
                    } else if (rowKey.includes('Accrued Revenue')) {
                      setShowCustomersWithAccrued(true);
                    } else if (rowKey.includes('Invoiced')) {
                      setShowCustomersWithInvoiced(true);
                    } else if (rowKey.includes('Accounting Revenue')) {
                      setShowCustomersWithAccountingRevenue(true);
                    }
                    handleModalOpen({ monthKey, rowTitle: `${rowKey.split('.')[0]} (${rowTitle})` });
                  }
                : null
            }
          >
            <CellValue subRow={subRow}>
              {numberFormatter({
                type: row.original.dataType,
                rawValue: currentValue,
                addSign: row.original.addSign,
                currency: accountingCurrencies?.[row.original.glId]?.value,
                minimumFractionDigits: 2,
                decimalPlaces: 3,
              })}
            </CellValue>
            {index !== '0' &&
              !subRow &&
              Indicator({
                lastValue,
                currentValue,
              })}
          </TableCell>
        );
      },
    });
  }

  return result;
};
