import React, { useContext, useMemo } from 'react';
import { Spacer } from 'components/Core';
import { SelectDropdownButton } from 'components/Buttons';
import { CardChartModalContext } from '../CardChartModalContext';
import { SelectorCard } from './styled';

export const GoalSelector = () => {
  const { selectedGoal, setSelectedGoal, goalsList } = useContext(CardChartModalContext);

  const options = useMemo(
    () =>
      goalsList.reduce((acc, goal) => {
        acc[goal.id] = goal.name;
        return acc;
      }, []),
    [goalsList],
  );
  const handleSelectGoal = (goalId) => {
    const newSelectedGoal = goalsList.find((goal) => goalId === goal.id);
    if (newSelectedGoal) {
      setSelectedGoal(newSelectedGoal);
    }
  };

  return goalsList.length > 0 ? (
    <SelectorCard>
      <span>Goal:</span>
      <Spacer width="8px" />
      <SelectDropdownButton
        name="goal_dropdown"
        options={options}
        selected={selectedGoal?.id}
        onSelect={handleSelectGoal}
        noMargin={true}
      />
    </SelectorCard>
  ) : (
    <></>
  );
};
