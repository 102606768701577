import React from 'react';
import { CentererVertical } from 'components/Core';
import { ToggleDropdownButton } from 'components/Buttons/ToggleDropdownButton';

export const CUSTOMER_TYPES_OPTIONS = {
  LIVE: 'live',
  PROSPECT: 'prospect',
};

export const TOGGLES = [
  {
    label: 'Customers',
    description: 'Customers with transactions in Subscript',
    value: CUSTOMER_TYPES_OPTIONS.LIVE,
  },
  {
    label: 'Leads',
    description: 'Prospects synced to Subscript with no transactions',
    value: CUSTOMER_TYPES_OPTIONS.PROSPECT,
  },
];

// draftDataFilter, updateDraftDataFilter props come from the Filters component
export const CustomerTypeFilter = ({ draftDataFilter, updateDraftDataFilter }) => (
  <CentererVertical>
    <span>Type: </span>
    <ToggleDropdownButton
      name="filters__customer-type"
      allSelectedLabel={'All Customers Objects'}
      toggles={TOGGLES}
      selected={draftDataFilter?.customerTypes}
      onSelect={(selectedToggles) => updateDraftDataFilter({ customerTypes: selectedToggles })}
    />
  </CentererVertical>
);
