import { useContext } from 'react';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { AppContext } from 'AppContext';
import { FlexBetweenContainer, Spacer, VerticalLine } from 'components/Core';
import { FillDot, PlusSignIcon } from 'components/Icons';
import { NUMBER_FORMATS } from 'consts/global';
import { numberFormatter } from 'utils/formatters';
import {
  ColumnWrapper,
  ColumnMain,
  ColumnHeader,
  ColumnHeaderSubText,
  ColumnHeaderDescription,
  ColumnFooter,
  StageName,
  StageProbability,
  LastPlusSignWrapper,
} from './PipelineStagesSummary.styles';

const PipelineStagesSummaryWrapper = styled.div`
  padding: 8px 40px;
  background: var(--primaryGray);
`;

const PipelineStagesSummaryContents = styled(FlexBetweenContainer)`
  padding: 20px 0 40px 0;
  overflow-x: scroll;
`;

const PipelineStageColumn = ({
  stageName,
  forecasted_amount,
  description,
  probability,
  isLast,
  showPlusSign = true,
}) => {
  const {
    appSettings: { isARR, currencyISOCode: currency },
  } = useContext(AppContext);

  return (
    <ColumnWrapper>
      <ColumnMain>
        <ColumnHeader>
          <span>{numberFormatter({ type: NUMBER_FORMATS.CURRENCY, rawValue: forecasted_amount, currency })}</span>
          <ColumnHeaderSubText>{isARR ? 'ARR' : 'MRR'}</ColumnHeaderSubText>
        </ColumnHeader>
        <ColumnHeaderDescription>{description}</ColumnHeaderDescription>
        <ColumnFooter>
          <StageName>{stageName}</StageName>
          <StageProbability>
            <Spacer width="8px" />
            <FillDot width={4} height={4} fill="var(--primaryBlack10)" />
            <Spacer width="8px" />
            <span>{numberFormatter({ type: NUMBER_FORMATS.PERCENT, rawValue: probability })}</span>
          </StageProbability>
        </ColumnFooter>
      </ColumnMain>
      {showPlusSign ? (
        isLast ? (
          <LastPlusSignWrapper>
            <VerticalLine height="20px" />
            <PlusSignIcon fill="var(--primaryBlack30)" />
            <VerticalLine height="20px" />
          </LastPlusSignWrapper>
        ) : (
          <PlusSignIcon fill="var(--primaryBlack30)" />
        )
      ) : null}
    </ColumnWrapper>
  );
};

export const PipelineStagesSummary = ({ month, stages, newPipeline = {} }) => {
  const monthLabel = dayjs(`${month}-15`).format('MMM YYYY');

  return (
    <PipelineStagesSummaryWrapper>
      <PipelineStagesSummaryContents>
        {stages
          .sort((a, b) => a.probability - b.probability)
          .map(({ stage_name, forecasted_amount, deals, probability }, index) => (
            <PipelineStageColumn
              key={stage_name ?? `${deals}-${probability}-${forecasted_amount}`}
              stageName={stage_name ?? 'No name'}
              forecasted_amount={forecasted_amount}
              description={`${deals} deal${deals > 1 ? 's' : ''}`}
              probability={probability}
              isLast={index === stages.length - 1}
            />
          ))}
        <PipelineStageColumn
          stageName="New pipeline"
          forecasted_amount={newPipeline.forecasted_amount}
          description={`from ${monthLabel}`}
          probability={newPipeline.probability ?? 0}
          showPlusSign={false}
        />
      </PipelineStagesSummaryContents>
    </PipelineStagesSummaryWrapper>
  );
};
