import styled from 'styled-components';
import { Centerer, Flexer, FlexerColumn } from 'components/Core';

export const ColumnWrapper = styled(Centerer)`
  padding: 0 20px;
  width: 100%;
`;

export const ColumnMain = styled(FlexerColumn)`
  margin-right: 15px;
  flex: 1;
`;

export const ColumnHeader = styled(Flexer)`
  color: var(--primaryBlack);
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 8px;
`;

export const ColumnHeaderSubText = styled.div`
  color: var(--primaryBlack30);
  margin-left: 8px;
`;

export const ColumnHeaderDescription = styled.span`
  color: var(--secondaryYellow);
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
`;

export const ColumnFooter = styled(Flexer)`
  color: var(--primaryBlack50);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin-top: 16px;
  width: 100%;
`;

export const StageName = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const StageProbability = styled(Centerer)`
  flex: 0;
`;

export const LastPlusSignWrapper = styled(FlexerColumn)`
  gap: 6px;
  align-items: center;
`;
