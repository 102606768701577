import React, { useEffect } from 'react';
import { Prompt } from 'react-router-dom';

export const useUnsavedChanges = ({ isModified, currentPathname }) => {
  useEffect(() => {
    if (isModified) {
      window.onbeforeunload = () => true;
    }
    return () => (window.onbeforeunload = null);
  }, [isModified]);

  const shouldShowPrompt = (location) => {
    // Only show the prompt if the user is navigating away from the page
    const isNavigatingAway =
      location.pathname !== currentPathname &&
      // Hack to prevent the prompt from showing twice when navigating between dashboard reports with changes
      // Couldn't figure out why this was happening, but this seems to fix it for now
      (!location.pathname.startsWith('/dashboard') ||
        ((currentPathname.startsWith('/dashboard') || currentPathname === '/') && location.state?.report));

    if (isNavigatingAway) {
      return 'Changes you made may not be saved.\nAre you sure you want to leave this page?';
    }

    return true;
  };

  return {
    Prompt: () => <Prompt when={isModified} message={shouldShowPrompt} />,
  };
};
