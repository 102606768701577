import styled from 'styled-components';
import { Radio } from 'formik-antd';
import { useField } from 'formik';

import { Flexer, FlexerColumn } from 'components/Core';
import { LabelIconContainer } from 'components/Blocks';

import { InputLabel } from '../FormikCustomSelector/styles';
import { RadioStyles } from '../Radio';

const RadioOptionsWrapper = styled(Flexer)`
  flex: 1;
  min-height: ${(props) => props.minHeight ?? '0px'};
`;

export const StyledRadio = styled(Radio)`
  ${RadioStyles}
`;

const RadioLabel = styled.span`
  font-size: 14px;
  line-height: 20px;
  color: var(--primaryBlack);
`;

// using 'Radio' from 'formik-antd' requires the <Radio /> tag to conatin a name attribute or else it generates an 'undefined' key in the values
// reference: https://github.com/jannikbuschke/formik-antd/issues/70
const FormikCustomRadio = ({
  options,
  label,
  handleChange,
  suffix,
  gap,
  flexDirection,
  justifyContent,
  disabled,
  ...props
}) => {
  const meta = useField(props);
  const field = meta[0];
  const helpers = meta[2];

  const onChange = (e) => {
    const value = e.target.value;
    if (handleChange) {
      handleChange(value);
    } else {
      helpers.setValue(value);
    }
  };

  return (
    <Radio.Group data-cy={suffix} disabled={disabled} {...field} onChange={onChange}>
      {label && (
        <LabelIconContainer>
          <InputLabel>{label}</InputLabel>
        </LabelIconContainer>
      )}
      <RadioOptionsWrapper
        gap={gap}
        minHeight={props.minHeight}
        direction={flexDirection}
        justifyContent={justifyContent}
        value={field.value}
        name={props.name}
      >
        {options?.map((option) => (
          <FlexerColumn key={option.value} data-cy={`${suffix}--${option.value}`}>
            <StyledRadio key={option.value} value={option.value} disabled={option.disabled} name={props.name}>
              <FlexerColumn gap="8px">
                <RadioLabel>{option.label}</RadioLabel>
                {option.explainer && <div>{option.explainer}</div>}
              </FlexerColumn>
            </StyledRadio>
          </FlexerColumn>
        ))}
      </RadioOptionsWrapper>
    </Radio.Group>
  );
};

export { FormikCustomRadio };
