import React, { useContext, useEffect, useMemo } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { isNil } from 'lodash';
import { useFormikContext } from 'formik';
import { useCurrencyNumberFormatter } from 'utils/hooks';
import { ReactComponent as NoRecognitionIcon } from 'images/no-recognition-icon.svg';
import { ReactComponent as TillCanceledMoreIcon } from 'images/box.svg';
import { ReactComponent as ArrowRight } from 'images/arrows-bold-right-blue.svg';
import { ReactComponent as PlusIcon } from 'images/item-plus.svg';
import { ReactComponent as MinusIcon } from 'images/item-minus.svg';
import { Flexer } from 'components/Core';
import { RECOGNITION_TYPES, NUMBER_FORMATS, RECURRING_RECOGNITION_TYPES } from 'consts/global';
import { useInvariantCheck } from 'utils/hooks';
import { TransactionContext } from 'shared/TransactionContent/TransactionContext';
import { checkSumOfSchedule } from './invariantChecks';
import { TRANSACTION_MODAL_MODE } from '../../consts';
import {
  RecognitionTable,
  TillCanceledInfo,
  ModalItem,
  RecognitionHeader,
  HeaderItem,
  RowItem,
  NoRecognition,
  RegularRow,
  ItemNumber,
  IncludeLastMonthItem,
  RecognitionBody,
  FlexerColumnGrower,
  ReplacedByInfoLink,
} from './styles';
import { calculateIncludeEndMonth, sortScheduleByDates } from './utils';
import { getOriginalField, SYMBOL_getOriginalField } from '../../utils';
import { includeLastMonthChange } from '../utils';

dayjs.extend(utc);

const RECOGINTIONS_WITH_INCLUDE_END_MONTH = [
  RECOGNITION_TYPES.linear,
  RECOGNITION_TYPES.linearNotRecurring,
  RECOGNITION_TYPES.tillCanceled,
];

export const TransactionRecognitionsSchedule = ({
  startDate,
  endDate,
  recognition,
  amount,
  recurringAmount,
  currency,
  includeEndMonth,
  replacedBy,
  values,
  initialValues,
  isARR,
  transactionId,
  disabled,
}) => {
  const { currentTransaction, mode, changedDataFormatted, replacedByTransaction } = useContext(TransactionContext);
  const numberFormatter = useCurrencyNumberFormatter({ currency });

  const { setFieldValue } = useFormikContext();
  const showLinearRecognitionSummary = useMemo(() => {
    return (
      RECOGINTIONS_WITH_INCLUDE_END_MONTH.includes(recognition) &&
      startDate &&
      endDate &&
      dayjs(startDate).isBefore(dayjs(endDate))
    );
  }, [recognition, startDate, endDate]);

  useEffect(() => {
    if (
      (startDate !== undefined && !dayjs(initialValues.start_date).isSame(startDate, 'day')) ||
      (endDate !== undefined && !dayjs(initialValues.end_date).isSame(endDate, 'day'))
    ) {
      if (mode === TRANSACTION_MODAL_MODE.EXTERNAL_UPDATE) {
        if (getOriginalField({ key: 'end_date', data: changedDataFormatted }) !== SYMBOL_getOriginalField)
          setFieldValue('include_end_month', calculateIncludeEndMonth({ recognition, startDate, endDate }));
      } else {
        setFieldValue('include_end_month', calculateIncludeEndMonth({ recognition, startDate, endDate }));
      }
    }
    // disable line because we don't want to change include last month
    // on recognition changes
    // eslint-disable-next-line
  }, [startDate, endDate, setFieldValue, initialValues.start_date, initialValues.end_date, mode, changedDataFormatted]);

  const showImmediateSummary = useMemo(() => {
    return recognition === RECOGNITION_TYPES.immediate && startDate;
  }, [recognition, startDate]);

  const linearRecognitionMonths = useMemo(() => {
    const diffInMonths = dayjs.utc(endDate).diff(dayjs.utc(startDate).startOf('month'), 'month');
    return includeEndMonth ? diffInMonths + 1 : diffInMonths;
  }, [endDate, startDate, includeEndMonth]);

  const linearRecognitionAmount = useMemo(() => {
    return amount / linearRecognitionMonths;
  }, [amount, linearRecognitionMonths]);

  const showTillCanceledRecognitionSummary = useMemo(() => {
    return (
      recognition === RECOGNITION_TYPES.tillCanceled &&
      startDate &&
      (!endDate || dayjs(startDate).isBefore(dayjs(endDate)))
    );
  }, [endDate, startDate, recognition]);

  const schedule = {};

  if (recognition === RECOGNITION_TYPES.immediate) {
    schedule[dayjs.utc(startDate).format('MMM YYYY')] = [
      {
        type: 'regular',
        amount,
      },
    ];
  }

  if (recognition !== RECOGNITION_TYPES.immediate) {
    // Add spread to schedule for all months
    for (let index = 0; index < linearRecognitionMonths; index++) {
      schedule[dayjs.utc(startDate).add(index, 'month').format('MMM YYYY')] = [
        {
          type: 'regular',
          amount:
            RECURRING_RECOGNITION_TYPES.includes(recognition) && !isNil(recurringAmount)
              ? recurringAmount
              : linearRecognitionAmount,
        },
      ];
    }
  }

  if (recognition === RECOGNITION_TYPES.tillCanceled && !endDate) {
    const numberOfMonthToToday = dayjs().endOf('month').diff(dayjs.utc(startDate).startOf('month'), 'month');

    // Add spread to till canceled without end date
    for (let index = 0; index <= numberOfMonthToToday; index++) {
      schedule[dayjs.utc(startDate).add(index, 'month').format('MMM YYYY')] = [
        {
          type: 'regular',
          amount: recurringAmount,
        },
      ];
    }
  }

  useInvariantCheck({
    readyData: { amount, schedule, recognition, transactionId },
    checkers: [checkSumOfSchedule],
  });

  const rowIsNonReccuring = () =>
    recognition === RECOGNITION_TYPES.immediate || recognition === RECOGNITION_TYPES.linearNotRecurring;

  const isSpreadReplaced = ({ date }) =>
    currentTransaction?.end_date &&
    replacedByTransaction?.start_date &&
    dayjs.utc(date, 'MMM YYYY').isAfter(dayjs.utc(replacedByTransaction.start_date).subtract(1, 'month'));

  return (
    <FlexerColumnGrower>
      {showLinearRecognitionSummary || showTillCanceledRecognitionSummary || showImmediateSummary ? (
        <FlexerColumnGrower>
          <RecognitionHeader>
            <HeaderItem>Month</HeaderItem>
            <HeaderItem style={{ textAlign: 'center' }}>Revenue</HeaderItem>
            <HeaderItem style={{ textAlign: 'center' }}>Type</HeaderItem>
            <HeaderItem style={{ textAlign: 'right' }}>{isARR ? 'ARR' : 'MRR'}</HeaderItem>
            <HeaderItem style={{ textAlign: 'right' }}>One-time</HeaderItem>
          </RecognitionHeader>
          <RecognitionBody>
            <RecognitionTable>
              {Object.entries(schedule)
                .sort((a, b) => sortScheduleByDates({ a, b }))
                .map(([date, value], itemIndex) => (
                  <ModalItem
                    data-cy={`transaction-modal__schedule-month-${date}`}
                    replacedMonth={isSpreadReplaced({ date })}
                    key={date}
                  >
                    {value.map((row, index) => (
                      <RegularRow key={index}>
                        <RowItem>
                          <ItemNumber>{itemIndex + 1}</ItemNumber>
                          {date.slice(0, date.indexOf(':') !== -1 ? date.indexOf(':') : 100)}
                        </RowItem>
                        <RowItem style={{ justifyContent: 'center' }}>
                          <span style={{ color: 'var(--primaryBlack)' }}>
                            {numberFormatter({
                              type: NUMBER_FORMATS.CURRENCY,
                              rawValue: +row.amount,
                              decimalPlaces: 2,
                            })}
                          </span>
                        </RowItem>
                        <RowItem style={{ justifyContent: 'center' }}>
                          {recognition === RECOGNITION_TYPES.immediate ||
                          recognition === RECOGNITION_TYPES.linearNotRecurring
                            ? 'Non-recurring'
                            : 'Recurring'}
                        </RowItem>
                        <RowItem isOpacityRow={rowIsNonReccuring(row)} style={{ justifyContent: 'flex-end' }}>
                          <span>
                            {rowIsNonReccuring(row)
                              ? '0'
                              : numberFormatter({
                                  type: NUMBER_FORMATS.CURRENCY,
                                  rawValue: isARR ? +row.amount * 12 : +row.amount,
                                  decimalPlaces: 2,
                                })}
                          </span>
                        </RowItem>
                        <RowItem isOpacityRow={!rowIsNonReccuring(row)} style={{ justifyContent: 'flex-end' }}>
                          <span>
                            {rowIsNonReccuring(row)
                              ? numberFormatter({
                                  type: NUMBER_FORMATS.CURRENCY,
                                  rawValue: +row.amount,
                                  decimalPlaces: 2,
                                })
                              : '0'}
                          </span>
                        </RowItem>
                      </RegularRow>
                    ))}
                  </ModalItem>
                ))}

              {RECOGINTIONS_WITH_INCLUDE_END_MONTH.includes(recognition) && endDate && !Boolean(replacedBy) && (
                <IncludeLastMonthItem
                  data-cy="transaction__include-last-month-button"
                  disabled={!!replacedBy || disabled}
                  includeEndMonth={includeEndMonth}
                  onClick={() => {
                    includeLastMonthChange(setFieldValue, values)(Boolean(!includeEndMonth));
                  }}
                >
                  <Flexer>
                    {includeEndMonth ? <MinusIcon /> : <PlusIcon />}
                    {includeEndMonth ? 'Exclude' : 'Include'} {dayjs.utc(endDate).format('MMM YYYY')}
                  </Flexer>
                </IncludeLastMonthItem>
              )}

              {recognition === RECOGNITION_TYPES.tillCanceled && !endDate && (
                <TillCanceledInfo>
                  <TillCanceledMoreIcon />
                  <p>More revenue will be generated each month until the end date is set</p>
                </TillCanceledInfo>
              )}

              {Boolean(replacedBy) && (
                <ReplacedByInfoLink to={`/transactions/${replacedBy}`} target="_blank">
                  View the recognition of the transaction that replaced this one
                  <ArrowRight />
                </ReplacedByInfoLink>
              )}
            </RecognitionTable>
          </RecognitionBody>
        </FlexerColumnGrower>
      ) : (
        <NoRecognition>
          <NoRecognitionIcon />
          <div>To automatically generate the spread table, you must fill in all the fields that are located above</div>
        </NoRecognition>
      )}
    </FlexerColumnGrower>
  );
};
