import { InfoBar } from 'components/Blocks';
import { Wrapper } from './SyncedExportInfoBar.styles';

export const SyncedExportInfoBar = ({ syncedExport, onDismiss }) => (
  <Wrapper data-cy="synced-export-info-bar">
    <InfoBar variant="secondary" onDismiss={onDismiss}>
      Auto-syncing page was created. Link for syncing: <a href={syncedExport.url}>{syncedExport.url}</a>
    </InfoBar>
  </Wrapper>
);
