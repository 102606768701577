import React from 'react';
import { FlexerColumn, FlexerRow } from 'components/Core';
import { AITagSolidIcon } from 'components/Icons';
import { TooltipContainer } from 'components/Tooltip';
import styled from 'styled-components';

const TooltipContent = styled(FlexerColumn)`
  gap: 12px;
  align-items: center;
  justify-content: center;

  span {
    color: var(--white70);
    font-size: 9px;
    font-weight: 900;
    text-transform: uppercase;
  }
`;

export const AITooltipContainer = ({ content, isVisible, showTag = true, children }) => (
  <TooltipContainer
    toolTipContent={
      <TooltipContent>
        {content}
        {showTag && (
          <FlexerRow gap="4px" alignItems="center">
            <span>Generated by</span> <AITagSolidIcon />
          </FlexerRow>
        )}
      </TooltipContent>
    }
    fontSize="12px"
    width={260}
    isVisible={isVisible}
    hideArrow
  >
    {children}
  </TooltipContainer>
);
