import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { onSelectPeriod, PERIOD_OPTIONS } from 'shared/Filters';
import { DASHBOARD_PERIOD_OPTIONS } from './consts';

dayjs.extend(isBetween);

export const onSelectDashboardWidgetPeriod = ({ period }) => {
  let startMonth, endMonth;

  switch (period) {
    case DASHBOARD_PERIOD_OPTIONS.thisQuarter:
      startMonth = onSelectPeriod({ period: PERIOD_OPTIONS.thisQuarter })?.startMonth;
      endMonth = onSelectPeriod({ period: PERIOD_OPTIONS.thisQuarter })?.endMonth;
      break;
    case DASHBOARD_PERIOD_OPTIONS.thisYear:
      startMonth = dayjs().startOf('year').toDate();
      endMonth = dayjs().endOf('year').toDate();
      break;
    case DASHBOARD_PERIOD_OPTIONS.thisMonth:
      startMonth = dayjs().startOf('month').toDate();
      endMonth = dayjs().endOf('month').toDate();
      break;
    case DASHBOARD_PERIOD_OPTIONS.thisWeek:
      startMonth = dayjs().startOf('week').toDate();
      endMonth = dayjs().endOf('week').toDate();
      break;
    case DASHBOARD_PERIOD_OPTIONS.today:
      startMonth = dayjs().subtract(1, 'day').toDate();
      endMonth = dayjs().endOf('day').toDate();
      break;
    default:
  }

  return {
    period,
    startMonth,
    endMonth: endMonth ?? new Date(),
  };
};

export const getActivityStartAndEndDates = ({ activityItems, status, startMonth, endMonth }) => {
  return {
    startMonth: activityItems?.[status]?.[activityItems?.[status]?.length - 1]?.date
      ? dayjs(activityItems?.[status]?.[activityItems?.[status]?.length - 1]?.date)
          .subtract(activityItems?.[status]?.length === 1 ? 1 : 0, 'day')
          .toDate()
      : startMonth,
    endMonth: dayjs(activityItems?.[status]?.[0]?.date).toDate() ?? endMonth,
  };
};
