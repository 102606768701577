import React, { useCallback, useContext, useState } from 'react';
import { AppContext } from 'AppContext';
import { ReactComponent as HeaderIcon } from 'images/info-circle-icon.svg';
import { Modal, ModalBody, ModalButton, ModalContainer, ModalFooter, ModalHeader, ModalTitle } from 'components/Modal';
import { pluralize } from 'utils/stringUtils';
import { Loader } from 'components/Loaders';
import { useProductsAPI } from 'api/products';

import { ProductsModalHeaderIcon } from './styles';

const ProductsBulkDeleteModal = ({ products, onClose }) => {
  const { orgId } = useContext(AppContext);
  const {
    isLoading,
    operations: { bulkDelete },
  } = useProductsAPI({ orgId, autoFetch: false });

  const handleConfirmClick = async () => {
    await bulkDelete.mutateAsync({
      orgId,
      productIds: products.map((products) => products.id),
    });
    onClose();
  };

  return (
    <>
      <ModalContainer>
        <Modal maxWidth="350px" height="auto" overflow="visible" compact>
          <ProductsModalHeaderIcon>
            <HeaderIcon width="20px" height="20px" />
          </ProductsModalHeaderIcon>
          <ModalHeader>
            <ModalTitle compact>
              <b>Products Bulk Delete</b>
            </ModalTitle>
          </ModalHeader>
          <ModalBody compact centered>
            <div>
              <b>Do you want to delete {pluralize(products.length, 'product')}?</b>
              <br />
              This cannot be un-done.
            </div>
            {isLoading && <Loader loaderStyles={{ height: 40 }} />}
          </ModalBody>
          <ModalFooter centered transparent>
            <ModalButton onClick={onClose}>No, cancel</ModalButton>
            <ModalButton
              className="primary"
              onClick={isLoading ? undefined : handleConfirmClick}
              disabled={isLoading}
              data-cy="products-bulk-delete-modal-submit"
            >
              Confirm
            </ModalButton>
          </ModalFooter>
        </Modal>
      </ModalContainer>
    </>
  );
};

const useProductsBulkDeleteModal = () => {
  const [products, setProducts] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const openModal = useCallback(() => setShowModal(true), [setShowModal]);
  const closeModal = useCallback(() => setShowModal(false), [setShowModal]);

  const Modal = useCallback(
    () => (showModal ? <ProductsBulkDeleteModal products={products} onClose={closeModal} /> : <></>),
    [products, showModal, closeModal],
  );

  return {
    openModal: (products) => {
      setProducts(products);
      openModal();
    },
    ProductsBulkDeleteModal: Modal,
  };
};

export { useProductsBulkDeleteModal };
