import styled from 'styled-components';
import { StyledInputCSS } from '../styles';

export const RecipientsList = styled.div`
  ${StyledInputCSS}

  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: ${({ padding, smallVersion }) => (smallVersion ? '1px 4px' : padding ?? '12px')};
  padding-bottom: ${({ smallVersion }) => (smallVersion ? '1px' : '0')};
  border-radius: 8px;

  border-color: ${({ isChanged }) => isChanged && 'var(--primaryYellow)'};
  max-height: 200px;
  overflow: auto;
  min-height: ${({ smallVersion }) => (smallVersion ? '40px' : '50px')};
`;

export const RecipientEmail = styled.div`
  font-size: 14px;
  line-height: 20px;
  padding: ${({ smallVersion }) => (smallVersion ? '3px 8px' : '4px 8px')};
  margin: ${({ smallVersion }) => (smallVersion ? '4px 3px' : '0')};
  margin-bottom: ${({ smallVersion }) => (smallVersion ? '4px' : '8px')};
  display: flex;
  align-items: center;
  background: ${({ blue }) => (blue ? 'var(--primaryBlue10)' : 'var(--primaryBlack5)')};
  color: ${({ blue }) => (blue ? 'var(--primaryBlue)' : 'var(--primaryBlack)')};
  cursor: ${({ blue, disabled }) => blue && !disabled && 'pointer'};
  border-radius: 8px;
  margin-right: 8px;

  &:last-child {
    margin-bottom: ${({ disabled }) => !disabled && '12px'};
  }

  &:hover {
    background: ${({ blue, disabled }) => blue && !disabled && 'var(--primaryBlue20)'};

    svg path {
      fill: var(--primaryBlue);
    }
  }

  svg {
    margin-left: 8px;
    width: 16px;
    height: 16px;

    path {
      fill: var(--primaryBlue50);
    }
  }
`;

export const AddEmailsButton = styled.div`
  color: #00152e;
  opacity: 0.3;
  padding: ${({ smallVersion }) => (smallVersion ? '0' : '4px 0')};
  margin: ${({ smallVersion }) => (smallVersion ? '6px 4px' : '0')};
  margin-bottom: ${({ smallVersion }) => (smallVersion ? '6px' : '12px')};
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }
`;

export const StyledAddInput = styled.input`
  height: ${({ smallVersion }) => (smallVersion ? '26px' : '28px')};
  border-radius: 8px;
  background-color: transparent;
  border: 1px solid transparent;
  font-size: 14px;
  line-height: 20px;
  padding: 0 8px;
  margin: ${({ smallVersion }) => (smallVersion ? '4px' : '0')};
  margin-bottom: ${({ smallVersion }) => (smallVersion ? '4px' : '8px')};
  border: 1px solid var(--accentGrayThird);

  &:focus {
    background-color: var(--accentGrayFourth);
    border: 1px solid var(--accentGrayThird);
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.08);
  }

  &:hover {
    background-color: var(--accentGrayFourth);
  }

  ::placeholder {
    color: var(--primaryBlack30);
    font-weight: 900;
    font-size: 10px;
    line-height: 14px;
  }
`;
