import styled from 'styled-components';
import { FlexerRow, FlexBetweenContainer } from 'components/Core';

export const ConfigurationHead = styled(FlexBetweenContainer)`
  padding-top: 20px;
  position: relative;
`;

export const ActiveTab = styled.div`
  display: flex;
  align-items: center;
  margin-left: 12px;
  font-weight: 900;
  color: var(--primaryBlue);
  text-transform: capitalize;
  cursor: pointer;

  &:hover {
    color: var(--secondaryBlue);
  }
`;

export const ConfigurationTitle = styled(FlexerRow)`
  align-items: center;
  font-weight: 400;
  font-size: 30px;
  line-height: 40px;
  display: flex;
`;

export const StyledArrow = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  margin-left: 12px;
  box-shadow: 4px 4px 24px var(--primaryBlack4);
  border-radius: 100px;
  border: 1px solid var(--accentGraySecond);
  transition: all ease 0.4s;
  transform: ${(props) => (props.active ? 'rotate(180deg)' : 'rotate(0deg)')};
`;
