import React, { useContext, useState } from 'react';
import { cssVar } from 'polished';

import { AppContext } from 'AppContext';
import { EVENTS } from 'consts/analytics';
import { useAnalytics, useClickOutside, useToasts } from 'utils/hooks';
import { ReactComponent as ShareIcon } from 'images/bx-share.svg';
import { ReactComponent as RotateIcon } from 'images/rotate-2.svg';
import { ReactComponent as LinkIcon } from 'images/link.svg';
import { ReactComponent as SendIcon } from 'images/send.svg';
import { FlexerColumn } from 'components/Core';
import { IconButton } from 'components/Buttons';
import {
  Popover,
  PopoverWrapper,
  PopoverListItemFull,
  PopoverListItemIconFull,
  PopoverListItemTitleFull,
  PopoverListItemDescriptionFull,
} from 'components/Portal';
import { HEADER_TITLES } from 'shared/Layout';
import { ReportShareModal } from 'shared/ReportShare';
import { ReportSubscribeModal } from 'shared/ReportSubscribe';
import { MembersModal } from 'shared/MembersModal';
import { UsersTooltip } from './styles';

const PAGE_WITH_ONE_TIME_SHARE = [HEADER_TITLES.dashboard, HEADER_TITLES.waterfall];

export const ShareButton = ({ headerTitle, chartRef, report, pageToShare }) => {
  const { organizations } = useContext(AppContext);
  const { trackEvent } = useAnalytics();
  const { pushToast } = useToasts();

  const [showReportSubscribeModal, setShowReportSubscribeModal] = useState(false);
  const [showReportShareModal, setShowReportShareModal] = useState(false);
  const [showMembersModal, setShowMembersModal] = useState(false);
  const onToggleShowMembersModal = () => {
    setShowMembersModal(!showMembersModal);
    setShowDropdown(true);
  };

  const [showDropdown, setShowDropdown] = useState(false);
  const openDropdown = () => {
    trackEvent({
      name: EVENTS.CLICK_SHARE_BUTTON,
      properties: {
        page: headerTitle,
      },
    });
    setShowDropdown(true);
  };
  const dropdownRef = useClickOutside(() => {
    setShowDropdown(false);
  });

  const handleReportSubscribeClick = () => {
    trackEvent({
      name: EVENTS.OPEN_REPORT_SHARE_MODAL,
      properties: {
        page: headerTitle,
      },
    });

    setShowReportSubscribeModal(true);
  };

  const handleReportShareClick = () => {
    trackEvent({
      name: EVENTS.OPEN_REPORT_ONE_TIME_SHARE_MODAL,
      properties: {
        page: headerTitle,
      },
    });

    setShowReportShareModal(true);
  };

  const handleLinkShareClick = () => {
    const url = window.location.href;
    trackEvent({
      name: EVENTS.CLICK_COPY_LINK_TO_PAGE,
      properties: {
        url,
      },
    });
    navigator.clipboard.writeText(window.location.href);
    setShowDropdown(false);
    pushToast('Copied to clipboard successfully', 'success');
  };

  return (
    <>
      <PopoverWrapper ref={dropdownRef}>
        <IconButton
          icon={<ShareIcon />}
          iconFillColor={cssVar('--primaryGreen')}
          onClick={openDropdown}
          data-cy="share-actions__toggler"
        >
          Share
        </IconButton>
        {showDropdown && (
          <Popover data-cy="share-actions__popover" darkBorder width="240px" padding="0" XOffset={-25}>
            {headerTitle === HEADER_TITLES.dashboard ? (
              <PopoverListItemFull
                data-cy="share-actions__popover__item--report"
                gap="8px"
                onClick={handleReportSubscribeClick}
              >
                <PopoverListItemIconFull>
                  <RotateIcon />
                </PopoverListItemIconFull>
                <FlexerColumn gap="4px">
                  <PopoverListItemTitleFull>Subscribe to recurring updates</PopoverListItemTitleFull>
                  <PopoverListItemDescriptionFull>
                    Get your reports with metrics on a recurring basis
                  </PopoverListItemDescriptionFull>
                </FlexerColumn>
              </PopoverListItemFull>
            ) : null}

            {PAGE_WITH_ONE_TIME_SHARE.includes(headerTitle) || PAGE_WITH_ONE_TIME_SHARE.includes(pageToShare) ? (
              <PopoverListItemFull
                data-cy="share-actions__popover__item--page-send"
                gap="8px"
                onClick={handleReportShareClick}
              >
                <PopoverListItemIconFull>
                  <SendIcon height={16} />
                </PopoverListItemIconFull>
                <FlexerColumn>
                  <PopoverListItemTitleFull>Send the page via Email / Slack</PopoverListItemTitleFull>
                </FlexerColumn>
              </PopoverListItemFull>
            ) : null}

            <div style={{ position: 'relative' }}>
              <PopoverListItemFull
                data-cy="share-actions__popover__item--link"
                gap="8px"
                onClick={handleLinkShareClick}
              >
                <PopoverListItemIconFull>
                  <LinkIcon height={16} />
                </PopoverListItemIconFull>
                <FlexerColumn>
                  <PopoverListItemTitleFull>Copy the link to this page</PopoverListItemTitleFull>
                </FlexerColumn>
              </PopoverListItemFull>

              {organizations[0]?.email_domains && (
                <UsersTooltip>
                  Users who sign up with a work email domain become{' '}
                  <span onClick={onToggleShowMembersModal}>members of the org</span>.
                </UsersTooltip>
              )}
            </div>
          </Popover>
        )}
      </PopoverWrapper>

      {showReportSubscribeModal && <ReportSubscribeModal onClose={() => setShowReportSubscribeModal(false)} />}
      {showReportShareModal && (
        <ReportShareModal
          chartRef={chartRef}
          report={report}
          pageToShare={pageToShare}
          headerTitle={headerTitle}
          onClose={() => setShowReportShareModal(false)}
        />
      )}
      {showMembersModal && <MembersModal onClose={onToggleShowMembersModal} />}
    </>
  );
};
