import React from 'react';
import TooltipContainer from './Tooltip';

export const TruncateStringWithTooltip = ({ children, length, tooltipWidth = 180 }) =>
  children?.length > length ? (
    <TooltipContainer width={tooltipWidth} toolTipContent={children}>
      {`${children.substring(0, length)}...`}
    </TooltipContainer>
  ) : (
    <>{children}</>
  );
