import React, { useCallback, useState } from 'react';
import { ReviewBulkInvoicesModal } from './ReviewBulkInvoicesModal';

export const useReviewBulkInvoicesModal = () => {
  const [showModal, setShowModal] = useState(false);
  const [invoicingScheduleIds, setInvoicingScheduleIds] = useState([]);

  const openModal = useCallback(
    ({ invoicingScheduleIds }) => {
      setInvoicingScheduleIds(invoicingScheduleIds);
      setShowModal(true);
    },
    [setInvoicingScheduleIds, setShowModal],
  );

  const closeModal = useCallback(() => {
    setShowModal(false);
  }, [setShowModal]);

  const Modal = useCallback(
    () =>
      showModal ? (
        <ReviewBulkInvoicesModal invoicingScheduleIds={invoicingScheduleIds} closeModal={closeModal} />
      ) : null,
    [showModal, invoicingScheduleIds, closeModal],
  );

  return {
    openModal,
    Modal,
  };
};
