import React, { useContext } from 'react';
import styled from 'styled-components';
import { Centerer } from 'components/Core';
import { BillingContext } from '../BillingContext';

const CountChip = styled(Centerer)`
  padding: 4px;
  position: absolute;
  right: -10px;
  top: -12px;
  background: var(--secondaryYellow);
  border: 2px solid var(--primaryGray);
  border-radius: 100px;
  min-width: 24px;

  font-weight: 900;
  font-size: 9px;
  line-height: 12px;
  text-transform: uppercase;
  color: #ffffff;
`;

export const TransactionsCountChip = () => {
  const { transactionsThatNeedSchedules } = useContext(BillingContext);

  return <CountChip data-cy="transactions-count-chip">{transactionsThatNeedSchedules}</CountChip>;
};
