import styled from 'styled-components';

export const NewBadge = styled.div`
  padding: 4px;
  border-radius: 2px;
  background: var(--primaryGreen);
  font-weight: 900;
  font-size: 8px;
  line-height: 8px;
  text-transform: uppercase;
  color: #fff;
`;
