import { useMutation, useQuery, useQueryClient } from 'react-query';
import { CACHE_KEY as MONTHLY_BREAKDOWN_CACHE_KEY } from 'api/monthlyBreakdown';
import { getLedgerValues, upsertLedgerValue } from './requests';

export const LEDGER_VALUES_KEYS = {
  NET_PROFIT: 'net_profit',
};

const CACHE_KEY = 'getLedgerValues';

export const useLedgerValuesAPI = ({ orgId, month, key, autoFetch = true }) => {
  const queryClient = useQueryClient();

  const { data, error, isLoading, isFetching } = useQuery(
    [CACHE_KEY, orgId, month, key],
    () => getLedgerValues({ orgId, month, key }),
    {
      enabled: autoFetch && !!orgId && !!month && !!key,
    },
  );

  const mutationOptions = {
    onSuccess: () => {
      queryClient.invalidateQueries([CACHE_KEY, orgId, month, key]);
      queryClient.invalidateQueries(MONTHLY_BREAKDOWN_CACHE_KEY);
    },
  };

  const upsertLedgerValueMutation = useMutation(
    ({ orgId, month, key, value }) => upsertLedgerValue({ orgId, month, key, value }),
    mutationOptions,
  );

  return {
    data,
    error,
    isLoading,
    isFetching,
    operations: {
      upsertLedgerValue: upsertLedgerValueMutation.mutateAsync,
    },
  };
};
