import { FlexBetweenContainer } from 'components/Core';
import styled, { css } from 'styled-components';

export const Header = styled(FlexBetweenContainer)`
  padding: 20px 0 10px 0;
`;

export const Title = styled.div`
  font-weight: 300;
  font-size: 30px;
  line-height: 40px;
  color: var(--primaryBlack);
`;

export const ReportName = styled(Title)`
  font-weight: 900;
  padding: 2px 4px;
  border: 1px solid transparent;
  cursor: ${({ editable }) => editable && 'pointer'};

  ${({ editable }) =>
    editable &&
    css`
      &:hover {
        color: rgba(0, 21, 46, 0.75);
        background: rgba(0, 21, 46, 0.03);
        border: 1px solid rgba(0, 21, 46, 0.1);
        border-radius: 8px;
      }
    `}
`;

export const PageWrapper = styled.div`
  padding-top: 10px;
  overflow-x: hidden;
`;
