import { useCallback, useContext, useMemo, useState } from 'react';

import { AppContext } from 'AppContext';
import { TimeLoader, TimeLoaderContainer } from 'components/Loaders';
import { EXTERNAL_UPDATES_SUPPORTED_RESOURCES } from 'views/ExternalUpdates/consts';
import { useExternalUpdatesAPI } from 'api/externalUpdates';
import { generateChanges } from 'views/ExternalUpdates/utils';
import { BillingContext } from 'views/Billing/BillingContext';
import { NoUpdatesGraphic } from 'views/ExternalUpdates/Components/NoUpdatesGraphic';
import { Table } from 'views/ExternalUpdates/Table';
import { useConfirmModal } from 'shared/ConfirmModal';
import { EXTERNAL_UPDATES_TABLE_COLUMNS } from './consts';
import { getTableScheduleData } from './utils';
import { generateColumns } from './columns';

export const SchedulesExternalUpdates = () => {
  const { orgId } = useContext(AppContext);

  const { openInvoiceModal } = useContext(BillingContext);

  const {
    loading,
    data,
    isFetching,
    operations: { bulkResolveUpdates, resolveExternalUpdate },
  } = useExternalUpdatesAPI({
    orgId,
    params: { page: 1, limit: 50000, objectType: EXTERNAL_UPDATES_SUPPORTED_RESOURCES.INVOICING_SCHEDULE },
  });

  const [selectedIds, setSelectedIds] = useState([]);

  const { openConfirmModal: openBulkDismissModal, ConfirmModal: BulkDismissModal } = useConfirmModal({
    title: 'Invoices Auto Confirmation',
    content: (
      <div>
        <b>Do you want to dismiss {selectedIds?.length} changes?</b>
      </div>
    ),
    closeButtonText: 'No, cancel',
    confirmButtonText: 'Yes, dismiss',
    onConfirm: () => {
      bulkResolveUpdates.mutate({ orgId, externalUpdateIds: selectedIds, actionType: 'dismiss' });
    },
  });

  const dataForTable = useMemo(() => {
    const mappedData = data?.data?.map((datum) => {
      const mappedTargetObject = getTableScheduleData(datum?.targetObject);
      const mappedExternalUpdateData = getTableScheduleData(datum?.externalUpdate?.update_data);

      const changedFields = generateChanges({
        source: mappedTargetObject,
        target: mappedExternalUpdateData,
        fields: Object.keys(EXTERNAL_UPDATES_TABLE_COLUMNS),
      });

      const hasDeletedTransactions = datum?.externalUpdate?.update_data?.deletedTransactions?.length > 0;

      const returned = {
        targetObject: { ...datum?.targetObject, ...mappedTargetObject },
        externalUpdate: {
          ...datum?.externalUpdate,
          update_data: mappedExternalUpdateData,
          customer_name: datum?.targetObject?.customer_name,
        },
        changedFields: {},
        hasDeletedTransactions,
      };

      for (const key in changedFields) {
        returned.changedFields[key] = true;
      }

      return returned;
    });

    return mappedData;
  }, [data]);

  const onSelectedRowsChange = useCallback(
    (rowIdsObject) => {
      // Filter out subrows (like 0.0, 15.2 etc), we only want the upper level rows
      const rowIds = Object.keys(rowIdsObject).filter((rowKey) => !rowKey.includes('.'));
      setSelectedIds(rowIds.map((rowId) => data?.data[rowId]?.externalUpdate.id));
    },
    [data, setSelectedIds],
  );

  const columns = useMemo(() => generateColumns({ openInvoiceModal, resolveExternalUpdate }), [
    openInvoiceModal,
    resolveExternalUpdate,
  ]);

  return (
    <div style={{ padding: '0 40px' }}>
      <TimeLoaderContainer isLoading={loading || isFetching || dataForTable === undefined}>
        {loading || isFetching || dataForTable === undefined ? (
          <TimeLoader pageName="externalUpdates" />
        ) : dataForTable?.length > 0 ? (
          <Table
            invoicingSchedulesPage
            data={dataForTable}
            columns={columns}
            onSelectedRowsChange={onSelectedRowsChange}
            onSelectedBulkAction={(action) => {
              if (action === 'dismiss') openBulkDismissModal();
            }}
          />
        ) : (
          <NoUpdatesGraphic />
        )}
      </TimeLoaderContainer>

      <BulkDismissModal />
    </div>
  );
};
