import React from 'react';
import { Flexer } from 'components/Core';
import { DateRangeSelector } from './DateRangeSelector';
import { GrowthTypeSelector } from './GrowthTypeSelector';
import { GoalSelector } from './GoalSelector';

export const CardChartSelectors = () => {
  return (
    <Flexer gap="20px" data-cy="card-chart-modal__selectors">
      <DateRangeSelector />
      <GrowthTypeSelector />
      <GoalSelector />
    </Flexer>
  );
};
