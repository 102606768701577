export const CARD_SIZES = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
  BIG: 'big',
  HUGE: 'huge',
};

export const CARD_SIZES_COLUMN_SIZES_V1 = {
  [CARD_SIZES.HUGE]: 4,
  [CARD_SIZES.BIG]: 2,
  [CARD_SIZES.MEDIUM]: 0,
  [CARD_SIZES.SMALL]: 0,
};

export const CARD_SIZES_ROW_SIZES_V1 = {
  [CARD_SIZES.HUGE]: 3,
  [CARD_SIZES.BIG]: 2,
  [CARD_SIZES.MEDIUM]: 2,
  [CARD_SIZES.SMALL]: 0,
};

export const CARD_SIZES_V1_TO_V2_CONVERTER = {
  [CARD_SIZES.SMALL]: CARD_SIZES.SMALL,
  [CARD_SIZES.MEDIUM]: CARD_SIZES.MEDIUM,
  [CARD_SIZES.BIG]: CARD_SIZES.LARGE,
  [CARD_SIZES.HUGE]: CARD_SIZES.HUGE,
};

export const CARD_SIZES_COLUMN_SIZES_V2 = {
  [CARD_SIZES.HUGE]: 12,
  [CARD_SIZES.BIG]: 12,
  [CARD_SIZES.LARGE]: 6,
  [CARD_SIZES.MEDIUM]: 4,
  [CARD_SIZES.SMALL]: 2,
};

export const CARD_SIZES_ROW_SIZES_V2 = {
  [CARD_SIZES.HUGE]: 3,
  [CARD_SIZES.BIG]: 2,
  [CARD_SIZES.LARGE]: 2,
  [CARD_SIZES.MEDIUM]: 2,
  [CARD_SIZES.SMALL]: 2,
};
