import React, { useContext, useState } from 'react';
import { cssVar } from 'polished';

import { AppContext } from 'AppContext';
import { useForecastsAPI } from 'api/forecasts';
import { ReactComponent as HowWorksIcon } from 'images/lifebuoy.svg';
import { CentererVertical, Spacer } from 'components/Core';
import { IconButton } from 'components/Buttons';
import { GreyDot } from 'components/Icons';
import { CircleLoader } from 'components/Loaders';
import { Header, HeaderTitle, HEADER_TITLES } from 'shared/Layout';
import { SYNC_ENTITIES, SyncEntityButton } from 'shared/Common';
import { SaveText, StyledSaveIcon } from './styles';
import { ForecastingContext } from './ForecastingContext';
import { ForecastingPage } from './ForecastingPage';
import { useHowItWorksModal } from './Components/HowItWorksModal';
import { AddScenarioButton } from './Components/AddScenarioButton';

export const ForecastingContainer = () => {
  const {
    orgId,
    appSettings: { isARR },
  } = useContext(AppContext);
  const {
    data,
    isLoading,
    isFetching,
    error,
    monthlyRecurringRevenue,
    outdatedCount,
    outdatedData,
    isLoadingOutdated,
    operations: { addForecast, editForecast, removeForecast, refetch, fetchOutdatedForecasts },
  } = useForecastsAPI({ orgId, isARR });

  const [highlightedScenario, setHighlightedScenario] = useState();

  const { Modal: HowItWorksModal, openModal: openHowItWorksModal } = useHowItWorksModal();

  return (
    <ForecastingContext.Provider
      value={{
        data,
        monthlyRecurringRevenue,
        outdatedCount,
        outdatedData,
        isLoading,
        isFetching,
        error,
        isLoadingOutdated,
        addForecast,
        editForecast,
        removeForecast,
        refetch,
        fetchOutdatedForecasts,
        highlightedScenario,
        setHighlightedScenario,
      }}
    >
      <Header
        activePage="forecasting"
        headerLeft={
          <>
            <HeaderTitle>{HEADER_TITLES.forecasting}</HeaderTitle>
            <SyncEntityButton entity={SYNC_ENTITIES.PIPELINE} />
          </>
        }
        headerRight={
          <>
            {!isLoading && isFetching && (
              <>
                <CircleLoader width={24} height={24} />
                <Spacer width="10px" />
              </>
            )}
            <CentererVertical>
              <StyledSaveIcon />
              <Spacer width="10px" />
              <SaveText>All changes will be saved automatically</SaveText>
            </CentererVertical>
            <GreyDot spacing="16px" />
            <IconButton
              icon={<HowWorksIcon />}
              iconFillColor={cssVar('--primaryGreen')}
              onClick={openHowItWorksModal}
              data-cy="forecasting__how-it-works"
            >
              How it works
            </IconButton>
            <GreyDot spacing="16px" />
            <AddScenarioButton primary />
          </>
        }
      />
      <ForecastingPage />
      <HowItWorksModal />
    </ForecastingContext.Provider>
  );
};
