import { css } from 'styled-components';

/*
    blueVer is mostly used for the filters section
*/

export const StyledInputCSS = css`
  color: ${(props) => (props.blueVer ? 'var(--primaryBlue)' : 'var(--primaryBlack)')};
  background-color: ${(props) =>
    props.backgroundColor ?? props.disabled
      ? 'var(--accentGrayFourth)'
      : props.blueVer
      ? 'rgba(57, 161, 220, 0.1)'
      : '#fff'};

  border: ${(props) =>
    props.disabled
      ? '1px solid var(--accentGraySecond)'
      : props.isError
      ? '1px solid var(--primaryRed)'
      : props.blueVer
      ? 'none'
      : props.isOpen
      ? '1px solid var(--primaryBlack);'
      : ' 1px solid var(--primaryBlack10);'};

  transition: all 0.3s;

  &:hover {
    border: ${(props) =>
      !props.disabled &&
      (props.isError ? '1px solid var(--primaryRed)' : props.blueVer ? 'none' : '1px solid var(--primaryBlack30)')};
  }

  &:focus {
    border: ${(props) => (props.isError ? '1px solid var(--primaryRed)' : props.blueVer ? 'none' : '1px solid #00000')};
    box-shadow: 3px 3px 10px var(--primaryBlack8);
  }
`;
