import styled from 'styled-components';
import { Centerer } from 'components/Core';

export const Card = styled(Centerer)`
  width: ${({ width }) => width};
  height: ${({ height }) => height};

  background: var(--primaryBlack2);
  border: 1px solid var(--primaryBlack5);
  border-radius: 20px;
`;

export const Title = styled.div`
  font-weight: 900;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
`;

export const Description = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: var(--primaryBlack50);

  a {
    color: var(--primaryGreen);
  }
`;
