import styled from 'styled-components';
import { Centerer } from 'components/Core';

export const MetadataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
`;

export const MetadataHeader = styled(Centerer)`
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const Metadata = styled.div`
  padding: 20px;
  width: 100%;
  height: 100%;
  position: relative;
  min-height: 150px;
  background: var(--accentGrayFourth);
  border: 1px solid var(--accentGraySecond);
  border-radius: 16px;
  overflow: auto;
  height: 80%;
`;

export const MetadataBody = styled.div``;

export const NoMetadataCustomer = styled.div`
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  width: 220px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  span {
    font-size: 14px;
    line-height: 20px;
    opacity: 0.5;
    margin-bottom: 18px;
  }

  div {
    cursor: pointer;
    display: flex;
    align-items: center;
    font-weight: 900;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    color: var(--primaryBlue);

    svg {
      margin-right: 8px;
    }
  }
`;

export const ItemsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
