import { useContext, useState } from 'react';
import { useFormikContext } from 'formik';
import styled from 'styled-components';

import { AppContext } from 'AppContext';
import { Popover, PopoverItemsContainer, PopoverListItem, PopoverWrapper } from 'components/Portal';
import { useClickOutside } from 'utils/hooks';
import { FormikCustomInput } from 'components/Controls';
import { useCreditNoteTitlesAPI } from 'api/billing/hooks';
import { Flexer, FlexerColumn } from 'components/Core';
import { TooltipContainer } from 'components/Tooltip';
import { ReactComponent as RelatedHelpIcon } from 'images/related-help.svg';

import { DEFAULT_CREDIT_NOTE_TITLE } from './consts';

const StyledLabel = styled(Flexer)`
  border: 1px solid var(--neutralGray);
  border-radius: 4px;
  padding: ${({ compact }) => (compact ? '2px' : '4px')};
  color: var(--primaryBlack50);
  font-weight: 900;
  font-size: 9px;
  align-items: center;
`;

const StyledRelatedHelpIcon = styled(RelatedHelpIcon)`
  width: 14px;
  height: 14px;
`;

export const CreditNoteTitleInput = ({ isDisabled }) => {
  const { orgId } = useContext(AppContext);
  const { setFieldValue } = useFormikContext();

  const [showDropdown, setShowDropdown] = useState(false);

  const closeDropdown = () => setShowDropdown(false);
  const openDropdown = () => setShowDropdown(true);

  const dropdownRef = useClickOutside(closeDropdown);

  const { data: titles = [], isLoading, isFetching } = useCreditNoteTitlesAPI({ orgId });

  return (
    <PopoverWrapper ref={dropdownRef}>
      <FormikCustomInput name="title" label="Credit Note Ttile" onClick={openDropdown} isDisabled={isDisabled} />

      {showDropdown && (
        <Popover YOffset={65} width="100%">
          <PopoverItemsContainer>
            {isLoading || isFetching ? (
              <PopoverListItem color="var(--primaryBlack50)">loading...</PopoverListItem>
            ) : (
              <>
                <FlexerColumn padding="12px 12px 4px" width="fit-content">
                  <StyledLabel>LAST USED</StyledLabel>
                </FlexerColumn>
                {titles.map((title) => (
                  <PopoverListItem
                    key={title}
                    onClick={() => {
                      setFieldValue('title', title);
                      closeDropdown();
                    }}
                    gap="8px"
                  >
                    {title}
                    {title === DEFAULT_CREDIT_NOTE_TITLE && (
                      <StyledLabel gap="2px" compact>
                        DEFAULT{' '}
                        <TooltipContainer
                          width={250}
                          fontSize="12px"
                          toolTipContent="Subscript will auto translate this default title to the according language in the PDFs"
                          tooltipWrapperStyles={{ marginBottom: '-3px', marginRight: '-6px' }}
                        >
                          <StyledRelatedHelpIcon />
                        </TooltipContainer>
                      </StyledLabel>
                    )}
                  </PopoverListItem>
                ))}
              </>
            )}
          </PopoverItemsContainer>
        </Popover>
      )}
    </PopoverWrapper>
  );
};
