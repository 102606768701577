import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import { FieldArray, Formik } from 'formik';
import { ReactComponent as AddIcon } from 'images/billing-add-icon.svg';
import { ReactComponent as ArrowUp } from 'images/arrow_up_bold.svg';
import { ReactComponent as DeleteIcon } from 'images/transaction_bill_delete.svg';
import { FormikOnFormChangeEffect } from 'components/Controls';
import { READ_ONLY_METADATA } from 'models/transaction';
import { MetadataSectionActionsModal } from './MetadataSectionActionsModal';

const Metadata = styled.div`
  padding: 14px;
  background: rgba(57, 161, 220, 0.1);
  border-radius: 10px;
  width: 100%;
  margin: 20px 0;
`;

const MetadataHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  svg {
    margin-left: 10px;
  }
`;

const MetadataTitle = styled.div`
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;
  pointer-events: ${(props) => props.disabled && 'none'};

  svg {
    transition: transform ease 0.4s;
    transform: ${(props) => props.isExpand && 'rotate(180deg)'};
  }
`;

const AddButton = styled.div`
  cursor: pointer;
`;

const MetadataBody = styled.div`
  margin-top: 14px;
`;

const ItemsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const MetadataContent = styled.div`
  display: flex;
  flex-wrap: wrap;

  div {
    font-size: 12px;
    line-height: 16px;
  }

  span {
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    color: ${(props) =>
      props.customerView || READ_ONLY_METADATA.includes(props.name) ? 'var(--primaryBlack)' : 'var(--primaryBlue)'};
    margin-right: 4px;
  }
`;

export const MetadataItem = styled.div`
  position: relative;
  padding: 8px 12px;
  border: 1px solid var(--primaryBlack5);
  border-radius: 100px;
  margin-right: 8px;
  margin-bottom: 8px;
  pointer-events: ${(props) => (props.customerView || READ_ONLY_METADATA.includes(props.name)) && 'none'};

  .del-icon {
    position: absolute;
    z-index: 2;
    right: -6px;
    top: -6px;
    display: none;
    cursor: pointer;

    rect {
      fill: var(--primaryRed);
    }

    path {
      fill: white;
    }
  }

  &:hover {
    cursor: pointer;
    background-color: white;
    box-shadow: 4px 4px 28px var(--primaryBlack5);

    .del-icon {
      display: block;
    }
  }
`;

export const METADATA_TYPES = {
  TRANSACTIONS: 'transactions',
  CUSTOMERS: 'customers',
};

const MetadataSection = ({
  initialMetadata,
  handleFormChange,
  organizationId,
  metadataType = METADATA_TYPES.TRANSACTIONS,
}) => {
  const [isExpand, setIsExpand] = useState(false);
  const [actionMetadataItem, setActionMetadataItem] = useState(null);
  const [modalAction, setModalAction] = useState(null);

  const initialValues = initialMetadata
    ? {
        metadataItems: Object.entries(initialMetadata).map(([key, value]) => {
          return {
            key: key,
            value: value,
          };
        }),
      }
    : {
        metadataItems: [],
      };

  return (
    <Metadata>
      <Formik initialValues={initialValues}>
        {({ values, setFieldValue }) => (
          <Fragment key={JSON.stringify(values.metadataItems)}>
            <MetadataHead>
              <MetadataTitle
                data-cy="metadata-exand-button"
                disabled={!Boolean(values.metadataItems.length)}
                isExpand={isExpand}
                onClick={() => setIsExpand(!isExpand)}
              >
                Metadata
                {Boolean(values.metadataItems.length) && <ArrowUp />}
              </MetadataTitle>
              <AddButton
                data-cy="metadata-add-button"
                onClick={() => {
                  setActionMetadataItem(null);
                  setModalAction('create');
                }}
              >
                <AddIcon />
              </AddButton>
            </MetadataHead>
            {isExpand && (
              <MetadataBody>
                <FieldArray name="metadataItems">
                  {({ remove }) => (
                    <ItemsWrapper>
                      {values.metadataItems.length > 0 &&
                        values.metadataItems.map((item, index) => (
                          <MetadataItem name={item.key} key={index} data-cy="metadata-item">
                            <DeleteIcon
                              width={18}
                              height={18}
                              data-cy={`transaction__metadata-delete-button-${item.value}`}
                              className="del-icon"
                              onClick={() => {
                                remove(index);
                              }}
                            />
                            <MetadataContent
                              name={item.key}
                              onClick={() => {
                                setActionMetadataItem(item);
                                setModalAction('edit');
                              }}
                            >
                              <div>{item.key} :</div>
                              <span>{item.value}</span>
                            </MetadataContent>
                          </MetadataItem>
                        ))}
                    </ItemsWrapper>
                  )}
                </FieldArray>
                <FormikOnFormChangeEffect onChange={() => handleFormChange(values)} />
              </MetadataBody>
            )}
            {modalAction && (
              <MetadataSectionActionsModal
                metadataType={metadataType}
                organizationId={organizationId}
                closeModal={(metadataItem) => {
                  setModalAction(null);
                  if (metadataItem) {
                    if (values.metadataItems.some((item) => item.key === metadataItem.key)) {
                      setFieldValue(
                        'metadataItems',
                        values.metadataItems.map((item) => {
                          if (item.key === metadataItem.key) {
                            item.value = metadataItem.value;
                          }
                          return item;
                        }),
                      );
                    } else {
                      setFieldValue('metadataItems', [...values.metadataItems, metadataItem]);
                    }
                  }
                  setIsExpand(true);
                }}
                modalAction={modalAction}
                actionMetadataItem={actionMetadataItem}
              />
            )}
          </Fragment>
        )}
      </Formik>
    </Metadata>
  );
};

export { MetadataSection };
