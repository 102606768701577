const config = process.env
  ? {
      app: {
        API_URL: localStorage.getItem('apiHost') || process.env.REACT_APP_API_URL || 'http://localhost:9000',
        AUTH0_DOMAIN: process.env.REACT_APP_AUTH0_DOMAIN || '',
        AUTH0_CLIENT_ID: process.env.REACT_APP_AUTH0_CLIENT_ID || '',
        ENVIRONMENT: process.env.REACT_APP_ENVIRONMENT,
        MIXPANEL_TOKEN: process.env.REACT_APP_MIXPANEL_TOKEN,
        STRIPE_PUBLIC_KEY: process.env.REACT_APP_STRIPE_PUBLIC_KEY,
        STRIPE_TEST_PUBLIC_KEY: process.env.REACT_APP_STRIPE_TEST_PUBLIC_KEY,
        INVARIANT_CHECK_SLACK_CHANNEL:
          process.env.NODE_ENV === 'production' ? process.env.REACT_APP_INVARIANT_CHECK_SLACK_CHANNEL : 'dev-test',
        GOOGLE_FONTS_API_KEY: process.env.REACT_APP_GOOGLE_FONTS_API_KEY,
      },
    }
  : {};

export default config;
