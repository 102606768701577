import styled from 'styled-components';

export const HeaderToolTip = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  background: #ffffff;
  border: 1px solid #f3f3f3;
  box-shadow: 0 4px 20px rgba(0, 21, 46, 0.1);
  border-radius: 100px;
`;
