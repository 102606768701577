import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { INVOICING_FREQUENCIES } from 'views/Billing/consts';

dayjs.extend(utc);

export const invoicingScheduleDisplayName = ({ invoicingSchedule }) =>
  `Invoice Schedule: ${invoicingSchedule.customer_name ?? invoicingSchedule.customerName} (${dayjs(
    invoicingSchedule.start_date,
  ).format('MMM DD, YYYY')})`;

// We accept all values like annually, quarterly, upFront, as long as they match case insensitively
export const normalizeFrequency = (rawFrequency) =>
  Object.values(INVOICING_FREQUENCIES).find((freq) => freq.toLowerCase() === rawFrequency?.toLowerCase());
