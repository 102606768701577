import React, { Fragment, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import { EVENTS } from 'consts/analytics';
import { AppContext } from 'AppContext';
import { useAnalytics, usePagination, useStateWithStorage } from 'utils/hooks';
import { Filters } from 'shared/Filters';
import { GlobalTogglesContextProvider } from 'shared/GlobalToggles';
import { SelectDropdownButton } from 'components/Buttons';
import { Row } from 'components/Core';
import { GreyDot } from 'components/Icons';
import { useTransactionsAPI } from 'api/transactions';
import { useInvoicingSchedulesAPI } from 'api/billing';
import { REQUIRED_BILLING_PARAMS } from 'api/transactions/hooks';
import { groupBy as groupByFunction } from 'utils/arrayUtils';

import { useReviewBulkInvoicesModal } from './useReviewBulkInvoicesModal';

import {
  groupTransactionsByCRMId,
  groupTransactionsByCustomer,
  groupTransactionsByParentCustomer,
  groupTransactionsByTransactionDate,
} from './utils';
import { ReviewTransactionsTable } from './ReviewTransactionsTable/ReviewTransactionsTable';

const FiltersWrapper = styled.div`
  padding: 0 40px;
  margin-bottom: 16px;
`;

const PAGE_SIZE = 100;

export const GROUP_BY = {
  CUSTOMER: 'Customer',
  PARENT_CUSTOMER: 'Parent Customer',
  TRANSACTION_DATE: 'Transaction Start Date',
  CRM_ID: 'CRM Id',
};

export const SHOW_TYPE = {
  NOT_DISMISSED: 'Non-dismissed',
  ALL: 'All (including dismissed)',
};

const GROUP_TRANSACTIONS_BY_FN = {
  [GROUP_BY.CUSTOMER]: groupTransactionsByCustomer,
  [GROUP_BY.TRANSACTION_DATE]: groupTransactionsByTransactionDate,
  [GROUP_BY.CRM_ID]: groupTransactionsByCRMId,
  [GROUP_BY.PARENT_CUSTOMER]: groupTransactionsByParentCustomer,
};

export const ReviewTransactionsTableContainer = ({ transactionIds, urlState }) => {
  const { orgId, orgConfigs } = useContext(AppContext);
  const { trackEvent } = useAnalytics();
  const [transactionsSelected, setTransactionsSelected] = useState(new Set());
  const [waitingOnBulkCreate, setWaitingOnBulkCreate] = useState();
  const [groupBy, setGroupBy] = useStateWithStorage('review-transactions-table-group-by', GROUP_BY.CUSTOMER);
  const [searchQuery, setSearchQuery] = useState();
  const [showType, setShowType] = useStateWithStorage('review-transactions-table-show-type', SHOW_TYPE.NOT_DISMISSED);
  const { billingInvoiceDefaults } = orgConfigs;
  const [dataForTable, setDataForTable] = useState([]);
  const [showBulkCreateModal, setShowBulkCreateModal] = useState(false);

  const { currentPageIndex, setCurrentPageIndex, pageSize, setPageSize } = usePagination({
    cacheSuffix: 'review-transactions',
    defaultPageSize: PAGE_SIZE,
  });

  const { Modal: ReviewBulkInvoicesModal, openModal: openReviewBulkInvoicesModal } = useReviewBulkInvoicesModal();

  const {
    operations: { bulkAddInvoicingSchedule, dismissTransactions },
  } = useInvoicingSchedulesAPI({ orgId, autoFetch: false });

  const metadataFilter = urlState?.metadataFilter;
  const setMetadataFilter = urlState?.setMetadataFilter;

  const orderBy =
    groupBy === GROUP_BY.CUSTOMER
      ? [
          { column: 'customer_name', order: 'asc' },
          { column: 'start_date', order: 'asc' },
        ]
      : [{ column: 'start_date', order: 'asc' }];

  const { data: transactions, metadata, isFetching } = useTransactionsAPI({
    orgId,
    filters: {
      page: currentPageIndex,
      limit: pageSize,
      params: {
        searchQuery,
        withoutInvoicingSchedule: true,
        confirmed: true,
        includeCount: true,
        includeReplaced: true,
        archived: false,
        includeCustomerInvoicingDetails: true,
        includeCRMExternalId: groupBy === GROUP_BY.CRM_ID,
        isGroupByParent: groupBy === GROUP_BY.PARENT_CUSTOMER,
        includeDismissedInvoicing: showType === SHOW_TYPE.ALL,
        ...REQUIRED_BILLING_PARAMS,
      },
      body: {
        orderBy,
        transactionIds,
      },
    },
    metadataFilter,
    enableToasts: false,
  });

  const onBulkCreateClick = async ({
    paymentOptions,
    daysToPay,
    autoSend,
    frequency,
    invoiceDateRelative,
    invoiceDateReference,
    daysFromDate,
    groupTransactionsBy,
    allowPastInvoices,
    onlyFutureInvoices,
    prorateInvoices,
    integrationId,
    entityId,
    memo,
    defaultMemoTemplateId,
    useParentCustomer,
    useMappingMemo,
  }) => {
    setWaitingOnBulkCreate(true);

    bulkAddInvoicingSchedule
      .mutateAsync({
        transactionIds: Array.from(transactionsSelected),
        paymentOptions,
        daysToPay,
        autoSend,
        frequency,
        invoiceDateRelative,
        invoiceDateReference,
        daysFromDate,
        groupTransactionsBy,
        allowPastInvoices,
        onlyFutureInvoices,
        prorateInvoices,
        integrationId,
        entityId,
        memo,
        defaultMemoTemplateId,
        useParentCustomer,
        useMappingMemo,
      })
      .then((result) => {
        openReviewBulkInvoicesModal({ invoicingScheduleIds: result.map(({ id }) => id) });
        setTransactionsSelected(new Set());
        setShowBulkCreateModal(false);
      })
      .catch(() => {})
      .finally(() => {
        setShowBulkCreateModal(false);
        setWaitingOnBulkCreate(false);
      });
  };

  const onBulkDismissClick = async () => {
    await dismissTransactions.mutateAsync({ transactionIds: Array.from(transactionsSelected) });
    setTransactionsSelected(new Set());
  };

  const onEmailListChange = useCallback(
    ({ customerId, newEmailList }) => {
      const changeInvoicingDetails = (object) => ({
        ...object,
        invoicingDetails:
          object.customerId === customerId || object.customer_id === customerId
            ? {
                ...object.invoicingDetails,
                contacts: [
                  ...(newEmailList ?? []),
                  ...(Array.isArray(object.invoicingDetails?.contacts)
                    ? object.invoicingDetails.contacts.filter((c) => typeof c !== 'string')
                    : typeof object.invoicingDetails?.contacts === 'string'
                    ? [object.invoicingDetails.contacts]
                    : []),
                ],
              }
            : object.invoicingDetails,
        isSelected: transactionsSelected.has(object.id),
      });

      setDataForTable((currentDataForTable) =>
        currentDataForTable.map((group) => ({
          ...changeInvoicingDetails(group),
          subRows: group.subRows.map(changeInvoicingDetails),
        })),
      );
    },
    [setDataForTable, transactionsSelected],
  );

  const transactionsWithProducts = useMemo(
    () => dataForTable.flatMap((group) => group.subRows ?? []).filter((transaction) => transaction.product_id),
    [dataForTable],
  );

  const transactionsById = useMemo(() => groupByFunction(transactions ?? [], 'id', { uniqueness: true }), [
    transactions,
  ]);

  useEffect(() => {
    trackEvent({ name: EVENTS.VISIT_BILLING_REVIEW_TRANSACTIONS_PAGE });
  }, [trackEvent]);

  useEffect(() => setDataForTable(transactions ? GROUP_TRANSACTIONS_BY_FN?.[groupBy]?.({ transactions }) : []), [
    transactions,
    groupBy,
    setDataForTable,
  ]);

  return (
    <Fragment>
      <FiltersWrapper>
        <GlobalTogglesContextProvider urlState={urlState}>
          <Filters
            hidePeriodSelection
            hideTimeSelection
            showConditions
            showGlobalToggles={false}
            metadataFilter={metadataFilter}
            setMetadataFilter={setMetadataFilter}
          >
            <Row>
              Showing:
              <SelectDropdownButton
                showSelectedDirectly
                name="review-transactions-page__show-type"
                options={SHOW_TYPE}
                selected={showType}
                onSelect={(option) => {
                  setCurrentPageIndex(1);
                  setShowType(SHOW_TYPE?.[option]);
                }}
              />
            </Row>

            <GreyDot width={4} height={4} style={{ marginLeft: 12, marginRight: 12 }} />
            <Row>
              Group by:
              <SelectDropdownButton
                showSelectedDirectly
                name="review-transactions-page__group-by"
                options={GROUP_BY}
                selected={groupBy}
                onSelect={(option) => {
                  setCurrentPageIndex(1);
                  setGroupBy(GROUP_BY?.[option]);
                }}
              />
            </Row>
          </Filters>
        </GlobalTogglesContextProvider>
      </FiltersWrapper>

      <ReviewTransactionsTable
        showBulkCreateModal={showBulkCreateModal}
        setShowBulkCreateModal={setShowBulkCreateModal}
        pageSize={pageSize}
        setPageSize={setPageSize}
        controlledPageCount={metadata?.maxPage}
        totalTransactionsCount={metadata?.count}
        currentPageIndex={currentPageIndex}
        setCurrentPageIndex={setCurrentPageIndex}
        groupBy={groupBy}
        setGroupBy={setGroupBy}
        showType={showType}
        setShowType={setShowType}
        searchQuery={searchQuery}
        onSearchQueryChange={setSearchQuery}
        isFetching={isFetching}
        dataForTable={dataForTable}
        transactionsWithProducts={transactionsWithProducts}
        transactionsSelected={transactionsSelected}
        setTransactionsSelected={setTransactionsSelected}
        billingInvoiceDefaults={billingInvoiceDefaults}
        onBulkDismissClick={onBulkDismissClick}
        onBulkCreateClick={onBulkCreateClick}
        waitingOnBulkCreate={waitingOnBulkCreate}
        onEmailListChange={onEmailListChange}
        transactionsById={transactionsById}
      />
      <ReviewBulkInvoicesModal />
    </Fragment>
  );
};
