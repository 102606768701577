import dayjs from 'dayjs';
import { isNil } from 'lodash';
import { INTEGRATION_TYPES } from 'consts/integrations';
import { SOURCE_TYPES, NAME_MATCHING_SOURCE, INTERNAL_CUSTOMER_ID_SOURCE } from './consts';

// By default just get the first integration source
export const getInitialIdSource = ({ idSources }) => ({
  type: SOURCE_TYPES.EXTERNAL,
  source: Object.keys(idSources)[0],
});

export const getOrgIdSources = ({ integrations, internalSource, suffix }) => {
  const idSources = {};
  integrations.forEach((integration) => {
    if (![INTEGRATION_TYPES.Analytics, INTEGRATION_TYPES.CSV].includes(integration.type))
      idSources[integration.service] = `${integration.service} ${suffix}`;
  });

  // Add internal sources if organization has them
  if (internalSource) idSources[internalSource] = internalSource;

  return idSources;
};

export const findCustomerSubscriptId = ({
  customerId,
  uploadCsvCustomersIdSource,
  customerIdSourceAndType,
  customers,
}) => {
  if ([INTERNAL_CUSTOMER_ID_SOURCE, NAME_MATCHING_SOURCE].includes(customerIdSourceAndType?.source)) {
    return customerId;
  } else if (customerIdSourceAndType?.type === SOURCE_TYPES.INTERNAL) {
    return customers?.find((customer) => customer?.metadata?.[uploadCsvCustomersIdSource] === customerId)?.id;
  } else {
    return customers?.find((customer) => customer.provider_object_id === customerId)?.chiffer_object_id;
  }
};

export const getInvoicesCellsValue = ({ invoices, formRefCurrent, invoiceId, rowIndex }) => {
  const formInvoices = formRefCurrent?.values ?? [];

  const fillInRow = ({ index }) => {
    const formInvoice = formInvoices?.[index];

    const subscriptInvoiceById = invoices?.find((invoice) => invoice.id === (invoiceId ?? formInvoice?.id));

    // Update field names to match invoice schema
    formRefCurrent?.setFieldValue(`[${index}].date`, formInvoice.date || subscriptInvoiceById?.date);
    formRefCurrent?.setFieldValue(
      `[${index}].invoicing_schedule_id`,
      formInvoice.invoicing_schedule_id || subscriptInvoiceById?.invoicing_schedule_id,
    );
    formRefCurrent?.setFieldValue(
      `[${index}].customer_id`,
      formInvoice.customer_id || subscriptInvoiceById?.customer_id,
    );
    formRefCurrent?.setFieldValue(
      `[${index}].service_start`,
      formInvoice.service_start || subscriptInvoiceById?.service_start,
    );
    formRefCurrent?.setFieldValue(
      `[${index}].service_end`,
      formInvoice.service_end || subscriptInvoiceById?.service_end,
    );
    formRefCurrent?.setFieldValue(`[${index}].currency`, formInvoice.currency || subscriptInvoiceById?.currency);
    formRefCurrent?.setFieldValue(`[${index}].metadata`, formInvoice.metadata || subscriptInvoiceById?.metadata);
    formRefCurrent?.setFieldValue(
      `[${index}].invoice_number`,
      formInvoice.invoice_number || subscriptInvoiceById?.invoice_number,
    );
    formRefCurrent?.setFieldValue(`[${index}].send_date`, formInvoice.send_date || subscriptInvoiceById?.send_date);
    formRefCurrent?.setFieldValue(`[${index}].memo`, formInvoice.memo || subscriptInvoiceById?.memo);
    formRefCurrent?.setFieldValue(
      `[${index}].secondary_memo`,
      formInvoice.secondary_memo || subscriptInvoiceById?.secondary_memo,
    );
    formRefCurrent?.setFieldValue(
      `[${index}].external_notes`,
      formInvoice.external_notes || subscriptInvoiceById?.external_notes,
    );
    formRefCurrent?.setFieldValue(
      `[${index}].internal_notes`,
      formInvoice.internal_notes || subscriptInvoiceById?.internal_notes,
    );
    formRefCurrent?.setFieldValue(`[${index}].po_number`, formInvoice.po_number || subscriptInvoiceById?.po_number);
    formRefCurrent?.setFieldValue(`[${index}].language`, formInvoice.language || subscriptInvoiceById?.language);
  };

  if (!isNil(rowIndex)) {
    // Fill in single row
    fillInRow({ index: rowIndex });
  } else {
    // Fill in all rows
    formInvoices.forEach((formInvoice, forEachIndex) => fillInRow({ index: forEachIndex }));
  }
};

export const getFormFinalInvoices = ({
  formInvoices,
  uploadCsvCustomersIdSource,
  subscriptCustomers,
  customerIdSourceAndType,
  organizations,
}) => {
  return formInvoices.map((value) => {
    const finalInvoice = { ...value, organization_id: organizations[0].id };
    if (!value.id || (value.id && value.customer_id)) {
      // Creating or Updating an invoice
      finalInvoice.customer_id = findCustomerSubscriptId({
        customerId: value.customer_id,
        customers: subscriptCustomers,
        customerIdSourceAndType,
        uploadCsvCustomersIdSource,
      });
    }

    // Format dates
    const datesToFormat = ['date', 'service_start', 'service_end', 'send_date'];
    datesToFormat.forEach((dateField) => {
      if (value[dateField]) finalInvoice[dateField] = dayjs(value[dateField]).format('YYYY-MM-DD');
    });

    return finalInvoice;
  });
};
