import * as Yup from 'yup';
import { USAGE_BASED_BILLING_SCHEMES, USAGE_BASED_ENGINE_FREQUENCY } from '../consts';

const tierSchema = Yup.object({
  maximum_units: Yup.number().nullable().typeError('Unit has to be a number'),
  name: Yup.string().nullable(),
  description: Yup.string().nullable(),

  billing_scheme: Yup.string().required().oneOf(Object.values(USAGE_BASED_BILLING_SCHEMES)),
  amount: Yup.number().when('billing_scheme', {
    is: (val) => val !== USAGE_BASED_BILLING_SCHEMES.FORMULA,
    then: Yup.number().required('Please enter an amount').typeError('Amount has to be a number'),
    otherwise: Yup.number().nullable(),
  }),
  amount_formula: Yup.string().when('billing_scheme', {
    is: (val) => val === USAGE_BASED_BILLING_SCHEMES.FORMULA,
    then: Yup.string().required('Formula is required').typeError('Formula is required'),
    otherwise: Yup.string().nullable(),
  }),

  carry_over_unused_units: Yup.boolean().nullable(),
});

export const editPricingModalValidationSchema = Yup.object({
  name: Yup.string().required('Please enter a name'),
  event_name: Yup.string().required('Please enter an event name'),
  product_id: Yup.number().nullable(),
  description: Yup.string().nullable(),
  currency: Yup.string().nullable(),
  invoicing_frequency: Yup.string('Select an interval')
    .required('Select an interval')
    .oneOf(Object.values(USAGE_BASED_ENGINE_FREQUENCY)),
  tiers: Yup.array().of(tierSchema).min(1, 'A pricing plan requires at least one tier').required(),
});

export const PRICING_PLAN_MODAL_MODE = {
  CREATE: 'create',
  EDIT: 'edit',
};

export const initialTiersValue = [
  {
    billing_scheme: USAGE_BASED_BILLING_SCHEMES.PER_UNIT,
    amount: null,
    amount_formula: null,
    carry_over_unused_units: false,
  },
];

export const initialPricingPlanValue = {
  name: '',
  event_name: '',
  invoicing_frequency: USAGE_BASED_ENGINE_FREQUENCY.MONTHLY,
  tiers: initialTiersValue,
};
