import dayjs from 'dayjs';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { AppContext } from 'AppContext';
import { NUMBER_FORMATS } from 'consts/global';
import { numberFormatter } from 'utils/formatters';
import { useCreditNotesAPI } from 'api/billing';
import { useTransactionsAPI } from 'api/transactions';
import { useCreditNoteAPI } from 'api/billing/hooks';
import { transactionDisplayTitle } from 'models/transaction';
import { InvoicingScheduleContext } from './InvoicingScheduleContext';
import { getModalInvoiceStatus } from '../InvoiceModal/utils';
import { CREDIT_NOTE_JOB_STATUS, CREDIT_NOTE_METADATA, INVOICE_STATUSES } from '../consts';
import { CreditNoteContext } from './CreditNoteContext';
import { useConfirmUnallocateInvoiceModal } from './useConfirmUnallocateInvoice';
import { useCreditNoteSendModal } from '../CreditNoteSendModal';

export const CreditNoteContainer = ({
  children,
  customerId,
  onAddCreditNote,
  currency,
  invoices,
  invoicingScheduleId,
}) => {
  const {
    orgId,
    appSettings: { currencyISOCode },
  } = useContext(AppContext);
  const [creditNotes, setCreditNotes] = useState([]);
  const [selectedCreditNoteId, setSelectedCreditNoteId] = useState();
  const creditNoteFormRef = useRef();

  const {
    Modal: ConfirmUnallocateInvoiceModal,
    openModal: openConfirmUnallocateInvoiceModal,
  } = useConfirmUnallocateInvoiceModal();

  const { Modal: CreditNoteSendModal, openModal: openCreditNoteSendModal } = useCreditNoteSendModal();

  const {
    data: creditNotesTransactions,
    isLoading: isCreditNotesTransactionsLoading,
    isFetching: isCreditNotesTransactionsFetching,
  } = useTransactionsAPI({
    orgId,
    filters: {
      limit: 1000,
      params: {
        currency: currency,
        archived: false,
        availableForCreditNote: true,
      },
      body: {
        customerIds: [customerId],
      },
    },
    autoFetch: !!customerId,
  });

  const {
    data: fetchedCreditNotes,
    isLoading: isCreditNotesLoading,
    isFetching: isCreditNotesFetching,
    operations: {
      refetch: refetchCreditNotes,
      addCreditNote,
      editCreditNote,
      removeCreditNote,
      voidCreditNote,
      allocateCreditNote,
      updateExternalCreditNote,
      allocateExternalCreditNote,
    },
  } = useCreditNotesAPI({
    orgId,
    scopes: ['items', 'allocations', 'external_url'],
    customerIds: [customerId],
    orderBy: ['credit_notes.date', 'ASC'],
    invoicingScheduleIds: invoicingScheduleId ? [invoicingScheduleId] : null,
    autoFetch: !!customerId,
  });

  const currentCreditNote = useMemo(
    () => creditNotes?.find((cn) => cn.id === selectedCreditNoteId || cn.unsavedId === selectedCreditNoteId),
    [creditNotes, selectedCreditNoteId],
  );

  const {
    data: creditNoteFromBackend,
    isLoading: isCreditNoteLoading,
    isFetching: isCreditNoteFetching,
    operations: { sendCreditNote },
  } = useCreditNoteAPI({
    orgId,
    creditNoteId: currentCreditNote?.id,
    scopes: ['items', 'allocations', 'external_url'],
  });

  const selectedCreditNote = creditNoteFromBackend ?? currentCreditNote;

  const isCreditNoteProcessing = [CREDIT_NOTE_JOB_STATUS.PROCESSING, CREDIT_NOTE_JOB_STATUS.QUEUED_UP].includes(
    selectedCreditNote?.metadata?.[CREDIT_NOTE_METADATA.JOB_STATUS],
  );

  const { transactionOptions } = useContext(InvoicingScheduleContext);
  const creditNoteTransactionOptions = useMemo(
    () =>
      (creditNotesTransactions ?? [])
        .map((t) => ({
          label: transactionDisplayTitle(t),
          value: t.id,
          transaction: t,
        }))
        .concat(transactionOptions ?? []),
    [creditNotesTransactions, transactionOptions],
  );

  const invoiceOptions = useMemo(() => {
    const options =
      invoices
        ?.filter(
          (invoice) => ![INVOICE_STATUSES.VOID].includes(getModalInvoiceStatus({ invoice })) && invoice.amount > 0,
        )
        .map((invoice) => ({
          label: `${invoice.invoice_number || invoice.id} - ${dayjs
            .utc(invoice.date)
            .format('MMM DD, YYYY')} (${numberFormatter({
            type: NUMBER_FORMATS.CURRENCY,
            rawValue: invoice.amount,
            currency: invoice.currency ?? currency ?? currencyISOCode,
            decimalPlaces: 2,
          })})`,
          value: invoice.id,
        })) ?? [];

    options.unshift({ label: 'None', value: null });

    return options;
  }, [currency, invoices, currencyISOCode]);

  const addDraftCreditNote = (draftCreditNoteData = {}) => {
    const creditNote = {
      unsavedId: `Unsaved ${creditNotes.length + 1}`,
      customer_id: customerId,
      currency,
      date: new Date(),
      items: [
        {
          transaction_id: null,
          product_id: null,
          amount: 0,
          seats: 0,
        },
      ],
      ...draftCreditNoteData,
    };
    setCreditNotes([creditNote, ...creditNotes]);
    setSelectedCreditNoteId(creditNote.unsavedId);
    onAddCreditNote();
  };

  const getEmptyCreditNoteItem = () => ({
    transaction_id: null,
    product_id: null,
    amount: 0,
    seats: 0,
  });

  useEffect(() => {
    if (fetchedCreditNotes) {
      setCreditNotes(fetchedCreditNotes.data ?? []);
      if (!selectedCreditNoteId) {
        setSelectedCreditNoteId(fetchedCreditNotes.data?.[0]?.id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedCreditNotes]);

  return (
    <CreditNoteContext.Provider
      value={{
        invoices,
        creditNotes,
        setCreditNotes,
        selectedCreditNoteId,
        setSelectedCreditNoteId,
        selectedCreditNote,
        loadingCreditNotes:
          isCreditNotesLoading ||
          isCreditNotesFetching ||
          isCreditNotesTransactionsLoading ||
          isCreditNotesTransactionsFetching,
        loadingSelectedCreditNote: isCreditNoteLoading || isCreditNoteFetching,
        sendCreditNote,
        addDraftCreditNote,
        getEmptyCreditNoteItem,
        creditNoteTransactionOptions,
        invoiceOptions,
        refetchCreditNotes,
        addCreditNote,
        editCreditNote,
        removeCreditNote,
        allocateCreditNote,
        voidCreditNote,
        updateExternalCreditNote,
        allocateExternalCreditNote,
        openConfirmUnallocateInvoiceModal,
        openCreditNoteSendModal,
        CreditNoteSendModal,
        isCreditNoteProcessing,
        creditNoteFormRef,
      }}
    >
      {children}
      <ConfirmUnallocateInvoiceModal />
    </CreditNoteContext.Provider>
  );
};
