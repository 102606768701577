import React, { useCallback, useEffect, useState } from 'react';
import dayjs from 'dayjs';

import { FlexBetweenContainer, Flexer, FlexerColumn } from 'components/Core';
import { ArrowBoldDownIcon, ArrowBoldUpIcon } from 'components/Icons';
import { NUMBER_FORMATS } from 'consts/global';
import { humanize } from 'utils/stringUtils';
import { useCurrencyNumberFormatter } from 'utils/hooks';
import { CustomerViewModal } from 'views/Customers/CustomerSingle/CustomerViewModal';
import { ScenarioBucketLabel } from '../Shared/styles';
import {
  Column,
  ColumnsWrapper,
  SectionItemCard,
  SectionItemCardSecondaryText,
  SectionTitle,
  SectionWrapper,
} from './styles';
import { BUCKET_SECTIONS, groupDataByBucket } from './utils';
import { NewPipelineToBeClosedCard } from './NewPipelineToBeClosedCard';
import { ExistingCustomersCard } from './ExistingCustomersCard';
import { RenewalCustomersCard } from './RenewalCustomersCard';
import { NewCustomersCard } from './NewCustomersCard';
import { DragAndDropSection, DraggableItem, useDragAndDropContext } from './DragAndDropComponents';

const BucketSection = ({
  scenarioId,
  month,
  editForecastDetails,
  bucket,
  section,
  sectionData,
  previousBucket,
  onCustomerClick,
  draggingItemData,
}) => {
  const [isExpanded, setIsExpanded] = useState(
    [
      BUCKET_SECTIONS.NEW_PIPELINE_TO_BE_CLOSED,
      BUCKET_SECTIONS.NEW,
      BUCKET_SECTIONS.RENEWAL,
      BUCKET_SECTIONS.UNDER_CONTRACT,
    ].includes(section),
  );
  const numberFormatter = useCurrencyNumberFormatter();
  const toggleIsExpanded = () => setIsExpanded(!isExpanded);

  const sectionCount = section === BUCKET_SECTIONS.NEW_PIPELINE_TO_BE_CLOSED ? 1 : sectionData.length ?? 0;

  const SectionItems = useCallback(() => {
    switch (section) {
      case BUCKET_SECTIONS.NEW_PIPELINE_TO_BE_CLOSED:
        return (
          <NewPipelineToBeClosedCard
            forecastId={scenarioId}
            month={month}
            editForecastDetails={editForecastDetails}
            values={sectionData}
          />
        );

      case BUCKET_SECTIONS.CHURN:
      case BUCKET_SECTIONS.UNDER_CONTRACT:
        return (
          <FlexerColumn gap="4px">
            {sectionData.map((data) => (
              <ExistingCustomersCard
                key={data.customer_id}
                values={data}
                onCustomerClick={onCustomerClick}
                isChurn={section === BUCKET_SECTIONS.CHURN}
              />
            ))}
            {previousBucket && (
              <SectionItemCard>
                + {humanize(section)} from {previousBucket}
              </SectionItemCard>
            )}
          </FlexerColumn>
        );

      case BUCKET_SECTIONS.NEW:
        return (
          <FlexerColumn gap="4px">
            <DragAndDropSection section={section} bucket={bucket} draggingItemData={draggingItemData}>
              {sectionData.map((data, index) => (
                <DraggableItem
                  key={`${data.customer_id}-${index}`}
                  id={data.customer_id}
                  index={index}
                  section={section}
                  bucket={bucket}
                  isDragDisabled={data.forecast_month === null}
                >
                  <NewCustomersCard
                    forecastId={scenarioId}
                    month={month}
                    editForecastDetails={editForecastDetails}
                    values={data}
                    onCustomerClick={onCustomerClick}
                  />
                </DraggableItem>
              ))}
            </DragAndDropSection>
            {previousBucket && <SectionItemCard>+ new deals from {previousBucket}</SectionItemCard>}
          </FlexerColumn>
        );

      case BUCKET_SECTIONS.UPSELL:
        return (
          <FlexerColumn gap="4px">
            {sectionData.map((data) => (
              <RenewalCustomersCard
                key={data.customer_id}
                forecastId={scenarioId}
                month={month}
                editForecastDetails={editForecastDetails}
                values={data}
                onCustomerClick={onCustomerClick}
              />
            ))}
            {previousBucket && (
              <SectionItemCard>
                + {humanize(section)} from {previousBucket}
              </SectionItemCard>
            )}
          </FlexerColumn>
        );

      case BUCKET_SECTIONS.RENEWAL:
        return (
          <FlexerColumn gap="4px">
            <DragAndDropSection section={section} bucket={bucket} draggingItemData={draggingItemData}>
              {sectionData.map((data, index) => (
                <DraggableItem
                  key={data.customer_id}
                  id={data.customer_id}
                  index={index}
                  section={section}
                  bucket={bucket}
                >
                  <RenewalCustomersCard
                    forecastId={scenarioId}
                    month={month}
                    editForecastDetails={editForecastDetails}
                    values={data}
                    onCustomerClick={onCustomerClick}
                  />
                </DraggableItem>
              ))}
            </DragAndDropSection>
            {previousBucket && <SectionItemCard>+ renewal deals from {previousBucket}</SectionItemCard>}
          </FlexerColumn>
        );

      case BUCKET_SECTIONS.FORECASTED_REVENUE_FROM_PREVIOUS_MONTHS:
        return (
          <FlexerColumn gap="4px">
            {Object.entries(sectionData).map(([monthKey, amount]) => (
              <SectionItemCard key={monthKey}>
                <FlexBetweenContainer centerer>
                  <div>{dayjs(`${monthKey}-15`).format('MMM YYYY')}</div>
                  <SectionItemCardSecondaryText>
                    {numberFormatter({ type: NUMBER_FORMATS.CURRENCY, rawValue: amount })}
                  </SectionItemCardSecondaryText>
                </FlexBetweenContainer>
              </SectionItemCard>
            ))}
          </FlexerColumn>
        );

      default:
        return <SectionItemCard>Nothing to Show</SectionItemCard>;
    }
  }, [
    scenarioId,
    month,
    editForecastDetails,
    bucket,
    section,
    sectionData,
    onCustomerClick,
    draggingItemData,
    previousBucket,
    numberFormatter,
  ]);

  // If We are on the first month of the forecast we hide the previous months section
  if (section === BUCKET_SECTIONS.FORECASTED_REVENUE_FROM_PREVIOUS_MONTHS && Object.keys(sectionData).length === 0) {
    return <></>;
  }

  return (
    <SectionWrapper>
      <SectionTitle onClick={toggleIsExpanded}>
        {section !== BUCKET_SECTIONS.FORECASTED_REVENUE_FROM_PREVIOUS_MONTHS && `${sectionCount} `}
        {humanize(section)}
        {(sectionCount > 0 || previousBucket) &&
          (isExpanded ? <ArrowBoldUpIcon size="9px" /> : <ArrowBoldDownIcon size="9px" />)}
      </SectionTitle>
      {isExpanded && <SectionItems />}
    </SectionWrapper>
  );
};

export const ForecastUnweightedBody = ({ scenarioId, data, month, editForecastDetails, editForecastDetailsAsync }) => {
  const [customerIdToShow, setCustomerIdToShow] = useState(null);
  const handleCustomerClick = (customerId) => setCustomerIdToShow(customerId);
  const [bucketedData, setBucketedData] = useState({});
  const { DragAndDropContext, draggingItemData } = useDragAndDropContext({
    scenarioId,
    month,
    editForecastDetailsAsync,
    bucketedData,
    setBucketedData,
  });

  useEffect(() => {
    setBucketedData(groupDataByBucket({ data, month }));
  }, [data, month]);

  return (
    <ColumnsWrapper>
      <DragAndDropContext>
        {Object.entries(bucketedData).map(([key, values]) => (
          <Column key={key} flex={1} order={values.order}>
            <Flexer>
              <ScenarioBucketLabel>{values.name}</ScenarioBucketLabel>
            </Flexer>
            {Object.values(BUCKET_SECTIONS).map((section) => (
              <BucketSection
                key={section}
                bucket={key}
                scenarioId={scenarioId}
                editForecastDetails={editForecastDetails}
                month={month}
                section={section}
                sectionData={values[section]}
                previousBucket={values.previousBucket}
                onCustomerClick={handleCustomerClick}
                draggingItemData={draggingItemData}
              />
            ))}
          </Column>
        ))}
      </DragAndDropContext>
      {customerIdToShow && (
        <CustomerViewModal customerId={customerIdToShow} onClose={() => setCustomerIdToShow(null)} />
      )}
    </ColumnsWrapper>
  );
};
