import React, { useContext, useMemo } from 'react';
import { AppContext } from 'AppContext';
import { useGetAvailableTags } from 'api/billing';
import { DEFAULT_AVAILABLE_TAGS } from 'consts/billing';
import { DefaultTemplate } from './DefaultTemplate/DefaultTemplate';
import { AdditionalPdfSettings } from './AdditionalPdfSettings';
import { MainInvoiceSettings } from './MainInvoiceSettings';
import { BillingEntities } from './BillingEntity';

export const InvoiceSettingsContent = ({ invoicingServices, values, setFieldValue, initialBillingInvoiceDefaults }) => {
  const { orgId } = useContext(AppContext);

  const {
    transactionMetadataTags = [],
    customerMetadataTags = [],
    transactionCustomerMetadataTags = [],
  } = useGetAvailableTags({ orgId });

  const availableTags = useMemo(
    () => [
      ...DEFAULT_AVAILABLE_TAGS,
      ...transactionMetadataTags,
      ...customerMetadataTags,
      ...transactionCustomerMetadataTags,
    ],
    [customerMetadataTags, transactionMetadataTags, transactionCustomerMetadataTags],
  );

  return (
    <>
      <MainInvoiceSettings
        values={values}
        setFieldValue={setFieldValue}
        initialBillingInvoiceDefaults={initialBillingInvoiceDefaults}
        transactionMetadataTags={transactionMetadataTags}
        customerMetadataTags={customerMetadataTags}
        transactionCustomerMetadataTags={transactionCustomerMetadataTags}
      />

      <BillingEntities values={values} setFieldValue={setFieldValue} availableTags={availableTags} />

      <AdditionalPdfSettings />

      <DefaultTemplate
        values={values}
        setFieldValue={setFieldValue}
        initialBillingInvoiceDefaults={initialBillingInvoiceDefaults}
        invoicingServices={invoicingServices}
      />
    </>
  );
};
