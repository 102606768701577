import styled from 'styled-components';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { ReactComponent as FillSquare } from 'images/tooltip_fill_square.svg';
import { RECOGNITION_TYPES } from 'consts/global';
import { minDate, maxDate } from 'utils/dateUtils';
import { GROUP_BY_OPTIONS } from 'shared/TransactionTable/consts';
import { calculateRecurringAmount } from 'models/transaction';

dayjs.extend(utc); //without this, time comparisons get messed up

const ToolTipRow = styled.div`
  display: flex;
  align-items: center;
`;

export const TOOLS_TIP_COLORS = {
  Unconfirmed: 'var(--primaryRed)',
  Confirmed: 'var(--primaryGreen)',
};

export const TransactionToolTipContent = () => (
  <div>
    {Object.keys(TOOLS_TIP_COLORS).map((status) => {
      return (
        <ToolTipRow key={status}>
          <FillSquare fill={TOOLS_TIP_COLORS[status]} />
          <span style={{ marginLeft: 10 }}>{status}</span>
        </ToolTipRow>
      );
    })}
  </div>
);

export const groupTransactions = ({ transactions, groupBy }) => {
  let transactionsGroups = {};
  if (groupBy === GROUP_BY_OPTIONS.CUSTOMER) {
    transactionsGroups = transactions.reduce((acc, transaction) => {
      acc[transaction.customer_id] = acc[transaction.customer_id] || {
        customer_name: transaction.customer_name,
        group_title: transaction.customer_name,
        group_customer_id: transaction.customer_id,
        group_customer_email: transaction.customer_email,
        date: transaction.date,
        earliest_start_date: transaction.start_date,
        latest_end_date: transaction.end_date,
        subRows: [],
      };
      acc[transaction.customer_id].subRows.push(transaction);

      acc[transaction.customer_id].earliest_start_date = minDate(
        acc[transaction.customer_id].earliest_start_date,
        transaction.start_date,
      );

      if (transaction.end_date) {
        acc[transaction.customer_id].latest_end_date = maxDate(
          acc[transaction.customer_id].latest_end_date,
          transaction.end_date,
        );
      }

      return acc;
    }, {});
  } else if (groupBy === GROUP_BY_OPTIONS.CONFIRMED_AT) {
    transactionsGroups = transactions.reduce((acc, transaction) => {
      const confirmedDate = dayjs(transaction.confirmed_at).format('MMM DD, YYYY');

      acc[confirmedDate] = acc[confirmedDate] || {
        group_title: `Confirmed on ${confirmedDate}`,
        confirmed_at: transaction.confirmed_at,
        date: transaction.confirmed_at,
        subRows: [],
      };
      acc[confirmedDate].subRows.push(transaction);
      return acc;
    }, {});
  }

  return Object.values(transactionsGroups);
};

export const addTotalAmountToGroups = (transactionsGroupedByCustomer) => {
  transactionsGroupedByCustomer.forEach((customerGroup) => {
    customerGroup.amount = customerGroup.subRows.reduce((sum, row) => {
      const amountToAdd =
        (row.recognition === RECOGNITION_TYPES.tillCanceled ? row?.recurring_amount : row?.amount) || 0;
      return sum + amountToAdd;
    }, 0);
  });
};

export const addRecRevToGroups = (transactionsGroupedByCustomer) => {
  transactionsGroupedByCustomer.forEach((customerGroup) => {
    customerGroup.recurring_revenue = customerGroup.subRows.reduce((sum, row) => {
      const amountToAdd =
        row?.recognition === RECOGNITION_TYPES.tillCanceled
          ? row?.recurring_amount
          : calculateRecurringAmount({
              startDate: row?.start_date,
              endDate: row?.end_date,
              totalAmount: row?.amount,
              includeEndMonth: row?.include_end_month,
            }) || 0;

      return sum + Number(amountToAdd);
    }, 0);
  });
};

export const getTransactionAmount = ({ recognition, transactionAmount, spreadSum }) => {
  if (recognition === RECOGNITION_TYPES.eventRecurring && transactionAmount === null) {
    return spreadSum ?? 0;
  }

  return transactionAmount;
};

export const isTransactionLocked = ({ transaction, transactionsLockBeforeDate }) => {
  return (
    transactionsLockBeforeDate &&
    transaction.confirmed &&
    (dayjs(transactionsLockBeforeDate).isAfter(dayjs(transaction?.end_date)) ||
      dayjs(transactionsLockBeforeDate).isAfter(dayjs(transaction?.start_date)) ||
      dayjs(transactionsLockBeforeDate).isAfter(dayjs(transaction?.date)))
  );
};

export const populateIsLocked = ({ transactions, transactionsLockBeforeDate }) => {
  return transactions?.map((transaction) => {
    const isLocked = isTransactionLocked({ transaction, transactionsLockBeforeDate });

    return {
      ...transaction,
      is_locked: isLocked,
    };
  });
};
