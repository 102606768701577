import axios from 'axios';
import { API_URL } from 'api/consts';

export const getEntities = async ({ orgId, params }) => {
  const { data } = await axios.get(`${API_URL}/organizations/${orgId}/billing-entities`, { params });
  return data;
};

export const getEntity = async ({ orgId, id }) => {
  const response = await axios.get(`${API_URL}/organizations/${orgId}/billing-entities/${id}`);
  return response.data;
};

export const createEntity = async ({ orgId, body }) => {
  const response = await axios.post(`${API_URL}/organizations/${orgId}/billing-entities`, body);
  return response.data;
};

export const updateEntity = async ({ orgId, body, id }) => {
  const response = await axios.patch(`${API_URL}/organizations/${orgId}/billing-entities/${id}`, body);
  return response.data;
};

export const deleteEntity = async ({ orgId, id }) => {
  const response = await axios.delete(`${API_URL}/organizations/${orgId}/billing-entities/${id}`);
  return response.data;
};
