import styled from 'styled-components';
import rollupBcg from 'images/rollup-explain-bcg.svg';

export const RollUpExplain = styled.div`
  padding: 8px;
  background-color: white;
  border-radius: 8px;
  cursor: pointer;
  font-size: 12px;
  line-height: 16px;
  color: var(--primaryBlack50);
  border: 1px solid var(--accentGraySecond);
  transition: all ease 0.4s;
  background-image: url(${rollupBcg});
  background-repeat: no-repeat;
  background-position: bottom -4px right 8px;
  display: flex;
  font-weight: 400;
  align-items: center;

  span {
    font-weight: 400;
    text-transform: none;
  }

  svg {
    height: 20px;
    width: 20px;
    margin-right: 8px;

    g {
      opacity: 1;
    }

    path {
      fill: var(--primaryBlue);
    }
  }

  &:hover {
    box-shadow: 4px 4px 24px var(--primaryBlack4);
    border: 1px solid var(--neutralGray);
  }
`;

export const CustomOptionTitle = styled.div`
  font-weight: 700;
  color: var(--primaryBlue);
  margin-bottom: 4px;
`;

export const CustomOptionText = styled.div`
  opacity: 0.7;
  margin-bottom: ${({ noMarginBottom }) => !noMarginBottom && '12px'};
  width: 256px;
  font-weight: 400;
  white-space: normal;
  text-transform: none;
`;

export const CustomOptionTextHighlight = styled.span`
  font-style: italic;
  font-weight: 700;
  padding: 0 4px;
  border-radius: 4px;
  background: var(--primaryYellow15);
`;

export const CustomOptionItem = styled.div`
  cursor: pointer;
  padding: 12px;
  font-size: 12px;
  line-height: 16px;
  color: var(--primaryBlack);
  background-color: ${({ current }) => current && 'var(--primaryBlack2)'};

  ${CustomOptionTitle} {
    color: ${({ current }) => current && 'var(--primaryBlack70)'};
  }

  ${CustomOptionTextHighlight} {
    color: ${({ current }) => current && 'var(--primaryBlack70)'};
  }

  .defaultImage {
    display: ${({ current }) => current && 'none'};
  }

  .activeImage {
    display: ${({ current }) => (current ? 'block' : 'none')};
  }

  &:hover {
    background-color: ${({ current }) => !current && 'var(--primaryBlue5)'};
  }
`;
