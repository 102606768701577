import React from 'react';
import styled from 'styled-components';
import {
  ReactTable,
  ReactTableBody,
  ReactTableCell,
  ReactTableHeader,
  ReactTableHeaderColumn,
  ReactTableRow,
  ReactTableHeaderRow,
} from 'components/Table';

const MembersReactTable = styled(ReactTable)`
  margin-bottom: 30px;
`;

const MembersReactTableRow = styled(ReactTableRow)`
  &:first-child {
    border-radius: 12px 12px 0 0;
    border-top: none;
  }

  &:last-child {
    border-radius: 0 0 12px 12px;
    border-bottom: none;
  }
`;

const MembersReactTableHeaderRow = styled(ReactTableHeaderRow)`
  padding-bottom: 10px;
  border-bottom: none;
`;

const MembersReactTableBody = styled(ReactTableBody)`
  border: 1px solid var(--primaryBlack5);
  border-radius: 12px;
`;

const MembersTable = ({ tableProps }) => {
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page } = tableProps;

  return (
    <>
      <MembersReactTable {...getTableProps()}>
        <ReactTableHeader>
          {headerGroups.map((headerGroup) => (
            <MembersReactTableHeaderRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <ReactTableHeaderColumn customWidth={column.width} {...column.getHeaderProps()}>
                  {column.render('Header')}
                </ReactTableHeaderColumn>
              ))}
            </MembersReactTableHeaderRow>
          ))}
        </ReactTableHeader>
        <MembersReactTableBody {...getTableBodyProps()} data-cy="members-table">
          {page.map((row) => {
            prepareRow(row);
            return (
              <MembersReactTableRow {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <ReactTableCell customWidth={cell.column.width} {...cell.getCellProps()}>
                    {cell.render('Cell')}
                  </ReactTableCell>
                ))}
              </MembersReactTableRow>
            );
          })}
        </MembersReactTableBody>
      </MembersReactTable>
    </>
  );
};

export { MembersTable };
