import styled from 'styled-components';
import { Flexer } from 'components/Core';

export const HeaderWrapper = styled(Flexer)`
  border-bottom: 1px solid var(--accentGraySecond);

  > div {
    flex: 1;

    &:not(:last-child) {
      border-right: 1px solid var(--accentGraySecond);
    }
  }
`;

export const HugeChartBackground = styled.div`
  height: 100%;
  width: 100%;
  padding: 5px;
  background: var(--primaryBlack2);
  border-radius: 0 0 20px 20px;
`;
