import React from 'react';
import { StyledSelectorPopover, SelectorItem } from './styles';

export const RESOLUTION_OPTIONS = {
  yearly: 'Yearly',
  quarterly: 'Quarterly',
  monthly: 'Monthly',
};

export const ResolutionSelectorPopover = ({ show, currentSelection, onSelect }) => {
  return show ? (
    <StyledSelectorPopover width="100px" data-cy="filters__resolution-selector">
      {Object.entries(RESOLUTION_OPTIONS).map(([key, value]) => (
        <SelectorItem key={key} current={currentSelection === value} onClick={() => onSelect(value)}>
          {value}
        </SelectorItem>
      ))}
    </StyledSelectorPopover>
  ) : (
    <></>
  );
};
