import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTable, useExpanded, useSortBy, useGlobalFilter } from 'react-table';
import { ReactComponent as TotalIcon } from 'images/sort-descending.svg';
import {
  ReactTable,
  ReactTableBody,
  ReactTableCell,
  ReactTableHeader,
  ReactTableHeaderColumn,
  ReactTableHeaderRow,
  ReactTableRow,
  SortIconWrapper,
  customGlobalFilter,
  HeaderCellWrapper,
} from 'components/Table';

const RenewalReactTableRow = styled(ReactTableRow)`
  &:hover {
    background-color: ${(props) => (props.noRowHover ? 'initial' : 'rgba(0, 21, 46,0.03)')};
  }
`;

export const Table = ({ columns, data }) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, toggleAllRowsExpanded } = useTable(
    {
      columns,
      data,
      initialState: {
        hiddenColumns: ['customerName'],
        sortBy: [
          {
            id: 'customerName',
          },
        ],
      },
      globalFilter: customGlobalFilter,
    },
    useGlobalFilter,
    useSortBy,
    useExpanded, // Use the useExpanded plugin hook
  );

  const [showSettingsPopover, setShowSettingsPopover] = useState(false);

  //Expand all rows by default
  useEffect(() => {
    toggleAllRowsExpanded(true);
  }, [data, toggleAllRowsExpanded]);

  return (
    <ReactTable {...getTableProps()}>
      <ReactTableHeader>
        {headerGroups.map((headerGroup) => {
          return (
            <ReactTableHeaderRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <ReactTableHeaderColumn
                  alignRight={column?.alignRight}
                  customWidth={column.width}
                  onClick={() => {
                    if (column.id === 'actions') {
                      setShowSettingsPopover(!showSettingsPopover);
                    }
                  }}
                  {...column.getHeaderProps(
                    column.id !== 'actions' && column.getSortByToggleProps({ title: undefined }),
                  )}
                >
                  <HeaderCellWrapper isSortable={column.canSort && !column.disableSortBy} isSorted={column.isSorted}>
                    {column.render('Header')}

                    {column.isSorted && (
                      <SortIconWrapper isSortedDesc={column.isSortedDesc}>
                        <TotalIcon />
                      </SortIconWrapper>
                    )}
                  </HeaderCellWrapper>
                </ReactTableHeaderColumn>
              ))}
            </ReactTableHeaderRow>
          );
        })}
      </ReactTableHeader>
      <ReactTableBody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <RenewalReactTableRow
              noRowHover={row.canExpand}
              subRow={row.depth}
              noBorderBottom={row.canExpand && row.isExpanded}
              {...row.getRowProps()}
            >
              {row.cells.map((cell) => (
                <ReactTableCell customWidth={cell.column.width} {...cell.getCellProps()}>
                  {cell.render('Cell')}
                </ReactTableCell>
              ))}
            </RenewalReactTableRow>
          );
        })}
      </ReactTableBody>
    </ReactTable>
  );
};
