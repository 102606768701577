import styled from 'styled-components';
import { ReactComponent as DeleteIcon } from 'images/billing_table_remove_button_icon.svg';
import { CancelButton, SubmitButton } from 'components/Buttons';
import { Centerer, Flexer, FlexerColumn } from 'components/Core';
import { ModalContainer } from 'components/Modal';
import { SizedLoader } from 'components/Loaders';

const LoaderWrapper = styled(Centerer)`
  margin-bottom: 20px;
`;

const DeleteModal = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  background: #ffffff;
  box-shadow: 0px 30px 120px rgba(0, 21, 46, 0.4);
  border-radius: 20px;
  padding-top: 30px;
  width: 440px;
`;

const ButtonsRow = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 20px 50px;
  margin-top: 30px;
  background: var(--accentGraySecond);
  border-top: 1px solid #d0d0d0;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
`;

const ModalTitle = styled.div`
  font-size: 20px;
  line-height: 26px;
  margin-bottom: 16px;
  padding: 0 50px;

  b {
    font-weight: 900;
  }
`;

const DeleteText = styled.div`
  font-size: 16px;
  line-height: 22px;
`;

const ModalBody = styled(FlexerColumn)`
  padding-left: 30px;
  padding-right: 30px;
  gap: 8px;
`;

const StyledDeleteIcon = styled(DeleteIcon)`
  margin-left: 10px;
`;

export const AdditionalModalBody = styled(Flexer)`
  background-color: var(--primaryRed5);
  padding: 16px;
  border-radius: 8px;
  gap: 16px;
`;

export const BodyHeader = styled.span`
  font-size: 14px;
  font-weight: 900;
`;

export const BodyDescription = styled.span`
  font-size: 12px;
  font-weight: 700;
  color: var(--primaryBlack70);
`;

export const DeleteConfirmationModal = ({
  modalName,
  modalTitle,
  modalDescription,
  additionalModalBody = null,
  resourceType,
  resourceName,
  isLoading,
  onClose,
  removeButtonText,
  onDeleteConfirm,
}) => (
  <ModalContainer data-cy={modalName}>
    <DeleteModal>
      <ModalTitle>
        <b>{modalTitle ?? `Remove ${resourceName}`}</b>
      </ModalTitle>
      {isLoading ? (
        <LoaderWrapper>
          <SizedLoader size={40} />
        </LoaderWrapper>
      ) : (
        <>
          <ModalBody>
            <DeleteText>
              {modalDescription ?? `Do you really want to remove this ${resourceType}? This cannot be un-done.`}
            </DeleteText>
            {additionalModalBody}
          </ModalBody>
          <ButtonsRow>
            <CancelButton onClick={onClose}>Cancel</CancelButton>
            <SubmitButton onClick={onDeleteConfirm} data-cy={`${modalName}__delete-button`} isRemove>
              {removeButtonText ?? 'Remove'} <StyledDeleteIcon />
            </SubmitButton>
          </ButtonsRow>
        </>
      )}
    </DeleteModal>
  </ModalContainer>
);
