import React from 'react';
import styled from 'styled-components';
import { ReactComponent as RowActionsIcon } from 'images/row_actions.svg';
import { usePortal } from 'components/Portal';
import { CircleXIcon, EditCircleIcon, PillCheck } from 'components/Icons';

const RowActionToogle = styled.div`
  height: 28px;
  cursor: pointer;
`;

const StyledRowActionsIcon = styled(RowActionsIcon)`
  path {
    fill-opacity: 0;
  }

  circle {
    fill: var(--primaryBlack50);
  }

  &:hover {
    path {
      fill-opacity: 0.05;
    }

    circle {
      fill: var(--primaryBlack);
    }
  }
`;

const ActionPopover = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100px;
  background: white;
  border: 1px solid var(--accentGray);
  box-shadow: 8px 8px 40px var(--primaryBlack10);
  border-radius: 12px;
  padding: 0 6px;
  z-index: 5;
`;

const ActionItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 50%;
  padding: 6px;
  color: ${(props) => (props.remove ? 'var(--secondaryRed)' : 'var(--primaryBlue)')};
  border-bottom: ${(props) => (props.remove ? 'none' : '1px solid var(--accentGray)')};
  cursor: pointer;

  p {
    width: 44px;
    height: 26px;
    font-weight: 900;
    font-size: 10px;
    line-height: 14px;
    padding: 6px 6px 6px 0;
    margin: 0;
    text-transform: uppercase;
  }

  svg {
    &:path {
      fill: ${(props) => props.remove && 'var(--secondaryRed)'};
      opacity: 1;
    }
  }
`;

export const ACTION_OPTIONS = {
  overwrite: 'overwrite',
  edit: 'edit',
  dismiss: 'dismiss',
};

export const ActionsDropdown = ({ onAction, rowType, dataCyPrefix }) => {
  const { triggerRef, togglePortal, isPortalVisible, hidePortal, Portal } = usePortal({ XOffset: -48, YOffset: -5 });

  const onClickHandler = (actionType) => {
    hidePortal();
    onAction(actionType);
  };

  return (
    <RowActionToogle>
      <StyledRowActionsIcon ref={triggerRef} onClick={togglePortal} data-cy={`${dataCyPrefix}__actions-button`} />
      {isPortalVisible && (
        <Portal>
          <ActionPopover data-cy={`${dataCyPrefix}__actions-popover`}>
            <ActionItem
              onClick={() => onClickHandler(ACTION_OPTIONS.overwrite)}
              data-cy={`${dataCyPrefix}__actions-select--confirm`}
            >
              <PillCheck /> <p>CONFIRM</p>
            </ActionItem>

            {rowType !== 'resync' && rowType !== 'delete' && (
              <ActionItem
                onClick={() => onClickHandler(ACTION_OPTIONS.edit)}
                data-cy={`${dataCyPrefix}__actions-select--edit`}
              >
                <EditCircleIcon /> <p>EDIT</p>
              </ActionItem>
            )}

            <ActionItem
              onClick={() => onClickHandler(ACTION_OPTIONS.dismiss)}
              remove
              data-cy={`${dataCyPrefix}__actions-select--skip`}
            >
              <CircleXIcon /> <p>SKIP</p>
            </ActionItem>
          </ActionPopover>
        </Portal>
      )}
    </RowActionToogle>
  );
};
