// useUrlStates wraps a state and returns sets of getter/setter where the getter and setter take
// the URL params into account.
// For example:
//   const [ name, setName ] = useState();
//   const [ age, setAge ] = useState();
//   const urlstates = useUrlStates({ name: [name, setName], age: [age, setAge] });
// When you do this,
//   - urlStates.setName will overwrite the URL so that the name is encoded in the URL.
//   - urlStates.name will look up the URL to see if there is a value encoded in the URL.
// The states are base64-encoded in the `s` URL param.

import { useEffect } from 'react';
import { identity, isEqual } from 'lodash';
import { getUrlParams, replaceUrlParam } from 'utils/urlUtils';

const isNotEqual = (currentState, changes) => !isEqual(currentState, changes);
const assignToNew = (_currentState, changes) => changes;

export const urlDecode = (encodedString) => {
  if (!encodedString) return {};
  try {
    return JSON.parse(window.atob(encodedString));
  } catch (e) {
    return {};
  }
};

const urlEncode = (dict) => window.btoa(JSON.stringify(dict, null, 0));

const syncLocalStatesWithUrl = (localStates, urlStates) => {
  Object.entries(localStates).forEach(([key, value]) => {
    const [getter, setter, serializer = identity, deserializer = identity] = value;
    if (!isEqual(serializer(getter), urlStates[key])) {
      // [JB 2022-01-13] If the state is being set for the first time (not in the url), let's use the default in local
      setter(deserializer(urlStates[key] ?? getter));
    }
  });
};

const syncUrlWithLocalStates = (localStates, globalKey) => {
  const urlStates = Object.entries(localStates).reduce((acc, [key, value]) => {
    const [getter, _ignoredSetter, serializer = identity, _ignoredDeserializer] = value;
    acc[key] = serializer(getter);
    return acc;
  }, {});

  replaceUrlParam({ [globalKey]: urlEncode(urlStates) });

  return urlStates;
};

export const useUrlStates = (localStates, options = {}) => {
  const globalKey = options.globalKey ?? 's';
  const params = getUrlParams();

  // Always use the URL itself as the current state, in case there's multiple useUrlStates floating around
  const syncedStates = params[globalKey]
    ? urlDecode(params[globalKey])
    : syncUrlWithLocalStates(localStates, globalKey);

  useEffect(() => {
    if (params[globalKey]) {
      syncLocalStatesWithUrl(localStates, urlDecode(params[globalKey]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return Object.entries(localStates).reduce((acc, [key, value]) => {
    const [
      getter,
      setter,
      serializer = identity,
      _ignoredDeserializer,
      willUpdateState = isNotEqual,
      updateState = assignToNew,
    ] = value;

    const wrappedSetter = (newStateValue) => {
      if (syncedStates) {
        const newSerializedValue = serializer(newStateValue);
        if (willUpdateState(syncedStates[key], newSerializedValue)) {
          syncedStates[key] = updateState(syncedStates[key], newSerializedValue);
          replaceUrlParam({ [globalKey]: urlEncode(syncedStates) });
        }
      }
      setter(newStateValue);
    };

    acc[key] = [getter, wrappedSetter];
    return acc;
  }, {});
};
