import React from 'react';
import { Spacer } from 'components/Core';
import { WhiteUpArrow, WhiteDownArrow } from 'components/Icons';
import {
  TooltipWrapper,
  TooltipRow,
  TooltipRowText,
  TooltipRowValue,
  TooltipSeparator,
} from 'components/Tooltip/styles';
import { NUMBER_FORMATS } from 'consts/global';
import { useCurrencyNumberFormatter } from 'utils/hooks';
import { PercentageDifferenceWrapper } from './ComparisonTooltipContent.styles';

const PercentageDifference = ({ originalValue, compareValue, dataType, colored }) => {
  const numberFormatter = useCurrencyNumberFormatter();
  const ratioDiff =
    ((dataType === NUMBER_FORMATS.CURRENCY ? originalValue?.toFixed() : originalValue) -
      (dataType === NUMBER_FORMATS.CURRENCY ? compareValue?.toFixed() : compareValue)) /
    compareValue;

  return (
    <PercentageDifferenceWrapper positive={ratioDiff >= 0} colored={colored}>
      {numberFormatter({
        type: NUMBER_FORMATS.PERCENT,
        rawValue: ratioDiff === Infinity ? 1 : ratioDiff,
        decimalPlaces: 2,
      })}
      <Spacer width="4px" />
      {ratioDiff < 0 ? <WhiteDownArrow size="8px" /> : <WhiteUpArrow size="8px" />}
    </PercentageDifferenceWrapper>
  );
};

export const ComparisonTooltipContent = ({
  originalValue,
  compareValue,
  originalLabel,
  compareLabel,
  footerLabel = 'Difference',
  dataType = NUMBER_FORMATS.CURRENCY,
  decimalPlaces = 2,
  colored = true,
}) => {
  const numberFormatter = useCurrencyNumberFormatter();

  return (
    <TooltipWrapper>
      <Spacer height="4px" />
      <TooltipRow>
        <TooltipRowText> {originalLabel}: </TooltipRowText>
        <TooltipRowValue>
          {numberFormatter({ type: dataType, rawValue: originalValue, decimalPlaces: decimalPlaces })}
        </TooltipRowValue>
      </TooltipRow>
      <Spacer height="8px" />
      <TooltipRow>
        <TooltipRowText> {compareLabel}: </TooltipRowText>
        <TooltipRowValue>
          {numberFormatter({ type: dataType, rawValue: compareValue, decimalPlaces: decimalPlaces })}
        </TooltipRowValue>
      </TooltipRow>
      <Spacer height="8px" />
      <TooltipSeparator noPadding />
      <Spacer height="8px" />
      <TooltipRow>
        <TooltipRowText> {footerLabel}: </TooltipRowText>
        <TooltipRow>
          <TooltipRowValue>
            {numberFormatter({
              type: dataType,
              rawValue: originalValue - compareValue,
              decimalPlaces: decimalPlaces,
            })}
          </TooltipRowValue>
          <Spacer width="8px" />
          <PercentageDifference
            originalValue={originalValue}
            dataType={dataType}
            compareValue={compareValue}
            colored={colored}
          />
        </TooltipRow>
      </TooltipRow>
      <Spacer height="6px" />
    </TooltipWrapper>
  );
};
