import React, { useContext, useMemo } from 'react';
import { cssVar } from 'polished';
import { Bar, defaults } from 'react-chartjs-2';

import { AppContext } from 'AppContext';
import { RECURRING_RECOGNITION_TYPES, NUMBER_FORMATS } from 'consts/global';
import { amountWithAbbreviation } from 'utils/formatters';
import { reFormatDate } from 'utils/dateUtils';
import { Centerer } from 'components/Core';
import { CHART_COLOR_CURRENT, CHART_COLORS, ChartWrapper, Hint, Label, LabelDot, LabelsWrapper } from './styles';
import { CardWrapper } from '../styles';

export const CustomerChart = ({ currentTransaction, transactionCurrency, data, transactionsMap }) => {
  defaults.global.defaultFontFamily = 'Nunito Sans';
  const {
    appSettings: { isARR, currencyISOCode },
  } = useContext(AppContext);

  const currency = transactionCurrency ?? currencyISOCode;

  const totals = useMemo(
    () =>
      Object.entries(data).reduce((acc, [transactionId, values]) => {
        Object.entries(values).forEach(([key, value]) => {
          const amount =
            value *
            (isARR && RECURRING_RECOGNITION_TYPES.includes(transactionsMap[transactionId]?.recognition) ? 12 : 1);
          if (acc[key]) {
            acc[key] += amount;
          } else {
            acc[key] = amount;
          }
        });
        return acc;
      }, {}),
    [isARR, data, transactionsMap],
  );

  const allMonths = Object.keys(totals).sort();
  const labels = allMonths.reduce((acc, curr, index) => {
    // Always add first and last month
    if (index === 0 || index === allMonths.length - 1) {
      acc.push(curr);
    } else if (totals[curr] !== (totals[allMonths[index - 1]] ?? 0)) {
      acc.push(curr);
    }
    return acc;
  }, []);

  const chartData = useMemo(
    () => ({
      labels: labels,
      datasets: Object.entries(data).map(([transactionId, monthValues], index) => ({
        label: transactionId,
        data: labels.map(
          (key) =>
            (monthValues[key] ?? 0) *
            (isARR && RECURRING_RECOGNITION_TYPES.includes(transactionsMap[transactionId]?.recognition) ? 12 : 1),
        ),
        backgroundColor: transactionId === currentTransaction ? CHART_COLOR_CURRENT : CHART_COLORS[index] ?? '#000',
        type: 'bar',
        barThickness: 16,
      })),
    }),
    [data, currentTransaction, labels, transactionsMap, isARR],
  );

  const totalValues = Object.values(totals);
  const maxValue = Math.max(...totalValues);
  const minValue = Math.min(...totalValues);
  const tenPercentDifference = (maxValue - minValue) / 10;

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    layout: {
      padding: { top: 40, right: 30 },
    },
    tooltips: {
      enabled: false,
    },
    scales: {
      xAxes: [
        {
          stacked: true,
          gridLines: {
            display: false,
          },
          ticks: {
            beginAtZero: true,
            callback: (value) => reFormatDate(value, 'YYYY-MM', 'MM/YYYY'),
          },
        },
      ],
      yAxes: [
        {
          stacked: true,
          gridLines: {
            drawBorder: false,
          },
          ticks: {
            maxTicksLimit: 6,
            suggestedMax: maxValue + tenPercentDifference,
            callback: (value) => amountWithAbbreviation({ value, type: NUMBER_FORMATS.CURRENCY, currency }),
          },
        },
      ],
    },
    plugins: {
      datalabels: {
        offset: '5',
        align: 'end',
        anchor: 'end',
        backgroundColor: cssVar('--accentDarkGray'),
        borderRadius: 4,
        color: 'white',
        font: {
          weight: 'bold',
        },
        formatter: (value, context) =>
          amountWithAbbreviation({ value: totals[labels[context.dataIndex]], type: NUMBER_FORMATS.CURRENCY, currency }),
        display: (context) => context.datasetIndex === Object.keys(data).length - 1,
        padding: 6,
      },
    },
  };

  return (
    <>
      <CardWrapper>
        <ChartWrapper>
          <Hint>Only changes and the last month are shown</Hint>
          <Bar options={options} data={chartData} />
        </ChartWrapper>
      </CardWrapper>
      <LabelsWrapper>
        <Centerer>
          <LabelDot color={CHART_COLOR_CURRENT} />
          <Label>This transaction</Label>
        </Centerer>
        {Object.keys(data).map(
          (key, index) =>
            key !== currentTransaction && (
              <Centerer key={key}>
                <LabelDot color={CHART_COLORS[index]} />
                <Label>{transactionsMap[key]?.name ?? 'No name'}</Label>
              </Centerer>
            ),
        )}
      </LabelsWrapper>
    </>
  );
};
