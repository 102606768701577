import React, { useContext } from 'react';
import styled from 'styled-components';
import { useAuth0 } from '@auth0/auth0-react';
import { Spacer } from 'components/Core';
import { InfoIcon } from 'components/Icons';
import { ReactComponent as ArrowRight } from 'images/arrows-bold-right-transparent-icon.svg';
import { humanize } from 'utils/stringUtils';
import { AppContext } from 'AppContext';
import { GL_INTEGRATION_SERVICES, INTEGRATION_SERVICES, INTEGRATION_TYPES } from 'consts/integrations';
import { getServiceCategory } from 'models/integration';
import { INTERNAL_CUSTOMER_METADATA } from 'models/customer';

const LinkButton = styled.a`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 320px;
  padding: 10px;

  border-radius: 16px;
  border: 1px solid var(--accentGraySecond);

  background-color: white;

  cursor: pointer;

  div {
    text-align: center;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    color: var(--primaryBlue);
  }
`;

const InfoWrapper = styled.div`
  display: flex;
  align-items: center;

  width: 320px;
  padding: 10px;

  border-radius: 16px;
  border: 1px solid var(--accentGraySecond);
  background-color: white;

  div {
    text-align: center;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: var(--primaryBlack50);
  }
`;

// example original URL: 'https://dashboard.stripe.com/customers/cus_FLb5DYmedDcQK7'
// example result URL: 'https://dashboard.stripe.com/acct_1C88ncGYOMUb5Ats/customers/cus_FLb5DYmedDcQK7'
const URL_BASE = 'https://dashboard.stripe.com';
const addPortalId = (customerImport) => {
  const { portal_id: portalId, external_url: externalURL } = customerImport;

  let result = externalURL;
  result = result.replace(URL_BASE, ''); // remove the https part
  result = `/${portalId}` + result; // add in the portal id
  result = URL_BASE + result; // add back in the https part

  return result;
};

export const CustomerImportedButton = ({ customer }) => {
  const { user: thisUser } = useAuth0();
  const { integrations } = useContext(AppContext);
  const selectedGlIntegrationId =
    customer.metadata?.[INTERNAL_CUSTOMER_METADATA.SELECTED_GL_INTEGRATION_ID] ??
    integrations?.find((integration) => integration.type === INTEGRATION_TYPES.GL)?.id;

  const isImported = customer.imports?.length >= 0;

  if (!isImported) {
    return (
      <InfoWrapper>
        <InfoIcon />
        <Spacer width="6px" />
        <div>Customer was manually created in Subscript</div>
      </InfoWrapper>
    );
  }

  return customer.imports.map((customerImport) => {
    const serviceCategory = getServiceCategory(customerImport.provider_name);

    const isGl = GL_INTEGRATION_SERVICES.includes(serviceCategory);

    const shouldHideImport = isGl && customerImport.integration_id !== selectedGlIntegrationId;

    const isStripe = serviceCategory === INTEGRATION_SERVICES.STRIPE;

    const externalURL =
      isStripe && thisUser?.email?.includes('@subscript.com')
        ? addPortalId(customerImport)
        : customerImport.external_url;

    if (customerImport.provider_name.split('.')[0] === 'csv') {
      return (
        <InfoWrapper key={customerImport.id}>
          <InfoIcon />
          <Spacer width="6px" />
          <div>Customer was manually imported to Subscript</div>
        </InfoWrapper>
      );
    }
    return !shouldHideImport ? (
      <LinkButton key={customerImport.id} href={externalURL} target="_blank">
        <div>View Customer on {humanize(customerImport.provider_name)}</div>
        <Spacer width="6px" />
        <ArrowRight />
      </LinkButton>
    ) : null;
  });
};
