import React, { useMemo, useCallback, useState } from 'react';
import { useTable, useRowSelect, usePagination } from 'react-table';

import {
  ReactTable,
  ReactTableRow,
  ReactTableCell,
  ReactTableBody,
  ReactTableHeader,
  ReactTableHeaderColumn,
  ReactTableHeaderRow,
  HeaderCellWrapper,
  TableHeaderActions,
  RowsCount,
} from 'components/Table';
import { Row } from 'components/Core';
import { PaginationButton } from 'components/Buttons';
import { GreyDot } from 'components/Icons';
import { TableColumnSettings } from 'shared/Common';
import { LoadingSpinnerWrapper, SearchContainer } from 'shared/TransactionTable/styles';
import { CircleLoader, TimeLoader } from 'components/Loaders';
import { MultiselectRibbon, useDebouncedSearchBar } from 'components/Blocks';
import { useAIContractReaderModals } from 'views/Contracts';
import { useConfirmModal } from 'shared/ConfirmModal';
import { useContractsAPI } from 'api/contracts';
import { pluralize } from 'utils/stringUtils';

import { generateColumns } from './columns';
import { useAIProcessingModal } from './AIProcessingModal';
import { CONTRACT_STATUS } from '../consts';

const ContractsTable = ({
  orgId,
  data,
  isLoading,
  pageSize,
  setPageSize,
  currentPageIndex,
  setCurrentPageIndex,
  controlledPageCount,
  setSearchQuery,
  rowCount,
  activeTab,
}) => {
  const { Modal: AIProcessingModal, openModal: openAIProcessingModal } = useAIProcessingModal();

  const {
    openContractAIPreviewModalWithData,
    ContractAIPreviewModal,
    ContractTransactionsCreatedModal,
    AIReaderLockedModal,
  } = useAIContractReaderModals();

  const handleSearchQueryChange = useCallback(
    (newSearchQuery) => {
      setSearchQuery(newSearchQuery);
      setCurrentPageIndex(1);
    },
    [setSearchQuery, setCurrentPageIndex],
  );
  const { DebouncedSearchBar } = useDebouncedSearchBar({
    fontSize: '12px',
    onSearchQueryChange: handleSearchQueryChange,
    initialValue: '',
    placeholder: 'Search by ID or pdf name',
    width: '200px',
  });

  const tableData = useMemo(() => data, [data]);

  const {
    operations: { removeContract, bulkDeleteContracts },
  } = useContractsAPI({
    orgId,
    autoFetch: false,
  });

  const [selectedContractId, setSelectedContractId] = useState(null);

  const {
    openConfirmModal: openConfirmDeleteContractModal,
    ConfirmModal: ConfirmDeleteContractModal,
  } = useConfirmModal({
    title: 'Delete transactions?',
    content: 'Do you also want to delete transactions related to this contract?',
    closeButtonText: 'Cancel',
    denyButtonText: 'No',
    confirmButtonText: 'Yes',
    onConfirm: () => {
      removeContract.mutateAsync({ id: selectedContractId, archiveTransactions: true });
    },
    onDeny: () => {
      removeContract.mutateAsync({ id: selectedContractId, archiveTransactions: false });
    },
  });

  const [selectedContractIds, setSelectedContractIds] = useState([]);

  const {
    openConfirmModal: openConfirmDeleteContractsModal,
    ConfirmModal: ConfirmDeleteContractsModal,
  } = useConfirmModal({
    title: 'Delete transactions?',
    content: 'Do you also want to delete transactions related to these contracts?',
    closeButtonText: 'Cancel',
    denyButtonText: 'No',
    confirmButtonText: 'Yes',
    onConfirm: () => {
      bulkDeleteContracts.mutateAsync({ data: { contractIds: selectedContractIds }, archiveTransactions: true });
    },
    onDeny: () => {
      bulkDeleteContracts.mutateAsync({ data: { contractIds: selectedContractIds }, archiveTransactions: false });
    },
  });

  const columns = useMemo(
    () =>
      generateColumns({
        onContractClick: (row) => {
          if (row.original.status === CONTRACT_STATUS.PROCESSING) {
            openAIProcessingModal();
          }
          if (row.original.status === CONTRACT_STATUS.NEEDS_REVIEW) {
            openContractAIPreviewModalWithData({ contract_id: row.original.id, ...row.original.interpreted_values });
          }
        },
        onContractDelete: (row) => {
          setSelectedContractId(row.original.id);
          openConfirmDeleteContractModal();
        },
      }),
    [openAIProcessingModal, openContractAIPreviewModalWithData, openConfirmDeleteContractModal],
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    allColumns,
    page,
    toggleAllRowsSelected,
    state: { selectedRowIds },
  } = useTable(
    {
      columns,
      data: tableData,
      initialState: {
        pageIndex: currentPageIndex,
        pageSize,
      },
      manualPagination: true,
      pageCount: controlledPageCount,
    },
    usePagination,
    useRowSelect,
  );

  return (
    <>
      <TableHeaderActions horizontal="space-between">
        <SearchContainer>
          <DebouncedSearchBar />
        </SearchContainer>
        <Row key={`${currentPageIndex}-${controlledPageCount}`}>
          {rowCount !== 0 && activeTab === CONTRACT_STATUS.PROCESSING && (
            <LoadingSpinnerWrapper data-cy="loader">
              <CircleLoader isAbsolute isInline width="24px" height="24px" thickness="4px" name="contracts-table" />
            </LoadingSpinnerWrapper>
          )}
          <RowsCount>
            <span data-cy="contracts-table__row-count">{pluralize(rowCount ?? 0, 'row')} found</span>
          </RowsCount>
          <GreyDot style={{ marginLeft: 20, marginRight: 20, minWidth: 4, minHeight: 4 }} />

          <PaginationButton
            pageIndex={currentPageIndex}
            canPreviousPage={currentPageIndex > 1}
            canNextPage={currentPageIndex < controlledPageCount}
            pageCount={controlledPageCount}
            nextPage={() => setCurrentPageIndex((prevPageIndex) => prevPageIndex + 1)}
            previousPage={() => setCurrentPageIndex((prevPageIndex) => prevPageIndex - 1)}
          />
          <GreyDot style={{ marginLeft: 20, marginRight: 20, minWidth: 4, minHeight: 4 }} />
          <TableColumnSettings
            showResultsDropdown={true}
            numberValue={pageSize}
            handleShowResultsChange={(option) => setPageSize(option.value)}
            columnsTitles={{
              file_name: 'File Name',
            }}
            allColumns={allColumns}
            resultsLabel={'Results on Page:'}
            tableName="contracts"
          />
        </Row>
      </TableHeaderActions>
      {isLoading ? (
        <TimeLoader />
      ) : (
        <ReactTable pageView={true} data-cy="contracts-table" {...getTableProps()}>
          <ReactTableHeader pageView={true}>
            {headerGroups.map((headerGroup) => {
              return (
                <ReactTableHeaderRow pageView={true} {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <ReactTableHeaderColumn
                      alignRight={column?.alignRight}
                      customWidth={column.width}
                      {...column.getHeaderProps()}
                    >
                      <HeaderCellWrapper>{column.id === 'actions' ? <></> : column.render('Header')}</HeaderCellWrapper>
                    </ReactTableHeaderColumn>
                  ))}
                </ReactTableHeaderRow>
              );
            })}
          </ReactTableHeader>
          <ReactTableBody pageView={true} {...getTableBodyProps()}>
            {Object.keys(selectedRowIds).length > 0 && (
              <MultiselectRibbon
                label={`${Object.keys(selectedRowIds).length} selected ${pluralize(
                  Object.keys(selectedRowIds).length,
                  'row',
                  false,
                )}:`}
                actions={[
                  {
                    role: 'destructive',
                    label: 'Delete',
                    onClick: async () => {
                      const contractIds = rows.filter((row) => selectedRowIds[row.id]).map((row) => row.original.id);
                      setSelectedContractIds(contractIds);
                      openConfirmDeleteContractsModal();
                    },
                  },
                ]}
                onResetSelection={() => toggleAllRowsSelected(false)}
              />
            )}
            {page.map((row) => {
              prepareRow(row);

              return (
                <ReactTableRow data-cy={'contract-row'} {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <ReactTableCell isAbsolute={row.canExpand} customWidth={cell.column.width} {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </ReactTableCell>
                  ))}
                </ReactTableRow>
              );
            })}
          </ReactTableBody>
        </ReactTable>
      )}
      <AIProcessingModal />
      <ContractAIPreviewModal />
      <ContractTransactionsCreatedModal />
      <AIReaderLockedModal />
      <ConfirmDeleteContractModal />
      <ConfirmDeleteContractsModal />
    </>
  );
};

export { ContractsTable };
