import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { CustomersContextProvider } from './CustomersContext';
import { CustomersList } from './CustomersList';
import { CustomerSingle } from './CustomerSingle';

export const CustomersRouter = () => {
  const { path } = useRouteMatch();

  return (
    <CustomersContextProvider>
      <Switch>
        <Route path={path} component={CustomersList} exact />
        <Route path={`${path}/:customerId`} component={CustomerSingle} />
      </Switch>
    </CustomersContextProvider>
  );
};
