import styled from 'styled-components';
import LoadingGif from 'images/preloader.gif';
import { Centerer } from 'components/Core';

export const SpinnerDiv = styled.div`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
`;

const LoaderContainer = styled(Centerer)`
  height: ${({ height }) => height}px;
`;

export const SizedLoader = ({ size, wrapperHeight }) => {
  const loader = (
    <SpinnerDiv size={size} data-cy="loader">
      <img src={LoadingGif} alt="Loading..." />
    </SpinnerDiv>
  );

  return wrapperHeight ? <LoaderContainer height={wrapperHeight}>{loader}</LoaderContainer> : loader;
};
