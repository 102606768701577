import React from 'react';
import { FormikCustomRadio } from 'components/Controls';
import { EXISTING_CUSTOMERS_FORECAST_TYPE } from '../consts';
import { Line, SettingExplainer, SettingsSeparator } from './styles';

export const RenewalUpsellCustomersSection = ({ name }) => (
  <>
    <SettingsSeparator>
      <div>Forecasting renewal and upsell revenue</div>
      <Line />
    </SettingsSeparator>

    <FormikCustomRadio
      name="existing_customers_forecast_type"
      suffix={`${name}__existing_customers_forecast_type--radio`}
      options={[
        {
          label: 'Based on Net Revenue Retention curves',
          value: EXISTING_CUSTOMERS_FORECAST_TYPE.NRR,
          explainer: (
            <SettingExplainer>
              <div>Subscript calculates the average NRR for each contract month and predicts future revenue.</div>
            </SettingExplainer>
          ),
        },
        {
          label: 'Based on opportunities in the pipeline',
          value: EXISTING_CUSTOMERS_FORECAST_TYPE.PIPELINE,
          explainer: (
            <SettingExplainer>
              <div>Subscript uses the open opportunities and their probabilities to predict renewals and upsells.</div>
            </SettingExplainer>
          ),
        },
      ]}
    />
  </>
);
