import styled from 'styled-components';

export const HeaderContainer = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 22px;
  margin-top: 24px;
`;

export const HeaderTitle = styled.h1`
  font-size: 30px;
  line-height: 40px;
  font-weight: 900;
  margin-bottom: 0;
  margin-right: 15px;
  color: var(--primaryBlack);
`;

export const ButtonsRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${(props) => !props.deleteView && 'var(--primaryBlack2)'};
  margin-top: ${(props) => !props.deleteView && '31px'};
  padding: ${(props) => (props.deleteView ? '20px 33px 24px 33px' : '12px 36px')};
  border-top: ${(props) => !props.deleteView && '1px solid var(--primaryBlack6)'};
`;

export const Tag = styled.div`
  background-color: rgba(57, 161, 220, 0.1);
  color: var(--primaryBlue);
  font-size: 12px;
  padding: 2px 6px;
  border-radius: 4px;
  width: auto;
  font-weight: 700;
  margin-right: 4px;
`;

export const LengthTag = styled.div`
  background-color: #f2f2f2;
  color: var(--primaryBlack);
  font-size: 12px;
  padding: 2px 6px;
  border-radius: 4px;
  width: auto;
  font-weight: 700;
`;

export const Empty = styled.div`
  background-color: #f2f2f2;
  width: 10px;
  padding: 1px 8px;
`;

export const TransactionCount = styled.div`
  background: var(--primaryBlack10);
  border-radius: 100px;
  font-weight: 900;
  font-size: 12px;
  padding: 0 6px;
  margin-left: 14px;
  height: 22px;
  cursor: pointer;
  display: flex;
  align-items: center;
`;
