import { assertCloseTo } from 'utils/assertionUtils';
import { RECOGNITION_TYPES } from 'consts/global';

export const checkSumOfSchedule = ({ organization, user, data }) => {
  if (!data?.transactionId) return;
  const { amount, schedule, recognition, transactionId, start_date, end_date } = data;

  if (!start_date || !end_date || RECOGNITION_TYPES.linear !== recognition) return;

  let totalAmount = 0;
  for (const monthDataArray of Object.values(schedule)) {
    const { type, amount: monthAmount } = monthDataArray[0] ?? {};
    if (type === 'regular') totalAmount += parseFloat(monthAmount);
  }

  // verify total amount
  assertCloseTo({
    organization,
    user,
    context: 'TransactionRecognitionsSchedule',
    expected: amount,
    actual: totalAmount,
    description: `transaction schedule sum for transaction id ${transactionId}`,
  });
};
