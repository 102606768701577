import React, { useContext } from 'react';
import dayjs from 'dayjs';

import { AppContext } from 'AppContext';
import { ReactComponent as UpIcon } from 'images/icon_up_fill.svg';
import { ReactComponent as DownIcon } from 'images/icon_down_fill.svg';
import { ReactComponent as UpArrow } from 'images/small-fill-up-arrow.svg';
import { ReactComponent as DownArrow } from 'images/small-fill-down-arrow.svg';
import { ReactComponent as TitleIcon } from 'images/revenue-card-title-icon.svg';
import { ReactComponent as GrowthIcon } from 'images/chart-arrows-vertical.svg';
import { NUMBER_FORMATS } from 'consts/global';
import TooltipContainer from 'components/Tooltip/Tooltip';
import { Column, Row } from 'components/Core';
import { useCurrencyNumberFormatter } from 'utils/hooks';
import { REVENUE_METRIC } from 'consts/revenueMetrics';
import { Flexer, FlexerColumn } from 'components/Core/Flex';
import { SwitchWithLabel } from 'components/Controls';
import { UNIT_OPTIONS, UPSELL_TYPES } from './RevenueDetailsContent';
import {
  ToolTipContentContainer,
  ToolTipRow,
  ToolTipLabel,
  ToolTipValue,
  Divider,
  PercentChange,
  CardTitle,
  BottomRow,
  TotalRevenueContainer,
  TopContainer,
  VerticalDivider,
  ToolTipDivider,
  MiddleContainer,
  Title,
  Value,
  UpsellTypesToggleLable,
  TotalRevenue,
  GrowthIconWrapper,
  GreyLabel,
  GrowthContainer,
  Percent,
  GrowthRateLabel,
  TotalGrowthIconWrapper,
} from './styled';

const TotalRevenueCard = ({
  historicalData,
  startMonth,
  endMonth,
  previousMonth,
  activeMetric,
  totalRecurringRevenue,
  lastRecurringTotal,
  revenueMonth,
  chartUnits,
  upsellTypes,
  setUpsellType,
  summaryStartMonth,
}) => {
  const {
    includeCommitted,
    appSettings: { isCommitted },
  } = useContext(AppContext);
  const numberFormatter = useCurrencyNumberFormatter();

  //year change
  let startValue = historicalData[startMonth][chartUnits][activeMetric];
  let endValue = historicalData[endMonth][chartUnits][activeMetric];
  let yearChange = endValue - startValue;
  let yearPercentChange = startValue === 0 ? 1 : yearChange / startValue;
  startValue =
    chartUnits === UNIT_OPTIONS.MONEY
      ? numberFormatter({ type: NUMBER_FORMATS.CURRENCY, rawValue: Math.round(startValue) })
      : Math.round(startValue);
  endValue =
    chartUnits === UNIT_OPTIONS.MONEY
      ? numberFormatter({ type: NUMBER_FORMATS.CURRENCY, rawValue: Math.round(endValue) })
      : Math.round(endValue);

  //month change
  const totalChange = totalRecurringRevenue - lastRecurringTotal;
  const totalPercentChange = (totalChange / totalRecurringRevenue) * 100;

  const currentMonthValue = historicalData[endMonth].revenue[activeMetric];
  const previousMonthValue = historicalData[previousMonth].revenue[activeMetric];
  const monthChange = currentMonthValue - previousMonthValue;
  const valuePercentChange = previousMonthValue === 0 ? 100 : (monthChange / previousMonthValue) * 100;

  let metricPercentArrowIcon;
  let metricPercentArrowIconToolTipBackgroundColor;
  let metricPercentBackgroundColor;
  let metricPercentColor;
  let yearGrowthBackgroundColor;
  let yearGrowthArrowIcon;
  if ([REVENUE_METRIC.CHURN.label, REVENUE_METRIC.DOWNSELL.label].includes(activeMetric)) {
    if (valuePercentChange >= 0) {
      metricPercentArrowIcon = <UpArrow fill="var(--primaryRed)" />;
      metricPercentArrowIconToolTipBackgroundColor = 'var(--secondaryRed)';
      metricPercentBackgroundColor = 'var(--accentRed)';
      metricPercentColor = 'var(--primaryRed)';
    } else {
      metricPercentArrowIcon = <DownArrow fill="var(--primaryGreen)" />;
      metricPercentArrowIconToolTipBackgroundColor = 'var(--secondaryGreen)';
      metricPercentBackgroundColor = 'var(--accentGreen)';
      metricPercentColor = 'var(--primaryGreen)';
    }
    if (yearChange >= 0) {
      yearGrowthBackgroundColor = 'var(--secondaryRed)';
      yearGrowthArrowIcon = <UpIcon fill="var(--primaryRed)" />;
    } else {
      yearGrowthBackgroundColor = 'var(--secondaryGreen)';
      yearGrowthArrowIcon = <DownIcon fill="var(--primaryGreen)" />;
    }
  } else {
    if (valuePercentChange >= 0) {
      metricPercentArrowIcon = <UpArrow fill="var(--primaryGreen)" />;
      metricPercentArrowIconToolTipBackgroundColor = 'var(--secondaryGreen)';
      metricPercentBackgroundColor = 'var(--accentGreen)';
      metricPercentColor = 'var(--primaryGreen)';
    } else {
      metricPercentArrowIcon = <DownArrow fill="var(--primaryRed)" />;
      metricPercentArrowIconToolTipBackgroundColor = 'var(--secondaryRed)';
      metricPercentBackgroundColor = 'var(--accentRed)';
      metricPercentColor = 'var(--primaryRed)';
    }
    if (yearChange >= 0) {
      yearGrowthBackgroundColor = 'var(--secondaryGreen)';
      yearGrowthArrowIcon = <UpIcon fill="var(--primaryGreen)" />;
    } else {
      yearGrowthBackgroundColor = 'var(--secondaryRed)';
      yearGrowthArrowIcon = <DownIcon fill="var(--primaryRed)" />;
    }
  }

  const totalChangeContent = () => (
    <ToolTipContentContainer>
      <ToolTipRow>
        <ToolTipLabel>{dayjs(previousMonth).format('MMM YYYY')}:</ToolTipLabel>
        <ToolTipValue>
          {numberFormatter({ type: NUMBER_FORMATS.CURRENCY, rawValue: Math.round(lastRecurringTotal) })}
        </ToolTipValue>
      </ToolTipRow>
      <ToolTipRow>
        <ToolTipLabel>{dayjs(revenueMonth).format('MMM YYYY')}:</ToolTipLabel>
        <ToolTipValue>
          {numberFormatter({ type: NUMBER_FORMATS.CURRENCY, rawValue: Math.round(totalRecurringRevenue) })}
        </ToolTipValue>
      </ToolTipRow>
      <ToolTipDivider />
      <ToolTipRow>
        <ToolTipLabel>Total change</ToolTipLabel>
        <ToolTipValue>
          <span style={{ paddingRight: 4 }}>
            {numberFormatter({ type: NUMBER_FORMATS.CURRENCY, rawValue: Math.round(totalChange) })}
          </span>
          <PercentChange
            backgroundColor={totalPercentChange >= 0 ? 'var(--secondaryGreen)' : 'var(--secondaryRed)'}
            color={'white'}
          >
            {totalPercentChange.toFixed(2)}%{' '}
            {totalPercentChange >= 0 ? <UpArrow fill="white" /> : <DownArrow fill="white" />}
          </PercentChange>
        </ToolTipValue>
      </ToolTipRow>
    </ToolTipContentContainer>
  );

  const metricChangeContent = () => (
    <ToolTipContentContainer>
      <ToolTipRow>
        <ToolTipLabel>{dayjs(previousMonth).format('MMM YYYY')}:</ToolTipLabel>
        <ToolTipValue>
          {numberFormatter({ type: NUMBER_FORMATS.CURRENCY, rawValue: Math.round(previousMonthValue) })}
        </ToolTipValue>
      </ToolTipRow>
      <ToolTipRow>
        <ToolTipLabel>{dayjs(revenueMonth).format('MMM YYYY')}:</ToolTipLabel>
        <ToolTipValue>
          {numberFormatter({ type: NUMBER_FORMATS.CURRENCY, rawValue: Math.round(currentMonthValue) })}
        </ToolTipValue>
      </ToolTipRow>
      <ToolTipDivider />
      <ToolTipRow>
        <ToolTipLabel>{`${activeMetric} change:`}</ToolTipLabel>
        <ToolTipValue>
          <span style={{ paddingRight: 4 }}>
            {numberFormatter({ type: NUMBER_FORMATS.CURRENCY, rawValue: Math.round(monthChange) })}
          </span>
          <PercentChange backgroundColor={metricPercentArrowIconToolTipBackgroundColor} color={'white'}>
            {valuePercentChange.toFixed(2)}%{' '}
            {valuePercentChange >= 0 ? <UpArrow fill="white" /> : <DownArrow fill="white" />}
          </PercentChange>
        </ToolTipValue>
      </ToolTipRow>
    </ToolTipContentContainer>
  );

  const yearGrowthChangeContent = () => (
    <ToolTipContentContainer>
      <ToolTipRow>
        <ToolTipLabel>{dayjs(endMonth).format('MMM YYYY')}:</ToolTipLabel>
        <ToolTipValue>{endValue}</ToolTipValue>
      </ToolTipRow>
      <ToolTipRow>
        <ToolTipLabel>{dayjs(startMonth).format('MMM YYYY')}:</ToolTipLabel>
        <ToolTipValue>{startValue}</ToolTipValue>
      </ToolTipRow>
      <ToolTipDivider />
      <ToolTipRow>
        <ToolTipLabel>Growth:</ToolTipLabel>
        <ToolTipValue>
          <span style={{ paddingRight: 4 }}>
            {numberFormatter({ type: NUMBER_FORMATS.CURRENCY, rawValue: yearChange })}
          </span>
          <PercentChange backgroundColor={yearGrowthBackgroundColor} color={'white'}>
            {numberFormatter({ type: NUMBER_FORMATS.PERCENT, rawValue: yearPercentChange })}{' '}
            {yearPercentChange >= 0 ? <UpArrow fill="white" /> : <DownArrow fill="white" />}
          </PercentChange>
        </ToolTipValue>
      </ToolTipRow>
    </ToolTipContentContainer>
  );

  return (
    <Column style={{ width: '25%', minWidth: '300px', marginLeft: '24px' }} horizontal="start">
      <CardTitle>
        <TitleIcon />
        Statistics
      </CardTitle>
      <TotalRevenueContainer includeCommitted={includeCommitted}>
        <TopContainer>
          {activeMetric === REVENUE_METRIC.UPSELL.label && !summaryStartMonth && (
            <>
              <FlexerColumn>
                <Title>Upsell Type</Title>
                <Flexer>
                  {Object.entries(UPSELL_TYPES).map(([key, label]) => (
                    <SwitchWithLabel
                      key={key}
                      onChange={() => {
                        let newTypes;
                        if (upsellTypes.includes(key)) {
                          newTypes = upsellTypes.filter((type) => type !== key);
                        } else {
                          newTypes = [...upsellTypes, key];
                        }
                        setUpsellType(newTypes);
                      }}
                      checked={upsellTypes.includes(key)}
                      label={<UpsellTypesToggleLable>{label}</UpsellTypesToggleLable>}
                      marginRight="16px"
                    />
                  ))}
                </Flexer>
              </FlexerColumn>
              <Divider />
            </>
          )}
          <Title>{`Total ${isCommitted ? 'Committed' : 'Recurring'} Revenue`}</Title>
          <TotalRevenue>
            <TooltipContainer
              width={250}
              isVisible={!summaryStartMonth}
              underline={false}
              toolTipContent={totalChangeContent()}
            >
              <Row horizontal="flex-start">
                <span data-cy="revenue-details__total-revenue-card__total-revenue-value">
                  {numberFormatter({ type: NUMBER_FORMATS.CURRENCY, rawValue: Math.round(totalRecurringRevenue) })}
                </span>
                {!summaryStartMonth && (
                  <PercentChange
                    backgroundColor={totalPercentChange >= 0 ? '#E9F8F1' : '#FEEEEE'}
                    color={totalPercentChange >= 0 ? 'var(--primaryGreen)' : 'var(--primaryRed)'}
                  >
                    {totalPercentChange.toFixed(2)}%{' '}
                    {totalPercentChange >= 0 ? (
                      <UpArrow fill="var(--primaryGreen)" />
                    ) : (
                      <DownArrow fill="var(--primaryRed)" />
                    )}
                  </PercentChange>
                )}
              </Row>
            </TooltipContainer>
          </TotalRevenue>
          <Divider />
          <Row horizontal="flex-start">
            <div>
              <Value>
                {numberFormatter({ type: NUMBER_FORMATS.NUMBER, rawValue: historicalData[endMonth].customers.Total })}
              </Value>
              <GreyLabel style={{ marginLeft: 4 }}>recurring customers</GreyLabel>
            </div>
          </Row>
          <Divider />
          <Row horizontal="space-between">
            <Column horizontal="flex-start">
              <Value>{numberFormatter({ type: NUMBER_FORMATS.CURRENCY, rawValue: Math.round(totalChange) })}</Value>
              <GreyLabel>total change</GreyLabel>
            </Column>
            <Column horizontal="flex-end">
              <Value>
                {numberFormatter({ type: NUMBER_FORMATS.CURRENCY, rawValue: Math.round(lastRecurringTotal) })}
              </Value>
              <GreyLabel>{dayjs(previousMonth).format('MMM YYYY')}</GreyLabel>
            </Column>
          </Row>
        </TopContainer>
        <MiddleContainer>
          <TooltipContainer
            width={250}
            isVisible={!summaryStartMonth}
            underline={false}
            toolTipContent={metricChangeContent()}
          >
            <Column horizontal="flex-start">
              <Row>
                <Value>
                  {numberFormatter({
                    type: NUMBER_FORMATS.CURRENCY,
                    rawValue: Math.round(historicalData[endMonth].revenue[activeMetric]),
                  })}
                </Value>
                {!summaryStartMonth && (
                  <PercentChange backgroundColor={metricPercentBackgroundColor} color={metricPercentColor}>
                    {valuePercentChange.toFixed(2)}% {metricPercentArrowIcon}
                  </PercentChange>
                )}
              </Row>

              <GreyLabel>{activeMetric.toLowerCase()} revenue</GreyLabel>
            </Column>
          </TooltipContainer>

          <VerticalDivider />

          <TooltipContainer
            width={100}
            isVisible={true}
            underline={false}
            toolTipContent={`for ${dayjs(revenueMonth).format('MMM YYYY')}`}
          >
            <Column horizontal="flex-start">
              <Value>{historicalData[endMonth].customers[activeMetric]}</Value>
              <GreyLabel>{`${activeMetric.toLowerCase()} customers`}</GreyLabel>
            </Column>
          </TooltipContainer>
        </MiddleContainer>

        {activeMetric === REVENUE_METRIC.INFLUX.key && (
          <>
            <Divider />
            <TooltipContainer
              width={150}
              isVisible={true}
              underline={false}
              toolTipContent={`for ${dayjs(revenueMonth).format('MMM YYYY')}`}
            >
              <Row horizontal="flex-start" style={{ padding: '0 20px 16px 20px' }}>
                <Value style={{ marginRight: 4 }}>
                  {historicalData[endMonth].revenue[REVENUE_METRIC.INFLUX_MOVEMENT.key] > 0 ? '+' : ''}
                  {numberFormatter({
                    type: NUMBER_FORMATS.CURRENCY,
                    rawValue: historicalData[endMonth].revenue[REVENUE_METRIC.INFLUX_MOVEMENT.key],
                  })}
                </Value>
                <GreyLabel>movement in flux amount</GreyLabel>
              </Row>
            </TooltipContainer>
          </>
        )}

        <BottomRow>
          {!summaryStartMonth && (
            <TooltipContainer width={250} isVisible={true} underline={false} toolTipContent={yearGrowthChangeContent()}>
              <GrowthContainer>
                <Percent>
                  <Row>
                    {numberFormatter({ type: NUMBER_FORMATS.PERCENT, rawValue: yearPercentChange })}
                    <GrowthIconWrapper>{yearGrowthArrowIcon}</GrowthIconWrapper>
                  </Row>

                  <GrowthRateLabel>year growth rate</GrowthRateLabel>
                </Percent>

                <TotalGrowthIconWrapper>
                  <GrowthIcon />
                </TotalGrowthIconWrapper>
              </GrowthContainer>
            </TooltipContainer>
          )}
        </BottomRow>
      </TotalRevenueContainer>
    </Column>
  );
};

export default TotalRevenueCard;
