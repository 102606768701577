import React, { useState } from 'react';

import { ReactComponent as InfoCircle } from 'images/black-info-circle.svg';
import {
  ModalListItem,
  ModalItemsSubtitle,
  SmallModalListItem,
  SmallItemsContainer,
  BigItemsContainer,
  Flexer,
  FlexerColumn,
  Spacer,
} from 'components/Core';
import { ModalPopover } from 'components/Modal';
import { FilterConditionHead } from 'shared/Filters';
import { INSTALL_BY_OPTIONS } from 'views/Cohorts/consts';
import { useClickOutside } from 'utils/hooks';

import { DropdownInfo } from '../styles';

//draftDataFilter, updateDraftDataFilter props come from the Filters component
export const CohortFilterPopover = ({ draftDataFilter, updateDraftDataFilter }) => {
  const { cohortInstallBy, cohortInstallSecondBy } = draftDataFilter;

  const [showSettingsPopover, setShowSettingsPopover] = useState(false);
  const togglePopover = () => setShowSettingsPopover(!showSettingsPopover);
  const popOverRef = useClickOutside(() => setShowSettingsPopover(false));

  return (
    <FilterConditionHead onClick={togglePopover} ref={popOverRef} data-cy="cohorts-page__settings-by">
      {cohortInstallBy} {cohortInstallSecondBy && `, ${cohortInstallSecondBy}`}
      {showSettingsPopover && (
        <ModalPopover isInstallByPopOver={true} minWidth="340px">
          <FlexerColumn>
            <Flexer>
              <BigItemsContainer soloView={true}>
                {Object.keys(INSTALL_BY_OPTIONS).map((key) => {
                  const option = INSTALL_BY_OPTIONS[key];

                  return (
                    <ModalListItem
                      key={key}
                      data-cy={`cohorts-page__settings-by__option--${key}`}
                      isActive={cohortInstallBy === option}
                      onClick={() => {
                        updateDraftDataFilter({
                          cohortInstallBy: option,
                          cohortInstallSecondBy: null,
                        });
                      }}
                    >
                      {option}
                    </ModalListItem>
                  );
                })}
              </BigItemsContainer>
              <Spacer width="10px" />
              <SmallItemsContainer>
                <ModalItemsSubtitle>2nd Metric:</ModalItemsSubtitle>

                <SmallModalListItem
                  data-cy={`cohorts-page__settings-by__second-option--none`}
                  isActive={cohortInstallSecondBy === null}
                  onClick={() => {
                    togglePopover();
                    updateDraftDataFilter({ cohortInstallSecondBy: null });
                  }}
                >
                  None
                </SmallModalListItem>

                {Object.keys(INSTALL_BY_OPTIONS).map((key) => {
                  const option = INSTALL_BY_OPTIONS[key];
                  if (option === cohortInstallBy) return null;

                  return (
                    <SmallModalListItem
                      key={key}
                      data-cy={`cohorts-page__settings-by__second-option--${key}`}
                      isActive={cohortInstallSecondBy === option}
                      onClick={() => {
                        togglePopover();
                        updateDraftDataFilter({ cohortInstallSecondBy: option });
                      }}
                    >
                      {option}
                    </SmallModalListItem>
                  );
                })}
              </SmallItemsContainer>
            </Flexer>

            <DropdownInfo>
              <InfoCircle />
              <span>Cohorts are based on subscription date</span>
            </DropdownInfo>
          </FlexerColumn>
        </ModalPopover>
      )}
    </FilterConditionHead>
  );
};
