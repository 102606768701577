import { useCallback, useState } from 'react';
import { ContractTransactionsCreatedModal } from './ContractTransactionsCreatedModal';

export const useContractTransactionsCreatedModal = ({ data, onSubmit }) => {
  const [showModal, setShowModal] = useState(false);

  const openModal = useCallback(() => {
    setShowModal(true);
  }, [setShowModal]);

  const closeModal = useCallback(() => {
    setShowModal(false);
  }, [setShowModal]);

  const handleSubmit = useCallback(
    (data) => {
      setShowModal(false);
      onSubmit?.(data);
    },
    [setShowModal, onSubmit],
  );

  const Modal = useCallback(
    () =>
      showModal ? <ContractTransactionsCreatedModal data={data} onClose={closeModal} onSubmit={handleSubmit} /> : null,
    [showModal, data, closeModal, handleSubmit],
  );

  return {
    openContractTransactionsCreatedModal: openModal,
    ContractTransactionsCreatedModal: Modal,
    isModalVisible: showModal,
  };
};
