import axios from 'axios';
import { API_URL } from 'api/consts';

const buildUrl = ({ orgId }) => {
  const baseUrl = `${API_URL}/organizations/${orgId}/exchange-rates`;
  return baseUrl;
};

export const getExchangeRates = async ({ orgId, params }) => {
  const { data } = await axios.get(buildUrl({ orgId }), { params });
  return data;
};

export const getRateByCurrencyAndDate = async ({ orgId, params }) => {
  const { data } = await axios.get(`${buildUrl({ orgId })}/by-currencies-and-date`, { params });
  return data;
};

export const bulkCreateExchangeRates = async ({ orgId, data }) => {
  const url = `${API_URL}/organizations/${orgId}/exchange-rates/bulk-create`;
  const { data: response } = await axios.post(url, data);
  return response;
};

export const bulkDeleteExchangeRates = async ({ orgId, data }) => {
  const url = `${API_URL}/organizations/${orgId}/exchange-rates/bulk-delete`;
  const { data: response } = await axios.post(url, data);
  return response;
};
