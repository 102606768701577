import { saveAs } from 'file-saver';
import XlsxPopulate from 'xlsx-populate/browser/xlsx-populate';
import dayjs from 'dayjs';
import { NUMBER_FORMATS } from 'consts/global';

export const getNumberFormat = (type) => {
  switch (type) {
    case NUMBER_FORMATS.CURRENCY:
      return '[$$-409]* #,##0;-[$$-409]* #,##0';
    case NUMBER_FORMATS.PERCENT:
      return '0.00%';
    case NUMBER_FORMATS.NUMBER:
    default:
      return '#,##0';
  }
};

const getCharRange = (start, stop) => {
  const result = [];
  let i = start.charCodeAt(0),
    last = stop.charCodeAt(0) + 1;
  for (i; i < last; i++) {
    result.push(String.fromCharCode(i));
  }
  return result;
};

const toString26 = (num) => {
  const alpha = getCharRange('A', 'Z');
  var result = '';

  let quotient = num;
  let remainder = 0;

  if (num === 0) {
    return 'A';
  }

  while (quotient !== 0) {
    var decremented = quotient - 1;
    quotient = Math.floor(decremented / 26);
    remainder = decremented % 26;
    result = alpha[remainder] + result;
  }

  return result;
};

const getRange = (start, end) => [toString26(start), toString26(end)];

const downloadWorkbook = async (workbook, filename) => {
  const blob = await workbook.outputAsync();
  saveAs(blob, filename);
};

/**
 * @param {*} reshapeData
 * @param {*} title
 * @param {boolean} [useColumnsForName=false] if useColumnsForName is true, uses second and last column name in the file title
 **/
export const generateRowsForDashboardToExcel = async ({ reshapeData, title, useColumnsForName = false }) => {
  const { columnHeaders, rows } = reshapeData;
  const getRowsDashboardData = (row, columns, depth = 0) => {
    const draftRows = [];
    if (row.data) {
      const draftRowData = {
        [columns[0]]: row.title,
        type: row.dataType,
        depth,
      };
      columns.slice(1).forEach((col) => {
        draftRowData[col] = row.data[col];
      });
      draftRows.push(draftRowData);
    } else {
      draftRows.push({
        [columns[0]]: row.title,
        type: row.dataType,
        depth,
      });
    }
    if (row.subRows) {
      draftRows.push(...row.subRows.flatMap((r) => getRowsDashboardData(r, columns, depth + 1)));
    }
    return draftRows;
  };

  const transformFromDashboardData = (columnHeaders, rows) => {
    const columns = ['SnapShot', ...columnHeaders];
    const data = rows.flatMap((s) => getRowsDashboardData(s, columns));
    return { data, columns };
  };

  const { data, columns } = transformFromDashboardData(columnHeaders, rows);

  const { fileName, workbook } = await exportToExcel(data, columns, title, useColumnsForName);

  const [start2, end] = getRange(2, columns.length);
  const [start1] = getRange(1, columns.length);

  const draftRows = data.map((value, index) => ({
    value,
    index: index + 2,
  }));

  const titleRows = draftRows.filter((t) => t.value.depth === 0 && Object.entries(t.value).length < columns.length);

  const subtitleRows = draftRows.filter(
    (t, i, rows) =>
      (t.value.depth > 0 && i > 0 && rows[i - 1].value.depth === 0) ||
      (t.value.depth === 0 && Object.entries(t.value).length >= columns.length),
  );

  const dataRows = draftRows.filter((value) => !titleRows.includes(value) && !subtitleRows.includes(value));

  const sheet = workbook.sheet(0);

  sheet.column(1).width(20);

  for (let index = 2; index < columns.length; index++) {
    sheet.column(index).width(10);
  }

  titleRows.forEach(({ index }) => {
    sheet.row(index).height(36).style({ italic: true, fontSize: 11 });
  });

  const colorCells = (sheet, i, green, red) => {
    for (let j = 1; j < columns.length; j++) {
      const currentCell = sheet.row(i).cell(j + 1);
      const prevCell = sheet.row(i).cell(j);
      const currentValue = Number(currentCell.value());
      const prevValue = Number(prevCell.value());

      if (!Number.isNaN(prevValue) && !Number.isNaN(currentValue) && currentValue >= prevValue) {
        currentCell.style({ fill: green });
      } else if (!Number.isNaN(prevValue) && !Number.isNaN(currentValue) && currentValue < prevValue) {
        currentCell.style({ fill: red });
      }
    }
  };

  const thinBorder = { leftBorder: 'thin', rightBorder: 'thin', topBorder: 'thin', bottomBorder: 'thin' };
  const neutralFill = { fill: 'EAEAEA' };

  sheet.row(1).height(18).style(thinBorder);

  dataRows.forEach(({ value, index }) => {
    sheet.row(index).height(18).style({ fontSize: 10 });
    sheet.range(`${start1}${index}:${end}${index}`).style(thinBorder);
    sheet.range(`${start2}${index}:${end}${index}`).style(neutralFill);
    if (value.type) {
      sheet.row(index).style('numberFormat', getNumberFormat(value.type));
      colorCells(sheet, index, 'e2f0d9', 'f8cbad');
    }
  });

  subtitleRows.forEach(({ value, index }) => {
    sheet.row(index).height(18).style({ bold: true, fontSize: 11 });
    sheet.range(`${start1}${index}:${end}${index}`).style(thinBorder);
    sheet.range(`${start2}${index}:${end}${index}`).style(neutralFill);
    if (value.type) {
      sheet.row(index).style('numberFormat', getNumberFormat(value.type));
      colorCells(sheet, index, 'a9d18e', 'f4b183');
    }
  });

  return {
    workbook,
    fileName,
  };
};

export const exportDashboardToExcel = async ({ reshapeData, title, useColumnsForName = false }) => {
  const { workbook, fileName } = await generateRowsForDashboardToExcel({ reshapeData, title, useColumnsForName });
  await downloadWorkbook(workbook, fileName);
};

/**
 *
 *
 * @param {*} data rows data that looks like -> [{ 'column1name': row1column1value, 'column2name': row1column2value ...}, { 'column1name': row2column1value, 'column2name': row2column2value ...}]
 * @param {*} columns array of columns
 * @param {*} title
 * @param {boolean} [useColumnsForName=false] uses second and last column name in the file title
 * @description creates and saves excel to user's PC
 */
export const exportToExcel = async (data, columns, title, useColumnsForName = false) => {
  try {
    const exportTime = dayjs().format('YYYY-MM-DD-HH-mm-ss');

    const workbook = await XlsxPopulate.fromBlankAsync();

    columns.forEach((item, index) => {
      workbook
        .sheet(0)
        .row(1)
        .cell(index + 1)
        .value(item)
        .style({ bold: true });
    });
    data.forEach((item, row) => {
      columns.forEach((value, column) => {
        workbook
          .sheet(0)
          .row(row + 2)
          .cell(column + 1)
          .value(item[value] instanceof Object ? item[value].value : item[value]);
      });
    });

    let fileName = `${`${title}_${exportTime}`.replace(/,/g, '').replace(/ /g, '_')}.xlsx`;

    if (useColumnsForName) {
      /* eh, fileName should be much more better */
      fileName = `${`${title}_${columns[1]}_${columns[columns.length - 1]}_${exportTime}`
        .replace(/,/g, '')
        .replace(/ /g, '_')}.xlsx`;
    }

    return {
      fileName,
      workbook,
    };
  } catch (err) {
    console.log(err);
  }
};

/**
 * Download blob file
 * @param file blob
 * @param format file format
 * @param filename custom file name
 */
export const downloadBlobFile = ({ file, format, filename, rawName = false }) => {
  const exportTime = dayjs().format('DD/MM/YYYY h:mm:ss A');
  const name = rawName ? filename : `${filename ?? 'Subscript'} ${exportTime}.${format}`;
  let url;
  if (format === 'pdf' && typeof file === 'string') {
    // For PDF, if file is a string (assumed to be base64), create data URL
    url = `data:application/pdf;base64,${file}`;
  } else if (typeof file === 'string' && file.startsWith('data:')) {
    // If file is a data URL, use it directly
    url = file;
  } else {
    // For other formats or if file is not a string, create object URL
    const blob = file instanceof Blob ? file : new Blob([file]);
    url = window.URL.createObjectURL(blob);
  }

  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', name);
  document.body.appendChild(link);
  link.click();
};

export const downloadPdf = ({ isLink, pdf, filename } = {}) => {
  if (!pdf) return;
  if (isLink) {
    window.open(pdf, '_blank')?.focus();
  } else {
    downloadBlobFile({
      file: pdf,
      format: 'pdf',
      filename,
      rawName: true,
    });
  }
};
