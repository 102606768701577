import styled from 'styled-components';

const ButtonContainer = styled.div`
  padding: 11px 14px;
  height: 40px;
  color: white;
  background-color: ${(props) => (props.active ? 'var(--secondaryGreen)' : 'var(--primaryGreen)')};
  box-shadow: 0px 4px 20px rgba(82, 181, 120, 0.2);
  border-radius: 100px;
  margin-right: 10px;
  border: 1px solid rgba(30, 183, 110, 0.1);
  cursor: pointer;
  display: ${(props) => (props.hidden ? 'none' : 'flex')};
  align-items: center;
  width: fit-content;

  &:hover {
    background-color: var(--secondaryGreen);
  }
`;

export const GreenButton = ({ onClick, buttonLabel, hidden, children, ...rest }) => (
  <ButtonContainer onClick={onClick} hidden={hidden} {...rest}>
    {buttonLabel ? <span>{buttonLabel}</span> : children}
  </ButtonContainer>
);
