import React, { useContext, useMemo } from 'react';
import dayjs from 'dayjs';
import { AppContext } from 'AppContext';
import { SNAPSHOT_METRICS } from 'consts/snapshotMetrics';
import { useCachedMetrics } from 'utils/hooks';
import { calculateCardData } from './utils';
import { DashboardContext } from '../DashboardContext';
import { Card } from '../Common/Card';

export const DashboardCustomersCard = (props) => {
  const {
    dataFilter: { endMonth },
  } = useContext(AppContext);
  const { rollup, loading, customerNURDCStats, lastTransactionUpdatedAt, respondedAt } = useContext(DashboardContext);
  const cardData = useMemo(
    () =>
      calculateCardData({
        data: customerNURDCStats,
        key: SNAPSHOT_METRICS.CUSTOMERS.monthDataKey,
      }),
    [customerNURDCStats],
  );

  useCachedMetrics({
    readyData: !loading && cardData && 'currentValue' in cardData ? cardData : null,
    getValue: ({ readyData }) => readyData?.currentValue,
    description: `All customer count in ${dayjs(endMonth).format('YYYY-MM')}`,
    storageKey: `allCustomerCount.${rollup ? 'withRollup' : 'withoutRollup'}.${dayjs(endMonth).format('YYYY-MM')}`,
    context: 'Dashboard Customers Card',
    lastTransactionUpdatedAt,
    readyDataRespondedAt: respondedAt,
  });

  return (
    <Card
      metricKey={SNAPSHOT_METRICS.CUSTOMERS.key}
      loading={loading}
      currentValue={cardData?.currentValue || 0}
      startingValue={cardData?.startingValue || 0}
      graphData={cardData?.graphData || []}
      graphLabels={cardData?.graphLabels || []}
      {...props}
    />
  );
};
