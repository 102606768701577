import { setNestedObjectValues } from 'formik';
import { isEmpty } from 'lodash';

export const validateAsync = async ({ values, formRef, setValidatedRows, setFormIsValid }) => {
  try {
    // Use batch validation for larger datasets
    const batchSize = 100;
    const totalBatches = Math.ceil(values.length / batchSize);
    let allErrors = {};
    let allTouched = {};

    for (let i = 0; i < totalBatches; i++) {
      const batchValues = values.slice(i * batchSize, (i + 1) * batchSize);
      await new Promise((resolve) => setTimeout(resolve, 0)); // Yield to the event loop

      const batchErrors = await formRef?.current?.validateForm(batchValues);

      if (batchErrors && !isEmpty(batchErrors)) {
        const adjustedBatchErrors = Object.entries(batchErrors).reduce((acc, [key, value]) => {
          const adjustedKey = Number(key) + i * batchSize;
          acc[adjustedKey] = value;
          return acc;
        }, {});

        allErrors = { ...allErrors, ...adjustedBatchErrors };
        const batchTouched = setNestedObjectValues(adjustedBatchErrors, true);
        allTouched = { ...allTouched, ...batchTouched };
      }

      setValidatedRows(i * batchSize + batchSize);

      if (!isEmpty(allErrors)) {
        await formRef?.current?.setTouched?.(allTouched);
        await formRef?.current?.setErrors?.(allErrors);
      }
    }

    setFormIsValid(isEmpty(allErrors));
  } finally {
    setValidatedRows(null);
  }
};
