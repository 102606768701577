import React, { useState, Fragment, useEffect } from 'react';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { Bar, defaults } from 'react-chartjs-2';
import { cssVar } from 'polished';

import { ReactComponent as HelpIcon } from 'images/chart-help-icon.svg';
import { NUMBER_FORMATS, WATERFALL_GRAPH_PERIOD_OPTIONS } from 'consts/global';
import { reFormatDate } from 'utils/dateUtils';
import { amountWithAbbreviation, numberFormatter } from 'utils/formatters';
import { REVENUE_METRIC } from 'consts/revenueMetrics';
import { TooltipContainer } from 'components/Tooltip';
import { BarChartSettings, MAX_NUMBER_OF_ACTIVE_SEGMENTS } from './BarChartSettings';
import { SelectDropdownButton } from 'components/Buttons';
import { LegendKeysContainer, LegendContainer, LegendLabel, Legend, LegendKey, LegendKeyDot } from 'components/Graph';

const ChartWrapper = styled.div`
  height: 100%;
  width: 100%;
`;

const ChartContainer = styled.div`
  position: relative;
  height: ${({ height }) => height ?? '100%'};
  width: 100%;
  border-bottom: none;
`;

const Divider = styled.hr`
  border: none;
  height: 1px;
  margin: 4px 0;
  background-color: #505050;
`;

const OtherToolTipCell = styled.td`
  padding: 2px;
  text-align: left;
`;

export const CHART_COLORS = {
  [REVENUE_METRIC.EXISTING.label]: cssVar('--primaryGreen'),
  [REVENUE_METRIC.NEW.label]: cssVar('--primaryBlue'),
  [REVENUE_METRIC.UPSELL.label]: cssVar('--primaryPurple'),
  [REVENUE_METRIC.DOWNSELL.label]: cssVar('--primaryYellow'),
  [REVENUE_METRIC.CHURN.label]: cssVar('--primaryRed'),
  Total: cssVar('--accentDarkGray'),
};

export const SEGMENT_COLORS = [
  cssVar('--primaryPink'),
  cssVar('--primaryGreen'),
  cssVar('--primaryPurple'),
  cssVar('--secondaryPurple'),
  cssVar('--primaryYellow'),
  cssVar('--primaryBlue'),
];

export const StackedBarChart = ({
  labels,
  data,
  dataTableShowingBy,
  selectedSections,
  includeLegend = true,
  height,
  waterfallGraphPeriod,
  setWaterfallGraphPeriod,
  currency,
  ...props
}) => {
  defaults.global.defaultFontFamily = 'Nunito Sans';

  const totalRow = data.rows.find((row) => row.type === 'totalRow');

  const defaultGraphSections = Object.keys(CHART_COLORS);
  const [graphSections, setGraphSections] = useState(new Set(defaultGraphSections));

  const lastMonthKey = Object.keys(totalRow.data).sort((a, b) => {
    const dateA = dayjs(a, 'MMM YYYY');
    const dateB = dayjs(b, 'MMM YYYY');
    // If date isn't valid (quarter, year, or cumulative), sort it to the end
    if (!dateA.isValid()) return 1;
    if (!dateB.isValid()) return -1;
    return dateB.diff(dateA);
  })[0];
  const sortFn = (a, b) => {
    return (b.data[lastMonthKey] ?? 0) - (a.data[lastMonthKey] ?? 0);
  };
  const segments = totalRow.subRows
    .slice()
    .sort(sortFn)
    .map((segment) => ({
      label: segment.title,
      key: segment.key,
    }));
  const hasSegmenting = segments.length > 0;
  const startingSegments = new Set(segments.slice(0, MAX_NUMBER_OF_ACTIVE_SEGMENTS).map((segment) => segment.key));
  const [showingSegments, setShowingSegments] = useState(startingSegments);
  const showingSegmentObjects = segments.filter((segment) => showingSegments.has(segment.key));

  const [showLabels, setShowLabels] = useState(true);

  useEffect(() => {
    if (selectedSections) {
      setGraphSections(new Set(selectedSections));
    }
  }, [selectedSections, setGraphSections]);

  const onChangeShowingSegments = (label) => {
    const newSegments = new Set(showingSegments);
    if (showingSegments.has(label)) {
      newSegments.delete(label);
    } else if (newSegments.size === MAX_NUMBER_OF_ACTIVE_SEGMENTS) {
      // limit selection to MAX_NUMBER_OF_ACTIVE_SEGMENTS
      return;
    } else {
      newSegments.add(label);
    }
    setShowingSegments(newSegments);
  };

  const onChangeGraphSections = (label) => {
    const newSections = new Set(graphSections);
    if (graphSections.has(label)) {
      newSections.delete(label);
    } else {
      newSections.add(label);
    }
    setGraphSections(newSections);
  };

  const sections = hasSegmenting ? totalRow.subRows.slice().sort(sortFn) : data.rows;
  const showOtherSegment = sections.length > MAX_NUMBER_OF_ACTIVE_SEGMENTS;

  const segmentingChartColors = {};
  const otherSegments = [];
  const barChart = [];
  if (hasSegmenting) {
    const otherData = {};
    let segmentColorsIndex = 0;
    const allDates = data.columnHeaders.filter((key) => labels.includes(key));

    for (const section of sections) {
      // segments values for the 4 selected segments
      if (showingSegments.has(section.key)) {
        // Fill with 0 for keys without amount on section.data
        const rowData = allDates.map((date) => Math.round(section?.data[date] ?? 0));

        barChart.push({
          type: 'bar',
          label: section.title,
          backgroundColor: SEGMENT_COLORS[segmentColorsIndex],
          barThickness: 18,
          data: rowData,
          datalabels: {
            display: false,
          },
        });
        segmentingChartColors[section.key] = SEGMENT_COLORS[segmentColorsIndex];
        segmentColorsIndex++;
      }
      // aggregates the values for the rest of the segments
      else {
        allDates.forEach((date) => {
          const value = section?.data[date] ?? 0;
          if (otherData[date]) {
            otherData[date] += value;
          } else {
            otherData[date] = value;
          }
        });
        otherSegments.push(section.title);
      }
    }
    if (showOtherSegment) {
      barChart.push({
        type: 'bar',
        label: 'Other',
        backgroundColor: SEGMENT_COLORS[MAX_NUMBER_OF_ACTIVE_SEGMENTS],
        barThickness: 18,
        data: Object.values(otherData).map((value) => Math.round(value)),
        datalabels: {
          display: false,
        },
      });
    }
  } else {
    const barChartSections = sections.filter(
      (section) =>
        ![REVENUE_METRIC.INFLUX.label, REVENUE_METRIC.INFLUX_MOVEMENT.label].includes(section.title) &&
        section.type !== 'totalRow',
    );
    for (const section of barChartSections) {
      if (graphSections.has(section.title)) {
        const rowData = [];
        for (const [key, value] of Object.entries(section.data)) {
          if (!labels.includes(key)) continue;

          //Makes Churn a negative number
          if (['Churn', 'Downsell'].includes(section.title)) {
            rowData.push(Math.round(value * -1));
          } else {
            rowData.push(Math.round(value));
          }
        }

        barChart.push({
          type: 'bar',
          label: section.title,
          backgroundColor: CHART_COLORS[section.title],
          barThickness: 18,
          data: rowData,
          datalabels: {
            display: false,
          },
        });
      }
    }
  }

  const lineData = [];
  if (hasSegmenting || graphSections.has('Total')) {
    for (const [key, value] of Object.entries(totalRow.data)) {
      if (!labels.includes(key)) continue;
      lineData.push(Math.round(value));
    }
  }

  const lineChart = {
    type: 'line',
    label: dataTableShowingBy === 'Revenue' ? 'Total Revenue' : 'Total Customers',
    fill: false,
    borderColor: CHART_COLORS.Total,
    pointBackgroundColor: CHART_COLORS.Total,
    pointBorderColor: 'white',
    borderWidth: 2,
    hoverBorderWidth: 2,
    pointRadius: 4,
    pointHoverRadius: 4,
    lineTension: 0,
    data: lineData,
    datalabels: {
      align: 'end',
      anchor: 'end',
    },
  };

  const formatLabel = (value) => {
    if (!dayjs(value).isValid()) return value;
    return dayjs(value).get('month') === 0
      ? value.toUpperCase()
      : reFormatDate(value, 'MMM YYYY', 'MMM YYYY').toUpperCase();
  };
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    layout: {
      padding: { top: 30 },
    },
    tooltips: {
      mode: 'index',
      intersect: false,
      callbacks: {
        label: function (tooltipItem, data) {
          let label = data.datasets[tooltipItem.datasetIndex].label || '';
          if (label) {
            label += ': ';
          }
          if (dataTableShowingBy === 'Revenue') {
            label += numberFormatter({ type: NUMBER_FORMATS.CURRENCY, rawValue: tooltipItem.yLabel, currency });
          } else {
            label += tooltipItem.yLabel.toString();
          }
          return label;
        },
      },
    },
    scales: {
      xAxes: [
        {
          stacked: true,
          gridLines: {
            display: false,
          },
          ticks: {
            padding: 10,
            fontStyle: 'normal',
            fontSize: 10,
            fontColor: cssVar('--primaryBlack70'),
            callback: formatLabel,
          },
        },
      ],
      yAxes: [
        {
          stacked: true,
          gridLines: {
            drawBorder: false,
            color: cssVar('--primaryBlack3'),
            borderColor: cssVar('--primaryBlack3'),
          },
          ticks: {
            padding: 10,
            fontStyle: 'normal',
            fontSize: 10,
            fontColor: cssVar('--primaryBlack70'),
            maxTicksLimit: 7,
            callback: (value) =>
              amountWithAbbreviation({
                value,
                type: dataTableShowingBy === 'Revenue' ? NUMBER_FORMATS.CURRENCY : NUMBER_FORMATS.NUMBER,
                currency,
              }),
          },
        },
      ],
    },
    plugins: {
      datalabels: {
        offset: '5',
        display: showLabels,
        backgroundColor: cssVar('--accentDarkGray'),
        borderRadius: 4,
        color: 'white',
        font: {
          weight: '800',
        },
        formatter: (value) =>
          amountWithAbbreviation({
            value,
            type: dataTableShowingBy === 'Revenue' ? NUMBER_FORMATS.CURRENCY : NUMBER_FORMATS.NUMBER,
            currency,
          }),
        padding: 6,
      },
    },
  };

  const HelpToolTipContent = () => (
    <div>
      <span>{`Subscript shows only the ${MAX_NUMBER_OF_ACTIVE_SEGMENTS} segments, the remaining segments are combined into others`}</span>
      <Divider />
      <span>To see the waterfall chart, turn off segmentation</span>
    </div>
  );

  const generateOtherTableRows = () => {
    const tableRows = [];
    for (let i = 0; i < otherSegments.length; i = i + 2) {
      tableRows.push(
        <tr key={otherSegments[i] + '-' + otherSegments[i + 1] + '-' + i}>
          <OtherToolTipCell>&#8226; {otherSegments[i]}</OtherToolTipCell>
          {otherSegments[i + 1] && <OtherToolTipCell>&#8226; {otherSegments[i + 1]}</OtherToolTipCell>}
        </tr>,
      );
    }
    return tableRows;
  };

  const datasets = [...barChart];
  if (!hasSegmenting) datasets.unshift(lineChart);

  return (
    <ChartWrapper {...props}>
      <ChartContainer height={height}>
        <Bar
          data={{
            labels,
            datasets,
          }}
          options={options}
        />
      </ChartContainer>
      {includeLegend && (
        <LegendContainer>
          <LegendLabel>
            <SelectDropdownButton
              showSelectedDirectly
              name="waterfall-graph__graph_period"
              options={WATERFALL_GRAPH_PERIOD_OPTIONS}
              selected={waterfallGraphPeriod}
              onSelect={(key) => setWaterfallGraphPeriod(WATERFALL_GRAPH_PERIOD_OPTIONS[key])}
              fontWeight={'900'}
              allCaps={true}
            />
            {hasSegmenting && (
              <TooltipContainer width={250} toolTipContent={HelpToolTipContent()}>
                <HelpIcon style={{ marginRight: 8 }} />
              </TooltipContainer>
            )}
            {hasSegmenting ? 'Segmented Total' : 'Waterfall chart'}
          </LegendLabel>
          <Legend>
            {hasSegmenting ? (
              <LegendKeysContainer>
                {showingSegmentObjects.map(({ key, label }) => {
                  // maximum of MAX_NUMBER_OF_ACTIVE_SEGMENTS segments
                  return (
                    <LegendKey key={key} active={true}>
                      <LegendKeyDot fill={segmentingChartColors[key]} />
                      <span>{label}</span>
                    </LegendKey>
                  );
                })}
                {showOtherSegment && (
                  <TooltipContainer
                    width={700}
                    toolTipContent={
                      <table>
                        <tbody>{generateOtherTableRows()}</tbody>
                      </table>
                    }
                  >
                    <LegendKey key="Other" active={true}>
                      <LegendKeyDot fill={SEGMENT_COLORS[MAX_NUMBER_OF_ACTIVE_SEGMENTS]} />
                      <span>{'Other'}</span>
                    </LegendKey>
                  </TooltipContainer>
                )}
              </LegendKeysContainer>
            ) : (
              <LegendKeysContainer>
                {defaultGraphSections.map((label) => {
                  // If the section for the label doesn't exist, don't show that legend
                  if (!sections.some((section) => section.title === label) && label !== 'Total') {
                    return <Fragment key={label}></Fragment>;
                  } else {
                    return (
                      <LegendKey
                        key={label}
                        active={graphSections.has(label)}
                        onClick={() => onChangeGraphSections(label)}
                      >
                        <LegendKeyDot fill={CHART_COLORS[label]} />
                        <span>{label}</span>
                      </LegendKey>
                    );
                  }
                })}
              </LegendKeysContainer>
            )}
            <BarChartSettings
              hasSegmenting={hasSegmenting}
              showingSegments={showingSegments}
              onChangeShowingSegments={onChangeShowingSegments}
              options={segments}
              showLabels={showLabels}
              setShowLabels={setShowLabels}
            />
          </Legend>
        </LegendContainer>
      )}
    </ChartWrapper>
  );
};
