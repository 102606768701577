import { css } from 'styled-components';
import { cssVar, transparentize } from 'polished';
import { SNAPSHOT_METRICS } from 'consts/snapshotMetrics';

export const METRIC_FILTER_GROUPS = {
  REVENUE_METRICS: 'Revenue Metrics',
  GROWTH_METRICS: 'Growth metrics',
  CUSTOMERS: 'Customers',
  RETENTION: 'Retention',
  LTV_CAC: 'LTV & CAC',
};

export const METRIC_GROUPS_BY_TITLE = {
  [METRIC_FILTER_GROUPS.REVENUE_METRICS]: [
    SNAPSHOT_METRICS.RECURRING_REVENUE.label,
    SNAPSHOT_METRICS.NON_RECURRING.label,
    SNAPSHOT_METRICS.TOTAL_PERIOD_REVENUE.label,
    SNAPSHOT_METRICS.PERCENTAGE_RECURRING.label,
    SNAPSHOT_METRICS.UP_FOR_RENEWAL.label,
    SNAPSHOT_METRICS.AMOUNT_RENEWED_THAT_WAS_UP.label,
    SNAPSHOT_METRICS.AMOUNT_RENEWED_THAT_WAS_UP_NET.label,
    SNAPSHOT_METRICS.BOOKINGS.label,
    SNAPSHOT_METRICS.BOOKINGS_NEW.label,
    SNAPSHOT_METRICS.GROSS_NEW_REVENUE.label,
    SNAPSHOT_METRICS.NET_NEW_REVENUE.label,
    SNAPSHOT_METRICS.NET_NEW_REVENUE_TTM.label,
    SNAPSHOT_METRICS.WINBACK_REVENUE.label,
    SNAPSHOT_METRICS.NON_RENEWAL_UPSELL.label,
    SNAPSHOT_METRICS.ARPU.label,
    SNAPSHOT_METRICS.ARPU_NEW.label,
  ],

  [METRIC_FILTER_GROUPS.GROWTH_METRICS]: [
    SNAPSHOT_METRICS.RECURRING_PERIOD_GROWTH.label,
    SNAPSHOT_METRICS.TOTAL_PERIOD_GROWTH.label,
    SNAPSHOT_METRICS.PERCENTAGE_RECURRING.label,
    SNAPSHOT_METRICS.ARPA.label,
    SNAPSHOT_METRICS.ARPA_TTM.label,
    SNAPSHOT_METRICS.ARPA_NEW.label,
    SNAPSHOT_METRICS.ARPA_NEW_TTM.label,
    SNAPSHOT_METRICS.GROSS_MARGIN.label,
    SNAPSHOT_METRICS.GROSS_MARGIN_TTM.label,
    SNAPSHOT_METRICS.GROSS_MARGIN_NON_RECURRING.label,
    SNAPSHOT_METRICS.GROSS_MARGIN_NON_RECURRING_TTM.label,
    SNAPSHOT_METRICS.MAGIC_NUMBER.label,
    SNAPSHOT_METRICS.SALES_EFFICIENCY_RATIO.label,
    SNAPSHOT_METRICS.PAYBACK_PERIOD.label,
    SNAPSHOT_METRICS.PAYBACK_PERIOD_TTM.label,
    SNAPSHOT_METRICS.RULE_OF_40_TTM.label,
    SNAPSHOT_METRICS.BURN_MULTIPLE.label,
    SNAPSHOT_METRICS.BURN_MULTIPLE_TTM.label,
  ],

  [METRIC_FILTER_GROUPS.CUSTOMERS]: [
    SNAPSHOT_METRICS.RECURRING_CUSTOMERS.label,
    SNAPSHOT_METRICS.CUSTOMERS.label,
    SNAPSHOT_METRICS.CUSTOMERS_WITH_OPEN_CONTRACTS.label,
    SNAPSHOT_METRICS.SEATS.label,
    SNAPSHOT_METRICS.CUSTOMERS_UP_FOR_RENEWAL.label,
    SNAPSHOT_METRICS.CUSTOMERS_RENEWED.label,
    SNAPSHOT_METRICS.WINBACK_CUSTOMERS.label,
  ],

  [METRIC_FILTER_GROUPS.RETENTION]: [
    SNAPSHOT_METRICS.GROSS_CUSTOMER_RETENTION.label,
    SNAPSHOT_METRICS.GROSS_CUSTOMER_RETENTION_TTM.label,
    SNAPSHOT_METRICS.NET_REVENUE_RETENTION.label,
    SNAPSHOT_METRICS.NET_REVENUE_RETENTION_TTM.label,
    SNAPSHOT_METRICS.GROSS_REVENUE_RETENTION.label,
    SNAPSHOT_METRICS.GROSS_REVENUE_RETENTION_TTM.label,
    SNAPSHOT_METRICS.GROSS_REVENUE_CHURN_RATE.label,
    SNAPSHOT_METRICS.GROSS_REVENUE_CHURN_RATE_TTM.label,
    SNAPSHOT_METRICS.CUSTOMER_UP_FOR_RENEWAL_RETENTION.label,
    SNAPSHOT_METRICS.GROSS_UP_FOR_RENEWAL_RETENTION.label,
    SNAPSHOT_METRICS.NET_UP_FOR_RENEWAL_RETENTION.label,
  ],

  [METRIC_FILTER_GROUPS.LTV_CAC]: [
    SNAPSHOT_METRICS.LTV.label,
    SNAPSHOT_METRICS.LTV_TTM.label,
    SNAPSHOT_METRICS.LTVGMLaden.label,
    SNAPSHOT_METRICS.LTVGMLaden_TTM.label,
    SNAPSHOT_METRICS.CAC_RECURRING.label,
    SNAPSHOT_METRICS.CAC_RECURRING_TTM.label,
    SNAPSHOT_METRICS.CAC_ALL.label,
    SNAPSHOT_METRICS.CAC_ALL_TTM.label,
    SNAPSHOT_METRICS.CEC_RECURRING.label,
    SNAPSHOT_METRICS.CEC_RECURRING_TTM.label,
    SNAPSHOT_METRICS.LTVCACRatio.label,
    SNAPSHOT_METRICS.LTVCACRatio_TTM.label,
    SNAPSHOT_METRICS.NEW_REVENUE_TO_COST_RATIO.label,
  ],
};

const getMetricRowBoxColor = ({ index }) => {
  switch (index % 8) {
    case 0:
      return css`
        background: ${transparentize(0.9, cssVar('--primaryRed'))};
        color: var(--primaryRed);
      `;
    case 1:
      return css`
        background: ${transparentize(0.9, cssVar('--primaryOrange'))};
        color: var(--primaryOrange);
      `;
    case 2:
      return css`
        background: ${transparentize(0.9, cssVar('--primaryYellow'))};
        color: var(--primaryYellow);
      `;
    case 3:
      return css`
        background: ${transparentize(0.9, cssVar('--primaryGreen'))};
        color: var(--primaryGreen);
      `;
    case 4:
      return css`
        background: ${transparentize(0.9, cssVar('--tertiaryGreen'))};
        color: var(--tertiaryGreen);
      `;
    case 5:
      return css`
        background: ${transparentize(0.9, cssVar('--primaryBlue'))};
        color: var(--primaryBlue);
      `;
    case 6:
      return css`
        background: ${transparentize(0.9, cssVar('--primaryPurple'))};
        color: var(--primaryPurple);
      `;
    case 7:
      return css`
        background: ${transparentize(0.9, cssVar('--secondaryPurple'))};
        color: var(--secondaryPurple);
      `;
    default:
      return css`
        background: #797979;
        color: #fff;
      `;
  }
};

export { getMetricRowBoxColor };
