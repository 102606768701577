import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const TransactionTooltipBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const TooltipInfo = styled.div`
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: 16px;
  color: rgba(255, 255, 255, 0.7);
  padding-bottom: 5px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
`;

export const TooltipTransactionName = styled.div`
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  color: white;
  text-align: left;
  margin-top: 8px;
  margin-bottom: 4px;
`;

export const TooltipTransactionInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 16px;
  color: rgba(255, 255, 255, 0.7);
  width: 100%;
  font-size: 12px;
`;

export const TooltipOpenTransactionLink = styled(Link)`
  padding: 8px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.15);
  margin-top: 8px;
  border-radius: 4px;
  color: white;
  font-size: 9px;
  font-style: normal;
  font-weight: 900;
  line-height: 12px;
  text-transform: uppercase;
`;
