import React from 'react';

import { FilterWrapper } from 'shared/Filters';
import { CohortFilterPopover } from './CohortFilterPopover';

//draftDataFilter, updateDraftDataFilter props come from the Filters component
export const CohortFilter = ({ draftDataFilter, updateDraftDataFilter }) => {
  return (
    <FilterWrapper style={{ marginLeft: 8 }}>
      <span>Metric: </span>
      <CohortFilterPopover draftDataFilter={draftDataFilter} updateDraftDataFilter={updateDraftDataFilter} />
    </FilterWrapper>
  );
};
