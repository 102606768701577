import { useContext } from 'react';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { Banner, BannerText } from 'components/Blocks/Banner';
import { TransactionContext } from '../TransactionContext';

export const OpenLockModalLink = styled(Link)`
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  margin: 0 4px;
  color: var(--primaryBlue);
  cursor: pointer;

  &:hover {
    color: var(--secondaryBlue);
  }
`;

export const TransactionLockedByDateWarning = () => {
  const { currentTransaction, isTransactionLocked, transactionsLockBeforeDate } = useContext(TransactionContext);

  return isTransactionLocked && !currentTransaction.replaced_by ? (
    <Banner marginTop="20px" tag="warning">
      <BannerText style={{ display: 'flex' }}>
        This transaction cannot be edited because
        <OpenLockModalLink to="/transactions?openLockModal=true">transaction modification is locked</OpenLockModalLink>
        until {dayjs.utc(transactionsLockBeforeDate).format('MMM DD, YYYY')}
      </BannerText>
    </Banner>
  ) : null;
};
