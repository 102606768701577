import styled from 'styled-components';

export const Section = styled.div`
  border-radius: 10px;
  border: 1px solid var(--primaryBlack5);
  box-sizing: border-box;
  padding: 15px 15px 10px;
  position: relative;
`;

export const SectionRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SectionTitle = styled.p`
  background: #fff;
  font-size: 10px;
  font-weight: 900;
  left: 15px;
  line-height: 14px;
  padding-right: 2px;
  position: absolute;
  text-transform: uppercase;
  top: -6px;
  width: fit-content;
`;
