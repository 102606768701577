import { useCallback, useContext, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Form, Formik } from 'formik';
import dayjs from 'dayjs';

import { AppContext } from 'AppContext';
import { EVENTS } from 'consts/analytics';
import { createCustomer } from 'api';
import {
  useUsageEventsAPI,
  useUniqueUsageEventNamesAPI,
  useUniqueUsageEventSourcesAPI,
} from 'api/usageBasedEngine/hooks';
import { Modal, ModalCloseIcon, ModalContainer, ModalFooter } from 'components/Modal';
import { Centerer, FlexBetweenContainer, Flexer, Spacer } from 'components/Core';
import { CustomDatePicker, FormikCustomInput, FormikCustomSelector } from 'components/Controls';
import { TooltipContainer } from 'components/Tooltip';
import { ReactComponent as CheckIcon } from 'images/transaction_check.svg';
import { ReactComponent as RelatedHelpIcon } from 'images/related-help.svg';
import { getCustomersFromSearch } from 'shared/TransactionContent';
import { getCustomerDisplayName } from 'models/customer';
import { formatDateForDatepicker, updateDateFromDatePicker } from 'utils/dateUtils';
import { useAnalytics } from 'utils/hooks';

import { createUsageEventValidationSchema } from './consts';

const Wrapper = styled.div`
  padding: 0 50px;
  background-color: ${({ bgcolor }) => bgcolor ?? 'var(--primaryGray)'};
`;

const Header = styled.div`
  font-size: 24px;
  line-height: 38px;
  margin-bottom: 20px;
  font-weight: 900;
`;

const ActionItem = styled(FlexBetweenContainer)`
  margin-bottom: 20px;
  gap: 12px;
`;

const FlexGrow = styled.div`
  width: 100%;
`;

const FormFooter = styled(ModalFooter)`
  width: 100%;
`;

const FormButtonsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const CancelButton = styled.div`
  background: var(--primaryBlack5);
  border: 1px solid var(--primaryBlack5);
  border-radius: 100px;
  margin-right: 14px;
  padding: 11px 14px;
  cursor: pointer;
  font-weight: 700;
`;

const SaveButton = styled.div`
  background: var(--primaryGreen);
  display: flex;
  align-items: center;
  border: 1px solid var(--primaryBlack5);
  border-radius: 100px;
  color: #ffffff;
  padding: 8px 14px;
  padding-right: 8px;
  cursor: pointer;
  pointer-events: ${(props) => props.isLoading && 'none'};
  opacity: ${(props) => props.isLoading && '0.3'};
  font-weight: 700;
`;

const StyledCheckIcon = styled(CheckIcon)`
  path {
    fill: white;
  }

  rect {
    fill: transparent;
  }
`;

const CheckIconWrapper = styled(Centerer)`
  border-radius: 50%;
  border: 1px solid white;
  width: 20px;
  height: 20px;
`;

const StyledRelatedHelpIcon = styled(RelatedHelpIcon)`
  width: 16px !important;
  height: 16px !important;
`;

const TooltipLabelContainer = styled(Flexer)`
  align-items: center;

  & svg {
    margin-left: 3px;

    & g {
      opacity: 1;
    }
    & path {
      fill: var(--primaryBlack40);
    }
  }

  &:hover {
    color: var(--primaryBlack);
    & svg path {
      fill: var(--primaryBlack);
    }
  }
`;

export const UsageEventCreateModal = ({ onClose }) => {
  const { orgId, dateFormat } = useContext(AppContext);

  const [selectedCustomerName, setSelectCustomerName] = useState(null);

  const {
    operations: { createUsageEvent },
  } = useUsageEventsAPI({ autoFetch: false, orgId });

  const { data: eventNameData, isLoading: isLoadingEvents } = useUniqueUsageEventNamesAPI({ orgId });
  const { data: eventSourceData, isLoading: isLoadingSources } = useUniqueUsageEventSourcesAPI({ orgId });

  const eventNames = useMemo(() => eventNameData?.data ?? [], [eventNameData?.data]);
  const eventSources = useMemo(() => eventSourceData?.data ?? [], [eventSourceData?.data]);

  const initialValues = {
    name: '',
    customer_id: null,
    timestamp: dayjs().utc(true).toDate(),
    source: 'Manual entry',
    units: 1,
  };

  return (
    <ModalContainer>
      <Modal
        overflow="visible"
        width="600px"
        minHeight="500px"
        data-cy="create-usage-events-modal"
        height="auto"
        maxHeight="auto"
      >
        <ModalCloseIcon onClose={onClose} />
        <Formik
          initialValues={initialValues}
          validationSchema={createUsageEventValidationSchema}
          onSubmit={async (values) => {
            await createUsageEvent.mutateAsync({ data: values });
            onClose();
          }}
        >
          {({ values, setFieldValue, handleSubmit, getFieldMeta }) => (
            <Form>
              <Wrapper>
                <Header>Add Usage Events</Header>
                <ActionItem>
                  <FlexGrow>
                    <FormikCustomSelector
                      label="Customer"
                      placeholder="Select customer"
                      value={
                        values?.customer_id
                          ? {
                              label: getCustomerDisplayName({
                                customerId: values?.customer_id,
                                customerName: selectedCustomerName,
                              }),
                              value: values?.customer_id,
                            }
                          : null
                      }
                      name="customer_id"
                      handleChange={(option) => {
                        setFieldValue('customer_id', option?.value ? Number(option.value) : null);
                        setSelectCustomerName(option?.label ?? null);
                      }}
                      loadOptions={(searchQuery, prevOptions, additional) =>
                        getCustomersFromSearch({ searchQuery, orgId, prevOptions, additional })
                      }
                      onCreateOption={async (newCustomerName) => {
                        const newCustomer = await createCustomer({
                          orgId,
                          customerName: newCustomerName,
                        });
                        setFieldValue('customer_id', newCustomer.id);
                        setSelectCustomerName(newCustomer.name);
                      }}
                      creatable
                      isPaginateable
                      isClearable
                    />
                  </FlexGrow>

                  <FlexGrow>
                    <CustomDatePicker
                      meta={getFieldMeta('timestamp')}
                      name="timestamp"
                      label="Event time"
                      selected={
                        values?.timestamp
                          ? formatDateForDatepicker(values?.timestamp, {
                              showTime: true,
                            })
                          : null
                      }
                      onChange={(value) =>
                        setFieldValue('timestamp', updateDateFromDatePicker(value, { includeTime: true }))
                      }
                      showTimeSelect
                      timeIntervals={1}
                      dateFormat={`${dateFormat ?? 'MM/dd/yyyy'} h:mm aa`}
                    />
                  </FlexGrow>
                </ActionItem>

                <ActionItem>
                  <FlexGrow>
                    <FormikCustomSelector
                      label="Event name"
                      placeholder="Select or create event name"
                      name="name"
                      value={values?.name ? { label: values.name, value: values.name } : null}
                      options={eventNames.map((eventName) => ({
                        label: eventName,
                        value: eventName,
                      }))}
                      handleChange={(option) => setFieldValue('name', option ? option.value : null)}
                      width="100%"
                      isClearable
                      creatable
                      isLoading={isLoadingEvents}
                    />
                  </FlexGrow>
                  <FlexGrow>
                    <FormikCustomInput
                      formik
                      type="number"
                      data-cy="usage-event-modal__units"
                      name="units"
                      label={
                        <TooltipContainer
                          toolTipContent="The number of units can be non integers or negative"
                          hideArrow
                          width={200}
                        >
                          <TooltipLabelContainer hasToolTip={true}>
                            <span>Units</span>
                            <StyledRelatedHelpIcon />
                          </TooltipLabelContainer>
                        </TooltipContainer>
                      }
                      placeholder="Enter units"
                      inputTextAlign="right"
                      disableLabelOpacity
                    />
                  </FlexGrow>
                </ActionItem>
                <ActionItem>
                  <FlexGrow>
                    <FormikCustomSelector
                      label={
                        <TooltipContainer
                          toolTipContent="To keep track of where this usage event comes from"
                          hideArrow
                          width={200}
                        >
                          <TooltipLabelContainer hasToolTip={true}>
                            <span>Source</span>
                            <StyledRelatedHelpIcon />
                          </TooltipLabelContainer>
                        </TooltipContainer>
                      }
                      placeholder="Select or create source"
                      name="source"
                      value={values?.source ? { label: values.source, value: values.source } : null}
                      options={eventSources.map((source) => ({
                        label: source,
                        value: source,
                      }))}
                      handleChange={(option) => setFieldValue('source', option ? option.value : null)}
                      width="100%"
                      isClearable
                      creatable
                      isLoading={isLoadingSources}
                    />
                  </FlexGrow>
                </ActionItem>
                <ActionItem>
                  <FormikCustomInput
                    formik
                    textarea
                    data-cy="usage-event-modal__notes"
                    name="notes"
                    label="Notes"
                    placeholder="Enter notes"
                    width="100%"
                    style={{
                      height: '150px',
                    }}
                  />
                </ActionItem>
              </Wrapper>

              <FormFooter>
                <FormButtonsRow>
                  <CancelButton onClick={onClose}>Cancel</CancelButton>
                  <SaveButton
                    onClick={handleSubmit}
                    isLoading={createUsageEvent.isLoading}
                    disabled={createUsageEvent.isLoading}
                    data-cy="create-usage-event-modal__save-button"
                  >
                    {createUsageEvent.isLoading ? 'Saving' : 'Save'}
                    <Spacer width="10px" />
                    <CheckIconWrapper>
                      <StyledCheckIcon height="20px" />
                    </CheckIconWrapper>
                  </SaveButton>
                </FormButtonsRow>
              </FormFooter>
            </Form>
          )}
        </Formik>
      </Modal>
    </ModalContainer>
  );
};

export const useUsageEventCreateModal = () => {
  const { trackEvent } = useAnalytics();
  const [showModal, setShowModal] = useState(false);
  const openModal = useCallback(() => {
    trackEvent({ name: EVENTS.OPEN_CREATE_USAGE_EVENT_MODAL });
    setShowModal(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setShowModal]);
  const closeModal = useCallback(() => setShowModal(false), [setShowModal]);

  const Modal = useCallback(() => showModal && <UsageEventCreateModal onClose={closeModal} />, [closeModal, showModal]);

  return {
    openModal,
    UsageEventCreateModal: Modal,
  };
};
