import React, { useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as HeaderIcon } from 'images/info-circle-icon.svg';
import { ReactComponent as TransactionsListRedIcon } from 'images/transactions-list-red-icon.svg';
import { ReactComponent as ArrowRightIcon } from 'images/arrows-bold-right-transparent-icon.svg';
import { useToasts } from 'components/Toasts';
import { Loader } from 'components/Loaders';
import { Modal, ModalBody, ModalButton, ModalContainer, ModalFooter, ModalHeader, ModalTitle } from 'components/Modal';
import { pluralize } from 'utils/stringUtils';
import { bulkConfirmTransactions } from 'api/transactions';

const StyledHeaderIcon = styled.div`
  background-color: #ffffff;
  border-radius: 50%;
  display: inline-block;
  left: 50%;
  padding: 11px;
  position: absolute;
  top: -20px;
  transform: translate(-50%, 0);
`;

const Infobox = styled.div`
  background-color: var(--primaryRed10);
  border-radius: 12px;
  margin-top: 14px;
  padding: 14px;
  text-align: center;
`;

const InfoboxHeader = styled.div`
  justify-content: center;
  display: flex;
  font-size: 10px;
  font-weight: 900;
  line-height: 14px;
  padding-bottom: 13px;
  text-transform: uppercase;

  svg:first-child {
    margin: -1px 9px 0 0;
  }

  svg:last-child {
    margin: 3px 9px;
  }
`;

const InfoboxSeparator = styled.div`
  border-top: 4px solid var(--primaryRed10);
  margin: 0 -13px 10px;
`;

const InfoboxTransactionsCount = styled.div`
  color: var(--primaryRed);
`;

export const TransactionAutoConfirmModal = ({ organization, transactionIds, onClose, onSuccess, onBulkProcess }) => {
  const { pushToast } = useToasts();
  const [unconfirmables, setUnconfirmables] = useState([]);
  const [confirmableIds, setConfirmableIds] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleConfirmClick = async () => {
    try {
      setLoading(true);

      const response = await bulkConfirmTransactions({
        orgId: organization.id,
        transactionIds: confirmableIds ? confirmableIds : transactionIds,
      });

      if (response.success) {
        pushToast(`${pluralize(response.confirmedIds.length, 'transaction')} were bulk confirmed`, 'success');
        if (unconfirmables.length > 0) {
          onBulkProcess({ transactions: unconfirmables, changed: response.confirmedIds.length > 0 });
        } else {
          onSuccess();
        }
      } else {
        setConfirmableIds(response.confirmableIds);
        setUnconfirmables(response.unconfirmableTransactions);
      }
    } catch (err) {
      pushToast('Failed to bulk Confirm!', 'error');
      console.error({ message: err.message, component: 'TransactionAutoConfirmModal', stack: err });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <ModalContainer>
        <Modal maxWidth="460px" height="auto" overflow="visible" compact>
          <StyledHeaderIcon>
            <HeaderIcon width="20px" height="20px" />
          </StyledHeaderIcon>
          <ModalHeader>
            <ModalTitle compact>
              <b>Transactions Bulk Confirmation</b>
            </ModalTitle>
          </ModalHeader>
          <ModalBody compact centered>
            <div>
              <b>Do you want to confirm {pluralize(transactionIds.length, 'transaction')}?</b>
              <br />
              We recommend manual confirmation unless you are confident that the imports are working perfectly. You can
              edit the transactions after confirming, too.
            </div>
            {loading && <Loader loaderStyles={{ height: 40 }} />}
            {unconfirmables.length > 0 && !loading && (
              <Infobox>
                <InfoboxHeader>
                  <TransactionsListRedIcon />
                  <InfoboxTransactionsCount>{pluralize(unconfirmables.length, 'transaction')}</InfoboxTransactionsCount>
                  <ArrowRightIcon />
                  Manual confirmation
                </InfoboxHeader>
                <InfoboxSeparator />
                <p>
                  Your <b>{pluralize(unconfirmables.length, 'transaction')}</b> cannot be confirmed automatically
                  because of missing parameters. You'll have to do those manually.
                </p>
              </Infobox>
            )}
          </ModalBody>
          <ModalFooter centered transparent>
            <ModalButton onClick={onClose}>No, cancel</ModalButton>
            <ModalButton
              className="primary"
              onClick={loading ? undefined : handleConfirmClick}
              disabled={loading}
              data-cy="transaction-auto-confirm-modal__submit-button"
            >
              {unconfirmables.length ? 'Confirm & Fill in the parameters' : 'Confirm'}
            </ModalButton>
          </ModalFooter>
        </Modal>
      </ModalContainer>
    </>
  );
};
