import React, { useContext, useState } from 'react';
import dayjs from 'dayjs';

import { AppContext } from 'AppContext';
import { useForecastDetailsAPI, FORECAST_DETAILS_SCOPE } from 'api/forecasts';
import { CircleLoader } from 'components/Loaders';
import { FORECAST_SCENARIO_TYPE } from '../consts';
import { ForecastModalContents, ForecastModalLayout } from '../Shared/ForecastModalLayout';
import { ForecastExistingBody } from '../ForecastedExistingModal/ForecastExistingBody';
import { ForecastNewBody } from '../ForecastedNewModal/ForecastNewBody';
import { ForecastUnweightedBody } from '../ForecastedUnweightedModal';
import { FORECAST_DETAILS_TYPE } from './consts';
import { HeaderTitle, HeaderWrapper, LoaderWrapper, MonthButton, MonthButtonsWrapper, MonthIndex } from './styles';
import { ForecastDetailsTypeSelector } from './ForecastDetailsTypeSelector';

export const ForecastedDetailsModal = ({
  scenarioId,
  scenarioName,
  initialMonth,
  scenarioMonths,
  forecastScenarioType = FORECAST_SCENARIO_TYPE.WEIGHTED,
  onClose,
}) => {
  // Changes here instead of in the hook so it does not rerender the whole modal on change
  const [selectedMonth, setSelectedMonth] = useState(initialMonth);
  const [selectedDetailsType, setSelectedDetailsType] = useState(FORECAST_DETAILS_TYPE.NEW);
  const handleForecastNewDetails = (month) => {
    setSelectedMonth(month);
    setSelectedDetailsType(FORECAST_DETAILS_TYPE.NEW);
  };

  const {
    orgId,
    appSettings: { isARR },
  } = useContext(AppContext);

  const {
    data,
    isLoading,
    operations: { editForecastDetails, editForecastDetailsAsync },
  } = useForecastDetailsAPI({
    orgId,
    forecastId: scenarioId,
    month: selectedMonth,
    scopes: Object.values(FORECAST_DETAILS_SCOPE),
    isARR,
  });

  return (
    <ForecastModalLayout onClose={onClose} data-cy="forecasted-details-modal">
      <HeaderWrapper>
        <HeaderTitle>Forecast of ({scenarioName || 'Unnamed Scenario'})</HeaderTitle>

        <MonthButtonsWrapper>
          {scenarioMonths.map((month, index) => (
            <MonthButton key={month} onClick={() => setSelectedMonth(month)} isSelected={month === selectedMonth}>
              <MonthIndex>{index + 1}</MonthIndex>
              <span>{dayjs(`${month}-15`).format('MMM YYYY')}</span>
            </MonthButton>
          ))}
        </MonthButtonsWrapper>
      </HeaderWrapper>

      <ForecastModalContents>
        {forecastScenarioType !== FORECAST_SCENARIO_TYPE.UNWEIGHTED && (
          <ForecastDetailsTypeSelector
            data={data}
            selectedMonth={selectedMonth}
            selectedDetailsType={selectedDetailsType}
            setSelectedDetailsType={setSelectedDetailsType}
          />
        )}

        {isLoading || !data ? (
          <LoaderWrapper>
            <CircleLoader name="forecasted-existing-customers-tables" />
          </LoaderWrapper>
        ) : forecastScenarioType === FORECAST_SCENARIO_TYPE.UNWEIGHTED ? (
          <ForecastUnweightedBody
            scenarioId={scenarioId}
            data={data}
            month={selectedMonth}
            editForecastDetails={editForecastDetails}
            editForecastDetailsAsync={editForecastDetailsAsync}
          />
        ) : selectedDetailsType === FORECAST_DETAILS_TYPE.EXISTING ? (
          <ForecastExistingBody
            scenarioId={scenarioId}
            data={data}
            month={selectedMonth}
            editForecastDetails={editForecastDetails}
            onNewRevenueDetailsClick={handleForecastNewDetails}
          />
        ) : (
          <ForecastNewBody
            scenarioId={scenarioId}
            data={data}
            month={selectedMonth}
            editForecastDetails={editForecastDetails}
          />
        )}
      </ForecastModalContents>
    </ForecastModalLayout>
  );
};
