import styled, { css } from 'styled-components';
import { useField } from 'formik';

import { FormikCustomInput } from 'components/Controls';
import { Flexer } from 'components/Core';
import { TooltipContainer } from 'components/Tooltip';
import { ISO_CODE_TO_SYMBOL } from 'consts/global';
import { ReactComponent as RelatedHelpIcon } from 'images/related-help.svg';
import { USAGE_BASED_BILLING_SCHEMES } from '../../consts';

const FlexGrow = styled.div`
  flex-grow: 1;

  ${({ hasToolTip }) =>
    hasToolTip &&
    css`
      & svg {
        margin-left: 3px;

        & g {
          opacity: 1;
        }
        & path {
          fill: var(--primaryBlack50);
        }
      }

      &:hover {
        & label {
          opacity: 1;
        }
      }
    `}
`;

const StyledRelatedHelpIcon = styled(RelatedHelpIcon)`
  width: 16px !important;
  height: 16px !important;
`;

export const PerUnitContent = ({ handleChange, draftPerUnitValue, name, currency }) => {
  const meta = useField(name);
  const { value: formikValue } = meta[0];
  const { setValue } = meta[2];

  const currencySymbol = ISO_CODE_TO_SYMBOL[currency] ?? '$';

  const perUnitTierIndex = draftPerUnitValue.findIndex(
    ({ billing_scheme }) => billing_scheme === USAGE_BASED_BILLING_SCHEMES.PER_UNIT,
  );
  const perUnitTier = draftPerUnitValue[perUnitTierIndex];

  const prepaidTierIndex = draftPerUnitValue.findIndex(
    ({ billing_scheme }) => billing_scheme === USAGE_BASED_BILLING_SCHEMES.PREPAID,
  );
  const prepaidTier = draftPerUnitValue[prepaidTierIndex];

  return (
    <Flexer gap="8px">
      <FlexGrow>
        <FormikCustomInput
          type="number"
          precision={8}
          name={`${name}.${perUnitTierIndex}.amount`}
          data-cy="pricing-plan-modal__billing-scheme__amount"
          label="Amount to charge per unit"
          placeholder="Enter amount"
          value={perUnitTier?.amount}
          handleChange={(value) => {
            const getNewValue = (tiers) =>
              tiers.map((tier) =>
                tier.billing_scheme === USAGE_BASED_BILLING_SCHEMES.PER_UNIT ? { ...tier, amount: value } : tier,
              );
            setValue(getNewValue(formikValue));
            handleChange(getNewValue(draftPerUnitValue));
          }}
          inputTextAlign="right"
          suffix={currencySymbol}
          customSuffixColor="var(--primaryBlack)"
          isSuffixStart={true}
          suffixStyle={{
            fontWeight: 400,
          }}
        />
      </FlexGrow>
      <FlexGrow hasToolTip>
        <TooltipContainer toolTipContent="The amount that the customer is expected to pay in advance. Subscript will auto-convert this into a prepaid tiered scheme with the according max units (if applicable)">
          <FormikCustomInput
            type="number"
            precision={8}
            name={`${name}.${prepaidTierIndex}.amount`}
            data-cy="pricing-plan-modal__billing-scheme__minimum-amount"
            placeholder="Enter amount"
            label={
              <Flexer alignItems="center">
                <div>Minimum Amount</div>
                <StyledRelatedHelpIcon />
              </Flexer>
            }
            value={prepaidTier?.amount}
            handleChange={(value) => {
              const getNewValue = (tiers) => {
                if (value) {
                  const baseData = {
                    billing_scheme: USAGE_BASED_BILLING_SCHEMES.PREPAID,
                    amount: value,
                    maximum_units: perUnitTier?.amount ? Math.round(value / perUnitTier.amount) : null,
                  };

                  return prepaidTier
                    ? tiers.map((tier) =>
                        tier.billing_scheme === USAGE_BASED_BILLING_SCHEMES.PREPAID
                          ? {
                              ...tier,
                              ...baseData,
                            }
                          : tier,
                      )
                    : [baseData, ...tiers];
                }

                return tiers.filter((tier) => tier.billing_scheme === USAGE_BASED_BILLING_SCHEMES.PER_UNIT);
              };
              setValue(getNewValue(formikValue));
              handleChange(getNewValue(draftPerUnitValue));
            }}
            inputTextAlign="right"
            suffix={currencySymbol}
            customSuffixColor="var(--primaryBlack)"
            isSuffixStart={true}
            suffixStyle={{
              fontWeight: 400,
            }}
          />
        </TooltipContainer>
      </FlexGrow>
    </Flexer>
  );
};
