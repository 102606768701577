import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  // Box styles
  width: 220px;
  height: 32px;
  background: #ffffff;
  box-shadow: 4px 4px 28px var(--primaryBlack5);
  border-radius: 4px;
  cursor: pointer;

  // Text styles
  font-weight: bold;
  font-size: 16px;
  color: #00152e;

  a {
    color: inherit;
  }
`;

export const WhiteButton = ({ children }) => <Wrapper className="flexer">{children}</Wrapper>;
