import React from 'react';
import styled from 'styled-components';
import { ReactComponent as _WarningIcon } from 'images/warning-icon.svg';
import { FlexerColumn } from 'components/Core';

const WarningIconWrapper = styled.div`
  position: absolute;
  top: 16px;
  right: 20px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const WarningIcon = styled(_WarningIcon)`
  height: 12px;

  path {
    fill: var(--primaryRed);
  }
`;

const WarningsContainer = styled(FlexerColumn)`
  width: 100%;
  margin-top: 15px;
  border: 1px solid var(--primaryRed40);
  border-radius: 12px;
  display: flex;
  position: relative;
  justify-content: flex-start;
  align-items: center;
`;

const WarningMessages = styled(FlexerColumn)`
  ${({ fullWidth }) => fullWidth && 'width: 100%;'}
  padding: 16px 16px 0px 16px;

  span {
    padding-bottom: 16px;
  }
`;

export const WarningBar = ({ warnings, fullWidth }) => (
  <WarningsContainer>
    <WarningIconWrapper>
      <WarningIcon />
    </WarningIconWrapper>
    <WarningMessages fullWidth={fullWidth}>
      {Object.entries(warnings).map(([index, warning]) => (
        <span key={`warning-${index}`}>
          <span>{`${warnings.length === 1 ? '' : `${parseInt(index) + 1}. `}`}</span>
          {warning}
        </span>
      ))}
    </WarningMessages>
  </WarningsContainer>
);
