import { useContext } from 'react';
import { SizedLoader } from 'components/Loaders';
import { ModalContainer, FullscreenModal } from 'components/Modal';
import { InvoicingScheduleChangesContainer } from 'shared/InvoicingScheduleChanges';
import { TRANSACTION_MODAL_MODE } from './consts';
import { TransactionContext } from './TransactionContext';
import { TransactionForm } from './TransactionForm';
import { TransactionHeader } from './TransactionHeader';
import { TransactionBody } from './TransactionBody';
import { TransactionFooter } from './TransactionFooter';
import { TransactionSidebar } from './TransactionSidebar';
import { TransactionDeleteModal } from './TransactionDeleteModal';
import { TransactionConfirmInvoicesModal } from './TransactionConfirmInvoicesModal';
import { TransactionCloseButton } from './TransactionCloseButton';
import { BoxShadow, ContentWrapper, LoaderContainer, ContentMain } from './TransactionLayout.styles';

export const TransactionLayout = () => {
  const {
    mode,
    currentTransaction,
    closeModal,
    setShowDeleteModal,
    showDeleteModal,
    showUpdateInvoicesModal,
    setShowUpdateInvoicesModal,
    loading,
    onTransactionDeleted,
    organization,
    setTransactionsBulk,
    transactionsBulk,
  } = useContext(TransactionContext);

  const handleTransactionDeleted = ({ deletedTransaction }) => {
    setShowDeleteModal(false);
    if (mode === TRANSACTION_MODAL_MODE.EDIT_BULK) {
      setTransactionsBulk(transactionsBulk.filter((t) => t.id !== currentTransaction.id));
    }
    onTransactionDeleted?.({ deletedTransaction });
  };

  return (
    <ModalContainer data-cy={`transaction-modal--${mode.toLowerCase()}`}>
      <FullscreenModal>
        {mode === TRANSACTION_MODAL_MODE.EDIT_BULK && (
          <>
            <BoxShadow width="95%" top="-10px" />
            <BoxShadow width="90%" top="-20px" opacity={0.5} />
          </>
        )}
        <ContentWrapper>
          {loading ? (
            <LoaderContainer>
              <SizedLoader size={40} />
            </LoaderContainer>
          ) : (
            <InvoicingScheduleChangesContainer>
              <TransactionForm>
                <ContentMain>
                  <TransactionHeader />
                  <TransactionBody />
                  <TransactionFooter />
                </ContentMain>
                <TransactionSidebar />
                <TransactionCloseButton />
                {showDeleteModal && (
                  <TransactionDeleteModal
                    organizationId={organization.id}
                    onClose={() => setShowDeleteModal(false)}
                    transaction={currentTransaction}
                    onDeleted={handleTransactionDeleted}
                  />
                )}
                {showUpdateInvoicesModal && (
                  <TransactionConfirmInvoicesModal
                    orgId={organization.id}
                    transaction={currentTransaction}
                    onClose={() => {
                      showDeleteModal && setShowDeleteModal(false);
                      setShowUpdateInvoicesModal(false);
                      closeModal?.();
                    }}
                  />
                )}
              </TransactionForm>
            </InvoicingScheduleChangesContainer>
          )}
        </ContentWrapper>
      </FullscreenModal>
    </ModalContainer>
  );
};
