import dayjs from 'dayjs';
import { isNil } from 'lodash';
import { assertCloseTo, assertNotGreaterThan } from './assertionUtils';

const checkRolledUpCustomerCount = ({ organization, user, cachedMetrics, context }) => {
  for (const [monthKey, withRollupCustomerCount] of Object.entries(cachedMetrics?.customerCount?.withRollup ?? {})) {
    if (isNil(withRollupCustomerCount) || isNaN(withRollupCustomerCount)) continue;
    const withoutRollupCustomerCount = cachedMetrics?.customerCount?.withoutRollup?.[monthKey];
    if (isNil(withoutRollupCustomerCount) || isNaN(withoutRollupCustomerCount)) continue;

    assertNotGreaterThan({
      organization,
      user,
      expected: withoutRollupCustomerCount,
      actual: withRollupCustomerCount,
      description: `rolled up customer count in ${monthKey}`,
      context,
    });
  }
};

const checkRolledUpCustomerWaterfall = ({ organization, user, cachedMetrics, context }) => {
  if (!cachedMetrics?.customerWaterfall?.withRollup || !cachedMetrics?.customerWaterfall?.withoutRollup) return;
  for (const [monthKey, withRollupCustomerWaterfall] of Object.entries(
    cachedMetrics?.customerWaterfall?.withRollup ?? {},
  )) {
    const withoutRollupCustomerWaterfall = cachedMetrics?.customerWaterfall?.withoutRollup[monthKey];
    // upsell and downsell not included since those could be greater when rolled up
    for (const metricName of ['New', 'Existing', 'Churn']) {
      const withRollupCustomerWaterfallValue = withRollupCustomerWaterfall?.[metricName];
      const withoutRollupCustomerWaterfallValue = withoutRollupCustomerWaterfall?.[metricName];
      if (!isNil(withRollupCustomerWaterfallValue) && !isNil(withoutRollupCustomerWaterfallValue)) {
        assertNotGreaterThan({
          organization,
          user,
          expected: withoutRollupCustomerWaterfallValue,
          actual: withRollupCustomerWaterfallValue,
          description: `Rolled up customer count in the waterfall for ${monthKey} and ${metricName}`,
          context,
        });
      }
    }
  }
};

const checkUpForRenewalIsLessThanRecurring = ({ organization, user, cachedMetrics, context }) => {
  for (const [monthKey, upForRenewal] of Object.entries(cachedMetrics?.upForRenewal ?? {})) {
    const previousMonth = dayjs(`${monthKey}-01`).subtract(1, 'month').format('YYYY-MM');
    const previousMonthRecurringRevenue = cachedMetrics?.recurringRevenue[previousMonth];
    if (!previousMonthRecurringRevenue) continue;
    assertNotGreaterThan({
      organization,
      user,
      expected: previousMonthRecurringRevenue,
      actual: upForRenewal,
      description: `up for renewal in ${monthKey}`,
      context,
    });
  }
};

const checkCohortARPANewAgainstDashboardARPANew = ({ organization, user, cachedMetrics, context }) => {
  if (!cachedMetrics.cohortsNewARPA || !cachedMetrics.dashboardNewARPA) return;

  const cohortMonths = new Set([...Object.keys(cachedMetrics.cohortsNewARPA)]);

  // gets the months in common
  const monthKeys = Object.keys(cachedMetrics.dashboardNewARPA).filter((month) => cohortMonths.has(month));

  for (const monthKey of monthKeys) {
    // skip future months
    if (dayjs(monthKey, 'YYYY-MM').isAfter(dayjs(), 'month')) continue;

    const cohortsNewARPA = cachedMetrics.cohortsNewARPA[monthKey];
    const dashboardNewARPA = cachedMetrics.dashboardNewARPA[monthKey];

    // only checks months that don't have reactivation customers
    if (cohortsNewARPA.customerCount === dashboardNewARPA.customerStatsNew) {
      const assertionPassed = assertCloseTo({
        organization,
        user,
        expected: cohortsNewARPA.totalAmount,
        actual: dashboardNewARPA.value,
        description: `month ${monthKey} New ARPA ({cohorts: ${cohortsNewARPA.sum}, dashboard: ${dashboardNewARPA.value})`,
        context,
      });

      if (!assertionPassed) return;
    }
  }
};

// In any given month:
// - Classical Recurring Revenue should stay the same with or without roll-up
// - Committed Recurring Revenue with roll-up should not exceed Committed Recurring Revenue without roll-up
const checkRecurringRevenueWithRespectToRollUp = ({ organization, user, cachedMetrics, context }) => {
  ['committed', 'classical'].forEach((revenueMode) => {
    const data = cachedMetrics?.recurringRevenue?.[revenueMode];
    if (!data || !data.withRollup || !data.withoutRollup) return;

    Object.keys(data.withoutRollup).forEach((monthKey) => {
      if (data.withRollup[monthKey]) {
        const assertFn = revenueMode === 'classical' ? assertCloseTo : assertNotGreaterThan;

        assertFn({
          organization,
          user,
          expected: data.withoutRollup[monthKey],
          actual: data.withRollup[monthKey],
          description: 'recurring revenue in roll-up mode (with respect to non-roll-up mode)',
          context,
        });
      }
    });
  });
};

export const INVARIANT_CHECKERS = [
  checkRolledUpCustomerCount,
  checkUpForRenewalIsLessThanRecurring,
  checkRolledUpCustomerWaterfall,
  checkCohortARPANewAgainstDashboardARPANew,
  checkRecurringRevenueWithRespectToRollUp,
];
