import { useState } from 'react';
import styled from 'styled-components';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';

const SubscribeButton = styled.button`
  align-items: center;
  background-color: var(--primaryGreen);
  border-radius: 100px;
  border: 1px solid rgba(30, 183, 110, 0.1);
  box-shadow: 0px 4px 20px rgba(82, 181, 120, 0.2);
  color: white;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  justify-content: center;
  height: 60x;
  margin-top: 16px;
  padding: 11px 14px;
  width: 100%;

  &:hover {
    background-color: var(--secondaryGreen);
  }
`;

const ErrorMessage = styled.div`
  margin-top: 12px;
  color: #df1b41;
  font-size: 0.93rem;
  font-family: -apple-system, 'system-ui', 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue',
    sans-serif;
`;

export const CheckoutForm = ({ translations }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) return;

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: window.location.href,
      },
    });

    if (error) {
      setErrorMessage(error.message);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <SubscribeButton disabled={!stripe}>
        {translations?.['Submit Payment Details'] ?? 'Submit Payment Details'}
      </SubscribeButton>
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </form>
  );
};
