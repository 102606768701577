import { useCallback, useContext, useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import { AppContext } from 'AppContext';
import {
  Modal,
  ModalBody,
  ModalButton,
  ModalCloseIcon,
  ModalContainer,
  ModalFooter,
  ModalTitle,
} from 'components/Modal';
import { Spacer } from 'components/Core';
import { useEntityAPI } from 'api/billingEntities/hooks';
import { useInvoiceMemoTemplatesAPI } from 'api/invoiceMemoTemplates/hooks';
import { DEFAULT_AVAILABLE_TAGS } from 'consts/billing';
import { useGetAvailableTags } from 'api/billing';

import { BillingCompanyInformation } from './BillingCompanyInformation';
import { BillingEntityMemoInput } from './BillingEntityMemoInput';

const validationSchema = Yup.object({
  company_name: Yup.string().trim().required('Please enter company name'),
  address: Yup.string().required('Please enter company address'),
  footer_memo_template_id: Yup.number().nullable(),
  memo_template_id: Yup.number().nullable(),
});

export const BillingEntityModal = ({ onClose, entity }) => {
  const { orgId } = useContext(AppContext);

  const {
    operations: { createEntity, updateEntity },
  } = useEntityAPI({ orgId, autoFetch: false });

  const { data: templates = [], isLoading: isLoadingTemplates } = useInvoiceMemoTemplatesAPI({ orgId });

  const {
    transactionMetadataTags = [],
    customerMetadataTags = [],
    transactionCustomerMetadataTags = [],
  } = useGetAvailableTags({ orgId });

  const handleSave = async (values) => {
    const { id, ...dataToUpdate } = values;

    if (id) {
      await updateEntity({ data: dataToUpdate, entityId: values.id });
    } else {
      await createEntity(dataToUpdate);
    }
    onClose();
  };

  return (
    <>
      <ModalContainer>
        <Formik initialValues={entity} validationSchema={validationSchema} onSubmit={handleSave} enableReinitialize>
          {({ values, setFieldValue, submitForm }) => (
            <Form>
              <Modal overflow="auto" width="600px" minHeight="400px" height="auto" maxHeight="90vh">
                <ModalTitle bold>Sender organization</ModalTitle>
                <ModalCloseIcon onClick={onClose} />

                <ModalBody>
                  <Spacer height="16px" />
                  <BillingCompanyInformation values={values} setFieldValue={setFieldValue} />

                  <BillingEntityMemoInput
                    values={values}
                    setFieldValue={setFieldValue}
                    templates={templates}
                    name="memo_template_id"
                    label="Memo template:"
                    generalTags={DEFAULT_AVAILABLE_TAGS}
                    transactionMetadataTags={transactionMetadataTags}
                    customerMetadataTags={customerMetadataTags}
                    transactionCustomerMetadataTags={transactionCustomerMetadataTags}
                    isLoadingTemplates={isLoadingTemplates}
                  />

                  <BillingEntityMemoInput
                    values={values}
                    setFieldValue={setFieldValue}
                    templates={templates}
                    name="footer_memo_template_id"
                    label="Footer memo template:"
                    generalTags={DEFAULT_AVAILABLE_TAGS}
                    transactionMetadataTags={transactionMetadataTags}
                    customerMetadataTags={customerMetadataTags}
                    transactionCustomerMetadataTags={transactionCustomerMetadataTags}
                    isLoadingTemplates={isLoadingTemplates}
                  />
                  <Spacer height="16px" />
                </ModalBody>

                <ModalFooter flexEnd>
                  <ModalButton onClick={onClose}>Close</ModalButton>
                  <ModalButton primary onClick={submitForm} data-cy="entity-modal__save-button">
                    Save
                  </ModalButton>
                </ModalFooter>
              </Modal>
            </Form>
          )}
        </Formik>
      </ModalContainer>
    </>
  );
};

export const useBillingEntityModal = () => {
  const [selectedEntity, setSelectedEntity] = useState(null);
  const openModal = useCallback((entity) => {
    setSelectedEntity(entity);
  }, []);

  const Modal = useCallback(
    () =>
      selectedEntity ? <BillingEntityModal onClose={() => setSelectedEntity(null)} entity={selectedEntity} /> : null,
    [selectedEntity],
  );
  return {
    openBillingEntityModal: openModal,
    BillingEntityModal: Modal,
  };
};
