import * as Yup from 'yup';
import {
  INTERNAL_CUSTOMER_ID_SOURCE,
  NAME_MATCHING_SOURCE,
  SOURCE_TYPES,
} from 'views/Transactions/TransactionsCsvUpload/consts';

export const getSchema = ({
  customers,
  parentCustomers,
  customerIdSourceAndType,
  parentCustomerIdSourceAndType,
  uploadCsvCustomersIdSource,
}) => {
  const validCustomerIds = [INTERNAL_CUSTOMER_ID_SOURCE, NAME_MATCHING_SOURCE].includes(customerIdSourceAndType?.source)
    ? (customers ?? []).map(({ id }) => id)
    : customers?.map((customer) =>
        customerIdSourceAndType?.type === SOURCE_TYPES.INTERNAL
          ? customer.metadata?.[uploadCsvCustomersIdSource]
          : customer.provider_object_id,
      );

  const validParentCustomerIds = [INTERNAL_CUSTOMER_ID_SOURCE, NAME_MATCHING_SOURCE].includes(
    parentCustomerIdSourceAndType?.source,
  )
    ? (parentCustomers ?? []).map(({ id }) => id)
    : parentCustomers?.map((parentCustomer) =>
        parentCustomerIdSourceAndType?.type === SOURCE_TYPES.INTERNAL
          ? parentCustomer.metadata?.[uploadCsvCustomersIdSource]
          : parentCustomer.provider_object_id,
      );

  const createSchema = Yup.array().of(
    Yup.object({
      name: Yup.string().nullable(),
      email: Yup.string().nullable(),
      parent_customer_id: Yup.string()
        .nullable()
        .oneOf(['', null, undefined, ...(validParentCustomerIds ?? [])], "We can't find that parent customer"),
      contacts: Yup.string().nullable(),
      addressLine1: Yup.string().nullable(),
      addressLine2: Yup.string().nullable(),
      city: Yup.string().nullable(),
      state: Yup.string().nullable(),
      postalcode: Yup.string().nullable(),
      country: Yup.string().nullable(),
    }),
  );

  const updateSchema = createSchema.concat(
    Yup.array().of(
      Yup.object({
        customer_id: Yup.string()
          .nullable()
          .test('oneOf customes', "We can't find that customer", (value) => {
            return value !== null && (validCustomerIds ?? []).includes(value);
          })
          .when(['parent_customer_id'], (parent_customer_id, schema) => {
            if (!parent_customer_id) return schema;
            return schema.notOneOf([parent_customer_id], 'Customer ID cannot be the same as the Parent Customer Id');
          }),
      }),
    ),
  );

  return { updateSchema, createSchema };
};
