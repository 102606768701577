import { useContext } from 'react';
import styled from 'styled-components';

import { AppContext } from 'AppContext';
import { Centerer, FlexBetweenContainer, Flexer, FlexerColumn } from 'components/Core';
import { SendIcon, FilePlus } from 'components/Icons';
import { ReactComponent as ReminderIcon } from 'images/message-2.svg';
import { ReactComponent as BellIcon } from 'images/bell-ringing.svg';
import { ReactComponent as CelebrateIcon } from 'images/celebrate.svg';
import { ReactComponent as ReceiptIcon } from 'images/receipt.svg';
import { numberFormatter } from 'utils/formatters';
import { NUMBER_FORMATS } from 'consts/global';

import { HISTORY_TYPE } from './const';

const ContentBox = styled.div`
  border: 1px solid var(--accentGraySecond);
  background-color: white;
  border-radius: 16px;
  width: ${({ width }) => width ?? '100%'};
`;

const ContentWrapper = styled(FlexBetweenContainer)`
  font-weight: ${({ disableBold }) => (disableBold ? 400 : 700)};
  font-size: 12px;
  padding: 12px 16px;
  gap: 10px;
  color: var(--primaryBlack);
  width: ${({ width }) => width};
`;

const LinkText = styled.div`
  color: var(--primaryBlue);
  font-weight: 400;
  font-size: 12px;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const DateText = styled.span`
  color: var(--primaryBlack50);
  font-size: 12px;
`;

const Line = styled.div`
  width: 100%;
  height: 1px;
  background: var(--accentGraySecond);
`;

const StyledReminderIcon = styled(ReminderIcon)`
  width: 20px;
  height: 20px;

  path {
    fill: var(--tertiaryYellow);
  }
`;

const StyledSendIcon = styled(SendIcon)`
  path {
    fill: var(--darkGreen);
  }
`;

const StyledReceiptIcon = styled(ReceiptIcon)`
  width: 16px;
  height: 16px;

  path {
    fill: var(--darkGreen);
  }
`;

const StyledBellIcon = styled(BellIcon)`
  path {
    fill: var(--tertiaryRed);
  }
`;

const CelebrateIconWrapper = styled(Centerer)`
  border-radius: 12px;
  padding: 8px;
  background-color: var(--darkGreen10);
  width: fit-content;
`;

const StyledFilePlus = styled(FilePlus)`
  width: 20px;
  height: 20px;

  path {
    fill: var(--accentDarkGray);
  }
`;

const GreenText = styled.span`
  color: var(--secondaryGreen);
`;

const TimelineDecoratorWrapper = styled(FlexerColumn)`
  align-items: center;
`;

const TimelineDecoratorLine = styled.div`
  width: 2px;
  height: 12px;
  background-color: var(--primaryBlack20);
  margin-right: 1px;
`;

const TimelineDecoratorCircle = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 2px solid var(--primaryBlack20);
`;

export const TimelineItem = ({ item, isHead, onOpenEmailDetailModal, handleSwitchInvoice, openReceiptModal }) => {
  const {
    appSettings: { currencyISOCode: defaultOrgCurrency },
  } = useContext(AppContext);

  const invoiceIdentifierDisplay = `Invoice ${item.invoiceNumber ? `#${item.invoiceNumber}` : `ID ${item.id}`}`;

  const renderContent = () => {
    switch (item.type) {
      case HISTORY_TYPE.INVOICE_SENT:
        return (
          <ContentBox>
            <FlexerColumn>
              <ContentWrapper>
                <Flexer alignItems="center" gap="4px">
                  <StyledSendIcon />
                  <span>{`${invoiceIdentifierDisplay} was sent`}</span>
                </Flexer>
                <LinkText
                  onClick={() => {
                    onOpenEmailDetailModal({ invoiceId: item.id });
                  }}
                >
                  See email
                </LinkText>
              </ContentWrapper>

              <Line />

              <ContentWrapper disableBold>
                {(item.data?.email_addresses ?? []).slice(0, 2).join(', ')}
                {(item.data?.email_addresses ?? []).length > 2 ? `, +${item.data?.email_addresses.length - 2}` : ''}
              </ContentWrapper>
            </FlexerColumn>
          </ContentBox>
        );
      case HISTORY_TYPE.REMINDER:
        return (
          <ContentBox>
            <FlexerColumn>
              <ContentWrapper>
                <Flexer alignItems="center" gap="4px">
                  <StyledReminderIcon />
                  <span>
                    The{' '}
                    {item.count === 1 ? '1st' : item.count === 2 ? '2nd' : item.count === 3 ? '3rd' : `${item.count}th`}
                    {' reminder was sent'}
                  </span>
                </Flexer>
                <LinkText
                  onClick={() => {
                    onOpenEmailDetailModal({ invoiceId: item.id, reminder: item.data });
                  }}
                >
                  See email
                </LinkText>
              </ContentWrapper>

              <Line />

              <ContentWrapper disableBold>
                {(item.data?.sent_to ?? []).slice(0, 2).join(', ')}
                {(item.data?.sent_to ?? []).length > 2 ? `, +${item.data?.sent_to.length - 2}` : ''}
              </ContentWrapper>
            </FlexerColumn>
          </ContentBox>
        );
      case HISTORY_TYPE.OVERDUE:
        return (
          <ContentBox>
            <ContentWrapper>
              <Flexer>
                <StyledBellIcon />
                <span>{`${invoiceIdentifierDisplay} is overdue`}</span>
              </Flexer>
            </ContentWrapper>
          </ContentBox>
        );
      case HISTORY_TYPE.VOIDED:
        return (
          <ContentBox>
            <ContentWrapper>
              <Flexer>
                <StyledBellIcon />
                <span>{`${invoiceIdentifierDisplay} is voided`}</span>
              </Flexer>
            </ContentWrapper>
          </ContentBox>
        );
      case HISTORY_TYPE.PAID:
        return (
          <ContentBox width="fit-content">
            <ContentWrapper>
              <FlexerColumn gap="8px" alignItems="center">
                <CelebrateIconWrapper>
                  <CelebrateIcon />
                </CelebrateIconWrapper>

                <span>
                  The customer paid{' '}
                  <GreenText>
                    {numberFormatter({
                      type: NUMBER_FORMATS.CURRENCY,
                      rawValue: item.amount,
                      decimalPlaces: 2,
                      currency: item.currency ?? defaultOrgCurrency,
                    })}
                  </GreenText>
                </span>
              </FlexerColumn>
            </ContentWrapper>
          </ContentBox>
        );
      case HISTORY_TYPE.RECEIPT_SENT:
        return (
          <ContentBox>
            <FlexerColumn>
              <ContentWrapper>
                <Flexer alignItems="center" gap="4px">
                  <StyledReceiptIcon />
                  <span>
                    The receipt{' '}
                    <GreenText>
                      {numberFormatter({
                        type: NUMBER_FORMATS.CURRENCY,
                        rawValue: item.amount,
                        decimalPlaces: 2,
                        currency: item.currency ?? defaultOrgCurrency,
                      })}
                    </GreenText>{' '}
                    was sent
                  </span>
                </Flexer>
                <LinkText
                  onClick={() => {
                    openReceiptModal({ receiptId: item.data.id });
                  }}
                >
                  See email
                </LinkText>
              </ContentWrapper>

              <Line />

              <ContentWrapper disableBold>
                {(item.data?.send_to ?? []).slice(0, 2).join(', ')}
                {(item.data?.send_to ?? []).length > 2 ? `, +${item.data?.send_to.length - 2}` : ''}
              </ContentWrapper>
            </FlexerColumn>
          </ContentBox>
        );
      default:
        return (
          <ContentBox>
            <ContentWrapper>
              <Flexer>
                <StyledFilePlus />
                <span>{`${invoiceIdentifierDisplay} was created or imported`} </span>
              </Flexer>
            </ContentWrapper>
          </ContentBox>
        );
    }
  };

  return (
    <FlexerColumn alignItems="center" gap="8px">
      {!isHead && (
        <TimelineDecoratorWrapper>
          <TimelineDecoratorLine />
          <TimelineDecoratorCircle />
        </TimelineDecoratorWrapper>
      )}

      <div>
        <LinkText
          style={{
            display: 'inline',
          }}
          onClick={() => handleSwitchInvoice(item.id)}
        >
          {invoiceIdentifierDisplay}
        </LinkText>
        <DateText>, {item.formattedDate}</DateText>
      </div>
      {renderContent()}
    </FlexerColumn>
  );
};
