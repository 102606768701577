import React, { useCallback, useRef, useState } from 'react';
import { isEmpty } from 'lodash';
import { EVENTS } from 'consts/analytics';
import { useAnalytics } from 'utils/hooks';
import { InvoicingScheduleContainer } from './InvoicingScheduleContainer';

export const useInvoicingScheduleModal = ({ onClose } = {}) => {
  const { trackEvent } = useAnalytics();
  const [showModal, setShowModal] = useState(false);
  const [invoicingSchedule, setInvoicingSchedule] = useState();
  const [selectedInvoice, setSelectedInvoice] = useState();
  const [selectedReminder, setSelectedReminder] = useState();
  const [initialTab, setInitialTab] = useState();
  const [includedTransactions, setIncludedTransactions] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState();
  const [options, setOptions] = useState();

  //used to auto-generate invoices only once if options.defualtFrequency is defined
  const initialOpenRef = useRef();

  const onInvoicingScheduleCreated = useCallback((schedule) => {
    setInvoicingSchedule(schedule);
  }, []);

  const onInvoicingScheduleUpdated = useCallback((schedule) => {
    setInvoicingSchedule(schedule);
  }, []);

  const onInvoicingScheduleDeleted = useCallback((schedule) => {
    setInvoicingSchedule(schedule);
  }, []);

  const openModal = useCallback(
    ({ invoicingSchedule, invoice, includedTransactions, selectedReminder, initialTab, customer, options }) => {
      setSelectedReminder(selectedReminder);
      setInvoicingSchedule(invoicingSchedule ?? null);
      setIncludedTransactions(includedTransactions ?? null);
      setInitialTab(initialTab ?? null);
      setSelectedInvoice(invoice ?? null);
      setSelectedCustomer(customer);
      setOptions(options);
      setShowModal(true);

      initialOpenRef.current = !!options?.defaultFrequency;

      if (invoicingSchedule) {
        trackEvent({
          name: EVENTS.OPEN_INVOICING_SCHEDULE_MODAL,
          properties: { mode: 'EDIT', invoicingScheduleId: invoicingSchedule.id },
        });
      } else {
        trackEvent({
          name: EVENTS.OPEN_INVOICING_SCHEDULE_MODAL,
          properties: { mode: 'CREATE', preselectedTransactions: !isEmpty(includedTransactions) },
        });
      }
    },
    [setShowModal, setInvoicingSchedule, setSelectedInvoice, setSelectedCustomer, trackEvent],
  );

  const closeModal = useCallback(() => {
    setShowModal(false);
    initialOpenRef.current = false;
    onClose && onClose();
  }, [onClose]);

  const Modal = useCallback(
    () =>
      showModal ? (
        <InvoicingScheduleContainer
          selectedReminderId={selectedReminder?.id ?? null}
          selectedInvoiceId={selectedInvoice?.id ?? null}
          invoicingScheduleId={invoicingSchedule?.id ? invoicingSchedule?.id : null}
          includedTransactions={includedTransactions}
          selectedCustomer={selectedCustomer}
          initialTab={initialTab}
          options={options}
          closeModal={closeModal}
          onInvoicingScheduleCreated={onInvoicingScheduleCreated}
          onInvoicingScheduleDeleted={onInvoicingScheduleDeleted}
          onInvoicingScheduleUpdated={onInvoicingScheduleUpdated}
          setSelectedInvoice={setSelectedInvoice}
          initialOpenRef={initialOpenRef}
        />
      ) : null,
    [
      showModal,
      selectedReminder,
      selectedInvoice,
      invoicingSchedule,
      includedTransactions,
      initialTab,
      selectedCustomer,
      options,
      closeModal,
      onInvoicingScheduleCreated,
      onInvoicingScheduleDeleted,
      onInvoicingScheduleUpdated,
    ],
  );

  return {
    openModal,
    Modal,
  };
};
