import styled from 'styled-components';
import { InlineButton } from 'components/Buttons';
import { CentererVertical } from 'components/Core';

const Wrapper = styled(CentererVertical)`
  opacity: ${({ disabled }) => disabled && '0.3'};
  cursor: ${({ disabled }) => !disabled && 'pointer'};
  gap: 4px;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};

  svg {
    width: 14px;
    height: 14px;

    path {
      fill: var(--primaryBlue);
    }
  }

  &:hover {
    svg {
      path {
        fill: var(--secondaryBlue);
      }
    }
  }
`;

export const ActionButton = ({ onClick, children, disabled, Icon, ...props }) => (
  <Wrapper onClick={onClick} disabled={disabled}>
    {Icon && <Icon color="var(--primaryBlue)" />}
    <InlineButton withBackground isSecondary disabled={disabled} {...props}>
      {children}
    </InlineButton>
  </Wrapper>
);
