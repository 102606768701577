export const groupProductsByCategory = (products) => {
  const groupedProducts = [];

  products?.forEach((product) => {
    if (product.product_category_id) {
      const categoryGroup = groupedProducts.find((row) => row.id === product.product_category_id);
      if (categoryGroup) {
        categoryGroup.subRows.push(product);
      } else {
        groupedProducts.push({ id: product.product_category_id, subRows: [product] });
      }
    } else {
      groupedProducts.push(product);
    }
  });

  return groupedProducts;
};
