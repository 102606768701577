import styled from 'styled-components';
import { Centerer } from 'components/Core';
import { AddNewButton } from 'components/Buttons';

export const GridContainer = styled.div`
  max-height: 40vh;
  overflow: auto;

  display: grid;
  grid-template-columns: 38px 1fr 1fr 1fr 38px;
  grid-column-gap: 10px;

  grid-row-gap: 10px;
`;

export const RightSideHeaderContainer = styled.div`
  grid-column: 4 / span 2;

  display: flex;
  justify-content: space-between;
`;

export const TextStyle = styled(Centerer)`
  font-weight: 900;
  font-size: 12px;
  justify-content: ${(props) => props.justifyContent ?? 'start'};
`;

export const NoPeriodsText = styled(TextStyle)`
  grid-column: 1 / span 5;
`;

export const PaddedAddNewButton = styled(AddNewButton)`
  padding: 0px 12px;
`;

export const PeriodHeader = ({ onAddPeriod }) => (
  <>
    <TextStyle justifyContent="center"> # </TextStyle>
    <TextStyle> TYPE </TextStyle>
    <TextStyle> PERIOD </TextStyle>
    <RightSideHeaderContainer>
      <TextStyle>VALUE</TextStyle> <PaddedAddNewButton onClick={onAddPeriod}>ADD PERIOD</PaddedAddNewButton>
    </RightSideHeaderContainer>
  </>
);

const StyledIndexDisplayCircle = styled(Centerer)`
  flex-shrink: 0;
  border-radius: 100px;
  width: 38px;
  height: 38px;

  background: var(--accentGray);
`;

const DisplayStyle = styled(Centerer)`
  font-weight: 900;
  opacity: 0.6;
`;

export const IndexDisplayCircle = ({ children }) => (
  <StyledIndexDisplayCircle>
    <DisplayStyle>{children}</DisplayStyle>
  </StyledIndexDisplayCircle>
);
