import { createFilter } from 'react-select';
import { SEARCH_CONFIDENCE } from 'utils/stringUtils';
import { FormikCustomSelector } from 'components/Controls';

import { CustomSingleOption } from './columns';
import { getFuzzyCellData } from 'shared/CsvUpload/CsvTable/getFuzzyCellData';
import { INTERNAL_PRODUCT_ID_SOURCE, NAME_MATCHING_SOURCE } from 'views/Transactions/TransactionsCsvUpload/consts';
import { getProductName } from 'views/Cohorts/utils';

export const renderProductIdCell = ({
  row,
  cell: { value },
  formCurrent,
  fuzzyRowsMapper,
  products,
  setFuzzyRowsMapper,
  productIdSourceAndType,
}) => {
  const index = row.original.rowIndex;

  const displayedValue = value
    ? {
        value,
        label: [NAME_MATCHING_SOURCE, INTERNAL_PRODUCT_ID_SOURCE].includes(productIdSourceAndType?.source)
          ? getProductName(products, value)
          : products?.find((product) => product?.chiffer_object_id.toString() === value.toString())?.metadata
              ?.product_name ?? value,
      }
    : null;

  if (productIdSourceAndType?.source === NAME_MATCHING_SOURCE) {
    if (value && formCurrent && !fuzzyRowsMapper?.[index]?.product_id?.fuzzySearchCompleted) {
      const {
        candidates: candidatesForMapper,
        fuzzyTooltip: fuzzyTooltipForMapper,
        confidence: confidenceForMapper,
        value: fuzzyValue,
      } = getFuzzyCellData({
        rowValue: value,
        dataset: products,
        candidateValueKey: 'id',
        candidateTitleKey: 'name',
        columns: ['name'],
      });

      setFuzzyRowsMapper((prevMapper) => ({
        ...prevMapper,
        [index]: {
          ...(prevMapper?.[index] ?? {}),
          product_id: {
            fuzzySearchCompleted: true,
            fuzzyTooltip: fuzzyTooltipForMapper,
            candidates: candidatesForMapper,
            confidence: confidenceForMapper,
          },
        },
      }));

      formCurrent?.setFieldValue(`[${index}].product_id`, fuzzyValue);
    }
  }

  const { fuzzyTooltip, confidence } = fuzzyRowsMapper?.[index]?.product_id ?? {};

  const options = products?.map((product) => ({
    value: [NAME_MATCHING_SOURCE, INTERNAL_PRODUCT_ID_SOURCE].includes(productIdSourceAndType?.source)
      ? product.id
      : product.chiffer_object_id,
    label:
      product.name ??
      product?.metadata?.product_name ??
      `No name / ${productIdSourceAndType?.source === NAME_MATCHING_SOURCE ? product.id : product.provider_object_id}`,
  }));

  return (
    <FormikCustomSelector
      height={32}
      minWidth={160}
      menuWidth={200}
      //optimization thing
      filterOption={createFilter({ ignoreAccents: false })}
      errorWithoutTooltip
      isClearable={true}
      placeholder="Select product..."
      name={`[${row.original.rowIndex}].product_id`}
      hasWarning={confidence === SEARCH_CONFIDENCE.FUZZY}
      tooltipInputDisplay={fuzzyTooltip}
      handleChange={(option) => {
        if (option) {
          formCurrent?.setFieldValue(`[${row.original.rowIndex}].product_id`, option.value);

          //fill recognition from product if no recognition
          if (option.recognition && !row.values.recognition) {
            formCurrent?.setFieldValue(`[${row.original.rowIndex}].recognition`, option.recognition);
          }
        } else {
          formCurrent?.setFieldValue(`[${row.original.rowIndex}].product_id`, null);
        }
      }}
      value={displayedValue}
      options={options}
    />
  );
};

export const renderEventNameCell = ({
  row,
  cell: { value },
  formCurrent,
  eventNames,
  fuzzyRowsMapper,
  setFuzzyRowsMapper,
}) => {
  const index = row.original.rowIndex;

  const events = eventNames.map((eventName) => ({
    label: eventName,
    value: eventName,
  }));

  if (value && formCurrent && !fuzzyRowsMapper?.[index]?.name?.fuzzySearchCompleted) {
    const {
      candidates: candidatesForMapper,
      fuzzyTooltip: fuzzyTooltipForMapper,
      confidence: confidenceForMapper,
      value: fuzzyValue,
    } = getFuzzyCellData({
      rowValue: value,
      dataset: events,
      candidateValueKey: 'value',
      candidateTitleKey: 'label',
      columns: ['value'],
    });

    setFuzzyRowsMapper((prevMapper) => ({
      ...prevMapper,
      [index]: {
        ...(prevMapper?.[index] ?? {}),
        name: {
          fuzzySearchCompleted: true,
          fuzzyTooltip: fuzzyTooltipForMapper,
          candidates: candidatesForMapper,
          confidence: confidenceForMapper,
        },
      },
    }));

    formCurrent?.setFieldValue(`[${index}].name`, fuzzyValue);
  }

  const { fuzzyTooltip, confidence } = fuzzyRowsMapper?.[index]?.name ?? {};

  return (
    <FormikCustomSelector
      height={32}
      components={{ Option: CustomSingleOption }}
      placeholder="Select event..."
      minWidth={160}
      menuWidth={200}
      //optimization thing
      filterOption={createFilter({ ignoreAccents: false })}
      errorWithoutTooltip
      value={value ? { value: value, label: value } : null}
      options={events}
      isClearable
      creatable
      tooltipInputDisplay={fuzzyTooltip}
      hasWarning={confidence === SEARCH_CONFIDENCE.FUZZY}
      handleChange={(option) => {
        if (option) {
          formCurrent?.setFieldValue(`[${row.original.rowIndex}].name`, option.value);
        } else {
          formCurrent?.setFieldValue(`[${row.original.rowIndex}].name`, null);
        }
      }}
      name={`[${row.original.rowIndex}].name`}
    />
  );
};

export const renderEventSourceCell = ({
  row,
  cell: { value },
  formCurrent,
  eventSources,
  fuzzyRowsMapper,
  setFuzzyRowsMapper,
}) => {
  const index = row.original.rowIndex;

  const defaultEventSource = { label: row.original.source, value: row.original.source };
  const previousEventSources = eventSources.map((eventName) => ({
    label: eventName,
    value: eventName,
  }));
  const sources = [defaultEventSource, ...previousEventSources];

  if (value && formCurrent && !fuzzyRowsMapper?.[index]?.source?.fuzzySearchCompleted) {
    const {
      candidates: candidatesForMapper,
      fuzzyTooltip: fuzzyTooltipForMapper,
      confidence: confidenceForMapper,
      value: fuzzyValue,
    } = getFuzzyCellData({
      rowValue: value,
      dataset: sources,
      candidateValueKey: 'value',
      candidateTitleKey: 'label',
      columns: ['value'],
    });

    setFuzzyRowsMapper((prevMapper) => ({
      ...prevMapper,
      [index]: {
        ...(prevMapper?.[index] ?? {}),
        source: {
          fuzzySearchCompleted: true,
          fuzzyTooltip: fuzzyTooltipForMapper,
          candidates: candidatesForMapper,
          confidence: confidenceForMapper,
        },
      },
    }));

    formCurrent?.setFieldValue(`[${index}].source`, fuzzyValue);
  }

  const { fuzzyTooltip, confidence } = fuzzyRowsMapper?.[index]?.source ?? {};

  return (
    <FormikCustomSelector
      height={32}
      components={{ Option: CustomSingleOption }}
      placeholder="Select event..."
      minWidth={160}
      menuWidth={200}
      //optimization thing
      filterOption={createFilter({ ignoreAccents: false })}
      errorWithoutTooltip
      value={value ? { value: value, label: value } : null}
      options={sources}
      isClearable
      creatable
      tooltipInputDisplay={fuzzyTooltip}
      hasWarning={confidence === SEARCH_CONFIDENCE.FUZZY}
      handleChange={(option) => {
        if (option) {
          formCurrent?.setFieldValue(`[${row.original.rowIndex}].source`, option.value);
        } else {
          formCurrent?.setFieldValue(`[${row.original.rowIndex}].source`, null);
        }
      }}
      name={`[${row.original.rowIndex}].source`}
    />
  );
};
