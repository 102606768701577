import React, { useContext } from 'react';
import { FlexerColumn, FlexerRow } from 'components/Core';
import { Loader } from 'components/Loaders';

import { InvoicingScheduleContext } from '../../InvoicingScheduleModal/InvoicingScheduleContext';
import { RemindForm } from './RemindForm';
import { AIReminderRelatedInvoicesWarning } from './AIReminderRelatedInvoicesWarning';
import { InvoiceReminderFailedToSendWarning } from './InvoiceReminderFailedToSendWarning';

export const InvoiceRemindForm = ({ values }) => {
  const { reminderLoading, selectedReminder, setSelectedReminder, setReminderFormValues, reminderFormRef } = useContext(
    InvoicingScheduleContext,
  );

  return reminderLoading ? (
    <div className="w-100 flexer">
      <Loader containerStyles={{ width: 40, marginTop: 80 }} />
    </div>
  ) : (
    <FlexerColumn width="100%" height="100%" gap="16px" alignItems="flex-start">
      <InvoiceReminderFailedToSendWarning reminder={selectedReminder} />

      <AIReminderRelatedInvoicesWarning />

      <FlexerRow width="100%" gap="16px">
        <RemindForm
          invoice={values}
          selectedReminder={selectedReminder}
          setSelectedReminder={setSelectedReminder}
          setReminderFormValues={setReminderFormValues}
          reminderFormRef={reminderFormRef}
        />
      </FlexerRow>
    </FlexerColumn>
  );
};
