import { useContext, useEffect } from 'react';

import { Popover, usePortal } from 'components/Portal';
import { ExternalLinkIcon } from 'components/Icons';

import { InvoiceLink } from './styles';
import { InvoicingScheduleContext } from '../InvoicingScheduleModal/InvoicingScheduleContext';
import { InvoiceActionItem } from '../InvoicingTables/InvoiceTableActions';
import { BillingContext } from '../BillingContext';

export const ExternalServiceLink = ({ onShowDropdownChange } = {}) => {
  const {
    invoicingServiceDisplayName,
    invoiceFormValues,
    isSelectedInvoiceImported,
    validateInvoiceFormAndExecuteAction,
    currentInvoicingSchedule,
  } = useContext(InvoicingScheduleContext);

  const { updateInvoice } = useContext(BillingContext);

  const { triggerRef, togglePortal, isPortalVisible, Portal } = usePortal({ XOffset: 0, YOffset: 45 });

  const invoice = invoiceFormValues;

  useEffect(() => {
    onShowDropdownChange && onShowDropdownChange(isPortalVisible);
    // excluding 'onShowDropdownChange' as dependency prevents infnite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPortalVisible]);

  return invoicingServiceDisplayName ? (
    <InvoiceLink ref={triggerRef} onClick={togglePortal}>
      <ExternalLinkIcon />
      {invoicingServiceDisplayName} Links
      {isPortalVisible && (
        <Portal>
          <Popover XOffset={100} zIndex={51} width="190px">
            <InvoiceActionItem
              onClick={() => {
                validateInvoiceFormAndExecuteAction({
                  action: async () => {
                    await updateInvoice({
                      invoice: invoiceFormValues,
                      createExternalInvoice: true,
                      integrationId: currentInvoicingSchedule?.integration_id,
                    });
                  },
                });
              }}
              disabled={isSelectedInvoiceImported}
            >
              Create Invoice in {invoicingServiceDisplayName}
            </InvoiceActionItem>

            <InvoiceActionItem
              onClick={() => {
                window.open(invoice.invoice_payment_link, '_blank').focus();
              }}
              disabled={!invoice.invoice_payment_link || !isSelectedInvoiceImported}
            >
              Payment Link
            </InvoiceActionItem>

            <InvoiceActionItem
              onClick={() => {
                window.open(invoice.invoice_external_url, '_blank').focus();
              }}
              disabled={!invoice.invoice_external_url || !isSelectedInvoiceImported}
            >
              Invoice in {invoicingServiceDisplayName}
            </InvoiceActionItem>
          </Popover>
        </Portal>
      )}
    </InvoiceLink>
  ) : null;
};
