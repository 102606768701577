export class Subject {
  constructor() {
    this.observers = [];
  }

  subscribe(callback) {
    const observerAt = this.observers.push(callback) - 1;
    return () => this.unsubscribe(observerAt);
  }

  unsubscribe(observerAt) {
    this.observers.splice(observerAt, 1);
  }

  fire(args) {
    this.observers.forEach((callback) => callback.call(this, args));
  }
}
