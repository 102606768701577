import { useContext } from 'react';
import { TransactionAudits } from './TransactionAudits';
import { TransactionContext } from '../TransactionContext';

export const TransactionAuditsContainer = () => {
  const { currentTransaction, organization, selectedAudit, setSelectedAudit } = useContext(TransactionContext);

  return (
    <TransactionAudits
      transaction={currentTransaction}
      organizationId={organization?.id}
      selectedAudit={selectedAudit}
      setSelectedAudit={setSelectedAudit}
    />
  );
};
