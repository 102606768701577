import React, { useState, useContext, useMemo, useCallback } from 'react';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { useTable, usePagination, useGlobalFilter } from 'react-table';
import { omitBy, isUndefined } from 'lodash';

import { usePagination as usePaginationButtons } from 'utils/hooks';
import { AppContext } from 'AppContext';
import { useCSVImportsAPI } from 'api/csvImports/hooks';
import { TruncateStringWithTooltip } from 'components/Tooltip';
import { RowActionPopover, StyledTableName, TableActionCell } from 'components/Table';
import { TableColumnSettings } from 'shared/Common';
import { TableContainerLayout } from 'views/Billing/Common/TableContainerLayout';

import { CSVImportsTable } from './CSVImportsTable';
import { getCSVImportById } from 'api/csvImports/requests';
import { downloadBlobFile } from 'utils/export';

const CSVTabContent = styled.div`
  margin-top: 28px;
  margin-bottom: 22px;
`;

const CSVTitle = styled.div`
  font-weight: 700;
  color: var(--primaryBlue);
  cursor: pointer;
`;

const HeaderCell = styled.div`
  font-weight: 900;
  font-size: 10px;
  line-height: 14px;
  text-transform: uppercase;

  &:last-child {
    width: 80px;
  }
`;

const CSV_IMPORT_STATUS = {
  PROCESSING: 'processing',
  FAILED: 'failed',
  DONE: 'done',
};

const STATUS_TAG_TO_FIELDS = {
  [CSV_IMPORT_STATUS.DONE]: {
    displayedText: 'Done',
    backgroundColor: 'var(--primaryGreen10)',
    borderColor: 'var(--primaryGreen10)',
    tagColor: 'var(--secondaryGreen80)',
  },
  [CSV_IMPORT_STATUS.FAILED]: {
    displayedText: 'Failed',
    backgroundColor: 'var(--primaryRed10)',
    borderColor: 'var(--accentGraySecond)',
    tagColor: 'var(--secondaryRed)',
  },
  [CSV_IMPORT_STATUS.PROCESSING]: {
    displayedText: 'In processing',
    backgroundColor: 'white',
    borderColor: 'white',
    tagColor: 'var(--primaryBlack50)',
  },
};

export const StatusTag = styled.div`
  font-weight: 900;
  font-size: 9px;
  text-transform: uppercase;
  line-height: 12px;
  padding: 4px 8px;
  position: relative;
  left: -5px;
  border-radius: 100px;
  background-color: ${({ status }) => STATUS_TAG_TO_FIELDS[status].backgroundColor};
  color: ${({ status }) => STATUS_TAG_TO_FIELDS[status].tagColor};
  border: ${({ status }) => `1px solid ${STATUS_TAG_TO_FIELDS[status].borderColor}`};
`;

const CSVImportsTab = () => {
  const { orgId } = useContext(AppContext);

  const [searchQuery, setSearchQuery] = useState('');

  const { currentPageIndex, setCurrentPageIndex, pageSize, setPageSize } = usePaginationButtons({
    cacheSuffix: 'csv-imports-page',
  });

  const {
    data,
    metadata,
    isFetching,
    operations: { deleteCSVImport },
  } = useCSVImportsAPI({
    orgId,
    params: omitBy(
      {
        'filters[searchQuery]': searchQuery || undefined,
        'pagination[page]': currentPageIndex,
        'pagination[limit]': pageSize,
        scopes: ['pagination_data'],
      },
      isUndefined,
    ),
  });
  const dataForTable = useMemo(() => data ?? [], [data]);

  const downloadCSVFile = useCallback(
    async ({ id }) => {
      const csvFile = await getCSVImportById({ orgId, csvImportId: id });
      downloadBlobFile({ file: csvFile.base64_file, format: 'csv', filename: csvFile.name, rawName: true });
    },
    [orgId],
  );

  const columns = useMemo(
    () => [
      {
        Header: () => <HeaderCell>Name</HeaderCell>,
        accessor: 'name',
        width: 300,
        Cell: ({ row, cell: { value } }) => (
          <StyledTableName>
            <CSVTitle onClick={() => downloadCSVFile({ id: row.original.id })}>
              <TruncateStringWithTooltip length={35} tooltipWidth={200}>
                {value}
              </TruncateStringWithTooltip>
            </CSVTitle>
          </StyledTableName>
        ),
      },
      {
        Header: 'Status',
        accessor: 'status',
        width: 120,
        Cell: ({ row }) => {
          return (
            <StatusTag status={row.original.status ?? CSV_IMPORT_STATUS.DONE}>
              {STATUS_TAG_TO_FIELDS[row.original.status ?? CSV_IMPORT_STATUS.DONE]?.displayedText}
            </StatusTag>
          );
        },
      },
      {
        Header: () => <HeaderCell>Import Entity</HeaderCell>,
        accessor: 'entity',
        width: 100,
      },
      {
        Header: () => <HeaderCell>Imported By</HeaderCell>,
        accessor: 'email',
        width: 200,
      },
      {
        Header: () => <HeaderCell>Imported At</HeaderCell>,
        accessor: 'created_at',
        width: 200,
        Cell: ({ cell: { value } }) => <> {dayjs(value).format('MMM DD, YYYY [at] HH:mm:ss')} </>,
      },
      {
        accessor: 'actions',
        width: 80,
        Cell: ({ row }) => (
          <TableActionCell>
            <RowActionPopover
              currentSelection={row.original}
              onDeleteClick={() => deleteCSVImport.mutateAsync({ orgId, id: row.original.id })}
              dataCyPrefix="csv-imports"
            />
          </TableActionCell>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [orgId, downloadCSVFile],
  );

  const tableProps = useTable(
    {
      columns,
      data: dataForTable,
      manualGlobalFilter: true,
      manualPagination: true,
    },
    useGlobalFilter,
    usePagination,
  );

  return (
    <CSVTabContent>
      <TableContainerLayout
        columnsSettingsComponent={
          <TableColumnSettings
            tableName="csv-imports"
            numberValue={pageSize}
            handleShowResultsChange={(option) => setPageSize(option.value)}
          />
        }
        isFetching={isFetching}
        rowsCount={metadata?.totalCount}
        currentPageIndex={currentPageIndex ?? 1}
        setCurrentPageIndex={setCurrentPageIndex}
        pageCount={metadata?.maxPage ?? 0}
        setSearchQuery={setSearchQuery}
        placeholder="Search by CSV name..."
      >
        <CSVImportsTable tableProps={tableProps} />
      </TableContainerLayout>
    </CSVTabContent>
  );
};

export { CSVImportsTab };
