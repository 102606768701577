import React, { useContext, useMemo } from 'react';

import noCustomerImage from 'images/customer-chart-empty-state.png';
import { CircleLoader } from 'components/Loaders';
import { TransactionContext } from 'shared/TransactionContent/TransactionContext';
import { EmptyState, EmptyStateDescription, EmptyStateTitle } from './styles';
import { CustomerChart } from './CustomerChart';
import { CardWrapper } from '../styles';

export const CustomerChartTab = () => {
  const { customer, customerLoading, transactionFormValues } = useContext(TransactionContext);

  const transactionsMap = useMemo(
    () =>
      [...(customer?.transactions?.nonRecurring ?? []), ...(customer?.transactions?.recurring ?? [])].reduce(
        (acc, transaction) => {
          acc[transaction.id] = {
            name: transaction.name,
            recognition: transaction.recognition,
          };
          return acc;
        },
        {},
      ),
    [customer],
  );

  if (!transactionFormValues?.customer_id) {
    return (
      <CardWrapper>
        <EmptyState>
          <img src={noCustomerImage} alt="No customer selected" />
          <EmptyStateTitle>Please fill in the required fields</EmptyStateTitle>
          <EmptyStateDescription>
            We can't build a graph until you select a customer and fill in all the fields of the recognition schedule.
          </EmptyStateDescription>
        </EmptyState>
      </CardWrapper>
    );
  }

  return customerLoading || !customer ? (
    <CardWrapper>
      <EmptyState>
        <CircleLoader />
      </EmptyState>
    </CardWrapper>
  ) : (
    <CustomerChart
      transactionCurrency={transactionFormValues?.currency}
      currentTransaction={transactionFormValues?.id}
      data={customer?.transactions_spreads}
      transactionsMap={transactionsMap}
    />
  );
};
