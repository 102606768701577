import { useQuery } from 'react-query';
import { getInsights } from './requests';

const CACHE_KEY = 'insights';

export const useInsightsAPI = ({ orgId, autoFetch = true, filters }) => {
  const { data, error, isLoading, refetch, isFetching } = useQuery(
    [CACHE_KEY, orgId, filters],
    () => getInsights({ orgId, params: filters }),
    {
      enabled: autoFetch && !!orgId,
    },
  );

  return {
    data,
    error,
    isLoading,
    isFetching,
    operations: {
      refetch,
    },
  };
};
