import styled from 'styled-components';

export const WizardIntegrationButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
`;

export const UnknownTag = styled.div`
  color: var(--secondaryYellow);
  font-weight: bold;
`;

export const StepBody = styled.div`
  font-size: 30px;
  line-height: 44px;
  text-align: center;

  span {
    color: var(--primaryGreen);
    font-weight: 900;
  }
`;

export const ConfigPageDice = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-weight: 900;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  margin-top: 40px;
  margin-bottom: 28px;

  span {
    opacity: 0.3;
  }
`;

export const SelectedItems = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

export const SelectedItem = styled.div`
  background: rgba(0, 117, 255, 0.1);
  font-size: 14px;
  line-height: 20px;
  color: var(--primaryBlue);
  border-radius: 8px;
  margin-right: 12px;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  margin-top: 12px;

  svg {
    margin-left: 8px;
    cursor: pointer;

    &:hover {
      g {
        opacity: 1;
      }
    }
  }
`;

export const FieldBody = styled.div`
  box-shadow: 8px 8px 60px var(--primaryBlack5);
  border-radius: 20px;
  padding: 28px;
  width: 100%;
  position: relative;
  margin-bottom: 40px;

  .ant-checkbox-inner {
    width: 20px;
    height: 20px;
  }
`;

export const FieldTitle = styled.div`
  font-size: 16px;
  line-height: 22px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ noMarginBottom }) => !noMarginBottom && '24px'};
  font-weight: 700;
`;

export const CircleCheckbox = styled.div`
  background: #ffffff;
  border: ${({ checked, isDisabled, borderWidth }) =>
    checked && !isDisabled ? `${borderWidth ?? 6}px solid var(--primaryBlue)` : '1px solid #d0d0d0'};
  border-radius: 100px;
  width: ${({ width }) => width ?? '20px'};
  height: ${({ height }) => height ?? '20px'};
  cursor: pointer;
  margin-right: 10px;
  pointer-events: ${({ isDisabled }) => isDisabled && 'none'};
  opacity: ${({ isDisabled }) => isDisabled && '0.3'};
`;

export const CircleCheckboxContent = styled.div`
  pointer-events: ${({ isDisabled }) => isDisabled && 'none'};
  opacity: ${({ isDisabled }) => isDisabled && '0.3'};
`;

export const FieldTag = styled.div`
  font-size: 14px;
  line-height: 20px;
  padding: 8px 12px;
  margin-top: 12px;
  background: ${(props) => (props.selected ? 'rgba(0, 117, 255, 0.1)' : 'var(--primaryBlack3)')};
  border: ${(props) => (props.selected ? '1px solid var(--primaryBlue)' : '1px solid transparent')};
  border-radius: 8px;
  margin-right: 8px;
  cursor: pointer;
  white-space: nowrap;

  &:hover {
    color: var(--primaryBlue);
    border: 1px solid rgba(0, 117, 255, 0.2);
  }
`;

export const CheckboxLabel = styled.div`
  margin-left: 10px;
  font-size: 14px;
  cursor: pointer;
  line-height: 20px;
`;

export const WizardWrapper = styled.div`
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const WizardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--accentGrayFourth);
  border-bottom: 1px solid rgba(0, 21, 46, 0.02);
  box-shadow: 0px 20px 40px rgba(0, 21, 46, 0.05);
  padding: 16px 135px;
`;

export const StartIconWrapper = styled.div`
  box-shadow: 4px 4px 28px var(--primaryBlack5);
  border-radius: 12px;
  padding: 6px;
  border: ${(props) => (props.active ? 'none' : '1px solid var(--primaryBlack10)')};
  background-color: ${(props) => props.active && 'var(--primaryGreen10)'};

  svg path {
    fill: ${(props) => props.active && 'var(--primaryGreen)'};
  }
`;

export const WizardMainLogo = styled.div`
  width: auto;
  color: var(--primaryBlack);
  align-items: center;
  display: flex;

  img {
    margin-right: 16px;
  }

  div {
    font-weight: 900;
    background-color: white;
    font-size: 14px;
    line-height: 20px;
    padding: 8px 12px;
    border: 1px solid rgba(0, 21, 46, 0.03);
    border-radius: 12px;
  }
`;

export const HeaderTitle = styled.div`
  font-size: 14px;
  line-height: 20px;
  opacity: 0.5;
  white-space: nowrap;
`;

export const WizardBody = styled.div`
  padding-top: 60px;
  width: 970px;
  margin: 0 auto;
`;

export const WizardSteps = styled.div`
  display: flex;
  margin-bottom: 56px;
  padding: 0 200px;
  align-items: center;

  svg {
    display: flex;
  }
`;

export const StepNumber = styled.div`
  font-weight: 900;
  font-size: 12px;
  line-height: 16px;
  color: ${(props) => (props.active ? 'var(--primaryBlack)' : 'var(--primaryBlack75)')};
  box-shadow: ${(props) => props.active && '4px 4px 28px var(--primaryBlack5)'};
  border: ${(props) => props.active && ' 1px solid var(--primaryBlack10)'};
  padding: 8px 12px;
  background: ${(props) => (props.active ? 'white' : 'var(--primaryBlack2)')};
  border-radius: 12px;
`;

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: var(--primaryBlack3);
  margin: 0 10px;
`;

export const ActionButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const ActionButton = styled.div`
  display: flex;
  align-items: center;
  background: var(--primaryBlue);
  box-shadow: 0px 4px 10px rgba(57, 161, 220, 0.2);
  border-radius: 100px;
  padding: 12px;
  padding-left: 20px;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  cursor: pointer;
  margin-top: 40px;
  pointer-events: ${(props) => props.unactive && 'none'};
  opacity: ${(props) => props.unactive && '0.3'};
  transition: background-color ease 0.3s;

  svg rect {
    transition: fill ease 0.4s;
  }

  &:hover {
    background-color: var(--secondaryBlue);

    svg rect {
      fill: rgba(0, 21, 46, 0.1);
    }
  }

  span {
    margin-right: 14px;
  }
`;

export const ReturnBackButton = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid var(--primaryBlack5);
  border-radius: 100px;
  padding: 12px;
  margin-top: 40px;
  padding-right: 20px;
  font-weight: bold;
  font-size: 14px;
  color: var(--primaryBlack75);
  line-height: 20px;
  cursor: pointer;
  pointer-events: ${(props) => props.unactive && 'none'};
  opacity: ${(props) => props.unactive && '0.3'};
  margin-right: 12px;
  transition: all ease 0.3s;

  span {
    margin-left: 14px;
  }

  &:hover {
    color: var(--primaryBlack);
    box-shadow: 4px 4px 28px var(--primaryBlack5);
  }
`;

export const Copyright = styled.div`
  font-weight: normal;
  font-size: 11px;
  line-height: 14px;
  opacity: 0.5;
  margin: 0 auto;
  padding-top: 24px;
  padding-bottom: 24px;
  margin-top: auto;
`;

export const StyledFinishIcon = styled.div`
  padding: 6px;
  background: ${(props) => (props.active ? 'var(--primaryBlue)' : 'rgba(0, 117, 255, 0.1)')};
  border-radius: 12px;

  svg path {
    fill: ${(props) => props.active && 'white'};
  }
`;

export const HeaderUserInfo = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 20px;

  div {
    align-items: center;
    margin-right: 16px;
    display: flex;
  }

  h5 {
    font-weight: 900;
    margin-bottom: 0;
    margin-right: 4px;
  }

  span {
    margin-left: 4px;
  }
`;

export const FieldCount = styled.div`
  font-weight: 900;
  font-size: 10px;
  position: absolute;
  display: ${({ configPageView }) => configPageView && 'none'};
  top: -12px;
  left: -12px;
  border-radius: 12px;
  line-height: 14px;
  color: var(--primaryBlue);
  border: 1px solid rgba(0, 117, 255, 0.05);
  background-color: var(--neutralBlue);
  padding: 5px 9px;
`;

export const fieldSelectorStyles = {
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  control: (styles, { isFocused }) => ({
    ...styles,
    backgroundColor: 'white',
    cursor: 'pointer',
    borderRadius: 8,
    border: isFocused ? '1px solid var(--primaryBlack)' : '1px solid var(--primaryBlack10)',
    padding: '8px 12px',
    borderColor: 'initial',
    boxShadow: isFocused ? ' 3px 3px 10px rgba(0, 0, 0, 0.08)' : 'none',
    color: 'var(--primaryBlack)',
    width: '100%',
    height: 36,
    minHeight: 0,
    flexWrap: 'no-wrap',
    '&:hover': {
      borderColor: 'initial',
    },
  }),
  input: (styles) => ({
    ...styles,
    color: 'var(--primaryBlack)',
    fontSize: 14,
  }),
  placeholder: (styles) => ({
    ...styles,
    color: 'var(--primaryBlack50)',
    fontSize: 14,
  }),
  valueContainer: (styles) => ({
    ...styles,
    padding: 0,
  }),
  MenuList: (styles) => ({
    ...styles,
    padding: 0,
  }),
  indicatorSeparator: (styles) => ({ ...styles, display: 'none' }),
  menu: (styles) => ({ ...styles }),
  dropdownIndicator: (styles) => ({ ...styles, color: 'var(--primaryBlack)', width: 13, padding: 0 }),
  option: (styles, { isSelected }) => ({
    ...styles,
    fontSize: 14,
    pointerEvents: isSelected && 'none',
    padding: '4px 0',
    borderBottom: '1px solid var(--accentGraySecond)',
    backgroundColor: isSelected ? 'rgba(0, 117, 255, 0.1)' : 'white !important',
    color: isSelected && 'var(--primaryBlack)',

    '&:hover': {
      cursor: 'poonter',
    },

    '&:last-child': {
      borderBottom: 'none',
    },
  }),
  singleValue: (styles) => ({
    ...styles,
  }),
};
