import React, { useCallback, useContext, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import { AppContext } from 'AppContext';
import { useProductsAPI } from 'api/products';
import { INTEGRATION_TYPES } from 'consts/integrations';
import { SERVICE_WITH_INCOME_ACCOUNT_REF } from 'views/Billing/consts';
import { getIncomeAccountOptions } from 'api/integrations/requests';
import { getServiceCategory } from 'models/integration';
import { Loader } from 'components/Loaders';
import { useProductActionsModal } from 'shared/ProductActionsModal';
import { ProductsTable } from './ProductsTable';
import { useProductsBulkDeleteModal } from './ProductsBulkDeleteModal';
import { useProductsBulkUpdateModal } from './ProductsBulkUpdateModal';
import { useProductsBulkUpdateIncomeAccountModal } from './ProductsBulkUpdateIncomeAccountModal';
import { useCategoryActionsModal } from './CategoryActionsModal';
import { ProductsTabContent } from './styles';
import { groupProductsByCategory } from './utils';

const ProductsTab = () => {
  const { orgId, integrations } = useContext(AppContext);

  // Check if we are on the Archived Products Page
  const isArchivedView = useLocation()?.search?.includes?.('archived');

  const glIntegrations = integrations?.filter((integration) => integration.type === INTEGRATION_TYPES.GL);

  const {
    data,
    isLoading,
    isFetching,
    refetch,
    operations: { deleteProduct },
  } = useProductsAPI({ orgId, params: { scopes: ['imports'], archived: isArchivedView } });

  const { products, productCategories } = data ?? {};

  const groupedProducts = useMemo(() => groupProductsByCategory(products), [products]);

  const getIncomeAccountOptionsByIntegrationId = useCallback(async () => {
    const result = {};

    for (const integration of glIntegrations) {
      const incomeAccountOptions = await getIncomeAccountOptions({ orgId, integrationId: integration.id });
      if (incomeAccountOptions) result[integration.id] = incomeAccountOptions;
    }

    return result;
  }, [glIntegrations, orgId]);

  const { data: incomeAccountOptionsByIntegrationId = {} } = useQuery(
    'income-account-options',
    () => getIncomeAccountOptionsByIntegrationId(),
    {
      enabled: glIntegrations.some((integration) =>
        SERVICE_WITH_INCOME_ACCOUNT_REF.includes(getServiceCategory(integration.service)),
      ),
    },
  );

  const { openModal: handleOpenProductActionsModal, ProductActionsModal } = useProductActionsModal({ refetch });

  const { openModal: handleUpdateBulk, ProductsBulkUpdateModal } = useProductsBulkUpdateModal();

  const {
    openModal: handleUpdateBulkIncomeAccount,
    ProductsBulkUpdateIncomeAccountModal,
  } = useProductsBulkUpdateIncomeAccountModal({ onSuccess: refetch });

  const { openModal: handleDeleteBulk, ProductsBulkDeleteModal } = useProductsBulkDeleteModal({
    onSuccess: refetch,
  });

  const { openModal: handleOpenCategoryModal, CategoryActionsModal } = useCategoryActionsModal();

  return (
    <ProductsTabContent>
      {isLoading ? (
        <div className="w-100 flexer">
          <Loader containerStyles={{ width: 40 }} />
        </div>
      ) : (
        <ProductsTable
          productCategories={productCategories}
          data={groupedProducts}
          onCreateProduct={() => handleOpenProductActionsModal({ action: 'create' })}
          onEditProduct={(product) => handleOpenProductActionsModal({ action: 'edit', product })}
          onDeleteProduct={(product) => deleteProduct.mutateAsync({ productId: product.id })}
          onEditBulk={handleUpdateBulk}
          onEditIncomeAccountBulk={handleUpdateBulkIncomeAccount}
          onDeleteBulk={handleDeleteBulk}
          onOpenCategoryModal={handleOpenCategoryModal}
          isLoading={isLoading}
          isFetching={isFetching}
          incomeAccountOptionsByIntegrationId={incomeAccountOptionsByIntegrationId}
        />
      )}

      <ProductActionsModal />
      <CategoryActionsModal />
      <ProductsBulkUpdateModal />
      <ProductsBulkUpdateIncomeAccountModal />
      <ProductsBulkDeleteModal />
    </ProductsTabContent>
  );
};

export { ProductsTab };
