import { isValidDate } from 'utils/dateUtils';
import { MAGIC_METADATA } from 'consts/global';
import { METADATA_DATA_TYPES } from './consts';

export const getMetadataDataType = ({ key, value, options = [] }) => {
  if ([MAGIC_METADATA.CANCEL_DATE, MAGIC_METADATA.UP_FOR_RENEWAL_DATE].includes(key)) {
    return METADATA_DATA_TYPES.DATETIME;
  } else if ([MAGIC_METADATA.REV_REC_FX_RATE, MAGIC_METADATA.AMOUNT_IN_ORIGINAL_CURRENCY].includes(key)) {
    return MAGIC_METADATA.NUMBER;
  } else if (key === MAGIC_METADATA.IS_WON) {
    return MAGIC_METADATA.BOOLEAN;
  } else if (
    typeof value === 'boolean' ||
    (options.length === 2 &&
      options.some((option) => typeof option === 'string' && option.toLowerCase() === 'yes') &&
      options.some((option) => typeof option === 'string' && option.toLowerCase() === 'no'))
  ) {
    return METADATA_DATA_TYPES.BOOLEAN;
  } else if (
    typeof value === 'number' ||
    (options.length > 0 && options.every((option) => typeof option === 'number'))
  ) {
    return METADATA_DATA_TYPES.NUMBER;
  } else if (isValidDate(value) || (options.length > 0 && options.every((option) => isValidDate(option)))) {
    return METADATA_DATA_TYPES.DATETIME;
  }

  return METADATA_DATA_TYPES.STRING;
};
