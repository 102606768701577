import dayjs from 'dayjs';

import { HISTORY_TYPE } from './const';
import { isInvoiceOverdue } from '../utils';

const getBaseObject = (invoice) => ({
  invoiceNumber: invoice.invoice_number,
  id: invoice.id,
});

//can pass in a single invoice or an array of invoices into the param
export const convertInvoiceToTimelineData = (invoice) => {
  if (invoice) {
    const draft = !Array.isArray(invoice)
      ? [
          {
            type: HISTORY_TYPE.PAID,
            date: invoice.paid_at,
            amount: invoice.amount,
            currency: invoice.currency,
            ...getBaseObject(invoice),
          },
          {
            type: HISTORY_TYPE.VOIDED,
            date: invoice.voided_at,
            ...getBaseObject(invoice),
          },
          {
            type: HISTORY_TYPE.OVERDUE,
            date: isInvoiceOverdue({ invoice }) ? dayjs.utc(invoice?.date).add(invoice?.days_to_pay, 'day') : null,
            ...getBaseObject(invoice),
          },
          ...(invoice?.reminders ?? [])
            .filter((reminder) => reminder.sent_at)
            .sort((a, b) => dayjs(a.sent_at).utc(true).valueOf() - dayjs(b.sent_at).utc(true).valueOf())
            .map((reminder, index) => ({
              type: HISTORY_TYPE.REMINDER,
              count: index + 1,
              date: reminder.sent_at,
              data: reminder,
              ...getBaseObject(invoice),
            })),
          {
            type: HISTORY_TYPE.INVOICE_SENT,
            date: invoice.sent_at && (invoice.send_date ?? invoice.sent_at),
            data: invoice,
            ...getBaseObject(invoice),
          },
          {
            type: HISTORY_TYPE.CREATED,
            date: invoice.created_at,
            ...getBaseObject(invoice),
          },
          {
            type: HISTORY_TYPE.RECEIPT_SENT,
            date: invoice.receipt?.sent_at,
            data: invoice.receipt,
            amount: invoice.amount,
            currency: invoice.currency,
            ...getBaseObject(invoice),
          },
        ].filter((item) => item.date)
      : invoice.flatMap((invoice) => convertInvoiceToTimelineData(invoice));

    return draft
      .sort((a, b) => dayjs(b.date).utc(true).valueOf() - dayjs(a.date).utc(true).valueOf())
      .map((item) => ({
        ...item,
        formattedDate: dayjs(item.date).utc(true).format('MMM DD, YYYY [at] hh:mm a'),
      }));
  }

  return [];
};
