import React from 'react';
import styled from 'styled-components';

const DividerLine = styled.div`
  width: 100%;
  height: 1px;
  background: var(--accentGraySecond);
`;

const Divider = styled.div`
  height: 16px;
  position: relative;
  margin-bottom: 20px;
`;
const DividerText = styled.div`
  font-weight: 900;
  font-size: 10px;
  line-height: 14px;
  color: rgba(0, 21, 46, 0.3);
  text-transform: uppercase;
  position: absolute;
  left: 50%;
  top: 0;
  background: white;
  padding: 0 6px;
  transform: translate(-50%, -50%);
`;

export const IntegrationDivider = ({ integration }) => (
  <Divider>
    <DividerLine />
    <DividerText>{integration} Integration</DividerText>
  </Divider>
);
