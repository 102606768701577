import styled from 'styled-components';
import { ReactComponent as Icon } from 'images/trash.svg';
import { Centerer } from 'components/Core';

export const TrashIcon = styled(Icon)`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  opacity: ${(props) => props.opacity};
  cursor: ${(props) => props.cursor};
  stroke: ${({ stroke }) => stroke};
  min-width: ${(props) => props.size};
  min-height: ${(props) => props.size};
  path {
    fill: ${({ fill }) => fill};
  }
`;

const TrashIconWrapper = styled(Centerer)`
  padding: 4px;
  gap: 8px;
  width: 36px;
  height: 36px;
  border: 1px solid var(--primaryBlack2);
  border-radius: 8px;
`;

export const BorderedTrashIcon = (props) => (
  <TrashIconWrapper>
    <TrashIcon {...props} />
  </TrashIconWrapper>
);
