import React, { useContext, useState } from 'react';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { cssVar } from 'polished';
import { AppContext } from 'AppContext';
import { MODALS_FOR_OPEN, useToasts } from 'utils/hooks';
import { downloadExportByType } from 'api/exports';
import { downloadBlobFile } from 'utils/export';
import { humanize, splitSegmentString } from 'utils/stringUtils';
import { formatYearMonth } from 'utils/dateUtils';
import { ReactComponent as GreyDot } from 'images/medium-grey-dot.svg';
import { ReactComponent as TableExportIcon } from 'images/table-export.svg';
import { SwitchWithLabel, CustomDatePicker } from 'components/Controls';
import { IconButton } from 'components/Buttons';
import { Modal, ModalBody, ModalCloseIcon, ModalHeader, ModalTitle, ModalContainer } from 'components/Modal';
import RevenueDetailsContent from 'views/RevenueDetails/RevenueDetailsContent';
import { HEADER_TITLES } from './Layout';
import { useLinkShare } from './LinkShare';
import { SEGMENT_BY_OPTIONS } from 'consts/global';

const SwitchLabel = styled.span`
  font-weight: 900;
  font-size: 10px;
  line-height: 45px;
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
`;

const RevenueModalTitle = styled.div`
  font-weight: 900;
  display: flex;
  align-items: center;

  > span {
    margin-right: 12px;
  }
`;

export const RevenueDetailsModal = ({
  organization,
  closeModal,
  selectedMonth: initiallySelectedMonth,
  selectedMetric,
  selectedSegment,
  segmentBy,
  timeseriesMonth,
  viewAsARR,
  setViewAsARR,
  optimisticAnalytics,
  setOptimisticAnalytics,
  selectedUpsellType,
  summaryStartMonth = null,
  summaryColumnHeader = null,
}) => {
  const {
    organizations,
    appSettings: { isCommitted, rollup },
  } = useContext(AppContext);
  const { pushToast } = useToasts();

  const [exporting, setExporting] = useState(false);
  const [transactionToShow, setTransactionToShow] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(new Date(`${initiallySelectedMonth}-15`));

  const { LinkShareButton, LinkShareModal } = useLinkShare({
    additionalQuery: {
      'open-modal': MODALS_FOR_OPEN.REVENUE_DETAILS,
      metric: selectedMetric,
      month: dayjs(selectedMonth).format('YYYY-MM'),
    },
    headerTitle: HEADER_TITLES.revenueDetails,
  });

  const revenueDetailsMonth = selectedMonth.getMonth() + 1;
  const revenueDetailsYear = selectedMonth.getFullYear();

  const handleExport = async () => {
    if (exporting) {
      return;
    }

    setExporting(true);
    pushToast('Download started...');
    const type = 'revenue_details';
    const file = await downloadExportByType({
      orgId: organizations[0].id,
      type,
      customization: {
        isARR: viewAsARR,
        isCommitted,
        rollup,
        optimisticAnalytics,
        month: formatYearMonth({ year: revenueDetailsYear, month: revenueDetailsMonth }),
        splitUpsell: true,
        summaryStartMonth,
      },
    });
    downloadBlobFile({ file, format: 'csv', filename: `Subscript ${humanize(type)}` });

    setExporting(false);
  };

  let additionalTitleLabel;
  if (selectedSegment && segmentBy) {
    const [segmentByTopLevel] = splitSegmentString({ string: segmentBy });
    if (segmentByTopLevel === SEGMENT_BY_OPTIONS.PRODUCT) {
      additionalTitleLabel = organizations?.[0].products.find((p) => p.id === selectedSegment)?.name || selectedSegment;
    } else if (segmentByTopLevel === SEGMENT_BY_OPTIONS.PRODUCT_CATEGORY) {
      additionalTitleLabel =
        organizations?.[0].productCategories.find((pc) => pc.id === selectedSegment)?.name || selectedSegment;
    } else {
      additionalTitleLabel = selectedSegment;
    }
  }

  return (
    <ModalContainer data-cy="revenue-details-modal">
      <Modal width="90%" height="90vh" overflow="auto">
        <ModalHeader>
          <ModalCloseIcon onClose={closeModal} data-cy="revenue-details-modal__close-button" />
          <ModalTitle padding="0 0 0 60px">
            <HeaderContainer>
              <RevenueModalTitle>
                <span>
                  {isCommitted ? 'Committed Revenue' : 'Recurring Revenue'}
                  {selectedSegment ? ` - ${segmentBy}: ${additionalTitleLabel}` : ''}:
                </span>
                {summaryColumnHeader ? (
                  <span>{summaryColumnHeader}</span>
                ) : (
                  <CustomDatePicker
                    name="revenue-details-modal__month"
                    dropdownView
                    showMonthYearPicker
                    dateFormat="MMMM yyyy"
                    selected={selectedMonth}
                    onChange={setSelectedMonth}
                  />
                )}
              </RevenueModalTitle>
              <GreyDot style={{ marginRight: 20, marginLeft: 20 }} />
              <SwitchWithLabel
                onChange={() => setViewAsARR(!viewAsARR)}
                checked={viewAsARR}
                label={<SwitchLabel>VIEW AS ARR</SwitchLabel>}
              />
              <GreyDot style={{ marginRight: 20, marginLeft: 20 }} />
              <IconButton icon={<TableExportIcon />} iconFillColor={cssVar('--primaryGreen')} onClick={handleExport}>
                Export Transactions
              </IconButton>

              <GreyDot style={{ marginRight: 20, marginLeft: 20 }} />

              <LinkShareButton />
            </HeaderContainer>
          </ModalTitle>
        </ModalHeader>
        <ModalBody paddingLeft={0} paddingRight="20px">
          <RevenueDetailsContent
            organization={organization}
            revenueDetailsMonth={revenueDetailsMonth}
            revenueDetailsYear={revenueDetailsYear}
            selectedMetric={selectedMetric}
            selectedSegment={selectedSegment}
            segmentBy={segmentBy}
            timeseriesMonth={timeseriesMonth}
            transactionToShow={transactionToShow}
            setTransactionToShow={setTransactionToShow}
            viewAsARR={viewAsARR}
            optimisticAnalytics={optimisticAnalytics}
            setOptimisticAnalytics={setOptimisticAnalytics}
            selectedUpsellType={selectedUpsellType}
            summaryStartMonth={summaryStartMonth}
          />
        </ModalBody>
      </Modal>

      <LinkShareModal />
    </ModalContainer>
  );
};
