import React, { useEffect, useState } from 'react';
import { capitalize } from 'lodash';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useCSVReader, formatFileSize } from 'react-papaparse';

import { COLORS } from 'consts/colors';
import { TooltipContainer } from 'components/Tooltip';
import { StyledButton } from 'components/Buttons/Button';
import { GreyDot } from 'components/Icons';
import { FlexerRow } from 'components/Core';
import { ReactComponent as ArrowRight } from 'images/arrow-narrow-right.svg';
import { ReactComponent as FileIcon } from 'images/files.svg';
import { ReactComponent as SyncIcon } from 'images/refresh.svg';
import { ReactComponent as UploadIcon } from 'images/upload-csv-icon.svg';
import { ReactComponent as TableExportIcon } from 'images/table-export.svg';
import { ReactComponent as FileCheckIcon } from 'images/contracts-file-check-icon.svg';
import { ReactComponent as DragIcon } from 'images/drag-drop.svg';
import { ReactComponent as ClickIcon } from 'images/click-icon.svg';

import { CSV_MODES } from './CsvUpload';

export const Uploader = styled.div`
  min-height: ${({ smallView }) => (smallView ? 'none' : 'calc(100vh - 130px)')};
  padding: ${({ smallView }) => smallView && '20px'};
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: ${({ zoneHover, fileLoaded, smallView }) =>
    zoneHover && !smallView ? (fileLoaded ? 'var(--primaryBlue10)' : 'var(--primaryBlue5)') : 'var(--primaryBlue3)'};
  border: ${({ zoneHover, smallView }) =>
    zoneHover && !smallView ? '1px dashed var(--primaryBlue)' : '1px dashed var(--primaryBlue20)'};
  border-radius: 20px;

  &:hover {
    background-color: var(--primaryBlue5);
    border: 1px dashed var(--primaryBlue);
  }
`;

export const Zone = styled.div`
  display: flex;
  flex-direction: ${({ smallView }) => (smallView ? 'row' : 'column')};
  justify-content: space-between;
  align-items: center;
  cursor: ${({ isFileLoaded }) => (isFileLoaded ? 'default' : 'pointer')};
  flex-grow: 1;
`;

export const UploadHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 25px;
  border-bottom: 1px solid var(--primaryBlue5);
`;

export const StyledProgressBar = styled.div`
  span {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: var(--primaryBlue5) !important;
    height: 100% !important;
    border-radius: 20px !important;
    border: none !important;
    box-shadow: none !important;
  }
`;

export const HeaderItem = styled.div`
  display: flex;
  padding: 20px;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  white-space: nowrap;

  a {
    margin-left: 4px;
    color: var(--primaryBlue);

    &:hover {
      color: var(--secondaryBlue);
    }
  }
`;

export const HeaderNumber = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  width: 20px;
  height: 20px;
  background: rgba(64, 80, 98, 0.98);
  border: 1px solid var(--primaryBlack5);
  border-radius: 100px;
  font-weight: 900;
  font-size: 10px;
  text-transform: uppercase;
  color: white;
`;

export const SubInfo = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  opacity: 0.5;
  margin-top: 4px;
`;

export const ZoneInfo = styled.div`
  display: flex;
  flex-direction: ${({ smallView }) => (smallView ? 'row' : 'column')};
  align-items: center;
`;

export const Separator = styled.div`
  width: 120px;
  height: 1px;
  background: var(--primaryBlack5);
  margin: 20px 0;
`;

export const InfoText = styled.div`
  margin-top: ${({ smallView }) => !smallView && '8px'};
  display: flex;

  span {
    margin-left: 4px;
    color: var(--primaryBlue);
  }
`;

export const CancelButton = styled.div`
  color: var(--primaryRed);
  margin-left: 4px;
  position: relative;
  cursor: pointer;
  z-index: 5;

  &:hover {
    color: var(--secondaryRed);
  }
`;

export const ZoneFooter = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ smallView }) => !smallView && '24px'};
  align-items: ${({ smallView }) => (smallView ? 'flex-end' : 'center')};

  span {
    font-size: 12px;
    line-height: 16px;
    opacity: ${({ smallView }) => !smallView && '0.2'};
    display: ${({ smallView }) => smallView && 'flex'};
    color: ${({ smallView }) => smallView && 'var(--primaryBlue)'};
  }
`;

export const TypeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TypeText = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  opacity: 0.5;
  margin-bottom: 12px;
`;

export const TypeButton = styled.div`
  padding: 12px;
  z-index: 5;
  width: 190px;
  height: 44px;
  display: flex;
  white-space: nowrap;
  align-items: center;
  justify-content: center;
  background-color: ${({ selected }) => (selected ? 'var(--primaryBlue10)' : 'white')};
  border-radius: 12px;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  box-shadow: 2px 2px 12px 0px var(--primaryBlack2);
  border: ${({ selected }) => (selected ? '1px solid var(--primaryBlue)' : '1px solid var(--neutralGray)')};
  cursor: pointer;
  color: ${({ selected }) => (selected ? 'var(--primaryBlue)' : 'var(--primaryBlack)')};
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};

  svg {
    margin-right: 8px;
    opacity: ${({ selected }) => (selected ? '1 !important' : 'initial')};

    g {
      opacity: ${({ selected }) => (selected ? '1 !important' : 'initial')};
    }

    path {
      fill: ${({ selected }) => (selected ? 'var(--primaryBlue)' : 'initial')};
    }
  }
`;

export const RelativeStyledIconButton = styled(StyledButton)`
  position: relative;
  z-index: 5;
  padding: 8px;
  margin-top: 22px;
`;

export const FooterIcons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 68px;
  margin-bottom: 8px;
`;

export const StyledTableExportIcon = styled(TableExportIcon)`
  width: 20px;
  height: 20px;
  opacity: 0.3;
  margin-right: 8px;
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${({ smallView }) => (smallView ? 'flex-start' : 'center')};
  background-color: ${({ smallView }) => smallView && 'var(--primaryBlue5)'};
  margin-left: ${({ smallView }) => smallView && '12px'};
  border-radius: ${({ smallView }) => smallView && '8px'};
  padding: ${({ smallView }) => smallView && '4px 8px'};
`;

export const OpacityText = styled.div`
  opacity: 0.5;
  margin-left: 4px;
  color: var(--primaryBlack);
`;

// TODO: Migrate to FileUploader if possible
export const CsvUploader = ({
  additionalInfoText,
  smallView,
  setRawFile,
  setParsedCsv,
  onlyCreateMode,
  entityName,
  createOrUpdateMode,
  setCreateOrUpdateMode,
}) => {
  const { CSVReader } = useCSVReader();
  const [zoneHover, setZoneHover] = useState(false);
  const [draftParsedCsv, setDraftParsedCsv] = useState();

  const [uploadedFile, setUploadedFile] = useState();
  useEffect(() => {
    if (!uploadedFile) {
      setParsedCsv(null);
      setRawFile(null);
    } else {
      setRawFile(uploadedFile);
    }
  }, [uploadedFile, setParsedCsv, setRawFile]);

  return (
    <Uploader smallView={smallView} fileLoaded={draftParsedCsv} zoneHover={zoneHover}>
      {!smallView && (
        <UploadHeader>
          <HeaderItem>
            <HeaderNumber>1</HeaderNumber>
            Choose type of CSV file
          </HeaderItem>
          <ArrowRight />
          <HeaderItem>
            <HeaderNumber>2</HeaderNumber>
            Download a
            <Link
              to={`${process.env.PUBLIC_URL}/templates/${capitalize(entityName)}_Template.csv`}
              target="_blank"
              download
            >
              template
            </Link>
          </HeaderItem>
          <ArrowRight />
          <HeaderItem>
            <HeaderNumber>3</HeaderNumber>
            Paste your data and upload the file
          </HeaderItem>
          <ArrowRight />
          <HeaderItem>
            <HeaderNumber>4</HeaderNumber>
            Check your data and finish uploading!
          </HeaderItem>
        </UploadHeader>
      )}

      <CSVReader
        config={{ header: true }}
        onUploadAccepted={(results) => {
          setZoneHover(true);

          //Small view is setting after the first upload
          //and in small view we dont have confirmation after upload csv
          //so we setParsedCsv instantly
          if (smallView) {
            setDraftParsedCsv(results);
            setParsedCsv(results);
          } else {
            setDraftParsedCsv(results);
          }
        }}
        onDragOver={(event) => {
          event.preventDefault();
          setZoneHover(true);
        }}
        onDragLeave={(event) => {
          event.preventDefault();
          setZoneHover(false);
        }}
      >
        {({ getRootProps, acceptedFile, ProgressBar, getRemoveFileProps }) => {
          setUploadedFile(acceptedFile);

          return (
            <Zone
              isFileLoaded={acceptedFile && !smallView}
              smallView={smallView}
              {...getRootProps()}
              onClick={(event) => {
                if (acceptedFile && !smallView) {
                  event.stopPropagation();
                  event.preventDefault();
                } else getRootProps()?.onClick(event);
              }}
            >
              {!smallView && <div />}

              {acceptedFile ? (
                <div>
                  <ZoneInfo smallView={smallView}>
                    {zoneHover && draftParsedCsv ? (
                      smallView ? (
                        <StyledTableExportIcon />
                      ) : (
                        <FileCheckIcon style={{ width: '24px', height: '24px' }} />
                      )
                    ) : (
                      <UploadIcon />
                    )}

                    {!smallView && (
                      <>
                        <InfoWrapper>
                          <InfoText>
                            {zoneHover && draftParsedCsv ? 'You’ve uploaded a file' : 'You’re uploading a file now'}.
                            <CancelButton {...getRemoveFileProps()}>Cancel</CancelButton>
                          </InfoText>
                          <SubInfo>
                            {acceptedFile.name} ({formatFileSize(acceptedFile.size)})
                          </SubInfo>
                        </InfoWrapper>

                        <Separator />
                        <TypeWrapper>
                          <TypeText>Pick a way to import your data</TypeText>

                          {additionalInfoText && (
                            <TypeText style={{ maxWidth: '500px', textAlign: 'center' }}>
                              ({additionalInfoText})
                            </TypeText>
                          )}
                        </TypeWrapper>

                        <FlexerRow gap="8px">
                          <TypeButton
                            data-cy="upload-csv__create-new-button"
                            selected={createOrUpdateMode === CSV_MODES.CREATE}
                            onClick={(event) => {
                              event.stopPropagation();
                              setCreateOrUpdateMode(CSV_MODES.CREATE);
                            }}
                          >
                            <FileIcon /> Create new records
                          </TypeButton>
                          {onlyCreateMode ? (
                            <TooltipContainer
                              width={300}
                              isVisible={true}
                              toolTipContent={'This CSV upload only supports creation'}
                            >
                              <TypeButton
                                disabled={onlyCreateMode}
                                data-cy="upload-csv__update-existing-button"
                                selected={createOrUpdateMode === CSV_MODES.UPDATE}
                                onClick={(event) => {
                                  event.stopPropagation();
                                  setCreateOrUpdateMode(CSV_MODES.UPDATE);
                                }}
                              >
                                <SyncIcon style={{ width: '20px', height: '20px', opacity: 0.3 }} /> Update existing
                                ones
                              </TypeButton>
                            </TooltipContainer>
                          ) : (
                            <TypeButton
                              disabled={onlyCreateMode}
                              data-cy="upload-csv__update-existing-button"
                              selected={createOrUpdateMode === CSV_MODES.UPDATE}
                              onClick={(event) => {
                                event.stopPropagation();
                                setCreateOrUpdateMode(CSV_MODES.UPDATE);
                              }}
                            >
                              <SyncIcon style={{ width: '20px', height: '20px', opacity: 0.3 }} /> Update existing ones
                            </TypeButton>
                          )}
                        </FlexerRow>
                      </>
                    )}

                    {smallView && (
                      <>
                        You’re trying to{' '}
                        <b style={{ margin: '0 3px' }}>
                          {createOrUpdateMode === CSV_MODES.UPDATE ? 'update existing' : 'create new'} {entityName}
                        </b>{' '}
                        using your file: {acceptedFile.name} ({formatFileSize(acceptedFile.size)}).
                        <CancelButton style={{ marginLeft: 8 }} {...getRemoveFileProps()}>
                          Cancel import
                        </CancelButton>
                      </>
                    )}

                    {!smallView && (
                      <RelativeStyledIconButton
                        disabled={!draftParsedCsv || !createOrUpdateMode}
                        filled
                        border
                        fontWeight="bold"
                        color={COLORS.GREEN}
                        onClick={(event) => {
                          event.stopPropagation();
                          setParsedCsv(draftParsedCsv);
                        }}
                      >
                        Continue Importing
                      </RelativeStyledIconButton>
                    )}
                  </ZoneInfo>
                  <StyledProgressBar>
                    <ProgressBar />
                  </StyledProgressBar>
                </div>
              ) : (
                <ZoneInfo data-cy="upload-csv__load-zone">
                  <UploadIcon />
                  <InfoText>
                    Please
                    <span>upload file with {entityName}</span>
                  </InfoText>

                  <SubInfo>(csv only, less 100 mb)</SubInfo>
                </ZoneInfo>
              )}

              <ZoneFooter smallView={smallView}>
                {!smallView && (
                  <FooterIcons>
                    <ClickIcon style={{ opacity: 0.3 }} />
                    <GreyDot spacing={0} />
                    <DragIcon />
                  </FooterIcons>
                )}
                <span>
                  {smallView ? (
                    <>
                      <i>For re-uploading</i>
                      <OpacityText>click or drag&drop</OpacityText>
                    </>
                  ) : (
                    'click or drag&drop for uploading'
                  )}
                </span>
              </ZoneFooter>
            </Zone>
          );
        }}
      </CSVReader>
    </Uploader>
  );
};
