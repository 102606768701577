import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from 'AppContext';
import { ReactComponent as AlarmIcon } from 'images/alarm.svg';
import LoadingGif from 'images/preloader.gif';
import { getLoadTime } from './utils';
import { SyledLoadWrapper, OpacityWrapper, LoadBar, BarTitle, ProgressBar, BarTime, Progress } from './styles';

export const TimeLoader = ({ pageName, isFirstLoad, showLoadingGif = true }) => {
  const {
    appSettings: { optimisticAnalytics, isCommitted, rollup },
    organizations,
    dataFilter,
    requiredPlugins,
  } = useContext(AppContext);

  const transactionCount = organizations && parseInt(organizations[0]?.transactionCount);
  const customersCount = organizations && parseInt(organizations[0]?.customersCount);

  const { cohortSegmentBy, spreadSegmentBy, transactionsConfirmation } = dataFilter;

  const loadTime = getLoadTime({
    pageName,
    transactionCount,
    customersCount,
    optimisticAnalytics,
    isCommitted,
    rollup,
    includeTTM: requiredPlugins?.includes('TTMMetrics'),
    cohortSegmentBy,
    spreadSegmentBy,
    transactionsConfirmation,
  });

  const [seconds, setSeconds] = useState(loadTime);

  useEffect(() => {
    let tick;
    if (seconds > 0) {
      tick = setTimeout(() => setSeconds(seconds - 1), 1000);
    } else {
      setSeconds(0);
    }

    return () => {
      clearTimeout(tick);
    };
  }, [seconds]);

  return (
    <SyledLoadWrapper data-cy="loader">
      <LoadBar>
        <BarTitle>
          <AlarmIcon style={{ marginRight: 4 }} />
          Crunching Data:
          <BarTime>{seconds}s</BarTime>
        </BarTitle>
        <ProgressBar>
          <Progress loadTime={loadTime} />
        </ProgressBar>
      </LoadBar>

      {showLoadingGif && <img className="loadGif" src={LoadingGif} alt="Loading..." />}

      <OpacityWrapper isFirstLoad={isFirstLoad} />
    </SyledLoadWrapper>
  );
};
