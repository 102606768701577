import styled from 'styled-components';
import { Centerer, FlexBetweenContainer } from 'components/Core';
import { CHANGE_TYPES } from './consts';

export const SpreadRows = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  overflow: auto;
  padding: 16px 12px;
`;

const spreadRowBackgroundColor = ({ changeType }) => {
  switch (changeType) {
    case CHANGE_TYPES.NEW:
      return 'background-color: var(--primaryGreen20);';
    case CHANGE_TYPES.DELETED:
      return 'background-color: var(--primaryRed20);';
    case CHANGE_TYPES.CHANGED:
      return 'background-color: var(--primaryYellow20);';
    default:
      return '';
  }
};

export const SpreadRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 2px;
  padding: 4px;
  border-radius: 8px;
  ${(props) => spreadRowBackgroundColor(props)}
  pointer-events: ${(props) => props.disabled && 'none'};

  span {
    font-weight: bold;
  }
`;

export const LeftToRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
  padding-top: 13px;
  border-top: 1px dashed rgba(0, 21, 46, 0.2);
`;

export const LeftToTitle = styled.div`
  font-weight: 900;
  font-size: 10px;
  line-height: 14px;
  opacity: 0.3;
  text-transform: uppercase;
`;

export const LeftToContent = styled.div`
  font-weight: 900;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;

  span {
    opacity: 0.2;
  }
`;

export const SpreadHeader = styled(FlexBetweenContainer)`
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  padding-right: 10px;
  gap: 8px;
  background: white;
  border: 1px solid var(--accentGraySecond);
  border-radius: 16px;
  margin-bottom: 8px;
`;

export const HeaderItem = styled.div`
  width: ${({ width }) => width ?? '23.3%'};
  font-size: 10px;
  font-style: normal;
  font-weight: 800;
  line-height: 12px; /* 120% */
  letter-spacing: 0.1px;
  text-transform: uppercase;
  margin-right: 10px;
  text-align: ${({ textAlign }) => textAlign};
  padding-right: ${({ paddingRight }) => paddingRight};
  color: var(--primaryBlack75);
`;

export const RowItem = styled.div`
  width: ${({ width }) => width ?? '23.3%'};
  display: flex;
  align-items: center;
  opacity: ${(props) => props.isOpacityRow && 0.2};
  margin-right: 10px;

  > * {
    width: 100%;
  }
`;

export const ActionIcon = styled.div`
  cursor: pointer;
  margin-right: 10px;
  width: auto;
  pointer-events: ${(props) => props.disabled && 'none'};
  opacity: 0.3;

  &:hover {
    opacity: 1;
  }

  svg path {
    fill: ${(props) => props.disabled && 'var(--primaryBlack)'};
  }

  &:last-child {
    margin-right: 0;
  }
`;

export const UsageDetailsButton = styled(Centerer)`
  color: var(--primaryBlue);
  font-size: 10px;
  font-weight: 700;
  letter-spacing: 0em;
  cursor: ${({ disabled }) => (disabled ? undefined : 'pointer')};
  gap: 6px;
  opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};
  margin-left: ${({ isLast }) => (isLast ? '14px' : 0)};
`;

export const InvoiceButton = styled(Centerer)`
  color: var(--primaryBlue);
  font-size: 10px;
  font-weight: 700;
  letter-spacing: 0em;
  cursor: ${({ disabled }) => (disabled ? undefined : 'pointer')};
  gap: 6px;
  opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};
  margin-left: ${({ isLast }) => (isLast ? '14px' : 0)};

  svg path {
    fill: var(--primaryBlue);
  }
`;
