import React, { useContext } from 'react';
import { AppContext } from 'AppContext';
import { ADDITIONAL_ORG_CONFIGS } from 'consts/global';
import {
  BILLING_CUSTOM_FIELDS_VARIABLES_TYPE,
  BILLING_CUSTOM_FIELDS_VARIABLE_LABELS,
  CUSTOM_FIELD_TO_INVOICE_FIELD_MAP,
} from 'consts/billing';
import { formatDateForDatepicker, updateDateFromDatePicker } from 'utils/dateUtils';
import { Flexer, FlexerColumn } from 'components/Core';
import { CustomDatePicker, FormikCustomInput } from 'components/Controls';
import { SectionTitle } from '../../InvoiceScheduleWarnings/styles';
import { InvoicingScheduleContext } from '../../InvoicingScheduleContext';

const METADATA_FIELD_TYPE_LABELS = {
  transaction_customer_metadata_: '(Transaction Customer) ',
  transaction_metadata_: '(Transaction) ',
  customer_metadata_: '(Customer) ',
};

export const InvoiceCustomFields = ({ values, setFieldValue, disableEditing }) => {
  const { orgConfigs } = useContext(AppContext);
  const { invoicingScheduleFormValues } = useContext(InvoicingScheduleContext);

  const billingCustomFields =
    orgConfigs[ADDITIONAL_ORG_CONFIGS.BILLING_CUSTOM_FIELDS_BY_INTEGRATION_ID]?.[
      invoicingScheduleFormValues?.integration_id
    ];

  if (Object.values(billingCustomFields ?? {}).length === 0) {
    return null;
  }

  return (
    <FlexerColumn gap="16px">
      <SectionTitle>Custom Fields</SectionTitle>
      <Flexer gap="16px" width="100%" minWidth="200px">
        {Object.values(billingCustomFields ?? {})
          .filter((key) => !Object.keys(CUSTOM_FIELD_TO_INVOICE_FIELD_MAP).includes(key))
          .map((key) => (
            <FlexerColumn width="100%" minWidth="200px" key={key}>
              {BILLING_CUSTOM_FIELDS_VARIABLES_TYPE[key] === 'date' ? (
                <CustomDatePicker
                  formik
                  name={`custom_fields.${key}`}
                  label={BILLING_CUSTOM_FIELDS_VARIABLE_LABELS[key] ?? key}
                  dateFormat="MMM dd, yyyy"
                  selected={values?.custom_fields?.[key] ? formatDateForDatepicker(values?.custom_fields?.[key]) : null}
                  onChange={(name, date) => setFieldValue(name, updateDateFromDatePicker(date))}
                  disabled={disableEditing}
                />
              ) : (
                <FormikCustomInput
                  name={`custom_fields.${key}`}
                  label={
                    BILLING_CUSTOM_FIELDS_VARIABLE_LABELS[key] ??
                    Object.entries(METADATA_FIELD_TYPE_LABELS).reduce(
                      (acc, [type, label]) => acc.replace(type, label),
                      key,
                    )
                  }
                  placeholder="Optional"
                  type={BILLING_CUSTOM_FIELDS_VARIABLES_TYPE[key] ?? 'text'}
                  isDisabled={disableEditing}
                />
              )}
            </FlexerColumn>
          ))}
      </Flexer>
    </FlexerColumn>
  );
};
