import dayjs from 'dayjs';
import { isNil } from 'lodash';
import { INTEGRATION_TYPES } from 'consts/integrations';
import { RECOGNITION_TYPES } from 'consts/global';
import { SOURCE_TYPES, NAME_MATCHING_SOURCE, INTERNAL_CUSTOMER_ID_SOURCE } from './consts';

// By default just get the first integration source
export const getInitialIdSource = ({ idSources }) => ({
  type: SOURCE_TYPES.EXTERNAL,
  source: Object.keys(idSources)[0],
});

export const getOrgIdSources = ({ integrations, internalSource, suffix }) => {
  const idSources = {};
  integrations.forEach((integration) => {
    if (![INTEGRATION_TYPES.Analytics, INTEGRATION_TYPES.CSV].includes(integration.type))
      idSources[integration.service] = `${integration.service} ${suffix}`;
  });

  // Add internal sources if organization has them
  if (internalSource) idSources[internalSource] = internalSource;

  return idSources;
};

// Fill in transactions cells on first initialize and changes transaction id
//  first we try to get the value from csv, then from subscript transaction if there is one
export const getTransactionsCellsValue = ({
  transactions,
  customers,
  formRefCurrent,
  products = [],
  transactionId,
  rowIndex,
}) => {
  const formTransactions = formRefCurrent?.values ?? [];

  const fillInRow = ({ index }) => {
    const formTransaction = formTransactions?.[index];

    const subscriptTransactionById = transactions?.find(
      (transaction) => transaction.provider_object_id === (transactionId ?? formTransaction?.transaction_id),
    );
    // Fill date cell
    const initialDateCellValue = formTransaction.date;
    const initialStartDateCellValue = formTransaction.start_date;

    // We use || there because initial value can be empty string
    // if there is no date in csv and subsctipt transaction we use start date
    const dateCellValue =
      initialDateCellValue ||
      subscriptTransactionById?.transaction_date ||
      initialStartDateCellValue ||
      subscriptTransactionById?.transaction_start_date;
    formRefCurrent?.setFieldValue(`[${index}].date`, dateCellValue);

    // Fill name
    // if there is no date in csv and subscript transaction we set name in format "Customer - Date"
    const nameValue =
      formTransaction.name ||
      subscriptTransactionById?.transaction_name ||
      (dateCellValue ? `customer - ${dateCellValue}` : '');
    formRefCurrent?.setFieldValue(`[${index}].name`, nameValue);

    // Fill customer id
    const importsCustomer = subscriptTransactionById?.transaction_customer_id
      ? customers?.find((customer) => customer.chiffer_object_id === subscriptTransactionById?.transaction_customer_id)
      : null;

    const customerIdValue = formTransaction.customer_id || importsCustomer?.provider_object_id;
    formRefCurrent?.setFieldValue(`[${index}].customer_id`, customerIdValue);

    // Fill amount
    const amountValue = formTransaction.amount || subscriptTransactionById?.transaction_amount;
    formRefCurrent?.setFieldValue(`[${index}].amount`, amountValue);

    // Fill currency
    const currencyValue = formTransaction.currency || subscriptTransactionById?.currency;
    formRefCurrent?.setFieldValue(`[${index}].currency`, currencyValue);

    // Fill recurring_amount
    const recurringAmountValue = formTransaction.recurring_amount;
    formRefCurrent?.setFieldValue(`[${index}].recurring_amount`, recurringAmountValue);

    // Fill product
    // In CSV, products are usually filled as name
    const productByName = products?.find(
      (product) => product?.name?.toLowerCase() === formTransaction?.product_id?.toLowerCase(),
    );
    const productIdValue =
      productByName?.id || formTransaction.product_id || subscriptTransactionById?.transaction_product_id;
    formRefCurrent?.setFieldValue(`[${index}].product_id`, productIdValue);

    // Fill Start Date
    const startDateValue = initialStartDateCellValue || subscriptTransactionById?.transaction_start_date;
    formRefCurrent?.setFieldValue(`[${index}].start_date`, startDateValue);

    // Fill End Date
    const endDateValue = formTransaction.end_date || subscriptTransactionById?.transaction_end_date;
    formRefCurrent?.setFieldValue(`[${index}].end_date`, endDateValue);

    // Fill recognition
    const recognitionValue = formTransaction.recognition || subscriptTransactionById?.transaction_recognition;
    formRefCurrent?.setFieldValue(`[${index}].recognition`, recognitionValue);

    // Fill seats
    const seatsValue = formTransaction.seats || subscriptTransactionById?.transaction_seats;
    formRefCurrent?.setFieldValue(`[${index}].seats`, seatsValue);
  };

  if (!isNil(rowIndex)) {
    // Fill in single row
    fillInRow({ index: rowIndex });
  } else {
    // Fill in all rows
    formTransactions.forEach((formTransaction, forEachIndex) => fillInRow({ index: forEachIndex }));
  }
};

// Duplicate of the same util from backend file importTransactionsUtils.js
const CAPITAL_LETTERS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
const CAPITAL_LETTERS_AND_NUMBERS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ012345';
/**
 * Takes a SFDC case-sensitive 15 character id and adds the checksums to make it a
 *  case insensitive 18 character id (the latter is what the API returns)
 * @param {string} sfdc15Id
 */
export const convertSFDC15ToSFDC18 = (sfdc15Id) => {
  // Just in case there's not exactly 15 chars
  if (sfdc15Id.length === 18) {
    return sfdc15Id;
  }
  if (sfdc15Id.length !== 15) {
    console.log(`Bad salesforce id! Not 15 nor 18 characters - ${sfdc15Id}`);
    return sfdc15Id;
  }
  let id18 = sfdc15Id.slice();
  // Groups of 5
  for (let i = 0; i < sfdc15Id.length; i += 5) {
    let checksumIndex = 0;
    checksumIndex += CAPITAL_LETTERS.includes(sfdc15Id.charAt(i)) ? 1 : 0;
    checksumIndex += CAPITAL_LETTERS.includes(sfdc15Id.charAt(i + 1)) ? 2 : 0;
    checksumIndex += CAPITAL_LETTERS.includes(sfdc15Id.charAt(i + 2)) ? 4 : 0;
    checksumIndex += CAPITAL_LETTERS.includes(sfdc15Id.charAt(i + 3)) ? 8 : 0;
    checksumIndex += CAPITAL_LETTERS.includes(sfdc15Id.charAt(i + 4)) ? 16 : 0;
    id18 += CAPITAL_LETTERS_AND_NUMBERS.charAt(checksumIndex);
  }
  return id18;
};

const findTransactionSubscriptId = ({ transactionId, transactions, transactionsIdSourceAndType }) => {
  if (transactionsIdSourceAndType?.type === SOURCE_TYPES.INTERNAL) {
    return transactions?.find((transaction) => transaction.id === transactionId)?.id;
  } else {
    return transactions?.find((transaction) => transaction.provider_object_id === transactionId)?.chiffer_object_id;
  }
};

export const findCustomerSubscriptId = ({
  customerId,
  uploadCsvCustomersIdSource,
  customerIdSourceAndType,
  customers,
}) => {
  if ([INTERNAL_CUSTOMER_ID_SOURCE, NAME_MATCHING_SOURCE].includes(customerIdSourceAndType?.source)) {
    return customerId;
  } else if (customerIdSourceAndType?.type === SOURCE_TYPES.INTERNAL) {
    return customers?.find((customer) => customer?.metadata?.[uploadCsvCustomersIdSource] === customerId)?.id;
  } else {
    return customers?.find((customer) => customer.provider_object_id === customerId)?.chiffer_object_id;
  }
};

export const getFormFinalTransactions = ({
  formTransactions,
  uploadCsvCustomersIdSource,
  subscriptTransactions,
  subscriptCustomers,
  customerIdSourceAndType,
  transactionsIdSourceAndType,
  organizations,
}) => {
  return formTransactions.map((value) => {
    const finalTransaction = { ...value, organization_id: organizations[0].id };
    if (value.transaction_id) {
      // Updating a transaction
      finalTransaction.id = findTransactionSubscriptId({
        transactions: subscriptTransactions,
        transactionId: value.transaction_id,
        transactionsIdSourceAndType,
      });
      if (value.customer_id)
        finalTransaction.customer_id = findCustomerSubscriptId({
          customerId: value.customer_id,
          customers: subscriptCustomers,
          customerIdSourceAndType,
          uploadCsvCustomersIdSource,
        });
      if (value.date) finalTransaction.date = dayjs(value.date).format('YYYY-MM-DD');
      if (value.start_date) finalTransaction.start_date = dayjs(value.start_date).format('YYYY-MM-DD');
      if (value.end_date) finalTransaction.end_date = dayjs(value.end_date).format('YYYY-MM-DD');
      if (isNil(value.recurring_amount)) delete finalTransaction.recurring_amount; // in update, null is different than undefined
    } else {
      finalTransaction.confirmed = true;
      finalTransaction.customer_id = findCustomerSubscriptId({
        customerId: value.customer_id,
        customers: subscriptCustomers,
        customerIdSourceAndType,
        uploadCsvCustomersIdSource,
      });
      finalTransaction.date = dayjs(value.date).format('YYYY-MM-DD'); // sets to today if it's not there
      if (value.start_date) finalTransaction.start_date = dayjs(value.start_date).format('YYYY-MM-DD');
      if (value.end_date) finalTransaction.end_date = dayjs(value.end_date).format('YYYY-MM-DD');
      if (!value.recognition) finalTransaction.recognition = null; // need to make it explicitly null if missing for new transactions
      if (finalTransaction.recognition === RECOGNITION_TYPES.tillCanceled && isNil(value.recurring_amount))
        finalTransaction.recurring_amount = value.amount;
    }

    // we use id field with id from subscript instead of this
    delete finalTransaction.transaction_id;
    return finalTransaction;
  });
};
