import {
  INTERNAL_CUSTOMER_ID_SOURCE,
  NAME_MATCHING_SOURCE,
  SOURCE_TYPES,
} from 'views/Transactions/TransactionsCsvUpload/consts';
import * as Yup from 'yup';

export const getSchema = ({ customers, customerIdSourceAndType, uploadCsvCustomersIdSource }) => {
  const validCustomerIds = [INTERNAL_CUSTOMER_ID_SOURCE, NAME_MATCHING_SOURCE].includes(customerIdSourceAndType?.source)
    ? (customers ?? []).map(({ id }) => id)
    : customers?.map((customer) =>
        customerIdSourceAndType?.type === SOURCE_TYPES.INTERNAL
          ? customer.metadata?.[uploadCsvCustomersIdSource]
          : customer.provider_object_id,
      );

  const schema = Yup.array().of(
    Yup.object({
      customer_id: Yup.string()
        .nullable()
        .oneOf([null, undefined, ...(validCustomerIds ?? [])], "We can't find that customer"),
      name: Yup.string().nullable().required('Event name is missing'),
      source: Yup.string().nullable().required('Source name is missing'),
    }),
  );

  return schema;
};
