import styled from 'styled-components';
import { Centerer, Flexer, FlexerColumn } from 'components/Core';

export const InputsRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 20px;
`;

export const InputsColumn = styled(Flexer)`
  flex: 1;
  gap: 20px;

  div {
    flex: 1;
  }
`;

export const SettingsSeparator = styled(Centerer)`
  padding: 20px 0;

  font-weight: 900;
  font-size: 14px;
  line-height: 20px;
`;

export const Line = styled.div`
  flex: 1;
  height: 1px;
  background-color: var(--accentGraySecond);
  margin: 0 10px;
`;

export const SettingExplainer = styled(Flexer)`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  opacity: 0.4;

  padding: 8px 0;

  div {
    padding-left: 9px;
  }

  svg {
    path {
      fill: var(--primaryBlack75);
    }
  }
`;

export const PipelineToBeClosedChartCard = styled(FlexerColumn)`
  align-items: center;
  padding: 0;
  background: var(--primaryBlack2);
  border: 1px solid var(--accentGraySecond);
  border-radius: 16px;
`;

export const PipelineToBeClosedChartTitle = styled(Flexer)`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;

  padding: 20px 0 10px 0;

  span {
    padding: 0 3px;
    font-weight: 700;
  }
`;
