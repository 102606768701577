import { useContext, useMemo } from 'react';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { useHistory } from 'react-router-dom';
import { AppContext } from 'AppContext';
import { Row } from 'components/Core';
import { FileInvoiceIcon } from 'components/Icons';
import { DropdownButton, DropdownButtonItem } from 'components/Buttons';
import { CircleLoader } from 'components/Loaders';
import { TooltipContainer } from 'components/Tooltip';
import { useInvoicesStatusCountAPI } from 'api/billing';
import { INVOICE_STATUSES } from 'views/Billing/consts';
import { useStateWithStorage } from 'utils/hooks';
import { DASHBOARD_PERIOD_OPTIONS } from './consts';
import { onSelectDashboardWidgetPeriod } from './utils';
import {
  Widget,
  DropdownTitle,
  DropdownRow,
  CountersRow,
  Counter,
  CounterNumber,
  CounterInfo,
  CounterInfoTitle,
  CounterInfoSubtitle,
  LoadingSpinnerWrapper,
} from './styles';

dayjs.extend(isBetween);

export const NewInvoicesWidget = () => {
  const { orgId } = useContext(AppContext);
  const history = useHistory();
  const [selectedPeriod, setSelectedPeriod] = useStateWithStorage(
    'billing-dashboard__new-invoices-widget__period',
    DASHBOARD_PERIOD_OPTIONS.thisMonth,
  );

  const { startMonth, endMonth } = useMemo(() => onSelectDashboardWidgetPeriod({ period: selectedPeriod }), [
    selectedPeriod,
  ]);

  const { data: invoicesStatusCount, isLoading } = useInvoicesStatusCountAPI({
    orgId,
    params: {
      startDate: startMonth,
      endDate: endMonth,
    },
  });

  const invoicesByStatusCount = useMemo(
    () =>
      invoicesStatusCount?.reduce(
        (acc, { invoice_status, count }) => ({ ...acc, [invoice_status]: parseInt(count ?? 0) }),
        {},
      ) ?? {},
    [invoicesStatusCount],
  );

  return (
    <Widget>
      <TooltipContainer
        hideArrow
        yOffset={20}
        fontSize="12px"
        width={220}
        toolTipContent="Click on the “Create” or “Send” button"
      >
        <Row horizontal="space-between" style={{ width: '100%' }}>
          <DropdownRow>
            <DropdownTitle>New invoices:</DropdownTitle>
            <DropdownButton
              noBorder
              buttonPadding="0"
              popoverYOffset="25"
              text={selectedPeriod}
              buttonFontWeight="700"
              color="var(--primaryBlack)"
              data-cy="billing-dashboard__new-invoices-widget__dropdown"
            >
              {Object.keys(DASHBOARD_PERIOD_OPTIONS).map((option) => (
                <DropdownButtonItem
                  name={option}
                  key={option}
                  onClick={() => setSelectedPeriod(DASHBOARD_PERIOD_OPTIONS[option])}
                >
                  {DASHBOARD_PERIOD_OPTIONS[option]}
                </DropdownButtonItem>
              ))}
            </DropdownButton>
          </DropdownRow>

          <FileInvoiceIcon style={{ opacity: '0.3' }} />
        </Row>

        <>
          {!!isLoading ? (
            <LoadingSpinnerWrapper data-cy="loader">
              <CircleLoader width="35px" height="35px" isAbsolute isInline={true} />
            </LoadingSpinnerWrapper>
          ) : (
            <>
              <CountersRow style={{ paddingRight: 40 }}>
                <Counter
                  data-cy="billing-dashboard__new-invoices-widget__counter-unsent-invoices"
                  onClick={() => {
                    history.push('/billing/invoices', { status: INVOICE_STATUSES.UNSENT, startMonth, endMonth });
                  }}
                >
                  <CounterNumber
                    isEmpty={!invoicesByStatusCount?.[INVOICE_STATUSES.UNSENT]}
                    data-cy="billing-dashboard__new-invoices-widget__counter-unsent-invoices__counter"
                    lightBlue
                  >
                    {invoicesByStatusCount?.[INVOICE_STATUSES.UNSENT] || 0}
                  </CounterNumber>

                  <CounterInfo>
                    <CounterInfoTitle>Send new invoices</CounterInfoTitle>

                    <CounterInfoSubtitle>
                      on{' '}
                      {selectedPeriod === DASHBOARD_PERIOD_OPTIONS.today
                        ? dayjs(endMonth).format('MMM D, YYYY')
                        : `${dayjs(startMonth).format('MMM D, YYYY')} - ${dayjs(endMonth).format('MMM D, YYYY')}`}
                    </CounterInfoSubtitle>
                  </CounterInfo>
                </Counter>
                <Counter
                  onClick={() => {
                    history.push('/billing/review-transactions');
                  }}
                >
                  <CounterNumber
                    lightBlue
                    isEmpty={!invoicesByStatusCount?.[INVOICE_STATUSES.TRANSACTIONS_THAT_NEED_SCHEDULES]}
                  >
                    {invoicesByStatusCount?.[INVOICE_STATUSES.TRANSACTIONS_THAT_NEED_SCHEDULES] || 0}
                  </CounterNumber>

                  <CounterInfo>
                    <CounterInfoTitle>Create new invoices</CounterInfoTitle>

                    <CounterInfoSubtitle>Based on confirmed transactions</CounterInfoSubtitle>
                  </CounterInfo>
                </Counter>
                <Counter
                  data-cy="billing-dashboard__auto-charge-widget__counter-unsent-invoices"
                  onClick={() => {
                    history.push('/billing/invoices', { status: INVOICE_STATUSES.AUTO_CHARGE, startMonth, endMonth });
                  }}
                >
                  <CounterNumber
                    isEmpty={!invoicesByStatusCount?.[INVOICE_STATUSES.AUTO_CHARGE]}
                    data-cy="billing-dashboard__auto-charge-widget__counter-unsent-invoices__counter"
                    lightBlue
                  >
                    {invoicesByStatusCount?.[INVOICE_STATUSES.AUTO_CHARGE] || 0}
                  </CounterNumber>

                  <CounterInfo>
                    <CounterInfoTitle>Invoices will be auto-charged</CounterInfoTitle>

                    <CounterInfoSubtitle>
                      on{' '}
                      {selectedPeriod === DASHBOARD_PERIOD_OPTIONS.today
                        ? dayjs(endMonth).format('MMM D, YYYY')
                        : `${dayjs(startMonth).format('MMM D, YYYY')} - ${dayjs(endMonth).format('MMM D, YYYY')}`}
                    </CounterInfoSubtitle>
                  </CounterInfo>
                </Counter>
              </CountersRow>
            </>
          )}
        </>
      </TooltipContainer>
    </Widget>
  );
};
