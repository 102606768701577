import { useContext } from 'react';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useInvoicesAPI } from 'api/billing';
import { AppContext } from 'AppContext';
import { ReactComponent as AIHint } from 'images/ai-hint.svg';
import { InvoicingScheduleContext } from 'views/Billing/InvoicingScheduleModal/InvoicingScheduleContext';
import { Row } from 'components/Core';
import { numberFormatter } from 'utils/formatters';
import { NUMBER_FORMATS } from 'consts/global';

const RelatedWarning = styled.div`
  padding: 12px;
  background-color: var(--accentGrayFourth);
  border: 1px solid #f3f3f3;
  width: 100%;
  border-radius: 8px;
`;

const TitleRow = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 16px;
`;

const InvoicesList = styled.div`
  padding-left: 29px;
  margin-top: 6px;
  gap: 4px;
`;

const RelatedInvoice = styled(Link)`
  cursor: pointer;
  color: var(--primaryBlue);

  &:hover {
    color: var(--secondaryBlue);
  }
`;

export const AIReminderRelatedInvoicesWarning = () => {
  const {
    orgId,
    appSettings: { currencyISOCode: defaultOrgCurrency },
  } = useContext(AppContext);
  const { reminderFormValues, invoiceFormValues } = useContext(InvoicingScheduleContext);

  const relatedInvoicesIds = reminderFormValues?.ai_generated
    ? reminderFormValues?.related_invoice_ids?.filter((id) => id !== invoiceFormValues?.id)
    : [];

  const { data: relatedInvoices } = useInvoicesAPI({
    orgId,
    invoiceIds: relatedInvoicesIds,
    autoFetch: true,
  });

  return relatedInvoicesIds && relatedInvoicesIds.length > 0 ? (
    <RelatedWarning>
      <TitleRow>
        <AIHint style={{ marginRight: 8 }} />
        In this reminder, Subscript AI also took into account other overdue invoices for this customer:
      </TitleRow>

      <InvoicesList>
        {relatedInvoices?.data?.map((invoice) => (
          <Row horizontal="start" key={invoice.id}>
            <span style={{ opacity: 0.3, marginRight: 4 }}>–</span>{' '}
            <RelatedInvoice key={invoice.id} to={`/billing/invoices/${invoice.id}`} target="_blank">
              {invoice?.invoice_number || invoice?.customer_name} ({dayjs(invoice?.date).format('MMM D, YYYY')}):{' '}
              {numberFormatter({
                type: NUMBER_FORMATS.CURRENCY,
                rawValue: invoice?.amount,
                currency: invoice?.currency ?? defaultOrgCurrency,
              })}
            </RelatedInvoice>
          </Row>
        ))}
      </InvoicesList>
    </RelatedWarning>
  ) : null;
};
