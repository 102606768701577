import { cssVar, transparentize } from 'polished';
import { COLORS } from 'consts/colors';

export const getColor = (color, active = true) => {
  switch (color) {
    case COLORS.GREEN:
      return active ? cssVar('--primaryGreen') : cssVar('--secondaryGreen');
    case COLORS.BLUE:
      return active ? cssVar('--secondaryBlue') : cssVar('--primaryBlue');
    case COLORS.RED:
      return active ? 'var(--primaryRed)' : transparentize(0.9, cssVar('--primaryRed'));
    case COLORS.GREY:
      return 'var(--primaryBlack5)';
    default:
      return cssVar('--primaryBlack');
  }
};

export const getFilledColor = (color) => {
  switch (color) {
    case COLORS.GREY:
      return cssVar('--primaryBlack');
    default:
      return '#fff';
  }
};
