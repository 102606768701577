import React, { useContext, useState } from 'react';
import { AppContext } from 'AppContext';
import { AutoConfirmationExplainModal } from 'views/Transactions/TransactionsContentModals/AutoConfirmationExplainModal';
import { ReactComponent as LeftIcon } from 'images/Arrow-left.svg';
import { ReactComponent as RightIcon } from 'images/Arrow-right.svg';
import { Banner, BannerText, BannerActions, BannerTitle } from 'components/Blocks/Banner';
import { getIntegrationName } from 'models/integration';
import { ProductsRecognitionBanner } from './ProductsRecognitionBanner';
import {
  AutoConfirmBannerTitle,
  StyledBannerButton,
  CarouselActions,
  ActionIcon,
  Carousel,
  CurrentBanner,
  HelpButton,
} from './styles';

export const TransactionsPageBanners = ({
  unconfirmedTransactionsCount = 0,
  updatedTransactionsCount = 0,
  showAutoconfirmBanner,
  onBulkProcessClick = () => {},
  onCheckUpdatesClick = () => {},
  onAutoUpdateClick = () => {},
}) => {
  const [showAutoconfirmHelpModal, setShowAutoconfirmHelpModal] = useState(false);
  const { integrations } = useContext(AppContext);
  const crmName = getIntegrationName({ integrations, type: 'CRM' });

  const productBannerOrNull = ProductsRecognitionBanner();

  const carouselBanners = [
    productBannerOrNull,

    unconfirmedTransactionsCount > 0 ? (
      <Banner onClick={onBulkProcessClick} redVersion>
        <BannerTitle redVersion>Transactions with missing details</BannerTitle>
        <BannerText style={{ marginBottom: 16 }}>
          Subscript can’t analyze or confirm {unconfirmedTransactionsCount} transactions because of missing details.
          <br />
          Please confirm or edit <b>{unconfirmedTransactionsCount} new transactions</b> that were missing details
          required for analysis
        </BannerText>
        <BannerActions>
          <StyledBannerButton>Go to bulk process</StyledBannerButton>
        </BannerActions>
      </Banner>
    ) : null,
    updatedTransactionsCount > 0 ? (
      <Banner onClick={onBulkProcessClick} redVersion data-cy="external-updates__banner">
        <BannerTitle redVersion>Confirm external transaction updates</BannerTitle>
        <BannerText style={{ marginBottom: 16 }}>
          Subscript is missing details on {updatedTransactionsCount} transactions that have changed in {crmName}.
          <br />
          Please review <b>{updatedTransactionsCount} old transactions</b> that have been changed in {crmName}
        </BannerText>
        <BannerActions>
          <StyledBannerButton onClick={onCheckUpdatesClick} data-cy="external-updates__banner-button--navigate">
            Check the changes
          </StyledBannerButton>
        </BannerActions>
      </Banner>
    ) : null,
  ].filter(Boolean);

  const changeBanner = ({ currentIndex, next = true }) => {
    const carouselBannerLength = carouselBanners.length;
    currentIndex = currentIndex + (next ? 1 : -1);
    if (currentIndex >= carouselBannerLength) currentIndex = 0;
    if (currentIndex < 0) currentIndex = carouselBannerLength - 1;

    return currentIndex;
  };

  const [currentBannerIndex, setCurrentBannerIndex] = useState(0);

  return (
    <>
      {unconfirmedTransactionsCount > 0 || updatedTransactionsCount > 0 || productBannerOrNull ? (
        <Carousel>
          {carouselBanners?.[currentBannerIndex]}
          <CarouselActions>
            <ActionIcon
              onClick={() =>
                setCurrentBannerIndex((prevIndex) => changeBanner({ currentIndex: prevIndex, next: false }))
              }
            >
              <LeftIcon />
            </ActionIcon>

            <CurrentBanner>
              critical Problem {currentBannerIndex + 1} / {carouselBanners.length}
            </CurrentBanner>
            <ActionIcon
              data-cy="transactions-banners__next-banner"
              onClick={() => setCurrentBannerIndex((prevIndex) => changeBanner({ currentIndex: prevIndex }))}
            >
              <RightIcon />
            </ActionIcon>
          </CarouselActions>
        </Carousel>
      ) : (
        <></>
      )}
      {showAutoconfirmBanner && (
        <Banner marginTop="20px" rowVersion blueVersion>
          <AutoConfirmBannerTitle>Automatically confirmed transactions</AutoConfirmBannerTitle>
          <BannerActions>
            <HelpButton onClick={() => setShowAutoconfirmHelpModal(true)}>how it works</HelpButton>
            <StyledBannerButton onClick={onAutoUpdateClick}>View Auto Confirmations</StyledBannerButton>
          </BannerActions>
        </Banner>
      )}
      {showAutoconfirmHelpModal && (
        <AutoConfirmationExplainModal hideModal={() => setShowAutoconfirmHelpModal(false)} />
      )}
    </>
  );
};
