import React from 'react';
import { Navbar } from './Navbar';

export const BaseNoHeader = ({ layoutProps, noPaddings, children }) => (
  <>
    <Navbar active={layoutProps.active} />
    <div
      style={{
        padding: (noPaddings || layoutProps.noPaddings) && '0px',
        backgroundColor: layoutProps.backgroundColor,
      }}
      className="main_content"
      id="main-content"
    >
      {children}
    </div>
  </>
);
