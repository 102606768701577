import { useContext, useState } from 'react';
import styled, { css } from 'styled-components';

import { ReactComponent as DiskIcon } from 'images/device-floppy.svg';
import { Flexer, FlexerColumn } from 'components/Core';
import { SwitchWithLabel } from 'components/Controls';
import { Popover } from 'components/Portal';
import { InvoicingScheduleContext } from 'views/Billing/InvoicingScheduleModal/InvoicingScheduleContext';
import { useClickOutside } from 'utils/hooks';

const HeaderWrapper = styled(Flexer)`
  padding: 12px 12px 0px 12px;
  margin-bottom: 5px;
`;

const HeaderTitleTag = styled(Flexer)`
  border-radius: 4px;
  border: 1px solid var(--neutralGray);
  padding: 4px;
  font-weight: 900;
  font-size: 9px;
  color: var(--primaryBlack50);
  width: fit-content;
  line-height: 12px;
`;

const Option = styled(Flexer)`
  padding: 16px 12px;
  font-size: 12px;
  font-weight: 700;

  &:last-child {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }

  ${({ isClickable }) =>
    isClickable &&
    css`
      &:hover {
        cursor: pointer;
        background-color: var(--primaryBlack2);
      }
    `}
`;

const StyledDiskIcon = styled(DiskIcon)`
  width: 16px;
  height: 16px;

  path {
    fill: var(--primaryBlack50);
  }
`;

const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: var(--accentGraySecond);
`;

export const SaveToExternalPopover = ({ onClose, onSelect, hasChanges }) => {
  const triggerRef = useClickOutside(onClose);
  const { invoicingServiceDisplayName } = useContext(InvoicingScheduleContext);
  const [applyChangesToAllInvoices, setApplyChangesToAllInvoices] = useState(false);

  return (
    <Popover YOffset={40} ref={triggerRef} width="200px">
      {hasChanges && (
        <FlexerColumn>
          <HeaderWrapper>
            <HeaderTitleTag>SAVE SETTINGS</HeaderTitleTag>
          </HeaderWrapper>

          <Option>
            <SwitchWithLabel
              onChange={() => setApplyChangesToAllInvoices(!applyChangesToAllInvoices)}
              name="billing__invoice-modal__apply-to-all"
              checked={applyChangesToAllInvoices}
              bolded
              label="Apply these changes to all invoices in this series"
              labelSize="12px"
              alignItems="flex-start"
            />
          </Option>
        </FlexerColumn>
      )}

      <FlexerColumn>
        {hasChanges && (
          <HeaderWrapper>
            <HeaderTitleTag>ACTIONS</HeaderTitleTag>
          </HeaderWrapper>
        )}

        <Option
          isClickable
          onClick={() => {
            onClose();
            onSelect({ applyChangesToAllInvoices, createExternalInvoice: true });
          }}
          data-cy="billing__invoice-modal__save-gl"
        >
          <StyledDiskIcon />
          In {invoicingServiceDisplayName} & Subscript
        </Option>

        <Line />

        <Option
          isClickable
          onClick={() => {
            onClose();
            onSelect({ applyChangesToAllInvoices, createExternalInvoice: false, saveOnlyInSubscript: true });
          }}
          data-cy="billing__invoice-modal__no-save-gl"
        >
          <StyledDiskIcon />
          Save only in Subscript
        </Option>
      </FlexerColumn>
    </Popover>
  );
};
