import styled from 'styled-components';
import { Centerer, Flexer } from 'components/Core';

export const EmptyStateContents = styled(Centerer)`
  flex-direction: column;
  padding: 20px;
`;

export const EmptyStateMainText = styled.div`
  font-weight: 900;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  color: var(--primaryBlack);
  margin-top: 24px;
`;

export const EmptyStateText = styled.div`
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: var(--primaryBlack);
`;

export const StepsWrapper = styled(Flexer)`
  margin-top: 16px;
  margin-bottom: 24px;
`;

export const Step = styled(Flexer)`
  margin-right: 28px;

  ${EmptyStateText} {
    width: ${(props) => props.textWidth || '128px'};
  }

  &:last-child {
    margin-right: 0;
  }
`;

export const StepsCircle = styled(Centerer)`
  width: 20px;
  height: 20px;
  border-radius: 100px;
  background: var(--accentGraySecond);
  margin: 0 8px;

  span {
    text-align: center;
    font-weight: 900;
    font-size: 10px;
    line-height: 14px;
    color: var(--primaryBlack);
  }
`;
