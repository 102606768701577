import React from 'react';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { Bar, defaults } from 'react-chartjs-2';
import { cssVar } from 'polished';
import { isEmpty } from 'lodash';

import { NUMBER_FORMATS } from 'consts/global';
import { reFormatDate } from 'utils/dateUtils';
import { amountWithAbbreviation, numberFormatter } from 'utils/formatters';

// Collected: value.paidRevenue,
// Sent: value.sentRevenue,
// Overdue: value.overdueRevenue,
// 'New to send': value.newToSendRevenue,

export const BAR_COLORS = [
  cssVar('--primaryGreen50'),
  cssVar('--primaryYellow50'),
  cssVar('--newRed50'),
  cssVar('--primaryBlue50'),
  cssVar('--primaryPurple50'),
];
export const SELECTED_BAR_COLORS = [
  cssVar('--primaryGreen90'),
  cssVar('--primaryYellow90'),
  cssVar('--newRed90'),
  cssVar('--primaryBlue90'),
  cssVar('--primaryPurple90'),
];
export const HOVER_BAR_COLORS = [
  cssVar('--primaryGreen65'),
  cssVar('--primaryYellow65'),
  cssVar('--newRed65'),
  cssVar('--primaryBlue65'),
  cssVar('--primaryPurple65'),
];
export const BAR_BORDER_COLORS = [
  cssVar('--primaryGreen'),
  cssVar('--primaryYellow'),
  cssVar('--newRed'),
  cssVar('--primaryBlue'),
  cssVar('--primaryPurple'),
];

const ChartLegendContainer = styled.div`
  width: 100%;
`;

const ChartContainer = styled.div`
  width: 100%;
`;

// Expect data in the form:
// {
//   "2022-09": {
//     "labe1": 1000,
//     "label2": 2000
//   },
//   "2022-10": {
//     "labe1": 2000,
//     "label2": 3000
//   }
// }
export const StackedBarChart = ({ data, height, currency, selectedMonth, setSelectedMonth }) => {
  if (isEmpty(data)) return null;

  defaults.global.defaultFontFamily = 'Nunito Sans';

  const months = Object.keys(data)
    .map((value) => reFormatDate(value, 'YYYY-MM', 'MMM YYYY'))
    ?.sort((a, b) => (dayjs(a, 'MMM YYYY') < dayjs(b, 'MMM YYYY') ? -1 : 1));
  const monthsValues = Object.values(data);
  const labels = Object.keys(monthsValues[0]);

  const chartData = labels.map((label, index) => ({
    type: 'bar',
    label,
    barThickness: 36,
    borderColor: BAR_BORDER_COLORS[index],
    backgroundColor: Object.keys(data).map(
      (month) => (month === selectedMonth ? SELECTED_BAR_COLORS : BAR_COLORS)[index],
    ),
    hoverBackgroundColor: Object.keys(data).map(
      (month) => (month === selectedMonth ? SELECTED_BAR_COLORS : HOVER_BAR_COLORS)[index],
    ),
    borderSkipped: false,
    borderWidth: 1,
    data: monthsValues.map((month) => month[label]),
    datalabels: {
      display: false,
    },
  }));

  chartData.unshift({
    type: 'line',
    label: 'Total',
    data: monthsValues.map((month) => Object.values(month).reduce((acc, value) => acc + value, 0)),
    backgroundColor: cssVar('--accentDarkGray'),
    fill: false,
    showLine: false,
    pointRadius: 0,
    pointHoverRadius: 0,
    datalabels: {
      display: true,
    },
  });

  const options = {
    responsive: true,
    layout: {
      padding: {
        top: 30,
      },
    },
    legend: {
      display: false,
    },
    tooltips: {
      mode: 'index',
      intersect: false,
      callbacks: {
        label: (tooltipItem, data) =>
          `${data.datasets[tooltipItem.datasetIndex].label || ''}: ${
            tooltipItem.yLabel === null || isNaN(tooltipItem.yLabel)
              ? 'disabled'
              : numberFormatter({
                  type: NUMBER_FORMATS.CURRENCY,
                  rawValue: tooltipItem.yLabel,
                  currency,
                })
          } `,
      },
    },
    scales: {
      xAxes: [
        {
          stacked: true,
          gridLines: {
            display: false,
          },
          ticks: {
            autoSkip: true,
            maxRotation: 0,
            minRotation: 0,
            fontStyle: 'normal',
            fontSize: 10,
            fontColor: cssVar('--primaryBlack70'),
          },
        },
      ],
      yAxes: [
        {
          stacked: true,
          gridLines: {
            display: true,
            drawBorder: false,
            color: cssVar('--primaryBlack3'),
          },
          ticks: {
            padding: 20,
            callback: (value) => amountWithAbbreviation({ value, type: 'currency', currency }),
            autoSkip: true,
            maxTicksLimit: 5,
            maxRotation: 0,
            minRotation: 0,
            fontStyle: 'normal',
            fontSize: 10,
            fontColor: cssVar('--primaryBlack70'),
          },
        },
      ],
    },
    plugins: {
      datalabels: {
        align: 'end',
        anchor: 'end',
        display: 'auto',
        borderRadius: 4,
        color: cssVar('--primaryBlack50'),
        backgroundColor: cssVar('--accentGraySecond'),
        font: {
          weight: 'bold',
        },
        formatter: (_, context) =>
          amountWithAbbreviation({ value: chartData[0]?.data?.[context.dataIndex], type: 'currency', currency }),
        padding: 6,
      },
    },
    // https://stackoverflow.com/questions/37122484/chart-js-bar-chart-click-events
    onClick: function (_, event) {
      const selectedIndex = event?.[0]?._index;
      if (selectedIndex !== undefined && selectedIndex !== null) {
        setSelectedMonth(Object.keys(data)[selectedIndex]);
      }
    },
  };

  return (
    <ChartLegendContainer>
      <ChartContainer>
        <Bar
          height={height ?? 60}
          data={{
            labels: months,
            datasets: chartData,
          }}
          options={options}
        />
      </ChartContainer>
    </ChartLegendContainer>
  );
};
