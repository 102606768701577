import React, { useContext, useMemo, useState } from 'react';

import { AppContext } from 'AppContext';
import { GROUP_BY_OPTIONS } from 'shared/TransactionTable/consts';
import { generateColumns } from './columns';
import { TransactionTable } from './TransactionTable';

// TODO: The number of props here is not ideal. An idea to refactor is to introduce a shared context
// in Transactions.js
const TransactionTableContainer = ({
  dataForTable,
  pageCount,
  pageSize,
  setPageSize,
  currentPageIndex,
  setCurrentPageIndex,
  isFetching,
  initialHiddenColumns,
  setHiddenTransactionColumns,
  transactionGroupBy,
  numberValue,
  columnsTitles,
  onDeleteClick,
  onUnconfirmClick,
  onTransactionClick,
  onBulkConfirm,
  onBulkDelete,
  noTransactions,
  initialSortBy,
  setSortBy,
  searchQuery,
  setSearchQuery,
  widgetView,
  additionalHeaderActions,
  showChecksColumn,
  onChecksTooltipClick,
}) => {
  const {
    appSettings: { isARR, currencyISOCode },
  } = useContext(AppContext);

  const [selectedTransactionIds, setSelectedTransactionIds] = useState([]);
  const [customerIdToShow, setCustomerIdToShow] = useState(null);
  const [rows, setRows] = useState();

  const data = useMemo(() => {
    return dataForTable;
  }, [dataForTable]);

  const hasSelectedUnconfirmedTransactions = useMemo(() => {
    if (dataForTable?.length) {
      const isRowSelectedAndUnconfirmed = (row) => row.id && selectedTransactionIds.includes(row.id) && !row.confirmed;
      if (transactionGroupBy === GROUP_BY_OPTIONS.CUSTOMER) {
        return dataForTable?.some((row) => row.subRows.some(isRowSelectedAndUnconfirmed));
      }
      return dataForTable?.some(isRowSelectedAndUnconfirmed);
    }
  }, [dataForTable, transactionGroupBy, selectedTransactionIds]);

  const hasSelectedUndeletedTransactions = useMemo(() => {
    if (dataForTable?.length) {
      const isRowSelectedAndNotDeleted = (row) => row.id && selectedTransactionIds.includes(row.id) && !row.archived_at;
      if (transactionGroupBy === GROUP_BY_OPTIONS.CUSTOMER) {
        return dataForTable?.some((row) => row.subRows.some(isRowSelectedAndNotDeleted));
      }
      return dataForTable?.some(isRowSelectedAndNotDeleted);
    }
  }, [dataForTable, transactionGroupBy, selectedTransactionIds]);

  const handleSelectedRowsChange = (selectedRowIds) => {
    const pageTransactionIds =
      rows
        ?.flatMap((row) =>
          row.canExpand
            ? row.subRows
                .filter((subRow) => {
                  subRow.isSelected = false;
                  return selectedRowIds[subRow.id];
                })
                .map((row) => {
                  row.isSelected = true;
                  return row.original.id;
                })
            : selectedRowIds[row.id] && row.original.id,
        )
        .filter(Boolean) ?? [];

    setSelectedTransactionIds(pageTransactionIds);
  };

  const onCustomerClick = (customerId) => {
    setCustomerIdToShow(customerId);
  };

  const columns = React.useMemo(
    () =>
      generateColumns({
        onDeleteClick,
        onUnconfirmClick,
        onTransactionClick,
        transactionGroupBy,
        onCustomerClick,
        isARR,
        currencyISOCode,
        showChecksColumn,
        onChecksTooltipClick,
      }),
    // eslint-disable-next-line
    [isARR],
  );

  return (
    <>
      {data ? (
        <TransactionTable
          numberValue={numberValue}
          columns={columns}
          noTransactions={noTransactions}
          selectedTransactionIds={selectedTransactionIds}
          initialHiddenColumns={initialHiddenColumns}
          setHiddenTransactionColumns={setHiddenTransactionColumns}
          columnsTitles={columnsTitles}
          data={data}
          pageSize={pageSize}
          setPageSize={setPageSize}
          pageCount={pageCount}
          currentPageIndex={currentPageIndex}
          setCurrentPageIndex={setCurrentPageIndex}
          isFetching={isFetching}
          onSelectedRowsChange={handleSelectedRowsChange}
          transactionGroupBy={transactionGroupBy}
          hasSelectedUnconfirmedTransactions={hasSelectedUnconfirmedTransactions}
          hasSelectedUndeletedTransactions={hasSelectedUndeletedTransactions}
          onConfirmTransactions={() => onBulkConfirm(selectedTransactionIds)}
          onDeleteTransactions={() => onBulkDelete(selectedTransactionIds)}
          getTableRows={setRows}
          customerIdToShow={customerIdToShow}
          setCustomerIdToShow={setCustomerIdToShow}
          initialSortBy={initialSortBy}
          setSortBy={setSortBy}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          widgetView={widgetView}
          additionalHeaderActions={additionalHeaderActions}
        />
      ) : (
        noTransactions
      )}
    </>
  );
};

export default TransactionTableContainer;
