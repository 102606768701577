import React, { Fragment, useCallback, useContext, useState } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { Formik } from 'formik';
import { FlexerRow, Spacer } from 'components/Core';
import { ReactComponent as CircleCheck } from 'images/circle-check-white.svg';
import { FlexEndContainer, Flexer } from 'components/Core';
import { ReactComponent as LeftArrow } from 'images/arrow-narrow-left.svg';
import { CustomDatePicker } from 'components/Controls';
import { SizedLoader } from 'components/Loaders';
import {
  Modal,
  ModalBody,
  ModalButton,
  ModalCloseIcon,
  ModalContainer,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTitleText,
} from 'components/Modal';
import { ReactComponent as SyncIcon } from 'images/refresh.svg';
import { formatDateForDatepicker, updateDateFromDatePicker } from 'utils/dateUtils';
import { BillingContext } from '../BillingContext';
import { LoaderContainer } from './InvoicingScheduleLayout.styles';
import { InvoicingScheduleContext } from './InvoicingScheduleContext';

const FooterInfoText = styled.div`
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: 16px;
  color: var(--primaryBlack50);
  margin-left: 8px;
`;

const DatesChangesTable = styled.div`
  width: 100%;
  padding: 10px 0px;
`;

const StyledSyncIcon = styled(SyncIcon)`
  path {
    fill: var(--primaryBlack50);
  }
`;

const Counter = styled.div`
  font-size: 9px;
  font-style: normal;
  font-weight: 900;
  line-height: 12px;
  text-transform: uppercase;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background: var(--primaryBlack5);
  margin-right: 8px;
`;

const DatesChangesTableBase = styled.div`
  width: 100%;
  display: grid;
  border-radius: 16px;
  overflow: hidden;
  grid-template-columns: ${({ doSomeInvoicesHaveAutoSend }) =>
    doSomeInvoicesHaveAutoSend ? '1fr 1.5fr 1.5fr 1.5fr 1.5fr' : '1fr 1.5fr 1.5fr 1.5fr'};
  border: ${({ withBorder }) => withBorder && '1px solid var(--accentGraySecond)'};
`;

const InlineDate = styled.div`
  width: 100%;
  border: none;
  background-image: none;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  padding: 0;
  height: auto;

  &:hover {
    border: none;
    font-weight: 700;
  }
`;

const DatesChangesTableColumnsWrapper = styled(DatesChangesTableBase)`
  border: 1px solid var(--accentGraySecond);
  border-radius: 16px;
  padding: 16px 20px;
  background: white;
`;

const DatesChangesTableColumn = styled(Flexer)`
  font-size: 10px;
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: 0.1px;
  color: var(--primaryBlack75);
  align-items: center;
  width: 100%;
`;

const DatesChangesTableCell = styled.div`
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  padding: 12px 20px;
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: ${({ noBorderBottom }) => (noBorderBottom ? 'none' : '1px solid var(--accentGraySecond)')};
  border-right: ${({ noBorderRight }) => (noBorderRight ? 'none' : '1px solid var(--accentGraySecond)')};
  background: ${({ background }) => background};

  &:last-child {
    border-right: none;
  }
`;

const BulkChangesModal = ({
  updatedInvoices,
  oldInvoices,
  changedFields,
  doSomeInvoicesHaveAutoSend,
  onClose,
  onBack,
}) => {
  const { bulkEditInvoices } = useContext(BillingContext);
  const { fillScheduleWithDraftInvoices, isScheduleDraft, invoicingScheduleFormValues } = useContext(
    InvoicingScheduleContext,
  );
  const [isUpdating, setIsUpdating] = useState(false);

  const handleUpdate = async (values) => {
    const invoicesForUpdate = values?.updatedInvoices?.map((invoice) => {
      const fieldsForUpdate = Object.keys(invoice).filter((key) => changedFields?.[key]);

      return fieldsForUpdate.reduce(
        (acc, key) => {
          acc[key] = invoice[key];

          if (key === 'date') {
            acc.service_start = invoice.service_start;
            acc.service_end = invoice.service_end;

            if (doSomeInvoicesHaveAutoSend) {
              acc.send_date = invoice.send_date;
            }
          }
          return acc;
        },
        { id: invoice?.id ?? invoice?.unsavedId },
      );
    });

    try {
      if (isScheduleDraft) {
        const updatedInvoices = invoicingScheduleFormValues?.invoices?.map((invoice) => {
          const invoiceForUpdate = invoicesForUpdate?.find(({ id }) => id === invoice?.unsavedId) ?? {};
          delete invoiceForUpdate?.id;

          return { ...invoice, ...invoiceForUpdate };
        });

        await fillScheduleWithDraftInvoices({
          invoices: updatedInvoices,
        });

        onClose();
      } else {
        setIsUpdating(true);
        await bulkEditInvoices({
          data: invoicesForUpdate,
        });
        setIsUpdating(false);
      }
    } catch (error) {
      setIsUpdating(false);
      console.error(error);
      onBack();
    }
  };

  return (
    <ModalContainer style={{ cursor: 'default' }}>
      <Modal data-cy="bulk-changes-modal" width="1140px" height="704px">
        <Formik initialValues={{ updatedInvoices }} onSubmit={handleUpdate}>
          {({ values, setFieldValue, getFieldMeta, handleSubmit }) => (
            <>
              <ModalHeader>
                <ModalCloseIcon onClose={onBack} />
                <ModalTitle margin="0 36px" compact>
                  <Flexer gap="8px" alignItems="center">
                    <ModalTitleText compact>
                      <b>
                        Apply the changes to {updatedInvoices?.length} unsent invoices in this series:{' '}
                        <span style={{ color: 'var(--primaryBlack50)', fontStyle: 'italic' }}>
                          click on any field to edit
                        </span>
                      </b>
                    </ModalTitleText>
                  </Flexer>
                </ModalTitle>
              </ModalHeader>

              <ModalBody>
                {isUpdating ? (
                  <LoaderContainer>
                    <SizedLoader size={40} />
                  </LoaderContainer>
                ) : !!changedFields?.date ? (
                  <DatesChangesTable>
                    <DatesChangesTableColumnsWrapper doSomeInvoicesHaveAutoSend={doSomeInvoicesHaveAutoSend}>
                      <DatesChangesTableColumn>inv. in series</DatesChangesTableColumn>
                      <DatesChangesTableColumn>Invoice date</DatesChangesTableColumn>
                      {doSomeInvoicesHaveAutoSend ? (
                        <DatesChangesTableColumn>auto-send date</DatesChangesTableColumn>
                      ) : null}
                      <DatesChangesTableColumn>service start date</DatesChangesTableColumn>
                      <DatesChangesTableColumn>service end date</DatesChangesTableColumn>
                    </DatesChangesTableColumnsWrapper>

                    <Spacer height="8px" />

                    <DatesChangesTableBase withBorder doSomeInvoicesHaveAutoSend={doSomeInvoicesHaveAutoSend}>
                      {values?.updatedInvoices?.map((invoice, invoiceIndex, arr) => (
                        <Fragment key={invoice.id}>
                          <DatesChangesTableCell
                            background="var(--primaryBlack2)"
                            noBorderBottom={invoiceIndex === arr.length - 1}
                          >
                            <Counter>{invoiceIndex + 1}</Counter>
                            Invoice
                          </DatesChangesTableCell>
                          <DatesChangesTableCell noBorderBottom={invoiceIndex === arr.length - 1}>
                            <CustomDatePicker
                              renderCustomInput={({ inputRef }) => (
                                <InlineDate ref={inputRef}>
                                  {dayjs.utc(values?.updatedInvoices[invoiceIndex]?.date).format('MMM DD, YYYY')}
                                </InlineDate>
                              )}
                              toolTipWidth={200}
                              tooltipInputDisplay={
                                <>
                                  <div>Old value:</div>
                                  <div>{dayjs.utc(oldInvoices?.[invoiceIndex]?.date).format('MMM DD, YYYY')}</div>
                                </>
                              }
                              formik
                              name={`updatedInvoices.${invoiceIndex}.date`}
                              dateFormat="MMM dd, yyyy"
                              meta={getFieldMeta(`updatedInvoices.${invoiceIndex}.date`)}
                              selected={
                                values?.updatedInvoices[invoiceIndex]?.date
                                  ? formatDateForDatepicker(values?.updatedInvoices[invoiceIndex]?.date)
                                  : null
                              }
                              onChange={(name, val) => setFieldValue(name, updateDateFromDatePicker(val))}
                            />
                          </DatesChangesTableCell>
                          {doSomeInvoicesHaveAutoSend ? (
                            <DatesChangesTableCell noBorderBottom={invoiceIndex === arr.length - 1}>
                              <CustomDatePicker
                                renderCustomInput={({ inputRef }) => (
                                  <InlineDate ref={inputRef}>
                                    {dayjs.utc(values?.updatedInvoices[invoiceIndex]?.send_date).format('MMM DD, YYYY')}
                                  </InlineDate>
                                )}
                                toolTipWidth={200}
                                tooltipInputDisplay={
                                  <>
                                    <div>Old value:</div>
                                    <div>
                                      {dayjs.utc(oldInvoices?.[invoiceIndex]?.send_date).format('MMM DD, YYYY')}
                                    </div>
                                  </>
                                }
                                formik
                                name={`updatedInvoices.${invoiceIndex}.send_date`}
                                dateFormat="MMM dd, yyyy"
                                meta={getFieldMeta(`updatedInvoices.${invoiceIndex}.send_date`)}
                                selected={
                                  values?.updatedInvoices[invoiceIndex]?.send_date
                                    ? formatDateForDatepicker(values?.updatedInvoices[invoiceIndex]?.send_date)
                                    : null
                                }
                                onChange={(name, val) => setFieldValue(name, updateDateFromDatePicker(val))}
                              />
                            </DatesChangesTableCell>
                          ) : null}
                          <DatesChangesTableCell noBorderBottom={invoiceIndex === arr.length - 1}>
                            <CustomDatePicker
                              renderCustomInput={({ inputRef }) => (
                                <InlineDate ref={inputRef}>
                                  {dayjs
                                    .utc(values?.updatedInvoices[invoiceIndex]?.service_start)
                                    .format('MMM DD, YYYY')}
                                </InlineDate>
                              )}
                              toolTipWidth={200}
                              tooltipInputDisplay={
                                <>
                                  <div>Old value:</div>
                                  <div>
                                    {dayjs.utc(oldInvoices?.[invoiceIndex]?.service_start).format('MMM DD, YYYY')}
                                  </div>
                                </>
                              }
                              formik
                              name={`updatedInvoices.${invoiceIndex}.service_start`}
                              dateFormat="MMM dd, yyyy"
                              meta={getFieldMeta(`updatedInvoices.${invoiceIndex}.service_start`)}
                              selected={
                                values?.updatedInvoices[invoiceIndex]?.service_start
                                  ? formatDateForDatepicker(values?.updatedInvoices[invoiceIndex]?.service_start)
                                  : null
                              }
                              onChange={(name, val) => setFieldValue(name, updateDateFromDatePicker(val))}
                            />
                          </DatesChangesTableCell>
                          <DatesChangesTableCell noBorderRight noBorderBottom={invoiceIndex === arr.length - 1}>
                            <CustomDatePicker
                              renderCustomInput={({ inputRef }) => (
                                <InlineDate ref={inputRef}>
                                  {dayjs.utc(values?.updatedInvoices[invoiceIndex]?.service_end).format('MMM DD, YYYY')}
                                </InlineDate>
                              )}
                              formik
                              toolTipWidth={200}
                              tooltipInputDisplay={
                                <>
                                  <div>Old value:</div>
                                  <div>
                                    {dayjs.utc(oldInvoices?.[invoiceIndex]?.service_end).format('MMM DD, YYYY')}
                                  </div>
                                </>
                              }
                              name={`updatedInvoices.${invoiceIndex}.service_end`}
                              dateFormat="MMM dd, yyyy"
                              meta={getFieldMeta(`updatedInvoices.${invoiceIndex}.service_end`)}
                              selected={
                                values?.updatedInvoices[invoiceIndex]?.service_end
                                  ? formatDateForDatepicker(values?.updatedInvoices[invoiceIndex]?.service_end)
                                  : null
                              }
                              onChange={(name, val) => setFieldValue(name, updateDateFromDatePicker(val))}
                            />
                          </DatesChangesTableCell>
                        </Fragment>
                      ))}
                    </DatesChangesTableBase>
                  </DatesChangesTable>
                ) : null}
              </ModalBody>

              <ModalFooter
                noFixedHeight
                padding="12px 36px"
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
              >
                <FlexerRow alignItems="center">
                  <StyledSyncIcon width={16} height={16} />
                  <FooterInfoText>Subscript will update only draft unsent invoices</FooterInfoText>
                </FlexerRow>
                <FlexEndContainer>
                  <ModalButton data-cy="bulk-changes-modal__back" onClick={onBack}>
                    <LeftArrow width={16} height={16} style={{ marginRight: '8px' }} />
                    <b>Edit Settings</b>
                  </ModalButton>

                  <ModalButton data-cy="bulk-changes-modal__save" hideIcon primary onClick={handleSubmit}>
                    <b>Save invoices only in Subscript</b>
                    <CircleCheck style={{ marginLeft: '8px' }} width={16} height={16} />
                  </ModalButton>
                </FlexEndContainer>
              </ModalFooter>
            </>
          )}
        </Formik>
      </Modal>
    </ModalContainer>
  );
};

export const useBulkChangesModal = () => {
  const [updatedInvoices, setUpdatedInvoices] = useState();
  const [oldInvoices, setOldInvoices] = useState();
  const [changedFields, setChangedFields] = useState();
  const [doSomeInvoicesHaveAutoSend, setDoSomeInvoicesHaveAutoSend] = useState(false);
  const onCloseRef = React.useRef();

  const openModal = useCallback(
    ({ updatedInvoices, oldInvoices, changedFields, doSomeInvoicesHaveAutoSend, onClose }) => {
      setUpdatedInvoices(updatedInvoices);
      setOldInvoices(oldInvoices);
      setChangedFields(changedFields);
      setDoSomeInvoicesHaveAutoSend(doSomeInvoicesHaveAutoSend);
      onCloseRef.current = onClose;
    },
    [],
  );

  const closeModal = useCallback(() => {
    setUpdatedInvoices(null);
    setOldInvoices(null);
    setChangedFields(null);
  }, []);

  const Modal = useCallback(
    () =>
      updatedInvoices?.length > 0 ? (
        <BulkChangesModal
          doSomeInvoicesHaveAutoSend={doSomeInvoicesHaveAutoSend}
          updatedInvoices={updatedInvoices}
          oldInvoices={oldInvoices}
          changedFields={changedFields}
          onBack={closeModal}
          onClose={() => {
            closeModal();
            onCloseRef.current?.();
          }}
        />
      ) : null,
    [closeModal, onCloseRef, updatedInvoices, doSomeInvoicesHaveAutoSend, oldInvoices, changedFields],
  );

  return {
    openModal,
    Modal,
  };
};
