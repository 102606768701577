import React, { useCallback, useState } from 'react';
import dayjs from 'dayjs';
import { useHistory, useLocation } from 'react-router-dom';
import { cssVar } from 'polished';
import { Header, HeaderTitle } from 'shared/Layout';
import { TooltipContainer } from 'components/Tooltip';
import { GreyDot, SaveIcon } from 'components/Icons';
import { IconButton, LinkBackButton } from 'components/Buttons';
import { REPORT_OPTIONS_ROUTE, REPORTS_OPTIONS } from '../consts';
import { useReport } from '../hooks';
import { SaveReportButton } from '../SaveReportButton';
import { EditReportModal } from '../EditReportModal';
import { useNewReportModal } from '../NewReportModal';
import { HeaderLeftWrapper, StyledEditCircleIcon } from './styles';

const HeaderWithReports = ({ headerTitle, headerRight, report, page, pageState }) => {
  const { reportData, setReportData, pageReportState } = useReport({
    report,
    page,
    pageState,
  });

  const [showEditModal, setShowEditModal] = useState(false);
  const handleEditModalToggle = ({ updatedReport } = {}) => {
    if (updatedReport) {
      setReportData(updatedReport);
    }
    setShowEditModal(!showEditModal);
  };
  const handleSaveReport = ({ updatedReport }) => {
    if (updatedReport) {
      setReportData(updatedReport);
    }
  };

  return (
    <>
      <Header
        headerTitle={headerTitle}
        headerLeft={
          reportData && (
            <HeaderLeftWrapper onClick={handleEditModalToggle}>
              <LinkBackButton data-cy="page-header-back-button" to="/reports">
                Back
              </LinkBackButton>
              <TooltipContainer
                yOffset={10}
                toolTipContent={`Saved report for ${REPORTS_OPTIONS[page]} page updated at ${dayjs(
                  reportData.updated_at,
                ).format('DD MMM YYYY')}`}
              >
                <HeaderTitle>{reportData.title}</HeaderTitle>
              </TooltipContainer>
              <StyledEditCircleIcon />
            </HeaderLeftWrapper>
          )
        }
        headerRight={
          <>
            {reportData && (
              <>
                <SaveReportButton
                  report={reportData}
                  pageReportState={pageReportState}
                  onSubmitted={handleSaveReport}
                />
                <GreyDot spacing="16px" />
              </>
            )}
            {headerRight}
          </>
        }
      />
      {showEditModal && <EditReportModal report={reportData} onClose={handleEditModalToggle} />}
    </>
  );
};

const SaveNewReportButton = () => {
  const history = useHistory();

  const handleNavigateToReportPage = useCallback(
    ({ report, isNewReport = true }) => {
      const route = `${REPORT_OPTIONS_ROUTE[report.type]}?${new URLSearchParams(window.location.search).toString()}`;
      history.push(route, { report, isNewReport });
    },
    [history],
  );

  const { openNewReportModal, NewReportModal } = useNewReportModal({
    onSubmitted: handleNavigateToReportPage,
  });

  return (
    <>
      <TooltipContainer
        width={240}
        yOffset={10}
        toolTipContent="Save the page with the configured filters and get quick access to it"
      >
        <IconButton
          icon={<SaveIcon />}
          iconFillColor={cssVar('--primaryGreen')}
          onClick={openNewReportModal}
          data-cy="save-report-button--new"
        >
          Save
        </IconButton>
      </TooltipContainer>
      <NewReportModal />
    </>
  );
};

/**
 * We use HeaderReports for pages with saved report functionality
 * @param headerTitle
 * @param headerRight
 * @param page
 * @param pageState
 * @param rest
 * @returns {JSX.Element}
 * @constructor
 */
export const HeaderReports = ({ headerTitle, headerRight, page, pageState, ...rest }) => {
  const { state } = useLocation();

  return (
    <>
      {state?.report ? (
        <HeaderWithReports
          headerTitle={headerTitle}
          headerRight={headerRight}
          report={state?.report}
          page={page}
          pageState={pageState}
        />
      ) : (
        <Header
          headerTitle={headerTitle}
          headerRight={
            <>
              <SaveNewReportButton />
              <GreyDot spacing="16px" />
              {headerRight}
            </>
          }
          {...rest}
        />
      )}
    </>
  );
};
