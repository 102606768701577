import axios from 'axios';

if (process.env.NODE_ENV === 'development') {
  // Requests logger
  axios.interceptors.request.use((request) => {
    return request;
  });

  // Responses logger
  axios.interceptors.response.use((response) => {
    return response;
  });
}

// TODO: There is not much point to having these wrapper functions when
// we can just use axios directly - the wrappers do not add any extra functionality
export const postRequest = async (url, payload = {}, opts = {}) => {
  const { data } = await axios.post(url, payload, opts);
  return data;
};

export const getRequest = async (url, opts = {}) => {
  const { data } = await axios.get(url, opts);
  return data;
};

export const putRequest = async (url, payload = {}, opts = {}) => {
  const { data } = await axios.put(url, payload, opts);
  return data;
};

export const patchRequest = async (url, payload = {}, opts = {}) => {
  const { data } = await axios.patch(url, payload, opts);
  return data;
};
