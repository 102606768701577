import styled from 'styled-components';
import { CentererVertical } from 'components/Core';

export const StyledLink = styled.a`
  color: var(--primaryBlue);
  text-decoration: none;
  cursor: pointer;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  font-weight: ${({ isBold }) => (isBold ? 700 : 400)};
`;

export const CustomerField = styled(CentererVertical)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const EventName = styled.span`
  padding: 2px;
  border-radius: 4px;
  gap: 10px;
  background: var(--accentGraySecond);
  font-size: 11px;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0em;
  color: var(--dark7);
`;
