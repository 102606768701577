import { useContext } from 'react';
import { isNil } from 'lodash';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { AppContext } from 'AppContext';
import { ReactComponent as DownArrow } from 'images/small-fill-down-arrow.svg';
import { ReactComponent as UpArrow } from 'images/small-fill-up-arrow.svg';
import { CentererVertical } from 'components/Core';
import { TooltipContainer } from 'components/Tooltip';
import { ComparisonTooltipContent } from 'shared/Common';
import { NUMBER_FORMATS } from 'consts/global';
import { numberFormatter } from 'utils/formatters';
import {
  MetricColumn,
  SubValue,
  SubValueContainer,
  LargeNumber,
  QuarterLabel,
} from './ScenarioCardQuartersSummary.styles';
import { getQuarterLabel, getLastYearQuarterKey, getQuarterDescriptor } from './ScenarioCardQuartersSummary.utils';

dayjs.extend(utc);

export const ScenarioCardQuartersSummary = ({ scenarioMonths, endOfQuarters }) => {
  const {
    appSettings: { isARR, currencyISOCode: currency },
  } = useContext(AppContext);

  return Object.entries(endOfQuarters).map(([quarterKey, monthKey], i) => (
    <MetricColumn key={quarterKey}>
      <CentererVertical>
        <TooltipContainer
          isVisible={!isNil(scenarioMonths[monthKey]?.total) && !isNil(scenarioMonths[monthKey]?.last_year_total)}
          width={235}
          toolTipContent={
            <ComparisonTooltipContent
              originalValue={Number(scenarioMonths[monthKey]?.total)}
              compareValue={Number(scenarioMonths[monthKey]?.last_year_total)}
              originalLabel={`Forecasted ${getQuarterLabel({ quarterKey })}`}
              compareLabel={`Past ${isARR ? 'ARR' : 'MRR'} ${getQuarterLabel({
                quarterKey: getLastYearQuarterKey({ quarterKey }),
              })}`}
              decimalPlaces={0}
              colored={false}
            />
          }
        >
          <LargeNumber>
            {numberFormatter({ type: NUMBER_FORMATS.CURRENCY, rawValue: scenarioMonths[monthKey]?.total, currency })}
          </LargeNumber>
        </TooltipContainer>
      </CentererVertical>
      <SubValueContainer>
        <SubValue>{`${i === 0 ? 'Year over Year:' : ''} ${numberFormatter({
          type: NUMBER_FORMATS.PERCENT,
          rawValue: scenarioMonths[monthKey]?.yoy,
          decimalPlaces: 2,
          currency,
        })}`}</SubValue>
        {scenarioMonths[monthKey]?.yoy >= 1 ? (
          <UpArrow fill="var(--accentDarkGray)" />
        ) : (
          <DownArrow fill="var(--accentDarkGray)" />
        )}
      </SubValueContainer>
      <QuarterLabel>{`${getQuarterDescriptor({ quarterKey })} ${getQuarterLabel({
        quarterKey: monthKey,
      })}`}</QuarterLabel>
    </MetricColumn>
  ));
};
