import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export const getQuarterLabel = ({ quarterKey }) => `(${dayjs.utc(quarterKey).format('MMM YYYY')})`;
export const getLastYearQuarterKey = ({ quarterKey }) => dayjs.utc(quarterKey).subtract(1, 'year').format('YYYY-MM');

export const getQuarterDescriptor = ({ quarterKey }) => {
  const [year, quarter] = quarterKey.split('-');
  return quarter === 'Q4' ? `Fiscal ${year} End` : `${quarter} ${year}`;
};
