import { useMutation, useQuery, useQueryClient } from 'react-query';
import { createExport, getExports } from './requests';

const CACHE_KEY = 'getExports';

export const useExportsAPI = ({ orgId, type, autoFetch = true }) => {
  const queryClient = useQueryClient();

  const { data, error, isLoading, isFetching } = useQuery([CACHE_KEY, orgId, type], () => getExports({ orgId, type }), {
    enabled: autoFetch && !!orgId && !!type,
  });

  const mutationOptions = {
    onSuccess: () => queryClient.invalidateQueries([CACHE_KEY, orgId, type]),
  };

  const createExportMutation = useMutation(
    ({ orgId, type, customization }) => createExport({ orgId, type, customization }),
    mutationOptions,
  );

  return {
    data,
    error,
    isLoading,
    isFetching,
    operations: {
      createExport: createExportMutation.mutateAsync,
    },
  };
};
