import styled from 'styled-components';
import { Centerer, FlexBetweenContainer, Flexer, FlexerColumn } from 'components/Core';

export const HistoricalWrapper = styled(Centerer)`
  width: 100%;
  flex-direction: column-reverse;
`;

export const DividerWrapper = styled(Flexer)`
  flex-direction: column;
  align-items: center;
  margin: 8px;
`;

export const Line = styled.div`
  width: 1px;
  height: 12px;
  background: var(--primaryBlack50);
`;

export const Circle = styled.div`
  width: 8px;
  height: 8px;
  border: 1px solid var(--primaryBlack50);
  border-radius: 8px;
`;

export const HistoricalRowWrapper = styled(FlexerColumn)`
  align-items: center;
  width: 100%;
`;

export const HistoricalTotalRow = styled.div`
  text-align: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--primaryBlack50);
  margin: 0 8px;

  span {
    font-weight: 700;
  }
`;

export const StyledHistoricalSegmentCard = styled(Centerer)`
  flex-direction: column;
  background: #ffffff;
  width: 100%;
  border: 1px solid var(--accentGraySecond);
  box-shadow: 8px 8px 60px var(--primaryBlack5);
  border-radius: 16px;
  margin: 8px 0;
`;

export const HistoricalSegmentCardTitle = styled(FlexBetweenContainer)`
  width: 100%;
  padding: 16px 16px 12px 16px;
  border-bottom: 1px solid var(--accentGraySecond);
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;

  img {
    margin-right: 5px;
  }

  span {
    font-weight: 700;
    color: ${({ red }) => red && 'var(--primaryRed)'};
  }

  svg {
    width: 16px;
    height: 16px;

    path {
      fill: ${(props) => props.iconFillColor};
    }
  }
`;

export const HistoricalSegmentCardBody = styled.div`
  width: 100%;
  padding: 16px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
`;
