import axios from 'axios';
import { API_URL } from 'api/consts';

const buildUrl = ({ orgId, goalId, periodId, bulk }) => {
  const baseUrl = `${API_URL}/organizations/${orgId}/goals/${goalId}/periods`;
  if (bulk) return `${baseUrl}:bulk`;
  if (!periodId) return baseUrl;

  return `${baseUrl}/${periodId}`;
};

export const getPeriods = async ({ orgId, goalId }) => {
  const response = await axios.get(buildUrl({ orgId, goalId }));
  return response.data;
};

export const getPeriod = async ({ orgId, goalId, periodId }) => {
  const response = await axios.get(buildUrl({ orgId, goalId, periodId }));
  return response.data;
};

export const postPeriod = async ({ orgId, goalId, body }) => {
  const response = await axios.post(buildUrl({ orgId, goalId }), body);
  return response.data;
};

export const updatePeriod = async ({ orgId, goalId, periodId, body }) => {
  const response = await axios.patch(buildUrl({ orgId, goalId, periodId }), body);
  return response.data;
};

export const deletePeriod = async ({ orgId, goalId, periodId }) => {
  const response = await axios.delete(buildUrl({ orgId, goalId, periodId }));
  return response.data;
};

export const postPeriodsBulk = async ({ orgId, goalId, body }) => {
  const response = await axios.post(buildUrl({ orgId, goalId, bulk: true }), body);
  return response.data;
};

export const updatePeriodsBulk = async ({ orgId, goalId, body }) => {
  const response = await axios.put(buildUrl({ orgId, goalId, bulk: true }), body);
  return response.data;
};

export const deletePeriodsBulk = async ({ orgId, goalId }) => {
  const response = await axios.delete(buildUrl({ orgId, goalId, bulk: true }));
  return response.data;
};
