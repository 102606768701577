import axios from 'axios';
import { API_URL } from 'api/consts';

const buildUrl = ({ orgId }) => {
  const baseUrl = `${API_URL}/organizations/${orgId}/ledger_values`;
  return baseUrl;
};

export const getLedgerValues = async ({ orgId, month, key }) => {
  const { data } = await axios.get(buildUrl({ orgId }), { params: { month, key } });
  return data;
};

export const upsertLedgerValue = async ({ orgId, month, key, value }) => {
  const { data } = await axios.put(buildUrl({ orgId }), { month, key, value });
  return data;
};
