import { SNAPSHOT_METRICS } from 'consts/snapshotMetrics';

export const RETENTION_METRICS = new Set([
  SNAPSHOT_METRICS.GROSS_CUSTOMER_RETENTION.monthDataKey,
  SNAPSHOT_METRICS.GROSS_CUSTOMER_RETENTION_TTM.monthDataKey,
  SNAPSHOT_METRICS.NET_REVENUE_RETENTION.monthDataKey,
  SNAPSHOT_METRICS.NET_REVENUE_RETENTION_TTM.monthDataKey,
  SNAPSHOT_METRICS.GROSS_REVENUE_RETENTION.monthDataKey,
  SNAPSHOT_METRICS.GROSS_REVENUE_RETENTION_TTM.monthDataKey,
]);

export const METRICS_WITH_GROWTH = {
  [SNAPSHOT_METRICS.RECURRING_REVENUE.key]: SNAPSHOT_METRICS.RECURRING_PERIOD_GROWTH.monthDataKey,
  [SNAPSHOT_METRICS.TOTAL_PERIOD_REVENUE.key]: SNAPSHOT_METRICS.TOTAL_PERIOD_GROWTH.monthDataKey,
};

export const GROWTH_RATE_METRICS = [
  SNAPSHOT_METRICS.RECURRING_REVENUE.key,
  SNAPSHOT_METRICS.RECURRING_PERIOD_GROWTH.key,
  SNAPSHOT_METRICS.TOTAL_PERIOD_GROWTH.key,
];
