import { useCallback, useContext, useState } from 'react';
import styled from 'styled-components';
import { useAuth0 } from '@auth0/auth0-react';

import { AppContext } from 'AppContext';
import { BinIcon } from 'components/Icons';
import { Modal, ModalButton, ModalCloseIcon, ModalContainer, ModalFooter } from 'components/Modal';
import { Spacer, Column } from 'components/Core';
import { useUsersAPI } from 'api/users/hooks';

import { Wrapper, Header } from './styles';

const Banner = styled.div`
  background-color: var(--primaryRed5);
  border-radius: 8px;
  padding: 16px;

  display: flex;
`;

const GreyText = styled.span`
  color: var(--primaryBlack);
  opacity: 0.7;
  font-size: 14px;
  font-weight: 400;
`;

const BoldText = styled.span`
  font-weight: 900;
  font-size: 14px;
`;

const GreyBoldText = styled.span`
  color: var(--primaryBlack);
  opacity: 0.7;

  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
`;

export const MemberDeleteModal = ({ onClose, user }) => {
  const { orgId } = useContext(AppContext);

  const { user: thisUser } = useAuth0();
  const {
    operations: { removeUser },
  } = useUsersAPI({ autoFetch: false, orgId });

  return (
    <ModalContainer>
      <Modal
        overflow="visible"
        width="440px"
        minHeight="280px"
        data-cy="members-invite-modal"
        height="auto"
        maxHeight="auto"
      >
        <ModalCloseIcon onClose={onClose} />
        <Wrapper>
          <Header>Remove Member</Header>
          <GreyText> Do you really want to remove this </GreyText> <GreyBoldText> user?</GreyBoldText>
          <Spacer height="16px" />
          <Banner>
            <BinIcon size="20px" />
            <Spacer width="10px" />
            <Column horizontal="start">
              <BoldText>
                {user.first_name} {user.last_name}
              </BoldText>

              <GreyBoldText>{user.email}</GreyBoldText>
            </Column>
          </Banner>
          <Spacer height="30px" />
        </Wrapper>

        <ModalFooter flexEnd>
          <ModalButton default onClick={onClose}>
            Cancel
          </ModalButton>
          <ModalButton
            delete
            disabled={thisUser.email === user.email}
            onClick={async () => {
              await removeUser.mutateAsync({ userId: user.id });
              onClose();
            }}
            data-cy="members-delete-modal__remove"
          >
            Remove
          </ModalButton>
        </ModalFooter>
      </Modal>
    </ModalContainer>
  );
};

export const useMemberDeleteModal = () => {
  const [openParams, setOpenParams] = useState({});
  const [showModal, setShowModal] = useState(false);
  const openModal = useCallback(
    (openParams) => {
      setOpenParams(openParams);
      setShowModal(true);
    },
    [setShowModal],
  );

  const closeModal = useCallback(() => setShowModal(false), [setShowModal]);

  const Modal = useCallback(() => (showModal ? <MemberDeleteModal onClose={closeModal} {...openParams} /> : <></>), [
    closeModal,
    showModal,
    openParams,
  ]);

  return {
    openModal,
    MemberDeleteModal: Modal,
  };
};
