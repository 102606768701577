import styled from 'styled-components';
import { ReactComponent as RotateClockwiseIcon } from 'images/rotate-clockwise.svg';
import { ReactComponent as RotateClockwise2Icon } from 'images/rotate-clockwise-2.svg';
import { ReactComponent as ArrowsRightLeftIcon } from 'images/arrows-right-left.svg';
import { ReactComponent as CircleIcon } from 'images/circle-1.svg';
import { ReactComponent as StepIntoIcon } from 'images/step-into.svg';
import { ReactComponent as ArrowsSplit } from 'images/arrows-split-2.svg';
import { RECOGNITION_TYPES } from 'consts/global';

const LinearRecurringIcon = styled(RotateClockwiseIcon)`
  path {
    fill: var(--primaryPurple);
  }
`;

const TillCanceledIcon = styled(RotateClockwise2Icon)`
  path {
    fill: var(--primaryPurple);
  }
`;

const EventRecurringIcon = styled(ArrowsRightLeftIcon)`
  path {
    fill: var(--primaryPurple);
  }
`;

const ImmediateIcon = styled(CircleIcon)`
  path {
    fill: var(--primaryOrange);
  }
`;

const LinearNonRecurringIcon = styled(StepIntoIcon)`
  path {
    fill: var(--primaryOrange);
  }
`;

const EventNonRecurringIcon = styled(ArrowsSplit)`
  path {
    fill: var(--primaryOrange);
  }
`;

export const RecognitionIcon = ({ recognitionType, ...rest }) => {
  switch (recognitionType) {
    case RECOGNITION_TYPES.linear:
      return <LinearRecurringIcon {...rest} />;
    case RECOGNITION_TYPES.tillCanceled:
      return <TillCanceledIcon {...rest} />;
    case RECOGNITION_TYPES.eventRecurring:
      return <EventRecurringIcon {...rest} />;
    case RECOGNITION_TYPES.immediate:
      return <ImmediateIcon {...rest} />;
    case RECOGNITION_TYPES.linearNotRecurring:
      return <LinearNonRecurringIcon {...rest} />;
    case RECOGNITION_TYPES.eventNotRecurring:
      return <EventNonRecurringIcon {...rest} />;
    default:
      return <></>;
  }
};
