import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { BannersContainer, Banner, BannerText, BannerActions, BannerButton } from 'components/Blocks/Banner';
import { InsightsContext } from 'shared/Insights/InsightsContext';
import { YellowBold } from 'shared/Filters/styles';
import { EVENTS } from 'consts/analytics';
import { useAnalytics } from 'utils/hooks';

const Wrapper = styled.div`
  margin-top: 28px;
`;

export const InsightsBanner = () => {
  const { orgHasInsights } = useContext(InsightsContext);
  const { trackEvent } = useAnalytics();
  return orgHasInsights({ chartType: 'cohort' }) ? (
    <Wrapper>
      <Link to="/cohorts">
        <BannersContainer>
          <Banner tag="warning">
            <BannerText>
              Subscript found <YellowBold>insights</YellowBold> based on correlation of your financial data and
              segmentation! Click to explore them.
            </BannerText>
            <BannerActions>
              <BannerButton
                onClick={() =>
                  trackEvent({
                    name: EVENTS.CLICK_INSIGHTS_BANNER,
                  })
                }
              >
                Go to Cohorts
              </BannerButton>
            </BannerActions>
          </Banner>
        </BannersContainer>
      </Link>
    </Wrapper>
  ) : null;
};
