import { isEmpty } from 'lodash';

const INVOICE_ITEM_FIELD_DISPLAY = {
  seats: 'quantity',
};

export const showInvoiceItemChanges = (changes) => {
  if (isEmpty(changes)) return null;

  const { toDelete, toInsert, toUpdate } = changes;

  if (toDelete?.length) return `: delete ${toDelete?.length} invoice items`;
  if (toInsert?.length) return `: insert ${toInsert?.length} invoice items`;

  // toUpdate is in the format of { [invoiceItemId]: fieldsToChange }
  const fieldsToChange = Object.values(toUpdate ?? {})[0];
  if (isEmpty(fieldsToChange)) return null;

  let fieldName = Object.keys(fieldsToChange)[0];
  fieldName = INVOICE_ITEM_FIELD_DISPLAY[fieldName] ?? fieldName;

  const fieldValue = Object.values(fieldsToChange)[0];
  return ` update an invoice item's ${fieldName} to ${fieldValue}`;
};
