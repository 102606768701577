import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Form, Formik } from 'formik';
import { isEmpty } from 'lodash';

import { AppContext } from 'AppContext';
import {
  Modal,
  ModalBody,
  ModalButton,
  ModalCloseIcon,
  ModalContainer,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from 'components/Modal';
import { getIntegrationDisplayName, getServiceCategory } from 'models/integration';
import { INTEGRATIONS_OPERATIONS } from 'consts/integrations';
import { useIntegrationsAPI } from 'api/integrations';
import { Spacer } from 'components/Core';
import { formatBillingAddress, formatShippingAddress } from 'models/customer';
import { useCustomersAPI, useExternalCustomerAPI, useExternalParentChildrenAPI } from 'api/customers/hooks';

import { CustomerAddressInformation } from './CustomerAddressInformation';
import { useConfirmChangesModal } from '../useConfirmChangesModal';

const SaveButton = ({ values, initialValues, onSave, serviceDisplayName, setChildrenCustomers }) => {
  const { orgId } = useContext(AppContext);

  const formattedBillingAddress = formatBillingAddress({ customer: values });
  const initialFormattedBillingAddress = formatBillingAddress({ customer: initialValues });
  const formattedShippingAddress = formatShippingAddress({ customer: values });
  const initialFormattedShippingAddress = formatShippingAddress({ customer: initialValues });

  const { data: childrenCustomers } = useExternalParentChildrenAPI({
    orgId,
    externalParentId: values.id,
    scopes: ['invoicing_details'],
  });

  const {
    openModal: openConfirmChangesModal,
    closeModal: closeConfirmChangesModal,
    Modal: ConfirmChangesModal,
  } = useConfirmChangesModal({
    newData: {
      billing: formattedBillingAddress,
      shipping: formattedShippingAddress || formattedBillingAddress,
      contacts: values?.invoicing_details?.contacts ?? [],
    },
    oldData: {
      billing: initialFormattedBillingAddress,
      shipping: initialFormattedShippingAddress || initialFormattedBillingAddress,
      contacts: initialValues?.invoicing_details?.contacts ?? [],
    },

    customerName: values?.name,
    childrenCustomers,
    serviceName: serviceDisplayName,

    onSave: (params) => {
      closeConfirmChangesModal();
      onSave(params);
    },
  });

  useEffect(() => {
    if (childrenCustomers) {
      setChildrenCustomers(childrenCustomers);
    }
  }, [childrenCustomers, setChildrenCustomers]);

  return (
    <>
      <ModalButton
        primary
        onClick={openConfirmChangesModal}
        data-cy="edit-external-parent-modal__confirm-button"
        fontWeight={700}
      >
        Save
      </ModalButton>

      <ConfirmChangesModal />
    </>
  );
};

export const EditExternalParentModal = ({
  onClose,
  customer,
  selectedIntegration,
  refetchCustomer,
  updateChildCustomer,
}) => {
  const { orgId } = useContext(AppContext);

  const shouldUpdateChildrenRef = useRef({});

  const [childrenCustomers, setChildrenCustomers] = useState([]);

  const {
    operations: { bulkUpdateInvoicingDetails },
  } = useCustomersAPI({ orgId, autoFetch: false });

  const {
    operations: { bulkUpdateExternalCustomers },
  } = useExternalCustomerAPI({ orgId, autoFetch: false });

  const {
    operations: { runOnExternalService },
  } = useIntegrationsAPI({ orgId, autoFetch: false });

  const serviceDisplayName = getIntegrationDisplayName(selectedIntegration);

  const onSubmit = useCallback(
    async (formValues) => {
      if (selectedIntegration && formValues.id) {
        const { external_parent_customer_name, ...invoicing_details } = formValues.invoicing_details;

        const {
          shouldUpdateChildrenBilling,
          shouldUpdateChildrenEmails,
          shouldUpdateChildrenShipping,
        } = shouldUpdateChildrenRef.current;
        const billingData = {
          country: invoicing_details.country ?? '',
          city: invoicing_details.city ?? '',
          state: invoicing_details.state ?? '',
          postalcode: invoicing_details.postalcode ?? '',
          addressLine1: invoicing_details.addressLine1 ?? '',
          addressLine2: invoicing_details.addressLine2 ?? '',
        };
        const shippingData = invoicing_details.shipping_address
          ? {
              country: invoicing_details.shipping_address.country ?? '',
              city: invoicing_details.shipping_address.city ?? '',
              state: invoicing_details.shipping_address.state ?? '',
              postalcode: invoicing_details.shipping_address.postalcode ?? '',
              addressLine1: invoicing_details.shipping_address.addressLine1 ?? '',
              addressLine2: invoicing_details.shipping_address.addressLine2 ?? '',
            }
          : billingData;
        const updateInvoicingDetailsForChildren = {
          ...(shouldUpdateChildrenBilling && billingData),
          ...(shouldUpdateChildrenEmails && {
            contacts: invoicing_details.contacts,
          }),
          ...(shouldUpdateChildrenShipping && {
            shipping_address: shippingData,
          }),
        };

        // we first save the current customer
        const updatedCurrentCustomer = await updateChildCustomer();

        // update the parent
        await runOnExternalService.mutateAsync({
          integrationService: getServiceCategory(selectedIntegration.service),
          operation: INTEGRATIONS_OPERATIONS.UPDATE_CUSTOMER,
          integrationId: selectedIntegration.id,
          data: {
            id: formValues.id,
            externalParentCustomer: {
              name: external_parent_customer_name,
              invoicing_details,
            },
          },
        });

        if (!isEmpty(updateInvoicingDetailsForChildren)) {
          // update the children invoicing details in Subscript
          await bulkUpdateInvoicingDetails({
            customers: childrenCustomers.map((customer) => ({
              ...customer,
              invoicing_details: updateInvoicingDetailsForChildren,
            })),
          });

          // update the children in quickbooks
          await bulkUpdateExternalCustomers({
            customers: childrenCustomers.map((customer) => ({
              ...customer,
              invoicing_details: {
                ...(customer.id === updatedCurrentCustomer.id ? updatedCurrentCustomer : customer)?.invoicing_details,
                ...updateInvoicingDetailsForChildren,
              },
            })),
            integrationId: selectedIntegration.id,
          });
        }

        shouldUpdateChildrenRef.current = {};
        refetchCustomer();
      }
      onClose();
    },
    [
      bulkUpdateExternalCustomers,
      bulkUpdateInvoicingDetails,
      childrenCustomers,
      onClose,
      refetchCustomer,
      runOnExternalService,
      selectedIntegration,
      updateChildCustomer,
    ],
  );

  return (
    <ModalContainer>
      <Modal compact maxWidth="940px" height="auto" maxHeight="auto" overflow="auto">
        <ModalHeader>
          <ModalCloseIcon data-cy="edit-external-parent-modal__close-button" onClose={onClose} />
          <ModalTitle>
            <b>Edit </b>
            {customer.name}
          </ModalTitle>
        </ModalHeader>

        <Spacer height="20px" />

        <Formik initialValues={customer} onSubmit={onSubmit} enableReinitialize>
          {({ values, setFieldValue, handleSubmit }) => (
            <Form>
              <ModalBody paddingBottom="20px">
                <CustomerAddressInformation
                  values={values}
                  setFieldValue={setFieldValue}
                  selectedIntegration={selectedIntegration?.service}
                />
              </ModalBody>

              <ModalFooter flexEnd>
                <ModalButton
                  default
                  onClick={onClose}
                  data-cy="edit-external-parent-modal__cancel-button"
                  fontWeight={700}
                >
                  Cancel
                </ModalButton>

                <SaveButton
                  values={values}
                  initialValues={customer}
                  serviceDisplayName={serviceDisplayName}
                  setChildrenCustomers={setChildrenCustomers}
                  onSave={({
                    shouldUpdateChildrenBilling,
                    shouldUpdateChildrenEmails,
                    shouldUpdateChildrenShipping,
                  }) => {
                    shouldUpdateChildrenRef.current = {
                      shouldUpdateChildrenBilling,
                      shouldUpdateChildrenEmails,
                      shouldUpdateChildrenShipping,
                    };
                    handleSubmit();
                  }}
                />
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>
    </ModalContainer>
  );
};
