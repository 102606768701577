import { useCallback, useContext } from 'react';

import { AppContext } from 'AppContext';
import { numberFormatter } from 'utils/formatters';

// currency can be provided to override AppContext currency
export const useCurrencyNumberFormatter = ({ currency, language = null } = {}) => {
  const {
    appSettings: { currencyISOCode },
  } = useContext(AppContext);

  const formatter = useCallback(
    ({ type, rawValue, overrideCurrency, decimalPlaces = 0, addSign = false }) => {
      return numberFormatter({
        type,
        rawValue,
        decimalPlaces,
        addSign,
        currency: overrideCurrency || currency || currencyISOCode,
        language,
      });
    },
    [currency, currencyISOCode, language],
  );

  return formatter;
};
