import styled from 'styled-components';
import { ReactComponent as LinkIcon } from 'images/link.svg';

export const Wrapper = styled.div`
  position: relative;
`;

export const Heading = styled.h2`
  margin-bottom: 0;
  font-weight: 900;
  white-space: nowrap;
  font-size: 20px;
  margin-bottom: 8px;
  line-height: 26px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  color: var(--primaryBlack50);
  padding: 20px 32px;
  border-bottom: 1px solid var(--neutralGray);
`;

export const SelectorWrapper = styled.div`
  position: relative;
  margin-left: ${({ hasValue }) => (hasValue ? 0 : '12px')};
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  cursor: pointer;

  .react-select__single-value--is-disabled {
    max-width: 250px;
    color: var(--primaryBlack);
  }
`;

export const LinksWrapper = styled.div`
  width: 100%;
  padding-bottom: 4px;
`;

export const PaddingWrapper = styled.div`
  padding: 0 32px;
  width: 100%;
`;

export const DeleteIconWrapper = styled.div`
  display: flex;
  width: 36px;
  height: 36px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  cursor: pointer;

  &:hover {
    border: 1px solid var(--accentGraySecond);
    box-shadow: 2px 2px 12px 0px var(--primaryBlack2);
    background: white;

    svg path {
      fill: var(--secondaryRed);
    }
  }
`;

export const InvoicingScheduleName = styled.span`
  font-size: 20px;
  line-height: 26px;
  font-weight: 400;
`;

export const StyledLinkIcon = styled(LinkIcon)`
  width: 16px;
  height: 16px;
  margin-left: 8px;

  path {
    fill: var(--primaryBlue);
  }

  &:hover {
    path {
      fill: var(--secondaryBlue);
    }
  }
`;
