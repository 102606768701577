import { useContext, useMemo } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { useHistory } from 'react-router-dom';
import { AppContext } from 'AppContext';
import { Column, Row } from 'components/Core';
import { CircleLoader } from 'components/Loaders';
import { ReactComponent as WalletIcon } from 'images/wallet.svg';
import { DropdownButton, DropdownButtonItem } from 'components/Buttons';
import { NUMBER_FORMATS } from 'consts/global';
import { INVOICE_STATUSES } from 'views/Billing/consts';
import { useStateWithStorage } from 'utils/hooks';
import { numberFormatter } from 'utils/formatters';
import { useInvoicesStatusCountAPI } from 'api/billing';
import {
  Widget,
  DropdownTitle,
  DropdownRow,
  LoadingSpinnerWrapper,
  ActivityItem,
  ActivityItemTitle,
  CounterInfoSubtitle,
  StyledArrowRight,
} from './styles';
import { DASHBOARD_PERIOD_OPTIONS } from './consts';
import { onSelectDashboardWidgetPeriod } from './utils';

const StyledWalletIcon = styled(WalletIcon)`
  width: 16px;
  height: 16px;
  margin-right: 6px;

  g {
    opacity: 1;
  }

  path {
    fill: var(--darkGreen);
  }
`;

const PaidAmount = styled.div`
  font-size: 10px;
  font-style: normal;
  font-weight: 900;
  line-height: 12px;
  text-transform: uppercase;
  color: white;
  padding: 4px 8px;
  border-radius: 100px;
  background-color: var(--darkGreen);
`;

const GrayCircle = styled.div`
  width: 8px;
  height: 8px;
  margin-top: 4px;
  margin-left: 4px;
  margin-right: 12px;
  border: 2px solid var(--primaryBlack10);
  border-radius: 100px;
`;

export const RecentActivityWidget = ({ hasAutoCharge }) => {
  const {
    orgId,
    appSettings: { currencyISOCode: currency },
  } = useContext(AppContext);

  const history = useHistory();
  const [selectedPeriod, setSelectedPeriod] = useStateWithStorage(
    'billing-dashboard__recent-activity-widget__period',
    DASHBOARD_PERIOD_OPTIONS.thisWeek,
  );

  const { startMonth, endMonth } = useMemo(() => onSelectDashboardWidgetPeriod({ period: selectedPeriod }), [
    selectedPeriod,
  ]);

  const { data: invoicesStatusCount, isLoading } = useInvoicesStatusCountAPI({
    orgId,
    params: {
      includeTotalAmounts: true,
      filterByExecutionDate: true,
      startDate: startMonth,
      endDate: endMonth,
    },
  });

  const invoicesByStatusCount = useMemo(
    () =>
      invoicesStatusCount?.reduce(
        (acc, { invoice_status, count, total_amount }) => ({
          ...acc,
          [invoice_status]: {
            ...acc[invoice_status],
            count: parseInt(count ?? 0),
            amount: parseInt(total_amount ?? 0),
          },
        }),
        {},
      ) ?? {},
    [invoicesStatusCount],
  );

  return (
    <Widget style={{ padding: 0, paddingTop: 20, maxWidth: 373 }}>
      <Row horizontal="space-between" style={{ width: '100%', padding: '0px 20px' }}>
        <DropdownRow>
          <DropdownTitle>Recent activity:</DropdownTitle>
          <DropdownButton
            noBorder
            buttonPadding="0"
            popoverYOffset="25"
            text={selectedPeriod}
            buttonFontWeight="700"
            color="var(--primaryBlack)"
            data-cy="billing-dashboard__failed-invoices-widget__dropdown"
          >
            {Object.keys(DASHBOARD_PERIOD_OPTIONS).map((option) => (
              <DropdownButtonItem
                name={option}
                key={option}
                onClick={() => setSelectedPeriod(DASHBOARD_PERIOD_OPTIONS[option])}
              >
                {DASHBOARD_PERIOD_OPTIONS[option]}
              </DropdownButtonItem>
            ))}
          </DropdownButton>
        </DropdownRow>
      </Row>

      <>
        {!!isLoading ? (
          <LoadingSpinnerWrapper data-cy="loader">
            <CircleLoader width="35px" height="35px" isAbsolute isInline={true} />
          </LoadingSpinnerWrapper>
        ) : (
          <Column vertical="top" horizontal="normal">
            {/* Recently Paid */}
            <ActivityItem
              onClick={() => {
                history.push('/billing/invoices', {
                  status: INVOICE_STATUSES.PAID,
                  startMonth,
                  endMonth,
                });
              }}
            >
              <Row horizontal="space-between" style={{ marginBottom: 8 }}>
                <Row horizontal="start">
                  <StyledWalletIcon />
                  <PaidAmount>
                    {numberFormatter({
                      type: NUMBER_FORMATS.CURRENCY,
                      rawValue: invoicesByStatusCount?.[INVOICE_STATUSES.PAID]?.amount ?? 0,
                      currency,
                    })}
                  </PaidAmount>
                </Row>

                <StyledArrowRight />
              </Row>

              <div style={{ paddingLeft: 24 }}>
                <ActivityItemTitle>
                  {invoicesByStatusCount?.[INVOICE_STATUSES.PAID]?.count ?? 0} recently{' '}
                  <span style={{ color: 'var(--darkGreen)' }}>
                    paid {invoicesByStatusCount?.[INVOICE_STATUSES.PAID]?.count > 1 ? 'invoices' : 'invoice'}
                  </span>
                </ActivityItemTitle>
                <CounterInfoSubtitle>
                  {selectedPeriod === DASHBOARD_PERIOD_OPTIONS.today
                    ? dayjs(endMonth).format('MMM D, YYYY')
                    : `${dayjs(startMonth).format('MMM D, YYYY')} - ${dayjs(endMonth).format('MMM D, YYYY')}`}
                </CounterInfoSubtitle>
              </div>
            </ActivityItem>

            {/* Recently Sent */}
            <ActivityItem
              onClick={() => {
                history.push('/billing/invoices', {
                  status: INVOICE_STATUSES.SENT,
                  startMonth,
                  endMonth,
                });
              }}
            >
              <Row horizontal="space-between">
                <Row horizontal="start" vertical="start">
                  <GrayCircle />

                  <div>
                    <ActivityItemTitle>
                      {invoicesByStatusCount?.[INVOICE_STATUSES.SENT]?.count ?? 0} recently{' '}
                      <span style={{ color: 'var(--primaryBlack50)' }}>
                        sent {invoicesByStatusCount?.[INVOICE_STATUSES.SENT]?.count > 1 ? 'invoices' : 'invoice'}
                      </span>
                    </ActivityItemTitle>
                    <CounterInfoSubtitle>
                      {selectedPeriod === DASHBOARD_PERIOD_OPTIONS.today
                        ? dayjs(endMonth).format('MMM D, YYYY')
                        : `${dayjs(startMonth).format('MMM D, YYYY')} - ${dayjs(endMonth).format('MMM D, YYYY')}`}
                    </CounterInfoSubtitle>
                  </div>
                </Row>

                <StyledArrowRight />
              </Row>
            </ActivityItem>

            {hasAutoCharge ? (
              <ActivityItem
                onClick={() => {
                  history.push('/billing/invoices', {
                    status: INVOICE_STATUSES.PAID,
                    startMonth,
                    endMonth,
                  });
                }}
              >
                <Row horizontal="space-between">
                  <Row horizontal="start" vertical="start">
                    <GrayCircle />

                    <div>
                      <ActivityItemTitle>
                        {invoicesByStatusCount?.[INVOICE_STATUSES.AUTO_CHARGE]?.count ?? 0} recently{' '}
                        <span style={{ color: 'var(--primaryBlack50)' }}>
                          {invoicesByStatusCount?.[INVOICE_STATUSES.AUTO_CHARGE]?.count > 1 ? 'invoices' : 'invoice'}{' '}
                          auto charged
                        </span>
                      </ActivityItemTitle>
                      <CounterInfoSubtitle>
                        {selectedPeriod === DASHBOARD_PERIOD_OPTIONS.today
                          ? dayjs(endMonth).format('MMM D, YYYY')
                          : `${dayjs(startMonth).format('MMM D, YYYY')} - ${dayjs(endMonth).format('MMM D, YYYY')}`}
                      </CounterInfoSubtitle>
                    </div>
                  </Row>

                  <StyledArrowRight />
                </Row>
              </ActivityItem>
            ) : null}
          </Column>
        )}
      </>
    </Widget>
  );
};
