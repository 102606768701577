import styled from 'styled-components';
import { Centerer, Flexer, FlexerColumn } from 'components/Core';
import { DismissButton, ToastContainer, ToastText } from 'components/Toasts/styles';
import { ReactComponent as CheckmarkIcon } from 'images/active-check.svg';
import { ReactComponent as BackUpIcon } from 'images/arrow-back-up.svg';

const MAXIMUM_ERROR_MESSAGES_SHOWN = 25;

const Container = styled(ToastContainer)`
  justify-content: space-between;
  align-items: flex-start;
  gap: 16px;
`;

const Button = styled(Centerer)`
  cursor: pointer;
  background-color: ${({ backgroundColor }) => backgroundColor ?? 'var(--secondaryGreen)'};
  padding: 8px;
  border-radius: 8px;
  gap: 4px;

  span {
    color: white;
    font-size: 14px;
    font-weight: 700;
  }
`;

const StyledCheckmarkIcon = styled(CheckmarkIcon)`
  cursor: pointer;
`;

export const SuccessToastWithUndo = ({
  message,
  type = 'success',
  errorMessages = [],
  onAction,
  onDismiss,
  dataCyLabel,
  dismissible = false,
}) => (
  <Container type={type}>
    <Flexer>
      <StyledCheckmarkIcon onClick={onDismiss} width="26px" height="26px" />
      <FlexerColumn>
        <ToastText>{message}</ToastText>
        {errorMessages.slice(0, MAXIMUM_ERROR_MESSAGES_SHOWN).map((errorMessage) => (
          <ToastText>&bull; {errorMessage}</ToastText>
        ))}
      </FlexerColumn>
    </Flexer>
    <Button
      data-cy={`${dataCyLabel}__success-toast--undo`}
      onClick={async () => {
        await onAction();
        onDismiss();
      }}
      backgroundColor={type === 'success' ? 'var(--secondaryGreen)' : 'var(--secondaryRed)'}
    >
      <BackUpIcon />
      <span>Undo</span>
    </Button>
    {dismissible && <DismissButton onClick={onDismiss}>Dismiss</DismissButton>}
  </Container>
);

export const DetailedFailureToast = ({ message, errorMessages = [], onDismiss, dismissible = false }) => (
  <Container type="error">
    <FlexerColumn>
      <ToastText>{message}</ToastText>
      {errorMessages.slice(0, MAXIMUM_ERROR_MESSAGES_SHOWN).map((errorMessage) => (
        <ToastText>&bull; {errorMessage}</ToastText>
      ))}
    </FlexerColumn>
    {dismissible && <DismissButton onClick={onDismiss}>Dismiss</DismissButton>}
  </Container>
);
