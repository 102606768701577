import React, { useContext } from 'react';
import styled from 'styled-components';
import { useFormikContext } from 'formik';

import { AppContext } from 'AppContext';
import { DEFAULT_AVAILABLE_TAGS } from 'consts/billing';
import { templateToHTMLString } from 'utils/htmlUtils';
import { TrashIcon, RefreshIcon } from 'components/Icons';
import {
  FILE_TYPES,
  FileUploader,
  FormikCustomInput,
  FormikEmailSelector,
  SwitchWithLabel,
  formatFileSize,
} from 'components/Controls';
import { CentererVertical, Flexer, FlexerColumn, FlexerRow, LabelText } from 'components/Core';
import { TooltipContainer } from 'components/Tooltip';
import { CircleLoader } from 'components/Loaders';
import { FileCard } from 'views/Configuration/Tabs/SubscriptBillingTab/styles';
import { ReactComponent as FileDownloadIcon } from 'images/file-download.svg';
import { useInvoicePdfAPI } from 'api/billing';

import { BillingDynamicFieldsTooltip, DYNAMIC_FIELD_TOOLTIP_SECTIONS } from '../BillingDynamicFieldsTooltip';
import { invoicePDFDownload } from '../InvoicePreviewModalV2/utils';
import { InvoiceSenderEmail } from '../InvoicePreviewModalV2/InvoiceSenderEmail';
import { InvoicingScheduleContext } from '../InvoicingScheduleModal/InvoicingScheduleContext';

const FadedText = styled(Flexer)`
  color: var(--primaryBlack50);
  font-weight: 700;
  font-size: 12px;
  gap: 4px;
  align-items: center;
`;

const HighlightedText = styled(FadedText)`
  color: var(--primaryBlue);
  align-items: center;

  &:hover {
    cursor: pointer;
    &:hover {
      svg path {
        fill: var(--primaryBlue);
      }
    }
  }
`;

const StyledFileDownloadIcon = styled(FileDownloadIcon)``;

const StyledRefreshIcon = styled(RefreshIcon)`
  width: 12px;
  height: 12px;

  path {
    fill: var(--primaryBlue);
  }
`;

export const ReceiptDetailsForm = ({ invoicePdfLink }) => {
  const { orgConfigs, orgId } = useContext(AppContext);
  const { invoiceFormValues } = useContext(InvoicingScheduleContext);

  const { billingReceiptDefaults, billingSenderDefaults } = orgConfigs;

  const { setFieldValue, values } = useFormikContext();

  const { data: _invoicePdf, isLoading: isPdfLoading } = useInvoicePdfAPI({
    orgId,
    invoiceId: values.invoice_id,
    autoFetch: !invoicePdfLink,
  });

  const invoicePdf = invoicePdfLink
    ? {
        is_pdf_link: true,
        pdf: invoicePdfLink,
      }
    : _invoicePdf;

  const handleAddInvoiceAttachment = (file) => {
    setFieldValue('attachments', [
      ...(values.attachments ?? []),
      {
        name: file.name,
        s3_file_key: file.s3FileKey,
        size: file.size,
        type: file.type,
      },
    ]);
  };
  const handleDeleteInvoiceAttachment = (index) => {
    const attachments = [...(values.attachments ?? [])];
    attachments.splice(index, 1);
    setFieldValue('attachments', attachments);
  };

  const resetReceiptRecipients = () => {
    setFieldValue('send_to', invoiceFormValues?.email_addresses);
  };

  const resetReceiptCCs = () => {
    setFieldValue('send_cc', billingReceiptDefaults.send_cc);
  };

  const resetReceiptEmailSubject = () => {
    setFieldValue('email_subject', billingReceiptDefaults.email_subject);
  };

  const resetReceiptEmailBody = () => {
    setFieldValue('email_body', billingReceiptDefaults.email_body);
  };

  return (
    <FlexerColumn width="100%" height="100%" gap="16px" alignItems="flex-start">
      <FlexerRow width="100%" gap="16px">
        <FormikEmailSelector
          containerWidth="100%"
          name="send_to"
          label="Recipient email(s)"
          value={values.send_to?.filter((e) => typeof e === 'string')} // Strip out any non-string values
          onChange={(selectedValues) => setFieldValue('send_to', selectedValues)}
          isDisabled={!!values.sent_at}
          labelAction={
            <TooltipContainer toolTipContent="Reset recipients to invoice recipients" width={200}>
              <StyledRefreshIcon onClick={resetReceiptRecipients} />
            </TooltipContainer>
          }
        />
      </FlexerRow>

      <FlexerRow width="100%" gap="16px">
        <FormikEmailSelector
          containerWidth="100%"
          name="send_cc"
          label="Email(s) to cc"
          value={values.send_cc}
          onChange={(selectedValues) => setFieldValue('send_cc', selectedValues)}
          isDisabled={!!values.sent_at}
          labelAction={
            <TooltipContainer toolTipContent="Reset cc emails to default" width={200}>
              <StyledRefreshIcon onClick={resetReceiptCCs} />
            </TooltipContainer>
          }
        />
      </FlexerRow>

      <FlexerRow width="100%" gap="16px">
        <FormikCustomInput
          width="100%"
          name="email_subject"
          label="Email subject"
          LabelAction={
            !values.sent_at && (
              <CentererVertical gap="8px">
                <TooltipContainer toolTipContent="Reset email subject to default" width={200}>
                  <RefreshIcon onClick={resetReceiptEmailSubject} />
                </TooltipContainer>
                <BillingDynamicFieldsTooltip
                  tags={{ [DYNAMIC_FIELD_TOOLTIP_SECTIONS.GENERAL_TAGS]: DEFAULT_AVAILABLE_TAGS }}
                  XOffset={200}
                />
              </CentererVertical>
            )
          }
          minHeight="40px"
          HTMLString={templateToHTMLString({
            text: values.email_subject,
            availableTags: DEFAULT_AVAILABLE_TAGS,
          })}
          availableTags={DEFAULT_AVAILABLE_TAGS}
          type="HTMLEdit"
          isDisabled={!!values.sent_at}
          data-cy="billing__receipt-modal__email-subject--input"
        />
      </FlexerRow>

      <FlexerRow width="100%" gap="16px">
        <FlexerColumn width="100%" height="100%" gap="16px">
          <FormikCustomInput
            name="email_body"
            label="Email body"
            LabelAction={
              !values.sent_at && (
                <CentererVertical gap="8px">
                  <TooltipContainer toolTipContent="Reset email body to default" width={200}>
                    <RefreshIcon onClick={resetReceiptEmailBody} />
                  </TooltipContainer>
                  <BillingDynamicFieldsTooltip
                    tags={{ [DYNAMIC_FIELD_TOOLTIP_SECTIONS.GENERAL_TAGS]: DEFAULT_AVAILABLE_TAGS }}
                  />
                </CentererVertical>
              )
            }
            minHeight="240px"
            HTMLString={templateToHTMLString({
              text: values.email_body,
              availableTags: DEFAULT_AVAILABLE_TAGS,
            })}
            availableTags={DEFAULT_AVAILABLE_TAGS}
            type="HTMLEdit"
            isDisabled={!!values.sent_at}
            data-cy="billing__receipt-modal__email-body--input"
          />
        </FlexerColumn>
        <FlexerColumn width="320px" gap="16px">
          <InvoiceSenderEmail senderEmail={billingSenderDefaults.email_from} />

          <FlexerColumn>
            <LabelText>
              Attachments ({(values.metadata?.exclude_invoice_pdf ? 0 : 1) + (values.attachments?.length ?? 0)})
            </LabelText>
            <FlexerColumn gap="8px">
              {!values.sent_at && (
                <FileUploader
                  onFileUpload={handleAddInvoiceAttachment}
                  fileTypes={FILE_TYPES.ALL}
                  maxFileSize={10485760}
                  smallView
                  infoText={`Attach files (${formatFileSize(10485760)})`}
                  subInfoText="click or drag&drop"
                  showFileUploaded={false}
                  privateBucket
                />
              )}

              <FileCard disableIconHover>
                {isPdfLoading ? (
                  <CircleLoader width="16px" height="16px" />
                ) : (
                  <>
                    <FlexerColumn>
                      <FadedText>
                        Attach invoice{' '}
                        <HighlightedText
                          onClick={() => invoicePdf?.pdf && invoicePDFDownload({ invoicePDF: invoicePdf })}
                        >
                          PDF <StyledFileDownloadIcon />
                        </HighlightedText>{' '}
                      </FadedText>
                    </FlexerColumn>

                    <SwitchWithLabel
                      checked={!values.metadata?.exclude_invoice_pdf}
                      onChange={() =>
                        setFieldValue('metadata', {
                          ...values.metadata,
                          exclude_invoice_pdf: !values.metadata?.exclude_invoice_pdf,
                        })
                      }
                    />
                  </>
                )}

                {values.attachments?.map((attachment, index) => (
                  <FileCard key={attachment.name}>
                    <span>
                      {attachment.name} {`(${formatFileSize(attachment.size)})`}
                    </span>
                    {!values.sent_at && (
                      <TrashIcon
                        size="16px"
                        fill="var(--primaryBlack)"
                        onClick={() => handleDeleteInvoiceAttachment(index)}
                      />
                    )}
                  </FileCard>
                ))}
              </FileCard>
            </FlexerColumn>
          </FlexerColumn>
        </FlexerColumn>
      </FlexerRow>
    </FlexerColumn>
  );
};
