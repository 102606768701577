export const selectStyles = ({
  pillStyle = false,
  isChanged,
  isDisabled,
  width,
  height,
  minWidth,
  customBorderRadius,
  blueVer,
  greyVer,
  menuWidth,
  boldValue,
  isError,
  redVer = false,
  isMulti = false,
  hasWarning,
  fullWidth = false,
  menuZIndex,
}) => {
  if (pillStyle) {
    return {
      menuPortal: (base) => ({ ...base, zIndex: menuZIndex ?? 9999 }),
      control: (styles) => ({
        ...styles,
        width,
        margin: '6px 8px',
        border: '1px solid var(--accentGraySecond)',
        borderRadius: '100px',
      }),
      container: (styles) => ({
        ...styles,
        padding: '0px 8px',
      }),
      indicatorSeparator: (styles) => ({ ...styles, display: 'none' }),
      placeholder: (styles) => ({
        ...styles,
        fontWeight: 'bold',
        fontSize: '12px',
        lineHeight: '16px',
        opacity: 0.2,
      }),
      singleValue: (styles) => ({
        ...styles,
        fontWeight: 'bold',
        fontSize: '12px',
        lineHeight: '16px',
        color: blueVer && 'var(--primaryBlue)',
        width: fullWidth && 'calc(100% - 10px)',
      }),
      option: (styles, state) => ({
        ...styles,
        color: state.isSelected && greyVer ? 'var(--primaryBlack)' : styles.color,
        background: state.isSelected && greyVer ? 'var(--primaryBlack10)' : styles.background,
        '&:hover': {
          background: !isDisabled && !state.isSelected && greyVer ? 'var(--primaryBlack3)' : styles.background,
        },
      }),
      dropdownIndicator: (styles) => ({ ...styles, display: 'none' }),
    };
  }

  return {
    menuPortal: (base) => ({ ...base, zIndex: menuZIndex ?? 9999 }),
    menu: (styles) => ({
      ...styles,
      width: menuWidth ?? width,
    }),
    control: (styles, state) => ({
      ...styles,
      width,
      minWidth: minWidth ?? 'inital',
      height: height ? height : isMulti ? undefined : 34,
      minHeight: height ?? 38,
      cursor: 'pointer',
      borderRadius: customBorderRadius || 8,
      background:
        state.hasValue && redVer
          ? 'transparent'
          : redVer
          ? 'rgba(254, 81, 81, 0.1)'
          : blueVer
          ? 'rgba(57, 161, 220, 0.1)'
          : isDisabled
          ? 'var(--accentGrayFourth)'
          : '#FFF',
      fontWeight: state.hasValue && redVer ? '900' : blueVer && 'bold',
      border:
        blueVer || redVer
          ? 'none'
          : isDisabled
          ? '1px solid var(--accentGraySecond)'
          : isError
          ? '1px solid var(--primaryRed)'
          : hasWarning
          ? '1px solid var(--primaryYellow)'
          : isChanged
          ? '1px solid var(--primaryYellow)'
          : '1px solid var(--primaryBlack10)',
      marginRight: blueVer && 10,
      alignItems: height && 'flex-start',
      boxShadow: !blueVer && state.isFocused ? '3px 3px 10px var(--primaryBlack8)' : styles.boxShadow,
      '&:hover': {
        borderColor: !isDisabled && !blueVer && 'var(--primaryBlack30)',
      },
    }),
    indicatorSeparator: (styles) => ({ ...styles, display: 'none' }),
    placeholder: (styles) => ({ ...styles, fontSize: 14, opacity: !redVer && 0.2, color: redVer && '#FE5151' }),
    valueContainer: (styles) => ({ ...styles, height: height ?? 'initial' }),
    singleValue: (styles, state) => ({
      ...styles,
      fontSize: redVer ? 20 : 14,
      color: (blueVer || (state.hasValue && redVer)) && 'var(--primaryBlue)',
      fontWeight: boldValue && 'bold',
      width: fullWidth && 'calc(100% - 10px)',
    }),
    option: (styles, state) => ({
      ...styles,
      color: state.isSelected && greyVer ? 'var(--primaryBlack)' : styles.color,
      background: state.isSelected && greyVer ? 'var(--primaryBlack10)' : styles.background,
      '&:hover': {
        background: !isDisabled && !state.isSelected && greyVer ? 'var(--primaryBlack3)' : styles.background,
      },
    }),
    indicatorsContainer: (styles) => ({ ...styles, height: height ?? 'initial' }),
    ...(isDisabled && { dropdownIndicator: (styles) => ({ ...styles, display: 'none' }) }),
  };
};

// Find the option with largest title and add this title length to base width
export const getSelectWidth = ({ options, baseWidth }) =>
  baseWidth + ([...options].sort((a, b) => b?.label?.length - a?.label?.length)?.[0]?.label?.length * 4 || 50);
