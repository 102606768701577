import { useContext, useEffect, useMemo, useState } from 'react';
import { Form, Formik } from 'formik';
import { useExpanded, useTable } from 'react-table';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import { AppContext } from 'AppContext';
import { Centerer, Spacer } from 'components/Core';
import { FillDot } from 'components/Icons';
import { ArrowBoldDownIcon, ArrowBoldUpIcon } from 'components/Icons';
import {
  TableHeaderColumn,
  TableHeaderRow,
  TableBody,
  TableRow,
  TableWrapper,
  TableHeader,
} from 'components/Table/CompactNumericTable';
import { NUMBER_FORMATS } from 'consts/global';
import { numberFormatter } from 'utils/formatters';
import { StageName, StyledTableCell } from './PipelineCustomersTable.styles';
import { groupCustomersByStage } from './PipelineCustomersTable.utils';
import { CustomerNameCell } from '../Shared/CustomerNameCell';
import { CustomizableMetric } from '../Shared/CustomizableMetric';
import { EmptyCustomersTable } from '../Shared/EmptyCustomersTable';
import { ShowHideButton } from '../Shared/styles';

export const PipelineCustomersTable = ({ forecastId, month, data, editForecastDetails }) => {
  const {
    appSettings: { isARR, currencyISOCode: currency },
  } = useContext(AppContext);
  const [showingAll, setShowingAll] = useState(false);
  const handleSubmit = (values) => {
    editForecastDetails({
      forecastId,
      forecastMonth: values.initial_forecast_month,
      forecastType: 'customers_new',
      customerId: values.customer_id,
      data: {
        recurring_amount: values.recurring_amount / (isARR ? 12 : 1),
        probability: values.probability / 100,
        forecast_month: values.forecast_month,
      },
    });
  };

  const columns = useMemo(
    () => [
      {
        Header: (
          <Centerer>
            <FillDot fill="var(--primaryYellow)" size="8px" />
            <Spacer width="8px" />
            <div>Deals due to close in {dayjs(`${month}-15`).format('MMM YYYY')}</div>
            <Spacer width="8px" />
            <ShowHideButton onClick={() => setShowingAll(!showingAll)}>
              ({showingAll ? 'Hide' : 'Show'} others)
            </ShowHideButton>
          </Centerer>
        ),
        accessor: 'customer_name',
        Cell: ({ cell: { value }, row }) =>
          row.canExpand ? (
            <StageName onClick={() => toggleRowExpanded(row.id)}>
              <span>{value}</span>
              {row.isExpanded ? <ArrowBoldUpIcon size="9px" /> : <ArrowBoldDownIcon size="9px" />}
            </StageName>
          ) : (
            <>
              <Spacer width="10px" />
              <CustomerNameCell
                externalServiceName={row.original.external_service_name ?? data?.external_service_name}
                externalUrl={row.original.external_url ?? data?.external_url}
              >
                {value}
              </CustomerNameCell>
            </>
          ),
      },
      {
        Header: 'Recurring amount',
        accessor: 'recurring_amount',
        Cell: ({ cell: { value }, row }) =>
          row.canExpand ? (
            numberFormatter({ type: NUMBER_FORMATS.CURRENCY, rawValue: value, currency })
          ) : (
            <CustomizableMetric
              name="recurring_amount"
              dataType={NUMBER_FORMATS.CURRENCY}
              value={value ?? 0}
              isSecondary={row.original.isSecondary}
            />
          ),
      },
      {
        Header: 'Weight',
        accessor: 'probability',
        Cell: ({ cell: { value }, row }) =>
          row.canExpand ? (
            numberFormatter({ type: NUMBER_FORMATS.PERCENT, rawValue: value })
          ) : (
            <CustomizableMetric
              name="probability"
              dataType={NUMBER_FORMATS.PERCENT}
              value={value}
              explanation="The likelihood that this deal will close"
              isSecondary={row.original.isSecondary}
            />
          ),
      },
      {
        Header: 'Forecasted revenue',
        accessor: 'forecasted_amount',
        Cell: ({ cell: { value } }) => numberFormatter({ type: NUMBER_FORMATS.CURRENCY, rawValue: value, currency }),
      },
      {
        Header: 'Close date',
        accessor: 'forecast_month',
        Cell: ({ cell: { value }, row }) =>
          row.canExpand ? null : (
            <CustomizableMetric
              name="forecast_month"
              dataType="calendar"
              value={value}
              explanation="Thus deal is expected to close on this date"
              missingExplanation="This deal is missing a close date"
              isSecondary={row.original.isSecondary}
            />
          ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [month, data?.external_service_name, data?.external_url, showingAll, setShowingAll],
  );

  const dataForTable = useMemo(
    () => groupCustomersByStage({ customers: data?.customers ?? [], stages: data?.stages ?? [], month, showingAll }),
    [data, month, showingAll],
  );

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows, toggleRowExpanded } = useTable(
    {
      columns,
      data: dataForTable,
      getSubRows: (row) => row.subRows || [],
    },
    useExpanded,
  );

  useEffect(() => {
    rows.forEach((row) => {
      toggleRowExpanded(row.id, true);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataForTable]);

  return (
    <TableWrapper {...getTableProps()} noMaxHeight={true} data-cy="pipeline-customers-table">
      <TableHeader>
        {headerGroups.map((headerGroup) => (
          <TableHeaderRow {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <TableHeaderColumn {...column.getHeaderProps()} columnCount={columns.length}>
                {column.render('Header')}
              </TableHeaderColumn>
            ))}
          </TableHeaderRow>
        ))}
      </TableHeader>
      {isEmpty(dataForTable) ? (
        <EmptyCustomersTable month={month} entityName="customers in the sales pipeline" />
      ) : (
        <TableBody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <Formik
                key={row.id}
                initialValues={{
                  customer_id: row.original.customer_id,
                  initial_forecast_month: row.original.forecast_month,
                  recurring_amount: row.original.recurring_amount,
                  probability: row.original.probability * 100,
                  forecast_month: row.original.forecast_month,
                }}
                onSubmit={handleSubmit}
                enableReinitialize={true}
              >
                <Form>
                  <TableRow {...row.getRowProps()} canExpand={row.canExpand}>
                    {row.cells.map((cell) => (
                      <StyledTableCell
                        {...cell.getCellProps()}
                        columnCount={columns.length}
                        canExpand={row.canExpand}
                        blurred={row.canExpand && cell?.column?.id === 'probability'}
                      >
                        {cell.render('Cell')}
                      </StyledTableCell>
                    ))}
                  </TableRow>
                </Form>
              </Formik>
            );
          })}
        </TableBody>
      )}
    </TableWrapper>
  );
};
