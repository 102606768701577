import styled from 'styled-components';

import { ReactComponent as Icon } from 'images/circle-check-gray.svg';

export const CircleCheckIcon = styled(Icon)`
  width: ${(props) => props.size};
  height: ${(props) => props.size};

  cursor: ${(props) => props.cursor};

  g {
    opacity: ${(props) => props.opacity};
  }

  path {
    fill: ${(props) => props.color};
  }
`;
