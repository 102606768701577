import { Centerer } from 'components/Core';
import styled from 'styled-components';

const Floater = styled(Centerer)`
  cursor: pointer;
  position: fixed;
  display: ${({ showFloater }) => (showFloater ? 'flex' : 'none')};
  z-index: 49;
  padding: 8px 8px 8px 12px;
  background: var(--primaryBlack40);
  backdrop-filter: blur(20px);
  border-radius: 100px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #ffffff;
  bottom: ${({ bottom }) => bottom ?? '90px'};
  top: ${({ top }) => top};
  left: ${({ left }) => left ?? 'calc(50%)'};
  right: ${({ right }) => right};
  gap: ${({ gap }) => gap ?? '5px'};
`;

export const FloatingActionsContainer = ({ showFloater, children, top, left, bottom, right, gap }) => {
  return (
    <Floater showFloater={showFloater} top={top} left={left} bottom={bottom} right={right} gap={gap}>
      {children}
    </Floater>
  );
};
