import LoadingGif from 'images/preloader.gif';

const Loader = (props) => {
  const { containerStyles = {}, loaderStyles = {} } = props;
  return (
    <span style={containerStyles} data-cy="loader">
      <img style={loaderStyles} src={LoadingGif} alt="Loading..." />
    </span>
  );
};

export default Loader;
