import { NUMBER_FORMATS, RECOGNITION_TYPES } from 'consts/global';
import { numberFormatter } from 'utils/formatters';
import { ReactComponent as ReceiptIcon } from 'images/receipt.svg';
import { AmountToBillContainer } from './styles';

export const TransactionAmountToBill = ({ recognition, totalAmount, currency }) => {
  let message = '';
  let boldText = '';
  switch (recognition) {
    case RECOGNITION_TYPES.tillCanceled:
      message = 'Subscript will use the amount to bill based on the';
      boldText = 'monthly recurring amount';
      break;
    case RECOGNITION_TYPES.eventRecurring:
      message = 'Subscript will use the amount to bill based on the';
      boldText = 'spreads of the recognition schedule';
      break;
    case RECOGNITION_TYPES.linear:
    default:
      const formattedAmount = numberFormatter({
        rawValue: totalAmount,
        type: NUMBER_FORMATS.CURRENCY,
        currency,
        decimalPlaces: 2,
      });
      message = 'Amount to bill based on the ';
      boldText = `total amount: ${formattedAmount}`;
      break;
  }

  return (
    <AmountToBillContainer data-cy="transaction-modal__amount-to-bill">
      {message} <b>{boldText}</b>
      <ReceiptIcon />
    </AmountToBillContainer>
  );
};
