import dayjs from 'dayjs';
import { EXISTING_CUSTOMERS_FORECAST_TYPE } from '../consts';
import { EXISTING_CUSTOMERS_TABLE_TYPE } from './consts';

export const getTitleColumnHeader = ({ tableType, month }) => {
  switch (tableType) {
    case EXISTING_CUSTOMERS_TABLE_TYPE.UNDER_CONTRACT:
      return `Existing in ${dayjs(month).format('MMM YYYY')}`;
    case EXISTING_CUSTOMERS_TABLE_TYPE.PIPELINE_RENEWAL:
      return `Renewals in ${dayjs(month).format('MMM YYYY')}`;
    case EXISTING_CUSTOMERS_TABLE_TYPE.PIPELINE_UPSELL:
      return `Upsells in ${dayjs(month).format('MMM YYYY')}`;
    default:
      return 'Up for Renewal';
  }
};

export const getHiddenColumns = ({ tableType, existingForecastType }) => {
  if (existingForecastType === EXISTING_CUSTOMERS_FORECAST_TYPE.PIPELINE) {
    return tableType === EXISTING_CUSTOMERS_TABLE_TYPE.UNDER_CONTRACT
      ? ['net_revenue_retention', 'pipeline_recurring_revenue', 'pipeline_closed_date', 'upsell', 'forecasted_change']
      : ['net_revenue_retention', 'downsell_churn', 'upsell'];
  } else {
    return tableType === EXISTING_CUSTOMERS_TABLE_TYPE.UNDER_CONTRACT
      ? ['downsell_churn', 'pipeline_recurring_revenue', 'pipeline_closed_date', 'forecasted_change']
      : ['pipeline_recurring_revenue', 'pipeline_closed_date', 'forecasted_change'];
  }
};

export const underContractFromPipelineRenewal = (customer) =>
  !customer.customer_under_contract && !customer.pipeline_closed_date && customer.forecasted_amount > 0;

export const secondaryMapper = ({ customerList, month, showingAll }) =>
  customerList.reduce((acc, customer) => {
    const isSecondary = dayjs.utc(customer.pipeline_closed_date).format('YYYY-MM') !== month;
    if (!showingAll && isSecondary) {
      return acc;
    } else {
      acc.push({
        ...customer,
        isSecondary,
      });
    }
    return acc;
  }, []);

export const getForecastedExistingCustomersTableData = ({ customersData, existingForecastType, tableType, month }) => {
  if (existingForecastType === EXISTING_CUSTOMERS_FORECAST_TYPE.PIPELINE) {
    switch (tableType) {
      case EXISTING_CUSTOMERS_TABLE_TYPE.UNDER_CONTRACT:
        return (
          customersData.filter(
            (customer) =>
              (customer.customer_under_contract || underContractFromPipelineRenewal(customer)) &&
              !customer.pipeline_closed_date,
          ) ?? []
        );
      case EXISTING_CUSTOMERS_TABLE_TYPE.UP_FOR_RENEWAL:
        return (
          customersData.filter(
            (customer) =>
              !customer.customer_under_contract &&
              customer.initial_amount > 0 &&
              customer.forecasted_amount === 0 &&
              !customer.pipeline_closed_date,
          ) ?? []
        );
      case EXISTING_CUSTOMERS_TABLE_TYPE.PIPELINE_RENEWAL:
        return (
          customersData.filter(
            (customer) =>
              customer.pipeline_closed_date &&
              // This filters deals that are not upsells (we don't need a selected month condition because we want them all - except upsells - either way)
              (customer.forecasted_amount <= customer.initial_amount ||
                // This filter all pipeline renewal deals from other months for "show others" section
                dayjs.utc(customer.pipeline_closed_date).format('YYYY-MM') !== month),
          ) ?? []
        );
      case EXISTING_CUSTOMERS_TABLE_TYPE.PIPELINE_UPSELL:
        return (
          customersData.filter(
            (customer) =>
              // Filter pipeline renewal deals in selected month that are upsells
              dayjs.utc(customer.pipeline_closed_date).format('YYYY-MM') === month &&
              customer.forecasted_amount > customer.initial_amount,
          ) ?? []
        );
      default:
        return [];
    }
  } else {
    return (
      customersData.filter((customer) =>
        tableType === EXISTING_CUSTOMERS_TABLE_TYPE.UNDER_CONTRACT
          ? customer.customer_under_contract
          : !customer.customer_under_contract,
      ) ?? []
    );
  }
};
