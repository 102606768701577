import { Centerer } from 'components/Core';
import styled, { css } from 'styled-components';

export const SubTabsHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px;
  gap: 12px;
  border: 1px solid var(--accentGraySecond);
  border-radius: 20px;
  box-shadow: 8px 8px 40px 0px var(--primaryBlack2);
  margin-top: 20px;
`;

export const SubTabs = styled(Centerer)`
  flex-direction: column;
  padding: 12px 20px;
  gap: 4px;
  color: var(--primaryBlack);
  font-weight: 700;
  font-size: 14px;
  flex-grow: 1;
  border-radius: 16px;

  &:hover {
    cursor: pointer;
    background-color: ${(props) => (!props.selected ? 'var(--primaryBlack3)' : 'default')};
  }

  ${(props) =>
    props.selected &&
    css`
      color: var(--primaryBlue);
      background-color: var(--primaryBlue10);
      & span {
        color: var(--primaryBlue);
      }
    `};
`;

export const SubText = styled.span`
  font-size: 12px;
  font-weight: 400;
  color: var(--primaryBlack50);
`;
