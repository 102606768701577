import { Fragment } from 'react';
import styled from 'styled-components';
import { truncate } from 'lodash';

const Wrapper = styled.div`
  display: inline;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
`;

export const TransactionsList = ({ transactions }) => {
  const sorted = (transactions ?? []).sort((a, b) => b.amount - a.amount);
  const toShow = sorted.slice(0, 2);

  const shownTransactions = toShow.map((transaction, index) => (
    <Fragment key={transaction.id}>
      {index > 0 && ', '}
      <span>{truncate(transaction.name)}</span>
    </Fragment>
  ));

  return sorted.length > 0 ? (
    <Wrapper>
      {shownTransactions}
      {transactions.length > 2 && ', +1'}
    </Wrapper>
  ) : null;
};
