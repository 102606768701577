import React, { useContext } from 'react';
import { AppContext } from 'AppContext';
import { Flexer, Spacer } from 'components/Core';
import { TimeLoader, TimeLoaderContainer } from 'components/Loaders';
import { UnconfirmedTransactionsBanner } from 'shared/Banners';
import { NoIntegrations, NoItems } from 'shared/ErrorBars';
import { Filters } from 'shared/Filters';
import { GlobalTogglesContextProvider } from 'shared/GlobalToggles';
import { GoalsBanner, GoalsProvider } from 'shared/Goals';
import { InsightsProvider } from 'shared/Insights/InsightsProvider';
import { InsightsBanner } from 'shared/Insights/InsightsBanner';
import { DashboardContext } from '../DashboardContext';
import { UnprocessableDataError } from '../Errors/UnprocessableDataError';
import { ReportBuilderContext } from './ReportBuilderContext';
import { ReportBuilder } from './ReportBuilder';
import { ReportBuilderEmptyState } from './ReportBuilderEmptyState';
import { PageWrapper } from './styles';

export const ReportBuilderPage = () => {
  const { organization } = useContext(AppContext);
  const pageState = useContext(DashboardContext);
  const {
    dataFilter,
    metadataFilter,
    setDataFilter,
    setMetadataFilter,
    error,
    isLoading,
    isFirstLoad,
    hasIntegrations,
    hasIncompleteIntegration,
    hasConfirmedTransactions,
    hasUnconfirmedTransactions,
    data,
    refetchMonthlyBreakdown,
  } = pageState;
  const { pageReportState, exportScreenshotRef } = useContext(ReportBuilderContext);
  const hasData = pageReportState?.metricsSetting?.length > 0 || pageReportState?.snapshotTableRows?.length > 0;

  if (!hasIntegrations && !isLoading && !hasConfirmedTransactions) {
    return <NoIntegrations />;
  }

  return !isLoading && !hasData ? (
    <>
      {!hasConfirmedTransactions && !isLoading && (
        <UnprocessableDataError hasIncompleteIntegration={hasIncompleteIntegration} />
      )}
      <Flexer width="100%" height="calc(100% - 76px)">
        <ReportBuilderEmptyState />
      </Flexer>
    </>
  ) : (
    <PageWrapper ref={exportScreenshotRef}>
      {error ? (
        <NoItems
          entityName="Data"
          filterState={dataFilter}
          buttonCb={refetchMonthlyBreakdown}
          organization={organization?.[0]}
        />
      ) : (
        <TimeLoaderContainer isFirstLoad={!isFirstLoad} isLoading={isLoading}>
          {isLoading && <TimeLoader isFirstLoad={!isFirstLoad} pageName="dashboard" />}
          {!hasConfirmedTransactions && !isLoading && (
            <UnprocessableDataError hasIncompleteIntegration={hasIncompleteIntegration} />
          )}

          <GlobalTogglesContextProvider urlState={pageState}>
            <Filters
              hidePeriodSelection
              showConditions
              showCommittedSwitch
              dataFilter={dataFilter}
              setDataFilter={setDataFilter}
              metadataFilter={metadataFilter}
              setMetadataFilter={setMetadataFilter}
            />
            <InsightsProvider>
              <InsightsBanner />
            </InsightsProvider>
            {hasUnconfirmedTransactions && <UnconfirmedTransactionsBanner />}
            <GoalsProvider>
              <Spacer height="28px" />
              <GoalsBanner revenueData={data} dataFilter={dataFilter} />
              <Spacer height="40px" />
              <ReportBuilder />
            </GoalsProvider>
          </GlobalTogglesContextProvider>
        </TimeLoaderContainer>
      )}
    </PageWrapper>
  );
};
