import { Row } from 'components/Core';
import styled from 'styled-components';

export const UpForRenewalWrapper = styled.div`
  cursor: pointer;
  display: inline-block;
  position: relative;

  svg {
    cursor: pointer;
    position: relative;
    top: 2px;
  }
`;

export const TotalRevenueContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
`;

export const PaddingWrapper = styled.div`
  padding: 0 40px 0 60px;
`;

export const TotalGrowthIconWrapper = styled.div`
  padding: 12px;
  border: 1px solid var(--accentGraySecond);
  box-shadow: 2px 2px 12px var(--primaryBlack2);
  border-radius: 100px;
  display: flex;
  align-items: center;
`;

export const MiddleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 24px 20px;
`;

export const VerticalDivider = styled.div`
  width: 1px;
  height: 40px;
  background-color: var(--accentGraySecond);
`;

export const CardTitle = styled.div`
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  margin-bottom: 24px;
  display: flex;
  align-items: center;

  svg {
    margin-right: 12px;
  }
`;

export const TopContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  background: var(--primaryBlack2);
  border-radius: 16px;
  padding: 20px;
`;

export const Title = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  opacity: 0.4;
  margin-bottom: 12px;
`;

export const TotalRevenue = styled.span`
  font-size: 16px;
  font-weight: 900;
`;

export const Divider = styled.hr`
  border: none;
  position: relative;
  height: 1px;
  margin: 16px 0;
  z-index: 1;
  background-color: ${({ backgroundColor }) => backgroundColor || 'var(--primaryBlack5)'};
  width: 130%;
  left: -20px;
`;

export const ToolTipDivider = styled(Divider)`
  margin: 10px 0;
  width: 100%;
  background-color: white;
  opacity: 0.05;
  left: 0;
`;

export const Value = styled.span`
  font-size: 14px;
  font-weight: bold;
`;

export const PercentChange = styled.span`
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ color }) => color};
  font-size: 10px;
  font-weight: bold;
  padding: 2px 4px;
  border-radius: 4px;
  margin-left: 8px;

  svg {
    margin-left: 4px;
  }
`;

export const GrowthRateLabel = styled.span`
  font-weight: normal;
  font-size: 12px;
  opacity: 0.5;
`;

export const GreyLabel = styled.span`
  color: var(--primaryBlack50);
  font-size: 12px;
`;

export const BottomRow = styled(Row)`
  > * {
    width: 100%;
  }
`;

export const GrowthContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 20px;
  cursor: pointer;
  border: 1px solid var(--accentGraySecond);
  border-radius: 16px;

  &:hover {
    background-color: white;
  }
`;

export const Percent = styled.h3`
  font-weight: 900;
  font-size: 16px;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const GrowthIconWrapper = styled.div`
  margin-left: 4px;
  width: 12px;
  height: 12px;
  padding: 2px;
  align-items: center;
  display: flex;
`;

export const ToolTipContentContainer = styled.div`
  display: flex;
  overflow: hidden;
  width: 100%;
  flex-direction: column;
`;

export const ToolTipRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 4px;
`;

export const ToolTipLabel = styled.span`
  color: white;
  opacity: 0.6;
  font-size: 10px;
`;

export const ToolTipValue = styled.span`
  font-weight: 900;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
`;

export const UpsellTypesToggleLable = styled.span`
  font-size: 12px;
`;

export const MetricCardsContainer = styled.div`
  margin: 32px 0px 28px;
  padding: 0 40px 0 60px;
  display: flex;
  justify-content: space-between;

  @media (max-width: 1439px) {
    margin-top: 10;
  }
`;

export const ChartAndTotalRevenueContainer = styled.div`
  display: flex;
  padding: 0 40px 0 60px;
  padding-bottom: 40px;
  box-shadow: rgba(0, 21, 46, 0.027) 2px 20px 48px;
  justify-content: space-between;
`;

export const Card = styled.div`
  flex: 1;
  cursor: pointer;
  padding: 4px;
  overflow: hidden;
  position: relative;
  white-space: nowrap;
`;

export const CardContents = styled.div`
  border-radius: 16px;
  border: 1px solid ${({ active }) => (active ? 'var(--primaryBlue)' : 'var(--accentGraySecond)')};
  padding: 20px;
  padding-right: 10px;
  background-color: ${({ active }) => (active ? 'var(--primaryBlue5)' : 'white')};
  width: 100%;
  height: 100%;

  &:hover {
    background-color: var(--primaryBlue5);
    border: 1px solid ${({ active }) => (active ? 'var(--primaryBlue)' : 'var(--primaryBlue10)')};
    ${({ active }) => !active && 'opacity: 0.8;'}
  }
`;

export const DoubleMetricCard = styled.div`
  flex: 2;
  cursor: pointer;
  padding: 4px;
  overflow: hidden;
  position: relative;
  white-space: nowrap;
`;

export const DoubleCardContents = styled.div`
  width: 50%;
  border: 1px solid white;
  border-radius: 16px;
  ${({ active }) => active && 'border: 1px solid var(--primaryBlue);'}
  padding: 20px;
  padding-right: 10px;
  background-color: ${({ active }) => (active ? 'var(--primaryBlue5)' : 'white')};
  width: 100%;
  height: 100%;

  ${({ opacity, active }) => opacity && !active && 'opacity: 0.6;'}

  &:hover {
    background-color: var(--primaryBlue5);
    border: 1px solid ${({ active }) => (active ? 'var(--primaryBlue)' : 'var(--primaryBlue10)')};
    ${({ active }) => !active && 'opacity: 0.8;'}
  }
`;

export const Border = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  border: 1px solid var(--accentGraySecond);
  border-radius: 16px;
  background-color: white;
`;

export const CardImage = styled.img`
  width: 20px;
  height: 20px;
`;

export const LabelContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 6px;
`;

export const MetricLabel = styled.div`
  color: ${({ active }) => (active ? 'var(--primaryBlack)' : '#808080')};
`;

export const MetricAmount = styled.h3`
  margin-bottom: 4px;
  font-weight: 900;
  color: ${({ active }) => (active ? 'var(--primaryBlue)' : 'var(--primaryBlack)')};
`;

export const DoubleCardDivider = styled.div`
  border-right: 1px solid var(--accentGraySecond);
  margin: 20px 0;
`;

export const RotatedText = styled.span`
  transform: rotate(90deg);
  font-weight: 900;
  font-size: 9px;
  color: var(--primaryBlack35);
  margin-left: -20px;
  margin-right: 20px;
`;

export const SpreadTable = styled.table`
  width: 100%;
  border-radius: 10px;

  td {
    font-size: 12px;
    padding: 12px;
  }

  td:first-child {
    padding-left: 14px;
  }

  td:last-child {
    padding-right: 14px;
  }
`;

export const ColumnTitle = styled.td`
  font-weight: 900;
  font-size: 10px !important;
  line-height: 12px;
`;

export const ColumnWithTooltip = styled.div`
  display: flex;
  justify-content: ${(props) => props.justifyContent || 'flex-start'};
  align-items: center;

  svg {
    width: 18px;
    height: 18px;
    margin-left: 6px;
    vertical-align: sub;
    cursor: pointer;

    g {
      transition: opacity ease 0.4s;
    }

    &:hover {
      g {
        opacity: 1;
      }
    }
  }
`;

export const TransactionTableRow = styled.tr`
  background-color: var(--primaryBlack2);
  border-top: 1px solid var(--accentGraySecond);
`;

export const TransactionName = styled.td`
  color: var(--primaryBlue);
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 700;
`;

export const ProductName = styled.td``;

export const SpreadType = styled.td`
  text-align: center;
`;

export const BookingDate = styled.td``;

export const Dates = styled.td``;

export const Seats = styled.td`
  text-align: right;
`;

export const SpreadAmount = styled.td`
  text-align: right;
  font-weight: 900;
`;

export const TransactionAmount = styled.td`
  text-align: right;
`;

export const PreviousMonthContainer = styled.div`
  width: 98%;
  padding: 4px 8px;
  background-color: white;
  border: 1px solid var(--neutralGray);
  border-radius: 4px;
`;

export const PreviousMonthName = styled.span`
  font-weight: 900;
  font-size: 10px;
  color: var(--primaryBlack);
  margin-right: 8px;
`;

export const PreviousMonthLabel = styled.span`
  font-weight: 900;
  font-size: 10px;
  color: var(--primaryBlack50);
`;
