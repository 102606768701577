import styled from 'styled-components';

import { Spacer } from 'components/Core';
import { AddIcon } from 'components/Icons';

import { ReactComponent as NoDataIcon } from 'images/no-recognition-icon.svg';

export const GoalsTableContainer = styled.div`
  width: 100%;
  margin-top: 20px;
  overflow-x: auto;
  margin-bottom: 60px;
  position: relative;
  max-height: 60vh;
  border: 1px solid #f3f3f3;
  border-radius: 10px;
`;

export const GoalsTableHeader = styled.div``;

export const GoalsTableHeaderRow = styled.div`
  display: flex;
  padding: 10px;
  background: rgba(0, 21, 46, 0.03);
`;

export const GoalsTableHeaderColumn = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  width: ${(props) => (props.width ? props.width + 'px' : '100%')};
`;

export const GoalsTableBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const GoalsTableRow = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px;
  border-top: 1px solid #f3f3f3;

  &:hover {
    background: rgba(0, 133, 255, 0.05);
  }
`;

export const GoalsTableCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  flex-grow: 1;
  flex-shrink: 1;
  width: ${(props) => (props.width ? props.width + 'px' : '100%')};
`;

export const NameText = styled.div`
  color: var(--primaryBlue);
  font-weight: bold;
  font-size: 12px;
`;

export const ForMetricText = styled.div`
  font-size: 10px;
  opacity: 0.5;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const ColFlexer = styled.div`
  display: flex;
  min-width: 0;
  flex: 1;
  justify-content: center;
  align-items: start;
  flex-direction: column;

  &:hover {
    cursor: pointer;
  }
`;

export const ActionButtonsWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  font-weight: 900;
  font-size: 12px;
`;

export const CreateNewGoalContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 900;
  font-size: 14px;
  color: var(--primaryGreen);

  cursor: pointer;
  pointer-events: ${(props) => props.disabled && 'none'};
  opacity: ${(props) => props.disabled && '0.3'};
`;

export const CreateNewGoalButton = ({ children, onClick }) => (
  <CreateNewGoalContainer onClick={onClick}>
    {children} <Spacer width="6px" />
    <AddIcon size="16px" />
  </CreateNewGoalContainer>
);

const NoData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 300px;

  font-weight: 900;
  font-size: 14px;
  line-height: 18px;
`;
export const NoDataInfo = () => (
  <NoData>
    <NoDataIcon />
    <Spacer height="10px" />
    {"You don't have any goals"}
  </NoData>
);
