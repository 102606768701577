import styled from 'styled-components';

import {
  STORAGE_TOGGLES_KEYS,
  TOGGLES_SELECTORS_DESCRIPTIONS,
  COMMITTED_BOOLEAN_TO_SELECTOR,
  ARR_BOOLEAN_TO_SELECTOR,
  ROLL_UP_BOOLEAN_TO_SELECTOR,
  OPTIMISTIC_BOOLEAN_TO_SELECTOR,
  INFLUX_BOOLEAN_TO_SELECTOR,
  TOGGLES_SELECTORS_DESCRIPTIONS_DETAILS_KEYS,
} from './consts';
import React from 'react';

const ToggleDescription = styled.div`
  padding-top: 8px;
  padding-left: 32px;
`;

export const GlobalToggleDescription = ({ globalTogglesState, toggleKey }) => {
  switch (toggleKey) {
    case STORAGE_TOGGLES_KEYS.IS_COMMITTED:
      return (
        <ToggleDescription>
          {`${
            TOGGLES_SELECTORS_DESCRIPTIONS[
              COMMITTED_BOOLEAN_TO_SELECTOR[globalTogglesState[STORAGE_TOGGLES_KEYS.IS_COMMITTED]]
            ]
          } ${
            globalTogglesState[STORAGE_TOGGLES_KEYS.IS_COMMITTED]
              ? TOGGLES_SELECTORS_DESCRIPTIONS[TOGGLES_SELECTORS_DESCRIPTIONS_DETAILS_KEYS.COMMITTED_REVENUE_DETAILS]
              : ''
          }`}
        </ToggleDescription>
      );
    case STORAGE_TOGGLES_KEYS.IS_ARR:
      return (
        <ToggleDescription>
          {TOGGLES_SELECTORS_DESCRIPTIONS[ARR_BOOLEAN_TO_SELECTOR[globalTogglesState[STORAGE_TOGGLES_KEYS.IS_ARR]]]}
        </ToggleDescription>
      );
    case STORAGE_TOGGLES_KEYS.IS_ROLLUP:
      return (
        <ToggleDescription>
          {
            TOGGLES_SELECTORS_DESCRIPTIONS[
              ROLL_UP_BOOLEAN_TO_SELECTOR[globalTogglesState[STORAGE_TOGGLES_KEYS.IS_ROLLUP]]
            ]
          }
        </ToggleDescription>
      );
    case STORAGE_TOGGLES_KEYS.IS_OPTIMISTIC:
      return (
        <ToggleDescription>
          {
            TOGGLES_SELECTORS_DESCRIPTIONS[
              OPTIMISTIC_BOOLEAN_TO_SELECTOR[globalTogglesState[STORAGE_TOGGLES_KEYS.IS_OPTIMISTIC]]
            ]
          }
        </ToggleDescription>
      );
    case STORAGE_TOGGLES_KEYS.IS_INFLUX:
      return (
        <ToggleDescription>
          {
            TOGGLES_SELECTORS_DESCRIPTIONS[
              INFLUX_BOOLEAN_TO_SELECTOR[globalTogglesState[STORAGE_TOGGLES_KEYS.IS_INFLUX]]
            ]
          }
          {globalTogglesState[STORAGE_TOGGLES_KEYS.IS_INFLUX] && (
            <div>
              {TOGGLES_SELECTORS_DESCRIPTIONS[TOGGLES_SELECTORS_DESCRIPTIONS_DETAILS_KEYS.ENABLE_INFLUX_DETAILS]}
            </div>
          )}
        </ToggleDescription>
      );
    default:
      return <></>;
  }
};
