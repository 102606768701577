export const getDataForTable = ({ data = [], accountingCurrency }) => {
  const result = [];
  for (const { billedAmount, transactionAmount, creditNoteItems, invoiceItems, transaction } of data) {
    result.push({
      customerName: transaction.customer_name,
      customerId: transaction.customer_id,
      transactionDate: transaction.date,
      transactionName: transaction.name,
      transactionId: transaction.id,
      transactionCurrency: transaction.currency || accountingCurrency,
      invoicingScheduleId: transaction.invoicing_schedule_id,
      productName: transaction.product_name,
      recognition: transaction.recognition,
      startDate: transaction.start_date,
      endDate: transaction.end_date,
      invoiceAmount: billedAmount,
      transactionAmount,
      difference: transactionAmount - billedAmount,
      invoiceItems,
      creditNoteItems,
    });
  }
  return result;
};
