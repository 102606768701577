import React, { useState, Fragment } from 'react';
import styled from 'styled-components';

import { Flexer } from 'components/Core';
import { FormikEmailSelector } from 'components/Controls';
import { MAGIC_METADATA } from 'consts/global';
import { getEmailAddresses } from 'models/customer';

import { EditBillingAddress } from './EditBillingAddress';
import { EditShippingAddress } from './EditShippingAddress';

const AddressTab = styled(Flexer)`
  cursor: pointer;
  padding: 10px 12px;
  border: 1px solid var(--accentGraySecond);
  border-radius: 8px;
  font-size: 14px;
  font-weight: 700;
  background-color: ${({ active }) => (active ? 'var(--primaryBlue10)' : 'white')};
  color: ${({ active }) => (active ? 'var(--primaryBlue)' : 'black')};
`;

const WarningWrapper = styled.div`
  padding: 8px 12px;
  border-radius: 8px;
  background-color: var(--accentGrayFourth);
  border: 1px solid var(--neutralGray);

  font-weight: 700;
  font-size: 12px;
  font-style: italic;
`;

const HightLightText = styled.span`
  color: var(--tertiaryYellow);
`;

export const ADDRESS_TABS = {
  EMAIL: 'Email',
  BILLING: 'Billing',
  SHIPPING: 'Shipping',
};

export const CustomerAddressInformation = ({
  values,
  setFieldValue,
  defaultAddressTab = ADDRESS_TABS.BILLING,
  service,
  isDisabled,
}) => {
  const [addressTab, setAddressTab] = useState(defaultAddressTab);

  const billWithExternalParent =
    values?.metadata?.[MAGIC_METADATA.EXTERNAL_PARENT_CUSTOMER_ID] &&
    values?.metadata?.[MAGIC_METADATA.QUICKBOOKS_BILLWITHPARENT];

  const additionalText = billWithExternalParent ? "Child's " : '';
  const tabLabels = {
    [ADDRESS_TABS.EMAIL]: `${additionalText}Billing Email(s)`,
    [ADDRESS_TABS.BILLING]: `${additionalText}Billing Address`,
    [ADDRESS_TABS.SHIPPING]: `${additionalText}Shipping Address`,
  };
  const warningLabels = {
    [ADDRESS_TABS.EMAIL]: ` billing email(s)`,
    [ADDRESS_TABS.BILLING]: ` billing address`,
    [ADDRESS_TABS.SHIPPING]: ` shipping address`,
  };

  const content = () => {
    switch (addressTab) {
      case ADDRESS_TABS.BILLING:
        return (
          <EditBillingAddress values={values} setFieldValue={setFieldValue} service={service} isDisabled={isDisabled} />
        );
      case ADDRESS_TABS.EMAIL:
        return (
          <FormikEmailSelector
            name="contacts"
            label="Billing Email(s)"
            containerWidth="100%"
            smallVersion
            value={getEmailAddresses({ objectWithInvoicingDetails: values })} // Strip out any non-string values
            onChange={(selectedValues) => setFieldValue('invoicing_details.contacts', selectedValues)}
            isDisabled={isDisabled}
          />
        );
      default:
        return (
          <EditShippingAddress
            values={values}
            setFieldValue={setFieldValue}
            service={service}
            isDisabled={isDisabled}
          />
        );
    }
  };

  return (
    <Fragment>
      <Flexer gap="16px">
        {Object.values(ADDRESS_TABS).map((tab) => (
          <AddressTab
            key={tab}
            onClick={() => setAddressTab(tab)}
            active={addressTab === tab}
            flexGrow="1"
            justifyContent="center"
            data-cy={`customer__address-tab--${tab}`}
          >
            {tabLabels[tab]}
          </AddressTab>
        ))}
      </Flexer>
      {billWithExternalParent && (
        <WarningWrapper>
          Subscript will use <HightLightText>the parent's {warningLabels[addressTab]}</HightLightText> instead of this
          one
        </WarningWrapper>
      )}
      {content()}
    </Fragment>
  );
};
