import React from 'react';
import styled from 'styled-components';
import introIcon from 'images/Saly-25.png';

const IntroductionWrapper = styled.div`
  padding: 0 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const IntroSubtitle = styled.div`
  font-size: 14px;
  line-height: 20px;
  opacity: 0.5;
  margin-top: 40px;
`;

const MainContent = styled.div`
  margin-top: 16px;
  font-size: 30px;
  line-height: 44px;
  max-width: 570px;
  text-align: center;

  span {
    font-weight: 900;
    color: var(--primaryGreen);
  }
`;

export const Introduction = ({ user }) => {
  return (
    <IntroductionWrapper>
      <img src={introIcon} alt="intro icon" width="187" height="100" />
      <IntroSubtitle>
        Hello, {user.name} from {user.company}!
      </IntroSubtitle>
      <MainContent>
        Welcome to the <span>Subscript</span> setup. This will take about 5 minutes, and will let us finish your account
        setup for you.
      </MainContent>
    </IntroductionWrapper>
  );
};
