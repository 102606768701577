import { components } from 'react-select';
import {
  ACCOUNTING_RECOGNITION_TYPES,
  ACCOUNTING_RECOGNITION_TYPES_DESCRIPTIONS,
  ACCOUNTING_RECOGNITION_TYPES_LABELS,
} from 'consts/global';
import { usePortal } from 'components/Portal';
import { FormikCustomSelector } from 'components/Controls';
import { useClickOutside } from 'utils/hooks';
import {
  RecognitionDescription,
  RecognitionLabel,
  RecognitionRow,
  RecognitionText,
  SelectorWrapper,
  StyledSelectorMenuBase,
} from 'shared/TransactionContent/TransactionBody/TransactionRecognitionSection/RecognitionSelect/styles';

export const AccountingRecognitionSelect = ({ values, setFieldValue }) => {
  const { triggerRef, togglePortal, hidePortal, isPortalVisible } = usePortal({ xOffset: 20, YOffset: 25 });

  const handleSelectRecognition = (recognition) => {
    setFieldValue('accounting_recognition', recognition);
    hidePortal();
  };

  const formikSelectorRef = useClickOutside(() => hidePortal());

  const MenuContent = () => (
    <>
      {Object.values(ACCOUNTING_RECOGNITION_TYPES).map((accountingRecognitionType) => (
        <RecognitionRow
          key={accountingRecognitionType}
          selected={accountingRecognitionType === values?.accounting_recognition}
          onClick={() => handleSelectRecognition(accountingRecognitionType)}
          data-cy={`accounting_recognition-selector__option-${accountingRecognitionType}`}
        >
          <RecognitionText>
            <RecognitionLabel>{ACCOUNTING_RECOGNITION_TYPES_LABELS[accountingRecognitionType]}</RecognitionLabel>
            <RecognitionDescription>
              {ACCOUNTING_RECOGNITION_TYPES_DESCRIPTIONS[accountingRecognitionType]}
            </RecognitionDescription>
          </RecognitionText>
        </RecognitionRow>
      ))}
    </>
  );

  const menuWidth = '320px';

  return (
    <SelectorWrapper ref={triggerRef}>
      <div ref={formikSelectorRef}>
        <FormikCustomSelector
          boldValue
          label="Accounting Rev. Recognition"
          placeholder="Select recognition..."
          isSearchable={false}
          options={Object.entries(ACCOUNTING_RECOGNITION_TYPES_LABELS).map(([value, label]) => ({
            label,
            value,
          }))}
          key="accounting_recognition"
          name="accounting_recognition"
          value={
            values?.accounting_recognition
              ? {
                  value: values.accounting_recognition,
                  label: ACCOUNTING_RECOGNITION_TYPES_LABELS[values.accounting_recognition],
                }
              : {
                  // default value is TRANSACTION
                  value: ACCOUNTING_RECOGNITION_TYPES.TRANSACTION,
                  label: ACCOUNTING_RECOGNITION_TYPES_LABELS[ACCOUNTING_RECOGNITION_TYPES.TRANSACTION],
                }
          }
          dataCy={'accounting_recognition-selector'}
          menuWidth={menuWidth}
          menuIsOpen={isPortalVisible}
          onMenuOpen={togglePortal}
          components={{
            Menu: (props) => {
              return (
                <components.Menu {...props}>
                  <StyledSelectorMenuBase data-cy="accounting_recognition-selector" menuWidth={menuWidth}>
                    <MenuContent />
                  </StyledSelectorMenuBase>
                </components.Menu>
              );
            },
          }}
        />
      </div>
    </SelectorWrapper>
  );
};
