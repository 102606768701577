import { omit } from 'lodash';
import { formatAddress } from 'models/common';

// Used internally and should not be displayed or edited directly in the UI
export const INTERNAL_CUSTOMER_METADATA = {
  INVOICING_DETAILS: 'invoicing_details',
  SELECTED_GL_INTEGRATION_ID: 'selected_gl_integration_id',
};

export const ADDRESS_FIELDS = {
  CITY: 'city',
  STATE: 'state',
  POSTAL_CODE: 'postalcode',
  ADRESS_LINE_1: 'addressLine1',
  ADRESS_LINE_2: 'addressLine2',
  COUNTRY: 'country',
};

export const getCustomerDisplayName = ({ customer, customerName, customerId, customerEmail }) =>
  customer ? customer.name ?? customer.email ?? customer.id : customerName ?? customerEmail ?? customerId ?? null;

export const hasBillingAddress = ({ customer }) =>
  customer?.invoicing_details?.city ||
  customer?.invoicing_details?.state ||
  customer?.invoicing_details?.postalcode ||
  customer?.invoicing_details?.addressLine1 ||
  customer?.invoicing_details?.addressLine2 ||
  customer?.invoicing_details?.country;

export const hasShippingAddress = ({ customer }) => !!customer?.invoicing_details?.shipping_address;

export const getEmailAddresses = ({ objectWithInvoicingDetails }) => {
  // 'invoicing_details' is the key for customer data structure
  // 'invoicingDetails' is the key for transaction data structure
  const contacts = (objectWithInvoicingDetails?.invoicing_details ?? objectWithInvoicingDetails?.invoicingDetails)
    ?.contacts;

  if (Array.isArray(contacts)) {
    return contacts.filter((c) => typeof c === 'string');
  } else if (typeof contacts === 'string') {
    return [contacts];
  } else {
    return [];
  }
};

export const hasEmailAddress = ({ objectWithInvoicingDetails }) =>
  getEmailAddresses({ objectWithInvoicingDetails }).length > 0;

export const formatBillingAddress = ({ customer }) => {
  if (!customer?.invoicing_details) return '';
  return formatAddress(customer.invoicing_details);
};

export const formatShippingAddress = ({ customer }) => {
  if (!customer?.invoicing_details?.shipping_address) return '';
  return formatAddress(customer.invoicing_details.shipping_address);
};

export const getEditableMetadata = ({ customer }) =>
  customer?.metadata ? omit(customer.metadata, Object.values(INTERNAL_CUSTOMER_METADATA)) : customer?.metadata;
