import React, { useState } from 'react';
import styled from 'styled-components';
import { Formik } from 'formik';
import { useContractAPI } from 'api/contracts/hooks';
import { ReactComponent as QuestionIcon } from 'images/related-help.svg';
import { FlexEndContainer, Spacer, FlexerColumn, CentererVertical, FlexerRow } from 'components/Core';
import { FormikCustomInput } from 'components/Controls';
import {
  Modal,
  ModalBody,
  ModalButton,
  ModalCloseIcon,
  ModalContainer,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTitleText,
} from 'components/Modal';
import { SeeWhyModal } from './SeeWhyModal';

const ResetAllocationsButton = styled.span`
  width: fit-content;
  padding: 4px 8px;
  border-radius: 4px;
  background: var(--primaryBlue10);
  color: var(--primaryBlue);
  font-size: 12px;
  font-weight: 700;
  cursor: pointer;
`;

const SeeWhyText = styled(CentererVertical)`
  font-size: 12px;
  font-style: italic;
  color: var(--primaryBlack50);
  cursor: pointer;
`;

const LabelText = styled.span`
  font-size: 14px;
  font-weight: 500;
`;

const ProductName = styled.span`
  font-size: 12px;
  font-weight: 300;
  color: var(--primaryBlack70);
`;

const convertToDecimals = (allocations) => {
  return Object.entries(allocations ?? {}).reduce((acc, [transactionId, allocation]) => {
    const value = isNaN(allocation) ? 0 : allocation;
    acc[transactionId] = value / 100;
    return acc;
  }, {});
};

const convertToPercentage = (allocations) => {
  return Object.entries(allocations ?? {}).reduce((acc, [transactionId, allocation]) => {
    const value = isNaN(allocation) ? 0 : allocation;
    acc[transactionId] = value * 100;
    return acc;
  }, {});
};

export const AllocationsModal = ({ orgId, contractData, transactions, onClose }) => {
  const [showWhyModal, setShowWhyModal] = useState(false);

  const {
    operations: { updateContractAllocations, getDefaultContractAllocations },
  } = useContractAPI({ orgId, contractId: contractData.id, enabled: false });

  const handleFormSubmit = async ({ values }) => {
    const decimalValues = convertToDecimals(values); // convert to decimals
    await updateContractAllocations({ body: { allocations: decimalValues } });
  };

  const allocationsFromContract = convertToPercentage(contractData.revrec_allocations); // convert to percentages

  console.log('transactions', transactions);

  return (
    <>
      <ModalContainer>
        <Formik
          initialValues={allocationsFromContract}
          enableReinitialize={true}
          onSubmit={(values) => handleFormSubmit({ values })}
        >
          {({ submitForm, setValues }) => {
            const onResetClick = async () => {
              const defaultAllocations = await getDefaultContractAllocations();
              const defaultPercentages = convertToPercentage(defaultAllocations); // convert to percentages
              setValues(defaultPercentages); // set formik values to defaults
            };
            return (
              <Modal data-cy="update-contract-allocations-modal" width="540px" height="auto">
                <ModalCloseIcon onClose={onClose} data-cy="update-contract-allocations__close-button" />
                <ModalHeader>
                  <ModalTitle padding="12px 36px">
                    <ModalTitleText>Update Contract Allocations</ModalTitleText>
                  </ModalTitle>
                </ModalHeader>
                <ModalBody paddingLeft="36px" paddingRight="36px">
                  <FlexerColumn gap="8px" marginBottom="16px" marginTop="16px">
                    {transactions.map((transaction) => (
                      <CentererVertical key={transaction.id} justifyContent="space-between">
                        <FlexerColumn gap="4px">
                          <LabelText>{transaction.name}</LabelText>
                          <ProductName>({transaction.productName})</ProductName>
                        </FlexerColumn>
                        <FormikCustomInput
                          name={transaction.id}
                          placeholder="0"
                          suffix="%"
                          type="number"
                          precision={2}
                          data-cy={`update-contract-allocations__input-field--${transaction.id}`}
                        />
                      </CentererVertical>
                    ))}
                    <Spacer height="8px" />
                    <FlexerRow gap="8px">
                      <ResetAllocationsButton onClick={onResetClick}>Reset to Defaults</ResetAllocationsButton>
                      <SeeWhyText onClick={() => setShowWhyModal(true)}>
                        <span>Why are these the defaults?</span>
                        <Spacer width="8px" />
                        <QuestionIcon />
                      </SeeWhyText>
                    </FlexerRow>
                  </FlexerColumn>
                </ModalBody>
                <ModalFooter padding="12px 36px" noFixedHeight>
                  <FlexEndContainer>
                    <ModalButton default onClick={onClose}>
                      Cancel
                    </ModalButton>
                    <Spacer width="14px" />
                    <ModalButton primary onClick={submitForm} data-cy="update-contract-allocations__submit-button">
                      Save
                    </ModalButton>
                  </FlexEndContainer>
                </ModalFooter>
              </Modal>
            );
          }}
        </Formik>
      </ModalContainer>
      {showWhyModal && <SeeWhyModal transactions={transactions} onClose={() => setShowWhyModal(false)} />}
    </>
  );
};
