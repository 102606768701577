import React from 'react';
import { NUMBER_FORMATS } from 'consts/global';
import { numberFormatter } from 'utils/formatters';
import { FlexBetweenContainer, Spacer } from 'components/Core';
import { CustomerNameCell } from '../Shared/CustomerNameCell';
import { SectionItemCard, SectionItemCardSecondaryText } from './styles';

export const ExistingCustomersCard = ({ values, onCustomerClick, isChurn }) => (
  <SectionItemCard>
    <CustomerNameCell
      externalServiceName={values.external_service_name}
      externalUrl={values.external_url}
      onCustomerClick={() => onCustomerClick(values.customer_id)}
    >
      {values.customer_name}
    </CustomerNameCell>

    <Spacer height="12px" />

    <FlexBetweenContainer centerer>
      <SectionItemCardSecondaryText>{/*TODO: Add contract start and end date*/}</SectionItemCardSecondaryText>
      <SectionItemCardSecondaryText>
        {numberFormatter({
          type: NUMBER_FORMATS.CURRENCY,
          rawValue: isChurn ? values.initial_amount : values.forecasted_amount,
        })}
      </SectionItemCardSecondaryText>
    </FlexBetweenContainer>
  </SectionItemCard>
);
