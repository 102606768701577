import React from 'react';
import { useFormikContext } from 'formik';
import { SwitchWithLabel } from 'components/Controls';

const FIELD_NAME = 'cohortDisplayingTotal';

export const CohortDisplayingTotalField = () => {
  const { setFieldValue, values } = useFormikContext();

  return (
    <SwitchWithLabel
      label="Cohort Displaying Total (instead of Average)"
      name={FIELD_NAME}
      checked={!!values.cohortDisplayingTotal}
      onChange={(newValue) => setFieldValue(FIELD_NAME, newValue)}
    />
  );
};
