import React from 'react';
import styled from 'styled-components';

const StyledSpinner = styled.svg`
  width: ${({ width }) => width ?? '50px'};
  height: ${({ width }) => width ?? '50px'};
  border: ${({ thickness }) => `${thickness ?? '3px'} solid #f3f3f3`};
  border-bottom-color: ${({ color }) => color ?? 'var(--primaryGreen)'};
  border-radius: 50%;
  display: inline-block;
  animation: rotation 1s linear infinite;
  position: ${({ isAbsolute }) => (isAbsolute ? 'absolute' : 'initial')};
  top: ${({ isAbsolute, isInline }) => (isInline ? 'initial' : isAbsolute ? '50%' : 'initial')};
  left: ${({ isAbsolute, isInline }) => (isInline ? 'initial' : isAbsolute ? '50%' : 'initial')};

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const CircleLoader = ({ name, width, height, isAbsolute, isInline, thickness, color }) => (
  <StyledSpinner
    isAbsolute={isAbsolute}
    width={width}
    height={height}
    isInline={isInline}
    thickness={thickness}
    color={color}
    data-cy={`loader${name ? '--' + name : ''}`}
  />
);
