import React, { useCallback, useMemo, useRef, useState } from 'react';
import * as Sentry from '@sentry/react';
import { ToastsContext } from './ToastsContext';
import { Toasts } from './Toasts';

// A toast needs to stay around for at least one second
const MINIMUM_TOAST_TIMEOUT = 1000;

const DEFAULT_TIMEOUTS = {
  error: 5000,
  success: 3000,
};

export const ToastsProvider = ({ children }) => {
  const idRef = useRef(0);
  const [toasts, setToasts] = useState([]);

  const removeToast = useCallback((toastId) => setToasts((toasts) => toasts.filter((toast) => toast.id !== toastId)), [
    setToasts,
  ]);

  const pushToast = useCallback(
    (message, type, desiredTimeout) => {
      const toastId = idRef.current++;
      const timeout = desiredTimeout ?? DEFAULT_TIMEOUTS[type] ?? 3000;
      const realTimeout = timeout === -1 ? timeout : Math.max(MINIMUM_TOAST_TIMEOUT, timeout);
      setToasts((toasts) => [...toasts, { message, type, timeout: realTimeout, id: toastId }]);
    },
    [setToasts],
  );

  const pushCustomToast = useCallback(
    ({ CustomToast, timeout = 3000 }) => {
      const toastId = idRef.current++;
      const realTimeout = timeout === -1 ? timeout : Math.max(MINIMUM_TOAST_TIMEOUT, timeout);

      setToasts((toasts) => [...toasts, { CustomToast, timeout: realTimeout, id: toastId }]);
    },
    [setToasts],
  );

  const pushError = useCallback(
    (err, defaultMessage, desiredTimeout) => {
      const isErrPassedAsMessage = typeof err === 'string' || React.isValidElement(err);
      const networkError =
        !isErrPassedAsMessage && !err?.response // lack of response means network error
          ? "We had trouble reaching Subscript servers - either yours, or Subscript's internet might be down"
          : null;
      const errorMessage = isErrPassedAsMessage ? err : err?.response?.data?.errors?.message;
      pushToast(
        networkError ?? errorMessage ?? defaultMessage ?? 'Oops, there is something wrong :( Please try again later',
        'error',
        desiredTimeout,
      );

      if (networkError) {
        Sentry.captureMessage(`Could not reach Subscript API: ${defaultMessage}`, 'error');
      }
    },
    [pushToast],
  );

  const value = useMemo(() => ({ pushToast, pushCustomToast, pushError }), [pushCustomToast, pushError, pushToast]);

  return (
    <ToastsContext.Provider value={value}>
      {children}
      <Toasts toasts={toasts} removeToast={removeToast} />
    </ToastsContext.Provider>
  );
};
