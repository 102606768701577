import { useCallback, useState } from 'react';
import { ConfirmModal } from './ConfirmModal';

export const useConfirmModal = (props) => {
  const [showModal, setShowModal] = useState(false);

  const openModal = useCallback(() => {
    setShowModal(true);
  }, [setShowModal]);

  const Modal = useCallback(
    () => (showModal ? <ConfirmModal {...props} onClose={() => setShowModal(false)} /> : null),
    [showModal, setShowModal, props],
  );

  return {
    openConfirmModal: openModal,
    ConfirmModal: Modal,
    isModalVisible: showModal,
  };
};
