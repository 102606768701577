import React from 'react';
import { useField } from 'formik';
import { Checkbox } from 'formik-antd';
import styled from 'styled-components';

export const CustomInput = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  opacity: ${({ isDisabled }) => (isDisabled ? 0.5 : 1)};
  pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'default')};
`;

export const InputLabel = styled.label`
  font-size: ${(props) => props.fontSize ?? '12px'};
  line-height: 16px;
  color: var(--primaryBlack);
  margin-left: 8px;
  cursor: pointer;
  font-weight: ${(props) => (props.bold ? '800' : '400')};
`;

const FormikCustomCheckbox = ({
  label,
  bold = false,
  fontSize,
  isDisabled,
  containerStyle,
  isReversedValue,
  labelStyle,
  ...props
}) => {
  const [field, meta, helpers] = useField(props);

  const onChange = isReversedValue
    ? (val) => {
        const value = !val.target.checked;
        helpers.setValue(value);
      }
    : field.onChange;

  const checked = isReversedValue ? !meta.value : meta.value;

  return (
    <CustomInput isDisabled={isDisabled} style={containerStyle}>
      <Checkbox {...field} checked={checked} onChange={onChange} {...props} id={props.id || props.name} />
      {label && (
        <InputLabel htmlFor={props.id || props.name} bold={bold} fontSize={fontSize} style={labelStyle}>
          {label}
        </InputLabel>
      )}
    </CustomInput>
  );
};

export default FormikCustomCheckbox;
