import { PAGE_CONTEXT_VALUES } from 'utils/hooks';
import { convertDateValues } from './utils';

// From all page state fields this fn only returns report fields relevant for each page
export const pageStateToReportState = ({ page, pageState }) => {
  return convertDateValues(
    [...PAGE_CONTEXT_VALUES[page].pageStates, ...Object.keys(PAGE_CONTEXT_VALUES[page].customAppSettingsStates)].reduce(
      (acc, key) => {
        if (key === 'dataFilter' && PAGE_CONTEXT_VALUES[page]?.dataFilter) {
          acc[key] = PAGE_CONTEXT_VALUES[page].dataFilter.reduce((acc, dataFilterKey) => {
            acc[dataFilterKey] = pageState[key]?.[dataFilterKey];
            return acc;
          }, {});
        } else {
          acc[key] = pageState[key];
        }
        return acc;
      },
      {},
    ),
  );
};
