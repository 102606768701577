import { GridContainer, NoPeriodsText, PeriodHeader } from './styles';
import { PeriodForm } from './PeriodForm';

const EMPTY_PERIOD = { type: '', start_month: '', value: '' };

export const PeriodsForm = ({ periods, remove, push }) => {
  return (
    <GridContainer>
      <PeriodHeader
        onAddPeriod={() => {
          push(EMPTY_PERIOD);
        }}
      />
      {periods?.length > 0 ? (
        periods.map((period, index) => (
          <PeriodForm
            key={index}
            index={index}
            period={period}
            onRemove={() => {
              remove(index);
            }}
          />
        ))
      ) : (
        <NoPeriodsText> Add your first period to get started </NoPeriodsText>
      )}
    </GridContainer>
  );
};
