import { Fragment, useContext } from 'react';

import { AppContext } from 'AppContext';
import { getCustomSegmentDescriptors } from 'shared/MetadataSegmentsModal/utils';
import { Row } from 'components/Core';
import { ReactComponent as PaintBrushIcon } from 'images/paint-brush.svg';

import { CohortAverageRow } from './CohortAverageRow';
import { CohortTableRow } from './CohortTableRow';
import { TogglePopover } from './TogglePopover';
import {
  SettingsTile,
  StyledCogIcon,
  StyledTable,
  TableHeaderRow,
  TableHeaderTile,
  TableBody,
  DateHeaderTile,
  CohortsTableTooltip,
} from './styles';
import { calculateAbsoluteHeatLevels, getStartingCountFluctuations, getColumnHeaders } from '../utils';
import { CohortsContext } from '../CohortsContext';

export const CohortsTable = ({ exportScreenshotRef }) => {
  const {
    totalAmounts,
    setTotalAmounts,
    absoluteColoring,
    setAbsoluteColouring,
    cohortsData,
    setShowSpreadsModal,
    setCohortStartDate,
    setCohortEndDate,
    setSelectedMonth,
    cohortPalette,
    setCohortPalette,
    dataFilter,
    setCohortCustomerIds,
    setSegmentDescriptor,
  } = useContext(CohortsContext);

  const { orgConfigs } = useContext(AppContext);

  const { cohortInstallBy, cohortInstallSecondBy, cohortSegmentBy, resolution } = dataFilter;

  if (!cohortsData?.[cohortInstallBy] || (cohortInstallSecondBy && !cohortsData?.[cohortInstallSecondBy])) return null;

  // calculated with MRR
  const absoluteHeatLevels = {
    total: {},
    segmented: {},
  };
  if (absoluteColoring) {
    const result = calculateAbsoluteHeatLevels({
      cohortsData,
      installBy: cohortInstallBy,
      totalAmounts,
      segmentBy: cohortSegmentBy,
    });
    absoluteHeatLevels.total.heatLevels = result.total.heatLevels;
    absoluteHeatLevels.total.maxValue = result.total.maxValue;

    if (cohortSegmentBy) {
      absoluteHeatLevels.segmented.heatLevels = result.segmented.heatLevels;
      absoluteHeatLevels.segmented.maxValue = result.segmented.maxValue;
    }

    if (cohortInstallSecondBy) {
      const secondResult = calculateAbsoluteHeatLevels({
        cohortsData,
        installBy: cohortInstallSecondBy,
        totalAmounts,
        segmentBy: cohortSegmentBy,
      });
      absoluteHeatLevels.total.secondHeatLevels = secondResult.total.heatLevels;
      absoluteHeatLevels.total.secondMaxValue = secondResult.total.maxValue;

      if (cohortSegmentBy) {
        absoluteHeatLevels.segmented.secondHeatLevels = secondResult.segmented.heatLevels;
        absoluteHeatLevels.segmented.secondMaxValue = secondResult.segmented.maxValue;
      }
    }
  }

  const cohortRowNames = Object.keys(cohortsData[cohortInstallBy].cohortsData);

  const segmentTooltips = getCustomSegmentDescriptors({ orgConfigs });

  const startingCountFluctuations = getStartingCountFluctuations({ data: cohortsData[cohortInstallBy].cohortsData });

  const columnHeaders = getColumnHeaders({
    rowData: Object.values(cohortsData[cohortInstallBy].cohortsData)[0]?.monthTotals,
    resolution,
  });

  return (
    <>
      <div ref={exportScreenshotRef} style={{ overflow: 'auto', position: 'relative', maxHeight: '80vh' }}>
        <StyledTable data-cy={`cohorts-table`}>
          <TableHeaderRow>
            {columnHeaders.map((column) => (
              <TableHeaderTile key={column}>
                {column === 'Rev. Ret.' || column === 'Starting' ? (
                  <Row>{column}</Row>
                ) : column === 'Period' ? (
                  <Row>
                    <SettingsTile>
                      <TogglePopover
                        cohortPalette={cohortPalette}
                        setCohortPalette={(palette) => setCohortPalette(palette)}
                        toggleTotalAmounts={() => setTotalAmounts(!totalAmounts)}
                        toggleAbsoluteColoring={() => setAbsoluteColouring(!absoluteColoring)}
                        totalAmounts={totalAmounts}
                        absoluteColoring={absoluteColoring}
                        icon={<StyledCogIcon />}
                      />
                    </SettingsTile>
                  </Row>
                ) : (
                  <DateHeaderTile>{column}</DateHeaderTile>
                )}
              </TableHeaderTile>
            ))}
          </TableHeaderRow>

          <TableBody>
            <CohortAverageRow segmentTooltips={segmentTooltips} />
            {cohortRowNames.map((cohortRowName, index, arr) => {
              return (
                <Fragment key={cohortRowName}>
                  {
                    <CohortTableRow
                      key={cohortRowName}
                      isFirstRow={!index}
                      isLastRow={index === arr.length - 1}
                      cohortData={cohortsData[cohortInstallBy].cohortsData[cohortRowName]}
                      secondCohortData={cohortsData?.[cohortInstallSecondBy]?.cohortsData?.[cohortRowName] ?? {}}
                      cohortTabName="total"
                      cohortRowName={cohortRowName}
                      setShowSpreadsModal={setShowSpreadsModal}
                      setCohortStartDate={setCohortStartDate}
                      setCohortEndDate={setCohortEndDate}
                      setSelectedMonth={setSelectedMonth}
                      setCohortCustomerIds={setCohortCustomerIds}
                      setSegmentDescriptor={setSegmentDescriptor}
                      absoluteHeatLevels={absoluteHeatLevels.total}
                      startingCountFluctuations={startingCountFluctuations}
                    />
                  }
                  {cohortSegmentBy &&
                    cohortsData?.[cohortInstallBy]?.cohortSegmentData?.[cohortRowName] &&
                    Object.keys(cohortsData[cohortInstallBy].cohortSegmentData[cohortRowName])
                      //we use sort there for consistent order in segments
                      .sort()
                      .map((segmentKey, index, arr) => {
                        return (
                          <CohortTableRow
                            isSegmentCell
                            isFirstRow={!index}
                            isLastRow={index === arr.length - 1}
                            key={segmentKey}
                            cohortData={cohortsData[cohortInstallBy].cohortSegmentData[cohortRowName][segmentKey]}
                            secondCohortData={
                              cohortsData[cohortInstallSecondBy]?.cohortSegmentData?.[cohortRowName]?.[segmentKey] ?? {}
                            }
                            setShowSpreadsModal={setShowSpreadsModal}
                            setCohortStartDate={setCohortStartDate}
                            setCohortEndDate={setCohortEndDate}
                            setSelectedMonth={setSelectedMonth}
                            setCohortCustomerIds={setCohortCustomerIds}
                            setSegmentDescriptor={setSegmentDescriptor}
                            cohortTabName="segmented"
                            cohortRowName={cohortRowName}
                            cohortSegmentBy={cohortSegmentBy}
                            cohortSegmentKey={segmentKey}
                            absoluteHeatLevels={absoluteHeatLevels.segmented}
                            segmentTooltips={segmentTooltips}
                          />
                        );
                      })}
                </Fragment>
              );
            })}
          </TableBody>
        </StyledTable>
      </div>
      <CohortsTableTooltip>
        <PaintBrushIcon />
        {absoluteColoring
          ? 'Color-coding is set absolute to the values of the entire table'
          : 'Color-coding is set relative to the values in each row of the table'}
      </CohortsTableTooltip>
    </>
  );
};
