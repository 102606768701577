import React, { useContext } from 'react';
import styled from 'styled-components';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { COLORS } from 'consts/colors';
import { INTEGRATION_TYPES } from 'consts/integrations';
import { downloadBlobFile } from 'utils/export';
import { exportTransactionsWithoutInvoicingSchedule, importTransactionsWithInvoiceData } from 'api/billing';
import { AppContext } from 'AppContext';
import { Button } from 'components/Buttons';
import { Flexer, FlexerColumn } from 'components/Core';
import { useToasts } from 'components/Toasts';
import { FormikCustomSelector } from 'components/Controls';
import { FieldBody, FieldTitle } from 'views/Wizard/styles';
import { getIntegrationDisplayName } from 'models/integration';

export const HowToLink = styled.a`
  font-weight: normal;
  font-size: 14;
  text-decoration: underline;

  color: var(--primaryGreen);

  :hover {
    color: var(--secondaryGreen);
  }
`;

export const Subtitle = styled.div`
  font-style: italic;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: var(--primaryBlack50);
`;

export const BillingHistory = () => {
  const { orgId, integrations } = useContext(AppContext);
  const { pushToast, pushError } = useToasts();

  const glIntegrationsOptions = React.useMemo(() => {
    return integrations
      .filter((integration) => integration.type === INTEGRATION_TYPES.GL)
      .map((integration) => ({
        label: getIntegrationDisplayName(integration),
        value: integration.id,
      }));
  }, [integrations]);

  const handleExport = async () => {
    pushToast('Download started...');
    const zipFile = await exportTransactionsWithoutInvoicingSchedule({
      orgId,
    });
    downloadBlobFile({
      file: zipFile,
      format: 'zip',
      filename: 'billing_history_export',
    });
  };

  const handleImport = async (values) => {
    pushToast('Upload started. Please wait, it could take a few minutes');
    try {
      const failuresFile = await importTransactionsWithInvoiceData({
        orgId,
        invoicing_schedules: values.invoicing_schedules,
        invoices: values.invoices,
        integrationId: values.integrationId,
      });
      downloadBlobFile({ file: failuresFile, format: 'csv', filename: `import_results.csv` });
      pushToast('Upload completed. Check your downloads folder for import results file.');
    } catch (err) {
      pushError(err);
    }
  };

  return (
    <FieldBody>
      <FlexerColumn gap="10px">
        <FieldTitle noMarginBottom>
          <span>Export/Import Billing History</span>
        </FieldTitle>

        <HowToLink
          target="_blank"
          href="https://teamsubscript.notion.site/External-Syncing-historical-invoices-into-Subscript-047cc670bc8843cdbd53a18f30ed41f4?pvs=4"
        >
          How to: Import Invoices into Subscription Billing (click me)
        </HowToLink>

        <FlexerColumn gap="12px">
          <FlexerColumn gap="6px">
            <Subtitle>
              In this section you can export all transactions without invoicing schedules that you can then manually
              mark as paid in a .csv format.
            </Subtitle>

            <Flexer width="100px">
              <Button filled color={COLORS.GREY} onClick={handleExport}>
                Export
              </Button>
            </Flexer>
          </FlexerColumn>

          <Formik
            initialValues={{
              file: null,
              integrationId: null,
            }}
            validationSchema={Yup.object({
              invoicing_schedules: Yup.mixed().required('Please, select file'),
              invoices: Yup.mixed().required('Please, select file'),
              integrationId: Yup.string().required('Please, select service'),
            })}
            onSubmit={handleImport}
          >
            {({ setFieldValue, submitForm, isValid, dirty }) => (
              <FlexerColumn gap="6px">
                <Subtitle>Then you can re-upload so your data is up to date in Subscript.</Subtitle>

                <Flexer>
                  Invoicing Schedules:
                  <input
                    type="file"
                    onChange={(event) => setFieldValue('invoicing_schedules', event.target.files[0])}
                  />
                </Flexer>
                <Flexer>
                  Invoices:
                  <input type="file" onChange={(event) => setFieldValue('invoices', event.target.files[0])} />
                </Flexer>

                <Flexer>
                  <FormikCustomSelector name="integrationId" width="160px" options={glIntegrationsOptions} />
                </Flexer>

                <Flexer width="100px">
                  <Button filled color={COLORS.GREEN} onClick={submitForm} disabled={!dirty || !isValid}>
                    Import
                  </Button>
                </Flexer>
              </FlexerColumn>
            )}
          </Formik>
        </FlexerColumn>
      </FlexerColumn>
    </FieldBody>
  );
};
