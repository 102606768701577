import styled from 'styled-components';
import { Centerer, FlexerColumn } from 'components/Core';
import { ModalContainer } from 'components/Modal';
import { SizedLoader } from 'components/Loaders';
import { AlertFilledIcon as _AlertFilledIcon } from 'components/Icons';

const LoaderWrapper = styled(Centerer)`
  margin-bottom: 20px;
`;

const ModalContent = styled(FlexerColumn)`
  width: 1040px;
  background: #ffffff;
  box-shadow: 0px 30px 120px rgba(0, 21, 46, 0.4);
  border-radius: 20px;
`;

const ModalBody = styled(FlexerColumn)`
  gap: 8px;
  font-size: 12px;
  text-align: center;
`;

const ModalTitle = styled.h1`
  margin-bottom: 4px;
  padding: 0 36px;
  font-size: 14px;
  font-weight: 900;
  line-height: 26px;
  color: var(--primaryBlack);
`;

export const TransactionActionsModal = ({ modalName, modalTitle, modalDescription, isLoading }) => {
  return (
    <ModalContainer data-cy={modalName}>
      <ModalContent>
        <FlexerColumn>
          <FlexerColumn marginTop="28px">
            <ModalTitle>{modalTitle}</ModalTitle>
            {isLoading ? (
              <LoaderWrapper>
                <SizedLoader size={40} />
              </LoaderWrapper>
            ) : (
              <ModalBody>{modalDescription}</ModalBody>
            )}
          </FlexerColumn>
        </FlexerColumn>
      </ModalContent>
    </ModalContainer>
  );
};
