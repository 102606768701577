import styled from 'styled-components';
import { cssVar, transparentize } from 'polished';
import { ReactTable, TableSettingsTitle } from 'components/Table';

export const WaterfallTable = styled(ReactTable)`
  border-radius: 16px;
  margin-bottom: 16px;
  filter: drop-shadow(8px 8px 40px rgba(0, 21, 46, 0.05));
  border: 1px solid var(--primaryBlack5);
`;

export const DashboardHeadInner = styled.div`
  padding: 14px;
`;

export const ReactTableDashboardHeaderColumn = styled.div`
  min-width: 110px;
  min-height: 44px;
  position: relative;
  padding: 0 5px;
  background: var(--accentGrayFourth);
  white-space: nowrap;
  font-weight: 900;
  font-size: 10px;
  line-height: 14px;
  text-transform: uppercase;
  z-index: ${(props) => (props.firstCell ? 10 : 6)};
  top: 0;
  left: ${(props) => props.firstCell && 0};
  border-right: ${(props) => props.firstCell && '1px solid var(--primaryBlack5)'};
  justify-content: ${(props) => !props.firstCell && 'flex-end'};
  display: flex;
  align-items: center;

  ${DashboardHeadInner} {
    height: 100%;
    background: ${(props) =>
      props.isQuarter
        ? `${transparentize(0.9, cssVar('--primaryYellow'))}`
        : props.isYearly
        ? `${transparentize(0.9, cssVar('--primaryPurple'))}`
        : props.isCumulative
        ? `${transparentize(0.3, cssVar('--accentGray'))}`
        : 'var(--accentGrayFourth)'};
    border-top-left-radius: ${(props) => props.firstCell && '16px'};
    width: ${(props) => (props.isQuarter || props.isYearly || props.isCumulative) && '100%'};
    text-align: ${(props) => (props.isQuarter || props.isYearly || props.isCumulative) && 'right'};
  }
`;

export const DashboardCellInner = styled.div`
  height: 100%;
  padding: 7px 10px;
  display: flex;
  align-items: center;
  pointer-events: ${({ isCustomerView }) => isCustomerView && 'none'};

  img {
    margin-left: 6px;
  }
`;

export const ReactTableDashboardCell = styled.div`
  min-width: 110px;
  position: relative;
  padding: ${(props) => (props.firstCell ? (props.totalRow ? '12px' : '7px 6px') : '0 5px')};
  text-align: ${(props) => !props.firstCell && 'right'};
  position: ${(props) => props.firstCell && 'sticky'};
  font-size: ${(props) => (props.isSmallRow || (props.totalRow && props.firstCell)) && '12px'};
  text-transform: ${(props) => props.totalRow && props.firstCell && 'uppercase'};
  padding-left: ${(props) => props.totalRow && props.firstCell && '14px'};
  color: var(--primaryBlack);
  left: ${(props) => props.firstCell && 0};
  pointer-events: ${(props) => props.noPointerEvents && 'none'};
  background-color: ${(props) =>
    props.totalRow || props.breakingTotalRow ? 'var(--accentGrayFourth) !important' : 'white'};
  border-right: ${(props) => props.firstCell && '1px solid var(--primaryBlack5)'};
  border-top-left-radius: ${(props) => props.firstCell && props.firstRow && '16px'};
  border-top: ${(props) => (props.totalRow || props.firstRow || props.headRow) && '1px solid var(--primaryBlack5)'};
  z-index: ${(props) => (props.firstCell ? 5 : 'initial')};
  font-weight: ${(props) => (props.totalRow && props.firstCell ? '900' : props.totalRow ? '700' : 'normal')};
  margin: ${(props) => props.cellWithActiveGoal && '4px 0'};
  background: ${(props) =>
    props.cellWithActiveGoal && props.firstCell
      ? 'var(--accentGray)'
      : props.cellWithActiveGoal
      ? `${transparentize(0.2, cssVar('--accentGray'))}`
      : 'white'};

  ${DashboardCellInner} {
    background: ${(props) =>
      props.isQuarter
        ? `${transparentize(0.9, cssVar('--primaryYellow'))}`
        : props.isYearly
        ? `${transparentize(0.9, cssVar('--primaryPurple'))}`
        : props.isCumulative
        ? `${transparentize(0.3, cssVar('--accentGray'))}`
        : 'none'};
  }
`;

export const SettingsContainer = styled(TableSettingsTitle)`
  text-align: left;
  padding-left: 14px;
  margin: 0;
`;

export const SnapshotTitle = styled.span`
  color: var(--primaryBlack);
  pointer-events: none;
`;

export const SubCatLabel = styled.span`
  font-weight: 900;
  font-size: 9px;
  color: var(--primaryBlack30);
  margin-left: 12px;
  margin-right: 8px;
`;
