import { convertQuartersConfig, getYearAndQuarter, periodKeyToDayJS } from 'utils/dateUtils';

// Returns {'2022-Q1': '2022-03', '2022-Q2': '2022-06', ...} respecting the org's quarters configuration
export const getEndOfQuarters = ({ scenario, quarters, quartersYearOffset }) =>
  Object.keys(scenario ?? {}).reduce((acc, monthKey) => {
    const { year, quarter } = getYearAndQuarter({
      quarterEndMonths: convertQuartersConfig(quarters),
      quartersYearOffset,
      year: parseInt(monthKey.split('-')[0]),
      month: parseInt(monthKey.split('-')[1]),
    });

    const quarterKey = `${year}-Q${quarter}`;

    if (!acc[quarterKey]) {
      // Set the end of quarter month
      const quarterMonth = periodKeyToDayJS({
        periodKey: quarterKey,
        periodType: 'quarter',
        quarters,
        quartersYearOffset,
      }).format('YYYY-MM');

      if (quarterMonth in scenario) {
        acc[quarterKey] = quarterMonth;
      }
    }

    return acc;
  }, {});
