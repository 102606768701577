import { useContext } from 'react';
import dayjs from 'dayjs';
import { isNil } from 'lodash';
import { AppContext } from 'AppContext';
import { Flexer, FlexerColumn } from 'components/Core';
import { NUMBER_FORMATS } from 'consts/global';
import { numberFormatter } from 'utils/formatters';
import { BenchmarkedComparisonWithTooltip, ValuesComparisonWithTooltip } from 'shared/Common';
import {
  Body,
  Sidebar,
  SidebarTitle,
  SidebarSection,
  MetricValue,
  MonthCellsBottom,
  MonthCell,
  MonthCellContent,
  MonthTitle,
  MonthTotal,
  MainMetricsMonthsWrapper,
  MonthCellsCard,
} from './ScenarioCardBody.styles';
import { ScenarioMonthTooltip } from './ScenarioMonthTooltip';

const METRICS_TO_SHOW = {
  new: 'New',
  upsell: 'Upsell',
  churnOrDownsell: 'Downsell / Churn',
};

const NULL_METRIC_DISPLAY = {
  yoy: '',
  variance: 'TBD',
};

const isValid = (x) => !isNil(x) && !isNaN(x);

export const ScenarioCardBody = ({ scenarioMonths, endOfQuarters, onMonthClick }) => {
  const {
    appSettings: { isARR, currencyISOCode: currency },
  } = useContext(AppContext);
  const monthsData = Object.entries(scenarioMonths ?? {}).map(([key, value]) => ({
    month: key,
    isQuarterEnd: Object.values(endOfQuarters).includes(key),
    new: parseFloat(value.new),
    total: parseFloat(value.total),
    lastYearTotal: parseFloat(value.last_year_total),
    upsell: parseFloat(value.upsell),
    churnOrDownsell: parseFloat(value.churn) + parseFloat(value.downsell),
    yoy: parseFloat(value.yoy),
    actual: isNil(value.actual) ? null : parseFloat(value.actual),
    variance: isNil(value.variance) ? null : parseFloat(value.variance),
  }));
  const revenueLabel = isARR ? 'ARR' : 'MRR';

  const renderBottomMetric = ({ metricName, dataType = NUMBER_FORMATS.CURRENCY, withTopBorder }) =>
    monthsData.map((monthData) => (
      <MonthCell key={monthData.month} withTopBorder={withTopBorder}>
        <MonthCellContent>
          <MetricValue darker={isValid(monthData[metricName])}>
            {!isValid(monthData[metricName]) ? (
              NULL_METRIC_DISPLAY[metricName] ?? '-'
            ) : metricName === 'yoy' ? (
              <ValuesComparisonWithTooltip
                percentageToDisplay={monthData.yoy}
                originalValue={monthData.total}
                compareValue={monthData.lastYearTotal}
                originalLabel={`${dayjs(`${monthData.month}-15`).format('MMM YYYY')} ${revenueLabel}`}
                compareLabel={`${dayjs(`${monthData.month}-15`)
                  .subtract(1, 'year')
                  .format('MMM YYYY')} ${revenueLabel}`}
                decimalPlaces={0}
                percentageDifferenceToShowValueComparison={0}
              />
            ) : metricName === 'variance' ? (
              <BenchmarkedComparisonWithTooltip
                accuracy={monthData.variance}
                accuracyThreshold={0.9}
                difference={Math.abs(monthData.total - monthData.lastYearTotal)}
                label={`${revenueLabel} difference`}
                passedText="Your prediction was quite accurate"
                failedText="Your prediction wasn't accurate"
              />
            ) : (
              // metricName === 'actual'
              numberFormatter({ type: dataType, rawValue: monthData[metricName], currency })
            )}
          </MetricValue>
        </MonthCellContent>
      </MonthCell>
    ));

  const MainMetrics = () => {
    return (
      <Flexer>
        <SidebarSection>
          <Sidebar>
            <SidebarTitle>{`${revenueLabel}`} forecasted by months</SidebarTitle>
          </Sidebar>
          <FlexerColumn>
            {Object.entries(METRICS_TO_SHOW).map(([metricName, metricLabel], index) => {
              const isLastRow = index === Object.keys(METRICS_TO_SHOW).length - 1;
              return (
                <MetricValue key={metricName} isSidebar={true}>
                  {metricLabel} {!isLastRow && revenueLabel}
                </MetricValue>
              );
            })}
          </FlexerColumn>
        </SidebarSection>

        <MainMetricsMonthsWrapper>
          {monthsData.map((monthData) => (
            <MonthCellsCard
              key={monthData.month}
              isQuarterEnd={monthData.isQuarterEnd}
              onClick={() => onMonthClick(monthData.month)}
            >
              <ScenarioMonthTooltip key={monthData.month}>
                <FlexerColumn>
                  <MonthCell forHeader isQuarterEnd={monthData.isQuarterEnd}>
                    <FlexerColumn alignItems="flex-end">
                      <MonthTitle>{dayjs(`${monthData.month}-15`).format('MMM YYYY')}</MonthTitle>
                      <MonthTotal>
                        <ValuesComparisonWithTooltip
                          originalValue={monthData.total}
                          compareValue={monthData.lastYearTotal}
                          originalLabel={`${dayjs(`${monthData.month}-15`).format('MMM YYYY')} ${revenueLabel}`}
                          compareLabel={`${dayjs(`${monthData.month}-15`)
                            .subtract(1, 'year')
                            .format('MMM YYYY')} ${revenueLabel}`}
                          decimalPlaces={0}
                          percentageDifferenceToShowValueComparison={0}
                        />
                      </MonthTotal>
                    </FlexerColumn>
                  </MonthCell>

                  {Object.keys(METRICS_TO_SHOW).map((metricName, index) => {
                    const isLastRow = index === Object.keys(METRICS_TO_SHOW).length - 1;
                    return (
                      <MonthCell key={`${monthData.month}-${metricName}`} isLastRow={isLastRow}>
                        <MonthCellContent>
                          <MetricValue>
                            {numberFormatter({
                              type: NUMBER_FORMATS.CURRENCY,
                              rawValue: monthData[metricName],
                              currency,
                            })}
                          </MetricValue>
                        </MonthCellContent>
                      </MonthCell>
                    );
                  })}
                </FlexerColumn>
              </ScenarioMonthTooltip>
            </MonthCellsCard>
          ))}
        </MainMetricsMonthsWrapper>
      </Flexer>
    );
  };

  return (
    <Body>
      <MainMetrics />
      <Flexer>
        <SidebarSection>
          <SidebarTitle>YoY Growth</SidebarTitle>
        </SidebarSection>
        <MonthCellsBottom>
          {renderBottomMetric({ metricName: 'yoy', dataType: NUMBER_FORMATS.PERCENT })}
        </MonthCellsBottom>
      </Flexer>
      <Flexer>
        <SidebarSection withTopBorder>
          <SidebarTitle>Actual {`${revenueLabel}`}</SidebarTitle>
        </SidebarSection>
        <MonthCellsBottom>{renderBottomMetric({ metricName: 'actual', withTopBorder: true })}</MonthCellsBottom>
      </Flexer>
      <Flexer>
        <SidebarSection>
          <SidebarTitle bottom>Variance</SidebarTitle>
        </SidebarSection>
        <MonthCellsBottom>
          {renderBottomMetric({ metricName: 'variance', dataType: NUMBER_FORMATS.PERCENT })}
        </MonthCellsBottom>
      </Flexer>
    </Body>
  );
};
