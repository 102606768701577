import React from 'react';
import { Formik } from 'formik';
import { Row } from 'components/Core';
import { INTEGRATION_METADATA_FIELDS, INTEGRATION_SERVICES } from 'consts/integrations';
import { FormikCustomSelector, FormikOnFormChangeEffect } from 'components/Controls';
import { ReactComponent as DateIcon } from 'images/calendar-time.svg';
import { ReactComponent as TimezoneIcon } from 'images/world.svg';
import { FrequencySelectorContainer, FrequencySelectorsTitle } from './styles';
import {
  EMAIL_DEFAULT_FREQUENCY,
  MONTH_PERIOD_DAY_OPTIONS,
  PERIOD_OPTIONS,
  TIME_OPTIONS,
  WEEK_PERIOD_DAY_OPTIONS,
} from './consts';

export const EmailReportFrequencySelector = ({ updateAnalyticsIntegration, analyticsIntegrations }) => {
  const emailFrequency =
    analyticsIntegrations?.[INTEGRATION_SERVICES.EMAIL]?.metadata[INTEGRATION_METADATA_FIELDS.EMAIL_FREQUENCY] ??
    EMAIL_DEFAULT_FREQUENCY;

  return (
    <FrequencySelectorContainer>
      <Formik initialValues={emailFrequency} enableReinitialize={true}>
        {({ values, setFieldValue }) => (
          <>
            <Row horizontal="space-between">
              <Row horizontal="start">
                <DateIcon width="100%" style={{ marginRight: 8 }} />
                <FrequencySelectorsTitle>Send reports every:</FrequencySelectorsTitle>

                <FormikCustomSelector
                  floatErrors
                  width={values?.period !== PERIOD_OPTIONS.DAY ? '100px' : '260px'}
                  name="period"
                  handleChange={(option) => {
                    setFieldValue('period', option.value);
                    setFieldValue('day', option.value === PERIOD_OPTIONS.MONTH ? 'first_day' : 1);
                  }}
                  options={Object.values(PERIOD_OPTIONS).map((key) => {
                    return { value: key, label: key };
                  })}
                />
              </Row>

              {values?.period !== PERIOD_OPTIONS.DAY && (
                <Row horizontal="start">
                  <FrequencySelectorsTitle>On:</FrequencySelectorsTitle>

                  <FormikCustomSelector
                    floatErrors
                    noSort
                    width="185px"
                    placeholder="Select a day"
                    name="day"
                    options={
                      values?.period === PERIOD_OPTIONS.WEEK ? WEEK_PERIOD_DAY_OPTIONS : MONTH_PERIOD_DAY_OPTIONS
                    }
                  />
                </Row>
              )}

              <Row horizontal="start">
                <FrequencySelectorsTitle>At:</FrequencySelectorsTitle>

                <FormikCustomSelector
                  floatErrors
                  noSort
                  width={values?.period !== PERIOD_OPTIONS.DAY ? '115px' : '190px'}
                  name="time"
                  options={TIME_OPTIONS}
                />
              </Row>
            </Row>

            <Row style={{ marginTop: 20 }} horizontal="start">
              <TimezoneIcon width={28} style={{ marginRight: 8 }} />
              <FrequencySelectorsTitle>Timezone:</FrequencySelectorsTitle>

              <FormikCustomSelector
                name="timezone"
                width="560px"
                options={Intl.supportedValuesOf('timeZone').map((zone) => {
                  return { value: zone, label: zone };
                })}
              />
            </Row>
            <FormikOnFormChangeEffect
              onChange={() =>
                updateAnalyticsIntegration({
                  service: INTEGRATION_SERVICES.EMAIL,
                  additionalMetadata: {
                    [INTEGRATION_METADATA_FIELDS.EMAIL_FREQUENCY]: values,
                  },
                })
              }
            />
          </>
        )}
      </Formik>
    </FrequencySelectorContainer>
  );
};
