import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export const getChartData = ({ rawStatistics }) =>
  Object.entries(rawStatistics).reduce((acc, [monthKey, value]) => {
    acc[monthKey] = {
      Collected: value.paidRevenue,
      Sent: value.sentRevenue,
      Overdue: value.overdueRevenue,
      'New to send': value.newToSendRevenue,
    };
    return acc;
  }, {});
