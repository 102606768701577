import dayjs from 'dayjs';

import { numberFormatter } from 'utils/formatters';
import { generateMonths, getPreviousMonth } from 'utils/dateUtils';
import { BarChart } from 'components/Graph';
import { NUMBER_FORMATS } from 'consts/global';
import { PipelineToBeClosedChartCard, PipelineToBeClosedChartTitle } from './styles';

export const PipelineToBeClosedChart = ({ values }) => {
  const {
    start_date,
    end_date,
    start_generating_new_pipeline_after_months,
    average_new_recurring_revenue_growth,
    average_pipeline_recurring_revenue,
  } = values;

  const forecastMonths = generateMonths({
    startDate: start_date.toDate(),
    endDate: end_date.toDate(),
    inclusive: true,
  });

  // Same as BE getMaxExpectedPipelineRecurringRevenue()
  const maxExpectedPipelineRecurringRevenue = forecastMonths.reduce((acc, monthKey, index) => {
    acc[monthKey] =
      index === 0
        ? average_pipeline_recurring_revenue
        : acc[getPreviousMonth(monthKey)] * (1 + average_new_recurring_revenue_growth / 100) ?? 0;

    return acc;
  }, {});

  // Similar to BE getNewPipelineToBeClosed
  const maxPipelineToBeClosed = forecastMonths.reduce((acc, monthKey, index) => {
    acc[monthKey] =
      isNaN(parseInt(start_generating_new_pipeline_after_months)) || index < start_generating_new_pipeline_after_months
        ? 0
        : maxExpectedPipelineRecurringRevenue[monthKey] ?? 0;

    return acc;
  }, {});

  const totalRevenue = Object.values(maxPipelineToBeClosed).reduce((acc, amount) => acc + amount);

  return (
    <PipelineToBeClosedChartCard>
      <PipelineToBeClosedChartTitle>
        {numberFormatter({ type: NUMBER_FORMATS.CURRENCY, rawValue: totalRevenue })}: max <span> new pipeline </span>{' '}
        will be closed in {dayjs(start_date).format('MMM YYYY')} - {dayjs(end_date).format('MMM YYYY')}
      </PipelineToBeClosedChartTitle>

      <BarChart data={maxPipelineToBeClosed} height={110} />
    </PipelineToBeClosedChartCard>
  );
};
