import React, { useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as MenuIcon } from 'images/menu.svg';
import { useClickOutside } from 'utils/hooks';
import { Popover, PopoverItemsContainer, PopoverListItem, PopoverWrapper } from 'components/Portal';
import { CircleButton } from 'components/Buttons';

const UnconfirmedTransactionsCounter = styled.div`
  font-weight: 900;
  font-size: 9px;
  line-height: 12px;
  padding: 2px 6px;
  background-color: var(--primaryYellow15);
  position: absolute;
  top: ${(props) => (props.topRightPosition ? '-8px' : '12px')};
  right: ${(props) => (props.topRightPosition ? '-12px' : '12px')};
  color: var(--secondaryYellow);
  border-radius: 100px;
`;

const ListItemIcon = styled.div`
  margin-right: 2px;
  opacity: 0.7;
  display: flex;
  align-items: center;
`;

const OpenedDropdown = ({ actions, width, unconfirmedTransactionsCount, dropdownXOffset }) => (
  <Popover data-cy="actions-popover__dropdown" width={width ?? '160px'} XOffset={dropdownXOffset ?? -45}>
    {actions && (
      <PopoverItemsContainer>
        {Object.entries(actions).map(
          ([actionName, iconAndCb]) =>
            actionName &&
            actionName !== 'null' &&
            !!iconAndCb && (
              <PopoverListItem key={actionName} onClick={iconAndCb?.cb} data-cy={actionName}>
                <ListItemIcon>{iconAndCb?.icon}</ListItemIcon>

                {actionName}

                {!!unconfirmedTransactionsCount && actionName === 'Bulk Process' && (
                  <UnconfirmedTransactionsCounter>{unconfirmedTransactionsCount}</UnconfirmedTransactionsCounter>
                )}
              </PopoverListItem>
            ),
        )}
      </PopoverItemsContainer>
    )}
  </Popover>
);

export const ActionsPopover = ({
  actions,
  width,
  unconfirmedTransactionsCount,
  dropdownXOffset,
  showHoverText = true,
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useClickOutside(() => setShowDropdown(false));

  return (
    <PopoverWrapper ref={dropdownRef}>
      <CircleButton
        data-cy="actions-popover__toggler"
        active={showDropdown}
        onClick={() => setShowDropdown(!showDropdown)}
        icon={<MenuIcon />}
        hoverText={showHoverText && 'Other Actions'}
        XOffset={-55}
      />
      {!!unconfirmedTransactionsCount && (
        <UnconfirmedTransactionsCounter topRightPosition={true}>
          {unconfirmedTransactionsCount}
        </UnconfirmedTransactionsCounter>
      )}
      {showDropdown && (
        <OpenedDropdown
          width={width}
          actions={actions}
          unconfirmedTransactionsCount={unconfirmedTransactionsCount}
          dropdownXOffset={dropdownXOffset}
        />
      )}
    </PopoverWrapper>
  );
};
