import { useQuery } from 'react-query';
import { getCounts, getOrganizationMetadata } from './requests';

export const ORGANIZATION_HELPERS_CACHE_KEY = 'organizationHelpers';

export const useOrganizationCountsAPI = ({ orgId, scopes }) => {
  const { data, error, isLoading, isFetching, refetch } = useQuery(
    [ORGANIZATION_HELPERS_CACHE_KEY, orgId, scopes],
    () => getCounts({ orgId, scopes }),
    { enabled: true },
  );

  return {
    data,
    error,
    isLoading,
    isFetching,
    operations: {
      refetch,
    },
  };
};

export const useOrganizationMetadataAPI = ({ orgId }) => {
  const { data, error, isLoading, isFetching, refetch } = useQuery(
    [ORGANIZATION_HELPERS_CACHE_KEY, orgId],
    () => getOrganizationMetadata({ orgId }),
    { enabled: true },
  );

  return {
    data,
    error,
    isLoading,
    isFetching,
    operations: {
      refetch,
    },
  };
};
