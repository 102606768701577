import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Modal, ModalCloseIcon, ModalContainer } from 'components/Modal';
import { AlarmIcon } from 'components/Icons';
import { SubmitButton } from 'components/Buttons';
import { ReactComponent as DownloadSuccessIcon } from 'images/download-success.svg';
import { ReactComponent as FileDownloadIcon } from 'images/download.svg';
import { pluralize } from 'utils/stringUtils';
import { downloadBlobFile } from 'utils/export';
import { PercentageProgressBar } from 'components/Loaders';

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  margin: 28px auto;
  padding: 16px 0;
  align-items: center;
  justify-content: center;
`;

const DownloadTitle = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 4px;

  b {
    font-size: 900;
  }
`;

const Subtitle = styled.div`
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  text-align: center;
  line-height: 16px;
  opacity: 0.7;
  max-width: 370px;
`;

const FinishTitle = styled.div`
  margin-bottom: 4px;
  margin-top: 20px;
  font-size: 14px;
  font-style: normal;
  font-weight: 900;
  line-height: 20px;
`;

export const BulkPdfDownloadModal = ({
  invoicesForDownloadCount,
  setInvoicesForDownloadCount,
  fileResponse,
  setFileResponse,
}) => {
  const [downloadedCount, setDownloadedCount] = useState(0);

  useEffect(() => {
    if (invoicesForDownloadCount) {
      setDownloadedCount(1); // Start with the first invoice

      const interval = setInterval(() => {
        setDownloadedCount((prevCount) => {
          if (prevCount >= invoicesForDownloadCount) {
            clearInterval(interval);
            return invoicesForDownloadCount;
          }
          return prevCount + 1;
        });
      }, 3000);

      return () => clearInterval(interval);
    }
  }, [invoicesForDownloadCount]);

  const progress = invoicesForDownloadCount ? (downloadedCount / invoicesForDownloadCount) * 100 : 0;

  return (
    <>
      {invoicesForDownloadCount || fileResponse ? (
        <ModalContainer data-cy="bulk-pdf-download-modal">
          <Modal padding={8} height="auto" width="540px">
            <ModalCloseIcon
              onClose={() => {
                setInvoicesForDownloadCount(null);
                setFileResponse(null);
                setDownloadedCount(0);
              }}
            />
            <ModalContent>
              {!fileResponse ? (
                <>
                  <AlarmIcon width={24} height={24} style={{ marginBottom: 20 }} />

                  <DownloadTitle>
                    Downloading invoice PDFs:{' '}
                    <b>
                      {downloadedCount} / {invoicesForDownloadCount}
                    </b>
                  </DownloadTitle>

                  <Subtitle>It may take some time to prepare all the files</Subtitle>

                  <PercentageProgressBar progress={progress} />
                </>
              ) : (
                <>
                  <DownloadSuccessIcon />

                  <FinishTitle>Your archive with {pluralize(invoicesForDownloadCount, 'invoice')} is ready</FinishTitle>
                  <Subtitle>
                    The archive should already be on your device. If you can't locate it, click the button below to
                    download it again.
                  </Subtitle>

                  <SubmitButton
                    data-cy="bulk-pdf-download-modal__download-again-button"
                    style={{ fontSize: 12, marginTop: 20 }}
                    onClick={() =>
                      downloadBlobFile({
                        file: fileResponse?.data,
                        filename: fileResponse?.name,
                        rawName: true,
                      })
                    }
                  >
                    <b>Download again</b>
                    <FileDownloadIcon style={{ marginLeft: 8 }} />
                  </SubmitButton>
                </>
              )}
            </ModalContent>
          </Modal>
        </ModalContainer>
      ) : null}
    </>
  );
};
