import axios from 'axios';
import { API_URL } from 'api/consts';

export const getFileByKey = async ({ orgId, key }) => {
  const url = `${API_URL}/organizations/${orgId}/files/key/${key}`;
  const { data } = await axios.get(url);
  return data;
};

export const uploadFile = async ({ orgId, file }) => {
  const url = `${API_URL}/organizations/${orgId}/files/upload`;
  const { data } = await axios.post(url, file);
  return data;
};
