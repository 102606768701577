import styled from 'styled-components';

export const Queue = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
`;

export const Reminder = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: -8px;
`;

export const AiRegenerateButton = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  color: var(--primaryBlue);
  cursor: pointer;
  margin-right: 4px;

  svg {
    margin-right: 4px;
  }

  &:hover {
    color: var(--secondaryBlue);
  }
`;

export const ReminderNumber = styled.div`
  font-weight: 900;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border: 1px solid var(--accentGraySecond);
  box-shadow: 4px 4px 24px var(--primaryBlack4);
  border-radius: 100px;
  background-color: ${({ active }) => active && 'var(--primaryBlue10)'};
  cursor: pointer;

  &:hover {
    box-shadow: 0px 4px 20px var(--primaryBlack10);
  }
`;

export const ReminderSkipButton = styled.div`
  font-weight: 900;
  font-size: 10px;
  line-height: 12px;
  margin-left: 4px;
  padding: 4px 8px;
  border: 1px solid var(--primaryBlack10);
  border-radius: 100px;
  cursor: pointer;

  &:hover {
    background-color: var(--primaryBlack5);
  }
`;
