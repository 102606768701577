import React, { useCallback, useContext, useState } from 'react';
import styled from 'styled-components';
import { Form, Formik } from 'formik';

import { AppContext } from 'AppContext';
import { ReactComponent as HeaderIcon } from 'images/info-circle-icon.svg';
import { Modal, ModalBody, ModalButton, ModalContainer, ModalFooter, ModalHeader, ModalTitle } from 'components/Modal';
import { FormikCustomSelector, InputLabel, SelectField } from 'components/Controls';
import { FlexerColumn, Spacer } from 'components/Core';
import { PRODUCT_IMPORT_METADATA_KEYS, SERVICE_WITH_INCOME_ACCOUNT_REF } from 'views/Billing/consts';
import { INTEGRATION_TYPES } from 'consts/integrations';
import { useImportsAPI } from 'api/imports';
import { groupBy } from 'utils/arrayUtils';
import { getIntegrationDisplayName, getServiceCategory } from 'models/integration';
import { IntegrationProductSelector } from 'shared/IntegrationProductSelector';
import { EditCircleIcon } from 'components/Icons';
import { IncomeAccountSelector } from 'shared/IncomeAccountSelector';

import { ProductsModalHeaderIcon, ProductsModalListName, ProductsModalListWrapper } from './styles';

const StyledEditCircleIcon = styled(EditCircleIcon)`
  opacity: 0.8;

  &:hover {
    cursor: pointer;
  }
`;

const LinkGeneralLedgerModal = ({ productImportData, productName, index, onClose, onLink, glIntegrationOptions }) => {
  const [glProductId, setGlProductId] = useState(productImportData?.provider_object_id);
  const [glProductName, setGlProductName] = useState(productImportData?.metadata?.product_name);
  const [glProductCode, setGlProductCode] = useState(productImportData?.metadata?.code);

  const handleLink = () => {
    onLink({ glProductId, glProductName, glProductCode, integrationId: productImportData?.integration_id });
    onClose();
  };

  return (
    <ModalContainer>
      <Modal maxWidth="400px" height="fit-content" overflow="visible" compact>
        <ModalHeader>
          <ModalTitle compact>
            <b>Link {productName}</b>
          </ModalTitle>
        </ModalHeader>
        <ModalBody compact centered>
          <FlexerColumn gap="6px" padding="0px 10px">
            <FormikCustomSelector
              name={`productsWithoutGlLink.${index}.importData.integration_id`}
              options={glIntegrationOptions.map((integration) => ({
                label: `${getIntegrationDisplayName(integration)} / ${integration.id}`,
                value: integration.id,
              }))}
              label="General Ledger"
              placeholder="Select a GL..."
              containerWidth="100%"
            />

            <IntegrationProductSelector
              name={`productsWithoutGlLink.${index}.importData.provider_object_id`}
              integrationId={productImportData?.integration_id}
              productImportName={glProductName}
              productImportProviderId={glProductId}
              label={`General ledger product${!productImportData?.integration_id ? ' (select a GL first)' : ''}`}
              handleChange={({ providerId, name, code }) => {
                setGlProductId(providerId);
                setGlProductName(name);
                setGlProductCode(code);
              }}
            />
          </FlexerColumn>
        </ModalBody>
        <ModalFooter centered transparent>
          <ModalButton onClick={onClose}>Cancel</ModalButton>
          <ModalButton className="primary" onClick={handleLink}>
            Link
          </ModalButton>
        </ModalFooter>
      </Modal>
    </ModalContainer>
  );
};

export const ProductsBulkUpdateIncomeAccountModal = ({ products, onClose, onSuccess }) => {
  const { orgId, integrations } = useContext(AppContext);

  const [selectedProductIdToLink, setSelectedProductIdToLink] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);

  const glIntegrations = integrations?.filter(
    (integration) =>
      integration.type === INTEGRATION_TYPES.GL &&
      SERVICE_WITH_INCOME_ACCOUNT_REF.includes(getServiceCategory(integration.service)),
  );

  const {
    data: productImports,
    isLoading,
    operations: { bulkUpsertImport },
  } = useImportsAPI({
    orgId,
    filters: {
      chifferObjectName: 'product',
      chifferObjectIds: products?.map((product) => product.id)?.filter((id) => !!id) ?? [],
      integrationIds: glIntegrations?.map(({ id }) => id),
    },
    autoFetch: !!products && !!glIntegrations,
  });

  const glIntegrationById = groupBy(glIntegrations ?? [], 'id', { uniqueness: true });
  const productImportsByProductId = groupBy(productImports ?? [], 'chiffer_object_id');
  const productsWithoutGlLink = [];
  const productsByIntegrationId = (products ?? []).reduce((obj, product) => {
    const productImports = productImportsByProductId[product.id] ?? [];
    if (productImports.length) {
      for (const productImport of productImports) {
        // make sure that the product import integration_id exisits in glIntegrationById
        const integrationData = glIntegrationById[productImport.integration_id];
        const key = integrationData?.id;

        if (key) {
          const data = {
            ...product,
            importData: productImport,
          };
          if (obj[key]) obj[key].push(data);
          else obj[key] = [data];
        } else {
          productsWithoutGlLink.push(product);
        }
      }
    } else {
      productsWithoutGlLink.push(product);
    }

    return obj;
  }, {});

  const handleLinkToGL = ({
    glProductId,
    glProductName,
    glProductCode,
    integrationId,
    setFieldValue,
    product,
    values,
  }) => {
    if (glProductId && integrationId) {
      setFieldValue(
        'productsWithoutGlLink',
        values.productsWithoutGlLink.filter(({ id }) => id !== product.id),
      );

      setFieldValue(`productsByIntegrationId.${integrationId}`, [
        ...(values.productsByIntegrationId[integrationId] ?? []),
        {
          ...product,
          importData: {
            chiffer_object_name: 'product',
            chiffer_object_id: product.id,
            provider_object_id: glProductId,
            integration_id: integrationId,
            metadata: {
              product_name: glProductName,
              code: glProductCode,
            },
          },
        },
      ]);
    }
  };

  const handleConfirmClick = async (values) => {
    setIsUpdating(true);

    const dataToUpdate = Object.entries(values.productsByIntegrationId)
      .flatMap(([integrationId, products]) =>
        products.map((product) => {
          const incomeAccountId = values.incomeAccountByIntegrationId[integrationId];
          const { importData } = product;
          return incomeAccountId && importData
            ? {
                ...importData,
                metadata: {
                  ...importData.metadata,
                  [PRODUCT_IMPORT_METADATA_KEYS.INCOME_ACCOUNT]: incomeAccountId,
                },
              }
            : null;
        }),
      )
      .filter((data) => !!data);

    if (dataToUpdate) {
      await bulkUpsertImport.mutateAsync({
        data: dataToUpdate,
      });

      onSuccess && (await onSuccess());
    }

    setIsUpdating(false);

    onClose();
  };

  return (
    <>
      <ModalContainer>
        <Modal maxWidth="400px" height="70vh" overflow="visible" compact>
          <Formik
            initialValues={{
              productsWithoutGlLink,
              productsByIntegrationId,
              incomeAccountByIntegrationId: {},
            }}
            enableReinitialize
            onSubmit={handleConfirmClick}
          >
            {({ values, setFieldValue, submitForm }) => (
              <Form>
                <ProductsModalHeaderIcon>
                  <HeaderIcon width="20px" height="20px" />
                </ProductsModalHeaderIcon>
                <ModalHeader>
                  <ModalTitle compact>
                    <b>Edit Income Account</b>
                  </ModalTitle>
                </ModalHeader>
                <ModalBody compact centered>
                  <Spacer height="16px" />
                  {!!values.productsWithoutGlLink.length && (
                    <>
                      <InputLabel>Products not yet linked to the GL ({values.productsWithoutGlLink.length})</InputLabel>
                      <ProductsModalListWrapper>
                        {values.productsWithoutGlLink.map((product, index) => (
                          <FlexerColumn gap="6px" key={product.id}>
                            <ProductsModalListName>
                              {product.name}{' '}
                              <StyledEditCircleIcon onClick={() => setSelectedProductIdToLink(product.id)} />
                            </ProductsModalListName>

                            {selectedProductIdToLink === product.id && (
                              <LinkGeneralLedgerModal
                                productImportData={values.productsWithoutGlLink[index]?.importData}
                                productName={product.name}
                                index={index}
                                onClose={() => setSelectedProductIdToLink(null)}
                                onLink={({ glProductId, glProductName, glProductCode, integrationId }) =>
                                  handleLinkToGL({
                                    glProductId,
                                    glProductName,
                                    glProductCode,
                                    integrationId,
                                    setFieldValue,
                                    values,
                                    product,
                                  })
                                }
                                glIntegrationOptions={glIntegrations}
                              />
                            )}
                          </FlexerColumn>
                        ))}
                      </ProductsModalListWrapper>
                      <Spacer height="16px" />
                    </>
                  )}
                  {Object.entries(values.productsByIntegrationId).map(([integrationId, products]) => (
                    <React.Fragment key={integrationId}>
                      <InputLabel>
                        {getIntegrationDisplayName(glIntegrationById[integrationId])} Products ({products.length})
                      </InputLabel>

                      <SelectField
                        height="100%"
                        label={`Income Account for ${getIntegrationDisplayName(glIntegrationById[integrationId])}`}
                      >
                        <IncomeAccountSelector
                          integrationId={integrationId}
                          name={`incomeAccountByIntegrationId.${integrationId}`}
                          label=""
                        />
                      </SelectField>

                      <ProductsModalListWrapper>
                        {products.map((product) => (
                          <ProductsModalListName key={product.id}>{product.name}</ProductsModalListName>
                        ))}
                      </ProductsModalListWrapper>

                      <Spacer height="16px" />
                    </React.Fragment>
                  ))}
                </ModalBody>
                <ModalFooter centered transparent>
                  <ModalButton onClick={onClose}>Cancel</ModalButton>
                  <ModalButton
                    className="primary"
                    onClick={
                      isLoading || isUpdating
                        ? undefined
                        : (e) => {
                            e.preventDefault();
                            submitForm();
                          }
                    }
                    disabled={isLoading || isUpdating}
                    data-cy="products-bulk-update-income-account-modal__submit-button"
                  >
                    Confirm
                  </ModalButton>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </Modal>
      </ModalContainer>
    </>
  );
};

const useProductsBulkUpdateIncomeAccountModal = ({ onSuccess } = {}) => {
  const [products, setProducts] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const openModal = useCallback(() => setShowModal(true), [setShowModal]);
  const closeModal = useCallback(() => setShowModal(false), [setShowModal]);

  const Modal = useCallback(
    () =>
      showModal ? (
        <ProductsBulkUpdateIncomeAccountModal products={products} onClose={closeModal} onSuccess={onSuccess} />
      ) : (
        <></>
      ),
    [showModal, products, closeModal, onSuccess],
  );

  return {
    openModal: (products) => {
      setProducts(products);
      openModal();
    },
    ProductsBulkUpdateIncomeAccountModal: Modal,
  };
};

export { useProductsBulkUpdateIncomeAccountModal };
