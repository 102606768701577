import axios from 'axios';
import { API_URL } from 'api/consts';

export const saveConfigsFromWizard = async ({ orgId, data }) => {
  const url = `${API_URL}/organizations/${orgId}/configs/wizard`;
  const response = await axios.post(url, data);
  return response.data;
};

export const getConfigs = async ({ orgId, configsToFetch, service, includeAllAppSettings }) => {
  const url = `${API_URL}/organizations/${orgId}/configs`;
  const { data } = await axios.get(url, { params: { configsKeys: configsToFetch, service, includeAllAppSettings } });
  return data;
};

export const updateConfigs = async ({ orgId, data, integrationId }) => {
  const url = `${API_URL}/organizations/${orgId}/configs`;
  const response = await axios.patch(url, data, { params: { integrationId } });
  return response.data;
};

export const updateConfigByKey = async ({ orgId, key, value }) => {
  const url = `${API_URL}/organizations/${orgId}/configs/${key}`;
  const { data } = await axios.patch(url, { value });
  return data;
};

export const getJobStatus = async ({ orgId, jobId }) => {
  const url = `${API_URL}/organizations/${orgId}/jobs/${jobId}`;
  const { data } = await axios.get(url);
  return data;
};

export const getMetadataSegments = async ({ orgId, params }) => {
  const url = `${API_URL}/organizations/${orgId}/metadata-segments`;
  const { data } = await axios.get(url, { params });
  return data;
};

export const replaceAllMetadataSegments = async ({ orgId, customerMetadataSegments, transactionMetadataSegments }) => {
  const url = `${API_URL}/organizations/${orgId}/metadata-segments/all`;
  const { data } = await axios.put(url, { customerMetadataSegments, transactionMetadataSegments });
  return data;
};

export const upsertMetadataSegment = async ({ orgId, metadataSegment }) => {
  const url = `${API_URL}/organizations/${orgId}/metadata-segments`;
  const { key, value, bucketType: bucket_type, objectType: object_type } = metadataSegment;
  const { data } = await axios.put(url, { key, value, bucket_type, object_type });
  return data;
};
