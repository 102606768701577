import React, { useContext, useMemo } from 'react';
import dayjs from 'dayjs';
import { AppContext } from 'AppContext';
import { useAnalytics } from 'utils/hooks';
import { Flexer } from 'components/Core';
import { HomeIcon } from 'components/Icons';
import { ReactComponent as PlusIcon } from 'images/plus-icon.svg';
import { ReactComponent as AlertIcon } from 'images/alert-triangle-icon.svg';
import { useInvoicesStatusCountAPI } from 'api/billing';
import { BILLING_PAGES_ROUTES, INVOICE_STATUSES } from 'views/Billing/consts';
import { SPREAD_REPORT_TYPE } from 'views/Spreads/utils';
import { EVENTS } from 'consts/analytics';
import { PopoverLinkContents, PopoverLinkContentsItem } from '../PopoverLinkContents';
import { ProblemsCounter } from '../styles';
import { UsageBasedEngineItem } from './UsageBasedEngineItem';
import { InvoicesItem } from './InvoicesItem';
import { OverdueWarning, RelativeContainer, TagContainer } from './styles';

export const BillingPopover = ({ onClose }) => {
  const title = 'Billing';
  const { trackEvent } = useAnalytics();
  const { orgId } = useContext(AppContext);

  const storageInvoicesFilters = localStorage.getItem('invoices-table__filters');
  const invoicesFilters = JSON.parse(storageInvoicesFilters);

  const { data: invoicesStatusCount } = useInvoicesStatusCountAPI({
    orgId,
    params: {
      startDate: invoicesFilters?.startMonth ? dayjs(invoicesFilters?.startMonth).toDate() : null,
      endDate: invoicesFilters?.endMonth ? dayjs(invoicesFilters?.endMonth).toDate() : null,
    },
  });

  const invoicesByStatusCount = useMemo(() => {
    const countByStatus =
      invoicesStatusCount?.reduce(
        (acc, { invoice_status, count }) => ({ ...acc, [invoice_status]: parseInt(count ?? 0) }),
        {},
      ) ?? {};

    // In the invoices table we have only "Sent & Awaiting payment" status that includes payment failed and auto-charge invoices
    countByStatus.sent =
      (countByStatus?.sent ?? 0) + (countByStatus?.['payment-failed'] ?? 0) + (countByStatus?.['auto-charge'] ?? 0);

    return countByStatus;
  }, [invoicesStatusCount]);

  return (
    <PopoverLinkContents minWidth="290px" title={title}>
      <PopoverLinkContentsItem
        title={title}
        item={{
          titlePrefix:
            invoicesByStatusCount[INVOICE_STATUSES.TRANSACTIONS_THAT_NEED_SCHEDULES] > 0 ? <AlertIcon /> : <PlusIcon />,
          title: 'Create New Invoices',
          titleSuffix: invoicesByStatusCount[INVOICE_STATUSES.TRANSACTIONS_THAT_NEED_SCHEDULES] ? (
            <ProblemsCounter>
              {invoicesByStatusCount[INVOICE_STATUSES.TRANSACTIONS_THAT_NEED_SCHEDULES]}
            </ProblemsCounter>
          ) : null,
          to: {
            pathname: BILLING_PAGES_ROUTES.REVIEW_TRANSACTIONS,
          },
        }}
        onClick={() => {
          trackEvent({ name: EVENTS.CLICK_BILLING_CREATE_NEW_INVOICES });
        }}
        highlighted={invoicesByStatusCount[INVOICE_STATUSES.TRANSACTIONS_THAT_NEED_SCHEDULES] > 0}
      />
      {invoicesByStatusCount[INVOICE_STATUSES.INVOICING_SCHEDULES_EXTERNAL_UPDATES] > 0 && (
        <PopoverLinkContentsItem
          title={title}
          item={{
            titlePrefix: <AlertIcon />,
            title: 'Review Existing Invoices',
            description: 'Handling changes to invoiced transactions',
            titleSuffix: (
              <ProblemsCounter data-cy="invoices-external-updates-popover__counter">
                {invoicesByStatusCount[INVOICE_STATUSES.INVOICING_SCHEDULES_EXTERNAL_UPDATES]}
              </ProblemsCounter>
            ),
            to: {
              pathname: BILLING_PAGES_ROUTES.SCHEDULES_EXTERNAL_UPDATES,
            },
          }}
          onClick={() => {
            trackEvent({ name: EVENTS.CLICK_BILLING_SCHEDULES_EXTERNAL_UPDATES });
          }}
          highlighted
        />
      )}
      <PopoverLinkContentsItem
        title={title}
        item={{
          title: 'Billing Dashboard',
          description: 'Never miss your invoices and get paid faster',
          to: {
            pathname: BILLING_PAGES_ROUTES.DASHBOARD,
          },
          icon: <HomeIcon />,
        }}
        onClick={() => {
          trackEvent({ name: EVENTS.CLICK_BILLING_DASHBOARD });
        }}
      />
      <UsageBasedEngineItem title={title} onClose={onClose} />
      <InvoicesItem title={title} invoicesByStatusCount={invoicesByStatusCount} onClose={onClose} />
      <Flexer>
        {invoicesByStatusCount[INVOICE_STATUSES.ALL_FAILED] > 0 && (
          <OverdueWarning
            to={{ pathname: BILLING_PAGES_ROUTES.INVOICES, state: { status: INVOICE_STATUSES.ALL_FAILED } }}
            onClick={() => {
              trackEvent({ name: EVENTS.CLICK_BILLING_FAILED_INVOICES });
              onClose();
            }}
          >
            <span>{`${invoicesByStatusCount[INVOICE_STATUSES.ALL_FAILED]} failed`}</span>{' '}
            {`invoice${invoicesByStatusCount[INVOICE_STATUSES.ALL_FAILED] > 1 ? 's' : ''}`}
          </OverdueWarning>
        )}

        {invoicesByStatusCount[INVOICE_STATUSES.OVERDUE] > 0 && (
          <OverdueWarning
            to={{ pathname: BILLING_PAGES_ROUTES.INVOICES, state: { status: INVOICE_STATUSES.SENT } }}
            onClick={() => {
              trackEvent({ name: EVENTS.CLICK_BILLING_OVERDUE_INVOICES });
              onClose();
            }}
          >
            <span>{`${invoicesByStatusCount[INVOICE_STATUSES.OVERDUE]} overdue`}</span>{' '}
            {`invoice${invoicesByStatusCount[INVOICE_STATUSES.OVERDUE] > 1 ? 's' : ''}`}
          </OverdueWarning>
        )}
      </Flexer>
      <RelativeContainer>
        <TagContainer top="2px">reports</TagContainer>
      </RelativeContainer>
      <PopoverLinkContentsItem
        title={title}
        item={{
          title: 'A/R Aging Report',
          to: {
            pathname: BILLING_PAGES_ROUTES.AR_AGING_REPORT,
          },
        }}
        onClick={() => {
          trackEvent({ name: EVENTS.CLICK_BILLING_AR_AGING_REPORT });
        }}
      />
      <PopoverLinkContentsItem
        title="spreads"
        item={{
          title: 'Accounting Revenue Report',
          to: {
            pathname: `/spreads`,
            state: { spreadReportType: SPREAD_REPORT_TYPE.Accounting },
          },
        }}
      />
      <PopoverLinkContentsItem
        title={title}
        item={{
          title: 'Billing Inconsistencies Report',
          to: {
            pathname: `/billing-inconsistencies-report`,
          },
        }}
      />
      <PopoverLinkContentsItem
        title={title}
        item={{
          title: 'RevRec Exceptions Report',
          to: {
            pathname: `/rev-rec-exceptions-report`,
          },
        }}
      />
    </PopoverLinkContents>
  );
};
