import React from 'react';
import dayjs from 'dayjs';
import styled from 'styled-components';

import { NUMBER_FORMATS } from 'consts/global';
import { useCurrencyNumberFormatter } from 'utils/hooks';

import TooltipContainer from 'components/Tooltip/Tooltip';
import { ReactComponent as CustomersIcon } from 'images/case-drilldown.svg';
import { ReactComponent as DividerIcon } from 'images/file-info.svg';
import { ToolTipContentContainer, ToolTipRow, ToolTipLabel, ToolTipValue } from 'views/RevenueDetails/styled';

const TotalRevenueContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 8px;
  border: 1px solid var(--accentGraySecond);
  border-radius: 16px;
  width: 100%;
  max-width: 300px;
  margin-left: 20px;
  overflow: hidden;
`;

const StyledDividerIcon = styled(DividerIcon)`
  position: relative;
  left: -4px;
  width: 24px;
  height: 24px;
  margin-top: -30px;
  z-index: 2;
  padding: 0 4px;
  background-color: white;
`;

const TopContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  padding: 8px 16px;
`;

const Title = styled.span`
  font-weight: 900;
  font-size: 10px;
  line-height: 14px;
  opacity: 0.3;
  margin-bottom: 12px;
`;

const TotalRevenue = styled.span`
  font-size: 16px;
  font-weight: 900;
  margin-bottom: 27px;
`;

const Divider = styled.hr`
  border: none;
  position: relative;
  height: 1px;
  margin-bottom: 18px;
  z-index: 1;
  background-color: var(--primaryBlack5);
  width: 130%;
  left: -20px;
`;

const ToolTipDivider = styled(Divider)`
  margin-bottom: 0;
  width: 100%;
  left: 0;
  background-color: white;
  opacity: 0.05;
`;

const Row = styled.div`
  margin-top: 8px;
`;

const Value = styled.span`
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  margin-right: 4px;
`;

export const PercentChange = styled.span`
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.color};
  font-size: 10px;
  font-weight: bold;
  padding: 2px 4px;
  border-radius: 4px;
  margin-left: 8px;

  svg {
    margin-left: 4px;
  }
`;

const GreyLabel = styled.span`
  color: var(--primaryBlack50);
  font-size: 12px;
`;

const CustomerContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 12px 18px;
  background-color: white;
  border: 1px solid var(--accentGraySecond);
  box-shadow: 4px 4px 24px var(--primaryBlack4);
  border-radius: 10px;
`;

export const SpreadsSummaryCard = ({ spreadsData, selectedMonth = null }) => {
  const numberFormatter = useCurrencyNumberFormatter();
  if (Object.keys(spreadsData).length === 0) {
    return null;
  }

  const { totalRevenue, totalCustomers, totalAmountsEachMonth, revenueRowsByCustomer } = spreadsData;

  const months = totalAmountsEachMonth ? Object.keys(totalAmountsEachMonth) : [];
  const selectedIndex = selectedMonth !== null ? selectedMonth : months.length - 1;

  const startingMonth = months[0];
  const currentMonth = months[selectedIndex];

  const monthAmounts = totalAmountsEachMonth ? Object.values(totalAmountsEachMonth) : [];
  const startingAmount = monthAmounts[0];
  const currentAmount = monthAmounts[selectedIndex];
  const revenueGrowth = currentAmount / startingAmount - 1;

  const startingCustomers = new Set();
  const endingCustomers = new Set();
  for (const customerId of Object.keys(revenueRowsByCustomer)) {
    const customerAmounts = Object.values(revenueRowsByCustomer[customerId]);
    const startingMonthAmount = customerAmounts[0];
    const endMonthAmount = customerAmounts[selectedIndex];

    if (startingMonthAmount > 0) {
      startingCustomers.add(customerId);
    }
    if (endMonthAmount > 0) {
      endingCustomers.add(customerId);
    }
  }

  const customerGrowth = endingCustomers.size / startingCustomers.size - 1;

  const customerRetentionContent = (noPercents) => (
    <ToolTipContentContainer>
      <ToolTipRow>
        <ToolTipLabel>{dayjs(startingMonth).format('MMM YYYY')}:</ToolTipLabel>
        <ToolTipValue>{startingCustomers.size}</ToolTipValue>
      </ToolTipRow>
      <ToolTipRow>
        <ToolTipLabel>{dayjs(currentMonth).format('MMM YYYY')}:</ToolTipLabel>
        <ToolTipValue>{endingCustomers.size}</ToolTipValue>
      </ToolTipRow>
      <ToolTipDivider />
      {!noPercents && (
        <ToolTipRow>
          <ToolTipLabel>Change in customer count</ToolTipLabel>
          <ToolTipValue>{numberFormatter({ type: NUMBER_FORMATS.PERCENT, rawValue: customerGrowth })}</ToolTipValue>
        </ToolTipRow>
      )}
    </ToolTipContentContainer>
  );

  const revenueRetentionContent = () => (
    <ToolTipContentContainer>
      <ToolTipRow>
        <ToolTipLabel>{dayjs(startingMonth).format('MMM YYYY')}:</ToolTipLabel>
        <ToolTipValue>{numberFormatter({ type: NUMBER_FORMATS.CURRENCY, rawValue: startingAmount })}</ToolTipValue>
      </ToolTipRow>
      <ToolTipRow>
        <ToolTipLabel>{dayjs(currentMonth).format('MMM YYYY')}:</ToolTipLabel>
        <ToolTipValue>{numberFormatter({ type: NUMBER_FORMATS.CURRENCY, rawValue: currentAmount })}</ToolTipValue>
      </ToolTipRow>
      <ToolTipDivider />
      <ToolTipRow>
        <ToolTipLabel>Change in revenue</ToolTipLabel>
        <ToolTipValue>{numberFormatter({ type: NUMBER_FORMATS.PERCENT, rawValue: revenueGrowth })}</ToolTipValue>
      </ToolTipRow>
    </ToolTipContentContainer>
  );

  return (
    <TotalRevenueContainer>
      <TopContainer>
        <Title>TOTAL REVENUE</Title>
        <TotalRevenue>
          <TooltipContainer
            width={200}
            isVisible={true}
            underline={false}
            toolTipContent={"Sum of all months' revenue for this cohort"}
          >
            {numberFormatter({ type: NUMBER_FORMATS.CURRENCY, rawValue: Math.round(totalRevenue) })}
          </TooltipContainer>
        </TotalRevenue>
        <Divider />
        <StyledDividerIcon />
        <Row>
          <TooltipContainer width={250} isVisible={true} underline={false} toolTipContent={customerRetentionContent()}>
            <Value>{numberFormatter({ type: NUMBER_FORMATS.PERCENT, rawValue: customerGrowth })}</Value>
            <GreyLabel>change in customer count:</GreyLabel>
          </TooltipContainer>
        </Row>
        <Row>
          <TooltipContainer width={250} isVisible={true} underline={false} toolTipContent={revenueRetentionContent()}>
            <Value>{numberFormatter({ type: NUMBER_FORMATS.PERCENT, rawValue: revenueGrowth })}</Value>
            <GreyLabel>change in revenue</GreyLabel>
          </TooltipContainer>
        </Row>
      </TopContainer>
      <Row>
        <TooltipContainer
          width={250}
          isVisible={true}
          underline={false}
          toolTipContent={customerRetentionContent(true)}
        >
          <CustomerContainer data-cy="spreads-drilldown__customers-count">
            <span>
              <Value>{totalCustomers}</Value> <GreyLabel>customer{totalCustomers !== 1 ? 's' : ''} in cohort</GreyLabel>
            </span>
            <CustomersIcon />
          </CustomerContainer>
        </TooltipContainer>
      </Row>
    </TotalRevenueContainer>
  );
};
