import * as Sentry from '@sentry/react';
import { useState } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useToasts } from 'components/Toasts';
import {
  getForecasts,
  createForecast,
  updateForecast,
  deleteForecast,
  getForecastDetails,
  updateForecastDetails,
  getForecastsDefaults,
} from './requests';
import { useMutationPolling, useQueryPolling } from '../jobHooks.helper';

export const CACHE_KEY = 'forecasts';
export const DEFAULTS_CACHE_KEY = 'forecastDefaults';
export const DETAILS_CACHE_KEY = 'forecastDetails';

export const useForecastsAPI = ({ orgId, isARR, autoFetch = true, enableToasts = true }) => {
  const dataKey = [CACHE_KEY, orgId, isARR];

  const { pushToast: wrappedPushToast, pushError: wrappedPushError } = useToasts();
  const pushToast = (...args) => {
    if (enableToasts) wrappedPushToast(...args);
  };
  const pushError = (...args) => {
    if (enableToasts) wrappedPushError(...args);
  };

  const queryClient = useQueryClient();

  const { data: result, error, isLoading, isFetching, refetch } = useQueryPolling({
    key: dataKey,
    fetchFn: () =>
      getForecasts({
        orgId,
        params: {
          scopes: ['includeActual', 'includeOutdatedCount', 'includeMonthlyRecurringRevenue'],
          isARR,
        },
      }),
    queryOptions: { enabled: autoFetch },
    pollingErrorMessage: `Forecasting is taking too long to load for org ${orgId}`,
  });

  const [isLoadingOutdated, setIsLoadingOutdated] = useState(false);
  const [outdatedData, setOutdatedData] = useState();

  const addForecast = useMutationPolling({
    mutationFn: async ({ data }) => {
      const { id: jobId } = await createForecast({ orgId, body: data });

      return { orgId, jobId };
    },
    mutationOptions: {
      onProgress: () =>
        pushToast(
          `Creating forecast scenario is in progress, but may take a while. Please check back in a few minutes.`,
          'success',
        ),
      onFailed: () => {
        throw new Error(`We did not finish creating scenario. Please try again later.`);
      },
      onMaxAttempts: () => {
        Sentry.captureMessage(`Creating forecast scenario is taking long to complete for org ${orgId}`, 'warning');
      },
      onError: (err) => {
        pushError(err, 'Failed to create forecast.');
      },
      onSuccess: () => {
        pushToast('Successfully created forecast!', 'success');
      },
      onSettled: () => {
        queryClient.invalidateQueries(CACHE_KEY);
      },
    },
  });

  const editForecast = useMutation(({ id, data }) => updateForecast({ orgId, forecastId: id, body: data }), {
    onMutate: async ({ id, data }) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(dataKey);

      // Snapshot the previous value
      const previousData = queryClient.getQueryData(dataKey);

      // Optimistically update to the new value
      queryClient.setQueryData(dataKey, (old) => {
        old?.data?.forEach((forecast) => {
          if (forecast.id === id) {
            forecast.name = data.name;
            forecast.start_date = data.start_date;
            forecast.end_date = data.end_date;
          }
        });
        return old;
      });

      // Return a context object with the snapshotted value
      return { previousData };
    },

    // If the mutation fails, use the context returned from onMutate to roll back
    onError: (err) => {
      queryClient.setQueryData(dataKey, (old) => old);
      pushError(err, 'Failed to update forecast.');
    },

    onSuccess: () => {
      pushToast('Successfully updated forecast!', 'success');
    },

    onSettled: () => {
      queryClient.invalidateQueries(CACHE_KEY);
    },
  });

  const removeForecast = useMutation(({ id }) => deleteForecast({ orgId, forecastId: id }), {
    onError: (err) => {
      pushError(err, 'Failed to remove forecast.');
    },

    onSuccess: () => {
      pushToast('Successfully removed forecast!', 'success');
    },

    onSettled: () => {
      queryClient.invalidateQueries(CACHE_KEY);
    },
  });

  const fetchOutdatedForecasts = async () => {
    if (result?.metadata?.outdatedCount > 0 && !outdatedData) {
      setIsLoadingOutdated(true);
      const results = await getForecasts({
        orgId,
        params: {
          outdated: true,
        },
      });
      setOutdatedData(results.data);
      setIsLoadingOutdated(false);
    }
  };

  return {
    data: result?.data,
    monthlyRecurringRevenue: result?.metadata?.monthlyRecurringRevenue ?? {},
    outdatedCount: result?.metadata?.outdatedCount ?? 0,
    outdatedData,
    error,
    isLoading,
    isFetching,
    isLoadingOutdated,
    operations: {
      addForecast: addForecast.mutateAsync,
      editForecast: editForecast.mutate,
      removeForecast: removeForecast.mutate,
      refetch,
      fetchOutdatedForecasts,
    },
  };
};

export const useForecastDefaultsAPI = ({ orgId, scenarioType, autoFetch = true }) => {
  const { data, isLoading, isFetching } = useQueryPolling({
    key: [DEFAULTS_CACHE_KEY, orgId, scenarioType],
    fetchFn: () => getForecastsDefaults({ orgId, params: { scenarioType } }),
    queryOptions: { enabled: autoFetch },
    pollingErrorMessage: `Forecasting is taking too long to load for org ${orgId}`,
  });

  return {
    data,
    isLoading,
    isFetching,
  };
};

export const useForecastDetailsAPI = ({
  orgId,
  forecastId,
  month,
  scopes = [],
  isARR,
  autoFetch = true,
  enableToasts = true,
}) => {
  const dataKey = [DETAILS_CACHE_KEY, orgId, forecastId, month, scopes.join(';'), isARR];

  const { pushToast: wrappedPushToast, pushError: wrappedPushError } = useToasts();
  const pushToast = (...args) => {
    if (enableToasts) wrappedPushToast(...args);
  };
  const pushError = (...args) => {
    if (enableToasts) wrappedPushError(...args);
  };

  const queryClient = useQueryClient();

  const { data, error, isLoading, isFetching, refetch } = useQuery(
    dataKey,
    () =>
      getForecastDetails({
        orgId,
        forecastId,
        params: {
          month,
          scopes,
          isARR,
        },
      }),
    {
      enabled: autoFetch && !!forecastId && !!month,
    },
  );

  const editForecastDetails = useMutation(
    ({ forecastId, forecastMonth, forecastType, customerId, data }) =>
      updateForecastDetails({
        orgId,
        forecastId,
        forecastMonth,
        forecastType,
        customerId,
        body: data,
      }),
    {
      // If the mutation fails, use the context returned from onMutate to roll back
      onError: (err) => {
        queryClient.setQueryData(dataKey, (old) => old);
        pushError(err, 'Failed to update forecast details.');
      },

      onSuccess: () => {
        pushToast('Successfully updated forecast!', 'success');
      },

      onSettled: () => {
        queryClient.invalidateQueries(DETAILS_CACHE_KEY);
        queryClient.invalidateQueries(CACHE_KEY);
      },
    },
  );

  return {
    data,
    error,
    isLoading,
    isFetching,
    operations: {
      refetch,
      editForecastDetails: editForecastDetails.mutate,
      editForecastDetailsAsync: editForecastDetails.mutateAsync,
    },
  };
};
