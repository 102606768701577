import React, { useContext, useMemo } from 'react';
import { AppContext } from 'AppContext';
import { useMVP } from 'utils/hooks';
import { useProductMetadataAPI } from 'api/products/hooks';
import { CirclePlusBlueIcon } from 'components/Icons';
import { GreyBox, GreyBoxActionButtonLabel, GreyBoxContent, GreyBoxHeader, GreyBoxTitle } from 'components/Metadata';
import { Centerer, Flexer, FlexerColumn } from 'components/Core';
import { CircleLoader } from 'components/Loaders';
import { MetadataItemFormikInput } from 'shared/Common/MetadataSection';
import { getMetadataDataType } from 'shared/Common/MetadataSection/utils';

export const ProductMetadata = ({ productMetadata = {} }) => {
  const isMVP = useMVP();
  const { orgId } = useContext(AppContext);
  const { data: allProductsMetadata, isLoading } = useProductMetadataAPI({ orgId });

  const metadataItems = useMemo(
    () =>
      (allProductsMetadata ?? [])
        .map(({ key, options }) => {
          const value = productMetadata?.[key] ?? null;
          const filteredOptions = (options ?? []).filter((option) => option !== null && option !== undefined);

          return {
            key,
            value,
            options: filteredOptions,
            dataType: getMetadataDataType({ key, value, options: filteredOptions }),
          };
        })
        .sort((a, b) => {
          const keyA = a.key.toLowerCase();
          const keyB = b.key.toLowerCase();
          return keyA < keyB ? -1 : 1;
        }),
    [allProductsMetadata, productMetadata],
  );

  return (
    <GreyBox>
      {isLoading ? (
        <Centerer>
          <CircleLoader />
        </Centerer>
      ) : (
        <FlexerColumn gap="16px">
          <GreyBoxHeader>
            <GreyBoxTitle>Metadata ({allProductsMetadata?.length ?? 0})</GreyBoxTitle>

            {isMVP && (
              <Centerer gap="8px">
                <GreyBoxActionButtonLabel>
                  <span>Manage</span> metadata keys
                </GreyBoxActionButtonLabel>
                <CirclePlusBlueIcon data-cy="product-metadata-add-button" onClick={() => alert('todo')} />
              </Centerer>
            )}
          </GreyBoxHeader>

          <GreyBoxContent>
            <Flexer flexDirection="column" gap="8px" wrapRow>
              {metadataItems.map((item) => (
                <Flexer width="calc(33% - 3px)" key={item.key}>
                  <MetadataItemFormikInput name={`metadata.${item.key}`} metadataDataType={item.dataType} item={item} />
                </Flexer>
              ))}
            </Flexer>
          </GreyBoxContent>
        </FlexerColumn>
      )}
    </GreyBox>
  );
};
