import React, { useContext } from 'react';
import dayjs from 'dayjs';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { cssVar, transparentize } from 'polished';
import { ReactComponent as ArrowRight } from 'images/arrows-bold-right-blue.svg';
import { AppContext } from 'AppContext';
import { DEFAULT_ICON_SIZE } from 'consts/global';
import { CustomersIcon, TransactionIcon } from 'components/Icons';
import { FlexBetweenContainer, Flexer } from 'components/Core';
import { CircleButton } from 'components/Buttons';
import { DashboardContext } from '../DashboardContext';
import { CardGridPosition, CardTitle } from '../Common/Card';

const CardContainer = styled(FlexBetweenContainer)`
  ${CardGridPosition};

  background-color: transparent;
  border: 1px solid var(--primaryBlack5);
  background-size: contain;
  border-radius: 20px;
  padding-bottom: 6px;
  min-height: 292px;
`;

const CardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border-bottom: solid 1px var(--accentGraySecond);
`;

const CardBody = styled(Flexer)`
  flex: 1;
  justify-content: space-around;

  font-size: 14px;
  line-height: 20px;

  padding: 20px;
`;

const Line = styled.div`
  width: 1px;
  height: 100%;
  background-color: var(--accentGraySecond);
  margin: 0 10px;
`;

const IconWrapper = styled(Flexer)`
  width: 36px;
  height: 36px;
  background: #ffffff;
  box-shadow: 4px 4px 28px var(--primaryBlack5);
  border-radius: 8px;
  padding: 8px;

  svg path {
    fill: var(--secondaryBlue);
  }
`;

const CountText = styled.span`
  opacity: 0.5;
`;

const CardFooter = styled.footer`
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  background: var(--primaryBlue5);
  margin: 0 6px;
  color: var(--primaryBlue);
  background-color: ${transparentize(0.95, cssVar('--primaryBlue'))};
  border-radius: 16px;
  padding: 16px;

  &:hover {
    background: rgba(0, 117, 255, 0.08);
  }
`;

export const DashboardCompanyDataCard = ({ rowStart, columnStart, size, version }) => {
  const history = useHistory();
  const { dataFilter, organizations } = useContext(AppContext);
  const { customerNURDCStats } = useContext(DashboardContext);

  const goToPage = (location) => {
    history.push(location);
  };

  const viewAllTransactions = () => {
    localStorage.setItem('transactions-page-confirmation', 'All transactions');
    window.location.replace('/transactions');
  };

  const viewAllCurrentCustomers = () => {
    localStorage.setItem('datafilter-customer-types', JSON.stringify(['current']));
    window.location.replace('/customers');
  };

  return (
    <CardContainer
      rowStart={rowStart}
      columnStart={columnStart}
      size={size}
      direction="column"
      version={version}
      data-cy="dashboard-company-data-card"
    >
      <CardHeader>
        <CardTitle>Company data</CardTitle>
      </CardHeader>
      <CardBody>
        <FlexBetweenContainer direction="column">
          <IconWrapper>
            <TransactionIcon width={DEFAULT_ICON_SIZE} height={DEFAULT_ICON_SIZE} />
          </IconWrapper>
          <div data-cy="dashboard-company-data-card--transactions-count">{organizations[0]?.transactionCount ?? 0}</div>
          <CountText>transactions</CountText>
          <CircleButton
            icon={<ArrowRight />}
            width="24px"
            height="24px"
            iconFillColor="var(--primaryBlue)"
            backgroundColor={transparentize(0.95, cssVar('--primaryBlue'))}
            onClick={viewAllTransactions}
            data-cy="dashboard-company-data-card--transactions-button"
          />
        </FlexBetweenContainer>
        <Line />
        <FlexBetweenContainer direction="column">
          <IconWrapper>
            <CustomersIcon width={DEFAULT_ICON_SIZE} height={DEFAULT_ICON_SIZE} />
          </IconWrapper>
          <div data-cy="dashboard-company-data-card--customers-count">
            {customerNURDCStats?.[dayjs(dataFilter?.endMonth).format('YYYY-MM')]?.Total ?? 0}
          </div>
          <CountText>customers</CountText>
          <CircleButton
            icon={<ArrowRight />}
            width="24px"
            height="24px"
            iconFillColor="var(--primaryBlue)"
            backgroundColor={transparentize(0.95, cssVar('--primaryBlue'))}
            onClick={viewAllCurrentCustomers}
            data-cy="dashboard-company-data-card--customers-button"
          />
        </FlexBetweenContainer>
      </CardBody>
      <CardFooter onClick={() => goToPage('/chart')} data-cy="dashboard-company-data-card--chart-visualizer-button">
        Go to Chart Visualizer
      </CardFooter>
    </CardContainer>
  );
};
