import { CentererVertical } from 'components/Core';
import styled from 'styled-components';

export const Header = styled.span`
  font-size: 18px;
  font-weight: 900;
  margin-top: 16px;
`;

export const TableContainer = styled.div`
  overflow: scroll;
  height: 80vh;
  position: relative;
`;

export const StyledTable = styled.div`
  width: fit-content;

  .table-data {
    position: relative;

    svg {
      position: absolute;
      margin-left: 10px;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
`;

export const TableHeaderRow = styled.div`
  height: 48px;
  display: flex;
  width: fit-content;
  position: sticky;
  top: 0;
  z-index: 30;
`;

export const TableHeaderTile = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  width: 90px;
  color: var(--primaryBlack75);
  font-weight: 800;
  font-size: 10px;
  border-radius: 4px;
  border: 1px solid var(--primaryBlack10);
  text-transform: uppercase;
  padding: 12px;
  background: white;
  margin-right: 4px;

  &:first-child {
    position: sticky;
    left: 0px;
    z-index: 10;
    justify-content: start;
    border-radius: 8px;
    margin-right: 12px;
    width: 360px;
  }
`;

export const TableBody = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
`;

export const TableBodyRow = styled.div`
  height: ${({ rowHeight }) => rowHeight ?? '48px'};
  display: flex;
  width: fit-content;
  position: sticky;
  top: 0;
  z-index: 20;
  margin-bottom: 4px;
`;

export const TableBodyTile = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  width: 90px;
  color: var(--primaryBlack);
  font-weight: 400;
  font-size: 12px;
  border-radius: 4px;
  padding: 12px;
  background: var(--primaryBlack5);
  margin-right: 4px;

  &:first-child {
    position: sticky;
    left: 0px;
    z-index: 10;
    justify-content: start;
    border-radius: 8px;
    margin-right: 12px;
    width: 360px;
    background: white;
    font-weight: 800;
    font-size: 12px;
    border: 1px solid var(--primaryBlack10);
  }
`;

export const EntireContractLabel = styled.span`
  font-weight: 700;
`;

export const TransactionName = styled.span`
  color: var(--primaryBlue);
  font-weight: 700;
  cursor: pointer;
`;

export const TableSubheaderContainer = styled(CentererVertical)`
  gap: 16px;
  margin-top: 24px;
  margin-bottom: 8px;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 10;
`;

export const TableSubheader = styled.span`
  font-size: 9px;
  font-weight: 900;
  border: 1px solid #e9e9e9;
  border-radius: 4px;
  padding: 4px 8px;
  top: 0;
  left: 0px;
  z-index: 10;
  justify-content: start;
`;

export const GroupingTotal = styled.span`
  font-size: 12px;
  font-weight: 700;
`;

export const TransactionAmount = styled.span`
  font-size: 12px;
  font-weight: 400;
`;

export const Allocation = styled.span`
  padding: 4px 8px;
  border-radius: 4px;
  background: var(--primaryBlue10);
  color: var(--primaryBlue);
  font-size: 12px;
  font-weight: 700;
  cursor: pointer;
`;
