import styled from 'styled-components';
import { cssVar, transparentize } from 'polished';
import { ReactTable, TableSettingsTitle } from 'components/Table';

export const SnapshotTable = styled(ReactTable)`
  border-radius: 16px;
  margin-bottom: 0;
  border: 1px solid var(--primaryBlack5);
  background-color: var(--accentGrayFourth);
  max-height: none;
  &:hover .data-table__row-container.has-drilldown {
    outline: 1px solid var(--primaryBlue10);
    border-radius: 4px;
  }
`;

export const DashboardHeadInner = styled.div`
  padding: 18px 20px;
`;

export const ReactTableDashboardHeaderColumn = styled.div`
  min-width: 110px;
  min-height: 44px;
  position: relative;
  padding: 0 5px;
  background: var(--accentGrayFourth);
  white-space: nowrap;
  font-weight: 900;
  font-size: 10px;
  line-height: 12px;
  color: var(--primaryBlack50);
  text-transform: uppercase;
  z-index: ${(props) => (props.firstCell ? 10 : 6)};
  top: 0;
  left: ${(props) => props.firstCell && 0};
  border-right: ${(props) => props.firstCell && '1px solid var(--primaryBlack5)'};
  justify-content: ${(props) => !props.firstCell && 'flex-end'};
  display: flex;
  align-items: center;

  ${DashboardHeadInner} {
    height: 100%;
    background: ${(props) =>
      props.isQuarter
        ? `${transparentize(0.9, cssVar('--primaryYellow'))}`
        : props.isYearly
        ? `${transparentize(0.9, cssVar('--primaryPurple'))}`
        : props.isCumulative
        ? `${transparentize(0.3, cssVar('--accentGray'))}`
        : 'var(--accentGrayFourth)'};
    border-top-left-radius: ${(props) => props.firstCell && '16px'};
    width: ${(props) => (props.isQuarter || props.isYearly || props.isCumulative) && '100%'};
    text-align: ${(props) => (props.isQuarter || props.isYearly || props.isCumulative) && 'right'};
  }
`;

export const DashboardCellInner = styled.div`
  height: 100%;
  padding: 7px 10px;
  display: flex;
  align-items: center;

  img {
    margin-left: 6px;
  }
`;

export const ReactTableDashboardCell = styled.div`
  min-width: 110px;
  position: relative;
  padding: ${(props) => (props.firstCell ? '7px 6px' : '0 5px')};
  text-align: ${(props) => !props.firstCell && 'right'};
  position: ${(props) => props.firstCell && 'sticky'};
  font-size: 12px;
  color: var(--primaryBlack);
  left: ${(props) => props.firstCell && 0};
  pointer-events: ${(props) => props.noPointerEvents && 'none'};
  border-right: ${(props) => props.firstCell && '1px solid var(--primaryBlack5)'};
  border-top-left-radius: ${(props) => props.firstCell && props.firstRow && '16px'};
  border-top: ${(props) => (props.headRow || props.firstRow) && '1px solid var(--primaryBlack5)'};
  z-index: ${(props) => (props.firstCell ? 5 : 'initial')};
  background: ${(props) =>
    props.cellWithActiveGoal && props.firstCell
      ? 'var(--accentGray)'
      : props.cellWithActiveGoal
      ? `${transparentize(0.2, cssVar('--accentGray'))}`
      : 'white'};

  ${DashboardCellInner} {
    background: ${(props) =>
      props.isQuarter
        ? `${transparentize(0.9, cssVar('--primaryYellow'))}`
        : props.isYearly
        ? `${transparentize(0.9, cssVar('--primaryPurple'))}`
        : props.isCumulative
        ? `${transparentize(0.3, cssVar('--accentGray'))}`
        : 'none'};
  }
`;

export const SettingsContainer = styled(TableSettingsTitle)`
  position: relative;
  width: 100%;
  font-weight: 900;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  color: var(--primaryBlue);
  padding: 0 5px;
  cursor: ${(props) => (props.pointer ? 'pointer' : 'default')};

  &:hover {
    color: var(--secondaryBlue);

    svg path {
      fill: var(--secondaryBlue);
    }
  }
`;
