import React, { useContext } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { DashboardProvider } from '../DashboardProvider';
import { ReportBuilderProvider } from './ReportBuilderProvider';
import { ReportBuilderHeader } from './ReportBuilderHeader';
import { ReportBuilderPage } from './ReportBuilderPage';
import { DashboardContext } from '../DashboardContext';

const ReportBuilderContent = () => {
  const { state } = useLocation();
  const pageState = useContext(DashboardContext);

  return (
    <ReportBuilderProvider pageState={pageState} selectedReport={pageState?.report} isNewReport={state?.isNewReport}>
      <ReportBuilderHeader />
      <ReportBuilderPage />
    </ReportBuilderProvider>
  );
};

export const ReportBuilderContainer = () => {
  const { state } = useLocation();
  const { id } = useParams();

  return (
    <DashboardProvider reportId={id} report={state?.report}>
      <ReportBuilderContent />
    </DashboardProvider>
  );
};
