import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { ErrorBar } from 'components/Blocks';
import { WhiteButton } from 'components/Buttons';

const Body = styled.div`
  margin-left: 20px;
`;

const Title = styled.h3`
  font-size: 16px; ;
`;

const DangerText = styled.span`
  font-weight: 600;
  color: red;
`;

const UnprocessableDataError = ({ hasIncompleteIntegration }) => (
  <ErrorBar height={100} styles={{ marginBottom: 20 }}>
    <Body>
      <Title>
        Subscript can't process data, because you{' '}
        <DangerText>
          {hasIncompleteIntegration
            ? 'have an incomplete integration configured'
            : 'don’t have any confirmed transactions.'}
        </DangerText>
      </Title>
      <WhiteButton>
        <Link to={hasIncompleteIntegration ? '/configure?tab=sources' : '/transactions'}>
          Go to {hasIncompleteIntegration ? 'Configuration' : 'Transactions'} Page
        </Link>
      </WhiteButton>
    </Body>
  </ErrorBar>
);

export { UnprocessableDataError };
