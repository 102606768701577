// Rearrange the array items after we pick up the item at position sourceIndex and put it
//  to the position at targetIndex.
export const rearrange = ({ items, sourceIndex, targetIndex }) => {
  if (items.length === 0) return items;
  const rearranged = Array.from(items);
  const [removed] = rearranged.splice(sourceIndex, 1);
  rearranged.splice(targetIndex, 0, removed);
  return rearranged;
};

// Partition an array into an array of arrays of equal size (except the last array where
//  there may not be enough elements to fill in)
export const partition = (array, partitionSize) => {
  if (partitionSize === 0) return null;
  const result = [];
  for (let i = 0; i < array.length; i += partitionSize) {
    result.push(array.slice(i, i + partitionSize));
  }
  return result;
};

export const isSubset = (x, y) => x.every((elem) => y.includes(elem));

export const toSentence = (elems, { connector } = {}) =>
  [elems.slice(0, -1).join(', '), elems[elems.length - 1]]
    .filter((a) => a !== undefined && String(a).length > 0)
    .join(` ${connector ?? 'and'} `);

export const replaceRecordInArray = (elems, replacingElem, options = {}) => {
  const primaryKey = options.primaryKey ?? 'id';
  const merged = options.merged ?? true;

  let replaced = false;
  const results = elems.reduce((acc, elem) => {
    if (elem[primaryKey] === replacingElem[primaryKey]) {
      acc.push(merged ? { ...elem, ...replacingElem } : replacingElem);
      replaced = true;
    } else {
      acc.push(elem);
    }
    return acc;
  }, []);

  return replaced ? results : [replacingElem, ...results];
};

// Copy from backend
export const groupBy = (arrayOfObjects, columnToGroupBy = null, options = {}) =>
  arrayOfObjects.reduce((acc, curr) => {
    if (columnToGroupBy) {
      const groupId = curr[columnToGroupBy];
      if (acc[groupId]) {
        if (!options.uniqueness) {
          acc[groupId].push(curr);
        }
      } else {
        acc[groupId] = options.uniqueness ? curr : [curr];
      }
    } else {
      acc[curr] = curr;
    }
    return acc;
  }, {});

// Copy from backend
export const mapFilter = (arr, mapFn, filterFn = (x) => x) =>
  arr.reduce((acc, curr) => {
    const mapped = mapFn(curr);
    if (filterFn(mapped)) {
      acc.push(mapped);
    }
    return acc;
  }, []);
