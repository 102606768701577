import React, { Fragment } from 'react';
import { useTable, useExpanded, useSortBy, usePagination } from 'react-table';
import {
  ReactTable,
  ReactTableBody,
  ReactTableCell,
  ReactTableHeader,
  ReactTableHeaderColumn,
  ReactTableHeaderRow,
  ReactTableRow,
  SortIconWrapper,
  HeaderCellWrapper,
} from 'components/Table';
import { ReactComponent as TotalIcon } from 'images/sort-descending.svg';
import { Row } from 'components/Core';
import { PaginationButton } from 'components/Buttons';

import RowTable from './RowTable';

const Table = ({ columns: userColumns, data, activeMetric, onTransactionClick, revenueMonth }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = useTable(
    {
      columns: userColumns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: 20,
        sortBy: [
          {
            id: 'metricValue',
            desc: true,
          },
        ],
      },
      paginateExpandedRows: false,
    },
    useSortBy,
    useExpanded, // Use the useExpanded plugin hook
    usePagination,
  );

  return (
    <>
      <Row
        horizontal="flex-end"
        style={{
          top: 47,
          right: 40,
          position: 'absolute',
        }}
      >
        <PaginationButton
          pageIndex={pageIndex + 1}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          pageCount={pageCount}
          nextPage={nextPage}
          previousPage={previousPage}
        />
      </Row>
      <ReactTable {...getTableProps()}>
        <ReactTableHeader>
          {headerGroups.map((headerGroup) => (
            <ReactTableHeaderRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <ReactTableHeaderColumn
                  alignRight={column?.alignRight}
                  customWidth={column.width}
                  {...column.getHeaderProps(column.getSortByToggleProps({ title: undefined }))}
                >
                  <HeaderCellWrapper isSortable={column.canSort && !column.disableSortBy} isSorted={column.isSorted}>
                    {column.render('Header')}

                    {column.isSorted && (
                      <SortIconWrapper isSortedDesc={column.isSortedDesc}>
                        <TotalIcon />
                      </SortIconWrapper>
                    )}
                  </HeaderCellWrapper>
                </ReactTableHeaderColumn>
              ))}
            </ReactTableHeaderRow>
          ))}
        </ReactTableHeader>
        <ReactTableBody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <Fragment key={row.id}>
                {!row.depth && (
                  <ReactTableRow noBorderBottom={row.canExpand && row.isExpanded} {...row.getRowProps()}>
                    <>
                      {row.cells.map((cell) => (
                        <ReactTableCell customWidth={cell.column.width} {...cell.getCellProps()}>
                          {cell.render('Cell')}
                        </ReactTableCell>
                      ))}
                    </>
                  </ReactTableRow>
                )}

                {!row.depth && row.isExpanded && (
                  <div style={{ borderBottom: '1px solid var(--accentGraySecond)' }}>
                    <RowTable
                      customer={row.original}
                      onTransactionClick={onTransactionClick}
                      metricSpreads={row.original.metricSpreads}
                      previousSpreads={row.original.previousSpreads}
                      activeMetric={activeMetric}
                      revenueMonth={revenueMonth}
                    />
                  </div>
                )}
              </Fragment>
            );
          })}
        </ReactTableBody>
      </ReactTable>
    </>
  );
};

const RevenueCustomerTransactionsTable = ({
  dataForTable,
  columns,
  onTransactionClick,
  activeMetric,
  revenueMonth,
  upsellTypes,
}) => {
  const data = React.useMemo(() => {
    return dataForTable;
  }, [dataForTable]);

  return (
    <>
      {data && (
        <Table
          columns={columns}
          data={data}
          onTransactionClick={onTransactionClick}
          activeMetric={activeMetric}
          revenueMonth={revenueMonth}
          upsellTypes={upsellTypes}
        />
      )}
    </>
  );
};

export { RevenueCustomerTransactionsTable };
