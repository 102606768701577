import React, { useCallback, useState } from 'react';
import { FlexerColumn } from 'components/Core';
import {
  AdditionalModalBody,
  BodyDescription,
  BodyHeader,
  DeleteConfirmationModal,
} from 'shared/DeleteConfirmationModal';
import { TrashIcon } from 'components/Icons';

const useDeleteFieldModal = ({ onDelete }) => {
  const [modalOpen, setModalOpen] = useState(null);

  const openModal = ({ glField, glFieldLabel, subscriptField, subscriptFieldLabel }) => {
    setModalOpen({ glField, glFieldLabel, subscriptField, subscriptFieldLabel });
  };

  const closeModal = () => {
    setModalOpen(null);
  };

  const Modal = useCallback(
    () =>
      modalOpen ? (
        <DeleteConfirmationModal
          modalName="invoice-custom-field--delete-confirmation-modal"
          modalTitle="Remove Custom Field"
          modalDescription="Do you really want to remove this custom field? This cannot be un-done."
          additionalModalBody={
            <AdditionalModalBody>
              <TrashIcon />
              <FlexerColumn gap="8px">
                <BodyHeader>{modalOpen?.subscriptFieldLabel}</BodyHeader>
                <BodyDescription>{modalOpen?.glFieldLabel}</BodyDescription>
              </FlexerColumn>
            </AdditionalModalBody>
          }
          onClose={closeModal}
          onDeleteConfirm={() => {
            onDelete(modalOpen);
            closeModal();
          }}
        />
      ) : null,
    [modalOpen, onDelete],
  );

  return {
    isModalOpen: !!modalOpen,
    openModal,
    Modal,
  };
};

export default useDeleteFieldModal;
