import { Switch as AntdSwitch } from 'antd';
import { Spacer } from 'components/Core';
import styled from 'styled-components';

export const SwitchLabelContainer = styled.div`
  display: flex;
  align-items: ${({ alignItems }) => alignItems ?? 'center'};
  ${({ marginRight }) => (marginRight ? `margin-right: ${marginRight};` : '')}
`;

export const SwitchContainer = styled.div``;

export const Switch = styled(AntdSwitch)`
  background-color: white;
  border: 1px solid var(--primaryBlack75);
  padding: 2px;
  height: 18px;

  & .ant-switch-handle {
    width: 10px;
    height: 10px;
    top: 3px;
    left: 3px;
  }

  &.ant-switch-checked {
    background-color: var(--primaryGreen);
    border: 1px solid transparent;

    & .ant-switch-handle {
      top: 3px;
    }

    & .ant-switch-handle::before {
      background-color: white;
    }
  }

  & .ant-switch-handle::before {
    background-color: var(--accentGrayThird);
  }
`;

export const SwitchLabel = styled.div`
  font-size: ${({ labelSize }) => labelSize ?? '14px'};
  font-style: normal;
  font-weight: ${({ bolded }) => (bolded ? 700 : 'normal')};
  line-height: 18px;
  color: var(--primaryBlack);
  display: inline-block;
  white-space: normal;
`;

export const SwitchDescription = styled.div`
  font-size: 12px;
  line-height: 16px;
  text-transform: none;
  color: var(--primaryBlack);
  opacity: 0.5;
  margin: 5px 0 5px 10px;
`;

const LabelWrapper = styled.div`
  display: inline-block;
  padding-left: ${({ labelFirst }) => (labelFirst ? '0' : '8px')};
  padding-right: ${({ labelFirst }) => (!labelFirst ? '0' : '8px')};
`;

export const SwitchWithLabel = ({
  label,
  labelFirst = false,
  labelSize,
  bolded = false,
  onChange,
  checked,
  disabled = false,
  name,
  alignItems,
  description,
  marginRight,
  icon,
  CustomSwitch,
}) => (
  <SwitchLabelContainer alignItems={alignItems} marginRight={marginRight}>
    {labelFirst && label && (
      <LabelWrapper labelFirst={labelFirst}>
        <SwitchLabel labelSize={labelSize} bolded={bolded}>
          {label}
        </SwitchLabel>
        {description && <SwitchDescription>{description}</SwitchDescription>}
      </LabelWrapper>
    )}

    {CustomSwitch ? (
      <CustomSwitch
        size="small"
        onChange={onChange}
        checked={checked}
        disabled={disabled}
        data-cy={`switch--${name}`}
      />
    ) : (
      <Switch size="small" onChange={onChange} checked={checked} disabled={disabled} data-cy={`switch--${name}`} />
    )}

    {!labelFirst && label && (
      <LabelWrapper labelFirst={labelFirst}>
        <SwitchLabel labelSize={labelSize} bolded={bolded}>
          {label}
        </SwitchLabel>
        {description && <SwitchDescription>{description}</SwitchDescription>}
      </LabelWrapper>
    )}
    {icon && (
      <>
        <Spacer width="4px" />
        {icon}
      </>
    )}
  </SwitchLabelContainer>
);
