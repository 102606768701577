import React from 'react';
import { Loader } from 'components/Loaders';
import { ErrorBar } from 'components/Blocks';
import { ReportCardsList } from './styles';
import { ReportsPopoverEmptyState } from './ReportsPopoverEmptyState';
import { ReportsPopoverEmptySearch } from './ReportsPopoverEmptySearch';
import { ReportsListCard } from './ReportsListCard';

export const ReportsList = ({
  error,
  isLoading,
  isFetching,
  reportList,
  searchTerm,
  handleClearSearch,
  handleClickCreateNewReport,
  handleNavigateToReportPage,
}) => {
  return (
    <div>
      {error && <ErrorBar> Sorry, something went wrong: {error.message} </ErrorBar>}
      {isLoading || isFetching ? (
        <div className="w-100 flexer">
          <Loader containerStyles={{ width: 40 }} />
        </div>
      ) : (
        <>
          {reportList.length === 0 && !searchTerm && (
            <ReportsPopoverEmptyState onCreateReport={handleClickCreateNewReport} />
          )}
          {reportList.length === 0 && searchTerm && <ReportsPopoverEmptySearch onResetSearch={handleClearSearch} />}
          {reportList.length > 0 && (
            <ReportCardsList>
              {reportList.map((report) => (
                <ReportsListCard key={report.id} report={report} onClick={handleNavigateToReportPage} />
              ))}
            </ReportCardsList>
          )}
        </>
      )}
    </div>
  );
};
