import styled from 'styled-components';
import { FlexerColumn, Centerer, FlexerRow } from 'components/Core';
import { DIRECTIONS } from './consts';

export const StyledTooltipContainer = styled.div`
  position: fixed;
  padding: 4px 8px;
  color: white;
  box-shadow: 3px 3px 10px rgba(0, 21, 46, 0.15);
  border-radius: 3px;
  background-color: #333;
  visibility: ${(props) => (props.isVisible ? 'initial' : 'hidden')};
  text-align: ${(props) => (props.leftAlign ? 'left' : 'center')};
  font-size: ${(props) => props.fontSize};
  background-color: ${(props) => props.backgroundColor};
  z-index: ${(props) => props.zIndex ?? 3};

  ::before {
    content: '';
    display: ${(props) => (props.hideArrow ? 'none' : 'block')};
    width: 0;
    height: 0;
    position: absolute;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-right: 8px solid var(--primaryBlack);
    border-right-color: ${(props) => props.backgroundColor};
    transform: ${(props) =>
      props.arrowDirection === DIRECTIONS.BOTTOM
        ? 'translate(-50%, 0) rotate(270deg)'
        : 'translate(-50%, 0) rotate(90deg)'};
    left: 50%;
    top: ${(props) => (props.arrowDirection === DIRECTIONS.BOTTOM ? 'calc(100% - 5px)' : '-10px')};
  }
`;

export const TooltipWrapper = FlexerColumn;

export const TooltipRowWrapper = FlexerRow;

export const TooltipRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TooltipColumn = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TooltipRowText = styled(Centerer)`
  color: rgba(255, 255, 255, 0.6);

  font-size: 10px;
  line-height: 14px;
`;

export const TooltipRowText12 = styled(Centerer)`
  padding: 4px 0;
  font-size: 12px;
  line-height: 16px;
`;

export const TooltipSeparator = styled.div`
  height: 1px;
  width: ${({ noPadding }) => (noPadding ? 'calc(100% + 16px)' : '100%')};
  background: rgba(255, 255, 255, 0.08);
  margin-left: ${({ noPadding }) => (noPadding ? '-8px' : 0)};
`;

export const TooltipVerticalSeparator = styled.div`
  width: 1px;
  margin-top: 2px;
  margin-bottom: 2px;
  background: rgba(255, 255, 255, 0.08);
`;

export const TooltipRowValue = styled(Centerer)`
  color: rgba(255, 255, 255);

  font-size: 12px;
  line-height: 16px;
  font-weight: 900;
`;
