import React, { useContext } from 'react';
import styled from 'styled-components';
import { AppContext } from 'AppContext';
import { ISO_CODE_TO_SYMBOL } from 'consts/global';
import { ACCOUNTING_CURRENCIES_NO_GL } from 'views/Billing/consts';
import { FieldTag, FieldTitle } from 'views/Wizard/styles';
import { TooltipContainer } from 'components/Tooltip';
import { TagsWrapper } from 'views/Wizard/steps/SubscriptionData/styles';
import { CentererVertical, Flexer, FlexerColumn } from 'components/Core';
import { StyledInput } from 'components/Controls/FormikCustomInput/FormikCustomInput';

const ItemsRow = styled.div`
  padding: 16px 28px;
`;

const ItemSubheader = styled.span`
  padding-left: ${({ noPaddingLeft }) => (noPaddingLeft ? '0px' : '4px')};
  font-size: 12px;
  font-weight: 400;
  color: var(--primaryBlack50);
`;

export const AccountingCurrenciesTable = ({ values, setFieldValue }) => {
  const { organizations, glIntegrations, orgConfigs } = useContext(AppContext);

  const hasMultipleGLs = glIntegrations.length > 1;
  const hasAccountingSpreads = organizations?.[0]?.hasAccountingSpreads;

  return hasMultipleGLs ? (
    <ItemsRow>
      <FieldTitle noMarginBottom style={{ padding: '28px 28px 0px 0px' }}>
        Accounting currencies
      </FieldTitle>
      <FlexerColumn gap="12px">
        <ItemSubheader noPaddingLeft>Please contact Subscript support if you wish to change</ItemSubheader>

        <FlexerColumn gap="8px">
          <CentererVertical>
            <Flexer width="50%">
              <ItemSubheader>General Ledger</ItemSubheader>
            </Flexer>
            <Flexer width="50%">
              <ItemSubheader>Currency</ItemSubheader>
            </Flexer>
          </CentererVertical>

          <FlexerColumn gap="8px">
            {glIntegrations.map((glIntegration) => (
              <CentererVertical key={glIntegration.id} gap="8px">
                <Flexer width="50%">
                  <StyledInput disabled={true} value={glIntegration.display_name} />
                </Flexer>
                <Flexer width="50%">
                  <StyledInput
                    disabled={true}
                    value={orgConfigs?.accountingCurrencies?.[glIntegration.id]?.value ?? 'No currency set'}
                  />
                </Flexer>
              </CentererVertical>
            ))}
            <CentererVertical gap="8px">
              <Flexer width="50%">
                <StyledInput disabled={true} value={ACCOUNTING_CURRENCIES_NO_GL} />
              </Flexer>
              <Flexer width="50%">
                <StyledInput
                  disabled={true}
                  value={orgConfigs?.accountingCurrencies?.[ACCOUNTING_CURRENCIES_NO_GL]?.value ?? 'No currency set'}
                />
              </Flexer>
            </CentererVertical>
          </FlexerColumn>
        </FlexerColumn>
      </FlexerColumn>
    </ItemsRow>
  ) : (
    <ItemsRow>
      <FieldTitle noMarginBottom style={{ padding: '28px 28px 0px 0px' }}>
        What currency do you use for accounting?
      </FieldTitle>
      <TagsWrapper>
        {hasAccountingSpreads ? (
          <TooltipContainer
            toolTipContent={'Please contact Subscript support if you wish to change'}
            isVisible={hasAccountingSpreads}
          >
            <FieldTag>
              <b>
                {ISO_CODE_TO_SYMBOL[values?.accountingCurrency]} ({values?.accountingCurrency})
              </b>
            </FieldTag>
          </TooltipContainer>
        ) : (
          Object.entries(ISO_CODE_TO_SYMBOL).map(([code, symbol]) => (
            <FieldTag
              key={code}
              selected={code === values?.accountingCurrency}
              onClick={() => {
                setFieldValue('accountingCurrency', code);
              }}
            >
              <b>
                {symbol} ({code})
              </b>
            </FieldTag>
          ))
        )}
      </TagsWrapper>
    </ItemsRow>
  );
};
