import React, { useState, useEffect } from 'react';
import { Route } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { CircleLoader } from 'components/Loaders';

// Writing our own version of withAuthenticationRequired, the original is here:
// https://github.com/auth0/auth0-react/blob/master/src/with-authentication-required.tsx
// The reason why we wanted to patch this is because <Route component={}> makes the inner
// component mounting twice, so we want to change it to <Route render={}>.
// @see https://stackoverflow.com/questions/49055172/react-component-mounting-twice
export const ProtectedRoute = ({ component, loadedOrgConfigs, ...rest }) => {
  const [loadedAuth, setLoadedAuth] = useState(false);
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();

  useEffect(
    () => {
      if (isAuthenticated) {
        setLoadedAuth(true);
        return;
      }
      if (isLoading) {
        return;
      }

      (async () => {
        await loginWithRedirect({ appState: { returnTo: `${window.location.pathname + window.location.search}` } });
      })();
    },
    // eslint-disable-next-line
    [isLoading, isAuthenticated, loginWithRedirect],
  );

  const rendered = (props) =>
    loadedAuth && loadedOrgConfigs ? (
      component(props)
    ) : (
      <div data-cy="main-spinner">
        <CircleLoader isAbsolute />
      </div>
    );
  return <Route render={rendered} {...rest} />;
};
