import dayjs from 'dayjs';
import { Bar, Line, defaults } from 'react-chartjs-2';
import { cssVar } from 'polished';
import { SNAPSHOT_METRICS } from 'consts/snapshotMetrics';
import { NUMBER_FORMATS } from 'consts/global';
import { reFormatDate } from 'utils/dateUtils';
import { amountWithAbbreviation } from 'utils/formatters';

const xAxes = [
  {
    gridLines: {
      display: false,
    },
    ticks: {
      callback: function (value) {
        return value.toUpperCase();
      },
      fontStyle: 'normal',
      fontSize: 10,
      fontColor: cssVar('--primaryBlack70'),
    },
  },
];

const generateYAxisOptions = ({ dataType, index = '0', chartData, currency }) => {
  const minValue = Math.min(...chartData);
  const maxValue = Math.max(...chartData);
  const hasNegativeValue = minValue < 0;
  const tenPercentDifference = (maxValue - minValue) / 10;
  return {
    id: `y${index}`,
    position: index === '0' ? 'left' : 'right',
    gridLines: {
      display: index === '0' ? true : false,
      drawBorder: false,
      color: cssVar('--primaryBlack3'),
    },
    ticks: {
      padding: 20,
      suggestedMax: maxValue + tenPercentDifference,
      suggestedMin: hasNegativeValue ? minValue - tenPercentDifference : Math.max(minValue - tenPercentDifference, 0),
      callback: (value) => amountWithAbbreviation({ value, type: dataType, currency }),
      beginAtZero: true,
      fontStyle: 'normal',
      fontSize: 10,
      fontColor: cssVar('--primaryBlack70'),
    },
  };
};

const getChartLabels = ({ months }) => months.map((month) => reFormatDate(month, 'YYYY-MM', 'MMM YYYY'));

export const CHART_COLORS = [cssVar('--primaryGreen'), cssVar('--primaryPurple')];

const generateChartData = ({ months, data, monthDataKey }) =>
  months.map((month) => {
    const monthValue = data?.[month]?.[monthDataKey];
    if (monthValue?.value) return monthValue?.value;
    else if (monthValue) return typeof monthValue === 'object' ? 0 : monthValue;
    else return 0;
  });

const buildChartDataset = ({
  data,
  months,
  monthDataKey,
  dataType,
  label,
  chartType,
  index,
  numberOfAxis,
  numberOfMetrics,
}) => {
  // gets the metric values for each month
  const chartData = generateChartData({ months, data, monthDataKey });

  chartType = dataType === NUMBER_FORMATS.PERCENT ? 'line' : 'bar';

  const defaultOptions = {
    type: chartType,
    label,
    data: chartData,
    yAxisID: numberOfAxis > 1 ? `y${index}` : null,
    datalabels: {
      align: 'end',
      anchor: 'end',
    },
  };

  let options;
  if (chartType === 'line') {
    // line chart style options
    options = {
      ...defaultOptions,
      fill: numberOfMetrics === 1,
      backgroundColor: cssVar('--accentGreen'),
      borderColor: CHART_COLORS[index],
      pointBackgroundColor: CHART_COLORS[index],
      pointBorderColor: 'white',
      borderWidth: 2,
      pointRadius: 4,
      lineTension: 0,
    };
  } else if (chartType === 'bar') {
    // bar chart style options
    options = {
      ...defaultOptions,
      backgroundColor: CHART_COLORS[index],
      barThickness: 18,
    };
  }

  return options;
};

const DATA_LABEL_COLORS = [cssVar('--accentGraySecond'), cssVar('--lightGray')];

const getPlugins = ({ selectedMetrics, currency }) => {
  // first data set's dataType
  const firstMetricDataType = SNAPSHOT_METRICS[selectedMetrics[0]].dataType;
  const dataTypes = [firstMetricDataType];

  // second data set's dataType
  if (selectedMetrics.length === 2) {
    const secondMetricDataType = SNAPSHOT_METRICS[selectedMetrics[1]].dataType;
    dataTypes.push(secondMetricDataType);
  }

  const plugins = {
    datalabels: {
      offset: '5',
      display: 'auto',
      backgroundColor: (context) => DATA_LABEL_COLORS[context.datasetIndex],
      borderRadius: 4,
      color: cssVar('--primaryBlack30'),
      font: {
        weight: 800,
      },
      formatter: (value, context) => amountWithAbbreviation({ value, type: dataTypes[context.datasetIndex], currency }),
      padding: 6,
    },
  };

  return plugins;
};

const dataSetsOverlap = (dataset1, dataset2) => {
  const minValue1 = Math.min(...dataset1);
  const maxValue1 = Math.max(...dataset1);
  const minValue2 = Math.min(...dataset2);
  const maxValue2 = Math.max(...dataset2);

  if (minValue1 > minValue2 && minValue1 < maxValue2) {
    return true;
  } else if (maxValue1 > minValue2 && maxValue1 < maxValue2) {
    return true;
  } else if (minValue2 > minValue1 && minValue2 < maxValue1) {
    return true;
  } else if (maxValue2 > minValue1 && maxValue2 < maxValue1) {
    return true;
  } else {
    return false;
  }
};

export const generateChart = ({ selectedMetrics, dataForVisualizer, months, currency }) => {
  defaults.global.defaultFontFamily = 'Nunito Sans';

  const numberOfMetrics = selectedMetrics.length;
  let numberOfAxis;
  if (numberOfMetrics === 1) {
    numberOfAxis = 1;
  } else if (numberOfMetrics > 1) {
    // different datatypes
    if (SNAPSHOT_METRICS[selectedMetrics[0]].dataType !== SNAPSHOT_METRICS[selectedMetrics[1]].dataType) {
      numberOfAxis = 2;
    } else if (selectedMetrics.every((metric) => SNAPSHOT_METRICS[metric].dataType === NUMBER_FORMATS.PERCENT)) {
      numberOfAxis = 1;
    } else {
      // if the values overlap
      const snapshotMetric1 = SNAPSHOT_METRICS[selectedMetrics[0]];
      const snapshotMetric2 = SNAPSHOT_METRICS[selectedMetrics[1]];
      const dataset1 = generateChartData({
        months,
        data: dataForVisualizer[snapshotMetric1.metricCategoryKey],
        monthDataKey: snapshotMetric1.monthDataKey,
      });
      const dataset2 = generateChartData({
        months,
        data: dataForVisualizer[snapshotMetric2.metricCategoryKey],
        monthDataKey: snapshotMetric2.monthDataKey,
      });

      numberOfAxis = dataSetsOverlap(dataset1, dataset2) ? 1 : 2;
    }
  }

  const ChartComponent = selectedMetrics.every((metric) => SNAPSHOT_METRICS[metric].dataType === NUMBER_FORMATS.PERCENT)
    ? Line
    : Bar;

  // builds dataset(s) for chart
  const datasets = [];
  for (const [index, metric] of Object.entries(selectedMetrics)) {
    const { metricCategoryKey, monthDataKey, dataType, label } = SNAPSHOT_METRICS[metric];

    const chartDataset = buildChartDataset({
      data: dataForVisualizer[metricCategoryKey],
      months,
      monthDataKey,
      dataType,
      label,
      index,
      numberOfAxis,
      numberOfMetrics,
    });
    datasets.push(chartDataset);
  }

  const yAxes = [];
  if (numberOfAxis === 2) {
    for (const [index, metric] of Object.entries(selectedMetrics)) {
      const chartData = datasets[index].data;
      const dataType = SNAPSHOT_METRICS[metric].dataType;
      const yAxisOptions = generateYAxisOptions({ dataType, index, chartData, currency });
      yAxes.push(yAxisOptions);
    }
  } else if (numberOfAxis === 1) {
    const chartData = datasets[0].data;
    if (numberOfMetrics === 2) {
      chartData.push(...datasets[1].data);
    }
    const dataType = SNAPSHOT_METRICS[selectedMetrics[0]].dataType;
    const yAxisOptions = generateYAxisOptions({ dataType, chartData, currency });
    yAxes.push(yAxisOptions);
  }

  const plugins = getPlugins({ selectedMetrics, currency });

  // creates options for chart
  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    layout: {
      padding: { top: 30, right: 30 },
    },
    tooltips: {
      enabled: false,
    },
    scales: {
      xAxes,
      yAxes,
    },
    plugins,
  };

  return (
    <ChartComponent
      data={{
        labels: getChartLabels({ months }),
        datasets,
      }}
      options={chartOptions}
    />
  );
};

export const calculateDataForOverview = ({ selectedMetrics, dataForVisualizer, months }) => {
  const result = {};

  if (selectedMetrics.length === 0 || Object.values(dataForVisualizer).some((value) => !value)) return {};

  for (const currentDate of months) {
    result[currentDate] = {};

    // calculate previous dates
    const currentDayJS = dayjs.utc(`${currentDate}-01`);
    const oneMonthAgoDate = currentDayJS.subtract(1, 'month').format('YYYY-MM');
    const threeMonthsAgoDate = currentDayJS.subtract(3, 'month').format('YYYY-MM');
    const oneYearAgoDate = currentDayJS.subtract(12, 'month').format('YYYY-MM');
    for (const metric of selectedMetrics) {
      const { metricCategoryKey, monthDataKey } = SNAPSHOT_METRICS[metric];
      const metricCategoryData = dataForVisualizer[metricCategoryKey];

      const currentValue =
        metricCategoryData[currentDate][monthDataKey]?.value ?? metricCategoryData[currentDate][monthDataKey];

      const lastMonthValue =
        metricCategoryData[oneMonthAgoDate][monthDataKey]?.value ?? metricCategoryData[oneMonthAgoDate][monthDataKey];
      const lastQuarterValue =
        metricCategoryData[threeMonthsAgoDate][monthDataKey]?.value ??
        metricCategoryData[threeMonthsAgoDate][monthDataKey];
      const lastYearValue =
        metricCategoryData[oneYearAgoDate][monthDataKey]?.value ?? metricCategoryData[oneYearAgoDate][monthDataKey];

      const momChange = currentValue - lastMonthValue;
      const qoqChange = currentValue - lastQuarterValue;
      const yoyChange = currentValue - lastYearValue;

      // set relevant values for metric
      result[currentDate][metric] = {
        currentValue,
        lastMonthValue,
        lastQuarterValue,
        lastYearValue,
        momChange,
        qoqChange,
        yoyChange,
        currentDate,
        oneMonthAgoDate,
        threeMonthsAgoDate,
        oneYearAgoDate,
      };
    }
  }
  return result;
};
