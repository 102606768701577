import { isEqual } from 'lodash';
import { bypassError } from './errorUtils';
import { dig, deepAssign } from './objectUtils';
import { assertCloseTo } from './assertionUtils';
import { INVARIANT_CHECKERS } from './cachedMetricsUtils.invariantCheckers';

// We "cache" the current metrics in session storage.
// Because the same metrics can be calculated in different ways but they will write to the same key under
// cachedMetrics, we will be able to detect inconsistencies.
// Whenever a transaction is updated, we clear the cache.
const CACHED_METRICS_KEY = 'cachedMetrics';

// The current metrics can change if isARR, isCommitted, and the likes change. We store these states that
// affect metrics calculation and clear cachedMetrics when one of these changes.
const CACHED_METRICS_STATES_KEY = 'cachedMetricsStates';

// By default, any change in URL state will clear cached metrics. For example, the visible recurring
// revenue should not stay the same when isARR is toggled. However, for some states, we don't want to clear
// cached metrics.
export const EXCLUDABLE_URL_STATE_KEYS = ['rollup', 'dataFilter', 'growthType', 'isIncreases'];

const safeGetFromStorage = (key) => {
  try {
    return JSON.parse(sessionStorage.getItem(key)) ?? {};
  } catch {
    return {};
  }
};

const getCachedMetrics = () => safeGetFromStorage(CACHED_METRICS_KEY);
const getCachedMetricsStates = () => safeGetFromStorage(CACHED_METRICS_STATES_KEY);

export const storeCachedMetricsStates = ({ states }) => {
  const cachedMetricStates = getCachedMetricsStates();
  let hasChanged = false;

  Object.entries(states).forEach(([key, value]) => {
    if (!EXCLUDABLE_URL_STATE_KEYS.includes(key)) {
      if (!isEqual(cachedMetricStates[key], value)) {
        hasChanged = true;
        cachedMetricStates[key] = value;
      }
    }
  });

  if (hasChanged) {
    clearCachedMetrics();

    // If the states that the current cachedMetrics was calculated based on have changed, we
    // don't want to store anything until we get a newer version of readyData. We do this by marking
    // this timestamp here. In checkOutDatedMetrics, if the caller provides a timestamp at which
    // it receives an API response, we will make sure that that timestamp is newer than this timestamp.
    cachedMetricStates.cachedMetricsClearedAt = new Date();

    sessionStorage.setItem(CACHED_METRICS_STATES_KEY, JSON.stringify(cachedMetricStates));
  }
};

const setValue = (cachedMetrics, key, value) => {
  deepAssign(cachedMetrics, key, value);
  sessionStorage.setItem(CACHED_METRICS_KEY, JSON.stringify(cachedMetrics));
};

// Returns true if after running the check, we decide that we will not do anything because the data
// given is outdated.
const checkOutdatedMetrics = ({ lastTransactionUpdatedAt, readyDataRespondedAt }) => {
  const cachedMetricStates = getCachedMetricsStates();
  let hasChanged = false;
  let shouldSkipStoring = false;

  if (!cachedMetricStates.lastTransactionUpdatedAt) {
    cachedMetricStates.lastTransactionUpdatedAt = lastTransactionUpdatedAt;
    hasChanged = true;
  } else if (new Date(cachedMetricStates.lastTransactionUpdatedAt) < new Date(lastTransactionUpdatedAt)) {
    clearCachedMetrics();
    cachedMetricStates.lastTransactionUpdatedAt = lastTransactionUpdatedAt;
    cachedMetricStates.cachedMetricsClearedAt = null;
    hasChanged = true;
  }

  if (cachedMetricStates.cachedMetricsClearedAt && readyDataRespondedAt) {
    // This response is not newer than the last time cached metrics was cleared. We only set cachedMetricsClearedAt
    // when we expect a newer response from the API.
    if (new Date(readyDataRespondedAt) <= new Date(cachedMetricStates.cachedMetricsClearedAt)) {
      shouldSkipStoring = true;
    } else {
      cachedMetricStates.cachedMetricsClearedAt = null;
      hasChanged = true;
    }
  }

  if (hasChanged) {
    sessionStorage.setItem(CACHED_METRICS_STATES_KEY, JSON.stringify(cachedMetricStates));
  }

  return shouldSkipStoring;
};

// Store a value in the session storage with the given key. If a value already exists
// with that key, check if this new value is equal to the old value. Raise a Sentry exception
// if the values don't match.
export const storeCachedMetrics = ({
  organization,
  user,
  key,
  readyData,
  getValue,
  description,
  context,
  lastTransactionUpdatedAt,
  readyDataRespondedAt,
  assertion = assertCloseTo,
}) => {
  // Don't block UI unnecessarily
  setTimeout(() => {
    const storeValue = ({ organization, user, key, value, description, context }) => {
      const cachedMetrics = getCachedMetrics();
      const existingValue = dig(cachedMetrics, key);
      if (existingValue) {
        // After we change string keys to nested object in #3637 we start to receive undefined for some keys
        // This will fix that. We might be able to remove in the future
        if (isNaN(existingValue)) {
          setValue(cachedMetrics, key, value);
        } else {
          assertion({
            organization,
            user,
            expected: existingValue,
            actual: value,
            description,
            context,
          });
        }
      } else {
        setValue(cachedMetrics, key, value);
      }

      INVARIANT_CHECKERS.forEach((checker) => {
        bypassError(() => checker({ organization, user, cachedMetrics, key, value, context }));
      });
    };

    const isMetricsOutdated = checkOutdatedMetrics({ lastTransactionUpdatedAt, readyDataRespondedAt });
    if (isMetricsOutdated) return;

    const value = getValue({ readyData });
    // So we can get a whole object with keys for all the months at once
    if (typeof value === 'object') {
      Object.entries(value).forEach(([month, value]) => {
        storeValue({
          value,
          organization,
          user,
          key: `${key}.${month}`,
          description: `${description} for ${month}`,
          context,
        });
      });
    } else {
      storeValue({
        value,
        organization,
        user,
        key,
        description,
        context,
      });
    }
  }, 0);
};

export const clearCachedMetrics = () => {
  sessionStorage.removeItem(CACHED_METRICS_KEY);
};
