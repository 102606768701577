import * as Yup from 'yup';

export const getSchema = () => {
  const schema = Yup.array().of(
    Yup.object({
      date: Yup.date().typeError('Date must be a valid date').required('Please select a date'),
    }),
  );

  return schema;
};
