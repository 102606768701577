import styled from 'styled-components';
import { Centerer } from 'components/Core';

export const ModalTitleLabel = styled.div`
  margin-left: 8px;
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 38px;
`;

export const CardChartSectionIcon = styled(Centerer)`
  padding: 10px;
  width: 40px;
  height: 40px;
  border: 1px solid var(--accentGraySecond);
  border-radius: 8px;
`;

export const CardChartSectionTitle = styled.div`
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  color: var(--primaryBlack);
`;
