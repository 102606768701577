import dayjs from 'dayjs';
import { cloneDeep, omit } from 'lodash';
import { RECOGNITION_TYPES } from 'consts/global';

export const buildCustomersTree = (customers) => {
  const customersTree = cloneDeep(customers);

  customersTree.forEach((customer) => {
    if (customer.parent_customer_id) {
      const parentCustomer = customersTree.find((parentCustomer) => parentCustomer.id === customer.parent_customer_id);
      if (parentCustomer) {
        if (parentCustomer.subRows) {
          parentCustomer.subRows.push(customer);
        } else {
          parentCustomer.subRows = [customer];
        }
      }
    }
  });

  return customersTree;
};

export const isCustomerTransactionUpForRenewal = ({ row }) =>
  row.original.end_date &&
  dayjs().add(1, 'month').isAfter(dayjs.utc(row.original.end_date)) &&
  [RECOGNITION_TYPES.linear, RECOGNITION_TYPES.tillCanceled].includes(row.original.recognition);

export const getPercentDifference = ({ previousMRR, currentMRR }) => {
  if (previousMRR || currentMRR) {
    const difference = currentMRR / previousMRR;
    let returnValue;
    if (difference === Infinity) {
      returnValue = 100;
    } else if (isNaN(difference)) {
      returnValue = -100;
    } else {
      returnValue = Math.floor((difference - 1) * 100);
    }
    return returnValue;
  } else {
    return null;
  }
};

// On Customers table, we don't care about the date range specified on Dashboard
export const cleanCustomerMetadata = (filters) => filters?.map((f) => omit(f, ['_startMonth', '_endMonth']));

export const getRequiredScopes = ({ visibleColumns }) => {
  const scopes = [];

  if (['currentMRR', 'nonRecurringRevenue', 'start_date'].some((column) => visibleColumns.includes(column))) {
    scopes.push('current_and_previous_MRR_and_customer_lifetime');
  }
  if (visibleColumns.includes('transactionSum')) {
    scopes.push('transactions_sum');
  }
  if (visibleColumns.includes('external_ids')) {
    scopes.push('imports');
  }
  if (visibleColumns.includes('invoices')) {
    scopes.push('invoices');
  }

  return scopes;
};
