import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { ReactComponent as NoIntegrationImage } from 'images/noIntegrations.svg';
import { ReactComponent as SettingIcon } from 'images/nav_configure_icon.svg';
import { IconGreenButton } from 'components/Buttons';
import { useAddIntegrationModal } from 'shared/AddIntegrationModal/useAddIntegrationModal';
import { Spacer } from 'components/Core';

const Wrapper = styled.div`
  top: 40%;
  background: rgba(0, 21, 46, 0.03);
  position: absolute;
  border-radius: 20px;
  padding: 80px 40px 40px;
`;
const Heading = styled.div`
  font-weight: 900;
  font-size: 24px;
  font-family: 'Nunito Sans';
`;

const Desc = styled.p`
  font-weight: normal;
  font-size: 16px;
  font-family: Nunito Sans;
  text-align: center;
  margin-top: 12px;
  max-width: 380px;
`;

const ContactLink = styled.a`
  font-weight: normal;
  font-size: 14;
  font-family: Nunito Sans;
  text-align: center;
  text-decoration: underline;
  color: var(--primaryBlack);

  :hover {
    color: var(--primaryGreen);
  }
`;

export const NoIntegrations = () => {
  const { openAddIntegrationModal, AddIntegrationModal } = useAddIntegrationModal();
  return (
    <>
      <div className="flexer" style={{ height: '85%', overflow: 'hidden', position: 'relative' }}>
        <Wrapper className="flexer-col">
          <NoIntegrationImage style={{ position: 'absolute', top: '-75%' }} />
          <Heading>You don't have any data</Heading>
          <Desc>To start using Subscript you need to add transactions manually or from your CRM</Desc>
          <Link to="/transactions">
            <IconGreenButton icon={<SettingIcon style={{ marginRight: 10 }} />} text="Add Transactions Manually" />
          </Link>
          <Spacer height="10px" />
          <IconGreenButton
            icon={<SettingIcon style={{ marginRight: 10 }} />}
            text="Import from CRM"
            onClick={openAddIntegrationModal}
          />
          <ContactLink href="mailto:help@subscript.com" className="mt-1">
            Contact support
          </ContactLink>
        </Wrapper>
      </div>
      <AddIntegrationModal />
    </>
  );
};
