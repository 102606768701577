import React, { useMemo } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import styled from 'styled-components';

import { ISO_CODE_TO_SYMBOL } from 'consts/global';
import { Flexer, FlexerColumn, Spacer } from 'components/Core';
import { FormikCustomInput } from 'components/Controls';
import { HelpCircleIcon, TrashIcon } from 'components/Icons';
import { DescriptionInput } from 'views/Billing/Common/styles';
import { TooltipContainer } from 'components/Tooltip';

import { InvoiceItemRecognition, RemoveWrapper } from '../../../InvoiceScheduleWarnings/styles';
import { getFieldChangesProps } from '../utils';

const FlexGrowContianer = styled.div`
  flex-grow: 1;
  position: relative;
`;

const StyledHelpCircleIcon = styled(HelpCircleIcon)`
  opacity: 0.5;
`;

const HelperWrapper = styled.div`
  position: absolute;
  top: 30%;
  right: 16px;
`;

export const MergedInvoiceItem = ({
  index,
  group,
  disableEditing,
  setFieldValue,
  values,
  isDragging,
  currency,
  invoiceItemById,
  // for drag and drop use
  groupId,
  invoicingServiceDisplayName,
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: groupId,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    width: '100%',
    paddingTop: index === 0 ? 6 : 20,
    visibility: isDragging ? 'hidden' : 'visible',
    paddingBottom: !group.invoice_items_ids?.length && 20,
    borderBottom: !group.invoice_items_ids?.length && '1px solid var(--primaryBlack5)',
  };

  const totalAmount = useMemo(
    () => group.invoice_items_ids.reduce((sum, id) => sum + (invoiceItemById[id]?.amount ?? 0), 0),
    [group.invoice_items_ids, invoiceItemById],
  );

  return (
    <FlexerColumn key={index} gap="8px" style={style} ref={setNodeRef} {...attributes}>
      <Flexer gap="16px" width="100%" alignItems="center">
        <FlexerColumn gap="8px" width="100%">
          <Flexer gap="12px" alignItems="center">
            <InvoiceItemRecognition {...listeners} index={index} disableEditing={disableEditing}>
              {index + 1}
            </InvoiceItemRecognition>
            <FlexGrowContianer>
              <FormikCustomInput
                name={`grouping[${index}].name`}
                width="100%"
                placeholder="Enter name..."
                isDisabled={disableEditing}
                handleChange={(value) => setFieldValue(`grouping[${index}].name`, value)}
                data-cy={`billing__invoice_schedule-modal__merged-invoice-item__${index}-name-input`}
                {...getFieldChangesProps({ values, field: `grouping[${index}].name` })}
              />
              {invoicingServiceDisplayName && (
                <HelperWrapper>
                  <TooltipContainer
                    toolTipContent={`The first invoice item product name of "${values.grouping?.[index]?.name}" will be used instead when saving to ${invoicingServiceDisplayName}`}
                    fontSize="12px"
                    width={250}
                  >
                    <StyledHelpCircleIcon />
                  </TooltipContainer>
                </HelperWrapper>
              )}
            </FlexGrowContianer>
            <FormikCustomInput
              name={`grouping[${index}].seats`}
              inputWidth="80px"
              type="number"
              precision={2}
              handleChange={(value) => setFieldValue(`grouping[${index}].seats`, value)}
              data-cy={`billing__invoice_schedule-modal__merged-invoice-item__${index}-seats-input`}
              {...getFieldChangesProps({ values, field: `grouping[${index}].seats` })}
            />
            <FormikCustomInput
              name={`grouping[${index}].amount`}
              inputWidth="120px"
              type="number"
              isDisabled
              suffix={ISO_CODE_TO_SYMBOL[values?.invoice_items?.[0]?.currency ?? currency] ?? '$'}
              precision={2}
              value={totalAmount}
              data-cy={`billing__invoice_schedule-modal__merged-invoice-item__${index}-amount-input`}
              {...getFieldChangesProps({ values, field: `grouping[${index}].amount` })}
            />
          </Flexer>
        </FlexerColumn>

        <Spacer width="4px" />

        <RemoveWrapper
          onClick={() => {
            const updatedGrouping = values.grouping.flatMap((g, i) =>
              i === index ? g.invoice_items_ids.map((id) => ({ invoice_items_ids: [id] })) : g,
            );
            setFieldValue('grouping', updatedGrouping);
            // update the order of invoice items
            setFieldValue(
              'invoice_items',
              updatedGrouping.flatMap((g) => g.invoice_items_ids).map((id) => invoiceItemById[id]),
            );
          }}
        >
          <TrashIcon />
        </RemoveWrapper>
      </Flexer>

      <DescriptionInput>
        <FormikCustomInput
          name={`grouping[${index}].description`}
          isDisabled={disableEditing}
          placeholder="Enter the merged item description"
          handleChange={(value) => setFieldValue(`grouping[${index}].description`, value)}
          data-cy={`billing__invoice_schedule-modal__merged-invoice-item__${index}-description-input`}
          {...getFieldChangesProps({ values, field: `grouping[${index}].description` })}
        />
      </DescriptionInput>
    </FlexerColumn>
  );
};
