import React, { useCallback, useState } from 'react';
import { isEqual } from 'lodash';
import { EVENTS } from 'consts/analytics';
import { useAnalytics } from 'utils/hooks';
import { InvoicePreviewModal } from './InvoicePreviewModal';
import { useAuth0 } from '@auth0/auth0-react';

export const useInvoicePreviewModal = ({ orgId, invoiceId, invoicingScheduleId }) => {
  const { user } = useAuth0();
  const { trackEvent } = useAnalytics();
  const [showModal, setShowModal] = useState(false);
  const [modalProps, setModalProps] = useState({});

  const openModal = useCallback(
    (props = {}) => {
      setShowModal(true);
      !isEqual(modalProps, props) && setModalProps(props);

      trackEvent({
        name: EVENTS.OPEN_INVOICE_PREVIEW_MODAL,
        properties: {
          orgId,
          invoiceId,
          invoicingScheduleId,
          userEmail: user?.email,
        },
      });
    },
    [modalProps, trackEvent, orgId, invoiceId, invoicingScheduleId, user],
  );

  const closeModal = useCallback(() => {
    setShowModal(false);
  }, [setShowModal]);

  //we don't need to pass invoice since it's gets from main modal context
  const Modal = useCallback(() => (showModal ? <InvoicePreviewModal onClose={closeModal} {...modalProps} /> : null), [
    showModal,
    closeModal,
    modalProps,
  ]);

  return {
    openModal,
    closeModal,
    Modal,
    isModalOpen: showModal,
  };
};
