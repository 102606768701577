import { useAuth0 } from '@auth0/auth0-react';
import { isNil } from 'lodash';
import dayjs from 'dayjs';
import { AppContext } from 'AppContext';
import { useContext, useEffect } from 'react';
import { storeCachedMetricsStates, storeCachedMetrics } from 'utils/cachedMetricsUtils';
import { getUrlParams } from 'utils/urlUtils';
import { urlDecode } from './useUrlStates';

/**
 * If the value at storageKey is missing, assign the return of getValue() to storageKey.
 * Otherwise, assert that the value at storageKey is to the return of getValue().
 *
 * @param readyData {*} if null or undefined, data is not ready and should do nothing
 * @param getValue {Function} a function that converts readyData to the value to store at storageKey
 * @param description {String} only relevant as part of the message to post to Slack when assertion fails
 * @param context {String} where this assertion comes from for debugging purposes
 * @param lastTransactionUpdatedAt {Date} if given and is older than lastTransactionUpdatedAt stored
                                          in sessionStorage, we do nothing
 * @param readyDataRespondedAt {Date} if given and NOT newer than cachedMetricsClearedAt stored
 *                                    in ssessionStorage, we do nothing
 */
export const useCachedMetrics = ({
  readyData,
  getValue = ({ readyData }) => readyData,
  description,
  storageKey,
  context,
  lastTransactionUpdatedAt,
  readyDataRespondedAt,
  deps = [],
  assertion,
}) => {
  const { organizations } = useContext(AppContext);
  const { user } = useAuth0();

  const params = getUrlParams();
  const urlStates = params.s;

  useEffect(() => {
    storeCachedMetricsStates({ states: urlDecode(urlStates) });
  }, [urlStates]);

  useEffect(() => {
    if (!isNil(readyData)) {
      storeCachedMetrics({
        readyData,
        getValue,
        organization: organizations[0],
        key: storageKey,
        description,
        context,
        user,
        lastTransactionUpdatedAt: lastTransactionUpdatedAt ?? readyData.lastTransactionUpdatedAt,
        readyDataRespondedAt: readyDataRespondedAt ?? readyData.respondedAt,
        assertion,
      });
    }
    // eslint-disable-next-line
  }, [readyData, organizations, ...deps]);
};

// Convenience function when storing recurring revenues at a given month with given settings
export const useRecurringRevenueCachedMetrics = ({ isCommitted, rollup, month, ...rest }) => {
  let description = `${rollup ? 'Rolled-up ' : ''}${isCommitted ? 'Committed ' : 'Classical '}Recurring Revenue`;
  if (month) description += ` in ${dayjs(month).format('YYYY-MM')}`;

  let storageKey = `recurringRevenue.${isCommitted ? 'committed' : 'classical'}.${
    rollup ? 'withRollup' : 'withoutRollup'
  }`;
  if (month) storageKey += `.${dayjs(month).format('YYYY-MM')}`;

  useCachedMetrics({
    description,
    storageKey,
    ...rest,
  });
};
