import React, { useCallback } from 'react';
import styled, { css } from 'styled-components';
import { ReactComponent as AuthorizedIcon } from 'images/authorized_icon.svg';

const StyledButton = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding: 12px;
  font-weight: bold;
  font-size: 14px;
  flex-grow: 2;
  cursor: pointer;
  width: ${({ width }) => width || '100%'};
  border: ${({ active }) => (active ? '2px solid var(--primaryBlue)' : '1px solid lightgrey')};
  color: ${({ active }) => (active ? 'var(--primaryBlue)' : 'var(--primaryBlack)')};
  box-shadow: ${({ active }) => (active ? '0px 4px 20px var(--primaryBlack10);' : 'none')};
  margin-right: 10px;
  border-radius: 8px;
  outline: none;
  ${({ isDisabled }) =>
    isDisabled &&
    css`
      filter: grayscale(100%);
      cursor: not-allowed;
    `}

  svg {
    margin-right: 10px;
  }

  &:last-child {
    margin-right: 0;
  }
`;

const StyledIcon = styled.div`
  display: ${({ active }) => (active ? 'block' : 'none')};
  position: absolute;
  right: 6px;
  top: 6px;

  svg {
    margin-right: 0;
  }
`;

export const IntegrationButton = ({
  title,
  active,
  icon: Icon,
  width,
  connected,
  configurationRequired,
  disabled = false,
  onClick,
  tabKey,
}) => {
  const handleClick = useCallback(() => {
    if (!disabled) onClick(tabKey);
  }, [disabled, onClick, tabKey]);

  return (
    <StyledButton onClick={handleClick} active={active} width={width} isDisabled={disabled}>
      {configurationRequired && connected && (
        <StyledIcon active={active}>
          <AuthorizedIcon />
        </StyledIcon>
      )}
      <Icon />
      {title}
    </StyledButton>
  );
};
