import styled from 'styled-components';

export const SubaccountButton = styled.div`
  cursor: pointer;
  text-decoration: none;
  cursor: pointer;
  position: absolute;
  right: 0;
  font-size: 12px;
  line-height: 16px;
  z-index: 5;
  color: ${({ hasParentCustomer }) => (hasParentCustomer ? 'var(--primaryRed)' : 'var(--primaryBlue)')};

  &:hover {
    color: ${({ hasParentCustomer }) => (hasParentCustomer ? 'var(--secondaryRed)' : 'var(--secondaryBlue)')};
  }
`;

export const ParentCustomerName = styled.div`
  padding: 8px 10px;
  background: var(--primaryBlack3);
  border: 1px solid var(--primaryBlack3);
  font-size: 12px;
  line-height: 16px;
  border-radius: 0px 0px 8px 8px;
  margin-top: 2px;
  display: flex;
  justify-content: space-between;

  b {
    opacity: 0.5;
    margin-right: 4px;
    font-weight: normal;
  }
`;
