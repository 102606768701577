import styled from 'styled-components';
import { cssVar, transparentize } from 'polished';
import { StyledTableName } from 'components/Table';
import { Flexer } from 'components/Core';

export const ProductsTabContent = styled.div`
  margin-top: 28px;
  margin-bottom: 22px;
`;

export const CategoryTitle = styled.div`
  font-weight: 700;
  margin-left: 8px;
  margin-right: 16px;
  color: var(--primaryBlue);
`;

export const ProductTitle = styled.div`
  font-weight: 700;
  color: var(--primaryBlue);
`;

export const ProductsTabTitle = styled.div`
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 26px;

  color: var(--primaryDark);
`;

export const ProductsTableName = styled(StyledTableName)`
  cursor: pointer;
`;

export const ProductsTableCell = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;

  text-transform: capitalize;
  color: var(--primaryDark);
`;

export const ProductsModalHeaderIcon = styled.div`
  background-color: #ffffff;
  border-radius: 50%;
  display: inline-block;
  left: 50%;
  padding: 11px;
  position: absolute;
  top: -20px;
  transform: translate(-50%, 0);
`;

export const ProductsModalListName = styled(Flexer)`
  padding: 10px 0;

  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;

  align-items: center;
  gap: 8px;

  color: var(--primaryDark);
`;

export const ProductsModalListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0;

  background: rgba(0, 21, 46, 0.03);
  border-radius: 8px;
  margin: 8px 0;

  ${ProductsModalListName} {
    border-bottom: 1px solid ${transparentize(0.97, cssVar('--primaryDark'))};
  }

  ${ProductsModalListName}:last-child {
    border-bottom: none;
  }
`;
