import { TooltipContainer } from 'components/Tooltip';

export const EmojiTooltip = ({ value }) => {
  return value.label ? (
    <TooltipContainer isVisible={true} width={150} toolTipContent={value.label}>
      {value.icon}
    </TooltipContainer>
  ) : (
    value
  );
};
