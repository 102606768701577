import React, { useMemo } from 'react';

import { DEFAULT_AVAILABLE_TAGS, DESCRIPTION_TAGS } from 'consts/billing';
import { BillingDynamicFieldsTooltip, DYNAMIC_FIELD_TOOLTIP_SECTIONS } from 'views/Billing/BillingDynamicFieldsTooltip';
import { templateToHTMLString } from 'utils/htmlUtils';
import { FieldBody, FieldTitle } from 'views/Wizard/styles';
import { TrashIcon } from 'components/Icons';
import { CentererVertical, FlexerColumn, LabelText } from 'components/Core';
import { TooltipContainer } from 'components/Tooltip';
import {
  FILE_TYPES,
  FileUploader,
  FormikCustomInput,
  FormikCustomRadio,
  formatFileSize,
  FormikCustomCheckbox,
} from 'components/Controls';

import { FileCard, FormRow, PaddingWrapper } from './styles';
import { ApplyToAllCheckboxes } from './ApplyToAllCheckboxes';

export const MainInvoiceSettings = ({
  values,
  setFieldValue,
  initialBillingInvoiceDefaults,
  transactionMetadataTags,
  customerMetadataTags,
  transactionCustomerMetadataTags,
}) => {
  const availableTags = useMemo(
    () => [
      ...DEFAULT_AVAILABLE_TAGS,
      ...transactionMetadataTags,
      ...customerMetadataTags,
      ...transactionCustomerMetadataTags,
    ],
    [customerMetadataTags, transactionMetadataTags, transactionCustomerMetadataTags],
  );

  const sendDateConfigIsChanged =
    (initialBillingInvoiceDefaults.send_x_days_before ?? null) !==
    (values.showSendXDaysBeforeConfig ? values.billingInvoiceDefaults.send_x_days_before ?? 0 : null);

  const invoiceAttachments = useMemo(
    () => [{ name: 'Invoice.pdf' }, ...(values.billingInvoiceDefaults.attachments ?? [])],
    [values.billingInvoiceDefaults.attachments],
  );

  const handleAddInvoiceAttachment = (file) => {
    setFieldValue('billingInvoiceDefaults.attachments', [
      ...(values.billingInvoiceDefaults.attachments ?? []),
      {
        name: file.name,
        s3_file_key: file.s3FileKey,
        size: file.size,
        type: file.type,
      },
    ]);
  };

  const handleDeleteInvoiceAttachment = (index) => {
    const attachments = [...(values.billingInvoiceDefaults.attachments ?? [])];
    attachments.splice(index, 1);
    setFieldValue('billingInvoiceDefaults.attachments', attachments);
  };

  return (
    <FieldBody style={{ padding: 0, overflow: 'hidden' }}>
      <PaddingWrapper>
        <FieldTitle>
          <span>Additional settings for invoices</span>
        </FieldTitle>

        <FormRow marginTop="16px" childrenWidth="100%">
          <FormikCustomRadio
            suffix="configuration__send-x-days-before-toggle"
            name="showSendXDaysBeforeConfig"
            width="400px"
            options={[
              { label: 'Send all invoices On invoice date', value: false },
              { label: 'Send all invoices Prior to invoice date', value: true },
            ]}
          />
        </FormRow>
        <FormRow marginTop="16px" childrenWidth="100%">
          <FormikCustomRadio
            suffix="configuration__post-pay-events"
            name="billingInvoiceDefaults.post_pay_events"
            width="400px"
            options={[
              { label: 'Bill events within the period in which they occur', value: false },
              { label: 'Bill events after the period in which they occur', value: true },
            ]}
            label="Do you bill Event based revenue (like Usage revenue) in the period that it happens, or the following period?"
          />
        </FormRow>
        {values.showSendXDaysBeforeConfig && (
          <FormRow marginTop="16px" childrenWidth="100%">
            <FormikCustomInput
              data-cy="configuration__send-x-days-before-input"
              name="billingInvoiceDefaults.send_x_days_before"
              minHeight="186px"
              label="How many days before?"
              type="number"
              placeholder="# of days"
            />
          </FormRow>
        )}

        <FormRow marginTop="16px" childrenWidth="100%">
          <FormikCustomInput
            name="billingInvoiceDefaults.invoice_item_description"
            label="Invoice Item Description"
            HTMLString={templateToHTMLString({
              text: values?.billingInvoiceDefaults?.invoice_item_description,
              availableTags: [...availableTags, ...DESCRIPTION_TAGS],
            })}
            availableTags={[...availableTags, ...DESCRIPTION_TAGS]}
            LabelAction={
              <CentererVertical gap="8px">
                <BillingDynamicFieldsTooltip
                  tags={{
                    [DYNAMIC_FIELD_TOOLTIP_SECTIONS.GENERAL_TAGS]: DEFAULT_AVAILABLE_TAGS,
                    [DYNAMIC_FIELD_TOOLTIP_SECTIONS.DESCRIPTION_TAGS]: DESCRIPTION_TAGS,
                    [DYNAMIC_FIELD_TOOLTIP_SECTIONS.TRANSACTION_METADATA_TAGS]: transactionMetadataTags,
                    [DYNAMIC_FIELD_TOOLTIP_SECTIONS.CUSTOMER_METADATA_TAGS]: customerMetadataTags,
                    [DYNAMIC_FIELD_TOOLTIP_SECTIONS.TRANSACTION_CUSTOMER_METADATA_TAGS]: transactionCustomerMetadataTags,
                  }}
                />
              </CentererVertical>
            }
            type="HTMLEdit"
            placeholder="Enter invoice item description..."
            data-cy="billing-configuration__invoice-item-description"
          />
        </FormRow>

        <FormRow marginTop="16px" childrenWidth="100%">
          <FlexerColumn>
            <LabelText
              style={{
                color: 'var(--primaryBlack40)',
              }}
            >
              Attachments ({invoiceAttachments?.length})
            </LabelText>
            <FlexerColumn gap="8px">
              <FileUploader
                onFileUpload={handleAddInvoiceAttachment}
                fileTypes={FILE_TYPES.PDF}
                maxFileSize={10485760}
                smallView
                infoText="Attach files that Subscript will send along with every invoice"
                showFileUploaded={false}
                privateBucket
              />
              {invoiceAttachments.map((attachment, index) => (
                <FileCard key={attachment.name}>
                  <span>
                    {attachment.name} {index !== 0 ? `(${formatFileSize(attachment.size)})` : ''}
                  </span>
                  {index !== 0 && (
                    <TrashIcon
                      size="16px"
                      fill="var(--primaryBlack)"
                      onClick={() => handleDeleteInvoiceAttachment(index - 1)}
                    />
                  )}
                </FileCard>
              ))}
            </FlexerColumn>
          </FlexerColumn>
        </FormRow>

        <FormRow marginTop="16px" childrenWidth="100%">
          <TooltipContainer
            toolTipContent={
              'Some GLs (Especially Xero) require the amount / quantity to have fewer than 4' +
              ' decimal places. This can happen for a variety of reasons, and we recommend simply moving the quantity to the description in that case.'
            }
            tooltipWidth={400}
          >
            <FormikCustomCheckbox
              name="billingInvoiceDefaults.quantity_in_description_if_long_rate"
              label={
                <>
                  If the rate for a line item has more than 4 decimal places, show the quantity in the description
                  instead
                </>
              }
            />
          </TooltipContainer>
        </FormRow>
      </PaddingWrapper>

      <ApplyToAllCheckboxes
        isShowing={sendDateConfigIsChanged}
        name="updateExistingInvoicesSendDate"
        changedEntity="send date"
      />
    </FieldBody>
  );
};
