import React, { useContext, useEffect, useState } from 'react';

import { AppContext } from 'AppContext';
import { INTEGRATION_METADATA_FIELDS, INTEGRATION_SERVICES, INTEGRATION_TYPES } from 'consts/integrations';
import { getMemberList } from 'api/members';
import { ReactComponent as MailIcon } from 'images/mail-icon.svg';
import { ReactComponent as SlackIcon } from 'images/slack-icon.svg';
import { ReactComponent as UsersIcon } from 'images/users.svg';

import { MembersModal } from 'shared/MembersModal';

import { Flexer, Spacer } from 'components/Core';
import { EmailReportFrequencySelector } from './EmailReportFrequencySelector';

import {
  ButtonStyling,
  StyledButton,
  MetricsSection,
  OptionsSection,
  StyledCheckIcon,
  Subtitle,
  Title,
  MembersButton,
  ListItem,
  ListSection,
} from './styles';
import { EmailReportForShareSelector } from './EmailReportForShareSelector';
import { EmailSelector } from 'components/Controls';

const Button = ({ connected, showIcon = true, children, ...rest }) => (
  <ButtonStyling connected={connected} {...rest}>
    {showIcon && connected && <StyledCheckIcon />}
    {children}
  </ButtonStyling>
);

export const ReportSubscribe = ({
  analyticsIntegrations,
  integrationTypes,
  hasActiveAnalyticsIntegrations,
  immediatelyToggleEmailIntegration,
  deactivateAllIntegrations,
  updateAnalyticsIntegration,
}) => {
  const { orgId } = useContext(AppContext);
  /*const { InProgressModal, openInProgressModal } = useInProgressModal({
    description: 'Soon you will be able to change your reports metrics.',
    trackEventName: EVENTS.OPEN_CHANGE_RECURRING_UPDATES_METRICS,
  });*/
  const [showMembersModal, setShowMembersModal] = useState(false);
  const handleClickMembersText = (event) => {
    event.stopPropagation();
    setShowMembersModal(true);
  };

  const isEmailConnected =
    analyticsIntegrations[INTEGRATION_SERVICES.EMAIL] &&
    !analyticsIntegrations[INTEGRATION_SERVICES.EMAIL].metadata[INTEGRATION_METADATA_FIELDS.SKIP_DAILY_SYNC];

  const isSlackConnected =
    analyticsIntegrations[INTEGRATION_SERVICES.SLACK] &&
    !analyticsIntegrations[INTEGRATION_SERVICES.SLACK].metadata[INTEGRATION_METADATA_FIELDS.SKIP_DAILY_SYNC];

  useEffect(() => {
    if (immediatelyToggleEmailIntegration && !isEmailConnected) {
      updateAnalyticsIntegration({ service: INTEGRATION_SERVICES.EMAIL, active: true });
    }
  }, [immediatelyToggleEmailIntegration, updateAnalyticsIntegration, isEmailConnected]);

  //Get inital state of the email integrations to load and set manually added emails

  const [members, setMembers] = useState([]);
  const [manuallyAddedEmails, setManuallyAddedEmails] = useState([]);

  useEffect(() => {
    const initialEmailIntegration = (integrationTypes ?? []).find(
      (integration) =>
        integration.type === INTEGRATION_TYPES.Analytics && integration.service === INTEGRATION_SERVICES.EMAIL,
    );

    if (initialEmailIntegration?.metadata?.[INTEGRATION_METADATA_FIELDS.NON_MEMBERS_EMAILS]) {
      setManuallyAddedEmails(initialEmailIntegration?.metadata?.[INTEGRATION_METADATA_FIELDS.NON_MEMBERS_EMAILS]);
    }
  }, [integrationTypes]);

  const fetchMembers = async () => {
    const data = await getMemberList(orgId);
    setMembers(data.map((member) => member.email));
  };

  useEffect(() => {
    fetchMembers();
    // eslint-disable-next-line
  }, [orgId]);

  useEffect(() => {
    updateAnalyticsIntegration({
      service: INTEGRATION_SERVICES.EMAIL,
      additionalMetadata: {
        [INTEGRATION_METADATA_FIELDS.NON_MEMBERS_EMAILS]: manuallyAddedEmails,
      },
    });
    // eslint-disable-next-line
  }, [manuallyAddedEmails]);

  return (
    <Flexer direction="column">
      <OptionsSection>
        <Button
          flexGrow={1}
          connected={!hasActiveAnalyticsIntegrations}
          onClick={deactivateAllIntegrations}
          showIcon={false}
        >
          <Flexer direction="column">
            <Title>Disabled</Title>
            <Subtitle>No updates</Subtitle>
          </Flexer>
        </Button>
        <Spacer width="10px" />
        <Button
          flexGrow={3}
          connected={isEmailConnected}
          onClick={() => updateAnalyticsIntegration({ service: INTEGRATION_SERVICES.EMAIL, active: !isEmailConnected })}
        >
          <MailIcon />
          <Spacer width="8px" />
          <Flexer direction="column">
            <Title>Email</Title>
            <Subtitle>
              All <MembersButton onClick={handleClickMembersText}>members</MembersButton> will receive updates
            </Subtitle>
          </Flexer>
        </Button>
        <Spacer width="10px" />
        <Button
          flexGrow={3}
          connected={isSlackConnected}
          onClick={() => updateAnalyticsIntegration({ service: INTEGRATION_SERVICES.SLACK, active: !isSlackConnected })}
        >
          <SlackIcon />
          <Spacer width="8px" />
          <Flexer direction="column">
            <Title>Slack</Title>
            <Subtitle>Get updates to your channel</Subtitle>
          </Flexer>
          {/*Show connect button only if SLACK integration is not already been added*/}
          {!analyticsIntegrations[INTEGRATION_SERVICES.SLACK]?.id && <StyledButton>Connect</StyledButton>}
        </Button>
      </OptionsSection>
      {hasActiveAnalyticsIntegrations && (
        <>
          {/* [JB 2022-10-28] Will hide for now but will keep code for future use
            https://github.com/ChifferCo/chiffer/issues/4130#issuecomment-1294828291
            <ReportSubscribeSelectors />
          */}
          <ListSection>
            <ListItem>
              <UsersIcon />
              <p>
                <b>Members</b> who will receive updates via Email:
              </p>
            </ListItem>

            <EmailSelector
              name="report-subscribe"
              value={manuallyAddedEmails}
              onChange={setManuallyAddedEmails}
              fixedValues={members}
            />

            <Spacer height="20px" />

            <EmailReportFrequencySelector
              analyticsIntegrations={analyticsIntegrations}
              updateAnalyticsIntegration={updateAnalyticsIntegration}
            />
          </ListSection>

          <MetricsSection direction="column">
            <EmailReportForShareSelector
              updateAnalyticsIntegration={updateAnalyticsIntegration}
              analyticsIntegrations={analyticsIntegrations}
              orgId={orgId}
            />
          </MetricsSection>
        </>
      )}
      {showMembersModal && <MembersModal onClose={() => setShowMembersModal(false)} />}
      {/*<InProgressModal />*/}
    </Flexer>
  );
};
