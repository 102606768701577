import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { getStartAndEndOfPeriodFromQuarterString, getStartAndEndOfYearPeriod } from 'utils/dateUtils';
import { lowerFirst } from 'utils/stringUtils';

dayjs.extend(utc);

const _generateAvgAndARRMetricTitle = (metrics) => {
  const clonedMetrics = { ...metrics };
  for (const [key, value] of Object.entries(metrics)) {
    clonedMetrics[`${key}ARR`] = `Annualized ${lowerFirst(value)}`;
    clonedMetrics[`${key}Avg`] = `Average ${lowerFirst(value)}`;
    clonedMetrics[`${key}AvgARR`] = `Annualized average ${lowerFirst(value)}`;
  }
  return clonedMetrics;
};

export const getExplainMoadalMetricTitleByKey = ({
  row,
  isQuarterMetric,
  isYearMetric,
  isCumulativeMetric,
  isTTMMetric,
  date,
  dataFilterEndMonth,
  dataFilterStartMonth,
  quarters,
  quartersYearOffset,
  usesMetricsEngine,
}) => {
  const dayJsDate = dayjs.utc(date, 'MMM YYYY');
  const startOfCumulative = dayjs.utc(dataFilterStartMonth).subtract(1, 'month').format('MMM YYYY');
  const endOfCumulative = dayjs.utc(dataFilterEndMonth).format('MMM YYYY');

  const { startOfQuarterPeriod, endOfQuarterPeriod } = getStartAndEndOfPeriodFromQuarterString({
    quarterDate: date,
    quarters,
    quartersYearOffset,
  });

  const { startOfYearPeriod, endOfYearPeriod } = getStartAndEndOfYearPeriod({
    yearDate: dayjs(date, 'YYYY[-Y]'),
    quarters,
  });

  //Now we have only TTM
  const numberOfTrailingMonths = 11;

  let thisPeriod;
  let lastPeriod;
  let twoPeriodsAgo;

  if (isTTMMetric) {
    if (isQuarterMetric) {
      thisPeriod = `from ${dayjs
        .utc(endOfQuarterPeriod, 'MMM YYYY')
        .subtract(numberOfTrailingMonths, 'month')
        .format('MMM YYYY')} to ${endOfQuarterPeriod}`;
      lastPeriod = dayjs.utc(endOfQuarterPeriod, 'MMM YYYY').subtract(1, 'year').format('MMM YYYY');
    } else if (isYearMetric) {
      thisPeriod = `from ${dayjs
        .utc(endOfYearPeriod, 'MMM YYYY')
        .subtract(numberOfTrailingMonths, 'month')
        .format('MMM YYYY')} to ${endOfYearPeriod}`;
      lastPeriod = dayjs.utc(endOfYearPeriod, 'MMM YYYY').subtract(1, 'year').format('MMM YYYY');
    } else if (isCumulativeMetric) {
      thisPeriod = `from ${dayjs
        .utc(endOfCumulative, 'MMM YYYY')
        .subtract(numberOfTrailingMonths, 'month')
        .format('MMM YYYY')} to ${endOfCumulative}`;
      lastPeriod = dayjs.utc(endOfCumulative, 'MMM YYYY').subtract(1, 'year').format('MMM YYYY');
    } else {
      thisPeriod = `from ${dayJsDate
        .subtract(numberOfTrailingMonths, 'month')
        .format('MMM YYYY')} to ${dayJsDate.format('MMM YYYY')}`;
      lastPeriod = dayJsDate.subtract(1, 'year').format('MMM YYYY');
    }
  } else {
    if (isQuarterMetric) {
      thisPeriod = `from ${startOfQuarterPeriod} to ${endOfQuarterPeriod}`;
      lastPeriod = startOfQuarterPeriod;
      // need this for Magic Number
      const currentMonth = dayjs.utc(endOfQuarterPeriod, 'MMM YYYY');
      const beginningOfTwoPeriodsAgo = currentMonth.subtract(5, 'month').format('MMM YYYY');
      const endOfTwoPeriodsAgo = currentMonth.subtract(3, 'month').format('MMM YYYY');
      twoPeriodsAgo = `from ${beginningOfTwoPeriodsAgo} to ${endOfTwoPeriodsAgo}`;
    } else if (isYearMetric) {
      thisPeriod = `from ${startOfYearPeriod} to ${endOfYearPeriod}`;
      lastPeriod = startOfYearPeriod;
    } else if (isCumulativeMetric) {
      thisPeriod = `from ${startOfCumulative} to ${endOfCumulative}`;
      lastPeriod = startOfCumulative;
    } else {
      thisPeriod = dayJsDate.format('MMM YYYY');
      lastPeriod = dayJsDate.subtract(1, 'month').format('MMM YYYY');
    }
  }

  return _generateAvgAndARRMetricTitle({
    revenuePreviousTotal: `Total revenue ${lastPeriod}:`,
    recurringRevenueTotal: `Total revenue ${thisPeriod}:`,
    revenueRenewed: `Revenue renewed ${thisPeriod}:`,
    revenueUpsell: `Revenue upsell ${thisPeriod}:`,
    revenueDownsell: `Revenue downsell ${thisPeriod}:`,
    customersPreviousTotal: `Total customers ${lastPeriod}:`,
    churnedCustomers: `Churned customers ${thisPeriod}:`,
    grossCustomerRetention: `Gross customer retention ${thisPeriod}:`,
    endingRevenueFromStarting: `Ending revenue from same customers:`,
    ARPA: `Monthly ARPA ${thisPeriod}`,
    ARPAAvg: `Average monthly ARPA ${thisPeriod}`,
    ARPArevenueStatsTotal:
      isTTMMetric || isQuarterMetric || isYearMetric || isCumulativeMetric
        ? `Avg recurring revenue ${thisPeriod}:`
        : `Recurring revenue ${thisPeriod}:`,
    ARPAcustomerStatsTotal:
      isTTMMetric || isQuarterMetric || isYearMetric || isCumulativeMetric
        ? `Avg customers ${thisPeriod}:`
        : `Total customers ${thisPeriod}:`,
    ARPArevenueStatsNew:
      isTTMMetric || isQuarterMetric || isYearMetric || isCumulativeMetric
        ? `Avg new revenue ${thisPeriod}:`
        : `New revenue ${thisPeriod}:`,
    ARPAcustomerStatsNew:
      isTTMMetric || isQuarterMetric || isYearMetric || isCumulativeMetric
        ? `Avg new customers ${thisPeriod}:`
        : `New customers ${thisPeriod}:`,
    CACcustomerStatsNew: usesMetricsEngine
      ? `Total recurring customers acquired ${thisPeriod}:`
      : `New recurring customers ${thisPeriod}:`,
    CACcustomerStatsNew_allCustomers: `All new customers ${thisPeriod}:`,
    CACcustomerStatsUpsell: `Upsell recurring customers ${thisPeriod}:`,
    costSales: `Sales cost ${thisPeriod}:`,
    costMarketing: `Marketing cost ${thisPeriod}:`,
    costSalesLastQuarter: `Sales cost ${twoPeriodsAgo}:`,
    costMarketingLastQuarter: `Marketing cost ${twoPeriodsAgo}:`,
    costSalesThisQuarter: `Sales cost ${thisPeriod}:`,
    costMarketingThisQuarter: `Marketing cost ${thisPeriod}:`,
    costCogs: `Cost of Goods Sold ${thisPeriod}:`,
    PBPrevenueStatsNew: `New revenue ${thisPeriod}:`,
    revenueTotalPeriod: `Period revenue:`,
    grossMargin: `Gross margin ${thisPeriod}:`,
    grossRevenueRetention: `Gross Revenue Retention ${thisPeriod}:`,
    growthInARR: `Recent period growth in recurring revenue:`,
    amountRenewedThatWasUpGross: `Renewed that was up (Gross)`,
    amountRenewedThatWasUpNet: `Renewed that was up (Net)`,
    upForRenewal: `Up for Renewal`,
    nonRenewalUpsell: `Non Renewal Upsells`,
    newRevenue: `New revenue ${thisPeriod}:`,
    upsellRevenue: `Upsell revenue ${thisPeriod}`,
    downsellRevenue: `Downsell revenue ${thisPeriod}`,
    churnRevenue: `Churn revenue ${thisPeriod}`,
    LTV: `LTV (GM Laden)${isTTMMetric ? ' TTM' : ''}`,
    CAC: `CAC (recurring)${isTTMMetric ? ' TTM' : ''}`,
    totalSeats: `Total seats ${thisPeriod}:`,
    newSeats: `New seats ${thisPeriod}:`,
    recurringPeriodGrowth: `Recurring Period Growth ${thisPeriod}:`,
    netProfit: `Net Profit ${thisPeriod}:`,
    totalPeriodRevenue: `Total Period Revenue ${thisPeriod}:`,
    customersUpForRenewal: `Customers Up For Renewal ${thisPeriod}:`,
    customersRenewed: `Customers Renewed ${thisPeriod}:`,
    netNewRevenue: `Net New Revenue ${thisPeriod}:`,
  })[row];
};
