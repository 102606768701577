import React, { useEffect, useCallback } from 'react';
import {
  useTable,
  useSortBy,
  useGroupBy,
  useGlobalFilter,
  useExpanded,
  usePagination,
  useRowSelect,
} from 'react-table';

import { EVENTS } from 'consts/analytics';
import { ReactComponent as TotalIcon } from 'images/sort-descending.svg';
import { ReactComponent as GreyDot } from 'images/grey-dot.svg';
import { MultiselectRibbon, useDebouncedSearchBar } from 'components/Blocks';
import { PaginationButton } from 'components/Buttons';
import { Row } from 'components/Core';
import { CircleLoader } from 'components/Loaders';
import {
  ReactTable,
  ReactTableBody,
  ReactTableHeader,
  ReactTableHeaderColumn,
  ReactTableHeaderRow,
  SortIconWrapper,
  RowsCount,
  TableHeaderActions,
  TablePageSeparator,
  HeaderCellWrapper,
} from 'components/Table';
import { TableColumnSettings } from 'shared/Common';
import { ReportsPopoverEmptySearch } from 'shared/Reports/ReportsPopover/ReportsPopoverEmptySearch';
import { CustomerViewModal } from 'views/Customers/CustomerSingle/CustomerViewModal';
import { GROUP_BY_OPTIONS } from 'shared/TransactionTable/consts';
import { useAnalytics, useStateWithStorage } from 'utils/hooks';
import { fixSelectingWithGetSubRows } from 'utils/tableUtils';

import {
  SearchContainer,
  TransactionReactTableCell,
  TransactionReactTableRow,
  Wrapper,
  LoadingSpinnerWrapper,
} from './styles';
import { checkRowIsReviewed } from './utils';

const TransactionTable = ({
  columns,
  initialHiddenColumns,
  data = [],
  pageCount: controlledPageCount,
  pageSize,
  setPageSize,
  currentPageIndex,
  setCurrentPageIndex,
  transactionGroupBy,
  columnsTitles,
  setHiddenTransactionColumns,
  hasSelectedUnconfirmedTransactions,
  hasSelectedUndeletedTransactions,
  onConfirmTransactions,
  onDeleteTransactions,
  getTableRows,
  initialSortBy,
  setSortBy,
  searchQuery,
  setSearchQuery,
  selectedTransactionIds,
  onSelectedRowsChange,
  customerIdToShow,
  setCustomerIdToShow,
  additionalHeaderActions,
  widgetView = false,
  isFetching,
}) => {
  const { trackEvent } = useAnalytics();

  const handleSearchQueryChange = useCallback(
    (newSearchQuery) => {
      setSearchQuery(newSearchQuery);
      setCurrentPageIndex(1);
    },
    [setSearchQuery, setCurrentPageIndex],
  );
  const { DebouncedSearchBar, resetSearchQuery } = useDebouncedSearchBar({
    onSearchQueryChange: handleSearchQueryChange,
    initialValue: searchQuery,
    placeholder: 'Search by Name or External ID...',
    width: '300px',
  });

  const [storageSortBy, setStorageSortBy] = useStateWithStorage('transaction-page-sort-by', [
    {
      id: 'updated_at',
      desc: true,
    },
  ]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    // Expansion
    allColumns,
    toggleRowExpanded,
    // Pagination
    page,
    toggleAllRowsSelected,
    state: { hiddenColumns, selectedRowIds, sortBy },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        hiddenColumns: initialHiddenColumns,
        sortBy: initialSortBy ? initialSortBy : storageSortBy,
        pageIndex: currentPageIndex,
        pageSize,
        globalFilter: searchQuery,
      },
      paginateExpandedRows: false,
      getSubRows: useCallback(
        (row) => row.subRows || [],
        // eslint-disable-next-line
        [searchQuery],
      ),
      manualPagination: true,
      manualSortBy: true,
      manualGlobalFilter: true,
      pageCount: controlledPageCount,
    },
    useGlobalFilter,
    useGroupBy,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
  );

  const rowCount =
    (transactionGroupBy === GROUP_BY_OPTIONS.CUSTOMER ? rows?.flatMap((row) => row.subRows).length : rows?.length) ?? 0;

  useEffect(() => {
    onSelectedRowsChange(selectedRowIds);
    // Otherwise we get into an infinite loop:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRowIds]);

  useEffect(() => {
    setHiddenTransactionColumns(hiddenColumns);
  }, [hiddenColumns, setHiddenTransactionColumns]);

  useEffect(() => {
    rows.forEach((row) => {
      toggleRowExpanded(row.id, true);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleRowExpanded, rows]);

  useEffect(() => {
    getTableRows(rows);
    // We have `data` as dependency here since we pass it to the wrapper of this Table component,
    // and we only want to refresh what the wrapper has when the passed-in data changes
    // if we set `rows` as the dependency, this will refresh when the rows change (for example, if we pass
    // filters), but we want ALL the rows (not just the materialized ones!)
    // eslint-disable-next-line
  }, [data, searchQuery]);

  useEffect(() => {
    // We don't want that sorting from widget view change the stored sortBy from page
    // and we don't want to save sorting from autoconfirm table to storage
    if (!initialSortBy && sortBy?.[0]?.id !== 'confirmed_at') {
      setStorageSortBy(sortBy);
    }
    setSortBy(sortBy);
  }, [sortBy, setStorageSortBy, initialSortBy, setSortBy]);

  return (
    <>
      <Wrapper data-cy="transaction-table">
        <TableHeaderActions horizontal="space-between">
          <SearchContainer>
            <DebouncedSearchBar />
          </SearchContainer>
          <Row key={`${currentPageIndex}-${controlledPageCount}`}>
            {isFetching && (
              <LoadingSpinnerWrapper data-cy="loader">
                <CircleLoader
                  isAbsolute
                  isInline
                  width="24px"
                  height="24px"
                  thickness="4px"
                  name="transactions-table"
                />
              </LoadingSpinnerWrapper>
            )}

            <RowsCount>
              <span data-cy="transactions-table__row-count">{rowCount} rows found</span>
            </RowsCount>
            <GreyDot style={{ marginLeft: 20, marginRight: 20, minWidth: 4, minHeight: 4 }} />

            <PaginationButton
              pageIndex={currentPageIndex}
              canPreviousPage={currentPageIndex > 1}
              canNextPage={currentPageIndex < controlledPageCount}
              pageCount={controlledPageCount}
              nextPage={() => setCurrentPageIndex((prevPageIndex) => prevPageIndex + 1)}
              previousPage={() => setCurrentPageIndex((prevPageIndex) => prevPageIndex - 1)}
            />
            <GreyDot style={{ marginLeft: 20, marginRight: 20, minWidth: 4, minHeight: 4 }} />
            {additionalHeaderActions}
            <TableColumnSettings
              showResultsDropdown={true}
              numberValue={pageSize}
              handleShowResultsChange={(option) => setPageSize(option.value)}
              columnsTitles={columnsTitles}
              allColumns={allColumns}
              resultsLabel={
                transactionGroupBy === GROUP_BY_OPTIONS.CUSTOMER ? 'Customers on Page:' : 'Results on Page:'
              }
              tableName="transactions"
            />
          </Row>
        </TableHeaderActions>
        <TablePageSeparator />
        <ReactTable pageView={!widgetView} data-cy="transactions-table" {...getTableProps()}>
          <ReactTableHeader pageView={!widgetView}>
            {headerGroups.map((headerGroup) => {
              return (
                <ReactTableHeaderRow pageView={!widgetView} {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <ReactTableHeaderColumn
                      alignRight={column?.alignRight}
                      customWidth={column.width}
                      {...column.getHeaderProps(
                        column.id !== 'actions' && column.getSortByToggleProps({ title: undefined }),
                      )}
                    >
                      <HeaderCellWrapper
                        isSortable={column.canSort && !column.disableSortBy}
                        isSorted={column.isSorted}
                      >
                        {column.id === 'actions' ? <></> : column.render('Header')}

                        {column.isSorted && (
                          <SortIconWrapper isSortedDesc={column.isSortedDesc}>
                            <TotalIcon />
                          </SortIconWrapper>
                        )}
                      </HeaderCellWrapper>
                    </ReactTableHeaderColumn>
                  ))}
                </ReactTableHeaderRow>
              );
            })}
          </ReactTableHeader>
          <ReactTableBody pageView={!widgetView} {...getTableBodyProps()}>
            {selectedTransactionIds.length > 0 && (
              <MultiselectRibbon
                label={`${selectedTransactionIds.length} selected rows:`}
                actions={[
                  {
                    role: 'primary',
                    label: 'Confirm',
                    onClick: () => {
                      trackEvent({
                        name: EVENTS.START_BULK_CONFIRMING_TRANSACTIONS,
                        properties: {
                          triggerFrom: 'MultiselectRibbon',
                        },
                      });

                      onConfirmTransactions();
                    },
                    hidden: !hasSelectedUnconfirmedTransactions,
                  },
                  {
                    role: 'destructive',
                    label: 'Delete',
                    onClick: onDeleteTransactions,
                    hidden: !hasSelectedUndeletedTransactions,
                  },
                ]}
                onResetSelection={() => toggleAllRowsSelected(false)}
              />
            )}
            {page.map((row) => {
              prepareRow(row);

              fixSelectingWithGetSubRows({ row, selectedIds: selectedTransactionIds });

              return (
                <TransactionReactTableRow
                  highlight={!checkRowIsReviewed(row) && !row.canExpand}
                  noRowHover={row.canExpand}
                  subRow={row.depth}
                  isBlurred={isFetching}
                  data-cy={!row.canExpand && 'transaction-row'}
                  {...row.getRowProps()}
                >
                  {row.cells.map((cell) => (
                    <TransactionReactTableCell
                      isAbsolute={row.canExpand}
                      customWidth={cell.column.width}
                      {...cell.getCellProps()}
                    >
                      {cell.render('Cell')}
                    </TransactionReactTableCell>
                  ))}
                </TransactionReactTableRow>
              );
            })}
            {!rows?.length && <ReportsPopoverEmptySearch onResetSearch={resetSearchQuery} />}
          </ReactTableBody>
        </ReactTable>
      </Wrapper>
      {customerIdToShow && (
        <CustomerViewModal customerId={customerIdToShow} onClose={() => setCustomerIdToShow(null)} />
      )}
    </>
  );
};

export { TransactionTable };
