import React from 'react';
import { ReactComponent as QuestionIcon } from 'images/related-help.svg';
const { CopyInfo } = require('./styles');

export const DynamicFieldsInfo = () => (
  <CopyInfo>
    Click to copy
    <QuestionIcon />
  </CopyInfo>
);
