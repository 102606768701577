export const EMPTY_SEGMENT = 'Without segment';

export const COHORT_BY_OPTIONS = {
  subscriptionDate: 'Subscription Date',
};

export const COHORT_PALLETS = {
  COLORFUL: 'Colorful',
  MONOCHROMIC: 'Monochromic',
};

export const INSTALL_BY_OPTIONS = {
  revenueDollar: 'Revenue $',
  revenuePercent: 'Revenue %',
  customerCount: 'Customer Count',
  customerPercent: 'Customer %',
};

export const COHORTS_DATA_KEYS = {
  TOTAL: 'monthTotals',
  AVERAGE: 'monthAverages',
};
