import dayjs from 'dayjs';
import { includesSearchQuery } from 'utils/stringUtils';

export const searchValues = ({ values, searchQuery, subRows, rowMatches }) =>
  Object.values(values ?? {}).some((rowValue) => {
    if (!rowValue) return false;

    if (Array.isArray(rowValue)) {
      return rowValue.some((v) => searchValues({ values: v, searchQuery }));
    }

    switch (typeof rowValue) {
      case 'string':
        return includesSearchQuery(rowValue, searchQuery);
      case 'number':
        return rowValue.toString().includes(searchQuery);
      case 'object':
        return searchValues({ values: rowValue, searchQuery });
      default:
        return false;
    }
  }) ||
  subRows?.some(rowMatches) ||
  false;

export const customGlobalFilter = (rows, columnIds, searchQuery) => {
  if (!searchQuery || !rows.length) return rows;

  let rowMatches;
  if (rows[0].depth === 0) {
    rowMatches = (row) => searchValues({ values: row.values, searchQuery, subRows: row.subRows, rowMatches });
  } else {
    rowMatches = (row) =>
      searchValues({
        values: row.values,
        searchQuery,
        subRows: row.subRows,
        rowMatches,
      });
  }

  return rows.filter(rowMatches);
};

export const dateGlobalFilter = (rows, columnIds, searchQuery) => {
  if (!searchQuery || !rows.length) return rows;

  let rowMatches;

  const searchDates = ({ values, searchQuery, subRows, rowMatches }) =>
    Object.values(values ?? {}).some((rowValue) => {
      if (!rowValue) return false;

      if (Array.isArray(rowValue)) {
        return rowValue.some((v) => searchDates({ values: v, searchQuery }));
      }

      //if user search a full date, we compare dates with dayjs
      const isSameDay = () =>
        includesSearchQuery(rowValue, searchQuery) ||
        (dayjs(searchQuery).isValid() &&
          dayjs(rowValue).isValid() &&
          dayjs(rowValue).isSame(dayjs(searchQuery), 'day'));

      switch (typeof rowValue) {
        case 'string':
          return isSameDay();
        case 'number':
          return isSameDay();
        case 'object':
          return searchValues({ values: rowValue, searchQuery });
        default:
          return false;
      }
    }) ||
    subRows?.some(rowMatches) ||
    false;

  if (rows[0].depth === 0) {
    rowMatches = (row) => searchDates({ values: row.values, searchQuery, subRows: row.subRows, rowMatches });
  } else {
    rowMatches = (row) =>
      searchDates({
        values: row.values,
        searchQuery,
        subRows: row.subRows,
        rowMatches,
      });
  }

  return rows.filter(rowMatches);
};
