import { useCallback, useContext, useState } from 'react';
import dayjs from 'dayjs';
import styled from 'styled-components';

import { AppContext } from 'AppContext';
import { COLORS } from 'consts/colors';
import { EVENTS } from 'consts/analytics';
import { SNAPSHOT_METRIC_BY_KEY } from 'consts/snapshotMetrics';
import { ReactComponent as TrashIcon } from 'images/trash.svg';
import { useAnalytics } from 'utils/hooks';
import { useCurrencyNumberFormatter } from 'utils/hooks';
import { Modal, ModalButton, ModalContainer, ModalTitle } from 'components/Modal';
import { Centerer } from 'components/Core';
import { Button } from 'components/Buttons';

const ModalIcon = styled(Centerer)`
  padding: 10px;
  position: absolute;
  width: 44px;
  height: 44px;
  top: calc(50% - 135px);
  z-index: 100;
  background: #ffffff;
  border-radius: 100px;

  svg {
    height: 20px;
    width: 20px;
  }
`;

const ModalWrapper = styled(Centerer)`
  position: relative;
  flex-direction: column;
  padding: 24px;
`;

const DeleteText = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: var(--primaryBlack50);
`;

const GoalInfo = styled(Centerer)`
  flex-direction: column;
  padding: 8px;
  margin: 10px 0 20px 0;
  gap: 10px;
  background: var(--primaryRed5);
  border-radius: 8px;
`;

const GoalName = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: var(--primaryBlack);
`;

const GoalDetails = styled.div`
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: var(--primaryBlack50);
`;

const RemoveGoalModal = ({ goal, onClose, onRemoveGoal }) => {
  const {
    appSettings: { isARR },
  } = useContext(AppContext);
  const numberFormatter = useCurrencyNumberFormatter();

  const metric = SNAPSHOT_METRIC_BY_KEY[goal.for_metric];
  const handleRemoveClick = () => {
    onClose();
    onRemoveGoal();
  };

  const lastPeriod = goal?.periods[goal?.periods.length - 1];

  return (
    <ModalContainer>
      <ModalIcon>
        <TrashIcon />
      </ModalIcon>
      <Modal height="auto" width="280px" padding="0">
        <ModalWrapper>
          <ModalTitle compact>
            <b>Remove Goal</b>
          </ModalTitle>

          <DeleteText>Do you really want to remove it?</DeleteText>
          <GoalInfo>
            <GoalName>{goal.name}</GoalName>
            <GoalDetails>
              {numberFormatter({
                type: metric.dataType,
                rawValue: isARR && metric.isARRMetric ? lastPeriod.value * 12 : lastPeriod.value,
              })}{' '}
              ({dayjs(lastPeriod.start_month).format('MMM YYYY')})
            </GoalDetails>
          </GoalInfo>

          <Centerer>
            <ModalButton onClick={onClose}>No, cancel</ModalButton>
            <Button color={COLORS.RED} border filled active onClick={handleRemoveClick}>
              Yes, remove
            </Button>
          </Centerer>
        </ModalWrapper>
      </Modal>
    </ModalContainer>
  );
};

export const useRemoveGoalModal = ({ goal, onRemoveGoal }) => {
  const { trackEvent } = useAnalytics();
  const [showModal, setShowModal] = useState(false);

  const openModal = useCallback(() => {
    trackEvent({ name: EVENTS.OPEN_GOAL_REMOVE, properties: { goalId: goal.id } });
    setShowModal(true);
  }, [goal?.id, setShowModal, trackEvent]);

  const closeModal = useCallback(() => {
    setShowModal(false);
  }, [setShowModal]);

  const Modal = useCallback(
    () => (showModal ? <RemoveGoalModal goal={goal} onClose={closeModal} onRemoveGoal={onRemoveGoal} /> : null),
    [showModal, goal, closeModal, onRemoveGoal],
  );

  return {
    openModal,
    Modal,
  };
};
