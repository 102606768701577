import React from 'react';
import styled from 'styled-components';
import { cssVar, transparentize } from 'polished';

const Wrapper = styled.div`
  // Box styles
  padding: 2px 4px;
  width: fit-content;
  cursor: pointer;

  // Text styles
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  background-color: ${({ active }) => active && 'var(--primaryBlue10)'};
  opacity: ${({ isDisabled }) => isDisabled && '0.3'};
  pointer-events: ${({ active, isDisabled }) => (active || isDisabled) && 'none'};
  color: ${({ active }) => (active ? 'var(--primaryBlue)' : `${transparentize(0.5, cssVar('--primaryBlack'))}`)};
  border: ${({ active }) => (active ? '1px solid transparent' : '1px solid var(--primaryBlack10)')};
  border-radius: 4px;

  a {
    color: inherit;
  }

  &:hover {
    color: var(--primaryBlack);
    border: 1px solid var(--primaryBlack);
  }
`;

export const GlobalToggleButton = ({ children, isDisabled, onClick, name, active = false }) => (
  <Wrapper
    data-cy={`global-toggle--${name}`}
    isDisabled={isDisabled}
    onClick={onClick}
    active={active}
    className="flexer"
  >
    {children}
  </Wrapper>
);
