import styled from 'styled-components';
import { Flexer } from 'components/Core';
import { ReactComponent as LeftIcon } from 'images/splash-banner-left.svg';
import { ReactComponent as RightIcon } from 'images/splash-banner-right.svg';

const OuterWrapper = styled.div`
  border: 1px solid var(--accentGraySecond);
  border-radius: 16px;
`;

const Wrapper = styled(Flexer)`
  align-items: center;
  background: #ffffff;
  border: 1px solid var(--accentGraySecond);
  border-radius: 16px;
  padding: 23px 56px;
  position: relative;
`;

const StyledLeftIcon = styled(LeftIcon)`
  left: 0px;
  top: 14px;
  position: absolute;
`;

const StyledRightIcon = styled(RightIcon)`
  right: 0px;
  top: 14px;
  position: absolute;
`;

export const SplashBanner = ({ detailsSection, children }) => {
  const bannerSection = (
    <Wrapper>
      <StyledLeftIcon />
      {children}
      <StyledRightIcon />
    </Wrapper>
  );

  return detailsSection ? (
    <OuterWrapper>
      {bannerSection}
      {detailsSection}
    </OuterWrapper>
  ) : (
    bannerSection
  );
};
