import styled from 'styled-components';

export const UnsavedChangesOverflow = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-color: rgba(0, 21, 46, 0.8);
  top: 0;
  left: 0;
  z-index: 40;
`;

export const UnsavedChangesModal = styled.div`
  position: relative;
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 50;

  svg {
    display: block;
    position: relative;
    z-index: 51;
    width: 42px;
    height: 42px;
  }
`;

export const UnsavedChangesContent = styled.div`
  position: absolute;
  z-index: 50;
  right: 18px;
  top: 20px;
  padding: 24px;
  background-color: white;
  text-align: center;
  box-shadow: 0px 30px 120px rgba(0, 21, 46, 0.4);
  border-radius: 20px;

  h5 {
    font-weight: 900;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 10px;
  }

  p {
    min-width: 232px;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 20px;
  }
`;

export const CancelButton = styled.div`
  cursor: pointer;
  padding: 10px 14px;
  border-radius: 100px;
  font-size: 14px;
  margin-right: 10px;
  line-height: 18px;
  background: var(--primaryBlack5);
  border: 1px solid var(--primaryBlack5);
`;
