import { Formik } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';
import { ReactComponent as HeaderIcon } from 'images/metadata_modal_icon.svg';
import { FormikCustomSelector } from 'components/Controls';
import { Loader } from 'components/Loaders';
import { ModalContainer } from 'components/Modal';
import { getCustomerDisplayName } from 'models/customer';
import { createCustomer, updateCustomer } from 'api';
import {
  AccountsModal,
  ButtonsRow,
  CancelButton,
  FieldsWrapper,
  ModalTitle,
  StyledHeaderIcon,
  SubmitButton,
} from './styles';
import { getCustomersFromSearch } from '../../utils';

export const SubaccountActionModal = ({ organizationId, parentCustomer, closeModal }) => {
  const [isLoading, setIsLoading] = useState(false);

  const initialValue = {
    customer_id: parentCustomer.customer_id,
    customer_name: parentCustomer.customer_name,
    subcustomer_name: null,
    subcustomer_id: null,
  };

  const fetchAccountAction = async (values) => {
    setIsLoading(true);
    try {
      await updateCustomer({
        orgId: organizationId,
        customerId: values.subcustomer_id,
        customerName: values.subcustomer_name,
        customerParent: values.customer_id,
      });
    } catch (e) {
      console.error(e);
    }
    setIsLoading(false);
    closeModal({ name: values.subcustomer_name, id: values.subcustomer_id }, values.customer_name);
  };

  return (
    <ModalContainer>
      <AccountsModal data-cy="subaccount-action-modal">
        <StyledHeaderIcon>
          <HeaderIcon />
        </StyledHeaderIcon>
        <ModalTitle>Add subaccount</ModalTitle>
        {isLoading ? (
          <div className="w-100 flexer">
            <Loader containerStyles={{ width: 40 }} />
          </div>
        ) : (
          <Formik
            initialValues={initialValue}
            validationSchema={Yup.object({
              customer_id: Yup.string().nullable().required('Please, select Parent account'),
              subcustomer_id: Yup.string()
                .nullable()
                .required('Please, select Child account')
                .notOneOf([Yup.ref('customer_id'), null], 'Parent and Child accounts must be different'),
            })}
            onSubmit={(values) => {
              fetchAccountAction(values);
            }}
          >
            {({ values, setFieldValue, handleSubmit }) => (
              <FieldsWrapper>
                <FormikCustomSelector
                  creatable
                  label="Parent account"
                  placeholder="Select customer"
                  value={
                    values.customer_id
                      ? {
                          label: getCustomerDisplayName({
                            customerName: values.customer_name,
                            customerId: values.customer_id,
                            customerEmail: values.customer_email,
                          }),
                          value: values.customer_id,
                        }
                      : null
                  }
                  name="customer_id"
                  handleChange={(option) => {
                    if (option) {
                      setFieldValue('customer_id', option.value);
                      setFieldValue('customer_name', option.name);
                    } else {
                      setFieldValue('customer_id', null);
                      setFieldValue('customer_name', null);
                    }
                  }}
                  onCreateOption={async (newCustomerName) => {
                    const newCustomer = await createCustomer({ orgId: organizationId, customerName: newCustomerName });
                    setFieldValue('customer_id', newCustomer.id);
                    setFieldValue('customer_name', newCustomer.name);
                  }}
                  loadOptions={(inputValue) => getCustomersFromSearch(inputValue, organizationId)}
                  isClearable={true}
                />

                <FormikCustomSelector
                  creatable
                  label="Child account"
                  placeholder="Select customer"
                  value={
                    values.subcustomer_id
                      ? {
                          label: getCustomerDisplayName({
                            customerName: values.subcustomer_name,
                            customerId: values.subcustomer_id,
                            customerEmail: values.subcustomer_email,
                          }),
                          value: values.subcustomer_id,
                        }
                      : null
                  }
                  name="subcustomer_id"
                  handleChange={(option) => {
                    if (option) {
                      setFieldValue('subcustomer_id', option.value);
                      setFieldValue('subcustomer_name', option.name);
                    } else {
                      setFieldValue('subcustomer_id', null);
                      setFieldValue('subcustomer_name', null);
                    }
                  }}
                  onCreateOption={async (newCustomerName) => {
                    const newCustomer = await createCustomer({ orgId: organizationId, customerName: newCustomerName });
                    setFieldValue('subcustomer_id', newCustomer.id);
                    setFieldValue('subcustomer_name', newCustomer.name);
                  }}
                  loadOptions={(searchQuery, prevOptions, additional) =>
                    getCustomersFromSearch({ searchQuery, orgId: organizationId, prevOptions, additional })
                  }
                  isPaginateable
                  isClearable={true}
                />

                <ButtonsRow>
                  <CancelButton onClick={() => closeModal(null)}>Close</CancelButton>
                  <SubmitButton data-cy="subaccount-submit-button" onClick={() => handleSubmit()}>
                    Confirm
                  </SubmitButton>
                </ButtonsRow>
              </FieldsWrapper>
            )}
          </Formik>
        )}
      </AccountsModal>
    </ModalContainer>
  );
};
