import dayjs from 'dayjs';
import { isEmpty, sum } from 'lodash';
import { assertCloseTo, assertNotGreaterThan, assertNotLowerThan } from 'utils/assertionUtils';

const EXCLUDABLE_CATEGORIES = ['Seats'];
const EXCLUDABLE_METRICS_IN_SUM_OF_SEGMENTS_INVARIANT = ['InfluxMovement'];

// Backend returns an extra 12 months before start month but we only want to assert the visible numbers
const forEachVisibleMonth = ({ data, startMonth, endMonth, callback }) => {
  for (const [monthKey, monthValue] of Object.entries(data)) {
    const dayjsObject = dayjs(`${monthKey}-15`);
    if (!dayjsObject.isValid() || dayjsObject.isBefore(startMonth, 'month') || dayjsObject.isAfter(endMonth, 'month')) {
      continue;
    }

    if (!callback(monthKey, monthValue)) return;
  }
};

export const checkSumOfSegments = ({ organization, user, data, startMonth, endMonth }) => {
  if (isEmpty(data)) return;
  if (isEmpty(data.breakdowns)) return;

  const firstMonthBreakdown = Object.values(data.breakdowns)[0];
  const segmentationName = Object.keys(firstMonthBreakdown)[0]; // e.g. productBreakdowns
  const category = Object.keys(Object.values(firstMonthBreakdown)?.[0] ?? {})?.[0]; // e.g. Revenue | Customers
  if (EXCLUDABLE_CATEGORIES.includes(category)) return;

  const totalStats = data[category === 'Revenue' ? 'revenueStats' : 'customerStats'];

  forEachVisibleMonth({
    data: totalStats,
    startMonth,
    endMonth,
    callback: (monthKey, totalMonthStats) => {
      const monthSegmentStats = data.breakdowns?.[monthKey]?.[segmentationName]?.[category];

      for (const [metricName, metricTotalValue] of Object.entries(totalMonthStats)) {
        if (EXCLUDABLE_METRICS_IN_SUM_OF_SEGMENTS_INVARIANT.includes(metricName)) continue;

        // For changeCategory segmentation, only Upsell and Downsell are broken down
        // Also, when categorizing by Customer Count and segmenting by changeCategory, this formula isn't respected.
        if (
          segmentationName === 'changeCategory' &&
          (!['Upsell', 'Downsell'].includes(metricName) || category !== 'Revenue')
        )
          continue;

        const metricMonthSegmentStats = monthSegmentStats?.[metricName.toLowerCase()]; // object of { [segment]: value }
        const sumOfSegments = metricMonthSegmentStats ? sum(Object.values(metricMonthSegmentStats ?? {})) : 0;

        // For productBreakdowns/productCategoryBreakdowns the same customer could have multiple transactions with different products,
        // that will make them be counted multiple times in breakdowns but not in total
        const assertion = ['productBreakdowns', 'productCategoryBreakdowns'].includes(segmentationName)
          ? assertNotLowerThan
          : assertCloseTo;
        return assertion({
          organization,
          user,
          context: 'Waterfall',
          expected: metricTotalValue,
          actual: sumOfSegments,
          description: `sum of ${metricName} for ${segmentationName} in ${monthKey}`,
        });
      }
    },
  });
};

export const checkRevenueStatsBasedOnLastMonth = ({ organization, user, data, startMonth, endMonth }) => {
  let previousMonthData = null;

  forEachVisibleMonth({
    data: data.revenueStats,
    startMonth,
    endMonth,
    callback: (monthKey, monthData) => {
      let assertionPassed = true;

      if (previousMonthData) {
        assertionPassed =
          assertionPassed &&
          assertCloseTo({
            organization,
            user,
            context: 'Waterfall',
            expected: monthData.Existing,
            actual: previousMonthData.Total - monthData.Churn - monthData.Downsell,
            description: `Existing revenue for ${monthKey} based on previous month and changes`,
          });

        assertionPassed =
          assertionPassed &&
          assertCloseTo({
            organization,
            user,
            context: 'Waterfall',
            expected: monthData.Total,
            actual: previousMonthData.Total - monthData.Downsell - monthData.Churn + monthData.Upsell + monthData.New,
            description: `Total revenue for ${monthKey} based changes from last month`,
          });

        assertionPassed =
          assertionPassed &&
          assertNotGreaterThan({
            organization,
            user,
            context: 'Waterfall',
            expected: previousMonthData.Total + previousMonthData.Influx,
            actual: monthData.Churn + monthData.Downsell,
            description: `Churn and Downsell revenue for ${monthKey} vs previous month total + influx`,
          });
      }

      assertionPassed =
        assertionPassed &&
        assertCloseTo({
          organization,
          user,
          context: 'Waterfall',
          expected: monthData.Total,
          actual: monthData.Existing + monthData.Upsell + monthData.New,
          description: `Total revenue for ${monthKey} based on existing, upsell, and new`,
        });

      previousMonthData = monthData;
      return assertionPassed;
    },
  });
};

export const checkCustomerStatsBasedOnLastMonth = ({ organization, user, data, startMonth, endMonth }) => {
  let previousMonthData = null;

  forEachVisibleMonth({
    data: data.customerStats,
    startMonth,
    endMonth,
    callback: (monthKey, monthData) => {
      let assertionPassed = true;

      if (previousMonthData) {
        assertionPassed =
          assertionPassed &&
          assertCloseTo({
            organization,
            user,
            context: 'Waterfall',
            expected: monthData.Existing,
            actual: previousMonthData.Total - monthData.Churn - monthData.Downsell - monthData.Upsell,
            description: `Existing customers for ${monthKey} based on previous month and changes`,
          });

        assertionPassed =
          assertionPassed &&
          assertCloseTo({
            organization,
            user,
            context: 'Waterfall',
            expected: monthData.Total,
            actual: previousMonthData.Total - monthData.Churn + monthData.New,
            description: `Total customers for ${monthKey} based changes from last month`,
          });

        assertionPassed =
          assertionPassed &&
          assertNotGreaterThan({
            organization,
            user,
            context: 'Waterfall',
            expected: previousMonthData.Total + previousMonthData.Influx,
            actual: monthData.Churn + monthData.Downsell,
            description: `Churn and Downsell customers for ${monthKey} vs previous month total + influx`,
          });
      }

      assertionPassed =
        assertionPassed &&
        assertCloseTo({
          organization,
          user,
          context: 'Waterfall',
          expected: monthData.Total,
          actual: monthData.Existing + monthData.Upsell + monthData.Downsell + monthData.New,
          description: `Total customers for ${monthKey} based on existing, upsell, and new`,
        });

      previousMonthData = monthData;
      return assertionPassed;
    },
  });
};
