import React, { Children } from 'react';
import styled from 'styled-components';
import { Flexer, Row } from 'components/Core';

const StyledBanner = styled.div`
  padding: 20px;
  padding-right: ${({ rowVersion }) => (rowVersion ? '20px' : '60px')};
  padding-left: ${({ redVersion }) => redVersion && 0};
  width: 100%;
  display: flex;
  margin-top: ${({ marginTop }) => marginTop ?? 0};
  position: relative;
  background: ${({ blueVersion, redVersion }) =>
    blueVersion ? 'var(--primaryBlue5)' : redVersion ? 'rgba(255, 89, 89, 0.05)' : 'var(--primaryYellow10)'};
  border-radius: 16px;
  margin-right: ${({ redVersion }) => !redVersion && '20px'};
  cursor: ${({ redVersion }) => redVersion && 'pointer'};

  &:hover {
    background: ${({ redVersion }) => redVersion && 'var(--primaryRed10)'};
  }
`;

const StyledBannersContainer = styled(Flexer)`
  align-items: center;
  justify-content: flex-start;
  position: relative;
  width: 100%;

  ${StyledBanner}:last-child {
    margin-right: 0;
  }
`;

const BannerTag = styled.div`
  position: absolute;
  right: 12px;
  top: 12px;
  background-color: var(--primaryYellow15);
  border-radius: 6px;
  color: var(--secondaryYellow);
  padding: 4px 5px;
  font-size: 7px;
  font-weight: 900;
  text-transform: uppercase;
`;

export const BannerText = styled.div`
  color: var(--primaryBlack);
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  margin-right: 8px;
`;

export const BannerTitle = styled.div`
  color: ${({ blueVersion, redVersion }) =>
    blueVersion ? 'var(--primaryBlue)' : redVersion ? 'var(--primaryRed)' : 'var(--secondaryYellow)'};
  font-weight: 900;
  font-size: 14px;
  margin-bottom: 8px;
  line-height: 20px;
`;

export const StyledAlert = styled.div`
  background: white;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  border: 1px solid var(--primaryRed10);
  box-shadow: 2px 2px 12px var(--primaryBlack2);
  border-radius: 100px;
  top: 20px;
  right: 20px;
`;

export const Line = styled.div`
  margin-right: 17px;
  width: 2px;
  background: ${({ blueVersion, redVersion }) =>
    blueVersion ? 'var(--primaryBlue)' : redVersion ? 'var(--primaryRed)' : 'var(--primaryYellow)'};
  border-radius: 100px;
`;

const StyledBannerButton = styled.button`
  background-color: white;
  color: var(--primaryBlack);
  padding: 4px 8px;
  cursor: pointer;
  display: flex;
  font-weight: 900;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  box-shadow: 4px 4px 24px var(--primaryBlack4);
  border-radius: 4px;
  margin-top: 8px;
  border: none;

  &:hover {
    box-shadow: 0px 4px 20px var(--primaryBlack10);
  }
`;

export const BannersContainer = ({ children }) => <StyledBannersContainer>{children}</StyledBannersContainer>;

export const Banner = ({
  tag,
  children,
  redVersion,
  rowVersion,
  marginTop,
  blueVersion,
  showVerticalLine = true,
  ...rest
}) => (
  <StyledBanner
    rowVersion={rowVersion}
    redVersion={redVersion}
    marginTop={marginTop}
    blueVersion={blueVersion}
    {...rest}
  >
    {tag ? <BannerTag>{tag}</BannerTag> : null}
    {showVerticalLine && <Line redVersion={redVersion} blueVersion={blueVersion} />}
    <Flexer
      style={{ width: rowVersion && '100%' }}
      direction={rowVersion ? 'row' : 'column'}
      alignItems={rowVersion ? 'center' : 'flex-start'}
      justifyContent={rowVersion ? 'space-between' : 'flex-start'}
    >
      {children}
    </Flexer>
  </StyledBanner>
);

export const BannerActions = ({ children }) => (
  <Row vertical="flex-end">{Children.map(children, (child) => child)}</Row>
);

export const BannerButton = ({ children, ...rest }) => <StyledBannerButton {...rest}>{children}</StyledBannerButton>;
