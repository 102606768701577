import { FieldArray } from 'formik';
import styled from 'styled-components';

import { Centerer, FlexBetweenContainer, Flexer, FlexerColumn } from 'components/Core';
import { getIntegrationDisplayName } from 'models/integration';
import { BinIcon } from 'components/Icons';
import { IntegrationProductSelector } from 'shared/IntegrationProductSelector';

const RemoveButton = styled(Centerer)`
  width: 16px;
  height: 16px;
  padding-top: 18px;
  pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'auto')};

  &:hover {
    cursor: pointer;
  }
`;

const EMPTY_DATA = {
  integration_id: null,
  id: null,
  name: null,
  service: null,
  metadata: {},
};

export const CRMOptions = ({ crmIntegration, values, setFieldValue, existingCRMImports }) => {
  const initialValues = values.crmImports?.length ? values.crmImports : [EMPTY_DATA];

  const handleImportChange = ({ providerId, name, code, index }) => {
    setFieldValue(`crmImports.${index}.provider_object_id`, providerId);
    setFieldValue(`crmImports.${index}.name`, name);
    setFieldValue(`crmImports.${index}.metadata.code`, code);
  };

  return crmIntegration ? (
    <FieldArray name="crmImports">
      {(arrayHelpers) => (
        <FlexerColumn gap="8px">
          <FlexBetweenContainer>
            <label style={{ fontSize: 14, display: 'block' }}>
              <b>Links with your CRM</b>
            </label>
          </FlexBetweenContainer>

          {initialValues.map((productImport, index) => (
            <Flexer
              gap="8px"
              key={`${productImport.provider_object_id}${productImport.integration_id}`}
              alignItems="center"
            >
              <IntegrationProductSelector
                name={`crmImports.${index}.provider_object_id`}
                existingGlImports={existingCRMImports}
                integrationId={productImport.integration_id}
                productImportName={productImport.name}
                productImportProviderId={productImport.provider_object_id}
                label={`${getIntegrationDisplayName(crmIntegration) || 'CRM'} product`}
                handleChange={({ providerId, name, code }) => handleImportChange({ providerId, name, code, index })}
                dataCy={`product-actions-modal__crm-provider-object-id--${index}`}
              />

              {initialValues.length > 1 && (
                <RemoveButton
                  onClick={() => arrayHelpers.remove(index)}
                  isDisabled={initialValues.length === 0}
                  data-cy={`product-actions-modal__crm-remove-link-button--${index}`}
                >
                  <BinIcon
                    size="16px"
                    fill={initialValues.length === 0 ? 'var(--primaryBlack5)' : 'var(--primaryBlack30)'}
                  />
                </RemoveButton>
              )}
            </Flexer>
          ))}
        </FlexerColumn>
      )}
    </FieldArray>
  ) : null;
};
