import React, { useContext } from 'react';

import { NUMBER_FORMATS } from 'consts/global';
import { useCurrencyNumberFormatter } from 'utils/hooks';

import { ReactComponent as UpForRenewalIcon } from 'images/icon_up_for_renewal.svg';
import { ReactComponent as GrossRenewedIcon } from 'images/icon_gross_renewed.svg';
import { ReactComponent as NetRenewedIcon } from 'images/icon_net_renewed.svg';
import { HelpCircleIcon } from 'components/Icons';
import { Popover, PopoverActions, PopoverButton, PopoverPrompt, usePortal } from 'components/Portal';
import {
  MetricsCard,
  Headers,
  HeaderValue,
  MetricContent,
  LabelContainer,
  Customers,
  Line,
  SectionContainer,
  TillCanceledText,
  TillCanceledTextContainer,
} from './styled';
import { useOrganizationCountsAPI } from 'api/organizations';
import { AppContext } from 'AppContext';

const RENEWAL_METRICS = [
  {
    label: 'UP FOR RENEWAL',
    icon: <UpForRenewalIcon />,
    revenueKey: 'upForRenewal',
    customerKey: 'upForRenewalCustomerCount',
    accentColor: 'var(--accentPurple)',
  },
  {
    label: 'GROSS RENEWED',
    icon: <GrossRenewedIcon />,
    revenueKey: 'grossRenewed',
    customerKey: 'renewedCustomerCount',
    accentColor: 'var(--accentYellow)',
  },
  {
    label: 'NET RENEWED',
    icon: <NetRenewedIcon />,
    revenueKey: 'netRenewed',
    customerKey: 'renewedCustomerCount',
    accentColor: 'var(--accentGreen)',
  },
];

const TillCanceledSection = () => {
  const { orgId } = useContext(AppContext);
  const { data } = useOrganizationCountsAPI({ orgId, scopes: ['tillCanceledTransactions'] });
  const hasTillCanceled = (data?.tillCanceledTransactions ?? 0) > 0;

  const { triggerRef, togglePortal, isPortalVisible, Portal } = usePortal({
    hideOnOutsideClick: true,
    XOffset: 180,
    YOffset: 50,
  });

  const handleLearnMore = (event) => {
    event.preventDefault();
    window.open(
      'https://www.notion.so/teamsubscript/Renewal-Management-in-Subscript-ed1d6aac88264bc584c0c51a644172d8',
      '_blank',
    );
  };

  if (!hasTillCanceled) {
    return null;
  }

  return (
    <>
      <Line />

      <TillCanceledTextContainer ref={triggerRef}>
        <TillCanceledText onClick={togglePortal}>
          By default,<b>till-canceled transactions don't show up</b>here. <span>Why</span>
          <HelpCircleIcon />
        </TillCanceledText>

        {isPortalVisible && (
          <Portal>
            <Popover darkMode width="300px">
              <PopoverPrompt>
                The till-canceled transactions will only appear if you add a metadata 'Up for Renewal Date' in the
                format YYYY-MM-DD to the transaction details.
              </PopoverPrompt>
              <PopoverActions>
                <PopoverButton onClick={handleLearnMore} primary>
                  Learn more
                </PopoverButton>
              </PopoverActions>
            </Popover>
          </Portal>
        )}
      </TillCanceledTextContainer>
    </>
  );
};

export const MetricsCardsSection = ({ upForRenewalData }) => {
  const numberFormatter = useCurrencyNumberFormatter();

  return (
    <SectionContainer>
      <MetricsCard>
        {RENEWAL_METRICS.map(({ label, icon, revenueKey, customerKey, accentColor }) => (
          <MetricContent key={revenueKey}>
            <LabelContainer backgroundColor={accentColor}>
              {icon}
              <span>{label}</span>
            </LabelContainer>
            <Headers>
              <HeaderValue>
                {numberFormatter({ type: NUMBER_FORMATS.CURRENCY, rawValue: upForRenewalData[revenueKey] })}
              </HeaderValue>
              <Customers>{upForRenewalData[customerKey]} customers</Customers>
            </Headers>
          </MetricContent>
        ))}
      </MetricsCard>

      <TillCanceledSection />
    </SectionContainer>
  );
};
