import React from 'react';
import styled from 'styled-components';
import { COMMITTED_POLICY, COMMITTED_START } from 'consts/global';
import { FlexerColumn } from 'components/Core';
import { SelectDropdownButton } from 'components/Buttons';
import { ExplainerTooltip } from 'components/Tooltip';
import { ReactComponent as ClassicalRevenueImage } from 'images/toggle-classical-revenue.svg';
import { ReactComponent as CommittedRevenueImage } from 'images/toggle-commited-revenue.svg';
import { ReactComponent as CommittedRevenueActiveImage } from 'images/toggle-commited-active.svg';
import { ReactComponent as ClassicalRevenueActiveImage } from 'images/toggle-classical-active.svg';
import { CustomOptionTitle, CustomOptionText, CustomOptionItem } from './styles';
import {
  COMMITTED_BOOLEAN_TO_SELECTOR,
  COMMITTED_SELECTOR_TO_BOOLEAN,
  STORAGE_TOGGLES_KEYS,
  TOGGLES_SELECTORS_DESCRIPTIONS,
  TOGGLES_SELECTORS_DESCRIPTIONS_DETAILS_KEYS,
  TOGGLES_SELECTORS_KEYS,
} from './consts';

const TooltipTextContainer = styled(FlexerColumn)`
  gap: 8px;
`;

export const GlobalToggleIsCommitted = ({
  globalTogglesState,
  setGlobalTogglesState,
  committedPolicy,
  committedStart,
}) => {
  const TooltipText = () => (
    <TooltipTextContainer>
      <div>
        * {TOGGLES_SELECTORS_DESCRIPTIONS[TOGGLES_SELECTORS_DESCRIPTIONS_DETAILS_KEYS.COMMITTED_REVENUE_DETAILS]}
      </div>
      {committedPolicy === COMMITTED_POLICY.HIGHEST && (
        <div>If a customer commits to an upsell, we count that as of the booking date of the upsell.</div>
      )}
      {committedPolicy === COMMITTED_POLICY.NEW_ONLY && (
        <div>If a customer commits to an upsell, we don't count it until the upsell revenue goes live.</div>
      )}
      {committedStart === COMMITTED_START.EARLIEST_DATE && (
        <div>
          If a deal has a booking date <i>after</i> the start date, then we count the revenue as committed as of the{' '}
          <b>start date</b>.
        </div>
      )}
      {committedStart === COMMITTED_START.BOOK_DATE && (
        <div>
          If a deal has a booking date <i>after</i> the start date, then we count the revenue as committed as of the{' '}
          <b>booking date</b>.
        </div>
      )}
    </TooltipTextContainer>
  );

  return (
    <SelectDropdownButton
      noOptionsStyles
      showSelectedDirectly
      name={STORAGE_TOGGLES_KEYS.IS_COMMITTED}
      options={{
        [TOGGLES_SELECTORS_KEYS.COMMITTED_REVENUE]: (
          <ExplainerTooltip
            width={240}
            yOffset={-130}
            xOffset={268}
            backgroundColor={'var(--opaquePrimaryBlack35)'}
            showPointer={false}
            toolTipText={<TooltipText />}
          >
            <CustomOptionItem>
              <CustomOptionTitle>Committed recurring revenue *</CustomOptionTitle>
              <CustomOptionText>
                {TOGGLES_SELECTORS_DESCRIPTIONS[TOGGLES_SELECTORS_KEYS.COMMITTED_REVENUE]}
              </CustomOptionText>

              <CommittedRevenueActiveImage className="activeImage" />
              <CommittedRevenueImage className="defaultImage" />
            </CustomOptionItem>
          </ExplainerTooltip>
        ),

        [TOGGLES_SELECTORS_KEYS.CLASSICAL_REVENUE]: (
          <CustomOptionItem>
            <CustomOptionTitle>Classical recurring revenue</CustomOptionTitle>
            <CustomOptionText>
              {TOGGLES_SELECTORS_DESCRIPTIONS[TOGGLES_SELECTORS_KEYS.CLASSICAL_REVENUE]}
            </CustomOptionText>

            <ClassicalRevenueActiveImage className="activeImage" />
            <ClassicalRevenueImage className="defaultImage" />
          </CustomOptionItem>
        ),
      }}
      selected={COMMITTED_BOOLEAN_TO_SELECTOR[globalTogglesState[STORAGE_TOGGLES_KEYS.IS_COMMITTED]]}
      onSelect={(key) =>
        setGlobalTogglesState({ name: STORAGE_TOGGLES_KEYS.IS_COMMITTED, value: COMMITTED_SELECTOR_TO_BOOLEAN[key] })
      }
    />
  );
};
