import React from 'react';
import { useCurrencyNumberFormatter } from 'utils/hooks';
import { NUMBER_FORMATS } from 'consts/global';
import { REVENUE_METRIC } from 'consts/revenueMetrics';
import { CardContents, Card, LabelContainer, MetricLabel, CardImage, MetricAmount } from './styled';

const MetricsCard = ({ historicalData, transactionData, metric, activeMetric, endMonth, setActiveMetric }) => {
  const { key, label, icon } = metric;

  const numberFormatter = useCurrencyNumberFormatter();

  const onCardClick = () => {
    setActiveMetric(key);
  };

  let totalRevenue = historicalData?.[endMonth].revenue?.[key];
  let totalCustomers = historicalData?.[endMonth].customers?.[key];

  // For non-recurring, and in case the period is more than just a month, we can't just use the endMonth
  if (key === REVENUE_METRIC.NON_RECURRING.key) {
    const nonRecurringCustomers = transactionData?.[key];
    if (Array.isArray(nonRecurringCustomers)) {
      totalRevenue = nonRecurringCustomers.reduce((acc, curr) => acc + curr.metricValue, 0);
      totalCustomers = nonRecurringCustomers.length;
    }
  }

  return (
    <Card active={key === activeMetric} key={key} onClick={onCardClick} data-cy={`revenue_details__metric_card-${key}`}>
      <CardContents active={key === activeMetric}>
        <LabelContainer>
          <MetricLabel>{label}</MetricLabel>
          <div>
            <CardImage src={icon} alt={label} />
          </div>
        </LabelContainer>
        <MetricAmount>{numberFormatter({ type: NUMBER_FORMATS.CURRENCY, rawValue: totalRevenue })}</MetricAmount>
        <div>
          {totalCustomers} <span>customers</span>
        </div>
      </CardContents>
    </Card>
  );
};

export default MetricsCard;
