import { useEffect, useMemo } from 'react';
import { useTable, useGroupBy, useGlobalFilter, useExpanded, usePagination } from 'react-table';
import { ReactComponent as TotalIcon } from 'images/sort-descending.svg';
import { ReactComponent as GreyDot } from 'images/medium-grey-dot.svg';
import { SearchBar } from 'components/Blocks';
import { Row } from 'components/Core';
import { PaginationButton } from 'components/Buttons';
import {
  ReactTable,
  ReactTableBody,
  ReactTableCell,
  ReactTableHeader,
  ReactTableHeaderColumn,
  ReactTableHeaderRow,
  ReactTableRow,
  RowsCount,
  SortIconWrapper,
  TablePageSeparator,
  HeaderCellWrapper,
  TableHeaderActions,
} from 'components/Table';
import { TableColumnSettings } from 'shared/Common';
import { ReportsPopoverEmptySearch } from 'shared/Reports/ReportsPopover/ReportsPopoverEmptySearch';
import { SearchContainer } from 'shared/TransactionTable/styles';
import { useStateWithStorage } from 'utils/hooks';

const Table = ({ columns, data, columnsTitles, noActionBar, pageView = true, tableStorageKey }) => {
  const [showResultsNumber, setShowResultsNumber] = useStateWithStorage(`${tableStorageKey}-results-number`, 20);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    allColumns,
    page,
    canPreviousPage,
    canNextPage,
    // Global Filter
    setGlobalFilter,
    pageCount,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, globalFilter },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: noActionBar ? 10000 : 20,
      },
      paginateExpandedRows: false,
    },
    useGlobalFilter,
    useGroupBy,
    useExpanded,
    usePagination,
  );

  useEffect(() => {
    if (!noActionBar) {
      setPageSize(showResultsNumber);
    }
  }, [showResultsNumber, setPageSize, noActionBar]);

  return (
    <>
      {!noActionBar && (
        <TableHeaderActions>
          <SearchContainer>
            <SearchBar
              value={globalFilter ?? ''}
              onChange={({ target }) => {
                setGlobalFilter(target.value);
              }}
              onBlur={(e) => setGlobalFilter(e.target.value)}
              onKeyPress={(e) => e.key === 'Enter' && e.target.blur()}
            />
          </SearchContainer>
          <Row>
            <RowsCount>{rows?.length} rows found</RowsCount>
            <GreyDot style={{ marginLeft: 20, marginRight: 16 }} />

            <PaginationButton
              pageIndex={pageIndex + 1}
              canPreviousPage={canPreviousPage}
              canNextPage={canNextPage}
              pageCount={pageCount}
              nextPage={nextPage}
              previousPage={previousPage}
            />
            <GreyDot style={{ marginLeft: 20, marginRight: 20, minWidth: 4, minHeight: 4 }} />
            <TableColumnSettings
              showResultsDropdown={true}
              numberValue={showResultsNumber}
              handleShowResultsChange={(number) => {
                setShowResultsNumber(number?.value ?? 20);
              }}
              columnsTitles={columnsTitles}
              allColumns={allColumns}
              resultsLabel="Rows on Page:"
            />
          </Row>
        </TableHeaderActions>
      )}

      <TablePageSeparator />
      <ReactTable {...getTableProps()}>
        <ReactTableHeader>
          {headerGroups.map((headerGroup) => (
            <ReactTableHeaderRow pageView={pageView} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <ReactTableHeaderColumn
                  customWidth={column.width}
                  alignRight={column?.alignRight}
                  {...column.getHeaderProps()}
                >
                  <HeaderCellWrapper isSortable={column.canSort && !column.disableSortBy} isSorted={column.isSorted}>
                    {column.id === 'actions' ? <></> : column.render('Header')}

                    {column.isSorted && (
                      <SortIconWrapper isSortedDesc={column.isSortedDesc}>
                        <TotalIcon />
                      </SortIconWrapper>
                    )}
                  </HeaderCellWrapper>
                </ReactTableHeaderColumn>
              ))}
            </ReactTableHeaderRow>
          ))}
        </ReactTableHeader>
        <ReactTableBody pageView={pageView} {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);

            return (
              <ReactTableRow
                rowDepth={row.depth}
                noBorderBottom={row.canExpand && row.isExpanded}
                {...row.getRowProps()}
              >
                {row.cells.map((cell) => (
                  <ReactTableCell customWidth={cell.column.width} {...cell.getCellProps()}>
                    {cell.render('Cell')}
                  </ReactTableCell>
                ))}
              </ReactTableRow>
            );
          })}
          {!rows?.length && <ReportsPopoverEmptySearch onResetSearch={() => setGlobalFilter('')} />}
        </ReactTableBody>
      </ReactTable>
    </>
  );
};

export const DefaultTable = ({
  dataForTable,
  columns,
  columnsTitles,
  pageView,
  disableSort,
  noActionBar,
  tableStorageKey,
}) => {
  const data = useMemo(() => {
    return dataForTable;
  }, [dataForTable]);

  return (
    <>
      {data && (
        <Table
          noActionBar={noActionBar}
          pageView={pageView}
          columns={columns}
          disableSort={disableSort}
          tableStorageKey={tableStorageKey}
          columnsTitles={columnsTitles ?? []}
          data={data}
        />
      )}
    </>
  );
};
