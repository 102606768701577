import styled from 'styled-components';
import { Centerer, Flexer, Spacer } from 'components/Core';
import { RedWarnIcon, CircleCheckBlackIcon } from 'components/Icons';
import { TooltipContainer } from 'components/Tooltip';
import {
  TooltipWrapper,
  TooltipRow,
  TooltipRowText,
  TooltipRowValue,
  TooltipSeparator,
} from 'components/Tooltip/styles';
import { NUMBER_FORMATS } from 'consts/global';
import { useCurrencyNumberFormatter } from 'utils/hooks';

const Wrapper = styled(Flexer)`
  &:hover {
    cursor: pointer;
  }
`;

const ValueText = styled.span`
  color: ${({ passed }) => (passed ? 'var(--primaryBlack50)' : 'var(--primaryRed)')};
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
`;

const StyledTooltipRowValue = styled(TooltipRowValue)`
  font-weight: 900;
  font-size: 11px;
  line-height: 14px;
`;

const TooltipFooter = styled(Centerer)`
  margin: 8px 0 4px;
`;

const PercentageDifference = styled.div`
  background: rgba(255, 255, 255, 0.2);
  border-radius: 4px;
  font-weight: 800;
  font-size: 10px;
  line-height: 14px;
  padding: 2px 4px;
`;

// Used to the percentage difference between the expected and the actual values of a metric.
// If the percentage difference (i.e. accuracy) is above threshold, we show a green check-mark.
export const BenchmarkedComparisonWithTooltip = ({
  accuracy, // Higher is better. 1.0 accuracy means expected === actual
  accuracyThreshold,
  difference, // Absolute difference between expected and actual
  label,
  passedText,
  failedText,
  differenceDataType = NUMBER_FORMATS.CURRENCY,
  differenceDecimalPlaces = 0,
}) => {
  const numberFormatter = useCurrencyNumberFormatter();
  const passed = accuracy >= accuracyThreshold;

  return (
    <TooltipContainer
      width={235}
      toolTipContent={
        <TooltipWrapper>
          <Spacer height="4px" />
          <TooltipRow>
            <TooltipRowText>{label}:</TooltipRowText>
            <StyledTooltipRowValue>
              {numberFormatter({
                type: differenceDataType,
                rawValue: difference,
                decimalPlaces: differenceDecimalPlaces,
              })}
              <Spacer width="12px" />
              <PercentageDifference>
                {numberFormatter({ type: NUMBER_FORMATS.PERCENT, rawValue: 1 - accuracy })}
              </PercentageDifference>
            </StyledTooltipRowValue>
          </TooltipRow>
          <Spacer height="6px" />
          <TooltipSeparator noPadding />
          <TooltipFooter>
            <TooltipRowText>{passed ? passedText : failedText}</TooltipRowText>
          </TooltipFooter>
        </TooltipWrapper>
      }
    >
      <Wrapper>
        <ValueText passed={passed}>{numberFormatter({ type: NUMBER_FORMATS.PERCENT, rawValue: accuracy })}</ValueText>
        <Spacer width="4px" />
        <Centerer>{passed ? <CircleCheckBlackIcon size="12px" /> : <RedWarnIcon size="12px" />}</Centerer>
      </Wrapper>
    </TooltipContainer>
  );
};
