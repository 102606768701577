import { useMutation, useQuery, useQueryClient } from 'react-query';
import { getAPIKey, rotateAPIKey } from './requests';

const CACHE_KEY = 'getAPIKey';

export const useAPIKeyAPI = ({ orgId, autoFetch = true }) => {
  const queryClient = useQueryClient();
  const dataKey = [CACHE_KEY, orgId];

  const { data, error, isLoading, isFetching } = useQuery(
    dataKey,
    () =>
      getAPIKey({
        orgId,
      }),
    {
      enabled: autoFetch && !!orgId,
    },
  );
  const mutationOptions = {
    onSuccess: () => queryClient.invalidateQueries([CACHE_KEY, orgId]),
  };

  const rotate = useMutation(
    ({ orgId }) =>
      rotateAPIKey({
        orgId,
      }),
    mutationOptions,
  );

  return {
    data,
    error,
    isLoading,
    isFetching,
    operations: {
      rotateAPIKey: rotate.mutateAsync,
    },
  };
};
