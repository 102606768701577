import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { EVENTS } from 'consts/analytics';
import {
  Filters,
  // SegmentByFilter
} from 'shared/Filters';
import { usePageContext, PAGE_CONTEXT_VALUES, useAnalytics } from 'utils/hooks';
import { InfoIcon } from 'components/Icons';
import { Spacer } from 'components/Core';
import { Header, HEADER_TITLES } from 'shared/Layout';
import { useLinkShare } from 'shared/LinkShare';
import { HeaderReports } from 'shared/Reports';
import { useSyncedExport } from 'shared/SyncedExports';
import { GlobalTogglesContextProvider } from 'shared/GlobalToggles';
import { TooltipContainer } from 'components/Tooltip';
import { ChartVizualizerContext } from './ChartVizualizerContext';
import { ChartVisualizerPage } from './ChartVisualizerPage';
import { MetricSelector } from './MetricSelector';

const HeaderToolTip = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  background: #ffffff;
  border: 1px solid #f3f3f3;
  box-shadow: 0 4px 20px rgba(0, 21, 46, 0.1);
  border-radius: 100px;
`;

export const ChartVisualizerContainer = () => {
  const { state } = useLocation();
  const { trackEvent } = useAnalytics();

  const { urlState } = usePageContext({
    page: PAGE_CONTEXT_VALUES.chart_visualizer.key,
  });

  const [loading, setLoading] = useState(false);
  const [fetchDataError, setFetchDataError] = useState(false);
  const [selectedMetrics, _setSelectedMetrics] = useState([]);
  const setSelectedMetrics = (data) => {
    if (data?.length)
      trackEvent({ name: EVENTS.CHART_VISUALIZER_SELECT_METRICS, properties: { selectedMetrics: data } });

    _setSelectedMetrics(data);
  };

  const exportScreenshotRef = useRef();

  const { ExportButton } = useSyncedExport({
    pageName: 'Chart Visualizer',
    exportScreenshotRef,
  });

  const uiState = {
    fetchDataError,
    loading,
    selectedMetrics,
    setFetchDataError,
    setLoading,
    setSelectedMetrics,
  };

  const { LinkShareButton, LinkShareModal } = useLinkShare({ headerTitle: HEADER_TITLES.chart });

  return (
    <ChartVizualizerContext.Provider
      value={{
        ...urlState,
        ...uiState,
      }}
    >
      {state?.report ? (
        <HeaderReports
          activePage="chart"
          headerTitle={HEADER_TITLES.chart}
          headerRight={<LinkShareButton />}
          report={state?.report}
          page={PAGE_CONTEXT_VALUES.chart_visualizer.key}
          pageState={urlState}
        />
      ) : (
        <Header
          activePage="chart"
          headerTitle={HEADER_TITLES.chart}
          headerRight={
            <>
              <LinkShareButton />
              <Spacer width="10px" />
              <ExportButton />
            </>
          }
          titleActions={
            <TooltipContainer width={200} toolTipContent="Visualize your data!">
              <HeaderToolTip>
                <InfoIcon />
              </HeaderToolTip>
            </TooltipContainer>
          }
        />
      )}
      <MetricSelector selectedMetrics={selectedMetrics} setSelectedMetrics={setSelectedMetrics} />

      <GlobalTogglesContextProvider urlState={urlState}>
        <Filters hidePeriodSelection setDataFilter={urlState.setDataFilter}>
          {/* <SegmentByFilter segmentName="chartSegmentBy" showCustomerMetadata={true} /> */}
        </Filters>
        <ChartVisualizerPage exportScreenshotRef={exportScreenshotRef} />
      </GlobalTogglesContextProvider>

      <LinkShareModal />
    </ChartVizualizerContext.Provider>
  );
};
