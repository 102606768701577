import React, { Fragment } from 'react';
import styled from 'styled-components';
import { isEqual, isNil, pick } from 'lodash';
import { FormikCustomCheckbox, FormikCustomInput } from 'components/Controls';
import { CustomersEditCountryField } from './CustomersEditCountryField';

const FormInputsRow = styled.div`
  display: flex;

  > * {
    width: 100%;
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }
  }
`;

export const EditShippingAddress = ({ values, setFieldValue, service, isDisabled }) => {
  const billingAddress = pick(values.invoicing_details ?? {}, [
    'addressLine1',
    'addressLine2',
    'city',
    'state',
    'postalcode',
    'country',
  ]);
  const useSameAddressAsBilling =
    isNil(values.invoicing_details?.shipping_address) ||
    isEqual(billingAddress, values.invoicing_details?.shipping_address);

  return (
    <Fragment>
      <FormikCustomCheckbox
        name="useSameAddressAsBilling"
        data-cy="input-useSameAddressAsBilling"
        checked={useSameAddressAsBilling}
        label="Use the billing address as the shipping address"
        onChange={(val) => {
          const value = val.target.checked ? null : {};
          setFieldValue('invoicing_details.shipping_address', value);
        }}
        isDisabled={isDisabled}
      />

      {!useSameAddressAsBilling && (
        <Fragment>
          <FormInputsRow>
            <FormikCustomInput
              name="addressLine1"
              data-cy="input-shippingaddressLine1"
              placeholder="Enter adress..."
              label="Adress Line 1"
              value={values.invoicing_details?.shipping_address?.addressLine1 ?? ''}
              handleChange={(val) => {
                setFieldValue('invoicing_details.shipping_address.addressLine1', val);
              }}
              isDisabled={useSameAddressAsBilling || isDisabled}
            />
            <FormikCustomInput
              name="addressLine2"
              data-cy="input-shippingaddressLine2"
              placeholder="Enter adress..."
              label="Adress Line 2"
              value={values.invoicing_details?.shipping_address?.addressLine2 ?? ''}
              handleChange={(val) => {
                setFieldValue('invoicing_details.shipping_address.addressLine2', val);
              }}
              isDisabled={useSameAddressAsBilling || isDisabled}
            />
          </FormInputsRow>

          <FormInputsRow>
            <FormikCustomInput
              name="shippingCity"
              data-cy="input-shippingCity"
              placeholder="Select city..."
              label="City"
              value={values.invoicing_details?.shipping_address?.city ?? ''}
              handleChange={(val) => {
                setFieldValue('invoicing_details.shipping_address.city', val);
              }}
              isDisabled={useSameAddressAsBilling || isDisabled}
            />
            <FormikCustomInput
              name="shippingState"
              data-cy="input-shippingState"
              placeholder="Select state..."
              label="State"
              value={values.invoicing_details?.shipping_address?.state ?? ''}
              handleChange={(val) => {
                setFieldValue('invoicing_details.shipping_address.state', val);
              }}
              isDisabled={useSameAddressAsBilling || isDisabled}
            />

            <FormikCustomInput
              name="shippingPostalcode"
              data-cy="input-shippingPostalcode"
              placeholder="Enter zipcode..."
              label="ZIP code"
              value={values.invoicing_details?.shipping_address?.postalcode ?? ''}
              handleChange={(val) => {
                setFieldValue('invoicing_details.shipping_address.postalcode', val);
              }}
              isDisabled={useSameAddressAsBilling || isDisabled}
            />
            <CustomersEditCountryField
              value={values.invoicing_details?.shipping_address?.country ?? ''}
              setFieldValue={setFieldValue}
              fieldName="invoicing_details.shipping_address.country"
              service={service}
              isDisabled={useSameAddressAsBilling || isDisabled}
            />
          </FormInputsRow>
        </Fragment>
      )}
    </Fragment>
  );
};
