import { useContext, useEffect, useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { omit } from 'lodash';

import { ReactComponent as ArrowUp } from 'images/arrow_up_bold.svg';
import { ReactComponent as ArrowRight } from 'images/bx-right-arrow-alt.svg';
import { ReactComponent as BellIcon } from 'images/bell-ringing-yellow.svg';
import { ReactComponent as CustomerStarIcon } from 'images/customer-star.svg';
import { ReactComponent as EditIcon } from 'images/edit-circle-customer.svg';
import { ReactComponent as PlusIcon } from 'images/customer-plus.svg';

import { AppContext } from 'AppContext';
import { NUMBER_FORMATS } from 'consts/global';
import { Spacer } from 'components/Core';
import { TimeLoader, TimeLoaderContainer } from 'components/Loaders';
import {
  RowActionPopover,
  StyledTableName,
  StyledTableNameTitle,
  TableActionCell,
  TableSubRowsCount,
  TableSubRowsCountArrow,
} from 'components/Table';
import { TooltipContainer } from 'components/Tooltip';
import { GreyDot, InfoIcon } from 'components/Icons';
import { searchValues } from 'components/Table/utils';
import { NoItems } from 'shared/ErrorBars';
import { numberFormatter } from 'utils/formatters';
import { CUSTOMERS_TABLE_COLUMN_TITLES_BY_ID } from 'consts/global';
import { getCustomerDisplayName } from 'models/customer';
import { useCustomerAPI } from 'api/customers';

import { buildCustomersHierarchyTree } from './utils';
import { ChildParentActionModal } from './ChildParentActionModal';
import { CustomersTable } from '../CustomersList/CustomersTable';
import { CustomersActionsModal, CUSTOMERS_MODAL_ACTIONS } from '../CustomersActionsModal';
import { getPercentDifference } from '../CustomersList/utils';
import { PercentDice, DatesCell, ArrCell, RelationLabel, StyledInlineButton } from '../styles';

const PAGE_LIMIT = 1000;

export const CHILD_PARENT_MODAL_ACTIONS = {
  ATTACH: 'attach',
  DETACH: 'detach',
};

export const CHILD_PARENT = {
  CHILD: 'child',
  PARENT: 'parent',
};

const InfoIconWrapper = styled.div`
  margin-top: 3px;
  margin-right: 2px;
`;

export const CustomerHierarchyTable = ({ customer }) => {
  const {
    organizations,
    formatDateWithLocale,
    appSettings: { isARR, isCommitted, rollup, currencyISOCode },
  } = useContext(AppContext);
  const [sortBy, setSortBy] = useState();

  const [currentPageIndex, setCurrentPageIndex] = useState(1);
  const [customers, setCustomers] = useState(null);
  const [showCustomersActionModal, setShowCustomersActionModal] = useState(false);
  const [showChildParentActionModal, setShowChildParentActionModal] = useState(false);
  const [childParentModalAction, setChildParentModalAction] = useState();
  const [childOrParent, setChildOrParent] = useState();
  const [childCustomer, setChildCustomer] = useState();
  const [dataForModal, setDataForModal] = useState({});
  const [searchQuery, setSearchQuery] = useState();

  const paramsObjectFull = {
    orgId: organizations?.[0]?.id,
    customerId: customer.id,
    filters: {
      scopes: ['current_and_previous_MRR_and_customer_lifetime', 'transactions_sum', 'imports'],
      includeHierarchy: true,
      isCommitted,
      rollup,
    },
  };
  const paramsObjectFast = { ...paramsObjectFull, filters: { ...paramsObjectFull.filters, scopes: ['imports'] } };

  const { data: partialData, isFetching: isFetchingFast } = useCustomerAPI(paramsObjectFast);

  const {
    data,
    error,
    isLoading,
    isFetching,
    operations: { refetch, edit },
  } = useCustomerAPI(paramsObjectFull);

  useEffect(() => {
    if (!(isFetching && isFetchingFast) && (data || partialData)) {
      setCustomers(
        buildCustomersHierarchyTree({
          customers: data?.hierarchy ?? partialData?.hierarchy,
          selectedCustomer: omit(data ?? partialData, 'hierarchy'),
        }),
      );
    }
  }, [isFetching, data, isFetchingFast, partialData, setCustomers, customer]);

  const closeModalCallback = () => {
    setShowCustomersActionModal(false);
  };

  const onChildParentModalSubmit = ({ values, modalAction, childOrParent }) => {
    if (modalAction === CHILD_PARENT_MODAL_ACTIONS.ATTACH) {
      if (childOrParent === CHILD_PARENT.CHILD) {
        edit({ id: values.customer_id, data: { parent_customer_id: customer?.id } });
      } else if (childOrParent === CHILD_PARENT.PARENT) {
        edit({ id: customer?.id, data: { parent_customer_id: values.customer_id } });
      }
    } else if (modalAction === CHILD_PARENT_MODAL_ACTIONS.DETACH) {
      if (childOrParent === CHILD_PARENT.CHILD) {
        edit({ id: values.customer_id, data: { parent_customer_id: null } });
      } else if (childOrParent === CHILD_PARENT.PARENT) {
        edit({ id: customer?.id, data: { parent_customer_id: null } });
      }
    }

    setShowChildParentActionModal(false);
  };

  const customerHasParent = (customers ?? []).some((customer) => customer.parentCustomer);

  // TODO: [AT 2022-04-19] This is a lot of duplicate with the definition of columns in CustomersListContent.
  // https://github.com/ChifferCo/chiffer/issues/3074
  const columns = useMemo(
    () => [
      {
        Header: 'Customer',
        accessor: 'name',
        width: 450,
        disableSortBy: true,
        Cell: ({ row, cell: { value } }) => {
          return (
            <StyledTableName
              style={{
                paddingLeft: customerHasParent && !row.original.parentCustomer && 50,
              }}
            >
              {row.original.selectedCustomer && <CustomerStarIcon style={{ marginRight: 9 }} />}
              {row.original.childCustomer && <RelationLabel data-cy="child-label">Child</RelationLabel>}
              {row.original.parentCustomer && <RelationLabel data-cy="parent-label">Parent</RelationLabel>}
              <Link to={row.original.selectedCustomer ? '#' : `/customers/${row.original.id}`}>
                <StyledTableNameTitle wrapText color="var(--primaryBlue)">
                  {getCustomerDisplayName({
                    customerName: value,
                    customerId: row.original.id,
                    customerEmail: row.original.email,
                  })}
                </StyledTableNameTitle>
              </Link>
              <Spacer width="8px" />
              {row.canExpand && (
                <TableSubRowsCount {...row.getToggleRowExpandedProps()} isHidden={!row.isExpanded}>
                  {row.subRows.length}
                  <TableSubRowsCountArrow isHidden={!row.isExpanded}>
                    <ArrowUp />
                  </TableSubRowsCountArrow>
                </TableSubRowsCount>
              )}
            </StyledTableName>
          );
        },
      },
      {
        Header: () => <>{isARR ? 'ARR' : 'MRR'}</>,
        accessor: 'currentMRR',
        width: 200,
        alignRight: true,
        disableSortBy: true,
        Cell: ({ cell: { value }, row }) => {
          const percentChange = getPercentDifference({
            previousMRR: row.original.previousMRR,
            currentMRR: value,
          });
          const previous = isARR ? row.original.previousMRR * 12 : row.original.previousMRR;
          const current = isARR ? value * 12 : value;

          return (
            <ArrCell>
              {rollup && row.original.parentCustomer && (
                <TooltipContainer
                  width={255}
                  toolTipContent="In rollup mode, the parent's revenue is only an estimate because there may be other siblings in the tree not taken into account. Click on the parent customer to see the actual number."
                >
                  <InfoIconWrapper>
                    <InfoIcon />
                  </InfoIconWrapper>
                </TooltipContainer>
              )}
              <TooltipContainer
                width={83}
                toolTipContent={`${dayjs().subtract(1, 'year').format('YYYY')} ${isARR ? 'ARR' : 'MRR'}`}
              >
                <span data-cy={'customer--previousMRR'}>
                  {numberFormatter({ type: NUMBER_FORMATS.CURRENCY, rawValue: previous, currency: currencyISOCode })}
                </span>
              </TooltipContainer>
              <ArrowRight />
              <span data-cy={'customer--currentMRR'}>
                {numberFormatter({ type: NUMBER_FORMATS.CURRENCY, rawValue: current, currency: currencyISOCode })}
              </span>
              {Boolean(percentChange) && (
                <PercentDice isPositive={percentChange > 0}>
                  {percentChange > 0 ? `+${percentChange}` : percentChange}%
                </PercentDice>
              )}
            </ArrCell>
          );
        },
      },
      {
        Header: () => (
          <>
            <TooltipContainer
              width={200}
              isVisible={true}
              underline={false}
              toolTipContent={'Total Non-Recurring Revenue'}
            >
              Non-RR
            </TooltipContainer>
          </>
        ),
        alignRight: true,
        accessor: 'nonRecurringRevenue',
        width: 150,
        disableSortBy: true,
        Cell: ({ cell: { value } }) => (
          <div style={{ width: '100%', textAlign: 'right' }}>
            {numberFormatter({ type: NUMBER_FORMATS.CURRENCY, rawValue: value, currency: currencyISOCode })}
          </div>
        ),
      },
      {
        Header: () => (
          <>
            <TooltipContainer
              width={200}
              isVisible={true}
              underline={false}
              toolTipContent={'Total Recurring + Non-Recurring Revenue'}
            >
              Total Rev.
            </TooltipContainer>
          </>
        ),
        alignRight: true,
        accessor: 'totalRevenue',
        width: 150,
        disableSortBy: true,
        Cell: ({ cell: { value } }) => (
          <div style={{ width: '100%', textAlign: 'right' }}>
            {numberFormatter({ type: NUMBER_FORMATS.CURRENCY, rawValue: value, currency: currencyISOCode })}
          </div>
        ),
      },
      {
        Header: 'Dates',
        accessor: 'start_date',
        width: 220,
        disableSortBy: true,
        Cell: ({ cell: { value }, row }) => {
          const isEndDateInPast = dayjs().isAfter(dayjs.utc(row.original.end_date));
          const toolTipContent = isEndDateInPast
            ? "This customer's latest transaction has ended"
            : 'This customer has current or future transactions';
          return (
            <TooltipContainer width={200} isVisible={true} underline={false} toolTipContent={toolTipContent}>
              <DatesCell className="dateCell" isDatePast={isEndDateInPast}>
                <span style={{ marginRight: 4 }}>{value ? formatDateWithLocale(value) : 'No start date'} - </span>
                {row.original.end_date ? formatDateWithLocale(row.original.end_date) : 'No end date'}
                {isEndDateInPast && <BellIcon />}
              </DatesCell>
            </TooltipContainer>
          );
        },
      },
      {
        accessor: 'actions',
        width: 65,
        disableSortBy: true,
        Cell: ({ row }) => (
          <TableActionCell>
            <RowActionPopover
              noIcons
              deleteText="Detach"
              currentSelection={row.original}
              onEditClick={() => {
                setDataForModal(row.original);
                setShowCustomersActionModal(true);
              }}
              onDeleteClick={
                (row?.original?.childCustomer || row?.original?.parentCustomer) &&
                (() => {
                  setChildParentModalAction(CHILD_PARENT_MODAL_ACTIONS.DETACH);
                  setChildOrParent(row?.original?.childCustomer ? CHILD_PARENT.CHILD : CHILD_PARENT.PARENT);
                  setChildCustomer(row.original);
                  setShowChildParentActionModal(true);
                })
              }
              dataCyPrefix="customer"
            />
          </TableActionCell>
        ),
      },
    ],
    [customerHasParent, isARR, rollup, currencyISOCode, formatDateWithLocale],
  );

  const customGlobalFilter = (rows, columnIds, searchQuery) => {
    if (!searchQuery) return rows;

    let rowMatches;

    rowMatches = (row) => searchValues({ values: row.values, searchQuery, subRows: row.subRows, rowMatches });

    return rows.filter(rowMatches);
  };

  return (
    <div data-cy="customer-hierarchy-table">
      <TimeLoaderContainer>
        {!error && !customers && (isLoading || isFetching) ? (
          <TimeLoader pageName="customerHierarchyTab" showLoadingGif={false} />
        ) : (
          <>
            {customers && (customers?.length || searchQuery) ? (
              <div style={{ marginTop: 32 }}>
                <CustomersTable
                  noInitialSort
                  columnsTitles={CUSTOMERS_TABLE_COLUMN_TITLES_BY_ID}
                  columns={columns}
                  pageView={false}
                  dataForTable={customers}
                  searchQuery={searchQuery}
                  customGlobalFilter={customGlobalFilter}
                  onSearchQueryChange={setSearchQuery}
                  pageSize={PAGE_LIMIT}
                  pageCount={1}
                  currentPageIndex={currentPageIndex}
                  setCurrentPageIndex={setCurrentPageIndex}
                  sortBy={sortBy}
                  setSortBy={setSortBy}
                  additionalHeaderActions={
                    <>
                      <StyledInlineButton
                        data-cy="child-action-button"
                        onClick={() => {
                          setChildParentModalAction(CHILD_PARENT_MODAL_ACTIONS.ATTACH);
                          setChildOrParent(CHILD_PARENT.CHILD);
                          setShowChildParentActionModal(true);
                        }}
                        isSecondary
                      >
                        add child <PlusIcon />
                      </StyledInlineButton>
                      <GreyDot style={{ minWidth: 4, minHeight: 4 }} spacing="20px" />
                      <StyledInlineButton
                        data-cy="parent-action-button"
                        onClick={() => {
                          setChildParentModalAction(CHILD_PARENT_MODAL_ACTIONS.ATTACH);
                          setChildOrParent(CHILD_PARENT.PARENT);
                          setShowChildParentActionModal(true);
                        }}
                        isSecondary
                      >
                        {customer?.parent_customer_id ? (
                          <>
                            change parent <EditIcon />
                          </>
                        ) : (
                          <>
                            add parent <PlusIcon />
                          </>
                        )}
                      </StyledInlineButton>
                      <GreyDot style={{ minWidth: 4, minHeight: 4 }} spacing="20px" />
                    </>
                  }
                />
              </div>
            ) : (
              <NoItems entityName="Customers" buttonCb={refetch} organization={organizations[0]?.id} />
            )}
          </>
        )}
      </TimeLoaderContainer>

      {showChildParentActionModal && (
        <ChildParentActionModal
          childOrParent={childOrParent}
          customer={customer}
          childCustomer={childCustomer}
          closeModal={({ values, modalAction }) => onChildParentModalSubmit({ values, modalAction, childOrParent })}
          modalAction={childParentModalAction}
        />
      )}

      {showCustomersActionModal && (
        <CustomersActionsModal
          organizationId={organizations?.[0].id}
          closeModal={closeModalCallback}
          modalAction={CUSTOMERS_MODAL_ACTIONS.EDIT}
          customer={dataForModal}
          filters={paramsObjectFull}
        />
      )}
    </div>
  );
};
