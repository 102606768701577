import { useState } from 'react';
import dayjs from 'dayjs';
import { SPREAD_TYPE } from 'consts/global';
import { safeParseJson } from 'utils/jsonUtils';
import { safeParseDate } from 'utils/dateUtils';
import { setLocalStorage } from 'utils/storageUtils';
import { WATERFALL_TYPE_OPTIONS, CUSTOMER_TYPES_OPTIONS } from 'shared/Filters';
import { DEFAULT_STORAGE_VALUE } from './usePageContext';

const LOCAL_STORAGE_KEYS = {
  showing: 'datafilter-showing-conditions',
  resolution: 'datafilter-resolution',
  startMonth: 'datafilter-startdate',
  endMonth: 'datafilter-enddate',
  revenueTypes: 'datafilter-revenue-types',
  revenueMonth: 'datafilter-revenuedate',
  period: 'datafilter-period',
  waterfallType: 'datafilter-waterfall-type',
  waterfallSegmentBy: 'datafilter-waterfall-segment-by',
  waterfallSegmentByTimeseriesMonth: 'datafilter-waterfall-segment-by-timeseries-month',
  cohortSegmentBy: 'cohort-page-segment-by',
  monthsFromStart: 'months-from-start',
  cohortBy: 'cohort-page-by',
  // TODO [TC 2022-02-21]: "installBy" and "installSecondBy" don't make sense as names. These should be
  // something more relevant like "cohortMetric" and "cohortSecondMetric".
  cohortInstallBy: 'cohorts-page-install-by',
  cohortInstallSecondBy: 'cohorts-page-install-second-by',
  transactionsGroupBy: 'transactions-page-group-by',
  transactionsConfirmation: 'transactions-page-confirmation',
  spreadSegmentBy: 'spreads-page-segment-by',
  customerTypes: 'datafilter-customer-types-v3',
};

const MONTH_KEYS = ['startMonth', 'endMonth', 'revenueMonth', 'waterfallSegmentByTimeseriesMonth'];

const SERIALISED_KEYS = ['revenueTypes', 'cohortSegmentBy', 'waterfallSegmentBy', 'customerTypes'];

export const FILTERS_WITH_PAGE_LOAD = ['waterfallSegmentBy', 'cohortSegmentBy', 'spreadSegmentBy'];

let cachedCustomerTypes = safeParseJson(localStorage.getItem(LOCAL_STORAGE_KEYS.customerTypes), []);
cachedCustomerTypes = cachedCustomerTypes.filter((x) => Object.values(CUSTOMER_TYPES_OPTIONS).includes(x));
if (cachedCustomerTypes.length === 0) {
  cachedCustomerTypes = [CUSTOMER_TYPES_OPTIONS.LIVE, CUSTOMER_TYPES_OPTIONS.PROSPECT];
}

// Default revenueTypes to just recurring
const dataFilterInitialState = {
  period: localStorage.getItem(LOCAL_STORAGE_KEYS.period) ?? 'Custom',
  resolution: localStorage.getItem(LOCAL_STORAGE_KEYS.resolution) ?? 'Monthly',
  startMonth: safeParseDate(
    localStorage.getItem(LOCAL_STORAGE_KEYS.startMonth),
    new Date(dayjs().subtract(18, 'month')),
  ),
  endMonth: safeParseDate(localStorage.getItem(LOCAL_STORAGE_KEYS.endMonth), new Date()),
  showRevenueTypes: false,
  revenueTypes: safeParseJson(localStorage.getItem(LOCAL_STORAGE_KEYS.revenueTypes), [SPREAD_TYPE.RECURRING]),
  revenueMonth: safeParseDate(localStorage.getItem(LOCAL_STORAGE_KEYS.revenueMonth), new Date()),
  waterfallType: Object.values(WATERFALL_TYPE_OPTIONS).includes(localStorage.getItem(LOCAL_STORAGE_KEYS.waterfallType))
    ? localStorage.getItem(LOCAL_STORAGE_KEYS.waterfallType)
    : WATERFALL_TYPE_OPTIONS.revenue,
  waterfallSegmentBy: safeParseJson(
    localStorage.getItem(LOCAL_STORAGE_KEYS.waterfallSegmentBy),
    DEFAULT_STORAGE_VALUE.segmentBy,
  ),
  waterfallSegmentByTimeseriesMonth: safeParseDate(
    localStorage.getItem(LOCAL_STORAGE_KEYS.waterfallSegmentByTimeseriesMonth),
    new Date(),
  ),
  cohortSegmentBy: safeParseJson(
    localStorage.getItem(LOCAL_STORAGE_KEYS.cohortSegmentBy),
    DEFAULT_STORAGE_VALUE.segmentBy,
  ),
  monthsFromStart: localStorage.getItem(LOCAL_STORAGE_KEYS.monthsFromStart) ?? 1,
  cohortInstallBy: localStorage.getItem(LOCAL_STORAGE_KEYS.cohortInstallBy) ?? DEFAULT_STORAGE_VALUE.installBy,
  cohortInstallSecondBy: safeParseJson(localStorage.getItem(LOCAL_STORAGE_KEYS.cohortInstallSecondBy), null),
  transactionsGroupBy: safeParseJson(localStorage.getItem(LOCAL_STORAGE_KEYS.transactionsGroupBy), null),
  transactionsConfirmation: localStorage.getItem(LOCAL_STORAGE_KEYS.transactionsConfirmation) ?? 'To be confirmed',
  spreadSegmentBy: safeParseJson(localStorage.getItem(LOCAL_STORAGE_KEYS.spreadSegmentBy), null),
  customerTypes: cachedCustomerTypes,
};

// Serialize a dataFilter object into one that can be embedded into a URL or stringified for local storage.
// Basically, it means converting complex data types such as dayjs, Date, etc into strings.
export const serializeDataFilter = (dataFilter) =>
  Object.entries(dataFilter).reduce((acc, [key, value]) => {
    if (MONTH_KEYS.includes(key)) {
      acc[key] = `${value.getFullYear()}-${value.getMonth() + 1}`;
    } else {
      acc[key] = value;
    }
    return acc;
  }, {});

// Turn a serialized object into a dataFilter object
export const deserializeDataFilter = (serialized) =>
  Object.entries(serialized).reduce((acc, [key, value]) => {
    if (MONTH_KEYS.includes(key)) {
      // When using the day 01, timezone may mess up with the month, so using day 15 to make sure
      acc[key] = safeParseDate(`${value}-15`, new Date());
    } else {
      acc[key] = value;
    }
    return acc;
  }, {});

export const updateDataFilter = (currentState, changes) => ({ ...currentState, ...changes });

export const useDataFilter = () => {
  const [dataFilter, setDataFilterRaw] = useState(dataFilterInitialState);

  const setDataFilter = (changes) => {
    setDataFilterRaw(updateDataFilter(dataFilter, changes));
    Object.entries(changes).forEach(([key, value]) => {
      if (key in LOCAL_STORAGE_KEYS) {
        const serializedValue = SERIALISED_KEYS.includes(key) ? JSON.stringify(value) : value;
        setLocalStorage(LOCAL_STORAGE_KEYS[key], serializedValue);
      }
    });
  };

  return [dataFilter, setDataFilter];
};
