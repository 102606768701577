import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { CsvUpload } from 'shared/CsvUpload';
import { CUSTOMER_TYPES_OPTIONS } from 'shared/Filters';
import { useImportsAPI } from 'api/imports';
import { CSV_MODES } from 'shared/CsvUpload/CsvUpload';
import { getInitialIdSource, getOrgIdSources } from 'views/Transactions/TransactionsCsvUpload/utils';
import {
  INTERNAL_CUSTOMER_ID_SOURCE,
  NAME_MATCHING_SOURCE,
  SOURCE_TYPES,
  UPLOADABLE_OBJECTS,
} from 'views/Transactions/TransactionsCsvUpload/consts';
import { useCustomersAPI } from 'api/customers';
import { useCSVImportsAPI } from 'api/csvImports';
import { getColumns } from './columns';
import { AppContext } from 'AppContext';
import { getSchema } from './schema';
import { getFormFinalCustomers } from './utils';
import { DEFAULT_CUSTOMER_CREATE_MAPPER, DEFAULT_CUSTOMER_MAPPER } from './consts';

export const CustomersCsvUpload = () => {
  const { orgId, integrations, orgConfigs } = useContext(AppContext);

  const history = useHistory();
  // `customerIdSourceAndType` and `parentCustomerIdSourceAndType` has object type
  // Example: { type: SOURCE_TYPES.INTERNAL, source: 'User ID' };
  const [customerIdSourceAndType, setCustomerIdSourceAndType] = useState({});
  const [parentCustomerIdSourceAndType, setParentCustomerIdSourceAndType] = useState({});
  const [submittingForm, setSubmittingForm] = useState(false);
  const [selectedMetadataFields, setSelectedMetadataFields] = useState([]);

  const { uploadCsvCustomersIdSource } = orgConfigs;

  const {
    operations: { uploadCSV },
  } = useCSVImportsAPI({ orgId, autoFetch: false });

  const customersIdSources = useMemo(
    () => ({
      ...getOrgIdSources({
        integrations,
        internalSource: uploadCsvCustomersIdSource,
        suffix: 'customer ID',
      }),
      [INTERNAL_CUSTOMER_ID_SOURCE]: INTERNAL_CUSTOMER_ID_SOURCE,
      [NAME_MATCHING_SOURCE]: 'Customer name',
    }),
    [integrations, uploadCsvCustomersIdSource],
  );

  useEffect(() => {
    if (orgId) {
      setCustomerIdSourceAndType(getInitialIdSource({ idSources: customersIdSources }));
      setParentCustomerIdSourceAndType(getInitialIdSource({ idSources: customersIdSources }));
    }
  }, [orgId, customersIdSources]);

  // With EXTERNAL source we get Customers from our imports table
  const {
    data: dataCustomerImports,
    isLoading: isCustomerImportsLoading,
    operations: { refetch: refetchCustomerImports },
  } = useImportsAPI({
    orgId,
    filters: {
      providerName: customerIdSourceAndType.source,
      chifferObjectName: 'customer',
      includeCustomerName: true,
    },
    autoFetch: false,
  });

  // With INTERNAL source we get Customers from our customers table
  const customersListParams = useMemo(
    () => ({
      limit: 100000,
      types: [CUSTOMER_TYPES_OPTIONS.LIVE, CUSTOMER_TYPES_OPTIONS.PROSPECT],
      // If matching internally by a metadata, we only need to fetch customers with existing id in metadata
      notNullMetadataField: [NAME_MATCHING_SOURCE, INTERNAL_CUSTOMER_ID_SOURCE].includes(customerIdSourceAndType.source)
        ? undefined
        : customerIdSourceAndType.source,
    }),
    [customerIdSourceAndType],
  );

  const {
    data: dataCustomers,
    isLoading: isCustomersLoading,
    operations: { refetch: refetchCustomers },
  } = useCustomersAPI({
    orgId,
    filters: { params: customersListParams },
    autoFetch: false,
  });

  const customers = useMemo(
    () => (customerIdSourceAndType?.type === SOURCE_TYPES.INTERNAL ? dataCustomers : dataCustomerImports),
    [dataCustomers, dataCustomerImports, customerIdSourceAndType?.type],
  );

  // With EXTERNAL source we get Parent Customers from our imports table
  const {
    data: dataParentCustomerImports,
    isLoading: isParentCustomerImportsLoading,
    operations: { refetch: refetchParentCustomerImports },
  } = useImportsAPI({
    orgId,
    filters: {
      providerName: parentCustomerIdSourceAndType.source,
      chifferObjectName: 'customer',
      includeCustomerName: true,
    },
    autoFetch: false,
  });

  // With INTERNAL source we get ParentCustomers from our customers table
  const parentCustomersListParams = useMemo(
    () => ({
      limit: 30000,
      types: [CUSTOMER_TYPES_OPTIONS.LIVE, CUSTOMER_TYPES_OPTIONS.PROSPECT],
      // If matching internally by a metadata, we only need to fetch customers with existing id in metadata
      notNullMetadataField: [NAME_MATCHING_SOURCE, INTERNAL_CUSTOMER_ID_SOURCE].includes(
        parentCustomerIdSourceAndType.source,
      )
        ? undefined
        : parentCustomerIdSourceAndType.source,
    }),
    [parentCustomerIdSourceAndType],
  );

  const {
    data: dataParentCustomers,
    isLoading: isParentCustomersLoading,
    operations: { refetch: refetchParentCustomers },
  } = useCustomersAPI({
    orgId,
    filters: { params: parentCustomersListParams },
    autoFetch: false,
  });

  const parentCustomers = useMemo(
    () =>
      parentCustomerIdSourceAndType?.type === SOURCE_TYPES.INTERNAL ? dataParentCustomers : dataParentCustomerImports,
    [dataParentCustomers, dataParentCustomerImports, parentCustomerIdSourceAndType?.type],
  );

  // Fetch Customers
  useEffect(() => {
    if (customerIdSourceAndType?.type === SOURCE_TYPES.INTERNAL) {
      refetchCustomers();
    } else {
      refetchCustomerImports();
    }
  }, [customerIdSourceAndType, refetchCustomers, refetchCustomerImports]);

  // Fetch Parent Customers
  useEffect(() => {
    if (parentCustomerIdSourceAndType?.type === SOURCE_TYPES.INTERNAL) {
      refetchParentCustomers();
    } else {
      refetchParentCustomerImports();
    }
  }, [parentCustomerIdSourceAndType, refetchParentCustomers, refetchParentCustomerImports]);

  const getColumnsWithCsvUploadState = useCallback(
    ({
      fuzzyRowsMapper,
      defaultMapper,
      setFuzzyRowsMapper,
      formRef,
      csvColumnsMapper,
      setCsvColumnsMapper,
      csvColumns,
    }) =>
      getColumns({
        objects: UPLOADABLE_OBJECTS.CUSTOMERS,
        customerIdSourceAndType,
        formRef,
        csvColumns,
        csvColumnsMapper,
        setCsvColumnsMapper,
        selectedMetadataFields,
        setSelectedMetadataFields,

        customers,
        customersIdSources,
        setCustomerIdSourceAndType,
        parentCustomerIdSourceAndType,
        setParentCustomerIdSourceAndType,
        uploadCsvCustomersIdSource,

        fuzzyRowsMapper,
        setFuzzyRowsMapper,

        defaultMapper,
      }),
    [
      customers,
      customerIdSourceAndType,
      parentCustomerIdSourceAndType,
      customersIdSources,
      uploadCsvCustomersIdSource,
      setCustomerIdSourceAndType,
      setParentCustomerIdSourceAndType,

      selectedMetadataFields,
      setSelectedMetadataFields,
    ],
  );

  const handleSubmit = async ({ values, extraData }) => {
    setSubmittingForm(true);

    try {
      const formCustomers = getFormFinalCustomers({
        formCustomers: values,
        subscriptCustomers: customers,
        subscriptParentCustomers: parentCustomers,
        orgId,
        uploadCsvCustomersIdSource,
        customerIdSourceAndType,
        parentCustomerIdSourceAndType,
      });

      await uploadCSV.mutateAsync({
        rawFile: extraData,
        data: formCustomers,
        settings: { entity: extraData.entity },
      });

      setTimeout(() => {
        history.push('/customers');

        setSubmittingForm(false);
      }, 1000);
    } catch {
      setSubmittingForm(false);
    }
  };

  const defaultMapperWithCreateOrUpdateMode = useCallback(({ createOrUpdateMode }) => {
    return createOrUpdateMode === CSV_MODES.UPDATE
      ? DEFAULT_CUSTOMER_MAPPER
      : createOrUpdateMode === CSV_MODES.CREATE
      ? DEFAULT_CUSTOMER_CREATE_MAPPER
      : undefined;
  }, []);

  const isLoading =
    submittingForm ||
    isCustomerImportsLoading ||
    isCustomersLoading ||
    isParentCustomerImportsLoading ||
    isParentCustomersLoading;

  const tableSchema = useMemo(() => {
    const { updateSchema, createSchema } = getSchema({
      customers,
      parentCustomers,
      customerIdSourceAndType,
      parentCustomerIdSourceAndType,
      uploadCsvCustomersIdSource,
    });

    return { updateSchema, createSchema };
  }, [customers, parentCustomers, customerIdSourceAndType, parentCustomerIdSourceAndType, uploadCsvCustomersIdSource]);

  return (
    <CsvUpload
      entityName={UPLOADABLE_OBJECTS.CUSTOMERS}
      isLoading={isLoading}
      updateSchema={tableSchema?.updateSchema}
      createSchema={tableSchema?.createSchema}
      defaultMapperWithCreateOrUpdateMode={defaultMapperWithCreateOrUpdateMode}
      handleSubmit={handleSubmit}
      getColumnsWithCsvUploadState={getColumnsWithCsvUploadState}
    />
  );
};
