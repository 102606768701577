import React, { useEffect, useRef, useState } from 'react';
import styled, { createGlobalStyle, css } from 'styled-components';
import dayjs from 'dayjs';
import { useField } from 'formik';

import { Divider, FlexBetweenContainer, FlexerColumn } from 'components/Core';
import { ReactComponent as RowActionsIcon } from 'images/row_actions.svg';
import { ReactComponent as BinIcon } from 'images/bx-trash.svg';
import { CustomDatePicker } from 'components/Controls';
import { formatDateForDatepicker } from 'utils/dateUtils';

import { MetadataItemInputPopover } from './MetadataItemInputPopover';
import { METADATA_DATA_TYPES } from '../consts';
import { useClickOutside } from 'utils/hooks';

const MetadataSectionWrapper = styled.div`
  width: 100%;
  position: relative;
  pointer-events: ${(props) => props.disabled && 'none'};
`;

const MetadataItemWrapper = styled(FlexerColumn)`
  padding: 8px;
  margin-block: 8px;
  gap: 5px;
  border-radius: 8px;
  border: none;
  background-color: transparent;
  height: auto;

  &:hover {
    background-color: var(--accentGray);
    cursor: pointer;
    border: none;
  }
  &:hover > svg {
    circle {
      fill: var(--primaryBlack);
    }
  }
  ${({ selected }) =>
    selected
      ? css`
          background-color: var(--accentGray);
          & svg {
            circle {
              fill: var(--primaryBlack);
            }
          }
        `
      : ''}
`;

const MetadataLabel = styled.div`
  font-size: 13px;
  color: var(--primaryBlack);
  font-weight: 400;
`;

const MetadataActionsIcon = styled(RowActionsIcon)`
  path {
    fill-opacity: 0;
  }
  circle {
    fill: var(--primaryBlack50);
  }
  transform: rotate(90deg);
  width: 20px;
`;

const MetadataValue = styled.div`
  font-size: 13px;
  font-weight: 700;
  word-break: break-word;
  ${({ isValueMissing }) =>
    isValueMissing
      ? css`
          color: rgba(0, 21, 46, 0.2);
          font-weight: 400;
        `
      : 'auto'};
`;

const StyledBinIcon = styled(BinIcon)`
  width: 18px;
  height: 18px;
  margin-right: 8px;
  margin-bottom: 2px;
`;

const ClearDateTimeValueButton = styled.div`
  display: flex;
  width: 100%;
  padding: 8px;
  align-items: center;
  font-weight: 700;
  justify-content: center;
  background-color: var(--accentWhiterGray);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border: 1px solid var(--accentGraySecond);
  border-top: 0;
  &:hover {
    cursor: pointer;
  }
`;

const GlobalCustomDatePickerStyle = createGlobalStyle`
  ${({ isValueMissing }) =>
    !isValueMissing
      ? css`
          .react-datepicker__month {
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
          }
          .react-datepicker__children-container {
            width: 100%;
            margin: 0;
            padding: 0;
          }
        `
      : ''}
`;

export const MetadataItemFormikInput = ({ name, metadataDataType, onDataChange, item, disabled }) => {
  const meta = useField(name);
  const { value: formikValue } = meta[0];
  const { setValue: setFormikValue } = meta[2];

  // Once the new value is set using formik's 'setValue', the popover closes.
  // So this additional state is to prevent the popover from closing when creating a custom value
  const [value, setValue] = useState();
  const [isOpen, setIsOpen] = useState(false);

  const isClickoutsideRef = useRef();
  const parentContainerRef = useRef();

  const isValueMissing = value === '' || value === undefined || value === null;
  const isDateTime = metadataDataType === METADATA_DATA_TYPES.DATETIME;

  const handleUpdateFormikValue = (newValue) => {
    setFormikValue(newValue);
    onDataChange && onDataChange(newValue);
  };

  const onClose = (closeValue) => {
    setIsOpen(false);
    handleUpdateFormikValue(closeValue ?? value);
  };

  const togglePopover = () => {
    if (isOpen) {
      onClose();
    } else {
      !isClickoutsideRef.current && setIsOpen(true);
    }
    isClickoutsideRef.current = false;
  };

  const handleValueChange = (newValue, close = false) => {
    setValue(newValue);
    if (close) onClose(newValue); // sometimes the popover closes before the value is set
  };

  const popoverRef = useClickOutside((e) => {
    if (parentContainerRef.current?.contains(e.target)) {
      return;
    }
    onClose();
  });

  // To initalize and prevent value not being updated when formikValue updates
  useEffect(() => {
    if (formikValue) {
      setValue(formikValue);
    }
  }, [formikValue]);

  return (
    <MetadataSectionWrapper ref={parentContainerRef} disabled={disabled}>
      {isDateTime ? (
        <>
          <CustomDatePicker
            name={name}
            formik
            hideCalendarIcon
            selected={value ? formatDateForDatepicker(value) : null}
            onChange={(_, date) => {
              handleUpdateFormikValue(dayjs(date).utc(true).toDate());
            }}
            renderCustomInput={({ inputRef }) => (
              <MetadataItemWrapper data-cy="metadata-item" ref={inputRef}>
                <FlexBetweenContainer>
                  <MetadataLabel>{item.key}</MetadataLabel>
                  <MetadataActionsIcon className="action-icon" />
                </FlexBetweenContainer>
                <MetadataValue isValueMissing={isValueMissing}>
                  {isValueMissing ? 'N/A' : dayjs.utc(value).format('MMM DD, YYYY')}
                </MetadataValue>
              </MetadataItemWrapper>
            )}
          >
            {!isValueMissing && (
              <ClearDateTimeValueButton
                onClick={() => {
                  handleValueChange(null);
                  handleUpdateFormikValue(null);
                }}
              >
                <StyledBinIcon />
                <div>Clear Value</div>
              </ClearDateTimeValueButton>
            )}
          </CustomDatePicker>

          <GlobalCustomDatePickerStyle isValueMissing={isValueMissing} />
        </>
      ) : (
        <MetadataItemWrapper selected={isOpen} data-cy={`metadata-item__${item.key}`} onClick={togglePopover}>
          <FlexBetweenContainer>
            <MetadataLabel>{item.key}</MetadataLabel>
            <MetadataActionsIcon />
          </FlexBetweenContainer>
          <MetadataValue isValueMissing={isValueMissing}>
            {isValueMissing ? 'N/A' : typeof value === 'object' ? 'N/A' : value.toString()}
          </MetadataValue>
        </MetadataItemWrapper>
      )}

      <Divider backgroundColor="var(--primaryBlack3)" />
      {isOpen && (
        <div ref={popoverRef}>
          <MetadataItemInputPopover
            value={value}
            setValue={handleValueChange}
            metadataDataType={metadataDataType}
            isValueMissing={isValueMissing}
            item={item}
            onClose={onClose}
          />
        </div>
      )}
    </MetadataSectionWrapper>
  );
};
