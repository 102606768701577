import Checkbox from 'antd/lib/checkbox/Checkbox';
import { truncate } from 'lodash';
import { Row } from 'antd';
import styled from 'styled-components';

import { Centerer } from 'components/Core';
import { TooltipContainer } from 'components/Tooltip';
import { AllRowsSelectorDropdown } from 'components/Table/AllRowsSelectorDropdown';
import { compareDates, formatDate, makeTableCell, sortingFunction } from 'views/ExternalUpdates/utils';
import { TableCell } from 'views/ExternalUpdates/Components/TableCell';
import { EXTERNAL_UPDATE_ACTION_OPTIONS } from 'views/ExternalUpdates/consts';
import { INVOICING_SCHEDULE_TABS_PANEL_TABS } from 'views/Billing/InvoicingScheduleModal/InvoicingScheduleTabsPanel';
import { LinkIcon, OpenCustomerButton } from 'views/Billing/InvoicingTables/columns';
import { EXTERNAL_UPDATES_TABLE_COLUMNS } from './consts';
import { formatExternalUpdateInvoiceDate } from './utils';
import { TransactionsCell } from './TransactionsCell';

const ReviewButton = styled.div`
  padding: 8px;
  border-radius: 8px;
  background-color: var(--primaryBlue10);
  font-size: 10px;
  font-style: normal;
  font-weight: 900;
  line-height: 12px;
  text-transform: uppercase;
  cursor: pointer;
  color: var(--primaryBlue);
  margin-right: 8px;
`;

const DismissButton = styled(ReviewButton)`
  background-color: white;
  color: var(--dark7);
  border: 1px solid var(--primaryBlack10);
  margin-right: 0px;
`;

export const generateColumns = ({ openInvoiceModal, resolveExternalUpdate }) => {
  const columns = [
    {
      Header: (props) => (
        <AllRowsSelectorDropdown
          getToggleAllPageRowsSelectedProps={props?.getToggleAllPageRowsSelectedProps}
          getToggleAllRowsSelectedProps={props?.getToggleAllRowsSelectedProps}
          {...props}
        />
      ),
      accessor: 'checked',
      width: 32,
      Cell: ({ row }) => (
        <Checkbox
          data-cy="external-updates__checkbox"
          checked={row.isSelected}
          onClick={() => {
            row.toggleRowSelected();
          }}
        />
      ),
      noPaddingRight: true,
      noInitialSortBy: true,
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: 'Customer  (inv. schedule)',
      accessor: EXTERNAL_UPDATES_TABLE_COLUMNS.customer_name.key,
      sortType: (rowA, rowB) =>
        sortingFunction({ rowA, rowB, fieldName: EXTERNAL_UPDATES_TABLE_COLUMNS.customer_name.key }),
      width: 250,
      Cell: ({ row }) => (
        <Row horizontal="start" style={{ width: '100%' }} gap="8px">
          <OpenCustomerButton
            data-cy={`updates-table--customer-row-${row.original?.targetObject?.customer_name}`}
            onClick={() =>
              openInvoiceModal({
                invoice: { invoicing_schedule_id: row.original?.targetObject?.id },
                initialTab: INVOICING_SCHEDULE_TABS_PANEL_TABS.WARNINGS,
              })
            }
            isSecondary
          >
            {truncate(row.original?.targetObject?.customer_name)}
          </OpenCustomerButton>
          <TooltipContainer width={125} toolTipContent="Open Customer">
            <LinkIcon onClick={() => window.open(`/customers/${row.original?.targetObject?.customer_id}`, '_blank')} />
          </TooltipContainer>
        </Row>
      ),
    },
    {
      Header: 'Schedule Transactions',
      accessor: EXTERNAL_UPDATES_TABLE_COLUMNS.transactions.key,
      sortType: (rowA, rowB) =>
        sortingFunction({
          rowA,
          rowB,
          fieldName: EXTERNAL_UPDATES_TABLE_COLUMNS.transactions.key,
        }),
      width: 386,
      disableFilters: true,
      disableSortBy: true,
      Cell: ({ row }) => (
        <TransactionsCell
          row={row}
          openInvoiceModal={() =>
            openInvoiceModal({
              invoice: { invoicing_schedule_id: row.original?.targetObject?.id },
              initialTab: INVOICING_SCHEDULE_TABS_PANEL_TABS.WARNINGS,
            })
          }
        />
      ),
    },
    {
      Header: 'First invoice date',
      accessor: EXTERNAL_UPDATES_TABLE_COLUMNS.start_date.key,
      sortType: (rowA, rowB) =>
        sortingFunction({
          rowA,
          rowB,
          fieldName: EXTERNAL_UPDATES_TABLE_COLUMNS.start_date.key,
          compareFunction: compareDates,
        }),
      width: 120,
      Cell: ({ row }) =>
        makeTableCell({
          row,
          key: EXTERNAL_UPDATES_TABLE_COLUMNS.start_date.key,
          formatter: formatExternalUpdateInvoiceDate,
        }),
    },
    {
      Header: 'Last invoice date',
      accessor: EXTERNAL_UPDATES_TABLE_COLUMNS.end_date.key,
      sortType: (rowA, rowB) =>
        sortingFunction({
          rowA,
          rowB,
          fieldName: EXTERNAL_UPDATES_TABLE_COLUMNS.end_date.key,
          compareFunction: compareDates,
        }),
      width: 120,
      Cell: ({ row }) =>
        makeTableCell({
          row,
          key: EXTERNAL_UPDATES_TABLE_COLUMNS.end_date.key,
          formatter: formatExternalUpdateInvoiceDate,
        }),
    },
    {
      Header: 'Frequency',
      accessor: EXTERNAL_UPDATES_TABLE_COLUMNS.frequency.key,
      noInitialSortBy: true,
      disableFilters: true,
      disableSortBy: true,
      width: 80,
      Cell: ({ row }) => (
        <Centerer>
          <TableCell original={row.original.targetObject.invoicing_frequency} />
        </Centerer>
      ),
    },
    {
      Header: 'Amount',
      accessor: EXTERNAL_UPDATES_TABLE_COLUMNS.amount.key,
      sortType: (rowA, rowB) => sortingFunction({ rowA, rowB, fieldName: EXTERNAL_UPDATES_TABLE_COLUMNS.amount.key }),
      width: 80,
      Cell: ({ row }) =>
        makeTableCell({
          row,
          key: EXTERNAL_UPDATES_TABLE_COLUMNS.amount.key,
        }),
    },
    {
      Header: 'Created At',
      accessor: EXTERNAL_UPDATES_TABLE_COLUMNS.created_at.key,
      sortType: (rowA, rowB) =>
        compareDates(rowA.original.externalUpdate.created_at, rowB.original.externalUpdate.created_at) ? 1 : -1,
      width: 160,
      Cell: ({ row }) => (
        <Centerer>
          <TableCell original={formatDate(row.original.externalUpdate.created_at)} />
        </Centerer>
      ),
    },
    {
      accessor: 'actions',
      disableSortBy: true,
      width: 130,
      Cell: ({ row }) => (
        <>
          <ReviewButton
            onClick={() =>
              openInvoiceModal({
                invoice: { invoicing_schedule_id: row.original?.targetObject?.id },
                initialTab: INVOICING_SCHEDULE_TABS_PANEL_TABS.WARNINGS,
              })
            }
          >
            review
          </ReviewButton>
          <DismissButton
            onClick={async () =>
              await resolveExternalUpdate.mutateAsync({
                id: row.original?.externalUpdate?.id,
                data: { actionType: EXTERNAL_UPDATE_ACTION_OPTIONS.dismiss },
              })
            }
          >
            Dismiss
          </DismissButton>
        </>
      ),
    },
  ];

  return columns;
};
