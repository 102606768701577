import React from 'react';
import dayjs from 'dayjs';
import { EMOJI, GROWTH_TYPE_KEYS, NUMBER_FORMATS } from 'consts/global';
import { numberFormatter } from 'utils/formatters';
import { EmojiTooltip } from '../EmojiTooltip';

export const getPreviousMonth = ({ month, growthType }) => {
  switch (growthType) {
    case GROWTH_TYPE_KEYS.YoY:
      return dayjs(month, 'YYYY-MM').subtract(12, 'months');
    case GROWTH_TYPE_KEYS.QoQ:
      return dayjs(month, 'YYYY-MM').subtract(3, 'months');
    default:
      return dayjs(month, 'YYYY-MM').subtract(1, 'month');
  }
};

export const getGrowthBasedOnType = (ending, starting, type) => {
  switch (type) {
    case NUMBER_FORMATS.CURRENCY:
    case NUMBER_FORMATS.NUMBER:
      return starting > 0 ? (
        numberFormatter({
          type: NUMBER_FORMATS.PERCENT,
          rawValue: starting === 0 ? 100 : (ending - starting) / starting,
          decimalPlaces: 2,
        })
      ) : (
        <EmojiTooltip value={EMOJI.JOY_FACE} />
      );
    case NUMBER_FORMATS.PERCENT:
      return `${Math.floor((ending - starting) * 100)} points`;
    default:
      return '';
  }
};

export const reshapeRetentionDataForTable = (data) => {
  const {
    months,
    remainingAmountByCustomerByMonth,
    customerNamesById,
    remainingAmountByMonth,
    retentionByMonth,
    startingAmountByMonth,
    customerOrder,
  } = data;
  const revenueRowsByCustomer = {};
  for (const customerId of customerOrder) {
    revenueRowsByCustomer[customerId] = {};
    for (const month of months) {
      revenueRowsByCustomer[customerId][month] = remainingAmountByCustomerByMonth[month][customerId] ?? 0;
    }
  }
  return {
    data: {
      months,
      revenueRowsByCustomer,
      customerNamesById,
      totalAmountsEachMonth: remainingAmountByMonth,
      retentionByMonth,
      startingAmountByMonth,
    },
    shownCustomers: customerOrder,
  };
};
