import styled, { css } from 'styled-components';

import { numberFormatter } from 'utils/formatters';

const Container = styled.div`
  margin: 14px 0 3px 0;
  padding: 2px 4px;
  color: ${({ failed }) => (failed ? 'var(--primaryRed)' : 'var(--primaryBlack)')};
  font-size: 12px;
  line-height: 16px;

  ${({ failed }) =>
    failed &&
    css`
      background: var(--primaryRed10);
      border-radius: 4px;
    `}
`;

export const GoalsValueContainer = ({ dataType, value, decimalPlaces, actualValue, currency }) => (
  <Container failed={actualValue < value}>
    {!isNaN(value) ? numberFormatter({ type: dataType, rawValue: value, decimalPlaces: decimalPlaces, currency }) : '—'}{' '}
  </Container>
);
