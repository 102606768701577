import { FormikCustomSelector, FormikCustomRadio, FormikCustomInput } from 'components/Controls';
import { FlexerColumn, Spacer } from 'components/Core';
import {
  GROUP_TRANSACTIONS_FOR_SCHEDULES_BY_LABEL_TO_KEY,
  INVOICING_FREQUENCIES,
  MANUAL_GL_SERVICE_NAME,
  SELECTOR_PAYMENT_OPTIONS,
} from 'views/Billing/consts';
import { humanize } from 'utils/stringUtils';
import {
  CheckboxRow,
  ItemsRow,
  TermsSelectors,
  StyledFieldTitle,
  StyledFieldBody,
  InvoiceNumberLabel,
  InvoiceNumberAdditionalSettings,
} from './styles';
import { SENDER_OPTIONS } from '../BillingSenderSetttings/consts';

export const BillingSettings = ({ values, setFieldValue, invoicingServiceDisplayName }) => {
  const noGLIntegration = invoicingServiceDisplayName === humanize(MANUAL_GL_SERVICE_NAME);
  const invoiceNumbersManagerOptions = noGLIntegration
    ? []
    : [
        {
          label: (
            <InvoiceNumberLabel>
              {invoicingServiceDisplayName
                ? `Let ${invoicingServiceDisplayName} manage invoice number automation`
                : 'I will manage invoice numbers manually'}
            </InvoiceNumberLabel>
          ),
          value: false,
        },
      ];
  invoiceNumbersManagerOptions.push({
    label: <InvoiceNumberLabel>Let Subscript manage invoice number automation</InvoiceNumberLabel>,
    value: true,
  });

  const paymentLinkOptions = noGLIntegration
    ? []
    : [
        {
          label: `Use ${invoicingServiceDisplayName ?? 'no payment links'}`,
          value: false,
        },
      ];
  paymentLinkOptions.push({ label: 'Use Subscript payment links (processed via stripe)', value: true });

  return (
    <FlexerColumn>
      <StyledFieldBody>
        <StyledFieldTitle>
          <span>Default terms</span>
        </StyledFieldTitle>

        <TermsSelectors>
          <ItemsRow>
            <FormikCustomInput name="billingInvoiceDefaults.days_to_pay" label="Terms (Net)" type="number" />
            <FormikCustomSelector
              name="billingInvoiceDefaults.frequency"
              label="Billing frequency"
              placeholder="Select Billing frequency"
              options={Object.values(INVOICING_FREQUENCIES).map((frequency) => ({
                value: frequency,
                label: frequency,
              }))}
              noSort
            />
          </ItemsRow>

          <ItemsRow>
            <FormikCustomSelector
              name="billingInvoiceDefaults.payment_options"
              label="Payment Options"
              options={SELECTOR_PAYMENT_OPTIONS}
              handleChange={(selectedValues) =>
                setFieldValue(
                  'billingInvoiceDefaults.payment_options',
                  selectedValues?.map((option) => option.value) ?? [],
                )
              }
              isMulti
            />
          </ItemsRow>

          <ItemsRow>
            <FormikCustomSelector
              name="billingInvoiceDefaults.group_transactions_by"
              label="Group transactions by"
              options={Object.entries(GROUP_TRANSACTIONS_FOR_SCHEDULES_BY_LABEL_TO_KEY).map(([key, value]) => ({
                label: key,
                value,
              }))}
            />
          </ItemsRow>

          <ItemsRow>
            <FormikCustomRadio
              name="billingInvoiceDefaults.use_subscript_payment_link"
              options={paymentLinkOptions}
              disabled={values?.billingSenderDefaults?.sender === SENDER_OPTIONS.GL}
              label="Use Subscript payment links to lower fees - Subscript charges $0 and you only pay Stripe payment processing fees"
            />
          </ItemsRow>
        </TermsSelectors>
      </StyledFieldBody>

      <StyledFieldBody>
        <StyledFieldTitle>
          <span>Invoice number automation</span>
        </StyledFieldTitle>
        <p>
          When saving an invoice, if you don't enter an invoice number, an invoice number will be generated. You can
          choose which system will manage this invoice number automation.
        </p>
        <p>
          <b>Subscript</b> allows more customization in the invoice number prefix, and will make sure that draft
          invoices do not create gaps in accounting.
        </p>
        <Spacer height="12px" />
        <CheckboxRow>
          <FormikCustomRadio
            name="billingInvoiceDefaults.assign_incremental_invoice_number"
            flexDirection="column"
            gap="12px"
            options={invoiceNumbersManagerOptions}
          />
        </CheckboxRow>
        {values?.billingInvoiceDefaults?.assign_incremental_invoice_number && (
          <InvoiceNumberAdditionalSettings>
            <FormikCustomInput
              name="billingInvoiceDefaults.invoice_number_prefix"
              label="Invoice number prefix"
              placeholder="e.g. INV-"
              labelTooltipContent="Enter a fixed prefix here. If you want dynamic prefix, please contact support."
            />
            <FormikCustomInput
              name="billingInvoiceDefaults.invoice_number_digits"
              label="Invoice number padding"
              placeholder="e.g. 4"
              value={values?.billingInvoiceDefaults.invoice_number_digits ?? 0}
              labelTooltipContent="Example: a padding of 5 will generate invoice numbers such as 00123"
            />
          </InvoiceNumberAdditionalSettings>
        )}
        <Spacer height="16px" />
      </StyledFieldBody>
    </FlexerColumn>
  );
};
