import styled from 'styled-components';
import { IconButton } from 'components/Buttons';

export const Uploader = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: column;
  background-color: ${({ zoneHover, isLoading }) =>
    isLoading ? 'var(--primaryBlue5)' : zoneHover ? 'var(--primaryBlue)' : 'var(--primaryBlue3)'};
  border: ${({ zoneHover, isLoading }) =>
    zoneHover || isLoading ? '1px dashed var(--primaryBlue)' : '1px dashed var(--primaryBlue20)'};
  border-radius: 20px;
  cursor: ${({ zoneHover }) => (zoneHover ? 'pointer' : 'default')};
  opacity: ${({ isDisabled }) => isDisabled && '0.3'};
  pointer-events: ${({ isDisabled }) => isDisabled && 'none'};

  &:hover {
    background-color: var(--primaryBlue5);
    border: 1px dashed var(--primaryBlue);
    cursor: pointer;
  }
`;

export const UploadHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 25px;
  border-bottom: 1px solid var(--primaryBlue5);
`;

export const HeaderItem = styled.div`
  display: flex;
  padding: 20px;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  white-space: nowrap;

  a {
    margin-left: 4px;
    color: var(--primaryBlue);

    &:hover {
      color: var(--secondaryBlue);
    }
  }
`;

export const HeaderNumber = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  width: 20px;
  height: 20px;
  background: rgba(64, 80, 98, 0.98);
  border: 1px solid var(--primaryBlack5);
  border-radius: 100px;
  font-weight: 900;
  font-size: 10px;
  text-transform: uppercase;
  color: white;
`;

export const SubInfo = styled.div`
  opacity: 0.5;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  margin-top: 4px;
`;

export const InfoText = styled.div`
  display: flex;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;

  span {
    margin-left: 4px;
    color: var(--primaryBlue);
  }
`;

export const RemoveButton = styled.div`
  color: var(--primaryRed);
  margin-left: 4px;
  position: relative;
  z-index: 5;

  &:hover {
    color: var(--secondaryRed);
  }
`;

export const ZoneFooter = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  align-items: center;

  span {
    font-size: 12px;
    line-height: 16px;
    opacity: 0.2;
  }
`;

export const StyledIconButton = styled(IconButton)`
  position: relative;
  z-index: 5;
  border: 1px solid var(--accentGraySecond);
`;

export const FooterIcons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 68px;
  margin-bottom: 8px;
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
`;

export const OpacityText = styled.div`
  opacity: 0.3;
  margin-left: 4px;
  color: var(--primaryBlack);
`;

export const Zone = styled.div`
  width: 100%;
  display: flex;
  flex-grow: 1;
  gap: ${({ smallView }) => (smallView ? '16px' : '20px')};
  flex-direction: ${({ smallView }) => (smallView ? 'row' : 'column')};
  align-items: center;
  padding: ${({ smallView }) => (smallView ? '12px' : '24px')};
  cursor: pointer;

  svg {
    width: ${({ smallView }) => (smallView ? '40px' : '68px')};
    height: ${({ smallView }) => (smallView ? '40px' : '68px')};
  }

  img {
    max-width: ${({ smallView }) => (smallView ? '40px' : '68px')};
    max-height: ${({ smallView }) => (smallView ? '40px' : '68px')};
  }

  ${InfoWrapper} {
    align-items: ${({ smallView }) => (smallView ? 'flex-start' : 'center')};
  }
`;
