import React, { useContext } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { AppContext } from 'AppContext';
import { useBillingEmailTemplatesAPI } from 'api/billing/hooks';
import { FlexEndContainer, FlexerRow } from 'components/Core';
import {
  Modal,
  ModalButton,
  ModalCloseIcon,
  ModalContainer,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from 'components/Modal';
import { AITagSolidIcon } from 'components/Icons';
import { convertToPlainText } from 'utils/htmlUtils';
import { StyledCheckButton } from 'shared/ReportSubscribe/styles';
import { ModalSubtitle } from '../styles';
import { TemplateModalBody } from './TemplateModalBody';
import { TemplateAIModalBody } from './TemplateAIModalBody';
import { VARIABLE_EMAILS } from './TemplateEmailRecipients';

const initialEmptyTemplate = {
  subject: '',
  body: '',
  from_email: '',
  from_name: '',
  to_emails: [VARIABLE_EMAILS.FROM_INVOICE],
  days_from_due_date: 0,
  send_before: false,
};

const getInitialValue = ({ selectedTemplate }) => {
  return {
    ...selectedTemplate,
    send_before: selectedTemplate?.days_from_due_date < 0,
    days_from_due_date: Math.abs(selectedTemplate?.days_from_due_date),
  };
};

export const CollectionTemplateModal = ({ selectedTemplate, setShowTemplateModal }) => {
  const { orgId } = useContext(AppContext);
  const {
    operations: { editBillingEmailTemplate, addBillingEmailTemplate },
  } = useBillingEmailTemplatesAPI({ orgId });

  const handleFormSubmit = ({ values }) => {
    //remove fake id after merge with backend
    const dataToSend = {
      ...values,
      body: convertToPlainText(values.body),
      subject: convertToPlainText(values.subject),
    };

    if (dataToSend?.send_before) {
      dataToSend.days_from_due_date = -dataToSend.days_from_due_date;
    }

    delete dataToSend.send_before;

    if (selectedTemplate) {
      // it's a template we already had, so update
      editBillingEmailTemplate({ id: selectedTemplate.id, data: dataToSend });
    } else {
      // It's a new template, so create
      addBillingEmailTemplate({ data: dataToSend });
    }

    setShowTemplateModal(false);
  };

  // For AI reminder, we only allow the second event to be modified
  const canEditTemplateFrequency = selectedTemplate?.ai_enabled && selectedTemplate?.days_from_due_date > 0;

  return (
    <ModalContainer>
      <Formik
        initialValues={selectedTemplate ? getInitialValue({ selectedTemplate }) : initialEmptyTemplate}
        enableReinitialize={true}
        validationSchema={Yup.object({
          send_before: Yup.boolean().required(),
          days_from_due_date: Yup.number().required(),
          subject: Yup.string().nullable().required(),
          body: Yup.string().nullable().required(),
          to_emails: Yup.array(),
        })}
        onSubmit={(values) => handleFormSubmit({ values })}
      >
        {({ submitForm, values, setFieldValue }) => (
          <Modal maxWidth="640px" height="auto" overflow="visible">
            <ModalHeader>
              <ModalCloseIcon onClose={() => setShowTemplateModal(false)} width={36} height={36} />
              <ModalTitle padding="0 0 0 36px" bold>
                {selectedTemplate?.ai_enabled ? (
                  <FlexerRow alignItems="center" gap="8px">
                    <AITagSolidIcon /> Reminders by the Subscript AI
                  </FlexerRow>
                ) : (
                  <> {selectedTemplate ? 'Edit' : 'Create'} Event</>
                )}
              </ModalTitle>
            </ModalHeader>
            {(!selectedTemplate?.ai_enabled || canEditTemplateFrequency) && (
              <ModalSubtitle>
                {selectedTemplate?.ai_enabled
                  ? 'After the last reminder in the collection sequence:'
                  : 'Before or after how many (calendar) days from due date to apply this event?'}
              </ModalSubtitle>
            )}
            {selectedTemplate?.ai_enabled ? (
              <TemplateAIModalBody
                values={values}
                setFieldValue={setFieldValue}
                canEditTemplateFrequency={canEditTemplateFrequency}
              />
            ) : (
              <TemplateModalBody values={values} setFieldValue={setFieldValue} />
            )}

            <ModalFooter noFixedHeight padding="13px 21px">
              <FlexEndContainer>
                <ModalButton onClick={() => setShowTemplateModal(false)}>Cancel</ModalButton>
                <ModalButton onClick={() => submitForm()} className="primary">
                  Save
                  <StyledCheckButton />
                </ModalButton>
              </FlexEndContainer>
            </ModalFooter>
          </Modal>
        )}
      </Formik>
    </ModalContainer>
  );
};
