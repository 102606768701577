import { useEffect, useState } from 'react';

export const useWindowScrollPositions = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = document.getElementById('main-content')?.scrollTop ?? 0;
    setScrollPosition(position);
  };

  useEffect(() => {
    document.addEventListener('scroll', handleScroll, true);

    // Remove the event listener
    return () => {
      document.removeEventListener('scroll', handleScroll, true);
    };
  }, []);

  return {
    scrollPosition,
  };
};
