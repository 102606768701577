import { FORMULA_DATA_VARIABLES, FORMULA_SUPPORTED_FUNCTIONS_VALUES } from './consts';

export const formulaStringToHTMLString = (value) => {
  const functionsToReplace = Object.values(FORMULA_SUPPORTED_FUNCTIONS_VALUES);
  const dataToReplace = Object.values(FORMULA_DATA_VARIABLES);

  // Create a regular expression to match any occurrence of the arr items globally
  // Escape special characters in the array elements to prevent regex errors
  const generateRegex = (arr) => new RegExp(arr.map((v) => v.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')).join('|'), 'g');

  const result = value
    .replace(generateRegex(functionsToReplace), (match) => `<span class='formula-function'>${match}</span>`)
    .replace(generateRegex(dataToReplace), (match) => `<span class='formula-variable'>${match}</span>`);

  return result;
};
