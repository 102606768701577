import React, { useContext, useMemo } from 'react';
import { Formik } from 'formik';
import { isUndefined } from 'lodash';
import { AppContext } from 'AppContext';
import { INTEGRATION_METADATA_FIELDS, INTEGRATION_SERVICES } from 'consts/integrations';
import { Centerer, FlexBetweenContainer, Flexer, Row, Spacer } from 'components/Core';
import { FormikCustomSelector, FormikOnFormChangeEffect } from 'components/Controls';
import { GreyDot } from 'components/Icons';
import { IconContainerPill } from 'components/Icons';
import { CircleLoader } from 'components/Loaders';
import { ReactComponent as ReportIcon } from 'images/report-money.svg';
import { useReportsAPI } from 'api/reports';
import { useShareAPI } from 'api/share';
import { HEADER_TITLES } from 'shared/Layout';
import { getFilteredReports } from 'shared/Reports/ReportsPopover/utils';
import { MetricsSectionTitle, StyledMetricRow } from './styles';
import { DEFAULT_DASHBOARD_TITLE } from 'views/Dashboard/ReportBuilder/consts';

const MetricRow = ({ metricTitle, amount, difference }) => (
  <StyledMetricRow>
    <GreyDot />
    <div>
      {metricTitle} <b>{amount} </b>
      {!isUndefined(difference) && (
        <span>
          ({difference}% <span>from last month)</span>
        </span>
      )}
    </div>
  </StyledMetricRow>
);

export const EmailReportForShareSelector = ({ updateAnalyticsIntegration, analyticsIntegrations, orgId }) => {
  const { appSettings } = useContext(AppContext);

  const { data } = useReportsAPI({
    orgId,
  });

  const reportListForSelector = useMemo(
    () =>
      getFilteredReports({
        data: data ?? [],
        selectedReportType: 'snapshot',
      }).map((report) => ({
        label: report?.title,
        value: report?.id,
      })),
    [data],
  );

  const defaultDashboardReport = useMemo(
    () => reportListForSelector.find((report) => report.label === DEFAULT_DASHBOARD_TITLE),
    [reportListForSelector],
  );

  const metadataReport =
    analyticsIntegrations?.[INTEGRATION_SERVICES.EMAIL]?.metadata?.[INTEGRATION_METADATA_FIELDS.REPORT_FOR_SHARE];

  const selectedReportId = metadataReport ?? defaultDashboardReport?.value;

  const selectedReport = useMemo(() => (data ?? []).find((report) => report.id === selectedReportId), [
    data,
    selectedReportId,
  ]);

  const { data: notificationMetrics, isFetching } = useShareAPI({
    orgId,
    metadataFilter: selectedReport?.options?.dataFilter?.metadataFilter,
    pageToShare: HEADER_TITLES.dashboard,
    appSettings,
  });

  return (
    <>
      <FlexBetweenContainer>
        <Row>
          <IconContainerPill>
            <ReportIcon width="20px" height="20px" />
          </IconContainerPill>
          <Spacer width="12px" />

          <MetricsSectionTitle>
            <span>Use metrics from your saved report:</span>

            <Formik
              enableReinitialize={true}
              initialValues={{
                report: selectedReportId,
              }}
            >
              {({ values }) => (
                <>
                  <Row horizontal="start">
                    <FormikCustomSelector name="report" width="371px" options={reportListForSelector} />
                  </Row>
                  <FormikOnFormChangeEffect
                    onChange={() =>
                      updateAnalyticsIntegration({
                        service: INTEGRATION_SERVICES.EMAIL,
                        additionalMetadata: {
                          [INTEGRATION_METADATA_FIELDS.REPORT_FOR_SHARE]: values?.report,
                        },
                      })
                    }
                  />
                </>
              )}
            </Formik>
          </MetricsSectionTitle>
        </Row>
      </FlexBetweenContainer>

      <Spacer height="12px" />
      <Flexer direction="column" alignItems="start">
        {isFetching ? (
          <Centerer>
            <CircleLoader width={24} height={24} />
            <Spacer width="10px" />
          </Centerer>
        ) : (
          <>
            {(selectedReport?.options?.metricsSetting ?? [])
              .slice(0, 5)
              .map(
                (metric) =>
                  notificationMetrics?.[metric.id]?.label && (
                    <MetricRow
                      key={metric.id}
                      metricTitle={`${notificationMetrics?.[metric.id]?.label}:`}
                      amount={notificationMetrics?.[metric.id]?.value}
                      difference={notificationMetrics?.[metric.id]?.percentChange}
                    />
                  ),
              )}

            {selectedReport?.options?.metricsSetting?.length > 5 && (
              <MetricRow
                metricTitle={`+ ${
                  selectedReport?.options?.metricsSetting?.length - 5
                } metrics, which you can see on the saved report`}
              />
            )}
          </>
        )}
      </Flexer>
    </>
  );
};
