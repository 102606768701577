import React from 'react';

import { formatValueForDisplay } from 'utils/formatters';

import { DataWrapper } from '../styles';
import { ComparatorDisplay } from './ComparatorDisplay';

export const TableCell = ({ isLink, onClick, isChanged, original, updated, field, overrideOriginalDisplay }) => {
  return (
    <ComparatorDisplay
      isLink={isLink}
      onClick={onClick}
      isChanged={isChanged}
      original={original}
      updated={updated}
      displayOriginal={<DataWrapper>{formatValueForDisplay(original)}</DataWrapper>}
      displayUpdated={<DataWrapper highlight>{formatValueForDisplay(updated)}</DataWrapper>}
      overrideOriginalDisplay={overrideOriginalDisplay}
      field={field}
    />
  );
};
