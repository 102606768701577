import { isEmpty } from 'lodash';
import Fuse from 'fuse.js';
import { mapFilter } from './arrayUtils';

export const formatCustomerName = (name = '') => {
  const splitName = name.split(' ');
  const firstName = name.split(' ')?.[0];
  const secondInitial = splitName.length > 1 ? name.split(' ')?.[1]?.[0] : '';

  const period = firstName && secondInitial && '.';
  return `${firstName} ${secondInitial}${period}`;
};

export const cleanNumber = (value) => parseFloat(value?.replace(/[^\d.-]/g, ''));

export const isJsonString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

// COPY of splitSegmentString from stringUtils.js backend/src/utils
//  split segment string only once on first match of a "-" character
export const splitSegmentString = ({ string }) => {
  const [firstPart, ...rest] = string.split('-');
  let secondPart = rest.join('-');

  //remove timeseries postfix from second part
  secondPart = secondPart.replace('-Timeseries', '');

  return [firstPart, secondPart];
};

export const stringToBoolean = (s) => {
  if (typeof s === 'boolean') return s;
  if (typeof s !== 'string') return null;

  const lowerS = s.toLowerCase();
  if (lowerS === 'true') return true;
  if (lowerS === 'false') return false;

  return null;
};

export const pluralize = (count, text, displayCount = true) =>
  count > 1 ? `${displayCount ? count : ''} ${text}s` : `${displayCount ? count : ''} ${text}`;

export const capitalize = (str) => str?.charAt(0).toUpperCase() + str?.slice(1);

export const capitalizeEveryFirstLetter = (sentence) =>
  sentence
    .split(' ')
    .map((word) => word[0].toUpperCase() + word.slice(1))
    .join(' ');

export const lowerFirst = (str) => str?.charAt(0).toLowerCase() + str?.slice(1);

export const humanize = (str) => str?.split(/[-_]/).map(capitalize).join(' ');

export const dehumanize = (str) =>
  str
    ?.split(/\s+/)
    .map((part) => part.toLowerCase())
    .join('_');

export const includesSearchQuery = (str, searchQuery) => {
  if (isEmpty(searchQuery)) return true;
  if (isEmpty(str)) return false;
  const lowerStr = str.toLowerCase();
  return searchQuery
    .toLowerCase()
    .trim()
    .split(/\s+/)
    .every((token) => lowerStr.includes(token));
};

export const getNumberWithOrdinal = (n) => {
  const s = ['th', 'st', 'nd', 'rd'];
  const v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
};

export const SEARCH_CONFIDENCE = {
  EXACT: 4,
  FUZZY: 2,
  NO_MATCH: 0,
};

const fuzzySearch = ({ query, columns, dataset, maxScore = 1, fuse }) => {
  if (!query) return [];

  const lowerQuery = query?.toLowerCase();
  const exactMatches = dataset?.filter((data) =>
    columns?.some((column) => data?.[column]?.toLowerCase() === lowerQuery),
  );

  if (exactMatches?.length === 1) {
    return { data: exactMatches, query, confidence: SEARCH_CONFIDENCE.EXACT };
  } else if (exactMatches?.length > 1) {
    return { data: exactMatches, query, confidence: SEARCH_CONFIDENCE.FUZZY };
  }

  const fuzzyMatchesWithScore = fuse.search(query);

  const fuzzyMatches = fuzzyMatchesWithScore?.filter((match) => match.score < maxScore).map((match) => match.item);
  if (fuzzyMatches.length > 0) return { data: fuzzyMatches, query, confidence: SEARCH_CONFIDENCE.FUZZY };

  return { data: [], query, confidence: SEARCH_CONFIDENCE.NO_MATCH };
};

export const bulkFuzzySearch = ({ queries, dataset, columns, maxScore }) => {
  const fuse = new Fuse(dataset, {
    keys: columns,
    includeScore: true,
  });
  return queries.map((query) => fuzzySearch({ query, dataset, columns, maxScore, fuse }));
};

const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
export const isValidEmail = (value) => value.match(emailRegex);

export const joinNonEmptyStrings = (strings, seperator) =>
  mapFilter(strings, (x) => (x ? x.toString().trim() : x)).join(seperator);

export const addQuotesToKeysAndValuesToCreateValidJSON = (string) =>
  string
    .replace(/([{,]\s*)(\w+)(\s*:)/g, '$1"$2"$3') // add quotes to keys
    .replace(/:\s*([^",{}[\]]+)(?=\s*[,}\]])/g, ':"$1"'); // quote the entire value including complex expressions

/**
 * Humanize a timestamp in milliseconds to minutes and seconds.
 * @param {number} ms - The timestamp in milliseconds.
 * @returns {string} - Humanized time in "X min Y sec" format.
 */
export const humanizeTime = (ms) => {
  const totalSeconds = Math.floor(ms / 1000);
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;
  let result = '';
  if (minutes > 0) {
    result += `${minutes} min `;
  }
  if (seconds > 0 || minutes === 0) {
    result += `${seconds} sec`;
  }
  return result.trim();
};
