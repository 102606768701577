import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { BannersContainer, Banner, BannerText, BannerActions, BannerButton } from 'components/Blocks/Banner';

const Wrapper = styled.div`
  margin-top: 28px;
`;

export const UnconfirmedTransactionsBanner = () => (
  <Wrapper>
    <Link to="/transactions">
      <BannersContainer>
        <Banner tag="warning">
          <BannerText>
            In this period, there are <b>unconfirmed transactions</b> that were not taken into account in the analysis:
          </BannerText>
          <BannerActions>
            <BannerButton>Go to Transactions</BannerButton>
          </BannerActions>
        </Banner>
      </BannersContainer>
    </Link>
  </Wrapper>
);
