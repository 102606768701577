import config from 'config';
import { getAppSetting } from 'models/appSettings';
import { safeParseJson } from 'utils/jsonUtils';
import { Slack } from 'utils/slackClient';

const ERROR_MARGIN = 0.5;
const IS_CREATING_THREAD_KEY = 'isCreatingInvariantCheckSlackThread';
const THREAD_ID_KEY = 'invariantCheckSlackThreadId';
const SLACK_CHANNEL = config.app.INVARIANT_CHECK_SLACK_CHANNEL;

const RELEVANT_APP_SETTING_KEYS = [
  'committedPolicy',
  'committedStart',
  'influxMonths',
  'isARR',
  'isCommitted',
  'optimisticAnalytics',
  'quarters',
  'quartersYearOffset',
  'rollup',
  'countInfluxAsRenewed',
];

const RELEVANT_LOCAL_STORAGE_KEYS = [
  'cohort-page-segment-by',
  'cohorts-page-install-by',
  'cohorts-page-install-second-by',
  'datafilter-customer-types ',
  'datafilter-enddate',
  'datafilter-period',
  'datafilter-resolution',
  'datafilter-revenue-types',
  'datafilter-revenuedate',
  'datafilter-showing-conditions',
  'datafilter-startdate',
  'datafilter-waterfall-segment-by',
  'datafilter-waterfall-type',
  'metadataFilter',
  'spreads-page-segment-by',
  'transactions-page-confirmation',
  'transactions-page-group-by',
];

const postedMessages = new Set();
let slackBotSingleton;

const getSlackBot = () => {
  if (!SLACK_CHANNEL) return null;
  if (slackBotSingleton) return slackBotSingleton;

  // @deprecated - We don't use the Slack bot on the FE anymore.
  if (config.app.SLACK_BOT_ACCESS_TOKEN) {
    slackBotSingleton = new Slack({ token: config.app.SLACK_BOT_ACCESS_TOKEN });
    return slackBotSingleton;
  }
};

const getDebuggingInformation = () => ({
  ...RELEVANT_LOCAL_STORAGE_KEYS.reduce((acc, curr) => {
    const raw = localStorage.getItem(curr);
    acc[curr] = typeof raw === 'string' ? safeParseJson(raw, raw) : raw;
    return acc;
  }, {}),
  ...RELEVANT_APP_SETTING_KEYS.reduce((acc, curr) => {
    acc[curr] = getAppSetting(curr);
    return acc;
  }, {}),
});

const getDebuggingInformationMessage = () => '```\n' + JSON.stringify(getDebuggingInformation(), null, 2) + '\n```';

// Prevent a race condition when two threads try to create a thread at the same time
const createSlackThread = async ({ slackBot, message }) => {
  const isCreatingThread = sessionStorage.getItem(IS_CREATING_THREAD_KEY);
  if (isCreatingThread === 'true') {
    setTimeout(() => createWarningOnSlack({ slackBot, message }), 1000);
  } else {
    sessionStorage.setItem(IS_CREATING_THREAD_KEY, 'true');

    try {
      const response = await slackBot.chat.postMessage({
        channel: SLACK_CHANNEL,
        text: message,
      });

      const threadId = response?.ts;
      if (threadId) {
        sessionStorage.setItem(THREAD_ID_KEY, threadId);
      }

      postedMessages.add(message);
      createSlackComment({ slackBot, threadId, message: getDebuggingInformationMessage() });
    } catch (err) {
      setTimeout(() => createWarningOnSlack({ slackBot, message }), 1000);
    }

    sessionStorage.removeItem(IS_CREATING_THREAD_KEY);
  }
};

const createSlackComment = ({ slackBot, message, threadId }) =>
  slackBot.chat.postMessage({
    channel: SLACK_CHANNEL,
    thread_ts: threadId,
    text: message,
    type: 'mrkdwn',
  });

const createWarningOnSlack = async ({ slackBot, message }) => {
  if (postedMessages.has(message)) return;

  const threadId = sessionStorage.getItem(THREAD_ID_KEY);
  if (!threadId) {
    await createSlackThread({ slackBot, message });
  } else {
    try {
      await createSlackComment({ slackBot, message, threadId });
      postedMessages.add(message);
    } catch (err) {
      // Probably rate limited or thread is too long, just ignore
    }
  }
};

const logError = (message) => {
  if (process.env.NODE_ENV === 'development') {
    //@deprecated, we don't want to log invariant checkers anymore
    //console.error(message);
  }

  const slackBot = getSlackBot();
  if (!slackBot) {
    if (process.env.NODE_ENV !== 'development') {
      //@deprecated, we don't want to log invariant checkers anymore
      //console.error(message);
    }
  } else {
    createWarningOnSlack({ slackBot, message });
  }
};

const buildAsserter = (predicateFn, predicateText) => ({
  expected,
  actual,
  description,
  context,
  organization,
  user,
  errorMargin = ERROR_MARGIN,
}) => {
  if (predicateFn(expected, actual, errorMargin)) return true;

  let message = `Expected ${description} ${predicateText} *${expected}*, but received *${actual}*`;
  if (context) message += ` in ${context}`;
  if (user) message += ` (triggered by ${user.email || user.name || user.nickname || user.sub})`;

  const orgContext = organization ? ` *${organization.name}* (id ${organization.id})` : '';
  logError(`_(Invariant Check)_ :warning: ${orgContext}: ${message}`);

  return false;
};

export const assertCloseTo = buildAsserter(
  (expected, actual, errorMargin) => Math.abs(expected - actual) <= errorMargin,
  'to be close to',
);

export const assertNotGreaterThan = buildAsserter(
  (expected, actual, errorMargin) => actual - expected <= errorMargin,
  'not to be greater than',
);

export const assertNotLowerThan = buildAsserter(
  (expected, actual, errorMargin) => expected - actual <= errorMargin,
  'not to be lower than',
);
