import dayjs from 'dayjs';
import { numberFormatter } from 'utils/formatters';
import { NUMBER_FORMATS } from 'consts/global';
import { getCustomerDisplayName } from 'models/customer';
import { getTransactionAmount } from 'views/Transactions/utils';
import { STATUS_OPTIONS, TRANSACTION_FIELD_VALUE_BY_ID, TRANSACTION_TABLE_COLUMN_KEY } from './consts';
import { transactionDisplayTitle } from 'models/transaction';

export const getTransactionFieldValue = ({ key, transaction, currency }) => {
  const customerName = getCustomerDisplayName({
    customerName: transaction.customer_name,
    customerId: transaction.customer_id,
    customerEmail: transaction.customer_email,
  });
  switch (key) {
    case TRANSACTION_FIELD_VALUE_BY_ID[TRANSACTION_TABLE_COLUMN_KEY.ID]:
      return transaction[key];
    case TRANSACTION_FIELD_VALUE_BY_ID[TRANSACTION_TABLE_COLUMN_KEY.EXTERNAL_ID]:
      return transaction[key]?.join(', ');
    case TRANSACTION_FIELD_VALUE_BY_ID[TRANSACTION_TABLE_COLUMN_KEY.SEATS]:
      return transaction[key];
    case TRANSACTION_FIELD_VALUE_BY_ID[TRANSACTION_TABLE_COLUMN_KEY.DATE]:
    case TRANSACTION_FIELD_VALUE_BY_ID[TRANSACTION_TABLE_COLUMN_KEY.UPDATED_AT]:
    case TRANSACTION_FIELD_VALUE_BY_ID[TRANSACTION_TABLE_COLUMN_KEY.CREATED_AT]:
      return dayjs.utc(transaction[key]).format('MMM D, YYYY');
    case TRANSACTION_FIELD_VALUE_BY_ID[TRANSACTION_TABLE_COLUMN_KEY.DEAL_NAME]:
      return transactionDisplayTitle(transaction);
    case TRANSACTION_FIELD_VALUE_BY_ID[TRANSACTION_TABLE_COLUMN_KEY.CUSTOMER]:
      return customerName ?? 'No Customer assigned';
    case TRANSACTION_FIELD_VALUE_BY_ID[TRANSACTION_TABLE_COLUMN_KEY.AMOUNT]:
      // specifically for transaction amount, if the amount === null and the recognition is `event recurring` then we can get the value from the spread sum
      const value = getTransactionAmount({
        recognition: transaction[TRANSACTION_FIELD_VALUE_BY_ID[TRANSACTION_TABLE_COLUMN_KEY.RECOGNITION_TYPE]],
        transactionAmount: transaction[key],
        spreadSum: transaction[TRANSACTION_FIELD_VALUE_BY_ID[TRANSACTION_TABLE_COLUMN_KEY.SPREAD_SUM]],
      });
      return numberFormatter({ type: NUMBER_FORMATS.CURRENCY, rawValue: value, currency });
    case TRANSACTION_FIELD_VALUE_BY_ID[TRANSACTION_TABLE_COLUMN_KEY.RECOGNITION_TYPE]:
      return transaction[key] ? transaction[key] : '-';
    case TRANSACTION_FIELD_VALUE_BY_ID[TRANSACTION_TABLE_COLUMN_KEY.PRODUCT]:
      return transaction[key] || 'No product';
    case TRANSACTION_FIELD_VALUE_BY_ID[TRANSACTION_TABLE_COLUMN_KEY.START_DATE]:
    case TRANSACTION_FIELD_VALUE_BY_ID[TRANSACTION_TABLE_COLUMN_KEY.END_DATE]:
      return transaction[key] ? dayjs.utc(transaction[key]).format('MMM YYYY') : '-';
    case TRANSACTION_FIELD_VALUE_BY_ID[TRANSACTION_TABLE_COLUMN_KEY.STATUS]:
      return transaction.archived_at
        ? STATUS_OPTIONS.DELETED
        : transaction[key]
        ? STATUS_OPTIONS.CONFIRMED
        : STATUS_OPTIONS.UNCONFIRMED;
    case TRANSACTION_FIELD_VALUE_BY_ID[TRANSACTION_TABLE_COLUMN_KEY.CHECKS]:
      return transaction[key] ? 'Checks Passed' : null;
    case TRANSACTION_FIELD_VALUE_BY_ID[TRANSACTION_TABLE_COLUMN_KEY.LEFT_TO_RECOGNIZE]:
      return transaction.spread_total != null && transaction.recognition === 'event not recurring'
        ? numberFormatter({
            type: NUMBER_FORMATS.CURRENCY,
            rawValue: transaction.amount - transaction.spread_total,
            currency,
          })
        : '-';
    default:
      return null;
  }
};

export const customGlobalFilter = (rows, columnIds, searchQuery) => {
  if (rows.length === 0) return rows;
  if (!searchQuery) return rows;

  const lowercaseQuery = searchQuery.toLowerCase();
  let rowMatches;
  if (rows[0].depth === 0) {
    rowMatches = (row) => {
      return (
        Object.values(row.values).some(
          (rowValue) => typeof rowValue === 'string' && rowValue.toLowerCase().includes(lowercaseQuery),
        ) || row.subRows.some(rowMatches)
      );
    };
  } else {
    rowMatches = (row) => {
      return (
        Object.values(row.values).some(
          (rowValue) => typeof rowValue === 'string' && rowValue.toLowerCase().includes(lowercaseQuery),
        ) || row.subRows.some(rowMatches)
      );
    };
  }

  const returned = rows.filter(rowMatches);
  return returned;
};

export const checkRowIsReviewed = (row) => {
  return row.original.reviewed_at ? dayjs(row.original.reviewed_at).diff(row.original.updated_at) >= 0 : false;
};
