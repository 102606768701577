import styled from 'styled-components';

export const Info = styled.div`
  margin-top: 28px;
  padding: 20px;
  display: flex;
  align-items: flex-start;
  font-size: 14px;
  line-height: 20px;
  background: var(--primaryBlack2);
  border-radius: 16px;

  svg {
    margin-right: 8px;
  }
`;

export const FormRow = styled.div`
  margin-bottom: 24px;
`;

export const Count = styled.div`
  opacity: 0.4;
  margin-right: 12px;
`;

export const Bullet = styled.div`
  opacity: 0.4;
  margin-right: 12px;
  margin-left: 10px;
`;

export const OtherSubtitle = styled.div`
  opacity: 0.4;
  margin-left: 4px;
`;

export const RangeDescription = styled.div`
  opacity: 0.4;
  font-style: italic;
  margin-top: 8px;
  margin-left: 32px;
  font-size: 12px;
  line-height: 16px;
`;

export const RowHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const LoadingState = styled.div`
  margin: 30px 0;
  height: 172px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: var(--primaryBlack2);
  border-radius: 16px;

  p {
    font-size: 14px;
    line-height: 20px;
    max-width: 240px;
  }

  svg {
    margin-bottom: 12px;
  }
`;

export const ValuesWrapper = styled.div`
  max-height: 130px;
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
`;

export const DroppableArea = styled.div`
  padding: 8px 28px;
  border-radius: 12px;
  margin-top: 4px;
  background-color: ${({ isDraggingOver }) => isDraggingOver && 'var(--primaryBlue5)'};
  border: ${({ canDragged }) => (canDragged ? '1px dashed var(--primaryBlue)' : '1px solid transparent')};
  transition: all ease 0.4s;
`;

export const Value = styled.div`
  padding: 4px 8px;
  color: ${({ isDragging }) => (isDragging ? 'var(--secondaryBlue)' : 'var(--primaryBlue)')};
  background: ${({ isDragging }) => (isDragging ? 'white' : 'var(--primaryBlue5)')};
  border: ${({ isDragging }) => (isDragging ? '1px solid var(--primaryBlue)' : '1px solid transparent')};
  border-radius: 8px;
  cursor: pointer;
  margin-right: 4px;
  margin-bottom: 4px;
  transition: all ease 0.4s;

  &:hover {
    background: white;
    color: var(--secondaryBlue);
    border: 1px solid var(--primaryBlue);
  }
`;

export const RemoveContainer = styled.div`
  display: flex;
  align-items: center;
  color: var(--primaryRed30);
  cursor: pointer;
  margin-left: 12px;

  &:hover {
    color: var(--primaryRed);

    svg g {
      opacity: 1;
    }
  }

  svg {
    margin-left: 4px;
  }
`;

export const NoItemsLabel = styled.div`
  font-style: italic;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  opacity: 0.3;
`;

export const AddSegmentButton = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: var(--primaryBlue);
  cursor: pointer;
  display: flex;
  align-items: center;

  svg path {
    fill: var(--primaryBlue);
  }

  &:hover {
    color: var(--secondaryBlue);

    svg path {
      fill: var(--secondaryBlue);
    }
  }

  svg {
    margin-right: 4px;
  }
`;

export const SwitchLabel = styled.span`
  font-weight: 700;
  margin-right: 8px;
  opacity: ${({ isOn }) => (isOn ? '0.3' : '1')};
`;

export const ZeroBucketLabel = styled.span`
  font-weight: bolder;
  text-decoration: ${({ deactivated }) => (deactivated ? 'line-through' : '')};
  opacity: ${({ deactivated }) => (deactivated ? '0.3' : '1')};
`;
