import { useEffect } from 'react';

const DEFAULT_PREFETCH_RANGE = 3;

// When the current page is fetched (in fetchedData), continue fetching the surrounding pages in advance
export const usePrefetchingPages = ({
  orgId,
  queryClient,
  fetchedData,
  fetchParams,
  cacheKey,
  fetcher,
  prefetchRange = DEFAULT_PREFETCH_RANGE,
}) => {
  useEffect(() => {
    const currentPage = parseInt(fetchedData?.metadata?.currentPage);
    const maxPage = parseInt(fetchedData?.metadata?.maxPage);

    if (!fetchParams || !currentPage || !maxPage) return;

    const lowerBound = Math.max(currentPage - prefetchRange, 1);
    const upperBound = Math.min(currentPage + prefetchRange, maxPage);

    for (let pageIndex = lowerBound; pageIndex <= upperBound; pageIndex++) {
      if (pageIndex === currentPage) continue;
      const prefetchParams = { ...fetchParams, page: pageIndex };
      const prefetchDataKey = [cacheKey, orgId, prefetchParams];
      queryClient.prefetchQuery(prefetchDataKey, () => fetcher(prefetchParams));
    }
  }, [orgId, queryClient, fetchedData, fetchParams, cacheKey, fetcher, prefetchRange]);
};
