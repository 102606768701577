import React, { useContext, useState } from 'react';
import { ReactComponent as ArrowDown } from 'images/arrows-bold-down.svg';
import { CircleLoader, TimeLoader, TimeLoaderContainer } from 'components/Loaders';
import { Spacer } from 'components/Core';
import { PageWrapper, ScenarioCardGroup, ScenarioListWrapper, ShowOutdatedButton } from './styles';
import { PageEmptyState } from './Components/PageEmptyState';
import { ScenariosChart } from './Components/ScenariosChart';
import { ForecastingContext } from './ForecastingContext';
import { ScenarioCard } from './Components/ScenarioCard';
import { useEditForecastModal } from './Components/EditForecastModal';
import { useDeleteForecastModal } from './Components/DeleteForecastModal';
import { FORECAST_SCENARIO_TYPE } from './Components/consts';

export const ForecastingPage = () => {
  const {
    data,
    isLoading,
    monthlyRecurringRevenue,
    outdatedCount,
    outdatedData,
    fetchOutdatedForecasts,
    isLoadingOutdated,
    highlightedScenario,
  } = useContext(ForecastingContext);
  const [showOutdated, setShowOutdated] = useState(false);

  const handleClickShowOutdated = async () => {
    if (showOutdated) {
      setShowOutdated(false);
    } else {
      fetchOutdatedForecasts();
      setShowOutdated(true);
    }
  };

  return (
    <PageWrapper>
      <TimeLoaderContainer isLoading={isLoading}>
        {isLoading ? (
          <TimeLoader pageName="forecasting" />
        ) : (
          <>
            {data?.length === 0 && outdatedCount === 0 && <PageEmptyState />}

            {data && <ScenarioList data={data} highlightedScenario={highlightedScenario} />}
            {data && data?.length > 0 && (
              <ScenariosChart data={data} monthlyRecurringRevenue={monthlyRecurringRevenue} />
            )}

            {outdatedCount > 0 && (
              <div>
                <Spacer height="20px" />
                <ShowOutdatedButton
                  onClick={handleClickShowOutdated}
                  showing={showOutdated}
                  data-cy="forecasting-page__show-outdated-scenarios-button"
                >
                  {`${showOutdated ? 'Hide' : 'Show'} ${outdatedCount} old forecast${outdatedCount > 1 ? 's' : ''}`}{' '}
                  <ArrowDown />
                </ShowOutdatedButton>
                <Spacer height="20px" />
              </div>
            )}

            {isLoadingOutdated && (
              <div data-cy="outdated-spinner">
                <CircleLoader isAbsolute />
              </div>
            )}
            {showOutdated && outdatedData && <ScenarioList data={outdatedData} />}
          </>
        )}
      </TimeLoaderContainer>
    </PageWrapper>
  );
};

const ScenarioList = ({ data, highlightedScenario }) => {
  const { Modal: EditForecastModal, openModal: openEditForecastModal } = useEditForecastModal();
  const { Modal: DeleteForecastModal, openModal: openDeleteForecastModal } = useDeleteForecastModal();

  const handleEditScenario = (scenario) => openEditForecastModal({ scenario });
  const handleDeleteScenario = (scenario) => openDeleteForecastModal({ scenario });

  return (
    <ScenarioListWrapper>
      {data?.map((forecast) =>
        forecast.scenarios ? (
          <ScenarioCardGroup key={forecast.id}>
            {forecast.scenarios.map((scenario, index, array) => (
              <ScenarioCard
                key={`${forecast.id}-${scenario.code}`}
                scenario={{
                  id: forecast.id,
                  name: forecast.name,
                  bucket: scenario.name,
                  previousBucket: array[index - 1]?.name,
                  scenario: scenario.scenario,
                  forecastScenarioType: FORECAST_SCENARIO_TYPE.UNWEIGHTED,
                }}
                onEdit={handleEditScenario}
                onRemove={handleDeleteScenario}
                initiallyExpanded={highlightedScenario && highlightedScenario.name === forecast.name && index === 0}
              />
            ))}
          </ScenarioCardGroup>
        ) : (
          <ScenarioCard
            key={forecast.id}
            scenario={forecast}
            onEdit={handleEditScenario}
            onRemove={handleDeleteScenario}
            initiallyExpanded={highlightedScenario && highlightedScenario.name === forecast.name}
          />
        ),
      )}
      <EditForecastModal />
      <DeleteForecastModal />
    </ScenarioListWrapper>
  );
};
