import React, { useState, useContext } from 'react';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { Bar, defaults } from 'react-chartjs-2';
import { ReactComponent as ChartIcon } from 'images/chart-icon.svg';
import { AppContext } from 'AppContext';
import { cssVar } from 'polished';
import { reFormatDate } from 'utils/dateUtils';
import { FlexerStart } from 'views/Dashboard/Common/styled';
import { TabButton } from 'components/Buttons';
import { Column, Spacer } from 'components/Core';
import { REVENUE_METRIC } from 'consts/revenueMetrics';
import { numberFormatter } from 'utils/formatters';
import { NUMBER_FORMATS } from 'consts/global';
import { WarnBlock } from 'components/Blocks';

const ChartLegendContainer = styled.div`
  width: 100%;
  border-radius: 16px;
  border: 1px solid var(--accentGraySecond);
`;

const ChartContainer = styled.div`
  width: 100%;
  padding: 20px 20px 0;
  height: 340px;
  border-radius: 16px;
  border-bottom: none;
  position: relative;
`;

const TabsTitle = styled.div`
  font-size: 20px;
  line-height: 26px;
  margin: 0 12px;
`;

export const CUSTOMER_UNIT_OPTIONS = {
  RECURING: REVENUE_METRIC.TOTAL.key,
  NON_RECURING: 'nonRecurring',
  RECURRING_AND_NON: 'recurringAndNonRecurring',
};

export const CUSTOMER_UNIT_OPTIONS_LABELS = {
  [REVENUE_METRIC.TOTAL.key]: 'Recurring',
  nonRecurring: 'Non-Recurring',
  recurringAndNonRecurring: 'All Revenue',
};

const CustomerCharts = ({ customer }) => {
  defaults.global.defaultFontFamily = 'Nunito Sans';
  const {
    organizations,
    appSettings: { isARR, currencyISOCode: currency },
  } = useContext(AppContext);

  const isMultiCurrency = organizations?.[0]?.currencies?.length > 1 ?? false;

  const [chartUnits, setChartUnits] = useState(CUSTOMER_UNIT_OPTIONS.RECURING);

  if (!customer.waterfall) {
    // To have the right spacing
    return (
      <Column horizontal="flex-start">
        <FlexerStart style={{ marginBottom: 24 }}></FlexerStart>
      </Column>
    );
  }

  const getMultiplyFactor = (units) => (isARR && units !== CUSTOMER_UNIT_OPTIONS.NON_RECURING ? 12 : 1);

  const startDate = dayjs.utc(customer.start_date);
  const todayPlus1Year = dayjs().add(1, 'year');
  const customerEndDate = dayjs.utc(customer.end_date);
  const endDate = todayPlus1Year.isBefore(customerEndDate) ? todayPlus1Year : customerEndDate;

  const labels = [];
  const lineData = [];
  for (
    let currentDate = startDate;
    currentDate.isBefore(endDate.add(1, 'month').endOf('month'));
    currentDate = currentDate.add(1, 'month')
  ) {
    labels.push(currentDate.format('MMM YYYY'));
    let data = 0;
    if (chartUnits === CUSTOMER_UNIT_OPTIONS.RECURRING_AND_NON) {
      data +=
        (customer.waterfall[currentDate.format('YYYY-MM')]?.[CUSTOMER_UNIT_OPTIONS.RECURING] ?? 0) *
        getMultiplyFactor(CUSTOMER_UNIT_OPTIONS.RECURING);
      data +=
        (customer.waterfall[currentDate.format('YYYY-MM')]?.[CUSTOMER_UNIT_OPTIONS.NON_RECURING] ?? 0) *
        getMultiplyFactor(CUSTOMER_UNIT_OPTIONS.NON_RECURING);
    } else {
      data += (customer.waterfall[currentDate.format('YYYY-MM')]?.[chartUnits] ?? 0) * getMultiplyFactor(chartUnits);
    }

    lineData.push(Math.round(data));
  }

  const datasets = [
    {
      type: 'line',
      label: 'Revenue',
      fill: true,
      borderColor: cssVar('--primaryGreen'),
      pointBackgroundColor: cssVar('--primaryGreen'),
      pointBorderColor: 'white',
      backgroundColor: 'rgba(9, 146, 89, 0.1)',
      borderWidth: 2,
      pointRadius: 4,
      lineTension: 0,
      data: lineData,
    },
  ];

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    tooltips: {
      mode: 'index',
      callbacks: {
        label: function (tooltipItem) {
          return numberFormatter({ type: NUMBER_FORMATS.CURRENCY, rawValue: tooltipItem.yLabel, currency });
        },
      },
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
          },
          ticks: {
            callback: function (value) {
              return dayjs(value).get('month') === 0
                ? value.toUpperCase()
                : reFormatDate(value, 'MMM YYYY', 'MMM YYYY').toUpperCase();
            },
          },
        },
      ],
      yAxes: [
        {
          position: 'left',
          gridLines: {
            display: false,
            drawBorder: false,
          },
          ticks: {
            min: 0,
            maxTicksLimit: 6,
            callback: function (value) {
              return numberFormatter({ type: NUMBER_FORMATS.CURRENCY, rawValue: value, currency });
            },
          },
        },
      ],
    },
  };

  return (
    <Column horizontal="flex-start">
      <FlexerStart style={{ marginBottom: 24 }}>
        <ChartIcon />
        <TabsTitle>Chart:</TabsTitle>
        {Object.values(CUSTOMER_UNIT_OPTIONS).map((option) => (
          <TabButton key={option} active={chartUnits === option} onClick={() => setChartUnits(option)}>
            {CUSTOMER_UNIT_OPTIONS_LABELS[option]}
          </TabButton>
        ))}
      </FlexerStart>
      {isMultiCurrency && customerEndDate.isAfter(dayjs(), 'day') && (
        <>
          <WarnBlock>
            Some revenue after {dayjs().subtract(1, 'month').format('MMM YYYY')} may look off because we don't have
            exchange rates for future dates yet
          </WarnBlock>
          <Spacer height="8px" />
        </>
      )}
      <ChartLegendContainer>
        <ChartContainer>
          <Bar
            height={78}
            data={{
              labels,
              datasets,
            }}
            options={options}
          />
        </ChartContainer>
      </ChartLegendContainer>
    </Column>
  );
};

export { CustomerCharts };
