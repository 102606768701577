import React, { useState, useRef, useContext, useEffect } from 'react';
import dayjs from 'dayjs';
import { AppContext } from 'AppContext';
import { ReactComponent as TableExportIcon } from 'images/table-export.svg';
import { TimeLoader, TimeLoaderContainer } from 'components/Loaders';
import { CircleButton } from 'components/Buttons';
import { isNil } from 'lodash';
import { UnconfirmedTransactionsBanner } from 'shared/Banners';
import { NoIntegrations } from 'shared/ErrorBars';
import { DATA_TYPES } from 'shared/Filters/utils';
import { useRecurringRevenueCachedMetrics, useInvariantCheck } from 'utils/hooks';
import { SpreadsChart } from './SpreadsChart';
import { SpreadsSummaryCard } from './SpreadsSummaryCard';
import { ChartAndTotalRevenueContainer } from './SpreadsTable.styles';
import { checkAverageAndSumRows, checkSumOfSegments } from './invariantChecks';
import { Table } from './Table';

const SpreadsTable = ({
  organizations,
  spreadsData,
  isLoading,
  shownCustomers,
  sortCustomers,
  loadMoreCustomers,
  fetchSpreadsData,
  exportScreenshotRef,
  handleExport,
  selectedMonth,
  cohortsDrilldown,
  cohortPeriodType,
  segmentBy,
  setTransactionToShow,
  setCellToExplain,
  isRevenueAnnualizable,
  isMultiMonthCohortDrilldown,
  dataTypeToShow = DATA_TYPES.revenue,
  showChart = true,
  isARROverride = null,
}) => {
  const {
    integrations,
    appSettings: { isARR, isCommitted, rollup, optimisticAnalytics },
    dataFilter,
    metadataFilter,
    countInfluxAsRenewed,
  } = useContext(AppContext);
  const tableHeader = useRef();

  const [sortByMonth, setSortByMonth] = useState(null);
  const [isDescSort, setIsDescSort] = useState(false);

  useEffect(() => {
    sortCustomers({ isDesc: isDescSort, sortMonth: sortByMonth });
    // eslint-disable-next-line
  }, [sortByMonth, isDescSort]);

  // Invariant checking for ARR, if it's only recurring revenue
  useRecurringRevenueCachedMetrics({
    readyData:
      !countInfluxAsRenewed &&
      Object.keys(spreadsData).length > 0 &&
      !isLoading &&
      !cohortsDrilldown && // don't do this for cohorts drilldown since not all customers are in the data
      dataFilter.revenueTypes?.length === 1 &&
      dataFilter.revenueTypes[0] === 'recurring' &&
      !metadataFilter // if there's a metadata filter on, it doesn't apply on this page and so recurring revenue won't match
        ? spreadsData.totalAmountsEachMonth
        : null,
    getValue: ({ readyData }) => {
      const thisMonth = dayjs();
      return Object.entries(readyData ?? {}).reduce((acc, [month, amount]) => {
        // When optimistic mode is on, ARR on this page won't necessarily tie with ARR on Dashboard for
        // the current month and future months.
        if (dayjs(`${month}-15`).isBefore(thisMonth, 'month') || !optimisticAnalytics) {
          acc[month] = isARR ? amount * 12 : amount;
        }
        return acc;
      }, {});
    },
    context: 'Spreads',
    lastTransactionUpdatedAt: spreadsData?.lastTransactionUpdatedAt,
    isCommitted,
    rollup,
  });

  useInvariantCheck({
    readyData: spreadsData && Object.keys(spreadsData).length > 0 ? spreadsData : null,
    checkers: [checkSumOfSegments, checkAverageAndSumRows],
  });

  let hasTransactions = organizations && parseInt(organizations[0]?.transactionCount) > 0;
  if (integrations.length > 0 && !hasTransactions) {
    return <NoIntegrations />;
  }

  return (
    <TimeLoaderContainer>
      {Object.keys(spreadsData).length === 0 && isLoading ? (
        <TimeLoader pageName="spreads" />
      ) : (
        <>
          {spreadsData?.hasUnconfirmedTransactions && <UnconfirmedTransactionsBanner />}

          {/* Table Header and Actions */}
          <div ref={tableHeader} className="flexer-space-between">
            {
              /*Left for SpreadsModal*/
              handleExport && (
                <CircleButton onClick={handleExport} icon={<TableExportIcon />} hoverText="Export" XOffset={-30} />
              )
            }
          </div>
          <div ref={exportScreenshotRef}>
            {showChart && (
              <ChartAndTotalRevenueContainer>
                {/* Spreads Chart */}
                <SpreadsChart
                  spreadsData={spreadsData}
                  selectedMonth={selectedMonth}
                  cohortsDrilldown={cohortsDrilldown}
                  dataTypeToShow={dataTypeToShow}
                />

                {/* Spreads Summary Card */}
                {cohortsDrilldown && <SpreadsSummaryCard spreadsData={spreadsData} selectedMonth={selectedMonth} />}
              </ChartAndTotalRevenueContainer>
            )}

            {/* Spreads Table */}
            <Table
              spreadsData={spreadsData}
              cohortsDrilldown={cohortsDrilldown}
              cohortPeriodType={cohortPeriodType}
              selectedMonth={selectedMonth}
              isDescSort={isDescSort}
              setIsDescSort={setIsDescSort}
              sortByMonth={sortByMonth}
              setSortByMonth={setSortByMonth}
              shownCustomers={shownCustomers}
              segmentBy={segmentBy}
              organizations={organizations}
              loadMoreCustomers={loadMoreCustomers}
              fetchSpreadsData={fetchSpreadsData}
              setTransactionToShow={setTransactionToShow}
              setCellToExplain={setCellToExplain}
              isRevenueAnnualizable={
                !isNil(isARROverride) ? isARROverride && isRevenueAnnualizable : isRevenueAnnualizable
              }
              isMultiMonthCohortDrilldown={isMultiMonthCohortDrilldown}
              dataTypeToShow={dataTypeToShow}
            />
          </div>
        </>
      )}
    </TimeLoaderContainer>
  );
};

export default SpreadsTable;
