import styled, { css } from 'styled-components';

import { ReactComponent as EditCircle } from 'images/edit-circle.svg';
import { Centerer } from 'components/Core';

export const Heading = styled.h2`
  margin-bottom: 0;
  font-weight: 900;
  white-space: nowrap;
  font-size: 20px;
  line-height: 26px;
  color: var(--primaryBlack);
`;

export const TransactionStatusContainer = styled.div`
  ${(props) =>
    props.pageView &&
    css`
      position: absolute;
      left: 32px;
      bottom: -8px;
      padding: 0 8px;
      background: var(--primaryGray);
    `}
`;

export const TransactionStatus = styled(Centerer)`
  position: relative;
  cursor: ${(props) => !props.isConfirmed && 'pointer'};

  color: ${(props) =>
    props.isDeleted ? 'var(--primaryRed)' : props.isConfirmed ? 'var(--primaryBlack30)' : 'var(--secondaryYellow)'};
  font-weight: ${(props) => (props.pageView ? 400 : 900)};
  font-size: ${(props) => (props.pageView ? '12px' : '20px')};
  line-height: ${(props) => (props.pageView ? '16px' : '26px')};

  span {
    margin-right: 4px;
    color: var(--primaryBlack30);
  }

  svg {
    margin-left: ${(props) => props.pageView && '5px'};

    g {
      opacity: 1;
    }

    path {
      fill: var(--secondaryYellow);
    }
  }
`;

export const UpdatedPill = styled(TransactionStatus)`
  span {
    color: var(--secondaryYellow);
  }

  svg {
    path {
      fill: var(--secondaryYellow);
    }
  }
`;

export const TransactionName = styled.span`
  font-size: ${(props) => (props.pageView ? '24px' : '20px')};
  line-height: ${(props) => (props.pageView ? '38px' : '26px')};
  font-weight: ${(props) => (props.pageView ? 900 : 400)};
  cursor: pointer;
  padding: 5px;
  border: 1px solid transparent;

  &:hover {
    background-color: var(--primaryBlack3);
    border: 1px solid var(--primaryBlack10);
    border-radius: 8px;
  }
`;

export const EditCircleWrapper = styled(Centerer)`
  padding: 8px;
  border-radius: 50%;
  border: 1px solid var(--accentGraySecond);
  background-color: white;
  margin-left: 5px;
  cursor: pointer;
  position: relative;
`;

export const StyledEditCircle = styled(EditCircle)`
  width: 20px;
  height: 20px;

  path {
    fill: var(--primaryBlack);
  }
`;
