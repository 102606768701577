import styled, { css } from 'styled-components';
import { EditMetricCardButton } from '../EditMetricCard';
import {
  CARD_SIZES,
  CARD_SIZES_COLUMN_SIZES_V1,
  CARD_SIZES_COLUMN_SIZES_V2,
  CARD_SIZES_ROW_SIZES_V1,
  CARD_SIZES_ROW_SIZES_V2,
} from './consts';

export const CardsGrid = styled.div`
  display: grid;
  grid-template-columns: ${({ version }) => (version > 1 ? 'repeat(12, minmax(0, 1fr))' : 'repeat(4, minmax(0, 1fr))')};
  grid-auto-rows: 135px;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
`;

export const CardGridPosition = css`
  grid-column-start: ${({ columnStart }) => columnStart && columnStart};
  grid-column-end: ${({ size, columnStart, version }) =>
    columnStart && columnStart + (version === 2 ? CARD_SIZES_COLUMN_SIZES_V2[size] : CARD_SIZES_COLUMN_SIZES_V1[size])};
  grid-column-end: ${({ size, columnStart, version }) =>
    columnStart && columnStart + (version === 2 ? CARD_SIZES_COLUMN_SIZES_V2[size] : CARD_SIZES_COLUMN_SIZES_V1[size])};
  grid-row-start: ${({ rowStart }) => rowStart && rowStart};
  grid-row-end: ${({ size, rowStart, version }) =>
    rowStart && rowStart + (version === 2 ? CARD_SIZES_ROW_SIZES_V2[size] : CARD_SIZES_ROW_SIZES_V1[size])};
`;

export const CardContainer = styled.div`
  ${CardGridPosition};

  display: flex;
  flex-direction: column;
  position: relative;

  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'initial')};
  background: #fff;
  border: 1px solid var(--accentGraySecond);
  border-radius: 20px;

  ${EditMetricCardButton} {
    display: none;
  }

  &:hover {
    ${EditMetricCardButton} {
      display: flex;
    }

    border: 1px solid var(--neutralGray);
    box-shadow: 8px 8px 60px var(--primaryBlack2);
  }

  &:last-child {
    margin-right: 0;
  }
`;

export const ChartContainer = styled.div`
  position: relative;
  height: ${({ size }) => (size === CARD_SIZES.SMALL ? '90px' : size === CARD_SIZES.MEDIUM ? '136px' : '100%')};
  width: ${({ size, version }) => (size === CARD_SIZES.SMALL && version !== 2 ? '134px' : '100%')};
  padding: 0;
`;

export const SettingsButtonContainer = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 5;
`;
