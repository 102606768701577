import { assertCloseTo } from 'utils/assertionUtils';
import { getTransactionAmount } from 'views/Transactions/utils';

export const checkTotalTransactionAmount = ({ organization, user, data }) => {
  if (!data.length > 0) return;

  for (const { metricSpreads, totalTransactionAmount, customer_name, id } of data) {
    let transactionTotal = 0;
    for (const { recognition, transaction_amount, transaction_spread_sum } of metricSpreads) {
      transactionTotal += getTransactionAmount({
        recognition,
        transactionAmount: transaction_amount,
        spreadSum: transaction_spread_sum,
      });
    }
    const assertionPassed = assertCloseTo({
      organization,
      user,
      context: 'Revenue Details',
      expected: totalTransactionAmount,
      actual: transactionTotal,
      description: `Total transaction amount for ${customer_name} (${id})`,
    });

    if (!assertionPassed) return;
  }
};
