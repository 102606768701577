import styled from 'styled-components';
import { ReactComponent as Icon } from 'images/mail-send-icon.svg';

export const MailSendIcon = styled(Icon)`
  width: ${({ size }) => size ?? '16px'};
  height: ${({ size }) => size ?? '16px'};

  stroke: ${({ stroke }) => stroke};
  path {
    fill: ${({ fill }) => fill};
  }
`;
