import { setNestedObjectValues } from 'formik';

export const validateFormWithToast = async ({ formCurrent, pushToast }) => {
  if (formCurrent) {
    const errors = await formCurrent?.validateForm(formCurrent?.values);

    if (Object.keys(errors ?? {}).length) {
      const objectToString = (errors = {}) =>
        Object.entries(errors)
          .flatMap(([, value]) => {
            if (Array.isArray(value)) {
              return value.flatMap((v) => objectToString(v, ''));
            } else if (value && typeof value === 'object') {
              return objectToString(value, '');
            } else {
              return `${value}`;
            }
          })
          .filter(Boolean)
          .join(`\n`);

      pushToast(objectToString(errors), 'error');

      formCurrent?.setTouched?.(setNestedObjectValues(errors, true));

      return false;
    } else {
      return true;
    }
  }
};
