import React, { useContext, useState } from 'react';
import { AppContext } from 'AppContext';
import { useCustomersAPI } from 'api/customers';
import { useConfirmModal } from 'shared/ConfirmModal';
import { useToasts } from 'components/Toasts';

const INITIAL_MODAL_STATE = {
  integrationDisplayName: null,
  importId: null,
};

export const useRemoveImportConfirmModal = ({ setLoading, customerId }) => {
  const { orgId } = useContext(AppContext);
  const { pushError } = useToasts();
  const [modalState, setModalState] = useState(INITIAL_MODAL_STATE);
  const resetModalState = () => setModalState(INITIAL_MODAL_STATE);

  const {
    operations: { removeCustomerImport },
  } = useCustomersAPI({
    orgId,
    autoFetch: false,
  });

  const { openConfirmModal, ConfirmModal } = useConfirmModal({
    title: 'Delete customer association?',
    content: <div>Are you sure you want to delete this {modalState.integrationDisplayName} customer association?</div>,
    closeButtonText: 'No, cancel',
    confirmButtonText: 'Yes, delete',
    onConfirm: async () => {
      try {
        setLoading(true);
        await removeCustomerImport({
          id: customerId,
          importId: modalState.importId,
        });
      } catch (err) {
        pushError(err);
      } finally {
        resetModalState();
        setLoading(false);
      }
    },
    onClose: resetModalState,
  });

  return {
    RemoveImportConfirmModal: ConfirmModal,
    openRemoveImportConfirmModal: ({ integrationDisplayName, importId }) => {
      setModalState({
        integrationDisplayName,
        importId,
      });
      openConfirmModal();
    },
  };
};
