import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { isNil } from 'lodash';
import { AppContext } from 'AppContext';
import { useInvoiceAPI } from 'api/billing/hooks';
import { Centerer } from 'components/Core';
import { SizedLoader } from 'components/Loaders';
import { BILLING_PAGES_ROUTES, INVOICE_STATUSES } from '../consts';
import { useInvoicingScheduleModal } from '../InvoicingScheduleModal';

export const BillingSingleInvoice = () => {
  let history = useHistory();
  const { orgId } = useContext(AppContext);
  const { invoiceId } = useParams();
  const [shouldShowModal, setShouldShowModal] = useState(false);
  const invoiceRef = useRef(null);

  const navigateToInvoicesListPage = useRef(() => {
    history.push(BILLING_PAGES_ROUTES.INVOICES, { status: INVOICE_STATUSES.ALL });
  }).current;

  const { Modal: InvoicingScheduleModal, openModal: openInvoicingScheduleModal } = useInvoicingScheduleModal({
    onClose: navigateToInvoicesListPage,
  });

  // We only need to fetch the invoice once to verify its existence
  // This prevents unnecessary rerenders during background jobs (e.g., tax syncing)
  const [isFetched, setIsFetched] = useState(false);

  const { data: invoice, isLoading, error } = useInvoiceAPI({
    orgId,
    invoiceId: invoiceId,
    autoFetch: !!invoiceId && !isFetched,
  });

  useEffect(() => {
    if (!isNil(error)) {
      alert('Could not fetch invoice. Redirecting to invoices list.');
      navigateToInvoicesListPage();
      setIsFetched(true);
    } else if (!isLoading && invoice && invoice !== invoiceRef.current) {
      invoiceRef.current = invoice;
      setIsFetched(true);
      if (invoice.invoicing_schedule_id) {
        openInvoicingScheduleModal({
          invoicingSchedule: { id: invoice.invoicing_schedule_id },
          invoice: { id: invoice.id },
        });
        setShouldShowModal(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, error, invoice]);

  const modalComponent = useMemo(
    () =>
      isLoading && !invoiceRef.current ? (
        <Centerer width="100%" height="100%">
          <SizedLoader size={40} />
        </Centerer>
      ) : shouldShowModal ? (
        <InvoicingScheduleModal />
      ) : null,
    [isLoading, shouldShowModal],
  );

  return modalComponent;
};
