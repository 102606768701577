import styled from 'styled-components';
import { Centerer, CentererVertical, FlexBetweenContainer } from 'components/Core';
import { Link } from 'react-router-dom';

export const EmptyState = styled(Centerer)`
  flex-direction: column;
  height: 100%;
`;

export const EmptyStateCircle = styled(Centerer)`
  width: 48px;
  height: 48px;
  border: 1px solid var(--accentGraySecond);
  box-sizing: border-box;
  border-radius: 100px;
  margin-bottom: 24px;

  svg {
    transform: rotate(-180deg);
  }
`;

export const EmptyStateTitle = styled.div`
  font-weight: 900;
  font-size: 14px;
  line-height: 20px;
`;

export const EmptyStateDescription = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  max-width: 220px;

  padding-top: 8px;
`;

export const RevenueWrapper = styled(CentererVertical)`
  flex-direction: column;
  margin-top: 20px;

  span {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: var(--primaryBlack50);
  }

  div {
    margin-top: 8px;

    font-weight: 900;
    font-size: 16px;
    line-height: 22px;
  }
`;

export const CustomerPill = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;

  border: 1px solid var(--accentGraySecond);
  box-sizing: border-box;
  border-radius: 8px;
  margin: 12px 0;
  cursor: pointer;
  padding: 16px;

  div {
    font-weight: 900;
    font-size: 10px;
    line-height: 12px;
    text-transform: uppercase;
    color: var(--primaryBlue);
  }

  svg {
    margin-left: 5px;
  }
`;

export const TransactionsWrapper = styled(FlexBetweenContainer)`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const CurrentTransaction = styled.div`
  color: var(--primaryBlack30);
  margin: 0 8px;
`;

export const RelatedTransaction = styled(Link)`
  font-weight: 700;
  color: var(--primaryBlue);

  cursor: pointer;
  margin: 0 8px;

  &:hover {
    color: initial;
  }
`;
