import { useContext, useState } from 'react';
import styled from 'styled-components';
import { useFormikContext } from 'formik';

import { AppContext } from 'AppContext';
import { Centerer, FlexBetweenContainer, Flexer, FlexerColumn } from 'components/Core';
import { MAGIC_METADATA } from 'consts/global';
import { FormikCustomCheckbox } from 'components/Controls';
import { INTERNAL_CUSTOMER_METADATA, formatBillingAddress, formatShippingAddress } from 'models/customer';
import { Line } from 'views/Wizard/styles';
import { groupBy } from 'utils/arrayUtils';
import { INTEGRATION_SERVICES } from 'consts/integrations';
import { getIntegrationDisplayName, getServiceCategory } from 'models/integration';
import { TooltipContainer } from 'components/Tooltip';
import { HelpCircleIcon } from 'components/Icons';

import { ParentCustomerSelector } from './ParentCustomerSelector';
import { EditExternalParentModal } from './EditExternalParentModal';
import { ExternalSyncStatus } from '../ExternalSyncStatus';

const Liner = styled(Line)`
  margin-block: 10px;
`;

const Text = styled.div`
  font-size: ${({ fontSize }) => fontSize ?? '12px'};
  font-weight: ${({ fontWeight }) => fontWeight};
  color: ${({ color }) => color};
`;

const EditButton = styled(Centerer)`
  cursor: pointer;
  padding: 4px 8px;
  border-radius: 4px;
  background-color: var(--primaryBlue10);
  color: var(--primaryBlue);
  font-size: 12px;
  font-weight: 700;
`;

export const CustomerDetails = ({
  glIntegrations,
  isImported,
  isFetchingCustomer,
  addCustomer,
  handleBillWithParentChange,
  refetchCustomer,
  updateChildCustomer,
  activeCustomerImportByIntegrationId,
  handleExternalUpdate,
}) => {
  const { integrations } = useContext(AppContext);

  const { values } = useFormikContext();

  const [openEditExternalParentModal, setOpenEditExternalParentModal] = useState(false);

  const selectedGlIntegrationId = values.metadata?.[INTERNAL_CUSTOMER_METADATA.SELECTED_GL_INTEGRATION_ID];
  const glIntegrationById = groupBy(glIntegrations, 'id', { uniqueness: true });
  const selectedIntegration = glIntegrationById[selectedGlIntegrationId];
  const hubspotIntegration = integrations?.find(
    (integration) => getServiceCategory(integration.service) === INTEGRATION_SERVICES.HUBSPOT,
  );

  const hubspotImport = activeCustomerImportByIntegrationId[hubspotIntegration?.id] ?? null;
  const glImport = activeCustomerImportByIntegrationId[selectedGlIntegrationId] ?? null;

  const adjustedCustomerInvoicingDetails = values?.childInvoicingDetails ?? values?.invoicing_details;

  const isParent = values.id && values.hierarchy?.some((customer) => customer.parent_customer_id === values.id);

  const customerNameUsed = values?.metadata?.[MAGIC_METADATA.QUICKBOOKS_BILLWITHPARENT]
    ? values?.invoicing_details?.external_parent_customer_name ?? values.name
    : values.name;

  const formattedBillingAddress = formatBillingAddress({ customer: values });

  const shippingAddress = formatShippingAddress({ customer: values });
  const formattedShippingAddress =
    !shippingAddress || shippingAddress.trim() === formattedBillingAddress.trim()
      ? 'same as billing address'
      : shippingAddress;

  return (
    <FlexerColumn>
      {(isParent || [INTEGRATION_SERVICES.QUICKBOOKS].includes(getServiceCategory(selectedIntegration?.service))) && (
        <>
          {isParent && (
            <FormikCustomCheckbox
              name={`metadata.${MAGIC_METADATA.USE_CHILD_CUSTOMER_SHIPPING_INFO}`}
              data-cy="input-useChildShippingInfo"
              label={
                <Flexer alignItems="center" gap="4px">
                  Ship to child customer
                  <TooltipContainer
                    toolTipContent="We will use the related transaction's child customer's shipping information for invoices"
                    fontSize="12px"
                    width={280}
                  >
                    <HelpCircleIcon />
                  </TooltipContainer>
                </Flexer>
              }
              labelStyle={{
                whiteSpace: 'nowrap',
              }}
            />
          )}
          <ParentCustomerSelector
            glIntegrations={glIntegrations}
            isImported={isImported}
            handleBillWithParentChange={handleBillWithParentChange}
            isFetchingCustomer={isFetchingCustomer}
            addCustomer={addCustomer}
            isExternalParent
          />
          <Liner />
        </>
      )}

      <FlexerColumn gap="8px">
        <FlexBetweenContainer>
          <Text fontWeight={900} fontSize="14px">
            Address that will be used in Subscript
          </Text>

          {values?.metadata?.[MAGIC_METADATA.EXTERNAL_PARENT_CUSTOMER_ID] &&
            values?.metadata?.[MAGIC_METADATA.QUICKBOOKS_BILLWITHPARENT] && (
              <EditButton onClick={() => setOpenEditExternalParentModal(true)}>Edit parent's address</EditButton>
            )}
        </FlexBetweenContainer>

        <Flexer gap="4px">
          <Text fontWeight={700}>{customerNameUsed ?? 'Emails'}:</Text>
          <Text color="#0075FF">{values?.invoicing_details?.contacts?.join(', ')}</Text>
        </Flexer>

        <Flexer marginTop="8px">
          <FlexerColumn flexGrow={1}>
            <Text color="var(--primaryBlack50)">Billing</Text>
            <Text fontWeight={700}>{formattedBillingAddress}</Text>
          </FlexerColumn>

          <FlexerColumn flexGrow={1}>
            <Text color="var(--primaryBlack50)">Shipping</Text>
            <Text fontWeight={700}>{formattedShippingAddress}</Text>
          </FlexerColumn>
        </Flexer>
      </FlexerColumn>

      {(!!glImport || !!hubspotImport) && (
        <FlexerColumn gap="8px">
          <Liner />

          <Flexer gap="16px">
            {!!glImport && (
              <ExternalSyncStatus
                customerImport={glImport}
                serviceLabel={getIntegrationDisplayName(selectedIntegration)}
                currentInvoicingDetails={adjustedCustomerInvoicingDetails}
                handleExternalUpdate={handleExternalUpdate}
              />
            )}
            {!!hubspotImport && (
              <ExternalSyncStatus
                customerImport={hubspotImport}
                serviceLabel={getIntegrationDisplayName(hubspotIntegration)}
                currentInvoicingDetails={adjustedCustomerInvoicingDetails}
                statusLabel="consistent"
                handleExternalUpdate={handleExternalUpdate}
              />
            )}
          </Flexer>
        </FlexerColumn>
      )}

      {openEditExternalParentModal && (
        <EditExternalParentModal
          onClose={() => setOpenEditExternalParentModal(false)}
          customer={{
            name: values?.invoicing_details?.external_parent_customer_name,
            id: values?.metadata?.[MAGIC_METADATA.EXTERNAL_PARENT_CUSTOMER_ID],
            invoicing_details: values?.invoicing_details,
          }}
          selectedIntegration={glIntegrations?.find(
            (integration) =>
              integration.id === values.metadata?.[INTERNAL_CUSTOMER_METADATA.SELECTED_GL_INTEGRATION_ID],
          )}
          refetchCustomer={refetchCustomer}
          updateChildCustomer={updateChildCustomer}
        />
      )}
    </FlexerColumn>
  );
};
