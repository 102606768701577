import styled from 'styled-components';

export const TextBox = styled.span`
  color: ${({ color }) => color || 'var(--primaryBlack)'};
  opacity: ${({ opacity }) => opacity};
  font-size: ${({ fontSize }) => fontSize || '14px'};
  font-weight: ${({ fontWeight, bold }) => (bold ? '900' : fontWeight || '400')};
  line-height: ${({ lineHeight }) => lineHeight || '16px'};
  font-style: ${({ italic }) => (italic ? 'italic' : undefined)};
  text-align: ${({ textAlign }) => textAlign};
`;
