import React, { useEffect } from 'react';
import Select from 'react-select';
import styled from 'styled-components';
import { ReactComponent as TrashIcon } from 'images/trash-wizard-selector.svg';
import { IconControl, CustomSingleOption, IconSingleValue } from './Deals';
import { FieldBody, FieldCount, fieldSelectorStyles, FieldTitle, SelectedItem, SelectedItems } from '../styles';
import { Row } from 'components/Core';
import { Switch } from 'antd';

const CustomersWrapper = styled.div`
  padding: 0 100px;
  display: flex;
  flex-direction: column;
  align-items: center;

  opacity: ${({ disabled }) => disabled && '0.7'};
  pointer-events: ${({ disabled }) => disabled && 'none'};
`;

const CustomersSubtitle = styled.div`
  font-size: 14px;
  line-height: 20px;
  opacity: 0.5;
`;

const ProductsHeader = styled.div`
  color: var(--primaryBlack30);
  text-transform: uppercase;
  font-weight: 800;
  font-size: 10px;
  line-height: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
`;

const HeaderLine = styled.div`
  width: 770px;
  position: absolute;
  z-index: 1;
  height: 1px;
  background-color: var(--primaryBlack3);
  left: -28px;
  top: 7px;
`;

const HeaderCount = styled.div`
  margin-left: -3px;
  padding: 0 12px;
  background-color: white;
  position: relative;
  z-index: 5;
`;

const HeaderName = styled.div`
  margin-left: 25px;
  padding: 0 12px;
  background-color: white;
  position: relative;
  z-index: 5;
`;

const HeaderRevenue = styled.div`
  padding: 0 12px;
  background-color: white;
  position: relative;
  z-index: 5;
`;

const ProductRow = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ProductCount = styled.div`
  font-weight: 900;
  font-size: 10px;
  line-height: 14px;
  color: rgba(0, 21, 46, 0.5);
  padding: ${({ smallPadding }) => (smallPadding ? '5px 6px' : '5px 9px')};
  border-radius: 100px;
  border: 1px solid rgba(0, 21, 46, 0.05);
`;

const ProductName = styled.div`
  font-size: 14px;
  line-height: 20px;
  margin-left: 40px;
`;

const ProductToggle = styled.div`
  margin-right: 53px;
`;

const MainContent = styled.div`
  margin-top: 16px;
  font-size: 30px;
  line-height: 44px;
  max-width: 770px;
  text-align: center;
  margin-bottom: 40px;

  span {
    font-weight: 900;
    color: var(--primaryGreen);
  }
`;

const StyledSwitch = styled(Switch)`
  &.ant-switch {
    background-color: white;
    border: 1px solid rgba(0, 21, 46, 0.75);
  }

  .ant-switch-handle {
    top: 1px;
  }

  .ant-switch-handle::before {
    background-color: #d0d0d0;
    box-shadow: none;
  }

  &.ant-switch-checked {
    background-color: #1eb76e;

    .ant-switch-handle::before {
      background-color: white;
      box-shadow: none;
    }
  }
`;

export const CustomersAndProducts = ({
  configPageView,
  customerMetadataOptions,
  setCustomerMetadataOptions,
  nonRecurringProducts,
  setNonRecurringProducts,
  integrationObjectFields,
  allProducts,
  setReachedPage,
}) => {
  const crmFields = integrationObjectFields.CRM;

  useEffect(() => {
    setReachedPage(3);
  }, [setReachedPage]);

  return (
    <CustomersWrapper data-cy="wizard__customers-config" disabled={configPageView}>
      {!configPageView && (
        <>
          <CustomersSubtitle>You have successfully completed the setup!</CustomersSubtitle>
          <MainContent>
            Next, tell us about your <span>customers and products</span>
          </MainContent>
        </>
      )}

      {/* Customer metadata fields */}
      <FieldBody>
        {!configPageView && <FieldCount>1</FieldCount>}

        <FieldTitle>
          What are fields on the Account that it would help to slice and dice analysis by in Subscript?
        </FieldTitle>

        <Select
          menuPlacement="auto"
          isMulti
          onChange={(selectedField) => {
            setCustomerMetadataOptions([...new Set([...customerMetadataOptions, selectedField?.[0]?.value])]);
          }}
          value={null}
          inputId="wizard__customer-metadata-selector--input"
          menuPortalTarget={document.querySelector('body')}
          styles={fieldSelectorStyles}
          components={{ Control: IconControl, Option: CustomSingleOption, SingleValue: IconSingleValue }}
          placeholder="You can add multiple fields..."
          options={crmFields?.account?.allFields}
        />
        <SelectedItems>
          {customerMetadataOptions.map((option) => (
            <SelectedItem key={option}>
              {crmFields?.account?.allFields.find((field) => field.value === option)?.label ?? option}
              <TrashIcon
                onClick={() =>
                  setCustomerMetadataOptions(
                    customerMetadataOptions.filter((metadataOption) => metadataOption !== option),
                  )
                }
              />
            </SelectedItem>
          ))}
        </SelectedItems>
      </FieldBody>

      {/* Non recurring products */}

      {!configPageView && (
        <FieldBody>
          <FieldCount>2</FieldCount>
          <FieldTitle>
            <span>
              Which of these products are <b>one time, or non-recurring revenue?</b>
            </span>
          </FieldTitle>

          <ProductsHeader>
            <HeaderLine />
            <Row>
              <HeaderCount>#</HeaderCount>
              <HeaderName>Name</HeaderName>
            </Row>
            <HeaderRevenue>non-recurring revenue</HeaderRevenue>
          </ProductsHeader>

          {(allProducts ?? []).map((product, index) => (
            <ProductRow key={product.id}>
              <Row>
                <ProductCount smallPadding={index >= 9}>{index + 1}</ProductCount>
                <ProductName>{product.name}</ProductName>
              </Row>

              <ProductToggle>
                <StyledSwitch
                  data-cy={`wizard__product-${product.id}-checkbox`}
                  onClick={() => {
                    if (nonRecurringProducts.includes(product.id)) {
                      setNonRecurringProducts(
                        nonRecurringProducts.filter((nonRecurringProduct) => nonRecurringProduct !== product.id),
                      );
                    } else {
                      setNonRecurringProducts([...nonRecurringProducts, product.id]);
                    }
                  }}
                  checked={nonRecurringProducts.includes(product.id)}
                  size="small"
                />
              </ProductToggle>
            </ProductRow>
          ))}
        </FieldBody>
      )}
    </CustomersWrapper>
  );
};
