import React, { useCallback, useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';

import { ReactComponent as Icon } from 'images/search-alt.svg';
import { ReactComponent as ClearIcon } from 'images/circle-x.svg';
import { Spacer } from 'components/Core';

import { ClearButton, Container, DropdownSearchBarContainer, InputContainer } from './SearchBar.styles';

export const SearchBar = ({ name, value, placeholder, width, onChange, onBlur, onKeyPress, ...props }) => {
  const hasValue = !!value?.trim();
  const adjustedPlaceholder = useMemo(() => {
    const splitText = (placeholder ?? '').split(' ');

    if (['Search', 'search'].includes(splitText[0])) {
      return splitText.slice(1).join(' ');
    }
    return placeholder;
  }, [placeholder]);

  return (
    <Container>
      <Icon />
      <Spacer width="5px" />
      <InputContainer hasValue={hasValue} width={width || '170px'} {...props}>
        <b>Search</b>
        <Spacer width="4px" />
        <input
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          onKeyPress={onKeyPress}
          data-cy={name ? `search-bar__input--${name}` : 'search-input'}
          placeholder={adjustedPlaceholder || '...'}
        />
        <ClearButton
          hasValue={hasValue}
          onClick={() => {
            onChange({
              target: {
                value: '',
              },
            });
          }}
        >
          <ClearIcon />
        </ClearButton>
      </InputContainer>
    </Container>
  );
};

export const DropdownSearchBar = ({ name, value, placeholder, width, onChange, onBlur, onKeyPress, className }) => (
  <DropdownSearchBarContainer width={width || '170px'} className={className}>
    <Icon />
    <Spacer width="10px" />
    <input
      autoFocus
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      onKeyPress={onKeyPress}
      data-cy={name ? `search-bar__input--${name}` : 'search-input'}
      placeholder={placeholder || 'Search...'}
    />
  </DropdownSearchBarContainer>
);

SearchBar.propTypes = {
  placeholder: PropTypes.string,
  width: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyPress: PropTypes.func,
};

// Can be used for optimization of re-renders
export const UncontrolledSearchBar = ({ onSearchQueryChange, setCleanSearchBarFn, initialValue, ...props }) => {
  const [searchQuery, setSearchQuery] = useState(initialValue);

  useEffect(() => {
    setCleanSearchBarFn(() => () => {
      setSearchQuery(null);
    });

    setSearchQuery(initialValue);
  }, [initialValue, setSearchQuery, setCleanSearchBarFn]);

  const updateSearchQuery = (value) => {
    setSearchQuery(value);
    onSearchQueryChange(value);
  };

  return (
    <SearchBar
      value={searchQuery ?? ''}
      onChange={({ target }) => {
        updateSearchQuery(target.value);
      }}
      onBlur={(e) => updateSearchQuery(e.target.value)}
      onKeyPress={(e) => e.key === 'Enter' && e.target.blur()}
      {...props}
    />
  );
};

// On the decision to use 250ms as default, see:
// https://stackoverflow.com/questions/42361485/how-long-should-you-debounce-text-input
export const useDebouncedSearchBar = ({ onSearchQueryChange, initialValue, debounceTime = 250, ...props }) => {
  // Debounce triggers this warning, but we can ignore it
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedOnChange = useCallback(
    debounce((newSearchQuery) => {
      onSearchQueryChange(newSearchQuery);
    }, debounceTime),
    [onSearchQueryChange, debounceTime],
  );

  //since the search bar is uncontrolled,
  //in order to clear its value from the parent component we have to call a clear function from the child
  const [cleanSearchBarFn, setCleanSearchBarFn] = useState();

  const resetSearchQuery = () => {
    // We want to cancel in-flight debounces since we set the globalFilter
    // directly here, and a debounce could overwrite that
    debouncedOnChange.cancel();
    onSearchQueryChange(null);
    cleanSearchBarFn && cleanSearchBarFn?.();
  };

  const DebouncedSearchBar = useCallback(
    () => (
      <UncontrolledSearchBar
        setCleanSearchBarFn={setCleanSearchBarFn}
        onSearchQueryChange={debouncedOnChange}
        initialValue={initialValue}
        {...props}
      />
    ),
    // We don't want to create a new component when initialValue changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return {
    DebouncedSearchBar,
    resetSearchQuery,
  };
};
