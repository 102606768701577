import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { AppContext } from 'AppContext';
import styled from 'styled-components';
import { getInvoicingIntegrationService } from 'models/integration';
import { humanize } from 'utils/stringUtils';
import { useCustomerAPI } from 'api/customers';
import { searchResources } from 'api/billing/requests';
import {
  Modal,
  ModalBody,
  ModalButton,
  ModalCloseIcon,
  ModalContainer,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTitleText,
} from 'components/Modal';
import { FlexEndContainer, Flexer, FlexerColumn, Spacer } from 'components/Core';
import { Loader } from 'components/Loaders';
import { useToasts } from 'components/Toasts';
import { FormikCustomInput, FormikCustomRadio } from 'components/Controls';
import { AlertIcon } from 'components/Icons';
import {
  ERROR_IMPORT_ON_OTHER_CUSTOMER,
  useCustomerImports,
} from 'views/Customers/CustomersActionsModal/useCustomerImports';

const WarningIcon = styled.div`
  display: flex;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: var(--tertiaryRed);

  svg {
    width: 16px;
    height: 16px;

    path {
      fill: white;
    }
  }
`;

const ExistingGLCustomerPill = styled.div`
  width: fit-content;
  padding: 4px;
  border-radius: 4px;
  background-color: var(--primaryYellow15);
  color: var(--tertiaryYellow);
  font-size: 10px;
  font-weight: 900;
  line-height: 12px;
  text-transform: uppercase;
`;

const ACTION_OPTIONS = {
  CHANGE_NAME: 'change_name',
  CHANGE_IMPORT: 'change_import',
};

const DuplicateCustomerNameModal = ({ customer, onClose }) => {
  const { orgId, integrations } = useContext(AppContext);
  const { pushError } = useToasts();
  const [isLoading, setIsLoading] = useState(false);

  const { updateCustomerImport, ErrorUpdatingImportModal } = useCustomerImports();

  const {
    operations: { edit: updateCustomer },
  } = useCustomerAPI({ orgId, customerId: customer.id, enabled: false });

  const [existingExternalCustomer, setExistingExternalCustomer] = useState();
  const invoicingIntegrationService = getInvoicingIntegrationService({ integrations });
  const customerInvoicingImport = customer?.imports?.find((i) => i.provider_name === invoicingIntegrationService);

  useEffect(() => {
    const fetchExistingExternalCustomer = async () => {
      try {
        setIsLoading(true);
        const customers = await searchResources({
          orgId,
          params: {
            searchQuery: customer?.name,
            resource: 'customer',
            integrationId: customerInvoicingImport.integration_id,
          },
        });
        setExistingExternalCustomer(customers.find((c) => c.name === customer.name));
      } catch (err) {
        pushError(err, `Failed to fetch customer from ${humanize()}.`);
      } finally {
        setIsLoading(false);
      }
    };
    fetchExistingExternalCustomer();
  }, [orgId, customer?.name, setExistingExternalCustomer, pushError, customerInvoicingImport?.integration_id]);

  const handleSubmit = async (values) => {
    if (values.duplicate_customer_name_action === ACTION_OPTIONS.CHANGE_NAME) {
      try {
        setIsLoading(true);
        await updateCustomer({
          id: customer.id,
          data: {
            name: values?.duplicate_customer_update_name,
          },
        });
        onClose();
      } catch (err) {
        pushError(err, 'Failed to update customer name.');
      } finally {
        setIsLoading(false);
      }
    } else if (values.duplicate_customer_name_action === ACTION_OPTIONS.CHANGE_IMPORT) {
      try {
        setIsLoading(true);
        await updateCustomerImport({
          integrationId: customerInvoicingImport.integration_id,
          chifferObjectId: customer.id,
          providerObjectId: existingExternalCustomer.id,
          metadata: { customer_name: existingExternalCustomer.name },
        });
        onClose();
      } catch (err) {
        // We can ignore ERROR_IMPORT_ON_OTHER_CUSTOMER errors because they are dealt inside useCustomerImports hook
        if (err !== ERROR_IMPORT_ON_OTHER_CUSTOMER) {
          pushError(err, 'Failed to update customer import.');
        }
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      <ModalContainer>
        <Modal
          data-cy="duplicate-gl-customer-name-modal"
          height="auto"
          maxHeight="80vh"
          maxWidth="470px"
          overflow="auto"
        >
          <ModalHeader>
            <ModalCloseIcon onClose={onClose} />
            <ModalTitle>
              <Flexer gap="8px" alignItems="center">
                <WarningIcon>
                  <AlertIcon />
                </WarningIcon>
                <ModalTitleText>
                  <b>Data Conflict</b>
                </ModalTitleText>
              </Flexer>
            </ModalTitle>
          </ModalHeader>
          {isLoading ? (
            <div className="w-100 flexer">
              <Loader containerStyles={{ width: 40, margin: 20 }} />
            </div>
          ) : (
            <Formik
              initialValues={{
                duplicate_customer_name_action: ACTION_OPTIONS.CHANGE_NAME,
                duplicate_customer_update_name:
                  customerInvoicingImport?.metadata?.customer_name ?? `${customer?.name} new`,
              }}
              enableReinitialize={true}
              onSubmit={handleSubmit}
            >
              {({ values, handleSubmit }) => (
                <Form>
                  <ModalBody>
                    <div>
                      {humanize(invoicingIntegrationService)} can't{' '}
                      {!!customerInvoicingImport
                        ? "modify the mapped customer's name"
                        : 'create a customer with this name'}{' '}
                      because the name <b>"{customer.name}"</b> is already taken by another customer.
                    </div>
                    <div>What would you like to do?</div>

                    <Flexer>
                      <FormikCustomRadio
                        name="duplicate_customer_name_action"
                        suffix="duplicate_customer_name_action--radio"
                        gap="12px"
                        flexDirection="column"
                        options={[
                          {
                            label: (
                              <FlexerColumn gap="8px">
                                <div>Use a different name:</div>
                                <FormikCustomInput
                                  data-cy="duplicate-gl-customer-name-modal__duplicate_customer_update_name"
                                  name="duplicate_customer_update_name"
                                  width="330px"
                                  isDisabled={values.duplicate_customer_name_action !== ACTION_OPTIONS.CHANGE_NAME}
                                />
                              </FlexerColumn>
                            ),
                            value: ACTION_OPTIONS.CHANGE_NAME,
                          },
                          {
                            label: (
                              <FlexerColumn gap="8px">
                                <div>Remap this Subscript customer to the existing record:</div>
                                <div>
                                  <ExistingGLCustomerPill data-cy="duplicate-gl-customer-name-modal__existing-gl-customer-name">
                                    {existingExternalCustomer?.name} / {existingExternalCustomer?.id}
                                  </ExistingGLCustomerPill>
                                </div>
                              </FlexerColumn>
                            ),
                            value: ACTION_OPTIONS.CHANGE_IMPORT,
                          },
                        ]}
                      />
                    </Flexer>

                    <Spacer height="20px" />
                  </ModalBody>

                  <ModalFooter>
                    <FlexEndContainer>
                      <ModalButton onClick={onClose}>Cancel</ModalButton>
                      <Spacer width="10px" />
                      <ModalButton data-cy="duplicate-gl-customer-name-modal__save" primary onClick={handleSubmit}>
                        Save
                      </ModalButton>
                    </FlexEndContainer>
                  </ModalFooter>
                </Form>
              )}
            </Formik>
          )}
        </Modal>
      </ModalContainer>
      <ErrorUpdatingImportModal />
    </>
  );
};

export const useDuplicateCustomerNameModal = () => {
  const [showModal, setShowModal] = useState(false);
  const [customer, setCustomer] = useState();

  const openModal = useCallback(
    ({ customer }) => {
      setCustomer(customer);
      setShowModal(true);
    },
    [setCustomer, setShowModal],
  );

  const closeModal = useCallback(() => {
    setShowModal(false);
    setCustomer(null);
  }, [setShowModal]);

  const Modal = useCallback(
    () => (showModal ? <DuplicateCustomerNameModal customer={customer} onClose={closeModal} /> : null),
    [showModal, closeModal, customer],
  );

  return {
    openModal,
    Modal,
    isModalOpen: showModal,
  };
};
