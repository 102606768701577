import styled from 'styled-components';
import { CentererVertical } from 'components/Core';

export const DropdownInfo = styled(CentererVertical)`
  padding: 12px 12px 0 12px;

  span {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: var(--primaryDark);
    opacity: 0.5;
    margin-left: 8px;
  }

  svg {
    width: 16px;
    height: 16px;
  }
`;
