import React from 'react';
import styled from 'styled-components';
import { useClickOutside } from 'utils/hooks';
import { Modal, ModalBody, ModalCloseIcon, ModalContainer, ModalHeader } from 'components/Modal';
import { FlexerColumn } from 'components/Core';

const BodyContent = styled(FlexerColumn)`
  padding: 32px 36px;
`;

const Title = styled.div`
  font-weight: 900;
  font-size: 20px;
  margin-bottom: 20px;
  line-height: 26px;
`;

export const ScheduleGenerationInfoModal = ({ hideModal, generatedInvoicesExplanations }) => {
  const modalRef = useClickOutside(() => hideModal());

  return (
    <ModalContainer>
      <Modal padding="0" ref={modalRef} maxWidth="740px" height="auto" overflow="hidden">
        <ModalHeader>
          <ModalCloseIcon
            data-cy="schedule-generation-info-modal__close-button"
            style={{ width: 32, height: 32 }}
            onClose={() => hideModal()}
          />
        </ModalHeader>
        <ModalBody paddingLeft="0" paddingRight="0">
          <BodyContent>
            <Title>How was this schedule generated?</Title>
            <FlexerColumn gap="8px">
              {generatedInvoicesExplanations.map((explanation) => (
                <span key={explanation}>{`- ${explanation}`}</span>
              ))}
            </FlexerColumn>
          </BodyContent>
        </ModalBody>
      </Modal>
    </ModalContainer>
  );
};
