import React, { useContext } from 'react';
import { Form, Formik } from 'formik';
import { AppContext } from 'AppContext';
import { FlexBetweenContainer, Spacer } from 'components/Core';
import { NUMBER_FORMATS } from 'consts/global';
import { FORECAST_DETAILS_FIELD } from '../consts';
import { CustomizableMetric } from '../Shared/CustomizableMetric';
import { CustomerNameCell } from '../Shared/CustomerNameCell';
import { SectionItemCard } from './styles';

export const NewCustomersCard = ({ editForecastDetails, forecastId, month, values, onCustomerClick, isDragging }) => {
  const {
    appSettings: { isARR },
  } = useContext(AppContext);
  const handleSubmit = (values) => {
    editForecastDetails({
      forecastId,
      forecastMonth: month,
      forecastType: FORECAST_DETAILS_FIELD.NEW,
      customerId: values.customer_id,
      data: {
        recurring_amount: values.recurring_amount / (isARR ? 12 : 1),
        forecast_month: values.forecast_month,
      },
    });
  };

  return (
    <Formik
      initialValues={{
        customer_id: values.customer_id,
        recurring_amount: values.recurring_amount,
        forecast_month: values.forecast_month,
      }}
      onSubmit={handleSubmit}
      enableReinitialize={true}
    >
      <Form>
        <SectionItemCard isDragging={isDragging}>
          <CustomerNameCell
            externalServiceName={values.external_service_name}
            externalUrl={values.external_url}
            onCustomerClick={() => onCustomerClick(values.customer_id)}
          >
            {values.customer_name}
          </CustomerNameCell>

          <Spacer height="12px" />

          <FlexBetweenContainer centerer>
            <CustomizableMetric
              name="forecast_month"
              dataType="calendar"
              value={values.forecast_month}
              explanation="Thus deal is expected to close on this date"
              missingExplanation="This deal is missing a close date"
              isSecondary={values.forecast_month === null}
            />

            <CustomizableMetric
              name="recurring_amount"
              dataType={NUMBER_FORMATS.CURRENCY}
              value={values.recurring_amount ?? 0}
              isSecondary={values.forecast_month === null}
            />
          </FlexBetweenContainer>
        </SectionItemCard>
      </Form>
    </Formik>
  );
};
