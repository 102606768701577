import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { ReactComponent as ArrowUp } from 'images/arrow_up_bold.svg';
import { ReactComponent as ArrowRight } from 'images/bx-right-arrow-alt.svg';
import { ReactComponent as BellIcon } from 'images/bell-ringing-yellow.svg';
import { NUMBER_FORMATS } from 'consts/global';
import { Spacer } from 'components/Core';
import {
  RowActionPopover,
  StyledTableName,
  StyledTableNameTitle,
  TableActionCell,
  TableSubRowsCount,
  TableSubRowsCountArrow,
} from 'components/Table';
import { TooltipContainer, TruncateStringWithTooltip } from 'components/Tooltip';
import { numberFormatter } from 'utils/formatters';
import { getCustomerDisplayName } from 'models/customer';
import { PercentDice, DatesCell, ArrCell, RelationLabel } from '../styles';
import { TABS as CUSTOMER_TABLE_TABS } from '../CustomerSingle/CustomerTablesTabs';
import { CUSTOMERS_MODAL_ACTIONS } from '../CustomersActionsModal';
import { getPercentDifference } from './utils';

const TextWrapper = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: ${({ width }) => width};
`;

const getAddress = ({ addressLine1, addressLine2, country, city, state, postalcode }) =>
  [addressLine1, addressLine2, city, `${state?.trim() || ''} ${postalcode?.trim() || ''}`, country]
    .filter((text) => !!(text ?? '').trim())
    .join(', ');

export const generateCustomersListColumns = ({
  isARR,
  currency,
  setDataForModal,
  setModalAction,
  setShowCustomersActionModal,
  setActiveCustomerSingleTab,
  dateFormat,
  rollup,
}) => [
  {
    Header: 'Customer',
    accessor: 'name',
    width: 800,
    Cell: ({ row, cell: { value } }) => {
      return (
        <StyledTableName>
          {rollup && row.original.parent_customer_id && <RelationLabel>Child</RelationLabel>}
          <Link to={`/customers/${row.original.id}`}>
            <StyledTableNameTitle
              wrapText={true}
              data-cy={`customers-list__customer-${value}`}
              color="var(--primaryBlue)"
            >
              <TruncateStringWithTooltip length={300} tooltipWidth={600}>
                {getCustomerDisplayName({
                  customerName: value,
                  customerId: row.original.id,
                  customerEmail: row.original.email,
                })}
              </TruncateStringWithTooltip>
            </StyledTableNameTitle>
          </Link>
          <Spacer width="8px" />
          {row.canExpand && (
            <TableSubRowsCount {...row.getToggleRowExpandedProps()} isHidden={!row.isExpanded}>
              {row.subRows.length}
              <TableSubRowsCountArrow isHidden={!row.isExpanded}>
                <ArrowUp />
              </TableSubRowsCountArrow>
            </TableSubRowsCount>
          )}
        </StyledTableName>
      );
    },
  },
  {
    Header: () => <>{isARR ? 'ARR' : 'MRR'}</>,
    accessor: 'currentMRR',
    width: 200,
    alignRight: true,
    disableSortBy: true,
    Cell: ({ cell: { value }, row }) => {
      const percentChange = getPercentDifference({
        previousMRR: row.original.previousMRR,
        currentMRR: value,
      });
      const previous = isARR ? row.original.previousMRR * 12 : row.original.previousMRR;
      const current = isARR ? value * 12 : value;

      return (
        (!rollup || !row.original.parent_customer_id) && (
          <ArrCell>
            <TooltipContainer
              width={83}
              toolTipContent={`${dayjs(row.original.start_date).format('YYYY-MM')} ${isARR ? 'ARR' : 'MRR'}`}
            >
              <span data-cy={'customer--previousMRR'}>
                {numberFormatter({ type: NUMBER_FORMATS.CURRENCY, rawValue: previous, currency })}
              </span>
            </TooltipContainer>

            <ArrowRight />
            <span data-cy={'customer--currentMRR'}>
              {numberFormatter({ type: NUMBER_FORMATS.CURRENCY, rawValue: current, currency })}
            </span>
            {Boolean(percentChange) && (
              <PercentDice isPositive={percentChange > 0}>
                {percentChange > 0 ? `+${percentChange}` : percentChange}%
              </PercentDice>
            )}
          </ArrCell>
        )
      );
    },
  },
  {
    Header: () => (
      <>
        <TooltipContainer width={200} isVisible={true} underline={false} toolTipContent={'Total Non-Recurring Revenue'}>
          Non-RR
        </TooltipContainer>
      </>
    ),
    alignRight: true,
    accessor: 'nonRecurringRevenue',
    width: 140,
    disableSortBy: true,
    Cell: ({ cell: { value }, row }) =>
      (!rollup || !row.original.parent_customer_id) && (
        <div style={{ width: '100%', textAlign: 'right' }}>
          {numberFormatter({ type: NUMBER_FORMATS.CURRENCY, rawValue: value, currency })}
        </div>
      ),
  },
  {
    Header: () => (
      <>
        <TooltipContainer
          width={150}
          isVisible={true}
          underline={false}
          toolTipContent={'Total Recurring + Non-Recurring Revenue'}
        >
          Total Rev.
        </TooltipContainer>
      </>
    ),
    alignRight: true,
    accessor: 'transactionSum',
    width: 140,
    disableSortBy: true,
    Cell: ({ cell: { value }, row }) =>
      (!rollup || !row.original.parent_customer_id) && (
        <div style={{ width: '100%', textAlign: 'right' }}>
          {numberFormatter({ type: NUMBER_FORMATS.CURRENCY, rawValue: value, currency })}
        </div>
      ),
  },
  {
    Header: 'Dates',
    accessor: 'start_date',
    width: 220,
    disableSortBy: true,
    Cell: ({ cell: { value }, row }) => {
      const isEndDateInPast = dayjs().isAfter(dayjs.utc(row.original.end_date));
      const toolTipContent = isEndDateInPast
        ? "This customer's latest transaction has ended"
        : 'This customer has current or future transactions';
      return (
        (!rollup || !row.original.parent_customer_id) && (
          <TooltipContainer width={200} isVisible={true} underline={false} toolTipContent={toolTipContent}>
            <DatesCell className="dateCell" isDatePast={isEndDateInPast}>
              <span style={{ marginRight: 4 }}>{value ? dayjs.utc(value).format(dateFormat) : 'No start date'} - </span>
              {row.original.end_date ? dayjs.utc(row.original.end_date).format(dateFormat) : 'No end date'}
              {isEndDateInPast && <BellIcon />}
            </DatesCell>
          </TooltipContainer>
        )
      );
    },
  },
  {
    Header: 'External Id',
    accessor: 'external_ids',
    width: 110,
    disableSortBy: true,
    Cell: ({ cell: { value }, row }) => {
      return (
        (!rollup || !row.original.parent_customer_id) && (
          <div style={{ width: '100%', textAlign: 'right' }}>{value?.filter((v) => v).join(', ')}</div>
        )
      );
    },
  },
  {
    Header: 'Billing',
    accessor: 'invoices',
    width: 100,
    disableSortBy: true,
    Cell: ({ row, cell: { value } }) => {
      const invoiceCount = value?.length ? value?.length : 0;
      return (
        (!rollup || !row.original.parent_customer_id) && (
          <Link
            to={`/customers/${row.original.id}`}
            onClick={() => setActiveCustomerSingleTab(CUSTOMER_TABLE_TABS.INVOICES.title)}
          >
            <div
              style={{ width: '100%', textAlign: 'left', color: 'var(--primaryBlue)', cursor: 'pointer' }}
            >{`${invoiceCount} invoices`}</div>
          </Link>
        )
      );
    },
  },
  {
    Header: 'Contacts',
    accessor: 'contacts',
    width: 150,
    disableSortBy: true,
    Cell: ({ row }) => {
      const invoicingDetails = row.original.invoicing_details;
      const contactsStr = invoicingDetails?.contacts?.join(', ');
      return contactsStr ? (
        <TooltipContainer toolTipContent={contactsStr} fontSize="12px" width={150}>
          <TextWrapper width="150px">{contactsStr}</TextWrapper>
        </TooltipContainer>
      ) : null;
    },
  },
  {
    Header: 'Billing Address',
    accessor: 'billing_address',
    width: 200,
    disableSortBy: true,
    Cell: ({ row }) => {
      const invoicingDetails = row.original.invoicing_details;
      const address = getAddress(invoicingDetails ?? {});
      return address ? (
        <TooltipContainer toolTipContent={address} fontSize="12px" width={200}>
          <TextWrapper width="200px">{address}</TextWrapper>
        </TooltipContainer>
      ) : null;
    },
  },
  {
    Header: 'Shipping Address',
    accessor: 'shipping_address',
    width: 200,
    disableSortBy: true,
    Cell: ({ row }) => {
      const invoicingDetails = row.original.invoicing_details;
      const address = getAddress(invoicingDetails?.shipping_address ?? invoicingDetails ?? {});
      return address ? (
        <TooltipContainer toolTipContent={address} fontSize="12px" width={200}>
          <TextWrapper width="200px">{address}</TextWrapper>
        </TooltipContainer>
      ) : null;
    },
  },
  {
    accessor: 'actions',
    disableSortBy: true,
    width: 50,
    Cell: ({ row }) => (
      <TableActionCell>
        <RowActionPopover
          currentSelection={row.original}
          onEditClick={() => {
            setModalAction(CUSTOMERS_MODAL_ACTIONS.EDIT);
            setDataForModal(row.original);
            setShowCustomersActionModal(true);
          }}
          onDeleteClick={() => {
            setModalAction(CUSTOMERS_MODAL_ACTIONS.DELETE);
            setDataForModal(row.original);
            setShowCustomersActionModal(true);
          }}
          dataCyPrefix="customer"
        />
      </TableActionCell>
    ),
  },
];
