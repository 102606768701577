import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { replaceUrlParam } from 'utils/urlUtils';

import { SubTabs, SubTabsHeaderContainer, SubText } from './styles';

export const BILLING_SUB_TABS = {
  GENERAL_SETTINGS: 'billing-general-settings',
  INVOICE_SETTINGS: 'billing-invoice-settings',
  COLLECTION_SEQUENCE: 'billing-collection-sequence',
};

const billingTabs = [
  {
    label: 'General Settings',
    subText: 'Default Terms',
    value: BILLING_SUB_TABS.GENERAL_SETTINGS,
  },
  {
    label: 'Invoice Settings',
    subText: 'PDF & Emails',
    value: BILLING_SUB_TABS.INVOICE_SETTINGS,
  },
  {
    label: 'Collection Sequence',
    subText: 'Invoice Reminder System',
    value: BILLING_SUB_TABS.COLLECTION_SEQUENCE,
  },
];

export const SubTabsHeader = ({ currentSubTab, setCurrentSubTab, defaultTab = BILLING_SUB_TABS.GENERAL_SETTINGS }) => {
  const history = useHistory();

  useEffect(() => {
    // detect for newly called urls
    const unsubscribe = history.listen((location) => {
      const query = new URLSearchParams(location.search);
      setCurrentSubTab(query.get('subTab'));
    });

    return unsubscribe;
  }, [history, setCurrentSubTab]);

  return (
    <SubTabsHeaderContainer>
      {billingTabs.map((tab) => (
        <SubTabs
          key={tab.value}
          selected={(currentSubTab ?? defaultTab) === tab.value}
          onClick={() => {
            // this change will not be detected by the listener
            replaceUrlParam({
              subTab: tab.value,
            });
            setCurrentSubTab(tab.value);
          }}
          data-cy={`configuration__sub-tab-header-${tab.value}`}
        >
          <div>{tab.label}</div>
          <SubText>{tab.subText}</SubText>
        </SubTabs>
      ))}
    </SubTabsHeaderContainer>
  );
};
