import React, { useContext } from 'react';
import styled from 'styled-components';
import { Bar, defaults } from 'react-chartjs-2';
import { cssVar, transparentize } from 'polished';
import { AppContext } from 'AppContext';
import { reFormatDate } from 'utils/dateUtils';
import { numberFormatter } from 'utils/formatters';
import { NUMBER_FORMATS } from 'consts/global';
import { ReactComponent as FillDot } from 'images/fill-dot.svg';

const LegendContainer = styled.div`
  width: 100%;
  min-height: 38px;
  display: flex;
  background: white;
  border: 1px solid var(--accentGraySecond);
  box-shadow: 8px 8px 60px var(--primaryBlack4);
  border-radius: 8px;
  margin-top: 24px;
`;

const LegendLabel = styled.div`
  padding: 12px 0px 12px 16px;
  color: var(--primaryBlack30);
  font-weight: 800;
  font-size: 10px;
  line-height: 14px;
  text-transform: uppercase;
  white-space: nowrap;
`;

const KeysContainer = styled.div`
  width: 95%;
  display: flex;
  font-weight: 900;
  font-size: 10px;
  line-height: 14px;
  text-transform: uppercase;
  vertical-align: sub;
  padding: 12px 14px;
`;

const LegendIcon = styled.div`
  margin-right: 22px;

  span {
    margin-left: 6px;
  }
`;

const ChartLegendContainer = styled.div`
  width: 100%;
`;

const ChartContainer = styled.div`
  margin-top: 48px;
  width: 100%;
`;

export const AccountingRevenueChart = ({ data }) => {
  defaults.global.defaultFontFamily = 'Nunito Sans';

  const {
    orgConfigs: { accountingCurrency },
  } = useContext(AppContext);

  const firstGL = Object.keys(data?.accountingRevenueByMonth)[0];

  const labels = Object.keys(data?.accountingRevenueByMonth?.[firstGL] ?? {}).map((monthKey) =>
    reFormatDate(monthKey, 'YYYY-MM', 'MMM YY'),
  );

  const REVENUE_TYPE_DETAILS = {
    DEFERRED: {
      label: 'Deferred Revenue',
      backgroundColor: cssVar('--primaryGreen50'),
      borderColor: cssVar('--primaryGreen'),
      data: Object.values(data?.deferredRevenueByMonth?.[firstGL] ?? {}),
    },
    ACCRUED: {
      label: 'Accrued Revenue',
      backgroundColor: cssVar('--primaryBlue50'),
      borderColor: cssVar('--primaryBlue'),
      data: Object.values(data?.accruedRevenueByMonth?.[firstGL] ?? {}),
    },
    ACCOUNTING: {
      label: 'Accounting Revenue',
      backgroundColor: cssVar('--primaryYellow50'),
      borderColor: cssVar('--primaryYellow'),
      data: Object.values(data?.accountingRevenueByMonth?.[firstGL] ?? {}),
    },
  };

  const datasets = Object.values(REVENUE_TYPE_DETAILS).map((fields) => ({
    type: 'bar',
    barThickness: 14,
    borderWidth: 1,
    borderRadius: 4,
    ...fields,
  }));

  const options = {
    responsive: true,
    legend: {
      display: false,
    },
    tooltips: {
      mode: 'index',
      callbacks: {
        label: function (tooltipItem, data) {
          const label = data.datasets[tooltipItem.datasetIndex].label;
          const amount = numberFormatter({
            type: NUMBER_FORMATS.CURRENCY,
            rawValue: tooltipItem.yLabel,
            currency: accountingCurrency,
          });
          return `${label}: ${amount}`;
        },
      },
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
          },
          ticks: {
            padding: 10,
            fontStyle: 'bold',
            fontColor: transparentize(0.8, cssVar('--primaryBlack')),
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            drawBorder: false,
          },
          ticks: {
            padding: 10,
            fontStyle: 'bold',
            fontColor: transparentize(0.6, cssVar('--primaryBlack')),
            maxTicksLimit: 7,
            beginAtZero: true,
            callback: function (value) {
              return `${value.toString().replace(/(0{3}$)/g, 'k')}`;
            },
          },
        },
      ],
    },
  };

  return (
    <ChartLegendContainer>
      <ChartContainer>
        <Bar
          height={55}
          data={{
            labels,
            datasets,
          }}
          options={options}
        />
      </ChartContainer>

      <LegendContainer>
        <LegendLabel>CHART:</LegendLabel>
        <KeysContainer>
          {Object.values(REVENUE_TYPE_DETAILS).map(({ borderColor, label }) => (
            <LegendIcon key={label}>
              <FillDot fill={borderColor} />
              <span>{label.toUpperCase()}</span>
            </LegendIcon>
          ))}
        </KeysContainer>
      </LegendContainer>
    </ChartLegendContainer>
  );
};
