import { createContext } from 'react';

export const ReportBuilderContext = createContext({
  report: null,
  setReport: (report) => console.error('Unimplementd setReport function in ReportBuilderContext', report),
  pageReportState: null,
  onAddWidget: ({ key, addInRowIndex }) =>
    console.error('Unimplementd onAddWidget function in ReportBuilderContext', key, addInRowIndex),
  onEditWidget: ({ metricIndex, metric }) =>
    console.error('Unimplementd onEditWidget function in ReportBuilderContext', metricIndex, metric),
  onDeleteWidget: ({ metricIndex, metric }) =>
    console.error('Unimplementd onDeleteWidget function in ReportBuilderContext', metricIndex, metric),
  onEditCollapseRows: (collapseRows) =>
    console.error('Unimplementd onEditCollapseRows function in ReportBuilderContext', collapseRows),
  exportScreenshotRef: null,
  ExportButton: null,
});
