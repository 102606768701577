import { createFilter } from 'react-select';
import { SEARCH_CONFIDENCE } from 'utils/stringUtils';
import { RECOGNITION_TYPES } from 'consts/global';
import { FormikCustomSelector } from 'components/Controls';
import { INTERNAL_CUSTOMER_ID_SOURCE, NAME_MATCHING_SOURCE, SOURCE_TYPES } from './consts';
import { CustomSingleOption } from './columns';
import { getFuzzyCellData } from 'shared/CsvUpload/CsvTable/getFuzzyCellData';

const getCustomerNameById = ({ id, customersById }) => {
  const customer = customersById?.[id];
  return customer ? (customer.customer_name ?? customer.name) || `No name / ${id}` : id;
};

export const renderCustomerIdCell = ({
  row,
  cell: { value },
  customerIdSourceAndType,
  customers,
  customersSelectOptions,
  customersById,
  formCurrent,
  fuzzyRowsMapper,
  setFuzzyRowsMapper,
  uploadCsvCustomersIdSource,
  key = 'customer_id',
}) => {
  const index = row.original.rowIndex;

  const displayedValue = value
    ? {
        value,
        label: [NAME_MATCHING_SOURCE, INTERNAL_CUSTOMER_ID_SOURCE].includes(customerIdSourceAndType?.source)
          ? getCustomerNameById({ id: value, customersById })
          : value,
      }
    : null;

  if (customerIdSourceAndType?.source === NAME_MATCHING_SOURCE) {
    if (value && formCurrent && !fuzzyRowsMapper?.[index]?.[key]?.fuzzySearchCompleted && customers) {
      const {
        candidates: candidatesForMapper,
        fuzzyTooltip: fuzzyTooltipForMapper,
        confidence: confidenceForMapper,
        value: fuzzyValue,
      } = getFuzzyCellData({
        rowValue: value,
        dataset: customers,
        candidateValueKey: 'id',
        candidateTitleKey: 'name',
        columns: ['name'],
      });

      setFuzzyRowsMapper((prevMapper) => ({
        ...prevMapper,
        [index]: {
          ...(prevMapper?.[index] ?? {}),
          [key]: {
            fuzzySearchCompleted: true,
            fuzzyTooltip: fuzzyTooltipForMapper,
            candidates: candidatesForMapper,
            confidence: confidenceForMapper,
          },
        },
      }));

      formCurrent?.setFieldValue(`[${index}].[${key}]`, fuzzyValue);
    }
  }

  const { fuzzyTooltip, confidence, candidates } = fuzzyRowsMapper?.[index]?.[key] ?? {};

  const sortedByRelevance = customerIdSourceAndType?.source === NAME_MATCHING_SOURCE && candidates?.length > 0;

  const options = sortedByRelevance
    ? candidates?.map((customer) => ({
        value: [NAME_MATCHING_SOURCE, INTERNAL_CUSTOMER_ID_SOURCE].includes(customerIdSourceAndType?.source)
          ? customer.id
          : customerIdSourceAndType?.type === SOURCE_TYPES.INTERNAL
          ? customer?.metadata?.[uploadCsvCustomersIdSource]
          : customer.provider_object_id,
        label:
          customer.customer_name ??
          customer.name ??
          `No name / ${
            customerIdSourceAndType?.source === NAME_MATCHING_SOURCE
              ? customer.id
              : customerIdSourceAndType?.type === SOURCE_TYPES.INTERNAL
              ? customer.metadata?.[uploadCsvCustomersIdSource]
              : customer.provider_object_id
          }`,
      }))
    : customersSelectOptions;

  return (
    <FormikCustomSelector
      height={32}
      components={{ Option: CustomSingleOption }}
      placeholder="Select id..."
      minWidth={160}
      menuWidth={200}
      filterOption={createFilter({ ignoreAccents: false })} // optimization
      errorWithoutTooltip
      value={displayedValue}
      options={options}
      name={`[${index}].${key}`}
      hasWarning={confidence === SEARCH_CONFIDENCE.FUZZY}
      tooltipInputDisplay={customerIdSourceAndType?.source === NAME_MATCHING_SOURCE ? fuzzyTooltip : undefined}
      noSort
    />
  );
};

export const renderProductIdCell = ({
  row,
  cell: { value },
  organization,
  formCurrent,
  fuzzyRowsMapper,

  setFuzzyRowsMapper,
}) => {
  const index = row.original.rowIndex;

  if (value && formCurrent && !fuzzyRowsMapper?.[index]?.product_id?.fuzzySearchCompleted) {
    const {
      candidates: candidatesForMapper,
      fuzzyTooltip: fuzzyTooltipForMapper,
      confidence: confidenceForMapper,
      value: fuzzyValue,
    } = getFuzzyCellData({
      rowValue: value,
      dataset: organization?.products,
      candidateValueKey: 'id',
      candidateTitleKey: 'name',
      columns: ['name', 'id'],
    });

    setFuzzyRowsMapper((prevMapper) => ({
      ...prevMapper,
      [index]: {
        ...(prevMapper?.[index] ?? {}),
        product_id: {
          fuzzySearchCompleted: true,
          fuzzyTooltip: fuzzyTooltipForMapper,
          candidates: candidatesForMapper,
          confidence: confidenceForMapper,
        },
      },
    }));

    formCurrent?.setFieldValue(`[${index}].product_id`, fuzzyValue);
  }

  const { fuzzyTooltip, confidence } = fuzzyRowsMapper?.[index]?.product_id ?? {};

  return (
    <FormikCustomSelector
      height={32}
      minWidth={160}
      menuWidth={200}
      //optimization thing
      filterOption={createFilter({ ignoreAccents: false })}
      errorWithoutTooltip
      isClearable={true}
      placeholder="Select product..."
      name={`[${row.original.rowIndex}].product_id`}
      hasWarning={confidence === SEARCH_CONFIDENCE.FUZZY}
      tooltipInputDisplay={fuzzyTooltip}
      handleChange={(option) => {
        if (option) {
          formCurrent?.setFieldValue(`[${row.original.rowIndex}].product_id`, option.value);

          //fill recognition from product if no recognition
          if (option.recognition && !row.values.recognition) {
            formCurrent?.setFieldValue(`[${row.original.rowIndex}].recognition`, option.recognition);
          }
        } else {
          formCurrent?.setFieldValue(`[${row.original.rowIndex}].product_id`, null);
        }
      }}
      value={
        value
          ? {
              label: organization?.products.find((product) => product.id === value)?.name ?? value,
              value: value,
            }
          : null
      }
      options={
        organization?.products?.map((productObject) => ({
          label: productObject.name ?? `No name / ${productObject.id}`,
          value: productObject.id,
          recognition: productObject.recognition,
        })) ?? []
      }
    />
  );
};

export const renderRecognitionCell = ({ row, cell: { value }, formCurrent, fuzzyRowsMapper, setFuzzyRowsMapper }) => {
  const index = row.original.rowIndex;

  const recognitions = Object.values(RECOGNITION_TYPES).map((recognition) => ({
    value: recognition,
    label: recognition,
  }));

  if (value && formCurrent && !fuzzyRowsMapper?.[index]?.recognition?.fuzzySearchCompleted) {
    const {
      candidates: candidatesForMapper,
      fuzzyTooltip: fuzzyTooltipForMapper,
      confidence: confidenceForMapper,
      value: fuzzyValue,
    } = getFuzzyCellData({
      rowValue: value,
      dataset: recognitions,
      candidateValueKey: 'value',
      candidateTitleKey: 'label',
      columns: ['value'],
    });

    setFuzzyRowsMapper((prevMapper) => ({
      ...prevMapper,
      [index]: {
        ...(prevMapper?.[index] ?? {}),
        recognition: {
          fuzzySearchCompleted: true,
          fuzzyTooltip: fuzzyTooltipForMapper,
          candidates: candidatesForMapper,
          confidence: confidenceForMapper,
        },
      },
    }));

    formCurrent?.setFieldValue(`[${index}].recognition`, fuzzyValue);
  }

  const { fuzzyTooltip, confidence } = fuzzyRowsMapper?.[index]?.recognition ?? {};

  return (
    <FormikCustomSelector
      height={32}
      placeholder="Select..."
      minWidth={160}
      menuWidth={200}
      name={`[${row.original.rowIndex}].recognition`}
      isClearable={true}
      //optimization thing
      errorWithoutTooltip
      options={recognitions}
      hasWarning={confidence === SEARCH_CONFIDENCE.FUZZY}
      tooltipInputDisplay={fuzzyTooltip}
      handleChange={(option) => {
        if (option) {
          formCurrent?.setFieldValue(`[${row.original.rowIndex}].recognition`, option.value);
        } else {
          formCurrent?.setFieldValue(`[${row.original.rowIndex}].recognition`, null);
        }
      }}
    />
  );
};
