import styled from 'styled-components';

import { Spacer } from 'components/Core';
import { AddIcon } from 'components/Icons';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  font-weight: 900;
  font-size: 12px;
  color: var(--primaryGreen);

  cursor: pointer;
  pointer-events: ${(props) => props.disabled && 'none'};
  opacity: ${(props) => props.disabled && '0.3'};
`;

export const AddNewButton = ({ children, onClick, className }) => (
  <Wrapper onClick={onClick} className={className}>
    {children} <Spacer width="4px" />
    <AddIcon size="14px" />
  </Wrapper>
);
