import React, { useContext, useEffect, useState } from 'react';

import { AppContext } from 'AppContext';
import { INTEGRATION_METADATA_FIELDS, INTEGRATION_SERVICES } from 'consts/integrations';
import { getMemberList } from 'api/members';
import { ReactComponent as MailIcon } from 'images/mail-icon.svg';
import { ReactComponent as SlackIcon } from 'images/slack-icon.svg';
import { ReactComponent as UsersIcon } from 'images/users.svg';
import { MembersModal } from 'shared/MembersModal';
import { Flexer, Spacer } from 'components/Core';
import {
  ButtonStyling,
  StyledButton,
  OptionsSection,
  Subtitle,
  Title,
  ListItem,
  ListSection,
} from 'shared/ReportSubscribe/styles';
import { REPORT_SHARE_TABS } from './ReportShareModal';
import { EmailSelector } from 'components/Controls/EmailSelector';

const Button = ({ connected, children, ...rest }) => (
  <ButtonStyling connected={connected} {...rest}>
    {children}
  </ButtonStyling>
);

export const ReportShare = ({
  analyticsIntegrations,
  updateAnalyticsIntegration,
  selectedTab,
  setSelectedTab,
  manuallyAddedEmails,
  setManuallyAddedEmails,
}) => {
  const { orgId } = useContext(AppContext);
  const [showMembersModal, setShowMembersModal] = useState(false);
  const [members, setMembers] = useState([]);

  const isSlackConnected =
    analyticsIntegrations[INTEGRATION_SERVICES.SLACK] &&
    !analyticsIntegrations[INTEGRATION_SERVICES.SLACK].metadata[INTEGRATION_METADATA_FIELDS.SKIP_DAILY_SYNC];

  const fetchMembers = async () => {
    const data = await getMemberList(orgId);
    setMembers(data.map((member) => member.email));
  };

  useEffect(() => {
    fetchMembers();
    // eslint-disable-next-line
  }, [orgId]);

  useEffect(() => {
    updateAnalyticsIntegration({
      service: INTEGRATION_SERVICES.EMAIL,
      additionalMetadata: {
        [INTEGRATION_METADATA_FIELDS.NON_MEMBERS_EMAILS]: manuallyAddedEmails,
      },
    });
    // eslint-disable-next-line
  }, [manuallyAddedEmails]);

  return (
    <Flexer direction="column">
      <OptionsSection>
        <Button
          data-cy="report-share__slack-tab"
          flexGrow={3}
          connected={selectedTab === REPORT_SHARE_TABS.SLACK}
          onClick={() => {
            if (isSlackConnected) {
              setSelectedTab(REPORT_SHARE_TABS.SLACK);
            } else {
              updateAnalyticsIntegration({ service: INTEGRATION_SERVICES.SLACK, active: !isSlackConnected });
            }
          }}
        >
          <SlackIcon />
          <Spacer width="8px" />
          <Flexer direction="column">
            <Title>Slack</Title>
            <Subtitle>Bot send a report to your channel</Subtitle>
          </Flexer>
          {/*Show connect button only if SLACK integration is not already been added*/}
          {!analyticsIntegrations[INTEGRATION_SERVICES.SLACK]?.id && <StyledButton>Connect</StyledButton>}
        </Button>

        <Spacer width="10px" />

        <Button
          flexGrow={3}
          connected={selectedTab === REPORT_SHARE_TABS.EMAIL}
          onClick={() => setSelectedTab(REPORT_SHARE_TABS.EMAIL)}
        >
          <MailIcon />
          <Spacer width="8px" />
          <Flexer direction="column">
            <Title>Email</Title>
            <Subtitle>Subscript send email to all recipients</Subtitle>
          </Flexer>
        </Button>
      </OptionsSection>
      {selectedTab === REPORT_SHARE_TABS.EMAIL && (
        <>
          <ListSection>
            <ListItem>
              <UsersIcon />
              <p>
                <b>Members</b> who will receive updates via Email:
              </p>
            </ListItem>

            <EmailSelector
              name="report-share"
              value={manuallyAddedEmails}
              onChange={setManuallyAddedEmails}
              fixedValues={members}
            />
          </ListSection>
        </>
      )}
      {showMembersModal && <MembersModal onClose={() => setShowMembersModal(false)} />}
      {/*<InProgressModal />*/}
    </Flexer>
  );
};
