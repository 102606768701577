import styled from 'styled-components';
import { Centerer, FlexerRow } from 'components/Core';

export const HeaderWrapper = styled.div`
  border-radius: 20px 20px 0 0;
  background: var(--primaryGray);
`;

export const HeaderTitle = styled.div`
  font-weight: 900;
  font-size: 20px;
  line-height: 26px;

  padding: 20px 40px;
`;

export const MonthButtonsWrapper = styled(FlexerRow)`
  padding: 0 40px 20px 40px;
  gap: 8px;
`;

export const MonthIndex = styled(Centerer)`
  font-weight: 900;
  font-size: 10px;
  line-height: 12px;

  padding: 8px;
  width: 24px;
  height: 24px;
  border-radius: 8px;
`;

export const MonthButton = styled(Centerer)`
  cursor: pointer;

  height: 40px;
  padding: 8px;

  border: 1px solid var(--accentGraySecond);
  box-shadow: 4px 4px 20px var(--primaryBlack3);
  border-radius: 8px;

  background: ${({ isSelected }) => (isSelected ? 'var(--primaryBlue10)' : '#FFF')};

  span {
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;

    margin-left: 8px;
  }

  ${MonthIndex} {
    color: ${({ isSelected }) => (isSelected ? '#FFF' : 'var(--primaryBlack50)')};
    background: ${({ isSelected }) => (isSelected ? 'var(--primaryBlue)' : 'var(--primaryBlack3)')};
  }

  &:hover {
    ${MonthIndex} {
      background: ${({ isSelected }) => (isSelected ? 'var(--primaryBlue)' : 'var(--primaryBlue10)')};
    }

    span {
      color: var(--primaryBlue);
    }
  }
`;

export const LoaderWrapper = styled(Centerer)`
  padding: 60px;
  width: 100%;
`;
