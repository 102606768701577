import styled, { css } from 'styled-components';

import { FlexerRow } from 'components/Core';
import { Popover } from 'components/Portal';
import { StyledInput } from 'components/Controls/FormikCustomInput/FormikCustomInput';
import { ReactComponent as EnterIcon } from 'images/input-enter-icon.svg';
import { ReactComponent as ClearIcon } from 'images/mini-grey-close-icon.svg';
import { ReactComponent as BinIcon } from 'images/bx-trash.svg';

import { METADATA_DATA_TYPES } from '../consts';

const StyledClearIcon = styled(ClearIcon)`
  width: 16px;
  height: 16px;

  &:hover {
    cursor: pointer;
  }
`;

const StyledEnterIcon = styled(EnterIcon)`
  width: 16px;
  height: 16px;
`;

const StyledBinIcon = styled(BinIcon)`
  width: 18px;
  height: 18px;
`;

const Input = styled(StyledInput)`
  border: none;
  padding-inline: 5px;

  &:hover {
    border: none;
  }

  &:focus {
    border: none;
    box-shadow: none;
  }
`;

const TextInputWrapper = styled(FlexerRow)`
  gap: 5px;
  padding-inline: 15px;
`;

const InputPopover = styled(Popover)`
  width: 100%;
  border-radius: 8px;
`;

const ContentWrapper = styled.div`
  background-color: var(--accentWhiterGray);
  padding: 8px;
  border-top: 1px solid var(--neutralGray);
`;

const OptionsHeaderText = styled.span`
  color: var(--primaryDark);
  opacity: 0.5;
  font-style: italic;
  margin-block: 5px;
  display: inline-block;
  margin-left: 5px;
`;

const Option = styled.div`
  background-color: white;
  border-inline: 1px solid var(--accentGraySecond);
  border-block: 0.5px solid var(--accentGraySecond);
  color: var(--primaryDark);
  font-weight: 700;
  padding: 12px;
  display: flex;
  align-items: center;
  font-size: 12px;

  &:hover {
    cursor: pointer;
    background-color: ${({ disableHoverColor }) => (disableHoverColor ? 'none' : 'var(--neutralBlue)')};
  }

  ${({ selected }) =>
    selected
      ? css`
          background-color: var(--primaryBlue);
          color: white;
          &:hover {
            cursor: pointer;
            background-color: var(--primaryBlue);
          }
        `
      : ''}

  ${({ position }) => {
    if (position === 'first') {
      return css`
        border-top-width: 1px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
      `;
    }

    if (position === 'last') {
      return css`
        border-bottom-width: 1px;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      `;
    }

    if (position === 'singleOption') {
      return css`
        border-block-width: 1px;
        border-radius: 8px;
      `;
    }
    return '';
  }}
`;

export const MetadataItemInputPopover = ({ value, setValue, metadataDataType, isValueMissing, item, onClose }) => {
  const handleClearValue = () => {
    setValue(null);
  };

  return (
    <InputPopover onClick={(e) => e.stopPropagation()} YOffset={80}>
      {(() => {
        switch (metadataDataType) {
          case METADATA_DATA_TYPES.BOOLEAN:
            return (
              <>
                {(item.options.length < 2 ? [true, false] : item.options).map((option, index) => (
                  <Option
                    key={option + index}
                    position={index === 0 ? 'first' : isValueMissing ? 'last' : null}
                    selected={value === option}
                    onClick={() => {
                      setValue(option);
                    }}
                  >
                    {option.toString()}
                  </Option>
                ))}
                {!isValueMissing && (
                  <Option position="last" disableHoverColor onClick={handleClearValue}>
                    <StyledBinIcon />
                    Clear Value
                  </Option>
                )}
              </>
            );
          case METADATA_DATA_TYPES.DATETIME:
            return null;
          default:
            return (
              <>
                <TextInputWrapper alignItems="center">
                  <StyledEnterIcon />

                  <Input
                    placeholder="Add new custom value..."
                    value={value ?? ''}
                    onChange={(e) => {
                      setValue(
                        metadataDataType === METADATA_DATA_TYPES.NUMBER && e.target.value
                          ? Number(e.target.value)
                          : e.target.value,
                      );
                    }}
                    type={metadataDataType === METADATA_DATA_TYPES.NUMBER ? 'number' : 'text'}
                    onWheel={(e) => {
                      metadataDataType === METADATA_DATA_TYPES.NUMBER &&
                        e?.currentTarget?.blur &&
                        e.currentTarget.blur();
                    }}
                    onKeyPress={(e) => e.key === 'Enter' && onClose()}
                    data-cy={`metadata-edit-input__${item.key}`}
                  />

                  <StyledClearIcon
                    onClick={() => handleClearValue()}
                    data-cy={`metadata-button--clear-value--${item.key}`}
                  />
                </TextInputWrapper>
                {item.options.length > 0 && (
                  <ContentWrapper>
                    <OptionsHeaderText>Select from existing:</OptionsHeaderText>
                    <div>
                      {item.options.map((option, index) => (
                        <Option
                          key={option}
                          position={
                            item.options.length === 1
                              ? 'singleOption'
                              : index === 0
                              ? 'first'
                              : index === item.options.length - 1
                              ? 'last'
                              : null
                          }
                          selected={(value?.trim?.() ?? value) === option}
                          onClick={() => {
                            setValue(option, true);
                          }}
                        >
                          {option}
                        </Option>
                      ))}
                    </div>
                    {!isValueMissing && (
                      <Option
                        position="singleOption"
                        disableHoverColor
                        onClick={handleClearValue}
                        style={{
                          marginTop: '8px',
                        }}
                      >
                        <StyledBinIcon />
                        Clear Value
                      </Option>
                    )}
                  </ContentWrapper>
                )}
              </>
            );
        }
      })()}
    </InputPopover>
  );
};
