import { useContext, useEffect } from 'react';
import dayjs from 'dayjs';
import { useHistory } from 'react-router-dom';
import { AppContext } from 'AppContext';
import { EVENTS } from 'consts/analytics';
import { useAnalytics, useUrlQuery } from 'utils/hooks';
import { SNAPSHOT_METRICS } from 'consts/snapshotMetrics';

export const MODALS_FOR_OPEN = {
  REVENUE_DETAILS: 'revenue-details',
  UP_FOR_RENEWAL: 'up-for-renewal',
  CARD_CHART: 'card-chart',
};

export const useOpenDrilldownsFromUrl = ({
  setSelectedMetric,
  setSelectedMonth,
  setShowRevenueDetailsModal,
  setShowUpForRenewalModal,
  setSummaryColumnHeader,
  openCardChartModal,
}) => {
  const { dataFilter } = useContext(AppContext);
  const { trackEvent } = useAnalytics();
  const history = useHistory();
  const query = useUrlQuery();
  const modalForOpen = query.get('open-modal');
  const metric = query.get('metric');
  const origin = query.get('origin');

  useEffect(() => {
    if (modalForOpen === MODALS_FOR_OPEN.REVENUE_DETAILS) {
      setSelectedMetric(metric);

      setSelectedMonth(dayjs(dataFilter?.endMonth).format('YYYY-MM'));

      setShowRevenueDetailsModal(true);

      //clean query so it doesn't appear in sharing feature
      query.delete('open-modal');
      query.delete('metric');
      query.delete('origin');
      history.replace({
        search: query.toString(),
      });

      trackEvent({
        name: EVENTS.OPEN_DASHBOARD_MODAL_FROM_EMAIL_REPORT,
        properties: {
          metric,
        },
      });
    }
  }, [
    history,
    query,
    trackEvent,
    setSelectedMetric,
    dataFilter?.endMonth,
    metric,
    modalForOpen,
    setSelectedMonth,
    setShowRevenueDetailsModal,
  ]);

  useEffect(() => {
    if (modalForOpen === MODALS_FOR_OPEN.UP_FOR_RENEWAL) {
      //when we open metric from email we always want to open current month
      setSelectedMonth(dayjs(dataFilter?.endMonth).format('YYYY-MM'));
      setSummaryColumnHeader(dayjs(dataFilter?.endMonth).format('MMMM YYYY'));
      setShowUpForRenewalModal(true);

      //clean query so it doesn't appear in sharing feature
      query.delete('open-modal');
      query.delete('origin');
      history.replace({
        search: query.toString(),
      });

      trackEvent({
        name: EVENTS.OPEN_DASHBOARD_MODAL_FROM_EMAIL_REPORT,
        properties: {
          metric: 'Up for renewal',
        },
      });
    }
  }, [
    history,
    query,
    trackEvent,
    modalForOpen,
    dataFilter?.endMonth,
    setSelectedMonth,
    setSummaryColumnHeader,
    setShowUpForRenewalModal,
  ]);

  useEffect(() => {
    if (modalForOpen === MODALS_FOR_OPEN.CARD_CHART) {
      //when we open metric from email we always want to open current month
      // if it comes from origin='link-share' we want to oppen using dataFilters
      const isTTM = metric.includes('_TTM');
      const tableDateEnd = dayjs(dataFilter?.endMonth);
      const tableDateStart =
        origin === 'link-share'
          ? dayjs(dataFilter?.startMonth)
          : isTTM
          ? tableDateEnd.subtract(1, 'year')
          : tableDateEnd.subtract(1, 'month');
      const currentMetric = SNAPSHOT_METRICS[metric];

      openCardChartModal({
        metricKey: currentMetric?.key,
        tableDateStart: tableDateStart.toDate(),
        tableDateEnd: tableDateEnd.toDate(),
      });

      //clean query so it doesn't appear in sharing feature
      query.delete('open-modal');
      query.delete('metric');
      query.delete('origin');
      history.replace({
        search: query.toString(),
      });

      trackEvent({
        name: EVENTS.OPEN_DASHBOARD_MODAL_FROM_EMAIL_REPORT,
        properties: {
          metric,
        },
      });
    }
  }, [
    history,
    query,
    dataFilter?.startMonth,
    dataFilter?.endMonth,
    trackEvent,
    metric,
    modalForOpen,
    openCardChartModal,
    origin,
  ]);
};
