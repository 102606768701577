import React, { useMemo, useState } from 'react';
import * as Sentry from '@sentry/react';
import styled from 'styled-components';
import { Input } from 'antd';
import { uniq } from 'lodash';
import { NUMBER_FORMATS } from 'consts/global';
import { isValidEmail, pluralize } from 'utils/stringUtils';
import { upsertInvoicingDetails } from 'api/customers/requests';
import { numberFormatter } from 'utils/formatters';
import { getEmailAddresses } from 'models/customer';
import { ReactComponent as ArrowDown } from 'images/chevron-down.svg';
import { ReactComponent as ArrowUp } from 'images/arrow_up_bold.svg';
import { ReactComponent as ExternalLink } from 'images/external-link-full.svg';
import { usePortal } from 'components/Portal';
import { AlertUnfilledIcon, PlusSignIcon, TrashIcon } from 'components/Icons';
import { CentererVertical, Centerer, Flexer, Spacer } from 'components/Core';
import { TableSubRowsCountArrow } from 'components/Table';
import { GROUP_BY } from '../Pages/ReviewTransactions/ReviewTransactionsContainer';

const Wrapper = styled(CentererVertical)`
  overflow: visible;
  white-space: nowrap;
`;

const Label = styled.div`
  font-size: 12px;
  font-weight: 700;
  margin-right: 8px;
`;

const IconWrapper = styled(Centerer)`
  cursor: pointer;
  padding: 2px;
  border-radius: 2px;
  background-color: var(--primaryBlue10);
`;

const SchedulesSummaryText = styled.div`
  margin: 0px 16px;
  font-size: 12px;
`;

const EmailBlock = styled(Flexer)`
  font-size: 12px;
  font-weight: 700;
  gap: 4px;
  background-color: var(--primaryBlue10);
  color: var(--primaryBlue);
  border-radius: 4px;
  padding: 4px 8px;

  &:hover {
    color: var(--secondaryBlue);
    background-color: var(--primaryBlue20);

    svg {
      path {
        fill: var(--primaryBlack50);
      }
    }
  }
`;

const EmailButton = styled(EmailBlock)`
  cursor: pointer;
  margin-right: 8px;
`;

const EmailsList = styled(Flexer)`
  height: 100%;
  width: 100%;
  max-height: 136px;
  overflow: auto;
  align-items: center;
  max-width: 520px;
  flex-wrap: nowrap;
  gap: 8px;

  ::-webkit-scrollbar {
    display: none;
  }
`;

const EmailOption = styled(EmailBlock)`
  white-space: nowrap;

  &:hover {
    svg {
      cursor: pointer;
    }
  }
`;

const EmailInput = styled(Input)`
  width: 200px;
  height: 25px;
  min-width: 200px;
  color: var(--secondaryBlue);
  background-color: var(--primaryBlue20);
  border-radius: 4px;

  ::placeholder {
    font-family: Nunito Sans;
    color: var(--primaryBlue50);
    font-size: 12px;
    font-weight: 700;
  }
`;

const EmailError = styled.span`
  color: var(--primaryRed);
  font-size: 12px;
  font-style: italic;
  font-weight: 700;
`;

const EmailsDropdown = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  max-width: 520px;
  gap: 8px;
  background-color: white;
  padding: 8px;
  box-shadow: 16px 16px 60px 0px var(--primaryBlack20);
  border-radius: 8px;
  border: 1px solid var(--neutralGray);
`;

const OtherEmailsButton = styled(EmailOption)`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 4px;
  padding-left: 8px;

  svg {
    transform: ${({ isOpen }) => isOpen && 'rotate(180deg)'};
    width: 16px;
    height: 16px;
  }

  &:hover {
    svg {
      path {
        fill: initial;
      }
    }
  }
`;

export const CustomerHeaderRow = ({
  orgId,
  customer,
  currency,
  unitCount,
  unitName,
  showSchedulesSummary = true,
  onEmailListChange,
  crmID,
  row,
  groupBy,
}) => {
  const { customerName, customerId, totalAmount } = customer;

  const { triggerRef, togglePortal, isPortalVisible, Portal } = usePortal({ XOffset: 0, YOffset: -8 });

  const [showEmailInput, setShowEmailInput] = useState(false);
  const [emailInputValue, setEmailInputValue] = useState();

  const emailsList = useMemo(() => getEmailAddresses({ objectWithInvoicingDetails: customer }), [customer]);
  const showEmailError = useMemo(() => emailsList.length === 0, [emailsList]);

  const handleEmailAdd = async (e) => {
    const value = e.target.value;
    if (!isValidEmail(value)) return;

    const newEmailList = uniq([value, ...emailsList]);

    try {
      await upsertInvoicingDetails({ orgId, customerId, body: { contacts: newEmailList } }); // save invoicingDetails.contacts to customer
      onEmailListChange({ customerId, newEmailList });
      setEmailInputValue(); // clear input
    } catch (err) {
      Sentry.captureMessage(`An error when adding the email ${value} to customer ${customerId} from CustomerHeaderRow`);
    }
  };

  const handleEmailRemove = async (email) => {
    const newEmailList = emailsList.filter((item) => item !== email);

    try {
      await upsertInvoicingDetails({ orgId, customerId, body: { contacts: newEmailList } }); // save invoicingDetails.contacts to customer
      onEmailListChange({ customerId, newEmailList });
    } catch (err) {
      Sentry.captureMessage(
        `An error when removing the email ${email} for customer ${customerId} from CustomerHeaderRow`,
      );
    }
  };

  return (
    <Wrapper>
      <Label>{`${customerName}${crmID ? ` - ${crmID}` : ''}`}</Label>
      {groupBy === GROUP_BY.CRM_ID && (
        <>
          <TableSubRowsCountArrow isHidden={!row.isExpanded} dashboardTable {...row.getToggleRowExpandedProps()}>
            <ArrowUp />
          </TableSubRowsCountArrow>
          <Spacer width="8px" />
        </>
      )}
      <IconWrapper>
        <ExternalLink onClick={() => window.open(`/customers/${customerId}`, '_blank')} />
      </IconWrapper>
      {showSchedulesSummary ? (
        <SchedulesSummaryText>
          {pluralize(unitCount ?? 0, unitName)}:{' '}
          {numberFormatter({
            type: NUMBER_FORMATS.CURRENCY,
            rawValue: totalAmount,
            currency,
          })}
        </SchedulesSummaryText>
      ) : (
        <Spacer width="8px" />
      )}
      <EmailButton onClick={() => setShowEmailInput(true)} data-cy="customer-header-row__add-email-button">
        <PlusSignIcon fill="var(--primaryBlack30)" size="16px" />
        <span>Email</span>
      </EmailButton>
      <EmailsList>
        {showEmailInput && (
          <EmailInput
            autoFocus
            value={emailInputValue}
            onChange={(e) => setEmailInputValue(e.target.value)}
            placeholder="Enter email..."
            data-cy="customer-header-row__new-email-input"
            onBlur={handleEmailAdd}
            onPressEnter={handleEmailAdd}
          />
        )}

        {showEmailError && (
          <Flexer>
            <AlertUnfilledIcon size="16px" />
            <EmailError>add at least 1 email</EmailError>
          </Flexer>
        )}

        {emailsList.map((email, index, list) =>
          index < 2 ? (
            <EmailOption key={email} data-cy={`customer-header-row__email--${index}`}>
              <span>{email}</span>
              <TrashIcon
                fill="var(--primaryBlack30)"
                size="16px"
                onClick={() => handleEmailRemove(email)}
                data-cy="customer-header-row__remove-email"
              />
            </EmailOption>
          ) : index === 2 ? (
            <>
              <OtherEmailsButton isOpen={isPortalVisible} onClick={togglePortal} key={email} ref={triggerRef}>
                <span>See other {pluralize(list?.slice(2)?.length, 'email')}</span>
                <ArrowDown />
              </OtherEmailsButton>

              {isPortalVisible && (
                <Portal>
                  <EmailsDropdown>
                    {list?.slice(2)?.map((email) => (
                      <EmailOption key={email}>
                        <span>{email}</span>
                        <TrashIcon
                          fill="var(--primaryBlack30)"
                          size="16px"
                          onClick={() => handleEmailRemove(email)}
                          data-cy="customer-header-row__remove-email"
                        />
                      </EmailOption>
                    ))}
                  </EmailsDropdown>
                </Portal>
              )}
            </>
          ) : null,
        )}
      </EmailsList>
    </Wrapper>
  );
};
