import React from 'react';
import PropTypes from 'prop-types';
import { Line, defaults } from 'react-chartjs-2';
import { cssVar, transparentize } from 'polished';
import { amountWithAbbreviation } from 'utils/formatters';

const CHART_COLORS = {
  DARK: cssVar('--accentDarkGray'),
  RED: cssVar('--secondaryRed'),
};

const Graph = ({ isARR, labels, data, dataKey, dataType, goalData, currency, showYAxis, ticks }) => {
  defaults.global.defaultFontFamily = 'Nunito Sans';

  return (
    <Line
      data={{
        labels,
        datasets: [
          {
            type: 'line',
            data: data.map((item) => (isARR ? item[dataKey] * 12 : item[dataKey])),
            borderColor: CHART_COLORS.DARK,
            backgroundColor: transparentize(0.98, cssVar('--primaryBlack')),

            // Fill area
            fill: 'start',

            // Point
            pointBackgroundColor: data.reduce((acc, item, index) => {
              const goalValue = goalData[index];
              acc.push(!goalValue || item[dataKey] >= goalValue ? CHART_COLORS.DARK : CHART_COLORS.RED);
              return acc;
            }, []),
            pointBorderColor: 'white',
            borderWidth: 2,
            pointRadius: 4,
            lineTension: 0,

            datalabels: {
              align: 'end',
              anchor: 'end',
            },
          },
        ],
      }}
      options={{
        responsive: true,
        maintainAspectRatio: false,
        layout: {
          padding: {
            top: 32,
            right: 40,
            bottom: 14,
            left: 40,
          },
        },
        legend: {
          display: false,
        },
        tooltips: {
          enabled: false,
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false,
              },
              ticks: {
                callback: (value) => value.toUpperCase(),
                autoSkip: true,
                maxTicksLimit: ticks,
                maxRotation: 0,
                minRotation: 0,
                fontStyle: 'normal',
                fontSize: 10,
                fontColor: cssVar('--primaryBlack70'),
              },
            },
          ],
          yAxes: [
            {
              display: showYAxis,
              gridLines: {
                display: false,
              },
              ticks: {
                padding: 20,
                callback: (value) => amountWithAbbreviation({ value, type: dataType, currency }),
                autoSkip: true,
                maxTicksLimit: 5,
                maxRotation: 0,
                minRotation: 0,
                fontStyle: 'normal',
                fontSize: 10,
                fontColor: cssVar('--primaryBlack70'),
              },
            },
          ],
        },
        plugins: {
          datalabels: {
            display: 'auto',
            backgroundColor: (value) => {
              const goalValue = goalData[value.dataIndex];
              const dataValue = data[value.dataIndex]?.[dataKey];
              return !goalValue || dataValue >= goalValue
                ? cssVar('--accentGraySecond')
                : cssVar('--opaquePrimaryRed10');
            },
            borderRadius: 4,
            color: (value) => {
              const goalValue = goalData[value.dataIndex];
              const dataValue = data[value.dataIndex]?.[dataKey];
              return !goalValue || dataValue >= goalValue ? cssVar('--primaryBlack50') : cssVar('--primaryRed');
            },
            font: {
              weight: 'bold',
            },
            formatter: (value) => amountWithAbbreviation({ value, type: dataType, currency }),
            padding: 6,
          },
        },
      }}
    />
  );
};

Graph.propTypes = {
  /**
   * Is graph used in big card
   */
  bigView: PropTypes.bool,
  /**
   * Is value ARR
   */
  isARR: PropTypes.bool,
  /**
   * Data Type of data
   */
  dataType: PropTypes.string,
  /**
   * Key in data object
   */
  dataKey: PropTypes.string.isRequired,
  /**
   * Chart data. Objects must include dataKey
   */
  data: PropTypes.arrayOf(PropTypes.any).isRequired,
  /**
   * Data labels.
   */
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default Graph;
