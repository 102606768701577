import React, { useContext, useState, useEffect } from 'react';
import { AppContext } from 'AppContext';

import { useContractsAPI } from 'api/contracts';
import { usePagination as usePaginationButtons } from 'utils/hooks';

import { ContractsTable } from './ContractsTable';
import { CONTRACT_STATUS } from '../consts';

export const ContractsTableContainer = ({ activeTab }) => {
  const { orgId } = useContext(AppContext);

  const { pageSize, setPageSize, currentPageIndex, setCurrentPageIndex } = usePaginationButtons({
    storageSuffix: 'contracts-page',
    defaultPageSize: 10,
  });

  // Set page index to 1 when tab changes
  useEffect(() => {
    setCurrentPageIndex(1);
  }, [activeTab, setCurrentPageIndex]);

  const [searchQuery, setSearchQuery] = useState('');

  const { data, isLoading, metadata } = useContractsAPI({
    orgId,
    page: currentPageIndex,
    limit: pageSize,
    status: activeTab,
    searchQuery,

    refetchInterval: activeTab === CONTRACT_STATUS.PROCESSING ? 2000 : undefined,
    // refetchInterval: 2000,
    // TODO: There's this really annoying bug where if we go through the flow all the way
    // to where the invoice schedule modal pops up, any portals we open on that modal
    // (for example, the frequency selecting modal/portal) will close on each refetch (due to a re-render).
    // We have to use the above line of code to disable refetching on any tab other than
    // the "PROCESSING" tab, if we don't find a better approach to this to avoid the portals
    // closing on refetch. It'd be nice to have this polling active on all tabs
  });

  return (
    <ContractsTable
      orgId={orgId}
      data={data ?? []}
      isLoading={isLoading}
      pageSize={pageSize}
      setPageSize={setPageSize}
      currentPageIndex={currentPageIndex}
      setCurrentPageIndex={setCurrentPageIndex}
      controlledPageCount={metadata?.maxPage}
      rowCount={metadata?.totalCount}
      setSearchQuery={setSearchQuery}
      activeTab={activeTab}
    />
  );
};
