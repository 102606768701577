import React from 'react';
import styled from 'styled-components';
import { ErrorBar } from 'components/Blocks';

const Body = styled.div`
  margin-left: 10px;
  width: 90%;
`;

const Text = styled.h5`
  margin-top: 10px;
  font-size: 16px;
  font-weight: 600;
`;

export const InvalidDateRangeError = () => (
  <ErrorBar styles={{ marginBottom: 20 }}>
    <Body>
      <Text>End date must be greater than Start date!</Text>
    </Body>
  </ErrorBar>
);
