import dayjs from 'dayjs';
import { BUSINESS_SNAPSHOT_COLUMNS } from 'consts/global';
import { convertQuartersConfig } from 'utils/dateUtils';
import { setQuarterColumn } from './setQuarterColumn';

export const setColumnHeaders = ({ revenueStats, quarters, quartersYearOffset, selectedColumns }) => {
  const columnHeaders = [];
  const quarterEndMonths = convertQuartersConfig(quarters);

  for (const month of Object.keys(revenueStats ?? {})) {
    const date = dayjs(month);
    //add normal month headers
    selectedColumns && selectedColumns[BUSINESS_SNAPSHOT_COLUMNS.MONTHS] && columnHeaders.push(date.format('MMM YYYY'));
    //add quarter and yearly summary headers
    setQuarterColumn({ date, selectedColumns, columnHeaders, quarterEndMonths, quartersYearOffset });
  }
  selectedColumns && selectedColumns[BUSINESS_SNAPSHOT_COLUMNS.CUMULATIVE] && columnHeaders.push('CUMULATIVE');

  return columnHeaders;
};
