import { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { FlexerColumn } from 'components/Core';
import { GreyDot } from 'components/Icons';
import { useToasts } from 'components/Toasts';
import { useInvoicingScheduleAPI } from 'api/billing/hooks';
import { transactionDisplayTitle } from 'models/transaction';
import { TransactionActionsModal } from 'shared/TransactionActionsModal';
import {
  BulkTransactionsCounter,
  NavigatorText,
  TotalBulkCount,
} from 'shared/TransactionContent/TransactionFooter/TransactionBulkNavigator.styles';
import { InvoiceChanges, InvoicingScheduleChangesContext } from 'shared/InvoicingScheduleChanges';

const InvoicesModalFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const generateTransactionDescription = (deletedTransactions) => {
  if (deletedTransactions.length === 1) {
    const transaction = deletedTransactions[0];
    return (
      <p style={{ textAlign: 'left', padding: '0 36px' }}>
        This transaction {transactionDisplayTitle(transaction)} ({transaction.id}) is being invoiced
      </p>
    );
  } else if (deletedTransactions.length > 0) {
    return (
      <div style={{ paddingLeft: '0 36px' }}>
        <p>These {deletedTransactions.length} transactions are being invoiced:</p>
        <ul>
          {deletedTransactions.map((transaction) => (
            <li key={transaction.id}>
              {transactionDisplayTitle(transaction)} ({transaction.id})
            </li>
          ))}
        </ul>
      </div>
    );
  }
};

export const TransactionsConfirmInvoicesModal = ({
  orgId,
  invoicingScheduleId,
  deletedTransactions,
  currentIndex,
  totalCount,
  onClose,
}) => {
  const { pushError } = useToasts();
  const { setShowInvoicesPreviewModal } = useContext(InvoicingScheduleChangesContext);
  const {
    data: invoicingSchedule,
    isLoading: isScheduleLoading,
    operations: { resyncInvoicingSchedule },
  } = useInvoicingScheduleAPI({
    orgId,
    invoicingScheduleId,
    scopes: ['transactions', 'invoices'],
  });
  const [invoicesToUpdate, setInvoicesToUpdate] = useState();
  const [isChangesLoading, setIsChangesLoading] = useState(false);

  const deletedTransactionIds = deletedTransactions.map(({ id }) => id);

  const getInvoicingScheduleChanges = async () => {
    try {
      setIsChangesLoading(true);

      const invoicesToUpdate = await resyncInvoicingSchedule({
        orgId,
        invoicingScheduleId,
        data: { dryRun: true, transactionIdsToRemove: deletedTransactionIds },
      });

      if (
        invoicesToUpdate?.invoicesToDelete?.length > 0 ||
        invoicesToUpdate?.invoicesToInsert?.length > 0 ||
        invoicesToUpdate?.changedInvoices?.length > 0
      ) {
        setInvoicesToUpdate(invoicesToUpdate);
      }
    } catch (e) {
      console.error({ message: e.message, component: 'InvoiceChangesModal.js', stack: e });
    } finally {
      setIsChangesLoading(false);
    }
  };

  useEffect(() => {
    getInvoicingScheduleChanges();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resyncScheduleAfterTransactionsDeleted = async (externalUpdatesMode) => {
    try {
      await resyncInvoicingSchedule({
        orgId,
        invoicingScheduleId,
        data: {
          transactionIdsToRemove: deletedTransactionIds,
          externalUpdatesMode,
        },
      });
      onClose();
    } catch (e) {
      pushError(e, 'Failed to update invoicing schedule!');
      console.error({ message: e.message, component: 'ResyncScheduleAfterTransactionsDeleted', stack: e });
    }
  };

  const handleConfirm = async () => {
    if (removeScheduleMode) {
      await resyncScheduleAfterTransactionsDeleted(false);
    } else {
      setShowInvoicesPreviewModal(true);
    }
  };

  const scheduleTransactionsAfterRemove = (invoicingSchedule?.transactions ?? []).filter(
    (scheduleTransaction) => !deletedTransactionIds.includes(scheduleTransaction?.id),
  );
  const sentOrPaidOrVoidedInvoices = (invoicingSchedule?.invoices ?? []).filter(
    (invoice) => invoice.sent_at || invoice.paid_at || invoice.voided_at,
  );
  const removeScheduleMode = !scheduleTransactionsAfterRemove?.length && !sentOrPaidOrVoidedInvoices?.length;

  const modalTitle = () => {
    if (removeScheduleMode) return 'Do you want to remove the invoicing schedule?';
    else return 'Do you want to update unsent invoices?';
  };

  const modalDescription = (
    <FlexerColumn>
      {generateTransactionDescription(deletedTransactions)}
      <InvoiceChanges
        update={{
          updateData: removeScheduleMode ? { invoicesToDelete: invoicingSchedule?.invoices } : invoicesToUpdate,
          invoicingSchedule,
        }}
        removeScheduleMode={removeScheduleMode}
        onClose={onClose}
        onConfirmUpdate={handleConfirm}
        onConfirmNoUpdate={() => resyncScheduleAfterTransactionsDeleted(true)}
        customFooter={
          <InvoicesModalFooter data-cy="transaction-bulk-delete-invoices-modal__navigation">
            <NavigatorText>Bulk Update Invoicing Schedule</NavigatorText>
            <GreyDot style={{ marginLeft: 20, marginRight: 20 }} />
            <BulkTransactionsCounter>
              <span data-cy="transactions-modal--bulk__update-invoicing-schedule__index">{currentIndex + 1}</span>
              <TotalBulkCount>
                {' '}
                / <span data-cy="transactions-modal--bulk__update-invoicing-schedule__total">{totalCount}</span>
              </TotalBulkCount>
            </BulkTransactionsCounter>
          </InvoicesModalFooter>
        }
      />
    </FlexerColumn>
  );

  return (
    <TransactionActionsModal
      modalName="transaction-bulk-delete-invoices-modal"
      modalTitle={modalTitle()}
      modalDescription={modalDescription}
      isLoading={isScheduleLoading || isChangesLoading}
    />
  );
};
