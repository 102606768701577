import React, { useContext } from 'react';
import { SNAPSHOT_METRIC_BY_VALUE_KEY } from 'consts/snapshotMetrics';
import { ReactComponent as ChartIcon } from 'images/chart-area-line.svg';
import { MODALS_FOR_OPEN } from 'utils/hooks';
import { CentererVertical, Flexer, FlexerColumn, Spacer } from 'components/Core';
import { Loader } from 'components/Loaders';
import { Modal, ModalBody, ModalCloseIcon, ModalHeader, ModalContainer } from 'components/Modal';
import { GreyDot } from 'components/Icons';
import { useLinkShare } from 'shared/LinkShare';
import { GoalsBanner } from 'shared/Goals';
import { DashboardContext } from 'views/Dashboard/DashboardContext';
import { CardChartBarChart } from './CardChartBarChart';
import { CardChartSelectors } from './CardChartSelectors';
import { CardChartModalContext } from './CardChartModalContext';
import { RetentionSection } from './RetentionSection';
import { StatisticsSideBar } from './StatisticsSideBar';
import { StatisticsTable } from './StatisticsTable';
import { ModalTitleLabel } from './styled';

export const CardChartModal = ({ closeModal }) => {
  const { dataFilter } = useContext(DashboardContext);
  const { metricKey, title, isTTM, isRetentionMetric, revenueData, isLoading } = useContext(CardChartModalContext);
  const { LinkShareButton, LinkShareModal } = useLinkShare({
    headerTitle: 'Metric',
    additionalQuery: {
      'open-modal': MODALS_FOR_OPEN.CARD_CHART,
      metric: SNAPSHOT_METRIC_BY_VALUE_KEY[metricKey],
      origin: 'link-share',
    },
  });

  return (
    <ModalContainer data-cy="card-chart-modal">
      <Modal background="var(--primaryGray)" padding="20px" width="100%" height="90vh">
        {isLoading ? (
          <div className="w-100 h-100 flexer">
            <Loader containerStyles={{ width: 40 }} />
          </div>
        ) : (
          <>
            <ModalCloseIcon onClose={closeModal} data-cy="card-chart-modal__close-button" />
            <ModalHeader>
              <CentererVertical style={{ fontSize: 24, paddingLeft: 50 }}>
                <ChartIcon width={24} height={24} />
                <ModalTitleLabel>{`${title}${isTTM ? ' TTM' : ''}`}</ModalTitleLabel>
                <GreyDot style={{ marginRight: 20, marginLeft: 20 }} />
                <LinkShareButton />
              </CentererVertical>
            </ModalHeader>

            <Spacer height="20px" />

            <ModalBody overflow="scroll" gap="40px" paddingBottom="40px">
              <Flexer gap="40px">
                <FlexerColumn flexGrow="1">
                  <CardChartSelectors />
                  <Spacer height="20px" />
                  <CardChartBarChart />
                </FlexerColumn>

                <StatisticsSideBar />
              </Flexer>

              <GoalsBanner revenueData={revenueData} dataFilter={dataFilter} metricKey={metricKey} />

              <StatisticsTable />

              {isRetentionMetric && <RetentionSection />}
            </ModalBody>
          </>
        )}
      </Modal>
      <LinkShareModal />
    </ModalContainer>
  );
};
