import { InfoBar } from 'components/Blocks';

export const SyncedExportModalInfo = () => (
  <InfoBar variant="tertiary">
    <p>
      Generate a unique URL (keep this URL secret!) and use the link to sync into{' '}
      <a href="https://support.google.com/docs/answer/3093335?hl=en" target="_blank" rel="noreferrer">
        Google Sheets
      </a>
      , or{' '}
      <a
        href="https://support.microsoft.com/en-us/office/import-data-from-external-data-sources-power-query-be4330b3-5356-486c-a168-b68e9e616f5a"
        target="_blank"
        rel="noreferrer"
      >
        Excel
      </a>
      . You can also create <b>as many URLs as you need!</b>
    </p>
    <p>
      Please import the URL in your external tool of choice <b>within 7 days.</b> We automatically remove unused URLs
      for security reasons.
    </p>
  </InfoBar>
);
