import styled from 'styled-components';
import { FlexerColumn, CentererVertical, FlexerRow } from 'components/Core';

export const SectionContainer = styled(FlexerColumn)`
  width: 100%;
  gap: 16px;
  border: 1px solid var(--accentGraySecond);
  border-radius: 20px;
  padding: 20px;
`;

export const MetricsCard = styled(FlexerRow)`
  width: 100%;
  justify-content: space-between;
`;

export const MetricContent = styled(FlexerColumn)`
  height: 100%;
  gap: 12px;
`;

export const Headers = styled(FlexerRow)`
  gap: 12px;
  align-items: flex-end;
`;

export const HeaderValue = styled.div`
  font-weight: 900;
  font-size: 20px;
  line-height: 26px;
`;

export const LabelContainer = styled(CentererVertical)`
  gap: 8px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: 8px;
  padding: 8px;
  font-weight: 900;
  font-size: 9px;
  align-self: flex-start;
`;

export const Customers = styled.div`
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  padding-bottom: 4px;
`;

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: var(--accentGraySecond);
`;

export const TillCanceledText = styled(CentererVertical)`
  gap: 4px;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: 16px;
  cursor: pointer;

  b {
    font-weight: 700;
  }

  span {
    font-weight: 700;
    color: var(--primaryBlue);
  }

  svg {
    width: 16px;
    height: 16px;

    g {
      opacity: 1;
    }

    path {
      fill: var(--primaryBlue);
    }
  }

  &:hover {
    span {
      color: var(--secondaryBlue);
    }

    svg {
      path {
        fill: var(--secondaryBlue);
      }
    }
  }
`;

export const TillCanceledTextContainer = styled.div`
  position: relative;
`;
