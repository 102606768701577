import { useUrlQuery } from './useUrlQuery';

export const useMVP = ({ disableInTests } = {}) => {
  const query = useUrlQuery();

  if (disableInTests && process.env.REACT_APP_RUN_ENV === 'e2e-test') return false;

  if (process.env.NODE_ENV === 'development' || process.env.REACT_APP_ENVIRONMENT === 'development') {
    // Always show in development
    return true;
  } else {
    const isMVP = query.get('isMVP');
    return !!isMVP;
  }
};
