import { arrayMove } from '@dnd-kit/sortable';
import { cloneDeep, get, isNil, omit } from 'lodash';
import { INVOICE_ITEM_TYPE_TO_KEY } from 'views/Billing/consts';

export const handleInvoiceItemsDragEnd = ({ active, over, hasGoruping, grouping, invoiceItems, invoiceItemById }) => {
  if (hasGoruping) {
    const isValidIndex = (index) => index !== -1;

    const oldGroupIndex = grouping.findIndex(
      (group) => group.id === active.id || group.invoice_items_ids.includes(active.id),
    );
    const newGroupIndex = grouping.findIndex(
      (group) => group.id === over.id || group.invoice_items_ids.includes(over.id),
    );
    const oldSubIndex = grouping[oldGroupIndex]?.invoice_items_ids?.findIndex((id) => id === active.id);
    const newSubIndex = grouping[newGroupIndex]?.invoice_items_ids?.findIndex((id) => id === over.id);

    let newGrouping = cloneDeep(grouping);
    const movingItem = grouping[oldGroupIndex]?.invoice_items_ids?.[oldSubIndex] ?? grouping[oldGroupIndex];

    // this means we are moving an invoice item
    if (typeof movingItem === 'string' || typeof movingItem === 'number') {
      if (
        // this means we are re-ordering invoice items in the same merged group
        isValidIndex(newSubIndex) &&
        oldGroupIndex === newGroupIndex &&
        grouping[newGroupIndex].isMerged
      ) {
        newGrouping[newGroupIndex].invoice_items_ids = arrayMove(
          newGrouping[newGroupIndex].invoice_items_ids,
          oldSubIndex,
          newSubIndex,
        );
      } else if (
        // this means we are moving an invoice item into a merged group
        isValidIndex(newSubIndex) &&
        oldGroupIndex !== newGroupIndex &&
        grouping[newGroupIndex].isMerged
      ) {
        const [removed] = newGrouping[oldGroupIndex].invoice_items_ids.splice(oldSubIndex, 1);
        newGrouping[newGroupIndex].invoice_items_ids.splice(newSubIndex, 0, removed);
      } else if (
        // this means we are moving an invoice item from a merged group
        grouping[oldGroupIndex].isMerged
      ) {
        const [removed] = newGrouping[oldGroupIndex].invoice_items_ids.splice(oldSubIndex, 1);
        newGrouping.splice(newGroupIndex, 0, { invoice_items_ids: [removed] });
      } else if (
        // anything else is just re-ordering in the higher group level
        oldGroupIndex !== newGroupIndex
      ) {
        newGrouping = arrayMove(newGrouping, oldGroupIndex, newGroupIndex);
      }
    } else {
      // when users move a merged group, we just re-order from the higher group level
      if (oldGroupIndex !== newGroupIndex) {
        newGrouping = arrayMove(newGrouping, oldGroupIndex, newGroupIndex);
      }
    }
    newGrouping = newGrouping.filter((group) => group.invoice_items_ids.length).map((group) => omit(group, 'id'));

    return {
      grouping: newGrouping,
      invoiceItems: newGrouping.flatMap((group) => group.invoice_items_ids.map((id) => invoiceItemById[id])),
    };
  }

  if (active.id !== over.id) {
    const newInvoiceItems = [...invoiceItems];
    const newInvoiceItemsIds = newInvoiceItems.map((item) => item.id);
    const oldIndex = newInvoiceItemsIds.indexOf(active.id);
    const newIndex = newInvoiceItemsIds.indexOf(over.id);

    return {
      invoiceItems: arrayMove(newInvoiceItems, oldIndex, newIndex),
    };
  }

  return {};
};

export const getFieldChangesProps = ({
  values,
  field,
  index,
  toolTipWidth = 150,
  tooltipInputDisplay,
  isIdValues,
  options,
}) => {
  const oldValue = get(values?.changedFieldsWithOldValues, field);
  const newValue = get(values, field);

  const isTransactionItemAdded =
    !!values?.id &&
    field?.includes('invoice_items') &&
    field?.includes('transaction_id') &&
    !isNil(index) &&
    !values?.invoice_items?.[index]?.created_at;

  const isInvoiceItemAdded = Object.values(INVOICE_ITEM_TYPE_TO_KEY).some(
    (key) => field?.includes(key) && values?.changedFieldsWithOldValues?.[key] === 'N/A',
  );

  const fieldChanged = (!isNil(oldValue) && oldValue !== newValue) || !!isTransactionItemAdded || !!isInvoiceItemAdded;

  return fieldChanged
    ? {
        isChanged: true,
        toolTipWidth,
        tooltipInputDisplay: tooltipInputDisplay ?? (
          <>
            {oldValue ? (
              <>
                <div>Old value:</div>
                <div>
                  {isIdValues && !!options ? options.find((option) => option.value === oldValue)?.label : oldValue}
                </div>
              </>
            ) : isTransactionItemAdded || isInvoiceItemAdded ? (
              <>
                <div>Item will be added</div>
              </>
            ) : null}
          </>
        ),
      }
    : {};
};
