import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as RotateIcon } from 'images/rotate-clockwise.svg';
import { AppContext } from 'AppContext';
import { useAPIKeyAPI } from 'api/api_key';
import { FlexBetweenContainer, Flexer, Spacer } from 'components/Core';
import { InlineButton } from 'components/Buttons';
import { ClipboardField } from 'components/Controls';
import { CircleLoader } from 'components/Loaders';

// TODO: Change to External Sources generic cards in
// https://github.com/ChifferCo/chiffer/issues/2138
const Card = styled.div`
  width: 770px;
  padding: 28px;
  background: #ffffff;
  margin: 20px 0;

  box-shadow: 8px 8px 60px rgba(0, 21, 46, 0.05);
  border-radius: 20px;
`;

const CardTitle = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: var(--primaryBlack);
`;

const RotateButton = styled(InlineButton)`
  display: flex;
  align-items: center;

  svg {
    margin-right: 5px;

    path {
      fill: var(--primaryGreen);
    }
  }
`;

export const APIKeyCard = () => {
  const { orgId } = useContext(AppContext);
  const {
    data,
    isLoading,
    isFetching,
    operations: { rotateAPIKey },
  } = useAPIKeyAPI({ orgId });
  const [hasRotated, setHasRotated] = useState(false);

  const handleRotateClick = async () => {
    setHasRotated(true);
    await rotateAPIKey({ orgId });
  };

  return (
    <Card>
      <Flexer direction="column">
        <FlexBetweenContainer>
          <CardTitle>
            Managing <b>Subscript API key</b>
          </CardTitle>
          <RotateButton onClick={handleRotateClick}>
            <RotateIcon width="15px" height="15px" />
            Rotate API Key
          </RotateButton>
        </FlexBetweenContainer>
        <Spacer height="24px" />
        {(isLoading || isFetching) && <CircleLoader width="30px" height="30px" />}
        {!(isLoading || isFetching) && (
          <ClipboardField
            name="api-key"
            value={data?.api_key ?? 'No api_key. Please rotate to get a new one!'}
            isSecret={true}
            showByDefault={hasRotated}
          />
        )}
      </Flexer>
    </Card>
  );
};
