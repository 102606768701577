import React from 'react';
import styled from 'styled-components';

import Toast from './Toast';

export const ToastsWrapper = styled.div`
  position: fixed;
  bottom: 25px;
  right: 25px;
  width: 500px;
  max-width: 90%;
  z-index: 999;
`;

export const Toasts = ({ toasts, removeToast }) => (
  <ToastsWrapper data-cy="toasts-container">
    {toasts.map((toast) => (
      <Toast key={toast.id} toast={toast} onDismiss={removeToast} />
    ))}
  </ToastsWrapper>
);
