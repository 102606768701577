import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Row } from 'components/Core';
import { BackButton } from 'components/Buttons';
import { Spacer } from 'components/Core';
import { HeaderContainer, HeaderTitle } from './style';

export const HEADER_TITLES = {
  dashboard: 'Subscription Metrics',
  waterfall: 'Revenue Waterfall',
  revenue: 'Revenue Details',
  spreads: 'Revenue Spread',
  cohorts: 'Cohorts',
  billing: 'Billing',
  billingReviewTransactions: 'Transactions that need schedules (invoices)',
  forecasting: 'Forecasting (beta)',
  chart: 'Chart Visualizer',
  reports: 'Saved Reports',
  transactions: 'Transactions',
  transactionsAuto: 'Automatic Confirmations',
  transactionsArchive: 'Transaction Archive',
  revenueDetails: 'Revenue Details',
  customers: 'Customers',
  members: 'Members',
  configure: 'Configuration',
  // Forecasts in Subscription Metrics
  forecasts: 'Forecasts',
  csv: 'Uploading CSV',
};

export const Header = ({
  activePage,
  headerTitle,
  backButton,
  onBackButtonClick,
  titleActions,
  margin,
  headerLeft,
  headerRight,
}) => {
  const activePageHeaderTitle = headerTitle ?? HEADER_TITLES[activePage] ?? 'Subscription Metrics';

  useEffect(() => {
    document.title = `Subscript — ${activePageHeaderTitle}`;
  }, [activePageHeaderTitle]);

  return (
    <HeaderContainer margin={margin}>
      <Row horizontal="flex-start">
        {backButton && (
          <>
            <BackButton onClick={onBackButtonClick} data-cy="page-header-back-button" />
            <Spacer width="14px" />
          </>
        )}
        {headerLeft ?? (
          <>
            <HeaderTitle>{activePageHeaderTitle}</HeaderTitle>
            {titleActions}
          </>
        )}
      </Row>
      <Row horizontal="flex-end">{headerRight}</Row>
    </HeaderContainer>
  );
};

Header.propTypes = {
  /**
   * The currently active page
   */
  activePage: PropTypes.string,
  /**
   * The header title (automatically inferred from activePage if not provided)
   */
  headerTitle: PropTypes.string,
  /**
   * Show back button?
   */
  backButton: PropTypes.bool,
  /**
   * Back button click
   */
  onBackButtonClick: PropTypes.func,
  /**
   * Left justified header element
   */
  headerLeft: PropTypes.element,
  /**
   * Right justified header element
   */
  headerRight: PropTypes.element,
};

const HeaderWrapper = ({
  activePage,
  headerTitle,
  headerSubtitle,
  backButton,
  titleActions,
  history,
  margin,
  headerLeft,
  headerRight,
}) => (
  <Header
    activePage={activePage}
    headerTitle={headerTitle}
    headerSubtitle={headerSubtitle}
    backButton={backButton}
    onBackButtonClick={history.goBack}
    titleActions={titleActions}
    margin={margin}
    headerLeft={headerLeft}
    headerRight={headerRight}
  />
);

export default withRouter(HeaderWrapper);
