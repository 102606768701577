import styled from 'styled-components';

export const NonEnterableDates = styled.span`
  color: rgba(0, 21, 46, 0.5);
  margin-right: 8px;
`;

export const SelectorCard = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;

  background: #ffffff;
  border: 1px solid var(--accentGraySecond);
  border-radius: 12px;
`;
