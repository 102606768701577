export const SENDER_OPTIONS = {
  GL: 'gl',
  CUSTOM_EMAIL: 'custom_email',
  SUBSCRIPT_EMAIL: 'subscript_email',
};

export const SENDER_MAILER_OPTIONS = {
  SUBSCRIPT: 'subscript',
  GMAIL: 'gmail',
};

export const SENDER_DEFAULT_EMAIL = 'billing@subscript.com';

// Most popular Google Fonts https://fonts.google.com/analytics
export const DEFAULT_FONT_OPTIONS = [
  { value: 'Nunito Sans', label: 'Nunito Sans' },
  { value: 'Roboto', label: 'Roboto' },
  { value: 'Open Sans', label: 'Open Sans' },
  { value: 'Lato', label: 'Lato' },
  { value: 'Montserrat', label: 'Montserrat' },
  { value: 'Oswald', label: 'Oswald' },
  { value: 'Raleway', label: 'Raleway' },
  { value: 'Poppins', label: 'Poppins' },
  { value: 'Noto Sans', label: 'Noto Sans' },
  { value: 'Source Sans Pro', label: 'Source Sans Pro' },
  { value: 'Lora', label: 'Lora' },
  { value: 'Merriweather', label: 'Merriweather' },
  { value: 'Playfair Display', label: 'Playfair Display' },
  { value: 'Crimson Text', label: 'Crimson Text' },
  { value: 'Josefin Sans', label: 'Josefin Sans' },
  { value: 'Ubuntu', label: 'Ubuntu' },
  { value: 'Karla', label: 'Karla' },
];
