import { useContext } from 'react';
import { Row } from 'components/Core';
import { SNAPSHOT_METRICS } from 'consts/snapshotMetrics';
import { TooltipContainer } from 'components/Tooltip';
import { TableRowTitle } from 'components/Table';
import { GoalsContext } from 'shared/Goals';

import { TitleTextContainer } from './styled';
import { SelectGoalDropdown } from './Goals/SelectGoalDropdown';
import { GoalRowTitleName } from './Goals/GoalRowTitleName';
import { PeriodGrowthDropdown } from './PeriodGrowthDropdown';

export const RowTitleDisplay = ({ element }) => {
  const { goals, selectedGoals } = useContext(GoalsContext);
  const goalsForMetric = goals?.[element.title] ?? {};
  const selectedGoalsForMetric = selectedGoals[element.title] ?? [];

  return (
    <TableRowTitle autoWidth>
      <Row horizontal="flex-start">
        <TooltipContainer
          width={200}
          isVisible={!!element.tooltipText}
          toolTipContent={element.tooltipText}
          underline={false}
        >
          <TitleTextContainer>{element.title}</TitleTextContainer>
        </TooltipContainer>
        {[SNAPSHOT_METRICS.RECURRING_PERIOD_GROWTH.label, SNAPSHOT_METRICS.TOTAL_PERIOD_GROWTH.label].includes(
          element.title,
        ) && <PeriodGrowthDropdown name={`${element.title}-row`} />}
        {Object.keys(goalsForMetric).length > 0 && <SelectGoalDropdown metric={element.title} />}
      </Row>
      {selectedGoalsForMetric.map((goalId) => (
        <GoalRowTitleName key={goalId} name={goalsForMetric[goalId]?.name} />
      ))}
    </TableRowTitle>
  );
};
