import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { isEqual } from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';
import { AppContext } from 'AppContext';
import { clearCachedMetrics } from 'utils/cachedMetricsUtils';
import { PAGE_CONTEXT_VALUES } from 'utils/hooks';
import { deepEqual } from 'utils/objectUtils';
import { capitalize } from 'utils/stringUtils';
import { REPORT_OPTIONS_ROUTE } from 'shared/Reports/consts';
import { convertDateValues } from './utils';
import { pageStateToReportState } from './stateUtils';

export const useNavigateToReportPage = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const { setMetadataFilter } = useContext(AppContext);

  const navigateToReportPage = useCallback(
    ({ report, isNewReport = false }) => {
      clearCachedMetrics();
      // [JB 2022-08-03] Clear metadataFilter in AppContext before navigation to avoid setMetadataFilter
      // collision between setMetadataFilter in HeaderReports and MetadataFilter components
      // TODO: When we are sure we want to have saved reports only as a page move set pageState logic out of HeadersReports to here
      setMetadataFilter({});
      let route = REPORT_OPTIONS_ROUTE[report.type];

      // We need this for when report options is already the same as page state
      // Otherwise the url will be empty
      if (REPORT_OPTIONS_ROUTE[report.type] === pathname) {
        route += `?${new URLSearchParams(window.location.search).toString()}`;
      }
      history.push(route, { report, isNewReport });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [history, pathname],
  );

  return { navigateToReportPage };
};

// This hook recieves the report and sets all filters necessary on the page
export const useReport = ({ report, page, pageState, keepAppState = false }) => {
  const [reportData, setReportData] = useState(report);

  const pageReportState = useMemo(() => pageStateToReportState({ page, pageState }), [page, pageState]);

  useEffect(() => {
    setReportData(report);

    // This will set the page according to the saved report
    if (report?.options && !keepAppState) {
      const options = convertDateValues(report.options);
      [
        ...PAGE_CONTEXT_VALUES[page].pageStates,
        ...Object.keys(PAGE_CONTEXT_VALUES[page].customAppSettingsStates),
      ].forEach((key) => {
        if (!deepEqual(pageReportState[key], options[key], { excludeNil: true })) {
          if (key === 'dataFilter') {
            const changes = Object.keys(options[key] ?? {}).reduce((acc, dataFilterKey) => {
              if (!isEqual(pageReportState[key][dataFilterKey], options[key][dataFilterKey])) {
                acc[dataFilterKey] = options[key][dataFilterKey];
              }
              return acc;
            }, {});
            pageState?.setDataFilter(changes);
          } else if (options.hasOwnProperty(key)) {
            pageState[`set${capitalize(key)}`](options[key]);
          } else {
            // It is a new key that was never before on the saved report
            // We will default to the pageState for that key
            pageState[`set${capitalize(key)}`](pageState[key]);
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [report]);

  return {
    reportData,
    setReportData,
    pageReportState,
  };
};
