import React, { useContext, useMemo } from 'react';
import dayjs from 'dayjs';

import { AppContext } from 'AppContext';
import { SNAPSHOT_METRICS } from 'consts/snapshotMetrics';
import { generateMonths } from 'utils/dateUtils';
import { ReactComponent as CircleIcon } from 'images/reports-circle.svg';
import { ChartVisualizerChart } from 'views/ChartVisualizer/ChartVisualizerChart';
import { CHART_COLORS } from 'views/ChartVisualizer/utils';
import { DashboardContext } from '../DashboardContext';
import { Card, CardHeader } from '../Common/Card';
import { PeriodGrowthDropdown } from '../Common/PeriodGrowthDropdown';
import { HeaderWrapper, HugeChartBackground } from './styles';
import { calculateRevenueNURDCStats, calculateSaasStats } from 'views/Dashboard/DashboardTables/DashboardTables.utils';

const convertToSnapshotMetric = (metric) => `${metric.id.toUpperCase()}${metric.isTTM ? '_TTM' : ''}`;

export const getDataMonthValue = ({ data, metric, month }) => {
  const { metricCategoryKey, monthDataKey } = SNAPSHOT_METRICS[convertToSnapshotMetric(metric)];
  const monthData = data[metricCategoryKey]?.[month] ?? {};
  return monthData[monthDataKey]?.value ?? monthData[monthDataKey] ?? 0;
};

const getMetricsStartingAndCurrentValues = ({ selectedMetrics, dataForVisualizer, months }) => {
  if (selectedMetrics && !Object.values(dataForVisualizer).some((value) => !value)) {
    return selectedMetrics.map((metric) => ({
      currentValue: getDataMonthValue({
        data: dataForVisualizer,
        metric,
        month: months[months.length - 1],
      }),
      startingValue: getDataMonthValue({
        data: dataForVisualizer,
        metric,
        month: dayjs
          .utc(months[months.length - 1])
          .subtract(1, 'year')
          .format('YYYY-MM'),
      }),
    }));
  }
  return [];
};

export const DashboardChartVisualizerCard = ({
  rowStart,
  columnStart,
  size,
  selectedMetrics,
  SettingsButton,
  onTTMClick,
  version,
  AddGridCardButton,
  children,
}) => {
  const {
    dataFilter,
    appSettings: { currencyISOCode },
  } = useContext(AppContext);
  const {
    loading,
    isARR,
    growthType,
    revenueNURDCStats: revenueStats,
    seatNURDCStats: seatStats,
    customerNURDCStats: customerStats,
    costStats,
    saasStats,
    hasConfirmedTransactions,
  } = useContext(DashboardContext);
  const { startMonth: startDate, endMonth: endDate } = dataFilter;
  const months = generateMonths({ startDate, endDate, inclusive: true });

  const calculatedRevenueNURDCStats = useMemo(
    () => calculateRevenueNURDCStats({ isARR, revenueNURDCStats: revenueStats }),
    [isARR, revenueStats],
  );

  const calculatedSaasStats = useMemo(() => calculateSaasStats({ isARR, saasStats }), [isARR, saasStats]);

  const dataForVisualizer = useMemo(
    () => ({
      revenueStats: calculatedRevenueNURDCStats,
      seatStats,
      customerStats,
      costStats,
      saasStats: calculatedSaasStats,
    }),
    [calculatedRevenueNURDCStats, seatStats, customerStats, costStats, calculatedSaasStats],
  );

  const metrics = useMemo(
    () =>
      getMetricsStartingAndCurrentValues({
        selectedMetrics,
        dataForVisualizer,
        months,
      }),
    [selectedMetrics, dataForVisualizer, months],
  );

  return (
    <Card
      title="Chart Visualizer"
      rowStart={rowStart}
      columnStart={columnStart}
      size={size}
      loading={loading}
      hasConfirmedTransactions={hasConfirmedTransactions}
      clickable={false}
      version={version}
      SettingsButton={SettingsButton}
      AddGridCardButton={AddGridCardButton}
    >
      <HeaderWrapper>
        {metrics.map((metric, index) => (
          <CardHeader
            key={selectedMetrics[index].id}
            isTTM={selectedMetrics[index].isTTM}
            title={SNAPSHOT_METRICS[selectedMetrics[index].id.toUpperCase()]?.label}
            titleIcon={<CircleIcon width={8} height={8} fill={CHART_COLORS[index]} />}
            titleTooltip={SNAPSHOT_METRICS[selectedMetrics[index].id.toUpperCase()]?.description({ isARR, growthType })}
            valueType={SNAPSHOT_METRICS[selectedMetrics[index].id.toUpperCase()]?.dataType}
            currentValue={metric?.currentValue ?? 0}
            startingValue={metric?.startingValue ?? 0}
            graphLabels={months}
            hasConfirmedTransactions={hasConfirmedTransactions}
            size={size}
            ActionButton={
              ['recurring_period_growth', 'total_period_growth'].includes(selectedMetrics[index].id) && (
                <PeriodGrowthDropdown
                  name={`${selectedMetrics[index].id}-chart-visualizer-card`}
                  showHasButton={true}
                />
              )
            }
            onTTMClick={onTTMClick}
          />
        ))}
      </HeaderWrapper>
      <HugeChartBackground>
        {!(loading || Object.values(dataForVisualizer).some((value) => !value)) && (
          <ChartVisualizerChart
            selectedMetrics={selectedMetrics.map(convertToSnapshotMetric)}
            dataForVisualizer={dataForVisualizer}
            months={months}
            includeLegend={false}
            standalone={false}
            currency={currencyISOCode}
          />
        )}
      </HugeChartBackground>
      {children}
    </Card>
  );
};
