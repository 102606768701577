import React from 'react';
import styled from 'styled-components';
import { ReactComponent as ExternalLink } from 'images/link-share.svg';

const StyledButton = styled.button`
  font-weight: 900;
  font-size: 10px;
  line-height: 14px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #00152e;
  cursor: pointer;
  background: transparent;
  border: none;

  svg {
    margin-left: 10px;
    position: relative;
    top: -2px;
  }
`;

export const TransactionModalOpenerButton = ({ children, onClick }) => {
  return (
    <StyledButton onClick={onClick} data-cy="transaction-modal--open">
      {children}
      <ExternalLink fill="var(--primaryBlue)" />
    </StyledButton>
  );
};
