import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { getRateByCurrencyAndDate } from 'api/exchangeRates/requests';

dayjs.extend(utc);

export const getRowWithOnlyCurrenciesKeys = ({ row }) => {
  const currenciesRow = structuredClone(row);
  delete currenciesRow.date;
  delete currenciesRow.rowIndex;

  return currenciesRow;
};

export const getFromAndToCurrenciesFromKey = ({ key }) => {
  const [from_currency, to_currency] = key.split('→');

  return { from_currency, to_currency };
};

export const getRatesDataForTable = ({ data }) => {
  if (data) {
    //add all currencies in one day row
    const ratesByDate = data?.reduce((acc, curr) => {
      const formattedDate = dayjs.utc(curr?.date).format('YYYY-MM-DD');
      acc[formattedDate] = {
        ...(acc?.[formattedDate] ?? {}),
        [`${curr?.from_currency}→${curr?.to_currency}`]: curr?.rate,
      };
      return acc;
    }, {});

    //back to array of objects for table
    return Object.entries(ratesByDate).reduce(
      (acc, [key, value], index) => [...acc, { date: key, ...value, rowIndex: index }],
      [],
    );
  }
};
export const getExchangeRatesForCreate = ({ values, orgId }) =>
  values?.rates?.reduce((acc, exchangeRateRow) => {
    const rowDate = exchangeRateRow?.date;
    const rowWithOnlyCurrenciesKeys = getRowWithOnlyCurrenciesKeys({ row: exchangeRateRow }) ?? {};

    Object.entries(rowWithOnlyCurrenciesKeys)?.forEach(([currencyKey, rate]) => {
      const { from_currency, to_currency } = getFromAndToCurrenciesFromKey({ key: currencyKey });

      acc.push({
        date: rowDate,
        rate: rate ?? null,
        from_currency,
        to_currency,
        organization_id: orgId,
      });
    });
    return acc;
  }, []);

export const getCurrenciesKeys = ({ currencies, homeCurrency }) => {
  const currenciesKeys = new Set();

  currencies?.forEach((currency) => {
    if (currency !== homeCurrency) {
      currenciesKeys.add(`${currency}→${homeCurrency}`);
    }
  });

  return Array.from(currenciesKeys).sort();
};

export const fillExchangeRates = async ({ formRef, row, currenciesKeys, orgId }) => {
  const fromKeys = currenciesKeys.map((key) => getFromAndToCurrenciesFromKey({ key })?.from_currency);
  const rateRows = await getRateByCurrencyAndDate({
    orgId,
    params: { date: row?.original?.date, fromCurrencies: fromKeys, scopes: ['only_default_rates'] },
  });
  for (const rateRow of rateRows) {
    const rate = rateRow?.rate ?? null;
    formRef?.current &&
      formRef?.current?.setFieldValue(
        `rates[${row.original.rowIndex}].${rateRow.from_currency}→${rateRow.to_currency}`,
        rate,
      );
  }
};
