import axios from 'axios';
import { API_URL } from 'api/consts';

export const getOrganizations = async ({ includeDefaultDashboard } = { includeDefaultDashboard: false }) => {
  const { data } = await axios.get(`${API_URL}/organizations/`, {
    params: {
      includeProducts: true,
      includeProductsTransactionsCount: true,
      includeOrgCounters: true,
      includeAllowsManualExpenses: true,
      includeHasCostAttribution: true,
      includeHasSpreadExternalUpdates: true,
      includeDefaultDashboard,
      includeUsesMetricsEngine: true,
      includeCurrencies: true,
      includeHasAccountingSpreads: true,
    },
  });

  return data;
};

export const getCounts = async ({ orgId, scopes }) => {
  const { data } = await axios.get(`${API_URL}/organizations/${orgId}/count`, {
    params: {
      scopes,
    },
  });

  return data;
};

export const getOrganizationMetadata = async ({ orgId }) => {
  const { data } = await axios.get(`${API_URL}/organizations/${orgId}/metadata`);

  return data;
};
