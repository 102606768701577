import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useProductsAPI } from 'api/products';
import { AppContext } from 'AppContext';
import { ReactComponent as ListArrow } from 'images/arrow-narrow-right-list.svg';
import { ReactComponent as DangerIcon } from 'images/alert-circle-csv.svg';
import { pluralize } from 'utils/stringUtils';
import { Banner, BannerText, BannerActions, BannerTitle } from 'components/Blocks/Banner';
import { RecognitionSelect } from 'shared/TransactionContent/TransactionBody/TransactionRecognitionSection/RecognitionSelect';
import { StyledBannerButton } from './styles';

const BannerList = styled.div`
  font-size: 12px;
  line-height: 16px;
  margin-top: 18px;
  padding-left: 8px;
`;

const ListItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  svg {
    margin: 0 4px;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const ProductsRecognitionBanner = () => {
  const { organizations } = useContext(AppContext);
  const {
    operations: { bulkUpdate },
  } = useProductsAPI({ orgId: organizations?.[0].id, autoFetch: false });

  const [productsWithoutRecognition, setProductsWithoutRecognition] = useState();

  useEffect(() => {
    setProductsWithoutRecognition(
      organizations?.[0]?.products?.filter(
        (product) =>
          (!product.recognition || product.recognition === 'null') && Number(product?.transactions_count ?? 0) > 0,
      ) ?? [],
    );
  }, [organizations]);

  return productsWithoutRecognition?.length ? (
    <Banner redVersion noHover>
      <BannerTitle redVersion>Products with no recognition</BannerTitle>
      <BannerText style={{ marginBottom: 16 }}>
        Subscript can’t analyze or confirm{' '}
        {pluralize(
          productsWithoutRecognition?.reduce((acc, curr) => acc + Number(curr?.transactions_count), 0),
          'transaction',
        )}{' '}
        because of null recognition.
        <br />
        Please <b>set the recognition policy</b> for the following products:
        <BannerList>
          {productsWithoutRecognition.map((product) => (
            <ListItem key={product.id} data-cy={`products-banner__product-${product.id}`}>
              – {product.name}
              <ListArrow />
              <RecognitionSelect
                dataCy="products-banner__recognition-selector-toggler"
                bannerVersion
                values={{ recognition: product.recognition }}
                onSelectRecongition={(recognition) =>
                  setProductsWithoutRecognition((prevProducts) =>
                    prevProducts.map((prevProduct) => {
                      if (prevProduct.id === product.id) {
                        return { ...prevProduct, recognition };
                      } else {
                        return prevProduct;
                      }
                    }),
                  )
                }
              />
              {!product.recognition && <DangerIcon width={16} height={16} />}
            </ListItem>
          ))}
        </BannerList>
      </BannerText>
      <BannerActions>
        <StyledBannerButton
          onClick={() =>
            bulkUpdate.mutateAsync({
              productIds: productsWithoutRecognition.map((product) => product.id),
              data: {
                recognitionsById: productsWithoutRecognition.reduce((acc, product) => {
                  acc[product.id] = product.recognition;
                  return acc;
                }, {}),
              },
            })
          }
        >
          apply changes
        </StyledBannerButton>
      </BannerActions>
    </Banner>
  ) : null;
};
