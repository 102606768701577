export const INITIAL_EVENT = {
  date: new Date(),
  amount: 0,
  seats: 0,
  percent: 0,
};

export const INITIAL_SPREAD_VALUE = {
  recognitionEvents: [
    {
      ...INITIAL_EVENT,
    },
  ],
};

export const CHANGE_TYPES = {
  NEW: 'new',
  CHANGED: 'changed',
  DELETED: 'deleted',
  SAME: 'same',
};
