import dayjs from 'dayjs';
import { ErrorContainer, LineItemErrors } from './styles';

const groupErrorsByInvoicesAndItems = (errors, invoices) => {
  if (!invoices?.length || !errors?.length) return [];

  return Object.keys(errors).flatMap((key) => {
    const invoiceKey = parseInt(key);
    const invoice = errors[key];
    const date = invoices[invoiceKey]?.date;

    const lineItemErrors =
      invoice?.invoice_items
        ?.map((item, index) => {
          if (!item) return null;
          const errorMessage = Object.values(item).join('; ');
          return { itemNumber: index + 1, error: errorMessage };
        })
        ?.filter(Boolean) ?? [];

    if (invoice?.amount) {
      lineItemErrors?.push({ error: invoice?.amount });
    }

    return {
      key: `invoice.${invoiceKey}.items`,
      invoiceDate: `Invoice ${invoiceKey + 1} on ${dayjs.utc(date).format('MMM D, YYYY')}`,
      lineItemErrors,
    };
  });
};

export const InvoicingScheduleFormErrors = ({ errors, invoices }) => {
  const formattedErrors = groupErrorsByInvoicesAndItems(errors, invoices);
  return (
    <ErrorContainer data-cy="billing__invoice-schedule-modal__form-errors">
      Errors found, please check the following:
      {formattedErrors.map(({ key, invoiceDate, lineItemErrors }) =>
        lineItemErrors?.length ? (
          <li key={key} data-cy={`billing__invoice-schedule-modal__form-errors__invoice-${invoiceDate}`}>
            {invoiceDate}
            <LineItemErrors>
              {lineItemErrors.map(({ itemNumber, error }) => (
                <li
                  key={itemNumber}
                  data-cy={`billing__invoice-schedule-modal__form-errors__invoice-item-${itemNumber}`}
                >
                  {itemNumber ? `On item ${itemNumber}:` : null} {error}
                </li>
              ))}
            </LineItemErrors>
          </li>
        ) : null,
      )}
    </ErrorContainer>
  );
};
