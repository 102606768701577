import { TooltipContainer } from 'components/Tooltip';
import { StyledButton, CircleText } from '../styles';

export const FilterButton = ({ state, onClick, isAllUpdatesButton, excludable = true, name, children }) => {
  const included = state === true;
  const excluded = excludable && state === false && !isAllUpdatesButton;
  const toolTipContent = included
    ? 'Click again to exclude'
    : excluded
    ? 'Click again to skip filtering'
    : 'Click to include';

  const button = (
    <StyledButton
      onClick={onClick}
      fillColor={included ? 'var(--primaryBlue10)' : excluded ? 'var(--primaryRed10)' : 'var(--primaryBlack5)'}
      borderColor={included ? 'var(--primaryBlue)' : excluded ? 'var(--primaryRed)' : 'var(--primaryBlack5)'}
      textColor={included ? 'var(--primaryBlue)' : excluded ? 'var(--primaryRed)' : 'var(--primaryBlack)'}
      data-cy={`external-updates__filter-button--${name}`}
    >
      {excludable && included && !isAllUpdatesButton && <CircleText variant="blue">in</CircleText>}
      {excludable && excluded && <CircleText variant="red">ex</CircleText>}
      {children}
    </StyledButton>
  );

  return excludable ? (
    <TooltipContainer isVisible={!isAllUpdatesButton} width={125} toolTipContent={toolTipContent}>
      {button}
    </TooltipContainer>
  ) : (
    button
  );
};
