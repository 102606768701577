import React, { useContext } from 'react';
import { useFormikContext } from 'formik';

import { AppContext } from 'AppContext';
import { CREDIT_NOTE_DYNAMIC_TAGS, DEFAULT_AVAILABLE_TAGS } from 'consts/billing';
import { templateToHTMLString } from 'utils/htmlUtils';
import { InvoicingScheduleContext } from '../InvoicingScheduleModal/InvoicingScheduleContext';
import { TrashIcon, RefreshIcon } from 'components/Icons';
import {
  FILE_TYPES,
  FileUploader,
  FormikCustomInput,
  FormikEmailSelector,
  SwitchWithLabel,
  formatFileSize,
} from 'components/Controls';
import { CentererVertical, FlexerColumn, FlexerRow, LabelText } from 'components/Core';
import { TooltipContainer } from 'components/Tooltip';
import { FileCard } from 'views/Configuration/Tabs/SubscriptBillingTab/styles';

import { BillingDynamicFieldsTooltip, DYNAMIC_FIELD_TOOLTIP_SECTIONS } from '../BillingDynamicFieldsTooltip';
import { getConfigSenderEmail } from '../utils';
import { InvoiceSenderEmail } from '../InvoicePreviewModalV2/InvoiceSenderEmail';
import { CREDIT_NOTE_METADATA } from '../consts';

export const CreditNoteDetailsForm = () => {
  const { orgConfigs } = useContext(AppContext);
  const { transactionMetadataTags, customerMetadataTags } = useContext(InvoicingScheduleContext);
  const { setFieldValue, values: creditNoteFormValues } = useFormikContext();

  const { billingCreditNoteDefaults } = orgConfigs;

  const senderEmail = getConfigSenderEmail({ orgConfigs });

  const defaultInvoiceAttachments = [{ name: 'Credit-Note.pdf' }];

  const handleAddCreditNoteAttachment = (file) => {
    setFieldValue('attachments', [
      ...(creditNoteFormValues.attachments ?? []),
      {
        name: file.name,
        s3_file_key: file.s3FileKey,
        size: file.size,
        type: file.type,
      },
    ]);
  };
  const handleDeleteCreditNoteAttachment = (index) => {
    const attachments = [...(creditNoteFormValues.attachments ?? [])];
    attachments.splice(index, 1);
    setFieldValue('attachments', attachments);
  };

  const availableTags = [
    ...DEFAULT_AVAILABLE_TAGS,
    ...CREDIT_NOTE_DYNAMIC_TAGS,
    ...transactionMetadataTags,
    ...customerMetadataTags,
  ];

  return (
    <FlexerColumn width="100%" height="100%" gap="16px" alignItems="flex-start">
      <FlexerRow width="100%" gap="16px">
        <FormikEmailSelector
          containerWidth="100%"
          name="send_to"
          label="Recipient email(s)"
          value={creditNoteFormValues.send_to?.filter((e) => typeof e === 'string')} // Strip out any non-string values
          onChange={(selectedValues) => setFieldValue('send_to', selectedValues)}
        />
      </FlexerRow>

      <FlexerRow width="100%" gap="16px">
        <FormikEmailSelector
          containerWidth="100%"
          name="send_cc"
          label="Email(s) to cc"
          value={creditNoteFormValues.send_cc}
          onChange={(selectedValues) => setFieldValue('send_cc', selectedValues)}
        />
      </FlexerRow>

      <FlexerRow width="100%" gap="16px">
        <FormikCustomInput
          data-cy="credit-note-send-modal__email-subject"
          width="100%"
          name="email_subject"
          disableLabelOpacity
          label={
            <CentererVertical gap="8px">
              <span>Email subject</span>
              {/* TODO: preview replaced variables */}
            </CentererVertical>
          }
          LabelAction={
            <CentererVertical gap="8px">
              <TooltipContainer toolTipContent="Reset to default email subject" width={150}>
                <RefreshIcon
                  onClick={() => setFieldValue('email_subject', billingCreditNoteDefaults?.email_subject ?? '')}
                  data-cy="credit-note-send-modal__email-subject--reset-button"
                />
              </TooltipContainer>
              <BillingDynamicFieldsTooltip
                tags={{
                  [DYNAMIC_FIELD_TOOLTIP_SECTIONS.GENERAL_TAGS]: [
                    ...DEFAULT_AVAILABLE_TAGS,
                    ...CREDIT_NOTE_DYNAMIC_TAGS,
                  ],
                  [DYNAMIC_FIELD_TOOLTIP_SECTIONS.TRANSACTION_METADATA_TAGS]: transactionMetadataTags,
                  [DYNAMIC_FIELD_TOOLTIP_SECTIONS.CUSTOMER_METADATA_TAGS]: customerMetadataTags,
                }}
                XOffset={200}
              />
            </CentererVertical>
          }
          minHeight="40px"
          HTMLString={templateToHTMLString({
            text: creditNoteFormValues?.email_subject,
            availableTags,
          })}
          availableTags={availableTags}
          type="HTMLEdit"
        />
      </FlexerRow>

      <FlexerRow width="100%" gap="16px">
        <FlexerColumn width="100%" height="100%" gap="16px">
          <FormikCustomInput
            data-cy="credit-note-send-modal__email-body"
            name="email_body"
            disableLabelOpacity
            label={
              <CentererVertical gap="8px">
                <span>Email body</span>
                {/* TODO: preview replaced variables */}
              </CentererVertical>
            }
            LabelAction={
              <CentererVertical gap="8px">
                <TooltipContainer toolTipContent="Reset to default email body" width={150}>
                  <RefreshIcon
                    onClick={() => setFieldValue('email_body', billingCreditNoteDefaults?.email_body ?? '')}
                    data-cy="credit-note-send-modal__email-body--reset-button"
                  />
                </TooltipContainer>
                <BillingDynamicFieldsTooltip
                  tags={{
                    [DYNAMIC_FIELD_TOOLTIP_SECTIONS.GENERAL_TAGS]: [
                      ...DEFAULT_AVAILABLE_TAGS,
                      ...CREDIT_NOTE_DYNAMIC_TAGS,
                    ],
                    [DYNAMIC_FIELD_TOOLTIP_SECTIONS.TRANSACTION_METADATA_TAGS]: transactionMetadataTags,
                    [DYNAMIC_FIELD_TOOLTIP_SECTIONS.CUSTOMER_METADATA_TAGS]: customerMetadataTags,
                  }}
                />
              </CentererVertical>
            }
            minHeight="240px"
            HTMLString={templateToHTMLString({
              text: creditNoteFormValues?.email_body,
              availableTags,
            })}
            availableTags={availableTags}
            type="HTMLEdit"
          />
        </FlexerColumn>

        <FlexerColumn width="320px" gap="16px">
          <InvoiceSenderEmail senderEmail={senderEmail ?? undefined} />

          <FlexerColumn>
            <LabelText>
              Attachments (
              {(creditNoteFormValues?.metadata?.[CREDIT_NOTE_METADATA.EXCLUDE_PDF]
                ? defaultInvoiceAttachments.length - 1
                : defaultInvoiceAttachments.length) + (creditNoteFormValues.attachments?.length ?? 0)}
              )
            </LabelText>
            <FlexerColumn gap="8px">
              <FileUploader
                onFileUpload={handleAddCreditNoteAttachment}
                fileTypes={FILE_TYPES.ALL}
                maxFileSize={10485760}
                smallView
                infoText={`Attach files (${formatFileSize(10485760)})`}
                subInfoText="click or drag&drop"
                showFileUploaded={false}
                privateBucket
              />

              {defaultInvoiceAttachments?.map((attachment, index) => (
                <FileCard key={attachment.name}>
                  <span>
                    {attachment.name} {index !== 0 ? `(${formatFileSize(attachment.size)})` : ''}
                  </span>
                  {index === 0 && (
                    <SwitchWithLabel
                      checked={!creditNoteFormValues?.metadata?.[CREDIT_NOTE_METADATA.EXCLUDE_PDF]}
                      onChange={() =>
                        setFieldValue('metadata', {
                          ...creditNoteFormValues?.metadata,
                          [CREDIT_NOTE_METADATA.EXCLUDE_PDF]: !creditNoteFormValues?.metadata?.[
                            CREDIT_NOTE_METADATA.EXCLUDE_PDF
                          ],
                        })
                      }
                      name="exclude_pdf"
                    />
                  )}
                </FileCard>
              ))}

              {creditNoteFormValues.attachments?.map((attachment, index) => (
                <FileCard key={attachment.name}>
                  <span>
                    {attachment.name} {`(${formatFileSize(attachment.size)})`}
                  </span>
                  <TrashIcon
                    size="16px"
                    fill="var(--primaryBlack)"
                    onClick={() => handleDeleteCreditNoteAttachment(index)}
                  />
                </FileCard>
              ))}
            </FlexerColumn>
          </FlexerColumn>
        </FlexerColumn>
      </FlexerRow>
    </FlexerColumn>
  );
};
