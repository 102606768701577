import React, { useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as ArchiveIcon } from 'images/actions-dropdown__archive.svg';
import { PlusSignIcon } from 'components/Icons';
import { ActionsPopover } from 'shared/Common';
import { FlexerRow, Row } from 'components/Core';
import { IconButton, LinkBackButton } from 'components/Buttons';
import { useConfirmModal } from 'shared/ConfirmModal';
import { AddTransactionsModal } from './AddTransactionsModal';

const CustomerName = styled.div`
  font-weight: 900;
  font-size: 30px;
  line-height: 40px;
`;

export const ContractSinglePageHeader = ({ contractData, archiveContract }) => {
  const [showAddTransactionsModal, setShowAddTransactionsModal] = useState(false);

  const { openConfirmModal, ConfirmModal } = useConfirmModal({
    title: 'Delete related transactions?',
    content: 'Would you also like to delete transactions related to this contract?',
    closeButtonText: 'Cancel',
    denyButtonText: 'No',
    confirmButtonText: 'Yes',
    onConfirm: async () => {
      await archiveContract({ archiveTransactions: true });
    },
    onDeny: async () => {
      await archiveContract({ archiveTransactions: false });
    },
  });

  return (
    <>
      <Row horizontal="space-between" style={{ margin: '20px 0' }}>
        <Row horizontal="start" gap="8px">
          <LinkBackButton data-cy="page-header-back-button" to="/contracts">
            Back
          </LinkBackButton>

          <CustomerName>{`Contract for ${contractData?.customer_name ?? '[No customer name]'}`}</CustomerName>
        </Row>

        <FlexerRow justifyContent="end" gap="12px">
          <IconButton
            data-cy="contract-page__add-transactions"
            icon={<PlusSignIcon />}
            iconFillColor="var(--primaryGreen)"
            onClick={() => setShowAddTransactionsModal(true)}
          >
            Transaction
          </IconButton>

          <ActionsPopover
            showHoverText={null}
            actions={{
              Delete: {
                icon: <ArchiveIcon />,
                cb: openConfirmModal,
              },
            }}
          />
        </FlexerRow>
      </Row>
      {showAddTransactionsModal && (
        <AddTransactionsModal onClose={() => setShowAddTransactionsModal(false)} contractData={contractData} />
      )}
      <ConfirmModal />
    </>
  );
};
