import React from 'react';
import { REVENUE_METRIC } from 'consts/revenueMetrics';
import { useCurrencyNumberFormatter } from 'utils/hooks';
import { NUMBER_FORMATS } from 'consts/global';
import { FlexBetweenContainer } from 'components/Core';
import { TooltipContainer } from 'components/Tooltip';
import {
  DoubleMetricCard,
  DoubleCardContents,
  Border,
  DoubleCardDivider,
  LabelContainer,
  MetricLabel,
  CardImage,
  MetricAmount,
  RotatedText,
} from './styled';

export const ExistingInFluxCard = ({ historicalData, activeMetric, endMonth, setActiveMetric }) => {
  const numberFormatter = useCurrencyNumberFormatter();
  const onCardClick = (key) => () => {
    setActiveMetric(key);
  };

  return (
    <DoubleMetricCard>
      <Border>
        <DoubleCardContents
          onClick={onCardClick(REVENUE_METRIC.EXISTING.key)}
          active={REVENUE_METRIC.EXISTING.key === activeMetric}
        >
          <LabelContainer>
            <MetricLabel>{REVENUE_METRIC.EXISTING.label}</MetricLabel>
            <div>
              <CardImage src={REVENUE_METRIC.EXISTING.icon} alt={REVENUE_METRIC.EXISTING.label} />
            </div>
          </LabelContainer>
          <MetricAmount>
            {numberFormatter({
              type: NUMBER_FORMATS.CURRENCY,
              rawValue: historicalData[endMonth].revenue[REVENUE_METRIC.EXISTING.key],
            })}
          </MetricAmount>
          <div>
            {historicalData[endMonth].customers[REVENUE_METRIC.EXISTING.key]} <span>customers</span>
          </div>
        </DoubleCardContents>
        {![REVENUE_METRIC.EXISTING.key, REVENUE_METRIC.INFLUX.key].includes(activeMetric) && <DoubleCardDivider />}
        <DoubleCardContents
          onClick={onCardClick(REVENUE_METRIC.INFLUX.key)}
          active={REVENUE_METRIC.INFLUX.key === activeMetric}
          opacity
        >
          <TooltipContainer width={150} toolTipContent="Sub-category of Existing revenue">
            <FlexBetweenContainer>
              <div>
                <LabelContainer>
                  <MetricLabel>{REVENUE_METRIC.INFLUX.label}</MetricLabel>
                </LabelContainer>
                <MetricAmount>
                  {numberFormatter({
                    type: NUMBER_FORMATS.CURRENCY,
                    rawValue: historicalData[endMonth].revenue[REVENUE_METRIC.INFLUX.key],
                  })}
                </MetricAmount>
                <div>
                  {historicalData[endMonth].customers[REVENUE_METRIC.INFLUX.key]} <span>customers</span>
                </div>
              </div>
              <RotatedText>Sub-category</RotatedText>
            </FlexBetweenContainer>
          </TooltipContainer>
        </DoubleCardContents>
      </Border>
    </DoubleMetricCard>
  );
};
