import { extractMonthFromPickedDate, monthStringToDate } from 'utils/dateUtils';

const TODAY = new Date();

const INITAL_VALUES = {
  startMonth: new Date(TODAY.getFullYear() - 1, TODAY.getMonth(), 15),
  periodType: 'all',
  cohortInstallBy: 'revenueDollar',
  cohortSegmentBy: '',
  cohortPeriodType: 'month',
  month: new Date(TODAY.getFullYear(), TODAY.getMonth(), 15),
};

const TO_CUSTOMIZATION = {
  startMonth: extractMonthFromPickedDate,
  month: extractMonthFromPickedDate,
};

const TO_FORM_VALUES = {
  startMonth: monthStringToDate,
  month: monthStringToDate,
};

export const customizationToFormValues = (fields, customization) =>
  fields.reduce((acc, field) => {
    const value = customization[field];
    const mapper = TO_FORM_VALUES[field] ?? ((id) => id);
    acc[field] = value !== null && value !== undefined ? mapper(value) : INITAL_VALUES[field];
    return acc;
  }, {});

export const formValuesToCustomization = (values) =>
  Object.entries(values).reduce((acc, [key, value]) => {
    acc[key] = TO_CUSTOMIZATION[key] ? TO_CUSTOMIZATION[key](value) : value;
    return acc;
  }, {});
