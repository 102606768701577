import * as Yup from 'yup';
import { USAGE_BASED_ENGINE_FREQUENCY } from '../PricingPlans/consts';

export const getSchema = ({ products }) => {
  const schema = Yup.array().of(
    Yup.object({
      name: Yup.string().nullable().required('Name is missing'),
      product_id: Yup.string()
        .nullable()
        .oneOf([null, ...products?.map((product) => product.id)], "We can't find that product"),
      description: Yup.string().nullable(),
      event_name: Yup.string().nullable().required('Event Name is missing'),
      currency: Yup.string().nullable().required('Currency is missing'),
      interval: Yup.string()
        .nullable()
        .oneOf(Object.values(USAGE_BASED_ENGINE_FREQUENCY))
        .required('Interval is missing'),
      bill_in_advance: Yup.boolean().nullable(),
      tiers: Yup.string().nullable().required('Tiers is missing'),
    }),
  );

  return schema;
};
