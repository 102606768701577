import { assertCloseTo } from 'utils/assertionUtils';

export const checkTotalRevenue = ({ organization, user, data, rollup }) => {
  const { transactions: customerTransactions, id, transactionSum } = data;

  // TODO: [AT 2022-07-08] Remove this line after https://github.com/ChifferCo/chiffer/issues/3915 is solved
  if (rollup) return;

  let totalRevenue = 0;
  for (const transactions of Object.values(customerTransactions)) {
    for (const transaction of transactions) {
      const { amount } = transaction;
      totalRevenue += amount;
    }
  }
  assertCloseTo({
    organization,
    user,
    context: 'Customer Single',
    expected: totalRevenue,
    actual: transactionSum,
    description: `transactionSum for customer ${id} (to match the sum of spreads of the returned transactions)`,
  });
};
