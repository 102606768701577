import { useContext, useState, useEffect } from 'react';
import { InsightsContext } from './InsightsContext';
import { AppContext } from 'AppContext';
import { useInsightsAPI } from 'api/insights';
import { INSIGHT_METRICS_BY_CHART, INSIGHT_METRIC_TYPES } from './consts';
import { hasSameSettings } from './utils';

export const InsightsProvider = ({ children }) => {
  const { orgId } = useContext(AppContext);
  const [insightsByChartType, setInsightsByChartType] = useState({});

  const { data, isLoading, isFetching, error } = useInsightsAPI({ orgId });

  useEffect(() => {
    const insightsByChartType = {};
    if (data?.length) {
      data.forEach((insight) => {
        const chartType = Object.keys(INSIGHT_METRICS_BY_CHART).find((key) =>
          INSIGHT_METRICS_BY_CHART[key].includes(INSIGHT_METRIC_TYPES[insight.metric]),
        );
        if (!insightsByChartType[chartType]) insightsByChartType[chartType] = {};
        if (!insightsByChartType[chartType][insight.segment_by])
          insightsByChartType[chartType][insight.segment_by] = [];
        insightsByChartType[chartType][insight.segment_by].push(insight);
      });
      setInsightsByChartType(insightsByChartType);
    }
  }, [data]);

  const segmentHasInsights = ({ chartType, segmentBy }) => insightsByChartType?.[chartType]?.[segmentBy]?.length > 0;

  const orgHasInsights = ({ chartType }) => {
    if (chartType === 'cohort') {
      const segmentsWithInsights = Object.keys(insightsByChartType?.[chartType] || {});
      return segmentsWithInsights.length > 0;
    }
    return Object.keys(insightsByChartType?.[chartType] || {}).length > 0;
  };

  const getInsights = ({ chartType, segmentBy, customization: settingsToMatch }) => {
    const insightsForSegment = insightsByChartType?.[chartType]?.[segmentBy] || [];
    if (!settingsToMatch) return insightsForSegment;
    return insightsForSegment.filter((insight) => hasSameSettings({ insight, settingsToMatch }));
  };

  return (
    <InsightsContext.Provider
      value={{
        insights: data,
        isLoading,
        isFetching,
        error,
        segmentHasInsights,
        orgHasInsights,
        getInsights,
        insightsByChartType,
      }}
    >
      {children}
    </InsightsContext.Provider>
  );
};
