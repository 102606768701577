import React, { useCallback, useMemo } from 'react';
import styled, { css } from 'styled-components';
import Select from 'react-select';
import { ReactComponent as DeleteIcon } from 'images/delete-icon.svg';
import { ReactComponent as CreateNew } from 'images/create_new_icon.svg';

const FormGroup = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 38px;
`;

const Input = styled.input`
  white-space: nowrap;
  display: block;
  border: solid 1px rgba(0, 21, 46, 0.2);
  &:last-child {
    border-radius: 0 4px 4px 0;
  }
  &:not(:first-child) {
    margin-left: -1px;
  }
  position: relative;
  z-index: 1;
  flex: 1 1 auto;
  width: 1%;
  margin-top: 0;
  margin-bottom: 0;
  text-align: right;
  border-left: 0;
  font-size: 14px;
  font-weight: 700;
`;

const InputAddon = styled.div`
  white-space: nowrap;
  display: block;
  border: solid 1px rgba(0, 21, 46, 0.2);
  &:first-child {
    border-radius: 4px 0px 0px 4px;
  }
  &:last-child {
    border-radius: 0 4px 4px 0;
  }
  &:not(:first-child) {
    margin-left: -1px;
  }
  text-align: left;
  border-right: 0;
  font-size: 14px;
  padding: 0 0 0 10px;
  line-height: 38px;
  width: 30px;
  color: rgba(0, 134, 232, 1);
  font-weight: 700;
`;

const InputGroup = ({ value = 100, onChange }) => {
  return (
    <FormGroup>
      <InputAddon>%</InputAddon>
      <Input onChange={onChange} defaultValue={value} min="0" max="100" type="number" />
    </FormGroup>
  );
};

const DeleteButton = ({ onClick }) => {
  return (
    <Button onClick={onClick}>
      <DeleteIcon />
    </Button>
  );
};

const CreateNewButton = ({ onClick }) => {
  return (
    <Button onClick={onClick}>
      <CreateNew />
    </Button>
  );
};

const Container = styled.div`
  flex-grow: 1;
  margin: 0 5px;
  &:first-child {
    margin-left: 0px;
  }
  &:last-child {
    margin-right: 0px;
  }
  min-width: 30%;
  display: grid;
  align-content: flex-start;
  grid-row-gap: 10px;
  grid-template-rows: auto;

  ${(props) =>
    props.editable
      ? css`
          grid-template-columns: 2.5fr 1fr 42px;
        `
      : css`
          grid-template-columns: 4fr 1fr;
        `};
`;

const HeaderFirstColumn = styled.div`
  grid-column: 1 / span 1;
  grid-row: auto;
  font-size: 14px;
  line-height: 44px;
  height: 44px;
  font-weight: 700;
  padding-left: 10px;
  background-color: var(--primaryBlack3);
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
`;

const HeaderSecondColumn = styled.div`
  ${(props) =>
    props.editable
      ? css`
          grid-column: 2 / span 1;
        `
      : css`
          grid-column: 2 / span 2;
          border-bottom-right-radius: 8px;
          border-top-right-radius: 8px;
        `};
  grid-row: auto;
  font-size: 10px;
  line-height: 44px;
  background-color: var(--primaryBlack3);
  font-weight: 900;
  color: var(--primaryBlack20);
  height: 44px;
`;

const HeaderThirdColumn = styled.div`
  grid-column: 3 / span 1;
  grid-row: auto;
  color: var(--primaryBlack);
  height: 44px;
  width: 42px;
  padding-top: 5px;
  background-color: var(--primaryBlack3);
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
`;

const FirstColumn = styled.div`
  grid-column: 1 / span 1;
  grid-row: auto;
  height: 42px;
  padding: 0px 10px;
  font-weight: 400;
  font-size: 14px;
  background-color: var(--primaryBlack3);
  margin-right: 16px;
  display: flex;
  align-items: center;
  border-radius: 8px;
`;

const SecondColumn = styled.div`
  grid-column: 2 / span 1;
  grid-row: auto;
  height: 42px;
  text-align: right;
  padding: 0px 10px;
  font-weight: 700;
  font-size: 14px;
  background-color: var(--primaryBlack3);
  display: flex;
  align-items: center;
  border-radius: 8px;
`;

const EditableFirstColumn = styled.div`
  grid-column: 1 / span 1;
  grid-row: auto;
  padding-right: 10px;
  height: 42px;
`;

const EditableSecondColumn = styled.div`
  grid-column: 2 / span 1;
  grid-row: auto;
  height: 42px;
  line-height: 42px;
`;

const EditableThirdColumn = styled.div`
  grid-column: 3 / span 1;
  grid-row: auto;
  width: 42px;
  height: 42px;
  line-height: 42px;
`;

const Button = styled.button`
  padding: 0;
  border: none;
  background: none;
  height: 38px;
  padding-left: 14px;
  padding-right: 14px;
`;

const EditableAccountsSelect = ({ id, account, accounts, percentage, onChange }) => {
  const handleSelect = useCallback(
    ({ value }) => {
      onChange(id, value, Number(percentage));
    },
    [id, onChange, percentage],
  );

  const handleChange = useCallback(
    (event) => {
      onChange(id, account.value, Number(event.target.value));
    },
    [id, onChange, account],
  );

  const handleDelete = useCallback(() => {
    onChange(id, null, null, true);
  }, [id, onChange]);

  return (
    <React.Fragment key={id}>
      <EditableFirstColumn>
        <Select
          menuPlacement="auto"
          defaultValue={account}
          options={accounts}
          isSearchable={true}
          onChange={handleSelect}
        />
      </EditableFirstColumn>
      <EditableSecondColumn>
        <InputGroup onChange={handleChange} value={percentage} />
      </EditableSecondColumn>
      <EditableThirdColumn>
        <DeleteButton onClick={handleDelete} />
      </EditableThirdColumn>
    </React.Fragment>
  );
};

const AccountsSelect = ({ title = 'UNKNOWN', onChange, selectedAccounts = [], accounts, editable = false }) => {
  const handleChange = useCallback(
    (index, id, percentage, shouldDelete = false) => {
      let newSelectedAccounts = [];

      if (shouldDelete) {
        newSelectedAccounts = selectedAccounts.filter((t, i) => i !== index);
      } else {
        newSelectedAccounts = [...selectedAccounts];
        newSelectedAccounts[index] = { account: id, percent: percentage / 100 };
      }

      onChange(newSelectedAccounts);
    },
    [selectedAccounts, onChange],
  );

  const handleCreateNew = useCallback(
    (e) => {
      e.preventDefault();
      const newSelectedAccounts = [...selectedAccounts];
      newSelectedAccounts.push({ account: accounts[0]?.id, percent: 1 });
      onChange(newSelectedAccounts);
    },
    [selectedAccounts, onChange, accounts],
  );

  const SelectedRows = useMemo(
    () =>
      selectedAccounts.map((account, id) => {
        if (!accounts) return null;
        const defaultAccount = accounts.find((t) => t.id === account.account);

        let defaultValue = {};
        let defaultPercentage = 100;

        if (defaultAccount) {
          defaultValue = {
            value: defaultAccount.id,
            label: defaultAccount.name,
          };
          defaultPercentage = (account.percent * 100).toString();
        }

        const dropdownAccounts = accounts.map((t) => ({ value: t.id, label: t.name }));

        return (
          <EditableAccountsSelect
            key={id}
            id={id}
            account={defaultValue}
            accounts={dropdownAccounts}
            percentage={defaultPercentage}
            onChange={handleChange}
          />
        );
      }),
    [selectedAccounts, accounts, handleChange],
  );

  let Rows = null;
  if (editable && selectedAccounts.length > 0) {
    Rows = SelectedRows;
  } else if (editable) {
    Rows = [];
  } else {
    Rows = selectedAccounts.map((account, id) => {
      const accountName = accounts?.find((t) => t.id === account.account)?.name || 'unkown';
      return (
        <React.Fragment key={id}>
          <FirstColumn>{accountName}</FirstColumn>
          <SecondColumn>{Number(account.percent * 100)} %</SecondColumn>
        </React.Fragment>
      );
    });
  }

  return (
    <Container editable={editable}>
      <HeaderFirstColumn>{title}</HeaderFirstColumn>
      <HeaderSecondColumn editable={editable}>PERCENTAGE</HeaderSecondColumn>
      {editable && (
        <HeaderThirdColumn>
          <CreateNewButton onClick={handleCreateNew} />
        </HeaderThirdColumn>
      )}
      {Rows}
    </Container>
  );
};

export default AccountsSelect;
