import { useState } from 'react';
import styled from 'styled-components';

import { IconButton } from 'components/Buttons';
import {
  Popover,
  PopoverItemsContainer,
  PopoverListItemFull,
  PopoverListItemIconFull,
  PopoverListItemTitleFull,
  PopoverWrapper,
} from 'components/Portal';
import { useClickOutside } from 'utils/hooks';
import { ReactComponent as ArrowDown } from 'images/chevron-down.svg';
import { COLORS } from 'consts/colors';
import { CirclePlusIcon } from 'components/Icons';
import { useEditPricingPlanModal } from 'views/Billing/UsageBasedEngine/PricingPlans/PricingPlanModal/EditPricingPlanModal';
import { PRICING_PLAN_MODAL_MODE } from 'views/Billing/UsageBasedEngine/PricingPlans/PricingPlanModal/consts';
import {
  SUBSCRIPTION_EDIT_MODAL_MODE,
  useSubscriptionEditModal,
} from 'views/Billing/UsageBasedEngine/Subscriptions/SubscriptionEditModal';
import { useUsageEventCreateModal } from 'views/Billing/UsageBasedEngine/UsageEvents/UsageEventCreateModal';

const StyledArrow = styled(ArrowDown)`
  margin-left: 8px;
  transform: ${({ active }) => active && 'rotate(180deg)'};
`;

export const HeaderActionsDropdown = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const toggleDropdown = () => setShowDropdown(!showDropdown);

  const dropdownRef = useClickOutside(() => setShowDropdown(false));

  const { openModal: openEditPricingPlanModal, EditPricingPlanModal } = useEditPricingPlanModal();
  const { openModal: openSubscriptionEditModal, SubscriptionEditModal } = useSubscriptionEditModal();
  const { openModal: openUsageEventCreateModal, UsageEventCreateModal } = useUsageEventCreateModal();

  return (
    <PopoverWrapper ref={dropdownRef}>
      <IconButton
        data-cy="usage-based__create"
        filled
        border
        color={COLORS.GREEN}
        colorOnHover="var(--secondaryGreen)"
        boxShadow="0px 4px 24px 0px rgba(20, 174, 101, 0.2)"
        boxShadowOnHover="0px 4px 24px 0px rgba(20, 174, 101, 0.2)"
        iconFillColor="white"
        active={!showDropdown}
        onClick={toggleDropdown}
      >
        Create
        <StyledArrow active={showDropdown} />
      </IconButton>
      {showDropdown && (
        <Popover width="160px">
          <PopoverItemsContainer>
            <PopoverListItemFull
              gap="8px"
              data-cy="usage-based__create--subscription"
              onClick={() => {
                openSubscriptionEditModal({ mode: SUBSCRIPTION_EDIT_MODAL_MODE.CREATE });
                setShowDropdown(false);
              }}
            >
              <PopoverListItemIconFull>
                <CirclePlusIcon color="var(--primaryBlack50)" />
              </PopoverListItemIconFull>
              <PopoverListItemTitleFull>Subscription</PopoverListItemTitleFull>
            </PopoverListItemFull>

            <PopoverListItemFull
              gap="8px"
              data-cy="usage-based__create--pricing_plan"
              onClick={() => {
                openEditPricingPlanModal({ mode: PRICING_PLAN_MODAL_MODE.CREATE });
                setShowDropdown(false);
              }}
            >
              <PopoverListItemIconFull>
                <CirclePlusIcon color="var(--primaryBlack50)" />
              </PopoverListItemIconFull>
              <PopoverListItemTitleFull>Pricing Plan</PopoverListItemTitleFull>
            </PopoverListItemFull>

            <PopoverListItemFull
              gap="8px"
              data-cy="usage-based__create--event"
              onClick={() => {
                openUsageEventCreateModal();
                setShowDropdown(false);
              }}
            >
              <PopoverListItemIconFull>
                <CirclePlusIcon color="var(--primaryBlack50)" />
              </PopoverListItemIconFull>
              <PopoverListItemTitleFull>Usage Event</PopoverListItemTitleFull>
            </PopoverListItemFull>
          </PopoverItemsContainer>
        </Popover>
      )}

      <EditPricingPlanModal />
      <SubscriptionEditModal />
      <UsageEventCreateModal />
    </PopoverWrapper>
  );
};
