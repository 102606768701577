const { SNAPSHOT_METRICS } = require('consts/snapshotMetrics');

const REVENUE_ROW_METRICS = [
  SNAPSHOT_METRICS.RECURRING_REVENUE,
  SNAPSHOT_METRICS.RECURRING_PERIOD_GROWTH,
  SNAPSHOT_METRICS.NON_RECURRING,
  SNAPSHOT_METRICS.TOTAL_PERIOD_REVENUE,
  SNAPSHOT_METRICS.TOTAL_PERIOD_GROWTH,
  SNAPSHOT_METRICS.PERCENTAGE_RECURRING,
  SNAPSHOT_METRICS.BOOKINGS,
  SNAPSHOT_METRICS.BOOKINGS_NEW,
  SNAPSHOT_METRICS.GROSS_NEW_REVENUE,
  SNAPSHOT_METRICS.NON_RENEWAL_UPSELL,
  SNAPSHOT_METRICS.RECURRING_CUSTOMERS,
  SNAPSHOT_METRICS.CUSTOMERS,
  SNAPSHOT_METRICS.CUSTOMERS_WITH_OPEN_CONTRACTS,
  SNAPSHOT_METRICS.SEATS,
  SNAPSHOT_METRICS.ARPU,
  SNAPSHOT_METRICS.ARPU_NEW,
];

const SAAS_ROW_METRICS = [
  SNAPSHOT_METRICS.LTV,
  SNAPSHOT_METRICS.LTV_TTM,
  SNAPSHOT_METRICS.LTVGMLaden,
  SNAPSHOT_METRICS.LTVGMLaden_TTM,
  SNAPSHOT_METRICS.CAC_RECURRING,
  SNAPSHOT_METRICS.CAC_RECURRING_TTM,
  SNAPSHOT_METRICS.CAC_ALL,
  SNAPSHOT_METRICS.CAC_ALL_TTM,
  SNAPSHOT_METRICS.CEC_RECURRING,
  SNAPSHOT_METRICS.CEC_RECURRING_TTM,
  SNAPSHOT_METRICS.LTVCACRatio,
  SNAPSHOT_METRICS.LTVCACRatio_TTM,
  SNAPSHOT_METRICS.NEW_REVENUE_TO_COST_RATIO,
  SNAPSHOT_METRICS.GROSS_CUSTOMER_RETENTION,
  SNAPSHOT_METRICS.GROSS_CUSTOMER_RETENTION_TTM,
  SNAPSHOT_METRICS.NET_REVENUE_RETENTION,
  SNAPSHOT_METRICS.NET_REVENUE_RETENTION_TTM,
  SNAPSHOT_METRICS.GROSS_REVENUE_RETENTION,
  SNAPSHOT_METRICS.GROSS_REVENUE_RETENTION_TTM,
  SNAPSHOT_METRICS.GROSS_REVENUE_CHURN_RATE,
  SNAPSHOT_METRICS.GROSS_REVENUE_CHURN_RATE_TTM,
  SNAPSHOT_METRICS.NET_NEW_REVENUE,
  SNAPSHOT_METRICS.NET_NEW_REVENUE_TTM,
  SNAPSHOT_METRICS.WINBACK_REVENUE,
  SNAPSHOT_METRICS.WINBACK_CUSTOMERS,
  SNAPSHOT_METRICS.UP_FOR_RENEWAL,
  SNAPSHOT_METRICS.AMOUNT_RENEWED_THAT_WAS_UP,
  SNAPSHOT_METRICS.AMOUNT_RENEWED_THAT_WAS_UP_NET,
  SNAPSHOT_METRICS.CUSTOMERS_UP_FOR_RENEWAL,
  SNAPSHOT_METRICS.CUSTOMERS_RENEWED,
  SNAPSHOT_METRICS.CUSTOMER_UP_FOR_RENEWAL_RETENTION,
  SNAPSHOT_METRICS.GROSS_UP_FOR_RENEWAL_RETENTION,
  SNAPSHOT_METRICS.NET_UP_FOR_RENEWAL_RETENTION,
];

const UNIT_ECONOMICS_METRICS = [
  SNAPSHOT_METRICS.ARPA,
  SNAPSHOT_METRICS.ARPA_TTM,
  SNAPSHOT_METRICS.ARPA_NEW,
  SNAPSHOT_METRICS.ARPA_NEW_TTM,
  SNAPSHOT_METRICS.GROSS_MARGIN,
  SNAPSHOT_METRICS.GROSS_MARGIN_TTM,
  SNAPSHOT_METRICS.GROSS_MARGIN_NON_RECURRING,
  SNAPSHOT_METRICS.GROSS_MARGIN_NON_RECURRING_TTM,
];

const EFFICIENCY_METRICS = [
  SNAPSHOT_METRICS.MAGIC_NUMBER,
  SNAPSHOT_METRICS.SALES_EFFICIENCY_RATIO,
  SNAPSHOT_METRICS.PAYBACK_PERIOD,
  SNAPSHOT_METRICS.PAYBACK_PERIOD_TTM,
  SNAPSHOT_METRICS.RULE_OF_40_TTM,
  SNAPSHOT_METRICS.BURN_MULTIPLE,
  SNAPSHOT_METRICS.BURN_MULTIPLE_TTM,
];

export const SNAPSHOT_TABLE_METRICS = {
  REVENUE_ROW_METRICS,
  SAAS_ROW_METRICS,
  UNIT_ECONOMICS_METRICS,
  EFFICIENCY_METRICS,
};
