export const EXTERNAL_UPDATES_TABLE_COLUMNS = {
  created_at: {
    key: 'created_at',
    name: 'Created At',
    isFilterable: false,
    isHideable: false,
  },
  amount: {
    key: 'amount',
    name: 'Amount',
    isFilterable: false,
    isHideable: false,
  },
  frequency: {
    key: 'frequency',
    name: 'frequency',
    isFilterable: false,
    isHideable: false,
  },
  start_date: {
    key: 'start_date',
    name: 'Start',
    isFilterable: false,
    isHideable: false,
  },
  end_date: {
    key: 'end_date',
    name: 'End',
    isFilterable: false,
    isHideable: false,
  },
  customer_name: {
    key: 'customer_name',
    name: 'Customer  (inv. schedule)',
    isFilterable: true,
    isHideable: false,
  },
  transactions: {
    key: 'transactions',
    name: 'transactions',
    isFilterable: false,
    isHideable: true,
  },
};
