import { useConfirmModal } from 'shared/ConfirmModal';

export const useManualSyncCustomerModal = ({ onShouldReingest, integrationDisplayName }) => {
  const { openConfirmModal, ConfirmModal } = useConfirmModal({
    width: '350px',
    title: 'Sync Customer?',
    content: `Are you sure you want to sync this customer's details (ex. email, address) over from ${integrationDisplayName} into Subscript?`,
    closeButtonText: 'Cancel',
    confirmButtonText: 'Yes',
    onConfirm: () => onShouldReingest(true),
    onDeny: () => {},
    centerFooter: true,
  });

  return {
    openManualSyncCustomerModal: openConfirmModal,
    ManualSyncCustomerModal: ConfirmModal,
  };
};
