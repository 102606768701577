import styled from 'styled-components';

const StyledTabButton = styled.div`
  display: flex;
  align-items: center;
  padding: ${(props) => (props.isBig ? '10px 12px' : '8px 10px')};
  font-weight: bold;
  font-size: ${(props) => (props.isBig ? '14px' : '12px')};
  line-height: ${(props) => (props.isBig ? '20px' : '14px')};
  text-align: center;
  margin-right: 12px;
  border-radius: 8px;
  cursor: pointer;
  width: ${(props) => (props.width ? props.width : 'fit-content')};
  text-transform: capitalize;
  color: ${(props) => (props.active ? 'var(--primaryBlue)' : 'var(--primaryBlack)')};
  background-color: ${(props) => (props.active ? 'rgba(0, 133, 255, 0.1)' : 'white')};
  box-shadow: ${(props) => !props.active && '4px 4px 28px var(--primaryBlack5)'};
  border: ${(props) => (props.active ? '1px solid rgba(0, 21, 46,0)' : '1px solid var(--accentGraySecond)')};
  pointer-events: ${(props) => (props.active || props.isDisabled) && 'none'};
  opacity: ${(props) => props.isDisabled && 0.3};
  list-style: none;
  transition: all ease 0.4s;

  &:hover {
    background-color: rgba(0, 133, 255, 0.15);
    color: var(--secondaryBlue);
  }

  svg {
    path {
      fill: ${(props) => props.active && 'var(--primaryBlue)'};
    }
  }
`;

export const TabButton = ({ onClick, isBig = true, active = false, isDisabled = false, children, ...rest }) => (
  <StyledTabButton onClick={onClick} isBig={isBig} active={active} isDisabled={isDisabled} {...rest}>
    {children}
  </StyledTabButton>
);
