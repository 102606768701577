import React from 'react';
import dayjs from 'dayjs';
import { ReactComponent as TooltipIcon } from 'images/tooltip-icon.svg';
import { ReactComponent as CornerLeftDownIcon } from 'images/corner-left-down-icon.svg';
import { FlexBetweenContainer } from 'components/Core/Flex';
import { TooltipContainer } from 'components/Tooltip';
import { REVENUE_METRIC } from 'consts/revenueMetrics';
import { TransactionRow } from './TransactionRow';
import {
  SpreadTable,
  ColumnTitle,
  ColumnWithTooltip,
  TransactionTableRow,
  PreviousMonthContainer,
  PreviousMonthName,
  PreviousMonthLabel,
} from '../styled';

const RowTable = ({ customer, metricSpreads, previousSpreads, activeMetric, revenueMonth, onTransactionClick }) => {
  let columns = {
    DEAL_NAME: 'TRANSACTION NAME',
    PRODUCT: 'PRODUCT',
    TYPE: 'TYPE',
    BOOKING_DATE: 'BOOKING DATE',
    DATES: 'DATES',
    SEATS: 'SEATS',
    REVENUE: '',
    CONTRACT_SIZE: 'CONTRACT SIZE',
  };

  switch (activeMetric) {
    case REVENUE_METRIC.NON_RECURRING.label:
      columns.REVENUE = 'NON-RECURRING REVENUE';
      break;
    default:
      columns.REVENUE = 'RECURRING REVENUE';
  }

  const revenueMonthDate = dayjs(revenueMonth);

  return (
    <SpreadTable>
      <thead>
        <tr style={{ backgroundColor: 'var(--primaryBlack2)' }}>
          {Object.values(columns).map((label) => {
            switch (label) {
              case columns.TYPE:
                return (
                  <ColumnTitle key={label} style={{ textAlign: 'center' }}>
                    {label}
                  </ColumnTitle>
                );
              case columns.REVENUE:
                return (
                  <ColumnTitle key={label}>
                    <ColumnWithTooltip justifyContent="flex-end">
                      {label}
                      <TooltipContainer
                        width={120}
                        isVisible={true}
                        underline={false}
                        toolTipContent={`For ${revenueMonthDate.format('MMM YYYY')}`}
                      >
                        <TooltipIcon />
                      </TooltipContainer>
                    </ColumnWithTooltip>
                  </ColumnTitle>
                );
              case columns.CONTRACT_SIZE:
                return (
                  <ColumnTitle key={label}>
                    <ColumnWithTooltip justifyContent="flex-end">
                      {label}
                      <TooltipContainer
                        width={180}
                        isVisible={true}
                        underline={false}
                        toolTipContent={'Contract size of all time'}
                      >
                        <TooltipIcon />
                      </TooltipContainer>
                    </ColumnWithTooltip>
                  </ColumnTitle>
                );
              case columns.PRODUCT:
                return <ColumnTitle key={label}>{label || '[No Product]'}</ColumnTitle>;
              default:
                return <ColumnTitle key={label}>{label}</ColumnTitle>;
            }
          })}
        </tr>
      </thead>
      <tbody>
        {metricSpreads.map((spread) => (
          <TransactionRow
            key={spread.spread_id}
            spread={spread}
            onTransactionClick={onTransactionClick}
            customer={customer}
            activeMetric={activeMetric}
          />
        ))}
        {Array.isArray(previousSpreads) && (
          <>
            <TransactionTableRow>
              <td colspan="8">
                <FlexBetweenContainer centerer>
                  <PreviousMonthContainer>
                    <PreviousMonthName>
                      {revenueMonthDate.subtract(1, 'month').format('MMMM YYYY').toUpperCase()}
                    </PreviousMonthName>
                    <PreviousMonthLabel>PREVIOUS MONTH</PreviousMonthLabel>
                  </PreviousMonthContainer>
                  <CornerLeftDownIcon />
                </FlexBetweenContainer>
              </td>
            </TransactionTableRow>
            {previousSpreads.map((spread) => (
              <TransactionRow
                key={spread.spread_id}
                spread={spread}
                onTransactionClick={onTransactionClick}
                customer={customer}
                activeMetric={activeMetric}
              />
            ))}
          </>
        )}
      </tbody>
    </SpreadTable>
  );
};

export default RowTable;
