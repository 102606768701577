import axios from 'axios';
import { API_URL } from 'api/consts';

export const getShareMetrics = async ({ orgId, appSettings, metadataFilter, endMonth, startMonth }) => {
  const response = await axios.post(`${API_URL}/organizations/${orgId}/share/metrics`, {
    appSettings,
    endMonth,
    startMonth,
    metadataFilter,
  });
  return response.data;
};

export const sharePageWithEmail = async ({
  orgId,
  metrics,
  snapshotMetrics,
  pageToShare,
  appSettings,
  chartImage,
  sendPreviewEmailTo,
  manuallyAddedEmails,
  showWaterFallExistingRow,
  metadataFilter,
  endMonth,
  startMonth,
  reportId,
}) => {
  const url = `${API_URL}/organizations/${orgId}/share/email`;

  const response = await axios.post(url, {
    metrics,
    snapshotMetrics,
    sendPreviewEmailTo,
    pageToShare,
    chartImage,
    showWaterFallExistingRow,
    appSettings,
    metadataFilter,
    endMonth,
    startMonth,
    manuallyAddedEmails,
    reportId,
  });
  return response.data;
};

export const sharePageWithSlack = async ({
  orgId,
  metrics,
  pageToShare,
  appSettings,
  metadataFilter,
  endMonth,
  startMonth,
  reportId,
}) => {
  const url = `${API_URL}/organizations/${orgId}/share/slack`;

  const response = await axios.post(url, {
    metrics,
    pageToShare,
    appSettings,
    metadataFilter,
    endMonth,
    startMonth,
    reportId,
  });
  return response.data;
};
