import styled from 'styled-components';

import { Flexer } from 'components/Core';

export const Wrapper = styled(Flexer)`
  padding: 2px;
  border-radius: 16px;
  border: 1px solid #f3f3f3;
`;

export const Line = styled.div`
  width: 1px;
  height: 40px;
  background-color: var(--accentGraySecond);
  margin: 0 10px;
`;
