import React, { useState } from 'react';
import styled from 'styled-components';
import { BigItemsContainer, Row } from 'components/Core';
import { ReactComponent as PaletteColorIcon } from 'images/palette-color.svg';
import { ReactComponent as PaletteMonoIcon } from 'images/palette-mono.svg';
import { ReactComponent as RelatedHelpIcon } from 'images/related-help.svg';
import { ReactComponent as PaintIcon } from 'images/cohorts-paint-color-drop-icon.svg';
import { useClickOutside } from 'utils/hooks';
import { COHORT_PALLETS } from '../consts';
import { CircleCheckIcon } from 'components/Icons';
import { GlobalToggleButton } from 'components/Buttons/GlobalToggleButton';
import { TooltipContainer } from 'components/Tooltip';
import { LabelIconContainer } from 'components/Blocks';

const ModalPopover = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  box-shadow: 15px 15px 60px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  border: 1px solid var(--accentGray);
  z-index: 20;
  top: 25px;
  left: -13px;
  cursor: initial;
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${({ bigMarginBottom }) => (bigMarginBottom ? '20px' : '8px')};

  & > * {
    margin-right: 8px;

    &:last-child {
      margin-right: 0;
    }
  }
`;

const TitleModalListItem = styled.div`
  opacity: 0.5;
  margin-bottom: 12px;
  font-size: 12px;
  text-transform: none;
  color: var(--primaryBlack);
  font-weight: normal;
  line-height: 16px;
`;

const IconContainer = styled.span`
  cursor: pointer;
`;

const ToggleContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const IconWrapper = styled.div`
  border: ${({ active }) => (active ? '1px solid rgba(0, 21, 46, 0.75)' : '1px solid transparent')};
  filter: drop-shadow(3px 3px 10px rgba(0, 0, 0, 0.08));
  border-radius: 100px;
  height: 18px;
  width: 38px;

  svg {
    border-radius: 100px;
    margin-top: 0;
    border: 1px solid #ffffff;
  }
`;

const PalleteOption = styled.div`
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  text-transform: none;
  color: var(--primaryBlack);
  justify-content: space-between;
  margin-bottom: 12px;
  cursor: pointer;
  pointer-events: ${({ active }) => active && 'none'};

  ${CircleCheckIcon} {
    opacity: ${({ active }) => (active ? '1' : '0')};
    margin-left: 4px;

    path {
      fill: initial !important;
    }

    g {
      opacity: ${({ active }) => (active ? '1' : '0.2')};
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    color: rgba(0, 21, 46, 0.7);

    ${IconWrapper} {
      border: 1px solid rgba(0, 21, 46, 0.3);
    }

    ${CircleCheckIcon} {
      opacity: 1;
    }
  }
`;

const ToggleText = styled.div`
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  text-transform: none;
  color: var(--primaryBlack);
`;
const ToggleButtonText = styled.div`
  text-transform: none;
`;

const StyledHelpIcon = styled(RelatedHelpIcon)`
  position: relative;
  cursor: pointer;
  color: var(--primaryBlack);
  &:hover {
    color: var(--primaryBlack);
    g {
      opacity: 1;
    }
  }
`;

const HintSection = styled.div`
  white-space: normal;
  padding: 10px 16px;
  background: rgba(0, 21, 46, 0.03);
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 0;
  text-transform: none;
  color: var(--primaryBlack);
`;

const HintTitle = styled.div`
  font-weight: bold;
`;

const HintDescription = styled.div`
  opacity: 0.5;
  font-weight: normal;
`;

const RoundedSquareIconWrapper = styled.div`
  display: flex;
  padding: 8px;
  background: #ffffff;
  margin-right: 12px;
  border: 1px solid #f3f3f3;
  box-sizing: border-box;
  border-radius: 8px;

  svg path {
    fill: initial !important;
  }
`;

export const TogglePopover = ({ icon, ...props }) => {
  const [showDropdown, setShowDropdown] = useState(false);

  const dropdownRef = useClickOutside(() => setShowDropdown(false));

  return (
    <ToggleContainer ref={dropdownRef}>
      <Row onClick={() => setShowDropdown(!showDropdown)}>
        Settings
        <IconContainer>{icon}</IconContainer>
      </Row>

      {showDropdown && <OpenedDropdown {...props} />}
    </ToggleContainer>
  );
};

const OpenedDropdown = ({
  toggleTotalAmounts,
  totalAmounts,
  absoluteColoring,
  toggleAbsoluteColoring,
  cohortPalette,
  setCohortPalette,
}) => {
  return (
    <ModalPopover>
      <BigItemsContainer style={{ padding: '12px 16px' }} soloView={true}>
        <TitleModalListItem>General</TitleModalListItem>
        <ButtonsContainer bigMarginBottom>
          <GlobalToggleButton name="isTotalAmounts" active={totalAmounts} onClick={toggleTotalAmounts}>
            <ToggleButtonText>Total</ToggleButtonText>
          </GlobalToggleButton>
          <GlobalToggleButton name="isAverageAmounts" active={!totalAmounts} onClick={toggleTotalAmounts}>
            <ToggleButtonText>Average</ToggleButtonText>
          </GlobalToggleButton>
          <ToggleText>amounts per cohorts</ToggleText>
        </ButtonsContainer>
        <ButtonsContainer bigMarginBottom>
          <GlobalToggleButton name="isAbsoluteColoring" active={absoluteColoring} onClick={toggleAbsoluteColoring}>
            <ToggleButtonText>Absolute</ToggleButtonText>
          </GlobalToggleButton>
          <GlobalToggleButton name="isRelativeColoring" active={!absoluteColoring} onClick={toggleAbsoluteColoring}>
            <ToggleButtonText>Relative</ToggleButtonText>
          </GlobalToggleButton>
          <ToggleText>color-coding</ToggleText>
          <TooltipContainer
            width={200}
            toolTipContent="Relative color coding works relative to each row, absolute depends on all values of the table"
          >
            <StyledHelpIcon />
          </TooltipContainer>
        </ButtonsContainer>
        <TitleModalListItem>Palette</TitleModalListItem>
        <PalleteOption
          active={cohortPalette === COHORT_PALLETS.COLORFUL}
          onClick={() => setCohortPalette(COHORT_PALLETS.COLORFUL)}
        >
          <Row>
            {COHORT_PALLETS.COLORFUL}

            <CircleCheckIcon />
          </Row>

          <IconWrapper active={cohortPalette === COHORT_PALLETS.COLORFUL}>
            <PaletteColorIcon />
          </IconWrapper>
        </PalleteOption>
        <PalleteOption
          active={cohortPalette === COHORT_PALLETS.MONOCHROMIC}
          onClick={() => setCohortPalette(COHORT_PALLETS.MONOCHROMIC)}
        >
          <Row>
            {COHORT_PALLETS.MONOCHROMIC}

            <CircleCheckIcon />
          </Row>

          <IconWrapper active={cohortPalette === COHORT_PALLETS.MONOCHROMIC}>
            <PaletteMonoIcon />
          </IconWrapper>
        </PalleteOption>
      </BigItemsContainer>
      <HintSection>
        <LabelIconContainer>
          <RoundedSquareIconWrapper>
            <PaintIcon />
          </RoundedSquareIconWrapper>
          <HintTitle>How do the colors work?</HintTitle>
        </LabelIconContainer>
        <HintDescription>
          {`The color of the cells changes for every 10% drop from the maximum number in the ${
            absoluteColoring ? 'table.' : 'row.'
          }`}
        </HintDescription>
      </HintSection>
    </ModalPopover>
  );
};
