import React from 'react';
import { useFormikContext } from 'formik';
import { ReactComponent as DateIcon } from 'images/calendar-time.svg';
import { CentererVertical, Spacer } from 'components/Core';
import { CustomDatePicker } from 'components/Controls';

export const StartMonthField = ({ name = 'startMonth', label = 'Syncing data from' }) => {
  const { setFieldValue, values } = useFormikContext();

  return (
    <CentererVertical>
      <DateIcon />
      <Spacer width="8px" />
      <div>{label}:</div>
      <CustomDatePicker
        name={name}
        showMonthYearPicker
        dateFormat="MM/yyyy"
        filtersView
        selected={values[name]}
        onChange={(date) => setFieldValue(name, date)}
      />
      <div>to present with auto-updating</div>
    </CentererVertical>
  );
};
