import { useContext, useEffect, useMemo, useState } from 'react';
import { cloneDeep, isNil } from 'lodash';

import { AppContext } from 'AppContext';
import { INTEGRATION_METADATA_FIELDS, INTEGRATION_SERVICES, INTEGRATION_TYPES } from 'consts/integrations';
import { EVENTS } from 'consts/analytics';
import { useAnalytics } from 'utils/hooks';
import { useIntegrationsAPI } from 'api/integrations/hooks';
import { initiateSlackLogin } from 'api/integrations/requests';
import { EMAIL_DEFAULT_FREQUENCY } from './consts';

export const useAnalyticsIntegrations = ({ integrationTypes }) => {
  const { trackEvent } = useAnalytics();
  const { orgId } = useContext(AppContext);
  const {
    operations: { upsertIntegrations },
  } = useIntegrationsAPI({ orgId, autoFetch: false });
  const [isTouched, setIsTouched] = useState(false);
  const [analyticsIntegrations, setAnalyticsIntegrations] = useState({});

  const deactivateAllIntegrations = () => {
    setIsTouched(true);
    setAnalyticsIntegrations(
      Object.entries(analyticsIntegrations).reduce((acc, [serviceKey, serviceValue]) => {
        acc[serviceKey] = serviceValue;
        acc[serviceKey].metadata[INTEGRATION_METADATA_FIELDS.SKIP_DAILY_SYNC] = true;
        return acc;
      }, {}),
    );
  };

  const updateAnalyticsIntegration = ({ service, active, additionalMetadata = {} }) => {
    setIsTouched(true);
    const integrations = cloneDeep(analyticsIntegrations);
    //create new slack integration
    if (!integrations[service] && service === INTEGRATION_SERVICES.SLACK) {
      trackEvent({
        name: EVENTS.CLICK_CONNECT_SLACK,
      });
      initiateSlackLogin();
      //create new email integration
    } else if (!integrations[service]) {
      integrations[service] = {
        service,
        type: INTEGRATION_TYPES.Analytics,
        metadata: {
          [INTEGRATION_METADATA_FIELDS.SKIP_DAILY_SYNC]: false,
          [INTEGRATION_METADATA_FIELDS.EMAIL_FREQUENCY]: EMAIL_DEFAULT_FREQUENCY,
          ...additionalMetadata,
        },
      };
    } else {
      integrations[service].metadata = {
        ...integrations[service].metadata,
        [INTEGRATION_METADATA_FIELDS.SKIP_DAILY_SYNC]: isNil(active)
          ? integrations[service].metadata[INTEGRATION_METADATA_FIELDS.SKIP_DAILY_SYNC]
          : active
          ? false
          : true,
        ...additionalMetadata,
      };
    }
    setAnalyticsIntegrations(integrations);
  };

  const hasActiveAnalyticsIntegrations = useMemo(
    () =>
      Object.values(analyticsIntegrations).some(
        (integration) => !integration.metadata[INTEGRATION_METADATA_FIELDS.SKIP_DAILY_SYNC],
      ),
    [analyticsIntegrations],
  );

  const updateAnalyticsIntegrations = async () => {
    if (isTouched) {
      const integrationsToUpsert = Object.values(analyticsIntegrations).filter(
        (integration) => !!integration.id || !integration.metadata[INTEGRATION_METADATA_FIELDS.SKIP_DAILY_SYNC],
      );

      const emailIntegration = integrationsToUpsert.find(
        (integration) =>
          !integration.metadata[INTEGRATION_METADATA_FIELDS.SKIP_DAILY_SYNC] &&
          integration.service === INTEGRATION_SERVICES.EMAIL,
      );

      const emailIntegrationFrequency = emailIntegration?.metadata?.[INTEGRATION_METADATA_FIELDS.EMAIL_FREQUENCY];

      const emailIntegrationReportForShare = emailIntegration?.metadata?.[INTEGRATION_METADATA_FIELDS.REPORT_FOR_SHARE];

      const slackIntegration = integrationsToUpsert.find(
        (integration) =>
          !integration.metadata[INTEGRATION_METADATA_FIELDS.SKIP_DAILY_SYNC] &&
          integration.service === INTEGRATION_SERVICES.SLACK,
      );

      //sync slack frequency with email frequency
      if (emailIntegrationFrequency && slackIntegration) {
        slackIntegration.metadata[INTEGRATION_METADATA_FIELDS.SLACK_FREQUENCY] = emailIntegrationFrequency;
      }

      //sync slack report for share with email report for share
      if (emailIntegrationReportForShare && slackIntegration) {
        slackIntegration.metadata[INTEGRATION_METADATA_FIELDS.REPORT_FOR_SHARE] = emailIntegrationReportForShare;
      }

      try {
        await upsertIntegrations.mutateAsync({ integrations: integrationsToUpsert });
        setIsTouched(false);
      } catch (e) {
        console.error(e);
      }
    }
  };

  useEffect(() => {
    if (integrationTypes?.length > 0) {
      const analyticsIntegrationList = integrationTypes.filter(
        (integration) => integration.type === INTEGRATION_TYPES.Analytics,
      );
      setAnalyticsIntegrations(
        analyticsIntegrationList.reduce((acc, integration) => {
          acc[integration.service] = integration;
          return acc;
        }, {}),
      );
    }
  }, [integrationTypes]);

  return {
    analyticsIntegrations,
    setAnalyticsIntegrations,
    hasActiveAnalyticsIntegrations,
    deactivateAllIntegrations,
    updateAnalyticsIntegration,
    updateAnalyticsIntegrations,
  };
};
