import styled from 'styled-components';
import { useContext } from 'react';
import { AppContext } from 'AppContext';
import { FlexerColumn } from 'components/Core';
import { useInvoicingScheduleAPI, useInvoicingSchedulesAPI } from 'api/billing/hooks';
import { transactionDisplayName } from 'models/transaction';
import { TransactionActionsModal } from 'shared/TransactionActionsModal';
import { InvoiceChanges, InvoicingScheduleChangesContext } from 'shared/InvoicingScheduleChanges';
import { TransactionContext } from './TransactionContext';

const TransactionName = styled.h3`
  color: var(--primaryBlack50);
  font-size: 12px;
  margin-bottom: 16px;
  padding: 0 36px;
`;

export const TransactionConfirmInvoicesModal = ({ transaction, onClose }) => {
  const {
    appSettings: { currencyISOCode },
    orgId,
  } = useContext(AppContext);

  const {
    invoicesToUpdate,
    setHasConfirmedUnsentInvoices,
    transactionWithChangedData,
    onTransactionDeleted,
    showDeleteModal,
    loading,
    setLoading,
  } = useContext(TransactionContext);
  const { setShowInvoicesPreviewModal } = useContext(InvoicingScheduleChangesContext);

  const {
    operations: { removeInvoicingSchedule },
  } = useInvoicingSchedulesAPI({ orgId: orgId, autoFetch: false });

  const removeScheduleMode = invoicesToUpdate?.removeSchedule;

  const {
    data: invoicingSchedule,
    operations: { resyncInvoicingSchedule },
  } = useInvoicingScheduleAPI({
    orgId,
    invoicingScheduleId: transaction?.invoicing_schedule_id,
  });

  const handleScheduleResyncAfterTransactionDelete = async ({
    externalUpdatesMode = false,
    transactionIdsToRemove = [],
  }) => {
    setLoading(true);
    await resyncInvoicingSchedule({ data: { externalUpdatesMode, transactionIdsToRemove } });
    onClose();
    onTransactionDeleted?.({ deletedTransaction: transactionWithChangedData });
    setLoading(false);
  };

  const handleResyncInvoicingScheduleOnUpdate = async ({ externalUpdatesMode }) => {
    setHasConfirmedUnsentInvoices(true);
    setLoading(true);
    await resyncInvoicingSchedule({ data: { externalUpdatesMode } });
    setHasConfirmedUnsentInvoices(false);
    setLoading(false);
    onClose();
  };

  const handleConfirm = async () => {
    if (showDeleteModal) {
      if (removeScheduleMode) {
        await removeInvoicingSchedule.mutateAsync({ id: invoicingSchedule.id });
        onClose();
        onTransactionDeleted?.({ deletedTransaction: transactionWithChangedData });
      } else {
        setShowInvoicesPreviewModal(true);
      }
    } else {
      setShowInvoicesPreviewModal(true);
    }
  };

  const handleNoConfirm = async () => {
    if (showDeleteModal) {
      handleScheduleResyncAfterTransactionDelete({
        externalUpdatesMode: true,
        transactionIdsToRemove: [transactionWithChangedData?.id],
      });
    } else {
      handleResyncInvoicingScheduleOnUpdate({ externalUpdatesMode: true });
    }
  };

  const modalDescription = (
    <FlexerColumn alignItems="flex-start">
      <TransactionName>
        This transaction {transaction.name} ({transaction.id}) is being invoiced
      </TransactionName>

      {!!invoicingSchedule?.id && (
        <InvoiceChanges
          update={{ updateData: invoicesToUpdate, invoicingSchedule }}
          removeScheduleMode={removeScheduleMode}
          onClose={onClose}
          onConfirmUpdate={handleConfirm}
          onConfirmNoUpdate={handleNoConfirm}
        />
      )}
    </FlexerColumn>
  );

  return (
    <TransactionActionsModal
      modalName="transaction-update-invoices-modal"
      modalTitle={removeScheduleMode ? 'Want to remove invoicing schedule?' : 'Want to update unsent invoices?'}
      resourceType="transaction"
      resourceName={transactionDisplayName({ transaction, currency: currencyISOCode })}
      confirmBtnTitle={removeScheduleMode ? 'Yes, remove schedule' : 'Yes, update invoices'}
      cancelBtnTitle={removeScheduleMode ? "Don't remove schedule" : "Don't update invoices"}
      onConfirm={handleConfirm}
      onConfirmNoUpdate={handleNoConfirm}
      modalDescription={modalDescription}
      isLoading={loading}
    />
  );
};
