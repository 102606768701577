import styled from 'styled-components';
import { Centerer, FlexBetweenContainer, Flexer } from 'components/Core';

export const InvoiceItemsHeader = styled(Flexer)`
  align-items: center;
  gap: 16px;
  width: 100%;
  padding-right: 50px;
`;

export const InvoiceItemColumnTitle = styled.div`
  font-weight: 900;
  color: var(--primaryBlack);
  font-size: 12px;
  line-height: 16px;
  margin: 8px 0;
`;

export const InvoiceItemSubTitle = styled(FlexBetweenContainer)`
  color: var(--primaryBlack40);
  width: ${({ width }) => width ?? 'auto'};
  font-size: 12px;
  line-height: 16px;
  margin: 8px 0;
  flex-grow: ${({ flexGrow }) => flexGrow ?? 'auto'};
  justify-content: ${({ flexEnd }) => (flexEnd ? 'flex-end' : 'auto')};
`;

export const InvoiceItemRecognition = styled(Centerer)`
  width: 24px;
  height: 24px;

  background: #ffffff;
  border: 1px solid var(--primaryBlack5);
  border-radius: 8px;

  font-weight: 900;
  font-size: 9px;
  line-height: 12px;
  text-transform: uppercase;
  color: var(--primaryBlack30);
`;

export const DescriptionInput = styled.div`
  padding-left: 36px;
  padding-right: 50px;
`;
