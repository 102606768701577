import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import { pick } from 'lodash';
import { NUMBER_FORMATS } from 'consts/global';
import { EVENTS } from 'consts/analytics';
import { ReactComponent as BackgroundIcon } from 'images/delete-usage-events-bg.svg';
import { Flexer, FlexerColumn, Centerer, Spacer } from 'components/Core';
import { CircleCheckStrokeIcon, CirclePlusIcon } from 'components/Icons';
import { ModalContainer, Modal, ModalBody, ModalCloseIcon } from 'components/Modal';
import { getCustomerDisplayName } from 'models/customer';
import { numberFormatter } from 'utils/formatters';
import { useAnalytics, useClickOutside } from 'utils/hooks';
import { useUsageEventsAPI } from 'api/usageBasedEngine/hooks';
import { EventName } from './styles';

const ModalTitle = styled(Centerer)`
  font-size: 20px;
  font-weight: 900;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  margin: 36px 0 0;
`;

const StyledModal = styled(Modal)`
  padding: 6px;
`;

const StyledModalCloseIcon = styled(ModalCloseIcon)`
  box-shadow: 4px 4px 24px 0px #00152e0a;
  border-radius: 20px;
`;

const BackgroundIconWrapper = styled(Centerer)`
  border-radius: 20px;
  border: 1px;
  background: linear-gradient(0deg, rgba(0, 21, 46, 0.02), rgba(0, 21, 46, 0.02)),
    linear-gradient(0deg, rgba(0, 21, 46, 0.03), rgba(0, 21, 46, 0.03));
`;

const StyledModalBody = styled(ModalBody)`
  padding: 0 30px 36px 30px;
  border-radius: 20px;
  border: 1px;
`;

const Description = styled.div`
  margin-bottom: 8px;
  text-align: center;
`;

const EventSummary = styled(Flexer)`
  padding: 16px;
  border-radius: 8px;
  gap: 8px;
  background: var(--primaryRed5);
`;

const EventSummaryMain = styled(FlexerColumn)`
  flex: 1;
`;

const CustomerName = styled.span`
  font-size: 14px;
  font-weight: 900;
  line-height: 20px;
`;

const EventUnits = styled.span`
  font-size: 14px;
  font-weight: 900;
  line-height: 20px;
  color: var(--primaryRed);
`;

const AddButton = styled(Centerer)`
  background: var(--tertiaryRed);
  box-shadow: 0px 4px 24px 0px #ff5d5d33;
  cursor: ${({ disabled }) => (disabled ? 'none' : 'pointer')};
  color: white;
  font-weight: 700;
  padding: 8px 12px 8px 12px;
  border-radius: 100px;
  gap: 8px;
  opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};
`;

export const UsageEventDeleteModal = ({ onClose, usageEvent }) => {
  const modalRef = useClickOutside(onClose);

  const {
    operations: { deleteUsageEvent },
  } = useUsageEventsAPI({ orgId: usageEvent.organization_id, autoFetch: false });

  const negateCurrentEvent = async () => {
    await deleteUsageEvent.mutateAsync({
      data: {
        ...pick(usageEvent, ['customer_id', 'name', 'timestamp', 'notes', 'source']),
        units: -usageEvent.units,
      },
    });
    onClose();
  };

  return (
    <ModalContainer zIndex={49}>
      <StyledModal
        overflow="visible"
        width="440px"
        data-cy="usage-events-delete-modal"
        height="auto"
        maxHeight="auto"
        ref={modalRef}
      >
        <BackgroundIconWrapper>
          <BackgroundIcon />
        </BackgroundIconWrapper>
        <StyledModalCloseIcon onClose={onClose} />
        <StyledModalBody>
          <ModalTitle>Delete usage event</ModalTitle>
          <Description>Are you sure you want to delete this usage event?</Description>
          <EventSummary>
            <CirclePlusIcon color="var(--primaryRed)" size="20px" />
            <EventSummaryMain>
              <CustomerName>{getCustomerDisplayName({ customer: usageEvent.customer })}</CustomerName>
              <Spacer height="4px" />
              <Flexer>
                <EventName>{usageEvent.name}</EventName>
                <div> </div>
              </Flexer>
              <Spacer height="10px" />
              <p>{usageEvent.source}</p>
            </EventSummaryMain>
            <EventUnits>
              {numberFormatter({
                type: NUMBER_FORMATS.NUMBER,
                rawValue: usageEvent.units ?? 1,
              })}
            </EventUnits>
          </EventSummary>
          <Centerer>
            <AddButton
              onClick={negateCurrentEvent}
              disabled={deleteUsageEvent.isLoading}
              data-cy="usage-event-modal__delete"
            >
              {deleteUsageEvent.isLoading ? 'Deleting' : 'Delete'} the event
              <CircleCheckStrokeIcon size="18px" fill="white" />
            </AddButton>
          </Centerer>
        </StyledModalBody>
      </StyledModal>
    </ModalContainer>
  );
};

export const useUsageEventDeleteModal = () => {
  const { trackEvent } = useAnalytics();
  const [openParams, setOpenParams] = useState({});
  const [showModal, setShowModal] = useState(false);
  const openModal = useCallback(
    (openParams = {}) => {
      trackEvent({ name: EVENTS.OPEN_DELETE_USAGE_EVENT_MODAL });
      setOpenParams(openParams);
      setShowModal(true);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setShowModal],
  );
  const closeModal = useCallback(() => setShowModal(false), [setShowModal]);

  const Modal = useCallback(
    () => (showModal ? <UsageEventDeleteModal onClose={closeModal} {...openParams} /> : <></>),
    [showModal, closeModal, openParams],
  );

  return {
    openModal,
    UsageEventDeleteModal: Modal,
  };
};
