import React, { useContext, useState, useEffect, useMemo, useCallback } from 'react';
import styled from 'styled-components';
import { AppContext } from 'AppContext';
import { getAppSetting } from 'models/appSettings';
import { TimeLoader, TimeLoaderContainer } from 'components/Loaders';
import { UnconfirmedTransactionsBanner } from 'shared/Banners';
import { NoItems } from 'shared/ErrorBars';
import { TransactionSingleContent, TRANSACTION_MODAL_MODE } from 'shared/TransactionContent';
import { CustomerViewModal } from 'views/Customers/CustomerSingle/CustomerViewModal';
import { useRevenueDetailsAPI } from 'api/revenueDetails';
import { REVENUE_METRIC } from 'consts/revenueMetrics';
import { useCachedMetrics, useRecurringRevenueCachedMetrics } from 'utils/hooks';
import { formatYearMonth } from 'utils/dateUtils';
import { assertCloseTo, assertNotGreaterThan } from 'utils/assertionUtils';
import { convertToARR } from './utils';
import { MetricsCards } from './MetricsCards';
import RevenueDetailsChart from './RevenueDetailsChart';
import TotalRevenueCard from './TotalRevenueCard';
import CustomerTransactions from './CustomerTransactions';
import { PaddingWrapper, MetricCardsContainer, ChartAndTotalRevenueContainer } from './styled';

const MainContainer = styled.div`
  padding-bottom: 50px;
`;

export const UNIT_OPTIONS = {
  MONEY: 'revenue',
  CUSTOMERS: 'customers',
};

export const UPSELL_TYPES = {
  renewal: 'Renewal',
  nonRenewal: 'Non-renewal',
};

const RevenueDetailsContent = ({
  organization,
  revenueDetailsMonth,
  revenueDetailsYear,
  selectedMetric,
  segmentBy,
  timeseriesMonth,
  selectedSegment,
  transactionToShow,
  setTransactionToShow,
  viewAsARR,
  exportScreenshotRef,
  optimisticAnalytics,
  setOptimisticAnalytics,
  selectedUpsellType,
  summaryStartMonth,
}) => {
  const {
    organizations,
    metadataFilter,
    appSettings: { rollup, isCommitted, countInfluxAsRenewed, currencyISOCode },
  } = useContext(AppContext);
  const [customerIdToShow, setCustomerIdToShow] = useState(null);
  const [revenueDetailsMonthResults, setRevenueDetailsMonthResults] = useState(null);
  const [revenueDetailsYearResults, setRevenueDetailsYearResults] = useState(null);
  const [activeMetric, setActiveMetric] = useState(selectedMetric ?? REVENUE_METRIC.EXISTING.label);
  const [upsellTypes, setUpsellTypes] = useState(selectedUpsellType ?? Object.keys(UPSELL_TYPES));
  const influxMonths = getAppSetting('influxMonths');
  const hasTransactionsWithRenewalDeadline = organizations[0].hasTransactionsWithRenewalDeadline;

  const revenueMonth = `${revenueDetailsYear}-${revenueDetailsMonth}-15`;

  const requiredPlugins = [];
  if (upsellTypes.length === 1 && upsellTypes[0] === 'nonRenewal') {
    requiredPlugins.push('includeNonRenewalUpsell');
  }

  const { data, error, isLoading, refetchRevenueDetails } = useRevenueDetailsAPI({
    orgId: organizations?.[0]?.id,
    month: revenueDetailsMonth,
    year: revenueDetailsYear,
    selectedSegment,
    segmentBy,
    metadataFilter,
    rollup,
    requiredPlugins,
    isCommitted,
    optimisticAnalytics,
    includeLastTransactionUpdatedAt: true,
    summaryStartMonth,
    countInfluxAsRenewed,
    timeseriesMonth,
  });

  useEffect(() => {
    if (!data) return;
    setRevenueDetailsMonthResults(data);
    setRevenueDetailsYearResults(convertToARR(data));
  }, [organizations, data]);

  const handleTransactionClick = useCallback(
    async ({ customer, transactionId }) => {
      setTransactionToShow({ id: transactionId, customer_id: customer.id });
    },
    [setTransactionToShow],
  );

  const handleCustomerClick = useCallback(
    (customerId) => {
      setCustomerIdToShow(customerId);
    },
    [setCustomerIdToShow],
  );

  const revenueDetailsData = viewAsARR ? revenueDetailsYearResults : revenueDetailsMonthResults;

  const {
    start_month,
    end_month,
    previous_month,
    historical_data,
    last_recurring_total,
    total_recurring_revenue,
    transaction_data,
  } = revenueDetailsData || {};

  useRecurringRevenueCachedMetrics({
    readyData:
      !countInfluxAsRenewed && data && 'total_recurring_revenue' in data
        ? viewAsARR
          ? data.total_recurring_revenue * 12
          : data.total_recurring_revenue
        : null,
    getValue: ({ readyData }) => readyData,
    context: 'Revenue Details',
    isCommitted,
    rollup,
    month: revenueMonth,
    assertion: segmentBy ? assertNotGreaterThan : assertCloseTo,
  });

  useCachedMetrics({
    readyData: revenueDetailsData && 'transaction_data' in revenueDetailsData ? revenueDetailsData : null,
    getValue: ({ readyData }) => {
      const countMetric = (metricName) => {
        const value = readyData?.transaction_data?.[metricName];
        return value ? Object.keys(value).length : 0;
      };
      const baseCount = countMetric('Existing') + countMetric('Upsell') + countMetric('Downsell') + countMetric('New');
      return countInfluxAsRenewed ? baseCount + countMetric('Influx') : baseCount;
    },
    description: `Recurring customer count in ${formatYearMonth({
      year: revenueDetailsYear,
      month: revenueDetailsMonth,
    })}`,
    storageKey: `recurringCustomerCount.${rollup ? 'withRollup' : 'withoutRollup'}.${formatYearMonth({
      year: revenueDetailsYear,
      month: revenueDetailsMonth,
    })}`,
    context: 'Revenue Details',
    assertion: segmentBy ? assertNotGreaterThan : assertCloseTo,
  });

  const MemorizedCustomerTransactions = useMemo(
    () => (
      <CustomerTransactions
        transactionData={transaction_data}
        activeMetric={activeMetric}
        revenueMonth={revenueMonth}
        previousMonth={previous_month}
        viewAsARR={viewAsARR}
        onTransactionClick={handleTransactionClick}
        onCustomerClick={handleCustomerClick}
        revenueDetailsMonth={revenueDetailsMonth}
        revenueDetailsYear={revenueDetailsYear}
        optimisticAnalytics={optimisticAnalytics}
        setOptimisticAnalytics={setOptimisticAnalytics}
        upsellTypes={upsellTypes}
      />
    ),
    [
      transaction_data,
      previous_month,
      activeMetric,
      revenueMonth,
      viewAsARR,
      handleTransactionClick,
      handleCustomerClick,
      revenueDetailsMonth,
      revenueDetailsYear,
      optimisticAnalytics,
      setOptimisticAnalytics,
      upsellTypes,
    ],
  );

  return (
    <TimeLoaderContainer isLoading={isLoading}>
      {!error && (revenueDetailsData === null || isLoading) ? (
        <TimeLoader pageName="revenue" />
      ) : (
        <MainContainer>
          {!!error || Object.keys(revenueDetailsData).length === 0 ? (
            <NoItems entityName="Data" buttonCb={refetchRevenueDetails} organization={organization.id} />
          ) : (
            <div>
              <PaddingWrapper>{data?.hasUnconfirmedTransactions && <UnconfirmedTransactionsBanner />}</PaddingWrapper>

              <div ref={exportScreenshotRef}>
                {/* Metrics cards */}
                <MetricCardsContainer>
                  <MetricsCards
                    historicalData={historical_data}
                    transactionData={transaction_data}
                    endMonth={end_month}
                    activeMetric={activeMetric}
                    setActiveMetric={setActiveMetric}
                    influxMonths={influxMonths}
                    hasTransactionsWithRenewalDeadline={hasTransactionsWithRenewalDeadline}
                    countInfluxAsRenewed={countInfluxAsRenewed}
                  />
                </MetricCardsContainer>
                {/* Chart and Total Revenue */}
                <ChartAndTotalRevenueContainer>
                  <RevenueDetailsChart
                    historicalData={historical_data}
                    activeMetric={activeMetric}
                    summaryStartMonth={summaryStartMonth}
                    currency={currencyISOCode}
                  />
                  <TotalRevenueCard
                    historicalData={historical_data}
                    startMonth={start_month}
                    endMonth={end_month}
                    previousMonth={previous_month}
                    activeMetric={activeMetric}
                    totalRecurringRevenue={total_recurring_revenue}
                    lastRecurringTotal={last_recurring_total}
                    revenueMonth={revenueMonth}
                    chartUnits={UNIT_OPTIONS.MONEY}
                    upsellTypes={upsellTypes}
                    setUpsellType={setUpsellTypes}
                    summaryStartMonth={summaryStartMonth}
                  />
                </ChartAndTotalRevenueContainer>
                {/* Customer Transactions */}
                {MemorizedCustomerTransactions}
              </div>

              {!!transactionToShow && (
                <TransactionSingleContent
                  mode={TRANSACTION_MODAL_MODE.EDIT}
                  transaction={transactionToShow}
                  organization={organization}
                  closeModal={() => setTransactionToShow(null)}
                  onTransactionUpdated={refetchRevenueDetails}
                  onTransactionDeleted={() => setTransactionToShow(null)}
                  onTransactionDuplicated={({ transaction }) => setTransactionToShow(transaction)}
                />
              )}
              {customerIdToShow && (
                <CustomerViewModal customerId={customerIdToShow} onClose={() => setCustomerIdToShow(null)} />
              )}
            </div>
          )}
        </MainContainer>
      )}
    </TimeLoaderContainer>
  );
};

export default RevenueDetailsContent;
