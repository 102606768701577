import { ModalCloseIcon, ModalHeader, ModalSubtitleText, ModalTitleText } from 'components/Modal';
import { humanize } from 'utils/stringUtils';
import { StyledModalTitle } from './SyncedExportModalHeader.styles';

export const SyncedExportModalHeader = ({ type, onClose }) => (
  <ModalHeader>
    <ModalCloseIcon onClose={onClose} data-cy="synced-export-modal__close-button" />
    <StyledModalTitle>
      <ModalTitleText>
        <b>Sync into</b> External Product
        <ModalSubtitleText>Creating auto syncing public URL with {humanize(type)}</ModalSubtitleText>
      </ModalTitleText>
    </StyledModalTitle>
  </ModalHeader>
);
