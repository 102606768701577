import { css } from 'styled-components';

export const FormulaDisplayCSS = css`
  & span.formula-function {
    color: var(--secondaryGreen);
  }

  & span.formula-variable {
    color: var(--additionalPurple);
    background-color: var(--primaryPurple10);
    border-radius: 4px;
    padding: 0px 2px;
  }
`;
