import styled from 'styled-components';

export const InputErrorMessage = styled.div`
  padding: 10px;
  background: var(--primaryRed);
  position: ${(props) => (props.floatErrors ? 'absolute' : 'relative')};
  display: ${(props) => props.floatErrors && 'block'};
  z-index: ${(props) => props.floatErrors && '999'};
  box-shadow: 3px 3px 10px rgba(0, 21, 46, 0.15);
  border-radius: 4px;
  font-size: 14px;
  line-height: 18px;
  white-space: normal;
  color: #ffffff;
  text-align: center;
  margin-top: 10px;
  bottom: ${({ errorBottomPosition, floatErrors }) => errorBottomPosition ?? (floatErrors && '18px')};

  svg {
    position: absolute;
    transform: translate(-50%, 0);
    left: 50%;
    top: -4px;
  }
`;
