import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { useAuth0 } from '@auth0/auth0-react';
import { AppContext } from 'AppContext';
import { ReactComponent as DotsIcon } from 'images/dots.svg';
import {
  Popover,
  PopoverActions,
  PopoverButton,
  PopoverItemsContainer,
  PopoverListItem,
  PopoverPrompt,
  Portal as PortalContainer,
  usePortal,
} from 'components/Portal';
import { EditCircleIcon, ExternalLinkIcon } from 'components/Icons';
import { ReactComponent as LinkIcon } from 'images/link.svg';
import { Centerer, CentererVertical, FlexBetweenContainer, Row } from 'components/Core';
import { DIRECTIONS, TooltipContainer } from 'components/Tooltip';
import { CustomDatePicker } from 'components/Controls';
import { useToasts } from 'components/Toasts';
import { formatDateForDatepicker, updateDateFromDatePicker } from 'utils/dateUtils';
import { useAnalytics } from 'utils/hooks';
import { getModalInvoiceStatus } from 'views/Billing/InvoiceModal/utils';
import { BillingContext } from 'views/Billing/BillingContext';
import { BILLING_PAGES_ROUTES, INVOICE_STATUSES } from 'views/Billing/consts';
import { EVENTS } from 'consts/analytics';
import { shouldSendEmailFromSubscript } from 'views/Billing/utils';
import { InvoicingScheduleContext } from '../../InvoicingScheduleContext';
import { buildCreditNoteItemsFromInvoice } from '../../InvoicingScheduleCreditNotePanel/utils';
import { CreditNoteContext } from '../../CreditNoteContext';
import { DotsIconGreen, PanelHeaderButton } from '../../panel.styles';
import { useInternalNoteModal } from '../useInternalNoteModal';
import { useChargeManuallyModal } from '../useChargeManuallyModal';
import { INVOICING_SCHEDULE_TABS_PANEL_TABS } from '../consts';
import { PaidAtDatePicker, PaidAtDatePickerContainer, PaidAtLabel } from '../../InvoiceScheduleWarnings/styles';
import { INVOICE_ACTIONS, PRIMARY_INVOICE_DROPDOWN_ACTIONS, REMOVING_INVOICE_DROPDOWN_ACTIONS } from './consts';
import { findFirstInvoiceAfterRemoval, isActionAvailable } from './utils';

export const ActionsButton = styled.div`
  display: flex;
  align-items: center;
  padding: 6px;
  opacity: 0.2;
  justify-content: center;
  border-radius: 100px;
  background-color: ${({ isSelected }) => isSelected && 'var(--primaryBlue10)'};
  pointer-events: ${(props) => props.disabled && 'none'};

  &:hover {
    opacity: 1;
  }

  svg {
    width: 20px;
    height: 20px;

    path {
      fill: var(--primaryBlue);
    }
  }
`;

const Container = styled.div`
  position: relative;
`;

const StyledPopoverItemsContainer = styled(PopoverItemsContainer)`
  border-left: ${({ isFirst }) => (isFirst ? 'none' : '1px solid var(--accentGraySecond)')};
`;

export const ListItemWrapper = styled(PopoverListItem)`
  pointer-events: auto;
  opacity: ${({ disabled }) => disabled && '0.3'};
  cursor: ${({ disabled }) => disabled && 'default'};

  svg {
    width: 16px;
    height: 16px;

    g {
      opacity: 1;
    }

    path {
      fill: ${({ isTrashItem }) => (isTrashItem ? 'var(--primaryRed50)' : 'var(--primaryBlack50)')};
    }
  }

  &:last-child {
    border-bottom: none;
  }
`;

const TitleTag = styled.div`
  border-radius: 4px;
  border: 1px solid var(--neutralGray);
  padding: 4px;
  font-weight: 900;
  font-size: 9px;
  color: var(--primaryBlack50);
  width: fit-content;
  line-height: 12px;
  margin: 12px 0px 6px 12px;
`;

const LIST_ITEM_HEIGHT = 40;

export const MarkAsPaidPopoverBody = ({ paidAt, setPaidAt, bulk = false, onClose, onSubmit }) => (
  <>
    <PaidAtDatePickerContainer>
      <CustomDatePicker
        fixedHeight
        name="invoice-modal-paid_at"
        hideCalendarIcon
        selected={paidAt ? formatDateForDatepicker(paidAt) : null}
        onChange={(date) => setPaidAt(updateDateFromDatePicker(date))}
        renderCustomInput={({ inputRef }) => (
          <PaidAtDatePicker ref={inputRef} gap="32px">
            <PaidAtLabel>Paid on:</PaidAtLabel>
            <CentererVertical gap="8px">
              {dayjs.utc(paidAt).format('MMM DD, YYYY')}
              <EditCircleIcon size="13px" fill="white" />
            </CentererVertical>
          </PaidAtDatePicker>
        )}
      />
    </PaidAtDatePickerContainer>
    <PopoverPrompt>Are you sure you want to mark {bulk ? 'those invoices' : 'this invoice'} as paid?</PopoverPrompt>
    <PopoverActions>
      <PopoverButton onClick={onClose}>No</PopoverButton>
      <PopoverButton data-cy="invoice-actions--mark-as-paid--confirm-yes" onClick={onSubmit} primary>
        Yes
      </PopoverButton>
    </PopoverActions>
  </>
);

const InvoiceActionTooltip = ({
  action,
  userEmail,
  invoiceExternalId,
  invoiceFormValues,
  customerDetails,
  modalInvoiceStatus,
  invoicingScheduleFormValues,
  children,
}) => {
  // Must be synced with isChargeManuallyEnabled
  const getChargeManuallyTooltipContent = () => {
    if ([INVOICE_STATUSES.PAID, INVOICE_STATUSES.VOID].includes(modalInvoiceStatus))
      return 'The invoice is already paid or void';
    if (!invoiceFormValues?.id) return 'Invoices must be saved before they can be charged';
    if (!invoicingScheduleFormValues?.auto_charge) return 'The invoicing schedule is not set to auto-charge';
    if (!customerDetails?.has_active_payment_method) return 'The customer does not have an active payment method';
    return "We'll charge the customer for this invoice right now";
  };

  const getChargeManuallyTooltipYOffset = () => {
    if ([INVOICE_STATUSES.PAID, INVOICE_STATUSES.VOID].includes(modalInvoiceStatus)) return 5;

    return 20;
  };

  const TOOLTIP_CONTENT_BY_ACTION = {
    [INVOICE_ACTIONS.CREDIT_NOTE]: 'Create and apply a credit note to this invoice',
    [INVOICE_ACTIONS.CHARGE_MANUALLY]: "We'll charge the customer for this invoice right now",
    [INVOICE_ACTIONS.TEST_SEND]: `Send the email to yourself: ${userEmail}`,
    [INVOICE_ACTIONS.DELETE]: 'Only invoices not sent to the accounting system can be deleted',
    [INVOICE_ACTIONS.DETACH]: 'The invoice will be removed from Subscript, but will stay in your accounting system',
    [INVOICE_ACTIONS.CHARGE_MANUALLY]: getChargeManuallyTooltipContent(),
  };

  const TOOLTIP_Y_OFFEST_BY_ACTION = {
    [INVOICE_ACTIONS.CREDIT_NOTE]: 40,
    [INVOICE_ACTIONS.CHARGE_MANUALLY]: getChargeManuallyTooltipYOffset(),
    [INVOICE_ACTIONS.TEST_SEND]: 15,
    [INVOICE_ACTIONS.DELETE]: 30,
    [INVOICE_ACTIONS.DETACH]: 35,
  };

  return (
    <TooltipContainer
      width={200}
      direction={DIRECTIONS.TOP}
      hideArrow
      yOffset={TOOLTIP_Y_OFFEST_BY_ACTION[action]}
      fontSize="12px"
      toolTipContent={TOOLTIP_CONTENT_BY_ACTION[action]}
      isVisible={
        !!TOOLTIP_CONTENT_BY_ACTION[action] && (action === INVOICE_ACTIONS.DELETE ? !!invoiceExternalId : true)
      }
    >
      <Row>{children}</Row>
    </TooltipContainer>
  );
};

export const InvoiceActions = ({ isSelected, headerView }) => {
  const { pushToast } = useToasts();
  const {
    validateInvoiceFormAndExecuteAction,
    setSelectedInvoiceId,
    setFetchedSelectedInvoice,
    invoiceFormValues,
    invoicingScheduleFormValues,
    fillScheduleWithDraftInvoices,
    setInvoiceUpdateLoading,
    invoicingService,
    openInvoicePreviewModal,
    customerDetails,
    setSelectedTabsPanelTab,
    currentInvoicingSchedule,
    setIsHistoryTabCollapsed,
    invoicingServiceDisplayName,
  } = useContext(InvoicingScheduleContext);

  const {
    markInvoiceAsPaid,
    markInvoiceAsUnpaid,
    markInvoiceAsVoid,
    removeInvoice,
    markInvoiceAsSent,
    testSendInvoice,
  } = useContext(BillingContext);

  const LINK_INVOICE_DROPDOWN_ACTIONS = {
    [INVOICE_ACTIONS.VIEW_INVOICES_IN_EXTERNAL_SYSTEM]: {
      title: `In ${invoicingServiceDisplayName}`,
      icon: <ExternalLinkIcon />,
    },
    [INVOICE_ACTIONS.COPY_INVOICES_SUBSCRIPT_LINK]: {
      title: 'In Subscript',
      icon: <LinkIcon />,
    },
  };

  const { addDraftCreditNote } = useContext(CreditNoteContext);

  const { trackEvent } = useAnalytics();

  const { orgConfigs } = useContext(AppContext);
  const { user = {} } = useAuth0();

  const { Modal: InternalNoteModal, openModal: openInternalNoteModal } = useInternalNoteModal({
    values: invoiceFormValues,
  });

  const { Modal: ChargeManuallyModal, openModal: openChargeManuallyModal } = useChargeManuallyModal({
    values: invoiceFormValues,
  });

  const [showMarkAsPaidPopover, setShowMarkAsPaidPopover] = useState(false);
  const [paidAt, setPaidAt] = useState(new Date());
  const toggleMarkAsPaidPopover = () => setShowMarkAsPaidPopover((prevValue) => !prevValue);

  const { triggerRef, togglePortal, isPortalVisible, hidePortal, Portal } = usePortal({
    hideOnOutsideClick: true,
    XOffset: headerView ? 55 : -15,
    YOffset: 46,
  });

  const sendEmailFromSubscript = shouldSendEmailFromSubscript({ orgConfigs });

  const handleMarkAsPaid = async (paidAt) => {
    toggleMarkAsPaidPopover();

    if (invoiceFormValues?.id) {
      await markInvoiceAsPaid({ invoice: invoiceFormValues, paidAt });
    } else {
      fillScheduleWithDraftInvoices({
        invoices: invoicingScheduleFormValues?.invoices?.map((mapInovice) => {
          let invoiceForReturn = mapInovice;
          if (mapInovice?.unsavedId === invoiceFormValues?.unsavedId) {
            invoiceForReturn = { ...invoiceFormValues, paid_at: paidAt };
          }
          return invoiceForReturn;
        }),
      });
    }
  };

  const [showVoidedPopover, setShowVoidedPopover] = useState(false);
  const toggleVoidedPopover = () => setShowVoidedPopover((prevValue) => !prevValue);
  const handleVoided = async () => {
    toggleVoidedPopover();

    try {
      setInvoiceUpdateLoading(true);
      await markInvoiceAsVoid({ invoice: invoiceFormValues });
      // So we're not showing the voided invoice

      setSelectedInvoiceId(null);
      setFetchedSelectedInvoice(null);
    } finally {
      setInvoiceUpdateLoading(false);
    }
  };

  const [showMarkAsUnpaidPopover, setShowMarkAsUnpaidPopover] = useState(false);
  const toggleMarkAsUnpaidPopover = () => setShowMarkAsUnpaidPopover((prevValue) => !prevValue);
  const handleUnpaid = async () => {
    toggleMarkAsUnpaidPopover();

    await markInvoiceAsUnpaid({ invoice: invoiceFormValues });
  };

  const [showDeletePopover, setShowDeletePopover] = useState(false);
  const toggleDeletePopover = () => setShowDeletePopover((prevValue) => !prevValue);
  const handleDelete = async () => {
    toggleDeletePopover();

    if (invoiceFormValues?.id) {
      try {
        setInvoiceUpdateLoading(true);
        await removeInvoice({
          id: invoiceFormValues?.id,
        });
        findFirstInvoiceAfterRemoval({
          removedInvoicesIds: [invoiceFormValues?.id],
          invoicingScheduleFormValues,
          setSelectedInvoiceId,
          setFetchedSelectedInvoice,
        });
      } catch (err) {
        throw err;
      } finally {
        setInvoiceUpdateLoading(false);
      }
    } else {
      await fillScheduleWithDraftInvoices({
        invoices: invoicingScheduleFormValues?.invoices?.filter(
          (mapInovice) => mapInovice?.unsavedId !== invoiceFormValues?.unsavedId,
        ),
      });
    }
  };

  const [showDetachPopover, setShowDetachPopover] = useState(false);
  const toggleDetachPopover = () => setShowDetachPopover((prevValue) => !prevValue);
  const handleDetach = async () => {
    toggleDetachPopover();

    try {
      setInvoiceUpdateLoading(true);
      if (currentInvoicingSchedule?.id) {
        await removeInvoice({
          id: invoiceFormValues?.id,
          params: { keepInExternalSystem: true },
        });
      } else {
        // it's not actually saved yet
        fillScheduleWithDraftInvoices({
          invoices: invoicingScheduleFormValues?.invoices?.filter(
            (filterInvoice) => filterInvoice?.unsavedId !== invoiceFormValues?.unsavedId,
          ),
        });
      }
      findFirstInvoiceAfterRemoval({
        removedInvoicesIds: [invoiceFormValues?.id],
        invoicingScheduleFormValues,
        setSelectedInvoiceId,
        setFetchedSelectedInvoice,
      });
    } catch (err) {
      throw err;
    } finally {
      setInvoiceUpdateLoading(false);
    }
  };

  const [showMarkAsSentPopover, setShowMarkAsSentPopover] = useState(false);
  const toggleMarkAsSentPopover = () => setShowMarkAsSentPopover((prevValue) => !prevValue);
  const handleMarkAsSent = async () => {
    toggleMarkAsSentPopover();

    if (invoiceFormValues?.id) {
      await markInvoiceAsSent({ invoice: invoiceFormValues });
    } else {
      fillScheduleWithDraftInvoices({
        invoices: invoicingScheduleFormValues?.invoices?.map((mapInovice) => {
          let invoiceForReturn = mapInovice;
          if (mapInovice?.unsavedId === invoiceFormValues?.unsavedId) {
            invoiceForReturn = { ...invoiceFormValues, sent_at: dayjs().toDate() };
          }
          return invoiceForReturn;
        }),
      });
    }
  };

  const modalInvoiceStatus = invoiceFormValues && getModalInvoiceStatus({ invoice: invoiceFormValues });

  const isChargeManuallyEnabled =
    invoiceFormValues?.id &&
    customerDetails?.has_active_payment_method &&
    invoicingScheduleFormValues?.auto_charge &&
    ![INVOICE_STATUSES.PAID, INVOICE_STATUSES.VOID].includes(modalInvoiceStatus);

  const handleActionSelect = ({ action }) => {
    switch (action) {
      case INVOICE_ACTIONS.TEST_SEND:
        validateInvoiceFormAndExecuteAction({
          action: async () => await testSendInvoice({ invoice: invoiceFormValues, user }),
        });

        break;
      case INVOICE_ACTIONS.INTERNAL_NOTE:
        hidePortal();
        openInternalNoteModal();
        break;
      case INVOICE_ACTIONS.MARK_SENT:
        hidePortal();
        toggleMarkAsSentPopover();
        break;
      case INVOICE_ACTIONS.MARK_PAID:
        hidePortal();
        toggleMarkAsPaidPopover();
        break;
      case INVOICE_ACTIONS.MARK_UNPAID:
        hidePortal();
        toggleMarkAsUnpaidPopover();
        break;
      case INVOICE_ACTIONS.CREDIT_NOTE:
        addDraftCreditNote({
          allocated_invoice: invoiceFormValues?.id,
          items: buildCreditNoteItemsFromInvoice(invoiceFormValues, invoicingService),
        });
        setSelectedTabsPanelTab(INVOICING_SCHEDULE_TABS_PANEL_TABS.CREDIT_NOTES);
        break;
      case INVOICE_ACTIONS.CHARGE_MANUALLY:
        if (isChargeManuallyEnabled) {
          hidePortal();
          openChargeManuallyModal();
        }
        break;
      case INVOICE_ACTIONS.VOID:
        hidePortal();
        toggleVoidedPopover();
        break;
      case INVOICE_ACTIONS.VIEW_HISTORY:
        trackEvent({
          name: EVENTS.OPEN_INVOICE_HISTORY,
          properties: {
            invoiceId: invoiceFormValues?.id,
          },
        });
        hidePortal();
        setIsHistoryTabCollapsed(false);
        break;
      case INVOICE_ACTIONS.DELETE:
        hidePortal();
        toggleDeletePopover();
        break;
      case INVOICE_ACTIONS.DETACH:
        hidePortal();
        toggleDetachPopover();
        break;
      case INVOICE_ACTIONS.VIEW_INVOICES_IN_EXTERNAL_SYSTEM:
        window.open(invoiceFormValues?.invoice_external_url, '_blank');
        break;
      case INVOICE_ACTIONS.COPY_INVOICES_SUBSCRIPT_LINK:
        navigator.clipboard.writeText(
          `${window.location.origin}${BILLING_PAGES_ROUTES.INVOICES}/${invoiceFormValues?.id}`,
        );
        pushToast('Copied invoice link to clipboard successfully', 'success');
        break;
      case INVOICE_ACTIONS.REMIND:
        openInvoicePreviewModal();
        hidePortal();
        break;
      default:
    }
  };

  const { top, left, height, bottom } = triggerRef?.current?.getBoundingClientRect() ?? {};

  const remainingHeight = window.innerHeight - bottom;

  const portalContentStyles = {
    zIndex: 999,
    position: 'absolute',
    left: left - document.getElementById('main-navigation')?.offsetWidth,
    top: top + height + document.getElementById('main-content')?.scrollTop,
  };

  const isNotSendOrRemindInvoice = ![INVOICE_STATUSES.SENT, INVOICE_STATUSES.REMIND].includes(modalInvoiceStatus);

  const isProcessingInvoice = [INVOICE_STATUSES.QUEUED_UP, INVOICE_STATUSES.PROCESSING].includes(modalInvoiceStatus);

  const menuHeight =
    Math.max(
      Object.keys(PRIMARY_INVOICE_DROPDOWN_ACTIONS).filter((key) =>
        isActionAvailable({
          headerView,
          action: key,
          invoicingService,
          invoiceFormValues,
          modalInvoiceStatus,
          isNotSendOrRemindInvoice,
          sendEmailFromSubscript,
        }),
      ).length * LIST_ITEM_HEIGHT,
      Object.keys(REMOVING_INVOICE_DROPDOWN_ACTIONS).filter((key) =>
        isActionAvailable({
          headerView,
          action: key,
          invoicingService,
          invoiceFormValues,
          modalInvoiceStatus,
          isNotSendOrRemindInvoice,
          sendEmailFromSubscript,
        }),
      ).length * LIST_ITEM_HEIGHT,
      // adding the menu header height
    ) + LIST_ITEM_HEIGHT;

  let actionsCategories = [
    { title: 'PRIMARY', values: PRIMARY_INVOICE_DROPDOWN_ACTIONS },
    { title: 'REMOVING', values: REMOVING_INVOICE_DROPDOWN_ACTIONS },
  ];

  if (
    Object.keys(LINK_INVOICE_DROPDOWN_ACTIONS).some((action) =>
      isActionAvailable({
        headerView,
        action,
        invoicingService,
        invoiceFormValues,
        modalInvoiceStatus,
        isNotSendOrRemindInvoice,
        sendEmailFromSubscript,
      }),
    )
  ) {
    actionsCategories.push({ title: 'LINK', values: LINK_INVOICE_DROPDOWN_ACTIONS });
  }

  return (
    <>
      <Container ref={triggerRef}>
        {headerView ? (
          <PanelHeaderButton
            data-cy="invoicing-schedule-modal__invoice-actions__more-actions-toggle"
            onClick={togglePortal}
            disabled={isProcessingInvoice}
          >
            <Centerer gap="8px">
              More Actions
              <DotsIconGreen active={isPortalVisible} width="20px" height="20px" />
            </Centerer>
          </PanelHeaderButton>
        ) : (
          <ActionsButton
            data-cy="invoicing-schedule-modal__invoice-actions__more-actions-toggle-dots"
            onClick={togglePortal}
            isSelected={isSelected}
            disabled={isProcessingInvoice}
          >
            <DotsIcon />
          </ActionsButton>
        )}

        {isPortalVisible && (
          <Portal data-cy="invoicing-schedule-modal__invoice-actions__portal">
            <Popover
              width={actionsCategories.length < 3 ? '300px' : '400px'}
              YOffset={remainingHeight < menuHeight + 10 ? -menuHeight : 50}
            >
              <FlexBetweenContainer>
                {actionsCategories.map((actions, index) => (
                  <StyledPopoverItemsContainer key={actions.title} isFirst={index === 0}>
                    <TitleTag>{actions.title}</TitleTag>
                    {Object.entries(actions.values).map(
                      ([key, item]) =>
                        isActionAvailable({
                          headerView,
                          action: key,
                          invoicingService,
                          invoiceFormValues,
                          modalInvoiceStatus,
                          isNotSendOrRemindInvoice,
                          sendEmailFromSubscript,
                        }) && (
                          <ListItemWrapper
                            isTrashItem={[
                              INVOICE_ACTIONS.VOID,
                              INVOICE_ACTIONS.DELETE,
                              INVOICE_ACTIONS.DETACH,
                            ].includes(key)}
                            key={item?.title}
                            onClick={() => handleActionSelect({ action: key })}
                            disabled={key === INVOICE_ACTIONS.CHARGE_MANUALLY ? !isChargeManuallyEnabled : false}
                            data-cy={`invoicing-schedule-modal__invoice-actions__action--${key}`}
                          >
                            <InvoiceActionTooltip
                              action={key}
                              invoiceFormValues={invoiceFormValues}
                              customerDetails={customerDetails}
                              invoicingScheduleFormValues={invoicingScheduleFormValues}
                              modalInvoiceStatus={modalInvoiceStatus}
                              invoiceExternalId={invoiceFormValues?.invoice_external_id}
                              userEmail={user?.email}
                            >
                              {item?.icon}
                              {item?.title}
                            </InvoiceActionTooltip>
                          </ListItemWrapper>
                        ),
                    )}
                  </StyledPopoverItemsContainer>
                ))}
              </FlexBetweenContainer>
            </Popover>
          </Portal>
        )}

        {showMarkAsPaidPopover && (
          <PortalContainer>
            <div style={portalContentStyles}>
              <Popover XOffset={0} YOffset={remainingHeight < 150 ? -180 : 10} zIndex={51} darkMode width="220px">
                {MarkAsPaidPopoverBody({
                  paidAt,
                  setPaidAt,
                  onClose: toggleMarkAsPaidPopover,
                  onSubmit: () => handleMarkAsPaid(paidAt),
                })}
              </Popover>
            </div>
          </PortalContainer>
        )}

        {showMarkAsUnpaidPopover && (
          <PortalContainer>
            <div style={portalContentStyles}>
              <Popover XOffset={0} YOffset={remainingHeight < 115 ? -150 : 10} zIndex={51} darkMode width="200px">
                <PopoverPrompt>
                  Are you sure you want to mark this invoice unpaid? This change will not be reflected in your GL
                </PopoverPrompt>
                <PopoverActions>
                  <PopoverButton onClick={toggleMarkAsUnpaidPopover}>No</PopoverButton>
                  <PopoverButton data-cy="invoice-actions--unpaid--confirm-yes" onClick={handleUnpaid} primary>
                    Yes
                  </PopoverButton>
                </PopoverActions>
              </Popover>
            </div>
          </PortalContainer>
        )}

        {showVoidedPopover && (
          <PortalContainer>
            <div style={portalContentStyles}>
              <Popover XOffset={0} YOffset={remainingHeight < 95 ? -130 : 10} zIndex={51} darkMode width="200px">
                <PopoverPrompt>Are you sure you want to void this invoice?</PopoverPrompt>
                <PopoverActions>
                  <PopoverButton onClick={toggleVoidedPopover}>No</PopoverButton>
                  <PopoverButton data-cy="invoice-actions--void--confirm-yes" onClick={handleVoided} primary>
                    Yes
                  </PopoverButton>
                </PopoverActions>
              </Popover>
            </div>
          </PortalContainer>
        )}

        {showDeletePopover && (
          <PortalContainer>
            <div style={portalContentStyles}>
              <Popover XOffset={0} YOffset={remainingHeight < 95 ? -130 : 10} zIndex={51} darkMode width="200px">
                <PopoverPrompt>Are you sure you want to delete this invoice?</PopoverPrompt>
                <PopoverActions>
                  <PopoverButton onClick={toggleDeletePopover}>No</PopoverButton>
                  <PopoverButton data-cy="invoice-actions--delete--confirm-yes" onClick={handleDelete} primary>
                    Yes
                  </PopoverButton>
                </PopoverActions>
              </Popover>
            </div>
          </PortalContainer>
        )}

        {showDetachPopover && (
          <PortalContainer>
            <div style={portalContentStyles}>
              <Popover XOffset={0} YOffset={remainingHeight < 115 ? -150 : 10} zIndex={51} darkMode width="200px">
                <PopoverPrompt>
                  Are you sure you want to remove this invoice from Subscript (but keep it in{' '}
                  {invoicingServiceDisplayName}?)
                </PopoverPrompt>
                <PopoverActions>
                  <PopoverButton onClick={toggleDetachPopover}>No</PopoverButton>
                  <PopoverButton data-cy="invoice-actions--detach--confirm-yes" onClick={handleDetach} primary>
                    Yes
                  </PopoverButton>
                </PopoverActions>
              </Popover>
            </div>
          </PortalContainer>
        )}

        {showMarkAsSentPopover && (
          <PortalContainer>
            <div style={portalContentStyles}>
              <Popover XOffset={0} YOffset={remainingHeight < 95 ? -130 : 10} zIndex={51} darkMode width="200px">
                <PopoverPrompt>Are you sure you want to mark this invoice as sent?</PopoverPrompt>
                <PopoverActions>
                  <PopoverButton onClick={toggleMarkAsSentPopover}>No</PopoverButton>
                  <PopoverButton
                    data-cy="invoicing-schedule-modal__confirm-mark-as-sent-option"
                    onClick={handleMarkAsSent}
                    primary
                  >
                    Yes
                  </PopoverButton>
                </PopoverActions>
              </Popover>
            </div>
          </PortalContainer>
        )}
      </Container>

      <InternalNoteModal />
      <ChargeManuallyModal />
    </>
  );
};
