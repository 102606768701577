import { useCallback, useState } from 'react';
import { CreditNoteSendModal } from './CreditNoteSendModal';

export const useCreditNoteSendModal = ({ onSaveCreditNote } = {}) => {
  const [showModal, setShowModal] = useState(false);

  const openModal = useCallback(() => setShowModal(true), []);
  const closeModal = useCallback(() => setShowModal(false), []);

  const Modal = useCallback(
    ({ onSaveCreditNote: _onSaveCreditNote } = {}) =>
      showModal ? (
        <CreditNoteSendModal onClose={closeModal} onSaveCreditNote={_onSaveCreditNote ?? onSaveCreditNote} />
      ) : null,
    [closeModal, onSaveCreditNote, showModal],
  );

  return {
    openModal,
    closeModal,
    Modal,
    isModalOpen: showModal,
  };
};
