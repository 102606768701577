import dayjs from 'dayjs';
import React from 'react';
import { PresetOption, PresetsWrapper } from './styles';

export const Presets = ({ selected, presetStartDate, onOptionClick }) => {
  const formattedPresetStart = dayjs(presetStartDate).format('MMM D, YYYY');
  const options = [
    {
      value: 1,
      unit: 'month',
    },
    {
      value: 3,
      unit: 'month',
    },
    {
      value: 6,
      unit: 'month',
    },
    {
      value: 1,
      unit: 'year',
    },
    {
      value: 2,
      unit: 'year',
    },
  ];

  return (
    <PresetsWrapper>
      {options.map(({ value, unit }) => {
        const optionDate = dayjs(presetStartDate).add(value, unit).subtract(1, 'day');
        return (
          <PresetOption
            key={`${value}${unit}`}
            selected={dayjs(selected).isSame(optionDate)}
            onClick={() => onOptionClick(optionDate.toDate())}
            data-cy={`custom-date-picker--presets__option-${unit}-${value}`}
          >
            {`+${value} ${unit}${value > 1 ? 's' : ''}`}
            <span>{`${formattedPresetStart} – ${optionDate.format('MMM D, YYYY')}`}</span>
          </PresetOption>
        );
      })}
    </PresetsWrapper>
  );
};
