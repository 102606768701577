import React, { useState, useEffect, useContext } from 'react';
import dayjs from 'dayjs';
import styled from 'styled-components';

import { AppContext } from 'AppContext';
import { Spacer } from 'components/Core';
import { Modal, ModalBody, ModalCloseIcon, ModalHeader, ModalTitle, ModalContainer } from 'components/Modal';
import { Loader } from 'components/Loaders';
import { getUpForRenewalData } from 'api';

import { convertToARR } from './UpForRenewal/utils';
import { MetricsCardsSection } from './UpForRenewal/MetricsCardsSection';
import { UpForRenewalTransactionsTable } from './UpForRenewal/UpForRenewalTransactionsTable';

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Month = styled.span`
  margin-left: 12px;
  color: var(--primaryGreen);
`;

export const UpForRenewalModal = ({
  organization,
  closeModal,
  selectedMonth,
  summaryStartMonth,
  summaryColumnHeader,
  isARR,
}) => {
  const {
    metadataFilter,
    appSettings: { isCommitted, countInfluxAsRenewed, optimisticAnalytics, rollup, currencyISOCode: currency },
  } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(true);
  const [upForRenewalData, setUpForRenewalData] = useState({});
  const selectedMonthDayJS = dayjs(`${selectedMonth}-01`);

  const fetchUpForRenewalData = async () => {
    setIsLoading(true);

    const params = {
      month: selectedMonthDayJS.format('YYYY-MM-DD'),
      isCommitted,
      optimisticAnalytics,
      countInfluxAsRenewed,
      rollup,
      summaryStartMonth,
      customerMetadata: JSON.stringify(metadataFilter?.customerMetadata),
      transactionMetadata: JSON.stringify(metadataFilter?.transactionMetadata),
    };

    const result = await getUpForRenewalData(organization.id, params);
    const data = isARR ? convertToARR(result) : result;
    setUpForRenewalData(data);

    setIsLoading(false);
  };

  useEffect(() => {
    fetchUpForRenewalData();
    // eslint-disable-next-line
  }, []);

  return (
    <ModalContainer data-cy="up-for-renewal-modal">
      <Modal width="90%" height="90vh" overflow="auto">
        <ModalHeader>
          <ModalCloseIcon data-cy="up-for-renewal-modal__close" onClose={closeModal} />
          <ModalTitle>
            <HeaderContainer>
              <b>
                Up for renewal:
                <Month>{summaryColumnHeader}</Month>
              </b>
            </HeaderContainer>
          </ModalTitle>
        </ModalHeader>

        <Spacer height="20px" />

        <ModalBody gap="32px">
          {isLoading ? (
            <div className="w-100 flexer">
              <Loader containerStyles={{ width: 40 }} />
            </div>
          ) : (
            <>
              <MetricsCardsSection upForRenewalData={upForRenewalData} />
              <UpForRenewalTransactionsTable
                upForRenewalData={upForRenewalData}
                isARR={isARR}
                currency={currency}
                selectedMonthDayJS={selectedMonthDayJS}
              />
            </>
          )}
        </ModalBody>
      </Modal>
    </ModalContainer>
  );
};
