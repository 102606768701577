import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { useExpanded, useGlobalFilter, useTable } from 'react-table';
import styled from 'styled-components';
import { NUMBER_FORMATS } from 'consts/global';
import { numberFormatter } from 'utils/formatters';
import { InlineButton, TableActionButton } from 'components/Buttons';
import { CentererVertical } from 'components/Core';
import {
  ReactTableBody,
  ReactTableHeader,
  ReactTableHeaderColumn,
  ReactTableHeaderRow,
  ReactTableCell,
  ReactTableRow,
  EmptyTableContent,
  EmptyTableContentAction,
  customGlobalFilter,
} from 'components/Table';
import { TransactionsList } from './TransactionsList';
import { ScheduleNumber } from './styles';
import { ExpandableRow } from '../Common/ExpandableRow';
import { BillingContext } from '../BillingContext';

const OpenCustomerButton = styled(InlineButton)`
  margin-left: 12px;
`;

const ClickableScheduleNumber = styled(ScheduleNumber)`
  cursor: pointer;
`;

export const InvoicingSchedulesTable = ({ currency, dataForTable, setTableRows }) => {
  const { openInvoicingScheduleModal } = useContext(BillingContext);

  const columns = useMemo(
    () => [
      {
        Header: 'Invoices',
        accessor: 'schedule_id',
        id: 'schedule_id',
        width: 200,
        Cell: ({ row }) =>
          row.canExpand ? (
            <CentererVertical>
              <ExpandableRow
                label={row.original.name}
                totalAmount={row.original.totalAmount}
                currency={currency}
                unitCount={row.originalSubRows.length}
                unitName="transaction"
                isExpanded={row.isExpanded}
                {...row.getToggleRowExpandedProps()}
              />
              <OpenCustomerButton onClick={() => window.open(`/customers/${row.original.id}`, '_blank')} isSecondary>
                Open customer
              </OpenCustomerButton>
            </CentererVertical>
          ) : (
            <ClickableScheduleNumber
              data-cy={`schedules-table__open-customer-${row?.original?.customer_id}-schedule`}
              onClick={() => openInvoicingScheduleModal({ invoicingSchedule: row.original })}
            >
              View all related invoices
            </ClickableScheduleNumber>
          ),
      },
      {
        Header: 'Transactions',
        accessor: 'transactions',
        id: 'transactions',
        width: 300,
        Cell: ({ row }) => (row.canExpand ? '' : <TransactionsList transactions={row.original.transactions} />),
      },
      {
        Header: 'Amount',
        accessor: 'amount',
        id: 'amount',
        width: 30,
        Cell: ({ row }) =>
          row.canExpand
            ? ''
            : numberFormatter({
                type: NUMBER_FORMATS.CURRENCY,
                rawValue: row.original.totalAmount,
                currency,
              }),
      },
      {
        Header: 'Frequency',
        accessor: 'frequency',
        id: 'frequency',
        width: 90,
        Cell: ({ row }) => (row.canExpand ? '' : row.original.invoicing_frequency ?? ''),
      },
      {
        Header: 'Invoices',
        accessor: 'invoices',
        id: 'invoices',
        width: 10,
        Cell: ({ row }) => (row.canExpand ? '' : row.original.invoices?.length ?? 0),
      },
      {
        Header: '',
        accessor: 'actions',
        id: 'actions',
        width: 100,
        Cell: ({ row }) =>
          row.canExpand ? (
            ''
          ) : (
            <TableActionButton onClick={() => openInvoicingScheduleModal({ invoicingSchedule: row.original })} active>
              Show Details
            </TableActionButton>
          ),
      },
    ],
    [currency, openInvoicingScheduleModal],
  );

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows, toggleAllRowsExpanded } = useTable(
    {
      columns,
      data: dataForTable,
      getSubRows: useCallback((row) => row.subRows || [], []),
      globalFilter: customGlobalFilter,
      expandSubRows: true,
    },
    useGlobalFilter,
    useExpanded,
  );

  useEffect(() => {
    setTimeout(toggleAllRowsExpanded(true));
  }, [toggleAllRowsExpanded, dataForTable]);

  useEffect(() => setTableRows(rows), [rows, setTableRows]);

  return (
    <div {...getTableProps()}>
      <ReactTableHeader backgroundColor="var(--secondaryGray)">
        {headerGroups.map((headerGroup) => (
          <ReactTableHeaderRow {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <ReactTableHeaderColumn {...column.getHeaderProps()} customWidth={column.width}>
                {column.render('Header')}
              </ReactTableHeaderColumn>
            ))}
          </ReactTableHeaderRow>
        ))}
      </ReactTableHeader>

      {rows.length ? (
        <ReactTableBody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <ReactTableRow
                {...row.getRowProps()}
                subRow={!row.canExpand}
                height={row.canExpand ? '68px' : undefined}
                noRowHover
              >
                {row.cells.map((cell) =>
                  row.canExpand ? (
                    <div {...cell.getCellProps()}>{cell.render('Cell')}</div>
                  ) : (
                    <ReactTableCell {...cell.getCellProps()} customWidth={cell.column.width}>
                      {cell.render('Cell')}
                    </ReactTableCell>
                  ),
                )}
              </ReactTableRow>
            );
          })}
        </ReactTableBody>
      ) : (
        <EmptyTableContent>
          You don't have any invoices, but you can
          <EmptyTableContentAction onClick={openInvoicingScheduleModal}>create them manually</EmptyTableContentAction>
        </EmptyTableContent>
      )}
    </div>
  );
};
