import dayjs from 'dayjs';
import { formatDateForGraph } from 'utils/dateUtils';

export const calculateCardData = ({ data, key }) => {
  if (!data) return null;

  const monthlyStats = Object.values(data);

  let startingValue = monthlyStats[0][key]?.value ?? monthlyStats[0][key] ?? 0;

  const keys = Object.keys(data);
  const startingKey = dayjs
    .utc(keys[keys.length - 1])
    .subtract(1, 'year')
    .format('YYYY-MM');

  if (data[startingKey]) {
    startingValue = data[startingKey][key]?.value ?? data[startingKey][key] ?? 0;
  }

  return {
    currentValue: monthlyStats[monthlyStats.length - 1][key]?.value ?? monthlyStats[monthlyStats.length - 1][key] ?? 0,
    startingValue,
    graphData: monthlyStats.map((stat) => ({ [key]: stat[key]?.value ?? stat[key] ?? 0 })),
    graphLabels: keys.map(formatDateForGraph),
  };
};
