import React from 'react';
import styled from 'styled-components';
import { ReactComponent as EmptyStateSearch } from 'images/reports-popover-empty-state-search.svg';
import { COLORS } from 'consts/colors';
import { RefreshIcon } from 'components/Icons';
import { IconButton } from 'components/Buttons';
import { EmptyStateContents, EmptyStateMainText, EmptyStateText } from 'shared/EmptyState';

const ExplainContainer = styled.div`
  width: 240px;
  margin: 12px 0 24px 0;
  text-align: center;
`;

export const ReportsPopoverEmptySearch = ({ onResetSearch }) => (
  <EmptyStateContents>
    <EmptyStateSearch />
    <EmptyStateMainText>0 results found</EmptyStateMainText>
    <ExplainContainer>
      <EmptyStateText>Try changing the search parameters or resetting the search</EmptyStateText>
    </ExplainContainer>

    <IconButton
      filled
      border
      color={COLORS.RED}
      icon={<RefreshIcon width={15} height={15} />}
      iconFillColor="white"
      onClick={onResetSearch}
    >
      Reset search
    </IconButton>
  </EmptyStateContents>
);
