import { useAuth0 } from '@auth0/auth0-react';
import { useUserAPI } from 'api/users';
import { GMAIL_FEATURES, INTEGRATION_SERVICES } from 'consts/integrations';
import { OAUTH_CALLBACK_PATH } from 'views/Wizard/consts';
import { initiateGoogleApi, initiateIntegrationLogin } from './requests';

export const useInitiateIntegration = () => {
  const { user: authUser = {} } = useAuth0();
  const { data: userData } = useUserAPI({ email: authUser.email });

  return {
    initiateIntegrationFlow: ({ integration }) =>
      integration?.service === INTEGRATION_SERVICES.GMAIL
        ? initiateGoogleApi({ params: { features: [GMAIL_FEATURES.SEND_EMAIL] } })
        : initiateIntegrationLogin({ userId: userData.id, integration, redirect: OAUTH_CALLBACK_PATH.CONFIGS }),
  };
};
