import React, { useContext, useCallback } from 'react';
import styled from 'styled-components';

import TooltipContainer from 'components/Tooltip/Tooltip';
import { FlexEndContainer, Column, Spacer } from 'components/Core';
import { TableDataCell, StyledDangerIcon } from './styles';
import { CohortsContext } from '../CohortsContext';

import { getBackgroundByHeatLevel, getFluctuationIcon, isWhiteText as _isWhiteText } from '../utils';

const ValueWrapper = styled(FlexEndContainer)`
  width: 100%;
`;

const Wrapper = styled(Column)`
  width: 90px;
`;

const TableTile = ({
  value,
  secondaryValue,
  heatLevel = -1,
  secondaryHeatLevel = -1,
  styles = {},
  bold,
  left,
  padding,
  fluctuate,
  primary,
  isLastColumn,
  toolTipText = null,
  secondaryToolTipText = null,
  description,
}) => {
  const { cohortPalette } = useContext(CohortsContext);

  const isWhiteText = useCallback(() => _isWhiteText({ primary, heatLevel, cohortPalette }), [
    primary,
    heatLevel,
    cohortPalette,
  ]);

  return (
    <Wrapper>
      <TableDataCell
        data-cy={'cohorts-page__table-tile__primary'}
        left={left}
        bold={bold}
        padding={padding}
        primary={primary}
        height={secondaryValue ? '60%' : '100%'}
        whiteText={isWhiteText(heatLevel)}
        backgroundColor={primary && getBackgroundByHeatLevel(cohortPalette, heatLevel)}
        styles={styles}
      >
        <ValueWrapper data-cy={`cohorts-page__table-tile__primary--${description ? description : 'value'}`}>
          <TooltipContainer width={168} fontSize="10px" isVisible={toolTipText} toolTipContent={toolTipText}>
            {value}
          </TooltipContainer>
          {fluctuate?.icon && <Spacer width="7px" />}
          {fluctuate?.icon && (
            <TooltipContainer width={150} fontSize="10px" toolTipContent={fluctuate?.tooltipText}>
              {getFluctuationIcon(fluctuate?.icon)}
            </TooltipContainer>
          )}
        </ValueWrapper>

        {isLastColumn && (
          <TooltipContainer
            width={150}
            fontSize="10px"
            toolTipContent={"This data is incomplete, because the period isn't complete"}
          >
            <StyledDangerIcon />
          </TooltipContainer>
        )}
      </TableDataCell>

      {secondaryValue && (
        <>
          <Spacer height="1px" />
          <TableDataCell
            data-cy={'cohorts-page__table-tile__secondary'}
            left={left}
            bold={bold}
            padding={padding}
            primary={primary}
            height={'calc(40% - 1px)'}
            whiteText={isWhiteText(secondaryHeatLevel)}
            backgroundColor={primary && getBackgroundByHeatLevel(cohortPalette, secondaryHeatLevel)}
            styles={styles}
          >
            <ValueWrapper data-cy={`cohorts-page__table-tile__secondary--${description ? description : 'value'}`}>
              <TooltipContainer
                width={168}
                fontSize="10px"
                isVisible={secondaryToolTipText}
                toolTipContent={secondaryToolTipText}
              >
                {secondaryValue}
              </TooltipContainer>
            </ValueWrapper>
          </TableDataCell>
        </>
      )}
    </Wrapper>
  );
};

export default TableTile;
