import styled from 'styled-components';
import PropTypes from 'prop-types';

import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const IconContainer = styled.div`
  width: 36px;
  height: 36px;

  border-radius: 100px;
  background: rgba(57, 161, 220, 0.1);
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Container = styled.span`
  display: flex;
  align-items: center;

  cursor: pointer;

  border-radius: 100px;
`;

// TODO: Migrate to CircleButton
export const BackButton = ({ onClick, icon = faChevronLeft, color = 'var(--primaryBlue)', ...rest }) => {
  return (
    <Container onClick={onClick} {...rest}>
      <IconContainer>
        <FontAwesomeIcon icon={icon} color={color} />
      </IconContainer>
    </Container>
  );
};

BackButton.propTypes = {
  /**
   * Optional click handler
   */
  onClick: PropTypes.func,
};
