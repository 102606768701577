import dayjs from 'dayjs';
import { components } from 'react-select';
import { CustomDatePicker, FormikCustomInput } from 'components/Controls';
import { SelectDropdownButton } from 'components/Buttons';
import { ColumnMapperSelector } from 'shared/CsvUpload/CsvTable/CsvTable';
import { Spacer } from 'components/Core';
import { renderCustomerIdCell } from 'views/Transactions/TransactionsCsvUpload/fuzzyCells';
import {
  INTERNAL_CUSTOMER_ID_SOURCE,
  NAME_MATCHING_SOURCE,
  SOURCE_TYPES,
} from 'views/Transactions/TransactionsCsvUpload/consts';
import {
  HeaderSelector,
  SelectorTitle,
  CustomOption,
  CustomOptionKey,
  CustomOptionValue,
  IdSourceSelectorWrapper,
} from 'shared/CsvUpload/styles';
import { renderEventNameCell, renderEventSourceCell } from './fuzzyCells';

const { Option } = components;

export const CustomSingleOption = (props) => {
  // optimization for large lists
  delete props.innerProps.onMouseMove;
  delete props.innerProps.onMouseOver;

  return (
    <Option {...props}>
      <CustomOption>
        <CustomOptionKey>{props.data.value}</CustomOptionKey>
        <CustomOptionValue>{props.data.label}</CustomOptionValue>
      </CustomOption>
    </Option>
  );
};

const SelectDropdownWithTooltip = ({ name, selected, options, onSelect }) => (
  <SelectDropdownButton
    name={name}
    noMargin
    selected={selected}
    options={options}
    fontSize="12px"
    onSelect={onSelect}
    toolTipContent="Click to change type"
    toolTipWidth="125px"
  />
);

export const getColumns = ({
  formRef,
  csvColumns,
  csvColumnsMapper,
  setCsvColumnsMapper,

  customers,
  customersById,
  customersIdSources,

  customerIdSourceAndType,
  setCustomerIdSourceAndType,
  uploadCsvCustomersIdSource,

  fuzzyRowsMapper,
  setFuzzyRowsMapper,

  eventNames,
  eventSources,

  defaultMapper,
}) => {
  const handleCustomerIdSourceChange = (newValue) => {
    setCsvColumnsMapper(defaultMapper);

    setCustomerIdSourceAndType({
      type: [uploadCsvCustomersIdSource, NAME_MATCHING_SOURCE, INTERNAL_CUSTOMER_ID_SOURCE].includes(newValue)
        ? SOURCE_TYPES.INTERNAL
        : SOURCE_TYPES.EXTERNAL,
      source: newValue,
    });
  };

  return [
    {
      Header: () => <></>,
      width: 80,
      accessor: 'status',
      Cell: () => <></>,
    },
    {
      Header: () => (
        <IdSourceSelectorWrapper>
          <SelectDropdownWithTooltip
            name="customers-ids-source"
            selected={customerIdSourceAndType?.source}
            options={customersIdSources}
            onSelect={handleCustomerIdSourceChange}
          />
          <Spacer height="6px" />
          <ColumnMapperSelector
            field="customer_id"
            csvColumnsMapper={csvColumnsMapper}
            setCsvColumnsMapper={setCsvColumnsMapper}
            csvColumns={csvColumns}
          />
        </IdSourceSelectorWrapper>
      ),
      width: 180,
      accessor: 'customer_id',
      Cell: (cellProps) =>
        renderCustomerIdCell({
          ...cellProps,
          customerIdSourceAndType,
          customers,
          customersById,
          formCurrent: formRef?.current,
          fuzzyRowsMapper,
          setFuzzyRowsMapper,
          uploadCsvCustomersIdSource,
        }),
    },
    {
      Header: () => (
        <HeaderSelector>
          <SelectorTitle>Event name</SelectorTitle>
          <ColumnMapperSelector
            field="name"
            csvColumnsMapper={csvColumnsMapper}
            setCsvColumnsMapper={setCsvColumnsMapper}
            csvColumns={csvColumns}
          />
        </HeaderSelector>
      ),
      accessor: 'name',
      width: 180,
      Cell: (cellProps) =>
        renderEventNameCell({
          ...cellProps,
          eventNames,
          formCurrent: formRef?.current,
          fuzzyRowsMapper,
          setFuzzyRowsMapper,
        }),
    },
    {
      Header: () => (
        <HeaderSelector>
          <SelectorTitle>Event source</SelectorTitle>
          <ColumnMapperSelector
            field="source"
            csvColumnsMapper={csvColumnsMapper}
            setCsvColumnsMapper={setCsvColumnsMapper}
            csvColumns={csvColumns}
          />
        </HeaderSelector>
      ),
      accessor: 'source',
      width: 180,
      Cell: (cellProps) =>
        renderEventSourceCell({
          ...cellProps,
          eventSources,
          formCurrent: formRef?.current,
          fuzzyRowsMapper,
          setFuzzyRowsMapper,
        }),
    },
    {
      Header: () => (
        <HeaderSelector>
          <SelectorTitle>Units</SelectorTitle>
          <ColumnMapperSelector
            field="units"
            csvColumnsMapper={csvColumnsMapper}
            setCsvColumnsMapper={setCsvColumnsMapper}
            csvColumns={csvColumns}
          />
        </HeaderSelector>
      ),
      accessor: 'units',
      width: 180,
      Cell: ({ row }) => (
        <FormikCustomInput
          style={{ height: 32 }}
          errorWithoutTooltip
          type="number"
          placeholder="Enter units..."
          width="100%"
          name={`[${row.original.rowIndex}].units`}
        />
      ),
    },
    {
      Header: () => (
        <HeaderSelector>
          <SelectorTitle>Notes</SelectorTitle>
          <ColumnMapperSelector
            field="notes"
            csvColumnsMapper={csvColumnsMapper}
            setCsvColumnsMapper={setCsvColumnsMapper}
            csvColumns={csvColumns}
          />
        </HeaderSelector>
      ),
      accessor: 'notes',
      width: 180,
      Cell: ({ row }) => (
        <FormikCustomInput
          style={{ height: 32 }}
          errorWithoutTooltip
          placeholder="Enter note..."
          width="100%"
          name={`[${row.original.rowIndex}].notes`}
        />
      ),
    },
    {
      Header: () => (
        <HeaderSelector>
          <SelectorTitle>Timestamp</SelectorTitle>
          <ColumnMapperSelector
            field="timestamp"
            csvColumnsMapper={csvColumnsMapper}
            setCsvColumnsMapper={setCsvColumnsMapper}
            csvColumns={csvColumns}
          />
        </HeaderSelector>
      ),
      accessor: 'timestamp',
      width: 180,
      Cell: ({ row, cell: { value } }) => (
        <CustomDatePicker
          offsetContainer={false}
          errorWithoutTooltip
          formik
          height="32px"
          onChange={formRef?.current && formRef?.current?.setFieldValue}
          selected={value && dayjs(value).isValid() ? dayjs(value).toDate() : ''}
          meta={formRef?.current && formRef?.current?.getFieldMeta(`[${row.original.rowIndex}].timestamp`)}
          width="100%"
          name={`[${row.original.rowIndex}].timestamp`}
        />
      ),
    },
  ];
};
