import { useState } from 'react';
import styled from 'styled-components';

import { Modal, ModalCloseIcon, ModalContainer } from 'components/Modal';
import { FlexerColumn, Row } from 'components/Core';
import { FileTextIcon } from 'components/Icons';
import { useClickOutside } from 'utils/hooks';

import { ExternalServiceLink } from '../InvoicePreviewModalV2/ExternalServiceLink';
import { InvoicePreviewContents } from '../InvoicePreviewModalV2/InvoicePreviewContents';
import { InvoiceLink } from '../InvoicePreviewModalV2/styles';
import { CreditNotePDFPreviewContents } from '../InvoicingScheduleModal/InvoicingScheduleCreditNotePanel/useCreditNotePDFPreviewModal';

const ContentWrapper = styled(FlexerColumn)`
  padding: 30px;
  gap: 16px;
  height: 90%;
  min-height: 750px;
`;

export const PDF_PREVIEW_MODAL_TYPES = {
  INVOICE: 'invoice',
  CREDIT_NOTE: 'creditNote',
};

export const PDFPreviewModal = ({
  onClose,
  type = PDF_PREVIEW_MODAL_TYPES.INVOICE,
  onDownload,
  pdfData,
  isPdfLoading,
  isPdfFetching,
  onRegenerate,
}) => {
  const [isExternalLinkDropDownOpen, setIsExternalLinkDropDownOpen] = useState();

  const triggerRef = useClickOutside(() => {
    !isExternalLinkDropDownOpen && onClose();
  });

  const AdditionalHeaders = () => {
    switch (type) {
      case PDF_PREVIEW_MODAL_TYPES.CREDIT_NOTE:
        return <></>;
      default:
        return (
          <ExternalServiceLink
            onShowDropdownChange={(value) => {
              setIsExternalLinkDropDownOpen(value);
            }}
          />
        );
    }
  };

  const PreviewContent = () => {
    switch (type) {
      case PDF_PREVIEW_MODAL_TYPES.CREDIT_NOTE:
        return (
          <CreditNotePDFPreviewContents
            creditNotePdf={pdfData}
            isPdfLoading={isPdfLoading || isPdfFetching}
            width="100%"
            pdfWidth={1050}
            onRegenerate={onRegenerate}
          />
        );
      default:
        return (
          <InvoicePreviewContents
            invoicePdf={pdfData}
            isPdfLoading={isPdfLoading || isPdfFetching}
            width="100%"
            pdfWidth={1050}
          />
        );
    }
  };

  return (
    <ModalContainer data-cy="pdf-preview-modal">
      <Modal ref={triggerRef} height="800px" maxHeight="800px" width="1200px">
        <ModalCloseIcon onClose={onClose} data-cy="pdf-preview-modal__close" />
        <ContentWrapper>
          <Row padding="8px">
            {pdfData?.pdf && !isPdfFetching && !isPdfLoading && (
              <InvoiceLink onClick={onDownload}>
                <FileTextIcon />
                PDF
              </InvoiceLink>
            )}

            <AdditionalHeaders />
          </Row>
          <PreviewContent />
        </ContentWrapper>
      </Modal>
    </ModalContainer>
  );
};
