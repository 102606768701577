import { WarnBlock } from 'components/Blocks';
import { FlexerColumn } from 'components/Core';
import { ConfirmModal } from 'shared/ConfirmModal';

export const ExternalAllocationConfirmModal = ({
  onConfirm,
  onClose,
  invoicingServiceDisplayName = 'your GL',
  creditNoteWaitingForTaxes,
}) => (
  <ConfirmModal
    title={`Allocate invoice on ${invoicingServiceDisplayName}?`}
    content={
      <FlexerColumn gap="12px">
        {creditNoteWaitingForTaxes && (
          <WarnBlock>
            We are still polling for taxes from {invoicingServiceDisplayName} on the credit note{' '}
            {creditNoteWaitingForTaxes.credit_note_number || creditNoteWaitingForTaxes.id}. By confirming, you accept
            that there are no taxes on this credit note and we will stop polling.
          </WarnBlock>
        )}
        <div>
          Once confirmed, you may not be able to edit the credit note or the amount of the invoice due to restrictions
          imposed by {invoicingServiceDisplayName}.
        </div>
      </FlexerColumn>
    }
    closeButtonText="No, let me continue editing on Subscript"
    confirmButtonText="I understand and want to proceed"
    onConfirm={onConfirm}
    onClose={onClose}
    contentPadding="70px"
    width="680px"
  />
);
