import { GROWTH_TYPE_OPTIONS, NUMBER_FORMATS } from './global';

// JB [2022-12-20] isARRMetric value based on DashboardTables.utils.js file
// On every change also please do the same changes in backend/src/config in constants.SNAPSHOT_METRICS
export const SNAPSHOT_METRICS = {
  REVENUE: {
    key: 'revenue',
    label: 'Revenue',
  },
  RECURRING_REVENUE: {
    key: 'recurring-revenue',
    label: 'Recurring Revenue',
    dataType: NUMBER_FORMATS.CURRENCY,
    decimalPlaces: null,
    metricCategoryKey: 'revenueStats',
    monthDataKey: 'Total',
    summaryDataKey: 'recurringRevenueTotal',
    description: () => 'Amount of revenue on a recurring basis',
    isARRMetric: true,
    isDecreasingMetric: false,
  },
  RECURRING_PERIOD_GROWTH: {
    key: 'recurring-period-growth',
    label: 'Recurring Period Growth',
    dataType: NUMBER_FORMATS.PERCENT,
    decimalPlaces: 2,
    metricCategoryKey: 'revenueStats',
    monthDataKey: 'recurringPeriodGrowth',
    summaryDataKey: 'recurringPeriodGrowth',
    description: ({ growthType }) => `${GROWTH_TYPE_OPTIONS[growthType]} Growth for Recurring Revenue`,
    isARRMetric: false,
    isDecreasingMetric: false,
  },
  NON_RECURRING: {
    key: 'non-recurring',
    label: 'Non-Recurring',
    dataType: NUMBER_FORMATS.CURRENCY,
    decimalPlaces: null,
    metricCategoryKey: 'revenueStats',
    monthDataKey: 'nonRecurring',
    summaryDataKey: 'nonRecurring',
    description: () => "Amount of revenue for the month that's not recurring",
    isARRMetric: false,
    isDecreasingMetric: false,
  },
  TOTAL_PERIOD_REVENUE: {
    key: 'total-period-revenue',
    label: 'Total Period Revenue',
    dataType: NUMBER_FORMATS.CURRENCY,
    decimalPlaces: null,
    metricCategoryKey: 'revenueStats',
    monthDataKey: 'totalPeriodRevenue',
    summaryDataKey: 'totalPeriodRevenue',
    description: () => 'Sum of recurring and non-recurring revenue',
    isARRMetric: false,
    isDecreasingMetric: false,
  },
  TOTAL_PERIOD_GROWTH: {
    key: 'total-period-growth',
    label: 'Total Period Growth',
    dataType: NUMBER_FORMATS.PERCENT,
    decimalPlaces: 2,
    metricCategoryKey: 'revenueStats',
    monthDataKey: 'totalPeriodGrowth',
    summaryDataKey: 'totalPeriodGrowth',
    description: ({ growthType }) =>
      `${GROWTH_TYPE_OPTIONS[growthType]} Growth for Recurring and Non-Recurring Revenue`,
    isARRMetric: false,
    isDecreasingMetric: false,
  },
  PERCENTAGE_RECURRING: {
    key: 'percentage-recurring',
    label: 'Percentage Recurring',
    dataType: NUMBER_FORMATS.PERCENT,
    decimalPlaces: 2,
    metricCategoryKey: 'revenueStats',
    monthDataKey: 'percentRecurring',
    summaryDataKey: 'percentRecurring',
    description: () => 'Percentage of Revenue that is Recurring Revenue',
    isARRMetric: false,
    isDecreasingMetric: false,
  },
  BOOKINGS: {
    key: 'bookings',
    label: 'Bookings (recurring)',
    dataType: NUMBER_FORMATS.CURRENCY,
    decimalPlaces: null,
    metricCategoryKey: 'revenueStats',
    monthDataKey: 'bookings',
    summaryDataKey: 'bookings',
    description: () => 'Bookings for recurring customers (of recurring recognition types) this month',
    isARRMetric: false,
    isDecreasingMetric: false,
  },
  BOOKINGS_NEW: {
    key: 'bookings-new',
    label: 'Bookings - New',
    dataType: NUMBER_FORMATS.CURRENCY,
    decimalPlaces: null,
    metricCategoryKey: 'revenueStats',
    monthDataKey: 'newBookings',
    summaryDataKey: 'newBookings',
    description: () => 'Bookings for new customers (of recurring recognition types) this month',
    isARRMetric: false,
    isDecreasingMetric: false,
  },
  GROSS_NEW_REVENUE: {
    key: 'gross-new-revenue',
    label: 'Gross New Revenue',
    dataType: NUMBER_FORMATS.CURRENCY,
    decimalPlaces: null,
    metricCategoryKey: 'revenueStats',
    monthDataKey: 'New',
    summaryDataKey: ['New', 'netNewRevenue.newRevenue'],
    description: () => 'New customer revenue this month',
    isARRMetric: true,
    isDecreasingMetric: false,
  },
  NON_RENEWAL_UPSELL: {
    key: 'non-Renewal-upsell',
    label: 'Non-Renewal Upsell',
    dataType: NUMBER_FORMATS.CURRENCY,
    decimalPlaces: null,
    metricCategoryKey: 'revenueStats',
    monthDataKey: 'nonRenewalUpsell',
    summaryDataKey: 'nonRenewalUpsell',
    description: () => 'Upsell revenue for customers that are not renewing',
    isARRMetric: true,
    isDecreasingMetric: false,
  },
  RECURRING_CUSTOMERS: {
    key: 'recurring-customers',
    label: 'Recurring Customers',
    dataType: NUMBER_FORMATS.NUMBER,
    decimalPlaces: null,
    metricCategoryKey: 'customerStats',
    monthDataKey: 'Total',
    summaryDataKey: 'recurringCustomers',
    description: () => 'Count of all customers with active recurring transactions',
    isARRMetric: false,
    isDecreasingMetric: false,
  },
  CUSTOMERS: {
    key: 'customers',
    label: 'Customers',
    dataType: NUMBER_FORMATS.NUMBER,
    decimalPlaces: null,
    metricCategoryKey: 'customerStats',
    monthDataKey: 'allCustomers',
    summaryDataKey: 'allCustomers',
    description: () => 'Count of all customers (recurring and otherwise)',
    isARRMetric: false,
    isDecreasingMetric: false,
  },
  CUSTOMERS_WITH_OPEN_CONTRACTS: {
    key: 'customers-with-open-contracts',
    label: 'Customers w/ Open Contracts',
    dataType: NUMBER_FORMATS.NUMBER,
    decimalPlaces: null,
    metricCategoryKey: 'customerStats',
    monthDataKey: 'customersWithOpenContracts',
    summaryDataKey: 'customersWithOpenContracts',
    description: () => 'Customers with recurring revenue plus customers with open contracts',
    isARRMetric: false,
    isDecreasingMetric: false,
  },
  SEATS: {
    key: 'seats',
    label: 'Seats',
    dataType: NUMBER_FORMATS.NUMBER,
    decimalPlaces: null,
    metricCategoryKey: 'seatStats',
    monthDataKey: 'Total',
    summaryDataKey: 'totalSeats',
    description: () => 'Number of recurring seats sold',
    isARRMetric: false,
    isDecreasingMetric: false,
  },
  ARPU: {
    key: 'arpu',
    label: 'ARPU (recurring)',
    dataType: NUMBER_FORMATS.CURRENCY,
    decimalPlaces: null,
    metricCategoryKey: 'saasStats',
    monthDataKey: 'recurringARPU',
    summaryDataKey: 'recurringARPU',
    description: () => 'Recurring Revenue divided by the number of recurring seats (units)',
    isARRMetric: true,
    isDecreasingMetric: false,
  },
  ARPU_NEW: {
    key: 'arpu-new',
    label: 'ARPU - New (recurring)',
    dataType: NUMBER_FORMATS.CURRENCY,
    decimalPlaces: null,
    metricCategoryKey: 'saasStats',
    monthDataKey: 'newRecurringARPU',
    summaryDataKey: 'newRecurringARPU',
    description: () => 'New Recurring Revenue divided by the number of new recurring seats (units)',
    isARRMetric: true,
    isDecreasingMetric: false,
  },
  SAAS: {
    key: 'saas',
    label: 'SaaS',
  },
  LTV: {
    key: 'ltv',
    label: 'LTV',
    dataType: NUMBER_FORMATS.CURRENCY,
    decimalPlaces: null,
    metricCategoryKey: 'saasStats',
    monthDataKey: 'LTV',
    summaryDataKey: 'LTV',
    description: () => 'Revenue per customer divided by gross customer churn rate',
    isARRMetric: false,
    isDecreasingMetric: false,
  },
  LTV_TTM: {
    key: 'ltv-ttm',
    label: 'LTV TTM',
    dataType: NUMBER_FORMATS.CURRENCY,
    decimalPlaces: null,
    metricCategoryKey: 'saasStats',
    monthDataKey: 'LTV_TTM',
    summaryDataKey: 'LTV_TTM',
    description: () => 'Revenue per customer divided by gross customer churn rate for the trailing twelve months',
    isARRMetric: false,
    isDecreasingMetric: false,
  },
  LTVGMLaden: {
    key: 'ltv-gmladen',
    label: 'LTV (GM Laden)',
    dataType: NUMBER_FORMATS.CURRENCY,
    decimalPlaces: null,
    metricCategoryKey: 'saasStats',
    monthDataKey: 'LTVGMLaden',
    summaryDataKey: 'LTVGMLaden',
    description: () => 'Revenue per customer times gross margin divided by gross customer churn rate',
    isARRMetric: false,
    isDecreasingMetric: false,
  },
  LTVGMLaden_TTM: {
    key: 'ltv-gmladen-ttm',
    label: 'LTV (GM Laden) TTM',
    dataType: NUMBER_FORMATS.CURRENCY,
    decimalPlaces: null,
    metricCategoryKey: 'saasStats',
    monthDataKey: 'LTVGMLaden_TTM',
    summaryDataKey: 'LTVGMLaden_TTM',
    description: () =>
      'Revenue per customer times gross margin divided by gross customer churn rate for the trailing twelve months',
    isARRMetric: false,
    isDecreasingMetric: false,
  },
  CAC_RECURRING: {
    key: 'cac-recurring',
    label: 'CAC (recurring)',
    dataType: NUMBER_FORMATS.CURRENCY,
    decimalPlaces: null,
    metricCategoryKey: 'saasStats',
    monthDataKey: 'recurringCAC',
    summaryDataKey: 'recurringCAC',
    description: () =>
      '(Customer Acquisition Costs) Sum of all sales and marketing expenses divided by number of new recurring customers',
    isARRMetric: false,
    isDecreasingMetric: true,
  },
  CAC_RECURRING_TTM: {
    key: 'cac-recurring-ttm',
    label: 'CAC (recurring) TTM',
    dataType: NUMBER_FORMATS.CURRENCY,
    decimalPlaces: null,
    metricCategoryKey: 'saasStats',
    monthDataKey: 'recurringCAC_TTM',
    summaryDataKey: 'recurringCAC_TTM',
    description: () =>
      '(Customer Acquisition Costs) Sum of all sales and marketing expenses divided by number of new recurring customers for the trailing twelve months',
    isARRMetric: false,
    isDecreasingMetric: true,
  },
  CAC_ALL: {
    key: 'cac-all',
    label: 'CAC (all customers)',
    dataType: NUMBER_FORMATS.CURRENCY,
    decimalPlaces: null,
    metricCategoryKey: 'saasStats',
    monthDataKey: 'allCAC',
    summaryDataKey: 'allCAC',
    description: () =>
      '(Customer Acquisition Costs) Sum of all sales and marketing expenses divided by number of all new customers',
    isARRMetric: false,
    isDecreasingMetric: true,
  },
  CAC_ALL_TTM: {
    key: 'cac-all-ttm',
    label: 'CAC (all customers) TTM',
    dataType: NUMBER_FORMATS.CURRENCY,
    decimalPlaces: null,
    metricCategoryKey: 'saasStats',
    monthDataKey: 'allCAC_TTM',
    summaryDataKey: 'allCAC_TTM',
    description: () =>
      '(Customer Acquisition Costs) Sum of all sales and marketing expenses divided by number of all new customers for the trailing twelve months',
    isARRMetric: false,
    isDecreasingMetric: true,
  },
  CEC_RECURRING: {
    key: 'cec-recurring',
    label: 'CEC (recurring)',
    dataType: NUMBER_FORMATS.CURRENCY,
    decimalPlaces: null,
    metricCategoryKey: 'saasStats',
    monthDataKey: 'recurringCEC',
    summaryDataKey: 'recurringCEC',
    description: () =>
      '(Customer Expansion Costs) Sum of all sales and marketing expenses divided by number of upsell recurring customers',
    isARRMetric: false,
    isDecreasingMetric: true,
  },
  CEC_RECURRING_TTM: {
    key: 'cec-recurring-ttm',
    label: 'CEC (recurring) TTM',
    dataType: NUMBER_FORMATS.CURRENCY,
    decimalPlaces: null,
    metricCategoryKey: 'saasStats',
    monthDataKey: 'recurringCEC_TTM',
    summaryDataKey: 'recurringCEC_TTM',
    description: () =>
      '(Customer Expansion Costs) Sum of all sales and marketing expenses divided by number of upsell recurring customers for the trailing twelve months',
    isARRMetric: false,
    isDecreasingMetric: true,
  },
  LTVCACRatio: {
    key: 'ltv-cac-ratio',
    label: 'LTV (GM Laden) / CAC',
    dataType: NUMBER_FORMATS.NUMBER,
    decimalPlaces: null,
    metricCategoryKey: 'saasStats',
    monthDataKey: 'LTVCACRatio',
    summaryDataKey: 'LTVCACRatio',
    description: () => 'LTV GM Laden divided by recurring CAC',
    isARRMetric: false,
    isDecreasingMetric: false,
  },
  LTVCACRatio_TTM: {
    key: 'ltv-cac-ratio-ttm',
    label: 'LTV (GM Laden) TTM / CAC TTM',
    dataType: NUMBER_FORMATS.NUMBER,
    decimalPlaces: null,
    metricCategoryKey: 'saasStats',
    monthDataKey: 'LTVCACRatio_TTM',
    summaryDataKey: 'LTVCACRatio_TTM',
    description: () => 'LTV GM Laden divided by recurring CAC for the trailing twelve months',
    isARRMetric: false,
    isDecreasingMetric: false,
  },
  NEW_REVENUE_TO_COST_RATIO: {
    key: 'new-revenue-to-cost-ratio-ttm',
    label: 'New Revenue / Costs',
    dataType: NUMBER_FORMATS.NUMBER,
    decimalPlaces: null,
    metricCategoryKey: 'saasStats',
    monthDataKey: 'newRevenueToCostsRatio',
    summaryDataKey: 'newRevenueToCostsRatio',
    description: () => 'Gross New Revenue divided by total sales and marketing costs',
    isARRMetric: true,
    isDecreasingMetric: false,
  },
  GROSS_CUSTOMER_RETENTION: {
    key: 'gross-customer-retention',
    label: 'Gross Customer Retention',
    dataType: NUMBER_FORMATS.PERCENT,
    decimalPlaces: 2,
    metricCategoryKey: 'saasStats',
    monthDataKey: 'grossCustomerRetention',
    summaryDataKey: 'grossCustomerRetention',
    description: () => '% of customers from previous period who are still paying customers',
    isARRMetric: false,
    isDecreasingMetric: false,
  },
  GROSS_CUSTOMER_RETENTION_TTM: {
    key: 'gross-customer-retention-ttm',
    label: 'Gross Customer Retention TTM',
    dataType: NUMBER_FORMATS.PERCENT,
    decimalPlaces: 2,
    metricCategoryKey: 'saasStats',
    monthDataKey: 'grossCustomerRetention_TTM',
    summaryDataKey: 'grossCustomerRetention_TTM',
    description: () => '% of customers from twelve months ago who are still paying customers',
    isARRMetric: false,
    isDecreasingMetric: false,
  },
  NET_REVENUE_RETENTION: {
    key: 'net-revenue-retention',
    label: 'Net Revenue Retention',
    dataType: NUMBER_FORMATS.PERCENT,
    decimalPlaces: 2,
    metricCategoryKey: 'saasStats',
    monthDataKey: 'netRevenueRetention',
    summaryDataKey: 'netRevenueRetention',
    description: () => 'Revenue earned from existing customers as a % of what they paid last period (incl upsells)',
    isARRMetric: false,
    isDecreasingMetric: false,
  },
  NET_REVENUE_RETENTION_TTM: {
    key: 'net-revenue-retention-ttm',
    label: 'Net Revenue Retention TTM',
    dataType: NUMBER_FORMATS.PERCENT,
    decimalPlaces: 2,
    metricCategoryKey: 'saasStats',
    monthDataKey: 'netRevenueRetention_TTM',
    summaryDataKey: 'netRevenueRetention_TTM',
    description: () =>
      'Revenue earned from existing customers as a % of what they paid twelve months ago (incl upsells)',
    isARRMetric: false,
    isDecreasingMetric: false,
  },
  GROSS_REVENUE_RETENTION: {
    key: 'gross-revenue-retention',
    label: 'Gross Revenue Retention',
    dataType: NUMBER_FORMATS.PERCENT,
    decimalPlaces: 2,
    metricCategoryKey: 'saasStats',
    monthDataKey: 'grossRevenueRetention',
    summaryDataKey: 'grossRevenueRetention',
    description: () => 'Revenue earned from existing customers as a % of what they paid last period (NOT incl upsells)',
    isARRMetric: false,
    isDecreasingMetric: false,
  },
  GROSS_REVENUE_RETENTION_TTM: {
    key: 'gross-revenue-retention-ttm',
    label: 'Gross Revenue Retention TTM',
    dataType: NUMBER_FORMATS.PERCENT,
    decimalPlaces: 2,
    metricCategoryKey: 'saasStats',
    monthDataKey: 'grossRevenueRetention_TTM',
    summaryDataKey: 'grossRevenueRetention_TTM',
    description: () =>
      'Revenue earned from existing customers as a % of what they paid twelve months ago (NOT incl upsells)',
    isARRMetric: false,
    isDecreasingMetric: false,
  },
  GROSS_REVENUE_CHURN_RATE: {
    key: 'gross-revenue-churn-rate',
    label: 'Gross Rev. Churn Rate',
    dataType: NUMBER_FORMATS.PERCENT,
    decimalPlaces: 2,
    metricCategoryKey: 'saasStats',
    monthDataKey: 'grossRevenueChurnRate',
    summaryDataKey: 'grossRevenueChurnRate',
    description: () => '100% - Gross Revenue Retention',
    isARRMetric: false,
    isDecreasingMetric: true,
  },
  GROSS_REVENUE_CHURN_RATE_TTM: {
    key: 'gross-revenue-churn-rate-ttm',
    label: 'Gross Rev. Churn Rate TTM',
    dataType: NUMBER_FORMATS.PERCENT,
    decimalPlaces: 2,
    metricCategoryKey: 'saasStats',
    monthDataKey: 'grossRevenueChurnRate_TTM',
    summaryDataKey: 'grossRevenueChurnRate_TTM',
    description: () => '100% - Gross Revenue Retention TTM',
    isARRMetric: false,
    isDecreasingMetric: true,
  },
  NET_NEW_REVENUE: {
    key: 'net-new-revenue',
    label: 'Net New Revenue',
    dataType: NUMBER_FORMATS.CURRENCY,
    decimalPlaces: null,
    metricCategoryKey: 'saasStats',
    monthDataKey: 'netNewRevenue',
    summaryDataKey: 'netNewRevenue',
    description: () => 'New + Upsell added, Net of Churn and Downsell',
    isARRMetric: true,
    isDecreasingMetric: false,
  },
  NET_NEW_REVENUE_TTM: {
    key: 'net-new-revenue-ttm',
    label: 'Net New Revenue TTM',
    dataType: NUMBER_FORMATS.CURRENCY,
    decimalPlaces: null,
    metricCategoryKey: 'saasStats',
    monthDataKey: 'netNewRevenue_TTM',
    summaryDataKey: 'netNewRevenue_TTM',
    description: () => 'New + Upsell added, Net of Churn and Downsell for the trailing twelve months',
    isARRMetric: true,
    isDecreasingMetric: false,
  },
  WINBACK_REVENUE: {
    key: 'winback-revenue',
    label: 'Winback Revenue',
    dataType: NUMBER_FORMATS.CURRENCY,
    decimalPlaces: null,
    metricCategoryKey: 'saasStats',
    monthDataKey: 'winbackRevenue',
    summaryDataKey: 'winbackRevenue',
    description: ({ winbackMonths }) =>
      `Revenue from customers that churned and came back within ${winbackMonths} months`,
    isARRMetric: true,
    isDecreasingMetric: false,
  },
  WINBACK_CUSTOMERS: {
    key: 'winback-customers',
    label: 'Winback Customers',
    dataType: NUMBER_FORMATS.NUMBER,
    decimalPlaces: null,
    metricCategoryKey: 'saasStats',
    monthDataKey: 'winbackCustomers',
    summaryDataKey: 'winbackCustomers',
    description: ({ winbackMonths }) => `Number of customers that churned and came back within ${winbackMonths} months`,
    isARRMetric: true,
    isDecreasingMetric: false,
  },
  UP_FOR_RENEWAL: {
    key: 'up-for-renewal',
    label: 'Up for renewal',
    dataType: NUMBER_FORMATS.CURRENCY,
    decimalPlaces: null,
    metricCategoryKey: 'revenueStats',
    monthDataKey: 'upForRenewal',
    summaryDataKey: 'upForRenewal',
    description: () => 'Recurring revenue that was up for renewal this month',
    isARRMetric: true,
    isDecreasingMetric: false,
  },
  AMOUNT_RENEWED_THAT_WAS_UP: {
    key: 'amount-renewed-that-was-up',
    label: 'Renewed that was up (Gross)',
    dataType: NUMBER_FORMATS.CURRENCY,
    decimalPlaces: null,
    metricCategoryKey: 'revenueStats',
    monthDataKey: 'amountRenewedThatWasUpGross',
    summaryDataKey: 'amountRenewedThatWasUpGross',
    description: () => 'Of the revenue up for renewal, amount renewed (excludes upsells)',
    isARRMetric: true,
    isDecreasingMetric: false,
  },
  AMOUNT_RENEWED_THAT_WAS_UP_NET: {
    key: 'amount-renewed-that-was-up-net',
    label: 'Renewed that was up (Net)',
    dataType: NUMBER_FORMATS.CURRENCY,
    decimalPlaces: null,
    metricCategoryKey: 'revenueStats',
    monthDataKey: 'amountRenewedThatWasUpNet',
    summaryDataKey: 'amountRenewedThatWasUpNet',
    description: () => 'Of the revenue up for renewal, amount renewed (incl upsells)',
    isARRMetric: true,
    isDecreasingMetric: false,
  },
  CUSTOMERS_UP_FOR_RENEWAL: {
    key: 'customers-up-for-renewal',
    label: 'Customers up for renewal',
    dataType: NUMBER_FORMATS.NUMBER,
    decimalPlaces: null,
    metricCategoryKey: 'customerStats',
    monthDataKey: 'customersUpForRenewal',
    summaryDataKey: 'customersUpForRenewal',
    description: () => 'Customers that are up for renewal this month',
    isARRMetric: false,
    isDecreasingMetric: false,
  },
  CUSTOMERS_RENEWED: {
    key: 'customers-renewed',
    label: 'Customers renewed',
    dataType: NUMBER_FORMATS.NUMBER,
    decimalPlaces: null,
    metricCategoryKey: 'customerStats',
    monthDataKey: 'customersRenewed',
    summaryDataKey: 'customersRenewed',
    description: () => 'Of the customers up for renewal, customers that renewed',
    isARRMetric: false,
    isDecreasingMetric: false,
  },
  CUSTOMER_UP_FOR_RENEWAL_RETENTION: {
    key: 'customer-up-for-renewal-retention',
    label: 'Customer retention (up for renewal)',
    dataType: NUMBER_FORMATS.PERCENT,
    decimalPlaces: null,
    metricCategoryKey: 'saasStats',
    monthDataKey: 'customerUpForRenewalRetention',
    summaryDataKey: 'customerUpForRenewalRetention',
    description: () => 'Customers that renewed / Customer up for renewal',
    isARRMetric: false,
    isDecreasingMetric: false,
  },
  GROSS_UP_FOR_RENEWAL_RETENTION: {
    key: 'gross-up-for-renewal-retention',
    label: 'Gross retention (up for renewal)',
    dataType: NUMBER_FORMATS.PERCENT,
    decimalPlaces: 2,
    metricCategoryKey: 'saasStats',
    monthDataKey: 'grossUpForRenewalRetention',
    summaryDataKey: 'grossUpForRenewalRetention',
    description: () => 'Gross retention rate out of the revenue that was up for renewal only',
    isARRMetric: false,
    isDecreasingMetric: false,
  },
  NET_UP_FOR_RENEWAL_RETENTION: {
    key: 'net-up-for-renewal-retention',
    label: 'Net retention (up for renewal)',
    dataType: NUMBER_FORMATS.PERCENT,
    decimalPlaces: 2,
    metricCategoryKey: 'saasStats',
    monthDataKey: 'netUpForRenewalRetention',
    summaryDataKey: 'netUpForRenewalRetention',
    description: () => 'Net retention rate out of the revenue that was up for renewal only',
    isARRMetric: false,
    isDecreasingMetric: false,
  },
  UNIT_ECONOMICS: {
    key: 'unit-economics',
    label: 'Unit Economics',
  },
  ARPA: {
    key: 'arpa',
    label: 'ARPA',
    dataType: NUMBER_FORMATS.CURRENCY,
    decimalPlaces: null,
    metricCategoryKey: 'saasStats',
    monthDataKey: 'ARPA',
    summaryDataKey: 'allARPA',
    description: ({ isARR }) => `${isARR ? 'Annualized' : 'Monthly'} recurring revenue divided by number of customers`,
    isARRMetric: true,
    isDecreasingMetric: false,
  },
  ARPA_TTM: {
    key: 'arpa-ttm',
    label: 'ARPA TTM',
    dataType: NUMBER_FORMATS.CURRENCY,
    decimalPlaces: null,
    metricCategoryKey: 'saasStats',
    monthDataKey: 'ARPA_TTM',
    summaryDataKey: 'allARPA_TTM',
    description: ({ isARR }) =>
      `${
        isARR ? 'Annualized' : 'Monthly'
      } recurring revenue divided by number of customers for the trailing twelve months`,
    isARRMetric: true,
    isDecreasingMetric: false,
  },
  ARPA_NEW: {
    key: 'arpa-new',
    label: 'ARPA - New',
    dataType: NUMBER_FORMATS.CURRENCY,
    decimalPlaces: null,
    metricCategoryKey: 'saasStats',
    monthDataKey: 'newARPA',
    summaryDataKey: 'newARPA',
    description: ({ isARR }) =>
      `${isARR ? 'Annualized' : 'Monthly'} New recurring revenue divided by number of new customers`,
    isARRMetric: true,
    isDecreasingMetric: false,
  },
  ARPA_NEW_TTM: {
    key: 'arpa-new-ttm',
    label: 'ARPA - New TTM',
    dataType: NUMBER_FORMATS.CURRENCY,
    decimalPlaces: null,
    metricCategoryKey: 'saasStats',
    monthDataKey: 'newARPA_TTM',
    summaryDataKey: 'newARPA_TTM',
    description: ({ isARR }) =>
      `${
        isARR ? 'Annualized' : 'Monthly'
      }  New recurring revenue divided by number of new customers for the trailing twelve months`,
    isARRMetric: true,
    isDecreasingMetric: false,
  },
  GROSS_MARGIN: {
    key: 'gross-margin',
    label: 'Gross Margin',
    dataType: NUMBER_FORMATS.PERCENT,
    decimalPlaces: 2,
    metricCategoryKey: 'saasStats',
    monthDataKey: 'grossMargin',
    summaryDataKey: 'grossMargin',
    description: ({ hasCostAttribution }) =>
      `1 - Sum of ${hasCostAttribution ? 'recurring' : 'all'} COGS expenses divided by ${
        hasCostAttribution ? 'recurring' : 'total'
      } revenue`,
    isARRMetric: false,
    isDecreasingMetric: false,
  },
  GROSS_MARGIN_TTM: {
    key: 'gross-margin-ttm',
    label: 'Gross Margin TTM',
    dataType: NUMBER_FORMATS.PERCENT,
    decimalPlaces: 2,
    metricCategoryKey: 'saasStats',
    monthDataKey: 'grossMargin_TTM',
    summaryDataKey: 'grossMargin_TTM',
    description: ({ hasCostAttribution }) =>
      `1 - Sum of ${hasCostAttribution ? 'recurring' : 'all'} COGS expenses divided by ${
        hasCostAttribution ? 'recurring' : 'total'
      } revenue for the trailing twelve months`,
    isARRMetric: false,
    isDecreasingMetric: false,
  },
  GROSS_MARGIN_NON_RECURRING: {
    key: 'gross-margin-non-recurring',
    label: 'Gross Margin (non-recurring)',
    dataType: NUMBER_FORMATS.PERCENT,
    decimalPlaces: 2,
    metricCategoryKey: 'saasStats',
    monthDataKey: 'grossMarginNonRecurring',
    summaryDataKey: 'grossMarginNonRecurring',
    description: () => '1 - Sum of non-recurring COGS expenses divided by non-recurring revenue',
    isARRMetric: false,
    isDecreasingMetric: false,
  },
  GROSS_MARGIN_NON_RECURRING_TTM: {
    key: 'gross-margin-non-recurring-ttm',
    label: 'Gross Margin (non-recurring) TTM',
    dataType: NUMBER_FORMATS.PERCENT,
    decimalPlaces: 2,
    metricCategoryKey: 'saasStats',
    monthDataKey: 'grossMarginNonRecurring_TTM',
    summaryDataKey: 'grossMarginNonRecurring_TTM',
    description: () =>
      '1 - Sum of non-recurring COGS expenses divided by non-recurring revenue for the trailing twelve months',
    isARRMetric: false,
    isDecreasingMetric: false,
  },
  EFFICIENCY: {
    key: 'efficiency',
    label: 'Efficiency',
  },
  MAGIC_NUMBER: {
    key: 'magic-number',
    label: 'Magic Number',
    dataType: NUMBER_FORMATS.NUMBER,
    metricCategoryKey: 'saasStats',
    monthDataKey: 'magicNumber',
    summaryDataKey: 'magicNumber',
    description: () =>
      '(Quarterly metric) Growth in recurring revenue this quarter divided by sales + marketing costs the previous quarter',
    isARRMetric: false,
    isDecreasingMetric: false,
  },
  SALES_EFFICIENCY_RATIO: {
    key: 'sales-efficiency-ratio',
    label: 'Sales Efficiency Ratio',
    dataType: NUMBER_FORMATS.NUMBER,
    metricCategoryKey: 'saasStats',
    monthDataKey: 'salesEfficiencyRatio',
    summaryDataKey: 'salesEfficiencyRatio',
    description: () =>
      '(Quarterly metric) Growth in recurring revenue this quarter divided by sales + marketing costs this quarter',
    isARRMetric: false,
    isDecreasingMetric: false,
  },
  PAYBACK_PERIOD: {
    key: 'payback-period',
    label: 'Payback period',
    dataType: NUMBER_FORMATS.MONTHS,
    decimalPlaces: 2,
    metricCategoryKey: 'saasStats',
    monthDataKey: 'paybackPeriod',
    summaryDataKey: 'paybackPeriod',
    description: () => 'In months, Sales and Marketing Costs divided by New MRR times Gross Margin',
    isARRMetric: false,
    isDecreasingMetric: true,
  },
  PAYBACK_PERIOD_TTM: {
    key: 'payback-period',
    label: 'Payback period TTM',
    dataType: NUMBER_FORMATS.MONTHS,
    decimalPlaces: 2,
    metricCategoryKey: 'saasStats',
    monthDataKey: 'paybackPeriod_TTM',
    summaryDataKey: 'paybackPeriod_TTM',
    description: () =>
      'In months, Sales and Marketing Costs divided by New MRR times Gross Margin for the trailing twelve months',
    isARRMetric: false,
    isDecreasingMetric: true,
  },
  RULE_OF_40_TTM: {
    key: 'rule-of-40-ttm',
    label: 'Rule of 40 TTM',
    dataType: NUMBER_FORMATS.PERCENT,
    decimalPlaces: 0,
    metricCategoryKey: 'saasStats',
    monthDataKey: 'ruleOf40_TTM',
    summaryDataKey: 'ruleOf40_TTM',
    description: () => 'Recurring Revenue growth % + (Net Profit / Total Revenue) for the trailing twelve months',
    isARRMetric: false,
    isDecreasingMetric: false,
  },
  BURN_MULTIPLE: {
    key: 'burn-multiple',
    label: 'Burn Multiple',
    dataType: NUMBER_FORMATS.NUMBER,
    metricCategoryKey: 'saasStats',
    monthDataKey: 'burnMultiple',
    summaryDataKey: 'burnMultiple',
    description: () => 'Net Profit x -1 / Net New ARR',
    isARRMetric: false,
    isDecreasingMetric: true,
  },
  BURN_MULTIPLE_TTM: {
    key: 'burn-multiple-ttm',
    label: 'Burn Multiple TTM',
    dataType: NUMBER_FORMATS.NUMBER,
    metricCategoryKey: 'saasStats',
    monthDataKey: 'burnMultiple_TTM',
    summaryDataKey: 'burnMultiple_TTM',
    description: () => 'Net Profit x -1 / Net New ARR for the trailing twelve months',
    isARRMetric: false,
    isDecreasingMetric: true,
  },
};

export const SNAPSHOT_METRIC_BY_LABEL = Object.values(SNAPSHOT_METRICS).reduce((acc, curr) => {
  acc[curr.label] = curr;
  return acc;
}, {});

export const SNAPSHOT_METRIC_BY_KEY = Object.values(SNAPSHOT_METRICS).reduce((acc, curr) => {
  // Only add if it is a business snapshot metric
  if (curr.metricCategoryKey) {
    acc[curr.key] = curr;
  }
  return acc;
}, {});

export const SNAPSHOT_METRIC_BY_VALUE_KEY = Object.entries(SNAPSHOT_METRICS).reduce((acc, [key, value]) => {
  acc[value.key] = key;
  return acc;
}, {});

/*
  [BE 2/16/22]
  You can add more metrics to this available list by grabbing them from SNAPSHOT_METRICS
*/
export const CHART_VISUALIZER_AVAILABLE_METRICS = {
  ARPA: SNAPSHOT_METRICS.ARPA,
  ARPA_TTM: SNAPSHOT_METRICS.ARPA_TTM,
  CAC_RECURRING: SNAPSHOT_METRICS.CAC_RECURRING,
  CAC_RECURRING_TTM: SNAPSHOT_METRICS.CAC_RECURRING_TTM,
  CUSTOMERS: SNAPSHOT_METRICS.CUSTOMERS,
  GROSS_CUSTOMER_RETENTION: SNAPSHOT_METRICS.GROSS_CUSTOMER_RETENTION,
  GROSS_CUSTOMER_RETENTION_TTM: SNAPSHOT_METRICS.GROSS_CUSTOMER_RETENTION_TTM,
  GROSS_REVENUE_RETENTION: SNAPSHOT_METRICS.GROSS_REVENUE_RETENTION,
  LTV: SNAPSHOT_METRICS.LTV,
  LTV_TTM: SNAPSHOT_METRICS.LTV_TTM,
  NET_REVENUE_RETENTION: SNAPSHOT_METRICS.NET_REVENUE_RETENTION,
  NET_REVENUE_RETENTION_TTM: SNAPSHOT_METRICS.NET_REVENUE_RETENTION_TTM,
  RECURRING_PERIOD_GROWTH: SNAPSHOT_METRICS.RECURRING_PERIOD_GROWTH,
  RECURRING_REVENUE: SNAPSHOT_METRICS.RECURRING_REVENUE,
  SEATS: SNAPSHOT_METRICS.SEATS,
  TOTAL_PERIOD_REVENUE: SNAPSHOT_METRICS.TOTAL_PERIOD_REVENUE,
  BURN_MULTIPLE: SNAPSHOT_METRICS.BURN_MULTIPLE,
  RULE_OF_40_TTM: SNAPSHOT_METRICS.RULE_OF_40_TTM,
  PAYBACK_PERIOD: SNAPSHOT_METRICS.PAYBACK_PERIOD,
  PAYBACK_PERIOD_TTM: SNAPSHOT_METRICS.PAYBACK_PERIOD_TTM,
  NET_NEW_REVENUE: SNAPSHOT_METRICS.NET_NEW_REVENUE,
  NET_NEW_REVENUE_TTM: SNAPSHOT_METRICS.NET_NEW_REVENUE_TTM,
};
