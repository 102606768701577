import dayjs from 'dayjs';
import { useContext } from 'react';
import { useFormikContext } from 'formik';
import { NUMBER_FORMATS, RECOGNITION_TYPES } from 'consts/global';
import { useCurrencyNumberFormatter } from 'utils/hooks';
import { calculateRecurringAmount } from 'models/transaction';
import { FlexerColumn, FlexerRow } from 'components/Core';
import { Banner, BannerText, BannersContainer } from 'components/Blocks/Banner';
import { TransactionContext } from '../TransactionContext';

const getRecognitionMonths = (startDate, endDate, includeEndMonth) => {
  const startDateObj = dayjs.utc(startDate);
  const endDateObj = dayjs.utc(endDate);
  const diffInMonths = endDateObj.diff(startDateObj.startOf('month'), 'month');
  return includeEndMonth ? diffInMonths + 1 : diffInMonths;
};

export const TransactionMismatchTotalAmountAndMRR = () => {
  const { transactionFormValues } = useContext(TransactionContext);
  const { isValid: isFormValid } = useFormikContext();

  const { currency, start_date, end_date, include_end_month, ...formValues } = transactionFormValues ?? {};

  const numberFormatter = useCurrencyNumberFormatter({ currency });

  const totalAmount = formValues?.amount ?? 0;
  const recurringAmount = formValues?.recurring_amount ?? 0;
  const expectedRecurringAmount = calculateRecurringAmount({
    startDate: start_date,
    endDate: end_date,
    totalAmount: totalAmount,
    includeEndMonth: include_end_month,
  });
  const linearRecognitionMonths = getRecognitionMonths(start_date, end_date, include_end_month);

  const amountsMismatch = parseFloat(recurringAmount).toFixed(2) !== parseFloat(expectedRecurringAmount).toFixed(2);
  const showWarning = isFormValid && formValues?.recognition === RECOGNITION_TYPES.linear && amountsMismatch;

  return showWarning ? (
    <FlexerRow marginTop="20px">
      <BannersContainer>
        <Banner tag="warning" showVerticalLine={false}>
          <FlexerColumn gap="8px">
            <BannerText style={{ fontWeight: 400 }}>
              <b>Please check again this transaction.</b> Subscript found some oddities:
            </BannerText>
            <BannerText style={{ fontWeight: 400 }}>
              <span>
                - The{' '}
                <b>
                  <i>total amount</i>
                </b>{' '}
                (
                {numberFormatter({
                  type: NUMBER_FORMATS.CURRENCY,
                  rawValue: +totalAmount,
                  decimalPlaces: 2,
                })}
                ) does not match the{' '}
                <b>
                  <i>monthly recurring revenue</i>
                </b>{' '}
                for the specified period (
                {numberFormatter({
                  type: NUMBER_FORMATS.CURRENCY,
                  rawValue: +recurringAmount,
                  decimalPlaces: 2,
                })}{' '}
                x {linearRecognitionMonths} ={' '}
                {numberFormatter({
                  type: NUMBER_FORMATS.CURRENCY,
                  rawValue: parseFloat(recurringAmount * linearRecognitionMonths).toFixed(2),
                  decimalPlaces: 2,
                })}
                )
              </span>
            </BannerText>
          </FlexerColumn>
        </Banner>
      </BannersContainer>
    </FlexerRow>
  ) : null;
};
