import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { CustomDatePicker, FormikCustomInput } from 'components/Controls';
import { ReactComponent as RemoveIcon } from 'images/circle-x.svg';
import { RowActionPopover, TableActionCell } from 'components/Table';
import { TooltipContainer } from 'components/Tooltip';
import { fillExchangeRates } from './utils';

dayjs.extend(isSameOrAfter);

export const getColumns = ({
  formRef,
  onRowRemove,
  homeCurrency,
  orgId,
  currenciesKeys = [],
  accountingSpreadsLockBeforeDate,
  dateFormat,
}) => {
  const accountingSpreadsLockBeforeDayJS = accountingSpreadsLockBeforeDate
    ? dayjs(accountingSpreadsLockBeforeDate)
    : null;
  return [
    {
      Header: 'DATE',
      accessor: 'date',
      width: 180,
      Cell: ({ row, cell: { value } }) => {
        const exchangeRateDayJS = dayjs(row.original?.date, 'YYYY-MM-DD');
        const dateIsBeforeAccountingSpreadLockDate = accountingSpreadsLockBeforeDayJS
          ? exchangeRateDayJS.isBefore(accountingSpreadsLockBeforeDayJS)
          : false;
        return (
          <CustomDatePicker
            offsetContainer={false}
            errorWithoutTooltip
            formik
            height="32px"
            dataCy={`rates-${dayjs(row.original?.date, 'YYYY-MM-DD').format(dateFormat)}__datepicker`}
            onChange={(name, val) =>
              formRef?.current &&
              dayjs(val).isValid() &&
              formRef?.current?.setFieldValue(name, dayjs(val).format('YYYY-MM-DD'))
            }
            selected={value && dayjs(value).isValid() ? dayjs(value).toDate() : ''}
            meta={formRef?.current && formRef?.current?.getFieldMeta(`rates[${row.original.rowIndex}].date`)}
            width="100%"
            name={`rates[${row.original.rowIndex}].date`}
            disabled={dateIsBeforeAccountingSpreadLockDate}
          />
        );
      },
    },
    ...currenciesKeys?.map((currencies, _, arr) => ({
      Header: currencies,
      accessor: currencies,
      disableSortBy: true,
      //120px min
      width: 750 / arr?.length < 120 ? 120 : 750 / arr?.length,
      Cell: ({ row, cell: { value } }) => {
        const exchangeRateDayJS = dayjs(row.original?.date, 'YYYY-MM-DD');
        const dateIsBeforeAccountingSpreadLockDate = accountingSpreadsLockBeforeDayJS
          ? exchangeRateDayJS.isBefore(accountingSpreadsLockBeforeDayJS)
          : false;
        return (
          <FormikCustomInput
            style={{ height: 32 }}
            errorWithoutTooltip
            placeholder="Enter rate..."
            data-cy={`rates-${dayjs(row.original?.date, 'YYYY-MM-DD').format(dateFormat)}-${currencies}`}
            value={value}
            width="100%"
            name={`rates[${row.original.rowIndex}].${currencies}`}
            isDisabled={dateIsBeforeAccountingSpreadLockDate}
          />
        );
      },
    })),

    {
      accessor: 'actions',
      disableSortBy: true,
      width: 75,
      Cell: ({ row }) => {
        const exchangeRateDayJS = dayjs(row.original?.date, 'YYYY-MM-DD');

        const isFutureDay = exchangeRateDayJS.isSameOrAfter(dayjs().add(1, 'day').startOf('day'));

        const dateIsBeforeAccountingSpreadLockDate = accountingSpreadsLockBeforeDayJS
          ? exchangeRateDayJS.isBefore(accountingSpreadsLockBeforeDayJS)
          : false;

        const disabled = isFutureDay || dateIsBeforeAccountingSpreadLockDate;

        const getToolTipContent = () => {
          if (isFutureDay) {
            return 'You cannot retrieve the exchange rate for a future date';
          } else if (dateIsBeforeAccountingSpreadLockDate) {
            return `You cannot change exchange rates for dates before the accounting spreads lock date: ${accountingSpreadsLockBeforeDayJS.format(
              'L',
            )}`;
          }
        };

        return (
          <TableActionCell>
            <TooltipContainer width={300} hideArrow isVisible={disabled} toolTipContent={getToolTipContent()}>
              <RowActionPopover
                currentSelection={row.original}
                deleteText="Reset to default"
                deleteIcon={<RemoveIcon />}
                onDeleteClick={() => onRowRemove({ row })}
                onEditClick={() => fillExchangeRates({ row, formRef, homeCurrency, currenciesKeys, orgId })}
                XOffset={-130}
                isLocked={disabled}
                isEditLocked={disabled}
                editText="Get exchange rates"
                dataCyPrefix={`exchange-rates-table__row--${row.original?.date}`}
              />
            </TooltipContainer>
          </TableActionCell>
        );
      },
    },
  ];
};
