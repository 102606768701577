import { useContext } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { ChargedIcon } from 'components/Icons';
import { Spacer, CentererVertical } from 'components/Core';
import { TruncateStringWithTooltip, TooltipContainer } from 'components/Tooltip';
import { transactionDisplayName } from 'models/transaction';
import { Banner, BannerText } from 'components/Blocks/Banner';
import { TransactionContext } from '../TransactionContext';
import { TRANSACTION_MODAL_MODE } from '../consts';

export const ReplacingTransactionLink = styled(Link)`
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  margin-left: 4px;
  color: var(--primaryBlue);
  cursor: pointer;

  &:hover {
    color: var(--secondaryBlue);
  }
`;

export const TransactionReplacedByWarning = () => {
  const { mode, currentTransaction, replacedByTransaction, changedDataFormatted } = useContext(TransactionContext);

  let showWarning;
  let warningMessage;
  if (mode === TRANSACTION_MODAL_MODE.EXTERNAL_UPDATE) {
    if (Object.keys(changedDataFormatted).length === 0) return null;

    showWarning = changedDataFormatted.replaced_by.original !== changedDataFormatted.replaced_by.updated;

    warningMessage = changedDataFormatted.replaced_by.updated
      ? `This transaction is now replaced by transaction ${changedDataFormatted.replaced_by.updated}`
      : `This transaction is NO LONGER replaced by ${changedDataFormatted.replaced_by.original}`;
  } else {
    showWarning = currentTransaction.replaced_by;
    warningMessage = 'This transaction cannot be edited because it is replaced by another transaction';
  }

  return showWarning ? (
    <Banner marginTop="20px" tag="warning">
      <BannerText>
        <CentererVertical>
          {mode === TRANSACTION_MODAL_MODE.EXTERNAL_UPDATE && (
            <>
              <TooltipContainer toolTipContent={`Old value: ${changedDataFormatted.replaced_by.original}`} width={100}>
                <ChargedIcon />
              </TooltipContainer>
              <Spacer width="10px" />
            </>
          )}
          {warningMessage}
        </CentererVertical>
        {replacedByTransaction && (
          <ReplacingTransactionLink
            data-cy="transaction-modal__replaced-name"
            to={`/transactions/${replacedByTransaction?.id}`}
            target="_blank"
          >
            <TruncateStringWithTooltip length={25} tooltipWidth={200}>
              {transactionDisplayName({ transaction: replacedByTransaction })}
            </TruncateStringWithTooltip>
          </ReplacingTransactionLink>
        )}
      </BannerText>
    </Banner>
  ) : null;
};
