import React from 'react';
import styled from 'styled-components';
import { cssVar, transparentize } from 'polished';
import { LabelText } from 'components/Core';

const Wrapper = styled.div`
  background-color: var(--neutralBlue);
  border: solid 1px ${transparentize(0.9, cssVar('--primaryBlue'))};
  border-radius: 16px;
  padding: 20px 26px;
`;

const Textarea = styled.div`
  background-color: white;
  border-color: solid 1px ${transparentize(0.1, cssVar('--primaryDark'))};
  color: var(--primaryDark);
  font-size: 14px;
  line-height: 20px;
  padding: 10px;
  width: 100%;
  white-space: pre-wrap;
`;

export const CodeBlock = ({ label, json }) => (
  <Wrapper>
    <LabelText>{label}</LabelText>
    <Textarea>{JSON.stringify(json, null, 2)}</Textarea>
  </Wrapper>
);
