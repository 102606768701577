import React from 'react';
import { ORG_CONFIGS } from 'consts/global';
import { humanize } from 'utils/stringUtils';
import { getIntegrationDisplayName } from 'models/integration';
import { FormikCustomCheckbox } from 'components/Controls';
import { FlexBetweenContainer, Spacer } from 'components/Core';
import { IncomeAccountSelector } from 'shared/IncomeAccountSelector';
import { FieldBody, FieldTitle } from 'views/Wizard/styles';
import { HAS_TAX_SETTINGS, SERVICE_WITH_INCOME_ACCOUNT_REF } from 'views/Billing/consts';
import { useExternalInvoiceTemplateSelector } from 'views/Billing/InvoicingScheduleModal/useExternalInvoiceTemplateSelector';
import { ItemsRow } from '../SubscriptBillingTab/BillingSettings/styles';
import { CheckboxDescription } from '../styles';
import { SyncAccountsButton } from './SyncAccountsButton';

const AdditionalSettings = ({ service, integration, setFieldValue }) => {
  const { ExternalInvoiceTemplateSelector, hasInvoiceTemplates } = useExternalInvoiceTemplateSelector({
    name: `configs.${ORG_CONFIGS.INVOICE_TEMPLATE_ID}`,
    integrationId: integration?.id,
    onTemplatesFetch: (templates) => setFieldValue('metadata.invoice_templates', templates),
    label: `Default branding theme for ${getIntegrationDisplayName(integration)}`,
  });

  return (
    <FieldBody>
      <FlexBetweenContainer centerer marginBottom="24px">
        <FieldTitle noMarginBottom>Billing settings</FieldTitle>
        <SyncAccountsButton integrationId={integration?.id} />
      </FlexBetweenContainer>

      <ItemsRow>
        {SERVICE_WITH_INCOME_ACCOUNT_REF.includes(service) && (
          <IncomeAccountSelector
            integrationId={integration?.id}
            label="Pick the account that we should map the revenue to"
            name={`configs.${ORG_CONFIGS.INCOME_ACCOUNT_REF_ID}`}
          />
        )}
      </ItemsRow>

      {hasInvoiceTemplates && (
        <ItemsRow>
          <ExternalInvoiceTemplateSelector />
        </ItemsRow>
      )}

      <Spacer height="16px" />

      {HAS_TAX_SETTINGS.includes(service) && (
        <>
          <FieldTitle>Tax Settings</FieldTitle>
          <p>
            Note: Unchecking both options means you will need to manually enter tax line items for invoices in
            Subscript.
          </p>

          <ItemsRow>
            <FormikCustomCheckbox
              data-cy="configuration__integrations--use-automatic-tax-checkbox"
              name="metadata.automaticTaxCalculation"
              label="Automatic tax calculation"
            />
          </ItemsRow>
          <CheckboxDescription>{`Use ${humanize(
            service,
          )} for determining taxes, then sync over into Subscript.`}</CheckboxDescription>
          <ItemsRow>
            <FormikCustomCheckbox
              data-cy="configuration__integrations--use-mapped-tax-codes-checkbox"
              name="metadata.useMappedTaxCodes"
              label="Mapped tax codes"
            />
          </ItemsRow>
          <CheckboxDescription>{`Use ${humanize(
            service,
          )} for determining taxes, with Subscript optionally dictating the tax codes ${humanize(
            service,
          )} will use. For example, you can set up custom rules based on product + customer geographic region.`}</CheckboxDescription>
        </>
      )}
    </FieldBody>
  );
};

export default AdditionalSettings;
