import React, { useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';

import { EVENTS } from 'consts/analytics';
import { AppContext } from 'AppContext';
import { useMonthlyBreakdownAPI } from 'api/monthlyBreakdown';
import { TimeLoader, TimeLoaderContainer } from 'components/Loaders';
import { GlobalTogglesFloatingContainer } from 'shared/GlobalToggles';
import { NoIntegrations, NoItems } from 'shared/ErrorBars';
import { generateMonths } from 'utils/dateUtils';

import { useAnalytics } from 'utils/hooks';
import { ReactComponent as NoMetric } from 'images/NoMetricState.svg';
import { calculateRevenueNURDCStats, calculateSaasStats } from 'views/Dashboard/DashboardTables/DashboardTables.utils';

import { ChartVizualizerContext } from './ChartVizualizerContext';
import { ChartVisualizerChart } from './ChartVisualizerChart';
import { ChartVisualizerTable } from './ChartVisualizerTable';
import { OverviewMetrics } from './OverviewMetrics';
import { calculateDataForOverview } from './utils';

const Wrapper = styled.div`
  width: calc(100% + 60px);
  padding: 0 40px 0 20px;
  margin-left: -20px;
  overflow-x: hidden;
`;

const Divider = styled.hr`
  border: none;
  height: 1px;
  margin: 32px 0;
  background-color: var(--lightGray);
`;

const MainContainer = styled.div`
  padding-bottom: 50px;
`;

export const ChartVisualizerPage = ({ exportScreenshotRef }) => {
  const {
    organizations,
    integrations,
    appSettings: { currencyISOCode },
  } = useContext(AppContext);
  const {
    // url state
    isARR,
    dataFilter,
    isCommitted,
    rollup,
    optimisticAnalytics,
    countInfluxAsRenewed,
    // ui state
    fetchDataError,
    loading,
    selectedMetrics,
    setFetchDataError,
    setLoading,
  } = useContext(ChartVizualizerContext);

  const { trackEvent } = useAnalytics();

  useEffect(() => {
    trackEvent({ name: EVENTS.CHART_VISUALIZER_VISIT });
    // eslint-disable-next-line
  }, []);

  const [isPreviousData, setIsPreviousData] = useState(false);

  const myOrganization = organizations?.[0];
  const hasTransactions = myOrganization && parseInt(myOrganization.transactionCount) > 0;

  const startDate = dataFilter.startMonth;
  const endDate = dataFilter.endMonth;
  const months = generateMonths({ startDate, endDate, inclusive: true });
  const segmentBy = dataFilter.chartSegmentBy;

  /*
    [BE 2/16/22]: we may have to add in plugins when we allow more CHART_VISUALIZER_AVAILABLE_METRICS in `consts/snapshotMetrics`
  */
  const { data, isLoading, isFetching, error, refetchMonthlyBreakdown } = useMonthlyBreakdownAPI({
    orgId: myOrganization?.id,
    startMonth: dayjs(startDate).startOf('month').subtract(12, 'month').month(), // we need this for YoY growth calculations
    startYear: dayjs(startDate).startOf('month').subtract(12, 'month').year(),
    endMonth: dayjs(endDate).month(),
    endYear: dayjs(endDate).year(),
    rollup,
    plugins: ['TTMMetrics'],
    optimisticAnalytics,
    isCommitted,
    segmentBy,
    countInfluxAsRenewed,
    usesMetricsEngine: myOrganization?.usesMetricsEngine,
  });

  useEffect(() => {
    if (!error) return;
    setFetchDataError(true);
    console.error({ error, message: error.message, component: 'Chart' });
  }, [error, setFetchDataError]);

  useEffect(() => {
    setLoading(isLoading || isFetching || !data);
  }, [isLoading, isFetching, data, setLoading]);

  useEffect(() => {
    if (!data) return;
    setIsPreviousData(true);
  }, [data]);

  const { revenueStats: rawRevenueStats, customerStats, seatStats, saasStats: rawSaasStats } = data ?? {};

  const revenueStats = useMemo(() => calculateRevenueNURDCStats({ isARR, revenueNURDCStats: rawRevenueStats }), [
    isARR,
    rawRevenueStats,
  ]);

  const saasStats = useMemo(() => calculateSaasStats({ isARR, saasStats: rawSaasStats }), [isARR, rawSaasStats]);

  const dataForVisualizer = {
    revenueStats,
    customerStats,
    saasStats,
    seatStats,
  };

  const dataForOverview = calculateDataForOverview({ selectedMetrics, dataForVisualizer, months });

  if (integrations.length === 0 && !loading && !hasTransactions) return <NoIntegrations />;

  return (
    <Wrapper>
      {fetchDataError ? (
        <NoItems
          entityName="Data"
          filterState={dataFilter}
          buttonCb={refetchMonthlyBreakdown}
          organization={myOrganization}
        />
      ) : (
        <TimeLoaderContainer isFirstLoad={!isPreviousData} isLoading={loading}>
          {loading || Object.values(dataForVisualizer).some((value) => !value) ? (
            <TimeLoader isFirstLoad={!isPreviousData} pageName="chart" />
          ) : selectedMetrics.length === 0 ? (
            <NoMetric style={{ display: 'flex', margin: '74px auto' }} />
          ) : (
            <GlobalTogglesFloatingContainer>
              <MainContainer ref={exportScreenshotRef}>
                <ChartVisualizerChart
                  height="400px"
                  selectedMetrics={selectedMetrics}
                  dataForVisualizer={dataForVisualizer}
                  months={months}
                  currency={currencyISOCode}
                />
                <OverviewMetrics dataForOverview={dataForOverview} months={months} />
                <Divider />
                <ChartVisualizerTable
                  selectedMetrics={selectedMetrics}
                  dataForOverview={dataForOverview}
                  months={months}
                />
              </MainContainer>
            </GlobalTogglesFloatingContainer>
          )}
        </TimeLoaderContainer>
      )}
    </Wrapper>
  );
};
