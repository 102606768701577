import React from 'react';
import { usePortal } from 'components/Portal';
import { TooltipContainer } from 'components/Tooltip';
import { NewBadge } from 'components/Blocks';
import { ListItem, StyledButton, tooltipStyles } from './styles';
import { PopoverLinkContents } from './PopoverLinkContents';

export const PopoverLink = ({
  active,
  icon,
  title,
  explainTooltipText,
  listItems,
  PopoverContents,
  ExtraPopoverSection,
  YOffset,
  isNewFeature,
  children,
}) => {
  const { triggerRef, togglePortal, isPortalVisible, Portal, hidePortal } = usePortal({
    XOffset: 114,
    YOffset: YOffset ?? 74,
  });
  const isActive = isPortalVisible || active;

  return (
    <>
      <TooltipContainer
        toolTipContent={explainTooltipText}
        tooltipStyles={tooltipStyles}
        xOffset={124}
        yOffset={-74}
        hideArrow={true}
        isVisible={!!explainTooltipText && !isPortalVisible}
      >
        <ListItem $active={isActive}>
          {isNewFeature && <NewBadge>New</NewBadge>}
          <StyledButton
            data-cy={`navbar__open-${title}-popover`}
            $active={isActive}
            onClick={togglePortal}
            ref={triggerRef}
          >
            {icon}
            {title}
            {children}
          </StyledButton>
        </ListItem>
      </TooltipContainer>
      {isPortalVisible && (
        <Portal>
          {PopoverContents ? (
            <PopoverContents onClose={hidePortal} togglePortal={togglePortal} />
          ) : (
            <PopoverLinkContents title={title} listItems={listItems} ExtraPopoverSection={ExtraPopoverSection} />
          )}
        </Portal>
      )}
    </>
  );
};
