import styled from 'styled-components';

export const LabelIconContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: ${({ labelFlex }) => labelFlex ?? 'start'};
  align-items: center;

  margin-bottom: ${({ labelDirection }) => (labelDirection === 'left' ? '0px' : '8px')};
  margin-right: ${({ labelDirection, labelFlex }) =>
    labelFlex === 'end' || labelDirection === 'left' ? '8px' : '0px'};

  svg {
    margin-left: 10px;
    width: 12px;
    height: 12px;

    path {
      fill: var(--primaryBlue);
    }
    cursor: pointer;
  }

  &:hover {
    svg {
      path {
        fill: var(--secondaryBlue);
      }
    }
  }
`;
