import styled from 'styled-components';
import { Centerer, FlexerColumn } from 'components/Core';

export const BodyContainer = styled(FlexerColumn)`
  overflow: auto;
  height: 100%;
`;

export const Wrapper = styled(Centerer)`
  flex: 1;
  gap: 40px;
  align-items: stretch;
  justify-content: space-between;
  opacity: ${(props) => props.disabled && '0.7'};
  overflow: auto;
  pointer-events: ${(props) => props.disabled && 'none'};
  position: relative;
  padding: 20px 40px;
`;

export const TopSection = styled(FlexerColumn)`
  padding: 20px 40px 0;
`;

export const LeftPane = styled(FlexerColumn)`
  opacity: ${(props) => props.disabled && '0.7'};
  max-width: 25%;
  @media (min-width: 1280px) {
    min-width: 420px;
  }

  @media (min-width: 1440px) {
    min-width: 446px;
  }
`;

export const RightPane = styled(FlexerColumn)`
  flex: 1;
  opacity: ${(props) => props.disabled && '0.7'};
`;
