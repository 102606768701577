import React from 'react';
import { ReactComponent as UnsavedChangesIcon } from 'images/unsaved_changes_icon.svg';
import { IconGreenButton } from 'components/Buttons';
import { Flexer } from 'components/Core';
import {
  UnsavedChangesOverflow,
  UnsavedChangesModal,
  UnsavedChangesContent,
  CancelButton,
} from './InvoicingScheduleUnsavedWarning.styles';

export const InvoicingScheduleUnsavedWarning = ({ onCancel, onConfirm }) => (
  <>
    <UnsavedChangesOverflow />
    <UnsavedChangesModal>
      <UnsavedChangesIcon />
      <UnsavedChangesContent>
        <h5>You have unsaved changes</h5>
        <p>Do you really want to close window without saving changes?</p>
        <Flexer>
          <CancelButton onClick={onCancel}>No, cancel</CancelButton>
          <IconGreenButton dataCy="invoicing-schedule__close-modal__confirm" text="Yes, close" onClick={onConfirm} />
        </Flexer>
      </UnsavedChangesContent>
    </UnsavedChangesModal>
  </>
);
