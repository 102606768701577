import React, { Fragment } from 'react';
import styled from 'styled-components';
import { FormikCustomInput } from 'components/Controls';
import { CustomersEditCountryField } from './CustomersEditCountryField';

const FormInputsRow = styled.div`
  display: flex;

  > * {
    width: 100%;
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }
  }
`;

export const EditBillingAddress = ({ values, setFieldValue, service, isDisabled }) => (
  <Fragment>
    <FormInputsRow>
      <FormikCustomInput
        name="addressLine1"
        data-cy="input-billingaddressLine1"
        placeholder="Enter adress..."
        label="Adress Line 1"
        value={values.invoicing_details?.addressLine1 ?? ''}
        handleChange={(val) => {
          setFieldValue('invoicing_details.addressLine1', val);
        }}
        isDisabled={isDisabled}
      />
      <FormikCustomInput
        name="addressLine2"
        data-cy="input-billingaddressLine2"
        placeholder="Enter adress..."
        label="Adress Line 2"
        value={values.invoicing_details?.addressLine2 ?? ''}
        handleChange={(val) => {
          setFieldValue('invoicing_details.addressLine2', val);
        }}
        isDisabled={isDisabled}
      />
    </FormInputsRow>

    <FormInputsRow>
      <FormikCustomInput
        name="billingCity"
        data-cy="input-billingCity"
        placeholder="Select city..."
        label="City"
        value={values.invoicing_details?.city ?? ''}
        handleChange={(val) => {
          setFieldValue('invoicing_details.city', val);
        }}
        isDisabled={isDisabled}
      />
      <FormikCustomInput
        name="billingState"
        data-cy="input-billingState"
        placeholder="Select state..."
        label="State"
        value={values.invoicing_details?.state ?? ''}
        handleChange={(val) => {
          setFieldValue('invoicing_details.state', val);
        }}
        isDisabled={isDisabled}
      />
      <FormikCustomInput
        name="billingPostalcode"
        data-cy="input-billingPostalcode"
        placeholder="Enter zipcode..."
        label="ZIP code"
        value={values.invoicing_details?.postalcode ?? ''}
        handleChange={(val) => {
          setFieldValue('invoicing_details.postalcode', val);
        }}
        isDisabled={isDisabled}
      />
      <CustomersEditCountryField
        value={values.invoicing_details?.country ?? ''}
        setFieldValue={setFieldValue}
        fieldName="invoicing_details.country"
        service={service}
        isDisabled={isDisabled}
      />
    </FormInputsRow>
  </Fragment>
);
