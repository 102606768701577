import React from 'react';
import styled from 'styled-components';
import { CentererVertical } from 'components/Core/Flex';
import { SNAPSHOT_METRICS } from 'consts/snapshotMetrics';
import { ReactComponent as FillDot } from 'images/fill-dot.svg';
import { generateChart, CHART_COLORS } from './utils';

const ChartWrapper = styled.div`
  height: 100%;
  width: 100%;
  margin-top: ${({ standalone }) => standalone && '32px'};
`;

const ChartContainer = styled.div`
  height: ${({ height }) => height ?? '100%'};
  width: 100%;
`;

const ChartLegend = styled(CentererVertical)`
  margin-top: 32px;
  padding: 12px 16px;
  font-size: 12px;
  border: 1px solid var(--primaryBlack10);
  border-radius: 8px;
`;

const ChartLabel = styled.span`
  margin-right: 20px;
  font-weight: 800;
  color: var(--primaryBlack30);
`;

const KeysContainer = styled.div`
  margin-right: 20px;
`;

const KeyLabel = styled.span`
  margin-left: 4px;
  font-weight: 900;
`;

export const ChartVisualizerChart = ({
  selectedMetrics,
  dataForVisualizer,
  months,
  includeLegend = true,
  standalone = true,
  height,
  currency,
}) => {
  if (Object.values(dataForVisualizer).some((value) => !value)) return null;
  const chart = generateChart({ selectedMetrics, dataForVisualizer, months, currency });
  if (!chart) return null;
  const legendKeys = selectedMetrics.map((metric) => SNAPSHOT_METRICS[metric].label);
  return (
    <ChartWrapper standalone={standalone}>
      <ChartContainer height={height}>{chart}</ChartContainer>
      {includeLegend && (
        <ChartLegend>
          <ChartLabel>Chart: </ChartLabel>
          {legendKeys.map((label, index) => (
            <KeysContainer key={label} data-cy={`chart-visualizer-chart-legend__${label}`}>
              <FillDot fill={CHART_COLORS[index]} />
              <KeyLabel>{label}</KeyLabel>
            </KeysContainer>
          ))}
        </ChartLegend>
      )}
    </ChartWrapper>
  );
};
