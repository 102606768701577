import { useCallback, useState } from 'react';
import { AddIntegrationModal } from './AddIntegrationModal';

export const useAddIntegrationModal = (props) => {
  const [showModal, setShowModal] = useState(false);

  const openModal = useCallback(() => {
    setShowModal(true);
  }, [setShowModal]);

  const Modal = useCallback(
    () => (showModal ? <AddIntegrationModal {...props} onClose={() => setShowModal(false)} /> : null),
    [showModal, setShowModal, props],
  );

  return {
    openAddIntegrationModal: openModal,
    AddIntegrationModal: Modal,
  };
};
