import styled from 'styled-components';
import { Centerer } from 'components/Core';

export const PercentageDifferenceWrapper = styled(Centerer)`
  flex-direction: row;

  background: ${({ colored, positive }) =>
    colored ? (positive ? 'var(--primaryGreen)' : '#e70000') : 'var(--dark20)'};
  border-radius: 4px;
  padding: 0px 4px;
  font-size: 10px;

  color: #ffffff;
`;
