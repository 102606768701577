import styled from 'styled-components';
import { Centerer } from 'components/Core';

export const BoxShadow = styled.div`
  background: grey;
  height: 50px;
  width: ${(props) => props.width};
  opacity: ${(props) => props.opacity};
  border-radius: 20px;
  left: 50%;
  transform: translate(-50%, 0);
  position: absolute;
  z-index: -1;
  top: ${(props) => props.top};
`;

export const ContentWrapper = styled.div`
  align-items: center;
  background-color: var(--primaryGray);
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  width: 100%;
  z-index: 1;
`;

export const LoaderContainer = styled(Centerer)`
  height: 400px;
`;

export const ContentMain = styled.div`
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const InvoicingScheduleBody = styled(Centerer)`
  flex: 1;
  align-items: stretch;
  justify-content: flex-start;
  opacity: ${(props) => props.disabled && '0.7'};
  overflow: auto;
  pointer-events: ${(props) => props.disabled && 'none'};
  position: relative;
`;
