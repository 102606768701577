export const DEFAULT_PAGE_SIZE = 20;

export const TOOLS_TIP_COLORS = {
  Unconfirmed: 'var(--primaryRed)',
  Confirmed: 'var(--primaryGreen)',
};

export const STATUS_OPTIONS = {
  CONFIRMED: 'confirmed',
  UNCONFIRMED: 'unconfirmed',
  DELETED: 'deleted',
};

export const CONFIRMATION_OPTIONS = {
  'To be confirmed': false,
  Confirmed: true,
  'All transactions': null,
};

export const GROUP_BY_OPTIONS = {
  CUSTOMER: 'Customer',
  NONE: null,
  CONFIRMED_AT: 'confirmed_at',
};

export const TRANSACTION_TABLE_COLUMN_KEY = {
  ID: 'id',
  DATE: 'date',
  DEAL_NAME: 'name',
  STATUS: 'confirmed',
  CHECKS: 'auto_confirmed',
  CHECKBOX: 'checked',
  EXTERNAL_ID: 'external_id',
  CUSTOMER: 'customer_name',
  RECURRING_REVENUE: 'recurring_revenue',
  AMOUNT: 'amount',
  SEATS: 'seats',
  RECOGNITION_TYPE: 'recognition_type',
  PRODUCT: 'product_name',
  START_DATE: 'start_date',
  END_DATE: 'end_date',
  LEFT_TO_RECOGNIZE: 'left_to_recognize',
  UPDATED_AT: 'updated_at',
  CREATED_AT: 'created_at',
  SPREAD_SUM: 'spread_sum',
};

// convert the react-table column names to names that the transactions.list db query will recognize
export const TRANSACTION_TABLE_COLUMN_NAME_MAPPING = {
  [TRANSACTION_TABLE_COLUMN_KEY.EXTERNAL_ID]: 'external_ids',
  [TRANSACTION_TABLE_COLUMN_KEY.RECOGNITION_TYPE]: 'recognition',
};

export const TRANSACTION_TABLE_COLUMN_TITLES_BY_ID = {
  [TRANSACTION_TABLE_COLUMN_KEY.ID]: 'ID',
  [TRANSACTION_TABLE_COLUMN_KEY.CHECKBOX]: 'Checkbox',
  [TRANSACTION_TABLE_COLUMN_KEY.DATE]: 'Date',
  [TRANSACTION_TABLE_COLUMN_KEY.DEAL_NAME]: 'Deal name',
  [TRANSACTION_TABLE_COLUMN_KEY.EXTERNAL_ID]: 'External ID',
  [TRANSACTION_TABLE_COLUMN_KEY.CUSTOMER]: 'Customer',
  [TRANSACTION_TABLE_COLUMN_KEY.RECURRING_REVENUE]: 'RR',
  [TRANSACTION_TABLE_COLUMN_KEY.AMOUNT]: 'Total',
  [TRANSACTION_TABLE_COLUMN_KEY.STATUS]: 'Status',
  [TRANSACTION_TABLE_COLUMN_KEY.CHECKS]: 'Checks',
  [TRANSACTION_TABLE_COLUMN_KEY.SEATS]: 'Seats',
  [TRANSACTION_TABLE_COLUMN_KEY.RECOGNITION_TYPE]: 'Type',
  [TRANSACTION_TABLE_COLUMN_KEY.PRODUCT]: 'Product',
  [TRANSACTION_TABLE_COLUMN_KEY.START_DATE]: 'Start',
  [TRANSACTION_TABLE_COLUMN_KEY.END_DATE]: 'End',
  [TRANSACTION_TABLE_COLUMN_KEY.LEFT_TO_RECOGNIZE]: 'Left to recognize',
  [TRANSACTION_TABLE_COLUMN_KEY.UPDATED_AT]: 'Updated at',
  [TRANSACTION_TABLE_COLUMN_KEY.CREATED_AT]: 'Created at',
};

export const TRANSACTION_FIELD_VALUE_BY_ID = {
  [TRANSACTION_TABLE_COLUMN_KEY.ID]: 'id',
  [TRANSACTION_TABLE_COLUMN_KEY.DATE]: 'date',
  [TRANSACTION_TABLE_COLUMN_KEY.STATUS]: 'confirmed',
  [TRANSACTION_TABLE_COLUMN_KEY.CHECKS]: 'auto_confirmed',
  [TRANSACTION_TABLE_COLUMN_KEY.CHECKBOX]: 'checked',
  [TRANSACTION_TABLE_COLUMN_KEY.DEAL_NAME]: 'name',
  [TRANSACTION_TABLE_COLUMN_KEY.EXTERNAL_ID]: 'external_ids',
  [TRANSACTION_TABLE_COLUMN_KEY.CUSTOMER]: 'customer_name',
  [TRANSACTION_TABLE_COLUMN_KEY.AMOUNT]: 'amount',
  [TRANSACTION_TABLE_COLUMN_KEY.SEATS]: 'seats',
  [TRANSACTION_TABLE_COLUMN_KEY.RECOGNITION_TYPE]: 'recognition',
  [TRANSACTION_TABLE_COLUMN_KEY.PRODUCT]: 'product_name',
  [TRANSACTION_TABLE_COLUMN_KEY.START_DATE]: 'start_date',
  [TRANSACTION_TABLE_COLUMN_KEY.END_DATE]: 'end_date',
  [TRANSACTION_TABLE_COLUMN_KEY.LEFT_TO_RECOGNIZE]: 'LEFT_TO_RECOGNIZE', //not a field on transaction
  [TRANSACTION_TABLE_COLUMN_KEY.UPDATED_AT]: 'updated_at',
  [TRANSACTION_TABLE_COLUMN_KEY.CREATED_AT]: 'created_at',
  [TRANSACTION_TABLE_COLUMN_KEY.SPREAD_SUM]: 'spread_sum',
};
