import { RECOGNITION_TYPES } from 'consts/global';
import { FILTER_FIELD_LABELS, METADATA_FILTER_TYPES } from './consts';
import { INTERNAL_CUSTOMER_METADATA } from 'models/customer';
import { getIntegrationDisplayName } from 'models/integration';

export const getMetadataFilterValue = ({ metadataFilter, returnKey }) => {
  if (metadataFilter) {
    const metadataFilterValues = { ...metadataFilter };

    //remove all fields that not a Value
    delete metadataFilterValues._endMonth;
    delete metadataFilterValues._operator;
    delete metadataFilterValues._startMonth;

    return returnKey ? Object.keys(metadataFilterValues)[0] : Object.values(metadataFilterValues)[0];
  }
};

export const getFieldOptions = ({ filterType, metadataOptions }) => {
  if (metadataOptions[filterType] && Object.keys(metadataOptions[filterType]).length) {
    const options = Object.keys(metadataOptions[filterType]).map((key) => {
      return { label: FILTER_FIELD_LABELS[key] || key, value: key };
    });
    return options;
  } else {
    return [];
  }
};

export const addEmptyFieldsToMetadataOption = ({ metadataOptions }) => {
  metadataOptions[METADATA_FILTER_TYPES.TRANSACTIONS].topLevel__product_id = [];
  metadataOptions[METADATA_FILTER_TYPES.TRANSACTIONS].topLevel__product_category_id = [];
  metadataOptions[METADATA_FILTER_TYPES.TRANSACTIONS].topLevel__amount = [];
  metadataOptions[METADATA_FILTER_TYPES.TRANSACTIONS].topLevel__amount_left_to_recognize = [];
  metadataOptions[METADATA_FILTER_TYPES.TRANSACTIONS].topLevel__currency = [];
  metadataOptions[METADATA_FILTER_TYPES.TRANSACTIONS].topLevel__date = [];
  metadataOptions[METADATA_FILTER_TYPES.TRANSACTIONS].topLevel__start_date = [];
  metadataOptions[METADATA_FILTER_TYPES.TRANSACTIONS].topLevel__end_date = [];
  metadataOptions[METADATA_FILTER_TYPES.TRANSACTIONS].topLevel__recognition = [];
  metadataOptions[METADATA_FILTER_TYPES.CUSTOMERS].topLevel__product_id = [];
  metadataOptions[METADATA_FILTER_TYPES.CUSTOMERS].topLevel__arr_growth = [];
  metadataOptions[METADATA_FILTER_TYPES.CUSTOMERS].topLevel__id = [];
  metadataOptions[METADATA_FILTER_TYPES.CUSTOMERS].topLevel__root_customer_id = [];
};

export const getInitialMetadataFilterFormValue = ({ metadataFilter, metadataFilterTypes }) => {
  if (!metadataFilter) {
    return { filters: null };
  }
  const filters = [];
  for (const [filterType, typeFilters] of Object.entries(metadataFilter)) {
    if (!metadataFilterTypes.includes(filterType)) continue;
    const typeFiltersArray = Array.isArray(typeFilters) ? typeFilters : [typeFilters];
    for (const filter of typeFiltersArray) {
      filters.push({
        filterType,
        field: getMetadataFilterValue({ metadataFilter: filter, returnKey: true }),
        value: getMetadataFilterValue({ metadataFilter: filter }),
        _operator: filter?._operator,
      });
    }
  }
  return { filters };
};

export const getValueOptions = ({ filterType, fieldValue, metadataOptions, organizations, glIntegrationsById }) => {
  if (fieldValue === INTERNAL_CUSTOMER_METADATA.SELECTED_GL_INTEGRATION_ID) {
    return metadataOptions[filterType][fieldValue].map((integrationId) => ({
      label: getIntegrationDisplayName(glIntegrationsById[integrationId]),
      value: integrationId,
    }));
  } else if (
    metadataOptions[filterType] &&
    metadataOptions[filterType][fieldValue] &&
    metadataOptions[filterType][fieldValue].length
  ) {
    return metadataOptions[filterType][fieldValue]
      .filter((value) => value !== null)
      .sort((a, b) => {
        return Number(a) && Number(b) ? a - b : 1;
      })
      .map((value) => ({ label: String(value), value: value }));
  } else if (fieldValue === 'topLevel__product_id') {
    return organizations[0].products.map((product) => ({
      label: product.name,
      value: product.id,
    }));
  } else if (fieldValue === 'topLevel__product_category_id') {
    return organizations[0].productCategories.map((productCategory) => ({
      label: productCategory.name,
      value: productCategory.id,
    }));
  } else if (fieldValue === 'topLevel__recognition') {
    return Object.values(RECOGNITION_TYPES).map((value) => ({
      label: value,
      value,
    }));
  } else if (fieldValue === 'topLevel__currency') {
    return organizations[0].currencies.map((currency) => ({
      label: currency,
      value: currency,
    }));
  } else {
    return [];
  }
};
