import styled from 'styled-components';
import { ReactComponent as Icon } from 'images/bxs-edit-alt-2.svg';

export const PencilIcon = styled(Icon)`
  width: ${({ size }) => size ?? '20px'};
  height: ${({ size }) => size ?? '20px'};

  stroke: ${({ stroke }) => stroke};

  path {
    fill: ${({ fill }) => fill};
  }
`;
