import { useCallback, useContext, useState } from 'react';
import styled from 'styled-components';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import { AppContext } from 'AppContext';
import { Modal, ModalButton, ModalCloseIcon, ModalContainer, ModalFooter } from 'components/Modal';
import { FlexBetweenContainer } from 'components/Core';
import { FormikCustomInput, FormikCustomSelector } from 'components/Controls';
import { useUsersAPI } from 'api/users/hooks';

import { Wrapper, Header } from './styles';

const ActionItem = styled(FlexBetweenContainer)`
  margin-bottom: 20px;
  gap: 12px;
`;

const FlexGrow = styled.div`
  width: 100%;
`;

export const ROLE_OPTIONS = [
  {
    label: 'Admin',
    value: 'full',
  },
  {
    label: 'Member',
    value: 'readonly',
  },
];

export const MemberInviteModal = ({ onClose }) => {
  const { orgId } = useContext(AppContext);

  const {
    operations: { inviteUser },
  } = useUsersAPI({ autoFetch: false, orgId });

  const initialValues = {
    name: '',
    email: '',
    permissions: 'readonly',
  };

  return (
    <ModalContainer>
      <Modal
        overflow="visible"
        width="440px"
        minHeight="300px"
        data-cy="members-invite-modal"
        height="auto"
        maxHeight="auto"
      >
        <ModalCloseIcon onClose={onClose} />
        <Formik
          initialValues={initialValues}
          validationSchema={Yup.object({
            name: Yup.string().required(),
            email: Yup.string().email('Invalid email').required(),
            permissions: Yup.string().required(),
          })}
          onSubmit={async (values) => {
            await inviteUser.mutateAsync({ data: values });
            onClose();
          }}
        >
          {({ values, setFieldValue, handleSubmit }) => (
            <Form>
              <Wrapper>
                <Header>Invite Member</Header>
                <ActionItem>
                  <FlexGrow>
                    <FormikCustomInput
                      label="Name"
                      name="name"
                      placeholder="Enter name"
                      value={values.name}
                      onChange={(value) => setFieldValue('name', value)}
                    />
                  </FlexGrow>
                  <FlexGrow>
                    <FormikCustomSelector
                      label="Role"
                      placeholder="Select role"
                      name="permissions"
                      value={
                        values?.permissions ? ROLE_OPTIONS.find((option) => option.value === values.permissions) : null
                      }
                      options={ROLE_OPTIONS}
                      handleChange={(option) => setFieldValue('permissions', option ? option.value : null)}
                      tooltipInputDisplay="Admins have full permissions, while members have read-only permissions"
                    />
                  </FlexGrow>
                </ActionItem>
                <ActionItem>
                  <FlexGrow>
                    <FormikCustomInput
                      label="Email"
                      placeholder="Enter email"
                      name="email"
                      value={values.email}
                      onChange={(value) => setFieldValue('email', value)}
                    />
                  </FlexGrow>
                </ActionItem>
              </Wrapper>

              <ModalFooter flexEnd>
                <ModalButton default onClick={onClose}>
                  Cancel
                </ModalButton>
                <ModalButton primary onClick={handleSubmit} data-cy="invite-member-modal__save-button">
                  {'Save & Send Invite'}
                </ModalButton>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>
    </ModalContainer>
  );
};

export const useMemberInviteModal = () => {
  const [showModal, setShowModal] = useState(false);
  const openModal = useCallback(() => {
    setShowModal(true);
  }, [setShowModal]);
  const closeModal = useCallback(() => setShowModal(false), [setShowModal]);

  const Modal = useCallback(() => showModal && <MemberInviteModal onClose={closeModal} />, [closeModal, showModal]);

  return {
    openModal,
    MemberInviteModal: Modal,
  };
};
