import styled from 'styled-components';
import { TooltipContainer } from 'components/Tooltip';
import { TooltipWrapper } from 'components/Tooltip/styles';

const Wrapper = styled.span`
  &:hover {
    cursor: pointer;
  }
`;

export const ScenarioMonthTooltip = ({ children }) => (
  <TooltipContainer width={100} yOffset={4} toolTipContent={<TooltipWrapper>Open details</TooltipWrapper>}>
    <Wrapper>{children}</Wrapper>
  </TooltipContainer>
);
