import styled from 'styled-components';
import { cssVar, transparentize } from 'polished';

export const AuditsHistory = styled.div`
  background: #ffffff;
  box-shadow: 0px 30px 120px rgba(0, 21, 46, 0.4);
  border-radius: 20px;
  width: 890px;
`;

export const LastUpdated = styled.div`
  color: ${transparentize(0.6, cssVar('--primaryBlack'))};
  background: ${transparentize(0.98, cssVar('--primaryBlack'))};
  font-weight: 900;
  font-size: 10px;
  line-height: 14px;
  text-transform: uppercase;
  display: flex;
  cursor: pointer;
  align-items: center;
  padding: 12px;
  border-radius: 100px;

  span {
    color: var(--primaryBlue);
    margin-left: 4px;
  }

  svg {
    margin-left: 4px;

    path {
      fill: var(--primaryBlue);
    }
  }
`;

export const AuditTable = styled.div`
  font-size: 12px;
  display: flex;
  flex-direction: column;
  max-height: 300px;
  overflow: auto;
  line-height: 16px;
  padding: 24px;
  background: rgba(0, 133, 255, 0.05);

  border-top: 1px solid rgba(0, 133, 255, 0.1);
`;

export const ColHead = styled.div`
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 8px;
  text-align: ${(props) => props.actionStyle && 'right'};
  opacity: ${(props) => props.actionStyle && '0.3'};
`;

export const AuditVer = styled.div`
  font-weight: bold;
`;

export const AuditChanges = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const TableHeader = styled.div`
  display: grid;
  grid-template-columns: 72px 3fr 5fr 2fr;
`;

export const AuditAction = styled.div`
  text-align: right;
  cursor: ${({ disabled }) => (disabled ? undefined : 'pointer')};
  font-weight: bold;
  color: var(--primaryBlue);
`;

export const EditIconWrapper = styled.div`
  padding: 17px 0;
  padding-right: 12px;
  border-right: 1px solid var(--primaryBlack5);
  margin-right: 12px;

  svg path {
    fill: var(--primaryYellow);
  }
`;

export const ChangeCard = styled.div`
  text-transform: uppercase;
  padding: 1px 4px;
  font-weight: bold;
  background: var(--primaryBlack5);
  border-radius: 2px;
  font-size: 10px;
  line-height: 14px;
  margin-right: 11px;
  margin-top: ${(props) => props.marginTop && '8px'};
`;

export const TableRow = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  border: 1px solid rgba(57, 161, 220, 0.1);
  border-radius: 4px;
  margin-bottom: 8px;
  border: ${(props) => props.active && '1px solid var(--primaryBlue)'};
  background: ${(props) => props.active && 'rgba(57, 161, 220, 0.1)'};

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    background: rgba(57, 161, 220, 0.1);
    border: ${(props) => (props.active ? '1px solid var(--primaryBlue)' : '1px solid rgba(57, 161, 220, 0.2)')};
  }
`;

export const RowItems = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr 5fr 2fr;
`;

export const CurrentVersion = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  justify-content: flex-end;

  svg {
    margin-left: 8px;
  }
`;

export const ConfirmButton = styled.div`
  padding: 6px 12px;
  background: var(--primaryBlue);
  box-shadow: 0px 4px 10px rgba(57, 161, 220, 0.2);
  border-radius: 4px;
  color: white;
  font-weight: bold;
  margin-right: 14px;
  cursor: pointer;
`;

export const AuditModalHeader = styled.div`
  padding: 24px;
  position: relative;

  .closeIcon {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }

  h3 {
    display: flex;
    align-items: center;
    font-weight: 900;
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 0;

    svg {
      margin-right: 10px;
    }
  }
`;
