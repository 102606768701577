import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import ContentEditable from 'react-contenteditable';
import sanitizeHtml from 'sanitize-html';
import { templateToHTMLString } from 'utils/htmlUtils';
import { ContentEditableWrapper, Tag, TagsContainer } from './styles';
import { useClickOutside } from 'utils/hooks';
import { FlexBetweenContainer, Flexer } from 'components/Core';
import { ReactComponent as WarningIcon } from 'images/alert-triangle.svg';
import {
  closeAtMention,
  insertTag,
  onContentEditableChange,
  onContentEditableKeyDown,
  onContentEditablePaste,
  openAtMentionOnAt,
} from './utils';

const WarningWrapper = styled(FlexBetweenContainer)`
  border: 1px solid var(--primaryRed30);
  border-radius: 12px;
  padding: 16px;
  font-style: italic;
  margin-bottom: 12px;
  gap: 4px;
  font-size: 12px;
  align-items: center;
`;

const InvalidTextContainer = styled.div`
  font-weight: 700;
  padding: 1px 6px 1px 4px;
  border-radius: 4px;
  background-color: var(--tertiaryRed);
  color: white;
`;

const StyledWarningIcon = styled(WarningIcon)`
  path {
    fill: var(--tertiaryRed);
  }

  width: 16px;
  height: 16px;
`;

const getInitialHumanizedTags = ({ availableTags }) =>
  availableTags?.map((tag) => ({
    key: tag,
    title: tag
      ?.replace(/{|}/g, '')
      .replace(/_/g, ' ')
      .replace(/^\w/, (c) => c.toUpperCase()),
  }));

export const ContentEditableInput = ({
  HTMLString,
  onChange,
  isChanged,
  id,
  placeholder,
  disabled,
  'data-cy': dataCy,
  availableTags,
  ...props
}) => {
  const [showTags, setShowTags] = useState(false);
  const [tagsPosition, setTagsPosition] = useState();
  const [activeTagIndex, setActiveTagIndex] = useState(0);
  const [enteredMention, setEnteredMention] = useState('');

  const tagsRefs = useRef([]);

  const hasInvalidTags = HTMLString.includes('<b class="invalid">');

  const dropdownRef = useClickOutside(() => closeAtMention({ setEnteredMention, setShowTags, id }));

  const humanizedTags = useMemo(
    () =>
      getInitialHumanizedTags({ availableTags })?.filter((tag) =>
        tag?.title?.toLowerCase()?.includes(enteredMention?.toLowerCase()?.split('@')?.[1]),
      ),
    [enteredMention, availableTags],
  );

  //we use ref to avoid rerenders and get correct value in MemoEditable
  const humanizedTagsRef = useRef(humanizedTags);
  const enteredMentionRef = useRef(enteredMention);

  useEffect(() => {
    humanizedTagsRef.current = humanizedTags;
  }, [humanizedTags]);

  useEffect(() => {
    enteredMentionRef.current = enteredMention;
  }, [enteredMention]);

  useEffect(() => {
    //moving between tags dropdown with arrows
    const handleKeyDown = (event) => {
      if (!showTags) return;

      let newIndex = activeTagIndex;
      if (event.key === 'ArrowDown') {
        newIndex = activeTagIndex + 1 >= humanizedTags?.length ? 0 : activeTagIndex + 1;
        event.preventDefault();
      } else if (event.key === 'ArrowUp') {
        newIndex = activeTagIndex - 1 < 0 ? humanizedTags?.length - 1 : activeTagIndex - 1;
        event.preventDefault();
      }

      if (newIndex !== activeTagIndex) {
        setActiveTagIndex(newIndex);

        // scroll tag into view in container
        setTimeout(() => {
          tagsRefs.current[newIndex]?.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
            inline: 'start',
          });
        }, 0);
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [activeTagIndex, showTags, humanizedTags?.length]);

  //we use memo component to not lose cursor position on rerenders
  const MemoEditable = useMemo(
    () => (
      <ContentEditable
        id={id}
        className="content-editable__input"
        html={HTMLString}
        onChange={(event) => onContentEditableChange({ event, onChange, sanitizeHtml, id, setEnteredMention })}
        placeholder={placeholder}
        style={{ display: 'inline-block', whiteSpace: 'pre-wrap' }}
        disabled={disabled}
        data-cy={dataCy}
        onKeyUp={(event) =>
          openAtMentionOnAt({
            event,
            id,
            enteredMentionRef,
            setEnteredMention,
            setTagsPosition,
            setShowTags,
            setActiveTagIndex,
          })
        }
        onKeyDown={(event) =>
          onContentEditableKeyDown({
            event,
            id,
            setShowTags,
            availableTags,
            templateToHTMLString,
            setEnteredMention,
            humanizedTagsRef,
          })
        }
        onPaste={(event) => onContentEditablePaste({ event, availableTags, templateToHTMLString })}
      />
    ),
    [HTMLString, availableTags, dataCy, disabled, placeholder, onChange, id],
  );

  return (
    <ContentEditableWrapper isChanged={isChanged} disabled={disabled} {...props}>
      {hasInvalidTags && (
        <WarningWrapper>
          <Flexer alignItems="center" gap="4px">
            <span>Replace or Remove</span>
            <InvalidTextContainer>invalid</InvalidTextContainer>
            <span>dynamic fields</span>
          </Flexer>
          <StyledWarningIcon />
        </WarningWrapper>
      )}

      {MemoEditable}

      {showTags && (
        <TagsContainer
          data-cy="content-editable__tags-container"
          ref={dropdownRef}
          style={{ left: tagsPosition?.left, top: tagsPosition?.top + 23 }}
        >
          {humanizedTags.map((tag, index) => (
            <Tag
              key={index}
              selected={index === activeTagIndex}
              data-tagkey={tag?.key}
              data-tagfocused={index === activeTagIndex}
              tabIndex={-1}
              ref={(el) => (tagsRefs.current[index] = el)}
              onMouseDown={(event) => {
                event?.preventDefault();
                event?.stopPropagation();
                insertTag({ tag: tag?.key, setShowTags, availableTags, templateToHTMLString, id });
              }}
            >
              {tag?.title}
            </Tag>
          ))}
        </TagsContainer>
      )}
    </ContentEditableWrapper>
  );
};
