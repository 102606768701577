import styled from 'styled-components';

export const SyledLoadWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 20;
  opacity: 1 !important;

  .loadGif {
    width: 32px;
    height: 32px;
    position: absolute;
    z-index: 100;
    left: 50%;
    top: 40vh;
    transform: translate(-50%, -50%);
  }
`;

export const TimeLoaderContainer = styled.div`
  width: 100%;
  height: 100%;
  min-height: ${(props) => props.minHeight ?? '500px'};
  position: relative;
  pointer-events: ${(props) => props.isLoading && 'none'};

  > * {
    opacity: ${(props) => props.isLoading && !props.isFirstLoad && '0.05'};
  }
`;

export const OpacityWrapper = styled.div`
  width: 100%;
  height: 100%;
  pointer-events: none;
  position: absolute;
  z-index: 20;

  // wrapper for first load
  &:after {
    content: '';
    position: absolute;
    pointer-events: none;
    visibility: ${(props) => (!props.isFirstLoad ? 'hidden' : 'visible')};
    left: 0;
    top: 0;
    z-index: 20;
    height: 100%;
    width: 100%;
    border-radius: 20px;
    border: 1px solid rgba(0, 21, 46, 0.03);
    background-color: var(--primaryGray);
  }
`;

export const LoadBar = styled.div`
  padding: 16px;
  position: absolute;
  z-index: 1000;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 4px 4px 28px rgba(0, 21, 46, 0.05);
  border-radius: 12px;
  background-color: white;
`;

export const BarTitle = styled.div`
  font-weight: 600;
  font-size: 11px;
  line-height: 14px;
  display: flex;
  align-items: center;
`;

export const BarTime = styled.div`
  font-weight: 800;
  margin-left: 4px;
`;

export const ProgressBar = styled.div`
  margin-top: 12px;
  width: 120px;
  background-color: var(--accentGraySecond);
  height: 4px;
  border-radius: 100px;
  overflow: hidden;
  position: relative;
`;

export const Progress = styled.div`
  display: block;
  height: 100%;
  position: absolute;
  left: 0;
  width: 1%;
  background: linear-gradient(180deg, #1eb76e 0%, #099259 100%);
  animation: ${(props) => `fillWidth ${props.loadTime}s linear`};
  animation-fill-mode: forwards;

  @keyframes fillWidth {
    from {
      width: 1%;
    }

    to {
      width: 110%;
    }
  }
`;
