import styled from 'styled-components';

import { Flexer, Centerer, Row } from 'components/Core';
import { EditCircleIcon, CircleXIcon } from 'components/Icons';
import { ReactTableRow, ReactTableCell } from 'components/Table';

export const ExternalUpdatesRow = styled(ReactTableRow)`
  background: ${({ type }) =>
    type === 'resync' ? 'var(--primaryYellow15)' : type === 'delete' ? 'var(--primaryRed10)' : undefined};
  padding: 4px 14px 4px 14px;
`;

export const ExternalUpdatesCell = styled(ReactTableCell)`
  overflow: auto;
  padding-right: ${({ noPaddingRight }) => noPaddingRight && '0'};
`;

export const DisplayValueWrapper = styled.div`
  margin-top: auto;
  margin-bottom: auto;
  cursor: ${({ isLink }) => (isLink ? 'pointer' : undefined)};
  font-weight: ${({ isLink }) => (isLink ? '700' : undefined)};
  font-size: ${({ isLink }) => (isLink ? '12px' : undefined)};
  line-height: ${({ isLink }) => (isLink ? '16px' : undefined)};
  color: ${({ isLink }) => (isLink ? 'var(--primaryBlue)' : undefined)};
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid var(--accentGraySecond);
  border-radius: 12px;
  background-color: 'white';
  margin-bottom: 20px;
`;

export const TableFilters = styled.div`
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  padding: 20px;
  background-color: white;
  border: 1px solid var(--accentGraySecond);
`;

export const Divider = styled.hr`
  border: none;
  height: 1px;
  background-color: var(--lightGray);
`;

export const DropdownTitle = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-left: 12px;
`;

export const DropdownButton = styled.div`
  padding: 8px 12px;
  font-weight: 700;
  font-size: 11px;
  line-height: 14px;
  background: white;
  cursor: pointer;
  border: ${({ isOpened }) => (isOpened ? '1px solid var(--primaryBlack)' : '1px solid var(--accentGraySecond)')};
  box-shadow: ${({ isOpened }) =>
    isOpened ? 'box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.08);' : '4px 4px 24px var(--primaryBlack4)'};
  border-radius: 100px;
  display: flex;
  align-items: center;
  /* small / dark / weak */

  svg {
    width: 8px;
    height: 8px;
    margin-left: 8px;
    transform: ${({ isOpened }) => isOpened && 'rotate(180deg)'};
  }

  &:hover {
    border: 1px solid var(--primaryBlack30);
  }
`;

export const Section = styled(Flexer)`
  flex-direction: column;
  justify-content: center;
  display: flex;
  padding: 12px;
`;

export const LabelText = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: rgba(0, 21, 46, 0.5);
`;

export const MetadataLabelText = styled.div`
  font-size: 11px;
  line-height: 14px;
  color: rgba(0, 21, 46, 0.5);
`;

export const DataWrapper = styled(Centerer)`
  padding: 1px 5px;

  background-color: ${({ deleted, highlight }) =>
    deleted ? 'var(--tertiaryRed)' : highlight ? 'var(--primaryYellow)' : undefined};
  border-radius: ${({ deleted, highlight }) => (deleted || highlight ? '5px' : undefined)};
  color: ${({ deleted }) => (deleted ? '#ffffff' : undefined)};
`;

export const TooltipLabel = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: var(--primaryDarkGray);
`;

export const TooltipValue = styled.div`
  font-size: 12px;
  line-height: 16px;
`;

export const MetadataWrapper = styled.div`
  font-size: 11px;
  line-height: 14px;

  border-radius: 100px;
  border: 1px solid rgba(0, 21, 46, 0.05);

  padding: 1px 5px;
  margin-right: 10px;
  margin-bottom: 5px;

  max-width: 250px;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  justify-content: start;

  background-color: ${({ highlight }) => (highlight ? 'var(--primaryYellow)' : undefined)};
`;

export const MetadataSection = styled(Section)`
  display: flex;

  flex-direction: row;
  flex-wrap: wrap;

  justify-content: start;
  align-items: flex-start;

  background: var(--accentGrayFourth);
  border-bottom: 1px solid var(--primaryBlack5);
`;

export const StyledCircleXIcon = styled(CircleXIcon)`
  cursor: pointer;

  &:hover {
    path {
      fill: var(--secondaryRed);
    }
  }
`;

export const StyledEditCircleIcon = styled(EditCircleIcon)`
  cursor: pointer;

  &:hover {
    path {
      fill: var(--secondaryBlue);
    }
  }
`;

export const Clickable = styled(Centerer)`
  cursor: pointer;
`;

export const TooltipSection = styled(Flexer)`
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
`;

export const SearchContainer = styled.div`
  display: flex;
  padding-left: 12px;
  align-items: center;
  z-index: 1;
`;

export const ExternalUpdatesTable = styled.div`
  div[role='cell'] {
    overflow-x: hidden;
  }
`;

export const ArrowContainer = styled(Centerer)`
  background: ${({ highlight }) => (highlight ? 'var(--primaryYellow)' : undefined)};
  border-radius: 100px;
  padding: 4px;
  cursor: pointer;

  svg {
    transition: transform ease 0.4s;
    transform: ${({ active }) => active && 'rotate(180deg)'};
  }
`;

export const StyledButton = styled(Centerer)`
  padding: 8px 12px;
  font-weight: 700;
  font-size: 11px;
  line-height: 14px;
  border-radius: 100px;
  cursor: pointer;
  user-select: none;
  pointer-events: ${({ disabled }) => disabled && 'none'};
  opacity: ${({ disabled }) => disabled && '0.3'};

  color: ${({ textColor }) => textColor};
  background: ${({ fillColor }) => fillColor};
  border: ${({ borderColor }) => (borderColor ? `1px solid ${borderColor}` : `1px solid transparent`)};
`;

export const CircleText = styled.div`
  align-items: center;
  background: ${(props) => (props.variant === 'red' ? 'var(--primaryRed)' : 'var(--primaryBlue)')};
  border-radius: 100px;
  color: #fff;
  display: flex;
  flex-direction: row;
  font-size: 8px;
  font-style: normal;
  font-weight: 800;
  height: 14px;
  justify-content: center;
  line-height: 10px;
  margin-right: 4px;
  padding: 4px;
  text-align: center;
  text-transform: uppercase;
  width: 15px;
`;

export const UpdateDetailsRow = styled(Row)`
  border-radius: 100px;
  overflow-x: ${(props) => (props.isOverflowAuto ? 'auto' : 'initial')};
  border: 1px solid var(--accentGraySecond);
  position: relative;
  padding: 12px;
`;

export const InfoIconWrapper = styled(Centerer)`
  padding: 3px;
  background: var(--accentGraySecond);
  border: 1px solid var(--accentGraySecond);
  border-radius: 8px;
  margin-right: 3px;
`;
