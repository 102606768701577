import styled, { css } from 'styled-components';
import { Centerer, Row } from 'components/Core';
import { InlineButton } from 'components/Buttons';

export const PercentDice = styled.div`
  margin-left: 6px;
  padding: 2px 4px;
  border-radius: 4px;
  font-weight: 900;
  font-size: 10px;
  background-color: ${(props) => (props.isPositive ? 'rgba(30, 183, 110, 0.1)' : 'rgba(255, 89, 89, 0.1)')};
  line-height: 14px;
  color: ${(props) => (props.isPositive ? 'var(--primaryGreen)' : 'var(--primaryRed)')};
`;

export const HeaderActions = styled.div`
  display: flex;

  & > * {
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }
  }
`;

export const PercentageDice = styled.div`
  padding: 1px 3px;
  font-size: 12px;
  line-height: 16px;
  background: rgba(0, 133, 255, 0.1);
  border-radius: 4px;
  margin-left: auto;
`;

export const TotalSum = styled.div`
  margin-right: 127px;
`;

export const TotalRecRev = styled.div`
  margin-right: 180px;
`;

export const CustomerViewContentContainer = styled.div`
  min-height: 700px;
  width: 100%;
  position: relative;
`;

export const TitleLegend = styled.div`
  display: flex;
  margin-top: 6px;
`;

export const LegendItem = styled.div`
  display: flex;
  align-items: center;
  font-weight: normal;
  margin-right: 16px;
  font-size: 12px;
  line-height: 16px;
`;

export const StyledInlineButton = styled(InlineButton)`
  font-weight: 900;
  font-size: 10px;
  line-height: 14px;
  text-transform: uppercase;
  white-space: nowrap;
  display: flex;
  align-items: center;

  svg {
    margin-left: 4px;
  }
`;

export const TotalRow = styled.div`
  width: 100%;
  display: flex;
  padding: 4px 28px;
  padding-top: 28px;
  justify-content: space-between;
  font-weight: 900;
  font-size: 10px;
  line-height: 12px;
  opacity: 0.5;
  text-transform: uppercase;
  margin-top: -10px;

  div {
    display: flex;
  }
`;

export const DatesCell = styled.div`
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: ${(props) => (props.isDatePast ? 'var(--secondaryYellow)' : 'var(--primaryBlack)')};

  span {
    color: var(--primaryBlack);
    opacity: 0.3;
  }

  svg {
    margin-left: 4px;
  }
`;

export const HeaderCellAlignRight = styled.div`
  width: 100%;
  text-align: right;
`;

export const HeaderCounter = styled.div`
  padding: 10px 14px;
  font-weight: 900;
  font-size: 16px;
  line-height: 20px;
  background: var(--accentGray);
  border-radius: 100px;
`;

export const RelationLabel = styled.div`
  font-size: 12px;
  line-height: 16px;
  font-weight: bold;
  color: var(--primaryBlack30);
  padding: 2px 4px;
  border-radius: 4px;
  margin-right: 8px;
  border: 1px solid var(--primaryBlack10);
`;

export const ArrCell = styled.div`
  font-weight: bold;
  width: 100%;
  justify-content: flex-end;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  span {
    opacity: 0.5;
  }

  svg {
    margin: 0 2px;
    opacity: 0.5;
  }
`;

export const LoaderContainer = styled(Centerer)`
  width: 100%;
`;

export const ExternalLink = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  div {
    text-align: center;
    font-weight: bold;
    font-style: italic;
    font-size: 14px;
    line-height: 20px;
    color: var(--primaryBlue);
  }
`;

export const IconWrapper = styled.div`
  flex-shrink: 0;
`;

export const InformationText = styled.div`
  color: rgba(0, 21, 46, 0.3);
  font-style: italic;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
`;

export const GreyBoxActionButton = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  cursor: pointer;

  padding: 5px;
  border-radius: 20px;

  transition: background-color 0.2s ease-in-out;

  &:hover {
    svg {
      background-color: var(--primaryBlue);
      box-shadow: 0px 4px 10px var(--primaryBlue20);

      path {
        fill: var(--lightGray);
      }
    }
  }

  svg {
    width: 30px;
    height: 30px;
    padding: 5px;
    background-color: var(--accentBlue);
    transition: background-color 0.2s ease-in-out;
    border-radius: 20px;
    transition: box-shadow 0.2s ease-in-out;

    path {
      fill: var(--primaryBlue);
      transition: fill 0.2s ease-in-out;
    }
  }

  ${({ isText }) =>
    isText &&
    css`
      border-radius: 4px;
      padding: 4px 8px;
      color: var(--primaryBlue);
      background-color: var(--primaryBlue20);
      font-size: 12px;

      &:hover {
        background-color: var(--primaryBlue);
        color: var(--lightGray);
      }
    `}
`;

export const GreyBoxTitle = styled.div`
  font-size: 14px;
  line-height: 20px;
  font-weight: 900;
  padding-left: 12px;
  padding-right: 12px;
  margin-top: 12px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  ${Row} {
    width: 100%;
  }

  span {
    margin-left: 12px;
  }
`;

export const GreyBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: 20px;
  position: relative;
  flex-grow: 2;
  background: var(--accentGrayFourth);
  border-radius: 16px;
  border: 1px solid var(--accentGraySecond);
  width: 320px;
`;

export const GreyBoxContent = styled.div`
  padding: 20px;
  max-height: 400px;
  overflow: auto;
  width: ${({ width }) => width};
`;
