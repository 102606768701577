import { useAuth0 } from '@auth0/auth0-react';
import { AppContext } from 'AppContext';
import { useContext, useEffect } from 'react';
import { bypassError } from 'utils/errorUtils';

export const useInvariantCheck = ({ readyData, checkers, checkerContext = {}, deps = [] }) => {
  const { organizations } = useContext(AppContext);
  const { user } = useAuth0();

  useEffect(() => {
    if (readyData) {
      setTimeout(() => {
        for (const checker of checkers) {
          bypassError(() => {
            checker({ data: readyData, organization: organizations?.[0], user, ...checkerContext });
          });
        }
      }, 0);
    }
    // eslint-disable-next-line
  }, [readyData, organizations, ...deps]);
};
