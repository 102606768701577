import styled from 'styled-components';

import { ReactComponent as Icon } from 'images/circled-arrow-down-v2.svg';

export const CircledArrowDownIcon = styled(Icon)`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  cursor: ${(props) => props.cursor};

  path {
    fill: ${(props) => props.color};
    transition: fill 0.2s ease-in-out;
  }
`;
