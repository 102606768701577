import React, { useContext, useMemo } from 'react';
import dayjs from 'dayjs';

import { AppContext } from 'AppContext';
import { ReactComponent as ExternalLink } from 'images/external-link-full.svg';
import { ReactComponent as Arrow } from 'images/bx-right-arrow-alt.svg';
import { REVENUE_METRIC } from 'consts/revenueMetrics';
import { NUMBER_FORMATS } from 'consts/global';
import { numberFormatter } from 'utils/formatters';
import { Centerer } from 'components/Core';
import { CircleLoader } from 'components/Loaders';
import { TruncateStringWithTooltip } from 'components/Tooltip';
import {
  Circle,
  DividerWrapper,
  HistoricalRowWrapper,
  HistoricalSegmentCardBody,
  HistoricalSegmentCardTitle,
  HistoricalTotalRow,
  HistoricalWrapper,
  Line,
  StyledHistoricalSegmentCard,
} from 'shared/Historical';
import { TransactionContext } from 'shared/TransactionContent/TransactionContext';
import { ReplaceTransaction } from '../ReplaceTransaction';
import {
  CurrentTransaction,
  CustomerPill,
  EmptyState,
  EmptyStateCircle,
  EmptyStateDescription,
  EmptyStateTitle,
  RelatedTransaction,
  RevenueWrapper,
  TransactionsWrapper,
} from './styles';
import { getHistoryData, METRIC_KEYS } from './utils';

const HistoricalSegmentCard = ({ currentTransactionId, metric, segment, currency }) => {
  return (
    <StyledHistoricalSegmentCard>
      <HistoricalSegmentCardTitle red={[METRIC_KEYS.CHURN, METRIC_KEYS.DOWNSELL, METRIC_KEYS.INFLUX].includes(metric)}>
        <Centerer>
          <img src={REVENUE_METRIC[metric].icon} alt="" />
          {REVENUE_METRIC[metric].label}
        </Centerer>
        <span>{numberFormatter({ type: NUMBER_FORMATS.CURRENCY, rawValue: segment.total, currency })}</span>
      </HistoricalSegmentCardTitle>
      <HistoricalSegmentCardBody>
        {segment.transactions.map((transaction) => (
          <TransactionsWrapper key={transaction.id}>
            {currentTransactionId === transaction.id ? (
              <CurrentTransaction>This transaction</CurrentTransaction>
            ) : (
              <RelatedTransaction to={`/transactions/${transaction.id}`} target="_blank" noBorder>
                <TruncateStringWithTooltip length={20} tooltipWidth={300}>
                  {transaction.name}
                </TruncateStringWithTooltip>
              </RelatedTransaction>
            )}
            {transaction.amount > 0 && (
              <div>{numberFormatter({ type: NUMBER_FORMATS.CURRENCY, rawValue: transaction.amount, currency })}</div>
            )}
          </TransactionsWrapper>
        ))}
      </HistoricalSegmentCardBody>
    </StyledHistoricalSegmentCard>
  );
};

const HistoricalMonthContainer = ({ currentTransactionId, isARR, monthData, currency }) => {
  return (
    <HistoricalRowWrapper>
      <DividerWrapper>
        <Line />
        <Circle />
      </DividerWrapper>

      <HistoricalTotalRow>
        {dayjs.utc(monthData.month).format('MMM YYYY')}
        {monthData.Total > 0 && (
          <>
            ,{' '}
            <span>
              {numberFormatter({ type: NUMBER_FORMATS.CURRENCY, rawValue: monthData.Total, currency })}{' '}
              {isARR ? 'ARR' : 'MRR'}
            </span>
          </>
        )}
      </HistoricalTotalRow>

      {Object.entries(monthData.segmented).map(([key, segment]) => (
        <HistoricalSegmentCard
          key={`${monthData.month}-${key}`}
          currentTransactionId={currentTransactionId}
          metric={key}
          segment={segment}
          currency={currency}
        />
      ))}
    </HistoricalRowWrapper>
  );
};

export const RevenueHistory = () => {
  const {
    appSettings: { isARR, currencyISOCode: currency },
  } = useContext(AppContext);
  const { customer, customerLoading, transactionFormValues } = useContext(TransactionContext);

  const historicalData = useMemo(() => {
    if (customer) {
      return getHistoryData({ isARR, customer });
    }
  }, [customer, isARR]);

  if (customerLoading) {
    return (
      <EmptyState>
        <CircleLoader />
      </EmptyState>
    );
  }

  if (!customer) {
    return (
      <EmptyState>
        <EmptyStateCircle>
          <Arrow />
        </EmptyStateCircle>
        <EmptyStateTitle>Select a customer</EmptyStateTitle>
        <EmptyStateDescription>We can't build a revenue history until you select a customer.</EmptyStateDescription>
      </EmptyState>
    );
  }

  return (
    <div>
      <ReplaceTransaction />

      <RevenueWrapper>
        <span>Current Customer’s {isARR ? 'ARR' : 'MRR'}</span>
        <div>
          {numberFormatter({
            type: NUMBER_FORMATS.CURRENCY,
            rawValue: customer.currentMRR * (isARR ? 12 : 1),
            currency,
          })}
        </div>
      </RevenueWrapper>

      <CustomerPill to={`/customers/${customer.id}`} target="_blank">
        <div>{customer.name}</div>
        <ExternalLink />
      </CustomerPill>

      <HistoricalWrapper>
        {historicalData?.map((monthData) => (
          <HistoricalMonthContainer
            key={monthData.month}
            currentTransactionId={transactionFormValues?.id}
            isARR={isARR}
            monthData={monthData}
            currency={currency}
          />
        ))}
      </HistoricalWrapper>
    </div>
  );
};
