import { useContext } from 'react';
import styled from 'styled-components';
import { flatten } from 'lodash';
import { Banner, BannerText } from 'components/Blocks/Banner';
import { TooltipContainer } from 'components/Tooltip';
import { TransactionContext } from '../TransactionContext';

const ReasonsList = styled.ul`
  margin: 0;
  padding: 0;
`;

const Reason = styled.li`
  list-style: inside;
`;

export const TransactionFlaggingReasons = () => {
  const { flaggingReasons } = useContext(TransactionContext);
  const flaggingMessages = flatten(Object.values(flaggingReasons ?? {}));

  return (
    flaggingMessages.length > 0 && (
      <TooltipContainer toolTipContent="This warning will go away after the transaction is saved and confirmed">
        <Banner data-cy="transaction-flagging-reasons" marginTop="20px" tag="warning">
          <BannerText>
            <div style={{ marginBottom: 8 }}>
              <b>Please check this transaction again.</b> Subscript found some oddities:
            </div>
            <ReasonsList>
              {flaggingMessages.map((reason, index) => (
                <Reason key={index} dangerouslySetInnerHTML={{ __html: reason }}></Reason>
              ))}
            </ReasonsList>
            <div>Is that expected ?</div>
          </BannerText>
        </Banner>
      </TooltipContainer>
    )
  );
};
