import { useContext } from 'react';
import { AppContext } from 'AppContext';
import { useAccountingRevenueForContractAPI } from 'api/accountingRevenue/hooks';
import { Loader } from 'components/Loaders';
import { Centerer } from 'components/Core';
import { CustomerAccountingTable } from 'views/Customers/CustomerSingle/CustomerAccountingTable';

export const ContractAccountingTables = ({ contractId }) => {
  const { orgId } = useContext(AppContext);

  const { data, isLoading } = useAccountingRevenueForContractAPI({
    orgId,
    contractId,
  });

  if (isLoading)
    return (
      <Centerer width="100%" marginTop="32px">
        <Loader containerStyles={{ width: 40 }} />
      </Centerer>
    );

  return <CustomerAccountingTable customerAccountingData={data} />;
};
