import { FlexBetweenContainer, FlexerColumn } from 'components/Core';
import { FILE_TYPES, FileUploader, FormikCustomInput } from 'components/Controls';

import { FormRow } from '../styles';

export const BillingCompanyInformation = ({ values, setFieldValue }) => {
  return (
    <FlexerColumn gap="24px">
      <FlexBetweenContainer gap="8px">
        <FormikCustomInput name="entity_name" label="Entity Name" width="100%" data-cy="entity-name--input" />
        <FormikCustomInput name="company_name" label="Company Name" width="100%" data-cy="entity-company-name--input" />
      </FlexBetweenContainer>

      <FileUploader
        initialUploadedFile={{ s3FileKey: values?.logo_s3_key }}
        onFileUpload={(value) => setFieldValue('logo_s3_key', value?.s3FileKey ?? null)}
        fileTypes={FILE_TYPES.IMAGES}
        maxFileSize={5242880}
        label="Company Logo"
      />

      <FormRow childrenWidth="100%">
        <FormikCustomInput data-cy="entity-address--input" name="address" label="Company Address" textarea rows="3" />
      </FormRow>
    </FlexerColumn>
  );
};
