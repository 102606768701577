import { ReactComponent as TooltipIcon } from 'images/help-table-header.svg';
import { TooltipContainer } from 'components/Tooltip';
import { LightbulbIcon, ThumbsUpIcon } from 'components/Icons';
import dayjs from 'dayjs';
import { CHART_COLORS, INSIGHT_MIN_POPULATION_SIZE } from '../consts';
import { pickBarColour } from '../utils';
import {
  StyledInsightCard,
  InsightOneLiner,
  InsightMiniCharts,
  InsightConfidenceDetail,
  YellowItalic,
  BoldItalic,
  MiniChartWrapper,
  MiniChartDescriptor,
  InsightCaption,
  ChartAndConfidenceContainer,
  SmallLightText,
} from './styles';

export const InsightCard = ({ insight, cohortData }) => {
  const retentions = {
    'Cohort Total': cohortData?.totalAndAverageRows?.totalRow?.monthData?.[12],
  };
  Object.keys(cohortData?.totalAndAverageSegmentRows ?? {}).forEach((segment) => {
    if (cohortData?.totalAndAverageSegmentRows?.[segment]?.startingCustomerCount < INSIGHT_MIN_POPULATION_SIZE) return;
    retentions[segment] = cohortData?.totalAndAverageSegmentRows?.[segment]?.totalRow?.monthData[12];
  });

  const highestRetention = Math.max(...Object.values(retentions));
  const startDate = dayjs(insight?.customization?.startMonth).format('MMM YYYY');
  const endDate = dayjs(insight?.customization?.endMonth).format('MMM YYYY');

  const MiniBarChart = ({ highestRetention, segmentRetention, segment, i }) => {
    const barHeight = 50;
    const segmentYStart = highestRetention ? barHeight - (segmentRetention / highestRetention) * barHeight : 0;
    const segmentHeight = `${(highestRetention ? segmentRetention / highestRetention : 0) * 100}%`;

    const fill =
      segment === 'Cohort Total'
        ? CHART_COLORS.default
        : segment === insight?.segment_name
        ? CHART_COLORS.insight
        : pickBarColour(i + 1);
    return (
      <MiniChartWrapper>
        <svg width="14" height={barHeight} xmlns="http://www.w3.org/2000/svg">
          <rect x="0" y="0" width="100%" height="100%" rx="4" fill="var(--primaryBlack5)" />
          <rect
            x="0"
            y={segmentYStart}
            width="100%"
            height={segmentHeight}
            rx="4"
            fill={fill}
            stroke={segment === insight?.segment_name ? 'white' : 'none'}
          />
        </svg>
        <MiniChartDescriptor>
          <div>{segment}</div>
          <div>
            <b>{Math.round(segmentRetention * 100)}%</b>
          </div>
        </MiniChartDescriptor>
      </MiniChartWrapper>
    );
  };
  return (
    <>
      <StyledInsightCard>
        <InsightOneLiner>
          <LightbulbIcon size="24px" fill="var(--secondaryYellow)" margin="0 4px 0 4px" />
          <div>
            <YellowItalic>{insight?.segment_name}</YellowItalic> was {insight?.direction}ly correlated with{' '}
            <BoldItalic>revenue retention</BoldItalic> at Month 13 for {startDate} - {endDate}
          </div>
        </InsightOneLiner>
        <ChartAndConfidenceContainer centerer gap="20px">
          <InsightMiniCharts>
            {Object.keys(retentions).map((segment, i) => (
              <MiniBarChart
                key={`mini-bar-chart-${i}`}
                highestRetention={highestRetention}
                segmentRetention={retentions[segment]}
                segment={segment}
                i={i}
              />
            ))}
          </InsightMiniCharts>
          <InsightConfidenceDetail>
            <ThumbsUpIcon fill="var(--secondaryYellow)" margin="0 10px 0 0" />
            <div>
              <b>Strong confidence in insight</b>
              <SmallLightText>
                {insight?.p_value < 0.01 ? 'p<0.01' : `p=${insight?.p_value.toFixed(2)}`}
                <TooltipContainer
                  width={300}
                  isVisible={true}
                  underline={false}
                  toolTipContent={`All segments with ${INSIGHT_MIN_POPULATION_SIZE} or more customers were compared. A p-value of less than 0.05 indicates a statistically significant difference between the retention of this segment against all others.`}
                >
                  <TooltipIcon />
                </TooltipContainer>
              </SmallLightText>
            </div>
          </InsightConfidenceDetail>
        </ChartAndConfidenceContainer>
        <InsightCaption>{`Overall Revenue Retention in Month 13 for ${startDate} - ${endDate}`}</InsightCaption>
      </StyledInsightCard>
    </>
  );
};
