import styled from 'styled-components';
import { Centerer } from 'components/Core';
import { Link } from 'react-router-dom';

export const FlexerColumnGrower = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const RecognitionHeader = styled.div`
  display: flex;
  padding: 10px 20px;
  background-color: var(--accentGrayFourth);
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
`;

export const RecognitionBody = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 5px 20px 0;
  position: relative;

  background: #ffffff;

  border: 1px solid var(--accentGraySecond);
  box-sizing: border-box;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
`;

export const RecognitionTable = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
`;

export const ItemNumber = styled.div`
  font-weight: 900;
  font-size: 9px;
  line-height: 12px;
  padding: 2px 5px;
  text-transform: uppercase;
  background: var(--primaryBlack5);
  border-radius: 4px;
  margin-right: 8px;
`;

export const TillCanceledInfo = styled.div`
  font-size: 12px;
  line-height: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 241px;
  margin: 20px auto;
  text-align: center;

  p {
    opacity: 0.5;
    margin-bottom: 0;
  }
`;

export const ReplacedByInfoLink = styled(Link)`
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: var(--primaryBlue);
  padding: 16px 12px;
  cursor: pointer;
  border-top: 1px solid var(--accentGraySecond);

  svg {
    margin-left: 8px;
    height: 8px;
    width: 8px;

    path {
      fill: var(--primaryBlue);
    }
  }

  &:hover {
    color: var(--secondaryBlue);

    svg path {
      fill: var(--secondaryBlue);
    }
  }
`;

export const ModalItem = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 16px 12px;
  border-bottom: 1px solid var(--primaryBlack5);
  opacity: ${({ replacedMonth }) => replacedMonth && '0.3'};

  &:last-child {
    border-bottom: none;
  }

  &:after {
    position: absolute;
    content: '';
    visibility: ${({ replacedMonth }) => (replacedMonth ? 'visible' : 'hidden')};
    height: 1px;
    width: 100%;
    background-color: var(--accentDarkGray);
    transform: translateY(-50%);
    opacity: 0.3;
    top: 50%;
    left: 0;
  }
`;

export const IncludeLastMonthItem = styled(ModalItem)`
  color: ${({ includeEndMonth }) => (includeEndMonth ? 'var(--primaryRed)' : 'var(--primaryBlue)')};
  cursor: pointer;
  font-size: 12px;
  line-height: 16px;
  opacity: ${(props) => props.disabled && '0.7'};
  pointer-events: ${(props) => props.disabled && 'none'};

  &:hover {
    color: ${({ includeEndMonth }) => (includeEndMonth ? 'var(--secondaryRed)' : 'var(--secondaryBlue)')};

    svg {
      path {
        fill: ${({ includeEndMonth }) => (includeEndMonth ? 'var(--secondaryRed)' : 'var(--secondaryBlue)')};
      }

      rect {
        fill-opacity: 0.2;
      }
    }
  }

  svg {
    margin-right: 8px;
  }
`;

export const HeaderItem = styled.div`
  width: 20%;
  font-weight: 900;
  font-size: 10px;
  line-height: 14px;
  opacity: 0.3;
  text-transform: uppercase;
`;

export const RowItem = styled.div`
  width: 20%;
  display: flex;
  align-items: center;
  opacity: ${(props) => props.isOpacityRow && 0.2};
`;

export const NoRecognition = styled(Centerer)`
  flex-direction: column;
  flex: 1;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  text-align: center;

  svg {
    margin-bottom: 10px;
  }

  div {
    width: 420px;
  }
`;

export const RegularRow = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  font-size: 12px;
  line-height: 16px;

  span {
    font-weight: bold;
  }
`;
