import styled from 'styled-components';
import { ReactComponent as Icon } from 'images/light-stroke-thumbs-up.svg';

export const LightStrokeThumbsUpIcon = styled(Icon)`
  width: ${({ size }) => size ?? '20px'};
  height: ${({ size }) => size ?? '20px'};
  margin: ${({ margin }) => margin ?? '4px'};

  stroke: ${({ stroke }) => stroke};
  path {
    fill: ${({ fill }) => fill};
  }
`;
