import { useContext, useEffect, useState, createContext } from 'react';
import { PAGE_CONTEXT_VALUES, usePagination, useStateWithStorage, usePageContext } from 'utils/hooks';
import { AppContext } from 'AppContext';
import { DEFAULT_PAGE_SIZE } from './CustomersList/consts';

export const CustomersContext = createContext();

export const CustomersContextProvider = ({ children }) => {
  const [customersListSearchQuery, setCustomersListSearchQuery] = useState();
  const [sortBy, setSortBy] = useStateWithStorage('customers-table-sort-by', []);
  const {
    dataFilter: { customerTypes },
  } = useContext(AppContext);

  const { urlState } = usePageContext({ page: PAGE_CONTEXT_VALUES.customers_list.key });

  const {
    pageSize: customersListPageSize,
    setPageSize: setCustomersListPageSize,
    currentPageIndex: customersListPageIndex,
    setCurrentPageIndex: setCustomersListPageIndex,
  } = usePagination({
    storageSuffix: 'customers-list-page',
    defaultPageSize: DEFAULT_PAGE_SIZE,
  });

  useEffect(() => {
    setCustomersListPageIndex(1);
  }, [customerTypes, setCustomersListPageIndex]);

  return (
    <CustomersContext.Provider
      value={{
        customersListSearchQuery,
        setCustomersListSearchQuery,
        customersListPageIndex,
        setCustomersListPageIndex,
        customersListPageSize,
        setCustomersListPageSize,
        sortBy,
        setSortBy,
        ...urlState,
      }}
    >
      {children}
    </CustomersContext.Provider>
  );
};
