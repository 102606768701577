import React, { Fragment, useEffect } from 'react';
import styled from 'styled-components';
import { EVENTS } from 'consts/analytics';
import { useAnalytics } from 'utils/hooks';
import { Header } from 'shared/Layout';
import { HeaderTitle } from 'shared/Layout/Header/style';
import { BillingInconsistenciesReportTable } from './BillingInconsistenciesReportTable';

const Wrapper = styled.div`
  padding: 0 40px 0px 40px;
  border-bottom: 1px solid var(--accentGraySecond);
`;

export const BillingInconsistenciesReportPage = () => {
  const { trackEvent } = useAnalytics();

  useEffect(() => {
    trackEvent({ name: EVENTS.VISIT_BILLING_INCONSISTENCIES_REPORT });
  }, [trackEvent]);

  return (
    <Fragment>
      <Wrapper>
        <Header headerLeft={<HeaderTitle>Billing Inconsistencies Report</HeaderTitle>} />
      </Wrapper>
      <BillingInconsistenciesReportTable />
    </Fragment>
  );
};
