import React, { useCallback, useState } from 'react';
import { FlexerColumn } from 'components/Core';
import {
  AdditionalModalBody,
  BodyDescription,
  BodyHeader,
  DeleteConfirmationModal,
} from 'shared/DeleteConfirmationModal';
import { TrashIcon } from 'components/Icons';

const useDeleteModal = ({ onDelete }) => {
  const [modalOpen, setModalOpen] = useState(null);

  const openModal = ({ glField, glFieldLabel, subscriptFieldLabel }) => {
    setModalOpen({ glField, glFieldLabel, subscriptFieldLabel });
  };

  const closeModal = () => {
    setModalOpen(null);
  };

  const Modal = useCallback(
    () =>
      modalOpen ? (
        <DeleteConfirmationModal
          modalName="invoice-tracking-categories--delete-confirmation-modal"
          modalTitle="Remove tracking category field"
          modalDescription="Do you really want to remove the next item? This cannot be un-done."
          additionalModalBody={
            <AdditionalModalBody>
              <TrashIcon />
              <FlexerColumn gap="8px">
                <BodyHeader>{modalOpen?.subscriptFieldLabel}</BodyHeader>
                <BodyDescription>{modalOpen?.glFieldLabel}</BodyDescription>
              </FlexerColumn>
            </AdditionalModalBody>
          }
          onClose={closeModal}
          onDeleteConfirm={() => {
            onDelete({ glField: modalOpen.glField });
            closeModal();
          }}
        />
      ) : null,
    [modalOpen, onDelete],
  );

  return {
    isModalOpen: !!modalOpen,
    openModal,
    Modal,
  };
};

export default useDeleteModal;
