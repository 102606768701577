import React, { useContext } from 'react';
import styled from 'styled-components';
import { AppContext } from 'AppContext';
import { ADDITIONAL_ORG_CONFIGS } from 'consts/global';
import { groupBy } from 'utils/arrayUtils';
import { Flexer, FlexerColumn, FlexerRow } from 'components/Core';
import { FormikCustomSelector } from 'components/Controls';
import { InvoicingScheduleContext } from 'views/Billing/InvoicingScheduleModal/InvoicingScheduleContext';

const TrackingCategoriesSectionWrapper = styled(FlexerRow)`
  gap: 8px;
  padding-left: 36px;
  padding-right: 50px;
`;

const TrackingCategorySelector = ({ index, setFieldValue, trackingCategoryId, trackingCategoriesById }) => {
  const trackingCategory = trackingCategoriesById[trackingCategoryId];
  const trackingCategoriesOptions = (trackingCategory?.options ?? []).map((tc) => ({
    label: tc.name,
    value: tc.id,
  }));

  const handleChange = (option) => {
    setFieldValue(`invoice_items[${index}].metadata.tracking_categories.${trackingCategory.id}`, option.value);
  };

  return (
    <FlexerColumn gap="8px" width="100%">
      <Flexer gap="12px" alignItems="center">
        <div>{trackingCategory?.name}:</div>
        <FormikCustomSelector
          placeholder="Select option"
          name={`invoice_items[${index}].metadata.tracking_categories.${trackingCategory.id}`}
          options={trackingCategoriesOptions}
          containerWidth="100%"
          handleChange={handleChange}
          greyVer
        />
      </Flexer>
    </FlexerColumn>
  );
};

const TrackingCategoryList = ({ index, setFieldValue, trackingCategoriesConfig }) => {
  const { glIntegration } = useContext(InvoicingScheduleContext);

  const integrationTrackingCategories = glIntegration.metadata?.tracking_categories ?? [];
  const trackingCategoriesById = groupBy(integrationTrackingCategories, 'id', { uniqueness: true });

  return (
    <TrackingCategoriesSectionWrapper>
      {Object.keys(trackingCategoriesConfig ?? {}).map((trackingCategoryId) => (
        <TrackingCategorySelector
          key={trackingCategoryId}
          index={index}
          setFieldValue={setFieldValue}
          trackingCategoryId={trackingCategoryId}
          trackingCategoriesById={trackingCategoriesById}
        />
      ))}
    </TrackingCategoriesSectionWrapper>
  );
};

export const TrackingCategoriesSection = ({ index, setFieldValue }) => {
  const { orgConfigs } = useContext(AppContext);
  const { glIntegration } = useContext(InvoicingScheduleContext);
  const integrationTrackingCategories = glIntegration?.metadata?.tracking_categories ?? [];
  const trackingCategoriesConfig =
    orgConfigs?.[ADDITIONAL_ORG_CONFIGS.BILLING_TRACKING_CATEGORIES_BY_INTEGRATION_ID]?.[glIntegration?.id] ?? {};

  const isTrackingCategoriesEnabled =
    !!glIntegration && integrationTrackingCategories.length > 0 && Object.keys(trackingCategoriesConfig).length > 0;

  return isTrackingCategoriesEnabled ? (
    <TrackingCategoryList
      index={index}
      setFieldValue={setFieldValue}
      trackingCategoriesConfig={trackingCategoriesConfig}
    />
  ) : null;
};
