import { useContext } from 'react';
import dayjs from 'dayjs';
import styled from 'styled-components';

import { AppContext } from 'AppContext';
import { ReactComponent as ArrowRight } from 'images/arrows-bold-right-blue.svg';
import { ReactComponent as SumIcon } from 'images/sum.svg';
import { ReactComponent as UsersIcon } from 'images/users.svg';
import { ReactComponent as ExistingUsersIcon } from 'images/report-money.svg';
import { NUMBER_FORMATS } from 'consts/global';
import { useCurrencyNumberFormatter } from 'utils/hooks';
import { Centerer, CentererVertical, Flexer, FlexerColumn, FlexerRow, Spacer } from 'components/Core';
import { ExplainerTooltip } from 'components/Tooltip';
import { EXISTING_CUSTOMERS_FORECAST_TYPE } from '../consts';
import { useHowItWorksModal } from '../HowItWorksModal';
import { FORECAST_DETAILS_TYPE } from './consts';

const Circle = styled(Centerer)`
  width: 44px;
  height: 44px;

  border-radius: 100px;
  margin-right: 16px;
`;

const TabsCircle = styled(Circle)`
  background: var(--primaryBlack2);

  svg {
    width: 24px;
    height: 24px;

    path {
      fill: var(--primaryBlack);
    }
  }
`;

const DetailsTypeSelectorWrapper = styled.div`
  background: var(--primaryGray);
`;

const DetailsTypeSelectorContainer = styled(FlexerRow)`
  align-items: center;
  margin: 0 40px;
  padding: 8px 20px;

  border: 1px solid var(--accentGraySecond);
  box-shadow: 8px 8px 40px var(--primaryBlack2);
  border-radius: 100px;
`;

const DetailsTypeSelectorTitle = styled.div`
  font-weight: 900;
  font-size: 14px;
  line-height: 20px;
`;

const DetailsTypeSelectorSubTitle = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;

  margin-top: 4px;
`;

const DetailsTypeSelector = styled(Flexer)`
  flex: 1;

  span {
    flex: 1;
  }
`;

const DetailsTypeSelectorOption = styled(CentererVertical)`
  flex: 1;
  cursor: pointer;
  background: ${({ isSelected }) => (isSelected ? 'var(--primaryBlue10)' : '#FFF')};
  color: ${({ isSelected }) => (isSelected ? 'var(--primaryBlue)' : '#000')};

  margin-left: 8px;
  padding: 12px;
  border: 1px solid var(--accentGraySecond);
  box-shadow: 4px 4px 20px var(--primaryBlack3);
  border-radius: 100px;

  ${TabsCircle} {
    background: ${({ isSelected }) => (isSelected ? 'var(--primaryBlue)' : 'var(--primaryBlack2)')};

    svg {
      path {
        fill: ${({ isSelected }) => (isSelected ? '#FFF' : 'var(--primaryBlack)')};
      }
    }
  }

  &:hover {
    ${TabsCircle} {
      background: ${({ isSelected }) => (isSelected ? 'var(--primaryBlue)' : 'var(--primaryBlue10)')};
    }

    color: var(--primaryBlue);
  }
`;

const DetailsTypeSelectorExplainerButton = styled(Circle)`
  margin-left: 16px;
  border: 1px solid var(--primaryBlue10);
`;

const DetailsTypeSelectorExplainer = styled(Flexer)`
  cursor: pointer;
  padding: 22px;

  ${DetailsTypeSelectorSubTitle} {
    color: var(--primaryBlue);
  }

  &:hover {
    color: var(--primaryBlue);

    ${DetailsTypeSelectorExplainerButton} {
      background: var(--primaryBlue10);
    }
  }
`;

export const ForecastDetailsTypeSelector = ({ data, selectedMonth, selectedDetailsType, setSelectedDetailsType }) => {
  const {
    appSettings: { isARR },
  } = useContext(AppContext);
  const { Modal: HowItWorksModal, openModal: openHowItWorksModal } = useHowItWorksModal();
  const numberFormatter = useCurrencyNumberFormatter();

  const newMonthData = data?.customers_new ?? {};
  const existingMonthData = data?.customers_existing ?? {};
  const existingForecastType = existingMonthData.forecast_type ?? EXISTING_CUSTOMERS_FORECAST_TYPE.NRR;

  return (
    <DetailsTypeSelectorWrapper>
      <DetailsTypeSelectorContainer>
        <TabsCircle>
          <SumIcon />
        </TabsCircle>
        <FlexerColumn>
          <DetailsTypeSelectorTitle>
            Total:{' '}
            {existingMonthData?.total &&
              newMonthData?.total &&
              numberFormatter({
                type: NUMBER_FORMATS.CURRENCY,
                rawValue: existingMonthData?.total + newMonthData?.total,
              })}
          </DetailsTypeSelectorTitle>
          <DetailsTypeSelectorSubTitle>
            Forecasted {isARR ? 'ARR' : 'MRR'} for {dayjs(`${selectedMonth}-15`).format('MMM YYYY')}
          </DetailsTypeSelectorSubTitle>
        </FlexerColumn>

        <Spacer width="20px" />

        <DetailsTypeSelector>
          <DetailsTypeSelector>
            <ExplainerTooltip
              width={314}
              isVisible={true}
              toolTipText={`Select New Customers or ${
                existingForecastType === EXISTING_CUSTOMERS_FORECAST_TYPE.NRR
                  ? 'Existing Customers'
                  : 'Renewals and Upsells'
              }`}
            >
              <Flexer>
                <DetailsTypeSelectorOption
                  onClick={() => setSelectedDetailsType(FORECAST_DETAILS_TYPE.NEW)}
                  isSelected={selectedDetailsType === FORECAST_DETAILS_TYPE.NEW}
                >
                  <TabsCircle>
                    <UsersIcon />
                  </TabsCircle>
                  <FlexerColumn>
                    <DetailsTypeSelectorTitle>
                      New Customers:{' '}
                      {newMonthData?.total &&
                        numberFormatter({ type: NUMBER_FORMATS.CURRENCY, rawValue: newMonthData?.total })}
                    </DetailsTypeSelectorTitle>
                    <DetailsTypeSelectorSubTitle>Based on the sales pipeline</DetailsTypeSelectorSubTitle>
                  </FlexerColumn>
                </DetailsTypeSelectorOption>
                <DetailsTypeSelectorOption
                  onClick={() => setSelectedDetailsType(FORECAST_DETAILS_TYPE.EXISTING)}
                  isSelected={selectedDetailsType === FORECAST_DETAILS_TYPE.EXISTING}
                >
                  <TabsCircle>
                    <ExistingUsersIcon />
                  </TabsCircle>
                  <FlexerColumn>
                    <DetailsTypeSelectorTitle>
                      {existingForecastType === EXISTING_CUSTOMERS_FORECAST_TYPE.NRR
                        ? 'Existing Customers: '
                        : 'Renewals and Upsells: '}
                      {existingMonthData?.total &&
                        numberFormatter({ type: NUMBER_FORMATS.CURRENCY, rawValue: existingMonthData?.total })}
                    </DetailsTypeSelectorTitle>
                    <DetailsTypeSelectorSubTitle>
                      Based on the{' '}
                      {existingForecastType === EXISTING_CUSTOMERS_FORECAST_TYPE.NRR ? 'NRR' : 'sales pipeline'}
                    </DetailsTypeSelectorSubTitle>
                  </FlexerColumn>
                </DetailsTypeSelectorOption>
              </Flexer>
            </ExplainerTooltip>
          </DetailsTypeSelector>
        </DetailsTypeSelector>

        <DetailsTypeSelectorExplainer onClick={openHowItWorksModal}>
          <FlexerColumn>
            <DetailsTypeSelectorTitle>How do forecasts work?</DetailsTypeSelectorTitle>
            <DetailsTypeSelectorSubTitle>Click to get help</DetailsTypeSelectorSubTitle>
          </FlexerColumn>
          <DetailsTypeSelectorExplainerButton>
            <ArrowRight />
          </DetailsTypeSelectorExplainerButton>
        </DetailsTypeSelectorExplainer>

        <HowItWorksModal />
      </DetailsTypeSelectorContainer>
    </DetailsTypeSelectorWrapper>
  );
};
