import React, { useContext, useState } from 'react';
import { useMemo } from 'react';
import { useTable, useSortBy, usePagination } from 'react-table';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import { Form, Formik } from 'formik';

import { AppContext } from 'AppContext';
import { NUMBER_FORMATS } from 'consts/global';
import { useCurrencyNumberFormatter } from 'utils/hooks';
import { CustomerViewModal } from 'views/Customers/CustomerSingle/CustomerViewModal';
import { Centerer, Row, Spacer } from 'components/Core';
import { FillDot } from 'components/Icons';
import {
  TableWrapper,
  TableHeader,
  TableHeaderColumn,
  TableHeaderRow,
  TableBody,
  TableRow,
  TableCell,
  TableCellEmpty,
} from 'components/Table/CompactNumericTable';
import { PaginationButton } from 'components/Buttons';
import { TableColumnSettings } from 'shared/Common';
import { EXISTING_CUSTOMERS_FORECAST_TYPE } from '../consts';
import { CustomerNameCell } from '../Shared/CustomerNameCell';
import { CustomizableMetric } from '../Shared/CustomizableMetric';
import { ShowHideButton } from '../Shared/styles';
import { EXISTING_CUSTOMERS_TABLE_TYPE } from './consts';
import {
  getForecastedExistingCustomersTableData,
  getHiddenColumns,
  getTitleColumnHeader,
  secondaryMapper,
  underContractFromPipelineRenewal,
} from './ForecastedExistingCustomersTable.utils';

export const ForecastedExistingCustomersTable = ({
  forecastId,
  month,
  customersData,
  existingForecastType,
  tableType,
  editForecastDetails,
}) => {
  const {
    appSettings: { isARR },
  } = useContext(AppContext);
  const numberFormatter = useCurrencyNumberFormatter();
  const [customerIdToShow, setCustomerIdToShow] = useState(null);
  const [showingAll, setShowingAll] = useState(false);
  const isShowOthersTable = [EXISTING_CUSTOMERS_TABLE_TYPE.PIPELINE_RENEWAL].includes(tableType);
  const isPipelineTable = [
    EXISTING_CUSTOMERS_TABLE_TYPE.PIPELINE_RENEWAL,
    EXISTING_CUSTOMERS_TABLE_TYPE.PIPELINE_UPSELL,
  ].includes(tableType);

  const handleSubmit = (values) => {
    const data =
      existingForecastType === EXISTING_CUSTOMERS_FORECAST_TYPE.NRR
        ? { net_revenue_retention: values.net_revenue_retention / 100 }
        : {
            pipeline_probability: values.pipeline_probability / 100,
            pipeline_recurring_revenue: values.pipeline_recurring_revenue / (isARR ? 12 : 1),
            pipeline_closed_date: values.pipeline_closed_date,
          };
    editForecastDetails({
      forecastId,
      forecastMonth: month,
      forecastType: 'customers_existing',
      customerId: values.customer_id,
      data,
    });
  };

  const columns = useMemo(
    () => [
      {
        Header: (
          <Centerer>
            {isPipelineTable && (
              <>
                <FillDot fill="var(--primaryYellow)" size="8px" />
                <Spacer width="8px" />
              </>
            )}
            <div>{getTitleColumnHeader({ tableType, month })}</div>
            {isShowOthersTable && (
              <>
                <Spacer width="8px" />
                <ShowHideButton onClick={() => setShowingAll(!showingAll)}>
                  ({showingAll ? 'Hide' : 'Show'} others)
                </ShowHideButton>
              </>
            )}
          </Centerer>
        ),
        accessor: 'customer_name',
        Cell: ({ row }) => (
          <CustomerNameCell
            externalServiceName={row.original.external_service_name}
            externalUrl={row.original.external_url}
            onCustomerClick={() => setCustomerIdToShow(row.original.customer_id)}
          >
            {row.original.customer_name}
          </CustomerNameCell>
        ),
      },
      {
        Header:
          tableType === EXISTING_CUSTOMERS_TABLE_TYPE.UNDER_CONTRACT
            ? 'Current recurring revenue'
            : 'Previous Recurring Rev.',
        accessor: 'initial_amount',
        Cell: ({ cell: { value } }) => numberFormatter({ type: NUMBER_FORMATS.CURRENCY, rawValue: value }),
      },
      {
        Header: 'Calculated NRR',
        accessor: 'net_revenue_retention',
        Cell: ({ row }) =>
          existingForecastType === EXISTING_CUSTOMERS_FORECAST_TYPE.PIPELINE &&
          underContractFromPipelineRenewal(row.original) ? null : (
            <CustomizableMetric
              explanation={
                tableType === EXISTING_CUSTOMERS_TABLE_TYPE.UNDER_CONTRACT &&
                row.original.net_revenue_retention === 1 ? (
                  <span>Customer is under contract in {dayjs(month).format('MMM YYYY')}</span>
                ) : (
                  <span>
                    In {dayjs(`${month}-15`).format('MMMM')}, <b>{row.original.customer_name}</b> will be in month{' '}
                    {row.original.customer_contract_at_month} since the start of their contract. On average, net revenue
                    retention for customers from Month {row.original.customer_contract_at_month - 1} to Month{' '}
                    {row.original.customer_contract_at_month} is{' '}
                    {numberFormatter({ type: NUMBER_FORMATS.PERCENT, rawValue: row.original.net_revenue_retention })}
                  </span>
                )
              }
              name="net_revenue_retention"
              value={row.original.net_revenue_retention}
              dataType={NUMBER_FORMATS.PERCENT}
            />
          ),
      },
      {
        Header: 'Downsell / Churn',
        accessor: 'downsell_churn',
        Cell: ({ row }) => {
          const downsellOrChurn = parseFloat(row.original.downsell) + parseFloat(row.original.churn);
          return numberFormatter({ type: NUMBER_FORMATS.CURRENCY, rawValue: downsellOrChurn });
        },
      },
      {
        Header: 'Upsell',
        accessor: 'upsell',
        Cell: ({ row, cell: { value } }) =>
          existingForecastType === EXISTING_CUSTOMERS_FORECAST_TYPE.PIPELINE &&
          underContractFromPipelineRenewal(row.original)
            ? null
            : numberFormatter({ type: NUMBER_FORMATS.CURRENCY, rawValue: value }),
      },
      {
        Header: tableType === EXISTING_CUSTOMERS_TABLE_TYPE.UP_FOR_RENEWAL ? '' : 'Pipeline amount',
        accessor: 'pipeline_recurring_revenue',
        Cell: ({ row }) =>
          tableType === EXISTING_CUSTOMERS_TABLE_TYPE.UP_FOR_RENEWAL ? null : (
            <CustomizableMetric
              name="pipeline_recurring_revenue"
              dataType={NUMBER_FORMATS.CURRENCY}
              value={row.original.pipeline_recurring_revenue}
              isSecondary={row.original.isSecondary}
            />
          ),
      },
      {
        Header: tableType === EXISTING_CUSTOMERS_TABLE_TYPE.UP_FOR_RENEWAL ? '' : 'Close date',
        accessor: 'pipeline_closed_date',
        Cell: ({ row }) =>
          tableType === EXISTING_CUSTOMERS_TABLE_TYPE.UP_FOR_RENEWAL ? null : (
            <CustomizableMetric
              name="pipeline_closed_date"
              dataType="calendar"
              value={row.original.pipeline_closed_date}
              explanation="Thus deal is expected to close on this date"
              missingExplanation="This deal is missing a close date"
              isSecondary={row.original.isSecondary}
            />
          ),
      },
      {
        Header: 'Forecasted change',
        accessor: 'forecasted_change',
        Cell: ({ row }) =>
          numberFormatter({
            type: NUMBER_FORMATS.CURRENCY,
            rawValue: row.original.forecasted_amount - row.original.initial_amount,
            decimalPlaces: 0,
            addSign: true,
          }),
      },
      {
        Header: 'Expected recurring revenue',
        accessor: 'forecasted_amount',
        Cell: ({ cell: { value } }) => numberFormatter({ type: NUMBER_FORMATS.CURRENCY, rawValue: value }),
      },
    ],
    [
      month,
      tableType,
      existingForecastType,
      showingAll,
      setShowingAll,
      isShowOthersTable,
      isPipelineTable,
      numberFormatter,
    ],
  );

  const data = useMemo(() => {
    const customerList = getForecastedExistingCustomersTableData({
      customersData,
      existingForecastType,
      tableType,
      month,
    });

    return isShowOthersTable
      ? secondaryMapper({
          customerList,
          month,
          showingAll,
        })
      : customerList;
  }, [customersData, tableType, existingForecastType, month, showingAll, isShowOthersTable]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageCount,
    nextPage,
    previousPage,
    setPageSize,
    canPreviousPage,
    canNextPage,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        hiddenColumns: getHiddenColumns({
          tableType,
          existingForecastType,
        }),
        pageSize: 20,
        sortBy: [
          {
            id: 'customer_name',
            desc: false,
          },
        ],
      },
    },
    useSortBy,
    usePagination,
  );

  if (isEmpty(data) && tableType !== EXISTING_CUSTOMERS_TABLE_TYPE.PIPELINE_RENEWAL) {
    return null;
  }

  return (
    <>
      <Row horizontal="flex-end">
        <Row key={`${page}-${pageCount}`}>
          <PaginationButton
            pageIndex={pageIndex + 1}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            pageCount={pageCount}
            nextPage={nextPage}
            previousPage={previousPage}
          />
          <Spacer width="20px" />
          <TableColumnSettings
            numberValue={pageSize}
            handleShowResultsChange={(option) => setPageSize(option.value)}
            tableName={`forecast-details-existing-${tableType}`}
          />
        </Row>
      </Row>
      <TableWrapper {...getTableProps()} noMaxHeight={true} data-cy="forecasted-existing-customers-table">
        <TableHeader>
          {headerGroups.map((headerGroup) => (
            <TableHeaderRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <TableHeaderColumn {...column.getHeaderProps()} columnCount={columns.length}>
                  {column.render('Header')}
                </TableHeaderColumn>
              ))}
            </TableHeaderRow>
          ))}
        </TableHeader>
        <TableBody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <Formik
                key={row.id}
                initialValues={{
                  customer_id: row.original.customer_id,
                  net_revenue_retention: row.original.net_revenue_retention * 100,
                  pipeline_probability: row.original.pipeline_probability * 100,
                  pipeline_recurring_revenue: row.original.pipeline_recurring_revenue,
                  pipeline_closed_date: row.original.pipeline_closed_date,
                }}
                onSubmit={handleSubmit}
                enableReinitialize={true}
              >
                <Form>
                  <TableRow {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <TableCell {...cell.getCellProps()} columnCount={columns.length}>
                        {cell.render('Cell')}
                      </TableCell>
                    ))}
                  </TableRow>
                </Form>
              </Formik>
            );
          })}
          {page.length === 0 && (
            <TableRow>
              <TableCellEmpty>Nothing was found for this month</TableCellEmpty>
            </TableRow>
          )}
        </TableBody>
      </TableWrapper>
      {customerIdToShow && (
        <CustomerViewModal customerId={customerIdToShow} onClose={() => setCustomerIdToShow(null)} />
      )}
    </>
  );
};
