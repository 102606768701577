import { NUMBER_FORMATS } from 'consts/global';
import { ReactComponent as CompareEqual } from 'images/compare_dot.svg';
import { Centerer, Spacer } from 'components/Core';
import { GreenUpArrow, RedDownArrow } from 'components/Icons';

const PERCENTAGE_DIFFERENCE_TO_SHOW_VALUE_COMPARISON = 0.25;

export const ValuesComparison = ({
  originalValue,
  compareValue,
  dataType = NUMBER_FORMATS.CURRENCY,
  percentageDifferenceToShowValueComparison = PERCENTAGE_DIFFERENCE_TO_SHOW_VALUE_COMPARISON,
}) => {
  if (isNaN(originalValue) || isNaN(compareValue)) return null;

  const roundedOriginalValue = dataType === NUMBER_FORMATS.CURRENCY ? originalValue?.toFixed() : originalValue;
  const roundedCompareValue = dataType === NUMBER_FORMATS.CURRENCY ? compareValue?.toFixed() : compareValue;

  let comparisonIcon = null;
  if (roundedOriginalValue > roundedCompareValue * (1 + percentageDifferenceToShowValueComparison))
    comparisonIcon = <GreenUpArrow size="12px" />;
  if (roundedOriginalValue === roundedCompareValue) comparisonIcon = <CompareEqual />;
  if (roundedOriginalValue < roundedCompareValue * (1 - percentageDifferenceToShowValueComparison))
    comparisonIcon = <RedDownArrow size="12px" />;

  return (
    <>
      <Spacer width="4px" />
      <Centerer>{comparisonIcon}</Centerer>
    </>
  );
};
