import styled from 'styled-components';
import { BannerButton, BannerTitle } from 'components/Blocks/Banner';
import { ReactComponent as ReportBannerDecor } from 'images/metrics-share-banner__top-decor.svg';

export const AutoConfirmBannerTitle = styled(BannerTitle)`
  color: var(--primaryBlack);
  font-weight: 900;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 0;
  margin-left: 8px;
`;

export const StyledBannerButton = styled(BannerButton)`
  padding: 8px 12px;
  font-weight: 900;
  font-size: 10px;
  line-height: 12px;
  border-radius: 100px;
  margin-top: 0;
`;

export const ActiveTag = styled.div`
  font-weight: 900;
  font-size: 9px;
  padding: 2px 4px;
  background: white;
  border-radius: 4px;
  line-height: 12px;
  position: absolute;
  color: var(--primaryBlack);
  text-transform: uppercase;
  left: 12px;
  top: -8px;
`;

export const CarouselActions = styled.div`
  display: flex;
  align-items: center;
  margin-top: 12px;
`;

export const ActionIcon = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;

  &:hover {
    svg path {
      fill: var(--secondaryBlue);
    }
  }
`;

export const Carousel = styled.div`
  padding: 20px;
  border: 1px solid var(--accentGraySecond);
  box-shadow: 8px 8px 60px var(--primaryBlack4);
  border-radius: 20px;
  margin-top: 20px;
`;

export const CurrentBanner = styled.div`
  font-weight: 900;
  font-size: 10px;
  line-height: 12px;
  padding: 8px 12px;
  border: 1px solid var(--accentGraySecond);
  border-radius: 100px;
  margin: 0 4px;
  text-transform: uppercase;
`;

export const HelpButton = styled.div`
  font-weight: 900;
  font-size: 10px;
  line-height: 12px;
  padding: 8px 12px;
  color: var(--primaryBlack75);
  border: 1px solid var(--primaryBlack5);
  text-transform: uppercase;
  border-radius: 100px;
  margin-right: 12px;
  cursor: pointer;

  &:hover {
    color: var(--primaryBlack);
    background-color: var(--primaryBlack5);
  }
`;

export const ReportsBannerWrapper = styled.div`
  margin: 28px 0;
  position: relative;
  background: var(--primaryGray);
  border: 1px solid var(--neutralGray);
  box-shadow: 5px 20px 200px var(--primaryBlack8);
  border-radius: 20px;
`;

export const ReportsBannerTitle = styled.div`
  margin-bottom: 12px;
  font-weight: 900;
  font-size: 20px;
  line-height: 26px;
`;

export const ReportsBannerText = styled.div`
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 20px;
`;

export const ReportsBannerTopLeftDecorImage = styled(ReportBannerDecor)`
  position: absolute;
  left: -12px;
  top: -12px;
`;

export const CloseReportBannerButton = styled.div`
  padding: 10px 12px;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  color: var(--primaryBlack50);

  &:hover {
    color: var(--primaryBlack);
  }
`;

export const ReportsConnectionButton = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  margin-right: 12px;
  padding: 12px;
  background: var(--primaryGreen);
  border: 1px solid var(--primaryBlack5);
  box-shadow: ${({ connected }) =>
    connected ? '0px 4px 24px var(--secondaryGreen20)' : '0px 4px 10px var(--shadowGreen)'};
  pointer-events: ${({ connected }) => connected && 'none'};
  cursor: pointer;
  border-radius: 8px;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: white;

  &:hover {
    box-shadow: 0px 4px 24px var(--secondaryGreen20);
  }
`;
