import React, { useState } from 'react';
import { Formik } from 'formik';
import styled from 'styled-components';

import { FormikCustomSelector } from 'components/Controls';
import { Flexer } from 'components/Core';
import { useConfirmModal } from 'shared/ConfirmModal';

const FlexGrow = styled.div`
  flex-grow: 1;
`;

export const useUpsertModal = ({ glTrackingCategories, trackingCategoryOptions, onUpsert }) => {
  const [trackingCategory, setTrackingCategory] = useState({});

  const { ConfirmModal, openConfirmModal, isModalVisible } = useConfirmModal({
    title: 'Create or edit tracking category field',
    width: '640px',
    content: (
      <Formik enableReinitialize initialValues={trackingCategory}>
        <Flexer gap="20px">
          <FlexGrow>
            <FormikCustomSelector
              name="subscriptField"
              label="Name"
              placeholder="Select"
              options={trackingCategoryOptions}
              isClearable
              minWidth="180px"
              handleChange={(option) => {
                setTrackingCategory((prev) => ({ ...prev, subscriptField: option?.value }));
              }}
            />
          </FlexGrow>
          <FlexGrow>
            <FormikCustomSelector
              name="glField"
              label="Field in your GL"
              placeholder="Select or create"
              options={glTrackingCategories}
              creatable
              isClearable
              minWidth="180px"
              handleChange={(option) => {
                setTrackingCategory((prev) => ({ ...prev, glField: option?.value }));
              }}
            />
          </FlexGrow>
        </Flexer>
      </Formik>
    ),
    confirmButtonText: 'Save',
    denyButtonText: 'Cancel',
    onConfirm: () => {
      onUpsert(trackingCategory);
      setTrackingCategory({});
    },
    onDeny: () => {
      setTrackingCategory({});
    },
  });

  return {
    Modal: ConfirmModal,
    openModal: ({ glField, subscriptField }) => {
      setTrackingCategory({ glField, subscriptField });
      openConfirmModal();
    },
    isModalVisible,
  };
};
