export const getIdWithEscapingDots = (id) => id?.replace(/\./g, '\\.');

export const closeAtMention = ({ setShowTags, setEnteredMention, id }) => {
  setShowTags(false);
  setEnteredMention('');

  const contentEditableElement = document.querySelector(`#${getIdWithEscapingDots(id)}`);
  if (!contentEditableElement) return;

  // get all <em> (mentions inputs) insude contenteditable
  const emElements = contentEditableElement.querySelectorAll('em');
  let lastSpan = null;

  // replace all <em> to span to avoid confusing when creating new <em> (mentions inputs)
  emElements.forEach((em) => {
    const span = document.createElement('span');

    // copy all chids from <em> into <span>
    while (em.firstChild) {
      span.appendChild(em.firstChild);
    }

    // replace <em> on <span> in DOM
    em.parentNode.replaceChild(span, em);

    lastSpan = span;
  });

  if (lastSpan) {
    //put cursor after last created span
    const range = document.createRange();
    range.setStartAfter(lastSpan);
    range.collapse(true);
    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
  }
};

export const insertTag = ({ tag, setShowTags, availableTags, templateToHTMLString, id }) => {
  const atTag = document.querySelector(`#${getIdWithEscapingDots(id)} em`);

  if (atTag) {
    atTag.remove();
  }

  setShowTags(false);

  const selection = window.getSelection();
  const range = selection.getRangeAt(0);

  const el = document.createElement('span');
  el.innerHTML = templateToHTMLString({ text: tag, availableTags });

  range.insertNode(el);

  range.setStartAfter(el);
  range.setEndAfter(el);
};

export const onContentEditableKeyDown = ({
  event,
  setShowTags,
  availableTags,
  templateToHTMLString,
  setEnteredMention,
  humanizedTagsRef,
  id,
}) => {
  const atTag = document.querySelector(`#${getIdWithEscapingDots(id)} em`);

  if (event.key === 'Enter') {
    event.preventDefault();

    const selectedTag = document.querySelector('[data-tagfocused="true"]');

    if (selectedTag) {
      insertTag({ tag: selectedTag?.dataset?.tagkey, setShowTags, availableTags, templateToHTMLString, id });
    } else {
      // info regarding 'getSelection' and 'getRangeAt'
      // https://medium.com/@alexandrawilll/window-getselection-and-range-in-javascript-5a13453d22
      const selection = window.getSelection();
      const range = selection.getRangeAt(0);
      const el = document.createElement('br');

      range.insertNode(el);

      range.setStartAfter(el);
      range.setEndAfter(el);
    }
    //on space we close mention if we don't find any tags
  } else if (event.keyCode === 32 && atTag && !humanizedTagsRef?.current?.length) {
    closeAtMention({ setShowTags, setEnteredMention, id });
  }
};

export const onContentEditablePaste = ({ event, availableTags, templateToHTMLString }) => {
  const _copiedText = event.clipboardData.getData('text/plain');

  // this is to ensure that copied text with brackets have the desired format
  // ie, '{' at the start and '}' at the end
  const getBracketText = _copiedText.match(/[^{}]+(?=})/g);

  const copiedText = getBracketText ? `{${getBracketText[0]}}` : _copiedText;

  if (copiedText.startsWith('{') && copiedText.endsWith('}') && (availableTags ?? []).includes(copiedText)) {
    event.preventDefault();
    const selection = window.getSelection();
    const range = selection.getRangeAt(0);

    const el = document.createElement('span');
    el.innerHTML = templateToHTMLString({ text: event.clipboardData.getData('text/plain'), availableTags });

    range.insertNode(el);

    range.setStartAfter(el);
    range.setEndAfter(el);
  }
};

export const onContentEditableChange = ({ event, onChange, id, sanitizeHtml, setEnteredMention }) => {
  let value = event.currentTarget.innerHTML;

  if (value.includes('<div>')) {
    value = value.replaceAll('<div><br></div>', '<br>').replaceAll('<div>', '').replaceAll('</div>', '<br>');
  }

  const atTag = document.querySelector(`#${getIdWithEscapingDots(id)} em`);
  const text = atTag?.innerText;

  if (atTag && text) {
    setEnteredMention(text);
  }

  onChange(
    sanitizeHtml(value, {
      allowedClasses: {
        b: ['invalid'],
      },
    }),
  );
};

export const openAtMentionOnAt = ({
  id,
  event,
  enteredMentionRef,
  setEnteredMention,
  setTagsPosition,
  setShowTags,
  setActiveTagIndex,
}) => {
  const selection = window.getSelection();
  const range = selection.getRangeAt(0);

  const textNode = range.startContainer;
  const offset = range.startOffset;

  if (textNode.nodeType === Node.TEXT_NODE && textNode.nodeValue[offset - 1] === '@') {
    textNode.nodeValue = textNode.nodeValue.substring(0, offset - 1) + textNode.nodeValue.substring(offset);

    // Move cursor back bacause we removed symbol
    range.setStart(textNode, offset - 1);
    range.collapse(true);

    const mentionSymbol = document.createElement('em');
    mentionSymbol.textContent = '@';
    range.insertNode(mentionSymbol);

    range.setStartAfter(mentionSymbol);
    range.setEndAfter(mentionSymbol);

    const symbolRect = mentionSymbol.getBoundingClientRect();
    const container = document.querySelector(`#${getIdWithEscapingDots(id)}`);
    const containerRect = container.getBoundingClientRect();

    const position = { left: symbolRect.left - containerRect.left, top: symbolRect.top - containerRect.top };

    setActiveTagIndex(0);
    setTagsPosition(position);
    setEnteredMention('@');
    setShowTags(true);
  } else if (event?.key === 'Backspace' && enteredMentionRef?.current === '@') {
    closeAtMention({ setShowTags, setEnteredMention, id });
  }
};
