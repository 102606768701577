import styled from 'styled-components';
import { Formik } from 'formik';
import dayjs from 'dayjs';
import * as Yup from 'yup';
import React, { useCallback, useContext, useState } from 'react';
import { EVENTS } from 'consts/analytics';
import {
  Modal,
  ModalBody,
  ModalButton,
  ModalCloseIcon,
  ModalContainer,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTitleText,
} from 'components/Modal';
import { Centerer, Row, Spacer } from 'components/Core';
import { useTransactionsAPI } from 'api/transactions';
import { REQUIRED_BILLING_PARAMS } from 'api/transactions/hooks';
import { FormikCustomSelector } from 'components/Controls';
import { CircleLoader } from 'components/Loaders';
import { GreyDot } from 'components/Icons';
import { TransactionName } from 'views/Billing/Pages/SchedulesExternalUpdates/TransactionsCell';
import { EXTERNAL_UPDATE_ACTION_OPTIONS } from 'views/ExternalUpdates/consts';
import { BillingContext } from 'views/Billing/BillingContext';
import { transactionDisplayNameForBilling } from 'models/transaction';
import { ReactComponent as ArrowDownCircle } from 'images/circle-arrow-down-v2.svg';
import { useAnalytics } from 'utils/hooks';
import { numberFormatter } from 'utils/formatters';
import { NUMBER_FORMATS } from 'consts/global';
import { InvoicingScheduleContext } from '../InvoicingScheduleContext';
import { RemovedTransactionInfoRow } from './ExternalUpdateRemoveTransactionsWarning';
import { getInvoicesWithRemovedTransactionLineItem, getTotalAndSendPlusPaidTransactionInvoicesAmounts } from './utils';

const RemovedTransactionInfo = styled.div`
  padding: 16px;
  border-radius: 12px;
  background-color: var(--primaryBlack3);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
`;

const ReplaceTransactionSelector = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
  border-radius: 12px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  cursor: pointer;
  border: 1px solid var(--neutralGray);
`;

const StyledArrowDownCircle = styled(ArrowDownCircle)`
  width: 16px;
  height: 16px;
  margin: 8px auto;
  opacity: 0.3;

  path {
    fill: var(--primaryBlack);
  }
`;

const ReplaceTransactionModal = ({
  onClose,
  update,
  resolveExternalUpdate,
  deleteTransactions,
  dateFormat,
  defaultOrgCurrency,
}) => {
  const { customer, orgId } = useContext(InvoicingScheduleContext);
  const { bulkEditInvoices } = useContext(BillingContext);

  const { data: selectorTransactions, isLoading } = useTransactionsAPI({
    orgId,
    filters: {
      limit: 1000,
      params: {
        withoutInvoicingSchedule: true,
        includeDismissedInvoicing: true,
        confirmed: true,
        archived: false,
        includeChildCustomerTransactions: true,
        ...REQUIRED_BILLING_PARAMS,
      },
      body: {
        orderBy: [{ column: 'start_date', order: 'asc' }],
        customerIds: [customer?.id],
      },
    },
  });

  const deletedTransaction = deleteTransactions?.[0];

  const transactionName = transactionDisplayNameForBilling(deletedTransaction);

  const invoicesWithRemovedTransactionLineItem = getInvoicesWithRemovedTransactionLineItem({
    update,
    deleteTransactions,
  });

  const { totalSentAndPaidInvoicesAmount } = getTotalAndSendPlusPaidTransactionInvoicesAmounts({
    invoicesWithRemovedTransactionLineItem,
    specificTransactionId: deletedTransaction?.id,
  });

  const onSubmit = async (values) => {
    const selectedTransaction = selectorTransactions?.find((t) => t?.id === values?.replace_transaction_id);

    const unsentInvoicesCount = invoicesWithRemovedTransactionLineItem?.filter(
      (invoice) => !invoice?.paid_at && !invoice?.sent_at && !invoice?.voided_at,
    )?.length;

    const newAmountForUnsentInvoice =
      ((selectedTransaction?.amount ?? 0) - (totalSentAndPaidInvoicesAmount?.total ?? 0)) / unsentInvoicesCount;

    const invoicesWithNewLineItems = invoicesWithRemovedTransactionLineItem?.map((invoice) => {
      const mappedInvoice = {
        ...invoice,
        invoice_items: invoice?.invoice_items?.map((item) => {
          if (item?.transaction_id === deletedTransaction?.id) {
            return {
              organization_id: orgId,
              customer_id: customer?.id,
              transaction_id: selectedTransaction?.id,
              amount: !!invoice?.paid_at || !!invoice?.sent_at ? item?.amount ?? 0 : newAmountForUnsentInvoice ?? 0,
              seats: item?.seats ?? 0,
              description: selectedTransaction.product_name ?? selectedTransaction.name,
              product_id: selectedTransaction?.product_id,
            };
          } else return item;
        }),
      };

      if (mappedInvoice?.grouping) {
        const removedItemId = invoice?.invoice_items?.find((i) => i?.transaction_id === deletedTransaction?.id)?.id;
        const filteredGrouping = mappedInvoice.grouping.reduce((result, group) => {
          const filteredIds = group?.invoice_items_ids?.filter((id) => id !== removedItemId);
          if (filteredIds.length > 0) {
            result.push({ ...group, invoice_items_ids: filteredIds });
          }
          return result;
        }, []);
        mappedInvoice.grouping = !!filteredGrouping?.length ? filteredGrouping : null;
      }

      return mappedInvoice;
    });

    await bulkEditInvoices({
      data: invoicesWithNewLineItems,
    });

    await resolveExternalUpdate.mutateAsync({
      id: update?.externalUpdate?.id,
      data: { actionType: EXTERNAL_UPDATE_ACTION_OPTIONS.dismiss },
      enableToasts: false,
    });
  };

  return (
    <ModalContainer data-cy="warnings__remove-transaction-warning__replace-modal">
      <Formik
        initialValues={{}}
        enableReinitialize={true}
        validationSchema={Yup.object({
          replace_transaction_id: Yup.string()
            .nullable()
            .test(
              'value',
              "The selected transaction's total amount is insufficient to cover the total of all sent and paid invoices",
              (value) => {
                if (!value) return true;
                const selectedTransaction = selectorTransactions?.find((t) => t?.id === value);

                return selectedTransaction?.amount > totalSentAndPaidInvoicesAmount?.total;
              },
            ),
        })}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, values }) => {
          const selectedTransaction = selectorTransactions?.find((t) => t?.id === values?.replace_transaction_id);

          return (
            <Modal maxWidth="540px" height="auto">
              <ModalHeader>
                <ModalTitle>
                  <ModalTitleText>
                    <b>Replace Transaction</b>
                  </ModalTitleText>
                </ModalTitle>
                <ModalCloseIcon onClose={onClose} />
              </ModalHeader>

              <Spacer height="20px" />

              {isLoading ? (
                <Centerer style={{ marginTop: 40, marginBottom: 'auto' }}>
                  <CircleLoader />
                </Centerer>
              ) : (
                <ModalBody>
                  <RemovedTransactionInfo>
                    <Row horizontal="flex-start" style={{ marginBottom: 8 }}>
                      Deleted transaction:{' '}
                      <TransactionName style={{ marginLeft: 4 }} deleted={true}>
                        {transactionName}
                      </TransactionName>
                    </Row>

                    <RemovedTransactionInfoRow
                      modalView
                      update={update}
                      deleteTransactions={deleteTransactions}
                      dateFormat={dateFormat}
                      defaultOrgCurrency={defaultOrgCurrency}
                    />
                  </RemovedTransactionInfo>

                  <StyledArrowDownCircle />

                  <ReplaceTransactionSelector>
                    <div>New transaction replacement</div>

                    <FormikCustomSelector
                      placeholder="Select transaction"
                      name="replace_transaction_id"
                      options={selectorTransactions?.map(({ id, name }) => ({ value: id, label: name }))}
                      containerWidth="100%"
                      greyVer
                    />

                    {selectedTransaction && (
                      <Row horizontal="start">
                        <b>
                          {dayjs(selectedTransaction?.start_date).format(dateFormat)} –{' '}
                          {selectedTransaction?.end_date
                            ? dayjs(selectedTransaction?.end_date).format(dateFormat)
                            : 'No End Date'}
                        </b>

                        <GreyDot spacing="8px" />

                        <b>
                          {numberFormatter({
                            type: NUMBER_FORMATS.CURRENCY,
                            rawValue: selectedTransaction?.amount,
                            currency: selectedTransaction?.currency ?? defaultOrgCurrency,
                          })}{' '}
                          total
                        </b>
                      </Row>
                    )}
                  </ReplaceTransactionSelector>
                </ModalBody>
              )}
              <Spacer height="20px" />

              <ModalFooter flexEnd>
                <ModalButton style={{ marginRight: 0 }} onClick={onClose} fontWeight={700}>
                  Cancel
                </ModalButton>
                <ModalButton
                  data-cy="replace-transaction-modal__confirm-button"
                  primary
                  onClick={() => handleSubmit()}
                  fontWeight={700}
                >
                  Save
                </ModalButton>
              </ModalFooter>
            </Modal>
          );
        }}
      </Formik>
    </ModalContainer>
  );
};

export const useReplaceTransactionModal = (props) => {
  const { trackEvent } = useAnalytics();

  const { customer } = useContext(InvoicingScheduleContext);

  const [showModal, setShowModal] = useState(false);

  const openModal = useCallback(() => {
    setShowModal(true);
    trackEvent({
      name: EVENTS.OPEN_REPLACE_TRANSACTION_FOR_INVOICING_SCHEDULE_MODAL,
      properties: { customerId: customer?.id },
    });
  }, [trackEvent, setShowModal, customer?.id]);

  const closeModal = useCallback(() => {
    setShowModal(false);
  }, [setShowModal]);

  const Modal = useCallback(() => (showModal ? <ReplaceTransactionModal onClose={closeModal} {...props} /> : null), [
    showModal,
    props,
    closeModal,
  ]);

  return {
    openModal,
    Modal,
  };
};
