import React from 'react';
import { SelectDropdownButton } from 'components/Buttons';
import { ReactComponent as PessimisticImageActive } from 'images/toggle-pessimistic-active.svg';
import { ReactComponent as PessimisticImage } from 'images/toggle-pessimistic.svg';
import { ReactComponent as OptimisticImageActive } from 'images/toggle-optimistic-active.svg';
import { ReactComponent as OptimisticImage } from 'images/toggle-optimistic.svg';
import {
  OPTIMISTIC_BOOLEAN_TO_SELECTOR,
  OPTIMISTIC_SELECTOR_TO_BOOLEAN,
  STORAGE_TOGGLES_KEYS,
  TOGGLES_SELECTORS_DESCRIPTIONS,
  TOGGLES_SELECTORS_KEYS,
} from './consts';
import { CustomOptionItem, CustomOptionText, CustomOptionTextHighlight, CustomOptionTitle } from './styles';

export const GlobalToggleIsOptimistic = ({ globalTogglesState, setGlobalTogglesState }) => (
  <SelectDropdownButton
    noOptionsStyles
    showSelectedDirectly
    name={STORAGE_TOGGLES_KEYS.IS_OPTIMISTIC}
    options={{
      [TOGGLES_SELECTORS_KEYS.PESSIMISTIC]: (
        <CustomOptionItem>
          <CustomOptionTitle>Pessimistic churn assumption</CustomOptionTitle>
          <CustomOptionText>{TOGGLES_SELECTORS_DESCRIPTIONS[TOGGLES_SELECTORS_KEYS.PESSIMISTIC]}</CustomOptionText>

          <PessimisticImageActive className="activeImage" />
          <PessimisticImage className="defaultImage" />
        </CustomOptionItem>
      ),

      [TOGGLES_SELECTORS_KEYS.OPTIMISTIC]: (
        <CustomOptionItem>
          <CustomOptionTitle>Optimistic churn assumption</CustomOptionTitle>
          <CustomOptionText>
            {TOGGLES_SELECTORS_DESCRIPTIONS[TOGGLES_SELECTORS_KEYS.OPTIMISTIC]
              .split('end_date')
              .reduce((acc, current, i) => {
                if (!i) {
                  return [current];
                }
                return acc.concat(<CustomOptionTextHighlight key={i}>end_date</CustomOptionTextHighlight>, current);
              }, [])}
          </CustomOptionText>

          <OptimisticImageActive className="activeImage" />
          <OptimisticImage className="defaultImage" />
        </CustomOptionItem>
      ),
    }}
    selected={OPTIMISTIC_BOOLEAN_TO_SELECTOR[globalTogglesState[STORAGE_TOGGLES_KEYS.IS_OPTIMISTIC]]}
    onSelect={(key) =>
      setGlobalTogglesState({ name: STORAGE_TOGGLES_KEYS.IS_OPTIMISTIC, value: OPTIMISTIC_SELECTOR_TO_BOOLEAN[key] })
    }
  />
);
