import styled from 'styled-components';
import { cssVar, transparentize } from 'polished';

export const InlineButton = styled.button`
  background: ${({ withBackground, isSecondary, isDelete }) =>
    withBackground
      ? transparentize(0.9, cssVar(isSecondary ? '--primaryBlue' : isDelete ? '--primaryRed' : '--primaryGreen'))
      : 'none'};
  border: none;
  border-radius: ${({ withBackground }) => (withBackground ? '4px' : undefined)};
  color: ${({ isSecondary, isDelete }) =>
    isSecondary ? 'var(--primaryBlue)' : isDelete ? 'var(--primaryRed)' : 'var(--primaryGreen)'};
  cursor: ${({ disabled }) => (disabled ? undefined : 'pointer')};
  font-size: ${({ fontSize }) => fontSize ?? '12px'};
  font-weight: ${({ isSecondary, isDelete, withBackground, fontWeight }) =>
    fontWeight ?? ((isSecondary || isDelete) && withBackground ? 'bold' : 700)};
  line-height: ${({ lineHeight }) => lineHeight ?? '16px'};
  margin: 0;
  padding: ${({ withBackground }) => (withBackground ? '3px 4px' : undefined)};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};

  ${({ withBackground, isSecondary, isDelete, disabled }) =>
    withBackground && !disabled
      ? `
    &:hover {
      color: var(--${isSecondary ? 'secondaryBlue' : isDelete ? 'secondaryRed' : 'secondaryGreen'});
      background: ${isSecondary ? 'var(--primaryBlue20)' : isDelete ? 'var(--primaryRed20)' : 'var(--primaryGreen10)'};
    }

    &:focus {
      color: ${isDelete ? 'var(--primaryRed)' : 'var(--secondaryBlue)'};
      background: ${isSecondary ? 'var(--primaryBlue20)' : isDelete ? 'var(--primaryRed20)' : 'var(--primaryGreen10)'};
    }
  `
      : undefined}
`;
