import { NUMBER_FORMATS } from 'consts/global';
import { SNAPSHOT_METRIC_BY_KEY } from 'consts/snapshotMetrics';
import { extractMonthFromPickedDate, monthStringToDate } from 'utils/dateUtils';

const PERIOD_TYPE = 'month';

export const convertFormToGoalData = ({ data, isARR }) => {
  const snapshotMetric = SNAPSHOT_METRIC_BY_KEY[data.metric];
  return {
    name: data.name,
    for_metric: data.metric,
    periods: data.periods.map((period) => ({
      type: PERIOD_TYPE,
      start_month: period.month,
      value:
        snapshotMetric.dataType === NUMBER_FORMATS.PERCENT
          ? period.value / 100
          : isARR && snapshotMetric.isARRMetric
          ? period.value / 12
          : period.value,
    })),
  };
};

const goalToFormValueConverter = ({ snapshotMetric, isARR, period }) =>
  snapshotMetric.dataType === NUMBER_FORMATS.PERCENT
    ? parseFloat((period.value * 100).toFixed(2))
    : Math.round(isARR && snapshotMetric.isARRMetric ? period.value * 12 : period.value);

export const convertGoalToFormData = ({ data, isARR }) => {
  const snapshotMetric = SNAPSHOT_METRIC_BY_KEY[data.for_metric];
  return {
    name: data.name,
    metric: snapshotMetric.key,
    startDate: monthStringToDate(extractMonthFromPickedDate(data.periods[0].start_month)),
    endDate: monthStringToDate(extractMonthFromPickedDate(data.periods[data.periods.length - 1].start_month)),
    goal: goalToFormValueConverter({ snapshotMetric, isARR, period: data.periods[data.periods.length - 1] }),
    periods: data.periods.map((period) => ({
      month: extractMonthFromPickedDate(period.start_month),
      value: goalToFormValueConverter({ snapshotMetric, isARR, period }),
    })),
  };
};
