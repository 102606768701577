import styled from 'styled-components';

export const Flexer = styled.div`
  display: flex;
  flex-wrap: ${({ wrapRow }) => (wrapRow ? 'wrap' : 'no-wrap')};
  flex-grow: ${({ flexGrow }) => flexGrow};
  ${({ justifyContent }) => (justifyContent ? `justify-content: ${justifyContent};` : '')}
  ${({ alignItems }) => (alignItems ? `align-items: ${alignItems};` : '')}
  width: ${({ width }) => width};
  min-width: ${({ minWidth }) => minWidth};
  height: ${({ height }) => height};
  min-height: ${({ minHeight }) => minHeight};
  ${({ direction }) => (direction ? `flex-direction: ${direction}` : '')};
  order: ${({ order }) => order};
  gap: ${({ gap }) => gap ?? ''};
  ${({ marginTop }) => marginTop && `margin-top: ${marginTop}`};
  ${({ marginBottom }) => marginBottom && `margin-bottom: ${marginBottom}`};
  ${({ marginLeft }) => marginLeft && `margin-left: ${marginLeft}`};
  ${({ marginRight }) => marginRight && `margin-right: ${marginRight}`};
  ${({ padding }) => padding && `padding: ${padding}`};
  ${({ paddingLeft }) => paddingLeft && `padding-left: ${paddingLeft}`};
`;

export const FlexerColumn = styled(Flexer)`
  flex-direction: column;
`;

export const FlexerRow = styled(Flexer)`
  flex-direction: row;
`;

export const Centerer = styled(Flexer)`
  align-items: center;
  justify-content: center;
`;

export const CentererVertical = styled(Flexer)`
  align-items: center;
`;

export const Container = styled.div`
  width: ${(props) => props.width ?? '100%'};
`;

export const FlexEndContainer = styled(Flexer)`
  justify-content: flex-end;
`;

export const FlexBetweenContainer = styled(Flexer)`
  justify-content: space-between;
  flex-direction: ${({ direction }) => direction ?? 'row'};
  align-items: ${({ centerer }) => (centerer ? 'center' : undefined)};
  gap: ${({ gap }) => gap ?? undefined};
  width: ${({ width }) => width ?? undefined};
`;

export const Spacer = styled.div`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  flex-grow: ${(props) => props.grow ?? 0};
  flex-shrink: ${(props) => props.shrink ?? 0};
`;
