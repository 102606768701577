import { useMemo } from 'react';
import { useSortBy, useTable } from 'react-table';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { EyeIcon, HelpIconBlack } from 'components/Icons';
import {
  CellWithIcon,
  TableWrapper,
  TableHeader,
  TableHeaderColumn,
  TableHeaderRow,
  TableBody,
  TableRow,
  TableCell,
} from 'components/Table/CompactNumericTable';
import { TooltipContainer } from 'components/Tooltip';
import { NUMBER_FORMATS } from 'consts/global';
import { numberFormatter } from 'utils/formatters';

const StyledHelpIcon = styled(HelpIconBlack)`
  cursor: pointer;
  height: 14px;
  margin-right: 4px;
  overflow: visible;
  width: 14px;
`;

export const NewRevenueFromPreviousMonthsTable = ({ previousMonths, month, onNewRevenueDetailsClick }) => {
  const columns = useMemo(
    () => [
      {
        Header: (
          <>
            <TooltipContainer
              width={220}
              fontSize="12px"
              toolTipContent="This section contains new customers that we expect to acquire prior to this month, but haven’t acquired yet"
            >
              <StyledHelpIcon />
            </TooltipContainer>
            <span>New forecasted revenue from previous months</span>
          </>
        ),
        accessor: 'monthKey',
        Cell: ({ cell: { value } }) => (
          <CellWithIcon>
            <span>New revenue in {dayjs(`${value}-15`).format('MMM YYYY')}</span>
            <TooltipContainer width={220} fontSize="12px" toolTipContent="View forecasted new revenue details">
              <EyeIcon size="14px" fill="var(--primaryBlue50)" onClick={() => onNewRevenueDetailsClick(value)} />
            </TooltipContainer>
          </CellWithIcon>
        ),
      },
      {
        Header: 'Recurring revenue',
        accessor: 'forecasted_recurring_revenue',
        Cell: ({ cell: { value } }) => numberFormatter({ type: NUMBER_FORMATS.CURRENCY, rawValue: value }),
      },
    ],
    [onNewRevenueDetailsClick],
  );

  const data = useMemo(() => {
    return Object.entries(previousMonths ?? {}).map(([key, value]) => ({
      forecasted_recurring_revenue: value,
      monthKey: key,
      month,
    }));
  }, [previousMonths, month]);

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [
          {
            id: 'monthKey',
          },
        ],
      },
    },
    useSortBy,
  );

  return data.length ? (
    <TableWrapper {...getTableProps()} noMaxHeight={true} rowCount={data.length}>
      <TableHeader>
        {headerGroups.map((headerGroup) => (
          <TableHeaderRow {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <TableHeaderColumn {...column.getHeaderProps()} columnCount={columns.length}>
                {column.render('Header')}
              </TableHeaderColumn>
            ))}
          </TableHeaderRow>
        ))}
      </TableHeader>
      <TableBody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <TableRow {...row.getRowProps()}>
              {row.cells.map((cell) => (
                <TableCell {...cell.getCellProps()} columnCount={columns.length}>
                  {cell.render('Cell')}
                </TableCell>
              ))}
            </TableRow>
          );
        })}
      </TableBody>
    </TableWrapper>
  ) : null;
};
