import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { CACHE_KEY as BILLING_CACHE_KEY } from 'api/billing';
import { Centerer, Spacer } from 'components/Core';
import { Modal, ModalBody, ModalCloseIcon, ModalContainer } from 'components/Modal';
import { ReactComponent as AlarmIcon } from 'images/alarm.svg';
import { Button } from 'components/Buttons';
import { SizedLoader } from 'components/Loaders';
import { ReactComponent as RightArrowIcon } from 'images/chevron-right.svg';
import { BILLING_PAGES_ROUTES, INVOICE_STATUSES } from '../consts';
import { useQueryClient } from 'react-query';

const StyledAlarmIcon = styled(AlarmIcon)`
  width: 24px;
  height: 24px;
`;

const Text = styled.div`
  color: ${({ highlighted }) => (highlighted ? 'var(--primaryBlack70)' : 'var(--primaryBlack)')};
  font-weight: ${({ fontWeight }) => fontWeight ?? 400};
  font-size: ${({ fontSize }) => fontSize};
  display: inline-block;
  text-align: center;
`;

const StyledButton = styled(Button)`
  font-weight: 700;
`;

const StyledRightArrowIcon = styled(RightArrowIcon)`
  width: 16px;
  height: 16px;
  margin-left: 8px;

  path {
    fill: var(--primaryGreen);
  }
`;

export const BULK_INVOICE_ACTIONS = {
  MARK_AS_SENT: 'MARK_AS_SENT',
  MARK_AS_PAID: 'MARK_AS_PAID',
  SAVE_TO_GL: 'SAVE_TO_GL',
};

const ActionDescription = ({ action, invoiceIds }) => {
  switch (action) {
    case BULK_INVOICE_ACTIONS.MARK_AS_SENT:
      return (
        <>
          <Text fontSize="14px" fontWeight="600">
            Subscript will mark as sent {invoiceIds?.length} invoices.
          </Text>
        </>
      );
    case BULK_INVOICE_ACTIONS.MARK_AS_PAID:
      return (
        <>
          <Text fontSize="14px" fontWeight="600">
            Subscript will mark as paid {invoiceIds?.length} invoices.
          </Text>
        </>
      );
    case BULK_INVOICE_ACTIONS.SAVE_TO_GL:
      return (
        <>
          <Text fontSize="14px" fontWeight="600">
            Subscript will save {invoiceIds?.length} invoices in your General Ledger.
          </Text>

          <Text fontSize="12px" highlighted>
            Please be aware that responses from third-party services might take some time. You can check the status of
            your invoices at a later time.
          </Text>
        </>
      );
    default:
      return null;
  }
};

export const BulkInvoiceActionModal = ({ onClose, invoiceIds, action, loading }) => {
  const queryClient = useQueryClient();
  const history = useHistory();

  const _onClose = useCallback(() => {
    queryClient.invalidateQueries(BILLING_CACHE_KEY);
    onClose();
  }, [onClose, queryClient]);

  return (
    <>
      <ModalContainer onClose={_onClose}>
        <Modal maxWidth="500px" height="auto" background="var(--primaryGray)">
          <ModalCloseIcon onClose={_onClose} />
          <ModalBody paddingBottom="20px">
            <Centerer direction="column">
              <StyledAlarmIcon />

              <Spacer height="12px" />

              <ActionDescription action={action} invoiceIds={invoiceIds} />

              <Spacer height="12px" />

              {loading ? (
                <>
                  <Text fontSize="10px" fontWeight="400">
                    Start processing...
                  </Text>

                  <Centerer height="100px">
                    <SizedLoader size={40} />
                  </Centerer>
                </>
              ) : (
                <StyledButton
                  onClick={() => {
                    history.push({
                      pathname: BILLING_PAGES_ROUTES.INVOICES,
                      state: { status: INVOICE_STATUSES.PROCESSING },
                    });
                    _onClose();
                  }}
                >
                  View invoices in processing
                  <StyledRightArrowIcon />
                </StyledButton>
              )}
            </Centerer>
          </ModalBody>
        </Modal>
      </ModalContainer>
    </>
  );
};

export const useBulkInvoiceActionModal = () => {
  const [modalState, setModalState] = useState({
    loading: false,
    showModal: false,
    invoiceIds: [],
    action: null,
  });

  const openModal = useCallback(({ invoiceIds, action, loading }) => {
    setModalState((prev) => ({ ...prev, invoiceIds, action, loading, showModal: true }));
  }, []);

  const closeModal = useCallback(() => setModalState((prev) => ({ ...prev, showModal: false })), []);

  const Modal = useCallback(
    () =>
      modalState.showModal ? (
        <BulkInvoiceActionModal
          onClose={closeModal}
          invoiceIds={modalState.invoiceIds}
          action={modalState.action}
          loading={modalState.loading}
        />
      ) : null,
    [modalState, closeModal],
  );

  return {
    openModal,
    Modal,
    isModalVisible: modalState.showModal,
    setModalLoading: (loading) => setModalState((prev) => ({ ...prev, loading })),
  };
};
