import styled from 'styled-components';
import { Centerer, Flexer, Row } from 'components/Core';
import { ReactComponent as DoughnutIcon } from 'images/doughnut-icon.svg';
import { ReactComponent as CheckIcon } from 'images/circle-check.svg';
import { ReactComponent as CrossIcon } from 'images/circle-x.svg';

export const StyledGoalCard = styled(Flexer)`
  flex-direction: column;
  align-items: flex-start;
  padding: 12px;
  border: 1px solid transparent;
  cursor: pointer;
  gap: 16px;

  &:hover {
    border: 1px solid var(--neutralGray);
    box-shadow: 2px 2px 12px var(--primaryBlack2);
    border-radius: 16px;
  }
`;

export const GoalContents = styled(Row)`
  justify-content: space-between;
  gap: 20px;
`;

export const GoalDetails = styled(Flexer)`
  flex-direction: column;
  align-items: flex-start;
`;

export const GoalTitleWrapper = styled(Row)`
  gap: 9px;
`;

export const GoalTitle = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--primaryBlack);
`;

export const GoalTitlePill = styled(Centerer)`
  height: 16px;
  padding: 2px 4px;
  min-width: ${({ failed }) => failed && '88px'};

  border: ${({ failed }) => (failed ? '1px solid var(--primaryRed)' : '1px solid var(--dark7)')};
  border-radius: 100px;

  font-weight: 900;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  color: ${({ failed }) => (failed ? 'var(--primaryRed)' : 'var(--dark7)')};
`;

export const StyledCheckIcon = styled(CheckIcon)`
  width: 14px;
  height: 14px;

  path {
    fill: var(--dark7);
  }
`;

export const StyledCrossIcon = styled(CrossIcon)`
  width: 14px;
  height: 14px;

  path {
    fill: var(--primaryRed);
  }
`;

export const GoalRow = styled(Row)`
  align-items: start;
  gap: 12px;

  svg {
    margin-top: 3px;
  }
`;

export const GoalAmount = styled.div`
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: var(--primaryBlack);
`;

export const GoalCurrent = styled.span`
  color: ${({ failed }) => (failed ? 'var(--secondaryRed)' : 'var(--secondaryBlue)')};
`;

export const GoalDate = styled.div`
  margin-top: 4px;
  margin-bottom: 12px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--primaryBlack50);
`;

export const GoalIcon = styled(DoughnutIcon)`
  circle {
    stroke: ${({ $failed }) => ($failed ? 'var(--secondaryRed)' : 'var(--secondaryBlue)')};
    opacity: ${({ $primary }) => !$primary && 0.3};
  }
`;
