import { SNAPSHOT_METRICS } from 'consts/snapshotMetrics';
import { ReactComponent as GrossCustomerRetIcon } from 'images/explain-modal-icon.svg';
import { ReactComponent as LTVIcon } from 'images/ltv-icon.svg';
import { ReactComponent as CacReccuringIcon } from 'images/cac-recurring-explain.svg';
import { ReactComponent as CacAllCustomerIcon } from 'images/cac-all-customers-explain.svg';
import { ReactComponent as NetRevenueRetentionIcon } from 'images/net-revenue-retention-explain.svg';
import { ReactComponent as GrossRevenueRetentionIcon } from 'images/gross-revenue-retention-explain.svg';
import { ReactComponent as GrossRevChurnRateIcon } from 'images/gross-rev-churn-rate-explain.svg';
import { ReactComponent as ARPAIcon } from 'images/arpa-icon.svg';
import { ReactComponent as ArpaNewIcon } from 'images/arpa-new.svg';
import { ReactComponent as GrossMarginIcon } from 'images/gross-margin-explain.svg';
import { ReactComponent as MaginNumberIcon } from 'images/magic-number-explain.svg';
import { ReactComponent as PaybackPeriodIcon } from 'images/payback-period-explain.svg';
import { ReactComponent as RevenueIcon } from 'images/icon_recurring_revenue_green.svg';
import { NUMBER_FORMATS } from 'consts/global';

const _generateAvgAndARRMetricDataType = (metrics) => {
  const clonedMetrics = { ...metrics };
  for (const [key, value] of Object.entries(metrics)) {
    clonedMetrics[`${key}ARR`] = value;
    clonedMetrics[`${key}Avg`] = value;
    clonedMetrics[`${key}AvgARR`] = value;
  }
  return clonedMetrics;
};

// data type for detail row that show up in Explain Modal
export const METRIC_DATA_TYPE_BY_KEY = _generateAvgAndARRMetricDataType({
  revenuePreviousTotal: NUMBER_FORMATS.CURRENCY,
  recurringRevenueTotal: NUMBER_FORMATS.CURRENCY,
  revenueRenewed: NUMBER_FORMATS.CURRENCY,
  revenueUpsell: NUMBER_FORMATS.CURRENCY,
  revenueDownsell: NUMBER_FORMATS.CURRENCY,
  customersPreviousTotal: NUMBER_FORMATS.NUMBER,
  churnedCustomers: NUMBER_FORMATS.NUMBER,
  grossCustomerRetention: NUMBER_FORMATS.PERCENT,
  ARPA: NUMBER_FORMATS.CURRENCY,
  ARPArevenueStatsTotal: NUMBER_FORMATS.CURRENCY,
  endingRevenueFromStarting: NUMBER_FORMATS.CURRENCY,
  ARPAcustomerStatsTotal: NUMBER_FORMATS.NUMBER,
  ARPArevenueStatsNew: NUMBER_FORMATS.CURRENCY,
  ARPAcustomerStatsNew: NUMBER_FORMATS.NUMBER,
  costSales: NUMBER_FORMATS.CURRENCY,
  costMarketing: NUMBER_FORMATS.CURRENCY,
  costSalesLastQuarter: NUMBER_FORMATS.CURRENCY,
  costMarketingLastQuarter: NUMBER_FORMATS.CURRENCY,
  costSalesThisQuarter: NUMBER_FORMATS.CURRENCY,
  costMarketingThisQuarter: NUMBER_FORMATS.CURRENCY,
  costCogs: NUMBER_FORMATS.CURRENCY,
  PBPrevenueStatsNew: NUMBER_FORMATS.CURRENCY,
  CACcustomerStatsNew: NUMBER_FORMATS.NUMBER,
  CACcustomerStatsNew_allCustomers: NUMBER_FORMATS.NUMBER,
  CACcustomerStatsUpsell: NUMBER_FORMATS.NUMBER,
  revenueTotalPeriod: NUMBER_FORMATS.CURRENCY,
  grossMargin: NUMBER_FORMATS.PERCENT,
  grossRevenueRetention: NUMBER_FORMATS.PERCENT,
  growthInARR: NUMBER_FORMATS.CURRENCY,
  upForRenewal: NUMBER_FORMATS.CURRENCY,
  nonRenewalUpsell: NUMBER_FORMATS.CURRENCY,
  amountRenewedThatWasUpNet: NUMBER_FORMATS.CURRENCY,
  amountRenewedThatWasUpGross: NUMBER_FORMATS.CURRENCY,
  newRevenue: NUMBER_FORMATS.CURRENCY,
  upsellRevenue: NUMBER_FORMATS.CURRENCY,
  downsellRevenue: NUMBER_FORMATS.CURRENCY,
  churnRevenue: NUMBER_FORMATS.CURRENCY,
  LTV: NUMBER_FORMATS.CURRENCY,
  CAC: NUMBER_FORMATS.CURRENCY,
  totalSeats: NUMBER_FORMATS.NUMBER,
  recurringPeriodGrowth: NUMBER_FORMATS.PERCENT,
  netProfit: NUMBER_FORMATS.CURRENCY,
  totalPeriodRevenue: NUMBER_FORMATS.CURRENCY,
  netNewRevenue: NUMBER_FORMATS.CURRENCY,
  customersUpForRenewal: NUMBER_FORMATS.NUMBER,
  customersRenewed: NUMBER_FORMATS.NUMBER,
});

// if the detail row in the Explain modal should be *12 when isARR is true
export const METRIC_SHOULD_CONVERT_TO_ARR_BY_KEY = {
  revenuePreviousTotal: true,
  revenueRenewed: true,
  revenueUpsell: true,
  revenueDownsell: true,
  customersPreviousTotal: false,
  churnedCustomers: false,
  grossCustomerRetention: false,
  ARPA: false, // we don't ARR it anywhere, but theoretically we should
  ARPArevenueStatsTotal: false, //used for ARPA which we don't ARR
  endingRevenueFromStarting: true,
  customerStatsTotal: false,
  ARPArevenueStatsNew: false, //used for ARPA and Payback period which we don't ARR
  customerStatsNew: false,
  costSales: false,
  costMarketing: false,
  costSalesLastQuarter: false,
  costMarketingLastQuarter: false,
  costSalesThisQuarter: false,
  costMarketingThisQuarter: false,
  customerStatsNew_allCustomers: false,
  costCogs: false,
  revenueTotalPeriod: false, //used for gross margin calc
  grossMargin: false,
  grossRevenueRetention: false,
  growthInARR: false, //already as ARR
};

// if the metric in the Explain modal should be *12 when isARR is true
export const METRIC_VALUE_SHOULD_CONVERT_TO_ARR_BY_TITLE = {
  [SNAPSHOT_METRICS.LTV.label]: false,
  [SNAPSHOT_METRICS.LTVGMLaden.label]: false,
  [SNAPSHOT_METRICS.CAC_RECURRING.label]: false,
  [SNAPSHOT_METRICS.CAC_ALL.label]: false,
  [SNAPSHOT_METRICS.CEC_RECURRING.label]: false,
  [SNAPSHOT_METRICS.GROSS_CUSTOMER_RETENTION.label]: false,
  [SNAPSHOT_METRICS.NET_REVENUE_RETENTION.label]: false,
  [SNAPSHOT_METRICS.GROSS_REVENUE_RETENTION.label]: false,
  [SNAPSHOT_METRICS.GROSS_REVENUE_CHURN_RATE.label]: false,
  [SNAPSHOT_METRICS.ARPA.label]: false, //currently we show this as monthly always but not sure that's right
  [SNAPSHOT_METRICS.ARPA_NEW.label]: false,
  [SNAPSHOT_METRICS.GROSS_MARGIN.label]: false,
  [SNAPSHOT_METRICS.GROSS_MARGIN_TTM.label]: false,
  [SNAPSHOT_METRICS.GROSS_MARGIN_NON_RECURRING.label]: false,
  [SNAPSHOT_METRICS.GROSS_MARGIN_NON_RECURRING_TTM.label]: false,
  [SNAPSHOT_METRICS.PAYBACK_PERIOD.label]: false,
  [SNAPSHOT_METRICS.PAYBACK_PERIOD_TTM.label]: false,
  [SNAPSHOT_METRICS.MAGIC_NUMBER.label]: false,
  [SNAPSHOT_METRICS.SALES_EFFICIENCY_RATIO.label]: false,
};

// list of detail rows for Explain modal
export const METRIC_ROWS = [
  'revenuePreviousTotal',
  'recurringRevenueTotal',
  'revenueRenewed',
  'revenueUpsell',
  'revenueDownsell',
  'ARPArevenueStatsTotalARR',
  'ARPArevenueStatsNewARR',
  'customersPreviousTotal',
  'churnedCustomers',
  'grossCustomerRetention',
  'endingRevenueFromStarting',
  'ARPA',
  'ARPAAvg',
  'ARPArevenueStatsTotal',
  'ARPAcustomerStatsTotal',
  'ARPArevenueStatsNew',
  'ARPAcustomerStatsNew',
  'newRevenue',
  'newSeats',
  'CACcustomerStatsNew',
  'CACcustomerStatsNew_allCustomers',
  'CACcustomerStatsUpsell',
  'costSales',
  'costMarketing',
  'costSalesLastQuarter',
  'costMarketingLastQuarter',
  'costSalesThisQuarter',
  'costMarketingThisQuarter',
  'costCogs',
  'PBPrevenueStatsNew',
  'revenueTotalPeriod',
  'grossMargin',
  'grossRevenueRetention',
  'growthInARR',
  'amountRenewedThatWasUpGross',
  'amountRenewedThatWasUpNet',
  'nonRenewalUpsell',
  'upsellRevenue',
  'downsellRevenue',
  'churnRevenue',
  'upForRenewal',
  'LTV',
  'CAC',
  'totalSeats',
  'recurringPeriodGrowth',
  'netProfit',
  'totalPeriodRevenue',
  'netNewRevenue',
  'customersUpForRenewal',
  'customersRenewed',
  'value',
];

export const METRIC_ROW_TO_EXPENSE_TYPE = {
  costSales: 'sales',
  costCogs: 'cogs',
  costMarketing: 'marketing',
};

export const HEADER_ICONS_BY_TITLE = {
  [SNAPSHOT_METRICS.LTV.label]: <LTVIcon />,
  [SNAPSHOT_METRICS.LTV_TTM.label]: <LTVIcon />,
  [SNAPSHOT_METRICS.LTVGMLaden.label]: <LTVIcon />,
  [SNAPSHOT_METRICS.LTVGMLaden_TTM.label]: <LTVIcon />,
  [SNAPSHOT_METRICS.GROSS_CUSTOMER_RETENTION.label]: <GrossCustomerRetIcon />,
  [SNAPSHOT_METRICS.GROSS_CUSTOMER_RETENTION_TTM.label]: <GrossCustomerRetIcon />,
  [SNAPSHOT_METRICS.CAC_RECURRING.label]: <CacReccuringIcon />,
  [SNAPSHOT_METRICS.CAC_RECURRING_TTM.label]: <CacReccuringIcon />,
  [SNAPSHOT_METRICS.CAC_ALL.label]: <CacAllCustomerIcon />,
  [SNAPSHOT_METRICS.CAC_ALL_TTM.label]: <CacAllCustomerIcon />,
  [SNAPSHOT_METRICS.CEC_RECURRING.label]: <CacReccuringIcon />,
  [SNAPSHOT_METRICS.CEC_RECURRING_TTM.label]: <CacReccuringIcon />,
  [SNAPSHOT_METRICS.NEW_REVENUE_TO_COST_RATIO.label]: <CacReccuringIcon />,
  [SNAPSHOT_METRICS.LTVCACRatio.label]: <LTVIcon />,
  [SNAPSHOT_METRICS.LTVCACRatio_TTM.label]: <LTVIcon />,
  [SNAPSHOT_METRICS.NET_REVENUE_RETENTION.label]: <NetRevenueRetentionIcon />,
  [SNAPSHOT_METRICS.NET_REVENUE_RETENTION_TTM.label]: <NetRevenueRetentionIcon />,
  [SNAPSHOT_METRICS.GROSS_REVENUE_RETENTION.label]: <GrossRevenueRetentionIcon />,
  [SNAPSHOT_METRICS.GROSS_REVENUE_RETENTION_TTM.label]: <GrossRevenueRetentionIcon />,
  [SNAPSHOT_METRICS.NET_UP_FOR_RENEWAL_RETENTION.label]: <NetRevenueRetentionIcon />,
  [SNAPSHOT_METRICS.GROSS_UP_FOR_RENEWAL_RETENTION.label]: <GrossRevenueRetentionIcon />,
  [SNAPSHOT_METRICS.CUSTOMER_UP_FOR_RENEWAL_RETENTION.label]: <GrossCustomerRetIcon />,
  [SNAPSHOT_METRICS.GROSS_REVENUE_CHURN_RATE.label]: <GrossRevChurnRateIcon />,
  [SNAPSHOT_METRICS.GROSS_REVENUE_CHURN_RATE_TTM.label]: <GrossRevChurnRateIcon />,
  [SNAPSHOT_METRICS.ARPU.label]: <ARPAIcon />,
  [SNAPSHOT_METRICS.ARPU_NEW.label]: <ARPAIcon />,
  [SNAPSHOT_METRICS.ARPA.label]: <ARPAIcon />,
  [SNAPSHOT_METRICS.ARPA_TTM.label]: <ARPAIcon />,
  [SNAPSHOT_METRICS.ARPA_NEW.label]: <ArpaNewIcon />,
  [SNAPSHOT_METRICS.ARPA_NEW_TTM.label]: <ArpaNewIcon />,
  [SNAPSHOT_METRICS.GROSS_MARGIN.label]: <GrossMarginIcon />,
  [SNAPSHOT_METRICS.GROSS_MARGIN_TTM.label]: <GrossMarginIcon />,
  [SNAPSHOT_METRICS.GROSS_MARGIN_NON_RECURRING.label]: <GrossMarginIcon />,
  [SNAPSHOT_METRICS.GROSS_MARGIN_NON_RECURRING_TTM.label]: <GrossMarginIcon />,
  [SNAPSHOT_METRICS.MAGIC_NUMBER.label]: <MaginNumberIcon />,
  [SNAPSHOT_METRICS.SALES_EFFICIENCY_RATIO.label]: <MaginNumberIcon />,
  [SNAPSHOT_METRICS.PAYBACK_PERIOD.label]: <PaybackPeriodIcon />,
  [SNAPSHOT_METRICS.PAYBACK_PERIOD_TTM.label]: <PaybackPeriodIcon />,
  [SNAPSHOT_METRICS.NET_NEW_REVENUE.label]: <RevenueIcon />,
  [SNAPSHOT_METRICS.NET_NEW_REVENUE_TTM.label]: <RevenueIcon />,
  [SNAPSHOT_METRICS.RULE_OF_40_TTM.label]: <MaginNumberIcon />,
  [SNAPSHOT_METRICS.BURN_MULTIPLE.label]: <MaginNumberIcon />,
  [SNAPSHOT_METRICS.BURN_MULTIPLE_TTM.label]: <MaginNumberIcon />,
};
