import dayjs from 'dayjs';
import {
  SEGMENT_BY_OPTIONS,
  NUMBER_FORMATS,
  RECOGNITION_TYPES,
  ACCOUNTING_SPREAD_POLICY_SPREAD_BY,
} from 'consts/global';
import { numberFormatter } from 'utils/formatters';

export const getHeatLevel = ({ quartiles, value }) => {
  return value === 0 ? -1 : value < quartiles[0] ? 0 : value < quartiles[1] ? 1 : value < quartiles[2] ? 2 : 3;
};

export const formatCurrency = (rawValue, isARR, currency) => {
  let value = parseFloat(rawValue);
  value = isARR ? value * 12 : value;
  return numberFormatter({ type: NUMBER_FORMATS.CURRENCY, rawValue: value, currency });
};

export const getSegmentTitle = ({ segmentKey, segmentBy, products = [], productCategories = [] }) => {
  switch (segmentBy) {
    case SEGMENT_BY_OPTIONS.PRODUCT:
      return products.find((product) => product.id === segmentKey)?.name ?? 'No Product';
    case SEGMENT_BY_OPTIONS.PRODUCT_CATEGORY:
      return productCategories.find((category) => category.id === segmentKey)?.name ?? 'No Product Category';
    default:
      return segmentKey;
  }
};

export const getQuartilesFromValues = (values) => {
  const sortedValues = values.sort((a, b) => a - b);
  return [
    sortedValues[Math.floor(sortedValues.length / 4)],
    sortedValues[Math.floor((sortedValues.length * 2) / 4)],
    sortedValues[Math.floor((sortedValues.length * 3) / 4)],
    sortedValues[sortedValues.length - 1],
  ];
};

export const SPREADS_PALLETS = {
  MONOCHROMIC: 'Monochromic',
  COLORFUL: 'Colorful',
};

export const ACCOUNTING_SPREAD_REASON = {
  SPREAD_TYPE: 'spread_type',
  STARTING_MONTH: 'starting_month',
  ENDING_MONTH: 'ending_month',
  MONTHLY_AMOUNT: 'monthly_amount',
  DAYS_IN_MONTH: 'days_in_month',
  OVERRIDE_AMOUNT: 'override_amount',
  BEFORE_LOCK_DATE: 'before_lock_date',
};

export const getValuesForTransaction = ({ transaction, accountingSpreadPolicy, currency }) => {
  const toReturn = {};

  const transactionStartDate = transaction.start_date ?? transaction.startDate;
  const transactionEndDate = transaction.end_date ?? transaction.endDate;

  const transactionStartDateDayJS = dayjs.utc(transactionStartDate);
  const transactionEndDateDayJS = transactionEndDate ? dayjs.utc(transactionEndDate) : null;

  currency = transaction?.spread?.metadata?.originalCurrency ?? currency;

  toReturn.nameSubtext = `${transactionStartDateDayJS.format('MMM YYYY')} - ${
    transactionEndDateDayJS
      ? transactionEndDateDayJS.format('MMM YYYY')
      : transaction.recognition === RECOGNITION_TYPES.tillCanceled
      ? 'Till Canceled'
      : 'No end date'
  }`;

  switch (transaction.spread.reason) {
    case ACCOUNTING_SPREAD_REASON.OVERRIDE_AMOUNT:
      toReturn.daysInMonth = 'custom amount';
      break;
    case ACCOUNTING_SPREAD_REASON.STARTING_MONTH:
      const adjustedTransactionStartDate = accountingSpreadPolicy?.includeStartDate
        ? transactionStartDateDayJS
        : transactionStartDateDayJS.add(1, 'day');

      toReturn.actDays = adjustedTransactionStartDate.endOf('month').diff(adjustedTransactionStartDate, 'days') + 1;
      toReturn.daysInMonth = dayjs.utc(transaction.spread.date).daysInMonth();
      const pctOfMonthLive = toReturn.actDays / toReturn.daysInMonth;
      toReturn.monthlyAmount = numberFormatter({
        type: NUMBER_FORMATS.CURRENCY,
        rawValue: transaction.spread.amount / pctOfMonthLive,
        decimalPlaces: 2,
        currency,
      });
      break;
    case ACCOUNTING_SPREAD_REASON.MONTHLY_AMOUNT:
      if (
        ![ACCOUNTING_SPREAD_POLICY_SPREAD_BY.WHOLE_MONTH, ACCOUNTING_SPREAD_POLICY_SPREAD_BY.ROUNDED_MONTH].includes(
          accountingSpreadPolicy?.spreadBy,
        )
      ) {
        // In this case we are ignoring the number of days in the month, not relevant
        toReturn.actDays = dayjs.utc(transaction.spread.date).daysInMonth();
        toReturn.daysInMonth = dayjs.utc(transaction.spread.date).daysInMonth();
      }
      toReturn.monthlyAmount = numberFormatter({
        type: NUMBER_FORMATS.CURRENCY,
        rawValue: transaction.spread.amount,
        decimalPlaces: 2,
        currency,
      });
      break;
    case ACCOUNTING_SPREAD_REASON.ENDING_MONTH:
      if (transaction.spread.replacedByDate) {
        const numberOfDays = dayjs
          .utc(transaction.spread.replacedByDate)
          .diff(dayjs.utc(transaction.spread.date), 'days');
        toReturn.actDays = `${numberOfDays} (before replaced)`;
        toReturn.daysInMonth = dayjs.utc(transaction.spread.replacedByDate).daysInMonth();
        const pctOfMonthLive = numberOfDays / toReturn.daysInMonth;
        toReturn.monthlyAmount = numberFormatter({
          type: NUMBER_FORMATS.CURRENCY,
          rawValue: transaction.spread.amount / pctOfMonthLive,
          decimalPlaces: 2,
          currency,
        });
      } else {
        toReturn.monthlyAmount = `(${numberFormatter({
          type: NUMBER_FORMATS.CURRENCY,
          rawValue: transaction.amount,
          decimalPlaces: 2,
          currency,
        })} - ${numberFormatter({
          type: NUMBER_FORMATS.CURRENCY,
          rawValue: transaction.amount - transaction.spread.amount,
          decimalPlaces: 2,
          currency,
        })})`;
        toReturn.monthlyAmountExplanation = `Total - RevRec (${dayjs
          .utc(transactionStartDate)
          .format('MMM YY')} - ${dayjs.utc(transaction.spread.date).subtract(1, 'month').format('MMM YY')})`;
      }
      break;
    case ACCOUNTING_SPREAD_REASON.SPREAD_TYPE:
      toReturn.nameSubtext = `${
        transaction.recognition === RECOGNITION_TYPES.immediate ? 'One time: ' : 'Event based: '
      } ${dayjs.utc(transaction.spread.date).format('MMM DD, YYYY')}`;
      break;
    case ACCOUNTING_SPREAD_REASON.DAYS_IN_MONTH:
      const adjustStartDays = accountingSpreadPolicy?.includeStartDate ? 0 : -1;
      if (transaction.spread.replacedByDate) {
        // number of days from the start of the spread's month to the transaction's replacedByDate
        const numberOfDays = dayjs
          .utc(transaction.spread.replacedByDate)
          .diff(dayjs.utc(transaction.spread.date).startOf('month'), 'days');
        toReturn.actDays = `${numberOfDays} (before replaced)`;
      } else if (dayjs.utc(transaction.spread.date).isSame(transactionStartDate, 'month')) {
        // number of days from transaction start to end of same month
        toReturn.actDays =
          dayjs.utc(transactionStartDate).endOf('month').diff(dayjs.utc(transactionStartDate), 'days') +
          1 +
          adjustStartDays;
      } else {
        // number of days in the spread's month
        toReturn.actDays = dayjs.utc(transaction.spread.date).daysInMonth();
      }

      const transactionLengthInDaysInclusiveEnd =
        dayjs.utc(transactionEndDate).add(1, 'day').diff(transactionStartDate, 'day', false) + adjustStartDays;
      toReturn.monthlyAmount = numberFormatter({
        type: NUMBER_FORMATS.CURRENCY,
        rawValue: transaction.amount / transactionLengthInDaysInclusiveEnd,
        decimalPlaces: 2,
        currency,
      });
      break;
    default:
      break;
  }
  return toReturn;
};

export const SPREAD_REPORT_TYPE = {
  SaaS: 'SaaS',
  Accounting: 'Accounting',
};
