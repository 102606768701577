import { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Formik } from 'formik';
import dayjs from 'dayjs';
import { NUMBER_FORMATS } from 'consts/global';
import { useCreditNoteAPI } from 'api/billing/hooks';
import { numberFormatter } from 'utils/formatters';
import { InlineButton } from 'components/Buttons';
import { Flexer, FlexerColumn, FlexBetweenContainer } from 'components/Core';
import { DIRECTIONS, TooltipContainer } from 'components/Tooltip';
import { InvoiceItemColumnTitle } from 'views/Billing/Common/styles';
import { CreditNoteItem } from 'views/Billing/InvoicingScheduleModal/InvoicingScheduleCreditNotePanel/CreditNoteItem';
import { InvoiceItemsWrapper } from '../../InvoiceScheduleWarnings/styles';
import { AmountBalanceWrapper, TaxesLoader } from '../../styles';
import { InvoicingScheduleContext } from '../../InvoicingScheduleContext';

const TitleWrapper = styled(Flexer)`
  margin-bottom: 4px;
`;

const TitleSubtext = styled.div`
  margin: 8px 0 8px 4px;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
`;

const Footer = styled(FlexBetweenContainer)`
  margin-top: 16px;
  width: 100%;
`;

export const InvoiceCreditNoteItem = ({ creditNote: initialCreditNote, onUnlinkClick, onEditClick }) => {
  const { invoicingServiceDisplayName, refetchCurrentInvoice } = useContext(InvoicingScheduleContext);
  const [creditNote, setCreditNote] = useState(initialCreditNote);

  // Keep polling for most up to date data of the credit note (mostly for taxes data)
  const { data: reloadedCreditNote } = useCreditNoteAPI({
    orgId: initialCreditNote.organization_id,
    creditNoteId: initialCreditNote.id,
    scopes: ['items'],
    queryOptions: {
      enabled: !!initialCreditNote.polling_taxes_job_id,
      refetchInterval: (response) => (!response || response.polling_taxes_job_id ? 1000 : undefined),
    },
  });

  useEffect(() => {
    if (reloadedCreditNote) {
      setCreditNote(reloadedCreditNote);
      if (initialCreditNote.polling_taxes_job_id && !reloadedCreditNote.polling_taxes_job_id) {
        refetchCurrentInvoice();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadedCreditNote?.polling_taxes_job_id]);

  return (
    <TooltipContainer
      key={creditNote.id}
      width={300}
      toolTipContent={`This credit note was already allocated in ${invoicingServiceDisplayName}`}
      hideArrow
      isVisible={!!creditNote.allocated_externally}
      direction={DIRECTIONS.TOP}
      yOffset={-100}
    >
      <InvoiceItemsWrapper>
        <TitleWrapper>
          <InvoiceItemColumnTitle>Credit Note:</InvoiceItemColumnTitle>
          <TitleSubtext>
            {creditNote.credit_note_number || creditNote.id} ({dayjs.utc(creditNote.date).format('MMM D, YYYY')})
          </TitleSubtext>
        </TitleWrapper>
        <Formik initialValues={creditNote} onSubmit={() => {}} enableReinitialize>
          <FlexerColumn gap="8px">
            {(creditNote.items ?? []).map((item, index) => (
              <CreditNoteItem key={item.id} index={index} item={item} readOnly />
            ))}
          </FlexerColumn>
        </Formik>
        <Footer>
          <Flexer gap="8px" justifyContent="flex-start">
            <InlineButton
              isSecondary
              withBackground
              onClick={() => onEditClick({ creditNote })}
              type="button"
              data-cy="invoice-schedule-modal__edit-credit-note-button"
            >
              Edit Credit Note
            </InlineButton>
            <InlineButton
              isDelete
              withBackground
              onClick={() => onUnlinkClick({ creditNote })}
              type="button"
              disabled={!!creditNote.allocated_externally}
              data-cy="invoice-schedule-modal__unlink-credit-note-button"
            >
              Unlink
            </InlineButton>
          </Flexer>
          <FlexerColumn alignItems="flex-end" gap="8px">
            {creditNote.polling_taxes_job_id && (
              <TaxesLoader invoicingServiceDisplayName={invoicingServiceDisplayName} loaderName="credit-note-taxes" />
            )}
            <AmountBalanceWrapper>
              {!creditNote.polling_taxes_job_id && (
                <div>
                  Total taxes on credit note:{' '}
                  <span data-cy="invoice_schedule-modal__credit-note-total-taxes">
                    {numberFormatter({
                      rawValue: creditNote.total_tax,
                      type: NUMBER_FORMATS.CURRENCY,
                      decimalPlaces: 2,
                      currency: creditNote.currency,
                    })}
                  </span>
                </div>
              )}
              <div>
                Total credit amount:{' '}
                <span data-cy="invoice_schedule-modal__credit-note-total">
                  {numberFormatter({
                    rawValue: -creditNote.amount,
                    type: NUMBER_FORMATS.CURRENCY,
                    decimalPlaces: 2,
                    currency: creditNote.currency,
                  })}
                </span>
              </div>
            </AmountBalanceWrapper>
          </FlexerColumn>
        </Footer>
      </InvoiceItemsWrapper>
    </TooltipContainer>
  );
};
