import { useCallback, useState } from 'react';

import { EVENTS } from 'consts/analytics';
import { useAnalytics } from 'utils/hooks';
import { GoalModal } from './GoalModal';

export const useGoalModal = () => {
  const { trackEvent } = useAnalytics();
  const [showModal, setShowModal] = useState(false);
  const [goalId, setGoalId] = useState();
  const [initialMetricKey, setInitialMetricKey] = useState();

  const openModal = useCallback(
    ({ goalId, metricKey }) => {
      setShowModal(true);
      setGoalId(goalId);
      setInitialMetricKey(metricKey);

      goalId
        ? trackEvent({ name: EVENTS.OPEN_GOAL_EDIT, properties: { goalId } })
        : trackEvent({ name: EVENTS.OPEN_GOAL_NEW });
    },
    [setShowModal, setGoalId, setInitialMetricKey, trackEvent],
  );

  const closeModal = useCallback(() => {
    setShowModal(false);
  }, [setShowModal]);

  const Modal = useCallback(
    () => (showModal ? <GoalModal goalId={goalId} onClose={closeModal} initialMetricKey={initialMetricKey} /> : null),
    [showModal, closeModal, goalId, initialMetricKey],
  );

  return {
    openModal,
    Modal,
    isModalOpen: showModal,
  };
};
