import { useState } from 'react';
import { usePapaParse } from 'react-papaparse';
import { Form, Formik, FieldArray } from 'formik';

import { Callout } from 'components/Blocks';
import { FormikCustomInput, FormikCustomSelector } from 'components/Controls';
import { FlexEndContainer, FlexBetweenContainer, Spacer, Flexer, Container } from 'components/Core';
import { InfoCircleOrange } from 'components/Icons';
import { ModalFooter, ModalBody } from 'components/Modal';
import { RemoveButton } from 'components/Buttons';

import { PeriodsForm } from './PeriodsForm';
import { goalSchema, csvGoalSchema } from './schema';
import { convertCsvToPeriods } from './utils';
import { SelectionButton, SubmitButton, CancelButton } from './styles';
import { GOAL_METRICS } from './consts';

export const GoalForm = ({ initialData, onSubmit, onCancel, onDelete, isEdit }) => {
  const { readString } = usePapaParse();

  const [manualCreation, setManualCreation] = useState(true);

  return (
    <Formik
      initialValues={
        initialData !== null
          ? initialData
          : {
              name: '',
              for_metric: '',
              periods: [
                {
                  type: '',
                  start_month: '',
                  value: 0,
                },
                {
                  type: '',
                  start_month: '',
                  value: 0,
                },
                {
                  type: '',
                  start_month: '',
                  value: 0,
                },
              ],
            }
      }
      validationSchema={manualCreation ? goalSchema : csvGoalSchema({ readString })}
      onSubmit={(data) => {
        if (manualCreation) onSubmit(data);
        else {
          const convertedPeriods = convertCsvToPeriods({ csvData: data.csv_data, readString });
          onSubmit({ ...data, periods: convertedPeriods });
        }
      }}
    >
      {({ handleSubmit, values }) => {
        const rightSideActionButtons = (
          <FlexEndContainer>
            <CancelButton onClick={onCancel}>Cancel</CancelButton>
            <Spacer width="10px" />
            <SubmitButton onClick={handleSubmit}>Save</SubmitButton>
          </FlexEndContainer>
        );

        return (
          <Form>
            <ModalBody>
              <Spacer height="20px" />
              <Flexer>
                <Container>
                  <FormikCustomInput name="name" placeholder="Enter name..." label="Name" />
                </Container>
                <Spacer width="20px" />
                <Container>
                  <FormikCustomSelector
                    name="for_metric"
                    placeholder="Select metric..."
                    label="Goal for"
                    options={GOAL_METRICS.map((t) => ({ label: t.label, value: t.key }))}
                  />
                </Container>
              </Flexer>
              <Spacer height="20px" />
              <Flexer>
                <Container>
                  <SelectionButton
                    selected={manualCreation}
                    onClick={() => {
                      setManualCreation(true);
                    }}
                  >
                    MANUALLY
                  </SelectionButton>
                </Container>
                <Container>
                  <SelectionButton
                    selected={!manualCreation}
                    onClick={() => {
                      setManualCreation(false);
                    }}
                  >
                    FROM EXCEL
                  </SelectionButton>
                </Container>
              </Flexer>
              <Spacer height="10px" />
              {manualCreation ? (
                <FieldArray
                  name="periods"
                  render={({ remove, push }) => <PeriodsForm remove={remove} push={push} periods={values.periods} />}
                />
              ) : (
                <>
                  <FormikCustomInput name="csv_data" placeholder="Enter goal, format: A, B, C" label="Goal" textarea />
                  <Spacer height="18px" />
                  <Callout
                    icon={<InfoCircleOrange />}
                    text='Your excel file should have three columns. "A" column is type of period (“month”, “quarter”, “year”), “B” column is starting month of period (e.g. 02/2021), and "C" is value (e.g. 400). For quarter and year periods, put the first month of the period in column A.'
                  />
                </>
              )}
            </ModalBody>
            <Spacer height="20px" />
            <ModalFooter>
              {isEdit ? (
                <FlexBetweenContainer>
                  <RemoveButton onClick={onDelete}>Remove</RemoveButton>
                  {rightSideActionButtons}
                </FlexBetweenContainer>
              ) : (
                rightSideActionButtons
              )}
            </ModalFooter>
          </Form>
        );
      }}
    </Formik>
  );
};
