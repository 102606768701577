import { useCallback, useEffect, useMemo } from 'react';
import { useTable, useSortBy, useGroupBy, useGlobalFilter, useExpanded, usePagination } from 'react-table';
import styled from 'styled-components';
import { ReactComponent as SortIcon } from 'images/sort-descending.svg';
import { ReactComponent as GreyDot } from 'images/medium-grey-dot.svg';
import { useDebouncedSearchBar } from 'components/Blocks';
import { PaginationButton } from 'components/Buttons';
import { Flexer, Row } from 'components/Core';
import { CircleLoader } from 'components/Loaders';
import {
  ReactTable,
  ReactTableBody,
  ReactTableCell,
  ReactTableHeader,
  ReactTableHeaderColumn,
  ReactTableHeaderRow,
  ReactTableRow,
  RowsCount,
  SortIconWrapper,
  TableHeaderActions,
  TablePageSeparator,
  HeaderCellWrapper,
} from 'components/Table';
import { TableColumnSettings } from 'shared/Common';
import { ReportsPopoverEmptySearch } from 'shared/Reports/ReportsPopover/ReportsPopoverEmptySearch';
import { SearchContainer } from 'shared/TransactionTable/styles';

const COLOR_BY_DEPTH = [null, '#F7F8F8', '#F1F2F2', '#F1F2F2'];

const CustomerReactTableRow = styled(ReactTableRow)`
  position: relative;
  background: ${({ rowDepth }) => rowDepth && COLOR_BY_DEPTH[rowDepth]};
  background-color: ${({ backgroundColor }) => backgroundColor};
  opacity: ${(props) => (props.isBlurred ? '0.5' : '1')};

  div[role='cell'] {
    &:first-child {
      position: relative;
      left: ${({ rowDepth }) => rowDepth >= 1 && rowDepth * 8}px;
    }
  }

  &:hover {
    background-color: ${(props) => (props.noRowHover ? 'initial' : 'var(--accentGrayer)')};
  }
`;

export const LoadingSpinnerWrapper = styled(Flexer)`
  position: relative;
  margin-right: 45px;
  height: 24px;
`;

const Table = ({
  columns,
  data,
  columnsTitles,
  noInitialSort,
  searchQuery,
  onSearchQueryChange,
  pageView = true,
  additionalHeaderActions,
  pageCount: controlledPageCount,
  currentPageIndex,
  customGlobalFilter,
  setCurrentPageIndex,
  initialSortBy,
  setSortBy,
  pageSize,
  setPageSize,
  isFetching,
  onVisibleColumnsChange,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    setGlobalFilter,
    prepareRow,
    rows,
    allColumns,
    visibleColumns,
    page,
    state: { sortBy },
  } = useTable(
    {
      columns,
      data,
      getSubRows: (row) => row.children || [],
      initialState: {
        pageIndex: 0,
        pageSize,
        sortBy: noInitialSort ? [] : initialSortBy,
        globalFilter: searchQuery,
        hiddenColumns: [
          'currentMRR',
          'transactionSum',
          'nonRecurringRevenue',
          'start_date',
          'external_ids',
          'invoices',
          'contacts',
          'billing_address',
          'shipping_address',
        ],
      },
      globalFilter: customGlobalFilter,
      paginateExpandedRows: false,
      manualPagination: true,
      manualSortBy: true,
      manualGlobalFilter: customGlobalFilter ? false : true,
      pageCount: controlledPageCount,
    },
    useGlobalFilter,
    useGroupBy,
    useSortBy,
    useExpanded,
    usePagination,
  );

  const handleSearchQueryChange = useCallback(
    (newSearchQuery) => {
      onSearchQueryChange(newSearchQuery);
      setGlobalFilter(newSearchQuery);
      setCurrentPageIndex(1);
    },
    [onSearchQueryChange, setCurrentPageIndex, setGlobalFilter],
  );

  const { DebouncedSearchBar, resetSearchQuery } = useDebouncedSearchBar({
    onSearchQueryChange: handleSearchQueryChange,
    initialValue: searchQuery,
    placeholder: 'Search by Name or External ID...',
    width: '300px',
  });

  const visibleColumnIds = useMemo(() => visibleColumns.map(({ id }) => id), [visibleColumns]);

  useEffect(() => {
    setSortBy(sortBy);
  }, [sortBy, setSortBy]);

  useEffect(() => {
    onVisibleColumnsChange?.(visibleColumnIds);
  }, [visibleColumnIds, onVisibleColumnsChange]);

  return (
    <div data-cy="customers-table">
      <TableHeaderActions horizontal="space-between">
        <SearchContainer>
          <DebouncedSearchBar />
        </SearchContainer>
        <Row>
          {isFetching && (
            <LoadingSpinnerWrapper>
              <CircleLoader isAbsolute isInline width="24px" height="24px" thickness="4px" name="customers-table" />
            </LoadingSpinnerWrapper>
          )}

          <RowsCount>
            <span data-cy="customers-table__row-count">{rows?.length} rows found</span>
          </RowsCount>
          <GreyDot style={{ marginLeft: 20, marginRight: 20, minWidth: 4, minHeight: 4 }} />

          <PaginationButton
            pageIndex={currentPageIndex}
            canPreviousPage={currentPageIndex > 1}
            canNextPage={currentPageIndex < controlledPageCount}
            pageCount={controlledPageCount}
            nextPage={() => setCurrentPageIndex((prevPageIndex) => prevPageIndex + 1)}
            previousPage={() => setCurrentPageIndex((prevPageIndex) => prevPageIndex - 1)}
          />
          <GreyDot style={{ marginLeft: 20, marginRight: 20, minWidth: 4, minHeight: 4 }} />
          {additionalHeaderActions}
          <TableColumnSettings
            showResultsDropdown={true}
            numberValue={pageSize}
            handleShowResultsChange={setPageSize ? (option) => setPageSize(option?.value) : null}
            columnsTitles={columnsTitles}
            allColumns={allColumns}
            resultsLabel="Customers on Page:"
            tableName="customers"
          />
        </Row>
      </TableHeaderActions>
      <TablePageSeparator />
      <ReactTable pageView={pageView} {...getTableProps()}>
        <ReactTableHeader pageView={pageView}>
          {headerGroups.map((headerGroup) => (
            <ReactTableHeaderRow pageView={pageView} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <ReactTableHeaderColumn
                  customWidth={column.width}
                  alignRight={column?.alignRight}
                  {...column.getHeaderProps(
                    column.id !== 'actions' && column.getSortByToggleProps({ title: undefined }),
                  )}
                >
                  <HeaderCellWrapper isSortable={column.canSort && !column.disableSortBy} isSorted={column.isSorted}>
                    {column.id === 'actions' ? <></> : column.render('Header')}

                    {column.isSorted && (
                      <SortIconWrapper isSortedDesc={column.isSortedDesc}>
                        <SortIcon />
                      </SortIconWrapper>
                    )}
                  </HeaderCellWrapper>
                </ReactTableHeaderColumn>
              ))}
            </ReactTableHeaderRow>
          ))}
        </ReactTableHeader>
        <ReactTableBody pageView={pageView} {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);

            return (
              <CustomerReactTableRow
                rowDepth={row.depth}
                backgroundColor={
                  row.original.selectedCustomer &&
                  page.some((row) => row.original.parentCustomer) &&
                  'var(--primaryBlack2)'
                }
                noBorderBottom={row.canExpand && row.isExpanded}
                isBlurred={isFetching}
                data-cy="customers-table__customer-row"
                {...row.getRowProps()}
              >
                {row.cells.map((cell) => (
                  <ReactTableCell customWidth={cell.column.width} {...cell.getCellProps()}>
                    {cell.render('Cell')}
                  </ReactTableCell>
                ))}
              </CustomerReactTableRow>
            );
          })}
          {!rows?.length && <ReportsPopoverEmptySearch onResetSearch={resetSearchQuery} />}
        </ReactTableBody>
      </ReactTable>
    </div>
  );
};

export const CustomersTable = ({
  dataForTable,
  columns,
  columnsTitles,
  searchQuery,
  customGlobalFilter,
  onSearchQueryChange,
  pageView,
  noInitialSort,
  additionalHeaderActions,
  pageCount,
  currentPageIndex,
  setCurrentPageIndex,
  sortBy,
  setSortBy,
  pageSize,
  setPageSize,
  isFetching,
  onVisibleColumnsChange,
}) => {
  const data = useMemo(() => {
    return dataForTable;
  }, [dataForTable]);

  return (
    <>
      {data && (
        <Table
          additionalHeaderActions={additionalHeaderActions}
          noInitialSort={noInitialSort}
          pageView={pageView}
          columns={columns}
          columnsTitles={columnsTitles}
          data={data}
          searchQuery={searchQuery}
          customGlobalFilter={customGlobalFilter}
          onSearchQueryChange={onSearchQueryChange}
          pageCount={pageCount}
          currentPageIndex={currentPageIndex}
          setCurrentPageIndex={setCurrentPageIndex}
          initialSortBy={sortBy}
          setSortBy={setSortBy}
          pageSize={pageSize}
          setPageSize={setPageSize}
          isFetching={isFetching}
          onVisibleColumnsChange={onVisibleColumnsChange}
        />
      )}
    </>
  );
};
