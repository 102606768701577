import React, { useState } from 'react';
import styled from 'styled-components';
import { useAuth0 } from '@auth0/auth0-react';
import { ReactComponent as UpperOverlay } from 'images/no-org-overlay1.svg';
import { ReactComponent as LowerOverlay } from 'images/no-org-overlay2.svg';
import { ReactComponent as SubscriptLogo } from 'images/subscript-logo-name.svg';
import { ReactComponent as FireIcon } from 'images/fire-icon.svg';
import subscriptLayout from 'images/subscript-no-org.png';
import { formatCustomerName } from 'utils/stringUtils';
import { resendVerificationEmail as _resendVerificationEmail } from 'api/users/requests';
import { NoOrgContainer, Content, InviteCard, RequestBtn } from './styles';

const ContactLink = styled.a`
  font-weight: normal;
  font-size: 14;
  font-family: Nunito Sans;
  text-align: center;
  text-decoration: underline;
  color: var(--primaryBlack);

  :hover {
    color: var(--primaryGreen);
  }
`;

const Clickable = styled.p`
  cursor: pointer;
  font-weight: normal;
  margin-top: 10;
  font-size: 1.2em;
  text-decoration: underline;
`;

function NoOrganization({ verifyEmail = false }) {
  const { user, logout } = useAuth0();
  const [resendingEmail, setResentingEmail] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const { email = '', name = '' } = user || {};

  const resendVerificationEmail = async () => {
    setResentingEmail(true);
    const response = await _resendVerificationEmail({ auth0UserId: user.sub });
    if (response.id) setEmailSent(true);
    setResentingEmail(false);
  };

  return (
    <NoOrgContainer>
      <UpperOverlay style={{ position: 'absolute', top: 0, left: 0 }} />
      <Content>
        <SubscriptLogo style={{ marginTop: 40 }} />
        <InviteCard className="flexer-col">
          {verifyEmail ? (
            <>
              <h2 className="invite-only-title">Please verify your email</h2>
              {resendingEmail ? (
                <span>Sending...</span>
              ) : !emailSent ? (
                <Clickable onClick={resendVerificationEmail}>Resend verification email</Clickable>
              ) : (
                <span>Verification email has been resent</span>
              )}
            </>
          ) : (
            <>
              <h2 className="invite-only-title">Subscript is invite-only for now</h2>
              <h4 className="invite-text">
                To join an invited organization on Subscript, please email{' '}
                <ContactLink href="mailto:help@subscript.com" className="mt-1">
                  help@subscript.com
                </ContactLink>{' '}
                and we'll get you fully set up!
              </h4>

              <RequestBtn href="https://form.typeform.com/to/TVDB7E17" target="_blank" className="flexer">
                <FireIcon style={{ marginRight: 4, height: '100%', width: '10%' }} />
                <h4 className="pm-0" style={{ color: 'white' }}>
                  Request an invite
                </h4>
              </RequestBtn>
            </>
          )}
          <div>
            <Clickable
              onClick={() =>
                logout({
                  returnTo: window.location.origin,
                })
              }
              style={{
                cursor: 'pointer',
                fontWeight: 'normal',
                marginTop: 10,
                fontSize: `1.2em`,
                textDecoration: 'underline',
              }}
            >
              Log out {name ? `(${formatCustomerName(name)})` : `(${email})`}
            </Clickable>
          </div>
        </InviteCard>
        <img src={subscriptLayout} alt="layout" style={{ position: 'absolute', bottom: 0, height: 400 }} />
      </Content>
      <LowerOverlay style={{ position: 'absolute', bottom: 0, right: 0 }} />
    </NoOrgContainer>
  );
}

export default NoOrganization;
