import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import overlay from 'images/recent_reports_overlay.svg';
import { AppContext } from 'AppContext';
import { useReportsAPI } from 'api/reports';
import { useNavigateToReportPage } from 'shared/Reports';
import { Centerer, Flexer, Spacer } from 'components/Core';
import { CircleLoader } from 'components/Loaders';
import { FoldersIcon, GreyDot } from 'components/Icons';

const Section = styled.div`
  width: 224px;
  height: 292px;
  padding: 12px 16px;
  background: url(${overlay}) no-repeat right bottom, var(--primaryBlack2);
  border-radius: 12px;

  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
`;

const TitleSection = styled(Flexer)`
  align-items: center;
  gap: 8px;
  opacity: 0.5;

  svg {
    width: 16px;
    height: 16px;

    path {
      fill: var(--primaryBlack);
    }
  }
`;

const SelectReportButton = styled(Flexer)`
  align-items: center;
  gap: 8px;
  color: var(--primaryBlack);
  cursor: pointer;

  &:hover {
    color: var(--primaryBlack50);
  }
`;

const SelectAllButton = styled.div`
  color: var(--primaryGreen);
  margin-left: 16px;
  cursor: pointer;

  &:hover {
    color: var(--secondaryGreen);
  }
`;

export const PopoverReportsSection = () => {
  const history = useHistory();
  const { orgId } = useContext(AppContext);
  const { navigateToReportPage } = useNavigateToReportPage();
  const { data, isLoading, isFetching, error } = useReportsAPI({ orgId });
  const reportsToShow = data
    ?.filter((report) => report.type !== 'snapshot')
    ?.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at))
    ?.slice(0, 4);

  if (error || reportsToShow?.length === 0) {
    return null;
  }

  return (
    <Section>
      {isLoading || isFetching ? (
        <Centerer>
          <CircleLoader />
        </Centerer>
      ) : (
        <div>
          <TitleSection>
            <FoldersIcon />
            Most Recent Reports
          </TitleSection>

          <Spacer height="20px" />

          <Flexer gap="16px" direction="column">
            {reportsToShow.map((report) => (
              <SelectReportButton key={report.id} onClick={() => navigateToReportPage({ report })}>
                <GreyDot size="4px" />
                {report.title}
              </SelectReportButton>
            ))}

            <SelectAllButton onClick={() => history.push('/reports')}>See All Reports ({data.length})</SelectAllButton>
          </Flexer>
        </div>
      )}
    </Section>
  );
};
