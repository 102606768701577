import React, { useContext, useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { Formik } from 'formik';
import { TEMPLATE_MODAL_DYNAMIC_TAGS } from 'consts/billing';
import {
  FILE_TYPES,
  FileUploader,
  FormikCustomInput,
  FormikEmailSelector,
  FormikOnFormChangeEffect,
  formatFileSize,
} from 'components/Controls';
import { BlueText, CentererVertical, FlexerColumn, FlexerRow, LabelText } from 'components/Core';
import { AppContext } from 'AppContext';
import { AIWandIcon, TrashIcon } from 'components/Icons';
import { generateAiReminderForCustomer } from 'api/billing/requests';
import { ReactComponent as WandIcon } from 'images/wand.svg';
import { useToasts } from 'components/Toasts';
import { CircleLoader } from 'components/Loaders';
import { SENDER_DEFAULT_EMAIL } from 'views/Configuration/Tabs/SubscriptBillingTab/BillingSenderSetttings/consts';
import { FileCard, RowSubtitle } from 'views/Configuration/Tabs/SubscriptBillingTab/styles';
import { InvoicingScheduleContext } from 'views/Billing/InvoicingScheduleModal/InvoicingScheduleContext';
import { BillingDynamicFieldsTooltip, DYNAMIC_FIELD_TOOLTIP_SECTIONS } from 'views/Billing/BillingDynamicFieldsTooltip';
import { templateToHTMLString } from 'utils/htmlUtils';
import { VALIDATION_REMIND_SCHEMA } from '../../schema';
import {
  getInvoiceNotSentReminders,
  getInvoiceSentReminders,
  hasReminderFailedToSend,
  shouldSendEmailFromSubscript,
} from '../../utils';
import { InvoiceSenderEmail } from '../InvoiceSenderEmail';
import { AITooltipContainer } from '../AITooltipContainer';

import { AiRegenerateButton } from './styles';

export const RemindForm = ({ invoice, setReminderFormValues, selectedReminder, sentPreview, reminderFormRef }) => {
  const { orgConfigs, orgId } = useContext(AppContext);
  const {
    customerDetails,
    setSelectedReminder,
    transactionMetadataTags,
    customerMetadataTags,
    refetchCurrentInvoice,
    refetchCustomer,
  } = useContext(InvoicingScheduleContext);
  const { pushToast } = useToasts();
  const availableTags = [...TEMPLATE_MODAL_DYNAMIC_TAGS, ...transactionMetadataTags, ...customerMetadataTags];

  const notSentReminders = useMemo(() => getInvoiceNotSentReminders({ invoice }), [invoice]);
  const sentReminders = useMemo(() => getInvoiceSentReminders({ invoice }), [invoice]);

  const [isRegenerating, setIsRegenerating] = useState(false);

  const sendEmailFromSubscript = shouldSendEmailFromSubscript({ orgConfigs });

  const isReminderGeneratedByAI = !!selectedReminder?.ai_generated;

  const defaultInvoiceAttachments = [{ name: 'Invoice.pdf' }];
  const handleAddInvoiceAttachment = ({ file, setFieldValue, values }) => {
    setFieldValue('attachments', [
      ...(values.attachments ?? []),
      {
        name: file.name,
        s3_file_key: file.s3FileKey,
        size: file.size,
        type: file.type,
      },
    ]);
  };
  const handleDeleteInvoiceAttachment = ({ index, values, setFieldValue }) => {
    const attachments = [...(values.attachments ?? [])];
    attachments.splice(index, 1);
    setFieldValue('attachments', attachments);
  };

  useEffect(() => {
    if (!selectedReminder || !notSentReminders?.some((reminder) => reminder.id === selectedReminder?.id))
      setSelectedReminder(notSentReminders?.[0]);
  }, [notSentReminders, setSelectedReminder, selectedReminder]);

  const handleGenerateReminder = async () => {
    setIsRegenerating(true);
    const generateResponse = await generateAiReminderForCustomer({ orgId, customerId: customerDetails?.id });

    if (generateResponse?.err) {
      pushToast(`Failed to generate reminder: ${generateResponse?.err}`, 'error');
    } else {
      pushToast(`Successfully generated reminder`, 'success');
    }
    setIsRegenerating(false);
    setSelectedReminder(null);
    await refetchCustomer();
    await refetchCurrentInvoice();
    setSelectedReminder(null);
  };

  const lastGeneratedDate = customerDetails?.invoicing_details?.last_ai_reminder_analysis_date
    ? dayjs(customerDetails?.invoicing_details?.last_ai_reminder_analysis_date).format('MMM D, YYYY: hh:mm A')
    : null;

  return (
    <Formik
      innerRef={reminderFormRef}
      initialValues={{
        ...selectedReminder,
        attachments: selectedReminder?.attachments ?? invoice?.attachments,
      }}
      enableReinitialize={true}
      validationSchema={VALIDATION_REMIND_SCHEMA}
      onSubmit={() => {}}
    >
      {({ values, setFieldValue }) => (
        <FlexerRow width="100%" gap="26px">
          <FlexerColumn width={sentPreview ? '70%' : '40vw'} gap="16px">
            <RowSubtitle style={{ marginBottom: -8, marginTop: 8 }}>Recipient(s)</RowSubtitle>
            <FormikEmailSelector
              name="to_emails"
              isDisabled={values?.sent_at}
              value={
                sentPreview
                  ? values?.sent_to?.filter((e) => typeof e === 'string')
                  : values?.to_emails?.filter((e) => typeof e === 'string')
              } // Strip out any non-string values
              onChange={(value) => setFieldValue('to_emails', value)}
            />
            <RowSubtitle style={{ marginBottom: -8, marginTop: 8 }}>CC</RowSubtitle>
            <FormikEmailSelector
              name="email_cc"
              isDisabled={values?.sent_at}
              value={
                sentPreview
                  ? values?.sent_cc_to?.filter((e) => typeof e === 'string')
                  : values?.email_cc?.filter((e) => typeof e === 'string')
              } // Strip out any non-string values
              onChange={(value) => setFieldValue('email_cc', value)}
            />

            <RowSubtitle style={{ marginBottom: -8, marginTop: 0, whiteSpace: 'nowrap' }}>
              <FlexerRow alignItems="center" gap="4px">
                Email subject
                {isReminderGeneratedByAI && !values?.sent_at && (
                  <AITooltipContainer
                    isVisible={isReminderGeneratedByAI}
                    content={
                      isRegenerating
                        ? 'Generating...'
                        : lastGeneratedDate
                        ? `The current reminder has been generated on ${lastGeneratedDate}`
                        : ''
                    }
                    showTag={!isRegenerating && !lastGeneratedDate}
                  >
                    {isRegenerating ? (
                      <CircleLoader width={15} height={15} />
                    ) : (
                      <AIWandIcon style={{ position: 'relative', display: 'block' }} />
                    )}
                  </AITooltipContainer>
                )}
              </FlexerRow>
              <CentererVertical gap="8px">
                <BillingDynamicFieldsTooltip
                  tags={{
                    [DYNAMIC_FIELD_TOOLTIP_SECTIONS.GENERAL_TAGS]: TEMPLATE_MODAL_DYNAMIC_TAGS,
                    [DYNAMIC_FIELD_TOOLTIP_SECTIONS.TRANSACTION_METADATA_TAGS]: transactionMetadataTags,
                    [DYNAMIC_FIELD_TOOLTIP_SECTIONS.CUSTOMER_METADATA_TAGS]: customerMetadataTags,
                  }}
                  XOffset={100}
                />

                {!!sentReminders?.length && !values?.sent_at && !isReminderGeneratedByAI && (
                  <i style={{ color: 'var(--primaryBlack)' }}>
                    Last reminder:{' '}
                    <BlueText>
                      {dayjs.utc(sentReminders[sentReminders.length - 1].sent_at).format('MMM DD, YYYY')}
                    </BlueText>
                  </i>
                )}
              </CentererVertical>
            </RowSubtitle>

            <FormikCustomInput
              data-cy="billing__reminder-modal__email-subject"
              name="subject"
              isDisabled={values?.sent_at}
              HTMLString={templateToHTMLString({
                text: sentPreview ? values?.subject_sent : values?.subject,
                availableTags,
              })}
              availableTags={availableTags}
              type="HTMLEdit"
              placeholder="Enter Email Subject..."
            />
            <RowSubtitle
              style={{ marginBottom: -8, marginTop: 0, whiteSpace: 'nowrap' }}
              data-cy="reminder__schedule-for"
            >
              <FlexerRow gap="4px">
                Email body
                {(isReminderGeneratedByAI || orgConfigs?.billingReminderDefaults?.ai_enabled) && !values?.sent_at && (
                  <AITooltipContainer
                    isVisible={isReminderGeneratedByAI || isRegenerating}
                    content={
                      isRegenerating
                        ? 'Generating...'
                        : lastGeneratedDate
                        ? `The current reminder has been generated on ${lastGeneratedDate}`
                        : ''
                    }
                    showTag={!isRegenerating && !lastGeneratedDate}
                  >
                    {isRegenerating ? (
                      <CircleLoader width={15} height={15} />
                    ) : (
                      <AiRegenerateButton onClick={handleGenerateReminder}>
                        <WandIcon style={{ position: 'relative', display: 'block' }} />
                        {isReminderGeneratedByAI ? 'Regenerate Reminder' : 'Regenerate with AI'}
                      </AiRegenerateButton>
                    )}
                  </AITooltipContainer>
                )}
              </FlexerRow>

              <CentererVertical gap="8px">
                <BillingDynamicFieldsTooltip
                  tags={{
                    [DYNAMIC_FIELD_TOOLTIP_SECTIONS.GENERAL_TAGS]: TEMPLATE_MODAL_DYNAMIC_TAGS,
                    [DYNAMIC_FIELD_TOOLTIP_SECTIONS.TRANSACTION_METADATA_TAGS]: transactionMetadataTags,
                    [DYNAMIC_FIELD_TOOLTIP_SECTIONS.CUSTOMER_METADATA_TAGS]: customerMetadataTags,
                  }}
                  XOffset={100}
                />
              </CentererVertical>
            </RowSubtitle>
            <FormikCustomInput
              data-cy="billing__reminder-modal__email-body"
              name="body"
              minHeight="186px"
              isDisabled={values?.sent_at}
              HTMLString={templateToHTMLString({
                text: sentPreview ? values?.body_sent : values?.body,
                availableTags,
              })}
              availableTags={availableTags}
              type="HTMLEdit"
              placeholder="Enter Email Body..."
            />
            <FormikOnFormChangeEffect onChange={(values) => setReminderFormValues(values)} />
          </FlexerColumn>

          <FlexerColumn width="270px" gap="16px">
            <InvoiceSenderEmail
              isInbound={!!selectedReminder?.is_inbound}
              senderEmail={!!selectedReminder?.is_inbound ? selectedReminder?.sender : SENDER_DEFAULT_EMAIL}
            />

            {(sentPreview || sendEmailFromSubscript) && (
              <FlexerColumn>
                <LabelText>
                  Attachments ({(defaultInvoiceAttachments?.length ?? 0) + (values.attachments?.length ?? 0)})
                </LabelText>
                <FlexerColumn gap="8px">
                  {(!values.sent_at || hasReminderFailedToSend({ reminder: values })) && !sentPreview && (
                    <FileUploader
                      onFileUpload={(file) => handleAddInvoiceAttachment({ file, setFieldValue, values })}
                      fileTypes={FILE_TYPES.ALL}
                      maxFileSize={10485760}
                      smallView
                      infoText={`Attach files (${formatFileSize(10485760)})`}
                      subInfoText="click or drag&drop"
                      showFileUploaded={false}
                      privateBucket
                    />
                  )}

                  {defaultInvoiceAttachments?.map((attachment, index) => (
                    <FileCard key={attachment.name}>
                      <span>
                        {attachment.name} {index !== 0 ? `(${formatFileSize(attachment.size)})` : ''}
                      </span>
                    </FileCard>
                  ))}
                  {values.attachments?.map((attachment, index) => (
                    <FileCard key={attachment.name}>
                      <span>
                        {attachment.name} {`(${formatFileSize(attachment.size)})`}
                      </span>
                      {!sentPreview && (
                        <TrashIcon
                          size="16px"
                          fill="var(--primaryBlack)"
                          onClick={() =>
                            handleDeleteInvoiceAttachment({
                              index,
                              values,
                              setFieldValue,
                            })
                          }
                        />
                      )}
                    </FileCard>
                  ))}
                </FlexerColumn>
              </FlexerColumn>
            )}
          </FlexerColumn>
        </FlexerRow>
      )}
    </Formik>
  );
};
