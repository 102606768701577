import axios from 'axios';
import { API_URL } from 'api/consts';

export const createReport = async ({ orgId, report, include }) => {
  const url = `${API_URL}/organizations/${orgId}/reports`;
  const { data } = await axios.post(url, report, {
    params: { include },
  });
  return data;
};

export const updateReport = async ({ orgId, reportId, report, include }) => {
  const url = `${API_URL}/organizations/${orgId}/reports/${reportId}`;
  const { data } = await axios.put(url, report, {
    params: { include },
  });
  return data;
};

export const getReports = async ({ orgId, filters, include }) => {
  const url = `${API_URL}/organizations/${orgId}/reports`;
  const { data } = await axios.get(url, {
    params: { ...filters, include },
  });
  return data;
};

export const getReport = async ({ orgId, reportId, include }) => {
  const url = `${API_URL}/organizations/${orgId}/reports/${reportId}`;
  const { data } = await axios.get(url, {
    params: { include },
  });
  return data;
};

export const deleteReport = async ({ orgId, reportId }) => {
  const url = `${API_URL}/organizations/${orgId}/reports/${reportId}`;
  const { data } = await axios.delete(url);
  return data;
};
