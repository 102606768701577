import { useContext, useMemo } from 'react';
import styled from 'styled-components';

import { getIntegrationDisplayName, getServiceCategory } from 'models/integration';
import { AppContext } from 'AppContext';
import { INTEGRATION_TYPES } from 'consts/integrations';
import { FormikCustomSelector } from 'components/Controls';
import { ReactComponent as SyncIcon } from 'images/refresh.svg';
import { useIntegrationInvoiceTemplates } from 'api/integrations/hooks';
import { Flexer } from 'components/Core';
import { TooltipContainer } from 'components/Tooltip';

import { SERVICE_WITH_INVOICE_TEMPLATES } from '../consts';

const IconWrapper = styled.div`
  cursor: pointer;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
`;

export const useExternalInvoiceTemplateSelector = ({ name, integrationId, onTemplatesFetch, label }) => {
  const { integrations, setIntegrations, orgId } = useContext(AppContext);

  const integration = integrations?.find((i) =>
    integrationId ? i.id === integrationId : i.type === INTEGRATION_TYPES.GL,
  );

  const {
    data: integrationWithInvoiceTemplates,
    operations: { refetch },
    isLoading,
  } = useIntegrationInvoiceTemplates({
    orgId,
    autoFetch: false,
    integrationId: integration?.id,
    onFetchSuccess: (integrationWithInvoiceTemplates) => {
      const templates = integrationWithInvoiceTemplates?.metadata?.invoice_templates ?? [];

      onTemplatesFetch
        ? onTemplatesFetch(templates)
        : setIntegrations(
            integrations.map((integration) =>
              integration.id === integrationWithInvoiceTemplates?.id
                ? {
                    ...integration,
                    metadata: {
                      ...integration?.metadata,
                      invoice_templates: templates,
                    },
                  }
                : integration,
            ),
          );
    },
  });

  const service = getServiceCategory(integration?.service);
  const displayName = getIntegrationDisplayName(integration);

  const hasInvoiceTemplates = SERVICE_WITH_INVOICE_TEMPLATES.includes(service);

  const externalInvoiceTemplateOptions = useMemo(
    () =>
      ((integrationWithInvoiceTemplates ?? integration)?.metadata?.invoice_templates ?? []).map(({ id, name }) => ({
        value: id,
        label: name,
      })),
    [integration, integrationWithInvoiceTemplates],
  );

  const ExternalInvoiceTemplateSelector = (props) =>
    hasInvoiceTemplates ? (
      <FormikCustomSelector
        isClearable
        label={
          <Flexer alignItems="center">
            <span>{label ?? `${displayName} invoice template`}</span>
            <TooltipContainer
              toolTipContent={isLoading ? 'loading...' : 'Refetch invoice templates'}
              fontSize="12px"
              width={160}
              tooltipWrapperStyles={{
                display: 'flex',
                alignItems: 'center',
              }}
              xOffset={5}
            >
              <IconWrapper disabled={isLoading} data-cy="invoice-templates-selector--refetch-button">
                <SyncIcon onClick={() => refetch()} />
              </IconWrapper>
            </TooltipContainer>
          </Flexer>
        }
        placeholder="Select template"
        name={name}
        options={externalInvoiceTemplateOptions}
        {...props}
      />
    ) : null;
  return {
    ExternalInvoiceTemplateSelector,
    externalInvoiceTemplateOptions,
    hasInvoiceTemplates,
  };
};
