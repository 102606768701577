import styled from 'styled-components';

export const StyledLink = styled.a`
  color: var(--primaryBlue);
  text-decoration: none;
  cursor: pointer;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
`;

export const SubText = styled.span`
  display: inline-block;
  color: var(--primaryBlack50);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`;

export const HighLightedText = styled.span`
  display: inline-block;
  color: var(--dark7);
  border-radius: 4px;
  padding: 2px;
  background-color: var(--accentGraySecond);
  font-weight: 700;
`;

export const FadedText = styled.span`
  color: var(--primaryBlack30);
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
`;

export const CountTag = styled.div`
  border-radius: 4px;
  background-color: var(--primaryBlue10);
  padding: 2px 5px;
  color: var(--primaryBlue);
  font-weight: 900;
  font-size: 9px;
`;
