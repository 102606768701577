import React, { useEffect, useState, useContext } from 'react';

import { AppContext } from 'AppContext';
import { SEGMENT_BY_OPTIONS, WATERFALL_UNSUPPORTED_SEGMENT_BY_OPTIONS, MAX_SEGMENTS_ALLOWED } from 'consts/global';
import { useClickOutside } from 'utils/hooks';
import { FillDot } from 'components/Icons';
import { DropdownSearchBar } from 'components/Blocks/SearchBar';
import { getSegmentLabel, getSegmentType, getSegmentTypeAbbreviation } from 'shared/MetadataSegmentsModal/utils';
import { TooltipContainer } from 'components/Tooltip';
import { InsightsContext } from 'shared/Insights/InsightsContext';
import { Spacer } from 'components/Core';

import {
  FilterConditionHead,
  SearchWrapperItem,
  SegmentLabelIcon,
  SegmentLabelWrapper,
  SelectorItem,
  StyledSelectorPopover,
} from '../styles';

const DEFAULT_SEGMENT_OPTIONS = [SEGMENT_BY_OPTIONS.PRODUCT, SEGMENT_BY_OPTIONS.PRODUCT_CATEGORY];

export const SegmentByPopover = ({
  selected,
  onSelect,
  segmentOptions,
  segmentName,
  showChangeCategory = true,
  showSize = true,
  showCustomerMetadata = true,
  showTransactionMetadata = true,
  showTransaction = false,
}) => {
  const { segmentHasInsights, orgHasInsights } = useContext(InsightsContext);
  const { orgConfigs } = useContext(AppContext);

  const {
    customerCustomFields,
    transactionCustomFields,
    customCustomerMetadataSegments,
    customTransactionMetadataSegments,
  } = orgConfigs;

  // regular customer segment options
  const customersMetadataSegmentsOptions =
    Object.keys(customerCustomFields).filter((key) => customerCustomFields[key].length <= MAX_SEGMENTS_ALLOWED) || {};

  // custom customer segment options
  const defaultCustomerCustomMetadataSegments = (customCustomerMetadataSegments || []).reduce((total, option) => {
    if (!Object.values(WATERFALL_UNSUPPORTED_SEGMENT_BY_OPTIONS).includes(option?.key)) {
      total.push(option.key);
    }
    return total;
  }, []);

  // regular transaction segment options
  const transactionsMetadataSegmentsOptions = Object.keys(transactionCustomFields || {});

  // custom transaction segment options
  const defaultTransactionCustomMetadataSegments = (customTransactionMetadataSegments || []).map(
    (option) => option.key,
  );

  useEffect(
    () => {
      if (segmentOptions) {
        setDefaultSearchOptions(segmentOptions);
        setFilteredSearchOptions(segmentOptions);
        return;
      }
      const options = global.structuredClone(DEFAULT_SEGMENT_OPTIONS);
      if (showTransaction) options.push(SEGMENT_BY_OPTIONS.TRANSACTION);
      if (showChangeCategory) options.push(SEGMENT_BY_OPTIONS.CHANGE_CATEGORY);
      if (showSize) options.push(SEGMENT_BY_OPTIONS.SIZE);
      if (showCustomerMetadata) {
        options.push(
          ...customersMetadataSegmentsOptions.map((option) => `${SEGMENT_BY_OPTIONS.CUSTOMER_METADATA}-${option}`),
        );
        options.push(
          ...defaultCustomerCustomMetadataSegments.map(
            (option) => `${SEGMENT_BY_OPTIONS.CUSTOMER_METADATA_CUSTOM}-${option}`,
          ),
        );
      }
      if (showTransactionMetadata) {
        options.push(
          ...transactionsMetadataSegmentsOptions.map(
            (option) => `${SEGMENT_BY_OPTIONS.TRANSACTION_METADATA}-${option}`,
          ),
        );
        options.push(
          ...defaultTransactionCustomMetadataSegments.map(
            (option) => `${SEGMENT_BY_OPTIONS.TRANSACTION_METADATA_CUSTOM}-${option}`,
          ),
        );
      }
      setDefaultSearchOptions(options);
      setFilteredSearchOptions(options);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [segmentOptions, showTransaction, showChangeCategory, showCustomerMetadata, showTransactionMetadata],
  );

  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useClickOutside(() => setShowDropdown(false));
  const [searchText, setSearchText] = useState('');
  const [defaultSearchOptions, setDefaultSearchOptions] = useState([]);
  const [filteredSearchOptions, setFilteredSearchOptions] = useState([]);

  const updateSearch = (text) => {
    setSearchText(text);
    setFilteredSearchOptions(
      defaultSearchOptions.filter((o) => getSegmentLabel(o).toLowerCase().includes(text.toLowerCase())),
    );
  };

  const optionsWithInsights = [];
  const optionsWithoutInsights = [];
  const chartType = segmentName.match(/cohort/) ? 'cohort' : segmentName.match(/waterfall/) ? 'waterfall' : null;
  filteredSearchOptions.forEach((option) => {
    if (segmentHasInsights({ segmentBy: option, chartType })) {
      optionsWithInsights.push(option);
    } else {
      optionsWithoutInsights.push(option);
    }
  });

  const OptionSelectorItems = ({ options }) =>
    (options ?? []).map((option) => (
      <SelectorItem
        key={option}
        current={selected === option}
        onClick={() => {
          onSelect(option);
          updateSearch('');
        }}
        data-cy={`segment-by__segment-option--${option}`}
      >
        <>
          {segmentHasInsights({ segmentBy: option, chartType }) ? (
            <>
              <FillDot size={'12px'} /> <Spacer width="2px" />
            </>
          ) : null}

          <TooltipContainer toolTipContent={getSegmentType(option)} width={100} tooltipWrapperStyles={{ zIndex: 1 }}>
            <SegmentLabelIcon>{getSegmentTypeAbbreviation(option)}</SegmentLabelIcon>
          </TooltipContainer>
          {getSegmentLabel(option)}
        </>
      </SelectorItem>
    ));

  return (
    <>
      <FilterConditionHead
        onClick={() => setShowDropdown(!showDropdown)}
        ref={dropdownRef}
        data-cy="segment-by__type-segment-toggler"
      >
        <SegmentLabelWrapper gap="8px" alignItems="center">
          {getSegmentLabel(selected) ?? 'Select Segment'}
          {orgHasInsights({ chartType }) ? <FillDot size={'12px'} /> : null}
        </SegmentLabelWrapper>

        {showDropdown && (
          <StyledSelectorPopover noOptionsStyles>
            <SearchWrapperItem onClick={(e) => e.stopPropagation()} data-cy="segment-by__search">
              <DropdownSearchBar
                width="100%"
                onChange={({ target }) => updateSearch(target.value)}
                value={searchText}
                onKeyPress={(e) => {
                  if (e.key === 'Enter' && filteredSearchOptions[0]) {
                    onSelect(filteredSearchOptions[0]);
                    updateSearch('');
                    setShowDropdown(false);
                  }
                }}
              />
            </SearchWrapperItem>
            {optionsWithInsights?.length > 0 && (
              <>
                <SelectorItem key={'with-insights-heading'}>With Insights</SelectorItem>
                <OptionSelectorItems options={optionsWithInsights} />
                <SelectorItem key={'without-insights-heading'}>Other</SelectorItem>
                <OptionSelectorItems options={optionsWithoutInsights} />
              </>
            )}

            {!optionsWithInsights?.length && <OptionSelectorItems options={filteredSearchOptions} />}
          </StyledSelectorPopover>
        )}
      </FilterConditionHead>
    </>
  );
};
