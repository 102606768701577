import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { AppContext } from 'AppContext';
import { checkIntegrationStatus } from 'models/integration';
import { UnconfirmedTransactionsBanner } from 'shared/Banners';
import { NoIntegrations, NoItems } from 'shared/ErrorBars';
import { GlobalTogglesContextProvider, GlobalTogglesFloatingContainer } from 'shared/GlobalToggles';
import { Filters } from 'shared/Filters';
import { GoalsBanner, GoalsProvider } from 'shared/Goals';
import { TimeLoader, TimeLoaderContainer } from 'components/Loaders';
import { Spacer } from 'components/Core';
import { BusinessSnapshotHamburgerButton } from './Common/EditMetricCard';
import { DashboardContext } from './DashboardContext';
import { DashboardOverview } from './DashboardOverview';
import { DashboardTables } from './DashboardTables';
import { UnprocessableDataError } from './Errors/UnprocessableDataError';

const Wrapper = styled.div`
  width: calc(100% + 60px);
  padding: 10px 40px 0 20px;
  margin-left: -20px;
  overflow-x: hidden;
`;

export const DashboardPage = () => {
  const { organizations, integrations } = useContext(AppContext);
  const pageState = useContext(DashboardContext);
  const {
    data,
    isLoading,
    error,
    refetchMonthlyBreakdown,
    isFirstLoad,
    dataFilter,
    metadataFilter,
    fetchDataError,
    exportScreenshotRef,
    setDataFilter,
    setDatesError,
    setFetchDataError,
    setMetadataFilter,
    hasConfirmedTransactions,
  } = pageState;

  const myOrganization = organizations?.[0];
  const hasIncompleteIntegration = checkIntegrationStatus({ integrations });
  const hasTransactions = myOrganization && parseInt(myOrganization.transactionCount) > 0;

  useEffect(() => {
    if (myOrganization && dataFilter.startMonth && dataFilter.endMonth) {
      if (dayjs(dataFilter.startMonth).startOf('month').diff(dayjs(dataFilter.endMonth).endOf('month'), 'month') >= 0) {
        setDatesError(true);
      } else {
        setDatesError(false);
      }
    }
  }, [myOrganization, dataFilter.startMonth, dataFilter.endMonth, setDatesError]);

  useEffect(() => {
    if (!error) return;
    setFetchDataError(true);
    console.error({ error, message: error.message, component: 'Dashboard' });
  }, [error, setFetchDataError]);

  if (integrations.length === 0 && !isLoading && !hasTransactions) {
    return <NoIntegrations />;
  }

  return (
    <Wrapper>
      {!hasConfirmedTransactions && !isLoading && (
        <UnprocessableDataError hasIncompleteIntegration={hasIncompleteIntegration} />
      )}

      <GlobalTogglesContextProvider urlState={pageState}>
        <Filters
          hidePeriodSelection
          showConditions
          showCommittedSwitch
          dataFilter={dataFilter}
          setDataFilter={setDataFilter}
          metadataFilter={metadataFilter}
          setMetadataFilter={setMetadataFilter}
        />
        {data?.hasUnconfirmedTransactions && <UnconfirmedTransactionsBanner />}
        {fetchDataError ? (
          <NoItems
            entityName="Data"
            filterState={dataFilter}
            buttonCb={refetchMonthlyBreakdown}
            organization={myOrganization}
          />
        ) : (
          <TimeLoaderContainer isFirstLoad={isFirstLoad} isLoading={isLoading}>
            {isLoading && <TimeLoader isFirstLoad={isFirstLoad} pageName="dashboard" />}
            <GoalsProvider>
              <Spacer height="28px" />
              <GoalsBanner revenueData={data} dataFilter={dataFilter} />
              <GlobalTogglesFloatingContainer showAfterScrolling={800}>
                <DashboardOverview exportScreenshotRef={exportScreenshotRef} />
                <DashboardTables
                  snapshotTableRows={pageState?.snapshotTableRows}
                  snapshotSelectedColumns={pageState?.snapshotSelectedColumns}
                  SettingsButton={BusinessSnapshotHamburgerButton}
                />
              </GlobalTogglesFloatingContainer>
            </GoalsProvider>
          </TimeLoaderContainer>
        )}
      </GlobalTogglesContextProvider>
    </Wrapper>
  );
};
