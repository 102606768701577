import { SNAPSHOT_METRICS } from 'consts/snapshotMetrics';
import _ from 'lodash';
import { assertCloseTo, assertNotGreaterThan } from 'utils/assertionUtils';
import { getYearAndQuarter, convertQuartersConfig } from 'utils/dateUtils';

const summaryMetricsThatShouldBeInBetween = [
  SNAPSHOT_METRICS.CAC_RECURRING.summaryDataKey,
  SNAPSHOT_METRICS.CAC_ALL.summaryDataKey,
  SNAPSHOT_METRICS.CEC_RECURRING.summaryDataKey,
];
const summaryMetricsThatShouldBeSameAsLastValue = [
  SNAPSHOT_METRICS.CAC_RECURRING_TTM.summaryDataKey,
  SNAPSHOT_METRICS.CAC_ALL_TTM.summaryDataKey,
  SNAPSHOT_METRICS.CEC_RECURRING_TTM.summaryDataKey,
  SNAPSHOT_METRICS.GROSS_CUSTOMER_RETENTION_TTM.summaryDataKey,
  SNAPSHOT_METRICS.GROSS_REVENUE_CHURN_RATE_TTM.summaryDataKey,
  SNAPSHOT_METRICS.GROSS_REVENUE_RETENTION_TTM.summaryDataKey,
  SNAPSHOT_METRICS.NET_REVENUE_RETENTION_TTM.summaryDataKey,
  SNAPSHOT_METRICS.ARPA_NEW_TTM.summaryDataKey,
];

// Invariant checkers
const checkUpForRenewalLessThanPreviousPeriodRecurring = ({ organization, user, data }) => {
  let lastMonth = null;
  for (const [month, revenueStats] of Object.entries(data)) {
    if (revenueStats[SNAPSHOT_METRICS.UP_FOR_RENEWAL.monthDataKey] && lastMonth !== null) {
      assertNotGreaterThan({
        organization,
        user,
        context: 'Dashboard',
        expected: data[lastMonth]?.[SNAPSHOT_METRICS.RECURRING_REVENUE.monthDataKey],
        actual: revenueStats[SNAPSHOT_METRICS.UP_FOR_RENEWAL.monthDataKey],
        description: `Up for renewal in ${month}`,
      });
    }
    lastMonth = month;
  }
};

const getMonthsFromSummaryCols = ({ monthsArray, quarters, quartersYearOffset }) => {
  const monthsByQuarter = {};
  const monthsByYear = {};
  for (const month of monthsArray) {
    const yearAndQuarter = getYearAndQuarter({
      quarterEndMonths: convertQuartersConfig(quarters),
      quartersYearOffset,
      year: parseInt(month.split('-')[0]),
      month: parseInt(month.split('-')[1]),
    });
    monthsByQuarter[`${yearAndQuarter.year}-Q${yearAndQuarter.quarter}`] =
      monthsByQuarter[`${yearAndQuarter.year}-Q${yearAndQuarter.quarter}`] ?? [];
    monthsByQuarter[`${yearAndQuarter.year}-Q${yearAndQuarter.quarter}`].push(month);
    monthsByYear[yearAndQuarter.year] = monthsByYear[yearAndQuarter.year] ?? [];
    monthsByYear[yearAndQuarter.year].push(month);
  }
  return { monthsByQuarter, monthsByYear };
};

const checkSummaryColumnsRelativeToMonthlyMetrics = ({ organization, user, data, quarters, quartersYearOffset }) => {
  const { calculatedSummaryStats, calculatedSaasStats } = data;
  const { monthsByQuarter, monthsByYear } = getMonthsFromSummaryCols({
    monthsArray: Object.keys(calculatedSaasStats),
    quarters,
    quartersYearOffset,
  });
  for (const [summaryPeriod, quarterSummary] of Object.entries(calculatedSummaryStats)) {
    // Skipping this for now
    if (summaryPeriod === 'CUMULATIVE') continue;
    const months = summaryPeriod.includes('Q')
      ? monthsByQuarter[summaryPeriod]
      : monthsByYear[summaryPeriod.split('-')[0]];
    if (![3, 12].includes(months?.length)) continue; // in case we don't have all the months in year or quarter

    for (const metric of summaryMetricsThatShouldBeInBetween) {
      if (_.isNil(quarterSummary[metric])) continue;
      const monthlyValues = months.map((month) => {
        return calculatedSaasStats[month][metric]?.value;
      });
      if (monthlyValues.some(_.isNil)) continue; // LTVs can be infinity for example
      //   Maximum of monthly values should be more than summary, and minimum should be less than summary
      assertNotGreaterThan({
        organization,
        user,
        context: 'Dashboard',
        expected: Math.max(...monthlyValues),
        actual: quarterSummary[metric].value,
        description: `${metric} in ${summaryPeriod}`,
      });
      assertNotGreaterThan({
        organization,
        user,
        context: 'Dashboard',
        expected: quarterSummary[metric].value,
        actual: Math.min(...monthlyValues),
        description: `${metric} in ${summaryPeriod}`,
      });
    }

    for (const metric of summaryMetricsThatShouldBeSameAsLastValue) {
      if (_.isNil(quarterSummary[metric])) continue;
      const summaryValue = quarterSummary[metric].value;
      const lastMonthValue = calculatedSaasStats[months[months.length - 1]][metric]?.value;
      // For TTM metrics if TTMMetrics is not included (not TTM metric selected) the .value will be NaN
      if (metric.includes('_TTM')) {
        if (_.isNaN(summaryValue)) continue;
        if (_.isNaN(lastMonthValue)) continue;
      }
      assertCloseTo({
        organization,
        user,
        context: 'Dashboard',
        expected: lastMonthValue,
        actual: summaryValue,
        description: `${metric} in ${summaryPeriod}`,
      });
    }
  }
};

const checkRetentionMetricLimits = ({ organization, data }) => {
  const { calculatedSaasStats, calculatedSummaryStats } = data;
  for (const [monthKey, data] of Object.entries(calculatedSaasStats)) {
    for (const grossRetentionMetric of [
      SNAPSHOT_METRICS.GROSS_REVENUE_RETENTION_TTM,
      SNAPSHOT_METRICS.GROSS_CUSTOMER_RETENTION_TTM,
      SNAPSHOT_METRICS.GROSS_CUSTOMER_RETENTION,
      SNAPSHOT_METRICS.GROSS_REVENUE_RETENTION,
    ]) {
      !_.isNil(data[grossRetentionMetric.monthDataKey]?.value) &&
        assertNotGreaterThan({
          organization,
          context: 'Dashboard',
          expected: 1,
          actual: data[grossRetentionMetric.monthDataKey]?.value,
          description: `${grossRetentionMetric.monthDataKey} in ${monthKey}`,
          errorMargin: 0,
        });
    }
  }

  for (const [periodKey, data] of Object.entries(calculatedSummaryStats)) {
    for (const grossRetentionMetric of [
      SNAPSHOT_METRICS.GROSS_REVENUE_RETENTION_TTM,
      SNAPSHOT_METRICS.GROSS_CUSTOMER_RETENTION_TTM,
      SNAPSHOT_METRICS.GROSS_CUSTOMER_RETENTION,
      SNAPSHOT_METRICS.GROSS_REVENUE_RETENTION,
    ]) {
      !_.isNil(data[grossRetentionMetric.monthDataKey]?.value) &&
        assertNotGreaterThan({
          organization,
          context: 'Dashboard',
          expected: 1,
          actual: data[grossRetentionMetric.summaryDataKey]?.value,
          description: `${grossRetentionMetric.summaryDataKey} in ${periodKey}`,
          errorMargin: 0,
        });
    }
  }
};

const checkRecurringCustomerVersusAllCustomerCounts = ({ organization, user, data, countInfluxAsRenewed }) => {
  const { customerNURDCStats, calculatedSummaryStats } = data;
  for (const [month, customerStats] of Object.entries(customerNURDCStats)) {
    const recurringCustomers = customerStats[SNAPSHOT_METRICS.RECURRING_CUSTOMERS.monthDataKey];
    const allCustomers = customerStats[SNAPSHOT_METRICS.CUSTOMERS.monthDataKey];
    const assertionPassed = assertNotGreaterThan({
      organization,
      user,
      context: 'Dashboard',
      expected: allCustomers,
      actual: recurringCustomers,
      description: `Month ${month}: {recurringCustomers: ${recurringCustomers}, allCustomers: ${allCustomers}}`,
    });
    if (!assertionPassed) return;
  }

  // When counting Influx as Renewed, sometimes recurring customers count can exceed all customers count
  // This is a known bug that we are choosing to suppress at the moment.
  // See https://github.com/ChifferCo/chiffer/issues/3853
  if (countInfluxAsRenewed) return;

  for (const [periodKey, summaryStats] of Object.entries(calculatedSummaryStats)) {
    const recurringCustomers = summaryStats[SNAPSHOT_METRICS.RECURRING_CUSTOMERS.summaryDataKey];
    const allCustomers = summaryStats[SNAPSHOT_METRICS.CUSTOMERS.summaryDataKey];
    const assertionPassed = assertNotGreaterThan({
      organization,
      user,
      context: 'Dashboard',
      expected: allCustomers,
      actual: recurringCustomers,
      description: `Period ${periodKey}: {recurringCustomers: ${recurringCustomers}, allCustomers: ${allCustomers}}`,
    });
    if (!assertionPassed) return;
  }
};

export {
  checkUpForRenewalLessThanPreviousPeriodRecurring,
  checkSummaryColumnsRelativeToMonthlyMetrics,
  checkRetentionMetricLimits,
  checkRecurringCustomerVersusAllCustomerCounts,
};
