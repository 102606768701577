import { FormikCustomRadio } from 'components/Controls';
import styled from 'styled-components';

const Wrapper = styled.div`
  padding: 28px;
  border-top: 1px solid var(--accentGraySecond);
  display: flex;
  flex-direction: column;
  box-shadow: 8px 8px 40px 0px var(--primaryBlack10);
`;

const Title = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: 20px;
  margin-bottom: 20px;
`;

export const ApplyToAllCheckboxes = ({
  isShowing,
  label,
  name,
  dataCy,
  changedEntity,
  checkboxTitleEntity = 'invoices',
}) => (
  <>
    {isShowing && (
      <Wrapper>
        <Title>{label ?? `How to update invoices to have the newly configured ${changedEntity}?`}</Title>
        <FormikCustomRadio
          flexDirection="column"
          data-cy={dataCy ?? 'configuration__update-existing-invoices-email-details-checkbox'}
          suffix={name}
          name={name}
          width="400px"
          options={[
            {
              label: (
                <div style={{ marginBottom: 16 }}>
                  Apply these settings{' '}
                  <b>
                    <i>only for new {checkboxTitleEntity} that will be created</i>
                  </b>
                </div>
              ),
              value: false,
            },
            {
              label: (
                <>
                  Update the settings for all my{' '}
                  <b>
                    <i>existing (unsent)</i>
                  </b>{' '}
                  {checkboxTitleEntity} and{' '}
                  <b>
                    <i>for new {checkboxTitleEntity} that will be created</i>
                  </b>
                </>
              ),
              value: true,
            },
          ]}
        />
      </Wrapper>
    )}
  </>
);
