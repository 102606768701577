import React, { useCallback, useState } from 'react';
import { EVENTS } from 'consts/analytics';
import { useAnalytics } from 'utils/hooks';
import { MetadataSectionActionsModal } from './MetadataSectionActionsModal';

export const useMetadataActionsModal = ({ organizationId, onClose }) => {
  const { trackEvent } = useAnalytics();
  const [showModal, setShowModal] = useState(false);
  const [modalAction, setModalAction] = useState();
  const [metadataType, setMetadataType] = useState();
  const [metadataItem, setMetadataItem] = useState();

  const openModal = useCallback(
    ({ action, metadataType, metadataItem }) => {
      trackEvent({
        name: EVENTS.OPEN_METADATA_ACTIONS_MODAL,
        properties: {
          action,
          metadataType,
          metadataItem,
        },
      });

      setModalAction(action);
      setMetadataType(metadataType);
      setMetadataItem(metadataItem);
      setShowModal(true);
    },
    [trackEvent, setModalAction, setShowModal],
  );

  const handleOnCloseModal = useCallback(
    (values) => {
      onClose(values);
      setShowModal(false);
    },
    [onClose, setShowModal],
  );

  const Modal = useCallback(
    () =>
      showModal ? (
        <MetadataSectionActionsModal
          organizationId={organizationId}
          modalAction={modalAction}
          metadataType={metadataType}
          actionMetadataItem={metadataItem}
          closeModal={handleOnCloseModal}
        />
      ) : (
        <></>
      ),
    [showModal, organizationId, modalAction, metadataType, metadataItem, handleOnCloseModal],
  );
  return {
    openCreateMetadataModal: ({ metadataType }) => openModal({ action: 'create', metadataType }),
    openUpdateMetadataModal: ({ metadataType, metadataItem }) =>
      openModal({ action: 'edit', metadataType, metadataItem }),
    MetadataActionsModal: Modal,
  };
};
