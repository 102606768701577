import styled from 'styled-components';
import { Flexer, FlexerColumn } from 'components/Core';

export const StyledCardHeader = styled(FlexerColumn)`
  position: relative;
  gap: 12px;
  padding: 20px;
  // These v1Compat is temporary while we don't ship the new dashboard grid in PRD
  padding-right: ${({ v1Compat }) => v1Compat && '0'};
  width: ${({ v1Compat }) => v1Compat && '100%'};
`;

export const CardTitle = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`;

export const TTMTag = styled.div`
  gap: 2px;
  padding: 4px 5px;
  border: 1px solid var(--primaryBlue);
  border-radius: 100px;
  color: var(--primaryBlue);

  cursor: pointer;
  text-transform: uppercase;
  font-weight: 900;
  font-size: 9px;
  line-height: 12px;

  &:hover {
    background: var(--primaryBlue10);
  }
`;

export const MainStats = styled(Flexer)`
  align-items: center;
  gap: 8px;
  font-weight: 900;
  font-size: 20px;
  line-height: 26px;
  color: var(--primaryBlack);

  svg {
    width: 12px;
    height: 12px;
  }
`;

export const Stats = styled.div`
  align-items: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--primaryBlack50);

  strong {
    color: var(--primaryBlack);

    &.neg {
      color: var(--primaryRed);
    }
  }
`;

export const NoDataFound = styled.h5`
  color: #c4c4c4;
  font-size: 12px;
`;

export const Dashed = styled.div`
  width: 20px;
  height: 2px;
  background-color: #c4c4c4;
  margin-bottom: 1px;
  margin-right: 5px;
`;
