import { useContext } from 'react';
import styled from 'styled-components';
import { AppContext } from 'AppContext';
import { useCustomerAPI } from 'api/customers';
import { useUrlQuery } from 'utils/hooks';
import { Centerer } from 'components/Core';
import { CircleLoader } from 'components/Loaders';
import { getCustomerDisplayName } from 'models/customer';
import { UsageEventsTableContainer } from 'views/Billing/UsageBasedEngine/UsageEvents';

const LoaderWrapper = styled(Centerer)`
  margin: 100px;
  width: 100%;
`;

export const BillingUsageEvents = () => {
  const { orgId } = useContext(AppContext);
  const query = useUrlQuery();
  const customerId = query.get('customerId');
  const { data: customerData, isLoading } = useCustomerAPI({
    orgId,
    customerId,
    enabled: !!customerId,
  });

  return isLoading ? (
    <LoaderWrapper>
      <CircleLoader name="billing-usage-events-page" />
    </LoaderWrapper>
  ) : (
    <UsageEventsTableContainer
      prefilteredEventName={query.get('eventName')}
      prefilteredCustomerId={customerId}
      prefilteredCustomerName={customerData ? getCustomerDisplayName({ customer: customerData }) : customerId}
      prefilteredStartDate={query.get('startDate')}
      prefilteredEndDate={query.get('endDate')}
    />
  );
};
