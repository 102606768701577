import React, { useContext } from 'react';
import styled from 'styled-components';
import { ReactComponent as EmptyCreditNotesIllustration } from 'images/empty-credit-notes-illustration.svg';
import { ReactComponent as PlusIcon } from 'images/plus-billing.svg';
import { Centerer, Flexer } from 'components/Core';
import { pluralize } from 'utils/stringUtils';
import { CreditNoteContext } from '../CreditNoteContext';
import { WhiteButtonScheduleHeader } from '../InvoicingScheduleTabsPanel/styles';

const Title = styled.div`
  font-size: 20px;
  font-style: normal;
  font-weight: 900;
  line-height: 26px;
`;

const Subtitle = styled.div`
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

export const CreditNoteEmptyState = () => {
  const { creditNotes, addDraftCreditNote } = useContext(CreditNoteContext);

  return (
    <Centerer width="100%" height="100%" direction="column" gap="20px" data-cy="credit-note-view__empty-state">
      <EmptyCreditNotesIllustration />

      <Centerer width="360px" direction="column" gap="12px">
        <Title>{pluralize(creditNotes?.length ?? 0, 'credit note')} in this schedule</Title>
        <Subtitle>To start using the credit notes functionality, create one by clicking on the button below</Subtitle>
      </Centerer>
      <Flexer>
        <WhiteButtonScheduleHeader onClick={addDraftCreditNote} data-cy="credit-note-view__empty-state__add-button">
          <PlusIcon />
          <span>Add Credit Note</span>
        </WhiteButtonScheduleHeader>
      </Flexer>
    </Centerer>
  );
};
