import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { ReportsContextProvider } from './ReportsContext';
import { ReportsList } from './ReportsList';
// import { ReportSingle } from './ReportSingle';

export const ReportsRouter = () => {
  const { path } = useRouteMatch();

  return (
    <ReportsContextProvider>
      <Switch>
        <Route path={path} component={ReportsList} exact />
      </Switch>
    </ReportsContextProvider>
  );
};
