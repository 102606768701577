import React, { useRef } from 'react';
import { InputNumber } from 'antd';
import { useField } from 'formik';
import styled, { css } from 'styled-components';
import { cssVar } from 'polished';
import { ReactComponent as TriangleIcon } from 'images/input_error_message_triagle.svg';
import { QuestionIcon } from 'components/Icons';
import { InputErrorMessage, LabelIconContainer } from 'components/Blocks';
import { TooltipContainer } from 'components/Tooltip';
import { ISO_CODE_TO_SYMBOL } from 'consts/global';
import { ContentEditableInput } from './ContentEditableInput';
import { StyledInputCSS } from '../styles';

export const StyledNumberInput = styled(InputNumber)`
  ${StyledInputCSS}

  position: relative;
  width: ${(props) => props.inputWidth ?? '100%'};
  min-width: 0;
  font-weight: ${(props) => (props.blueVer || props.bold) && 'bold'};
  font-size: ${({ fontSize }) => fontSize ?? '14px'};
  display: inline-flex;
  max-height: 38px;
  pointer-events: ${(props) => props.disabled && 'none'};
  height: ${(props) => props.blueVer && '24px'};
  border-radius: ${(props) => (props.blueVer ? '4px' : '8px')};
  border-color: ${({ isChanged }) => isChanged && 'var(--primaryYellow)'};
  .ant-input-number-input-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: ${(props) => props.inputWidth ?? '100%'};
  }

  .ant-input-number-handler-wrap {
    border-top-right-radius: ${(props) => (props.blueVer ? '4px' : '8px')};
    border-bottom-right-radius: ${(props) => (props.blueVer ? '4px' : '8px')};
  }

  .ant-input-number-input {
    height: ${(props) => props.blueVer && '24px !important'};
    text-align: ${({ inputTextAlign }) => inputTextAlign ?? 'left'};
    padding-right: 30px;
  }

  &.ant-input-number-focused {
    box-shadow: ${(props) => !props.blueVer && '3px 3px 10px var(--primaryBlack8)'};
  }

  &::placeholder {
    opacity: 0.3;
  }
`;

export const StyledInput = styled.input`
  ${StyledInputCSS}

  position: relative;
  width: ${(props) => props.inputWidth ?? '100%'};
  min-width: 0;
  padding: ${(props) => (props.type === 'color' ? '0' : '12px')};
  height: ${(props) => props.blueVer && '24px'};
  font-weight: ${(props) => (props.blueVer || props.bold) && 'bold'};
  font-size: 14px;
  border-radius: ${(props) => (props.blueVer ? '4px' : '8px')};
  transition: all 0.3s;
  display: inline-flex;
  max-height: 38px;
  pointer-events: ${(props) => props.disabled && 'none'};
  border-color: ${({ isChanged }) => isChanged && 'var(--primaryYellow)'};

  &::placeholder {
    opacity: 0.3;
  }
`;

const GROUPING_SIZE = 3;

/**
 * Formats the number with a maximum allowed decimal precision and a thousands separator.
 * Examples:
 *  - precision = 8, value = 1234.1234      -> "1,234.1234"
 *  - precision = 8, value = 1234.123456789 -> "1,234.12345679"
 *  - precision = 8, value = 1234           -> "1,234"
 *  - precision = 2, value = 1234.12345     -> "1,234.12"
 *  - precision = 2, value = 1234.1         -> "1,234.10"
 *  - precision = 2, value = 1234           -> "1,234.00"
 *
 * @param {number|string} value - The number to format.
 * @param {number} precision - The maximum number of decimal places to include.
 * @returns {string} - The formatted number as a string.
 */
export const numberFormatter = (value, precision) => {
  if (isNaN(value)) return value;
  if (value === null || value === undefined || value === '') return '';

  let [integerPart, decimalPart] = value.toString().split('.');

  if (precision && decimalPart?.length > precision) {
    decimalPart = Number('0.' + decimalPart)
      .toFixed(precision)
      .split('.')[1];
  }

  // Format the number with commas
  const formattedInteger =
    integerPart.replace(new RegExp(`\\B(?=(\\d{${GROUPING_SIZE}})+(?!\\d))`, 'g'), ',') +
    (decimalPart ? '.' + decimalPart : '');

  // Preserve period if value ends with one - applicable when user is typing
  if (value.toString().endsWith('.')) {
    return `${formattedInteger}.`;
  }

  return formattedInteger;
};

export const numberParser = (value) => value.replace(/,/g, '');

const StyledTextarea = styled.textarea`
  ${StyledInputCSS}

  position: relative;
  border-color: ${({ isChanged }) => isChanged && 'var(--primaryYellow)'};
  width: ${(props) => props.inputWidth ?? '100%'};
  min-width: 0;
  padding: 12px;
  font-weight: ${(props) => props.blueVer && 'bold'};
  font-size: 14px;
  border-radius: 8px;
  transition: all 0.3s;
  display: inline-flex;
  pointer-events: ${(props) => props.disabled && 'none'};

  &::placeholder {
    opacity: 0.3;
  }
`;

const CustomInput = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.labelDirection === 'left' ? 'row' : 'column')};
  width: ${(props) => props.width};
`;

const InputLabel = styled.label`
  font-size: 12px;
  line-height: 16px;
  position: relative;
  padding: 0px 4px;
  min-width: fit-content;

  pointer-events: ${(props) => props.disabled && 'none'};
  cursor: pointer;

  ${({ disableLabelOpacity }) =>
    disableLabelOpacity
      ? css`
          color: ${(props) => (props.isError ? 'var(--primaryRed)' : 'var(--primaryBlack40)')};
        `
      : css`
          color: ${(props) => (props.isError ? 'var(--primaryRed)' : 'var(--primaryBlack)')};
          opacity: ${(props) => (props.isError ? '1' : ' 0.4')};
        `};
`;

const InputWrapper = styled.div`
  position: relative;
`;

export const InputSuffix = styled.div`
  color: ${(props) => props.suffixColor};
  font-weight: bold;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  ${({ isSuffixStart }) =>
    isSuffixStart
      ? css`
          left: 10px;
        `
      : css`
          right: 10px;
        `}
`;

const InputSuffixBadge = styled.div`
  color: var(--primaryBlack);
  background: var(--primaryBlack5);
  opacity: 0.5;
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  right: 0.5em;
  width: 2em;
  height: 2em;
  border-radius: 4px;
  padding: 4px;
  &:hover {
    background: var(--primaryBlack10);
    opacity: 1;
  }
`;

const QuestionIconWrapper = styled.div`
  margin-bottom: -5px;
`;

// TODO: Rename to Input and split TextArea
const FormikCustomInput = ({
  label,
  handleChange,
  blueVer = false,
  suffix,
  isSuffixStart = false,
  customSuffixColor,
  suffixStyle,
  suffixBadgeText,
  suffixTooltipInputDisplay,
  errorWithoutTooltip = false,
  bold,
  HTMLString,
  availableTags,
  isDisabled,
  isChanged,
  tooltipInputDisplay,
  toolTipWidth,
  textarea,
  type,
  precision,
  floatErrors = false,
  errorBottomPosition,
  labelDirection = 'top',
  labelFlex = 'start',
  inputWidth,
  inputHeight,
  width,
  TopRightHeader,
  LabelAction = null,
  disableLabelOpacity,
  labelTooltipContent = null,
  ...props
}) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input>. We can use field meta to show an error
  // message if the field is invalid and it has been touched (i.e. visited)
  const [field, meta, helpers] = useField(props);

  const suffixColor =
    customSuffixColor ??
    (['%', ...Object.values(ISO_CODE_TO_SYMBOL)].includes(suffix) ? cssVar('--primaryGreen') : '#B3B3B3');

  const inputRef = useRef();

  const onInputChange = (value) => {
    if (handleChange) {
      handleChange(value);
    } else {
      helpers.setValue(value);
    }
  };

  const getInput = () => {
    if (textarea) {
      return (
        <StyledTextarea
          id={props.id ?? props.name}
          blueVer={blueVer}
          disabled={isDisabled}
          isChanged={isChanged}
          isError={meta.touched && meta.error}
          inputWidth={inputWidth}
          {...field}
          {...props}
          onChange={(event) => onInputChange(event.target.value)}
        />
      );
    } else if (type === 'number') {
      return (
        <>
          <StyledNumberInput
            isChanged={isChanged}
            ref={inputRef}
            id={props.id ?? props.name}
            blueVer={blueVer}
            bold={bold}
            disabled={isDisabled}
            isError={meta.touched && meta.error}
            inputWidth={inputWidth}
            {...field}
            {...props}
            formatter={(value) => numberFormatter(value, precision)}
            parser={numberParser}
            onChange={(value) => onInputChange(value)}
            size="large"
            {...(precision <= GROUPING_SIZE && { precision })}
          />
          {suffix && (
            <InputSuffix suffixColor={suffixColor} isSuffixStart={isSuffixStart} style={suffixStyle}>
              {suffix}
            </InputSuffix>
          )}
          {suffixBadgeText && (
            <TooltipContainer toolTipContent={suffixTooltipInputDisplay} width={200} direction="top" hideArrow>
              <InputSuffixBadge>{suffixBadgeText}</InputSuffixBadge>
            </TooltipContainer>
          )}
        </>
      );
    } else if (type === 'HTMLEdit') {
      return (
        <>
          <ContentEditableInput
            HTMLString={HTMLString}
            id={props.id ?? props.name}
            blueVer={blueVer}
            bold={bold}
            isChanged={isChanged}
            disabled={isDisabled}
            isError={meta.touched && meta.error}
            inputWidth={inputWidth}
            inputHeight={inputHeight}
            availableTags={availableTags}
            {...field}
            {...props}
            onChange={(htmlString) => onInputChange(htmlString)}
          />
          {suffix && (
            <InputSuffix suffixColor={suffixColor} isSuffixStart={isSuffixStart} style={suffixStyle}>
              {suffix}
            </InputSuffix>
          )}
        </>
      );
    } else {
      return (
        <>
          <StyledInput
            isChanged={isChanged}
            ref={inputRef}
            id={props.id ?? props.name}
            blueVer={blueVer}
            bold={bold}
            disabled={isDisabled}
            isError={meta.touched && meta.error}
            inputWidth={inputWidth}
            {...field}
            {...props}
            type={type}
            onChange={(event) => onInputChange(event.target.value)}
          />
          {suffix && (
            <InputSuffix suffixColor={suffixColor} isSuffixStart={isSuffixStart} style={suffixStyle}>
              {suffix}
            </InputSuffix>
          )}
        </>
      );
    }
  };
  return (
    <CustomInput labelDirection={labelDirection} width={width}>
      {label && (
        <LabelIconContainer labelDirection={labelDirection} labelFlex={labelFlex}>
          <InputLabel
            disabled={isDisabled}
            isError={meta.touched && meta.error}
            htmlFor={props.id ?? props.name}
            disableLabelOpacity={disableLabelOpacity}
          >
            {label}
          </InputLabel>
          {labelTooltipContent && (
            <TooltipContainer toolTipContent={labelTooltipContent}>
              <QuestionIconWrapper>
                <QuestionIcon stroke="var(--primaryBlack20)" />
              </QuestionIconWrapper>
            </TooltipContainer>
          )}
          {TopRightHeader}
          {LabelAction}
        </LabelIconContainer>
      )}
      {tooltipInputDisplay ? (
        <TooltipContainer toolTipContent={tooltipInputDisplay} width={toolTipWidth}>
          <InputWrapper>{getInput()}</InputWrapper>
        </TooltipContainer>
      ) : (
        <InputWrapper>{getInput()}</InputWrapper>
      )}
      {meta.touched && meta.error && !errorWithoutTooltip ? (
        <InputErrorMessage floatErrors={floatErrors} errorBottomPosition={errorBottomPosition}>
          <TriangleIcon />
          {meta.error}
        </InputErrorMessage>
      ) : null}
    </CustomInput>
  );
};

export default FormikCustomInput;
