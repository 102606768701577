export const getUrlParams = () => {
  let searchParams = new URLSearchParams(window.location.search);

  try {
    return Object.fromEntries(searchParams.entries());
  } catch {
    return {};
  }
};

export const replaceUrlParam = (changes) => {
  const urlParams = getUrlParams();
  const updatedUrlParams = new URLSearchParams({
    ...urlParams,
    ...changes,
  });
  window.history.replaceState(null, '', `${window.location.pathname}?${updatedUrlParams.toString()}`);
};

export const getURLWithoutParam = ({ param }) => {
  const url = new URL(window.location);
  let searchParams = new URLSearchParams(url.search);
  searchParams.delete(param);
  const query = searchParams.toString();
  if (query !== '') {
    return `${url.origin}${url.pathname}?${searchParams.toString()}`;
  }
  return `${url.origin}${url.pathname}`;
};

export const urlParamExists = ({ param }) => {
  const url = new URL(window.location);
  const searchParams = new URLSearchParams(url.search);
  return searchParams.has(param);
};
