import { useContext } from 'react';
import dayjs from 'dayjs';

import { AppContext } from 'AppContext';
import { SNAPSHOT_METRIC_BY_KEY } from 'consts/snapshotMetrics';
import { useCurrencyNumberFormatter } from 'utils/hooks';
import { TooltipContainer } from 'components/Tooltip';
import { DoughnutChart } from 'components/Graph/DoughnutChart';
import { DASHBOARD_CHART_CARDS_METRICS } from 'views/Dashboard/DashboardOverview/consts';
import { useCardChartModal } from 'views/Dashboard/Common/Card';
import { GoalsContext } from '../../GoalsContext';
import {
  GoalTitleWrapper,
  GoalAmount,
  GoalContents,
  GoalCurrent,
  GoalDate,
  GoalDetails,
  GoalIcon,
  GoalRow,
  GoalTitle,
  GoalTitlePill,
  StyledCheckIcon,
  StyledCrossIcon,
  StyledGoalCard,
} from './styles';

export const GoalCard = ({ revenueData, goal, index, editMode }) => {
  const {
    appSettings: { isARR },
  } = useContext(AppContext);
  const numberFormatter = useCurrencyNumberFormatter();
  const { openGoalModal } = useContext(GoalsContext);
  const { openModal: openCardChartModal, Modal: CardChartModal } = useCardChartModal();

  const metric = SNAPSHOT_METRIC_BY_KEY[goal.for_metric];
  const isChartCardMetric = DASHBOARD_CHART_CARDS_METRICS.includes(goal.for_metric.toUpperCase().replaceAll('-', '_'));

  const handleCardClick = () => {
    if (editMode || !isChartCardMetric) {
      openGoalModal({ goalId: goal.id });
    } else {
      openCardChartModal({
        metricKey: goal.for_metric,
        selectedGoalId: goal.id,
      });
    }
  };

  const currentMonthKey = dayjs().startOf('month').format('YYYY-MM');
  const firstPeriod = goal.periods[0];
  const lastPeriod = goal.periods[goal.periods.length - 1];
  // To keep compatibility with old Year or Quarter periods
  const monthsToAdd = lastPeriod.type === 'year' ? 12 : lastPeriod.type === 'quarter' ? 3 : 0;
  const lastPeriodMonthKey = dayjs(lastPeriod.start_month).add(monthsToAdd, 'month').startOf('month').format('YYYY-MM');

  const metricCategory = revenueData?.[metric.metricCategoryKey] ?? {};
  const actualCurrentPeriodAmount =
    metricCategory[currentMonthKey]?.[metric.monthDataKey]?.value ??
    metricCategory[currentMonthKey]?.[metric.monthDataKey];
  const actualLastPeriodAmount =
    metricCategory[lastPeriodMonthKey]?.[metric.monthDataKey]?.value ??
    metricCategory[lastPeriodMonthKey]?.[metric.monthDataKey];

  const metricCategoryMonths = Object.keys(metricCategory);
  const lastAvailableMonthKey = metricCategoryMonths[metricCategoryMonths.length - 1];
  const lastAvailableAmount =
    metricCategory[lastAvailableMonthKey]?.[metric.monthDataKey]?.value ??
    metricCategory[lastAvailableMonthKey]?.[metric.monthDataKey];

  const hasFinished = dayjs(lastPeriodMonthKey).isBefore(currentMonthKey);
  const goalAmount = hasFinished ? actualLastPeriodAmount : actualCurrentPeriodAmount;

  const differenceFromGoalStart = metric.isDecreasingMetric
    ? firstPeriod.value - lastPeriod.value
    : lastPeriod.value - firstPeriod.value;
  const differenceFromCurrentValue = metric.isDecreasingMetric
    ? firstPeriod.value - (goalAmount ?? lastAvailableAmount ?? 0)
    : (goalAmount ?? lastAvailableAmount ?? 0) - firstPeriod.value;

  const goalProgress =
    differenceFromCurrentValue <= 0 || differenceFromGoalStart <= 0
      ? 0
      : differenceFromCurrentValue / differenceFromGoalStart;
  const failed = hasFinished && goalProgress < 1;

  return (
    <>
      <StyledGoalCard onClick={handleCardClick}>
        <GoalTitleWrapper>
          <GoalTitle>{`${index === 0 ? 'Goals: ' : ''}${goal.name}`}</GoalTitle>
          {hasFinished && actualLastPeriodAmount && (failed ? <StyledCrossIcon /> : <StyledCheckIcon />)}
          {hasFinished && actualLastPeriodAmount && (
            <GoalTitlePill failed={failed}>{failed ? 'Not achieved' : 'Achieved'}</GoalTitlePill>
          )}
        </GoalTitleWrapper>
        <GoalContents>
          <GoalDetails>
            <GoalRow>
              <GoalIcon $failed={failed} />
              <div>
                <GoalAmount>
                  Goal:{' '}
                  {numberFormatter({
                    type: metric.dataType,
                    rawValue: lastPeriod.value * (isARR && metric.isARRMetric ? 12 : 1),
                  })}
                </GoalAmount>
                <GoalDate>The end of {dayjs(lastPeriodMonthKey).format('MMM YYYY')}</GoalDate>
              </div>
            </GoalRow>
            <TooltipContainer
              isVisible={!goalAmount}
              width={150}
              toolTipContent="Last available value from date filters"
            >
              <GoalRow>
                <GoalIcon $primary $failed={failed} />
                <GoalAmount>
                  {!goalAmount
                    ? dayjs(lastAvailableMonthKey).format('MMM YYYY')
                    : hasFinished
                    ? dayjs(lastPeriodMonthKey).format('MMM YYYY')
                    : 'Current'}
                  :{' '}
                  <GoalCurrent failed={failed}>
                    {numberFormatter({
                      type: metric.dataType,
                      rawValue: (goalAmount ?? lastAvailableAmount) * (isARR && metric.isARRMetric ? 12 : 1),
                    })}
                  </GoalCurrent>
                </GoalAmount>
              </GoalRow>
            </TooltipContainer>
          </GoalDetails>

          <DoughnutChart colorRed={failed} data={[goalProgress, goalProgress < 1 ? 1 - goalProgress : 0]} />
        </GoalContents>
      </StyledGoalCard>
      <CardChartModal />
    </>
  );
};
