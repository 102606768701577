import { useContext, useState } from 'react';
import { Formik } from 'formik';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { Row } from 'components/Core';
import { isEmpty, isObject, omit } from 'lodash';
import { useInvoicingSchedulesAPI } from 'api/billing';
import { COLORS } from 'consts/colors';
import { useClickOutside, useStateWithStorage, useToasts } from 'utils/hooks';
import { ReactComponent as SaveIcon } from 'images/device-floppy.svg';
import { FormikOnFormChangeEffect } from 'components/Controls';
import { INTERNAL_CUSTOMER_METADATA } from 'models/customer';
import { useCustomerAPI } from 'api/customers';

import { ApplyToAllPopover, ApplyToAllPopoverText } from './InvoiceScheduleWarnings/styles';
import { PanelHeaderButton } from './panel.styles';
import { StyledForm } from './InvoicingScheduleForm.styles';
import { formValidations } from './InvoicingScheduleForm.utils';
import { clearInvoiceItemsForBulkEdit, getClearedInvoiceChanges } from './utils';
import { InvoicingScheduleContext } from './InvoicingScheduleContext';
import { getInvoiceData, getInvoiceInitialValues, invoiceGenerateChanges } from '../InvoiceModal/utils';
import { INVOICE_ITEM_TYPE_TO_KEY } from '../consts';

dayjs.extend(utc);

export const InvoicingScheduleForm = ({ children }) => {
  const {
    orgId,
    currentInvoicingSchedule,
    onInvoicingScheduleCreated,
    performInvoicingScheduleUpdate,
    setLoading,
    scheduleFormRef,
    isScheduleDraft,
    setInvoicingScheduleFormValues,
    invoicingScheduleFormValues,
    includedTransactions,
    invoiceFormValues,
    fetchedSelectedInvoice,
    customerDetails,
  } = useContext(InvoicingScheduleContext);

  const [invoiceChanges, setInvoiceChanges] = useState(null);
  const [dismissedWarnings, setDismissedWarnings] = useStateWithStorage('storage-dismissed-warnings', []);

  const [showApplyToAllPopover, setShowApplyToAllPopover] = useState(false);
  const popoverRef = useClickOutside(() => setShowApplyToAllPopover(false));
  const toggleShowApplyToAllPopover = () => setShowApplyToAllPopover((prevValue) => !prevValue);

  const { pushToast } = useToasts();

  const {
    operations: { addInvoicingSchedule },
  } = useInvoicingSchedulesAPI({ orgId, autoFetch: false });

  const {
    operations: { edit: updateCustomer },
  } = useCustomerAPI({
    orgId,
    enabled: false,
    enableToasts: false,
  });

  const performInvoicingScheduleCreate = async (invoicingScheduleData) => {
    setLoading(true);

    try {
      const invoicingSchedule = await addInvoicingSchedule.mutateAsync({
        data: invoicingScheduleData,
      });

      onInvoicingScheduleCreated(invoicingSchedule);
    } catch (err) {
      console.error({ message: err.message, component: 'InvoicingScheduleModal', stack: err });
    }

    setLoading(false);
  };

  const handleFormSubmit = async ({ values, applyToAll = false, propInvoiceChanges }) => {
    try {
      const handler = currentInvoicingSchedule.id ? performInvoicingScheduleUpdate : performInvoicingScheduleCreate;

      const invoiceChanges = getInvoiceData(propInvoiceChanges);
      const changes = getClearedInvoiceChanges({ invoiceChanges });

      if (
        customerDetails?.metadata?.[INTERNAL_CUSTOMER_METADATA.SELECTED_GL_INTEGRATION_ID] !== values.integration_id
      ) {
        if (values.integration_id && customerDetails?.id) {
          await updateCustomer({
            id: customerDetails.id,
            data: {
              metadata: {
                [INTERNAL_CUSTOMER_METADATA.SELECTED_GL_INTEGRATION_ID]: values.integration_id,
              },
            },
            params: {
              overwriteMetadata: false,
            },
          });
        }
      }

      // clear all dismissed warnings after regenerate
      if (currentInvoicingSchedule?.id)
        setDismissedWarnings(
          dismissedWarnings?.filter(
            (warning) => !warning?.includes(`schedule-${values?.id}`) && typeof warning === 'string',
          ),
        );

      const normalizedValues = {
        ...omit(values, 'external_invoice_template_id'),
        invoices: values.invoices
          ? values.invoices.map((invoice) => {
              const isCurrentInvoice = isScheduleDraft
                ? invoiceFormValues?.unsavedId === invoice?.unsavedId
                : invoice.id && invoiceFormValues?.id === invoice.id;

              clearInvoiceItemsForBulkEdit({ invoiceChanges: changes, invoice });

              const invoiceData = getInvoiceData(
                isCurrentInvoice
                  ? invoiceFormValues
                  : applyToAll && !invoice.paid_at && !invoice.sent_at && !invoice.is_imported
                  ? { ...invoice, ...changes }
                  : invoice,
              );

              if (currentInvoicingSchedule?.external_invoice_template_id !== values.external_invoice_template_id) {
                invoiceData.metadata = {
                  ...invoiceData.metadata,
                  external_invoice_template_id: values.external_invoice_template_id,
                };
              }

              invoiceData.language = currentInvoicingSchedule.language;

              return invoiceData;
            })
          : values.invoices,
        transaction_ids: includedTransactions.map(({ id }) => id),
      };
      await handler(normalizedValues);
    } catch (err) {
      pushToast(`Failed to save invoicing schedule! ${err.message}`, 'error');
      console.error({ message: err.message, component: 'InvoicingScheduleForm', stack: err });
    }
  };

  return (
    <Formik
      innerRef={scheduleFormRef}
      initialValues={currentInvoicingSchedule}
      validationSchema={formValidations}
      enableReinitialize={true}
      onSubmit={(values) => {
        const initialInvoice = fetchedSelectedInvoice ?? {};
        let invoiceChanges = invoiceGenerateChanges({
          source: initialInvoice ? getInvoiceInitialValues(initialInvoice) : {},
          target: invoiceFormValues ? getInvoiceInitialValues(invoiceFormValues) : {},
          fields: Object.keys(initialInvoice ? getInvoiceInitialValues(initialInvoice) : {}).concat(
            Object.values(INVOICE_ITEM_TYPE_TO_KEY),
          ),
        });

        //We should not apply invoice metadata changes to all invoices because it mostly contains
        //uniq information job status or send errors
        delete invoiceChanges?.metadata;

        //if changes contains only one field with empty object it's usually a bug,
        //not real changes, for example 'invoiceChanges: custom_fields: {}'
        if (
          Object.keys(invoiceChanges)?.length === 1 &&
          isObject(Object.values(invoiceChanges)?.[0]) &&
          isEmpty(Object.values(invoiceChanges)?.[0])
        ) {
          invoiceChanges = {};
        }

        const isCreateMode = !values?.id;
        if (!isEmpty(invoiceChanges) && values?.invoices?.length > 1 && !isCreateMode) {
          setInvoiceChanges(invoiceChanges);
          setShowApplyToAllPopover(true);
        } else {
          handleFormSubmit({ values, applyToAll: false, propInvoiceChanges: null });
        }
      }}
    >
      {() => {
        return (
          <StyledForm>
            {children}

            <FormikOnFormChangeEffect onChange={(values) => setInvoicingScheduleFormValues(values)} />

            {showApplyToAllPopover && (
              <ApplyToAllPopover style={{ left: 130, top: 245 }} ref={popoverRef}>
                <SaveIcon />

                <ApplyToAllPopoverText>
                  Do you want to apply last changes to <b>all invoices in this series?</b>
                </ApplyToAllPopoverText>
                <Row>
                  <PanelHeaderButton
                    style={{ width: 74, marginLeft: 0 }}
                    data-cy="billing__invoice-modal__confirm--no-apply"
                    onClick={() => {
                      toggleShowApplyToAllPopover();
                      handleFormSubmit({
                        values: invoicingScheduleFormValues,
                        applyToAll: false,
                        propInvoiceChanges: invoiceChanges,
                      });
                    }}
                  >
                    No
                  </PanelHeaderButton>
                  <PanelHeaderButton
                    style={{ width: 74 }}
                    data-cy="billing__invoice-modal__confirm--apply-to-all"
                    onClick={() => {
                      toggleShowApplyToAllPopover();
                      handleFormSubmit({
                        values: invoicingScheduleFormValues,
                        applyToAll: true,
                        propInvoiceChanges: invoiceChanges,
                      });
                    }}
                    filled={true}
                    color={COLORS.GREEN}
                    active
                  >
                    Yes
                  </PanelHeaderButton>
                </Row>
              </ApplyToAllPopover>
            )}
          </StyledForm>
        );
      }}
    </Formik>
  );
};
