import React from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { ReactComponent as ArrowUp } from 'images/arrow_up_bold.svg';
import { ReactComponent as TooltipIcon } from 'images/help-table-header.svg';
import { ReactComponent as UpForRenewalIcon } from 'images/bell-ringing.svg';
import { ReactComponent as GreenCubeIcon } from 'images/green_cube_icon.svg';
import { ReactComponent as CaseIcon } from 'images/customer-case.svg';
import { NUMBER_FORMATS } from 'consts/global';
import { FlexBetweenContainer, Row, Centerer } from 'components/Core';
import { GreyDot } from 'components/Icons';
import { ReactTableColumnWithTooltip, TableSubRowsCount, TableSubRowsCountArrow } from 'components/Table';
import { TooltipContainer } from 'components/Tooltip';
import { getCustomerDisplayName } from 'models/customer';
import { useCurrencyNumberFormatter } from 'utils/hooks';
import { REVENUE_METRIC } from 'consts/revenueMetrics';
import { OutlineGreenButton } from 'components/Buttons';
import { RevenueCustomerTransactionsTable } from './RevenueCustomerTransactionsTable';
import { useInvariantCheck } from 'utils/hooks';
import { checkTotalTransactionAmount } from './invariantChecks';
import { UpForRenewalWrapper } from './styled';

const TableHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 22px;
`;

const TableWrapper = styled.div`
  padding: 0 40px 30px 60px;
  padding-top: 40px;
  border-top: 1px solid var(--primaryBlack3);
  position: relative;
`;

const CustomerName = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;

  span {
    color: var(--primaryBlue);
    font-weight: 700;
  }
`;

const CustomerNumberContainer = styled.div`
  display: flex;
  align-items: center;
`;

const CustomerLabel = styled.div`
  font-weight: 400;
  font-size: 20px;
  margin-left: 12px;
  line-height: 26px;
`;

const CustomerNumber = styled.div`
  padding: 8px 12px;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  margin-left: 12px;
  background: var(--primaryBlack2);
  border-radius: 100px;
`;

const CustomerLink = styled.span`
  cursor: pointer;
`;

const Currency = styled.div`
  text-align: right;
  width: 100%;
`;

const NoCustomersDataContainer = styled(Centerer)`
  border: 1px solid var(--accentGray);
  border-radius: 8px;
  padding: 64px;
  margin-top: 16px;
  margin-bottom: 16px;
  flex-direction: column;
`;

const NoCustomersDataHeader = styled.span`
  font-weight: 900;
  font-size: 14px;
`;

const NoCustomersDataDescription = styled.span`
  color: var(--primaryDarkGray);
  margin-bottom: 16px;
`;

const CustomerTransactions = ({
  transactionData,
  activeMetric,
  revenueMonth,
  previousMonth,
  viewAsARR,
  onTransactionClick,
  onCustomerClick,
  revenueDetailsMonth,
  revenueDetailsYear,
  optimisticAnalytics,
  setOptimisticAnalytics,
  upsellTypes,
}) => {
  const numberFormatter = useCurrencyNumberFormatter();
  const metricData = transactionData[activeMetric] ?? {};

  const metricDataEntries = Object.entries(metricData);

  const mappedMetricDataForTable = [];
  for (const [customerId, customer] of metricDataEntries) {
    const metricData = {
      id: customerId,
      ...customer,
      subRows: customer.metricSpreads,
    };
    if (activeMetric === REVENUE_METRIC.UPSELL.key) {
      // this logic picks which customer to include depending on the Upsell toggles
      if (upsellTypes.includes('renewal') && !metricData.nonRenewalUpsell) mappedMetricDataForTable.push(metricData);
      if (upsellTypes.includes('nonRenewal') && metricData.nonRenewalUpsell) mappedMetricDataForTable.push(metricData);
    } else {
      mappedMetricDataForTable.push(metricData);
    }
  }

  useInvariantCheck({
    readyData: mappedMetricDataForTable,
    checkers: [checkTotalTransactionAmount],
  });

  const numberOfCustomer = mappedMetricDataForTable.length;

  const today = new Date();
  const comparisonDate = new Date(`${revenueDetailsYear}-${revenueDetailsMonth}-01`);
  const currentMonthFormatted = dayjs(`${revenueDetailsYear}-${revenueDetailsMonth}-01`).format('MMM YYYY');
  const showOptimisticChurn =
    optimisticAnalytics && activeMetric === REVENUE_METRIC.CHURN.key && today < comparisonDate;

  const columns = React.useMemo(() => {
    const tableColumns = [];

    const nameColumn = {
      Header: 'Name',
      accessor: 'customer_name',
      width: 350,
      Cell: ({ row }) => {
        return row.canExpand ? (
          <CustomerName>
            <TableSubRowsCount
              isHidden={!row.isExpanded}
              data-cy={`customer-transactions-table__row__expander-column--${row.original.customer_name?.replaceAll(
                ' ',
                '-',
              )}`}
              {...row.getToggleRowExpandedProps()}
            >
              {row.original.metricSpreads.length}
              <TableSubRowsCountArrow isHidden={!row.isExpanded}>
                <ArrowUp />
              </TableSubRowsCountArrow>
            </TableSubRowsCount>
            <Row style={{ paddingLeft: 8 }}>
              {row.original.upForRenewal && activeMetric !== REVENUE_METRIC.CHURN.label ? (
                <>
                  <UpForRenewalWrapper>
                    <TooltipContainer width={105} fontSize="12px" toolTipContent="Up For Renewal">
                      <UpForRenewalIcon />
                    </TooltipContainer>
                  </UpForRenewalWrapper>
                  <GreyDot style={{ marginLeft: 8, marginRight: 8 }} />
                </>
              ) : (
                ''
              )}
              <CustomerLink
                data-cy="customer-transactions-table__row__customer-link-column"
                onClick={(e) => {
                  e.stopPropagation();
                  onCustomerClick(row.original.id);
                }}
              >
                {getCustomerDisplayName({
                  customerName: row.original.customer_name,
                  customerId: row.original?.id,
                  customerEmail: row.original?.email,
                })}
              </CustomerLink>
            </Row>
          </CustomerName>
        ) : (
          <CustomerName>
            <CustomerLink
              data-cy="customer-transactions-table__row__customer-link-column"
              onClick={(e) => {
                e.stopPropagation();
                onCustomerClick(row.original.id);
              }}
            >
              {getCustomerDisplayName({
                customerName: row.original.customer_name,
                customerId: row.original?.id,
                customerEmail: row.original?.email,
              })}
            </CustomerLink>
          </CustomerName>
        );
      },
    };

    const activeMetricColumn = {
      Header: () => <Currency> {activeMetric} </Currency>,
      accessor: 'metricValue',
      alignRight: true,
      width: 150,
      Cell: ({ cell: { value } }) => (
        <div
          style={{
            color:
              activeMetric === REVENUE_METRIC.CHURN.label || activeMetric === REVENUE_METRIC.DOWNSELL.label
                ? 'var(--primaryRed)'
                : 'var(--primaryGreen)',
            fontWeight: 900,
            textAlign: 'right',
            width: '100%',
          }}
        >
          {numberFormatter({ type: NUMBER_FORMATS.CURRENCY, rawValue: value })}
        </div>
      ),
    };

    const existingRRColumn = {
      Header: () => (
        <ReactTableColumnWithTooltip justifyContent="flex-end">
          <TooltipContainer
            width={200}
            isVisible={true}
            underline={false}
            toolTipContent={`${viewAsARR ? 'ARR' : 'MRR'} in ${dayjs(previousMonth).format(
              'MMM YYYY',
            )} that was carried over from ${dayjs(revenueMonth).format('MMM YYYY')}`}
          >
            <TooltipIcon />
          </TooltipContainer>

          {viewAsARR ? 'Existing ARR' : 'Existing MRR'}
        </ReactTableColumnWithTooltip>
      ),
      alignRight: true,
      accessor: 'existingRR',
      width: 150,
      Cell: ({ cell: { value } }) => (
        <Currency>{numberFormatter({ type: NUMBER_FORMATS.CURRENCY, rawValue: value })}</Currency>
      ),
    };

    const previousRRColumn = {
      Header: () => (
        <ReactTableColumnWithTooltip justifyContent="flex-end">
          <TooltipContainer
            width={120}
            isVisible={true}
            underline={false}
            toolTipContent={`For ${dayjs(previousMonth).format('MMM YYYY')}`}
          >
            <TooltipIcon />
          </TooltipContainer>

          {viewAsARR ? 'Old ARR' : 'Old MRR'}
        </ReactTableColumnWithTooltip>
      ),
      alignRight: true,
      accessor: 'previousRR',
      width: 150,
      Cell: ({ cell: { value } }) => (
        <Currency>{numberFormatter({ type: NUMBER_FORMATS.CURRENCY, rawValue: value })}</Currency>
      ),
    };

    const currentRRColumn = {
      Header: () => (
        <ReactTableColumnWithTooltip justifyContent="flex-end">
          <TooltipContainer
            width={120}
            isVisible={true}
            underline={false}
            toolTipContent={`For ${dayjs(revenueMonth).format('MMM YYYY')}`}
          >
            <TooltipIcon />
          </TooltipContainer>

          <Currency>{viewAsARR ? 'Current ARR' : 'Current MRR'}</Currency>
        </ReactTableColumnWithTooltip>
      ),
      alignRight: true,
      accessor: 'currentRR',
      width: 150,
      Cell: ({ cell: { value } }) => (
        <Currency>{numberFormatter({ type: NUMBER_FORMATS.CURRENCY, rawValue: value })}</Currency>
      ),
    };

    const totalContractColumn = {
      Header: () => (
        <ReactTableColumnWithTooltip justifyContent="flex-end">
          <TooltipContainer
            width={150}
            isVisible={true}
            underline={false}
            toolTipContent="Sum of contract values for recurring contracts"
          >
            <TooltipIcon />
          </TooltipContainer>
          Total
        </ReactTableColumnWithTooltip>
      ),
      alignRight: true,
      accessor: 'totalTransactionAmount',
      width: 150,
      Cell: ({ cell: { value } }) => (
        <Currency>{numberFormatter({ type: NUMBER_FORMATS.CURRENCY, rawValue: value })}</Currency>
      ),
    };

    tableColumns.push(nameColumn);
    tableColumns.push(activeMetricColumn);
    if (![REVENUE_METRIC.NON_RECURRING.label].includes(activeMetric)) {
      tableColumns.push(existingRRColumn);
      tableColumns.push(previousRRColumn);
      tableColumns.push(currentRRColumn);
    }
    tableColumns.push(totalContractColumn);

    return tableColumns;
  }, [activeMetric, revenueMonth, previousMonth, viewAsARR, onCustomerClick, numberFormatter]);

  return (
    <TableWrapper>
      <TableHeader>
        <FlexBetweenContainer style={{ width: '100%' }}>
          <CustomerNumberContainer>
            <CaseIcon />
            <CustomerLabel>Customers</CustomerLabel>{' '}
            <CustomerNumber data-cy="customer-transactions__customers-count">{numberOfCustomer}</CustomerNumber>
          </CustomerNumberContainer>
        </FlexBetweenContainer>
      </TableHeader>
      {mappedMetricDataForTable && !showOptimisticChurn && (
        <RevenueCustomerTransactionsTable
          columns={columns}
          activeMetric={activeMetric}
          revenueMonth={revenueMonth}
          dataForTable={mappedMetricDataForTable}
          onTransactionClick={onTransactionClick}
          upsellTypes={upsellTypes}
        />
      )}
      {showOptimisticChurn && (
        <NoCustomersDataContainer>
          <GreenCubeIcon style={{ marginBottom: 16 }} />
          <NoCustomersDataHeader>{`Churn in ${currentMonthFormatted} is $0`}</NoCustomersDataHeader>
          <NoCustomersDataDescription>
            When the optimistic metrics toggle is turned on, future months have no churn
          </NoCustomersDataDescription>
          <OutlineGreenButton
            onClick={() => setOptimisticAnalytics(false)}
            text={'Switch to pessimistic mode'}
            fontWeight="400"
          />
        </NoCustomersDataContainer>
      )}
      {!showOptimisticChurn && mappedMetricDataForTable.length === 0 && (
        <NoCustomersDataContainer>
          <GreenCubeIcon style={{ marginBottom: 16 }} />
          <NoCustomersDataHeader>{`${activeMetric} in ${currentMonthFormatted} is $0`}</NoCustomersDataHeader>
          <NoCustomersDataDescription>
            {`Your company doesn't have ${activeMetric} in ${currentMonthFormatted}`}
          </NoCustomersDataDescription>
        </NoCustomersDataContainer>
      )}
    </TableWrapper>
  );
};

export default CustomerTransactions;
