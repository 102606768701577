import React, { useEffect } from 'react';
import styled from 'styled-components';
import { anonymousIntegrationLogin } from 'api/integrations';
import { ErrorBar } from 'components/Blocks';
import { StepBody, WizardIntegrationButtonsContainer } from '../styles';
import { GLIntegrationButtons, CRMIntegrationButtons, BillingIntegrationButtons } from '../IntegrationButtons';
import { OAUTH_CALLBACK_PATH } from '../consts';

const ExternalAccountsWrapper = styled.div`
  padding: 0 100px;
`;

export const ExternalAccounts = ({
  user,
  selectedIntegration,
  setSelectedIntegration,
  integrationTypes,
  haveError,
  setReachedPage,
}) => {
  const handleConnect = () => {
    if (selectedIntegration) {
      anonymousIntegrationLogin(user.id, selectedIntegration, OAUTH_CALLBACK_PATH.OLD_WIZARD);
    }
  };

  useEffect(() => {
    setReachedPage(1);
  }, [setReachedPage]);

  return (
    <ExternalAccountsWrapper>
      <StepBody>
        Connect your <span>external source:</span>
      </StepBody>

      <WizardIntegrationButtonsContainer>
        <CRMIntegrationButtons
          handleConnect={handleConnect}
          selectedIntegration={selectedIntegration}
          setSelectedIntegration={setSelectedIntegration}
          disabled={integrationTypes.some((integration) => integration.type === 'CRM')}
          integrationTypes={integrationTypes}
        />
      </WizardIntegrationButtonsContainer>
      <WizardIntegrationButtonsContainer>
        <GLIntegrationButtons
          handleConnect={handleConnect}
          selectedIntegration={selectedIntegration}
          setSelectedIntegration={setSelectedIntegration}
          disabled={integrationTypes.some((integration) => integration.type === 'GL')}
          connectedIntegration={integrationTypes.find((integration) => integration.type === 'GL')?.service}
        />
        <BillingIntegrationButtons
          handleConnect={handleConnect}
          selectedIntegration={selectedIntegration}
          setSelectedIntegration={setSelectedIntegration}
          disabled={integrationTypes.some((integration) => integration.type === 'Billing')}
          connectedIntegration={integrationTypes.find((integration) => integration.type === 'Billing')?.service}
        />
      </WizardIntegrationButtonsContainer>

      {haveError && (
        <ErrorBar>
          <div style={{ width: '90%', marginLeft: 10 }}>
            <h5 className="mt-1" style={{ fontSize: 16 }}>
              An error occurred in setting up the integration 😓. Please press back and try again? If it keeps
              happening, please email us back with the URL that it goes to when you connect and we'll work on figuring
              it out right away!
            </h5>
          </div>
        </ErrorBar>
      )}
    </ExternalAccountsWrapper>
  );
};
