import React from 'react';
import styled from 'styled-components';
import noTransactionsImage from 'images/no_transactions.png';
import { ReactComponent as RefreshIcon } from 'images/refresh-icon.svg';
import { IconGreenButton } from 'components/Buttons';
import { Centerer } from 'components/Core/Flex';
import { SyncEntityButton, SYNC_ENTITIES } from 'shared/Common';

const Wrapper = styled.div`
  max-width: 220px;
  text-align: center;

  img {
    width: 160px;
    height: 170px;
    display: block;
    margin: 22px 0;
  }
`;

const WhiteRefreshIcon = styled(RefreshIcon)`
  margin-right: 10px;

  path {
    fill: white;
  }
`;

const Heading = styled.div`
  font-weight: 900;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 12px;
`;

const Desc = styled.p`
  font-size: 12px;
  line-height: 16px;
  opacity: 0.5;
  margin-bottom: 12px;
`;

export const NoItems = ({
  buttonCb,
  filterState,
  organization,
  cohortBy,
  syncEntityVer,
  entityName = 'Transactions',
}) => (
  <Centerer style={{ marginTop: 40 }}>
    <Wrapper className="flexer-col">
      <img src={noTransactionsImage} alt="notebook" />
      <Heading>{entityName} weren’t found</Heading>
      <Desc>You should change parameters of filters or select another time period</Desc>
      {syncEntityVer && entityName === 'Transactions' ? (
        <SyncEntityButton tableVer entity={SYNC_ENTITIES.TRANSACTIONS} />
      ) : (
        <IconGreenButton
          dataCy="no-items-error__refresh-button"
          onClick={() => {
            buttonCb({ organization, filterState, cohortBy });
          }}
          text="Refresh data"
          icon={<WhiteRefreshIcon />}
        />
      )}
    </Wrapper>
  </Centerer>
);
