import styled, { css } from 'styled-components';
import { Centerer, CentererVertical, FlexerColumn, Row } from 'components/Core';

export const PanelContainer = styled(FlexerColumn)`
  width: 340px;
  height: 100%;
  border-radius: 0px 8px 8px 0px;
  border: 1px solid var(--primaryBlack5);
  background-color: ${({ isFloating }) => (isFloating ? 'var(--primaryGray)' : 'var(--primaryBlack2)')};
  display: ${({ show }) => (show ? 'auto' : 'none')};
  position: ${({ isFloating }) => (isFloating ? 'absolute' : 'relative')};
  left: ${({ XOffset }) => XOffset};
  top: ${({ YOffset }) => YOffset};
  z-index: 30;

  ${({ isFloating }) =>
    isFloating &&
    css`
      &::before {
        content: '';
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        background-color: var(--primaryBlack2);
      }
    `}
`;

export const Header = styled(CentererVertical)`
  padding: 12px;
  justify-content: space-between;
  border-bottom: 1px solid var(--neutralGray);
  width: 100%;
`;

export const Body = styled(FlexerColumn)``;

export const TableHeader = styled.div`
  padding: 12px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--primaryBlack30);
  font-size: 9px;
  font-weight: 900;
  line-height: 12px;
  text-transform: uppercase;
  background: var(--primaryBlack3);
  border-bottom: 1px solid var(--neutralGray);
`;

export const TogglesDescription = styled.span`
  font-size: 12px;
  font-style: italic;
  line-height: 16px;
  opacity: 0.5;
`;

export const TransactionsList = styled(FlexerColumn)`
  height: 504px;
  overflow: auto;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
`;

export const FiltersTogglesWrapper = styled(Row)`
  justify-content: flex-end;

  > div {
    margin-left: 16px;
    white-space: nowrap;
  }
`;

export const Description = styled.div`
  font-style: italic;
  position: absolute;
  font-size: 12px;
  white-space: nowrap;
  line-height: 16px;
  padding: 8px 12px;
  color: white;
  border-radius: 8px;
  background: #757f8b;
  transform: translate(-50%, 0);
  left: 50%;
  bottom: -44px;
`;

export const TransactionsGroup = styled(FlexerColumn)`
  border-top: ${({ hasBorder }) => (hasBorder ? '1px solid var(--accentGraySecond)' : 'none')};
  padding-top: 16px;
  width: 100%;
`;

export const ExternalId = styled(CentererVertical)`
  margin-bottom: 8px;
  padding: 0 16px;

  span {
    color: var(--primaryBlack30);
    font-size: 9px;
    font-weight: 900;
    line-height: 12px;
    text-transform: uppercase;
  }
`;

export const TransactionItemWrapper = styled(FlexerColumn)`
  border-top: ${({ hasBorder }) => (hasBorder ? '1px solid var(--accentGraySecond)' : 'none')};
  padding: 12px 16px;
  opacity: ${({ disabled }) => disabled && 0.5};
`;

export const EmptyState = styled.div`
  color: var(--primaryBlack50);
  font-size: 12px;
  margin: 16px;
  line-height: 16px;
`;

export const Footer = styled(Centerer)`
  border-top: 1px solid var(--primaryBlack5);
  box-shadow: 8px 8px 60px 0px var(--primaryBlack4);
  padding: 12px;
`;

export const ConfirmButton = styled(Centerer)`
  cursor: pointer;
  width: 100%;
  color: var(--primaryBlue);
  font-size: 12px;
  font-weight: 700;
  padding: 16px 12px;
  margin-right: ${({ filled }) => !filled && '8px'};
  background-color: ${({ filled }) => filled && 'rgba(0, 117, 255, 0.10)'};
  border: ${({ filled }) => !filled && '1px solid rgba(0, 117, 255, 0.2)'};
  line-height: 16px;
  border-radius: 12px;
  opacity: ${({ disabled }) => disabled && '0.3'};
  pointer-events: ${({ disabled }) => disabled && 'none'};
`;
