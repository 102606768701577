import { Flexer } from 'components/Core';
import styled from 'styled-components';
import { TooltipContainer } from 'components/Tooltip';
import { NUMBER_FORMATS } from 'consts/global';
import { useCurrencyNumberFormatter } from 'utils/hooks';
import { ComparisonTooltipContent } from './ComparisonTooltipContent';
import { ValuesComparison } from './ValuesComparison';

const DEFAULT_TOOLTIP_WIDTH = 235;

const Wrapper = styled(Flexer)`
  &:hover {
    cursor: pointer;
  }
`;

// Convenience component to combine ValuesComparison and ComparisonTooltipContent
export const ValuesComparisonWithTooltip = ({
  originalValue,
  compareValue,
  dataType = NUMBER_FORMATS.CURRENCY,
  percentageToDisplay = null,
  tooltipWidth = DEFAULT_TOOLTIP_WIDTH,
  percentageDifferenceToShowValueComparison,
  ...tooltipProps
}) => {
  const numberFormatter = useCurrencyNumberFormatter();

  return (
    <TooltipContainer
      width={tooltipWidth}
      toolTipContent={
        <ComparisonTooltipContent
          originalValue={originalValue}
          compareValue={compareValue}
          dataType={dataType}
          {...tooltipProps}
        />
      }
    >
      <Wrapper>
        {percentageToDisplay
          ? numberFormatter({ type: NUMBER_FORMATS.PERCENT, rawValue: percentageToDisplay })
          : numberFormatter({ type: dataType, rawValue: originalValue })}
        <ValuesComparison
          originalValue={originalValue}
          compareValue={compareValue}
          percentageDifferenceToShowValueComparison={percentageDifferenceToShowValueComparison}
        />
      </Wrapper>
    </TooltipContainer>
  );
};
