import { useContext, useEffect, useMemo } from 'react';
import { useExpanded, useTable } from 'react-table';
import { Form, Formik } from 'formik';
import dayjs from 'dayjs';
import { AppContext } from 'AppContext';
import { Centerer, Spacer } from 'components/Core';
import { FillDot } from 'components/Icons';
import { TableHeaderColumn, TableHeaderRow, TableBody, TableHeader } from 'components/Table/CompactNumericTable';
import { NUMBER_FORMATS } from 'consts/global';
import { numberFormatter } from 'utils/formatters';
import { StyledTableWrapper, StyledTableRow, StyledTableCell, FirstColumn, Subtext } from './NewPipelineTable.styles';
import { CustomizableMetric } from '../Shared/CustomizableMetric';

export const NewPipelineTable = ({ forecastId, month, data, editForecastDetails }) => {
  const {
    appSettings: { isARR, currencyISOCode },
  } = useContext(AppContext);
  const handleSubmit = (values) => {
    editForecastDetails({
      forecastId,
      forecastMonth: month,
      forecastType: 'customers_new',
      customerId: 'new_pipeline',
      data: {
        recurring_amount: values.recurring_amount / (isARR ? 12 : 1),
        probability: values.probability / 100,
      },
    });
  };

  const columns = useMemo(
    () => [
      {
        Header: (
          <Centerer>
            <FillDot fill="var(--primaryYellow)" size="8px" />
            <Spacer width="8px" />
            <span>New pipeline to be closed</span>
            <Spacer width="8px" />
            <Subtext>(Average sales cycle: {(parseFloat(data.average_sales_cycle) ?? 56).toFixed(0)} days)</Subtext>
          </Centerer>
        ),
        accessor: 'average_sales_cycle',
        Cell: () => (
          <FirstColumn>
            <div>New pipeline generated in {dayjs(`${month}-15`).format('MMM YYYY')} </div>
            {/* FORECAST_AVERAGE_PIPELINE_MONTHS_INTO_PAST const value for now */}
            <Subtext>
              (estimate is based on new pipeline generated in the past 6 months and average new revenue growth since the
              beginning of forecast)
            </Subtext>
          </FirstColumn>
        ),
      },
      {
        Header: '',
        accessor: 'recurring_amount',
        Cell: ({ cell: { value } }) => (
          <CustomizableMetric name="recurring_amount" dataType={NUMBER_FORMATS.CURRENCY} value={value ?? 0} />
        ),
      },
      {
        Header: '',
        accessor: 'probability',
        Cell: ({ cell: { value } }) => (
          <CustomizableMetric
            name="probability"
            dataType={NUMBER_FORMATS.PERCENT}
            value={value ?? 0}
            explanation="The average likelihood new deals will be generated and closed"
          />
        ),
      },
      {
        Header: '',
        accessor: 'forecasted_amount',
        Cell: ({ cell: { value } }) =>
          numberFormatter({ type: NUMBER_FORMATS.CURRENCY, rawValue: value ?? 0, currency: currencyISOCode }),
      },
      {
        Header: '',
        accessor: 'forecast_month',
        Cell: () => null,
      },
      {
        Header: '',
        accessor: 'salesperson',
        Cell: () => null,
      },
    ],
    [month, data?.average_sales_cycle, currencyISOCode],
  );

  const dataForTable = useMemo(() => [data], [data]);

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows, toggleRowExpanded } = useTable(
    {
      columns,
      data: dataForTable,
      getSubRows: (row) => row.subRows || [],
    },
    useExpanded,
  );

  useEffect(() => {
    rows.forEach((row) => {
      toggleRowExpanded(row.id, true);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <StyledTableWrapper {...getTableProps()} data-cy="pipeline-customers-table">
      <TableHeader>
        {headerGroups.map((headerGroup) => (
          <TableHeaderRow {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <TableHeaderColumn {...column.getHeaderProps()} columnCount={columns.length}>
                {column.render('Header')}
              </TableHeaderColumn>
            ))}
          </TableHeaderRow>
        ))}
      </TableHeader>
      <TableBody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <Formik
              key={row.id}
              initialValues={{
                recurring_amount: row.original.recurring_amount,
                probability: row.original.probability * 100,
              }}
              onSubmit={handleSubmit}
              enableReinitialize={true}
            >
              <Form>
                <StyledTableRow {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <StyledTableCell {...cell.getCellProps()} columnCount={columns.length}>
                      {cell.render('Cell')}
                    </StyledTableCell>
                  ))}
                </StyledTableRow>
              </Form>
            </Formik>
          );
        })}
      </TableBody>
    </StyledTableWrapper>
  );
};
