import { connect } from 'formik';
import noop from 'lodash/noop';
import { useEffect } from 'react';

const FormikOnFormChangeEffect = ({ onChange = noop, formik }) => {
  const { values } = formik;

  useEffect(() => {
    onChange(values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  return null;
};

export default connect(FormikOnFormChangeEffect);
