import styled from 'styled-components';

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;

  & > * {
    margin-right: 8px;
    margin-left: 0;

    &:last-child {
      margin-right: 0;
    }
  }
`;

export const Toggles = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  padding: 22px 16px;
  align-items: center;
  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 16px;
  background-color: var(--primaryGray);
  border-top: 1px solid var(--accentGraySecond);
`;

export const ExchangeRow = styled.div`
  width: 100%;
  padding-left: 32px;
  margin-top: 12px;
`;
