import styled from 'styled-components';
import { FormikCustomInput, FormikCustomSelector } from 'components/Controls';

// TODO: MIGRATE TO ModalBodyMargin and delete
export const ModalBodyWrapper = styled.div`
  margin: 15px 0 30px 0;
`;

export const PageSelector = styled(FormikCustomSelector)`
  width: 200px;
`;

export const InputName = styled(FormikCustomInput)`
  width: 310px;
`;
