import styled from 'styled-components';
import { ReactComponent as Icon } from 'images/edit-circle.svg';

export const EditCircleIcon = styled(Icon)`
  width: ${({ size }) => size ?? '20px'};
  height: ${({ size }) => size ?? '20px'};

  stroke: ${({ stroke }) => stroke};
  path {
    fill: ${({ fill }) => fill};
  }

  &:hover {
    path {
      fill: ${({ hoverFill, fill }) => hoverFill ?? fill};
    }
  }
`;
