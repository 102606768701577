import React, { useContext } from 'react';

import { ReactComponent as EyeIcon } from 'images/toggles-eye.svg';
import { FillDot } from 'components/Icons';
import { Row } from 'components/Core';
import { FilterTextBlock } from 'shared/Filters/styles';
import { GlobalTogglesContext } from './GlobalTogglesContext';
import { ButtonsContainer, Toggles } from './GlobalToggles.styles';
import {
  GlobalToggleInflux,
  GlobalToggleIsARR,
  GlobalToggleIsCommitted,
  GlobalToggleIsOptimistic,
  GlobalToggleIsRollup,
} from './GlobalTogglesOptions';

const Separator = () => (
  <FillDot style={{ marginLeft: 12, marginRight: 12 }} width={5} height={5} fill="var(--neutralGray)" />
);

export const GlobalToggles = () => {
  const { draftGlobalToggles, setDraftGlobalToggles, committedPolicy, committedStart, showInfluxToggle } = useContext(
    GlobalTogglesContext,
  );

  return (
    <Toggles>
      <Row horizontal="start" style={{ width: '100%' }}>
        <FilterTextBlock>View Toggles</FilterTextBlock>
        <EyeIcon style={{ marginRight: 12 }} />
        <ButtonsContainer>
          <GlobalToggleIsCommitted
            globalTogglesState={draftGlobalToggles}
            setGlobalTogglesState={setDraftGlobalToggles}
            committedPolicy={committedPolicy}
            committedStart={committedStart}
          />
          <GlobalToggleIsARR globalTogglesState={draftGlobalToggles} setGlobalTogglesState={setDraftGlobalToggles} />
          <div>recurring revenue</div>

          <Separator />
          <GlobalToggleIsRollup globalTogglesState={draftGlobalToggles} setGlobalTogglesState={setDraftGlobalToggles} />

          <Separator />
          <GlobalToggleIsOptimistic
            globalTogglesState={draftGlobalToggles}
            setGlobalTogglesState={setDraftGlobalToggles}
          />
          <div>churn assumption</div>

          {showInfluxToggle && (
            <>
              <Separator />
              <GlobalToggleInflux
                globalTogglesState={draftGlobalToggles}
                setGlobalTogglesState={setDraftGlobalToggles}
              />
              <div>In Flux</div>
            </>
          )}
        </ButtonsContainer>
      </Row>
    </Toggles>
  );
};
