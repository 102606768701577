import { useCallback, useState } from 'react';
import { ContractAIPreviewModal } from './ContractAIPreviewModal';

export const useContractAIPreviewModal = ({ data, onSubmit }) => {
  const [showModal, setShowModal] = useState(false);

  const openModal = useCallback(() => {
    setShowModal(true);
  }, [setShowModal]);

  const Modal = useCallback(
    () =>
      showModal ? (
        <ContractAIPreviewModal
          data={data}
          onClose={() => setShowModal(false)}
          onSubmit={(data) => {
            setShowModal(false);
            onSubmit?.(data);
          }}
        />
      ) : null,
    [showModal, data, onSubmit],
  );

  return {
    openContractAIPreviewModal: openModal,
    ContractAIPreviewModal: Modal,
    isModalVisible: showModal,
  };
};
