import { MAGIC_METADATA } from 'consts/global';
import { INVOICE_ITEM_TYPES, MANUAL_GL_SERVICE_NAME, NO_GL_SERVICE_NAME } from 'views/Billing/consts';
import { downloadPdf } from 'utils/export';
import { convertToPlainText } from 'utils/htmlUtils';

export const buildCreditNoteItemsFromInvoice = (invoice, invoicingService) => {
  const creditNoteItems = [];
  let unallocatedAmount = invoice.amount;

  // If not using GL, distribute the tax line, since credit notes have per line item taxes
  const taxLine =
    invoice?.invoice_taxes ?? invoice?.invoice_items?.find((item) => item.type === INVOICE_ITEM_TYPES.TAXES);
  const taxAmount = taxLine?.amount || 0;
  const invoiceSubTotal = invoice?.invoice_items?.reduce(
    (acc, item) => (Object.values(INVOICE_ITEM_TYPES).includes(item.type) ? acc : acc + item.amount),
    0,
  );

  for (const invoiceItem of invoice?.invoice_items ?? []) {
    // Ignore special invoice items like TAXES, SHIPPING, FEES
    if (Object.values(INVOICE_ITEM_TYPES).includes(invoiceItem.type)) continue;

    const itemRatio = invoiceItem.amount / invoiceSubTotal;
    const amount = itemRatio * (unallocatedAmount - taxAmount);

    // If using a GL, then we'll have the GL calculate the tax amount
    const prorataTaxAmount = [MANUAL_GL_SERVICE_NAME, NO_GL_SERVICE_NAME].includes(invoicingService)
      ? taxAmount * itemRatio
      : undefined;

    creditNoteItems.push({
      transaction_id: null,
      product_id: invoiceItem.product_id,
      seats: invoiceItem.seats || 1,
      amount,
      tax_amount: prorataTaxAmount,
      external_tax_code: invoiceItem.metadata?.[MAGIC_METADATA.EXTERNAL_TAX_CODE],
    });
  }

  return creditNoteItems;
};

export const creditNotePDFDownload = ({ creditNotePdf }) =>
  downloadPdf({
    isLink: creditNotePdf?.is_pdf_link,
    pdf: creditNotePdf?.pdf,
    filename: `credit-note-${creditNotePdf?.credit_note_number || creditNotePdf?.id || ''}.pdf`,
  });

export const getCreditNoteData = (creditNote) => ({
  ...creditNote,
  memo: creditNote.memo ? convertToPlainText(creditNote.memo) : creditNote.memo,
  footer_memo: creditNote.footer_memo ? convertToPlainText(creditNote.footer_memo) : creditNote.footer_memo,
  email_subject: creditNote.email_subject ? convertToPlainText(creditNote.email_subject) : creditNote.email_subject,
  email_body: creditNote.email_body ? convertToPlainText(creditNote.email_body) : creditNote.email_body,
});
