/* Our data model on the backend is different than what we
 * directly show on the frontend, so we add the hydration
 * step to be able to translate frontend->backend and backend->frontend
 *
 * BTF = Backend to Frontend
 * We need to translate this way to show the user the proper data
 *
 * FTB = Frontend to Backend
 * We need to translate this way to have the API accept the data
 * */
export const durationToType = {
  1: 'month',
  3: 'quarter',
  12: 'year',
};

export const typeToDuration = {
  month: 1,
  quarter: 3,
  year: 12,
};

export const hydratePeriodBTF = ({ period }) => ({ ...period, type: durationToType[period.months_count] });

export const hydratePeriodsBTF = ({ periods }) => periods.map((period) => hydratePeriodBTF({ period }));

export const hydratePeriodFTB = ({ period }) => ({ ...period, months_count: typeToDuration[period.type] });

export const hydratePeriodsFTB = ({ periods }) => periods.map((period) => hydratePeriodFTB({ period }));
