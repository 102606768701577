import React from 'react';
import styled from 'styled-components';
import { Checkbox } from 'antd';

export const CustomInput = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  opacity: ${({ isDisabled }) => (isDisabled ? 0.5 : 1)};
  pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'default')};
`;

export const InputLabel = styled.label`
  font-size: ${(props) => props.fontSize ?? '12px'};
  line-height: 16px;
  color: ${({ color }) => color ?? 'var(--primaryBlack)'};
  margin-left: 8px;
  cursor: pointer;
  font-weight: ${(props) => (props.bold ? '800' : '400')};
`;

const CustomCheckbox = ({
  label,
  bold = false,
  fontSize,
  isDisabled,
  containerStyle,
  labelColor,
  labelStyle,
  ...props
}) => {
  return (
    <CustomInput isDisabled={isDisabled} style={containerStyle}>
      <Checkbox {...props} id={props.id || props.name} />
      {label && (
        <InputLabel
          htmlFor={props.id || props.name}
          bold={bold}
          fontSize={fontSize}
          color={labelColor}
          style={labelStyle}
        >
          {label}
        </InputLabel>
      )}
    </CustomInput>
  );
};

export default CustomCheckbox;
