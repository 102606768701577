import styled from 'styled-components';
import { FullscreenModal, ModalContainer, ModalCloseIcon } from 'components/Modal';

export const StyledFullscreenModal = styled(FullscreenModal)`
  background: var(--accentGrayFourth);
`;

export const ForecastModalLayout = ({ onClose, children, ...rest }) => (
  <ModalContainer {...rest}>
    <StyledFullscreenModal>
      {children}
      <ModalCloseIcon onClose={onClose} />
    </StyledFullscreenModal>
  </ModalContainer>
);

export const ForecastModalContents = styled.div`
  overflow: scroll;
`;

export const ForecastModalBody = styled.div`
  padding: 8px 40px;
`;
