import React, { useContext, useState } from 'react';
import styled from 'styled-components';

import { DirectionsIcon, FilePlus, ScaleIcon } from 'components/Icons';
import { COLORS } from 'consts/colors';
import { useClickOutside } from 'utils/hooks';
import { Flexer, FlexerColumn } from 'components/Core';
import { IconButton } from 'components/Buttons';
import { Popover, PopoverItemsContainer, PopoverWrapper } from 'components/Portal';
import { ForecastingContext } from '../ForecastingContext';
import { useCreateForecastModal } from './CreateForecastModal';
import { FORECAST_SCENARIO_TYPE } from './consts';

const StyledPopover = styled(Popover)`
  padding: 0;
`;

const PopoverItem = styled(Flexer)`
  cursor: pointer;
  padding: 16px 12px;

  svg {
    width: 24px;
    height: 24px;

    path {
      fill: var(--accentDarkGray);
    }
  }
`;

const PopoverItemTitle = styled.div`
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: var(--primaryBlack);
`;

const PopoverItemDescription = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--primaryBlack70);
`;

const StyledPopoverItemsContainer = styled(PopoverItemsContainer)`
  padding: 0;
  white-space: normal;

  &:first-child {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }

  &:last-child {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }

  &:hover {
    background: var(--primaryBlack3);

    ${PopoverItem} {
      svg {
        path {
          fill: var(--primaryBlack);
        }
      }
    }

    ${PopoverItemDescription} {
      color: var(--primaryBlack);
    }
  }
`;

export const AddScenarioButton = ({ primary }) => {
  const { setHighlightedScenario } = useContext(ForecastingContext);
  const { Modal: CreateForecastModal, openModal: openCreateForecastModal } = useCreateForecastModal({
    onForecastCreated: setHighlightedScenario,
  });
  const [showDropdown, setShowDropdown] = useState(false);
  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };
  const dropdownRef = useClickOutside(() => {
    setShowDropdown(false);
  });

  const handleOnClick = () => {
    toggleDropdown();
  };

  return (
    <>
      <PopoverWrapper ref={dropdownRef}>
        <IconButton
          icon={<FilePlus />}
          iconFillColor={primary ? '#FFF' : 'var(--primaryGreen)'}
          filled={primary}
          color={primary && COLORS.GREEN}
          onClick={handleOnClick}
          data-cy={'forecasting_add__toggler'}
        >
          Add Scenario
        </IconButton>
        {showDropdown && (
          <StyledPopover data-cy="forecasting_add__popover" width="240px" XOffset={primary ? 25 : 64}>
            <StyledPopoverItemsContainer
              data-cy="forecasting_add__popover__item--weighted"
              onClick={() => openCreateForecastModal(FORECAST_SCENARIO_TYPE.WEIGHTED)}
            >
              <PopoverItem>
                <ScaleIcon />
                <FlexerColumn>
                  <PopoverItemTitle>Based on weighted pipeline</PopoverItemTitle>
                  <PopoverItemDescription>
                    Using the weights (probabilities) of deals and their stages
                  </PopoverItemDescription>
                </FlexerColumn>
              </PopoverItem>
            </StyledPopoverItemsContainer>

            <StyledPopoverItemsContainer
              data-cy="forecasting_add__popover__item--unweighted"
              onClick={() => openCreateForecastModal(FORECAST_SCENARIO_TYPE.UNWEIGHTED)}
            >
              <PopoverItem>
                <DirectionsIcon />
                <FlexerColumn>
                  <PopoverItemTitle>Based on unweighted categories</PopoverItemTitle>
                  <PopoverItemDescription>
                    Using sales categories: Base case, Upside and Best case
                  </PopoverItemDescription>
                </FlexerColumn>
              </PopoverItem>
            </StyledPopoverItemsContainer>
          </StyledPopover>
        )}
      </PopoverWrapper>
      <CreateForecastModal />
    </>
  );
};
