import { useContext } from 'react';
import styled from 'styled-components';
import { Form, Formik } from 'formik';
import dayjs from 'dayjs';
import { useDiscountAPI } from 'api/usageBasedEngine';
import { getCustomerDisplayName } from 'models/customer';
import { AppContext } from 'AppContext';
import { ErrorBar } from 'components/Blocks';
import { CancelButton, RemoveButton, SaveButton } from 'components/Buttons';
import { FormikCustomInput } from 'components/Controls';
import { FlexBetweenContainer, FlexerColumn, FlexEndContainer } from 'components/Core';
import { Modal, ModalTitle, ModalBody, ModalCloseIcon, ModalContainer, ModalFooter } from 'components/Modal';
import { DiscountTypeValue } from './DiscountTypeValue';
import { FormRow } from './styles';

const StyledModalBody = styled(ModalBody)`
  padding-top: 16px;
`;

const DiscountTypeValueWrapper = styled(FormRow)`
  > div {
    width: 50%;
  }
`;

const WarningsWrapper = styled(FlexerColumn)`
  padding: 8px 12px 8px 0;
  font-size: 12px;

  > ul {
    margin: 0;
    padding: 0;

    > li {
      list-style-type: disc;
    }
  }
`;

const WarningTitle = styled.div`
  margin-left: -12px;
`;

export const DiscountEditModal = ({ discount, onClose, onDiscountUpdated }) => {
  const { orgId, dateFormat } = useContext(AppContext);

  const {
    data,
    operations: { editDiscount, deleteDiscount },
  } = useDiscountAPI({
    orgId,
    id: discount.id,
    params: {
      'scopes[]': 'usage_subscriptions',
    },
  });

  const handleSave = async (values) => {
    await editDiscount.mutateAsync({
      orgId,
      id: discount?.id,
      data: { code: values?.discount_code, type: values?.discount_type, value: values?.discount_value },
    });

    onClose();
    onDiscountUpdated?.();
  };

  const handleRemove = async () => {
    await deleteDiscount.mutateAsync({
      orgId,
      id: discount?.id,
    });

    onClose();
    onDiscountUpdated?.();
  };

  return (
    <ModalContainer>
      <Modal width="440px" height="auto" data-cy="discount-edit-modal">
        <ModalCloseIcon onClose={onClose} />
        <ModalTitle>Edit Discount</ModalTitle>
        {data?.usage_subscriptions?.length > 0 && (
          <ErrorBar iconSize="16px" showSplitter={false}>
            <WarningsWrapper>
              <WarningTitle>Updating or deleting will cascade changes to usage subscriptions:</WarningTitle>
              <ul>
                {data.usage_subscriptions.map(({ id, customer, pricing_plan, start_date }) => (
                  <li key={id}>
                    <b>{getCustomerDisplayName({ customer })}</b> subscribed to <i>{pricing_plan?.name}</i> since{' '}
                    {dayjs.utc(start_date).format(dateFormat ?? 'L')}
                  </li>
                ))}
              </ul>
            </WarningsWrapper>
          </ErrorBar>
        )}
        <Formik
          initialValues={{
            discount_code: discount?.code,
            discount_type: discount?.type,
            discount_value: discount?.value,
          }}
          onSubmit={handleSave}
        >
          {({ handleSubmit, dirty }) => (
            <Form>
              <StyledModalBody>
                <FormikCustomInput
                  label="Discount Code"
                  name="discount_code"
                  data-cy="formik-discount-section__discount-code"
                />
                <DiscountTypeValueWrapper>
                  <DiscountTypeValue fullyEditable />
                </DiscountTypeValueWrapper>
              </StyledModalBody>
              <ModalFooter>
                <FlexBetweenContainer>
                  <RemoveButton
                    onClick={handleRemove}
                    disabled={deleteDiscount.isLoading}
                    data-cy="discount-edit-modal__delete-button"
                  >
                    {deleteDiscount.isLoading ? 'Deleting' : 'Delete'}
                  </RemoveButton>
                  <FlexEndContainer>
                    <CancelButton onClick={onClose}>Cancel</CancelButton>
                    <SaveButton
                      onClick={handleSubmit}
                      loading={editDiscount.isLoading}
                      disabled={!dirty}
                      data-cy="discount-edit-modal__save-button"
                    />
                  </FlexEndContainer>
                </FlexBetweenContainer>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>
    </ModalContainer>
  );
};
