import dayjs from 'dayjs';
import * as yup from 'yup';
import { SENDER_OPTIONS } from './consts';

// Keep in sync with billingSenderDefaults at backend/src/components/integrations/consts.js
export const BILLING_SENDER_VALIDATOR = yup
  .object()
  .shape({
    sender: yup.string().oneOf(Object.values(SENDER_OPTIONS)),
    email_from: yup.string().when('sender', {
      is: (val) => [SENDER_OPTIONS.CUSTOM_EMAIL, SENDER_OPTIONS.SUBSCRIPT_EMAIL].includes(val),
      then: yup
        .string()
        .email('Must be a valid email')
        .typeError('Please enter a email')
        .required('Please enter a email'),
      otherwise: yup.string().nullable(),
    }),
    email_reply_to: yup.string().when('sender', {
      is: (val) => [SENDER_OPTIONS.CUSTOM_EMAIL, SENDER_OPTIONS.SUBSCRIPT_EMAIL].includes(val),
      then: yup
        .string()
        .email('Reply to must be a valid email')
        .typeError('Please enter a reply to email')
        .required('Please enter a reply to email'),
      otherwise: yup.string().nullable(),
    }),
    email_name: yup.string().when('sender', {
      is: (val) => [SENDER_OPTIONS.CUSTOM_EMAIL, SENDER_OPTIONS.SUBSCRIPT_EMAIL].includes(val),
      then: yup.string().typeError('Please enter a name').required('Please enter a name'),
      otherwise: yup.string().nullable(),
    }),
    show_po_number: yup.boolean().nullable(),
    show_service_dates: yup.boolean().nullable(),
    show_days_to_pay: yup.boolean().nullable(),
    primary_color: yup.string().nullable(),
    font: yup.string().nullable(),
    show_powered_by_subscript: yup.boolean().nullable(),
    show_quantity_column: yup.boolean().nullable(),
    show_rate_column: yup.boolean().nullable(),
    show_amount_column: yup.boolean().nullable(),
    tax_invoice_title: yup.string().nullable(),
    dates_format: yup
      .string()
      .nullable()
      .test('value', 'Please enter a valid date format', (value) => {
        if (!value) return true;
        try {
          dayjs(new Date()).format(value);
          return true;
        } catch (error) {
          return false;
        }
      }),
    tax_label: yup.string().nullable(),
    customer_tax_id_label: yup.string().nullable(),
    hide_subscript_footer: yup.boolean().nullable(),
    show_shipping_address: yup.boolean().nullable(),
    hide_bill_to_address: yup.boolean().nullable(),
  })
  .nullable();

export const billingSenderInitialValues = (initialValues) => ({
  sender: initialValues?.sender ?? 'gl',
  email_from: initialValues?.email_from ?? null,
  email_reply_to: initialValues?.email_reply_to ?? null,
  email_name: initialValues?.email_name ?? null,
  show_po_number: initialValues?.show_po_number ?? false,
  show_service_dates: initialValues?.show_service_dates ?? false,
  show_days_to_pay: initialValues?.show_days_to_pay ?? false,
  show_payment_link: initialValues?.show_payment_link ?? true,
  sender_mailer: initialValues?.sender_mailer ?? 'subscript',
  primary_color: initialValues?.primary_color ?? '#14ae65',
  font: initialValues?.font ?? 'Nunito Sans',
  show_powered_by_subscript: initialValues?.show_powered_by_subscript ?? true,
  show_quantity_column: initialValues?.show_quantity_column ?? true,
  show_amount_column: initialValues?.show_amount_column ?? true,
  show_rate_column: initialValues?.show_rate_column ?? false,
  tax_invoice_title: initialValues?.tax_invoice_title ?? 'INVOICE',
  dates_format: initialValues?.dates_format ?? 'MMMM D, YYYY',
  tax_label: initialValues?.tax_label ?? 'Total Tax',
  customer_tax_id_label: initialValues?.customer_tax_id_label ?? 'Tax ID',
  default_entity_id: initialValues?.default_entity_id ?? null,
  hide_subscript_footer: initialValues?.hide_subscript_footer ?? false,
  show_shipping_address: initialValues?.show_shipping_address ?? false,
  hide_bill_to_address: initialValues?.hide_bill_to_address ?? false,
});
