import React from 'react';
import styled, { keyframes } from 'styled-components';
import { ReactComponent as RecurringIcon } from 'images/refresh.svg';
import { CircleButton } from 'components/Buttons';

const StyledCircleButton = styled(CircleButton)`
  width: 40px;
  height: 40px;
  box-shadow: 4px 4px 28px rgba(0, 21, 46, 0.05);
  border: 1px solid var(--accentGraySecond);

  &:hover {
    box-shadow: 0px 4px 20px rgba(0, 21, 46, 0.1);
  }
`;

export const SyncButton = ({ onClick }) => (
  <StyledCircleButton
    backgroundColor="white"
    iconFillColor="var(--primaryBlack)"
    onClick={onClick}
    icon={<RecurringIcon />}
  />
);

const rotateAnimation = keyframes`
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
`;

const RotatingRecurringIcon = styled(RecurringIcon)`
  animation-name: ${rotateAnimation};
  animation-duration: 3s;
  animation-iteration-count: infinite;
`;

export const SyncingButton = () => (
  <StyledCircleButton backgroundColor="white" iconFillColor="var(--primaryBlack)" icon={<RotatingRecurringIcon />} />
);
