import React from 'react';
import { Row } from 'components/Core';
import { useToasts } from 'components/Toasts';
import {
  DynamicFields,
  DynamicFieldsTags,
  DynamicFieldsTitle,
  DynamicTag,
  DynamicFieldsHeader,
  DynamicFieldsInfo,
} from 'components/Blocks/DynamicFields';

export const DynamicFieldsSection = ({
  marginTop,
  marginBottom,
  title,
  generalTags,
  transactionMetadataTags,
  customerMetadataTags,
  transactionCustomerMetadataTags,
  invoiceItemsTags,
}) => {
  const { pushToast } = useToasts();
  const onClick = (tag) => () => {
    navigator.clipboard.writeText(tag);
    pushToast('Copied to clipboard successfully', 'success');
  };
  return (
    <DynamicFields marginTop={marginTop} marginBottom={marginBottom}>
      <DynamicFieldsTitle>
        <Row>{title}</Row>

        <DynamicFieldsInfo />
      </DynamicFieldsTitle>

      <DynamicFieldsTags>
        {generalTags.map((tag) => (
          <DynamicTag key={tag} onClick={onClick(tag)}>
            {tag}
          </DynamicTag>
        ))}
      </DynamicFieldsTags>

      {invoiceItemsTags && (
        <>
          <DynamicFieldsTitle>
            <DynamicFieldsHeader>Dynamic fields for invoice item description</DynamicFieldsHeader>
          </DynamicFieldsTitle>
          <DynamicFieldsTags>
            {invoiceItemsTags.map((tag) => (
              <DynamicTag key={tag} onClick={onClick(tag)}>
                {tag}
              </DynamicTag>
            ))}
          </DynamicFieldsTags>
        </>
      )}

      {transactionMetadataTags && (
        <>
          <DynamicFieldsTitle>
            <DynamicFieldsHeader>Transaction metadata tags</DynamicFieldsHeader>
          </DynamicFieldsTitle>
          <DynamicFieldsTags>
            {transactionMetadataTags.map((tag) => (
              <DynamicTag key={tag} onClick={onClick(tag)}>
                {tag}
              </DynamicTag>
            ))}
          </DynamicFieldsTags>
        </>
      )}

      {customerMetadataTags && (
        <>
          <DynamicFieldsTitle>
            <DynamicFieldsHeader>Customer metadata tags</DynamicFieldsHeader>
          </DynamicFieldsTitle>
          <DynamicFieldsTags>
            {customerMetadataTags.map((tag) => (
              <DynamicTag key={tag} onClick={onClick(tag)}>
                {tag}
              </DynamicTag>
            ))}
          </DynamicFieldsTags>
        </>
      )}

      {transactionCustomerMetadataTags && (
        <>
          <DynamicFieldsTitle>
            <DynamicFieldsHeader>Transaction customer metadata tags</DynamicFieldsHeader>
          </DynamicFieldsTitle>
          <DynamicFieldsTags>
            {transactionCustomerMetadataTags.map((tag) => (
              <DynamicTag key={tag} onClick={onClick(tag)}>
                {tag}
              </DynamicTag>
            ))}
          </DynamicFieldsTags>
        </>
      )}
    </DynamicFields>
  );
};
