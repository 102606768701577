import dayjs from 'dayjs';
import { deepConvertValues, deepEqual } from 'utils/objectUtils';

export const convertDateValues = (object) => {
  return deepConvertValues({
    object,
    predicate: (value) => value && typeof value !== 'boolean' && dayjs(value).isValid(),
    converter: (value) => dayjs(value).startOf('month').toDate(),
    excludeKeys: ['metadataFilter'],
  });
};

const reportCleanUp = (reportOptions) => {
  const optionsCopy = global.structuredClone(reportOptions);

  if (!optionsCopy.metadataFilter) {
    // If metadataFilter is not defined, we should set it to an empty object to avoid uncorectly comparing reports
    optionsCopy.metadataFilter = {};
  }

  return optionsCopy;
};

export const isReportModified = ({ reportOptions, changedReportOptions }) =>
  !deepEqual(convertDateValues(reportCleanUp(reportOptions)), convertDateValues(reportCleanUp(changedReportOptions)), {
    excludeNil: true,
  });
