import React, { useContext, useMemo } from 'react';
import { SNAPSHOT_METRICS } from 'consts/snapshotMetrics';
import { DashboardContext } from '../DashboardContext';
import { Card } from '../Common/Card';
import { PeriodGrowthDropdown } from '../Common/PeriodGrowthDropdown';
import { GROWTH_METRICS } from './consts';
import { calculateCardData } from './utils';

export const DashboardCard = ({ metricId, isTTM, ...rest }) => {
  const { isARR, loading, saasStats, customerNURDCStats, revenueNURDCStats, seatNURDCStats } = useContext(
    DashboardContext,
  );
  const snapshotMetric = SNAPSHOT_METRICS[metricId];
  const isARRValue = !!snapshotMetric.isARRMetric && isARR;

  const data = useMemo(() => {
    switch (snapshotMetric.metricCategoryKey) {
      case 'saasStats':
        return saasStats;
      case 'revenueStats':
        return revenueNURDCStats;
      case 'customerStats':
        return customerNURDCStats;
      case 'seatStats':
        return seatNURDCStats;
      default:
        return [];
    }
  }, [snapshotMetric, saasStats, revenueNURDCStats, customerNURDCStats, seatNURDCStats]);

  const cardData = useMemo(
    () =>
      calculateCardData({
        data,
        key: isTTM ? SNAPSHOT_METRICS[`${metricId}_TTM`].monthDataKey : snapshotMetric.monthDataKey,
      }),
    [data, isTTM, metricId, snapshotMetric],
  );

  const currentValue = (cardData?.currentValue ?? 0) * (isARRValue ? 12 : 1);
  const startingValue = (cardData?.startingValue ?? 0) * (isARRValue ? 12 : 1);

  return (
    <Card
      metricKey={isTTM ? SNAPSHOT_METRICS[`${metricId}_TTM`].key : snapshotMetric.key}
      loading={loading}
      currentValue={currentValue}
      startingValue={startingValue}
      graphData={cardData?.graphData || []}
      graphLabels={cardData?.graphLabels || []}
      ActionButton={
        GROWTH_METRICS.includes(snapshotMetric.key) ? (
          <PeriodGrowthDropdown name={`${snapshotMetric.key}-card`} showHasButton={true} />
        ) : null
      }
      {...rest}
    />
  );
};
