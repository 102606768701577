import React from 'react';

import { COLORS } from 'consts/colors';
import { ReactComponent as EmptyStateIllustration } from 'images/reports-popover-empty-state-illustration.svg';
import { IconButton } from 'components/Buttons';
import { FilePlus } from 'components/Icons';
import {
  EmptyStateContents,
  EmptyStateMainText,
  EmptyStateText,
  Step,
  StepsCircle,
  StepsWrapper,
} from 'shared/EmptyState';

export const ReportsPopoverEmptyState = ({ onCreateReport }) => (
  <EmptyStateContents>
    <EmptyStateIllustration />
    <EmptyStateMainText>Save reports for easy access in the future</EmptyStateMainText>

    <StepsWrapper>
      <Step>
        <StepsCircle>
          <span>1</span>
        </StepsCircle>
        <EmptyStateText>Create a new saved report by clicking</EmptyStateText>
      </Step>

      <Step>
        <StepsCircle>
          <span>2</span>
        </StepsCircle>
        <EmptyStateText>Name your future report</EmptyStateText>
      </Step>

      <Step>
        <StepsCircle>
          <span>3</span>
        </StepsCircle>
        <EmptyStateText>Adjust any filters and save the report</EmptyStateText>
      </Step>
    </StepsWrapper>

    <IconButton filled border color={COLORS.GREEN} icon={<FilePlus />} iconFillColor={'#FFF'} onClick={onCreateReport}>
      Create New Report
    </IconButton>
  </EmptyStateContents>
);
