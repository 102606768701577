import styled from 'styled-components';
import { useContext, useEffect } from 'react';

import { AppContext } from 'AppContext';
import { useIntegrationsAPI } from 'api/integrations';
import { FlexerRow } from 'components/Core';
import { ArrowNarrowLeftIcon } from 'components/Icons';
import { checkIfGmailDisconnected, getConfigSenderEmail, shouldSendEmailFromGmail } from '../utils';
import { InvoicingScheduleContext } from '../InvoicingScheduleModal/InvoicingScheduleContext';

const SenderLabel = styled.label`
  color: var(--primaryBlack40);
  font-size: 12px;
  line-height: 16px;
`;

const SenderInput = styled.input`
  background-color: var(--accentGrayFourth);
  color: ${(props) => (props.error ? 'var(--tertiaryRed)' : 'var(--primaryBlack)')};
  border: ${(props) => (props.error ? '1px solid var(--primaryRed)' : '1px solid var(--accentGraySecond)')};
  border-radius: 8px;
  display: inline-flex;
  font-size: 14px;
  margin-top: 8px;
  max-height: 38px;
  min-width: 0;
  padding: 12px;
  position: relative;
  width: 100%;
`;

const SenderSettings = styled.a`
  display: flex;
  align-items: center;
  color: var(--primaryBlue);
  font-size: 12px;
  font-weight: 700;

  svg {
    transform: rotate(180deg);

    path {
      fill: var(--primaryBlue);
    }
  }

  :hover {
    color: var(--secondaryBlue);

    svg {
      transform: rotate(180deg);

      path {
        fill: var(--secondaryBlue);
      }
    }
  }
`;

export const InvoiceSenderEmail = ({ senderEmail: passedSenderEmail, isInbound = false }) => {
  const { orgId, orgConfigs } = useContext(AppContext);
  const { setDisableSubmitButton } = useContext(InvoicingScheduleContext);
  const { data: disauthenticatedIntegrations = [] } = useIntegrationsAPI({
    orgId,
    params: {
      includeProducts: false,
      onlyDisauthenticated: true,
    },
  });

  const isGmailMailer = shouldSendEmailFromGmail({ orgConfigs });
  const senderEmail = isInbound
    ? passedSenderEmail
    : isGmailMailer
    ? getConfigSenderEmail({ orgConfigs })
    : passedSenderEmail;
  const isGmailDisconnected = isGmailMailer && checkIfGmailDisconnected({ disauthenticatedIntegrations });

  useEffect(() => {
    setDisableSubmitButton(isGmailDisconnected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGmailDisconnected]);

  return senderEmail ? (
    <div>
      <FlexerRow alignItems="center" justifyContent="space-between" minWidth="230px">
        <SenderLabel>Sender email</SenderLabel>
        {isGmailMailer && isGmailDisconnected && (
          <SenderSettings
            onClick={() => window.open('/configure?tab=subscript-billing&subTab=billing-invoice-settings', '_self')}
          >
            Go to Sender Settings
            <ArrowNarrowLeftIcon />
          </SenderSettings>
        )}
      </FlexerRow>

      <SenderInput
        name="sender"
        defaultValue={isGmailDisconnected ? 'No email' : senderEmail}
        error={isGmailDisconnected}
        disabled
      ></SenderInput>
    </div>
  ) : null;
};
