import { useContext } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { AppContext } from 'AppContext';
import { EXTERNAL_UPDATE_ACTION_OPTIONS, EXTERNAL_UPDATE_TYPES } from 'views/ExternalUpdates/consts';
import { Spacer, CentererVertical } from 'components/Core';
import { Banner, BannerText } from 'components/Blocks/Banner';
import { InlineButton } from 'components/Buttons';
import { useExternalUpdatesAPI } from 'api/externalUpdates';
import { EXTERNAL_UPDATES_SUPPORTED_RESOURCES } from 'views/ExternalUpdates/consts';
import { TransactionContext } from '../TransactionContext';
import { TRANSACTION_MODAL_MODE } from '../consts';

export const ReplacingTransactionLink = styled(Link)`
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  margin-left: 4px;
  color: var(--primaryBlue);
  cursor: pointer;

  &:hover {
    color: var(--secondaryBlue);
  }
`;

export const TransactionResyncNeededWarning = () => {
  const { mode, currentTransaction, setCurrentTransaction, setLoading } = useContext(TransactionContext);
  const { orgId } = useContext(AppContext);

  const {
    operations: { resolveExternalUpdate },
  } = useExternalUpdatesAPI({
    orgId,
    params: { page: 1, limit: 10 },
    objectType: EXTERNAL_UPDATES_SUPPORTED_RESOURCES.TRANSACTION,
  });

  if (mode === TRANSACTION_MODAL_MODE.EXTERNAL_UPDATE) {
    return null;
  }

  const resyncTypeUpdate = currentTransaction?.externalUpdates?.find(
    (externalUpdate) => externalUpdate.type === EXTERNAL_UPDATE_TYPES.resync,
  );

  const handleClick = async () => {
    setLoading(true);
    const result = await resolveExternalUpdate.mutateAsync({
      id: resyncTypeUpdate.id,
      data: { actionType: EXTERNAL_UPDATE_ACTION_OPTIONS.overwrite, includeResyncedTransactions: true },
    });
    setLoading(false);
    if (result && result.createdTransactions?.length) {
      setCurrentTransaction(result.createdTransactions[0]);
    }
  };

  return resyncTypeUpdate ? (
    <Banner marginTop="20px" tag="warning">
      <BannerText>
        <CentererVertical>
          This transaction needs to be resynced, since line items have changed upstream.
          <Spacer width="2px" />
          <InlineButton isSecondary onClick={handleClick}>
            Click to resync.
          </InlineButton>
        </CentererVertical>
      </BannerText>
    </Banner>
  ) : null;
};
