import React from 'react';
import dayjs from 'dayjs';
import { WarningBar } from 'components/Blocks';
import { INVOICE_MAGIC_METADATA } from 'views/Billing/consts';
import { hasInvoiceFailedToSave } from 'views/Billing/utils';

export const InvoiceFailedToSaveWarning = ({ invoice }) => {
  const invoiceHasFailed = hasInvoiceFailedToSave({ invoice });
  if (!invoiceHasFailed) return null;

  const warnings = [
    <>
      <b>Failed to save</b> invoice on{' '}
      {dayjs.utc(invoice.metadata?.[INVOICE_MAGIC_METADATA.LAST_SAVE_INVOICE_FAILED_AT]).format('MMM DD, YYYY')}.
      <br />
      <b>Reason:</b>{' '}
      <i>
        {invoice.metadata?.[INVOICE_MAGIC_METADATA.LAST_SAVE_INVOICE_ERROR]}
        <b />
      </i>
    </>,
  ];

  return <WarningBar fullWidth warnings={warnings} />;
};
