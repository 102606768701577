import styled from 'styled-components';
import { cssVar, transparentize } from 'polished';
import { ReactComponent as ArrowRight } from 'images/arrows-bold-right-transparent-icon.svg';
import { CentererVertical, FlexBetweenContainer, Flexer, FlexerColumn } from 'components/Core';

export const ReportsPopoverStyledButton = styled(CentererVertical)`
  height: 24px;
  padding: 4px 8px;
  font-weight: 900;
  font-size: 10px;
  line-height: 14px;
  text-transform: uppercase;
  cursor: pointer;
  color: ${(props) => (props.active || props.warn ? 'var(--primaryBlack)' : 'var(--primaryBlack30)')};

  border: ${(props) => (props.warn ? '1px solid var(--primaryYellow)' : '1px solid transparent')};
  box-sizing: border-box;
  border-radius: 100px;
  background: ${(props) => (props.warn ? transparentize(0.85, cssVar('--primaryYellow')) : 'transparent')};

  svg {
    margin-left: 4px;

    g {
      opacity: 1;
    }

    path {
      fill: ${(props) => (props.active || props.warn ? 'var(--primaryBlack)' : 'var(--primaryBlack30)')};
    }
  }
`;

export const ReportsPopoverContents = styled.div`
  width: 740px;

  background: #fff;

  border: 1px solid var(--accentGray);
  box-sizing: border-box;

  box-shadow: 15px 15px 40px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
`;

export const ReportsPopoverHeader = styled(CentererVertical)`
  height: 56px;
`;

export const ReportsPopoverHeaderLeft = styled(FlexBetweenContainer)`
  flex: 1;
  align-items: center;
  padding: 16px;
`;

export const ReportsPopoverHeaderTitle = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: var(--primaryBlack);
  margin: 0 8px;
`;

export const ReportsPopoverHeaderSearch = styled(Flexer)`
  align-items: center;
  height: 56px;
  width: 232px;
  padding: 16px;
  border-left: 1px solid ${transparentize(0.95, cssVar('--primaryBlack'))};
`;

export const ReportsPopoverHeaderSearchInput = styled.input`
  background-color: transparent;
  width: 70%;
  border: none;
  margin-left: 10px;

  ::placeholder {
    color: var(--primaryBlack);
    opacity: 0.3;
  }
`;

export const ReportsPopoverBody = styled.div`
  max-height: 474px;
  overflow: auto;
  padding: 16px;
`;

export const ReportCardsList = styled.div`
  display: flex;
  margin-top: 20px;
  flex-wrap: wrap;
`;

export const ArrowRightHover = styled(ArrowRight)`
  width: 8px;
  height: 8px;
`;

export const ReportCardWrapper = styled.div`
  cursor: pointer;
  width: 220px;
  box-shadow: 8px 8px 60px var(--primaryBlack4);
  border-radius: 4px;
  border-left: ${({ borderColor }) => `2px solid ${borderColor}`};
  margin-right: 16px;
  margin-bottom: 16px;

  &:last-child {
    margin-right: 0;
  }

  ${ArrowRightHover} {
    display: none;

    svg {
      path: {
        fill: var(--primaryBlack);
      }
    }
  }

  &:hover {
    box-shadow: 8px 8px 60px var(--primaryBlack10);

    ${ArrowRightHover} {
      display: block;
    }
  }
`;

export const ReportCardContents = styled(FlexerColumn)`
  padding: 20px;
`;

export const ReportCardType = styled.div`
  font-style: normal;
  font-weight: 900;
  font-size: 9px;
  line-height: 12px;

  text-transform: uppercase;
  color: var(--primaryBlack);
  opacity: 0.6;
  margin: 0 4px;
`;

export const ReportCardTitle = styled.div`
  font-weight: 900;
  font-size: 14px;
  line-height: 20px;
  color: var(--primaryBlack);
  margin-top: 8px;
`;

export const ReportCardUpdatedAt = styled.div`
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: var(--primaryBlack);
  opacity: 0.5;
  margin-top: 4px;
`;
