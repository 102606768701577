import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useToasts } from 'components/Toasts';
import {
  getEntity,
  createEntity as _createEntity,
  updateEntity as _updateEntity,
  deleteEntity as _deleteEntity,
  getEntities,
} from './requests';

export const CACHE_KEY = 'billing_entities';

export const useEntityAPI = ({ orgId, autoFetch = true, entityId, enableToasts = true }) => {
  const dataKey = [CACHE_KEY, orgId, entityId];

  const { pushToast: wrappedPushToast, pushError: wrappedPushError } = useToasts();
  const pushToast = (...args) => {
    if (enableToasts) wrappedPushToast(...args);
  };
  const pushError = (...args) => {
    if (enableToasts) wrappedPushError(...args);
  };
  const queryClient = useQueryClient();

  const { data, error, isLoading, isFetching, refetch } = useQuery(
    dataKey,
    () =>
      getEntity({
        orgId,
        id: entityId,
      }),
    { enabled: autoFetch && !!orgId && !!entityId },
  );

  const createEntity = useMutation((data) => _createEntity({ orgId, body: data }), {
    onError: (err) => {
      pushError(err, 'Failed to create billing entity');
    },

    onSuccess: () => {
      pushToast('Successfully created billing entity!', 'success');
    },

    onSettled: () => {
      queryClient.invalidateQueries(dataKey);
      queryClient.invalidateQueries(CACHE_KEY);
    },
  });

  const updateEntity = useMutation(
    ({ data, entityId: id }) => _updateEntity({ orgId, body: data, id: id ?? entityId }),
    {
      onError: (err) => {
        pushError(err, 'Failed to update billing entity');
      },

      onSuccess: () => {
        pushToast('Successfully updated billing entity!', 'success');
      },

      onSettled: () => {
        queryClient.invalidateQueries(dataKey);
        queryClient.invalidateQueries(CACHE_KEY);
      },
    },
  );

  const deleteEntity = useMutation(({ entityId }) => _deleteEntity({ orgId, id: entityId }), {
    onError: (err) => {
      pushError(err, 'Failed to delete billing entity');
    },

    onSuccess: () => {
      pushToast('Successfully deleted billing entity!', 'success');
    },

    onSettled: () => {
      queryClient.invalidateQueries(dataKey);
      queryClient.invalidateQueries(CACHE_KEY);
    },
  });

  return {
    data,
    error,
    isLoading,
    isFetching,
    operations: {
      refetch,
      createEntity: createEntity.mutateAsync,
      updateEntity: updateEntity.mutateAsync,
      deleteEntity: deleteEntity.mutateAsync,
    },
  };
};

export const useEntitiesAPI = ({ orgId, autoFetch = true, filters }) => {
  const dataKey = [CACHE_KEY, orgId];

  const { data, error, isLoading, isFetching, refetch } = useQuery(
    dataKey,
    () =>
      getEntities({
        orgId,
        params: filters,
      }),
    { enabled: autoFetch && !!orgId },
  );

  return {
    data,
    error,
    isLoading,
    isFetching,
    operations: {
      refetch,
    },
  };
};
