import styled from 'styled-components';
import { FlexBetweenContainer, Flexer } from './Core';

export const GreyBox = styled(Flexer)`
  flex-direction: column;
  width: 100%;
  padding: 28px 28px 20px 28px;
  background: var(--accentGrayFourth);
`;

export const GreyBoxHeader = styled(FlexBetweenContainer)`
  width: 100%;
  align-items: center;
  margin-left: 8px;
`;

export const GreyBoxTitle = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 900;
  line-height: 20px;
`;

export const GreyBoxActionButtonLabel = styled.div`
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: 16px;

  span {
    color: var(--primaryBlue);
  }
`;

export const GreyBoxContent = styled.div``;
