import styled from 'styled-components';

export const NumberCircle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  font-weight: 900;
  font-size: 10px;
  line-height: 12px;
  color: ${({ inverted }) => (inverted ? 'white' : 'var(--dark7)')};
  background: ${({ inverted }) => (inverted ? 'var(--dark7)' : 'var(--primaryBlack5)')};
  border-radius: 50%;
  width: 20px;
  height: 20px;
`;
