import axios from 'axios';
import { API_URL } from 'api/consts';

const buildUrl = ({ orgId, forecastId }) => {
  const baseUrl = `${API_URL}/organizations/${orgId}/forecasts`;
  if (!forecastId) return baseUrl;

  return `${baseUrl}/${forecastId}`;
};

export const getForecasts = async ({ orgId, params }) => {
  const response = await axios.get(buildUrl({ orgId }), { params });
  return response.data;
};

export const getForecastsDefaults = async ({ orgId, params }) => {
  const response = await axios.get(`${buildUrl({ orgId })}/defaults`, { params });
  return response.data;
};

export const getForecast = async ({ orgId, forecastId, params }) => {
  const response = await axios.get(buildUrl({ orgId, forecastId }), { params });
  return response.data;
};

export const getForecastDetails = async ({ orgId, forecastId, params }) => {
  const response = await axios.get(`${buildUrl({ orgId, forecastId })}/details`, { params });
  return response.data;
};

export const createForecast = async ({ orgId, body }) => {
  const response = await axios.post(buildUrl({ orgId }), body);
  return response.data;
};

export const updateForecast = async ({ orgId, forecastId, body }) => {
  const response = await axios.patch(buildUrl({ orgId, forecastId }), body);
  return response.data;
};

export const updateForecastDetails = async ({ orgId, forecastId, forecastMonth, forecastType, customerId, body }) => {
  const response = await axios.patch(
    `${buildUrl({
      orgId,
      forecastId,
    })}/details/${forecastMonth}/${forecastType}/${customerId}`,
    body,
  );
  return response.data;
};

export const deleteForecast = async ({ orgId, forecastId }) => {
  const response = await axios.delete(buildUrl({ orgId, forecastId }));
  return response.data;
};
