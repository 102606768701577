import React, { useContext, useState } from 'react';
import { Formik } from 'formik';
import { useAuth0 } from '@auth0/auth0-react';
import * as Yup from 'yup';
import { uniq } from 'lodash';

import { AppContext } from 'AppContext';
import { ORG_CONFIGS } from 'consts/global';
import { COLORS } from 'consts/colors';
import { convertToPlainText } from 'utils/htmlUtils';
import { useUrlQuery } from 'utils/hooks';
import { useBillingRemindersAPI } from 'api/billing/hooks';
import { useConfigAPI } from 'api/configs';
import { useInvoiceAPI } from 'api/billing';
import { useReceiptAPI } from 'api/billingReceipts';
import { INTEGRATION_SERVICES, INTEGRATION_TYPES } from 'consts/integrations';
import {
  getIntegrationByService,
  getIntegrationDisplayName,
  getMailerIntegrationService,
  getServiceCategory,
} from 'models/integration';
import { TooltipContainer } from 'components/Tooltip';
import { SaveIcon } from 'components/Icons';

import { BillingConfigWrapper } from './styles';
import { BILLING_SENDER_VALIDATOR, billingSenderInitialValues } from './BillingSenderSetttings';
import { BILLING_SUB_TABS, SubTabsHeader } from './SubTabsHeader/SubTabsHeader';
import { InvoiceSettingsContent } from './InvoiceSettingsContent';
import { GeneralSettingsContent } from './GeneralSettingsContent';
import { CollectionSequence } from './CollectionSequence/CollectionSequence';
import { SENDER_DEFAULT_EMAIL, SENDER_OPTIONS, SENDER_MAILER_OPTIONS } from './BillingSenderSetttings/consts';
import { DEFAULT_BILLING_RECEIPT_SETTINGS } from './BillingReceiptsSettings/consts';
import { DEFAULT_AUTO_CHARGE_INVOICE_SENDING_SETTINGS } from './AutoChargeInvoiceSendingSettings/consts';
import { BodyContainer, StyledHeaderIconButton, StyledIconButton } from '../styles';
import { DEFAULT_CREDIT_NOTE_SETTINGS } from './CreditNoteSettings/consts';

const getInitialValues = ({ user, organizations, orgConfigs, entities }) => {
  const {
    billingInvoiceDefaults: initialBillingInvoiceDefaults,
    billingReminderDefaults: initialBillingReminderDefaults,
    billingSenderDefaults: initialBillingSenderDefaults,
    accountingSpreadPolicy,
    billingUpdatesEmailsToSend,
    billingReceiptDefaults: initialBillingReceiptDefaults,
    billingCreditNoteDefaults: initialBillingCreditNoteDefaults,
    autoChargeInvoiceSendingDefaults: initialAutoChargeInvoiceSendingDefaults,
    defaultAccountingRateType,
    accountingCurrency,
  } = orgConfigs;

  const initialValues = {
    billingInvoiceDefaults: initialBillingInvoiceDefaults,
    billingReminderDefaults: initialBillingReminderDefaults,
    billingSenderDefaults: billingSenderInitialValues({
      ...initialBillingSenderDefaults,
      email_from:
        initialBillingSenderDefaults?.email_from === SENDER_OPTIONS.SUBSCRIPT_EMAIL
          ? SENDER_DEFAULT_EMAIL
          : initialBillingSenderDefaults?.email_from,
      email_name: initialBillingSenderDefaults?.email_name ?? organizations[0]?.name,
      email_reply_to: initialBillingSenderDefaults?.email_reply_to ?? user?.email,
      primary_color: initialBillingSenderDefaults?.primary_color,
      font: initialBillingSenderDefaults?.font ?? 'Nunito Sans',
      show_powered_by_subscript: initialBillingSenderDefaults?.show_powered_by_subscript ?? true,
      show_quantity_column: initialBillingSenderDefaults?.show_quantity_column ?? true,
      show_rate_column: initialBillingSenderDefaults?.show_rate_column ?? false,
      show_amount_column: initialBillingSenderDefaults?.show_amount_column ?? true,
      tax_invoice_title: initialBillingSenderDefaults?.tax_invoice_title ?? 'INVOICE',
      dates_format: initialBillingSenderDefaults?.dates_format ?? 'MMMM D, YYYY',
      tax_label: initialBillingSenderDefaults?.tax_label ?? 'Total taxes',
      customer_tax_id_label: initialBillingSenderDefaults?.customer_tax_id_label ?? 'Tax ID',
      default_entity_id: initialBillingSenderDefaults.default_entity_id ?? entities?.[0]?.id,
    }),
    showSendXDaysBeforeConfig: !!initialBillingInvoiceDefaults.send_x_days_before,
    updateExistingInvoicesSendDate: false,
    updateExistingInvoicesEmailDetails: false,
    accountingSpreadPolicy: accountingSpreadPolicy ?? {},
    billingUpdatesEmailsToSend: billingUpdatesEmailsToSend ?? [],
    billingReceiptDefaults: { ...DEFAULT_BILLING_RECEIPT_SETTINGS, ...initialBillingReceiptDefaults },
    billingCreditNoteDefaults: { ...DEFAULT_CREDIT_NOTE_SETTINGS, ...initialBillingCreditNoteDefaults },
    autoChargeInvoiceSendingDefaults: {
      ...DEFAULT_AUTO_CHARGE_INVOICE_SENDING_SETTINGS,
      ...initialAutoChargeInvoiceSendingDefaults,
    },
    defaultAccountingRateType,
    accountingCurrency,
  };

  initialValues.billingInvoiceDefaults.memo = initialValues?.billingInvoiceDefaults?.memo ?? '';
  initialValues.billingInvoiceDefaults.memo_for_open_ended =
    initialValues?.billingInvoiceDefaults?.memo_for_open_ended ?? '';
  initialValues.billingInvoiceDefaults.secondary_memo = initialValues?.billingInvoiceDefaults?.secondary_memo ?? '';
  initialValues.billingInvoiceDefaults.post_pay_events = initialValues?.billingInvoiceDefaults?.post_pay_events ?? true;

  return initialValues;
};

export const SubscriptBillingTab = () => {
  const { orgId, integrations, organizations, orgConfigs, entities } = useContext(AppContext);
  const { updateConfigs } = useConfigAPI({ orgId });
  const {
    operations: { bulkUpdateUnsentInvoices },
  } = useInvoiceAPI({ orgId });
  const {
    operations: { bulkUpdateReceipts },
  } = useReceiptAPI({ orgId, autoFetch: false });
  const {
    operations: { enableBillingAIReminders, disableBillingAIReminders },
  } = useBillingRemindersAPI({ orgId, autoFetch: false });
  const { user } = useAuth0();

  const query = useUrlQuery();
  // 'initialSubTab' can account for manually-typed urls as it is not detected by the listener
  const initialSubTab = query.get('subTab');
  const [currentSubTab, setCurrentSubTab] = useState(initialSubTab);

  const glIntegrations = integrations?.filter((i) => i.type === INTEGRATION_TYPES.GL) ?? [];
  const invoicingServices = uniq(glIntegrations.map(({ service }) => getServiceCategory(service)));
  const invoicingServiceDisplayName =
    glIntegrations.length > 1 ? 'your GLs' : getIntegrationDisplayName(glIntegrations[0]);

  const mailerIntegrationService = getMailerIntegrationService({ integrations });
  const mailerIntegration = getIntegrationByService({ integrations, service: mailerIntegrationService });

  const {
    billingInvoiceDefaults: initialBillingInvoiceDefaults,
    billingReminderDefaults: initialBillingReminderDefaults,
  } = orgConfigs;

  const initialValues = getInitialValues({
    user,
    organizations,
    orgConfigs,
    entities,
  });

  const getChangedReceiptDefaults = (billingReceiptDefaultsFormikValues) =>
    Object.entries(billingReceiptDefaultsFormikValues).reduce((obj, [key, value]) => {
      const normalizeValue = (v) => (typeof v === 'string' ? v.trim() : v);
      const initialValue = normalizeValue(initialValues.billingReceiptDefaults[key]);

      const hasChanged =
        key !== 'update_unsent_receipts' && key !== 'auto_send' && initialValue !== normalizeValue(value);
      return hasChanged ? { ...obj, [key]: value } : obj;
    }, {});

  const handleFormSubmit = async (values) => {
    const {
      billingInvoiceDefaults,
      billingReminderDefaults,
      billingSenderDefaults,
      showSendXDaysBeforeConfig,
      updateExistingInvoicesSendDate,
      updateExistingInvoicesEmailDetails,
      accountingSpreadPolicy,
      billingUpdatesEmailsToSend,
      billingReceiptDefaults,
      billingCreditNoteDefaults,
      autoChargeInvoiceSendingDefaults,
      defaultAccountingRateType,
      accountingCurrency,
    } = values;

    const data = {
      [ORG_CONFIGS.BILLING_INVOICE_DEFAULTS]: {
        ...billingInvoiceDefaults,
        send_x_days_before: showSendXDaysBeforeConfig ? billingInvoiceDefaults.send_x_days_before ?? null : null,
        memo: convertToPlainText(billingInvoiceDefaults.memo),
        secondary_memo: convertToPlainText(billingInvoiceDefaults.secondary_memo),
        memo_for_open_ended: convertToPlainText(billingInvoiceDefaults.memo_for_open_ended),
        email_subject: convertToPlainText(billingInvoiceDefaults.email_subject),
        email_body: convertToPlainText(billingInvoiceDefaults.email_body),
        email_CC: billingInvoiceDefaults.email_CC,
        invoice_item_description: billingInvoiceDefaults.invoice_item_description
          ? convertToPlainText(billingInvoiceDefaults.invoice_item_description)
          : '',
      },
      [ORG_CONFIGS.BILLING_REMINDER_DEFAULTS]: billingReminderDefaults ?? {},
      [ORG_CONFIGS.BILLING_SENDER_DEFAULTS]: billingSenderDefaults,
      [ORG_CONFIGS.ACCOUNTING_SPREAD_POLICY]: accountingSpreadPolicy,
      [ORG_CONFIGS.BILLING_UPDATES_EMAILS_TO_SEND]: billingUpdatesEmailsToSend,
      [ORG_CONFIGS.BILLING_RECEIPT_DEFAULTS]: billingReceiptDefaults,
      [ORG_CONFIGS.BILLING_CREDIT_NOTE_DEFAULTS]: {
        ...billingCreditNoteDefaults,
        email_subject: convertToPlainText(billingCreditNoteDefaults.email_subject ?? ''),
        email_body: convertToPlainText(billingCreditNoteDefaults.email_body ?? ''),
      },
      [ORG_CONFIGS.AUTO_CHARGE_INVOICE_SENDING_DEFAULTS]: autoChargeInvoiceSendingDefaults,
      [ORG_CONFIGS.DEFAULT_ACCOUNTING_RATE_TYPE]: defaultAccountingRateType,
      [ORG_CONFIGS.ACCOUNTING_CURRENCY]: accountingCurrency,
    };

    await updateConfigs({ data });

    const sendDateConfigIsChanged =
      (initialBillingInvoiceDefaults.send_x_days_before ?? null) !==
      (showSendXDaysBeforeConfig ? billingInvoiceDefaults.send_x_days_before ?? 0 : null);

    const emailDetailsAreChanged =
      (initialBillingInvoiceDefaults.email_subject ?? null) !== values.billingInvoiceDefaults.email_subject ||
      (initialBillingInvoiceDefaults.email_body ?? null) !== values.billingInvoiceDefaults.email_body;

    const aiRemindersEnabled = !initialBillingReminderDefaults?.ai_enabled && billingReminderDefaults?.ai_enabled; // was disabled before, now enabled
    const aiRemindersDisabled = initialBillingReminderDefaults?.ai_enabled && !billingReminderDefaults?.ai_enabled; // was enabled before, now disabled

    const changedReceiptDefaults = getChangedReceiptDefaults(billingReceiptDefaults);

    const fields = [];

    if (updateExistingInvoicesSendDate && sendDateConfigIsChanged) {
      fields.push('send_date');
    }

    if (updateExistingInvoicesEmailDetails && emailDetailsAreChanged) {
      fields.push('email_body', 'email_subject');
    }

    if (fields.length) {
      await bulkUpdateUnsentInvoices({ data: { fields } });
    }

    if (billingReceiptDefaults.update_unsent_receipts && Object.keys(changedReceiptDefaults).length !== 0) {
      await bulkUpdateReceipts({ data: changedReceiptDefaults, filters: { isUnsent: true } });
    }

    if (aiRemindersEnabled) {
      await enableBillingAIReminders();
    } else if (aiRemindersDisabled) {
      await disableBillingAIReminders();
    }
  };

  const getSubTabContent = ({ values, setFieldValue, validateAndSubmitForm }) => {
    switch (currentSubTab) {
      case BILLING_SUB_TABS.INVOICE_SETTINGS:
        return (
          <InvoiceSettingsContent
            initialValues={initialValues}
            values={values}
            setFieldValue={setFieldValue}
            invoicingServices={invoicingServices}
            invoicingServiceDisplayName={invoicingServiceDisplayName}
            initialBillingInvoiceDefaults={initialBillingInvoiceDefaults}
          />
        );
      case BILLING_SUB_TABS.COLLECTION_SEQUENCE:
        return (
          <CollectionSequence
            validateAndSubmitForm={validateAndSubmitForm}
            values={values}
            setFieldValue={setFieldValue}
            mailerIntegration={mailerIntegration}
          />
        );
      default:
        return (
          <GeneralSettingsContent
            initialValues={initialValues}
            values={values}
            setFieldValue={setFieldValue}
            invoicingServices={invoicingServices}
            invoicingServiceDisplayName={invoicingServiceDisplayName}
            getChangedReceiptDefaults={getChangedReceiptDefaults}
            gmailIntegration={mailerIntegration?.service === INTEGRATION_SERVICES.GMAIL ? mailerIntegration : null}
          />
        );
    }
  };

  return (
    <>
      <SubTabsHeader currentSubTab={currentSubTab} setCurrentSubTab={setCurrentSubTab} />

      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={Yup.object({
          billingSenderDefaults: BILLING_SENDER_VALIDATOR,
        })}
        onSubmit={handleFormSubmit}
      >
        {({ values, setFieldValue, submitForm }) => {
          const disableSaveButton =
            values?.billingSenderDefaults?.sender === SENDER_OPTIONS.SUBSCRIPT_EMAIL &&
            values?.billingSenderDefaults?.sender_mailer === SENDER_MAILER_OPTIONS.GMAIL &&
            !mailerIntegration?.metadata?.user_email;

          const validateAndSubmitForm = async () => {
            try {
              // manually validate form before submit
              await Yup.reach(
                Yup.object().shape({ billingSenderDefaults: BILLING_SENDER_VALIDATOR }),
                'billingSenderDefaults',
              ).validate(values.billingSenderDefaults);

              submitForm();
            } catch (err) {
              console.log('Error', err.message);
            }
          };

          return (
            <>
              <StyledHeaderIconButton
                icon={<SaveIcon />}
                iconFillColor="var(--primaryGreen)"
                onClick={validateAndSubmitForm}
              >
                Save Settings
              </StyledHeaderIconButton>

              <BodyContainer>
                <div style={{ height: 50 }}></div>

                <BillingConfigWrapper>
                  {getSubTabContent({ values, setFieldValue, validateAndSubmitForm })}
                </BillingConfigWrapper>

                <TooltipContainer
                  toolTipContent="Finish setting up sending through the Google Apps API"
                  isVisible={disableSaveButton}
                  hideArrow
                >
                  <StyledIconButton
                    data-cy="configuration__save-button"
                    style={{ display: 'flex' }}
                    filled
                    border
                    color={COLORS.GREEN}
                    icon={<SaveIcon />}
                    iconFillColor={'#FFF'}
                    onClick={submitForm}
                    disabled={disableSaveButton}
                  >
                    Save Settings
                  </StyledIconButton>
                </TooltipContainer>
              </BodyContainer>
            </>
          );
        }}
      </Formik>
    </>
  );
};
