import { useCallback, useState } from 'react';
import { ContractFilesModal } from './ContractFilesModal';

export const useContractFilesModal = ({
  transactionIds,
  customerId,
  uploadNew = false,
  readOnly = false,
  onSubmit,
}) => {
  const [showModal, setShowModal] = useState(false);

  const openModal = useCallback(() => {
    setShowModal(true);
  }, [setShowModal]);
  const Modal = useCallback(
    () =>
      showModal ? (
        <ContractFilesModal
          transactionIds={transactionIds}
          customerId={customerId}
          uploadNew={uploadNew}
          readOnly={readOnly}
          onClose={() => setShowModal(false)}
          onSubmit={(data) => {
            setShowModal(false);
            onSubmit(data);
          }}
        />
      ) : null,
    [showModal, setShowModal, transactionIds, customerId, uploadNew, readOnly, onSubmit],
  );

  return {
    openContractFilesModal: openModal,
    ContractFilesModal: Modal,
    isModalVisible: showModal,
  };
};
