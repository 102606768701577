import React, { useContext, useMemo } from 'react';
import dayjs from 'dayjs';
import { AppContext } from 'AppContext';
import { getAppSetting } from 'models/appSettings';
import { EMOJI, NUMBER_FORMATS } from 'consts/global';
import { REVENUE_METRIC } from 'consts/revenueMetrics';
import { EVENTS } from 'consts/analytics';
import { SNAPSHOT_METRIC_BY_LABEL, SNAPSHOT_METRICS, SNAPSHOT_METRIC_BY_KEY } from 'consts/snapshotMetrics';
import { reFormatDate, periodKeyToDayJS, formatDateForDatepicker } from 'utils/dateUtils';
import { useAnalytics } from 'utils/hooks';
import { RowValueDisplay } from './RowValueDisplay';
import { pickOutPeriod, startAndEndMonthsFromHeader, getIsSummaryColumn } from './utils';

const RENEWAL_ROWS = new Set([
  SNAPSHOT_METRICS.UP_FOR_RENEWAL.label,
  SNAPSHOT_METRICS.AMOUNT_RENEWED_THAT_WAS_UP.label,
  SNAPSHOT_METRICS.AMOUNT_RENEWED_THAT_WAS_UP_NET.label,
  SNAPSHOT_METRICS.CUSTOMERS_UP_FOR_RENEWAL.label,
  SNAPSHOT_METRICS.CUSTOMERS_RENEWED.label,
]);

// This is where you add a Revenue Details drilldown if you want a row to open a tab in the Revenue Details Modal
const ROW_LABEL_TO_REVENUE_DETAILS_METRIC = {
  [SNAPSHOT_METRICS.RECURRING_REVENUE.label]: REVENUE_METRIC.EXISTING.label,
  [SNAPSHOT_METRICS.NON_RECURRING.label]: REVENUE_METRIC.NON_RECURRING.label,
  [SNAPSHOT_METRICS.TOTAL_PERIOD_REVENUE.label]: REVENUE_METRIC.EXISTING.label,
  [SNAPSHOT_METRICS.RECURRING_CUSTOMERS.label]: REVENUE_METRIC.EXISTING.label,
  [SNAPSHOT_METRICS.GROSS_NEW_REVENUE.label]: REVENUE_METRIC.NEW.key,
  [SNAPSHOT_METRICS.NON_RENEWAL_UPSELL.label]: REVENUE_METRIC.UPSELL.key,
  Upsell: REVENUE_METRIC.UPSELL.label,
  Downsell: REVENUE_METRIC.DOWNSELL.label,
  Churn: REVENUE_METRIC.CHURN.label,
  Existing: REVENUE_METRIC.EXISTING.label,
  New: REVENUE_METRIC.NEW.label,
  [REVENUE_METRIC.INFLUX.label]: REVENUE_METRIC.INFLUX.key,
  [REVENUE_METRIC.INFLUX_MOVEMENT.label]: REVENUE_METRIC.INFLUX.key,
};

// This is where you add an Explain drilldown if you want a row to open an Explain Modal
export const METRICS_WITH_EXPLAIN = new Set([
  SNAPSHOT_METRICS.NET_NEW_REVENUE.label,
  SNAPSHOT_METRICS.NET_NEW_REVENUE_TTM.label,
  SNAPSHOT_METRICS.GROSS_REVENUE_CHURN_RATE.label,
  SNAPSHOT_METRICS.GROSS_REVENUE_CHURN_RATE_TTM.label,
  SNAPSHOT_METRICS.NET_UP_FOR_RENEWAL_RETENTION.label,
  SNAPSHOT_METRICS.GROSS_UP_FOR_RENEWAL_RETENTION.label,
  SNAPSHOT_METRICS.CUSTOMER_UP_FOR_RENEWAL_RETENTION.label,
  SNAPSHOT_METRICS.ARPU.label,
  SNAPSHOT_METRICS.ARPU_NEW.label,
  SNAPSHOT_METRICS.ARPA.label,
  SNAPSHOT_METRICS.ARPA_TTM.label,
  SNAPSHOT_METRICS.ARPA_NEW.label,
  SNAPSHOT_METRICS.ARPA_NEW_TTM.label,
  SNAPSHOT_METRICS.LTV.label,
  SNAPSHOT_METRICS.LTV_TTM.label,
  SNAPSHOT_METRICS.LTVGMLaden.label,
  SNAPSHOT_METRICS.LTVGMLaden_TTM.label,
  SNAPSHOT_METRICS.CAC_RECURRING.label,
  SNAPSHOT_METRICS.CAC_RECURRING_TTM.label,
  SNAPSHOT_METRICS.CAC_ALL.label,
  SNAPSHOT_METRICS.CAC_ALL_TTM.label,
  SNAPSHOT_METRICS.CEC_RECURRING.label,
  SNAPSHOT_METRICS.CEC_RECURRING_TTM.label,
  SNAPSHOT_METRICS.LTVCACRatio.label,
  SNAPSHOT_METRICS.LTVCACRatio_TTM.label,
  SNAPSHOT_METRICS.NEW_REVENUE_TO_COST_RATIO.label,
  SNAPSHOT_METRICS.GROSS_MARGIN.label,
  SNAPSHOT_METRICS.GROSS_MARGIN_TTM.label,
  SNAPSHOT_METRICS.GROSS_MARGIN_NON_RECURRING.label,
  SNAPSHOT_METRICS.GROSS_MARGIN_NON_RECURRING_TTM.label,
  SNAPSHOT_METRICS.PAYBACK_PERIOD.label,
  SNAPSHOT_METRICS.PAYBACK_PERIOD_TTM.label,
  SNAPSHOT_METRICS.MAGIC_NUMBER.label,
  SNAPSHOT_METRICS.SALES_EFFICIENCY_RATIO.label,
  SNAPSHOT_METRICS.RULE_OF_40_TTM.label,
  SNAPSHOT_METRICS.BURN_MULTIPLE.label,
  SNAPSHOT_METRICS.BURN_MULTIPLE_TTM.label,
]);

export const RETENTION_METRICS = new Set([
  SNAPSHOT_METRICS.GROSS_CUSTOMER_RETENTION.label,
  SNAPSHOT_METRICS.GROSS_CUSTOMER_RETENTION_TTM.label,
  SNAPSHOT_METRICS.NET_REVENUE_RETENTION.label,
  SNAPSHOT_METRICS.NET_REVENUE_RETENTION_TTM.label,
  SNAPSHOT_METRICS.GROSS_REVENUE_RETENTION.label,
  SNAPSHOT_METRICS.GROSS_REVENUE_RETENTION_TTM.label,
]);

export const METRICS_NULL_IF_NEGATIVE = new Set([
  SNAPSHOT_METRICS.MAGIC_NUMBER.label,
  SNAPSHOT_METRICS.SALES_EFFICIENCY_RATIO.label,
  SNAPSHOT_METRICS.PAYBACK_PERIOD.label,
  SNAPSHOT_METRICS.PAYBACK_PERIOD_TTM.label,
  SNAPSHOT_METRICS.BURN_MULTIPLE.label,
  SNAPSHOT_METRICS.BURN_MULTIPLE_TTM.label,
]);

const DataTableRowValue = ({
  title,
  columnHeaders,
  dataType,
  monthDataKey,
  decimalPlaces,
  setSelectedMonth,
  setSelectedSegment,
  setSummaryStartMonth,
  setSummaryColumnHeader,
  setSelectedMetric,
  setSelectedUpsellType,
  setShowRevenueDetailsModal,
  setShowUpForRenewalModal,
  setBookingStart,
  setBookingEnd,
  setShowBookingsModal,
  setBookingsValue,
  setShowExplainModal,
  setDataForExplainModal,
  index,
  value,
  date,
  previousValue,
  goalData,
  waterfallType,
  depth,
  row,
  rowsById,
  // needed for SnapshotTable
  dataFilter,
  openCardChartModal,
}) => {
  const {
    appSettings: { isARR },
  } = useContext(AppContext);
  const { trackEvent } = useAnalytics();

  const quarters = getAppSetting('quarters');
  const quartersYearOffset = getAppSetting('quartersYearOffset');

  // Ids passed to rows are of the form `parent.child`,
  // so we can use the `.` to split the id into an array of ids
  // and then use the first element as the parent id
  const parentRowId = row?.id?.split('.')?.slice(0, -1)?.join('.');
  const parentRow = rowsById?.[parentRowId];

  const metricTitle = depth > 0 ? parentRow.original.title : title;
  const revenueDetailsMetric = ROW_LABEL_TO_REVENUE_DETAILS_METRIC[metricTitle];

  const openExplainModal = () => {
    trackEvent({
      name: EVENTS.OPEN_EXPLAIN_MODAL,
      properties: {
        explainFor: title,
      },
    });

    setShowExplainModal(true);
  };

  const openRevenueDetailsModal = () => {
    trackEvent({
      name: EVENTS.OPEN_REVENUE_DEATILS_MODAL,
      properties: {
        fromTableRow: title,
      },
    });

    setShowRevenueDetailsModal(true);
  };

  const openUpForRenewalModal = () => {
    trackEvent({
      name: EVENTS.OPEN_UP_FOR_RENEWAL_MODAL,
      properties: {
        fromTableRow: title,
      },
    });

    setShowUpForRenewalModal(true);
  };

  const handleClickRetentionMetric = () => {
    const isTTM = monthDataKey.includes('_TTM');
    // find the date range that we want to set in the Retention Modal
    let tableDateStart;
    let tableDateEnd;
    if (date === 'CUMULATIVE') {
      tableDateEnd = dayjs.utc(dataFilter.endMonth);
      tableDateStart = isTTM ? tableDateEnd.subtract(1, 'year') : dayjs.utc(dataFilter.startMonth).subtract(1, 'month');
    } else if (date.includes('-Q')) {
      tableDateEnd = periodKeyToDayJS({ periodKey: date, periodType: 'quarter', quarters, quartersYearOffset });
      tableDateStart = isTTM ? tableDateEnd.subtract(1, 'year') : tableDateEnd.subtract(3, 'month');
    } else if (date.includes('-Y')) {
      tableDateEnd = periodKeyToDayJS({ periodKey: date, periodType: 'year', quarters, quartersYearOffset });
      tableDateStart = tableDateEnd.subtract(1, 'year');
    } else {
      tableDateEnd = dayjs.utc(date, 'MMM YYYY');
      tableDateStart = isTTM ? tableDateEnd.subtract(1, 'year') : tableDateEnd.subtract(1, 'month');
    }

    openCardChartModal({
      metricKey: SNAPSHOT_METRIC_BY_LABEL[title]?.key,
      tableDateStart: formatDateForDatepicker(tableDateStart),
      tableDateEnd: formatDateForDatepicker(tableDateEnd),
    });
  };

  const handleClickExplainMetric = () => {
    if (
      [SNAPSHOT_METRICS.MAGIC_NUMBER.label, SNAPSHOT_METRICS.SALES_EFFICIENCY_RATIO.label].includes(title) &&
      !columnHeaders[index].includes('-Q')
    ) {
      return null;
    }

    if (
      ([
        SNAPSHOT_METRICS.LTV.label,
        SNAPSHOT_METRICS.LTV_TTM.label,
        SNAPSHOT_METRICS.LTVGMLaden.label,
        SNAPSHOT_METRICS.LTVGMLaden_TTM.label,
      ].includes(title) &&
        value?.grossCustomerRetention === 1) ||
      ([SNAPSHOT_METRICS.NEW_REVENUE_TO_COST_RATIO.label].includes(title) &&
        (value?.costMarketing ?? 0) + (value?.costSales ?? 0) === 0)
    ) {
      const metricValue = {
        ...value,
        value: EMOJI.INFINITY.icon,
      };
      setDataForExplainModal({
        title,
        dataType: NUMBER_FORMATS.EMOJI,
        date: columnHeaders[index],
        metricValue,
        isTTMMetric: title.includes('TTM'),
        isQuarterMetric: columnHeaders[index].includes('-Q'),
        isYearMetric: columnHeaders[index].includes('-Y'),
        isCumulativeMetric: columnHeaders[index].includes('CUMULATIVE'),
        numberMonths: columnHeaders.filter((c) => !c.includes('-Q') && !c.includes('-Y') && !c.includes('CUMULATIVE'))
          .length, // This next one is needed for some explain modals
        tooltip: `${title} can't be calculated in periods of 100% retention. Please check trailing-twelve-month for a version using longer-running retention estimates`,
      });
    } else if (METRICS_NULL_IF_NEGATIVE.has(title) && value?.value < 0) {
      const metricValue = {
        ...value,
        value: EMOJI.OUT_OF_BOUNDS.icon,
      };
      setDataForExplainModal({
        title,
        dataType: NUMBER_FORMATS.EMOJI,
        date: columnHeaders[index],
        metricValue,
        isTTMMetric: title.includes('TTM'),
        isQuarterMetric: columnHeaders[index].includes('-Q'),
        isYearMetric: columnHeaders[index].includes('-Y'),
        isCumulativeMetric: columnHeaders[index].includes('CUMULATIVE'),
        numberMonths: columnHeaders.filter((c) => !c.includes('-Q') && !c.includes('-Y') && !c.includes('CUMULATIVE'))
          .length, // This next one is needed for some explain modals
        tooltip: `${title} doesn't have a valid value for this period - it's out of bounds`,
      });
    } else {
      setDataForExplainModal({
        title,
        dataType,
        date: columnHeaders[index],
        metricValue: value,
        isTTMMetric: title.includes('TTM'),
        isQuarterMetric: columnHeaders[index].includes('-Q'),
        isYearMetric: columnHeaders[index].includes('-Y'),
        isCumulativeMetric: columnHeaders[index].includes('CUMULATIVE'),
        numberMonths: columnHeaders.filter((c) => !c.includes('-Q') && !c.includes('-Y') && !c.includes('CUMULATIVE'))
          .length, // This next one is needed for some explain modals
      });
    }

    openExplainModal();
  };

  const handleClickUpForRenewal = () => {
    const isSummaryColumn = getIsSummaryColumn({ columnHeaders, index });
    if (isSummaryColumn) {
      const { startMonth, endMonth } = startAndEndMonthsFromHeader({
        columnHeaders,
        index,
        quarters,
        quartersYearOffset,
        dataFilter,
      });
      setSelectedMonth(endMonth.format('YYYY-MM'));
      setSummaryColumnHeader(columnHeaders[index]);
      setSummaryStartMonth(startMonth.add(1, 'month').format('YYYY-MM'));
    } else {
      setSelectedMonth(reFormatDate(columnHeaders[index], 'MMM YYYY', 'YYYY-MM'));
      setSummaryColumnHeader(reFormatDate(columnHeaders[index], 'MMM YYYY', 'MMMM YYYY'));
      setSummaryStartMonth(null);
    }

    openUpForRenewalModal();
  };

  const handleClickRevenueDetails = () => {
    const isSummaryColumn = getIsSummaryColumn({ columnHeaders, index });
    if (isSummaryColumn) {
      const { startMonth, endMonth } = startAndEndMonthsFromHeader({
        columnHeaders,
        index,
        quarters,
        quartersYearOffset,
        dataFilter,
      });

      setSummaryStartMonth(startMonth.format('YYYY-MM'));
      setSummaryColumnHeader(columnHeaders[index]);
      setSelectedMonth(endMonth.format('YYYY-MM'));
    } else {
      setSummaryStartMonth(null);
      setSummaryColumnHeader(null);
      setSelectedMonth(reFormatDate(columnHeaders[index], 'MMM YYYY', 'YYYY-MM'));
    }

    if (depth > 0) {
      setSelectedSegment?.(row.original.key);
    } else {
      setSelectedSegment?.(null);
    }

    setSelectedMetric(revenueDetailsMetric);
    if (title === SNAPSHOT_METRICS.NON_RENEWAL_UPSELL.label) {
      setSelectedUpsellType(['nonRenewal']);
    }
    openRevenueDetailsModal();
  };

  // We pass all goal data here, then pick out our period based on the column title
  const periods = useMemo(
    () =>
      goalData?.map((goalLine) => {
        const metric = SNAPSHOT_METRIC_BY_KEY[goalLine?.for_metric];
        const pickedOutPeriod = pickOutPeriod({
          periodsArray: goalLine?.periods,
          columnTitle: columnHeaders[index],
        });

        if (pickedOutPeriod) {
          return {
            ...pickedOutPeriod,
            value: (pickedOutPeriod?.value ?? 0) * (isARR && metric.isARRMetric ? 12 : 1),
          };
        }

        return pickOutPeriod;
      }),
    [isARR, goalData, columnHeaders, index],
  );

  if (revenueDetailsMetric) {
    return (
      <RowValueDisplay
        hasDrilldown={true}
        handleClick={handleClickRevenueDetails}
        title={title}
        previousValue={previousValue}
        date={date}
        dataType={dataType}
        decimalPlaces={decimalPlaces}
        value={value}
        periods={periods}
        goalData={goalData}
        addSign={title === REVENUE_METRIC.INFLUX_MOVEMENT.label}
        waterfallType={waterfallType}
      />
    );
  } else if (title === SNAPSHOT_METRICS.BOOKINGS.label) {
    const { startMonth, endMonth } = startAndEndMonthsFromHeader({
      columnHeaders,
      index,
      quarters,
      quartersYearOffset,
      dataFilter,
    });
    const startDate = startMonth.format('YYYY-MM-DD');
    const endDate = endMonth.add(1, 'month').format('YYYY-MM-DD');

    const handleClickBooking = () => {
      setBookingStart(startDate);
      setBookingEnd(endDate);
      setBookingsValue(value);
      setShowBookingsModal(true);
    };

    return (
      <RowValueDisplay
        hasDrilldown={true}
        handleClick={handleClickBooking}
        title={title}
        previousValue={previousValue}
        date={date}
        dataType={dataType}
        decimalPlaces={decimalPlaces}
        value={value}
        periods={periods}
        goalData={goalData}
      />
    );
  } else if (
    ([
      SNAPSHOT_METRICS.LTV.label,
      SNAPSHOT_METRICS.LTV_TTM.label,
      SNAPSHOT_METRICS.LTVGMLaden.label,
      SNAPSHOT_METRICS.LTVGMLaden_TTM.label,
    ].includes(title) &&
      value?.grossCustomerRetention === 1) ||
    ([SNAPSHOT_METRICS.NEW_REVENUE_TO_COST_RATIO.label].includes(title) &&
      (value?.costMarketing ?? 0) + (value?.costSales ?? 0) === 0)
  ) {
    return (
      <RowValueDisplay
        hasDrilldown={true}
        handleClick={handleClickExplainMetric}
        title={title}
        date={date}
        value={EMOJI.INFINITY.icon}
        dataType={NUMBER_FORMATS.EMOJI}
        previousValue={previousValue}
        periods={periods}
        goalData={goalData}
      />
    );
  } else if (METRICS_NULL_IF_NEGATIVE.has(title) && value?.value < 0) {
    return (
      <RowValueDisplay
        hasDrilldown={true}
        handleClick={handleClickExplainMetric}
        title={title}
        date={date}
        value={EMOJI.OUT_OF_BOUNDS.icon}
        dataType={NUMBER_FORMATS.EMOJI}
        previousValue={previousValue}
        periods={periods}
        goalData={goalData}
      />
    );
  } else if (RENEWAL_ROWS.has(title)) {
    return (
      <RowValueDisplay
        hasDrilldown={true}
        handleClick={handleClickUpForRenewal}
        title={title}
        dataType={dataType}
        previousValue={previousValue}
        date={date}
        decimalPlaces={decimalPlaces}
        value={value}
        periods={periods}
        goalData={goalData}
      />
    );
  } else if (METRICS_WITH_EXPLAIN.has(title)) {
    return (
      <RowValueDisplay
        hasDrilldown={true}
        handleClick={handleClickExplainMetric}
        title={title}
        dataType={dataType}
        previousValue={previousValue}
        date={date}
        decimalPlaces={decimalPlaces}
        value={value?.value}
        periods={periods}
        goalData={goalData}
      />
    );
  } else if (RETENTION_METRICS.has(title)) {
    return (
      <RowValueDisplay
        hasDrilldown={true}
        handleClick={handleClickRetentionMetric}
        title={title}
        dataType={dataType}
        previousValue={previousValue}
        date={date}
        decimalPlaces={decimalPlaces}
        value={value?.value}
        periods={periods}
        goalData={goalData}
      />
    );
  } else {
    return (
      <RowValueDisplay
        title={title}
        dataType={dataType}
        decimalPlaces={decimalPlaces}
        previousValue={previousValue}
        date={date}
        value={value}
        periods={periods}
        goalData={goalData}
        isCumulative={columnHeaders[index].includes('CUMULATIVE')}
      />
    );
  }
};

export default DataTableRowValue;
