import React, { useEffect } from 'react';
import styled from 'styled-components';
import { cssVar } from 'polished';
import { EVENTS } from 'consts/analytics';
import { PAGE_CONTEXT_VALUES, useAnalytics, usePageContext } from 'utils/hooks';
import { ReactComponent as HowWorksIcon } from 'images/lifebuoy.svg';
import { IconButton } from 'components/Buttons';
import { usePortal } from 'components/Portal';
import { ReactComponent as ArrowDown } from 'images/chevron-down.svg';
import { Header, HEADER_TITLES } from 'shared/Layout';
import { HeaderTitle } from 'shared/Layout/Header/style';
import { BillingPopover } from 'shared/Layout/Navbar';
import { DisconnectedIntegrationBanner } from 'views/Billing/Common/DisconnectedIntegrationBanner';
import { useHowItWorksModal } from '../../HowItWorksModal';
import { HeaderTab, StyledArrow } from '../../BillingHeader';
import { BillingProvider } from '../../BillingProvider';
import { ReviewTransactionsTableContainer } from './ReviewTransactionsContainer';

const Wrapper = styled.div`
  padding: 0 40px 0px 40px;
`;

export const ReviewTransactionsPage = () => {
  const { Modal: HowItWorksModal, openModal: openHowItWorksModal } = useHowItWorksModal();
  const { triggerRef, togglePortal, isPortalVisible, hidePortal, Portal } = usePortal();
  const { trackEvent } = useAnalytics();

  const { urlState } = usePageContext({ page: PAGE_CONTEXT_VALUES.review_transactions.key });

  useEffect(() => {
    trackEvent({ name: EVENTS.VISIT_BILLING_REVIEW_TRANSACTIONS_PAGE });
  }, [trackEvent]);

  return (
    <BillingProvider>
      <Wrapper>
        <Header
          headerLeft={
            <HeaderTitle ref={triggerRef} onClick={togglePortal} weight={300} cursor="pointer">
              {HEADER_TITLES.billing}: <HeaderTab>Review invoices for new transactions</HeaderTab>
              <StyledArrow active={isPortalVisible}>
                <ArrowDown />
              </StyledArrow>
              {isPortalVisible && (
                <Portal>
                  <BillingPopover onClose={hidePortal} />
                </Portal>
              )}
            </HeaderTitle>
          }
          headerRight={
            <IconButton
              icon={<HowWorksIcon />}
              iconFillColor={cssVar('--primaryGreen')}
              onClick={openHowItWorksModal}
              data-cy="billing__how-it-works"
            >
              How it works
            </IconButton>
          }
        />
        <HowItWorksModal />

        <DisconnectedIntegrationBanner />
      </Wrapper>

      <ReviewTransactionsTableContainer urlState={urlState} />
    </BillingProvider>
  );
};
