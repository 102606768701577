import React, { useContext, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from 'AppContext';
import { SNAPSHOT_METRICS } from 'consts/snapshotMetrics';
import { useReportsAPI } from 'api/reports';
import { ReportIcon, TableIcon } from 'components/Icons';
import { Centerer } from 'components/Core';
import { CircleLoader } from 'components/Loaders';
import { COMMON_METRICS_DASHBOARD_TITLE, DEFAULT_DASHBOARD_TITLE } from 'views/Dashboard/ReportBuilder/consts';
import { getCommonMetricsReport, getSnapshotReportDefaultOptions } from 'views/Dashboard/utils';
import { PopoverLinkActionButton, PopoverLinkContents, PopoverLinkContentsItem } from './PopoverLinkContents';

const DASHBOARD_ICONS = {
  [DEFAULT_DASHBOARD_TITLE]: <ReportIcon />,
  [COMMON_METRICS_DASHBOARD_TITLE]: <TableIcon />,
};

const getCreateReportBody = ({ title, settings }) => {
  const options = getSnapshotReportDefaultOptions(settings);

  return {
    title,
    type: 'snapshot',
    options: {
      ...options,
      metricsSetting: [],
      collapseRows: [],
      snapshotTableRows: [],
      snapshotSelectedColumns: {},
    },
  };
};

export const DashboardLinksPopover = ({ togglePortal }) => {
  const title = 'Dashboard';
  const pathname = '/dashboard';
  const history = useHistory();
  const { orgId, organizations, appSettings, dataFilter, metadataFilter, setMetadataFilter } = useContext(AppContext);
  const {
    data,
    isLoading,
    isFetching,
    operations: { createReport },
  } = useReportsAPI({ orgId });

  const reportsToShow = useMemo(() => {
    const reports =
      data
        ?.filter((report) => report.type === 'snapshot' && report.title !== DEFAULT_DASHBOARD_TITLE)
        ?.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at)) ?? [];

    // Always add default dashboard at the top of the list
    if (organizations[0]?.defaultDashboard) {
      reports.unshift(organizations[0]?.defaultDashboard);
    }

    // Add common metrics dashboard at the end of the list
    reports.push(getCommonMetricsReport({ ...appSettings, dataFilter, metadataFilter }));

    return reports;
  }, [appSettings, data, dataFilter, metadataFilter, organizations]);

  const handleAddReport = async () => {
    const reportsCount = reportsToShow.length;
    const report = await createReport({
      orgId,
      report: getCreateReportBody({
        title: `My Dashboard ${reportsCount >= 0 ? reportsCount + 1 : ''}`,
        settings: { ...appSettings, dataFilter, metadataFilter },
      }),
    });
    history.push(`${pathname}/${report.id}`, { report, reportsCount, isNewReport: true });
  };

  return (
    <PopoverLinkContents title={title}>
      {isLoading || isFetching ? (
        <Centerer width="100%">
          <CircleLoader />
        </Centerer>
      ) : (
        <>
          {reportsToShow.map((report) => (
            <PopoverLinkContentsItem
              key={report.id}
              title={title}
              item={{
                title: report.title,
                description:
                  report.description ??
                  (report.options?.metricsSetting ?? [])
                    .reduce((acc, metric) => {
                      const snapshotMetric = SNAPSHOT_METRICS[metric.id.toUpperCase()];
                      if (snapshotMetric) {
                        acc.push(snapshotMetric.label);
                      }
                      return acc;
                    }, [])
                    .join(', '),
                icon: DASHBOARD_ICONS[report.title],
              }}
              onClick={() => {
                togglePortal();
                setMetadataFilter({});
                history.push(`${pathname}/${report.id}`, { report });
              }}
            />
          ))}
        </>
      )}

      <PopoverLinkActionButton
        parentTitle={title}
        title="Add Report"
        onClick={() => {
          togglePortal();
          handleAddReport();
        }}
      />
    </PopoverLinkContents>
  );
};
