import React from 'react';
import { SelectDropdownButton } from 'components/Buttons';
import { FilterWrapper } from '../styles';

export const WATERFALL_TYPE_OPTIONS = {
  revenue: 'Revenue',
  customers: 'Customers',
  seats: 'Seats',
};

//draftDataFilter, updateDraftDataFilter props come from the Filters component
export const WaterfallTypeFilter = ({ draftDataFilter, updateDraftDataFilter }) => {
  return (
    <FilterWrapper style={{ marginLeft: 8 }}>
      <span>Type: </span>
      <SelectDropdownButton
        showSelectedDirectly
        name="filters__waterfall-type"
        options={WATERFALL_TYPE_OPTIONS}
        selected={draftDataFilter?.waterfallType}
        onSelect={(key) => updateDraftDataFilter({ waterfallType: WATERFALL_TYPE_OPTIONS[key] })}
      />
    </FilterWrapper>
  );
};
