import React from 'react';
import styled from 'styled-components';
import { INTEGRATION_SERVICES_WITHOUT_RECONNECT } from 'consts/integrations';
import { useInitiateIntegration } from 'api/integrations';
import { getServiceCategory } from 'models/integration';
import { IconButton } from 'components/Buttons';
import { SyncIcon } from 'components/Icons';

const StyledSyncIcon = styled(SyncIcon)`
  width: 20px;
  height: 20px;
  path {
    fill: var(--primaryGreen);
  }
`;

export const ReconnectIntegrationButton = ({ integration }) => {
  const { initiateIntegrationFlow } = useInitiateIntegration();
  const integrationService = getServiceCategory(integration?.service);

  const handleClickReconnectButton = () => {
    initiateIntegrationFlow({ integration });
  };

  if (INTEGRATION_SERVICES_WITHOUT_RECONNECT.includes(integrationService)) return <></>;
  return (
    <IconButton icon={<StyledSyncIcon />} onClick={handleClickReconnectButton}>
      Reconnect
    </IconButton>
  );
};
