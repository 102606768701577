import React, { useContext, useMemo } from 'react';
import styled from 'styled-components';

import { EVENTS } from 'consts/analytics';
import { useAnalytics } from 'utils/hooks';
import { GoalsContext } from 'shared/Goals';
import { Centerer, Flexer } from 'components/Core';
import { Switch } from 'components/Controls';
import { usePortal } from 'components/Portal';

const Container = styled(Centerer)`
  position: relative;
  z-index: 10;
  margin-left: 6px;
  padding: 4px;
  background: var(--primaryBlue10);
  border-radius: 4px;

  &:hover {
    cursor: pointer;
  }
`;

const ContainerText = styled.div`
  font-weight: 900;
  font-size: 9px;
  line-height: 12px;
  text-transform: uppercase;
  color: ${({ isActive }) => (isActive ? 'var(--secondaryBlue)' : 'var(--primaryBlue)')};

  &:hover {
    color: var(--secondaryBlue);
  }
`;

const DropdownContainer = styled.div`
  min-width: 200px;
  max-width: 400px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  background-color: white;
  border-radius: 10px;
  box-shadow: 15px 15px 60px rgba(0, 21, 46, 0.2);
`;

const LineItem = styled(Centerer)`
  width: 100%;

  padding: 8px;
  border-bottom: 1px solid var(--primaryBlack5);

  &:hover {
    background-color: rgba(0, 21, 46, 0.01);
    cursor: pointer;
  }
`;

const TextWrap = styled(Flexer)`
  justify-content: start;
  align-items: center;
  width: 100%;
  padding: 2px 7px;

  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--primaryBlack);
`;

export const SelectGoalDropdown = ({ metric }) => {
  const { triggerRef, togglePortal, isPortalVisible, Portal } = usePortal({ XOffset: -40, YOffset: -5 });
  const { trackEvent } = useAnalytics();
  const { goals, selectedGoals, setSelectedGoals } = useContext(GoalsContext);

  const options = useMemo(
    () =>
      Object.entries(goals?.[metric] ?? {}).map(([goalId, goalValues]) => ({
        key: goalId,
        value: goalValues.name,
      })),
    [goals, metric],
  );
  const activeOptions = selectedGoals[metric] ?? [];

  const setActiveOptions = (data) => {
    if (data) {
      trackEvent({
        name: EVENTS.SELECT_DISPLAYED_GOAL_ROW,
        properties: { metric: metric },
      });
    }
    setSelectedGoals({ ...selectedGoals, [metric]: data });
  };

  return (
    <>
      <Container ref={triggerRef}>
        <ContainerText isActive={isPortalVisible} onClick={togglePortal}>
          Goals
        </ContainerText>
      </Container>
      {isPortalVisible && (
        <Portal>
          <OpenedDropdown options={options} activeOptions={activeOptions} setActiveOptions={setActiveOptions} />
        </Portal>
      )}
    </>
  );
};

const OpenedDropdown = ({ options, activeOptions, setActiveOptions }) => {
  const toggleLineItem = (key) => {
    if (!activeOptions.some((lineItemKey) => key === lineItemKey)) setActiveOptions([...activeOptions, key]);
    else setActiveOptions(activeOptions.filter((lineItem) => lineItem !== key));
  };

  return (
    <DropdownContainer>
      {options.map(({ key, value }) => (
        <LineItem key={key} onClick={() => toggleLineItem(key)}>
          <Switch checked={activeOptions.some((lineItem) => lineItem === key)} />
          <TextWrap>{value}</TextWrap>
        </LineItem>
      ))}
    </DropdownContainer>
  );
};
