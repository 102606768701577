import styled from 'styled-components';
import { Flexer, FlexBetweenContainer, Centerer } from 'components/Core';
import { ReactTableRow } from 'components/Table';

export const TableControlWrapper = styled(FlexBetweenContainer)`
  padding: 20px 40px;
`;

export const TableWrapper = styled.div`
  padding: 0 40px 40px;
  width: 100%;
  height: 90vh;
  overflow: scroll;
`;

export const LoadingSpinnerWrapper = styled(Flexer)`
  position: relative;
  margin-right: 45px;
  height: 24px;
`;

export const TransactionName = styled.span`
  font-weight: 700;
`;

export const ReviewTransactionsReactTableRow = styled(ReactTableRow)`
  align-items: center;
  height: fit-content;
  padding: ${({ subRow }) => (subRow ? '16px 12px' : '20px 12px')};

  ${({ subRow, firstChild, lastChild, disabled, selected }) =>
    subRow
      ? `
      background: ${
        selected ? 'linear-gradient(rgba(235, 246, 251, 1), rgba(235, 246, 251, 1)), var(--accentGrayer)' : 'white'
      };
      border: 1px solid var(--accentGraySecond);
      ${firstChild ? 'border-top-left-radius: 16px; border-top-right-radius: 16px;' : ''}
      ${lastChild ? 'border-bottom-left-radius: 16px; border-bottom-right-radius: 16px;' : 'border-bottom: none;'}
      ${disabled ? 'cursor: auto; &:hover { background: white }; opacity: 0.5;' : 'cursor: pointer'}
    `
      : `
      background: transparent;
      border-radius: 0px;
      cursor: auto;
      border: none;
    `}
`;

export const IconWrapper = styled(Centerer)`
  cursor: pointer;
  padding: 4px;
  border-radius: 4px;
  background-color: var(--primaryBlue10);
`;
