import { useQuery, useMutation, useQueryClient } from 'react-query';

import { getGoals, createGoal, updateGoal, deleteGoal, getGoal } from './goals';
import { postPeriodsBulk, updatePeriodsBulk } from './periods';
import { hydratePeriodsBTF, hydratePeriodsFTB } from './hydration';

import { useToasts } from 'components/Toasts';

export const useGoalsAPI = ({ orgId, autoFetch = true, enableToasts = true }) => {
  const { pushToast: wrappedPushToast } = useToasts();
  const pushToast = (...args) => {
    if (enableToasts) wrappedPushToast(...args);
  };

  const { data, error, isLoading, isFetching } = useQuery(
    ['goals', orgId],
    async () => {
      const goals = await getGoals({ orgId });
      goals.forEach((goal) => {
        if (goal.periods === null) return;
        goal.periods = hydratePeriodsBTF({ periods: goal.periods });
      });

      return goals;
    },
    { enabled: autoFetch },
  );

  const queryClient = useQueryClient();

  const addGoal = useMutation(({ data }) => createGoal({ orgId, body: data }), {
    onSuccess: () => {
      pushToast('Successfully created goal!', 'success');
    },
    onError: (err) => {
      pushToast(`Failed to create goal. ${err}`, 'error');
    },
    onSettled: () => {
      queryClient.invalidateQueries('goals');
    },
  });

  const editGoal = useMutation(({ id, data }) => updateGoal({ orgId, goalId: id, body: data }), {
    onSuccess: () => {
      pushToast('Successfully updated goal!', 'success');
    },
    onError: (err) => {
      pushToast(`Failed to update goal. ${err}`, 'error');
    },
    onSettled: () => {
      queryClient.invalidateQueries('goals');
    },
  });

  const removeGoal = useMutation(({ id }) => deleteGoal({ orgId, goalId: id }), {
    onSuccess: () => {
      pushToast('Successfully removed goal!', 'success');
    },
    onError: (err) => {
      pushToast(`Failed to remove goal. ${err}`, 'error');
    },
    onSettled: () => {
      queryClient.invalidateQueries('goals');
    },
  });

  const addGoalWithPeriods = useMutation(
    async ({ data }) => {
      const { periods, ...rest } = data;

      const goal = await createGoal({ orgId, body: rest });
      await postPeriodsBulk({ orgId, goalId: goal.id, body: hydratePeriodsFTB({ periods }) });
    },
    {
      onSuccess: () => {
        pushToast('Successfully added goal with periods!', 'success');
      },
      onError: (err) => {
        pushToast(`Failed to add goal with periods. ${err}`, 'error');
      },
      onSettled: () => {
        queryClient.invalidateQueries('goals');
      },
    },
  );

  const editGoalWithPeriods = useMutation(
    async ({ id, data }) => {
      const { periods, ...rest } = data;
      const goal = await updateGoal({ orgId, goalId: id, body: rest });
      await updatePeriodsBulk({ orgId, goalId: goal.id, body: hydratePeriodsFTB({ periods }) });
    },
    {
      onSuccess: () => {
        pushToast('Successfully updated goal with periods!', 'success');
      },
      onError: (err) => {
        pushToast(`Failed to update goal with periods. ${err}`, 'error');
      },
      onSettled: () => {
        queryClient.invalidateQueries('goals');
      },
    },
  );

  return {
    data,
    error,
    isLoading,
    isFetching,
    operations: {
      addGoal,
      addGoalWithPeriods,
      editGoal,
      editGoalWithPeriods,
      removeGoal,
    },
  };
};

export const useGoalAPI = ({ orgId, goalId, autoFetch = true }) => {
  const { data, error, isLoading, isFetching } = useQuery(
    ['goals', orgId, goalId],
    async () => {
      const goal = await getGoal({ orgId, goalId });
      if (goal.periods === null) return;
      goal.periods = hydratePeriodsBTF({ periods: goal.periods });
      return goal;
    },
    { enabled: autoFetch && !!goalId },
  );

  return {
    data,
    error,
    isLoading,
    isFetching,
  };
};
