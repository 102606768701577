import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { Modal, ModalBody, ModalCloseIcon, ModalContainer } from 'components/Modal';
import { CircleLoader } from 'components/Loaders';
import { Centerer } from 'components/Core';
import { replaceDynamicVariablesForInvoice } from 'api/billing/requests';
import { convertToPlainText } from 'utils/htmlUtils';
import { getInvoiceData } from './utils';

const ModalHeader = styled.span`
  font-size: 16px;
  font-weight: 700;
`;

const PreviewButton = styled.span`
  width: fit-content;
  white-space: nowrap;
  color: var(--primaryBlue);
  background: rgba(0, 133, 255, 0.1);
  border-radius: 4px;
  padding: 4px;
  cursor: pointer;
  font-weight: 700;

  &:hover {
    color: var(--secondaryBlue);
    background: rgba(0, 133, 255, 0.2);
  }

  &:focus {
    color: var(--secondaryBlue);
    background: rgba(0, 133, 255, 0.2);
  }
`;

const Field = styled.span`
  white-space: pre-line;
`;

const Divider = styled.hr`
  border: none;
  height: 1px;
  margin: 4px 0;
  background-color: var(--lightGray);
`;

export const ReplacedTemplateVariablesModal = ({ onClose, templateFieldResults }) => (
  <ModalContainer data-cy="preview-variables-modal">
    <Modal height="auto" width="1000px">
      <ModalBody paddingBottom="50px" height="700px">
        <ModalCloseIcon data-cy="preview-variables-modal__close" onClose={onClose} />
        {templateFieldResults ? (
          <>
            <ModalHeader>Memo</ModalHeader>
            <Field>{templateFieldResults.memo}</Field>
            <Divider />
            {templateFieldResults.secondary_memo?.length > 0 && (
              <>
                <ModalHeader>Secondary Memo</ModalHeader>
                <Field>{templateFieldResults.secondary_memo}</Field>
                <Divider />
              </>
            )}
            <ModalHeader>Email Subject</ModalHeader>
            <Field>{templateFieldResults.email_subject}</Field>
            <Divider />
            <ModalHeader>Email Body</ModalHeader>
            <Field>{templateFieldResults.email_body}</Field>
          </>
        ) : (
          <Centerer>
            <CircleLoader />
          </Centerer>
        )}
      </ModalBody>
    </Modal>
  </ModalContainer>
);

export const useReplacedTemplateVariablesModal = ({ orgId, invoiceId, invoiceData }) => {
  const [showModal, setShowModal] = useState(false);
  const [templateFieldResults, setTemplateFieldResults] = useState();

  const closeModal = () => {
    setShowModal(false);
    setTemplateFieldResults(null);
  };

  const openModal = async () => {
    setShowModal(true);
    const data = {
      ...invoiceData,
      memo: convertToPlainText(invoiceData.memo),
      secondary_memo: convertToPlainText(invoiceData.secondary_memo),
      email_subject: convertToPlainText(invoiceData.email_subject),
      email_body: convertToPlainText(invoiceData.email_body),
    };

    const result = await replaceDynamicVariablesForInvoice({ orgId, invoiceId, body: { data: getInvoiceData(data) } });
    setTemplateFieldResults(result);
  };

  const Modal = useCallback(
    () =>
      showModal ? (
        <ReplacedTemplateVariablesModal onClose={closeModal} templateFieldResults={templateFieldResults} />
      ) : (
        <></>
      ),
    [showModal, templateFieldResults],
  );

  const OpenReplacedTemplateVariablesModalButton = ({ dataCy }) => (
    <PreviewButton data-cy={dataCy} onClick={openModal}>
      Preview
    </PreviewButton>
  );

  return {
    OpenReplacedTemplateVariablesModalButton,
    ReplacedTemplateVariablesModal: Modal,
  };
};
