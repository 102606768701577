import React from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { BillingDashboard } from './Pages/BillingDashboard/BillingDashboard';
import { BillingSingleInvoice } from './Pages/BillingSingleInvoice';
import { BillingInvoices } from './Pages/BillingInvoices';
import { BillingAgingReport } from './Pages/BillingAgingReport';
import { SchedulesExternalUpdates } from './Pages/SchedulesExternalUpdates/SchedulesExternalUpdates';
import { BILLING_PAGES_ROUTES } from './consts';

const PAGES = {
  [BILLING_PAGES_ROUTES.ROOT]: BillingDashboard,
  [BILLING_PAGES_ROUTES.DASHBOARD]: BillingDashboard,
  [BILLING_PAGES_ROUTES.INVOICES]: BillingInvoices,
  [`${BILLING_PAGES_ROUTES.INVOICES}/:invoiceId`]: BillingSingleInvoice,
  [BILLING_PAGES_ROUTES.AR_AGING_REPORT]: BillingAgingReport,
  [BILLING_PAGES_ROUTES.SCHEDULES_EXTERNAL_UPDATES]: SchedulesExternalUpdates,
};

export const BillingPagesCoordinator = ({ exportScreenshotRef }) => {
  const location = useLocation();
  const matchSingleInvoice = useRouteMatch(`${BILLING_PAGES_ROUTES.INVOICES}/:invoiceId`);
  const PageComponent =
    PAGES?.[location?.pathname] ?? (matchSingleInvoice?.isExact && PAGES?.[matchSingleInvoice.path]);

  if (!PageComponent) {
    return <></>;
  }

  return <PageComponent exportScreenshotRef={exportScreenshotRef} />;
};
