import { useCallback, useContext, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { AppContext } from 'AppContext';
import { useEvaluatePricingPlanFormulaAPI } from 'api/usageBasedEngine/hooks';
import { useClickOutside, useToasts } from 'utils/hooks';
import { Modal, ModalContainer } from 'components/Modal';
import { Centerer, Flexer } from 'components/Core';
import { FormikCustomInput } from 'components/Controls';
import { TooltipContainer } from 'components/Tooltip';
import { CircleLoader } from 'components/Loaders';
import { InputErrorMessage } from 'components/Blocks';
import {
  InputSuffix,
  StyledNumberInput,
  numberFormatter,
  numberParser,
} from 'components/Controls/FormikCustomInput/FormikCustomInput';
import { ReactComponent as FunctionSymbol } from 'images/function-symbol.svg';
import { ReactComponent as CheckIcon } from 'images/check-with-no-circle.svg';
import { ReactComponent as CloseIcon } from 'images/X-icon.svg';
import { ReactComponent as TriangleIcon } from 'images/input_error_message_triagle.svg';

import {
  FORMULA_DATA_VARIABLES,
  FORMULA_SUPPORTED_FUNCTIONS_USAGE,
  FORMULA_SUPPORTED_FUNCTIONS_VALUES,
  FORMULA_SUPPORTED_SYNTAX,
} from './consts';
import { formulaStringToHTMLString } from './utils';
import { FormulaDisplayCSS } from './styles';

const FormulaInputWrapper = styled(Flexer)`
  align-items: center;
  padding: 16px;
  gap: 8px;
  background-color: white;
  box-shadow: 0px 4px 20px 0px var(--primaryBlack10);
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  font-family: 'Ubuntu Mono';
  position: relative;
`;

const FormulaStyledDisplay = styled.div`
  ${FormulaDisplayCSS}

  width: 100%;
  padding: 12px;
`;

const SymbolTag = styled(Centerer)`
  width: fit-content;
  border-radius: 4px;
  padding: 2px 4px;
  font-family: 'Ubuntu Mono';
  font-size: 14px;

  &:hover {
    cursor: pointer;
  }

  ${({ type }) =>
    type === 'function'
      ? css`
          color: var(--secondaryGreen);
          border: 1px solid #14ae651a;
          &:hover {
            background-color: #14ae651a;
          }
        `
      : type === 'data'
      ? css`
          color: var(--additionalPurple);
          border: 1px solid var(--primaryPurple10);
          &:hover {
            background-color: var(--primaryPurple10);
          }
        `
      : css`
          color: var(--primaryBlack);
          border: 1px solid var(--primaryBlack10);
          &:hover {
            background-color: var(--primaryBlack10);
          }
        `};
`;

const SectionsWrapper = styled.div`
  padding: 16px;
`;

const TagSection = styled(Flexer)`
  flex-wrap: wrap;
  gap: 8px;
`;

const SectionTitle = styled.div`
  font-size: 14px;
  font-style: italic;
  color: var(--primaryBlack50);
  margin-top: 16px;
  margin-bottom: 10px;
`;

const TooltipContent = styled.span`
  font-size: 10px;
`;

const FooterSection = styled(Flexer)`
  width: 100%;
  border-top: 1px solid var(--neutralGray);
  padding: 16px;
  align-items: center;
  gap: 8px;
`;

const FooterText = styled.div`
  font-size: 14px;
  font-style: italic;
  color: var(--primaryBlack50);
`;

const EvaluationResultText = styled(Flexer)`
  align-items: center;
  color: var(--primaryBlack);
  font-weight: 700;
`;

const CheckButton = styled(Centerer)`
  border-radius: 50%;
  background-color: var(--darkGreen);
  min-width: 26px;
  min-height: 26px;

  &:hover {
    opacity: 0.9;
    cursor: pointer;
  }
`;

const ClearButton = styled(Centerer)`
  border-radius: 50%;
  min-width: 26px;
  min-height: 26px;

  &:hover {
    background-color: var(--accentWhiterGray);
    cursor: pointer;
  }
`;

const StyledCloseIcon = styled(CloseIcon)`
  opacity: 0.5;
`;

const FormulaWrapper = styled.div`
  position: relative;
`;

const TestButton = styled.div`
  background-color: var(--primaryBlue10);
  color: var(--primaryBlue);
  padding: 2px 4px;
  border-radius: 4px;
  width: fit-content;
  font-weight: 700;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  &:hover {
    cursor: pointer;
    background-color: var(--primaryBlue20);
  }
`;

const ControlInputDisplay = styled.div`
  ${({ show }) =>
    show
      ? css`
          width: 100%;
        `
      : css`
          width: 0px;
          height: 0px;
          & * {
            padding: 0;
            width: 0px;
            height: 0px;
            background-color: transparent;
          }
        `}
`;

export const FormulaSchemeModal = ({ onClose, name, value, onSave }) => {
  const { orgId } = useContext(AppContext);

  const [formula, setFormula] = useState(value);
  const [units, setUnits] = useState(10);
  const [cumulativeUnits, setCumulativeUnits] = useState(100);
  const [evaluationResult, setEvaluationResult] = useState(0);
  const [isInputSelected, setIsInputSelected] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const isEvaluateBeforeSave = useRef(false);

  const {
    isFetching: isEvaluating,
    operations: { evaluateFormula: _evaluateFormula },
  } = useEvaluatePricingPlanFormulaAPI({
    orgId,
    enableToasts: false,
    onSuccess: (data) => {
      if (isEvaluateBeforeSave.current) {
        onSave(formula);
        onClose();
      } else {
        setEvaluationResult(data?.result ?? 0);
      }
    },
    onError: (error) => {
      setErrorMessage(error?.response?.data?.errors?.message);
    },
  });

  const { pushToast } = useToasts();

  const triggerRef = useClickOutside(onClose);

  const handleTagClick = (tag) => {
    navigator.clipboard.writeText(tag);
    pushToast(`Copied ${tag} to clipboard!`, 'success');
  };

  const evaluateFormula = ({
    params = {
      formula,
      variables: {
        $units: 1,
        $cumulativeUnits: cumulativeUnits,
      },
    },
    isEvaluateBeforeSave: _isEvaluateBeforeSave, // the evaluation before saving the formula
  }) => {
    _evaluateFormula(params);
    isEvaluateBeforeSave.current = !!_isEvaluateBeforeSave;
  };

  return (
    // this is override the z-index of error popovers
    <ModalContainer zIndex={1000}>
      <Modal
        ref={triggerRef}
        overflow="visible"
        width="700px"
        minHeight="200px"
        data-cy="pricing-plan-formula-scheme-modal"
        height="auto"
        maxHeight="auto"
        background="var(--accentGray)"
        padding="0px"
      >
        <FormulaInputWrapper>
          <Flexer alignItems="center" gap="5px">
            <FunctionSymbol /> =
          </Flexer>

          <Flexer width="100%">
            {!isInputSelected && (
              <FormulaStyledDisplay
                dangerouslySetInnerHTML={{
                  __html: formulaStringToHTMLString(formula),
                }}
                onClick={() => {
                  setIsInputSelected(true);
                  document.getElementById(name)?.focus();
                }}
              />
            )}

            <ControlInputDisplay show={isInputSelected}>
              <FormikCustomInput
                name={name}
                value={formula}
                handleChange={(value) => setFormula(value)}
                inputTextAlign="right"
                placeholder="...Enter formula"
                errorWithoutTooltip
                inputWidth="100%"
                width="100%"
                style={{
                  border: 'none',
                }}
                onBlur={() => setIsInputSelected(false)}
              />
            </ControlInputDisplay>
          </Flexer>

          <Flexer gap="8px" alignItems="center">
            <ClearButton onClick={() => setFormula('')}>
              <StyledCloseIcon />
            </ClearButton>

            {isEvaluating ? (
              <CircleLoader width="20px" height="20px" />
            ) : (
              <CheckButton onClick={() => evaluateFormula({ isEvaluateBeforeSave: true })}>
                <CheckIcon />
              </CheckButton>
            )}
          </Flexer>
        </FormulaInputWrapper>

        {errorMessage && (
          <InputErrorMessage>
            <TriangleIcon />
            {errorMessage}
          </InputErrorMessage>
        )}

        <SectionsWrapper>
          <SectionTitle>Functions:</SectionTitle>
          <TagSection>
            {Object.values(FORMULA_SUPPORTED_FUNCTIONS_VALUES).map((symbol) => (
              <TooltipContainer
                key={symbol}
                toolTipContent={<TooltipContent>{FORMULA_SUPPORTED_FUNCTIONS_USAGE[symbol]}</TooltipContent>}
                width={150}
              >
                <SymbolTag type="function" onClick={() => handleTagClick(symbol)}>
                  {symbol}
                </SymbolTag>
              </TooltipContainer>
            ))}
          </TagSection>

          <SectionTitle>Data:</SectionTitle>
          <TagSection>
            {Object.values(FORMULA_DATA_VARIABLES).map((symbol) => (
              <SymbolTag key={symbol} type="data" onClick={() => handleTagClick(symbol)}>
                {symbol}
              </SymbolTag>
            ))}
          </TagSection>

          <SectionTitle>Other supported syntax:</SectionTitle>
          <TagSection>
            {FORMULA_SUPPORTED_SYNTAX.map((item) => (
              <TooltipContainer
                key={item.symbol}
                toolTipContent={<TooltipContent>{item.description}</TooltipContent>}
                width={150}
              >
                <SymbolTag onClick={() => handleTagClick(item.symbol)}>{item.symbol}</SymbolTag>
              </TooltipContainer>
            ))}
          </TagSection>
        </SectionsWrapper>

        <FooterSection>
          <FooterText>Test your formula:</FooterText>

          <FormulaWrapper>
            <StyledNumberInput
              inputWidth="160px"
              inputTextAlign="right"
              formatter={numberFormatter}
              parser={numberParser}
              value={units}
              onChange={(value) => setUnits(value)}
            />

            <InputSuffix
              suffixColor="var(--primaryBlack50)"
              isSuffixStart
              style={{
                fontWeight: 400,
              }}
            >
              $units
            </InputSuffix>
          </FormulaWrapper>

          <FormulaWrapper>
            <StyledNumberInput
              inputWidth="200px"
              inputTextAlign="right"
              formatter={numberFormatter}
              parser={numberParser}
              value={cumulativeUnits}
              onChange={(value) => setCumulativeUnits(value)}
            />

            <InputSuffix
              suffixColor="var(--primaryBlack50)"
              isSuffixStart
              style={{
                fontWeight: 400,
              }}
            >
              $cumulativeUnits
            </InputSuffix>
          </FormulaWrapper>

          <TestButton
            onClick={() => {
              setErrorMessage('');
              evaluateFormula({
                params: {
                  formula,
                  variables: {
                    $units: units,
                    $cumulativeUnits: cumulativeUnits,
                  },
                },
              });
            }}
            disabled={isEvaluating}
          >
            Test
          </TestButton>

          <FooterText>,</FooterText>

          <EvaluationResultText>
            <FunctionSymbol /> = {evaluationResult}
          </EvaluationResultText>
        </FooterSection>
      </Modal>
    </ModalContainer>
  );
};

export const useFormulaSchemeModal = () => {
  const [openModalProps, setOpenModalProps] = useState({});

  const [showModal, setShowModal] = useState(false);
  const openModal = useCallback(
    (openModalProps = {}) => {
      setOpenModalProps(openModalProps);
      setShowModal(true);
    },
    [setShowModal],
  );
  const closeModal = useCallback(() => setShowModal(false), [setShowModal]);

  const Modal = useCallback(
    (props) => showModal && <FormulaSchemeModal {...openModalProps} {...props} onClose={closeModal} />,
    [closeModal, openModalProps, showModal],
  );

  return {
    openModal,
    FormulaSchemeModal: Modal,
  };
};
