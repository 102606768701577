import { isEmpty } from 'lodash';
import { humanize } from 'utils/stringUtils';

export const getUnprocessedMappingsMessage = ({ unprocessedMappings }) => {
  if (isEmpty(unprocessedMappings)) return null;

  let message =
    'Some mappings could not be saved due to their complexities, please contact us if you would like to change them: ';
  message += Object.entries(unprocessedMappings)
    .map(([key, value]) => `${humanize(key)} (${value.map(humanize).join(', ')})`)
    .join('; ');

  return message;
};
