import React, { useContext } from 'react';
import { SizedLoader } from 'components/Loaders';
import { ModalContainer, FullscreenModal } from 'components/Modal';
import { InvoicingScheduleChangesContainer } from 'shared/InvoicingScheduleChanges';

import { InvoicingScheduleContext } from './InvoicingScheduleContext';
import { InvoicingScheduleForm } from './InvoicingScheduleForm';
import { InvoicingScheduleCloseButton } from './InvoicingScheduleCloseButton';
import { ContentWrapper, LoaderContainer, ContentMain, InvoicingScheduleBody } from './InvoicingScheduleLayout.styles';
import { INVOICING_SCHEDULE_TABS_PANEL_TABS, InvoicingScheduleTabsPanel } from './InvoicingScheduleTabsPanel';
import { InvoicingScheduleDeleteModal } from './InvoicingScheduleDeleteModal';
import { InvoiceScheduleSingleInvoicePanel } from './InvoiceScheduleSingleInvoicePanel/InvoiceScheduleSingleInvoicePanel';
import { InvoicingScheduleCreditNotePanel } from './InvoicingScheduleCreditNotePanel';
import { InvoicingScheduleHistoryChangesPanel } from './InvoicingScheduleHistoryChangesPanel';
import { useInvoiceWarnings } from './InvoiceScheduleWarnings/useInvoiceWarnings';
import { CREATE_INVOICING_SCHEDULE_MODES, CreateInvoicingScheduleModal } from './CreateInvoicingScheduleModal';
import { DisconnectedIntegrationBanner } from '../Common/DisconnectedIntegrationBanner';

export const InvoicingScheduleLayout = () => {
  const {
    loading,
    showDeleteModal,
    invoicingScheduleFormValues,
    isInvoiceLoading,
    isInvoiceFetching,
    selectedTabsPanelTab,
    customer,
    closeModal,
    glIntegration,
  } = useContext(InvoicingScheduleContext);

  const { WarningsTab, WarningsButton } = useInvoiceWarnings();

  const isCreateMode = !invoicingScheduleFormValues?.id;

  return isCreateMode && !loading ? (
    <InvoicingScheduleChangesContainer>
      <InvoicingScheduleForm>
        <CreateInvoicingScheduleModal
          initialMode={
            customer?.id ?? invoicingScheduleFormValues?.customer_id
              ? CREATE_INVOICING_SCHEDULE_MODES.SETTINGS
              : CREATE_INVOICING_SCHEDULE_MODES.SELECT_CUSTOMER
          }
          onClose={closeModal}
        />
      </InvoicingScheduleForm>
    </InvoicingScheduleChangesContainer>
  ) : (
    <ModalContainer data-cy="invoice-modal">
      <FullscreenModal>
        <InvoicingScheduleCloseButton />
        <ContentWrapper>
          {loading ? (
            <LoaderContainer>
              <SizedLoader size={40} />
            </LoaderContainer>
          ) : (
            <InvoicingScheduleChangesContainer>
              <InvoicingScheduleForm>
                <ContentMain>
                  <DisconnectedIntegrationBanner scheduleHeaderView showOnlyForIntegrationId={glIntegration?.id} />
                  <InvoicingScheduleBody>
                    <InvoicingScheduleTabsPanel WarningsButton={WarningsButton} />
                    {selectedTabsPanelTab === INVOICING_SCHEDULE_TABS_PANEL_TABS.INVOICES && (
                      <>
                        <InvoiceScheduleSingleInvoicePanel />
                        {invoicingScheduleFormValues?.id &&
                          !isInvoiceLoading &&
                          !isInvoiceFetching &&
                          !!invoicingScheduleFormValues?.invoices?.length && <InvoicingScheduleHistoryChangesPanel />}
                      </>
                    )}

                    {selectedTabsPanelTab === INVOICING_SCHEDULE_TABS_PANEL_TABS.CREDIT_NOTES && (
                      <InvoicingScheduleCreditNotePanel />
                    )}

                    {selectedTabsPanelTab === INVOICING_SCHEDULE_TABS_PANEL_TABS.WARNINGS && <WarningsTab />}
                  </InvoicingScheduleBody>
                </ContentMain>
                {showDeleteModal && <InvoicingScheduleDeleteModal />}
              </InvoicingScheduleForm>
            </InvoicingScheduleChangesContainer>
          )}
        </ContentWrapper>
      </FullscreenModal>
    </ModalContainer>
  );
};
