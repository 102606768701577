import styled from 'styled-components';
import { Centerer } from 'components/Core';
import { cssVar } from 'polished';

export const CHART_COLOR_CURRENT = cssVar('--primaryBlue');
// https://www.figma.com/file/EF0608NGesHrocQ4nP8UaO/Subscript-%E2%80%94-Styleguide?node-id=9617%3A973
// Bottom 2 rows
export const CHART_COLORS = [
  '#FF9C9C',
  '#FFBF91',
  '#FFCF88',
  '#89EAAE',
  '#7BFFDF',
  '#C4B6FF',
  '#F1B8FF',
  '#D0D0D0',
  '#FFCFCF',
  '#FFD9BE',
  '#FFE4BC',
  '#BCFFD6',
  '#B5FAE9',
  '#DFD7FF',
  '#F8DAFF',
  '#E9E9E9',
];

export const EmptyState = styled(Centerer)`
  flex: 1;
  flex-direction: column;

  div {
    max-width: 240px;
  }
`;

export const EmptyStateTitle = styled.div`
  font-weight: 900;
  font-size: 14px;
  line-height: 20px;
`;

export const EmptyStateDescription = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: var(--primaryBlack50);
`;

export const ChartWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const Hint = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
`;

export const LabelsWrapper = styled(Centerer)`
  flex-wrap: wrap;
  padding: 16px 20px 0 20px;
`;

export const LabelDot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background: ${({ color }) => color};
  margin: 0 6px;
`;

export const Label = styled.div`
  font-weight: 900;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  margin: 6px;
`;
