import React, { useContext, useEffect, useCallback, useMemo, useState } from 'react';
import { useTable, useRowSelect } from 'react-table';

import { AppContext } from 'AppContext';
import {
  ReactTable,
  ReactTableBody,
  ReactTableHeader,
  ReactTableHeaderColumn,
  ReactTableHeaderRow,
  HeaderCellWrapper,
} from 'components/Table';

import { TransactionReactTableCell, TransactionReactTableRow, Wrapper } from 'shared/TransactionTable/styles';
import { TransactionSingleContent, TRANSACTION_MODAL_MODE } from 'shared/TransactionContent';
import { generateColumns } from './columns';

const TransactionPreviewTable = ({ data = [], setDataHook }) => {
  const [tableData, setTableData] = useState(data);
  const [modalTransactionIndex, setModalTransactionIndex] = useState(null);

  const updateTableData = useCallback(
    ({ data }) => {
      const updatedTableData = [...tableData];
      updatedTableData[modalTransactionIndex] = data;
      delete updatedTableData[modalTransactionIndex].product_confidence;
      delete updatedTableData[modalTransactionIndex].customer_confidence;
      setTableData(updatedTableData);
    },
    [tableData, modalTransactionIndex],
  );

  const selectedRowData = useMemo(() => {
    return tableData[modalTransactionIndex];
  }, [tableData, modalTransactionIndex]);

  const {
    organizations,
    appSettings: { isARR, currencyISOCode },
  } = useContext(AppContext);

  const columns = useMemo(
    () =>
      generateColumns({
        onTransactionClick: (row) => {
          setModalTransactionIndex(row.index);
        },
        onCustomerClick: () => {},
        isARR,
        currencyISOCode,
      }),
    [isARR, currencyISOCode],
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    state: { selectedRowIds },
  } = useTable(
    {
      columns,
      data: tableData,
    },
    useRowSelect,
  );

  useEffect(() => {
    // Filter tableData to include only the selected rows
    const filteredData = tableData.filter((_, index) => selectedRowIds[index]);
    setDataHook(filteredData);
  }, [setDataHook, tableData, selectedRowIds]);

  return (
    <>
      <Wrapper data-cy="transaction-table">
        {tableData?.length ? (
          <ReactTable pageView={true} data-cy="transactions-table" {...getTableProps()}>
            <ReactTableHeader pageView={true}>
              {headerGroups.map((headerGroup) => {
                return (
                  <ReactTableHeaderRow pageView={true} {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <ReactTableHeaderColumn
                        alignRight={column?.alignRight}
                        customWidth={column.width}
                        {...column.getHeaderProps()}
                      >
                        <HeaderCellWrapper>
                          {column.id === 'actions' ? <></> : column.render('Header')}
                        </HeaderCellWrapper>
                      </ReactTableHeaderColumn>
                    ))}
                  </ReactTableHeaderRow>
                );
              })}
            </ReactTableHeader>
            <ReactTableBody pageView={true} {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);

                return (
                  <TransactionReactTableRow data-cy={'transaction-row'} {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <TransactionReactTableCell
                        isAbsolute={row.canExpand}
                        customWidth={cell.column.width}
                        {...cell.getCellProps()}
                      >
                        {cell.render('Cell')}
                      </TransactionReactTableCell>
                    ))}
                  </TransactionReactTableRow>
                );
              })}
            </ReactTableBody>
          </ReactTable>
        ) : (
          <div>
            Subscript AI was unable to parse any transactions out of this contract. Please try again or reach out to us
            on Slack!
          </div>
        )}

        {modalTransactionIndex !== null && (
          <TransactionSingleContent
            transaction={selectedRowData}
            mode={TRANSACTION_MODAL_MODE.CREATE}
            saveNewTransaction={false}
            organization={organizations[0]}
            closeModal={() => setModalTransactionIndex(null)}
            onTransactionCreated={({ transactionData }) => {
              updateTableData({ data: transactionData });
            }}
          />
        )}
      </Wrapper>
    </>
  );
};

export { TransactionPreviewTable };
