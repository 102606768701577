import { useCallback, useContext } from 'react';

import { AppContext } from 'AppContext';
import { searchResources } from 'api/billing/requests';
import { FormikCustomSelector } from 'components/Controls';

export const IntegrationProductSelector = ({
  name,
  existingGlImports = [],
  integrationId,
  productImportName,
  productImportProviderId,
  handleChange,
  label,
  ...props
}) => {
  const { orgId } = useContext(AppContext);

  const getGLProductsFromSearch = useCallback(
    async ({ searchQuery, integrationId }) => {
      const products = await searchResources({
        params: { searchQuery, resource: 'product', integrationId },
        orgId,
      });
      return (products ?? []).map(({ name, id, ...rest }) => ({
        label: `${name || '<Unknown Name>'} / ${id || ''}`,
        value: { id, name, ...rest },
      }));
    },
    [orgId],
  );
  const loadOptions = (props) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(getGLProductsFromSearch(props));
      }, 250);
    });

  return (
    <FormikCustomSelector
      name={name}
      value={
        productImportProviderId
          ? {
              label: `${productImportName || '<No Name>'} / ${productImportProviderId}`,
              value: {
                id: productImportProviderId,
                name: productImportName,
              },
            }
          : null
      }
      options={existingGlImports
        .filter((existingGlImport) => existingGlImport.integration_id === integrationId)
        .map((existingGlImport) => ({
          label: `${existingGlImport.metadata?.product_name} / ${existingGlImport.provider_object_id}`,
          value: { id: existingGlImport.provider_object_id, name: existingGlImport.metadata?.product_name },
        }))}
      label={label ?? 'General Ledger Product'}
      handleChange={(option) => {
        handleChange({
          providerId: option?.value?.id ?? null,
          name: option?.value?.name ?? null,
          code: option?.value?.code ?? null,
        });
      }}
      boldValue
      loadOptions={(searchQuery) => loadOptions({ searchQuery, integrationId })}
      containerWidth="100%"
      isDisabled={!integrationId}
      placeholder="Type to search..."
      {...props}
    />
  );
};
