import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const RelatedTransactionsContent = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 70%;
  overflow: auto;
  width: 100%;
  margin-top: 20px;
`;

export const RelatedTransactionsItemId = styled.div`
  color: var(--primaryBlue);
  display: inline-block;
  align-items: center;
  transition: color 0.3s;

  &:hover {
    color: var(--secondaryBlue);
  }

  svg {
    margin-right: 6px;
  }
`;

export const RelatedTransactionsItemDates = styled.div`
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  margin-top: 6px;
  opacity: 0.5;
`;

export const RelatedTransactionsItemSeats = styled.div`
  font-size: 12px;
  line-height: 16px;
  margin-top: 6px;

  span {
    opacity: 0.5;
    margin-left: 4px;
  }
`;

export const RelatedTransactionsItem = styled(Link)`
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  cursor: pointer;
  font-size: 14px;
  color: initial;
  line-height: 18px;
  padding-bottom: 14px;
  margin-bottom: 14px;
  border-bottom: 1px solid var(--primaryBlack5);

  &:hover {
    color: initial;
  }

  &:last-child {
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: none;
  }
`;

export const RelatedTransactionsItemColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${({ alignItems }) => alignItems && `align-items: ${alignItems};`}
`;

export const RelatedTransactionsNoItems = styled.div`
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  h5 {
    font-weight: 900;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 14px;
    margin-top: 14px;
  }

  p {
    font-size: 14px;
    line-height: 20px;
    width: 220px;
  }
`;

export const ToolTipContentContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const ToolTipRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ToolTipLabel = styled.span`
  color: white;
  font-weight: 400;
  font-size: 12px;
`;

export const ToolTipValue = styled.span`
  font-weight: 700;
  font-size: 12px;
`;

export const Divider = styled.hr`
  border: none;
  height: 1px;
  margin: 4px 0;
  background-color: #505050;
`;

export const TillCanceledLabel = styled.span`
  font-weight: 900;
  font-size: 9px;
  color: #505050;
`;
