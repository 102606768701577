import React from 'react';
import styled from 'styled-components';
import { transparentize } from 'polished';
import { cssVar } from 'polished';
import { Line, defaults } from 'react-chartjs-2';
import { TooltipIcon, FillDot } from 'components/Icons';
import TooltipContainer from 'components/Tooltip/Tooltip';
import { getResolutionFormats } from '../utils';
import { INSTALL_BY_OPTIONS, COHORTS_DATA_KEYS } from '../consts';

const ChartWrapper = styled.div`
  width: 100%;
  margin: 28px 0 28px;
`;

const ChartContainer = styled.div`
  width: 100%;
  border-bottom: none;
`;

const LegendContainer = styled.div`
  width: 100%;
  height: 38px;
  margin-top: 24px;
  background: white;
  border: 1px solid var(--accentGraySecond);
  box-shadow: 8px 8px 60px var(--primaryBlack4);
  border-radius: 8px;
  display: flex;
`;

const LegendTooltipContainer = styled.div`
  width: 94px;
  padding: 12px 0px 12px 16px;
  color: var(--primaryBlack30);
  font-weight: 800;
  font-size: 10px;
  line-height: 14px;
  text-transform: uppercase;
  transition: opacity ease 0.4s;

  svg {
    width: 12px;
    height: 12px;
    margin-left: 4px;
    vertical-align: sub;
    cursor: pointer;

    g {
      transition: opacity ease 0.4s;
    }
  }

  &:hover {
    opacity: 1;

    svg g {
      opacity: 1;
    }
  }
`;

const KeysContainer = styled.div`
  width: 95%;
  display: flex;
  justify-content: space-around;
  font-weight: 800;
  font-size: 10px;
  line-height: 14px;
  text-transform: uppercase;
  vertical-align: sub;
  padding: 12px 0;
`;

const LEGEND_COLORS = [
  '#1EB76E', //green
  '#0075ff', //blue
  '#FFAB2D', //yellow
  '#957AFF', //purple
  '#ff5959', //red
  '#797979', //grey
  '#09D8A6', //turqoise
  '#A517C9', //magenta
  '#D0D0D0', //lightgrey
  '#E70000', //darkred
];

const MAX_CHART_LINES = 8; // We only show 8 cohortKey lines on the chart

export const CohortLineChart = ({
  isARR,
  cohortsData,
  cohortResolution,
  cohortInstallBy,
  totalAmounts,
  quarters,
  quartersYearOffset,
}) => {
  defaults.global.defaultFontFamily = 'Nunito Sans';

  const { labelPrefix, legendFormatter, getMaxNumberOfLabels } = getResolutionFormats({
    resolution: cohortResolution,
    quarters,
    quartersYearOffset,
  });

  const totalOrAverageKey = totalAmounts ? COHORTS_DATA_KEYS.TOTAL : COHORTS_DATA_KEYS.AVERAGE;

  const chartKeys = Object.keys(cohortsData);

  if (chartKeys.length === 0) return null;

  const maxLabels = getMaxNumberOfLabels(chartKeys[0]);
  const labels = [];
  // column headers
  for (let i = 0; i < maxLabels; i++) {
    const monthNumber = i + 1;
    labels.push(labelPrefix + monthNumber);
  }

  const datasets = [];
  // max 8 lines
  for (let j = 0; j < MAX_CHART_LINES; j++) {
    const cohortKey = chartKeys[j];

    if (!cohortsData[cohortKey]) break;

    const rowData = cohortsData[cohortKey][totalOrAverageKey];

    const lineData = [];
    // row data for each cohort
    for (let k = 0; k < maxLabels - j; k++) {
      const value = rowData[k];
      let dataValue = 0;
      if (value) {
        switch (cohortInstallBy) {
          case INSTALL_BY_OPTIONS.revenueDollar:
            dataValue = Math.round(isARR ? value * 12 : value);
            break;
          case INSTALL_BY_OPTIONS.customerCount:
            dataValue = value;
            break;
          case INSTALL_BY_OPTIONS.revenuePercent:
          case INSTALL_BY_OPTIONS.customerPercent:
            dataValue = Math.floor(value * 100);
            break;
          default:
        }
      }
      lineData.push(dataValue);
    }

    datasets.push({
      label: cohortKey,
      data: lineData,
      lineTension: 0,
      fill: false,
      pointRadius: 0,
      pointHitRadius: 6,
      pointBackgroundColor: LEGEND_COLORS[j],
      pointHoverRadius: 3,
      borderColor: LEGEND_COLORS[j],
      borderWidth: 2,
    });
  }

  const options = {
    responsive: true,
    legend: {
      display: false,
    },
    tooltips: {
      mode: 'index',
      callbacks: {
        label: function (tooltipItem, data) {
          let label = data.datasets[tooltipItem.datasetIndex].label || '';
          if (label) {
            label = legendFormatter(label) + ': ';
          }

          if (cohortInstallBy === INSTALL_BY_OPTIONS.customerCount) {
            label += tooltipItem.yLabel.toString();
          } else if (cohortInstallBy === INSTALL_BY_OPTIONS.revenueDollar) {
            label += '$' + tooltipItem.yLabel.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          } else {
            label += tooltipItem.yLabel.toString() + '%';
          }
          return label;
        },
      },
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
          },
          ticks: {
            padding: 10,
            fontStyle: 'bold',
            fontColor: transparentize(0.8, cssVar('--primaryBlack')),
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            drawBorder: false,
          },
          ticks: {
            padding: 10,
            fontStyle: 'bold',
            fontColor: transparentize(0.6, cssVar('--primaryBlack')),
            maxTicksLimit: 7,
            beginAtZero: true,
            callback: function (value) {
              if (cohortInstallBy === INSTALL_BY_OPTIONS.customerCount) {
                return value.toString();
              } else if (cohortInstallBy === INSTALL_BY_OPTIONS.revenueDollar) {
                return `$${value.toString().replace(/(0{3}$)/g, 'k')}`;
              } else {
                return value.toString() + '%';
              }
            },
          },
        },
      ],
    },
  };

  const CohortChartToolTipContent = () => {
    return <span>{`Only the first ${MAX_CHART_LINES} cohorts of the given date boundaries are displayed`}</span>;
  };

  return (
    <ChartWrapper>
      <ChartContainer data-cy={'cohorts-graph'}>
        <Line
          height={55}
          data={{
            labels,
            datasets,
          }}
          options={options}
        />
      </ChartContainer>
      <LegendContainer>
        <LegendTooltipContainer>
          <TooltipContainer width={200} isVisible={true} underline={false} toolTipContent={CohortChartToolTipContent()}>
            {MAX_CHART_LINES} items
            <TooltipIcon width={12} height={12} />
          </TooltipContainer>
        </LegendTooltipContainer>
        <KeysContainer>
          {chartKeys.map((cohortKey, index) => {
            if (index >= MAX_CHART_LINES) {
              return null;
            }

            return (
              <div key={cohortKey}>
                <FillDot width={8} fill={LEGEND_COLORS[index]} />
                <span style={{ marginLeft: 6 }}>{legendFormatter(cohortKey)}</span>
              </div>
            );
          })}
        </KeysContainer>
      </LegendContainer>
    </ChartWrapper>
  );
};
