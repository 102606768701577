import styled from 'styled-components';

import { Centerer } from 'components/Core';
import { BinIcon } from 'components/Icons';

export const BinContainer = styled(Centerer)`
  width: 38px;
  height: 38px;
  cursor: pointer;
`;

export const BinButton = ({ onClick }) => (
  <BinContainer onClick={onClick}>
    <BinIcon />
  </BinContainer>
);
