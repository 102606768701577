import styled from 'styled-components';
import { ReactComponent as CheckIcon } from 'images/transaction_check.svg';
import { Button } from 'components/Buttons';
import { Spacer } from 'components/Core';

export const CancelButton = styled(Button)`
  background: var(--primaryBlack5);
`;

export const SubmitButtonStyle = styled(Button)`
  color: #ffffff;
  background: var(--primaryGreen);
  padding-right: 8px;
  border: none;
`;

export const SubmitButton = ({ onClick, disabled, children, className }) => {
  return (
    <SubmitButtonStyle className={className} onClick={onClick} disabled={disabled}>
      {children} <Spacer width="10px" /> <CheckIcon />
    </SubmitButtonStyle>
  );
};

export const SelectionButton = styled(Button)`
  width: 100%;
  border-radius: 8px;
  font-weight: 900;

  color: ${(props) => (props.selected ? 'var(--primaryBlue)' : undefined)};
  background: ${(props) => (props.selected ? undefined : 'rgba(57, 161, 220, 0.05)')};
  border: ${(props) => (props.selected ? '2px solid var(--primaryBlue)' : '2px solid rgba(0, 133, 255, 0.1)')};
`;
