import React, { useContext } from 'react';
import dayjs from 'dayjs';
import { AppContext } from 'AppContext';
import { getAppSetting } from 'models/appSettings';
import { WaterfallTable } from 'shared/WaterfallTable';
import { reshapeDataForTable } from 'views/Dashboard';
import { getDataForSnapshot } from './utils';

export const CustomerSnapshot = ({ customer }) => {
  const { start_date, end_date, timeseries_metrics: timeSeriesMetrics } = customer;
  const {
    organizations,
    appSettings: { isARR, setIsARR, optimisticAnalytics, setOptimisticAnalytics, countInfluxAsRenewed },
  } = useContext(AppContext);

  const influxMonths = getAppSetting('influxMonths');
  const hasTransactionsWithRenewalDeadline = organizations[0].hasTransactionsWithRenewalDeadline;
  const useInFlux = !!influxMonths || hasTransactionsWithRenewalDeadline;

  const startDate = dayjs.utc(start_date);
  const endDate = dayjs.utc(end_date).add(1, 'month');

  // includes customer timeseries metrics
  const dataForTable = reshapeDataForTable({
    startDate: startDate,
    endDate: endDate, //to show churn
    rawData: getDataForSnapshot({ customer, isARR }),
    isIncreases: false,
    showWaterFallExistingRow: true,
    showInflux: useInFlux,
    showInfluxMovement: useInFlux,
    waterfallType: 'Revenue',
    timeSeriesMetrics,
    countInfluxAsRenewed,
  });

  return (
    <div style={{ marginTop: 22, marginBottom: 22 }}>
      {customer?.waterfall && (
        <WaterfallTable
          isARR={isARR}
          setIsARR={setIsARR}
          optimisticAnalytics={optimisticAnalytics}
          setOptimisticAnalytics={setOptimisticAnalytics}
          dataForTable={dataForTable}
          isCustomerView={true}
        />
      )}
    </div>
  );
};
