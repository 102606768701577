import React from 'react';
import styled from 'styled-components';
import { NUMBER_FORMATS } from 'consts/global';
import { FlexerRow, FlexerColumn, CentererVertical } from 'components/Core';
import { numberFormatter } from 'utils/formatters';

const RevenueCardsContainer = styled(FlexerRow)`
  width: 100%;
`;

const RevenueCard = styled.div`
  background-color: white;
  width: 33%;
  height: 100%;
  border-top: 1px solid var(--accentGraySecond);
  border-bottom: 1px solid var(--accentGraySecond);
  padding: 20px;

  ${({ firstCard }) =>
    firstCard &&
    `
    border-radius: 20px 0px 0px 20px;
    border-left: 1px solid var(--accentGraySecond);
  `}

  ${({ lastCard }) =>
    lastCard &&
    `
    border-radius: 0px 20px 20px 0px;
    border-right: 1px solid var(--accentGraySecond);
  `}
`;

const RevenueCardContents = styled(FlexerColumn)`
  height: 100%;

  ${({ lastCard }) =>
    !lastCard &&
    `
    border-right: 1px solid var(--accentGraySecond);
  `}
`;

const Number = styled.span`
  font-size: 9px;
  font-weight: 900;
  line-height: 12px;
  padding: 2px 4px;
  background-color: var(--primaryBlack5);
  border-radius: 4px;
`;

const Title = styled.span`
  font-weight: 400;
  font-size: 14px;
`;

const Amount = styled.span`
  margin-top: 16px;
  font-weight: 900;
  font-size: 20px;
`;

const ObjectCount = styled.span`
  margin-top 4px;
  font-weight: 700;
  font-size: 12px;

  b {
    color: var(--primaryBlack50);
    font-weight: 700;
  }
`;

export const RevenueCards = ({ data, accountingCurrency }) => {
  const { accountingRevenue, accruedRevenue, deferredRevenue, detailsPerCustomer, detailsPerTransaction } = data;

  const REVENUE_CARDS = [
    {
      title: 'Accounting Revenue',
      amount: accountingRevenue,
      customerCount: detailsPerCustomer ? Object.keys(detailsPerCustomer).length : null,
      transactionCount: detailsPerTransaction ? Object.keys(detailsPerTransaction).length : null,
    },
    {
      title: 'Accrued Revenue',
      amount: accruedRevenue,
    },
    {
      title: 'Deferred Revenue',
      amount: deferredRevenue,
    },
  ];

  return (
    <RevenueCardsContainer>
      {REVENUE_CARDS.map(({ title, amount, customerCount, transactionCount }, index) => (
        <RevenueCard key={title} firstCard={index === 0} lastCard={index === REVENUE_CARDS.length - 1}>
          <RevenueCardContents lastCard={index === REVENUE_CARDS.length - 1}>
            <CentererVertical gap="8px">
              <Number>{index + 1}</Number>
              <Title>{title}</Title>
            </CentererVertical>

            <Amount>
              {numberFormatter({ type: NUMBER_FORMATS.CURRENCY, rawValue: amount, currency: accountingCurrency })}
            </Amount>

            {customerCount && (
              <ObjectCount>
                {customerCount} <b>customer(s)</b>
              </ObjectCount>
            )}

            {transactionCount && (
              <ObjectCount>
                {transactionCount} <b>transaction(s)</b>
              </ObjectCount>
            )}
          </RevenueCardContents>
        </RevenueCard>
      ))}
    </RevenueCardsContainer>
  );
};
