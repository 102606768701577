import React, { useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { AppContext } from 'AppContext';
import { useAuth0 } from '@auth0/auth0-react';
import { Centerer, CentererVertical, Flexer, FlexBetweenContainer, Spacer } from 'components/Core';
import { InfoCircleOrange } from 'components/Icons';
import { SizedLoader } from 'components/Loaders';
import {
  ModalContainer,
  Modal,
  ModalHeader,
  ModalCloseIcon,
  ModalTitle,
  ModalTitleText,
  ModalBody,
  ModalFooter,
} from 'components/Modal';
import { StyledTableName } from 'components/Table';
import { toSentence } from 'utils/arrayUtils';
import { formatMemberDetails } from 'models/member';
import { getMemberList } from 'api/members';
import { useUsersAPI } from 'api/users';
import { Button } from 'components/Buttons';
import { COLORS } from 'consts/colors';
import { useConfirmModal } from 'shared/ConfirmModal';
import { MembersTable } from './MembersTable';

const HeaderCounter = styled.div`
  padding: 10px 14px;
  font-weight: 900;
  font-size: 16px;
  line-height: 20px;
  background: var(--accentGray);
  border-radius: 100px;
`;

const MemberTableNameTitle = styled.div`
  white-space: nowrap;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
`;

const HeaderCell = styled.div`
  font-weight: 900;
  font-size: 10px;
  line-height: 14px;
  text-transform: uppercase;

  &:last-child {
    width: 80px;
  }
`;

const MemberFooterText = styled.div`
  font-size: 14px;
  margin-left: 20px;

  b {
    font-weight: 700;
  }
`;

const LoaderContainer = styled(Centerer)`
  height: 550px;
`;

const MemberTableContainer = styled(FlexBetweenContainer)`
  padding-top: 30px;
  margin-bottom: 10px;
  border-top: 1px solid var(--primaryBlack10);
`;

const MemberTableSpacer = styled(Spacer)`
  padding-top: 20px;
  margin-bottom: 10px;
`;

const FooterCenterer = styled(CentererVertical)`
  height: 100%;
`;

export const MembersModal = ({ onClose }) => {
  const { organizations } = useContext(AppContext);
  const organizationId = organizations[0]?.id;
  const [members, setMembers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);

  const fetchMembers = async () => {
    const data = await getMemberList(organizationId);
    const memberList = data.map((member) => formatMemberDetails(member));
    setMembers(memberList);
  };

  const {
    operations: { removeUser },
  } = useUsersAPI({ orgId: organizationId, autoFetch: false });

  const { user: thisUser } = useAuth0();

  const { openConfirmModal, ConfirmModal } = useConfirmModal({
    title: 'Delete User',
    content: (
      <div>
        Are you sure you want to delete this user: <b>{selectedUser?.email}</b>?
        <br />
        Doing so will remove them from the Subscript database entirely and they will no longer be able to access the
        platform.
      </div>
    ),
    closeButtonText: 'No, cancel',
    confirmButtonText: 'Yes, delete',
    onConfirm: async () => {
      await removeUser.mutateAsync({ userId: selectedUser?.id });
      await fetchMembers();
    },
    onClose: () => {
      setSelectedUser(null);
    },
  });

  useEffect(() => {
    fetchMembers();
    // eslint-disable-next-line
  }, [organizationId]);

  const columns = useMemo(
    () => [
      {
        Header: () => <HeaderCell>Name</HeaderCell>,
        accessor: 'name',
        width: 180,
        Cell: ({ cell: { value } }) => (
          <StyledTableName>
            <MemberTableNameTitle>{value}</MemberTableNameTitle>
          </StyledTableName>
        ),
      },
      {
        Header: () => <HeaderCell>Email</HeaderCell>,
        accessor: 'email',
        width: 250,
      },
      {
        Header: () => <HeaderCell>Role</HeaderCell>,
        accessor: 'permissions',
        width: 55,
      },
      {
        Header: () => <HeaderCell>Actions</HeaderCell>,
        accessor: 'actions',
        width: 80,
        Cell: ({ cell: { row } }) => {
          return row.original.email !== thisUser?.email ? (
            <Button
              color={COLORS.RED}
              active
              filled
              onClick={() => {
                setSelectedUser(row.original);
                openConfirmModal();
              }}
            >
              Delete
            </Button>
          ) : null;
        },
      },
    ],
    [openConfirmModal, thisUser],
  );

  return (
    <ModalContainer>
      <Modal data-cy="members-modal">
        <ModalCloseIcon onClose={() => onClose()} data-cy="members-modal__close-button" />
        <ModalHeader>
          <ModalTitle>
            <ModalTitleText>
              <b>Members</b>
            </ModalTitleText>
          </ModalTitle>
        </ModalHeader>
        <ModalBody>
          {!members.length ? (
            <LoaderContainer>
              <SizedLoader size={40} />
            </LoaderContainer>
          ) : (
            <>
              <MemberTableSpacer width="100%">
                <MemberTableContainer>
                  <Centerer>
                    <HeaderCounter>{members ? members?.length : '?'} Members</HeaderCounter>
                  </Centerer>
                </MemberTableContainer>
              </MemberTableSpacer>
              <MembersTable dataForTable={members} columns={columns} />
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <FooterCenterer>
            <Flexer>
              <InfoCircleOrange size="30px" />
            </Flexer>
            <div>
              {organizations[0]?.email_domains && (
                <MemberFooterText>
                  Users who sign up with the email domain <b>{toSentence(organizations[0]?.email_domains)}</b>{' '}
                  automatically become members of the organization.
                </MemberFooterText>
              )}
              <MemberFooterText>You can share Subscript links to other members of your organization.</MemberFooterText>
            </div>
          </FooterCenterer>
        </ModalFooter>
      </Modal>
      <ConfirmModal />
    </ModalContainer>
  );
};
