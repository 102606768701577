import dayjs from 'dayjs';
import styled from 'styled-components';
import { NUMBER_FORMATS } from 'consts/global';
import { numberFormatter } from 'utils/formatters';
import { TransactionRecognitionIcon } from 'shared/Icons';
import { TooltipContainer } from 'components/Tooltip';
import { TableActionButton } from 'components/Buttons';

const CustomerName = styled.span`
  color: var(--primaryBlue);
  font-weight: 700;
  cursor: pointer;
`;

const TransactionDate = styled.span`
  font-weight: 700;
`;

const TransactionName = styled.span`
  color: var(--primaryBlue);
  font-weight: 700;
  cursor: pointer;
`;

const AccountingSpreadSum = styled.span`
  font-weight: 700;
`;

const TransactionAmount = styled.span`
  font-weight: 700;
`;

const Difference = styled.span`
  color: var(--primaryRed);
`;

export const getColumns = ({ formatDateWithLocale, handleDismissTransaction }) => {
  const columns = [
    {
      Header: 'CUSTOMER',
      accessor: 'customerName',
      id: 'customerName',
      width: 250,
      Cell: ({ value, row }) => (
        <CustomerName onClick={() => window.open(`/customers/${row.original.customerId}`, '_blank')}>
          {value}
        </CustomerName>
      ),
    },
    {
      Header: 'TRANSACTION DATE',
      accessor: 'transactionDate',
      id: 'transactionDate',
      width: 150,
      Cell: ({ value }) => <TransactionDate>{formatDateWithLocale(value)}</TransactionDate>,
    },
    {
      Header: 'TRANSACTION NAME',
      accessor: 'transactionName',
      id: 'transactionName',
      width: 200,
      Cell: ({ value, row }) => (
        <TransactionName onClick={() => window.open(`/transactions/${row.original.transactionId}`, '_blank')}>
          {value || `${row.original.customerName} - ${dayjs.utc(row.original.transactionDate).format('MMM YY')}`}
        </TransactionName>
      ),
    },
    {
      Header: 'PRODUCT',
      accessor: 'productName',
      id: 'productName',
      width: 150,
      Cell: ({ value }) => value || '[No Product]',
    },
    {
      Header: 'TYPE',
      accessor: 'recognition',
      id: 'recognition',
      width: 50,
      Cell: ({ value }) => <TransactionRecognitionIcon type={value} />,
    },
    {
      Header: 'START',
      accessor: 'startDate',
      id: 'startDate',
      width: 100,
      Cell: ({ value }) => dayjs.utc(value).format('MMM YYYY'),
    },
    {
      Header: 'END',
      accessor: 'endDate',
      id: 'endDate',
      width: 100,
      Cell: ({ value }) => (value ? dayjs.utc(value).format('MMM YYYY') : 'No end date'),
    },
    {
      Header: 'TRANSACTION TOTAL',
      accessor: 'transactionAmount',
      id: 'transactionAmount',
      width: 150,
      alignRight: true,
      Cell: ({ value, row }) => (
        <TransactionAmount>
          {numberFormatter({
            rawValue: value,
            type: NUMBER_FORMATS.CURRENCY,
            currency: row.original.transactionCurrency,
            decimalPlaces: 2,
          })}
        </TransactionAmount>
      ),
    },
    {
      Header: 'REV REC',
      accessor: 'accountingSpreadSum',
      id: 'accountingSpreadSum',
      width: 150,
      alignRight: true,
      Cell: ({ value, row }) => (
        <AccountingSpreadSum>
          {numberFormatter({
            rawValue: value,
            type: NUMBER_FORMATS.CURRENCY,
            currency: row.original.transactionCurrency,
            decimalPlaces: 2,
          })}
        </AccountingSpreadSum>
      ),
    },
    {
      Header: 'DIFFERENCE',
      accessor: 'difference',
      id: 'difference',
      width: 150,
      alignRight: true,
      Cell: ({ value, row }) => (
        <TooltipContainer toolTipContent={value > 0 ? 'Under Recognized' : 'Over Recognized'} width={100}>
          <Difference>
            {numberFormatter({
              rawValue: value,
              type: NUMBER_FORMATS.CURRENCY,
              currency: row.original.transactionCurrency,
              decimalPlaces: 2,
            })}
          </Difference>
        </TooltipContainer>
      ),
    },
    {
      Header: '',
      accessor: 'actions',
      width: 100,
      alignRight: true,
      Cell: ({ row }) => (
        <TableActionButton onClick={handleDismissTransaction(row.original.transactionId)} secondary>
          Dismiss
        </TableActionButton>
      ),
    },
  ];

  return columns;
};
