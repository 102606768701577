import { pick } from 'lodash';
import { SNAPSHOT_METRICS } from 'consts/snapshotMetrics';
import { PAGE_CONTEXT_VALUES } from 'utils/hooks';
import { BOOKINGS_ROWS, MAGIC_NUMBER_SNAPSHOT_ROWS, SNAPSHOT_ROWS_WITH_TTM } from 'models/appSettings/plugins';
import { COMMON_METRICS_DASHBOARD_ID, COMMON_METRICS_DASHBOARD_TITLE } from './ReportBuilder/consts';

export const getSnapshotReportDefaultOptions = (settings) =>
  PAGE_CONTEXT_VALUES.snapshot.pageStates.reduce((acc, option) => {
    if (![null, undefined].includes(settings[option])) {
      acc[option] =
        option === 'dataFilter' ? pick(settings[option], PAGE_CONTEXT_VALUES.snapshot.dataFilter) : settings[option];
    }
    return acc;
  }, {});

export const getCommonMetricsReport = (settings) => {
  const options = getSnapshotReportDefaultOptions(settings);

  // For now will exclude all metrics that need plugin for perf reasons
  // More can be added later according with feedback
  const snapshotTableRows = Object.entries(SNAPSHOT_METRICS).reduce((acc, [, values]) => {
    if (
      SNAPSHOT_ROWS_WITH_TTM.has(values.label) ||
      MAGIC_NUMBER_SNAPSHOT_ROWS.includes(values.label) ||
      BOOKINGS_ROWS.has(values.label) ||
      values.label === SNAPSHOT_METRICS.CUSTOMERS_WITH_OPEN_CONTRACTS.label ||
      values.label === SNAPSHOT_METRICS.CAC_ALL.label ||
      values.label === SNAPSHOT_METRICS.CAC_ALL_TTM.label ||
      values.label === SNAPSHOT_METRICS.LTVCACRatio.label ||
      values.label === SNAPSHOT_METRICS.LTVCACRatio_TTM.label ||
      values.label === SNAPSHOT_METRICS.WINBACK_REVENUE.label ||
      values.label === SNAPSHOT_METRICS.WINBACK_CUSTOMERS.label
    ) {
      return acc;
    }

    acc.push(values.label);
    return acc;
  }, []);

  return {
    title: COMMON_METRICS_DASHBOARD_TITLE,
    description: 'Table with Business Snapshot',
    id: COMMON_METRICS_DASHBOARD_ID,
    type: 'snapshot',
    options: {
      ...options,
      collapseRows: [],
      metricsSetting: [],
      snapshotTableRows,
      snapshotSelectedColumns: {
        years: true,
        months: true,
        quarters: true,
        cumulative: true,
      },
    },
  };
};
