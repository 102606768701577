import React from 'react';
import styled from 'styled-components';
import { ReactComponent as ExplainImage } from 'images/autconfirm-explain-image.svg';
import { ReactComponent as CheckIcon } from 'images/list-check.svg';
import { Modal, ModalBody, ModalCloseIcon, ModalContainer, ModalHeader } from 'components/Modal';
import { useClickOutside } from 'utils/hooks';

const BodyContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 32px;
`;

const ExplainTitle = styled.div`
  font-weight: 900;
  font-size: 20px;
  line-height: 26px;
  margin-bottom: 12px;
`;

const ExplainParagraph = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 12px;
`;

const ExplainList = styled.div`
  display: flex;
  flex-direction: column;
`;

const GrayParagraph = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  padding: 12px;
  background: var(--primaryBlack2);
  border-radius: 8px;
  margin-top: 4px;
  margin-bottom: 12px;
`;

const ListItem = styled.div`
  display: flex;
  align-items: flex-start;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--primaryBlack50);
  margin-bottom: 8px;

  svg {
    margin-right: 8px;
    width: 16px;
    min-width: 16px;
  }
`;

export const AutoConfirmationExplainModal = ({ hideModal }) => {
  const modalRef = useClickOutside(() => hideModal());

  return (
    <ModalContainer>
      <Modal padding="4px 4px" ref={modalRef} maxWidth="540px" height="auto" overflow="hidden">
        <ModalHeader>
          <ModalCloseIcon
            data-cy="auto-confirmation-explain-modal__close-button"
            style={{ width: 32, height: 32 }}
            onClose={() => hideModal()}
          />
        </ModalHeader>
        <ModalBody paddingLeft="4px" paddingRight="4px">
          <ExplainImage width="100%" />

          <BodyContent>
            <ExplainTitle>Transactions & Confirmations</ExplainTitle>
            <ExplainParagraph>
              Only confirmed transactions become part of the analysis that’s computed on the Subscription Metrics page,
              cohorts, and other screens.
            </ExplainParagraph>
            <ExplainParagraph>
              Subscript will confirm new transactions based on our Transaction Analyzer™️, which uses Machine Learning
              and other statistical techniques to:
            </ExplainParagraph>

            <ExplainList>
              <ListItem>
                <CheckIcon /> Identify fields that should be present but are not
              </ListItem>
              <ListItem>
                <CheckIcon /> Analyze pricing relative to typical pricing
              </ListItem>
              <ListItem>
                <CheckIcon /> Identify data that is out of bounds of what is expected and acceptable
              </ListItem>
              <ListItem>
                <CheckIcon /> Identify anomalies in interdependent data between fields
              </ListItem>
              <ListItem>
                <CheckIcon /> Analyze different types of products and different types of revenue per transaction
              </ListItem>
              <ListItem>
                <CheckIcon /> Identify variations contract terms per customer in relation to other contracts from the
                customer
              </ListItem>
              <ListItem>
                <CheckIcon /> Analyze relative dates of all contracts
              </ListItem>
            </ExplainList>

            <GrayParagraph>
              Transactions <b>with anomalies</b> will be included in a list for manual review.
            </GrayParagraph>

            <ExplainParagraph style={{ marginBottom: 0 }}>
              Subscript also imports updates in external systems and reconfirms transactions with those updates.
              Anomalies detected in such updates are also flagged for manual review.
            </ExplainParagraph>
          </BodyContent>
        </ModalBody>
      </Modal>
    </ModalContainer>
  );
};
