import { useContext } from 'react';
import styled from 'styled-components';
import { ReactComponent as WarningIcon } from 'images/warning-icon.svg';
import { TooltipContainer } from 'components/Tooltip';
import { RECOGNITION_TYPES } from 'consts/global';
import { TransactionContext } from '../TransactionContext';

const Wrapper = styled.div`
  background-color: var(--primaryRed10);
  margin: 15px 0;
  border-radius: 12px;
  padding: 0 20px;
  display: flex;
`;

const Sidebar = styled.div`
  padding-right: 20px;
  border-right: 2px solid var(--primaryRed20);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  padding: 14px 0 14px 20px;
`;

export const TransactionLinearToTillCanceledWarning = () => {
  const { currentTransaction, transactionFormValues } = useContext(TransactionContext);

  return currentTransaction.recognition === RECOGNITION_TYPES.linear &&
    transactionFormValues?.recognition === RECOGNITION_TYPES.tillCanceled ? (
    <TooltipContainer toolTipContent="This warning will go away after the transaction is saved">
      <Wrapper>
        <Sidebar>
          <WarningIcon />
        </Sidebar>
        <Content data-cy="transaction-modal__linear-to-till-canceled-warning">
          When changing from Linear to Till-canceled you can set the Monthly Recurring amount instead of the Total
          transaction amount. Confirm that the data is correct
        </Content>
      </Wrapper>
    </TooltipContainer>
  ) : null;
};
