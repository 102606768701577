import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components';
import { EMOJI } from 'consts/global';
import { EVENTS } from 'consts/analytics';
import { useAnalytics, useUrlQuery } from 'utils/hooks';
import { useInvoicingScheduleCheckoutAPI } from 'api/billing/hooks';
import { Flexer } from 'components/Core';
import { PaymentDetailsPanel } from './PaymentDetailsPanel';
import { CheckoutFormContainer } from './CheckoutFormContainer';

const Wrapper = styled(Flexer)`
  min-width: 100%;
  > div {
    flex: 1;
  }
`;

export const InvoiceCheckoutPage = () => {
  const { trackEvent } = useAnalytics();

  const { id } = useParams();
  const urlQuery = useUrlQuery();

  const [errorMessage, setErrorMessage] = useState(null);

  // New checkout link
  const paymentKey = urlQuery.get('paymentKey');
  const invoiceId = urlQuery.get('invoiceId');

  // TODO: Remove this after Sentry for frontend is confirmed to work
  if (paymentKey === 'invalidPaymentKey') {
    throw new Error(`Invalid payment key`);
  }

  // Deprecated checkout link. Still need to support as the links may have already been sent out.
  const paymentId = urlQuery.get('paymentId') || urlQuery.get('payment_intent');

  // Will only be available after Stripe processes the form and redirects back to this page
  const clientSecret = urlQuery.get('payment_intent_client_secret');
  const redirectStatus = urlQuery.get('redirect_status');

  const { data, isLoading, error } = useInvoicingScheduleCheckoutAPI({ id, paymentId, paymentKey, invoiceId });

  useEffect(() => {
    trackEvent({ name: EVENTS.VISIT_INVOICE_CHECKOUT_PAGE });
  }, [trackEvent]);

  // The payment is successful if the customer already has a payment method and the payment intent
  //  didn't fail (so, it either succeeded or is still being processed).
  // OR, we are being redirected from Stripe after completing the checkout form.
  const autoChargingWithPaymentMethod =
    !!data?.hasPaymentMethod &&
    data?.invoicingSchedule?.auto_charge &&
    !data?.paymentLastFailedAt &&
    data?.paymentStatus === 'succeeded';
  const paymentSucceeded =
    autoChargingWithPaymentMethod || ((paymentId || paymentKey) && clientSecret && redirectStatus);

  useEffect(() => {
    if (error?.response?.data?.errors?.message?.includes('Invalid checkout link')) {
      setErrorMessage(null);
      const messageTokens = error.response.data.errors.message.split(':');
      const newPaymentId = messageTokens.length === 2 ? messageTokens[1] : null;
      if (newPaymentId) {
        window.location.href = `/checkout/${id}?paymentId=${newPaymentId}`;
      }
    } else if (!isLoading && error) {
      setErrorMessage(error?.response?.data?.errors?.message ?? `Something went wrong ${EMOJI.NO_GOOD.icon}`);
    } else {
      setErrorMessage(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  return (
    <Wrapper>
      <PaymentDetailsPanel
        invoicingSchedule={data?.invoicingSchedule}
        invoiceId={invoiceId ?? data?.invoice?.id}
        invoice={data?.invoice}
        isLoading={isLoading}
        organizationName={data?.organization_name}
        products={data?.products}
        orgConfigs={data?.orgConfigs}
        language={data?.language}
        translations={data?.translations}
      />
      {!isLoading && (
        <CheckoutFormContainer
          invoice={data?.invoice}
          publicKey={data?.publicKey}
          paymentId={paymentId}
          clientSecret={data?.clientSecret}
          isCurrentInvoiceMarkedPaid={!!data?.invoice?.paid_at}
          paymentSucceeded={paymentSucceeded}
          autoChargingWithPaymentMethod={autoChargingWithPaymentMethod}
          errorMessage={errorMessage}
          language={data?.language}
          translations={data?.translations}
        />
      )}
    </Wrapper>
  );
};
