import * as Yup from 'yup';
import { RECOGNITION_TYPES } from 'consts/global';

export const getSchema = () => {
  const schema = Yup.array().of(
    Yup.object({
      name: Yup.string().required(),
      recognition: Yup.string().required().oneOf(Object.values(RECOGNITION_TYPES)),
      display_name: Yup.string().nullable(),
      description: Yup.string().nullable(),
    }),
  );

  return schema;
};
