import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { getAppSetting } from 'models/appSettings';
import { getEndOfPeriodDayJS } from 'utils/dateUtils';

dayjs.extend(utc);

export const PERIOD_OPTIONS = {
  allTime: 'All time',
  thisMonth: 'This month',
  thisQuarter: 'This quarter',
  lastThreeMonths: 'Last 3 months',
  custom: 'Custom',
  lastQuarter: 'Last quarter',
  thisYear: 'This year',
  lastYear: 'Last year',
  lastTwelveMonths: 'Last 12 months',
};

export const DATA_TYPES = {
  revenue: 'revenue',
  seats: 'seats',
};

// Keep in sync with backend/src/utils/dateUtils.js
export const onSelectPeriod = ({ period, earliestAndLatestSpreadsDates }) => {
  let startMonth, endMonth;
  const quarters = getAppSetting('quarters');

  const endOfCurrentQuarter = getEndOfPeriodDayJS({
    date: new Date(),
    periodType: 'quarter',
    quarters,
  });
  const endOfCurrentYear = getEndOfPeriodDayJS({
    date: new Date(),
    periodType: 'year',
    quarters,
  });

  switch (period) {
    case PERIOD_OPTIONS.thisQuarter:
      endMonth = endOfCurrentQuarter;
      startMonth = endOfCurrentQuarter.subtract(2, 'month');
      break;
    case PERIOD_OPTIONS.lastThreeMonths:
      startMonth = dayjs().subtract(3, 'month');
      endMonth = dayjs().subtract(1, 'month');
      break;
    case PERIOD_OPTIONS.thisMonth:
      startMonth = dayjs().startOf('month');
      endMonth = dayjs().endOf('month');
      break;
    case PERIOD_OPTIONS.custom:
      endMonth = endOfCurrentQuarter;
      startMonth = endOfCurrentQuarter.subtract(2, 'month');
      break;
    case PERIOD_OPTIONS.lastQuarter:
      startMonth = endOfCurrentQuarter.subtract(5, 'month');
      endMonth = endOfCurrentQuarter.subtract(3, 'month');
      break;
    case PERIOD_OPTIONS.thisYear:
      startMonth = endOfCurrentYear.subtract(11, 'month');
      endMonth = endOfCurrentYear;
      break;
    case PERIOD_OPTIONS.lastYear:
      startMonth = endOfCurrentYear.subtract(23, 'month');
      endMonth = endOfCurrentYear.subtract(12, 'month');
      break;
    case PERIOD_OPTIONS.lastTwelveMonths:
      startMonth = dayjs().subtract(12, 'month');
      break;
    case PERIOD_OPTIONS.allTime:
      startMonth = earliestAndLatestSpreadsDates?.earliestdate
        ? dayjs.utc(earliestAndLatestSpreadsDates?.earliestdate).subtract(1, 'month')
        : dayjs.utc().subtract(15, 'year');
      endMonth = earliestAndLatestSpreadsDates?.latestdate
        ? dayjs.utc(earliestAndLatestSpreadsDates?.latestdate).add(1, 'month')
        : dayjs.utc().add(15, 'year');
      break;
    default:
  }

  return {
    period,
    startMonth: startMonth?.toDate(),
    endMonth: endMonth?.toDate() ?? new Date(),
  };
};
