import { useContext, useMemo } from 'react';
import { uniq } from 'lodash';
import styled from 'styled-components';
import dayjs from 'dayjs';

import { AppContext } from 'AppContext';
import { useTransactionsAPI } from 'api/transactions';
import { REQUIRED_BILLING_PARAMS } from 'api/transactions/hooks';
import { Centerer, FlexBetweenContainer, Flexer, FlexerColumn } from 'components/Core';
import { ReactComponent as CheckIcon } from 'images/check-with-no-circle.svg';
import { ExternalLinkFullIcon } from 'components/Icons';
import { numberFormatter } from 'utils/formatters';
import { NUMBER_FORMATS } from 'consts/global';
import { getTransactionAmountByRecognition } from 'views/Billing/InvoicingScheduleModal/utils';

import { IconWrapper } from './ReviewTransactionsTable/ReviewTransactionsTable.styles';

const Wrapper = styled(FlexerColumn)`
  font-size: 12px;
  width: 100%;

  background-color: var(--primaryBlack2);
  border-radius: 8px;
  border: 1px solid var(--primaryBlack3);
`;

const Item = styled(Flexer)`
  padding: 12px 12px 8px;
  width: 100%;
  align-items: center;

  &:not(:last-child) {
    border-bottom: 1px solid var(--primaryBlack3);
  }
`;

const Button = styled(Centerer)`
  width: fit-content;
  padding: 4px 8px;
  border-radius: 100px;
  border: ${({ primary }) => (primary ? '1px solid var(--primaryBlack10)' : '1px solid var(--primaryBlack3)')};
  background-color: ${({ primary }) => (primary ? 'var(--primaryBlue5)' : 'transparent')};
  color: ${({ primary }) => (primary ? 'var(--primaryBlue)' : 'var(--primaryBlack)')};
  font-weight: 700;
  font-style: italic;
  font-size: 11px;

  &:hover {
    cursor: pointer;
  }
`;

const StyledCheckIcon = styled(CheckIcon)`
  width: 11px;
  height: 11px;

  path {
    fill: var(--primaryBlue);
  }
`;

const TransactionsWrapper = styled(FlexerColumn)`
  width: 100%;
  max-height: 120px;
  overflow-y: scroll;
  font-size: 11px;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  &::-webkit-scrollbar:vertical {
    width: 6px;
  }

  &::-webkit-scrollbar:horizontal {
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 1px solid var(--primaryBlack3);
    background-color: rgba(0, 0, 0, 0.5);
  }
`;

export const RelatedTransactionsAction = ({
  transactionsSelected,
  transactionsById,
  setTransactionsSelected,
  handleAddAdditionalTransactions,
}) => {
  const {
    orgId,
    appSettings: { currencyISOCode: currency },
  } = useContext(AppContext);

  const customerIds = uniq(
    Array.from(transactionsSelected).map((transactionId) => transactionsById[transactionId]?.customer_id),
  );
  const { data: additionalTransactions } = useTransactionsAPI({
    orgId,
    filters: {
      limit: 100,
      params: {
        withoutInvoicingSchedule: true,
        confirmed: true,
        includeCount: true,
        includeReplaced: true,
        archived: false,
        includeCustomerInvoicingDetails: true,
        includeDismissedInvoicing: false,
        ...REQUIRED_BILLING_PARAMS,
      },
      body: {
        orderBy: [{ column: 'start_date', order: 'asc' }],
        customerIds,
        notTransactionIds: Array.from(transactionsSelected),
      },
    },
    enableToasts: false,
    autoFetch: customerIds.length === 1,
  });
  const additionalTransactionsWithProducts = useMemo(
    () => additionalTransactions?.filter((transaction) => transaction.product_id),
    [additionalTransactions],
  );

  const handleAddTransactions = () => {
    const additionalTransactionsIds = (additionalTransactions ?? [])?.map(({ id }) => id);
    setTransactionsSelected(new Set([...Array.from(transactionsSelected), ...additionalTransactionsIds]));
    handleAddAdditionalTransactions && handleAddAdditionalTransactions(additionalTransactionsIds);
  };

  return additionalTransactionsWithProducts?.length ? (
    <Wrapper>
      <Item>
        <FlexBetweenContainer gap="4px" width="100%" alignItems="center">
          <b>Do you want to add the related transactions?</b>
          <Flexer gap="6px">
            <Button
              primary
              gap="4px"
              onClick={handleAddTransactions}
              data-cy="bulk-create-settings__related-transactions-action__add-button"
            >
              Add to the schedule <StyledCheckIcon />
            </Button>
          </Flexer>
        </FlexBetweenContainer>
      </Item>
      <TransactionsWrapper>
        {additionalTransactionsWithProducts.map((transaction) => (
          <Item key={transaction.id}>
            <Flexer
              gap="6px"
              alignItems="center"
              width="25%"
              data-cy="bulk-create-settings__related-transactions-action__transaction-name"
            >
              <b>{transaction.name}</b>
              <IconWrapper>
                <ExternalLinkFullIcon
                  size="10px"
                  onClick={() => window.open(`/transactions/${transaction.id}`, '_blank')}
                />
              </IconWrapper>
            </Flexer>

            <Flexer alignItems="center" width="20%">
              {transaction.recognition}
            </Flexer>

            <Flexer alignItems="center" justifyContent="center" width="40%">
              {`${dayjs.utc(transaction.start_date).format('MMM D, YYYY')} - ${
                !!transaction.end_date ? dayjs.utc(transaction.end_date).format('MMM D, YYYY') : 'No end date'
              }`}
            </Flexer>

            <Flexer alignItems="center" justifyContent="flex-end" width="15%">
              {numberFormatter({
                type: NUMBER_FORMATS.CURRENCY,
                rawValue: getTransactionAmountByRecognition({ transaction }),
                currency: transaction.currency ?? currency,
              })}
            </Flexer>
          </Item>
        ))}
      </TransactionsWrapper>
    </Wrapper>
  ) : null;
};
