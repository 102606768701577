import React from 'react';
import { numberFormatter } from 'utils/formatters';
import { TableBodyRow, TableBodyTile } from './styles';
import { NUMBER_FORMATS } from 'consts/global';

export const METRIC_KEY_TO_ROW_LABEL = {
  recognizedRevenue: 'Recognized',
  totalRecognized: 'Recognized to date',
  deferredRevenue: 'Deferred balance',
  accruedRevenue: 'Accrued balance',
  invoiced: 'Invoiced',
  totalInvoiced: 'Invoiced to date',
};

export const CustomerAccountingMetricRows = ({ total, monthKeys, accountingCurrency }) => {
  return Object.entries(METRIC_KEY_TO_ROW_LABEL).map(([metricKey, rowLabel]) => (
    <TableBodyRow key={metricKey}>
      <TableBodyTile>{rowLabel}</TableBodyTile>
      {monthKeys?.map((monthKey) => (
        <TableBodyTile>
          {numberFormatter({
            rawValue: total?.[metricKey]?.[monthKey] ?? 0,
            type: NUMBER_FORMATS.CURRENCY,
            decimalPlaces: 2,
            currency: accountingCurrency,
          })}
        </TableBodyTile>
      ))}
    </TableBodyRow>
  ));
};
