import React, { useEffect, useState, Fragment } from 'react';

import logo from 'images/logo-wizard.svg';
import { ReactComponent as AvatarIcon } from 'images/wizard-user-icon.svg';
import { ReactComponent as ButtonIcon } from 'images/btn-wizard.svg';
import { ReactComponent as ButtonBackIcon } from 'images/btn-back-wizard.svg';
import { ReactComponent as StartIcon } from 'images/flag.svg';
import { ReactComponent as CheckIcon } from 'images/check-wizard.svg';
import { ReactComponent as FinishIcon } from 'images/rocket.svg';
import { Loader } from 'components/Loaders';
import { TooltipContainer } from 'components/Tooltip';
import {
  getIntegrationsAnonymous,
  getIntegrationsObjectFields,
  getOneIntegrationsObject,
  getIntegrationsProducts,
  getPipelinesAndStages,
} from 'api/integrations';
import { useConfigAPI } from 'api/configs';
import { setLocalStorage } from 'utils/storageUtils';
import { useUrlQuery } from 'utils/hooks';
import { INTEGRATION_SERVICES } from 'consts/integrations';
import { RECOGNITION_TYPES } from 'consts/global';
import { getServiceCategory } from 'models/integration';
import { getLocalStorage, WIZARD_LOCAL_STORAGE } from './storage';
import { Introduction } from './steps/Introduction';
import { ExternalAccounts } from './steps/ExternalAccounts';
import { Deals } from './steps/Deals';
import { CustomersAndProducts } from './steps/CustomersAndProducts';
import { SubscriptionData } from './steps/SubscriptionData/SubscriptionData';
import { Success } from './steps/Success';
import { isPageFilled, getConfigsFromData, DEFAULT_SEGMENTS } from './utils';
import {
  HeaderUserInfo,
  StyledFinishIcon,
  ReturnBackButton,
  ActionButton,
  ActionButtonContainer,
  WizardWrapper,
  Line,
  StepNumber,
  WizardSteps,
  WizardBody,
  HeaderTitle,
  WizardMainLogo,
  StartIconWrapper,
  WizardHeader,
} from './styles';

// TODO: This is unused (Wizard2), and to be retired.
export const Wizard = () => {
  /*
    Handling user details in URL params
  */
  const { storedWizardUserInfo } = getLocalStorage();

  const query = useUrlQuery();

  const haveError = query.get('error');

  const user = haveError
    ? storedWizardUserInfo
    : {
        name: query.get('name'),
        company: query.get('company'),
        orgId: query.get('orgId'),
        id: query.get('id'),
      };

  !haveError && setLocalStorage(WIZARD_LOCAL_STORAGE.USER_INFO, JSON.stringify(user));

  /*
    Wizard state variables
  */
  const [currentStep, setCurrentStep] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  //we saves config on every step, but wanted to swow toast only in the end
  const { updateConfigFromWizard } = useConfigAPI({ orgId: user.orgId, enableToasts: currentStep === 4 });

  /*
      Google Analytics in production
  */
  useEffect(() => {
    // only trigger in production
    if (process.env.REACT_APP_ENVIRONMENT === 'production') {
      window.gtag('event', 'wizard', {
        event_category: 'access',
        event_label: 'wizard',
      });
    }
  }, []);

  /*
    Integrations
  */
  const [selectedIntegration, setSelectedIntegration] = useState();
  const [integrationTypes, setIntegrationTypes] = useState([]);
  const [integrationObjectFields, setIntegrationObjectFields] = useState({});
  const [exampleIntegrationObjects, setExampleIntegrationObjects] = useState({});
  const [pipelinesAndStages, setPipelinesAndStages] = useState({});
  const [allProducts, setAllProducts] = useState([]);

  /*
    Page 2
  */
  const [defaultTransactionRecognition, setDefaultTransactionRecognition] = useState(); // string

  // only used if organization has hubspot integration
  const [closedWonStages, setClosedWonStages] = useState([]); // array

  // determines if to show deal or line item fields or both
  const [dealsOrLineItems, setDealsOrLineItems] = useState();

  // deal and line item mappings
  const [totalValueFieldDeal, setTotalValueFieldDeal] = useState();
  const [totalValueFieldLineItem, setTotalValueFieldLineItem] = useState();
  const [startDateFieldDeal, setStartDateFieldDeal] = useState();
  const [startDateFieldLineItem, setStartDateFieldLineItem] = useState();
  const [endDateFieldDeal, setEndDateFieldDeal] = useState();
  const [endDateFieldLineItem, setEndDateFieldLineItem] = useState();
  const [seatsFieldDeal, setSeatsFieldDeal] = useState();
  const [seatsFieldLineItem, setSeatsFieldLineItem] = useState();
  const [isTillCanceledMrr, setIsTillCanceledMrr] = useState();

  const [transactionMetadataOptions, setTransactionMetadataOptions] = useState([]); // array
  const [internalId, setInternald] = useState(); // this also includes "internal database id"

  const [skipDailySync, setSkipDailySync] = useState(true);

  /*
    Page 3
  */
  const [customerMetadataOptions, setCustomerMetadataOptions] = useState([]); // array
  const [nonRecurringProducts, setNonRecurringProducts] = useState([]); // array

  /*
    Page 4
  */
  // ARR/MRR, Classical/Committed, committedPolicy, committedStart, In-flux, Fiscal year end, segments
  const [isARR, setIsARR] = useState();

  const [isCommitted, setIsCommitted] = useState();
  const [committedPolicy, setCommittedPolicy] = useState();
  const [committedStart, setCommittedStart] = useState();

  const [influxMonths, setInfluxMonths] = useState();
  const [influxOption, setInfluxOption] = useState();
  const [yearEndMonthIndex, setYearEndMonthIndex] = useState();

  const [segments, setSegments] = useState(DEFAULT_SEGMENTS);
  const [reachedPage, setReachedPage] = useState(0);

  const rawData = {
    // page 2
    defaultTransactionRecognition:
      defaultTransactionRecognition === 'linear:2' ? RECOGNITION_TYPES.linear : defaultTransactionRecognition,
    closedWonStages, // only add if organization has hubspot integration
    totalValueFieldDeal,
    totalValueFieldLineItem,
    startDateFieldDeal,
    startDateFieldLineItem,
    endDateFieldDeal,
    endDateFieldLineItem,
    seatsFieldDeal,
    seatsFieldLineItem,
    recurringRevenueFieldDeal:
      defaultTransactionRecognition === RECOGNITION_TYPES.tillCanceled ? totalValueFieldDeal : undefined,
    recurringRevenueFieldLineItem:
      defaultTransactionRecognition === RECOGNITION_TYPES.tillCanceled ? totalValueFieldLineItem : undefined,
    isTillCanceledMrr,
    transactionMetadataOptions,
    internalId,
    skipDailySync,
    // page 3
    customerMetadataOptions,
    nonRecurringProducts,
    // page 4
    isARR,
    isCommitted,
    committedPolicy,
    committedStart,
    influxMonths,
    yearEndMonthIndex,
    segments: reachedPage === 4 ? segments : [],
  };

  if (internalId) {
    rawData.transactionMetadataOptions = [...rawData.transactionMetadataOptions, internalId];
  }

  if (influxOption === 'influx') {
    rawData.influxMonths = influxMonths && influxMonths !== '' ? influxMonths : 0;
  }

  const saveConfigs = async ({ newStep }) => {
    const data = getConfigsFromData(rawData);

    await updateConfigFromWizard({
      data,
    });

    setCurrentStep(newStep);
  };

  const crmSelectedIntegration = integrationTypes?.[0];

  const onClickActionButton = (direction) => () => {
    let newStep = direction === 'next' ? currentStep + 1 : currentStep - 1;
    const hasHubspotOrSalesforceIntegration = integrationTypes.some((integration) => {
      const serviceNameOnly = getServiceCategory(integration.service);
      return [INTEGRATION_SERVICES.HUBSPOT, INTEGRATION_SERVICES.SALESFORCE].includes(serviceNameOnly);
    });
    if (!hasHubspotOrSalesforceIntegration) {
      // no hubspot or salesforce integration (we skip all the CRM related questions)
      if (direction === 'next' && currentStep === 1) {
        newStep = 4;
      } else if (direction === 'previous' && currentStep === 4) {
        newStep = 1;
      }
    }
    if (newStep > currentStep) {
      saveConfigs({ newStep });
    } else {
      setCurrentStep(newStep);
    }
  };

  const getCurrentStepComponent = () => {
    const data = {
      haveError,
      user,
      integrationTypes,
      integrationObjectFields,
      setReachedPage,
    };
    switch (currentStep) {
      case 0:
        return <Introduction {...data} />;
      case 1:
        return (
          <ExternalAccounts
            selectedIntegration={selectedIntegration}
            setSelectedIntegration={setSelectedIntegration}
            {...data}
          />
        );
      case 2:
        return (
          <Deals
            configSelectedIntegration={crmSelectedIntegration}
            defaultTransactionRecognition={defaultTransactionRecognition}
            setDefaultTransactionRecognition={setDefaultTransactionRecognition}
            closedWonStages={closedWonStages}
            setClosedWonStages={setClosedWonStages}
            transactionMetadataOptions={transactionMetadataOptions}
            setTransactionMetadataOptions={setTransactionMetadataOptions}
            internalId={internalId}
            setInternald={setInternald}
            totalValueFieldDeal={totalValueFieldDeal}
            setTotalValueFieldDeal={setTotalValueFieldDeal}
            totalValueFieldLineItem={totalValueFieldLineItem}
            setTotalValueFieldLineItem={setTotalValueFieldLineItem}
            startDateFieldDeal={startDateFieldDeal}
            setStartDateFieldDeal={setStartDateFieldDeal}
            startDateFieldLineItem={startDateFieldLineItem}
            setStartDateFieldLineItem={setStartDateFieldLineItem}
            endDateFieldDeal={endDateFieldDeal}
            setEndDateFieldDeal={setEndDateFieldDeal}
            endDateFieldLineItem={endDateFieldLineItem}
            setEndDateFieldLineItem={setEndDateFieldLineItem}
            seatsFieldDeal={seatsFieldDeal}
            setSeatsFieldDeal={setSeatsFieldDeal}
            seatsFieldLineItem={seatsFieldLineItem}
            setSeatsFieldLineItem={setSeatsFieldLineItem}
            isTillCanceledMrr={isTillCanceledMrr}
            setIsTillCanceledMrr={setIsTillCanceledMrr}
            pipelinesAndStages={pipelinesAndStages}
            exampleIntegrationObjects={exampleIntegrationObjects}
            dealsOrLineItems={dealsOrLineItems}
            setDealsOrLineItems={setDealsOrLineItems}
            skipDailySync={skipDailySync}
            setSkipDailySync={setSkipDailySync}
            {...data}
          />
        );
      case 3:
        return (
          <CustomersAndProducts
            exampleIntegrationObjects={exampleIntegrationObjects}
            customerMetadataOptions={customerMetadataOptions}
            setCustomerMetadataOptions={setCustomerMetadataOptions}
            nonRecurringProducts={nonRecurringProducts}
            setNonRecurringProducts={setNonRecurringProducts}
            allProducts={allProducts}
            {...data}
          />
        );
      case 4:
        return (
          <SubscriptionData
            isARR={isARR}
            setIsARR={setIsARR}
            isCommitted={isCommitted}
            setIsCommitted={setIsCommitted}
            committedPolicy={committedPolicy}
            setCommittedPolicy={setCommittedPolicy}
            committedStart={committedStart}
            setCommittedStart={setCommittedStart}
            influxMonths={influxMonths}
            setInfluxMonths={setInfluxMonths}
            yearEndMonthIndex={yearEndMonthIndex}
            setYearEndMonthIndex={setYearEndMonthIndex}
            segments={segments}
            setSegments={setSegments}
            influxOption={influxOption}
            setInfluxOption={setInfluxOption}
            {...data}
          />
        );
      case 5:
        return <Success {...data} />;
      default:
        return null;
    }
  };

  const getStepIcons = () => {
    const steps = Array.from(Array(6).keys());
    return (
      <>
        {steps.map((step) => {
          switch (step) {
            case 0:
              return (
                <StartIconWrapper key={step} active={currentStep > 0}>
                  <StartIcon />
                </StartIconWrapper>
              );
            case 1:
            case 2:
            case 3:
            case 4:
              return (
                <Fragment key={step}>
                  <Line />
                  {currentStep > step ? (
                    <CheckIcon style={{ width: '100%' }} />
                  ) : (
                    <StepNumber active={currentStep === step}>{step}</StepNumber>
                  )}
                </Fragment>
              );
            case 5:
              return (
                <Fragment key={step}>
                  <Line />
                  <StyledFinishIcon active={currentStep === step}>
                    <FinishIcon />
                  </StyledFinishIcon>
                </Fragment>
              );
            default:
              return null;
          }
        })}
      </>
    );
  };

  const hasHubspotIntegration = integrationTypes.some((integration) =>
    integration.service.includes(INTEGRATION_SERVICES.HUBSPOT),
  );

  /*
    Get all integrations for user's organization
  */
  useEffect(() => {
    const getIntegrations = async () => {
      const integrations = await getIntegrationsAnonymous(user.orgId);
      setIntegrationTypes(integrations);
      setIsLoading(false);
    };

    getIntegrations();

    if (haveError) {
      setCurrentStep(1);
    }
  }, [user.orgId, haveError]);

  /*
    Get external object fields and pipelines/stages
  */
  useEffect(() => {
    const getExternalObjectFields = async () => {
      const integrationExternalObjectFields = await getIntegrationsObjectFields(user.orgId);
      setIntegrationObjectFields(integrationExternalObjectFields);
    };

    const getHubspotPipelinesAndStages = async () => {
      const hubspotPipelinesAndStages = await getPipelinesAndStages(user.orgId);
      setPipelinesAndStages(hubspotPipelinesAndStages);
    };

    if (integrationTypes.length === 0) return;

    getExternalObjectFields();

    if (hasHubspotIntegration) getHubspotPipelinesAndStages();
  }, [integrationTypes, hasHubspotIntegration, user.orgId]);

  /*
    Get products
  */
  useEffect(() => {
    const getProducts = async () => {
      const productsResults = await getIntegrationsProducts(user.orgId);
      setAllProducts(productsResults);
    };

    if (integrationTypes.length === 0) return;

    getProducts();
  }, [integrationTypes, user.orgId]);

  /*
    Get example external object
  */
  useEffect(() => {
    const getExampleExternalObject = async () => {
      const externalObjectResults = await getOneIntegrationsObject({
        orgId: user.orgId,
        params: { closedWonStages, dealsOrLineItems },
      });
      setExampleIntegrationObjects(externalObjectResults);
    };

    if (integrationTypes.length === 0) return;

    // if Hubspot, before getting example object, you also need the closed-won stages
    const hasClosedWon = !hasHubspotIntegration || closedWonStages.length > 0;
    if (hasClosedWon && dealsOrLineItems) getExampleExternalObject();
  }, [integrationTypes, hasHubspotIntegration, closedWonStages, dealsOrLineItems, user.orgId]);

  return (
    <WizardWrapper>
      <WizardHeader>
        <WizardMainLogo>
          <img src={logo} alt="logo" width="38" height="32" />
          <div>Subscript</div>
        </WizardMainLogo>
        <HeaderTitle>Set up Configuration</HeaderTitle>
        <HeaderUserInfo>
          <div>
            <h5>{user.name}</h5>
            at <span>{user.company}</span>
          </div>

          <AvatarIcon />
        </HeaderUserInfo>
      </WizardHeader>
      <WizardBody>
        {isLoading ? (
          <div className="w-100 flexer">
            <Loader containerStyles={{ width: 40, marginTop: 20 }} />
          </div>
        ) : (
          <>
            <WizardSteps>{getStepIcons()}</WizardSteps>
            {getCurrentStepComponent()}
            <ActionButtonContainer>
              {currentStep > 1 && currentStep !== 5 && (
                <ReturnBackButton onClick={onClickActionButton('previous')}>
                  <ButtonBackIcon />
                  <span>Return back</span>
                </ReturnBackButton>
              )}
              {currentStep < 5 && (
                <TooltipContainer
                  width={200}
                  toolTipContent="To go further please answer all the questions"
                  isVisible={
                    !isPageFilled({
                      integrationsLength: integrationTypes.length,
                      currentStep,
                      ...rawData,
                    })
                  }
                >
                  <ActionButton
                    data-cy="wizard__confirm-button"
                    unactive={
                      !isPageFilled({
                        integrationsLength: integrationTypes.length,
                        currentStep,
                        ...rawData,
                      })
                    }
                    onClick={onClickActionButton('next')}
                  >
                    <span>
                      {currentStep === 0
                        ? 'Start configuration'
                        : currentStep === 4
                        ? 'Finish setup'
                        : 'Go to next step'}
                    </span>
                    <ButtonIcon />
                  </ActionButton>
                </TooltipContainer>
              )}
            </ActionButtonContainer>
          </>
        )}
      </WizardBody>
    </WizardWrapper>
  );
};
