import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { AppContext } from 'AppContext';
import { getRequiredPlugins } from 'models/appSettings/plugins';
import { useReport } from 'shared/Reports/hooks';
import { useSyncedExport } from 'shared/SyncedExports';
import { PAGE_CONTEXT_VALUES } from 'utils/hooks';
import { CARD_SIZES, CARD_SIZES_COLUMN_SIZES_V2 } from '../Common/Card/consts';
import { useBusinessSnapshotSettingsModal } from '../DashboardTables/BusinessSnapshotSettingsModal';
import { COMMON_METRICS_DASHBOARD_TITLE, DEFAULT_DASHBOARD_TITLE, WIDGET_TYPES } from './consts';
import { ReportBuilderContext } from './ReportBuilderContext';
import { useWidgetModal } from './useWidgetModal';
import { useEditReportTitleModal } from './useEditReportTitleModal';
import { addMetricToReport, removeMetricFromReport, reportV1ToV2Converter, updateMetricInReport } from './utils';

export const ReportBuilderProvider = ({ pageState, selectedReport, isNewReport, children }) => {
  const { setRequiredPlugins } = useContext(AppContext);
  const [report, setReport] = useState();
  const [currentRowIndex, setCurrentRowIndex] = useState(1);
  const [currentColumnIndex, setCurrentColumnIndex] = useState(1);
  const [lastMetricSize, setLastMetricSize] = useState(CARD_SIZES.SMALL);

  const convertedReport = useMemo(() => {
    const initialReport = selectedReport ?? {
      // If we are missing the default dashboard we will create it by
      title: DEFAULT_DASHBOARD_TITLE,
      type: PAGE_CONTEXT_VALUES.snapshot.key,
      options: {
        version: 2,
        ...PAGE_CONTEXT_VALUES[PAGE_CONTEXT_VALUES.snapshot.key].pageStates.reduce((acc, key) => {
          acc[key] = pageState[key];
          return acc;
        }, {}),
        ...Object.keys(PAGE_CONTEXT_VALUES[PAGE_CONTEXT_VALUES.snapshot.key].customAppSettingsStates).reduce(
          (acc, key) => {
            acc[key] = key === 'snapshotSelectedColumns' ? {} : [];
            return acc;
          },
          {},
        ),
      },
    };
    // convert to V2 if necessary
    return selectedReport?.options?.version !== 2 ? reportV1ToV2Converter(initialReport) : initialReport;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedReport]);

  const { reportData: savedReport, setReportData: setSavedReport, pageReportState } = useReport({
    report: convertedReport,
    page: PAGE_CONTEXT_VALUES.snapshot.key,
    pageState: {
      ...pageState,
      collapseRows: report?.options?.collapseRows,
      snapshotSelectedColumns: report?.options?.snapshotSelectedColumns,
      snapshotTableRows: report?.options?.snapshotTableRows,
      metricsSetting: report?.options?.metricsSetting,
      setCollapseRows: () => {},
      setSnapshotSelectedColumns: () => {},
      setSnapshotTableRows: () => {},
      setMetricsSetting: () => {},
    },
    // We will keep the app state when navigating to common metrics dashboard
    // [JB 2023-03-08] This will keep old behaviour of keeping filters while navigating between pages
    keepAppState: [COMMON_METRICS_DASHBOARD_TITLE].includes(convertedReport?.title),
  });

  useEffect(() => {
    const lastMetric = convertedReport?.options?.metricsSetting?.[convertedReport?.options?.metricsSetting?.length - 1];

    // restart positioning
    if (lastMetric) {
      const { rowStart, columnStart, size } = lastMetric;
      setCurrentRowIndex(rowStart);
      setCurrentColumnIndex(columnStart + CARD_SIZES_COLUMN_SIZES_V2[size]);
      setLastMetricSize(size);
    } else {
      setCurrentRowIndex(1);
      setCurrentColumnIndex(1);
      setLastMetricSize(CARD_SIZES.SMALL);
    }

    setReport({ ...convertedReport });
  }, [convertedReport]);

  const exportScreenshotRef = useRef();
  const { ExportButton } = useSyncedExport({
    pageName: `Report: ${report?.title}`,
    exportScreenshotRef,
  });

  const handleAddMetric = (metricData, addInRowIndex) => {
    const { updatedReport, rowIndex, columnIndex, updatedLastMetricSize } = addMetricToReport({
      report,
      metricData,
      currentRowIndex,
      currentColumnIndex,
      lastMetricSize,
      addInRowIndex,
    });

    setCurrentRowIndex(rowIndex);
    setCurrentColumnIndex(columnIndex);
    setLastMetricSize(updatedLastMetricSize);
    setReport(updatedReport);
  };

  const handleEditMetric = (metricData, metricIndex) => {
    const { updatedReport, rowIndex, columnIndex, updatedLastMetricSize } = updateMetricInReport({
      report,
      metricIndex,
      metricData,
      currentRowIndex,
      currentColumnIndex,
      lastMetricSize,
    });

    setCurrentRowIndex(rowIndex);
    setCurrentColumnIndex(columnIndex);
    setLastMetricSize(updatedLastMetricSize);
    setReport(updatedReport);
  };

  const { Modal: EditReportNameModal, openModal: openEditReportNameModal } = useEditReportTitleModal({
    report: savedReport,
    setReport: setSavedReport,
    pageReportState: {
      ...pageReportState,
      version: report?.options?.version,
    },
  });

  const { openAddWidgetModal, openEditWidgetModal, WidgetModal } = useWidgetModal({
    onAdd: handleAddMetric,
    onEdit: handleEditMetric,
  });

  const {
    toggleModal: toggleBusinessSnapshotSnapshotModal,
    Modal: BusinessSnapshotSnapshotModal,
  } = useBusinessSnapshotSettingsModal({
    selectedMetrics: report?.options?.snapshotTableRows,
    selectedColumns: report?.options?.snapshotSelectedColumns,
    setSelected: ({ selectedMetrics, selectedColumns }) => {
      setReport({
        ...report,
        options: { ...report?.options, snapshotTableRows: selectedMetrics, snapshotSelectedColumns: selectedColumns },
      });
    },
  });

  const onAddWidget = ({ key, addInRowIndex }) => {
    switch (key) {
      case WIDGET_TYPES.REVENUE_WATERFALL:
        handleAddMetric(
          {
            id: key,
            size: CARD_SIZES.HUGE,
          },
          addInRowIndex,
        );
        break;
      case WIDGET_TYPES.DATA_TABLE:
        toggleBusinessSnapshotSnapshotModal({
          selectedColumns: {
            months: true,
            quarters: true,
            years: true,
            cumulative: true,
          },
        });
        break;
      default:
        openAddWidgetModal({ key, addInRowIndex });
    }
  };

  const onEditWidget = ({ metricIndex, metric }) => {
    switch (metric.id) {
      case WIDGET_TYPES.REVENUE_WATERFALL:
        console.log('Not implemented at this moment');
        break;
      case WIDGET_TYPES.DATA_TABLE:
        toggleBusinessSnapshotSnapshotModal();
        break;
      default:
        openEditWidgetModal({ metricIndex, metric });
        break;
    }
  };

  const onDeleteWidget = ({ metricIndex, metric }) => {
    switch (metric.id) {
      case WIDGET_TYPES.DATA_TABLE:
        setReport({ ...report, options: { ...report.options, snapshotTableRows: [], snapshotSelectedColumns: {} } });
        break;
      default:
        const { updatedReport, rowIndex, columnIndex, updatedLastMetricSize } = removeMetricFromReport({
          report,
          metricIndex,
          currentRowIndex,
          currentColumnIndex,
          lastMetricSize,
        });

        setCurrentRowIndex(rowIndex);
        setCurrentColumnIndex(columnIndex);
        setLastMetricSize(updatedLastMetricSize);
        setReport(updatedReport);
        break;
    }
  };

  const onEditCollapseRows = (collapseRows) => {
    setReport({
      ...report,
      options: { ...report?.options, collapseRows },
    });
  };

  useEffect(() => {
    if (isNewReport) {
      openEditReportNameModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [report?.id]);

  useEffect(() => {
    if (report) {
      setRequiredPlugins(getRequiredPlugins({ reportData: report }));
    }
  }, [report, setRequiredPlugins]);

  return (
    <ReportBuilderContext.Provider
      value={{
        report: savedReport,
        setReport: setSavedReport,
        pageReportState: {
          ...pageReportState,
          version: report?.options?.version,
        },
        exportScreenshotRef,
        ExportButton,
        onAddWidget,
        onEditWidget,
        onDeleteWidget,
        onEditCollapseRows,
        openEditReportNameModal,
      }}
    >
      {children}
      <WidgetModal />
      <BusinessSnapshotSnapshotModal />
      <EditReportNameModal />
    </ReportBuilderContext.Provider>
  );
};
