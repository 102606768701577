import dayjs from 'dayjs';

export const calculateRevenueCard = ({ revenueStats }) => {
  if (!revenueStats) return null;

  /* Array of {
    Churn: 0,
    Downsell: '221.42859',
    New: '4045.0554',
    Renewal: '49513.105',
    Total: '54785.438',
    Upsell: '1227.2756',
  } */

  const revenueValues = Object.values(revenueStats);

  let startingValue = revenueValues?.[0].Total ?? 0;

  const keys = Object.keys(revenueStats);
  const startingKey = dayjs
    .utc(keys[keys.length - 1])
    .subtract(1, 'year')
    .format('YYYY-MM');

  if (revenueStats[startingKey]) {
    startingValue = revenueStats[startingKey]?.Total ?? 0;
  }

  let currentValue = revenueValues?.[revenueValues.length - 1]?.Total ?? 0;

  return {
    currentValue,
    startingValue,
    graphData: revenueValues,
    graphLabels: Object.keys(revenueStats).map((date) => dayjs(date).format('MMM YYYY')),
  };
};
