import { useContext } from 'react';
import styled from 'styled-components';

import { BillingContext } from 'views/Billing/BillingContext';
import { USAGE_BASED_ENGINE_TABS } from 'views/Billing/UsageBasedEngine/Tabs/consts';
import { BillingUsageSubscriptions } from './BillingUsageSubscriptions';
import { BillingPricingPlans } from './BillingPricingPlans';
import { BillingUsageEvents } from './BillingUsageEvents';

const Wrapper = styled.div`
  margin-top: 20px;
`;

export const UsageBasedEngineMainContent = () => {
  const { currentUsageBasedEngineTab } = useContext(BillingContext);

  const renderTabContent = () => {
    switch (currentUsageBasedEngineTab) {
      case USAGE_BASED_ENGINE_TABS.PRICING_PLANS:
        return <BillingPricingPlans />;
      case USAGE_BASED_ENGINE_TABS.USAGE_EVENTS:
        return <BillingUsageEvents />;
      default:
        return <BillingUsageSubscriptions />;
    }
  };

  return <Wrapper>{renderTabContent()}</Wrapper>;
};
