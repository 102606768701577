import styled from 'styled-components';
import { FlexerColumn } from 'components/Core';

export const StyledUserInfo = styled(FlexerColumn)`
  width: ${(props) => (props.isHeaderInfo ? '212px' : 'initial')};
  height: ${(props) => (props.isHeaderInfo ? '40px' : 'initial')};
  padding: ${(props) => (props.isHeaderInfo ? '3px 0' : '0px 0px 0px 14px')};
  text-align: ${(props) => (props.isHeaderInfo ? 'right' : 'left')};
`;

export const NavCompanyName = styled.h3`
  width: 119px;
  color: var(--primaryBlack);
  font-weight: 900;
  font-size: 14px;
  line-height: 19px;
  margin-bottom: 4px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const HeaderCompanyName = styled.h3`
  width: 200px;
  color: var(--primaryBlack);
  font-weight: 900;
  font-size: 10px;
  line-height: 16px;
  text-transform: uppercase;
  margin: 0 0 2px;

  white-space: nowrap;
`;

export const NavUserName = styled(NavCompanyName)`
  color: rgba(0, 21, 46, 0.3);
  font-weight: 800;
  font-size: 10px;
  line-height: 14px;
  text-transform: uppercase;
  margin: 0;
`;

export const HeaderUserName = styled(HeaderCompanyName)`
  color: var(--primaryBlack);
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-transform: capitalize;
  margin: 0;
`;
