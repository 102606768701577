import React, { useContext } from 'react';
import dayjs from 'dayjs';
import { AppContext } from 'AppContext';
import { ReactComponent as OneIcon } from 'images/one-time.svg';
import { ReactComponent as RecurringIcon } from 'images/recurring-icon.svg';
import { ReactComponent as UpForRenewalIcon } from 'images/bell-ringing.svg';
import { useCurrencyNumberFormatter } from 'utils/hooks';
import { SPREAD_TYPE, RECOGNITION_TYPES, NUMBER_FORMATS } from 'consts/global';
import { transactionDisplayTitle } from 'models/transaction';
import { REVENUE_METRIC } from 'consts/revenueMetrics';
import { TooltipContainer } from 'components/Tooltip';
import { GreyDot } from 'components/Icons';
import { getTransactionAmount } from 'views/Transactions/utils';
import {
  TransactionTableRow,
  TransactionName,
  ProductName,
  SpreadType,
  BookingDate,
  Dates,
  Seats,
  SpreadAmount,
  TransactionAmount,
  UpForRenewalWrapper,
} from '../styled';

export const TransactionRow = ({ spread, onTransactionClick, customer, activeMetric }) => {
  const { formatDateWithLocale } = useContext(AppContext);

  const {
    booking_date,
    end_date,
    product_name,
    spread_amount,
    seats,
    spread_id,
    spread_type,
    start_date,
    transaction_id,
    transaction_amount,
    transaction_name,
    upForRenewal,
    recognition,
    transaction_spread_sum,
    customer_name,
  } = spread;

  const numberFormatter = useCurrencyNumberFormatter();

  const handleTransactionClick = () => {
    onTransactionClick({ customer, transactionId: transaction_id });
  };

  const startDate = dayjs.utc(start_date);
  const endDate = dayjs.utc(end_date);
  const productName = product_name || '-';
  const recurringRevenue = [SPREAD_TYPE.RECURRING].includes(spread_type);
  const endDateText = end_date
    ? endDate.format('MM/YYYY')
    : recognition === RECOGNITION_TYPES.tillCanceled
    ? 'Till Canceled'
    : 'No end date';
  return (
    <TransactionTableRow key={spread_id}>
      <>
        <TransactionName onClick={handleTransactionClick} data-cy="revenue-details-transactions-row">
          {upForRenewal && activeMetric !== REVENUE_METRIC.CHURN.label ? (
            <>
              <UpForRenewalWrapper>
                <TooltipContainer width={105} fontSize="12px" toolTipContent="Up For Renewal">
                  <UpForRenewalIcon />
                </TooltipContainer>
              </UpForRenewalWrapper>
              <GreyDot style={{ marginLeft: 8, marginRight: 8 }} />
            </>
          ) : (
            ''
          )}
          {transactionDisplayTitle({
            name: transaction_name,
            product_name,
            customer_name,
            customer_id: customer.id,
            customer_email: customer.email,
            start_date: start_date,
            end_date: end_date,
            recognition,
          })}
        </TransactionName>
        <ProductName>{productName}</ProductName>
        <SpreadType>{recurringRevenue ? <RecurringIcon /> : <OneIcon />}</SpreadType>
        <BookingDate>{`${formatDateWithLocale(booking_date)}`}</BookingDate>
        <Dates>{`${startDate.format('MM/YYYY')} - ${endDateText}`}</Dates>
        <Seats>{numberFormatter({ type: NUMBER_FORMATS.NUMBER, rawValue: seats })}</Seats>
        <SpreadAmount>{numberFormatter({ type: NUMBER_FORMATS.CURRENCY, rawValue: spread_amount })}</SpreadAmount>
        <TransactionAmount>
          {numberFormatter({
            type: NUMBER_FORMATS.CURRENCY,
            rawValue: getTransactionAmount({
              recognition,
              transactionAmount: transaction_amount,
              spreadSum: transaction_spread_sum,
            }),
          })}
        </TransactionAmount>
      </>
    </TransactionTableRow>
  );
};
