import { useContext, useMemo, useState } from 'react';

import { AppContext } from 'AppContext';
import { useGoalsAPI } from 'api/goals';
import { getLabelForKey } from 'utils/objectUtils';
import { getLocalStorage, setLocalStorage, LOCAL_STORAGE_KEYS } from 'utils/storageUtils';
import { SNAPSHOT_METRICS } from 'consts/snapshotMetrics';
import { GoalsContext } from './GoalsContext';
import { GoalsModal } from './GoalsModal';
import { useGoalModal } from './GoalModal';

export const GoalsProvider = ({ children }) => {
  const { orgId } = useContext(AppContext);
  const { openModal: openGoalModal, Modal: GoalModal, isModalOpen: isGoalModalOpen } = useGoalModal();
  const [showGoalsModal, setShowGoalsModal] = useState(false);
  const openGoalsModal = () => setShowGoalsModal(true);
  const closeGoalsModal = () => setShowGoalsModal(false);

  const savedSelectedGoals = getLocalStorage(LOCAL_STORAGE_KEYS.GOALS_SELECTED_KEY, {}, true);
  const [selectedGoals, setSelectedGoals] = useState(savedSelectedGoals);
  const handleSetSelectedGoals = (goals) => {
    // Let's assume optimistic update
    setTimeout(() => {
      try {
        setLocalStorage(LOCAL_STORAGE_KEYS.GOALS_SELECTED_KEY, goals, true);
      } catch (e) {
        console.error(e);
      }
    });
    setSelectedGoals(goals);
  };

  const { data, isLoading, isFetching, error } = useGoalsAPI({ orgId });
  const goals = useMemo(
    () =>
      data?.reduce((acc, goal) => {
        const labelForMetric = getLabelForKey({ object: SNAPSHOT_METRICS, key: goal.for_metric });
        if (acc[labelForMetric]) {
          acc[labelForMetric][goal.id] = goal;
        } else {
          acc[labelForMetric] = {
            [goal.id]: goal,
          };
        }
        return acc;
      }, {}),
    [data],
  );

  return (
    <GoalsContext.Provider
      value={{
        goals,
        rawGoals: data,
        openGoalsModal,
        closeGoalsModal,
        selectedGoals,
        setSelectedGoals: handleSetSelectedGoals,
        isLoading,
        isFetching,
        error,
        openGoalModal,
        isGoalModalOpen,
      }}
    >
      {children}

      {showGoalsModal && <GoalsModal orgId={orgId} closeModal={closeGoalsModal} />}
      <GoalModal />
    </GoalsContext.Provider>
  );
};
