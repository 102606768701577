import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useToasts } from 'components/Toasts';
import { ORGANIZATION_HELPERS_CACHE_KEY } from 'api/organizations/hooks';
import {
  getExchangeRates,
  bulkCreateExchangeRates as _bulkCreateExchangeRates,
  bulkDeleteExchangeRates as _bulkDeleteExchangeRates,
} from './requests';

const CACHE_KEY = 'exchangeRates';

export const useExchangeRatesAPI = ({ orgId, autoFetch = true, filters, enableToasts = true }) => {
  const { data, error, isLoading, refetch, isFetching } = useQuery(
    [CACHE_KEY, orgId, filters],
    () => getExchangeRates({ orgId, params: filters }),
    {
      enabled: autoFetch && !!orgId,
    },
  );

  const queryClient = useQueryClient();

  const { pushToast: wrappedPushToast, pushError: wrappedPushError } = useToasts();
  const pushToast = (...args) => {
    if (enableToasts) wrappedPushToast(...args);
  };
  const pushError = (...args) => {
    if (enableToasts) wrappedPushError(...args);
  };

  const bulkCreateExchangeRates = useMutation(({ data }) => _bulkCreateExchangeRates({ orgId, data }), {
    onError: (err) => {
      pushError(err, 'Failed to bulk create exchange rates.');
    },

    onSuccess: () => {
      pushToast('Successfully bulk created exchange rates!', 'success');
    },

    onSettled: () => {
      queryClient.invalidateQueries(CACHE_KEY);
      queryClient.invalidateQueries(ORGANIZATION_HELPERS_CACHE_KEY);
    },
  });

  const bulkDeleteExchangeRates = useMutation(({ data }) => _bulkDeleteExchangeRates({ orgId, data }), {
    onError: (err) => {
      pushError(err, 'Failed to remove exchange rates.');
    },

    onSuccess: () => {
      pushToast('Successfully removed exchange rates!', 'success');
    },

    onSettled: () => {
      queryClient.invalidateQueries(CACHE_KEY);
    },
  });

  return {
    data,
    error,
    isLoading,
    isFetching,
    operations: {
      refetch,
      bulkCreateExchangeRates: bulkCreateExchangeRates.mutateAsync,
      bulkDeleteExchangeRates: bulkDeleteExchangeRates.mutateAsync,
    },
  };
};
