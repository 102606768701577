import React, { useState, useContext, useMemo, useEffect } from 'react';
import dayjs from 'dayjs';

import { AppContext } from 'AppContext';
import { getGoalsInsideFiltersPeriod, GoalsContext } from 'shared/Goals';
import { useMonthlyBreakdownAPI } from 'api/monthlyBreakdown';
import { useRetentionBreakdownAPI } from 'api/retentionBreakdown';
import { GROWTH_TYPE_KEYS, NUMBER_FORMATS } from 'consts/global';
import { SNAPSHOT_METRIC_BY_KEY } from 'consts/snapshotMetrics';
import { getLastMonthDate, generateMonths } from 'utils/dateUtils';
import { DashboardContext } from 'views/Dashboard/DashboardContext';

import { CardChartModalContext } from './CardChartModalContext';
import { METRICS_WITH_GROWTH, RETENTION_METRICS } from './consts';
import { reshapeRetentionDataForTable } from '../utils';

// This provider holds the state that could be use by any component inside CardChartModal
// This will allow us to get it from CardChartModalContext instead of needing prop drilling
export const CardChartModalProvider = ({ cardChartModalData, children }) => {
  const { organizations, requiredPlugins } = useContext(AppContext);
  const {
    dataFilter,
    metadataFilter,
    rollup,
    isARR,
    isCommitted,
    optimisticAnalytics,
    countInfluxAsRenewed,
    growthType,
  } = useContext(DashboardContext);
  const { rawGoals: goals } = useContext(GoalsContext);

  const {
    metricKey,
    selectedGoalId,
    // these are only set when this modal is summoned from the Dashboard table
    tableDateStart,
    tableDateEnd,
  } = cardChartModalData;

  const metric = SNAPSHOT_METRIC_BY_KEY[metricKey];
  const { label, dataType, monthDataKey, metricCategoryKey, isARRMetric } = metric;
  const title = label.replace(' TTM', '');
  const isTTM = metricKey.includes('ttm');
  const isARRValue = !!isARRMetric && isARR;

  const isRetentionMetric = RETENTION_METRICS.has(monthDataKey);

  const startDate = tableDateStart
    ? tableDateStart
    : isRetentionMetric
    ? isTTM
      ? dayjs.utc(dataFilter.endMonth).subtract(1, 'year').toDate() // go back 12 months to see your starting customers
      : dayjs.utc(dataFilter.endMonth).subtract(1, 'month').toDate() // go back 1 month to see your starting customers
    : dayjs.utc(dataFilter.startMonth).toDate(); // default behavior
  const endDate = tableDateEnd ? tableDateEnd : dayjs.utc(dataFilter.endMonth).toDate();

  const [startMonth, setStartMonth] = useState(startDate);
  const [endMonth, setEndMonth] = useState(endDate);
  const currentOrganization = organizations?.[0];

  const { data: monthlyBreakdownData, isLoading: monthlyBreakdownLoading } = useMonthlyBreakdownAPI({
    orgId: currentOrganization?.id,
    // For YoY we need 1 previous month to show on StatisticsSideBar
    startMonth: GROWTH_TYPE_KEYS.YoY ? getLastMonthDate(startMonth).getMonth() : startMonth.getMonth(),
    startYear: GROWTH_TYPE_KEYS.YoY ? getLastMonthDate(startMonth).getFullYear() : startMonth.getFullYear(),
    endMonth: endMonth.getMonth(),
    endYear: endMonth.getFullYear(),
    metadataFilter,
    rollup,
    plugins: requiredPlugins,
    optimisticAnalytics,
    isCommitted,
    growthType,
    countInfluxAsRenewed,
    usesMetricsEngine: currentOrganization?.usesMetricsEngine,
    autoFetch: !isRetentionMetric,
  });

  const {
    data: retentionBreakdownData,
    isLoading: retentionBreakdownLoading,
    refetch: refetchRetentionBreakdown,
  } = useRetentionBreakdownAPI({
    orgId: currentOrganization?.id,
    // [AT 2024-11-29] Using dayjs.utc would actually be incorrect here as I've tested in my timezone for retention
    //  drilldown. I think we shouldn't have called formatDateForDatepicker in DataTableRowValue.
    startMonth: dayjs(startMonth).format('YYYY-MM-DD'),
    endMonth: dayjs(endMonth).format('YYYY-MM-DD'),
    metadataFilter,
    rollup,
    optimisticAnalytics,
    isCommitted,
    countInfluxAsRenewed,
    retentionMetric: monthDataKey,
    autoFetch: isRetentionMetric,
  });

  const usableData = {};
  const growthData = {};
  let dataForRetentionTable;
  if (monthlyBreakdownData) {
    for (const [monthKey, monthData] of Object.entries(monthlyBreakdownData[metricCategoryKey])) {
      if (!(monthKey.includes('-Q') || monthKey.includes('-Y') || monthKey.includes('CUMULATIVE'))) {
        const value = monthData[monthDataKey]?.value ?? monthData[monthDataKey];

        // if metrics has growth metric
        if (METRICS_WITH_GROWTH[metricKey]) {
          growthData[monthKey] = monthData[METRICS_WITH_GROWTH[metricKey]];
        }

        usableData[monthKey] = dataType === NUMBER_FORMATS.PERCENT ? value : Math.round(value * (isARRValue ? 12 : 1));
      }
    }
  } else if (retentionBreakdownData) {
    for (const month of retentionBreakdownData.months) {
      usableData[month] = retentionBreakdownData.retentionByMonth[month];
    }
    dataForRetentionTable = reshapeRetentionDataForTable(retentionBreakdownData);
  }

  const months = generateMonths({ startDate: startMonth, endDate: endMonth, inclusive: true });

  const [selectedGoal, setSelectedGoal] = useState();
  useEffect(() => {
    if (selectedGoalId) {
      setSelectedGoal(goals.find((goal) => goal.id === selectedGoalId));
    }
  }, [selectedGoalId, setSelectedGoal, goals]);

  const goalsList = useMemo(
    () =>
      getGoalsInsideFiltersPeriod({
        goalsMetrics: Object.keys(SNAPSHOT_METRIC_BY_KEY),
        rawGoals: goals,
        metricKey,
        startDate: dayjs.utc(startMonth).startOf('month'),
      }),
    [goals, metricKey, startMonth],
  );

  const goalData = useMemo(() => {
    const multiplier = dataType === NUMBER_FORMATS.PERCENT ? 100 : isARRValue ? 12 : 1;
    return selectedGoal?.periods?.reduce((acc, period) => {
      acc[dayjs.utc(period.start_month).format('YYYY-MM')] = period.value * multiplier;
      return acc;
    }, {});
  }, [selectedGoal, dataType, isARRValue]);

  const revenueData = useMemo(() => {
    if (!isRetentionMetric) return monthlyBreakdownData;
    else {
      // Because we need this format for Goals Banner
      return {
        [metric.metricCategoryKey]: Object.entries(retentionBreakdownData?.retentionByMonth ?? {}).reduce(
          (acc, [monthKey, value]) => {
            acc[monthKey] = {
              [metric.monthDataKey]: value,
            };
            return acc;
          },
          {},
        ),
      };
    }
  }, [isRetentionMetric, metric, monthlyBreakdownData, retentionBreakdownData]);

  return (
    <CardChartModalContext.Provider
      value={{
        metricKey,
        metric,
        title,
        isTTM,
        isARRValue,
        isRetentionMetric,
        selectedGoalId,
        startMonth,
        setStartMonth,
        endMonth,
        setEndMonth,
        months,
        chartData: usableData,
        growthData,
        tableData: dataForRetentionTable,
        refetchRetentionBreakdown,
        revenueData,
        selectedGoal,
        setSelectedGoal,
        goalData,
        goalsList,
        isLoading: monthlyBreakdownLoading || retentionBreakdownLoading,
      }}
    >
      {cardChartModalData && children}
    </CardChartModalContext.Provider>
  );
};
