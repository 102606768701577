import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 14px;
  cursor: pointer;
  height: 40px;
  background: var(--primaryGreen);
  box-shadow: 0px 4px 20px rgba(82, 181, 120, 0.2);
  border-radius: 100px;
  color: white;
`;

export const IconGreenButton = ({ icon: Icon, className, dataCy, text, styles, onClick }) => (
  <Wrapper data-cy={dataCy} onClick={onClick} style={styles} className={className}>
    {Icon} {text}
  </Wrapper>
);
