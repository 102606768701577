import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Spacer } from 'components/Core';
import { Button, StyledButton } from './Button';

const StyledIconButton = styled(StyledButton)`
  width: fit-content;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;

  box-shadow: ${(props) => props.boxShadow ?? `4px 4px 28px var(--primaryBlack5)`};

  &:hover {
    box-shadow: ${(props) => props.boxShadowOnHover ?? `0 4px 20px var(--primaryBlack10)`};
    border: 1px solid var(--neutralGray);
    background: ${(props) => props.colorOnHover};
  }

  svg {
    width: 20px;
    height: 20px;

    path {
      fill: ${(props) => props.iconFillColor};
    }
  }
`;

export const IconButton = ({ onClick, icon, children, ...rest }) => (
  <StyledIconButton onClick={onClick} {...rest}>
    {icon}
    <Spacer width="7px" />
    {children}
  </StyledIconButton>
);

IconButton.propTypes = {
  ...Button.propTypes,
  /**
   * Optional child icon color
   */
  iconFillColor: PropTypes.string,
  /**
   * Optional button color on hover
   */
  colorOnHover: PropTypes.string,
};
