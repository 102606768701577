import { useCallback, useState } from 'react';
import styled, { css } from 'styled-components';

import {
  Modal,
  ModalBody,
  ModalButton,
  ModalCloseIcon,
  ModalContainer,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from 'components/Modal';
import { Flexer, FlexerColumn, Spacer } from 'components/Core';
import { TooltipContainer } from 'components/Tooltip';
import CustomCheckbox from 'components/Controls/CustomCheckbox';

const SectionItem = styled(FlexerColumn)`
  gap: 8px;
  padding: 12px;
`;

const Section = styled(FlexerColumn)`
  border: 1px solid var(--neutralGray);
  background-color: white;
  border-radius: 12px;

  & > ${SectionItem} {
    border-bottom: 1px solid var(--neutralGray);

    &:last-child {
      border-bottom: none;
    }
  }
`;

const SectionTitle = styled(Flexer)`
  color: var(--primaryBlack50);
  align-items: center;
  gap: 8px;

  ${({ primary }) =>
    primary &&
    css`
      color: var(--primaryBlack);
      font-weight: 700;
    `};
`;

const SectionValue = styled.div`
  color: ${({ highlight }) => (highlight ? 'var(--primaryBlue)' : 'var(--primaryBlack)')};
`;

const StyledModal = styled(Modal)`
  & > * {
    background-color: var(--primaryBlack2);
  }
`;

const TooltipText = styled.div`
  opacity: ${({ opacity }) => opacity ?? 1};
`;

const Counter = styled.div`
  border-radius: 4px;
  background-color: var(--primaryBlack5);
  font-weight: 900;
  display: inline-block;
  padding: 2px 4px 0px;
  text-align: center;
  font-size: 9px;
`;

export const ConfirmChangesModal = ({
  onClose,
  newData,
  oldData,
  hasBilling = true,
  hasShipping = true,
  hasContacts = true,
  customerName,
  onSave,
  childrenCustomers,
  serviceName,
}) => {
  const tooltipProps = {
    tooltipWrapperStyles: { borderRadius: '4px' },
    tooltipStyles: { textAlign: 'left' },
    fontSize: '12px',
    width: 776,
    hideArrow: true,
  };

  const hasChildrenCustomers = !!childrenCustomers;

  const [shouldUpdateChildrenEmails, setShouldUpdateChildrenEmails] = useState(hasChildrenCustomers);
  const [shouldUpdateChildrenBilling, setShouldUpdateChildrenBilling] = useState(hasChildrenCustomers);
  const [shouldUpdateChildrenShipping, setShouldUpdateChildrenShipping] = useState(hasChildrenCustomers);

  const getCheckboxLabel = (field) => (
    <i>{`Update children ${field} in Subscript ${serviceName ? '& ' + serviceName : ''}`}</i>
  );

  return (
    <ModalContainer>
      <StyledModal compact maxWidth="850px" height="auto" maxHeight="auto" overflow="auto">
        <ModalHeader>
          <ModalCloseIcon onClick={onClose} />
          <ModalTitle bold>
            We'll update the addresses of the customer{childrenCustomers ? ' and their children' : ''}
          </ModalTitle>
        </ModalHeader>

        <Spacer height="20px" />

        <ModalBody paddingBottom="20px">
          <FlexerColumn gap="16px">
            <Section>
              <SectionItem>
                <SectionTitle primary>Customer</SectionTitle>
                <SectionValue highlight>{customerName}</SectionValue>
              </SectionItem>

              {hasChildrenCustomers && (
                <SectionItem>
                  <SectionTitle primary>
                    Children customers <Counter>{childrenCustomers.length}</Counter>
                  </SectionTitle>
                  <SectionValue highlight>{childrenCustomers.map(({ name }) => name).join(', ')}</SectionValue>
                </SectionItem>
              )}
            </Section>

            <Section>
              {hasContacts && (
                <SectionItem>
                  <SectionTitle>New Emails</SectionTitle>

                  <TooltipContainer
                    {...tooltipProps}
                    toolTipContent={
                      <FlexerColumn>
                        <TooltipText opacity={0.5}>Old emails</TooltipText>
                        <TooltipText>{(oldData.contacts ?? []).join(', ')}</TooltipText>
                      </FlexerColumn>
                    }
                  >
                    <SectionValue highlight>{(newData.contacts ?? []).join(', ')}</SectionValue>
                  </TooltipContainer>

                  {hasChildrenCustomers && (
                    <CustomCheckbox
                      checked={shouldUpdateChildrenEmails}
                      onChange={(e) => setShouldUpdateChildrenEmails(e.target.checked)}
                      label={getCheckboxLabel('emails')}
                    />
                  )}
                </SectionItem>
              )}

              {hasBilling && (
                <SectionItem>
                  <SectionTitle>New billing address</SectionTitle>

                  <TooltipContainer
                    {...tooltipProps}
                    toolTipContent={
                      <FlexerColumn>
                        <TooltipText opacity={0.5}>Old billing address</TooltipText>
                        <TooltipText>{oldData.billing}</TooltipText>
                      </FlexerColumn>
                    }
                  >
                    <SectionValue>{newData.billing}</SectionValue>
                  </TooltipContainer>

                  {hasChildrenCustomers && (
                    <CustomCheckbox
                      checked={shouldUpdateChildrenBilling}
                      onChange={(e) => setShouldUpdateChildrenBilling(e.target.checked)}
                      label={getCheckboxLabel('billing address')}
                    />
                  )}
                </SectionItem>
              )}

              {hasShipping && (
                <SectionItem>
                  <SectionTitle>New shipping address</SectionTitle>
                  <TooltipContainer
                    {...tooltipProps}
                    toolTipContent={
                      <FlexerColumn>
                        <TooltipText opacity={0.5}>Old shipping address</TooltipText>
                        <TooltipText>{oldData.shipping}</TooltipText>
                      </FlexerColumn>
                    }
                  >
                    <SectionValue>{newData.shipping}</SectionValue>
                  </TooltipContainer>

                  {hasChildrenCustomers && (
                    <CustomCheckbox
                      checked={shouldUpdateChildrenShipping}
                      onChange={(e) => setShouldUpdateChildrenShipping(e.target.checked)}
                      label={getCheckboxLabel('shipping address')}
                    />
                  )}
                </SectionItem>
              )}
            </Section>
          </FlexerColumn>
        </ModalBody>

        <ModalFooter flexEnd>
          <ModalButton default fontWeight={700} onClick={onClose}>
            Cancel
          </ModalButton>
          <ModalButton
            primary
            fontWeight={700}
            onClick={() =>
              onSave({
                shouldUpdateChildrenBilling,
                shouldUpdateChildrenEmails,
                shouldUpdateChildrenShipping,
              })
            }
          >
            Save
          </ModalButton>
        </ModalFooter>
      </StyledModal>
    </ModalContainer>
  );
};

export const useConfirmChangesModal = ({ ...props } = {}) => {
  const [showModal, setShowModal] = useState(false);

  const openModal = useCallback(() => setShowModal(true), []);
  const closeModal = useCallback(() => setShowModal(false), []);

  const Modal = useCallback(() => (showModal ? <ConfirmChangesModal onClose={closeModal} {...props} /> : null), [
    closeModal,
    props,
    showModal,
  ]);

  return {
    openModal,
    closeModal,
    Modal,
    isModalOpen: showModal,
  };
};
