import { useContext } from 'react';
import styled from 'styled-components';
import { getAppSetting } from 'models/appSettings';
import { CohortsContext } from '../CohortsContext';
import { CohortLineChart } from './CohortLineChart';

const Wrapper = styled.div`
  width: 100%;
`;

export const CohortsGraph = () => {
  const { isARR, cohortsData, cohortResolution, totalAmounts, dataFilter } = useContext(CohortsContext);
  const { cohortInstallBy } = dataFilter;
  const quarters = getAppSetting('quarters');
  const quartersYearOffset = getAppSetting('quartersYearOffset');

  if (!cohortsData?.[cohortInstallBy]) return null;

  return (
    <Wrapper>
      <CohortLineChart
        isARR={isARR}
        cohortsData={cohortsData[cohortInstallBy].cohortsData}
        cohortResolution={cohortResolution}
        cohortInstallBy={cohortInstallBy}
        totalAmounts={totalAmounts}
        quarters={quarters}
        quartersYearOffset={quartersYearOffset}
      />
    </Wrapper>
  );
};
