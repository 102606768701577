import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { FlexerColumn } from 'components/Core';
import { ReactComponent as _WarningIcon } from 'images/warning-icon.svg';
import { ReactComponent as _ChevronDownIcon } from 'images/chevron-down.svg';

const WarningContainer = styled(FlexerColumn)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  width: 100%;
  margin-top: 15px;
  padding: 4px;
  border: 1px solid var(--primaryRed40);
  border-radius: 12px;
`;

const WarningHeader = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  padding: 10px;
  gap: 8px;
  cursor: pointer;
  font-weight: 900;
  border-radius: 8px;

  ${({ isOpen }) =>
    isOpen &&
    css`
      background-color: var(--newRed5);
    `}
`;

const WarningContent = styled.div`
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
  padding: 8px;
`;

const WarningItems = styled(FlexerColumn)`
  gap: 12px;
`;

const ExpandableCounter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 0px 8px;
  border-radius: 100px;
  background: ${({ isOpen }) => (isOpen ? '#ffffff' : 'var(--primaryBlack5)')};
  height: 20px;
  min-width: 42px;
  font-size: 12px;

  ${({ isOpen }) =>
    isOpen &&
    css`
      border: 1px solid var(--accentGrayThird);
    `}
`;

const ChevronDownIcon = styled(_ChevronDownIcon)`
  width: 16px;
  height: 16px;
  transition: transform 0.3s ease;
  transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
`;

const WarningIcon = styled(_WarningIcon)`
  height: 16px;
  width: 16px;
  margin-left: auto;

  path {
    fill: var(--primaryRed);
  }
`;

export const WarningBarExpandable = ({ warnings }) => {
  const [isOpen, setIsOpen] = useState(true);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <WarningContainer>
      <WarningHeader onClick={toggleAccordion} isOpen={isOpen}>
        <>
          Warnings
          <ExpandableCounter isOpen={isOpen}>
            {warnings.length}
            <ChevronDownIcon isOpen={isOpen} />
          </ExpandableCounter>
        </>
        <WarningIcon />
      </WarningHeader>
      <WarningContent isOpen={isOpen}>
        <WarningItems>
          {Object.entries(warnings).map(([index, warning]) => (
            <span key={`warning-${index}`}>
              <span>{`${warnings.length === 1 ? '' : `${parseInt(index) + 1}. `}`}</span>
              {warning}
            </span>
          ))}
        </WarningItems>
      </WarningContent>
    </WarningContainer>
  );
};
