import { useCallback, useState } from 'react';
import { EVENTS } from 'consts/analytics';
import { useAnalytics } from 'utils/hooks';
import { NewReportModal } from './NewReportModal';

export const useNewReportModal = ({ onSubmitted }) => {
  const { trackEvent } = useAnalytics();
  const [showModal, setShowModal] = useState(false);

  const openModal = useCallback(() => {
    trackEvent({
      name: EVENTS.OPEN_NEW_REPORT_MODAL,
    });

    setShowModal(true);
  }, [trackEvent, setShowModal]);

  const Modal = useCallback(
    () => (showModal ? <NewReportModal onClose={() => setShowModal(false)} onSubmitted={onSubmitted} /> : <></>),
    [showModal, setShowModal, onSubmitted],
  );

  return {
    openNewReportModal: openModal,
    NewReportModal: Modal,
  };
};
