import React, { useContext } from 'react';

import { GROWTH_TYPE_OPTIONS, NUMBER_FORMATS } from 'consts/global';
import { useCurrencyNumberFormatter } from 'utils/hooks';
import { reFormatDate } from 'utils/dateUtils';
import { ReactComponent as MinIcon } from 'images/min_icon_drilldown.svg';
import { ReactComponent as MaxIcon } from 'images/max_icon_drilldown.svg';
import { ReactComponent as UpArrow } from 'images/small-fill-up-arrow.svg';
import { ReactComponent as ChartBar } from 'images/chart-bar.svg';
import { ReactComponent as DownArrow } from 'images/small-fill-down-arrow.svg';

import { Flexer, FlexerColumn, FlexerRow, Spacer } from 'components/Core';
import { DashboardContext } from 'views/Dashboard/DashboardContext';
import { CardChartModalContext } from '../CardChartModalContext';
import {
  TitleAmount,
  TitleMonth,
  IconContainer,
  Label,
  StatsContainer,
  Value,
  Title,
  Text,
  PercentagePill,
  MinIllustration,
  MaxIllustration,
  TitleGrowth,
} from './styled';
import { GROWTH_RATE_METRICS } from '../consts';

export const StatisticsSideBar = () => {
  const { growthType } = useContext(DashboardContext);
  const { metric, metricKey, title, months, chartData: data, growthData } = useContext(CardChartModalContext);
  const numberFormatter = useCurrencyNumberFormatter();
  const startMonth = months[0];
  const endMonth = months[months.length - 1];
  const valueType = metric.dataType;

  let minMonth = startMonth;
  let maxMonth = startMonth;
  months.forEach((month) => {
    if (data[month] > data[maxMonth]) {
      maxMonth = month;
    }
    if (data[month] < data[minMonth]) {
      minMonth = month;
    }
  });

  // Growth between selected period
  const startAmount = data[startMonth];
  const endAmount = data[endMonth];
  const periodGrowth = endAmount - startAmount;
  const periodGrowthRate = startAmount === 0 ? 100 : periodGrowth / startAmount;
  const increasingPeriodGrowth = periodGrowth >= 0;

  // Growth on the period
  const growthRate = growthData[endMonth] ?? endAmount;
  const increasingGrowth = growthRate >= 0;

  return (
    <FlexerColumn data-cy="card-chart-modal__statistics-sidebar" width="280px" gap="16px">
      <StatsContainer>
        <TitleMonth>{reFormatDate(endMonth, 'YYYY-MM', 'MMMM YYYY')}</TitleMonth>
        <Title>{title}</Title>
        <TitleAmount>{numberFormatter({ type: valueType, rawValue: endAmount })}</TitleAmount>

        <Spacer height="20px" />

        <Text>
          Growth
          <PercentagePill positive={increasingPeriodGrowth}>
            {numberFormatter({
              type: NUMBER_FORMATS.PERCENT,
              rawValue: valueType === NUMBER_FORMATS.PERCENT ? periodGrowth : periodGrowthRate,
            })}
            {increasingPeriodGrowth ? <UpArrow fill="var(--primaryGreen)" /> : <DownArrow fill="var(--primaryRed)" />}
          </PercentagePill>
          in selected period
        </Text>
        <Spacer height="8px" />
        <Text>
          Start from {reFormatDate(startMonth, 'YYYY-MM', 'MMM YYYY')}:{' '}
          {numberFormatter({
            type: valueType,
            rawValue: startAmount,
          })}
        </Text>
      </StatsContainer>

      {GROWTH_RATE_METRICS.includes(metricKey) && (
        <StatsContainer>
          <FlexerRow gap="4px" alignItems="center">
            <ChartBar width="16px" heigth="16px" />
            <TitleGrowth>Metrics changes</TitleGrowth>
          </FlexerRow>

          <Spacer height="16px" />

          <Text>
            <PercentagePill positive={increasingGrowth}>
              {numberFormatter({
                type: NUMBER_FORMATS.PERCENT,
                rawValue: growthRate,
              })}
              {increasingGrowth ? <UpArrow fill="var(--primaryGreen)" /> : <DownArrow fill="var(--primaryRed)" />}
            </PercentagePill>
            {GROWTH_TYPE_OPTIONS[growthType]}
          </Text>
        </StatsContainer>
      )}

      <Flexer gap="16px">
        <StatsContainer width="100%" alignItems="flex-start">
          <IconContainer color="var(--primaryRed)">
            <MinIcon /> min
          </IconContainer>
          <Spacer height="16px" />
          <Value>{numberFormatter({ type: valueType, rawValue: data[minMonth] })}</Value>
          <Label>{reFormatDate(minMonth, 'YYYY-MM', 'MMM YYYY')}</Label>
          <MinIllustration />
        </StatsContainer>

        <StatsContainer width="100%" alignItems="flex-end">
          <IconContainer color="var(--primaryGreen)">
            max <MaxIcon />
          </IconContainer>
          <Spacer height="16px" />
          <Value>{numberFormatter({ type: valueType, rawValue: data[maxMonth] })}</Value>
          <Label>{reFormatDate(maxMonth, 'YYYY-MM', 'MMM YYYY')}</Label>
          <MaxIllustration />
        </StatsContainer>
      </Flexer>
    </FlexerColumn>
  );
};
