import React, { useCallback, useContext, useState } from 'react';
import { EVENTS } from 'consts/analytics';
import { useAnalytics } from 'utils/hooks';
import { SelectTransactionModal } from './SelectTransactionModal';
import { InvoicingScheduleContext } from '../InvoicingScheduleContext';

export const useSelectTransactionModal = () => {
  const { trackEvent } = useAnalytics();

  const { includedTransactions, customer } = useContext(InvoicingScheduleContext);

  const [customerId, setCustomerId] = useState();
  const [initiallySelectedTransactions, setInitiallySelectedTransactions] = useState([]);

  const [showModal, setShowModal] = useState(false);

  const openModal = useCallback(() => {
    setCustomerId(customer?.id);
    setInitiallySelectedTransactions(includedTransactions);
    setShowModal(true);

    trackEvent({
      name: EVENTS.OPEN_SELECT_TRANSACTION_FOR_INVOICING_SCHEDULE_MODAL,
      properties: { customerId: customer?.id },
    });
  }, [trackEvent, setCustomerId, customer?.id, includedTransactions, setShowModal]);

  const closeModal = useCallback(() => {
    setShowModal(false);
  }, [setShowModal]);

  const Modal = useCallback(
    () =>
      showModal ? (
        <SelectTransactionModal
          customerId={customerId}
          initiallySelectedTransactions={initiallySelectedTransactions}
          onClose={closeModal}
        />
      ) : null,
    [showModal, customerId, initiallySelectedTransactions, closeModal],
  );

  return {
    openModal,
    Modal,
  };
};
