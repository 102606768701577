export const addGroupToGrouping = ({ invoiceGrouping, newGroup, invoiceItems }) => {
  const updatedGrouping = invoiceGrouping
    ? invoiceGrouping?.reduce(
        (arr, group) =>
          // we remove the independent item as it is being merged
          !group.isMerged && newGroup.invoice_items_ids?.includes(group.invoice_items_ids[0]) ? arr : [...arr, group],
        [],
      )
    : invoiceItems.reduce(
        (arr, item = {}) =>
          newGroup.invoice_items_ids?.includes(item.id) ? arr : [...arr, { invoice_items_ids: [item.id] }],
        [],
      );

  const newGrouping = [...updatedGrouping, newGroup];

  return newGrouping;
};
