import { useContext } from 'react';
import { ReactComponent as ArrowDown } from 'images/chevron-down.svg';
import { InvoiceHistoryPopover } from 'views/Billing/InvoiceHistoryPopover';

import { CollapseViewWrapper, StyledArrow } from '../styles';
import { InvoicingScheduleContext } from '../InvoicingScheduleContext';

export const InvoicingScheduleHistoryChangesPanel = () => {
  const { isHistoryTabCollapsed, setIsHistoryTabCollapsed } = useContext(InvoicingScheduleContext);

  return (
    <CollapseViewWrapper isCollapsed={isHistoryTabCollapsed} disableExpand>
      {!isHistoryTabCollapsed ? (
        <>
          <StyledArrow
            onClick={() => setIsHistoryTabCollapsed(!isHistoryTabCollapsed)}
            active={!isHistoryTabCollapsed}
            data-cy="billing__invoice-schedule-modal__open-history-panel-button"
            isRightSide
            zIndex={!isHistoryTabCollapsed ? 30 : 'auto'}
            XOffset={isHistoryTabCollapsed ? undefined : '-403px'}
          >
            <ArrowDown width={16} height={16} />
          </StyledArrow>

          <InvoiceHistoryPopover
            popoverProps={{
              YOffset: '0',
              XOffset: -210,
            }}
            onClose={() => setIsHistoryTabCollapsed(true)}
          />
        </>
      ) : null}
    </CollapseViewWrapper>
  );
};
