import { useContext } from 'react';
import styled from 'styled-components';
import { AppContext } from 'AppContext';
import { FILE_TYPES, FormikCustomCheckbox } from 'components/Controls';
import { useFileUploader } from 'components/Controls/FileUploader/useFileUploader';
import { FlexBetweenContainer, FlexerColumn, Spacer } from 'components/Core';
import { RowActionPopover } from 'components/Table';
import { TooltipContainer } from 'components/Tooltip';
import { FieldBody, FieldTitle } from 'views/Wizard/styles';
import { useBillingEntityModal } from './useBillingEntityModal';
import { useRemoveBillingEntityModal } from './useRemoveBillingEntityModal';
import { AddButton, Number } from '../../styles';

const Text = styled.div`
  font-weight: ${({ fontWeight }) => fontWeight ?? 400};
  font-size: 12px;
  color: ${({ color }) =>
    color === 'light' ? 'var(--primaryBlack50)' : color === 'blue' ? 'var(--primaryBlue)' : 'var(--primaryBlack)'};
  margin: ${({ margin }) => margin};

  &:hover {
    cursor: ${({ clickable }) => (clickable ? 'pointer' : 'default')};
  }
`;

const Image = styled.img`
  width: 18%;
`;

const EntityImage = ({ initialUploadedFile, orgId }) => {
  const { uploadedFile } = useFileUploader({
    orgId,
    initialUploadedFile,
    privateBucket: false,
  });

  return FILE_TYPES.IMAGES.includes(uploadedFile?.type) && uploadedFile.data ? (
    <Image src={uploadedFile.data} alt="entity" />
  ) : null;
};

export const BillingEntities = ({ values, setFieldValue, availableTags }) => {
  const { orgId, entities, orgConfigs, organizations } = useContext(AppContext);

  const { openBillingEntityModal, BillingEntityModal } = useBillingEntityModal();

  const { billingSenderDefaults: initialBillingSenderDefaults } = orgConfigs;

  const { openRemoveBillingEntityModal, RemoveBillingEntityModal } = useRemoveBillingEntityModal({
    availableTags,
    currentDefaultEntity: initialBillingSenderDefaults?.default_entity_id,
  });

  return (
    <FieldBody>
      <FieldTitle>
        <div>Sender organization</div>
        <AddButton
          onClick={() =>
            openBillingEntityModal(
              entities.length
                ? {
                    company_name: '',
                    address: '',
                  }
                : {
                    company_name: organizations[0]?.name ?? '',
                    entity_name: 'Default organization',
                    logo_s3_key: null,
                    address: '',
                    footer_memo_template_id: null,
                  },
            )
          }
          data-cy="entity__add-button"
        >
          Add Entity
        </AddButton>
      </FieldTitle>
      <Text color="light" margin="-28px 0px 0px">
        Add your company information (for PDFs / emails / subscription pages)
      </Text>

      <Spacer height="25px" />

      <FlexerColumn gap="8px">
        {entities.map((entity, index) => (
          <FlexBetweenContainer key={`entity-${entity.id ?? index}`} gap="16px">
            <Number>{index + 1}</Number>
            <FlexerColumn flexGrow={1} justifyContent="center">
              <FlexBetweenContainer gap="8px" alignItems="center">
                <Text
                  color="blue"
                  fontWeight={700}
                  data-cy={`entities__display-name--${index}`}
                  onClick={() => openBillingEntityModal(entity)}
                  clickable
                >
                  {entity.entity_name ? `${entity.entity_name}: ` : ''}
                  {entity.company_name}
                </Text>

                <TooltipContainer
                  direction="top"
                  toolTipContent="select as default"
                  width={110}
                  fontSize="12px"
                  hideArrow
                >
                  <FormikCustomCheckbox
                    name="billingSenderDefaults.default_entity_id"
                    data-cy={`checkbox__default-entity-${index}`}
                    checked={
                      values.billingSenderDefaults.default_entity_id
                        ? values.billingSenderDefaults.default_entity_id.toString() === entity.id
                        : index === 0
                    }
                    onChange={() => {
                      setFieldValue('billingSenderDefaults.default_entity_id', entity.id ?? null);
                    }}
                  />
                </TooltipContainer>
              </FlexBetweenContainer>

              <Text color="light" data-cy={`entities__display-address--${index}`}>
                {entity.address}
              </Text>

              {entity.logo_s3_key && (
                <EntityImage initialUploadedFile={{ s3FileKey: entity.logo_s3_key }} orgId={orgId} />
              )}
            </FlexerColumn>
            <RowActionPopover
              dataCyPrefix={entity.company_name}
              onEditClick={() => openBillingEntityModal(entity)}
              onDeleteClick={
                entities && entities.length > 1 && entity.id ? () => openRemoveBillingEntityModal(entity) : null
              }
            />
          </FlexBetweenContainer>
        ))}
      </FlexerColumn>

      <BillingEntityModal />
      <RemoveBillingEntityModal />
    </FieldBody>
  );
};
