export const TEMPLATE_DYNAMIC_TAGS = {
  COMPANY_NAME: '{company_name}',
  CUSTOMER_NAME: '{customer_name}',
  TRANSACTION_CUSTOMER_NAME: '{transaction_customer_name}',
  INVOICE_NUMBER: '{invoice_number}',
  INVOICE_PAYMENT_LINK: '{invoice_payment_link}',
  INVOICE_EMAIL_ADDRESSES: '{invoice_email_addresses}',
  SENT_AT: '{invoice_sent_at}',
  PAID_AT: '{invoice_paid_at}',
  INVOICE_DATE: '{invoice_date}',
  INVOICE_DUE_DATE: '{invoice_due_date}',
  INVOICE_AMOUNT: '{invoice_amount}',
  INVOICE_LINK: '{invoice_link}',
  SERVICE_START_DATE: '{service_start_date}',
  SERVICE_END_DATE: '{service_end_date}',
  INVOICE_SERIAL_NUMBER_IN_THE_SCHEDULE: '{invoice_serial_number_in_the_schedule}',
  TOTAL_NUMBER_OF_INVOICES_IN_THE_SCHEDULE: '{total_number_of_invoices_in_the_schedule}',
  CURRENT_INVOICED_AMOUNT_IN_THE_SCHEDULE: '{current_invoiced_amount_in_the_schedule}',
  TOTAL_INVOICED_AMOUNT_IN_THE_SCHEDULE: '{total_invoiced_amount_in_the_schedule}',
  INVOICE_ITEM_PRODUCT_NAME: '{product_name}',
  INVOICE_ITEM_PRODUCT_DISPLAY_NAME: '{product_display_name}',
  INVOICE_ITEM_PRODUCT_LINE_ITEM_DESCRIPTION: '{product_line_item_description}',
  INVOICE_ITEM_TRANSACTION_NAME: '{transaction_name}',
  INVOICING_FREQUENCY: '{invoicing_frequency}',
  NEXT_INVOICE_DATE: '{next_invoice_date}',
  CREDIT_NOTE_NUMBER: '{credit_note_number}',
  CREDIT_NOTE_DATE: '{credit_note_date}',
  CREDIT_NOTE_AMOUNT: '{credit_note_amount}',
  CREDIT_NOTE_SENT_AT: '{credit_note_sent_at}',
  PO_NUMBER: '{po_number}',
};

// JB [2023-05-05]: Please make sure we always have the same in src/components/invoices/replaceVariables.js on the backend
export const DEFAULT_AVAILABLE_TAGS = [
  TEMPLATE_DYNAMIC_TAGS.COMPANY_NAME,
  TEMPLATE_DYNAMIC_TAGS.CUSTOMER_NAME,
  TEMPLATE_DYNAMIC_TAGS.TRANSACTION_CUSTOMER_NAME,
  TEMPLATE_DYNAMIC_TAGS.INVOICE_NUMBER,
  TEMPLATE_DYNAMIC_TAGS.INVOICE_DATE,
  TEMPLATE_DYNAMIC_TAGS.INVOICE_DUE_DATE,
  TEMPLATE_DYNAMIC_TAGS.INVOICE_AMOUNT,
  TEMPLATE_DYNAMIC_TAGS.SERVICE_START_DATE,
  TEMPLATE_DYNAMIC_TAGS.SERVICE_END_DATE,
  TEMPLATE_DYNAMIC_TAGS.INVOICE_PAYMENT_LINK,
  TEMPLATE_DYNAMIC_TAGS.INVOICING_FREQUENCY,
  TEMPLATE_DYNAMIC_TAGS.INVOICE_SERIAL_NUMBER_IN_THE_SCHEDULE,
  TEMPLATE_DYNAMIC_TAGS.TOTAL_NUMBER_OF_INVOICES_IN_THE_SCHEDULE,
  TEMPLATE_DYNAMIC_TAGS.CURRENT_INVOICED_AMOUNT_IN_THE_SCHEDULE,
  TEMPLATE_DYNAMIC_TAGS.TOTAL_INVOICED_AMOUNT_IN_THE_SCHEDULE,
  TEMPLATE_DYNAMIC_TAGS.NEXT_INVOICE_DATE,
  TEMPLATE_DYNAMIC_TAGS.PO_NUMBER,
];

export const CREDIT_NOTE_DYNAMIC_TAGS = [
  TEMPLATE_DYNAMIC_TAGS.CREDIT_NOTE_NUMBER,
  TEMPLATE_DYNAMIC_TAGS.CREDIT_NOTE_DATE,
  TEMPLATE_DYNAMIC_TAGS.CREDIT_NOTE_AMOUNT,
  TEMPLATE_DYNAMIC_TAGS.CREDIT_NOTE_SENT_AT,
];

export const DESCRIPTION_TAGS = [
  TEMPLATE_DYNAMIC_TAGS.INVOICE_ITEM_PRODUCT_NAME,
  TEMPLATE_DYNAMIC_TAGS.INVOICE_ITEM_PRODUCT_DISPLAY_NAME,
  TEMPLATE_DYNAMIC_TAGS.INVOICE_ITEM_PRODUCT_LINE_ITEM_DESCRIPTION,
  TEMPLATE_DYNAMIC_TAGS.INVOICE_ITEM_TRANSACTION_NAME,
];

// LL [2024-08-06]: Please make sure we always have the same in src/components/billing_email_remainders/replaceReminderVariables.js on the backend
export const TEMPLATE_MODAL_DYNAMIC_TAGS = [
  TEMPLATE_DYNAMIC_TAGS.COMPANY_NAME,
  TEMPLATE_DYNAMIC_TAGS.CUSTOMER_NAME,
  TEMPLATE_DYNAMIC_TAGS.INVOICE_NUMBER,
  TEMPLATE_DYNAMIC_TAGS.INVOICE_PAYMENT_LINK,
  TEMPLATE_DYNAMIC_TAGS.INVOICE_DATE,
  TEMPLATE_DYNAMIC_TAGS.INVOICE_DUE_DATE,
  TEMPLATE_DYNAMIC_TAGS.INVOICING_FREQUENCY,
  TEMPLATE_DYNAMIC_TAGS.INVOICE_AMOUNT,
  TEMPLATE_DYNAMIC_TAGS.SERVICE_START_DATE,
  TEMPLATE_DYNAMIC_TAGS.SERVICE_END_DATE,
  TEMPLATE_DYNAMIC_TAGS.SENT_AT,
  TEMPLATE_DYNAMIC_TAGS.PAID_AT,
  TEMPLATE_DYNAMIC_TAGS.INVOICE_EMAIL_ADDRESSES,
  TEMPLATE_DYNAMIC_TAGS.PO_NUMBER,
];

export const GENERATE_NEW_PAYMENT_LINK_EMAIL_TAGS = [
  TEMPLATE_DYNAMIC_TAGS.CUSTOMER_NAME,
  TEMPLATE_DYNAMIC_TAGS.INVOICE_DATE,
  TEMPLATE_DYNAMIC_TAGS.INVOICE_DUE_DATE,
  TEMPLATE_DYNAMIC_TAGS.INVOICE_AMOUNT,
  TEMPLATE_DYNAMIC_TAGS.COMPANY_NAME,
  TEMPLATE_DYNAMIC_TAGS.INVOICE_NUMBER,
  TEMPLATE_DYNAMIC_TAGS.INVOICE_PAYMENT_LINK,
];

// Matches BILLING_CUSTOM_FIELDS_VARIABLES in the backend
export const BILLING_CUSTOM_FIELDS_VARIABLES = {
  UNPAID_AMOUNT_INVOICED: 'unpaid_invoiced_amount_in_schedule',
  CURRENT_AMOUNT_INVOICED: 'current_invoiced_amount_in_schedule',
  AMOUNT_LEFT_TO_INVOICE: 'amount_left_to_invoice_in_schedule',
  TOTAL_AMOUNT_TO_INVOICE: 'total_amount_to_invoice_in_schedule',
};

// Matches CUSTOM_FIELD_TO_INVOICE_FIELD_MAP in the backend
export const CUSTOM_FIELD_TO_INVOICE_FIELD_MAP = {
  po_number: 'po_number',
  service_start_date: 'service_start',
  service_end_date: 'service_end',
};

export const BILLING_CUSTOM_FIELDS_VARIABLE_LABELS = {
  [BILLING_CUSTOM_FIELDS_VARIABLES.UNPAID_AMOUNT_INVOICED]: 'Unpaid invoiced amount',
  [BILLING_CUSTOM_FIELDS_VARIABLES.CURRENT_AMOUNT_INVOICED]: 'Current invoiced amount',
  [BILLING_CUSTOM_FIELDS_VARIABLES.AMOUNT_LEFT_TO_INVOICE]: 'Amount left to invoice',
  [BILLING_CUSTOM_FIELDS_VARIABLES.TOTAL_AMOUNT_TO_INVOICE]: 'Total amount to invoice',
};

export const BILLING_CUSTOM_FIELDS_VARIABLES_TYPE = {
  [BILLING_CUSTOM_FIELDS_VARIABLES.UNPAID_AMOUNT_INVOICED]: 'number',
  [BILLING_CUSTOM_FIELDS_VARIABLES.CURRENT_AMOUNT_INVOICED]: 'number',
  [BILLING_CUSTOM_FIELDS_VARIABLES.AMOUNT_LEFT_TO_INVOICE]: 'number',
  [BILLING_CUSTOM_FIELDS_VARIABLES.TOTAL_AMOUNT_TO_INVOICE]: 'number',
};

export const BILLING_CUSTOM_FIELD_OPTIONS = Object.values(BILLING_CUSTOM_FIELDS_VARIABLES).map((value) => ({
  label: BILLING_CUSTOM_FIELDS_VARIABLE_LABELS[value],
  value,
}));
