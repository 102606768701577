import React, { useMemo, useContext } from 'react';
import dayjs from 'dayjs';
import { isNil } from 'lodash';
import { AppContext } from 'AppContext';
import styled from 'styled-components';
import { useTable } from 'react-table';
import { ACCOUNTING_SPREAD_POLICY_SPREAD_BY, NUMBER_FORMATS, RECOGNITION_TYPES } from 'consts/global';
import { numberFormatter } from 'utils/formatters';
import { Flexer, FlexerColumn } from 'components/Core';
import {
  ReactTableBody,
  ReactTableHeader,
  ReactTableHeaderColumn,
  ReactTableHeaderRow,
  ReactTableCell,
  ReactTableRow,
} from 'components/Table';
import { TooltipContainer } from 'components/Tooltip';
import { ACCOUNTING_SPREAD_REASON, getValuesForTransaction } from 'views/Spreads/utils';

var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

const TableContainer = styled.div`
  width: 100%;
  margin-top: 32px;
`;

const TransactionName = styled.span`
  color: var(--primaryBlue);
  font-weight: 700;
  cursor: pointer;
`;

const Dates = styled.span`
  color: var(--primaryBlack50);
`;

const CellContainer = styled(Flexer)`
  justify-content: ${({ alignRight }) => (alignRight ? 'flex-end' : 'space-between')};
  width: 100%;
`;

const CalculationOperatorSymbol = styled.span`
  color: var(--primaryBlack50);
`;

const Divider = styled.hr`
  height: 1px;
  width: 100%;
  border: none;
  margin: 12px 0;
  background-color: var(--accentGraySecond);
`;

export const TransactionsTable = ({
  detailsPerTransaction,
  accountingSpreadPolicy,
  setTransactionToShow,
  accountingCurrency,
}) => {
  const { organizations, formatDateWithLocale } = useContext(AppContext);

  const isMultiCurrency = organizations?.[0]?.currencies?.length > 1 ?? false;

  const dataForTable = useMemo(
    () =>
      Object.values(detailsPerTransaction).map((transaction) => ({
        ...transaction,
        ...getValuesForTransaction({ transaction, accountingSpreadPolicy, currency: accountingCurrency }),
      })),
    [detailsPerTransaction, accountingSpreadPolicy, accountingCurrency],
  );

  const columns = [
    {
      Header: 'TRANSACTION',
      accessor: 'transactionName',
      id: 'transactionName',
      width: 160,
      Cell: ({ row, value }) => (
        <TransactionName
          onClick={() => {
            setTransactionToShow({ id: row.original.transactionId });
          }}
        >
          {value || '[No name]'}
        </TransactionName>
      ),
    },
    {
      Header: 'DATES',
      accessor: 'startDate',
      id: 'dates',
      width: 200,
      Cell: ({ row }) => {
        let text;
        switch (row.original.recognition) {
          case RECOGNITION_TYPES.linear:
          case RECOGNITION_TYPES.linearNotRecurring:
            text = `${formatDateWithLocale(row.original.startDate)} - ${formatDateWithLocale(row.original.endDate)}`;
            break;
          case RECOGNITION_TYPES.eventRecurring:
          case RECOGNITION_TYPES.eventNotRecurring:
            text = `Event-based: ${formatDateWithLocale(row.original.spread.date)}`;
            break;
          case RECOGNITION_TYPES.immediate:
            text = `One time: ${formatDateWithLocale(row.original.spread.date)}`;
            break;
          case RECOGNITION_TYPES.tillCanceled:
            text = `${formatDateWithLocale(row.original.startDate)} - ${
              row.original.endDate ? formatDateWithLocale(row.original.endDate) : 'Till canceled'
            }`;
            break;
          default:
        }

        return <Dates>{text}</Dates>;
      },
    },
    {
      Header: 'ACT. DAYS',
      accessor: 'actDays',
      id: 'actDays',
      width: 150,
      alignRight: true,
      Cell: ({ value }) => value ?? '',
    },
    {
      Header: 'DAYS IN MONTH',
      accessor: 'daysInMonth',
      id: 'daysInMonth',
      width: 100,
      alignRight: true,
      Cell: ({ row }) =>
        row.original.daysInMonth ? (
          <CellContainer alignRight={!row.original.actDays}>
            {row.original.actDays && <CalculationOperatorSymbol>÷</CalculationOperatorSymbol>}
            <span>{row.original.daysInMonth}</span>
          </CellContainer>
        ) : (
          ''
        ),
    },
    {
      Header:
        accountingSpreadPolicy?.spreadBy === ACCOUNTING_SPREAD_POLICY_SPREAD_BY.DAILY_AMOUNT
          ? 'DAILY AMOUNT'
          : 'MONTH AMOUNT',
      accessor: 'monthlyAmount',
      id: 'monthlyAmount',
      width: 100,
      alignRight: true,
      Cell: ({ row }) => {
        let text = row.original.monthlyAmount;
        if (!text) {
          const rawValue = row.original.spread.amount ?? row.original.spread.spread_amount ?? 0;
          text = numberFormatter({
            rawValue,
            type: NUMBER_FORMATS.CURRENCY,
            currency: accountingCurrency,
            decimalPlaces: 2,
          });
        }
        return (
          <CellContainer alignRight={!row.original.actDays}>
            {row.original.daysInMonth && row.original.spread.reason !== ACCOUNTING_SPREAD_REASON.OVERRIDE_AMOUNT && (
              <CalculationOperatorSymbol>×</CalculationOperatorSymbol>
            )}
            <span>{text}</span>
          </CellContainer>
        );
      },
    },
    {
      Header: 'ACCOUNTING REV.',
      accessor: 'accountingRevenue',
      id: 'accountingRevenue',
      width: 100,
      alignRight: true,
      Cell: ({ value, row }) => {
        const {
          equationTemplate,
          equationCalculation,
          originalCurrency,
          conversionCurrency,
          conversionDate,
          conversionRate,
          rateType,
        } = row.original.spread?.metadata ?? {};
        const toolTipContent = (
          <FlexerColumn alignItems="flex-start">
            <b>Conversion calculation:</b>
            <span>{equationTemplate} =</span>
            <span>
              {originalCurrency} {equationCalculation} =
            </span>
            <span>
              {conversionCurrency} {value}
            </span>
            {rateType !== 'transaction_metadata_override' && (
              <>
                <Divider />
                <b>
                  Conversion rates from {originalCurrency} to {conversionCurrency}:
                </b>
                {(conversionDate ?? []).map((date, index) => (
                  <span>
                    [{date}]: {conversionRate[index]}
                  </span>
                ))}
              </>
            )}
          </FlexerColumn>
        );

        return (
          <CellContainer>
            <CalculationOperatorSymbol>=</CalculationOperatorSymbol>
            <TooltipContainer
              isVisible={isMultiCurrency && !isNil(equationCalculation)}
              toolTipContent={toolTipContent}
              width={400}
            >
              <span>
                {numberFormatter({
                  rawValue: value,
                  type: NUMBER_FORMATS.CURRENCY,
                  currency: accountingCurrency,
                  decimalPlaces: 2,
                })}
              </span>
            </TooltipContainer>
          </CellContainer>
        );
      },
    },
    {
      Header: 'INVOICED THIS MONTH',
      accessor: 'invoiced',
      id: 'invoiced',
      width: 100,
      alignRight: true,
      Cell: ({ value }) =>
        numberFormatter({
          rawValue: value,
          type: NUMBER_FORMATS.CURRENCY,
          currency: accountingCurrency,
          decimalPlaces: 2,
        }),
    },
    {
      Header: 'TOTAL INVOICED',
      accessor: 'totalInvoiced',
      id: 'totalInvoiced',
      width: 100,
      alignRight: true,
      Cell: ({ value }) =>
        numberFormatter({
          rawValue: value,
          type: NUMBER_FORMATS.CURRENCY,
          currency: accountingCurrency,
          decimalPlaces: 2,
        }),
    },
    {
      Header: 'ACCRUED REV.',
      accessor: 'accruedRevenue',
      id: 'accruedRevenue',
      width: 100,
      alignRight: true,
      Cell: ({ value }) =>
        numberFormatter({
          rawValue: value,
          type: NUMBER_FORMATS.CURRENCY,
          currency: accountingCurrency,
          decimalPlaces: 2,
        }),
    },
    {
      Header: 'DEFERRED REV.',
      accessor: 'deferredRevenue',
      id: 'deferredRevenue',
      width: 100,
      alignRight: true,
      Cell: ({ value }) =>
        numberFormatter({
          rawValue: value,
          type: NUMBER_FORMATS.CURRENCY,
          currency: accountingCurrency,
          decimalPlaces: 2,
        }),
    },
  ];

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable({
    columns,
    data: dataForTable,
    initialState: {
      hiddenColumns:
        accountingSpreadPolicy?.spreadBy === ACCOUNTING_SPREAD_POLICY_SPREAD_BY.DAILY_AMOUNT ? ['daysInMonth'] : [],
    },
  });

  return (
    <TableContainer {...getTableProps()}>
      <ReactTableHeader backgroundColor="var(--secondaryGray)" notSticky>
        {headerGroups.map((headerGroup) => (
          <ReactTableHeaderRow {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <ReactTableHeaderColumn
                {...column.getHeaderProps()}
                customWidth={column.width}
                alignRight={column.alignRight}
              >
                {column.render('Header')}
              </ReactTableHeaderColumn>
            ))}
          </ReactTableHeaderRow>
        ))}
      </ReactTableHeader>
      {rows.length ? (
        <ReactTableBody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <ReactTableRow {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <ReactTableCell
                    {...cell.getCellProps()}
                    customWidth={cell.column.width}
                    alignRight={cell.column.alignRight}
                  >
                    {cell.render('Cell')}
                  </ReactTableCell>
                ))}
              </ReactTableRow>
            );
          })}
        </ReactTableBody>
      ) : null}
    </TableContainer>
  );
};
