import React, { useContext } from 'react';
import styled from 'styled-components';
import { AppContext } from 'AppContext';
import { ACCOUNTING_RATE_TYPES, ACCOUNTING_SPREAD_POLICY_LABELS } from 'consts/global';
import { FieldBody, FieldTitle } from 'views/Wizard/styles';
import { FormikCustomRadio, FormikCustomSelector } from 'components/Controls';
import { TooltipContainer } from 'components/Tooltip';
import { AccountingCurrenciesTable } from './AccountingCurrenciesTable';

const ItemsRow = styled.div`
  padding: 16px 28px;
`;

const ACCOUNTING_RATE_TYPES_TO_LABEL = {
  [ACCOUNTING_RATE_TYPES.BOOKING_DATES]: 'Booking Date',
  [ACCOUNTING_RATE_TYPES.INVOICE_DATES]: 'Invoice Date',
};

export const AccountingRevenuePolicy = ({ initialAccountingRevenuePolicy, values, setFieldValue }) => {
  const { organizations } = useContext(AppContext);
  const hasAccountingSpreads = organizations?.[0]?.hasAccountingSpreads;
  const isMultiCurrency = organizations?.[0]?.currencies?.length > 1 ?? false;
  const hasAccountingSpreadPolicy =
    initialAccountingRevenuePolicy && Object.keys(initialAccountingRevenuePolicy).length > 0;

  return (
    <FieldBody style={{ padding: 0 }}>
      <FieldTitle noMarginBottom style={{ padding: '28px 28px 0px 28px' }}>
        <span>Accounting revenue</span>
      </FieldTitle>

      <ItemsRow>
        <FormikCustomSelector
          name="accountingSpreadPolicy.spreadBy"
          options={Object.entries(ACCOUNTING_SPREAD_POLICY_LABELS).map(([value, label]) => ({ label, value }))}
          isDisabled={hasAccountingSpreadPolicy}
        />
      </ItemsRow>

      <ItemsRow>
        <FormikCustomRadio
          name="accountingSpreadPolicy.includeStartDate"
          options={[
            {
              label: 'Include the day the contract starts on',
              value: true,
            },
            {
              label: 'Start the revenue recognition the day after the contract starts',
              value: false,
            },
          ]}
          disabled={hasAccountingSpreadPolicy}
        />
      </ItemsRow>

      {isMultiCurrency && (
        <>
          <FieldTitle noMarginBottom style={{ padding: '28px 28px 0px 28px' }}>
            <span>Accounting Conversion Rate Type</span>
          </FieldTitle>

          <ItemsRow>
            <TooltipContainer
              toolTipContent={'Please contact Subscript support if you wish to change'}
              isVisible={hasAccountingSpreads}
            >
              <FormikCustomSelector
                name="defaultAccountingRateType"
                options={Object.values(ACCOUNTING_RATE_TYPES).map((value) => ({
                  label: ACCOUNTING_RATE_TYPES_TO_LABEL[value],
                  value,
                }))}
                isDisabled={hasAccountingSpreads}
              />
            </TooltipContainer>
          </ItemsRow>
        </>
      )}

      <AccountingCurrenciesTable values={values} setFieldValue={setFieldValue} />
    </FieldBody>
  );
};
