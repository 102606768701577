import React, { useContext } from 'react';

import { GROWTH_TYPE_OPTIONS } from 'consts/global';
import { Spacer } from 'components/Core';
import { SelectDropdownButton } from 'components/Buttons';
import { DashboardContext } from 'views/Dashboard/DashboardContext';
import { CardChartModalContext } from '../CardChartModalContext';
import { GROWTH_RATE_METRICS } from '../consts';
import { SelectorCard } from './styled';

export const GrowthTypeSelector = () => {
  const { growthType, setGrowthType } = useContext(DashboardContext);
  const { metricKey } = useContext(CardChartModalContext);

  return GROWTH_RATE_METRICS.includes(metricKey) ? (
    <SelectorCard>
      <span>Growth rate:</span>
      <Spacer width="8px" />
      <SelectDropdownButton
        name="growth_type_dropdown"
        options={GROWTH_TYPE_OPTIONS}
        selected={growthType}
        onSelect={setGrowthType}
        noMargin={true}
      />
    </SelectorCard>
  ) : (
    <></>
  );
};
