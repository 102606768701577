import styled from 'styled-components';

import { FlexerColumn, FlexerRow } from 'components/Core';
import { ReactComponent as NoDataIcon } from 'images/no-metadata-image.svg';
import { ReactComponent as PlusIcon } from 'images/plus.svg';

const Text = styled.div`
  color: var(--primaryBlack50);
  text-align: center;
  font-size: 14px;
`;

const AddMetadataWhiteButton = styled(FlexerRow)`
  background-color: white;
  padding: 12px;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  gap: 8px;
  color: var(--primaryBlack);
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
`;

const StyledPlusIcon = styled(PlusIcon)`
  path {
    fill: var(--dark7);
  }
  width: 20px;
  height: 20px;
`;

export const NoMetadata = ({ handleAddMetadata }) => (
  <FlexerColumn justifyContent="center" alignItems="center" height="100%" gap="8px">
    <FlexerColumn alignItems="center" gap="8px">
      <NoDataIcon />
      <Text>
        Metadata sync is not configured.
        <br />
        Do you want to set this up?
      </Text>
    </FlexerColumn>
    {handleAddMetadata && (
      <AddMetadataWhiteButton onClick={handleAddMetadata}>
        <StyledPlusIcon />
        Add Metadata
      </AddMetadataWhiteButton>
    )}
  </FlexerColumn>
);
