import styled from 'styled-components';
import { Spacer } from 'components/Core';
import { ReactComponent as CheckIcon } from 'images/transaction_check.svg';

const Wrapper = styled.div`
  background: var(--primaryGreen);
  display: flex;
  align-items: center;
  border: 1px solid var(--primaryBlack5);
  border-radius: 100px;
  color: #ffffff;
  padding: 8px 14px;
  padding-right: 8px;
  cursor: pointer;
  pointer-events: ${(props) => props.disabled && 'none'};
  opacity: ${(props) => props.disabled && '0.3'};
`;

export const SaveButton = ({ loading, ...rest }) => (
  <Wrapper disabled={loading} {...rest}>
    {loading ? 'Saving' : 'Save'}
    <Spacer width="10px" />
    <CheckIcon height="20px" />
  </Wrapper>
);
