import styled from 'styled-components';
import { TooltipContainer } from 'components/Tooltip';

const Wrapper = styled.div`
  align-items: center;
  background-color: ${({ secondary, red }) =>
    red ? 'var(--newRed10)' : secondary ? 'var(--primaryBlack10);' : 'var(--primaryBlue10)'};
  border-radius: 8px;
  color: ${({ secondary, red }) =>
    red ? 'var(--tertiaryRed)' : secondary ? 'var(--primaryBlack75)' : 'var(--primaryBlue)'};
  cursor: pointer;
  display: flex;
  font-size: 10px;
  font-weight: 900;
  line-height: 12px;
  opacity: ${({ active, disabled }) => (active && !disabled ? 1 : 0.5)};
  padding: 8px;
  text-align: center;
  text-transform: uppercase;
  transition: all ease 0.4s;
  width: fit-content;
  height: fit-content;

  &:hover {
    opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  }
`;

export const TableActionButton = ({ toolTipContent, toolTipWidth, ...rest }) =>
  toolTipContent ? (
    <TooltipContainer toolTipContent={toolTipContent} width={toolTipWidth}>
      <Wrapper {...rest} />
    </TooltipContainer>
  ) : (
    <Wrapper {...rest} />
  );
