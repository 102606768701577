import styled from 'styled-components';
import { ReactComponent as Icon } from 'images/metadata-plus.svg';

export const PlusSignIcon = styled(Icon)`
  width: ${({ size }) => size ?? '20px'};
  height: ${({ size }) => size ?? '20px'};

  stroke: ${({ stroke }) => stroke};
  path {
    fill: ${({ fill }) => fill};
  }
`;
