import styled from 'styled-components';

// TODO: Move this to src/components/Core
export const StyledAvatar = styled.div`
  width: 40px;
  height: 40px;
  background: linear-gradient(180deg, var(--primaryPurple) 0%, var(--primaryRed) 100%);
  box-shadow: 0px 4px 20px var(--primaryBlack10);
  border-radius: 100px;
  border: 2px solid #ffffff;
  position: relative;
`;

export const HeaderContainer = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: ${({ margin }) => margin ?? '20px 0'};
`;

export const HeaderTitle = styled.h1`
  font-size: 30px;
  display: flex;
  align-items: center;
  line-height: 40px;
  font-weight: ${({ weight }) => weight ?? 900};
  margin-bottom: 0;
  margin-right: 15px;
  color: var(--primaryBlack);
  cursor: ${({ cursor }) => cursor ?? 'default'};

  opacity: ${({ variant }) => (variant === 'secondary' ? '0.3' : '1')};
`;
