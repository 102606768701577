import styled from 'styled-components';

export const DataFilter = styled.div`
  border-radius: 10px;
  pointer-events: ${(props) => props.isDisabled && 'none'};
  opacity: ${(props) => props.isDisabled && '0.3'};
  padding: 0 18px 46px 18px;
`;

export const AddFilter = styled.div`
  font-weight: 900;
  font-size: 12px;
  line-height: 16px;
  cursor: pointer;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  color: var(--primaryGreen);

  &:hover {
    color: var(--secondaryGreen);
  }

  svg {
    margin-right: 7px;
  }
`;

export const GroupWrapper = styled.div`
  width: 100%;
  background: #fff;
  border-radius: 10px;
  padding: 14px;
  max-height: 410px;
  position: relative;
  box-shadow: 4px 4px 28px var(--primaryBlack5);
`;

export const GrayText = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  position: relative;
  margin-right: 8px;
  opacity: 0.3;
  width: auto;
`;

export const GroupConditionType = styled.div`
  display: flex;
  align-items: center;
  font-weight: 900;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  color: var(--primaryBlue);
  margin: 14px 18px;
`;

export const LogicalTitle = styled.div`
  font-weight: bold;
  font-size: 12px;
  margin-left: 14px;
  line-height: 16px;
  color: rgba(0, 21, 46, 0.3);
  text-transform: uppercase;
`;

export const FilterGroup = styled.div`
  width: 100%;
  padding-left: 38px;
`;

export const FormBody = styled.div`
  padding: 20px;

  > * {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const FormFooter = styled.div`
  align-items: center;
  display: flex;
  position: absolute;
  padding-left: 29px;
  bottom: 20px;
  justify-content: space-between;
`;

export const HasFilterValue = styled.div`
  display: flex;
  margin-left: 6px;
  padding: 4px 6px;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  background: var(--primaryBlack5);
  border-radius: 6px;

  span {
    margin: 0 4px;
    opacity: 0.4;
  }
`;

export const RemoveButton = styled.div`
  background: rgba(255, 93, 93, 0.1);
  border-radius: 100px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    svg path {
      fill: var(--secondaryRed);
    }
  }
`;

export const ApplyButton = styled.button`
  font-weight: 900;
  font-size: 10px;
  line-height: 14px;
  border: none;
  padding: 8px 12px;
  background: var(--primaryGreen);
  box-shadow: 0px 4px 20px rgba(82, 181, 120, 0.2);
  border-radius: 100px;
  text-transform: uppercase;
  color: #ffffff;
  cursor: pointer;

  &:hover {
    background: var(--secondaryGreen);
  }
`;

export const HasFiltersContainer = styled.div`
  padding: 5px;
  padding-right: 12px;
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 18px;
  color: rgba(0, 21, 46, 0.8);
  cursor: pointer;

  &:hover .segment-icon rect {
    fill: var(--secondaryBlue);
  }
`;

export const FilterIconContainer = styled.div`
  margin-right: 12px;
  height: 30px;
`;

export const AdvancedFilterDescription = styled.div`
  font-size: 11px;
  line-height: 16px;
  opacity: 0.5;
  padding-left: 58px;
  margin-top: 5px;
`;

export const ArrowWrapper = styled.div`
  margin-left: 6px;
  transition: transform ease 0.4s;
  transform: ${(props) => (props.isOpen ? 'rotate(0deg)' : 'rotate(180deg)')};
`;

export const NoFilters = styled.div`
  padding: 5px;
  padding-right: 12px;

  cursor: pointer;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: var(--primaryBlue);
  display: flex;
  align-items: center;

  &:hover svg rect {
    fill: var(--secondaryBlue);
  }

  svg {
    filter: drop-shadow(0px 4px 10px rgba(57, 161, 220, 0.2));
  }
`;
