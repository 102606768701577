import React from 'react';
import { CentererVertical, Spacer } from 'components/Core';
import { FormikCustomSelector } from 'components/Controls';

export const PERIOD_TYPE_OPTIONS = [
  { value: 'month', label: 'Monthly' },
  { value: 'quarter', label: 'Quarterly' },
  { value: 'year', label: 'Yearly' },
];

export const PeriodTypeField = ({ hasAllOption = false, name = 'periodType' }) => {
  const options = hasAllOption ? [{ value: 'all', label: 'All' }, ...PERIOD_TYPE_OPTIONS] : PERIOD_TYPE_OPTIONS;

  return (
    <CentererVertical>
      <div>Period Type:</div>
      <Spacer width="8px" />
      <FormikCustomSelector width="120px" options={options} name={name} />
    </CentererVertical>
  );
};
