import { useContext, useState } from 'react';
import { AppContext } from 'AppContext';
import { transactionDisplayName } from 'models/transaction';
import { useTransactionsAPI } from 'api/transactions';
import { useInvoicingScheduleAPI } from 'api/billing/hooks';
import { DeleteConfirmationModal } from 'shared/DeleteConfirmationModal';
import { getUnsentInvoicesToBeUpdated } from './utils';
import { TransactionContext } from './TransactionContext';

export const TransactionDeleteModal = ({ organizationId, transaction, onClose, onDeleted }) => {
  const {
    operations: { removeTransaction },
  } = useTransactionsAPI({ orgId: organizationId, autoFetch: false });

  const {
    appSettings: { currencyISOCode },
  } = useContext(AppContext);

  const {
    data: invoicingSchedule,
    isFetching: isFetchingSchedule,
    isLoading: isLoadingSchedule,
  } = useInvoicingScheduleAPI({
    autoFetch: !!transaction?.invoicing_schedule_id,
    orgId: organizationId,
    invoicingScheduleId: transaction?.invoicing_schedule_id,
  });

  const context = useContext(TransactionContext);
  const { setInvoicesToUpdate, setShowUpdateInvoicesModal, hasConfirmedUnsentInvoices, transactionWithChangedData } =
    context ?? {};

  const [loading, setLoading] = useState(false);

  const handleDelete = async () => {
    setLoading(true);
    try {
      await removeTransaction.mutateAsync({ id: transaction.id, params: { skipInvoicingResync: true } });
      if (transaction?.invoicing_schedule_id && invoicingSchedule?.id && setInvoicesToUpdate) {
        const scheduleTransactionsAfterRemove = invoicingSchedule?.transactions?.filter(
          (scheduleTransaction) => scheduleTransaction?.id !== transaction?.id,
        );
        const sentOrPaidOrVoidedInvoices = invoicingSchedule.invoices?.filter(
          (invoice) => invoice.sent_at || invoice.paid_at || invoice.voided_at,
        );

        if (!scheduleTransactionsAfterRemove?.length && !sentOrPaidOrVoidedInvoices?.length) {
          // invoicing schedule can be deleted
          setInvoicesToUpdate({
            invoicesToDelete: invoicingSchedule?.invoices,
            updatedInvoices: [],
            removeSchedule: true,
          });
          setShowUpdateInvoicesModal(true);
          setLoading(false);
          return;
        } else if (
          scheduleTransactionsAfterRemove?.length ||
          (!scheduleTransactionsAfterRemove?.length && sentOrPaidOrVoidedInvoices?.length)
        ) {
          // invoicing schedule cannot be deleted, but there maybe invoices to update
          const invoicesToUpdate = await getUnsentInvoicesToBeUpdated({
            removeMode: true,
            hasConfirmedUnsentInvoices,
            transactionWithChangedData,
            orgId: organizationId,
            currentInvoicingScheduleId: transaction?.invoicing_schedule_id,
            transactionIdsToRemove: [transaction?.id],
          });
          if (
            invoicesToUpdate?.invoicesToDelete?.length > 0 ||
            invoicesToUpdate?.invoicesToInsert?.length > 0 ||
            invoicesToUpdate?.changedInvoices?.length > 0 ||
            invoicesToUpdate?.deletedTransactions?.length > 0
          ) {
            setInvoicesToUpdate(invoicesToUpdate);
            setShowUpdateInvoicesModal(true);
            setLoading(false);
            return;
          }
        }
      }

      onDeleted?.({ deletedTransaction: transaction });
      onClose?.();
    } catch (err) {
      console.error({ message: err.message, component: 'TransactionDeleteModal.js', stack: err });
    }
    setLoading(false);
  };

  return (
    <DeleteConfirmationModal
      modalName="transaction-delete-modal"
      resourceType="transaction"
      resourceName={transactionDisplayName({ transaction, currency: currencyISOCode })}
      isLoading={loading || isFetchingSchedule || isLoadingSchedule}
      onClose={onClose}
      onDeleteConfirm={handleDelete}
    />
  );
};
