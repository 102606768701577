import { useState, useContext, useEffect, useCallback } from 'react';
import { AppContext } from 'AppContext';
import { Form, Formik } from 'formik';
import styled from 'styled-components';
import * as Yup from 'yup';

import { ReactComponent as CheckIcon } from 'images/transaction_check.svg';
import { ReactComponent as TrashIcon } from 'images/trash-wizard-selector.svg';
import { FormikCustomInput, FormikCustomSelector } from 'components/Controls';
import { useProductsAPI } from 'api/products';
import { Loader } from 'components/Loaders';
import {
  Modal,
  ModalBody,
  ModalCloseIcon,
  ModalHeader,
  ModalTitle,
  ModalContainer,
  ModalFooter,
} from 'components/Modal';
import { CentererVertical, Row } from 'components/Core';

const CancelButton = styled.div`
  padding: 11px 14px;
  font-size: 14px;
  line-height: 18px;
  background: var(--primaryBlack5);
  border: 1px solid var(--primaryBlack5);
  border-radius: 100px;
  margin-right: 10px;
  cursor: pointer;
`;

const SubmitButton = styled.button`
  padding: 8px;
  padding-left: 14px;
  font-size: 14px;
  display: flex;
  align-items: center;
  color: #ffffff;
  line-height: 18px;
  background: ${(props) => (props.isRemove ? 'var(--primaryRed)' : 'var(--primaryGreen)')};
  box-shadow: 0px 4px 20px rgba(82, 181, 120, 0.2);
  border-radius: 100px;
  border: none;
  cursor: pointer;
  pointer-events: ${(props) => props.isDisabled && 'none'};
  opacity: ${(props) => props.isDisabled && '0.3'};
`;

const FormRow = styled(Row)`
  margin-bottom: 20px;

  > * {
    width: 100%;
  }
`;

const FormButtonsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const FormFooter = styled(ModalFooter)`
  position: absolute;
  margin-top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
`;

const SelectedItem = styled(CentererVertical)`
  background: rgba(0, 117, 255, 0.1);
  font-size: 14px;
  line-height: 20px;
  color: var(--primaryBlue);
  border-radius: 8px;
  margin-right: 12px;
  padding: 8px 12px;
  margin-top: 12px;

  svg {
    margin-left: 8px;
    cursor: pointer;

    &:hover {
      g {
        opacity: 1;
      }
    }
  }
`;

const RecognitionText = styled.span`
  opacity: 0.5;
`;

const StyledModalBody = styled(ModalBody)`
  padding-bottom: 100px;
`;

const ProductsSection = styled(CentererVertical)`
  max-height: 400px;
  overflow-y: auto;
`;

const formatOptionLabel = (product) => (
  <>
    <b>{product.name}</b>
    <RecognitionText>{product.recognition ? ` (${product.recognition})` : ''}</RecognitionText>
  </>
);

export const CategoryActionsModal = ({ action, category, selectedProductsIds, onClose }) => {
  const [categoryProducts, setCategoryProducts] = useState({}); // map of { productId: true }
  const [createdCategory, setCreatedCategory] = useState();

  const { orgId } = useContext(AppContext);
  const {
    data,
    isLoading,
    operations: { updateCategory, createCategory },
  } = useProductsAPI({ orgId });

  useEffect(() => {
    if (category) {
      setCategoryProducts(
        category.subRows.reduce((acc, subRow) => {
          acc[subRow.id] = true;
          return acc;
        }, {}),
      );
    }
    if (selectedProductsIds) {
      setCategoryProducts(
        selectedProductsIds.reduce((acc, product) => {
          acc[product] = true;
          return acc;
        }, {}),
      );
    }
  }, [category, selectedProductsIds]);

  const handleSaveCategory = async (formValues) => {
    const requestBody = {
      name: formValues.category_name,
      productIds: Object.keys(categoryProducts).filter((key) => categoryProducts[key]),
    };

    if (action === 'create') {
      await createCategory.mutateAsync({ data: requestBody });
    } else {
      await updateCategory.mutateAsync({ data: requestBody, categoryId: category?.id });
    }

    onClose();
  };

  const availableProductOptions = (data?.products ?? [])
    .filter((product) => !categoryProducts[product.id])
    .map((product) => ({
      label: product.name,
      value: product.id,
      displayLabel: formatOptionLabel(product),
    }));

  const handleProductSelected = (selectedProduct) => {
    if (!selectedProduct) return;
    const selectedProductId = selectedProduct?.[0]?.value;
    if (selectedProductId && !categoryProducts[selectedProductId]) {
      setCategoryProducts((prevValue) => ({
        ...prevValue,
        [selectedProductId]: true,
      }));
    }
  };

  return (
    <ModalContainer>
      <Modal maxWidth="640px" height="auto" maxHeight="auto" data-cy="category-actions-modal">
        <ModalHeader data-cy="category-actions-modal__title">
          <ModalCloseIcon onClose={onClose} />
          <ModalTitle padding="0 0 20px 50px">
            <b>{action === 'create' ? 'Attribute' : 'Edit'}</b> Category
          </ModalTitle>
        </ModalHeader>
        {isLoading ? (
          <div className="w-100 flexer">
            <Loader containerStyles={{ width: 40, margin: 20 }} />
          </div>
        ) : (
          <>
            <Formik
              initialValues={
                category
                  ? {
                      category_name: (data?.productCategories ?? []).find(
                        (productCategory) => productCategory.id.toString() === category?.id.toString(),
                      )?.name,
                    }
                  : {
                      category_name: '',
                      product_ids: [],
                    }
              }
              validationSchema={
                action === 'edit'
                  ? Yup.object({
                      category_name: Yup.string().required('Please enter a name'),
                    })
                  : null
              }
              onSubmit={handleSaveCategory}
            >
              {({ values, setFieldValue }) => (
                <Form>
                  <StyledModalBody>
                    <FormRow>
                      {action === 'edit' ? (
                        <FormikCustomInput
                          name="category_name"
                          label="Name"
                          placeholder="Enter name..."
                          data-cy="category-actions-modal__name-input"
                        />
                      ) : (
                        <FormikCustomSelector
                          name="category_name"
                          label="Name"
                          placeholder="Enter name of category (or leave blank for no category)"
                          options={[
                            ...(data?.productCategories ?? []).map((productCategory) => ({
                              label: productCategory.name,
                              value: productCategory.name,
                            })),
                            ...(createdCategory && createdCategory === values.category_name
                              ? [
                                  {
                                    label: createdCategory,
                                    value: createdCategory,
                                  },
                                ]
                              : []),
                          ]}
                          creatable
                          onCreateOption={(name) => {
                            setFieldValue('category_name', name);
                            setCreatedCategory(name);
                          }}
                          customBorderRadius={4}
                          data-cy="category-actions-modal__name-input"
                        />
                      )}
                    </FormRow>
                    <FormikCustomSelector
                      width="100%"
                      minWidth={160}
                      placeholder="You can add multiple products..."
                      customBorderRadius={4}
                      name="product_ids"
                      label="Products"
                      options={availableProductOptions}
                      getOptionLabel={(option) => option.displayLabel}
                      value={null}
                      handleChange={handleProductSelected}
                      isMulti
                      isClearable
                    />
                    <ProductsSection wrapRow>
                      {categoryProducts &&
                        Object.keys(categoryProducts)
                          .filter((productId) => categoryProducts[productId])
                          .map((productId) => (
                            <SelectedItem key={productId}>
                              {(data?.products ?? []).find((prod) => prod.id === productId)?.name}
                              <TrashIcon
                                data-cy={`category-modal__remove-category-${productId}`}
                                onClick={() =>
                                  setCategoryProducts((prevValue) => ({
                                    ...prevValue,
                                    [productId]: false,
                                  }))
                                }
                              />
                            </SelectedItem>
                          ))}
                    </ProductsSection>
                    <FormFooter>
                      <FormButtonsRow>
                        <CancelButton onClick={onClose}>Cancel</CancelButton>
                        <SubmitButton
                          type="submit"
                          className="green"
                          data-cy="category-actions-modal__submit-button"
                          isDisabled={action === 'edit' && !values.category_name}
                        >
                          Save
                          <CheckIcon height="20px" style={{ marginLeft: '10px' }} />
                        </SubmitButton>
                      </FormButtonsRow>
                    </FormFooter>
                  </StyledModalBody>
                </Form>
              )}
            </Formik>
          </>
        )}
      </Modal>
    </ModalContainer>
  );
};

export const useCategoryActionsModal = () => {
  const [openParams, setOpenParams] = useState({});
  const [showModal, setShowModal] = useState(false);
  const openModal = useCallback(() => setShowModal(true), [setShowModal]);
  const closeModal = useCallback(() => setShowModal(false), [setShowModal]);

  const Modal = useCallback(() => (showModal ? <CategoryActionsModal onClose={closeModal} {...openParams} /> : <></>), [
    showModal,
    closeModal,
    openParams,
  ]);

  return {
    openModal: (openParams) => {
      setOpenParams(openParams);
      openModal();
    },
    CategoryActionsModal: Modal,
  };
};
