import React, { Fragment, useContext, useMemo } from 'react';
import dayjs from 'dayjs';
import { AppContext } from 'AppContext';
import styled from 'styled-components';
import { useTable } from 'react-table';
import { NUMBER_FORMATS } from 'consts/global';
import { numberFormatter } from 'utils/formatters';
import {
  ReactTableBody,
  ReactTableHeader,
  ReactTableHeaderColumn,
  ReactTableHeaderRow,
  ReactTableCell,
  ReactTableRow,
} from 'components/Table';
import { InvoiceStatusTag } from 'views/Billing/InvoicingTables/columns';
import { useInvoicingScheduleModal } from 'views/Billing/InvoicingScheduleModal';

var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

const TableContainer = styled.div`
  width: 100%;
`;

const InvoiceDate = styled.span`
  color: var(--primaryBlue);
  font-weight: 700;
  cursor: pointer;
`;

export const InvoicesTable = ({ invoiceItems, invoicingScheduleId, accountingCurrency }) => {
  const { formatDateWithLocale } = useContext(AppContext);

  const { Modal: InvoicingScheduleModal, openModal: openInvoicingScheduleModal } = useInvoicingScheduleModal();

  const dataForTable = useMemo(() => invoiceItems, [invoiceItems]);

  const columns = useMemo(
    () => [
      {
        Header: 'INVOICE',
        accessor: 'date',
        id: 'date',
        width: 150,
        Cell: ({ value, row }) => (
          <InvoiceDate
            onClick={() =>
              openInvoicingScheduleModal({
                invoicingSchedule: { id: invoicingScheduleId },
                invoice: { id: row.original.invoice_id },
              })
            }
          >
            {formatDateWithLocale(value)}
          </InvoiceDate>
        ),
      },
      {
        Header: 'INVOICE NUMBER',
        accessor: 'invoice_number',
        id: 'invoice_number',
        width: 200,
        Cell: ({ value }) => value ?? '-',
      },
      {
        Header: 'STATUS',
        accessor: 'invoice_status',
        id: 'invoice_status',
        width: 150,
        Cell: ({ value }) => <InvoiceStatusTag status={value}>{value}</InvoiceStatusTag>,
      },
      {
        Header: 'AMOUNT',
        accessor: 'amount',
        id: 'amount',
        width: 200,
        alignRight: true,
        Cell: ({ value }) =>
          numberFormatter({
            rawValue: value,
            type: NUMBER_FORMATS.CURRENCY,
            currency: accountingCurrency,
            decimalPlaces: 2,
          }),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formatDateWithLocale, accountingCurrency],
  );

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable({
    columns,
    data: dataForTable,
  });

  return (
    <Fragment>
      <TableContainer {...getTableProps()}>
        <ReactTableHeader backgroundColor="var(--primaryBlack6)" notSticky>
          {headerGroups.map((headerGroup) => (
            <ReactTableHeaderRow {...headerGroup.getHeaderGroupProps()} noSpaceBetween>
              {headerGroup.headers.map((column) => (
                <ReactTableHeaderColumn
                  {...column.getHeaderProps()}
                  customWidth={column.width}
                  alignRight={column.alignRight}
                >
                  {column.render('Header')}
                </ReactTableHeaderColumn>
              ))}
            </ReactTableHeaderRow>
          ))}
        </ReactTableHeader>
        {rows.length ? (
          <ReactTableBody {...getTableBodyProps()} noBorder>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <ReactTableRow
                  {...row.getRowProps()}
                  rowBackgroundColor="var(--primaryBlack2)"
                  ignoreChildStyles
                  noSpaceBetween
                >
                  {row.cells.map((cell) => (
                    <ReactTableCell
                      {...cell.getCellProps()}
                      customWidth={cell.column.width}
                      alignRight={cell.column.alignRight}
                    >
                      {cell.render('Cell')}
                    </ReactTableCell>
                  ))}
                </ReactTableRow>
              );
            })}
          </ReactTableBody>
        ) : null}
      </TableContainer>
      <InvoicingScheduleModal />
    </Fragment>
  );
};
