import axios from 'axios';
import { API_URL } from 'api/consts';

const buildUrl = ({ orgId }) => {
  const baseUrl = `${API_URL}/organizations/${orgId}/expenses`;
  return baseUrl;
};

export const getExpenses = async ({ orgId, month }) => {
  const { data } = await axios.get(buildUrl({ orgId }), { params: { month, type: 'manual' } });
  return data;
};

export const updateExpenses = async ({ orgId, month, expenses }) => {
  const { data } = await axios.put(buildUrl({ orgId }), { month, expenses });
  return data;
};
