import { assertCloseTo } from 'utils/assertionUtils';

export const checkTransactionFieldsMatch = ({ organization, user, data }) => {
  const { initialTransaction, transaction } = data;
  if (!data || !initialTransaction || !transaction) return;

  // verify total amount
  if (!!initialTransaction.amount && !!transaction.amount) {
    assertCloseTo({
      organization,
      user,
      context: 'TransactionSingle',
      expected: initialTransaction.amount,
      actual: transaction.amount,
      description: `Transaction total amount for transaction: ${transaction.name} (${transaction.id})`,
    });
  }

  // verify recurring amount
  if (!!initialTransaction.recurring_amount && !!transaction.recurring_amount) {
    assertCloseTo({
      organization,
      user,
      context: 'TransactionSingle',
      expected: parseFloat(initialTransaction.recurring_amount),
      actual: transaction.recurring_amount,
      description: `Transaction recurring_amount for transaction: ${transaction.name} (${transaction.id})`,
    });
  }
};
