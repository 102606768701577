import React from 'react';
import { TransactionBulkNavigator } from './TransactionBulkNavigator';
import { TransactionFormActions } from './TransactionFormActions';
import { ModalFooter } from './TransactionFooter.styles';

export const TransactionFooter = () => {
  return (
    <ModalFooter>
      <TransactionBulkNavigator />
      <TransactionFormActions />
    </ModalFooter>
  );
};
