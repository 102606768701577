import { useContext } from 'react';
import { AppContext } from 'AppContext';
import { useAccountingRevenueForCustomerAPI } from 'api/accountingRevenue/hooks';
import { Loader } from 'components/Loaders';
import { CustomerAccountingTable } from './CustomerAccountingTable';
import { Centerer } from 'components/Core';

export const CustomerAccounting = ({ customer }) => {
  const { organizations } = useContext(AppContext);

  const { data, isLoading } = useAccountingRevenueForCustomerAPI({
    orgId: organizations[0].id,
    customerId: customer.id,
  });

  if (isLoading)
    return (
      <Centerer width="100%" marginTop="32px">
        <Loader />
      </Centerer>
    );

  return <CustomerAccountingTable customerAccountingData={data} />;
};
