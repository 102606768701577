import styled from 'styled-components';
import { cssVar, transparentize } from 'polished';
import { ReactComponent as RelatedHelpIcon } from 'images/related-help.svg';

export const ReplaceTransactionWrapper = styled.div`
  background: ${transparentize(0.98, cssVar('--primaryBlack'))};
  border-radius: 10px;
  margin-bottom: 6px;
  padding: 12px;
`;

export const StyledRelatedHelpIcon = styled(RelatedHelpIcon)`
  position: relative;
  top: 4px;
  cursor: pointer;

  &:hover {
    g {
      opacity: 1;
    }
  }
`;

export const RelatedSelectorCustomOption = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  padding: 6px 10px;
  border-bottom: 1px solid var(--primaryBlack5);

  &:hover {
    .relatedName {
      color: var(--secondaryBlue);
    }

    .relatedAmount {
      opacity: 1;
    }
  }
`;

export const RelatedSelectorName = styled.div`
  font-weight: 900;
  font-size: 10px;
  line-height: 14px;
  text-transform: uppercase;
  color: var(--primaryBlue);
  margin-bottom: 4px;
`;

export const RelatedSelectorDates = styled.div`
  font-weight: 900;
  font-size: 9px;
  line-height: 12px;
  opacity: 0.5;
  text-transform: uppercase;
`;

export const RelatedSelectorInfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const RelatedSelectorAmount = styled.div`
  font-weight: 900;
  font-size: 10px;
  line-height: 14px;
  text-transform: uppercase;
  opacity: 0.5;
`;

export const ReplacingTransactionAdditionalInfo = styled.div`
  margin-top: 9px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    font-weight: 900;
    font-size: 9px;
    line-height: 12px;
    opacity: 0.5;
    text-transform: uppercase;
  }

  a {
    cursor: pointer;
    font-weight: 900;
    font-size: 10px;
    color: var(--primaryBlue);
    display: flex;
    align-items: center;

    svg {
      margin-left: 4px;
      position: relative;
      top: -2px;
    }

    &:hover {
      color: var(--secondaryBlue);

      svg {
        fill: var(--secondaryBlue);
      }
    }
  }
`;

export const relatedSelectStyles = {
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  control: (styles) => ({
    ...styles,
    height: 'auto',
    minHeight: 18,
    flexWrap: 'nowrap',
    boxShadow: '4px 4px 28px var(--primaryBlack5)',
    border: 'none',
  }),
  valueContainer: (styles) => ({
    ...styles,
    height: 'initial',
    minHeight: 20,
  }),
  multiValueLabel: (styles) => ({
    ...styles,
    maxWidth: 140,
  }),
  option: (styles, { isSelected, isFocused }) => ({
    ...styles,
    margin: 0,
    padding: 0,
    color: (isFocused || isSelected) && 'initial',
    backgroundColor: isFocused || isSelected ? 'var(--neutralBlue)' : 'white',
  }),
  placeholder: (styles) => ({
    ...styles,
    fontSize: 10,
    fontWeight: 900,
    opacity: 0.15,
    textTransform: 'uppercase',
  }),
  menuList: (styles) => ({
    ...styles,
    padding: 0,
    boxShadow: '15px 15px 60px rgba(0, 21, 46, 0.2)',
  }),
  dropdownIndicator: (styles) => ({ ...styles, color: 'var(--primaryBlack)' }),
  singleValue: (styles) => ({
    ...styles,
    fontSize: 10,
    textTransform: 'uppercase',
    fontWeight: 900,
  }),
};
