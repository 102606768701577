import React, { useContext, useMemo } from 'react';
import dayjs from 'dayjs';
import { AppContext } from 'AppContext';
import { SNAPSHOT_METRICS } from 'consts/snapshotMetrics';
import { useRecurringRevenueCachedMetrics } from 'utils/hooks';
import { DEFAULT_GRAPH_LABELS } from './consts';
import { calculateRevenueCard } from './DashboardRecurringRevenueCard.utils';
import { Card } from '../Common/Card';
import { DashboardContext } from '../DashboardContext';

const getCurrentValue = ({ revenueCard, isARR }) =>
  isARR ? (revenueCard?.currentValue ?? 0) * 12 : revenueCard?.currentValue ?? 0;

export const DashboardRecurringRevenueCard = (props) => {
  const {
    dataFilter: { endMonth },
  } = useContext(AppContext);
  const {
    loading,
    revenueNURDCStats,
    isARR,
    countInfluxAsRenewed,
    isCommitted,
    rollup,
    lastTransactionUpdatedAt,
    respondedAt,
  } = useContext(DashboardContext);

  const revenueCard = useMemo(() => calculateRevenueCard({ revenueStats: revenueNURDCStats }), [revenueNURDCStats]);

  useRecurringRevenueCachedMetrics({
    readyData: !loading && !countInfluxAsRenewed && revenueCard && 'currentValue' in revenueCard ? revenueCard : null,
    getValue: ({ readyData }) => getCurrentValue({ revenueCard: readyData, isARR }),
    context: 'Dashboard Recurring Revenue Card',
    isCommitted,
    rollup,
    month: dayjs(endMonth).date(15),
    lastTransactionUpdatedAt,
    readyDataRespondedAt: respondedAt,
  });

  const currentValue = getCurrentValue({ revenueCard, isARR });
  const startingValue = isARR ? (revenueCard?.startingValue ?? 0) * 12 : revenueCard?.startingValue ?? 0;

  return (
    <Card
      metricKey={SNAPSHOT_METRICS.RECURRING_REVENUE.key}
      loading={loading}
      currentValue={currentValue}
      startingValue={startingValue}
      graphData={revenueCard?.graphData || []}
      graphLabels={revenueCard?.graphLabels || DEFAULT_GRAPH_LABELS}
      {...props}
    />
  );
};
