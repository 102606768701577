import { useMutation, useQuery } from 'react-query';

import { useToasts } from 'components/Toasts';
import { getShareMetrics, sharePageWithEmail, sharePageWithSlack } from './requests';

export const CACHE_KEY = 'share';

export const useShareAPI = ({
  orgId,
  appSettings,
  pageToShare,
  metadataFilter,
  endMonth,
  startMonth,
  enableToasts = true,
  autoFetch = true,
}) => {
  const { pushToast: wrappedPushToast } = useToasts();
  const pushToast = (...args) => {
    if (enableToasts) wrappedPushToast(...args);
  };

  const dataKey = [CACHE_KEY, orgId, metadataFilter, endMonth, startMonth, appSettings];
  const { data, error, isLoading, isFetching } = useQuery(
    dataKey,
    () => getShareMetrics({ orgId, appSettings, metadataFilter, endMonth, startMonth }),
    {
      enabled: autoFetch,
    },
  );

  const shareEmail = useMutation(
    ({ metrics, snapshotMetrics, manuallyAddedEmails, sendPreviewEmailTo, chartImage, reportId }) =>
      sharePageWithEmail({
        orgId,
        manuallyAddedEmails,
        chartImage,
        sendPreviewEmailTo,
        metrics,
        snapshotMetrics,
        pageToShare,
        appSettings,
        endMonth,
        startMonth,
        metadataFilter,
        reportId,
      }),
    {
      onMutate: () => {},

      onError: (err, variables) => {
        pushToast(
          variables?.sendPreviewEmailTo
            ? 'Failed to send preview to you by email'
            : `Failed share page with email. ${err}`,
          'error',
        );
      },

      onSuccess: (_, variables) => {
        pushToast(
          variables?.sendPreviewEmailTo ? 'The preview was sent to you by email' : 'Successfully shared page!',
          'success',
        );
      },
    },
  );

  const shareSlack = useMutation(
    ({ metrics, reportId }) =>
      sharePageWithSlack({
        orgId,
        metrics,
        pageToShare,
        appSettings,
        endMonth,
        startMonth,
        metadataFilter,
        reportId,
      }),
    {
      onMutate: () => {},

      onError: (err) => {
        pushToast(`Failed share page with slack. ${err}`, 'error');
      },

      onSuccess: (_, variables) => {
        pushToast(
          variables?.sendPreviewEmailTo ? 'The preview was sent to you by email' : 'Successfully shared page!',
          'success',
        );
      },
    },
  );

  return {
    data,
    error,
    isLoading,
    isFetching,
    shareEmail: shareEmail.mutateAsync,
    shareSlack: shareSlack.mutateAsync,
  };
};
