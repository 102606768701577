import { useCallback, useState } from 'react';
import { InfoBar } from 'components/Blocks';
import { FlexBetweenContainer, Row } from 'components/Core';
import { FieldBody, FieldTitle } from 'views/Wizard/styles';
import { COLORS } from 'consts/colors';
import { EditCircleIcon } from 'components/Icons';
import { IconButton } from 'components/Buttons';
import { ReactComponent as CheckWhite } from 'images/check-white.svg';
import AccountsSelect from './AccountsSelect';

const TabBody = ({ accounts, selectedAccounts, setFieldValue }) => {
  const [editable, setEditable] = useState(false);

  const handleSalesAccountsSelection = useCallback(
    async (accounts) => {
      setFieldValue('metadata.sales', accounts);
    },
    [setFieldValue],
  );

  const handleMarketingAccountsSelection = useCallback(
    async (accounts) => {
      setFieldValue('metadata.marketing', accounts);
    },
    [setFieldValue],
  );

  const handleCOGSAccountsSelection = useCallback(
    async (accounts) => {
      setFieldValue('metadata.cogs', accounts);
    },
    [setFieldValue],
  );

  return (
    <FieldBody>
      <FieldTitle>
        <FlexBetweenContainer alignItems="center" width="100%">
          <span style={{ fontWeight: 700 }}>
            Pick one or more sales, marketing, and COGS accounts and percentage allocation to the category
          </span>

          <IconButton
            filled
            border
            color={COLORS.GREEN}
            icon={editable ? <CheckWhite /> : <EditCircleIcon />}
            iconFillColor="#FFF"
            onClick={() => setEditable(!editable)}
          >
            {editable ? 'Finish Edit' : 'Edit Configuration'}
          </IconButton>
        </FlexBetweenContainer>
      </FieldTitle>

      <InfoBar>
        <div style={{ width: '90%' }}>
          <span style={{ fontSize: '12px', fontWeight: 700, lineHeight: '16px' }}>
            Sometimes an account is only part of something
          </span>
          <p style={{ fontSize: '12px', lineHeight: '16px', paddingTop: '10px' }}>
            For example, we may pay for a customer support team that helps customers who are thinking of buying half the
            time (marketing) and those who have already bought the other half of the time (cost of service). So,
            customer support in that case would be 50% marketing.
          </p>
        </div>
      </InfoBar>
      <Row horizontal="start" style={{ alignItems: 'normal' }} className="flexer mt-2 mb-2">
        <AccountsSelect
          editable={editable}
          title="Sales"
          onChange={handleSalesAccountsSelection}
          selectedAccounts={selectedAccounts.sales}
          accounts={accounts}
        />
        <AccountsSelect
          editable={editable}
          title="Marketing"
          onChange={handleMarketingAccountsSelection}
          selectedAccounts={selectedAccounts.marketing}
          accounts={accounts}
        />
        <AccountsSelect
          editable={editable}
          title="COGS"
          onChange={handleCOGSAccountsSelection}
          selectedAccounts={selectedAccounts.cogs}
          accounts={accounts}
        />
      </Row>
    </FieldBody>
  );
};

export default TabBody;
