import React, { useState, useEffect, useContext } from 'react';
import { AppContext } from 'AppContext';
import { COLORS } from 'consts/colors';
import { INTEGRATION_SERVICES } from 'consts/integrations';
import { useTransactionsAPI } from 'api/transactions';
import { useTransactionAPI } from 'api/transactions/hooks';
import { useExternalUpdatesAPI } from 'api/externalUpdates';
import { EXTERNAL_UPDATE_ACTION_OPTIONS, EXTERNAL_UPDATE_TYPES } from 'views/ExternalUpdates/consts';
import { useConfirmResyncModal } from 'views/ExternalUpdates/Components/ConfirmResyncModal';
import { Loader } from 'components/Loaders';
import { Button, DropdownButton, DropdownButtonItem } from 'components/Buttons';
import { EXTERNAL_UPDATES_SUPPORTED_RESOURCES } from 'views/ExternalUpdates/consts';
import { SyncIcon, LinkIcon, ItemText, ManualCreatedText } from './styles';

const INTEGRATIONS_WITH_URL = [
  INTEGRATION_SERVICES.HUBSPOT,
  INTEGRATION_SERVICES.SALESFORCE,
  INTEGRATION_SERVICES.MAXIO,
  INTEGRATION_SERVICES.STRIPE,
];

const INTEGRATIONS_WITH_SYNC = [
  INTEGRATION_SERVICES.HUBSPOT,
  INTEGRATION_SERVICES.SALESFORCE,
  INTEGRATION_SERVICES.MAXIO,
];

export const TransactionDataActions = ({
  transactionId,
  onTransactionFieldsRefresh,
  currentTransaction,
  setCurrentTransaction,
  setLoading,
}) => {
  const { organizations, integrations } = useContext(AppContext);
  const [dealUrl, setDealUrl] = useState('#');
  const orgId = organizations[0].id;
  const [integration, setIntegration] = useState();

  const {
    operations: { refreshTransactionFields },
  } = useTransactionsAPI({ orgId });

  const {
    operations: { resolveExternalUpdate },
  } = useExternalUpdatesAPI({
    orgId,
    params: { page: 1, limit: 10 },
    objectType: EXTERNAL_UPDATES_SUPPORTED_RESOURCES.TRANSACTION,
  });

  const { isLoading: isTransactionLoading, data: transaction } = useTransactionAPI({
    orgId,
    transactionId,
    params: { includeDealUrl: true },
  });

  const resyncTypeUpdate = currentTransaction?.externalUpdates?.find(
    (externalUpdate) => externalUpdate.type === EXTERNAL_UPDATE_TYPES.resync,
  );

  const handleResyncSubmitted = async () => {
    setLoading(true);
    const result = await resolveExternalUpdate.mutateAsync({
      id: resyncTypeUpdate.id,
      data: { actionType: EXTERNAL_UPDATE_ACTION_OPTIONS.overwrite, includeResyncedTransactions: true },
    });
    setLoading(false);
    if (result && result.createdTransactions?.length) {
      setCurrentTransaction(result.createdTransactions[0]);
    }
  };

  const { openConfirmResyncModal, ConfirmResyncModal } = useConfirmResyncModal({
    data: {
      targetObject: currentTransaction,
      externalUpdate: currentTransaction?.externalUpdates?.[0],
      actionType: EXTERNAL_UPDATE_ACTION_OPTIONS.overwrite,
    },
    onConfirm: handleResyncSubmitted,
  });

  const refreshTransaction = async () => {
    try {
      await refreshTransactionFields.mutateAsync(
        {
          transactionId,
          fields: [
            'name',
            'date',
            'amount',
            'recurring_amount',
            'seats',
            'start_date',
            'end_date',
            'customer_id',
            'metadata',
          ],
        },
        {
          onSuccess: onTransactionFieldsRefresh,
        },
      );
    } catch (err) {
      console.error({ message: err.message, component: 'TransactionDataActions.js', stack: err });
    }
  };

  useEffect(() => {
    if (transaction) {
      setDealUrl(transaction?.dealUrl);
      setIntegration((integrations || []).find((i) => i.service === transaction?.service));
    }
  }, [integrations, transaction, setDealUrl, setIntegration]);

  if (refreshTransactionFields.isLoading) {
    return (
      <Button color={COLORS.BLUE} filled active border>
        Refreshing...
      </Button>
    );
  }

  const hasUrl = INTEGRATIONS_WITH_URL.some((service) => (integration?.service || '').includes(service));
  const hasSync = INTEGRATIONS_WITH_SYNC.some((service) => (integration?.service || '').includes(service));

  return (
    <>
      <DropdownButton
        text="Source"
        color={COLORS.BLUE}
        width={hasUrl ? '200px' : '275px'}
        border
        data-cy="transaction-data-actions-button"
      >
        {hasUrl || hasSync ? (
          <>
            {hasSync && (
              <DropdownButtonItem
                onClick={resyncTypeUpdate ? openConfirmResyncModal : refreshTransaction}
                name="transaction-data-actions-button__resync"
              >
                <SyncIcon />
                <ItemText>Sync from {integration.service}</ItemText>
              </DropdownButtonItem>
            )}

            {hasUrl && (
              <DropdownButtonItem onClick={() => window.open(dealUrl, '_blank')}>
                <LinkIcon />
                <ItemText>View on {integration.service}</ItemText>
              </DropdownButtonItem>
            )}
          </>
        ) : (
          <>
            {isTransactionLoading ? (
              <Loader containerStyles={{ width: 50 }} />
            ) : (
              <ManualCreatedText>
                This transaction was created manually
                <br /> in Subscript and does not have an external source
              </ManualCreatedText>
            )}
          </>
        )}
      </DropdownButton>
      <ConfirmResyncModal />
    </>
  );
};
