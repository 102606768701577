import styled, { css } from 'styled-components';
import { ReactComponent as RefreshIcon } from 'images/refresh.svg';
import { Centerer, Flexer } from 'components/Core';
import { Popover, PopoverItemsContainer, PopoverWrapper } from 'components/Portal';

export const WhiteButtonScheduleHeader = styled.div`
  padding: ${({ iconVersion }) => (iconVersion ? '10px 8px' : '12px 10.2px')};
  background: ${({ green }) => (green ? '#14AE65' : 'white')};
  color: ${({ green }) => green && 'white'};
  box-shadow: 2px 2px 12px var(--primaryBlack2);
  border-radius: ${({ iconVersion }) => (iconVersion ? '0px 8px 8px 0px' : '8px')};
  opacity: ${({ disabled }) => disabled && '0.3'};
  pointer-events: ${({ disabled }) => disabled && 'none'};
  font-weight: 700;
  font-size: 12px;
  justify-content: center;
  white-space: nowrap;
  width: ${({ iconVersion }) => (iconVersion ? 'initial' : '100%')};
  line-height: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;

  svg {
    margin-right: 8px;
  }
`;

export const TabsContainer = styled(Flexer)`
  position: relative;
  width: 100%;
  border-radius: 8px;
  border: 1px solid var(--accentGraySecond);
  background: white;
  padding: 4px;
  box-shadow: 2px 2px 12px 0px var(--primaryBlack2);
  gap: 4px;
`;

export const TabCounter = styled(Flexer)`
  width: 16px;
  height: 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  font-size: 9px;
  font-style: normal;
  font-weight: 900;
  line-height: 12px;
  text-transform: uppercase;
`;

export const Tab = styled(Centerer)`
  padding: 8px;
  gap: 8px;
  flex-grow: 1;
  min-height: 32px;
  border-radius: 8px;
  border-radius: inherit;
  opacity: ${({ disabled }) => disabled && '0.3'};

  &:hover {
    background-color: ${({ disabled }) => !disabled && 'var(--primaryBlue5)'};
    cursor: ${({ disabled }) => !disabled && 'pointer'};
  }

  ${({ isSelected }) =>
    isSelected &&
    css`
      background-color: var(--primaryBlue10);
      &:hover {
        background-color: var(--primaryBlue10);
      }
    `}

  ${TabCounter} {
    background-color: ${({ isSelected }) => (isSelected ? 'var(--primaryBlue)' : 'var(--primaryBlue10)')};
    color: ${({ isSelected }) => (isSelected ? 'white' : 'var(--primaryBlue)')};
  }
`;

export const TabText = styled.div`
  font-size: 12px;
  font-style: italic;
  font-weight: 700;
  line-height: 16px;
  color: ${({ highlight }) => (highlight ? 'var(--primaryBlue)' : 'var(--primaryBlack)')};
`;

export const ErrorContainer = styled.ul`
  background: var(--primaryRed10);
  border: 1px solid var(--primaryRed10);
  border-radius: 8px;
  color: var(--primaryRed);
  display: flex;
  flex-direction: column;
  font-size: 12px;
  font-style: italic;
  font-weight: 700;
  justify-content: space-between;
  gap: 8px;
  padding: 12px 20px;

  > li {
    font-style: normal;
    line-height: 16px;
    list-style: inside circle;
  }
`;

export const LineItemErrors = styled.ul`
  padding-left: 16px;

  > li {
    font-weight: 400;
    list-style: inside square;
  }
`;

export const StyledPopoverWrapper = styled(PopoverWrapper)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledPopover = styled(Popover)`
  width: ${({ width }) => width ?? '260px'};
  padding: 8px;
  background-color: var(--primaryGray);
  border-color: var(--neutralGray);
  gap: 8px;
`;

export const StyledRefreshIcon = styled(RefreshIcon)`
  margin-right: 0 !important;
  width: 20px;
  height: 20px;

  path {
    fill: var(--primaryBlack50);
  }
`;

export const PopoverItemsSection = styled(PopoverItemsContainer)`
  background-color: white;
  border: 1px solid var(--accentGraySecond);
  border-radius: 12px;
`;

export const FrequencyOption = styled(Centerer)`
  width: 100%;

  div {
    flex: 1;
  }
`;

export const ConfirmActionTitle = styled.div`
  border-radius: 4px;
  border: 1px solid var(--neutralGray);
  color: var(--primaryBlack50);
  padding: 4px;
  font-size: 9px;
  width: fit-content;
  font-weight: 900;
`;

export const ConfirmationText = styled.span`
  font-style: ${({ fontStyle }) => fontStyle ?? 'auto'};
  font-size: 12px;
  font-weight: ${({ fontWeight }) => fontWeight ?? 400};
  color: ${({ subText }) => (subText ? 'var(--primaryBlack50)' : 'auto')};
  background-color: ${({ highlight }) => (highlight ? 'var(--primaryBlack10)' : 'transparent')};
  display: inline-block;
  text-align: center;
  padding: 2px;
  border-radius: 4px;
  white-space: pre-wrap;
`;

export const ConfirmationPopoverButton = styled(Flexer)`
  border-radius: 100px;
  border: 1px solid var(--primaryBlack5);
  padding: 8px 12px;
  background-color: ${({ isConfirmButton }) => (isConfirmButton ? 'var(--darkGreen)' : 'var(--neutralGray)')};
  color: ${({ isConfirmButton }) => (isConfirmButton ? 'white' : 'var(--primaryBlack)')};
  font-weight: 700;
  width: 100%;
  font-size: 12px;
  min-width: 90px;
  justify-content: center;

  &:hover {
    cursor: pointer;
  }
`;
