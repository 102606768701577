import { useState, useEffect } from 'react';

export const useStateWithStorage = (key, initialValue, dontUseStorageValue = false, { setStorageValue } = {}) => {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    try {
      // Get from local storage by key
      const item = localStorage.getItem(key);
      // Parse stored json or if none return initialValue

      return setStorageValue ? setStorageValue(item) : item && !dontUseStorageValue ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      console.log(error);
      return initialValue;
    }
  });

  useEffect(() => {
    if (storedValue !== null) {
      localStorage.setItem(key, JSON.stringify(storedValue));
    } else {
      localStorage.removeItem(key);
    }
  }, [key, storedValue]);

  return [storedValue, setStoredValue];
};
