import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { AppContext } from 'AppContext';
import { CsvUpload } from 'shared/CsvUpload';
import { useCSVImportsAPI } from 'api/csvImports';

import { DEFAULT_PRODUCTS_MAPPER } from './consts';
import { getSchema } from './schema';
import { getColumns } from './columns';

export const ProductsCsvUpload = () => {
  const { orgId } = useContext(AppContext);

  const [submittingForm, setSubmittingForm] = useState(false);

  const history = useHistory();

  const {
    operations: { uploadCSV },
  } = useCSVImportsAPI({ orgId, autoFetch: false });

  const handleSubmit = async ({ values, extraData }) => {
    setSubmittingForm(true);

    await uploadCSV.mutateAsync({
      rawFile: extraData,
      data: values,
      settings: { entity: extraData.entity },
    });

    setTimeout(() => {
      history.push('/configure?tab=products');

      setSubmittingForm(false);
    }, 1000);
  };

  return (
    <CsvUpload
      onlyCreateMode={true}
      backLink="/configure?tab=products"
      entityName="products"
      isLoading={submittingForm}
      createSchema={getSchema()}
      handleSubmit={handleSubmit}
      defaultMapperWithCreateOrUpdateMode={() => DEFAULT_PRODUCTS_MAPPER}
      getColumnsWithCsvUploadState={getColumns}
    />
  );
};
