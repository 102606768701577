import { RECOGNITION_TYPES } from 'consts/global';
import { updateDateFromDatePicker } from 'utils/dateUtils';
import { calculateTotalAmount, calculateRecurringAmount } from 'models/transaction';
import { calculateIncludeEndMonth } from './TransactionRecognitionsSchedule/utils';

const setAdditionalAmountValue = ({
  setFieldValue,
  recognition,
  recurringAmount,
  totalAmount,
  startDate,
  endDate,
  includeEndMonth,
}) => {
  switch (recognition) {
    case RECOGNITION_TYPES.linear:
      //setting recurring amount
      if (totalAmount && startDate && endDate) {
        setFieldValue(
          'recurring_amount',
          calculateRecurringAmount({
            startDate,
            endDate,
            totalAmount,
            includeEndMonth,
          }),
        );
      } else {
        setFieldValue('recurring_amount', 0);
      }
      break;
    case RECOGNITION_TYPES.tillCanceled:
      //setting total amount
      if (recurringAmount && startDate) {
        setFieldValue(
          'amount',
          calculateTotalAmount({
            startDate,
            endDate,
            recurringAmount,
            includeEndMonth,
          }),
        );
      } else {
        setFieldValue('amount', 0);
      }
      break;
    case RECOGNITION_TYPES.eventRecurring:
      // do nothing since we want to allow them to set this in the UI
      break;
    default:
      //for any other recognition type, the recurring amount is 0
      setFieldValue('recurring_amount', 0);
  }
};

export const recognitionHandleChange = (setFieldValue, values) => (recognition) => {
  setFieldValue('recognition', recognition);
  setAdditionalAmountValue({
    setFieldValue,
    recognition,
    recurringAmount: values.recurring_amount,
    totalAmount: values.amount,
    startDate: values.start_date,
    endDate: values.end_date,
    includeEndMonth: values.include_end_month,
  });
};

export const recurringAmountHandleChange = (setFieldValue, values) => (value) => {
  setFieldValue('recurring_amount', value);
  if (values.recognition === RECOGNITION_TYPES.tillCanceled)
    setAdditionalAmountValue({
      setFieldValue,
      recognition: values.recognition,
      recurringAmount: value,
      startDate: values.start_date,
      endDate: values.end_date,
      includeEndMonth: values.include_end_month,
    });
};

export const includeLastMonthChange = (setFieldValue, values) => (value) => {
  setFieldValue('include_end_month', value);
  setAdditionalAmountValue({
    setFieldValue,
    recognition: values.recognition,
    totalAmount: values.amount,
    recurringAmount: values.recurring_amount,
    startDate: values.start_date,
    endDate: values.end_date,
    includeEndMonth: value,
  });
};

export const totalAmountHandleChange = (setFieldValue, values) => (value) => {
  setFieldValue('amount', value);
  setAdditionalAmountValue({
    setFieldValue,
    recognition: values.recognition,
    totalAmount: value,
    startDate: values.start_date,
    endDate: values.end_date,
    includeEndMonth: values.include_end_month,
  });
};

export const startDateHandleChange = (setFieldValue, values) => (name, date) => {
  const startDate = updateDateFromDatePicker(date);
  const endDate = updateDateFromDatePicker(values.end_date);
  setFieldValue(name, startDate);
  const includeEndMonthForCalculation = calculateIncludeEndMonth({
    recognition: values.recognition,
    startDate,
    endDate,
  });
  setAdditionalAmountValue({
    setFieldValue,
    recognition: values.recognition,
    totalAmount: values.amount,
    recurringAmount: values.recurring_amount,
    startDate,
    endDate,
    includeEndMonth: includeEndMonthForCalculation,
  });
};

export const endDateHandleChange = (setFieldValue, values) => (name, date) => {
  const startDate = updateDateFromDatePicker(values.start_date);
  const endDate = updateDateFromDatePicker(date);
  setFieldValue(name, endDate);
  const includeEndMonthForCalculation = calculateIncludeEndMonth({
    recognition: values.recognition,
    startDate,
    endDate,
  });
  setAdditionalAmountValue({
    setFieldValue,
    recognition: values.recognition,
    totalAmount: values.amount,
    recurringAmount: values.recurring_amount,
    startDate,
    endDate: endDate,
    includeEndMonth: includeEndMonthForCalculation,
  });
};
