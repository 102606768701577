import { useContext, useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';

import { SNAPSHOT_METRIC_BY_KEY } from 'consts/snapshotMetrics';
import { partition } from 'utils/arrayUtils';
import { Loader } from 'components/Loaders';
import { GoalsContext } from '../GoalsContext';
import { getGoalsInsideFiltersPeriod } from '../utils';
import { NewGoalCard, StyledCirclePlus, WrapperCard } from './styles';
import { EmptyBanner } from './EmptyBanner';
import { PaginationSection } from './GoalsPaginationSection';
import { GoalCard } from './GoalCard';

export const GoalsBanner = ({ revenueData, dataFilter, metricKey }) => {
  const { rawGoals, isLoading, openGoalModal } = useContext(GoalsContext);
  const [selectedPage, setSelectedPage] = useState(0);

  const startDate = dayjs(dataFilter.startMonth).startOf('month');
  const goalsMetrics = Object.keys(SNAPSHOT_METRIC_BY_KEY);
  const goalsInsideFiltersPeriod = useMemo(
    () =>
      getGoalsInsideFiltersPeriod({
        goalsMetrics,
        rawGoals,
        metricKey,
        startDate,
      }),
    [rawGoals, goalsMetrics, startDate, metricKey],
  );

  useEffect(() => {
    if (goalsInsideFiltersPeriod.length <= 3) {
      setSelectedPage(0);
    }
  }, [goalsInsideFiltersPeriod]);

  const partitionedGoals = useMemo(() => partition(goalsInsideFiltersPeriod, 3), [goalsInsideFiltersPeriod]);

  const handleClickBanner = () => openGoalModal({ metricKey });

  if (goalsInsideFiltersPeriod.length === 0) {
    return <EmptyBanner onClick={handleClickBanner} />;
  }

  return (
    <>
      <WrapperCard>
        {!isLoading &&
          partitionedGoals[selectedPage]?.map((goal, index) => (
            <GoalCard key={goal.id} index={index} goal={goal} revenueData={revenueData} editMode={!!metricKey} />
          ))}
        {isLoading && (
          <div className="w-100 flexer">
            <Loader containerStyles={{ width: 40, margin: 40 }} />
          </div>
        )}
        <NewGoalCard onClick={handleClickBanner}>
          <StyledCirclePlus />
          <div>Start tracking a new goal</div>
        </NewGoalCard>
      </WrapperCard>
      {goalsInsideFiltersPeriod.length > 3 && (
        <PaginationSection
          goalsCount={goalsInsideFiltersPeriod.length}
          selectedPage={selectedPage}
          setSelectedPage={setSelectedPage}
        />
      )}
    </>
  );
};
