import React, { Fragment, useState, useEffect } from 'react';

import { useUrlQuery } from 'utils/hooks';
import { Spacer } from 'components/Core';
import { BillingProvider } from 'views/Billing/BillingProvider';

import { CONTRACT_STATUS } from './consts';
import { ContractsHeader } from './ContractsHeader';
import { TabSelector } from './Tabs/TabSelector';
import { ContractsTableContainer } from './ContractsTable';
import { ContractsUploader } from './ContractsUploader';

export const ContractsPdfsContainer = () => {
  const query = useUrlQuery();
  const currentTab = query.get('tab');

  const [tab, setTab] = useState(CONTRACT_STATUS.PROCESSING);

  useEffect(() => {
    currentTab && setTab(currentTab);
  }, [currentTab]);

  return (
    <Fragment>
      <BillingProvider>
        <ContractsHeader activeTab={tab} />
        <ContractsUploader />
        <Spacer height="10px" />
        <TabSelector activeTab={tab} setTab={setTab} />
        <ContractsTableContainer activeTab={tab} />
      </BillingProvider>
    </Fragment>
  );
};
