import { useConfirmModal } from 'shared/ConfirmModal';

export const useReingestCustomerModal = ({ onShouldReingest, integrationDisplayName }) => {
  const { openConfirmModal, ConfirmModal } = useConfirmModal({
    width: '470px',
    title: 'Sync Customer?',
    content: `Do you want to sync this customer's details (ex. email, address) over from ${integrationDisplayName} into Subscript?`,
    denyButtonText: 'No (just link customer)',
    confirmButtonText: 'Yes (recommended)',
    onConfirm: () => onShouldReingest(true),
    onDeny: () => onShouldReingest(false),
  });

  return {
    openReingestCustomerModal: openConfirmModal,
    ReingestCustomerModal: ConfirmModal,
  };
};
