import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { getForecastedExistingCustomersTableData } from '../ForecastedExistingModal/ForecastedExistingCustomersTable.utils';
import { EXISTING_CUSTOMERS_TABLE_TYPE } from '../ForecastedExistingModal/consts';
import { FORECAST_DETAILS_FIELD } from '../consts';

dayjs.extend(utc);

export const BUCKET_SECTIONS = {
  NEW_PIPELINE_TO_BE_CLOSED: 'new_pipeline_to_be_closed',
  NEW: 'new',
  RENEWAL: 'renewal',
  CHURN: 'churn',
  UPSELL: 'upsell',
  UNDER_CONTRACT: 'under_contract',
  FORECASTED_REVENUE_FROM_PREVIOUS_MONTHS: 'forecasted_revenue_from_previous_months',
};

export const BUCKET_SECTION_TO_FORECAST_DETAILS_FIELD = {
  [BUCKET_SECTIONS.NEW]: FORECAST_DETAILS_FIELD.NEW,
  [BUCKET_SECTIONS.RENEWAL]: FORECAST_DETAILS_FIELD.EXISTING,
};

// for reusing getForecastedExistingCustomersTableData
const BUCKET_SECTIONS_TO_EXISTING_CUSTOMERS_TABLE_TYPE = {
  [BUCKET_SECTIONS.UNDER_CONTRACT]: EXISTING_CUSTOMERS_TABLE_TYPE.UNDER_CONTRACT,
  [BUCKET_SECTIONS.CHURN]: EXISTING_CUSTOMERS_TABLE_TYPE.UP_FOR_RENEWAL,
  [BUCKET_SECTIONS.RENEWAL]: EXISTING_CUSTOMERS_TABLE_TYPE.PIPELINE_RENEWAL,
  [BUCKET_SECTIONS.UPSELL]: EXISTING_CUSTOMERS_TABLE_TYPE.PIPELINE_UPSELL,
};

export const groupDataByBucket = ({ data, month }) => {
  const orderedBuckets = Object.values(data.data.pipeline_unweighted_buckets).reduce((acc, value) => {
    acc[value.order] = value.name;
    return acc;
  }, Array.from({ length: 3 }));

  const buckets = Object.entries(data.data.pipeline_unweighted_buckets).reduce((acc, [key, values]) => {
    acc[key] = Object.values(BUCKET_SECTIONS).reduce(
      (acc, section) => {
        section === BUCKET_SECTIONS.NEW_PIPELINE_TO_BE_CLOSED
          ? (acc[BUCKET_SECTIONS.NEW_PIPELINE_TO_BE_CLOSED] = data?.[FORECAST_DETAILS_FIELD.NEW]?.new_pipeline[key])
          : section === BUCKET_SECTIONS.FORECASTED_REVENUE_FROM_PREVIOUS_MONTHS
          ? (acc[section] = {})
          : (acc[section] = []);
        return acc;
      },
      {
        name: values.name,
        order: values.order,
        previousBucket: orderedBuckets[values.order - 1] ?? null,
      },
    );
    return acc;
  }, {});

  for (const [bucketSection, existingCustomerTableType] of Object.entries(
    BUCKET_SECTIONS_TO_EXISTING_CUSTOMERS_TABLE_TYPE,
  )) {
    const filteredData = getForecastedExistingCustomersTableData({
      customersData: data[FORECAST_DETAILS_FIELD.EXISTING].customers,
      existingForecastType: data.data.existing_customers_forecast_type,
      tableType: existingCustomerTableType,
      month,
    });

    for (const customer of filteredData) {
      if (
        bucketSection !== BUCKET_SECTIONS.RENEWAL ||
        (bucketSection === BUCKET_SECTIONS.RENEWAL &&
          dayjs.utc(customer.pipeline_closed_date).format('YYYY-MM') === month)
      ) {
        buckets[customer.bucket][bucketSection].push(customer);
      }
    }
  }

  for (const [bucket, values] of Object.entries(data[FORECAST_DETAILS_FIELD.EXISTING].previous_months)) {
    buckets[bucket][BUCKET_SECTIONS.FORECASTED_REVENUE_FROM_PREVIOUS_MONTHS] = values;
  }

  for (const newCustomer of data?.[FORECAST_DETAILS_FIELD.NEW]?.customers) {
    if (dayjs.utc(newCustomer.forecast_month).format('YYYY-MM') === month || newCustomer.forecast_month === null) {
      buckets[newCustomer.bucket][BUCKET_SECTIONS.NEW].push(newCustomer);
    }
  }

  return buckets;
};
