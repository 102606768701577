import { useContext } from 'react';

import { ReactComponent as NextTransactionIcon } from 'images/bulk-transactions-next-icon.svg';
import { FlexerStart } from 'views/Dashboard/Common/styled';
import { Centerer, Spacer } from 'components/Core';
import { GreyDot, RechargeIcon } from 'components/Icons';

import { Tooltip } from 'components/Tooltip_NEW';

import { getDifferingFields } from 'models/externalUpdates';
import {
  BulkTransactionsControls,
  StyledPrevTransactionIcon,
  BulkTransactionsCounter,
  StyledNextTransactionIcon,
  TotalBulkCount,
  NavigatorText,
} from './TransactionBulkNavigator.styles';
import { TRANSACTION_MODAL_MODE } from '../consts';
import { TransactionContext } from '../TransactionContext';

// TODO [TC 2021-11-25]: Rename this, since it doesn't just handle bulk navigation anymore
export const TransactionBulkNavigator = () => {
  const { mode, currentBulkIndex, setCurrentBulkIndex, transactionsBulk, changedDataFormatted } = useContext(
    TransactionContext,
  );

  switch (mode) {
    case TRANSACTION_MODAL_MODE.EDIT_BULK:
      return (
        <FlexerStart>
          <NavigatorText>Bulk Confirmation Process</NavigatorText>
          <GreyDot style={{ marginLeft: 20, marginRight: 20 }} />
          <BulkTransactionsControls>
            <StyledPrevTransactionIcon
              unactive={currentBulkIndex === 0}
              data-cy="transaction-modal--bulk__bulk_navigator__prev"
              onClick={() => setCurrentBulkIndex(currentBulkIndex - 1)}
            >
              <NextTransactionIcon width={30} height={30} />
            </StyledPrevTransactionIcon>
            <BulkTransactionsCounter>
              <span data-cy="transaction-modal--bulk__bulk-navigator__bulk-index">{currentBulkIndex + 1}</span>
              <TotalBulkCount>
                {' '}
                / <span data-cy="transaction-modal--bulk__bulk-navigator__bulk-total">{transactionsBulk.length}</span>
              </TotalBulkCount>
            </BulkTransactionsCounter>

            <StyledNextTransactionIcon
              data-cy="transaction-modal--bulk__bulk_navigator__next"
              unactive={currentBulkIndex === transactionsBulk.length - 1}
              onClick={() => setCurrentBulkIndex(currentBulkIndex + 1)}
            >
              <NextTransactionIcon width={30} height={30} />
            </StyledNextTransactionIcon>
          </BulkTransactionsControls>
        </FlexerStart>
      );
    case TRANSACTION_MODAL_MODE.EXTERNAL_UPDATE:
      return (
        <FlexerStart>
          <NavigatorText>Information about transaction</NavigatorText>
          <GreyDot style={{ marginLeft: 20, marginRight: 20 }} />

          <Tooltip overlay={<>{getDifferingFields({ data: changedDataFormatted }).join(', ')}</>} placement="top">
            <Centerer>
              <RechargeIcon fill="var(--primaryOrange)" />
              <Spacer width="10px" />
              What was updated?
            </Centerer>
          </Tooltip>
        </FlexerStart>
      );
    default:
      return null;
  }
};
