import { useContext } from 'react';
import { useQuery } from 'react-query';
import { AppContext } from 'AppContext';
import { getCustomerTimeseriesMetricsKeys } from 'api';

export const useCustomerTimeseriesMetrics = ({ autoFetch = true } = {}) => {
  const { organizations } = useContext(AppContext);
  const hasCustomerTimeSeriesMetrics = organizations[0]?.hasCustomerTimeSeriesMetrics;

  const { data: customerTimeSeriesMetricsData, isLoading: isCustomerTimeSeriesMetricsLoading } = useQuery(
    ['getCustomerTimeSeriesMetrics', organizations[0]?.id],
    () => getCustomerTimeseriesMetricsKeys({ orgId: organizations[0]?.id }),
    {
      enabled: autoFetch && hasCustomerTimeSeriesMetrics && !!organizations[0]?.id,
    },
  );

  return {
    hasCustomerTimeSeriesMetrics,
    customerTimeSeriesMetricsData,
    isCustomerTimeSeriesMetricsLoading,
  };
};
