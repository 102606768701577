import { useContext, useState } from 'react';
import styled from 'styled-components';

import {
  Modal,
  ModalBody,
  ModalButton,
  ModalCloseIcon,
  ModalContainer,
  ModalFooter,
  ModalTitle,
} from 'components/Modal';
import { Flexer, Spacer } from 'components/Core';
import { EditCircleIcon } from 'components/Icons';
import { CUSTOMERS_MODAL_ACTIONS, CustomersActionsModal } from 'views/Customers/CustomersActionsModal';
import { ReactComponent as ArrowRightIcon } from 'images/Arrow-right.svg';

import { InvoicingScheduleContext } from '../InvoicingScheduleContext';
import { InvoicingScheduleCustomerSelector } from '../InvoicingScheduleCustomerSelector';

const Wrapper = styled(Flexer)`
  width: 100%;
  gap: 8px;
  align-items: flex-end;
`;

const StyledEditCircleIcon = styled(EditCircleIcon)`
  margin-bottom: 12px;

  &:hover {
    cursor: pointer;
  }
`;

const StyledArrowRightIcon = styled(ArrowRightIcon)`
  width: 30px;
  height: 30px;

  path {
    fill: white;
  }
`;

export const SelectCustomerModal = ({ onClose, onSave }) => {
  const { invoicingScheduleFormValues } = useContext(InvoicingScheduleContext);

  const [showEditCustomerModal, setShowEditCustomerModal] = useState(false);

  return (
    <>
      <ModalContainer>
        <Modal width="600px" height="250px">
          <ModalCloseIcon onClick={onClose} />
          <ModalTitle data-cy="billing__create-invoice-schedule__title" bold fontSize="18px">
            Create a new invoicing schedule
          </ModalTitle>

          <ModalBody>
            <Spacer height="8px" />

            <Wrapper>
              <InvoicingScheduleCustomerSelector />
              <StyledEditCircleIcon
                data-cy="billing__create-invoice-schedule__customer-edit-button"
                size="16px"
                fill="black"
                onClick={() => setShowEditCustomerModal(true)}
              />
            </Wrapper>
          </ModalBody>

          <ModalFooter flexEnd padding="20px 20px">
            <ModalButton onClick={onClose}>
              <b>Cancel</b>
            </ModalButton>

            <ModalButton
              data-cy="billing__create-invoice-schedule__customer-selector--confirm-button"
              onClick={onSave}
              primary
              hideIcon
              disabled={!invoicingScheduleFormValues?.customer_id}
            >
              <b>Continue</b>
              <StyledArrowRightIcon />
            </ModalButton>
          </ModalFooter>
        </Modal>
      </ModalContainer>
      {showEditCustomerModal && (
        <CustomersActionsModal
          closeModal={() => {
            setShowEditCustomerModal(false);
          }}
          modalAction={CUSTOMERS_MODAL_ACTIONS.EDIT}
          customer={{ id: invoicingScheduleFormValues?.customer_id }}
        />
      )}
    </>
  );
};
