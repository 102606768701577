import React from 'react';
import { SettingsContainer } from './styles';
import { DATA_TYPES } from './utils';
import { SelectDropdownButton } from 'components/Buttons';
import styled from 'styled-components';

const LabelContainer = styled.div`
  font-size: 14px;
  display: flex;
  align-items: center;
`;

//draftDataFilter, updateDraftDataFilter props come from the Filters component
export const DataTypeFilter = ({ draftDataFilter = {}, updateDraftDataFilter }) => {
  const { dataType } = draftDataFilter;

  return (
    <SettingsContainer style={{ marginLeft: 8 }}>
      <LabelContainer>
        <span>Data: </span>
        <SelectDropdownButton
          name="spreadsDataType"
          selected={dataType ?? 'revenue'}
          options={DATA_TYPES}
          onSelect={(dataType) => updateDraftDataFilter({ dataType })}
          showSelectedDirectly
        />
      </LabelContainer>
    </SettingsContainer>
  );
};
