import { useMemo, useState } from 'react';
import dayjs from 'dayjs';
import styled from 'styled-components';
import AsyncSelect from 'react-select';
import { AsyncPaginate } from 'react-select-async-paginate';
import { ReactComponent as FilterIcon } from 'images/adjustments-horizontal.svg';
import { useUniqueUsageEventNamesAPI } from 'api/usageBasedEngine/hooks';
import { CustomDatePicker } from 'components/Controls';
import { selectStyles } from 'components/Controls/FormikCustomSelector/utils';
import { CentererVertical, Spacer } from 'components/Core';
import { SelectDropdownButton } from 'components/Buttons';
import { PERIOD_OPTIONS } from 'shared/Filters/utils';
import { getCustomersFromSearch } from 'shared/TransactionContent/utils';

const Wrapper = styled(CentererVertical)`
  margin: 0 40px 10px 46px;
  gap: 0;
`;

const DateRangeDetails = styled.span`
  color: var(--primaryBlack50);
  margin-right: 8pxl;
`;

export const UsageEventFilters = ({
  orgId,
  initialCustomerName,
  customerIds,
  eventNames,
  period,
  selectPeriod,
  startMonth,
  selectStartMonth,
  endMonth,
  selectEndMonth,
  setCustomerIds,
  setEventNames,
}) => {
  const { data: eventNameData } = useUniqueUsageEventNamesAPI({ orgId });
  const [customerInputValue, setCustomerInputValue] = useState(
    initialCustomerName
      ? [
          {
            label: initialCustomerName,
            id: customerIds[0],
          },
        ]
      : [],
  );
  const allEventNames = useMemo(() => eventNameData?.data ?? [], [eventNameData?.data]);

  const selectCustomerInputValue = (selectedCustomers) => {
    setCustomerInputValue(selectedCustomers);
    setCustomerIds(selectedCustomers.map(({ id }) => id));
  };

  return (
    <Wrapper>
      <FilterIcon />
      <Spacer width="8px" />
      <span>Period: </span>
      <SelectDropdownButton
        showSelectedDirectly
        name="filters__period"
        options={PERIOD_OPTIONS}
        selected={PERIOD_OPTIONS[period]}
        onSelect={selectPeriod}
      />
      {period === 'custom' ? (
        <>
          from
          <CustomDatePicker
            name="start-month"
            showMonthYearPicker
            dateFormat="MM/yyyy"
            filtersView
            selected={startMonth}
            onChange={selectStartMonth}
          />
          to
          <CustomDatePicker
            name="end-month"
            showMonthYearPicker
            dateFormat="MM/yyyy"
            filtersView
            selected={endMonth}
            onChange={selectEndMonth}
          />
        </>
      ) : (
        <DateRangeDetails>
          {dayjs.utc(startMonth).format('MMM YYYY')} through {dayjs.utc(endMonth).format('MMM YYYY')}
        </DateRangeDetails>
      )}
      <Spacer width="8px" />
      <AsyncPaginate
        boldValue
        placeholder="Select customers to filter"
        value={customerInputValue}
        onChange={selectCustomerInputValue}
        loadOptions={(searchQuery, prevOptions, additional) =>
          getCustomersFromSearch({ searchQuery, orgId, prevOptions, additional })
        }
        styles={selectStyles({ blueVer: true, isMulti: true })}
        menuPortalTarget={document.body}
        isClearable
        isMulti
      />
      <AsyncSelect
        boldValue
        placeholder="Select event names to filter"
        value={eventNames.map((eventName) => ({
          label: eventName,
          value: eventName,
        }))}
        onChange={(selection) => setEventNames(selection.map(({ value }) => value))}
        options={allEventNames.map((eventName) => ({
          label: eventName,
          value: eventName,
        }))}
        styles={selectStyles({ blueVer: true, isMulti: true })}
        menuPortalTarget={document.body}
        isClearable
        isMulti
      />
    </Wrapper>
  );
};
