import React, { useCallback, useContext, useState } from 'react';
import styled from 'styled-components';
import { AppContext } from 'AppContext';
import { ReactComponent as DeleteItem } from 'images/segments-trash.svg';
import { templateToHTMLString } from 'utils/htmlUtils';
import { Column, FlexEndContainer, Flexer } from 'components/Core';
import {
  Modal,
  ModalBody,
  ModalButton,
  ModalCloseIcon,
  ModalContainer,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from 'components/Modal';
import { useEntityAPI } from 'api/billingEntities/hooks';
import { TooltipContainer } from 'components/Tooltip';
import { useInvoiceMemoTemplatesAPI } from 'api/invoiceMemoTemplates/hooks';

export const DeleteCard = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 16px;
  justify-content: flex-start;
  margin-bottom: 30px;
  background: var(--primaryRed5);
  border-radius: 8px;

  svg {
    margin-right: 8px;

    g {
      opacity: 1;
    }
  }
`;

const DeleteTitle = styled.div`
  font-weight: 900;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 4px;
`;

const DeleteSubtitle = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  opacity: 0.5;
  margin-bottom: 8px;
  width: ${({ width }) => width};
`;

const DeleteText = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
`;

const Subtitle = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin-top: 8px;
  margin: 0 36px;
  width: 281px;
  margin-bottom: 24px;
`;

export const RemoveBillingEntityModal = ({ entity, onClose, availableTags, currentDefaultEntity }) => {
  const { orgId } = useContext(AppContext);
  const {
    operations: { deleteEntity },
  } = useEntityAPI({ orgId, autoFetch: false });
  const { data: templates = [] } = useInvoiceMemoTemplatesAPI({
    orgId,
    autoFetch: !!entity.footer_memo_template_id,
  });
  const memoContent = templates.find((template) => entity.footer_memo_template_id === template.id)?.content;

  return (
    <ModalContainer>
      <Modal maxWidth="440px" height="auto" overflow="visible">
        <ModalHeader>
          <ModalCloseIcon onClose={onClose} width={36} height={36} />
          <ModalTitle padding="0 0 0 36px" bold>
            Remove Sender Organization
          </ModalTitle>
        </ModalHeader>

        <Subtitle>Do you want to remove the record below? This cannot be un-done.</Subtitle>

        <ModalBody height="700px" gap="0" paddingLeft="36px" paddingRight="36px">
          <DeleteCard>
            <DeleteItem />
            <Column horizontal="flex-start" gap="16px">
              <DeleteTitle>{`${entity.entity_name ? `${entity.entity_name}: ` : ''}${
                entity.company_name
              }`}</DeleteTitle>

              <Flexer gap="16px">
                <DeleteSubtitle width="50px">Address: </DeleteSubtitle>

                <DeleteText
                  dangerouslySetInnerHTML={{
                    __html: templateToHTMLString({
                      text: entity.address,
                    }),
                  }}
                />
              </Flexer>

              {memoContent && (
                <Flexer gap="16px">
                  <DeleteSubtitle width="50px">Footer: </DeleteSubtitle>
                  <DeleteText
                    dangerouslySetInnerHTML={{
                      __html: templateToHTMLString({
                        text: memoContent,
                        availableTags,
                      }),
                    }}
                  />
                </Flexer>
              )}
            </Column>
          </DeleteCard>
        </ModalBody>
        <ModalFooter noFixedHeight padding="13px 21px">
          <FlexEndContainer>
            <ModalButton onClick={onClose}>Cancel</ModalButton>

            <TooltipContainer
              toolTipContent="Please select a different entity as default first"
              isVisible={currentDefaultEntity?.toString() === entity.id}
            >
              <ModalButton
                onClick={async () => {
                  await deleteEntity({ entityId: entity.id });
                  onClose();
                }}
                delete
                disabled={currentDefaultEntity?.toString() === entity.id}
                data-cy="remove-entity-modal__confirm-button"
              >
                Remove
              </ModalButton>
            </TooltipContainer>
          </FlexEndContainer>
        </ModalFooter>
      </Modal>
    </ModalContainer>
  );
};

export const useRemoveBillingEntityModal = (props) => {
  const [selectedEntity, setSelectedEntity] = useState(null);
  const openModal = useCallback((entity) => {
    setSelectedEntity(entity);
  }, []);

  const Modal = useCallback(
    () =>
      selectedEntity ? (
        <RemoveBillingEntityModal onClose={() => setSelectedEntity(null)} entity={selectedEntity} {...props} />
      ) : null,
    [props, selectedEntity],
  );
  return {
    openRemoveBillingEntityModal: openModal,
    RemoveBillingEntityModal: Modal,
  };
};
