import styled from 'styled-components';

import { FlexerColumn } from 'components/Core';
import { ReactComponent as BillingEmptyInvoicesIcon } from 'images/schedule-empty-chart-icon.svg';
import { ReactComponent as LeftArrow } from 'images/arrow-narrow-left.svg';
import { useContext } from 'react';
import { InvoicingScheduleContext } from '../../InvoicingScheduleContext';

const StyledBillingEmptyInvoicesIcon = styled(BillingEmptyInvoicesIcon)`
  margin-bottom: -65px;
`;

const StyledLeftArrow = styled(LeftArrow)`
  path {
    fill: var(--primaryBlack30);
  }
`;

const Container = styled(FlexerColumn)`
  border: 1px solid var(--accentGraySecond);
  border-radius: 8px;
  width: 100%;
  margin: 0px 16px 12px;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

const MainText = styled.div`
  font-weight: 700;
  font-size: 20px;
`;

const SubText = styled.div`
  font-size: 14px;
  max-width: 360px;
  text-align: center;
`;

export const SingleInvoiceEmptyState = () => {
  const { invoicingScheduleFormValues } = useContext(InvoicingScheduleContext);

  return (
    <Container>
      <StyledBillingEmptyInvoicesIcon />
      <MainText>
        {invoicingScheduleFormValues?.invoices?.length
          ? 'Select an invoice on the left'
          : '0 invoices in this schedule'}
      </MainText>

      <SubText>
        {invoicingScheduleFormValues?.invoices?.length
          ? 'To start using the billing, select one of any invoices on the left side.'
          : 'To start using the billing, create new invoices in this schedule by auto-generating or manual adding'}
      </SubText>
      <StyledLeftArrow />
    </Container>
  );
};
