import { useContext, useState, useEffect } from 'react';
import { useFormikContext } from 'formik';
import { AppContext } from 'AppContext';
import {
  RECOGNITION_TYPES,
  RECOGNITION_TYPES_LABELS,
  ISO_CODE_TO_SYMBOL,
  RECURRING_RECOGNITION_TYPES,
} from 'consts/global';
import { CustomDatePicker, FormikCustomInput, FormikCustomSelector } from 'components/Controls';
import { TooltipContainer } from 'components/Tooltip';
import { ChargedIcon, EditCircleIcon } from 'components/Icons';
import { Row, Spacer } from 'components/Core';
import { useProductsAPI } from 'api/products';
import { useClickOutside } from 'utils/hooks';
import { formatDateForDatepicker, updateDateFromDatePicker } from 'utils/dateUtils';
import { TransactionContext } from 'shared/TransactionContent/TransactionContext';
import { ItemsRow } from 'shared/TransactionContent/styles';
import { TRANSACTION_MODAL_MODE } from 'shared/TransactionContent/consts';
import { getOriginalField, SYMBOL_getOriginalField } from 'shared/TransactionContent/utils';
import { TransactionProductField } from '../TransactionProductField';
import { TransactionCustomerField } from '../TransactionCustomerField';
import { RecognitionSelect } from '../TransactionRecognitionSection/RecognitionSelect';
import {
  recurringAmountHandleChange,
  totalAmountHandleChange,
  startDateHandleChange,
  endDateHandleChange,
} from '../utils';
import {
  Container,
  SelectIconContainer,
  ProductRecognitionTooltip,
  ProductTooltipButton,
  EditMonthlyRecurringButton,
} from './styles';
import { TransactionAmountToBill } from './TransactionAmountToBill';
import { TransactionLinkToInvoicingSchedule } from './TransactionLinkToInvoicingSchedule';
import { AccountingRecognitionSelect } from '../TransactionRecognitionSection/AccountingRecognitionSelect';

// 'otherFields' is for display purposes
export const TransactionDetails = () => {
  const {
    appSettings: { currencyISOCode: currency },
    organizations,
  } = useContext(AppContext);
  const { mode, currentTransaction, transactionFormValues, changedDataFormatted } = useContext(TransactionContext);
  const { setFieldValue, getFieldMeta } = useFormikContext();

  const {
    operations: { bulkUpdate },
  } = useProductsAPI({ orgId: organizations?.[0].id, autoFetch: false });
  const [showProductRecognitionTooltip, setShowProductRecognitionTooltip] = useState(false);
  const productTooltipRef = useClickOutside(() => setShowProductRecognitionTooltip(false));
  const mrrInputRef = useClickOutside(() => setHighlightMRR(false));

  const currentProduct = organizations?.[0]?.products?.find(
    (product) => product.id === transactionFormValues?.product_id,
  );

  const [disableMRR, setDisableMRR] = useState(false);
  const [highlightMRR, setHighlightMRR] = useState(false);

  useEffect(() => {
    if (transactionFormValues?.recognition && currentProduct && !currentProduct?.recognition) {
      setShowProductRecognitionTooltip(true);
    }

    setDisableMRR(transactionFormValues?.recognition === RECOGNITION_TYPES.linear);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionFormValues?.recognition, currentProduct, organizations]);

  const isChanged = (key) =>
    mode === TRANSACTION_MODAL_MODE.EXTERNAL_UPDATE
      ? getOriginalField({ key, data: changedDataFormatted }) !== SYMBOL_getOriginalField
      : undefined;

  const getTooltipLabel = (key) => {
    return mode === TRANSACTION_MODAL_MODE.EXTERNAL_UPDATE && isChanged(key) ? (
      <>
        <div>Old value:</div>
        <div>{getOriginalField({ key, data: changedDataFormatted }) || 'No previous value'}</div>
      </>
    ) : undefined;
  };

  const currencySymbol = ISO_CODE_TO_SYMBOL[transactionFormValues?.currency] ?? ISO_CODE_TO_SYMBOL[currency] ?? '$';

  return (
    <Container disabled={!!currentTransaction.replaced_by}>
      {mode === TRANSACTION_MODAL_MODE.CREATE && (
        <ItemsRow>
          <FormikCustomInput
            name="name"
            label="Name"
            data-cy="input-name"
            placeholder="Enter name"
            isChanged={
              mode === TRANSACTION_MODAL_MODE.EXTERNAL_UPDATE
                ? getOriginalField({ key: 'name', data: changedDataFormatted }) !== SYMBOL_getOriginalField
                : undefined
            }
            tooltipInputDisplay={
              mode === TRANSACTION_MODAL_MODE.EXTERNAL_UPDATE ? (
                getOriginalField({ key: 'name', data: changedDataFormatted }) !== SYMBOL_getOriginalField ? (
                  <>
                    <div>Old value:</div>{' '}
                    <div> {getOriginalField({ key: 'name', data: changedDataFormatted }) || 'No previous value'} </div>{' '}
                  </>
                ) : null
              ) : undefined
            }
          />

          <FormikCustomSelector
            label="Confirmation"
            options={[
              { label: 'Unconfirmed', value: false },
              { label: 'Confirmed', value: true },
            ]}
            name="confirmed"
          />
        </ItemsRow>
      )}

      <ItemsRow>
        <TransactionCustomerField />

        <TransactionProductField />

        {/* SaaS Rev. Recognition */}
        <div style={{ position: 'relative' }}>
          {isChanged('recognition') ? (
            <TooltipContainer width={150} toolTipContent={getTooltipLabel('recognition')}>
              <SelectIconContainer>
                <RecognitionSelect values={transactionFormValues} setFieldValue={setFieldValue} formik />
                <Spacer width="10px" />
                <ChargedIcon size="16px" />
              </SelectIconContainer>
            </TooltipContainer>
          ) : (
            <RecognitionSelect values={transactionFormValues} setFieldValue={setFieldValue} formik />
          )}

          {showProductRecognitionTooltip && (
            <ProductRecognitionTooltip ref={productTooltipRef}>
              <b>{currentProduct?.name}</b> has no recognition policy. Do you want to update it to{' '}
              <b>{RECOGNITION_TYPES_LABELS[transactionFormValues?.recognition]}</b>?
              <Row style={{ marginTop: 12 }}>
                <ProductTooltipButton onClick={() => setShowProductRecognitionTooltip(false)} noButton>
                  no, thanks
                </ProductTooltipButton>
                <ProductTooltipButton
                  onClick={() => {
                    bulkUpdate.mutateAsync({
                      productIds: [currentProduct.id],
                      data: {
                        recognition: transactionFormValues?.recognition,
                      },
                    });

                    setShowProductRecognitionTooltip(false);
                  }}
                >
                  yes, update
                </ProductTooltipButton>
              </Row>
            </ProductRecognitionTooltip>
          )}
        </div>

        {/* Accounting Rev. Recognition */}
        <AccountingRecognitionSelect values={transactionFormValues} setFieldValue={setFieldValue} />

        {/* TODO: Add billing invoicing options */}
        {/* <FormikCustomSelector
          label="Billing"
          options={[
            { label: 'Allow invoicing', value: 'Allow invoicing' },
            { label: "Don't allow invoicing", value: "Don't allow invoicing" },
          ]}
          boldValue
          name="allow_invoicing"
          defaultValue="Allow invoicing"
          handleChange={(option) => {
            if (option) setFieldValue('currency', option.value);
            else setFieldValue('currency', null);
          }}
        /> */}
      </ItemsRow>

      <ItemsRow>
        <FormikCustomSelector
          label="Currency"
          options={Object.entries(ISO_CODE_TO_SYMBOL).map(([code, symbol]) => ({
            label: `${symbol} (${code})`,
            value: code,
          }))}
          boldValue
          name="currency"
          handleChange={(option) => {
            if (option) setFieldValue('currency', option.value);
            else setFieldValue('currency', null);
          }}
          isDisabled={!!transactionFormValues?.invoicing_schedule_id}
          tooltipInputDisplay={
            transactionFormValues?.invoicing_schedule_id
              ? 'Transactions that have invoicing schedules cannot change currency. If no invoices have been sent or voided, delete the invoicing schedule first.'
              : null
          }
        />
        <CustomDatePicker
          formik
          label="Booking Date"
          selected={transactionFormValues?.date ? formatDateForDatepicker(transactionFormValues?.date) : null}
          onChange={(name, date) => setFieldValue(name, updateDateFromDatePicker(date))}
          name="date"
          isChanged={
            mode === TRANSACTION_MODAL_MODE.EXTERNAL_UPDATE
              ? getOriginalField({ key: 'date', data: changedDataFormatted }) !== SYMBOL_getOriginalField
              : undefined
          }
          tooltipInputDisplay={
            mode === TRANSACTION_MODAL_MODE.EXTERNAL_UPDATE ? (
              getOriginalField({ key: 'date', data: changedDataFormatted }) !== SYMBOL_getOriginalField ? (
                <>
                  <div>Old value:</div> <div> {getOriginalField({ key: 'date', data: changedDataFormatted })} </div>{' '}
                </>
              ) : null
            ) : undefined
          }
        />
        <div ref={mrrInputRef} style={{ position: 'relative' }}>
          <FormikCustomInput
            name="recurring_amount"
            data-cy="input-recurring-amount"
            label="Monthly Recurring"
            placeholder={RECURRING_RECOGNITION_TYPES.includes(transactionFormValues?.recognition) ? 'Enter amount' : 0}
            suffix={currencySymbol}
            type="number"
            isDisabled={!RECURRING_RECOGNITION_TYPES.includes(transactionFormValues?.recognition) || disableMRR}
            handleChange={recurringAmountHandleChange(setFieldValue, transactionFormValues)}
            defaultValue={currentTransaction ? currentTransaction.recurring_amount : ''}
            precision={2}
            isChanged={isChanged('recurring_amount')}
            tooltipInputDisplay={getTooltipLabel('recurring_amount')}
            isOpen={highlightMRR}
            TopRightHeader={
              disableMRR && (
                <TooltipContainer
                  toolTipContent="This was auto-calculated, but you can manually change it"
                  width={180}
                  fontSize="12px"
                  hideArrow
                >
                  <EditMonthlyRecurringButton
                    data-cy="input-recurring-amount__edit-button"
                    onClick={() => {
                      setDisableMRR(false);
                      setHighlightMRR(true);
                    }}
                  >
                    <EditCircleIcon />
                  </EditMonthlyRecurringButton>
                </TooltipContainer>
              )
            }
          />
        </div>
        <FormikCustomInput
          name="amount"
          data-cy="input-total"
          label="Total Amount"
          placeholder={transactionFormValues?.recognition === RECOGNITION_TYPES.tillCanceled ? 0 : 'Enter total'}
          suffix={currencySymbol}
          type="number"
          isDisabled={RECOGNITION_TYPES.tillCanceled === transactionFormValues?.recognition}
          handleChange={totalAmountHandleChange(setFieldValue, transactionFormValues)}
          defaultValue={currentTransaction ? currentTransaction.amount : ''}
          precision={2}
          isChanged={isChanged('amount')}
          tooltipInputDisplay={getTooltipLabel('amount')}
        />

        <CustomDatePicker
          formik
          label="Start Date"
          selected={
            transactionFormValues?.start_date ? formatDateForDatepicker(transactionFormValues?.start_date) : null
          }
          onChange={startDateHandleChange(setFieldValue, transactionFormValues)}
          name="start_date"
          meta={getFieldMeta('start_date')}
          isChanged={isChanged('start_date')}
          tooltipInputDisplay={getTooltipLabel('start_date')}
        />
        <CustomDatePicker
          formik
          label="End Date"
          selected={transactionFormValues?.end_date ? formatDateForDatepicker(transactionFormValues?.end_date) : null}
          presetStartDate={
            transactionFormValues?.start_date ? formatDateForDatepicker(transactionFormValues?.start_date) : null
          }
          onChange={endDateHandleChange(setFieldValue, transactionFormValues)}
          name="end_date"
          meta={getFieldMeta('end_date')}
          isChanged={isChanged('end_date')}
          tooltipInputDisplay={getTooltipLabel('end_date')}
        />

        {!Boolean(currentTransaction.replaced_by) &&
          (transactionFormValues?.recognition === RECOGNITION_TYPES.linear ||
            transactionFormValues?.recognition === RECOGNITION_TYPES.tillCanceled) && (
            <div data-cy="transaction-form__renewal-deadline-date">
              <CustomDatePicker
                formik
                label="Renewal Deadline"
                data-cy="transaction-form__renewal-deadline-date"
                selected={
                  transactionFormValues?.renewal_deadline
                    ? formatDateForDatepicker(transactionFormValues?.renewal_deadline)
                    : null
                }
                onChange={(name, date) => setFieldValue(name, updateDateFromDatePicker(date))}
                name="renewal_deadline"
                isChanged={isChanged('renewal_deadline')}
                tooltipInputDisplay={getTooltipLabel('renewal_deadline')}
                disabled={!!currentTransaction.replaced_by}
              />
            </div>
          )}

        <FormikCustomInput
          bold
          name="seats"
          label="Seats"
          data-cy="input-seats"
          placeholder="Enter seats"
          min="0"
          type="number"
          isChanged={
            mode === TRANSACTION_MODAL_MODE.EXTERNAL_UPDATE
              ? getOriginalField({ key: 'seats', data: changedDataFormatted }) !== SYMBOL_getOriginalField
              : undefined
          }
          tooltipInputDisplay={
            mode === TRANSACTION_MODAL_MODE.EXTERNAL_UPDATE ? (
              getOriginalField({ key: 'seats', data: changedDataFormatted }) !== SYMBOL_getOriginalField ? (
                <>
                  <div>Old value:</div> <div> {getOriginalField({ key: 'seats', data: changedDataFormatted })} </div>{' '}
                </>
              ) : null
            ) : undefined
          }
        />
      </ItemsRow>

      <ItemsRow>
        {transactionFormValues?.recognition && (
          <TransactionAmountToBill
            recognition={transactionFormValues?.recognition}
            totalAmount={transactionFormValues?.amount}
            currency={transactionFormValues?.currency}
          />
        )}
        {!!transactionFormValues?.invoicing_schedule_id && (
          <TransactionLinkToInvoicingSchedule invoicingScheduleId={transactionFormValues?.invoicing_schedule_id} />
        )}
      </ItemsRow>
    </Container>
  );
};
