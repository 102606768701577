import styled from 'styled-components';

import { Spacer, Centerer } from 'components/Core';

const Container = styled.div`
  display: flex;
  width: ${({ width }) => width ?? '100%'};

  flex-direction: row;
  justify-content: start;
  align-items: center;
`;

const TextContainer = styled(Centerer)`
  font-size: 12px;
  opacity: 0.5;
`;

export const Callout = ({ text, icon, width }) => (
  <Container width={width}>
    {icon}
    {icon && <Spacer width="8px" />}
    <TextContainer>{text}</TextContainer>
  </Container>
);
