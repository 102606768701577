import { useContext } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { Row, Spacer, FlexEndContainer } from 'components/Core';
import { TransactionContext } from 'shared/TransactionContent/TransactionContext';
import { TransactionTitle } from './TransactionTitle';
import { TransactionDataActionsContainer } from './TransactionDataActionsContainer';
import { TransactionAuditsContainer } from './TransactionAuditsContainer';
import { TransactionReplacedByWarning } from './TransactionReplacedByWarning';
import { TransactionLinearToTillCanceledWarning } from './TransactionLinearToTillCanceledWarning';
import { TransactionFlaggingReasons } from './TransactionFlaggingReasons';
import { TransactionSidebarToggle } from './TransactionSidebarToggle';
import { TransactionLockedByDateWarning } from './TransactionLockedByDateWarning';
import { TransactionResyncNeededWarning } from './TransactionResyncNeededWarning';
import { TransactionMismatchTotalAmountAndMRR } from './TransactionMismatchTotalAmountAndMRR';

const Wrapper = styled.div`
  position: relative;
  padding: 20px 40px 24px 40px;
  border-bottom: 1px solid var(--accentGraySecond);

  @media (max-width: 1439px) {
    padding: 20px 60px 24px 40px;
  }
`;

export const SelectedAuditTimestamp = styled.div`
  margin-top: 2px;
  margin-bottom: -10px;
  font-size: 12px;
  font-weight: 400;
  color: var(--primaryBlack30);
`;

export const TransactionHeader = ({ leftSection, rightSection }) => {
  const { selectedAudit } = useContext(TransactionContext);

  return (
    <Wrapper>
      <Row horizontal="space-between">
        <Row horizontal="start">
          {leftSection}
          <TransactionTitle />
          <TransactionDataActionsContainer />
        </Row>

        <Row horizontal="end">
          <TransactionAuditsContainer />
          {rightSection && (
            <>
              <Spacer width="16px" />
              {rightSection}
            </>
          )}
        </Row>
      </Row>
      {selectedAudit?.id && (
        <FlexEndContainer>
          <SelectedAuditTimestamp data-cy="transaction-modal__transaction-audit-timestamp">
            Showing an older version on {dayjs(selectedAudit.created_at).format('MMM DD, YYYY')}
          </SelectedAuditTimestamp>
        </FlexEndContainer>
      )}
      <TransactionReplacedByWarning />
      <TransactionLockedByDateWarning />
      <TransactionLinearToTillCanceledWarning />
      <TransactionFlaggingReasons />
      <TransactionResyncNeededWarning />
      <TransactionMismatchTotalAmountAndMRR />
      <TransactionSidebarToggle />
    </Wrapper>
  );
};
