import React, { useContext } from 'react';
import { Bar, defaults } from 'react-chartjs-2';
import dayjs from 'dayjs';
import { cssVar } from 'polished';
import { transparentize } from 'polished';
import styled from 'styled-components';
import { AppContext } from 'AppContext';
import { ReactComponent as TitleIcon } from 'images/chart-title-icon.svg';
import { FillDot } from 'components/Icons';
import { Column } from 'components/Core';
import { UNIT_OPTIONS } from './RevenueDetailsContent';
import { NUMBER_FORMATS } from 'consts/global';
import { numberFormatter } from 'utils/formatters';

const ChartContainer = styled.div`
  padding: 22px 14px;
  border-radius: 16px;
  background-color: white;
`;

const ChartTitle = styled.div`
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  margin-bottom: 24px;
  display: flex;
  align-items: center;

  svg {
    margin-right: 12px;
  }
`;

const ChartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  border: 1px solid var(--accentGraySecond);
  width: 100%;
`;

const LegendContainer = styled.div`
  width: 100%;
  height: 38px;
  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 16px;
  border-top: 1px solid var(--accentGraySecond);
  background-color: var(--primaryGray);
  display: flex;
`;

const DataKeysTotal = styled.div`
  padding: 12px 0px 12px 16px;
  color: var(--primaryBlack30);
  font-weight: 800;
  font-size: 10px;
  line-height: 14px;
  text-transform: uppercase;
  white-space: nowrap;
`;

const KeysContainer = styled.div`
  width: 95%;
  display: flex;
  font-weight: 900;
  font-size: 10px;
  line-height: 14px;
  text-transform: uppercase;
  vertical-align: sub;
  padding: 12px 14px;
`;

const LegendIcon = styled.div`
  margin-right: 22px;

  span {
    margin-left: 6px;
  }
`;

const RevenueDetailsChart = ({ historicalData, activeMetric, summaryStartMonth, currency }) => {
  defaults.global.defaultFontFamily = 'Nunito Sans';

  const { includeCommitted } = useContext(AppContext);

  if (!historicalData) {
    return null;
  }

  const labels = Object.keys(historicalData);

  const revenueChartData = {
    type: 'line',
    label: 'Revenue',
    fill: false,
    borderColor: cssVar('--accentDarkGray'),
    pointBackgroundColor: cssVar('--accentDarkGray'),
    pointBorderColor: 'white',
    borderWidth: 2,
    pointRadius: 4,
    lineTension: 0,
    data: labels.map((month) => Math.round(historicalData[month][UNIT_OPTIONS.MONEY][activeMetric])),
    yAxisID: 'y0',
  };

  const customersChartData = {
    type: 'bar',
    label: 'Customers',
    backgroundColor: '#957AFF',
    data: labels.map((month) => Math.round(historicalData[month][UNIT_OPTIONS.CUSTOMERS][activeMetric])),
    barThickness: 18,
    yAxisID: 'y1',
  };

  const options = {
    responsive: true,
    legend: {
      display: false,
    },
    tooltips: {
      mode: 'index',
      callbacks: {
        title: function (tooltipItem) {
          return dayjs(tooltipItem[0].label).format('MMM YYYY');
        },
        label: function (tooltipItem) {
          if (tooltipItem.datasetIndex === 0) {
            return numberFormatter({ type: NUMBER_FORMATS.CURRENCY, rawValue: tooltipItem.yLabel, currency });
          } else {
            return `${tooltipItem.yLabel.toString()} customers`;
          }
        },
      },
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
          },
          ticks: {
            padding: 10,
            fontStyle: 'bold',
            fontColor: transparentize(0.8, cssVar('--primaryBlack')),
            callback: function (value) {
              return dayjs(value).format('MMM YYYY').toUpperCase();
            },
          },
        },
      ],
      yAxes: [
        {
          id: 'y0',
          position: 'right',
          gridLines: {
            display: false,
            drawBorder: false,
          },
          ticks: {
            padding: 10,
            fontStyle: 'bold',
            fontColor: transparentize(0.6, cssVar('--primaryBlack')),
            min: 0,
            maxTicksLimit: 6,
            callback: function (value) {
              return numberFormatter({ type: NUMBER_FORMATS.CURRENCY, rawValue: value, currency });
            },
          },
        },
        {
          id: 'y1',
          position: 'left',
          gridLines: {
            drawBorder: false,
          },
          ticks: {
            padding: 10,
            fontStyle: 'bold',
            fontColor: transparentize(0.6, cssVar('--primaryBlack')),
            min: 0,
            maxTicksLimit: 6,
          },
        },
      ],
    },
  };

  return (
    <Column style={{ width: '75%' }} horizontal="start">
      <ChartTitle>
        <TitleIcon />
        {`${activeMetric} ${summaryStartMonth ? '' : '12-months'} Chart`}
      </ChartTitle>
      <ChartWrapper>
        <ChartContainer includeCommitted={includeCommitted}>
          <Bar
            height={85}
            data={{
              labels,
              datasets: [revenueChartData, customersChartData],
            }}
            options={options}
          />
        </ChartContainer>
        <LegendContainer>
          <DataKeysTotal>chart Legend:</DataKeysTotal>
          <KeysContainer>
            <LegendIcon>
              <FillDot fill={cssVar('--primaryPurple')} />
              <span>Customers</span>
            </LegendIcon>

            <LegendIcon>
              <FillDot fill={cssVar('--accentDarkGray')} />
              <span>revenue</span>
            </LegendIcon>
          </KeysContainer>
        </LegendContainer>
      </ChartWrapper>
    </Column>
  );
};

export default RevenueDetailsChart;
