import { useContext } from 'react';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { AppContext } from 'AppContext';
import { NUMBER_FORMATS } from 'consts/global';
import { BlueText, Row } from 'components/Core';
import { AlertCircleIcon } from 'components/Icons';
import { ReactComponent as SubrowArrow } from 'images/subrow-arrow.svg';
import { numberFormatter } from 'utils/formatters';
import { capitalize } from 'utils/stringUtils';
import { isImportInvoiceTotalMatchWithSubscriptTotal } from './utils';

const InfoWrapper = styled.div`
  padding-bottom: 16px;
  border-bottom: 1px solid var(--primaryBlack3);
  margin-bottom: 8px;
`;

const Title = styled.div`
  font-size: 12px;
  font-style: normal;
  font-weight: 900;
  line-height: 16px;
`;

const ErrorMessage = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  font-style: italic;
  gap: 4px;
  margin-top: 8px;
  line-height: 16px;
  font-weight: 400;
  color: var(--tertiaryRed);
`;

const LineItemTitleRow = styled(Row)`
  display: flex;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  justify-content: flex-start;
  gap: 6px;
  align-self: stretch;
`;

const LineItemRow = styled(Row)`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  margin-left: 3px;
  margin-top: 6px;
`;

export const ImportedInvoiceItemsInfo = ({ values }) => {
  const {
    appSettings: { currencyISOCode: defaultOrgCurrency },
  } = useContext(AppContext);

  const isTotalsMatch = isImportInvoiceTotalMatchWithSubscriptTotal({
    originalAmount: values?.importedOriginalTotal,
    newAmount: values?.amount,
  });

  return (
    <InfoWrapper>
      <Title>
        Imported invoice <BlueText>#{values?.importedOriginalNumber}</BlueText> from{' '}
        {capitalize(values?.imported_service ?? values?.import_service)}:{' '}
        {numberFormatter({
          type: NUMBER_FORMATS.CURRENCY,
          rawValue: values?.importedOriginalTotal,
          decimalPlaces: 2,
          currency: values.currency ?? defaultOrgCurrency,
        })}{' '}
        <span style={{ color: 'var(--primaryBlack50)' }}>
          ({dayjs.utc(values.importedOriginalDate).format('MMM DD, YYYY')})
        </span>
      </Title>

      {values?.importedOriginalInvoiceItems?.map((lineItem, index) => (
        <LineItemRow horizontal="flex-start" key={(lineItem.name ?? lineItem.description) + index}>
          <LineItemTitleRow>
            <SubrowArrow style={{ opacity: 0.2 }} />
            {lineItem.name ?? lineItem.description ?? lineItem.type} /{' '}
            {numberFormatter({
              type: NUMBER_FORMATS.CURRENCY,
              rawValue: lineItem?.amount,
              decimalPlaces: 2,
              currency: values?.currency ?? defaultOrgCurrency,
            })}
          </LineItemTitleRow>
        </LineItemRow>
      ))}

      {!isTotalsMatch && (
        <ErrorMessage data-cy="invoice_items__imported__total-error">
          <AlertCircleIcon size="16px" />
          Match the imported invoice data with transactions in Subscript based on the total amount of
          <b>
            {numberFormatter({
              type: NUMBER_FORMATS.CURRENCY,
              rawValue: values?.importedOriginalTotal,
              decimalPlaces: 2,
              currency: values?.currency ?? defaultOrgCurrency,
            })}
          </b>
        </ErrorMessage>
      )}
    </InfoWrapper>
  );
};
