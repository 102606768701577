import styled from 'styled-components';
import { ReactComponent as LegendKeyDot } from 'images/fill-dot.svg';

export const LegendContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  min-height: 38px;
  margin-top: 20px;
  padding: 10px 12px;

  background: white;
  border: 1px solid var(--accentGraySecond);
  box-shadow: 8px 8px 60px var(--primaryBlack4);
  border-radius: 8px;
`;

export const LegendLabel = styled.div`
  display: flex;
  align-items: center;

  color: var(--primaryBlack30);
  font-weight: 800;
  font-size: 10px;
  line-height: 14px;
  text-transform: uppercase;
  white-space: nowrap;
`;

export const Legend = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const LegendKeysContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 4px;

  font-weight: 900;
  font-size: 10px;
  line-height: 14px;
  text-transform: uppercase;
`;

export const LegendKey = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 4px 8px;
  cursor: pointer;
  opacity: ${({ active }) => (active ? '1' : '0.5')};
`;

export { LegendKeyDot };
