import React, { useState } from 'react';

import { useClickOutside } from 'utils/hooks';
import { CentererVertical } from 'components/Core';
import { CONFIRMATION_OPTIONS } from 'shared/TransactionTable/consts';

import { FilterConditionHead, StyledSelectorPopover, SelectorItem, FilterLabel } from './styles';

const TransactionsTypePopover = ({ draftDataFilter, updateDraftDataFilter, ...props }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useClickOutside(() => setShowDropdown(false));

  const showTransactionsWithConfirmation = draftDataFilter?.transactionsConfirmation;

  return (
    <CentererVertical>
      <FilterLabel>Type: </FilterLabel>
      <FilterConditionHead
        data-cy="filters__confirmation-type-button"
        ref={dropdownRef}
        onClick={() => setShowDropdown(!showDropdown)}
      >
        {showTransactionsWithConfirmation}
        {showDropdown && (
          <OpenedDropdown
            setShowDropdown={setShowDropdown}
            updateDraftDataFilter={updateDraftDataFilter}
            showTransactionsWithConfirmation={showTransactionsWithConfirmation}
            {...props}
          />
        )}
      </FilterConditionHead>
    </CentererVertical>
  );
};

const OpenedDropdown = ({ showTransactionsWithConfirmation, updateDraftDataFilter, setShowDropdown }) => {
  return (
    <StyledSelectorPopover>
      {Object.keys(CONFIRMATION_OPTIONS).map((option) => {
        return (
          <SelectorItem
            key={option}
            data-cy={`filters__confirmation-select--${option}`}
            current={showTransactionsWithConfirmation === option}
            onClick={() => {
              setShowDropdown(false);
              updateDraftDataFilter({ transactionsConfirmation: option });
            }}
          >
            {option}
          </SelectorItem>
        );
      })}
    </StyledSelectorPopover>
  );
};

export { TransactionsTypePopover };
