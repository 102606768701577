import React from 'react';
import styled from 'styled-components';
import { Bar, defaults } from 'react-chartjs-2';
import { cssVar } from 'polished';

import { reFormatDate } from 'utils/dateUtils';
import { amountWithAbbreviation } from 'utils/formatters';

const ChartLegendContainer = styled.div`
  width: 100%;
`;

const ChartContainer = styled.div`
  width: 100%;
`;

// Expect data in the form: {'2022-09': 1000, '2022-10': 2000, ...}
export const BarChart = ({ data, title, height, currency }) => {
  defaults.global.defaultFontFamily = 'Nunito Sans';

  if (Object.keys(data).length === 0) {
    return null;
  }

  const months = data ? Object.keys(data) : [];
  const monthsValues = data ? Object.values(data) : [];

  const chartDate = {
    type: 'bar',
    label: title,
    barThickness: 24,
    backgroundColor: cssVar('--primaryPurple20'),
    borderSkipped: false,
    borderWidth: 1,
    data: monthsValues,
  };

  const options = {
    responsive: true,
    layout: {
      padding: {
        top: 30,
      },
    },
    legend: {
      display: false,
    },
    tooltips: {
      enable: false,
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
          },
          ticks: {
            callback: (value) => reFormatDate(value, 'YYYY-MM', 'MMM YY').toUpperCase(),
            autoSkip: true,
            maxRotation: 0,
            minRotation: 0,
            fontStyle: 'bold',
            fontSize: 9,
            fontColor: cssVar('--primaryBlack50'),
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            display: false,
          },
          ticks: {
            padding: 20,
            callback: (value) => amountWithAbbreviation({ value, type: 'currency', currency }),
            autoSkip: true,
            maxTicksLimit: 5,
            maxRotation: 0,
            minRotation: 0,
            fontStyle: 'bold',
            fontSize: 10,
            fontColor: cssVar('--primaryBlack40'),
          },
        },
      ],
    },
    plugins: {
      datalabels: {
        align: 'end',
        anchor: 'end',
        display: 'auto',
        backgroundColor: cssVar('--accentDarkGray'),
        borderRadius: 4,
        color: '#FFF',
        font: {
          size: 9,
          weight: 'bold',
        },
        formatter: (value) => amountWithAbbreviation({ value, type: 'currency', currency }),
        padding: 6,
      },
    },
  };
  return (
    <ChartLegendContainer>
      <ChartContainer>
        <Bar
          height={height ?? 60}
          data={{
            labels: months,
            datasets: [chartDate],
          }}
          options={options}
        />
      </ChartContainer>
    </ChartLegendContainer>
  );
};
