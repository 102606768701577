import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { EVENTS } from 'consts/analytics';
import { ReactComponent as CheckIcon } from 'images/audit-check.svg';
import { ReactComponent as HeaderIcon } from 'images/bxs-report.svg';
import { ReactComponent as CloseIcon } from 'images/close-icon.svg';
import { ReactComponent as TimeIcon } from 'images/bxs-time.svg';
import { ModalContainer } from 'components/Modal';
import { getTransactionsAudits } from 'api';
import { getTransaction } from 'api/transactions';
import { normalizeAmountFields } from 'models/transaction';
import { useAnalytics } from 'utils/hooks';
import {
  AuditAction,
  AuditChanges,
  AuditModalHeader,
  AuditsHistory,
  AuditTable,
  AuditVer,
  CurrentVersion,
  ChangeCard,
  ColHead,
  LastUpdated,
  RowItems,
  TableHeader,
  TableRow,
} from './styles';

dayjs.extend(utc);

const LABELS_FOR_CHANGES = {
  amount: 'total',
  confirmed: 'confirmed',
  customer_id: 'Customer',
  date: 'date',
  end_date: 'end',
  name: 'name',
  product_id: 'product',
  recognition: 'recognition',
  seats: 'Seats',
  start_date: 'start',
  metadata: 'Metadata',
};

export const TransactionAudits = ({ transaction, organizationId, selectedAudit, setSelectedAudit }) => {
  const [audits, setAudits] = useState([]);
  const [showHistory, setShowHistory] = useState(false);
  const { trackEvent } = useAnalytics();

  useEffect(() => {
    const fetchAudits = async () => {
      if (transaction?.id) {
        const fetchedAudits = await getTransactionsAudits(transaction.id, organizationId);

        let latestTransaction = transaction;
        if (selectedAudit?.id) {
          latestTransaction = await getTransaction({
            orgId: organizationId,
            transactionId: transaction.id,
          });
          normalizeAmountFields(transaction);
        }

        if (fetchedAudits) {
          setAudits([
            {
              ...latestTransaction,
              id: null,
              transaction_id: latestTransaction?.id,
              created_at: latestTransaction?.updated_at,
            },
            ...fetchedAudits,
          ]);
        }
      }
    };
    fetchAudits();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transaction?.id, organizationId, selectedAudit?.id]);

  const getChanges = (currentAudit, previousAudit) => {
    let changes = [];

    Object.keys(currentAudit).forEach((currentObjectKey) => {
      if (currentObjectKey in previousAudit && currentObjectKey !== 'id' && currentObjectKey !== 'created_at') {
        if (currentAudit[currentObjectKey] !== previousAudit[currentObjectKey]) {
          changes.push(currentObjectKey);
        }
      }
    });

    return changes.length ? (
      changes.map((change, cardIndex) => (
        <ChangeCard key={change} marginTop={cardIndex > 3}>
          {LABELS_FOR_CHANGES[change]}
        </ChangeCard>
      ))
    ) : (
      <div style={{ opacity: 0.4 }}>—</div>
    );
  };

  return (
    <>
      {transaction?.id && Boolean(audits.length) ? (
        <div>
          <div>
            <LastUpdated
              onClick={() => {
                trackEvent({
                  name: EVENTS.VIEW_TRANSACTION_HISTORY,
                  properties: {
                    transactionId: transaction?.id,
                  },
                });

                setShowHistory(true);
              }}
              data-cy="transaction-modal__transaction-audits__opener"
            >
              Last updated at:
              <span>{dayjs.utc(audits[0]?.created_at).format('MMM DD, YYYY')}</span>
              <TimeIcon />
            </LastUpdated>
          </div>
          {showHistory && (
            <ModalContainer>
              <AuditsHistory data-cy="transaction_modal__transaction_audits">
                <AuditModalHeader>
                  <CloseIcon
                    onClick={() => setShowHistory(false)}
                    className="closeIcon"
                    width={26}
                    height={26}
                    data-cy="transaction-modal__transaction-audits__closer"
                  />
                  <h3>
                    <HeaderIcon />
                    Transaction History
                  </h3>
                </AuditModalHeader>
                <AuditTable>
                  <TableHeader>
                    <ColHead>Version</ColHead>
                    <ColHead>Date & time</ColHead>
                    <ColHead>Changes</ColHead>
                    <ColHead actionStyle={true}>Action</ColHead>
                  </TableHeader>

                  {audits.map((audit, index) => (
                    <TableRow key={audit.id} active={audit.id === selectedAudit?.id}>
                      <RowItems data-cy="transaction-modal__transaction-audits__row">
                        <AuditVer>#{audits.length - index}</AuditVer>
                        <div>{dayjs.utc(audit.created_at).format('MMM DD, YYYY [at] h:mm a')}</div>
                        <AuditChanges>
                          {/* if we have one audit, we compare its change with the current transaction,
                        but if there is more than one, then changes in first audit always remains empty,
                        since it is illogical to compare the first audit with a transaction when more audits were added  */}
                          {index !== audits.length - 1 || audits.length === 1 ? (
                            getChanges(audit, audits.length === 1 ? transaction : audits[index + 1])
                          ) : (
                            <div style={{ opacity: 0.4 }}>—</div>
                          )}
                        </AuditChanges>
                        <AuditAction
                          onClick={() => {
                            if (audit.id === (selectedAudit?.id ?? null)) return;

                            trackEvent({
                              name: EVENTS.SELECT_TRANSACTION_AUDIT,
                              properties: {
                                transactionId: transaction?.id,
                                transactionAuditId: audit.id,
                              },
                            });
                            setSelectedAudit(audit);
                          }}
                          disabled={audit.id === (selectedAudit?.id ?? null)}
                          data-cy="transaction-modal__transaction-audits__row__action"
                        >
                          {audit.id === (selectedAudit?.id ?? null) ? (
                            <CurrentVersion>
                              Current version <CheckIcon />
                            </CurrentVersion>
                          ) : (
                            'Show version'
                          )}
                        </AuditAction>
                      </RowItems>
                    </TableRow>
                  ))}
                </AuditTable>
              </AuditsHistory>
            </ModalContainer>
          )}
        </div>
      ) : null}
    </>
  );
};
