import React, { useContext } from 'react';
import styled from 'styled-components';
import { ReactComponent as InfoIcon } from 'images/info-circle.svg';
import { useCurrencyNumberFormatter } from 'utils/hooks';
import { reFormatDate } from 'utils/dateUtils';
import { ValuesComparison } from 'shared/Common';
import { Flexer, FlexerColumn } from 'components/Core';
import { CardChartModalContext } from './CardChartModalContext';
import { CardChartSectionIcon, CardChartSectionTitle } from './styled';

const TableContainer = styled(Flexer)`
  position: relative;
  background: #ffffff;
  border: 1px solid var(--accentGraySecond);
  border-radius: 16px;
`;

const TableColumn = styled(FlexerColumn)`
  min-width: 90px;
  align-items: end;
`;

const Line = styled.div`
  position: absolute;
  top: 50%;
  width: 100%;
  height: 1px;
  background-color: var(--accentGraySecond);
`;

const LineVertical = styled.div`
  width: 1px;
  height: 100%;
  background-color: var(--accentGraySecond);
`;

const Label = styled.div`
  padding: 18px 14px;

  font-weight: 900;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  text-align: right;
  color: var(--primaryBlack50);
`;

const ValueText = styled(Label)`
  text-align: left;
  color: var(--primaryBlack);
`;

const Value = styled(Flexer)`
  padding: 18px 14px;

  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: right;
  color: var(--primaryBlack);
`;

export const StatisticsTable = () => {
  const { metric, title, months, chartData: data } = useContext(CardChartModalContext);
  const numberFormatter = useCurrencyNumberFormatter();

  return (
    <Flexer gap="12px" direction="column">
      <Flexer gap="12px" alignItems="center">
        <CardChartSectionIcon>
          <InfoIcon width="20px" height="20px" />
        </CardChartSectionIcon>
        <CardChartSectionTitle>Statistics: {title}</CardChartSectionTitle>
      </Flexer>

      <TableContainer data-cy="card-chart-modal__statistics-table">
        <FlexerColumn alignItems="flex-start" width="160px">
          <Label>Month</Label>
          <ValueText>Value</ValueText>
        </FlexerColumn>

        <LineVertical />

        <Flexer style={{ overflow: 'scroll' }}>
          {months.map((month, index) => {
            const currentValue = data[month];
            const previousValue = data[months[index - 1]];

            return (
              <TableColumn key={month}>
                <Label>{reFormatDate(month, 'YYYY-MM', 'MMM YYYY')}</Label>
                <Value>
                  {numberFormatter({ type: metric.dataType, rawValue: currentValue })}
                  <ValuesComparison
                    dataType={metric.dataType}
                    originalValue={currentValue}
                    compareValue={previousValue}
                  />
                </Value>
              </TableColumn>
            );
          })}
        </Flexer>

        <Line />
      </TableContainer>
    </Flexer>
  );
};
