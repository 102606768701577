import axios from 'axios';
import { API_URL } from 'api/consts';

const buildUrl = ({ orgId }) => {
  const baseUrl = `${API_URL}/organizations/${orgId}/exports`;
  return baseUrl;
};

export const getExports = async ({ orgId, type }) => {
  const { data } = await axios.get(buildUrl({ orgId }), { params: { type } });
  return data;
};

export const downloadExportByType = async ({ orgId, type, customization = null }) => {
  const { data } = await axios.post(
    `${buildUrl({ orgId })}/download`,
    { type, customization },
    { responseType: 'blob' },
  );
  return data;
};

export const createExport = async ({ orgId, type, customization }) => {
  const { data } = await axios.post(buildUrl({ orgId }), { type, customization });
  return data;
};
