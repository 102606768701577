import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { PopoverWrapper, TooltipPopover, usePortal } from 'components/Portal';
import { FilterConditionHead } from 'shared/Filters';
import { SelectorItem, StyledSelectorPopover } from 'shared/Filters/styles';

const SecondaryMenuPopover = styled.div`
  position: absolute;
  z-index: 100;
  min-width: ${({ minWidth }) => minWidth ?? '240px'};
  width: ${({ width }) => width ?? 'fit-content'};
  top: ${({ top }) => (top ? `${top + 40}px` : '0px')};
`;

const SelectorOptionWrapper = ({ noOptionsStyles, children, current, ...rest }) =>
  noOptionsStyles ? (
    <div {...rest}>{children}</div>
  ) : (
    <SelectorItem current={current} {...rest}>
      {children}
    </SelectorItem>
  );

const Menu = ({
  noOptionsStyles,
  name,
  minWidth,
  options,
  selected,
  optionStyles,
  onSelect,
  actions,
  secondaryMenu,
}) => {
  const primaryMenuRef = useRef();

  const [primaryMenuDimensions, setPrimaryMenuDimensions] = useState();

  useEffect(() => {
    setTimeout(() => {
      setPrimaryMenuDimensions({
        width: primaryMenuRef.current?.offsetWidth,
        height: primaryMenuRef.current?.offsetHeight,
      });
    });
  }, [primaryMenuRef]);

  return (
    <>
      <StyledSelectorPopover
        noOptionsStyles={noOptionsStyles}
        data-cy={`${name}-selector`}
        minWidth={minWidth}
        ref={primaryMenuRef}
      >
        {Object.entries(options).map(([key, value]) => {
          const valueIsComponent = React.isValidElement(value);

          // can add to array the options that we want to be beta
          const label = !valueIsComponent && ['Seats'].includes(value) ? `${value} (beta)` : value;

          return (
            <SelectorOptionWrapper
              noOptionsStyles={noOptionsStyles}
              key={key}
              current={selected === key || selected === value}
              onClick={() => onSelect(key)}
              data-cy={`${name}-selector--${key}`}
              style={optionStyles}
            >
              {/* pass 'current' state if selector option is a react component instead of string */}
              {valueIsComponent
                ? React.cloneElement(value, {
                    current: selected === key || selected === value,
                  })
                : label}
            </SelectorOptionWrapper>
          );
        })}
        {actions && actions}
      </StyledSelectorPopover>

      {secondaryMenu && (
        <SecondaryMenuPopover
          minWidth={minWidth}
          width={`${primaryMenuDimensions?.width}px`}
          top={primaryMenuDimensions?.height}
        >
          {secondaryMenu}
        </SecondaryMenuPopover>
      )}
    </>
  );
};

export const SelectDropdownButton = ({
  options,
  selected,
  onSelect,
  noOptionsStyles,
  name,
  showSelectedDirectly = false,
  noMargin = false,
  fontWeight = null,
  fontSize = null,
  allCaps = false,
  toolTipContent = null,
  toolTipWidth = null,
  padding = null,
  XOffset,
  actions,
  optionStyles,
  minWidth,
  secondaryMenu,
  ...rest
}) => {
  const { triggerRef, showPortal, hidePortal, isPortalVisible, Portal } = usePortal({ YOffset: 27, XOffset });

  const [showTooltip, setShowTooltip] = useState(false);

  const trigger = showSelectedDirectly ? selected : options[selected];
  const decoratedTrigger = toolTipContent ? (
    <PopoverWrapper>
      <div onMouseEnter={() => setShowTooltip(true)} onMouseOut={() => setShowTooltip(false)}>
        {trigger}
      </div>
      {showTooltip && <TooltipPopover width={toolTipWidth}>{toolTipContent}</TooltipPopover>}
    </PopoverWrapper>
  ) : (
    trigger
  );

  const wrappedSetShowDropDown = (shown) => {
    if (shown) {
      showPortal();
      setShowTooltip(false);
    } else {
      hidePortal();
    }
  };

  return (
    <FilterConditionHead
      onClick={() => wrappedSetShowDropDown(!isPortalVisible)}
      ref={triggerRef}
      data-cy={`${name}-selector-toggler`}
      noMargin={noMargin}
      fontWeight={fontWeight}
      fontSize={fontSize}
      allCaps={allCaps}
      padding={padding}
      {...rest}
    >
      {decoratedTrigger}
      {isPortalVisible && (
        <Portal>
          <Menu
            noOptionsStyles={noOptionsStyles}
            name={name}
            minWidth={minWidth}
            options={options}
            selected={selected}
            optionStyles={optionStyles}
            onSelect={onSelect}
            actions={actions}
            secondaryMenu={secondaryMenu}
          />
        </Portal>
      )}
    </FilterConditionHead>
  );
};
