import styled, { css } from 'styled-components';

export const SidebarWrapper = styled.aside`
  position: relative;
  overflow-y: scroll;
  padding: 20px 24px;
  border-top-right-radius: 20px;
  width: 336px;
  border-left: 1px solid var(--primaryBlack5);
  background-color: var(--accentGrayFourth);

  ${(props) =>
    props.pageView
      ? css`
          @media (max-width: 1599px) {
            display: ${(props) => !props.floating && 'none'};
            height: 100%;
          }
        `
      : css`
          @media (max-width: 1439px) {
            display: ${(props) => !props.floating && 'none'};
            height: 100%;
          }
        `}
`;

export const SidebarTabs = styled.ul`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  margin-bottom: 21px;
  ${({ noMinWidth }) => (noMinWidth ? '' : 'min-width: 272px;')}
  width: 100%;
  list-style: none;
`;
