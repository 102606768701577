import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import styled from 'styled-components';

import { Loader } from 'components/Loaders';
import { FlexerColumn } from 'components/Core';
import { WarnBlock } from 'components/Blocks';
import { TooltipContainer } from 'components/Tooltip';
import { RefreshIcon } from 'components/Icons';
import { InvoicePreviewCard, PDFWrapper } from 'views/Billing/InvoicePreviewModalV2/styles';

// https://github.com/wojtekmaj/react-pdf/wiki/Upgrade-guide-from-version-5.x-to-6.x#textlayer-css-must-now-be-manually-imported
import 'react-pdf/dist/Page/TextLayer.css';
// https://github.com/wojtekmaj/react-pdf?tab=readme-ov-file#support-for-annotations
import 'react-pdf/dist/Page/AnnotationLayer.css';
// https://github.com/wojtekmaj/react-pdf/wiki/Known-issues#create-react-app
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const RefreshContainer = styled.div`
  & svg {
    width: 12px;
    height: 12px;

    path {
      fill: var(--primaryBlue);
    }

    &:hover {
      cursor: pointer;
    }
  }
`;

export const CreditNotePDFPreviewContents = ({
  creditNotePdf,
  onRegenerate,
  isPdfLoading,
  width,
  height,
  pdfWidth,
}) => {
  const [totalPages, setTotalPages] = useState(1);

  return (
    <FlexerColumn gap="8px" height="100%">
      {creditNotePdf?.sent_at ? (
        <WarnBlock
          rightIcon={
            <RefreshContainer>
              <TooltipContainer toolTipContent="Regenerate Credit Note PDF" width={200} fontSize="12px">
                <RefreshIcon onClick={onRegenerate} />
              </TooltipContainer>
            </RefreshContainer>
          }
        >
          {creditNotePdf?.pdf_link
            ? 'This is the last version sent to the customer'
            : 'Displaying the most recent version. A previous version was sent to the customer'}
        </WarnBlock>
      ) : null}
      <InvoicePreviewCard width={width} height={height}>
        <PDFWrapper>
          {isPdfLoading ? (
            <Loader containerStyles={{ width: 40 }} />
          ) : creditNotePdf?.pdf ? (
            <Document
              file={`data:application/pdf;base64,${creditNotePdf.pdf}`}
              onLoadSuccess={({ numPages }) => {
                setTotalPages(numPages);
              }}
            >
              {Array.from(new Array(totalPages || 0), (el, index) => (
                <Page key={`page_${index + 1}`} pageNumber={index + 1} width={pdfWidth ?? 340} />
              ))}
            </Document>
          ) : (
            'No PDF preview available. Save the credit note to generate a PDF.'
          )}
        </PDFWrapper>
      </InvoicePreviewCard>
    </FlexerColumn>
  );
};
