import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Container } from 'components/Core';
import { GlobalTogglesContextProvider } from 'shared/GlobalToggles';
import { Filters } from 'shared/Filters';
import { TRANSACTION_TABLE_COLUMN_KEY } from 'shared/TransactionTable';
import { GROUP_BY_OPTIONS } from 'shared/TransactionTable/consts';
import { TransactionsPage } from 'views/Transactions/TransactionsPage';
import { PAGE_CONTEXT_VALUES, usePageContext, useStateWithStorage, useUrlQuery } from 'utils/hooks';
import { stringToBoolean } from 'utils/stringUtils';
import { TRANSACTIONS_PAGES_ROUTES } from './consts';
import { TransactionsContext } from './TransactionsContext';

export const TransactionsContainer = () => {
  const query = useUrlQuery();
  // it's needed to open bulk confirm from navbar "Unconfirmed data" link
  const openLockModal = query.get('openLockModal');

  const [showTransactionLockModal, setShowTransactionLockModal] = useState(false);
  const [showAccountingSpreadsLockModal, setShowAccountingSpreadsLockModal] = useState(false);

  const [showTransactionInvoicesPreviewModal, setShowTransactionInvoicesPreviewModal] = useState(false);

  useEffect(() => {
    setShowTransactionLockModal(stringToBoolean(openLockModal) ?? false);
  }, [openLockModal]);

  const { urlState } = usePageContext({
    page: PAGE_CONTEXT_VALUES.transactions.key,
  });

  const { dataFilter, setDataFilter, metadataFilter, setMetadataFilter } = urlState;

  // Check if we are on the Automatic Confirmation Page
  const isAutoConfirmView = useLocation().pathname === TRANSACTIONS_PAGES_ROUTES.TRANSACTIONS_AUTOMATIC_CONFIRMATIONS;

  // Check if we are on the Archived Transactions Page
  const isArchivedView = useLocation().pathname === TRANSACTIONS_PAGES_ROUTES.TRANSACTIONS_ARCHIVED;

  const hiddenTransactionsColumnsKey = isAutoConfirmView
    ? 'automatic-confirmation-page-hidden-columns'
    : 'transaction-table-hidden-columns';
  const hiddenTransactionsColumnsInitialValue = isAutoConfirmView
    ? [
        TRANSACTION_TABLE_COLUMN_KEY.ID,
        TRANSACTION_TABLE_COLUMN_KEY.STATUS,
        TRANSACTION_TABLE_COLUMN_KEY.EXTERNAL_ID,
        TRANSACTION_TABLE_COLUMN_KEY.SEATS,
        TRANSACTION_TABLE_COLUMN_KEY.LEFT_TO_RECOGNIZE,
        TRANSACTION_TABLE_COLUMN_KEY.UPDATED_AT,
        TRANSACTION_TABLE_COLUMN_KEY.CREATED_AT,
      ]
    : [
        TRANSACTION_TABLE_COLUMN_KEY.ID,
        TRANSACTION_TABLE_COLUMN_KEY.EXTERNAL_ID,
        TRANSACTION_TABLE_COLUMN_KEY.SEATS,
        TRANSACTION_TABLE_COLUMN_KEY.LEFT_TO_RECOGNIZE,
        TRANSACTION_TABLE_COLUMN_KEY.UPDATED_AT,
        TRANSACTION_TABLE_COLUMN_KEY.CREATED_AT,
      ];
  const [hiddenTransactionColumns, setHiddenTransactionColumns] = useStateWithStorage(
    hiddenTransactionsColumnsKey,
    hiddenTransactionsColumnsInitialValue,
  );

  return (
    <TransactionsContext.Provider
      value={{
        ...urlState,
        showTransactionInvoicesPreviewModal,
        setShowTransactionInvoicesPreviewModal,
      }}
    >
      <GlobalTogglesContextProvider urlState={urlState}>
        <Container>
          <TransactionsPage
            hasListPageContext
            showTransactionLockModal={showTransactionLockModal}
            setShowTransactionLockModal={setShowTransactionLockModal}
            showAccountingSpreadsLockModal={showAccountingSpreadsLockModal}
            setShowAccountingSpreadsLockModal={setShowAccountingSpreadsLockModal}
            hiddenTransactionColumns={hiddenTransactionColumns}
            setHiddenTransactionColumns={setHiddenTransactionColumns}
            isAutoConfirmView={isAutoConfirmView}
            isArchivedView={isArchivedView}
            transactionsGroupBy={isAutoConfirmView ? GROUP_BY_OPTIONS.CONFIRMED_AT : GROUP_BY_OPTIONS.NONE}
            initialSortBy={
              isAutoConfirmView
                ? [
                    {
                      id: 'confirmed_at',
                      desc: true,
                    },
                  ]
                : undefined
            }
            filtersBar={
              <Filters
                setDataFilter={setDataFilter}
                metadataFilter={metadataFilter}
                setMetadataFilter={setMetadataFilter}
                hidePeriodSelection
                dataFilter={dataFilter}
                hideTimeSelection
                showTransactionsSettings
                showConditions
              />
            }
          />
        </Container>
      </GlobalTogglesContextProvider>
    </TransactionsContext.Provider>
  );
};
