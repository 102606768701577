import { isEmpty } from 'lodash';
import { Spacer } from 'components/Core';
import { SplashBannerDetails } from './SplashBannerDetails';
import { NewRevenueFromPreviousMonthsTable } from './NewRevenueFromPreviousMonthsTable';
import { ForecastedExistingCustomersTable } from './ForecastedExistingCustomersTable';
import { ForecastModalBody } from '../Shared/ForecastModalLayout';
import { EmptyCustomersTable } from '../Shared/EmptyCustomersTable';
import { EXISTING_CUSTOMERS_FORECAST_TYPE } from '../consts';
import { EXISTING_CUSTOMERS_TABLE_TYPE } from './consts';

export const ForecastExistingBody = ({ scenarioId, data, month, editForecastDetails, onNewRevenueDetailsClick }) => {
  const monthData = data?.customers_existing ?? {};
  const existingForecastType = monthData.forecast_type ?? EXISTING_CUSTOMERS_FORECAST_TYPE.NRR;

  return (
    <>
      <SplashBannerDetails upsell={monthData.upsell} downsell={monthData.downsell} churn={monthData.churn} />
      <ForecastModalBody>
        {!isEmpty(data) && monthData?.customers.length > 0 ? (
          <>
            {existingForecastType === EXISTING_CUSTOMERS_FORECAST_TYPE.PIPELINE && (
              <>
                <ForecastedExistingCustomersTable
                  forecastId={scenarioId}
                  month={month}
                  customersData={monthData?.customers}
                  existingForecastType={existingForecastType}
                  tableType={EXISTING_CUSTOMERS_TABLE_TYPE.PIPELINE_RENEWAL}
                  editForecastDetails={editForecastDetails}
                />

                <ForecastedExistingCustomersTable
                  forecastId={scenarioId}
                  month={month}
                  customersData={monthData?.customers}
                  existingForecastType={existingForecastType}
                  tableType={EXISTING_CUSTOMERS_TABLE_TYPE.PIPELINE_UPSELL}
                  editForecastDetails={editForecastDetails}
                />
              </>
            )}

            {/*Table with under contract customers up for renewal in month*/}
            <ForecastedExistingCustomersTable
              forecastId={scenarioId}
              month={month}
              customersData={monthData?.customers}
              existingForecastType={existingForecastType}
              tableType={EXISTING_CUSTOMERS_TABLE_TYPE.UP_FOR_RENEWAL}
              editForecastDetails={editForecastDetails}
            />

            <Spacer height="24px" />

            {/*Table with under contract customers in month*/}
            <ForecastedExistingCustomersTable
              forecastId={scenarioId}
              month={month}
              customersData={monthData?.customers}
              existingForecastType={existingForecastType}
              tableType={EXISTING_CUSTOMERS_TABLE_TYPE.UNDER_CONTRACT}
              editForecastDetails={editForecastDetails}
            />
          </>
        ) : (
          <EmptyCustomersTable month={month} entityName="existing customers" />
        )}

        <Spacer height="24px" />

        <NewRevenueFromPreviousMonthsTable
          scenarioId={scenarioId}
          previousMonths={monthData?.previous_months}
          month={month}
          onNewRevenueDetailsClick={onNewRevenueDetailsClick}
        />
      </ForecastModalBody>
    </>
  );
};
