import React, { useCallback, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { AppContext } from 'AppContext';
import { useReportsAPI } from 'api/reports';
import { Centerer, Container, FlexBetweenContainer, Flexer, Spacer } from 'components/Core';
import {
  Modal,
  ModalBody,
  ModalButton,
  ModalCloseIcon,
  ModalContainer,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTitleText,
} from 'components/Modal';
import { FormikCustomInput } from 'components/Controls';
import { useToasts } from 'components/Toasts';
import { RemoveButton } from 'components/Buttons';
import { CircleLoader } from 'components/Loaders';

const EditReportNameModal = ({ onClose, report, setReport, pageReportState }) => {
  const history = useHistory();
  const { orgId } = useContext(AppContext);
  const {
    operations: { createReport, updateReport, removeReport },
  } = useReportsAPI({
    orgId,
    autoFetch: false,
  });

  const { pushError } = useToasts();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (data) => {
    setLoading(true);
    try {
      if (report.id) {
        const updatedReport = await updateReport({
          orgId,
          reportId: report.id,
          report: {
            ...report,
            title: data.title,
            options: pageReportState,
          },
        });
        setReport(updatedReport);
      } else {
        const createdReport = await createReport({
          orgId,
          reportId: report.id,
          report: {
            ...report,
            title: data.title,
            options: pageReportState,
          },
        });
        setReport(createdReport);
      }

      onClose();
    } catch (e) {
      pushError(e, 'Could not not update the report');
      console.error({ message: e.message, component: 'ReportBuilderHeader.js', stack: e });
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    setLoading(true);
    try {
      onClose();
      history.push('/');
      if (report.id) {
        await removeReport({
          orgId,
          reportId: report.id,
        });
      }
    } catch (e) {
      pushError(e, 'Could not not remove the report');
      console.error({ message: e.message, component: 'ReportBuilderHeader.js', stack: e });
      setLoading(false);
    }
  };

  return (
    <>
      <ModalContainer data-cy="edit-report-name-modal">
        <Modal maxWidth="440PX" height="auto">
          <ModalHeader>
            <ModalTitle>
              <ModalTitleText>
                <b>Edit Saved Report</b>
              </ModalTitleText>
            </ModalTitle>
            <ModalCloseIcon onClose={onClose} />
          </ModalHeader>

          <Spacer height="20px" />

          {loading ? (
            <ModalBody>
              <Centerer width="100%">
                <CircleLoader />
              </Centerer>
            </ModalBody>
          ) : (
            <Formik
              initialValues={report}
              validationSchema={Yup.object({
                title: Yup.string().nullable().required('Name is required'),
              })}
              onSubmit={handleSubmit}
            >
              {({ handleSubmit }) => (
                <Form>
                  <ModalBody>
                    <Container>
                      <FormikCustomInput
                        data-cy="edit-report-name-modal__title-input"
                        name="title"
                        label="Name"
                        placeholder="Enter report name..."
                        type="text"
                      />
                    </Container>
                  </ModalBody>

                  <Spacer height="20px" />

                  <ModalFooter>
                    <FlexBetweenContainer centerer>
                      <RemoveButton onClick={handleDelete}>Remove</RemoveButton>
                      <Flexer>
                        <ModalButton onClick={onClose}>Cancel</ModalButton>
                        <ModalButton data-cy="edit-report-name-modal__save-button" primary onClick={handleSubmit}>
                          Save
                        </ModalButton>
                      </Flexer>
                    </FlexBetweenContainer>
                  </ModalFooter>
                </Form>
              )}
            </Formik>
          )}
        </Modal>
      </ModalContainer>
    </>
  );
};

export const useEditReportTitleModal = (props) => {
  const [showModal, setShowModal] = useState(false);

  const openModal = useCallback(() => {
    setShowModal(true);
  }, [setShowModal]);

  const Modal = useCallback(
    () => (showModal ? <EditReportNameModal {...props} onClose={() => setShowModal(false)} /> : null),
    [showModal, setShowModal, props],
  );

  return {
    openModal,
    Modal,
  };
};
