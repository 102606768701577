import { useContext } from 'react';
import { useFormikContext } from 'formik';
import { Link } from 'react-router-dom';
import { Checkbox } from 'antd';

import { ReactComponent as ExternalLink } from 'images/external-link-full.svg';
import { RECOGNITION_TYPES } from 'consts/global';
import { FormikCustomSelector } from 'components/Controls';
import { Row } from 'components/Core';
import { TooltipContainer, TruncateStringWithTooltip } from 'components/Tooltip';
import { TransactionContext } from 'shared/TransactionContent/TransactionContext';
import { ItemsRow } from 'shared/TransactionContent/styles';
import { AppContext } from 'AppContext';

import {
  ReplaceTransactionWrapper,
  ReplacingTransactionAdditionalInfo,
  relatedSelectStyles,
  StyledRelatedHelpIcon,
} from './styles';
import { formatOptionLabel } from './utils';

export const ReplaceTransaction = () => {
  const { setFieldValue } = useFormikContext();
  const { formatDateWithLocale } = useContext(AppContext);
  const {
    currentTransaction,
    replacingChecked,
    setReplacingChecked,
    relatedTransactions,
    transactionFormValues,
  } = useContext(TransactionContext);

  return (
    <ReplaceTransactionWrapper>
      <Row horizontal="space-between">
        <Checkbox
          style={{ fontSize: '12px' }}
          onChange={(event) => {
            setFieldValue('replaced_transactions', null);
            setReplacingChecked(event.target.checked);
          }}
          checked={replacingChecked || transactionFormValues?.replaced_transactions?.length > 0}
          disabled={!!currentTransaction.replaced_by}
          data-cy="transaction-modal_related-transactions-checkbox"
        >
          This transaction replaces other transactions
        </Checkbox>

        <TooltipContainer width={156} toolTipContent="Replaced transactions must have same customer">
          <StyledRelatedHelpIcon />
        </TooltipContainer>
      </Row>

      {(replacingChecked || transactionFormValues?.replaced_transactions?.length > 0) && (
        <>
          <ItemsRow style={{ marginTop: 12, marginBottom: 0 }}>
            <FormikCustomSelector
              formatOptionLabel={formatOptionLabel}
              placeholder="Please select transaction"
              styles={relatedSelectStyles}
              isClearable={true}
              isMulti={true}
              handleChange={(transaction) => {
                if (transaction) {
                  setFieldValue('replaced_transactions', transaction);
                } else {
                  setFieldValue('replaced_transactions', null);
                }
              }}
              options={relatedTransactions.map((transaction) => ({
                value: transaction.id,
                label: transaction.name,
                ...transaction,
              }))}
              value={
                transactionFormValues?.replaced_transactions?.length
                  ? transactionFormValues?.replaced_transactions.map((v) => ({
                      label: v.name,
                      value: v.id,
                      ...v,
                    }))
                  : null
              }
              name="replaced_transactions"
            />
          </ItemsRow>
          {transactionFormValues?.replaced_transactions?.length
            ? transactionFormValues?.replaced_transactions.map((t) => (
                <ReplacingTransactionAdditionalInfo
                  data-cy="transaction-modal_related-transactions-replacing-transaction-info"
                  key={t.id}
                >
                  <span>
                    <TruncateStringWithTooltip length={10} tooltipWidth={200}>
                      {t.name}: {formatDateWithLocale(t.start_date)} -{' '}
                      {t.end_date
                        ? formatDateWithLocale(t.end_date)
                        : t.recognition === RECOGNITION_TYPES.tillCanceled
                        ? 'Till Canceled'
                        : 'No end date'}
                    </TruncateStringWithTooltip>
                  </span>
                  <Link to={`/transactions/${t.id}`} target="_blank">
                    <div>${t.amount}</div>
                    <ExternalLink />
                  </Link>
                </ReplacingTransactionAdditionalInfo>
              ))
            : null}
        </>
      )}
    </ReplaceTransactionWrapper>
  );
};
