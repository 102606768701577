import { RECOGNITION_TYPES } from 'consts/global';
import { calculateRecurringAmount } from 'models/transaction';

export const addRecRevToCustomers = ({ transactionsForTable }) => {
  return transactionsForTable.map((transaction) => {
    if (transaction.recurring_amount !== null) return transaction;

    // The rest below is basically for backward compatibility since old linear transactions may not have a recurring_amount field set
    const typeValue = [RECOGNITION_TYPES.linear, RECOGNITION_TYPES.tillCanceled].includes(transaction.recognition)
      ? 'recurring'
      : 'one-time';
    if (typeValue === 'recurring') {
      return {
        ...transaction,
        recurring_amount: calculateRecurringAmount({
          startDate: transaction.start_date,
          endDate: transaction.end_date,
          totalAmount: transaction.amount,
          includeEndMonth: transaction.include_end_month,
        }),
      };
    } else {
      return { ...transaction, recurring_amount: 0 };
    }
  });
};

export const buildCustomersHierarchyTree = ({ customers, selectedCustomer }) => {
  const customersTree = [];

  //Add all parents
  const addAllParents = (currentCustomer) => {
    const parentCustomer = customers.find((parentCustomer) => parentCustomer.id === currentCustomer.parent_customer_id);
    if (parentCustomer) {
      customersTree.unshift({ ...parentCustomer, parentCustomer: true });
      addAllParents(parentCustomer);
    }
  };

  addAllParents(selectedCustomer);

  customersTree.push({ ...selectedCustomer, selectedCustomer: true });

  //Add all parents
  const addAllChilds = (currentCustomer) => {
    customers.forEach((customer) => {
      if (customer.parent_customer_id && customer.parent_customer_id === currentCustomer.id) {
        customersTree.push({ ...customer, childCustomer: true });
        addAllChilds(customer);
      }
    });
  };

  addAllChilds(selectedCustomer);

  return customersTree;
};

export const addChildTagToCustomers = ({ transactionsForTable, customerId }) => {
  //Add child tag
  transactionsForTable.forEach((tableCustomer) => {
    if (tableCustomer.group_customer_id !== customerId) {
      tableCustomer.childCustomer = true;
    }
  });
};

export const addPercentage = ({ transactionsForTable, transactionSum }) => {
  return transactionsForTable.map((transaction) => {
    return { ...transaction, percentage: transaction.amount / transactionSum };
  });
};

export const getDataForSnapshot = ({ customer, isARR }) => {
  const revenueMetrics = {};
  for (const [month, monthMetrics] of Object.entries(customer?.waterfall ?? {})) {
    for (const [metric, metricValue] of Object.entries(monthMetrics)) {
      revenueMetrics[month] = revenueMetrics[month] ?? {};
      revenueMetrics[month][metric] = isARR ? metricValue * 12 : metricValue;
    }
  }

  return revenueMetrics;
};
