import styled from 'styled-components';
import { ReactComponent as _LightbulbIcon } from 'images/insights-bulb.svg';

export const LightbulbIcon = styled(_LightbulbIcon)`
  width: ${({ size }) => size ?? '20px'};
  height: ${({ size }) => size ?? '20px'};
  margin: ${({ margin }) => margin ?? '4px'};

  stroke: ${({ stroke }) => stroke};
  path {
    fill: ${({ fill }) => fill};
  }
`;
