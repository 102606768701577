import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useToasts } from 'components/Toasts';
import {
  bulkUpdateReceipts as _bulkUpdateReceipts,
  getReceipt,
  getReceipts,
  createReceipt as _createReceipt,
  updateReceipt as _updateReceipt,
  sendReceipt as _sendReceipt,
} from './requests';

export const CACHE_KEY = 'billing';

export const useReceiptAPI = ({ orgId, autoFetch = true, receiptId, enableToasts = true }) => {
  const dataKey = [CACHE_KEY, orgId, 'receipts', receiptId];

  const { pushToast: wrappedPushToast, pushError: wrappedPushError } = useToasts();
  const pushToast = (...args) => {
    if (enableToasts) wrappedPushToast(...args);
  };
  const pushError = (...args) => {
    if (enableToasts) wrappedPushError(...args);
  };
  const queryClient = useQueryClient();

  const { data, error, isLoading, isFetching, refetch } = useQuery(
    dataKey,
    () =>
      getReceipt({
        orgId,
        id: receiptId,
      }),
    { enabled: autoFetch && !!orgId && !!receiptId },
  );

  const createReceipt = useMutation((data) => _createReceipt({ orgId, body: data }), {
    onError: (err) => {
      pushError(err, 'Failed to create receipt');
    },

    onSuccess: () => {
      pushToast('Successfully created receipt!', 'success');
    },

    onSettled: () => {
      queryClient.invalidateQueries(dataKey);
      queryClient.invalidateQueries(CACHE_KEY);
    },
  });

  const updateReceipt = useMutation(({ data, receiptId }) => _updateReceipt({ orgId, body: data, id: receiptId }), {
    onError: (err) => {
      pushError(err, 'Failed to update receipt');
    },

    onSuccess: () => {
      pushToast('Successfully updated receipt!', 'success');
    },

    onSettled: () => {
      queryClient.invalidateQueries(dataKey);
      queryClient.invalidateQueries(CACHE_KEY);
    },
  });

  const sendReceipt = useMutation(({ data, params }) => _sendReceipt({ orgId, body: data, params }), {
    onError: (err) => {
      pushError(err, 'Failed to send receipt');
    },

    onSuccess: () => {
      pushToast('Successfully sent receipt!', 'success');
    },

    onSettled: () => {
      queryClient.invalidateQueries(dataKey);
      queryClient.invalidateQueries(CACHE_KEY);
    },
  });

  const bulkUpdateReceipts = useMutation(
    ({ data, filters }) => _bulkUpdateReceipts({ orgId, body: data, params: filters }),
    {
      onError: (err) => {
        pushError(err, 'Failed to update receipts');
      },

      onSuccess: () => {
        pushToast('Successfully updated receipts!', 'success');
      },

      onSettled: () => {
        queryClient.invalidateQueries(dataKey);
        queryClient.invalidateQueries(CACHE_KEY);
      },
    },
  );

  return {
    data,
    error,
    isLoading,
    isFetching,
    operations: {
      refetch,
      createReceipt: createReceipt.mutateAsync,
      bulkUpdateReceipts: bulkUpdateReceipts.mutateAsync,
      updateReceipt: updateReceipt.mutateAsync,
      sendReceipt: sendReceipt.mutateAsync,
    },
  };
};

export const useReceiptsAPI = ({ orgId, autoFetch = true, filters }) => {
  const dataKey = [CACHE_KEY, orgId, 'receipts'];

  const { data, error, isLoading, isFetching, refetch } = useQuery(
    dataKey,
    () =>
      getReceipts({
        orgId,
        params: filters,
      }),
    { enabled: autoFetch && !!orgId },
  );

  return {
    data,
    error,
    isLoading,
    isFetching,
    operations: {
      refetch,
    },
  };
};
