import React, { useState, useMemo } from 'react';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { ReactComponent as QuestionIcon } from 'images/related-help.svg';
import { TooltipContainer } from 'components/Tooltip';
import { SwitchWithLabel } from 'components/Controls';
import { Centerer, Flexer, FlexerColumn } from 'components/Core';
import { useClickOutside } from 'utils/hooks';
import { Popover, PopoverItemsContainer, PopoverListItem, PopoverWrapper } from 'components/Portal';

const SwitchOptionContainer = styled.div`
  padding: 6px 12px;
  display: flex;
  flex-direction: column;

  & svg {
    margin-right: 0px;
  }

  &:hover {
    & svg {
      g {
        opacity: 1;
      }
    }
  }
`;

const StyledQuestionIcon = styled(QuestionIcon)`
  margin-left: auto;
`;

const SelectPastDateOptionsWrapper = styled(Centerer)`
  padding: 2px 4px;
  background-color: var(--primaryBlue20);
  color: var(--primaryBlue);
  font-size: 12px;
  font-weight: 700;
  border-radius: 4px;

  &:hover {
    cursor: pointer;
  }
`;

const StyledPastDatePopoverListItem = styled(PopoverListItem)`
  white-space: pre-wrap;
  background-color: ${({ selected }) => selected && 'var(--primaryBlack3)'};
`;

const StyledSelectPastDatePopover = styled(Popover)`
  top: 30px;
`;

const SelectPastDateOption = ({ regenerateAfterExistingInvoice, setRegenerateAfterExistingInvoice }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const triggerRef = useClickOutside(() => setShowDropdown(false));

  const options = [
    { value: false, label: 'Today' },
    { value: true, label: 'After the most recent existing inv.' },
  ];

  return (
    <Flexer wrapRow alignItems="center" gap="4px">
      <span>Date past invoices for</span>
      <PopoverWrapper ref={triggerRef}>
        <SelectPastDateOptionsWrapper onClick={() => setShowDropdown(true)}>
          {options.find((option) => option.value === regenerateAfterExistingInvoice).label.toLowerCase()}
        </SelectPastDateOptionsWrapper>

        {showDropdown && (
          <StyledSelectPastDatePopover>
            <PopoverItemsContainer>
              {options.map((option) => (
                <StyledPastDatePopoverListItem
                  key={option.label}
                  onClick={() => {
                    setShowDropdown(false);
                    setRegenerateAfterExistingInvoice(option.value);
                  }}
                  selected={option.value === regenerateAfterExistingInvoice}
                >
                  {option.label}
                </StyledPastDatePopoverListItem>
              ))}
            </PopoverItemsContainer>
          </StyledSelectPastDatePopover>
        )}
      </PopoverWrapper>
    </Flexer>
  );
};

export const usePastAndFutureInvoiceOptions = ({
  includedTransactions,
  otherOptions = [],
  hasInvoicesToNotRegenerate,
  regenerateAfterExistingInvoice,
  setRegenerateAfterExistingInvoice,
  onSwitchChange,
  optionValues,
}) => {
  const [_onlyFutureInvoices, setOnlyFutureInvoices] = useState(false);
  const [_allowPastInvoices, setAllowPastInvoices] = useState(true); //default is to backdate

  const onlyFutureInvoices = optionValues?.onlyFutureInvoices ?? _onlyFutureInvoices;
  const allowPastInvoices = optionValues?.allowPastInvoices ?? _allowPastInvoices;

  const today = dayjs.utc();
  const hasInvoicesThatStartInThePast = includedTransactions.some((transaction) =>
    today.isAfter(transaction.start_date, 'day'),
  );
  const hasInvoicesInTheFuture = includedTransactions.some((transaction) =>
    !transaction.end_date
      ? !today.isBefore(transaction.start_date, 'day')
      : today.isSameOrBefore(transaction.end_date, 'day'),
  );

  const additionalOptions = useMemo(
    () => [
      {
        label: 'Create only future invoices',
        onChange: () => {
          const newOnlyFutureInvoices = !onlyFutureInvoices;
          const newAllowPastInvoices = !onlyFutureInvoices || allowPastInvoices;

          setOnlyFutureInvoices(newOnlyFutureInvoices);
          setAllowPastInvoices(newAllowPastInvoices);
          onSwitchChange &&
            onSwitchChange({
              onlyFutureInvoices: newOnlyFutureInvoices,
              allowPastInvoices: newAllowPastInvoices,
            });
        },
        checked: onlyFutureInvoices,
        tooltipInfo: 'Ignore past invoices in the schedule',
        name: 'billing__invoice-schedule-future-invoices',
        show: hasInvoicesInTheFuture,
        disabled: !allowPastInvoices,
      },
      {
        label: hasInvoicesToNotRegenerate ? (
          <SelectPastDateOption
            regenerateAfterExistingInvoice={regenerateAfterExistingInvoice}
            setRegenerateAfterExistingInvoice={setRegenerateAfterExistingInvoice}
          />
        ) : (
          'Date past invoices for today'
        ),
        onChange: () => {
          const newAllowPastInvoices = !allowPastInvoices;
          const newOnlyFutureInvoices = newAllowPastInvoices ? false : onlyFutureInvoices;

          setAllowPastInvoices(newAllowPastInvoices);
          setOnlyFutureInvoices(newOnlyFutureInvoices);
          onSwitchChange &&
            onSwitchChange({
              onlyFutureInvoices: newOnlyFutureInvoices,
              allowPastInvoices: newAllowPastInvoices,
            });
        },
        checked: !allowPastInvoices,
        tooltipInfo: 'Some transactions start in the past. Do you want to date invoices for them to today?',
        name: 'billing__invoice-schedule-todays-date',
        show: true,
        disabled: onlyFutureInvoices && !hasInvoicesToNotRegenerate,
      },
      ...otherOptions,
    ],
    [
      onlyFutureInvoices,
      hasInvoicesInTheFuture,
      allowPastInvoices,
      hasInvoicesToNotRegenerate,
      regenerateAfterExistingInvoice,
      setRegenerateAfterExistingInvoice,
      otherOptions,
      onSwitchChange,
    ],
  );

  // Component
  const PastAndFutureInvoiceOptions = useMemo(
    () => (
      <FlexerColumn>
        {additionalOptions.map((option) =>
          option.show ? (
            <SwitchOptionContainer key={option.name}>
              <SwitchWithLabel
                name={option.name}
                onChange={option.onChange}
                checked={option.checked}
                label={option.label}
                disabled={option.disabled}
                labelSize="12px"
                icon={
                  <TooltipContainer
                    key={option.name}
                    toolTipContent={option.tooltipInfo}
                    width={180}
                    fontSize="12px"
                    hideArrow
                    tooltipWrapperStyles={{
                      display: 'flex',
                      marginLeft: 'auto',
                    }}
                  >
                    <StyledQuestionIcon />{' '}
                  </TooltipContainer>
                }
              />
            </SwitchOptionContainer>
          ) : null,
        )}
      </FlexerColumn>
    ),
    [additionalOptions],
  );

  return {
    PastAndFutureInvoiceOptions,
    onlyFutureInvoices,
    allowPastInvoices,
    hasInvoicesThatStartInThePast,
    hasInvoicesInTheFuture,
    elementsShown: additionalOptions.filter((option) => option.show).length,
  };
};
