import { useContext, useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { createCustomer } from 'api';
import { useCustomerAPI } from 'api/customers';
import { getCustomer } from 'api/customers/requests';
import { AppContext } from 'AppContext';
import { ReactComponent as ExternalLink } from 'images/external-link-full.svg';
import { CentererVertical, Row } from 'components/Core';
import { FormikCustomSelector } from 'components/Controls';
import { getCustomerDisplayName } from 'models/customer';
import { SubaccountActionModal } from './SubaccountActionModal';
import { SubaccountButton, ParentCustomerName } from './TransactionCustomerField.styles';
import { TransactionContext } from '../TransactionContext';
import { StyledCircleXIcon, StyledEditCircle } from '../styles';
import { getCustomersFromSearch, getOriginalField, SYMBOL_getOriginalField } from '../utils';
import { TRANSACTION_MODAL_MODE } from '../consts';

export const TransactionCustomerField = () => {
  const {
    appSettings: { isCommitted },
  } = useContext(AppContext);
  const {
    currentTransaction,
    transactionFormValues,
    fetchRelatedTransactionsRef,
    organization,
    mode,
    changedDataFormatted,
    setCustomer,
  } = useContext(TransactionContext);
  const { setFieldValue } = useFormikContext();
  const [customerParentAccountName, setCustomerParentAccountName] = useState(currentTransaction.parent_customer_name);
  const [showSubaccontActionModal, setShowSubaccontActionModal] = useState(false);

  const { data } = useCustomerAPI({
    orgId: organization.id,
    customerId: transactionFormValues?.customer_id,
    filters: {
      scopes: [
        'current_and_previous_MRR_and_customer_lifetime',
        'customer_monthly_MRR',
        'transactions',
        'transactions_spreads',
      ],
      isCommitted,
    },
    enabled: !!transactionFormValues?.customer_id,
  });

  const fetchParentCustomeName = async ({ customerId }) => {
    const fullCustomerData = await getCustomer({ orgId: organization.id, customerId });
    setCustomerParentAccountName(fullCustomerData?.parent_customer_name);
  };

  useEffect(() => {
    setCustomer(data);
  }, [data, setCustomer]);

  return (
    <>
      <div style={{ position: 'relative' }}>
        {transactionFormValues?.customer_id && (
          <SubaccountButton
            hasParentCustomer={!!customerParentAccountName}
            data-cy="add-subaccount-button"
            onClick={() =>
              !!customerParentAccountName ? setCustomerParentAccountName(null) : setShowSubaccontActionModal(true)
            }
          >
            {!!customerParentAccountName ? 'Deattach subaccount' : 'Add subaccount'}
          </SubaccountButton>
        )}
        <FormikCustomSelector
          key={transactionFormValues?.customer_id}
          boldValue
          creatable
          customBorderRadius={!!customerParentAccountName && '8px 8px 0px 0px'}
          label={
            transactionFormValues?.customer_id ? (
              <CentererVertical>
                <span>Customer</span>
                <ExternalLink
                  style={{ pointerEvents: 'all' }}
                  onClick={() => window.open(`/customers/${transactionFormValues.customer_id}`, '_blank')}
                />
              </CentererVertical>
            ) : (
              'Customer'
            )
          }
          placeholder="Select customer"
          value={
            transactionFormValues?.customer_id
              ? {
                  label: getCustomerDisplayName({
                    customerName: transactionFormValues?.customer_name,
                    customerId: transactionFormValues?.customer_id,
                    customerEmail: transactionFormValues?.customer_email,
                  }),
                  value: transactionFormValues?.customer_id,
                }
              : null
          }
          name="customer_id"
          handleChange={(option) => {
            if (option) {
              setFieldValue('customer_id', option.value);
              setFieldValue('customer_name', option.label);
              fetchParentCustomeName({ customerId: option.value });
              fetchRelatedTransactionsRef.current?.({ customerId: option.value });
            } else {
              setFieldValue('customer_id', null);
              setFieldValue('customer_name', null);
              setCustomerParentAccountName(null);
            }
          }}
          isChanged={
            mode === TRANSACTION_MODAL_MODE.EXTERNAL_UPDATE
              ? getOriginalField({ key: 'customer_name', data: changedDataFormatted }) !== SYMBOL_getOriginalField
              : undefined
          }
          tooltipInputDisplay={
            mode === TRANSACTION_MODAL_MODE.EXTERNAL_UPDATE ? (
              getOriginalField({ key: 'customer_name', data: changedDataFormatted }) !== SYMBOL_getOriginalField ? (
                <>
                  <div>Old value:</div>
                  <div>
                    {getOriginalField({ key: 'customer_name', data: changedDataFormatted }) || 'No previous value'}
                  </div>
                </>
              ) : null
            ) : undefined
          }
          onCreateOption={async (newCustomerName) => {
            const newCustomer = await createCustomer({
              orgId: organization.id,
              customerName: newCustomerName,
            });
            setFieldValue('customer_id', newCustomer.id);
            setFieldValue('customer_name', newCustomer.name);
          }}
          loadOptions={(searchQuery, prevOptions, additional) =>
            getCustomersFromSearch({ searchQuery, orgId: organization.id, prevOptions, additional })
          }
          isPaginateable
          isClearable
        />
        {transactionFormValues?.customer_id && !!customerParentAccountName && (
          <ParentCustomerName>
            <Row>
              <b>Parent:</b>
              {customerParentAccountName ? customerParentAccountName : currentTransaction.parent_customer_name}
            </Row>
            <Row>
              <StyledCircleXIcon onClick={() => setCustomerParentAccountName(null)} />
              <StyledEditCircle onClick={() => setShowSubaccontActionModal(true)} />
            </Row>
          </ParentCustomerName>
        )}
      </div>
      {showSubaccontActionModal && (
        <SubaccountActionModal
          organizationId={organization?.id}
          parentCustomer={{
            customer_id: transactionFormValues?.customer_id,
            customer_name: transactionFormValues?.customer_name,
          }}
          closeModal={(subCustomer, parentСustomerName) => {
            if (subCustomer) {
              setFieldValue('customer_id', subCustomer.id);
              setFieldValue('customer_name', subCustomer.name);
              setCustomerParentAccountName(parentСustomerName);
            }
            setShowSubaccontActionModal(false);
          }}
        />
      )}
    </>
  );
};
