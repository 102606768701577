import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import dayjs from 'dayjs';

import { EVENTS } from 'consts/analytics';
import { downloadExportByType, useExportsAPI } from 'api/exports';
import { humanize } from 'utils/stringUtils';
import { downloadBlobFile } from 'utils/export';
import { useAnalytics, useScreenshotDownload, useToasts } from 'utils/hooks';

import { SyncedExportInfoBar } from './SyncedExportInfoBar';
import { SyncedExportModal } from './SyncedExportModal';
import { ExportButton } from './ExportButton';

export const useSyncedExport = ({
  orgId,
  type,
  customization: _customization = {},
  pageName,
  onExport,
  buttonWhite,
  exportScreenshotRef,
  externalSync = true,
  isFilterOn,
  startDateKey = '',
  endDateKey = '',
  additionalExportOption,
  additionalMenuOptions,
}) => {
  const [selectAllData, setSelectAllData] = useState(false);
  const selectAllDataRef = useRef();
  const persistMenuStateRef = useRef();

  const customization = useMemo(
    () =>
      typeof _customization === 'function'
        ? _customization({ selectAllData: selectAllData || selectAllDataRef.current })
        : _customization,
    [_customization, selectAllData],
  );

  const timePeriodText =
    customization?.[startDateKey] && customization?.[endDateKey]
      ? `from ${dayjs.utc(customization[startDateKey]).format('MM/YY')} to ${dayjs
          .utc(customization[endDateKey])
          .format('MM/YY')}`
      : null;

  const { pushToast } = useToasts();
  const {
    data,
    operations: { createExport },
  } = useExportsAPI({ orgId, type, autoFetch: false });
  const { downloadScreenshot } = useScreenshotDownload({
    pageName: type ? humanize(type) : pageName,
    ref: exportScreenshotRef,
  });
  const [currentSyncedExport, setCurrentSyncedExport] = useState();
  const [showModal, setShowModal] = useState(false);
  const { trackEvent } = useAnalytics();

  const isSynced = data?.length > 0;

  useEffect(() => {
    setCurrentSyncedExport(null);
  }, [type, setCurrentSyncedExport]);

  const handleSyncClick = useCallback(() => {
    trackEvent({
      name: EVENTS.OPEN_SYNCED_EXPORT_MODAL,
      properties: {
        orgId,
        exportType: type,
      },
    });

    setShowModal(true);
  }, [trackEvent, orgId, type, setShowModal]);

  const handleExportClick = useCallback(() => {
    trackEvent({
      name: 'Export CSV',
      properties: {
        orgId,
        exportType: type,
        exportCustomization: customization,
      },
    });

    const handleBackendExport = async () => {
      pushToast('Download started...');
      const file = await downloadExportByType({ orgId, type, customization });
      downloadBlobFile({ file, format: 'csv', filename: `Subscript ${humanize(type)}` });
    };

    onExport ? onExport({ selectAllData }) : handleBackendExport();
  }, [trackEvent, orgId, type, customization, onExport, selectAllData, pushToast]);

  const handleSubmit = useCallback(
    async ({ customization }) => {
      const currentExport = await createExport({ orgId, type, customization });
      setCurrentSyncedExport(currentExport);
      setShowModal(false);
    },
    [orgId, type, setCurrentSyncedExport, createExport],
  );

  const InfoBar = useCallback(
    () =>
      currentSyncedExport ? (
        <SyncedExportInfoBar syncedExport={currentSyncedExport} onDismiss={() => setCurrentSyncedExport(null)} />
      ) : (
        <></>
      ),
    [currentSyncedExport, setCurrentSyncedExport],
  );

  const Modal = useCallback(
    () =>
      showModal ? (
        <SyncedExportModal
          type={type}
          onSubmit={handleSubmit}
          initialCustomization={customization}
          onClose={() => setShowModal(false)}
        />
      ) : (
        <></>
      ),
    // [AT 2021-11-16] Omitting customization because somehow adding it here introduces an UI glitch when submitting.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [showModal, type, handleSubmit, setShowModal],
  );

  const Button = useCallback(
    () => (
      <ExportButton
        type={type}
        whiteVer={buttonWhite}
        downloadScreenshot={exportScreenshotRef ? downloadScreenshot : null}
        isSynced={isSynced}
        onSync={handleSyncClick}
        onExport={handleExportClick}
        externalSync={externalSync}
        selectAllData={selectAllData}
        setSelectAllData={setSelectAllData}
        isFilterOn={isFilterOn}
        selectAllDataRef={selectAllDataRef}
        persistMenuStateRef={persistMenuStateRef}
        timePeriodText={timePeriodText}
        additionalExportOption={additionalExportOption}
        additionalMenuOptions={additionalMenuOptions}
      >
        Export
      </ExportButton>
    ),
    [
      type,
      buttonWhite,
      exportScreenshotRef,
      downloadScreenshot,
      isSynced,
      handleSyncClick,
      handleExportClick,
      externalSync,
      selectAllData,
      isFilterOn,
      timePeriodText,
      additionalExportOption,
      additionalMenuOptions,
    ],
  );

  return {
    isSynced,
    SyncedExportInfoBar: InfoBar,
    SyncedExportModal: Modal,
    ExportButton: Button,
    showSyncedExportModal: handleSyncClick,
    exportCSV: handleExportClick,
    exportPNG: exportScreenshotRef ? downloadScreenshot : null,
  };
};
