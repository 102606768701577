import styled from 'styled-components';
import { FlexerRow } from 'components/Core';
import { useStateWithStorage } from 'utils/hooks';
import { ReactComponent as PencilIcon } from 'images/pencil-new.svg';
import { useInvoicingScheduleInternalNoteModal } from './useInvoicingScheduleInternalNoteModal';

const InternalNoteWrapper = styled.div`
  padding: ${({ hasInternalNotes }) => (hasInternalNotes ? '8px' : '4px')};
  margin: 4px 32px;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  border-radius: 8px;
  background-color: ${({ hasInternalNotes }) => (hasInternalNotes ? 'var(--primaryBlack3)' : 'transparent')};
`;

const StyledPencilIcon = styled(PencilIcon)`
  margin-left: 8px;
  width: 16px;
  height: 16px;

  path {
    fill: var(--primaryBlue);
  }
`;

const AddButton = styled(FlexerRow)`
  cursor: pointer;

  &:hover {
    ${StyledPencilIcon} {
      path {
        fill: var(--secondaryBlue);
      }
    }
  }
`;

const NotesContent = styled.div`
  margin-top: 8px;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: 16px;
  white-space: pre-wrap;
`;

const HideButton = styled.div`
  cursor: pointer;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  color: var(--primaryBlue);

  &:hover {
    color: var(--secondaryBlue);
  }
`;

export const InvoicingScheduleInternalNotes = ({ invoicingScheduleFormValues }) => {
  const { Modal: InternalNoteModal, openModal: openInternalNoteModal } = useInvoicingScheduleInternalNoteModal({
    invoicingScheduleFormValues,
  });

  const [noteContentHidden, setNoteContentHidden] = useStateWithStorage(
    'hide-invoicing-schedule-internal-notes',
    false,
  );

  const internalNotes = invoicingScheduleFormValues?.internal_notes;

  return (
    <InternalNoteWrapper hasInternalNotes={!!internalNotes}>
      <FlexerRow justifyContent="space-between">
        <AddButton data-cy="invoicing-schedule-internal-notes__add-button" onClick={openInternalNoteModal}>
          {internalNotes ? 'Edit' : 'Add'} notes
          <StyledPencilIcon />
        </AddButton>

        {internalNotes ? (
          <HideButton onClick={() => setNoteContentHidden(!noteContentHidden)}>
            {noteContentHidden ? 'Expand' : 'Hide'}
          </HideButton>
        ) : null}
      </FlexerRow>

      {internalNotes && !noteContentHidden ? (
        <NotesContent data-cy="invoicing-schedule-internal-notes__notes-content">{internalNotes}</NotesContent>
      ) : null}

      <InternalNoteModal />
    </InternalNoteWrapper>
  );
};
