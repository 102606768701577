import styled from 'styled-components';
import { useContext } from 'react';
import { numberFormatter } from 'utils/formatters';
import { NUMBER_FORMATS } from 'consts/global';
import { TooltipContainer } from 'components/Tooltip';
import { AppContext } from 'AppContext';
import { getFormulasByLabel } from './getFormulasByLabel';

const Formula = styled.div`
  font-weight: 900;
  font-size: 16px;
  line-height: 40px;
`;

export const ExplainModalFormula = ({ value, convertedMetricValue, convertedMetricValueObject, tooltip }) => {
  const {
    appSettings: { currencyISOCode },
  } = useContext(AppContext);
  const { title, dataType, isQuarterMetric, isYearMetric, isCumulativeMetric, numberMonths } = value;
  const isTTM = title.includes('TTM');
  const replacedTitle = title.replaceAll(' TTM', '');

  const formulasByLabel = getFormulasByLabel({
    convertedMetricValueObject,
    isQuarterMetric,
    isYearMetric,
    isCumulativeMetric,
    numberMonths,
    isTTM,
    currency: currencyISOCode,
  });

  return (
    <Formula>
      {title && formulasByLabel[replacedTitle]}
      <TooltipContainer isVisible={!!tooltip} width={300} toolTipContent={tooltip}>
        {numberFormatter({
          type: dataType,
          rawValue: convertedMetricValue,
          decimalPlaces: dataType === NUMBER_FORMATS.PERCENT ? 2 : 0,
          currency: currencyISOCode,
        })}
      </TooltipContainer>
    </Formula>
  );
};
