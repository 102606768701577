import React, { useContext, useMemo, useState } from 'react';
import { AppContext } from 'AppContext';
import styled from 'styled-components';
import { truncate } from 'lodash';
import { ReactComponent as EmailIcon } from 'images/email-full.svg';
import { getEmailAddresses } from 'models/customer';
import { Row } from 'components/Core';
import { PopoverActions, PopoverButton } from 'components/Portal';
import { TooltipContainer } from 'components/Tooltip';
import { CustomersActionsModal, CUSTOMERS_MODAL_ACTIONS } from 'views/Customers/CustomersActionsModal';

const TooltipText = styled.div`
  color: white;
  font-size: 12px;
  line-height: 16px;
`;

const TooltipButton = styled(PopoverButton)`
  font-weight: 900;
  text-transform: uppercase;
  font-size: 9px;
  line-height: 12px;
`;

const ContentContainer = styled.div`
  cursor: pointer;
`;

const CustomerName = styled.span`
  font-weight: 700;
  color: ${({ noEmails }) => noEmails && 'var(--tertiaryRed)'};

  &:hover {
    color: ${({ noEmails }) => noEmails && 'var(--secondaryRed)'};
  }
`;

const StyledIcon = styled.div`
  display: flex;
  margin-right: 4px;
  margin-left: 8px;
  align-items: center;
  cursor: pointer;

  svg {
    rect {
      fill: ${({ noEmails }) => noEmails && 'var(--tertiaryRed)'};
    }

    path {
      fill: ${({ noEmails }) => noEmails && 'var(--tertiaryRed)'};
    }
  }

  &:hover {
    svg {
      path {
        fill: ${({ noEmails }) => (noEmails ? 'var(--secondaryRed)' : 'var(--secondaryBlue)')};
      }

      rect {
        fill-opacity: 0.2;
      }
    }
  }
`;

export const CustomerEmailsTooltip = ({ transaction, onEmailListChange }) => {
  const { orgId } = useContext(AppContext);
  const [showCustomersActionModal, setShowCustomersActionModal] = useState(false);

  const emailsList = useMemo(() => getEmailAddresses({ objectWithInvoicingDetails: transaction }), [transaction]);

  const transactionWithEmail = !!emailsList?.length;

  return (
    <>
      <TooltipContainer
        width={Math.max(20, ...(emailsList?.map((email) => email?.length) ?? [])) * 8 + 20}
        toolTipContent={
          <ContentContainer onClick={() => setShowCustomersActionModal(true)}>
            {transactionWithEmail ? (
              <>
                <TooltipText>
                  {emailsList.map((email) => (
                    <div key={email}>{email}</div>
                  ))}
                </TooltipText>
                <PopoverActions>
                  <TooltipButton primary>edit emails</TooltipButton>
                </PopoverActions>
              </>
            ) : (
              <TooltipText>click to add at least 1 email</TooltipText>
            )}
          </ContentContainer>
        }
      >
        <Row
          data-no-emails={!transactionWithEmail}
          onClick={() => setShowCustomersActionModal(true)}
          data-cy={`review-transactions-page__transaction-${transaction?.id}-customer-name`}
        >
          <CustomerName noEmails={!transactionWithEmail}>
            {truncate(transaction.customer_name || `#${transaction.customer_id}`)}
          </CustomerName>

          <StyledIcon noEmails={!transactionWithEmail}>
            <EmailIcon />
          </StyledIcon>
        </Row>
      </TooltipContainer>

      {showCustomersActionModal && (
        <div style={{ cursor: 'default' }}>
          <CustomersActionsModal
            organizationId={orgId}
            closeModal={(customer) => {
              setShowCustomersActionModal(false);
              if (customer?.invoicing_details?.contacts)
                onEmailListChange({ customerId: customer?.id, newEmailList: customer?.invoicing_details?.contacts });
            }}
            modalAction={CUSTOMERS_MODAL_ACTIONS.EDIT}
            customer={{
              id: transaction.customer_id,
            }}
          />
        </div>
      )}
    </>
  );
};
