import React, { useContext, useMemo } from 'react';
import dayjs from 'dayjs';
import styled from 'styled-components';

import { EVENT_RECOGNITION_TYPES, RECOGNITION_TYPES } from 'consts/global';
import { transactionDisplayNameForBilling } from 'models/transaction';
import { CentererVertical, FlexerColumn } from 'components/Core';
import { RecognitionIcon, WarningIcon } from 'components/Icons';
import { INVOICE_MAGIC_METADATA, INVOICING_FREQUENCIES } from 'views/Billing/consts';

import { InvoicingScheduleContext } from '../InvoicingScheduleContext';
import { useAutoChargeModal } from './useAutoChargeModal';

const WarningsBlock = styled(FlexerColumn)`
  margin: 0 31px;
  border-radius: 8px;
  border: 1px solid var(--primaryBlack3);
  background: var(--primaryBlack3);
  overflow: hidden;
  margin-top: 8px;
`;

const WarningText = styled.span`
  color: var(--primaryBlack);
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
`;

const WarnBlock = styled.div`
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: 16px;
  color: var(--dark7);
  padding: 8px;
  border-bottom: 1px solid var(--primaryBlack3);

  &:hover {
    cursor: ${({ clickable }) => (clickable ? 'pointer' : 'default')};
    background-color: ${({ clickable }) => (clickable ? 'white' : 'transparent')};
    font-weight: ${({ clickable }) => (clickable ? '700' : '400')};
  }

  &:last-child {
    border-bottom: none;
  }
`;

const INVOICING_FREQUENCY_TIME_UNIT = {
  [INVOICING_FREQUENCIES.MONTHLY]: 'every month',
  [INVOICING_FREQUENCIES.QUARTERLY]: 'every quarter',
  [INVOICING_FREQUENCIES.ANNUALLY]: 'every year',
  [INVOICING_FREQUENCIES.SEMI_ANNUALLY]: 'twice a year',
};

export const InvoicesWarns = ({ invoices }) => {
  const {
    invoicingScheduleFormValues,
    customerDetails,
    includedTransactions,
    disableSaveScheduleButton,
    scheduleFormRef,
    isScheduleDraft,
  } = useContext(InvoicingScheduleContext);

  const setFieldValue = scheduleFormRef?.current?.setFieldValue;

  const { Modal: ConfirmAutoChargeModal, openModal: openConfirmAutoChargeModal } = useAutoChargeModal({
    setFieldValue,
  });

  const pastInvoices = useMemo(
    () =>
      invoicingScheduleFormValues?.invoices?.filter(
        (invoice) =>
          !invoice.paid_at &&
          !invoice.voided_at &&
          (!customerDetails?.has_active_payment_method || invoice?.metadata?.payment_status !== 'processing') &&
          dayjs.utc(invoice.date).isBefore(dayjs.utc().startOf('day')),
      ) ?? [],
    [customerDetails?.has_active_payment_method, invoicingScheduleFormValues?.invoices],
  );

  const pastInvoicesWillBeCharged = pastInvoices.some(
    (invoice) => invoice?.metadata?.[INVOICE_MAGIC_METADATA.DISABLE_AUTO_CHARGE] === false,
  );

  const tillCanceledTransaction = useMemo(
    () => includedTransactions?.find(({ recognition }) => recognition === RECOGNITION_TYPES.tillCanceled),
    [includedTransactions],
  );
  const eventTransactions = useMemo(
    () => includedTransactions?.filter(({ recognition }) => EVENT_RECOGNITION_TYPES.includes(recognition)),
    [includedTransactions],
  );

  const invoicesWithPastDates = useMemo(
    () =>
      invoices?.filter((invoice) => dayjs.utc(invoice?.date).isBefore(dayjs.utc().startOf('day')) && !invoice?.paid_at),
    [invoices],
  );

  return (
    <>
      {!!invoicingScheduleFormValues?.invoices?.length && (
        <>
          {isScheduleDraft && disableSaveScheduleButton && (
            <CentererVertical gap="8px" style={{ margin: '10px 32px' }}>
              <WarningIcon size="12px" />
              <WarningText>First setup income accounts for all transactions/products</WarningText>
            </CentererVertical>
          )}

          {(tillCanceledTransaction && invoicingScheduleFormValues?.invoicing_frequency) ||
          eventTransactions?.length > 0 ||
          (!!invoicesWithPastDates?.length && invoicingScheduleFormValues?.auto_charge) ? (
            <WarningsBlock>
              {!!invoicesWithPastDates?.length && invoicingScheduleFormValues?.auto_charge && (
                <WarnBlock data-cy="invoices-warns__auto-charge" clickable onClick={openConfirmAutoChargeModal}>
                  {pastInvoicesWillBeCharged
                    ? 'Old invoices will be auto-charged as well'
                    : 'Old invoices are not auto-charged'}
                </WarnBlock>
              )}
              {tillCanceledTransaction && invoicingScheduleFormValues?.invoicing_frequency && (
                <>
                  <WarnBlock
                    justifyContent="space-between"
                    rightIcon={<RecognitionIcon recognitionType={RECOGNITION_TYPES.tillCanceled} />}
                  >
                    Subscript will generate a new invoice{' '}
                    {INVOICING_FREQUENCY_TIME_UNIT[invoicingScheduleFormValues.invoicing_frequency] ?? 'every month'}{' '}
                    until {transactionDisplayNameForBilling(tillCanceledTransaction, { shortForm: true })} is canceled.
                    {invoicingScheduleFormValues?.auto_charge && (
                      <>
                        {' '}
                        Once the customer has entered the payment details for the first invoice, future invoices will be
                        charged automatically.
                      </>
                    )}
                  </WarnBlock>
                </>
              )}
              {eventTransactions?.length > 0 && (
                <>
                  <WarnBlock
                    justifyContent="space-between"
                    rightIcon={
                      <RecognitionIcon
                        recognitionType={eventTransactions[0]?.recognition || RECOGNITION_TYPES.eventNotRecurring}
                      />
                    }
                  >
                    Subscript will auto-update your invoices when new events are added to the usage-based transactions.
                  </WarnBlock>
                </>
              )}
            </WarningsBlock>
          ) : null}

          <ConfirmAutoChargeModal />
        </>
      )}
    </>
  );
};
