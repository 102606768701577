export const BILLING_SCHEME_TAB_VALUES = {
  PER_UNIT: 'per_unit',
  TIERED: 'tiered',
};

export const FORMULA_DATA_VARIABLES = {
  UNITS: '$units',
  CUMULATIVE_UNITS: '$cumulativeUnits',
};

// https://github.com/silentmatt/expr-eval
// Plus our custom functions in backend/src/components/pricingPlans/calculator.js
export const FORMULA_SUPPORTED_FUNCTIONS_VALUES = {
  ABS: 'abs',
  ACOS: 'acos',
  ACOSH: 'acosh',
  ASIN: 'asin',
  ASINH: 'asinh',
  ATAN: 'atan',
  ATANH: 'atanh',
  CBRT: 'cbrt',
  CEIL: 'ceil',
  COS: 'cos',
  COSH: 'cosh',
  EXPR: 'expr',
  FLOOR: 'floor',
  IF: 'if',
  LN: 'ln',
  LOG: 'log',
  LOG10: 'log10',
  LOG2: 'log2',
  LOGN: 'logn',
  RANDOM: 'random',
  ROUND: 'round',
  MAX: 'max',
  MIN: 'min',
  POW: 'pow',
  SIN: 'sin',
  SINH: 'sinh',
  SQRT: 'sqrt',
  TAN: 'tan',
  TANH: 'tanh',
};

export const FORMULA_SUPPORTED_FUNCTIONS_USAGE = {
  [FORMULA_SUPPORTED_FUNCTIONS_VALUES.ABS]: 'Absolute value (magnitude) of x: abs(x)',
  [FORMULA_SUPPORTED_FUNCTIONS_VALUES.ACOS]: 'Arc cosine of x (in radians): acos(x)',
  [FORMULA_SUPPORTED_FUNCTIONS_VALUES.ACOSH]: 'Hyperbolic arc cosine of x (in radians): acosh(x)',
  [FORMULA_SUPPORTED_FUNCTIONS_VALUES.ASIN]: 'Arc sine of x (in radians): asin(x)',
  [FORMULA_SUPPORTED_FUNCTIONS_VALUES.ASINH]: 'Hyperbolic arc sine of x (in radians): asinh(x)',
  [FORMULA_SUPPORTED_FUNCTIONS_VALUES.ATAN]: 'Arc tangent of x (in radians): atan(x)',
  [FORMULA_SUPPORTED_FUNCTIONS_VALUES.ATANH]: 'Hyperbolic arc tangent of x (in radians): atanh(x)',
  [FORMULA_SUPPORTED_FUNCTIONS_VALUES.CBRT]: 'Cube root of x: cbrt(x)',
  [FORMULA_SUPPORTED_FUNCTIONS_VALUES.CEIL]: 'The smallest integer >= x: ceil(x)',
  [FORMULA_SUPPORTED_FUNCTIONS_VALUES.COS]: 'Cosine of x (x is in radians): cos(x)',
  [FORMULA_SUPPORTED_FUNCTIONS_VALUES.COSH]: 'Hyperbolic cosine of x (x is in radians): cosh(x)',
  [FORMULA_SUPPORTED_FUNCTIONS_VALUES.EXPR]: 'e^x (exponential/antilogarithm function with base e): expr(x)',
  [FORMULA_SUPPORTED_FUNCTIONS_VALUES.FLOOR]: 'The largest integer <= x: floor(x)',
  [FORMULA_SUPPORTED_FUNCTIONS_VALUES.IF]:
    'If condition is evaluated to true, return x; otherwise return y: if(condition, x, y)',
  [FORMULA_SUPPORTED_FUNCTIONS_VALUES.LN]: 'Log with base e: ln(x)',
  [FORMULA_SUPPORTED_FUNCTIONS_VALUES.LOG]: '(Synonym of ln) Log with base e: log(x)',
  [FORMULA_SUPPORTED_FUNCTIONS_VALUES.LOG2]: 'Log with base 2: log2(x)',
  [FORMULA_SUPPORTED_FUNCTIONS_VALUES.LOG10]: 'Log with base 10: log10(x)',
  [FORMULA_SUPPORTED_FUNCTIONS_VALUES.LOGN]: 'Log with base n: logn(n, x)',
  [FORMULA_SUPPORTED_FUNCTIONS_VALUES.RANDOM]: 'A random number in the range [0, n): random(n)',
  [FORMULA_SUPPORTED_FUNCTIONS_VALUES.ROUND]: 'The integer closest to x: round(x)',
  [FORMULA_SUPPORTED_FUNCTIONS_VALUES.MAX]: 'Maximum value of an arbitrary list of numbers: max(x1, x2, x3, ....)',
  [FORMULA_SUPPORTED_FUNCTIONS_VALUES.MIN]: 'Minimum value of an arbitrary list of numbers: min(x1, x2, x3, ....)',
  [FORMULA_SUPPORTED_FUNCTIONS_VALUES.POW]: 'x ^ y: pow(x, y)',
  [FORMULA_SUPPORTED_FUNCTIONS_VALUES.SIN]: 'Sine of x (x is in radians): sin(x)',
  [FORMULA_SUPPORTED_FUNCTIONS_VALUES.SINH]: 'Hyperbolic sine of x (x is in radians): sinh(x)',
  [FORMULA_SUPPORTED_FUNCTIONS_VALUES.SQRT]: 'Square root of x. Result is NaN (Not a Number) if x is negative: sqrt(x)',
  [FORMULA_SUPPORTED_FUNCTIONS_VALUES.TAN]: 'Tangent of x (x is in radians): tan(x)',
  [FORMULA_SUPPORTED_FUNCTIONS_VALUES.TANH]: 'Hyperbolic tangent of x (x is in radians): tanh(x)',
};

export const FORMULA_SUPPORTED_SYNTAX = [
  { symbol: '+', description: 'addition' },
  { symbol: '-', description: 'subtraction' },
  { symbol: '/', description: 'division' },
  { symbol: '*', description: 'multiplication' },
  { symbol: '!', description: 'factorial of n: n!' },
  { symbol: 'PI', description: 'math constant pi (~3.1416)' },
  { symbol: 'E', description: 'math constant e (~2.7182)' },
  { symbol: '>', description: 'x > y' },
  { symbol: '>=', description: 'x >= y' },
  { symbol: '<', description: 'x < y' },
  { symbol: '<=', description: 'x <= y' },
];
