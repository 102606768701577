import styled from 'styled-components';
import { Centerer } from 'components/Core';

export const EditMetricCardButton = styled(Centerer)`
  display: ${({ active }) => active && 'flex !important'};

  width: 28px;
  height: 28px;
  padding: 6px;

  background: ${({ active }) => (active ? 'var(--primaryBlue20)' : 'var(--primaryBlue10)')};
  border-radius: 100px;
  cursor: pointer;

  svg {
    width: 16px;
    height: 16px;

    path {
      fill: ${({ active }) => (active ? 'var(--secondaryBlue)' : 'var(--primaryBlue)')};
    }
  }

  &:hover {
    background: var(--primaryBlue20);

    svg {
      path {
        fill: var(--secondaryBlue);
      }
    }
  }
`;
