import { useContext } from 'react';

import { AppContext } from 'AppContext';
import { useGetAvailableTags } from 'api/billing';
import { FieldBody, FieldTitle } from 'views/Wizard/styles';
import { BillingDynamicFieldsTooltip, DYNAMIC_FIELD_TOOLTIP_SECTIONS } from 'views/Billing/BillingDynamicFieldsTooltip';
import { FormikCustomCheckbox, FormikCustomInput, FormikEmailSelector } from 'components/Controls';
import { CentererVertical } from 'components/Core';
import { templateToHTMLString } from 'utils/htmlUtils';
import { DEFAULT_AVAILABLE_TAGS, DESCRIPTION_TAGS } from 'consts/billing';

import { ApplyToAllCheckboxes } from '../ApplyToAllCheckboxes';
import { FormRow, PaddingWrapper } from '../styles';

export const BillingReceiptsSettings = ({ values, setFieldValue, getChangedReceiptDefaults }) => {
  const { orgId } = useContext(AppContext);

  const {
    transactionMetadataTags = [],
    customerMetadataTags = [],
    transactionCustomerMetadataTags = [],
  } = useGetAvailableTags({ orgId });

  const availableTags = [
    ...DEFAULT_AVAILABLE_TAGS,
    ...transactionMetadataTags,
    ...customerMetadataTags,
    ...transactionCustomerMetadataTags,
    ...DESCRIPTION_TAGS,
  ];

  const hasChanged = Object.keys(getChangedReceiptDefaults(values.billingReceiptDefaults)).length !== 0;

  return (
    <FieldBody style={{ padding: 0, overflow: 'hidden' }}>
      <PaddingWrapper>
        <FieldTitle>Receipts after payments</FieldTitle>
        <FormikCustomCheckbox
          name="billingReceiptDefaults.auto_send"
          label="Auto send receipts to customers after payments"
          data-cy="billing-receipts-setting__auto-send--checkbox"
        />

        <FormRow marginTop="16px" childrenWidth="100%">
          <FormikEmailSelector
            name="billingReceiptDefaults.send_cc"
            label={`Email address to CC for receipt emails`}
            value={values?.billingReceiptDefaults?.send_cc?.filter((e) => typeof e === 'string')}
            onChange={(selectedValues) => {
              setFieldValue('billingReceiptDefaults.send_cc', selectedValues);
            }}
            smallVersion
            data-cy="billing-receipts-setting__email-cc--selector"
          />
        </FormRow>

        <FormRow marginTop="16px" childrenWidth="100%">
          <FormikCustomInput
            name="billingReceiptDefaults.email_subject"
            label="Receipt Email Subject"
            HTMLString={templateToHTMLString({
              text: values?.billingReceiptDefaults?.email_subject,
              availableTags,
            })}
            availableTags={availableTags}
            TopRightHeader={
              <CentererVertical gap="8px">
                <BillingDynamicFieldsTooltip
                  tags={{
                    [DYNAMIC_FIELD_TOOLTIP_SECTIONS.GENERAL_TAGS]: DEFAULT_AVAILABLE_TAGS,
                    [DYNAMIC_FIELD_TOOLTIP_SECTIONS.DESCRIPTION_TAGS]: DESCRIPTION_TAGS,
                    [DYNAMIC_FIELD_TOOLTIP_SECTIONS.TRANSACTION_METADATA_TAGS]: transactionMetadataTags,
                    [DYNAMIC_FIELD_TOOLTIP_SECTIONS.CUSTOMER_METADATA_TAGS]: customerMetadataTags,
                    [DYNAMIC_FIELD_TOOLTIP_SECTIONS.TRANSACTION_CUSTOMER_METADATA_TAGS]: transactionCustomerMetadataTags,
                  }}
                  XOffset={100}
                />
              </CentererVertical>
            }
            type="HTMLEdit"
            placeholder="Enter Receipt Email Subject..."
            data-cy="billing-receipts-setting__email-subject--input"
          />
        </FormRow>

        <FormRow marginTop="16px" childrenWidth="100%">
          <FormikCustomInput
            name="billingReceiptDefaults.email_body"
            minHeight="186px"
            label="Receipt Email Body"
            HTMLString={templateToHTMLString({
              text: values?.billingReceiptDefaults?.email_body,
              availableTags,
            })}
            availableTags={availableTags}
            TopRightHeader={
              <CentererVertical gap="8px">
                <BillingDynamicFieldsTooltip
                  tags={{
                    [DYNAMIC_FIELD_TOOLTIP_SECTIONS.GENERAL_TAGS]: DEFAULT_AVAILABLE_TAGS,
                    [DYNAMIC_FIELD_TOOLTIP_SECTIONS.DESCRIPTION_TAGS]: DESCRIPTION_TAGS,
                    [DYNAMIC_FIELD_TOOLTIP_SECTIONS.TRANSACTION_METADATA_TAGS]: transactionMetadataTags,
                    [DYNAMIC_FIELD_TOOLTIP_SECTIONS.CUSTOMER_METADATA_TAGS]: customerMetadataTags,
                    [DYNAMIC_FIELD_TOOLTIP_SECTIONS.TRANSACTION_CUSTOMER_METADATA_TAGS]: transactionCustomerMetadataTags,
                  }}
                  XOffset={100}
                />
              </CentererVertical>
            }
            type="HTMLEdit"
            placeholder="Enter Receipt Email Body..."
            data-cy="billing-receipts-setting__email-body--input"
          />
        </FormRow>
      </PaddingWrapper>

      <ApplyToAllCheckboxes
        isShowing={hasChanged}
        name="billingReceiptDefaults.update_unsent_receipts"
        dataCy="billing-receipts-setting__update-unsent-receipts--checkbox"
        label="Update all existing and unsent receipts to have the newly configured details (subject, body, CC)"
        checkboxTitleEntity="receipts"
      />
    </FieldBody>
  );
};
