const dayjs = require('dayjs');
const { isEqual, identity } = require('lodash');
const { PERIOD_OPTIONS } = require('shared/Filters/utils');
const { RESOLUTION_OPTIONS } = require('shared/Filters/ResolutionSelectorPopover');
const { CHART_COLORS } = require('./consts');

export const pickBarColour = (i) => {
  const colours = CHART_COLORS.others;
  return colours[i % colours.length];
};

export const SETTINGS_VALUE_MAPPINGS = {
  startMonth: (insightStartMonth) => new Date(dayjs(insightStartMonth)),
  endMonth: (insightEndMonth) => new Date(dayjs(insightEndMonth)),
  revenueTypes: (insightSpreadTypes) => insightSpreadTypes,
  resolution: (insightPeriodType) => RESOLUTION_OPTIONS[insightPeriodType + 'ly'],
  monthsFromStart: (insightMonthsFromStart) => insightMonthsFromStart + 1,
  period: () => PERIOD_OPTIONS.custom,
};

// This is a mapping of the parameter names used in the API (which are stored in insight.customization) to the names used in the UI
export const SETTINGS_NAME_MAPPINGS = {
  resolution: 'periodType',
  revenueTypes: 'spreadTypes',
  period: 'custom',
};

export const hasSameSettings = ({ insight, settingsToMatch }) => {
  for (const [settingName, settingValue] of Object.entries(settingsToMatch)) {
    const mappedSettingName = SETTINGS_NAME_MAPPINGS[settingName] ?? settingName;
    const valueMapFunc = SETTINGS_VALUE_MAPPINGS[settingName] ?? identity;
    const compareFunc = (val1, val2) =>
      val1 instanceof Date && val2 instanceof Date
        ? dayjs(val1).format('YYYY-MM') === dayjs(val2).format('YYYY-MM')
        : isEqual(val1, val2);

    if (
      mappedSettingName in insight?.customization &&
      !compareFunc(settingValue, valueMapFunc(insight?.customization[mappedSettingName]))
    ) {
      return false;
    }
  }
  return true;
};
