import styled, { css } from 'styled-components';
import { cssVar, transparentize } from 'polished';

import { Button, ButtonText } from 'components/Buttons';

const ButtonWrapper = styled(Button)`
  color: var(--secondaryGreen);
  background: ${transparentize(0.9, cssVar('--primaryGreen'))};
  border: 1px solid ${transparentize(0.9, cssVar('--primaryGreen'))};

  &:hover {
    border: 1px solid var(--secondaryGreen);
  }

  svg {
    path {
      fill: var(--secondaryGreen);
    }
  }

  ${(props) =>
    props.active &&
    css`
      color: #fff;
      background: var(--primaryGreen);
      border: 1px solid var(--primaryGreen);

      svg {
        path {
          fill: #fff;
        }
      }
    `}
`;

export const OutlineGreenButton = ({ text, icon, onClick, active, fontWeight }) => (
  <ButtonWrapper onClick={onClick} active={active}>
    {icon}
    <ButtonText fontWeight={fontWeight}>{text}</ButtonText>
  </ButtonWrapper>
);
