import styled from 'styled-components';

import { ReactComponent as Icon } from 'images/trash-new-no-opacity.svg';

export const BinIcon = styled(Icon)`
  width: ${(props) => props.size};
  height: ${(props) => props.size};

  stroke: ${({ stroke }) => stroke};
  path {
    fill: ${({ fill }) => fill};
  }
`;
