import styled from 'styled-components';

import { ReactComponent as TableExportIcon } from 'images/table-export.svg';
import { COLORS } from 'consts/colors';
import { IconButton } from './IconButton';
import { DEFAULT_ICON_SIZE } from 'consts/global';

const StyledIconButton = styled(IconButton)`
  box-shadow: ${(props) => props.whiteVer && '4px 4px 28px var(--primaryBlack5)'};
  &:hover {
    background-color: ${(props) => (props.whiteVer ? '' : 'var(--secondaryGreen)')};
    box-shadow: ${(props) => props.whiteVer && '0px 4px 20px var(--primaryBlack10)'};
    border: ${(props) => props.whiteVer && '1px solid var(--neutralGray)'};
  }
`;

export const ExportButton = ({ onClick, whiteVer }) => (
  <StyledIconButton
    whiteVer={whiteVer}
    filled={!whiteVer}
    color={whiteVer ? 'white' : COLORS.GREEN}
    iconFillColor={whiteVer ? 'var(--primaryGreen)' : 'white'}
    onClick={onClick}
    icon={<TableExportIcon width={DEFAULT_ICON_SIZE} height={DEFAULT_ICON_SIZE} />}
  >
    Export
  </StyledIconButton>
);
