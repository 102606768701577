import { useContext, useState } from 'react';
import { isEqual } from 'lodash';
import { useConfirmModal } from 'shared/ConfirmModal';
import { convertToPlainText } from 'utils/htmlUtils';

import { InvoicingScheduleContext } from '../InvoicingScheduleModal/InvoicingScheduleContext';
import { BillingContext } from '../BillingContext';

export const useSafelySwitchReminder = () => {
  const { reminderFormValues, setSelectedReminder, reminderFormRef } = useContext(InvoicingScheduleContext);

  const { editBillingEmailReminder } = useContext(BillingContext);

  const [newReminder, setNewReminder] = useState(null);

  const handleSave = async (isSave) => {
    if (isSave) {
      await editBillingEmailReminder({
        id: reminderFormValues?.id,
        send: false,
        data: {
          scheduled_for: reminderFormValues?.scheduled_for,
          body: convertToPlainText(reminderFormValues?.body),
          subject: convertToPlainText(reminderFormValues?.subject),
          attachments: reminderFormValues?.attachments,
          auto_send: reminderFormValues?.auto_send,
        },
        isPreview: false,
      });
    }

    setSelectedReminder(newReminder);
  };

  const {
    openConfirmModal: openSaveModal,
    ConfirmModal: ConfirmSaveModal,
    isModalVisible: isSaveModalVisible,
  } = useConfirmModal({
    title: 'You have unsaved changes',
    content: 'Do you want to save the reminder before you leave it?',
    denyButtonText: `Don't save`,
    confirmButtonText: 'Yes, save it',
    onConfirm: () => handleSave(true),
    onDeny: () => handleSave(false),
  });

  const checkAndSwitchReminder = ({ clickedReminder }) => {
    if (
      reminderFormRef?.current?.initialValues &&
      // omit to_emails and email_cc as they do not get saved
      !isEqual(
        { ...reminderFormRef?.current?.initialValues, to_emails: [], email_cc: [] },
        { ...reminderFormValues, to_emails: [], email_cc: [] },
      )
    ) {
      openSaveModal();
      setNewReminder(clickedReminder);
    } else {
      setSelectedReminder(clickedReminder);
    }
  };

  return {
    checkAndSwitchReminder,
    ConfirmSaveModal,
    isSaveModalVisible,
  };
};
