import React, { useContext, useMemo, useState } from 'react';
import { ReactComponent as DotsIcon } from 'images/dots.svg';
import { SNAPSHOT_METRICS } from 'consts/snapshotMetrics';
import { EditMetricCardButton, useEditMetricCardModal } from 'views/Dashboard/Common/EditMetricCard';
import { DASHBOARD_CHART_CARDS_METRICS } from './consts';
import { DashboardCustomersCard } from './DashboardCustomersCard';
import { DashboardRecurringRevenueCard } from './DashboardRecurringRevenueCard';
import { DashboardCompanyDataCard } from './DashboardCompanyDataCard';
import { DashboardChartVisualizerCard } from './DashboardChartVisualizerCard';
import { DashboardRevenueWaterfallCard } from './DashboardRevenueWaterfallCard';
import { DashboardContext } from '../DashboardContext';
import { CardsGrid, CARD_SIZES } from '../Common/Card';
import { DashboardCard } from './DashboardCard';

const METRIC_CARDS = {
  // [JB 2023-01-13] We could in the future add all SNAPSHOT_METRICS,
  // but we are keeping just these for now
  // context: https://teamsubscript.slack.com/archives/C01FKNY15HN/p1673607798042859
  ...DASHBOARD_CHART_CARDS_METRICS.reduce((acc, key) => {
    acc[key.toLowerCase()] = DashboardCard;
    return acc;
  }, {}),
  // These are special METRIC_CARDS with custom logic
  customers: DashboardCustomersCard,
  recurring_revenue: DashboardRecurringRevenueCard,
  company_data: DashboardCompanyDataCard,
  chart_visualizer: DashboardChartVisualizerCard,
  revenue_waterfall: DashboardRevenueWaterfallCard,
};

export const DashboardCardsGrid = () => {
  const { metricsSetting, setMetricsSetting } = useContext(DashboardContext);

  const [selectedMetricIndex, setSelectedMetricIndex] = useState();
  const metricOptions = useMemo(
    () =>
      DASHBOARD_CHART_CARDS_METRICS?.map((key) => ({
        label: SNAPSHOT_METRICS[key].label,
        value: key.toLowerCase(),
        hasTTMOption: !!SNAPSHOT_METRICS[`${key}_TTM`],
      })),
    [],
  );

  const { openEditMetricCardModal, EditMetricCardModal } = useEditMetricCardModal({
    metricOptions,
    onSubmitted: (metric) => {
      const newMetricsSetting = [...metricsSetting];
      newMetricsSetting[selectedMetricIndex] = metric;
      setMetricsSetting(newMetricsSetting);
    },
  });

  return (
    <CardsGrid>
      {metricsSetting?.map((metric, metricIndex) => {
        const Component = METRIC_CARDS[metric.id];
        if (!metric.active || !Component) return null;
        const handleOpenSettings = (event) => {
          event?.stopPropagation();
          setSelectedMetricIndex(metricIndex);
          openEditMetricCardModal(metric);
        };
        return (
          <Component
            metricId={metric.id.toUpperCase()}
            columnStart={metric.columnStart}
            size={metric.size ?? CARD_SIZES.SMALL}
            rowStart={metric.rowStart}
            key={metric.id}
            isTTM={metric.isTTM}
            selectedMetrics={metric.selectedMetrics}
            SettingsButton={
              <EditMetricCardButton onClick={handleOpenSettings} data-cy="edit-metric-card-button">
                <DotsIcon />
              </EditMetricCardButton>
            }
            onTTMClick={handleOpenSettings}
          />
        );
      })}
      <EditMetricCardModal />
    </CardsGrid>
  );
};
