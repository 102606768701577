import styled from 'styled-components';

export const SubscriptionWrapper = styled.div`
  padding: 0 100px;
  position: relative;
`;

export const Subtitle = styled.div`
  font-size: 14px;
  text-align: center;
  line-height: 20px;
  opacity: 0.5;
`;

export const ItemsHeader = styled.div`
  color: var(--primaryBlack30);
  text-transform: uppercase;
  font-weight: 800;
  font-size: 10px;
  line-height: 14px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: relative;
`;

export const HeaderLine = styled.div`
  width: 770px;
  position: absolute;
  z-index: 1;
  height: 1px;
  background-color: var(--primaryBlack3);
  left: -28px;
  top: 9px;
`;

export const HeaderCount = styled.div`
  padding: 0 12px;
  background-color: white;
  position: relative;
  z-index: 5;
`;

export const HeaderName = styled.div`
  margin-left: 25px;
  padding: 0 12px;
  background-color: white;
  position: relative;
  z-index: 5;
`;

export const HeaderValue = styled.div`
  padding: 0 12px;
  margin-right: 48px;
  background-color: white;
  position: relative;
  z-index: 5;
`;

export const ItemRow = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ItemCount = styled.div`
  font-weight: 900;
  font-size: 10px;
  line-height: 14px;
  color: rgba(0, 21, 46, 0.5);
  padding: ${({ smallPadding }) => (smallPadding ? '5px 6px' : '5px 9px')};
  border-radius: 100px;
  border: 1px solid rgba(0, 21, 46, 0.05);
`;

export const ItemName = styled.div`
  color: var(--primaryBlue);
  font-size: 14px;
  line-height: 20px;
  padding-left: 40px;
  cursor: pointer;
`;

export const SegmentActions = styled.div`
  position: relative;
  margin-right: 55px;
`;

export const DeleteIconContainer = styled.span`
  position: absolute;
  right: -55px;
  cursor: pointer;

  &:hover {
    g {
      opacity: 1;
    }
  }
`;

export const SegmentsSubtitle = styled.div`
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: var(--primaryBlack50);
  margin-bottom: 24px;

  b {
    color: var(--primaryBlack);
    margin: 0 4px;
  }
`;

export const StyledInput = styled.input`
  width: 295px;
  height: 38px;
  padding: 9px 10px;
  font-size: 14px;
  line-height: 20px;
  border: 1px solid rgba(0, 21, 46, 0.1);
  border-radius: 8px;
  margin-top: 8px;
  margin-left: 30px;

  &::placeholder {
    color: var(--primaryBlack);
    opacity: 0.3;
  }
`;

export const MainContent = styled.div`
  margin-top: 16px;
  font-size: 30px;
  line-height: 44px;
  max-width: 770px;
  text-align: center;
  margin-bottom: 40px;

  span {
    font-weight: 900;
    color: var(--primaryGreen);
  }
`;

export const TagsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const AddSegmentButton = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: var(--primaryBlue);
  cursor: pointer;
  display: flex;
  align-items: center;

  svg path {
    fill: var(--primaryBlue);
  }

  &:hover {
    color: var(--secondaryBlue);

    svg path {
      fill: var(--secondaryBlue);
    }
  }

  svg {
    margin-right: 4px;
  }
`;
