import styled from 'styled-components';

import { ReactComponent as Icon } from 'images/circled-arrow-right-v2.svg';

export const CircledArrowRightIcon = styled(Icon)`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  cursor: ${(props) => props.cursor};

  path:nth-child(2) {
    fill: ${(props) => props.color};
    transition: fill 0.2s ease-in-out;
  }
`;
