import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { ErrorBar } from 'components/Blocks';
import { Centerer } from 'components/Core';
import { CircleLoader } from 'components/Loaders';
import { CheckoutForm } from './CheckoutForm';
import { CheckoutSuccess } from './CheckoutSuccess';
import { getModalInvoiceStatus } from '../InvoiceModal/utils';
import { INVOICE_STATUSES } from '../consts';
import { CheckoutLoading } from './CheckoutLoading';

const Wrapper = styled.div`
  padding: 72px 135px;
`;

export const CheckoutFormContainer = ({
  publicKey,
  clientSecret,
  paymentSucceeded,
  isCurrentInvoiceMarkedPaid,
  autoChargingWithPaymentMethod,
  errorMessage,
  language,
  translations,
  invoice,
}) => {
  const [stripePromise, setStripePromise] = useState();
  const [invoiceStatus, setInvoiceStatus] = useState();

  useEffect(() => {
    if (publicKey) {
      setStripePromise(loadStripe(publicKey));
    }
  }, [publicKey]);

  useEffect(() => {
    if (invoice) {
      setInvoiceStatus(getModalInvoiceStatus({ invoice }));
    }
  }, [invoice]);

  return (
    <Wrapper>
      {errorMessage ? (
        <ErrorBar>{errorMessage}</ErrorBar>
      ) : [INVOICE_STATUSES.QUEUED_UP, INVOICE_STATUSES.PROCESSING, INVOICE_STATUSES.TAXES_PROCESSING].includes(
          invoiceStatus,
        ) ? (
        <CheckoutLoading translations={translations} />
      ) : paymentSucceeded || isCurrentInvoiceMarkedPaid ? (
        <CheckoutSuccess
          autoChargingWithPaymentMethod={autoChargingWithPaymentMethod}
          isCurrentInvoiceMarkedPaid={isCurrentInvoiceMarkedPaid}
          translations={translations}
        />
      ) : clientSecret && stripePromise ? (
        <Elements stripe={stripePromise} options={{ clientSecret, locale: language }}>
          <CheckoutForm translations={translations} />
        </Elements>
      ) : (
        <Centerer>
          <CircleLoader />
        </Centerer>
      )}
    </Wrapper>
  );
};
