import { useContext, useState } from 'react';
import styled from 'styled-components';

import { Popover, PopoverPrompt, PopoverActions, PopoverButton } from 'components/Portal';
import { ExternalLinkIcon } from 'components/Icons';
import { ReactComponent as TrashIcon } from 'images/trash.svg';
import { Row } from 'components/Core';
import { TableActionButton } from 'components/Buttons';
import { SwitchWithLabel } from 'components/Controls';

import { BillingContext } from 'views/Billing/BillingContext';
import { convertToPlainText } from 'utils/htmlUtils';
import { AppContext } from 'AppContext';

const LinkIconWrapper = styled.div`
  cursor: pointer;
  opacity: 0.5;

  &:hover {
    opacity: 1;
  }

  svg path {
    fill: var(--primaryBlack);
  }
`;

const TrashWrapper = styled.div`
  cursor: pointer;
  opacity: 0.5;

  &:hover {
    opacity: 1;
  }

  svg path {
    fill: var(--tertiaryRed);
  }
`;

const ReminderItem = styled.div`
  padding: 16px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  border-bottom: 1px solid var(--accentGraySecond);
`;

const Title = styled.div`
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
`;

const PopoverTitle = styled.div`
  color: var(--primaryBlack50);
  display: flex;
  padding: 10px 12px;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  justify-content: flex-end;
  border-bottom: 1px solid var(--accentGraySecond);
`;

export const UnsentRemindersPopup = ({ XOffset, YOffset, unsentReminders, onRemiderClick }) => {
  const { formatDateWithLocale } = useContext(AppContext);
  const { bulkRemoveReminders, removeBillingEmailReminder, editBillingEmailReminder } = useContext(BillingContext);

  const [confirmReminderToSkip, setConfirmReminderToSkip] = useState();

  const handleConfirmedSkipReminder = () => {
    removeBillingEmailReminder({
      emailReminderObject: confirmReminderToSkip,
      toDoMessage: 'reminder',
    });
    setConfirmReminderToSkip(null);
  };

  return (
    <Popover XOffset={XOffset} YOffset={YOffset} zIndex={51} width="260px">
      <PopoverTitle>
        {!!unsentReminders?.length && (
          <TableActionButton
            data-cy="invoices-table__actions__skip-all-reminders"
            red
            onClick={() => {
              bulkRemoveReminders({ data: unsentReminders?.map(({ id }) => id) });
            }}
            active
          >
            Skip All
          </TableActionButton>
        )}
      </PopoverTitle>

      {unsentReminders?.map((reminder) => (
        <ReminderItem key={reminder.id} data-cy="invoices-table__actions__reminder-row">
          <Title>{formatDateWithLocale(reminder?.scheduled_for)}</Title>

          <Row gap="4px">
            <SwitchWithLabel
              name="invoices-table__actions__auto-send-reminders-toggle"
              labelSize="10px"
              labelFirst
              onChange={async (value) => {
                await editBillingEmailReminder({
                  id: reminder?.id,
                  send: false,
                  data: {
                    ...reminder,
                    body: convertToPlainText(reminder?.body),
                    subject: convertToPlainText(reminder?.subject),
                    auto_send: value,
                  },
                  isPreview: false,
                });
              }}
              checked={reminder?.auto_send}
              label={<i>Auto-send</i>}
            />

            <LinkIconWrapper onClick={() => onRemiderClick(reminder)}>
              <ExternalLinkIcon size="16px" style={{ display: 'flex' }} />
            </LinkIconWrapper>

            <TrashWrapper
              data-cy="invoices-table__actions__remove-reminder"
              onClick={(event) => {
                event.stopPropagation();
                setConfirmReminderToSkip(reminder);
              }}
            >
              <TrashIcon width={16} height={16} style={{ display: 'flex' }} />
            </TrashWrapper>

            {confirmReminderToSkip?.id && confirmReminderToSkip?.id === reminder?.id && (
              <Popover XOffset={200} YOffset={40} zIndex={51} darkMode width="200px">
                <PopoverPrompt>Are you sure you want to skip this reminder?</PopoverPrompt>
                <PopoverActions>
                  <PopoverButton onClick={() => setConfirmReminderToSkip(null)}>No</PopoverButton>
                  <PopoverButton data-cy="invoices-table__skip-submit" onClick={handleConfirmedSkipReminder}>
                    Yes
                  </PopoverButton>
                </PopoverActions>
              </Popover>
            )}
          </Row>
        </ReminderItem>
      ))}
    </Popover>
  );
};
