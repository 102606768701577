import { safeParseJson, safeStringifyJson } from './jsonUtils';

export const LOCAL_STORAGE_KEYS = {
  GOALS_SELECTED_KEY: 'goals-selected',
};

const CLEAR_STORAGE_KEYS = [LOCAL_STORAGE_KEYS.GOALS_SELECTED_KEY];

export const getLocalStorage = (key, fallback = null, safeJson = false) => {
  if (safeJson) {
    return safeParseJson(localStorage.getItem(key), fallback);
  }
  return localStorage.getItem(key) ?? fallback;
};

export const setLocalStorage = (key, value, safeJson = false) => {
  if (safeJson) {
    localStorage.setItem(key, safeStringifyJson(value));
  } else {
    localStorage.setItem(key, value);
  }
};

export const removeLocalStorage = (key) => {
  localStorage.removeItem(key);
};

export const clearLocalStorage = () => {
  CLEAR_STORAGE_KEYS.forEach((key) => localStorage.removeItem(key));
};
