import styled from 'styled-components';
import { Flexer, FlexerColumn } from 'components/Core';
import { CellWithIcon } from 'components/Table/CompactNumericTable';

export const ColumnsWrapper = styled(Flexer)`
  min-height: calc(95vh - 126px);
`;

export const Column = styled(Flexer)`
  flex: 1;
  flex-direction: column;
  order: ${({ order }) => order};

  padding: 20px 40px;

  &:not(:last-child) {
    border-right: 2px solid var(--accentGraySecond);
  }
`;

export const SectionWrapper = styled.div`
  margin-top: 20px;
`;

export const DroppableWrapper = styled(FlexerColumn)`
  background-color: ${({ isOver }) => isOver && 'var(--primaryBlue2)'};
`;

export const DroppableSection = styled(FlexerColumn)`
  flex-grow: 1;
  height: 92px;
  border: 1px dashed var(--primaryYellow);
  border-radius: 12px;
`;

export const DraggableItemStyled = styled.div`
  cursor: ${({ isDragDisabled, isDragging }) => (isDragDisabled ? 'auto' : isDragging ? 'grabbing' : 'grab')};
`;

export const SectionTitle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  cursor: pointer;
  font-weight: 900;
  font-size: 9px;
  line-height: 12px;
  text-transform: uppercase;

  color: var(--primaryBlack);

  svg {
    margin-left: 4px;
  }
`;

export const SectionItemCard = styled.div`
  padding: 12px;
  background: #ffffff;
  border: ${({ isDragging }) => (isDragging ? '1px solid var(--primaryYellow)' : '1px solid var(--accentGraySecond)')};
  border-radius: 12px;

  ${CellWithIcon} {
    padding-right: 0;
  }
`;

export const SectionItemCardSecondaryText = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: right;
  color: var(--primaryBlack70);
`;
