import React from 'react';
import { Header } from './Header';
import { Navbar } from './Navbar';

export const Base = ({ layoutProps, children }) => (
  <>
    <Navbar active={layoutProps.active} />
    <div className="main_content" id="main-content">
      <Header
        activePage={layoutProps.active}
        headerTitle={layoutProps.headerTitle}
        backButton={layoutProps.headerBackButton}
        titleActions={layoutProps.titleActions}
        headerRight={layoutProps.headerRight}
      />
      {children}
    </div>
  </>
);
