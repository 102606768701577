import React, { createContext, useState } from 'react';

export const ReportsContext = createContext();

export const ReportsContextProvider = ({ children }) => {
  const [reportsListSearchTerm, setReportsListSearchTerm] = useState();

  return (
    <ReportsContext.Provider
      value={{
        reportsListSearchTerm,
        setReportsListSearchTerm,
      }}
    >
      {children}
    </ReportsContext.Provider>
  );
};
