import React from 'react';
import { useTable } from 'react-table';
import dayjs from 'dayjs';
import { SNAPSHOT_METRIC_BY_KEY } from 'consts/snapshotMetrics';
import { AddNewButton, BinButton } from 'components/Buttons';
import { getIntervalForPeriodsArray } from './utils';
import {
  GoalsTableContainer,
  GoalsTableHeader,
  GoalsTableHeaderRow,
  GoalsTableHeaderColumn,
  GoalsTableBody,
  GoalsTableRow,
  GoalsTableCell,
  NameText,
  ForMetricText,
  ColFlexer,
  ActionButtonsWrapper,
  HeaderContainer,
  CreateNewGoalButton,
  NoDataInfo,
} from './styles';

export const GoalsTable = ({ onOpenGoalForm, onDeleteGoal, data }) => {
  const columns = React.useMemo(
    () => [
      {
        Header: <HeaderContainer> NAME </HeaderContainer>,
        accessor: 'name',
        Cell: ({ cell: { value }, row }) => {
          const forMetric = row.original['for_metric'];

          return (
            <ColFlexer
              onClick={() => {
                onOpenGoalForm(row.original.id);
              }}
            >
              <NameText> {value} </NameText>
              <ForMetricText> {SNAPSHOT_METRIC_BY_KEY[forMetric].label} </ForMetricText>
            </ColFlexer>
          );
        },
      },
      {
        Header: <HeaderContainer> PERIOD </HeaderContainer>,
        accessor: 'periods',
        Cell: ({ cell: { value } }) => {
          if (value === null || value.length === 0) return 'No periods';

          const { min, max } = getIntervalForPeriodsArray({ periodsArray: value });
          return `${min.format('MMM YYYY')} - ${max.format('MMM YYYY')}`;
        },
      },
      {
        Header: <HeaderContainer> CREATED </HeaderContainer>,
        accessor: 'created_at',
        Cell: ({ cell: { value } }) => dayjs(value).format('YYYY.MM.DD'),
      },
      {
        Header: <HeaderContainer> UPDATED </HeaderContainer>,
        accessor: 'updated_at',
        Cell: ({ cell: { value } }) => dayjs(value).format('YYYY.MM.DD'),
      },
      {
        Header: (
          <AddNewButton
            onClick={() => {
              onOpenGoalForm(null);
            }}
          >
            ADD NEW
          </AddNewButton>
        ),
        accessor: 'actions',
        disableSortBy: true,
        width: 100,
        Cell: ({ row }) => {
          return (
            <ActionButtonsWrapper>
              <BinButton onClick={() => onDeleteGoal(row.original.id)} />
            </ActionButtonsWrapper>
          );
        },
      },
    ],
    [onOpenGoalForm, onDeleteGoal],
  );

  const memoData = React.useMemo(() => {
    return data ?? [];
  }, [data]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data: memoData });

  return (
    <GoalsTableContainer {...getTableProps()}>
      <GoalsTableHeader>
        {headerGroups.map((headerGroup) => (
          <GoalsTableHeaderRow {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <GoalsTableHeaderColumn width={column.width} {...column.getHeaderProps()}>
                {column.render('Header')}
              </GoalsTableHeaderColumn>
            ))}
          </GoalsTableHeaderRow>
        ))}
      </GoalsTableHeader>
      {data?.length > 0 ? (
        <GoalsTableBody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <GoalsTableRow {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <GoalsTableCell width={cell.column.width} {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </GoalsTableCell>
                  );
                })}
              </GoalsTableRow>
            );
          })}
          <GoalsTableRow>
            <CreateNewGoalButton
              onClick={() => {
                onOpenGoalForm(null);
              }}
            >
              CREATE NEW GOAL
            </CreateNewGoalButton>
          </GoalsTableRow>
        </GoalsTableBody>
      ) : (
        <NoDataInfo />
      )}
    </GoalsTableContainer>
  );
};
