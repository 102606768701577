import React from 'react';
import styled from 'styled-components';

import noUpdatesGraphic from 'images/no-updates-graphic.png';
import { Centerer, Spacer } from 'components/Core/Flex';

const Wrapper = styled(Centerer)`
  flex-direction: column;
`;

const Heading = styled.div`
  font-weight: 900;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 12px;
`;

const Desc = styled.p`
  font-size: 12px;
  line-height: 16px;
  opacity: 0.5;
  margin-bottom: 12px;
`;

export const NoUpdatesGraphic = () => (
  <Wrapper data-cy="external-updates__graphic ">
    <img src={noUpdatesGraphic} alt="notebook" />
    <Spacer height="20px" />
    <Heading>You have successfully processed all updates!</Heading>
    <Desc>Your reports will now take your recent changes into account. </Desc>
  </Wrapper>
);
