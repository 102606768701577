import styled from 'styled-components';

import { ReactComponent as Icon } from 'images/icon_dot.svg';

export const GreyDot = styled(Icon)`
  width: ${(props) => props.size ?? '4px !important'};
  height: ${(props) => props.size ?? '4px !important'};

  margin-left: ${(props) => props.spacing ?? '8px'};
  margin-right: ${(props) => props.spacing ?? '8px'};
`;
