import styled from 'styled-components';

export const EditSubtitleText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--primaryBlack30);
`;

export const DeleteWarningCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  padding: 16px;
  margin-bottom: 28px;

  height: 72px;

  background: var(--primaryRed5);
  border-radius: 8px;

  div:first-child {
    font-style: normal;
    font-weight: 900;
    font-size: 14px;
    line-height: 20px;
  }

  div:nth-child(2) {
    font-weight: 900;
    font-size: 10px;
    line-height: 12px;
    text-transform: uppercase;
    color: var(--primaryBlack30);
  }
`;

export const ShowHideButton = styled.div`
  color: var(--primaryBlue);

  &:hover {
    cursor: pointer;
  }
`;

export const ScenarioBucketLabel = styled.div`
  display: flex;
  align-items: center;
  height: 24px;

  margin-left: ${({ marginLeft }) => marginLeft};
  padding: 2px 4px;
  background: var(--primaryYellow10);
  border-radius: 4px;

  font-weight: 700;
  font-size: 14px;
  line-height: 20px;

  color: var(--primaryBlack);
`;
