import { useQuery } from 'react-query';
import { getCohorts } from './requests';

export const CACHE_KEY = 'cohorts';

export const useCohortsAPI = ({ orgId, params, autoFetch = true }) => {
  const dataKey = [CACHE_KEY, orgId, params];

  const { data, error, isLoading, isFetching, refetch, dataUpdatedAt } = useQuery(
    dataKey,
    () => getCohorts({ orgId, params }),
    {
      enabled: autoFetch,
    },
  );

  return {
    data,
    error,
    isLoading,
    isFetching,
    dataUpdatedAt,
    operations: {
      refetch,
    },
  };
};
