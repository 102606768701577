import styled from 'styled-components';
import submodalFooterImg from 'images/transaction-modal-sidebar-footer.svg';

export const SidebarFooter = styled.footer`
  font-size: 14px;
  line-height: 18px;
  color: rgba(0, 21, 46, 0.2);
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 24px 0;
  width: 100%;
  text-align: center;
  background-image: url(${submodalFooterImg});
  background-repeat: no-repeat;
  background-position: right bottom;
`;
