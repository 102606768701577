import { components } from 'react-select';
import { FormikCustomInput } from 'components/Controls';
import { SelectDropdownButton } from 'components/Buttons';
import { CentererVertical, FlexerColumn } from 'components/Core';
import { TooltipContainer } from 'components/Tooltip';
import { QuestionIcon } from 'components/Icons';
import { renderCustomerIdCell } from 'views/Transactions/TransactionsCsvUpload/fuzzyCells';
import {
  INTERNAL_CUSTOMER_ID_SOURCE,
  NAME_MATCHING_SOURCE,
  SOURCE_TYPES,
} from 'views/Transactions/TransactionsCsvUpload/consts';
import { AddMetadataDropdown } from 'views/Transactions/TransactionsCsvUpload/AddMetadataDropdown';
import { ColumnMapperSelector } from 'shared/CsvUpload/CsvTable/CsvTable';
import {
  IdSourceSelectorWrapper,
  HeaderSelector,
  SelectorTitle,
  CustomOption,
  CustomOptionKey,
  CustomOptionValue,
} from 'shared/CsvUpload/styles';
import { DEFAULT_CUSTOMER_MAPPER } from './consts';

const { Option } = components;

export const CustomSingleOption = (props) => {
  // optimization for large lists
  delete props.innerProps.onMouseMove;
  delete props.innerProps.onMouseOver;

  return (
    <Option {...props}>
      <CustomOption>
        <CustomOptionKey>{props.data.value}</CustomOptionKey>
        <CustomOptionValue>{props.data.label}</CustomOptionValue>
      </CustomOption>
    </Option>
  );
};

const SelectDropdownWithTooltip = ({ name, selected, options, onSelect }) => (
  <SelectDropdownButton
    name={name}
    noMargin
    selected={selected}
    options={options}
    fontSize="12px"
    onSelect={onSelect}
    toolTipContent="Click to change type"
    toolTipWidth="125px"
  />
);

export const getColumns = ({
  objects,
  formRef,
  csvColumns,
  csvColumnsMapper,
  setCsvColumnsMapper,
  selectedMetadataFields,
  setSelectedMetadataFields,

  customers,
  customersIdSources,
  customerIdSourceAndType,
  setCustomerIdSourceAndType,
  parentCustomerIdSourceAndType,
  setParentCustomerIdSourceAndType,
  uploadCsvCustomersIdSource,

  fuzzyRowsMapper,
  setFuzzyRowsMapper,

  defaultMapper,
}) => {
  const toggleSelectedMetadataField = (field) => {
    if (!selectedMetadataFields.includes(field)) {
      setSelectedMetadataFields([...selectedMetadataFields, field]);
    } else {
      setSelectedMetadataFields(selectedMetadataFields.filter((f) => f !== field));
    }
  };

  const handleCustomerIdSourceChange = (setter) => (newValue) => {
    setCsvColumnsMapper(defaultMapper);

    setter({
      type: [uploadCsvCustomersIdSource, NAME_MATCHING_SOURCE, INTERNAL_CUSTOMER_ID_SOURCE].includes(newValue)
        ? SOURCE_TYPES.INTERNAL
        : SOURCE_TYPES.EXTERNAL,
      source: newValue,
    });
  };

  return [
    {
      Header: () => (
        <FlexerColumn>
          <HeaderSelector>
            <SelectorTitle>Metadata</SelectorTitle>
          </HeaderSelector>
          <AddMetadataDropdown
            selectedMetadataFields={selectedMetadataFields}
            toggleSelectedMetadataField={toggleSelectedMetadataField}
            objects={objects}
          />
        </FlexerColumn>
      ),
      width: 80,
      accessor: 'status',
      Cell: () => <></>,
    },
    ...(selectedMetadataFields?.map((metadataField) => {
      const fieldQualifier = `metadata.${metadataField}`;

      return {
        Header: () => (
          <HeaderSelector textTransform="none">
            <SelectorTitle>Metadata: {metadataField}</SelectorTitle>
            <ColumnMapperSelector
              field={fieldQualifier}
              csvColumnsMapper={csvColumnsMapper}
              setCsvColumnsMapper={setCsvColumnsMapper}
              csvColumns={csvColumns}
            />
          </HeaderSelector>
        ),
        width: 180,
        accessor: fieldQualifier,
        Cell: ({ row }) => (
          <FormikCustomInput
            style={{ height: 32 }}
            errorWithoutTooltip
            placeholder="Enter value..."
            width="100%"
            name={`[${row.original.rowIndex}].${fieldQualifier}`}
          />
        ),
      };
    }) ?? []),
    {
      Header: () => (
        <IdSourceSelectorWrapper>
          <SelectDropdownWithTooltip
            name="customers-ids-source"
            selected={customerIdSourceAndType?.source}
            options={customersIdSources}
            onSelect={handleCustomerIdSourceChange(setCustomerIdSourceAndType)}
          />
          <ColumnMapperSelector
            field="customer_id"
            csvColumnsMapper={csvColumnsMapper}
            setCsvColumnsMapper={setCsvColumnsMapper}
            csvColumns={csvColumns}
          />
        </IdSourceSelectorWrapper>
      ),
      width: 180,
      accessor: 'customer_id',
      Cell: (cellProps) =>
        renderCustomerIdCell({
          ...cellProps,
          customerIdSourceAndType,
          customers,
          formCurrent: formRef?.current,
          fuzzyRowsMapper,
          setFuzzyRowsMapper,
          uploadCsvCustomersIdSource,
        }),
    },
    {
      Header: () => (
        <IdSourceSelectorWrapper>
          <CentererVertical gap="2px">
            <SelectDropdownWithTooltip
              name="parent-customers-ids-source"
              selected={parentCustomerIdSourceAndType?.source}
              options={customersIdSources}
              onSelect={handleCustomerIdSourceChange(setParentCustomerIdSourceAndType)}
            />
            <SelectorTitle noMargin>(parent)</SelectorTitle>
          </CentererVertical>
          <ColumnMapperSelector
            field="parent_customer_id"
            csvColumnsMapper={csvColumnsMapper}
            setCsvColumnsMapper={setCsvColumnsMapper}
            csvColumns={csvColumns}
          />
        </IdSourceSelectorWrapper>
      ),
      width: 200,
      accessor: 'parent_customer_id',
      Cell: ({ row }) => (
        <FormikCustomInput
          style={{ height: 32 }}
          errorWithoutTooltip
          width="100%"
          name={`[${row.original.rowIndex}].parent_customer_id`}
        />
      ),
    },
    {
      Header: () => (
        <HeaderSelector>
          <SelectorTitle>{DEFAULT_CUSTOMER_MAPPER['name']}</SelectorTitle>
          <ColumnMapperSelector
            field="name"
            csvColumnsMapper={csvColumnsMapper}
            setCsvColumnsMapper={setCsvColumnsMapper}
            csvColumns={csvColumns}
          />
        </HeaderSelector>
      ),
      accessor: 'name',
      width: 180,
      Cell: ({ row }) => (
        <FormikCustomInput
          style={{ height: 32 }}
          errorWithoutTooltip
          placeholder="Enter name..."
          width="100%"
          name={`[${row.original.rowIndex}].name`}
        />
      ),
    },
    {
      Header: () => (
        <HeaderSelector>
          <SelectorTitle>{DEFAULT_CUSTOMER_MAPPER['email']}</SelectorTitle>
          <ColumnMapperSelector
            field="email"
            csvColumnsMapper={csvColumnsMapper}
            setCsvColumnsMapper={setCsvColumnsMapper}
            csvColumns={csvColumns}
          />
        </HeaderSelector>
      ),
      accessor: 'email',
      width: 180,
      Cell: ({ row }) => (
        <FormikCustomInput
          style={{ height: 32 }}
          errorWithoutTooltip
          placeholder="Enter email..."
          width="100%"
          name={`[${row.original.rowIndex}].email`}
        />
      ),
    },
    {
      Header: () => (
        <HeaderSelector>
          <SelectorTitle>
            <span>{DEFAULT_CUSTOMER_MAPPER['contacts']}</span>
            <TooltipContainer
              toolTipContent={
                'To provide multiple contacts, separate them by "|". For example "abc@email.com|def@email.com"'
              }
            >
              <QuestionIcon stroke="var(--primaryBlack20)" />
            </TooltipContainer>
          </SelectorTitle>
          <ColumnMapperSelector
            field="contacts"
            csvColumnsMapper={csvColumnsMapper}
            setCsvColumnsMapper={setCsvColumnsMapper}
            csvColumns={csvColumns}
          />
        </HeaderSelector>
      ),
      accessor: 'contacts',
      width: 180,
      Cell: ({ row }) => (
        <FormikCustomInput
          style={{ height: 32 }}
          errorWithoutTooltip
          placeholder="Email addresses separated by '|'"
          width="100%"
          name={`[${row.original.rowIndex}].contacts`}
        />
      ),
    },
    {
      Header: () => (
        <HeaderSelector>
          <SelectorTitle>{DEFAULT_CUSTOMER_MAPPER['addressLine1']}</SelectorTitle>
          <ColumnMapperSelector
            field="addressLine1"
            csvColumnsMapper={csvColumnsMapper}
            setCsvColumnsMapper={setCsvColumnsMapper}
            csvColumns={csvColumns}
          />
        </HeaderSelector>
      ),
      accessor: 'addressLine1',
      width: 180,
      Cell: ({ row }) => (
        <FormikCustomInput
          style={{ height: 32 }}
          errorWithoutTooltip
          placeholder="Address line 1"
          width="100%"
          name={`[${row.original.rowIndex}].addressLine1`}
        />
      ),
    },
    {
      Header: () => (
        <HeaderSelector>
          <SelectorTitle>{DEFAULT_CUSTOMER_MAPPER['addressLine2']}</SelectorTitle>
          <ColumnMapperSelector
            field="addressLine2"
            csvColumnsMapper={csvColumnsMapper}
            setCsvColumnsMapper={setCsvColumnsMapper}
            csvColumns={csvColumns}
          />
        </HeaderSelector>
      ),
      accessor: 'addressLine2',
      width: 180,
      Cell: ({ row }) => (
        <FormikCustomInput
          style={{ height: 32 }}
          errorWithoutTooltip
          placeholder="Address line 2"
          width="100%"
          name={`[${row.original.rowIndex}].addressLine2`}
        />
      ),
    },
    {
      Header: () => (
        <HeaderSelector>
          <SelectorTitle>{DEFAULT_CUSTOMER_MAPPER['city']}</SelectorTitle>
          <ColumnMapperSelector
            field="city"
            csvColumnsMapper={csvColumnsMapper}
            setCsvColumnsMapper={setCsvColumnsMapper}
            csvColumns={csvColumns}
          />
        </HeaderSelector>
      ),
      accessor: 'city',
      width: 180,
      Cell: ({ row }) => (
        <FormikCustomInput
          style={{ height: 32 }}
          errorWithoutTooltip
          placeholder="City"
          width="100%"
          name={`[${row.original.rowIndex}].city`}
        />
      ),
    },
    {
      Header: () => (
        <HeaderSelector>
          <SelectorTitle>{DEFAULT_CUSTOMER_MAPPER['state']}</SelectorTitle>
          <ColumnMapperSelector
            field="state"
            csvColumnsMapper={csvColumnsMapper}
            setCsvColumnsMapper={setCsvColumnsMapper}
            csvColumns={csvColumns}
          />
        </HeaderSelector>
      ),
      accessor: 'state',
      width: 180,
      Cell: ({ row }) => (
        <FormikCustomInput
          style={{ height: 32 }}
          errorWithoutTooltip
          placeholder="State"
          width="100%"
          name={`[${row.original.rowIndex}].state`}
        />
      ),
    },
    {
      Header: () => (
        <HeaderSelector>
          <SelectorTitle>{DEFAULT_CUSTOMER_MAPPER['postalcode']}</SelectorTitle>
          <ColumnMapperSelector
            field="postalcode"
            csvColumnsMapper={csvColumnsMapper}
            setCsvColumnsMapper={setCsvColumnsMapper}
            csvColumns={csvColumns}
          />
        </HeaderSelector>
      ),
      accessor: 'postalcode',
      width: 180,
      Cell: ({ row }) => (
        <FormikCustomInput
          style={{ height: 32 }}
          errorWithoutTooltip
          placeholder="Postalcode"
          width="100%"
          name={`[${row.original.rowIndex}].postalcode`}
        />
      ),
    },
    {
      Header: () => (
        <HeaderSelector>
          <SelectorTitle>{DEFAULT_CUSTOMER_MAPPER['country']}</SelectorTitle>
          <ColumnMapperSelector
            field="country"
            csvColumnsMapper={csvColumnsMapper}
            setCsvColumnsMapper={setCsvColumnsMapper}
            csvColumns={csvColumns}
          />
        </HeaderSelector>
      ),
      accessor: 'country',
      width: 180,
      Cell: ({ row }) => (
        <FormikCustomInput
          style={{ height: 32 }}
          errorWithoutTooltip
          placeholder="Country"
          width="100%"
          name={`[${row.original.rowIndex}].country`}
        />
      ),
    },
  ];
};
