import { useState, useCallback } from 'react';
import { useStateWithStorage } from './useStateWithStorage';

const DEFAULT_PAGE_SIZE = 20;

export const usePagination = ({ cacheSuffix, defaultPageSize = DEFAULT_PAGE_SIZE }) => {
  const [pageSize, setPageSizeRaw] = useStateWithStorage(`${cacheSuffix}-page-size`, defaultPageSize);
  const [currentPageIndex, setCurrentPageIndex] = useState(1);

  const setPageSize = useCallback(
    (value) => {
      const newPageSize = value ?? defaultPageSize;
      // react-table natively updates pageIndex when pageSize is changed, via Math.floor(currentTopRowIndex / newPageSize)
      // https://react-table.tanstack.com/docs/api/usePagination
      // so we can emulate that, too
      const currentTopRowIndex = (currentPageIndex - 1) * pageSize + 1;
      setCurrentPageIndex(Math.floor(currentTopRowIndex / newPageSize) + 1);
      setPageSizeRaw(newPageSize);
    },
    [pageSize, defaultPageSize, currentPageIndex, setCurrentPageIndex, setPageSizeRaw],
  );

  return { pageSize, setPageSize, currentPageIndex, setCurrentPageIndex };
};
