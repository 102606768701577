import dayjs from 'dayjs';
import styled from 'styled-components';
import { Centerer } from 'components/Core';
import { EmptyStateContents, EmptyStateMainText } from 'shared/EmptyState';
import { EMOJI } from 'consts/global';

export const EmptyStateWrapper = styled(Centerer)`
  padding: 60px;
  width: 100%;
`;

export const EmptyCustomersTable = ({ month, entityName }) => (
  <EmptyStateWrapper>
    <EmptyStateContents>
      <EmptyStateMainText>
        You don't have any {entityName} in {dayjs(`${month}-15`).format('MMMM YYYY')} {EMOJI.NO_MOUTH.icon}
      </EmptyStateMainText>
    </EmptyStateContents>
  </EmptyStateWrapper>
);
