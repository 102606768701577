import React, { useState, useContext, useMemo, useEffect, useCallback } from 'react';
import _ from 'lodash';
import Checkbox from 'antd/lib/checkbox/Checkbox';

import { AppContext } from 'AppContext';
import { useExternalUpdatesAPI } from 'api/externalUpdates';
import { ReactComponent as ArrowUp } from 'images/arrow_up_bold.svg';
import { TooltipContainer } from 'components/Tooltip';
import { Centerer, Spacer } from 'components/Core';
import { AllRowsSelectorDropdown } from 'components/Table/AllRowsSelectorDropdown';
import { CircleLoader, TimeLoaderContainer, TimeLoader } from 'components/Loaders';
import { InfoIcon } from 'components/Icons';
import { LinkBackButton } from 'components/Buttons';
import { Header } from 'shared/Layout';
import { TransactionSingleContent, TRANSACTION_MODAL_MODE } from 'shared/TransactionContent';
import { useConfirmModal } from 'shared/ConfirmModal';
import { normalizeAmountFields } from 'models/externalUpdates';

import { Table } from './Table';
import { ActionsDropdown } from './Components/ActionsDropdown';
import { TableCell } from './Components/TableCell';
import { NoUpdatesGraphic } from './Components/NoUpdatesGraphic';
import { useConfirmResyncModal } from './Components/ConfirmResyncModal';
import { ArrowContainer } from './styles';
import { EXTERNAL_UPDATES_SUPPORTED_RESOURCES, EXTERNAL_UPDATES_TABLE_COLUMNS } from './consts';
import { onAction, formatDate, generateChanges, compareDates, sortingFunction, makeTableCell } from './utils';

const TableCoordinator = () => {
  const { organizations, orgId } = useContext(AppContext);

  const hasSpreadExternalUpdates = organizations?.[0]?.hasSpreadExternalUpdates;

  const {
    loading,
    data,
    isFetching,
    operations: { resolveExternalUpdate, bulkApproveUpdates, bulkResolveUpdates },
  } = useExternalUpdatesAPI({
    orgId,
    params: { page: 1, limit: 50000, objectType: EXTERNAL_UPDATES_SUPPORTED_RESOURCES.TRANSACTION },
  });

  const [combinedIsFetching, setCombinedIsFetching] = useState(false);
  const [filtersToShow, setFiltersToShow] = useState(null);

  useEffect(() => {
    setCombinedIsFetching(
      isFetching || resolveExternalUpdate.isLoading || bulkApproveUpdates.isLoading || bulkResolveUpdates.isLoading,
    );
  }, [isFetching, resolveExternalUpdate.isLoading, bulkApproveUpdates.isLoading, bulkResolveUpdates.isLoading]);

  const dataForTable = useMemo(() => {
    const mappedData = data?.data?.map((datum) => {
      normalizeAmountFields(datum.externalUpdate.update_data);

      // normalize the end_date
      if (
        typeof datum.externalUpdate.update_data.end_date === 'string' &&
        datum.externalUpdate.update_data.end_date.trim() === ''
      ) {
        datum.externalUpdate.update_data.end_date = null;
      }

      const returned = {
        ...datum,
        metadata: {
          keys: _.uniq([
            ...Object.keys(datum.targetObject.metadata ?? {}),
            ...Object.keys(datum.externalUpdate.update_data.metadata ?? {}),
          ]),
          original: datum.targetObject.metadata ?? {},
          updated: datum.externalUpdate.update_data.metadata ?? {},
        },
        changedFields: {},
      };

      const changedFields = generateChanges({
        source: datum.targetObject,
        target: datum.externalUpdate?.update_data,
        fields: Object.keys(EXTERNAL_UPDATES_TABLE_COLUMNS),
      });

      for (const key in changedFields) {
        if (
          [
            EXTERNAL_UPDATES_TABLE_COLUMNS.new_spreads.key,
            EXTERNAL_UPDATES_TABLE_COLUMNS.changed_spreads.key,
            EXTERNAL_UPDATES_TABLE_COLUMNS.deleted_spreads.key,
          ].includes(key)
        ) {
          returned.changedFields[key] = changedFields[key];
        } else {
          returned.changedFields[key] = true;
        }
      }

      return returned;
    });

    // We only set this once per page load
    if (!filtersToShow && mappedData) {
      const filters = new Set();
      mappedData.forEach((change) => {
        for (const [key, value] of Object.entries(change.changedFields)) if (value) filters.add(key);
      });
      setFiltersToShow(filters);
    }

    return mappedData;
  }, [data, filtersToShow]);

  const [dataUnderEdit, setDataUnderEdit] = useState(null);
  const [dataToResync, setDataToResync] = useState(null);

  const handleFormSubmitted = async ({ data }) => {
    await resolveExternalUpdate.mutateAsync({
      id: dataUnderEdit.externalUpdate.id,
      orgId,
      //TODO [TC 2021-11-25]: Rename to actionType, actionData to avoid confusing names (needs backend changes)
      data: { actionType: dataUnderEdit.actionType, data: data, skipInvoicesSync: true },
    });
  };

  const handleResyncSubmitted = () => {
    resolveExternalUpdate.mutate({
      id: dataToResync.externalUpdate.id,
      orgId,
      data: { actionType: dataToResync.actionType },
    });
  };

  const [selectedIds, setSelectedIds] = useState([]);

  const onSelectedRowsChange = useCallback(
    (rowIdsObject) => {
      // Filter out subrows (like 0.0, 15.2 etc), we only want the upper level rows
      const rowIds = Object.keys(rowIdsObject).filter((rowKey) => !rowKey.includes('.'));
      setSelectedIds(rowIds.map((rowId) => data?.data[rowId]?.externalUpdate.id));
    },
    [data, setSelectedIds],
  );

  const onSelectedBulkAction = (actionType) => {
    if (actionType === 'overwrite') openBulkConfirmModal();
    if (actionType === 'dismiss') openBulkDismissModal();
  };

  const { openConfirmResyncModal, ConfirmResyncModal } = useConfirmResyncModal({
    data: dataToResync,
    onConfirm: handleResyncSubmitted,
  });

  const columns = useMemo(() => {
    const handleEditExternalUpdate = ({ data, actionType }) => {
      setDataUnderEdit({
        transaction: {
          id: data.targetObject.id,
        },
        updateData: data.externalUpdate.update_data,
        externalUpdate: data.externalUpdate,
        actionType,
      });
    };

    const handleResyncAction = ({ data, actionType }) => {
      setDataToResync({
        targetObject: data.targetObject,
        externalUpdate: data.externalUpdate,
        actionType,
      });
      openConfirmResyncModal();
    };

    // This handler figures out what to do based on what dropdown item the user has picked
    // TODO [TC 2022-06-16]: Refactor this handler
    const actionHandler = ({ data, actionType }) =>
      onAction({
        data,
        actionType,
        resolveExternalUpdate,
        handleEditExternalUpdate,
        handleResyncAction,
      });

    return generateColumns({ onAction: actionHandler, hasSpreadExternalUpdates });
  }, [resolveExternalUpdate, setDataUnderEdit, openConfirmResyncModal, hasSpreadExternalUpdates]);

  const { openConfirmModal: openBulkConfirmModal, ConfirmModal: BulkConfirmModal } = useConfirmModal({
    title: 'Transactions Auto Confirmation',
    content: (
      <div>
        <b>Do you want to confirm {selectedIds?.length} changes?</b>
        <br />
        We recommend manual confirmation unless you are confident that the imports are working perfectly. You can edit
        after confirming, too.
      </div>
    ),
    closeButtonText: 'No, cancel',
    confirmButtonText: 'Yes, confirm',
    onConfirm: () => {
      bulkResolveUpdates.mutate({ orgId, externalUpdateIds: selectedIds, actionType: 'overwrite' });
    },
  });

  const { openConfirmModal: openBulkDismissModal, ConfirmModal: BulkDismissModal } = useConfirmModal({
    title: 'Transactions Auto Confirmation',
    content: (
      <div>
        <b>Do you want to dismiss {selectedIds?.length} changes?</b>
      </div>
    ),
    closeButtonText: 'No, cancel',
    confirmButtonText: 'Yes, dismiss',
    onConfirm: () => {
      bulkResolveUpdates.mutate({ orgId, externalUpdateIds: selectedIds, actionType: 'dismiss' });
    },
  });

  return (
    <>
      <Header
        activePage="transactions"
        headerTitle="Transaction Updates"
        headerLeft={
          <Centerer>
            <LinkBackButton to="/transactions" data-cy="external-updates__back-button">
              All Transactions
            </LinkBackButton>
            <b>Transaction Updates </b>
            <Spacer width="10px" />
            <TooltipContainer
              width={400}
              toolTipContent="You can confirm or dismiss updates using the list or all at once.
                The yellow highlighting of the fields shows what has changed in the new version,
                hover the mouse cursor to find out what the value was before the update."
            >
              <Centerer>
                <InfoIcon size="20px" fill="var(--primaryGreen)" />
              </Centerer>
            </TooltipContainer>
          </Centerer>
        }
        headerRight={
          <>
            {combinedIsFetching && (
              <>
                <CircleLoader data-cy="external-updates__fetching-loader" width={24} height={24} />
                <Spacer width="20px" />
              </>
            )}
          </>
        }
      />

      <TimeLoaderContainer isLoading={loading || dataForTable === undefined}>
        {loading || dataForTable === undefined ? (
          <TimeLoader pageName="externalUpdates" />
        ) : dataForTable?.length > 0 ? (
          <Table
            data={dataForTable}
            columns={columns}
            onSelectedRowsChange={onSelectedRowsChange}
            onSelectedBulkAction={onSelectedBulkAction}
            filtersToShow={filtersToShow}
          />
        ) : (
          <NoUpdatesGraphic />
        )}
      </TimeLoaderContainer>

      {dataUnderEdit ? (
        <TransactionSingleContent
          mode={TRANSACTION_MODAL_MODE.EXTERNAL_UPDATE}
          changedData={dataUnderEdit.updateData}
          transaction={{ id: dataUnderEdit.transaction.id }}
          organization={organizations[0]}
          closeModal={() => {
            setDataUnderEdit(null);
          }}
          onTransactionDeleted={() => {
            setDataUnderEdit(null);
          }}
          onTransactionDuplicated={() => {}}
          onFormSubmitted={async ({ data, externalUpdatesMode }) => {
            dataUnderEdit.actionType !== 'edit_and_create' &&
              (await handleFormSubmitted({ data, externalUpdatesMode }));
          }}
        />
      ) : null}

      <BulkConfirmModal />
      <BulkDismissModal />

      <ConfirmResyncModal />
    </>
  );
};

const generateColumns = ({ onAction, hasSpreadExternalUpdates }) => {
  const columns = [
    {
      Header: (props) => (
        <AllRowsSelectorDropdown
          getToggleAllPageRowsSelectedProps={props?.getToggleAllPageRowsSelectedProps}
          getToggleAllRowsSelectedProps={props?.getToggleAllRowsSelectedProps}
          {...props}
        />
      ),
      accessor: 'checked',
      width: 32,
      Cell: ({ row }) => (
        <Checkbox
          data-cy="external-updates__checkbox"
          checked={row.isSelected}
          onClick={() => {
            row.toggleRowSelected();
          }}
        />
      ),
      noPaddingRight: true,
      noInitialSortBy: true,
      disableFilters: true,
      disableSortBy: true,
    },
    {
      accessor: 'actions',
      disableSortBy: true,
      width: 65,
      Cell: ({ row }) => (
        <ActionsDropdown
          onAction={async (actionType) => {
            await onAction({ data: row.original, actionType });
          }}
          rowType={row.original.externalUpdate.type}
          dataCyPrefix="external-updates"
        />
      ),
    },
    {
      Header: 'Created At',
      accessor: EXTERNAL_UPDATES_TABLE_COLUMNS.created_at.key,
      sortType: (rowA, rowB) =>
        compareDates(rowA.original.externalUpdate.created_at, rowB.original.externalUpdate.created_at) ? 1 : -1,
      width: 160,
      Cell: ({ row }) => (
        <Centerer
          onClick={() => {
            if (row.original.metadata.keys.length > 0) row.toggleRowExpanded();
          }}
        >
          <TableCell original={formatDate(row.original.externalUpdate.created_at)} />
          {row.original.metadata.keys.length > 0 && (
            <ArrowContainer active={row.isExpanded} highlight={row.original.changedFields.metadata ?? false}>
              <ArrowUp />
            </ArrowContainer>
          )}
        </Centerer>
      ),
    },
    {
      Header: 'Name',
      accessor: EXTERNAL_UPDATES_TABLE_COLUMNS.name.key,
      sortType: (rowA, rowB) => sortingFunction({ rowA, rowB, fieldName: EXTERNAL_UPDATES_TABLE_COLUMNS.name.key }),
      width: 250,
      Cell: ({ row }) =>
        makeTableCell({
          row,
          key: EXTERNAL_UPDATES_TABLE_COLUMNS.name.key,
          onClick: async () => {
            await onAction({ data: row.original, actionType: 'edit' });
          },
          isLink: true,
        }),
    },
    {
      Header: 'Customer',
      accessor: EXTERNAL_UPDATES_TABLE_COLUMNS.customer_name.key,
      sortType: (rowA, rowB) =>
        sortingFunction({ rowA, rowB, fieldName: EXTERNAL_UPDATES_TABLE_COLUMNS.customer_name.key }),
      width: 200,
      Cell: ({ row }) =>
        makeTableCell({
          row,
          key: EXTERNAL_UPDATES_TABLE_COLUMNS.customer_name.key,
          onClick: () =>
            window
              .open(`${window.location.origin}/customers/${row.original.targetObject?.customer_id}`, '_blank')
              .focus(),
          isLink: true,
        }),
    },
    {
      Header: 'Date',
      accessor: EXTERNAL_UPDATES_TABLE_COLUMNS.date.key,
      sortType: (rowA, rowB) =>
        sortingFunction({
          rowA,
          rowB,
          fieldName: EXTERNAL_UPDATES_TABLE_COLUMNS.date.key,
          compareFunction: compareDates,
        }),
      width: 120,
      Cell: ({ row }) =>
        makeTableCell({
          row,
          key: EXTERNAL_UPDATES_TABLE_COLUMNS.date.key,
          formatter: formatDate,
        }),
    },
    {
      Header: 'Total',
      accessor: EXTERNAL_UPDATES_TABLE_COLUMNS.amount.key,
      sortType: (rowA, rowB) => sortingFunction({ rowA, rowB, fieldName: EXTERNAL_UPDATES_TABLE_COLUMNS.amount.key }),
      width: 80,
      Cell: ({ row }) =>
        makeTableCell({
          row,
          key: EXTERNAL_UPDATES_TABLE_COLUMNS.amount.key,
        }),
    },
    {
      Header: 'Recurring',
      accessor: EXTERNAL_UPDATES_TABLE_COLUMNS.recurring_amount.key,
      sortType: (rowA, rowB) =>
        sortingFunction({ rowA, rowB, fieldName: EXTERNAL_UPDATES_TABLE_COLUMNS.recurring_amount.key }),
      width: 80,
      Cell: ({ row }) =>
        makeTableCell({
          row,
          key: EXTERNAL_UPDATES_TABLE_COLUMNS.recurring_amount.key,
        }),
    },
    {
      Header: 'Seats',
      accessor: EXTERNAL_UPDATES_TABLE_COLUMNS.seats.key,
      sortType: (rowA, rowB) => sortingFunction({ rowA, rowB, fieldName: EXTERNAL_UPDATES_TABLE_COLUMNS.seats.key }),
      width: 80,
      Cell: ({ row }) =>
        makeTableCell({
          row,
          key: EXTERNAL_UPDATES_TABLE_COLUMNS.seats.key,
        }),
    },
    {
      Header: 'Type',
      accessor: EXTERNAL_UPDATES_TABLE_COLUMNS.recognition.key,
      sortType: (rowA, rowB) =>
        sortingFunction({ rowA, rowB, fieldName: EXTERNAL_UPDATES_TABLE_COLUMNS.recognition.key }),
      width: 100,
      Cell: ({ row }) =>
        makeTableCell({
          row,
          key: EXTERNAL_UPDATES_TABLE_COLUMNS.recognition.key,
        }),
    },
    {
      Header: 'Product',
      accessor: EXTERNAL_UPDATES_TABLE_COLUMNS.product_name.key,
      sortType: (rowA, rowB) =>
        sortingFunction({ rowA, rowB, fieldName: EXTERNAL_UPDATES_TABLE_COLUMNS.product_name.key }),
      width: 130,
      Cell: ({ row }) =>
        makeTableCell({
          row,
          key: EXTERNAL_UPDATES_TABLE_COLUMNS.product_name.key,
        }),
    },
    {
      Header: 'Start',
      accessor: EXTERNAL_UPDATES_TABLE_COLUMNS.start_date.key,
      sortType: (rowA, rowB) =>
        sortingFunction({
          rowA,
          rowB,
          fieldName: EXTERNAL_UPDATES_TABLE_COLUMNS.start_date.key,
          compareFunction: compareDates,
        }),
      width: 120,
      Cell: ({ row }) =>
        makeTableCell({
          row,
          key: EXTERNAL_UPDATES_TABLE_COLUMNS.start_date.key,
          formatter: formatDate,
        }),
    },
    {
      Header: 'End',
      accessor: EXTERNAL_UPDATES_TABLE_COLUMNS.end_date.key,
      sortType: (rowA, rowB) =>
        sortingFunction({
          rowA,
          rowB,
          fieldName: EXTERNAL_UPDATES_TABLE_COLUMNS.end_date.key,
          compareFunction: compareDates,
        }),
      width: 120,
      Cell: ({ row }) =>
        makeTableCell({
          row,
          key: EXTERNAL_UPDATES_TABLE_COLUMNS.end_date.key,
          formatter: formatDate,
        }),
    },
    {
      Header: 'Replaced By',
      accessor: EXTERNAL_UPDATES_TABLE_COLUMNS.replaced_by.key,
      width: 120,
      Cell: ({ row }) =>
        makeTableCell({
          row,
          key: EXTERNAL_UPDATES_TABLE_COLUMNS.replaced_by.key,
          formatter: (value) => value || 'None',
        }),
    },
    {
      Header: 'Renewal Deadline',
      accessor: EXTERNAL_UPDATES_TABLE_COLUMNS.renewal_deadline.key,
      sortType: (rowA, rowB) =>
        sortingFunction({
          rowA,
          rowB,
          fieldName: EXTERNAL_UPDATES_TABLE_COLUMNS.renewal_deadline.key,
          compareFunction: compareDates,
        }),
      width: 120,
      Cell: ({ row }) =>
        makeTableCell({
          row,
          key: EXTERNAL_UPDATES_TABLE_COLUMNS.renewal_deadline.key,
          formatter: formatDate,
        }),
    },
    {
      Header: 'Include End Month',
      accessor: EXTERNAL_UPDATES_TABLE_COLUMNS.include_end_month.key,
      width: 200,
      Cell: ({ row }) =>
        makeTableCell({
          row,
          key: EXTERNAL_UPDATES_TABLE_COLUMNS.include_end_month.key,
        }),
    },
  ];

  if (hasSpreadExternalUpdates) {
    columns.push(
      {
        Header: 'New spreads',
        accessor: EXTERNAL_UPDATES_TABLE_COLUMNS.new_spreads.key,
        width: 120,
        Cell: ({ row }) =>
          makeTableCell({
            row,
            key: EXTERNAL_UPDATES_TABLE_COLUMNS.new_spreads.key,
            formatter: (value) => (value ? 'Yes' : 'No'),
          }),
      },
      {
        Header: 'Changed spreads',
        accessor: EXTERNAL_UPDATES_TABLE_COLUMNS.changed_spreads.key,
        width: 120,
        Cell: ({ row }) =>
          makeTableCell({
            row,
            key: EXTERNAL_UPDATES_TABLE_COLUMNS.changed_spreads.key,
            formatter: (value) => (value ? 'Yes' : 'No'),
          }),
      },
      {
        Header: 'Deleted spreads',
        accessor: EXTERNAL_UPDATES_TABLE_COLUMNS.deleted_spreads.key,
        width: 120,
        Cell: ({ row }) =>
          makeTableCell({
            row,
            key: EXTERNAL_UPDATES_TABLE_COLUMNS.deleted_spreads.key,
            formatter: (value) => (value ? 'Yes' : 'No'),
          }),
      },
    );
  }

  return columns;
};

export { TableCoordinator };
