import React from 'react';
import styled from 'styled-components';

import { Flexer, Spacer } from 'components/Core';
import { INTEGRATION_SERVICES } from 'consts/integrations';
import { ReportSubscribe } from 'shared/ReportSubscribe';
import { getFrequencyLabel } from './helper';
import { Card } from '../styles';

const Title = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: var(--primaryBlack);
`;

const Subtitle = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: var(--primaryBlack50);

  b {
    color: var(--primaryBlack);
  }
`;

export const NotificationsCard = ({
  integrationTypes,
  analyticsIntegrations,
  hasActiveAnalyticsIntegrations,
  updateAnalyticsIntegration,
  deactivateAllIntegrations,
  ...props
}) => {
  const emailIntegrationFrequency = integrationTypes.find(
    (integration) => integration.service === INTEGRATION_SERVICES.EMAIL,
  )?.metadata?.email_recurring_reports_frequency;

  return (
    <Card>
      <Flexer direction="column">
        <Title>
          <b>Subscribe</b> to updates of your company's financial data and metrics
        </Title>
        <Subtitle>
          Subscript will send reports on how your business is moving{' '}
          <b> every {getFrequencyLabel({ emailIntegrationFrequency })} </b>
        </Subtitle>
        <Spacer height="24px" />
      </Flexer>
      <ReportSubscribe
        integrationTypes={integrationTypes}
        analyticsIntegrations={analyticsIntegrations}
        hasActiveAnalyticsIntegrations={hasActiveAnalyticsIntegrations}
        updateAnalyticsIntegration={updateAnalyticsIntegration}
        deactivateAllIntegrations={deactivateAllIntegrations}
        {...props}
      />
    </Card>
  );
};
