import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useGlobalFilter, useRowSelect, useSortBy, useTable, usePagination } from 'react-table';
import Checkbox from 'antd/lib/checkbox/Checkbox';

import { AppContext } from 'AppContext';
import { usePricingPlansAPI } from 'api/usageBasedEngine';
import { usePagination as usePaginationButtons, useStateWithStorage, useCurrencyNumberFormatter } from 'utils/hooks';
import { InlineButton } from 'components/Buttons';
import { FlexerColumn, FlexerRow } from 'components/Core';
import { TooltipContainer } from 'components/Tooltip';
import { RowActionPopover, TableActionCell, customGlobalFilter } from 'components/Table';
import { TableContainerLayout } from 'views/Billing/Common/TableContainerLayout';
import { TableColumnSettings } from 'shared/Common';
import { useProductActionsModal } from 'shared/ProductActionsModal';
import { NUMBER_FORMATS } from 'consts/global';

import {
  PRICING_PLANS_TABLE_COLUMN_TITLES_BY_ID,
  USAGE_BASED_BILLING_SCHEMES,
  USAGE_BASED_BILLING_SCHEMES_LABELS,
} from './consts';
import { PricingPlansTable } from './PricingPlansTable';
import { CountTag, HighLightedText, StyledLink, SubText, FadedText } from './styles';
import { useEditPricingPlanModal } from './PricingPlanModal/EditPricingPlanModal';
import { useDeletePricingPlanModal } from './PricingPlanModal/DeletePricingPlanModal';
import { isTieredPlan } from './utils';

export const PricingPlansTableContainer = () => {
  const { orgId } = useContext(AppContext);
  const numberFormatter = useCurrencyNumberFormatter();

  const [searchQuery, setSearchQuery] = useState('');
  const [hiddenColumns, setHiddenColumns] = useStateWithStorage('pricing-plans-table-hidden-columns', []);
  const [storageSortBy, setStorageSortBy] = useStateWithStorage('pricing-plans-page-sort-by', [
    {
      id: 'name',
      desc: false,
    },
  ]);
  const { currentPageIndex, setCurrentPageIndex, pageSize, setPageSize } = usePaginationButtons({
    cacheSuffix: 'pricing-plans-page',
  });

  const {
    data,
    isFetching,
    operations: { bulkDeletePricingPlans },
  } = usePricingPlansAPI({
    orgId,
    params: {
      'filters[searchQuery]': searchQuery,
      'pagination[page]': currentPageIndex,
      'pagination[limit]': pageSize,
      orderBy:
        storageSortBy && storageSortBy.length > 0
          ? [storageSortBy[0].id, storageSortBy[0].desc ? 'DESC' : 'ASC']
          : ['updated_at', 'DESC'],
      scopes: ['pagination_data', 'tiers', 'products'],
    },
  });

  const dataForTable = useMemo(() => data?.data ?? [], [data]);

  const { openModal: openEditPricingPlanModal, EditPricingPlanModal } = useEditPricingPlanModal();
  const { openModal: openDeletePricingPlanModal, DeletePricingPlanModal } = useDeletePricingPlanModal();
  const { openModal: handleOpenProductActionsModal, ProductActionsModal } = useProductActionsModal();

  const columns = useMemo(
    () => [
      {
        Header: ({ getToggleAllPageRowsSelectedProps }) => <Checkbox {...getToggleAllPageRowsSelectedProps()} />,
        accessor: 'checkbox',
        id: 'checkbox',
        width: 32,
        Cell: ({ row }) => (
          <Checkbox
            checked={row.isSelected}
            onClick={() => {
              row.toggleRowSelected();
            }}
          />
        ),
        disableSortBy: true,
      },
      {
        Header: 'Name / Description',
        accessor: 'name',
        id: 'name',
        width: 250,
        Cell: ({ row }) => (
          <FlexerColumn gap="5px" alignItems="flex-start" width="100%">
            <InlineButton
              onClick={() =>
                openEditPricingPlanModal({
                  pricingPlan: row.original,
                })
              }
              isSecondary
              data-cy={`pricing-plan-table__name--${row.original.name}`}
            >
              {row.original.name}
            </InlineButton>
            {row.original.description && (
              <TooltipContainer
                fontSize="12px"
                width={200}
                toolTipContent={row.original.description}
                tooltipWrapperStyles={{
                  width: '100%',
                }}
              >
                <SubText>{row.original.description}</SubText>
              </TooltipContainer>
            )}
          </FlexerColumn>
        ),
      },
      {
        Header: 'Product',
        accessor: 'product_id',
        id: 'product_id',
        width: 150,
        Cell: ({ row }) => (
          <StyledLink onClick={() => handleOpenProductActionsModal({ action: 'edit', product: row.original.product })}>
            {row.original.product?.name}
          </StyledLink>
        ),
      },
      {
        Header: 'Event Name',
        accessor: 'event_name',
        id: 'event_name',
        width: 300,
        Cell: ({ row }) => (
          <HighLightedText
            onClick={() => {
              /**TODO: Link to list of usage events */
            }}
          >
            {row.original.event_name}
          </HighLightedText>
        ),
      },
      {
        Header: 'Billing Scheme',
        accessor: 'tiers',
        id: 'tiers',
        width: 180,
        Cell: ({ row }) => {
          const tiers = row.original.tiers ?? [];
          const showTieredDisplay =
            tiers.length === 2
              ? !(
                  tiers[0].billing_scheme === USAGE_BASED_BILLING_SCHEMES.PREPAID &&
                  tiers[1].billing_scheme === USAGE_BASED_BILLING_SCHEMES.PER_UNIT
                )
              : isTieredPlan({ tiers });
          const perUnitTier = tiers.find(
            ({ billing_scheme }) => billing_scheme === USAGE_BASED_BILLING_SCHEMES.PER_UNIT,
          );
          return (
            <>
              {showTieredDisplay ? (
                <FlexerRow gap="10px" alignItems="center">
                  <span
                    style={{
                      fontWeight: 700,
                    }}
                  >
                    Tiered
                  </span>
                  <CountTag>{row.original.tiers.length}</CountTag>
                  <StyledLink
                    onClick={() => {
                      openEditPricingPlanModal({
                        pricingPlan: row.original,
                      });
                    }}
                  >
                    See more
                  </StyledLink>
                </FlexerRow>
              ) : (
                perUnitTier && (
                  <FlexerColumn gap="5px">
                    <span
                      style={{
                        fontWeight: 700,
                      }}
                    >
                      {`${USAGE_BASED_BILLING_SCHEMES_LABELS[perUnitTier.billing_scheme]} ${numberFormatter({
                        type: NUMBER_FORMATS.CURRENCY,
                        rawValue: perUnitTier.amount,
                        decimalPlaces: 2,
                        overrideCurrency: row.original.currency,
                      })}`}
                    </span>

                    {row.original.tiers[0].billing_scheme === USAGE_BASED_BILLING_SCHEMES.PREPAID && (
                      <SubText>
                        Minimum amount:{' '}
                        {numberFormatter({
                          type: NUMBER_FORMATS.CURRENCY,
                          rawValue: row.original.tiers[0].amount,
                          decimalPlaces: 2,
                          overrideCurrency: row.original.currency,
                        })}
                      </SubText>
                    )}
                  </FlexerColumn>
                )
              )}
            </>
          );
        },
        disableSortBy: true,
      },
      {
        Header: 'Interval',
        accessor: 'invoicing_frequency',
        id: 'invoicing_frequency',
        width: 100,
        Cell: ({ row }) => <>{row.original.invoicing_frequency}</>,
      },
      {
        Header: 'Currency',
        accessor: 'currency',
        id: 'currency',
        width: 80,
        Cell: ({ row }) => (row.original.currency ? `$${row.original.currency}` : <FadedText>Not specified</FadedText>),
        disableSortBy: true,
      },

      {
        accessor: 'actions',
        id: 'actions',
        width: 65,
        Cell: ({ row }) => (
          <TableActionCell>
            <RowActionPopover
              currentSelection={row.original}
              onEditClick={() => openEditPricingPlanModal({ pricingPlan: row.original })}
              onDeleteClick={() => openDeletePricingPlanModal({ pricingPlan: row.original })}
              dataCyPrefix="pricing-plans"
            />
          </TableActionCell>
        ),
        disableSortBy: true,
      },
    ],
    [handleOpenProductActionsModal, numberFormatter, openDeletePricingPlanModal, openEditPricingPlanModal],
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    allColumns,
    prepareRow,
    toggleAllRowsSelected,
    state: tableState,
  } = useTable(
    {
      columns,
      data: dataForTable,
      initialState: {
        sortBy: storageSortBy,
        pageIndex: currentPageIndex,
        pageSize,
        globalFilter: searchQuery,
        hiddenColumns,
      },
      getSubRows: useCallback((row) => row.subRows || [], []),
      pageCount: data?.metadata?.maxPage,
      globalFilter: customGlobalFilter,
      manualPagination: true,
      manualSortBy: true,
      manualGlobalFilter: true,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
  );

  // These values are returned from the useTable hook, but we need to store them in state
  // For this reason, we have these useEffect hooks
  useEffect(() => {
    setHiddenColumns(tableState.hiddenColumns);
  }, [setHiddenColumns, tableState.hiddenColumns]);

  useEffect(() => {
    setStorageSortBy(tableState.sortBy);
  }, [setStorageSortBy, tableState.sortBy]);

  return (
    <>
      <TableContainerLayout
        columnsSettingsComponent={
          <div>
            <TableColumnSettings
              tableName="pricing-plans"
              numberValue={pageSize}
              handleShowResultsChange={(option) => setPageSize(option.value)}
              columnsTitles={PRICING_PLANS_TABLE_COLUMN_TITLES_BY_ID}
              allColumns={allColumns}
            />
          </div>
        }
        setSearchQuery={setSearchQuery}
        isFetching={isFetching}
        rowsCount={data?.metadata?.totalCount}
        currentPageIndex={currentPageIndex ?? 1}
        setCurrentPageIndex={setCurrentPageIndex}
        pageCount={data?.metadata?.maxPage ?? 0}
        placeholder="Search by pricing plan or event name"
      >
        <PricingPlansTable
          getTableProps={getTableProps}
          getTableBodyProps={getTableBodyProps}
          headerGroups={headerGroups}
          rows={rows}
          prepareRow={prepareRow}
          toggleAllRowsSelected={toggleAllRowsSelected}
          selectedRowIds={tableState.selectedRowIds}
          bulkDeletePricingPlans={bulkDeletePricingPlans}
        />
      </TableContainerLayout>

      <EditPricingPlanModal />
      <DeletePricingPlanModal />
      <ProductActionsModal />
    </>
  );
};
