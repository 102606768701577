import React, { useContext, useEffect, useRef, useState, useMemo } from 'react';
import { Formik, FieldArray } from 'formik';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import * as Yup from 'yup';
import { AppContext } from 'AppContext';
import { FlexEndContainer, Spacer } from 'components/Core';
import {
  CUSTOMER_TIMESERIES_METRICS_SUFFIX,
  METADATA_SEGMENT_OBJECT_TYPES,
  METADATA_SEGMENT_BUCKET_TYPES,
} from 'shared/Filters/MetadataFilter/consts';
import { MAGIC_METADATA } from 'consts/global';
import { InputErrorMessage } from 'components/Blocks';
import { ProgressBar, Progress } from 'components/Loaders';
import { useConfigAPI } from 'api/configs';
import { useCustomerTimeseriesMetrics } from 'utils/hooks';
import { ReactComponent as InfoCircle } from 'images/black-info-circle.svg';
import { ReactComponent as PlusIcon } from 'images/circle-plus.svg';
import { ReactComponent as AlarmIcon } from 'images/alarm.svg';
import { ReactComponent as TriangleIcon } from 'images/input_error_message_triagle.svg';
import { ReactComponent as DeleteSegment } from 'images/segments-trash.svg';
import { FormikCustomInput, FormikCustomRadio, FormikCustomSelector, SwitchWithLabel } from 'components/Controls';
import { useToasts } from 'components/Toasts';
import {
  Modal,
  ModalBody,
  ModalButton,
  ModalCloseIcon,
  ModalContainer,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from 'components/Modal';
import { Row } from 'components/Core';
import {
  Info,
  FormRow,
  RowHead,
  Count,
  OtherSubtitle,
  AddSegmentButton,
  NoItemsLabel,
  RemoveContainer,
  ValuesWrapper,
  Value,
  DroppableArea,
  LoadingState,
  RangeDescription,
  Bullet,
  SwitchLabel,
  ZeroBucketLabel,
} from './styles';
import { formatMetadataSegments, getRangeDescription } from './utils';

const emptyLevel = {
  name: '',
  items: [],
};

const getArrayErrorMessage = ({ arrayKey, getFieldMeta }) =>
  getFieldMeta(arrayKey)?.touched &&
  getFieldMeta(arrayKey)?.error &&
  typeof getFieldMeta(arrayKey)?.error === 'string' ? (
    <InputErrorMessage style={{ marginTop: 0, marginBottom: 20 }}>
      <TriangleIcon />
      {getFieldMeta(arrayKey)?.error}
    </InputErrorMessage>
  ) : null;

export const MetadataSegmentsModal = ({
  selectedSegment,
  displaySegments,
  saveOnSubmit = true,
  onSubmitted,
  setShowMetadataSegmentModal,
  integrationObjectFields,
  objectType,
}) => {
  const segmentForEdit = displaySegments?.[selectedSegment];
  const { organizations, orgConfigs } = useContext(AppContext);
  const { pushToast } = useToasts();
  const { upsertMetadataSegment } = useConfigAPI({ orgId: organizations[0].id });
  const { customerCustomFields, transactionCustomFields } = orgConfigs;
  const { customerTimeSeriesMetricsData } = useCustomerTimeseriesMetrics();

  const formRef = useRef(integrationObjectFields);

  const metadataOptions = useMemo(() => {
    // when segment modal is to make custom customer segments
    if (objectType === METADATA_SEGMENT_OBJECT_TYPES.CUSTOMER) {
      // we don't include MAGIC_METADATA in general, with the exception of ARR_GROWTH_PERCENT, since that should be configurable
      let options = Object.keys(customerCustomFields || {})
        .filter((metadataField) => !Object.values(MAGIC_METADATA).includes(metadataField)) // removes magic metadata options
        .concat(MAGIC_METADATA.ARR_GROWTH_PERCENT); // concatenates ARR growth percent

      const timeseriesKeysWithPrefix = (customerTimeSeriesMetricsData || []).map(
        (key) => `${key}${CUSTOMER_TIMESERIES_METRICS_SUFFIX}`,
      );

      // concatenates timeseries metrics
      options = options.concat(timeseriesKeysWithPrefix);

      return options;
    } else {
      // when segment modal is to make custom transaction segments
      return Object.keys(transactionCustomFields || {});
    }
  }, [customerCustomFields, customerTimeSeriesMetricsData, transactionCustomFields, objectType]);

  const [selectedMetadataKey, setSelectedMetadataKey] = useState();
  const [otherValues, setOtherValues] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDragging, setIsDragging] = useState(false);

  const selectedKeyIsTimeseries = (selectedMetadataKey ?? '').includes(CUSTOMER_TIMESERIES_METRICS_SUFFIX);

  useEffect(() => {
    const loadMetadataValues = () => {
      setIsLoading(true);
      let otherLevelValues = [];
      const metadataValues =
        objectType === METADATA_SEGMENT_OBJECT_TYPES.CUSTOMER
          ? customerCustomFields?.[selectedMetadataKey]
          : transactionCustomFields?.[selectedMetadataKey];
      if (selectedKeyIsTimeseries) {
        // timeseries metrics don't have metadataValues so do nothing
      } else if (metadataValues?.length < 1000) {
        otherLevelValues = metadataValues;
      } else if (metadataValues?.length) {
        pushToast('Failed to load. Too many values, please try other metadata or use Continuous Data Type', 'error');
      }

      if (segmentForEdit && segmentForEdit?.key === selectedMetadataKey) {
        //if we edit existing segment we need to clean "Other" level from all another level's values
        otherLevelValues = (otherLevelValues || []).filter((value) => {
          const editSegmentItems = segmentForEdit.levels.reduce(
            (allLevelsItems, level) => allLevelsItems.concat(level.items),
            [],
          );

          return !editSegmentItems.includes(value);
        });
      } else {
        //clean all levels items on metadata key change if it's Create new segment mode
        //and also if it's Edit mode and metadata key is not equal to the initial segment key
        formRef?.current?.setFieldValue('levels', [emptyLevel]);
      }

      setOtherValues(otherLevelValues);
      setIsLoading(false);
    };

    //we dont want to reload value
    if (selectedMetadataKey) {
      loadMetadataValues();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMetadataKey, organizations, pushToast, segmentForEdit]);

  const onDragEnd = ({ result, setFieldValue, values }) => {
    setIsDragging(false);

    const { source, destination, draggableId } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    const sInd = +source.droppableId;
    const dInd = +destination.droppableId;
    const draggableValue = draggableId;

    let draggedItem;
    if (!isNaN(Number(draggableValue))) {
      draggedItem = Number(draggableValue);
    } else if (draggableValue === 'null') {
      draggedItem = null;
    } else {
      draggedItem = draggableValue;
    }

    if (sInd === dInd) {
      return;
    }

    //remove item from old level
    if (sInd === -1) {
      setOtherValues((previousOtherValues) => previousOtherValues?.filter((item) => item !== draggedItem));
    } else {
      setFieldValue(
        `levels.${sInd}.items`,
        values.levels?.[sInd]?.items?.filter((item) => item !== draggedItem),
      );
    }

    //move item to new level
    if (dInd === -1) {
      setOtherValues((previousOtherValues) => [...previousOtherValues, draggedItem]);
    } else {
      setFieldValue(`levels.${dInd}.items`, [...values.levels?.[dInd]?.items, draggedItem]);
    }
  };

  const handleFormSubmit = async ({ values }) => {
    let upsertedSegment;
    if (saveOnSubmit) {
      setIsLoading(true);
      upsertedSegment = await upsertMetadataSegment.mutateAsync({
        metadataSegment: formatMetadataSegments({
          rawMetadataSegments: values,
          objectType,
        }),
      });
      setIsLoading(false);
    }

    if (onSubmitted) {
      onSubmitted({ values, upsertedSegment });
    }

    setShowMetadataSegmentModal(false);
  };

  const validationSchema = Yup.object({
    key: Yup.string()
      .required('Metadata key is required')
      .notOneOf(
        (displaySegments ?? [])
          .map((segment) => segment?.key)
          .filter((segmentKey) => segmentKey !== segmentForEdit?.key),
        'You already have segment with this metadata',
      ),
    type: Yup.string().required('Please select a type'),
    levels: Yup.array().when('type', (bucketType) => {
      if (bucketType === METADATA_SEGMENT_BUCKET_TYPES.DISCRETE) {
        return Yup.array()
          .of(
            Yup.object({
              name: Yup.string().required('Please enter a name'),
              items: Yup.array().required().min(1, 'Please add at least one value'),
            }),
          )
          .required()
          .min(1, 'Please, add at least one bucket');
      } else {
        return Yup.array()
          .of(
            Yup.object({
              bucketName: Yup.string().required('Please enter a name'),
              bucketThreshold: Yup.number().nullable(),
            }),
          )
          .required()
          .min(1, 'Please, add at least one bucket');
      }
    }),
  });

  return (
    <ModalContainer>
      <Formik
        innerRef={formRef}
        initialValues={
          selectedSegment !== undefined
            ? segmentForEdit
            : {
                key: '',
                type: METADATA_SEGMENT_BUCKET_TYPES.DISCRETE,
                levels: [emptyLevel],
                useZeroBucket: true,
              }
        }
        validationSchema={validationSchema}
        onSubmit={(values) => handleFormSubmit({ values })}
      >
        {({ submitForm, values, setFieldValue, getFieldMeta }) => {
          setSelectedMetadataKey(values?.key);

          return (
            <Modal maxWidth="640px" height="auto" overflow="visible">
              <ModalHeader>
                <ModalCloseIcon
                  data-cy="metadata-segment-modal__close-button"
                  onClose={() => setShowMetadataSegmentModal(false)}
                  width={36}
                  height={36}
                />
                <ModalTitle>
                  <b>Create Segment</b>
                </ModalTitle>
              </ModalHeader>
              <Spacer height="20px" />
              <ModalBody height="600px" style={{ paddingBottom: 30 }}>
                <Row>
                  <FormikCustomSelector
                    name="key"
                    width="210px"
                    placeholder="Select metadata key..."
                    label="Metadata"
                    options={metadataOptions.map((option) => ({
                      label: option,
                      value: option,
                    }))}
                    handleChange={(option) => {
                      setFieldValue('key', option.value);
                      // if you select a timeseries metric, then set the type to `continuous`
                      if (option.value.includes(CUSTOMER_TIMESERIES_METRICS_SUFFIX)) {
                        setFieldValue('type', METADATA_SEGMENT_BUCKET_TYPES.CONTINUOUS);
                      }
                    }}
                  />
                  <Spacer width="30px" />
                  <FormikCustomRadio
                    name="type"
                    label="Data type"
                    width="400px"
                    suffix="metadata-segments-modal__bucket-type-radio"
                    minHeight="38px"
                    options={(selectedKeyIsTimeseries
                      ? [METADATA_SEGMENT_BUCKET_TYPES.CONTINUOUS]
                      : Object.values(METADATA_SEGMENT_BUCKET_TYPES)
                    ).map((value) => ({
                      value,
                      label: `${value} values`,
                    }))}
                  />
                </Row>

                {values?.key && values?.type === METADATA_SEGMENT_BUCKET_TYPES.DISCRETE && (
                  <>
                    {isLoading ? (
                      <LoadingState>
                        <AlarmIcon />
                        <p>Subscript needs time to collect all the metadata values for this key</p>

                        <ProgressBar>
                          <Progress loadTime={3} />
                        </ProgressBar>
                      </LoadingState>
                    ) : (
                      <>
                        <Info>
                          <InfoCircle />
                          Create new segments and use drag&drop to move the metadata values
                        </Info>

                        <FieldArray name="levels">
                          {({ remove, push }) => (
                            <DragDropContext
                              onDragStart={() => setIsDragging(true)}
                              onDragEnd={(result) => onDragEnd({ result, setFieldValue, values })}
                            >
                              <FormRow>
                                <RowHead>
                                  <Row>
                                    <Count>#1</Count>
                                    <b>Other Bucket</b>
                                    <OtherSubtitle>(+ data without this metadata)</OtherSubtitle>
                                  </Row>

                                  <AddSegmentButton
                                    data-cy={`wizard__add-${objectType}-metadata-segment`}
                                    onClick={() => push(emptyLevel)}
                                  >
                                    Add New Bucket <PlusIcon style={{ marginRight: 0, marginLeft: 4 }} />
                                  </AddSegmentButton>
                                </RowHead>

                                {otherValues?.length ? (
                                  <Droppable droppableId="-1">
                                    {(provided, snapshot) => (
                                      <DroppableArea
                                        canDragged={isDragging}
                                        isDraggingOver={snapshot.isDraggingOver}
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                      >
                                        <ValuesWrapper>
                                          {otherValues.map((value, index) => (
                                            <Draggable key={value} draggableId={String(value)} index={index}>
                                              {(provided, snapshot) => (
                                                <Value
                                                  isDragging={snapshot.isDragging}
                                                  ref={provided.innerRef}
                                                  {...provided.dragHandleProps}
                                                  {...provided.draggableProps}
                                                >
                                                  {String(value)}

                                                  {provided.placeholder}
                                                </Value>
                                              )}
                                            </Draggable>
                                          ))}
                                        </ValuesWrapper>
                                        {provided.placeholder}
                                      </DroppableArea>
                                    )}
                                  </Droppable>
                                ) : (
                                  <Row style={{ marginTop: 12 }}>
                                    <b>No items</b>
                                  </Row>
                                )}
                              </FormRow>

                              {values.levels &&
                                values.levels.map((level, index) => (
                                  <FormRow key={index}>
                                    <RowHead>
                                      <Row>
                                        <Count>#{index + 2}</Count>
                                        <FormikCustomInput name={`levels.${index}.name`} placeholder="Enter name" />
                                      </Row>

                                      <RemoveContainer
                                        onClick={() => {
                                          //return items to the Other level than delete a levele
                                          if (level.items.length) {
                                            setOtherValues((previousOtherValues) => [
                                              ...previousOtherValues,
                                              ...level.items,
                                            ]);
                                          }
                                          remove(index);
                                        }}
                                      >
                                        Delete
                                        <DeleteSegment />
                                      </RemoveContainer>
                                    </RowHead>
                                    <Droppable droppableId={String(index)}>
                                      {(provided, snapshot) => (
                                        <DroppableArea
                                          canDragged={isDragging}
                                          isDraggingOver={snapshot.isDraggingOver}
                                          {...provided.droppableProps}
                                          ref={provided.innerRef}
                                        >
                                          {level?.items?.length ? (
                                            <ValuesWrapper>
                                              {level.items.map((value, itemsIndex) => (
                                                <Draggable key={value} draggableId={String(value)} index={itemsIndex}>
                                                  {(provided, snapshot) => (
                                                    <Value
                                                      isDragging={snapshot.isDragging}
                                                      ref={provided.innerRef}
                                                      {...provided.dragHandleProps}
                                                      {...provided.draggableProps}
                                                    >
                                                      {String(value)}

                                                      {provided.placeholder}
                                                    </Value>
                                                  )}
                                                </Draggable>
                                              ))}
                                            </ValuesWrapper>
                                          ) : (
                                            <NoItemsLabel>
                                              Please create a new segment and move some metadata values here...
                                            </NoItemsLabel>
                                          )}
                                          {provided.placeholder}
                                        </DroppableArea>
                                      )}
                                    </Droppable>

                                    {getArrayErrorMessage({ arrayKey: `levels.${index}.items`, getFieldMeta })}
                                  </FormRow>
                                ))}
                            </DragDropContext>
                          )}
                        </FieldArray>

                        {getArrayErrorMessage({ arrayKey: 'levels', getFieldMeta })}
                      </>
                    )}
                  </>
                )}
                {values?.key && values?.type === METADATA_SEGMENT_BUCKET_TYPES.CONTINUOUS && (
                  <>
                    <Info>
                      <InfoCircle />
                      Create new segments and fill in the ranges
                    </Info>

                    <FieldArray name="levels">
                      {({ remove, push }) => (
                        <>
                          <FormRow>
                            <RowHead>
                              <Row>
                                <Bullet>*</Bullet>
                                <b>Other Bucket</b>
                                <OtherSubtitle>(data that doesn't belong to any range)</OtherSubtitle>
                              </Row>

                              <AddSegmentButton
                                data-cy={`wizard__add-${objectType}-metadata-segment`}
                                onClick={() => push(emptyLevel)}
                              >
                                Add New Bucket <PlusIcon style={{ marginRight: 0, marginLeft: 4 }} />
                              </AddSegmentButton>
                            </RowHead>
                          </FormRow>
                          <FormRow>
                            <RowHead>
                              <Row>
                                {values.useZeroBucket ? <Count>#1</Count> : <Bullet>*</Bullet>}
                                <ZeroBucketLabel deactivated={!values.useZeroBucket}>Zero or None</ZeroBucketLabel>
                                {values.useZeroBucket && (
                                  <OtherSubtitle>(0 or empty data in this bucket)</OtherSubtitle>
                                )}
                              </Row>
                              <SwitchWithLabel
                                onChange={(value) => setFieldValue('useZeroBucket', value)}
                                checked={values.useZeroBucket}
                                label={
                                  <SwitchLabel isOn={values.useZeroBucket}>
                                    {values.useZeroBucket ? 'Deactivate' : 'Activate Zero Bucket'}
                                  </SwitchLabel>
                                }
                                labelFirst
                                name="zeroBucketActivated"
                              />
                            </RowHead>
                          </FormRow>
                          {values.levels &&
                            values.levels.map((level, index) => (
                              <FormRow key={index}>
                                <RowHead>
                                  <Row>
                                    <Count>#{values.useZeroBucket ? index + 2 : index + 1}</Count>
                                    <FormikCustomInput name={`levels.${index}.bucketName`} placeholder="Enter name" />
                                    <Spacer width="30px" />
                                    <FormikCustomInput
                                      label="Upper bound:"
                                      type="number"
                                      labelDirection="left"
                                      inputWidth="110px"
                                      name={`levels.${index}.bucketThreshold`}
                                      placeholder="Infinity"
                                      tooltipInputDisplay={'Leave the field empty for no upper bound'}
                                      suffixBadgeText="EX"
                                      suffixTooltipInputDisplay={'Excluding bound'}
                                    />
                                  </Row>
                                  <RemoveContainer
                                    onClick={() => {
                                      remove(index);
                                    }}
                                  >
                                    Delete
                                    <DeleteSegment />
                                  </RemoveContainer>
                                </RowHead>
                                <RangeDescription>{getRangeDescription(index, values)}</RangeDescription>
                              </FormRow>
                            ))}
                        </>
                      )}
                    </FieldArray>
                    {getArrayErrorMessage({ arrayKey: 'levels', getFieldMeta })}
                  </>
                )}
              </ModalBody>
              <ModalFooter noFixedHeight padding="12px 21px">
                <FlexEndContainer>
                  <ModalButton
                    data-cy="wizard__cancel-custom-segment"
                    onClick={() => setShowMetadataSegmentModal(false)}
                  >
                    Cancel
                  </ModalButton>
                  <ModalButton data-cy="wizard__save-custom-segment" onClick={() => submitForm()} className="primary">
                    Save
                  </ModalButton>
                </FlexEndContainer>
              </ModalFooter>
            </Modal>
          );
        }}
      </Formik>
    </ModalContainer>
  );
};
