import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import helpImage from 'images/filters-help-image.png';
import { Modal, ModalBody, ModalCloseIcon, ModalContainer, ModalHeader } from 'components/Modal';
import { useClickOutside } from 'utils/hooks';
import { CONFIGURATION_TABS } from 'views/Configuration/consts';

const BodyContent = styled.div`
  display: flex;
`;

const ContentText = styled.div`
  padding: 32px 36px;
`;

const ContentTitle = styled.div`
  font-weight: 900;
  font-size: 20px;
  margin-bottom: 20px;
  line-height: 26px;
`;

const Blue = styled.span`
  color: var(--primaryBlue);

  &:hover {
    color: var(--secondaryBlue);
  }
`;

const ListItem = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 12px;
  display: flex;

  &:last-child {
    margin-bottom: 0;
  }
`;

const ListDot = styled.div`
  opacity: 0.3;
  border-radius: 100px;
  border: 2px solid var(--primaryBlack);
  width: 8px;
  min-width: 8px;
  margin-right: 20px;
  height: 8px;
  min-height: 8px;
  margin-top: 6px;
`;

export const FiltersHelpModal = ({ hideModal }) => {
  const modalRef = useClickOutside(() => hideModal());

  return (
    <ModalContainer>
      <Modal padding="0" ref={modalRef} maxWidth="740px" height="auto" overflow="hidden">
        <ModalHeader>
          <ModalCloseIcon
            data-cy="filters-help-modal__close-button"
            style={{ width: 32, height: 32 }}
            onClose={() => hideModal()}
          />
        </ModalHeader>
        <ModalBody paddingLeft="0" paddingRight="0">
          <BodyContent>
            <img src={helpImage} alt="" style={{ height: 234, width: 212 }} />

            <ContentText>
              <ContentTitle>Tips about filters and segments</ContentTitle>

              <ListItem>
                <ListDot />
                <div>
                  You can combine several products into{' '}
                  <Link to={`/configure?tab=${CONFIGURATION_TABS.PRODUCTS}`}>
                    <Blue>categories</Blue>
                  </Link>{' '}
                  to make it easier for you to constantly filter your financial data.
                </div>
              </ListItem>

              <ListItem>
                <ListDot />

                <div>
                  Go to the Configuration page if you want to customize your{' '}
                  <Link to={`/configure?tab=${CONFIGURATION_TABS.GENERAL_SETTINGS}`}>
                    <Blue>segments by size</Blue>
                  </Link>
                </div>
              </ListItem>

              <ListItem>
                <ListDot />
                <div>
                  You can also create and customize your{' '}
                  <Link to={`/configure?tab=${CONFIGURATION_TABS.GENERAL_SETTINGS}`}>
                    <Blue>segments using metadata</Blue>
                  </Link>
                </div>
              </ListItem>
            </ContentText>
          </BodyContent>
        </ModalBody>
      </Modal>
    </ModalContainer>
  );
};
