import styled from 'styled-components';

export const UsersTooltip = styled.div`
  padding: 12px;
  position: absolute;
  width: 240px;
  height: 56px;
  bottom: -64px;
  left: 0;

  background: var(--primaryBlack35);
  backdrop-filter: blur(20px);
  border-radius: 12px;

  font-style: italic;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;

  color: rgba(255, 255, 255, 0.7);

  span {
    cursor: pointer;
    color: #fff;
  }
`;
