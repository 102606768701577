import React, { useState } from 'react';
import { Switch } from 'antd';
import styled from 'styled-components';

import { useClickOutside } from 'utils/hooks';
import { SPREAD_TYPE } from 'consts/global';
import { ReactComponent as InfoCircle } from 'images/black-info-circle.svg';
import { BigItemsContainer } from 'components/Core';
import { FilterConditionHead } from '../styles';

const ModalPopover = styled.div`
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border-radius: 10px;
  margin-top: 5px;
  box-shadow: 15px 15px 60px rgba(0, 21, 46, 0.2);
  z-index: 20;
  flex-direction: column;
`;

const TitleModalListItem = styled.div`
  opacity: 0.5;
  padding-bottom: 2px;
  padding-top: 4px;
  margin-bottom: 4px;
  color: var(--primaryBlack);
  font-size: 12px;
  line-height: 16px;
  text-align: left;
  pointer-events: none;
`;

const LabelContainer = styled.div`
  font-size: 14px;
  display: flex;
  align-items: center;
`;

const SwitchContainer = styled.div`
  padding: 4px;
`;

const StyledSwitch = styled(Switch)`
  &.ant-switch-checked {
    background-color: #1eb76e;
  }
`;

const SwitchLabel = styled.span`
  margin-left: 8px;
  font-weight: 400;
  color: #000e4b;
  opacity: ${(props) => props.isDisabled && 0.3};
`;

const ArrDescription = styled.div`
  max-width: 231px;
  white-space: normal;
  padding: 10px 16px;
  background: rgba(0, 21, 46, 0.03);

  svg {
    margin-bottom: 8px;
  }

  p {
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 0;
    color: var(--primaryBlack);
    opacity: 0.5;
  }
`;

const TOGGLES = [
  {
    label: 'Recurring revenue',
    value: SPREAD_TYPE.RECURRING,
  },
  {
    label: 'Non-recurring revenue',
    value: SPREAD_TYPE.NON_RECURRING,
  },
];

// TODO: Migrate to ToggleDropdownButton
const RevenueTypePopover = ({ currentSelections, isARR, ...props }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useClickOutside(() => setShowDropdown(false));

  return (
    <div>
      <LabelContainer>
        <span>Revenue:</span>
        <FilterConditionHead
          ref={dropdownRef}
          style={{ textTransform: 'capitalize' }}
          onClick={() => setShowDropdown(!showDropdown)}
          data-cy="page-settings__toggle-button"
        >
          {currentSelections?.join(', ')}
          {showDropdown && <OpenedDropdown currentSelections={currentSelections} isARR={isARR} {...props} />}
          {!currentSelections?.length && 'Select Revenue Types'}
        </FilterConditionHead>
      </LabelContainer>
    </div>
  );
};

const OpenedDropdown = ({ selectRevenueTypes, currentSelections, isARR }) => {
  const handleRevenueSwitchChange = (value) => {
    const selectionSet = new Set(currentSelections);
    if (selectionSet.has(value)) {
      selectionSet.delete(value);
    } else {
      selectionSet.add(value);
    }
    const selectionArray = Array.from(selectionSet);
    selectRevenueTypes(selectionArray);
  };
  return (
    <ModalPopover>
      <BigItemsContainer style={{ padding: '12px 16px' }} soloView={true}>
        <TitleModalListItem>Include Data:</TitleModalListItem>
        {TOGGLES.map((toggle) => {
          const isArrAndNotReccuringToggle = isARR && toggle.value !== SPREAD_TYPE.RECURRING;

          return (
            <SwitchContainer key={toggle.value}>
              <StyledSwitch
                disabled={isArrAndNotReccuringToggle}
                size="small"
                data-cy={`page-settings__switch--${toggle.value}`}
                onChange={() => handleRevenueSwitchChange(toggle.value)}
                checked={currentSelections?.includes(toggle.value)}
              />
              <SwitchLabel isDisabled={isArrAndNotReccuringToggle}>{toggle.label}</SwitchLabel>
            </SwitchContainer>
          );
        })}
      </BigItemsContainer>
      {isARR && (
        <ArrDescription>
          <InfoCircle />
          <p>In ARR mode, only Recurring revenue is available</p>
        </ArrDescription>
      )}
    </ModalPopover>
  );
};

export default RevenueTypePopover;
