import React, { useCallback, useContext, useState } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { AppContext } from 'AppContext';
import { NUMBER_FORMATS } from 'consts/global';
import { COLORS } from 'consts/colors';
import { numberFormatter } from 'utils/formatters';
import { useCreditNoteAPI, useCreditNotesAPI } from 'api/billing/hooks';
import {
  Modal,
  ModalBodyMargin,
  ModalRedSection,
  ModalRedSectionText,
  ModalRedSectionTitle,
  ModalButton,
  ModalCloseIcon,
  ModalContainer,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTitleText,
} from 'components/Modal';
import { FlexEndContainer, Flexer, FlexerColumn, FlexerRow } from 'components/Core';
import { Loader } from 'components/Loaders';
import { Button } from 'components/Buttons';
import { UnlinkIcon } from 'components/Icons';
import { InvoicingScheduleContext } from './InvoicingScheduleContext';

const WhiteUnlinkIcon = styled(UnlinkIcon)`
  margin-left: 8px;

  height: 20px;
  width: 20px;

  g {
    opacity: 1;
    path {
      fill: white;
    }
  }
`;

const StyledUnlinkIcon = styled(UnlinkIcon)`
  margin-top: 2px;
  g {
    opacity: 1;
  }
`;

const ConfirmUnallocateInvoiceModal = ({ creditNoteId, onClose }) => {
  const {
    orgId,
    appSettings: { currencyISOCode: currency },
  } = useContext(AppContext);

  const { refetchInvoicingSchedule } = useContext(InvoicingScheduleContext);

  const {
    operations: { allocateCreditNote },
  } = useCreditNotesAPI({ orgId, autoFetch: false });
  const { data: creditNote, isLoading } = useCreditNoteAPI({ orgId, creditNoteId });

  const handleSubmit = async () => {
    await allocateCreditNote.mutateAsync({
      id: creditNoteId,
      data: {},
    });

    await refetchInvoicingSchedule();

    onClose();
  };

  return (
    <ModalContainer>
      <Modal height="auto" maxHeight="80vh" maxWidth="440px" overflow="auto">
        <ModalHeader>
          <ModalCloseIcon onClose={onClose} />
          <ModalTitle>
            <ModalTitleText>
              <b>Unlink Credit Note</b>
            </ModalTitleText>
          </ModalTitle>
        </ModalHeader>
        <ModalBodyMargin>
          {isLoading ? (
            <div className="w-100 flexer">
              <Loader containerStyles={{ width: 40, margin: 20 }} />
            </div>
          ) : (
            <>
              <div>Do you really want to unlink the following credit note?</div>
              <ModalRedSection>
                <FlexerRow gap="8px">
                  <Flexer>
                    <StyledUnlinkIcon />
                  </Flexer>
                  <FlexerColumn gap="4px">
                    <ModalRedSectionTitle>
                      Credit Note #{creditNote.credit_note_number ?? creditNote.id}{' '}
                      {dayjs(creditNote.date).format('MMM DD, YYYY')}
                    </ModalRedSectionTitle>
                    <ModalRedSectionText>
                      Amount:{' '}
                      {numberFormatter({
                        rawValue: creditNote.amount ?? 0,
                        type: NUMBER_FORMATS.CURRENCY,
                        decimalPlaces: 0,
                        currency: creditNote.currency ?? currency,
                      })}
                    </ModalRedSectionText>
                  </FlexerColumn>
                </FlexerRow>
              </ModalRedSection>
            </>
          )}
        </ModalBodyMargin>

        <ModalFooter>
          <FlexEndContainer gap="8px">
            <ModalButton onClick={onClose}>Cancel</ModalButton>
            <Button
              color={COLORS.RED}
              border
              filled
              active
              onClick={handleSubmit}
              data-cy="confirm-unallocate-invoice-modal__unlink-button"
            >
              Unlink
              <WhiteUnlinkIcon color="white" />
            </Button>
          </FlexEndContainer>
        </ModalFooter>
      </Modal>
    </ModalContainer>
  );
};

export const useConfirmUnallocateInvoiceModal = () => {
  const [showModal, setShowModal] = useState(false);
  const [creditNoteId, setCreditNoteId] = useState();

  const openModal = useCallback(
    ({ creditNoteId }) => {
      setCreditNoteId(creditNoteId);
      setShowModal(true);
    },
    [setCreditNoteId, setShowModal],
  );

  const closeModal = useCallback(() => {
    setShowModal(false);
    setCreditNoteId(null);
  }, [setShowModal]);

  const Modal = useCallback(
    () => (showModal ? <ConfirmUnallocateInvoiceModal creditNoteId={creditNoteId} onClose={closeModal} /> : null),
    [showModal, closeModal, creditNoteId],
  );

  return {
    openModal,
    Modal,
    isModalOpen: showModal,
  };
};
