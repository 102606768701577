import React from 'react';
import styled from 'styled-components';
import { ReactComponent as CalculatorIcon } from 'images/bxs-calculator.svg';
import { FlexerColumn, CentererVertical, Spacer, Divider } from 'components/Core';
import { Modal, ModalBody, ModalCloseIcon, ModalContainer, ModalHeader, ModalTitle } from 'components/Modal';

const TitleText = styled.span`
  font-size: 20px;
  font-weight: 900;
`;

const TransactionText = styled.span`
  font-size: 12px;
`;

const SubHeader = styled.span`
  font-size: 16px;
  font-weight: 900;
`;

const Bold = styled.span`
  font-weight: 700;
`;

const TotalSSPText = styled.span`
  font-size: 12px;
`;

export const SeeWhyModal = ({ transactions, onClose }) => {
  const totalSSP = transactions.reduce(
    (acc, transaction) => acc + (transaction.productSSP ?? 0) * (transaction?.seats || 1),
    0,
  );

  return (
    <>
      <ModalContainer>
        <Modal width="700px" height="auto">
          <ModalCloseIcon onClose={onClose} />
          <ModalHeader>
            <ModalTitle padding="12px 36px">
              <CentererVertical gap="12px">
                <CalculatorIcon />
                <TitleText>Recognition based on ratios of SSPs</TitleText>
              </CentererVertical>
            </ModalTitle>
          </ModalHeader>
          <ModalBody paddingLeft="36px" paddingRight="36px">
            <Divider />
            <FlexerColumn gap="16px">
              <SubHeader>Standalone selling price(s)</SubHeader>
              {transactions.map((transaction) => (
                <CentererVertical justifyContent="space-between" key={transaction.id}>
                  <TransactionText>
                    The standalone selling price of <Bold>{transaction.productName}</Bold> for transaction{' '}
                    {transaction.name} is{' '}
                    <Bold>
                      {transaction.productSSP ?? 0} {`x ${transaction?.seats || 1} seats`}
                    </Bold>
                  </TransactionText>
                </CentererVertical>
              ))}
              <Divider />

              <SubHeader>Total of SSPs</SubHeader>
              <TotalSSPText>
                The total of SSPs is <Bold>{totalSSP}</Bold>
              </TotalSSPText>
              <Divider />

              <SubHeader>Allocations</SubHeader>
              {transactions.map((transaction) => (
                <CentererVertical justifyContent="space-between" key={transaction.id}>
                  <TransactionText>
                    The allocation of <Bold>{transaction.productName}</Bold> for transaction {transaction.name} is
                    <Bold>
                      {` ${transaction.productSSP ?? 0} ${`x ${transaction?.seats || 1} seats`} / ${totalSSP ?? 0} = `}
                      {(totalSSP === 0 ? 0 : ((transaction.productSSP ?? 0) * (transaction?.seats || 1)) / totalSSP) *
                        100}
                      %
                    </Bold>
                  </TransactionText>
                </CentererVertical>
              ))}
              <Divider />
            </FlexerColumn>
          </ModalBody>
          <Spacer height="36px" />
        </Modal>
      </ModalContainer>
    </>
  );
};
