import React, { Fragment, useContext, useMemo, useState, useCallback } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useTable, useSortBy, usePagination } from 'react-table';
import { AppContext } from 'AppContext';
import { useContractsAPI } from 'api/contracts/hooks';
import { ReactComponent as TotalIcon } from 'images/sort-descending.svg';
import { SizedLoader } from 'components/Loaders';
import {
  ReactTableBody,
  ReactTableHeader,
  ReactTableHeaderColumn,
  ReactTableHeaderRow,
  ReactTableCell,
  ReactTableRow,
  HeaderCellWrapper,
  SortIconWrapper,
  TableHeaderActions,
  RowsCount,
} from 'components/Table';
import { Centerer, Row } from 'components/Core';
import { PaginationButton } from 'components/Buttons';
import { GreyDot } from 'components/Icons';
import { SearchContainer } from 'shared/TransactionTable/styles';
import { useDebouncedSearchBar } from 'components/Blocks';
import { pluralize } from 'utils/stringUtils';
import { getColumns } from './columns';

const TableContainer = styled.div`
  width: 100%;
  overflow: scroll;
  margin-bottom: 24px;
`;

const PAGE_SIZE = 10;

export const ContractsListTable = ({ customerIds }) => {
  const { orgId } = useContext(AppContext);
  const [currentPageIndex, setCurrentPageIndex] = useState(1);
  const [customerNameSearchQuery, setCustomerNameSearchQuery] = useState('');
  const history = useHistory();

  const fromCustomerPage = !!customerIds;

  const handleSearchQueryChange = useCallback((newSearchQuery) => {
    setCustomerNameSearchQuery(newSearchQuery);
    setCurrentPageIndex(1);
  }, []);

  const { DebouncedSearchBar } = useDebouncedSearchBar({
    fontSize: '12px',
    onSearchQueryChange: handleSearchQueryChange,
    initialValue: '',
    placeholder: 'Search by customer name',
    width: '200px',
  });

  const { data: contractsData, metadata, isLoading, isFetching } = useContractsAPI({
    orgId,
    scopes: ['transactions'],
    page: currentPageIndex,
    limit: PAGE_SIZE,
    customerNameSearchQuery,
    customerIds,
  });

  const data = useMemo(() => contractsData || [], [contractsData]);
  const columns = useMemo(() => getColumns({ history, fromCustomerPage }), [history, fromCustomerPage]);

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page, pageCount } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: currentPageIndex, pageSize: PAGE_SIZE },
      manualPagination: true,
      pageCount: metadata?.maxPage || 1,
    },
    useSortBy,
    usePagination,
  );

  return (
    <>
      <TableHeaderActions horizontal="space-between">
        <SearchContainer>
          <DebouncedSearchBar />
        </SearchContainer>
        <Row>
          <RowsCount>
            <span data-cy="contracts-table__row-count">{pluralize(metadata?.totalCount ?? 0, 'row')} found</span>
          </RowsCount>
          <GreyDot style={{ marginLeft: 20, marginRight: 20, minWidth: 4, minHeight: 4 }} />
          <PaginationButton
            pageIndex={currentPageIndex}
            canPreviousPage={currentPageIndex > 1}
            canNextPage={currentPageIndex < pageCount}
            pageCount={pageCount}
            nextPage={() => setCurrentPageIndex((prevPageIndex) => prevPageIndex + 1)}
            previousPage={() => setCurrentPageIndex((prevPageIndex) => prevPageIndex - 1)}
          />
        </Row>
      </TableHeaderActions>
      {isLoading || isFetching ? (
        <Centerer width="100%" height="100%">
          <SizedLoader size={40} />
        </Centerer>
      ) : (
        <TableContainer {...getTableProps()}>
          <ReactTableHeader>
            {headerGroups.map((headerGroup) => (
              <ReactTableHeaderRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <ReactTableHeaderColumn
                    key={column.id}
                    customWidth={column.width}
                    alignRight={column.alignRight}
                    {...column.getHeaderProps(column.getSortByToggleProps({ title: undefined }))}
                  >
                    <HeaderCellWrapper isSortable={column.canSort && !column.disableSortBy} isSorted={column.isSorted}>
                      {column.render('Header')}
                      {column.isSorted && (
                        <SortIconWrapper isSortedDesc={column.isSortedDesc}>
                          <TotalIcon />
                        </SortIconWrapper>
                      )}
                    </HeaderCellWrapper>
                  </ReactTableHeaderColumn>
                ))}
              </ReactTableHeaderRow>
            ))}
          </ReactTableHeader>
          {page.length ? (
            <ReactTableBody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <Fragment key={row.id}>
                    <ReactTableRow {...row.getRowProps()}>
                      {row.cells.map((cell) => (
                        <ReactTableCell
                          key={cell.column.id}
                          {...cell.getCellProps()}
                          customWidth={cell.column.width}
                          alignRight={cell.column.alignRight}
                        >
                          {cell.render('Cell')}
                        </ReactTableCell>
                      ))}
                    </ReactTableRow>
                  </Fragment>
                );
              })}
            </ReactTableBody>
          ) : null}
        </TableContainer>
      )}
    </>
  );
};
