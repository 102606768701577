import { useContext, useEffect, useMemo } from 'react';

import { MAGIC_METADATA } from 'consts/global';
import { AppContext } from 'AppContext';
import { HelpCircleIcon } from 'components/Icons';
import { SwitchWithLabel } from 'components/Controls';
import { TooltipContainer } from 'components/Tooltip';

import { InvoicingScheduleContext } from '../InvoicingScheduleContext';

export const InvoiceCurrencySwitch = ({
  setFieldValue,
  values,
  hasOriginalCurrencyToBillIn,
  defaultOrgCurrency,
  transactionOriginalCurrencies,
}) => {
  return hasOriginalCurrencyToBillIn ? (
    <SwitchWithLabel
      name="invoice_in_original_currency"
      checked={values?.currency && values?.currency !== defaultOrgCurrency}
      label={`Create invoices in ${transactionOriginalCurrencies[0]}`}
      labelSize="12px"
      onChange={(newValue) => {
        if (newValue) setFieldValue('currency', transactionOriginalCurrencies[0]);
        else setFieldValue('currency', null);
      }}
      icon={
        <TooltipContainer
          toolTipContent="existing invoices will not change"
          tooltipWrapperStyles={{
            display: 'flex',
          }}
          fontSize="12px"
          width={200}
        >
          <HelpCircleIcon />
        </TooltipContainer>
      }
    />
  ) : null;
};

export const useInvoiceCurrencySwitch = ({ setFieldValue, values }) => {
  const {
    appSettings: { currencyISOCode: defaultOrgCurrency },
  } = useContext(AppContext);

  const { includedTransactions } = useContext(InvoicingScheduleContext);

  const transactionOriginalCurrencies = useMemo(
    () =>
      includedTransactions.map(
        (transaction) => transaction.currency ?? transaction.metadata?.[MAGIC_METADATA.ORIGINAL_CURRENCY],
      ),
    [includedTransactions],
  );
  const hasOriginalCurrencyToBillIn = useMemo(
    () =>
      transactionOriginalCurrencies.length &&
      transactionOriginalCurrencies.every(
        (currency) => currency && currency !== defaultOrgCurrency && currency === transactionOriginalCurrencies[0],
      ),
    [transactionOriginalCurrencies, defaultOrgCurrency],
  );

  useEffect(() => {
    if (transactionOriginalCurrencies[0] !== defaultOrgCurrency) {
      setFieldValue && setFieldValue('currency', transactionOriginalCurrencies[0]);
    }
  }, [transactionOriginalCurrencies, defaultOrgCurrency, setFieldValue]);

  return {
    hasOriginalCurrencyToBillIn,
    InvoiceCurrencySwitch: () => (
      <InvoiceCurrencySwitch
        setFieldValue={setFieldValue}
        values={values}
        hasOriginalCurrencyToBillIn={hasOriginalCurrencyToBillIn}
        defaultOrgCurrency={defaultOrgCurrency}
        transactionOriginalCurrencies={transactionOriginalCurrencies}
      />
    ),
  };
};
