import styled from 'styled-components';
import { ReactComponent as Icon } from 'images/recharging.svg';

export const RechargeIcon = styled(Icon)`
  width: ${({ size }) => size ?? '20px'};
  height: ${({ size }) => size ?? '20px'};

  stroke: ${({ stroke }) => stroke};
  path {
    fill: ${({ fill }) => fill};
  }
`;
