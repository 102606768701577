import styled from 'styled-components';
import { CentererVertical } from 'components/Core';
import { Button } from 'components/Buttons';
import { MULTISELECT_RIBBON_BUTTONS_ROLES } from './MultiselectRibbon';

export const Wrapper = styled(CentererVertical)`
  padding: 8px 16px;
  border-radius: 100px;
  white-space: nowrap;
  box-shadow: 12px 12px 60px 0px var(--primaryBlack2);
  background-color: #77879d;
  position: fixed;
  z-index: 100;
  bottom: 30px;
  left: ${({ left }) => left ?? '50%'};
  transform: translateX(-50%);
  width: fit-content;
  margin: 0 auto;
  color: white;
`;

export const Title = styled.div`
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: white;
`;

export const StyledResetIcon = styled.div`
  cursor: pointer;
  display: flex;
  margin-left: 8px;

  svg g {
    opacity: 0.3;
  }

  &:hover {
    svg g {
      opacity: 1;
    }
  }
`;

export const StyledButton = styled(Button)`
  color: white;
  background: ${({ role }) =>
    role === MULTISELECT_RIBBON_BUTTONS_ROLES.REMOVE ? 'var(--tertiaryRed)' : 'transparent'};
  border: 1px solid
    ${({ role }) =>
      role === MULTISELECT_RIBBON_BUTTONS_ROLES.REMOVE ? 'var(--primaryBlack5)' : 'rgba(255, 255, 255, 0.1)'};
  margin-left: 8px;
  border-radius: 100px;
  text-transform: capitalize;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;

  padding: ${({ isRound }) => isRound && '8px'};
`;
