import dayjs from 'dayjs';
import styled from 'styled-components';
import { NUMBER_FORMATS } from 'consts/global';
import { numberFormatter } from 'utils/formatters';
import { BILLING_PAGES_ROUTES } from 'views/Billing/consts';
import { INVOICE_STATUS_TAG_TO_LABEL, InvoiceStatusTag, SyncStatusTag } from 'views/Billing/InvoicingTables/columns';
import { FlexerColumn } from 'components/Core';

const CustomerName = styled.span`
  color: var(--primaryBlue);
  font-weight: 700;
  cursor: pointer;
`;

const Value = styled.span`
  font-weight: 400;
  font-size: 12px;
`;

export const getColumns = () => {
  const columns = [
    {
      Header: 'CUSTOMER',
      accessor: 'customer_name',
      id: 'customer_name',
      width: 200,
      Cell: ({ value, row }) => (
        <CustomerName onClick={() => window.open(`${BILLING_PAGES_ROUTES.INVOICES}/${row.original.id}`, '_blank')}>
          {value || '[No name]'}
        </CustomerName>
      ),
    },
    {
      Header: 'NUMBER',
      accessor: 'invoice_number',
      id: 'invoice_number',
      width: 150,
      Cell: ({ value }) => <Value>{value || '[No Number]'}</Value>,
    },
    {
      Header: 'STATUS',
      accessor: 'invoice_status',
      id: 'invoice_status',
      width: 100,
      Cell: ({ value }) => (
        <InvoiceStatusTag status={value}>{INVOICE_STATUS_TAG_TO_LABEL[value] ?? value}</InvoiceStatusTag>
      ),
    },
    {
      Header: 'DATE',
      accessor: 'date',
      id: 'date',
      width: 100,
      Cell: ({ value }) => <Value>{dayjs(value).format('MMM D, YYYY')}</Value>,
    },
    {
      Header: 'PRODUCTS',
      accessor: 'product_names',
      id: 'product_names',
      width: 200,
      Cell: ({ value }) => (
        <FlexerColumn gap="4px">
          {value?.map((productName) => (
            <Value key={productName}>• {productName}</Value>
          ))}
        </FlexerColumn>
      ),
    },
    {
      Header: 'AMOUNT',
      accessor: 'amount',
      id: 'amount',
      width: 150,
      alignRight: true,
      Cell: ({ value, row }) => (
        <Value>
          {numberFormatter({
            rawValue: value,
            type: NUMBER_FORMATS.CURRENCY,
            currency: row.original.currency,
          })}
        </Value>
      ),
    },
    {
      Header: 'TRANSACTIONS',
      accessor: 'transaction_names',
      id: 'transaction_names',
      width: 200,
      Cell: ({ value }) => (
        <FlexerColumn gap="4px">
          {value?.map((transactionName) => (
            <Value key={transactionName}>• {transactionName}</Value>
          ))}
        </FlexerColumn>
      ),
    },
    {
      Header: 'PAYMENT OPTIONS',
      accessor: 'payment_options',
      id: 'payment_options',
      width: 150,
      Cell: ({ value }) => (
        <FlexerColumn gap="4px">
          {value?.map((paymentOption) => (
            <Value key={paymentOption}>• {paymentOption}</Value>
          ))}
        </FlexerColumn>
      ),
    },
    {
      Header: 'SYNC STATUS',
      accessor: 'sync_status',
      id: 'sync_status',
      width: 100,
      Cell: ({ value }) => <SyncStatusTag status={value}>{value}</SyncStatusTag>,
    },
  ];

  return columns;
};
