import React, { useContext, useMemo, useState } from 'react';
import { ReactComponent as ArrowUp } from 'images/arrow-bold-icon.svg';
import { ReactComponent as SubcategoryArrowIcon } from 'images/icon_subcategory_arrow.svg';
import DataDecreasingIcon from 'images/icon_stat_dec.svg';
import DataIncreasingIcon from 'images/icon_stat_inc.svg';
import DataStableIcon from 'images/icon_stat_stable.svg';
import { AppContext } from 'AppContext';
import { Row, Spacer, FlexBetweenContainer, CentererVertical } from 'components/Core';
import { StyledTableName, TableSubRowsCountArrow, TableRowTitle, TableRowTagWaterfall } from 'components/Table';
import { TooltipContainer } from 'components/Tooltip';
import { REVENUE_METRIC } from 'consts/revenueMetrics';
import { RevenueDetailsModal } from 'shared/RevenueDetailsModal';
import { useOpenDrilldownsFromUrl } from 'utils/hooks/useOpenDrilldownsFromUrl';
import DataTableRowValue from 'views/Dashboard/Common/DataTableRowValue';
import { StackedBarChart } from 'views/Dashboard/Common/StackedBarChart';
import { Table } from './Table';
import { DashboardCellInner, DashboardHeadInner, SettingsContainer, SnapshotTitle, SubCatLabel } from './styles';

const WaterfallTable = ({
  tableId,
  dataForTable,
  exportScreenshotRef,
  chartRef,
  togglePopover,
  dataTableShowingBy,
  showStackedBarChart,
  isARR,
  setIsARR,
  optimisticAnalytics,
  setOptimisticAnalytics,
  isCustomerView = false,
  waterfallType,
  waterfallGraphPeriod,
  setWaterfallGraphPeriod,
  waterfallSegmentBy,
  waterfallSegmentByTimeseriesMonth,
  dataFilter,
}) => {
  const {
    organizations,
    appSettings: { currencyISOCode: currency },
  } = useContext(AppContext);

  //used for TransactionDetailsModal
  const [showRevenueDetailsModal, setShowRevenueDetailsModal] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedMetric, setSelectedMetric] = useState(null);
  const [selectedSegment, setSelectedSegment] = useState(null);
  const [summaryStartMonth, setSummaryStartMonth] = useState(null);
  const [summaryColumnHeader, setSummaryColumnHeader] = useState(null);

  useOpenDrilldownsFromUrl({ setSelectedMonth, setSelectedMetric, setShowRevenueDetailsModal });

  const Indicator = ({ lastValue, currentValue }) => {
    let icon;
    if (lastValue < currentValue) {
      icon = DataIncreasingIcon;
    } else if (lastValue > currentValue) {
      icon = DataDecreasingIcon;
    } else {
      icon = DataStableIcon;
    }
    return <img src={icon} alt="icon" />;
  };

  const dateColumns = useMemo(
    () =>
      dataForTable?.columnHeaders.map((date, index) => ({
        Header: () => <DashboardHeadInner>{date}</DashboardHeadInner>,
        accessor: date,
        Cell: ({ row, rowsById }) => {
          const isQuarter = (dataForTable.columnHeaders[index] || '').includes('-Q');
          const isYear = (dataForTable.columnHeaders[index] || '').includes('-Y');
          const isCumulative = (dataForTable.columnHeaders[index] || '').includes('CUMULATIVE');

          //buffer is the number of columns backwards that we need to look to figure out the Indicator difference
          //with the introduction of the Quarter and Year columns, we need to skip over those columns to find the previous value
          let buffer = 1;
          if ((dataForTable.columnHeaders[index - 1] || '').includes('-Q')) {
            buffer = 2;
          } else if ((dataForTable.columnHeaders[index - 1] || '').includes('-Y')) {
            buffer = 3;
          }
          const lastValue = row.original.data[dataForTable.columnHeaders[index - buffer]] || 0;
          const showEmptyCell =
            (row.original.dontShowQuarterAndYearCells && (isQuarter || isYear || isCumulative)) ||
            row.original.type === 'breakdownHeader';

          return (
            <DashboardCellInner isCustomerView={isCustomerView}>
              {!showEmptyCell && (
                <>
                  <DataTableRowValue
                    title={row.original.title}
                    columnHeaders={dataForTable.columnHeaders}
                    dataType={row.original.dataType}
                    setSelectedMonth={setSelectedMonth}
                    setSummaryStartMonth={setSummaryStartMonth}
                    setSummaryColumnHeader={setSummaryColumnHeader}
                    setSelectedSegment={setSelectedSegment}
                    setSelectedMetric={setSelectedMetric}
                    setShowRevenueDetailsModal={setShowRevenueDetailsModal}
                    index={index}
                    value={row.original.data[date] ?? 0}
                    waterfallType={waterfallType}
                    depth={row.depth}
                    row={row}
                    rowsById={rowsById}
                    segmentBy={waterfallSegmentBy}
                    dataFilter={dataFilter}
                  />
                  {row.original.type === 'totalRow' && !isQuarter && !isYear && (
                    <Indicator lastValue={lastValue} currentValue={row.original.data[date]} />
                  )}
                </>
              )}
            </DashboardCellInner>
          );
        },
      })),
    [dataForTable.columnHeaders, isCustomerView, waterfallType, waterfallSegmentBy, dataFilter],
  );

  const columns = React.useMemo(
    () => [
      {
        Header: () => <SettingsContainer>{togglePopover ?? <SnapshotTitle>Snapshot</SnapshotTitle>}</SettingsContainer>,
        accessor: 'title',
        Cell: ({ row, cell: { value } }) => {
          const isInFluxRelated = [REVENUE_METRIC.INFLUX.label, REVENUE_METRIC.INFLUX_MOVEMENT.label].includes(value);

          return !row.depth ? (
            <StyledTableName style={{ paddingTop: 4, paddingBottom: (!row.isExpanded || !row.canExpand) && 4 }}>
              {row.original.type === 'totalRow' ? (
                <>
                  {value}
                  {row.canExpand && (
                    <TableSubRowsCountArrow
                      dashboardTable={true}
                      {...row.getToggleRowExpandedProps()}
                      isHidden={!row.isExpanded}
                    >
                      <ArrowUp />
                    </TableSubRowsCountArrow>
                  )}
                </>
              ) : (
                <FlexBetweenContainer centerer style={{ width: '100%' }}>
                  <CentererVertical>
                    {isInFluxRelated && <SubcategoryArrowIcon style={{ marginLeft: 12 }} />}
                    <TableRowTagWaterfall noBackgroundColor={row.original.type === 'customer_timeseries_metric'}>
                      <img src={row.original.icon} alt="" style={{ marginRight: 4 }} />
                      {value}
                    </TableRowTagWaterfall>
                    {row.canExpand && (
                      <TableSubRowsCountArrow
                        dashboardTable={true}
                        {...row.getToggleRowExpandedProps()}
                        isHidden={!row.isExpanded}
                      >
                        <ArrowUp />
                      </TableSubRowsCountArrow>
                    )}
                  </CentererVertical>
                  {isInFluxRelated && (
                    <TooltipContainer
                      toolTipContent={row.original.tooltipText}
                      isVisible={!!row.original.tooltipText}
                      width={110}
                      fontSize="10px"
                      underline={false}
                    >
                      <SubCatLabel>SUB-CAT.</SubCatLabel>
                    </TooltipContainer>
                  )}
                </FlexBetweenContainer>
              )}
            </StyledTableName>
          ) : (
            <TableRowTitle style={{ whiteSpace: 'normal' }}>
              <Row horizontal="flex-start" {...row.getToggleRowExpandedProps()}>
                <TooltipContainer
                  toolTipContent={row.original.tooltipText}
                  isVisible={!!row.original.tooltipText}
                  width={110}
                  fontSize="10px"
                  underline={false}
                >
                  {value}
                </TooltipContainer>
                {row.canExpand && (
                  <TableSubRowsCountArrow dashboardTable={true} isHidden={!row.isExpanded}>
                    <ArrowUp />
                  </TableSubRowsCountArrow>
                )}
              </Row>
            </TableRowTitle>
          );
        },
      },
      ...dateColumns,
    ],
    [dateColumns, togglePopover],
  );

  const data = React.useMemo(() => {
    return dataForTable;
  }, [dataForTable]);

  const MemoTable = useMemo(() => <Table columns={columns} data={data} tableId={tableId} />, [columns, data, tableId]);

  return (
    <>
      {data && (
        <div ref={exportScreenshotRef}>
          {/* This is the chart */}
          {showStackedBarChart && (
            <Row ref={chartRef} vertical="flex-start" wrap="wrap">
              <StackedBarChart
                data-cy="waterfall-bar-chart"
                height="400px"
                labels={data.waterfallColumnHeaders}
                data={data}
                dataTableShowingBy={dataTableShowingBy}
                isARR={isARR}
                waterfallGraphPeriod={waterfallGraphPeriod}
                setWaterfallGraphPeriod={setWaterfallGraphPeriod}
                currency={currency}
              />
            </Row>
          )}
          <Spacer height="28px" />

          {MemoTable}
        </div>
      )}

      {showRevenueDetailsModal && (
        <RevenueDetailsModal
          organization={organizations[0]}
          closeModal={() => setShowRevenueDetailsModal(false)}
          selectedMonth={selectedMonth}
          selectedMetric={selectedMetric}
          selectedSegment={selectedSegment}
          segmentBy={waterfallSegmentBy}
          timeseriesMonth={waterfallSegmentByTimeseriesMonth}
          viewAsARR={isARR}
          setViewAsARR={setIsARR}
          optimisticAnalytics={optimisticAnalytics}
          setOptimisticAnalytics={setOptimisticAnalytics}
          summaryStartMonth={summaryStartMonth}
          summaryColumnHeader={summaryColumnHeader}
        />
      )}
    </>
  );
};

export { WaterfallTable };
