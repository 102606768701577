import React, { useCallback, useContext, useState } from 'react';
import { cssVar } from 'polished';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { EVENTS } from 'consts/analytics';
import { useAnalytics } from 'utils/hooks';
import { formatCustomerName } from 'utils/stringUtils';
import { Modal, ModalCloseIcon, ModalContainer, ModalHeader, ModalTitle, ModalTitleText } from 'components/Modal';
import { FillDot } from 'components/Icons';
import { CentererVertical, Spacer } from 'components/Core';
import { ForecastingContext } from '../ForecastingContext';
import { ForecastModalForm } from './ForecastModalForm';
import { EditSubtitleText } from './Shared/styles';

dayjs.extend(utc);

const EditForecastModal = ({ scenario, onClose }) => {
  const { editForecast } = useContext(ForecastingContext);
  const handleSaveForecast = (data) => {
    editForecast({ id: scenario.id, data });
    onClose();
  };

  return (
    <ModalContainer data-cy="edit-forecast-modal">
      <Modal width="740px" height="auto" overflow="visible" compact>
        <ModalCloseIcon onClose={onClose} />
        <ModalHeader>
          <ModalTitle>
            <ModalTitleText>Edit scenario with forecasts</ModalTitleText>
            <CentererVertical>
              <EditSubtitleText>
                Updated: {dayjs.utc(scenario.updated_at).format('DD/MM/YYYY')} by{' '}
                {formatCustomerName(scenario.updated_by_name)}
              </EditSubtitleText>
              <Spacer width="8px" />
              <FillDot size="4px" fill={cssVar('--neutralGray')} />
              <Spacer width="8px" />
              <EditSubtitleText>
                Created: {dayjs.utc(scenario.created_at).format('DD/MM/YYYY')} by{' '}
                {formatCustomerName(scenario.created_by_name)}
              </EditSubtitleText>
            </CentererVertical>
          </ModalTitle>
        </ModalHeader>
        <ForecastModalForm
          name="edit-forecast-modal"
          initialValues={{
            name: scenario?.name,
            start_date: scenario?.start_date,
            end_date: scenario?.end_date,
            start_generating_new_pipeline_after_months: scenario?.data?.start_generating_new_pipeline_after_months,
            new_recurring_revenue_growth: scenario?.data?.average_new_recurring_revenue_growth,
            average_pipeline_recurring_revenue: scenario?.data?.average_pipeline_recurring_revenue,
          }}
          onSubmit={handleSaveForecast}
          onClose={onClose}
          editMode={true}
        />
      </Modal>
    </ModalContainer>
  );
};

export const useEditForecastModal = (props) => {
  const { trackEvent } = useAnalytics();
  const [showModal, setShowModal] = useState(false);
  const [scenario, setScenario] = useState();

  const openModal = useCallback(
    ({ scenario }) => {
      trackEvent({
        name: EVENTS.OPEN_EDIT_FORECAST_SCENARIO,
        properties: {
          scenarioId: scenario.id,
        },
      });

      setScenario(scenario);
      setShowModal(true);
    },
    [trackEvent, setShowModal],
  );

  const Modal = useCallback(
    () => (showModal ? <EditForecastModal {...props} scenario={scenario} onClose={() => setShowModal(false)} /> : null),
    [showModal, setShowModal, scenario, props],
  );

  return {
    openModal,
    Modal,
  };
};
