import axios from 'axios';
import { API_URL } from 'api/consts';

export const bulkUpdateAccountingSpreads = async ({ orgId, data }) => {
  const url = `${API_URL}/organizations/${orgId}/accountingSpreads/bulk-update`;
  const { data: response } = await axios.post(url, data);
  return response;
};

export const exportRevenueRecognitionReportAsSeparateFiles = async ({ orgId, params }) => {
  const { data } = await axios.get(
    `${API_URL}/organizations/${orgId}/accounting/export-revenue-recognition-as-separate-files`,
    {
      responseType: 'blob',
      params,
    },
  );
  return data;
};
