import styled from 'styled-components';

import { ReactComponent as Icon } from 'images/link-share.svg';

export const LinkIcon = styled(Icon)`
  width: ${(props) => props.size};
  height: ${(props) => props.size};

  path {
    fill: var(--secondaryBlue);
  }
`;
