import { useContext, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';

import { groupBy } from 'utils/arrayUtils';
import {
  Modal,
  ModalBody,
  ModalButton,
  ModalCloseIcon,
  ModalContainer,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from 'components/Modal';
import { Flexer, FlexerColumn, Spacer } from 'components/Core';
import { InvoicingScheduleContext } from 'views/Billing/InvoicingScheduleModal/InvoicingScheduleContext';
import { numberFormatter } from 'utils/formatters';
import { NUMBER_FORMATS } from 'consts/global';
import { FormikCustomCheckbox, FormikCustomInput } from 'components/Controls';
import { addGroupToGrouping } from './utils';

const StyledModal = styled(Modal)`
  padding: 40px 0 0;
`;

const TableContainer = styled.div`
  min-height: 200px;
  overflow: auto;
  width: 100%;
  padding: 0 50px;
  flex: 1;
  max-height: 400px;
`;

const Table = styled.table`
  width: 100%;
`;

const TableHeaderWrapper = styled.thead`
  background-color: var(--accentGray);
  position: sticky;
  top: 0;
  z-index: 20;
`;

const TableHeader = styled.th`
  padding: 8px 12px;
  color: var(--primaryBlack50);
  font-weight: 400;
  font-size: 12px;

  &:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  &:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`;

const TableCell = styled.td`
  padding: 10px 5px;
  width: ${({ width }) => width ?? 'auto'};
  border-bottom: 1px solid var(--accentGraySecond);
  font-size: 12px;
  text-align: ${({ textAlign }) => textAlign ?? 'auto'};
`;

const LightText = styled.div`
  color: var(--primaryBlack50);
`;

const GroupContentWrapper = styled(FlexerColumn)`
  border: 1px solid var(--primaryBlack10);
  background-color: var(--primaryBlack3);
  padding: 20px 50px;
  gap: 16px;
`;

const ErrorBanner = styled(Flexer)`
  align-items: center;
  color: var(--primaryRed);
  border: 1px solid var(--primaryRed);
  border-radius: 8px;
  padding: 12px;
  font-size: 12px;
  font-style: italic;
  margin-bottom: 8px;
`;

const MergedItemNameInput = ({ values, setFieldValue, invoiceItems, currency }) => {
  useEffect(() => {
    if (values.groupData.name === null && values.selectedItemIds[0]) {
      const { name } = invoiceItems?.find((item) => item.id === values.selectedItemIds[0]) ?? {};
      name && setFieldValue('groupData.name', name);
    }
  }, [invoiceItems, setFieldValue, values.groupData.name, values.selectedItemIds]);

  return (
    <FormikCustomInput
      name="groupData.name"
      label="Name of the merged invoice item"
      width="100%"
      placeholder="Enter name..."
      TopRightHeader={
        <span
          style={{
            fontSize: 12,
          }}
        >
          Amount of the merged invoice item:{' '}
          <b>
            {numberFormatter({
              type: NUMBER_FORMATS.CURRENCY,
              rawValue: values.selectedItemIds.reduce(
                (sum, itemId) => sum + (invoiceItems.find(({ id }) => id === itemId)?.amount ?? 0),
                0,
              ),
              decimalPlaces: 2,
              currency,
            })}
          </b>
        </span>
      }
      labelFlex="space-between"
      data-cy="billing__merge-invoice-items-modal__input__name"
    />
  );
};

export const MergeInvoiceItemsModal = ({
  onClose,
  invoicingServiceDisplayName,
  invoiceItems: rawInvoiceItems,
  currency,
  onConfirm,
  invoiceGrouping,
}) => {
  const { transactionOptions, productOptions } = useContext(InvoicingScheduleContext);

  const invoiceItemById = groupBy(rawInvoiceItems ?? [], 'id', { uniqueness: true });

  const invoiceItems = useMemo(
    () =>
      invoiceGrouping?.reduce(
        (arr, group) => (!group.isMerged ? [...arr, invoiceItemById[group.invoice_items_ids[0]]] : arr),
        [],
      ) ?? rawInvoiceItems,
    [invoiceGrouping, invoiceItemById, rawInvoiceItems],
  );

  const columns = [
    {
      content: (
        <span>
          <b
            style={{
              color: 'var(--primaryBlack)',
            }}
          >
            Invoice Item:
          </b>{' '}
          Transaction (internal)
        </span>
      ),
    },
    {
      content: 'Product line item',
    },
    {
      content: `${invoicingServiceDisplayName} Product`,
    },
    {
      content: 'Quantity',
    },
    {
      content: 'Amount',
    },
  ];

  return (
    <ModalContainer>
      <Formik
        initialValues={{
          groupData: {
            isMerged: true,
            seats: 1,
            name: null,
            description: '',
          },
          selectedItemIds: [],
        }}
        validationSchema={Yup.object({
          groupData: Yup.object({
            name: Yup.string().required('Name is required').typeError('Name is required'),
            seats: Yup.number().nullable(),
            description: Yup.string().nullable(),
            isMerged: Yup.boolean().required(),
          }).required(),
          selectedItemIds: Yup.array()
            .of(Yup.string())
            .min(1, 'Must select at least one invoice item')
            .required('Must select at least one invoice item')
            // for some reason Yup keeps returning a null type error when it is never null
            .typeError(''),
        })}
        onSubmit={(values) => {
          const newGrouping = addGroupToGrouping({
            invoiceGrouping: invoiceGrouping,
            invoiceItems: invoiceItems,
            newGroup: {
              invoice_items_ids: values.selectedItemIds,
              ...values.groupData,
              seats: values.groupData.seats || 1,
            },
          });
          onConfirm(newGrouping);
          onClose();
        }}
      >
        {({ values, setFieldValue, submitForm, errors, touched }) => (
          <Form>
            <StyledModal width="50vw" minWidth="600px" overflow="auto" height="fit-content" minHeight="50vh">
              <ModalHeader>
                <ModalCloseIcon onClick={onClose} />
                <ModalTitle bold>Merge Invoice Items</ModalTitle>
              </ModalHeader>

              <Spacer height="25px" />

              <ModalBody paddingLeft="0px" paddingRight="0px">
                <TableContainer>
                  {!!touched.selectedItemIds && errors.selectedItemIds && (
                    <ErrorBanner>{errors.selectedItemIds}</ErrorBanner>
                  )}
                  <Table>
                    <TableHeaderWrapper>
                      <tr>
                        {columns.map((header, index) => (
                          <TableHeader key={'header' + index}>{header.content}</TableHeader>
                        ))}
                      </tr>
                    </TableHeaderWrapper>

                    <tbody>
                      {invoiceItems.map((item = {}, index) => (
                        <tr key={item.id ?? index}>
                          <TableCell width="250px">
                            <Flexer gap="4px" alignItems="center">
                              <FormikCustomCheckbox
                                name="selectedItemIds"
                                checked={values.selectedItemIds.includes(item.id)}
                                onChange={(event) => {
                                  const value = event.target.checked;
                                  if (value) {
                                    setFieldValue('selectedItemIds', [...values.selectedItemIds, item.id]);
                                  } else {
                                    setFieldValue(
                                      'selectedItemIds',
                                      values.selectedItemIds.filter((id) => id !== item.id),
                                    );
                                  }
                                }}
                                data-cy={`billing__merge-invoice-items-modal__invoice-item__checkbox--${index}`}
                              />
                              <span>
                                {transactionOptions.find((option) => option.value === item.transaction_id)?.label ??
                                  '-'}
                              </span>
                            </Flexer>
                          </TableCell>

                          <TableCell width="150px">
                            {productOptions.find((option) => option.value === item.product_id)?.label ?? '-'}
                          </TableCell>

                          <TableCell width="180px">
                            <LightText>
                              {productOptions.find((option) => option.value === item.product_id)?.subLabel ?? '-'}
                            </LightText>
                          </TableCell>

                          <TableCell width="80px" textAlign="center">
                            {item.seats ?? 0}
                          </TableCell>

                          <TableCell width="80px" textAlign="center">
                            {numberFormatter({
                              type: NUMBER_FORMATS.CURRENCY,
                              rawValue: item.amount ?? 0,
                              decimalPlaces: 2,
                              currency,
                            })}
                          </TableCell>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </TableContainer>

                <GroupContentWrapper>
                  <Flexer gap="16px">
                    <MergedItemNameInput
                      values={values}
                      setFieldValue={setFieldValue}
                      invoiceItems={invoiceItems}
                      currency={currency}
                    />
                    <FormikCustomInput
                      name="groupData.seats"
                      label="Quantity"
                      width="100px"
                      type="number"
                      labelFlex="end"
                      data-cy="billing__merge-invoice-items-modal__input__seats"
                    />
                  </Flexer>
                  <Flexer>
                    <FormikCustomInput
                      name="groupData.description"
                      label="Description"
                      placeholder="Enter description..."
                      width="100%"
                      data-cy="billing__merge-invoice-items-modal__input__description"
                    />
                  </Flexer>
                </GroupContentWrapper>
              </ModalBody>

              <ModalFooter flexEnd>
                <ModalButton onClick={onClose}>
                  <b>Cancel</b>
                </ModalButton>
                <ModalButton primary onClick={submitForm} data-cy="billing__merge-invoice-items-modal__confirm-button">
                  <b>Confirm</b>
                </ModalButton>
              </ModalFooter>
            </StyledModal>
          </Form>
        )}
      </Formik>
    </ModalContainer>
  );
};
