import { assertCloseTo } from 'utils/assertionUtils';

export const checkSumOfSegments = ({ organization, user, data }) => {
  const { cohortsData, cohortSegmentData } = data;

  if (!cohortSegmentData) return;

  /*
    For each cohort
    Loop through each month
    Verify that the sum of the segment amounts for each month matches the total
  */
  for (const cohortKey of Object.keys(cohortsData)) {
    const cohortMonthAverages = cohortsData[cohortKey].monthAverages;
    const cohortMonthTotals = cohortsData[cohortKey].monthTotals;

    const segmentMonthAveragesSums = [];
    const segmentMonthTotalsSums = [];
    const customersPerMonth = [];
    for (const { monthAverages = [], monthTotals = [], startingCustomerCount = 0 } of Object.values(
      cohortSegmentData[cohortKey] ?? {},
    )) {
      for (let index = 0; index < monthTotals.length; index++) {
        segmentMonthAveragesSums[index] = segmentMonthAveragesSums[index] ?? 0;
        segmentMonthAveragesSums[index] += monthAverages[index] * startingCustomerCount;

        segmentMonthTotalsSums[index] = segmentMonthTotalsSums[index] ?? 0;
        segmentMonthTotalsSums[index] += monthTotals[index];

        customersPerMonth[index] = customersPerMonth[index] ?? 0;
        customersPerMonth[index] += startingCustomerCount;
      }
    }

    for (let index = 0; index < cohortMonthTotals.length; index++) {
      const expectedMonthAverage = cohortMonthAverages[index];
      const expectedMonthTotal = cohortMonthTotals[index];

      const actualMonthAverage = (segmentMonthAveragesSums[index] || 0) / (customersPerMonth[index] || 1) ?? 0;
      const actualMonthTotal = segmentMonthTotalsSums[index] ?? 0;

      let assertionPassed = true;

      assertionPassed = assertCloseTo({
        organization,
        user,
        context: 'Cohorts',
        expected: expectedMonthAverage,
        actual: actualMonthAverage,
        description: `Average amount for month ${index + 1} for cohort ${cohortKey} as sum of segments`,
        errorMargin: 1,
      });

      assertionPassed = assertCloseTo({
        organization,
        user,
        context: 'Cohorts',
        expected: expectedMonthTotal,
        actual: actualMonthTotal,
        description: `Total amount for month ${index + 1} for cohort ${cohortKey} as sum of segments`,
        errorMargin: 1,
      });

      if (!assertionPassed) return;
    }
  }
};

export const checkAverageAndTotalRows = ({ organization, user, data }) => {
  const { cohortsData, totalAndAverageRows } = data;

  /*
    Verify calculations for cohorts Total and Average rows:
    
    Total (weighted) = (sum of cohort revenue total) / (number of customers)
    Average (unweighted) = (sum of cohort revenue averages) / (number of cohorts)
  */
  const sumOfAveragesPerMonth = [];
  const sumOfTotalsPerMonth = [];
  const customersPerMonth = [];
  const cohortsPerMonth = [];

  for (const { monthAverages, monthTotals, startingCustomerCount } of Object.values(cohortsData)) {
    for (let index = 0; index < monthTotals.length; index++) {
      // totals
      sumOfTotalsPerMonth[index] = sumOfTotalsPerMonth[index] ?? 0;
      sumOfTotalsPerMonth[index] += monthTotals[index];

      // averages
      sumOfAveragesPerMonth[index] = sumOfAveragesPerMonth[index] ?? 0;
      sumOfAveragesPerMonth[index] += monthAverages[index];

      customersPerMonth[index] = customersPerMonth[index] ?? 0;
      cohortsPerMonth[index] = cohortsPerMonth[index] ?? 0;
      if (startingCustomerCount > 0) {
        // customers
        customersPerMonth[index] += startingCustomerCount;

        // cohorts
        cohortsPerMonth[index]++;
      }
    }
  }

  for (let index = 0; index < totalAndAverageRows.totalRow.monthData.length; index++) {
    const totalValue = totalAndAverageRows.totalRow.monthData[index];
    const averageValue = totalAndAverageRows.averageRow.monthData[index];

    const actualTotalValue = (sumOfTotalsPerMonth[index] || 0) / (customersPerMonth[index] || 1);
    const actualAverageValue = (sumOfAveragesPerMonth[index] || 0) / (cohortsPerMonth[index] || 1);

    let assertionPassed = true;

    assertionPassed =
      assertionPassed &&
      assertCloseTo({
        organization,
        user,
        context: 'Cohorts',
        expected: totalValue,
        actual: actualTotalValue,
        description: `Total amount for month ${index + 1} as sum of cohort total amounts / # of customers`,
        errorMargin: 1,
      });

    assertionPassed =
      assertionPassed &&
      assertCloseTo({
        organization,
        user,
        context: 'Cohorts',
        expected: averageValue,
        actual: actualAverageValue,
        description: `Average amount for month ${index + 1} as sum of cohort average amounts / # of cohorts`,
        errorMargin: 1,
      });

    if (!assertionPassed) return;
  }
};
