import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { AppContext } from 'AppContext';
import { convertToPlainText, templateToHTMLString } from 'utils/htmlUtils';
import { INTEGRATION_TYPES } from 'consts/integrations';
import { DEFAULT_AVAILABLE_TAGS } from 'consts/billing';
import { CentererVertical, FlexerColumn, FlexerRow } from 'components/Core';
import { Button } from 'components/Buttons';
import { ContentEditableInput } from 'components/Controls/FormikCustomInput/ContentEditableInput';
import { DynamicFieldsSection } from 'views/Configuration/Tabs/SubscriptBillingTab/DefaultTemplate/DynamicFieldsSection';
import { shouldSendEmailFromSubscript } from 'views/Billing/utils';
import { SERVICE_WITHOUT_EMAIL_MODIFICATIONS } from '../consts';

const InvoiceModalFieldsWrapper = styled.div`
  background-color: var(--primaryGray);
  width: 50%;
  height: 86vh;
  overflow: scroll;
`;

const Header = styled.div`
  background-color: var(--accentGrayFourth);
  font-size: 10px;
  font-style: normal;
  font-weight: 900;
  color: var(--primaryBlack50);
  padding: 19px 32px;
  border-bottom: 1px solid var(--primaryBlack5);
`;

const InvoiceFields = styled(FlexerColumn)`
  padding: 24px 32px;
`;

const Row = styled(FlexerRow)`
  justify-content: space-between;
`;

const InputLabel = styled.span`
  color: var(--primaryBlack40);
  font-size: 12px;
  font-weight: 400;
  margin: 8px 0px;
`;

const BulkActionsButtonsContainer = styled(CentererVertical)`
  padding: 8px 16px;
  border-radius: 100px;
  background-color: var(--backgroundGray);
  position: sticky;
  bottom: 1vh;
  z-index: 20;
  width: fit-content;
  margin: 0 auto;
  color: white;
`;

const BulkCreateButton = styled(Button)`
  width: fit-content;
  color: white;
  background: var(--primaryGreen);
`;

export const BulkSendInvoicesModalFields = ({ invoiceIds, bulkSendInvoices, setInvoicesSelected, setShowModal }) => {
  const { integrations, orgConfigs } = useContext(AppContext);
  const { billingInvoiceDefaults } = orgConfigs;

  const invoicingService = integrations?.find((i) => i.type === INTEGRATION_TYPES.GL)?.service;
  const sendEmailFromSubscript = shouldSendEmailFromSubscript({ orgConfigs });

  const showEmailModifications =
    sendEmailFromSubscript || !SERVICE_WITHOUT_EMAIL_MODIFICATIONS.includes(invoicingService);

  const [emailSubject, setEmailSubject] = useState(billingInvoiceDefaults.email_subject);
  const [emailBody, setEmailBody] = useState(billingInvoiceDefaults.email_body);
  const [waitingOnBulkSend, setWaitingOnBulkSend] = useState(false);

  const onBulkSendNowClick = async () => {
    setWaitingOnBulkSend(true);

    try {
      await bulkSendInvoices.mutateAsync({
        invoiceIds,
        emailSubject: convertToPlainText(emailSubject),
        emailBody: convertToPlainText(emailBody),
      });
      setInvoicesSelected([]);
    } finally {
      setShowModal(false);
      setWaitingOnBulkSend(false);
    }
  };

  return (
    <InvoiceModalFieldsWrapper>
      <Header>INVOICE BODY</Header>
      <InvoiceFields gap="8px">
        {showEmailModifications && (
          <>
            <Row gap="8px">
              <FlexerColumn width="100%">
                <InputLabel>Email subject</InputLabel>
                <ContentEditableInput
                  width="100%"
                  minHeight="40px"
                  HTMLString={templateToHTMLString({
                    text: emailSubject,
                    availableTags: DEFAULT_AVAILABLE_TAGS,
                  })}
                  availableTags={DEFAULT_AVAILABLE_TAGS}
                  onChange={(value) => setEmailSubject(value)}
                />
              </FlexerColumn>
            </Row>

            <Row gap="8px">
              <FlexerColumn width="60%">
                <InputLabel>Email body</InputLabel>
                <ContentEditableInput
                  minHeight="300px"
                  HTMLString={templateToHTMLString({
                    text: emailBody,
                    availableTags: DEFAULT_AVAILABLE_TAGS,
                  })}
                  availableTags={DEFAULT_AVAILABLE_TAGS}
                  onChange={(value) => setEmailBody(value)}
                />
              </FlexerColumn>

              <FlexerColumn width="40%">
                {/* TODO: It's actually a lot of work to get the sender email from the external system, so I'm leaving it out for now */}
                {/* <InputLabel>Sender email</InputLabel>
            <StyledInput disabled={true} value={sender} /> */}
                <DynamicFieldsSection title="Dynamic fields" generalTags={DEFAULT_AVAILABLE_TAGS} />
              </FlexerColumn>
            </Row>
          </>
        )}
      </InvoiceFields>
      <BulkActionsButtonsContainer gap="8px">
        <span>Actions:</span>
        <BulkCreateButton fontWeight="700" onClick={onBulkSendNowClick} disabled={waitingOnBulkSend}>
          <span>{waitingOnBulkSend ? 'Sending...' : 'Bulk Send Invoices Now'}</span>
        </BulkCreateButton>
      </BulkActionsButtonsContainer>
    </InvoiceModalFieldsWrapper>
  );
};
