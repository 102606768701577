import React, { useMemo } from 'react';
import { useTable, usePagination, useGlobalFilter } from 'react-table';
import styled from 'styled-components';
import { SearchBar } from 'components/Blocks';
import { PaginationButton } from 'components/Buttons';
import { Row, Spacer } from 'components/Core';
import {
  ReactTable,
  ReactTableBody,
  ReactTableCell,
  ReactTableHeader,
  ReactTableHeaderColumn,
  ReactTableRow,
  ReactTableHeaderRow,
} from 'components/Table';

const MembersReactTable = styled(ReactTable)`
  margin-bottom: 30px;
`;

const MembersReactTableRow = styled(ReactTableRow)`
  &:first-child {
    border-radius: 12px 12px 0 0;
    border-top: none;
  }

  &:last-child {
    border-radius: 0 0 12px 12px;
    border-bottom: none;
  }
`;

const MembersReactTableHeaderRow = styled(ReactTableHeaderRow)`
  padding-bottom: 10px;
  border-bottom: none;
`;

const MembersReactTableBody = styled(ReactTableBody)`
  border: 1px solid var(--primaryBlack5);
  border-radius: 12px;
`;

const Table = ({ columns, data }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    setGlobalFilter,
    pageCount,
    nextPage,
    previousPage,
    state: { pageIndex, globalFilter },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: 6,
      },
    },
    useGlobalFilter,
    usePagination,
  );

  return (
    <>
      <Row horizontal="flex-end" style={{ marginTop: -51, marginBottom: 18 }}>
        <PaginationButton
          pageIndex={pageIndex + 1}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          pageCount={pageCount}
          nextPage={nextPage}
          previousPage={previousPage}
        />
        <Spacer width="20px" />
        <SearchBar
          value={globalFilter ?? ''}
          placeholder="Search..."
          onChange={({ target }) => {
            setGlobalFilter(target.value);
          }}
          onBlur={({ target }) => {
            setGlobalFilter(target.value);
          }}
          onKeyPress={(e) => e.key === 'Enter' && e.target.blur()}
        />
      </Row>
      <MembersReactTable {...getTableProps()}>
        <ReactTableHeader>
          {headerGroups.map((headerGroup) => (
            <MembersReactTableHeaderRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <ReactTableHeaderColumn customWidth={column.width} {...column.getHeaderProps()}>
                  {column.render('Header')}
                </ReactTableHeaderColumn>
              ))}
            </MembersReactTableHeaderRow>
          ))}
        </ReactTableHeader>
        <MembersReactTableBody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <MembersReactTableRow {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <ReactTableCell customWidth={cell.column.width} {...cell.getCellProps()}>
                    {cell.render('Cell')}
                  </ReactTableCell>
                ))}
              </MembersReactTableRow>
            );
          })}
        </MembersReactTableBody>
      </MembersReactTable>
    </>
  );
};

const MembersTable = ({ dataForTable, columns }) => {
  const data = useMemo(() => {
    return dataForTable;
  }, [dataForTable]);

  return <>{data && <Table columns={columns} data={data} />}</>;
};

export { MembersTable };
