import React, { useCallback, useContext, useState } from 'react';
import { AppContext } from 'AppContext';
import { humanize } from 'utils/stringUtils';
import { useImportsAPI } from 'api/imports';
import { getServiceCategory } from 'models/integration';
import {
  ModalCloseIcon,
  Modal,
  ModalContainer,
  ModalHeader,
  ModalTitle,
  ModalBody,
  ModalFooter,
} from 'components/Modal';
import { CancelButton, SubmitButton } from 'components/Buttons';
import { Loader } from 'components/Loaders';

const ERROR_IMPORT_ON_OTHER_CUSTOMER_MESSAGE = 'There is already an import for customer with the same external id';
export const ERROR_IMPORT_ON_OTHER_CUSTOMER = new Error('This import is already linked to another customer');

const ErrorModal = ({ orgId, setIsOpen, importId }) => {
  const { data: customerImports, isLoading } = useImportsAPI({
    orgId,
    filters: {
      chifferObjectName: 'customer',
      providerObjectIds: [importId],
    },
  });

  const customerImport = customerImports?.[0];

  return (
    <ModalContainer data-cy="customers-import-error-modal">
      <Modal compact maxWidth="400px" height="auto" maxHeight="auto" overflow="auto">
        <ModalHeader>
          <ModalCloseIcon onClose={() => setIsOpen(false)} />
          <ModalTitle>
            <b>Duplicated association</b>
          </ModalTitle>
        </ModalHeader>

        {isLoading ? (
          <div className="w-100 flexer">
            <Loader containerStyles={{ width: 40, margin: 20 }} />
          </div>
        ) : (
          <>
            <ModalBody>
              <p>
                There is already another Subscript customer associated to this{' '}
                {humanize(getServiceCategory(customerImport?.provider_name))} customer.
              </p>
              <p>
                Please <b>delete</b> the association from the other customer and try again.
              </p>
            </ModalBody>

            <ModalFooter flexEnd>
              <CancelButton onClick={() => setIsOpen(false)}>Close</CancelButton>
              <SubmitButton
                data-cy="customers-import-error-modal__open-customer"
                className="green"
                onClick={() => {
                  if (!customerImport?.chiffer_object_id) {
                    alert('There was an error opening the customer. Please try again.');
                  } else {
                    window
                      .open(`${window.location.origin}/customers/${customerImport?.chiffer_object_id}`, '_blank')
                      .focus();
                  }
                  setIsOpen(false);
                }}
              >
                Open Customer
              </SubmitButton>
            </ModalFooter>
          </>
        )}
      </Modal>
    </ModalContainer>
  );
};

export const useCustomerImports = () => {
  const { orgId } = useContext(AppContext);
  const [isOpen, setIsOpen] = useState(false);
  const [errorImportId, setErrorImportId] = useState();

  const {
    operations: { upsertImport },
  } = useImportsAPI({
    orgId,
    autoFetch: false,
  });

  const updateCustomerImport = async ({ integrationId, chifferObjectId, providerObjectId, metadata }) => {
    try {
      await upsertImport.mutateAsync({
        integrationId,
        data: {
          chiffer_object_name: 'customer',
          chiffer_object_id: chifferObjectId,
          provider_object_id: providerObjectId,
          metadata,
        },
      });
    } catch (err) {
      if (err?.response?.data?.errors?.message === ERROR_IMPORT_ON_OTHER_CUSTOMER_MESSAGE) {
        setIsOpen(true);
        setErrorImportId(providerObjectId);
        throw ERROR_IMPORT_ON_OTHER_CUSTOMER;
      }
      throw err;
    }
  };

  const ErrorUpdatingImportModal = useCallback(
    () => (isOpen ? <ErrorModal orgId={orgId} setIsOpen={setIsOpen} importId={errorImportId} /> : null),
    [orgId, isOpen, setIsOpen, errorImportId],
  );

  return {
    updateCustomerImport,
    ErrorUpdatingImportModal,
  };
};
