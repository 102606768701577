import { useContext } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { ReactComponent as InfoCircleIcon } from 'images/info-circle-icon.svg';
import { RECOGNITION_TYPES } from 'consts/global';
import { Wrapper } from './TransactionTillCanceledEndDateWarning.styles';
import { TransactionContext } from '../TransactionContext';

dayjs.extend(utc);

export const TransactionTillCanceledEndDateWarning = () => {
  const { currentTransaction, transactionFormValues } = useContext(TransactionContext);

  const showTillCanceledEndDateWarning =
    transactionFormValues?.recognition === RECOGNITION_TYPES.tillCanceled &&
    currentTransaction.end_date === null &&
    transactionFormValues?.end_date;

  return (
    showTillCanceledEndDateWarning && (
      <Wrapper>
        <InfoCircleIcon width={24} height={24} />
        <span className="p-1">
          All future bills after <b>{dayjs.utc(transactionFormValues?.end_date).format('MMM YYYY')}</b> for this
          transaction will be <i>deleted</i>.
        </span>
      </Wrapper>
    )
  );
};
