import React, { useContext, useCallback } from 'react';
import { useQueryClient } from 'react-query';
import styled from 'styled-components';
import { AppContext } from 'AppContext';
import { INTEGRATION_TYPES } from 'consts/integrations';
import { getIntegrationDisplayName } from 'models/integration';
import { CACHE_KEY } from 'api/customers';
import { ReactComponent as RecurringIcon } from 'images/refresh.svg';
import { ReactComponent as RefreshIcon } from 'images/refresh-icon.svg';
import { TooltipContainer } from 'components/Tooltip';
import { DropdownButton, DropdownButtonItem, IconGreenButton } from 'components/Buttons';
import { ObservableContext } from 'utils/observable/ObservableContext';
import { useIntegrationsAPI } from 'api/integrations/hooks';
import { getOrganizations } from 'api/organizations';
import { SyncButton, SyncingButton } from './styles';
import { SYNC_ENTITIES, SYNC_ENTITIES_SINGULAR } from './consts';

const WhiteRefreshIcon = styled(RefreshIcon)`
  margin-right: 10px;

  path {
    fill: white;
  }
`;

const SyncEntityButton = ({ entity, entityId, tableVer }) => {
  const { organizations, setOrganizations, integrations } = useContext(AppContext);
  const { transactionsObservable } = useContext(ObservableContext);
  const {
    operations: { syncEntity },
  } = useIntegrationsAPI({ orgId: organizations[0].id, autoFetch: false });
  const queryClient = useQueryClient();

  const refetch = useCallback(async () => {
    try {
      const backendOrganizations = await getOrganizations();
      if (backendOrganizations) {
        setOrganizations(backendOrganizations);
      }
    } catch (err) {
      console.error({ message: err.message, component: 'SyncEntityButton.js', stack: err });
    }
  }, [setOrganizations]);

  const syncIntegrations = (integrations || []).filter((e) => {
    switch (entity) {
      case SYNC_ENTITIES.TRANSACTIONS:
      case SYNC_ENTITIES.PIPELINE:
      case SYNC_ENTITIES.PRODUCTS:
        return e.type === INTEGRATION_TYPES.CRM;
      case SYNC_ENTITIES.CUSTOMERS:
        // Maybe we can add INTEGRATION_TYPES.GL here eventually
        // We "just" need to also add it to syncUtils.js on the BE
        return [INTEGRATION_TYPES.CRM, INTEGRATION_TYPES.Billing, INTEGRATION_TYPES.GL].includes(e.type);
      default:
        return false;
    }
  });

  const sync = async (integrationId) => {
    await syncEntity.mutateAsync({
      integrationId,
      entity,
      internalIds: entityId ? [entityId] : null,
      initialSync: !!entityId,
    });
    switch (entity) {
      case SYNC_ENTITIES.TRANSACTIONS:
        transactionsObservable.fire();
        break;
      case SYNC_ENTITIES.PRODUCTS:
        await refetch();
        break;
      case SYNC_ENTITIES.CUSTOMERS:
        await queryClient.invalidateQueries(CACHE_KEY);
        break;
      default:
    }
  };

  if (syncEntity.isLoading) {
    return tableVer ? (
      <IconGreenButton text="Syncing data..." icon={<RefreshIcon style={{ marginRight: 10 }} />} />
    ) : (
      <SyncingButton />
    );
  }

  if (syncIntegrations.length === 1) {
    return (
      <SyncIntegrationButton
        tableVer={tableVer}
        integration={syncIntegrations[0]}
        sync={sync}
        entity={entity}
        entityId={entityId}
        data-cy="sync-entity-button"
      />
    );
  } else if (syncIntegrations.length > 1) {
    return <SyncIntegrationsMultipleButton integrations={syncIntegrations} sync={sync} />;
  }
  return <></>;
};

const SyncIntegrationButton = ({ integration, sync, entity, entityId, tableVer }) => (
  <TooltipContainer
    width={170}
    fontSize="12px"
    toolTipContent={
      entityId
        ? `Sync ${SYNC_ENTITIES_SINGULAR[entity]} from ${getIntegrationDisplayName(integration)}`
        : `Get new ${entity} from ${getIntegrationDisplayName(integration)}`
    }
  >
    {tableVer ? (
      <IconGreenButton onClick={() => sync(integration.id)} text="Refresh data" icon={<WhiteRefreshIcon />} />
    ) : (
      <SyncButton onClick={() => sync(integration.id)} />
    )}
  </TooltipContainer>
);

const SyncIntegrationsMultipleButton = ({ integrations, sync }) => {
  return (
    <DropdownButton
      hideArrow
      style={{ boxShadow: '4px 4px 28px var(--primaryBlack5)', padding: '8px' }}
      icon={<RecurringIcon />}
      color="var(--primaryBlack)"
      width={'200'}
      data-cy="sync-entity-button"
    >
      {integrations.map((integration) => (
        <DropdownButtonItem
          key={integration.id}
          onClick={() => sync(integration.id)}
          data-cy={`sync-entity-${integration}`}
        >
          Sync from {getIntegrationDisplayName(integration)}
        </DropdownButtonItem>
      ))}
    </DropdownButton>
  );
};

export { SyncEntityButton };
