import React, { useState } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';

import { FlexBetweenContainer, Flexer, Divider } from 'components/Core';
import { displayBillingPeriod } from 'views/Billing/utils';
import { INVOICE_ITEM_TYPES } from 'views/Billing/consts';
import { ReactComponent as CircledArrowDownWhiteIcon } from 'images/circled-arrow-down-white.svg';
import { ReactComponent as CircledArrowUpWhiteIcon } from 'images/circled-arrow-up-white.svg';
import { numberFormatter } from 'utils/formatters';
import { NUMBER_FORMATS } from 'consts/global';
import { groupBy } from 'utils/arrayUtils';

const InvoiceListItem = styled.div`
  padding: 22px;

  &:not(:last-child) {
    border-bottom: 1px solid var(--white20);
  }
`;

const InvoiceHeader = styled(FlexBetweenContainer)`
  font-weight: 900;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
`;

const InvoiceHeaderLeft = styled(Flexer)`
  align-items: center;
  gap: 10px;
`;

const InvoiceHeaderRight = styled(Flexer)`
  align-items: center;
  gap: 8px;
`;

const TransactionPillsList = styled(Flexer)`
  margin-top: 8px;
  gap: 8px;
  flex-wrap: wrap;
`;

const InvoiceItemPill = styled.div`
  background: var(--secondaryGreen);
  border-radius: 100px;
  color: #ffffff;
  gap: 4px;
  padding: 8px 12px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  width: fit-content;

  > b {
    font-weight: 700;
  }
`;

const FutureInvoicesToggle = styled.div`
  color: white;
  width: 100%;
  margin-top: 20px;
  font-size: 14px;
  display: flex;
  align-items: center;

  > svg {
    margin-left: 8px;
  }
`;

const StyledDivider = styled(Divider)`
  margin: 20px -22px;
  opacity: 0.2;
`;

const FutureInvoicesContainer = styled.div`
  background: var(--white8);
  border: 1px solid var(--white20);
  border-radius: 12px;
  margin-top: 12px;
`;

const FutureInvoiceContainer = styled.div`
  border-bottom: ${({ isLast }) => (isLast ? 'none' : '1px solid var(--white20)')};
  padding: 12px;
`;

const FutureInvoiceHeader = styled.div`
  font-weight: 900;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
`;

const FutureInvoiceItem = styled.div`
  color: #ffffff;
  gap: 4px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  width: fit-content;
`;

const StyledCircledArrowDownWhiteIcon = styled(CircledArrowDownWhiteIcon)`
  cursor: pointer;
`;

const StyledCircledArrowUpWhiteIcon = styled(CircledArrowUpWhiteIcon)`
  cursor: pointer;
`;

export const InvoicesSection = ({
  invoice,
  productsById,
  currency,
  frequency,
  futureInvoices = [],
  orgConfigs,
  translations,
  language,
}) => {
  const taxLabel = orgConfigs?.billingSenderDefaults?.tax_label;
  const shouldDisplayEndDate = invoice?.service_end;
  const startPeriod = displayBillingPeriod({
    invoiceDate: invoice?.service_start ?? invoice?.date,
    frequency,
    language,
  });
  const startTimeDisplay = shouldDisplayEndDate ? startPeriod.split('-')[0].trim() : startPeriod;
  const endPeriod = shouldDisplayEndDate
    ? displayBillingPeriod({
        invoiceDate: invoice?.service_end ?? invoice?.date,
        frequency: !invoice?.service_end ? frequency : null,
        language,
      })
    : null;

  const endTimeDisplay = endPeriod && (endPeriod.split('-')[1]?.trim() ?? endPeriod);
  const displayPeriod = `${startTimeDisplay}${endTimeDisplay ? ` - ${endTimeDisplay}` : ''}`;

  const [isFutureInvoicesVisible, setIsFutureInvoicesVisible] = useState(false);

  const invoiceItemsForInvoice = (invoice) => {
    const getInvoiceItemDescription = (invoiceItem) => {
      switch (invoiceItem.type) {
        case INVOICE_ITEM_TYPES.TAXES:
          return invoiceItem.description ?? taxLabel ?? 'Total Tax';
        case INVOICE_ITEM_TYPES.FEE:
          return invoiceItem.description ?? 'Total Fee';
        case INVOICE_ITEM_TYPES.SHIPPING:
          return invoiceItem.description ?? 'Shipping';
        default:
          return (
            productsById[invoiceItem.product_id]?.display_name ||
            productsById[invoiceItem.product_id]?.name ||
            invoiceItem.name
          );
      }
    };

    const invoiceItemById = invoice?.invoice_items ? groupBy(invoice?.invoice_items, 'id', { uniqueness: true }) : {};
    const groupingItemsIds =
      invoice?.grouping?.flatMap((group) => group.invoice_items_ids.map((id) => id?.toString())) ?? [];
    const excludedItems =
      invoice?.invoice_items?.filter((item) => !groupingItemsIds.includes(item.id?.toString())) ?? [];
    const invoiceItemsWithMergedItems = invoice?.grouping
      ? [
          ...invoice?.grouping.map((group) =>
            group.isMerged
              ? {
                  ...group,
                  amount: group.invoice_items_ids.reduce((sum, id) => sum + (invoiceItemById[id]?.amount ?? 0), 0),
                  id: group.invoice_items_ids.join(''),
                }
              : { ...invoiceItemById[group.invoice_items_ids[0]] },
          ),
          ...excludedItems,
        ]
      : invoice?.invoice_items;

    return (
      invoiceItemsWithMergedItems?.map((item) => ({
        description: getInvoiceItemDescription(item),
        date: invoice?.date,
        amount: item.amount,
        id: item.id,
        isPaid: !!invoice?.paid_at,
        invoiceId: invoice?.id,
      })) ?? []
    );
  };

  const invoiceItems = invoiceItemsForInvoice(invoice);

  return (
    <InvoiceListItem>
      <InvoiceHeader>
        <InvoiceHeaderLeft>{displayPeriod}</InvoiceHeaderLeft>
        <InvoiceHeaderRight>
          <span>
            {numberFormatter({
              type: NUMBER_FORMATS.CURRENCY,
              rawValue: invoice?.amount,
              currency,
              decimalPlaces: 2,
              language,
            })}
          </span>
        </InvoiceHeaderRight>
      </InvoiceHeader>

      <TransactionPillsList>
        {Object.values(
          invoiceItems.map(({ description, amount }) => (
            <InvoiceItemPill key={description}>
              {description}:{' '}
              <b>
                {numberFormatter({
                  type: NUMBER_FORMATS.CURRENCY,
                  rawValue: amount,
                  currency,
                  decimalPlaces: 2,
                  language,
                })}
              </b>
            </InvoiceItemPill>
          )),
        )}
      </TransactionPillsList>
      {futureInvoices?.length ? (
        <>
          <StyledDivider />
          <FutureInvoicesToggle onClick={() => setIsFutureInvoicesVisible(!isFutureInvoicesVisible)}>
            {translations?.['See'] ?? 'See'} {futureInvoices.length}{' '}
            {translations?.['future invoices'] ?? 'future invoices'}{' '}
            {isFutureInvoicesVisible ? <StyledCircledArrowDownWhiteIcon /> : <StyledCircledArrowUpWhiteIcon />}
          </FutureInvoicesToggle>
          {isFutureInvoicesVisible && (
            <FutureInvoicesContainer>
              {futureInvoices.map((futureInvoice, index) => (
                <FutureInvoiceContainer isLast={index === futureInvoice.length - 1} key={futureInvoice.id}>
                  <FutureInvoiceHeader>
                    <span>{dayjs.utc(futureInvoice.date).format('MMM YYYY')}</span>
                    <span>
                      {numberFormatter({
                        type: NUMBER_FORMATS.CURRENCY,
                        rawValue: futureInvoice.amount,
                        currency,
                        decimalPlaces: 2,
                        language,
                      })}
                    </span>
                  </FutureInvoiceHeader>
                  <TransactionPillsList>
                    {Object.values(
                      invoiceItemsForInvoice(futureInvoice).map(({ description, amount }) => (
                        <FutureInvoiceItem key={description}>
                          {description}:{' '}
                          {numberFormatter({
                            type: NUMBER_FORMATS.CURRENCY,
                            rawValue: amount,
                            currency,
                            decimalPlaces: 2,
                            language,
                          })}
                        </FutureInvoiceItem>
                      )),
                    )}
                  </TransactionPillsList>
                </FutureInvoiceContainer>
              ))}
            </FutureInvoicesContainer>
          )}
        </>
      ) : null}
    </InvoiceListItem>
  );
};
