import React, { Fragment, useMemo } from 'react';
import styled from 'styled-components';
import { useGlobalFilter, useTable, useExpanded, usePagination } from 'react-table';
import { SearchContainer } from 'shared/TransactionTable/styles';
import { useDebouncedSearchBar } from 'components/Blocks';
import { Row } from 'components/Core';
import { RowsCount } from 'components/Table';
import { PaginationButton } from 'components/Buttons';
import {
  ReactTableBody,
  ReactTableHeader,
  ReactTableHeaderColumn,
  ReactTableHeaderRow,
  ReactTableCell,
  ReactTableRow,
} from 'components/Table';
import { ReactComponent as GreyDot } from 'images/medium-grey-dot.svg';
import { getColumns } from './columns';
import { ChildCustomersTable } from './ChildCustomersTable';

const ControlsWrapper = styled(Row)`
  width: 100%;
  justify-content: space-between;

  padding: 8px 12px;
`;

export const ARCustomersTable = ({ data }) => {
  const dataForTable = useMemo(
    () =>
      Object.keys(data?.customers ?? {}).map((key) => ({
        ...data?.customers?.[key],
        id: key,
      })),
    [data],
  );

  const columns = useMemo(() => getColumns({}), []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    canPreviousPage,
    canNextPage,
    setGlobalFilter,
    pageCount,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data: dataForTable,
      initialState: {
        globalFilter: '',
        pageIndex: 0,
        pageSize: 50,
      },
    },
    useGlobalFilter,
    useExpanded,
    usePagination,
  );

  const { DebouncedSearchBar } = useDebouncedSearchBar({
    fontSize: '12px',
    onSearchQueryChange: setGlobalFilter,
    initialValue: '',
    placeholder: 'Search by customer...',
    width: '450px',
  });

  return (
    <>
      <ControlsWrapper>
        <SearchContainer>
          <DebouncedSearchBar />
        </SearchContainer>

        <Row>
          <RowsCount>
            <span data-cy="customers-table__row-count">{dataForTable?.length} rows found</span>
          </RowsCount>
          <GreyDot style={{ marginLeft: 20, marginRight: 16 }} />

          <PaginationButton
            pageIndex={pageIndex + 1}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            pageCount={pageCount}
            nextPage={nextPage}
            previousPage={previousPage}
          />
        </Row>
      </ControlsWrapper>
      <div {...getTableProps()}>
        <ReactTableHeader backgroundColor="var(--secondaryGray)">
          {headerGroups.map((headerGroup) => (
            <ReactTableHeaderRow {...headerGroup.getHeaderGroupProps()} noSpaceBetween>
              {headerGroup.headers.map((column) => (
                <ReactTableHeaderColumn
                  {...column.getHeaderProps()}
                  customWidth={column.width}
                  alignRight={column.alignRight ?? true}
                >
                  {column.render('Header')}
                </ReactTableHeaderColumn>
              ))}
            </ReactTableHeaderRow>
          ))}
        </ReactTableHeader>
        {rows.length ? (
          <ReactTableBody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              const dataForTable = [...row.original.childCustomers];
              if (row.original.currentCustomer) {
                dataForTable.unshift({ ...row.original.currentCustomer, isCurrentCustomer: true });
              }
              return (
                <Fragment>
                  <ReactTableRow {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <ReactTableCell
                        {...cell.getCellProps()}
                        customWidth={cell.column.width}
                        alignRight={cell.column.alignRight ?? true}
                      >
                        {cell.render('Cell')}
                      </ReactTableCell>
                    ))}
                  </ReactTableRow>
                  {row.isExpanded && row.original?.childCustomers && (
                    <ChildCustomersTable dataForTable={dataForTable} />
                  )}
                </Fragment>
              );
            })}
          </ReactTableBody>
        ) : null}
      </div>
    </>
  );
};
