import { useContext, useEffect } from 'react';
import { AppContext } from 'AppContext';
import { useInvoicingScheduleAPI } from 'api/billing/hooks';
import { TransactionContext } from 'shared/TransactionContent/TransactionContext';
import { ViewScheduleContainer, HorizontalLine, LinkToInvoicingSchedule } from './styles';

export const TransactionLinkToInvoicingSchedule = ({ invoicingScheduleId }) => {
  const { orgId } = useContext(AppContext);
  const { openInvoicingScheduleModal, setInvoicingSchedule } = useContext(TransactionContext);
  const { data: invoicingSchedule } = useInvoicingScheduleAPI({ orgId, invoicingScheduleId, scopes: ['invoices'] });

  useEffect(() => {
    if (invoicingSchedule) {
      setInvoicingSchedule(invoicingSchedule);
    }
  }, [invoicingSchedule, setInvoicingSchedule]);

  return (
    <ViewScheduleContainer>
      <LinkToInvoicingSchedule
        data-cy="transaction__view-schedule-button"
        onClick={() => openInvoicingScheduleModal({ invoicingSchedule: { id: invoicingScheduleId } })}
      >
        View Invoicing Schedule
      </LinkToInvoicingSchedule>
      <HorizontalLine />
      <span>{invoicingSchedule?.invoices?.length ?? 0} invoices</span>
    </ViewScheduleContainer>
  );
};
