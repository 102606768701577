import { useContext } from 'react';
import { SizedLoader } from 'components/Loaders';
import { LinkBackButton } from 'components/Buttons';
import { TransactionContext } from 'shared/TransactionContent/TransactionContext';
import { TransactionForm } from 'shared/TransactionContent/TransactionForm';
import { TransactionHeader } from 'shared/TransactionContent/TransactionHeader';
import { TransactionBody } from 'shared/TransactionContent/TransactionBody';
import { TransactionSidebar } from 'shared/TransactionContent/TransactionSidebar';
import { TransactionDeleteModal } from 'shared/TransactionContent/TransactionDeleteModal';
import { TransactionsActions } from 'shared/TransactionContent/TransactionsActions';
import { TransactionConfirmInvoicesModal } from 'shared/TransactionContent/TransactionConfirmInvoicesModal';
import { InvoicingScheduleChangesContainer } from 'shared/InvoicingScheduleChanges';
import { ContentWrapper, LoaderContainer, ContentMain } from 'shared/TransactionContent/TransactionLayout.styles';

export const TransactionSinglePageLayout = () => {
  const {
    currentTransaction,
    closeModal,
    setShowDeleteModal,
    showDeleteModal,
    showUpdateInvoicesModal,
    setShowUpdateInvoicesModal,
    loading,
    onTransactionDeleted,
    organization,
  } = useContext(TransactionContext);

  return (
    <ContentWrapper>
      {loading ? (
        <LoaderContainer>
          <SizedLoader size={40} />
        </LoaderContainer>
      ) : (
        <InvoicingScheduleChangesContainer>
          <TransactionForm>
            <ContentMain>
              <TransactionHeader
                leftSection={<LinkBackButton to="/transactions">All Transactions</LinkBackButton>}
                rightSection={<TransactionsActions />}
              />
              <TransactionBody />
            </ContentMain>
            <TransactionSidebar />
            {showDeleteModal && (
              <TransactionDeleteModal
                organizationId={organization.id}
                onClose={() => setShowDeleteModal(false)}
                transaction={currentTransaction}
                onDeleted={({ deletedTransaction }) => {
                  onTransactionDeleted?.({ deletedTransaction });
                }}
              />
            )}
            {showUpdateInvoicesModal && (
              <TransactionConfirmInvoicesModal
                orgId={organization.id}
                transaction={currentTransaction}
                onClose={() => {
                  showDeleteModal && setShowDeleteModal(false);
                  setShowUpdateInvoicesModal(false);
                  closeModal?.();
                }}
              />
            )}
          </TransactionForm>
        </InvoicingScheduleChangesContainer>
      )}
    </ContentWrapper>
  );
};
