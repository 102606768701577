import dayjs from 'dayjs';

export const getIntervalForPeriodsArray = ({ periodsArray }) => {
  if (periodsArray.length === 0) return null;

  let min = dayjs(periodsArray[0].start_month);
  let max = dayjs(periodsArray[0].start_month).add(periodsArray[0].months_count, 'month');
  periodsArray.forEach((val) => {
    const start = dayjs(val.start_month);
    const end = dayjs(val.start_month).add(val.months_count, 'month');

    if (start.isBefore(min)) min = start;
    if (end.isAfter(max)) max = end;
  });

  return { min, max };
};
