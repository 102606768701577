import React, { useContext } from 'react';
import { useFormikContext } from 'formik';
import styled from 'styled-components';
import dayjs from 'dayjs';

import { AppContext } from 'AppContext';
import { Modal, ModalBody, ModalCloseIcon, ModalContainer, ModalHeader, ModalTitleText } from 'components/Modal';
import { Centerer, Column, Flexer, Row } from 'components/Core';
import { AlarmIcon, FileTextIcon } from 'components/Icons';
import { useCreditNotePdfAPI } from 'api/billing/hooks';
import { CustomDatePicker } from 'components/Controls';
import { formatDateForDatepicker, updateDateFromDatePicker } from 'utils/dateUtils';

import { CreditNoteDetailsForm } from './CreditNoteDetailsForm';
import { CreditNoteSendFooter } from './CreditNoteSendFooter';
import { InvoicingScheduleContext } from '../InvoicingScheduleModal/InvoicingScheduleContext';
import { CreditNoteContext } from '../InvoicingScheduleModal/CreditNoteContext';
import {
  StyledLeftTabButton,
  GeneratingPreview,
  InvoiceLink,
  ScheduleTabs,
  StyledRightTabButton,
  Wrapper,
} from '../InvoicePreviewModalV2/styles';
import { creditNotePDFDownload } from '../InvoicingScheduleModal/InvoicingScheduleCreditNotePanel/utils';
import { CreditNotePDFPreviewContents } from '../InvoicingScheduleModal/InvoicingScheduleCreditNotePanel/useCreditNotePDFPreviewModal';
import { CREDIT_NOTE_METADATA } from '../consts';

const FlexGrow = styled.div`
  flex-grow: 1;
`;

export const CreditNoteSendModal = ({ onClose, onSaveCreditNote }) => {
  const { orgId } = useContext(AppContext);
  const { currentInvoicingSchedule } = useContext(InvoicingScheduleContext);

  const { loadingCreditNotes } = useContext(CreditNoteContext);

  const { values: creditNoteFormValues, setFieldValue, getFieldMeta, resetForm } = useFormikContext();

  const {
    data: creditNotePdf,
    isLoading: isPdfLoading,
    isFetching: isPdfFetching,
    operations: { regenerateCreditNotePdf },
  } = useCreditNotePdfAPI({
    orgId,
    creditNoteId: creditNoteFormValues?.id,
    invoiceId: creditNoteFormValues?.allocated_invoice,
    entityId: currentInvoicingSchedule?.entity_id,
    language: currentInvoicingSchedule?.language,
  });

  const isResend = !!creditNoteFormValues?.sent_at;

  return (
    <ModalContainer style={{ flexDirection: 'column', backgroundColor: 'var(--primaryBlack95)' }}>
      <Modal
        padding="0"
        direction="row"
        maxHeight="90vh"
        width="90vw"
        overflow="auto"
        background="var(--primaryGray)"
        data-cy="credit-note-send-modal"
      >
        {!creditNoteFormValues || loadingCreditNotes ? (
          <Centerer>
            <GeneratingPreview gap="4px">
              <AlarmIcon /> {creditNotePdf?.pdf ? 'Loading...' : 'Generating the preview...'}
            </GeneratingPreview>
          </Centerer>
        ) : (
          <>
            <Column horizontal="start" style={{ paddingTop: 28 }}>
              <ModalHeader addPadding style={{ paddingTop: 0 }}>
                <ModalCloseIcon
                  data-cy="credit-note-send-modal__close-button"
                  onClose={() => {
                    resetForm();
                    onClose();
                  }}
                />

                <Row>
                  <ModalTitleText compact>
                    <Flexer alignItems="center" gap="8px">
                      <b>{isResend ? 'Send Credit Note' : 'Send Credit Note'}</b>
                    </Flexer>
                  </ModalTitleText>

                  <ScheduleTabs>
                    <StyledLeftTabButton
                      active={!creditNoteFormValues?.auto_send}
                      onClick={() => {
                        setFieldValue(`metadata.${CREDIT_NOTE_METADATA.AUTO_SEND_INVOICING_SCHEDULE_ID}`, null);
                        setFieldValue('auto_send', false);
                      }}
                      isBig={false}
                      data-cy="credit-note-send-modal__send-now-button"
                    >
                      Send right now
                    </StyledLeftTabButton>

                    <StyledRightTabButton
                      onClick={() => {
                        setFieldValue('auto_send', true);
                        !creditNoteFormValues?.auto_send_date &&
                          setFieldValue('auto_send_date', dayjs.utc().startOf('day').add(1, 'day').toDate());
                        setFieldValue(
                          `metadata.${CREDIT_NOTE_METADATA.AUTO_SEND_INVOICING_SCHEDULE_ID}`,
                          currentInvoicingSchedule?.id ?? null,
                        );
                      }}
                      active={creditNoteFormValues?.auto_send}
                      isBig={false}
                      data-cy="credit-note-send-modal__schedule-send-button"
                    >
                      Schedule send
                    </StyledRightTabButton>

                    {creditNoteFormValues?.auto_send && (
                      <CustomDatePicker
                        formik
                        floatErrors
                        errorBottomPosition="-65px"
                        name="auto_send_date"
                        meta={getFieldMeta('auto_send_date')}
                        dateFormat="MMM dd, yyyy"
                        selected={
                          creditNoteFormValues?.auto_send_date
                            ? formatDateForDatepicker(creditNoteFormValues?.auto_send_date)
                            : null
                        }
                        onChange={(name, send_date) => setFieldValue(name, updateDateFromDatePicker(send_date))}
                      />
                    )}
                  </ScheduleTabs>
                </Row>

                <Row>
                  {creditNotePdf?.pdf && (
                    <InvoiceLink onClick={() => creditNotePDFDownload({ creditNotePdf })}>
                      <FileTextIcon />
                      PDF
                    </InvoiceLink>
                  )}
                </Row>
              </ModalHeader>

              <ModalBody maxWidth="90vw" paddingLeft="0px" paddingRight="0px">
                <Wrapper>
                  <FlexGrow>
                    <CreditNoteDetailsForm />
                  </FlexGrow>

                  <CreditNotePDFPreviewContents
                    creditNotePdf={creditNotePdf}
                    isPdfLoading={isPdfLoading || isPdfFetching || regenerateCreditNotePdf.isLoading}
                    width="650px"
                    pdfWidth={620}
                    onRegenerate={async () => {
                      await regenerateCreditNotePdf.mutateAsync();
                    }}
                  />
                </Wrapper>
              </ModalBody>

              <Row style={{ width: '100%' }} horizontal="end">
                <CreditNoteSendFooter
                  creditNote={creditNoteFormValues}
                  onClose={onClose}
                  onSaveCreditNote={onSaveCreditNote}
                  isResend={isResend}
                />
              </Row>
            </Column>
          </>
        )}
      </Modal>
    </ModalContainer>
  );
};
