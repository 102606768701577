import React, { useContext } from 'react';
import { cssVar } from 'polished';
import { AppContext } from 'AppContext';
import { useAccountingRevenueForMonthAPI, useAccountingRevenueForMonthCustomerAPI } from 'api/accountingRevenue';
import { Centerer } from 'components/Core';
import { FullscreenModal, ModalContainer } from 'components/Modal';
import { SizedLoader } from 'components/Loaders';
import { AccountingMonthModalHeader } from './AccountingMonthModalHeader';
import { AccountingMonthModalBody } from './AccountingMonthModalBody';

export const AccountingMonthDrilldownModal = ({
  month,
  revenueType,
  onClose,
  customerId,
  setCustomerId,
  accountingSpreadPolicy,
  setTransactionToShow,
  setMonth,
  showCustomersWithDeferred,
  setShowCustomersWithDeferred,
  showCustomersWithAccrued,
  setShowCustomersWithAccrued,
  showCustomersWithAccountingRevenue,
  setShowCustomersWithAccountingRevenue,
  showCustomersWithInvoiced,
  setShowCustomersWithInvoiced,
  modalGLIntegrationId: glIntegrationId,
}) => {
  const {
    orgId,
    metadataFilter,
    glIntegrations,
    orgConfigs: { accountingCurrencies },
  } = useContext(AppContext);

  const hasMultipleGLs = glIntegrations.length > 1;

  const accountingCurrency = accountingCurrencies?.[glIntegrationId]?.value;

  const { data: dataForMonth, isLoading: isLoadingDataForMonth } = useAccountingRevenueForMonthAPI({
    orgId,
    params: {
      month,
      glIntegrationId: hasMultipleGLs ? glIntegrationId : undefined,
      customerMetadata: JSON.stringify(metadataFilter?.customerMetadata),
      transactionMetadata: JSON.stringify(metadataFilter?.transactionMetadata),
    },
    autoFetch: !customerId,
  });

  const { data: dataForCustomer, isLoading: isLoadingDataForCustomer } = useAccountingRevenueForMonthCustomerAPI({
    orgId,
    params: {
      month,
      customerId,
      customerMetadata: JSON.stringify(metadataFilter?.customerMetadata),
      transactionMetadata: JSON.stringify(metadataFilter?.transactionMetadata),
    },
    autoFetch: !!customerId,
  });

  const data = customerId ? dataForCustomer : dataForMonth;
  const isLoading = isLoadingDataForMonth || isLoadingDataForCustomer;

  return (
    <ModalContainer>
      <FullscreenModal data-cy="accounting-month-explain-modal" background={cssVar('--primaryGray')}>
        {!data || isLoading ? (
          <Centerer height="100%">
            <SizedLoader size={40} />
          </Centerer>
        ) : (
          <>
            <AccountingMonthModalHeader
              month={month}
              revenueType={revenueType}
              onClose={onClose}
              customerName={data.customerName}
              setCustomerId={setCustomerId}
              setMonth={setMonth}
            />
            <AccountingMonthModalBody
              data={data}
              customerId={customerId}
              setCustomerId={setCustomerId}
              accountingSpreadPolicy={accountingSpreadPolicy}
              setTransactionToShow={setTransactionToShow}
              lastMonthDetailsPerCustomer={dataForMonth?.lastMonthDetailsPerCustomer}
              showCustomersWithDeferred={showCustomersWithDeferred}
              setShowCustomersWithDeferred={setShowCustomersWithDeferred}
              showCustomersWithAccrued={showCustomersWithAccrued}
              setShowCustomersWithAccrued={setShowCustomersWithAccrued}
              showCustomersWithAccountingRevenue={showCustomersWithAccountingRevenue}
              setShowCustomersWithAccountingRevenue={setShowCustomersWithAccountingRevenue}
              showCustomersWithInvoiced={showCustomersWithInvoiced}
              setShowCustomersWithInvoiced={setShowCustomersWithInvoiced}
              accountingCurrency={accountingCurrency}
            />
          </>
        )}
      </FullscreenModal>
    </ModalContainer>
  );
};
