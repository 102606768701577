import dayjs from 'dayjs';
import { INVOICE_STATUSES } from '../consts';
import { isInvoiceOverdue } from '../utils';

export const addRemindersInfoFieldsToInvoice = ({ invoice }) => {
  const sortedReminders = invoice.reminders?.sort(
    (a, b) => (new Date(a.scheduled_for)?.getTime() ?? 0) - (new Date(b.scheduled_for)?.getTime() ?? 0),
  );
  const sentReminders = [...(sortedReminders ?? [])]?.filter(({ sent_at }) => !!sent_at);

  const furtureRemindersToSend = sortedReminders?.filter(
    ({ sent_at, scheduled_for, dismissed_at }) =>
      !sent_at && !dismissed_at && !dayjs.utc(scheduled_for).isBefore(dayjs.utc(), 'day'),
  );

  return {
    ...invoice,
    has_unsent_reminders: !!invoice.reminders?.some((reminder) => !reminder.sent_at),
    reminder_date: furtureRemindersToSend?.[0]?.scheduled_for,
    next_reminder_id: furtureRemindersToSend?.[0]?.id,
    last_communication_count: sentReminders?.length,
    last_communication: sentReminders?.[sentReminders.length - 1]?.sent_at,
    days_overdue: isInvoiceOverdue({ invoice })
      ? dayjs.utc().diff(dayjs.utc(invoice?.date).add(invoice?.days_to_pay, 'day'), 'day')
      : 0,
  };
};

export const groupInvoicesByMonth = ({ invoices, dateKey }) => {
  const groupBy = dateKey ?? 'date';

  const invoicesByMonth = invoices.reduce((acc, curr, index) => {
    const month = dayjs.utc(curr[groupBy]).format('YYYY-MM');
    if (!acc[month]) {
      acc[month] = {
        invoices: [],
        originalOrder: index,
      };
    }
    acc[month].invoices.push({
      ...addRemindersInfoFieldsToInvoice({ invoice: curr }),
      originalIndex: index,
    });
    return acc;
  }, {});

  return Object.entries(invoicesByMonth)
    .sort(([, a], [, b]) => a?.originalOrder - b?.originalOrder)
    .map(([month, group]) => ({
      month,
      subRows: group?.invoices?.sort((a, b) => a?.originalIndex - b?.originalIndex),
      isOverdueGroup: group?.invoices?.some((invoice) => isInvoiceOverdue({ invoice })),
      totalAmount: group?.invoices?.reduce((acc, curr) => acc + curr?.amount, 0),
    }));
};

export const groupInvoicesBySchedule = ({ invoices }) => {
  const invoicesBySchedule = invoices.reduce((acc, curr, index) => {
    const groupBy = curr.invoicing_schedule_id;
    if (!acc[groupBy]) {
      acc[groupBy] = {
        invoices: [],
        originalOrder: index,
      };
    }
    acc[groupBy].invoices.push({
      ...addRemindersInfoFieldsToInvoice({ invoice: curr }),
      originalIndex: index,
    });
    return acc;
  }, {});

  return Object.entries(invoicesBySchedule)
    .sort(([, a], [, b]) => a?.originalOrder - b?.originalOrder)
    .map(([, group]) => ({
      customerName: group?.invoices?.[0]?.customer_name,
      internalNotes: group?.invoices?.[0]?.invoicing_schedule_internal_notes,
      subRows: group?.invoices?.sort((a, b) => a?.originalIndex - b?.originalIndex),
      isOverdueGroup: group?.invoices?.some((invoice) => isInvoiceOverdue({ invoice })),
      totalAmount: group?.invoices?.reduce((acc, curr) => acc + curr?.amount, 0),
    }));
};

export const getInvoiceStatusForTableTag = ({ invoice }) =>
  invoice?.invoice_status === INVOICE_STATUSES.SENT
    ? isInvoiceOverdue({ invoice })
      ? 'OVERDUE'
      : INVOICE_STATUSES.SENT
    : invoice?.invoice_status;

export const convertDoDateToLabel = ({ doDate }) => {
  const today = dayjs.utc();
  const tomorrow = today.add(1, 'day');

  if (dayjs.utc(doDate).isSameOrBefore(today, 'day')) {
    return 'Today';
  } else if (dayjs.utc(doDate).isSame(tomorrow, 'day')) {
    return 'Tomorrow';
  } else {
    return dayjs.utc(doDate).format('MMM D, YYYY');
  }
};
