import React from 'react';
import { ReactComponent as PrimaryInfoIcon } from 'images/info-icon.svg';
import { ReactComponent as SecondaryInfoIcon } from 'images/info-circle-new.svg';
import { ReactComponent as TertiaryInfoIcon } from 'images/black-info-circle.svg';
import { ReactComponent as CloseIcon } from 'images/close.svg';
import { InfoContainer, InfoIconWrapper, InfoContent, CloseIconWrapper } from './styles';

const INFO_ICONS = {
  primary: PrimaryInfoIcon,
  secondary: SecondaryInfoIcon,
  tertiary: TertiaryInfoIcon,
};

const InfoBar = ({ height, variant = 'primary', children, styles, onDismiss }) => {
  const InfoIcon = INFO_ICONS[variant];

  return (
    <InfoContainer height={height} style={styles} variant={variant}>
      <InfoIconWrapper variant={variant}>
        <InfoIcon />
      </InfoIconWrapper>
      <InfoContent variant={variant}>{children}</InfoContent>
      {onDismiss && (
        <CloseIconWrapper>
          <CloseIcon onClick={onDismiss} />
        </CloseIconWrapper>
      )}
    </InfoContainer>
  );
};

export default InfoBar;
