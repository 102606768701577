import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import { FieldArray, Formik } from 'formik';
import dayjs from 'dayjs';

import { useCustomersAPI } from 'api/customers';
import { getEditableMetadata } from 'models/customer';
import { ReactComponent as MetadataPlus } from 'images/metadata-plus.svg';
import { MetadataSectionActionsModal, METADATA_TYPES } from 'shared/Common';
import { MetadataItemFormikInput, NoMetadata, useCombinedMetadata } from 'shared/Common/MetadataSection';
import { METADATA_FILTER_TYPES } from 'shared/Filters/MetadataFilter';
import { Loader } from 'components/Loaders';
import { CancelButton } from 'components/Buttons';
import { METADATA_DATA_TYPES } from 'shared/Common/MetadataSection/consts';
import { FloatingActionsContainer } from 'components/FloatingActionsContainer';

import { GreyBox, GreyBoxTitle, GreyBoxContent, GreyBoxActionButton } from '../styles';

const ItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const CustomerMetadataWrapper = styled(GreyBox)`
  min-width: 260px;
  max-width: 320px;
  overflow: auto;
  min-height: 100%;
  max-height: 100%;
`;

const SaveButton = styled(CancelButton)`
  background: var(--primaryGreen);
  border: 1px solid var(--primaryGreen);
  &:hover {
    background: var(--lightGreen);
    border: 1px solid var(--lightGreen);
  }
`;

const LoadingMetadataText = styled.span`
  color: var(--primaryDark);
  opacity: 0.5;
  font-style: italic;
  font-size: 0.8rem;
  margin-top: 5px;
  display: inline-block;
`;

const LoadingMetadataWrapper = styled.div`
  flex-direction: column;
  margin: auto;
`;

const CustomGreyBoxContent = styled(GreyBoxContent)`
  padding: 15px;
  max-height: none;
  height: 100%;
`;

const CustomerMetadata = ({ customer, organizationId, metadataType = METADATA_TYPES.CUSTOMERS }) => {
  const { id, name, parent_customer_id } = customer;
  const metadata = getEditableMetadata({ customer }) ?? {};

  const [actionMetadataItem, setActionMetadataItem] = useState(null);
  const [modalAction, setModalAction] = useState(null);
  const [updatedValues, setUpdatedValues] = useState({});

  const { combinedMetadataOptions, isLoading } = useCombinedMetadata({
    orgId: organizationId,
    metadataFilterType: METADATA_FILTER_TYPES.CUSTOMERS,
    currentValues: { ...updatedValues, ...metadata },
  });

  const metadataCount = combinedMetadataOptions.length;

  const {
    operations: { editCustomer },
  } = useCustomersAPI({ orgId: organizationId, autoFetch: false });

  const initialValues = {
    metadataItems: combinedMetadataOptions ?? [],
  };

  const handleMetadataEdit = async (metadata) => {
    await editCustomer({
      id,
      data: {
        name,
        parent_customer_id,
        metadata,
      },
    });
  };

  return (
    <CustomerMetadataWrapper>
      {isLoading ? (
        <LoadingMetadataWrapper className="w-100 flexer">
          <Loader containerStyles={{ width: 40 }} />
          <LoadingMetadataText>Fetching metadata...</LoadingMetadataText>
        </LoadingMetadataWrapper>
      ) : (
        <>
          <GreyBoxTitle>
            <span
              style={{
                fontSize: '16px',
              }}
            >
              Metadata ({metadataCount})
            </span>
            <GreyBoxActionButton
              data-cy="metadata-add-button"
              onClick={() => {
                setActionMetadataItem(null);
                setModalAction('create');
              }}
            >
              <MetadataPlus />
            </GreyBoxActionButton>
          </GreyBoxTitle>
          <Formik enableReinitialize initialValues={initialValues}>
            {({ values, resetForm }) => {
              return (
                <Fragment key={JSON.stringify(values.metadataItems)}>
                  <CustomGreyBoxContent>
                    {values.metadataItems.length === 0 ? (
                      <NoMetadata handleAddMetadata={() => setModalAction('create')} />
                    ) : (
                      <FieldArray name="metadataItems">
                        {() => (
                          <ItemsWrapper>
                            {values.metadataItems.map((item, index) => (
                              <MetadataItemFormikInput
                                key={item.key}
                                name={`metadataItems.${index}.value`}
                                metadataType={metadataType}
                                organizationId={organizationId}
                                metadataDataType={item.dataType}
                                item={item}
                                onDataChange={(newValue) =>
                                  setUpdatedValues((prev) => ({
                                    ...prev,
                                    [item.key]: newValue,
                                  }))
                                }
                              />
                            ))}
                          </ItemsWrapper>
                        )}
                      </FieldArray>
                    )}
                  </CustomGreyBoxContent>
                  {modalAction && (
                    <MetadataSectionActionsModal
                      metadataType={metadataType}
                      organizationId={organizationId}
                      closeModal={(metadataItem) => {
                        setModalAction(null);
                        if (!metadataItem) return;

                        let data;
                        if (actionMetadataItem) {
                          data = values.metadataItems.map((item) => {
                            if (item.key === actionMetadataItem.key) {
                              item.value = metadataItem.value;
                              item.key = metadataItem.key;
                            }
                            return item;
                          });
                        } else {
                          data = [...values.metadataItems, metadataItem];
                        }

                        handleMetadataEdit(
                          data.reduce((acc, value) => {
                            acc[value.key] = value.value;
                            return acc;
                          }, {}),
                        );
                      }}
                      modalAction={modalAction}
                      actionMetadataItem={actionMetadataItem}
                    />
                  )}
                  <FloatingActionsContainer
                    showFloater={values.metadataItems.some((newMetadata) => {
                      const previous = metadata[newMetadata.key] ?? '';
                      const current = newMetadata.value ?? '';
                      const dataType = newMetadata.dataType;

                      return dataType === METADATA_DATA_TYPES.DATETIME
                        ? dayjs.utc(previous).format('MMM DD, YYYY') !== dayjs.utc(current).format('MMM DD, YYYY')
                        : previous !== current;
                    })}
                    left="calc(48%)"
                  >
                    <span
                      style={{
                        opacity: 0.8,
                      }}
                    >
                      You've changed metadata:
                    </span>
                    <SaveButton
                      onClick={() => {
                        handleMetadataEdit(
                          values.metadataItems.reduce(
                            (obj, item) => ({
                              ...obj,
                              [item.key]: item.value,
                            }),
                            {},
                          ),
                        );
                      }}
                    >
                      Save
                    </SaveButton>
                    <CancelButton
                      onClick={() => {
                        resetForm();
                        setUpdatedValues({ ...metadata });
                      }}
                    >
                      Cancel
                    </CancelButton>
                  </FloatingActionsContainer>
                </Fragment>
              );
            }}
          </Formik>
        </>
      )}
    </CustomerMetadataWrapper>
  );
};

export { CustomerMetadata };
