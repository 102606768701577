import styled from 'styled-components';
import { Row } from 'components/Core';

export const DynamicFields = styled.div`
  padding: 12px;
  margin-top: ${({ marginTop }) => marginTop ?? '8px'};
  background: var(--primaryBlack2);
  border-radius: 8px;
  margin-bottom: ${({ marginBottom }) => marginBottom ?? '28px'};
`;

export const DynamicFieldsHeader = styled.div`
  margin-top: 24px;
  color: black;
`;

export const DynamicFieldsTitle = styled.div`
  font-weight: 700;
  font-size: 12px;
  margin-bottom: 12px;
  line-height: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    opacity: 0.5;
  }
`;

export const DynamicFieldsTags = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
`;

export const DynamicTag = styled.div`
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  background: var(--primaryBlack10);
  padding: 1px 4px;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background: var(--dark7);
    color: white;
  }
`;

export const CopyInfo = styled(Row)`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--primaryBlack50);

  svg {
    margin-left: 4px;
  }
`;
