import styled from 'styled-components';

export const UnsavedText = styled.div`
  width: 86px;

  font-weight: normal;
  font-size: 11px;
  line-height: 14px;
  color: var(--primaryBlack);
  opacity: 0.5;
  margin: 0 7px;
`;
