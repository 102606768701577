import styled from 'styled-components';
import { IconButton } from 'components/Buttons';
import { Centerer, FlexerColumn } from 'components/Core';

export const SectionContainer = styled.div`
  box-shadow: 10px 10px 60px var(--primaryBlack10);
  border-radius: 20px;
  padding: 20px;
  width: 100%;
  overflow: hidden;
`;

export const GeneralSettingTabContainer = styled(FlexerColumn)`
  margin-top: 40px;
  padding: 0px 18px;
  justify-content: center;
  align-items: center;
`;

export const ConnectionEstablished = styled.div`
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: 20px;
`;

export const ConnectionContainer = styled(Centerer)`
  justify-content: space-between;
  height: 40px;
  padding: 12px;
  align-items: center;
  gap: 8px;
  border-radius: 100px;
  border: 1px solid var(--neutralGray);
  box-shadow: 2px 2px 12px 0px var(--primaryBlack2);
`;

export const IntegrationButtonsContainer = styled.div`
  display: flex;
  margin-bottom: 14px;
  margin-top: 20px;
  justify-content: space-between;
`;

export const TestConnectionBtn = styled.button`
  background: transparent;
  cursor: pointer;
  outline: none;
  border: solid 2px var(--primaryGreen);
  padding: 7px 12px;
  color: var(--primaryGreen);
  font-weight: 900;
  font-size: 10px;
  line-height: 14px;
  border-radius: 60px;
  pointer-events: ${(props) => props.unactive && 'none'};
  opacity: ${(props) => props.unactive && '0.3'};
`;

export const SyncCard = styled.div`
  background: rgba(0, 21, 46, 0.03);
  border: 1px solid var(--primaryBlack5);
  padding: 22px;
  display: flex;
  border-radius: 12px;
  margin-top: 22px;

  .cardImage {
    display: block;
    padding-right: 30px;
    border-right: 1px solid var(--primaryBlack5);
    margin-right: 30px;
  }
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  h5 {
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 8px;
  }

  span {
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 14px;
  }

  svg {
    width: 14px;
    margin-right: 8px;

    path {
      fill: white;
    }
  }
`;

export const ProductCard = styled.div`
  background: rgba(57, 161, 220, 0.05);
  border: 1px solid rgba(57, 161, 220, 0.1);
  border-radius: 8px;
  padding: 14px;
  margin-right: 20px;
  margin-bottom: 20px;
  display: flex;
  width: ${(props) => (props.integrationView ? 'auto' : '23%')};
  max-height: ${(props) => (props.integrationView ? '120px' : '98px')};

  &:last-child {
    margin-right: 0;
  }

  p {
    margin-bottom: 6px;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: rgba(0, 21, 46, 0.5);

    span {
      margin-left: 6px;
      color: var(--primaryBlack);
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const ProductCardHeading = styled.h4`
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: var(--primaryBlue);
`;

export const ProductCardIconBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: auto;
  padding-left: 14px;
  border-left: 1px solid rgba(57, 161, 220, 0.1);

  svg {
    opacity: 0.5;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
  }
`;

export const ProductCardContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const SaveConfigurationBtn = styled.div`
  cursor: pointer;
  background-color: var(--primaryGreen);
  padding: 11px 14px;
  display: flex;
  align-items: center;
  color: white;
  border-radius: 20px;

  span {
    margin-left: 7px;
  }
`;

export const GeneralLedgerHeaderCell = styled.td`
  padding: 10px;
  background: #f7f7f7;
  color: var(--primaryBlack);
  font-weight: 400;
  font-size: 12px;
  border: ${(props) => (props.widthProp ? props.widthProp : '230px')};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Card = styled.div`
  width: 770px;
  padding: ${({ padding }) => padding ?? '28px'};
  background: #ffffff;
  margin: 20px 0;

  box-shadow: 8px 8px 60px rgba(0, 21, 46, 0.05);
  border-radius: 20px;
`;

export const StyledIconButton = styled(IconButton)`
  position: sticky;
  bottom: 10px;
  z-index: 20;
  width: fit-content;
  margin: 0 auto;
  margin-top: 20px;
`;

export const StyledHeaderIconButton = styled(IconButton)`
  position: absolute;
  top: 20px;
  right: 40px;
  width: fit-content;
`;

export const BodyContainer = styled.div`
  width: 970px;
  margin: 0 auto;
  padding-bottom: 30px;
`;

export const IntegrationAdditionalDetails = styled.div`
  padding: 16px;
`;

export const ConfigError = styled.div`
  margin: 24px 100px;
  padding: 16px;
  color: var(--secondaryRed);
  background-color: var(--primaryRed5);
  border-radius: 16px;
`;

export const Divider = styled.hr`
  border: none;
  height: 1px;
  margin: 12px 0;
  background-color: var(--accentGraySecond);
`;

export const CheckboxDescription = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: var(--primaryBlack50);
  margin-bottom: 16px;
  margin-left: 20px;
`;

export const AddButton = styled(Centerer)`
  cursor: pointer;
  pointer-events: ${(props) => props.disabled && 'none'};
  opacity: ${(props) => props.disabled && '0.3'};
  text-transform: uppercase;
  padding: 8px;
  border-radius: 8px;
  font-size: 10px;
  font-style: normal;
  font-weight: 900;
  line-height: 12px;
  text-transform: uppercase;
  background-color: var(--primaryBlue10);
  color: var(--primaryBlue);
`;

export const Number = styled(Centerer)`
  border-radius: 8px;
  border: 1px solid var(--primaryBlack5);
  font-size: 10px;
  font-weight: 900;
  color: var(--primaryBlack50);
  width: 24px;
  height: 24px;
`;

export const ItemName = styled.div`
  flex: ${({ flex }) => flex};
  width: ${({ width }) => width};
  cursor: ${({ cursor }) => cursor ?? 'default'};
  color: ${({ color }) => color ?? 'var(--primaryBlack)'};
  font-size: 12px;
  line-height: 16px;
  font-weight: ${({ fontWeight }) => fontWeight ?? '400'};
`;
