import React, { useContext, useState } from 'react';
import { AppContext } from 'AppContext';
import styled from 'styled-components';
import { useConfigAPI } from 'api/configs';
import { ReactComponent as CheckIcon } from 'images/circle-check-white.svg';
import { ReactComponent as InfoCircle } from 'images/black-info-circle.svg';
import { ReactComponent as RemoveIcon } from 'images/circle-x.svg';
import { Modal, ModalBody, ModalCloseIcon, ModalContainer, ModalHeader } from 'components/Modal';
import { CancelButton } from 'components/Buttons';
import { CustomDatePicker } from 'components/Controls';
import { Row } from 'components/Core';
import { ORG_CONFIGS } from 'consts/global';
import { formatDateForDatepicker, updateDateFromDatePicker } from 'utils/dateUtils';
import { FooterButton } from 'shared/TransactionContent/TransactionFooter/TransactionFormActions.styles';
import { ButtonsRow } from '../styles';

const BodyContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 32px;
`;

const Title = styled.div`
  font-weight: 900;
  font-size: 20px;
  line-height: 26px;
  margin-bottom: 16px;
`;

const DisableButton = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: var(--primaryRed);
  cursor: pointer;

  &:hover {
    color: var(--secondaryRed);
  }

  svg {
    margin-right: 8px;
  }
`;

const Subtitle = styled.div`
  color: var(--primaryBlack50);
  font-size: 12px;
  display: flex;
  line-height: 16px;
  margin-bottom: 24px;

  svg {
    opacity: 0.3;
    margin-right: 8px;
  }

  span {
    max-width: 340px;
  }
`;

export const LockOldTransactionsModal = ({ hideModal }) => {
  const { organizations, orgConfigs } = useContext(AppContext);
  const { transactionsLockBeforeDate } = orgConfigs;

  const [draftLockDate, setDraftLockDate] = useState(transactionsLockBeforeDate);

  const orgId = organizations?.[0].id;

  const { updateConfig } = useConfigAPI({ orgId });

  const handleSubmit = () => {
    updateConfig({
      key: ORG_CONFIGS.TRANSACTIONS_LOCK_BEFORE_DATE,
      value: draftLockDate,
    });

    hideModal();
  };

  return (
    <ModalContainer>
      <Modal padding="4px 4px" maxWidth="440px" height="auto" overflow="hidden">
        <ModalHeader>
          <ModalCloseIcon
            data-cy="auto-confirmation-explain-modal__close-button"
            style={{ width: 32, height: 32 }}
            onClose={() => hideModal()}
          />
        </ModalHeader>
        <ModalBody paddingLeft={0} paddingRight={0}>
          <BodyContent>
            <Title>
              <b>Transactions Locking</b>
            </Title>

            <Subtitle>
              <InfoCircle width={16} height={16} />

              <span>
                Locking prevents all users from making any changes to confirmed transactions. Subscript will compare end
                dates of deals with the lock date.
              </span>
            </Subtitle>

            <CustomDatePicker
              label="Stop all users making changes on and before"
              isClearable={true}
              popperPlacement="bottom"
              selected={draftLockDate ? formatDateForDatepicker(draftLockDate) : null}
              onChange={(date) => setDraftLockDate(updateDateFromDatePicker(date))}
              name="transactions_lock_before"
            />
          </BodyContent>

          <ButtonsRow style={{ marginTop: 0 }}>
            <Row>
              {transactionsLockBeforeDate && draftLockDate ? (
                <DisableButton onClick={() => setDraftLockDate(null)}>
                  <RemoveIcon width={20} height={20} />
                  Disable locking
                </DisableButton>
              ) : null}
            </Row>

            <Row horizontal="flex-end">
              <CancelButton data-cy="lock-modal__cancel-button" onClick={() => hideModal()}>
                Cancel
              </CancelButton>
              <FooterButton data-cy="lock-modal__save-button" save onClick={handleSubmit}>
                {transactionsLockBeforeDate ? 'Save' : 'Activate'}
                <CheckIcon style={{ marginLeft: 8 }} />
              </FooterButton>
            </Row>
          </ButtonsRow>
        </ModalBody>
      </Modal>
    </ModalContainer>
  );
};
