import NewIcon from 'images/fire.svg';
import UpsellIcon from 'images/upsell.svg';
import ExistingIcon from 'images/circuit.svg';
import DownsellIcon from 'images/downsell.svg';
import ChurnIcon from 'images/churn.svg';
import InfluxIcon from 'images/influx.svg';
import NonRecurringIcon from 'images/green-money-coin.svg';

export const REVENUE_METRIC = {
  EXISTING: {
    key: 'Existing',
    label: 'Existing',
    icon: ExistingIcon,
  },
  UPSELL: {
    key: 'Upsell',
    label: 'Upsell',
    icon: UpsellIcon,
  },
  NEW: {
    key: 'New',
    label: 'New',
    icon: NewIcon,
  },
  DOWNSELL: {
    key: 'Downsell',
    label: 'Downsell',
    icon: DownsellIcon,
  },
  CHURN: {
    key: 'Churn',
    label: 'Churn',
    icon: ChurnIcon,
  },
  INFLUX: {
    key: 'Influx',
    label: 'In Flux',
    icon: InfluxIcon,
  },
  INFLUX_MOVEMENT: {
    key: 'InfluxMovement',
    label: 'In Flux movement',
    icon: InfluxIcon,
  },
  NON_RECURRING: {
    key: 'Non-Rec. Rev.',
    label: 'Non-Rec. Rev.',
    icon: NonRecurringIcon,
  },
  TOTAL: {
    key: 'Total',
    label: 'Total',
  },
};
