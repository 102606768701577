import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { Spin } from 'antd';
import { useFormikContext } from 'formik';

import { AppContext } from 'AppContext';
import { CentererVertical, FlexBetweenContainer, FlexerColumn } from 'components/Core';
import { RowActionPopover } from 'components/Table';
import { FormikCustomCheckbox } from 'components/Controls';
import { useInvoiceMemoTemplatesAPI } from 'api/invoiceMemoTemplates/hooks';

import { MemoTemplatesActionsModal } from './MemoTemplatesActionsModal';
import { AddButton, Number } from '../../styles';

const Title = styled.span`
  font-size: 16px;
  font-weight: 700;
`;

const TemplateName = styled(CentererVertical)`
  width: 80%;
  font-size: 12px;
  font-weight: 700;
  color: var(--primaryBlue);
  cursor: pointer;

  &:hover {
    color: var(--secondaryBlue);
  }
`;

export const MemoTemplatesTable = ({
  generalTags,
  transactionMetadataTags,
  customerMetadataTags,
  transactionCustomerMetadataTags,
}) => {
  const { orgId } = useContext(AppContext);
  const [showModal, setShowModal] = useState(false);
  const [invoiceMemoTemplateId, setInvoiceMemoTemplateId] = useState(null);

  const { data: templates, isLoading } = useInvoiceMemoTemplatesAPI({ orgId });

  const { values, setFieldValue } = useFormikContext();

  return (
    <>
      <FlexerColumn gap="16px">
        <FlexBetweenContainer centerer marginBottom="16px">
          <Title>Manage memo templates</Title>
          <AddButton
            onClick={() => {
              setInvoiceMemoTemplateId(null);
              setShowModal(true);
            }}
            data-cy="billing__tab--add-memo-button"
          >
            Add Memo Template
          </AddButton>
        </FlexBetweenContainer>
        {isLoading ? (
          <Spin />
        ) : (
          <FlexerColumn gap="8px">
            {templates.map((template, index) => (
              <FlexBetweenContainer key={template.id}>
                <Number>{index + 1}</Number>
                <TemplateName
                  onClick={() => {
                    setInvoiceMemoTemplateId(template.id);
                    setShowModal(true);
                  }}
                >
                  {template.name}
                </TemplateName>
                <RowActionPopover
                  dataCyPrefix={template.name}
                  onEditClick={() => {
                    setInvoiceMemoTemplateId(template.id);
                    setShowModal(true);
                  }}
                />
              </FlexBetweenContainer>
            ))}
          </FlexerColumn>
        )}
        <FormikCustomCheckbox
          name="billingSenderDefaults.hide_subscript_footer"
          label={
            <span
              style={{
                wordSpacing: '1.5px',
              }}
            >
              Show{' '}
              <i>
                <b>Powered by Subscript</b>
              </i>{' '}
              in emails
            </span>
          }
          checked={!values.billingSenderDefaults?.hide_subscript_footer}
          onChange={(e) => {
            const value = e.target.checked;
            setFieldValue('billingSenderDefaults.hide_subscript_footer', !value);
          }}
        />
      </FlexerColumn>
      {showModal && (
        <MemoTemplatesActionsModal
          invoiceMemoTemplateId={invoiceMemoTemplateId}
          onClose={() => {
            setShowModal(false);
            setInvoiceMemoTemplateId(null);
          }}
          generalTags={generalTags}
          transactionMetadataTags={transactionMetadataTags}
          customerMetadataTags={customerMetadataTags}
          transactionCustomerMetadataTags={transactionCustomerMetadataTags}
        />
      )}
    </>
  );
};
