import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { INTEGRATION_SERVICES } from 'consts/integrations';
import { useToasts, useUrlQuery } from 'utils/hooks';
import { FlexerColumn } from 'components/Core';
import { FormikCustomInput, FormikCustomRadio } from 'components/Controls';
import { TooltipContainer } from 'components/Tooltip';
import { FieldBody, FieldTitle } from 'views/Wizard/styles';
import { humanize } from 'utils/stringUtils';
import { MANUAL_GL_SERVICE_NAME } from 'views/Billing/consts';

import { SENDER_DEFAULT_EMAIL, SENDER_OPTIONS, SENDER_MAILER_OPTIONS } from './consts';
import { Divider, FormRow, Explainer } from '../styles';
import { EnableGoogleApiButton } from './GoogleAuth';

export const BillingSenderSettings = ({
  invoicingServiceDisplayName,
  invoicingServices,
  initialValues,
  values,
  setFieldValue,
  gmailIntegration,
}) => {
  const query = useUrlQuery();
  const { pushToast } = useToasts();
  const history = useHistory();
  const gauthStatus = query.get('gauth');

  useEffect(() => {
    if (gauthStatus) {
      if (gauthStatus === 'success') {
        pushToast('Successfully updated configurations!', 'success');
      } else if (gauthStatus === 'error') {
        pushToast(`Failed to update configurations.`, 'error');
      }

      query.delete('gauth');
      history.replace({
        search: query.toString(),
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const gmailMetadata = gmailIntegration?.metadata ?? {};
  const isGmail = values?.billingSenderDefaults?.sender_mailer === SENDER_MAILER_OPTIONS.GMAIL;
  const isGmailIntegrated = isGmail && !!gmailMetadata?.user_email;

  const hasXeroIntegrated = invoicingServices.includes(INTEGRATION_SERVICES.XERO);
  const hasOnlyXeroIntegrated = invoicingServices.every((service) => service === INTEGRATION_SERVICES.XERO);

  const noGLIntegration = invoicingServiceDisplayName === humanize(MANUAL_GL_SERVICE_NAME);
  const senderOptions = noGLIntegration
    ? []
    : [
        {
          label: `Create and send ${invoicingServiceDisplayName || 'your GLs'} invoice PDFs by using ${
            invoicingServiceDisplayName || 'your GLs'
          } email`,
          value: SENDER_OPTIONS.GL,
          explainer: (
            <Explainer>
              All actions are performed on your accounting system: generating invoice records and their PDF files,
              sending the invoice emails. Subscript will help you keep track of invoices with invoicing schedules.
            </Explainer>
          ),
        },
      ];
  senderOptions.push(
    // { label: 'Send Subscript Invoice PDFs using a custom email', value: SENDER_OPTIONS.CUSTOM_EMAIL, disabled: true }, // uncomment when this option is available
    {
      label: 'Create and send Subscript invoice PDFs by using your custom email',
      value: SENDER_OPTIONS.SUBSCRIPT_EMAIL,
      explainer: (
        <Explainer>
          Subscript will be responsible for all actions: creating the invoice PDF using your Subscript-configured
          template, and sending invoice emails. For accounting purposes, Subscript will store a copy of all sent
          invoices in your accounting system.
        </Explainer>
      ),
    },
  );

  return (
    <FieldBody data-cy="billing-configuration__email_sender">
      <FieldTitle>
        <span>Settings for creating and sending invoices</span>
      </FieldTitle>

      <FlexerColumn gap="24px">
        <FormRow childrenWidth="100%">
          <FormikCustomRadio
            name="billingSenderDefaults.sender"
            options={senderOptions}
            flexDirection="column"
            gap="16px"
            handleChange={(value) => {
              if (value === SENDER_OPTIONS.GL) {
                // when switching to GL set the sender_mailer to SUBSCRIPT and email_from to SENDER_DEFAULT_EMAIL (for things like reminders and receipts)
                setFieldValue('billingSenderDefaults.sender_mailer', SENDER_MAILER_OPTIONS.SUBSCRIPT);
                setFieldValue('billingSenderDefaults.email_from', SENDER_DEFAULT_EMAIL);
              } else if (value === SENDER_OPTIONS.SUBSCRIPT_EMAIL && !values.billingSenderDefaults.email_from) {
                // if the user select SUBSCRIPT_EMAIL, we make sure to set the default email to SENDER_DEFAULT_EMAIL
                setFieldValue('billingSenderDefaults.email_from', SENDER_DEFAULT_EMAIL);
              }
            }}
          />
        </FormRow>

        {values?.billingSenderDefaults.sender === SENDER_OPTIONS.SUBSCRIPT_EMAIL && (
          <>
            <Divider />
            <FlexerColumn gap="16px">
              <FieldTitle noMarginBottom>
                <span>Sender user (email) to indicate who emails should go out from</span>
              </FieldTitle>
              <FormRow childrenWidth="100%">
                <FormikCustomRadio
                  name="billingSenderDefaults.sender_mailer"
                  options={[
                    {
                      label: "Users' Google apps emails",
                      value: SENDER_MAILER_OPTIONS.GMAIL,
                    },
                    {
                      label: `Default email: ${SENDER_DEFAULT_EMAIL}`,
                      value: SENDER_MAILER_OPTIONS.SUBSCRIPT,
                    },
                  ]}
                  handleChange={(value) => {
                    // if the user selects SUBSCRIPT mailer, we make sure to set the default email to SENDER_DEFAULT_EMAIL
                    // and set email name and reply-to initial values
                    if (value === SENDER_MAILER_OPTIONS.SUBSCRIPT) {
                      setFieldValue('billingSenderDefaults.email_from', SENDER_DEFAULT_EMAIL);
                      setFieldValue(
                        'billingSenderDefaults.email_name',
                        initialValues.billingSenderDefaults?.email_name,
                      );
                      setFieldValue(
                        'billingSenderDefaults.email_reply_to',
                        initialValues.billingSenderDefaults?.email_reply_to,
                      );
                    }
                    // if the user selects GMAIL mailer, we make sure to set the email details to initial values
                    if (value === SENDER_MAILER_OPTIONS.GMAIL && gmailMetadata?.user_email) {
                      setFieldValue(
                        'billingSenderDefaults.email_from',
                        initialValues.billingSenderDefaults?.email_from,
                      );
                      setFieldValue(
                        'billingSenderDefaults.email_name',
                        initialValues.billingSenderDefaults?.email_name,
                      );
                      setFieldValue(
                        'billingSenderDefaults.email_reply_to',
                        initialValues.billingSenderDefaults?.email_reply_to,
                      );
                    }
                  }}
                />
              </FormRow>
            </FlexerColumn>
          </>
        )}

        {values?.billingSenderDefaults?.sender !== SENDER_OPTIONS.GL ? (
          <>
            {/* Show email, name and reply-to form when sender is not GL */}
            {(values?.billingSenderDefaults?.sender_mailer === SENDER_MAILER_OPTIONS.SUBSCRIPT ||
              isGmailIntegrated) && (
              <>
                <FormRow gap="16px" childrenWidth="100%">
                  <FormikCustomInput
                    name="billingSenderDefaults.email_from"
                    data-cy="billingSenderDefaults.email_from"
                    label="Email"
                    isDisabled={!isGmail}
                    tooltipInputDisplay={
                      isGmail
                        ? 'Please enter a valid email address. If using an alias, ensure it is set up in your Gmail settings under "Send mail as".'
                        : null
                    }
                  />

                  <FormikCustomInput
                    name="billingSenderDefaults.email_name"
                    data-cy="billingSenderDefaults.email_name"
                    label="Name"
                  />
                </FormRow>
                <FormRow childrenWidth="100%">
                  <FormikCustomInput
                    name="billingSenderDefaults.email_reply_to"
                    data-cy="billingSenderDefaults.email_reply_to"
                    label="Reply to"
                  />
                </FormRow>
              </>
            )}
            {isGmail && (
              <>
                {!isGmailIntegrated && (
                  <FormikCustomInput
                    name="billingSenderDefaults.email_reply_to"
                    data-cy="billingSenderDefaults.email_reply_to"
                    label="Reply to"
                  />
                )}
                <EnableGoogleApiButton isIntegrated={isGmailIntegrated} />
              </>
            )}
          </>
        ) : (
          <>
            {/* Show reply-to input when sender is GL */}
            <FormRow gap="16px" childrenWidth="100%">
              <TooltipContainer
                toolTipContent="Xero manages the reply-to setting for invoices sent from its platform. Please ensure the Reply-to email address is configured within Xero."
                width={250}
                fontSize="12px"
                isVisible={hasXeroIntegrated}
                hideArrow
              >
                <FormikCustomInput
                  name="billingSenderDefaults.email_reply_to"
                  data-cy="billingSenderDefaults.email_reply_to"
                  label="Reply to"
                  isDisabled={hasOnlyXeroIntegrated}
                />
              </TooltipContainer>
            </FormRow>
          </>
        )}
      </FlexerColumn>
    </FieldBody>
  );
};
