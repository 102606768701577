import { useState } from 'react';
import styled from 'styled-components';
import { useFormikContext } from 'formik';
import { InlineButton, Button } from 'components/Buttons';
import { FlexBetweenContainer, FlexEndContainer, Spacer } from 'components/Core';
import { FormikCustomCheckbox } from 'components/Controls';
import { CodeBlock } from 'components/Blocks';
import { ModalFooter } from 'components/Modal';
import { COLORS } from 'consts/colors';
import { CheckIcon } from './SyncedExportModal.styles';

const ActionsRow = styled(FlexBetweenContainer)`
  align-items: center;
  padding: 20px 36px ${({ showingCodeBlock }) => (showingCodeBlock ? '12px' : undefined)};
`;

const CodeBlockWrapper = styled.div`
  margin: 10px;
`;

const UseExistingFiltersCheckboxLabel = ({ isShowing, hasExistingFilters, onShowingToggle }) => (
  <div style={{ fontSize: 14 }}>
    Use my existing filters on the page
    {hasExistingFilters && (
      <>
        {' ('}
        <InlineButton
          onClick={onShowingToggle}
          isSecondary
          data-cy="synced-export-modal__use-existing-filters__show-toggle"
        >
          {isShowing ? 'hide' : 'show'}
        </InlineButton>
        {')'}
      </>
    )}
  </div>
);

export const SyncedExportModalFooter = ({ existingFilters, onClose }) => {
  const { submitForm } = useFormikContext();
  const [isShowing, setIsShowing] = useState(false);
  const hasExistingFilters = existingFilters && Object.keys(existingFilters).length > 0;

  return (
    <ModalFooter noFixedHeight noPadding>
      <ActionsRow showingCodeBlock={isShowing}>
        <FormikCustomCheckbox
          data-cy="synced-export-modal__use-existing-filters-checkbox"
          name="useExistingFilters"
          disabled={!hasExistingFilters}
          label={
            <UseExistingFiltersCheckboxLabel
              isShowing={isShowing}
              hasExistingFilters={hasExistingFilters}
              onShowingToggle={() => hasExistingFilters && setIsShowing(!isShowing)}
            />
          }
        />
        <FlexEndContainer>
          <Button border filled color={COLORS.GREY} onClick={onClose}>
            Cancel
          </Button>
          <Spacer width="14px" />
          <Button
            color={COLORS.GREEN}
            border
            filled
            active
            data-cy="synced-export-modal__submit-button"
            onClick={submitForm}
          >
            Export Data
            <CheckIcon />
          </Button>
        </FlexEndContainer>
      </ActionsRow>
      {isShowing && hasExistingFilters && (
        <CodeBlockWrapper>
          <CodeBlock label="Filters JSON codeblock" json={existingFilters} />
        </CodeBlockWrapper>
      )}
    </ModalFooter>
  );
};
