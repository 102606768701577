import styled from 'styled-components';
import { FlexerColumn } from 'components/Core';
import { TableWrapper, TableRow, TableCell } from 'components/Table/CompactNumericTable';

export const StyledTableWrapper = styled(TableWrapper)`
  flex: 1;
  overflow-x: noscroll;
`;

export const StyledTableRow = styled(TableRow)`
  height: 78px;
`;

export const StyledTableCell = styled(TableCell)`
  color: var(--primaryBlack70);
`;

export const FirstColumn = styled(FlexerColumn)`
  padding-left: 10px;
  padding-right: 18px;
`;

export const Subtext = styled.span`
  color: var(--primaryBlack30);
  font-size: 11px;
`;
