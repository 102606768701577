import styled from 'styled-components';

export const AccountsModal = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  background: #ffffff;
  box-shadow: 0px 30px 120px rgba(0, 21, 46, 0.4);
  border-radius: 20px;
  padding: 24px;
  width: 280px;
`;

export const ButtonsRow = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

export const CancelButton = styled.div`
  padding: 11px 14px;
  font-size: 14px;
  line-height: 18px;
  background: var(--primaryBlack5);
  border: 1px solid var(--primaryBlack5);
  border-radius: 100px;
  margin-right: 10px;
  cursor: pointer;
`;

export const SubmitButton = styled.div`
  padding: 11px 14px;
  font-size: 14px;
  color: #ffffff;
  line-height: 18px;
  background: ${(props) => (props.isRemove ? 'var(--primaryRed)' : 'var(--primaryGreen)')};
  box-shadow: 0px 4px 20px rgba(82, 181, 120, 0.2);
  border-radius: 100px;
  border: none;
  cursor: pointer;
`;

export const ModalTitle = styled.div`
  font-size: 20px;
  line-height: 26px;
  font-weight: 900;
  margin-bottom: 20px;
  text-align: center;
`;

export const StyledHeaderIcon = styled.div`
  position: absolute;
  transform: translate(-50%, 0);
  left: 50%;
  top: -20px;
`;

export const FieldsWrapper = styled.div`
  > *:first-child {
    margin-bottom: 20px;
  }
`;
