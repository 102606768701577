import React from 'react';
import dayjs from 'dayjs';
import { ReactComponent as ReportsCircle } from 'images/reports-circle.svg';
import { CentererVertical, FlexBetweenContainer } from 'components/Core';
import { REPORTS_COLOR, REPORTS_OPTIONS } from '../consts';
import {
  ArrowRightHover,
  ReportCardContents,
  ReportCardTitle,
  ReportCardType,
  ReportCardUpdatedAt,
  ReportCardWrapper,
} from './styles';

export const ReportsListCard = ({ report, onClick }) => (
  <ReportCardWrapper borderColor={REPORTS_COLOR[report.type]} onClick={() => onClick({ report })}>
    <ReportCardContents>
      <FlexBetweenContainer>
        <CentererVertical>
          <ReportsCircle width={8} height={8} fill={REPORTS_COLOR[report.type]} />
          <ReportCardType>{REPORTS_OPTIONS[report.type]}</ReportCardType>
        </CentererVertical>
        <ArrowRightHover />
      </FlexBetweenContainer>
      <ReportCardTitle>{report.title}</ReportCardTitle>
      <ReportCardUpdatedAt>Updated at {dayjs(report.updated_at).format('DD MMM YYYY')}</ReportCardUpdatedAt>
    </ReportCardContents>
  </ReportCardWrapper>
);
