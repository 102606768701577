import styled from 'styled-components';
import { Centerer, Divider, FlexerColumn } from 'components/Core';
import { CircleLoader } from 'components/Loaders';
import { PaymentDetailsTable } from './PaymentDetailsTable';

const Wrapper = styled(FlexerColumn)`
  background: var(--darkGreen);

  padding: 72px 135px;
  overflow-y: scroll;
`;

const Body = styled(FlexerColumn)`
  justify-content: flex-start;
  margin-top: 5px;
`;

const SubscriptionName = styled.div`
  color: #ffffff;
  font-weight: 900;
  font-size: 30px;
  line-height: 40px;
  margin-top: 15px;
`;

const StyledDivider = styled(Divider)`
  margin: 32px -135px;
  opacity: 0.2;
`;

export const PaymentDetailsPanel = ({
  invoicingSchedule,
  invoiceId,
  invoice,
  isLoading,
  organizationName,
  products,
  orgConfigs,
  translations,
  language,
}) => {
  const transactions = invoicingSchedule?.transactions ?? [];

  return (
    <Wrapper>
      {isLoading ? (
        <>
          <Centerer>
            <CircleLoader />
          </Centerer>
        </>
      ) : (
        <Body>
          <SubscriptionName>
            {translations?.['Bill from'] ?? 'Invoice from'} {organizationName}
          </SubscriptionName>
          <StyledDivider backgroundColor="#ffffff" />
          <PaymentDetailsTable
            transactions={transactions}
            invoices={invoicingSchedule?.invoices ?? []}
            currentInvoiceId={invoiceId}
            currentInvoiceObject={invoice}
            frequency={invoicingSchedule?.invoicing_frequency}
            products={products ?? []}
            orgConfigs={orgConfigs}
            translations={translations}
            language={language}
          />
        </Body>
      )}
    </Wrapper>
  );
};
