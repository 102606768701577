import styled from 'styled-components';
import { ReactComponent as HugeSuccessIcon } from 'images/success-icon-huge.svg';
import { Flexer, FlexerColumn } from 'components/Core';

const OuterWrapper = styled(Flexer)`
  align-items: center;
  justify-content: center;
  height: 75vh;
  width: 370px;
`;

const Wrapper = styled(FlexerColumn)`
  align-items: center;
  justify-content: center;
`;

const Title = styled.div`
  color: var(--primaryBlack);
  font-weight: 900;
  font-size: 24px;
  line-height: 38px;
  margin: 40px 0 12px;
  text-align: center;
`;

const Subtitle = styled.div`
  color: var(--primaryBlack);
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 34px;
  text-align: center;
`;

export const CheckoutSuccess = ({ autoChargingWithPaymentMethod, isCurrentInvoiceMarkedPaid, translations }) => (
  <OuterWrapper>
    <Wrapper>
      <HugeSuccessIcon />
      <Title>{translations?.['Success!'] ?? 'Success!'}</Title>
      <Subtitle>
        {autoChargingWithPaymentMethod ? (
          <div>
            <p>{translations?.['Thank you for subscribing!'] ?? 'Thank you for subscribing!'}</p>
            <p>
              {translations?.['We will charge the invoices on their respective dates.'] ??
                'We will charge the invoices on their respective dates.'}
            </p>
          </div>
        ) : isCurrentInvoiceMarkedPaid ? (
          <div>
            <p>{translations?.['Thank you for your payment!'] ?? 'Thank you for your payment!'}</p>
          </div>
        ) : (
          <div>
            <p>
              {translations?.['Thank you for completing the checkout form.'] ??
                'Thank you for completing the checkout form.'}
            </p>
            <p>
              {translations?.['We will be processing your invoice shortly!'] ??
                'We will be processing your invoice shortly!'}
            </p>
          </div>
        )}
      </Subtitle>
    </Wrapper>
  </OuterWrapper>
);
