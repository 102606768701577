import { SEGMENT_BY_OPTIONS, MAX_SEGMENTS_ALLOWED } from 'consts/global';

export const getSegmentOptions = ({ orgConfigs }) => {
  const { customerCustomFields, customCustomerMetadataSegments } = orgConfigs;

  const customersMetadataSegments =
    Object.keys(customerCustomFields).filter((key) => customerCustomFields[key].length <= MAX_SEGMENTS_ALLOWED) || {};

  const segmentedOptionsLabels = [];

  segmentedOptionsLabels.push(SEGMENT_BY_OPTIONS.PRODUCT);
  segmentedOptionsLabels.push('size');

  customersMetadataSegments.forEach((option) => {
    segmentedOptionsLabels.push(`${SEGMENT_BY_OPTIONS.CUSTOMER_METADATA}-${option}`);
  });

  customCustomerMetadataSegments.forEach((option) => {
    segmentedOptionsLabels.push(`${SEGMENT_BY_OPTIONS.CUSTOMER_METADATA_CUSTOM}-${option.key}`);
  });

  return segmentedOptionsLabels;
};
