import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Centerer } from 'components/Core';
import { ReportIcon } from 'components/Icons';

export const TagContainer = styled(Centerer)`
  border: 1px solid var(--neutralGray);
  color: var(--primaryBlack50);
  border-radius: 4px;
  padding: 4px;
  font-size: 9px;
  font-weight: 900;
  line-height: 12px;
  text-transform: uppercase;
  background: #fff;
  position: absolute;
  top: ${({ top }) => top ?? '0'};
  left: 0px;
`;

export const OverdueWarning = styled(Link)`
  padding: 4px 8px;
  border-radius: 100px;
  margin-left: 6px;
  background-color: var(--newRed10);
  font-size: 12px;
  font-style: italic;
  cursor: pointer;
  font-weight: 700;
  color: var(--primaryBlack);
  display: inline-block;
  line-height: 16px;
  width: auto !important;

  span {
    color: var(--tertiaryRed);
  }

  &:hover {
    color: var(--primaryBlack);
    background-color: var(--newRed20);
  }
`;

export const RelativeContainer = styled.div`
  position: relative;
  width: 100%;
  margin: 8px 0px 28px;
  height: 1px;
`;

export const InvoicesCounter = styled.div`
  font-weight: 900;
  font-size: 10px;
  line-height: 12px;
  padding: 4px 8px;
  color: ${({ yellow, red }) => (yellow ? 'var(--terciaryYellow)' : red ? 'var(--tertiaryRed)' : 'var(--dark7)')};
  background: ${({ yellow, red }) =>
    yellow ? 'var(--primaryYellow15)' : red ? 'var(--tertiaryRed5)' : 'var(--primaryBlack5)'};
  border-radius: 100px;
`;

export const MenuReportIcon = styled(ReportIcon)`
  width: 16px;
  height: 16px;

  path {
    fill: var(--primaryBlack50);
  }
`;
