import React, { useContext, useRef, useState } from 'react';
import styled from 'styled-components';

import { AppContext } from 'AppContext';
import { ReactComponent as UploadIcon } from 'images/upload-csv-icon.svg';
import { ReactComponent as UploadedIcon } from 'images/uploaded-csv-icon.svg';
import { useToasts } from 'components/Toasts';
import { FlexerColumn } from 'components/Core';

import { DEFAULT_MAX_FILE_SIZE, FILE_TYPES, FILE_TYPES_NAMES } from './consts';
import { Uploader, Zone, InfoText, SubInfo, InfoWrapper, RemoveButton } from './styles';
import { useFileUploader } from './useFileUploader';

const Label = styled.label`
  width: 100%;
  font-size: 12px;
  line-height: 16px;
  color: ${(props) => (props.isError ? 'var(--primaryRed)' : 'var(--primaryBlack)')};
  opacity: ${(props) => (props.isError ? '1' : ' 0.4')};
  pointer-events: ${(props) => props.disabled && 'none'};
  cursor: pointer;
`;

export const formatFileSize = (number) => {
  if (number < 1024) {
    return `${number} bytes`;
  } else if (number >= 1024 && number < 1048576) {
    return `${(number / 1024).toFixed(1)} kb`;
  } else if (number >= 1048576) {
    return `${(number / 1048576).toFixed(1)} mb`;
  }
};

export const FileUploader = ({
  initialUploadedFile,
  onFileUpload,
  fileTypes,
  // 5mb
  maxFileSize = DEFAULT_MAX_FILE_SIZE,
  smallView,
  infoText,
  isDisabled,
  subInfoText,
  showFileUploaded = true,
  privateBucket = false,
  label,
}) => {
  const { orgId } = useContext(AppContext);
  const { pushError } = useToasts();
  const inputRef = useRef();
  const [zoneHover, setZoneHover] = useState(false);
  const { loading, uploadedFile, uploadFile, removeFile } = useFileUploader({
    orgId,
    initialUploadedFile,
    onFileUpload,
    privateBucket,
  });

  const checkSizeAndSetFile = (file) => {
    if (!file) {
      return;
    }

    if (!fileTypes.includes(file.type)) {
      pushError(
        '',
        `File type is not supported. Supported file types are ${fileTypes
          ?.map((fileType) => FILE_TYPES_NAMES[fileType])
          .join(', ')}.`,
      );
    } else if (file.size <= maxFileSize) {
      uploadFile({ file });
    } else {
      pushError('', `File size is too big. Max file size is ${formatFileSize(maxFileSize)}.`);
    }
  };

  const handleUploadClick = () => {
    if (!loading) {
      // Reset the input value so that the user can upload the same file again
      if (!showFileUploaded && inputRef?.current?.value) {
        inputRef.current.value = null;
      }

      // Redirect the click event onto the hidden input element
      inputRef.current?.click();
    }
  };

  const handleFileInputSelect = (e) => {
    checkSizeAndSetFile(e?.target?.files[0]);
  };

  const handleFileDrop = (event) => {
    // For now we only support single file upload
    const item = event?.dataTransfer?.items?.[0];
    if (!item) {
      return;
    }
    if (item.kind === 'file') {
      checkSizeAndSetFile(item.getAsFile());
    }
  };

  const handleRemoveButton = (event) => {
    event.stopPropagation();
    removeFile();
    // Reset the input value so that the user can upload the same file again
    if (inputRef?.current?.value) {
      inputRef.current.value = null;
    }
  };

  return (
    <FlexerColumn gap="8px">
      {label && <Label>{label}</Label>}
      <Uploader
        isDisabled={isDisabled}
        onClick={handleUploadClick}
        zoneHover={zoneHover}
        isLoading={!!loading}
        onDragOver={(event) => {
          event.preventDefault();
          setZoneHover(true);
        }}
        onDragLeave={(event) => {
          event.preventDefault();
          setZoneHover(false);
        }}
        onDrop={(event) => {
          event.preventDefault();
          handleFileDrop(event);
        }}
      >
        <Zone smallView={smallView}>
          {loading ? (
            <>
              <UploadIcon />

              <InfoWrapper>
                <InfoText>
                  You're uploading file now.
                  <RemoveButton onClick={handleRemoveButton}>Cancel</RemoveButton>
                </InfoText>
                {uploadFile && (
                  <SubInfo>
                    {uploadedFile?.name} ({formatFileSize(uploadedFile?.size)})
                  </SubInfo>
                )}
              </InfoWrapper>
            </>
          ) : showFileUploaded && uploadedFile ? (
            <>
              {FILE_TYPES.IMAGES.includes(uploadedFile.type) ? (
                <img src={uploadedFile.data} alt="uploaded file" />
              ) : (
                <UploadedIcon />
              )}

              <InfoWrapper>
                <InfoText>
                  You've uploaded a file.
                  <RemoveButton onClick={handleRemoveButton}>Remove</RemoveButton>
                </InfoText>
                <SubInfo>
                  {uploadedFile.name} ({formatFileSize(uploadedFile.size)})
                </SubInfo>
              </InfoWrapper>
            </>
          ) : (
            <>
              <UploadIcon />

              <InfoWrapper>
                <InfoText>
                  {infoText ?? (
                    <>
                      Click or drag & drop for<span>uploading</span>
                    </>
                  )}
                </InfoText>
                <SubInfo>
                  {subInfoText ?? (
                    <>
                      {infoText ? <>click or drag&drop </> : null}(
                      {fileTypes?.map((fileType) => FILE_TYPES_NAMES[fileType]).join(', ')}, less{' '}
                      {formatFileSize(maxFileSize)})
                    </>
                  )}
                </SubInfo>
              </InfoWrapper>
            </>
          )}
        </Zone>

        <input
          type="file"
          ref={inputRef}
          onChange={handleFileInputSelect}
          style={{ display: 'none' }}
          accept={fileTypes?.join(', ')}
        />
      </Uploader>
    </FlexerColumn>
  );
};
