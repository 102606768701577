import React, { useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as LogoutIcon } from 'images/logout-icon.svg';
import { ReactComponent as ProfileAvatarIcon } from 'images/profile-avatar.svg';
import { PopoverItemsContainer, PopoverListItemFull, PopoverListItemTitleFull, usePortal } from 'components/Portal';
import { StyledPopoverLinkContents } from './PopoverLinkContents';
import { useCurrentUser, useLogout } from 'shared/Auth';
import { Centerer, Flexer } from 'components/Core';

const ProfileLinkWrapper = styled.div`
  position: relative;
  cursor: pointer;
`;

const StyledProfileAvatarIcon = styled(ProfileAvatarIcon)`
  outline: ${({ $active }) => $active && '2px solid #FFFFFF'};
  border-radius: ${({ $active }) => $active && '100px'};
  filter: ${({ $active }) => $active && 'drop-shadow(0px 4px 20px var(--primaryBlack10))'};
`;

const Title = styled.div`
  font-weight: ${({ $active }) => ($active ? '700' : '400')};
  font-size: 12px;
  line-height: 16px;
  color: var(--primaryBlack);
`;

const UserNameItem = styled(PopoverListItemFull)`
  cursor: auto;

  ${PopoverListItemTitleFull} {
    color: var(--primaryBlack50);
  }

  &:hover {
    background: #fff;

    ${PopoverListItemTitleFull} {
      color: var(--primaryBlack50);
    }
  }
`;

const StyledLogoutIcon = styled(LogoutIcon)`
  width: 16px;
  height: 16px;

  path {
    fill: var(--primaryRed);
  }
`;

export const ProfileLink = () => {
  const { name } = useCurrentUser();
  const { logout } = useLogout();
  const { Portal, isPortalVisible, togglePortal, triggerRef } = usePortal({
    XOffset: 94,
    YOffset: 100,
  });
  const [isHover, setIsHover] = useState(false);
  const toggleIsHover = () => setIsHover(!isHover);
  const isActive = isHover || isPortalVisible;

  return (
    <>
      <ProfileLinkWrapper
        ref={triggerRef}
        onClick={togglePortal}
        onMouseEnter={toggleIsHover}
        onMouseLeave={toggleIsHover}
      >
        <Centerer direction="column" gap="8px">
          <StyledProfileAvatarIcon $active={isActive} />
          <Title $active={isActive}>Profile</Title>
        </Centerer>
        {isPortalVisible && (
          <Portal>
            <StyledPopoverLinkContents>
              <PopoverItemsContainer>
                <UserNameItem>
                  <PopoverListItemTitleFull>{name}</PopoverListItemTitleFull>
                </UserNameItem>
                <PopoverListItemFull onClick={logout}>
                  <Flexer width="112px" justifyContent="space-between">
                    <PopoverListItemTitleFull>Log out</PopoverListItemTitleFull>
                    <StyledLogoutIcon />
                  </Flexer>
                </PopoverListItemFull>
              </PopoverItemsContainer>
            </StyledPopoverLinkContents>
          </Portal>
        )}
      </ProfileLinkWrapper>
    </>
  );
};
