import styled from 'styled-components';
// import { HeaderTitle } from 'shared/Layout';
import { EditCircleIcon } from 'components/Icons';
import { CentererVertical } from 'components/Core';

export const StyledEditCircleIcon = styled(EditCircleIcon)`
  display: none;
  cursor: pointer;
  width: 20px;
  height: 20px;

  &:hover {
    path {
      fill: var(--secondaryBlue);
    }
  }
`;

/*
  TODO [AT 2023-08-16] Add this back in. This seems to be invalid syntax currently.
  ${HeaderTitle} {
    cursor: pointer;
  }

  ${StyledEditCircleIcon} {
    display: block;
  }
*/
export const HeaderLeftWrapper = styled(CentererVertical)`
  &:hover {
  }
`;
