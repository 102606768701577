import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { BrowserRouter as Router } from 'react-router-dom';
import { FlagsProvider } from 'flagged';
import { QueryClient, QueryClientProvider } from 'react-query';

import 'antd/dist/antd.min.css';
import 'react-datepicker/dist/react-datepicker.css';
import './index.css';
import './global.css';

import { Auth0ProviderWithHistory } from './shared/Auth';
import { getFlags } from './utils/features';
import App from './App';
import reportWebVitals from './reportWebVitals';
const featureFlags = getFlags();

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  ignoreErrors: [/request failed with status code 4[0-9]{2}/i], // Ignore 40X errors from Axios
  environment: process.env.REACT_APP_ENVIRONMENT ?? process.env.NODE_ENV,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // Capture Session Replay 10% of all sessions in prod,
  // but 100% if error happens. Adjust if affects app performance
  replaysSessionSampleRate: process.env.REACT_APP_RUN_ENV === 'e2e-test' ? 0 : 1,
  replaysOnErrorSampleRate: process.env.REACT_APP_RUN_ENV === 'e2e-test' ? 0 : 1,
});

// react-query instantiating query client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 15, // 15 minutes
      refetchOnWindowFocus: false,
    },
  },
});

ReactDOM.render(
  <StrictMode>
    <FlagsProvider features={featureFlags}>
      <Router>
        <Auth0ProviderWithHistory>
          <QueryClientProvider client={queryClient}>
            <App />
          </QueryClientProvider>
        </Auth0ProviderWithHistory>
      </Router>
    </FlagsProvider>
  </StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
