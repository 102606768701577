import React, { useContext, useMemo } from 'react';
import styled from 'styled-components';
import { cssVar } from 'polished';
import { NUMBER_FORMATS } from 'consts/global';
import { SNAPSHOT_METRICS } from 'consts/snapshotMetrics';
import { Centerer, CentererVertical, FlexEndContainer } from 'components/Core/Flex';
import { TruncateStringWithTooltip } from 'components/Tooltip';
import { Loader } from 'components/Loaders';
import { ReactComponent as DownArrow } from 'images/small-fill-down-arrow.svg';
import { ReactComponent as UpArrow } from 'images/small-fill-up-arrow.svg';
import { reFormatDate } from 'utils/dateUtils';
import { numberFormatter } from 'utils/formatters';
import { useCurrencyNumberFormatter } from 'utils/hooks';
import { DefaultTable } from 'shared/DefaultTable/Table';
import { AppContext } from 'AppContext';

const PreviousValue = styled.span`
  margin-right: 8px;
  font-weight: bold;
`;

const PercentChange = styled(Centerer)`
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ color }) => color};
  font-size: 10px;
  font-weight: bold;
  padding: 2px 4px;
  border-radius: 4px;
  width: fit-content;

  svg {
    margin-left: 4px;
  }
`;

const GrowthIndicator = ({ metricChange, previousValue, dataType, currency }) => {
  let backgroundColor;
  let color;
  let icon;
  if (metricChange >= 0) {
    backgroundColor = cssVar('--accentGreen');
    color = cssVar('--primaryGreen');
    icon = <UpArrow fill="var(--primaryGreen)" />;
  } else {
    backgroundColor = cssVar('--accentRed');
    color = cssVar('--primaryRed');
    icon = <DownArrow fill="var(--primaryRed)" />;
  }

  const percentChange = metricChange / previousValue;

  const valueToShow =
    dataType === NUMBER_FORMATS.PERCENT
      ? `${Math.round(metricChange * 100)} POINTS`
      : numberFormatter({ type: NUMBER_FORMATS.PERCENT, rawValue: percentChange });

  return (
    <CentererVertical>
      {dataType !== NUMBER_FORMATS.PERCENT && (
        <PreviousValue>{numberFormatter({ type: dataType, rawValue: metricChange, currency })}</PreviousValue>
      )}
      <PercentChange backgroundColor={backgroundColor} color={color}>
        {valueToShow}
        {dataType !== NUMBER_FORMATS.PERCENT && icon}
      </PercentChange>
    </CentererVertical>
  );
};

export const ChartVisualizerTable = ({ selectedMetrics, dataForOverview, months }) => {
  const numberFormatter = useCurrencyNumberFormatter();
  const {
    appSettings: { currencyISOCode: currency },
  } = useContext(AppContext);

  const { columns, data } = useMemo(() => {
    if (Object.keys(dataForOverview).length === 0) return [];

    // builds columns for table
    const columns = [
      {
        Header: 'MONTH',
        accessor: 'month',
      },
    ];

    for (const metric of selectedMetrics) {
      const dataType = SNAPSHOT_METRICS[metric].dataType;
      const metricColumn = {
        Header: () => (
          <TruncateStringWithTooltip length={14} tooltipWidth={200}>
            <span data-cy={`chart-visualizer-table-column__${SNAPSHOT_METRICS[metric].label}`}>
              {SNAPSHOT_METRICS[metric].label}
            </span>
          </TruncateStringWithTooltip>
        ),
        accessor: `${metric}_currentValue`,
        Cell: ({ cell: { value } }) => (
          <FlexEndContainer>{numberFormatter({ type: dataType, rawValue: value })}</FlexEndContainer>
        ),
      };
      columns.push(metricColumn);
      const momColumn = {
        Header: 'MOM CHANGE',
        accessor: `${metric}_momChange`,
        Cell: ({ cell: { value }, row }) => (
          <FlexEndContainer>
            {GrowthIndicator({
              metricChange: value,
              previousValue: row.original[`${metric}_lastMonthValue`],
              dataType,
              currency,
            })}
          </FlexEndContainer>
        ),
      };
      columns.push(momColumn);
      const qoqColumn = {
        Header: 'QOQ CHANGE',
        accessor: `${metric}_qoqChange`,
        Cell: ({ cell: { value }, row }) => (
          <FlexEndContainer>
            {GrowthIndicator({
              metricChange: value,
              previousValue: row.original[`${metric}_lastQuarterValue`],
              dataType,
              currency,
            })}
          </FlexEndContainer>
        ),
      };
      columns.push(qoqColumn);
      const yoyColumn = {
        Header: 'YOY CHANGE',
        accessor: `${metric}_yoyChange`,
        Cell: ({ cell: { value }, row }) => (
          <FlexEndContainer>
            {GrowthIndicator({
              metricChange: value,
              previousValue: row.original[`${metric}_lastYearValue`],
              dataType,
              currency,
            })}
          </FlexEndContainer>
        ),
      };
      columns.push(yoyColumn);
    }

    // builds data for table
    const data = [];
    for (const month of months) {
      const fullMonth = reFormatDate(month, 'YYYY-MM', 'MMMM YYYY');
      const monthData = dataForOverview[month];
      let rowData = {
        month: fullMonth,
      };
      for (const metric of selectedMetrics) {
        const {
          currentValue,
          lastMonthValue,
          lastQuarterValue,
          lastYearValue,
          momChange,
          qoqChange,
          yoyChange,
        } = monthData[metric];
        rowData = {
          ...rowData,
          [`${metric}_currentValue`]: currentValue,
          [`${metric}_lastMonthValue`]: lastMonthValue,
          [`${metric}_lastQuarterValue`]: lastQuarterValue,
          [`${metric}_lastYearValue`]: lastYearValue,
          [`${metric}_momChange`]: momChange,
          [`${metric}_qoqChange`]: qoqChange,
          [`${metric}_yoyChange`]: yoyChange,
        };
      }
      data.push(rowData);
    }
    return {
      columns,
      data,
    };
  }, [dataForOverview, selectedMetrics, months, numberFormatter, currency]);

  return (
    <>
      {data ? (
        <DefaultTable
          columns={columns}
          tableStorageKey="chart-visualizer"
          pageView={false}
          noActionBar
          dataForTable={data}
        />
      ) : (
        <div className="w-100 flexer">
          <Loader containerStyles={{ width: 40 }} />
        </div>
      )}
    </>
  );
};
