import styled from 'styled-components';
import { Flexer, FlexerColumn } from 'components/Core';

export const SideBar = styled(Flexer)`
  flex-direction: column;
  align-items: flex-start;
  width: 354px;
  height: 100%;
  padding: 28px;
  gap: 20px;
  border-right: 1px solid var(--accentGraySecond);
  background-color: var(--primaryGray);

  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
`;

export const Title = styled.div`
  font-size: 20px;
  font-style: normal;
  font-weight: 900;
  line-height: 26px;
`;

export const Details = styled.div`
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  opacity: 0.5;
`;

export const AddIconButton = styled(Flexer)`
  width: 28px;
  height: 28px;
  padding: 4px;
  justify-content: center;
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  border-radius: 100px;
  background: var(--primaryBlue10);
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};

  svg {
    width: 16px;
    height: 16px;

    path {
      fill: var(--primaryBlue);
    }
  }
`;

export const FilesListSection = styled(FlexerColumn)`
  width: 100%;
  height: 100%;
  gap: 20px;
`;

export const EmptyStateText = styled.div`
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  opacity: 0.5;
`;

export const AttachmentItem = styled(Flexer)`
  position: relative;
  align-items: center;
  gap: 8px;
  padding: 12px;
  border-radius: 8px;
  background: ${({ selected }) => selected && 'var(--primaryBlue10)'};
  border: ${({ selected }) => `1px solid ${selected ? 'var(--primaryBlue10)' : 'var(--accentGraySecond)'}`};
  cursor: pointer;

  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;

  svg {
    width: 16px;
    height: 16px;
    path {
      fill: ${({ isDeletedActive }) => (isDeletedActive ? 'var(--primaryRed)' : 'var(--primaryBlack30)')};
    }

    &:hover {
      path {
        fill: var(--primaryRed);
      }
    }
  }

  &:hover {
    background: ${({ selected }) => !selected && 'var(--accentGraySecond)'};
    border: ${({ selected }) => !selected && '1px solid var(--accentGraySecond)'};
  }
`;

export const AttachmentItemDetails = styled(Flexer)`
  width: 100%;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  opacity: 0.5;
`;
