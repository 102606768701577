import { useState, useEffect } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useToasts } from 'components/Toasts';
import { useQueryPolling } from 'api/jobHooks.helper';
import { getRevenueSpreads, bulkCreateSpreads as _bulkCreateSpreads } from './requests';

export const CACHE_KEY = 'spreads';

export const useRevenueSpreadsAPI = ({ orgId, params, body, autoFetch = true, enableToasts = true }) => {
  const dataKey = [CACHE_KEY, orgId, params, body];
  const [shownCustomers, setShownCustomers] = useState([]);
  const [nrClientsToRender, setNrClientsToRender] = useState(100);

  const { pushToast: wrappedPushToast, pushError: wrappedPushError } = useToasts();
  const pushToast = (...args) => {
    if (enableToasts) wrappedPushToast(...args);
  };
  const pushError = (...args) => {
    if (enableToasts) wrappedPushError(...args);
  };
  const queryClient = useQueryClient();

  const { data, error, isLoading, isFetching } = useQueryPolling({
    key: dataKey,
    fetchFn: () => getRevenueSpreads({ orgId, params, body }),
    fetchWithJobIdFn: (jobId) => getRevenueSpreads({ orgId, params: { jobId } }),
    queryOptions: {
      enabled: autoFetch && !!orgId,
    },
    pollingErrorMessage: `Revenue spreads is taking too long to load for org ${orgId}`,
  });

  const refetch = () => queryClient.invalidateQueries(dataKey);

  useEffect(() => {
    setShownCustomers(getFirstNCustomers(data, nrClientsToRender));
  }, [data, nrClientsToRender]);

  const getFirstNCustomers = (spreadsData, n) => spreadsData?.customerOrder.slice(0, n) ?? [];

  const sortCustomers = ({ isDesc, sortMonth }) => {
    if (sortMonth) {
      const sortedData = [...data.customerOrder].sort((idA, idB) => {
        return data.revenueRowsByCustomer[idB][sortMonth] - data.revenueRowsByCustomer[idA][sortMonth];
      });

      const dataWithSort = { ...data, customerOrder: isDesc ? sortedData.reverse() : sortedData };
      setShownCustomers(getFirstNCustomers(dataWithSort, nrClientsToRender));
    } else {
      setShownCustomers(getFirstNCustomers(data, nrClientsToRender));
    }
  };

  const bulkCreateSpreads = useMutation(({ data }) => _bulkCreateSpreads({ orgId, data }), {
    onError: (err) => {
      pushError(err, 'Failed to bulk create spreads.');
    },

    onSuccess: () => {
      pushToast('Successfully bulk created spreads!', 'success');
    },

    onSettled: () => {
      queryClient.invalidateQueries(CACHE_KEY);
    },
  });

  const loadMoreCustomers = () => {
    setNrClientsToRender(nrClientsToRender + 100);
  };

  return {
    data,
    shownCustomers,
    error,
    isLoading,
    isFetching,
    operations: {
      refetch,
      sortCustomers,
      bulkCreateSpreads: bulkCreateSpreads.mutateAsync,
      loadMoreCustomers,
    },
  };
};
