import { useCallback, useContext } from 'react';
import { isEmpty, isEqual, isNil } from 'lodash';

import { InvoicingScheduleContext } from './InvoicingScheduleContext';

import { getInvoiceInitialValues, invoiceGenerateChanges } from '../InvoiceModal/utils';
import { INVOICE_ITEM_TYPE_TO_KEY } from '../consts';
import { INVOICING_SCHEDULE_TABS_PANEL_TABS } from './InvoicingScheduleTabsPanel';
import { validateInvoiceChanges } from './utils';

export const useSafelySwitchInvoice = (props) => {
  const {
    setSelectedInvoiceId,
    selectedInvoiceId,
    scheduleFormRef,
    invoiceFormValues,
    invoicingScheduleFormValues,
    fetchedSelectedInvoice,
    validateInvoiceFormAndExecuteAction,
    setSelectedTabsPanelTab,
    isInvoiceLoading,
    isInvoiceFetching,
  } = useContext(InvoicingScheduleContext);

  const afterActionFn = props?.afterActionFn;

  const checkAndSwitchInvoice = useCallback(
    ({ clickedInvoice }) => {
      const updateInvoice = async ({ invoice: propsInvoice, invoiceChanges, source, clickedInvoice }) => {
        const updatedChangedFields = validateInvoiceChanges({
          invoiceChanges,
          invoiceFormValues: propsInvoice,
          fetchedInvoice: source,
        });
        if (
          !propsInvoice?.unsavedId &&
          (!isEmpty(invoiceChanges) ||
            (!isNil(updatedChangedFields) &&
              !isNil(propsInvoice?.changedFieldsWithOldValues) &&
              !isEqual(updatedChangedFields, propsInvoice?.changedFieldsWithOldValues)))
        ) {
          await validateInvoiceFormAndExecuteAction({
            action: async () => {
              await scheduleFormRef?.current?.setFieldValue(
                'invoices',
                invoicingScheduleFormValues?.invoices?.map((invoice) => {
                  if ((invoice?.id ?? invoice?.unsavedId) === (propsInvoice?.id ?? propsInvoice?.unsavedId)) {
                    return {
                      ...invoice,
                      changedFieldsWithOldValues: updatedChangedFields,
                    };
                  }
                  return invoice;
                }),
              );

              (!!clickedInvoice?.id || !!clickedInvoice?.unsavedId) &&
                setSelectedInvoiceId(clickedInvoice?.id ?? clickedInvoice?.unsavedId);
            },
          });
        } else {
          (!!clickedInvoice?.id || !!clickedInvoice?.unsavedId) &&
            setSelectedInvoiceId(clickedInvoice?.id ?? clickedInvoice?.unsavedId);
        }

        if (afterActionFn) afterActionFn();
      };

      setSelectedTabsPanelTab(INVOICING_SCHEDULE_TABS_PANEL_TABS.INVOICES);

      if (
        selectedInvoiceId !== clickedInvoice?.unsavedId &&
        selectedInvoiceId !== clickedInvoice?.id &&
        !isInvoiceLoading &&
        !isInvoiceFetching
      ) {
        const source = fetchedSelectedInvoice ? getInvoiceInitialValues(fetchedSelectedInvoice) : {};
        const target = invoiceFormValues ? getInvoiceInitialValues(invoiceFormValues) : {};

        const invoiceChanges = invoiceGenerateChanges({
          source,
          target,
          fields: Object.keys(source).concat(Object.values(INVOICE_ITEM_TYPE_TO_KEY)),
        });
        updateInvoice({ invoiceChanges, source, clickedInvoice, invoice: invoiceFormValues });
      }
    },
    [
      selectedInvoiceId,
      invoiceFormValues,
      afterActionFn,
      fetchedSelectedInvoice,
      setSelectedInvoiceId,
      validateInvoiceFormAndExecuteAction,
      invoicingScheduleFormValues,
      isInvoiceLoading,
      isInvoiceFetching,
      scheduleFormRef,
      setSelectedTabsPanelTab,
    ],
  );

  return {
    checkAndSwitchInvoice,
  };
};
