import { useQuery } from 'react-query';
import { getTransactionCurrencies } from './requests';

export const useTransactionCurrenciesAPI = ({ orgId }) => {
  const { data, error, isLoading, isFetching, refetch } = useQuery(
    ['transactions', orgId],
    () => getTransactionCurrencies({ orgId }),
    { enabled: true },
  );

  return {
    data,
    error,
    isLoading,
    isFetching,
    operations: {
      refetch,
    },
  };
};
