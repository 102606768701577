import React from 'react';
import styled from 'styled-components';
import {
  ReactTable,
  ReactTableBody,
  ReactTableCell,
  ReactTableHeader,
  ReactTableHeaderColumn,
  ReactTableRow,
  ReactTableHeaderRow,
} from 'components/Table';

const CSVReactTable = styled(ReactTable)`
  margin-bottom: 30px;
`;

const CSVReactTableRow = styled(ReactTableRow)`
  &:first-child {
    border-radius: 12px 12px 0 0;
    border-top: none;
  }

  &:last-child {
    border-radius: 0 0 12px 12px;
    border-bottom: none;
  }
`;

const CSVReactTableHeaderRow = styled(ReactTableHeaderRow)`
  padding-bottom: 10px;
  border-bottom: none;
`;

const CSVReactTableBody = styled(ReactTableBody)`
  border: 1px solid var(--primaryBlack5);
  border-radius: 12px;
`;

const CSVImportsTable = ({ tableProps }) => {
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page } = tableProps;

  return (
    <>
      <CSVReactTable {...getTableProps()}>
        <ReactTableHeader>
          {headerGroups.map((headerGroup) => (
            <CSVReactTableHeaderRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <ReactTableHeaderColumn customWidth={column.width} {...column.getHeaderProps()}>
                  {column.render('Header')}
                </ReactTableHeaderColumn>
              ))}
            </CSVReactTableHeaderRow>
          ))}
        </ReactTableHeader>
        <CSVReactTableBody {...getTableBodyProps()} data-cy="csv-imports-table">
          {page.map((row) => {
            prepareRow(row);
            return (
              <CSVReactTableRow {...row.getRowProps()} data-cy="csv-imports-table__row">
                {row.cells.map((cell) => (
                  <ReactTableCell customWidth={cell.column.width} {...cell.getCellProps()}>
                    {cell.render('Cell')}
                  </ReactTableCell>
                ))}
              </CSVReactTableRow>
            );
          })}
        </CSVReactTableBody>
      </CSVReactTable>
    </>
  );
};

export { CSVImportsTable };
