import React, { useContext, useMemo } from 'react';
import styled from 'styled-components';
import { useFormikContext } from 'formik';
import { ReactComponent as ArrowRight } from 'images/arrow-narrow-right.svg';
import { FormikCustomSelector } from 'components/Controls';
import { FlexBetweenContainer, FlexerColumn, FlexerRow } from 'components/Core';
import { InvoicingScheduleContext } from '../InvoicingScheduleContext';
import { CreditNoteContext } from '../CreditNoteContext';
import { INVOICING_SCHEDULE_TABS_PANEL_TABS } from '../InvoicingScheduleTabsPanel';
import dayjs from 'dayjs';
import { numberFormatter } from 'utils/formatters';
import { NUMBER_FORMATS } from 'consts/global';
import { AppContext } from 'AppContext';
import { InlineButton } from 'components/Buttons';
import { buildCreditNoteItemsFromInvoice } from './utils';

const InvoiceLink = styled(FlexerRow)`
  gap: 4px;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  color: var(--primaryBlue);
  cursor: pointer;

  svg {
    width: 16px;
    height: 16px;

    g {
      opacity: 1;

      path {
        fill: var(--primaryBlue);
      }
    }
  }
`;

const AllocationWrapper = styled(FlexerRow)`
  gap: 8px;
  align-items: center;
`;

const AllocationTitle = styled.div`
  font-weight: 900;
  font-size: 12px;
  line-height: 16px;
  color: var(--primaryBlack);
`;

const AllocationSubTitle = styled.div`
  color: var(--primaryBlack50);
  font-size: 12px;
  font-style: italic;
  font-weight: 900;
  line-height: 16px;
`;

export const AllocateCreditNoteSection = ({ readOnly }) => {
  const {
    appSettings: { currencyISOCode },
  } = useContext(AppContext);
  const {
    isScheduleDraft,
    setSelectedInvoiceId,
    setSelectedTabsPanelTab,
    invoicingService,
    fetchedSelectedInvoice,
  } = useContext(InvoicingScheduleContext);
  const { invoices, invoiceOptions, openConfirmUnallocateInvoiceModal } = useContext(CreditNoteContext);
  const { values, setFieldValue } = useFormikContext();

  const allocatedInvoice = useMemo(() => {
    const invoiceId = Object.keys(values.allocations ?? {})[0];
    return invoiceId
      ? invoiceId === fetchedSelectedInvoice?.id
        ? fetchedSelectedInvoice
        : invoices.find((invoice) => invoice.id === invoiceId)
      : null;
  }, [invoices, fetchedSelectedInvoice, values.allocations]);

  const handleClickInvoiceLink = async () => {
    await setSelectedInvoiceId(values.allocated_invoice);
    setSelectedTabsPanelTab(INVOICING_SCHEDULE_TABS_PANEL_TABS.INVOICES);
  };

  const handleClickUnlinkInvoice = async () => {
    openConfirmUnallocateInvoiceModal({ creditNoteId: values.id });
  };

  return (
    <FlexerColumn width="100%" gap="16px">
      {allocatedInvoice ? (
        <AllocationWrapper>
          <AllocationTitle>Credit Note is allocated to invoice:</AllocationTitle>
          <AllocationSubTitle>
            #{allocatedInvoice.invoice_number || allocatedInvoice.id} -{' '}
            {dayjs.utc(allocatedInvoice.date).format('MMM DD, YYYY')} (
            {numberFormatter({
              type: NUMBER_FORMATS.CURRENCY,
              rawValue: allocatedInvoice.amount,
              currency: allocatedInvoice.currency ?? currencyISOCode,
              decimalPlaces: 2,
            })}
            )
          </AllocationSubTitle>
          <InlineButton
            isSecondary
            withBackground
            onClick={handleClickInvoiceLink}
            type="button"
            data-cy="credit-note-view__view-invoice-button"
          >
            View Invoice
          </InlineButton>
          <InlineButton
            isDelete
            withBackground
            onClick={handleClickUnlinkInvoice}
            type="button"
            disabled={readOnly}
            data-cy="credit-note-view__unlink-invoice-button"
          >
            Unlink Invoice
          </InlineButton>
        </AllocationWrapper>
      ) : (
        <>
          <FlexBetweenContainer>
            <AllocationTitle>Apply credit note to the invoice</AllocationTitle>
            {!!values.allocated_invoice && (
              <InvoiceLink onClick={handleClickInvoiceLink}>
                View Invoice
                <ArrowRight />
              </InvoiceLink>
            )}
          </FlexBetweenContainer>
          <FormikCustomSelector
            placeholder="Select Invoice"
            name="allocated_invoice"
            tooltipInputDisplay={
              isScheduleDraft
                ? 'You can only allocate credit notes to invoices after the invocing schedule is saved.'
                : ''
            }
            handleChange={(option) => {
              setFieldValue('allocated_invoice', option?.value);
              if (option.value) {
                const invoice = invoices.find(({ id }) => id === option.value);
                if (invoice) {
                  setFieldValue('items', buildCreditNoteItemsFromInvoice(invoice, invoicingService));
                }
              }
            }}
            isDisabled={isScheduleDraft || readOnly}
            options={invoiceOptions}
            containerWidth="100%"
            greyVer
            noSort
          />
        </>
      )}
    </FlexerColumn>
  );
};
