export const configurationRequired = (selectedCRMIntegration) => {
  return (
    !selectedCRMIntegration?.metadata?.closedWonStages &&
    !selectedCRMIntegration?.metadata?.seatsVar &&
    !selectedCRMIntegration?.metadata?.contractStartDateField &&
    !selectedCRMIntegration?.metadata?.contractEndDateField
  );
};

export const FREQUENCY_DAY_TO_LABEL = {
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
  7: 'Sunday',
  first_day: 'First day of the month',
  first_monday: 'First Monday of the month',
  last_day: 'Last day of the month',
};

export const getFrequencyLabel = ({ emailIntegrationFrequency }) => {
  let label = 'Monday';
  if (emailIntegrationFrequency?.period) {
    if (emailIntegrationFrequency?.period === 'Day') {
      label = 'Day';
    } else {
      label = FREQUENCY_DAY_TO_LABEL[emailIntegrationFrequency.day];
    }
  }

  return label;
};
