import React from 'react';
import { ReactComponent as RedWarnIcon } from 'images/red-warn.svg';
import { ReactComponent as SuccessIcon } from 'images/success-icon.svg';
import { ReactComponent as AuthorizedIcon } from 'images/authorized_icon.svg';
import { ConnectionContainer, ConnectionEstablished } from '../Tabs/styles';

const StatusComponent = ({ hasIntegration, isConfigurationRequired }) => {
  let Status = () => (
    <>
      <RedWarnIcon height={20} width={20} />
      <ConnectionEstablished>Integration is disconnected</ConnectionEstablished>
    </>
  );

  if (hasIntegration && isConfigurationRequired) {
    Status = () => (
      <>
        <AuthorizedIcon height={20} width={20} />
        <ConnectionEstablished>Integration is authorized. Configuration required!</ConnectionEstablished>
      </>
    );
  } else if (hasIntegration && !isConfigurationRequired) {
    Status = () => (
      <>
        <SuccessIcon height={20} width={20} />
        <ConnectionEstablished>Integration is connected</ConnectionEstablished>
      </>
    );
  }

  return <Status />;
};

const ConnectionStatus = ({ isConfigurationRequired, hasIntegration }) => (
  <ConnectionContainer>
    <StatusComponent hasIntegration={hasIntegration} isConfigurationRequired={isConfigurationRequired} />
  </ConnectionContainer>
);

export default ConnectionStatus;
