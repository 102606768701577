import React, { useState } from 'react';
import styled from 'styled-components';
import { useClickOutside } from 'utils/hooks';
import { FilterConditionHead } from 'shared/Filters';
import { StyledSelectorPopover } from 'shared/Filters/styles';
import { SwitchWithLabel } from '../Controls';

const SwitchWrapper = styled.div`
  white-space: normal;

  &:not(:last-child) {
    margin-bottom: 10px;
  }
`;

/*
 * Optional: allSelectedLabel - If all toggles are selected, show this label
 */
export const ToggleDropdownButton = ({ toggles, selected, onSelect, name, allSelectedLabel }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useClickOutside(() => setShowDropdown(false));

  const handleSwitchChange = (value) => {
    const selectionSet = new Set(selected);
    // We don't allow all toggles to be unchecked
    if (!(selectionSet.size === 1 && selectionSet.has(value))) {
      if (selectionSet.has(value)) {
        selectionSet.delete(value);
      } else {
        selectionSet.add(value);
      }
      onSelect(Array.from(selectionSet));
    }
  };

  return (
    <FilterConditionHead
      onClick={() => setShowDropdown(!showDropdown)}
      ref={dropdownRef}
      data-cy={`${name}-selector-toggler`}
    >
      {selected.length === toggles.length && allSelectedLabel
        ? allSelectedLabel
        : selected?.length > 0
        ? toggles
            // Keeping toggle order and display toggle label
            .filter((toggle) => selected.includes(toggle.value))
            .map((toggle) => toggle.label)
            .join(', ')
        : 'None'}
      {showDropdown && (
        <StyledSelectorPopover data-cy={`${name}-selector`} width="220px" style={{ padding: '12px' }}>
          {toggles.map((toggle) => (
            <SwitchWrapper key={toggle.value}>
              <SwitchWithLabel
                name={toggle.value}
                label={toggle.label}
                checked={selected.includes(toggle.value)}
                onChange={() => handleSwitchChange(toggle.value)}
                alignItems={true}
                description={toggle.description}
              />
            </SwitchWrapper>
          ))}
        </StyledSelectorPopover>
      )}
    </FilterConditionHead>
  );
};
