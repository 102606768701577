import { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';

export const Portal = ({ children }) => {
  const elemRef = useRef();

  elemRef.current = elemRef.current || document.createElement('div');

  const anchor = document.getElementById('main-content');

  useEffect(() => {
    anchor?.appendChild(elemRef.current);
    return () => anchor?.removeChild(elemRef.current);
  }, [anchor]);

  return ReactDOM.createPortal(children, elemRef.current);
};
