import styled from 'styled-components';
import { ReactComponent as Icon } from 'images/file-plus.svg';

export const FilePlusIcon = styled(Icon)`
  width: ${({ size }) => size ?? '24px'};
  height: ${({ size }) => size ?? '24px'};

  path {
    fill: ${({ fill }) => fill};
  }
`;
