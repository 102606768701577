import React from 'react';
import { useHistory } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import config from 'config';

const { app } = config;

export const Auth0ProviderWithHistory = ({ children }) => {
  const domain = app.AUTH0_DOMAIN;
  const clientId = app.AUTH0_CLIENT_ID;

  const history = useHistory();

  const onRedirectCallback = (appState) => {
    history.push(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={window.location.origin}
      audience="https://authorization"
      onRedirectCallback={onRedirectCallback}
      scope="read:current_user update:current_user_metadata"
      cacheLocation={app.ENVIRONMENT === 'production' ? undefined : 'localstorage'}
    >
      {children}
    </Auth0Provider>
  );
};
