import React from 'react';
import styled from 'styled-components';
import { ReactComponent as DeleteItem } from 'images/segments-trash.svg';
import { templateToHTMLString } from 'utils/htmlUtils';
import { Column, FlexEndContainer } from 'components/Core';

import {
  Modal,
  ModalBody,
  ModalButton,
  ModalCloseIcon,
  ModalContainer,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from 'components/Modal';

export const DeleteCard = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 16px;
  justify-content: flex-start;
  margin-bottom: 30px;
  background: var(--primaryRed5);
  border-radius: 8px;

  svg {
    margin-right: 8px;

    g {
      opacity: 1;
    }
  }
`;

const DeleteTitle = styled.div`
  font-weight: 900;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 4px;
`;

const DeleteSubtitle = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  opacity: 0.5;
  margin-bottom: 8px;
`;

const DeleteText = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
`;

const Subtitle = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin-top: 8px;
  margin: 0 36px;
  width: 281px;
  margin-bottom: 24px;
`;

export const RemoveTemplateModal = ({ selectedTemplate, setShowRemoveModal, onSubmit }) => {
  return (
    <ModalContainer>
      <Modal maxWidth="440px" height="auto" overflow="visible">
        <ModalHeader>
          <ModalCloseIcon onClose={() => setShowRemoveModal(false)} width={36} height={36} />
          <ModalTitle padding="0 0 0 36px" bold>
            Remove step
          </ModalTitle>
        </ModalHeader>

        <Subtitle>Do you really want to remove the next step? This cannot be un-done.</Subtitle>

        <ModalBody height="700px" gap="0" paddingLeft="36px" paddingRight="36px">
          <DeleteCard>
            <DeleteItem />
            <Column horizontal="flex-start">
              <DeleteTitle
                dangerouslySetInnerHTML={{
                  __html: templateToHTMLString({
                    text: selectedTemplate?.subject,
                  }),
                }}
              />
              <DeleteSubtitle>
                {selectedTemplate?.days_from_due_date < 0 ? 'Before' : 'After'}{' '}
                {Math.abs(selectedTemplate?.days_from_due_date)} days
              </DeleteSubtitle>

              <DeleteText
                dangerouslySetInnerHTML={{
                  __html: templateToHTMLString({
                    text: selectedTemplate?.body,
                  }),
                }}
              />
            </Column>
          </DeleteCard>
        </ModalBody>
        <ModalFooter noFixedHeight padding="13px 21px">
          <FlexEndContainer>
            <ModalButton onClick={() => setShowRemoveModal(false)}>Cancel</ModalButton>
            <ModalButton onClick={() => onSubmit(selectedTemplate)} delete>
              Remove
            </ModalButton>
          </FlexEndContainer>
        </ModalFooter>
      </Modal>
    </ModalContainer>
  );
};
