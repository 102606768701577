import React, { useContext, useState } from 'react';
import { useFormikContext } from 'formik';
import { cssVar } from 'polished';
import { ReactComponent as MenuIcon } from 'images/menu.svg';
import { useClickOutside } from 'utils/hooks';
import { Popover, PopoverItemsContainer, PopoverListItem, PopoverWrapper } from 'components/Portal';
import { CircleButton, IconButton } from 'components/Buttons';
import { SaveIcon } from 'components/Icons';
import { Spacer } from 'components/Core';
import { TransactionContext } from '../TransactionContext';

export const TransactionsActions = () => {
  const { setShowDeleteModal, isTransactionLocked, handleDuplicateTransaction } = useContext(TransactionContext);
  const { submitForm } = useFormikContext();

  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useClickOutside(() => setShowDropdown(false));

  const onSave = () => submitForm();
  const onDelete = () => setShowDeleteModal(true);

  return (
    <>
      <IconButton
        icon={<SaveIcon />}
        disabled={isTransactionLocked}
        iconFillColor={cssVar('--primaryGreen')}
        onClick={onSave}
        data-cy="transaction-actions__save-button"
      >
        Save
      </IconButton>
      <Spacer width="8px" />
      <PopoverWrapper ref={dropdownRef}>
        <CircleButton
          data-cy="transaction-actions-popover__toggler"
          disabled={isTransactionLocked}
          active={showDropdown}
          onClick={() => setShowDropdown(!showDropdown)}
          icon={<MenuIcon />}
          hoverText="Actions"
          XOffset={-30}
        />
        {showDropdown && (
          <Popover data-cy="transaction-actions-popover__dropdown" width="98px">
            <PopoverItemsContainer>
              <PopoverListItem
                borderBottom
                onClick={handleDuplicateTransaction}
                data-cy="transaction-actions-popover__duplicate-button"
              >
                Duplicate
              </PopoverListItem>
              <PopoverListItem
                borderBottom
                color="var(--primaryRed)"
                onClick={onDelete}
                data-cy="transaction-actions-popover__delete-button"
              >
                Remove
              </PopoverListItem>
            </PopoverItemsContainer>
          </Popover>
        )}
      </PopoverWrapper>
    </>
  );
};
