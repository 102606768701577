import React, { useContext, useMemo, useCallback, useState } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { isEmpty } from 'lodash';
import { useGlobalFilter, useTable, usePagination, useRowSelect, useSortBy } from 'react-table';
import { omitBy, isUndefined } from 'lodash';
import { AppContext } from 'AppContext';
import { InlineButton } from 'components/Buttons';
import { customGlobalFilter, RowActionPopover, TableActionCell } from 'components/Table';
import { TruncateStringWithTooltip } from 'components/Tooltip';
import { TableColumnSettings } from 'shared/Common';
import { onSelectPeriod, PERIOD_OPTIONS } from 'shared/Filters/utils';
import { useUsageEventsAPI } from 'api/usageBasedEngine';
import { useOrganizationMetadataAPI } from 'api/organizations';
import { usePagination as usePaginationButtons } from 'utils/hooks';
import { getCustomerDisplayName } from 'models/customer';
import { USAGE_EVENTS_TABLE_COLUMN_TITLES_BY_ID } from './consts';
import { UsageEventsTable } from './UsageEventsTable';
import { useUsageEventViewModal } from './UsageEventViewModal';
import { useUsageEventDeleteModal } from './UsageEventDeleteModal';
import { UsageEventFilters } from './UsageEventFilters';
import { CustomerField, EventName } from './styles';
import { TableContainerLayout } from '../../Common/TableContainerLayout';

dayjs.extend(utc);

export const UsageEventsTableContainer = ({
  prefilteredEventName,
  prefilteredCustomerId,
  prefilteredCustomerName,
  prefilteredStartDate,
  prefilteredEndDate,
}) => {
  const { orgId } = useContext(AppContext);

  const { data: organizationMetadata } = useOrganizationMetadataAPI({ orgId });
  const { earliestAndLatestSpreadsDates } = organizationMetadata ?? {};

  const [searchQuery, setSearchQuery] = useState('');
  const [period, setPeriod] = useState(prefilteredStartDate ? 'custom' : 'thisYear');
  const [startMonth, setStartMonth] = useState(
    prefilteredStartDate ? dayjs.utc(prefilteredStartDate).toDate() : dayjs.utc().startOf('year').toDate(),
  );
  const [endMonth, setEndMonth] = useState(
    prefilteredEndDate
      ? dayjs.utc(prefilteredEndDate).subtract(1, 'day').toDate()
      : dayjs.utc().endOf('month').toDate(),
  );
  const [customerIds, setCustomerIds] = useState(prefilteredCustomerId ? [prefilteredCustomerId] : []);
  const [eventNames, setEventNames] = useState(prefilteredEventName ? [prefilteredEventName] : []);

  const selectPeriod = useCallback(
    (newPeriod) => {
      const { startMonth, endMonth } = onSelectPeriod({
        period: PERIOD_OPTIONS[newPeriod],
        earliestAndLatestSpreadsDates,
      });
      setPeriod(newPeriod);
      if (startMonth) setStartMonth(dayjs(startMonth).startOf('month').toDate());
      if (endMonth) setEndMonth(dayjs(endMonth).endOf('month').toDate());
    },
    [setPeriod, setStartMonth, setEndMonth, earliestAndLatestSpreadsDates],
  );
  const selectStartMonth = useCallback(
    (newStartMonth) => setStartMonth(dayjs(newStartMonth).startOf('month').toDate()),
    [setStartMonth],
  );
  const selectEndMonth = useCallback((newEndMonth) => setEndMonth(dayjs(newEndMonth).endOf('month').toDate()), [
    setEndMonth,
  ]);

  const { currentPageIndex, setCurrentPageIndex, pageSize, setPageSize } = usePaginationButtons({
    cacheSuffix: 'usage-events-page',
  });

  const { data, isFetching } = useUsageEventsAPI({
    orgId,
    params: omitBy(
      {
        'filters[customerIds]': isEmpty(customerIds) ? undefined : customerIds,
        'filters[eventNames]': isEmpty(eventNames) ? undefined : eventNames,
        'filters[searchQuery]': searchQuery || undefined,
        'filters[startDate]': startMonth ?? undefined,
        'filters[endDate]': endMonth ? dayjs.utc(endMonth).add(1, 'day').toDate() : undefined,
        'pagination[page]': currentPageIndex,
        'pagination[limit]': pageSize,
        'scopes[]': ['customers', 'pagination_data'],
      },
      isUndefined,
    ),
  });

  const dataForTable = useMemo(
    () =>
      data?.data?.map((event) => ({
        ...event,
      })) ?? [],
    [data],
  );

  const { openModal: openUsageEventViewModal, UsageEventViewModal } = useUsageEventViewModal();
  const { openModal: openUsageEventDeleteModal, UsageEventDeleteModal } = useUsageEventDeleteModal();

  const columns = useMemo(
    () => [
      {
        Header: 'Customer',
        accessor: 'customer_id',
        id: 'customer_id',
        width: 200,
        Cell: ({ row }) => (
          <CustomerField>
            <InlineButton onClick={() => window.open(`/customers/${row.original.customer_id}`, '_blank')} isSecondary>
              {getCustomerDisplayName({ customer: row.original.customer })}
            </InlineButton>
          </CustomerField>
        ),
        disableSortBy: true,
      },
      {
        Header: 'Event name',
        accessor: 'name',
        id: 'name',
        width: 200,
        Cell: ({ row }) => <EventName>{row.original.name}</EventName>,
        disableSortBy: true,
      },
      {
        Header: 'Units',
        accessor: 'units',
        id: 'units',
        width: 70,
        Cell: ({ row }) => row.original.units ?? 1,
        disableSortBy: true,
      },
      {
        Header: 'Source',
        accessor: 'source',
        id: 'source',
        width: 130,
        Cell: ({ row }) => row.original.source,
        disableSortBy: true,
      },
      {
        Header: 'Notes',
        accessor: 'notes',
        id: 'notes',
        width: 250,
        Cell: ({ row }) => (
          <TruncateStringWithTooltip length={37} tooltipWidth={200}>
            {row.original.notes}
          </TruncateStringWithTooltip>
        ),
      },
      {
        Header: 'Event time',
        accessor: 'timestamp',
        id: 'timestamp',
        width: 160,
        Cell: ({ row }) => dayjs.utc(row.original.timestamp).format('L [at] h:mm A'),
        disableSortBy: true,
      },
      {
        accessor: 'actions',
        id: 'actions',
        width: 10,
        Cell: ({ row }) => (
          <TableActionCell>
            <RowActionPopover
              currentSelection={row.original}
              onViewDetailsClick={(usageEvent) => openUsageEventViewModal({ usageEvent })}
              onDeleteClick={(usageEvent) => openUsageEventDeleteModal({ usageEvent })}
              dataCyPrefix="usage-events"
            />
          </TableActionCell>
        ),
        disableSortBy: true,
      },
    ],
    [openUsageEventViewModal, openUsageEventDeleteModal],
  );

  const tableProps = useTable(
    {
      columns,
      data: dataForTable,
      getSubRows: useCallback((row) => row.subRows || [], []),
      globalFilter: customGlobalFilter,
      manualPagination: true,
      manualSortBy: true,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
  );

  return (
    <>
      <UsageEventFilters
        orgId={orgId}
        initialCustomerName={prefilteredCustomerName}
        customerIds={customerIds}
        eventNames={eventNames}
        period={period}
        selectPeriod={selectPeriod}
        startMonth={startMonth}
        endMonth={endMonth}
        setCustomerIds={setCustomerIds}
        setEventNames={setEventNames}
        selectStartMonth={selectStartMonth}
        selectEndMonth={selectEndMonth}
      />
      <TableContainerLayout
        columnsSettingsComponent={
          <TableColumnSettings
            tableName="usageEvents"
            numberValue={pageSize}
            handleShowResultsChange={(option) => setPageSize(option.value)}
            columnsTitles={USAGE_EVENTS_TABLE_COLUMN_TITLES_BY_ID}
            allColumns={tableProps.allColumns}
          />
        }
        isFetching={isFetching}
        rowsCount={data?.metadata?.totalCount}
        currentPageIndex={currentPageIndex ?? 1}
        setCurrentPageIndex={setCurrentPageIndex}
        pageCount={data?.metadata?.maxPage ?? 0}
        setSearchQuery={setSearchQuery}
        placeholder="Search by event name, source, or notes"
      >
        <UsageEventsTable tableProps={tableProps} />
      </TableContainerLayout>
      <UsageEventViewModal />
      <UsageEventDeleteModal />
    </>
  );
};
