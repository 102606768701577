import dayjs from 'dayjs';
import { RECOGNITION_TYPES } from 'consts/global';

export const sortScheduleByDates = ({ a, b }) => {
  const dateA = a[0].slice(0, a[0].indexOf(':') !== -1 ? a[0].indexOf(':') : 100);
  const dateB = b[0].slice(0, b[0].indexOf(':') !== -1 ? b[0].indexOf(':') : 100);

  if (dayjs(dateA).isBefore(dayjs(dateB))) return -1;
  else if (dayjs(dateA).isAfter(dayjs(dateB))) return 1;
  else return 0;
};

// Same as `calculateIncludeEndMonth` in transactions.utils on the backend
export const calculateIncludeEndMonth = ({ recognition, startDate, endDate }) => {
  const startDateDayJS = dayjs.utc(startDate);
  const endDateDayJS = dayjs.utc(endDate);
  if (
    ![RECOGNITION_TYPES.immediate, RECOGNITION_TYPES.eventNotRecurring].includes(recognition) &&
    startDate &&
    endDate &&
    recognition &&
    startDateDayJS.isBefore(endDateDayJS)
  ) {
    // if the end date is the last day of the month
    // TODO: Will add back in after testing manually
    // if (endDateDayJS.isSame(endDateDayJS.endOf('month'), 'day')) return true;

    const trueMonthsBetweenEndAndStart = endDateDayJS.diff(startDateDayJS, 'month', true);
    const monthsWhileExcluding = endDateDayJS.diff(startDateDayJS.startOf('month'), 'month');
    return trueMonthsBetweenEndAndStart - monthsWhileExcluding >= 0.5;
  } else {
    return null;
  }
};
