import { FormikCustomRadio } from 'components/Controls';
import { FlexBetweenContainer } from 'components/Core';
import { humanize } from 'utils/stringUtils';
import { FieldBody, FieldTitle } from 'views/Wizard/styles';

const ENTITY_OPTIONS = {
  ACCOUNT: {
    label: 'By accounts',
    value: 'account',
  },
  CLASS: {
    label: 'By classes',
    value: 'class',
  },
};

const IntegrationEntity = ({ service }) => {
  return (
    <FieldBody>
      <FlexBetweenContainer>
        <FieldTitle noMarginBottom>
          <span>How do you want to integrate your {humanize(service)}?</span>
        </FieldTitle>

        <FormikCustomRadio
          name="metadata.byEntity"
          suffix="edit-metric-card-modal__id-radio"
          options={Object.values(ENTITY_OPTIONS)}
        />
      </FlexBetweenContainer>
    </FieldBody>
  );
};

export default IntegrationEntity;
