import React, { useContext, useMemo } from 'react';

import { AppContext } from 'AppContext';
import { CREDIT_NOTE_DYNAMIC_TAGS, DEFAULT_AVAILABLE_TAGS } from 'consts/billing';
import { SERVICE_WITHOUT_EMAIL_CC } from 'views/Billing/consts';
import { BillingDynamicFieldsTooltip, DYNAMIC_FIELD_TOOLTIP_SECTIONS } from 'views/Billing/BillingDynamicFieldsTooltip';
import { templateToHTMLString } from 'utils/htmlUtils';
import { useGetAvailableTags } from 'api/billing';
import { FieldBody, FieldTitle } from 'views/Wizard/styles';
import { CentererVertical } from 'components/Core';
import { FormikCustomInput, FormikEmailSelector } from 'components/Controls';
import { humanize } from 'utils/stringUtils';

import { FormRow, PaddingWrapper } from '../styles';
import { SENDER_OPTIONS } from '../BillingSenderSetttings/consts';
import { MemoTemplatesTable } from './MemoTemplatesTable';
import { ApplyToAllCheckboxes } from '../ApplyToAllCheckboxes';

export const DefaultTemplate = ({ values, setFieldValue, initialBillingInvoiceDefaults, invoicingServices }) => {
  const { orgId } = useContext(AppContext);

  const sendEmailFromSubscript = values?.billingSenderDefaults?.sender === SENDER_OPTIONS.SUBSCRIPT_EMAIL;

  const {
    transactionMetadataTags = [],
    customerMetadataTags = [],
    transactionCustomerMetadataTags = [],
  } = useGetAvailableTags({ orgId });

  const emailDetailsAreChanged =
    (initialBillingInvoiceDefaults.email_subject ?? null) !== values.billingInvoiceDefaults.email_subject ||
    (initialBillingInvoiceDefaults.email_body ?? null) !== values.billingInvoiceDefaults.email_body;

  const availableTags = useMemo(
    () => [...DEFAULT_AVAILABLE_TAGS, ...CREDIT_NOTE_DYNAMIC_TAGS, ...transactionMetadataTags, ...customerMetadataTags],
    [customerMetadataTags, transactionMetadataTags],
  );

  return (
    <>
      <FieldBody>
        <MemoTemplatesTable
          generalTags={[...DEFAULT_AVAILABLE_TAGS, ...CREDIT_NOTE_DYNAMIC_TAGS]}
          transactionMetadataTags={transactionMetadataTags}
          customerMetadataTags={customerMetadataTags}
          transactionCustomerMetadataTags={transactionCustomerMetadataTags}
        />
      </FieldBody>

      <FieldBody style={{ padding: 0, overflow: 'hidden' }}>
        <PaddingWrapper>
          <FieldTitle>Default settings for invoice emails</FieldTitle>
          <FormRow marginTop="16px" childrenWidth="100%">
            <FormikEmailSelector
              name="billingInvoiceDefaults.email_CC"
              label={`Email address to CC on all emails${
                !sendEmailFromSubscript &&
                invoicingServices.some((service) => SERVICE_WITHOUT_EMAIL_CC.includes(service))
                  ? ` (CC not supported by ${humanize(
                      SERVICE_WITHOUT_EMAIL_CC.filter((service) => invoicingServices.includes(service)).join(', ') ??
                        '',
                    )} on initial invoicing email, only for reminders)`
                  : ''
              }`}
              value={values?.billingInvoiceDefaults?.email_CC?.filter((e) => typeof e === 'string')}
              onChange={(selectedValues) => {
                setFieldValue('billingInvoiceDefaults.email_CC', selectedValues);
              }}
              smallVersion
            />
          </FormRow>
          <FormRow marginTop="16px" childrenWidth="100%">
            <FormikCustomInput
              data-cy="configuration__email-subject"
              name="billingInvoiceDefaults.email_subject"
              label="Email Subject"
              HTMLString={templateToHTMLString({
                text: values?.billingInvoiceDefaults?.email_subject,
                availableTags,
              })}
              TopRightHeader={
                <CentererVertical gap="8px">
                  <BillingDynamicFieldsTooltip
                    tags={{
                      [DYNAMIC_FIELD_TOOLTIP_SECTIONS.GENERAL_TAGS]: DEFAULT_AVAILABLE_TAGS,
                      [DYNAMIC_FIELD_TOOLTIP_SECTIONS.TRANSACTION_METADATA_TAGS]: transactionMetadataTags,
                      [DYNAMIC_FIELD_TOOLTIP_SECTIONS.CUSTOMER_METADATA_TAGS]: customerMetadataTags,
                    }}
                    XOffset={100}
                  />
                </CentererVertical>
              }
              availableTags={availableTags}
              type="HTMLEdit"
              placeholder="Enter Email Subject..."
            />
          </FormRow>
          <FormRow marginTop="16px" childrenWidth="100%">
            <FormikCustomInput
              data-cy="configuration__email-body"
              name="billingInvoiceDefaults.email_body"
              minHeight="186px"
              label="Email Body"
              HTMLString={templateToHTMLString({
                text: values?.billingInvoiceDefaults?.email_body,
                availableTags,
              })}
              TopRightHeader={
                <CentererVertical gap="8px">
                  <BillingDynamicFieldsTooltip
                    tags={{
                      [DYNAMIC_FIELD_TOOLTIP_SECTIONS.GENERAL_TAGS]: DEFAULT_AVAILABLE_TAGS,
                      [DYNAMIC_FIELD_TOOLTIP_SECTIONS.TRANSACTION_METADATA_TAGS]: transactionMetadataTags,
                      [DYNAMIC_FIELD_TOOLTIP_SECTIONS.CUSTOMER_METADATA_TAGS]: customerMetadataTags,
                    }}
                    XOffset={100}
                  />
                </CentererVertical>
              }
              availableTags={availableTags}
              type="HTMLEdit"
              placeholder="Enter Email Body..."
            />
          </FormRow>
        </PaddingWrapper>

        <ApplyToAllCheckboxes
          isShowing={emailDetailsAreChanged}
          name="updateExistingInvoicesEmailDetails"
          changedEntity="email details (subject, body, CC)"
        />
      </FieldBody>
    </>
  );
};
