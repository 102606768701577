import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import navOverlay from 'images/nav_overlay.svg';
import { NewBadge } from 'components/Blocks';

export const Nav = styled.nav`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  height: 100%;
  padding: 20px 12px;

  border-right: 1px solid var(--accentGraySecond);
  background: url(${navOverlay}) no-repeat right bottom, var(--primaryGray);

  &:hover {
    background: url(${navOverlay}) no-repeat right bottom, var(--accentGrayFourth);
  }
`;

export const NavMenuList = styled.ul`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 0px;
  margin: 0px;
  gap: 8px;
  height: 100%;
  overflow-y: auto;
`;

export const tooltipStyles = {
  display: 'flex',
  padding: '12px 16px',
  width: 'inherit',
  maxWidth: '200px',

  color: '#FFF',
  fontSize: '12px',
  lineHeight: '16px',
  textAlign: 'left',

  backgroundColor: 'var(--dark7)',
  boxShadow: '16px 16px 60px var(--primaryBlack20)',
  borderRadius: '12px',
};

export const ListItem = styled.li`
  margin: 0;
  position: relative;

  background: ${(props) => props.$active && '#ffffff'};
  border: ${(props) => (props.$active ? '1px solid var(--accentGraySecond)' : '1px solid transparent')};
  box-shadow: ${(props) => props.$active && '4px 4px 24px var(--primaryBlack4)'};
  border-radius: 16px;

  &:hover {
    background: #ffffff;
    border: 1px solid var(--accentGraySecond);
    box-shadow: 4px 4px 24px var(--primaryBlack4);
  }

  ${NewBadge} {
    position: absolute;
    left: 0px;
    top: 50%;
    transform: translate(0, -50%) rotate(-90deg);
  }
`;

const StyledItem = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  position: relative;

  width: 92px;
  height: 76px;
  padding: 16px 12px;

  color: var(--primaryBlack);
  font-weight: ${(props) => (props.$active ? '700' : '400')};
  font-size: 12px;
  line-height: 16px;
  cursor: pointer;
  transition: all ease 0.1s;

  svg {
    width: DEFAULT_ICON_SIZE;
    height: DEFAULT_ICON_SIZE;

    path {
      fill: ${(props) => (props.$active ? 'url(#icon_active_gradient)' : 'var(--primaryBlack75)')};
    }
  }

  &:hover {
    color: var(--primaryBlack);
    font-weight: 700;

    svg {
      path {
        fill: url(#icon_active_gradient);
      }
    }
  }
`;

export const StyledLink = styled(Link)`
  ${StyledItem}
`;

export const StyledButton = styled.div`
  ${StyledItem}
`;

export const ProblemsCounter = styled.div`
  padding: 4px 8px;
  font-weight: 900;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  color: var(--primaryRed);
  background: var(--primaryRed10);
  border-radius: 100px;
`;
