import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export const groupCustomersByStage = ({ customers, stages, month, showingAll }) =>
  customers
    .reduce((acc, customer) => {
      const stage = stages.find((stage) => stage.stage_name === customer.stage);
      if (stage) {
        const group = acc.find((row) => row.id === stage.stage_name);
        const isSecondary = dayjs.utc(customer.forecast_month).format('YYYY-MM') !== month;
        const subRow = { ...customer, isSecondary };

        if (!showingAll && isSecondary) {
          return acc;
        } else if (group) {
          group.subRows.push(subRow);
        } else {
          acc.push({
            id: stage.stage_name,
            customer_name: stage.stage_name,
            recurring_amount: stage.recurring_amount,
            probability: stage.probability,
            forecasted_amount: stage.forecasted_amount,
            forecast_month: null,
            subRows: [subRow],
          });
        }
      }
      return acc;
    }, [])
    .sort((a, b) => a.probability - b.probability);
