import { FormikCustomCheckbox, FormikCustomInput, FormikCustomSelector } from 'components/Controls';
import { ColumnMapperSelector } from 'shared/CsvUpload/CsvTable/CsvTable';
import { components, createFilter } from 'react-select';
import { ISO_CODE_TO_SYMBOL } from 'consts/global';
import {
  HeaderSelector,
  SelectorTitle,
  CustomOption,
  CustomOptionKey,
  CustomOptionValue,
} from 'shared/CsvUpload/styles';

import { renderProductIdCell } from 'views/Transactions/TransactionsCsvUpload/fuzzyCells';
import { USAGE_BASED_ENGINE_FREQUENCY } from '../PricingPlans/consts';

const { Option } = components;

export const CustomSingleOption = (props) => {
  // optimization for large lists
  delete props.innerProps.onMouseMove;
  delete props.innerProps.onMouseOver;

  return (
    <Option {...props}>
      <CustomOption>
        <CustomOptionKey>{props.data.value}</CustomOptionKey>
        <CustomOptionValue>{props.data.label}</CustomOptionValue>
      </CustomOption>
    </Option>
  );
};

export const getColumns = ({
  organization,
  formRef,
  csvColumns,
  csvColumnsMapper,
  setCsvColumnsMapper,

  fuzzyRowsMapper,
  setFuzzyRowsMapper,
}) => {
  return [
    {
      Header: () => <></>,
      width: 80,
      accessor: 'status',
      Cell: () => <></>,
    },
    {
      Header: () => (
        <HeaderSelector data-cy="upload-csv__header-selector--name">
          <SelectorTitle>Pricing Plan name</SelectorTitle>
          <ColumnMapperSelector
            field="name"
            csvColumnsMapper={csvColumnsMapper}
            setCsvColumnsMapper={setCsvColumnsMapper}
            csvColumns={csvColumns}
          />
        </HeaderSelector>
      ),
      accessor: 'name',
      width: 180,
      Cell: ({ row }) => (
        <FormikCustomInput
          style={{ height: 32 }}
          errorWithoutTooltip
          placeholder="Enter name..."
          width="100%"
          name={`[${row.original.rowIndex}].name`}
        />
      ),
    },
    {
      Header: () => (
        <HeaderSelector>
          <SelectorTitle>Product</SelectorTitle>

          <ColumnMapperSelector
            field="product_id"
            csvColumnsMapper={csvColumnsMapper}
            setCsvColumnsMapper={setCsvColumnsMapper}
            csvColumns={csvColumns}
          />
        </HeaderSelector>
      ),
      accessor: 'product_id',
      width: 180,
      Cell: (cellProps) =>
        renderProductIdCell({
          ...cellProps,
          organization,
          formCurrent: formRef?.current,
          fuzzyRowsMapper,
          setFuzzyRowsMapper,
        }),
    },
    {
      Header: () => (
        <HeaderSelector>
          <SelectorTitle>Description (Optional)</SelectorTitle>
          <ColumnMapperSelector
            field="description"
            csvColumnsMapper={csvColumnsMapper}
            setCsvColumnsMapper={setCsvColumnsMapper}
            csvColumns={csvColumns}
          />
        </HeaderSelector>
      ),
      accessor: 'description',
      width: 270,
      Cell: ({ row }) => (
        <FormikCustomInput
          style={{ height: 32 }}
          errorWithoutTooltip
          placeholder="Enter description..."
          width="100%"
          name={`[${row.original.rowIndex}].description`}
        />
      ),
    },
    {
      Header: () => (
        <HeaderSelector>
          <SelectorTitle>Event name</SelectorTitle>
          <ColumnMapperSelector
            field="event_name"
            csvColumnsMapper={csvColumnsMapper}
            setCsvColumnsMapper={setCsvColumnsMapper}
            csvColumns={csvColumns}
          />
        </HeaderSelector>
      ),
      accessor: 'event_name',
      width: 180,
      Cell: ({ row }) => (
        <FormikCustomInput
          style={{ height: 32 }}
          errorWithoutTooltip
          placeholder="Enter event name..."
          width="100%"
          name={`[${row.original.rowIndex}].event_name`}
        />
      ),
    },
    {
      Header: () => (
        <HeaderSelector>
          <SelectorTitle>Currency</SelectorTitle>
          <ColumnMapperSelector
            field="currency"
            csvColumnsMapper={csvColumnsMapper}
            setCsvColumnsMapper={setCsvColumnsMapper}
            csvColumns={csvColumns}
          />
        </HeaderSelector>
      ),
      accessor: 'currency',
      width: 160,
      Cell: ({ row }) => (
        <FormikCustomSelector
          height={32}
          minWidth={160}
          menuWidth={200}
          //optimization thing
          filterOption={createFilter({ ignoreAccents: false })}
          errorWithoutTooltip
          placeholder="Select currency..."
          options={Object.entries(ISO_CODE_TO_SYMBOL).map(([code, symbol]) => ({
            label: `${symbol} (${code})`,
            value: code,
          }))}
          name={`[${row.original.rowIndex}].currency`}
          handleChange={(option) => {
            formRef?.current?.setFieldValue(`[${row.original.rowIndex}].currency`, option.value);
          }}
        />
      ),
    },
    {
      Header: () => (
        <HeaderSelector>
          <SelectorTitle>Interval</SelectorTitle>

          <ColumnMapperSelector
            field="interval"
            csvColumnsMapper={csvColumnsMapper}
            setCsvColumnsMapper={setCsvColumnsMapper}
            csvColumns={csvColumns}
          />
        </HeaderSelector>
      ),
      accessor: 'interval',
      width: 180,
      Cell: ({ row }) => (
        <FormikCustomSelector
          height={32}
          minWidth={160}
          menuWidth={200}
          //optimization thing
          filterOption={createFilter({ ignoreAccents: false })}
          errorWithoutTooltip
          placeholder="Select interval..."
          options={Object.values(USAGE_BASED_ENGINE_FREQUENCY).map((frequency) => ({
            label: frequency,
            value: frequency,
          }))}
          name={`[${row.original.rowIndex}].interval`}
          handleChange={(option) => {
            formRef?.current?.setFieldValue(`[${row.original.rowIndex}].interval`, option.value);
          }}
        />
      ),
    },
    {
      Header: () => (
        <HeaderSelector>
          <SelectorTitle>Bill in advance</SelectorTitle>
          <ColumnMapperSelector
            field="bill_in_advance"
            csvColumnsMapper={csvColumnsMapper}
            setCsvColumnsMapper={setCsvColumnsMapper}
            csvColumns={csvColumns}
          />
        </HeaderSelector>
      ),
      accessor: 'bill_in_advance',
      width: 180,
      Cell: ({ row }) => <FormikCustomCheckbox name={`[${row.original.rowIndex}].bill_in_advance`} />,
    },
    {
      Header: () => (
        <HeaderSelector data-cy="upload-csv__header-selector--tiers">
          <SelectorTitle>Tiers</SelectorTitle>

          <ColumnMapperSelector
            field="tiers"
            csvColumnsMapper={csvColumnsMapper}
            setCsvColumnsMapper={setCsvColumnsMapper}
            csvColumns={csvColumns}
          />
        </HeaderSelector>
      ),
      accessor: 'tiers',
      width: 360,
      Cell: ({ row }) => (
        <FormikCustomInput
          style={{ height: 32 }}
          errorWithoutTooltip
          placeholder="Enter tiers..."
          width="100%"
          name={`[${row.original.rowIndex}].tiers`}
        />
      ),
    },
  ];
};
