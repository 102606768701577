import { WATERFALL_GRAPH_PERIOD_OPTIONS } from 'consts/global';
import React, { useState } from 'react';
import { PAGE_CONTEXT_VALUES, useAppSetting, usePageContext, useStateWithStorage } from 'utils/hooks';
import { WaterfallContext } from './WaterfallContext';
import { WaterfallPage } from './WaterfallPage';

export const WaterfallContainer = () => {
  const [loading, setLoading] = useState(false);
  const [datesError, setDatesError] = useState(false);
  const [fetchDataError, setFetchDataError] = useState(false);
  const [waterfallGraphPeriod, setWaterfallGraphPeriod] = useStateWithStorage(
    'waterfallGraphPeriod',
    WATERFALL_GRAPH_PERIOD_OPTIONS.MONTHS,
  );

  const [snapshotSelectedColumns, setSnapshotSelectedColumns] = useAppSetting('dashboard.businessSnapshot.columns');
  const [showWaterFallExistingRow, setShowWaterFallExistingRow] = useAppSetting(
    'dashboard.dataSnapshot.showExistingRow',
  );
  const [showInfluxRow, setShowInfluxRow] = useAppSetting('dashboard.dataSnapshot.showInfluxRow');
  const [showInfluxMovementRow, setShowInfluxMovementRow] = useAppSetting(
    'dashboard.dataSnapshot.showInfluxMovementRow',
  );

  const { urlState } = usePageContext({
    page: PAGE_CONTEXT_VALUES.waterfall.key,
    includeInUrl: {
      waterfallGraphPeriod: [waterfallGraphPeriod, setWaterfallGraphPeriod],
    },
  });

  const uiState = {
    datesError,
    fetchDataError,
    loading,
    setDatesError,
    setFetchDataError,
    setLoading,
    snapshotSelectedColumns,
    setSnapshotSelectedColumns,
    waterfallGraphPeriod,
    setWaterfallGraphPeriod,
    showWaterFallExistingRow,
    setShowWaterFallExistingRow,
    showInfluxRow,
    setShowInfluxRow,
    showInfluxMovementRow,
    setShowInfluxMovementRow,
  };

  return (
    <WaterfallContext.Provider
      value={{
        ...uiState,
        ...urlState,
      }}
    >
      <WaterfallPage />
    </WaterfallContext.Provider>
  );
};
