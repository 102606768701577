import React from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { FILTER_OPERATORS } from './MetadataFilter';

const Wrapper = styled.div`
  margin-top: 12px;
  font-size: 12px;
  line-height: 16px;
  opacity: 0.5;
`;

export const MetadataFilterExplanation = ({ metadataFilter }) => {
  const shouldExplain = Array.isArray(metadataFilter?.customerMetadata?.topLevel__product_id);
  if (!shouldExplain) return <></>;

  const from = dayjs(metadataFilter.customerMetadata._startMonth).format('MMM YYYY');
  const to = dayjs(metadataFilter.customerMetadata._endMonth).format('MMM YYYY');
  const prefix = FILTER_OPERATORS[metadataFilter.customerMetadata._operator];

  return (
    <Wrapper>
      {prefix} customers who were active on one of products specified in the advanced filters between {from} and {to}
    </Wrapper>
  );
};
