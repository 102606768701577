import { useContext } from 'react';
import { useFormikContext } from 'formik';
import { AppContext } from 'AppContext';
import { BinButton } from 'components/Buttons';
import { CustomDatePicker, FormikCustomInput, FormikCustomSelector } from 'components/Controls';
import { Container } from 'components/Core';
import { formatDateForDatepicker, updateDateFromDatePicker } from 'utils/dateUtils';
import { ISO_CODE_TO_SYMBOL } from 'consts/global';
import { IndexDisplayCircle } from './styles';

export const PeriodForm = ({ index, onRemove }) => {
  const { setFieldValue, values, getFieldMeta } = useFormikContext();
  const {
    appSettings: { currencyISOCode: currency },
  } = useContext(AppContext);

  return (
    <>
      <IndexDisplayCircle> {index + 1} </IndexDisplayCircle>
      <Container>
        <FormikCustomSelector
          name={`periods.${index}.type`}
          options={[
            { label: 'Month', value: 'month' },
            { label: 'Quarter', value: 'quarter' },
            { label: 'Year', value: 'year' },
          ]}
        />
      </Container>
      <Container>
        <CustomDatePicker
          formik
          height="38px"
          selected={
            values?.periods?.[index]?.start_month ? formatDateForDatepicker(values.periods[index].start_month) : null
          }
          name={`periods.${index}.start_month`}
          onChange={(name, val) => {
            setFieldValue(name, updateDateFromDatePicker(val));
          }}
          showMonthYearPicker
          dateFormat="MM/yyyy"
          meta={getFieldMeta(`periods.${index}.start_month`)}
        />
      </Container>
      <Container>
        <FormikCustomInput name={`periods.${index}.value`} type="number" suffix={ISO_CODE_TO_SYMBOL[currency] ?? '$'} />
      </Container>
      <BinButton onClick={onRemove} />
    </>
  );
};
