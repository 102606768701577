import styled from 'styled-components';
import { Flexer, FlexBetweenContainer } from 'components/Core';
import {
  ReactTable,
  ReactTableBody,
  ReactTableCell,
  ReactTableHeader,
  ReactTableRow,
  ReactTableHeaderRow,
} from 'components/Table/styles';

export const TableWrapper = styled(ReactTable)`
  box-shadow: none;
  border: none;

  overflow-x: ${({ noMaxHeight }) => (noMaxHeight ? 'initial' : undefined)};
  max-height: ${({ noMaxHeight }) => (noMaxHeight ? 'none' : undefined)};
`;

export const TableHeader = styled(ReactTableHeader)`
  background: var(--accentGrayFourth);
  border: none;
  border-radius: 0;
`;

export const TableHeaderColumn = styled(Flexer)`
  color: var(--primaryBlack50);
  font-weight: 900;
  font-size: 10px;
  justify-content: flex-end;
  line-height: 12px;
  overflow: visible;
  text-transform: uppercase;
  min-width: 120px;
  width: ${({ columnCount }) => (columnCount ? `calc((100% - 270px) / ${columnCount - 1})` : undefined)};

  &:first-child {
    align-items: center;
    color: var(--primaryBlack);
    justify-content: flex-start;
    margin-top: -3px;
    white-space: nowrap;
    width: 270px;
  }
`;

export const TableHeaderRow = styled(ReactTableHeaderRow)`
  background: none;
  border-bottom: none;
  gap: 4px;
  height: 45px;
  padding-bottom: 18px;
`;

export const TableBody = styled(ReactTableBody)`
  border: 1px solid var(--accentGraySecond);
  border-radius: 16px;
  background: #ffffff;
`;

export const TableRow = styled(ReactTableRow)`
  background: none;
  gap: 4px;
  height: ${({ canExpand }) => !canExpand && '45px'};
  padding: 0 14px;

  &:first-child {
    border-radius: initial;
  }

  &:last-child {
    border-bottom: none;
    border-radius: initial;
  }

  &:hover {
    background: none;
    box-shadow: 4px 4px 24px var(--primaryBlack4);
  }
`;

export const TableCell = styled(ReactTableCell)`
  background: none;
  display: flex;
  font-size: ${({ canExpand }) => (canExpand ? '14px' : '12px')};
  font-weight: 400;
  justify-content: flex-end;
  line-height: ${({ canExpand }) => (canExpand ? '20px' : '16px')};
  padding: 19px 0;
  width: ${({ columnCount }) => (columnCount ? `calc((100% - 270px) / ${columnCount - 1})` : undefined)};

  &:first-child {
    border-right: 1px solid var(--accentGraySecond);
    justify-content: flex-start;
    width: 270px;
  }
`;

export const TableCellEmpty = styled(ReactTableCell)`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--primaryBlack30);
`;

export const CellWithIcon = styled(FlexBetweenContainer)`
  align-items: center;
  gap: 8px;
  padding-right: 18px;
  width: 100%;

  svg {
    min-height: 14px;
    min-width: 14px;

    &:hover {
      cursor: pointer;
    }
  }
`;
