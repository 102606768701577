import styled from 'styled-components';

export const Container = styled.div`
  width: 142px;
  min-width: 142px;
  display: flex;
  align-items: center;
`;

export const ArrowButton = styled.div`
  box-shadow: 4px 4px 28px var(--primaryBlack5);
  border-radius: 100px;
  border: 1px solid var(--accentGraySecond);
  padding: 8px;
  cursor: pointer;
  opacity: ${(props) => props.disabled && '0.7'};
  pointer-events: ${(props) => props.disabled && 'none'};

  &:hover {
    background-color: var(--accentGrayFourth);
  }
`;

export const TablePage = styled.h6`
  font-weight: 900;
  font-size: 10px;
  line-height: 14px;
  margin-bottom: 0;
  text-transform: uppercase;
  color: var(--primaryBlack50);
`;
