import { useContext, useMemo } from 'react';
import { AppContext } from 'AppContext';
import { Column, Row } from 'components/Core';
import { hasAutoChargeSupport } from 'views/Billing/utils';

import { NewInvoicesWidget } from './widgets/NewInvoicesWidget';
import { CollectionsAtRiskWidget } from './widgets/CollectionsAtRiskWidget';
import { RecentActivityWidget } from './widgets/RecentActivityWidget';
import { ARReportChartWidget } from './widgets/ARReportChartWidget';

export const BillingDashboardWidgets = () => {
  const { integrations } = useContext(AppContext);
  const hasAutoCharge = useMemo(() => hasAutoChargeSupport({ integrations }), [integrations]);

  return (
    <Column vertical="top" gap="20px" horizontal="normal" style={{ width: '100%' }}>
      <Row gap="20px" horizontal="normal" style={{ padding: '0 40px' }}>
        <Column gap="20px" vertical="top">
          <NewInvoicesWidget />

          <Row gap="20px" horizontal="normal" style={{ width: '100%' }}>
            <CollectionsAtRiskWidget />
          </Row>
        </Column>
      </Row>

      <Row gap="20px" horizontal="normal" style={{ padding: '0 40px', marginBottom: 52 }}>
        <RecentActivityWidget hasAutoCharge={hasAutoCharge} />

        <ARReportChartWidget hasAutoCharge={hasAutoCharge} />
      </Row>
    </Column>
  );
};
