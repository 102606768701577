import React from 'react';
import { uniqBy } from 'lodash';
import { MultiselectRibbon } from 'components/Blocks';
import { TrashIcon } from 'components/Icons';
import {
  ReactTableBody,
  ReactTableHeader,
  ReactTableHeaderColumn,
  ReactTableHeaderRow,
  ReactTableCell,
  ReactTableRow,
  EmptyTableContent,
  SortIconWrapper,
  HeaderCellWrapper,
} from 'components/Table';
import { ReactComponent as TotalIcon } from 'images/sort-descending.svg';

export const SubscriptionsTable = ({ tableProps, openInvoicingScheduleModal, bulkDeleteUsageSubscriptions }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    toggleAllRowsSelected,
    state: { selectedRowIds },
  } = tableProps;

  const selectedRows = rows.filter((row) => selectedRowIds[row.id]);

  return (
    <div {...getTableProps()} data-cy="billing__subscriptions-table">
      <ReactTableHeader backgroundColor="var(--secondaryGray)">
        {headerGroups.map((headerGroup) => (
          <ReactTableHeaderRow {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <ReactTableHeaderColumn
                {...column.getHeaderProps(column.id !== 'actions' && column.getSortByToggleProps({ title: undefined }))}
                customWidth={column.width}
              >
                <HeaderCellWrapper isSortable={column.canSort && !column.disableSortBy} isSorted={column.isSorted}>
                  {column.id === 'actions' ? <></> : column.render('Header')}

                  {column.isSorted && (
                    <SortIconWrapper isSortedDesc={column.isSortedDesc}>
                      <TotalIcon />
                    </SortIconWrapper>
                  )}
                </HeaderCellWrapper>
              </ReactTableHeaderColumn>
            ))}
          </ReactTableHeaderRow>
        ))}
      </ReactTableHeader>

      {rows.length ? (
        <ReactTableBody {...getTableBodyProps()}>
          {Object.keys(selectedRowIds).length > 0 && (
            <MultiselectRibbon
              label={`${Object.keys(selectedRowIds).length} selected rows:`}
              actions={[
                {
                  role: 'primary',
                  label: 'Create invoices',
                  disabled:
                    selectedRows.some((row) => !!row?.original?.transactions?.[0]?.invoicing_schedule_id) ||
                    selectedRows.every((row) => row.original?.transactions?.length === 0) ||
                    new Set(selectedRows.map((row) => row.original.customer_id)).size > 1,
                  onClick: () => {
                    openInvoicingScheduleModal({
                      includedTransactions: uniqBy(
                        selectedRows.flatMap((row) => row.original?.transactions ?? []),
                        'id',
                      ),
                      options: {
                        defaultFrequency: selectedRows[0]?.original?.pricing_plan?.invoicing_frequency,
                        autoSetAutoCharge: true,
                      },
                    });

                    toggleAllRowsSelected(false);
                  },
                },
                {
                  label: <TrashIcon size="16px" fill="white" />,
                  isRound: true,
                  onClick: async () => {
                    const subscriptionIds = selectedRows.map((row) => row.original.id);
                    await bulkDeleteUsageSubscriptions.mutateAsync({ ids: subscriptionIds });
                  },
                },
              ]}
              onResetSelection={() => toggleAllRowsSelected(false)}
            />
          )}
          {rows.map((row) => {
            prepareRow(row);
            return (
              <ReactTableRow {...row.getRowProps()} noRowHover>
                {row.cells.map((cell) => (
                  <ReactTableCell {...cell.getCellProps()} customWidth={cell.column.width}>
                    {cell.render('Cell')}
                  </ReactTableCell>
                ))}
              </ReactTableRow>
            );
          })}
        </ReactTableBody>
      ) : (
        <EmptyTableContent>No Usage-based Subscriptions Found</EmptyTableContent>
      )}
    </div>
  );
};
