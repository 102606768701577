import { useState } from 'react';
import Checkbox from 'antd/lib/checkbox/Checkbox';

import { NUMBER_FORMATS } from 'consts/global';
import { numberFormatter } from 'utils/formatters';
import { ReactComponent as TooltipIcon } from 'images/help-table-header.svg';
import { ReactComponent as FillDot } from 'images/fill-dot.svg';
import { ReactComponent as CheckIcon } from 'images/circle-check-csv.svg';
import { ReactTableColumnWithTooltip, RowActionPopover, TableActionCell } from 'components/Table';
import { TooltipContainer, TruncateStringWithTooltip } from 'components/Tooltip';
import { Tooltip } from 'components/Tooltip_NEW';
import { TooltipButton } from 'components/Buttons';
import { getTransactionMRR } from 'shared/TransactionContent/utils';
import { GROUP_BY_OPTIONS } from 'shared/TransactionTable/consts';
import { TransactionRecognitionIcon } from 'shared/Icons';

import TransactionTableGroupHeadRow from './TransactionTableGroupHeadRow';
import { TOOLS_TIP_COLORS, TRANSACTION_FIELD_VALUE_BY_ID, TRANSACTION_TABLE_COLUMN_KEY } from './consts';
import { getTransactionFieldValue } from './utils';
import {
  ToolTipRow,
  DealNameColumn,
  TotalTransactionCount,
  UnassignedCustomerText,
  CustomerName,
  StatusDice,
  ChecksDice,
  ChecksDiceTooltip,
} from './styles';

const rowIsNotGroupHead = (row) => !row.canExpand;

const TransactionToolTipContent = () => (
  <div>
    {Object.keys(TOOLS_TIP_COLORS).map((status) => {
      return (
        <ToolTipRow key={status}>
          <FillDot fill={TOOLS_TIP_COLORS[status]} />
          <span style={{ marginLeft: 10 }}>{status}</span>
        </ToolTipRow>
      );
    })}
  </div>
);

const TransactionsChecksColumn = ({ value, onChecksTooltipClick }) => {
  const [showChecksTooltip, setShowChecksTooltip] = useState(false);
  const handleChecksTooltipClick = () => {
    setShowChecksTooltip(false);
    onChecksTooltipClick();
  };

  return (
    <Tooltip
      visible={showChecksTooltip}
      onVisibleChange={setShowChecksTooltip}
      secondary
      overlay={
        <ChecksDiceTooltip>
          <CheckIcon />
          <span>All checks based on our Transaction Analyzer passed</span>
          <TooltipButton
            data-cy="transaction-table__row__checks-cell--tooltip-about-button"
            onClick={handleChecksTooltipClick}
          >
            More about checks
          </TooltipButton>
        </ChecksDiceTooltip>
      }
      overlayInnerStyle={{
        width: '180px',
      }}
      placement="bottom"
    >
      <ChecksDice data-cy="transaction-table__row__checks-cell">{value}</ChecksDice>
    </Tooltip>
  );
};

export const generateColumns = ({
  onDeleteClick,
  onUnconfirmClick,
  onTransactionClick,
  onCustomerClick,
  transactionGroupBy,
  isARR,
  currencyISOCode,
  showChecksColumn,
  onChecksTooltipClick,
}) => {
  const handleTransactionClick = ({ row }) => {
    if (!row.canExpand) {
      onTransactionClick(row.original);
    }
  };

  const columns = [
    {
      Header: 'ID',
      accessor: TRANSACTION_TABLE_COLUMN_KEY.ID,
      width: 80,
    },
    {
      Header: ({ getToggleAllPageRowsSelectedProps }) => (
        <Checkbox data-cy="transaction-row-checkbox--select-all" {...getToggleAllPageRowsSelectedProps()} />
      ),
      accessor: TRANSACTION_TABLE_COLUMN_KEY.CHECKBOX,
      width: 32,
      Cell: ({ row }) => (
        <Checkbox
          checked={row.isSelected}
          onClick={() => {
            row.toggleRowSelected();
          }}
          data-cy={`transaction-row-checkbox-${row.original.name}-${row.original.customer_name}`}
        />
      ),
      noInitialSortBy: true,
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: () => (
        <ReactTableColumnWithTooltip>
          <TooltipContainer width={128} isVisible={true} underline={false} toolTipContent={TransactionToolTipContent()}>
            <TooltipIcon />
          </TooltipContainer>
          Date
        </ReactTableColumnWithTooltip>
      ),
      width: 160,
      accessor: TRANSACTION_TABLE_COLUMN_KEY.DATE,
      Cell: ({ row }) => (
        <TransactionTableGroupHeadRow
          row={row}
          value={getTransactionFieldValue({
            key: TRANSACTION_FIELD_VALUE_BY_ID[TRANSACTION_TABLE_COLUMN_KEY.DATE],
            transaction: row.original,
          })}
        />
      ),
    },
    {
      Header: 'Deal name',
      accessor: TRANSACTION_TABLE_COLUMN_KEY.DEAL_NAME,
      width: 265,
      Cell: ({ row }) => {
        const dealNameValue = getTransactionFieldValue({
          key: TRANSACTION_FIELD_VALUE_BY_ID[TRANSACTION_TABLE_COLUMN_KEY.DEAL_NAME],
          transaction: row.original,
        });

        return (
          <DealNameColumn onClick={() => handleTransactionClick({ row })} data-cy="transaction-table__name-column">
            {rowIsNotGroupHead(row) && dealNameValue}
          </DealNameColumn>
        );
      },
    },
    {
      Header: 'Status',
      accessor: TRANSACTION_TABLE_COLUMN_KEY.STATUS,
      width: 130,
      Cell: ({ row }) => {
        const statusValue = getTransactionFieldValue({
          key: TRANSACTION_FIELD_VALUE_BY_ID[TRANSACTION_TABLE_COLUMN_KEY.STATUS],
          transaction: row.original,
        });

        return !row.canExpand ? <StatusDice status={statusValue}>{statusValue}</StatusDice> : null;
      },
    },
    {
      Header: 'External ID',
      accessor: TRANSACTION_TABLE_COLUMN_KEY.EXTERNAL_ID,
      width: 250,
      Cell: ({ row }) => {
        const externalIDValue = getTransactionFieldValue({
          key: TRANSACTION_FIELD_VALUE_BY_ID[TRANSACTION_TABLE_COLUMN_KEY.EXTERNAL_ID],
          transaction: row.original,
        });

        return <>{rowIsNotGroupHead(row) && (externalIDValue ?? '(none)')} </>;
      },
    },
    {
      Header: 'Customer',
      accessor: TRANSACTION_TABLE_COLUMN_KEY.CUSTOMER,
      width: 265,
      Cell: ({ row }) =>
        row.original.customer_id ? (
          <CustomerName
            onClick={() => {
              onCustomerClick(row.original.customer_id);
            }}
            data-cy="transaction-table__row__customer-cell"
          >
            {rowIsNotGroupHead(row) &&
              getTransactionFieldValue({
                key: TRANSACTION_FIELD_VALUE_BY_ID[TRANSACTION_TABLE_COLUMN_KEY.CUSTOMER],
                transaction: row.original,
              })}
          </CustomerName>
        ) : (
          <UnassignedCustomerText>
            {getTransactionFieldValue({
              key: TRANSACTION_FIELD_VALUE_BY_ID[TRANSACTION_TABLE_COLUMN_KEY.CUSTOMER],
              transaction: row.original,
            })}
          </UnassignedCustomerText>
        ),
    },
    {
      Header: () => (
        <TooltipContainer
          width={100}
          isVisible={true}
          underline={false}
          toolTipContent={`${isARR ? 'Annual' : 'Monthly'} Recurring Revenue`}
        >
          <span>RR</span>
        </TooltipContainer>
      ),
      accessor: TRANSACTION_TABLE_COLUMN_KEY.RECURRING_REVENUE,
      width: 80,
      sortType: (rowA, rowB) =>
        transactionGroupBy === GROUP_BY_OPTIONS.CUSTOMER
          ? rowA.original.recurring_revenue - rowB.original.recurring_revenue
          : getTransactionMRR(rowA.original) - getTransactionMRR(rowB.original),
      Cell: ({ row }) => {
        const recurringRevenue = getTransactionMRR(row.original);
        return (
          !row.canExpand && (
            <div
              style={{ fontWeight: 700, position: 'relative' }}
              data-cy="transaction-table__row__recurring-amount-cell"
            >
              {numberFormatter({
                type: NUMBER_FORMATS.CURRENCY,
                rawValue: isARR ? recurringRevenue * 12 : recurringRevenue,
                currency: row.original.currency ?? currencyISOCode,
              })}
            </div>
          )
        );
      },
      disableSortBy: true, // sorting is done on backend, but this value is calculated on the frontend
    },
    {
      Header: 'Total',
      accessor: TRANSACTION_TABLE_COLUMN_KEY.AMOUNT,
      width: 80,
      sortType: (rowA, rowB) => rowA.original.amount - rowB.original.amount,
      Cell: ({ row }) =>
        !row.canExpand && (
          <div style={{ fontWeight: 700, position: 'relative' }} data-cy="transaction-table__row__total-cell">
            {getTransactionFieldValue({
              key: TRANSACTION_FIELD_VALUE_BY_ID[TRANSACTION_TABLE_COLUMN_KEY.AMOUNT],
              transaction: row.original,
              currency: row.original.currency ?? currencyISOCode,
            })}
          </div>
        ),
    },
    {
      Header: 'Seats',
      accessor: TRANSACTION_TABLE_COLUMN_KEY.SEATS,
      width: 80,
      Cell: ({ row }) => (
        <div style={{ fontWeight: 700, position: 'relative' }}>
          {getTransactionFieldValue({
            key: TRANSACTION_FIELD_VALUE_BY_ID[TRANSACTION_TABLE_COLUMN_KEY.SEATS],
            transaction: row.original,
          })}
        </div>
      ),
    },
    {
      Header: 'type',
      accessor: TRANSACTION_TABLE_COLUMN_KEY.RECOGNITION_TYPE,
      width: 60,
      Cell: ({ row }) => (
        <TransactionRecognitionIcon
          type={getTransactionFieldValue({
            key: TRANSACTION_FIELD_VALUE_BY_ID[TRANSACTION_TABLE_COLUMN_KEY.RECOGNITION_TYPE],
            transaction: row.original,
          })}
        />
      ),
    },
    {
      Header: 'Product',
      accessor: TRANSACTION_TABLE_COLUMN_KEY.PRODUCT,
      width: 130,
      Cell: ({ row }) =>
        rowIsNotGroupHead(row) && (
          <TruncateStringWithTooltip length={10}>
            {getTransactionFieldValue({
              key: TRANSACTION_FIELD_VALUE_BY_ID[TRANSACTION_TABLE_COLUMN_KEY.PRODUCT],
              transaction: row.original,
            })}
          </TruncateStringWithTooltip>
        ),
    },
    {
      Header: 'Start',
      accessor: TRANSACTION_TABLE_COLUMN_KEY.START_DATE,
      width: 90,
      Cell: ({ row }) => (
        <div>
          {rowIsNotGroupHead(row) &&
            getTransactionFieldValue({
              key: TRANSACTION_FIELD_VALUE_BY_ID[TRANSACTION_TABLE_COLUMN_KEY.START_DATE],
              transaction: row.original,
            })}
        </div>
      ),
    },
    {
      Header: 'End',
      accessor: TRANSACTION_TABLE_COLUMN_KEY.END_DATE,
      width: 90,
      Cell: ({ row }) => (
        <div>
          {rowIsNotGroupHead(row) &&
            getTransactionFieldValue({
              key: TRANSACTION_FIELD_VALUE_BY_ID[TRANSACTION_TABLE_COLUMN_KEY.END_DATE],
              transaction: row.original,
            })}
        </div>
      ),
    },
    {
      Header: 'Updated at',
      accessor: TRANSACTION_TABLE_COLUMN_KEY.UPDATED_AT,
      width: 120,
      Cell: ({ row }) => (
        <div>
          {rowIsNotGroupHead(row) &&
            getTransactionFieldValue({
              key: TRANSACTION_FIELD_VALUE_BY_ID[TRANSACTION_TABLE_COLUMN_KEY.UPDATED_AT],
              transaction: row.original,
            })}
        </div>
      ),
    },
    {
      Header: 'Created at',
      accessor: TRANSACTION_TABLE_COLUMN_KEY.CREATED_AT,
      width: 120,
      Cell: ({ row }) => (
        <div>
          {rowIsNotGroupHead(row) &&
            getTransactionFieldValue({
              key: TRANSACTION_FIELD_VALUE_BY_ID[TRANSACTION_TABLE_COLUMN_KEY.CREATED_AT],
              transaction: row.original,
            })}
        </div>
      ),
    },
    {
      Header: 'Left to recognize',
      accessor: TRANSACTION_TABLE_COLUMN_KEY.LEFT_TO_RECOGNIZE,
      width: 160,
      Cell: ({ row }) => {
        const leftToRecognizeValue = getTransactionFieldValue({
          key: TRANSACTION_FIELD_VALUE_BY_ID[TRANSACTION_TABLE_COLUMN_KEY.LEFT_TO_RECOGNIZE],
          transaction: row.original,
          currency: row.original.currency ?? currencyISOCode,
        });
        return <div style={{ fontWeight: 700 }}>{leftToRecognizeValue}</div>;
      },
      disableSortBy: true, // sorting is done on backend, but this value is calculated on the frontend
    },
    {
      accessor: 'actions',
      disableSortBy: true,
      width: 65,
      Cell: ({ row }) => {
        return !row.canExpand ? (
          <TableActionCell>
            <RowActionPopover
              currentSelection={row.original}
              onEditClick={(row) => onTransactionClick(row)}
              onDeleteClick={row.original.archived_at ? null : (transaction) => onDeleteClick({ transaction })}
              onUnconfirmClick={
                row.original.confirmed && !row.original.archived_at
                  ? (transaction) => onUnconfirmClick({ transaction })
                  : null
              }
              editText={
                row.original.archived_at ? 'Restore & edit' : row.original.confirmed ? 'Edit' : 'Edit & Confirm'
              }
              isLocked={row.original.is_locked}
              dataCyPrefix={'transactions'}
            />
          </TableActionCell>
        ) : (
          <TableActionCell>
            <TotalTransactionCount>{row.subRows.length} trans.</TotalTransactionCount>
          </TableActionCell>
        );
      },
    },
  ];

  // For now we only want to add it on the "Automatic Confirmations" page
  if (showChecksColumn) {
    columns.splice(4, 0, {
      Header: 'Checks',
      accessor: TRANSACTION_TABLE_COLUMN_KEY.CHECKS,
      width: 130,
      Cell: ({ row }) => {
        const checksValue = getTransactionFieldValue({
          key: TRANSACTION_FIELD_VALUE_BY_ID[TRANSACTION_TABLE_COLUMN_KEY.CHECKS],
          transaction: row.original,
        });

        return !row.canExpand && checksValue ? (
          <TransactionsChecksColumn value={checksValue} onChecksTooltipClick={onChecksTooltipClick} />
        ) : null;
      },
    });
  }

  return columns;
};
