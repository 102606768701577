import React, { useMemo, useContext } from 'react';
import dayjs from 'dayjs';
import { AppContext } from 'AppContext';
import styled from 'styled-components';
import { useTable } from 'react-table';
import { NUMBER_FORMATS } from 'consts/global';
import { numberFormatter } from 'utils/formatters';
import { ReactComponent as ArrowRight } from 'images/bx-right-arrow-alt.svg';
import { ArrCell } from 'views/Customers/styles';
import { TooltipContainer } from 'components/Tooltip';
import {
  ReactTableBody,
  ReactTableHeader,
  ReactTableHeaderColumn,
  ReactTableHeaderRow,
  ReactTableCell,
  ReactTableRow,
} from 'components/Table';
import { CentererVertical, FlexerColumn } from 'components/Core';
import { SwitchWithLabel } from 'components/Controls';

var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

const TableContainer = styled.div`
  width: 100%;
  margin-top: 32px;
`;

const Customer = styled.span`
  cursor: pointer;
  color: var(--primaryBlue);
  font-weight: 700;
`;

const Dates = styled.span`
  color: var(--primaryBlack50);
`;

export const CustomersTable = ({
  detailsPerCustomer,
  setCustomerId,
  lastMonthDetailsPerCustomer,
  showCustomersWithDeferred = false,
  setShowCustomersWithDeferred,
  showCustomersWithAccrued = false,
  setShowCustomersWithAccrued,
  showCustomersWithAccountingRevenue = false,
  setShowCustomersWithAccountingRevenue,
  showCustomersWithInvoiced = false,
  setShowCustomersWithInvoiced,
  accountingCurrency,
}) => {
  const { formatDateWithLocale } = useContext(AppContext);

  const dataForTable = useMemo(() => {
    return Object.values(detailsPerCustomer).filter((c) => {
      if (showCustomersWithAccrued && c.accruedRevenue === 0) {
        return false;
      } else if (showCustomersWithDeferred && c.deferredRevenue === 0) {
        return false;
      } else if (showCustomersWithAccountingRevenue && c.accountingRevenue === 0) {
        return false;
      } else if (showCustomersWithInvoiced && c.invoiced === 0) {
        return false;
      }
      return true;
    });
  }, [
    detailsPerCustomer,
    showCustomersWithDeferred,
    showCustomersWithAccrued,
    showCustomersWithAccountingRevenue,
    showCustomersWithInvoiced,
  ]);

  const columns = useMemo(() => {
    const generateChangingValue = (value, previous) => (
      <ArrCell>
        <TooltipContainer width={200} toolTipContent={`Previous month value`}>
          <span>
            {numberFormatter({
              rawValue: previous ?? 0,
              type: NUMBER_FORMATS.CURRENCY,
              currency: accountingCurrency,
              decimalPlaces: 2,
            })}
          </span>
        </TooltipContainer>
        <ArrowRight />
        <span>
          {numberFormatter({
            rawValue: value,
            type: NUMBER_FORMATS.CURRENCY,
            currency: accountingCurrency,
            decimalPlaces: 2,
          })}
        </span>
      </ArrCell>
    );
    return [
      {
        Header: 'CUSTOMER',
        accessor: 'customerName',
        id: 'customerName',
        width: 160,
        Cell: ({ row }) => (
          <Customer onClick={() => setCustomerId(row.original.customerId)}>{row.original.customerName}</Customer>
        ),
      },
      {
        Header: 'DATES',
        accessor: 'startDate',
        id: 'dates',
        width: 160,
        Cell: ({ row }) => {
          const startDate = formatDateWithLocale(row.original.startDate);
          // end_date is only required for linear transactions so if there is no end_date, then put "No end date"
          const endDate = row.original.endDate ? formatDateWithLocale(row.original.endDate) : 'No end date';

          return <Dates>{`${startDate} - ${endDate}`}</Dates>;
        },
      },
      {
        Header: 'TRANS.',
        accessor: 'transactionsCount',
        id: 'transactionsCount',
        width: 20,
        alignRight: true,
        Cell: ({ value }) => value,
      },
      {
        Header: 'ACCOUNTING REV.',
        accessor: 'accountingRevenue',
        id: 'accountingRevenue',
        width: 180,
        alignRight: true,
        Cell: ({ cell: { value }, row }) => {
          const previous = lastMonthDetailsPerCustomer?.[row.original.customerId]?.accountingRevenue;
          return generateChangingValue(value, previous);
        },
      },
      {
        Header: 'INVOICED THIS MONTH',
        accessor: 'invoiced',
        id: 'invoiced',
        width: 100,
        alignRight: true,
        Cell: ({ cell: { value } }) =>
          numberFormatter({
            rawValue: value,
            type: NUMBER_FORMATS.CURRENCY,
            currency: accountingCurrency,
            decimalPlaces: 2,
          }),
      },
      {
        Header: 'TOTAL INVOICED',
        accessor: 'totalInvoiced',
        id: 'totalInvoiced',
        width: 180,
        alignRight: true,
        Cell: ({ cell: { value }, row }) => {
          const previous = lastMonthDetailsPerCustomer?.[row.original.customerId]?.totalInvoiced;
          return generateChangingValue(value, previous);
        },
      },
      {
        Header: 'ACCRUED REV.',
        accessor: 'accruedRevenue',
        id: 'accruedRevenue',
        width: 180,
        alignRight: true,
        Cell: ({ cell: { value }, row }) => {
          const previous = lastMonthDetailsPerCustomer?.[row.original.customerId]?.accruedRevenue;
          return generateChangingValue(value, previous);
        },
      },
      {
        Header: 'DEFERRED REV.',
        accessor: 'deferredRevenue',
        id: 'deferredRevenue',
        width: 180,
        alignRight: true,
        Cell: ({ cell: { value }, row }) => {
          const previous = lastMonthDetailsPerCustomer?.[row.original.customerId]?.deferredRevenue;
          return generateChangingValue(value, previous);
        },
      },
    ];
  }, [formatDateWithLocale, accountingCurrency, setCustomerId, lastMonthDetailsPerCustomer]);

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable({
    columns,
    data: dataForTable,
  });

  return (
    <FlexerColumn marginTop="24px">
      <CentererVertical gap="16px" marginLeft="8px">
        <SwitchWithLabel
          size="small"
          label="Customers with accounting revenue"
          onChange={() => {
            setShowCustomersWithAccountingRevenue(!showCustomersWithAccountingRevenue);
          }}
          checked={showCustomersWithAccountingRevenue}
        />
        <SwitchWithLabel
          size="small"
          label="Customers with invoice"
          onChange={() => {
            setShowCustomersWithInvoiced(!showCustomersWithInvoiced);
          }}
          checked={showCustomersWithInvoiced}
        />
        <SwitchWithLabel
          size="small"
          label="Customers with accrued revenue"
          onChange={() => {
            setShowCustomersWithAccrued(!showCustomersWithAccrued);
          }}
          checked={showCustomersWithAccrued}
        />
        <SwitchWithLabel
          size="small"
          label="Customers with deferred revenue"
          onChange={() => {
            setShowCustomersWithDeferred(!showCustomersWithDeferred);
          }}
          checked={showCustomersWithDeferred}
        />
      </CentererVertical>
      <TableContainer {...getTableProps()}>
        <ReactTableHeader backgroundColor="var(--secondaryGray)" top="-40px">
          {headerGroups.map((headerGroup) => (
            <ReactTableHeaderRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <ReactTableHeaderColumn
                  {...column.getHeaderProps()}
                  customWidth={column.width}
                  alignRight={column.alignRight}
                >
                  {column.render('Header')}
                </ReactTableHeaderColumn>
              ))}
            </ReactTableHeaderRow>
          ))}
        </ReactTableHeader>
        {rows.length ? (
          <ReactTableBody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <ReactTableRow {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <ReactTableCell
                      {...cell.getCellProps()}
                      customWidth={cell.column.width}
                      alignRight={cell.column.alignRight}
                    >
                      {cell.render('Cell')}
                    </ReactTableCell>
                  ))}
                </ReactTableRow>
              );
            })}
          </ReactTableBody>
        ) : null}
      </TableContainer>
    </FlexerColumn>
  );
};
