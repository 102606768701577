import { isEmpty } from 'lodash';
import { humanize } from 'utils/stringUtils';
import { INTEGRATION_TYPES, SERVICE_DELIMITER } from 'consts/integrations';

export const DISCONNECTED_CODE = {
  AUTHENTICATION: 'authentication',
};

export const checkIntegrationStatus = ({ integrations }) => {
  const integration = integrations?.find((e) => e.type === 'CRM') || {};
  const { metadata, products } = integration;

  if (!metadata || !products) return true;
  if (isEmpty(metadata) || isEmpty(products)) return true;

  return false;
};

export const getIntegrationName = ({ integrations, type }) => {
  const integration = integrations.find((elem) => elem.type === type && !elem.disconnected_at);
  return integration ? humanize(integration.service) : `your ${type}`;
};

export const isDisauthenticated = (integration) =>
  integration.disconnected_at && integration.metadata?.disconnected_code === DISCONNECTED_CODE.AUTHENTICATION;

export const getServiceCategory = (serviceName) => serviceName?.split(SERVICE_DELIMITER)?.[0] ?? '';

export const getIntegrationByService = ({ integrations, service }) => {
  const integration = integrations.find((elem) => getServiceCategory(elem.service) === service);
  return integration;
};

export const getInvoicingIntegrationService = ({ integrations }) => {
  return getServiceCategory(integrations?.find((i) => i.type === INTEGRATION_TYPES.GL)?.service);
};

export const getIntegrationDisplayName = (integration) =>
  integration?.display_name ?? humanize(integration?.service?.replace(SERVICE_DELIMITER, ' '));

export const getMailerIntegrationService = ({ integrations }) => {
  return getServiceCategory(integrations?.find((i) => i.type === INTEGRATION_TYPES.MAILER)?.service);
};
