import dayjs from 'dayjs';
import { pick } from 'lodash';
import { INVOICE_FORM_FIELDS } from './InvoicingScheduleModal/consts';
import { getInvoiceData } from './InvoiceModal/utils';
import { COMMON_INVOICE_FIELDS } from './consts';

const { convertToPlainText } = require('utils/htmlUtils');

export const markInvoiceAsPaid = ({ editInvoice }) => ({ invoice, paidAt }) =>
  editInvoice({
    id: invoice?.id,
    markAsPaid: true,
    data: {
      ...pick(getInvoiceData(invoice), INVOICE_FORM_FIELDS),
      paid_at: dayjs.utc(paidAt).format('YYYY-MM-DD'),
    },
  });

export const markInvoiceAsUnpaid = ({ editInvoice }) => ({ invoice }) =>
  editInvoice({
    id: invoice?.id,
    data: {
      ...pick(getInvoiceData(invoice), INVOICE_FORM_FIELDS),
      paid_at: null,
    },
  });

export const markInvoiceAsVoid = ({ editInvoice }) => ({ invoice }) =>
  editInvoice({
    id: invoice?.id,
    voidInvoice: true,
  });

export const resetInvoiceMemo = ({ editInvoice, pushToast }) => async ({ invoice, invoiceMemoTemplateId }) => {
  await editInvoice({
    id: invoice?.id,
    resetMemo: true,
    data: {
      invoice_memo_template_id: invoiceMemoTemplateId,
    },
  });
  pushToast(`Updated memo`);
};

export const bulkResetInvoicesMemo = ({ bulkResetMemo }) => async ({ invoices, invoiceMemoTemplateId }) => {
  await bulkResetMemo({
    data: invoices.map((invoice) => ({ ...invoice, invoice_memo_template_id: invoiceMemoTemplateId })),
  });
};

export const resetInvoiceSecondaryMemo = ({ editInvoice, pushToast }) => async ({ invoice, invoiceMemoTemplateId }) => {
  await editInvoice({
    id: invoice?.id,
    resetSecondaryMemo: true,
    data: {
      invoice_secondary_memo_template_id: invoiceMemoTemplateId,
    },
  });
  pushToast(`Updated memo`);
};

export const bulkResetInvoicesSecondaryMemo = ({ bulkResetSecondaryMemo }) => async ({
  invoices,
  invoiceMemoTemplateId,
}) => {
  await bulkResetSecondaryMemo({
    data: invoices.map((invoice) => ({ ...invoice, invoice_secondary_memo_template_id: invoiceMemoTemplateId })),
  });
};

export const resetInvoiceEmailSubject = ({ editInvoice, pushToast }) => async ({ invoice }) => {
  await editInvoice({
    id: invoice?.id,
    resetEmailSubject: true,
  });
  pushToast(`Updated Invoice Subject`);
};

export const resetInvoiceEmailBody = ({ editInvoice, pushToast }) => async ({ invoice }) => {
  await editInvoice({
    id: invoice?.id,
    resetEmailBody: true,
  });
  pushToast(`Updated Invoice Body`);
};

export const editFutureInvoices = ({ editInvoice, pushToast }) => async ({ invoice, invoicingScheduleInvoices }) => {
  // skip "this" invoice, we already edited that one, as well as invoices that have already been paid/sent
  const futureInvoices = (invoicingScheduleInvoices ?? []).filter(
    (inv) => !inv.paid_at && !inv.sent_at && !!inv.id && inv.id !== invoice.id,
  );
  for (const futureInvoice of futureInvoices) {
    // TODO: Use bulk update instead of individual updates
    await editInvoice({
      id: futureInvoice.id,
      data: pick(getInvoiceData(invoice), COMMON_INVOICE_FIELDS),
    });
  }
  pushToast('Successfully updated future invoices!', 'success');
};

export const markInvoiceAsSent = ({ editInvoice }) => async ({ invoice }) =>
  await editInvoice({
    id: invoice?.id,
    markAsSent: true,
    data: pick(getInvoiceData(invoice), INVOICE_FORM_FIELDS),
  });

export const updateInvoice = ({ editInvoice }) => async ({ invoice, ...rest }) =>
  await editInvoice({
    id: invoice?.id,
    data: getInvoiceData(invoice),
    ...rest,
  });

export const testSendInvoice = ({ editInvoice, pushToast }) => async ({ invoice, user }) => {
  const updatedInvoice = await editInvoice({
    id: invoice?.id,
    data: getInvoiceData(invoice),
    testSendInvoice: true,
  });

  if (!!updatedInvoice?.polling_taxes_job_id) {
    pushToast(
      `Test email will be sent as soon as taxes are done syncing. Please check your inbox for ${user?.email} in a few moments.`,
    );
  } else {
    pushToast(`Test email sent! Please check your inbox for ${user?.email}`);
  }
};

export const saveInvoiceWithPreview = ({ editInvoice }) => async ({ invoice }) =>
  await editInvoice({
    id: invoice?.id,
    data: getInvoiceData(invoice),
    showPreview: true,
  });

export const sendInvoice = ({ editInvoice }) => async ({ invoice, integrationId }) => {
  const result = await editInvoice({
    id: invoice?.id,
    data: getInvoiceData(invoice),
    sendInvoice: true,
    integrationId,
  });
  const invoicePaymentLink = result.invoice_payment_link;

  return invoicePaymentLink;
};

export const handleRemindSend = ({ editBillingEmailReminder }) => async ({ updatedEmailReminder }) => {
  const data = {
    ...updatedEmailReminder,
    body: convertToPlainText(updatedEmailReminder.body),
    subject: convertToPlainText(updatedEmailReminder.subject),
  };

  await editBillingEmailReminder({ id: updatedEmailReminder?.id, data, send: true });
};

export const testSendReminder = ({ editBillingEmailReminder }) => async ({ updatedEmailReminder }) => {
  const data = {
    ...updatedEmailReminder,
    body: convertToPlainText(updatedEmailReminder.body),
    subject: convertToPlainText(updatedEmailReminder.subject),
  };

  return await editBillingEmailReminder({ id: updatedEmailReminder?.id, data, send: true, isPreview: true });
};

export const handleRemindEdit = ({ editBillingEmailReminder }) => async ({ updatedEmailReminder }) => {
  const data = {
    ...updatedEmailReminder,
    body: convertToPlainText(updatedEmailReminder.body),
    subject: convertToPlainText(updatedEmailReminder.subject),
  };

  await editBillingEmailReminder({ id: updatedEmailReminder?.id, send: false, data });
};
