import dayjs from 'dayjs';
import styled from 'styled-components';
import { CustomDatePicker, FormikCustomInput } from 'components/Controls';
import { SelectDropdownButton } from 'components/Buttons';
import { Centerer, Column, Spacer } from 'components/Core';
import { CirclePlusIcon } from 'components/Icons';
import { ColumnMapperSelector } from 'shared/CsvUpload/CsvTable/CsvTable';
import { ISO_CODE_TO_SYMBOL } from 'consts/global';
import { TooltipContainer } from 'components/Tooltip';
import { HeaderSelector, SelectorTitle } from 'shared/CsvUpload/styles';

const ButtonWrapper = styled(Centerer)`
  background: var(--primaryBlue5);
  border-radius: 8px;
  cursor: pointer;
  padding: 6px;

  width: 36px;
  height: 36px;

  &:hover {
    background: var(--primaryBlue10);
  }
`;

export const getColumns = ({
  formRef,
  csvColumns,
  fromCurrencies,
  setFromCurrencies,
  csvColumnsMapper,
  setCsvColumnsMapper,
}) => {
  return [
    {
      Header: () => (
        <Column>
          <Spacer height="30px" width="1px" />

          <TooltipContainer width={150} toolTipContent="Add currency">
            <ButtonWrapper
              data-cy="rates-csv__add-currency"
              onClick={() =>
                setFromCurrencies((prevValue) => {
                  const currencies = Object.values(prevValue);
                  return {
                    ...prevValue,
                    [`currency_${currencies?.length + 1}`]: Object.keys(ISO_CODE_TO_SYMBOL).filter(
                      (currency) => !currencies?.includes(currency),
                    )?.[0],
                  };
                })
              }
            >
              <CirclePlusIcon color="var(--primaryBlue)" />
            </ButtonWrapper>
          </TooltipContainer>
        </Column>
      ),
      width: 30,
      accessor: 'status',
      Cell: () => <></>,
    },
    {
      Header: () => (
        <HeaderSelector data-cy="upload-csv__header-selector--date">
          <SelectorTitle>Date</SelectorTitle>
          <ColumnMapperSelector
            field="date"
            csvColumnsMapper={csvColumnsMapper}
            setCsvColumnsMapper={setCsvColumnsMapper}
            csvColumns={csvColumns}
          />
        </HeaderSelector>
      ),
      accessor: 'date',
      width: 180,
      Cell: ({ row, cell: { value } }) => (
        <CustomDatePicker
          offsetContainer={false}
          errorWithoutTooltip
          formik
          height="32px"
          onChange={formRef?.current && formRef?.current?.setFieldValue}
          selected={value && dayjs(value).isValid() ? dayjs(value).toDate() : ''}
          meta={formRef?.current && formRef?.current?.getFieldMeta(`[${row.original.rowIndex}].date`)}
          width="100%"
          name={`[${row.original.rowIndex}].date`}
        />
      ),
    },
    ...(Object.entries(fromCurrencies)?.map(([currencyIndexKey, currency]) => {
      return {
        Header: () => (
          <HeaderSelector>
            <SelectDropdownButton
              name={`${currencyIndexKey}__currency`}
              selected={fromCurrencies?.[currencyIndexKey]}
              noMargin
              options={Object.keys(ISO_CODE_TO_SYMBOL)
                .sort()
                .reduce((acc, key) => {
                  acc[key] = key;
                  return acc;
                }, {})}
              fontSize="12px"
              onSelect={(newValue) =>
                !Object.values(fromCurrencies).includes(newValue) &&
                setFromCurrencies((oldValue) => ({ ...oldValue, [currencyIndexKey]: newValue }))
              }
              toolTipContent="Click to select currency"
              toolTipWidth="135px"
            />

            <Spacer height="6px" />

            <ColumnMapperSelector
              id={`csv-column-${currency}`}
              field={currency}
              csvColumnsMapper={csvColumnsMapper}
              setCsvColumnsMapper={setCsvColumnsMapper}
              csvColumns={csvColumns}
            />
          </HeaderSelector>
        ),
        width: 180,
        accessor: currency ?? currencyIndexKey,
        Cell: ({ row }) => (
          <FormikCustomInput
            style={{ height: 32 }}
            errorWithoutTooltip
            placeholder="Enter value..."
            width="100%"
            name={`[${row.original.rowIndex}].${currency}`}
          />
        ),
      };
    }) ?? []),
  ];
};
