import React from 'react';
import styled from 'styled-components';
import { ExternalLinkIcon } from 'components/Icons';
import { TooltipContainer } from 'components/Tooltip';
import { CellWithIcon } from 'components/Table/CompactNumericTable';

const Name = styled.span`
  &:hover {
    cursor: pointer;
  }
`;

export const CustomerNameCell = ({ children, externalServiceName = 'your CRM', externalUrl, onCustomerClick }) => (
  <CellWithIcon>
    {onCustomerClick ? (
      <TooltipContainer width={220} fontSize="12px" toolTipContent="View this customer on Subscript">
        <Name onClick={onCustomerClick}>{children}</Name>
      </TooltipContainer>
    ) : (
      children
    )}
    {externalUrl && (
      <TooltipContainer width={220} fontSize="12px" toolTipContent={`View this customer on ${externalServiceName}`}>
        <ExternalLinkIcon size="14px" fill="var(--primaryBlue50)" onClick={() => window.open(externalUrl, '_blank')} />
      </TooltipContainer>
    )}
  </CellWithIcon>
);
