import styled from 'styled-components';
import { FlexBetweenContainer, Flexer } from 'components/Core';
import { FillDot } from 'components/Icons';
import { NUMBER_FORMATS } from 'consts/global';
import { useCurrencyNumberFormatter } from 'utils/hooks';

const BannerWrapper = styled.div`
  padding: 8px 40px;
  background: var(--primaryGray);
`;

const BannerContents = styled(FlexBetweenContainer)`
  align-items: center;
  width: 100%;
  padding: 20px 0 40px 0;
`;

const Column = styled(Flexer)`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--primaryBlack50);
  padding: 0 20px;
`;

const Line = styled.div`
  flex: 1;
  height: 1px;
  background: var(--accentGraySecond);
`;

const RevenueValue = styled.div`
  color: var(--primaryBlack);
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin-left: 8px;
`;

export const SplashBannerDetails = ({ upsell, downsell, churn }) => {
  const numberFormatter = useCurrencyNumberFormatter();
  return (
    <BannerWrapper>
      <BannerContents>
        <Column>Possible revenue waterfall</Column>
        <Line />
        <Column>
          Upsell:
          <RevenueValue>{numberFormatter({ type: NUMBER_FORMATS.CURRENCY, rawValue: upsell })}</RevenueValue>
        </Column>
        <FillDot width={4} height={4} fill="var(--primaryBlack10)" />
        <Column>
          Downsell:
          <RevenueValue>{numberFormatter({ type: NUMBER_FORMATS.CURRENCY, rawValue: downsell })}</RevenueValue>
        </Column>
        <FillDot width={4} height={4} fill="var(--primaryBlack10)" />
        <Column>
          Churn:
          <RevenueValue>{numberFormatter({ type: NUMBER_FORMATS.CURRENCY, rawValue: churn })}</RevenueValue>
        </Column>
      </BannerContents>
    </BannerWrapper>
  );
};
