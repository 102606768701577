import React from 'react';
import { SelectDropdownButton } from 'components/Buttons';
import {
  ARR_BOOLEAN_TO_SELECTOR,
  ARR_SELECTOR_TO_BOOLEAN,
  STORAGE_TOGGLES_KEYS,
  TOGGLES_SELECTORS_KEYS,
} from './consts';

export const GlobalToggleIsARR = ({ globalTogglesState, setGlobalTogglesState }) => (
  <SelectDropdownButton
    showSelectedDirectly
    name={STORAGE_TOGGLES_KEYS.IS_ARR}
    options={{
      [TOGGLES_SELECTORS_KEYS.ARR]: 'Annual recurring revenue',
      [TOGGLES_SELECTORS_KEYS.MRR]: 'Monthly recurring revenue',
    }}
    selected={ARR_BOOLEAN_TO_SELECTOR[globalTogglesState[STORAGE_TOGGLES_KEYS.IS_ARR]]}
    onSelect={(key) =>
      setGlobalTogglesState({ name: STORAGE_TOGGLES_KEYS.IS_ARR, value: ARR_SELECTOR_TO_BOOLEAN[key] })
    }
  />
);
