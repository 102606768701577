import dayjs from 'dayjs';
import { getInvoicesTotalAmount } from 'views/Billing/utils';

export const getTableScheduleData = (data) => {
  const returned = {};

  returned.start_date = data?.updatedInvoices?.[0]?.date ?? data?.invoices?.[0]?.date;
  returned.end_date = data?.updatedInvoices
    ? data?.updatedInvoices?.length > 1
      ? data?.updatedInvoices?.[data?.updatedInvoices?.length - 1]?.date
      : null
    : data?.invoices?.length > 1
    ? data?.invoices?.[data?.invoices?.length - 1]?.date
    : null;

  returned.amount = getInvoicesTotalAmount({ invoices: data?.updatedInvoices ?? data?.invoices ?? [] });

  returned.transactions = [
    ...new Set(
      (data?.updatedInvoices ?? data?.invoices ?? []).reduce((sceduleItems, acc) => {
        return sceduleItems.concat(
          acc?.invoice_items.reduce((invoiceItems, invoiceItem) => {
            if (invoiceItem?.transaction_id) {
              invoiceItems.push(invoiceItem?.transaction_id);
            }
            return invoiceItems;
          }, []),
        );
      }, []),
    ),
  ]?.filter(Boolean);

  return { ...data, ...returned };
};

export const formatExternalUpdateInvoiceDate = (date) => {
  if (date === undefined || date === null) return 'No invoice';
  return dayjs.utc(date).format('YYYY-MM-DD');
};
