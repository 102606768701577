import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { IconButton } from 'components/Buttons';
import {
  Popover,
  PopoverItemsContainer,
  PopoverListItemFull,
  PopoverListItemIconFull,
  PopoverListItemTitleFull,
  PopoverWrapper,
} from 'components/Portal';
import { useClickOutside } from 'utils/hooks';
import { ReactComponent as ArrowDown } from 'images/chevron-down.svg';
import { CirclePlusIcon } from 'components/Icons';

const StyledArrow = styled(ArrowDown)`
  margin-left: 8px;
  transform: ${({ active }) => active && 'rotate(180deg)'};
`;

export const UploadActionsDropdown = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const toggleDropdown = () => setShowDropdown(!showDropdown);

  const history = useHistory();

  const dropdownRef = useClickOutside(() => setShowDropdown(false));

  return (
    <PopoverWrapper ref={dropdownRef}>
      <IconButton
        data-cy="usage-based__upload"
        iconFillColor="var(--primaryGreen)"
        active={!showDropdown}
        onClick={toggleDropdown}
      >
        Import
        <StyledArrow active={showDropdown} />
      </IconButton>
      {showDropdown && (
        <Popover width="160px">
          <PopoverItemsContainer>
            <PopoverListItemFull
              gap="8px"
              data-cy="usage-based__upload--subscription"
              onClick={() => history.push('/csv/subscriptions')}
            >
              <PopoverListItemIconFull>
                <CirclePlusIcon color="var(--primaryBlack50)" />
              </PopoverListItemIconFull>
              <PopoverListItemTitleFull>Subscriptions</PopoverListItemTitleFull>
            </PopoverListItemFull>

            <PopoverListItemFull
              gap="8px"
              data-cy="usage-based__upload--pricing_plan"
              onClick={() => history.push('/csv/pricing-plans')}
            >
              <PopoverListItemIconFull>
                <CirclePlusIcon color="var(--primaryBlack50)" />
              </PopoverListItemIconFull>
              <PopoverListItemTitleFull>Pricing Plans</PopoverListItemTitleFull>
            </PopoverListItemFull>

            <PopoverListItemFull
              gap="8px"
              data-cy="usage-based__upload--event"
              onClick={() => history.push('/csv/usage-events')}
            >
              <PopoverListItemIconFull>
                <CirclePlusIcon color="var(--primaryBlack50)" />
              </PopoverListItemIconFull>
              <PopoverListItemTitleFull>Usage Events</PopoverListItemTitleFull>
            </PopoverListItemFull>
          </PopoverItemsContainer>
        </Popover>
      )}
    </PopoverWrapper>
  );
};
