import { AppContext } from 'AppContext';
import React, { useContext } from 'react';
import { useHistory, useParams } from 'react-router';
import { TransactionSingleContent, TRANSACTION_MODAL_MODE, TRANSACTION_VIEW_MODE } from 'shared/TransactionContent';

export const TransactionSinglePage = () => {
  const { organizations } = useContext(AppContext);
  const { id } = useParams();

  const history = useHistory();

  const handleTransactionDeleted = () => {
    history.push('/transactions');
  };

  return (
    <TransactionSingleContent
      view={TRANSACTION_VIEW_MODE.PAGE}
      mode={TRANSACTION_MODAL_MODE.EDIT}
      transaction={{ id }}
      organization={organizations[0]}
      onTransactionDeleted={handleTransactionDeleted}
    />
  );
};
