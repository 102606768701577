import { SNAPSHOT_METRICS } from 'consts/snapshotMetrics';

export const DEFAULT_GRAPH_LABELS = ['Jan 2020', 'Apr 2020', 'Jul 2020', 'Oct 2020'];

export const GROWTH_METRICS = [SNAPSHOT_METRICS.RECURRING_PERIOD_GROWTH.key, SNAPSHOT_METRICS.TOTAL_PERIOD_GROWTH.key];

export const DASHBOARD_CHART_CARDS_METRICS = [
  'CUSTOMERS',
  'CUSTOMERS_WITH_OPEN_CONTRACTS',
  'RECURRING_REVENUE',
  'RECURRING_PERIOD_GROWTH',
  'TOTAL_PERIOD_GROWTH',
  'GROSS_REVENUE_RETENTION',
  'NET_REVENUE_RETENTION',
  'GROSS_CUSTOMER_RETENTION',
  'LTV',
  'SEATS',
  'ARPA',
  'BURN_MULTIPLE',
  'RULE_OF_40_TTM',
  'PAYBACK_PERIOD',
  'NET_NEW_REVENUE',
];
