import React from 'react';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { pluralize } from 'utils/stringUtils';
import { ReactComponent as ArrowRight } from 'images/arrow-narrow-right.svg';
import { Spacer, Centerer, FlexerRow } from 'components/Core';
import { FlexerColumn } from 'components/Core';
import { Modal, ModalBody, ModalButton, ModalCloseIcon, ModalContainer, ModalHeader } from 'components/Modal';
import { TextBox } from 'components/Core/Text';
import { AllDoneCheckmark } from 'components/Icons/AllDoneCheckmark';
import { useInvoicingScheduleModal } from 'views/Billing/InvoicingScheduleModal';
import { numberFormatter } from 'utils/formatters';
import { NUMBER_FORMATS } from 'consts/global';

const AllDoneCheckmarkContainer = styled(Centerer)`
  width: 50px;
  height: 50px;

  background: var(--primaryBlack10);
  box-shadow: 0px 4px 10px var(--primaryBlack10);

  border: 1px solid var(--primaryBlack10);
  border-radius: 100px;
`;

const TableContainer = styled(FlexerColumn)`
  border-radius: 16px;
  border: 1px solid #f3f3f3;
  background: #fff;
`;

const GreenArrowRight = styled(ArrowRight)`
  g {
    opacity: 1;
  }
  path {
    fill: var(--primaryGreen);
  }
`;

const RowWrapper = styled(FlexerRow)`
  cursor: pointer;
`;

const NameWrapper = styled(FlexerRow)`
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: var(--primaryBlue);

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ContractTransactionsCreatedModal = ({ data, onClose }) => {
  const { openModal: openInvoicingScheduleModal, Modal: InvoicingScheduleModal } = useInvoicingScheduleModal({
    onClose,
  });
  return (
    <>
      <ModalContainer data-cy="contract-files-modal">
        <Modal width="60vw" height="60vh" overflow="visible" padding="0px">
          <ModalHeader addPadding>
            <ModalCloseIcon onClose={onClose} />
          </ModalHeader>

          <ModalBody>
            <FlexerColumn height="100%" alignItems="center" justifyContent="space-between">
              <AllDoneCheckmarkContainer>
                <AllDoneCheckmark />
              </AllDoneCheckmarkContainer>
              <FlexerColumn alignItems="center">
                <TextBox bold>Subscript AI has created</TextBox>
                <TextBox bold italic opacity="0.5">
                  {pluralize(data?.length, 'transaction')}
                </TextBox>
              </FlexerColumn>
              <TableContainer width="100%" padding="20px">
                {data?.map((transaction) => {
                  return (
                    <RowWrapper width="100%" onClick={() => window.open(`/transactions/${transaction.id}`, '_blank')}>
                      <NameWrapper width="40%">{transaction.name}</NameWrapper>
                      <FlexerRow width="20%">
                        {dayjs.utc(transaction.start_date ?? transaction.date).format('YYYY-MM-DD')}
                      </FlexerRow>
                      <FlexerRow width="30%" justifyContent="flex-end">
                        {numberFormatter({
                          type: NUMBER_FORMATS.CURRENCY,
                          rawValue: transaction.amount,
                          decimalPlaces: 2,
                        })}
                      </FlexerRow>
                      <FlexerRow width="10%" justifyContent="flex-end">
                        <ArrowRight />
                      </FlexerRow>
                    </RowWrapper>
                  );
                })}
              </TableContainer>

              <TextBox italic opacity="0.5" textAlign="center">
                If you also would like to generate invoices, simply click on the button below to get started immediately
              </TextBox>

              <ModalButton
                onClick={() => {
                  openInvoicingScheduleModal({
                    includedTransactions: data,
                  });
                }}
              >
                Create Invoices
                <Spacer width="5px" /> <GreenArrowRight />
              </ModalButton>
            </FlexerColumn>
          </ModalBody>
          <Spacer height="10px" />
        </Modal>
      </ModalContainer>

      <InvoicingScheduleModal />
    </>
  );
};
