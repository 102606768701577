import { FlexBetweenContainer } from 'components/Core';
import styled, { css } from 'styled-components';

export const TabsWrapper = styled(FlexBetweenContainer)`
  gap: 8px;
  padding: 20px;
  border-radius: 16px;
  border: 1px solid var(--accentGraySecond);
  background-color: white;
`;

export const TabItemWrapper = styled(FlexBetweenContainer)`
  padding: 12px;
  border-radius: 12px;
  border: 1px solid var(--accentGraySecond);
  box-sizing: border-box;
  flex-grow: 1;
  color: var(--primaryBlack);
  font-weight: 700;
  font-size: 14px;

  &:hover {
    cursor: pointer;
    background-color: var(--primaryBlack3);
  }

  & svg {
    width: 20px;
    height: 20px;

    path {
      fill: var(--primaryBlack30);
    }
    g {
      opacity: 1;
    }
  }

  ${({ selected }) =>
    selected &&
    css`
      background-color: var(--primaryBlue10);
      border: none;
      color: var(--primaryBlue);

      & svg {
        path {
          fill: var(--primaryBlue);
        }
      }

      &:hover {
        cursor: pointer;
        background-color: var(--primaryBlue10);
      }
    `}
`;
