import React from 'react';

import { FlexerRow } from 'components/Core';
import { FormikCustomSelector } from 'components/Controls';
import { ModalBody } from 'components/Modal';
import { TemplateEmailRecipients } from './TemplateEmailRecipients';

export const TemplateAIModalBody = ({ values, setFieldValue, canEditTemplateFrequency }) => {
  const FREQUENCY_OPTIONS = [
    // TODO: Make this dynamic
    { label: '3 days', value: 3 },
    { label: '5 days', value: 5 },
    { label: '1 week', value: 7 },
    { label: '2 weeks', value: 14 },
  ];
  const REMINDER_OPTIONS = [
    { label: 'Generate reminders by the Subscript AI', value: 'Generate reminders by the Subscript AI' },
  ];

  return (
    <ModalBody paddingBottom="28px">
      {canEditTemplateFrequency && (
        <FlexerRow gap="16px" alignItems="center" width="100%">
          <div style={{ flex: 1 }}>
            <FormikCustomSelector name="ai_reminder" options={REMINDER_OPTIONS} value={REMINDER_OPTIONS[0]} />
          </div>
          <span>every</span>
          <FormikCustomSelector name="days_from_due_date" options={FREQUENCY_OPTIONS} width="140px" />
        </FlexerRow>
      )}

      <TemplateEmailRecipients values={values} setFieldValue={setFieldValue} />
    </ModalBody>
  );
};
