import React, { useEffect } from 'react';
import { useTable, useExpanded, useGridLayout } from 'react-table';
import { ReactTableDashboardCell, ReactTableDashboardHeaderColumn, WaterfallTable } from './styles';

export const Table = ({ tableId, columns, data, tableRef, hideHeaderRow = false }) => {
  const { getTableProps, headerGroups, rows, prepareRow, toggleAllRowsExpanded } = useTable(
    {
      columns,
      data: data?.rows,
    },
    useExpanded,
    useGridLayout,
  );

  //Expand all rows by default
  useEffect(() => {
    toggleAllRowsExpanded(true);
  }, [data, toggleAllRowsExpanded]);

  return (
    <>
      <WaterfallTable ref={tableRef} id={tableId}>
        <div
          {...getTableProps()}
          style={{
            display: 'grid',
            width: 'max-content',
            // The useGridLayout hook does not recalculate the number of columns after changing them (we can change it in settings modal),
            // so we do it manually
            gridTemplateColumns: `${'max-content '.repeat(columns.length)}`,
          }}
        >
          {!hideHeaderRow &&
            headerGroups.map((headerGroup) =>
              headerGroup.headers.map((column, i) => (
                <ReactTableDashboardHeaderColumn
                  firstCell={!i}
                  isQuarter={column.id.includes('-Q')}
                  isYearly={column.id.includes('-Y')}
                  isCumulative={column.id.includes('CUMULATIVE')}
                  {...column.getHeaderProps()}
                >
                  {column.render('Header')}
                </ReactTableDashboardHeaderColumn>
              )),
            )}
          {rows.map((row, rowIndex) => {
            prepareRow(row);
            return row.cells.map((cell, cellIndex) => (
              <ReactTableDashboardCell
                firstRow={!rowIndex}
                headRow={!row.depth && row.index}
                firstCell={!cellIndex}
                isSmallRow={!!row.depth}
                totalRow={row.original.type === 'totalRow'}
                breakingTotalRow={row.original.isTotalRow}
                isExpanded={row.isExpanded}
                isQuarter={cell.column.id.includes('-Q')}
                isYearly={cell.column.id.includes('-Y')}
                isCumulative={cell.column.id.includes('CUMULATIVE')}
                {...cell.getCellProps()}
              >
                {cell.render('Cell')}
              </ReactTableDashboardCell>
            ));
          })}
        </div>
      </WaterfallTable>
    </>
  );
};
