import { INTEGRATION_SERVICES } from 'consts/integrations';
import { getServiceCategory } from 'models/integration';

const getQuickbooksExternalUrl = () =>
  `https://app${
    process.env.INTEGRATIONS_QUICKBOOKS_ENVIRONMENT === 'sandbox' && process.env.NODE_ENV !== 'test' ? '.sandbox' : ''
  }.qbo.intuit.com/app`;
const getXeroExternalUrl = () => `https://go.xero.com`;

export const buildExternalCustomerUrl = ({ service = '', portalId, instanceURL, externalId }) => {
  const serviceCategory = getServiceCategory(service);
  switch (serviceCategory) {
    case INTEGRATION_SERVICES.HUBSPOT:
      return `https://app.hubspot.com/contacts/${portalId}/company/${externalId}/`;
    case INTEGRATION_SERVICES.SALESFORCE:
      return instanceURL ? `${instanceURL}/${externalId}` : '#';
    case INTEGRATION_SERVICES.MAXIO:
      // .slice(0, -9) gets rid of "/api/v1.0"
      return instanceURL ? `${instanceURL.slice(0, -9)}/so/customers/${externalId}` : '#';
    case INTEGRATION_SERVICES.QUICKBOOKS:
      return `${getQuickbooksExternalUrl()}/customerdetail?nameId=${externalId}`;
    case INTEGRATION_SERVICES.XERO:
      return `${getXeroExternalUrl()}/Contacts/View/${externalId}`;
    case INTEGRATION_SERVICES.NETSUITE:
      return `https://system.netsuite.com/app/common/entity/custjob.nl?id=${externalId}`;
    case INTEGRATION_SERVICES.STRIPE:
      return `https://dashboard.stripe.com/customers/${externalId}`;
    default:
      return '';
  }
};
