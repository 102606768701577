import React from 'react';
import styled from 'styled-components';
import { ReactComponent as ReloadIcon } from 'images/reload.svg';
import { IconGreenButton } from 'components/Buttons';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 40vh;
  text-align: center;
`;

const Title = styled.h5`
  font-weight: 900;
  font-size: 20px;
  line-height: 26px;
  margin-bottom: 12px;
`;

const Description = styled.h6`
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 24px;
  width: 240px;
`;

export const NoDataFound = () => (
  <Container>
    <Title>0 results found</Title>
    <Description>Try changing the search parameters or resetting the search</Description>
    <IconGreenButton icon={<ReloadIcon className="icon-white" style={{ marginRight: 5 }} />} text="Refresh data" />
  </Container>
);
