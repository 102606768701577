import axios from 'axios';
import { API_URL } from 'api/consts';

const buildUrl = ({ orgId, goalId }) => {
  const baseUrl = `${API_URL}/organizations/${orgId}/goals`;
  if (!goalId) return baseUrl;

  return `${baseUrl}/${goalId}`;
};

export const getGoals = async ({ orgId }) => {
  const response = await axios.get(buildUrl({ orgId }));
  return response.data;
};

export const getGoal = async ({ orgId, goalId }) => {
  const response = await axios.get(buildUrl({ orgId, goalId }));
  return response.data;
};

export const createGoal = async ({ orgId, body }) => {
  const response = await axios.post(buildUrl({ orgId }), body);
  return response.data;
};

export const updateGoal = async ({ orgId, goalId, body }) => {
  const response = await axios.put(buildUrl({ orgId, goalId }), body);
  return response.data;
};

export const deleteGoal = async ({ orgId, goalId }) => {
  const response = await axios.delete(buildUrl({ orgId, goalId }));
  return response.data;
};
