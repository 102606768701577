import React, { useContext } from 'react';

import { AppContext } from 'AppContext';
import { PERIOD_OPTIONS, onSelectPeriod } from 'shared/Filters';
import { PAGE_CONTEXT_VALUES, usePageContext, useStateWithStorage } from 'utils/hooks';
import { useOrganizationCountsAPI, useOrganizationMetadataAPI } from 'api/organizations';
import { Centerer, Column } from 'components/Core';
import { CircleLoader } from 'components/Loaders';
import { BillingEmptyState } from 'views/Billing/Common/BillingEmptyState';
import { BillingDashboardChart } from './BillingDashboardChart';
import { BillingDashboardContext } from './BillingDashboardContext';
import { BillingDashboardWidgets } from './BillingDashboardWidgets';

export const BillingDashboard = () => {
  const { orgId } = useContext(AppContext);

  const { data: countData, isLoading: isLoadingCount } = useOrganizationCountsAPI({
    orgId,
    scopes: ['invoicingSchedules'],
  });
  const { data: organizationMetadata, isLoading: isLoadingMetadata } = useOrganizationMetadataAPI({ orgId });
  const { urlState } = usePageContext({ page: PAGE_CONTEXT_VALUES.dashboard_chart.key });
  const { earliestAndLatestSpreadsDates } = organizationMetadata ?? {};
  const [dashboardChartDataFilter, setDashboardChartDataFilter] = useStateWithStorage('dasboard-chart__filters', {
    ...urlState?.dataFilter,
    period: PERIOD_OPTIONS.allTime,
    startMonth: onSelectPeriod({ period: PERIOD_OPTIONS.allTime, earliestAndLatestSpreadsDates })?.startMonth,
    endMonth: onSelectPeriod({ period: PERIOD_OPTIONS.allTime, earliestAndLatestSpreadsDates })?.endMonth,
  });

  return (
    <BillingDashboardContext.Provider
      value={{
        dashboardChartDataFilter,
        setDashboardChartDataFilter,
      }}
    >
      {isLoadingCount || isLoadingMetadata ? (
        <Centerer width="100%" height="100%">
          <CircleLoader name="billing-dashboard-loading" />
        </Centerer>
      ) : (countData?.invoicingSchedules ?? 0) === 0 ? (
        <BillingEmptyState />
      ) : (
        <Column vertical="space-between" horizontal="normal" style={{ width: '100%' }}>
          <BillingDashboardWidgets />

          <BillingDashboardChart />
        </Column>
      )}
    </BillingDashboardContext.Provider>
  );
};
