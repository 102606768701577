import { useContext, useState } from 'react';
import styled from 'styled-components';
import { FlexerColumn, FlexerRow } from 'components/Core';
import { getCustomerDisplayName } from 'models/customer';
import { Popover, usePortal } from 'components/Portal';
import { FileTextIcon } from 'components/Icons';
import { CUSTOMERS_MODAL_ACTIONS, CustomersActionsModal } from 'views/Customers/CustomersActionsModal';
import { ReactComponent as EditCircle } from 'images/edit-circle.svg';
import { InvoicingScheduleContext } from '../../InvoicingScheduleContext';
import { StyledLinkIcon } from './style';

const DropdownLink = styled(FlexerRow)`
  cursor: pointer;
  font-size: 12px;
  padding: 8px;
  position: relative;
  align-items: center;
  justify-content: space-between;
`;

const OptionsTitle = styled.div`
  font-size: 9px;
  font-style: normal;
  border-radius: 4px;
  font-weight: 900;
  line-height: 12px;
  border: 1px solid var(--neutralGray);
  text-transform: uppercase;
  padding: 2px 4px;
  color: var(--primaryBlack50);
  margin: 12px;
  margin-bottom: 4px;
`;

const Option = styled.div`
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  display: flex;
  align-items: center;
  width: 180px;
  cursor: pointer;
  padding: 12px;
  border-bottom: 1px solid var(--neutralGray);

  &:last-child {
    border-bottom: none;
  }

  svg {
    width: 16px;
    height: 16px;

    path {
      fill: var(--primaryBlack50);
    }
  }

  &:hover {
    color: var(--primaryBlack80);

    svg path {
      fill: var(--primaryBlack);
    }
  }
`;

export const SubscriptLink = () => {
  const { invoicingScheduleFormValues, refetchCustomer } = useContext(InvoicingScheduleContext);
  const { triggerRef, togglePortal, hidePortal, isPortalVisible, Portal } = usePortal({ XOffset: 280, YOffset: 55 });
  const [showCustomersActionModal, setShowCustomersActionModal] = useState(false);

  return invoicingScheduleFormValues?.customer_id ? (
    <>
      <DropdownLink ref={triggerRef} onClick={togglePortal}>
        <b style={{ opacity: 0.5 }}>Customer in Subscript:</b>
        <FlexerRow alignItems="center">
          <b>
            {getCustomerDisplayName({
              customerName: invoicingScheduleFormValues?.customer_name,
              customerId: invoicingScheduleFormValues?.customer_id,
              customerEmail: invoicingScheduleFormValues?.customer_email,
            })}
          </b>
          <StyledLinkIcon />
        </FlexerRow>
      </DropdownLink>
      {isPortalVisible ? (
        <Portal>
          <Popover>
            <FlexerColumn>
              <OptionsTitle>customer details</OptionsTitle>

              <Option onClick={() => window.open(`/customers/${invoicingScheduleFormValues?.customer_id}`, '_blank')}>
                <FileTextIcon /> In Subscript
              </Option>

              <Option
                onClick={() => {
                  hidePortal();
                  setShowCustomersActionModal(true);
                }}
              >
                <EditCircle />
                Edit Customer
              </Option>
            </FlexerColumn>
          </Popover>
        </Portal>
      ) : null}

      {showCustomersActionModal && (
        <CustomersActionsModal
          closeModal={async () => {
            setShowCustomersActionModal(false);
            await refetchCustomer();
          }}
          modalAction={CUSTOMERS_MODAL_ACTIONS.EDIT}
          customer={{ id: invoicingScheduleFormValues?.customer_id }}
        />
      )}
    </>
  ) : null;
};
