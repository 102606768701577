import { useState } from 'react';
import { ReactComponent as LeftIcon } from 'images/Arrow-left.svg';
import { ReactComponent as RightIcon } from 'images/Arrow-right.svg';
import { ActionIcon, CarouselActions, CurrentBanner } from 'shared/Banners/styles';
import { Carousel } from './styles';
import { InsightCard } from './InsightCard';

export const InsightCards = ({ insights, cohortData }) => {
  const [currentInsightIndex, setCurrentInsightIndex] = useState(0);

  return (
    <>
      {insights?.length ? (
        <Carousel>
          <InsightCard insight={insights[currentInsightIndex]} cohortData={cohortData} />
          {insights?.length > 1 && (
            <CarouselActions>
              <ActionIcon onClick={() => setCurrentInsightIndex((currentInsightIndex + 1) % insights.length)}>
                <LeftIcon />
              </ActionIcon>
              <CurrentBanner>
                insight {currentInsightIndex + 1} / {insights.length}{' '}
              </CurrentBanner>
              <ActionIcon
                onClick={() =>
                  setCurrentInsightIndex(currentInsightIndex - 1 < 0 ? insights.length - 1 : currentInsightIndex - 1)
                }
              >
                <RightIcon />
              </ActionIcon>
            </CarouselActions>
          )}
        </Carousel>
      ) : null}
    </>
  );
};
