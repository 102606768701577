import React, { useContext, useMemo, useState } from 'react';
import { AppContext } from 'AppContext';
import { useInvoicingSchedulesAPI } from 'api/billing';
import { Centerer } from 'components/Core';
import { SizedLoader } from 'components/Loaders';
import { TableContainerLayout } from '../Common/TableContainerLayout';
import { InvoicingSchedulesTable } from './InvoicingSchedulesTable';

export const InvoicingSchedulesTableContainer = () => {
  const {
    orgId,
    appSettings: { currencyISOCode: currency },
  } = useContext(AppContext);

  const [invoicingScheduleSearchQuery, setInvoicingScheduleSearchQuery] = useState('');

  // TODO: Paginate this
  const {
    data: invoicingSchedulesByCustomer,
    isFetching: isFetchingSchedules,
    isLoading: isLoadingSchedules,
  } = useInvoicingSchedulesAPI({
    orgId,
    searchQuery: invoicingScheduleSearchQuery,
    autoFetch: true,
  });

  const dataForTable = useMemo(
    () =>
      invoicingSchedulesByCustomer
        ? Object.entries(invoicingSchedulesByCustomer).map(([customerId, customerData]) => ({
            id: customerId,
            name: customerData.customerName,
            subRows: (customerData.invoicingSchedules ?? []).map((invoicingSchedule) => ({
              customerName: customerData.customerName,
              ...invoicingSchedule,
            })),
            totalAmount: (customerData.invoicingSchedules ?? []).reduce(
              (acc, curr) => (acc = acc + curr.totalAmount),
              0,
            ),
          }))
        : [],
    [invoicingSchedulesByCustomer],
  );

  const [tableRows, setTableRows] = useState([]);

  return (
    <TableContainerLayout
      setSearchQuery={setInvoicingScheduleSearchQuery}
      isFetching={isFetchingSchedules}
      rowsCount={tableRows?.length}
    >
      {isLoadingSchedules || isFetchingSchedules ? (
        <Centerer height="100px">
          <SizedLoader size={40} />
        </Centerer>
      ) : (
        <InvoicingSchedulesTable setTableRows={setTableRows} currency={currency} dataForTable={dataForTable} />
      )}
    </TableContainerLayout>
  );
};
