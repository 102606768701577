import React from 'react';
import styled from 'styled-components';
import { FlexerColumn } from 'components/Core';
import { transactionDisplayTitle } from 'models/transaction';
import { TABS } from 'views/Contracts/ContractSingleView/ContractTabs';

const CustomerName = styled.span`
  color: var(--primaryBlue);
  font-weight: 700;
  cursor: pointer;
`;

export const getColumns = ({ history, fromCustomerPage = false }) => [
  {
    Header: 'CUSTOMER NAME',
    accessor: 'customer_name',
    id: 'customer_name',
    width: 200,
    Cell: ({ value, row }) => (
      <CustomerName
        onClick={() => {
          const url = `/contracts/${row.original.id}?tab=${TABS.INVOICES}`;
          if (fromCustomerPage) {
            // open in new tab
            window.open(url, '_blank');
          } else {
            // navigate to the page
            history.push(url);
          }
        }}
      >
        {value || '[No customer name]'}
      </CustomerName>
    ),
  },
  {
    Header: 'TRANSACTION NAMES',
    accessor: 'transactions',
    id: 'transactions',
    width: 300,
    Cell: ({ value }) =>
      value?.length > 0 ? (
        <FlexerColumn gap="4px">
          {value?.map((transaction) => {
            const transactionTitle = transactionDisplayTitle(transaction);
            return <span key={transactionTitle}>• {transactionTitle}</span>;
          })}
        </FlexerColumn>
      ) : (
        '[No Transactions]'
      ),
  },
  {
    Header: 'ATTACHMENTS',
    accessor: 'attachments',
    id: 'attachments',
    width: 300,
    Cell: ({ value }) =>
      value?.length > 0 ? (
        <FlexerColumn gap="4px">
          {value?.map(({ name }) => (
            <span key={name}>• {name || '[No attachment name]'}</span>
          ))}
        </FlexerColumn>
      ) : (
        '[No Attachments]'
      ),
  },
];
