import { useCallback, useContext, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Form, Formik } from 'formik';

import { AppContext } from 'AppContext';
import { EVENTS } from 'consts/analytics';
import { Modal, ModalCloseIcon, ModalContainer, ModalFooter } from 'components/Modal';
import { Centerer, FlexerColumn, FlexBetweenContainer, Spacer } from 'components/Core';
import { FormikCustomCheckbox, FormikCustomInput, FormikCustomSelector } from 'components/Controls';
import { TooltipContainer } from 'components/Tooltip';
import { usePricingPlansAPI } from 'api/usageBasedEngine';
import { ReactComponent as CheckIcon } from 'images/transaction_check.svg';
import { useUniqueUsageEventNamesAPI } from 'api/usageBasedEngine/hooks';
import { useOrganizationMetadataAPI } from 'api/organizations';
import { useAnalytics } from 'utils/hooks';

import { PRICING_PLAN_MODAL_MODE, editPricingModalValidationSchema, initialPricingPlanValue } from './consts';
import { BillingSchemeSection } from './BillingSchemeSection';
import { USAGE_BASED_ENGINE_FREQUENCY } from '../consts';

const Wrapper = styled.div`
  padding: 0 50px;
  background-color: ${({ bgcolor }) => bgcolor ?? 'var(--primaryGray)'};
`;

const Header = styled.div`
  font-size: 24px;
  line-height: 38px;
  margin-bottom: 20px;
  font-weight: 900;
`;

const ActionItem = styled(FlexBetweenContainer)`
  margin-bottom: 20px;
  gap: 12px;
`;

const FlexGrow = styled.div`
  flex-grow: 1;
`;

const FormFooter = styled(ModalFooter)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const FormButtonsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const CancelButton = styled(Centerer)`
  background: var(--primaryBlack5);
  border: 1px solid var(--primaryBlack5);
  border-radius: 100px;
  margin-right: 14px;
  padding: 11px 14px;
  cursor: pointer;
  font-weight: 700;
`;

const SaveButton = styled.div`
  background: var(--primaryGreen);
  display: flex;
  align-items: center;
  border: 1px solid var(--primaryBlack5);
  border-radius: 100px;
  color: #ffffff;
  padding: 8px 14px;
  padding-right: 8px;
  cursor: pointer;
  pointer-events: ${(props) => props.disabled && 'none'};
  opacity: ${(props) => props.disabled && '0.3'};
  font-weight: 700;
`;

const StyledCheckIcon = styled(CheckIcon)`
  path {
    fill: white;
  }

  rect {
    fill: transparent;
  }
`;

const FREQUENCY_OPTIONS = Object.values(USAGE_BASED_ENGINE_FREQUENCY).map((freq) => ({
  label: freq,
  value: freq,
}));

const CheckIconWrapper = styled(Centerer)`
  border-radius: 50%;
  border: 1px solid white;
  width: 20px;
  height: 20px;
`;

export const EditPricingPlanModal = ({
  onClose,
  mode = PRICING_PLAN_MODAL_MODE.EDIT,
  pricingPlan = initialPricingPlanValue,
}) => {
  const {
    organizations,
    orgId,
    appSettings: { currencyISOCode },
  } = useContext(AppContext);

  const productOptions =
    organizations[0]?.products?.map((productObject) => ({
      label: productObject.name,
      value: Number(productObject.id),
    })) ?? [];

  const {
    operations: { editPricingPlan, createPricingPlan },
  } = usePricingPlansAPI({ autoFetch: false, orgId });

  const { data: organizationMetadata } = useOrganizationMetadataAPI({ orgId });
  const { currencies: allOrgNonHomeCurrencies } = organizationMetadata ?? {};

  const {
    data: eventNameData,
    isLoading: isLoadingEvents,
    operations: { refetch: refetchEventNames },
  } = useUniqueUsageEventNamesAPI({ orgId });

  const isCreate = mode === PRICING_PLAN_MODAL_MODE.CREATE;

  const eventNames = useMemo(() => eventNameData?.data ?? [], [eventNameData?.data]);

  return (
    <ModalContainer>
      <Modal
        overflow="visible"
        width="900px"
        minHeight="500px"
        data-cy="edit-pricing-plan-modal"
        height="auto"
        maxHeight="auto"
      >
        <ModalCloseIcon onClose={onClose} />
        <Formik
          initialValues={{
            currency: currencyISOCode,
            bill_in_advance: false,
            ...pricingPlan,
          }}
          validationSchema={editPricingModalValidationSchema}
          onSubmit={async (values) => {
            isCreate
              ? await createPricingPlan.mutateAsync({ data: values })
              : await editPricingPlan.mutateAsync({
                  data: values,
                  id: pricingPlan.id,
                });

            if (!eventNames.includes(values.event_name)) refetchEventNames();

            onClose();
          }}
          enableReinitialize
        >
          {({ values, setFieldValue, handleSubmit }) => (
            <Form>
              <Wrapper>
                <Header>{`${isCreate ? 'Create' : 'Edit'} Pricing Plan`}</Header>
                <ActionItem>
                  <FlexGrow>
                    <FormikCustomInput
                      formik
                      data-cy="pricing-plan-modal__name"
                      name="name"
                      label="Name"
                      placeholder="Enter pricing plan name"
                      width="100%"
                    />
                  </FlexGrow>
                  <FlexGrow>
                    <FormikCustomSelector
                      label="Product"
                      placeholder="Select product"
                      name="product_id"
                      value={
                        values?.product_id ? productOptions.find((option) => option.value === values.product_id) : null
                      }
                      options={productOptions}
                      handleChange={(option) => setFieldValue('product_id', option ? option.value : null)}
                      width="100%"
                      isClearable
                    />
                  </FlexGrow>
                </ActionItem>
                <ActionItem>
                  <FormikCustomInput
                    formik
                    textarea
                    data-cy="pricing-plan-modal__description"
                    name="description"
                    label="Description"
                    placeholder="Enter description"
                    width="100%"
                  />
                </ActionItem>
                <ActionItem>
                  <FlexGrow>
                    <FormikCustomSelector
                      label="Event name"
                      placeholder="Select or create event name"
                      name="event_name"
                      value={values?.event_name ? { label: values.event_name, value: values.event_name } : null}
                      options={eventNames.map((eventName) => ({
                        label: eventName,
                        value: eventName,
                      }))}
                      handleChange={(option) => setFieldValue('event_name', option ? option.value : null)}
                      width="100%"
                      isClearable
                      creatable
                      isLoading={isLoadingEvents}
                    />
                  </FlexGrow>

                  <FormikCustomSelector
                    label="Currency"
                    placeholder="Select currency"
                    name="currency"
                    value={values?.currency ? { label: `$${values.currency}`, value: values.currency } : null}
                    options={[
                      ...(allOrgNonHomeCurrencies?.map((currency) => ({
                        label: `$${currency}`,
                        value: currency,
                      })) ?? []),
                      { label: currencyISOCode, value: currencyISOCode },
                    ]}
                    handleChange={(option) => setFieldValue('currency', option ? option.value : null)}
                    width="160px"
                    isClearable
                  />

                  <FormikCustomSelector
                    label="Interval"
                    placeholder="Select interval"
                    name="invoicing_frequency"
                    value={
                      values?.invoicing_frequency
                        ? { label: values.invoicing_frequency, value: values.invoicing_frequency }
                        : null
                    }
                    options={FREQUENCY_OPTIONS}
                    handleChange={(option) => setFieldValue('invoicing_frequency', option ? option.value : null)}
                    width="180px"
                  />
                </ActionItem>
              </Wrapper>

              <BillingSchemeSection name="tiers" currency={values.currency} />

              <FormFooter>
                <FlexerColumn>
                  <TooltipContainer
                    toolTipContent={
                      <span>
                        By default, we create invoices at the end of each billing period. Check this box if you want to
                        instead create invoices <em>at start of each billing period</em>.
                      </span>
                    }
                  >
                    <FormikCustomCheckbox name="bill_in_advance" label="Bill in advance" />
                  </TooltipContainer>
                </FlexerColumn>
                <FormButtonsRow>
                  <CancelButton onClick={onClose}>Cancel</CancelButton>
                  <SaveButton
                    onClick={handleSubmit}
                    disabled={createPricingPlan.isLoading || editPricingPlan.isLoading}
                    data-cy="edit-pricing-plan-modal__save-button"
                  >
                    {createPricingPlan.isLoading || editPricingPlan.isLoading ? 'Saving' : 'Save'}
                    <Spacer width="10px" />
                    <CheckIconWrapper>
                      <StyledCheckIcon height="20px" />
                    </CheckIconWrapper>
                  </SaveButton>
                </FormButtonsRow>
              </FormFooter>
            </Form>
          )}
        </Formik>
      </Modal>
    </ModalContainer>
  );
};

export const useEditPricingPlanModal = () => {
  const { trackEvent } = useAnalytics();
  const [openParams, setOpenParams] = useState({});
  const [showModal, setShowModal] = useState(false);
  const openModal = useCallback(
    (openParams = {}) => {
      trackEvent({
        name: EVENTS.OPEN_PRICING_PLAN_MODAL,
        properties: { mode: openParams?.mode ?? PRICING_PLAN_MODAL_MODE.EDIT },
      });
      setOpenParams(openParams);
      setShowModal(true);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setShowModal],
  );
  const closeModal = useCallback(() => setShowModal(false), [setShowModal]);

  const Modal = useCallback(() => (showModal ? <EditPricingPlanModal {...openParams} onClose={closeModal} /> : <></>), [
    closeModal,
    openParams,
    showModal,
  ]);

  return {
    openModal,
    EditPricingPlanModal: Modal,
  };
};
