import { useContext } from 'react';
import { ToastsContext } from './ToastsContext';

export const useToasts = () => {
  const context = useContext(ToastsContext);

  if (!context) {
    console.error('The `useToasts` hook must be called from a descendent of the `ToastsProvider`.');
  }

  return {
    pushToast: context.pushToast,
    pushCustomToast: context.pushCustomToast,
    pushError: context.pushError,
  };
};
