import styled from 'styled-components';

export const ClearButton = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  visibility: ${({ hasValue }) => (hasValue ? 'visible' : 'hidden')};

  svg {
    width: 18px;
    height: 18px;
    background-color: transparent;
    box-shadow: none;
    padding: 0;

    path {
      fill: var(--primaryBlack30);
    }
  }

  &:hover {
    cursor: pointer;
  }
`;

export const InputContainer = styled.div`
  height: 35px;
  display: flex;
  align-items: center;
  background-color: ${({ backgroundColor }) => backgroundColor ?? 'var(--primaryBlack3)'};
  transition: background-color 0.2s ease-in-out;
  border-radius: 20px;
  padding: 0px 10px;
  border: 1px solid transparent;

  font-size: ${({ fontSize }) => fontSize ?? '12px'};
  font-weight: 700;
  font-style: italic;

  b {
    color: var(--primaryBlue);
    &::after {
      content: ':';
      visibility: ${({ hasValue }) => (hasValue ? 'visible' : 'hidden')};
    }
  }

  input {
    width: ${({ width }) => width};
    background-color: transparent;
    border: 1px solid transparent;

    font-weight: ${({ fontWeight }) => fontWeight ?? 700};
    font-size: ${({ fontSize }) => fontSize};
    line-height: ${({ lineHeight }) => lineHeight};
    font-style: italic;

    ::placeholder {
      color: ${({ placeholderColor }) => placeholderColor ?? 'var(--primaryBlack50)'};
      font-weight: 700;
      font-style: italic;
      transition: color 0.2s ease-in-out;
    }
  }

  /* If input is focused, set all required css for 'focused state' */
  :has(input:focus) {
    background-color: var(--primaryBlack5);
    border: 1px solid var(--primaryBlack50);

    input::placeholder {
      color: var(--primaryBlack20);
    }

    b {
      &::after {
        visibility: visible;
      }
    }

    & > ${ClearButton} {
      visibility: visible;

      path {
        fill: var(--primaryBlack);
      }
    }
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;

  & > svg {
    width: 30px;
    height: 30px;
    padding: 5px;
    background-color: var(--primaryBlue);
    box-shadow: 0px 4px 10px var(--primaryBlue20);
    transition: background-color 0.2s ease-in-out;
    border-radius: 20px;
    transition: box-shadow 0.2s ease-in-out;

    path {
      fill: var(--lightGray);
      transition: fill 0.2s ease-in-out;
    }
  }

  /* Hover checked on parent to transition both input and bg color */
  &:hover {
    & > svg {
      background-color: var(--secondaryBlue);

      path {
        fill: white;
      }
    }

    & b {
      color: var(--secondaryBlue);
    }

    & > ${InputContainer} {
      background-color: var(--primaryBlack5);
      border: 1px solid var(--primaryBlack10);
    }

    & > ${InputContainer} > ${ClearButton} {
      path {
        fill: var(--primaryBlack);
      }
    }
  }

  /* If input is focused, set all required css for 'focused state' */
  :has(input:focus) {
    & > svg {
      background-color: var(--secondaryBlue);

      path {
        fill: white;
      }
    }
  }
`;

export const DropdownSearchBarContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  color: var(--primaryBlack);

  padding: 5px;

  svg {
    width: 30px;
    height: 30px;
    padding: 5px;

    path {
      fill: var(--primaryBlue);
    }
  }

  input {
    width: ${({ width }) => width};
    background-color: transparent;
    border: 1px solid transparent;

    ::placeholder {
      color: var(--primaryBlack);
      opacity: 0.3;
      transition: color 0.2s ease-in-out;
    }
  }
`;
