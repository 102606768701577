import React from 'react';
import { Row } from 'components/Core';

import { DropdownTitle } from '../styles';
import { EXTERNAL_UPDATE_TYPES } from '../consts';
import { SelectDropdownButton } from 'components/Buttons';

const TYPE_TO_TEXT = {
  [EXTERNAL_UPDATE_TYPES.edit]: 'Fields changed',
  [EXTERNAL_UPDATE_TYPES.delete]: 'Deletions',
  [EXTERNAL_UPDATE_TYPES.resync]: 'New line items',
};

export const FilterDropdown = ({ onAction, activeType }) => {
  return (
    <Row>
      <DropdownTitle>Show updates with:</DropdownTitle>

      <SelectDropdownButton
        showSelectedDirectly
        name="filter-dropdown__actions-button"
        options={TYPE_TO_TEXT}
        selected={TYPE_TO_TEXT[activeType]}
        onSelect={(key) => onAction(key)}
      />
    </Row>
  );
};
