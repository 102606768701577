import { useHistory } from 'react-router';
import { TransactionDeleteModal } from 'shared/TransactionContent';
import { AutoConfirmationExplainModal } from './AutoConfirmationExplainModal';
import { LockOldTransactionsModal } from './LockOldTransactionsModal';
import { TransactionAutoConfirmModal } from './TransactionAutoConfirmModal';
import { TransactionBulkDeleteModal } from './TransactionBulkDeleteModal';
import { LockAccountingSpreadsModal } from './LockAccountingSpreadsModal';
import { BulkDeleteInvoiceChangesModal } from '../BulkDeleteInvoiceChangesModal/BulkDeleteInvoiceChangesModal';

export const TransactionsContentModals = ({
  showDeleteModal,
  organizations,
  dataForModal,
  setShowDeleteModal,
  handleTransactionDeleted,
  transactionIdsToBulkConfirm,
  setTransactionIdsToBulkConfirm,
  updateAllPages,
  setUnconfirmedTransactions,
  setShowBulkTransactions,
  setOpeningBulkProcessFromBulkConfirm,
  transactionIdsToBulkDelete,
  setTransactionIdsToBulkDelete,
  deletedTransactionIds,
  setDeletedTransactionIds,
  showAutoconfirmHelpModal,
  setShowAutoconfirmHelpModal,
  showTransactionLockModal,
  setShowTransactionLockModal,
  showAccountingSpreadsLockModal,
  setShowAccountingSpreadsLockModal,
  LinkShareModal,
  SyncedExportModal,
}) => {
  const history = useHistory();

  return (
    <>
      {showDeleteModal && (
        <TransactionDeleteModal
          organizationId={organizations[0]?.id}
          transaction={dataForModal}
          onClose={() => {
            setShowDeleteModal(false);
          }}
          onDeleted={handleTransactionDeleted}
        />
      )}
      {transactionIdsToBulkConfirm.length > 0 && (
        <TransactionAutoConfirmModal
          transactionIds={transactionIdsToBulkConfirm}
          onClose={() => setTransactionIdsToBulkConfirm([])}
          onSuccess={() => {
            setTransactionIdsToBulkConfirm([]);
            updateAllPages();
          }}
          organization={organizations[0]}
          onBulkProcess={({ transactions: transactionsToBulkProcess, changed }) => {
            setTransactionIdsToBulkConfirm([]);
            setUnconfirmedTransactions(transactionsToBulkProcess);
            setShowBulkTransactions(true);
            if (changed) {
              setOpeningBulkProcessFromBulkConfirm(true);
            }
          }}
        />
      )}
      {transactionIdsToBulkDelete.length > 0 && (
        <TransactionBulkDeleteModal
          transactionIds={transactionIdsToBulkDelete}
          onClose={() => setTransactionIdsToBulkDelete([])}
          onSuccess={(deletedTransactionIds) => {
            setTransactionIdsToBulkDelete([]);
            setDeletedTransactionIds(deletedTransactionIds);
            updateAllPages();
          }}
          organization={organizations[0]}
        />
      )}
      {deletedTransactionIds?.length > 0 && (
        <BulkDeleteInvoiceChangesModal
          deletedTransactionIds={deletedTransactionIds}
          onClose={() => setDeletedTransactionIds([])}
        />
      )}

      <LinkShareModal />
      <SyncedExportModal />

      {showAutoconfirmHelpModal && (
        <AutoConfirmationExplainModal hideModal={() => setShowAutoconfirmHelpModal(false)} />
      )}

      {showTransactionLockModal && (
        <LockOldTransactionsModal
          hideModal={() => {
            setShowTransactionLockModal(false);
            history.push('/transactions?openLockModal=false');
          }}
        />
      )}

      {showAccountingSpreadsLockModal && (
        <LockAccountingSpreadsModal
          hideModal={() => {
            setShowAccountingSpreadsLockModal(false);
          }}
        />
      )}
    </>
  );
};
