import dayjs from 'dayjs';
import { isNil } from 'lodash';
import { TooltipContainer } from 'components/Tooltip';
import { RECOGNITION_TYPES } from 'consts/global';
import { UnknownTag } from './styles';

export const DEFAULT_SEGMENTS = [
  {
    name: 'Small',
    amount: 3000,
  },
  {
    name: 'Medium',
    amount: 10000,
  },
  {
    name: 'Large',
    amount: null,
  },
];

export const getExampleDealTopPosition = ({
  defaultTransactionRecognition,
  current,
  amount,
  lineAmount,
  start,
  lineStart,
  end,
  lineEnd,
  seats,
  lineSeats,
}) => {
  const getFieldOffsetTopPx = ({ id }) => `${[...current?.children].find((field) => field.id === id)?.offsetTop}px`;

  if (current) {
    if (amount === undefined && lineAmount === undefined) {
      return getFieldOffsetTopPx({ id: 'amount-field' });
    } else if (start === undefined && lineStart === undefined) {
      return getFieldOffsetTopPx({ id: 'amount-field' });
    } else if (end === undefined && lineEnd === undefined) {
      return getFieldOffsetTopPx({ id: 'start-field' });
    } else if (seats === undefined && lineSeats === undefined) {
      return defaultTransactionRecognition === 'linear'
        ? getFieldOffsetTopPx({ id: 'end-field' })
        : getFieldOffsetTopPx({ id: 'seats-field' });
    } else {
      return getFieldOffsetTopPx({ id: 'seats-field' });
    }
  }
};

export const calcDealFieldCount = ({ integrationCRM, initalCount, defaultTransactionRecognition }) => {
  let newCount = initalCount;
  if (integrationCRM?.service !== 'hubspot') --newCount;
  if (defaultTransactionRecognition !== 'linear') --newCount;

  return newCount;
};

export const isPageFilled = ({
  integrationsLength,
  currentStep,
  defaultTransactionRecognition,
  internalId,
  totalValueFieldDeal,
  totalValueFieldLineItem,
  startDateFieldDeal,
  startDateFieldLineItem,
  endDateFieldDeal,
  endDateFieldLineItem,
  seatsFieldDeal,
  seatsFieldLineItem,
  recurringRevenueFieldDeal,
  recurringRevenueFieldLineItem,
}) => {
  if (currentStep === 1) {
    return !!integrationsLength;
  } else if (currentStep === 2) {
    if (defaultTransactionRecognition === RECOGNITION_TYPES.linear) {
      return (
        internalId !== undefined &&
        (totalValueFieldDeal !== undefined || totalValueFieldLineItem !== undefined) &&
        (startDateFieldDeal !== undefined || startDateFieldLineItem !== undefined) &&
        (endDateFieldDeal !== undefined || endDateFieldLineItem !== undefined) &&
        (seatsFieldDeal !== undefined || seatsFieldLineItem !== undefined)
      );
    } else if (defaultTransactionRecognition === RECOGNITION_TYPES.tillCanceled) {
      return (
        internalId !== undefined &&
        (totalValueFieldDeal !== undefined ||
          totalValueFieldLineItem !== undefined ||
          recurringRevenueFieldDeal !== undefined ||
          recurringRevenueFieldLineItem !== undefined) &&
        (startDateFieldDeal !== undefined || startDateFieldLineItem !== undefined) &&
        (seatsFieldDeal !== undefined || seatsFieldLineItem !== undefined)
      );
    } else {
      return (
        defaultTransactionRecognition !== undefined &&
        internalId !== undefined &&
        (totalValueFieldDeal !== undefined || totalValueFieldLineItem !== undefined) &&
        (startDateFieldDeal !== undefined || startDateFieldLineItem !== undefined) &&
        (seatsFieldDeal !== undefined || seatsFieldLineItem !== undefined)
      );
    }
  } else {
    return true;
  }
};

export const filterRawData = (rawData) => {
  const result = {};
  for (const [key, value] of Object.entries(rawData)) {
    switch (key) {
      // primitives
      case 'defaultTransactionRecognition':
      case 'skipDailySync':
        if (value !== undefined) {
          result[key] = value;
        }
        break;
      // arrays
      case 'closedWonStages':
      case 'customerMetadataOptions':
      case 'transactionMetadataOptions':
      case 'nonRecurringProducts':
        const newArray = value?.filter((item) => item);
        if (newArray?.length > 0) {
          result[key] = newArray;
        }
        break;
      // object
      case 'transactionMappings':
      case 'lineItemMappings':
        if (Object.keys(value ?? {}).some((field) => !isNil(value[field]))) {
          result[key] = value;
        }
        break;
      // object
      case 'customSettings':
      case 'segments':
        const newObject = {};
        for (const [objectKey, objectValue] of Object.entries(value)) {
          if (Array.isArray(objectValue)) {
            if (objectValue.length > 0) {
              newObject[objectKey] = objectValue;
            }
          } else if (objectValue !== undefined) {
            newObject[objectKey] = objectValue;
          }
        }
        if (Object.keys(newObject).length > 0) {
          result[key] = newObject;
        }
        break;
      default:
    }
  }
  return result;
};

const CONFIG_FIELDS = [
  'defaultTransactionRecognition',
  'closedWonStages',
  'transactionMetadataOptions',
  'customerMetadataOptions',
  'nonRecurringProducts',
  'customSettings',
  'segments',
  'transactionMappings',
  'lineItemMappings',
  'skipDailySync',
];
// {amount, recurring_amount, start_date, end_date, seats}
const TRANSACTION_MAPPING_FIELDS_TO_KEY = {
  totalValueFieldDeal: 'amount',
  startDateFieldDeal: 'start_date',
  endDateFieldDeal: 'end_date',
  seatsFieldDeal: 'seats',
  recurringRevenueFieldDeal: 'recurring_amount',
  currencyISOField: 'currency',
};
// {amount, recurring_amount, start_date, end_date, seats}
const LINE_ITEM_MAPPING_FIELDS_TO_KEY = {
  totalValueFieldLineItem: 'amount',
  startDateFieldLineItem: 'start_date',
  endDateFieldLineItem: 'end_date',
  seatsFieldLineItem: 'seats',
  recurringRevenueFieldLineItem: 'recurring_amount',
};

const mappingFromSpecialKeys = ({ data, field, key, mappingToKeys }) => {
  if (!['recurring_amount', 'amount'].includes(key)) return null;
  switch (key) {
    case 'recurring_amount':
      if (data[field]) {
        const result = {
          source: data[field],
        };
        if (!data.isTillCanceledMrr) {
          result['transformer'] = ['divideBy', 12];
        }
        return result;
      }
      break;
    case 'amount':
      const dataRecurringAmountKey = Object.entries(mappingToKeys).find(([, value]) => value === 'recurring_amount')[0];
      if (data.defaultTransactionRecognition === RECOGNITION_TYPES.tillCanceled && data[dataRecurringAmountKey]) {
        return {
          value: null,
        };
      }
      break;
    default:
  }
  return null;
};

const createMappingConfig = (data, mappingToKeys) => {
  const config = {};
  for (const [field, key] of Object.entries(mappingToKeys)) {
    config[key] = mappingFromSpecialKeys({ data, field, key, mappingToKeys }) ?? data[field] ?? null;
  }
  return config;
};

export const getExampleDealFieldValue = ({ field, exampleIntegrationObjects }) =>
  exampleIntegrationObjects?.Deal?.[field] ?? undefined;
export const getExampleLineItemFieldValue = ({ field, exampleIntegrationObjects }) =>
  exampleIntegrationObjects?.LineItem?.[field] ?? undefined;

export const getExampleFieldComponent = ({ dealField, lineItemField, exampleIntegrationObjects }) => {
  const dealFieldValue = getExampleDealFieldValue({ field: dealField, exampleIntegrationObjects });
  const lineItemFieldValue = getExampleLineItemFieldValue({ field: lineItemField, exampleIntegrationObjects });
  if (dealFieldValue !== undefined) {
    return String(dealFieldValue);
  } else if (lineItemFieldValue !== undefined) {
    return String(lineItemFieldValue);
  } else if (dealField === null) {
    return (
      <TooltipContainer width={150} toolTipContent="Please answer the question">
        <UnknownTag>Unknown</UnknownTag>
      </TooltipContainer>
    );
  } else {
    return 'No example value';
  }
};

export const getSelectorOptions = ({ fields, type, isDealLevel, exampleIntegrationObjects }) => {
  if (exampleIntegrationObjects) {
    // TODO [TC 2023-04-13]: We now get the field type from the backend since PR #6444,
    // so the code in this "if" block should be deprecated and taken out,
    // since I don't think we return example objects anymore
    return fields
      .map((field) => ({
        ...field,
        exampleValue: getExampleFieldComponent({
          dealField: isDealLevel ? field?.value ?? undefined : undefined,
          lineItemField: !isDealLevel ? field?.value ?? undefined : undefined,
          exampleIntegrationObjects,
        }),
      }))
      .filter((mappedField) => {
        //If we don't have example value we don't know field type, so we dont filter
        switch (type) {
          case 'number':
            return mappedField?.exampleValue === 'No example value' ? true : !isNaN(Number(mappedField?.exampleValue));
          case 'string':
            return mappedField?.exampleValue === 'No example value'
              ? true
              : typeof mappedField?.exampleValue === 'string' && isNaN(Number(mappedField?.exampleValue));
          default:
            return true;
        }
      });
  } else {
    return fields.filter((field) => {
      if (!field.type) return true;

      switch (type) {
        case 'number':
          return ['number', 'decimal', 'integer', 'currency', 'percent', 'double'].some((numericType) =>
            field.type.toLowerCase().includes(numericType),
          );
        case 'date':
          return ['date', 'time'].some((dateType) => field.type.toLowerCase().includes(dateType));
        default:
          return true;
      }
    });
  }
};

const CUSTOM_SETTINGS_KEYS = ['isARR', 'isCommitted', 'influxMonths', 'quarters', 'committedPolicy', 'committedStart'];

const createCustomSettingsConfig = (data) => {
  const config = {};
  for (const key of CUSTOM_SETTINGS_KEYS) {
    if (key === 'quarters') {
      if (isNil(data.yearEndMonthIndex)) continue;
      const quarters = [];
      for (let i = 0; i < 4; i++) {
        const monthsToGoBack = i * 3;
        quarters.unshift(
          dayjs()
            .month(data.yearEndMonthIndex - monthsToGoBack)
            .format('MMM'),
        );
      }
      config[key] = quarters;
    } else {
      if (Array.isArray(data[key])) {
        if (data[key].length > 0) {
          config[key] = data[key];
        }
      } else if (data[key] !== undefined) {
        config[key] = data[key];
      }
    }
  }
  return config;
};

const createSegmentConfig = (data) => {
  if (!data.segments || data.segments.length === 0) return {};

  const config = {
    size: {},
  };

  const sortedSegments = [...data.segments];
  sortedSegments.sort((a, b) => (a.amount === null ? 1 : b.amount === null ? -1 : a.amount - b.amount));
  let lowerBound = 0;
  let upperBound;
  for (const [index, { name, amount }] of sortedSegments.entries()) {
    if (index === sortedSegments.length - 1 && amount === null) {
      // the last value is the max
      config.size[name] = [lowerBound];
    } else {
      upperBound = amount;
      config.size[name] = [lowerBound, upperBound];
      lowerBound = amount;
    }
  }

  return config;
};

export const convertDataToConfigs = (data) => {
  const result = {};
  for (const field of CONFIG_FIELDS) {
    switch (field) {
      case 'defaultTransactionRecognition':
      case 'closedWonStages':
      case 'transactionMetadataOptions':
      case 'customerMetadataOptions':
      case 'nonRecurringProducts':
      case 'skipDailySync':
        result[field] = data[field];
        break;
      case 'customSettings':
        result[field] = createCustomSettingsConfig(data);
        break;
      case 'transactionMappings':
        result[field] = createMappingConfig(data, TRANSACTION_MAPPING_FIELDS_TO_KEY);
        break;
      case 'lineItemMappings':
        result[field] = createMappingConfig(data, LINE_ITEM_MAPPING_FIELDS_TO_KEY);
        break;
      case 'segments':
        result[field] = createSegmentConfig(data);
        break;
      default:
    }
  }
  return result;
};

export const getConfigsFromData = (rawData) => {
  const configData = convertDataToConfigs(rawData);
  const filteredConfigData = filterRawData(configData);
  return filteredConfigData;
};
