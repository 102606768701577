import dayjs from 'dayjs';
import { cssVar } from 'polished';
import { capitalize } from 'utils/stringUtils';
import { Flexer, Spacer } from 'components/Core';
import { CircleArrowDownIcon, CircleArrowUpIcon, DirectionsIcon, ScaleIcon, FillDot } from 'components/Icons';
import { FORECAST_SCENARIO_TYPE } from '../consts';
import { ScenarioBucketLabel } from '../Shared/styles';
import {
  HeaderWrapper,
  CircleArrowWrapper,
  Header,
  DirectionIconWrapper,
  ScenarioName,
  ScenarioSubTitle,
  ScenarioActionsWrapper,
  ScenarioAction,
} from './ScenarioCardHeader.styles';

var utc = require('dayjs/plugin/utc');
var timezone = require('dayjs/plugin/timezone'); // dependent on utc plugin
dayjs.extend(utc);
dayjs.extend(timezone);

export const ScenarioCardHeader = ({ scenario, onEdit, onRemove, showingDetails }) => (
  <HeaderWrapper>
    <CircleArrowWrapper>{showingDetails ? <CircleArrowUpIcon /> : <CircleArrowDownIcon />}</CircleArrowWrapper>
    <Header>
      <DirectionIconWrapper>{scenario.bucket ? <DirectionsIcon /> : <ScaleIcon />}</DirectionIconWrapper>
      <div>
        <Flexer alignItems="center">
          <ScenarioName>{scenario.name}</ScenarioName>
          {scenario.bucket && <ScenarioBucketLabel marginLeft="12px">{scenario.bucket}</ScenarioBucketLabel>}
        </Flexer>
        <ScenarioSubTitle>
          {scenario.previousBucket
            ? `${scenario.previousBucket} + Additional deals`
            : `${capitalize(
                scenario.forecastScenarioType ?? FORECAST_SCENARIO_TYPE.WEIGHTED,
              )} pipeline, updated at ${dayjs.utc(scenario.updated_at).tz(dayjs.tz.guess()).format('DD/MM/YYYY')}
            `}
        </ScenarioSubTitle>
        <Spacer height="20px" />
        <ScenarioActionsWrapper>
          <ScenarioAction data-cy={`scenario-card--${scenario.name}__show-details-button`}>
            {showingDetails ? 'Hide' : 'Show'} forecast
          </ScenarioAction>

          {(!scenario.bucket || !scenario.previousBucket) && (
            <>
              <FillDot size="4px" fill={cssVar('--primaryBlack5')} />
              <ScenarioAction onClick={onEdit} data-cy={`scenario-card--${scenario.name}__edit-button`}>
                Settings
              </ScenarioAction>
              <FillDot size="4px" fill={cssVar('--primaryBlack5')} />
              <ScenarioAction warn onClick={onRemove} data-cy={`scenario-card--${scenario.name}__remove-button`}>
                Remove
              </ScenarioAction>
            </>
          )}
        </ScenarioActionsWrapper>
      </div>
    </Header>
  </HeaderWrapper>
);
