import React, { useState } from 'react';
import styled from 'styled-components';

import { Centerer, FlexerColumn, FlexerRow } from 'components/Core';
import { FormikCustomInput } from 'components/Controls';
import { HelpIconBlack } from 'components/Icons';
import { FORECAST_SCENARIO_TYPE } from '../consts';
import { InputsColumn, InputsRow, Line, SettingExplainer, SettingsSeparator } from './styles';
import { PipelineToBeClosedChart } from './PipelineToBeClosedChart';
import { ISO_CODE_TO_SYMBOL } from 'consts/global';

export const TabButtonsWrapper = styled(FlexerRow)`
  padding: 0 12px;
  gap: 12px;
`;

const TabButton = styled(Centerer)`
  cursor: pointer;

  height: 40px;
  padding: 10px 12px;

  font-weight: 700;
  font-size: 14px;
  line-height: 20px;

  border: 1px solid var(--accentGraySecond);
  box-shadow: 4px 4px 20px var(--primaryBlack3);
  border-radius: 8px;

  color: ${({ isSelected }) => (isSelected ? 'var(--primaryBlue)' : 'var(--primaryBlack)')};
  background: ${({ isSelected }) => (isSelected ? 'var(--primaryBlue10)' : '#FFF')};

  &:hover {
    color: var(--primaryBlue);
  }
`;

export const NewCustomersSection = ({ name, scenarioType, values, editMode, currency }) => {
  const orderedBucketKeys = Object.entries(values.pipeline_unweighted_buckets ?? {})
    .sort(([, valuesA], [, valuesB]) => valuesA.order - valuesB.order)
    .map(([key]) => key);
  const [selectedBucket, setSelectedBucket] = useState(orderedBucketKeys[0]);
  const chartValues =
    scenarioType === FORECAST_SCENARIO_TYPE.WEIGHTED
      ? values
      : {
          ...values,
          ...values.pipeline_unweighted_buckets[selectedBucket],
        };

  return (
    <>
      <SettingsSeparator>
        <div>Settings of {!editMode ? 'new' : ''} pipeline</div>
        {orderedBucketKeys?.length > 0 && (
          <TabButtonsWrapper>
            {orderedBucketKeys.map((key) => (
              <TabButton key={key} isSelected={key === selectedBucket} onClick={() => setSelectedBucket(key)}>
                {values.pipeline_unweighted_buckets[key]?.name}
              </TabButton>
            ))}
          </TabButtonsWrapper>
        )}
        <Line />
      </SettingsSeparator>

      <InputsRow>
        <InputsColumn>
          <FlexerColumn>
            <FormikCustomInput
              name={
                selectedBucket
                  ? `pipeline_unweighted_buckets[${selectedBucket}].start_generating_new_pipeline_after_months`
                  : 'start_generating_new_pipeline_after_months'
              }
              data-cy={`${name}__start_generating_new_pipeline_after_months-input`}
              placeholder="Enter months"
              label="Generate new pipeline after month"
              type="number"
              suffix="month(s)"
              min="0"
              isDisabled={editMode}
            />
            <SettingExplainer>
              <HelpIconBlack />
              <div>In how many months might you start closing new pipeline that’s not yet generated?</div>
            </SettingExplainer>
          </FlexerColumn>
        </InputsColumn>

        <InputsColumn>
          <FlexerColumn>
            <FormikCustomInput
              name={
                selectedBucket
                  ? `pipeline_unweighted_buckets[${selectedBucket}].average_new_recurring_revenue_growth`
                  : 'average_new_recurring_revenue_growth'
              }
              data-cy={`${name}__average_new_recurring_revenue_growth-input`}
              placeholder="Enter growth rate"
              label="Generated pipeline growth rate"
              type="number"
              suffix="%"
              precision={2}
              min="0"
              isDisabled={editMode}
            />
            <SettingExplainer>
              <HelpIconBlack />
              <div>What percentage (per month) will your new pipeline generated grow?</div>
            </SettingExplainer>
          </FlexerColumn>
        </InputsColumn>
      </InputsRow>

      {/* Let's just show for UNWEIGHTED, in the future we might want to also show in WEIGHTED*/}
      {scenarioType === FORECAST_SCENARIO_TYPE.UNWEIGHTED && (
        <InputsRow>
          <InputsColumn>
            <FlexerColumn>
              <FormikCustomInput
                name={
                  selectedBucket
                    ? `pipeline_unweighted_buckets[${selectedBucket}].average_pipeline_recurring_revenue`
                    : 'average_pipeline_recurring_revenue'
                }
                data-cy={`${name}__average_pipeline_recurring_revenue-input`}
                placeholder="Enter amount"
                label="Initial generated recurring revenue"
                type="number"
                suffix={ISO_CODE_TO_SYMBOL[currency] ?? '$'}
                precision={0}
                min="0"
                isDisabled={editMode}
              />
              <SettingExplainer>
                <HelpIconBlack />
                <div>What is the average monthly recurring revenue amount added to the pipeline?</div>
              </SettingExplainer>
            </FlexerColumn>
          </InputsColumn>
          <InputsColumn></InputsColumn>
        </InputsRow>
      )}

      {!editMode && <PipelineToBeClosedChart values={chartValues} />}
    </>
  );
};
