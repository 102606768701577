export const getDataForTable = ({ data = [], accountingCurrency }) => {
  const result = [];
  for (const transaction of data) {
    const difference = transaction.amount - transaction.accounting_spreads_total_amount;

    if (difference < 0.01) continue;

    result.push({
      customerName: transaction.customer_name,
      customerId: transaction.customer_id,
      transactionDate: transaction.date,
      transactionName: transaction.name,
      transactionId: transaction.id,
      transactionCurrency: transaction.currency || accountingCurrency,
      productName: transaction.product_name,
      recognition: transaction.recognition,
      startDate: transaction.start_date,
      endDate: transaction.end_date,
      transactionAmount: transaction.amount,
      accountingSpreadSum: transaction.accounting_spreads_total_amount,
      difference,
    });
  }
  return result;
};
