import React, { useContext, useState } from 'react';
import { AppContext } from 'AppContext';
import { useIntegrationsAPI } from 'api/integrations';
import { useToasts } from 'components/Toasts';
import { CircleLoader } from 'components/Loaders';
import { AddButton } from '../../styles';
import { getServiceCategory } from 'models/integration';
import { INTEGRATIONS_OPERATIONS } from 'consts/integrations';

export const SyncTrackingCategoriesButton = ({ integration }) => {
  const { orgId, setIntegrations } = useContext(AppContext);
  const {
    operations: { runOnExternalService },
  } = useIntegrationsAPI({ orgId, autoFetch: false });

  const { pushError } = useToasts();
  const [isLoading, setIsLoading] = useState(false);

  const handleSyncTrackingCategories = async () => {
    setIsLoading(true);
    try {
      const updatedIntegration = await runOnExternalService.mutateAsync({
        operation: INTEGRATIONS_OPERATIONS.SYNC_TRACKING_CATEGORIES,
        integrationService: getServiceCategory(integration.service),
        integrationId: integration.id,
      });
      if (updatedIntegration) {
        // We need to do this to update the integration object with tracking categories in the AppContext
        setIntegrations((existingIntegrations) =>
          existingIntegrations
            .filter((existingIntegration) => existingIntegration.id !== integration.id)
            .concat(updatedIntegration),
        );
      }
    } catch (error) {
      pushError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return !integration || isLoading ? (
    <CircleLoader width={30} height={30} />
  ) : (
    <AddButton onClick={handleSyncTrackingCategories}>Sync from GL</AddButton>
  );
};
