import { useContext } from 'react';
import { useTransactionsAPI } from 'api/transactions';
import { TransactionDataActions } from './TransactionDataActions';
import { Wrapper } from './TransactionDataActionsContainer.styles';
import { TRANSACTION_MODAL_MODE } from '../consts';
import { TransactionContext } from '../TransactionContext';

export const TransactionDataActionsContainer = () => {
  const { currentTransaction, mode, setCurrentTransaction, setLoading } = useContext(TransactionContext);

  const {
    operations: { invalidateCache },
  } = useTransactionsAPI({ autoFetch: false });

  const handleTransactionFieldsRefresh = async () => {
    setCurrentTransaction({ id: currentTransaction.id });

    invalidateCache();
  };

  return (
    mode !== TRANSACTION_MODAL_MODE.CREATE && (
      <Wrapper>
        <TransactionDataActions
          transactionId={currentTransaction?.id}
          onTransactionFieldsRefresh={handleTransactionFieldsRefresh}
          currentTransaction={currentTransaction}
          setCurrentTransaction={setCurrentTransaction}
          setLoading={setLoading}
        />
      </Wrapper>
    )
  );
};
