export const safeParseJson = (jsonString, fallback = null) => {
  if (!jsonString) return fallback;
  try {
    return JSON.parse(jsonString);
  } catch {
    return fallback;
  }
};

export const safeStringifyJson = (json) => {
  try {
    return JSON.stringify(json, null, 2);
  } catch (e) {
    return '{}';
  }
};
