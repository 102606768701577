import styled from 'styled-components';
import { ReactComponent as CalculatorIcon } from 'images/bxs-calculator.svg';
import { ModalFooter, ModalTitle } from 'components/Modal';

export const HeaderIconWrapper = styled.div`
  padding: 10px;
  background-color: white;
  box-shadow: 8px 8px 40px var(--primaryBlack10);
  border-radius: 10px;
  width: max-content;
  margin-bottom: 16px;
  height: 54px;
`;

export const ExplainModalTitle = styled(ModalTitle)`
  font-size: 20px;
  line-height: 26px;
  padding: 0;
`;

export const ModalDivider = styled.div`
  position: relative;
  margin-top: 30px;
  margin-bottom: 22px;

  .divider {
    position: absolute;
    top: 4px;
    z-index: 1;
  }
`;

export const DividerText = styled.div`
  font-weight: 900;
  font-size: 10px;
  line-height: 14px;
  width: max-content;
  position: relative;
  z-index: 2;
  background-color: white;
  padding: 0 10px;
  display: flex;
  margin-left: 40px;
  text-transform: uppercase;
  align-items: center;

  svg {
    margin-right: 6px;
  }
`;

export const ExplainSubtitle = styled.div`
  font-style: italic;
  opacity: 0.5;
  margin-top: 8px;
  font-size: 14px;
  line-height: 20px;
`;

export const ExplainModalFooter = styled(ModalFooter)`
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  position: relative;
`;

export const MetricRow = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  line-height: 16px;
  padding: 12px 0;
  border-bottom: 1px solid rgba(0, 21, 46, 0.03);
  color: ${(props) => props.isExplainMetric && 'var(--secondaryGreen)'};

  &:last-child {
    border-bottom: none;
    margin-bottom: 18px;
  }

  span {
    font-weight: 900;
  }
`;

export const StyledCalculatorIcon = styled(CalculatorIcon)`
  position: absolute;
  top: -10px;
`;
