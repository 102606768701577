import { useMutation, useQuery, useQueryClient } from 'react-query';
import { CACHE_KEY as MONTHLY_BREAKDOWN_CACHE_KEY } from 'api/monthlyBreakdown';
import { getExpenses, updateExpenses } from './requests';

const CACHE_KEY = 'getExpenses';

export const useExpensesAPI = ({ orgId, month, autoFetch = true }) => {
  const queryClient = useQueryClient();

  const { data, error, isLoading, isFetching } = useQuery(
    [CACHE_KEY, orgId, month],
    () => getExpenses({ orgId, month }),
    {
      enabled: autoFetch && !!orgId && !!month,
    },
  );

  const mutationOptions = {
    onSuccess: () => {
      queryClient.invalidateQueries([CACHE_KEY, orgId, month]);
      queryClient.invalidateQueries(MONTHLY_BREAKDOWN_CACHE_KEY);
    },
  };

  const updateExpensesMutation = useMutation(
    ({ orgId, month, expenses }) => updateExpenses({ orgId, month, expenses }),
    mutationOptions,
  );

  return {
    data,
    error,
    isLoading,
    isFetching,
    operations: {
      updateExpenses: updateExpensesMutation.mutateAsync,
    },
  };
};
