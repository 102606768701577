import { useCallback, useState } from 'react';
import { AIReaderLockedModal } from './AIReaderLockedModal';

export const useAIReaderLockedModal = () => {
  const [showModal, setShowModal] = useState(false);

  const openModal = useCallback(() => {
    setShowModal(true);
  }, [setShowModal]);

  const Modal = useCallback(() => (showModal ? <AIReaderLockedModal onClose={() => setShowModal(false)} /> : null), [
    showModal,
    setShowModal,
  ]);

  return {
    openAIReaderLockedModal: openModal,
    AIReaderLockedModal: Modal,
    isModalVisible: showModal,
  };
};
