import React, { useState } from 'react';
import styled from 'styled-components';
import { Modal, ModalBody, ModalCloseIcon, ModalHeader, ModalTitle, ModalContainer } from 'components/Modal';
import { getCustomerDisplayName } from 'models/customer';
import { BillingProvider } from 'views/Billing/BillingProvider';
import { CustomerViewContent } from './CustomerViewContent';

const StyledModalTitle = styled(ModalTitle)`
  margin-bottom: 20px;
  font-weight: 900;
`;

export const CustomerViewModal = ({ customerId, onClose }) => {
  const [customer, setCustomer] = useState();

  return (
    <ModalContainer data-cy="customer-view-modal">
      <Modal width="90%" height="90vh" overflow="auto">
        <ModalHeader>
          <ModalCloseIcon onClose={onClose} />
          <StyledModalTitle>
            {getCustomerDisplayName({ customerName: customer?.name, customerId, customerEmail: customer?.email })}
          </StyledModalTitle>
        </ModalHeader>
        <ModalBody>
          <BillingProvider>
            <CustomerViewContent id={customerId} onCustomerFetched={setCustomer} />
          </BillingProvider>
        </ModalBody>
      </Modal>
    </ModalContainer>
  );
};
