import styled from 'styled-components';

const BORDER_RADIUSES = {
  primary: '12px',
  secondary: '12px',
  tertiary: '8px',
};

const BACKGROUNDS = {
  primary: 'rgba(253, 178, 66, 0.1)',
  secondary: 'white',
  tertiary: 'var(--primaryGreen10)',
};

const DIVIDING_LINES = {
  primary: '2px solid rgb(244, 227, 227)',
  secondary: 'none',
  tertiary: 'none',
};

export const CloseIconWrapper = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  flex: 0;
  justify-content: center;
  padding: 17px 20px;
`;

export const InfoIconWrapper = styled(CloseIconWrapper)`
  border-right: ${({ variant }) => DIVIDING_LINES[variant]};
  ${({ variant }) =>
    variant === 'tertiary' &&
    `
    padding: 0 14px 0 0;
    display: block;
    position: absolute;
    top: 14px;
    left: 14px;
  `}
  ${({ variant }) =>
    variant === 'secondary' &&
    `
    padding: 16px;
    padding-right: 0;
  `}
`;

export const InfoContent = styled.div`
  flex: 1;
  margin: 0 ${({ variant }) => (variant === 'tertiary' ? '14px' : variant === 'secondary' ? '12px' : '20px')};
  padding: ${({ variant }) => (variant === 'tertiary' ? '14px 0 14px 35px' : variant === 'secondary' ? '0' : '18px 0')};
  ${({ variant }) =>
    variant === 'tertiary' &&
    `
    font-size: 12px;
    line-height: 18px;
    weight: 400;
  `}
`;

export const InfoContainer = styled.div`
  align-items: center;
  background: ${({ variant }) => BACKGROUNDS[variant]};
  border-radius: ${({ variant }) => BORDER_RADIUSES[variant]};
  display: flex;
  justify-content: flex-start;
  margin-top: 15px;
  width: 100%;
  ${({ height }) =>
    height &&
    `
    height: ${height}px;
  `}
  ${({ variant }) =>
    variant === 'tertiary' &&
    `
    position: relative;
  `}
  ${({ variant }) =>
    variant === 'secondary' &&
    `
    border: 1px solid var(--primaryBlack5);
  `}

  p:last-child {
    margin-bottom: 0;
  }
`;
