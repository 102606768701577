import styled from 'styled-components';
import { ReactComponent as ClickIcon } from 'images/click-icon.svg';

export const PointerIcon = styled(ClickIcon)`
  width: ${({ size }) => size ?? '20px'};
  height: ${({ size }) => size ?? '20px'};
  margin: ${({ margin }) => margin ?? '4px'};

  stroke: ${({ stroke }) => stroke};
  path {
    fill: ${({ fill }) => fill};
  }
`;
