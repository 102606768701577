import React from 'react';
import styled from 'styled-components';
import { FlexerColumn } from 'components/Core';
import { RevenueCards } from './RevenueCards';
import { CustomersTable } from './CustomersTable';
import { TransactionsTable } from './TransactionsTable';

const ModalBodyContainer = styled(FlexerColumn)`
  width: 100%;
  height: 100%;
  padding: 40px;
  overflow: scroll;
`;

export const AccountingMonthModalBody = ({
  data,
  customerId,
  setCustomerId,
  accountingSpreadPolicy,
  setTransactionToShow,
  lastMonthDetailsPerCustomer,
  showCustomersWithDeferred,
  setShowCustomersWithDeferred,
  showCustomersWithAccrued,
  setShowCustomersWithAccrued,
  showCustomersWithAccountingRevenue,
  setShowCustomersWithAccountingRevenue,
  showCustomersWithInvoiced,
  setShowCustomersWithInvoiced,
  accountingCurrency,
}) => (
  <ModalBodyContainer>
    <RevenueCards data={data} accountingCurrency={accountingCurrency} />
    {customerId ? (
      <TransactionsTable
        detailsPerTransaction={data.detailsPerTransaction}
        accountingSpreadPolicy={accountingSpreadPolicy}
        setTransactionToShow={setTransactionToShow}
        accountingCurrency={accountingCurrency}
      />
    ) : (
      <CustomersTable
        detailsPerCustomer={data.detailsPerCustomer}
        setCustomerId={setCustomerId}
        lastMonthDetailsPerCustomer={lastMonthDetailsPerCustomer}
        showCustomersWithDeferred={showCustomersWithDeferred}
        setShowCustomersWithDeferred={setShowCustomersWithDeferred}
        showCustomersWithAccrued={showCustomersWithAccrued}
        setShowCustomersWithAccrued={setShowCustomersWithAccrued}
        showCustomersWithAccountingRevenue={showCustomersWithAccountingRevenue}
        setShowCustomersWithAccountingRevenue={setShowCustomersWithAccountingRevenue}
        showCustomersWithInvoiced={showCustomersWithInvoiced}
        setShowCustomersWithInvoiced={setShowCustomersWithInvoiced}
        accountingCurrency={accountingCurrency}
      />
    )}
  </ModalBodyContainer>
);
