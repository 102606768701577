import styled from 'styled-components';
import { Flexer } from 'components/Core';
import { ReactComponent as EyeIcon } from 'images/toggles-eye.svg';
import { EXTERNAL_UPDATE_TYPES } from '../consts';

const Wrapper = styled(Flexer)`
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: 20px;
  border: 1px solid var(--accentGraySecond);
  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 16px;
  padding: 20px;
`;

const ColumnName = styled(Flexer)`
  background: ${(props) => (props.variant === 'exclude' ? 'var(--primaryBlack10)' : 'var(--primaryYellow)')};
  border-radius: 2px;
  padding: 0px 2px;
  color: var(--primaryBlack);
  align-items: center;
  font-size: 12px;
  font-style: normal;
  margin: 0 3px;
  font-weight: 700;
  line-height: 16px;
`;

const ColumnsListing = ({ columns, variant = 'included' }) => (
  <Flexer>
    {columns.map((column, index) => (
      <Flexer key={column}>
        {columns.length > 1 && index === columns.length - 1 ? <span> and </span> : index ? <span>, </span> : null}
        <ColumnName variant={variant}>{column}</ColumnName>
      </Flexer>
    ))}
  </Flexer>
);

export const FilteredColumnsExplanation = ({ filteredColumns, filteredType }) => {
  const filterKeys = (value) => Object.keys(filteredColumns).filter((column) => filteredColumns[column] === value);
  const includedColumns = filterKeys(true);
  const excludedColumns = filterKeys(false);
  const haveBoth = includedColumns.length && excludedColumns.length;
  const haveNone = !includedColumns.length && !excludedColumns.length;

  let innerComponent;
  if (filteredType === EXTERNAL_UPDATE_TYPES.delete)
    innerComponent = 'Showing all updates where the upstream object has been deleted.';
  else if (filteredType === EXTERNAL_UPDATE_TYPES.resync)
    innerComponent = 'Showing all updates where new line items have been created.';
  else {
    innerComponent = (
      <>
        {haveNone ? (
          'Showing all updates where fields have changed'
        ) : (
          <>
            Showing updates where{' '}
            {includedColumns.length ? (
              <Flexer>
                <ColumnsListing columns={includedColumns} /> changed
              </Flexer>
            ) : null}
            {haveBoth ? ', but ' : ''}
            {excludedColumns.length ? (
              <Flexer>
                <ColumnsListing columns={excludedColumns} variant="exclude" /> did not change
              </Flexer>
            ) : null}
          </>
        )}
        .
      </>
    );
  }

  return (
    <Wrapper data-cy="external-updates__filtered-columns-explanation">
      <EyeIcon style={{ marginRight: 12 }} />
      {innerComponent}
    </Wrapper>
  );
};
