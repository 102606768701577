import { FlexBetweenContainer } from 'components/Core';
import { ReactComponent as IconCopy } from 'images/icon-copy.svg';
import { ReactComponent as IconEye } from 'images/eye.svg';
import { ReactComponent as IconEyeOff } from 'images/eye-off.svg';
import styled, { css } from 'styled-components';

export const Label = styled.label`
  color: var(--primaryBlack);
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 8px;
  opacity: 0.4;
`;

export const InputField = styled(FlexBetweenContainer)`
  background-color: var(--accentGray);
  border-radius: 8px;
  padding: 10px 12px;
`;

export const ValueText = styled.div`
  color: var(--primaryBlack);
  font-size: 12px;
  font-weight: 400;
  line-height: ${({ isSecret }) => (isSecret ? '22px' : '17.57px')};

  width: 100%;
  word-break: break-all;
`;

const IconStyle = css`
  cursor: pointer;
  width: 14px;
  height: 14px;

  path {
    fill: var(--primaryBlue);
  }
`;

export const CopyButton = styled(IconCopy)`
  ${IconStyle}
`;

export const ShowIcon = styled(IconEye)`
  ${IconStyle}
`;

export const HideIcon = styled(IconEyeOff)`
  margin-left: 10px;
  ${IconStyle}
`;
