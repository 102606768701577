import axios from 'axios';
import { API_URL } from '../index';

export const getAPIKey = async ({ orgId }) => {
  const url = `${API_URL}/v1/organizations/${orgId}/api-key`;
  const { data } = await axios.get(url);
  return data;
};

export const rotateAPIKey = async ({ orgId }) => {
  const url = `${API_URL}/v1/organizations/${orgId}/api-key/rotate`;
  const { data } = await axios.get(url);
  return data;
};
