import dayjs from 'dayjs';
import { isBetween, customFormatParseDate, periodKeyToDayJS } from 'utils/dateUtils';

export const pickOutPeriod = ({ periodsArray, columnTitle }) => {
  if (periodsArray === undefined || periodsArray === null || periodsArray === []) return null;

  if (columnTitle.includes('-Y')) {
    // Colum Title has format 2020-Y, slice off last two characters
    const needsStartMonth = dayjs(columnTitle.slice(0, -2), 'YYYY');
    const matchingPeriods = periodsArray.filter((period) => {
      const startMonth = dayjs(period.start_month);

      // Start month can fall anywhere in the year and we'll accept it for current year
      return (
        period.type === 'year' &&
        isBetween({
          date: startMonth,
          startDate: needsStartMonth,
          endDate: needsStartMonth.add(11, 'month'),
          granularity: 'month',
        })
      );
    });

    // Matching periods should have length at most 1, but it's feasible that
    // a goal has duplicated periods by accident, so we'll just return
    // the first one
    return matchingPeriods[0];
  }

  if (columnTitle.includes('-Q')) {
    // Colum Title has format 2020-Q3, slice off last three characters
    // Since this goals the end, but our data model has the start_month field,
    // we add in the quarters to get proper starting month
    const quarter = parseInt(columnTitle.slice(-1));
    const needsStartMonth = dayjs(columnTitle.slice(0, -3), 'YYYY').add((quarter - 1) * 3, 'months');
    const matchingPeriods = periodsArray.filter((period) => {
      const startMonth = dayjs(period.start_month);

      // Start month can fall anywhere in the first quarter and we'll accept it for this quarter
      return (
        period.type === 'quarter' &&
        isBetween({
          date: startMonth,
          startDate: needsStartMonth,
          endDate: needsStartMonth.add(2, 'month'),
          granularity: 'month',
        })
      );
    });

    return matchingPeriods[0];
  }

  const needsStartMonth = dayjs(columnTitle, 'MMM YYYY');
  const matchingPeriods = periodsArray.filter((period) => {
    const startMonth = dayjs(period.start_month);

    return period.type === 'month' && startMonth.isSame(needsStartMonth, 'month');
  });

  return matchingPeriods[0];
};

export const changeTableSummaryColumns = ({ currentColumns, changeColumn }) => {
  const newColumns = JSON.parse(JSON.stringify(currentColumns));
  newColumns[changeColumn] = !newColumns[changeColumn];
  return newColumns;
};

export const startAndEndMonthsFromHeader = ({ columnHeaders, index, quarters, quartersYearOffset = 0, dataFilter }) => {
  let startMonth;
  let endMonth;
  if (columnHeaders[index].includes('-Q')) {
    endMonth = periodKeyToDayJS({
      periodKey: columnHeaders[index],
      periodType: 'quarter',
      quarters,
      quartersYearOffset,
    });
    startMonth = endMonth.subtract(3, 'month');
  } else if (columnHeaders[index].includes('-Y')) {
    endMonth = periodKeyToDayJS({ periodKey: columnHeaders[index], periodType: 'year', quarters, quartersYearOffset });
    startMonth = endMonth.subtract(12, 'month');
  } else if (columnHeaders[index].includes('CUMULATIVE')) {
    endMonth = dayjs(dataFilter?.endMonth).startOf('month');
    startMonth = dayjs(dataFilter?.startMonth).subtract(1, 'month').startOf('month');
  } else {
    endMonth = customFormatParseDate(columnHeaders[index], 'MMM YYYY').startOf('month');
    startMonth = endMonth;
  }

  return {
    startMonth,
    endMonth,
  };
};

export const getIsSummaryColumn = ({ columnHeaders, index, date }) => {
  let value = '';
  if (date) {
    value = date;
  } else if (columnHeaders) {
    value = columnHeaders[index];
  }

  return value.includes('-Q') || value.includes('-Y') || value.includes('CUMULATIVE');
};
