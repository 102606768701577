import { useState, useEffect } from 'react';
import {
  Modal,
  ModalBody,
  ModalCloseIcon,
  ModalHeader,
  ModalTitle,
  ModalTitleText,
  ModalContainer,
} from 'components/Modal';
import { ErrorBar } from 'components/Blocks';
import { BackButton } from 'components/Buttons';
import { Flexer, Centerer, Spacer } from 'components/Core';
import { Loader, CircleLoader } from 'components/Loaders';
import { useGoalsAPI } from 'api/goals';
import { GoalsTable } from './GoalsTable';
import { GoalForm } from './GoalForm';

export const GoalsModal = ({ orgId, closeModal }) => {
  const [showGoalForm, setShowGoalForm] = useState(false);
  const [initialFormData, setInitialFormData] = useState(null);
  const [idUnderEdit, setIdUnderEdit] = useState(null);

  const {
    data,
    error,
    isLoading,
    isFetching,
    operations: { addGoalWithPeriods, editGoalWithPeriods, removeGoal },
  } = useGoalsAPI({ orgId });

  const [isFetchingOrOperation, setIsFetchingOrOperation] = useState(false);
  useEffect(() => {
    setIsFetchingOrOperation(
      isFetching || addGoalWithPeriods.isLoading || editGoalWithPeriods.isLoading || removeGoal.isLoading,
    );
  }, [isFetching, addGoalWithPeriods.isLoading, editGoalWithPeriods.isLoading, removeGoal.isLoading]);

  const handleOpenForm = (id) => {
    if (id !== null) setIdUnderEdit(id);
    const dataUnderId = data.filter((val) => val.id === id)[0];
    if (dataUnderId !== undefined) setInitialFormData(dataUnderId);
    else setInitialFormData(null);

    setShowGoalForm(true);
  };

  const handleCloseForm = () => {
    setShowGoalForm(false);
    setIdUnderEdit(null);
  };

  const handleSubmit = (data) => {
    if (idUnderEdit === null) addGoalWithPeriods.mutate({ data });
    else editGoalWithPeriods.mutate({ id: idUnderEdit, data });

    handleCloseForm();
  };

  const handleDelete = (id) => {
    if (idUnderEdit === null) removeGoal.mutate({ id });
    else removeGoal.mutate({ id: idUnderEdit });

    handleCloseForm();
  };

  let content = null;
  if (error) content = <ErrorBar> Sorry, something went wrong: {error.message} </ErrorBar>;
  else if (isLoading) content = <Loader />;
  else
    content = (
      <>
        <ModalHeader>
          <ModalCloseIcon onClose={closeModal} />
          <ModalTitle>
            <Flexer>
              {showGoalForm ? (
                <Centerer>
                  <BackButton onClick={handleCloseForm} />
                  <Spacer width="14px" />
                  <ModalTitleText>
                    <b>{initialFormData === null ? 'Create' : 'Edit'}</b> Goal
                  </ModalTitleText>
                </Centerer>
              ) : (
                <ModalTitleText>
                  <b>Goals</b> Management
                </ModalTitleText>
              )}
              {isFetchingOrOperation ? (
                <>
                  <Spacer width="20px" /> <CircleLoader width={24} height={24} />
                </>
              ) : null}
            </Flexer>
          </ModalTitle>
        </ModalHeader>
        {showGoalForm ? (
          <GoalForm
            initialData={initialFormData}
            onSubmit={handleSubmit}
            onCancel={() => {
              setShowGoalForm(false);
            }}
            onDelete={handleDelete}
            isEdit={initialFormData !== null}
          />
        ) : (
          <ModalBody>
            <GoalsTable data={data} onDeleteGoal={handleDelete} onOpenGoalForm={handleOpenForm} />
          </ModalBody>
        )}
      </>
    );

  return (
    <ModalContainer>
      <Modal height="auto" maxHeight="80vh" overflow="auto">
        {content}
      </Modal>
    </ModalContainer>
  );
};
