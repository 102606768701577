import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import { CentererVertical, FlexerColumn } from 'components/Core';
import { reFormatDate } from 'utils/dateUtils';
import { useDebouncedSearchBar } from 'components/Blocks';
import { SwitchWithLabel } from 'components/Controls';
import { CustomerRow } from './CustomerRow';
import { CustomerAccountingMetricRows } from './CustomerAccountingMetricRows';
import { CustomerTransactionAccountingMetricRows } from './CustomerTransactionAccountingMetricRows';
import { StyledTable, TableBody, TableContainer, TableHeaderRow, TableHeaderTile } from './styles';

const Header = styled.span`
  font-size: 18px;
  font-weight: 900;
  margin-top: 16px;
`;

const SectionOfTableForCustomer = ({
  customerId,
  customerName,
  accountingCurrency,
  total,
  transactions,
  monthKeys,
  isChildCustomer,
  searchQuery,
  showTransactionsWithDeferred,
  showTransactionsWithAccrued,
}) => {
  const usingFilter = searchQuery || showTransactionsWithDeferred || showTransactionsWithAccrued;

  const returnCustomerTransactionAccountingMetricRows = ({ transactionId, transaction }) => (
    <CustomerTransactionAccountingMetricRows
      transactionId={transactionId}
      transaction={transaction}
      monthKeys={monthKeys}
      accountingCurrency={accountingCurrency}
    />
  );

  return (
    <Fragment>
      <CustomerRow customerId={customerId} customerName={customerName} isChildCustomer={isChildCustomer} />

      {!usingFilter && (
        <CustomerAccountingMetricRows total={total} monthKeys={monthKeys} accountingCurrency={accountingCurrency} />
      )}

      {Object.entries(transactions)?.map(([transactionId, transaction]) => {
        if (!usingFilter) {
          return returnCustomerTransactionAccountingMetricRows({
            transactionId,
            transaction,
          });
        } else {
          const lastMonthKey = monthKeys[monthKeys.length - 1];
          if (
            searchQuery &&
            (transaction.transactionName.includes(searchQuery) ||
              transaction.externalIds.some((externalId) => externalId.includes(searchQuery)))
          ) {
            return returnCustomerTransactionAccountingMetricRows({
              transactionId,
              transaction,
            });
          } else if (showTransactionsWithDeferred && transaction?.deferredRevenue?.[lastMonthKey]) {
            return returnCustomerTransactionAccountingMetricRows({ transactionId, transaction });
          } else if (showTransactionsWithAccrued && transaction?.accruedRevenue?.[lastMonthKey]) {
            return returnCustomerTransactionAccountingMetricRows({ transactionId, transaction });
          }

          return null;
        }
      })}
    </Fragment>
  );
};

export const CustomerAccountingTable = ({ customerAccountingData = {} }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [showTransactionsWithDeferred, setShowTransactionsWithDeferred] = useState(false);
  const [showTransactionsWithAccrued, setShowTransactionsWithAccrued] = useState(false);

  const { DebouncedSearchBar } = useDebouncedSearchBar({
    onSearchQueryChange: setSearchQuery,
    initialValue: searchQuery,
    placeholder: 'Search Transaction Name or External ID...',
    width: '300px',
  });

  const {
    childCustomers,
    customerId,
    customerName,
    total,
    transactions,
    monthKeys,
    accountingCurrency,
  } = customerAccountingData;

  return (
    <FlexerColumn gap="20px">
      <Header>Accounting Revenue</Header>

      <CentererVertical gap="16px">
        <DebouncedSearchBar />
        <SwitchWithLabel
          size="small"
          label="Transactions with current deferred revenue"
          onChange={() => {
            setShowTransactionsWithDeferred(!showTransactionsWithDeferred);
            setShowTransactionsWithAccrued(false);
          }}
          checked={showTransactionsWithDeferred}
        />
        <SwitchWithLabel
          size="small"
          label="Transactions with current accrued revenue"
          onChange={() => {
            setShowTransactionsWithAccrued(!showTransactionsWithAccrued);
            setShowTransactionsWithDeferred(false);
          }}
          checked={showTransactionsWithAccrued}
        />
      </CentererVertical>

      {monthKeys?.length ? (
        <TableContainer data-cy="accounting-spreads-table">
          <StyledTable>
            <TableHeaderRow>
              <TableHeaderTile>REVENUE</TableHeaderTile>
              {monthKeys.map((monthKey) => (
                <TableHeaderTile data-cy={`accounting-spread-table__month-cell-${monthKey}`} key={monthKey}>
                  {reFormatDate(monthKey, 'YYYY-MM', 'MMM YYYY')}
                </TableHeaderTile>
              ))}
            </TableHeaderRow>

            <TableBody>
              {/* Current customer */}
              <SectionOfTableForCustomer
                customerId={customerId}
                customerName={customerName}
                accountingCurrency={accountingCurrency}
                total={total}
                transactions={transactions}
                monthKeys={monthKeys}
                searchQuery={searchQuery}
                showTransactionsWithDeferred={showTransactionsWithDeferred}
                showTransactionsWithAccrued={showTransactionsWithAccrued}
              />

              {/* Child customers */}
              {Object.entries(childCustomers)?.map(([childCustomerId, childCustomer]) => (
                <SectionOfTableForCustomer
                  isChildCustomer={true}
                  customerId={childCustomerId}
                  customerName={childCustomer.customerName}
                  accountingCurrency={childCustomer.accountingCurrency}
                  total={childCustomer.total}
                  transactions={childCustomer.transactions}
                  monthKeys={monthKeys}
                  searchQuery={searchQuery}
                  showTransactionsWithDeferred={showTransactionsWithDeferred}
                  showTransactionsWithAccrued={showTransactionsWithAccrued}
                />
              ))}
            </TableBody>
          </StyledTable>
        </TableContainer>
      ) : (
        <span>No Accounting Spreads</span>
      )}
    </FlexerColumn>
  );
};
