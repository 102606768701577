import React, { useContext } from 'react';
import styled from 'styled-components';
import { AppContext } from 'AppContext';
import { TabButton } from 'components/Buttons';
import { Row } from 'components/Core';
import { useStateWithStorage } from 'utils/hooks';
import { FEATURES, isFeatureActive } from 'utils/featureUtils';
import { TRANSACTION_TABLE_COLUMN_KEY } from 'shared/TransactionTable';
import { ReactComponent as InfoIcon } from 'images/info-circle-customer.svg';
import { SubscriptionsTableContainer } from 'views/Billing/UsageBasedEngine/Subscriptions';
import CustomerTransactionsTable from './CustomerTransactionsTable';
import { CustomerSnapshot } from './CustomerSnapshot';
import { CustomerHierarchyTable } from './CustomerHierarchyTable';
import { CustomerInvoicesTable } from './CustomerInvoicesTable';
import { CustomerSpreads } from './CustomerSpreads';
import { CustomerAccounting } from './CustomerAccounting';
import { CustomerContractsTable } from './CustomerContractsTable';

const TabsContainer = styled.div`
  padding: 12px 20px;
  display: flex;
  align-items: center;
  margin-top: 32px;
  justify-content: space-between;
  border-radius: 16px;
  margin-left: 40px;
  margin-right: 40px;
  border: 1px solid var(--accentGraySecond);
`;

const TablesWrapper = styled.div`
  padding-bottom: 8px;
  position: relative;
`;

const TabsTitle = styled.div`
  font-weight: normal;
  font-size: 20px;
  line-height: 26px;
  display: flex;
  margin-right: 12px;
  align-items: center;
`;

export const TableTitle = styled.div`
  font-size: 14px;
  line-height: 20px;
  font-weight: 900;
  display: flex;
  flex-direction: column;
`;

export const TABS = {
  REVENUE: {
    title: 'Revenue Details',
  },
  SPREADS: {
    title: 'Spreads',
  },
  ACCOUNTING: {
    title: 'Accounting',
  },
  TRANSACTIONS: {
    title: 'Transactions',
  },
  CONTRACTS: {
    title: 'Contracts',
  },
  HIERARCHY: {
    title: 'Hierarchy',
  },
  INVOICES: {
    title: 'All Invoices',
  },
  USAGE_SUBSCRIPTIONS: {
    title: 'Usage Subscriptions',
  },
};

const CustomerTablesTabs = ({ customer, fetchCustomer }) => {
  const { orgConfigs } = useContext(AppContext);
  const [activeTab, setActiveTab] = useStateWithStorage('customer-page-tab', TABS.REVENUE.title);

  const [
    hiddenTransactionColumns,
    setHiddenTransactionColumns,
  ] = useStateWithStorage('transaction-table-hidden-columns', [
    TRANSACTION_TABLE_COLUMN_KEY.ID,
    TRANSACTION_TABLE_COLUMN_KEY.EXTERNAL_ID,
    TRANSACTION_TABLE_COLUMN_KEY.SEATS,
    TRANSACTION_TABLE_COLUMN_KEY.CUSTOMER,
    TRANSACTION_TABLE_COLUMN_KEY.RECOGNITION,
    TRANSACTION_TABLE_COLUMN_KEY.LEFT_TO_RECOGNIZE,
    TRANSACTION_TABLE_COLUMN_KEY.CHECKBOX,
    TRANSACTION_TABLE_COLUMN_KEY.UPDATED_AT,
    TRANSACTION_TABLE_COLUMN_KEY.CREATED_AT,
  ]);

  const getTabContents = (activeTab) => {
    switch (activeTab) {
      case TABS.REVENUE.title:
        return <CustomerSnapshot customer={customer} />;
      case TABS.SPREADS.title:
        // We get customers twice - once without currentMRR scopes, and once with. Only the longer call has
        //  start_date and end_date, and we want to make sure we don't fetch until we have those
        return customer.start_date && customer.end_date ? <CustomerSpreads customer={customer} /> : null;
      case TABS.ACCOUNTING.title:
        return <CustomerAccounting customer={customer} />;
      case TABS.TRANSACTIONS.title:
        return (
          <CustomerTransactionsTable
            fetchCustomer={fetchCustomer}
            customer={customer}
            hiddenTransactionColumns={hiddenTransactionColumns}
            setHiddenTransactionColumns={setHiddenTransactionColumns}
          />
        );
      case TABS.CONTRACTS.title:
        return <CustomerContractsTable customer={customer} />;
      case TABS.HIERARCHY.title:
        return <CustomerHierarchyTable customer={customer} />;
      case TABS.INVOICES.title:
        return <CustomerInvoicesTable customer={customer} />;
      case TABS.USAGE_SUBSCRIPTIONS.title:
        return <SubscriptionsTableContainer customer={customer} />;
      default:
        return null;
    }
  };

  const tabsToShow = Object.assign({}, TABS);
  if (!isFeatureActive({ feature: FEATURES.BILLING, orgConfigs })) {
    delete tabsToShow.INVOICES;
  }

  return (
    <TablesWrapper>
      <TabsContainer>
        <Row>
          <TabsTitle>About:</TabsTitle>
          {Object.values(tabsToShow).map((tab) => (
            <TabButton
              key={tab.title}
              data-cy={`customer-tab-${tab.title}`}
              active={tab.title === activeTab}
              onClick={() => setActiveTab(tab.title)}
            >
              {tab.title}
            </TabButton>
          ))}
        </Row>

        <InfoIcon style={{ opacity: 0.1 }} />
      </TabsContainer>

      <div style={{ padding: '0 40px' }}>{getTabContents(activeTab)}</div>
    </TablesWrapper>
  );
};

export { CustomerTablesTabs };
