import { useCallback, useState } from 'react';
import dayjs from 'dayjs';
import styled from 'styled-components';

import { ReactComponent as HeaderIcon } from 'images/info-circle-icon.svg';
import { Modal, ModalBody, ModalButton, ModalContainer, ModalFooter, ModalHeader, ModalTitle } from 'components/Modal';
import { Column, Spacer } from 'components/Core';

const StyledHeaderIcon = styled.div`
  background-color: #ffffff;
  border-radius: 50%;
  display: inline-block;
  left: 50%;
  padding: 11px;
  position: absolute;
  top: -20px;
  transform: translate(-50%, 0);
`;

const TextWrapper = styled(Column)`
  background: var(--primaryYellow15);
  padding: 14px;
  border-radius: 12px;
`;

export const ConfirmResyncModal = ({ data, onClose, onConfirm }) => {
  return (
    <>
      <ModalContainer data-cy="confirm-modal">
        <Modal maxWidth="400px" height="auto" overflow="visible" compact>
          <StyledHeaderIcon>
            <HeaderIcon width="20px" height="20px" />
          </StyledHeaderIcon>
          <ModalHeader>
            <ModalTitle compact>
              <b>Line items have changed</b>
            </ModalTitle>
          </ModalHeader>
          <ModalBody compact centered>
            <Column>
              <div>
                This transaction was previously synced on {dayjs(data.targetObject.created_at).format('DD MMM YYYY')}{' '}
                with no line items. A recent sync on {dayjs(data.externalUpdate.created_at).format('DD MMM YYYY')} has
                discovered this has changed.
              </div>
              <Spacer height="10px" />
              <TextWrapper>
                Are you sure you want to confirm this update? Confirming will remove existing information (the current
                transaction will be archived) and perform a resync of the upstream object.
              </TextWrapper>
            </Column>
          </ModalBody>
          <ModalFooter centered transparent>
            <ModalButton onClick={onClose} data-cy="confirm-modal__close-button">
              No, cancel
            </ModalButton>
            <ModalButton
              className="primary"
              data-cy="confirm-modal__confirm-button"
              onClick={() => {
                onConfirm();
                onClose();
              }}
            >
              Confirm & Resync
            </ModalButton>
          </ModalFooter>
        </Modal>
      </ModalContainer>
    </>
  );
};

export const useConfirmResyncModal = (props) => {
  const [showModal, setShowModal] = useState(false);

  const openModal = useCallback(() => {
    setShowModal(true);
  }, [setShowModal]);

  const Modal = useCallback(
    () => (showModal ? <ConfirmResyncModal {...props} onClose={() => setShowModal(false)} /> : null),
    [showModal, setShowModal, props],
  );

  return {
    openConfirmResyncModal: openModal,
    ConfirmResyncModal: Modal,
  };
};
