import React from 'react';
import { Column, FlexerRow } from 'components/Core';
import { EXTERNAL_UPDATES_TABLE_COLUMNS, EXTERNAL_UPDATE_TYPES } from 'views/ExternalUpdates/consts';
import { TableCell } from 'views/ExternalUpdates/Components/TableCell';
import { generateChanges } from 'views/ExternalUpdates/utils';
import {
  formatExternalUpdateInvoiceDate,
  getTableScheduleData,
} from 'views/Billing/Pages/SchedulesExternalUpdates/utils';
import { InvoiceChangesToApply } from './InvoiceChangesToApply';
import { FirstLastInvoice, InlineFlexWarnings } from './styles';

export const InvoiceChanges = ({
  update,
  externalUpdate,
  resolveExternalUpdate,
  removeScheduleMode = false,
  onClose,
  onConfirmUpdate,
  onConfirmNoUpdate,
  customFooter,
}) => {
  const { updateData, invoicingSchedule } = update ?? {};
  const mappedTargetObject = getTableScheduleData(invoicingSchedule);
  const mappedExternalUpdateData = getTableScheduleData(updateData);

  const changedFields = generateChanges({
    source: mappedTargetObject,
    target: mappedExternalUpdateData,
    fields: Object.keys(EXTERNAL_UPDATES_TABLE_COLUMNS),
  });

  const isDeleteUpdate = externalUpdate?.type === EXTERNAL_UPDATE_TYPES.delete;

  return (
    <Column vertical="flex-start" horizontal="flex-start">
      <div style={{ width: '100%' }}>
        <div style={{ textAlign: 'left', padding: '0 36px' }}>
          {resolveExternalUpdate && (
            <FlexerRow marginBottom="8px">
              Some transactions have changed, which may impact this schedule.{' '}
              {!isDeleteUpdate && <InlineFlexWarnings>Update invoicing schedule data: </InlineFlexWarnings>}
            </FlexerRow>
          )}
          {removeScheduleMode || isDeleteUpdate ? (
            <InlineFlexWarnings style={{ minWidth: !isDeleteUpdate && 480 }}>
              Remove {invoicingSchedule?.customer_name} invoicing schedule!
            </InlineFlexWarnings>
          ) : (
            <FlexerRow alignItems="flex-start" gap="12px">
              <FirstLastInvoice>
                <span>First invoice</span>
                <b>
                  <TableCell
                    isChanged={changedFields[EXTERNAL_UPDATES_TABLE_COLUMNS.start_date.key]}
                    original={formatExternalUpdateInvoiceDate(
                      mappedTargetObject?.[EXTERNAL_UPDATES_TABLE_COLUMNS.start_date.key],
                    )}
                    updated={formatExternalUpdateInvoiceDate(
                      mappedExternalUpdateData?.[EXTERNAL_UPDATES_TABLE_COLUMNS.start_date.key],
                    )}
                    field={EXTERNAL_UPDATES_TABLE_COLUMNS.start_date.key}
                  />
                </b>
              </FirstLastInvoice>
              <FirstLastInvoice>
                <span>Last invoice</span>
                <b>
                  <TableCell
                    isChanged={changedFields[EXTERNAL_UPDATES_TABLE_COLUMNS.end_date.key]}
                    original={formatExternalUpdateInvoiceDate(
                      mappedTargetObject?.[EXTERNAL_UPDATES_TABLE_COLUMNS.end_date.key],
                    )}
                    updated={formatExternalUpdateInvoiceDate(
                      mappedExternalUpdateData?.[EXTERNAL_UPDATES_TABLE_COLUMNS.end_date.key],
                    )}
                    field={EXTERNAL_UPDATES_TABLE_COLUMNS.end_date.key}
                  />
                </b>
              </FirstLastInvoice>
            </FlexerRow>
          )}
        </div>

        <InvoiceChangesToApply
          externalUpdate={externalUpdate}
          resolveExternalUpdate={resolveExternalUpdate}
          updateData={updateData}
          invoicingSchedule={mappedTargetObject}
          isDeleteUpdate={isDeleteUpdate}
          removeScheduleMode={removeScheduleMode}
          onClose={onClose}
          onConfirmUpdate={onConfirmUpdate}
          onConfirmNoUpdate={onConfirmNoUpdate}
          customFooter={customFooter}
        />
      </div>
      {/* TODO: Show this warning but compute the total amounts based on the selected changes to be applied */}
      {/*
      validationResult && validationResult?.isValid === false && (
        <UnmatchedAmountsWarning fullWidth>
          <WarnBlock rightIcon={<TriangleWarningIcon />}>
            <p>
              The transaction amounts <b>({formatValueForDisplay(validationResult.scheduleTransactionsTotal)})</b>{' '}
              do not match the total invoice amounts{' '}
              <b>({formatValueForDisplay(validationResult.scheduleProposedInvoicesTotal)})</b>. You can still
              proceed with the changes, but it's recommended to review the invoicing schedule to ensure accuracy.
            </p>
          </WarnBlock>
        </UnmatchedAmountsWarning>
      )*/}
    </Column>
  );
};
