import { omit } from 'lodash';

export const DEFAULT_CUSTOMER_MAPPER = {
  customer_id: 'Customer ID (optional)',
  name: 'Customer name',
  parent_customer_id: 'Parent customer ID (optional)',
  email: 'Customer email (optional)',
  contacts: 'Contacts (optional)',
  addressLine1: 'Address line 1 (optional)',
  addressLine2: 'Address line 2 (optional)',
  city: 'City (optional)',
  state: 'State (optional)',
  postalcode: 'Postalcode (optional)',
  country: 'Country (optional)',
};

export const DEFAULT_CUSTOMER_CREATE_MAPPER = omit(DEFAULT_CUSTOMER_MAPPER, 'customer_id');
