import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { isNil } from 'lodash';
import { RECOGNITION_TYPES, NUMBER_FORMATS, SYSTEM_USER_ID, MAGIC_METADATA } from 'consts/global';
import { numberFormatter } from 'utils/formatters';
import { formatAddress } from 'models/common';
import { getCustomerDisplayName } from './customer';

dayjs.extend(utc);

export const transactionDisplayName = ({ transaction, currency }) =>
  `${transaction.name || transaction.customer_name || ''} / ${dayjs
    .utc(transaction.date)
    .format('MMM YY')} / ${numberFormatter({
    type: NUMBER_FORMATS.CURRENCY,
    rawValue: parseFloat(transaction.amount),
    currency,
  })}`;

export const transactionDisplayTitle = (transaction, options = { showProduct: true }) => {
  if (!transaction) return 'Unassigned Transaction';
  if (transaction.name) return transaction.name;
  const customerName = getCustomerDisplayName({
    customerName: transaction.customer_name,
    customerId: transaction.customer_id,
    customerEmail: transaction.customer_email,
  });

  let defaultName = `${customerName ?? 'Unassigned Transaction'}`;

  defaultName += ` - ${dayjs.utc(transaction.start_date).format('MM/YY')}`;

  defaultName += transaction.end_date
    ? `-${dayjs.utc(transaction.end_date).format('MM/YY')}`
    : transaction.recognition === RECOGNITION_TYPES.tillCanceled
    ? '-Till Canceled'
    : '-No end date';

  if (options.showProduct && transaction.product_name) {
    defaultName += ` (${transaction.product_name})`;
  }

  return defaultName;
};

export const transactionDisplayNameForBilling = (transaction, options = {}) =>
  transaction?.product_name
    ? options?.shortForm
      ? transaction.product_name
      : `${transactionDisplayTitle(transaction, { showProduct: false })} (${transaction.product_name})`
    : transactionDisplayTitle(transaction);

export const calculateTotalAmount = ({ startDate, endDate, recurringAmount, includeEndMonth = false }) => {
  if (startDate && recurringAmount) {
    const end = endDate ? dayjs.utc(endDate) : dayjs.utc();
    let monthsDiff = end.diff(dayjs.utc(startDate).startOf('month'), 'month');
    if (includeEndMonth) monthsDiff = monthsDiff + 1;
    //if the start date is in the future, then the total amount should be 0;
    return monthsDiff >= 0 ? recurringAmount * monthsDiff : 0;
  } else {
    return 0;
  }
};

export const calculateRecurringAmount = ({ startDate, endDate, totalAmount, includeEndMonth = false }) => {
  if (startDate && endDate && totalAmount) {
    let monthsDiff = dayjs.utc(endDate).startOf('month').diff(dayjs.utc(startDate).startOf('month'), 'month');
    if (includeEndMonth) monthsDiff = monthsDiff + 1;
    // if the start date is in the future, then the total amount should be 0;
    if (monthsDiff <= 0) {
      return 0;
    } else {
      return totalAmount / monthsDiff;
    }
  } else {
    return 0;
  }
};

// Sets 'recurring_amount' if transaction is 'till-canceled' and does have a 'recurring_amount'
// Calculates the 'recurring_amount' for a 'linear' transaction
export const normalizeAmountFields = (transaction) => {
  if (
    transaction.recognition === RECOGNITION_TYPES.tillCanceled &&
    isNil(transaction.recurring_amount) && // this should only be the case for UNCONFIRMED transactions
    !isNil(transaction.amount)
  ) {
    // this is because when we import "till_canceled" transactions, their "amount" gets imported as the "total amount",
    // when we want it to be the "recurring amount"
    transaction.recurring_amount = transaction.amount;
    transaction.amount = calculateTotalAmount({
      startDate: transaction.start_date,
      endDate: transaction.end_date,
      recurringAmount: transaction.recurring_amount,
      includeEndMonth: transaction.include_end_month,
    });
  } else if (
    !transaction.recognition && // this should only be the case for UNCONFIRMED transactions
    isNil(transaction.recurring_amount) &&
    !isNil(transaction.amount)
  ) {
    transaction.recurring_amount = transaction.amount;
  } else if (
    transaction.recognition === RECOGNITION_TYPES.linear &&
    !isNil(transaction.amount) &&
    isNil(transaction.recurring_amount)
  ) {
    transaction.recurring_amount = calculateRecurringAmount({
      startDate: transaction.start_date,
      endDate: transaction.end_date,
      totalAmount: transaction.amount,
      includeEndMonth: transaction.include_end_month,
    });
  }
  return transaction;
};

const normalizeDate = (val) => (dayjs(val).isValid() ? dayjs(val).format('YYYY-MM-DD') : null);
const normalizeNumber = (val) => (!isNil(val) ? Number(val).toFixed(2) : null);

export const MAPPING_FUNCTIONS = {
  start_date: normalizeDate,
  end_date: normalizeDate,
  date: normalizeDate,
  amount: normalizeNumber,
  recurring_amount: normalizeNumber,
};

export const READ_ONLY_METADATA = [MAGIC_METADATA.STAGE_NAME, MAGIC_METADATA.SHIPPING_ADDRESS];

export const wasManuallyEdited = (transaction) =>
  !isNil(transaction.updated_by) && transaction.updated_by.toString() !== SYSTEM_USER_ID.toString();

export const transactionHasShippingAddress = (transaction) =>
  !!transaction?.metadata?.[MAGIC_METADATA.SHIPPING_ADDRESS];

export const getShippingAddressOnTransaction = (transaction) => {
  if (!transaction?.metadata?.[MAGIC_METADATA.SHIPPING_ADDRESS]) return '';
  return formatAddress(transaction?.metadata?.[MAGIC_METADATA.SHIPPING_ADDRESS]);
};

// Used internally and should not be displayed or edited directly in the UI
export const INTERNAL_TRANSACTION_METADATA = {
  MONITOR_INVOICES_FOR_TILL_CANCELLED_ALERTS: 'monitor_invoices_for_till_cancelled_alerts',
};
