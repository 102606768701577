import { FormikCustomInput, FormikCustomSelector } from 'components/Controls';
import { RECOGNITION_TYPES } from 'consts/global';
import { ColumnMapperSelector } from 'shared/CsvUpload/CsvTable/CsvTable';
import { HeaderSelector, SelectorTitle } from 'shared/CsvUpload/styles';

export const getColumns = ({ formRef, csvColumns, csvColumnsMapper, setCsvColumnsMapper }) => {
  return [
    {
      Header: () => <></>,
      width: 30,
      accessor: 'status',
      Cell: () => <></>,
    },
    {
      Header: () => (
        <HeaderSelector>
          <SelectorTitle>Name</SelectorTitle>
          <ColumnMapperSelector
            field="name"
            csvColumnsMapper={csvColumnsMapper}
            setCsvColumnsMapper={setCsvColumnsMapper}
            csvColumns={csvColumns}
          />
        </HeaderSelector>
      ),
      accessor: 'name',
      width: 180,
      Cell: ({ row }) => (
        <FormikCustomInput
          style={{ height: 32 }}
          errorWithoutTooltip
          placeholder="Enter name..."
          width="100%"
          name={`[${row.original.rowIndex}].name`}
        />
      ),
    },
    {
      Header: () => (
        <HeaderSelector>
          <SelectorTitle>Recognition</SelectorTitle>

          <ColumnMapperSelector
            field="recognition"
            csvColumnsMapper={csvColumnsMapper}
            setCsvColumnsMapper={setCsvColumnsMapper}
            csvColumns={csvColumns}
          />
        </HeaderSelector>
      ),
      accessor: 'recognition',
      width: 180,
      Cell: ({ row }) => (
        <FormikCustomSelector
          height={32}
          placeholder="Select..."
          minWidth={160}
          menuWidth={200}
          name={`[${row.original.rowIndex}].recognition`}
          isClearable={true}
          options={Object.values(RECOGNITION_TYPES).map((recognition) => ({
            value: recognition,
            label: recognition,
          }))}
          handleChange={(option) => {
            if (option) {
              formRef?.current?.setFieldValue(`[${row.original.rowIndex}].recognition`, option.value);
            } else {
              formRef?.current?.setFieldValue(`[${row.original.rowIndex}].recognition`, null);
            }
          }}
        />
      ),
    },
    {
      Header: () => (
        <HeaderSelector>
          <SelectorTitle>Display name</SelectorTitle>
          <ColumnMapperSelector
            field="display_name"
            csvColumnsMapper={csvColumnsMapper}
            setCsvColumnsMapper={setCsvColumnsMapper}
            csvColumns={csvColumns}
          />
        </HeaderSelector>
      ),
      accessor: 'display_name',
      width: 180,
      Cell: ({ row }) => (
        <FormikCustomInput
          style={{ height: 32 }}
          errorWithoutTooltip
          placeholder="Enter display name..."
          width="100%"
          name={`[${row.original.rowIndex}].display_name`}
        />
      ),
    },
    {
      Header: () => (
        <HeaderSelector>
          <SelectorTitle>Description</SelectorTitle>
          <ColumnMapperSelector
            field="description"
            csvColumnsMapper={csvColumnsMapper}
            setCsvColumnsMapper={setCsvColumnsMapper}
            csvColumns={csvColumns}
          />
        </HeaderSelector>
      ),
      accessor: 'description',
      width: 180,
      Cell: ({ row }) => (
        <FormikCustomInput
          style={{ height: 32 }}
          errorWithoutTooltip
          placeholder="Enter description..."
          width="100%"
          name={`[${row.original.rowIndex}].description`}
        />
      ),
    },
  ];
};
