import { useContext } from 'react';

import { TransactionTillCanceledEndDateWarning } from './TransactionTillCanceledEndDateWarning';
import { TransactionModalMetadata } from './TransactionModalMetadata';
import { TransactionDetails } from './TransactionDetails';
import { TransactionRecognitionSection } from './TransactionRecognitionSection';
import { Wrapper, LeftPane, RightPane, TopSection, BodyContainer } from './TransactionBody.styles';
import { TransactionContext } from '../TransactionContext';

export const TransactionBody = () => {
  const { currentTransaction, isTransactionFullyLocked } = useContext(TransactionContext);
  const disabled = !!currentTransaction.replaced_by || isTransactionFullyLocked;
  return (
    <BodyContainer>
      <TopSection>
        <TransactionDetails />
      </TopSection>
      <Wrapper>
        <LeftPane disabled={disabled}>
          <TransactionTillCanceledEndDateWarning />
          <TransactionModalMetadata disabled={disabled} />
        </LeftPane>
        <RightPane disabled={isTransactionFullyLocked}>
          <TransactionRecognitionSection disabled={isTransactionFullyLocked} />
        </RightPane>
      </Wrapper>
    </BodyContainer>
  );
};
