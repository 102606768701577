import React, { useContext } from 'react';
import styled from 'styled-components';
import { useInitiateIntegration } from 'api/integrations';
import { getIntegrationDisplayName, getServiceCategory } from 'models/integration';
import {
  BannersContainer,
  Banner,
  BannerText,
  BannerActions,
  BannerButton,
  BannerTitle,
} from 'components/Blocks/Banner';
import { FlexerRow } from 'components/Core';
import { ReactComponent as AlertIcon } from 'images/alert-triangle.svg';
import { BillingContext } from '../BillingContext';

const Wrapper = styled.div`
  margin-top: 4px;
  margin-bottom: 16px;
`;

const ScheduleHeaderWrapper = styled.div`
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 32px;
  border-bottom: 1px solid var(--neutralGray);
  background: var(--tertiaryRed);
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;

  svg {
    width: 20px;
    height: 20px;
    margin-right: 8px;

    path {
      fill: white;
    }
  }
`;

const ScheduleHeaderTitle = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 900;
  line-height: 20px;
  color: white;
`;

const ScheduleHeaderSubtitle = styled.div`
  margin-left: 4px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: white;
`;

const ScheduleHeaderButton = styled.div`
  cursor: pointer;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  padding: 8px 12px;
  background: white;
  box-shadow: 2px 2px 12px 0px var(--primaryBlack2);
  border: 1px solid var(--accentGraySecond);
  border-radius: 100px;

  &:hover {
    color: var(--primaryBlack80);
    box-shadow: 2px 2px 12px 0px var(--primaryBlack20);
  }
`;

export const DisconnectedIntegrationBanner = ({ scheduleHeaderView, showOnlyForIntegrationId }) => {
  const { glDisconnectedIntegrations } = useContext(BillingContext);
  const { initiateIntegrationFlow } = useInitiateIntegration();

  if (glDisconnectedIntegrations.length === 0) {
    return <></>;
  }

  const disconnectedIntegrations = showOnlyForIntegrationId
    ? glDisconnectedIntegrations.filter((integration) => integration.id === showOnlyForIntegrationId)
    : glDisconnectedIntegrations;

  return (
    <>
      {disconnectedIntegrations.map((integration) =>
        scheduleHeaderView ? (
          <ScheduleHeaderWrapper
            onClick={() => initiateIntegrationFlow({ integration })}
            data-cy={`disconnected-integration-banner-${integration.id}`}
            key={integration.id}
          >
            <FlexerRow alignItems="center">
              <AlertIcon />
              <ScheduleHeaderTitle>
                {`Your ${getIntegrationDisplayName(integration)} integration is disconnected.`}
              </ScheduleHeaderTitle>
              <ScheduleHeaderSubtitle>
                {`Re-connect the ${getServiceCategory(
                  integration.service,
                )} integration so that Subscript can process invoices in your general ledger`}
              </ScheduleHeaderSubtitle>
            </FlexerRow>

            <ScheduleHeaderButton>{`Reintegrate ${getServiceCategory(integration.service)}`}</ScheduleHeaderButton>
          </ScheduleHeaderWrapper>
        ) : (
          <Wrapper data-cy={`disconnected-integration-banner-${integration.id}`} key={integration.id}>
            <div onClick={() => initiateIntegrationFlow({ integration })}>
              <BannersContainer redVersion>
                <Banner redVersion>
                  <BannerTitle redVersion>{`Your ${getIntegrationDisplayName(
                    integration,
                  )} integration is disconnected`}</BannerTitle>
                  <BannerText>
                    <div>{`Subscript can't process invoices in your general ledger. Please visit ${getServiceCategory(
                      integration.service,
                    )} and re-connect the integration for invoicing.`}</div>
                  </BannerText>
                  <BannerActions>
                    <BannerButton>{`Reintegrate ${getServiceCategory(integration.service)}`}</BannerButton>
                  </BannerActions>
                </Banner>
              </BannersContainer>
            </div>
          </Wrapper>
        ),
      )}
    </>
  );
};
