import React, { useContext } from 'react';

import { AppContext } from 'AppContext';
import { getSegmentOptions } from 'models/cohorts';
import { CentererVertical, Spacer } from 'components/Core';
import { FormikCustomSelector } from 'components/Controls';

export const CohortSegmentByField = () => {
  const { orgConfigs } = useContext(AppContext);

  return (
    <CentererVertical>
      <div>Cohort Segment By:</div>
      <Spacer width="8px" />
      <FormikCustomSelector
        width="220px"
        options={[
          { label: 'None', value: '' },
          ...getSegmentOptions({ orgConfigs }).map((segment) => ({ label: segment, value: segment })),
        ]}
        name="cohortSegmentBy"
      />
    </CentererVertical>
  );
};
