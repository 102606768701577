import React from 'react';
import styled from 'styled-components';
import { ReactComponent as HeaderIcon } from 'images/info-circle-icon.svg';
import { Modal, ModalBody, ModalButton, ModalContainer, ModalFooter, ModalHeader, ModalTitle } from 'components/Modal';

const StyledHeaderIcon = styled.div`
  display: flex;
  background-color: #ffffff;
  border-radius: 50%;
  left: 50%;
  padding: 10px;
  position: absolute;
  top: -20px;
  transform: translate(-50%, 0);
`;

export const ConfirmModal = ({
  title,
  content,
  closeButtonText,
  confirmButtonText,
  denyButtonText,
  deleteButtonText,
  onClose,
  onConfirm,
  onDeny,
  onDelete,
  width = '350px',
  contentPadding,
  centerFooter,
}) => {
  return (
    <>
      <ModalContainer data-cy="confirm-modal">
        <Modal maxWidth={width} height="auto" overflow="visible" compact>
          <StyledHeaderIcon>
            <HeaderIcon width="20px" height="20px" />
          </StyledHeaderIcon>
          <ModalHeader>
            <ModalTitle compact>
              <b>{title}</b>
            </ModalTitle>
          </ModalHeader>
          <ModalBody compact centered paddingLeft={contentPadding} paddingRight={contentPadding}>
            {content}
          </ModalBody>
          <ModalFooter centered={centerFooter} flexEnd transparent>
            {closeButtonText ? (
              <ModalButton default onClick={onClose} data-cy="confirm-modal__close-button">
                {closeButtonText}
              </ModalButton>
            ) : null}
            {denyButtonText ? (
              <ModalButton
                default
                data-cy="confirm-modal__deny-button"
                onClick={() => {
                  onDeny();
                  onClose();
                }}
              >
                {denyButtonText}
              </ModalButton>
            ) : null}
            {confirmButtonText ? (
              <ModalButton
                primary
                data-cy="confirm-modal__confirm-button"
                onClick={() => {
                  onConfirm();
                  onClose();
                }}
              >
                {confirmButtonText}
              </ModalButton>
            ) : null}
            {deleteButtonText ? (
              <ModalButton
                delete
                data-cy="confirm-modal__delete-button"
                onClick={() => {
                  onDelete();
                  onClose();
                }}
              >
                {deleteButtonText}
              </ModalButton>
            ) : null}
          </ModalFooter>
        </Modal>
      </ModalContainer>
    </>
  );
};
