import { useState, useContext, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useBillingEmailTemplatesAPI } from 'api/billing/hooks';
import { AppContext } from 'AppContext';
import { useConfirmModal } from 'shared/ConfirmModal';
import { capitalize } from 'utils/stringUtils';
import { useUrlQuery } from 'utils/hooks';
import { MAILER_SERVICES_READ_SCOPE, INTEGRATION_SERVICES, GMAIL_FEATURES } from 'consts/integrations';
import { GoogleSignInButton } from '../BillingSenderSetttings/GoogleAuth';
import { ConfirmModalContainer, ReconnectButtonContainer } from '../styles';

export const useCollectionSequence = ({ values, setFieldValue, validateAndSubmitForm, mailerIntegration }) => {
  const { orgId } = useContext(AppContext);
  const [showTemplateModal, setShowTemplateModal] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  const aiEnabled = useMemo(() => values?.billingReminderDefaults?.ai_enabled, [
    values?.billingReminderDefaults?.ai_enabled,
  ]);

  const {
    data: emailTemplates,
    isLoading,
    operations: { refetch: refetchBillingEmailTemplates, editBillingEmailTemplate, removeBillingEmailTemplate },
  } = useBillingEmailTemplatesAPI({
    orgId,
    aiTemplatesOnly: aiEnabled,
  });

  const query = useUrlQuery();
  const history = useHistory();
  const gauthReadStatus = query.get('gauthRead');

  useEffect(() => {
    const enableAi = async () => {
      if (gauthReadStatus === 'success') {
        await setFieldValue('billingReminderDefaults', { enabled: true, auto_send: true, ai_enabled: true });
        await Promise.resolve();
        await validateAndSubmitForm();
        await refetchBillingEmailTemplates();

        query.delete('gauthRead');
        history.replace({
          search: query.toString(),
        });
      }
    };

    enableAi();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const {
    openConfirmModal: openConfirmConfigureSenderModal,
    ConfirmModal: ConfirmConfigureSenderModal,
  } = useConfirmModal({
    title: 'Email Sender Not Configured',
    width: '500px',
    content: (
      <ConfirmModalContainer>
        <p>
          To use this feature, you need to select "Create and send Subscript invoice PDFs by using your custom email"
          and integrate the mailer service you want to use.
        </p>
      </ConfirmModalContainer>
    ),
    denyButtonText: 'Cancel',
    confirmButtonText: 'Go to Settings',
    onConfirm: () => (window.location.href = '/configure?tab=subscript-billing&subTab=billing-general-settings'),
    onDeny: () => {},
  });

  const SignInButtonByMailer = {
    // add mapping here for other mailer ie. Microsoft
    [INTEGRATION_SERVICES.GMAIL]: GoogleSignInButton,
  };
  const SignInComponent = SignInButtonByMailer[mailerIntegration?.service] || (() => <div>Sign-in not available</div>);
  const {
    openConfirmModal: openConfirmReauthenticateMailerModal,
    ConfirmModal: ConfirmReauthenticateMailerModal,
  } = useConfirmModal({
    title: 'Additional Permissions Required',
    width: '500px',
    content: (
      <ConfirmModalContainer>
        Your current {capitalize(mailerIntegration?.service)} permissions are insufficient for this feature. Please
        re-login to your account to grant the necessary permissions.
        <ReconnectButtonContainer>
          <p>Subscript will automatically save the settings after reconnecting account</p>
          <SignInComponent features={[GMAIL_FEATURES.AI_REMINDERS]} />
        </ReconnectButtonContainer>
      </ConfirmModalContainer>
    ),
    denyButtonText: 'Cancel',
    onDeny: () => {},
  });

  const handleSelectedOptionChange = async (value) => {
    if (value.ai_enabled) {
      const requiredReadScope = MAILER_SERVICES_READ_SCOPE[mailerIntegration?.service];
      const notEnoughPermission = !mailerIntegration?.metadata?.scope?.includes(requiredReadScope);
      if (values?.billingSenderDefaults?.sender_mailer !== mailerIntegration?.service) {
        openConfirmConfigureSenderModal();
        return;
      } else if (notEnoughPermission) {
        openConfirmReauthenticateMailerModal();
        return;
      }
    }

    await setFieldValue('billingReminderDefaults', value);
    refetchBillingEmailTemplates();
  };

  return {
    emailTemplates,
    isLoading,
    showTemplateModal,
    setShowTemplateModal,
    showRemoveModal,
    setShowRemoveModal,
    selectedTemplate,
    setSelectedTemplate,
    handleSelectedOptionChange,
    ConfirmConfigureSenderModal,
    ConfirmReauthenticateMailerModal,
    editBillingEmailTemplate,
    removeBillingEmailTemplate,
  };
};
