const FORECAST_SCENARIO_TYPE = {
  WEIGHTED: 'weighted',
  UNWEIGHTED: 'unweighted',
};

const EXISTING_CUSTOMERS_FORECAST_TYPE = {
  NRR: 'nrr',
  PIPELINE: 'pipeline',
};

const NEW_CUSTOMERS_FORECAST_TYPE = {
  PIPELINE_WEIGHTED: 'pipeline_weighted',
  PIPELINE_UNWEIGHTED: 'pipeline_unweighted',
};

const FORECAST_DETAILS_FIELD = {
  EXISTING: 'customers_existing',
  NEW: 'customers_new',
};

export {
  FORECAST_SCENARIO_TYPE,
  EXISTING_CUSTOMERS_FORECAST_TYPE,
  NEW_CUSTOMERS_FORECAST_TYPE,
  FORECAST_DETAILS_FIELD,
};
