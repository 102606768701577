import React, { useContext } from 'react';
import { Form, Formik } from 'formik';
import { AppContext } from 'AppContext';
import { numberFormatter } from 'utils/formatters';
import { Centerer, FlexBetweenContainer, Spacer } from 'components/Core';
import { TooltipContainer } from 'components/Tooltip';
import { NUMBER_FORMATS } from 'consts/global';
import { FORECAST_DETAILS_FIELD } from '../consts';
import { CustomizableMetric } from '../Shared/CustomizableMetric';
import { CustomerNameCell } from '../Shared/CustomerNameCell';
import { SectionItemCard, SectionItemCardSecondaryText } from './styles';

export const RenewalCustomersCard = ({
  editForecastDetails,
  forecastId,
  month,
  values,
  onCustomerClick,
  isDragging,
}) => {
  const {
    appSettings: { isARR, currencyISOCode: currency },
  } = useContext(AppContext);
  const handleSubmit = (values) => {
    editForecastDetails({
      forecastId,
      forecastMonth: month,
      forecastType: FORECAST_DETAILS_FIELD.EXISTING,
      customerId: values.customer_id,
      data: {
        pipeline_probability: values.pipeline_probability / 100,
        pipeline_recurring_revenue: values.pipeline_recurring_revenue / (isARR ? 12 : 1),
        pipeline_closed_date: values.pipeline_closed_date,
      },
    });
  };

  return (
    <Formik
      initialValues={{
        customer_id: values.customer_id,
        pipeline_probability: values.pipeline_probability * 100,
        pipeline_recurring_revenue: values.pipeline_recurring_revenue,
        pipeline_closed_date: values.pipeline_closed_date,
      }}
      onSubmit={handleSubmit}
      enableReinitialize={true}
    >
      <Form>
        <SectionItemCard isDragging={isDragging}>
          <CustomerNameCell
            externalServiceName={values.external_service_name}
            externalUrl={values.external_url}
            onCustomerClick={() => onCustomerClick(values.customer_id)}
          >
            {values.customer_name}
          </CustomerNameCell>

          <Spacer height="12px" />

          <FlexBetweenContainer centerer gap="4px">
            <CustomizableMetric
              name="pipeline_closed_date"
              dataType="calendar"
              value={values.pipeline_closed_date}
              explanation="Thus deal is expected to close on this date"
              missingExplanation="This deal is missing a close date"
              isSecondary={values.pipeline_closed_date === null}
            />

            <Centerer gap="4px">
              {values.forecasted_amount > values.pipeline_recurring_revenue && (
                <TooltipContainer width={120} fontSize="12px" toolTipContent="Existing revenue">
                  <SectionItemCardSecondaryText>
                    {numberFormatter({ type: NUMBER_FORMATS.CURRENCY, rawValue: values.initial_amount, currency })}
                    {' + '}
                  </SectionItemCardSecondaryText>
                </TooltipContainer>
              )}
              <CustomizableMetric
                name="pipeline_recurring_revenue"
                dataType={NUMBER_FORMATS.CURRENCY}
                value={values.pipeline_recurring_revenue ?? 0}
              />
              {values.forecasted_amount > values.pipeline_recurring_revenue && (
                <TooltipContainer
                  width={220}
                  fontSize="12px"
                  toolTipContent="Includes existing revenue and the renewal revenue"
                >
                  <Centerer gap="4px">
                    {' '}
                    ={' '}
                    <SectionItemCardSecondaryText>
                      {numberFormatter({ type: NUMBER_FORMATS.CURRENCY, rawValue: values.forecasted_amount, currency })}
                    </SectionItemCardSecondaryText>
                  </Centerer>
                </TooltipContainer>
              )}
            </Centerer>
          </FlexBetweenContainer>
        </SectionItemCard>
      </Form>
    </Formik>
  );
};
