import React, { useContext } from 'react';
import styled from 'styled-components';

import { DashboardContext } from 'views/Dashboard/DashboardContext';
import { DashboardCardsGrid } from './DashboardCardsGrid';

const Wrapper = styled.div`
  margin-top: 28px;
  margin-bottom: 44px;
`;

export const DashboardOverview = ({ exportScreenshotRef }) => {
  const { metricsSetting, CardChartModal } = useContext(DashboardContext);

  return (
    <Wrapper ref={exportScreenshotRef}>
      {metricsSetting?.length > 0 && (
        <>
          <DashboardCardsGrid />
          <CardChartModal />
        </>
      )}
    </Wrapper>
  );
};
