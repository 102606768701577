import React from 'react';
import { useField } from 'formik';
import { FlexerColumn } from 'components/Core';
import { ReactComponent as TriangleIcon } from 'images/input_error_message_triagle.svg';
import { InputErrorMessage } from 'components/Blocks';
import { EmailSelector } from '../EmailSelector';

export const FormikEmailSelector = (props) => {
  const useFieldProps = useField(props);
  const meta = useFieldProps[1];

  return (
    <FlexerColumn width={props.containerWidth}>
      <EmailSelector {...props} />
      {meta.touched && meta.error ? (
        <InputErrorMessage>
          <TriangleIcon />
          {meta.error}
        </InputErrorMessage>
      ) : null}
    </FlexerColumn>
  );
};
