// If you change these, please also change the same functions on the backend

export const calculateSummaryStats = ({ isARR, summaryStats }) => {
  if (!summaryStats) return null;
  if (!isARR) return summaryStats;
  return Object.entries(summaryStats).reduce((total, [key, value]) => {
    total[key] = {
      ...value,
      New: value?.New * 12,
      recurringRevenueTotal: value?.recurringRevenueTotal * 12,
      nonRenewalUpsell: value?.nonRenewalUpsell * 12,
      upForRenewal: value?.upForRenewal * 12,
      amountRenewedThatWasUpGross: value?.amountRenewedThatWasUpGross * 12,
      amountRenewedThatWasUpNet: value?.amountRenewedThatWasUpNet * 12,
      committedRecurring: value?.committedRecurring * 12,
      recurringARPU: {
        recurringRevenueTotal: value?.recurringARPU?.recurringRevenueTotal * 12,
        totalSeats: value?.recurringARPU?.totalSeats,
        value: value?.recurringARPU?.value * 12,
      },
      newRecurringARPU: {
        newRevenue: value?.newRecurringARPU?.newRevenue * 12,
        newSeats: value?.newRecurringARPU?.newSeats,
        value: value?.newRecurringARPU?.value * 12,
      },
      allARPA: {
        ARPArevenueStatsTotalARR: value?.allARPA?.ARPArevenueStatsTotal * 12,
        ARPAcustomerStatsTotal: value?.allARPA?.ARPAcustomerStatsTotal,
        value: value?.allARPA?.value * 12,
      },
      allARPA_TTM: {
        ARPArevenueStatsTotalARR: value?.allARPA_TTM?.ARPArevenueStatsTotal * 12,
        ARPAcustomerStatsTotal: value?.allARPA_TTM?.ARPAcustomerStatsTotal,
        value: value?.allARPA_TTM?.value * 12,
      },
      newARPA: {
        ARPArevenueStatsNewARR: value?.newARPA?.ARPArevenueStatsNew * 12,
        ARPAcustomerStatsNew: value?.newARPA?.ARPAcustomerStatsNew,
        value: value?.newARPA?.value * 12,
      },
      newARPA_TTM: {
        ARPArevenueStatsNewARR: value?.newARPA_TTM?.ARPArevenueStatsNew * 12,
        ARPAcustomerStatsNew: value?.newARPA_TTM?.ARPAcustomerStatsNew,
        value: value?.newARPA_TTM?.value * 12,
      },
      grossUpForRenewalRetention: {
        amountRenewedThatWasUpGross: value?.grossUpForRenewalRetention?.amountRenewedThatWasUpGross * 12,
        upForRenewal: value?.grossUpForRenewalRetention?.upForRenewal * 12,
        value: value?.grossUpForRenewalRetention?.value,
      },
      netUpForRenewalRetention: {
        amountRenewedThatWasUpNet: value?.netUpForRenewalRetention?.amountRenewedThatWasUpNet * 12,
        upForRenewal: value?.netUpForRenewalRetention?.upForRenewal * 12,
        value: value?.netUpForRenewalRetention?.value,
      },
      netNewRevenue: {
        newRevenue: value?.netNewRevenue?.newRevenue * 12,
        upsellRevenue: value?.netNewRevenue?.upsellRevenue * 12,
        downsellRevenue: value?.netNewRevenue?.downsellRevenue * 12,
        churnRevenue: value?.netNewRevenue?.churnRevenue * 12,
        value: value?.netNewRevenue?.value * 12,
      },
      netNewRevenue_TTM: {
        newRevenue: value?.netNewRevenue_TTM?.newRevenue * 12,
        upsellRevenue: value?.netNewRevenue_TTM?.upsellRevenue * 12,
        downsellRevenue: value?.netNewRevenue_TTM?.downsellRevenue * 12,
        churnRevenue: value?.netNewRevenue_TTM?.churnRevenue * 12,
        value: value?.netNewRevenue_TTM?.value * 12,
      },
      newRevenueToCostsRatio: {
        costMarketing: value?.newRevenueToCostsRatio?.costMarketing,
        costSales: value?.newRevenueToCostsRatio?.costSales,
        newRevenue: value?.newRevenueToCostsRatio?.newRevenue * 12,
        value: value?.newRevenueToCostsRatio?.value * 12,
      },
      waterfall: {
        ...value?.waterfall,
      },
      winbackRevenue: value?.winbackRevenue * 12,
    };

    return total;
  }, {});
};

export const calculateRevenueNURDCStats = ({ isARR, revenueNURDCStats }) => {
  if (!revenueNURDCStats) return null;
  if (!isARR) return revenueNURDCStats;
  return Object.entries(revenueNURDCStats).reduce((result, [month, value]) => {
    result[month] = {
      ...value,
      Churn: value?.Churn * 12,
      Downsell: value?.Downsell * 12,
      New: value?.New * 12,
      Existing: value?.Existing * 12,
      Total: value?.Total * 12,
      Upsell: value?.Upsell * 12,
      upForRenewal: value?.upForRenewal * 12,
      amountRenewedThatWasUpGross: value?.amountRenewedThatWasUpGross * 12,
      amountRenewedThatWasUpNet: value?.amountRenewedThatWasUpNet * 12,
      committedRecurring: value?.committedRecurring * 12,
      nonRenewalUpsell: value?.nonRenewalUpsell * 12,
    };
    return result;
  }, {});
};

export const calculateSaasStats = ({ isARR, saasStats }) => {
  if (!saasStats) return null;
  if (!isARR) return saasStats;
  return Object.entries(saasStats).reduce((result, [month, value]) => {
    result[month] = {
      ...value,
      recurringARPU: {
        recurringRevenueTotal: value?.recurringARPU?.recurringRevenueTotal * 12,
        totalSeats: value?.recurringARPU?.totalSeats,
        value: value?.recurringARPU?.value * 12,
      },
      newRecurringARPU: {
        newRevenue: value?.newRecurringARPU?.newRevenue * 12,
        newSeats: value?.newRecurringARPU?.newSeats,
        value: value?.newRecurringARPU?.value * 12,
      },
      ARPA: {
        ARPArevenueStatsTotalARR: value?.ARPA?.ARPArevenueStatsTotal * 12,
        ARPAcustomerStatsTotal: value?.ARPA?.ARPAcustomerStatsTotal,
        value: value?.ARPA?.value * 12,
      },
      ARPA_TTM: {
        ARPArevenueStatsTotalARR: value?.ARPA_TTM?.ARPArevenueStatsTotal * 12,
        ARPAcustomerStatsTotal: value?.ARPA_TTM?.ARPAcustomerStatsTotal,
        value: value?.ARPA_TTM?.value * 12,
      },
      newARPA: {
        ARPArevenueStatsNewARR: value?.newARPA?.ARPArevenueStatsNew * 12,
        ARPAcustomerStatsNew: value?.newARPA?.ARPAcustomerStatsNew,
        value: value?.newARPA?.value * 12,
      },
      newARPA_TTM: {
        ARPArevenueStatsNewARR: value?.newARPA_TTM?.ARPArevenueStatsNew * 12,
        ARPAcustomerStatsNew: value?.newARPA_TTM?.ARPAcustomerStatsNew,
        value: value?.newARPA_TTM?.value * 12,
      },
      grossUpForRenewalRetention: {
        amountRenewedThatWasUpGross: value?.grossUpForRenewalRetention?.amountRenewedThatWasUpGross * 12,
        upForRenewal: value?.grossUpForRenewalRetention?.upForRenewal * 12,
        value: value?.grossUpForRenewalRetention?.value,
      },
      netUpForRenewalRetention: {
        amountRenewedThatWasUpNet: value?.netUpForRenewalRetention?.amountRenewedThatWasUpNet * 12,
        upForRenewal: value?.netUpForRenewalRetention?.upForRenewal * 12,
        value: value?.netUpForRenewalRetention?.value,
      },
      netNewRevenue: {
        newRevenue: value?.netNewRevenue?.newRevenue * 12,
        upsellRevenue: value?.netNewRevenue?.upsellRevenue * 12,
        downsellRevenue: value?.netNewRevenue?.downsellRevenue * 12,
        churnRevenue: value?.netNewRevenue?.churnRevenue * 12,
        value: value?.netNewRevenue?.value * 12,
      },
      netNewRevenue_TTM: {
        newRevenue: value?.netNewRevenue_TTM?.newRevenue * 12,
        upsellRevenue: value?.netNewRevenue_TTM?.upsellRevenue * 12,
        downsellRevenue: value?.netNewRevenue_TTM?.downsellRevenue * 12,
        churnRevenue: value?.netNewRevenue_TTM?.churnRevenue * 12,
        value: value?.netNewRevenue_TTM?.value * 12,
      },
      newRevenueToCostsRatio: {
        costMarketing: value?.newRevenueToCostsRatio?.costMarketing,
        costSales: value?.newRevenueToCostsRatio?.costSales,
        newRevenue: value?.newRevenueToCostsRatio?.newRevenue * 12,
        value: value?.newRevenueToCostsRatio?.value * 12,
      },
      winbackRevenue: value?.winbackRevenue * 12,
    };
    return result;
  }, {});
};
