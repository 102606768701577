import { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { SwitchWithLabel } from 'components/Controls';
import { Row } from 'components/Core';
import { Popover, usePortal } from 'components/Portal';
import { BillingContext } from 'views/Billing/BillingContext';
import { InvoicingScheduleContext } from 'views/Billing/InvoicingScheduleModal/InvoicingScheduleContext';

const SettingButton = styled.div`
  cursor: pointer;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  padding: 8px 12px;
  border-radius: 8px;
  background: white;
  border: 1px solid var(--neutralGray);
  box-shadow: 2px 2px 12px 0px var(--primaryBlack2);

  &:hover {
    color: var(--primaryBlack75);
  }
`;

const ApplyButton = styled.div`
  width: 100%;
  border-radius: 8px;
  background-color: var(--primaryBlue10);
  padding: 8px;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  color: var(--primaryBlue);
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin-top: 16px;

  &:hover {
    background-color: var(--primaryBlue20);
  }
`;

const SwitchLabel = styled.div`
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  margin-left: 8px;
`;

export const RemindersSettings = ({ notSentReminders }) => {
  const { bulkUpdateReminders } = useContext(BillingContext);
  const { selectedReminder, setSelectedReminder } = useContext(InvoicingScheduleContext);

  const isAllReminderAutoSend = notSentReminders?.every((reminder) => !!reminder?.auto_send);

  const [autoSendToggle, setAutoSendToggle] = useState();

  useEffect(() => {
    setAutoSendToggle(isAllReminderAutoSend);
  }, [isAllReminderAutoSend]);

  const { triggerRef, togglePortal, isPortalVisible, Portal } = usePortal({
    XOffset: 20,
    YOffset: 50,
  });

  const handleApplySettings = async () => {
    let updatedReminders = [];

    try {
      updatedReminders = await bulkUpdateReminders({
        data: { data: { auto_send: !!autoSendToggle }, ids: notSentReminders?.map((reminder) => reminder.id) },
      });

      const updatedSelectedReminder = updatedReminders?.find((reminder) => reminder.id === selectedReminder?.id);
      setSelectedReminder({ ...selectedReminder, auto_send: updatedSelectedReminder?.auto_send });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <SettingButton data-cy="reminders-settings-button" ref={triggerRef} onClick={togglePortal}>
        Settings
      </SettingButton>

      {isPortalVisible && (
        <Portal>
          <Popover padding="12px" width="200px">
            <Row vertical="flex-start">
              <SwitchWithLabel
                bolded
                name="reminders-settings-auto-send"
                checked={autoSendToggle}
                onChange={async (value) => {
                  setAutoSendToggle(value);
                }}
              />
              <SwitchLabel>Auto-send all reminders for this invoice</SwitchLabel>
            </Row>

            <ApplyButton data-cy="apply-reminders-settings-button" onClick={handleApplySettings}>
              Apply Settings
            </ApplyButton>
          </Popover>
        </Portal>
      )}
    </>
  );
};
