import { useQuery, useMutation, useQueryClient } from 'react-query';
import * as Sentry from '@sentry/react';
import { useToasts } from 'components/Toasts';
import { isNil, isNull, flatten, uniq } from 'lodash';
import { humanize } from 'utils/stringUtils';
import { CACHE_KEY as MONTHLY_BREAKDOWN_CACHE_KEY } from 'api/monthlyBreakdown';
import { CACHE_KEY as REVENUE_DETAILS_CACHE_KEY } from 'api/revenueDetails';
import { CACHE_KEY as REVENUE_SPREADS_CACHE_KEY } from 'api/revenueSpreads';
import { CACHE_KEY as CUSTOMERS_CACHE_KEY } from 'api/customers';
import { useDelegatedMutationPolling } from 'api/jobHooks.helper';
import { CACHE_KEY as TRANSACTIONS_CACHE_KEY } from 'api/transactions';
import { CACHE_KEY as BILLING_CACHE_KEY } from 'api/billing';
import { ORGANIZATION_HELPERS_CACHE_KEY } from 'api/organizations/hooks';

import { SuccessToastWithUndo, DetailedFailureToast } from 'shared/Toasts';
import {
  getExternalUpdates,
  resolveExternalUpdate as _resolveExternalUpdate,
  bulkApproveUpdates as _bulkApproveUpdates,
  bulkDismissUpdates as _bulkDismissUpdates,
  bulkResolveUpdates as _bulkResolveUpdates,
  undoUpdate as _undoUpdate,
  bulkUndoUpdates as _bulkUndoUpdates,
} from './requests';

export const CACHE_KEY = 'externalUpdates';

const OPERATION_NAME_FOR_ACTION_TYPE = {
  overwrite: 'updated',
  dismiss: 'dismissed',
};

export const useExternalUpdatesAPI = ({ orgId, params, autoFetch = true, enableToasts = true }) => {
  const {
    pushToast: wrappedPushToast,
    pushError: wrappedPushError,
    pushCustomToast: wrappedPushCustomToast,
  } = useToasts();
  const pushToast = (...args) => {
    if (enableToasts) wrappedPushToast(...args);
  };
  const pushError = (...args) => {
    if (enableToasts) wrappedPushError(...args);
  };
  const pushCustomToast = (...args) => {
    if (enableToasts) wrappedPushCustomToast(...args);
  };

  const dataKey = [CACHE_KEY, orgId, params];

  const { data, error, isLoading, isFetching } = useQuery(
    dataKey,
    async () => {
      if (isNull(params.objectId)) return []; // Not undefined, but null
      return await getExternalUpdates({ orgId, params });
    },
    { enabled: autoFetch },
  );

  const queryClient = useQueryClient();

  const invalidateAll = () => {
    queryClient.invalidateQueries(CACHE_KEY);
    queryClient.invalidateQueries(TRANSACTIONS_CACHE_KEY);
    queryClient.invalidateQueries(MONTHLY_BREAKDOWN_CACHE_KEY);
    queryClient.invalidateQueries(REVENUE_DETAILS_CACHE_KEY);
    queryClient.invalidateQueries(REVENUE_SPREADS_CACHE_KEY);
    queryClient.invalidateQueries(CUSTOMERS_CACHE_KEY);
    queryClient.invalidateQueries(ORGANIZATION_HELPERS_CACHE_KEY);
    queryClient.invalidateQueries(BILLING_CACHE_KEY);
  };

  const resolveExternalUpdate = useMutation(
    ({ id, data, enableToasts = true }) =>
      _resolveExternalUpdate({ orgId, externalUpdateId: id, body: data, enableToasts }),
    {
      onMutate: async ({ id }) => {
        await queryClient.cancelQueries(dataKey);

        const previousExternalUpdates = queryClient.getQueryData(dataKey);

        queryClient.setQueryData(dataKey, (old) => {
          return { ...old, data: old.data?.filter((oldUpdate) => oldUpdate.externalUpdate.id !== id.toString()) };
        });

        return {
          previousExternalUpdates,
          updateData: previousExternalUpdates.data.filter((update) => update.externalUpdate.id === id.toString())[0],
        };
      },

      onError: (_err, _newCustomer, context) => {
        queryClient.setQueryData(dataKey, context.previousExternalUpdates);

        pushToast('Failed to resolve external update.', 'error');
      },

      onSuccess: (_res, variables, context) => {
        const operationName = OPERATION_NAME_FOR_ACTION_TYPE[variables.data.actionType];

        if (
          context.updateData.externalUpdate.type === 'resync' &&
          (isNil(variables?.enableToasts) || variables?.enableToasts)
        ) {
          pushToast(
            `"${context.updateData.targetObject.name ?? context.updateData.targetObject.customer_name}" ${humanize(
              params?.objectType,
            )} was successfully ${operationName}`,
            'success',
          );
        } else {
          if (isNil(variables?.enableToasts) || variables?.enableToasts) {
            pushCustomToast({
              CustomToast: (
                <SuccessToastWithUndo
                  message={`"${
                    context.updateData.targetObject.name ?? context.updateData.targetObject.customer_name
                  }" ${humanize(params?.objectType)} was successfully ${operationName}`}
                  onAction={() => undoUpdate.mutate({ id: context.updateData.externalUpdate.id })}
                  dataCyLabel="external-updates"
                />
              ),
              timeout: 10000,
            });
          }
        }
      },

      onSettled: invalidateAll,
    },
  );

  const bulkApproveUpdates = useMutation(() => _bulkApproveUpdates({ orgId }), {
    onSuccess: () => {
      pushToast('Successfully approved updates in bulk!', 'success', 10000);
    },
    onError: (err) => {
      pushError(err, 'Failed to approve updates in bulk.', 10000);
    },
    onSettled: invalidateAll,
  });

  const bulkDismissUpdates = useMutation(() => _bulkDismissUpdates({ orgId }), {
    onSuccess: () => {
      pushToast('Successfully dismissed updates in bulk!', 'success', 10000);
    },
    onError: (err) => {
      pushError(err, 'Failed to dismiss updates in bulk.', 10000);
    },
    onSettled: invalidateAll,
  });

  const bulkResolveUpdates = useDelegatedMutationPolling({
    mutationFn: async ({ externalUpdateIds, actionType }) =>
      _bulkResolveUpdates({ orgId, externalUpdateIds, actionType }),
    mutationOptions: {
      onProgress: () =>
        pushToast(
          `Bulk resolving updates is in progress, but may take a while. Please check back in a few minutes.`,
          'success',
          10000,
        ),
      onFailed: () => {
        throw new Error(`We did not finish resolving updates. Please try again later.`);
      },
      onMaxAttempts: () => {
        Sentry.captureMessage(`Bulk resolving updates is taking long to complete for org ${orgId}`, 'warning');
      },
      onError: (err) => {
        pushError(err, 'Failed to resolve any update', -1);
      },
      onSuccess: (response) => {
        const successful = response.successful ?? [];
        const failed = response.failed ?? {};

        let message;
        const allErrorMessages = flatten(Object.values(failed));
        const errorMessages = uniq(allErrorMessages);

        if (successful.length && !Object.keys(failed).length) {
          message = `Successfully resolved ${successful.length} updates in bulk!`;
        } else if (successful.length && Object.keys(failed).length) {
          message =
            `Successfully resolved ${successful.length} updates in bulk, but couldn't resolve ` +
            `${allErrorMessages.length} updates due to some errors below:`;
        } else if (Object.keys(failed).length) {
          pushCustomToast({
            CustomToast: (
              <DetailedFailureToast
                message="Failed to resolve any update due to some errors below:"
                errorMessages={errorMessages}
                dismissible
              />
            ),
            timeout: -1,
          });
          return;
        } else {
          pushError('Failed to resolve any update', null, -1);
          return;
        }

        pushCustomToast({
          CustomToast: (
            <SuccessToastWithUndo
              type={errorMessages.length ? 'warning' : 'success'}
              message={message}
              errorMessages={errorMessages}
              onAction={() => bulkUndoUpdates.mutate({ externalUpdateIds: successful.map((update) => update.id) })}
              dataCyLabel="external-updates"
            />
          ),
          timeout: -1,
        });
      },
      onMutate: async ({ externalUpdateIds }) => {
        await queryClient.cancelQueries(dataKey);

        const previousExternalUpdates = queryClient.getQueryData(dataKey);

        const idsObject = {};
        for (const id of externalUpdateIds) idsObject[id.toString()] = true;

        queryClient.setQueryData(dataKey, (old) => {
          return { ...old, data: old.data?.filter((oldUpdate) => !idsObject[oldUpdate.externalUpdate.id]) };
        });

        return { previousExternalUpdates };
      },

      onSettled: invalidateAll,
    },
  });

  const undoUpdate = useMutation(({ id }) => _undoUpdate({ orgId, externalUpdateId: id }), {
    onError: () => {
      pushToast('Failed to undo external update.', 'error');
    },

    onSuccess: (_data) => {
      pushToast('Successfully undid external update!', 'success');
    },

    onSettled: invalidateAll,
  });

  const bulkUndoUpdates = useMutation(({ externalUpdateIds }) => _bulkUndoUpdates({ orgId, externalUpdateIds }), {
    onSuccess: (response) => {
      if (response.successful?.length && !response.failed?.length)
        pushToast('Successfully undid updates in bulk!', 'success');
      else if (response.successful?.length && response.failed?.length)
        pushToast(
          `Successfully undid ${response.successful.length} updates in bulk, but couldn't undo ${response.failed.length} updates - please edit the transactions manually`,
          'success',
        );
      else pushError('Failed to undo updates in bulk.', 'Failed to undo updates in bulk.');
    },

    onError: (err) => {
      pushError(err, 'Failed to undo updates in bulk.');
    },

    onSettled: invalidateAll,
  });

  return {
    data,
    error,
    isLoading,
    isFetching,
    operations: {
      resolveExternalUpdate,
      bulkApproveUpdates,
      bulkDismissUpdates,
      bulkResolveUpdates,
      undoUpdate,
      bulkUndoUpdates,
    },
  };
};
