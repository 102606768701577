import React, { useContext } from 'react';
import styled from 'styled-components';
import { Bar, defaults } from 'react-chartjs-2';
import { cssVar } from 'polished';

import { AppContext } from 'AppContext';
import { LegendKeysContainer, LegendContainer, LegendLabel, Legend, LegendKey, LegendKeyDot } from 'components/Graph';
import { reFormatDate } from 'utils/dateUtils';
import { GROWTH_TYPE_OPTIONS, NUMBER_FORMATS } from 'consts/global';
import { DashboardContext } from 'views/Dashboard/DashboardContext';
import { numberFormatter } from 'utils/formatters';

import { CardChartModalContext } from './CardChartModalContext';

const ChartLegendContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const ChartContainer = styled.div`
  height: 362px;
`;

const CHART_COLORS = {
  PURPLE: cssVar('--primaryPurple'),
  GREEN: cssVar('--primaryGreen'),
  RED: cssVar('--primaryRed'),
  BLUE: cssVar('--primaryBlue'),
  DARK: cssVar('--accentDarkGray'),
};

export const CardChartBarChart = () => {
  defaults.global.defaultFontFamily = 'Nunito Sans';

  const {
    appSettings: { currencyISOCode: currency },
  } = useContext(AppContext);
  const { growthType } = useContext(DashboardContext);
  const { metric, title, months, chartData, growthData, selectedGoal, goalData } = useContext(CardChartModalContext);

  const valueType = metric.dataType;
  const data = chartData;
  const goalName = selectedGoal?.name;

  const barValues = [];
  const labels = [];
  months.forEach((month) => {
    barValues.push(data[month]);
    labels.push(reFormatDate(month, 'YYYY-MM', 'MMM YYYY'));
  });

  // convert percentages
  let percentageValues;
  if (valueType === NUMBER_FORMATS.PERCENT) {
    percentageValues = barValues.map((value) => Math.round(value * 100));
  }

  const barData = {
    label: title,
    type: 'bar',
    backgroundColor: (valueType === NUMBER_FORMATS.PERCENT ? percentageValues : barValues)?.reduce(
      (acc, item, index) => {
        const goalValue = goalData?.[months[index]];
        acc.push(
          !goalData ? CHART_COLORS.PURPLE : !goalValue || item >= goalValue ? CHART_COLORS.GREEN : CHART_COLORS.RED,
        );
        return acc;
      },
      [],
    ),
    data: valueType === NUMBER_FORMATS.PERCENT ? percentageValues : barValues,
    yAxisID: 'y1',
    barThickness: 18,
  };

  const yAxes = [
    {
      id: 'y1',
      position: 'left',
      gridLines: {
        drawBorder: false,
      },
      ticks: {
        suggestedMin:
          valueType !== NUMBER_FORMATS.PERCENT
            ? Math.min(...barValues)
            : ((Math.min(...percentageValues) - 1) / 10) * 10,
        suggestedMax:
          valueType !== NUMBER_FORMATS.PERCENT
            ? Math.max(...barValues)
            : ((Math.max(...percentageValues) + 1) / 10) * 10,
        callback: function (value) {
          if (valueType === NUMBER_FORMATS.CURRENCY) {
            return numberFormatter({ type: valueType, rawValue: value, currency });
          } else if (valueType === NUMBER_FORMATS.PERCENT) {
            return value.toString() + '%';
          } else {
            return value.toString();
          }
        },
        fontStyle: 'bold',
      },
    },
  ];

  const datasets = [barData];
  let addedGrowthLineChart = false;
  // we only add the "growth rate" percent line chart if the original metric is not a percentage
  if (valueType !== NUMBER_FORMATS.PERCENT && Object.keys(growthData).length > 0) {
    addedGrowthLineChart = true;
    const chartGrowthData = [];
    months.forEach((month) => {
      const growthRate = growthData[month];
      const growthPercent = growthRate * 100;
      chartGrowthData.push(growthPercent.toFixed(2));
    });
    const lineData = {
      label: `${GROWTH_TYPE_OPTIONS[growthType]} Growth Rate`,
      type: 'line',
      fill: false,
      borderColor: CHART_COLORS.DARK,
      pointBackgroundColor: CHART_COLORS.DARK,
      pointBorderColor: 'white',
      borderWidth: 2,
      pointRadius: 4,
      lineTension: 0,
      data: chartGrowthData,
      yAxisID: 'y0',
    };

    yAxes.unshift({
      id: 'y0',
      position: 'right',
      gridLines: {
        display: false,
        drawBorder: false,
      },
      ticks: {
        maxTicksLimit: 7,
        suggestedMax: Math.max(...chartGrowthData.slice(1, chartGrowthData.length)),
        callback: function (value) {
          return value.toString() + '%';
        },
        fontStyle: 'bold',
      },
    });
    datasets.unshift(lineData);
  }

  if (goalData) {
    const chartGoalData = [];
    months.forEach((month) => {
      const goalValue = goalData[month];
      chartGoalData.push(goalValue);
    });
    const lineGoalData = {
      label: goalName,
      type: 'line',
      fill: false,
      borderColor: CHART_COLORS.BLUE,
      borderDash: [5],
      pointBackgroundColor: CHART_COLORS.BLUE,
      pointBorderColor: CHART_COLORS.BLUE,
      borderWidth: 2,
      pointRadius: 4,
      lineTension: 0,
      data: chartGoalData,
      yAxisID: 'y1',
    };

    datasets.push(lineGoalData);
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    tooltips: {
      mode: 'index',
      intersect: false,
      callbacks: {
        label: function (tooltipItem, data) {
          let label = data.datasets[tooltipItem.datasetIndex].label ?? '';
          if (label) {
            label += ': ';
          }
          // If we're on the growth line dataset, we show the percent sign
          if (tooltipItem.datasetIndex === 0 && addedGrowthLineChart) {
            label += tooltipItem.yLabel.toString() + '%';
            return label;
          }

          if (valueType === NUMBER_FORMATS.PERCENT) {
            label += tooltipItem.yLabel.toString() + '%';
          } else if (valueType === NUMBER_FORMATS.CURRENCY) {
            label += numberFormatter({ type: valueType, rawValue: tooltipItem.yLabel, currency });
          } else {
            label += tooltipItem.yLabel.toString();
          }
          return label;
        },
      },
    },
    scales: {
      xAxes: [
        {
          stacked: true,
          gridLines: {
            display: false,
          },
          ticks: {
            callback: function (value) {
              return value.toUpperCase();
            },
            fontStyle: 'bold',
            fontColor: '#BFBFBF',
          },
        },
      ],
      yAxes,
    },
  };

  return (
    <ChartLegendContainer>
      <ChartContainer>
        <Bar
          width={140}
          data={{
            labels,
            datasets,
          }}
          options={options}
        />
      </ChartContainer>
      <LegendContainer>
        <LegendLabel>Metric chart: </LegendLabel>
        <Legend>
          <LegendKeysContainer>
            <LegendKey active={true}>
              <LegendKeyDot fill={goalData ? CHART_COLORS.GREEN : CHART_COLORS.PURPLE} />
              <span>{title}</span>
            </LegendKey>
            {goalData && (
              <LegendKey active={true}>
                <LegendKeyDot fill={CHART_COLORS.BLUE} />
                <span>{goalName}</span>
              </LegendKey>
            )}
            {valueType !== NUMBER_FORMATS.PERCENT && Object.keys(growthData).length > 0 && (
              <LegendKey active={true}>
                <LegendKeyDot fill={CHART_COLORS.DARK} />
                <span>Growth Rate</span>
              </LegendKey>
            )}
          </LegendKeysContainer>
        </Legend>
      </LegendContainer>
    </ChartLegendContainer>
  );
};
