import styled from 'styled-components';

export const TableContainer = styled.div`
  overflow: scroll;
  height: 80vh;
  position: relative;
`;

export const StyledTable = styled.div`
  width: fit-content;

  .table-data {
    position: relative;

    svg {
      position: absolute;
      margin-left: 10px;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
`;

export const TableHeaderRow = styled.div`
  height: 48px;
  display: flex;
  width: fit-content;
  position: sticky;
  top: 0;
  z-index: 30;
`;

export const TableHeaderTile = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  width: 90px;
  color: var(--primaryBlack75);
  font-weight: 800;
  font-size: 10px;
  border-radius: 4px;
  border: 1px solid var(--primaryBlack10);
  text-transform: uppercase;
  padding: 12px;
  background: white;
  margin-right: 4px;

  &:first-child {
    position: sticky;
    left: 0px;
    z-index: 10;
    justify-content: start;
    border-radius: 8px;
    margin-right: 12px;
    width: 250px;
  }
`;

export const TableBody = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
`;

export const TableBodyRow = styled.div`
  height: ${({ rowHeight }) => rowHeight ?? '48px'};
  display: flex;
  width: fit-content;
  position: sticky;
  top: 0;
  z-index: 20;
  margin-bottom: 4px;
`;

export const TableBodyTile = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  width: 90px;
  color: var(--primaryBlack);
  font-weight: 400;
  font-size: 12px;
  border-radius: 4px;
  padding: 12px;
  background: var(--primaryBlack5);
  margin-right: 4px;

  &:first-child {
    position: sticky;
    left: 0px;
    z-index: 10;
    justify-content: start;
    border-radius: 8px;
    margin-right: 12px;
    width: 250px;
    background: white;
    font-weight: 800;
    font-size: 12px;
    border: 1px solid var(--primaryBlack10);
  }
`;
