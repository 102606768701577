import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
  useExpanded,
  useGlobalFilter,
  useGroupBy,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import { cssVar } from 'polished';
import styled from 'styled-components';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import { uniq } from 'lodash';

import { AppContext } from 'AppContext';
import { ReactComponent as TotalIcon } from 'images/sort-descending.svg';
import { ReactComponent as ArrowUp } from 'images/arrow_up_bold.svg';
import { ReactComponent as GreyDot } from 'images/medium-grey-dot.svg';
import { ReactComponent as FilePlus } from 'images/file-plus.svg';
import { ReactComponent as FolderPlus } from 'images/icon-folder-plus.svg';
import { ReactComponent as _WarningIcon } from 'images/warning-icon.svg';
import { ReactComponent as CsvIcon } from 'images/actions-dropdown__csv.svg';
import { ReactComponent as ArchiveIcon } from 'images/actions-dropdown__archive.svg';
import { ActionsPopover } from 'shared/Common';
import { MultiselectRibbon, SearchBar } from 'components/Blocks';
import { IconButton, PaginationButton } from 'components/Buttons';
import { CentererVertical, Flexer, Row, Spacer } from 'components/Core';
import { TruncateStringWithTooltip } from 'components/Tooltip';
import {
  ReactTable,
  ReactTableBody,
  ReactTableCell,
  ReactTableHeader,
  ReactTableHeaderColumn,
  ReactTableHeaderRow,
  ReactTableRow,
  RowActionPopover,
  RowsCount,
  TableHeaderActions,
  SortIconWrapper,
  StyledTableNameTitle,
  TableActionCell,
  TablePageSeparator,
  TableSubRowsCount,
  TableSubRowsCountArrow,
  HeaderCellWrapper,
} from 'components/Table';
import { SearchContainer } from 'shared/TransactionTable/styles';
import { ReportsPopoverEmptySearch } from 'shared/Reports/ReportsPopover/ReportsPopoverEmptySearch';
import { ProductsTableCell, ProductsTableName, CategoryTitle, ProductTitle } from './styles';
import { ProductsRecognitionBanner } from 'shared/Banners';
import { CircleLoader } from 'components/Loaders';
import { PRODUCT_IMPORT_METADATA_KEYS } from 'views/Billing/consts';
import { GL_INTEGRATION_SERVICES, INTEGRATION_TYPES } from 'consts/integrations';
import { getIntegrationDisplayName, getServiceCategory } from 'models/integration';
import { ADDITIONAL_ORG_CONFIGS } from 'consts/global';

const ProductReactTableRow = styled(ReactTableRow)`
  position: relative;
`;

const Buttons = styled.div`
  display: flex;
  position: absolute;
  right: 0;
  top: -70px;
`;

const WarningIcon = styled(_WarningIcon)`
  height: 12px;

  path {
    fill: var(--primaryRed);
  }
`;

const NullIncomeAccount = styled(CentererVertical)`
  font-size: 12px;
  background-color: var(--primaryRed10);
  color: var(--primaryRed);
  padding: 2px 4px;
  border-radius: 4px;
`;

const ServiceContainer = styled.div`
  border-radius: 4px;
  padding: 4px;
  border: 1px solid var(--neutralGray);
  font-weight: 900;
  font-size: 9px;
  line-height: 12px;
  color: var(--primaryBlack50);
  background-color: white;
  text-transform: capitalize;
  height: fit-content;
`;

const IncomeRefText = styled.span`
  font-size: 12px;
  color: ${({ color }) => color ?? 'auto'};
`;

const AdditionalIncomeRefCounter = styled.div`
  border-radius: 4px;
  padding-inline: 4px;
  background-color: var(--dark7);
  font-size: 9px;
  font-weight: 900;
  color: white;
  height: fit-content;
`;

const customGlobalFilter = (rows, columnIds, searchQuery) => {
  if (rows.length === 0) return rows;
  if (!searchQuery) return rows;

  const lowercaseQuery = searchQuery.toLowerCase();
  let rowMatches;
  rowMatches = (row) => {
    return (
      Object.values(row.values).some(
        (rowValue) => typeof rowValue === 'string' && rowValue.toLowerCase().includes(lowercaseQuery),
      ) || row.subRows.some(rowMatches)
    );
  };

  const returned = rows.filter(rowMatches);
  return returned;
};

const ProductsTable = ({
  data,
  productCategories,
  onCreateProduct,
  onEditProduct,
  onDeleteProduct,
  onEditBulk,
  onEditIncomeAccountBulk,
  onDeleteBulk,
  onOpenCategoryModal,
  isLoading,
  isFetching,
  incomeAccountOptionsByIntegrationId,
}) => {
  const { orgConfigs, integrations } = useContext(AppContext);
  const glIntegrations = useMemo(
    () => integrations?.filter((integration) => integration.type === INTEGRATION_TYPES.GL),
    [integrations],
  );
  const history = useHistory();
  const incomeAccountRefIdByIntegrationId = orgConfigs[ADDITIONAL_ORG_CONFIGS.INCOME_ACCOUNT_REF_ID_BY_INTEGRATION_ID];
  const columns = useMemo(() => {
    const result = [
      {
        Header: ({ getToggleAllPageRowsSelectedProps }) => (
          <Checkbox data-cy="products-table__select-all-checkbox" {...getToggleAllPageRowsSelectedProps()} />
        ),
        accessor: 'checked',
        width: 32,
        Cell: ({ row }) => (
          <Checkbox
            checked={row.isSelected}
            onClick={() => {
              row.toggleRowSelected();
            }}
            data-cy="products-table__row__checkbox-cell"
          />
        ),
        noInitialSortBy: true,
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: 'Product',
        accessor: 'name',
        width: 450,
        Cell: ({ row, cell: { value } }) => (
          <ProductsTableName
            data-cy={`products-table__row--${row.canExpand ? row.original?.id : row.original?.name}__name-cell`}
          >
            <StyledTableNameTitle
              onClick={() =>
                row.canExpand
                  ? onOpenCategoryModal({ action: 'edit', category: row.original })
                  : onEditProduct(row.original)
              }
              wrapText
            >
              {row.canExpand ? (
                <Row>
                  Category:
                  <CategoryTitle>
                    {productCategories.find((category) => category.id.toString() === row.original?.id.toString())?.name}
                  </CategoryTitle>
                </Row>
              ) : (
                <ProductTitle>
                  <TruncateStringWithTooltip length={35} tooltipWidth={200}>
                    {value}
                  </TruncateStringWithTooltip>
                </ProductTitle>
              )}
            </StyledTableNameTitle>

            {row.canExpand && (
              <TableSubRowsCount {...row.getToggleRowExpandedProps()} isHidden={!row.isExpanded}>
                {row.subRows.length}
                <TableSubRowsCountArrow isHidden={!row.isExpanded}>
                  <ArrowUp />
                </TableSubRowsCountArrow>
              </TableSubRowsCount>
            )}
          </ProductsTableName>
        ),
      },
      {
        Header: 'Recognition',
        accessor: 'recognition',
        width: 150,
        Cell: ({ row, cell: { value } }) => (
          <ProductsTableCell data-cy={`products-table__row--${row.original?.name}__recognition-cell`}>
            {value}
          </ProductsTableCell>
        ),
      },
      {
        Header: 'Source',
        accessor: 'source',
        width: 160,
        Cell: ({ row, cell: { value } }) => {
          const adjustedValues = [];
          for (const service of uniq(value ?? [])) {
            const adjustedValue = GL_INTEGRATION_SERVICES.includes(getServiceCategory(service))
              ? getIntegrationDisplayName(glIntegrations.find((integration) => integration.service === service))
              : service?.toLowerCase()?.includes('csv')
              ? 'CSV'
              : service;
            if (adjustedValue && !adjustedValues.includes(adjustedValue)) {
              adjustedValues.push(adjustedValue);
            }
          }

          return (
            <ProductsTableCell data-cy={`products-table__row--${row.original?.name}__source-cell`}>
              {adjustedValues.sort().join(', ')}
            </ProductsTableCell>
          );
        },
      },
    ];

    if (Object.keys(incomeAccountOptionsByIntegrationId || {}).length > 0) {
      result.push({
        Header: 'GL Link & Income Account',
        accessor: `${PRODUCT_IMPORT_METADATA_KEYS.INCOME_ACCOUNT}`,
        width: 350,
        Cell: ({ row }) => {
          const importsToDisplay = (row.original?.imports ?? [])
            .sort(
              (a, b) =>
                a?.metadata?.[PRODUCT_IMPORT_METADATA_KEYS.DISPLAY_ORDER] -
                b?.metadata?.[PRODUCT_IMPORT_METADATA_KEYS.DISPLAY_ORDER],
            )
            .slice(0, 2);
          const remainingImportsCount = (row.original?.imports ?? []).length - 2;
          const hasAddtionalImports = remainingImportsCount > 0;

          return row.canExpand ? null : (
            <ProductsTableCell data-cy={`products-table__row--${row.original?.name}__income-account-cell`}>
              {!!importsToDisplay.length ? (
                <Flexer gap="8px" wrapRow alignItems="center">
                  {importsToDisplay.map((productImport, index) => {
                    const serviceName = getIntegrationDisplayName(
                      glIntegrations.find((integration) => integration.id === productImport.integration_id),
                    );
                    const incomeAccountOptions = incomeAccountOptionsByIntegrationId[productImport.integration_id];
                    const importIncomeAccountRef =
                      productImport?.metadata?.[PRODUCT_IMPORT_METADATA_KEYS.INCOME_ACCOUNT];
                    const incomeAccountRefIdConfig = incomeAccountRefIdByIntegrationId?.[productImport.integration_id];
                    const incomeRefText = importIncomeAccountRef
                      ? incomeAccountOptions[importIncomeAccountRef]
                      : incomeAccountRefIdConfig
                      ? `${incomeAccountOptions[incomeAccountRefIdConfig]} (default)`
                      : '';
                    return serviceName ? (
                      <Flexer key={productImport.id} alignItems="center" gap="6px">
                        <ServiceContainer>{serviceName}</ServiceContainer>
                        <IncomeRefText color={incomeRefText ? null : 'var(--primaryRed)'}>
                          {incomeRefText || 'NULL'}
                          {index === importsToDisplay.length - 1 && !hasAddtionalImports ? '' : ','}
                        </IncomeRefText>
                      </Flexer>
                    ) : (
                      <></>
                    );
                  })}
                  {hasAddtionalImports && (
                    <AdditionalIncomeRefCounter>+ {remainingImportsCount}</AdditionalIncomeRefCounter>
                  )}
                </Flexer>
              ) : (
                <CentererVertical gap="8px">
                  <WarningIcon />
                  <NullIncomeAccount>Null</NullIncomeAccount>
                </CentererVertical>
              )}
            </ProductsTableCell>
          );
        },
      });
    }

    result.push(
      {
        Header: 'Created',
        accessor: 'created_at',
        width: 150,
        Cell: ({ row, cell: { value } }) => (
          <ProductsTableCell data-cy={`products-table__row--${row.original?.name}__created-at-cell`}>
            {dayjs.utc(value).format('MMM D, YYYY')}
          </ProductsTableCell>
        ),
      },
      {
        Header: 'Updated',
        accessor: 'updated_at',
        width: 150,
        Cell: ({ row, cell: { value } }) => (
          <ProductsTableCell data-cy={`products-table__row--${row.original?.name}__updated-at-cell`}>
            {dayjs.utc(value).format('MMM D, YYYY')}
          </ProductsTableCell>
        ),
      },
      {
        accessor: 'actions',
        disableSortBy: true,
        width: 75,
        Cell: ({ row }) => (
          <TableActionCell>
            <RowActionPopover
              currentSelection={row.original}
              editText={row.original.archived_at ? 'Edit & Restore' : 'Edit'}
              onEditClick={
                row.canExpand
                  ? () => onOpenCategoryModal({ action: 'edit', category: row.original })
                  : () => onEditProduct(row.original)
              }
              onDeleteClick={row.canExpand || row.original.archived_at ? undefined : onDeleteProduct}
              dataCyPrefix={`products-table__row--${row.original?.name}`}
            />
          </TableActionCell>
        ),
      },
    );

    return result;
  }, [
    incomeAccountOptionsByIntegrationId,
    productCategories,
    onOpenCategoryModal,
    onEditProduct,
    glIntegrations,
    incomeAccountRefIdByIntegrationId,
    onDeleteProduct,
  ]);

  // We want to keep the search state between re-renders. We can swap this out
  // for useStateWithStorage if we want to keep it between refreshes as well.
  const [memoGlobalFilter, setMemoGlobalFilter] = useState('');

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    toggleRowExpanded,
    rows,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    setGlobalFilter,
    pageCount,
    nextPage,
    previousPage,
    toggleAllRowsSelected,
    state: { pageIndex, globalFilter, selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: 50,
        sortBy: [
          {
            id: 'name',
            desc: false,
          },
        ],
        globalFilter: memoGlobalFilter,
      },
      globalFilter: customGlobalFilter,
      getSubRows: useCallback(
        (row) => row.subRows || [],
        // eslint-disable-next-line
        [memoGlobalFilter],
      ),
    },
    useGlobalFilter,
    useGroupBy,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
  );

  const tableRef = useRef();

  const [selectedProductsIds, setSelectedProductsIds] = useState([]);

  const handleEditProducts = useCallback(() => {
    const flatData = [];
    data.forEach((row) => (row.subRows ? row.subRows.forEach((subRow) => flatData.push(subRow)) : flatData.push(row)));
    const products = flatData.filter((product) => selectedProductsIds.includes(product.id));
    onEditBulk(products);
  }, [data, selectedProductsIds, onEditBulk]);

  const handleEditIncomeAccountForProducts = useCallback(() => {
    const flatData = [];
    data.forEach((row) => (row.subRows ? row.subRows.forEach((subRow) => flatData.push(subRow)) : flatData.push(row)));
    const products = flatData.filter((product) => selectedProductsIds.includes(product.id));
    onEditIncomeAccountBulk(products);
  }, [data, selectedProductsIds, onEditIncomeAccountBulk]);

  const handleDeleteProducts = useCallback(() => {
    const flatData = [];
    data.forEach((row) => (row.subRows ? row.subRows.forEach((subRow) => flatData.push(subRow)) : flatData.push(row)));
    const products = flatData.filter((product) => selectedProductsIds.includes(product.id));
    onDeleteBulk(products);
  }, [data, selectedProductsIds, onDeleteBulk]);

  useEffect(() => {
    const pageProductsIds = [
      ...new Set(
        rows
          ?.flatMap((row) =>
            row.canExpand
              ? row.subRows
                  .filter((subRow) => {
                    subRow.isSelected = false;
                    return selectedRowIds[subRow.id];
                  })
                  .map((row) => {
                    row.isSelected = true;
                    return row.original.id;
                  })
              : selectedRowIds[row.id] && row.original.id,
          )
          .filter(Boolean),
      ),
    ];

    setSelectedProductsIds(pageProductsIds);
    // Otherwise we get into an infinite loop:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRowIds]);

  useEffect(() => {
    setMemoGlobalFilter(globalFilter);
  }, [globalFilter, setMemoGlobalFilter]);

  useEffect(() => {
    rows.forEach((row) => {
      toggleRowExpanded(row.id, true);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const bulkActions = useMemo(() => {
    const result = [
      {
        role: 'primary',
        label: 'Change recognition',
        onClick: handleEditProducts,
      },
      {
        role: 'primary',
        label: 'Attribute category',
        onClick: () => onOpenCategoryModal({ action: 'create', selectedProductsIds }),
      },
    ];

    if (incomeAccountOptionsByIntegrationId) {
      result.push({
        role: 'primary',
        label: 'Edit income account',
        onClick: handleEditIncomeAccountForProducts,
      });
    }

    result.push({
      role: 'destructive',
      label: 'Delete',
      onClick: handleDeleteProducts,
    });

    return result;
  }, [
    handleEditProducts,
    incomeAccountOptionsByIntegrationId,
    handleDeleteProducts,
    onOpenCategoryModal,
    selectedProductsIds,
    handleEditIncomeAccountForProducts,
  ]);

  return (
    <div style={{ position: 'relative' }}>
      <Buttons>
        <IconButton
          style={{ marginRight: 8 }}
          data-cy="products-table__create-category-button"
          onClick={() => onOpenCategoryModal({ action: 'create' })}
          icon={<FolderPlus />}
          iconFillColor={cssVar('--primaryGreen')}
        >
          Category
        </IconButton>
        <IconButton
          style={{ marginRight: 8 }}
          data-cy="products-table__create-button"
          onClick={onCreateProduct}
          icon={<FilePlus />}
          iconFillColor={cssVar('--primaryGreen')}
        >
          Product
        </IconButton>

        <ActionsPopover
          width="205px"
          actions={{
            'Upload CSV Products': {
              icon: <CsvIcon />,
              cb: () => {
                history.push('/csv/products');
              },
            },
            'Deleted Products': {
              icon: <ArchiveIcon />,
              cb: () => {
                history.push('/configure?tab=products&archived=true');
              },
            },
          }}
        />
      </Buttons>

      <ProductsRecognitionBanner />
      <TableHeaderActions horizontal="space-between">
        <SearchContainer>
          <SearchBar
            value={globalFilter ?? ''}
            placeholder="Search..."
            onChange={({ target }) => {
              setGlobalFilter(target.value);
            }}
            onBlur={({ target }) => {
              setGlobalFilter(target.value);
            }}
            onKeyPress={(e) => e.key === 'Enter' && e.target.blur()}
            name="product"
          />
        </SearchContainer>
        <Row>
          {!isLoading && isFetching && (
            <>
              <CircleLoader name={'products'} width={24} height={24} />
              <Spacer width="10px" />
            </>
          )}

          <RowsCount>{rows?.length} rows found</RowsCount>
          <GreyDot style={{ marginLeft: 20, marginRight: 16 }} />

          <PaginationButton
            pageIndex={pageIndex + 1}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            pageCount={pageCount}
            nextPage={nextPage}
            previousPage={previousPage}
          />
        </Row>
      </TableHeaderActions>

      <TablePageSeparator />

      <ReactTable pageView ref={tableRef} {...getTableProps()}>
        <ReactTableHeader pageView>
          {headerGroups.map((headerGroup) => (
            <ReactTableHeaderRow pageView {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <ReactTableHeaderColumn
                  alignRight={column?.alignRight}
                  customWidth={column.width}
                  {...column.getHeaderProps(
                    column.id !== 'actions' && column.getSortByToggleProps({ title: undefined }),
                  )}
                >
                  <HeaderCellWrapper isSortable={column.canSort && !column.disableSortBy} isSorted={column.isSorted}>
                    {column.render('Header')}

                    {column.isSorted && (
                      <SortIconWrapper isSortedDesc={column.isSortedDesc}>
                        <TotalIcon />
                      </SortIconWrapper>
                    )}
                  </HeaderCellWrapper>
                </ReactTableHeaderColumn>
              ))}
            </ReactTableHeaderRow>
          ))}
        </ReactTableHeader>
        <ReactTableBody pageView {...getTableBodyProps()}>
          {selectedProductsIds.length > 0 && (
            <MultiselectRibbon
              label={`${selectedProductsIds.length} selected row${selectedProductsIds.length > 1 ? 's' : ''}:`}
              actions={bulkActions}
              onResetSelection={() => toggleAllRowsSelected(false)}
            />
          )}
          {page.map((row) => {
            prepareRow(row);
            return (
              <ProductReactTableRow
                subRow={row.depth}
                {...row.getRowProps()}
                data-cy={`products-table__row--${row.original?.name}`}
              >
                {row.cells.map((cell) => (
                  <ReactTableCell customWidth={cell.column.width} {...cell.getCellProps()}>
                    {cell.render('Cell')}
                  </ReactTableCell>
                ))}
              </ProductReactTableRow>
            );
          })}
          {!rows?.length && <ReportsPopoverEmptySearch onResetSearch={() => setGlobalFilter('')} />}
        </ReactTableBody>
      </ReactTable>
    </div>
  );
};

export { ProductsTable };
