import styled, { css } from 'styled-components';
import { ReactComponent as CircleXIcon } from 'images/circle-x.svg';
import { ReactComponent as EditCircle } from 'images/edit-circle.svg';
import { Centerer, Flexer, FlexerColumn, Row } from 'components/Core';
import { CircleLoader } from 'components/Loaders';

export const FormWrapper = styled(Flexer)`
  gap: 40px;
  width: 100%;
  padding-left: 40px;
  padding-right: 30px;
  padding-bottom: 30px;
`;

export const InvoiceWrapper = styled.div`
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: auto;
`;

export const InternalNoteWrapper = styled.div`
  padding: 20px 40px;
  background-color: var(--primaryBlack2);
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.01) inset;
  border-bottom: 1px solid var(--primaryBlack3);
  margin-top: -20px;
  margin-bottom: 20px;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: 16px;
  color: var(--primaryBlack70);
  white-space: pre-wrap;
`;

export const InternalNoteTitle = styled.div`
  font-size: 12px;
  font-style: normal;
  font-weight: 900;
  line-height: 16px;
  margin-bottom: 12px;
  color: var(--primaryBlack);
`;

export const EditNoteButton = styled.div`
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: 16px;
  color: var(--primaryBlue);
  cursor: pointer;
  margin-left: 16px;

  &:hover {
    color: var(--secondaryBlue);
  }
`;

export const SectionTitle = styled(Centerer)`
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: var(--primaryBlack);
  margin: 0 12px;
`;

export const SectionIcon = styled(Centerer)`
  width: 32px;
  height: 32px;
  padding: 6px;
  border: 1px solid var(--accentGraySecond);
  border-radius: 8px;

  svg {
    g {
      opacity: 1;

      path {
        fill: var(--primaryBlack);
      }
    }
  }
`;

export const ItemsRow = styled.div`
  display: flex;
  margin-bottom: 14px;

  > * {
    width: 100%;
    min-width: 120px;
    margin-right: 20px;
    white-space: nowrap;

    &:last-child {
      margin-right: 0;
    }
  }
`;

export const StyledCircleXIcon = styled(CircleXIcon)`
  cursor: pointer;
  margin-right: 8px;

  &:hover {
    path {
      fill: var(--secondaryRed);
    }
  }
`;

export const StyledEditCircle = styled(EditCircle)`
  cursor: pointer;

  &:hover {
    path {
      fill: var(--secondaryBlue);
    }
  }
`;

export const CollapsedView = styled.div`
  height: 100%;
  width: 100%;
  padding: 12px 22px;
  background: var(--primaryBlack2);
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  white-space: nowrap;

  &:hover {
    background-color: var(--primaryBlack5);
    & > div {
      color: var(--primaryBlack);
      & g {
        opacity: 1;
      }
    }
  }
`;

export const MainCollapsedText = styled.div`
  transform-origin: 0 0;
  transform: rotate(270deg);
  font-style: italic;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  position: relative;
  top: ${({ top }) => top ?? '140px'};
  right: 2px;
`;

export const CollapsedSubtitle = styled.div`
  transform-origin: 0 0;
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 16px;
  transform: rotate(-90deg);
  color: var(--primaryBlack30);
  width: 300px;
  position: relative;

  svg {
    margin-right: 8px;
    width: 16px;
    height: 16px;
  }
`;

export const StyledArrow = styled.div`
  position: absolute;
  width: 24px;
  height: 24px;
  top: 18px;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  background-color: ${({ active }) => (!active ? 'white' : 'var(--dark7)')};
  box-shadow: 2px 2px 12px var(--primaryBlack2);
  border-radius: 100px;
  border: 1px solid var(--accentGraySecond);
  transition: all ease 0.4s;
  z-index: ${({ zIndex }) => zIndex ?? 'auto'};

  & svg {
    path {
      fill: ${({ active }) => (!active ? 'var(--primaryBlack)' : 'white')};
    }
  }

  ${({ isRightSide }) =>
    isRightSide
      ? css`
          left: ${({ active, XOffset }) => XOffset ?? (!active ? '-15px' : '-50px')};
          transform: ${({ active }) => (!active ? 'rotate(90deg)' : 'rotate(-90deg)')};
        `
      : css`
          right: ${({ active, XOffset }) => XOffset ?? (!active ? '-15px' : '26px')};
          transform: ${({ active }) => (!active ? 'rotate(-90deg)' : 'rotate(90deg)')};
        `}
`;

export const CollapseViewWrapper = styled(FlexerColumn)`
  position: relative;
  width: ${({ isCollapsed, disableExpand }) => (isCollapsed || disableExpand ? '0' : '30%')};
  opacity: ${(props) => props.disabled && '0.3'};
  pointer-events: ${(props) => props.disabled && 'none'};
  padding: ${({ isCollapsed, disableExpand }) => (isCollapsed || disableExpand ? '0' : '0 40px')};
  margin: ${({ isCollapsed, disableExpand }) => (isCollapsed || disableExpand ? '0' : '0')};
`;

export const CountBox = styled.div`
  background-color: var(--dark7);
  padding: 4px;
  border-radius: 4px;
  font-size: 9px;
  font-weight: 900;
  color: white;
  padding: 2px 4px;
  text-align: center;
`;

export const AmountBalanceWrapper = styled(FlexerColumn)`
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: var(--primaryBlack40);
  gap: 8px;

  span {
    margin-left: 8px;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    color: var(--primaryBlack);
  }
`;

export const ExternalUpdateBody = styled.div`
  padding: 12px 0;
  padding-right: 12px;
  border-radius: 12px;
  border: 1px solid var(--primaryBlack3);
  background: var(--primaryBlack2);
  width: 100%;
  margin-top: 8px;
`;

export const ExternalUpdateBodyTitle = styled.div`
  margin-bottom: 10px;
  margin-left: 12px;
  color: var(--primaryBlack50);
  font-size: 12px;
  font-style: italic;
  line-height: 16px;
  text-align: left;

  span {
    font-style: normal;
    font-weight: 600;
  }
`;

export const InvoiceItem = styled.div`
  display: flex;
  align-items: center;
  padding-left: 16px;
  position: relative;
  margin-bottom: 8px;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
    width: 11px;
    height: 1px;
    background-color: var(--primaryBlack10);
  }
`;

export const DismissButton = styled.div`
  padding: 5px 8px;
  display: flex;
  cursor: pointer;
  align-items: center;
  border-radius: 100px;
  border: 1px solid var(--primaryBlack5);
  font-size: 11px;
  font-style: italic;
  font-weight: 700;
  line-height: 14px;
  margin-left: 12px;
  margin-right: 8px;
  margin-top: 8px;
`;

export const ApplyButton = styled.div`
  padding: 5px 8px;
  display: flex;
  align-items: center;
  border-radius: 100px;
  border: 1px solid var(--primaryBlue10);
  background: var(--primaryBlue5);
  color: var(--primaryBlue);
  cursor: pointer;
  font-size: 11px;
  font-style: italic;
  font-weight: 700;
  line-height: 14px;
  margin-top: 8px;

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.7;
      cursor: default;
      pointer-events: none;
    `}

  &:hover {
    background: var(--primaryBlue10);
  }

  svg {
    margin-left: 4px;
  }
`;

export const ModalFooter = styled.div`
  padding: 20px 36px;
  background-color: var(--primaryBlack2);
  display: flex;
  align-items: center;
  justify-content: ${({ custom }) => (custom ? 'space-between' : 'flex-end')};
`;

export const InlineFlexWarnings = styled.div`
  display: inline-flex;
  align-items: center;
  font-weight: 700;
  white-space: nowrap;
`;

export const DeletedTransactionInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 12px;
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
  margin-bottom: 4px;
`;

export const TransactionInfoRow = styled(Row)`
  margin-top: 4px;
  justify-content: flex-start;

  b {
    font-weight: 700;
    margin: 0 8px;

    &:first-child {
      margin-left: ${({ modalView }) => modalView && '0'};
    }
  }
`;

export const AutomaticTaxNotification = styled.div`
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: var(--primaryBlack40);

  span {
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    color: var(--primaryBlack);
  }
`;

export const TaxesLoader = ({ invoicingServiceDisplayName, loaderName }) => (
  <Centerer gap="8px">
    <AutomaticTaxNotification>
      Please wait while we sync taxes from {invoicingServiceDisplayName}
    </AutomaticTaxNotification>
    <CircleLoader isInline width="24px" height="24px" thickness="4px" name={loaderName} />
  </Centerer>
);
