import React, { useContext } from 'react';
import styled from 'styled-components';
import { cssVar, transparentize } from 'polished';
import { defaults, Line } from 'react-chartjs-2';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { AppContext } from 'AppContext';
import { NUMBER_FORMATS } from 'consts/global';
import { amountWithAbbreviation } from 'utils/formatters';
import { reFormatDate } from 'utils/dateUtils';
import { FillDot } from 'components/Icons';

dayjs.extend(utc);

const Wrapper = styled.div`
  width: 100%;
`;

const ChartWrapper = styled.div`
  width: 100%;
  margin: 28px 0 28px;
`;

const ChartContainer = styled.div`
  width: 100%;
  border-bottom: none;
`;

const LegendContainer = styled.div`
  width: 100%;
  height: 38px;
  margin-top: 24px;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 16px;
  gap: 16px;

  border: 1px solid var(--accentGraySecond);
  box-shadow: 8px 8px 60px var(--primaryBlack4);
  border-radius: 8px;
`;

const LegendText = styled.div`
  font-weight: 800;
  font-size: 10px;
  line-height: 14px;

  display: flex;
  align-items: center;
  text-transform: uppercase;

  color: var(--primaryBlack30);
`;

const LegendKey = styled.div`
  margin-left: 6px;

  font-weight: 800;
  font-size: 10px;
  line-height: 14px;
  text-transform: uppercase;
  color: var(--primaryBlack);

  svg {
    margin-right: 6px;
  }

  span {
    margin-left: 2px;
    color: var(--primaryBlack40);
  }
`;

const CHART_COLORS = ['#797979', '#E68A00', '#957AFF', '#078953', '#1457BC', '#A517C9', '#E70000'];

const mapScenarios = ({ data }) =>
  data.reduce((acc, res) => {
    const scenarios = res.scenarios
      ? res.scenarios.map((scenario) => ({
          ...scenario,
          name: `${res.name} - ${scenario.name}`,
        }))
      : [res];

    for (const scenario of scenarios) {
      acc.push({
        label: scenario.name,
        data: Object.entries(scenario.scenario).reduce((acc, [monthKey, values]) => {
          acc[monthKey] = values?.total;
          return acc;
        }, {}),
      });
    }
    return acc;
  }, []);

export const ScenariosChart = ({ data, monthlyRecurringRevenue }) => {
  const chartData = [
    {
      label: 'Current',
      data: monthlyRecurringRevenue,
    },
    ...mapScenarios({ data }),
  ];

  return (
    <Wrapper>
      <ScenariosLineChart chartData={chartData} />
    </Wrapper>
  );
};

export const ScenariosLineChart = ({ chartData = [] }) => {
  defaults.global.defaultFontFamily = 'Nunito Sans';
  const {
    appSettings: { isARR, currencyISOCode: currency },
  } = useContext(AppContext);

  const allKeys = Array.from(
    chartData.reduce((acc, scenario) => {
      Object.keys(scenario.data).forEach((elm) => acc.add(elm));
      return acc;
    }, new Set()),
  );
  const labels = allKeys.map((date) => dayjs.utc(date).format('MM/YYYY'));

  const datasets = chartData.slice(0, CHART_COLORS.length).map((dataSet, index) => ({
    label: dataSet.label,
    data: allKeys.map((key) => dataSet.data[key]),
    borderColor: CHART_COLORS[index],
    backgroundColor: transparentize(0.95, CHART_COLORS[index]),
    // Dashed line
    borderDash: index > 0 ? [5] : null,
    lineTension: 0,
    pointRadius: index === 0 ? 3 : 0,
    pointHitRadius: 6,
    pointHoverRadius: 3,
    borderWidth: 2,
    datalabels: {
      align: 'end',
      anchor: 'end',
    },
  }));

  const options = {
    responsive: true,
    legend: {
      display: false,
    },
    layout: {
      padding: { top: 30, right: 30 },
    },
    tooltips: {
      mode: 'index',
      callbacks: {
        title: (tooltipItem) => {
          return `Forecasted ${isARR ? 'ARR' : 'MRR'} for ${reFormatDate(tooltipItem[0].label, 'MM/YYYY', 'MMM YYYY')}`;
        },
        label: (tooltipItem, data) => {
          return `${data.datasets[tooltipItem.datasetIndex].label}: ${amountWithAbbreviation({
            value: tooltipItem.yLabel,
            type: NUMBER_FORMATS.CURRENCY,
            currency,
          })}`;
        },
      },
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
          },
          ticks: {
            padding: 10,
            fontStyle: 'bold',
            fontColor: cssVar('--primaryBlack20'),
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            drawBorder: false,
          },
          ticks: {
            padding: 30,
            fontStyle: 'bold',
            fontColor: cssVar('--primaryBlack40'),
            maxTicksLimit: 7,
            beginAtZero: true,
            callback: (value) => amountWithAbbreviation({ value, type: NUMBER_FORMATS.CURRENCY, currency }),
          },
        },
      ],
    },
    plugins: {
      datalabels: {
        offset: '5',
        display: (context) => context.datasetIndex === 0,
        backgroundColor: cssVar('--accentDarkGray'),
        borderRadius: 4,
        color: 'white',
        font: {
          weight: 'bold',
        },
        formatter: (value) => amountWithAbbreviation({ value, type: NUMBER_FORMATS.CURRENCY, currency }),
        padding: 6,
      },
    },
  };

  return (
    <ChartWrapper>
      <ChartContainer>
        <Line
          height={100}
          data={{
            labels,
            datasets,
          }}
          options={options}
        />
      </ChartContainer>
      <LegendContainer>
        <LegendText>{isARR ? 'ARR: ' : 'MRR: '}</LegendText>
        {chartData.map((scenario, index) => {
          const dates = Object.keys(scenario.data).map((date) => dayjs.utc(date).format('MM/YYYY'));
          return (
            <LegendKey key={index}>
              <FillDot width={8} fill={CHART_COLORS[index]} />
              {scenario.label}
              <span>
                ({dates[0]}-{dates[dates.length - 1]})
              </span>
            </LegendKey>
          );
        })}
      </LegendContainer>
    </ChartWrapper>
  );
};
