import styled from 'styled-components';
import { ReactComponent as ChartModalMinIllustration } from 'images/chart-modal-min-illustration.svg';
import { ReactComponent as ChartModalMaxIllustration } from 'images/chart-modal-max-illustration.svg';
import { Centerer, Flexer, FlexerColumn, FlexerRow } from 'components/Core';

export const StatsContainer = styled(FlexerColumn)`
  position: relative;
  padding: 16px;
  background-color: var(--primaryBlack2);
  border-radius: 12px;
`;

export const TitleMonth = styled.div`
  font-weight: 900;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  color: var(--primaryBlack);
`;

export const Title = styled(TitleMonth)`
  margin-top: 4px;
  opacity: 0.3;
`;

export const TitleAmount = styled.span`
  margin-top: 12px;
  font-weight: 900;
  font-size: 20px;
  line-height: 26px;
  color: var(--primaryBlack);
`;

export const Text = styled(FlexerRow)`
  align-items: center;
  gap: 8px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--primaryBlack50);
`;

export const PercentagePill = styled(Centerer)`
  gap: 4px;
  padding: 4px 8px;
  background: ${({ positive }) => (positive ? 'var(--primaryGreen10)' : 'var(--primaryRed10)')};
  border-radius: 100px;

  font-weight: 900;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  color: ${({ positive }) => (positive ? 'var(--primaryGreen)' : 'var(--primaryRed)')};
`;

export const IconContainer = styled(Flexer)`
  align-items: center;
  gap: 4px;

  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: ${({ color }) => color};
`;

export const MinIllustration = styled(ChartModalMinIllustration)`
  position: absolute;
  left: 0;
  bottom: 0;
`;

export const MaxIllustration = styled(ChartModalMaxIllustration)`
  position: absolute;
  left: 0;
  bottom: 0;
`;

export const Value = styled.span`
  font-weight: 900;
  font-size: 14px;
  line-height: 20px;
  color: var(--primaryBlack);
`;
export const Label = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--primaryBlack50);
`;

export const TitleGrowth = styled.div`
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: var(--primaryBlack);
`;
