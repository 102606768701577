import React, { useContext, useState } from 'react';

import { AppContext } from 'AppContext';
import { GreyDot } from 'components/Icons';
import { ReactComponent as PlusIcon } from 'images/customer-plus.svg';
import { TransactionSingleContent, TRANSACTION_MODAL_MODE } from 'shared/TransactionContent';
import { TransactionsPage } from 'views/Transactions/TransactionsPage';

import { StyledInlineButton } from '../styles';

const CustomerTransactionsTable = ({
  customer,
  fetchCustomer,
  hiddenTransactionColumns,
  setHiddenTransactionColumns,
}) => {
  const { organizations } = useContext(AppContext);
  const [showNewTransactionModal, setShowNewTransactionModal] = useState(false);

  const additionalTransactionsFilters = {
    body: {
      customerIds: [customer.id],
    },
    params: {
      includeCount: true,
      archived: false,
      includeChildCustomerTransactions: true,
    },
  };

  return (
    <>
      <div style={{ minHeight: 400, position: 'relative' }}>
        <TransactionsPage
          widgetView
          customerId={customer.id}
          transactionsConfirmation="Confirmed"
          transactionsGroupBy="Customer"
          hiddenTransactionColumns={hiddenTransactionColumns}
          setHiddenTransactionColumns={(x) => setHiddenTransactionColumns?.(x)}
          //we don't use filters on this page so se pass empty metadata filter
          extendedMetadataFilter={{}}
          additionalTransactionsFilters={additionalTransactionsFilters}
          additionalHeaderActions={
            <>
              <StyledInlineButton
                data-cy="customer-page__transactions-table__create-button"
                onClick={() => setShowNewTransactionModal(true)}
                isSecondary
              >
                new transaction <PlusIcon />
              </StyledInlineButton>
              <GreyDot style={{ minWidth: 4, minHeight: 4 }} spacing="20px" />
            </>
          }
          showTotalRow
        />
      </div>
      {showNewTransactionModal && (
        <TransactionSingleContent
          transaction={{
            customer_id: customer.id,
            customer_name: customer.name,
          }}
          mode={TRANSACTION_MODAL_MODE.CREATE}
          organization={organizations[0]}
          closeModal={() => setShowNewTransactionModal(false)}
          onTransactionCreated={fetchCustomer}
          onTransactionUpdated={fetchCustomer}
          onTransactionDeleted={fetchCustomer}
        />
      )}
    </>
  );
};

export default CustomerTransactionsTable;
