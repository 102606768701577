import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Formik } from 'formik';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { AppContext } from 'AppContext';
import { COLORS } from 'consts/colors';
import { Button } from 'components/Buttons';
import { FlexEndContainer, Spacer } from 'components/Core';
import { FormikCustomSelector } from 'components/Controls';
import {
  Modal,
  ModalBody,
  ModalCloseIcon,
  ModalContainer,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTitleText,
} from 'components/Modal';
import { getCustomersFromSearch } from 'shared/TransactionContent';
import { useContractsAPI } from 'api/contracts/hooks';

dayjs.extend(utc);

export const CreateContractModal = ({ onClose }) => {
  const { orgId } = useContext(AppContext);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const history = useHistory();

  const {
    operations: { addContract },
  } = useContractsAPI({ orgId, autoFetch: false });

  const handleFormSubmit = async ({ values }) => {
    setIsSubmitting(true);
    const { id } = await addContract.mutateAsync({
      data: { customer_id: values.customer_id },
    });
    setIsSubmitting(false);
    history.push(`/contracts/${id}`);
  };

  return (
    <ModalContainer>
      <Formik initialValues={{}} onSubmit={(values) => handleFormSubmit({ values })}>
        {({ submitForm, setFieldValue, values }) => (
          <Modal data-cy="add-contract-modal" width="540px" height="auto">
            <ModalCloseIcon onClose={onClose} data-cy="add-contract-modal__close-button" />
            <ModalHeader>
              <ModalTitle padding="12px 36px">
                <ModalTitleText>Create Contract:</ModalTitleText>
              </ModalTitle>
            </ModalHeader>
            <ModalBody paddingLeft="36px" paddingRight="36px">
              <FormikCustomSelector
                boldValue
                label="Customer"
                placeholder="Select customer"
                name="customer_id"
                value={
                  values?.customer_id
                    ? {
                        value: values.customer_id,
                        label: values.customer_name,
                      }
                    : null
                }
                handleChange={(option) => {
                  setFieldValue('customer_id', option.value);
                  setFieldValue('customer_name', option.label);
                }}
                loadOptions={(searchQuery, prevOptions, additional) =>
                  getCustomersFromSearch({ searchQuery, orgId, prevOptions, additional })
                }
                isPaginateable
              />
              <Spacer height="30px" />
            </ModalBody>
            <ModalFooter padding="12px 36px" noFixedHeight>
              <FlexEndContainer>
                <Button border filled color={COLORS.GREY} onClick={onClose}>
                  Cancel
                </Button>
                <Spacer width="14px" />
                <Button
                  color={COLORS.GREEN}
                  border
                  filled
                  active
                  disabled={isSubmitting}
                  onClick={submitForm}
                  data-cy="add-contract-modal__submit-button"
                >
                  Create
                </Button>
              </FlexEndContainer>
            </ModalFooter>
          </Modal>
        )}
      </Formik>
    </ModalContainer>
  );
};
