import React, { useContext } from 'react';
import styled from 'styled-components';
import { cssVar } from 'polished';
import { CentererVertical, FlexerColumn, FlexBetweenContainer, Centerer } from 'components/Core/Flex';
import { AppContext } from 'AppContext';
import { NUMBER_FORMATS } from 'consts/global';
import { SNAPSHOT_METRICS } from 'consts/snapshotMetrics';
import { ReactComponent as FillDot } from 'images/fill-dot.svg';
import { ReactComponent as DownArrow } from 'images/small-fill-down-arrow.svg';
import { ReactComponent as UpArrow } from 'images/small-fill-up-arrow.svg';
import { reFormatDate } from 'utils/dateUtils';
import { numberFormatter } from 'utils/formatters';
import { CHART_COLORS } from './utils';

const OverviewContainer = styled(FlexBetweenContainer)`
  margin-top: 32px;
`;

const ColumnHeader = styled.span`
  font-size: 12px;
  color: var(--accentDarkGray);
  margin-bottom: 20px;
`;

const GrowthColumn = styled(FlexerColumn)`
  width: 25%;
  padding-left: 24px;
  ${({ borderLeft }) => borderLeft && 'border-left: 1px solid var(--primaryBlack5);'}
`;

const MetricColumn = styled(FlexerColumn)`
  margin-bottom: 32px;
`;

const LargeNumber = styled.span`
  font-weight: 300;
  font-size: 24px;
  margin-right: 8px;
`;

const SubValueContainer = styled.span`
  font-size: 12px;
  padding: 8px;
  margin-top: 16px;
  border-radius: 8px;
  background-color: var(--primaryBlack2);
  color: var(--accentDarkGray);
  width: fit-content;
`;

const SubValue = styled.span`
  padding-left: 4px;
  font-weight: bold;
  color: black;
`;

const PercentChange = styled(Centerer)`
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ color }) => color};
  font-size: 10px;
  font-weight: bold;
  padding: 2px 4px;
  border-radius: 4px;

  svg {
    margin-left: 4px;
  }
`;

const GrowthIndicator = ({ metricChange, previousValue, dataType }) => {
  let backgroundColor;
  let color;
  let icon;
  if (metricChange >= 0) {
    backgroundColor = cssVar('--accentGreen');
    color = cssVar('--primaryGreen');
    icon = <UpArrow fill="var(--primaryGreen)" />;
  } else {
    backgroundColor = cssVar('--accentRed');
    color = cssVar('--primaryRed');
    icon = <DownArrow fill="var(--primaryRed)" />;
  }

  const percentChange = metricChange / previousValue;
  return (
    <PercentChange backgroundColor={backgroundColor} color={color}>
      {dataType !== NUMBER_FORMATS.PERCENT && (
        <span>{numberFormatter({ type: NUMBER_FORMATS.PERCENT, rawValue: percentChange })}</span>
      )}
      {icon}
    </PercentChange>
  );
};

const GrowthSummary = ({ month, metricChange, previousValue, dataType, currency }) => {
  let largeNumberValue;
  if (dataType === NUMBER_FORMATS.PERCENT) {
    largeNumberValue = `${Math.round(metricChange * 100)} points`;
  } else {
    largeNumberValue = numberFormatter({ type: dataType, rawValue: metricChange, currency });
  }
  return (
    <MetricColumn>
      <CentererVertical>
        <LargeNumber>{largeNumberValue}</LargeNumber>
        <GrowthIndicator metricChange={metricChange} previousValue={previousValue} dataType={dataType} />
      </CentererVertical>
      <SubValueContainer>
        <span>{month}:</span>
        <SubValue>{numberFormatter({ type: dataType, rawValue: previousValue, currency })}</SubValue>
      </SubValueContainer>
    </MetricColumn>
  );
};

export const OverviewMetrics = ({ dataForOverview, months }) => {
  const {
    appSettings: { currencyISOCode: currency },
  } = useContext(AppContext);

  if (Object.keys(dataForOverview).length === 0) return null;

  const latestMonth = months[months.length - 1];
  const latestMonthData = dataForOverview[latestMonth];
  const metrics = Object.keys(latestMonthData);

  return (
    <OverviewContainer width="100%">
      {/* Latest Month */}
      <GrowthColumn>
        <ColumnHeader>{reFormatDate(latestMonth, 'YYYY-MM', 'MMM YYYY')}</ColumnHeader>
        {metrics.map((metric, index) => (
          <MetricColumn key={metric}>
            <CentererVertical>
              <FillDot fill={CHART_COLORS[index]} style={{ marginRight: 8 }} />
              <LargeNumber>
                {numberFormatter({
                  type: SNAPSHOT_METRICS[metric].dataType,
                  rawValue: latestMonthData[metric].currentValue,
                  currency,
                })}
              </LargeNumber>
            </CentererVertical>
            <SubValueContainer data-cy={`chart-visualizer-overview__${SNAPSHOT_METRICS[metric].label}`}>
              {SNAPSHOT_METRICS[metric].label}
            </SubValueContainer>
          </MetricColumn>
        ))}
      </GrowthColumn>
      {/* MoM */}
      <GrowthColumn borderLeft={true}>
        <ColumnHeader>MoM Growth</ColumnHeader>
        {metrics.map((metric) => (
          <GrowthSummary
            key={metric}
            month={reFormatDate(latestMonthData[metric].oneMonthAgoDate, 'YYYY-MM', 'MMM YYYY')}
            dataType={SNAPSHOT_METRICS[metric].dataType}
            metricChange={latestMonthData[metric].momChange}
            previousValue={latestMonthData[metric].lastMonthValue}
            currency={currency}
          />
        ))}
      </GrowthColumn>
      {/* QoQ */}
      <GrowthColumn borderLeft={true}>
        <ColumnHeader>QoQ Growth</ColumnHeader>
        {metrics.map((metric) => (
          <GrowthSummary
            key={metric}
            month={reFormatDate(latestMonthData[metric].threeMonthsAgoDate, 'YYYY-MM', 'MMM YYYY')}
            dataType={SNAPSHOT_METRICS[metric].dataType}
            metricChange={latestMonthData[metric].qoqChange}
            previousValue={latestMonthData[metric].lastQuarterValue}
            currency={currency}
          />
        ))}
      </GrowthColumn>
      {/* YoY */}
      <GrowthColumn borderLeft={true}>
        <ColumnHeader>YoY Growth</ColumnHeader>
        {metrics.map((metric) => (
          <GrowthSummary
            key={metric}
            month={reFormatDate(latestMonthData[metric].oneYearAgoDate, 'YYYY-MM', 'MMM YYYY')}
            dataType={SNAPSHOT_METRICS[metric].dataType}
            metricChange={latestMonthData[metric].yoyChange}
            previousValue={latestMonthData[metric].lastYearValue}
            currency={currency}
          />
        ))}
      </GrowthColumn>
    </OverviewContainer>
  );
};
