import styled from 'styled-components';
import { Row } from 'components/Core';
import { ReactComponent as ArrowRight } from 'images/arrow-narrow-right.svg';

export const DropdownTitle = styled.div`
  color: var(--primaryBlack50);
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: 20px;
  margin-right: 8px;
`;

export const SubCounterRow = styled.div`
  font-size: 12px;
  font-style: italic;
  font-weight: 700;
  line-height: 16px;
  color: var(--primaryBlack50);
  margin-top: 12px;

  b {
    font-weight: 700;
    color: var(--primaryBlue);
    cursor: pointer;

    &:hover {
      color: var(--secondaryBlue);
    }
  }
`;

export const Widget = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 128px;
  flex-grow: 1;
  height: 100%;
  width: 100%;
  position: relative;
  padding: 20px;
  border-radius: 20px;
  border: 1px solid var(--neutralGray);
  background: white;
  cursor: pointer;

  > span {
    width: 100%;
  }

  &:hover {
    box-shadow: 8px 8px 60px 0px var(--primaryBlack4);

    ${DropdownTitle} {
      color: var(--primaryBlack);
    }

    ${SubCounterRow} {
      color: var(--primaryBlack);
    }

    svg {
      opacity: 1 !important;
    }
  }
`;

export const DropdownRow = styled(Row)`
  white-space: nowrap;

  svg {
    width: 12px;
    height: 12px;
  }
`;

export const CountersRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 12px;
  position: relative;
`;

export const StyledArrowRight = styled(ArrowRight)`
  g {
    opacity: 0.3;
  }
`;

export const CounterInfoSubtitle = styled.div`
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: var(--primaryBlack50);
  margin-top: 4px;
`;

export const ActivityItem = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid var(--neutralGray);
  cursor: pointer;

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    ${StyledArrowRight} {
      g {
        opacity: 1;
      }
    }

    ${CounterInfoSubtitle} {
      color: var(--primaryBlack);
    }
  }
`;

export const ActivityItemTitle = styled.div`
  font-size: 14px;
  font-style: italic;
  font-weight: 700;
  line-height: 20px;
`;

export const LoadingSpinnerWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
`;

export const Counter = styled.div`
  padding: 8px;
  position: relative;
  left: -8px;
  width: 300px;
  display: flex;
  border-radius: 12px;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: var(--primaryBlack3);

    ${CounterInfoSubtitle} {
      color: var(--primaryBlack);
    }
  }
`;

export const CounterInfo = styled.div`
  margin-left: 12px;
`;

export const CounterInfoTitle = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  display: flex;
  align-items: center;
  line-height: 20px;
  color: ${({ isEmpty, red }) =>
    isEmpty ? 'var(--primaryBlack)' : red ? 'var(--tertiaryRed)' : 'var(--primaryBlack)'};
`;

export const RedCircle = styled.div`
  border: 2px solid var(--primaryRed);
  width: 8px;
  border-radius: 100px;
  margin-top: 4px;
  margin-right: 8px;
  height: 8px;
`;

export const AdditionalInfo = styled.div`
  width: 100%;
  padding: 16px;
  background-color: var(--primaryBlack2);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  height: 120px;
  flex-grow: 1;
  justify-content: space-between;
`;

export const PercentTag = styled.div`
  font-size: 10px;
  font-style: normal;
  font-weight: 900;
  line-height: 12px;
  text-transform: uppercase;
  color: white;
  color: var(--tertiaryRed);
  border: 1px solid var(--tertiaryRed);
  border-radius: 100px;
  padding: 4px 8px;
  margin-left: 8px;
`;

export const TitleTotal = styled.div`
  margin-left: 8px;
  color: var(--tertiaryRed);
`;

export const AdditionalInfoAmount = styled.div`
  font-size: 12px;
  font-style: normal;
  font-weight: 900;
  line-height: 16px;
  color: ${({ red }) => (red ? 'var(--tertiaryRed)' : 'var(--primaryBlack)')};
`;

export const AdditionalInfoSubtitle = styled.div`
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin-top: 4px;
`;

export const CounterNumber = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  border-radius: 8px;
  background: ${({ lightBlue, isEmpty, red }) =>
    isEmpty
      ? 'var(--primaryBlack3)'
      : red
      ? 'var(--newRed10)'
      : lightBlue
      ? 'var(--primaryBlue10)'
      : 'var(--primaryBlue)'};
  font-size: 18px;
  font-style: normal;
  font-weight: 900;
  line-height: 24px;
  color: ${({ lightBlue, isEmpty, red }) =>
    isEmpty ? 'var(--primaryBlack)' : red ? 'var(--tertiaryRed)' : lightBlue ? 'var(--primaryBlue)' : 'white'};
`;
