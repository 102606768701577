import { RECOGNITION_TYPES } from 'consts/global';
import * as Yup from 'yup';
import { SOURCE_TYPES, NAME_MATCHING_SOURCE, INTERNAL_CUSTOMER_ID_SOURCE } from './consts';

export const getSchema = ({
  customers,
  transactions,
  products,
  customerIdSourceAndType,
  transactionsIdSourceAndType,
  uploadCsvCustomersIdSource,
}) => {
  const validCustomerIds = new Set(
    [INTERNAL_CUSTOMER_ID_SOURCE, NAME_MATCHING_SOURCE].includes(customerIdSourceAndType?.source)
      ? (customers ?? []).map(({ id }) => id)
      : customers?.map((customer) =>
          customerIdSourceAndType?.type === SOURCE_TYPES.INTERNAL
            ? customer.metadata?.[uploadCsvCustomersIdSource]
            : customer.provider_object_id,
        ),
  );

  const transactionsSet = new Set([
    null,
    ...(transactions ?? [])?.map((transaction) =>
      transactionsIdSourceAndType?.type === SOURCE_TYPES.INTERNAL ? transaction.id : transaction.provider_object_id,
    ),
  ]);

  const baseSchema = Yup.object({
    recognition: Yup.string()
      .oneOf([null, ...Object.values(RECOGNITION_TYPES)], 'Not accepted recognition')
      .nullable(),
    date: Yup.date().nullable(),
    name: Yup.string().nullable(),
    amount: Yup.number().typeError('Total must be a number').nullable(),
    recurring_amount: Yup.number().typeError('MRR must be a number').nullable(),
    product_id: Yup.string()
      .nullable()
      .oneOf([null, ...products?.map((product) => product.id)], "We can't find that product"),
    seats: Yup.number().nullable(),
    include_end_month: Yup.boolean().nullable(),
  });

  const createSchema = Yup.array().of(
    baseSchema.shape({
      customer_id: Yup.string()
        .nullable()
        .required('Customer field is missing')
        .test('oneOf customers', "We can't find that customer", (value) => {
          return validCustomerIds.has(value);
        }),
      start_date: Yup.date().nullable().required('Start date is missing'),
      end_date: Yup.date()
        .nullable()
        .when(['recognition', 'transaction_id'], {
          is: (recognition) => recognition === RECOGNITION_TYPES.linear || !recognition,
          then: Yup.date().required('End date is missing'),
        }),
    }),
  );

  const updateSchema = Yup.array().of(
    baseSchema.shape({
      transaction_id: Yup.string()
        .nullable()
        .test('oneOf transactions', "We can't find that transaction", (value) => {
          return value !== null && transactionsSet.has(value);
        }),
      customer_id: Yup.string().nullable(),
      start_date: Yup.date().nullable(),
      end_date: Yup.date().nullable(),
    }),
  );

  return { updateSchema, createSchema };
};
