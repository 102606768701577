import { useEffect, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import config from 'config';
import { FieldBody, FieldTitle } from 'views/Wizard/styles';
import { Flexer, FlexerColumn } from 'components/Core';
import { FormikCustomCheckbox, FormikCustomInput, FormikCustomSelector } from 'components/Controls';

import { DEFAULT_FONT_OPTIONS } from './BillingSenderSetttings/consts';

const FlexGrow = styled.div`
  flex-grow: 1;
`;

const SectionLabel = styled.div`
  font-size: 12px;
  color: var(--primaryBlack40);
  margin-top: 4px;
`;

export const AdditionalPdfSettings = () => {
  // Get the font options
  const [fonts, setFonts] = useState([]);

  useEffect(() => {
    const fetchFonts = async () => {
      const response = await axios.get(
        `https://www.googleapis.com/webfonts/v1/webfonts?key=${config.app.GOOGLE_FONTS_API_KEY}&sort=popularity`,
      );
      let fontsData = response?.data?.items?.map((font) => ({
        value: font?.family,
        label: font?.family,
      }));
      if (!fontsData || fontsData.length === 0) fontsData = DEFAULT_FONT_OPTIONS;
      setFonts(fontsData);
    };

    if (config.app.GOOGLE_FONTS_API_KEY) fetchFonts();
    else setFonts(DEFAULT_FONT_OPTIONS);
  }, []);

  return (
    <FieldBody>
      <FieldTitle>
        <span>Customizing Subscript-hosted invoices PDFs</span>
      </FieldTitle>

      <FlexerColumn gap="16px">
        <Flexer gap="16px">
          <FlexGrow>
            <FormikCustomSelector
              label="Google Font for PDFs"
              name="billingSenderDefaults.font"
              options={fonts}
              width="100%"
            />
          </FlexGrow>
          <FlexGrow>
            <FormikCustomInput
              data-cy="billingSenderDefaults.dates_format"
              name="billingSenderDefaults.dates_format"
              label="Format for dates"
            />
          </FlexGrow>
        </Flexer>

        <SectionLabel>Show in PDFs:</SectionLabel>
        <Flexer gap="16px">
          <FlexerColumn gap="16px" flexGrow={1}>
            <FormikCustomCheckbox name="billingSenderDefaults.show_shipping_address" label={<b>Shipping address</b>} />

            <FormikCustomCheckbox
              name="billingSenderDefaults.hide_bill_to_address"
              label={<b>Bill-to section</b>}
              isReversedValue
            />

            <FormikCustomCheckbox name="billingSenderDefaults.show_service_dates" label={<b>Start and End dates</b>} />

            <FormikCustomCheckbox name="billingSenderDefaults.show_days_to_pay" label={<b>Terms (days to pay)</b>} />

            <FormikCustomCheckbox
              name="billingSenderDefaults.show_rate_column"
              label={
                <>
                  <b>Rate column</b> for line items
                </>
              }
            />

            <FormikCustomCheckbox
              name="billingSenderDefaults.show_amount_column"
              label={
                <>
                  <b>Amount column</b> for line items
                </>
              }
              data-cy="billingSenderDefaults.show_amount_column"
            />
          </FlexerColumn>

          <FlexerColumn gap="16px" flexGrow={1}>
            <FormikCustomCheckbox name="billingSenderDefaults.show_po_number" label={<b>PO #</b>} />

            <FormikCustomCheckbox
              name="billingSenderDefaults.show_payment_link"
              label={<b>'Pay this invoice' button</b>}
            />

            <FormikCustomCheckbox
              name="billingSenderDefaults.show_quantity_column"
              label={
                <>
                  <b>Quantity column</b> for line items
                </>
              }
              data-cy="billingSenderDefaults.show_quantity_column"
            />

            <FormikCustomCheckbox
              name="billingSenderDefaults.show_powered_by_subscript"
              label={
                <>
                  <b>'Powered by Subscript'</b> label
                </>
              }
            />
          </FlexerColumn>
        </Flexer>

        <FormikCustomInput
          name="billingSenderDefaults.tax_invoice_title"
          label="Invoice title if there are taxes"
          data-cy="billingSenderDefaults.tax_invoice_title"
        />

        <FormikCustomInput
          name="billingSenderDefaults.tax_label"
          label="Tax line display name"
          data-cy="billingSenderDefaults.tax_label"
        />

        <FormikCustomInput
          name="billingSenderDefaults.customer_tax_id_label"
          label="Customer tax ID display name"
          data-cy="billingSenderDefaults.customer_tax_id_label"
        />

        <FormikCustomInput
          type="color"
          name="billingSenderDefaults.primary_color"
          label="Primary accent color on invoices"
          inputWidth={20}
        />
      </FlexerColumn>
    </FieldBody>
  );
};
