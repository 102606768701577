import React from 'react';
import styled from 'styled-components';
import { numberFormatter } from 'utils/formatters';
import { NUMBER_FORMATS } from 'consts/global';
import { ReactComponent as ArrowUp } from 'images/arrow_up_bold.svg';
import { Spacer, Centerer, CentererVertical } from 'components/Core';
import { HelpCircleIcon } from 'components/Icons';
import { TooltipContainer } from 'components/Tooltip';

const TableSubRowsArrow = styled.div`
  svg {
    transition: transform ease 0.4s;
    transform: ${(props) => props.isHidden && 'rotate(180deg)'};
  }
`;

const CustomerName = styled.span`
  color: var(--primaryBlue);
  font-weight: 700;
  cursor: pointer;
`;

export const getColumns = ({ isSubRow = false }) => {
  return [
    {
      Header: isSubRow ? 'Customer(s)' : 'Customer',
      accessor: 'name',
      width: '16%',
      alignRight: false,
      Cell: ({ row }) => {
        return (
          <CentererVertical gap="8px">
            {row.original?.childCustomers?.length > 0 && (
              <TableSubRowsArrow {...row.getToggleRowExpandedProps()} isHidden={!row.isExpanded}>
                <ArrowUp />
              </TableSubRowsArrow>
            )}
            <CentererVertical gap="4px">
              {isSubRow && <span>({row.original?.isCurrentCustomer ? 'Parent' : 'Child'})</span>}
              <CustomerName onClick={() => window.open(`/customers/${row.original?.id}`, '_blank')}>
                {row.original?.name}
              </CustomerName>
            </CentererVertical>
          </CentererVertical>
        );
      },
    },
    {
      Header: () =>
        isSubRow ? (
          ''
        ) : (
          <Centerer>
            Current
            <Spacer width="4px" />
            <TooltipContainer
              width={360}
              isVisible={true}
              underline={false}
              toolTipContent={
                'Current is anything that is not yet due. The due date is in the future of the report date and the aging is based on the due date of a transaction.'
              }
              tooltipWrapperStyles={{ height: '16px' }}
            >
              <HelpCircleIcon />
            </TooltipContainer>
          </Centerer>
        ),
      accessor: 'current',
      width: '14%',
      Cell: ({ row }) => (
        <div>
          {numberFormatter({
            type: NUMBER_FORMATS.CURRENCY,
            rawValue: row.original.current,
            currency: row.original.currency,
            decimalPlaces: 2,
          })}
        </div>
      ),
    },
    {
      Header: isSubRow ? '' : '1-30',
      accessor: '1-30',
      width: '14%',
      Cell: ({ row }) => (
        <div>
          {numberFormatter({
            type: NUMBER_FORMATS.CURRENCY,
            rawValue: row.original['1-30'],
            currency: row.original.currency,
            decimalPlaces: 2,
          })}
        </div>
      ),
    },
    {
      Header: isSubRow ? '' : '31-60',
      accessor: '31-60',
      width: '14%',
      Cell: ({ row }) => (
        <div>
          {numberFormatter({
            type: NUMBER_FORMATS.CURRENCY,
            rawValue: row.original['31-60'],
            currency: row.original.currency,
            decimalPlaces: 2,
          })}
        </div>
      ),
    },
    {
      Header: isSubRow ? '' : '61-90',
      accessor: '61-90',
      width: '14%',
      Cell: ({ row }) => (
        <div>
          {numberFormatter({
            type: NUMBER_FORMATS.CURRENCY,
            rawValue: row.original['61-90'],
            currency: row.original.currency,
            decimalPlaces: 2,
          })}
        </div>
      ),
    },
    {
      Header: isSubRow ? '' : '91+',
      accessor: '91+',
      width: '14%',
      Cell: ({ row }) => (
        <div>
          {numberFormatter({
            type: NUMBER_FORMATS.CURRENCY,
            rawValue: row.original['91+'],
            currency: row.original.currency,
            decimalPlaces: 2,
          })}
        </div>
      ),
    },
    {
      Header: isSubRow ? '' : 'Total',
      accessor: 'total',
      width: '14%',
      Cell: ({ row }) => (
        <div>
          {numberFormatter({
            type: NUMBER_FORMATS.CURRENCY,
            rawValue: row.original.total,
            currency: row.original.currency,
            decimalPlaces: 2,
          })}
        </div>
      ),
    },
  ];
};
