import { useContext, useMemo, useState } from 'react';
import styled from 'styled-components';
import { isEqual } from 'lodash';
import { useFormikContext } from 'formik';

import { AppContext } from 'AppContext';
import { useCustomersAPI, useExternalCustomerAPI } from 'api/customers/hooks';
import { FlexBetweenContainer, Flexer, FlexerColumn } from 'components/Core';
import { ReactComponent as SyncIcon } from 'images/refresh.svg';
import { INTEGRATION_SERVICES } from 'consts/integrations';
import { ADDRESS_FIELDS, formatBillingAddress, formatShippingAddress } from 'models/customer';
import { Popover, PopoverActions, PopoverButton, PopoverPrompt, PopoverWrapper, usePortal } from 'components/Portal';
import { CircleLoader } from 'components/Loaders';
import { getServiceCategory } from 'models/integration';
import { MAGIC_METADATA } from 'consts/global';
import CustomCheckbox from 'components/Controls/CustomCheckbox';
import { useClickOutside } from 'utils/hooks';
import { ReactComponent as RightCircle } from 'images/arrow-right-circle.svg';

import { useConfirmChangesModal } from './useConfirmChangesModal';

const Container = styled(Flexer)`
  align-items: center;
  gap: 4px;
  font-size: 12px;
`;

const StatusWrapper = styled.div`
  padding: 2px 4px;
  border-radius: 4px;
  border: ${({ isSynced }) => `1px solid var(--${isSynced ? 'darkGreen' : 'primaryBlack'})`};
  color: ${({ isSynced }) => (isSynced ? 'var(--darkGreen)' : 'var(--primaryBlack)')};
  font-weight: 700;
  &:first-letter {
    text-transform: capitalize;
  }
`;

const StyledSyncIcon = styled(SyncIcon)`
  width: 15px;
  height: 15px;
  opacity: 0.5;

  &:hover {
    cursor: pointer;
    opacity: 1;
  }
`;

const StyledPopoverPrompt = styled(PopoverPrompt)`
  text-align: left;
  opacity: 1;
  width: ${({ width }) => width ?? 'fit-content'};
  white-space: nowrap;
  line-height: 20px;

  * b {
    font-weight: 900;
    font-size: 9px;
  }
`;

const PromptGreyText = styled.span`
  opacity: 0.5;
`;

const StyledPopoverActions = styled(PopoverActions)`
  margin-top: 10px;
`;

const StyledRightCircle = styled(RightCircle)`
  width: 16px;
  height: 16px;

  path {
    fill: white;
  }
`;

const BoldText = styled.div`
  font-weight: 700;
  font-size: 12px;
`;

const FIELD_OPTIONS = {
  BILIING: 'billing',
  SHIPPING: 'shipping',
  EMAILS: 'emails',
};

const CopyToSubscriptButton = ({
  isLoading,
  fieldsSelected,
  setFieldsSelected,
  hasShippingAddress,
  openConfirmChangesModal,
}) => {
  const [openOptins, setOpenOptions] = useState(false);

  const triggerRef = useClickOutside(() => setOpenOptions(false));

  return (
    <PopoverWrapper ref={triggerRef} style={{ flex: 1 }}>
      <PopoverButton onClick={() => setOpenOptions(true)} disabled={isLoading}>
        <b> COPY TO SUBSCRIPT </b>
      </PopoverButton>

      {openOptins && (
        <Popover width="180px" darkMode YOffset={48} XOffset={75}>
          <StyledPopoverPrompt width="100%">
            <FlexerColumn gap="10px">
              <CustomCheckbox
                label="Contact emails"
                checked={fieldsSelected[FIELD_OPTIONS.EMAILS]}
                onChange={(e) =>
                  setFieldsSelected({
                    ...fieldsSelected,
                    [FIELD_OPTIONS.EMAILS]: e.target.checked,
                  })
                }
                labelColor="white"
                bold
              />
              <CustomCheckbox
                label="Billing address"
                checked={fieldsSelected[FIELD_OPTIONS.BILIING]}
                onChange={(e) =>
                  setFieldsSelected({
                    ...fieldsSelected,
                    [FIELD_OPTIONS.BILIING]: e.target.checked,
                  })
                }
                labelColor="white"
                bold
              />
              {hasShippingAddress && (
                <CustomCheckbox
                  label="Shipping address"
                  checked={fieldsSelected[FIELD_OPTIONS.SHIPPING]}
                  onChange={(e) =>
                    setFieldsSelected({
                      ...fieldsSelected,
                      [FIELD_OPTIONS.SHIPPING]: e.target.checked,
                    })
                  }
                  labelColor="white"
                  bold
                />
              )}
            </FlexerColumn>
            <StyledPopoverActions>
              <PopoverButton
                onClick={() => {
                  openConfirmChangesModal();
                  setOpenOptions(false);
                }}
              >
                <FlexBetweenContainer width="100%">
                  <BoldText>Continue</BoldText>
                  <StyledRightCircle />
                </FlexBetweenContainer>
              </PopoverButton>
            </StyledPopoverActions>
          </StyledPopoverPrompt>
        </Popover>
      )}
    </PopoverWrapper>
  );
};

export const ExternalSyncStatus = ({
  customerImport,
  serviceLabel,
  statusLabel = 'synced',
  currentInvoicingDetails,
  handleExternalUpdate,
}) => {
  const { orgId } = useContext(AppContext);

  const { values, setFieldValue } = useFormikContext();

  const { provider_object_id: externalId, integration_id: integrationId } = customerImport ?? {};

  const service = getServiceCategory(customerImport?.provider_name);

  const hasShippingAddress = service !== INTEGRATION_SERVICES.HUBSPOT;
  const canPushToExternal = service !== INTEGRATION_SERVICES.HUBSPOT;

  const [isPushing, setIsPushing] = useState(false);
  const [fieldsSelected, setFieldsSelected] = useState({
    [FIELD_OPTIONS.BILIING]: true,
    [FIELD_OPTIONS.SHIPPING]: hasShippingAddress,
    [FIELD_OPTIONS.EMAILS]: true,
  });

  const {
    operations: { editCustomer },
  } = useCustomersAPI({ orgId, autoFetch: false, enableToasts: false, disableInvalidate: true });

  const { triggerRef, isPortalVisible, hidePortal, showPortal, Portal } = usePortal({
    YOffset: 45,
  });

  const {
    data: externalCustomerData,
    isLoading: externalCustomerIsLoading,
    operations: { refetch },
  } = useExternalCustomerAPI({
    orgId,
    externalCustomerId: externalId,
    integrationId,
    service,
    autoFetch: !!externalId,
  });

  const isLoading = externalCustomerIsLoading || isPushing;

  const externalCustomer = useMemo(
    () =>
      externalCustomerData?.invoicing_details
        ? externalCustomerData
        : {
            ...externalCustomerData,
            invoicing_details: {
              addressLine1: externalCustomerData?.addressLine1,
              addressLine2: externalCustomerData?.addressLine2,
              city: externalCustomerData?.city,
              state: externalCustomerData?.state,
              country: externalCustomerData?.country,
              postalcode: externalCustomerData?.postalcode,
              contacts: externalCustomerData?.contacts,
              shipping_address: externalCustomerData?.shipping_address,
            },
          },
    [externalCustomerData],
  );

  const formattedBillingAddress = formatBillingAddress({ customer: externalCustomer });
  const formattedShippingAddress = formatShippingAddress({ customer: externalCustomer });
  const currentFormattedBillingAddress = formatBillingAddress({
    customer: { invoicing_details: currentInvoicingDetails },
  });
  const currentFormattedShippingAddress = formatShippingAddress({
    customer: { invoicing_details: currentInvoicingDetails },
  });

  const isSynced = useMemo(() => {
    const isSyncedStatus = {
      billing: formattedBillingAddress === currentFormattedBillingAddress,
      shipping:
        (formattedShippingAddress || formattedBillingAddress) ===
        (currentFormattedShippingAddress || currentFormattedBillingAddress),
      email: isEqual(
        (externalCustomer?.invoicing_details?.contacts ?? []).sort(),
        (currentInvoicingDetails?.contacts ?? []).sort(),
      ),
    };
    return !hasShippingAddress
      ? isSyncedStatus.billing && isSyncedStatus.email
      : isSyncedStatus.billing && isSyncedStatus.email && isSyncedStatus.shipping;
  }, [
    currentFormattedBillingAddress,
    currentFormattedShippingAddress,
    currentInvoicingDetails?.contacts,
    externalCustomer?.invoicing_details?.contacts,
    formattedBillingAddress,
    formattedShippingAddress,
    hasShippingAddress,
  ]);

  const handlePushToSubscript = async () => {
    if (!externalCustomer?.invoicing_details) return;

    setIsPushing(true);

    const updateInvoicingDetails = {};

    if (fieldsSelected[FIELD_OPTIONS.BILIING])
      Object.values(ADDRESS_FIELDS).forEach(
        (field) => (updateInvoicingDetails[field] = externalCustomer.invoicing_details?.[field]),
      );
    if (fieldsSelected[FIELD_OPTIONS.SHIPPING] && hasShippingAddress)
      updateInvoicingDetails.shipping_address = externalCustomer.invoicing_details?.shipping_address;
    if (fieldsSelected[FIELD_OPTIONS.EMAILS])
      updateInvoicingDetails.contacts = externalCustomer.invoicing_details?.contacts;

    const updatedCustomer = await editCustomer({
      id: values?.id,
      data: {
        metadata: {
          ...values?.metadata,
          invoicing_details: {
            ...values?.metadata?.invoicing_details,
            [service]: {
              ...values?.metadata?.invoicing_details?.[service],
              ...updateInvoicingDetails,
            },
          },
        },
        invoicing_details: {
          ...currentInvoicingDetails,
          ...updateInvoicingDetails,
        },
      },
      overwriteAllMetadata: true,
    });

    setFieldValue('metadata', updatedCustomer.metadata);
    setFieldValue(
      values?.metadata?.[MAGIC_METADATA.QUICKBOOKS_BILLWITHPARENT] ? 'childInvoicingDetails' : 'invoicing_details',
      updatedCustomer.invoicing_details,
    );

    setIsPushing(false);
    hidePortal();
  };

  const handlePushToService = async () => {
    setIsPushing(true);

    customerImport &&
      externalCustomer.name &&
      (await handleExternalUpdate({
        name: externalCustomer.name,
        metadata: values?.metadata,
        customerImport,
        invoicingDetails: currentInvoicingDetails,
      }));

    refetch();
    setIsPushing(false);
    hidePortal();
  };

  const {
    openModal: openConfirmChangesModal,
    closeModal: closeConfirmChangesModal,
    Modal: ConfirmChangesModal,
  } = useConfirmChangesModal({
    newData: {
      billing: formattedBillingAddress,
      shipping: formattedShippingAddress,
      contacts: externalCustomer?.invoicing_details?.contacts ?? [],
    },
    oldData: {
      billing: currentFormattedBillingAddress,
      shipping: currentFormattedShippingAddress,
      contacts: currentInvoicingDetails?.contacts ?? [],
    },

    hasBilling: fieldsSelected[FIELD_OPTIONS.BILIING],
    hasShipping: fieldsSelected[FIELD_OPTIONS.SHIPPING],
    hasContacts: fieldsSelected[FIELD_OPTIONS.EMAILS],

    customerName: values?.name,

    onSave: async () => {
      closeConfirmChangesModal();
      await handlePushToSubscript();
    },
  });

  const handleOpenConfirmChangesModal = () => {
    openConfirmChangesModal();
    hidePortal();
  };

  return (
    <Container>
      <StatusWrapper isSynced={isSynced}>{isSynced ? statusLabel : `not ${statusLabel}`}</StatusWrapper>
      <span>with {serviceLabel}</span>
      {isLoading ? (
        <CircleLoader width="16px" height="16px" />
      ) : (
        <StyledSyncIcon ref={triggerRef} onClick={() => showPortal()} />
      )}
      {isPortalVisible && (
        <Portal>
          <Popover width="fit-content" darkMode>
            <StyledPopoverPrompt>
              <PromptGreyText>Emails: </PromptGreyText>
              <sapn>{(externalCustomer?.invoicing_details?.contacts ?? []).join(', ')}</sapn>

              <br />

              <PromptGreyText>Billing: </PromptGreyText>
              <sapn>{formattedBillingAddress}</sapn>

              <br />

              {hasShippingAddress && (
                <>
                  <PromptGreyText>Shipping: </PromptGreyText>
                  <sapn>
                    {!formattedShippingAddress || formattedShippingAddress === formattedBillingAddress ? (
                      <i>Same as billing address</i>
                    ) : (
                      formattedShippingAddress
                    )}
                  </sapn>
                </>
              )}

              <StyledPopoverActions>
                <CopyToSubscriptButton
                  isLoading={isLoading}
                  fieldsSelected={fieldsSelected}
                  setFieldsSelected={setFieldsSelected}
                  hasShippingAddress={hasShippingAddress}
                  openConfirmChangesModal={handleOpenConfirmChangesModal}
                />

                {canPushToExternal && (
                  <PopoverButton onClick={handlePushToService} disabled={isLoading}>
                    <b> PUSH TO SERVICE </b>
                  </PopoverButton>
                )}
              </StyledPopoverActions>
            </StyledPopoverPrompt>
          </Popover>
        </Portal>
      )}

      <ConfirmChangesModal />
    </Container>
  );
};
