import React, { useState } from 'react';
import { useAnalytics } from 'utils/hooks';
import { EVENTS } from 'consts/analytics';
import { GeometryIcon } from 'components/Icons';
import { BILLING_PAGES_ROUTES } from 'views/Billing/consts';
import { USAGE_BASED_ENGINE_TABS } from 'views/Billing/UsageBasedEngine/Tabs/consts';
import { PopoverLinkContents, PopoverLinkContentsItem } from '../PopoverLinkContents';
import { PopoverLinkContentsWrapper, Wrapper } from '../SubLinkPopovers/BillingConfigSubLinksPopover';

export const UsageBasedEngineItem = ({ title, onClose }) => {
  const { trackEvent } = useAnalytics();
  const [isOpen, setIsOpen] = useState(false);

  const subTabs = [
    {
      title: 'Usage Subscriptions',
      to: {
        pathname: BILLING_PAGES_ROUTES.USAGE_BASED_ENGINE,
        search: `?tab=${USAGE_BASED_ENGINE_TABS.USAGE_SUBSCRIPTIONS}`,
      },
    },
    {
      title: 'Pricing Plans',
      to: {
        pathname: BILLING_PAGES_ROUTES.USAGE_BASED_ENGINE,
        search: `?tab=${USAGE_BASED_ENGINE_TABS.PRICING_PLANS}`,
      },
    },
    {
      title: 'Usage Events',
      to: {
        pathname: BILLING_PAGES_ROUTES.USAGE_BASED_ENGINE,
        search: `?tab=${USAGE_BASED_ENGINE_TABS.USAGE_EVENTS}`,
      },
    },
  ];

  return (
    <Wrapper onMouseEnter={() => setIsOpen(true)} onMouseLeave={() => setIsOpen(false)}>
      <PopoverLinkContentsItem
        title={title}
        item={{
          title: 'Usage-based Engine',
          description: 'Manage pricing plans and usage subscriptions',
          to: {
            pathname: BILLING_PAGES_ROUTES.USAGE_BASED_ENGINE,
            search: `?tab=${USAGE_BASED_ENGINE_TABS.PRICING_PLANS}`,
          },
          icon: <GeometryIcon />,
        }}
        onClick={() => {
          trackEvent({ name: EVENTS.CLICK_BILLING_USAGE_BASED_ENGINE });
          onClose();
        }}
      />

      {isOpen && (
        <PopoverLinkContentsWrapper width="230px" left="280px">
          <PopoverLinkContents title="Usage-based Engine" minWidth="100%">
            {subTabs.map((tab) => (
              <PopoverLinkContentsItem
                key={tab.title}
                title={title}
                item={tab}
                width="100%"
                onClick={() => {
                  trackEvent({ name: EVENTS.CLICK_BILLING_USAGE_BASED_ENGINE });
                  onClose();
                }}
              />
            ))}
          </PopoverLinkContents>
        </PopoverLinkContentsWrapper>
      )}
    </Wrapper>
  );
};
