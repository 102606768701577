import React, { useState, useContext } from 'react';
import { AppContext } from 'AppContext';
import { syncInvoiceStatuses } from 'api/billing/requests';
import { TooltipContainer } from 'components/Tooltip';
import { SyncButton, SyncingButton } from 'shared/Common/SyncEntityButton/styles';

const SyncInvoiceStatusesButton = ({ refetchInvoices, pushToast }) => {
  const { orgId } = useContext(AppContext);

  const [isSyncing, setIsSyncing] = useState(false);

  return (
    <TooltipContainer
      width={170}
      fontSize="12px"
      toolTipContent={'Click here to sync invoice statuses from your external accounting system'}
    >
      {isSyncing ? (
        <SyncingButton />
      ) : (
        <SyncButton
          onClick={async () => {
            setIsSyncing(true);
            const { statusesUpdated } = await syncInvoiceStatuses({ orgId });
            if (parseInt(statusesUpdated) > 0) await refetchInvoices();
            pushToast('Finished Syncing!', 'success');
            setIsSyncing(false);
          }}
        />
      )}
    </TooltipContainer>
  );
};

export { SyncInvoiceStatusesButton };
