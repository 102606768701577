import React, { useContext } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { AppContext } from 'AppContext';
import { useUrlQuery } from 'utils/hooks';
import { FEATURES, isFeatureActive } from 'utils/featureUtils';
import { TabButton } from 'components/Buttons';
import { Row } from 'components/Core';
import { ReactComponent as InfoIcon } from 'images/info-circle-customer.svg';
import { ContractInvoicesTable } from './ContractInvoicesTable';
import { ContractAccountingTables } from './ContractAccountingTables';
import { ContractRevRecTable } from './ContractRevRecTable';

const TabsContainer = styled.div`
  padding: 12px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const TablesWrapper = styled.div`
  padding-bottom: 8px;
  position: relative;
`;

const TabsTitle = styled.div`
  font-weight: normal;
  font-size: 20px;
  line-height: 26px;
  display: flex;
  margin-right: 12px;
  align-items: center;
`;

export const TableTitle = styled.div`
  font-size: 14px;
  line-height: 20px;
  font-weight: 900;
  display: flex;
  flex-direction: column;
`;

export const TABS = {
  INVOICES: 'invoices',
  ACCOUNTING: 'accounting',
  REV_REC: 'rev_rec',
};

const TABS_LABELS = {
  [TABS.INVOICES]: 'Invoices',
  [TABS.ACCOUNTING]: 'Accounting',
  [TABS.REV_REC]: 'Rev. Rec.',
};

export const ContractTabs = ({ contractId, contractData, transactionsData }) => {
  const { orgConfigs } = useContext(AppContext);

  const history = useHistory();
  const query = useUrlQuery();
  const activeTab = query.get('tab') ?? TABS.INVOICES;

  const getTabContents = (activeTab) => {
    switch (activeTab) {
      case TABS.INVOICES:
        return <ContractInvoicesTable transactionsData={transactionsData} />;
      case TABS.ACCOUNTING:
        return <ContractAccountingTables contractId={contractId} />;
      case TABS.REV_REC:
        return <ContractRevRecTable contractId={contractId} contractData={contractData} />;
      default:
        return null;
    }
  };

  const tabsToShow = Object.assign({}, TABS);
  if (!isFeatureActive({ feature: FEATURES.BILLING, orgConfigs })) {
    delete tabsToShow.INVOICES;
  }

  return (
    <TablesWrapper>
      <TabsContainer>
        <Row>
          <TabsTitle>Details:</TabsTitle>
          {Object.values(tabsToShow).map((key) => (
            <TabButton
              key={key}
              data-cy={`contract-tab-${key}`}
              active={key === activeTab}
              onClick={() => history.push(`/contracts/${contractId}?tab=${key}`)}
            >
              {TABS_LABELS[key]}
            </TabButton>
          ))}
        </Row>

        <InfoIcon style={{ opacity: 0.1 }} />
      </TabsContainer>

      <div>{getTabContents(activeTab)}</div>
    </TablesWrapper>
  );
};
