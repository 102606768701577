import React from 'react';
import styled from 'styled-components';

import backgroundImage from 'images/csv-help-bcg.png';
import { useClickOutside } from 'utils/hooks';
import { Modal, ModalBody, ModalContainer } from 'components/Modal';
import { FlexerColumn } from 'components/Core';

const ModalHeader = styled.div`
  padding: 4px;
  margin-bottom: 5px;
`;

const FloatingCloseButton = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  width: 32px;
  height: 32px;
  border-radius: 16px;

  cursor: pointer;
`;

const Title = styled.div`
  font-weight: 900;
  font-size: 20px;
  line-height: 26px;
`;

const Counter = styled.div`
  width: 20px;
  min-width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primaryBlack5);
  color: var(--primaryBlack);
  border-radius: 100px;
  margin-right: 12px;
  font-size: 10px;
  font-style: normal;
  font-weight: 900;
  line-height: 12px; /* 120% */
  text-transform: uppercase;
`;

const DotRow = styled.div`
  display: flex;
  margin-bottom: 8px;
  color: var(--primaryBlack50);

  &:last-child {
    margin-bottom: 0;
  }

  &:first-child {
    margin-top: 8px;
  }
`;

const DetailsRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0;
`;

const Dot = styled.div`
  flex: none;
  width: 8px;
  height: 8px;
  margin-top: 6px;
  margin-right: 8px;
  border: 2px solid var(--primaryBlack);
  border-radius: 50%;
  opacity: 0.23;
`;

const DetailsText = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--primaryBlack);

  :last-child {
    margin-bottom: 0;
  }
`;

export const HelpModal = ({ onClose }) => {
  const modal = useClickOutside(onClose);

  return (
    <>
      <ModalContainer data-cy="billing-how-it-works-modal">
        <Modal width="540px" height="auto" overflow="visible" padding="0" ref={modal}>
          <ModalHeader>
            <FloatingCloseButton data-cy="forecasting-how-it-works-modal--close-button" onClick={onClose} />
            <img src={backgroundImage} alt="CSV Upload Help" />
          </ModalHeader>
          <ModalBody paddingLeft="36px" paddingRight="36px" paddingBottom="36px">
            <Title>How to import data to Subscript?</Title>
            <DetailsRow>
              <Counter>1</Counter>
              <DetailsText>
                Pick a way to import your data: <b>creating new records</b> or <b>updating existing ones</b>
              </DetailsText>
            </DetailsRow>
            <DetailsRow>
              <Counter>2</Counter>

              <DetailsText>
                <FlexerColumn>
                  Fill in all the required fields for all data, so you can:
                  <DotRow>
                    <Dot />
                    Match each column of the data in Subscript with the columns of your file
                  </DotRow>
                  <DotRow>
                    <Dot />
                    Fix your CSV file and re-upload it
                  </DotRow>
                  <DotRow>
                    <Dot />
                    Manually make changes
                  </DotRow>
                </FlexerColumn>
              </DetailsText>
            </DetailsRow>
            <DetailsRow>
              <Counter>3</Counter>
              <DetailsText>
                When you have checked all the data, please click on the "Finish uploading" button
              </DetailsText>
            </DetailsRow>
          </ModalBody>
        </Modal>
      </ModalContainer>
    </>
  );
};
