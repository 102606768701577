import { useCallback, useState } from 'react';
import { MergeInvoiceItemsModal } from './MergeInvoiceItemsModal';

export const useMergeInvoiceItemsModal = ({
  invoicingServiceDisplayName,
  invoiceItems,
  currency,
  onConfirm,
  invoiceGrouping,
}) => {
  const [showModal, setShowModal] = useState(false);
  const closeModal = useCallback(() => setShowModal(false), []);
  const openModal = useCallback(() => setShowModal(true), []);

  const Modal = useCallback(
    () =>
      showModal ? (
        <MergeInvoiceItemsModal
          onClose={closeModal}
          invoicingServiceDisplayName={invoicingServiceDisplayName}
          invoiceItems={invoiceItems}
          currency={currency}
          onConfirm={onConfirm}
          invoiceGrouping={invoiceGrouping}
        />
      ) : null,
    [closeModal, currency, invoiceGrouping, invoiceItems, invoicingServiceDisplayName, onConfirm, showModal],
  );

  return {
    Modal,
    openModal,
  };
};
