import axios from 'axios';
import { API_URL } from 'api/consts';

const buildUrl = ({ orgId }) => {
  const baseUrl = `${API_URL}/monthly-breakdown/${orgId}`;
  return baseUrl;
};

export const getMonthlyBreakdown = async ({ orgId, body, params }) => {
  const response = await axios.post(buildUrl({ orgId }), body, { params });
  return response.data;
};
