import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 0 50px;
  background-color: ${({ bgcolor }) => bgcolor ?? 'var(--primaryGray)'};
`;

export const Header = styled.div`
  font-size: 24px;
  line-height: 38px;
  margin-bottom: 20px;
  font-weight: 900;
`;
