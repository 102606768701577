import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import { AIWandIcon } from 'components/Icons';
import { useClickOutside } from 'utils/hooks';
import { Modal, ModalBody, ModalContainer, ModalButton, ModalFooter, ModalCloseIcon } from 'components/Modal';

const WandWrapper = styled.div`
  display: flex;
  width: 60px;
  height: 60px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 100px;
  border: 1px solid var(--Dark-3, rgba(0, 21, 46, 0.03));
  background: linear-gradient(
    180deg,
    rgba(255, 194, 122, 0.1) 0%,
    rgba(255, 98, 221, 0.1) 48%,
    rgba(109, 72, 255, 0.1) 100%
  );
  box-shadow: 0px 10px 40px 0px rgba(152, 80, 245, 0.1);
`;

const ModalHeader = styled.div`
  padding: 4px;
  margin-bottom: 20px;
`;

const Title = styled.div`
  font-weight: 900;
  font-size: 20px;
  line-height: 26px;
`;

const DescriptionText = styled.div`
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  gap: 12px;
`;

const CenteredModal = styled(Modal)`
  justify-content: center;
  align-items: center;
`;

const StyledModalFooter = styled(ModalFooter)`
  width: 100%;
`;

const AIProcessingModal = ({ onClose }) => {
  const modal = useClickOutside(onClose);
  const history = useHistory();

  return (
    <ModalContainer data-cy="ai-processing-modal">
      <CenteredModal width="700px" height="auto" overflow="visible" compact ref={modal}>
        <ModalHeader>
          <WandWrapper>
            <AIWandIcon width="26px" height="26px" />
          </WandWrapper>
          <ModalCloseIcon data-cy="ai-processing-modal__close" onClose={onClose} />
        </ModalHeader>
        <ModalBody centered paddingLeft="36px" paddingRight="36px" paddingBottom="36px">
          <Title>Subscript AI needs time to process your files</Title>
          <DescriptionText>
            Refresh this window in a few moments or go to the page of all contract files to check new data that the
            system will suggest creating based on your uploaded PDFs
          </DescriptionText>
        </ModalBody>
        <StyledModalFooter flexEnd>
          <ModalButton primary onClick={() => history.go(0)}>
            Go to Contract PDFs
          </ModalButton>
        </StyledModalFooter>
      </CenteredModal>
    </ModalContainer>
  );
};

export const useAIProcessingModal = () => {
  const [showModal, setShowModal] = useState(false);

  const openModal = useCallback(() => {
    setShowModal(true);
  }, [setShowModal]);

  const closeModal = useCallback(() => {
    setShowModal(false);
  }, [setShowModal]);

  const Modal = useCallback(() => (showModal ? <AIProcessingModal onClose={closeModal} /> : null), [
    showModal,
    closeModal,
  ]);

  return {
    openModal,
    Modal,
  };
};
