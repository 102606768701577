import dayjs from 'dayjs';
import { Checkbox } from 'antd';
import { isEmpty } from 'lodash';
import React, { useContext, Fragment, useState, useEffect, useMemo } from 'react';
import { AppContext } from 'AppContext';
import { useInvoicingSchedulesAPI } from 'api/billing';
import { NUMBER_FORMATS } from 'consts/global';
import { useToasts } from 'components/Toasts';
import { CancelButton, SubmitButton } from 'components/Buttons';
import { FlexerColumn, FlexerRow, Row } from 'components/Core';
import { ArrowNarrowRightIcon } from 'components/Icons';
import { ReactComponent as CheckIcon } from 'images/check-warning.svg';
import { EXTERNAL_UPDATE_ACTION_OPTIONS, EXTERNAL_UPDATES_SUPPORTED_RESOURCES } from 'views/ExternalUpdates/consts';
import { numberFormatter } from 'utils/formatters';
import { PreviewScheduleModal } from 'views/Billing/InvoicingScheduleModal/InvoicingScheduleSelectTransactionsPanel/PreviewScheduleModal';
import { InvoicingScheduleChangesContext } from './InvoicingScheduleChangesContainer';
import { showInvoiceItemChanges } from './utils';
import {
  ApplyButton,
  ChangeLabel,
  CreateLabel,
  DismissButton,
  ExternalUpdateBody,
  ExternalUpdateBodyTitle,
  InvoiceItem,
  InvoiceItemsCheck,
  InvoiceName,
  InvoiceNewValue,
  InvoicePreviousValue,
  ModalFooter,
  RemoveLabel,
} from './styles';

const formatDate = (date) => dayjs.utc(date)?.format('MMM DD, YYYY');

export const InvoiceChangesToApply = ({
  externalUpdate,
  resolveExternalUpdate,
  updateData,
  isDeleteUpdate,
  removeScheduleMode,
  invoicingSchedule,
  onClose,
  onConfirmUpdate,
  onConfirmNoUpdate,
  customFooter,
}) => {
  const { orgId } = useContext(AppContext);

  const {
    customer,
    acceptedChanges,
    changedInvoices,
    setInitialAcceptedChanges,
    handleInvoiceChangeSelection,
    isConfirmBtnDisabled,
    showInvoicesPreviewModal,
    setShowInvoicesPreviewModal,
  } = useContext(InvoicingScheduleChangesContext) ?? {};

  const [loading, setLoading] = useState(false);

  const {
    operations: { applyScheduleInvoiceChanges },
  } = useInvoicingSchedulesAPI({ orgId, autoFetch: false });

  const { pushError } = useToasts();

  useEffect(() => {
    setInitialAcceptedChanges({ updateData, invoicingSchedule });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const previewUpdatedScheduleData = useMemo(() => {
    if (!acceptedChanges || !invoicingSchedule?.invoices) return null;
    const existingInvoicesToKeep = invoicingSchedule?.invoices?.filter(
      (existingInvoice) =>
        !acceptedChanges.changedInvoices.some((updatedInvoice) => updatedInvoice.id === existingInvoice.id) &&
        !acceptedChanges.invoicesToDelete.some((deletedInvoice) => deletedInvoice.id === existingInvoice.id),
    );

    return {
      ...invoicingSchedule,
      invoices: [
        ...existingInvoicesToKeep,
        ...acceptedChanges?.changedInvoices,
        ...acceptedChanges?.invoicesToInsert,
      ].sort((i1, i2) => (i1.date < i2.date ? -1 : 1)),
      invoicesToDelete: acceptedChanges.invoicesToDelete,
    };
  }, [acceptedChanges, invoicingSchedule]);

  const handleApplyInvoiceChange = async () => {
    setLoading(true);
    try {
      await applyScheduleInvoiceChanges.mutateAsync({
        data: { invoicingSchedule: previewUpdatedScheduleData },
      });

      setShowInvoicesPreviewModal(false);
      onClose?.();
    } catch (err) {
      pushError(err, 'Failed to update invoices');
      console.error({ message: err.message, component: 'InvoiceScheduleChanges', stack: err.stack });
    }
    setLoading(false);
  };

  return (
    <>
      <FlexerColumn padding="0 36px 28px">
        <ExternalUpdateBody>
          <ExternalUpdateBodyTitle>
            {removeScheduleMode ? 'The following invoices will be removed:' : 'Choose changes to apply:'}
          </ExternalUpdateBodyTitle>

          <div style={{ maxHeight: '400px', overflow: 'auto' }}>
            {changedInvoices?.map((invoice) => (
              <InvoiceItem key={invoice?.id}>
                <ChangeLabel active={acceptedChanges.changedInvoices.some(({ id }) => id === invoice.id)}>
                  change
                </ChangeLabel>

                <InvoiceName active={acceptedChanges.changedInvoices.some(({ id }) => id === invoice.id)}>
                  {formatDate(invoice?.date)} unsent invoice:
                </InvoiceName>

                <InvoiceItemsCheck
                  selected={acceptedChanges.changedInvoices.some(({ id }) => id === invoice.id)}
                  onClick={() => handleInvoiceChangeSelection({ invoice, key: 'changedInvoices' })}
                >
                  <Checkbox
                    checked={acceptedChanges.changedInvoices.some(({ id }) => id === invoice.id)}
                    data-cy={`select-invoice-schedule-changes__checkbox--updated-${formatDate(invoice?.date)}`}
                    style={{ marginTop: '2px', marginLeft: '-4px' }}
                  />
                  <FlexerRow alignItems="flex-start" style={{ flexShrink: 0 }}>
                    Change the{' '}
                    <span style={{ color: 'var(--tertiaryYellow)' }}>{formatDate(invoice?.originalDate)}</span> invoice{' '}
                    {Object.entries(invoice?.changedFields ?? {}).map(([key, value], index) => {
                      let node;
                      if (key === 'date') {
                        node = (
                          <Fragment key={key}>
                            date to <InvoicePreviousValue>{formatDate(invoice?.original?.date)}</InvoicePreviousValue>
                            <ArrowNarrowRightIcon style={{ marginRight: '0px' }} />
                            <InvoiceNewValue>{formatDate(invoice?.date)}</InvoiceNewValue>
                          </Fragment>
                        );
                      } else if (key === 'amount') {
                        node = (
                          <Fragment key={key}>
                            amount to{' '}
                            <InvoicePreviousValue>
                              {numberFormatter({
                                currency: invoice?.original?.currency,
                                type: NUMBER_FORMATS.CURRENCY,
                                rawValue: invoice?.original?.amount,
                                decimalPlaces: 2,
                              })}
                            </InvoicePreviousValue>
                            <ArrowNarrowRightIcon style={{ marginRight: '0px' }} />
                            <InvoiceNewValue>
                              {numberFormatter({
                                currency: invoice?.currency,
                                type: NUMBER_FORMATS.CURRENCY,
                                rawValue: invoice?.amount,
                                decimalPlaces: 2,
                              })}
                            </InvoiceNewValue>
                          </Fragment>
                        );
                      } else if (typeof value === 'object') {
                        node = (
                          <Fragment key={key}>
                            {key} to {JSON.stringify(value)}{' '}
                          </Fragment>
                        );
                      } else {
                        node = (
                          <Fragment key={key}>
                            {key} to {value}{' '}
                          </Fragment>
                        );
                      }

                      return (
                        <Fragment key={key}>
                          {index > 0 ? <> and </> : ''}
                          {node}
                        </Fragment>
                      );
                    })}
                  </FlexerRow>
                  {Object.keys(invoice?.changedFields ?? {}).length === 0 && ': '}
                  <FlexerColumn style={{ paddingLeft: '4px', textAlign: 'left' }}>
                    {Object.keys(invoice?.changedFields ?? {}).length === 0 &&
                      showInvoiceItemChanges(invoice?.invoiceItemChanges)}
                  </FlexerColumn>
                </InvoiceItemsCheck>
              </InvoiceItem>
            ))}

            {updateData?.invoicesToDelete?.map((invoice) => (
              <InvoiceItem key={invoice?.id}>
                <RemoveLabel active={acceptedChanges.invoicesToDelete.some(({ id }) => id === invoice.id)}>
                  remove
                </RemoveLabel>

                <InvoiceName active={acceptedChanges.invoicesToDelete.some(({ id }) => id === invoice.id)}>
                  {formatDate(invoice?.date)} unsent invoice:
                </InvoiceName>

                <InvoiceItemsCheck
                  selected={acceptedChanges.invoicesToDelete.some(({ id }) => id === invoice.id)}
                  onClick={() =>
                    !removeScheduleMode && handleInvoiceChangeSelection({ invoice, key: 'invoicesToDelete' })
                  }
                >
                  <Checkbox
                    checked={acceptedChanges.invoicesToDelete.some(({ id }) => id === invoice.id)}
                    disabled={removeScheduleMode}
                    data-cy={`select-invoice-schedule-changes__checkbox--deleted-${formatDate(invoice?.date)}`}
                    style={{ marginTop: '2px', marginLeft: '-4px' }}
                  />
                  Remove the <span>{formatDate(invoice?.date)}</span> invoice
                </InvoiceItemsCheck>
              </InvoiceItem>
            ))}

            {updateData?.invoicesToInsert?.map((invoice) => (
              <InvoiceItem key={invoice?.date}>
                <CreateLabel active={acceptedChanges.invoicesToInsert.some(({ date }) => date === invoice.date)}>
                  create
                </CreateLabel>

                <InvoiceName active={acceptedChanges.invoicesToInsert.some(({ date }) => date === invoice.date)}>
                  {formatDate(invoice?.date)} unsent invoice:
                </InvoiceName>

                <InvoiceItemsCheck
                  selected={acceptedChanges.invoicesToInsert.some(({ date }) => date === invoice.date)}
                  onClick={() => handleInvoiceChangeSelection({ invoice, key: 'invoicesToInsert' })}
                >
                  <Checkbox
                    checked={acceptedChanges.invoicesToInsert.some(({ date }) => date === invoice.date)}
                    disabled={false}
                    data-cy={`select-invoice-schedule-changes__checkbox--inserted-${formatDate(invoice?.date)}`}
                    style={{ marginTop: '2px', marginLeft: '-4px' }}
                  />
                  Create the <span style={{ color: 'var(--secondaryGreen)' }}>{formatDate(invoice?.date)}</span> invoice
                </InvoiceItemsCheck>
              </InvoiceItem>
            ))}
          </div>

          {isEmpty(updateData?.invoicesToInsert) &&
            isEmpty(updateData?.invoicesToDelete) &&
            isEmpty(changedInvoices) &&
            updateData?.deletedTransactions?.length > 0 &&
            updateData?.deletedTransactions?.map((transaction) => (
              <div key={transaction?.id}>
                <b>{transaction?.name} Invoice items will be deleted!</b>
              </div>
            ))}

          {resolveExternalUpdate && (
            <Row horizontal="flex-start">
              <DismissButton
                data-cy="external-update-warning__deny"
                onClick={async () =>
                  await resolveExternalUpdate.mutateAsync({
                    id: externalUpdate?.id,
                    data: { actionType: EXTERNAL_UPDATE_ACTION_OPTIONS.dismiss },
                  })
                }
              >
                Dismiss
              </DismissButton>

              <ApplyButton
                data-cy="external-update-warning__apply"
                onClick={async () => {
                  if (isDeleteUpdate) {
                    await resolveExternalUpdate.mutateAsync({
                      id: externalUpdate?.id,
                      data: {
                        actionType: EXTERNAL_UPDATE_ACTION_OPTIONS.overwrite,
                        objectType: EXTERNAL_UPDATES_SUPPORTED_RESOURCES.INVOICING_SCHEDULE,
                      },
                    });
                    onClose?.();
                  } else {
                    setShowInvoicesPreviewModal(true);
                  }
                }}
                disabled={isConfirmBtnDisabled}
              >
                Apply
                <CheckIcon />
              </ApplyButton>
            </Row>
          )}
        </ExternalUpdateBody>
      </FlexerColumn>

      {!resolveExternalUpdate && (
        <ModalFooter custom={!!customFooter}>
          {customFooter}
          <FlexerRow>
            <CancelButton
              data-cy="invoice-changes-to-apply-modal__deny-button"
              style={{ fontSize: 12 }}
              onClick={onConfirmNoUpdate}
            >
              {removeScheduleMode ? "Don't remove schedule" : "Don't update invoices"}
            </CancelButton>
            <SubmitButton
              data-cy="invoice-changes-to-apply-modal__confirm-button"
              style={{ fontSize: 12 }}
              onClick={onConfirmUpdate}
              disabled={isConfirmBtnDisabled}
            >
              {removeScheduleMode ? 'Yes, remove schedule' : 'Yes, update invoices'}
            </SubmitButton>
          </FlexerRow>
        </ModalFooter>
      )}

      {showInvoicesPreviewModal && (
        <PreviewScheduleModal
          onBack={() => setShowInvoicesPreviewModal(false)}
          previewScheduleData={previewUpdatedScheduleData}
          handleRegenerateSchedule={handleApplyInvoiceChange}
          isLoading={loading}
          customer={customer}
          confirmButtonLabel="Confirm and Apply Changes"
          backButtonLabel="Choose Invoice Changes"
        />
      )}
    </>
  );
};
