import { useCallback, useContext, useState } from 'react';

import { AppContext } from 'AppContext';
import { useCreditNotePdfAPI } from 'api/billing/hooks';
import { PDFPreviewModal, PDF_PREVIEW_MODAL_TYPES } from 'views/Billing/Common/PDFPreviewModal';
import { creditNotePDFDownload } from '../utils';

export const CreditNotePDFPreviewModal = ({ creditNoteId, invoiceId, entityId, language, onClose }) => {
  const { orgId } = useContext(AppContext);

  const {
    data: creditNotePdf,
    isLoading: isPdfLoading,
    isFetching: isPdfFetching,
    operations: { regenerateCreditNotePdf },
  } = useCreditNotePdfAPI({
    orgId,
    creditNoteId,
    invoiceId,
    entityId,
    language,
  });

  return (
    <PDFPreviewModal
      onClose={onClose}
      onDownload={() => creditNotePDFDownload({ creditNotePdf })}
      pdfData={creditNotePdf}
      isPdfLoading={isPdfLoading || regenerateCreditNotePdf.isLoading}
      isPdfFetching={isPdfFetching}
      type={PDF_PREVIEW_MODAL_TYPES.CREDIT_NOTE}
      onRegenerate={async () => {
        await regenerateCreditNotePdf.mutateAsync();
      }}
    />
  );
};

export const useCreditNotePDFPreviewModal = ({ creditNoteId, invoiceId, entityId, language }) => {
  const [showModal, setShowModal] = useState(false);

  const openModal = useCallback(() => setShowModal(true), []);

  const closeModal = useCallback(() => setShowModal(false), []);

  const Modal = useCallback(
    () =>
      showModal ? (
        <CreditNotePDFPreviewModal
          onClose={closeModal}
          language={language}
          entityId={entityId}
          invoiceId={invoiceId}
          creditNoteId={creditNoteId}
        />
      ) : null,
    [closeModal, creditNoteId, entityId, invoiceId, language, showModal],
  );

  return {
    openModal,
    closeModal,
    Modal,
    isModalOpen: showModal,
  };
};
