import styled from 'styled-components';
import { Flexer } from 'components/Core';

export const StyledNextTransactionIcon = styled.div`
  cursor: pointer;
  height: 30px;
  pointer-events: ${(props) => props.unactive && 'none'};
  opacity: ${(props) => props.unactive && '0.2'};

  svg {
    > rect {
      fill: white;
    }

    > path {
      fill: var(--primaryBlack);
    }

    &:hover {
      > rect {
        fill: var(--primaryBlack);
        fill-opacity: 0.03;
      }
    }
  }
`;

export const BulkTransactionsControls = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 116px;
`;

export const BulkTransactionsCounter = styled(Flexer)`
  font-size: 14px;
  line-height: 18px;
`;

export const TotalBulkCount = styled.div`
  opacity: 0.3;
  margin-left: 8px;
`;

export const NavigatorText = styled.div`
  font-size: 14px;
  line-height: 18px;
  opacity: 0.3;
`;

export const StyledPrevTransactionIcon = styled.div`
  transform: rotate(180deg);
  cursor: pointer;
  height: 30px;
  pointer-events: ${(props) => props.unactive && 'none'};

  svg {
    > rect {
      fill: white;
    }

    > path {
      fill: var(--primaryBlack);
    }

    &:hover {
      > rect {
        fill: var(--primaryBlack);
        fill-opacity: 0.03;
      }
    }
  }
`;
