import styled from 'styled-components';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import { NUMBER_FORMATS } from 'consts/global';
import { numberFormatter } from 'utils/formatters';
import { ReactComponent as TooltipIcon } from 'images/help-table-header.svg';
import { ReactComponent as FillDot } from 'images/fill-dot.svg';
import { ReactTableColumnWithTooltip, RowActionPopover, TableActionCell } from 'components/Table';
import { TooltipContainer } from 'components/Tooltip';
import { getTransactionMRR } from 'shared/TransactionContent/utils';
import { TransactionRecognitionIcon } from 'shared/Icons';
import TransactionTableGroupHeadRow from 'shared/TransactionTable/TransactionTableGroupHeadRow';
import { SEARCH_CONFIDENCE } from 'utils/stringUtils';
import {
  TOOLS_TIP_COLORS,
  TRANSACTION_FIELD_VALUE_BY_ID,
  TRANSACTION_TABLE_COLUMN_KEY,
} from 'shared/TransactionTable/consts';
import { getTransactionFieldValue } from 'shared/TransactionTable/utils';
import { ToolTipRow, DealNameColumn } from 'shared/TransactionTable/styles';

const FuzzyName = styled.span`
  color: ${({ textColor }) => textColor ?? 'black'};
`;

const TransactionToolTipContent = () => (
  <div>
    {Object.keys(TOOLS_TIP_COLORS).map((status) => {
      return (
        <ToolTipRow key={status}>
          <FillDot fill={TOOLS_TIP_COLORS[status]} />
          <span style={{ marginLeft: 10 }}>{status}</span>
        </ToolTipRow>
      );
    })}
  </div>
);

export const generateColumns = ({ onTransactionClick, onCustomerClick, isARR, currencyISOCode }) => {
  const columns = [
    {
      Header: ({ getToggleAllRowsSelectedProps }) => (
        <Checkbox data-cy="transaction-row-checkbox--select-all" {...getToggleAllRowsSelectedProps()} />
      ),
      accessor: TRANSACTION_TABLE_COLUMN_KEY.CHECKBOX,
      width: 32,
      Cell: ({ row }) => (
        <Checkbox
          checked={row.isSelected}
          onClick={() => {
            row.toggleRowSelected();
          }}
          data-cy={`transaction-row-checkbox-${row.original.name}`}
        />
      ),
    },
    {
      Header: () => (
        <ReactTableColumnWithTooltip>
          <TooltipContainer width={128} isVisible={true} underline={false} toolTipContent={TransactionToolTipContent()}>
            <TooltipIcon />
          </TooltipContainer>
          Date
        </ReactTableColumnWithTooltip>
      ),
      width: 160,
      accessor: TRANSACTION_TABLE_COLUMN_KEY.DATE,
      Cell: ({ row }) => (
        <TransactionTableGroupHeadRow
          row={row}
          value={getTransactionFieldValue({
            key: TRANSACTION_FIELD_VALUE_BY_ID[TRANSACTION_TABLE_COLUMN_KEY.DATE],
            transaction: row.original,
          })}
        />
      ),
    },
    {
      Header: 'Deal name',
      accessor: TRANSACTION_TABLE_COLUMN_KEY.DEAL_NAME,
      width: 265,
      Cell: ({ row }) => {
        const dealNameValue = getTransactionFieldValue({
          key: TRANSACTION_FIELD_VALUE_BY_ID[TRANSACTION_TABLE_COLUMN_KEY.DEAL_NAME],
          transaction: row.original,
        });

        return (
          <DealNameColumn onClick={() => onTransactionClick(row)} data-cy="transaction-table__name-column">
            {dealNameValue}
          </DealNameColumn>
        );
      },
    },
    {
      Header: 'Customer',
      accessor: TRANSACTION_TABLE_COLUMN_KEY.CUSTOMER,
      width: 265,
      Cell: ({ row }) => {
        let textColor;
        let toolTipContent;
        switch (row.original.customer_confidence) {
          case SEARCH_CONFIDENCE.EXACT:
            textColor = 'var(--primaryGreen)';
            toolTipContent = `Found a customer with the exact name "${row.original.interpreter_customer_name}" (AI interpreted from contract)`;
            break;
          case SEARCH_CONFIDENCE.FUZZY:
            textColor = 'var(--primaryYellow)';
            toolTipContent = `Found a customer with a similar name to "${row.original.interpreter_customer_name}" (AI interpreted from contract)`;
            break;
          case SEARCH_CONFIDENCE.NO_MATCH:
            textColor = 'var(--primaryRed)';
            toolTipContent = `Could not find a customer with a similar name to "${row.original.interpreter_customer_name}" (AI interpreted from contract). Customer is required`;
            break;
          default:
            textColor = 'black';
            toolTipContent = undefined;
        }
        return (
          <TooltipContainer toolTipContent={toolTipContent} width={300} isVisible={!!toolTipContent}>
            <FuzzyName
              textColor={textColor}
              onClick={() => {
                onCustomerClick(row.original.customer_id);
              }}
              data-cy="transaction-table__row__customer-cell"
            >
              {getTransactionFieldValue({
                key: TRANSACTION_FIELD_VALUE_BY_ID[TRANSACTION_TABLE_COLUMN_KEY.CUSTOMER],
                transaction: row.original,
              })}
            </FuzzyName>
          </TooltipContainer>
        );
      },
    },
    {
      Header: () => (
        <TooltipContainer
          width={100}
          isVisible={true}
          underline={false}
          toolTipContent={`${isARR ? 'Annual' : 'Monthly'} Recurring Revenue`}
        >
          <span>RR</span>
        </TooltipContainer>
      ),
      accessor: TRANSACTION_TABLE_COLUMN_KEY.RECURRING_REVENUE,
      width: 80,
      Cell: ({ row }) => {
        const recurringRevenue = getTransactionMRR(row.original);
        return (
          <div
            style={{ fontWeight: 700, position: 'relative' }}
            data-cy="transaction-table__row__recurring-amount-cell"
          >
            {numberFormatter({
              type: NUMBER_FORMATS.CURRENCY,
              rawValue: isARR ? recurringRevenue * 12 : recurringRevenue,
              currency: row.original.currency ?? currencyISOCode,
            })}
          </div>
        );
      },
    },
    {
      Header: 'Total',
      accessor: TRANSACTION_TABLE_COLUMN_KEY.AMOUNT,
      width: 80,
      sortType: (rowA, rowB) => rowA.original.amount - rowB.original.amount,
      Cell: ({ row }) => (
        <div style={{ fontWeight: 700, position: 'relative' }} data-cy="transaction-table__row__total-cell">
          {getTransactionFieldValue({
            key: TRANSACTION_FIELD_VALUE_BY_ID[TRANSACTION_TABLE_COLUMN_KEY.AMOUNT],
            transaction: row.original,
            currency: row.original.currency ?? currencyISOCode,
          })}
        </div>
      ),
    },
    {
      Header: 'Seats',
      accessor: TRANSACTION_TABLE_COLUMN_KEY.SEATS,
      width: 80,
      Cell: ({ row }) => (
        <div style={{ fontWeight: 700, position: 'relative' }}>
          {getTransactionFieldValue({
            key: TRANSACTION_FIELD_VALUE_BY_ID[TRANSACTION_TABLE_COLUMN_KEY.SEATS],
            transaction: row.original,
          })}
        </div>
      ),
    },
    {
      Header: 'type',
      accessor: TRANSACTION_TABLE_COLUMN_KEY.RECOGNITION_TYPE,
      width: 60,
      Cell: ({ row }) => (
        <TransactionRecognitionIcon
          type={getTransactionFieldValue({
            key: TRANSACTION_FIELD_VALUE_BY_ID[TRANSACTION_TABLE_COLUMN_KEY.RECOGNITION_TYPE],
            transaction: row.original,
          })}
        />
      ),
    },
    {
      Header: 'Product',
      accessor: TRANSACTION_TABLE_COLUMN_KEY.PRODUCT,
      width: 130,
      Cell: ({ row }) => {
        let textColor;
        let toolTipContent;
        switch (row.original.product_confidence) {
          case SEARCH_CONFIDENCE.EXACT:
            textColor = 'var(--primaryGreen)';
            toolTipContent = `Found a product with the exact name "${row.original.interpreter_product_name}" (AI interpreted from contract)`;
            break;
          case SEARCH_CONFIDENCE.FUZZY:
            textColor = 'var(--primaryYellow)';
            toolTipContent = `Found a product with a similar name to "${row.original.interpreter_product_name}" (AI interpreted from contract)`;
            break;
          case SEARCH_CONFIDENCE.NO_MATCH:
            textColor = 'var(--primaryRed)';
            toolTipContent = `Could not find a product with a similar name to "${row.original.interpreter_product_name}" (AI interpreted from contract). Product is required`;
            break;
          default:
            textColor = 'black';
            toolTipContent = undefined;
        }
        return (
          <TooltipContainer toolTipContent={toolTipContent} width={300} isVisible={!!toolTipContent}>
            <FuzzyName textColor={textColor}>
              {getTransactionFieldValue({
                key: TRANSACTION_FIELD_VALUE_BY_ID[TRANSACTION_TABLE_COLUMN_KEY.PRODUCT],
                transaction: row.original,
              })}
            </FuzzyName>
          </TooltipContainer>
        );
      },
    },
    {
      Header: 'Start',
      accessor: TRANSACTION_TABLE_COLUMN_KEY.START_DATE,
      width: 90,
      Cell: ({ row }) => (
        <div>
          {getTransactionFieldValue({
            key: TRANSACTION_FIELD_VALUE_BY_ID[TRANSACTION_TABLE_COLUMN_KEY.START_DATE],
            transaction: row.original,
          })}
        </div>
      ),
    },
    {
      Header: 'End',
      accessor: TRANSACTION_TABLE_COLUMN_KEY.END_DATE,
      width: 90,
      Cell: ({ row }) => (
        <div>
          {getTransactionFieldValue({
            key: TRANSACTION_FIELD_VALUE_BY_ID[TRANSACTION_TABLE_COLUMN_KEY.END_DATE],
            transaction: row.original,
          })}
        </div>
      ),
    },
    {
      accessor: 'actions',
      disableSortBy: true,
      width: 65,
      Cell: ({ row }) => (
        <TableActionCell>
          <RowActionPopover onEditClick={() => onTransactionClick(row)} />
        </TableActionCell>
      ),
    },
  ];

  return columns;
};
