import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { transparentize } from 'polished';

import { ReactComponent as ArrowDown } from 'images/arrows-medium-down.svg';
import { Popover, PopoverItemsContainer, PopoverListItem, PopoverWrapper } from 'components/Portal';
import { useClickOutside } from 'utils/hooks';

import { Button } from './Button';
import { ButtonText } from './common';
import { getColor } from './utils';
import PropTypes from 'prop-types';

const ArrowDownWrapper = styled.div`
  margin-left: 7px;
  position: relative;
  top: 2px;
  display: ${({ hideArrow }) => hideArrow && 'none'};

  svg {
    transition: transform ease 0.4s;

    path {
      fill: ${(props) => getColor(props.color, false)};
    }
  }
`;

const DropdownButtonWrapper = styled(Button)`
  color: ${(props) => getColor(props.color, false)};
  padding: ${({ buttonPadding }) => !!buttonPadding && buttonPadding};
  background: ${(props) => (props.fillDropdown ? `${transparentize(0.9, getColor(props.color))}` : 'transparent')};
  border: ${(props) =>
    props.noBorder
      ? 'none'
      : props.border
      ? `1px solid ${getColor(props.color, false)}}`
      : '1px solid var(--primaryBlack5)'};

  svg {
    path {
      fill: ${(props) => getColor(props.color, false)};
    }
  }

  ${(props) =>
    props.active &&
    css`
      color: ${(props) => (props.fillDropdown ? '#fff' : getColor(props.color))};
      background: ${(props) => props.fillDropdown && getColor(props.color)};
      border: ${(props) => (props.noBorder ? 'none' : `1px solid ${getColor(props.color)}`)};

      svg {
        path {
          fill: ${(props) => (props.fillDropdown ? '#fff' : getColor(props.color))};
        }
      }

      ${ArrowDownWrapper} {
        svg {
          transform: rotate(180deg);

          path {
            fill: ${(props) => (props.fillDropdown ? '#fff' : getColor(props.color))};
          }
        }
      }
    `}
`;

const DropdownPopoverItem = styled(PopoverListItem)`
  display: flex;
  align-items: center;
  background-color: ${(props) => (props.isActive ? 'var(--accentGray)' : 'initial')};
  color: ${(props) => (props.isActive ? 'var(--primaryBlack)' : getColor(props.color))};
`;

const DropdownButtonItem = ({ children, onClick, color, name }) => {
  return (
    <PopoverItemsContainer>
      <DropdownPopoverItem onClick={onClick} color={color} data-cy={name}>
        {children}
      </DropdownPopoverItem>
    </PopoverItemsContainer>
  );
};

DropdownButtonItem.propTypes = {
  /**
   * Children element
   */
  children: PropTypes.any,
  /**
   * Optional click handler
   */
  onClick: PropTypes.func,
};

/**
 * Dropdown button
 * @param children
 * @param text
 * @param icon
 * @param color
 * @param hideArrow
 * @param fill background with color
 * @param border add border in inactive state
 * @param width of popover
 * @returns {JSX.Element}
 * @constructor
 */
const DropdownButton = ({
  children,
  text,
  hideArrow,
  icon,
  fill,
  border,
  popoverYOffset,
  noBorder,
  buttonPadding,
  buttonFontWeight,
  color,
  width,
  ...props
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const toggleDropdown = () => setShowDropdown(!showDropdown);
  const dropdownRef = useClickOutside(() => setShowDropdown(false));

  return (
    <PopoverWrapper ref={dropdownRef}>
      <DropdownButtonWrapper
        active={showDropdown}
        onClick={toggleDropdown}
        fillDropdown={fill}
        border={border}
        buttonPadding={buttonPadding}
        noBorder={noBorder}
        color={color}
        {...props}
      >
        {icon}
        {text && <ButtonText fontWeight={buttonFontWeight}>{text}</ButtonText>}
        <ArrowDownWrapper hideArrow={hideArrow} color={color}>
          <ArrowDown />
        </ArrowDownWrapper>
      </DropdownButtonWrapper>
      {showDropdown && (
        <Popover YOffset={popoverYOffset} width={width}>
          {children}
        </Popover>
      )}
    </PopoverWrapper>
  );
};

DropdownButton.propTypes = {
  /**
   * Children element
   */
  children: PropTypes.any,
  /**
   * Text
   */
  text: PropTypes.string,
  /**
   * Icon
   */
  icon: PropTypes.element,
  /**
   * Color
   */
  color: PropTypes.oneOfType([PropTypes.oneOf(['green', 'blue']), PropTypes.string]),
  /**
   * Is filled
   */
  fillDropdown: PropTypes.bool,
  /**
   * Set border
   */
  border: PropTypes.bool,
  /**
   * Is hide arrow
   */
  hideArrow: PropTypes.bool,
  /**
   * Popover width
   */
  width: PropTypes.string,
};

DropdownButton.defaultProps = {
  text: undefined,
  icon: undefined,
  color: undefined,
  fillDropdown: false,
  border: false,
  width: undefined,
};

export { DropdownButton, DropdownButtonItem };
