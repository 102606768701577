import { useContext, useMemo } from 'react';
import dayjs from 'dayjs';
import styled from 'styled-components';
import isBetween from 'dayjs/plugin/isBetween';
import { useHistory } from 'react-router-dom';
import { AppContext } from 'AppContext';
import { Row } from 'components/Core';
import { CircleLoader } from 'components/Loaders';
import { ReactComponent as AlertIcon } from 'images/alert-triangle.svg';
import { useInvoicesStatusCountAPI } from 'api/billing';
import { INVOICE_STATUSES } from 'views/Billing/consts';
import { PERIOD_OPTIONS } from 'shared/Filters';
import { pluralize } from 'utils/stringUtils';
import { numberFormatter } from 'utils/formatters';
import { NUMBER_FORMATS } from 'consts/global';
import {
  Widget,
  DropdownTitle,
  DropdownRow,
  CountersRow,
  Counter,
  CounterNumber,
  CounterInfo,
  CounterInfoTitle,
  CounterInfoSubtitle,
  LoadingSpinnerWrapper,
  SubCounterRow,
  PercentTag,
  TitleTotal,
} from './styles';

const StyledAlertIcon = styled(AlertIcon)`
  path {
    fill: var(--tertiaryRed);
  }
`;

dayjs.extend(isBetween);

export const CollectionsAtRiskWidget = () => {
  const {
    appSettings: { currencyISOCode },
    orgId,
  } = useContext(AppContext);
  const history = useHistory();

  const { data: invoicesStatusCount, isLoading } = useInvoicesStatusCountAPI({
    orgId,
    params: {
      includeTotalAmounts: true,
      includeTodayRemindersCount: true,
    },
  });

  const invoicesByStatusCount = useMemo(
    () =>
      invoicesStatusCount?.reduce(
        (acc, { invoice_status, count, total_amount }) => ({
          ...acc,
          [invoice_status]: {
            ...acc[invoice_status],
            count: parseInt(count ?? 0),
            amount: parseInt(total_amount ?? 0),
          },
        }),
        {},
      ) ?? {},
    [invoicesStatusCount],
  );

  const overduePercentage = useMemo(
    () =>
      invoicesByStatusCount[INVOICE_STATUSES.OVERDUE]?.amount / invoicesByStatusCount[INVOICE_STATUSES.ALL]?.amount ||
      0,
    [invoicesByStatusCount],
  );

  const failedPercentage = useMemo(
    () =>
      invoicesByStatusCount[INVOICE_STATUSES.ALL_FAILED]?.amount /
        invoicesByStatusCount[INVOICE_STATUSES.ALL]?.amount || 0,
    [invoicesByStatusCount],
  );

  return (
    <Widget style={{ paddingBottom: 12 }}>
      <Row horizontal="space-between" style={{ width: '100%' }}>
        <DropdownRow>
          <DropdownTitle>Collections at risk:</DropdownTitle>
        </DropdownRow>

        <StyledAlertIcon />
      </Row>

      <>
        {!!isLoading ? (
          <LoadingSpinnerWrapper data-cy="loader">
            <CircleLoader width="35px" height="35px" isAbsolute isInline={true} />
          </LoadingSpinnerWrapper>
        ) : (
          <>
            <CountersRow style={{ paddingRight: 40, width: '100%' }}>
              <Counter
                onClick={() => {
                  history.push('/billing/invoices', {
                    status: INVOICE_STATUSES.SENT,
                    period: PERIOD_OPTIONS.allTime,
                  });
                }}
              >
                <CounterNumber isEmpty={!invoicesByStatusCount[INVOICE_STATUSES.OVERDUE]?.count} red>
                  {invoicesByStatusCount[INVOICE_STATUSES.OVERDUE]?.count || 0}
                </CounterNumber>

                <CounterInfo>
                  <CounterInfoTitle isEmpty={!invoicesByStatusCount[INVOICE_STATUSES.OVERDUE]?.count}>
                    Overdue
                    {!!invoicesByStatusCount[INVOICE_STATUSES.OVERDUE]?.count ? (
                      <>
                        :
                        <TitleTotal>
                          {numberFormatter({
                            type: NUMBER_FORMATS.CURRENCY,
                            currency: currencyISOCode,
                            rawValue: invoicesByStatusCount[INVOICE_STATUSES.OVERDUE]?.amount || 0,
                          })}
                        </TitleTotal>
                        <PercentTag>
                          {numberFormatter({
                            type: NUMBER_FORMATS.PERCENT,
                            rawValue: overduePercentage,
                            decimalPlaces: 1,
                          })}
                        </PercentTag>
                      </>
                    ) : null}
                  </CounterInfoTitle>

                  <CounterInfoSubtitle>For all time</CounterInfoSubtitle>
                </CounterInfo>
              </Counter>

              <Counter
                data-cy="billing-dashboard__failed-invoices-widget__counter-unsent-invoices"
                onClick={() => {
                  history.push('/billing/invoices', { status: INVOICE_STATUSES.ALL_FAILED });
                }}
              >
                <CounterNumber
                  isEmpty={!invoicesByStatusCount[INVOICE_STATUSES.ALL_FAILED]?.count}
                  data-cy="billing-dashboard__failed-invoices-widget__counter-unsent-invoices__counter"
                  red
                >
                  {invoicesByStatusCount[INVOICE_STATUSES.ALL_FAILED]?.count || 0}
                </CounterNumber>

                <CounterInfo>
                  <CounterInfoTitle>
                    Failed
                    {!!invoicesByStatusCount[INVOICE_STATUSES.ALL_FAILED]?.count ? (
                      <>
                        :
                        <TitleTotal>
                          {numberFormatter({
                            type: NUMBER_FORMATS.CURRENCY,
                            currency: currencyISOCode,
                            rawValue: invoicesByStatusCount[INVOICE_STATUSES.ALL_FAILED]?.amount || 0,
                          })}
                        </TitleTotal>
                        <PercentTag>
                          {numberFormatter({
                            type: NUMBER_FORMATS.PERCENT,
                            rawValue: failedPercentage,
                            decimalPlaces: 1,
                          })}
                        </PercentTag>
                      </>
                    ) : null}
                  </CounterInfoTitle>

                  <CounterInfoSubtitle>For all time</CounterInfoSubtitle>
                </CounterInfo>
              </Counter>

              <Counter
                onClick={() => {
                  history.push('/billing/external-updates');
                }}
              >
                <CounterNumber
                  isEmpty={!invoicesByStatusCount[INVOICE_STATUSES.INVOICING_SCHEDULES_EXTERNAL_UPDATES]?.count}
                  red
                >
                  {invoicesByStatusCount[INVOICE_STATUSES.INVOICING_SCHEDULES_EXTERNAL_UPDATES]?.count || 0}
                </CounterNumber>

                <CounterInfo>
                  <CounterInfoTitle
                    isEmpty={!invoicesByStatusCount[INVOICE_STATUSES.INVOICING_SCHEDULES_EXTERNAL_UPDATES]?.count}
                  >
                    Existing invoices to review
                  </CounterInfoTitle>

                  <CounterInfoSubtitle>Handling changes to invoiced transactions</CounterInfoSubtitle>
                </CounterInfo>
              </Counter>
            </CountersRow>

            {!!invoicesByStatusCount.today_reminders?.count && (
              <SubCounterRow>
                {dayjs.utc().format('MMM D, YYYY')}: set up{' '}
                <b
                  onClick={() =>
                    history.push('/billing/invoices', {
                      status: INVOICE_STATUSES.SENT,
                    })
                  }
                >
                  {pluralize(invoicesByStatusCount.today_reminders?.count, 'reminder')}
                </b>{' '}
                for unpaid invoices
                {!!invoicesByStatusCount.today_reminders_auto_send?.count ? (
                  <>
                    ,{' '}
                    <b
                      onClick={() =>
                        history.push('/billing/invoices', {
                          status: INVOICE_STATUSES.SENT,
                        })
                      }
                    >
                      {pluralize(invoicesByStatusCount.today_reminders_auto_send?.count, 'reminder')}
                    </b>{' '}
                    will be auto-sent
                  </>
                ) : (
                  ''
                )}
              </SubCounterRow>
            )}
          </>
        )}
      </>
    </Widget>
  );
};
