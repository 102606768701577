import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { AppContext } from 'AppContext';
import { CsvUpload } from 'shared/CsvUpload';
import { RECOGNITION_TYPES } from 'consts/global';
import { useTransactionsAPI } from 'api/transactions';
import { useImportsAPI } from 'api/imports';
import {
  DEFAULT_TRANSACTIONS_TRANSFORMATIONS,
  INTERNAL_TRANSACTION_ID_SOURCE,
  SOURCE_TYPES,
} from '../TransactionsCsvUpload/consts';
import { getInitialIdSource, getOrgIdSources } from '../TransactionsCsvUpload/utils';
import { getColumns } from './columns';
import { getSchema } from './schema';

dayjs.extend(utc);

export const SpreadsCsvUpload = ({
  handleSubmit,
  submittingForm,
  entityName,
  defaultMapper,
  eventRecognitionsOnly,
  additionalInfoText,
}) => {
  const {
    integrations,
    organizations,
    appSettings: { currencyISOCode: currency },
  } = useContext(AppContext);

  // `customerIdSourceAndType` (and `transactionsIdSourceAndType`) has object type
  // Example: { type: SOURCE_TYPES.INTERNAL, source: 'User ID' };
  const [transactionsIdSourceAndType, setTransactionsIdSourceAndType] = useState({});

  const transactionsIdSources = useMemo(
    () =>
      getOrgIdSources({
        integrations,
        internalSource: INTERNAL_TRANSACTION_ID_SOURCE,
        suffix: 'transaction ID',
      }),
    [integrations],
  );

  useEffect(() => {
    if (organizations) {
      setTransactionsIdSourceAndType(getInitialIdSource({ idSources: transactionsIdSources }));
    }
  }, [organizations, transactionsIdSources]);

  // With INTERNAL source we get Transactions from our transactions table
  const {
    data: dataTransactions,
    isLoading: isTransactionsLoading,
    operations: { refetch: refetchTransactions },
  } = useTransactionsAPI({
    orgId: organizations?.[0]?.id,
    filters: {
      limit: 30000,
      params: {
        notRecognition: eventRecognitionsOnly
          ? Object.values(RECOGNITION_TYPES).filter(
              (recognition) =>
                ![RECOGNITION_TYPES.eventNotRecurring, RECOGNITION_TYPES.eventRecurring].includes(recognition),
            )
          : undefined,
      },
    },
    autoFetch: false,
  });

  // With EXTERNAL source we get Transactions from our imports table
  const {
    data: dataTransactionImports,
    isLoading: isTransactionImportsLoading,
    operations: { refetch: refetchTransactionImports },
  } = useImportsAPI({
    orgId: organizations?.[0]?.id,
    filters: {
      providerName: transactionsIdSourceAndType.source,
      chifferObjectName: 'transaction',
      transactionRecognitions: eventRecognitionsOnly ? [RECOGNITION_TYPES.eventNotRecurring] : undefined,
      includeTransactionFields: true,
    },
    autoFetch: false,
  });

  // Fetch Transactions
  useEffect(() => {
    if (transactionsIdSourceAndType?.type === SOURCE_TYPES.INTERNAL) {
      refetchTransactions();
    } else {
      refetchTransactionImports();
    }
  }, [transactionsIdSourceAndType, refetchTransactions, refetchTransactionImports]);

  const transactions = useMemo(
    () => (transactionsIdSourceAndType?.type === SOURCE_TYPES.INTERNAL ? dataTransactions : dataTransactionImports),
    [dataTransactionImports, dataTransactions, transactionsIdSourceAndType?.type],
  );

  const getColumnsWithCsvUploadState = useCallback(
    ({ formRef, csvColumnsMapper, setCsvColumnsMapper, csvColumns, defaultMapper }) =>
      getColumns({
        formRef,
        csvColumns,
        setTransactionsIdSourceAndType,
        transactionsIdSourceAndType,
        transactionsIdSources,
        transactions,
        csvColumnsMapper,
        setCsvColumnsMapper,
        currency,

        defaultMapper,
      }),
    [currency, transactions, transactionsIdSourceAndType, transactionsIdSources],
  );

  return (
    <CsvUpload
      backLink="/transactions"
      onlyCreateMode={true}
      additionalInfoText={additionalInfoText}
      entityName={entityName}
      isLoading={isTransactionsLoading || isTransactionImportsLoading || submittingForm}
      createSchema={getSchema({ transactions, transactionsIdSourceAndType })}
      handleSubmit={handleSubmit({ transactions, transactionsIdSourceAndType })}
      getColumnsWithCsvUploadState={getColumnsWithCsvUploadState}
      defaultMapperWithCreateOrUpdateMode={() => defaultMapper}
      csvColumnsTransformations={DEFAULT_TRANSACTIONS_TRANSFORMATIONS}
    />
  );
};
