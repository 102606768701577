import styled from 'styled-components';
import { ReactComponent as _ThumbsUpIcon } from 'images/insights-thumbs-up-icon.svg';

export const ThumbsUpIcon = styled(_ThumbsUpIcon)`
  width: ${({ size }) => size ?? '20px'};
  height: ${({ size }) => size ?? '20px'};
  margin: ${({ margin }) => margin ?? '4px'};

  stroke: ${({ stroke }) => stroke};
  path {
    fill: ${({ fill }) => fill};
  }
`;
