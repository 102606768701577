import * as Sentry from '@sentry/react';
import { useContext } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { AppContext } from 'AppContext';
import { useToasts } from 'components/Toasts';
import { useMutationPolling } from 'api/jobHooks.helper';
import {
  saveConfigsFromWizard,
  replaceAllMetadataSegments as _replaceAllMetadataSegments,
  upsertMetadataSegment as _upsertMetadataSegment,
  updateConfigByKey,
  updateConfigs as _updateConfigs,
} from './requests';
import { getUnprocessedMappingsMessage } from './utils';
import { CACHE_KEY as WATERFALL_CACHE_KEY } from '../waterfall';
import { CACHE_KEY as REVENUE_DETAILS_CACHE_KEY } from '../revenueDetails';

export const useConfigAPI = ({ orgId, enableToasts = true }) => {
  // we use this API also on Wizard page where are no organizations
  const { refreshOrgConfigs } = useContext(AppContext) ?? {};
  const { pushToast: wrappedPushToast, pushError } = useToasts();
  const queryClient = useQueryClient();
  const pushToast = (...args) => {
    if (enableToasts) wrappedPushToast(...args);
  };

  const updateConfigFromWizard = useMutationPolling({
    mutationFn: async ({ data }) => {
      const { jobId, unprocessedMappings } = await saveConfigsFromWizard({ orgId, data });
      return { orgId, jobId, unprocessedMappings };
    },
    mutationOptions: {
      onProgress: () =>
        pushToast(
          `Syncing your settings is in progress, but may take a while. Please check back in a few minutes.`,
          'success',
        ),
      onFailed: () => {
        throw new Error(`We did not finish syncing settings. Please try again later.`);
      },
      onMaxAttempts: () => {
        Sentry.captureMessage(`Syncing settings is taking long to complete for org ${orgId}`, 'warning');
      },
      onError: (err) => {
        pushError(err, `Failed to update configuration. ${err}`);
      },
      onSuccess: (data) => {
        const warning = getUnprocessedMappingsMessage({ unprocessedMappings: data?.unprocessedMappings });
        let message = 'Successfully updated configuration!';
        if (warning) message += ` ${warning}`;
        pushToast(message, 'success', warning ? 10000 : undefined);
        refreshOrgConfigs();
      },
      onSettled: () => {
        queryClient.invalidateQueries(WATERFALL_CACHE_KEY);
        queryClient.invalidateQueries(REVENUE_DETAILS_CACHE_KEY);
      },
    },
  });

  const updateConfig = useMutationPolling({
    mutationFn: async ({ key, value }) => {
      const { jobId } = await updateConfigByKey({ orgId, key, value });

      return { orgId, jobId };
    },
    mutationOptions: {
      onProgress: () => pushToast(`Update your setting is in progress`, 'success'),
      onFailed: () => {
        throw new Error(`We did not finish update setting. Please try again later.`);
      },
      onMaxAttempts: () => {
        Sentry.captureMessage(`Update setting is taking long to complete for org ${orgId}`, 'warning');
      },
      onError: (err) => {
        pushError(err, `Failed to update configuration. ${err}`);
      },
      onSuccess: () => {
        pushToast('Successfully updated configuration!', 'success');
        refreshOrgConfigs();
      },
    },
  });

  const updateConfigs = useMutationPolling({
    mutationFn: async ({ data, integrationId }) => {
      const { jobId } = await _updateConfigs({ orgId, data, integrationId });

      return { orgId, jobId };
    },
    mutationOptions: {
      onProgress: () => pushToast(`Update your settings is in progress`, 'success'),
      onFailed: () => {
        throw new Error(`We did not finish update settings. Please try again later.`);
      },
      onMaxAttempts: () => {
        Sentry.captureMessage(`Update settings is taking long to complete for org ${orgId}`, 'warning');
      },
      onError: (err) => {
        pushError(err, `Failed to update configurations. ${err}`);
      },
      onSuccess: () => {
        pushToast('Successfully updated configurations!', 'success');
        refreshOrgConfigs();
      },
    },
  });

  const replaceAllMetadataSegments = useMutation(
    ({ customerMetadataSegments, transactionMetadataSegments }) =>
      _replaceAllMetadataSegments({ orgId, customerMetadataSegments, transactionMetadataSegments }),
    {
      onError: (err) => pushError(err, `Failed to update metadata segments. ${err}`),
      onSuccess: () => refreshOrgConfigs(),
    },
  );

  const upsertMetadataSegment = useMutation(
    ({ metadataSegment }) => _upsertMetadataSegment({ orgId, metadataSegment }),
    {
      onError: (err) => pushError(err, `Failed to create metadata segment. ${err}`),
      onSuccess: () => refreshOrgConfigs(),
    },
  );

  return {
    updateConfigFromWizard: updateConfigFromWizard.mutateAsync,
    updateConfig: updateConfig.mutateAsync,
    updateConfigs: updateConfigs.mutateAsync,
    replaceAllMetadataSegments,
    upsertMetadataSegment,
  };
};
