import styled from 'styled-components';
import { ReactComponent as CircleCheck } from 'images/transaction_check.svg';
import { ModalBody } from 'components/Modal';
import { Centerer } from 'components/Core';

export const CheckIcon = styled(CircleCheck)`
  margin-left: 10px;
  height: 20px;
  width: 20px;
`;

export const StyledModalBody = styled(ModalBody)`
  padding-left: 36px;
  padding-right: 36px;
`;

export const BodyWrapper = styled.div`
  margin-bottom: 30px;
`;

export const LoaderContainer = styled(Centerer)`
  padding: 50px 0;
`;
