import styled from 'styled-components';
import { mediaQuery } from 'components/Core/Elements';

export const NoOrgContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: start;
  height: 100%;
  width: 100%;
  min-height: 800px;
  background: linear-gradient(180deg, var(--primaryGreen) 0%, var(--secondaryGreen) 100%);
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const InviteCard = styled.div`
  width: 460px;
  height: 280px;
  margin-top: 10%;
  background: var(--primaryBlack5);
  border-radius: 20px;
  ${mediaQuery('min-height').giant`
    margin-top: 20%;
    height: 420px;
    width: 600px;
    padding: 20px;
  `}

  .invite-only-title {
    font-weight: 900;
    font-size: 24px;
    ${mediaQuery('min-height').giant`
    font-size: 30px;
    `}
    line-height: 38px;
    text-align: center;
    color: #ffffff;
  }

  .invite-text {
    font-size: 16px;
    ${mediaQuery('min-height').giant`
    font-size: 24px;
    line-height: 30px;
    `}

    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #ffffff;
  }
`;

export const RequestBtn = styled.a`
  margin-top: 20px;
  width: 195px;
  height: 48px;
  ${mediaQuery('min-height').giant`
  width: 300px;
  height: 70px;
  `}

  h4 {
    font-size: 16px;
    ${mediaQuery('min-height').giant`
      font-size: 24px;
  `}
  }
  background-color: var(--primaryBlack);
  border-radius: 100px;
`;
