import { numberFormatter } from 'utils/formatters';
import { SNAPSHOT_METRICS } from 'consts/snapshotMetrics';
import { NUMBER_FORMATS } from 'consts/global';

export const getFormulasByLabel = ({
  convertedMetricValueObject,
  isQuarterMetric,
  isYearMetric,
  isCumulativeMetric,
  numberMonths,
  isTTM,
  currency,
}) => ({
  [SNAPSHOT_METRICS.GROSS_CUSTOMER_RETENTION.label]: (
    <span>
      ({convertedMetricValueObject.customersPreviousTotal} - {convertedMetricValueObject.churnedCustomers}) /{' '}
      {convertedMetricValueObject.customersPreviousTotal} ={' '}
    </span>
  ),
  [SNAPSHOT_METRICS.LTV.label]: (
    <span>
      (
      {numberFormatter({
        type: NUMBER_FORMATS.CURRENCY,
        rawValue:
          isQuarterMetric || isYearMetric || isCumulativeMetric || isTTM
            ? convertedMetricValueObject.ARPAAvg
            : convertedMetricValueObject.ARPA,
        currency,
      })}
      {isQuarterMetric && !isTTM ? ' * 3 mo' : ''}
      {isYearMetric || isTTM ? ' * 12 mo' : ''} {isCumulativeMetric && !isTTM ? ` * ${numberMonths} mo` : ''}) / (100% -{' '}
      {numberFormatter({
        type: NUMBER_FORMATS.PERCENT,
        rawValue: convertedMetricValueObject.grossCustomerRetention,
        decimalPlaces: 2,
      })}
      ) ={' '}
    </span>
  ),
  [SNAPSHOT_METRICS.LTVGMLaden.label]: (
    <span>
      (
      {numberFormatter({
        type: NUMBER_FORMATS.CURRENCY,
        rawValue:
          isQuarterMetric || isYearMetric || isCumulativeMetric || isTTM
            ? convertedMetricValueObject.ARPAAvg
            : convertedMetricValueObject.ARPA,
        currency,
      })}
      {isQuarterMetric && !isTTM ? ' * 3 mo' : ''}
      {isYearMetric || isTTM ? ' * 12 mo' : ''} {isCumulativeMetric && !isTTM ? ` * ${numberMonths} mo` : ''} *{' '}
      {numberFormatter({
        type: NUMBER_FORMATS.PERCENT,
        rawValue: convertedMetricValueObject.grossMargin,
        decimalPlaces: 2,
      })}
      ) / (100% -{' '}
      {numberFormatter({
        type: NUMBER_FORMATS.PERCENT,
        rawValue: convertedMetricValueObject.grossCustomerRetention,
        decimalPlaces: 2,
      })}
      ) ={' '}
    </span>
  ),
  [SNAPSHOT_METRICS.NET_REVENUE_RETENTION.label]: (
    <span>
      {numberFormatter({
        type: NUMBER_FORMATS.CURRENCY,
        rawValue: convertedMetricValueObject.endingRevenueFromStarting,
        currency,
      })}{' '}
      /{' '}
      {numberFormatter({
        type: NUMBER_FORMATS.CURRENCY,
        rawValue: convertedMetricValueObject.revenuePreviousTotal,
        currency,
      })}{' '}
      ={' '}
    </span>
  ),
  [SNAPSHOT_METRICS.GROSS_REVENUE_RETENTION.label]: (
    <span>
      {numberFormatter({
        type: NUMBER_FORMATS.CURRENCY,
        rawValue: convertedMetricValueObject.revenueRenewed,
        currency,
      })}{' '}
      /{' '}
      {numberFormatter({
        type: NUMBER_FORMATS.CURRENCY,
        rawValue: convertedMetricValueObject.revenuePreviousTotal,
        currency,
      })}{' '}
      ={' '}
    </span>
  ),
  [SNAPSHOT_METRICS.NET_UP_FOR_RENEWAL_RETENTION.label]: (
    <span>
      (
      {numberFormatter({
        type: NUMBER_FORMATS.CURRENCY,
        rawValue: convertedMetricValueObject.amountRenewedThatWasUpNet,
        currency,
      })}{' '}
      /{' '}
      {numberFormatter({ type: NUMBER_FORMATS.CURRENCY, rawValue: convertedMetricValueObject.upForRenewal, currency })})
      ={' '}
    </span>
  ),
  [SNAPSHOT_METRICS.GROSS_UP_FOR_RENEWAL_RETENTION.label]: (
    <span>
      {numberFormatter({
        type: NUMBER_FORMATS.CURRENCY,
        rawValue: convertedMetricValueObject.amountRenewedThatWasUpGross,
        currency,
      })}{' '}
      /{' '}
      {numberFormatter({ type: NUMBER_FORMATS.CURRENCY, rawValue: convertedMetricValueObject.upForRenewal, currency })}{' '}
      ={' '}
    </span>
  ),
  [SNAPSHOT_METRICS.CUSTOMER_UP_FOR_RENEWAL_RETENTION.label]: (
    <span>
      {numberFormatter({
        type: NUMBER_FORMATS.NUMBER,
        rawValue: convertedMetricValueObject.customersRenewed,
      })}{' '}
      / {numberFormatter({ type: NUMBER_FORMATS.NUMBER, rawValue: convertedMetricValueObject.customersUpForRenewal })} ={' '}
    </span>
  ),
  [SNAPSHOT_METRICS.NET_NEW_REVENUE.label]: (
    <span>
      {numberFormatter({ type: NUMBER_FORMATS.CURRENCY, rawValue: convertedMetricValueObject.newRevenue, currency })} +{' '}
      {numberFormatter({ type: NUMBER_FORMATS.CURRENCY, rawValue: convertedMetricValueObject.upsellRevenue, currency })}{' '}
      -{' '}
      {numberFormatter({
        type: NUMBER_FORMATS.CURRENCY,
        rawValue: convertedMetricValueObject.downsellRevenue,
        currency,
      })}{' '}
      -{' '}
      {numberFormatter({ type: NUMBER_FORMATS.CURRENCY, rawValue: convertedMetricValueObject.churnRevenue, currency })}{' '}
      ={' '}
    </span>
  ),
  [SNAPSHOT_METRICS.ARPA.label]: (
    <span>
      {isQuarterMetric && !isTTM && '3 mo '}
      {(isYearMetric || isTTM) && '12 mo '}
      {isCumulativeMetric && !isTTM && `${numberMonths} mo `}
      {(isQuarterMetric || isYearMetric || isCumulativeMetric || isTTM) && ' ARPA = '}
      {`${numberFormatter({
        type: NUMBER_FORMATS.CURRENCY,
        rawValue:
          convertedMetricValueObject.ARPArevenueStatsTotal ?? convertedMetricValueObject.ARPArevenueStatsTotalARR,
        currency,
      })} /
      ${numberFormatter({
        type: NUMBER_FORMATS.NUMBER,
        rawValue: convertedMetricValueObject.ARPAcustomerStatsTotal,
        decimalPlaces: 2,
      })} `}
      ={' '}
    </span>
  ),
  [SNAPSHOT_METRICS.ARPA_NEW.label]: (
    <span>
      {isQuarterMetric && !isTTM && '3 mo '}
      {(isYearMetric || isTTM) && '12 mo '}
      {isCumulativeMetric && !isTTM && `${numberMonths} mo `}
      {(isQuarterMetric || isYearMetric || isCumulativeMetric || isTTM) && ' ARPA (new) = '}
      {`${numberFormatter({
        type: NUMBER_FORMATS.CURRENCY,
        rawValue: convertedMetricValueObject.ARPArevenueStatsNew ?? convertedMetricValueObject.ARPArevenueStatsNewARR,
        currency,
      })} /
      ${numberFormatter({
        type: NUMBER_FORMATS.NUMBER,
        rawValue: convertedMetricValueObject.ARPAcustomerStatsNew,
        decimalPlaces: 2,
      })} `}
      ={' '}
    </span>
  ),
  [SNAPSHOT_METRICS.CAC_RECURRING.label]: (
    <span>
      ({numberFormatter({ type: NUMBER_FORMATS.CURRENCY, rawValue: convertedMetricValueObject.costSales, currency })} +{' '}
      {numberFormatter({ type: NUMBER_FORMATS.CURRENCY, rawValue: convertedMetricValueObject.costMarketing, currency })}
      ) / {convertedMetricValueObject.CACcustomerStatsNew} ={' '}
    </span>
  ),
  [SNAPSHOT_METRICS.CAC_ALL.label]: (
    <span>
      ({numberFormatter({ type: NUMBER_FORMATS.CURRENCY, rawValue: convertedMetricValueObject.costSales, currency })} +{' '}
      {numberFormatter({ type: NUMBER_FORMATS.CURRENCY, rawValue: convertedMetricValueObject.costMarketing, currency })}
      ) / {convertedMetricValueObject.CACcustomerStatsNew_allCustomers} ={' '}
    </span>
  ),
  [SNAPSHOT_METRICS.CEC_RECURRING.label]: (
    <span>
      ({numberFormatter({ type: NUMBER_FORMATS.CURRENCY, rawValue: convertedMetricValueObject.costSales, currency })} +{' '}
      {numberFormatter({ type: NUMBER_FORMATS.CURRENCY, rawValue: convertedMetricValueObject.costMarketing, currency })}
      ) / {convertedMetricValueObject.CACcustomerStatsUpsell} ={' '}
    </span>
  ),
  [SNAPSHOT_METRICS.LTVCACRatio.label]: (
    <span>
      {numberFormatter({ type: NUMBER_FORMATS.CURRENCY, rawValue: convertedMetricValueObject.LTV, currency })} /{' '}
      {numberFormatter({ type: NUMBER_FORMATS.CURRENCY, rawValue: convertedMetricValueObject.CAC, currency })} ={' '}
    </span>
  ),
  [SNAPSHOT_METRICS.GROSS_MARGIN.label]: (
    <span>
      100% -{' '}
      {numberFormatter({ type: NUMBER_FORMATS.CURRENCY, rawValue: convertedMetricValueObject.costCogs, currency })} /{' '}
      {numberFormatter({
        type: NUMBER_FORMATS.CURRENCY,
        rawValue: convertedMetricValueObject.revenueTotalPeriod,
        currency,
      })}{' '}
      ={' '}
    </span>
  ),
  [SNAPSHOT_METRICS.GROSS_MARGIN_NON_RECURRING.label]: (
    <span>
      100% -{' '}
      {numberFormatter({ type: NUMBER_FORMATS.CURRENCY, rawValue: convertedMetricValueObject.costCogs, currency })} /{' '}
      {numberFormatter({
        type: NUMBER_FORMATS.CURRENCY,
        rawValue: convertedMetricValueObject.revenueTotalPeriod,
        currency,
      })}{' '}
      ={' '}
    </span>
  ),
  [SNAPSHOT_METRICS.PAYBACK_PERIOD.label]: (
    <span>
      ({numberFormatter({ type: NUMBER_FORMATS.CURRENCY, rawValue: convertedMetricValueObject.costSales, currency })} +{' '}
      {numberFormatter({ type: NUMBER_FORMATS.CURRENCY, rawValue: convertedMetricValueObject.costMarketing, currency })}
      ) / (
      {numberFormatter({
        type: NUMBER_FORMATS.CURRENCY,
        rawValue: convertedMetricValueObject.PBPrevenueStatsNew,
        currency,
      })}{' '}
      x{' '}
      {numberFormatter({
        type: NUMBER_FORMATS.PERCENT,
        rawValue: convertedMetricValueObject.grossMargin,
        decimalPlaces: 2,
      })}
      ) ={' '}
    </span>
  ),
  [SNAPSHOT_METRICS.GROSS_REVENUE_CHURN_RATE.label]: (
    <span>
      100% -{' '}
      {numberFormatter({
        type: NUMBER_FORMATS.PERCENT,
        rawValue: convertedMetricValueObject.grossRevenueRetention,
        decimalPlaces: 2,
      })}{' '}
      ={' '}
    </span>
  ),
  [SNAPSHOT_METRICS.MAGIC_NUMBER.label]: (
    <span>
      {numberFormatter({ type: NUMBER_FORMATS.CURRENCY, rawValue: convertedMetricValueObject.growthInARR, currency })} /
      (
      {numberFormatter({
        type: NUMBER_FORMATS.CURRENCY,
        rawValue: convertedMetricValueObject.costSalesLastQuarter,
        currency,
      })}{' '}
      +{' '}
      {numberFormatter({
        type: NUMBER_FORMATS.CURRENCY,
        rawValue: convertedMetricValueObject.costMarketingLastQuarter,
        currency,
      })}
      ) ={' '}
    </span>
  ),
  [SNAPSHOT_METRICS.SALES_EFFICIENCY_RATIO.label]: (
    <span>
      {numberFormatter({ type: NUMBER_FORMATS.CURRENCY, rawValue: convertedMetricValueObject.growthInARR, currency })} /
      (
      {numberFormatter({
        type: NUMBER_FORMATS.CURRENCY,
        rawValue: convertedMetricValueObject.costSalesThisQuarter,
        currency,
      })}{' '}
      +{' '}
      {numberFormatter({
        type: NUMBER_FORMATS.CURRENCY,
        rawValue: convertedMetricValueObject.costMarketingThisQuarter,
        currency,
      })}
      ) ={' '}
    </span>
  ),
  [SNAPSHOT_METRICS.ARPU.label]: (
    <span>
      {numberFormatter({
        type: NUMBER_FORMATS.CURRENCY,
        rawValue: convertedMetricValueObject.recurringRevenueTotal,
        currency,
      })}{' '}
      / {numberFormatter({ type: NUMBER_FORMATS.NUMBER, rawValue: convertedMetricValueObject.totalSeats, currency })} ={' '}
    </span>
  ),
  [SNAPSHOT_METRICS.ARPU_NEW.label]: (
    <span>
      {numberFormatter({ type: NUMBER_FORMATS.CURRENCY, rawValue: convertedMetricValueObject.newRevenue, currency })} /{' '}
      {numberFormatter({ type: NUMBER_FORMATS.NUMBER, rawValue: convertedMetricValueObject.newSeats, currency })} ={' '}
    </span>
  ),
  [SNAPSHOT_METRICS.NEW_REVENUE_TO_COST_RATIO.label]: (
    <span>
      {numberFormatter({ type: NUMBER_FORMATS.CURRENCY, rawValue: convertedMetricValueObject.newRevenue, currency })} /
      ({numberFormatter({ type: NUMBER_FORMATS.CURRENCY, rawValue: convertedMetricValueObject.costSales, currency })} +{' '}
      {numberFormatter({ type: NUMBER_FORMATS.CURRENCY, rawValue: convertedMetricValueObject.costMarketing, currency })}
      ) ={' '}
    </span>
  ),
  'Rule of 40': (
    <span>
      {numberFormatter({ type: NUMBER_FORMATS.PERCENT, rawValue: convertedMetricValueObject.recurringPeriodGrowth })} +
      ({numberFormatter({ type: NUMBER_FORMATS.CURRENCY, rawValue: convertedMetricValueObject.netProfit, currency })} /{' '}
      {numberFormatter({
        type: NUMBER_FORMATS.CURRENCY,
        rawValue: convertedMetricValueObject.totalPeriodRevenue,
        currency,
      })}
      ) x 100 ={' '}
    </span>
  ),
  [SNAPSHOT_METRICS.BURN_MULTIPLE.label]: (
    <span>
      ({numberFormatter({ type: NUMBER_FORMATS.CURRENCY, rawValue: convertedMetricValueObject.netProfit, currency })} x
      -1) /{' '}
      {numberFormatter({ type: NUMBER_FORMATS.CURRENCY, rawValue: convertedMetricValueObject.netNewRevenue, currency })}{' '}
      ={' '}
    </span>
  ),
});
