import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useTable } from 'react-table';
import {
  ReactTableBody,
  ReactTableHeader,
  ReactTableHeaderColumn,
  ReactTableHeaderRow,
  ReactTableCell,
  ReactTableRow,
} from 'components/Table';
import { getColumns } from './columns';

const TableContainer = styled.div`
  width: 100%;
`;

export const ChildCustomersTable = ({ dataForTable }) => {
  const columns = useMemo(() => getColumns({ isSubRow: true }), []);

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable({
    columns,
    data: dataForTable,
  });

  return (
    <TableContainer {...getTableProps()}>
      <ReactTableHeader backgroundColor="var(--primaryBlack6)" notSticky>
        {headerGroups.map((headerGroup) => (
          <ReactTableHeaderRow {...headerGroup.getHeaderGroupProps()} noSpaceBetween>
            {headerGroup.headers.map((column) => (
              <ReactTableHeaderColumn
                {...column.getHeaderProps()}
                customWidth={column.width}
                alignRight={column.alignRight ?? true}
              >
                {column.render('Header')}
              </ReactTableHeaderColumn>
            ))}
          </ReactTableHeaderRow>
        ))}
      </ReactTableHeader>
      {rows.length ? (
        <ReactTableBody {...getTableBodyProps()} noBorder>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <ReactTableRow
                {...row.getRowProps()}
                rowBackgroundColor="var(--primaryBlack2)"
                ignoreChildStyles
                noSpaceBetween
              >
                {row.cells.map((cell) => (
                  <ReactTableCell
                    {...cell.getCellProps()}
                    customWidth={cell.column.width}
                    alignRight={cell.column.alignRight ?? true}
                  >
                    {cell.render('Cell')}
                  </ReactTableCell>
                ))}
              </ReactTableRow>
            );
          })}
        </ReactTableBody>
      ) : null}
    </TableContainer>
  );
};
