import { makePaginateableLoadFn } from 'utils/apiUtils';
import { getPricingPlans, getDiscounts } from './requests';

export const getPricingPlansFromSearch = makePaginateableLoadFn({
  listFn: getPricingPlans,
  converter: (pricingPlan) => ({
    label: `${pricingPlan.name || ''} / ${pricingPlan.id || ''}`,
    id: pricingPlan.id,
    value: pricingPlan.id,
    name: pricingPlan.name,
    data: pricingPlan,
  }),
});

export const getDiscountsFromSearch = makePaginateableLoadFn({
  listFn: getDiscounts,
  converter: (discount) => ({
    label: discount.code,
    value: discount.id,
    data: discount,
  }),
});
