import styled, { css } from 'styled-components';
import { Button } from 'components/Buttons';
import { CentererVertical, Flexer, Centerer, FlexerRow } from 'components/Core';

export const BodyWrapper = styled(FlexerRow)`
  height: 100%;
`;

export const EmptyWrapper = styled(Centerer)`
  height: 100%;
  font-size: 14px;
  font-style: italic;
  font-weight: 700;
  line-height: 20px;
  padding: 16px 24px;
  letter-spacing: 0em;
  text-transform: none;
  gap: 8px;
`;

export const SettingsRow = styled.div`
  margin-bottom: 16px;
  font-size: 12px;
  display: flex;
  justify-content: flex-start;
  align-items: start;
  gap: 8px;
  flex-direction: column;
  padding: 16px;
  border-radius: 12px;
  border: 1px solid var(--accentGraySecond);
`;

export const LeftPart = styled.div`
  padding: 28px 36px;
  border-right: 1px solid var(--primaryBlack5);
  background: var(--primaryBlack2);
  width: 314px;
  min-width: 314px;
`;

export const SettingItem = styled(Centerer)`
  padding: 4px 8px;
  border-radius: 4px;
  background-color: var(--primaryBlue10);
  color: var(--primaryBlue);
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  margin-right: ${({ withMarginRight }) => (withMarginRight ? '8px' : undefined)};
  cursor: pointer;
  gap: 4px;
`;

export const SettingItemText = styled(Centerer)`
  margin-top: 1px;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  gap: 4px;
`;

export const SettingItemWithText = styled(Centerer)`
  margin-right: 12px;
  font-size: 12px;
  align-items: ${({ alignItems }) => alignItems};

  > span {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  }
`;

export const SchedulesCount = styled.div`
  font-size: 10px;
  font-style: normal;
  font-weight: 900;
  line-height: 12px; /* 120% */
  text-transform: uppercase;
  opacity: 0.3;
`;

export const LeftTitle = styled.div`
  font-size: 14px;
  font-weight: 900;
  line-height: 20px;
  margin-bottom: 16px;

  span {
    font-style: italic;
    color: var(--primaryBlack50);
  }
`;

export const PreviewHeader = styled.div`
  color: var(--primaryBlack50);
  line-height: 12px;
  font-size: 10px;
  font-weight: 900;
  margin: 16px 0;
  text-transform: uppercase;
`;

export const DefaultMemo = styled.span`
  color: var(--primaryBlue);
  margin-left: 4px;
  cursor: pointer;
`;

export const TransactionsContainer = styled.div`
  max-height: 510px;
  overflow: auto;
`;

export const RightPart = styled.div`
  padding: 28px 36px;
  max-height: 510px;
  overflow: auto;
`;

export const RightTitle = styled.div`
  font-size: 20px;
  font-style: normal;
  font-weight: 900;
  line-height: 26px;
  margin-bottom: 16px;
`;

export const InvoicingScheduleLine = styled(Flexer)`
  justify-content: space-between;
  margin-bottom: 12px;
  padding-bottom: 12px;
  border-bottom: 1px solid var(--neutralGray);

  &:last-child {
    border-bottom: none;
  }
`;

export const InvoicingScheduleBadge = styled(Centerer)`
  background-color: var(--dark7);
  color: white;
  border-radius: 4px;
  width: 16px;
  height: 16px;
  margin-right: 8px;
  font-size: 9px;
  font-style: normal;
  font-weight: 900;
  line-height: 12px;
  text-transform: uppercase;
`;

export const CustomerName = styled.div`
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  text-transform: none;
`;

export const TransactionsAmount = styled.div`
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
`;

export const InvoicingScheduleSummary = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin-top: 4px;
`;

export const BulkActionsButtonsContainer = styled(CentererVertical)`
  padding: 8px 16px;
  border-radius: 100px;
  background-color: var(--backgroundGray);
  position: sticky;
  bottom: 4vh;
  z-index: 20;
  width: fit-content;
  margin: 0 auto;
  color: white;
`;

export const BulkCreateButton = styled(Button)`
  width: fit-content;
  color: white;
  background: ${({ disabled }) => (disabled ? 'var(--primaryGreen50)' : 'var(--primaryGreen)')};
`;

export const BulkDismissButton = styled(Button)`
  color: white;
  background-color: transparent;
  border: 1px solid var(--dark10);
`;

export const GroupByWrapper = styled(Flexer)`
  gap: 8px;
  flex-grow: 1;
  border-radius: 8px;
  border: 1px solid var(--neutralGray);
  padding: 4px;
  width: 100%;
`;

export const GroupByItem = styled(Centerer)`
  flex-grow: 1;

  &:hover {
    cursor: pointer;
  }

  ${({ selected }) =>
    selected
      ? css`
          background-color: var(--primaryBlue10);
          color: var(--primaryBlue);
          border-radius: 4px;
          font-weight: 700;
        `
      : css`
          font-weight: 600;
        `}
`;

export const GroupByItemLine = styled.div`
  height: 100%;
  width: 1px;
  background-color: var(--neutralGray);
`;
