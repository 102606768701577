import React, { useContext } from 'react';
import styled from 'styled-components';
import { FlexerColumn, FlexerRow, Spacer } from 'components/Core';
import { GreyDot } from 'components/Icons';
import {
  Modal,
  ModalBody,
  ModalButton,
  ModalCloseIcon,
  ModalContainer,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from 'components/Modal';
import { GlobalTogglesContext } from './GlobalTogglesContext';
import {
  GlobalToggleDescription,
  GlobalToggleInflux,
  GlobalToggleIsARR,
  GlobalToggleIsCommitted,
  GlobalToggleIsOptimistic,
  GlobalToggleIsRollup,
  STORAGE_TOGGLES_KEYS,
} from './GlobalTogglesOptions';

const ToggleRow = styled(FlexerRow)`
  align-items: center;
`;

export const GlobalTogglesModal = ({ onCloseModal }) => {
  const {
    draftGlobalToggles,
    setDraftGlobalToggles,
    applyDraftGlobalToggles,
    committedPolicy,
    committedStart,
    showInfluxToggle,
  } = useContext(GlobalTogglesContext);

  return (
    <ModalContainer data-cy="global-toggles-modal">
      <Modal width="540px" overflow="auto">
        <ModalHeader>
          <ModalCloseIcon onClose={onCloseModal} />
          <ModalTitle bold>How Subscript calculates metrics:</ModalTitle>
        </ModalHeader>
        <ModalBody gap="16px">
          <Spacer height="20px" />

          <FlexerColumn>
            <ToggleRow>
              <GreyDot size="8px" />
              <GlobalToggleIsCommitted
                globalTogglesState={draftGlobalToggles}
                setGlobalTogglesState={setDraftGlobalToggles}
                committedPolicy={committedPolicy}
                committedStart={committedStart}
              />
              <div>recurring revenue</div>
            </ToggleRow>
            <GlobalToggleDescription
              globalTogglesState={draftGlobalToggles}
              toggleKey={STORAGE_TOGGLES_KEYS.IS_COMMITTED}
            />
          </FlexerColumn>

          <FlexerColumn>
            <ToggleRow>
              <GreyDot size="8px" />
              <GlobalToggleIsARR
                globalTogglesState={draftGlobalToggles}
                setGlobalTogglesState={setDraftGlobalToggles}
              />
              <div>recurring revenue</div>
            </ToggleRow>
          </FlexerColumn>

          <FlexerColumn>
            <ToggleRow>
              <GreyDot size="8px" />
              <GlobalToggleIsRollup
                globalTogglesState={draftGlobalToggles}
                setGlobalTogglesState={setDraftGlobalToggles}
              />
            </ToggleRow>
            <GlobalToggleDescription
              globalTogglesState={draftGlobalToggles}
              toggleKey={STORAGE_TOGGLES_KEYS.IS_ROLLUP}
            />
          </FlexerColumn>

          <FlexerColumn>
            <ToggleRow>
              <GreyDot size="8px" />
              <GlobalToggleIsOptimistic
                globalTogglesState={draftGlobalToggles}
                setGlobalTogglesState={setDraftGlobalToggles}
              />
              <div>churn assumption</div>
            </ToggleRow>
            <GlobalToggleDescription
              globalTogglesState={draftGlobalToggles}
              toggleKey={STORAGE_TOGGLES_KEYS.IS_OPTIMISTIC}
            />
          </FlexerColumn>

          {showInfluxToggle && (
            <FlexerColumn>
              <ToggleRow>
                <GreyDot size="8px" />
                <GlobalToggleInflux
                  globalTogglesState={draftGlobalToggles}
                  setGlobalTogglesState={setDraftGlobalToggles}
                />
                <div>In Flux</div>
              </ToggleRow>
              <GlobalToggleDescription
                globalTogglesState={draftGlobalToggles}
                toggleKey={STORAGE_TOGGLES_KEYS.IS_INFLUX}
              />
            </FlexerColumn>
          )}
        </ModalBody>
        <ModalFooter flexEnd>
          <ModalButton onClick={onCloseModal}>Close</ModalButton>
          <ModalButton
            className="primary"
            onClick={() => {
              applyDraftGlobalToggles();
              onCloseModal();
            }}
          >
            Save
          </ModalButton>
        </ModalFooter>
      </Modal>
    </ModalContainer>
  );
};
