import { Link } from 'react-router-dom';
import buttonIcon from 'images/arrows-bold-left-green.svg';
import styled from 'styled-components';

const StyledLinkBackButton = styled(Link)`
  padding: 11px;
  padding-left: 30px;
  box-shadow: 4px 4px 24px var(--primaryBlack3);
  border-radius: 100px;
  border: 1px solid var(--accentGraySecond);
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: var(--primaryBlack);
  margin-right: 18px;
  background-image: url(${buttonIcon});
  background-repeat: no-repeat;
  background-position: center left 11px;
`;

export const LinkBackButton = ({ children, ...rest }) => (
  <StyledLinkBackButton {...rest}>{children}</StyledLinkBackButton>
);
