import React, { useContext } from 'react';
import styled from 'styled-components';
import { Centerer } from 'components/Core';
import { usePortal } from 'components/Portal';
import { CollapseIcon } from 'components/Icons/CollapseIcon';
import { FilterConditionHead, SelectorItem, StyledSelectorPopover } from 'shared/Filters/styles';
import { GROWTH_TYPE_LABEL, GROWTH_TYPE_OPTIONS } from 'consts/global';
import { DashboardContext } from '../DashboardContext';

const Container = styled(Centerer)`
  position: relative;
  z-index: 10;
  margin-left: 6px;

  &:hover {
    cursor: pointer;
  }
`;

const ArrowWrapper = styled.div`
  display: flex;
  transform: ${(props) => (props.isOpen ? 'rotate(0deg)' : 'rotate(180deg)')};
`;

const GrowthTypeLabel = styled.div`
  margin-left: 5px;
`;

export const PeriodGrowthDropdown = ({ name, showHasButton = false }) => {
  const { growthType, setGrowthType } = useContext(DashboardContext);
  const { triggerRef, togglePortal, isPortalVisible, Portal } = usePortal({
    XOffset: showHasButton ? -90 : -105,
    YOffset: 25,
  });
  const handleTogglerClick = (event) => {
    event.stopPropagation();
    togglePortal();
  };

  const handleOptionClick = (event, key) => {
    event.stopPropagation();
    setGrowthType(key);
    togglePortal();
  };

  return (
    <>
      {!showHasButton && <GrowthTypeLabel>{`(${GROWTH_TYPE_LABEL[growthType]})`}</GrowthTypeLabel>}
      <Container ref={triggerRef} data-cy={`${name}-growth-type-selector-toggler`}>
        {showHasButton ? (
          <FilterConditionHead onClick={handleTogglerClick}>{GROWTH_TYPE_LABEL[growthType]}</FilterConditionHead>
        ) : (
          <ArrowWrapper isOpen={isPortalVisible} onClick={handleTogglerClick}>
            <CollapseIcon size="16px" />
          </ArrowWrapper>
        )}
      </Container>
      {isPortalVisible && (
        <Portal>
          <StyledSelectorPopover data-cy={'growth-type-selector'}>
            {Object.entries(GROWTH_TYPE_OPTIONS).map(([key, value]) => (
              <SelectorItem
                key={key}
                current={growthType === key}
                onClick={(event) => handleOptionClick(event, key)}
                data-cy={`growth-type-selector--${key}`}
              >
                {value}
              </SelectorItem>
            ))}
          </StyledSelectorPopover>
        </Portal>
      )}
    </>
  );
};
