import React from 'react';
import { TooltipContainer } from 'components/Tooltip';
import { ListItem, StyledLink, tooltipStyles } from './styles';
import { NewBadge } from 'components/Blocks';

export const AnchorLink = ({ active, title, icon, explainTooltipText, to, isNewFeature }) => {
  return (
    <TooltipContainer
      toolTipContent={explainTooltipText}
      tooltipStyles={tooltipStyles}
      xOffset={124}
      yOffset={-74}
      hideArrow={true}
      isVisible={!!explainTooltipText}
    >
      <ListItem $active={active}>
        {isNewFeature && <NewBadge>New</NewBadge>}
        <StyledLink to={to} $active={active} data-cy={`navbar__${title?.toLowerCase()}-link`}>
          {icon}
          {title}
        </StyledLink>
      </ListItem>
    </TooltipContainer>
  );
};
