import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

import { ReactComponent as HowItWorksIllustration } from 'images/billing-how-it-works-illustration.svg';
import { useClickOutside } from 'utils/hooks';
import { Modal, ModalBody, ModalContainer } from 'components/Modal';

const ModalHeader = styled.div`
  padding: 4px;
  margin-bottom: 20px;
`;

const FloatingCloseButton = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  width: 32px;
  height: 32px;
  border-radius: 16px;

  cursor: pointer;
`;

const Title = styled.div`
  font-weight: 900;
  font-size: 20px;
  line-height: 26px;
`;

const DescriptionText = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  gap: 12px;
`;

const DetailsRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0;
  gap: 8px;
`;

const Dot = styled.div`
  flex: none;
  width: 8px;
  height: 8px;
  margin: 5px;
  border: 2px solid var(--primaryBlue);
  border-radius: 50%;
`;

const DetailsText = styled.div`
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: 20px;
  color: var(--primaryBlack);

  :last-child {
    margin-bottom: 0;
  }
`;

const HowItWorksModal = ({ onClose }) => {
  const modal = useClickOutside(onClose);

  return (
    <>
      <ModalContainer data-cy="billing-how-it-works-modal">
        <Modal width="540px" height="auto" overflow="visible" padding="0" ref={modal}>
          <ModalHeader>
            <FloatingCloseButton data-cy="forecasting-how-it-works-modal--close-button" onClick={onClose} />
            <HowItWorksIllustration />
          </ModalHeader>
          <ModalBody paddingLeft="36px" paddingRight="36px" paddingBottom="36px">
            <Title>Transactions → Invoicing Schedules → Invoices</Title>
            <DescriptionText>
              To create invoices for your transactions, you can set up an Invoicing Schedule. This allows you to set the
              invoicing frequency and distribute the transactions across invoices as needed.
            </DescriptionText>
            <DescriptionText>
              To avoid missing any invoices and receive payments faster, you can use the Tasks section to:
            </DescriptionText>
            <DetailsRow>
              <Dot />
              <DetailsText>
                <b>Easily find and fix problems</b> with invoices
              </DetailsText>
            </DetailsRow>
            <DetailsRow>
              <Dot />
              <DetailsText>
                Create new invoices or invoicing schedules with <b>differing frequencies, and flexible amounts</b>
              </DetailsText>
            </DetailsRow>
            <DetailsRow>
              <Dot />
              <DetailsText>
                Send invoices and reminders and <b>keep track of payment status</b>
              </DetailsText>
            </DetailsRow>
          </ModalBody>
        </Modal>
      </ModalContainer>
    </>
  );
};

export const useHowItWorksModal = () => {
  const [showModal, setShowModal] = useState(false);

  const openModal = useCallback(() => {
    setShowModal(true);
  }, [setShowModal]);

  const closeModal = useCallback(() => {
    setShowModal(false);
  }, [setShowModal]);

  const Modal = useCallback(() => (showModal ? <HowItWorksModal onClose={closeModal} /> : null), [
    showModal,
    closeModal,
  ]);

  return {
    openModal,
    Modal,
  };
};
