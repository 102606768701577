import React, { useContext, useMemo } from 'react';
import { isEqual } from 'lodash';
import { Form, Formik } from 'formik';
import styled from 'styled-components';

import { AppContext } from 'AppContext';
import { useIntegrationsAPI, useIntegrationAccountsAPI } from 'api/integrations';
import { FlexerColumn } from 'components/Core';
import { Loader } from 'components/Loaders';
import { ReactComponent as SaveIcon } from 'images/device-floppy.svg';
import { COLORS } from 'consts/colors';
import { useConfigAPI } from 'api/configs';
import { ADDITIONAL_ORG_CONFIGS, ORG_CONFIGS } from 'consts/global';
import { getIntegrationDisplayName } from 'models/integration';
import { ConfigurationHeader } from 'views/Configuration/ConfigurationHeader';
import { CONFIGURATION_TABS } from 'views/Configuration/consts';

import TabBody from './TabBody';
import AdditionalSettings from './AdditionalSettings';
import GeneralSettings from './GeneralSettings';
import { GeneralSettingTabContainer, StyledIconButton } from '../styles';
import IntegrationEntity from './IntegrationEntity';
import { BillingCustomFields } from './BillingCustomFields';
import { BillingTrackingCategories } from './BillingTrackingCategories';

const CompactSection = styled(FlexerColumn)`
  max-width: 770px;
  width: 100%;
  gap: 8px;
`;

const INITIAL_SELECTED_ACCOUNTS_STATE = { sales: [], marketing: [], cogs: [] };

const GeneralLedgerTab = ({ selectedService, selectedIntegration }) => {
  const { orgId, organizations, integrations, setIntegrations, orgConfigs } = useContext(AppContext);

  const organization = organizations?.[0];

  const { updateConfigs } = useConfigAPI({ orgId });
  const {
    operations: { updateIntegration },
  } = useIntegrationsAPI({ orgId, autoFetch: false });
  const { data: accounts, isLoading } = useIntegrationAccountsAPI({
    orgId,
    integrationId: selectedIntegration.id,
  });

  const initialValues = useMemo(
    () =>
      selectedIntegration
        ? {
            ...selectedIntegration,
            display_name: getIntegrationDisplayName(selectedIntegration),
            metadata: {
              ...INITIAL_SELECTED_ACCOUNTS_STATE,
              ...selectedIntegration.metadata,
              automaticTaxCalculation: selectedIntegration?.metadata?.automaticTaxCalculation ?? false,
              useMappedTaxCodes: selectedIntegration?.metadata?.useMappedTaxCodes ?? false,
              byEntity: selectedIntegration?.metadata?.byEntity ?? 'account',
            },
            configs: {
              [ORG_CONFIGS.INCOME_ACCOUNT_REF_ID]:
                orgConfigs[ADDITIONAL_ORG_CONFIGS.INCOME_ACCOUNT_REF_ID_BY_INTEGRATION_ID]?.[selectedIntegration.id],
              [ORG_CONFIGS.BILLING_CUSTOM_FIELDS]:
                orgConfigs[ADDITIONAL_ORG_CONFIGS.BILLING_CUSTOM_FIELDS_BY_INTEGRATION_ID]?.[selectedIntegration.id] ??
                {},
              [ORG_CONFIGS.BILLING_TRACKING_CATEGORIES]:
                orgConfigs[ADDITIONAL_ORG_CONFIGS.BILLING_TRACKING_CATEGORIES_BY_INTEGRATION_ID]?.[
                  selectedIntegration.id
                ] ?? {},
              [ORG_CONFIGS.INVOICE_TEMPLATE_ID]:
                orgConfigs[ADDITIONAL_ORG_CONFIGS.INVOICE_TEMPLATE_ID_BY_INTEGRATION_ID]?.[selectedIntegration.id],
            },
          }
        : null,
    [orgConfigs, selectedIntegration],
  );

  const handleFormSubmit = async (values) => {
    const { id: integrationId, configs, metadata, display_name } = values;

    if (
      !isEqual(
        { metadata, display_name },
        {
          metadata: selectedIntegration?.metadata,
          display_name: selectedIntegration?.display_name,
        },
      )
    ) {
      // only metadata and display_name can be modified here
      await updateIntegration.mutateAsync(
        { integrationId, data: { metadata, display_name }, skipIngestion: true },
        {
          onSuccess: () => {
            setIntegrations(
              integrations.map((integration) =>
                integration.id === integrationId
                  ? {
                      ...integration,
                      metadata,
                      display_name,
                    }
                  : integration,
              ),
            );
          },
        },
      );
    }

    await updateConfigs({ data: configs, integrationId });
  };

  return (
    <Formik initialValues={initialValues} enableReinitialize onSubmit={handleFormSubmit}>
      {({ values, setFieldValue, submitForm }) => (
        <Form>
          {isLoading ? (
            <div className="w-100 flexer">
              <Loader containerStyles={{ width: 40 }} />
            </div>
          ) : (
            <>
              <ConfigurationHeader
                activeTab={CONFIGURATION_TABS.SOURCES}
                selectedIntegration={selectedIntegration}
                onSave={submitForm}
              />
              <GeneralSettingTabContainer>
                {!!values && (
                  <CompactSection>
                    <GeneralSettings values={values} service={selectedService} />

                    <AdditionalSettings service={selectedService} integration={values} setFieldValue={setFieldValue} />

                    <IntegrationEntity service={selectedService} />

                    <BillingCustomFields service={selectedService} values={values} setFieldValue={setFieldValue} />

                    <BillingTrackingCategories
                      integration={selectedIntegration}
                      service={selectedService}
                      values={values}
                      setFieldValue={setFieldValue}
                    />
                  </CompactSection>
                )}

                {!!values && !organization.hasCostAttribution && values.metadata.byEntity === 'account' ? (
                  <TabBody
                    accounts={accounts}
                    selectedAccounts={{
                      sales: values.metadata?.sales,
                      cogs: values.metadata?.cogs,
                      marketing: values.metadata?.marketing,
                    }}
                    setFieldValue={setFieldValue}
                  />
                ) : null}

                <StyledIconButton
                  data-cy="integration__save-button"
                  style={{ display: 'flex' }}
                  filled
                  border
                  color={COLORS.GREEN}
                  icon={<SaveIcon />}
                  iconFillColor="#FFF"
                  onClick={submitForm}
                >
                  Save Settings
                </StyledIconButton>
              </GeneralSettingTabContainer>
            </>
          )}
        </Form>
      )}
    </Formik>
  );
};

export { GeneralLedgerTab };
