import dayjs from 'dayjs';
import { RECURRING_RECOGNITION_TYPES } from 'consts/global';
import { REVENUE_METRIC } from 'consts/revenueMetrics';
import { compareDate } from 'utils/dateUtils';
import { getDataForSnapshot } from 'views/Customers/CustomerSingle/utils';

export const METRIC_KEYS = {
  NEW: 'NEW',
  EXISTING: 'EXISTING',
  DOWNSELL: 'DOWNSELL',
  UPSELL: 'UPSELL',
  CHURN: 'CHURN',
  INFLUX: 'INFLUX',
};

// TODO: Add influx logic. After we do https://github.com/ChifferCo/chiffer/issues/1860
const segmentMetrics = ({ isARR, monthKey, monthMetrics, transactions_spreads, transactionsMap }) => {
  return Object.entries(transactions_spreads).reduce(
    (acc, [transactionId, spreads]) => {
      const currentAmount = spreads?.[monthKey] ?? 0;
      const previousAmount = spreads[dayjs.utc(monthKey).subtract(1, 'month').format('YYYY-MM')] ?? 0;
      const multiplier =
        isARR && RECURRING_RECOGNITION_TYPES.includes(transactionsMap?.[transactionId]?.recognition) ? 12 : 1;

      // If this month is NEW on customer level, then all transactions will be NEW for this customer
      if (monthMetrics[REVENUE_METRIC.NEW.key] > 0 && currentAmount > 0) {
        acc[METRIC_KEYS.NEW]?.transactions.push({
          id: transactionId,
          name: transactionsMap?.[transactionId]?.name,
          amount: currentAmount * multiplier,
        });
        // If this month is CHURN on customer level, then all transactions will be CHURN for this customer
      } else if (monthMetrics[REVENUE_METRIC.CHURN.key] > 0 && previousAmount > 0) {
        acc[METRIC_KEYS.CHURN]?.transactions.push({
          id: transactionId,
          name: transactionsMap?.[transactionId]?.name,
          amount: currentAmount * multiplier,
        });
      } else if (currentAmount || previousAmount) {
        if (currentAmount > previousAmount) {
          acc[METRIC_KEYS.UPSELL]?.transactions.push({
            id: transactionId,
            name: transactionsMap?.[transactionId]?.name,
            amount: currentAmount * multiplier,
          });
        } else if (currentAmount < previousAmount) {
          acc[METRIC_KEYS.DOWNSELL]?.transactions.push({
            id: transactionId,
            name: transactionsMap?.[transactionId]?.name,
            amount: previousAmount * multiplier,
          });
        } else if (currentAmount === previousAmount) {
          acc[METRIC_KEYS.EXISTING]?.transactions.push({
            id: transactionId,
            name: transactionsMap?.[transactionId]?.name,
            amount: currentAmount * multiplier,
          });
        }
      }

      return acc;
    },
    Object.keys(METRIC_KEYS).reduce((acc, metric) => {
      if (monthMetrics[REVENUE_METRIC[metric].key] > 0) {
        acc[metric] = {
          total: monthMetrics[REVENUE_METRIC[metric].key],
          transactions: [],
        };
      }
      return acc;
    }, {}),
  );
};

export const getHistoryData = ({ isARR, customer }) => {
  const transactionsMap = [
    ...(customer?.transactions?.nonRecurring ?? []),
    ...(customer?.transactions?.recurring ?? []),
  ].reduce((acc, transaction) => {
    acc[transaction.id] = {
      name: transaction.name,
      recognition: transaction.recognition,
    };
    return acc;
  }, {});

  // Using this, so we can reuse logic and make it more future-proof
  const customerWaterfallData = getDataForSnapshot({
    customer,
    isARR,
  });
  const allDates = Object.keys(customerWaterfallData).filter((date) => compareDate(date, new Date()) < 0);
  const monthsWithChanges = allDates.reduce((acc, dateKey, index) => {
    for (const metric of Object.keys(METRIC_KEYS).map((metric) => REVENUE_METRIC[metric].key)) {
      if (
        customerWaterfallData[dateKey][metric] &&
        customerWaterfallData[dateKey][metric] !== (customerWaterfallData[allDates[index - 1]]?.[metric] ?? 0)
      ) {
        acc[dateKey] = customerWaterfallData[dateKey];
        return acc;
      }
    }
    return acc;
  }, {});

  return Object.entries(monthsWithChanges).reduce((acc, [key, value]) => {
    acc.push({
      month: key,
      Total: value.Total,
      segmented: segmentMetrics({
        isARR,
        monthKey: key,
        monthMetrics: value,
        transactions_spreads: customer.transactions_spreads,
        transactionsMap,
      }),
    });
    return acc;
  }, []);
};
