import styled from 'styled-components';
import { ReactComponent as CircleCheck } from 'images/transaction_check.svg';

export const CheckboxIcon = styled(CircleCheck)`
  margin-right: 8px;
  height: 16px;
  width: 16px;

  rect {
    fill: var(--lightGreen);
  }
`;

export const CheckIcon = styled(CircleCheck)`
  margin-left: 10px;

  height: 20px;
  width: 20px;
`;

export const SubtitleText = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: var(--primaryBlack50);
`;

export const ShareLinkButton = styled.div`
  padding: 12px;
  background: var(--primaryBlue5);
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: var(--primaryBlue);
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;

  svg {
    margin-right: 10px;
  }

  &:hover {
    background: var(--primaryBlue10);
    color: var(--secondaryBlue);
  }
`;

export const BlockTitle = styled.div`
  display: flex;
  justify-content: space-between;
  background: var(--primaryGray);
  border-bottom: 1px solid var(--accentGraySecond);
  padding: 20px;
  font-size: 14px;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  line-height: 20px;
`;

export const LinkWithButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  padding-left: 20px;

  a {
    color: var(--primaryBlue);

    &:hover {
      color: var(--secondaryBlue);
    }
  }
`;

export const LinkBlock = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid var(--accentGraySecond);
  box-shadow: 8px 8px 40px var(--primaryBlack2);
  border-radius: 12px;
  margin-bottom: 32px;
`;

export const FooterSubtitle = styled.div`
  font-size: 12px;
  line-height: 16px;
  opacity: 0.5;
  margin-top: 4px;
`;

export const SubtitleWrapper = styled.div`
  display: flex;
  margin-top: 16px;
  margin-bottom: 28px;

  p {
    margin-top: 8px;
    margin-bottom: 0;

    b {
      font-weight: 700;
      font-style: italic;
    }
  }

  svg {
    margin-right: 8px;
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;

    path {
      fill: var(--primaryBlack30);
    }
  }
`;

export const CheckboxLabel = styled.div`
  font-size: 14px;
  line-height: 18px;

  color: var(--primaryBlack);

  margin: 0 10px;
`;

export const MembersLink = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: var(--primaryBlue);
`;
