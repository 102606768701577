import { useContext, useEffect, useState } from 'react';
import { AppContext } from 'AppContext';
import { useTransactionsAPI } from 'api/transactions';
import { useToasts } from 'components/Toasts';
import { DEFAULT_PAGE_SIZE } from 'shared/TransactionTable';
import { InvoicingScheduleChangesContainer } from 'shared/InvoicingScheduleChanges';
import { TransactionsConfirmInvoicesModal } from './TransactionsConfirmInvoicesModal';

export const BulkDeleteInvoiceChangesModal = ({ deletedTransactionIds, onClose }) => {
  const { orgId } = useContext(AppContext);
  const { pushToast } = useToasts();

  const [deletedTransactionsScheduleIds, setDeletedTransactionsScheduleIds] = useState(null);
  const [currentScheduleIndex, setCurrentScheduleIndex] = useState(0);

  const { data: deletedTransactions } = useTransactionsAPI({
    orgId,
    filters: {
      limit: DEFAULT_PAGE_SIZE,
      params: { archived: true },
      body: { transactionIds: deletedTransactionIds },
    },
    autoFetch: deletedTransactionIds?.length > 0,
  });

  useEffect(() => {
    if (deletedTransactions?.length > 0) {
      const deletedTransactionsByScheduleId = deletedTransactions?.reduce((acc, transaction) => {
        if (transaction.invoicing_schedule_id !== null) {
          if (!acc[transaction.invoicing_schedule_id]) {
            acc[transaction.invoicing_schedule_id] = [];
          }
          acc[transaction.invoicing_schedule_id].push(transaction);
        }
        return acc;
      }, {});
      setDeletedTransactionsScheduleIds(deletedTransactionsByScheduleId);
    }

    return () => setDeletedTransactionsScheduleIds(null);
  }, [deletedTransactions]);

  if (!deletedTransactionsScheduleIds || Object.keys(deletedTransactionsScheduleIds).length === 0) {
    return null;
  }

  const handleCloseModal = () => {
    if (currentScheduleIndex + 1 === Object.keys(deletedTransactionsScheduleIds).length) {
      onClose();
      pushToast('Successfully updated invoicing schedules!', 'success');
    } else {
      setCurrentScheduleIndex(currentScheduleIndex + 1);
    }
  };

  return (
    <InvoicingScheduleChangesContainer>
      <TransactionsConfirmInvoicesModal
        key={currentScheduleIndex}
        orgId={orgId}
        invoicingScheduleId={Object.keys(deletedTransactionsScheduleIds)[currentScheduleIndex]}
        deletedTransactions={
          deletedTransactionsScheduleIds[Object.keys(deletedTransactionsScheduleIds)[currentScheduleIndex]]
        }
        currentIndex={currentScheduleIndex}
        totalCount={Object.keys(deletedTransactionsScheduleIds).length}
        onClose={handleCloseModal}
      />
    </InvoicingScheduleChangesContainer>
  );
};
