import React, { useContext, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { FieldArray, Form, Formik } from 'formik';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { AppContext } from 'AppContext';
import { useExchangeRatesAPI } from 'api/exchangeRates';
import { useTransactionCurrenciesAPI } from 'api/currency';
import { useStateWithStorage } from 'utils/hooks';
import { CONFIGURATION_TABS } from 'views/Configuration/consts';
import { Loader } from 'components/Loaders';
import { COLORS } from 'consts/colors';
import { FilePlusIcon, GreyDot, SaveIcon, ToolsIcon } from 'components/Icons';
import { ReactComponent as TableExportIcon } from 'images/table-export.svg';
import { IconButton } from 'components/Buttons';
import { ExchangeRatesTable } from './ExchangeRatesTable/ExchangeRatesTable';
import {
  getCurrenciesKeys,
  getExchangeRatesForCreate,
  getFromAndToCurrenciesFromKey,
  getRatesDataForTable,
  getRowWithOnlyCurrenciesKeys,
} from './ExchangeRatesTable/utils';
import { StyledIconButton } from '../styles';

const Container = styled.div`
  position: relative;
  margin-top: 20px;
`;

const Buttons = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  top: -73px;
`;

export const ExchangeRatesTab = () => {
  const history = useHistory();
  const {
    orgId,
    appSettings: { currencyISOCode: homeCurrency },
  } = useContext(AppContext);

  const formRef = useRef();

  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [convertToCurrency, setConvertToCurrency] = useState(homeCurrency);
  const [selectedIntegration, setSelectedIntegration] = useState(null);

  const {
    data,
    isLoading,
    isFetching,
    operations: { bulkCreateExchangeRates, bulkDeleteExchangeRates },
  } = useExchangeRatesAPI({ orgId, filters: { scopes: ['include_default_rates'], convertToCurrency } });

  const [storageSortBy, setStorageSortBy] = useStateWithStorage('exchange-rates-page-sort-by', [
    {
      id: 'date',
      desc: true,
    },
  ]);
  const dataForTable = getRatesDataForTable({ data }) ?? [];
  const latestDate = dataForTable.reduce((latest, { date }) => {
    return date > latest ? date : latest;
  }, data?.[0]?.date ?? dayjs().subtract(1, 'day').toDate());

  const { data: transactionCurrencies } = useTransactionCurrenciesAPI({ orgId });

  const currenciesKeys = getCurrenciesKeys({ currencies: transactionCurrencies, homeCurrency: convertToCurrency });

  const handleRatesSubmit = async (values) => {
    const ratesForCreate = getExchangeRatesForCreate({ values, orgId });
    await bulkCreateExchangeRates({ data: ratesForCreate });
  };

  const onRowRemove = async ({ row }) => {
    const metricsColumns = getRowWithOnlyCurrenciesKeys({ row: row?.original });

    const metricsForRemove = [];

    Object.entries(metricsColumns ?? {})?.forEach(([key, rate]) => {
      const { to_currency, from_currency } = getFromAndToCurrenciesFromKey({ key });

      if (transactionCurrencies?.includes(from_currency)) {
        metricsForRemove.push({ to_currency, from_currency, rate, date: row?.original?.date, organization_id: orgId });
      }
    });

    bulkDeleteExchangeRates({ data: metricsForRemove });
  };

  return (
    <>
      {isLoading || isFetching ? (
        <div className="w-100 flexer">
          <Loader containerStyles={{ width: 40 }} />
        </div>
      ) : (
        <Formik
          enableReinitialize={true}
          onSubmit={handleRatesSubmit}
          innerRef={formRef}
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={{ rates: dataForTable }}
        >
          {({ submitForm, values }) => {
            return (
              <>
                <Form>
                  <FieldArray name="rates">
                    {({ push }) => (
                      <Container>
                        <Buttons>
                          <IconButton
                            icon={<ToolsIcon />}
                            iconFillColor="var(--primaryGreen)"
                            onClick={() => history.push(`/configure?tab=${CONFIGURATION_TABS.GENERAL_SETTINGS}`)}
                          >
                            Currency Settings
                          </IconButton>

                          <GreyDot spacing="12px" />

                          <IconButton
                            icon={<FilePlusIcon />}
                            iconFillColor="var(--primaryGreen)"
                            data-cy="exchange-rates__add-new"
                            onClick={() =>
                              push({
                                date: dayjs.utc(latestDate).add(1, 'day').format('YYYY-MM-DD'),
                                rowIndex: values?.rates?.length,
                              })
                            }
                          >
                            Add New
                          </IconButton>

                          <IconButton
                            style={{ marginLeft: 8 }}
                            filled={true}
                            color={COLORS.GREEN}
                            icon={<TableExportIcon />}
                            iconFillColor="white"
                            data-cy="exchange-rates__upload-csv"
                            onClick={() => history.push('/csv/rates')}
                          >
                            Import CSV
                          </IconButton>
                        </Buttons>
                        <ExchangeRatesTable
                          onRowRemove={onRowRemove}
                          homeCurrency={homeCurrency}
                          storageSortBy={storageSortBy}
                          setStorageSortBy={setStorageSortBy}
                          currenciesKeys={currenciesKeys}
                          formRef={formRef}
                          currentPageIndex={currentPageIndex}
                          setCurrentPageIndex={setCurrentPageIndex}
                          setConvertToCurrency={setConvertToCurrency}
                          selectedIntegration={selectedIntegration}
                          setSelectedIntegration={setSelectedIntegration}
                        />
                      </Container>
                    )}
                  </FieldArray>
                </Form>

                <StyledIconButton
                  data-cy="exchange-rates__save-button"
                  style={{ display: 'flex' }}
                  filled
                  border
                  color={COLORS.GREEN}
                  icon={<SaveIcon />}
                  iconFillColor={'#FFF'}
                  onClick={submitForm}
                >
                  Save Rates
                </StyledIconButton>
              </>
            );
          }}
        </Formik>
      )}
    </>
  );
};
