import React from 'react';
import styled from 'styled-components';
import { ReactComponent as WarningIcon } from 'images/warning-icon.svg';

const WarningIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 17px 20px;
  border-right: ${({ showSplitter }) => (showSplitter ? '2px solid rgb(244, 227, 227)' : undefined)};
`;

const ErrorContainer = styled.div`
  background: rgba(255, 93, 93, 0.1);
  width: 100%;
  margin-top: 15px;
  border-radius: 12px;
  height: ${(props) => props.height}px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
`;

const StyledWarningIcon = styled(WarningIcon)`
  width: ${({ size }) => size ?? undefined};
  height: ${({ size }) => size ?? undefined};
`;

export const ErrorBar = ({ height, children, styles, iconSize, showSplitter = true }) => (
  <ErrorContainer height={height} style={styles}>
    <WarningIconWrapper showSplitter={showSplitter}>
      <StyledWarningIcon size={iconSize} />
    </WarningIconWrapper>
    {children}
  </ErrorContainer>
);
