import styled from 'styled-components';
import { ReactComponent as ArrowLeft } from 'images/arrow-narrow-left.svg';
import { ReactComponent as ArrowRight } from 'images/arrow-narrow-right.svg';
import { Centerer } from 'components/Core';

export const PaginationWrapper = styled(Centerer)`
  justify-content: space-between;
  margin-top: 12px;
  padding: 0 20px;

  font-weight: 900;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  color: var(--primaryBlack30);
`;

export const GoalsPill = styled(Centerer)`
  padding: 4px 12px;
  height: 28px;
  background: var(--primaryBlack2);
  border-radius: 100px;
`;

export const PageSelector = styled(Centerer)`
  gap: 16px;
`;

export const StyledArrowLeft = styled(ArrowLeft)`
  width: 16px;
  height: 16px;

  path {
    fill: var(--primaryBlue);
  }
`;

export const StyledArrowRight = styled(ArrowRight)`
  width: 16px;
  height: 16px;

  g {
    opacity: 1;

    path {
      fill: var(--primaryBlue);
    }
  }
`;

export const IconCircle = styled(Centerer)`
  width: 28px;
  height: 28px;

  background: var(--primaryBlue10);
  border-radius: 100px;
  cursor: pointer;

  &:hover {
    background: var(--primaryBlue20);

    path {
      fill: var(--secondaryBlue);
    }
  }
`;

export const PaginationSection = ({ goalsCount, selectedPage, setSelectedPage }) => {
  const pagesCount = Math.ceil(goalsCount / 3);
  const handleClickLeft = () => {
    if (selectedPage === 0) {
      setSelectedPage(pagesCount - 1);
    } else {
      setSelectedPage(selectedPage - 1);
    }
  };

  const handleClickRight = () => {
    if (selectedPage === pagesCount - 1) {
      setSelectedPage(0);
    } else {
      setSelectedPage(selectedPage + 1);
    }
  };

  return (
    <PaginationWrapper>
      <GoalsPill>{goalsCount} Goals</GoalsPill>
      <PageSelector>
        <IconCircle onClick={handleClickLeft}>
          <StyledArrowLeft />
        </IconCircle>
        <div>
          {selectedPage + 1}/{pagesCount}
        </div>
        <IconCircle onClick={handleClickRight}>
          <StyledArrowRight />
        </IconCircle>
      </PageSelector>
      <div></div>
    </PaginationWrapper>
  );
};
