import React, { Fragment, useContext, useMemo } from 'react';
import styled from 'styled-components';
import { useTable, useExpanded, useSortBy } from 'react-table';
import { AppContext } from 'AppContext';
import { useBillingInconsistenciesAPI } from 'api/billing';
import { BillingProvider } from 'views/Billing/BillingProvider';
import { ReactComponent as TotalIcon } from 'images/sort-descending.svg';
import { Centerer } from 'components/Core';
import { SizedLoader } from 'components/Loaders';
import {
  ReactTableBody,
  ReactTableHeader,
  ReactTableHeaderColumn,
  ReactTableHeaderRow,
  ReactTableCell,
  ReactTableRow,
  HeaderCellWrapper,
  SortIconWrapper,
} from 'components/Table';
import { InvoicesTable } from './InvoicesTable';
import { CreditNotesTable } from './CreditNotesTable';
import { getDataForTable } from './getDataForTable';
import { getColumns } from './columns';

const LoaderContainer = styled(Centerer)`
  height: 400px;
`;

const TableContainer = styled.div`
  width: 100%;
  overflow: scroll;
  padding: 12px 24px;
  margin-bottom: 24px;
`;

export const BillingInconsistenciesReportTable = () => {
  const {
    orgId,
    formatDateWithLocale,
    orgConfigs: { accountingCurrency },
  } = useContext(AppContext);

  const {
    data,
    isLoading,
    isFetching,
    operations: { dismissTransactionFromBillingInconsistencies },
  } = useBillingInconsistenciesAPI({
    orgId,
  });

  const dataForTable = useMemo(() => getDataForTable({ data, accountingCurrency }), [data, accountingCurrency]);

  const columns = useMemo(() => {
    const handleDismissTransaction = (transactionId) => async () => {
      await dismissTransactionFromBillingInconsistencies.mutateAsync({ transactionId });
    };

    return getColumns({
      formatDateWithLocale,
      handleDismissTransaction,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formatDateWithLocale]);

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable(
    {
      columns,
      data: dataForTable,
    },
    useSortBy,
    useExpanded,
  );

  if (isLoading || isFetching) {
    return (
      <LoaderContainer>
        <SizedLoader size={40} />
      </LoaderContainer>
    );
  }

  return (
    <BillingProvider>
      <TableContainer {...getTableProps()}>
        <ReactTableHeader>
          {headerGroups.map((headerGroup) => (
            <ReactTableHeaderRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <ReactTableHeaderColumn
                  customWidth={column.width}
                  alignRight={column.alignRight}
                  {...column.getHeaderProps(column.getSortByToggleProps({ title: undefined }))}
                >
                  <HeaderCellWrapper isSortable={column.canSort && !column.disableSortBy} isSorted={column.isSorted}>
                    {column.render('Header')}
                    {column.isSorted && (
                      <SortIconWrapper isSortedDesc={column.isSortedDesc}>
                        <TotalIcon />
                      </SortIconWrapper>
                    )}
                  </HeaderCellWrapper>
                </ReactTableHeaderColumn>
              ))}
            </ReactTableHeaderRow>
          ))}
        </ReactTableHeader>
        {rows.length ? (
          <ReactTableBody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <Fragment>
                  <ReactTableRow {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <ReactTableCell
                        {...cell.getCellProps()}
                        customWidth={cell.column.width}
                        alignRight={cell.column.alignRight}
                      >
                        {cell.render('Cell')}
                      </ReactTableCell>
                    ))}
                  </ReactTableRow>
                  {row.isExpanded && (
                    <Fragment>
                      <InvoicesTable
                        invoiceItems={row.original.invoiceItems}
                        invoicingScheduleId={row.original.invoicingScheduleId}
                        accountingCurrency={row.original.transactionCurrency}
                      />
                      {row.original.creditNoteItems.length > 0 && (
                        <CreditNotesTable
                          creditNoteItems={row.original.creditNoteItems}
                          invoicingScheduleId={row.original.invoicingScheduleId}
                          accountingCurrency={row.original.transactionCurrency}
                        />
                      )}
                    </Fragment>
                  )}
                </Fragment>
              );
            })}
          </ReactTableBody>
        ) : null}
      </TableContainer>
    </BillingProvider>
  );
};
