import { isEmpty } from 'lodash';
import { Spacer } from 'components/Core';
import { PipelineStagesSummary } from './PipelineStagesSummary';
import { PipelineCustomersTable } from './PipelineCustomersTable';
import { NewPipelineTable } from './NewPipelineTable';
import { ForecastModalBody } from '../Shared/ForecastModalLayout';

export const ForecastNewBody = ({ scenarioId, data, month, editForecastDetails }) => {
  const monthData = data?.customers_new ?? {};

  return (
    <>
      {!isEmpty(monthData?.stages) && (
        <PipelineStagesSummary month={month} stages={monthData?.stages} newPipeline={monthData?.new_pipeline} />
      )}
      <ForecastModalBody>
        <PipelineCustomersTable
          forecastId={scenarioId}
          data={monthData}
          month={month}
          editForecastDetails={editForecastDetails}
        />

        <Spacer height="24px" />

        <NewPipelineTable
          forecastId={scenarioId}
          data={monthData?.new_pipeline}
          month={month}
          editForecastDetails={editForecastDetails}
        />
      </ForecastModalBody>
    </>
  );
};
