import { useToasts } from 'components/Toasts';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { getProfileByEmail, getUsers, deleteUser, inviteUser as _inviteUser } from './requests';

const CACHE_KEY = 'users';

export const useUsersAPI = ({ orgId, autoFetch = true, enableToasts = true }) => {
  const { pushToast: wrappedPushToast, pushError: wrappedPushError } = useToasts();
  const pushToast = (...args) => {
    if (enableToasts) wrappedPushToast(...args);
  };
  const pushError = (...args) => {
    if (enableToasts) wrappedPushError(...args);
  };
  const queryClient = useQueryClient();

  const { data, error, isLoading, isFetching } = useQuery([CACHE_KEY, orgId], () => getUsers({ orgId }), {
    enabled: autoFetch,
  });

  const inviteUser = useMutation(({ data }) => _inviteUser({ orgId, body: data }), {
    onSuccess: () => {
      queryClient.invalidateQueries(CACHE_KEY);
      pushToast('Sent invite successfully!');
    },
    onError: (err) => {
      pushError(err, 'Error creating user');
    },
  });

  const removeUser = useMutation(({ userId }) => deleteUser({ orgId, userId }), {
    onSuccess: () => {
      queryClient.invalidateQueries(CACHE_KEY);
      pushToast('Removed user successfully!');
    },
    onError: (err) => {
      pushError(err, 'Error removing user');
    },
  });

  return {
    data,
    error,
    isLoading,
    isFetching,
    operations: {
      inviteUser,
      removeUser,
    },
  };
};

export const useUserAPI = ({ email, autoFetch = true }) => {
  const { data, error, isLoading, isFetching } = useQuery([CACHE_KEY, email], () => getProfileByEmail({ email }), {
    enabled: autoFetch && !!email,
    staleTime: Infinity,
  });

  return {
    data,
    error,
    isLoading,
    isFetching,
  };
};
