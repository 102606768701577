import styled from 'styled-components';
import { ReactComponent as GoalsEmptyStateButtonIllustration } from 'images/goals-empty-state-button-illustration.svg';
import { ReactComponent as GoalsEmptyStateChartIllustration } from 'images/goals-empty-state-chart-illustration.svg';
import { Flexer } from 'components/Core';
import { WrapperCard } from './styles';

const ChartWrapper = styled(Flexer)`
  align-items: end;
  height: 106px;

  svg {
    opacity: 0.5;
  }
`;

const EmptyWrapperCard = styled(WrapperCard)`
  justify-content: space-between;
  height: 108px;
  min-height: 108px;
  padding: 0;
  background: #ffffff;
  cursor: pointer;

  &:hover {
    ${ChartWrapper} {
      svg {
        opacity: 1;
      }
    }
  }
`;

const Wrapper = styled(Flexer)`
  flex-direction: row;
  align-items: center;
  padding: 12px;
  gap: 32px;
`;

const Contents = styled(Flexer)`
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;

const MainText = styled.div`
  font-weight: 900;
  font-size: 16px;
  line-height: 22px;
  color: var(--primaryBlack);
`;

const DescriptionText = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--primaryBlack);

  span {
    color: var(--primaryBlue);
  }
`;

export const EmptyBanner = (props) => (
  <EmptyWrapperCard {...props}>
    <Wrapper>
      <GoalsEmptyStateButtonIllustration />
      <Contents>
        <MainText>Start tracking a new goal</MainText>
        <DescriptionText>
          <span>Create a goal</span> for any metric to track your progress.
        </DescriptionText>
      </Contents>
    </Wrapper>
    <ChartWrapper>
      <GoalsEmptyStateChartIllustration />
    </ChartWrapper>
  </EmptyWrapperCard>
);
