import { USAGE_BASED_BILLING_SCHEMES } from './consts';

export const isTieredPlan = ({ tiers }) => {
  const isPerUnitPlan =
    !tiers.length ||
    (tiers.length === 1 &&
      tiers[0].billing_scheme === USAGE_BASED_BILLING_SCHEMES.PER_UNIT &&
      (tiers[0].maximum_units === null || tiers[0].maximum_units === undefined));

  return !isPerUnitPlan;
};
