import React, { useContext } from 'react';
import { GlobalTogglesContext } from 'shared/GlobalToggles';
import { SettingsContainer } from '../styles';
import RevenueTypePopover from './RevenueTypePopover';

//draftDataFilter, updateDraftDataFilter props come from the Filters component
export const RevenueTypeFilter = ({ draftDataFilter = {}, updateDraftDataFilter }) => {
  const { draftGlobalToggles } = useContext(GlobalTogglesContext);
  const { resolution, revenueTypes } = draftDataFilter;

  const selectRevenueTypes = (revenueTypes) => updateDraftDataFilter({ revenueTypes });

  return (
    <SettingsContainer style={{ marginLeft: 8 }}>
      <RevenueTypePopover
        isARR={draftGlobalToggles.isARR}
        currentSelections={revenueTypes}
        selectRevenueTypes={selectRevenueTypes}
        resolution={resolution}
      />
    </SettingsContainer>
  );
};
