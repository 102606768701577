import styled from 'styled-components';
import { ReactComponent as CircleXIcon } from 'images/circle-x.svg';
import { ReactComponent as EditCircle } from 'images/edit-circle.svg';
import { Centerer } from 'components/Core';

export const SectionTitle = styled(Centerer)`
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: var(--primaryBlack);
  margin: 0 12px;
`;

export const SectionIcon = styled(Centerer)`
  width: 32px;
  height: 32px;
  padding: 6px;
  border: 1px solid var(--accentGraySecond);
  border-radius: 8px;

  svg {
    g {
      opacity: 1;

      path {
        fill: var(--primaryBlack);
      }
    }
  }
`;

export const ItemsRow = styled.div`
  display: flex;
  margin-bottom: 16px;

  > * {
    width: 100%;
    min-width: 120px;
    margin-right: 20px;
    white-space: nowrap;

    &:last-child {
      margin-right: 0;
    }
  }
`;

export const StyledCircleXIcon = styled(CircleXIcon)`
  cursor: pointer;
  margin-right: 8px;

  &:hover {
    path {
      fill: var(--secondaryRed);
    }
  }
`;

export const StyledEditCircle = styled(EditCircle)`
  cursor: pointer;

  &:hover {
    path {
      fill: var(--secondaryBlue);
    }
  }
`;
