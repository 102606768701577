import * as Sentry from '@sentry/react';

export const bypassError = (func, options = {}) => {
  try {
    func();
  } catch (err) {
    if (process.env.NODE_ENV === 'development') {
      throw err;
    } else if (!options.skipSentry) {
      Sentry.captureMessage(err, 'error');
    }
  }
};
