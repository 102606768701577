import React from 'react';
import styled from 'styled-components';
import { usePortal } from 'components/Portal';
import { ReactComponent as AlertIcon } from 'images/alert-triangle.svg';
import { ReactComponent as ContentImage } from 'images/rollup-explain-modal-img.svg';
import { useClickOutside } from 'utils/hooks';
import { Modal, ModalBody, ModalContainer, ModalHeader, ModalTitle, ModalCloseIcon } from 'components/Modal';

const Dot = styled.div`
  flex: none;
  width: 8px;
  height: 8px;
  margin: 5px;
  border: 2px solid var(--primaryBlack30);
  border-radius: 50%;
`;

const DetailsRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0;
  gap: 8px;
  margin-bottom: 12px;
`;

const Warning = styled.div`
  display: flex;
  margin: 20px 0;
  align-items: flex-start;
  color: var(--primaryBlack50);
`;

const IconWrapper = styled.div`
  background: #ffab2d;
  box-shadow: 0px 4px 10px rgba(255, 171, 45, 0.2);
  border-radius: 8px;
  padding: 4px;
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;

  svg path {
    fill: white;
  }
`;

const DetailsText = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`;

const BottomContent = styled.div`
  margin-top: 20px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  line-height: 20px;
  border-top: 1px solid var(--accentGraySecond);
  box-shadow: inset -10px 0px 80px rgba(0, 0, 0, 0.06);
`;

const CustomModalTitle = styled(ModalTitle)`
  font-weight: 900;
  font-size: 20px;
  line-height: 26px;
  padding-left: 36px;
`;

export const RollupExplainModal = ({ onClose }) => {
  const modalRef = useClickOutside(onClose);
  const { Portal } = usePortal({ fixed: true, instantVisible: true });

  return (
    <Portal>
      <ModalContainer>
        <Modal ref={modalRef} width="540px" height="auto" overflow="visible">
          <ModalHeader>
            <CustomModalTitle>Roll up by parent</CustomModalTitle>
            <ModalCloseIcon onClose={onClose} />
          </ModalHeader>
          <ModalBody paddingLeft="0" paddingRight="0">
            <div style={{ padding: '0 36px' }}>
              <Warning>
                <IconWrapper>
                  <AlertIcon />
                </IconWrapper>
                In this mode, please keep in mind that there are the following specifics:
              </Warning>

              <DetailsRow>
                <Dot />
                <DetailsText>
                  Please note that non-overlapping transactions from customers in the same hierarchy may cause rolled up
                  committed revenue to differ from non-rolled up committed revenue,
                </DetailsText>
              </DetailsRow>

              <DetailsRow>
                <Dot />
                <DetailsText>
                  When renewal deadlines are used, and transactions of different customers in the same hierarchy overlap
                  with each other's renewal deadlines, this may cause rolled up revenue (either classical or committed)
                  to differ from non-rolled up revenue.
                </DetailsText>
              </DetailsRow>

              <DetailsRow>
                <Dot />
                <DetailsText>
                  Number of customer may be lower in rollup mode because only the root customers of each hierarchy are
                  counted.
                </DetailsText>
              </DetailsRow>
            </div>

            <BottomContent>
              <ContentImage />
            </BottomContent>
          </ModalBody>
        </Modal>
      </ModalContainer>
    </Portal>
  );
};
