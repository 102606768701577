import styled from 'styled-components';
import { isNil } from 'lodash';
import { FlexEndContainer } from 'components/Core';
import { TooltipContainer } from 'components/Tooltip';
import { ComparisonTooltipContent, ValuesComparison } from 'shared/Common';
import { getPreviousDateKey } from 'utils/dateUtils';
import { useCurrencyNumberFormatter } from 'utils/hooks';
import { WATERFALL_TYPE_OPTIONS } from 'shared/Filters/WaterfallTypeFilter/WaterfallTypeFilter';
import { GoalsValueContainer } from './Goals/GoalsValueContainer';
import { getIsSummaryColumn } from './utils';

const PairedValuesContainer = styled(FlexEndContainer)`
  flex-direction: column;
  width: 100%;
  align-items: flex-end;

  &:hover {
    svg g {
      opacity: 1;
    }
  }
`;

const RowValueStyledLink = styled.span`
  ${({ hasValue }) => (hasValue ? `cursor: pointer;` : '')}
`;

const RowContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0px 4px 0px 4px;

  ${({ hasValue }) =>
    hasValue
      ? `&:hover {
      background: var(--primaryBlue10);
      transition: all 0.3s;
      border-radius: 4px;
    }`
      : ''}
`;

export const RowValueDisplay = ({
  dataType,
  decimalPlaces,
  value,
  title,
  hasDrilldown,
  handleClick,
  goalData,
  periods,
  previousValue,
  date,
  addSign,
  waterfallType,
}) => {
  const isComparisonTooltip = !isNil(previousValue) && !isNil(value);
  const isNegativeSeatToolTip = !isNil(waterfallType) && waterfallType === WATERFALL_TYPE_OPTIONS.seats && value < 0;
  let toolTipContent;
  if (isComparisonTooltip) {
    toolTipContent = (
      <ComparisonTooltipContent
        originalValue={value}
        compareValue={previousValue}
        originalLabel={date}
        compareLabel={getPreviousDateKey(date)}
        dataType={dataType}
        decimalPlaces={decimalPlaces}
      />
    );
  } else if (isNegativeSeatToolTip) {
    toolTipContent = `The customer downsold, but they ended up adding more "cheaper" seats than they had before`;
  }

  const numberFormatter = useCurrencyNumberFormatter();

  return (
    <PairedValuesContainer>
      <TooltipContainer
        isVisible={isComparisonTooltip || isNegativeSeatToolTip}
        width={220}
        toolTipContent={toolTipContent}
      >
        <RowContainer
          className={`data-table__row-container ${hasDrilldown ? 'has-drilldown' : ''}`}
          hasValue={hasDrilldown}
        >
          <RowValueStyledLink
            onClick={hasDrilldown ? handleClick : null}
            hasValue={hasDrilldown}
            data-cy={`data-table__row--${title?.replace(/ /g, '') ?? ''}${
              getIsSummaryColumn({ date }) ? `--${date}` : ''
            }`}
          >
            {value === undefined
              ? ''
              : numberFormatter({ type: dataType, rawValue: value, decimalPlaces: decimalPlaces, addSign })}
          </RowValueStyledLink>
          {previousValue || value ? (
            <ValuesComparison
              dataType={dataType}
              originalValue={value}
              compareValue={previousValue}
              percentageDifferenceToShowValueComparison={0}
            />
          ) : null}
        </RowContainer>
      </TooltipContainer>

      {goalData &&
        periods?.map((period, index) => {
          const tooltipContent = (
            <ComparisonTooltipContent
              originalValue={value}
              compareValue={period?.value}
              originalLabel="Actual value"
              compareLabel="Goal value"
              dataType={dataType}
              decimalPlaces={decimalPlaces}
            />
          );
          return (
            <TooltipContainer key={index} isVisible={true} width={220} toolTipContent={tooltipContent}>
              <GoalsValueContainer
                dataType={dataType}
                decimalPlaces={decimalPlaces}
                actualValue={value}
                value={period?.value}
              />
            </TooltipContainer>
          );
        })}
    </PairedValuesContainer>
  );
};
