import React, { useContext, useState } from 'react';

import { getCustomerDisplayName } from 'models/customer';
import { ReactComponent as DeleteIcon } from 'images/trash.svg';
import { CUSTOMERS_MODAL_ACTIONS, CustomersActionsModal } from 'views/Customers/CustomersActionsModal';
import { Column, FlexerRow } from 'components/Core';
import { TooltipContainer } from 'components/Tooltip';

import { InvoicingScheduleContext } from '../../InvoicingScheduleContext';
import { InvoicingScheduleDeleteModal } from '../../InvoicingScheduleDeleteModal';
import { CustomerExternalSelector } from '../../../Common/CustomerExternalSelector';
import { MANUAL_GL_SERVICE_NAME } from '../../../consts';
import { InvoicingScheduleCustomerSelector } from '../../InvoicingScheduleCustomerSelector';
import { DeleteIconWrapper, Wrapper, Heading, SelectorWrapper, PaddingWrapper, LinksWrapper } from './style';
import { SubscriptLink } from './SubscriptLink';

export const InvoicingScheduleHeader = () => {
  const {
    invoicingScheduleFormValues,
    showDeleteModal,
    setShowDeleteModal,
    currentInvoicingSchedule,
    fetchedSelectedInvoice: invoice,
    invoicingService,
    refetchCustomer,
    invoicingServiceDisplayName,
  } = useContext(InvoicingScheduleContext);

  const [showCustomersActionModal, setShowCustomersActionModal] = useState(false);

  return (
    <Wrapper>
      <Column horizontal="start" vertical="start">
        <Heading>
          <FlexerRow alignItems="center">
            Schedule:
            <SelectorWrapper
              data-cy="invoicing-schedule-customer-selector"
              hasValue={invoicingScheduleFormValues?.customer_id}
              onClick={() => setShowCustomersActionModal(true)}
            >
              <InvoicingScheduleCustomerSelector
                label=""
                redVer
                value={
                  invoicingScheduleFormValues?.customer_id
                    ? {
                        label: getCustomerDisplayName({
                          customerName: invoicingScheduleFormValues?.customer_name,
                          customerId: invoicingScheduleFormValues?.customer_id,
                          customerEmail: invoicingScheduleFormValues?.customer_email,
                        }),
                        value: invoicingScheduleFormValues?.customer_id,
                      }
                    : null
                }
              />
            </SelectorWrapper>
          </FlexerRow>

          {currentInvoicingSchedule?.id && (
            <TooltipContainer
              width={200}
              fontSize="12px"
              toolTipContent="Delete the schedule and all its invoices"
              hideArrow
            >
              <DeleteIconWrapper data-cy="invoicing-schedule__remove-schedule" onClick={() => setShowDeleteModal(true)}>
                <DeleteIcon width={20} height={20} />
              </DeleteIconWrapper>
            </TooltipContainer>
          )}
        </Heading>

        <PaddingWrapper style={{ overflow: 'auto', maxHeight: '120px' }}>
          <LinksWrapper>
            <SubscriptLink />
            {invoicingScheduleFormValues?.customer_id && invoicingService !== MANUAL_GL_SERVICE_NAME && (
              <CustomerExternalSelector
                customerId={invoicingScheduleFormValues?.customer_id}
                invoice={invoice}
                invoicingService={invoicingService}
                invoicingServiceDisplayName={invoicingServiceDisplayName}
                integrationId={invoicingScheduleFormValues?.integration_id}
              />
            )}
          </LinksWrapper>
        </PaddingWrapper>
      </Column>

      {showDeleteModal && <InvoicingScheduleDeleteModal />}

      {showCustomersActionModal && (
        <CustomersActionsModal
          closeModal={async () => {
            setShowCustomersActionModal(false);
            await refetchCustomer();
          }}
          modalAction={CUSTOMERS_MODAL_ACTIONS.EDIT}
          customer={{ id: invoicingScheduleFormValues?.customer_id }}
        />
      )}
    </Wrapper>
  );
};
