import { MAGIC_METADATA, NUMBER_FORMATS, SEGMENT_BY_OPTIONS } from 'consts/global';
import { isNil } from 'lodash';
import { humanize } from 'utils/stringUtils';
import {
  METADATA_SEGMENT_BUCKET_TYPES,
  METADATA_SEGMENT_OBJECT_TYPES,
  CUSTOMER_TIMESERIES_METRICS_SUFFIX,
} from 'shared/Filters/MetadataFilter/consts';
import { numberFormatter } from 'utils/formatters';

export const formatMetadataSegments = ({ rawMetadataSegments, objectType }) => {
  if (!rawMetadataSegments) return [];

  const result = (Array.isArray(rawMetadataSegments) ? rawMetadataSegments : [rawMetadataSegments]).map((segment) => {
    return {
      key: segment.key,
      value: segment.levels.reduce(
        (acc, level) => {
          switch (segment.type) {
            case METADATA_SEGMENT_BUCKET_TYPES.DISCRETE:
              acc[level.name] = level.items;
              break;
            case METADATA_SEGMENT_BUCKET_TYPES.CONTINUOUS:
              if (!acc['$bucketNames']) acc['$bucketNames'] = [];
              if (!acc['$bucketThresholds']) acc['$bucketThresholds'] = [];
              acc['$bucketNames'].push(level.bucketName);
              if (!isNil(level.bucketThreshold)) acc['$bucketThresholds'].push(parseFloat(level.bucketThreshold));
              break;
            default:
              break;
          }
          return acc;
        },
        segment.type === METADATA_SEGMENT_BUCKET_TYPES.CONTINUOUS ? { $useZeroBucket: segment.useZeroBucket } : {},
      ),
      bucketType: segment.type,
      objectType,
    };
  });

  if (Array.isArray(rawMetadataSegments)) {
    return result;
  }
  return result[0];
};

export const getRangeDescription = (index, values) => {
  const lowerBound = values?.levels[index - 1]?.bucketThreshold;
  const upperBound = values.levels[index].bucketThreshold;
  if (isNil(lowerBound) && index === 0 && !isNil(upperBound)) return `Range: Less than ${upperBound}`;
  if (isNil(lowerBound)) return '';
  return `Range: ${lowerBound} and above${upperBound ? `, but less than ${upperBound}` : ''}`;
};

// Format a metadata_segment db object into the format needed for MetadataSegmentsModal
export const valuesToLevels = (metadataSegment) => {
  const bucketThresholds = metadataSegment?.value?.['$bucketThresholds'];
  const bucketNames = metadataSegment?.value?.['$bucketNames'];
  return metadataSegment.bucket_type === METADATA_SEGMENT_BUCKET_TYPES.DISCRETE
    ? Object.entries(metadataSegment.value).map(([levelKey, levelValue]) => ({
        name: levelKey,
        items: levelValue,
      }))
    : bucketNames.map((bucketName, i) => ({
        bucketName,
        bucketThreshold: i < bucketThresholds.length ? bucketThresholds[i] : undefined,
      }));
};

// Converts something like "Custom_Customers-MetadataSegmentName-Timeseries" to "Timeseries: MetadataSegmentName"
export const getSegmentLabel = (option) => {
  if (!option) return null;

  let friendlyLabel = global.structuredClone(option);

  [SEGMENT_BY_OPTIONS.CUSTOMER_METADATA_CUSTOM, SEGMENT_BY_OPTIONS.TRANSACTION_METADATA_CUSTOM].forEach((prefix) => {
    if (!option.includes(prefix)) return;
    if (option.includes(CUSTOMER_TIMESERIES_METRICS_SUFFIX)) {
      friendlyLabel = `Timeseries:-${friendlyLabel
        .replace(CUSTOMER_TIMESERIES_METRICS_SUFFIX, '')
        .replace(`${prefix}-`, '')}`;
    } else {
      friendlyLabel = friendlyLabel.replace(`${prefix}-`, 'Custom: ');
    }
  });

  if (friendlyLabel.includes(CUSTOMER_TIMESERIES_METRICS_SUFFIX)) {
    // sometimes it will skip the previous loop, so we do this extra replacement too if needed
    friendlyLabel = `Timeseries:-${friendlyLabel.replace(CUSTOMER_TIMESERIES_METRICS_SUFFIX, '')}`;
  }

  [SEGMENT_BY_OPTIONS.CUSTOMER_METADATA, SEGMENT_BY_OPTIONS.TRANSACTION_METADATA].forEach((prefix) => {
    friendlyLabel = friendlyLabel.replace(`${prefix}-`, '');
  });

  return humanize(friendlyLabel);
};

export const getSegmentType = (segment) => {
  if (
    [SEGMENT_BY_OPTIONS.CUSTOMER_METADATA, SEGMENT_BY_OPTIONS.CUSTOMER_METADATA_CUSTOM, 'size'].some((prefix) =>
      segment.includes(prefix),
    )
  ) {
    return 'Customer';
  } else {
    return 'Transaction';
  }
};

export const getSegmentTypeAbbreviation = (segment) => {
  const segmentType = getSegmentType(segment);
  return segmentType.slice(0, 1);
};

// Either bucketThresholds may be provided, or an explicit upper/lower bound
// If bucketThresholds is provided, it will override any upper/lower bounds passed in
const getContinuousSegmentRangeText = ({
  index,
  bucketThresholds,
  upperBound = null,
  lowerBound = null,
  numberFormatter = (val) => val,
}) => {
  if (bucketThresholds) {
    upperBound = bucketThresholds[index];
    lowerBound = bucketThresholds[index - 1];
  }
  if (isNil(lowerBound) && index === 0 && !isNil(upperBound)) return `less than ${upperBound}`;
  if (isNil(lowerBound)) return '';
  return `${upperBound ? 'from ' : ''}${numberFormatter(lowerBound)}${
    upperBound ? `-${numberFormatter(upperBound)}` : ' and above'
  }`;
};

// create a map like { segmentname: { key1: tooltip1, key2: tooltip2 } }
export const getCustomSegmentDescriptors = ({ orgConfigs }) => {
  const { customCustomerMetadataSegments, customTransactionMetadataSegments, segments } = orgConfigs;
  const customSegments = [...customCustomerMetadataSegments, ...customTransactionMetadataSegments];
  const segmentDescriptors = {};
  customSegments.forEach((segment) => {
    let startingTooltipText = `${
      segment.object_type === METADATA_SEGMENT_OBJECT_TYPES.CUSTOMER ? 'Customers' : 'Revenue'
    } with "${getSegmentLabel(segment.key)}"`;
    segmentDescriptors[segment.key] = {};
    if (segment.bucket_type === METADATA_SEGMENT_BUCKET_TYPES.CONTINUOUS) {
      const bucketThresholds = segment.value.$bucketThresholds;
      segment.value.$bucketNames.forEach((bucketName, i) => {
        segmentDescriptors[segment.key][bucketName] = `${startingTooltipText} ${getContinuousSegmentRangeText({
          index: i,
          bucketThresholds,
        })}`;
      });
    } else {
      Object.entries(segment.value).forEach(([bucketName, bucketEntries]) => {
        if (bucketName === '$useZeroBucket') return; // self explanatory bucket, no descriptor
        const tooltip = startingTooltipText + ' values: ' + bucketEntries.join(', ');
        segmentDescriptors[segment.key][bucketName] = tooltip.length <= 200 ? tooltip : `${tooltip.slice(0, 200)}...`;
      });
    }
  });

  // we also want to show tooltips for the native Size segment, too
  // waterfall uses Segment Size and Initial Segement Size as the keys, but cohorts uses 'size'
  // we need to assign the tooltip to all keys, for simplicity in accessing the map later
  segmentDescriptors['size'] = {};
  segmentDescriptors[MAGIC_METADATA.SEGMENT_SIZE] = {};
  segmentDescriptors[MAGIC_METADATA.INITIAL_SEGMENT_SIZE] = {};
  Object.entries(segments?.size ?? {}).forEach(([bucketName, bucketBounds]) => {
    let tooltip = 'Customers with ARR Size ';
    const lowerBound = bucketBounds?.[0];
    const upperBound = bucketBounds?.length >= 2 ? bucketBounds[1] : null;
    tooltip += getContinuousSegmentRangeText({
      upperBound,
      lowerBound,
      numberFormatter: (rawValue) =>
        numberFormatter({ type: NUMBER_FORMATS.CURRENCY, rawValue, currency: orgConfigs.currencyISOCode.value }),
    });
    segmentDescriptors['size'][bucketName] = tooltip;
    segmentDescriptors[MAGIC_METADATA.SEGMENT_SIZE][bucketName] = tooltip;
    segmentDescriptors[MAGIC_METADATA.INITIAL_SEGMENT_SIZE][bucketName] = tooltip;
  });
  return segmentDescriptors;
};

export const dataFilterSegmentKeyToDatabaseSegmentKey = (dataFilterSegmentKey) => {
  return [
    // order matters
    SEGMENT_BY_OPTIONS.CUSTOMER_METADATA_CUSTOM,
    SEGMENT_BY_OPTIONS.TRANSACTION_METADATA_CUSTOM,
    SEGMENT_BY_OPTIONS.CUSTOMER_METADATA,
    SEGMENT_BY_OPTIONS.TRANSACTION_METADATA,
  ].reduce((acc, curr) => {
    const segmentPrefix = `${curr}-`;
    return acc.replace(segmentPrefix, '');
  }, dataFilterSegmentKey ?? '');
};
