import React from 'react';
import { PointerIcon } from '../Icons';
import { Spacer } from '../Core';
import { TooltipColumn, TooltipRowText12, TooltipRowWrapper, TooltipVerticalSeparator } from './styles';
import { TooltipContainer } from './';

export const ExplainerTooltip = ({
  width,
  isVisible,
  toolTipText,
  yOffset,
  xOffset,
  backgroundColor,
  showPointer = true,
  children,
  ...rest
}) => (
  <TooltipContainer
    width={width}
    hideArrow
    tooltipStyles={{ borderRadius: '12px' }}
    backgroundColor={backgroundColor ?? 'var(--opaquePrimaryBlack50)'}
    leftAlign
    yOffset={yOffset ?? 8}
    xOffset={xOffset}
    isVisible={isVisible}
    toolTipContent={
      <TooltipRowWrapper>
        {showPointer && (
          <>
            <TooltipColumn>
              <PointerIcon fill={'white'} />
            </TooltipColumn>
            <Spacer width="8px" />
            <TooltipVerticalSeparator noPadding />
            <Spacer width="8px" />
          </>
        )}
        <TooltipColumn>
          <TooltipRowText12>{toolTipText}</TooltipRowText12>
        </TooltipColumn>
      </TooltipRowWrapper>
    }
  >
    {/* pass all rest props to child component*/}
    {children ? React.cloneElement(children, rest) : <></>}
  </TooltipContainer>
);
