import styled from 'styled-components';

import { ModalHeader, ModalTitle } from 'components/Modal';
import { HelpCircleIcon } from 'components/Icons';

export const ReceiptModalTitle = styled(ModalTitle)`
  font-size: 20px;
  font-weight: 900;
`;

export const ReceiptModalHeader = styled(ModalHeader)`
  justify-content: flex-start;
  align-items: center;
`;

export const ReceiptModalContentWrapper = styled.div`
  margin-top: 20px;
`;

export const StyledHelpCircleIcon = styled(HelpCircleIcon)`
  width: 14px;
  height: 14px;
  margin-bottom: -3px;
  margin-left: 2px;
`;
