import styled from 'styled-components';
import { ReactComponent as DateIcon } from 'images/calendar-time.svg';
import { ReactComponent as CheckIcon } from 'images/circle-check.svg';
import { ReactComponent as CrossIcon } from 'images/circle-x.svg';
import { Centerer, Container, Flexer } from 'components/Core';

export const EditHoverState = styled.div`
  display: none;
  padding: 4px;
  background: #ffffff;
  border: 1px solid var(--neutralGray);
  border-radius: 4px;

  font-weight: 900;
  font-size: 9px;
  line-height: 12px;
  text-transform: uppercase;
  color: var(--primaryBlack50);
`;

export const PeriodsContainer = styled(Container)`
  padding: 28px 50px;
  border-top: 1px solid var(--primaryBlack3);
  background: var(--primaryGray);

  &:hover {
    ${EditHoverState} {
      display: block;
    }
  }
`;

export const PeriodsTitleRow = styled(Flexer)`
  align-items: center;
  gap: 16px;

  div {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: var(--primaryBlack);
  }
`;

export const EmptyCard = styled(Flexer)`
  align-items: center;
  justify-content: space-between;
  gap: 12px;

  background: #ffffff;
  border: 1px solid var(--accentGraySecond);
  border-radius: 12px;

  div {
    padding: 28px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--primaryBlack50);
  }

  svg {
    margin-bottom: -25px;
  }
`;

export const PeriodsTable = styled.div`
  max-height: 36vh;
  overflow: auto;

  display: grid;
  grid-template-columns: 40px 80px 1fr 70px 60px;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
`;

export const PeriodHeaderColumn = styled(Flexer)`
  align-items: center;
  justify-content: ${({ justifyContent }) => justifyContent ?? 'center'};
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--primaryBlack40);

  svg {
    margin-right: 8px;
  }
`;

export const StyledDateIcon = styled(DateIcon)`
  width: 14px;
  height: 14px;

  path {
    fill: var(--primaryBlack40);
  }
`;

export const PeriodNumber = styled(Centerer)`
  padding: 8px 12px;
  width: 24px;
  height: 24px;
  background: var(--primaryBlack3);
  border-radius: 100px;

  font-weight: 900;
  font-size: 9px;
  line-height: 12px;
  text-transform: uppercase;
  color: var(--primaryBlack50);
`;

export const PeriodText = styled(Flexer)`
  align-items: center;
  justify-content: ${({ justifyContent }) => justifyContent ?? 'center'};

  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: right;
  color: var(--primaryBlack);
`;

export const StyledCheckIcon = styled(CheckIcon)`
  width: 14px;
  height: 14px;

  path {
    fill: #1eb76e;
  }
`;

export const StyledCrossIcon = styled(CrossIcon)`
  width: 14px;
  height: 14px;

  path {
    fill: #ff5959;
  }
`;
