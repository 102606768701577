import { useState } from 'react';
import { useToasts } from 'utils/hooks';
import { Centerer } from '../Core';
import { GreyDot } from '../Icons';
import { CopyButton, Label, InputField, ValueText, ShowIcon, HideIcon } from './ClipboardField.styles';

export const ClipboardField = ({ name, label, value, isSecret = false, showByDefault = true }) => {
  const { pushToast } = useToasts();
  const [show, setShow] = useState(showByDefault);

  const handleCopy = () => {
    navigator.clipboard.writeText(value);
    pushToast('Copied to clipboard successfully', 'success');
  };

  const toggleShow = () => setShow(!show);

  return (
    <div data-cy={`clipboard-field--${name}`}>
      {!!label && <Label>{label}</Label>}
      <InputField>
        {show && (
          <ValueText data-cy={`clipboard-field--${name}-text`} isSecret={isSecret}>
            {value}
          </ValueText>
        )}
        {isSecret && !show && (
          <span>
            {Array(16)
              .fill()
              .map((_ignored, idx) => (
                <GreyDot key={idx} size="6px" spacing="4px" />
              ))}
          </span>
        )}
        <div>
          <Centerer>
            {isSecret && (
              <>
                {!show && <ShowIcon onClick={toggleShow} />}
                {show && <HideIcon onClick={toggleShow} />}
                <GreyDot />
              </>
            )}
            <CopyButton onClick={handleCopy} data-cy={`clipboard-field--${name}-copy-button`} />
          </Centerer>
        </div>
      </InputField>
    </div>
  );
};
