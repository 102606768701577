import React, { useState, useContext, useCallback } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { isEqual } from 'lodash';
import utc from 'dayjs/plugin/utc';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { AppContext } from 'AppContext';
import { useIntegrationsAPI } from 'api/integrations';
import { useImportsAPI } from 'api/imports';
import { useCustomerAPI, useCustomersAPI } from 'api/customers';
import {
  BILLING_INTEGRATION_SERVICES,
  CRM_INTEGRATION_SERVICES,
  GL_INTEGRATION_SERVICES,
  INTEGRATIONS_OPERATIONS,
  INTEGRATION_FIELD_MAPPINGS,
  INTEGRATION_METADATA_FIELDS,
  INTEGRATION_TYPES,
} from 'consts/integrations';
import { humanize, stringToBoolean } from 'utils/stringUtils';
import { FormikCustomInput } from 'components/Controls';
import { Loader } from 'components/Loaders';
import { useToasts } from 'components/Toasts';
import {
  Modal,
  ModalBody,
  ModalCloseIcon,
  ModalHeader,
  ModalTitle,
  ModalContainer,
  ModalFooter,
  ModalButton,
} from 'components/Modal';
import { FlexerRow } from 'components/Core';
import { ExternalLinkFullIcon } from 'components/Icons';
import { useUpdateInvoiceFieldsModal } from 'views/Billing/InvoiceModal/UpdateInvoiceFieldsModal';
import { getServiceCategory } from 'models/integration';
import { groupBy } from 'utils/arrayUtils';
import { INTERNAL_CUSTOMER_METADATA } from 'models/customer';
import { MAGIC_METADATA } from 'consts/global';

import { ExternalLink } from '../styles';
import { CUSTOMERS_MODAL_ACTIONS } from './consts';
import { CustomerAddressInformation } from './CustomerAddressInformation';
import { GLCustomerContextProvider } from './GLCustomerContext';
import { IntegrationCustomerSelector } from './IntegrationCustomerSelector';
import { GLCustomerDetailsSelector } from './GLCustomerDetailsSelector';
import { SaveCustomerButton } from './SaveCustomerButton';
import { ParentCustomerSelector } from './ParentCustomerSelector';
import { CustomerDetails } from './CustomerDetails';

dayjs.extend(utc);

const DeleteText = styled.div`
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 16px;
  margin-top: 20px;
`;

const FormButtonsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 10px;
`;

const FormInputsRow = styled.div`
  display: flex;
  margin-top: 20px;
  flex-wrap: ${({ flexWrap }) => flexWrap};
  gap: 20px;

  > * {
    width: 100%;
    flex: ${({ flex }) => flex};
  }
`;

const FormFooter = styled(ModalFooter)`
  width: 100%;
  position: absolute;
  margin-top: 0;
  bottom: 0;
  left: 0;
`;

const OverflowWrapper = styled.div`
  overflow: auto;
  max-height: 750px;
`;

const DeleteContent = styled.div`
  display: flex;
  flex-direction: column;
  background: rgba(57, 161, 220, 0.05);
  border: 1px solid var(--primaryBlack5);
  border-radius: 8px;
  padding: 10px;

  p {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: var(--primaryBlue);
  }

  span {
    opacity: 0.5;
  }

  div {
    opacity: 0.5;
    font-size: 14px;
    line-height: 18px;
    display: block;
  }
`;

const SectionContainer = styled.div`
  padding-block: 20px;

  &:nth-child(odd) {
    background-color: var(--primaryBlack2);
  }
`;

export const CustomersActionsModal = ({
  isPageView,
  customer: initialCustomer,
  modalAction,
  closeModal,
  filters,
  defaultAddressTab,
}) => {
  const history = useHistory();
  const { orgId, integrations } = useContext(AppContext);
  const { pushError } = useToasts();
  const [loading, setLoading] = useState(false);
  // TODO: Pass only customerId to this modal
  const [customerId, setCustomerId] = useState(initialCustomer?.id);

  const { openModal: openUpdateEmailsModal, UpdateInvoiceFieldsModal } = useUpdateInvoiceFieldsModal();

  const {
    data: customerData,
    isLoading: isLoadingCustomer,
    isFetching: isFetchingCustomer,
    operations: { invalidate },
  } = useCustomerAPI({
    orgId,
    customerId,
    filters: { scopes: ['invoicing_details'], includeHierarchy: true },
    enabled: !!customerId,
  });

  const {
    operations: { addCustomer, editCustomer, removeCustomer },
  } = useCustomersAPI({ orgId, filters, autoFetch: false });

  const {
    operations: { runOnExternalService, syncEntity },
  } = useIntegrationsAPI({ orgId, autoFetch: false });

  const {
    data: customerImports,
    operations: { upsertImport, invalidate: invalidateImports },
  } = useImportsAPI({
    orgId,
    filters: {
      chifferObjectName: 'customer',
      chifferObjectIds: [customerId],
      scopes: ['external_urls'],
    },
    autoFetch: !!customerId,
  });

  const glIntegrations = integrations?.filter(
    (i) => i.type === INTEGRATION_TYPES.GL && GL_INTEGRATION_SERVICES.includes(getServiceCategory(i.service)),
  );

  const nightlySyncedIntegrations = (integrations ?? [])?.filter(
    (i) => stringToBoolean(i?.metadata?.[INTEGRATION_METADATA_FIELDS.SKIP_DAILY_SYNC]) === false,
  );
  const isImported =
    (customerImports ?? []).filter((customerImport) =>
      nightlySyncedIntegrations.map((i) => i.id).includes(customerImport.integration_id),
    ).length > 0;
  const connectedIntegrations = (integrations ?? []).map((i) => i.id?.toString());
  const activeCustomerImports = (customerImports ?? []).filter((i) =>
    connectedIntegrations.includes(i.integration_id?.toString()),
  );
  const activeCustomerImportByIntegrationId = groupBy(activeCustomerImports, 'integration_id', { uniqueness: true });

  const crmIntegration = integrations?.find((i) => i.type === INTEGRATION_TYPES.CRM);
  const billingIntegration = integrations?.find((i) => i.type === INTEGRATION_TYPES.Billing);
  const otherImports = !!activeCustomerImports.filter(
    (customerImport) =>
      !GL_INTEGRATION_SERVICES.includes(getServiceCategory(customerImport.provider_name)) &&
      !CRM_INTEGRATION_SERVICES.includes(getServiceCategory(customerImport.provider_name)) &&
      !BILLING_INTEGRATION_SERVICES.includes(getServiceCategory(customerImport.provider_name)),
  ).length;

  let initialSelectedIntegrationId =
    customerData?.metadata?.[INTERNAL_CUSTOMER_METADATA.SELECTED_GL_INTEGRATION_ID] ??
    activeCustomerImports?.find((imp) => GL_INTEGRATION_SERVICES.includes(getServiceCategory(imp.provider_name)))
      ?.integration_id ??
    glIntegrations[0]?.id;
  if (
    initialSelectedIntegrationId &&
    !glIntegrations.some(({ id }) => parseInt(initialSelectedIntegrationId) === parseInt(id))
  ) {
    initialSelectedIntegrationId = null;
  }

  const initialExistingImport =
    activeCustomerImportByIntegrationId[initialSelectedIntegrationId] ?? activeCustomerImports[0];

  const initialValue = !!customerId
    ? {
        ...customerData,
        metadata: {
          ...customerData?.metadata,
          [INTERNAL_CUSTOMER_METADATA.SELECTED_GL_INTEGRATION_ID]: initialSelectedIntegrationId,
        },
        provider_object_id: initialExistingImport?.provider_object_id ?? null,
        crm_provider_object_id: activeCustomerImportByIntegrationId[crmIntegration?.id]?.provider_object_id ?? null,
        billing_provider_object_id:
          activeCustomerImportByIntegrationId[billingIntegration?.id]?.provider_object_id ?? null,
        multiSave: activeCustomerImports.reduce(
          (obj, customerImport) => ({
            ...obj,
            [customerImport.id]: false,
          }),
          {},
        ),
      }
    : {
        name: '',
        metadata: { [INTERNAL_CUSTOMER_METADATA.SELECTED_GL_INTEGRATION_ID]: glIntegrations[0]?.id },
        parent_customer_id: null,
        provider_object_id: null,
        crm_provider_object_id: null,
      };

  const handleBillWithParentChange = useCallback(
    async ({ values, updateData = {}, setFieldValue, billWithParent }) => {
      setFieldValue(`metadata.${MAGIC_METADATA.QUICKBOOKS_BILLWITHPARENT}`, billWithParent);

      if (!!customerData?.metadata?.[MAGIC_METADATA.QUICKBOOKS_BILLWITHPARENT] === !!billWithParent) return;

      const data = {
        name: updateData.name ?? values.name,
        metadata: {
          ...values.metadata,
          ...updateData.metadata,
          [MAGIC_METADATA.QUICKBOOKS_BILLWITHPARENT]: billWithParent,
        },
        parent_customer_id: billWithParent ? null : updateData.parent_customer_id ?? values.parent_customer_id, // this is the parent not from QB
        ...(!customerData?.metadata?.[MAGIC_METADATA.QUICKBOOKS_BILLWITHPARENT] && {
          invoicing_details: updateData.invoicing_details ?? values.invoicing_details,
        }),
      };
      if (!customerId) {
        await addCustomer({ data });
      } else {
        await editCustomer({ id: customerId, data });
      }
    },
    [addCustomer, customerData?.metadata, customerId, editCustomer],
  );

  const handleExternalUpdate = async ({ name, metadata, customerImport, invoicingDetails }) => {
    const integrationEmailAddressesKey =
      INTEGRATION_FIELD_MAPPINGS.emailAddresses[getServiceCategory(customerImport.provider_name)];
    const integrationInvoicingDetailsKey =
      INTEGRATION_FIELD_MAPPINGS.invoicingDetails[getServiceCategory(customerImport.provider_name)];

    await runOnExternalService.mutateAsync({
      integrationService: getServiceCategory(customerImport.provider_name),
      operation: INTEGRATIONS_OPERATIONS.UPDATE_CUSTOMER,
      integrationId: customerImport.integration_id,
      data: {
        id: customerImport.provider_object_id,
        [INTEGRATION_FIELD_MAPPINGS.name[customerImport.provider_name]]: name,
        ...(integrationEmailAddressesKey &&
          invoicingDetails && {
            [integrationEmailAddressesKey]: invoicingDetails?.contacts,
          }),
        ...(integrationInvoicingDetailsKey &&
          invoicingDetails && {
            [integrationInvoicingDetailsKey]: invoicingDetails,
          }),
        metadata,
      },
    });
  };

  const fetchCustomerAction = async (formValues) => {
    try {
      setLoading(true);

      if (modalAction === CUSTOMERS_MODAL_ACTIONS.DELETE) {
        await removeCustomer({ id: customerId });
        if (isPageView) {
          history.push('/customers');
        }
      } else if (modalAction === CUSTOMERS_MODAL_ACTIONS.CREATE || modalAction === CUSTOMERS_MODAL_ACTIONS.EDIT) {
        const externalParentName = formValues.invoicing_details?.external_parent_customer_name;
        const childInvoicingDetails = formValues?.metadata?.[MAGIC_METADATA.QUICKBOOKS_BILLWITHPARENT]
          ? formValues.childInvoicingDetails
          : formValues.invoicing_details;

        const requestBody = {
          name: formValues.name,
          metadata: {
            ...formValues.metadata,
            [MAGIC_METADATA.BILLING_CUSTOMER_NAME]: formValues?.metadata?.[MAGIC_METADATA.QUICKBOOKS_BILLWITHPARENT]
              ? externalParentName
              : formValues.name,
          },
          parent_customer_id: formValues.parent_customer_id,
          invoicing_details: childInvoicingDetails,
        };

        let customerForReturn;
        if (!customerId) {
          customerForReturn = await addCustomer({ data: requestBody });
        } else {
          customerForReturn = await editCustomer({ id: customerId, data: requestBody });
        }

        if (!formValues.saveParent) {
          let shouldRefetch = false;
          if (activeCustomerImports?.length > 0) {
            await Promise.all(
              activeCustomerImports.map(async (customerImport) => {
                const shouldSave = (formValues.multiSave ?? {})[customerImport.id];

                if (shouldSave) {
                  await handleExternalUpdate({
                    name: formValues.name,
                    metadata: formValues.metadata,
                    customerImport,
                    invoicingDetails: childInvoicingDetails,
                  });

                  // need to also update the metadata.customer_name in the import
                  await upsertImport.mutateAsync({
                    integrationId: customerImport.integration_id,
                    data: {
                      chiffer_object_name: 'customer',
                      chiffer_object_id: customerData.id,
                      provider_object_id: customerImport.provider_object_id,
                      metadata: { customer_name: formValues.name },
                    },
                  });
                  // Re-ingest the customer, after it's been updated in the external system
                  await syncEntity.mutateAsync({
                    integrationId: customerImport.integration_id,
                    entity: 'customers',
                    externalIds: [customerImport.provider_object_id],
                  });

                  shouldRefetch = true;
                }
              }),
            );
          }

          if (
            (activeCustomerImportByIntegrationId?.[crmIntegration?.id]?.provider_object_id ||
              formValues.crm_provider_object_id) &&
            activeCustomerImportByIntegrationId?.[crmIntegration?.id]?.provider_object_id !==
              formValues.crm_provider_object_id
          ) {
            // We don't write to the CRM here, however the user does want us to change what this object is linked to
            await upsertImport.mutateAsync({
              integrationId: crmIntegration?.id,
              data: {
                chiffer_object_name: 'customer',
                chiffer_object_id: customerData.id,
                provider_object_id: formValues.crm_provider_object_id,
                metadata: { customer_name: customerData.name },
              },
            });

            shouldRefetch = true;
          }

          if (
            (activeCustomerImportByIntegrationId?.[billingIntegration?.id]?.provider_object_id ||
              formValues.billing_provider_object_id) &&
            activeCustomerImportByIntegrationId?.[billingIntegration?.id]?.provider_object_id !==
              formValues.billing_provider_object_id
          ) {
            // We don't write to Stripe here, however the user does want us to change what this object is linked to
            await upsertImport.mutateAsync({
              integrationId: billingIntegration?.id,
              data: {
                chiffer_object_name: 'customer',
                chiffer_object_id: customerData.id,
                provider_object_id: formValues.billing_provider_object_id,
                metadata: { customer_name: customerData.name },
              },
            });

            shouldRefetch = true;
          }

          if (shouldRefetch) {
            invalidateImports();
            invalidate();
          }

          const isEmailsChanged = !isEqual(
            initialValue?.invoicing_details?.contacts,
            formValues?.invoicing_details?.contacts,
          );

          if (isEmailsChanged && modalAction === CUSTOMERS_MODAL_ACTIONS.EDIT) {
            const handleEmailModalClose = () => {
              closeModal(customerForReturn);
            };

            openUpdateEmailsModal({
              updatedFieldsWithNewValues: { email_addresses: formValues?.invoicing_details?.contacts },
              customerId,
              customerName: formValues.name,
              afterCloseFn: handleEmailModalClose,
            });
          } else {
            closeModal(customerForReturn);
          }
        }

        return customerForReturn;
      }
    } catch (err) {
      pushError(err);
    } finally {
      setLoading(false);
    }
  };

  const getCustomerImportLabel = (customerImport) => {
    return (
      <FlexerRow>
        {`${humanize(customerImport.provider_name)} Customer`}
        <ExternalLink onClick={() => window.open(customerImport.external_url, '_blank')}>
          <ExternalLinkFullIcon />
        </ExternalLink>
      </FlexerRow>
    );
  };

  return (
    <>
      <ModalContainer>
        <Modal
          compact
          maxWidth={modalAction === CUSTOMERS_MODAL_ACTIONS.DELETE ? '440px' : '940px'}
          height="auto"
          maxHeight="auto"
          overflow="auto"
        >
          <ModalHeader>
            <ModalCloseIcon data-cy="customers-actions-modal__close-button" onClose={() => closeModal(null)} />
            <ModalTitle>
              <b>
                {modalAction === CUSTOMERS_MODAL_ACTIONS.DELETE
                  ? 'Remove'
                  : modalAction === CUSTOMERS_MODAL_ACTIONS.EDIT || customerId
                  ? 'Edit'
                  : 'Create'}
              </b>{' '}
              Customer
            </ModalTitle>
          </ModalHeader>
          {loading || isLoadingCustomer ? (
            <div className="w-100 flexer">
              <Loader containerStyles={{ width: 40, marginTop: 20 }} />
            </div>
          ) : (
            <GLCustomerContextProvider
              customerId={customerId}
              setCustomerId={setCustomerId}
              initialCustomerExternalId={initialExistingImport?.provider_object_id}
              initialCustomerData={customerData}
              editCustomer={editCustomer}
            >
              {modalAction !== CUSTOMERS_MODAL_ACTIONS.DELETE ? (
                <Formik
                  initialValues={initialValue}
                  validationSchema={Yup.object({
                    name: Yup.string().required('Please, enter name'),
                  })}
                  enableReinitialize
                  onSubmit={(values) => {
                    fetchCustomerAction(values);
                  }}
                >
                  {({ values, setFieldValue, handleSubmit }) => (
                    <Form
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          handleSubmit();
                        }
                      }}
                    >
                      <OverflowWrapper>
                        <SectionContainer>
                          <ModalBody>
                            <FormInputsRow>
                              <FormikCustomInput
                                name="name"
                                data-cy="input-name"
                                placeholder="Enter name..."
                                label="Customer Name"
                                handleChange={(val) => setFieldValue('name', val)}
                              />

                              <ParentCustomerSelector
                                glIntegrations={glIntegrations}
                                isImported={isImported}
                                handleBillWithParentChange={handleBillWithParentChange}
                                isFetchingCustomer={isFetchingCustomer}
                                addCustomer={addCustomer}
                              />

                              <FormikCustomInput
                                name="taxID"
                                data-cy="input-taxID"
                                placeholder="Enter tax ID..."
                                label="Tax ID"
                                value={values.invoicing_details?.taxID}
                                handleChange={(val) => setFieldValue('invoicing_details.taxID', val)}
                                isDisabled={!!values?.metadata?.[MAGIC_METADATA.QUICKBOOKS_BILLWITHPARENT]}
                              />
                            </FormInputsRow>

                            {!!glIntegrations?.length && (
                              <FormInputsRow>
                                <GLCustomerDetailsSelector
                                  activeCustomerImportByIntegrationId={activeCustomerImportByIntegrationId}
                                  glIntegrations={glIntegrations}
                                  modalAction={modalAction}
                                  initialCustomerData={customerData}
                                  editCustomer={editCustomer}
                                  handleBillWithParentChange={handleBillWithParentChange}
                                />
                              </FormInputsRow>
                            )}

                            {(crmIntegration || billingIntegration || otherImports) && (
                              <FormInputsRow flex="1 1 45%" flexWrap="wrap">
                                {crmIntegration && (
                                  <IntegrationCustomerSelector
                                    activeCustomerImportByIntegrationId={activeCustomerImportByIntegrationId}
                                    integration={crmIntegration}
                                    customerData={customerData}
                                    fieldName={'crm_provider_object_id'}
                                    defaultToCustomerName={true}
                                  />
                                )}

                                {billingIntegration && (
                                  <IntegrationCustomerSelector
                                    activeCustomerImportByIntegrationId={activeCustomerImportByIntegrationId}
                                    integration={billingIntegration}
                                    customerData={customerData}
                                    fieldName={'billing_provider_object_id'}
                                    defaultToCustomerName={false}
                                  />
                                )}

                                {otherImports &&
                                  activeCustomerImports
                                    .filter(
                                      (customerImport) =>
                                        !GL_INTEGRATION_SERVICES.includes(
                                          getServiceCategory(customerImport.provider_name),
                                        ) &&
                                        !CRM_INTEGRATION_SERVICES.includes(
                                          getServiceCategory(customerImport.provider_name),
                                        ) &&
                                        !BILLING_INTEGRATION_SERVICES.includes(
                                          getServiceCategory(customerImport.provider_name),
                                        ),
                                    )
                                    .map((customerImport) => (
                                      <FormikCustomInput
                                        key={`${customerImport.provider_name}-customer-name`}
                                        name={`${customerImport.provider_name}-customer-name`}
                                        value={customerImport.metadata?.customer_name ?? customerData?.name}
                                        placeholder="Enter name..."
                                        label={getCustomerImportLabel(customerImport)}
                                        disabled={isImported}
                                      />
                                    ))}
                              </FormInputsRow>
                            )}
                          </ModalBody>
                        </SectionContainer>

                        <SectionContainer>
                          <ModalBody>
                            <CustomerDetails
                              glIntegrations={glIntegrations}
                              isImported={isImported}
                              handleBillWithParentChange={handleBillWithParentChange}
                              isFetchingCustomer={isFetchingCustomer}
                              addCustomer={addCustomer}
                              refetchCustomer={() => invalidate()}
                              updateChildCustomer={() => fetchCustomerAction({ ...values, saveParent: true })}
                              activeCustomerImportByIntegrationId={activeCustomerImportByIntegrationId}
                              handleExternalUpdate={handleExternalUpdate}
                            />
                          </ModalBody>
                        </SectionContainer>

                        <SectionContainer>
                          <ModalBody paddingBottom="80px">
                            <b style={{ fontWeight: 900 }}>For manual changes</b>
                            <CustomerAddressInformation
                              values={
                                values.childInvoicingDetails
                                  ? {
                                      ...values,
                                      invoicing_details: {
                                        ...values.childInvoicingDetails,
                                      },
                                    }
                                  : values
                              }
                              setFieldValue={setFieldValue}
                              defaultAddressTab={defaultAddressTab}
                              service={
                                glIntegrations?.find(
                                  (integration) =>
                                    integration.id ===
                                    values.metadata?.[INTERNAL_CUSTOMER_METADATA.SELECTED_GL_INTEGRATION_ID],
                                )?.service
                              }
                              isDisabled={!!values?.metadata?.[MAGIC_METADATA.QUICKBOOKS_BILLWITHPARENT]}
                            />
                          </ModalBody>
                        </SectionContainer>
                      </OverflowWrapper>

                      <FormFooter>
                        <FormButtonsRow>
                          <ModalButton
                            default
                            onClick={() => closeModal(null)}
                            data-cy="customers-actions-modal__cancel-button"
                            fontWeight={700}
                          >
                            Cancel
                          </ModalButton>
                          <SaveCustomerButton
                            onSave={handleSubmit}
                            activeCustomerImports={activeCustomerImports}
                            glIntegrations={glIntegrations}
                            selectedGlIntegrationId={
                              values.metadata?.[INTERNAL_CUSTOMER_METADATA.SELECTED_GL_INTEGRATION_ID]
                            }
                            values={values}
                            isFetchingCustomer={isFetchingCustomer}
                          />
                        </FormButtonsRow>
                      </FormFooter>
                    </Form>
                  )}
                </Formik>
              ) : (
                <ModalBody style={{ paddingBottom: 100 }}>
                  <DeleteText>Do you really want to remove this customer? This cannot be un-done.</DeleteText>
                  <DeleteContent>
                    <p>
                      {customerData?.name} <span>/ {customerData?.id}</span>
                    </p>
                  </DeleteContent>
                  <FormFooter>
                    <FormButtonsRow>
                      <ModalButton default onClick={() => closeModal(null)}>
                        Cancel
                      </ModalButton>
                      <ModalButton delete data-cy="customer-remove-button" onClick={fetchCustomerAction}>
                        Remove
                      </ModalButton>
                    </FormButtonsRow>
                  </FormFooter>
                </ModalBody>
              )}
            </GLCustomerContextProvider>
          )}
        </Modal>
      </ModalContainer>

      <UpdateInvoiceFieldsModal />
    </>
  );
};
