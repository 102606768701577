import React, { useState } from 'react';
import styled from 'styled-components';
import { formatBillingAddress, formatShippingAddress } from 'models/customer';
import { Column, Flexer, FlexerColumn } from 'components/Core';
import { CustomersActionsModal, CUSTOMERS_MODAL_ACTIONS } from '../CustomersActionsModal';
import { GreyBoxTitle, GreyBoxContent, GreyBoxActionButton } from '../styles';

const Text = styled.span`
  font-weight: ${({ fontWeight }) => fontWeight ?? 400};
  color: ${({ light, blue }) =>
    light ? 'var(--primaryBlack50)' : blue ? 'var(--primaryBlue)' : 'var(--primaryBlack)'};
  font-size: 12px;
`;

const Container = styled(Column)`
  border-radius: 16px;
  padding: 10px 20px;
  border: 1px solid var(--primaryBlack2);
  background: white;
`;

const CustomerBillingAddress = ({ customer, organizationId }) => {
  const [showCustomersActionModal, setShowCustomerActionModal] = useState(false);

  const formattedBillingAddress = formatBillingAddress({ customer });
  const formattedShippingAddress = formatShippingAddress({ customer }) || formattedBillingAddress;

  return (
    <Container data-cy="customer-billing-address">
      <GreyBoxTitle>
        {customer?.name}
        <GreyBoxActionButton
          data-cy="address-edit-button"
          onClick={() => {
            setShowCustomerActionModal(true);
          }}
          isText
        >
          Edit Address
        </GreyBoxActionButton>
      </GreyBoxTitle>

      <GreyBoxContent width="100%">
        <FlexerColumn gap="8px" justifyContent="flex-start" width="100%">
          <Flexer gap="4px">
            <Text light>Emails:</Text>
            <Text blue>{(customer.invoicing_details?.contacts ?? []).join(', ')}</Text>
          </Flexer>

          <Flexer gap="8px">
            <Flexer gap="4px" flexGrow={1}>
              <Text light>Billing:</Text>
              <Text>{formattedBillingAddress}</Text>
            </Flexer>
            <Flexer gap="4px" flexGrow={1}>
              <Text light>Shipping:</Text>
              <Text>
                {formattedShippingAddress && formattedShippingAddress === formattedBillingAddress
                  ? 'same as billing address'
                  : formattedShippingAddress}
              </Text>
            </Flexer>
          </Flexer>
        </FlexerColumn>
      </GreyBoxContent>

      {showCustomersActionModal && (
        <CustomersActionsModal
          organizationId={organizationId}
          closeModal={() => setShowCustomerActionModal(false)}
          modalAction={CUSTOMERS_MODAL_ACTIONS.EDIT}
          customer={customer}
        />
      )}
    </Container>
  );
};

export { CustomerBillingAddress };
