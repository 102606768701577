import { useCallback } from 'react';

import { useMetadataOptionsAPI } from 'api/metadata';
import { getMetadataDataType } from '../utils';

export const useCombinedMetadata = ({ orgId, metadataFilterType, currentValues }) => {
  const { metadataOptions, ...apiValues } = useMetadataOptionsAPI({ orgId, metadataFilterType });

  const formatToCombinedStructure = useCallback(({ metadataOptions, metadataValues }) => {
    const combinedMetadataKeys = Object.keys({ ...metadataOptions, ...metadataValues });

    return combinedMetadataKeys
      .map((key) => {
        const value = metadataValues[key] ?? null;
        const options = (metadataOptions[key] ?? []).filter((option) => {
          if (typeof option === 'string') {
            return !!option.trim();
          } else {
            return option !== null && option !== undefined;
          }
        });

        return {
          key,
          value,
          options,
          dataType: getMetadataDataType({
            key,
            value,
            options,
          }),
        };
      })
      .sort((a, b) => {
        const keyA = a.key.toLowerCase();
        const keyB = b.key.toLowerCase();

        return keyA < keyB ? -1 : 1;
      });
  }, []);

  return {
    ...apiValues,
    combinedMetadataOptions: formatToCombinedStructure({ metadataOptions, metadataValues: currentValues }),
    formatToCombinedStructure,
    metadataOptions,
  };
};
