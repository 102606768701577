import React from 'react';
import styled from 'styled-components';
import { ReactComponent as SalesforceIcon } from 'images/salesforce-icon.svg';
import { ReactComponent as StripeIcon } from 'images/stripe-icon.svg';
import { ReactComponent as HubspotIcon } from 'images/hubspot-icon.svg';
import { ReactComponent as QuickBooksIcon } from 'images/quickbook-icon.svg';
import { ReactComponent as XeroIcon } from 'images/xero-icon.svg';
import checkIcon from 'images/wizard_check_icon.svg';
import { GL_INTEGRATION_SERVICES_WITH_OAUTH, INTEGRATION_SERVICES } from 'consts/integrations';
import { getServiceCategory } from 'models/integration';
import { capitalize } from 'utils/stringUtils';

const WizardIntegrationButton = styled.div`
  position: relative;
  padding: ${({ small }) => (small ? '20px' : '28px 15px')};
  border-radius: 20px;
  margin-right: ${({ noMarginRight }) => (noMarginRight ? 0 : '28px')};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: ${({ active }) => (active ? 'default' : 'pointer')};
  border: ${({ active }) => (active ? '2px solid var(--primaryBlue)' : '1px solid var(--primaryBlack10)')};
  border: ${({ connected }) => connected && '3px solid var(--primaryGreen)'};
  box-shadow: ${({ active }) => (active ? '0px 4px 20px var(--primaryBlack10)' : '8px 8px 60px var(--primaryBlack5)')};
  box-shadow: ${({ connected }) => connected && 'none'};
  pointer-events: ${({ disabled }) => disabled && 'none'};
  opacity: ${({ disabled, connected }) => disabled && !connected && '0.5'};
  background-image: url(${checkIcon});
  background-repeat: no-repeat;
  background-position: right 10px top 10px;
  background-size: ${({ connected }) => (connected ? '20px 20px' : '0 0')};

  &:after {
    content: 'connected';
    position: absolute;
    font-weight: 900;
    font-size: 10px;
    line-height: 14px;
    text-transform: uppercase;
    color: rgba(0, 21, 46, 0.2);
    padding: 10px;
    background-color: white;
    border-radius: 12px;
    bottom: -20px;
    display: ${({ connected }) => (connected ? 'block' : 'none')};
  }

  svg {
    width: 52px;
    height: 52px;
  }

  div {
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    margin-top: 16px;
    color: ${({ active }) => active && 'var(--primaryBlue)'};
  }

  span {
    font-size: 14px;
    line-height: 20px;
    max-width: 200px;
    opacity: 0.5;
    margin-top: 8px;
    text-align: center;
  }

  &:hover {
    background-color: var(--primaryBlack5);
  }
`;

const ConnectButton = styled.button`
  font-weight: 900;
  font-size: 10px;
  line-height: 14px;
  text-transform: uppercase;
  color: #ffffff;
  padding: 8px 12px;
  background: var(--primaryBlue);
  border: 2px solid #ffffff;
  border-radius: 100px;
  position: absolute;
  cursor: pointer;
  bottom: -20px;
`;

const INTEGRATION_ICONS = {
  [INTEGRATION_SERVICES.QUICKBOOKS]: <QuickBooksIcon />,
  [INTEGRATION_SERVICES.XERO]: <XeroIcon />,
};

export const GLIntegrationButtons = ({
  selectedIntegration,
  setSelectedIntegration,
  disabled = false,
  connectedIntegration,
  handleConnect,
  showConnected = true,
}) => (
  <>
    {GL_INTEGRATION_SERVICES_WITH_OAUTH.map((service) => (
      <WizardIntegrationButton
        key={service}
        data-cy={`button-integration-modal__gl--${service}`}
        onClick={() => setSelectedIntegration({ service })}
        active={selectedIntegration?.service === service}
        connected={showConnected && getServiceCategory(connectedIntegration) === service}
        disabled={disabled}
        small
      >
        {INTEGRATION_ICONS[service]}
        <div>{capitalize(service)}</div>
        <span>Connect to your GL</span>
        {selectedIntegration?.service === service && <ConnectButton onClick={handleConnect}>Connect</ConnectButton>}
      </WizardIntegrationButton>
    ))}
  </>
);

export const CRMIntegrationButtons = ({
  selectedIntegration,
  setSelectedIntegration,
  integrationTypes,
  handleConnect,
  showConnected = true,
  isFetching,
}) => {
  let hasHubspotIntegration = false;
  const salesforceIntegrations = [];
  for (const integration of integrationTypes) {
    const serviceNameOnly = getServiceCategory(integration.service);
    switch (serviceNameOnly) {
      case INTEGRATION_SERVICES.SALESFORCE:
        salesforceIntegrations.push(integration);
        break;
      case INTEGRATION_SERVICES.HUBSPOT:
        hasHubspotIntegration = true;
        break;
      default:
    }
  }

  // disable salesforce if there is a hubspot integration or there are 2 salesforce integrations
  const disableSalesforce = isFetching || hasHubspotIntegration || salesforceIntegrations.length >= 2;

  return (
    <>
      <WizardIntegrationButton
        data-cy="button-integration-modal__crm--hubspot"
        onClick={() => setSelectedIntegration({ service: INTEGRATION_SERVICES.HUBSPOT })}
        active={selectedIntegration?.service === INTEGRATION_SERVICES.HUBSPOT}
        connected={showConnected && hasHubspotIntegration}
        disabled={isFetching || hasHubspotIntegration || salesforceIntegrations.length > 0}
      >
        <HubspotIcon />
        <div>HubSpot</div>
        <span>Import your deals, products and companies</span>
        {selectedIntegration?.service === INTEGRATION_SERVICES.HUBSPOT && (
          <ConnectButton onClick={handleConnect}>Connect</ConnectButton>
        )}
      </WizardIntegrationButton>
      <WizardIntegrationButton
        data-cy="button-integration-modal__crm--salesforce"
        onClick={() => setSelectedIntegration({ service: INTEGRATION_SERVICES.SALESFORCE })}
        active={selectedIntegration?.service === INTEGRATION_SERVICES.SALESFORCE}
        connected={showConnected && salesforceIntegrations.length > 0}
        disabled={disableSalesforce}
        noMarginRight
      >
        <SalesforceIcon />
        <div>Salesforce</div>
        <span>Import your opportunities, products and accounts</span>
        {selectedIntegration?.service === INTEGRATION_SERVICES.SALESFORCE && (
          <ConnectButton onClick={handleConnect}>Connect</ConnectButton>
        )}
      </WizardIntegrationButton>
    </>
  );
};

export const BillingIntegrationButtons = ({
  selectedIntegration,
  setSelectedIntegration,
  disabled = false,
  connectedIntegration,
  handleConnect,
  showConnected = true,
}) => (
  <WizardIntegrationButton
    small
    data-cy="button-integration-modal__billing--stripe"
    onClick={() => setSelectedIntegration({ service: INTEGRATION_SERVICES.STRIPE })}
    active={selectedIntegration?.service === INTEGRATION_SERVICES.STRIPE}
    connected={showConnected && connectedIntegration === INTEGRATION_SERVICES.STRIPE}
    disabled={disabled}
    noMarginRight
  >
    <StripeIcon />
    <div>Stripe</div>
    <span>Import your invoices</span>
    {selectedIntegration?.service === INTEGRATION_SERVICES.STRIPE && (
      <ConnectButton onClick={handleConnect}>Connect</ConnectButton>
    )}
  </WizardIntegrationButton>
);
