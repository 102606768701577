import dayjs from 'dayjs';
import html2canvas from 'html2canvas';
import Chart from 'chart.js';
import { EVENTS } from 'consts/analytics';
import { useAnalytics } from './useAnalytics';

export const getBlobImageFromRef = async ({ refCurrent, downloadFunc }) => {
  if (refCurrent) {
    const html = document.getElementsByTagName('html')[0];
    const body = document.getElementsByTagName('body')[0];
    let htmlWidth = html.clientWidth;
    let bodyWidth = body.clientWidth;

    //Min width
    if (htmlWidth < 1440) {
      htmlWidth = 1440;
      bodyWidth = 1440;
    }

    html.style.width = htmlWidth + 'px';
    body.style.width = bodyWidth + 'px';

    for (const id in Chart.instances) {
      Chart.instances[id].resize();
    }

    const canvas = await html2canvas(refCurrent, {});

    const image = canvas.toDataURL('image/png', 1);

    if (downloadFunc) downloadFunc(image);
    html.style.width = null;
    body.style.width = null;

    for (const id in Chart.instances) {
      Chart.instances[id].resize();
    }

    return image;
  }
};

export const useScreenshotDownload = ({ pageName, ref }) => {
  const { trackEvent } = useAnalytics();

  const downloadImage = (blob) => {
    const fakeLink = window.document.createElement('a');
    fakeLink.style = 'display:none;';
    fakeLink.download = `Subscript - ${pageName} - ${dayjs().format('YYYY-MM-DD')}`;

    fakeLink.href = blob;

    document.body.appendChild(fakeLink);
    fakeLink.click();
    document.body.removeChild(fakeLink);

    fakeLink.remove();
  };

  const downloadScreenshot = async () => {
    trackEvent({
      name: EVENTS.EXPORT_PNG,
      properties: {
        pageName,
      },
    });
    const refElement = ref?.current;

    if (refElement) {
      getBlobImageFromRef({ refCurrent: refElement, downloadFunc: downloadImage });
    }
  };

  return { downloadScreenshot };
};
