import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { AppContext } from 'AppContext';
import { useCSVImportsAPI } from 'api/csvImports';
import { ACCOUNTING_SPREAD_REASON } from 'views/Spreads/utils';
import { SOURCE_TYPES } from '../TransactionsCsvUpload/consts';
import { SpreadsCsvUpload } from '../EventBasedSpreadsCsvUpload/SpreadsCsvUpload';
import { DEFAULT_ACCOUNTING_SPREADS_MAPPER } from './consts';

dayjs.extend(utc);

export const AccountingSpreadsCsvUpload = () => {
  const { orgId } = useContext(AppContext);

  const {
    operations: { uploadCSV },
  } = useCSVImportsAPI({ orgId, autoFetch: false });

  const history = useHistory();

  const [submittingForm, setSubmittingForm] = useState(false);

  const handleSubmit = ({ transactions, transactionsIdSourceAndType }) => async ({ values, extraData }) => {
    setSubmittingForm(true);

    const accountingSpreads = values?.map((row) => {
      const transaction =
        transactionsIdSourceAndType?.type === SOURCE_TYPES.INTERNAL
          ? transactions?.find((transaction) => transaction?.id === row?.transaction_id)
          : transactions?.find((transaction) => transaction?.provider_object_id === row?.transaction_id);

      const customerId = transaction?.customer_id ?? transaction?.transaction_customer_id;

      return {
        customer_id: customerId,
        transaction_id: row?.transaction_id,
        amount: row?.amount,
        date: dayjs.utc(row?.date).format('YYYY-MM-DD'),
        reason: ACCOUNTING_SPREAD_REASON.OVERRIDE_AMOUNT,
      };
    });

    try {
      await uploadCSV.mutateAsync({
        rawFile: extraData,
        data: accountingSpreads,
        settings: { entity: extraData.entity },
      });

      setTimeout(() => {
        history.push('/transactions');
        setSubmittingForm(false);
      }, 1000);
    } catch (error) {
      setSubmittingForm(false);
    }
  };

  return (
    <SpreadsCsvUpload
      additionalInfoText="Update Accounting Spreads for transactions. Existing accounting spreads for transactions will be deleted and replaced by uploaded accounting spreads."
      handleSubmit={handleSubmit}
      submittingForm={submittingForm}
      entityName="accounting-spreads"
      defaultMapper={DEFAULT_ACCOUNTING_SPREADS_MAPPER}
      eventRecognitionsOnly={false}
    />
  );
};
