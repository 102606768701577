import React from 'react';
import { CHART_VISUALIZER_AVAILABLE_METRICS } from 'consts/snapshotMetrics';
import { SelectablePill } from 'components/SelectablePill';

export const MetricSelector = ({ selectedMetrics, setSelectedMetrics }) => {
  const metricOptions = Object.entries(CHART_VISUALIZER_AVAILABLE_METRICS).map(([metricKey, { label }]) => ({
    key: metricKey,
    label,
  }));
  return <SelectablePill selected={selectedMetrics} setSelected={setSelectedMetrics} options={metricOptions} />;
};
