import { createFilter } from 'react-select';

import { SEARCH_CONFIDENCE } from 'utils/stringUtils';
import { FormikCustomSelector } from 'components/Controls';
import { getFuzzyCellData } from 'shared/CsvUpload/CsvTable/getFuzzyCellData';

import { INTERNAL_CUSTOMER_ID_SOURCE, NAME_MATCHING_SOURCE, SOURCE_TYPES } from './consts';
import { CustomSingleOption } from './columns';

const getCustomerNameById = ({ id, customersById }) => {
  const customer = customersById?.[id];
  return customer ? (customer.customer_name ?? customer.name) || `No name / ${id}` : id;
};

export const renderCustomerIdCell = ({
  row,
  cell: { value },
  customerIdSourceAndType,
  customers,
  customersSelectOptions,
  customersById,
  formCurrent,
  fuzzyRowsMapper,
  setFuzzyRowsMapper,
  uploadCsvCustomersIdSource,
  key = 'customer_id',
}) => {
  const index = row.original.rowIndex;

  const displayedValue = value
    ? {
        value,
        label: [NAME_MATCHING_SOURCE, INTERNAL_CUSTOMER_ID_SOURCE].includes(customerIdSourceAndType?.source)
          ? getCustomerNameById({ id: value, customersById })
          : value,
      }
    : null;

  if (customerIdSourceAndType?.source === NAME_MATCHING_SOURCE) {
    if (value && formCurrent && !fuzzyRowsMapper?.[index]?.[key]?.fuzzySearchCompleted && customers) {
      const {
        candidates: candidatesForMapper,
        fuzzyTooltip: fuzzyTooltipForMapper,
        confidence: confidenceForMapper,
        value: fuzzyValue,
      } = getFuzzyCellData({
        rowValue: value,
        dataset: customers,
        candidateValueKey: 'id',
        candidateTitleKey: 'name',
        columns: ['name'],
      });

      setFuzzyRowsMapper((prevMapper) => ({
        ...prevMapper,
        [index]: {
          ...(prevMapper?.[index] ?? {}),
          [key]: {
            fuzzySearchCompleted: true,
            fuzzyTooltip: fuzzyTooltipForMapper,
            candidates: candidatesForMapper,
            confidence: confidenceForMapper,
          },
        },
      }));

      formCurrent?.setFieldValue(`[${index}].[${key}]`, fuzzyValue);
    }
  }

  const { fuzzyTooltip, confidence, candidates } = fuzzyRowsMapper?.[index]?.[key] ?? {};

  const sortedByRelevance = customerIdSourceAndType?.source === NAME_MATCHING_SOURCE && candidates?.length > 0;

  const options = sortedByRelevance
    ? candidates?.map((customer) => ({
        value: [NAME_MATCHING_SOURCE, INTERNAL_CUSTOMER_ID_SOURCE].includes(customerIdSourceAndType?.source)
          ? customer.id
          : customerIdSourceAndType?.type === SOURCE_TYPES.INTERNAL
          ? customer?.metadata?.[uploadCsvCustomersIdSource]
          : customer.provider_object_id,
        label:
          customer.customer_name ??
          customer.name ??
          `No name / ${
            customerIdSourceAndType?.source === NAME_MATCHING_SOURCE
              ? customer.id
              : customerIdSourceAndType?.type === SOURCE_TYPES.INTERNAL
              ? customer.metadata?.[uploadCsvCustomersIdSource]
              : customer.provider_object_id
          }`,
      }))
    : customersSelectOptions;

  return (
    <FormikCustomSelector
      height={32}
      components={{ Option: CustomSingleOption }}
      placeholder="Select id..."
      minWidth={160}
      menuWidth={200}
      filterOption={createFilter({ ignoreAccents: false })} // optimization
      errorWithoutTooltip
      value={displayedValue}
      options={options}
      name={`[${index}].${key}`}
      hasWarning={confidence === SEARCH_CONFIDENCE.FUZZY}
      tooltipInputDisplay={customerIdSourceAndType?.source === NAME_MATCHING_SOURCE ? fuzzyTooltip : undefined}
      noSort
    />
  );
};
