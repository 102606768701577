import React, { useEffect } from 'react';
import styled from 'styled-components';
import rockerImage from 'images/Saly-43.png';

const SuccessWrapper = styled.div`
  padding: 0 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SuccessSubtitle = styled.div`
  font-size: 14px;
  line-height: 20px;
  opacity: 0.5;
  margin-top: 40px;
`;

const MainContent = styled.div`
  margin-top: 16px;
  font-size: 30px;
  line-height: 44px;
  max-width: 570px;
  text-align: center;

  span {
    font-weight: 900;
    color: var(--primaryGreen);
  }
`;

const ContactUsButton = styled.a`
  display: flex;
  align-items: center;
  border: 1px solid var(--primaryBlack5);
  border-radius: 100px;
  padding: 12px 20px;
  margin-top: 40px;
  font-weight: bold;
  font-size: 14px;
  color: var(--primaryBlack75);
  line-height: 20px;
  cursor: pointer;
  margin-right: 12px;
  transition: all ease 0.3s;

  &:hover {
    color: var(--primaryBlack);
    box-shadow: 4px 4px 28px var(--primaryBlack5);
  }
`;

export const Success = ({ user, setReachedPage }) => {
  useEffect(() => {
    setReachedPage(5);
  }, [setReachedPage]);
  return (
    <SuccessWrapper>
      <img src={rockerImage} alt="rocket" width="160" height="160" />
      <SuccessSubtitle>You have successfully completed the setup!</SuccessSubtitle>
      <MainContent>
        Thank you, <span>{user.name}</span> for telling us about your setup! We'll get to work right away on getting
        your account ready to go!
      </MainContent>

      <ContactUsButton href="mailto:help@subscript.com">Contact us!</ContactUsButton>
    </SuccessWrapper>
  );
};
