import styled from 'styled-components';
import { useContext } from 'react';

import { initiateGoogleApi, useIntegrationsAPI } from 'api/integrations';
import { AppContext } from 'AppContext';
import { GMAIL_FEATURES, INTEGRATION_SERVICES } from 'consts/integrations';
import { getIntegrationByService } from 'models/integration';
import { ReactComponent as AlertIcon } from 'images/alert-triangle.svg';
import { ReactComponent as GoogleLogo } from 'images/google-logo.svg';

const GoogleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${({ isIntegrated }) => (isIntegrated ? 'var(--accentGrayFourth)' : 'var(--primaryBlue10)')};
  border-radius: ${({ isDisconnected }) => (isDisconnected ? '0 0 12px 12px' : '12px')};
  padding: 16px;
  color: var(--primaryBlack);
`;

const Description = styled.div`
  span {
    margin-left: 4px;
    color: var(--primaryBlue);
  }
`;

const AutoSaveNote = styled.p`
  font-style: italic;
  color: var(--primaryBlack50);
  margin: 4px 0 0;
`;

const DisconnectedWarning = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
  background: var(--newRed10);
  border-radius: 12px 12px 0 0;
  line-height: 16px;

  span {
    font-weight: 700;
  }
`;

const StyledWarningIcon = styled(AlertIcon)`
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  margin-right: 4px;

  path {
    fill: var(--tertiaryRed);
  }
`;

const SignInButton = styled.button`
  width: 200px;
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 10px 12px;
  border-radius: 8px;
  border: 1px solid var(--neutralGray);
  background: #ffffff;
  color: var(--primaryBlack);
  font-weight: 500;
  cursor: pointer;

  :hover {
    box-shadow: 0px 4px 20px var(--primaryBlack10);
    color: var(--primaryBlack70);
  }
`;

const StyledGoogleLogo = styled(GoogleLogo)`
  width: 20px;
  height: 20px;
  flex-shrink: 0;
`;

const DisconnectedAccountWarning = ({ data }) => {
  const name = data.metadata?.user_name;
  const email = data.metadata?.user_email;
  return (
    <DisconnectedWarning data-cy="gmail-disconnected-warning">
      <StyledWarningIcon />
      <div>
        The Google integration&nbsp;<span>({`${name} ${email}`})</span>&nbsp;has been disconnected
      </div>
    </DisconnectedWarning>
  );
};

export const GoogleSignInButton = ({ features = [GMAIL_FEATURES.SEND_EMAIL] }) => (
  <SignInButton data-cy="google__login-button" onClick={() => initiateGoogleApi({ params: { features } })}>
    <StyledGoogleLogo />
    <span>Login with Google</span>
  </SignInButton>
);

export const EnableGoogleApiButton = ({ isIntegrated = false }) => {
  const { organizations } = useContext(AppContext);
  const orgId = organizations?.[0].id;
  const { data: disauthenticatedIntegrations } = useIntegrationsAPI({
    orgId,
    params: {
      includeProducts: false,
      onlyDisauthenticated: true,
    },
  });
  const disconnectedAccount = getIntegrationByService({
    integrations: disauthenticatedIntegrations,
    service: INTEGRATION_SERVICES.GMAIL,
  });
  const isDisconnected = !!disconnectedAccount;

  return (
    <div>
      {isDisconnected && <DisconnectedAccountWarning data={disconnectedAccount} />}
      <GoogleContainer data-cy="google-auth" isIntegrated={isIntegrated} isDisconnected={isDisconnected}>
        <Description>
          {/* Integrated Google account is active */}
          {isIntegrated && <>Change Google Account</>}

          {/* Integrated Google account has been disconnected */}
          {isDisconnected && (
            <>
              Connect <span>Google Apps API</span> again to enable invoice sending
            </>
          )}

          {/* Default - no Google integration at all */}
          {!isIntegrated && !isDisconnected && (
            <>
              Enable sending through <span>Google Apps API</span>
            </>
          )}
          <AutoSaveNote>
            Subscript will automatically save the settings {isIntegrated ? '' : 'after connecting Google API'}
          </AutoSaveNote>
        </Description>
        <GoogleSignInButton />
      </GoogleContainer>
    </div>
  );
};
