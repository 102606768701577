import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { AppContext } from 'AppContext';
import { CsvUpload } from 'shared/CsvUpload';
import { useToasts } from 'components/Toasts';
import { addQuotesToKeysAndValuesToCreateValidJSON, stringToBoolean } from 'utils/stringUtils';
import { useCSVImportsAPI } from 'api/csvImports';

import { getColumns } from './columns';
import { getSchema } from './schema';
import { DEFAULT_PRICING_PLAN_MAPPER } from './consts';

export const PricingPlansCsvUpload = () => {
  const { organizations, orgId } = useContext(AppContext);
  const [submittingForm, setSubmittingForm] = useState(false);
  const { pushError } = useToasts();
  const history = useHistory();

  const {
    operations: { uploadCSV },
  } = useCSVImportsAPI({ orgId, autoFetch: false });

  const getColumnsWithCsvUploadState = useCallback(
    ({
      fuzzyRowsMapper,
      defaultMapper,
      setFuzzyRowsMapper,
      formRef,
      csvColumnsMapper,
      setCsvColumnsMapper,
      csvColumns,
    }) =>
      getColumns({
        organization: organizations[0],
        formRef,
        csvColumns,
        csvColumnsMapper,
        setCsvColumnsMapper,

        fuzzyRowsMapper,
        setFuzzyRowsMapper,

        defaultMapper,
      }),
    [organizations],
  );

  const handleSubmit = async ({ values, extraData }) => {
    setSubmittingForm(true);

    try {
      // Transform the data
      const formattedPricingPlans = values.map((value) => {
        try {
          const tiersJSON = JSON.parse(addQuotesToKeysAndValuesToCreateValidJSON(value.tiers));
          return {
            ...value,
            tiers: tiersJSON,
            bill_in_advance: value.bill_in_advance ?? false,
          };
        } catch (error) {
          throw new Error('Error parsing JSON in tiers column for pricing plan: ' + value.name);
        }
      });

      await uploadCSV.mutateAsync({
        rawFile: extraData,
        data: formattedPricingPlans,
        settings: { entity: extraData.entity },
      });

      setTimeout(() => {
        history.push('/billing/usage-based-engine?tab=pricing-plans');
        setSubmittingForm(false);
      }, 1000);
    } catch (error) {
      pushError({
        response: {
          data: { errors: { message: error.message || 'Failed to upload pricing plans' } },
        },
      });
      setSubmittingForm(false);
    }
  };

  const tableSchema = useMemo(() => {
    return getSchema({
      products: organizations[0]?.products,
    });
  }, [organizations]);

  const isLoading = submittingForm;

  return (
    <CsvUpload
      onlyCreateMode={true}
      entityName="pricing-plans"
      backLink="/billing/usage-based-engine?tab=pricing-plans"
      isLoading={isLoading}
      createSchema={tableSchema}
      handleSubmit={handleSubmit}
      csvColumnsTransformations={{ bill_in_advance: stringToBoolean }}
      defaultMapperWithCreateOrUpdateMode={() => DEFAULT_PRICING_PLAN_MAPPER}
      getColumnsWithCsvUploadState={getColumnsWithCsvUploadState}
    />
  );
};
