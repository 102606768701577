import React, { useContext, useMemo, useState } from 'react';
import styled from 'styled-components';

import { AppContext } from 'AppContext';
import { Row } from 'components/Core';
import { ExplainerTooltip } from 'components/Tooltip';
import { getEndOfQuarters } from './ScenarioCard.utils';
import { ScenarioCardHeader } from './ScenarioCardHeader';
import { ScenarioCardQuartersSummary } from './ScenarioCardQuartersSummary';
import { ScenarioCardBody } from './ScenarioCardBody';
import { CircleArrowWrapper, DirectionIconWrapper } from './ScenarioCardHeader.styles';
import { useForecastedDetailsModal } from '../ForecastedDetailsModal';

export const ScenarioCardWrapper = styled.div`
  background: #ffffff;
  border-radius: 20px;
  border: 1px solid var(--accentGraySecond);
  width: 100%;

  &:hover {
    border: 1px solid var(--neutralGray);
    box-shadow: 2px 2px 12px var(--primaryBlack2);
  }
`;

const FirstRow = styled(Row)`
  padding-right: 20px;

  cursor: pointer;

  &:hover {
    ${DirectionIconWrapper} {
      background: var(--primaryBlack5);
    }

    ${CircleArrowWrapper} {
      rect {
        &:first-child {
          fill: var(--accentGraySecond);
        }
      }
    }
  }
`;

export const ScenarioCard = ({ scenario, onEdit, onRemove, initiallyExpanded = false }) => {
  const { orgConfigs } = useContext(AppContext);
  const [showingDetails, setShowingDetails] = useState(initiallyExpanded);

  const handleEdit = () => onEdit(scenario);
  const handleRemove = () => onRemove(scenario);

  const { quarters, quartersYearOffset } = orgConfigs;

  const scenarioMonths = scenario?.scenario;
  const endOfQuarters = useMemo(
    () =>
      getEndOfQuarters({
        scenario: scenarioMonths,
        quarters,
        quartersYearOffset,
      }),
    [scenarioMonths, quarters, quartersYearOffset],
  );

  const {
    Modal: ForecastedDetailsModal,
    openModalWith: openForecastedDetailsModal,
    isVisible: isForecastDetailsModalVisible,
  } = useForecastedDetailsModal();
  const handleMonthClick = (month) => {
    openForecastedDetailsModal({
      scenarioId: scenario.id,
      scenarioName: scenario.name,
      scenarioMonths: Object.keys(scenario.scenario),
      selectedMonth: month,
      forecastScenarioType: scenario.forecastScenarioType,
    });
  };

  return (
    <ScenarioCardWrapper>
      <FirstRow onClick={() => setShowingDetails((current) => !current)}>
        <ScenarioCardHeader
          scenario={scenario}
          onEdit={handleEdit}
          onRemove={handleRemove}
          showingDetails={showingDetails}
        />
        <ScenarioCardQuartersSummary scenarioMonths={scenarioMonths} endOfQuarters={endOfQuarters} />
      </FirstRow>
      {showingDetails && (
        <ExplainerTooltip
          width={228}
          isVisible={showingDetails && !isForecastDetailsModalVisible}
          toolTipText="Click any month to drill down"
        >
          <ScenarioCardBody
            scenarioMonths={scenarioMonths}
            endOfQuarters={endOfQuarters}
            onMonthClick={handleMonthClick}
          />
        </ExplainerTooltip>
      )}
      <ForecastedDetailsModal />
    </ScenarioCardWrapper>
  );
};
