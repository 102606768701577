const { default: styled } = require('styled-components');

const ProgressBarContainer = styled.div`
  width: ${({ width }) => width ?? '100%'};
  background-color: var(--accentGraySecond);
  border-radius: 100px;
  margin-top: 20px;
  height: 4px;
`;

const ProgressBarFill = styled.div`
  height: 4px;
  background: linear-gradient(180deg, var(--primaryGreen) 0%, #099259 100%);
  border-radius: 100px;
  width: ${(props) => props.progress}%;
  transition: width 0.3s ease-in-out;
`;

export const PercentageProgressBar = ({ progress, width }) => (
  <ProgressBarContainer width={width}>
    <ProgressBarFill progress={progress} />
  </ProgressBarContainer>
);
