import React, { useCallback, useState } from 'react';
import { cssVar } from 'polished';
import { EVENTS } from 'consts/analytics';
import { ReactComponent as ShareIcon } from 'images/bx-share.svg';
import { IconButton } from 'components/Buttons';
import { useAnalytics } from 'utils/hooks';
import { LinkShareModal } from './LinkShareModal';

export const useLinkShare = ({ headerTitle, additionalQuery }) => {
  const { trackEvent } = useAnalytics();
  const [showModal, setShowModal] = useState(false);
  const closeModal = useCallback(() => setShowModal(false), [setShowModal]);

  const openModal = useCallback(() => {
    trackEvent({
      name: EVENTS.OPEN_LINK_SHARE_MODAL,
      properties: {
        page: headerTitle,
      },
    });

    setShowModal(true);
  }, [trackEvent, setShowModal, headerTitle]);

  const LinkShareButton = useCallback(
    () => (
      <IconButton
        icon={<ShareIcon />}
        iconFillColor={cssVar('--primaryGreen')}
        onClick={openModal}
        data-cy="link-share-button"
      >
        Share
      </IconButton>
    ),
    [openModal],
  );

  const Modal = useCallback(
    () =>
      showModal ? (
        <LinkShareModal onClose={closeModal} additionalQuery={additionalQuery} headerTitle={headerTitle} />
      ) : (
        <></>
      ),
    [headerTitle, showModal, closeModal, additionalQuery],
  );

  return {
    LinkShareButton,
    LinkShareModal: Modal,
  };
};
