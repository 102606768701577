import React, { useContext } from 'react';
import { AppContext } from 'AppContext';
import { useIntegrationsAPI } from 'api/integrations';
import { ReactComponent as ProblemsCollapsedIcon } from 'images/problem-transactions-collapsed.svg';
import { PopoverLink } from './PopoverLink';

export const ConfigLink = ({ section }) => {
  const { orgId } = useContext(AppContext);
  const { data } = useIntegrationsAPI({
    orgId,
    params: {
      includeProducts: false,
      onlyDisauthenticated: true,
    },
  });

  return (
    <PopoverLink
      key={section.title}
      active={section.active}
      title={section.title}
      icon={section.icon}
      explainTooltipText={section.explainTooltipText}
      PopoverContents={section.PopoverContents}
    >
      {data?.length > 0 ? <ProblemsCollapsedIcon style={{ position: 'absolute', right: 34, top: 16 }} /> : <></>}
    </PopoverLink>
  );
};
