import React, { useCallback, useContext, useMemo, useState } from 'react';
import { isEmpty } from 'lodash';
import { Link } from 'react-router-dom';
import { AppContext } from 'AppContext';
import { useInvoicesAPI } from 'api/billing';
import { pluralize } from 'utils/stringUtils';
import { sortByToKnexOrderBy } from 'utils/tableUtils';
import { INVOICES_DEFAULT_SORT_BY, INVOICES_SORT_BY_COLUMNS_NAME_MAPPING } from 'views/Billing/InvoicingTables/consts';
import { Spacer } from 'components/Core';
import { CircleCheckStrokeIcon } from 'components/Icons';
import { ModalContainer, FullscreenModal, ModalCloseIcon } from 'components/Modal';
import { TableContainerLayout } from 'views/Billing/Common/TableContainerLayout';
import { InvoicesTable } from 'views/Billing/InvoicingTables/InvoicesTable';
import { groupInvoicesByMonth } from 'views/Billing/InvoicingTables/utils';
import { useInvoicingScheduleModal } from 'views/Billing/InvoicingScheduleModal';
import { ContentWrapper, ModalHeader, ModalSubHeading, ModalHeading } from './ReviewBulkInvoicesModal.styles';

export const ReviewBulkInvoicesModal = ({ invoicingScheduleIds, closeModal }) => {
  const {
    orgId,
    appSettings: { currencyISOCode: currency },
  } = useContext(AppContext);

  const { Modal: InvoicingScheduleModal, openModal: openInvoicingScheduleModal } = useInvoicingScheduleModal();

  const [searchQuery, setSearchQuery] = useState('');

  const [sortBy, setSortBy] = useState();

  const {
    data: { data: invoices },
    isLoading,
  } = useInvoicesAPI({
    orgId,
    invoicingScheduleIds,
    orderBy: isEmpty(sortBy)
      ? INVOICES_DEFAULT_SORT_BY
      : sortByToKnexOrderBy(sortBy, INVOICES_SORT_BY_COLUMNS_NAME_MAPPING),
    notVoided: true,
    autoFetch: invoicingScheduleIds?.length > 0,
  });

  const dataForTable = useMemo(() => (invoices ? groupInvoicesByMonth({ invoices }) : []), [invoices]);

  const openInvoiceModal = useCallback(
    (invoice) =>
      openInvoicingScheduleModal({
        invoicingSchedule: { id: invoice?.invoicing_schedule_id },
        invoice,
        selectedReminder: invoice?.selectedReminder,
      }),
    [openInvoicingScheduleModal],
  );

  const uniqueInvoicingSchedules = invoices.reduce((total, invoice) => {
    total.add(invoice.invoicing_schedule_id);
    return total;
  }, new Set());

  return (
    <>
      <ModalContainer data-cy="review-bulk-invoices-modal">
        <FullscreenModal>
          <ContentWrapper>
            <ModalHeader>
              <ModalCloseIcon data-cy="review-bulk-invoices-modal__close" onClose={closeModal} size="38px" />
              <ModalSubHeading>
                <CircleCheckStrokeIcon size="16px" stroke="var(--primaryGreen)" />
                <Spacer width="4px" />
                You've created:
                <em>
                  {pluralize(uniqueInvoicingSchedules.size, 'invoicing schedule')}
                  {!isLoading && `, including ${pluralize(invoices.length, 'invoice')}`}
                </em>
                .
              </ModalSubHeading>
              <ModalHeading>
                Send and check the first invoices of the created schedules, or go to
                <Link to="/billing">billing dashboard</Link>
              </ModalHeading>
            </ModalHeader>
            <TableContainerLayout
              setSearchQuery={setSearchQuery}
              isFetching={isLoading}
              rowsCount={dataForTable?.length}
              width="100%"
            >
              <InvoicesTable
                useOnePageSelectAllCheckbox={true}
                fixedSelectAllCheckbox={true}
                selectAllCheckboxXOffset={90}
                setSortBy={setSortBy}
                initialSortBy={sortBy}
                currency={currency}
                dataForTable={dataForTable}
                searchQuery={searchQuery}
                onInvoiceClick={openInvoiceModal}
              />
            </TableContainerLayout>
          </ContentWrapper>
        </FullscreenModal>
      </ModalContainer>
      <InvoicingScheduleModal />
    </>
  );
};
