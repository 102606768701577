import { createFilter } from 'react-select';
import { SEARCH_CONFIDENCE } from 'utils/stringUtils';
import { FormikCustomSelector } from 'components/Controls';
import { getFuzzyCellData } from 'shared/CsvUpload/CsvTable/getFuzzyCellData';

import { CustomSingleOption } from './columns';

export const renderPricingPlanCell = ({
  row,
  cell: { value },
  formCurrent,
  pricingPlans,
  fuzzyRowsMapper,
  setFuzzyRowsMapper,
}) => {
  const index = row.original.rowIndex;

  const pricingPlanOptions = pricingPlans.map((pricingPlan) => ({
    label: pricingPlan.name,
    value: pricingPlan.id,
  }));

  if (value && formCurrent && !fuzzyRowsMapper?.[index]?.pricing_plan_id?.fuzzySearchCompleted) {
    const {
      candidates: candidatesForMapper,
      fuzzyTooltip: fuzzyTooltipForMapper,
      confidence: confidenceForMapper,
      value: fuzzyValue,
    } = getFuzzyCellData({
      rowValue: value,
      dataset: pricingPlans,
      candidateValueKey: 'id',
      candidateTitleKey: 'name',
      columns: ['name', 'id'],
      maxScore: 0.5,
    });

    setFuzzyRowsMapper((prevMapper) => ({
      ...prevMapper,
      [index]: {
        ...(prevMapper?.[index] ?? {}),
        pricing_plan_id: {
          fuzzySearchCompleted: true,
          fuzzyTooltip: fuzzyTooltipForMapper,
          candidates: candidatesForMapper,
          confidence: confidenceForMapper,
        },
      },
    }));

    formCurrent?.setFieldValue(`[${index}].pricing_plan_id`, fuzzyValue);
  }

  const { fuzzyTooltip, confidence } = fuzzyRowsMapper?.[index]?.pricing_plan_id ?? {};

  return (
    <FormikCustomSelector
      height={32}
      placeholder="Select pricing plan..."
      minWidth={160}
      menuWidth={200}
      //optimization thing
      filterOption={createFilter({ ignoreAccents: false })}
      errorWithoutTooltip
      value={
        value
          ? {
              label: pricingPlans.find((plan) => plan.id === value)?.name ?? value,
              value: value,
            }
          : null
      }
      options={pricingPlanOptions}
      isClearable
      tooltipInputDisplay={fuzzyTooltip}
      hasWarning={confidence === SEARCH_CONFIDENCE.FUZZY}
      handleChange={(option) => {
        if (option) {
          formCurrent?.setFieldValue(`[${row.original.rowIndex}].pricing_plan_id`, option.value);
        } else {
          formCurrent?.setFieldValue(`[${row.original.rowIndex}].pricing_plan_id`, null);
        }
      }}
      name={`[${row.original.rowIndex}].pricing_plan_id`}
    />
  );
};

export const renderDiscountCodeNameCell = ({
  row,
  cell: { value },
  formCurrent,
  discountCodeNames,
  fuzzyRowsMapper,
  setFuzzyRowsMapper,
}) => {
  const index = row.original.rowIndex;

  const discountCodes = discountCodeNames.map((discountCodeName) => ({
    label: discountCodeName,
    value: discountCodeName,
  }));

  if (value && formCurrent && !fuzzyRowsMapper?.[index]?.name?.fuzzySearchCompleted) {
    const {
      candidates: candidatesForMapper,
      fuzzyTooltip: fuzzyTooltipForMapper,
      confidence: confidenceForMapper,
      value: fuzzyValue,
    } = getFuzzyCellData({
      rowValue: value,
      dataset: discountCodes,
      candidateValueKey: 'value',
      candidateTitleKey: 'label',
      columns: ['value'],
    });

    setFuzzyRowsMapper((prevMapper) => ({
      ...prevMapper,
      [index]: {
        ...(prevMapper?.[index] ?? {}),
        name: {
          fuzzySearchCompleted: true,
          fuzzyTooltip: fuzzyTooltipForMapper,
          candidates: candidatesForMapper,
          confidence: confidenceForMapper,
        },
      },
    }));

    formCurrent?.setFieldValue(`[${index}].name`, fuzzyValue);
  }

  const { fuzzyTooltip, confidence } = fuzzyRowsMapper?.[index]?.name ?? {};

  return (
    <FormikCustomSelector
      height={32}
      components={{ Option: CustomSingleOption }}
      placeholder="Select discount code..."
      minWidth={160}
      menuWidth={200}
      //optimization thing
      filterOption={createFilter({ ignoreAccents: false })}
      errorWithoutTooltip
      value={value ? { value: value, label: value } : null}
      options={discountCodes}
      isClearable
      creatable
      tooltipInputDisplay={fuzzyTooltip}
      hasWarning={confidence === SEARCH_CONFIDENCE.FUZZY}
      handleChange={(option) => {
        if (option) {
          formCurrent?.setFieldValue(`[${row.original.rowIndex}].name`, option.value);
        } else {
          formCurrent?.setFieldValue(`[${row.original.rowIndex}].name`, null);
        }
      }}
      name={`[${row.original.rowIndex}].name`}
    />
  );
};
