import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import mixpanel from 'mixpanel-browser';
import config from 'config';
import { useUserAPI } from 'api/users';

const trackEventOnMixpanel = ({ client, name, userId, properties }) => {
  client.track(name, {
    distinct_id: userId,
    ...properties,
  });
};

export const useAnalytics = () => {
  const { user } = useAuth0();
  const userId = user?.email;
  const { data: userData } = useUserAPI({ email: userId });
  const [destinations, setDestinations] = useState([]);

  useEffect(() => {
    const currentDestinations = [];

    if (config.app.MIXPANEL_TOKEN) {
      mixpanel.init(config.app.MIXPANEL_TOKEN);
      currentDestinations.push({ client: mixpanel, trackEventFn: trackEventOnMixpanel });
    }

    setDestinations(currentDestinations);
  }, [userId]);

  const trackEvent = ({ name, properties = {} }) => {
    const decoratedProperties = {
      ...properties,
      source: 'webapp',
      isInternal: userData?.is_internal ?? false,
    };

    destinations.forEach(({ client, trackEventFn }) => {
      if (client) {
        trackEventFn({ client, name, userId, properties: decoratedProperties });
      }
    });
  };

  return { trackEvent };
};
