import { useContext } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { AppContext } from 'AppContext';
import { getAppSetting } from 'models/appSettings';
import { FlexEndContainer, Row } from 'components/Core';
import { NUMBER_FORMATS } from 'consts/global';
import { RESOLUTION_OPTIONS } from 'shared/Filters';
import { getEndOfPeriodDayJS, periodKeyToDayJS } from 'utils/dateUtils';
import { useCurrencyNumberFormatter } from 'utils/hooks';
import { NULL_SEGMENT_STRING_TIMESERIES } from 'shared/Filters/MetadataFilter/consts';
import { dataFilterSegmentKeyToDatabaseSegmentKey } from 'shared/MetadataSegmentsModal/utils';
import TableTile from './TableTile';
import { TileValue } from './styles';
import { CohortsContext } from '../CohortsContext';
import { INSTALL_BY_OPTIONS, COHORTS_DATA_KEYS } from '../consts';
import {
  getHeatLevel,
  periodTypeFromCohortResolution,
  getTooltipByInstallationType,
  getCohortRowLabel,
  calculateRowHeatLevels,
  getFormatType,
} from '../utils';

const TableBodyRow = styled.div`
  font-size: ${(props) => props.rowType === 'segmented' && '12px'};
  line-height: ${(props) => props.rowType === 'segmented' && '16px'};
  display: flex;
  width: fit-content;
  height: ${({ hasDoubleValues }) => (hasDoubleValues ? '58px' : '47px')};

  margin-bottom: ${(props) => props.isLastRow && '8px'};
`;

const LeftTitlesGroup = styled.div`
  display: flex;
  border: 1px solid var(--primaryBlack5);
  border-bottom: none;
  border-top: ${(props) => props.segmentCell && 'none'};
  margin-right: 12px;
  position: sticky;
  left: -180px;
  z-index: 10;
  font-size: 12px;
  line-height: 16px;

  border-top-left-radius: ${(props) => props.isFirstRow && !props.segmentCell && '8px'};
  border-top-right-radius: ${(props) => props.isFirstRow && !props.segmentCell && '8px'};
  border-bottom-left-radius: ${(props) => props.isLastRow && !props.segmentCell && '8px'};
  border-bottom-right-radius: ${(props) => props.isLastRow && !props.segmentCell && '8px'};
  border-bottom: ${(props) =>
    ((props.isLastRow && !props.isCohortSegmented) || (props.isLastRow && props.segmentCell)) &&
    '1px solid var(--primaryBlack5)'};

  & > div:first-child {
    border-top-left-radius: ${(props) => props.isFirstRow && '8px'};
    border-bottom-left-radius: ${(props) => props.isLastRow && '8px'};
    background: white;
    z-index: 10;
    position: sticky;
    left: 0;

    ${FlexEndContainer} {
      justify-content: flex-start;
    }
  }
`;

const RightValuesGroup = styled(Row)`
  padding: 3px 0px;
`;

export const CohortTableRow = ({
  isFirstRow,
  isLastRow,
  cohortData,
  secondCohortData = {},
  startingCountFluctuations = {},
  cohortTabName,
  isSegmentCell,
  cohortRowName,
  cohortSegmentBy = null,
  cohortSegmentKey = null,
  setShowSpreadsModal,
  setCohortStartDate,
  setCohortEndDate,
  setSelectedMonth,
  setCohortCustomerIds,
  setSegmentDescriptor,
  absoluteHeatLevels,
  segmentTooltips,
}) => {
  const { organizations } = useContext(AppContext);

  const { isARR, absoluteColoring, totalAmounts, cohortResolution, dataFilter } = useContext(CohortsContext);
  const numberFormatter = useCurrencyNumberFormatter();

  const { cohortInstallBy, cohortInstallSecondBy } = dataFilter;
  const quarters = getAppSetting('quarters');
  const quartersYearOffset = getAppSetting('quartersYearOffset');

  const periodType = periodTypeFromCohortResolution(cohortResolution);

  const cohortDateDayjs = periodKeyToDayJS({ periodKey: cohortRowName, periodType, quarters, quartersYearOffset });

  const currentPeriod = getEndOfPeriodDayJS({
    date: dayjs.utc().startOf('month'),
    periodType,
    quarters,
    quartersYearOffset,
  });

  const totalOrAverageKey = totalAmounts ? COHORTS_DATA_KEYS.TOTAL : COHORTS_DATA_KEYS.AVERAGE;

  const cohortDisplayRowName = getCohortRowLabel({
    cohortTabName,
    cohortRowName,
    cohortSegmentBy,
    cohortSegmentKey,
    cohortResolution,
    products: organizations?.[0]?.products,
  });

  // calculated with MRR
  let heatLevelsObject = absoluteHeatLevels;
  if (!absoluteColoring) {
    heatLevelsObject = {};

    const result = calculateRowHeatLevels({
      installBy: cohortInstallBy,
      rowData: cohortData,
      totalAmounts,
      segmentBy: cohortSegmentBy,
    });
    heatLevelsObject.heatLevels = result.heatLevels;
    heatLevelsObject.maxValue = result.maxValue;

    if (cohortInstallSecondBy) {
      const secondResult = calculateRowHeatLevels({
        installBy: cohortInstallSecondBy,
        rowData: secondCohortData,
        totalAmounts,
        segmentBy: cohortSegmentBy,
      });
      heatLevelsObject.secondHeatLevels = secondResult.heatLevels;
      heatLevelsObject.secondMaxValue = secondResult.maxValue;
    }
  }

  const metadataSegmentKey = dataFilterSegmentKeyToDatabaseSegmentKey(cohortSegmentBy);
  const customSegmentTooltip = segmentTooltips?.[metadataSegmentKey]?.[cohortDisplayRowName];

  return (
    <TableBodyRow
      rowType={cohortTabName}
      key={cohortTabName + cohortRowName + cohortSegmentKey}
      isLastRow={isLastRow}
      isFirstRow={isFirstRow}
      hasDoubleValues={cohortInstallSecondBy}
    >
      <LeftTitlesGroup
        isCohortSegmented={cohortSegmentBy}
        segmentCell={isSegmentCell}
        isLastRow={isLastRow}
        isFirstRow={isFirstRow}
      >
        {/* cohort date */}
        <TableTile
          value={cohortDisplayRowName.length < 9 ? cohortDisplayRowName : `${cohortDisplayRowName.substring(0, 9)}...`}
          toolTipText={
            cohortDisplayRowName === NULL_SEGMENT_STRING_TIMESERIES
              ? "Customers who don't have a value for the month"
              : customSegmentTooltip
              ? customSegmentTooltip
              : cohortDisplayRowName.length >= 9
              ? cohortDisplayRowName
              : null
          }
          padding="14px"
          left={true}
        />

        {/* starting customer count */}
        <TableTile
          value={numberFormatter({ type: NUMBER_FORMATS.NUMBER, rawValue: cohortData.startingCustomerCount })}
          padding="14px"
          fluctuate={startingCountFluctuations[cohortRowName]}
          description={`${cohortDateDayjs.format('YYYY-MM')}--starting-customer-count${
            isSegmentCell ? `--segment-${cohortSegmentKey}` : ''
          }`}
        />

        {/* revenue retention */}
        <TableTile
          padding="14px"
          value={
            cohortData.startingCustomerCount === 0
              ? 'N/A'
              : numberFormatter({ type: NUMBER_FORMATS.PERCENT, rawValue: cohortData.revenueRetention })
          }
        />
      </LeftTitlesGroup>
      <RightValuesGroup>
        {cohortData[totalOrAverageKey].map((value, index) => {
          const columnDate = cohortDateDayjs.add(index, periodType);
          const isLastColumn = columnDate.isSame(currentPeriod, periodType);

          if (columnDate <= currentPeriod) {
            const handleClick = () => {
              setShowSpreadsModal(true);
              setCohortStartDate(
                dayjs.utc(cohortDateDayjs).subtract(1, periodType).add(1, 'month').startOf('month').format('YYYY-MM-D'),
              ); // cohortDateDayjs represents the start of the last month of the period type
              setCohortEndDate(dayjs.utc(cohortDateDayjs).endOf('month').format('YYYY-MM-D'));
              setSelectedMonth(cohortResolution === RESOLUTION_OPTIONS.monthly ? index : null); //When index === 0, that is the first month
              if (isSegmentCell) {
                setCohortCustomerIds(cohortData.customers);
                setSegmentDescriptor(`${cohortSegmentBy}: ${cohortSegmentKey}`);
              } else {
                setCohortCustomerIds(null);
                setSegmentDescriptor(null);
              }
            };

            // this is in MRR
            const tileValue = cohortData[totalOrAverageKey][index];
            let secondTileValue;
            if (cohortInstallSecondBy) {
              secondTileValue = secondCohortData[totalOrAverageKey][index];
            }

            return (
              <TableTile
                value={
                  <TileValue
                    active
                    segmentCell={isSegmentCell}
                    onClick={setShowSpreadsModal && handleClick}
                    data-cy={`${cohortDateDayjs.format('YYYY-MM')}-cohort-table-value${
                      isSegmentCell ? `--segment-${cohortSegmentKey}` : ''
                    }`}
                  >
                    {numberFormatter({
                      type: getFormatType(cohortInstallBy),
                      rawValue:
                        isARR && cohortInstallBy === INSTALL_BY_OPTIONS.revenueDollar ? tileValue * 12 : tileValue,
                    })}
                  </TileValue>
                }
                secondaryValue={
                  cohortInstallSecondBy && (
                    <TileValue
                      active
                      segmentCell={isSegmentCell}
                      isSecondaryValue
                      onClick={setShowSpreadsModal && handleClick}
                      data-cy={`${cohortDateDayjs.format('YYYY-MM')}-cohort-table-value${
                        isSegmentCell ? `--segment-${cohortSegmentKey}` : ''
                      }`}
                    >
                      {numberFormatter({
                        type: getFormatType(cohortInstallSecondBy),
                        rawValue:
                          isARR && cohortInstallSecondBy === INSTALL_BY_OPTIONS.revenueDollar
                            ? secondTileValue * 12
                            : secondTileValue,
                      })}
                    </TileValue>
                  )
                }
                toolTipText={getTooltipByInstallationType(cohortInstallBy, totalAmounts)}
                secondaryToolTipText={getTooltipByInstallationType(cohortInstallSecondBy, totalAmounts)}
                isLastColumn={isLastColumn}
                primary={true}
                heatLevel={getHeatLevel({
                  value: tileValue,
                  maxValue: heatLevelsObject.maxValue,
                  installBy: cohortInstallBy,
                  heatLevels: heatLevelsObject.heatLevels,
                })}
                secondaryHeatLevel={
                  cohortInstallSecondBy &&
                  getHeatLevel({
                    value: secondTileValue,
                    maxValue: heatLevelsObject.secondMaxValue,
                    installBy: cohortInstallSecondBy,
                    heatLevels: heatLevelsObject.secondHeatLevels,
                  })
                }
                key={index}
              />
            );
          } else {
            const tableTileToolTip = isLastColumn
              ? "This data is incomplete, because the period isn't complete"
              : 'This is total revenue for this cohort divided by number of starting customers in the cohort';
            return (
              <TableTile
                segmentCell={isSegmentCell}
                value={cohortInstallBy === INSTALL_BY_OPTIONS.revenueDollar ? '$0' : '0'}
                isLastColumn={isLastColumn}
                toolTipText={tableTileToolTip}
                primary={true}
                key={index}
              />
            );
          }
        })}
      </RightValuesGroup>
    </TableBodyRow>
  );
};
