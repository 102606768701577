import React, { useContext } from 'react';
import styled from 'styled-components';
import { useFormikContext } from 'formik';
import { AppContext } from 'AppContext';
import { DEFAULT_AVAILABLE_TAGS } from 'consts/billing';
import { INTEGRATION_SERVICES } from 'consts/integrations';
import { templateToHTMLString } from 'utils/htmlUtils';
import { BillingContext } from 'views/Billing/BillingContext';
import { InvoicingScheduleContext } from '../InvoicingScheduleModal/InvoicingScheduleContext';
import { TrashIcon, RefreshIcon } from 'components/Icons';
import {
  FILE_TYPES,
  FileUploader,
  FormikCustomInput,
  FormikEmailSelector,
  SwitchWithLabel,
  formatFileSize,
} from 'components/Controls';
import { CentererVertical, Flexer, FlexerColumn, FlexerRow, LabelText } from 'components/Core';
import { TooltipContainer } from 'components/Tooltip';
import { CircleLoader } from 'components/Loaders';
import { FileCard } from 'views/Configuration/Tabs/SubscriptBillingTab/styles';
import { ReactComponent as FileDownloadIcon } from 'images/file-download.svg';
import { getServiceCategory } from 'models/integration';
import { getModalInvoiceStatus } from '../InvoiceModal/utils';
import { BillingDynamicFieldsTooltip, DYNAMIC_FIELD_TOOLTIP_SECTIONS } from '../BillingDynamicFieldsTooltip';
import { INVOICE_STATUSES, SERVICE_WITHOUT_EMAIL_MODIFICATIONS, SERVICE_WITHOUT_INVOICE_SEND_EMAIL } from '../consts';
import { getConfigSenderEmail, shouldSendEmailFromSubscript } from '../utils';
import { InvoiceSenderEmail } from './InvoiceSenderEmail';

const FadedText = styled(Flexer)`
  color: var(--primaryBlack50);
  font-weight: 700;
  font-size: 12px;
  gap: 4px;
  align-items: center;
`;

const HighlightedText = styled(FadedText)`
  color: var(--primaryBlue);
  align-items: center;

  &:hover {
    cursor: pointer;
    &:hover {
      svg path {
        fill: var(--primaryBlue);
      }
    }
  }
`;

const Dot = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: white;
  margin-top: 6px;
`;

const StyledFileDownloadIcon = styled(FileDownloadIcon)``;

export const InvoiceDetailsForm = ({ invoice: _invoice, invoicePDFDownload, isPdfLoading, isResend } = {}) => {
  const { orgConfigs } = useContext(AppContext);
  const { resetInvoiceEmailSubject, resetInvoiceEmailBody } = useContext(BillingContext);
  const {
    OpenReplacedTemplateVariablesModalButton,
    transactionMetadataTags,
    customerMetadataTags,
    invoicingServiceDisplayName,
    invoicingService,
  } = useContext(InvoicingScheduleContext);
  const { setFieldValue: setInvoiceFieldValue, values } = useFormikContext();

  const invoice = _invoice ?? values;

  const { billingInvoiceDefaults } = orgConfigs;

  const invoiceStatus = invoice ? getModalInvoiceStatus({ invoice }) : '';
  const sendEmailFromSubscript = shouldSendEmailFromSubscript({ orgConfigs });
  const senderEmail =
    sendEmailFromSubscript || invoice.auto_charge
      ? getConfigSenderEmail({ orgConfigs })
      : SERVICE_WITHOUT_EMAIL_MODIFICATIONS.includes(getServiceCategory(invoicingService))
      ? `Sender email set in ${invoicingServiceDisplayName}`
      : invoice.sender;

  const isXeroSender =
    !(sendEmailFromSubscript || invoice.auto_charge) &&
    getServiceCategory(invoicingService) === INTEGRATION_SERVICES.XERO;

  const showEmailModifications =
    sendEmailFromSubscript || !SERVICE_WITHOUT_EMAIL_MODIFICATIONS.includes(getServiceCategory(invoicingService));
  const integrationAllowsSendingEmail =
    sendEmailFromSubscript || !SERVICE_WITHOUT_INVOICE_SEND_EMAIL.includes(getServiceCategory(invoicingService));

  const defaultInvoiceAttachments = [{ name: 'Invoice.pdf' }, ...(billingInvoiceDefaults.attachments ?? [])];
  const handleAddInvoiceAttachment = (file) => {
    setInvoiceFieldValue('attachments', [
      ...(invoice.attachments ?? []),
      {
        name: file.name,
        s3_file_key: file.s3FileKey,
        size: file.size,
        type: file.type,
      },
    ]);
  };
  const handleDeleteInvoiceAttachment = (index) => {
    const attachments = [...(invoice.attachments ?? [])];
    attachments.splice(index, 1);
    setInvoiceFieldValue('attachments', attachments);
  };

  const availableTags = [...DEFAULT_AVAILABLE_TAGS, ...transactionMetadataTags, ...customerMetadataTags];

  if (!integrationAllowsSendingEmail) {
    return (
      <FlexerColumn width="100%" height="100%">
        You need to send the invoice manually through {invoicingServiceDisplayName} or activate sending emails from
        Subscript in the Invoice Settings.
      </FlexerColumn>
    );
  }

  const isReadOnly =
    [
      INVOICE_STATUSES.PAID,
      INVOICE_STATUSES.VOID,
      INVOICE_STATUSES.SENT,
      INVOICE_STATUSES.PROCESSING,
      INVOICE_STATUSES.QUEUED_UP,
    ].includes(invoiceStatus) && !isResend;

  return (
    <FlexerColumn width="100%" height="100%" gap="16px" alignItems="flex-start">
      <FlexerRow width="100%" gap="16px">
        <FormikEmailSelector
          containerWidth="100%"
          name="email_addresses"
          label="Recipient email(s)"
          value={invoice.email_addresses?.filter((e) => typeof e === 'string')} // Strip out any non-string values
          onChange={(selectedValues) => setInvoiceFieldValue('email_addresses', selectedValues)}
          isDisabled={isReadOnly}
        />
      </FlexerRow>

      <FlexerRow width="100%" gap="16px">
        <TooltipContainer
          toolTipContent="Xero does not support CC recipients when sending invoices."
          tooltipWrapperStyles={{ width: '100%' }}
          width={200}
          fontSize="12px"
          isVisible={isXeroSender}
          hideArrow
        >
          <FormikEmailSelector
            containerWidth="100%"
            name="email_cc"
            label="Email(s) to cc"
            value={invoice.email_cc ?? billingInvoiceDefaults?.email_CC}
            onChange={(selectedValues) => setInvoiceFieldValue('email_cc', selectedValues)}
            isDisabled={isReadOnly || isXeroSender}
          />
        </TooltipContainer>
      </FlexerRow>

      {showEmailModifications && (
        <FlexerRow width="100%" gap="16px">
          <FormikCustomInput
            data-cy="billing__invoice-modal__email-subject"
            width="100%"
            name="email_subject"
            disableLabelOpacity
            label={
              <CentererVertical gap="8px">
                <span>Email subject</span>
                <OpenReplacedTemplateVariablesModalButton />
              </CentererVertical>
            }
            LabelAction={
              <CentererVertical gap="8px">
                <TooltipContainer toolTipContent="Reset email subject" width={150}>
                  <RefreshIcon onClick={() => resetInvoiceEmailSubject({ invoice })} />
                </TooltipContainer>
                <BillingDynamicFieldsTooltip
                  tags={{
                    [DYNAMIC_FIELD_TOOLTIP_SECTIONS.GENERAL_TAGS]: DEFAULT_AVAILABLE_TAGS,
                    [DYNAMIC_FIELD_TOOLTIP_SECTIONS.TRANSACTION_METADATA_TAGS]: transactionMetadataTags,
                    [DYNAMIC_FIELD_TOOLTIP_SECTIONS.CUSTOMER_METADATA_TAGS]: customerMetadataTags,
                  }}
                  XOffset={100}
                />
              </CentererVertical>
            }
            minHeight="40px"
            HTMLString={templateToHTMLString({
              text: invoice?.email_subject,
              availableTags,
            })}
            availableTags={availableTags}
            type="HTMLEdit"
            isDisabled={isReadOnly}
          />
        </FlexerRow>
      )}

      <FlexerRow width="100%" gap="16px">
        {showEmailModifications && (
          <FlexerColumn width="100%" height="100%" gap="16px">
            <FormikCustomInput
              data-cy="billing__invoice-modal__email-body"
              name="email_body"
              disableLabelOpacity
              label={
                <CentererVertical gap="8px">
                  <span>Email body</span>
                  <OpenReplacedTemplateVariablesModalButton dataCy="billing__invoice-modal__email-body__preview" />
                </CentererVertical>
              }
              LabelAction={
                <CentererVertical gap="8px">
                  <TooltipContainer toolTipContent="Reset email body" width={150}>
                    <RefreshIcon onClick={() => resetInvoiceEmailBody({ invoice })} />
                  </TooltipContainer>
                  <BillingDynamicFieldsTooltip
                    tags={{
                      [DYNAMIC_FIELD_TOOLTIP_SECTIONS.GENERAL_TAGS]: DEFAULT_AVAILABLE_TAGS,
                      [DYNAMIC_FIELD_TOOLTIP_SECTIONS.TRANSACTION_METADATA_TAGS]: transactionMetadataTags,
                      [DYNAMIC_FIELD_TOOLTIP_SECTIONS.CUSTOMER_METADATA_TAGS]: customerMetadataTags,
                    }}
                  />
                </CentererVertical>
              }
              minHeight="240px"
              HTMLString={templateToHTMLString({
                text: invoice?.email_body,
                availableTags,
              })}
              availableTags={availableTags}
              type="HTMLEdit"
              isDisabled={isReadOnly}
            />
          </FlexerColumn>
        )}

        <FlexerColumn width="320px" gap="16px">
          <InvoiceSenderEmail senderEmail={invoice?.sender ? senderEmail : undefined} />

          {(invoice?.sent_at || sendEmailFromSubscript || invoice.auto_charge) && (
            <FlexerColumn>
              <LabelText>
                Attachments ({(defaultInvoiceAttachments?.length ?? 0) + (invoice.attachments?.length ?? 0)})
              </LabelText>
              <FlexerColumn gap="8px">
                {!isReadOnly && (
                  <FileUploader
                    onFileUpload={handleAddInvoiceAttachment}
                    fileTypes={FILE_TYPES.ALL}
                    maxFileSize={10485760}
                    smallView
                    infoText={`Attach files (${formatFileSize(10485760)})`}
                    subInfoText="click or drag&drop"
                    showFileUploaded={false}
                    privateBucket
                  />
                )}

                {invoice?.auto_charge
                  ? invoicePDFDownload && (
                      <FileCard disableIconHover>
                        {isPdfLoading ? (
                          <CircleLoader width="16px" height="16px" />
                        ) : (
                          <>
                            <FlexerColumn>
                              <FadedText>
                                Attach invoice{' '}
                                <HighlightedText onClick={invoicePDFDownload}>
                                  PDF <StyledFileDownloadIcon />
                                </HighlightedText>{' '}
                              </FadedText>

                              {billingInvoiceDefaults.attachments && billingInvoiceDefaults.attachments.length > 0 && (
                                <FadedText>
                                  and{' '}
                                  <TooltipContainer
                                    toolTipContent={
                                      <FlexerColumn gap="4px">
                                        {billingInvoiceDefaults.attachments?.map((attachment) => (
                                          <Flexer gap="8px" key={attachment.s3_file_key ?? attachment.name}>
                                            <Dot />
                                            <span
                                              style={{
                                                fontSize: 12,
                                                textAlign: 'left',
                                              }}
                                            >
                                              {attachment.name}
                                            </span>
                                          </Flexer>
                                        ))}
                                      </FlexerColumn>
                                    }
                                    width={250}
                                  >
                                    <HighlightedText> default attachments</HighlightedText>
                                  </TooltipContainer>
                                </FadedText>
                              )}
                            </FlexerColumn>

                            <SwitchWithLabel
                              checked={!invoice?.metadata?.exclude_invoice_pdf}
                              onChange={() =>
                                setInvoiceFieldValue('metadata', {
                                  ...invoice?.metadata,
                                  exclude_invoice_pdf: !invoice?.metadata?.exclude_invoice_pdf,
                                })
                              }
                            />
                          </>
                        )}
                      </FileCard>
                    )
                  : defaultInvoiceAttachments?.map((attachment, index) => (
                      <FileCard key={attachment.name}>
                        <span>
                          {attachment.name} {index !== 0 ? `(${formatFileSize(attachment.size)})` : ''}
                        </span>
                      </FileCard>
                    ))}
                {invoice.attachments?.map((attachment, index) => (
                  <FileCard key={attachment.name}>
                    <span>
                      {attachment.name} {`(${formatFileSize(attachment.size)})`}
                    </span>
                    {(!invoice.sent_at || isReadOnly) && (
                      <TrashIcon
                        size="16px"
                        fill="var(--primaryBlack)"
                        onClick={() => handleDeleteInvoiceAttachment(index)}
                      />
                    )}
                  </FileCard>
                ))}
              </FlexerColumn>
            </FlexerColumn>
          )}
        </FlexerColumn>
      </FlexerRow>
    </FlexerColumn>
  );
};
