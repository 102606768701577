import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { isNil } from 'lodash';
import styled from 'styled-components';

import { ISO_CODE_TO_SYMBOL } from 'consts/global';
import { FlexEndContainer } from 'components/Core';
import { FormikCustomInput } from 'components/Controls';
import { TooltipContainer } from 'components/Tooltip';
import {
  Modal,
  ModalBody,
  ModalButton,
  ModalCloseIcon,
  ModalContainer,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from 'components/Modal';
import { Row } from 'components/Core';

const AmountInput = styled.div`
  max-width: 140px;
  margin-left: 20px;
`;

const ModalSubtitle = styled.div`
  font-size: 14px;
  line-height: 20px;
  opacity: 0.4;
  margin-right: auto;
  margin-left: 50px;
  margin-top: 4px;
  margin-bottom: 24px;
`;

const FormRow = styled(Row)`
  margin-bottom: 30px;

  > * {
    width: 100%;
  }
`;

export const CustomersSizeSegmentsModal = ({
  currency,
  isARR,
  selectedSegment,
  displaySegments,
  setSegments,
  setShowCreateSegmentModal,
}) => (
  <ModalContainer>
    <Formik
      initialValues={
        selectedSegment !== undefined
          ? {
              ...displaySegments[selectedSegment],
              amount: displaySegments[selectedSegment].amount
                ? displaySegments[selectedSegment].amount / (isARR ? 1 : 12)
                : null,
            }
          : {
              name: '',
              amount: null,
            }
      }
      validationSchema={Yup.object({
        name: Yup.string().required('Name is required'),
        amount: Yup.number().nullable(),
      })}
      onSubmit={(values) => {
        const newSegments = [...displaySegments];
        if (selectedSegment !== undefined) {
          // removes the old value
          newSegments.splice(selectedSegment, 1);
        }
        // adds in the new value
        newSegments.push({
          ...values,
          amount: values.amount ? values.amount * (isARR ? 1 : 12) : null,
        });
        // in the case where there are 2 options with null as the value, we don't do anything
        if (newSegments.filter((s) => s.amount === null).length < 2) {
          setSegments(newSegments);
        }
        setShowCreateSegmentModal(false);
      }}
    >
      {({ submitForm, values }) => {
        const isLastSegment = Boolean(
          selectedSegment && selectedSegment === displaySegments.length - 1 && isNil(values.amount),
        );
        return (
          <Modal maxWidth="540px" height="auto" overflow="visible">
            <ModalHeader>
              <ModalCloseIcon onClose={() => setShowCreateSegmentModal(false)} width={36} height={36} />
              <ModalTitle>Create Segment</ModalTitle>
            </ModalHeader>
            <ModalSubtitle>Manually add segment data</ModalSubtitle>
            <ModalBody>
              <FormRow>
                <FormikCustomInput
                  data-cy="wizard__segment-name-input"
                  name="name"
                  label="Name"
                  placeholder="Enter name"
                />
                <AmountInput>
                  <TooltipContainer
                    width={150}
                    isVisible={isLastSegment}
                    toolTipContent="Last segment is always infinite"
                  >
                    <FormikCustomInput
                      isDisabled={isLastSegment}
                      data-cy="wizard__segment-amount-input"
                      suffix={ISO_CODE_TO_SYMBOL[currency] ?? '$'}
                      name="amount"
                      type="number"
                      label={`Upper bound of ${isARR ? 'ARR' : 'MRR'}`}
                      placeholder="Enter amount"
                    />
                  </TooltipContainer>
                </AmountInput>
              </FormRow>
            </ModalBody>
            <ModalFooter padding="12px 21px">
              <FlexEndContainer>
                <ModalButton onClick={() => setShowCreateSegmentModal(false)}>Cancel</ModalButton>
                <ModalButton data-cy="wizard__save-segment" onClick={() => submitForm()} className="primary">
                  Save
                </ModalButton>
              </FlexEndContainer>
            </ModalFooter>
          </Modal>
        );
      }}
    </Formik>
  </ModalContainer>
);
