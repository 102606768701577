import React, { useContext } from 'react';
import { AppContext } from 'AppContext';
import { ReactComponent as ChartDonutIcon } from 'images/chart-donut-4.svg';
import { Table } from 'views/Spreads/Table';
import { Flexer } from 'components/Core';
import { DATA_TYPES } from 'shared/Filters/utils';
import { CardChartModalContext } from './CardChartModalContext';
import { CardChartSectionIcon, CardChartSectionTitle } from './styled';

export const RetentionSection = () => {
  const { organizations } = useContext(AppContext);
  const { metric, title, isTTM, tableData, refetch } = useContext(CardChartModalContext);

  return (
    tableData && (
      <Flexer gap="12px" direction="column">
        <Flexer gap="12px" alignItems="center">
          <CardChartSectionIcon>
            <ChartDonutIcon width="20px" height="20px" />
          </CardChartSectionIcon>
          <CardChartSectionTitle>SaaS Revenue Spread</CardChartSectionTitle>
        </Flexer>
        <Table
          spreadsData={tableData.data}
          shownCustomers={tableData.shownCustomers}
          organizations={organizations}
          fetchSpreadsData={refetch}
          isRetentionMetric={true}
          retentionMetricKey={metric.monthDataKey}
          isTTM={isTTM}
          retentionMetricTitle={title}
          dataTypeToShow={DATA_TYPES.revenue} // not seats
        />
      </Flexer>
    )
  );
};
