import { Loader } from 'components/Loaders';
import styled from 'styled-components';

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 300px;
  width: 100%;
`;

export const LoadingTable = () => (
  <Wrapper>
    <Loader containerStyles={{ width: 40 }} />
  </Wrapper>
);
