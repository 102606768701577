export const getLoadTime = ({
  pageName,
  transactionCount,
  customersCount,
  includeTTM,
  optimisticAnalytics,
  isCommitted,
  rollup,
  cohortSegmentBy,
  spreadSegmentBy,
  transactionsConfirmation,
}) => {
  let loadTime;
  let dashboardLoadCoefficient = 1;
  let waterfallLoadCoefficient = 1;
  let cohortsLoadCoefficient = 1;
  let revenueDetailsCoefficient = 1;
  let spreadsCoefficient = 1;
  let transactionsCoefficient = 1;

  if (includeTTM) dashboardLoadCoefficient *= 1.25;

  if (optimisticAnalytics) {
    dashboardLoadCoefficient *= 2;
    waterfallLoadCoefficient *= 1.2;
    cohortsLoadCoefficient *= 1.5;
    revenueDetailsCoefficient *= 1.2;
    spreadsCoefficient *= 1.2;
  }

  if (isCommitted) {
    dashboardLoadCoefficient *= 2;
    waterfallLoadCoefficient *= 2;
    cohortsLoadCoefficient *= 2;
    revenueDetailsCoefficient *= 2.5;
    spreadsCoefficient *= 2.5;
  }

  if (rollup) {
    waterfallLoadCoefficient *= 2;
  }

  if (cohortSegmentBy) {
    cohortsLoadCoefficient *= 1.3;
  }

  if (spreadSegmentBy) {
    spreadsCoefficient *= 1.4;
  }

  if (transactionsConfirmation === 'All transactions' || transactionsConfirmation === 'Confirmed') {
    transactionsCoefficient *= 2.5;
  }

  switch (pageName) {
    case 'dashboard':
    case 'forecasting':
      if (transactionCount < 5000) {
        loadTime = 2 * dashboardLoadCoefficient;
      } else if (transactionCount < 10000) {
        loadTime = 3 * dashboardLoadCoefficient;
      } else if (transactionCount < 23000) {
        loadTime = 5 * dashboardLoadCoefficient;
      } else {
        loadTime = 10 * dashboardLoadCoefficient;
      }
      break;
    case 'chart':
      if (transactionCount < 5000) {
        loadTime = 2 * dashboardLoadCoefficient;
      } else if (transactionCount < 10000) {
        loadTime = 3 * dashboardLoadCoefficient;
      } else if (transactionCount < 23000) {
        loadTime = 5 * dashboardLoadCoefficient;
      } else {
        loadTime = 10 * dashboardLoadCoefficient;
      }
      break;
    case 'waterfall':
      if (transactionCount < 5000) {
        loadTime = 2 * waterfallLoadCoefficient;
      } else if (transactionCount < 10000) {
        loadTime = 3 * waterfallLoadCoefficient;
      } else if (transactionCount < 23000) {
        loadTime = 5 * waterfallLoadCoefficient;
      } else {
        loadTime = 10 * waterfallLoadCoefficient;
      }
      break;
    case 'cohorts':
      if (transactionCount < 5000) {
        loadTime = 2 * cohortsLoadCoefficient;
      } else if (transactionCount < 10000) {
        loadTime = 4 * cohortsLoadCoefficient;
      } else if (transactionCount < 23000) {
        loadTime = 7 * cohortsLoadCoefficient;
      } else {
        loadTime = 12 * cohortsLoadCoefficient;
      }
      break;
    case 'revenue':
      if (customersCount < 5000) {
        loadTime = 3 * revenueDetailsCoefficient;
      } else if (customersCount < 10000) {
        loadTime = 5 * revenueDetailsCoefficient;
      } else if (customersCount < 23000) {
        loadTime = 7 * revenueDetailsCoefficient;
      } else {
        loadTime = 10 * revenueDetailsCoefficient;
      }
      break;
    case 'spreads':
      if (customersCount < 5000) {
        loadTime = 2 * spreadsCoefficient;
      } else if (customersCount < 10000) {
        loadTime = 3 * spreadsCoefficient;
      } else if (customersCount < 23000) {
        loadTime = 5 * spreadsCoefficient;
      } else {
        loadTime = 7 * spreadsCoefficient;
      }
      break;
    case 'transactions':
      if (transactionCount < 5000) {
        loadTime = 1 * transactionsCoefficient;
      } else if (transactionCount < 10000) {
        loadTime = 2 * transactionsCoefficient;
      } else if (transactionCount < 23000) {
        loadTime = 4 * transactionsCoefficient;
      } else {
        loadTime = 6 * transactionsCoefficient;
      }
      break;
    case 'transactionsCsv':
      loadTime = 2;
      break;
    case 'externalUpdates':
      loadTime = 2;
      break;
    case 'customers':
      if (customersCount < 5000) {
        loadTime = 2;
      } else if (customersCount < 10000) {
        loadTime = 4;
      } else if (customersCount < 23000) {
        loadTime = 6;
      } else {
        loadTime = 8;
      }
      break;
    default:
      loadTime = 1;
  }

  return Math.ceil(loadTime);
};
