import React, { useContext } from 'react';
import { Checkbox } from 'antd';
import dayjs from 'dayjs';
import styled from 'styled-components';

import { AppContext } from 'AppContext';
import { ReactComponent as SubrowArrow } from 'images/subrow-arrow.svg';
import { ReactComponent as CheckCircle } from 'images/transaction_check.svg';
import { TrashIcon } from 'components/Icons';
import { numberFormatter } from 'utils/formatters';
import { NUMBER_FORMATS } from 'consts/global';
import { Popover, PopoverActions, PopoverButton, PopoverPrompt, usePortal } from 'components/Portal';

import {
  InvoiceLink,
  InvoiceInfo,
  InvoiceDate,
  PaidDate,
  Wrapper,
  CheckBoxRow,
  StyledExternalLink,
  LineItemRow,
  LineItemTitleRow,
  LineItemAmount,
  TotalAmount,
} from './styles';

const StyledTrashIcon = styled(TrashIcon)`
  margin-right: 5px;
  opacity: 0.5;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
`;

export const IMPORT_INVOICES_TABLE_LIST_MODE = {
  SELECTABLE: 'selectable',
  DELETABLE: 'deletable',
  VIEW: 'view',
};

export const ImportInvoiceLine = ({
  externalInvoice,
  showCheckbox,
  isSelected,
  onInvoiceAdd,
  onInvoiceRemove,
  listMode = IMPORT_INVOICES_TABLE_LIST_MODE.SELECTABLE,
}) => {
  const {
    appSettings: { currencyISOCode: defaultOrgCurrency },
  } = useContext(AppContext);

  const { triggerRef, togglePortal, isPortalVisible, hidePortal, Portal } = usePortal({
    hideOnOutsideClick: true,
    YOffset: 46,
    XOffset: 20,
  });

  return (
    <Wrapper>
      <CheckBoxRow>
        {showCheckbox && listMode === IMPORT_INVOICES_TABLE_LIST_MODE.SELECTABLE && (
          <Checkbox
            checked={isSelected}
            onClick={() => (isSelected ? onInvoiceRemove && onInvoiceRemove() : onInvoiceAdd && onInvoiceAdd())}
            data-cy={`select-import-invoice__checkbox--${externalInvoice.external_id}`}
          />
        )}
        {!showCheckbox && (
          <StyledExternalLink onClick={() => window.open(externalInvoice.invoice_external_url, '_blank')} />
        )}
        {listMode === IMPORT_INVOICES_TABLE_LIST_MODE.DELETABLE && (
          <div ref={triggerRef}>
            <StyledTrashIcon onClick={togglePortal} />

            {isPortalVisible && (
              <Portal>
                <Popover darkMode width="200px">
                  <PopoverPrompt>
                    Are you sure you want to remove this invoice from Subscript (but keep it in your external system)?
                  </PopoverPrompt>
                  <PopoverActions>
                    <PopoverButton onClick={hidePortal}>No</PopoverButton>
                    <PopoverButton
                      data-cy="invoice-actions--detach--confirm-yes"
                      onClick={() => onInvoiceRemove && onInvoiceRemove()}
                      primary
                    >
                      Yes
                    </PopoverButton>
                  </PopoverActions>
                </Popover>
              </Portal>
            )}
          </div>
        )}
        <InvoiceInfo>
          <InvoiceLink onClick={() => window.open(externalInvoice.invoice_external_url, '_blank')}>
            <div>Invoice #{externalInvoice.invoice_number}</div>
            {showCheckbox && <StyledExternalLink />}
          </InvoiceLink>
        </InvoiceInfo>

        <InvoiceDate>{dayjs.utc(externalInvoice.date).format('MMM DD, YYYY')}</InvoiceDate>

        <PaidDate>
          {externalInvoice.paid_at && (
            <>
              <CheckCircle height="16px" />
              Paid: <span>{dayjs.utc(externalInvoice.paid_at).format('MMM DD, YYYY')}</span>
            </>
          )}
        </PaidDate>

        <TotalAmount>
          {numberFormatter({
            type: NUMBER_FORMATS.CURRENCY,
            rawValue: externalInvoice?.amount ?? externalInvoice?.Total,
            decimalPlaces: 2,
            currency: externalInvoice.currency ?? defaultOrgCurrency,
          })}
        </TotalAmount>
      </CheckBoxRow>
      {externalInvoice.invoice_items?.map((lineItem, index) => (
        <React.Fragment key={(lineItem.name ?? lineItem.description) + index}>
          <LineItemRow horizontal="flex-start">
            <LineItemTitleRow>
              <SubrowArrow style={{ opacity: 0.2 }} />
              {lineItem.name || lineItem.description || lineItem.type}

              <LineItemAmount>
                {numberFormatter({
                  type: NUMBER_FORMATS.CURRENCY,
                  rawValue: lineItem?.amount,
                  decimalPlaces: 2,
                  currency: externalInvoice.currency ?? defaultOrgCurrency,
                })}
              </LineItemAmount>
            </LineItemTitleRow>
          </LineItemRow>

          {lineItem.metadata?.bundleItems?.map((bundleItem, i) => (
            <LineItemRow horizontal="flex-start" key={bundleItem.id + lineItem?.name + i}>
              <LineItemTitleRow margin="0 16px 0 16px">
                <SubrowArrow style={{ opacity: 0.2 }} />
                {bundleItem.name || bundleItem.description}

                <LineItemAmount>
                  {numberFormatter({
                    type: NUMBER_FORMATS.CURRENCY,
                    rawValue: (bundleItem?.unit_price ?? 0) * (bundleItem?.seats ?? 0),
                    decimalPlaces: 2,
                    currency: externalInvoice.currency ?? defaultOrgCurrency,
                  })}
                </LineItemAmount>
              </LineItemTitleRow>
            </LineItemRow>
          ))}
        </React.Fragment>
      ))}
    </Wrapper>
  );
};
