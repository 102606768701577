import axios from 'axios';
import { API_URL } from 'api/consts';

const buildUrl = ({ orgId }) => {
  const baseUrl = `${API_URL}/organizations/${orgId}/retention`;
  return baseUrl;
};

export const getRetentionBreakdown = async ({ orgId, params }) => {
  const response = await axios.get(buildUrl({ orgId }), { params });
  return response.data;
};
