import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { NUMBER_FORMATS } from 'consts/global';
import { getCustomerDisplayName } from 'models/customer';
import { InlineButton } from 'components/Buttons';
import { ModalContainer, Modal, ModalBody, ModalCloseIcon } from 'components/Modal';
import { numberFormatter } from 'utils/formatters';
import { useClickOutside } from 'utils/hooks';
import { EventName } from './styles';

const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 900;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 20px;
`;

const StyledModalBody = styled(ModalBody)`
  padding: 0 36px 36px 36px;
`;

const Field = styled.div`
  margin-bottom: 8px;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0em;
`;

const FieldLabel = styled.div`
  opacity: 0.4;
  margin-bottom: 8px;
`;

const FieldValue = styled.div`
  opacity: 1;
`;

export const UsageEventViewModal = ({ onClose, usageEvent }) => {
  const modalRef = useClickOutside(onClose);

  return (
    <ModalContainer zIndex={49}>
      <Modal
        overflow="visible"
        width="440px"
        minHeight="500px"
        data-cy="usage-events-view-modal"
        height="auto"
        maxHeight="auto"
        ref={modalRef}
      >
        <ModalCloseIcon onClose={onClose} />
        <StyledModalBody>
          <ModalTitle>Usage Event</ModalTitle>
          <Field>
            <FieldLabel>Customer</FieldLabel>
            <InlineButton onClick={() => window.open(`/customers/${usageEvent.customer_id}`, '_blank')} isSecondary>
              {getCustomerDisplayName({ customer: usageEvent.customer })}
            </InlineButton>
          </Field>
          <Field>
            <FieldLabel>Event name</FieldLabel>
            <EventName>{usageEvent.name}</EventName>
          </Field>
          <Field>
            <FieldLabel>Units</FieldLabel>
            <FieldValue>
              {numberFormatter({
                type: NUMBER_FORMATS.NUMBER,
                rawValue: usageEvent.units ?? 1,
              })}
            </FieldValue>
          </Field>
          <Field>
            <FieldLabel>Source</FieldLabel>
            <FieldValue>{usageEvent.source}</FieldValue>
          </Field>
          <Field>
            <FieldLabel>Notes</FieldLabel>
            <FieldValue>{usageEvent.notes}</FieldValue>
          </Field>
          <Field>
            <FieldLabel>Created at</FieldLabel>
            <FieldValue>{dayjs.utc(usageEvent.timestamp).format('L [at] mm:ss A')}</FieldValue>
          </Field>
        </StyledModalBody>
      </Modal>
    </ModalContainer>
  );
};

export const useUsageEventViewModal = () => {
  const [openParams, setOpenParams] = useState({});
  const [showModal, setShowModal] = useState(false);
  const openModal = useCallback(
    (openParams = {}) => {
      setOpenParams(openParams);
      setShowModal(true);
    },
    [setShowModal],
  );
  const closeModal = useCallback(() => setShowModal(false), [setShowModal]);

  const Modal = useCallback(() => (showModal ? <UsageEventViewModal onClose={closeModal} {...openParams} /> : <></>), [
    showModal,
    closeModal,
    openParams,
  ]);

  return {
    openModal,
    UsageEventViewModal: Modal,
  };
};
