import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { Formik } from 'formik';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { AppContext } from 'AppContext';
import { ISO_CODE_TO_SYMBOL } from 'consts/global';
import { COLORS } from 'consts/colors';
import { useToasts } from 'utils/hooks';
import { useLedgerValuesAPI, LEDGER_VALUES_KEYS } from 'api/ledgerValues';
import { ReactComponent as CircleCheck } from 'images/transaction_check.svg';
import { Button } from 'components/Buttons';
import { Flexer, FlexEndContainer, Spacer } from 'components/Core';
import { CustomDatePicker, FormikCustomInput } from 'components/Controls';
import { SizedLoader } from 'components/Loaders';
import {
  Modal,
  ModalBody,
  ModalCloseIcon,
  ModalContainer,
  ModalFooter,
  ModalHeader,
  ModalSubtitleText,
  ModalTitle,
  ModalTitleText,
} from 'components/Modal';

dayjs.extend(utc);

const CheckIcon = styled(CircleCheck)`
  margin-left: 10px;
  height: 20px;
  width: 20px;
`;

export const EditNetProfitModal = ({ orgId, initialMonth, onClose }) => {
  const { pushToast, pushError } = useToasts();
  const [month, setMonth] = useState(dayjs(initialMonth).startOf('month').toDate());
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasDataChanged, setHasDataChanged] = useState(false);

  const monthString = `${dayjs(month).format('YYYY-MM')}-01`;
  const {
    data,
    isLoading,
    operations: { upsertLedgerValue },
  } = useLedgerValuesAPI({ orgId, month: monthString, key: LEDGER_VALUES_KEYS.NET_PROFIT });
  const {
    appSettings: { currencyISOCode: currency },
  } = useContext(AppContext);

  const handleFormSubmit = async ({ values }) => {
    setIsSubmitting(true);
    try {
      await upsertLedgerValue({
        orgId,
        month: monthString,
        key: LEDGER_VALUES_KEYS.NET_PROFIT,
        value: values.net_profit,
      });
      setHasDataChanged(true);
      pushToast(`${dayjs(month).format('MMMM YYYY')} net profit updated successfully`, 'success');
    } catch (err) {
      pushError(err);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleClose = () => onClose({ hasDataChanged });

  const initialValues = data?.[0]
    ? {
        net_profit: data[0].value,
      }
    : {};

  return (
    <ModalContainer>
      <Formik initialValues={initialValues} enableReinitialize onSubmit={(values) => handleFormSubmit({ values })}>
        {({ submitForm }) => (
          <Modal data-cy="edit-net-profit-modal" width="540px" height="auto">
            <ModalCloseIcon onClose={handleClose} data-cy="edit-net-profit-modal__close-button" />
            <ModalHeader>
              <ModalTitle padding="12px 36px">
                <Flexer>
                  <ModalTitleText>Net Profit:</ModalTitleText>
                  <Spacer width="8px" />
                  <CustomDatePicker
                    showMonthYearPicker
                    dateFormat="MMMM yyyy"
                    onChange={setMonth}
                    selected={month}
                    dropdownView
                  />
                </Flexer>
                <ModalSubtitleText>Manually add net profit</ModalSubtitleText>
              </ModalTitle>
            </ModalHeader>
            <ModalBody paddingLeft="36px" paddingRight="36px">
              {isLoading ? (
                <SizedLoader size={40} wrapperHeight={62} />
              ) : (
                <FormikCustomInput
                  name="net_profit"
                  label="Net Profit"
                  placeholder="0"
                  suffix={ISO_CODE_TO_SYMBOL[currency] ?? '$'}
                  type="number"
                  precision={2}
                  data-cy={`edit-net-profit-modal__input-field--net_profit`}
                />
              )}
              <Spacer height="30px" />
            </ModalBody>
            <ModalFooter padding="12px 36px" noFixedHeight>
              <FlexEndContainer>
                <Button border filled color={COLORS.GREY} onClick={handleClose}>
                  Cancel
                </Button>
                <Spacer width="14px" />
                <Button
                  color={COLORS.GREEN}
                  border
                  filled
                  active
                  disabled={isSubmitting || isLoading}
                  onClick={submitForm}
                  data-cy="edit-net-profit-modal__submit-button"
                >
                  Save
                  <CheckIcon />
                </Button>
              </FlexEndContainer>
            </ModalFooter>
          </Modal>
        )}
      </Formik>
    </ModalContainer>
  );
};
