import React, { useCallback, useContext, useState } from 'react';
import styled from 'styled-components';
import { AppContext } from 'AppContext';
import { NUMBER_FORMATS } from 'consts/global';
import { numberFormatter } from 'utils/formatters';
import { Centerer, FlexBetweenContainer, Flexer, Spacer } from 'components/Core';
import { CircleLoader } from 'components/Loaders';
import {
  Modal,
  ModalBody,
  ModalButton,
  ModalCloseIcon,
  ModalContainer,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTitleText,
} from 'components/Modal';
import { Portal } from 'components/Portal';
import { useInvoiceAPI } from 'api/billing';

const InvoiceChargeDetails = styled(Flexer)`
  flex-direction: column;
  border-radius: 8px;
  background: var(--primaryBlue5);

  > div {
    padding: 8px 12px;

    color: var(--primaryBlack);
    font-size: 12px;
    font-style: italic;
    font-weight: 700;
    line-height: 16px;
  }

  span {
    opacity: 0.5;
  }

  > *:first-child {
    border-bottom: 1px solid white;
  }
`;

const InvoiceChargeDetailsAmount = styled.div`
  font-size: 12px;
  font-style: italic;
  font-weight: 900;
  line-height: 16px;
`;

const ChargeManuallyModal = ({ onClose, values }) => {
  const {
    appSettings: { currencyISOCode: defaultOrgCurrency },
  } = useContext(AppContext);

  const {
    operations: { chargeInvoice },
  } = useInvoiceAPI({
    orgId: values?.organization_id,
    invoiceId: values?.id,
    autoFetch: false,
  });

  const [isChargingLoading, setIsChargingLoading] = useState(false);

  const handleChargeInvoice = async () => {
    setIsChargingLoading(true);
    try {
      await chargeInvoice({ id: values?.id });
    } catch (e) {
      console.error({ message: e.message, component: 'useChargeManuallyModal.js', stack: e });
    } finally {
      setIsChargingLoading(false);
      onClose();
    }
  };

  return (
    <ModalContainer data-cy="charge-manually-modal">
      <Modal maxWidth="440px" height="auto">
        <ModalHeader>
          <ModalTitle>
            <ModalTitleText>
              <b>Charge the customer</b>
            </ModalTitleText>
          </ModalTitle>
          <ModalCloseIcon onClose={onClose} />
        </ModalHeader>

        <Spacer height="20px" />

        {isChargingLoading ? (
          <ModalBody>
            <Centerer width="100%">
              <CircleLoader />
            </Centerer>
          </ModalBody>
        ) : (
          <ModalBody>
            <div>Do you want to charge the customer's credit card now? Please confirm the operation below.</div>

            <InvoiceChargeDetails>
              <div>{values.customer_name}</div>
              <FlexBetweenContainer centerer>
                <span>Amount:</span>
                <InvoiceChargeDetailsAmount>
                  {numberFormatter({
                    type: NUMBER_FORMATS.CURRENCY,
                    rawValue: values.amount,
                    currency: values.currency ?? defaultOrgCurrency,
                    decimalPlaces: 2,
                  })}
                </InvoiceChargeDetailsAmount>
              </FlexBetweenContainer>
            </InvoiceChargeDetails>
          </ModalBody>
        )}

        <Spacer height="20px" />

        {!isChargingLoading && (
          <ModalFooter flexEnd>
            <ModalButton onClick={onClose}>Cancel</ModalButton>
            <ModalButton data-cy="charge-manually-modal__confirm-button" primary onClick={handleChargeInvoice}>
              Confirm
            </ModalButton>
          </ModalFooter>
        )}
      </Modal>
    </ModalContainer>
  );
};

export const useChargeManuallyModal = (props) => {
  const [showModal, setShowModal] = useState(false);

  const openModal = useCallback(() => {
    setShowModal(true);
  }, [setShowModal]);

  const Modal = useCallback(
    () =>
      showModal ? (
        <Portal>
          <ChargeManuallyModal {...props} onClose={() => setShowModal(false)} />
        </Portal>
      ) : null,
    [showModal, setShowModal, props],
  );

  return {
    openModal,
    Modal,
  };
};
