import styled from 'styled-components';
import { ReactComponent as CheckIcon } from 'images/circle-check.svg';

import { BlueText, Flexer, Row } from 'components/Core';

export const Title = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: var(--primaryBlack);
`;

export const Subtitle = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--primaryBlack50);

  margin-top: 4px;
`;

export const ButtonStyling = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-grow: ${({ flexGrow }) => flexGrow ?? undefined};

  position: relative;
  padding: 16px;

  background-color: ${({ disabled }) => (disabled ? 'var(--accentGray)' : undefined)};
  cursor: pointer;
  border-radius: 12px;
  border: ${({ connected, disabled }) =>
    connected && !disabled ? '1px solid var(--primaryBlue)' : '1px solid var(--primaryBlack10)'};

  svg {
    path {
      fill: ${({ connected }) => connected && 'var(--primaryBlue)'};
    }
  }

  &:hover {
    ${Subtitle} {
      color: ${({ connected }) => !connected && 'var(--primaryBack)'};
    }
  }
`;

export const StyledCheckIcon = styled(CheckIcon)`
  width: 20px;
  height: 20px;
  position: absolute;
  top: 4px;
  right: 4px;
`;

export const StyledMetricRow = styled(Row)`
  margin: 5px;

  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--primaryBlack);

  b {
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
  }

  span {
    color: var(--primaryBlack50);
  }
`;

export const OptionsSection = styled(Flexer)`
  padding: 0 32px;
  margin-bottom: 28px;
`;

export const SelectorSectionRow = styled(Flexer)`
  width: 100%;
  align-items: center;
`;

export const ListSection = styled.div`
  padding: 0 36px 28px 36px;
`;

export const SelectorsSection = styled(Flexer)`
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  padding: 0 36px 28px 36px;

  ${SelectorSectionRow} {
    div {
      &:not(:first-child) {
        margin-left: 12px;
      }

      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      color: var(--primaryBlack);
    }
  }

  svg {
    width: 20px;
    height: 20px;
    margin-right: 9px;
  }
`;

export const ListItem = styled(Flexer)`
  margin-bottom: 12px;

  &:last-child {
    margin-bottom: 0;
  }

  svg {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }

  p {
    margin: 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    b {
      font-weight: 700;
    }
  }
`;

export const MetricsSection = styled(Flexer)`
  background: var(--primaryGray);
  border-top: 1px solid var(--primaryBlack3);
  padding: 28px 36px;
`;

export const MetricsSectionTitle = styled.div`
  font-size: 14px;
  align-items: center;
  line-height: 20px;
  display: flex;

  span {
    font-weight: 700;
    margin-right: 12px;
  }
`;

export const StyledButton = styled(Flexer)`
  cursor: pointer;

  padding: 12px 8px;
  margin-left: auto;
  border-radius: 12px;
  background: var(--primaryBlue10);

  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--primaryBlue);
`;

export const StyledCheckButton = styled(CheckIcon)`
  margin-left: 9px;

  path {
    fill: #fff;
  }
`;

export const MembersButton = styled(BlueText)`
  &:hover {
    color: var(--secondaryBlue);
  }
`;

export const FrequencySelectorContainer = styled.div`
  display: flex;
  flex-direction: column;

  .react-datepicker__input-container {
    width: 404px;
  }
`;

export const FrequencySelectorsTitle = styled.b`
  white-space: nowrap;
  margin-right: 12px;
`;
