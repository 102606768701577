import { FlexBetweenContainer, Flexer } from 'components/Core';
import styled from 'styled-components';
import insightsWaveImg from 'images/insights-wave.svg';

export const StyledInsightCard = styled.div``;

export const InsightOneLiner = styled(Flexer)`
  padding: 20px;
  font-size: 20px;
`;

export const InsightMiniCharts = styled(Flexer)`
  overflow: scroll;
  svg {
    fill: var(--primaryBlack5);
  }
`;

export const MiniChartWrapper = styled(Flexer)`
  overflow: visible;
  padding: 20px;
`;

export const InsightConfidenceDetail = styled(Flexer)`
  background: var(--primaryBlack2);
  padding: 20px;
  border-radius: 16px;
`;

export const YellowItalic = styled.span`
  color: var(--primaryYellow);
  font-style: italic;
  font-weight: bold;
`;

export const BoldItalic = styled.span`
  font-style: italic;
  font-weight: bold;
`;

export const MiniChartDescriptor = styled.div`
  margin-left: 12px;

  b {
    font-weight: 900;
  }
`;

export const InsightCaption = styled.div`
  padding-left: 20px;
  padding-right: 20px;
`;

export const ChartAndConfidenceContainer = styled(FlexBetweenContainer)`
  padding-left: 20px;
  padding-right: 20px;
  flex-direction: row;
`;

export const SmallLightText = styled.div`
  font-size: 12px;
  color: var(--primaryBlack50);

  svg {
    width: 16px;
    height: 16px;
    margin-left: 4px;
    vertical-align: sub;
    cursor: pointer;
    g {
      transition: opacity ease 0.4s;
    }
    &:hover {
      g {
        opacity: 1;
      }
    }
  }
`;

export const Carousel = styled.div`
  padding: 20px;

  background: var(--primaryBlack2);
  background-image: url(${insightsWaveImg});
  background-repeat: no-repeat;
  background-position: right bottom;
`;
