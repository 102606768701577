import React, { useContext } from 'react';
import { HEADER_TITLES } from 'shared/Layout';
import { ShareButton } from 'shared/ShareButton';
import { SaveReportButton } from 'shared/Reports/SaveReportButton';
import { Centerer } from 'components/Core';
import { GreyDot } from 'components/Icons';

import { TooltipRowText12 } from 'components/Tooltip/styles';
import { TooltipContainer } from 'components/Tooltip';
import { COMMON_METRICS_DASHBOARD_TITLE, DEFAULT_DASHBOARD_TITLE } from '../consts';
import { Header, ReportName, Title } from '../styles';
import { ReportBuilderContext } from '../ReportBuilderContext';

export const ReportBuilderHeader = () => {
  const { report, setReport, pageReportState, ExportButton, openEditReportNameModal } = useContext(
    ReportBuilderContext,
  );
  const handleSaveReport = ({ updatedReport }) => {
    if (updatedReport) {
      setReport(updatedReport);
    }
  };

  // So we disable editing the default dashboard
  const isEditable = [DEFAULT_DASHBOARD_TITLE, COMMON_METRICS_DASHBOARD_TITLE].includes(report?.title);

  return (
    <Header>
      <Centerer gap="12px">
        <Title>Reports: </Title>
        <TooltipContainer
          width={70}
          hideArrow
          fontSize="12px"
          toolTipContent={<TooltipRowText12>Edit name</TooltipRowText12>}
          isVisible={!isEditable}
          tooltipWrapperStyles={{ height: '40px', marginTop: '-3px' }}
        >
          <ReportName
            data-cy="header-reports__title"
            editable={!isEditable}
            onClick={!isEditable ? openEditReportNameModal : null}
          >
            {report?.title}
          </ReportName>
        </TooltipContainer>
      </Centerer>

      <Centerer gap="12px">
        {report && ![COMMON_METRICS_DASHBOARD_TITLE].includes(report?.title) && (
          <>
            <SaveReportButton report={report} pageReportState={pageReportState} onSubmitted={handleSaveReport} />
            <GreyDot size="6px" spacing="0" />
          </>
        )}
        <ShareButton
          headerTitle={HEADER_TITLES.dashboard}
          report={{
            ...report,
            // The user could have changed the filters without saving the report, this will make sure it share whatever the user is seeing
            options: pageReportState,
          }}
          pageToShare={HEADER_TITLES.dashboard}
        />
        <ExportButton />
      </Centerer>
    </Header>
  );
};
