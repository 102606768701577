import { useCallback, useState } from 'react';
import { isEqual } from 'lodash';

import { ReceiptModal } from './ReceiptModal';

export const useReceiptModal = ({ receiptId } = {}) => {
  const [showModal, setShowModal] = useState(false);
  const [modalProps, setModalProps] = useState({});
  const openModal = useCallback(
    (props) => {
      setShowModal(true);
      !isEqual(props ?? {}, modalProps) && setModalProps(props ?? {});
    },
    [modalProps],
  );

  const Modal = useCallback(
    () =>
      showModal ? <ReceiptModal receiptId={receiptId} onClose={() => setShowModal(false)} {...modalProps} /> : null,
    [modalProps, receiptId, showModal],
  );

  return {
    isModalOpen: showModal,
    openModal,
    Modal,
  };
};
