import styled from 'styled-components';

import { SubrowArrow } from 'components/Icons';
import { Spacer } from 'components/Core';

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  position: relative;
  font-weight: normal;
  margin: 14px 0 3px 0;
`;

const TextContainer = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 12px;
  line-height: 16px;
  position: relative;
  color: rgba(0, 21, 46, 0.6);
`;

const TransparentArrow = styled(SubrowArrow)`
  opacity: 0.6;
`;

export const GoalRowTitleName = ({ name }) => (
  <Container>
    <TransparentArrow size="10px" />
    <Spacer width="4px" />
    <TextContainer>{name}</TextContainer>
  </Container>
);
