import React, { useContext, useMemo } from 'react';
import { useTable } from 'react-table';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { AppContext } from 'AppContext';
import { NUMBER_FORMATS } from 'consts/global';
import { numberFormatter } from 'utils/formatters';
import {
  ReactTableHeader,
  ReactTableHeaderColumn,
  ReactTableHeaderRow,
  ReactTableBody,
  ReactTableRow,
  ReactTableCell,
} from 'components/Table';
import { FlexerColumn } from 'components/Core';

const TableWrapper = styled.div`
  padding-bottom: 40px;
  background-color: var(--primaryGray);
  width: 50%;
  height: 86vh;
  overflow: scroll;
  border-right: 1px solid var(--primaryBlack5);
`;

const TableRowsWrapper = styled.div`
  padding: 0px 40px;
`;

const CustomerName = styled.span`
  color: var(--primaryBlue);
  font-size: 12px;
  font-weight: 700;
`;

const CustomerEmails = styled.span`
  font-size: 12px;
  font-weight: 400;
`;

export const BulkSendInvoicesModalTable = ({ invoices = [] }) => {
  const { dateFormat } = useContext(AppContext);

  const columns = useMemo(() => {
    const result = [
      {
        Header: '',
        accessor: 'id',
        id: 'id',
        width: '40%',
        Cell: ({ row }) => (
          <FlexerColumn gap="4px">
            <CustomerName>{row.original.customer_name}</CustomerName>
            <CustomerEmails>
              {(() => {
                const contacts = row.original.email_addresses || row.original.customerInvoicingDetails?.contacts || [];
                return Array.isArray(contacts) ? contacts.join(', ') : contacts;
              })()}
            </CustomerEmails>
          </FlexerColumn>
        ),
      },
      {
        Header: 'SEND DATE',
        accessor: 'send_date',
        id: 'send_date',
        width: '15%',
        Cell: () => <b>Today</b>,
      },
      {
        Header: 'INVOICE DATE',
        accessor: 'date',
        id: 'date',
        width: '15%',
        Cell: ({ row }) => dayjs(row.original.date).format(dateFormat),
      },
      {
        Header: 'DUE DATE',
        accessor: 'days_to_pay',
        id: 'terms',
        width: '15%',
        Cell: ({ row }) => dayjs(row.original.date).add(row.original.days_to_pay, 'days').format(dateFormat),
      },
      {
        Header: 'AMOUNT',
        accessor: 'amount',
        id: 'amount',
        width: '15%',
        alignRight: true,
        Cell: ({ row }) =>
          numberFormatter({
            type: NUMBER_FORMATS.CURRENCY,
            rawValue: row.original.amount,
            decimalPlaces: 2,
            currency: row.original.currency,
          }),
      },
    ];

    return result;
  }, [dateFormat]);

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable({
    columns,
    data: invoices,
  });

  return (
    <TableWrapper>
      <div {...getTableProps()}>
        <ReactTableHeader
          backgroundColor="var(--accentGrayFourth)"
          borderBottom="1px solid var(--primaryBlack5)"
          padding="0px 40px"
          marginBottom="24px"
        >
          {headerGroups.map((headerGroup) => (
            <ReactTableHeaderRow {...headerGroup.getHeaderGroupProps()} noSpaceBetween>
              {headerGroup.headers.map((column) => (
                <ReactTableHeaderColumn
                  {...column.getHeaderProps()}
                  customWidth={column.width}
                  alignRight={column.alignRight}
                >
                  {column.render('Header')}
                </ReactTableHeaderColumn>
              ))}
            </ReactTableHeaderRow>
          ))}
        </ReactTableHeader>
        <TableRowsWrapper>
          <ReactTableBody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <ReactTableRow {...row.getRowProps()} noRowHover>
                  {row.cells.map((cell) => (
                    <ReactTableCell
                      {...cell.getCellProps()}
                      customWidth={cell.column.width}
                      alignRight={cell.column.alignRight}
                    >
                      {cell.render('Cell')}
                    </ReactTableCell>
                  ))}
                </ReactTableRow>
              );
            })}
          </ReactTableBody>
        </TableRowsWrapper>
      </div>
    </TableWrapper>
  );
};
