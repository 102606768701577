import React from 'react';
import { Divider } from 'components/Core/Lines';
import { FormikCustomCheckbox, FormikCustomSelector } from 'components/Controls';

export const WidgetChartVisualizerForm = ({ metricOptions, values, setFieldValue }) => {
  return (
    <>
      <FormikCustomSelector
        name="selectedMetrics.0.id"
        label="First metric"
        options={metricOptions}
        handleChange={(option) => {
          setFieldValue('selectedMetrics.0.id', option.value);
          if (!option.hasTTMOption) {
            // If it does not have TTM option we will reset the value
            setFieldValue('selectedMetrics.0.isTTM', false);
          }
        }}
      />
      <FormikCustomCheckbox
        data-cy="chart-visualizer-widget-modal__isTTM-0-checkbox"
        name="selectedMetrics.0.isTTM"
        label="Trailing Twelve Months (TTM)"
        disabled={!metricOptions.find((option) => option.value === values.selectedMetrics?.[0]?.id)?.hasTTMOption}
      />

      <Divider />

      <FormikCustomSelector
        name="selectedMetrics.1.id"
        label="Second metric"
        options={[{ label: 'None' }, ...metricOptions]}
        handleChange={(option) => {
          if (!option.value) {
            // If null we are removing from selectedMetrics
            setFieldValue('selectedMetrics', [values.selectedMetrics[0]]);
          } else {
            setFieldValue('selectedMetrics.1.id', option.value);
            if (!option.hasTTMOption) {
              // If it does not have TTM option we will reset the value
              setFieldValue('selectedMetrics.1.isTTM', false);
            }
          }
        }}
      />

      {values.selectedMetrics?.length === 2 && (
        <FormikCustomCheckbox
          data-cy="chart-visualizer-widget-modal__isTTM-1-checkbox"
          name="selectedMetrics.1.isTTM"
          label="Trailing Twelve Months (TTM)"
          disabled={!metricOptions.find((option) => option.value === values.selectedMetrics?.[1]?.id)?.hasTTMOption}
        />
      )}
    </>
  );
};
