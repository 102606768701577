import styled, { css } from 'styled-components';
import { Fragment, useContext, useMemo } from 'react';
import { AppContext } from 'AppContext';
import { useTransactionsAPI } from 'api/transactions';
import { FlexerRow } from 'components/Core';
import { TooltipButton } from 'components/Buttons';
import { getScheduleTransactionsIds } from 'views/Billing/InvoicingScheduleModal/InvoiceScheduleWarnings/utils';
import { TooltipContainer } from 'components/Tooltip';
import { transactionDisplayTitle } from 'models/transaction';
import { EXTERNAL_UPDATES_TABLE_COLUMNS } from './consts';

const MAX_DISPLAYED_TRANSACTION = 3;
const UPDATED_TRANSACTION_STATUS = { UPDATED: 'UPDATED', REMOVED: 'REMOVED' };

const TransactionContainer = styled.div`
  width: 386px;
`;

export const TransactionName = styled.span`
  max-width: 350px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${({ deleted }) =>
    deleted &&
    css`
      color: #ffffff;
      background: var(--tertiaryRed);
      padding: 0 2px;
      border-radius: 2px;
      cursor: pointer;
    `}
`;

const TruncatedTransactionsCounter = styled.div`
  background: var(--primaryBlack5);
  border-radius: 100px;
  margin-left: 4px;
  padding: 4px 8px;
  color: var(--primaryBlack);
  font-size: 10px;
  font-weight: 900;
  line-height: 12px;
  cursor: pointer;
`;

export const TransactionsCell = ({ row, openInvoiceModal }) => {
  const { orgId } = useContext(AppContext);

  const allTransactionsIds = useMemo(
    () => [
      ...new Set([
        ...row?.original?.externalUpdate?.update_data?.transactions,
        ...row?.original?.targetObject?.transactions,
      ]),
    ],
    [row],
  );
  const { data: dataTransactions } = useTransactionsAPI({
    orgId,
    filters: {
      limit: 30000,
      body: { transactionIds: allTransactionsIds },
    },
    autoFetch: true,
  });

  const transactionsIds = useMemo(() => getScheduleTransactionsIds({ targetObject: row.original.targetObject }), [row]);

  // for external updates created before `deletedTransactions` has been stored
  const { data: dataArchivedTransactions } = useTransactionsAPI({
    orgId,
    filters: {
      limit: 30000,
      body: { transactionIds: transactionsIds },
      params: { archived: true },
    },
    autoFetch: transactionsIds?.length > 0,
  });

  const updatedTransactions =
    row.original.externalUpdate?.update_data?.[EXTERNAL_UPDATES_TABLE_COLUMNS.transactions.key]?.map((transactionId) =>
      transactionDisplayTitle(dataTransactions?.find((transaction) => transaction?.id === transactionId)),
    ) ?? [];

  const deletedTransactions =
    row.original.externalUpdate?.update_data?.deletedTransactions ?? dataArchivedTransactions ?? [];

  const originalTransactions = row.original.targetObject?.[EXTERNAL_UPDATES_TABLE_COLUMNS.transactions.key];

  const transactionsWithStatuses = originalTransactions?.map((transactionId) => {
    const deletedTransaction = deletedTransactions?.find(({ id }) => id === transactionId);
    const updatedTransaction = updatedTransactions?.find(({ id }) => id === transactionId);

    if (deletedTransaction) {
      return { name: transactionDisplayTitle(deletedTransaction), status: UPDATED_TRANSACTION_STATUS.REMOVED };
    } else if (updatedTransaction) {
      return { name: transactionDisplayTitle(updatedTransaction), status: UPDATED_TRANSACTION_STATUS.UPDATED };
    } else {
      return {
        name: transactionDisplayTitle(dataTransactions?.find((transaction) => transaction?.id === transactionId)),
      };
    }
  });

  const hiddenTransactionsCount = (transactionsWithStatuses.slice(MAX_DISPLAYED_TRANSACTION) ?? []).length;

  return (
    <FlexerRow alignItems="flex-end">
      <TransactionContainer>
        <FlexerRow alignItems="center" gap="2px" wrapRow>
          {transactionsWithStatuses?.slice(0, MAX_DISPLAYED_TRANSACTION).map(({ name, status }, index) => (
            <Fragment key={index}>
              <TooltipContainer
                width={220}
                isVisible={status === UPDATED_TRANSACTION_STATUS.REMOVED}
                toolTipContent="Transaction was deleted"
              >
                <TransactionName deleted={status === UPDATED_TRANSACTION_STATUS.REMOVED}>{name}</TransactionName>
              </TooltipContainer>
              {index < transactionsWithStatuses.length - 1 ? ',' : ''}
            </Fragment>
          ))}
          {hiddenTransactionsCount > 0 && (
            <TooltipContainer
              width={128}
              isVisible={true}
              toolTipContent={
                <>
                  {`+${hiddenTransactionsCount} transactions`}
                  <TooltipButton onClick={openInvoiceModal}>See Details</TooltipButton>
                </>
              }
            >
              <TruncatedTransactionsCounter>+{hiddenTransactionsCount}</TruncatedTransactionsCounter>
            </TooltipContainer>
          )}
        </FlexerRow>
      </TransactionContainer>
    </FlexerRow>
  );
};
