import styled from 'styled-components';
import { ReactComponent as SaveIcon } from 'images/device-floppy.svg';
import { CentererVertical, FlexBetweenContainer, FlexerColumn } from 'components/Core';
import { ScenarioCardWrapper } from './Components/ScenarioCard';

export const StyledSaveIcon = styled(SaveIcon)`
  path {
    fill: var(--primaryOrange);
  }
`;

export const SaveText = styled.div`
  width: 99px;

  font-weight: 400;
  font-size: 11px;
  line-height: 14px;
  text-align: right;

  color: var(--primaryBlack);
  opacity: 0.5;
`;

export const PageWrapper = styled.div`
  width: calc(100% + 60px);
  // To fill height
  min-height: calc(100vh - 148px);
  padding: 0 40px 0 20px;
  margin-left: -20px;
  overflow-x: hidden;
`;

export const Filters = styled(FlexBetweenContainer)`
  padding: 8px;
  border: 1px solid var(--accentGraySecond);
  border-radius: 16px;
`;

export const FiltersInfo = styled(CentererVertical)`
  padding-left: 14px;

  svg {
    path {
      fill: var(--primaryBlack50);
    }
  }

  div {
    margin-left: 10px;

    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    color: var(--primaryBlack);
    opacity: 0.5;
  }
`;

export const ScenarioListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  padding: 0;
`;

export const ScenarioCardGroup = styled(FlexerColumn)`
  width: 100%;

  ${ScenarioCardWrapper} {
    &:not(:first-child) {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-top: 0;
    }

    &:not(:last-child) {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
`;

export const ShowOutdatedButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  font-weight: 900;
  font-size: 10px;
  line-height: 12px;

  text-transform: uppercase;
  cursor: pointer;

  color: ${({ showing }) => (showing ? 'var(--primaryBlack)' : 'var(--primaryBlack30)')};

  svg {
    width: 8px;
    height: 8px;

    margin-left: 8px;

    transform: ${({ showing }) => (showing ? 'rotate(180deg)' : null)};

    path {
      fill: ${({ showing }) => (showing ? 'var(--primaryBlack)' : 'var(--primaryBlack30)')};
    }
  }
`;
