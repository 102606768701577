import { useContext, useRef, useState } from 'react';
import { Form, Formik } from 'formik';
import dayjs from 'dayjs';

import { AppContext } from 'AppContext';
import { useReceiptAPI } from 'api/billingReceipts';
import { Modal, ModalBody, ModalCloseIcon, ModalContainer } from 'components/Modal';
import { TooltipContainer } from 'components/Tooltip';
import { CircleLoader } from 'components/Loaders';
import { useToasts } from 'components/Toasts';
import { Centerer } from 'components/Core';

import { ReceiptDetailsForm } from './ReceiptDetailsForm';
import { ReceiptModalFooter } from './ReceiptModalFooter';
import { ReceiptModalContentWrapper, ReceiptModalHeader, ReceiptModalTitle, StyledHelpCircleIcon } from './styles';
import { InvoicingScheduleContext } from '../InvoicingScheduleModal/InvoicingScheduleContext';
import { ScheduleTabs, StyledLeftTabButton, StyledRightTabButton } from '../InvoicePreviewModalV2/styles';

export const SUBMIT_TYPS = {
  SAVE: 'save',
  TEST_SEND: 'test_send',
  SEND: 'send',
};

export const ReceiptModal = ({ receiptId, onClose }) => {
  const { orgId } = useContext(AppContext);
  const { fetchedSelectedInvoice, invoicingScheduleFormValues, performInvoicingScheduleUpdate } = useContext(
    InvoicingScheduleContext,
  );

  const [isSubmitting, setIsSubmitting] = useState(false);

  const { pushError } = useToasts();

  const submissionType = useRef();

  const {
    data: receipt,
    isLoading,
    isFetching,
    operations: { updateReceipt, sendReceipt },
  } = useReceiptAPI({ receiptId, orgId });

  const isLoadingReceipt = isLoading && isFetching;

  const onSubmissionTypeChange = (type) => {
    submissionType.current = type;
  };

  return (
    <Formik
      initialValues={receipt ?? {}}
      enableReinitialize
      onSubmit={async (values) => {
        if (!values.send_to || values.send_to?.length === 0) {
          pushError(null, 'At least one recipient email must be filled in');
          return;
        }

        if (!values.email_subject || !values.email_subject.trim()) {
          pushError(null, 'Please fill in email subject');
          return;
        }

        setIsSubmitting(true);

        const allReceipts = invoicingScheduleFormValues?.invoices
          ?.map((invoice) => invoice.receipt)
          ?.filter((receipt) => !!receipt && !receipt.sent_at && receipt.id !== values.id);

        switch (submissionType.current) {
          case SUBMIT_TYPS.SAVE:
            await updateReceipt({ data: values, receiptId: values.id });
            break;
          case SUBMIT_TYPS.SEND:
            await sendReceipt({ data: values });
            break;
          case SUBMIT_TYPS.TEST_SEND:
            await sendReceipt({ data: values, params: { testMode: true } });
            break;
          default:
            break;
        }

        if (receipt.auto_send !== values.auto_send) {
          // if an unsent receipt's auto_send is turned off, then auto_send_receipts for the schedule should be off too
          if (!values.auto_send && invoicingScheduleFormValues?.auto_send_receipts) {
            await performInvoicingScheduleUpdate({
              auto_send_receipts: false,
            });
          }

          // if turning on an unsent receipt's auto_send results in all unsent receipts in the schedule to be auto-send,
          // then auto_send_receipts for a schedule should be on
          if (
            values.auto_send &&
            !invoicingScheduleFormValues?.auto_send_receipts &&
            allReceipts?.every((receipt) => receipt.auto_send)
          ) {
            await performInvoicingScheduleUpdate({
              auto_send_receipts: true,
            });
          }
        }
        setIsSubmitting(false);

        onClose();
      }}
    >
      {({ values, setFieldValue, submitForm }) => (
        <Form>
          <ModalContainer>
            <Modal maxHeight="750px" width="800px" overflow="auto" background="var(--primaryGray)">
              <ModalCloseIcon onClick={onClose} data-cy="billing__receipt-modal--close-button" />
              {isLoadingReceipt || isSubmitting ? (
                <Centerer height="100%">
                  <CircleLoader />
                </Centerer>
              ) : (
                <>
                  <ReceiptModalHeader>
                    {!!receipt.sent_at ? (
                      <ReceiptModalTitle data-cy="billing__receipt-modal__title">
                        Receipt was sent on {dayjs.utc(receipt.sent_at).format('MMM DD, YYYY [at] h:mma')}
                      </ReceiptModalTitle>
                    ) : (
                      <>
                        <ReceiptModalTitle data-cy="billing__receipt-modal__title">Receipt:</ReceiptModalTitle>
                        <ScheduleTabs>
                          <StyledLeftTabButton
                            active={!values?.auto_send}
                            onClick={() => {
                              setFieldValue('auto_send', false);
                            }}
                            data-cy="billing__receipt-modal__send-now-tab"
                          >
                            Send right now
                          </StyledLeftTabButton>
                          <StyledRightTabButton
                            active={values?.auto_send}
                            onClick={() => {
                              setFieldValue('auto_send', true);
                            }}
                            data-cy="billing__receipt-modal__auto-send-tab"
                          >
                            Auto-send{' '}
                            <TooltipContainer
                              fontSize="12px"
                              toolTipContent="Receipts will not be auto-sent when an invoice is manually marked as paid in Subscript"
                              width={200}
                            >
                              <StyledHelpCircleIcon />
                            </TooltipContainer>
                          </StyledRightTabButton>
                        </ScheduleTabs>
                      </>
                    )}
                  </ReceiptModalHeader>
                  <ModalBody>
                    <ReceiptModalContentWrapper>
                      <ReceiptDetailsForm invoicePdfLink={fetchedSelectedInvoice?.invoice_pdf_link} />
                    </ReceiptModalContentWrapper>
                  </ModalBody>
                  <ReceiptModalFooter
                    isPaid={!!fetchedSelectedInvoice?.paid_at}
                    handleSubmit={(submissionType) => {
                      onSubmissionTypeChange(submissionType);
                      submitForm();
                    }}
                    receipt={values}
                  />
                </>
              )}
            </Modal>
          </ModalContainer>
        </Form>
      )}
    </Formik>
  );
};
