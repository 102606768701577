import { useQuery } from 'react-query';
import { getMetadataOptions } from './requests';

export const CACHE_KEY = 'metadata';

export const useMetadataOptionsAPI = ({ orgId, metadataFilterType, enabled = true }) => {
  const { data, error, isLoading, isFetching, refetch } = useQuery(
    [CACHE_KEY, orgId, metadataFilterType],
    () => getMetadataOptions(orgId),
    {
      enabled,
    },
  );

  const result = data ?? {};

  return {
    metadataOptions: metadataFilterType ? result[metadataFilterType] ?? {} : result,
    error,
    isLoading,
    isFetching,
    refetch,
  };
};
