import React, { useContext, useMemo, useState } from 'react';
import { AppContext } from 'AppContext';
import { useReportsAPI } from 'api/reports';
import FilterIcon from 'images/adjustments-horizontal.svg';
import { InfoIcon } from 'components/Icons';
import { useLinkShare } from 'shared/LinkShare';
import { TooltipContainer } from 'components/Tooltip';
import { Header, HEADER_TITLES } from 'shared/Layout';
import { CentererVertical, Spacer } from 'components/Core';
import { InlineButton, SelectDropdownButton } from 'components/Buttons';
import { getFilteredReports, getReportsOptionsWithCount } from 'shared/Reports/ReportsPopover/utils';
import {
  ReportsPopoverHeader,
  ReportsPopoverHeaderTitle,
  ReportsPopoverHeaderLeft,
} from 'shared/Reports/ReportsPopover/styles';
import { DEFAULT_REPORTS_OPTION } from 'shared/Reports/consts';
import { ReportsList, useNavigateToReportPage, useNewReportModal } from 'shared/Reports';
import { ReportsContext } from './ReportsContext';
import { HeaderToolTip } from './styles';
import { SearchBar } from 'components/Blocks';

export const ReportsListContent = () => {
  const { organizations } = useContext(AppContext);

  const { reportsListSearchTerm, setReportsListSearchTerm } = useContext(ReportsContext);
  const { LinkShareButton, LinkShareModal } = useLinkShare({ headerTitle: HEADER_TITLES.cohorts });

  const { navigateToReportPage: handleNavigateToReportPage } = useNavigateToReportPage();

  const { data, error, isLoading, isFetching } = useReportsAPI({
    orgId: organizations[0].id,
    filters: {
      searchQuery: reportsListSearchTerm,
    },
  });

  const { openNewReportModal, NewReportModal } = useNewReportModal({
    onSubmitted: handleNavigateToReportPage,
  });

  const reportsOptions = useMemo(() => getReportsOptionsWithCount(data), [data]);

  const [selectedReportType, setSelectedReportType] = useState(DEFAULT_REPORTS_OPTION);

  const reportList = useMemo(
    () =>
      getFilteredReports({
        data: data ?? [],
        selectedReportType,
      }),
    [data, selectedReportType],
  );

  const handleClearSearch = () => {
    setReportsListSearchTerm('');
  };

  const handleClickCreateNewReport = () => {
    openNewReportModal();
  };

  return (
    <div style={{ paddingLeft: 20 }}>
      <Header
        activePage="reports"
        headerTitle={HEADER_TITLES.reports}
        titleActions={
          <TooltipContainer
            width={200}
            toolTipContent="Customize and save all page settings for quick access to your reports!"
          >
            <HeaderToolTip>
              <InfoIcon />
            </HeaderToolTip>
          </TooltipContainer>
        }
        headerRight={<LinkShareButton />}
      />
      <Spacer width="100%">
        <ReportsPopoverHeader>
          <ReportsPopoverHeaderLeft>
            <CentererVertical>
              <img src={FilterIcon} alt="" />
              <ReportsPopoverHeaderTitle>Filter:</ReportsPopoverHeaderTitle>
              <div style={{ marginRight: 8, whiteSpace: 'nowrap' }}>
                <SelectDropdownButton
                  name="reports-popover__report-type"
                  options={reportsOptions}
                  selected={selectedReportType}
                  onSelect={setSelectedReportType}
                />
              </div>
              <SearchBar
                value={reportsListSearchTerm}
                onChange={(e) => setReportsListSearchTerm(e.target.value)}
                onBlur={(e) => setReportsListSearchTerm(e.target.value)}
                onPressEnter={(e) => e.target.blur()}
                placeholder={'Search'}
              />
            </CentererVertical>
          </ReportsPopoverHeaderLeft>
          <InlineButton withBackground fontSize="14px" onClick={handleClickCreateNewReport}>
            Create new
          </InlineButton>
        </ReportsPopoverHeader>
        <ReportsList
          error={error}
          isLoading={isLoading}
          isFetching={isFetching}
          reportList={reportList}
          searchTerm={reportsListSearchTerm}
          handleClearSearch={handleClearSearch}
          handleClickCreateNewReport={handleClickCreateNewReport}
          handleNavigateToReportPage={handleNavigateToReportPage}
        />
      </Spacer>
      <LinkShareModal />
      <NewReportModal />
    </div>
  );
};
