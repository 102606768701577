import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { AppContext } from 'AppContext';
import { useInvoicesAPI } from 'api/billing';
import { ReactComponent as CloseIcon } from 'images/close-icon.svg';
import { SizedLoader } from 'components/Loaders';
import { ModalContainer, FullscreenModal } from 'components/Modal';
import { Centerer, Flexer, FlexerColumn } from 'components/Core';
import { BulkSendInvoicesModalTable } from './BulkSendInvoicesModalTable';
import { BulkSendInvoicesModalFields } from './BulkSendInvoicesModalFields';

const LoaderContainer = styled(Centerer)`
  height: 400px;
`;

const ContentWrapper = styled.div`
  background-color: var(--primaryGray);
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  width: 100%;
  z-index: 1;
`;

const StyledCloseIcon = styled(CloseIcon)`
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  height: 38px;
  width: 38px;

  rect {
    fill: var(--accentGray);
  }

  path {
    fill: var(--primaryBlack);
  }
`;

const Header = styled.div`
  font-size: 20px;
  font-weight: 900;
  padding: 28px 40px;
  border-bottom: 1px solid var(--primaryBlack5);
`;

export const BulkSendInvoicesModal = ({ invoiceIds, setShowModal, setInvoicesSelected }) => {
  const { orgId } = useContext(AppContext);

  const {
    data: { data: invoices },
    isLoading,
    operations: { refetch, bulkSend: bulkSendInvoices },
  } = useInvoicesAPI({
    orgId,
    invoiceIds,
    autoFetch: false,
  });

  useEffect(() => {
    if (invoiceIds.length > 0) {
      refetch();
    }
  }, [invoiceIds, refetch]);

  return (
    <ModalContainer data-cy="bulk-send-invoices-modal">
      <FullscreenModal>
        <ContentWrapper>
          {isLoading ? (
            <LoaderContainer>
              <SizedLoader size={40} />
            </LoaderContainer>
          ) : (
            <>
              <FlexerColumn>
                <Header>Bulk Send Invoices</Header>
                <Flexer>
                  <BulkSendInvoicesModalTable invoices={invoices} />
                  <BulkSendInvoicesModalFields
                    invoiceIds={invoiceIds}
                    bulkSendInvoices={bulkSendInvoices}
                    setInvoicesSelected={setInvoicesSelected}
                    setShowModal={setShowModal}
                  />
                </Flexer>
              </FlexerColumn>
              <StyledCloseIcon
                data-cy="bulk-send-invoices-modal__close-button"
                height={30}
                onClick={() => setShowModal(false)}
              />
            </>
          )}
        </ContentWrapper>
      </FullscreenModal>
    </ModalContainer>
  );
};
