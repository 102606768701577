import styled from 'styled-components';
import { Centerer, CentererVertical, Flexer } from 'components/Core';
import SegmentSliceIcon from 'images/segment-by-sector-icon.svg';

export const HelpButton = styled.div`
  opacity: 0.3;
  padding: 12px;
  width: 51px;
  height: 68px;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  border-radius: 12px;
  position: absolute;
  right: 20px;
  top: 20px;
  display: flex;
  flex-direction: column;
  border: 1px solid transparent;
  align-items: center;

  svg {
    margin-bottom: 8px;

    path {
      fill: var(--primaryGreen);
    }
  }

  &:hover {
    cursor: pointer;
    opacity: 1;
    background: white;
    border: 1px solid var(--neutralGray) !important;
    box-shadow: 0px 4px 20px var(--primaryBlack10) !important;
  }
`;

export const FilterTextBlock = styled.div`
  display: none;

  position: absolute;
  left: 20px;
  top: -10px;
  padding: 4px;

  background: #ffffff;
  border: 1px solid #e9e9e9;
  border-radius: 4px;

  font-weight: 900;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  color: var(--primaryBlack50);
`;

export const FilterContainer = styled.div`
  border-radius: ${(props) =>
    props.removeTopBorderRadius ? '0px 0px 16px 16px' : props?.removeBottomBorderRadius ? '16px 16px 0px 0px' : '16px'};
  overflow-y: ${(props) => (props.isOverflowAuto ? 'auto' : 'initial')};
  display: ${(props) => props.hidden && 'none'};
  border: 1px solid var(--accentGraySecond);
  position: relative;
  z-index: 21;
  transition: all ease 0.4s;
  background: white;

  &:hover {
    border: 1px solid var(--neutralGray);
    box-shadow: 8px 8px 60px var(--primaryBlack4);

    ${FilterTextBlock} {
      display: initial;
    }

    ${HelpButton} {
      cursor: pointer;
      opacity: 1;
      background: white;
      border: 1px solid var(--accentGraySecond);
      box-shadow: 0px 4px 20px var(--primaryBlack4);
    }
  }
`;

export const TrashButton = styled.div`
  margin-left: 14px;
  margin-right: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;

  svg {
    width: 20px;
    height: 20px;

    path {
      opacity: 0.3;
    }
  }

  &:hover {
    svg path {
      opacity: 1;
    }
  }
`;

export const FilterBox = styled.div`
  padding-left: 56px;
`;

export const ConditionsWrapper = styled.div`
  position: relative;
  z-index: 2;
  padding-top: ${({ hasSegment, showConditions }) => (hasSegment || !showConditions) && '40px'};
`;

export const ListDot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 100px;
  margin-right: 12px;
  opacity: 0.1;
  border: 2px solid var(--primaryBlack);
`;

export const Bracket = styled.div`
  font-size: 25px;
  opacity: 0.5;
  margin-bottom: 5px;
  margin-right: 2px;
`;

export const GroupConditions = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  .react-select__control {
    margin-right: 8px;
    white-space: nowrap;
    min-height: 24px !important;
  }

  .react-multiselect__control {
    margin-right: 8px;
    white-space: nowrap;
    min-height: 24px !important;

    max-width: 350px;
    min-width: 350px;
  }

  .react-multiselect__value-container {
    white-space: nowrap;
  }

  .react-multiselect__multi-value {
    background: none;
    padding: 0;
  }

  .react-multiselect__multi-value__label {
    color: var(--primaryBlue);
    font-weight: bold;
  }

  .react-select__indicator {
    padding: 0;
  }

  .react-multiselect__indicator {
    padding: 0;
  }
`;

export const FilterGroupAdd = styled.div`
  padding: 18px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const AddGroup = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 18px;
  color: rgba(0, 21, 46, 0.3);
  cursor: pointer;

  img {
    width: 34px;
    height: 34px;
    border-radius: 50%;
    margin-right: 14px;
  }
`;

export const GroupConditionType = styled.div`
  display: flex;
  align-items: center;
  font-weight: 900;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  color: var(--primaryBlue);
  margin: 14px 18px;
`;

export const ConditionInfo = styled.div`
  display: flex;
  align-items: center;
`;

export const OrSeparator = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  opacity: 0.5;
  margin: 0 8px;
`;

export const CondiditionsFooter = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  left: 131px;
  bottom: 14px;
`;

export const DeleteGroupButton = styled.div`
  cursor: pointer;
  position: absolute;
  top: -21px;
  right: -21px;
`;

export const AppliedFilters = styled.div`
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: rgba(0, 21, 46, 0.3);
  margin-right: 20px;

  strong {
    color: var(--primaryBlack);
  }
`;

export const ApplyButton = styled.button`
  font-weight: 900;
  font-size: 10px;
  line-height: 14px;
  border: none;
  padding: 8px 12px;
  background: var(--primaryGreen);
  box-shadow: 0px 4px 20px rgba(82, 181, 120, 0.2);
  border-radius: 100px;
  text-transform: uppercase;
  color: #ffffff;
  cursor: pointer;
  z-index: 3;

  &:hover {
    background: var(--secondaryGreen);
  }
`;

export const ApplyFiltersButton = styled.button`
  font-weight: 900;
  font-size: 10px;
  line-height: 14px;
  border: none;
  padding: 8px 12px;
  background: var(--primaryGreen);
  box-shadow: 0px 4px 20px rgba(82, 181, 120, 0.2);
  border-radius: 100px;
  text-transform: uppercase;
  color: #ffffff;
  cursor: pointer;
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  bottom: -20px;
  z-index: 1;

  pointer-events: ${({ isDisabled }) => isDisabled && 'none'};
  opacity: ${({ isDisabled }) => isDisabled && '0.3'};

  &:hover {
    background: var(--secondaryGreen);
  }
`;

export const FilterGroup = styled.div`
  background: #fff;
  border-radius: 10px;
  padding-bottom: 30px;
  overflow: auto;
  max-height: 410px;
  position: relative;
  box-shadow: 4px 4px 28px var(--primaryBlack5);
`;

export const GroupHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 18px;
  margin-bottom: 18px;
  border-bottom: 1px solid var(--primaryBlack5);
`;

export const GroupHeadName = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: rgba(0, 21, 46, 0.3);
`;

export const AddConditionButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 121px;
  height: 18px;
  color: var(--primaryGreen);
  text-transform: uppercase;
  font-weight: 900;
  font-size: 12px;
  line-height: 16px;
  cursor: pointer;
`;

export const CustomerFilterText = styled.strong`
  font-size: 14px;
  font-weight: bold;
  padding: 8px 10px;
  background: var(--primaryBlack5);
  border-radius: 6px;
  display: flex;

  span {
    margin: 0 4px;
  }
`;

export const CustomerLogicalOperator = styled.div`
  font-weight: 900;
  font-size: 10px;
  line-height: 14px;
  opacity: 0.3;
  margin: 0 10px;
`;

export const CustomerDeleteIcon = styled.div`
  padding-left: 12px;
  margin-left: 10px;
  border-left: 1px solid lightgrey;
  cursor: pointer;

  &:hover {
    svg {
      opacity: 1;

      path {
        fill: var(--primaryRed);
      }
    }
  }

  svg {
    opacity: 0.3;
  }
`;

export const FilterGroupCondition = styled.div`
  padding: 20px 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  &:before {
    position: absolute;
    content: '';
    width: 100%;
    height: 1px;
    background: var(--primaryBlack5);
    left: 0;
    top: 50%;
  }

  .css-kaqsrx-control {
    background-color: var(--accentGray);
  }
`;

export const FilterHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
`;

export const StyledSettingIcon = styled.div`
  margin-right: 20px;
  margin-left: 22px;
  cursor: pointer;
`;

export const NoConditionsCard = styled.div`
  font-size: 14px;
  line-height: 20px;
  opacity: 0.5;
  text-align: center;
  margin: 22px auto;
`;

export const LogicalTitle = styled.div`
  font-weight: bold;
  font-size: 12px;
  margin-left: 14px;
  line-height: 16px;
  color: rgba(0, 21, 46, 0.3);
  text-transform: uppercase;
`;

export const FilterConditionHead = styled.div`
  color: var(--primaryBlue);
  position: relative;
  background: rgba(0, 133, 255, 0.1);
  border-radius: 4px;
  padding: ${({ padding }) => padding ?? '3px 4px'};
  margin: ${({ noMargin }) => (noMargin ? '0' : '0 8px')};
  cursor: pointer;
  font-weight: ${({ fontWeight }) => fontWeight ?? '700'};
  font-size: ${({ fontSize }) => fontSize ?? 'inherit'};
  text-transform: ${({ allCaps }) => (allCaps ? 'uppercase' : 'capitalize')};

  &:hover {
    color: var(--secondaryBlue);
    background: rgba(0, 133, 255, 0.2);
  }

  &:focus {
    color: var(--secondaryBlue);
    background: rgba(0, 133, 255, 0.2);
  }
`;

export const FilterRecurring = styled.div`
  color: var(--primaryGreen);
  position: relative;
  border-bottom: 2px dashed var(--primaryGreen);
  margin: 0 8px;
  cursor: pointer;
  font-weight: 700;
`;

export const ShadowWrapper = styled.div`
  box-shadow: ${({ noShadow }) => !noShadow && '8px 8px 40px var(--primaryBlack2)'};
  position: relative;
`;

export const ConditionLetter = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  position: relative;
  top: 10px;
  opacity: 0.3;
  margin-right: 20px;
`;

export const FilterHeadInner = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
  width: 100%;
  margin-bottom: 0;

  strong {
    font-weight: 700;
    margin-left: 10px;
  }
`;

export const FilterLabel = styled.span``;

export const FilterBtn = styled.div`
  margin-right: 20px;

  a {
    display: flex;
    white-space: nowrap;
    align-items: center;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    position: relative;
    right: -2px;
    color: var(--secondaryBlue);

    &.active {
      color: var(--primaryBlue);

      img {
        transform: rotate(0deg);
      }
    }

    img {
      transform: rotate(180deg);
      margin-left: 8px;
      transition: all ease 0.4s;
    }
  }
`;

export const StyledSelectorPopover = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  width: ${(props) => (props.width ? props.width : 'fit-content')};
  min-width: ${({ minWidth }) => minWidth ?? '240px'};
  top: 33px;
  left: 0;
  background: white;
  border: 1px solid var(--accentGray);
  padding: ${({ noOptionsStyles }) => !noOptionsStyles && '0 6px'};
  box-shadow: 8px 8px 40px var(--primaryBlack10);
  border-radius: 12px;
  white-space: nowrap;
  z-index: 100;
  max-height: 310px;
  overflow: auto;
`;

export const SearchWrapperItem = styled.div`
  position: relative;
  width: 100%;
  height: 38px;
  font-size: 12px;
  text-align: ${({ center }) => (center ? 'center' : 'left')};
  cursor: text;

  padding: 10px;
  display: flex;
  align-items: center;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: 38px;
    border-bottom: 1px solid var(--primaryBlack5);
  }
`;

export const SelectorItem = styled.div`
  position: relative;
  width: 100%;
  height: 38px;
  font-size: 12px;
  text-align: ${({ center }) => (center ? 'center' : 'left')};
  font-weight: 700;
  color: ${({ current }) => (current ? 'var(--primaryBlue)' : 'var(--primaryBlack)')};
  background-color: ${({ current }) => (current ? 'var(--primaryBlue2)' : '')};
  pointer-events: ${({ current }) => (current ? 'none' : 'initial')};
  cursor: pointer;
  text-transform: capitalize;
  padding: 10px;
  display: flex;
  align-items: center;

  &:hover {
    background-color: var(--primaryBlack2);
    opacity: 0.7;
  }

  &::after {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: 38px;
    border-bottom: 1px solid var(--primaryBlack5);
  }

  &:last-of-type {
    &::after {
      border-bottom: none;
    }
  }

  svg {
    display: block;
    fill: var(--primaryYellow);
    border: 2px solid #ffffff;
    border-radius: 8px;
  }
`;

export const NewSegmentSelectorItem = styled(SelectorItem)`
  text-transform: none;
  color: var(--primaryBlack);
  background-image: url(${SegmentSliceIcon});
  background-repeat: no-repeat;
  background-position: right bottom;

  svg path {
    fill: var(--primaryBlue);
  }
`;

export const CustomerFilterContainer = styled.div`
  padding: 22px 0;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
`;

export const SettingsContainer = styled.div`
  display: flex;
  white-space: nowrap;
  align-items: center;
`;

export const SwitchLabel = styled.span`
  font-weight: 900;
  font-size: 10px;
`;

export const ExportButton = styled.div`
  &:hover {
    cursor: pointer;
  }

  display: flex;
  align-items: center;
  color: #fff;
  background: var(--primaryGreen);
  box-shadow: 0px 4px 20px 0px #52b57833;
  border-radius: 100px;
  height: 14px;
  font-size: 10px;
  font-weight: 900;
  padding: 12px;
`;

export const FilterWrapper = styled(CentererVertical)``;

export const MetadataFilterWrapper = styled.div`
  border-top: 1px dashed rgba(0, 21, 46, 0.2);
  padding: 15px;
`;

export const SegmentLabelIcon = styled(Centerer)`
  gap: 4px;
  padding: 4px 8px;
  margin-right: 8px;
  background: white;
  border-radius: 8px;
  border: 1px solid var(--primaryBlack10);
  z-index: 1;

  font-weight: 900;
  font-size: 10px;
  opacity: 0.3;
  line-height: 12px;
  text-transform: uppercase;
  color: var(--primaryBlack);

  &:hover {
    cursor: pointer;
    opacity: 0.7;
    background: var(--primaryBlack2);
    border: 1px solid var(--primaryBlack10);
  }
`;

export const SegmentLabelWrapper = styled(Flexer)`
  svg {
    display: block;
    position: absolute;
    top: -4px;
    right: -6px;
    fill: var(--primaryYellow);
    border: 2px solid #ffffff;
    border-radius: 8px;
    z-index: 51;
  }
`;

export const ExploreInsightsLabel = styled.div`
  position: absolute;
  right: -140px;
  font-weight: 400;
  font-size: 14px;
  font-style: italic;
  line-height: 20px;
  color: var(--primaryBlack50);
  margin: 0 8px;

  svg {
    width: 16px;
    height: 16px;
    margin-left: 4px;
    vertical-align: sub;
    cursor: pointer;
    g {
      transition: opacity ease 0.4s;
    }
    &:hover {
      g {
        opacity: 1;
      }
    }
  }
`;

export const YellowBold = styled.span`
  color: var(--primaryYellow);
  font-weight: 900;
  background-color: none;
`;
