import React, { useCallback, useContext, useState } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { EVENTS } from 'consts/analytics';
import { useAnalytics } from 'utils/hooks';
import {
  Modal,
  ModalBody,
  ModalButton,
  ModalCloseIcon,
  ModalContainer,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalSubtitleText,
  ModalTitleText,
} from 'components/Modal';
import { ForecastingContext } from '../ForecastingContext';
import { DeleteWarningCard } from './Shared/styles';

dayjs.extend(utc);

const DeleteForecastModal = ({ scenario, onClose }) => {
  const { removeForecast } = useContext(ForecastingContext);
  const handleDeleteForecast = () => {
    removeForecast({ id: scenario.id });
    onClose();
  };

  return (
    <ModalContainer data-cy="delete-forecast-modal">
      <Modal maxWidth="440px" height="auto" overflow="visible">
        <ModalCloseIcon onClose={onClose} />
        <ModalHeader>
          <ModalTitle>
            <ModalTitleText>Remove Scenario</ModalTitleText>
          </ModalTitle>
        </ModalHeader>

        <ModalBody>
          <ModalSubtitleText>Do you really want to remove the next scenario? This cannot be un-done.</ModalSubtitleText>
          <DeleteWarningCard>
            <div>{scenario.name}</div>
            <div>
              {dayjs.utc(scenario.start_date).format('MMM YYYY')} - {dayjs.utc(scenario.end_date).format('MM YYYY')}
            </div>
          </DeleteWarningCard>
        </ModalBody>

        <ModalFooter flexEnd>
          <ModalButton onClick={onClose} data-cy="delete-forecast-modal__cancel-button">
            Cancel
          </ModalButton>
          <ModalButton delete data-cy="delete-forecast-modal__delete-button" onClick={handleDeleteForecast}>
            Remove
          </ModalButton>
        </ModalFooter>
      </Modal>
    </ModalContainer>
  );
};

export const useDeleteForecastModal = (props) => {
  const { trackEvent } = useAnalytics();
  const [showModal, setShowModal] = useState(false);
  const [scenario, setScenario] = useState();

  const openModal = useCallback(
    ({ scenario }) => {
      trackEvent({
        name: EVENTS.OPEN_DELETE_FORECAST_SCENARIO,
        properties: {
          scenarioId: scenario.id,
        },
      });

      setScenario(scenario);
      setShowModal(true);
    },
    [trackEvent, setShowModal],
  );

  const Modal = useCallback(
    () =>
      showModal ? <DeleteForecastModal {...props} scenario={scenario} onClose={() => setShowModal(false)} /> : null,
    [showModal, setShowModal, scenario, props],
  );

  return {
    openModal,
    Modal,
  };
};
