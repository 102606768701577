import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { usePortal } from 'components/Portal';
import { ToolsIcon } from 'components/Icons';
import { UnsentRemindersPopup } from 'views/Billing/Common/UnsentRemindersPopup';
import { ReactComponent as PreviewIcon } from 'images/file-invoice.svg';
import { ReactComponent as RowActionsIcon } from 'images/row_actions.svg';

const RowActionToogle = styled.div`
  height: 28px;
  cursor: pointer;
`;

const ActionPopover = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: white;
  border: 1px solid var(--accentGray);
  box-shadow: 16px 16px 60px 0px var(--primaryBlack20);
  border-radius: 12px;
  z-index: 5;
`;

const StyledRowActionsIcon = styled(RowActionsIcon)`
  path {
    fill-opacity: 0;
  }

  circle {
    fill: var(--primaryBlack50);
  }

  &:hover {
    path {
      fill-opacity: 0.05;
    }

    circle {
      fill: var(--primaryBlack);
    }
  }
`;

export const InvoiceActionItem = styled.div`
  display: flex;
  white-space: nowrap;
  align-items: center;
  width: 100%;
  height: 48px;
  padding: 16px 12px;
  border-bottom: 1px solid var(--accentGraySecond);
  cursor: pointer;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;

  &:hover {
    background-color: var(--primaryBlack3);
    color: var(--primaryBlack70);

    svg {
      opacity: 1;
    }
  }

  svg {
    width: 16px;
    height: 16px;
    margin-right: 8px;
    opacity: 0.5;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}
`;

export const InvoiceTableActions = ({ invoice, onInvoiceClick }) => {
  const [showManageRemindersPopover, setShowManageRemindersPopover] = useState(false);

  const { triggerRef, togglePortal, isPortalVisible, hidePortal, Portal } = usePortal({ XOffset: -120, YOffset: -5 });

  const unsentReminders = invoice?.reminders?.filter(({ sent_at }) => !sent_at);

  useEffect(() => {
    !isPortalVisible && setShowManageRemindersPopover(false);
  }, [isPortalVisible]);

  return (
    <RowActionToogle ref={triggerRef}>
      <StyledRowActionsIcon onClick={togglePortal} data-cy={`invoice-table__actions-button`} />

      {isPortalVisible && (
        <Portal>
          <ActionPopover data-cy={`invoice-table__actions-popover`}>
            <InvoiceActionItem
              onClick={() => {
                hidePortal();
                onInvoiceClick(invoice);
              }}
              data-cy={`invoice-table__actions-select--detaild`}
            >
              <PreviewIcon />
              Open Details
            </InvoiceActionItem>

            <InvoiceActionItem
              onClick={() => setShowManageRemindersPopover(!showManageRemindersPopover)}
              remove
              data-cy={`invoice-table__actions-select--manage-reminders`}
              disabled={!unsentReminders?.length || invoice?.auto_charge}
            >
              <ToolsIcon />
              Manage Reminders
            </InvoiceActionItem>
          </ActionPopover>

          {showManageRemindersPopover && (
            <UnsentRemindersPopup
              onRemiderClick={(reminder) => {
                onInvoiceClick({
                  id: invoice?.id,
                  invoicing_schedule_id: invoice?.invoicing_schedule_id,
                  selectedReminder: reminder,
                });
              }}
              unsentReminders={unsentReminders}
              XOffset={-118}
              YOffset={45}
            />
          )}
        </Portal>
      )}
    </RowActionToogle>
  );
};
