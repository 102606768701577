import React from 'react';
import { COLORS } from 'consts/colors';
import { ReactComponent as EmptyStateIllustration } from 'images/report-builder-illustration.svg';
import { ReactComponent as BigPlusIcon } from 'images/report-plus-icon.svg';
import { ReactComponent as PlusIcon } from 'images/plus.svg';
import { Centerer, FlexerColumn, Spacer } from 'components/Core';
import { IconButton } from 'components/Buttons';
import { AddWidgetDropdown } from '../AddWidgetDropdown';
import { Title, Card, Description } from './styles';

export const ReportBuilderEmptyState = () => (
  <Centerer width="100%" height="100%" direction="column">
    <Centerer gap="20px">
      <Card width="380px" height="200px">
        <EmptyStateIllustration />
      </Card>
      <Card width="190px" height="200px">
        <BigPlusIcon />
      </Card>
    </Centerer>

    <Spacer height="40px" />

    <FlexerColumn gap="8px">
      <Title>Add a new metric or text to get started.</Title>
      <Description>
        Learn more about{' '}
        <a
          data-cy="report-builder-empty-state__explain-link"
          href="https://www.subscript.com/the-dive"
          target="_blank"
          rel="noreferrer"
        >
          Key SaaS metrics
        </a>
      </Description>
    </FlexerColumn>

    <Spacer height="24px" />

    <AddWidgetDropdown
      addWidgetButton={({ active, onClick }) => (
        <IconButton
          filled={true}
          color={COLORS.GREEN}
          icon={<PlusIcon />}
          iconFillColor="#FFF"
          active={!active}
          onClick={onClick}
        >
          Add Widget
        </IconButton>
      )}
    />
  </Centerer>
);
