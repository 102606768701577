import { useCallback, useRef, useState } from 'react';
import { useClickOutside } from 'utils/hooks';
import { Portal as PortalContainer } from './Portal';

export const usePortal = ({
  XOffset = -10,
  YOffset = -10,
  fixed = false,
  instantVisible = false,
  showAbove = false,
  hideOnOutsideClick = true,
  onPortalClose = () => {},
} = {}) => {
  const triggerRef = useRef();
  const [style, setStyle] = useState(null);
  const [visible, setVisible] = useState(instantVisible);

  const showPortal = useCallback(() => {
    if (!triggerRef.current) return;

    const { top, left, bottom, height } = triggerRef.current.getBoundingClientRect();

    setStyle({
      zIndex: 999,
      position: fixed ? 'fixed' : 'absolute',
      left: left + XOffset - document.getElementById('main-navigation')?.offsetWidth,
      top: showAbove
        ? undefined
        : top + height - YOffset + (fixed ? 0 : document.getElementById('main-content')?.scrollTop),
      bottom: showAbove
        ? bottom - top - YOffset - (fixed ? 0 : document.getElementById('main-content')?.scrollTop)
        : undefined,
    });

    setVisible(true);
  }, [fixed, XOffset, showAbove, YOffset]);

  const hidePortal = useCallback(() => {
    setVisible(false);
    onPortalClose();
  }, [onPortalClose]);

  const togglePortal = useCallback(() => (visible ? hidePortal() : showPortal()), [visible, hidePortal, showPortal]);

  const portalContentRef = useClickOutside((event) => {
    if (hideOnOutsideClick && !triggerRef.current?.contains(event.target)) {
      hidePortal();
    }
  });

  const Portal = useCallback(
    ({ children }) =>
      visible ? (
        <PortalContainer>
          <div style={style} ref={portalContentRef}>
            {children}
          </div>
        </PortalContainer>
      ) : null,
    [visible, style, portalContentRef],
  );

  return {
    triggerRef,
    showPortal,
    hidePortal,
    togglePortal,
    Portal,
    isPortalVisible: visible,
  };
};
