import styled from 'styled-components';
import { ModalTitle } from 'components/Modal';
import { ReactComponent as CircleCheck } from 'images/transaction_check.svg';

export const StyledModalTitle = styled(ModalTitle)`
  padding-left: 40px;
  padding-right: 40px;
`;

export const SyncedIcon = styled(CircleCheck)`
  margin-right: 10px;
  height: 16px;
  width: 16px;

  rect {
    fill: var(--lightGreen);
  }
`;
