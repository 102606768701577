import React from 'react';
import styled from 'styled-components';
import { FormikCustomCheckbox, FormikCustomRadio, FormikCustomSelector, InputLabel } from 'components/Controls';
import { Flexer, FlexerColumn, FlexerRow, Spacer } from 'components/Core';
import { LabelIconContainer } from 'components/Blocks';
import { CARD_SIZES } from 'views/Dashboard/Common/Card';

const WIDTH_OPTIONS = [
  {
    value: CARD_SIZES.BIG,
    width: 100,
    label: '100%',
  },
  {
    value: CARD_SIZES.LARGE,
    width: 50,
    label: '50%',
  },
  {
    value: CARD_SIZES.MEDIUM,
    width: 33,
    label: '33%',
  },
  {
    value: CARD_SIZES.SMALL,
    width: 16,
    label: '16%',
  },
];

const WidthCard = styled(Flexer)`
  padding: 4px;
  gap: 4px;
  width: 82px;
  height: 40px;
  border: ${({ active }) => `1px solid ${active ? 'var(--accentGrayThird)' : 'var(--accentGraySecond)'}`};
  border-radius: 8px;
  cursor: pointer;
`;

const WidthWidgetExample = styled.div`
  width: 100%;
  background: var(--primaryBlack5);
  border: 1px solid var(--primaryBlack5);
  border-radius: 8px;

  &:first-child {
    background: ${({ active }) => (active ? 'var(--primaryBlue20)' : 'var(--primaryBlue5)')};
    border: ${({ active }) => `1px solid ${active ? 'var(--primaryBlue)' : 'var(--primaryBlue10)'}`};
  }
`;

export const WidgetMetricsForm = ({ metricOptions, values, setFieldValue }) => {
  return (
    <>
      <FormikCustomSelector
        name="id"
        label="Metric"
        options={metricOptions}
        handleChange={(option) => {
          setFieldValue('id', option.value);
          if (!option.hasTTMOption) {
            // If it does not have TTM option we will reset the value
            setFieldValue('isTTM', false);
          }
        }}
      />

      <FormikCustomCheckbox
        data-cy="metric-widget-modal__isTTM-checkbox"
        name="isTTM"
        label="Trailing Twelve Months (TTM)"
        disabled={!metricOptions.find((option) => option.value === values.id)?.hasTTMOption}
      />

      <Spacer height="0" />

      <FlexerColumn>
        <LabelIconContainer>
          <InputLabel>Card width (relative to a row width)</InputLabel>
        </LabelIconContainer>
        <FlexerRow gap="20px">
          {WIDTH_OPTIONS.map((option) => (
            <WidthCard
              key={option.value}
              active={values.size === option.value}
              onClick={() => setFieldValue('size', option.value)}
            >
              {Array.from({ length: Math.floor(100 / option.width) }).map((_, index) => (
                <WidthWidgetExample key={index} active={values.size === option.value} />
              ))}
            </WidthCard>
          ))}
        </FlexerRow>
        <Spacer height="8px" />
        <FormikCustomRadio
          name="size"
          suffix="metric-widget-modal__size-radio"
          options={WIDTH_OPTIONS}
          justifyContent="space-between"
        />
      </FlexerColumn>
    </>
  );
};
