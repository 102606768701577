import { useState } from 'react';
import styled from 'styled-components';

import { InputLabel } from 'components/Controls';
import { FlexBetweenContainer, Flexer } from 'components/Core';
import { templateToHTMLString } from 'utils/htmlUtils';
import { ContentEditableInput } from 'components/Controls/FormikCustomInput/ContentEditableInput';
import { MemoTemplateDropdownButton } from 'views/Billing/Common/useMemoTemplateDropdownButton';
import { CircleLoader } from 'components/Loaders';

import { MemoTemplatesActionsModal } from '../DefaultTemplate/MemoTemplatesActionsModal';

const FlexShrink = styled.div`
  flex-shrink: 1;
`;

const EditButton = styled.div`
  background-color: var(--primaryBlue10);
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 700;
  color: var(--primaryBlue);

  &:hover {
    cursor: pointer;
  }
`;

export const BillingEntityMemoInput = ({
  values,
  setFieldValue,
  templates,
  name,
  label,
  generalTags,
  transactionMetadataTags,
  customerMetadataTags,
  transactionCustomerMetadataTags,
  isLoadingTemplates,
}) => {
  const selectedTemplate = templates.find((template) => template.id === values?.[name]);

  const [showCreateMemoModal, setShowCreateMemoModal] = useState(false);
  const [showEditMemoModal, setShowEditMemoModal] = useState(false);

  const availableTags = [
    ...generalTags,
    ...transactionMetadataTags,
    ...customerMetadataTags,
    ...transactionCustomerMetadataTags,
  ];

  return (
    <>
      <FlexBetweenContainer>
        <Flexer alignItems="center" gap="4px">
          <InputLabel fontSize="12px" margin="0px" fontWeight={700} opacity={1}>
            {label}
          </InputLabel>
          {isLoadingTemplates ? (
            <CircleLoader width="16px" height="16px" />
          ) : (
            <MemoTemplateDropdownButton
              templates={templates}
              name={name}
              buttonText={selectedTemplate?.name ?? 'Select Template'}
              isCreatable={true}
              isClearable={!!selectedTemplate}
              XOffset={-20}
              generalTags={generalTags}
              transactionMetadataTags={transactionMetadataTags}
              customerMetadataTags={customerMetadataTags}
              transactionCustomerMetadataTags={transactionCustomerMetadataTags}
              onCreate={() => setShowCreateMemoModal(true)}
              onSelect={(id) => setFieldValue(name, id)}
            />
          )}
        </Flexer>

        {!!selectedTemplate?.id && (
          <FlexShrink>
            <EditButton data-cy={`entity-modal__${name}__edit-button`} onClick={() => setShowEditMemoModal(true)}>
              Edit template
            </EditButton>
          </FlexShrink>
        )}
      </FlexBetweenContainer>
      <ContentEditableInput
        name="footer_memo"
        data-cy={`entity-modal__${name}__content`}
        minHeight="166px"
        HTMLString={templateToHTMLString({ text: selectedTemplate?.content ?? '', availableTags })}
        availableTags={availableTags}
        type="HTMLEdit"
        disabled
      />

      {(showEditMemoModal || showCreateMemoModal) && (
        <MemoTemplatesActionsModal
          invoiceMemoTemplateId={showEditMemoModal ? selectedTemplate?.id : null}
          onClose={() => {
            setShowEditMemoModal(false);
            setShowCreateMemoModal(false);
          }}
          generalTags={generalTags}
          transactionMetadataTags={transactionMetadataTags}
          customerMetadataTags={customerMetadataTags}
          transactionCustomerMetadataTags={transactionCustomerMetadataTags}
          templates={templates}
        />
      )}
    </>
  );
};
