import axios from 'axios';
import { API_URL } from 'api/consts';

const buildUrl = ({ endpoint }) => {
  const baseUrl = `${API_URL}/users/${endpoint}`;
  return baseUrl;
};

export const getUsers = async ({ orgId }) => {
  const url = `${API_URL}/organizations/${orgId}/users`;
  const { data } = await axios.get(url);

  return data;
};

export const inviteUser = async ({ orgId, body }) => {
  const url = `${API_URL}/organizations/${orgId}/users/invite`;
  const { data } = await axios.post(url, body);

  return data;
};

export const deleteUser = async ({ orgId, userId }) => {
  await axios.delete(`${API_URL}/organizations/${orgId}/users/${userId}`);
};

export const getProfileByEmail = async ({ email }) => {
  const { data } = await axios.get(buildUrl({ endpoint: 'profile' }), { params: { email } });
  return data;
};

export const resendVerificationEmail = async ({ auth0UserId }) => {
  const { data } = await axios.post(`${API_URL}/resend-verification-email`, { auth0UserId });
  return data;
};
