export const METADATA_FIELD_TYPE = {
  TRANSACTION: 'transaction',
  CUSTOMER: 'customer',
  TRANSACTION_CUSTOMER: 'transaction_customer',
};

const METADATA_FIELD_TYPE_LABELS = {
  [METADATA_FIELD_TYPE.TRANSACTION]: '(transaction metadata)',
  [METADATA_FIELD_TYPE.CUSTOMER]: '(customer metadata)',
  [METADATA_FIELD_TYPE.TRANSACTION_CUSTOMER]: '(transaction customer metadata)',
};

export const metadataToOptions = ({ metadataFields, type = '' }) =>
  metadataFields.map((metadataField) => ({
    label: `${metadataField} ${METADATA_FIELD_TYPE_LABELS[type]}`,
    value: `${type}_metadata_${metadataField}`,
  }));
