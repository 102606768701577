import React from 'react';
import { ReactComponent as ChartVisualizerIcon } from 'images/chart-1-2.svg';
import { Flexer } from 'components/Core/Flex';
import { TooltipContainer } from 'components/Tooltip';
import { LeftSide, SelectedCounter, Option } from './styles';

export const SelectablePill = ({ options, selected, setSelected }) => (
  <Flexer style={{ marginBottom: 16 }}>
    <LeftSide>
      <ChartVisualizerIcon style={{ margin: 16 }} />
      <SelectedCounter>{selected.length}</SelectedCounter>
    </LeftSide>
    <Flexer wrapRow>
      {options.map(({ key, label }) => {
        return (
          <Option
            key={key}
            data-cy={`selectable-pill-option__${key}`}
            selected={selected.includes(key)}
            onClick={() => {
              if (selected.includes(key)) {
                setSelected(selected.filter((item) => item !== key));
              } else {
                if (selected.length === 2) return;
                setSelected([...selected, key]);
              }
            }}
          >
            <TooltipContainer
              width={150}
              isVisible={selected.length === 2}
              toolTipContent="A maximum of 2 metrics can be selected"
            >
              {label}
            </TooltipContainer>
          </Option>
        );
      })}
    </Flexer>
  </Flexer>
);
