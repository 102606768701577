import styled from 'styled-components';

export const ModalFooter = styled.footer`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 12px 40px;
  white-space: nowrap;
  margin-top: auto;
  background: var(--primaryGray);
  border-top: 1px solid var(--accentGraySecond);
`;
