import { cssVar } from 'polished';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import { Centerer } from 'components/Core';
import { IconButton } from 'components/Buttons';
import { FilePlus } from 'components/Icons';
import { ReactComponent as BillingEmptyToDoIcon } from 'images/billing-empty-invoices.svg';
import { COLORS } from 'consts/colors';

const EmptyState = styled(Centerer)`
  flex-direction: column;
  gap: 20px;
`;

const EmptyStateHeading = styled.span`
  font-weight: 900;
  font-size: 20px;
`;

const EmptyStateSubText = styled.span`
  font-size: 14px;
`;

export const BillingEmptyState = () => {
  const history = useHistory();

  return (
    <EmptyState data-cy="billing__empty-state">
      <BillingEmptyToDoIcon />
      <EmptyStateHeading>You don't have any data</EmptyStateHeading>
      <EmptyStateSubText>To start using Subscript billing, create new invoicing schedules</EmptyStateSubText>
      <IconButton
        icon={<FilePlus />}
        filled
        border
        color={COLORS.GREEN}
        colorOnHover={cssVar('--secondaryGreen')}
        iconFillColor="white"
        onClick={() => history.push('/billing/review-transactions')}
      >
        Create Schedule
      </IconButton>
    </EmptyState>
  );
};

export const AgingReportEmptyState = () => {
  const history = useHistory();

  return (
    <EmptyState data-cy="billing__empty-state">
      <BillingEmptyToDoIcon />
      <EmptyStateHeading>There are no bills awaiting payment</EmptyStateHeading>
      <EmptyStateSubText>The AR Aging Report will show up once there are bills awaiting payment</EmptyStateSubText>
      <IconButton
        icon={<FilePlus />}
        filled
        border
        color={COLORS.GREEN}
        colorOnHover={cssVar('--secondaryGreen')}
        iconFillColor="white"
        onClick={() => history.push('/billing/dashboard')}
      >
        Go To Dashboard
      </IconButton>
    </EmptyState>
  );
};
