import styled from 'styled-components';
import { Centerer } from 'components/Core';
import { TableCell } from 'components/Table/CompactNumericTable';

export const StageName = styled(Centerer)`
  color: var(--primaryBlack);
  font-size: 12px;
  font-weight: 900;
  line-height: 16px;
  padding: 4px 0;

  &:hover {
    cursor: pointer;
  }

  svg {
    margin-left: 8px;
  }
`;

export const StyledTableCell = styled(TableCell)`
  color: ${({ blurred, canExpand }) =>
    blurred ? 'var(--primaryBlack50)' : canExpand ? 'var(--primaryBlack)' : 'var(--primaryBlack70)'};
`;
