import React, { createContext, useCallback, useContext, useState } from 'react';
import dayjs from 'dayjs';
import { isEmpty, pick } from 'lodash';
import { INVOICE_ITEM_TYPES } from 'views/Billing/consts';
import { TransactionContext } from 'shared/TransactionContent/TransactionContext';
import { InvoicingScheduleContext } from 'views/Billing/InvoicingScheduleModal/InvoicingScheduleContext';

export const InvoicingScheduleChangesContext = createContext();

export const InvoicingScheduleChangesContainer = ({ children }) => {
  const scheduleContext = useContext(InvoicingScheduleContext);
  const transactionContext = useContext(TransactionContext);

  const customer = scheduleContext?.customer || transactionContext?.customer || null;

  const [changedInvoices, setChangedInvoices] = useState([]);
  const [acceptedChanges, setAcceptedChanges] = useState({
    changedInvoices: [],
    invoicesToDelete: [],
    invoicesToInsert: [],
  });
  const [showInvoicesPreviewModal, setShowInvoicesPreviewModal] = useState(false);

  const setInitialAcceptedChanges = useCallback(({ updateData, invoicingSchedule }) => {
    const changedInvoices = (updateData?.changedInvoices ?? []).map((updatedInvoice) => {
      const existingInvoice = invoicingSchedule?.invoices?.find((invoice) => invoice?.id === updatedInvoice.id);
      // added charges (tax items) are excluded from the changed invoices, we want to add this back on update
      const addedChargesToKeep = (existingInvoice?.invoice_items ?? []).filter(
        ({ type }) =>
          Object.values(INVOICE_ITEM_TYPES).includes(type) &&
          !updatedInvoice.invoice_items.some((item) => item.type === type), // Only keep if not in updatedInvoice
      );
      return {
        ...updatedInvoice,
        invoice_items: [...updatedInvoice.invoice_items, ...addedChargesToKeep],
        original: {
          ...pick(existingInvoice, ['date', 'currency']),
          // changed invoices will only show total amount of non-tax items,
          // to be consistent we'll only show the same for existing invoice amount
          amount: existingInvoice?.invoice_items
            ?.filter(({ type }) => !Object.values(INVOICE_ITEM_TYPES).includes(type))
            ?.reduce((sum, { amount }) => sum + amount, 0),
        },
      };
    });

    const initialAcceptedChanges = {
      changedInvoices,
      invoicesToInsert:
        updateData?.invoicesToInsert?.filter(({ date }) => dayjs.utc(date).isSameOrAfter(dayjs.utc())) || [],
      invoicesToDelete: updateData?.invoicesToDelete?.map(({ id }) => ({ id })) || [],
    };

    setAcceptedChanges(initialAcceptedChanges);
    setChangedInvoices(changedInvoices);
  }, []);

  const handleInvoiceChangeSelection = ({ invoice, key }) => {
    setAcceptedChanges((prevState) => {
      const updatedList = prevState[key].some((item) =>
        key === 'invoicesToInsert' ? item.date === invoice.date : item.id === invoice.id,
      )
        ? prevState[key].filter((item) =>
            key === 'invoicesToInsert' ? item.date !== invoice.date : item.id !== invoice.id,
          )
        : [...prevState[key], key === 'invoicesToDelete' ? { id: invoice.id } : invoice];

      return { ...prevState, [key]: updatedList };
    });
  };

  return (
    <InvoicingScheduleChangesContext.Provider
      value={{
        customer,

        showInvoicesPreviewModal,
        setShowInvoicesPreviewModal,

        changedInvoices,
        acceptedChanges,
        setInitialAcceptedChanges,
        handleInvoiceChangeSelection,

        isConfirmBtnDisabled:
          isEmpty(acceptedChanges.changedInvoices) &&
          isEmpty(acceptedChanges.invoicesToInsert) &&
          isEmpty(acceptedChanges.invoicesToDelete),
      }}
    >
      {children}
    </InvoicingScheduleChangesContext.Provider>
  );
};
