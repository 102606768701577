import React from 'react';
import { SelectDropdownButton } from 'components/Buttons';
import {
  INFLUX_BOOLEAN_TO_SELECTOR,
  INFLUX_SELECTOR_TO_BOOLEAN,
  STORAGE_TOGGLES_KEYS,
  TOGGLES_SELECTORS_DESCRIPTIONS,
  TOGGLES_SELECTORS_DESCRIPTIONS_DETAILS_KEYS,
  TOGGLES_SELECTORS_KEYS,
} from './consts';
import { CustomOptionItem, CustomOptionText, CustomOptionTitle } from './styles';

export const GlobalToggleInflux = ({ globalTogglesState, setGlobalTogglesState }) => (
  <SelectDropdownButton
    noOptionsStyles
    showSelectedDirectly
    name={STORAGE_TOGGLES_KEYS.IS_INFLUX}
    options={{
      [TOGGLES_SELECTORS_KEYS.DISABLE_INFLUX]: (
        <CustomOptionItem>
          <CustomOptionTitle>Disable In Flux</CustomOptionTitle>
          <CustomOptionText>{TOGGLES_SELECTORS_DESCRIPTIONS[TOGGLES_SELECTORS_KEYS.DISABLE_INFLUX]}</CustomOptionText>
        </CustomOptionItem>
      ),

      [TOGGLES_SELECTORS_KEYS.ENABLE_INFLUX]: (
        <CustomOptionItem>
          <CustomOptionTitle>Enable In Flux</CustomOptionTitle>
          <CustomOptionText>
            {TOGGLES_SELECTORS_DESCRIPTIONS[TOGGLES_SELECTORS_KEYS.ENABLE_INFLUX]}
            <br />
            <br />
            {TOGGLES_SELECTORS_DESCRIPTIONS[TOGGLES_SELECTORS_DESCRIPTIONS_DETAILS_KEYS.ENABLE_INFLUX_DETAILS]}
          </CustomOptionText>
        </CustomOptionItem>
      ),
    }}
    selected={INFLUX_BOOLEAN_TO_SELECTOR[globalTogglesState[STORAGE_TOGGLES_KEYS.IS_INFLUX]]}
    onSelect={(key) =>
      setGlobalTogglesState({
        name: STORAGE_TOGGLES_KEYS.IS_INFLUX,
        value: INFLUX_SELECTOR_TO_BOOLEAN[key],
      })
    }
  />
);
