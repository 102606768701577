import { cssVar } from 'polished';
import React, { useContext } from 'react';
import { Bar, defaults } from 'react-chartjs-2';
import { AppContext } from 'AppContext';
import { amountWithAbbreviation } from 'utils/formatters';

const CHART_COLORS = {
  DARK: cssVar('--accentDarkGray'),
  RED: cssVar('--secondaryRed'),
};

// cut graph labels and items down to just 8, because that's what our cards can fit
const getCutData = ({ values }) => {
  const valuesCount = values.length;
  if (valuesCount <= 8) return values;
  return [
    values[0],
    values[Math.floor(valuesCount / 7)],
    values[Math.floor((valuesCount * 2) / 7)],
    values[Math.floor((valuesCount * 3) / 7)],
    values[Math.floor((valuesCount * 4) / 7)],
    values[Math.floor((valuesCount * 5) / 7)],
    values[Math.floor((valuesCount * 6) / 7)],
    values[valuesCount - 1],
  ];
};

export const SmallCardBarChart = ({ valueType, data, areaKey, graphLabels, goalData, showTicks = false }) => {
  defaults.global.defaultFontFamily = 'Nunito Sans';
  const {
    appSettings: { currencyISOCode: currency },
  } = useContext(AppContext);

  const labels = getCutData({ values: graphLabels });
  const normalizedData = getCutData({ values: data }).map((dataObject) => dataObject[areaKey]);
  const goalNormalizedData = getCutData({ values: goalData });

  const barData = {
    labels,
    datasets: [
      {
        type: 'bar',
        barThickness: 4,
        data: normalizedData,
        borderRadius: 5,
        borderWidth: 2,
        borderSkipped: false,
        backgroundColor: normalizedData.reduce((acc, item, index) => {
          const goalValue = goalNormalizedData[index];
          acc.push(!goalValue || item >= goalValue ? CHART_COLORS.DARK : CHART_COLORS.RED);
          return acc;
        }, []),
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        top: 0,
        right: 25,
        bottom: 14,
        left: 25,
      },
    },
    legend: {
      display: false,
    },
    tooltips: {
      mode: 'index',
      intersect: false,
      callbacks: {
        label: (tooltipItem) => amountWithAbbreviation({ value: tooltipItem.value ?? 0, type: valueType, currency }),
      },
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
          },
          ticks: {
            display: showTicks,
            callback: (value, index) => (index === 0 || index === normalizedData.length - 1 ? value.toUpperCase() : ''),
            autoSkip: false,
            maxRotation: 0,
            minRotation: 0,
            fontStyle: 'normal',
            fontSize: 10,
            fontColor: cssVar('--primaryBlack70'),
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            display: false,
          },
          ticks: {
            display: false,
          },
        },
      ],
    },
  };

  return <Bar data={barData} options={options} />;
};
