import React, { useEffect, useRef } from 'react';
import { EVENTS } from 'consts/analytics';
import { useAnalytics } from 'utils/hooks';
import { BillingProvider } from './BillingProvider';
import { BillingHeader } from './BillingHeader';
import { BillingPagesCoordinator } from './BillingPagesCoordinator';

export const BillingContainer = () => {
  const { trackEvent } = useAnalytics();
  const exportScreenshotRef = useRef();

  useEffect(() => {
    trackEvent({ name: EVENTS.VISIT_BILLING_PAGE });
  }, [trackEvent]);

  return (
    <BillingProvider>
      <BillingHeader exportScreenshotRef={exportScreenshotRef} />
      <BillingPagesCoordinator exportScreenshotRef={exportScreenshotRef} />
    </BillingProvider>
  );
};
