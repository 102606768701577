import React from 'react';
import { Row } from 'components/Core';
import { EmailSelector } from 'components/Controls';
import { GreyDot } from 'components/Icons';
import { AddEmailButton, AddEmailText, RowSubtitle } from '../styles';

export const VARIABLE_EMAILS = {
  FROM_INVOICE: 'Emails from invoice',
  ACCOUNT_EXECUTIVE: 'Account executive',
};

export const TemplateEmailRecipients = ({ values, setFieldValue }) => {
  return (
    <div>
      <RowSubtitle>
        Recipient(s) email
        <Row>
          <Row>
            <AddEmailText>Add recipients</AddEmailText>
            <AddEmailButton
              onClick={() =>
                !values?.to_emails?.includes(VARIABLE_EMAILS.FROM_INVOICE) &&
                setFieldValue('to_emails', [...values?.to_emails, VARIABLE_EMAILS.FROM_INVOICE])
              }
            >
              from the invoice
            </AddEmailButton>
          </Row>

          <GreyDot size="4px" spacing="8px" />

          <Row>
            <AddEmailText>Add</AddEmailText>
            <AddEmailButton
              onClick={() =>
                !values?.to_emails?.includes(VARIABLE_EMAILS.ACCOUNT_EXECUTIVE) &&
                setFieldValue('to_emails', [...values?.to_emails, VARIABLE_EMAILS.ACCOUNT_EXECUTIVE])
              }
            >
              account executive
            </AddEmailButton>
          </Row>
        </Row>
      </RowSubtitle>

      <EmailSelector
        padding="6px"
        name="template-emails"
        value={values?.to_emails}
        onChange={(value) => setFieldValue('to_emails', value)}
      />
    </div>
  );
};
