import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useExpanded, useGlobalFilter, useGroupBy, usePagination, useRowSelect, useTable } from 'react-table';
import { truncate } from 'lodash';
import dayjs from 'dayjs';
import { Checkbox } from 'antd';
import { AppContext } from 'AppContext';
import { NUMBER_FORMATS } from 'consts/global';
import { numberFormatter } from 'utils/formatters';
import { humanize } from 'utils/stringUtils';
import { fixSelectingWithGetSubRows } from 'utils/tableUtils';
import { ReactComponent as GreyDot } from 'images/medium-grey-dot.svg';
import { ReactComponent as ExternalLink } from 'images/external-link-full.svg';
import { TableColumnSettings } from 'shared/Common';
import { MultiselectRibbon, useDebouncedSearchBar } from 'components/Blocks';
import { PaginationButton, TableActionButton } from 'components/Buttons';
import { Flexer, CentererVertical, Row } from 'components/Core';
import { getTransactionAmountByRecognition } from 'views/Billing/InvoicingScheduleModal/utils';
import { useInvoicingScheduleModal } from 'views/Billing/InvoicingScheduleModal';
import { CircleLoader } from 'components/Loaders';
import {
  ReactTableHeader,
  ReactTableHeaderColumn,
  ReactTableHeaderRow,
  ReactTableCell,
  RowsCount,
  customGlobalFilter,
  ReactTableBody,
} from 'components/Table';
import { TooltipContainer } from 'components/Tooltip';
import { transactionDisplayTitle } from 'models/transaction';
import { SearchContainer } from 'shared/TransactionTable/styles';
import { CustomerHeaderRow } from 'views/Billing/Common/CustomerHeaderRow';
import { GROUP_BY } from '../ReviewTransactionsContainer';
import { areSelectedTransactionsEligibleForBulkCreation, getTransactionsGroup } from '../utils';
import {
  TableControlWrapper,
  TableWrapper,
  LoadingSpinnerWrapper,
  TransactionName,
  ReviewTransactionsReactTableRow,
  IconWrapper,
} from './ReviewTransactionsTable.styles';
import { CustomerEmailsTooltip } from './CustomerEmailsTooltip';
import { BulkCreateInvoicesModal } from '../BulkCreateInvoicesModal';
import { BulkCreateButton, BulkDismissButton } from '../BulkCreateSettings.styles';

export const ReviewTransactionsTable = ({
  dataForTable,
  isFetching,
  transactionsWithProducts,
  transactionsSelected,
  setTransactionsSelected,
  billingInvoiceDefaults,
  onBulkCreateClick,
  onBulkDismissClick,
  waitingOnBulkCreate,
  onEmailListChange,
  pageSize,
  setPageSize,
  controlledPageCount,
  currentPageIndex,
  setCurrentPageIndex,
  groupBy,
  searchQuery,
  onSearchQueryChange,
  showBulkCreateModal,
  setShowBulkCreateModal,
  transactionsById,
}) => {
  const {
    orgId,
    appSettings: { currencyISOCode: currency },
  } = useContext(AppContext);

  const { Modal: InvoicingScheduleModal, openModal: openInvoicingScheduleModal } = useInvoicingScheduleModal();

  const [selectedRow, setSelectedRow] = useState();

  const columns = useMemo(() => {
    const result = [
      {
        Header: ({ getToggleAllPageRowsSelectedProps }) => (
          <CentererVertical gap="8px">
            <Checkbox {...getToggleAllPageRowsSelectedProps()} />
            <span>CUSTOMER / TRANSACTIONS</span>
          </CentererVertical>
        ),
        accessor: 'name',
        id: 'name',
        width: '35%',
        Cell: ({ row, cell: { value } }) => {
          return row.canExpand ? (
            <CentererVertical data-cy={`header-group-${value}`}>
              <Checkbox
                style={{ marginRight: 10 }}
                checked={row.isSelected}
                onClick={() => row.toggleRowSelected()}
                data-cy={`header-group-${value}__checkbox`}
              />

              {[GROUP_BY.CUSTOMER, GROUP_BY.CRM_ID, GROUP_BY.PARENT_CUSTOMER].includes(groupBy) ? (
                <CustomerHeaderRow
                  orgId={orgId}
                  customer={row.original}
                  currency={currency}
                  unitCount={row.originalSubRows.length}
                  unitName="transaction"
                  showSchedulesSummary={false}
                  onEmailListChange={onEmailListChange}
                  crmID={GROUP_BY.CRM_ID === groupBy ? row.original.name : null}
                  groupBy={groupBy}
                  row={row}
                />
              ) : (
                <b>{dayjs(value).format('MMMM YYYY')}</b>
              )}
            </CentererVertical>
          ) : (
            <CentererVertical gap="12px">
              <Checkbox
                checked={row.isSelected}
                onClick={() => {
                  row.toggleRowSelected();
                }}
                data-cy="billing__review-transactions__checkbox"
                data-transaction-id={row.original.id}
              />
              <Flexer gap="8px" alignItems="center">
                {groupBy === GROUP_BY.TRANSACTION_DATE && (
                  <CustomerEmailsTooltip transaction={row.original} onEmailListChange={onEmailListChange} />
                )}
                <TooltipContainer toolTipContent={transactionDisplayTitle(row.original)} width={300}>
                  <TransactionName>{truncate(transactionDisplayTitle(row.original), { length: 100 })}</TransactionName>
                </TooltipContainer>
                <IconWrapper style={{ height: 20, width: 20 }}>
                  <ExternalLink onClick={() => window.open(`/transactions/${row.original.id}`, '_blank')} />
                </IconWrapper>
              </Flexer>
            </CentererVertical>
          );
        },
      },
      {
        Header: 'recognition',
        accessor: 'recognition',
        id: 'recognition',
        width: '15%',
        Cell: ({ row }) => (row.canExpand ? null : humanize(row.original.recognition ?? '')),
      },
      {
        Header: 'Dates',
        accessor: 'Dates',
        id: 'Dates',
        width: '15%',
        Cell: ({ row }) =>
          row.canExpand
            ? null
            : `${dayjs.utc(row.original.start_date).format('MMM D, YYYY')} - ${
                !!row.original.end_date ? dayjs.utc(row.original.end_date).format('MMM D, YYYY') : 'No end date'
              }`,
      },
      {
        Header: 'TOTAL AMOUNT',
        accessor: 'amount',
        id: 'amount',
        width: '15%',
        alignRight: true,
        Cell: ({ row }) =>
          row.canExpand
            ? null
            : numberFormatter({
                type: NUMBER_FORMATS.CURRENCY,
                rawValue: getTransactionAmountByRecognition({ transaction: row.original }),
                currency: row.original.currency ?? currency,
              }),
      },
      {
        Header: '',
        accessor: 'customer_name',
        id: 'actions',
        width: '20%',
        alignRight: true,
        Cell: ({ row }) =>
          row.canExpand ? null : (
            <TableActionButton
              onClick={row.original.product_id ? () => handleOpenInvoiceModal(row.original) : undefined}
              data-cy="billing__review-transactions__create-button"
              data-transaction-id={row.original.id}
              disabled={!row.original.product_id}
              active
              toolTipContent={
                !row.original.product_id &&
                'The transaction must have a product in order to create an invoicing schedule from. Please click on the link icon to open the transaction and associate a product with it.'
              }
            >
              Create invoicing schedule
            </TableActionButton>
          ),
      },
    ];

    return result;
    // If we have handleOpenInvoiceModal here, we'll get into an infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgId, currency, groupBy, onEmailListChange]);

  const [isSelectedRowsLoaded, setIsSelectedRowsLoaded] = useState(false);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    toggleAllRowsExpanded,
    setGlobalFilter,
    state: { selectedRowIds },
  } = useTable(
    {
      columns,
      data: dataForTable,
      getSubRows: (row) => row.subRows || [],
      globalFilter: customGlobalFilter,
      expandSubRows: true,
      initialState: {
        globalFilter: searchQuery,
        pageIndex: currentPageIndex,
        pageSize,
      },
      paginateExpandedRows: false,
      manualPagination: true,
      manualGlobalFilter: true,
      pageCount: controlledPageCount,
    },
    useGroupBy,
    useGlobalFilter,
    useExpanded,
    usePagination,
    useRowSelect,
  );

  const handleResetTransactionsSelected = useCallback(() => {
    rows?.forEach((row) => {
      if (selectedRowIds[row.id]) {
        row.toggleRowSelected();
      } else {
        row.subRows?.forEach((subRow) => {
          if (selectedRowIds[subRow.id]) subRow.toggleRowSelected();
        });
      }
    });

    setTransactionsSelected(new Set());
  }, [rows, selectedRowIds, setTransactionsSelected]);

  const handleOpenInvoiceModal = useCallback(
    (transaction) => {
      handleResetTransactionsSelected();

      openInvoicingScheduleModal({
        includedTransactions: getTransactionsGroup({ transaction }),
      });
    },
    [handleResetTransactionsSelected, openInvoicingScheduleModal],
  );

  const handleBulkCreate = useCallback(() => {
    if (transactionsSelected.size === 1) {
      handleOpenInvoiceModal(transactionsById[Array.from(transactionsSelected)[0]]);
    } else {
      setShowBulkCreateModal(true);
    }
  }, [handleOpenInvoiceModal, setShowBulkCreateModal, transactionsById, transactionsSelected]);

  useEffect(() => {
    setTimeout(() => {
      if (groupBy !== GROUP_BY.CRM_ID) toggleAllRowsExpanded(true);
    });
  }, [toggleAllRowsExpanded, searchQuery, dataForTable, groupBy]);

  useEffect(() => {
    page?.forEach((row) => {
      if (row?.toggleRowSelected && !!row.original.isSelected) {
        row.toggleRowSelected(row.id);
      }
    });

    setIsSelectedRowsLoaded(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataForTable]);

  const canBulkCreate = useMemo(
    () =>
      areSelectedTransactionsEligibleForBulkCreation({
        transactionIds: transactionsSelected,
        transactionsWithProducts,
      }),
    [transactionsSelected, transactionsWithProducts],
  );

  useEffect(() => {
    if (isSelectedRowsLoaded) {
      const selectedRowsTransactionsIds = [
        ...new Set(
          rows
            ?.flatMap((row) =>
              row.canExpand
                ? row.subRows
                    .filter((subRow) => {
                      subRow.isSelected = false;
                      return selectedRowIds[subRow.id];
                    })
                    .map((row) => {
                      row.isSelected = true;
                      return row.original.id;
                    })
                : selectedRowIds[row.id] && row.original.id,
            )
            .filter(Boolean),
        ),
      ];

      setTransactionsSelected(new Set(selectedRowsTransactionsIds));
    }
    // Otherwise we get into an infinite loop:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRowIds, isSelectedRowsLoaded]);

  const handleSearchQueryChange = useCallback(
    (newSearchQuery) => {
      onSearchQueryChange(newSearchQuery);
      setGlobalFilter(newSearchQuery);
      setCurrentPageIndex(1);
    },
    [onSearchQueryChange, setCurrentPageIndex, setGlobalFilter],
  );

  const handleAddAdditionalTransactionsInBulkCreate = useCallback(
    (transactionIds) => {
      const subRows = rows
        ?.flatMap((row) => row.subRows ?? [])
        .filter((row) => transactionIds.includes(row.original.id));

      subRows.forEach((row) => {
        row.toggleRowSelected();
      });
    },
    [rows],
  );

  const { DebouncedSearchBar } = useDebouncedSearchBar({
    onSearchQueryChange: handleSearchQueryChange,
    initialValue: '',
    placeholder: 'Search by deal name, customer name, or product...',
    width: '350px',
  });

  return (
    <>
      <TableControlWrapper>
        <SearchContainer>
          <DebouncedSearchBar />
        </SearchContainer>
        <Row style={{ marginLeft: '15px' }}>
          <RowsCount>
            <span data-cy="invoices-table__row-count">{page?.filter((row) => !row.canExpand)?.length} rows found</span>
          </RowsCount>

          <GreyDot style={{ marginLeft: 20, marginRight: 20, minWidth: 4, minHeight: 4 }} />

          {isFetching && (
            <LoadingSpinnerWrapper>
              <CircleLoader isAbsolute isInline width="24px" height="24px" thickness="4px" name="customers-table" />
            </LoadingSpinnerWrapper>
          )}
          <PaginationButton
            pageIndex={currentPageIndex}
            canPreviousPage={currentPageIndex > 1}
            canNextPage={currentPageIndex < controlledPageCount}
            pageCount={controlledPageCount}
            nextPage={() => setCurrentPageIndex((prevPageIndex) => prevPageIndex + 1)}
            previousPage={() => setCurrentPageIndex((prevPageIndex) => prevPageIndex - 1)}
          />

          <GreyDot style={{ marginLeft: 20, marginRight: 20, minWidth: 4, minHeight: 4 }} />

          <TableColumnSettings
            tableName="review-transactions"
            numberValue={pageSize}
            handleShowResultsChange={(number) => setPageSize(number?.value ?? 10)}
            resultsLabel={'# of transactions on page'}
            options={[
              { label: '10', value: 10 },
              { label: '50', value: 50 },
              { label: '100', value: 100 },
            ]}
          />
        </Row>
      </TableControlWrapper>
      <Flexer>
        <TableWrapper>
          <div {...getTableProps()}>
            <ReactTableHeader backgroundColor="white" border="1px solid var(--accentGraySecond)" borderRadius="16px">
              {headerGroups.map((headerGroup) => (
                <ReactTableHeaderRow {...headerGroup.getHeaderGroupProps()} noSpaceBetween>
                  {headerGroup.headers.map((column) => (
                    <ReactTableHeaderColumn
                      color="var(--primaryBlack70)"
                      {...column.getHeaderProps()}
                      customWidth={column.width}
                      alignRight={column.alignRight}
                    >
                      {column.render('Header')}
                    </ReactTableHeaderColumn>
                  ))}
                </ReactTableHeaderRow>
              ))}
            </ReactTableHeader>
            <ReactTableBody background="transparent" noBorder {...getTableBodyProps()}>
              {page.map((row, index) => {
                prepareRow(row);
                const nextIndex = index + 1;
                const subRow = !row.canExpand;

                fixSelectingWithGetSubRows({ row, selectedIds: Array.from(transactionsSelected) });

                return (
                  <ReviewTransactionsReactTableRow
                    {...row.getRowProps()}
                    data-cy="billing__review-transactions__table-row"
                    subRow={subRow}
                    firstChild={subRow && row.index === 0}
                    lastChild={subRow && (nextIndex === page.length || !page[nextIndex].id.includes('.'))}
                    noSpaceBetween
                    ignoreChildStyles
                    onClick={() => subRow && setSelectedRow(row.original)}
                    selected={selectedRow?.id === row.original.id}
                  >
                    {row.cells.map((cell) =>
                      row.canExpand ? (
                        <div {...cell.getCellProps()}>{cell.render('Cell')}</div>
                      ) : (
                        <ReactTableCell
                          {...cell.getCellProps()}
                          customWidth={cell.column.width}
                          alignRight={cell.column.alignRight}
                        >
                          {cell.render('Cell')}
                        </ReactTableCell>
                      ),
                    )}
                  </ReviewTransactionsReactTableRow>
                );
              })}
            </ReactTableBody>
          </div>
        </TableWrapper>
      </Flexer>

      {transactionsSelected.size > 0 && !showBulkCreateModal && (
        <MultiselectRibbon
          dataCy="bulk-create-settings"
          label={`${transactionsSelected.size} selected items:`}
          insertActionsDirectly={true}
          actions={
            <Row gap="8px" style={{ marginLeft: 8 }}>
              <TooltipContainer
                isVisible={!canBulkCreate}
                toolTipContent="You can only bulk create from transactions with products"
              >
                <BulkCreateButton
                  fontWeight="700"
                  onClick={handleBulkCreate}
                  disabled={!canBulkCreate || waitingOnBulkCreate}
                  data-cy="bulk-create-settings__bulk-create-button"
                >
                  <span>{waitingOnBulkCreate ? 'Bulk Creating...' : 'Bulk Create'}</span>
                </BulkCreateButton>
              </TooltipContainer>
              <BulkDismissButton fontWeight="700" onClick={onBulkDismissClick}>
                <span>Dismiss</span>
              </BulkDismissButton>
            </Row>
          }
          onResetSelection={handleResetTransactionsSelected}
        />
      )}

      {showBulkCreateModal ? (
        <BulkCreateInvoicesModal
          transactionsSelected={transactionsSelected}
          billingInvoiceDefaults={billingInvoiceDefaults}
          transactionsWithProducts={transactionsWithProducts}
          onBulkCreateClick={onBulkCreateClick}
          onBulkDismissClick={onBulkDismissClick}
          waitingOnBulkCreate={waitingOnBulkCreate}
          onClose={() => setShowBulkCreateModal(false)}
          transactionsById={transactionsById}
          setTransactionsSelected={setTransactionsSelected}
          handleAddAdditionalTransactions={handleAddAdditionalTransactionsInBulkCreate}
        />
      ) : null}

      <InvoicingScheduleModal />
    </>
  );
};
