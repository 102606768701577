import React, { useCallback, useContext, useState } from 'react';
import Select from 'react-select';
import { AppContext } from 'AppContext';
import { useProductsAPI } from 'api/products';
import { ReactComponent as HeaderIcon } from 'images/info-circle-icon.svg';
import { RECOGNITION_TYPES } from 'consts/global';
import { capitalize } from 'utils/stringUtils';
import { Modal, ModalBody, ModalButton, ModalContainer, ModalFooter, ModalHeader, ModalTitle } from 'components/Modal';
import { InputLabel, SelectField, selectStyles } from 'components/Controls';
import { Spacer } from 'components/Core';

import { ProductsModalHeaderIcon, ProductsModalListName, ProductsModalListWrapper } from './styles';

export const ProductsBulkUpdateModal = ({ products, onClose }) => {
  const { orgId } = useContext(AppContext);
  const {
    isLoading,
    operations: { bulkUpdate },
  } = useProductsAPI({ orgId, autoFetch: false });

  const [recognition, setRecognition] = useState();

  const handleConfirmClick = async () => {
    await bulkUpdate.mutateAsync({
      productIds: products.map((product) => product.id),
      data: {
        recognition: recognition?.value,
      },
    });
    onClose();
  };

  return (
    <>
      <ModalContainer>
        <Modal maxWidth="280px" height="70vh" overflow="visible" compact>
          <ProductsModalHeaderIcon>
            <HeaderIcon width="20px" height="20px" />
          </ProductsModalHeaderIcon>
          <ModalHeader>
            <ModalTitle compact>
              <b>Recognition Change</b>
            </ModalTitle>
          </ModalHeader>
          <ModalBody compact centered>
            <SelectField height="100%" label="Recognition">
              <Select
                menuPlacement="auto"
                menuPortalTarget={document.querySelector('body')}
                styles={selectStyles(false)}
                openMenuOnFocus
                value={recognition}
                placeholder="Select recognition..."
                onChange={setRecognition}
                options={Object.values(RECOGNITION_TYPES).map((t) => ({
                  label: capitalize(t),
                  value: t,
                }))}
              />
            </SelectField>

            <Spacer height="16px" />

            <InputLabel>Products ({products.length})</InputLabel>
            <ProductsModalListWrapper>
              {products.map((product) => (
                <ProductsModalListName key={product.id}>{product.name}</ProductsModalListName>
              ))}
            </ProductsModalListWrapper>
          </ModalBody>
          <ModalFooter centered transparent>
            <ModalButton onClick={onClose}>Cancel</ModalButton>
            <ModalButton
              className="primary"
              onClick={isLoading ? undefined : handleConfirmClick}
              disabled={isLoading}
              data-cy="products-bulk-update-modal__submit-button"
            >
              Confirm
            </ModalButton>
          </ModalFooter>
        </Modal>
      </ModalContainer>
    </>
  );
};

const useProductsBulkUpdateModal = () => {
  const [products, setProducts] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const openModal = useCallback(() => setShowModal(true), [setShowModal]);
  const closeModal = useCallback(() => setShowModal(false), [setShowModal]);

  const Modal = useCallback(
    () => (showModal ? <ProductsBulkUpdateModal products={products} onClose={closeModal} /> : <></>),
    [products, showModal, closeModal],
  );

  return {
    openModal: (products) => {
      setProducts(products);
      openModal();
    },
    ProductsBulkUpdateModal: Modal,
  };
};

export { useProductsBulkUpdateModal };
