import React, { useContext, useState } from 'react';
import { AppContext } from 'AppContext';
import { ReactComponent as DeleteItem } from 'images/segments-trash.svg';
import { useGetAvailableTags } from 'api/billing';
import { TEMPLATE_MODAL_DYNAMIC_TAGS } from 'consts/billing';
import { removeEmptyLinesFromHTMLString, templateToHTMLString } from 'utils/htmlUtils';
import { daysToPeriodString } from 'utils/formatters';
import { AITagSolidIcon, CirclePlusIcon, EditCircleIcon } from 'components/Icons';
import { ReactComponent as InfoCircle } from 'images/black-info-circle.svg';
import { Row } from 'components/Core';
import { TooltipContainer } from 'components/Tooltip';
import { Switch } from 'components/Controls';
import { Loader } from 'components/Loaders';
import { CircleCheckbox, FieldBody, FieldTitle } from 'views/Wizard/styles';
import { ActionButton } from 'views/Billing/Common/ActionButton';
import { HeaderCount, HeaderLine, HeaderName, ItemsHeader, ItemRow } from 'views/Wizard/steps/SubscriptionData/styles';
import { CollectionTemplateModal } from './TemplateModal';
import { RemoveTemplateModal } from './RemoveTemplateModal';
import { useCollectionSequence } from './hooks';
import {
  AiHelpButton,
  CollectionItemCount,
  CollectionSequenceByAINote,
  CollectionsCheckboxRow,
  CollectionsSubtitle,
  DeleteButtonWrapper,
  EditButtonWrapper,
  HeaderActions,
  ItemActions,
  ItemTemplate,
  ItemTemplateEvent,
} from '../styles';
import { AiExplainModal } from './AiExplainModal';

const AI_FREQUENCY_TAG = '{ai_reminder_frequency}';

export const CollectionSequence = ({ values, setFieldValue, validateAndSubmitForm, mailerIntegration }) => {
  const {
    emailTemplates,
    isLoading,
    showTemplateModal,
    setShowTemplateModal,
    showRemoveModal,
    setShowRemoveModal,
    selectedTemplate,
    setSelectedTemplate,
    handleSelectedOptionChange,
    ConfirmConfigureSenderModal,
    ConfirmReauthenticateMailerModal,
    editBillingEmailTemplate,
    removeBillingEmailTemplate,
  } = useCollectionSequence({ values, setFieldValue, validateAndSubmitForm, mailerIntegration });

  const [showAiHelpModal, setShowAiHelpModal] = useState(false);

  const { orgId } = useContext(AppContext);
  const {
    transactionMetadataTags = [],
    customerMetadataTags = [],
    transactionCustomerMetadataTags = [],
  } = useGetAvailableTags({ orgId });
  const availableTags = [
    ...TEMPLATE_MODAL_DYNAMIC_TAGS,
    ...transactionMetadataTags,
    ...customerMetadataTags,
    ...transactionCustomerMetadataTags,
  ];

  if (isLoading) {
    return (
      <div className="w-100 flexer" style={{ marginTop: 20 }}>
        <Loader containerStyles={{ width: 40 }} />
      </div>
    );
  }

  return (
    <>
      <FieldBody>
        <FieldTitle style={{ gap: '4px', justifyContent: 'flex-start', marginBottom: '16px' }}>
          <span>Collection sequence:</span>
          <CollectionsSubtitle>Get paid faster with Subscript’s Reminders!</CollectionsSubtitle>

          {values?.billingReminderDefaults?.ai_enabled && (
            <AiHelpButton onClick={() => setShowAiHelpModal(true)}>how ai works</AiHelpButton>
          )}
        </FieldTitle>

        <CollectionsCheckboxRow>
          <Row
            onClick={() => handleSelectedOptionChange({ enabled: true, auto_send: false, ai_enabled: false })}
            style={{ cursor: 'pointer' }}
          >
            <CircleCheckbox
              checked={
                values?.billingReminderDefaults?.enabled &&
                !values?.billingReminderDefaults?.auto_send &&
                !values?.billingReminderDefaults?.ai_enabled
              }
            />
            Manual reminders on
          </Row>

          <Row
            onClick={() => handleSelectedOptionChange({ enabled: true, auto_send: true, ai_enabled: false })}
            style={{ cursor: 'pointer', marginLeft: 20 }}
          >
            <CircleCheckbox
              checked={
                values?.billingReminderDefaults?.enabled &&
                values?.billingReminderDefaults.auto_send &&
                !values?.billingReminderDefaults?.ai_enabled
              }
            />
            Auto reminders on
          </Row>

          <Row
            onClick={() => handleSelectedOptionChange({ enabled: true, auto_send: false, ai_enabled: true })}
            style={{ cursor: 'pointer', marginLeft: 20 }}
          >
            <CircleCheckbox checked={values?.billingReminderDefaults?.ai_enabled} />
            Reminders by Subscript AI on
          </Row>

          <Row
            onClick={() => handleSelectedOptionChange({ enabled: false, auto_send: false, ai_enabled: false })}
            style={{ cursor: 'pointer', marginLeft: 20 }}
          >
            <CircleCheckbox checked={!values?.billingReminderDefaults?.enabled} />
            Reminders off
          </Row>
        </CollectionsCheckboxRow>

        <ItemsHeader>
          <HeaderLine />
          <Row style={{ width: '100%' }} horizontal="space-between">
            <Row>
              <HeaderCount>days</HeaderCount>
              <HeaderName style={{ marginLeft: 18 }}>event</HeaderName>
              <HeaderName style={{ marginLeft: 173 }}>Template</HeaderName>
            </Row>

            {!values?.billingReminderDefaults?.ai_enabled && (
              <HeaderActions>
                <ActionButton
                  Icon={CirclePlusIcon}
                  onClick={() => {
                    setSelectedTemplate(null);
                    setShowTemplateModal(true);
                  }}
                >
                  Add Event
                </ActionButton>
              </HeaderActions>
            )}
          </Row>
        </ItemsHeader>

        {(emailTemplates ?? [])
          .sort((a, b) => a.days_from_due_date - b.days_from_due_date)
          .map((template) => (
            <ItemRow key={template?.id}>
              <Row>
                <CollectionItemCount>
                  {template?.ai_enabled && template?.days_from_due_date > 0 ? '*' : template?.days_from_due_date}
                </CollectionItemCount>
                <TooltipContainer
                  width={300}
                  fontSize="12px"
                  toolTipContent={
                    <div
                      dangerouslySetInnerHTML={{
                        __html: templateToHTMLString({
                          text: template?.ai_enabled
                            ? template?.subject?.replace(
                                AI_FREQUENCY_TAG,
                                daysToPeriodString(template?.days_from_due_date),
                              )
                            : template?.subject,
                          availableTags,
                        }),
                      }}
                    />
                  }
                >
                  <ItemTemplateEvent>
                    {template?.ai_enabled && (
                      <Switch
                        size="small"
                        checked={template?.active}
                        onChange={() =>
                          editBillingEmailTemplate({ id: template?.id, data: { active: !template?.active } })
                        }
                        data-cy={`billing-email-templates__template__switch--${template?.id}`}
                      />
                    )}
                    <div
                      onClick={() => {
                        setSelectedTemplate(template);
                        setShowTemplateModal(true);
                      }}
                      dangerouslySetInnerHTML={{
                        __html: removeEmptyLinesFromHTMLString({
                          htmlString: templateToHTMLString({
                            text: template?.ai_enabled
                              ? template?.subject?.replace(
                                  AI_FREQUENCY_TAG,
                                  daysToPeriodString(template?.days_from_due_date),
                                )
                              : template?.subject,
                            availableTags,
                          }),
                        }),
                      }}
                    />
                  </ItemTemplateEvent>
                </TooltipContainer>

                <TooltipContainer
                  width={300}
                  fontSize="12px"
                  toolTipContent={
                    <div
                      style={{ textAlign: 'left' }}
                      dangerouslySetInnerHTML={{
                        __html: templateToHTMLString({
                          text: template?.body,
                          availableTags,
                        }),
                      }}
                    />
                  }
                >
                  <ItemTemplate>
                    {template?.ai_enabled && <AITagSolidIcon />}
                    <div
                      dangerouslySetInnerHTML={{
                        __html: removeEmptyLinesFromHTMLString({
                          htmlString: templateToHTMLString({
                            text: template?.body,
                            availableTags,
                          }),
                        }),
                      }}
                    />
                  </ItemTemplate>
                </TooltipContainer>
              </Row>

              <ItemActions>
                <EditButtonWrapper
                  data-cy={`billing-email-templates__template-${template?.days_from_due_date}`}
                  onClick={() => {
                    setSelectedTemplate(template);
                    setShowTemplateModal(true);
                  }}
                >
                  <EditCircleIcon />
                </EditButtonWrapper>

                {!values?.billingReminderDefaults?.ai_enabled && (
                  <DeleteButtonWrapper
                    onClick={() => {
                      setSelectedTemplate(template);
                      setShowRemoveModal(true);
                    }}
                  >
                    <DeleteItem />
                  </DeleteButtonWrapper>
                )}
              </ItemActions>
            </ItemRow>
          ))}

        {values?.billingReminderDefaults?.ai_enabled && (
          <CollectionSequenceByAINote>
            <InfoCircle width={26} height={26} style={{ position: 'relative', top: -5, marginRight: 8 }} />
            Based on invoice histories and interactions with customers, the Subscript AI will automatically create
            reminders. If it detects significant information from your communications, it can adjust the frequency of
            these reminders accordingly.
          </CollectionSequenceByAINote>
        )}
      </FieldBody>

      {showTemplateModal && (
        <CollectionTemplateModal selectedTemplate={selectedTemplate} setShowTemplateModal={setShowTemplateModal} />
      )}

      {showRemoveModal && (
        <RemoveTemplateModal
          onSubmit={(template) => {
            removeBillingEmailTemplate({ id: template.id });
            setShowRemoveModal(false);
          }}
          selectedTemplate={selectedTemplate}
          setShowRemoveModal={setShowRemoveModal}
        />
      )}

      {showAiHelpModal && <AiExplainModal hideModal={() => setShowAiHelpModal(false)} />}

      <ConfirmConfigureSenderModal />
      <ConfirmReauthenticateMailerModal />
    </>
  );
};
