import React, { createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { AppContext } from 'AppContext';
import { getAppSetting } from 'models/appSettings';
import { useAnalytics } from 'utils/hooks';
import { getObjectDiff } from 'utils/objectUtils';

export const GlobalTogglesContext = createContext();

export const GlobalTogglesContextProvider = ({ children, urlState }) => {
  const { pathname } = useLocation();

  const { organizations, appSettings } = useContext(AppContext);

  const {
    isARR,
    setIsARR,
    isCommitted,
    setIsCommitted,
    optimisticAnalytics,
    setOptimisticAnalytics,
    rollup,
    setRollup,
    countInfluxAsRenewed,
    setCountInfluxAsRenewed,
    influxMonths,
  } = urlState ? urlState : appSettings;

  const { trackEvent } = useAnalytics();
  const committedPolicy = getAppSetting('committedPolicy');
  const committedStart = getAppSetting('committedStart');
  const { hasTransactionsWithRenewalDeadline } = organizations[0] ?? {};

  const currentStateWithSetters = {
    isARR: [isARR, setIsARR],
    isCommitted: [isCommitted, setIsCommitted],
    rollup: [rollup, setRollup],
    optimisticAnalytics: [optimisticAnalytics, setOptimisticAnalytics],
    countInfluxAsRenewed: [countInfluxAsRenewed, setCountInfluxAsRenewed],
  };

  const currentStateWithoutSetters = {
    isARR: currentStateWithSetters.isARR[0],
    isCommitted: currentStateWithSetters.isCommitted[0],
    rollup: currentStateWithSetters.rollup[0],
    optimisticAnalytics: currentStateWithSetters.optimisticAnalytics[0],
    countInfluxAsRenewed: currentStateWithSetters.countInfluxAsRenewed[0],
  };

  const [draftGlobalToggles, setDraftGlobalToggles] = useState(currentStateWithoutSetters);

  const applyDraftGlobalToggles = () => {
    const togglesDiffs = getObjectDiff(currentStateWithoutSetters, draftGlobalToggles);

    if (togglesDiffs.length) {
      togglesDiffs.forEach((toggle) => {
        const toggleSetter = currentStateWithSetters[toggle][1];
        toggleSetter(draftGlobalToggles[toggle]);
      });
    }
  };

  useEffect(() => {
    setDraftGlobalToggles(currentStateWithoutSetters);
    // eslint-disable-next-line
  }, [appSettings]);

  const setAndTrack = ({ name, value }) => {
    trackEvent({
      name: 'Apply Global Toggles',
      properties: {
        toggleName: name,
        toggleValue: value,
      },
    });
    setDraftGlobalToggles((prevState) => ({ ...prevState, [name]: value }));
  };

  // In https://github.com/ChifferCo/chiffer/issues/4462 we decided to hide the 'Enable Influx' option on the Revenue Spreads page until the full release of MQE (with filtering, segmenting, rollup, and optimistic)
  // Refer to https://github.com/ChifferCo/chiffer/pull/5653 for how we were planning to use MQE in the Revenue Spreads logic
  const isRevenueSpreadsPage = pathname === '/spreads';

  return (
    <GlobalTogglesContext.Provider
      value={{
        globalTogglesState: currentStateWithoutSetters,
        draftGlobalToggles,
        setDraftGlobalToggles: setAndTrack,
        applyDraftGlobalToggles,
        committedPolicy,
        committedStart,
        showInfluxToggle: !isRevenueSpreadsPage && (hasTransactionsWithRenewalDeadline || influxMonths !== 0),
      }}
    >
      {children}
    </GlobalTogglesContext.Provider>
  );
};
