import React, { useEffect } from 'react';
import { FlexerColumn, Spacer } from 'components/Core';
import { Modal, ModalBody, ModalButton, ModalCloseIcon, ModalContainer, ModalHeader } from 'components/Modal';
import { TextBox } from 'components/Core/Text';
import { AIBetaTagIcon } from 'components/Icons';
import { useAnalytics } from 'utils/hooks';

export const AIReaderLockedModal = ({ onClose }) => {
  const { trackEvent } = useAnalytics();

  useEffect(() => {
    trackEvent({ name: 'Visit AI Reader Locked Modal' });
  }, [trackEvent]);

  return (
    <ModalContainer>
      <Modal width="40vw" height="auto" overflow="visible" padding="0px">
        <ModalHeader addPadding>
          <ModalCloseIcon onClose={onClose} />
        </ModalHeader>

        <ModalBody>
          <FlexerColumn alignItems="center">
            <AIBetaTagIcon />
            <Spacer height="20px" />
            <TextBox bold fontSize="20px">
              Reach out to us to get started!
            </TextBox>
            <Spacer height="20px" />
            <TextBox textAlign="center">
              Please reach out to us on Slack so that we can set you up with our AI contract interpreter feature
            </TextBox>
            <Spacer height="20px" />
            <ModalButton primary onClick={onClose}>
              Ok, got it!
            </ModalButton>
            <Spacer height="20px" />
          </FlexerColumn>
        </ModalBody>
      </Modal>
    </ModalContainer>
  );
};
