import styled from 'styled-components';
import { ReactComponent as DotsIcon } from 'images/dots.svg';
import { StyledButton } from 'components/Buttons/Button';

export const PanelHeaderWrapper = styled.div`
  padding: 19px 32px;
  box-shadow: 0px 24px 24px 0px var(--primaryBlack3);
  margin-bottom: 20px;
  overflow: visible;
  border-bottom: 1px solid var(--neutralGray);
`;

export const PanelHeaderButton = styled(StyledButton)`
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  font-style: normal;
  margin-left: 8px;
`;

export const PanelHeaderTitle = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;

export const PanelHeaderInfo = styled.div`
  padding: 4px;
  align-items: center;
  font-style: normal;
  border-radius: 4px;
  border: 1px solid var(--neutralGray);
  font-size: 9px;
  font-weight: 900;
  line-height: 12px;
  text-transform: uppercase;
  opacity: 0.5;
`;

export const PanelHeaderTitleText = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  color: var(--primaryBlack50);
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: 16px;
`;

export const PanelHeaderSubText = styled.div`
  font-size: 12px;
  font-style: italic;
  color: var(--primaryBlack50);
`;

export const ExternalLinkText = styled.a`
  color: var(--primaryBlue);
  text-decoration: none;
`;

export const DotsIconGreen = styled(DotsIcon)`
  path {
    fill: ${({ active }) => (active ? 'var(--secondaryGreen)' : 'var(--darkGreen)')};
  }
`;
