import React, { useContext } from 'react';
import { AppContext } from 'AppContext';
import { useGetAvailableTags } from 'api/billing';
import { TEMPLATE_MODAL_DYNAMIC_TAGS } from 'consts/billing';
import { templateToHTMLString } from 'utils/htmlUtils';
import { CentererVertical, Row } from 'components/Core';
import { FormikCustomInput } from 'components/Controls';
import { ModalBody } from 'components/Modal';
import { useToasts } from 'components/Toasts';
import {
  DynamicFields,
  DynamicFieldsTags,
  DynamicFieldsTitle,
  DynamicTag,
  DynamicFieldsInfo,
} from 'components/Blocks/DynamicFields';
import { CircleCheckbox } from 'views/Wizard/styles';
import { BillingDynamicFieldsTooltip, DYNAMIC_FIELD_TOOLTIP_SECTIONS } from 'views/Billing/BillingDynamicFieldsTooltip';
import { FormRow, RowSubtitle } from '../styles';
import { TemplateEmailRecipients } from './TemplateEmailRecipients';

export const TemplateModalBody = ({ values, setFieldValue }) => {
  const { pushToast } = useToasts();

  const { orgId } = useContext(AppContext);
  const {
    transactionMetadataTags = [],
    customerMetadataTags = [],
    transactionCustomerMetadataTags = [],
  } = useGetAvailableTags({ orgId });
  const availableTags = [
    ...TEMPLATE_MODAL_DYNAMIC_TAGS,
    ...transactionMetadataTags,
    ...customerMetadataTags,
    ...transactionCustomerMetadataTags,
  ];

  return (
    <ModalBody height="700px" gap="0" paddingLeft="36px" paddingRight="36px">
      <FormRow>
        <Row horizontal="flex-start" onClick={() => setFieldValue('send_before', true)} style={{ cursor: 'pointer' }}>
          <CircleCheckbox checked={values?.send_before} /> Send before due date
        </Row>

        <Row horizontal="flex-start" onClick={() => setFieldValue('send_before', false)} style={{ cursor: 'pointer' }}>
          <CircleCheckbox checked={!values?.send_before} />
          Send after due date
        </Row>

        <FormikCustomInput style={{ height: 36 }} name="days_from_due_date" placeholder="days" type="number" />
      </FormRow>

      {/* We're going to do this in the next iteration */}
      {/* <RowSubtitle>From whom the email will be sent?</RowSubtitle>
              <FormRow>
                <TooltipContainer width={140} fontSize="12px" toolTipContent="Max@mycompany.com">
                  <TooltipRow
                    horizontal="flex-start"
                    onClick={() => setFieldValue('send_from_executive_email', false)}
                    style={{ cursor: 'pointer' }}
                  >
                    <CircleCheckbox checked={!values?.send_from_executive_email} /> Default email
                    <QuestionIcon />
                  </TooltipRow>
                </TooltipContainer>

                <Row
                  horizontal="flex-start"
                  onClick={() => setFieldValue('send_from_executive_email', true)}
                  style={{ cursor: 'pointer' }}
                >
                  <CircleCheckbox checked={values?.send_from_executive_email} />
                  Account executive email mapped on transaction metadata
                </Row>
              </FormRow> */}

      <RowSubtitle>
        Email Subject
        <CentererVertical gap="8px">
          <BillingDynamicFieldsTooltip
            tags={{
              [DYNAMIC_FIELD_TOOLTIP_SECTIONS.GENERAL_TAGS]: TEMPLATE_MODAL_DYNAMIC_TAGS,
              [DYNAMIC_FIELD_TOOLTIP_SECTIONS.TRANSACTION_METADATA_TAGS]: transactionMetadataTags,
              [DYNAMIC_FIELD_TOOLTIP_SECTIONS.CUSTOMER_METADATA_TAGS]: customerMetadataTags,
              [DYNAMIC_FIELD_TOOLTIP_SECTIONS.TRANSACTION_CUSTOMER_METADATA_TAGS]: transactionCustomerMetadataTags,
            }}
            XOffset={100}
          />
        </CentererVertical>
      </RowSubtitle>

      <FormRow childrenWidth="100%">
        <FormikCustomInput
          name="subject"
          HTMLString={templateToHTMLString({
            text: values?.subject,
            availableTags,
          })}
          availableTags={availableTags}
          type="HTMLEdit"
          placeholder="Enter Email Subject..."
        />
      </FormRow>

      <TemplateEmailRecipients values={values} setFieldValue={setFieldValue} />

      <RowSubtitle>
        Email Body
        <CentererVertical gap="8px">
          <BillingDynamicFieldsTooltip
            tags={{
              [DYNAMIC_FIELD_TOOLTIP_SECTIONS.GENERAL_TAGS]: TEMPLATE_MODAL_DYNAMIC_TAGS,
              [DYNAMIC_FIELD_TOOLTIP_SECTIONS.TRANSACTION_METADATA_TAGS]: transactionMetadataTags,
              [DYNAMIC_FIELD_TOOLTIP_SECTIONS.CUSTOMER_METADATA_TAGS]: customerMetadataTags,
              [DYNAMIC_FIELD_TOOLTIP_SECTIONS.TRANSACTION_CUSTOMER_METADATA_TAGS]: transactionCustomerMetadataTags,
            }}
            XOffset={100}
          />
        </CentererVertical>
      </RowSubtitle>

      <FormRow childrenWidth="100%">
        <FormikCustomInput
          name="body"
          minHeight="186px"
          HTMLString={templateToHTMLString({
            text: values?.body,
            availableTags,
          })}
          availableTags={availableTags}
          type="HTMLEdit"
          placeholder="Enter Email Body..."
        />
      </FormRow>

      <DynamicFields>
        <DynamicFieldsTitle>
          <Row>
            Dynamic fields (
            <i>
              you can use them in <span>Email Subject</span> and <span>Email Body</span>
            </i>
            )
          </Row>

          <DynamicFieldsInfo />
        </DynamicFieldsTitle>

        <DynamicFieldsTags>
          {availableTags.map((tag) => (
            <DynamicTag
              onClick={() => {
                navigator.clipboard.writeText(tag);
                pushToast('Copied to clipboard successfully', 'success');
              }}
            >
              {tag}
            </DynamicTag>
          ))}
        </DynamicFieldsTags>
      </DynamicFields>
    </ModalBody>
  );
};
