import React, { useContext } from 'react';
import { Form, Formik } from 'formik';

import { AppContext } from 'AppContext';
import { formatDateForDatepicker } from 'utils/dateUtils';
import { ModalBody, ModalButton, ModalFooter } from 'components/Modal';
import { Spacer } from 'components/Core';
import { CustomDatePicker, FormikCustomInput } from 'components/Controls';
import { InputsRow, InputsColumn } from './styles';
import { EXISTING_CUSTOMERS_FORECAST_TYPE, FORECAST_SCENARIO_TYPE, NEW_CUSTOMERS_FORECAST_TYPE } from '../consts';
import { RenewalUpsellCustomersSection } from './RenewalUpsellCustomersSection';
import { NewCustomersSection } from './NewCustomersSection';

const getInitialValuesByForecastScenarioType = ({ isARR, scenarioType, initialValues }) => {
  return scenarioType === FORECAST_SCENARIO_TYPE.WEIGHTED
    ? {
        ...initialValues,
        existing_customers_forecast_type: EXISTING_CUSTOMERS_FORECAST_TYPE.NRR,
        new_customers_forecast_type: NEW_CUSTOMERS_FORECAST_TYPE.PIPELINE_WEIGHTED,

        average_pipeline_recurring_revenue: (initialValues?.average_pipeline_recurring_revenue ?? 0) * (isARR ? 12 : 1),
        average_new_recurring_revenue_growth: (initialValues?.average_new_recurring_revenue_growth ?? 0) * 100,
        average_pipeline_sales_cycle: initialValues?.average_pipeline_sales_cycle ?? 0,
        average_pipeline_probability: initialValues?.average_pipeline_probability ?? 0,
        start_generating_new_pipeline_after_months: initialValues?.start_generating_new_pipeline_after_months ?? 0,
      }
    : {
        ...initialValues,
        // On UNWEIGHTED scenarios, existing customers will always be based on pipeline
        existing_customers_forecast_type: EXISTING_CUSTOMERS_FORECAST_TYPE.PIPELINE,
        new_customers_forecast_type: NEW_CUSTOMERS_FORECAST_TYPE.PIPELINE_UNWEIGHTED,

        pipeline_unweighted_buckets: Object.entries(initialValues?.pipeline_unweighted_buckets ?? {}).reduce(
          (acc, [key, values]) => {
            acc[key] = {
              ...values,
              average_pipeline_recurring_revenue: (values?.average_pipeline_recurring_revenue ?? 0) * (isARR ? 12 : 1),
              average_new_recurring_revenue_growth: values?.average_new_recurring_revenue_growth * 100,
              average_pipeline_sales_cycle: values?.average_pipeline_sales_cycle ?? 0,
              average_pipeline_probability: values?.average_pipeline_probability ?? 0,
              start_generating_new_pipeline_after_months: values?.start_generating_new_pipeline_after_months ?? 0,
            };
            return acc;
          },
          {},
        ),
      };
};
const convertDataByForecastScenarioType = ({ isARR, scenarioType, data }) =>
  scenarioType === FORECAST_SCENARIO_TYPE.WEIGHTED
    ? {
        ...data,
        average_pipeline_recurring_revenue: data.average_pipeline_recurring_revenue / (isARR ? 12 : 1),
        average_new_recurring_revenue_growth: data.average_new_recurring_revenue_growth / 100,
      }
    : {
        ...data,
        pipeline_unweighted_buckets: Object.entries(data.pipeline_unweighted_buckets ?? {}).reduce(
          (acc, [key, values]) => {
            acc[key] = {
              ...values,
              average_pipeline_recurring_revenue: values.average_pipeline_recurring_revenue / (isARR ? 12 : 1),
              average_new_recurring_revenue_growth: values.average_new_recurring_revenue_growth / 100,
            };
            return acc;
          },
          {},
        ),
      };

export const ForecastModalForm = ({ name, scenarioType, initialValues, onSubmit, onClose, editMode }) => {
  const {
    appSettings: { isARR, currencyISOCode: currency },
  } = useContext(AppContext);

  const convertedInitialValues = getInitialValuesByForecastScenarioType({
    isARR,
    scenarioType,
    initialValues,
  });

  const handleOnSubmit = (data) => {
    onSubmit(
      convertDataByForecastScenarioType({
        isARR,
        scenarioType,
        data,
      }),
    );
  };

  return (
    <Formik initialValues={convertedInitialValues} onSubmit={handleOnSubmit} enableReinitialize={true}>
      {({ submitForm, values, setFieldValue }) => (
        <Form>
          <Spacer height="28px" />

          <ModalBody>
            <InputsRow>
              <InputsColumn>
                <FormikCustomInput
                  name="name"
                  data-cy={`${name}__name-input`}
                  placeholder="Enter name..."
                  label="Name"
                />
              </InputsColumn>

              <InputsColumn>
                <CustomDatePicker
                  formik
                  name="start_date"
                  label="Start Month"
                  dateFormat="MMM yyyy"
                  showMonthYearPicker
                  selected={values.start_date ? formatDateForDatepicker(values.start_date) : null}
                  onChange={setFieldValue}
                  disabled={true}
                />

                <CustomDatePicker
                  formik
                  name="end_date"
                  label="End Month"
                  dateFormat="MMM yyyy"
                  showMonthYearPicker
                  selected={values.end_date ? formatDateForDatepicker(values.end_date) : null}
                  onChange={setFieldValue}
                  disabled={true}
                />
              </InputsColumn>
            </InputsRow>

            {/* Only available for WEIGHTED scenarios, as in UNWEIGHTED is always pipeline*/}
            {scenarioType === FORECAST_SCENARIO_TYPE.WEIGHTED && <RenewalUpsellCustomersSection name={name} />}

            <NewCustomersSection
              name={name}
              scenarioType={scenarioType}
              values={values}
              editMode={editMode}
              currency={currency}
            />
          </ModalBody>
          <Spacer height="20px" />
          <ModalFooter flexEnd>
            <ModalButton onClick={onClose} data-cy={`${name}__cancel-button`}>
              Cancel
            </ModalButton>
            <ModalButton primary data-cy={`${name}__save-button`} onClick={submitForm}>
              Save
            </ModalButton>
          </ModalFooter>
        </Form>
      )}
    </Formik>
  );
};
