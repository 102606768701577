import * as Yup from 'yup';
import { INVOICING_FREQUENCIES } from '../consts';
import { isImportInvoiceTotalMatchWithSubscriptTotal } from './InvoiceScheduleSingleInvoicePanel/SingleInvoice/InvoiceItems/utils';

export const formValidations = () =>
  Yup.lazy(() =>
    Yup.object().shape({
      customer_id: Yup.string().required('Invoicing schedule needs a customer ID'),
      invoicing_frequency: Yup.string().when('invoices', {
        is: (invoices) => invoices?.length > 0,
        then: Yup.string().nullable(),
        otherwise: Yup.string()
          .typeError('Invoicing schedule needs an invoicing frequency')
          .required('Invoicing schedule needs an invoicing frequency')
          .oneOf(Object.values(INVOICING_FREQUENCIES), 'Invoicing schedule needs an invoicing frequency'),
      }),
      invoices: Yup.array()
        .of(
          Yup.object().shape({
            date: Yup.date('Invoice needs a date'),
            amount: Yup.number().when(['is_imported', 'importedOriginalTotal'], {
              is: (is_imported) => !!is_imported,
              then: Yup.number()
                .test('equalToImportedOriginalTotal', 'Invoice amount must match the imported data', function (value) {
                  const { importedOriginalTotal } = this.parent;
                  return isImportInvoiceTotalMatchWithSubscriptTotal({
                    originalAmount: importedOriginalTotal,
                    newAmount: value,
                  });
                })
                .typeError('Invoice amount must match the imported data'),
              otherwise: Yup.number().nullable(),
            }),
            invoice_items: Yup.array().of(
              Yup.object().shape({
                transaction_id: Yup.string().when('type', {
                  is: (val) => val === 'FEE' || val === 'TAXES',
                  then: Yup.string().nullable(),
                  otherwise: Yup.string().nullable().required('Invoice item needs a transaction'),
                }),
                amount: Yup.number().typeError('Invoice item needs an amount').required('Invoice item needs an amount'),
                seats: Yup.number().nullable(true),
                description: Yup.string().nullable(),
              }),
            ),
          }),
        )
        .min(1, 'Invoicing schedule needs to have at least one invoice'),
    }),
  );
