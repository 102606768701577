import { FormikCustomInput } from 'components/Controls';
import { humanize } from 'utils/stringUtils';
import { FieldBody, FieldTitle } from 'views/Wizard/styles';

import { ItemsRow } from '../SubscriptBillingTab/BillingSettings/styles';

const GeneralSettings = ({ values, service }) => {
  return (
    <FieldBody>
      <FieldTitle>
        <span>General information</span>
      </FieldTitle>
      <ItemsRow key={values.id}>
        <FormikCustomInput
          name="display_name"
          label="Name of integration"
          data-cy="general-ledger-configuration__display-name-input"
        />

        <FormikCustomInput isDisabled name="user_account" label={`integrated ${humanize(service)} account`} />
      </ItemsRow>
    </FieldBody>
  );
};

export default GeneralSettings;
