import { useState } from 'react';
import styled, { css } from 'styled-components';

import { StyledInput } from 'components/Controls/FormikCustomInput/FormikCustomInput';
import { ModalContainer } from 'components/Modal';
import { useFormikContext } from 'formik';

const EditNameModal = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  background: #ffffff;
  box-shadow: 0px 30px 120px rgba(0, 21, 46, 0.4);
  border-radius: 20px;
  padding: 24px 30px;
  width: fit-content;
  min-width: 300px;
`;

const ModalTitle = styled.div`
  font-size: 20px;
  line-height: 26px;
  font-weight: 900;
  margin-bottom: 20px;
  text-align: center;
`;

const ButtonsRow = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 5px;
`;

const CancelButton = styled.div`
  padding: 11px 14px;
  font-size: 14px;
  line-height: 18px;
  background: var(--primaryBlack5);
  border: 1px solid var(--primaryBlack5);
  border-radius: 100px;
  margin-right: 10px;
  cursor: pointer;
`;

const SubmitButton = styled.div`
  padding: 11px 14px;
  font-size: 14px;
  color: #ffffff;
  line-height: 18px;
  background: ${(props) => (props.isRemove ? 'var(--primaryRed)' : 'var(--primaryGreen)')};
  box-shadow: 0px 4px 20px rgba(82, 181, 120, 0.2);
  border-radius: 100px;
  border: none;
  cursor: pointer;
`;

const NameInput = styled(StyledInput)`
  ${({ error }) =>
    error &&
    css`
      border-color: var(--secondaryRed);
      &:hover {
        border-color: var(--secondaryRed);
      }
    `}
`;

const ErrorMessage = styled.div`
  color: var(--secondaryRed);
  font-size: 10px;
  padding-left: 5px;
  line-height: 20px;
  visibility: ${({ error }) => (error ? 'visible' : 'hidden')};
`;

export const EditTransactionNameModal = ({ value, onClose }) => {
  const [name, setName] = useState(value);
  const [error, setError] = useState(false);

  const { setFieldValue } = useFormikContext();

  return (
    <ModalContainer data-cy="edit-transaction-name-modal">
      <EditNameModal>
        <ModalTitle>Edit Transaction Name</ModalTitle>
        <NameInput
          value={name}
          onChange={(e) => {
            setName(e.target.value);
          }}
          error={error}
          data-cy="input-name"
        />
        <ErrorMessage error={error}>name is required</ErrorMessage>
        <ButtonsRow>
          <CancelButton onClick={() => onClose()}>Cancel</CancelButton>
          <SubmitButton
            onClick={async () => {
              if (name.trim()) {
                setFieldValue('name', name);

                onClose();
              } else {
                setError(true);
              }
            }}
            data-cy="edit-transaction--save-name-button"
          >
            Set Name
          </SubmitButton>
        </ButtonsRow>
      </EditNameModal>
    </ModalContainer>
  );
};
