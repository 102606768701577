import styled from 'styled-components';
import { Centerer } from 'components/Core';

export const FooterButton = styled(Centerer)`
  background: ${(props) => props.save && 'var(--primaryGreen)'};
  border-radius: 100px;
  margin-right: ${(props) => props.delete && '10px'};
  padding: 8px 10px;
  font-size: 12px;
  line-height: 18px;
  font-weight: bold;
  cursor: pointer;
  pointer-events: ${(props) => props.disabled && 'none'};
  opacity: ${(props) => props.disabled && '0.3'};
  border: ${(props) => props.save && '1px solid rgba(30, 183, 110, 0.1)'};
  box-shadow: ${(props) => props.save && '0px 4px 10px rgba(82, 181, 120, 0.2)'};
  color: ${(props) => {
    if (props.delete) {
      return 'var(--primaryRed)';
    } else if (props.cancel) {
      return 'var(--primaryBlack)';
    } else if (props.duplicate) {
      return 'var(--primaryGreen)';
    } else if (props.save) {
      return 'white';
    }
  }};

  &:last-child {
    margin-right: 0;
  }

  span {
    margin-left: 4px;
  }

  svg {
  }

  &:hover {
    svg rect {
      fill: ${(props) => props.save && '#004025'};
    }

    ${(props) => {
      if (props.delete) {
        return `
          background-color: rgba(255, 89, 89, 0.1);
          `;
      } else if (props.duplicate) {
        return `
          background-color:  rgba(30, 183, 110, 0.1);
        `;
      } else if (props.save) {
        return `
            background-color: var(--secondaryGreen);
          `;
      }
    }};
  }
`;

export const HotkeyText = styled.span`
  opacity: 0.7;
`;
