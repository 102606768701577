import styled from 'styled-components';
import { ReactTableCell, ReactTableRow } from 'components/Table';
import { Centerer, Flexer } from 'components/Core';
import { STATUS_OPTIONS } from './consts';

export const TransactionReactTableCell = styled(ReactTableCell)`
  height: ${(props) => props.isAbsolute && '26px'};

  &:first-child {
    padding-left: ${(props) => (props.isAbsolute ? '0' : '6px')};
  }
`;

export const TransactionReactTableRow = styled(ReactTableRow)`
  position: relative;
  background: ${(props) => {
    if (props.highlight) return 'var(--primaryBlack3)';
    if (props.subRow) return 'var(--primaryBlack2)';
    return 'white';
  }};

  opacity: ${(props) => (props.isBlurred ? '0.5' : '1')};
`;

export const SearchContainer = styled.div`
  display: flex;
  padding-left: 12px;
  align-items: center;
  z-index: 1;
`;

export const Wrapper = styled.div`
  position: relative;
`;

export const TotalTransactionCount = styled.span`
  color: rgba(0, 21, 46, 0.2);
  font-weight: 900;
  font-size: 10px;
  line-height: 14px;
  text-transform: uppercase;
  text-align: right;
`;

export const ToolTipRow = styled.div`
  align-items: center;
  display: flex;
`;

export const StatusDice = styled.div`
  padding: 2px 4px;
  font-weight: 700;
  font-size: 11px;
  line-height: 14px;
  text-transform: capitalize;
  border-radius: 4px;
  background-color: ${({ status }) =>
    status === STATUS_OPTIONS.DELETED
      ? 'var(--primaryRed10)'
      : status === STATUS_OPTIONS.CONFIRMED
      ? 'var(--primaryGreen10)'
      : 'var(--primaryYellow10)'};
  color: ${({ status }) =>
    status === STATUS_OPTIONS.DELETED
      ? 'var(--secondaryRed)'
      : status === STATUS_OPTIONS.CONFIRMED
      ? 'var(--primaryGreen)'
      : 'var(--primaryYellow)'};
`;

export const ChecksDice = styled.div`
  padding: 2px 4px;
  font-weight: 700;
  font-size: 11px;
  line-height: 14px;
  text-transform: capitalize;
  border-radius: 4px;
  background-color: var(--primaryGreen10);
  color: var(--primaryGreen);
  cursor: pointer;

  &:hover {
    background-color: var(--secondaryGreen20);
    color: var(--secondaryGreen);
  }
`;

export const ChecksDiceTooltip = styled(Centerer)`
  flex-direction: column;

  svg {
    margin: 4px;

    g {
      opacity: 1;

      path {
        fill: var(--primaryGreen);
      }
    }
  }

  span {
    padding: 4px;
  }
`;

export const DealNameColumn = styled.div`
  cursor: pointer;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: var(--primaryBlue);

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const UnassignedCustomerText = styled.span`
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: var(--primaryBlack);

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const CustomerName = styled(UnassignedCustomerText)`
  color: var(--primaryBlue);
  cursor: pointer;
`;

export const LoadingSpinnerWrapper = styled(Flexer)`
  position: relative;
  margin-right: 45px;
  height: 24px;
`;
