import React, { useCallback, useContext } from 'react';
import dayjs from 'dayjs';
import { FlexerColumn, Spacer } from 'components/Core';
import { AppContext } from 'AppContext';
import { NUMBER_FORMATS, SEGMENT_BY_OPTIONS } from 'consts/global';
import { RESOLUTION_OPTIONS } from 'shared/Filters';
import { useCurrencyNumberFormatter } from 'utils/hooks';
import TooltipContainer from 'components/Tooltip/Tooltip';
import { NULL_SEGMENT_STRING_TIMESERIES } from 'shared/Filters/MetadataFilter/consts';
import { dataFilterSegmentKeyToDatabaseSegmentKey } from 'shared/MetadataSegmentsModal/utils';
import {
  getFormatType,
  getProductName,
  getBackgroundByHeatLevel,
  isWhiteText as _isWhiteText,
  getHeatLevel,
} from '../utils';
import { INSTALL_BY_OPTIONS } from '../consts';
import { StyledAverageRow, AverageTile, TileValue, TableDataCell } from './styles';
import { CohortsContext } from '../CohortsContext';

const getTileValue = ({ isARR, installBy, element }) =>
  isARR && installBy === INSTALL_BY_OPTIONS.revenueDollar ? element * 12 : element;

export const CohortAverageRow = ({ segmentTooltips }) => {
  const { organizations } = useContext(AppContext);
  const {
    isARR,
    cohortPalette,
    cohortsData,
    dataFilter,
    totalAmounts,
    setShowSpreadsModal,
    setCohortStartDate,
    setCohortEndDate,
    setSelectedMonth,
    setCohortCustomerIds,
    setSegmentDescriptor,
    cohortResolution,
  } = useContext(CohortsContext);
  const numberFormatter = useCurrencyNumberFormatter();

  const { cohortInstallBy, cohortInstallSecondBy, cohortSegmentBy, startMonth, endMonth } = dataFilter;

  const isWhiteText = useCallback((heatLevel) => _isWhiteText({ primary: true, heatLevel, cohortPalette }), [
    cohortPalette,
  ]);

  const totalOrAverageKey = cohortInstallBy === INSTALL_BY_OPTIONS.customerCount ? 'averageRow' : 'totalRow';

  if (!cohortsData?.[cohortInstallBy] || (cohortInstallSecondBy && !cohortsData?.[cohortInstallSecondBy])) return null;

  const data = cohortsData[cohortInstallBy].totalAndAverageRows;
  const dataMaxValue = Math.max(...data[totalOrAverageKey].monthData);

  let secondaryData;
  let secondaryDataMaxValue;
  if (cohortInstallSecondBy) {
    secondaryData = cohortsData[cohortInstallSecondBy].totalAndAverageRows ?? {};
    secondaryDataMaxValue = Math.max(...(secondaryData[totalOrAverageKey].monthData ?? []));
  }

  const segmentKeys =
    cohortSegmentBy && cohortsData[cohortInstallBy].totalAndAverageSegmentRows
      ? Object.keys(cohortsData[cohortInstallBy].totalAndAverageSegmentRows)
      : [];

  const getToolTipContent = (cohortInstallBy) => {
    switch (cohortInstallBy) {
      case INSTALL_BY_OPTIONS.revenueDollar:
        if (totalAmounts) {
          return '(sum of total revenue for month) / (total number of customers for month)';
        } else {
          return '(sum of (average revenue for month * number of customers for month)) / (total number of customers for month)';
        }
      case INSTALL_BY_OPTIONS.customerCount:
        return '(sum of customer counts in month) / (number of cohorts for month)';
      case INSTALL_BY_OPTIONS.revenuePercent:
        return '(sum of total revenue in month) / (sum of starting revenues)';
      case INSTALL_BY_OPTIONS.customerPercent:
        return '(sum of customer counts in month) / (sum of starting customer counts)';
      default:
    }
  };

  const toolTipText = getToolTipContent(cohortInstallBy);
  const secondaryToolTipText = getToolTipContent(cohortInstallSecondBy);

  const handleClick = (isSegmentCell, index, cohortData, cohortSegmentBy, segmentKey) => {
    setShowSpreadsModal(true);
    setCohortStartDate(dayjs.utc(startMonth).startOf('month').format('YYYY-MM-D'));
    setCohortEndDate(dayjs.utc(endMonth).endOf('month').format('YYYY-MM-D'));
    setSelectedMonth(cohortResolution === RESOLUTION_OPTIONS.monthly ? index : null); //When index === 0, that is the first month
    if (isSegmentCell) {
      setCohortCustomerIds(cohortData?.customers);
      setSegmentDescriptor(`${cohortSegmentBy}: ${segmentKey}`);
    } else {
      setCohortCustomerIds(null);
      setSegmentDescriptor(null);
    }
  };

  return (
    <>
      <StyledAverageRow marginBottom={!cohortSegmentBy}>
        <AverageTile noBorderBottom={!!cohortSegmentBy}>W. Avg.</AverageTile>
        <AverageTile data-cy="cohort-table__average-row__total-customers" noBorderBottom={!!cohortSegmentBy}>
          <TooltipContainer width={300} toolTipContent={'Sum of all customers included in any cohort (not an average)'}>
            {numberFormatter({ type: NUMBER_FORMATS.NUMBER, rawValue: data.startingCustomerCount })}
          </TooltipContainer>
        </AverageTile>
        <AverageTile noBorderBottom={!!cohortSegmentBy}>
          <TooltipContainer
            width={300}
            toolTipContent={'Avg. Retention = (sum of ending Rev. Ret. for each cohort) / (# of cohorts)'}
          >
            {numberFormatter({ type: NUMBER_FORMATS.PERCENT, rawValue: data.revenueRetention })}
          </TooltipContainer>
        </AverageTile>
        {data[totalOrAverageKey].monthData.map((element, idx) => {
          let heatLevel;
          heatLevel = getHeatLevel({
            installBy: cohortInstallBy,
            value: data[totalOrAverageKey].monthData[idx],
            maxValue: dataMaxValue,
          });

          let secondaryHeatLevel;
          if (cohortInstallSecondBy) {
            secondaryHeatLevel = getHeatLevel({
              installBy: cohortInstallSecondBy,
              value: secondaryData[totalOrAverageKey].monthData[idx],
              maxValue: secondaryDataMaxValue,
            });
          }

          return (
            <FlexerColumn style={{ padding: '3px 0' }} data-cy={`cohorts-table__average-row--${idx}`} key={idx}>
              <TableDataCell
                backgroundColor={getBackgroundByHeatLevel(cohortPalette, heatLevel)}
                primary
                height={cohortInstallSecondBy ? '60%' : '100%'}
                whiteText={isWhiteText(heatLevel)}
              >
                <TooltipContainer width={168} fontSize="10px" isVisible={toolTipText} toolTipContent={toolTipText}>
                  <TileValue
                    active={true}
                    segmentCell
                    onClick={() => handleClick(false, idx)}
                    data-cy={`${totalOrAverageKey}-${idx}-cohort-table-value`}
                  >
                    <div>
                      {numberFormatter({
                        type: getFormatType(cohortInstallBy),
                        rawValue: getTileValue({
                          isARR,
                          installBy: cohortInstallBy,
                          element: data[totalOrAverageKey].monthData[idx],
                        }),
                      })}
                    </div>
                  </TileValue>
                </TooltipContainer>
              </TableDataCell>

              {cohortInstallSecondBy && secondaryData ? (
                <>
                  <Spacer height="1px" />
                  <TableDataCell
                    backgroundColor={getBackgroundByHeatLevel(cohortPalette, secondaryHeatLevel)}
                    height={'calc(40% - 1px)'}
                    primary
                    whiteText={isWhiteText(secondaryHeatLevel)}
                  >
                    <TooltipContainer
                      width={168}
                      fontSize="10px"
                      isVisible={secondaryToolTipText}
                      toolTipContent={secondaryToolTipText}
                    >
                      <TileValue active={true} segmentCell onClick={() => handleClick(false, idx)}>
                        {numberFormatter({
                          type: getFormatType(cohortInstallSecondBy),
                          rawValue: getTileValue({
                            isARR,
                            installBy: cohortInstallSecondBy,
                            element: secondaryData[totalOrAverageKey].monthData[idx],
                          }),
                        })}
                      </TileValue>
                    </TooltipContainer>
                  </TableDataCell>
                </>
              ) : null}
            </FlexerColumn>
          );
        })}
      </StyledAverageRow>
      {cohortSegmentBy &&
        //we use sort there for consistent order in segments
        segmentKeys.sort().map((segmentKey, index) => {
          const segmentData = cohortsData[cohortInstallBy].totalAndAverageSegmentRows[segmentKey];
          const segmentDataMaxValue = Math.max(...segmentData[totalOrAverageKey].monthData);

          let secondarySegmentData;
          let secondarySegmentDataMaxValue;
          if (cohortInstallSecondBy) {
            secondarySegmentData = cohortsData[cohortInstallSecondBy].totalAndAverageSegmentRows[segmentKey] ?? {};
            secondarySegmentDataMaxValue = Math.max(...(secondarySegmentData[totalOrAverageKey].monthData ?? []));
          }

          const metadataSegmentKey = dataFilterSegmentKeyToDatabaseSegmentKey(cohortSegmentBy);
          const cohortDisplayRowName =
            cohortSegmentBy === SEGMENT_BY_OPTIONS.PRODUCT
              ? getProductName(organizations?.[0]?.products ?? [], segmentKey)
              : segmentKey;
          const customSegmentTooltip = segmentTooltips?.[metadataSegmentKey]?.[segmentKey];

          return (
            <StyledAverageRow key={segmentKey} marginBottom={index === segmentKeys.length - 1}>
              <AverageTile noBorderTop noBorderBottom={index !== segmentKeys.length - 1}>
                <TooltipContainer
                  width={150}
                  isVisible={
                    segmentKey === NULL_SEGMENT_STRING_TIMESERIES ||
                    !!customSegmentTooltip ||
                    cohortDisplayRowName.length >= 20
                  }
                  fontSize="10px"
                  toolTipContent={
                    segmentKey === NULL_SEGMENT_STRING_TIMESERIES
                      ? "Customers who don't have a value for the month"
                      : customSegmentTooltip
                      ? customSegmentTooltip
                      : cohortDisplayRowName
                  }
                >
                  <TileValue active={false} segmentCell>
                    {cohortDisplayRowName.length < 20
                      ? cohortDisplayRowName
                      : `${cohortDisplayRowName.substring(0, 20)}...`}
                  </TileValue>
                </TooltipContainer>
              </AverageTile>
              <AverageTile noBorderTop noBorderBottom={index !== segmentKeys.length - 1}>
                <TileValue
                  active={false}
                  segmentCell
                  data-cy={`cohorts-page__table-tile--${totalOrAverageKey}-starting-customer-count--segment-${segmentKey}`}
                >
                  {numberFormatter({ type: NUMBER_FORMATS.NUMBER, rawValue: segmentData.startingCustomerCount })}
                </TileValue>
              </AverageTile>
              <AverageTile noBorderTop noBorderBottom={index !== segmentKeys.length - 1}>
                <TileValue active={false} segmentCell>
                  {numberFormatter({ type: NUMBER_FORMATS.PERCENT, rawValue: segmentData.revenueRetention })}
                </TileValue>
              </AverageTile>
              {segmentData[totalOrAverageKey].monthData.map((element, idx) => {
                const segmentDataValue = segmentData[totalOrAverageKey].monthData[idx];
                const cohortHeatLevel = getHeatLevel({
                  installBy: cohortInstallBy,
                  value: segmentDataValue,
                  maxValue: segmentDataMaxValue,
                });

                let cohortSecondaryHeatLevel;
                let secondarySegmentDataValue;
                if (cohortInstallSecondBy && secondarySegmentData) {
                  secondarySegmentDataValue = secondarySegmentData[totalOrAverageKey].monthData[idx] ?? 0;
                  cohortSecondaryHeatLevel = getHeatLevel({
                    installBy: cohortInstallSecondBy,
                    value: secondarySegmentDataValue,
                    maxValue: secondarySegmentDataMaxValue,
                  });
                }

                return (
                  <FlexerColumn
                    style={{ padding: '3px 0' }}
                    key={idx}
                    data-cy={`cohorts-table__average-row-segment-${segmentKey}--${idx}`}
                  >
                    <TableDataCell
                      backgroundColor={getBackgroundByHeatLevel(cohortPalette, cohortHeatLevel)}
                      primary
                      height={cohortInstallSecondBy ? '60%' : '100%'}
                      whiteText={isWhiteText(cohortHeatLevel)}
                    >
                      <TileValue
                        active
                        segmentCell
                        onClick={() => handleClick(true, idx, segmentData, cohortSegmentBy, segmentKey)}
                        data-cy={`cohorts-table__table-value--${totalOrAverageKey}--segment-${segmentKey}`}
                      >
                        <div>
                          {numberFormatter({
                            type: getFormatType(cohortInstallBy),
                            rawValue: getTileValue({
                              isARR,
                              installBy: cohortInstallBy,
                              element: segmentDataValue,
                            }),
                          })}
                        </div>
                      </TileValue>
                    </TableDataCell>

                    {cohortInstallSecondBy && secondarySegmentData ? (
                      <>
                        <Spacer height="1px" />
                        <TableDataCell
                          backgroundColor={getBackgroundByHeatLevel(cohortPalette, cohortSecondaryHeatLevel)}
                          height={'calc(40% - 1px)'}
                          primary
                          whiteText={isWhiteText(cohortSecondaryHeatLevel)}
                        >
                          <TileValue
                            active
                            segmentCell
                            onClick={() => handleClick(true, idx, secondarySegmentData, cohortSegmentBy, segmentKey)}
                          >
                            {numberFormatter({
                              type: getFormatType(cohortInstallSecondBy),
                              rawValue: getTileValue({
                                isARR,
                                installBy: cohortInstallSecondBy,
                                element: secondarySegmentDataValue,
                              }),
                            })}
                          </TileValue>
                        </TableDataCell>
                      </>
                    ) : null}
                  </FlexerColumn>
                );
              })}
            </StyledAverageRow>
          );
        })}
    </>
  );
};
