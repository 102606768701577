import React, { useContext } from 'react';
import styled from 'styled-components';
import { Bar, defaults } from 'react-chartjs-2';
import { transparentize } from 'polished';
import { cssVar } from 'polished';
import { AppContext } from 'AppContext';
import { reFormatDate } from 'utils/dateUtils';
import { ReactComponent as FillDot } from 'images/fill-dot.svg';
import { numberFormatter } from 'utils/formatters';
import { NUMBER_FORMATS } from 'consts/global';
import { DATA_TYPES } from 'shared/Filters/utils';

const DefaultLegendContainer = styled.div`
  width: 100%;
  min-height: 38px;
  display: flex;
  background: white;
  border: 1px solid var(--accentGraySecond);
  box-shadow: 8px 8px 60px var(--primaryBlack4);
  border-radius: 8px;
  margin-top: 24px;
`;

const DataKeysTotal = styled.div`
  padding: 12px 0px 12px 16px;
  color: var(--primaryBlack30);
  font-weight: 800;
  font-size: 10px;
  line-height: 14px;
  text-transform: uppercase;
  white-space: nowrap;
`;

const KeysContainer = styled.div`
  width: 95%;
  display: flex;
  font-weight: 900;
  font-size: 10px;
  line-height: 14px;
  text-transform: uppercase;
  vertical-align: sub;
  padding: 12px 14px;
`;

const LegendIcon = styled.div`
  margin-right: 22px;

  span {
    margin-left: 6px;
  }
`;

const ChartLegendContainer = styled.div`
  width: 100%;
`;

const ChartContainer = styled.div`
  width: 100%;
`;

export const SpreadsChart = ({ spreadsData, selectedMonth, cohortsDrilldown, dataTypeToShow }) => {
  defaults.global.defaultFontFamily = 'Nunito Sans';

  const {
    appSettings: { isARR, currencyISOCode: currency },
  } = useContext(AppContext);

  if (Object.keys(spreadsData).length === 0) {
    return null;
  }

  const { totalCustomersPerMonth } = spreadsData;
  const quantityEachMonth =
    dataTypeToShow === DATA_TYPES.revenue ? spreadsData.totalAmountsEachMonth : spreadsData.totalSeatsEachMonth;
  const customerCount =
    dataTypeToShow === DATA_TYPES.revenue
      ? spreadsData.nonZeroAmountCustomersPerMonth
      : spreadsData.nonZeroSeatsCustomersPerMonth;

  const months = quantityEachMonth ? Object.keys(quantityEachMonth) : [];
  let monthRevenueTotals = quantityEachMonth ? Object.values(quantityEachMonth) : [];
  monthRevenueTotals = isARR ? monthRevenueTotals.map((value) => value * 12) : monthRevenueTotals;
  const monthCustomerTotals = cohortsDrilldown ? Object.values(totalCustomersPerMonth) : Object.values(customerCount);

  const revenueChartData = {
    type: 'line',
    label: 'Revenue',
    fill: false,
    borderColor: cssVar('--accentDarkGray'),
    pointBackgroundColor: cssVar('--accentDarkGray'),
    pointBorderColor: 'white',
    borderWidth: 2,
    pointRadius: 4,
    lineTension: 0,
    data: monthRevenueTotals.map((value) => Math.round(value)),
    yAxisID: 'y0',
  };

  const customersChartData = {
    type: 'bar',
    label: 'Customers',
    backgroundColor: months.map((month, index) => (index === selectedMonth ? '#FFAB2D' : '#957AFF')),
    data: monthCustomerTotals.map((value) => value),
    barThickness: 18,
    yAxisID: 'y1',
  };

  const options = {
    responsive: true,
    legend: {
      display: false,
    },
    tooltips: {
      callbacks: {
        title: function (tooltipItem) {
          return reFormatDate(tooltipItem[0].label, 'YYYY-MM', 'MMM YY');
        },
        label: function (tooltipItem) {
          if (tooltipItem.datasetIndex === 0) {
            return dataTypeToShow === DATA_TYPES.revenue
              ? numberFormatter({
                  type: NUMBER_FORMATS.CURRENCY,
                  rawValue: tooltipItem.yLabel,
                  currency,
                })
              : numberFormatter({ type: NUMBER_FORMATS.NUMBER, rawValue: tooltipItem.yLabel });
          } else {
            return `${tooltipItem.yLabel.toString()} customers`;
          }
        },
      },
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
          },
          ticks: {
            padding: 10,
            fontStyle: 'bold',
            fontColor: transparentize(0.8, cssVar('--primaryBlack')),
            callback: function (value) {
              return reFormatDate(value, 'YYYY-MM', 'MMM YY');
            },
          },
        },
      ],
      yAxes: [
        {
          id: 'y0',
          position: 'right',
          gridLines: {
            display: false,
            drawBorder: false,
          },
          ticks: {
            padding: 10,
            fontStyle: 'bold',
            fontColor: transparentize(0.6, cssVar('--primaryBlack')),
            min: 0,
            maxTicksLimit: 6,
            callback: function (value) {
              return dataTypeToShow === DATA_TYPES.revenue
                ? numberFormatter({ type: NUMBER_FORMATS.CURRENCY, rawValue: value, currency })
                : numberFormatter({ type: NUMBER_FORMATS.NUMBER, rawValue: value });
            },
          },
        },
        {
          id: 'y1',
          position: 'left',
          gridLines: {
            drawBorder: false,
          },
          ticks: {
            padding: 10,
            fontStyle: 'bold',
            fontColor: transparentize(0.6, cssVar('--primaryBlack')),
            min: 0,
            maxTicksLimit: 6,
          },
        },
      ],
    },
  };

  return (
    <ChartLegendContainer>
      <ChartContainer>
        <Bar
          height={55}
          data={{
            labels: months,
            datasets: [revenueChartData, customersChartData],
          }}
          options={options}
        />
      </ChartContainer>

      <DefaultLegendContainer>
        <DataKeysTotal> {cohortsDrilldown ? 'cohort chart' : 'revenue spread chart'}:</DataKeysTotal>
        <KeysContainer>
          <LegendIcon>
            <FillDot fill={cssVar('--primaryPurple')} />
            <span>Customers</span>
          </LegendIcon>

          {cohortsDrilldown && (
            <LegendIcon>
              <FillDot fill={cssVar('--primaryYellow')} />
              <span>HIGHLIGHTED MONTH</span>
            </LegendIcon>
          )}

          <LegendIcon>
            <FillDot fill={cssVar('--accentDarkGray')} />
            <span>total revenue</span>
          </LegendIcon>
        </KeysContainer>
      </DefaultLegendContainer>
    </ChartLegendContainer>
  );
};
