import styled from 'styled-components';

import { BinIcon } from '../Icons';
import { Spacer } from '../Core';
import { Button } from './Button';

export const RemoveButtonStyle = styled(Button)`
  background: rgba(0, 21, 46, 0);
  border: none;
  font-weight: 700;
  color: var(--primaryRed);
`;

export const RemoveButton = ({ onClick, disabled, children, dataCy, className, ...rest }) => (
  <RemoveButtonStyle data-cy={dataCy} className={className} onClick={onClick} disabled={disabled} {...rest}>
    <BinIcon /> <Spacer width="10px" /> {children}
  </RemoveButtonStyle>
);
