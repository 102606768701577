import React from 'react';
import { ActionButton } from './styles';
import { INTEGRATION_SERVICES } from 'consts/integrations';

export const SeePaymentsButton = ({ customer }) => {
  const stripeCustomerId = customer?.imports?.filter((customer) => customer.service === INTEGRATION_SERVICES.STRIPE)[0]
    ?.provider_object_id;

  if (!stripeCustomerId) return null;
  return (
    <ActionButton onClick={() => window.open(`https://dashboard.stripe.com/customers/${stripeCustomerId}`, '_blank')}>
      See payments
    </ActionButton>
  );
};
