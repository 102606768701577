import { useEffect, useRef } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useHistory } from 'react-router';

import { useToasts } from 'components/Toasts';
import { CACHE_KEY as TRANSACTIONS_CACHE_KEY } from 'api/transactions';
import {
  getPricingPlans,
  createPricingPlan as _createPricingPlan,
  updatePricingPlan,
  deletePricingPlan as _deletePricingPlan,
  bulkDeletePricingPlans as _bulkDeletePricingPlans,
  getUsageSubscriptions,
  getUsageSubscription,
  recalculateUsageSubscription as _recalculateUsageSubscription,
  createUsageSubscription as _createUsageSubscription,
  editUsageSubscription as _editUsageSubscription,
  deleteUsageSubscription as _deleteUsageSubscription,
  bulkDeleteUsageSubscriptions as _bulkDeleteUsageSubscriptions,
  getUsageEvents,
  createUsageEvent as _createUsageEvent,
  bulkCreateUsageEvent as _bulkCreateUsageEvent,
  getUniqueEventNames,
  getUniqueEventSources,
  evaluatePricingPlanFormula,
  getDiscounts,
  getDiscount,
  createDiscount as _createDiscount,
  updateDiscount,
  deleteDiscount as _deleteDiscount,
  bulkCreateUsageSubscriptions as _bulkCreateUsageSubscriptions,
  bulkCreatePricingPlans as _bulkCreatePricingPlans,
} from './requests';

export const CACHE_KEY = 'billing';

export const usePricingPlansAPI = ({ orgId, params = {}, autoFetch = true, enableToasts = true }) => {
  const dataKey = [CACHE_KEY, 'pricingPlans', orgId, JSON.stringify(params)];
  const history = useHistory();

  const { pushToast: wrappedPushToast, pushError: wrappedPushError } = useToasts();
  const pushToast = (...args) => {
    if (enableToasts) wrappedPushToast(...args);
  };
  const pushError = (...args) => {
    if (enableToasts) wrappedPushError(...args);
  };
  const queryClient = useQueryClient();

  const { data, error, isLoading, isFetching, refetch } = useQuery(dataKey, () => getPricingPlans({ orgId, params }), {
    enabled: autoFetch,
  });

  const createPricingPlan = useMutation(({ data }) => _createPricingPlan({ orgId, body: data }), {
    onError: (err) => {
      pushError(err, 'Failed to create pricing plan.');
    },

    onSuccess: () => {
      pushToast('Successfully created pricing plan!', 'success');
    },
    onSettled: () => {
      queryClient.invalidateQueries([CACHE_KEY, 'pricingPlans', orgId]);
    },
  });

  const editPricingPlan = useMutation(({ data, id }) => updatePricingPlan({ orgId, body: data, id }), {
    onError: (err) => {
      pushError(err, 'Failed to update pricing plan.');
    },

    onSuccess: () => {
      pushToast('Successfully updated pricing plan!', 'success');
    },
    onSettled: () => {
      queryClient.invalidateQueries([CACHE_KEY]);
    },
  });

  const deletePricingPlan = useMutation(({ id }) => _deletePricingPlan({ orgId, id }), {
    onError: (err) => {
      pushError(err, 'Failed to delete pricing plan.');
    },

    onSuccess: () => {
      pushToast('Successfully deleted pricing plan!', 'success');
    },

    onSettled: () => {
      queryClient.invalidateQueries([CACHE_KEY, 'pricingPlans', orgId]);
      queryClient.invalidateQueries([CACHE_KEY, 'usageSubscriptions', orgId]);
    },
  });

  const bulkDeletePricingPlans = useMutation(({ ids }) => _bulkDeletePricingPlans({ orgId, pricingPlanIds: ids }), {
    onError: (err) => {
      pushError(err, 'Failed to bulk delete pricing plans.');
    },

    onSuccess: () => {
      pushToast('Successfully bulk deleted pricing plans!', 'success');
    },

    onSettled: () => {
      queryClient.invalidateQueries([CACHE_KEY, 'pricingPlans', orgId]);
      queryClient.invalidateQueries([CACHE_KEY, 'usageSubscriptions', orgId]);
    },
  });

  const bulkUpload = useMutation(({ data }) => _bulkCreatePricingPlans({ orgId, body: data }), {
    onError: (err) => {
      pushError(err, 'Failed to upload pricing plans.');
    },

    onSuccess: () => {
      pushToast('Successfully uploaded pricing plans!', 'success');

      setTimeout(() => {
        history.push('/billing/usage-based-engine?tab=pricing-plans');
      }, 1000);
    },

    onSettled: async () => {
      queryClient.invalidateQueries(CACHE_KEY);
    },
  });

  return {
    data,
    error,
    isLoading,
    isFetching,
    operations: {
      refetch,
      createPricingPlan,
      editPricingPlan,
      deletePricingPlan,
      bulkDeletePricingPlans,
      bulkUpload: bulkUpload.mutateAsync,
    },
  };
};

export const useUsageSubscriptionsAPI = ({ orgId, params = {}, autoFetch = true, enableToasts = true }) => {
  const { pushToast: wrappedPushToast, pushError: wrappedPushError } = useToasts();
  const history = useHistory();

  const pushToast = (...args) => {
    if (enableToasts) wrappedPushToast(...args);
  };
  const pushError = (...args) => {
    if (enableToasts) wrappedPushError(...args);
  };
  const queryClient = useQueryClient();

  const { data, error, isLoading, isFetching, refetch } = useQuery(
    [CACHE_KEY, 'usageSubscriptions', orgId, JSON.stringify(params)],
    () => getUsageSubscriptions({ orgId, params }),
    {
      enabled: autoFetch,
    },
  );

  const createUsageSubscription = useMutation(({ data }) => _createUsageSubscription({ orgId, body: data }), {
    onError: (err) => {
      pushError(err, 'Failed to create usage subscription.');
    },

    onSuccess: () => {
      pushToast('Successfully created usage subscription!', 'success');
    },

    onSettled: () => {
      queryClient.invalidateQueries([CACHE_KEY, 'usageSubscriptions', orgId]);
    },
  });

  const editUsageSubscription = useMutation(
    ({ id, data }) => _editUsageSubscription({ orgId, subscriptionId: id, body: data }),
    {
      onError: (err) => {
        pushError(err, 'Failed to update usage subscription.');
      },

      onSuccess: () => {
        pushToast('Successfully updated usage subscription!', 'success');
      },

      onSettled: () => {
        queryClient.invalidateQueries([CACHE_KEY, 'usageSubscriptions', orgId]);
      },
    },
  );

  const createTransactionsForUsageSubscriptions = useMutation(
    ({ id }) => _editUsageSubscription({ orgId, subscriptionId: id, body: data, params: { createTransactions: true } }),
    {
      onError: (err) => {
        pushError(err, 'Failed to create transactions for subscription.');
      },

      onSuccess: () => {
        pushToast('Successfully created transactions for usage subscription!', 'success');
      },

      onSettled: () => {
        queryClient.invalidateQueries([CACHE_KEY, 'usageSubscriptions', orgId]);
      },
    },
  );

  const deleteUsageSubscription = useMutation(({ id }) => _deleteUsageSubscription({ orgId, subscriptionId: id }), {
    onError: (err) => {
      pushError(err, 'Failed to delete usage subscription.');
    },

    onSuccess: () => {
      pushToast('Successfully deleted usage subscription!', 'success');
    },

    onSettled: () => {
      queryClient.invalidateQueries([CACHE_KEY, 'usageSubscriptions', orgId]);
      queryClient.invalidateQueries(TRANSACTIONS_CACHE_KEY);
    },
  });

  const bulkDeleteUsageSubscriptions = useMutation(
    ({ ids }) => _bulkDeleteUsageSubscriptions({ orgId, subscriptionIds: ids }),
    {
      onError: (err) => {
        pushError(err, 'Failed to bulk delete usage subscriptions.');
      },

      onSuccess: () => {
        pushToast('Successfully bulk deleted usage subscriptions!', 'success');
      },

      onSettled: () => {
        queryClient.invalidateQueries([CACHE_KEY, 'usageSubscriptions', orgId]);
      },
    },
  );

  const bulkUpload = useMutation(({ data }) => _bulkCreateUsageSubscriptions({ orgId, body: data }), {
    onError: (err) => {
      pushError(err, 'Failed to upload subscriptions.');
    },

    onSuccess: () => {
      pushToast('Successfully uploaded subscriptions!', 'success');

      setTimeout(() => {
        history.push('/billing/usage-based-engine?tab=subscriptions');
      }, 1000);
    },

    onSettled: async () => {
      queryClient.invalidateQueries(CACHE_KEY);
    },
  });

  return {
    data,
    error,
    isLoading,
    isFetching,
    operations: {
      refetch,
      createUsageSubscription,
      editUsageSubscription,
      deleteUsageSubscription,
      bulkDeleteUsageSubscriptions,
      createTransactionsForUsageSubscriptions,
      bulkUpload: bulkUpload.mutateAsync,
    },
  };
};

export const useUsageSubscriptionAPI = ({
  orgId,
  id,
  params = {},
  autoFetch = true,
  queryOptions = {},
  enableToasts = true,
}) => {
  const { pushToast: wrappedPushToast, pushError: wrappedPushError } = useToasts();
  const pushToast = (...args) => {
    if (enableToasts) wrappedPushToast(...args);
  };
  const pushError = (...args) => {
    if (enableToasts) wrappedPushError(...args);
  };
  const queryClient = useQueryClient();

  const dataKey = [CACHE_KEY, 'usageSubscriptions', orgId, id, JSON.stringify(params)];

  const { data, error, isLoading, isFetching, refetch } = useQuery(
    dataKey,
    () => getUsageSubscription({ orgId, id, params }),
    {
      enabled: autoFetch,
      ...queryOptions,
    },
  );

  const recalculateUsageSubscription = useMutation(() => _recalculateUsageSubscription({ orgId, id }), {
    onError: (err) => {
      pushError(err, 'Failed to recalculate spreads for usage subscription.');
    },

    onSuccess: () => {
      pushToast('Successfully started recalculating spreads for usage subscription!', 'success');
    },

    onSettled: () => {
      queryClient.invalidateQueries([CACHE_KEY, 'usageSubscriptions', orgId]);
    },
  });

  return {
    data,
    error,
    isLoading,
    isFetching,
    operations: {
      refetch,
      recalculate: recalculateUsageSubscription,
    },
  };
};

export const useUsageEventsAPI = ({ orgId, params, autoFetch = true, enableToasts = true }) => {
  const dataKey = [CACHE_KEY, 'usageEvents', orgId, JSON.stringify(params)];

  const { pushToast: wrappedPushToast, pushError: wrappedPushError } = useToasts();
  const pushToast = (...args) => {
    if (enableToasts) wrappedPushToast(...args);
  };
  const pushError = (...args) => {
    if (enableToasts) wrappedPushError(...args);
  };
  const queryClient = useQueryClient();

  const { data, error, isLoading, isFetching, refetch } = useQuery(dataKey, () => getUsageEvents({ orgId, params }), {
    enabled: autoFetch,
  });

  const createUsageEvent = useMutation(({ data }) => _createUsageEvent({ orgId, body: data }), {
    onError: (err) => {
      pushError(err, 'Failed to create customer usage event.');
    },

    onSuccess: () => {
      pushToast(
        'Successfully created customer usage event! Related usage subscriptions and invoices will also be updated within a few minutes.',
        'success',
        10000,
      );
    },

    onSettled: () => {
      queryClient.invalidateQueries([CACHE_KEY, 'usageEvents', orgId]);
    },
  });

  const deleteUsageEvent = useMutation(({ data }) => _createUsageEvent({ orgId, body: data }), {
    onError: (err) => {
      pushError(err, 'Failed to delete customer usage event.');
    },

    onSuccess: () => {
      pushToast(
        'Successfully deleted customer usage event! Related usage subscriptions and invoices will also be updated within a few minutes.',
        'success',
        10000,
      );
    },

    onSettled: () => {
      queryClient.invalidateQueries([CACHE_KEY, 'usageEvents', orgId]);
    },
  });

  const bulkUpload = useMutation(({ data }) => _bulkCreateUsageEvent({ orgId, body: data }), {
    onError: (err) => {
      pushError(err, 'Failed to upload usage events.');
    },

    onSuccess: () => {
      pushToast('Successfully uploaded usage events!', 'success');
    },

    onSettled: async () => {
      queryClient.invalidateQueries(CACHE_KEY);
    },
  });

  return {
    data,
    error,
    isLoading,
    isFetching,
    operations: {
      refetch,
      createUsageEvent,
      deleteUsageEvent,
      bulkUpload: bulkUpload.mutateAsync,
    },
  };
};

export const useUniqueUsageEventNamesAPI = ({ orgId, autoFetch = true }) => {
  const dataKey = [CACHE_KEY, 'usageEvents', orgId, 'eventNames'];

  const { data, error, isLoading, isFetching, refetch } = useQuery(dataKey, () => getUniqueEventNames({ orgId }), {
    enabled: autoFetch,
  });

  return {
    data,
    error,
    isLoading,
    isFetching,
    operations: {
      refetch,
    },
  };
};

export const useUniqueUsageEventSourcesAPI = ({ orgId, autoFetch = true }) => {
  const dataKey = [CACHE_KEY, 'usageEvents', orgId, 'eventSources'];

  const { data, error, isLoading, isFetching, refetch } = useQuery(dataKey, () => getUniqueEventSources({ orgId }), {
    enabled: autoFetch,
  });

  return {
    data,
    error,
    isLoading,
    isFetching,
    operations: {
      refetch,
    },
  };
};

export const useEvaluatePricingPlanFormulaAPI = ({
  orgId,
  params,
  autoFetch = true,
  enableToasts = true,
  onSuccess,
  onError,
}) => {
  const bodyRef = useRef(params);

  const dataKey = [CACHE_KEY, 'pricingPlans', orgId, 'formula', params];

  const { pushToast: wrappedPushToast, pushError: wrappedPushError } = useToasts();
  const pushToast = (...args) => {
    if (enableToasts) wrappedPushToast(...args);
  };
  const pushError = (...args) => {
    if (enableToasts) wrappedPushError(...args);
  };

  const { data, error, isLoading, isFetching, refetch } = useQuery(
    dataKey,
    () => evaluatePricingPlanFormula({ orgId, body: bodyRef.current }),
    {
      enabled: autoFetch && !!bodyRef.current,

      onError: (err) => {
        pushError(err, 'Failed to evaluate formula');
        onError && onError(err);
      },

      onSuccess: (data) => {
        pushToast('Successfully evaluated formula!', 'success');
        onSuccess && onSuccess(data);
      },
    },
  );

  useEffect(() => {
    bodyRef.current = params;
  }, [params]);

  return {
    data,
    error,
    isLoading,
    isFetching,
    operations: {
      evaluateFormula: (body) => {
        bodyRef.current = body;
        refetch();
      },
    },
  };
};

export const useDiscountsAPI = ({ orgId, params = {}, autoFetch = true, enableToasts = true }) => {
  const dataKey = [CACHE_KEY, 'discounts', orgId, JSON.stringify(params)];

  const { pushToast: wrappedPushToast, pushError: wrappedPushError } = useToasts();
  const pushToast = (...args) => {
    if (enableToasts) wrappedPushToast(...args);
  };
  const pushError = (...args) => {
    if (enableToasts) wrappedPushError(...args);
  };
  const queryClient = useQueryClient();

  const { data, error, isLoading, isFetching, refetch } = useQuery(dataKey, () => getDiscounts({ orgId, params }), {
    enabled: autoFetch,
  });

  const createDiscount = useMutation(({ data }) => _createDiscount({ orgId, body: data }), {
    onError: (err) => {
      pushError(err, 'Failed to create discount.');
    },

    onSuccess: () => {
      pushToast('Successfully created discount!', 'success');
    },

    onSettled: () => {
      queryClient.invalidateQueries([CACHE_KEY, 'discounts', orgId]);
    },
  });

  return {
    data,
    error,
    isLoading,
    isFetching,
    operations: {
      refetch,
      createDiscount,
    },
  };
};

export const useDiscountAPI = ({ orgId, id, params = {}, autoFetch = true, enableToasts = true }) => {
  const dataKey = [CACHE_KEY, 'discounts', orgId, id, JSON.stringify(params)];

  const { pushToast: wrappedPushToast, pushError: wrappedPushError } = useToasts();
  const pushToast = (...args) => {
    if (enableToasts) wrappedPushToast(...args);
  };
  const pushError = (...args) => {
    if (enableToasts) wrappedPushError(...args);
  };
  const queryClient = useQueryClient();

  const { data, error, isLoading, isFetching, refetch } = useQuery(dataKey, () => getDiscount({ orgId, id, params }), {
    enabled: autoFetch,
  });

  const editDiscount = useMutation(({ data, id }) => updateDiscount({ orgId, body: data, id }), {
    onError: (err) => {
      pushError(err, 'Failed to update discount.');
    },

    onSuccess: () => {
      pushToast('Successfully updated discount!', 'success');
    },
    onSettled: () => {
      queryClient.invalidateQueries([CACHE_KEY]);
    },
  });

  const deleteDiscount = useMutation(({ id }) => _deleteDiscount({ orgId, id }), {
    onError: (err) => {
      pushError(err, 'Failed to delete discount.');
    },

    onSuccess: () => {
      pushToast('Successfully deleted discount!', 'success');
    },

    onSettled: () => {
      queryClient.invalidateQueries([CACHE_KEY]);
    },
  });

  return {
    data,
    error,
    isLoading,
    isFetching,
    operations: {
      refetch,
      editDiscount,
      deleteDiscount,
    },
  };
};
