import { useCallback, useState } from 'react';
import { ForecastedDetailsModal } from './ForecastedDetailsModal';

const useForecastedDetailsModal = () => {
  const [modalState, setModalState] = useState({});
  const handleCloseModal = () => setModalState({});
  const openModalWith = ({ scenarioId, scenarioName, scenarioMonths, selectedMonth, forecastScenarioType }) => {
    setModalState({
      scenarioId,
      scenarioName,
      scenarioMonths,
      selectedMonth,
      forecastScenarioType,
    });
  };

  const Modal = useCallback(
    () =>
      Object.keys(modalState).length > 0 ? (
        <ForecastedDetailsModal
          scenarioId={modalState.scenarioId}
          scenarioName={modalState.scenarioName}
          scenarioMonths={modalState.scenarioMonths}
          initialMonth={modalState.selectedMonth}
          forecastScenarioType={modalState.forecastScenarioType}
          onClose={handleCloseModal}
        />
      ) : null,
    [modalState],
  );

  return {
    openModalWith,
    Modal,
    isVisible: Object.keys(modalState).length > 0,
  };
};

export { useForecastedDetailsModal };
