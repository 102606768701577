import sanitizeHtml from 'sanitize-html';

// Must be the same as DYNAMIC_TAGS_REPLACE_REGEX on the backend
const DYNAMIC_TAGS_REPLACE_REGEX = /\{([^{}]*)\}/g;

// Converts a template with text inside curly braces to an HTML string
// with the text inside bold tags.
export const templateToHTMLString = ({ text = '', availableTags = [] }) => {
  // This code uses a regular expression that matches both <b> tags with their content and text
  // inside balanced curly braces. Then, in the replace function, we check if the found text is curly braces
  // that are outside the <b> and </b> tags. If so, we wrap the text inside the curly braces, including the braces themselves, in a <b> tag
  // only if the tag is available in the availableTags array.
  // Otherwise, we simply return the found text without modification.
  const regex = /<b class="invalid">[\s\S]*?<\/b>|<b>[\s\S]*?<\/b>|\{([^{}]*)\}/g;

  if (!text) return '';

  // Get all values between '{' and '}' including the brackets
  const allTags = text.match(DYNAMIC_TAGS_REPLACE_REGEX);
  const invalidTags = allTags?.filter((tag) => !availableTags.includes(tag)) ?? [];

  const htmlString = text?.replace(regex, (match) => {
    if (match.startsWith('{') && match.endsWith('}')) {
      // If the text part is inside balanced curly braces and not inside <b> tags, and the tag is in the availableTags array
      // &#8203; it's a 'zero-width space' symbol, which ensures the correct cursor display after exiting the <b> tag
      if (availableTags.includes(match)) return `<b>${match}</b>&#8203;`;
      if (invalidTags.includes(match)) return `<b class='invalid'>${match}</b>&#8203;`;
    } else {
      // If the text part is a whole <b> tag or outside balanced curly braces
      if (match.startsWith('<b') && match.endsWith('</b>')) {
        const value = match.match(DYNAMIC_TAGS_REPLACE_REGEX)?.[0];

        // was invalid but is now valid
        if (availableTags.includes(value) && match.includes('invalid')) return `<b>${value}</b>&#8203;`;
        // was valid but is now invalid
        if (invalidTags.includes(value) && !match.includes('invalid')) return `<b class='invalid'>${value}</b>&#8203;`;

        return match;
      }
      return match;
    }
  });

  // Replace empty rows in text with <br/> for ContentEditable
  const stringWithDivs = htmlString?.replace(/\n|\r\n|\n\r|\r/g, '<br/>');

  return sanitizeHtml(stringWithDivs, {
    allowedClasses: {
      b: ['invalid'],
    },
  });
};

export const removeEmptyLinesFromHTMLString = ({ htmlString }) => {
  // clean HTML
  const plainText = sanitizeHtml(htmlString, {
    allowedTags: ['b'],
    allowedAttributes: {},
  });

  return plainText;
};

const decodeHtmlEntities = (str) => {
  const entities = {
    '&amp;': '&',
    '&lt;': '<',
    '&gt;': '>',
    '&quot;': '"',
    '&#039;': "'",
    '&#x2F;': '/',
  };

  return str?.replace(/&[^;]+;/g, (match) => entities[match] || match);
};

export const convertToPlainText = (htmlString) => {
  // Decode HTML entities first
  const decodedHtmlString = decodeHtmlEntities(htmlString);

  // Create a temporary DOM element to manipulate the HTML
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = decodedHtmlString;

  // Replace <br> and <div> tags with newline characters
  tempDiv.querySelectorAll('br, div').forEach((el) => {
    if (el.textContent.trim() === '' && el.tagName.toLowerCase() === 'div') {
      el.innerHTML = '\n';
    } else {
      el.insertAdjacentText('beforebegin', '\n');
    }
  });

  // Remove any remaining HTML tags
  let plainText = tempDiv.textContent;

  // Clean HTML
  plainText = sanitizeHtml(plainText, {
    allowedTags: [],
    allowedAttributes: {},
  });

  plainText = decodeHtmlEntities(plainText);

  // Remove Unicode zero-width space characters
  return plainText?.replace(/[\u200B-\u200D\uFEFF]/g, '');
};
