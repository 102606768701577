import { useToasts } from 'components/Toasts';
import { useMemoTemplateDropdownButton } from 'views/Billing/Common/useMemoTemplateDropdownButton';
import { CentererVertical } from 'components/Core';

export const MemoTemplateDropdown = ({ values, isDisabled, invoicingScheduleFormValues, onSelect }) => {
  const { pushError } = useToasts();

  const { MemoTemplateDropdownButton, templates } = useMemoTemplateDropdownButton({
    isDisabled,
    name: 'invoice_modal_memo_templates_dropdown',
    buttonText: 'Templates',
    onSelect: (key) => {
      if (!values?.id) {
        pushError(
          { response: {} },
          `Please save the ${!invoicingScheduleFormValues?.id ? 'invoicing schedule' : 'invoice'} first`,
        );
      } else if (!isDisabled) {
        const selectedTemplate = templates?.find(({ id }) => id === key);
        onSelect({ templateId: key, content: selectedTemplate?.content });
      }
    },
  });

  return (
    <CentererVertical>
      <MemoTemplateDropdownButton />
    </CentererVertical>
  );
};
