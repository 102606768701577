import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {
  BannersContainer,
  Banner,
  BannerText,
  BannerActions,
  BannerButton,
  BannerTitle,
} from 'components/Blocks/Banner';
import { BillingContext } from '../BillingContext';

const Wrapper = styled.div`
  margin-top: 4px;
  margin-bottom: 16px;
`;

export const ReviewTransactionsBanner = () => {
  const { transactionsThatNeedSchedules } = useContext(BillingContext);

  if (transactionsThatNeedSchedules === 0) {
    return <></>;
  }

  return (
    <Wrapper>
      <Link to="/billing/review-transactions">
        <BannersContainer>
          <Banner tag="warning">
            <BannerTitle>Review transactions that need invoices</BannerTitle>

            <BannerText>
              <div>You can generate new invoices based on your new confirmed transactions.</div>
              <div>
                Please review <b>{transactionsThatNeedSchedules}</b> new invoices before sending them to customers.
              </div>
            </BannerText>
            <BannerActions>
              <BannerButton>Go to Review</BannerButton>
            </BannerActions>
          </Banner>
        </BannersContainer>
      </Link>
    </Wrapper>
  );
};
