// From a standard list function that supports pagination and search query, create a loadOptions function
//  to be used by a ReactSelect component with isPaginateable.
export const makePaginateableLoadFn = ({ listFn, converter }) => async ({ searchQuery, orgId, additional }) => {
  const currentPage = parseInt(additional?.currentPage) || 0;

  const searchResult = await listFn({
    orgId,
    params: {
      'filters[searchQuery]': searchQuery,
      'pagination[page]': currentPage + 1,
      'pagination[limit]': 10,
      'scopes[]': 'pagination_data',
    },
  });

  const options = searchResult.data.map(
    converter ??
      ((record) => ({
        label: record.name,
        id: record.id,
        value: record.id,
      })),
  );

  return {
    options,
    hasMore: !!searchResult.metadata?.hasMore,
    additional: searchResult.metadata,
  };
};
