import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useClickOutside } from 'utils/hooks';
import { IconButton } from 'components/Buttons';
import { Popover, PopoverItemsContainer, PopoverListItem, PopoverWrapper } from 'components/Portal';
import { ReactComponent as TableExportIcon } from 'images/table-export.svg';
import { ReactComponent as FilterIcon } from 'images/filter-funnel.svg';
import { ReactComponent as TableIcon } from 'images/table.svg';
import { ReactComponent as SyncIcon } from 'images/rotate-2.svg';
import { ReactComponent as PhotoIcon } from 'images/photo.svg';
import { COLORS } from 'consts/colors';
import { DEFAULT_ICON_SIZE } from 'consts/global';
import { FlexerColumn } from 'components/Core';
import { Radio, RadioGroup } from 'components/Controls';

export const StyledIconButton = styled(IconButton)`
  box-shadow: ${(props) => props.whiteVer && '4px 4px 28px var(--primaryBlack5)'};
  &:hover {
    background-color: ${(props) => (props.whiteVer ? '' : 'var(--secondaryGreen)')};
    box-shadow: ${(props) => props.whiteVer && '0px 4px 20px var(--primaryBlack10)'};
    border: ${(props) => props.whiteVer && '1px solid var(--neutralGray)'};
  }
`;

const StyledPopoverListItem = styled(PopoverListItem)`
  white-space: pre-wrap;
  overflow-wrap: break-word;
  align-items: flex-start;
`;

const SubText = styled.div`
  font-weight: 400;
  font-size: 12px;
`;

const StyledPopover = styled(Popover)`
  ${({ isSelectableData }) =>
    isSelectableData
      ? css`
          padding: 12px;
          background-color: var(--primaryGray);
        `
      : ''}
`;

const OptionsWrapper = styled.div`
  display: ${({ hide }) => (hide ? 'none' : 'auto')};

  ${({ isSelectableData }) =>
    isSelectableData
      ? css`
          background-color: white;
          border-radius: 8px;
          border: 1px solid var(--accentGraySecond);
          margin-top: 8px;
        `
      : ''};
`;

const RadioLabel = styled.span`
  font-size: 12px;
  line-height: 20px;
  color: var(--primaryBlack);
  font-style: italic;
  white-space: pre-wrap;
  overflow-wrap: break-word;
`;

const StyledFilterIcon = styled(FilterIcon)`
  min-width: 16px;
  min-height: 16px;
`;

const StyledTableIcon = styled(TableIcon)`
  width: 16px;
  height: 16px;
  min-width: 16px;
  min-height: 16px;

  path {
    fill: var(--primaryBlack50);
  }
`;

export const StyledTableExportIcon = styled(TableExportIcon)`
  width: 16px;
  height: 16px;
  min-width: 16px;
  min-height: 16px;

  path {
    fill: var(--primaryBlack50);
  }
`;

const StyledSyncIcon = styled(SyncIcon)`
  width: 16px;
  height: 16px;
  min-width: 16px;
  min-height: 16px;

  path {
    fill: var(--primaryBlack50);
  }
`;

const StyledPhotoIcon = styled(PhotoIcon)`
  width: 16px;
  height: 16px;
  min-width: 16px;
  min-height: 16px;
`;

export const ExportButton = ({
  onSync,
  onExport,
  type,
  downloadScreenshot,
  children,
  whiteVer = false,
  externalSync,
  isFilterOn,
  selectAllData,
  setSelectAllData,
  selectAllDataRef,
  persistMenuStateRef,
  timePeriodText,
  additionalExportOption,
  additionalMenuOptions,
}) => {
  const [showDropdown, setShowDropdown] = useState(persistMenuStateRef?.current ?? false);
  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
    if (persistMenuStateRef) {
      persistMenuStateRef.current = !showDropdown;
    }
  };
  const dropdownRef = useClickOutside(() => {
    setShowDropdown(false);

    if (persistMenuStateRef) {
      persistMenuStateRef.current = false;
    }
  });

  const menuOptions = [
    {
      title: 'Export to CSV file',
      action: onExport,
      dataCy: 'export-actions__popover__item--export',
      isAvailable: !!type,
      icon: <StyledTableExportIcon />,
    },
    ...(additionalMenuOptions || []),
    {
      title: 'Sync into Ext Product',
      action: onSync,
      dataCy: 'export-actions__popover__item--sync',
      isAvailable: type && externalSync,
      icon: <StyledSyncIcon />,
    },
    {
      title: 'Export to PNG',
      action: downloadScreenshot,
      dataCy: 'export-actions__popover__item--screenshot',
      isAvailable: !!downloadScreenshot,
      icon: <StyledPhotoIcon />,
      excludesData: true,
    },
  ];

  const dynamicMenuOptions = menuOptions.filter((option) => !option.excludesData);
  const fixedMenuOptions = menuOptions.filter((option) => option.excludesData);

  const hasMultipleActions = menuOptions.filter((option) => option.isAvailable && !option.excludesData).length > 1;
  const isSelectableData = hasMultipleActions && isFilterOn;

  return (
    <PopoverWrapper ref={dropdownRef}>
      <StyledIconButton
        whiteVer={whiteVer}
        filled={!whiteVer}
        color={whiteVer ? 'white' : COLORS.GREEN}
        iconFillColor={whiteVer ? 'var(--primaryGreen)' : 'white'}
        onClick={toggleDropdown}
        icon={<TableExportIcon width={DEFAULT_ICON_SIZE} height={DEFAULT_ICON_SIZE} />}
        data-cy="export-actions__toggler"
      >
        {children}
      </StyledIconButton>
      {showDropdown && (
        <StyledPopover
          data-cy="export-actions__popover"
          width={isSelectableData ? '240px' : '180px'}
          XOffset={isSelectableData ? 10 : 40}
          isSelectableData={isSelectableData}
        >
          {isSelectableData && (
            <FlexerColumn gap="16px">
              <RadioGroup value={selectAllData} onChange={() => setSelectAllData((prev) => !prev)}>
                <FlexerColumn gap="8px">
                  <Radio value={true}>
                    <RadioLabel>
                      {timePeriodText ? `All data ${timePeriodText}` : 'Data without additional filters'}
                    </RadioLabel>
                  </Radio>
                  <Radio value={false}>
                    <RadioLabel>Data that matches filters</RadioLabel>
                  </Radio>
                </FlexerColumn>
              </RadioGroup>
              {additionalExportOption}
            </FlexerColumn>
          )}
          <OptionsWrapper
            isSelectableData={isSelectableData}
            hide={dynamicMenuOptions.filter((option) => option.isAvailable).length === 0}
          >
            {dynamicMenuOptions.map((option) => {
              const hasFilter = !hasMultipleActions && isFilterOn;

              return option.isAvailable ? (
                <React.Fragment key={option.title}>
                  <PopoverItemsContainer>
                    <StyledPopoverListItem
                      data-cy={option.dataCy}
                      onClick={() => {
                        selectAllDataRef.current = false;
                        option.action();
                      }}
                    >
                      {hasFilter ? <StyledFilterIcon /> : option.icon}
                      {option.title} {hasFilter && 'for filtered data'}
                    </StyledPopoverListItem>
                  </PopoverItemsContainer>
                  {hasFilter && (
                    <PopoverItemsContainer>
                      <StyledPopoverListItem
                        data-cy={`${option.dataCy}--all`}
                        onClick={() => {
                          selectAllDataRef.current = true;
                          option.action();
                        }}
                      >
                        <StyledTableIcon />
                        <FlexerColumn>
                          <div>
                            {option.title} {'for all data'}
                          </div>
                          <SubText>Without using additional filters</SubText>
                        </FlexerColumn>
                      </StyledPopoverListItem>
                    </PopoverItemsContainer>
                  )}
                </React.Fragment>
              ) : null;
            })}
          </OptionsWrapper>

          <OptionsWrapper
            isSelectableData={isSelectableData}
            hide={fixedMenuOptions.filter((option) => option.isAvailable).length === 0}
          >
            {fixedMenuOptions.map((option) => {
              return option.isAvailable ? (
                <React.Fragment key={option.title}>
                  <PopoverItemsContainer>
                    <StyledPopoverListItem data-cy={option.dataCy} onClick={option.action}>
                      {option.icon}
                      {option.title}
                    </StyledPopoverListItem>
                  </PopoverItemsContainer>
                </React.Fragment>
              ) : null;
            })}
          </OptionsWrapper>
        </StyledPopover>
      )}
    </PopoverWrapper>
  );
};
