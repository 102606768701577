import * as Yup from 'yup';
import { SOURCE_TYPES } from '../TransactionsCsvUpload/consts';

export const getSchema = ({ transactions, transactionsIdSourceAndType }) => {
  const schema = Yup.array().of(
    Yup.object({
      transaction_id: Yup.string()
        .nullable()
        .oneOf(
          [
            null,
            ...(transactions ?? [])?.map((transaction) =>
              transactionsIdSourceAndType?.type === SOURCE_TYPES.INTERNAL
                ? transaction.id
                : transaction.provider_object_id,
            ),
          ],
          "We can't find that transaction",
        )
        .required('Event-based transacton is missing'),
      date: Yup.date().nullable().required('Date is missing'),
      amount: Yup.number()
        .typeError('Total must be a number')
        .nullable()
        .when('transaction_id', {
          is: (val) => val === null,
          then: Yup.number().required('Amount field is missing'),
          otherwise: Yup.number().nullable(),
        }),
      seats: Yup.number().nullable(),
    }),
  );

  return schema;
};
