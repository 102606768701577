import React, { useState } from 'react';
import { Filters, SegmentByFilter } from 'shared/Filters';

export const FiltersAndSegmentBy = ({
  currentPageSegmentKey,
  isARR,
  dataFilter,
  setDataFilter,
  resolutionDropdownEnabled,
  showConditions,
  metadataFilter,
  setMetadataFilter,
  allowSegment,
  segmentOptions,
  segmentName,
  relativeMonthSelectorForTimeseries,
  showTransactionMetadata,
  setLoading,
  orgId,
  hidePeriodSelection,
  showGlobalToggles,
  removeTopBorderRadius,
  showOrSeparator,
  showCustomerMetadata,
  showChangeCategory,
  sameMonthAllowed = false,
  showTransaction,
  showSize,
  children,
}) => {
  const [isSelectingSegment, setIsSelectingSegment] = useState(false);
  return (
    <Filters
      currentPageSegmentKey={currentPageSegmentKey}
      setLoading={setLoading}
      orgId={orgId}
      isARR={isARR}
      dataFilter={dataFilter}
      setDataFilter={setDataFilter}
      sameMonthAllowed={sameMonthAllowed}
      resolutionDropdownEnabled={resolutionDropdownEnabled}
      showConditions={showConditions}
      metadataFilter={metadataFilter}
      setMetadataFilter={setMetadataFilter}
      hidePeriodSelection={hidePeriodSelection}
      showGlobalToggles={showGlobalToggles}
      removeTopBorderRadius={removeTopBorderRadius}
      isSelectingSegment={isSelectingSegment}
    >
      {children}
      {allowSegment && (
        <SegmentByFilter
          segmentOptions={segmentOptions}
          segmentName={segmentName}
          relativeMonthSelectorForTimeseries={relativeMonthSelectorForTimeseries}
          showOrSeparator={showOrSeparator}
          showCustomerMetadata={showCustomerMetadata}
          showChangeCategory={showChangeCategory}
          showTransactionMetadata={showTransactionMetadata}
          showTransaction={showTransaction}
          showSize={showSize}
          isSelectingSegment={isSelectingSegment}
          setIsSelectingSegment={setIsSelectingSegment}
        />
      )}
    </Filters>
  );
};
