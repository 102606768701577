import { useState } from 'react';
import { default as TooltipWrap } from 'rc-tooltip';

import 'rc-tooltip/assets/bootstrap.css';
import './styles.css';

// JB [2022-06-07] the secondary prop allow us to have the new tooltip styling (dark bluish)
// as seen here https://www.figma.com/file/EF0608NGesHrocQ4nP8UaO/Subscript-%E2%80%94-Styleguide?node-id=11168%3A49650
export const Tooltip = ({ children, secondary, ...props }) => {
  // Transparency workaround found in:
  // https://github.com/react-component/tooltip/issues/215#:~:text=I%20fixed%20this%20issues%20with%20this%20code.
  const [visibleClass, setVisibleClass] = useState(false);

  //TODO [TC 2021-11-25]: This tooltip still has issues, for example the transparency and color
  // of the arrow cannot be changed. We need a better tooltip component to replace this & the old one
  // I added this since we couldn't set the tooltip direction on the old tooltip (and it is a very rigid
  // implementation)
  return (
    <TooltipWrap
      placement="bottom"
      overlayInnerStyle={!secondary ? { 'background-color': '#000000', 'min-width': '200px' } : undefined}
      onPopupAlign={() => {
        setVisibleClass(true);
      }}
      overlayClassName={`${visibleClass ? 'rc-tooltip-no-transparent' : undefined} ${
        // If secondary style use rc-tooltip-secondary from styles.css
        // Example: https://www.figma.com/file/EF0608NGesHrocQ4nP8UaO/Subscript-%E2%80%94-Styleguide?node-id=11168%3A49650
        secondary ? 'tooltip-secondary' : ''
      }`}
      {...props}
    >
      <div> {children} </div>
    </TooltipWrap>
  );
};
